import { AdminAccount } from 'api/account/admin';
import { AdminUserForm } from 'components/Admin';
import { Svgs } from 'environment';
import { LanguageType } from 'types/index';
import { UserLicenceModel } from 'store/account/subscription';
import { Wrapper, Container, Header, BackArrowContainer } from './styles';
import { Icon } from 'components/UI/Icons';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useAdminUsersList } from 'hooks/store';

export function AdminUserCreatePage() {
	const { navigate, routes } = useNavigation();
	const { translate } = useTranslation();

	const [{ loading: loadingUsers, fetched }] = useAdminUsersList();

	const newUser: AdminAccount = {
		userid: '',
		licenceModel: UserLicenceModel.Full,
		cognitoCreatedDate: '',
		cognitoUserStatus: '',
		userFirstName: '',
		userSirName: '',
		position: '',
		department: '',
		workplace: '',
		city: '',
		country: '',
		phoneNumber: '',
		emailAddress: '',
		imageURL: '',
		address: '',
		status: 'ACTIVE',
		userPreferences: {
			chosenLanguageCode: LanguageType.English,
			dateTimeFormat: '24'
		}
	};

	return (
		<Suspend loading={loadingUsers} immediate={!fetched}>
			<Wrapper>
				<Container>
					<Header>
						<BackArrowContainer>
							<Icon
								customSize={4}
								svg={Svgs.ChevronLeft}
								variant={v => v.button}
								onClick={() => navigate(routes.admin.view)}
							/>
						</BackArrowContainer>
						<Typography.H4>
							{translate(({ admin }) => admin.users.create.title)}
						</Typography.H4>
					</Header>
				</Container>
				<AdminUserForm user={newUser} createMode />
			</Wrapper>
		</Suspend>
	);
}

import styled, { css } from 'styled-components';

import { MediaQueries, Colors, Shadows } from 'environment';
import { Columns } from 'api/data/analyses';
import { disableableComponent, Disabled } from 'helpers/cssGenerators';

interface AnalysisContainerProps {
	activeColumn?: number;
	invalid?: boolean;
	disabled?: boolean;
}

interface OptionsHeaderProps {
	fullscreen?: string;
}

interface ConfigProps extends Disabled {
	isFullScreen?: string;
	areFiltersOpen?: boolean;
}
interface AnalysisChartContainerProps {
	fullscreen: string;
	activeColumn: number;
}
export const AnalysisContainer = styled.div<AnalysisContainerProps>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-right: 1.2rem;
	padding-bottom: 2.4rem;
	height: 100%;
	cursor: ${props => (props.activeColumn === Columns.OneColumn ? 'default' : 'pointer')};
	overflow: hidden;

	${({ invalid }) =>
		invalid &&
		css`
			width: 100%;
			padding-right: 0;
		`};

	@media ${MediaQueries.maxWidth.lg} {
		align-items: ${({ invalid }) => !invalid && 'flex-start'};
	}
`;

interface AnalysisStatisticsWrapperProps {
	maxWidth?: number;
}
export const AnalysisStatisticsWrapper = styled.div<AnalysisStatisticsWrapperProps>`
	margin-top: 2.4rem;
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}rem;
		`}
`;

interface AnalysisGroupedTablesProps {
	marginTop?: number;
}
export const AnalysisGroupedTables = styled.div<AnalysisGroupedTablesProps>`
	display: flex;
	align-items: flex-start;

	${({ marginTop }) =>
		marginTop &&
		css`
			margin-top: ${marginTop}rem;
		`}
`;

export const ConfigContainer = styled.div<ConfigProps>`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	padding: 1.2rem;
	z-index: 0;

	@media ${MediaQueries.maxWidth.lg} {
		width: 50%;
		padding: 0 1.6rem;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		background: ${Colors.white};
		height: 100%;
		box-shadow: ${Shadows.normal};

		${({ isFullScreen, areFiltersOpen }) =>
			isFullScreen &&
			areFiltersOpen &&
			css`
				width: 100%;
			`}
	}

	@media ${MediaQueries.maxWidth.sm} {
		width: 100%;
		height: 100vh;
		box-shadow: none;
		position: absolute;
		top: 6rem;
	}

	${({ disabled }) => disableableComponent({ disabled })};
`;

export const CollapsibleContainer = styled.div`
	@media ${MediaQueries.maxWidth.lg} {
		div:nth-child(2) {
			align-items: flex-start;
		}
	}
`;

export const VariableContainer = styled.div`
	> * {
		margin: 1rem 0;
	}

	width: 100%;
`;

export const Divider = styled.div`
	height: 0.1rem;
	border-bottom: 0.1rem solid ${Colors.silver.lightest};
`;

export const OptionsHeaderContainer = styled.div<OptionsHeaderProps>`
	display: none;
	padding: 1.6rem 0;
	@media ${MediaQueries.maxWidth.lg} {
		display: ${props => (props.fullscreen ? 'none' : 'flex')};
		justify-content: space-between;
	}

	@media ${MediaQueries.maxWidth.sm} {
		display: ${props => (props.fullscreen ? 'flex' : 'none')};
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;
	}
`;

export const AnalysisChartContainer = styled.div<AnalysisChartContainerProps>`
	height: ${props => (props.fullscreen || props.activeColumn === 1 ? '100%' : '40vh')};
	min-height: ${props => (props.fullscreen || props.activeColumn === 1 ? '50rem' : '100%')};
`;

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { sortInsideTemplate, SortInsideTemplateAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useSortInsideTemplate(): LocalOperation<
	(payload: ActionPayload<SortInsideTemplateAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SortInsideTemplateAction>) {
		dispatch(sortInsideTemplate(payload));
	}

	return handler;
}

import { Input as DefaultInput } from 'components/UI/Inputs/Input';
import styled from 'styled-components';

export const Container = styled.div``;

export const CheckboxWrapper = styled.div`
	> div {
		:not(:last-child) {
			margin-bottom: 1.6rem;
		}
		> div {
			flex-wrap: wrap;
			> div {
				flex: 33.33%;
				padding-bottom: 1.6rem;
			}
		}
	}
`;

export const Input = styled(DefaultInput)`
	pointer-events: none;
`;

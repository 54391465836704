import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { moveGroup, MoveGroupAction, ActionTypes } from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveGroup(): Operation<(payload: ActionPayload<MoveGroupAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_GROUP);

	function handler(payload: ActionPayload<MoveGroupAction>) {
		dispatch(moveGroup(payload));
	}

	return [{ loading, error }, handler];
}

import { State } from './types';

const initialState: State = {
	projectId: null,
	bySeriesName: null,
	fullScreenSeriesName: null,
	seriesViews: { narrowTableView: false, gridView: false, tableFullSizeView: false },
	metadata: {
		defaultValues: {
			pageIndex: 0,
			pageSize: 10,
			columnSettings: {
				visible: []
			}
		}
	}
};

export default initialState;

import { AnalysisV2 } from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';

export function validateAnalysis(analysis: AnalysisV2): AnalysisV2 {
	if (
		analysis.type === AnalysisType.FrequenciesV2 ||
		analysis.type === AnalysisType.ExploreV2 ||
		analysis.type === AnalysisType.CompareNumericV2 ||
		analysis.type === AnalysisType.CrosstabV2 ||
		analysis.type === AnalysisType.KaplanV2 ||
		analysis.type === AnalysisType.CorrelationsV2 ||
		analysis.type === AnalysisType.PlotNumericV2 ||
		analysis.type === AnalysisType.DensityPlotV2 ||
		analysis.type === AnalysisType.TimeCourseV2 ||
		analysis.type === AnalysisType.ComparePairedV2 ||
		analysis.type === AnalysisType.JADBio ||
		analysis.type === AnalysisType.LogisticRegressionV2 ||
		analysis.type === AnalysisType.NumberPlotXY
	) {
		return analysis;
	}

	const invalidAnalysis = {
		...analysis,
		invalid: true
	};

	return invalidAnalysis;
}

import { useDispatch, useSelector } from 'hooks/utils';
import { setRefetchEntries } from 'store/data/entries';
import { LocalOperationResult } from 'hooks/store/types';

export function useRefetchEntries(): LocalOperationResult<boolean> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.entries.metadata.refetch);

	function handler() {
		dispatch(setRefetchEntries());
	}

	return [data, handler];
}

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Wrapper = styled.div`
	flex: 1;
	padding: 3rem;
	height: 100%;
	overflow: auto;

	@media ${MediaQueries.maxWidth.xl} {
		padding: 2rem;
	}
	@media ${MediaQueries.maxWidth.md} {
		padding: 1rem;
	}
`;

export const Container = styled.div`
	max-width: 68rem;
`;

export const Content = styled.div``;

export const Navigation = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { ActionTypes } from 'store/account/payments';

import { AddFirstCardContainer, HSpacer } from './AddFirstCard.style';
import { useTranslation, useActivity, useSubscriptionRules } from 'hooks/store';

interface Props {
	setAddCreditCardModalVisible: () => void;
}
export function AddFirstCard({ setAddCreditCardModalVisible }: Props) {
	const { translate } = useTranslation();

	const { isSubscriptionUpdating } = useSubscriptionRules();

	const [{ loading }] = useActivity(ActionTypes.ADD_SUBSCRIPTION_PAYMENT_METHOD);

	return (
		<AddFirstCardContainer>
			<Spacer size={s => s.m} />
			<Flex>
				<HSpacer />
				<div>
					<Typography.Paragraph fontweight={f => f.bold}>
						{translate(({ accountUM }) => accountUM.payment.noCardsAdded)}
					</Typography.Paragraph>
					<Spacer size={s => s.m} />
					<Button
						variant={v => v.primary}
						title={translate(({ accountUM }) => accountUM.payment.addCard)}
						onClick={setAddCreditCardModalVisible}
						disabled={isSubscriptionUpdating}
						loading={loading}
					/>
				</div>
			</Flex>
			<Spacer size={s => s.m} />
		</AddFirstCardContainer>
	);
}

import { useEffect } from 'react';
import { AdminAccount } from 'api/account/admin';
import { OperationResult } from 'hooks/store/types';
import {
	selectIsAccountFetched,
	ActionTypes as AccountActionTypes
} from 'store/account/subscription';
import { ActionTypes, selectAdminUsersList, getAdminUsers } from 'store/account/admin';
import { useDispatch, useSelector } from 'hooks/utils';
import { useNavigation } from 'hooks/navigation';
import { useActivities } from 'hooks/store/utils/useActivities';

export function useAdminUsersList(): OperationResult<AdminAccount[]> {
	const dispatch = useDispatch();
	const { replace, routes } = useNavigation();

	const data = useSelector(state => selectAdminUsersList(state.account.admin));
	const fetched = useSelector(state => state.account.admin.users.metadata.fetched);
	const isMyAccountLoaded = useSelector(state =>
		selectIsAccountFetched(state.account.subscription)
	);

	const [{ loading, error }] = useActivities([
		ActionTypes.ADMIN_GET_USERS,
		AccountActionTypes.GET_ACCOUNT
	]);

	useEffect(() => {
		if (!fetched && !loading && !error) handler();
	}, [fetched, loading, error]);

	useEffect(() => {
		if (error) replace(routes.projects.list);
	}, [error]);

	function handler() {
		dispatch(getAdminUsers());
	}

	return [{ data, loading, error, fetched: fetched && isMyAccountLoaded }, handler];
}

import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Typography } from 'components/UI/Typography';

import {
	borderActive,
	borderActiveDisabled,
	borderDefault,
	borderDisabled,
	Disabled,
	generateSpacingOffsets
} from 'helpers/cssGenerators';
import { SpacingOffsets } from 'types/index';

interface ContainerProps extends SpacingOffsets, Disabled {
	selected?: boolean;
	hasDescription?: boolean;
	inline?: boolean;
}

export const HiddenInput = styled.input`
	width: 0;
	height: 0;
	border: 0;
	margin: 0;
	padding: 0;
	opacity: 0;
	display: block;
	position: absolute;
`;

interface TextProps {
	error?: string;
}

export const Text = styled(Typography.Paragraph)<TextProps>`
	width: initial;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const RadioButtonCheck = styled.div`
	height: 0.8rem;
	width: 0.8rem;

	border-radius: 50%;

	background: ${Colors.primary.normal};
`;

export const RadioButton = styled.div`
	flex: 1;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 1.6rem;
	width: 1.6rem;
	min-width: 1.6rem;
	max-width: 1.6rem;
	margin-right: 0.8rem;

	transition: 0.2s;

	${borderDefault};
	border-radius: 50%;
`;

export const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: ${({ hasDescription }) => (hasDescription ? 'flex-start' : 'center')};
	width: ${({ inline }) => (inline ? 'auto' : '100%')};
	user-select: none;
	cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};

	:hover {
		${RadioButton} {
			${borderActive}
		}
	}
	${({ selected }) => {
		if (selected)
			return css`
				${RadioButton} {
					${borderActive}
				}
				${RadioButtonCheck} {
					background: ${Colors.primary.normal};
				}
			`;
	}}

	${({ disabled, selected }) => {
		if (disabled)
			return css`
				pointer-events: none;
				${RadioButton} {
					${selected ? borderActiveDisabled : borderDisabled}
				}
				${RadioButtonCheck} {
					background: ${selected ? Colors.primary.disabled : Colors.text.disabled};
				}
				${Text} {
					color: ${Colors.text.disabled};
				}
			`;
	}}

	/* SPACING OFFSETS */
	${({ marginOffset, paddingOffset }) => css`
		${generateSpacingOffsets({ paddingOffset, marginOffset })}
	`}
`;

export const Wrapper = styled.div<Disabled>`
	${RadioButtonCheck} {
		display: none;
	}

	input:checked ~ label > div > ${RadioButton} {
		${borderActive}

		${RadioButtonCheck} {
			display: flex;
		}
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;

			${RadioButton} {
				${borderDisabled}
			}
			${RadioButtonCheck} {
				background: ${Colors.text.disabled};
			}
			${Text} {
				color: ${Colors.text.disabled};
			}

			input:checked ~ label > div > ${RadioButton} {
				${borderActiveDisabled}

				${RadioButtonCheck} {
					background: ${Colors.primary.disabled};
				}
			}
		`}
`;

export const Description = styled(Typography.Caption)``;

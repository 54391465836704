import { useState } from 'react';

import { Variable } from 'api/data/variables';
import { Svgs, Colors } from 'environment';
import { groupCardPulse, groupCountPulse } from 'events/variables';
import { GroupData } from 'store/data/variables';
import { useTranslation } from 'hooks/store';

import { CenteredCheckbox, ColorIndicator } from '../styles';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';

interface Props {
	groupData: GroupData;
	rowNumber?: number;
	isNested?: boolean;
	expandedRowsData: {
		expanded: boolean;
		onExpand: () => void;
	};
	checkedRowData: {
		checked: boolean;
		partial: boolean;
		onCheck: () => void;
	};
	readOnly: boolean;
	hasError: boolean;
	id: string;
	onClick: () => void;
	renderVariableRow: (variable: Variable, index: number) => React.ReactNode;
}

export function GroupRow({
	groupData,
	rowNumber,
	isNested,
	expandedRowsData: { expanded, onExpand },
	checkedRowData: { checked, partial, onCheck },
	readOnly,
	hasError,
	id,
	onClick,
	renderVariableRow
}: Props) {
	const { groupName, groupLabel, groupVariables } = groupData;
	const { translate } = useTranslation();

	const numberOfVariables = groupVariables.length;
	const hasOneVariable = numberOfVariables === 1;
	const numberOfVariablesMessage = `${numberOfVariables} ${
		hasOneVariable
			? translate(dict => dict.terms.variableLowerCase)
			: translate(dict => dict.terms.variables)
	}`;

	const [pulse, setPulse] = useState(false);
	const [pulseCount, setPulseCount] = useState(false);

	useCustomEventListener(groupCardPulse, {
		onListen: payload => {
			if (payload.groupName === groupName) setPulse(true);
		}
	});
	useCustomEventListener(groupCountPulse, {
		onListen: payload => {
			if (payload.groupName === groupName) setPulseCount(true);
		}
	});

	return (
		<>
			<Table.Row
				id={id}
				{...(pulse && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={() => setPulse(false)}
				//
				data-scroll-id={groupName}
				onClick={readOnly ? undefined : onClick}
				active={checked}
				clickable={!readOnly}
			>
				{!readOnly && (
					<Table.Cell
						style={{ position: 'relative' }}
						onClick={e => {
							e.stopPropagation();
							onCheck();
						}}
					>
						<ColorIndicator type="group" />
						<CenteredCheckbox
							checked={checked}
							partial={!checked && partial}
							onClick={onCheck}
						/>
					</Table.Cell>
				)}
				<Table.Cell style={{ position: 'relative' }} textCenter>
					{readOnly && <ColorIndicator type="group" />}

					<Typography.Caption>{rowNumber}</Typography.Caption>
				</Table.Cell>
				<Table.Cell minWidth={20} maxWidth={30} title={groupLabel}>
					<Flex marginOffset={isNested && { left: 2 }}>
						<Icon
							svg={expanded ? Svgs.ArrowDown : Svgs.ArrowRight}
							size={s => s.m}
							onClick={onExpand}
						/>
						<Icon
							svg={Svgs.Folder}
							size={s => s.m}
							colors={{ color: Colors.groupOrange }}
							marginOffset={{ x: 0.4 }}
							propagate
						/>
						<Typography.Paragraph fontweight={w => w.medium} ellipsis>
							{groupLabel}
						</Typography.Paragraph>
					</Flex>
				</Table.Cell>
				<Table.Cell
					{...(pulseCount && {
						className: 'pulse-vibrant-green',
						style: {
							color: Colors.chart.darkGreen.dark
						}
					})}
					onAnimationEnd={() => setPulseCount(false)}
					//
					title={numberOfVariablesMessage}
				>
					<Flex>
						<Typography.Paragraph fontweight={!isNested && (w => w.medium)}>
							{numberOfVariablesMessage}
						</Typography.Paragraph>

						{hasError && (
							<Icon
								svg={Svgs.Information}
								size={s => s.m}
								colors={{ color: Colors.text.error }}
								marginOffset={{ left: 0.8 }}
							/>
						)}
					</Flex>
				</Table.Cell>
				<Table.Cell>-</Table.Cell>
				{/* TODO: FEATURE: REPLACE WITH `description` WHEN IMPLEMENTED */}
				<Table.Cell>-</Table.Cell>
			</Table.Row>

			{expanded && groupVariables.map(renderVariableRow)}
		</>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	UserLicenceModel,
	cancelUserSubscriptionInvitation
} from 'store/account/subscription';

export function useSubscriptionCancelUserInvitation(): Operation<
	(userId: string, licenceModel: UserLicenceModel) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CANCEL_SUBSCRIPTION_USER_INVITATION);

	function handler(userId: string, licenceModel: UserLicenceModel) {
		dispatch(cancelUserSubscriptionInvitation(userId, licenceModel));
	}

	return [{ loading, error }, handler];
}

import { useEffect } from 'react';
import { Patient } from 'api/data/patients';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, getDistributionPatientList, selectPatients } from 'store/data/patients';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

interface Options {
	lazy?: boolean;
}

export function usePatients(options?: Options): OperationResult<Patient[] | undefined> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const data = useSelector(state => selectPatients(state.data.patients));

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.GET_PATIENT_DISTRIBUTION_LIST,
		{
			projectId
		}
	);

	useEffect(() => {
		if (!error && !loading && !data && isProjectValid && !options?.lazy) {
			handler();
		}
	}, [error, loading, data, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getDistributionPatientList());
	}

	return [{ loading, error, data }, handler];
}

import { useShouldRefetchUserAddons } from 'hooks/store';
import { JADBioProvider } from '../JADBioProvider';

export function AddonsProvider() {
	useShouldRefetchUserAddons();

	return (
		<>
			<JADBioProvider />
		</>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionPayload } from 'store/types';
import {
	moveVariableInsideGroup,
	MoveVariableInsideGroupAction,
	ActionTypes
} from 'store/data/variables';

export function useMoveVariableInsideGroup(): Operation<
	(payload: ActionPayload<MoveVariableInsideGroupAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_VARIABLE_INSIDE_GROUP);

	function handler(payload: ActionPayload<MoveVariableInsideGroupAction>) {
		dispatch(moveVariableInsideGroup(payload));
	}

	return [{ loading, error }, handler];
}

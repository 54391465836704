import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { NeutralButtonProps } from 'types/index';

interface NotificationButtonsProps {
	primary: NeutralButtonProps;
	neutral: NeutralButtonProps;
	pending?: NeutralButtonProps;
}

export function NotificationButtons({ primary, neutral, pending }: NotificationButtonsProps) {
	function handleButtonsClick(onClick?: () => void) {
		if (canClose()) return onClick;
	}

	/**
	 * Disable close modal action when there is an action going on
	 */
	function canClose() {
		let close = true;

		if ((primary && primary.loading) || (neutral && neutral.loading)) {
			close = false;
		}

		return close;
	}

	return (
		<Flex marginOffset={{ top: 0.6 }}>
			{pending ? (
				<Button
					variant={v => v.primary}
					title={pending.label ?? ''}
					disabled
					paddingOffset={{ y: 0, x: 1.6 }}
					minHeight={0}
					minWidth={0}
					stopPropagation
				/>
			) : (
				<>
					{primary && (
						<Button
							variant={v => v.primary}
							title={primary.label ?? ''}
							loading={primary.loading}
							disabled={primary.disabled}
							paddingOffset={{ y: 0, x: 1.6 }}
							minHeight={0}
							minWidth={0}
							onClick={handleButtonsClick(primary.onClick)}
							stopPropagation
						/>
					)}
					{neutral && (
						<Button
							variant={v => v.link}
							title={neutral.label ?? ''}
							loading={neutral.loading}
							disabled={neutral.disabled}
							marginOffset={primary && { left: 2.4 }}
							paddingOffset={{ all: 0 }}
							minHeight={0}
							minWidth={0}
							onClick={handleButtonsClick(neutral.onClick)}
							stopPropagation
						/>
					)}
				</>
			)}
		</Flex>
	);
}

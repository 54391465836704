import { deleteBulkVariablesData, ActionTypes } from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';

type HandlerInput = Parameters<typeof deleteBulkVariablesData>[0];

export function useDeleteBulkVariablesData(): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.DELETE_BULK_VARIABLES_DATA);

	function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(deleteBulkVariablesData(input));
	}

	return [{ loading, error }, handler];
}

import { useMemo } from 'react';

import { Form } from 'store/data/forms';
import { VariablesData, VariableSetData } from 'store/data/variables';

import { FormGroup, FormElement, FormVariable } from 'components/Forms';

import {
	Wrapper,
	Container,
	FormTitle
} from 'components/Dataset/AddEditForm/AddEditInputsAndGroupsFormDesigner/AddEditInputsAndGroupsFormDesigner.style';
import { VariableWrapper } from 'components/Dataset/AddEditForm/AddEditInputsAndGroupsFormDesigner/AddEditInputsFormDesigner/AddEditInputsFormDesigner.style';
import { AddEditInputSet } from 'components/Dataset/AddEditForm/AddEditInputsAndGroups/AddEditInputs/AddEditInputSet';

import { FormGroupWrapper, FormThumbnailContainer, TwoColumnRow } from './FormThumbnail.style';

interface Props {
	form: Form;
	variablesData: VariablesData;
	usedInList?: boolean;
}

export function FormThumbnail({ form, variablesData, usedInList }: Props) {
	const { title, titleEnabled, elements, groups, sets, elementsOrder } = form;
	const { variablesMap, variableSetsMap } = variablesData;

	const elementsOrderPreview = useMemo(() => elementsOrder.slice(0, 10), [elementsOrder]);

	return (
		<FormThumbnailContainer usedInList={usedInList}>
			<Wrapper>
				<Container>
					{/* FORM TITLE */}
					{titleEnabled && title && <FormTitle title={title}>{title}</FormTitle>}

					{elementsOrderPreview.map(row => {
						// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
						if (Array.isArray(row)) {
							return (
								<TwoColumnRow
									key={`two-column-${row[0]}-${row[1]}}`}
									className="two-column-row"
								>
									{row.map(elementId => {
										const element = elements[elementId];

										if (!element) return null;

										const { variableRef } = element;

										if (!variableRef) return null;

										return (
											<VariableWrapper key={elementId}>
												<FormVariable
													element={element}
													variable={variablesMap[variableRef]}
												/>
											</VariableWrapper>
										);
									})}
								</TwoColumnRow>
							);
						}
						// ONE COLUMN - (FORM ELEMENT / FORM GROUP / FORM SET)
						else {
							const elementId = row;

							const formSet = sets[elementId];

							// FORM SET
							if (formSet) {
								const { setName } = formSet;

								const variableSet = variableSetsMap[setName];

								const variableSetData: VariableSetData = {
									setName: variableSet.setName,
									setLabel: variableSet.setLabel,
									setData: [],
									identifier: {
										variableName: variableSet.identifier.variableName
									},
									aggregationRules: variableSet.aggregationRules
								};

								return (
									<AddEditInputSet
										key={`variable_set-${variableSetData.setName}`}
										variableSetData={variableSetData}
										entriesCountData={{
											count: null,
											fetched: false
										}}
										mainEntry={null}
										formSet={formSet}
									/>
								);
							}

							const group = groups[elementId];

							// FORM GROUP
							if (group) {
								return (
									<FormGroupWrapper key={elementId}>
										<FormGroup
											formGroup={group}
											elements={elements}
											expanded={false}
											setGroupExpanded={() => null}
											setGroupPaginationData={() => null}
											renderElement={(element, options) => {
												const { elementId, variableRef } = element;

												if (!variableRef) return null;

												return (
													<VariableWrapper
														key={elementId}
														halfWidth={options?.grouped}
													>
														<FormVariable
															element={element}
															variable={variablesMap[variableRef]}
														/>
													</VariableWrapper>
												);
											}}
										/>
									</FormGroupWrapper>
								);
							}

							// FORM ELEMENT
							const element = elements[elementId];

							if (!element) return null;

							const { variableRef } = element;

							// FORM VARIABLE
							if (variableRef) {
								return (
									<VariableWrapper halfWidth key={elementId}>
										<FormVariable
											element={element}
											variable={variablesMap[variableRef]}
										/>
									</VariableWrapper>
								);
							}
							// FORM ELEMENT (ex: SUBTITLE / TEXT / SEPARATOR)
							else {
								return <FormElement key={elementId} element={element} />;
							}
						}
					})}
				</Container>
			</Wrapper>
		</FormThumbnailContainer>
	);
}

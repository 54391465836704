import { deleteVariableSet, ActionTypes } from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useDeleteVariableSet(): Operation<(setName: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.DELETE_VARIABLE_SET);

	function handler(setName: string) {
		if (loading) return;
		if (error) resetError();

		dispatch(deleteVariableSet(setName));
	}

	return [{ loading, error }, handler];
}

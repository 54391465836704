import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';

interface ContainerProps {
	heightOffset: number;
}

export const Container = styled.div<ContainerProps>`
	position: sticky;
	z-index: 2;
	display: flex;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};
	top: ${({ heightOffset }) => heightOffset}rem;
	width: 100%;

	@media print {
		display: none;
	}
`;

export const Views = styled.div`
	margin-left: 2rem;
	display: flex;
	width: 4rem;
	padding: 0.4rem 0 0.4rem;
`;

export const View = styled.div`
	height: 100vh;
	width: 100%;
	position: absolute;

	display: flex;
	flex-direction: column;
	border-left: 0.1rem solid ${Colors.background.disabled};
	background-color: ${Colors.white};
`;

export const ViewHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.6rem;
	background-color: ${Colors.background.disabled};
`;
interface ViewBodyType {
	isEntryHistoryChanged?: boolean;
	disabled?: boolean;
}

export const ViewBody = styled.div<ViewBodyType>`
	height: 100%;
	width: 100%;

	overflow-y: auto;

	${({ isEntryHistoryChanged }) => {
		if (isEntryHistoryChanged) {
			return css`
				margin-bottom: 9rem;
			`;
		}
	}}

	${({ disabled }) => disableableComponent({ disabled })}
`;

export const ButtonWrapper = styled.div<Disabled>`
	width: 100%;
	padding: 0rem 2.5rem 0rem 2rem;
	position: fixed;
	bottom: 3.4rem;
`;

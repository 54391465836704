import { AVATARS_COLORS_X } from 'consts';
import seedrandom from 'seedrandom';
import {
	UserManagement,
	UserActivity,
	EnterpriseAdminRoles,
	// GeneralRolePermissions,
	RolePermissions,
	APIEnterpriseRolePermissions,
	EnterpriseRole
} from 'store/account/enterprise';
import { Role } from 'store/data/roles';
import { GenericMap } from 'types';

export function isUserManagementBatch(
	object: UserManagement | UserActivity | EnterpriseAdminRoles,
	// | GeneralRolePermissions
	key?: keyof UserManagement
): object is UserManagement {
	return (key ?? 'editUser') in object;
}
export function isUserActivityBatch(
	object: UserActivity | EnterpriseAdminRoles
	// | GeneralRolePermissions
): object is UserActivity {
	return 'viewProject' in object;
}
export function isEnterpriseAdminBatch(
	object: EnterpriseAdminRoles
	// | GeneralRolePermissions
): object is EnterpriseAdminRoles {
	return 'createEnterpriseRoles' in object;
}
export function isProjectRoleType(object: Role | EnterpriseRole): object is Role {
	return 'projectId' in object;
}

export function categorizePermissionsForBatch(apiPermissions: APIEnterpriseRolePermissions) {
	const userManagementKeys = Object.keys(defaultPermissions.userManagement);
	const userActivityKeys = Object.keys(defaultPermissions.userActivity);
	const enterpriseAdminRolesKeys = Object.keys(defaultPermissions.enterpriseAdminRoles);
	// const adminRolePermissionsKeys = Object.keys(defaultPermissions.adminRolePermissions);

	const permissions = {
		userManagement: {},
		userActivity: {},
		enterpriseAdminRoles: {}
		// adminRolePermissions: {}
	} as RolePermissions;

	Object.entries(apiPermissions).forEach(([key, value]) => {
		if (userManagementKeys.includes(key as keyof UserManagement)) {
			permissions.userManagement[key as keyof UserManagement] = value;
		} else if (userActivityKeys.includes(key)) {
			permissions.userActivity[key as keyof UserActivity] = value;
		} else if (enterpriseAdminRolesKeys.includes(key)) {
			permissions.enterpriseAdminRoles[key as keyof EnterpriseAdminRoles] = value;
		}
		//  else if (adminRolePermissionsKeys.includes(key)) {
		// 	permissions.adminRolePermissions[key as keyof AdminRolePermissions] = value;
		// }
	});

	return { ...permissions };
}

export function getInitialCardsBatchExpanded(keys: string[]) {
	const booleanMap: GenericMap<boolean> = {};
	keys.forEach(key => {
		if (key === 'adminRolePermissions') return;
		//Initial permissions batch card open
		booleanMap[key] = key === 'userManagement';
	});

	return booleanMap;
}

export const defaultPermissions: RolePermissions = {
	userManagement: {
		addUser: true,
		editUser: true,
		deactivateUser: true,
		reactivateUser: true,
		resetPassword: true
	},
	userActivity: {
		viewProject: true,
		transferOwnership: true
		// privateProjects: true
	},
	enterpriseAdminRoles: {
		createEnterpriseRoles: true
		// viewFeaturePermission: true
	}
	// ,generalRolePermissions: {
	// 	edit: true,
	// 	delete: true,
	// 	assignAndSet: true,
	// 	remove: true
	// }
};

export const newRole = {
	roleId: '-1',
	roleDescription: '',
	roleName: '',
	permissions: defaultPermissions
};

export function getRandomColorByRoleName(roleName?: string) {
	const randomIndex = seedrandom(roleName ?? 'random name')()
		.toString()
		.substring(2);

	// We're taking the first two decimals to use them as an index to select a color
	let formattedIndex = parseInt(
		randomIndex.substring(0, 2) + randomIndex.substring(randomIndex.length),
		10
	);

	if (formattedIndex > AVATARS_COLORS_X.length) formattedIndex = Math.floor(formattedIndex / 10);

	// Generated random could be 0.00xxxxxxxxxxxx
	if (formattedIndex === 0) formattedIndex = 1;

	const avatarColor = AVATARS_COLORS_X[formattedIndex - 1];
	return avatarColor;
}

import {
	GetKaplanMeierResponseV2,
	KaplanMeierResultsV2,
	GetKaplanMeierSingleDataV2,
	GetKaplanMeierGroupedDataV2
} from 'api/data/analyses';

function isKaplanMeierSingleDataResponse(
	data: GetKaplanMeierResponseV2['data']['result']
): data is GetKaplanMeierSingleDataV2 {
	if ('length' in data && data[0].groupIdentifiers) return false;
	return true;
}

export function parseKaplanMeierResponse(
	res: GetKaplanMeierResponseV2['data']['result']
): KaplanMeierResultsV2 {
	if (isKaplanMeierSingleDataResponse(res)) {
		const data = res as GetKaplanMeierSingleDataV2;

		return { data: { single: data } };
	} else {
		const data = res as GetKaplanMeierGroupedDataV2[];

		const result: KaplanMeierResultsV2 = { data: {} };

		data.forEach(groupedData => {
			result.data![groupedData.groupIdentifiers[0].value] = groupedData.values;
		});

		return result;
	}
}

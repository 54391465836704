import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useAlerts } from 'hooks/ui';

interface Props {
	onConfirm: () => void;
	onClose: () => void;
	debouncedMessage: string;
	direction: 'Main Level' | 'Series';
}

export function ConfirmMoveVariablesToSetModal({
	onConfirm,
	onClose,
	direction,
	debouncedMessage
}: Props) {
	const { translate } = useTranslation();
	const { setError } = useAlerts();

	function handleConfirm() {
		debouncedMessage && setError({ message: debouncedMessage });
		onConfirm();
		onClose();
	}

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.terms.confirmation)}
			primary={{
				label: translate(dict => dict.buttons.yes),
				warning: true,
				onClick: handleConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{direction !== 'Series'
					? `${translate(
							dict =>
								dict.variablesPage.confirmMoveVariablesToSetModal.moveInMainLevel
					  )}\n\n`
					: `${translate(
							dict => dict.variablesPage.confirmMoveVariablesToSetModal.moveInSeries
					  )}\n\n`}

				<b>{translate(dict => dict.terms.deleteWarningMessage)}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

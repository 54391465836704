import { ActionPayload, Thunk } from 'store/types';
import {
	APIEnterpriseRolePermissions,
	ActionTypes,
	CreateEnterpriseRoleAction,
	DeactivateUserAccountAction,
	GetAllEnterpriseOwnedProjectsAction,
	DeleteEnterpriseRoleAction,
	EnterpriseRole,
	GetEnterpriseRolesAction,
	NewEnterpriseRole,
	ReactivateUserAccountAction,
	SetAdminRoleIdAction,
	SetAdminRolesSearchTermAction,
	UpdateEnterpriseRoleAction,
	UpdateEnterpriseUserAction,
	SetOrganizationAccessRights
} from './types';
import { NewAPIEnterpriseRole, UpdateApiEnterpriseUser } from 'api/account/enterprise';
import { createActivity } from 'store/ui/activities';
import { UpdateEnterpriseUser } from '../subscription';
import { categorizePermissionsForBatch } from 'components/Account/EnterpriseRoles/helpers';

export const updateEnterpriseUserAction = (
	payload: ActionPayload<UpdateEnterpriseUserAction>
): UpdateEnterpriseUserAction => ({
	type: ActionTypes.UPDATE_ENTERPRISE_USER,
	payload
});

export const updateEnterpriseUser =
	(enterpriseUser: UpdateEnterpriseUser): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_ENTERPRISE_USER,
			dispatch
		});

		try {
			activity.begin();
			const { licenceModel, ...userToUpdate } = enterpriseUser;
			const apiEnterpriseUser: UpdateApiEnterpriseUser = {
				...userToUpdate,
				licenseModel: licenceModel
			};

			const user = await context.api.account
				.enterprise()
				.updateEnterpriseUser(apiEnterpriseUser);

			// we still need to parse the response to replace license with licence.
			// In the future the api should return licence or we should keep it as it comes
			const { licenseModel: licenceModelRes, ...apiUser } = user;

			// since the local user contains more data than the received api user keep the unmodified data
			const localUser = getState().account.subscription.subscriptionUsers.byId[user.userid];
			dispatch(
				updateEnterpriseUserAction({
					user: {
						...localUser,
						...apiUser,
						licenceModel: licenceModelRes
					}
				})
			);
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deactivateUserAccountAction = (
	payload: ActionPayload<DeactivateUserAccountAction>
): DeactivateUserAccountAction => ({
	type: ActionTypes.DEACTIVATE_USER_ACCOUNT,
	payload
});

export const deactivateUserAccount =
	(userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.DEACTIVATE_USER_ACCOUNT,
			dispatch
		});

		try {
			activity.begin();
			const deactivated = await context.api.account
				.enterprise()
				.deactivateUserAccount(userId);

			if (deactivated) dispatch(deactivateUserAccountAction({ userId }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const reactivateUserAccountAction = (
	payload: ActionPayload<ReactivateUserAccountAction>
): ReactivateUserAccountAction => ({
	type: ActionTypes.REACTIVATE_USER_ACCOUNT,
	payload
});

export const reactivateUserAccount =
	(userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.REACTIVATE_USER_ACCOUNT,
			dispatch
		});

		try {
			activity.begin();
			const reactivated = await context.api.account
				.enterprise()
				.reactivateUserAccount(userId);

			if (reactivated) dispatch(reactivateUserAccountAction({ userId }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const getAllEnterpriseOwnedProjectsAction = (
	payload: ActionPayload<GetAllEnterpriseOwnedProjectsAction>
): GetAllEnterpriseOwnedProjectsAction => ({
	type: ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS,
	payload
});

export const getAllEnterpriseOwnedProjects = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS,
		dispatch
	});

	try {
		activity.begin();
		const projects = await context.api.account.enterprise().getAllEnterpriseOwnedProjects();

		dispatch(getAllEnterpriseOwnedProjectsAction({ projects }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const getEnterpriseRolesAction = (
	payload: ActionPayload<GetEnterpriseRolesAction>
): GetEnterpriseRolesAction => ({
	type: ActionTypes.GET_ENTERPRISE_ROLES,
	payload
});

export const getEnterpriseRoles = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ENTERPRISE_ROLES,
		dispatch
	});

	try {
		activity.begin();
		const apiRoles = await context.api.account.enterprise().getEnterpriseRoles();

		const computedRoles: EnterpriseRole[] = apiRoles.enterpriseRoles.map(role => {
			const permissions = categorizePermissionsForBatch(role.permissions);
			return {
				...role,
				permissions: permissions
			};
		});

		if (computedRoles) dispatch(getEnterpriseRolesAction({ roles: computedRoles }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const createEnterpriseRoleAction = (
	payload: ActionPayload<CreateEnterpriseRoleAction>
): CreateEnterpriseRoleAction => ({
	type: ActionTypes.CREATE_ENTERPRISE_ROLE,
	payload
});

export const createEnterpriseRole =
	(role: NewEnterpriseRole): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_ENTERPRISE_ROLE,
			dispatch
		});
		const {
			roleName,
			roleDescription,
			permissions: {
				// adminRolePermissions,
				enterpriseAdminRoles,
				userActivity,
				userManagement
			}
		} = role;

		const apiPermissions: APIEnterpriseRolePermissions = {
			// ...adminRolePermissions,
			...enterpriseAdminRoles,
			...userActivity,
			...userManagement
		};
		const apiNewRole: NewAPIEnterpriseRole = {
			roleName,
			roleDescription,
			permissions: apiPermissions
		};

		try {
			activity.begin();

			const newRoleId = await context.api.account
				.enterprise()
				.createEnterpriseRole(apiNewRole);

			if (newRoleId) {
				const createdRole: EnterpriseRole = {
					...role,
					roleId: newRoleId.toString()
				};
				dispatch(createEnterpriseRoleAction({ role: createdRole }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const updateEnterpriseRoleAction = (
	payload: ActionPayload<UpdateEnterpriseRoleAction>
): UpdateEnterpriseRoleAction => ({
	type: ActionTypes.UPDATE_ENTERPRISE_ROLE,
	payload
});

export const updateEnterpriseRole =
	(role: EnterpriseRole): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_ENTERPRISE_ROLE,
			dispatch
		});

		try {
			activity.begin();

			const {
				roleName,
				roleId,
				roleDescription,
				permissions: {
					// adminRolePermissions,
					enterpriseAdminRoles,
					userActivity,
					userManagement
				}
			} = role;

			const apiPermissions: APIEnterpriseRolePermissions = {
				// ...adminRolePermissions,
				...enterpriseAdminRoles,
				...userActivity,
				...userManagement
			};
			const apiRole = {
				roleName,
				roleId,
				roleDescription,
				permissions: apiPermissions
			};

			const success = await context.api.account.enterprise().updateEnterpriseRole(apiRole);

			if (success) dispatch(updateEnterpriseRoleAction({ role }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const deleteEnterpriseRoleAction = (
	payload: ActionPayload<DeleteEnterpriseRoleAction>
): DeleteEnterpriseRoleAction => ({
	type: ActionTypes.DELETE_ENTERPRISE_ROLE,
	payload
});

export const deleteEnterpriseRole =
	(roleId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_ENTERPRISE_ROLE,
			dispatch
		});

		try {
			activity.begin();

			const success = await context.api.account.enterprise().deleteEnterpriseRole(roleId);

			if (success) dispatch(deleteEnterpriseRoleAction({ roleId }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setAdminRolesSearchTerm = (
	payload: ActionPayload<SetAdminRolesSearchTermAction>
): SetAdminRolesSearchTermAction => ({
	type: ActionTypes.ADMIN_SET_ROLES_SEARCH_TERM,
	payload
});

export const setAdminRoleId = (
	payload: ActionPayload<SetAdminRoleIdAction>
): SetAdminRoleIdAction => ({
	type: ActionTypes.ADMIN_SET_ROLE_ID,
	payload
});

export const setOrganizationAccessRightsAction = (
	payload: ActionPayload<SetOrganizationAccessRights>
): SetOrganizationAccessRights => ({
	type: ActionTypes.SET_ORGANIZATION_ACCESS_RIGHTS,
	payload
});

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column-reverse;
	}
`;

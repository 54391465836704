import {
	AdditionNode,
	CalculationActions,
	SubtractionNode,
	Variable,
	VariableNode
} from 'api/data/variables/types';
import { VariableType, ArithmeticOperator, OperandType } from 'types/data/variables/constants';
import { useMemo, useState } from 'react';
import { SUBTYPE_OPTIONS } from '../types';
import {
	AddButton,
	CalculationColumn,
	CalculationRow,
	ReadonlyField,
	RuleContainer
} from '../CalculatedVariable.style';
import { useTranslation } from 'hooks/store';
import { Dropdown } from 'components/UI/Dropdown';
import { DynamicCalculationInput } from './DynamicCalculationInput';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { SelectItem } from 'types/index';
import { CalculationDeleteIcon } from '../DeleteIcon/CalculationDeleteIcon';
import { ArithmeticNode } from '../../../../../api/data/variables/types';

type Props = {
	node: AdditionNode | SubtractionNode;
	nodePath: string;
	variables: Variable[];
	draftVariableType: VariableType;
	calculationActions: CalculationActions;
};

export function DateOperation({
	node,
	nodePath,
	variables,
	draftVariableType,
	calculationActions
}: Props) {
	const { onAddOperandValue } = calculationActions;
	const isAdditionOperation = ArithmeticOperator.Addition in node;

	const { translate } = useTranslation();

	const [isDeleteHovered, setIsDeleteHovered] = useState(false);

	const { firstField, secondField } = useMemo(
		() => extractFields(node, nodePath),
		[node, nodePath]
	);

	const subtype = useMemo(() => extractSubtype(node), [node]);

	const subTypeSelectItems: SelectItem[] = SUBTYPE_OPTIONS.map(option => ({
		label: translate(() => option.label),
		value: option.value
	}));

	function handleAddOperand(
		type: 'number-input' | 'date-input' | 'number-variable' | 'date-variable',
		index: number
	) {
		const path = `${nodePath}.${index}`;

		switch (type) {
			case 'number-input': {
				onAddOperandValue(path, OperandType.NumberInput);
				break;
			}

			case 'date-input': {
				onAddOperandValue(path, OperandType.DateInput);
				break;
			}

			case 'number-variable': {
				const numberVariable = variables.find(
					v => v.type === VariableType.Float || v.type === VariableType.Integer
				);
				numberVariable &&
					onAddOperandValue(path, OperandType.NumberVariable, {
						var: [numberVariable.name, numberVariable.type]
					});
				break;
			}

			case 'date-variable': {
				const dateVariable = variables.find(
					v => v.type === VariableType.Date || v.type === VariableType.DateTime
				);
				dateVariable &&
					onAddOperandValue(path, OperandType.DateVariable, {
						var: [dateVariable.name, dateVariable.type]
					});
				break;
			}

			default:
				break;
		}
	}

	function dropdownElementsByOperandIndex(index: number) {
		const isNumberOptionDisabled =
			draftVariableType === VariableType.Date &&
			(index == 0
				? secondField.value !== null && !secondField.isDateField
				: firstField.value !== null && !firstField.isDateField);

		const isDateOptionDisabled =
			draftVariableType === VariableType.Date &&
			(index == 0
				? secondField.value !== null && secondField.isDateField
				: firstField.value !== null && firstField.isDateField);

		return [
			<Dropdown.Item
				key={`dateOperation-addInput`}
				title={translate(dict =>
					isAdditionOperation ? dict.terms.numberInput : dict.terms.dateInput
				)}
				onClick={() =>
					handleAddOperand(isAdditionOperation ? 'number-input' : 'date-input', index)
				}
				disabled={isNumberOptionDisabled}
			/>,
			<Dropdown.Item
				key={`dateOperation-addVariable`}
				title={translate(dict =>
					isAdditionOperation ? dict.terms.numberVariable : dict.terms.dateVariable
				)}
				onClick={() =>
					handleAddOperand(
						isAdditionOperation ? 'number-variable' : 'date-variable',
						index
					)
				}
				disabled={isNumberOptionDisabled}
			/>,
			...[
				isAdditionOperation && (
					<Dropdown.Item
						key={`dateOperation-addition-addVariable`}
						title={translate(dict => dict.terms.dateVariable)}
						onClick={() => {
							handleAddOperand('date-variable', index);
						}}
						disabled={isDateOptionDisabled}
					/>
				)
			]
		];
	}

	return (
		<RuleContainer isDeleteHovered={isDeleteHovered}>
			<CalculationRow depth={0}>
				<CalculationColumn>
					<ReadonlyField>{translate(dict => dict.terms.valuesIs)}</ReadonlyField>
				</CalculationColumn>

				<CalculationColumn>
					{firstField.value == null ? (
						<Dropdown
							button
							width={24}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => {
								return (
									<AddButton
										ref={ref}
										onClick={toggle}
										full
										id={`date-add-operand-${
											firstField.path ? firstField.path : nodePath + '.0'
										}`}
									>
										{translate(dict => dict.terms.add)}
									</AddButton>
								);
							}}
						>
							{dropdownElementsByOperandIndex(0)}
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={firstField.type!}
							value={firstField.value}
							valueDepth={1}
							valuePath={firstField.path}
							onChange={value =>
								onAddOperandValue(firstField.path, firstField.type!, value)
							}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
							isDateField={firstField.isDateField}
						/>
					)}
				</CalculationColumn>

				<CalculationColumn>
					<ReadonlyField>
						{translate(dict =>
							isAdditionOperation ? dict.terms.plus : dict.terms.minus
						)}
					</ReadonlyField>
				</CalculationColumn>

				<CalculationColumn>
					{secondField.value == null ? (
						<Dropdown
							button
							width={24}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => {
								return (
									<AddButton
										ref={ref}
										onClick={toggle}
										full
										id={`date-add-operand-${
											secondField.path ? secondField.path : nodePath + '.1'
										}`}
									>
										{translate(dict => dict.terms.add)}
									</AddButton>
								);
							}}
						>
							{dropdownElementsByOperandIndex(1)}
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={secondField.type!}
							value={secondField.value}
							valueDepth={1}
							valuePath={secondField.path}
							onChange={value => {
								onAddOperandValue(secondField.path, secondField.type!, value);
							}}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
							isDateField={secondField.isDateField}
						/>
					)}
				</CalculationColumn>

				<CalculationColumn>
					<ReadonlyField>
						{translate(dict =>
							isAdditionOperation ? dict.terms.addedTo : dict.terms.differenceAs
						)}
					</ReadonlyField>
				</CalculationColumn>

				<CalculationColumn>
					<CreatableSelect
						items={subTypeSelectItems}
						value={subTypeSelectItems.find(item => item.value === subtype)}
						onValueSelected={subType =>
							subType &&
							onAddOperandValue(`${nodePath}.2`, OperandType.String, subType)
						}
						canClear={false}
						id={'date-add-subtype-' + (subtype ? subtype : nodePath + '.2')}
					/>
				</CalculationColumn>
			</CalculationRow>
			<CalculationDeleteIcon
				calculationActions={calculationActions}
				depth={0}
				isDeleteHovered={isDeleteHovered}
				setIsDeleteHovered={setIsDeleteHovered}
				nodePath={nodePath}
			/>
		</RuleContainer>
	);
}

function extractFields(node: AdditionNode | SubtractionNode, nodePath: string) {
	const fields: {
		firstField: {
			value: number | string | VariableNode | null;
			path: string;
			type: OperandType | null;
			isDateField: boolean;
		};
		secondField: {
			value: number | string | VariableNode | null;
			path: string;
			type: OperandType | null;
			isDateField: boolean;
		};
	} = {
		firstField: {
			value: null,
			path: '',
			type: null,
			isDateField: false
		},
		secondField: {
			value: null,
			path: '',
			type: null,
			isDateField: false
		}
	};

	if (ArithmeticOperator.Addition in node) {
		node[ArithmeticOperator.Addition].forEach((value, index) => {
			const newNodePath = `${nodePath}.${index}`;

			if (index >= 2) return;

			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' &&
						  'var' in value &&
						  (value.var[1] === VariableType.Date ||
								value.var[1] === VariableType.DateTime)
						? OperandType.DateVariable
						: OperandType.NumberVariable;

				if (index == 0) {
					fields.firstField = {
						value: !isArithmeticNode(value) ? value : null,
						path: newNodePath,
						type,
						isDateField: type === OperandType.DateVariable
					};
				} else {
					fields.secondField = {
						value: !isArithmeticNode(value) ? value : null,
						path: newNodePath,
						type,
						isDateField: type === OperandType.DateVariable
					};
				}
			} else {
				if (index == 0) {
					fields.firstField = {
						value: null,
						path: newNodePath,
						type: null,
						isDateField: false
					};
				} else {
					fields.secondField = {
						value: null,
						path: newNodePath,
						type: null,
						isDateField: false
					};
				}
			}
		});
	}

	if (ArithmeticOperator.Subtraction in node) {
		node[ArithmeticOperator.Subtraction].forEach((value, index) => {
			const newNodePath = `${nodePath}.${index}`;

			if (index >= 2) return;

			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'object' && 'var' in value
						? OperandType.DateVariable
						: OperandType.DateInput;

				if (index == 0) {
					fields.firstField = {
						value: !isArithmeticNode(value) ? value : null,
						path: newNodePath,
						type,
						isDateField: true
					};
				} else {
					fields.secondField = {
						value: !isArithmeticNode(value) ? value : null,
						path: newNodePath,
						type,
						isDateField: true
					};
				}
			} else {
				if (index == 0) {
					fields.firstField = {
						value: null,
						path: nodePath,
						type: null,
						isDateField: false
					};
				} else {
					fields.secondField = {
						value: null,
						path: nodePath,
						type: null,
						isDateField: false
					};
				}
			}
		});
	}

	return fields;
}

function extractSubtype(node: AdditionNode | SubtractionNode) {
	if (ArithmeticOperator.Addition in node) {
		return node[ArithmeticOperator.Addition][node[ArithmeticOperator.Addition].length - 1];
	}

	if (ArithmeticOperator.Subtraction in node) {
		return node[ArithmeticOperator.Subtraction][
			node[ArithmeticOperator.Subtraction].length - 1
		];
	}

	return null;
}

function isArithmeticNode(
	value: number | string | VariableNode | null | ArithmeticNode
): value is ArithmeticNode {
	return (
		!!value &&
		typeof value === 'object' &&
		(ArithmeticOperator.Addition in value ||
			ArithmeticOperator.Division in value ||
			ArithmeticOperator.Multiplication in value ||
			ArithmeticOperator.Subtraction in value)
	);
}

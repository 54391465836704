import { cloneDeep } from 'lodash';
import { Notification, NotificationBodyType } from 'store/data/notifications';

/**
 * Groups notifications and batches consecutive  ProjectEntryAdd types
 * @param notifications
 */
export function batchNotifications(notifications: Notification[]) {
	const processedNotifications: Notification[] = [];

	notifications.forEach(n => {
		const newNotification = cloneDeep(n);
		if (n.type === NotificationBodyType.ProjectEntryAdd && processedNotifications.length > 0) {
			const lastIndex = processedNotifications.length - 1;
			const lastN = processedNotifications[lastIndex];

			if (lastN.type.includes(NotificationBodyType.ProjectEntryAdd)) {
				if (
					lastN.body.datasetId === n.body.datasetId &&
					lastN.body.userId === n.body.userId &&
					lastN.body.projectId === n.body.projectId
				) {
					newNotification.body.amount = lastN.body.amount ? lastN.body.amount + 1 : 1;
					newNotification.type = NotificationBodyType.ProjectEntryAddBatch;
					processedNotifications.pop();
				}
			}
		}

		if (
			n.type === NotificationBodyType.SubscriptionPlanUpdate &&
			processedNotifications.length > 0
		) {
			const lastIndex = processedNotifications.length - 1;
			const lastN = processedNotifications[lastIndex];
			// Subscription Update
			if (lastN.type.includes(NotificationBodyType.SubscriptionPlanUpdate)) {
				if (lastN.body.to === n.body.to) {
					newNotification.type = NotificationBodyType.SubscriptionPlanUpdateBatch;
					processedNotifications.pop();
				}
			}
		}

		processedNotifications.push(newNotification);
	});
	return processedNotifications;
}

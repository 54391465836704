import { ActionTypes, abortJADBioAnalysisGeneration } from 'store/addons/jadbio';
import { Operation } from 'hooks/store/types';
import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { selectJADBioStopProgress } from 'store/data/analyses';
import { JADBioAnalysisModels } from 'api/data/analyses';

export function useAbortJADBioAnalysisGeneration(
	analysisId: string,
	jadBioModel: JADBioAnalysisModels | null
): Operation<() => void> {
	const dispatch = useDispatch();

	const [{ loading: abortingGeneration, error }, resetError] = useActivity(
		ActionTypes.ABORT_JADBIO_ANALYSIS_GENERATION,
		analysisId
	);

	const stopProgress = useSelector(state =>
		selectJADBioStopProgress(state.data.analyses, analysisId, jadBioModel)
	);

	function handler() {
		if (error) resetError();

		if (jadBioModel) {
			dispatch(abortJADBioAnalysisGeneration(analysisId, jadBioModel));
		}
	}

	return [{ loading: stopProgress || abortingGeneration, error }, handler];
}

import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { Typography, getTypographyCSS, FontWeights } from 'components/UI/Typography';

interface ChangesProps {
	hasChanges: boolean;
}

export const InputWrapper = styled.div<ChangesProps>`
	display: flex;
	width: 100%;
	padding: 1rem;

	/* show tooltip on hover */
	:hover .tooltip__icon {
		visibility: visible;
	}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`}

	@media ${MediaQueries.minWidth.md} {
		width: 50%;
	}
	@media print {
		max-width: 100%;
		width: 100%;
	}
`;

export const RadioWrapper = styled.div<ChangesProps>`
	display: flex;
	width: 100%;
	padding: 1rem;

	${Typography.Caption} {
		/*
			TEST UI - OVERWRITE LABEL FONT TO SEE IF IT LOOKS BETTER
			THIS MIGHT CHANGE IN THE FUTURE IF NEGATIVE FEEDBACK
		 */
		${getTypographyCSS(t => t.Paragraph)}

		font-weight: ${FontWeights.medium};
	}

	/* show tooltip on hover */
	:hover .tooltip__icon {
		visibility: visible;
	}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`};
`;

export const FileWrapper = styled.div<ChangesProps>`
	display: flex;
	width: 100%;
	padding: 1rem;

	/* show tooltip on hover */
	:hover .tooltip__icon {
		visibility: visible;
	}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`};
`;

interface SelectWrapperProps extends ChangesProps {
	longOptions: boolean;
}

export const InputContainer = styled.div`
	width: 100%;
`;

export const SelectWrapper = styled.div<SelectWrapperProps>`
	display: flex;
	justify-content: flex-start;
	padding: 1rem;

	/* show tooltip on hover */
	:hover .tooltip__icon {
		visibility: visible;
	}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`}

	${({ longOptions }) =>
		longOptions
			? css`
					flex-basis: 100%;
			  `
			: css`
					@media ${MediaQueries.minWidth.md} {
						width: 50%;
						max-width: 40rem;
					}
			  `}
`;

import { Colors, Svgs } from 'environment';
import { DeleteButton, DeleteButtonContainer } from '../CalculatedVariable.style';
import type { CalculationActions } from 'api/data/variables/types';
import { useContext } from 'react';
import { HoverContext } from 'helpers/variables/calculatedVariables/HoverContext';

type Props = {
	nodePath: string | null;
	isChild?: boolean;
	depth: number;
	calculationActions: CalculationActions;
	isDeleteHovered: boolean;
	setIsDeleteHovered: (isDeleteHovered: boolean) => void;
	jsonLogicId?: string;
	setDeleteIconIndex?: (jsonLogicId: string) => void;
};

export function CalculationDeleteIcon({
	depth,
	nodePath,
	isChild,
	calculationActions,
	isDeleteHovered,
	setIsDeleteHovered,
	jsonLogicId,
	setDeleteIconIndex
}: Props) {
	const { onDeleteCase, onDeleteOperand } = calculationActions;

	const { hoverPath } = useContext(HoverContext);

	return (
		<DeleteButtonContainer
			depth={depth}
			onMouseOver={() => {
				setIsDeleteHovered(true);
				jsonLogicId !== undefined && setDeleteIconIndex && setDeleteIconIndex(jsonLogicId);
			}}
			onMouseLeave={() => {
				setIsDeleteHovered(false);
				jsonLogicId !== undefined && setDeleteIconIndex && setDeleteIconIndex('');
			}}
			className="DeleteButton"
			id={`delete_button_container_${nodePath}`}
		>
			<DeleteButton
				svg={Svgs.Delete}
				visible={depth == 0 || hoverPath === nodePath}
				colors={{
					color: Colors.text.disabled,
					hover: Colors.primary.normal
				}}
				onClick={() => {
					if (isChild) {
						onDeleteOperand(nodePath);
					} else {
						onDeleteCase(nodePath);
						isDeleteHovered && setIsDeleteHovered(false);
					}
				}}
				zIndex={depth + 1}
				isAtRootLevel={!isChild}
				id={`delete_button_${nodePath}`}
			/>
		</DeleteButtonContainer>
	);
}

import { useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import { Operator, EntryFilter } from 'api/data/filters';
import { HTMLInput, InputType } from 'types/index';

import { SelectOperator } from './SelectOperator';

import { Inputs, Wrapper } from './FilterInputs.style';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { Input } from 'components/UI/Inputs/Input';
import { getInitialValues } from 'helpers/filters';
import {
	useTranslation,
	useAreFiltersValid,
	useFilters,
	useDependenciesFilters,
	useVariables
} from 'hooks/store';
import { useFiltersHelpers } from 'hooks/ui';
import { useSeriesFilters } from 'hooks/store/data/useSeriesFilters/useSeriesFilters';
import { useDeepCompareEffect, useOutsideClick } from 'hooks/utils';
import { TIME_DURATION_OPTIONS_PREFIX_KEY_MAP } from 'timeDurationConsts';
import {
	getMicrosecondsFromTimeDurationString,
	getTimeDurationInputPreview,
	sanitizeTimeDurationInput
} from 'helpers/entries';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';

interface Props {
	filter: EntryFilter;
	disabled: boolean;
	isDependencyFilter?: boolean;
	isSeriesFilter?: boolean;
	tableFilter?: boolean;
	hideFilter: () => void;
	filterContainerRef: React.RefObject<HTMLDivElement>;
	filterDrawerContainerRef: React.RefObject<HTMLDivElement>;
}

export function FilterInputs({
	filter,
	disabled,
	isDependencyFilter,
	isSeriesFilter,
	tableFilter,
	hideFilter,
	filterContainerRef,
	filterDrawerContainerRef
}: Props) {
	const { translate } = useTranslation();

	const areFiltersValid = useAreFiltersValid();
	const [, { updateFilter, invalidateFilter, deleteDatasetFilter }] = useFilters();
	const [, { updateDependenciesFilter, invalidateDependenciesFilter, deleteDependenciesFilter }] =
		useDependenciesFilters();
	const [, { updateSeriesFilter, invalidateSeriesFilter, deleteSeriesFilter }] =
		useSeriesFilters();

	const [
		{
			data: { variablesMap, variableSetsMap }
		}
	] = useVariables({ initial: true, lazy: true });

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const variable =
		variablesMap[filter.columnName] ??
		variablesMap?.[aggregatorVariableNameByAggregationRuleName?.[filter.columnName]];
	const { inputType, parseResults, shouldInvalidateFilter, validate, validationSchema } =
		useFiltersHelpers({
			filterType: filter.filterType,
			subType: filter.filterSubType,
			variable: variable
		});

	// auto blur onMount
	const fromInputRef = useRef<HTMLInput | null>(null);
	const getFromInputRef = (node: HTMLInput | null) => {
		if (node && !fromInputRef.current) {
			fromInputRef.current = node;
		}
	};

	const toInputRef = useRef<HTMLInput | null>(null);
	const getToInputRef = (node: HTMLInput | null) => {
		if (node && !toInputRef.current) {
			toInputRef.current = node;
		}
	};

	useDeepCompareEffect(() => {
		if (fromInputRef && fromInputRef.current && fromInputRef.current.value) {
			fromInputRef.current.focus();
			fromInputRef.current.blur();
		}

		if (toInputRef && toInputRef.current && toInputRef.current.value) {
			toInputRef.current.focus();
			toInputRef.current.blur();
		}
	}, [fromInputRef, toInputRef, filter.operator]);

	const {
		values,
		errors,
		touched,
		handleChange: onChange,
		setFieldValue,
		setFieldTouched
	} = useFormik({
		initialValues: getInitialValues(filter),
		validationSchema,
		validate,
		enableReinitialize: true,
		onSubmit: () => undefined
	});

	const [operator, setOperator] = useState(filter.operator);

	function handleSubmit(customData?: {
		values: { to: string | number; from: string | number };
		operator: Operator;
	}) {
		const initialData = {
			values: getInitialValues(filter),
			operator: filter.operator
		};

		let parsedData: {
			values: { to: string; from: string };
			operator: Operator;
		} | null = null;
		if (customData) {
			parsedData = {
				values: getParsedValues(customData.values),
				operator: customData.operator
			};
		}

		if (isEqual(initialData, parsedData)) return hideFilter();

		update(
			operator,
			parsedData?.values.from ?? typeof values.from === 'number'
				? values.from.toString()
				: values.from,
			values.to
		);
	}

	const isBetween = operator === Operator.Between;
	const isDateTime = inputType === InputType.DateTime;

	function update(operator: Operator, from: string, to: string) {
		if (areFiltersValid && shouldInvalidateFilter(operator, from, to)) {
			if (isDependencyFilter) {
				return invalidateDependenciesFilter({ filterId: filter.itemId });
			}
			if (isSeriesFilter) {
				return invalidateSeriesFilter({ filterId: filter.itemId });
			}

			return invalidateFilter({ filterId: filter.itemId });
		} else {
			const updatedFilter = parseResults({
				filter,
				operator,
				from,
				to
			});

			if (updatedFilter) {
				if (isDependencyFilter) {
					return updateDependenciesFilter({ dependenciesFilter: updatedFilter });
				}
				if (isSeriesFilter) {
					return updateSeriesFilter({ seriesFilter: updatedFilter });
				}
				return updateFilter({ filter: updatedFilter });
			}
		}
		hideFilter();
	}

	useOutsideClick(() => {
		if (!tableFilter) return;
		return hideFilter();
	}, [filterContainerRef, filterDrawerContainerRef]);

	function handleFromDateChange({ formattedDate: from }: { formattedDate: string }) {
		if (!touched.from) setFieldTouched('from');

		setFieldValue('from', from, true);
	}

	function handleToDateChange({ formattedDate: from }: { formattedDate: string }) {
		if (!touched.from) setFieldTouched('to');

		setFieldValue('to', from, true);
	}

	function onTimeDurationChange(e: React.ChangeEvent<HTMLInput>) {
		const { value, name } = e.target;

		const formatLength = variable.durationFormat?.length ?? 0;
		const currentLength = value.split(':').length;
		if (currentLength > formatLength) return;

		setFieldValue(name, value);
	}

	// automatically calculate entry value on blur (eg (hh:mm) 21:60 will become 22h:00m)
	function onTimeDurationBlur(e: React.FocusEvent<HTMLInput>) {
		const { value, name } = e.target;
		if (!variable.durationFormat) return;

		const previewValue = getTimeDurationInputPreview(value, variable.durationFormat);
		setFieldValue(name, previewValue);
	}

	function onTimeDurationFocus(e: React.FocusEvent<HTMLInput>) {
		const { value, name } = e.target;
		const format = variable.durationFormat;
		if (!format) return;

		const sanitizedValue = sanitizeTimeDurationInput(value);
		setFieldValue(name, sanitizedValue);
	}

	// in case it is a series, variable is undefined
	const isTimeDuration = variable && variable.type && variable.type === VariableType.TimeDuration;

	function generateTimeDurationProps() {
		const placeholder =
			variable.durationFormat
				?.map(timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey])
				.join(':') ?? '';
		const labelHint = `(${placeholder})`;

		return {
			placeholder,
			labelHint
		};
	}

	function onClear() {
		if (isSeriesFilter) deleteSeriesFilter(filter.itemId);
		if (isDependencyFilter) deleteDependenciesFilter(filter.itemId);
		if (!isDependencyFilter && !isSeriesFilter) deleteDatasetFilter(filter.itemId);
		hideFilter();
	}

	const canUpdate = useMemo(() => {
		const aggregatorVariableName =
			aggregatorVariableNameByAggregationRuleName[filter.columnName];

		let currentVariable: Variable | undefined;

		if (filter.columnName in aggregatorVariableNameByAggregationRuleName) {
			currentVariable = variablesMap[aggregatorVariableName];
		} else {
			currentVariable = variablesMap[filter.columnName];
		}

		const durationFormat = currentVariable ? currentVariable.durationFormat : null;

		if (operator === Operator.Between) {
			if (
				currentVariable &&
				VariableType.TimeDuration === currentVariable.type &&
				durationFormat
			) {
				const prevFrom = getMicrosecondsFromTimeDurationString(values.from, durationFormat);
				const prevTo = getMicrosecondsFromTimeDurationString(values.from, durationFormat);

				const currFrom = getMicrosecondsFromTimeDurationString(
					!filter.from
						? ''
						: typeof filter.from === 'number'
						? filter.from.toString()
						: filter.from,
					durationFormat
				);
				const currTo = getMicrosecondsFromTimeDurationString(
					!filter.to
						? ''
						: typeof filter.to === 'number'
						? filter.to.toString()
						: filter.to,
					durationFormat
				);

				if (prevFrom === currFrom && prevTo === currTo) return false;
			}

			if (values?.from === filter?.from && values?.to === filter?.to) return false;
		}
		if (
			currentVariable &&
			VariableType.TimeDuration === currentVariable.type &&
			durationFormat
		) {
			const prevFrom = getMicrosecondsFromTimeDurationString(values.from, durationFormat);
			const currFrom = getMicrosecondsFromTimeDurationString(
				!filter.value
					? ''
					: typeof filter.value === 'number'
					? filter.value.toString()
					: filter.value,
				durationFormat
			);

			if (prevFrom === currFrom) return false;
		}
		if (
			(values?.from == filter.value || (!filter.value && !values.from)) &&
			operator === filter.operator
		)
			return false;
		return true;
	}, [values, filter, variablesMap, operator]);

	return (
		<>
			<SelectOperator
				selected={operator}
				type={filter.filterType}
				subtype={filter.filterSubType}
				disabled={disabled}
				onSelect={setOperator}
			/>
			<Inputs>
				<Wrapper>
					{isDateTime ? (
						<DateTimeInput
							value={values.from}
							onChange={from => {
								if (!touched.from) setFieldTouched('from');
								setFieldValue('from', from);
							}}
							options={{
								shouldHaveInitialTime: false,
								error: errors.from,
								label: translate(({ filterInputs }) =>
									isBetween ? filterInputs.fromUpperCase : filterInputs.value
								),
								name: 'from',
								disabled: disabled,
								onBlur: () => {
									if (!touched.from) setFieldTouched('from');
								},
								responsive: true
							}}
						/>
					) : (
						<Input
							ref={getFromInputRef}
							name="from"
							type={inputType}
							value={values.from}
							error={errors.from}
							label={translate(({ filterInputs }) =>
								isBetween ? filterInputs.fromUpperCase : filterInputs.value
							)}
							disabled={disabled}
							onChange={e => {
								if (isTimeDuration) return onTimeDurationChange(e);
								return onChange(e);
							}}
							onDateChange={handleFromDateChange}
							onBlur={e => {
								if (!touched.from) setFieldTouched('from');
								if (isTimeDuration) {
									onTimeDurationBlur(e);
								}
							}}
							onSubmit={handleSubmit}
							{...(isTimeDuration && generateTimeDurationProps())}
							{...(isTimeDuration && { onFocus: onTimeDurationFocus })}
						/>
					)}
				</Wrapper>
				{isBetween && (
					<Wrapper>
						{isDateTime ? (
							<DateTimeInput
								value={values.to}
								onChange={to => {
									if (!touched.to) setFieldTouched('to');
									setFieldValue('to', to, true);
								}}
								options={{
									shouldHaveInitialTime: false,
									error: errors.to,
									label: translate(dict => dict.filterInputs.toUpperCase),
									name: 'from',
									disabled: disabled,
									onBlur: () => {
										if (!touched.to) setFieldTouched('to');
									},
									responsive: true
								}}
							/>
						) : (
							<Input
								ref={getToInputRef}
								name="to"
								type={inputType}
								value={values.to}
								error={errors.to}
								label={translate(dict => dict.filterInputs.toUpperCase)}
								disabled={disabled}
								onChange={e => {
									if (isTimeDuration) return onTimeDurationChange(e);
									return onChange(e);
								}}
								onDateChange={handleToDateChange}
								onBlur={e => {
									if (!touched.from) setFieldTouched('to');
									if (isTimeDuration) {
										onTimeDurationBlur(e);
									}
								}}
								onSubmit={handleSubmit}
								{...(isTimeDuration && generateTimeDurationProps())}
								{...(isTimeDuration && { onFocus: onTimeDurationFocus })}
							/>
						)}
					</Wrapper>
				)}
			</Inputs>
			<Flex marginOffset={{ top: 1.6 }} fullWidth>
				<Flex align={align => align.center}>
					<Button
						onClick={onClear}
						size="small"
						title="Clear"
						variant={variant => variant.link}
					/>
				</Flex>
				<Flex fullWidth justify={j => j.end}>
					<Button
						size="small"
						onClick={hideFilter}
						title={translate(dict => dict.buttons.cancel)}
						variant={variant => variant.secondary}
						marginOffset={{ right: 1.6 }}
					/>
					<Button
						size="small"
						disabled={!canUpdate}
						onClick={handleSubmit}
						title={translate(dict => dict.buttons.apply)}
						variant={variant => variant.primary}
					/>
				</Flex>
			</Flex>
		</>
	);
}

function getParsedValues(values: { to: string | number; from: string | number }) {
	return {
		from: typeof values.from === 'number' ? values.from.toString() : values.from,
		to: typeof values.to === 'number' ? values.to.toString() : values.to
	};
}

import { Colors, Svgs } from 'environment';
import { OrganizationData } from 'store/data/collaborators';

import {
	OrganiationAvatar,
	Footer,
	CollaboratorAvatarsContainer,
	MoreAvatarsContainer
} from './OrganizationCard.style';

import { GenericCard } from '../styles';
import { useTranslation } from 'hooks/store';
import { Icon } from 'components/UI/Icons';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Typography } from 'components/UI/Typography';
import { getMultilineEllipsisCSS } from 'helpers/cssGenerators';

interface Props {
	organizationData: OrganizationData;
	onClick: () => void;
}

export function OrganizationCard({ organizationData, onClick }: Props) {
	const { translate } = useTranslation();
	function getCollaboratorsCount(): string {
		const count = organizationData.collaborators.length;
		const hasOne = count === 1;

		return `${count} ${
			hasOne
				? translate(dict => dict.collaborators.organisationCard.collaborator)
				: translate(dict => dict.collaborators.organisationCard.plural)
		}`;
	}

	function getMoreCollaboratorsPlaceholderData() {
		const visible = organizationData.collaborators.length > 3;
		const count = organizationData.collaborators.length - 2;

		return { visible, count };
	}

	const collaboratorsToRender =
		organizationData.collaborators.length > 3
			? organizationData.collaborators.slice(0, 2)
			: organizationData.collaborators;

	return (
		<GenericCard onClick={onClick} clickable>
			<OrganiationAvatar>
				<Icon
					svg={Svgs.Building}
					customSize={4}
					colors={{ color: Colors.text.disabled }}
					propagate
				/>
			</OrganiationAvatar>

			<Typography.H6
				marginOffset={{ top: 0.4 }}
				title={organizationData.name}
				css={`
					${getMultilineEllipsisCSS(2)}
				`}
			>
				{organizationData.name}
			</Typography.H6>

			<Footer>
				<Typography.Hint
					color={Colors.text.disabled}
					marginOffset={{ bottom: 0.4, right: 0.4 }}
					ellipsis
				>
					{getCollaboratorsCount()}
				</Typography.Hint>

				<CollaboratorAvatarsContainer>
					{collaboratorsToRender.map(collaborator => (
						<Avatar
							key={`collaborator_${collaborator.userId}`}
							userId={collaborator.userId}
							data={{
								userFirstName: collaborator.userFirstName,
								userSirName: collaborator.userSirName
							}}
							size={s => s.s}
						/>
					))}
					{getMoreCollaboratorsPlaceholderData().visible && (
						<MoreAvatarsContainer>{`+${
							getMoreCollaboratorsPlaceholderData().count
						}`}</MoreAvatarsContainer>
					)}
				</CollaboratorAvatarsContainer>
			</Footer>
		</GenericCard>
	);
}

import { createSelector } from 'reselect';
import { isEqual } from 'lodash';

import { State } from './types';

interface SelectOneProps {
	type: string;
	payload?: any;
}

interface SelectMultipleProps {
	types: Array<string | null>;
	payload?: any;
}

/*
 * EXTRACTOR FUNCTIONS
 */
function getActivity({ activities }: State, { type, payload }: SelectOneProps) {
	if (payload !== undefined) {
		return !!activities.find(
			activity => activity.type === type && isEqual(activity.payload, payload)
		);
	}

	return !!activities.find(activity => activity.type === type);
}

function getActivities({ activities }: State, { types, payload }: SelectMultipleProps) {
	if (payload !== undefined) {
		return (
			activities.filter(
				activity => types.includes(activity.type) && isEqual(activity.payload, payload)
			).length > 0
		);
	}

	return activities.filter(activity => types.includes(activity.type)).length > 0;
}

function getError({ errors }: State, { type, payload }: SelectOneProps) {
	if (payload !== undefined) {
		return !!errors.find(error => error.type === type && isEqual(error.payload, payload));
	}

	return !!errors.find(error => error.type === type);
}

function getErrorObject({ errors }: State, { type, payload }: SelectOneProps) {
	if (payload !== undefined) {
		return errors.find(error => error.type === type && isEqual(error.payload, payload));
	}

	return errors.find(error => error.type === type);
}

function getErrors({ errors }: State, { types, payload }: SelectMultipleProps) {
	if (payload !== undefined) {
		return (
			errors.filter(error => types.includes(error.type) && isEqual(error.payload, payload))
				.length > 0
		);
	}

	return errors.filter(error => types.includes(error.type)).length > 0;
}

function getLatestError(state: State) {
	return state.errors.length ? state.errors[state.errors.length - 1] : null;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectActivity = createSelector([getActivity], activity => activity);

export const selectErrorObject = createSelector([getErrorObject], activity => activity);

export const selectActivities = createSelector([getActivities], activities => activities);

export const selectError = createSelector([getError], error => error);

export const selectErrors = createSelector([getErrors], errors => errors);

export const selectLatestError = createSelector([getLatestError], error => error);

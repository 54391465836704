import styled, { css } from 'styled-components';

export const AppendToLicenceContainer = styled.div<{
	alignRight: boolean;
	alignLeft: boolean;
}>`
	display: flex;

	${({ alignLeft }) =>
		!alignLeft &&
		css`
			justify-content: space-between;
		`}

	${({ alignRight }) =>
		!alignRight &&
		css`
			justify-content: flex-end;
		`}
`;

import { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ResizableComponent } from 'components/Account';

import {
	CardBody,
	CardHeader,
	ComponentWrapper,
	DropToBottom,
	DropToLeft,
	DropToRight,
	EmptyLayout,
	LineMarker,
	RelativeContainer,
	RowLineTop,
	RowLineBottom,
	VerticalLineMarker,
	CardReplacement,
	DropCardPlaceholder
} from '../../style';

//MOCKS for cards content
// import { ResponseRateChart } from 'components/Proms';
import { RenderDelayed } from './RenderDelayed';

import { Colors } from 'environment';
import { Typography } from 'components/UI/Typography';
import { AdvancedMenu } from 'components/UI/Interactables/AdvancedMenu';
import { Button } from 'components/UI/Interactables/Button';
import {
	APIWidthSize,
	HeightSizes,
	WidgetHeightSize,
	WidgetWidthSize,
	WidthSizes
} from 'store/data/projectDashboard';
import {
	CardDraggableItem,
	CardDraggableResizableProps,
	CoordinatePayload,
	SetSizePayload,
	WidthSizes as AutomaticWidthSizes
} from 'components/Account/AdminDashboard/AdminDashboardAutomatic';
import { useTranslation } from 'hooks/store';
import { convertAPIWidthToPX } from 'helpers/adminDashboard';

interface Props {
	card: CardDraggableResizableProps;
	index: number;
	widthSizes: AutomaticWidthSizes;
	heightSizes: HeightSizes;
	isResizing: boolean;
	noRowDropzones?: boolean;
	lockCard?: boolean;
	totalCards: number;
	cardIds?: string[];
	cardAutoResize?: string | null;
	group?: {
		index: number;
		groupId: string;
		colIndex?: number;
		rowIndex?: number;
	};
	setElementsOrder: (coordinates: CoordinatePayload) => void;
	setDimensions: (payload: SetSizePayload) => void;
	setIsResizing: (canSplitView: boolean) => void;
	combineLayout: (coordinates: CoordinatePayload) => void;
	setFillLayoutVisible?: () => void;
	setCardAutoResize?: (cardAutoResize: string | null) => void;
	expandCardNewRow: (coordinates: CoordinatePayload) => void;
	removeCard: (index: number) => void;
}

enum DraggableItemType {
	Card = 'card'
}

export function ResizableCardDnDAutomatic({
	card,
	widthSizes,
	heightSizes,
	index,
	isResizing,
	noRowDropzones,
	cardIds,
	lockCard,
	totalCards,
	cardAutoResize,
	group,
	setCardAutoResize,
	setElementsOrder,
	setDimensions,
	setIsResizing,
	combineLayout,
	expandCardNewRow,
	setFillLayoutVisible,
	removeCard
}: Props) {
	const { translate } = useTranslation();
	const [menuVisible, setMenuVisible] = useState(false);

	const elementValueRef = useRef<HTMLDivElement>(null);
	const mainRef = useRef<HTMLDivElement>(null);

	const invisRefRight = useRef<HTMLDivElement>(null);
	const invisRefLeft = useRef<HTMLDivElement>(null);
	const invisRefBottom = useRef<HTMLDivElement>(null);

	const invisRefNewLineTop = useRef<HTMLDivElement>(null);
	const invisRefNewLineBottom = useRef<HTMLDivElement>(null);

	const [{ isDragging, fitsInsideLayout, draggingItself, couldSuggestSplitView }, drag] = useDrag(
		{
			type: DraggableItemType.Card,
			item: (): CardDraggableResizableProps => ({
				index,
				cardId: card.cardId,
				heightSize: card.heightSize,
				cardName: card.cardName,
				widthSize: card.widthSize,
				...(group && { group })
			}),
			collect: monitor => ({
				isDragging:
					cardAutoResize === card.cardId ||
					monitor.isDragging() ||
					monitor.didDrop() ||
					!!(
						cardAutoResize &&
						!monitor.isDragging() &&
						cardIds?.includes(cardAutoResize)
					),
				fitsInsideLayout:
					card.widthSize === monitor.getItem()?.widthSize ||
					(monitor.getItem()?.widthSize === WidgetWidthSize.small &&
						card.widthSize === WidgetWidthSize.medium),
				draggingItself: card.cardId === monitor.getItem()?.cardId,
				couldSuggestSplitView:
					(mainRef?.current?.clientHeight ?? 0) -
						(elementValueRef?.current?.clientHeight ?? 0) >
						300 &&
					card.widthSize !== WidgetWidthSize.big &&
					card.heightSize !== WidgetHeightSize.big
			})
		}
	);

	drag(elementValueRef);

	// DROP FOR LEFT
	const [{ leftHandlerId, isOverCurrentLeft }, dropLeft] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverLeft: monitor.isOver(),
			isOverCurrentLeft: monitor.isOver({ shallow: true }),
			leftHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { index: sourceIndex } = item;

			const destinationIndex = index;
			// debugger;
			setElementsOrder({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group
				}
			});
		}
	});

	// DROP FOR RIGHT
	const [{ rightHandlerId, isOverCurrentRight }, dropRight] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverRight: monitor.isOver(),
			isOverCurrentRight: monitor.isOver({ shallow: true }),
			rightHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { index: sourceIndex } = item;

			const destinationIndex = index;

			setElementsOrder({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group
				}
			});
		}
	});

	// DROP FOR BOTTOM
	const [{ bottomHandlerId, isOverCurrentBottom }, dropBottom] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentBottom: monitor.isOver(),
			bottomHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			combineLayout({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	const [{ newLineTopHandlerId, isOverCurrentNewLineTop }, dropNewLineTop] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentNewLineTop: monitor.isOver(),
			newLineTopHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			expandCardNewRow({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	const [{ newLineBottomHandlerId, isOverCurrentNewLineBottom }, dropNewLineBottom] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentNewLineBottom: monitor.isOver(),
			newLineBottomHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			const destinationIndex = index;

			expandCardNewRow({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group
				}
			});
		}
	});

	dropNewLineTop(invisRefNewLineTop);
	dropNewLineBottom(invisRefNewLineBottom);

	dropLeft(invisRefLeft);
	dropRight(invisRefRight);
	dropBottom(invisRefBottom);

	const canDropUnder =
		(mainRef?.current?.clientHeight ?? 0) - (elementValueRef?.current?.clientHeight ?? 0) > 300;

	const commonChildren = (
		<>
			<DropToLeft
				ref={invisRefLeft}
				data-handler-id={leftHandlerId}
				isResizing={isResizing}
				grouped={!!cardIds}
			>
				{cardIds ? (
					<VerticalLineMarker
						isDraggingInMain={isDragging}
						isHovered={isOverCurrentLeft}
					/>
				) : (
					<LineMarker isHovered={isOverCurrentLeft} isDraggingInMain={isDragging} />
				)}
			</DropToLeft>
			<DropToRight
				isResizing={isResizing}
				ref={invisRefRight}
				data-handler-id={rightHandlerId}
				grouped={!!cardIds}
			>
				{cardIds ? (
					<VerticalLineMarker
						isDraggingInMain={isDragging}
						isHovered={isOverCurrentRight}
					/>
				) : (
					<LineMarker isHovered={isOverCurrentRight} isDraggingInMain={isDragging} />
				)}
			</DropToRight>
		</>
	);

	useEffect(() => {
		setIsResizing(!isDragging);
		return () => setIsResizing(true);
	}, [isDragging]);

	return (
		<>
			<div
				ref={mainRef}
				style={
					lockCard &&
					cardAutoResize !== card.cardId &&
					card.widthSize === WidgetWidthSize.small
						? { position: 'absolute', top: '33.2rem' }
						: undefined
				}
			>
				{index === 0 && !cardIds && !noRowDropzones && (
					<RelativeContainer>
						<RowLineTop
							ref={invisRefNewLineTop}
							data-handler-id={newLineTopHandlerId}
							isHovered={isOverCurrentNewLineTop}
							index={100 + totalCards - index}
						/>
					</RelativeContainer>
				)}
				<ComponentWrapper ref={elementValueRef} isDragging={isDragging}>
					<CardHeader
						menuVisible={menuVisible}
						width={convertAPIWidthToPX(APIWidthSize.one, widthSizes as WidthSizes)}
					>
						<Typography.H6>
							{!isDragging && (
								<>
									{card.cardName} {card.cardId}
								</>
							)}
						</Typography.H6>
						<AdvancedMenu
							className="card-more-icon"
							offset={{ top: 8, right: 8 }}
							items={[
								{ label: translate(({ buttons }) => buttons.edit) },
								{ label: translate(({ buttons }) => buttons.layout) },
								{ label: translate(({ buttons }) => buttons.fullScreen) },
								{
									label: translate(({ buttons }) => buttons.remove),
									handler: () => removeCard(index)
								}
							]}
							active={menuVisible}
							onOpen={() => setMenuVisible(true)}
							onClose={() => setMenuVisible(false)}
						/>
					</CardHeader>
					<CardBody>
						<ResizableComponent
							id={card.cardId}
							widthSize={card.widthSize}
							heightSize={card.heightSize}
							widthSizes={widthSizes}
							cardAutoResize={cardAutoResize}
							setCardAutoResize={setCardAutoResize}
							heightSizes={heightSizes}
							cardIds={cardIds}
							setDimensions={setDimensions}
							resizeVertically
						>
							{isDragging ? (
								<CardReplacement>
									<DropToLeft grouped={!!cardIds}>
										{!cardIds && (
											<LineMarker
												isDraggingInMain={isDragging}
												isHovered={isOverCurrentLeft}
											/>
										)}
									</DropToLeft>
								</CardReplacement>
							) : (
								<>
									{[2, 5, 7].includes(card.index) ? (
										<div>Another mockedcard</div>
									) : ![0, 1, 4].includes(card.index) ? (
										<>
											<Typography.H4 color={Colors.primary.normal}>
												Some non-configured card
											</Typography.H4>
										</>
									) : (
										<Typography.H4>Some other non cofigured card</Typography.H4>
									)}
								</>
							)}
							{/* DRAG N DROP LINE INDICATOR */}
							{commonChildren}
						</ResizableComponent>
					</CardBody>
				</ComponentWrapper>
				<DropToBottom
					visible={!draggingItself && !isResizing && fitsInsideLayout && canDropUnder}
					ref={invisRefBottom}
					data-handler-id={bottomHandlerId}
				>
					<DropCardPlaceholder
						smallCard={card.widthSize === WidgetWidthSize.small}
						isHovered={isOverCurrentBottom}
					/>
				</DropToBottom>
				{isResizing && couldSuggestSplitView && !isDragging && (
					<RenderDelayed>
						<EmptyLayout visible={isResizing && couldSuggestSplitView && !isDragging}>
							<Button
								title={'+'}
								variant={v => v.outline}
								onClick={setFillLayoutVisible}
							/>
						</EmptyLayout>
					</RenderDelayed>
				)}
				{!cardIds && !noRowDropzones && (
					<RelativeContainer>
						<RowLineBottom
							ref={invisRefNewLineBottom}
							data-handler-id={newLineBottomHandlerId}
							isHovered={isOverCurrentNewLineBottom}
							isMaxHeight={couldSuggestSplitView}
							isDragging={isDragging}
						/>
					</RelativeContainer>
				)}
			</div>
		</>
	);
}

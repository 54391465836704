import styled from 'styled-components';

export const ScrollableContainer = styled.div`
	overflow-y: auto;
	max-height: calc(100vh - 16rem);
`;

export const Image = styled.img`
	width: 100%;
`;

import produce from 'immer';
import initialState from './initialState';

import { State, Actions, ActionTypes } from './types';

export default (state: State = initialState, action: Actions) => {
	switch (action.type) {
		case ActionTypes.SET_SERIES_EXPORT_FILE_AND_NAMES: {
			const {
				projectId,
				downloadUrl,
				emptySeriesNames,
				erroredSeriesNames,
				validSeriesNames
			} = action.payload;

			return produce(state, draft => {
				if (projectId && projectId !== '') {
					draft.projectId = projectId;
				}

				if (downloadUrl && downloadUrl !== '') {
					draft.downloadUrl = downloadUrl;
				}

				if (emptySeriesNames && emptySeriesNames.length > 0) {
					draft.empty = emptySeriesNames;
				}

				if (erroredSeriesNames && erroredSeriesNames.length > 0) {
					draft.invalid = erroredSeriesNames;
				}

				if (validSeriesNames && validSeriesNames.length > 0) {
					draft.valid = validSeriesNames;
				}
			});
		}

		case ActionTypes.RESET_EXPORT_WIZARD: {
			return initialState;
		}

		default: {
			return state;
		}
	}
};

import { loadProgressBar } from 'axios-progress-bar';

import './progress.scss';

loadProgressBar({
	easing: 'ease',
	speed: 500,
	trickle: true,
	trickleRate: 0.02,
	trickleSpeed: 800
});

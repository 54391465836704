import { Disabled, disableableComponent } from 'helpers/cssGenerators';
import styled from 'styled-components';

export const Container = styled.div<Disabled>`
	display: flex;
	flex-direction: column;
	width: 100%;

	${({ disabled }) => disableableComponent({ disabled })}
`;

import type { EntryStatus, EntryValues, Entry, EntryValue } from 'store/data/entries';
import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export interface State {
	byId: ById;
	byEntryId: ByEntryId;
	metadata: {
		revisionId: string | null;
		setRevisionId: string | null;
		currentChange: number;
		isClicked: boolean;
	};
	currentRevision: Revision | null;
}

type ById = GenericMap<Revision>;

type ByEntryId = GenericMap<{ revisionIds: string[] }>;

export interface Revision {
	revisionId: string;
	userName: string;
	creationDate: string;
	changes: Changes;
	statusChanges: StatusChanges;
}

interface Changes {
	variableNames: string[];
	list: RevisionChanges[];
	data: EntryValues | null;
}

export interface RevisionChanges {
	variableName: string;
	from: EntryValue;
	to: EntryValue;
}

export interface StatusChanges {
	from: EntryStatus;
	to: EntryStatus;
	data: EntryStatus;
}

export enum ActionTypes {
	// ASYNC
	GET_ENTRY_REVISIONS = 'data/revisions/GET_ENTRY_REVISIONS',
	GET_SET_ENTRY_REVISIONS = 'data/revisions/GET_SET_ENTRY_REVISIONS',
	GET_ENTRY_REVISION_DATA = 'data/revisions/GET_ENTRY_REVISION_DATA',
	GET_SET_ENTRY_REVISION_DATA = 'data/revisions/GET_SET_ENTRY_REVISION_DATA',
	// LOCAL
	SET_REVISION_ID = 'data/revisions/SET_REVISION_ID',
	SET_SET_REVISION_ID = 'data/revisions/SET_SET_REVISION_ID',
	SET_CURRENT_CHANGE = 'data/revisions/SET_CURRENT_CHANGE',
	SET_CURRENT_REVISION = 'data/revisions/SET_CURRENT_REVISION',
	SET_IS_CLICKED = 'data/revisions/SET_IS_CLICKED'
}

/*
	============================
				ASYNC
	============================
*/

export type GetEntryRevisionsAction = Action<
	ActionTypes.GET_ENTRY_REVISIONS,
	{
		entryId: string;
		revisions: Revision[];
	}
>;

export type GetSetEntryRevisionsAction = Action<
	ActionTypes.GET_SET_ENTRY_REVISIONS,
	{
		setName: string;
		revisions: Revision[];
		entryId: string;
	}
>;

export type GetEntryRevisionDataAction = Action<
	ActionTypes.GET_ENTRY_REVISION_DATA,
	{
		entry: Entry;
		entryStatus: EntryStatus;
	}
>;

export type GetSetEntryRevisionDataAction = Action<
	ActionTypes.GET_SET_ENTRY_REVISION_DATA,
	{
		entry: Entry;
		entryStatus: EntryStatus;
	}
>;
/*
	============================
				LOCAL
	============================
*/

export type SetRevisionIdAction = Action<
	ActionTypes.SET_REVISION_ID,
	{
		revisionId: string | null;
	}
>;

export type SetSetRevisionIdAction = Action<
	ActionTypes.SET_SET_REVISION_ID,
	{
		revisionId: string | null;
	}
>;

export type SetCurrentChangeAction = Action<
	ActionTypes.SET_CURRENT_CHANGE,
	{
		currentChange: number;
	}
>;

export type SetCurrentRevisionAction = Action<
	ActionTypes.SET_CURRENT_REVISION,
	{
		currentRevision: Revision | null;
	}
>;

export type SetIsClickedAction = Action<
	ActionTypes.SET_IS_CLICKED,
	{
		isClicked: boolean;
	}
>;

export type Actions =
	// ASYNC
	| GetEntryRevisionsAction
	| GetEntryRevisionDataAction
	| GetSetEntryRevisionsAction
	| GetSetEntryRevisionDataAction
	// LOCAL
	| SetRevisionIdAction
	| SetCurrentChangeAction
	| SetCurrentRevisionAction
	| SetIsClickedAction
	| SetSetRevisionIdAction;

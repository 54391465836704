import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { buildNewBlankTemplate, BuildNewBlankTemplateAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useNewBlankTemplate(): LocalOperation<
	(payload: ActionPayload<BuildNewBlankTemplateAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<BuildNewBlankTemplateAction>) {
		dispatch(buildNewBlankTemplate(payload));
	}

	return handler;
}

import { Colors } from 'environment';
import styled, { css } from 'styled-components';

interface PositionProps {
	alignCenter?: boolean;
	noMargin?: boolean;
	extraMarginTop?: boolean;
}

export const TooltipContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
`;

export const FlexRow = styled.div<PositionProps>`
	display: flex;
	margin-bottom: 1rem;

	${({ alignCenter }) =>
		alignCenter &&
		css`
			align-items: center;
		`}

	${({ noMargin }) =>
		noMargin &&
		css`
			margin-bottom: 0rem;
		`};

	.select {
		background-color: ${Colors.white};
	}
`;

export const FlexCellLabel = styled.div`
	flex: 1;
	width: 18rem;
	margin-right: 1rem;
`;

export const FlexCellIcon = styled.div`
	width: 4rem;
	margin-left: 1rem;
	margin-top: 0.75rem;
`;

export const FlexCellType = styled.div`
	flex: 1;
	margin-left: 1rem;
	position: relative;
	width: 100%;
	min-width: 0;
`;

export const FlexCellNewLabel = styled.div`
	flex: 15;
	margin-right: 1rem;
`;

interface FlexCellNewDataProps {
	stretch?: boolean;
}

export const FlexCellNewData = styled.div<FlexCellNewDataProps>`
	flex: 18;
	max-width: 18rem;

	${({ stretch }) =>
		stretch &&
		css`
			max-width: initial;
		`}
`;

export const FlexCellEmpty = styled.div`
	flex: 16;
`;

export const ErrorContainer = styled.div`
	background-color: ${Colors.lightRed};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: center;
	border-radius: 0.4rem;
	padding: 0.8rem;
	margin: 1.6rem 0;
`;

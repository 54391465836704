import { useState, useMemo } from 'react';
import { FilterIcon } from 'components/UI/Icons';
import { Svgs, Colors } from 'environment';
import { useTranslation } from 'hooks/store';
import type { CheckboxTableFilter } from 'store/ui/tables/types';
import { InputType } from 'types/index';

import { Dropdown, DropdownHeader, DropdownTitle, ResetFilterIcon } from '../TableFilter.style';
import type { TableFilterProps } from '../TableFilter';
import { DropdownScrollContainer, NoResults } from './CheckboxFilter.style';
import { Input } from 'components/UI/Inputs/Input';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Flex } from 'components/UI/Flex';

interface Props extends TableFilterProps {
	filter: CheckboxTableFilter;
}

export function CheckboxFilter({
	filter,
	title,
	width,
	offset,
	isLastColumn,
	onOpen,
	onClose,
	updateFilter,
	resetFilter
}: Props) {
	const { translate } = useTranslation();
	const [forceClose, setForceClose] = useState(false);

	const [term, setTerm] = useState('');

	const checkboxOptions = useMemo(() => {
		return filter.all.filter(item => {
			const trimmedTerm = term.trim().toLowerCase();

			if (!trimmedTerm.length) return true;

			item = item.trim().toLowerCase();

			return item.includes(trimmedTerm);
		});
	}, [filter.all, term]);

	const noResults = useMemo(
		() => term.length > 0 && !checkboxOptions.length,
		[term, checkboxOptions]
	);

	function onFilterClose() {
		setTerm('');
		onClose?.();
	}

	function toggleItem(item: string) {
		let newActiveList = [...filter.active];

		if (newActiveList.includes(item)) {
			newActiveList = newActiveList.filter(i => i !== item);
		} else {
			newActiveList.push(item);
		}

		const newFilter = { ...filter, active: newActiveList };

		updateFilter(newFilter);
	}

	function reset() {
		setTerm('');

		if (filter.active.length) {
			resetFilter();
		}
		onFilterClose();
		setForceClose(true);
	}

	const dropdownOffset = isLastColumn ? offset : undefined;

	return (
		<Dropdown
			usedForCustomisableTable
			width={width}
			forceClose={forceClose}
			offset={dropdownOffset}
			toggleComponent={({ ref, open, toggle }) => (
				<FilterIcon
					dataTestId="filter-icon"
					ref={ref}
					active={open || filter.active.length > 0}
					onClick={toggle}
				/>
			)}
			setForceClose={setForceClose}
			onOpen={onOpen}
			onClose={onFilterClose}
		>
			<>
				<DropdownHeader onClick={e => e.stopPropagation()}>
					<DropdownTitle>{title}</DropdownTitle>
					<ResetFilterIcon
						dataTestId="checkbox-filter-reset-icon"
						svg={Svgs.Delete}
						colors={{
							color: Colors.text.disabled,
							hover: Colors.primary.normal
						}}
						onClick={reset}
					/>
				</DropdownHeader>
				{filter.hasSearch && (
					<Flex paddingOffset={{ x: 1, bottom: 1 }}>
						<Input
							type={InputType.Text}
							value={term}
							placeholder={translate(dict => dict.tableLists.search)}
							onChange={e => setTerm(e.target.value)}
						/>
					</Flex>
				)}
				<DropdownScrollContainer>
					{checkboxOptions.map((item, index) => (
						<Dropdown.Item key={index} onClick={() => toggleItem(item)}>
							<Checkbox
								label={item}
								checked={filter.active.includes(item)}
								onClick={() => toggleItem(item)}
							/>
						</Dropdown.Item>
					))}
					{noResults && (
						<NoResults>
							{translate(dict => dict.tableLists.noFilterSeachResults)}
						</NoResults>
					)}
				</DropdownScrollContainer>
			</>
		</Dropdown>
	);
}

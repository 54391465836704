import { useEffect } from 'react';
import { Svgs } from 'environment';
import { VariableType } from 'types/data/variables/constants';
import { DATA_TYPES_OPTIONS } from 'consts';
import { GridVariableTypes, ItemOptions, Sort } from 'store/data/variables';
import { Container, RightSide, MoreIcon } from './style';
import { Select } from 'components/UI/Interactables/Select';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useVariablesFilters } from 'hooks/store';

interface Props {
	toggleGroups: (flag: boolean) => void;
}

export function TemplateVariablesSubHeader({ toggleGroups }: Props) {
	const { translate } = useTranslation();

	const [{ filters }, { setShowFilter, setTypeFilter, setSort, clearFilters }] =
		useVariablesFilters();

	const allDataTypes = [
		{ label: GridVariableTypes.ALL, value: GridVariableTypes.ALL },
		...DATA_TYPES_OPTIONS
	];

	const showTypeDropdown = ![ItemOptions.GROUPS, ItemOptions.VARIABLE_SETS].includes(
		filters.show
	);

	useEffect(() => {
		return () => {
			clearFilters();
		};
	}, []);

	return (
		<Container>
			<Flex>
				<Flex align={a => a.center} marginOffset={{ right: 1.6 }}>
					<Typography.Caption marginOffset={{ right: 0.8 }}>
						{translate(dict => dict.terms.show)}
					</Typography.Caption>
					<Select
						type={t => t.Tag}
						title={translate(dict => dict.terms[filters.show])}
						items={Object.values(ItemOptions).map(value => ({
							label: translate(dict => dict.terms[value]),
							value
						}))}
						onSelect={item => setShowFilter(item.value as ItemOptions)}
						width={8}
					/>
				</Flex>

				{showTypeDropdown && (
					<Flex>
						<Select
							type={t => t.Tag}
							title={translate(({ variableLabels }) =>
								filters.type === GridVariableTypes.ALL
									? GridVariableTypes.ALL
									: variableLabels[filters.type as keyof typeof variableLabels]
							)}
							items={allDataTypes}
							onSelect={item =>
								setTypeFilter(item.value as VariableType | GridVariableTypes.ALL)
							}
							width={16}
							translateChildren
						/>
					</Flex>
				)}
				<RightSide>
					<Flex>
						<Typography.Caption marginOffset={{ right: 0.8 }}>
							{translate(dict => dict.terms.sort)}
						</Typography.Caption>
						<Select
							type={t => t.Tag}
							title={translate(
								dict => dict.variablesPage.sortVariables[filters.sort]
							)}
							items={Object.values(Sort).map(value => ({
								label: translate(dict => dict.variablesPage.sortVariables[value]),
								value
							}))}
							onSelect={item => setSort(item.value as Sort)}
							width={12}
						/>
					</Flex>
					<Dropdown
						offset={{ right: 0 }}
						shouldScrollIntoView={false}
						toggleComponent={({ ref, open, toggle }) => (
							<MoreIcon ref={ref} svg={Svgs.More} onClick={toggle} active={open} />
						)}
					>
						<Dropdown.Item onClick={() => toggleGroups(true)}>
							{translate(dict => dict.variables.expandGroups)}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => toggleGroups(false)}>
							{translate(dict => dict.variables.collapseGroups)}
						</Dropdown.Item>
					</Dropdown>
				</RightSide>
			</Flex>
		</Container>
	);
}

import { Typography } from 'components/UI/Typography';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';

import { Table } from './ConflictsModal.style';
import { Colors, Svgs } from 'environment';
import { GroupsMap } from 'store/data/variables';
import { ConflictedGroup, ConflictedVariable } from './ConflictsModal';

interface ConflictsGroupRowProps {
	groupData: ConflictedGroup;
	expandedRowsData: {
		expanded: boolean;
		onExpand: () => void;
	};
	groupsMap: GroupsMap;
	renderVariableRow: (variable: ConflictedVariable, index: number) => React.ReactNode;
}

export function ConflictsGroupRow({
	groupData,
	expandedRowsData: { expanded, onExpand },
	groupsMap,
	renderVariableRow
}: ConflictsGroupRowProps) {
	const { groupName, groupVariables } = groupData;
	function getVariableGroupLabel(groupName: string) {
		const group = groupsMap[groupName];

		if (group) return group.groupLabel;
	}

	const groupLabel = getVariableGroupLabel(groupName);

	return (
		<>
			<Table.Row data-scroll-id={groupName}>
				<Table.Cell minWidth={20} maxWidth={30} title={groupLabel}>
					<Flex>
						<Icon
							svg={expanded ? Svgs.ArrowDown : Svgs.ArrowRight}
							size={s => s.m}
							onClick={onExpand}
						/>
						<Icon
							svg={Svgs.Folder}
							size={s => s.m}
							colors={{ color: Colors.chart.orange.dark }}
							marginOffset={{ x: 0.4 }}
							propagate
						/>
						<Typography.Paragraph fontweight={w => w.medium} ellipsis>
							{groupLabel}
						</Typography.Paragraph>
					</Flex>
				</Table.Cell>
				<Table.Cell>-</Table.Cell>
				<Table.Cell>-</Table.Cell>
			</Table.Row>

			{expanded && groupVariables.map(renderVariableRow)}
		</>
	);
}

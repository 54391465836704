import { useState, useRef } from 'react';
import { Group, UpdatedGroup } from 'api/data/variables';
import { InputType } from 'types/index';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { useTranslation, useVariables, useUpdateGroup } from 'hooks/store';
import { useEffectOnce, useCompletedAction } from 'hooks/utils';

interface Props {
	group: Group;
	onClose: () => void;
}

export function UpdateVariableGroupModal({ group, onClose }: Props) {
	const { translate } = useTranslation();

	const { groupName, groupLabel } = group;

	const [draftGroupLabel, setDraftGroupLabel] = useState(groupLabel);

	const [
		{
			data: { groups }
		}
	] = useVariables({ initial: true, lazy: true });

	const [{ loading: updatingGroup, error: errorUpdatingGroup }, updateGroup] = useUpdateGroup();

	const draftGroupLabelTrimmed = draftGroupLabel.trim();
	const isGroupLabelValid = draftGroupLabelTrimmed.length > 0;
	const isGroupLabelUnique = !groups.some(group => group.groupLabel === draftGroupLabelTrimmed);
	const hasChanges = draftGroupLabelTrimmed !== groupLabel;

	const inputRef = useRef<HTMLInputElement>(null);

	/**
	 * Select all text on-open
	 * improve UX - save user time
	 */
	useEffectOnce(() => inputRef.current?.select());

	// CLOSE MODAL AFTER GROUP WAS UPDATED
	useCompletedAction(updatingGroup, errorUpdatingGroup, onClose);

	function handleUpdateGroup() {
		if (updatingGroup || !isGroupLabelValid || !isGroupLabelUnique || !hasChanges) return;

		const updatedGroup: UpdatedGroup = {
			groupName,
			groupLabel: draftGroupLabelTrimmed
		};

		updateGroup(updatedGroup);
	}

	const title = `${translate(dict => dict.terms.update)} ${groupLabel}`;

	const primaryButtonDisabled = !isGroupLabelValid || !isGroupLabelUnique || !hasChanges;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(dict => dict.buttons.update),
				loading: updatingGroup,
				disabled: primaryButtonDisabled,
				onClick: handleUpdateGroup
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Input
				ref={inputRef}
				type={InputType.Text}
				value={draftGroupLabel}
				label={translate(dict => dict.variablesPage.updateVariableGroupModal.label)}
				placeholder={translate(
					dict => dict.variablesPage.updateVariableGroupModal.placeholder
				)}
				error={
					hasChanges && !isGroupLabelUnique
						? translate(dict => dict.terms.errorlabelUnique)
						: undefined
				}
				onChange={e => setDraftGroupLabel(e.target.value)}
				onSubmit={handleUpdateGroup}
				autoFocus
			/>
		</Modal>
	);
}

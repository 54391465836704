import { useEffect, useMemo } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	useMatchEnterpriseAdmin,
	useMatchProjects,
	useMatchProms,
	useMatchTemplateRoles
} from '../../../navigation/matches';
import {
	ActionTypes,
	getProjects,
	getProms,
	selectInitialProjectIds,
	selectProjects,
	selectProms
} from 'store/data/projects';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

interface Options {
	lazy?: boolean;
	checkRoute?: boolean;
}

/**
 * Fetches projects or proms based on the current route and returns the projects or proms that the user is a collaborator or owner of.
 * It's important to note we're filtering out projects that the user is not a collaborator or owner of, e.g. projects added to
 * redux state because an org admin views project collaborators.
 */
export function useScopedProjects(options?: Options): OperationResult<string[]> {
	const dispatch = useDispatch();

	const lazy = !!options?.lazy;
	const checkRoute = options?.checkRoute !== undefined ? options?.checkRoute : true;

	const matchProjects = useMatchProjects();
	const matchEnterpriseAdmin = useMatchEnterpriseAdmin();

	const matchProms = useMatchProms();
	const matchTemplateRoles = useMatchTemplateRoles();

	const areProjectsFetched = useSelector(state => state.data.projects.projects.fetched);

	const arePromsFetched = useSelector(state => state.data.projects.proms.fetched);

	const projectIds = useSelector(state => selectProjects(state.data.projects));

	const promIds = useSelector(state => selectProms(state.data.projects));

	const [{ loading: loadingProjects, error: projectsError }] = useActivity(
		ActionTypes.GET_PROJECTS
	);
	const [{ loading: loadingProms, error: promsError }] = useActivity(ActionTypes.GET_PROMS);

	useEffect(() => {
		if (
			(matchProjects || matchTemplateRoles || matchEnterpriseAdmin) &&
			!projectsError &&
			!areProjectsFetched &&
			!loadingProjects &&
			!lazy
		) {
			dispatch(getProjects());
		}
	}, [matchProjects, projectsError, matchTemplateRoles, matchEnterpriseAdmin]);

	useEffect(() => {
		if (
			(matchProms || matchTemplateRoles) &&
			!promsError &&
			!arePromsFetched &&
			!loadingProms &&
			!lazy
		) {
			dispatch(getProms());
		}
	}, [matchProms, promsError, matchTemplateRoles]);

	let allProjectsOrProms: string[] = [];
	let loading = false;
	let error = false;
	let fetched = false;

	if (matchProjects || matchEnterpriseAdmin) {
		allProjectsOrProms = projectIds;
		loading = loadingProjects;
		error = projectsError;
		fetched = areProjectsFetched;
	}

	if (matchProms) {
		allProjectsOrProms = promIds;
		loading = loadingProms;
		error = promsError;
		fetched = arePromsFetched;
	}

	function handler() {
		if (checkRoute) {
			if (matchProjects || matchEnterpriseAdmin) {
				dispatch(getProjects());
			}

			if (matchProms) {
				dispatch(getProms());
			}
		} else dispatch(getProjects());
	}

	/**
	 * initialProjectIds is here used as a proxy for "what projects the user is a collaborator or owner of",
	 * and used for hiding projects that the user is not a collaborator or owner of, e.g. projects added to
	 * redux state because an org admin views project collaborators.
	 */
	const initialProjectIds = useSelector(state => selectInitialProjectIds(state.data.projects));

	const projectIdsWhereUserIsOwnerOrCollaborator = useMemo(
		() =>
			allProjectsOrProms.filter(projectId => {
				if (!initialProjectIds) {
					return;
				}

				return initialProjectIds.includes(projectId);
			}),
		[allProjectsOrProms, selectInitialProjectIds]
	);

	return [{ loading, error, data: projectIdsWhereUserIsOwnerOrCollaborator, fetched }, handler];
}

import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import {
	Columns,
	LineOptions,
	AlignOptions,
	PlotNumericAnalysisV2,
	DataModel,
	AnalysisStatisticAggregationType
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { SelectItem, GenericMap, Nullable } from 'types/index';
import { Svgs } from 'environment';
import { ScatterOptions } from './PlotNumericV2';
import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { TabsWithIcon } from 'components/UI/Tabs';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { RadioButton } from 'components/UI/Interactables/RadioButton';

import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useAnalysisActiveTab,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce, useMemoOnce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';
import { VariableType } from 'types/data/variables/constants';

const ANALYSIS_STATISTIC_AGGREGATION_VALUES = [
	AnalysisStatisticAggregationType.Mean,
	AnalysisStatisticAggregationType.MeanSD,
	AnalysisStatisticAggregationType.MeanCI,
	AnalysisStatisticAggregationType.MeanRange,
	AnalysisStatisticAggregationType.Median,
	AnalysisStatisticAggregationType.MedianCI,
	AnalysisStatisticAggregationType.MedianRange,
	AnalysisStatisticAggregationType.MedianIQR
];

interface Props extends ScatterOptions {
	analysis: PlotNumericAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
	onLineSelect: (option: LineOptions) => void;
	onAlignSelect: (value: AlignOptions) => void;
}

export function PlotNumericConfigV2({
	analysis,
	variablesDataSelectItems,
	loading,
	line,
	align,
	onLineSelect,
	onAlignSelect
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		id,
		input: { variables, dataModel, series }
	} = analysis;

	const [values, setValues] = useState(variables);
	const [
		{ isConfigPanelOpen, isParamsOpen, isChartTypesOpen },
		{ openParameters, openChartTypes }
	] = useAnalysisConfigPanel(analysis.id);
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const [activeColumn] = useAnalysesActiveColum();

	const [activeTab, setActiveTab] = useAnalysisActiveTab(id);

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values)) {
				const updatedAnalysis: PlotNumericAnalysisV2 = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	// RESET GROUP 2 VARIABLE ON SCATTER CHART
	useEffect(() => {
		if (activeTab === 2 && analysis.input.variables.groupingVariable) {
			updateAnalysis({
				analysis: {
					...analysis,
					input: {
						...analysis.input,
						variables: {
							...analysis.input.variables,
							groupingVariable: null
						}
					},
					output: {
						...analysis.output,
						grouping: false
					}
				} as PlotNumericAnalysisV2
			});

			setValues({
				...values,
				groupingVariable: null
			});
		}
	}, [activeTab, values]);

	const { selectItemsMap: mainSelectItemsMap, selectItems: mainSelectItems } =
		variablesDataSelectItems;

	const [{ data: variablesData }] = useVariables();

	const { variableSets } = variablesData;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});
	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const errorBars = useMemoOnce(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		ANALYSIS_STATISTIC_AGGREGATION_VALUES.forEach(errorBar => {
			const item = {
				label: translateErrorBars(errorBar),
				value: errorBar
			};

			items.push(item);
			itemsMap[item.value] = item;
		});
		return { items, itemsMap };
	});

	function translateErrorBars(type: AnalysisStatisticAggregationType) {
		switch (type) {
			case AnalysisStatisticAggregationType.Mean:
				return translate(({ analysis }) => analysis.generic.errorBars.mean);
			case AnalysisStatisticAggregationType.MeanSD:
				return translate(({ analysis }) => analysis.generic.errorBars.meanSD);
			case AnalysisStatisticAggregationType.MeanCI:
				return translate(({ analysis }) => analysis.generic.errorBars.meanCI);
			case AnalysisStatisticAggregationType.MeanRange:
				return translate(({ analysis }) => analysis.generic.errorBars.meanRange);
			case AnalysisStatisticAggregationType.Median:
				return translate(({ analysis }) => analysis.generic.errorBars.median);
			case AnalysisStatisticAggregationType.MedianCI:
				return translate(({ analysis }) => analysis.generic.errorBars.medianCI);
			case AnalysisStatisticAggregationType.MedianRange:
				return translate(({ analysis }) => analysis.generic.errorBars.medianRange);
			case AnalysisStatisticAggregationType.MedianIQR:
				return translate(({ analysis }) => analysis.generic.errorBars.medianIQR);
			default:
				return translate(({ analysis }) => analysis.generic.errorBars.mean);
		}
	}

	const selectItemsMap = {
		...seriesSelectItemsMap,
		...mainSelectItemsMap
	};

	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}

		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [mainSelectItems, seriesSelectItems, series]);

	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: PlotNumericAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: {
					categoryVariable: null,
					numericVariable: null,
					errorBar: AnalysisStatisticAggregationType.Mean,
					groupingVariable: null
				}
			}
		};

		setValues({
			...newAnalysis.input.variables
		});
		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = {
			...analysis,
			input: {
				...analysis.input,
				series,
				variables: {
					categoryVariable: null,
					numericVariable: null,
					errorBar: AnalysisStatisticAggregationType.Mean,
					groupingVariable: null
				}
			}
		};

		setValues({
			...newAnalysis.input.variables
		});

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input
				}
			}
		});
	}

	function onSelectVariable(
		key: keyof PlotNumericAnalysisV2['input']['variables'],
		variableName: Nullable<string>
	) {
		if (!variableName) {
			setValues(values => ({
				...values,
				[key]: null
			}));
			return;
		}
		const setName = variablesLocation[variableName]?.setName;
		setValues(values => ({
			...values,
			[key]: {
				name: variableName,
				...(setName ? { series: setName } : {})
			}
		}));
	}

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as PlotNumericAnalysisV2} />
			)}

			{/* Chart type */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.ChartType
				)}
				open={!isChartTypesOpen}
				onToggle={() =>
					openChartTypes({ analysisId: analysis.id, chartType: !isChartTypesOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<TabsWithIcon
						labels={[
							translate(
								({ analysis }) => analysis.analyses.plotNumericV2.view.columns
							),
							translate(({ analysis }) => analysis.analyses.plotNumericV2.view.box),
							translate(
								({ analysis }) => analysis.analyses.plotNumericV2.view.scatter
							)
						]}
						icons={[Svgs.GroupedView, Svgs.BoxPlotView, Svgs.ScatterView]}
						startIndex={activeTab}
						onChangeTabs={active => setActiveTab({ analysisId: id, activeTab: active })}
						maxWidth={36}
					/>
				</Gap>
			</CollapsibleCard>

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.correlationsV2.config.series
							)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.plotNumericV2.config.numeric
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.numericVariable?.name ?? '']}
						onValueSelected={numericVariable =>
							onSelectVariable('numericVariable', numericVariable)
						}
						isItemDisabled={item =>
							[values.categoryVariable?.name, values.groupingVariable?.name].includes(
								item.value
							)
						}
						disabled={!dataModel || (dataModel !== DataModel.main && !series)}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.plotNumericV2.config.groupOne
						)}
						items={groupingSelectItems}
						isItemDisabled={item =>
							[values.groupingVariable?.name ?? ''].includes(item.value)
						}
						disabled={!dataModel || (dataModel !== DataModel.main && !series)}
						value={selectItemsMap[values.categoryVariable?.name ?? '']}
						onValueSelected={categoryVariable =>
							onSelectVariable('categoryVariable', categoryVariable)
						}
						canClear={false}
					/>
					{/* PLOT */}
					{activeTab === 0 && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.plotNumericV2.config.plot
							)}
							items={errorBars.items}
							disabled={
								!values.errorBar ||
								!dataModel ||
								(dataModel !== DataModel.main && !series)
							}
							value={errorBars.itemsMap[values.errorBar ?? ''] ?? errorBars.items[0]}
							onValueSelected={errorBar =>
								errorBar &&
								setValues(state => ({
									...state,
									errorBar: errorBar as AnalysisStatisticAggregationType
								}))
							}
							canClear={false}
						/>
					)}
					{/* GROUPING */}
					{activeTab !== 2 && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.plotNumericV2.config.groupTwo
							)}
							placeholder={translate(
								({ analysis }) => analysis.analyses.plotNumericV2.config.noGrouping
							)}
							isItemDisabled={item =>
								[values.categoryVariable?.name].includes(item.value) ||
								[values.numericVariable?.name].includes(item.value)
							}
							disabled={!dataModel || (dataModel !== DataModel.main && !series)}
							items={groupingSelectItems}
							value={selectItemsMap[values?.groupingVariable?.name ?? ''] ?? null}
							onValueSelected={groupingVariable =>
								onSelectVariable('groupingVariable', groupingVariable)
							}
						/>
					)}
					{activeTab === 2 && (
						<>
							<RadioGroup
								items={[AlignOptions.Aligned, AlignOptions.Scattered]}
								selected={align}
								onSelect={item => onAlignSelect(item as AlignOptions)}
							/>
							<Gap marginGap={{ bottom: 0.4 }}>
								<RadioButton
									label={translate(
										({ analysis }) =>
											analysis.analyses.plotNumericV2.config.line.noLine
									)}
									selected={line === 0}
									onSelect={() => onLineSelect(LineOptions.None)}
								/>
								<RadioButton
									label={translate(
										({ analysis }) =>
											analysis.analyses.plotNumericV2.config.line.lineAtMean
									)}
									selected={line === 1}
									onSelect={() => onLineSelect(LineOptions.Mean)}
								/>
								<RadioButton
									label={translate(
										({ analysis }) =>
											analysis.analyses.plotNumericV2.config.line.lineAtMedian
									)}
									selected={line === 2}
									onSelect={() => onLineSelect(LineOptions.Median)}
								/>
							</Gap>
						</>
					)}
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting
				analysis={analysis}
				isEnabled={
					!!analysis.input.variables.categoryVariable ||
					!!analysis.input.variables.groupingVariable
				}
			/>
		</ConfigContainer>
	);
}

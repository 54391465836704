import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, NewEnterpriseRole, createEnterpriseRole } from 'store/account/enterprise';

export function useCreateEnterpriseRole(): Operation<(role: NewEnterpriseRole) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CREATE_ENTERPRISE_ROLE);

	function handler(role: NewEnterpriseRole) {
		dispatch(createEnterpriseRole(role));
	}

	return [{ loading, error }, handler];
}

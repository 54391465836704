import { useEffect, useMemo } from 'react';

import { TimeCourseAnalysisV2, TimeCourseGroupedV2 } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { TimeCourseChart } from './TimeCourseChart';
import { VariablesData } from 'store/data/variables';
import { cloneDeep } from 'lodash';
import { AnalysisChartContainer } from '../../UI';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import {
	useProject,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: TimeCourseAnalysisV2;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function TimeCourseView({ analysis, variablesData, exporting, loading }: Props) {
	const [{ data: project }] = useProject();

	const {
		id,
		options: { configPanel, chartLegend = false },
		input: { variables, series },
		output: { dataset, grouping }
	} = analysis;

	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		series: series,
		omitVariables: []
	});

	const { variablesMap } = variablesData;
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const { parsedDataset } = useMemo(() => {
		if (dataset?.data) {
			let parsedDataset = cloneDeep(dataset.data);

			if (analysis.options.showCategoryLabels && grouping) {
				parsedDataset = parsedDataset as TimeCourseGroupedV2;
				parsedDataset.groupedTimeCourse.forEach(g => {
					g.group.groupValue =
						(variables.groupVariables?.[0] &&
							mapVariableCategoryValueToLabel(
								g.group.groupValue,
								variablesMap[variables.groupVariables[0].name]
							)) ??
						g.group.groupValue;
				});
			}

			return { parsedDataset };
		}
		return { parsedDataset: null };
	}, [dataset, variablesMap, analysis.options.showCategoryLabels]);

	const timeCourseChartId = ZingChartExportTypes.TimeCourse + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				timeCourseChartId,
				getExportFileName(
					ExportFileNames.TimeCourseChartV2,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: selectItemsLabelMap[variables.timeVariable?.name ?? ''],
		labelY: selectItemsLabelMap[variables.numericVariable?.name ?? '']
	};

	const legendHeader = variables.groupVariables?.[0]
		? selectItemsLabelMap[variables.groupVariables?.[0].name]
		: undefined;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.TimeCourseV2}>
				{parsedDataset && (
					<>
						<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
							<TimeCourseChart
								data={parsedDataset}
								grouping={grouping}
								isLegendEnabled={chartLegend}
								errorBar={variables.statistic ?? undefined}
								scalesLabels={scalesLabels}
								legendHeader={legendHeader}
								loading={loading}
								//@ts-ignore TODO
								timeWindowSize={analysis.input.variables.timeUnit}
								isConfigPanelOpen={configPanel.open}
							/>
						</AnalysisChartContainer>
						<AnalysisExportWrapper>
							<TimeCourseChart
								id={timeCourseChartId}
								data={parsedDataset}
								grouping={grouping}
								errorBar={variables.statistic ?? undefined}
								isForExport
								isLegendEnabled
								scalesLabels={scalesLabels}
								legendHeader={legendHeader}
								loading={loading}
								//@ts-ignore TODO
								timeWindowSize={analysis.input.variables.timeUnit}
								isConfigPanelOpen={configPanel.open}
							/>
						</AnalysisExportWrapper>
					</>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}

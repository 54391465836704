import { useState } from 'react';
import * as yup from 'yup';
import { initButtonProps } from 'helpers/buttons';
import { DueTimeUnit, Status } from 'store/data/statuses';
import { InputType, DateLabels } from 'types/index';
import { StatusColorPicker } from './StatusColorPicker';
import { Container, Row, InputError } from './StatusModal.style';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Dropdown } from 'components/UI/Dropdown';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { useTranslation, useStatuses, useCreateStatus, useUpdateStatus } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, useMemoOnce, useKeyPress } from 'hooks/utils';

const DATA_TIME_UNITS = [
	{
		label: DateLabels.Hours,
		value: DueTimeUnit.Hours
	},
	{
		label: DateLabels.Days,
		value: DueTimeUnit.Days
	},
	{
		label: DateLabels.Weeks,
		value: DueTimeUnit.Weeks
	}
];

interface Props {
	status: Status;
	onClose: () => void;
	shouldShowUpdateAllEntries: boolean;
}

export function StatusModal({ status, onClose, shouldShowUpdateAllEntries }: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { statuses }
		}
	] = useStatuses({ lazy: true });

	const [{ loading: creatingStatus, error: errorCreatingStatus }, createStatus] =
		useCreateStatus();
	const [{ loading: updatingStatus, error: errorUpdatingStatus }, updateStatus] =
		useUpdateStatus();

	useCompletedAction(creatingStatus, errorCreatingStatus, onClose);
	useCompletedAction(updatingStatus, errorUpdatingStatus, onClose);

	const [updateExistingEntries, setUpdateExistingEntries] = useState(false);

	const isEditMode = status.name.length > 0;
	const statusLabels = statuses.map(v => v.label).filter(s => s !== status.label);

	const validationSchema = yup.object().shape(
		{
			label: yup
				.string()
				.trim()
				.required(translate(dict => dict.statuses.statusModal.labelError))
				.lowercase()
				.notOneOf(
					statusLabels,
					translate(dict => dict.statuses.statusModal.uniqueLabelError)
				),
			dueTimeAmount: yup
				.string()
				.nullable()
				.when('dueTimeUnit', {
					is: (dueTimeUnit: string | null) => !!dueTimeUnit,
					then: yup
						.string()
						.nullable()
						.required(translate(dict => dict.statuses.statusModal.dueTimeAmountError))
				}),
			dueTimeUnit: yup
				.string()
				.nullable()
				.when('dueTimeAmount', {
					is: (dueTimeAmount: string | null) => !!dueTimeAmount,
					then: yup
						.string()
						.nullable()
						.required(translate(dict => dict.statuses.statusModal.dueTimeUnitError))
				})
		},
		[['dueTimeAmount', 'dueTimeUnit']]
	);

	const initialValues: Status = useMemoOnce(() => ({
		...status,
		isDefault: !!status.isDefault
	}));

	const {
		Form,
		handleSubmit,
		register,
		setValue,
		getValues,
		trigger,
		Controller,
		control,
		errors,
		isDirty: formHasChanges,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues,
		validationSchema
	});

	const onFormSubmit = handleSubmit(() => {
		trimFields();

		const values = { ...getValues() };

		if (canSubmitForm && !(creatingStatus || updatingStatus)) {
			if (isEditMode) return updateStatus(values, updateExistingEntries);

			createStatus(values, updateExistingEntries);
		}
	});

	function onDueTimeChange(value: string) {
		// allow numbers only
		value = value.replace(/[^0-9]+/g, '');
		// trim 0's from the begining
		value = value.replace(/[^1-9]*/, '');

		// parse `""` to `null`
		const parsedValue = value || null;

		setValue('dueTimeAmount', parsedValue, {
			shouldDirty: true,
			shouldValidate: true
		});
		trigger('dueTimeUnit');
	}

	function trimFields() {
		const values = { ...getValues() };

		setValue('label', values.label.trim());
		setValue('description', values.description.trim());
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(dict => (isEditMode ? dict.buttons.update : dict.buttons.add)),
			loading: creatingStatus || updatingStatus,
			disabled: !canSubmitForm,
			onClick: onFormSubmit
		};

		buttons.neutral = {
			label: translate(dict => dict.buttons.cancel),
			onClick: onClose
		};

		if (isEditMode && !formHasChanges) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(dict => dict.buttons.done),
				onClick: onClose
			};
		}
	});

	useKeyPress(
		{
			onEnterKeyPress: isEditMode && !formHasChanges ? onClose : onFormSubmit
		},
		{
			listen: !buttonProps.primary.loading
		}
	);

	return (
		<Modal
			title={translate(dict =>
				isEditMode
					? dict.statuses.statusModal.updateTitle
					: dict.statuses.statusModal.addTitle
			)}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			onClose={onClose}
			visible
			close
		>
			<Form onSubmit={onFormSubmit}>
				<Container>
					<Input
						{...register('label')}
						type={InputType.Text}
						label={translate(dict => dict.statuses.statusName)}
						error={errors.label?.message}
						autoFocus={!isEditMode}
						onBlur={trimFields}
						required
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('description')}
						rows={4}
						type={InputType.Textarea}
						label={translate(dict => dict.statuses.statusDescription)}
						onBlur={trimFields}
					/>
					<Spacer size={s => s.s} />
					<Row>
						<Controller
							name="dueTimeAmount"
							control={control}
							render={({ field: { value } }) => (
								<Input
									type={InputType.Text}
									value={value ?? ''}
									label={translate(dict => dict.statuses.dueTimeAmount)}
									onChange={e => onDueTimeChange(e.target.value)}
								/>
							)}
						/>
						<Controller
							name="dueTimeUnit"
							control={control}
							render={({ field: { value } }) => (
								<Dropdown
									title={
										value
											? translate(
													dict => dict.dateLabels[value as DueTimeUnit]
											  )
											: ''
									}
									shouldScrollIntoView
									label={translate(dict => dict.statuses.dueTimeUnit)}
									button
								>
									<Dropdown.Item
										title={'-'}
										onClick={() => {
											setValue('dueTimeUnit', null, {
												shouldDirty: true,
												shouldValidate: true
											});
											trigger('dueTimeAmount');
										}}
									/>
									{DATA_TIME_UNITS.map(dataType => (
										<Dropdown.Item
											key={dataType.value}
											title={translate(() => dataType.label)}
											onClick={() => {
												setValue('dueTimeUnit', dataType.value, {
													shouldDirty: true,
													shouldValidate: true
												});
												trigger('dueTimeAmount');
											}}
										/>
									))}
								</Dropdown>
							)}
						/>
					</Row>
					{/* DUE TIME ERROR */}
					<InputError
						error={errors.dueTimeAmount?.message || errors.dueTimeUnit?.message}
					/>

					<Spacer size={s => s.s} />

					<Controller
						name="isDefault"
						control={control}
						render={({ field: { value } }) => (
							<>
								<Checkbox
									label={translate(dict => dict.statuses.statusModal.isDefault)}
									checked={!!value}
									onClick={() => {
										setValue('isDefault', !value, {
											shouldDirty: true,
											shouldValidate: true
										});

										if (value) setUpdateExistingEntries(false);
									}}
								/>

								{!initialValues.isDefault && shouldShowUpdateAllEntries && (
									<>
										<Spacer size={s => s.xs} />

										<Checkbox
											label={translate(
												dict =>
													dict.statuses.statusModal.updateExistingEntries
														.label
											)}
											description={translate(
												dict =>
													dict.statuses.statusModal.updateExistingEntries
														.description
											)}
											checked={updateExistingEntries}
											disabled={!value}
											onClick={() =>
												setUpdateExistingEntries(state => !state)
											}
										/>
									</>
								)}
							</>
						)}
					/>

					<Spacer size={s => s.m} />

					<Controller
						name="statusColor"
						control={control}
						render={({ field: { value } }) => (
							<StatusColorPicker
								color={value}
								onColorChange={color =>
									setValue('statusColor', color, {
										shouldDirty: true,
										shouldValidate: true
									})
								}
							/>
						)}
					/>
					<Spacer size={s => s.xl} />
				</Container>
			</Form>
		</Modal>
	);
}

import { useSelector } from 'hooks/utils';
import { LoginSteps, setLoginStep, SetLoginStepAction } from 'store/auth';
import { LocalOperationResult } from '../types';
import { useDispatch } from 'hooks/utils';
import { ActionPayload } from 'store/types';

export function useLoginStep(): LocalOperationResult<
	LoginSteps | null,
	(payload: ActionPayload<SetLoginStepAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.auth.loginStep);

	function handler(payload: ActionPayload<SetLoginStepAction>) {
		dispatch(setLoginStep(payload));
	}

	return [data, handler];
}

import produce from 'immer';

import { withMemo } from 'helpers/HOCs';
import { useTranslation } from 'hooks/store';
import {
	GeneralPermissionFlags,
	GeneralPermissions as GeneralPermissionsType
} from 'store/data/collaborators';

import { Container } from './GeneralPermissions.style';
import { Table } from 'components/UI/Table';
import { Flex } from 'components/UI/Flex';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Gap } from 'components/UI/Gap';
import { InfoMessage } from 'components/UI/InfoMessage';
import { DataTestId } from 'tests/consts';

interface Props {
	permissions: GeneralPermissionsType;
	disabled?: boolean;
	isProjectOwner?: boolean;
	hasOrganizationExportAccess: boolean;
	onChange: (permissions: GeneralPermissionsType) => void;
}

function Component({
	permissions,
	hasOrganizationExportAccess,
	disabled,
	isProjectOwner,
	onChange
}: Props) {
	const { translate } = useTranslation();

	const defaultDepFlags = [
		GeneralPermissionFlags.accessProjectRead,
		GeneralPermissionFlags.accessVariablesRead
	];

	function handleUpdate(flag: GeneralPermissionFlags, depFlags: GeneralPermissionFlags[] = []) {
		if (disabled) return;

		const updatedPermissions = produce(permissions, draft => {
			// GET NEW VALUE
			const updatedFlagValue = !draft[flag];

			// TOGGLE FLAG WITH NEW VALUE
			draft[flag] = updatedFlagValue;

			// UPDATE DEPENDENCY FLAGS WITH NEW FLAG VALUE
			depFlags.forEach(depFlag => (draft[depFlag] = updatedFlagValue));
		});

		onChange(updatedPermissions);
	}

	return (
		<Container disabled={disabled}>
			<Table.Responsive horizontalScroll noDesign>
				<Table fullWidth noDesign>
					<Table.Head>
						<Table.Row>
							<Table.Column empty />
							<Table.Column noWrap textCenter>
								{translate(dict => dict.sharingPermissions.headerViewData)}
							</Table.Column>
							<Table.Column noWrap textCenter>
								{translate(dict => dict.sharingPermissions.headerEditData)}
							</Table.Column>
							<Table.Column noWrap textCenter>
								{translate(dict => dict.sharingPermissions.headerAnalyse)}
							</Table.Column>
							<Table.Column noWrap textCenter>
								{translate(dict => dict.sharingPermissions.headerDeleteData)}
							</Table.Column>
						</Table.Row>
					</Table.Head>

					<Table.Body>
						{/* OWN DATA */}
						<Table.Row>
							<Table.Cell paddingLeft={0} noWrap>
								{translate(dict => dict.sharingPermissions.bodyOwnData)}
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											handleUpdate(
												GeneralPermissionFlags.accessEntriesOwnonlyRead,
												defaultDepFlags
											);
										}}
										on={permissions.accessEntriesOwnonlyRead}
										disabled={
											isProjectOwner ||
											permissions.accessEntriesOrgRead ||
											permissions.accessEntriesAllRead
										}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() =>
											handleUpdate(
												GeneralPermissionFlags.accessEntriesOwnonlyWrite,
												defaultDepFlags
											)
										}
										on={permissions.accessEntriesOwnonlyWrite}
										disabled={
											isProjectOwner ||
											permissions.accessEntriesOrgWrite ||
											permissions.accessEntriesAllWrite
										}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() =>
											handleUpdate(
												GeneralPermissionFlags.accessAnalysisOwnonly,
												defaultDepFlags
											)
										}
										on={permissions.accessAnalysisOwnonly}
										disabled={
											isProjectOwner ||
											permissions.accessAnalysisOrg ||
											permissions.accessAnalysisAll
										}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() =>
											handleUpdate(
												GeneralPermissionFlags.accessEntriesOwnonlyDelete,
												defaultDepFlags
											)
										}
										on={permissions.accessEntriesOwnonlyDelete}
										disabled={
											isProjectOwner ||
											permissions.accessEntriesOrgDelete ||
											permissions.accessEntriesAllDelete
										}
									/>
								</Flex>
							</Table.Cell>
						</Table.Row>

						{/* CENTER DATA */}
						<Table.Row>
							<Table.Cell paddingLeft={0} noWrap>
								{translate(dict => dict.sharingPermissions.bodyCenterData)}
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [...defaultDepFlags];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessEntriesOwnonlyRead
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessEntriesOrgRead,
												depFlags
											);
										}}
										on={permissions.accessEntriesOrgRead}
										disabled={permissions.accessEntriesAllRead}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [...defaultDepFlags];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessEntriesOwnonlyWrite
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessEntriesOrgWrite,
												depFlags
											);
										}}
										on={permissions.accessEntriesOrgWrite}
										disabled={permissions.accessEntriesAllWrite}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [...defaultDepFlags];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessAnalysisOwnonly
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessAnalysisOrg,
												depFlags
											);
										}}
										on={permissions.accessAnalysisOrg}
										disabled={permissions.accessAnalysisAll}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [...defaultDepFlags];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessEntriesOwnonlyDelete
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessEntriesOrgDelete,
												depFlags
											);
										}}
										on={permissions.accessEntriesOrgDelete}
										disabled={permissions.accessEntriesAllDelete}
									/>
								</Flex>
							</Table.Cell>
						</Table.Row>

						{/* ALL DATA */}
						<Table.Row>
							<Table.Cell paddingLeft={0} noWrap>
								{translate(dict => dict.sharingPermissions.bodyAllData)}
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [
												GeneralPermissionFlags.accessEntriesOrgRead,
												...defaultDepFlags
											];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessEntriesOwnonlyRead
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessEntriesAllRead,
												depFlags
											);
										}}
										on={permissions.accessEntriesAllRead}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [
												GeneralPermissionFlags.accessEntriesOrgWrite,
												...defaultDepFlags
											];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessEntriesOwnonlyWrite
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessEntriesAllWrite,
												depFlags
											);
										}}
										on={permissions.accessEntriesAllWrite}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [
												GeneralPermissionFlags.accessAnalysisOrg,
												...defaultDepFlags
											];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessAnalysisOwnonly
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessAnalysisAll,
												depFlags
											);
										}}
										on={permissions.accessAnalysisAll}
									/>
								</Flex>
							</Table.Cell>
							<Table.Cell width={12} minWidth={8}>
								<Flex justify={j => j.center}>
									<Switch
										onChange={() => {
											const depFlags = [
												GeneralPermissionFlags.accessEntriesOrgDelete,
												...defaultDepFlags
											];

											if (!isProjectOwner) {
												depFlags.push(
													GeneralPermissionFlags.accessEntriesOwnonlyDelete
												);
											}

											handleUpdate(
												GeneralPermissionFlags.accessEntriesAllDelete,
												depFlags
											);
										}}
										on={permissions.accessEntriesAllDelete}
									/>
								</Flex>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Table.Responsive>

			<Spacer size={s => s.l} />

			<Gap marginGap={{ bottom: 1.6 }} notLastChild>
				<Switch
					label={translate(dict => dict.sharingPermissions.allowExportData)}
					onChange={() => handleUpdate(GeneralPermissionFlags.accessExport)}
					disabled={
						isProjectOwner ||
						(hasOrganizationExportAccess === false && !permissions.accessExport)
					}
					on={permissions.accessExport}
					dataTestId={DataTestId.GeneralPermissionsSwitch}
				/>
				<Switch
					label={translate(dict => dict.sharingPermissions.allowAccessPersonalData)}
					onChange={() => {
						const depFlags = [];

						if (permissions.accessPersonalData) {
							depFlags.push(GeneralPermissionFlags.accessVariablesWrite);
							depFlags.push(GeneralPermissionFlags.accessVariablesRead);
						}

						handleUpdate(GeneralPermissionFlags.accessPersonalData, depFlags);
					}}
					disabled={isProjectOwner}
					on={permissions.accessPersonalData}
				/>
				<Switch
					label={translate(dict => dict.sharingPermissions.allowVariablesEditing)}
					onChange={() =>
						permissions.accessPersonalData &&
						handleUpdate(GeneralPermissionFlags.accessVariablesWrite, [
							GeneralPermissionFlags.accessVariablesRead
						])
					}
					disabled={isProjectOwner || !permissions.accessPersonalData}
					on={permissions.accessVariablesWrite}
				/>
				<Switch
					label={translate(dict => dict.sharingPermissions.allowProjectEditing)}
					onChange={() =>
						handleUpdate(GeneralPermissionFlags.accessProjectWrite, [
							GeneralPermissionFlags.accessProjectRead
						])
					}
					disabled={isProjectOwner}
					on={permissions.accessProjectWrite}
				/>
			</Gap>

			<Spacer size={s => s.l} />

			<InfoMessage message={translate(dict => dict.rolesPage.roleModal.permissionsMessage)} />
		</Container>
	);
}

export const GeneralPermissions = withMemo(Component);

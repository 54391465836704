import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { selectIsAccountFetched, getAccount, ActionTypes } from 'store/account/subscription';
import { AccountDetails } from 'store/account/subscription';
import { LanguageType, StorageKeys } from 'types/index';

interface AccountData {
	username: string | null;
	details: AccountDetails | null;
	languageCode: LanguageType;
	isProfileIncomplete: boolean;
}

export function useAccount(): OperationResult<AccountData> {
	const dispatch = useDispatch();

	const fetched = useSelector(state => selectIsAccountFetched(state.account.subscription));
	const details = useSelector(state => state.account.subscription.accountDetails);
	const username = useSelector(state => state.auth.username);

	const languageCode = details
		? details.userPreferences.chosenLanguageCode
		: (localStorage.getItem(StorageKeys.UserLanguage) as LanguageType) ?? LanguageType.English;

	const isProfileIncomplete =
		details !== null &&
		(!details.userFirstName ||
			!details.userSirName ||
			!details.department ||
			!details.phoneNumber ||
			!details.workplace);

	const [{ loading, error }] = useActivity(ActionTypes.GET_ACCOUNT);

	const data: AccountData = {
		username,
		details,
		languageCode,
		isProfileIncomplete
	};

	function handler() {
		dispatch(getAccount());
	}

	return [{ data, loading, error, fetched }, handler];
}

import produce from 'immer';
import { nanoid as generate } from 'nanoid';

import { DragAndDropTypes } from 'types/index';

import initialState from './initialState';
import {
	ActionTypes,
	Actions,
	State,
	////////
	Template,
	TemplateGroupInterface,
	DraggableVariable,
	UserAccessInterface,
	ProjectAccessInterface
} from './types';

import { Actions as ProjectsAction, ActionTypes as ProjectActionTypes } from '../projects/types';
import { arrayUtils } from 'helpers/arrays';
import { buildTemporaryTemplate } from 'helpers/templates';

export default (state: State = initialState, action: Actions | ProjectsAction): State => {
	switch (action.type) {
		case ProjectActionTypes.SET_PROJECT_ID: {
			return produce(state, draft => {
				draft.templatesById = initialState.templatesById;
				draft.editableTemplatesIds = initialState.editableTemplatesIds;
				draft.sharedWithUser = initialState.sharedWithUser;
				draft.sharedWithProject = initialState.sharedWithProject;
				draft.sharedWithPublic = initialState.sharedWithPublic;
				draft.metadata = initialState.metadata;
			});
		}

		case ActionTypes.SET_PAGE_VIEW: {
			const { pageView } = action.payload;

			return produce(state, draft => {
				draft.metadata.pageView = pageView;
			});
		}

		case ActionTypes.SET_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.searchTerm = term;
			});
		}

		case ActionTypes.SET_TEMPLATE_ID: {
			const { templateId } = action.payload;

			return produce(state, draft => {
				draft.templateId = templateId;
			});
		}

		case ActionTypes.SET_SOURCE_CREATE_TEMPLATE_ID: {
			const { templateId } = action.payload;

			return produce(state, draft => {
				draft.sourceCreateTemplateId = templateId;
			});
		}

		case ActionTypes.RENAME_TEMPLATE: {
			const { templateId, newName } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				templatesById[templateId].templateName = newName;
			});
		}

		case ActionTypes.SET_TEMPLATE_DESCRIPTION: {
			const { templateId, description } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				templatesById[templateId].description = description;
			});
		}

		case ActionTypes.BUILD_NEW_BLANK_TEMPLATE: {
			const { owner } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				const tempId = `${'temporaryId-'}${generate()}`;
				const newTemplate: Template = {
					templateId: tempId,
					variables: [],
					groups: [],
					templateName: '',
					elementsOrder: [],
					isSingleVariableTemplate: false,
					accessPublicRead: false,
					owner,
					totalVariableNumber: 0,
					description: '',
					accessWrite: true,
					projectsSharedWith: { initial: [], current: [] },
					usersSharedWith: { initial: [], current: [] }
				};
				templatesById[tempId] = newTemplate;
				const newArray = [tempId, ...draft.editableTemplatesIds];
				draft.editableTemplatesIds = newArray;
			});
		}

		case ActionTypes.ADD_VARIABLE_TO_TEMPLATE: {
			const {
				variable,
				templateId: destinationTemplateId,
				destinationIndex
			} = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				const destinationTemplate = templatesById[destinationTemplateId];

				if (destinationTemplate) {
					const draggableId = `${variable.name}${
						DragAndDropTypes.DraggableTemplateVariable
					}${generate()}`;
					const computedVariable = { ...variable, draggableId };
					destinationTemplate.variables.push(computedVariable);
					const newElementsOrder = arrayUtils.insert(
						destinationTemplate.elementsOrder,
						destinationIndex,
						draggableId
					);
					destinationTemplate.elementsOrder = newElementsOrder;
					destinationTemplate.totalVariableNumber++;
				}
			});
		}

		case ActionTypes.ADD_GROUP_TO_TEMPLATE: {
			const { group, templateId, destinationIndex } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;
				const template = templatesById[templateId];

				if (template) {
					const { variables: groupedVariables } = group;
					const variablesToDeleteFromUngrouped: string[] = [];

					groupedVariables.forEach(variable =>
						variablesToDeleteFromUngrouped.push(variable.name)
					);

					let computedVariables: DraggableVariable[] = template.variables;
					let computedElementsOrder: string[] = template.elementsOrder;
					let numberOfVariablesToAdd = groupedVariables.length;

					variablesToDeleteFromUngrouped.forEach(variableToDelete => {
						computedVariables = computedVariables.filter(
							variable => variable.name !== variableToDelete
						);
						computedElementsOrder = computedElementsOrder.filter(element => {
							const elementName = element.split(
								DragAndDropTypes.DraggableTemplateVariable
							)[0];
							if (elementName !== variableToDelete) {
								return element;
							} else {
								numberOfVariablesToAdd--;
								return null;
							}
						});
					});

					template.variables = computedVariables;
					template.elementsOrder = computedElementsOrder;

					template.totalVariableNumber =
						template.totalVariableNumber + numberOfVariablesToAdd;

					const draggableId = `${group.groupName}${
						DragAndDropTypes.DraggableTemplateGroup
					}${generate()}`;
					const groupedVariablesCopy = group.variables.map(variable => {
						return {
							...variable,
							draggableId: `${variable.name}${
								DragAndDropTypes.DraggableTemplateVariable
							}${generate()}-groupedVariable`
						};
					});
					const groupCopy: TemplateGroupInterface = {
						...group,
						variables: groupedVariablesCopy,
						draggableId
					};
					const newElementsOrder = arrayUtils.insert(
						template.elementsOrder,
						destinationIndex,
						draggableId
					);
					template.elementsOrder = newElementsOrder;
					template.groups.push(groupCopy);
				}
			});
		}

		case ActionTypes.REMOVE_FROM_TEMPLATE: {
			const { templateId, elementId } = action.payload;

			const fromGroup = elementId.includes(DragAndDropTypes.DraggableTemplateGroup);

			return produce(state, draft => {
				const { templatesById } = draft;
				const template = templatesById[templateId];
				const { variables, groups, totalVariableNumber, elementsOrder } = template;

				if (!fromGroup) {
					template.variables = variables.filter(
						variable => variable.draggableId !== elementId
					);
					templatesById[templateId].totalVariableNumber = totalVariableNumber - 1;
				} else {
					const group = groups.find(group => group.draggableId === elementId);
					if (group) {
						template.groups = groups.filter(
							filteredGroup => filteredGroup?.draggableId !== group?.draggableId
						);
						const numberOfVariables = group.variables.length;
						templatesById[templateId].totalVariableNumber =
							totalVariableNumber - numberOfVariables;
					}
				}

				const computedList = elementsOrder.filter(id => id !== elementId);

				template.elementsOrder = computedList;
			});
		}

		case ActionTypes.UPDATE_TEMPLATE: {
			const { templateId, shareTemplateGlobally } = action.payload;

			return produce(state, draft => {
				if (typeof shareTemplateGlobally !== 'undefined') {
					draft.templatesById[templateId].accessPublicRead = shareTemplateGlobally;

					if (shareTemplateGlobally) {
						draft.sharedWithPublic.ids.push(templateId);
					} else {
						draft.sharedWithPublic.ids = draft.sharedWithPublic.ids.filter(
							id => id !== templateId
						);
					}
				}
			});
		}

		case ActionTypes.RESET_LISTS_OF_SHARED_WITH: {
			const { templateId } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;
				const template = templatesById[templateId];
				template.projectsSharedWith = { initial: [], current: [] };
				template.usersSharedWith = { initial: [], current: [] };
			});
		}

		case ActionTypes.RESET_PUBLISHED_UNSUPPORTED: {
			const { variablesToDelete, avoidUnnecessaryApiCall } = action.payload;

			return produce(state, draft => {
				const { metadata } = draft;
				metadata.publishedWithUnsupported = false;

				if (variablesToDelete.length || avoidUnnecessaryApiCall) {
					metadata.publishedWithUnsupported = true;
				}
			});
		}

		case ActionTypes.DELETE_TEMPLATE: {
			const { templateId } = action.payload;

			return produce(state, draft => {
				const { editableTemplatesIds, sharedWithPublic } = draft;
				delete draft.templatesById[templateId];
				draft.editableTemplatesIds = editableTemplatesIds.filter(id => id !== templateId);
				draft.sharedWithPublic.ids = sharedWithPublic.ids.filter(id => id !== templateId);
			});
		}

		case ActionTypes.GET_TEMPLATE_LIST_OF_SHARED_WITH: {
			const { templateId, users, projects } = action.payload;

			return produce(state, draft => {
				draft.templatesById[templateId].usersSharedWith = {
					initial: [],
					current: []
				};
				draft.templatesById[templateId].usersSharedWith.initial = users;
				draft.templatesById[templateId].usersSharedWith.current = users;
				draft.templatesById[templateId].projectsSharedWith = {
					initial: [],
					current: []
				};
				draft.templatesById[templateId].projectsSharedWith.initial = projects;
				draft.templatesById[templateId].projectsSharedWith.current = projects;
			});
		}

		case ActionTypes.PUBLISH_TEMPLATE: {
			const { templateId, removedTempId } = action.payload;

			return produce(state, draft => {
				const { templatesById, editableTemplatesIds } = draft;

				if (templatesById) {
					const template = draft.templatesById[removedTempId];
					delete templatesById[removedTempId];
					const indexOfRemovedId = editableTemplatesIds.indexOf(removedTempId);
					draft.editableTemplatesIds[indexOfRemovedId] = templateId;
					template.templateId = templateId;
					templatesById[templateId] = template;

					draft.templateId = templateId;
				}
			});
		}

		case ActionTypes.CREATE_AND_IMPORT_TEMPLATE: {
			const { template, removedTemplateId, newTemplateId } = action.payload;

			return produce(state, draft => {
				if (template) {
					delete draft.templatesById[removedTemplateId];
					const indexOfRemovedId = draft.editableTemplatesIds.indexOf(removedTemplateId);
					draft.editableTemplatesIds[indexOfRemovedId] = newTemplateId;
					template.templateId = newTemplateId;
					draft.templatesById[newTemplateId] = template;

					draft.templateId = newTemplateId;
				}
			});
		}

		case ActionTypes.GET_TEMPLATES: {
			const { templates } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				Object.values(templates).forEach(template => {
					const { templateId } = template;

					if (templatesById) {
						draft.templatesById[templateId] = template;
					}
				});
			});
		}

		case ActionTypes.GET_TEMPLATE: {
			const { template } = action.payload;

			return produce(state, draft => {
				const { templateId } = template;
				const { templatesById } = draft;
				if (templatesById) {
					draft.templatesById[templateId] = template;
				}
			});
		}

		case ActionTypes.SHARE_WITH_INSTANCE: {
			const { instanceId, usersShare } = action.payload;

			return produce(state, draft => {
				const { templateId, templatesById } = draft;
				if (!templateId) return;
				const template = templatesById[templateId];
				const { usersSharedWith, projectsSharedWith } = template;
				const userAddedAlready = usersSharedWith.current.find(
					user => user.userId === instanceId
				);
				const projectAddedAlready = projectsSharedWith.current.find(
					project => project.projectId.toString() === instanceId
				);
				if (usersShare) {
					if (userAddedAlready) {
						userAddedAlready.accessRead = true;
					} else {
						if (!projectAddedAlready) {
							const newUserWithAccess: UserAccessInterface = {
								userId: instanceId,
								accessRead: true,
								accessWrite: false
							};
							const newUsersListWithAccess = [
								newUserWithAccess,
								...usersSharedWith.current
							];
							template.usersSharedWith.current = newUsersListWithAccess;
						}
					}
				} else {
					if (projectAddedAlready) {
						projectAddedAlready.accessRead = true;
					} else {
						if (!userAddedAlready) {
							const newProjectWithAccess: ProjectAccessInterface = {
								projectId: Number(instanceId),
								accessRead: true,
								accessWrite: false
							};
							const newProjectsListWithAccess = [
								newProjectWithAccess,
								...projectsSharedWith.current
							];
							template.projectsSharedWith.current = newProjectsListWithAccess;
						}
					}
				}
			});
		}

		case ActionTypes.UNSHARE_WITH_INSTANCE: {
			const { instanceId } = action.payload;

			return produce(state, draft => {
				const { templateId, templatesById } = draft;
				if (!templateId) return;
				const template = templatesById[templateId];
				const { usersSharedWith, projectsSharedWith } = template;
				const userWithNoAccess = usersSharedWith.current.find(
					user => user.userId === instanceId
				);
				const projectWithNoAccess = projectsSharedWith.current.find(
					project => project.projectId.toString() === instanceId
				);
				if (userWithNoAccess) {
					userWithNoAccess.accessRead = false;
				}
				if (projectWithNoAccess) {
					projectWithNoAccess.accessRead = false;
				}
			});
		}

		case ActionTypes.GET_PROJECT_TEMPLATES: {
			const { templates } = action.payload;

			return produce(state, draft => {
				Object.values(templates).forEach(template => {
					const { templateId } = template;
					const existingTemplate =
						draft.templatesById[templateId] &&
						!!draft.templatesById[templateId].templateId;

					if (!existingTemplate) {
						draft.templatesById[templateId] = template;
					}
					draft.sharedWithProject.ids.push(templateId);
				});
				draft.sharedWithProject.fetched = true;
			});
		}

		case ActionTypes.GET_USER_TEMPLATES: {
			const { templates, currentUserId } = action.payload;

			return produce(state, draft => {
				Object.values(templates).forEach(template => {
					const { templateId, owner, accessWrite } = template;
					const existingTemplate =
						draft.templatesById[templateId] &&
						!!draft.templatesById[templateId].templateId;

					if (!existingTemplate) {
						draft.templatesById[templateId] = template;
					}

					if (currentUserId === owner || accessWrite) {
						draft.editableTemplatesIds.push(templateId);
					} else {
						draft.sharedWithUser.ids.push(templateId);
					}
				});
				draft.sharedWithUser.fetched = true;
			});
		}

		case ActionTypes.GET_PUBLIC_TEMPLATES: {
			const { templates } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				Object.values(templates).forEach(template => {
					const { templateId } = template;

					if (templatesById) {
						draft.templatesById[templateId] = template;
						draft.templatesById[templateId].accessPublicRead = true;
						draft.sharedWithPublic.ids.push(templateId);
					}
				});
				draft.sharedWithPublic.fetched = true;
			});
		}

		case ActionTypes.CREATE_TEMPLATE_FROM_ALL: {
			const { variables, groups, owner } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;

				const newTemplate = buildTemporaryTemplate({ variables, groups, owner });
				templatesById[newTemplate.templateId] = newTemplate;
				const newArray = [newTemplate.templateId, ...draft.editableTemplatesIds];
				draft.editableTemplatesIds = newArray;
			});
		}

		case ActionTypes.SORT_INSIDE_TEMPLATE: {
			const { sourceIndex, destinationIndex, templateId } = action.payload;

			return produce(state, draft => {
				const { templatesById } = draft;
				if (templatesById[templateId]) {
					const template = templatesById[templateId];
					let elementsOrder = [...template.elementsOrder];

					// Work-around for always ensure variables ocupy first indexes, followed by groups
					// To be removed in the future
					if (template.groups.length) {
						let movedLowerThanShould = null;
						let movedHigherThanShould = null;

						const draggedId = elementsOrder[sourceIndex];

						const groupDragged = draggedId.includes(
							DragAndDropTypes.DraggableTemplateGroup
						);

						if (groupDragged) {
							const firstGroupIndex = elementsOrder.findIndex(element =>
								element.includes(DragAndDropTypes.DraggableTemplateGroup)
							);
							movedLowerThanShould = firstGroupIndex > destinationIndex;
							if (movedLowerThanShould) {
								elementsOrder = arrayUtils.move(
									elementsOrder,
									sourceIndex,
									firstGroupIndex
								);
							}
						} else {
							const lastVariableIndex =
								elementsOrder.findIndex(
									element =>
										!element.includes(
											DragAndDropTypes.DraggableTemplateVariable
										)
								) - 1;
							movedHigherThanShould = lastVariableIndex < destinationIndex;
							if (movedHigherThanShould) {
								elementsOrder = arrayUtils.move(
									elementsOrder,
									sourceIndex,
									lastVariableIndex
								);
							}
						}

						if (!movedLowerThanShould && !movedHigherThanShould) {
							elementsOrder = arrayUtils.move(
								elementsOrder,
								sourceIndex,
								destinationIndex
							);
						}
					}
					// Normal sort without ensure of structure variables first, groups after
					else {
						elementsOrder = arrayUtils.move(
							elementsOrder,
							sourceIndex,
							destinationIndex
						);
					}
					template.elementsOrder = elementsOrder;
				}
			});
		}

		case ActionTypes.SHARE_TEMPLATE: {
			const { users, projects } = action.payload;

			return produce(state, draft => {
				const { templateId, templatesById } = draft;
				if (!templateId) return;

				const template = templatesById[templateId];
				if (users) {
					Object.values(users).forEach(user => {
						const alreadyInList = template.usersSharedWith.current.find(
							userSharedWith => userSharedWith.userId === user.userId
						);
						!alreadyInList &&
							template.usersSharedWith.current.push({
								userId: user.userId,
								accessRead: true,
								accessWrite: false
							});
					});
				}
				if (projects) {
					Object.values(projects).forEach(project => {
						const alreadyInList = template.projectsSharedWith.current.find(
							projectSharedWith => projectSharedWith.projectId === project.projectId
						);
						!alreadyInList &&
							template.projectsSharedWith.current.push({
								projectId: project.projectId,
								accessRead: true,
								accessWrite: false
							});
					});
				}
			});
		}

		default: {
			return state;
		}
	}
};

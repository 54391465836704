import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, removeDomains } from 'store/account/admin';

export function useRemoveDomains(): Operation<(domains: string[]) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.REMOVE_DOMAINS);

	async function handler(domains: string[]) {
		if (loading) return;
		if (error) resetError();

		await dispatch(removeDomains(domains));
	}

	return [{ loading, error }, handler];
}

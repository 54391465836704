import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setDestinationSetName, SetDestinationSetNameAction } from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useDestinationSetName(): LocalOperationResult<
	string | null,
	(setName: ActionPayload<SetDestinationSetNameAction>['setName']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.variables.metadata.destinationSetName);

	function handler(setName: ActionPayload<SetDestinationSetNameAction>['setName']) {
		dispatch(setDestinationSetName({ setName }));
	}

	return [data, handler];
}

import styled, { css } from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons';
import { Flex as DefaultFlex } from 'components/UI/Flex';
import { Colors, Shadows } from 'environment';

interface OpenProps {
	open: boolean;
}

export const CardContainer = styled(DefaultFlex)`
	min-height: 6rem;
	padding: 1rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	margin-bottom: 1rem;
`;

export const PatientHeader = styled.div<OpenProps>`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	color: ${props => props.color};
`;

export const ScrollableArea = styled.div`
	overflow-x: auto;
	margin: 0 3rem;
`;

export const PatientContent = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-bottom: 1rem;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	position: relative;
`;

interface ScrollProps {
	left?: number;
}

export const DistributionWrapper = styled.div<ScrollProps>`
	position: relative;
	min-width: 16rem;
	margin-bottom: 1rem;
	margin-top: 1rem;
	margin-left: ${props => props.left || 0}rem;
	transition: margin-left 0.3s;
`;
interface LineProps {
	startColor?: string;
	endColor?: string;
}
export const Line = styled.div<LineProps>`
	height: 2px;
	background: linear-gradient(
		to right,
		${props => props.startColor || Colors.gray.lighter},
		${props => props.endColor || Colors.gray.lighter}
	);
	position: absolute;
	top: 0.8rem;
	left: 1.6rem;
	right: 0;
`;

interface PatientFooterProps {
	columnDirection?: boolean;
}

export const PatientFooter = styled.div<PatientFooterProps>`
	display: flex;
	flex-direction: ${props => (props.columnDirection ? 'column' : 'row')};
	overflow: hidden;
	margin: 0 2.6rem 0 3rem;
	justify-content: space-between;
`;

export const FooterRight = styled.div``;
interface FooterDetailProps {
	alignRight?: boolean;
	forceAlignLeft?: boolean;
}
export const FooterDetail = styled.div.attrs(() => ({
	className: 'textRegular'
}))<FooterDetailProps>`
	display: flex;
	align-items: center;
	justify-content: ${props =>
		props.alignRight && !props.forceAlignLeft ? 'flex-end' : 'flex-start'};
	margin-bottom: 1rem;
`;

export const FooterDetailSwitch = styled(FooterDetail)`
	flex-direction: row;
	margin-right: -1rem;

	${props =>
		props.forceAlignLeft &&
		css`
			flex-direction: row-reverse;
			margin-top: 2rem;
			justify-content: flex-end;

			${SwitchContainer} {
				margin-left: 0;
			}
		`}
`;

export const SwitchContainer = styled.div`
	width: 5rem;
	margin-left: 1rem;
`;

export const Icon = styled(DefaultIcon)`
	padding: 0;
	margin-right: 1rem;
	display: inline-block;
	& > svg {
		display: block;
	}
`;

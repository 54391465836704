import styled from 'styled-components';

import { Colors } from 'environment';
import { FontWeights, Typography } from 'components/UI/Typography';

export const WithStripes = styled.div`
	overflow: auto;

	> :nth-child(odd) {
		background-color: ${Colors.background.disabled};
	}
`;

export const CollaboratorContainer = styled.div`
	display: flex;
	padding: 0.8rem 1.6rem;

	.remove-button {
		visibility: hidden;
		color: ${Colors.text.error};
		font-weight: ${FontWeights.normal};
	}

	:hover {
		background-color: ${Colors.primary.disabled};

		.remove-button {
			visibility: visible;
		}
	}
`;

export const Title = styled(Typography.Paragraph)`
	font-weight: ${FontWeights.bold};
`;

import { useState, useRef, useMemo } from 'react';
import { Colors } from 'environment';
import { UserCard } from 'components/UI/UserCard';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { DropdownItem } from 'components/UI/Dropdown/DropdownItem';

import {
	Container,
	ToggleContainer,
	TransferDropdownMenu,
	DropdownList
} from './DropdownUsers.style';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Typography } from 'components/UI/Typography';
import { hasMatches } from 'helpers/strings';
import { useTranslation } from 'hooks/store';
import { useOutsideClick } from 'hooks/utils';
import { ChevronToggle } from './ChevronToggle/ChevronToggle';

interface UserProp {
	userId: string;
	emailAddress: string;
	userFirstName?: string;
	userSirName?: string;
}

interface Props {
	selectedUserId: string | null;
	users: UserProp[];
	label?: string;
	searchPlaceholder?: string;
	disabled?: boolean;
	inactive?: boolean; // takes away the dropdown capabilities
	onSelect?: (userId: string) => void;
}

export function DropdownUsers({
	selectedUserId,
	users,
	searchPlaceholder,
	label,
	disabled,
	inactive,
	onSelect
}: Props) {
	const { translate } = useTranslation();

	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const ref = useRef<HTMLDivElement>(null);
	const menuRef = useRef<HTMLDivElement>(null);

	function handleOnSelect(userId: string) {
		onSelect && onSelect(userId);
		close();
	}

	function close() {
		setOpen(false);
		setSearchTerm('');
	}

	useOutsideClick(close, [ref, menuRef]);

	const filteredUsers = useMemo(() => {
		const isSearchTermValid = searchTerm.trim().length > 0;

		if (!isSearchTermValid) return users;

		function matchUser(user: UserProp) {
			const keywords: string[] = [
				user.emailAddress,
				user.userFirstName ?? '',
				user.userSirName ?? ''
			];

			return hasMatches({ searchTerm, keywords });
		}

		return users.filter(matchUser);
	}, [searchTerm, users]);

	const user = users.find(u => u.userId === selectedUserId);

	return (
		<Container>
			<ToggleContainer>
				<InputLabel label={label} disabled={disabled} />

				<ChevronToggle
					inactive={inactive}
					ref={ref}
					open={open}
					onClick={inactive ? undefined : () => setOpen(prev => !prev)}
				>
					{user ? (
						<UserCard.Basic
							userId={user.userId}
							userData={{
								userFirstName: user.userFirstName,
								userSirName: user.userSirName,
								emailAddress: user.emailAddress
							}}
							avatarSize={s => s.s}
						/>
					) : (
						<Typography.Paragraph color={Colors.text.caption}>
							{translate(dict => dict.terms.select)}
						</Typography.Paragraph>
					)}
				</ChevronToggle>

				{open && (
					<TransferDropdownMenu ref={menuRef}>
						<SearchInput
							placeholder={
								searchPlaceholder ??
								translate(dict => dict.accountUM.subscription.searchUser)
							}
							term={searchTerm}
							onChangeTerm={setSearchTerm}
							className="transfer-ownership-search"
						/>
						<DropdownList>
							{filteredUsers.map(u => (
								<DropdownItem
									key={u.userId}
									className="transfer-ownership-user"
									onClick={() => handleOnSelect(u.userId)}
								>
									{u.userFirstName && (
										<UserCard.Basic
											userData={{
												userFirstName: u.userFirstName,
												userSirName: u.userSirName,
												emailAddress: u.emailAddress
											}}
											userId={u.userId}
											avatarSize={s => s.s}
										/>
									)}
								</DropdownItem>
							))}
						</DropdownList>
					</TransferDropdownMenu>
				)}
			</ToggleContainer>
		</Container>
	);
}

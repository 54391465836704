import { LocalOperationResult } from 'hooks/store/types';
import { useSelector, useDispatch } from 'hooks/utils';
import { setSoruceCreateTemplateId } from 'store/data/templates';

export function useSourceCreateTemplateId(): LocalOperationResult<
	string | null,
	(id: string | null) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.templates.sourceCreateTemplateId);

	function handler(templateId: string | null) {
		dispatch(setSoruceCreateTemplateId({ templateId }));
	}

	return [data, handler];
}

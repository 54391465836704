import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
	minValue: number;
	maxValue: number;
	scaleInterval: number | null;
	error?: boolean;
};

const DottedScale: React.FC<Props> = ({ minValue, maxValue, scaleInterval, error }) => {
	const [dotsArray, setDotsArray] = useState<number[]>([]);
	// Calculate the dots based on minValue, maxValue, and scaleInterval
	useEffect(() => {
		if (!error) {
			const interval =
				scaleInterval !== 0 && scaleInterval ? scaleInterval : (maxValue - minValue) / 20;

			const dots: number[] = [];

			if (interval && (minValue !== 0 || maxValue !== 0)) {
				for (let value = minValue; value <= maxValue; value += interval) {
					dots.push(value);
				}
			}

			if (!dots.includes(maxValue)) dots.push(maxValue);

			setDotsArray(dots);
		}
	}, [minValue, maxValue, scaleInterval]);

	// Calculate the percentage width based on minValue and maxValue
	const getPercentageWidth = (value: number) => {
		return ((value - minValue) / (maxValue - minValue)) * 100;
	};

	return (
		<DotLineContainer>
			{dotsArray.map((dot, index) => (
				<Dot percentageWidth={getPercentageWidth(dot)} key={index}></Dot>
			))}
		</DotLineContainer>
	);
};

const DotLineContainer = styled.div`
	display: flex;
	position: relative;
	width: calc(100% - 15px);
	height: 8px;
`;

const Dot = styled.div<{ percentageWidth: number }>`
	width: 1px;
	height: 6px; /* default height */
	background-color: #515161;
	position: absolute;
	transform: translate(-50%);
	left: ${props => props.percentageWidth}%;

	&:first-child {
		height: 8px;
	}

	&:last-child {
		height: 8px;
	}
`;

export default DottedScale;

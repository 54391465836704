import { useRef, useMemo } from 'react';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';

import { CorrelationsResults, JADBioScatterGroup } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { CHART_COLORS, CHART_HEIGHT, DEFAULT_MAX_LABELS } from 'consts';
import { ZingChartTypes } from 'types/charts';
import { BooleanMap, ScalesLabels } from 'types/index';
import { computeScalesLabels, computeLegend, getMaxAndMinInArray } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import {
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useAnalysesActiveColum,
	useFullscreenAnalysis
} from 'hooks/store';

function computeData(
	dataset: JADBioScatterGroup[],
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	fullscreen: boolean,
	plots: BooleanMap,
	legendHeader?: string
) {
	const trackers: number[] = [];
	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];
	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	const [legendItem, legendTooltip] = computeLegend(Object.keys(dataset));

	const scatterSeries = dataset.map(({ group, xValues, yValues }, index) => {
		const values = xValues.map((x, i) => [Number(x), Number(yValues[i])]);
		trackers.push(values.length);
		xValuesSeries.push(...xValues);
		yValuesSeries.push(...yValues);

		return {
			values,

			backgroundColor: CHART_COLORS[index + xValues.length],
			lineColor: CHART_COLORS[index + xValues.length],
			lineWidth: '2px',
			text: group,
			size: 20,
			marker: {
				borderWidth: 0,
				size: 0
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	const series = scatterSeries;

	const { max: yMaxValue, min: yMinValue } = getMaxAndMinInArray(yValuesSeries);

	const minYValue = Math.floor(yMinValue);
	const maxTrackers = Math.max(...trackers);

	const disableAnimations = trackers.reduce((a, b) => a + b, 0) > 100;

	const options = {
		type: ZingChartTypes.Area,
		plot: {
			maxTrackers,
			aspect: 'spline'
		},
		legend: {
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && dataset.length > 1,
			marker: {
				type: 'circle'
			}
		},
		scaleX: {
			label: labelX,
			values: '0:1:0.1',
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : undefined,
			itemsOverlap: false,
			tick: {
				lineWidth: 2
			}
		},
		scaleY: {
			label: labelY,
			minValue: minYValue,
			offset: 0,
			exponent: yMaxValue >= 10000 ? true : false
		},
		plotarea: {
			marginLeft: yMaxValue >= 10000 ? 75 : undefined,
			marginRight: yMaxValue >= 10000 ? 40 : undefined
		}
	};

	return { series, options, disableAnimations };
}

interface Props {
	id?: string;
	data: CorrelationsResults;
	isForExport?: boolean;
	isLegendEnabled: boolean;
	legendHeader?: string;
	scalesLabels: ScalesLabels;
}

function Component({ id, data, isForExport, isLegendEnabled, legendHeader, scalesLabels }: Props) {
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const ref = useRef<ZingChart>(null);
	const [activeColumns] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const { options, series, disableAnimations } = useMemo(
		() =>
			computeData(
				data,
				isLegendEnabled,
				scalesLabels,
				activeColumns,
				!!fullscreen,
				plots,
				legendHeader
			),
		[data, isLegendEnabled, scalesLabels, activeColumns, plots, legendHeader]
	);

	// legend item click toggle chained range and line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		!isForExport && togglePlot({ id: analysisId, plotIndex: e.plotindex });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `linear-${e.plotindex}` });
		}
	}

	return (
		<Chart
			id={id}
			chartRef={ref}
			type={t => t.Area}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			disableAnimations={disableAnimations}
			effect={e => e.SlideBottom}
			sequence={s => s.ByPlot}
			method={m => m.RegularEaseOut}
			speed={s => s.Faster}
			isForExport={isForExport}
			height={CHART_HEIGHT}
		/>
	);
}

export const JADBioProbabilitiesDensityPlotChart = withMemo(Component, [
	'data',
	'isLegendEnabled',
	'scalesLabels',
	'legendHeader'
]);

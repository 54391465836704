import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { setTemplateDescription, SetTemplateDescriptionAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useSetTemplateDescription(): LocalOperation<
	(payload: ActionPayload<SetTemplateDescriptionAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetTemplateDescriptionAction>) {
		dispatch(setTemplateDescription(payload));
	}

	return handler;
}

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	selectProjectFiles,
	getProjectFiles,
	selectAreFilesFetched
} from 'store/data/documents';
import { ProjectFile } from 'api/data/documents';
import { useDispatch, useSelector } from 'hooks/utils';
import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

export function useProjectDocuments(): OperationResult<ProjectFile[]> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const { hasDatasetReadAllAccess } = usePermissions();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_PROJECT_FILES, {
		projectId
	});

	const data = useSelector(state => selectProjectFiles(state.data.documents));
	const fetched = useSelector(state => selectAreFilesFetched(state.data.documents));

	useEffect(() => {
		if (!error && !loading && !fetched && isProjectValid && hasDatasetReadAllAccess) {
			handler();
		}
	}, [error, loading, fetched, isProjectValid, hasDatasetReadAllAccess]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getProjectFiles());
	}

	return [{ loading, error, data, fetched }, handler];
}

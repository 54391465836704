import type { Action } from 'types/store/types';
import type {
	FileMimeType,
	GenericMap,
	StringMap,
	NumberMap,
	StringArrayMap,
	Nullable
} from 'types/index';

export interface State {
	projectId: string | null;
	rows: {
		byId: EntryRowsById;
		idsByPage: Record<number, string[] | undefined>;
		ids: string[];
		count: number | null;
		latestFetched: boolean;
		prevEntry?: EntryValues;
	};
	subEntries: {
		byEntryId: {
			initial: SubEntriesByEntryId;
			current: SubEntriesByEntryId;
			conflicted: ConflictsById;
		};
		selected: SelectedSeriesEntry;
		count: {
			byEntryId: SubEntriesCountByEntryId;
		};
	};
	files: {
		byId: EntryFiles;
		toStore: string[];
	};
	statuses: {
		byId: {
			initial: EntryStatusesById;
			current: EntryStatusesById;
		};
	};
	drafts: {
		new: EntryDraftData;
		byId: GenericMap<EntryDraftData>;
	};
	metadata: {
		fetched: boolean;
		refetch: boolean;
		pageSize: number;
		pageIndex: number;
		totalCount: number;
		term: string;
		entryId: string | null;
		validationErrors: StringMap;
		columnSettings: {
			visible: string[];
		};
		namesFromUserIds: EntryNamesFromUserIds;
	};
	errors: {
		data: EntriesErrors | null;
		filter: EntriesErrorsFilter;
	};
}

// CONFLICTED DATA
export interface ConflictedData {
	values: ConflictedDataValues;
	statuses: Partial<ConflictedDataStatuses>;
	metadata?: {
		date: string;
		user: string;
	};
}

export interface Conflicts<T> {
	previous: T;
	current: T;
}

export type ConflictedDataValues = GenericMap<Conflicts<EntryValue>>;
export type ConflictedDataStatuses = Conflicts<EntryStatus>;

interface EntryRowsById {
	entries: EntriesById;
	conflicted: ConflictsById;
}

export interface EntriesErrors {
	columns: string[];
	rows: StringArrayMap;
}

export interface EntriesErrorsFilter {
	columns: boolean;
	rows: boolean;
}

export type SubEntriesByEntryId = GenericMap<{
	bySetName: SubEntriesBySetName;
}>;

export type SubEntriesBySetName = GenericMap<SubEntries>;

export interface SubEntries {
	rows: {
		byId: EntriesById;
		ids: string[];
	};
	fetched: boolean;
}

export type SubEntriesCountByEntryId = GenericMap<SubEntriesCount>;

export type SubEntriesCount = {
	bySetName: NumberMap;
	fetched: boolean;
};

export interface SelectedSeriesEntry {
	setName: string | null;
	subEntryId: string | null;
	subFormId: string | null;
}

export interface EntryDraftData {
	data: EntryDraft;
	fetched: boolean;
}

export type EntryDraft = EntryDraftValue | null;

export interface EntryDraftValue {
	draftEntryId: string;
	creationDate: string;
	values: EntryValues;
	differenceFromActiveEntry?: EntryDifferenceOutput;
}

export type EntryStatus = EntryStatusValue | null;
export type EntryStatusValue = {
	variableName: string;
	dueTimeStamp: string | null;
	comment: string;
};

export type Entry = EntryValues & EntryMetadata & EntryMetadataNames;
export type EntryMetadata = {
	enteredbyuser: string;
	ownedbyuser: string;
	creationdate: string;
	lastmodifieddate: string;
	userProjectOrgId: string;
	datasetentryid: string;
};
export type EntryMetadataNames = {
	enteredbyuserwithname: string;
	ownedbyuserwithname: string;
};

export type EntryNamesFromUserIds = GenericMap<string>;

export type EntryValues = GenericMap<EntryValue>;
export type EntryValue = string | string[] | null;
export type EntryDifferenceResponse = {
	variableName: string;
	from: EntryValue | number;
	to: EntryValue | number;
};
export type EntryDifferenceOutput = GenericMap<{ to: EntryValue; from: EntryValue }>;
export type Entries = Entry[];
export type EntriesById = GenericMap<Entry>;
export type ConflictsById = GenericMap<ConflictedData | null>;
export type EntryStatusesById = GenericMap<EntryStatus>;
export type StatusObservationData = GenericMap<{
	value: boolean;
	comment: string;
}>;

export type DynamicFormValue = Exclude<EntryValue, null>;
export type DynamicFormValues = GenericMap<DynamicFormValue>;

export interface EntriesTableParams {
	pageSize?: number;
	pageIndex?: number;
	totalCount?: number;
}

export interface DynamicFormFiles {
	fileIds: string[];
}

export type EntryFile = {
	fileId: string;
	fileName: string;
	fileLabel?: string;
};

export type LocalEntryFile = EntryFile & {
	variableName: string;
	base64: string;
	size: number;
	isLocal: true;
};

export type StoredEntryFile = EntryFile & {
	signedS3Url: string;
	thumbnailURL: string;
	extendedMimeType: FileMimeType;
	mimeType: string;
	isLocal: false;
};

export type EntryFiles = GenericMap<StoredEntryFile | LocalEntryFile>;
export type StoredEntryFiles = GenericMap<StoredEntryFile>;

export type VariableFilteringMap = StringArrayMap;

export type FormFieldPageLocationByName = GenericMap<{
	pageIndex: number;
	group?: {
		groupName: string;
		groupPageIndex?: number;
	};
}>;

export type FormGroupPaginationDataByname = GenericMap<FormGroupPaginationData>;

export interface FormGroupPaginationData {
	pageIndex: number;
	pageSize: number;
}

export interface SetGroupPaginationDataInput {
	groupName: string;
	paginationData: FormGroupPaginationData;
}

export interface SelectedEntry {
	entryId: string;
	confirmed: boolean;
}

export interface TransferOwnershipState {
	isTransferOwnership: boolean;
	selectedEntries: SelectedEntry[];
	showCancelModal: boolean;
	showPreviewModal: boolean;
	showTransferModal: boolean;
}

export interface TransferEntriesOwnershipActions {
	getIsTransferOwnership: () => boolean;
	setIsTransferOwnership: (value: boolean) => void;
	getSelectedEntries: () => SelectedEntry[];
	setSelectedEntries: (selectedEntries: SelectedEntry[]) => void;
	toggleAllSelectedEntriesConfirmed: (value: boolean) => void;
	addSelectedEntry: (entryId: string) => void;
	removeSelectedEntry: (entryId: string) => void;
	removeAllSelectedEntries: () => void;
	toggleSelectedEntryConfirmed: (entryId: string) => void;
	getCancelModal: () => boolean;
	setCancelModal: (value: boolean) => void;
	getPreviewModal: () => boolean;
	setPreviewModal: (value: boolean) => void;
	closePreviewModal: () => void;
	getTransferModal: () => boolean;
	setTransferModal: (value: boolean) => void;
}

export enum ActionTypes {
	// get entries
	GET_ENTRIES = 'data/entries/GET_ENTRIES',
	GET_ENTRY = 'data/entries/GET_ENTRY',
	GET_LATEST_ENTRY = 'data/entries/GET_LATEST_ENTRY',
	GET_CONFLICT_ENTRY = 'data/entries/GET_CONFLICT_ENTRY',
	GET_MORE_ENTRIES = 'data/entries/GET_MORE_ENTRIES',
	SET_ENTRY_ID = 'data/dataset/SET_ENTRY_ID',
	RECALCULATING_ENTRIES = 'data/dataset/RECALCULATING_ENTRIES',

	// create, update, delete entry
	CREATE_ENTRY = 'data/entries/CREATE_ENTRY',
	UPDATE_ENTRY = 'data/entries/UPDATE_ENTRY',
	UPDATE_ENTRY_STATUS = 'data/entries/UPDATE_ENTRY_STATUS',
	DELETE_ENTRY = 'data/entries/DELETE_ENTRY',

	// HYDRATE
	HYDRATE_ENTRY = 'data/dataset/HYDRATE_ENTRY',

	// DOWNLOAD
	DOWNLOAD_ENTRIES_AS_CSV = 'data/entries/DOWNLOAD_ENTRIES_AS_CSV',
	DOWNLOAD_ENTRIES_AS_EXCEL = 'data/entries/DOWNLOAD_ENTRIES_AS_EXCEL',
	GET_DOWNLOADABLE_ENTRIES = 'data/entries/GET_DOWNLOADABLE_ENTRIES',

	// entries table filters
	SET_ENTRIES_SEARCH_TERM = 'data/entries/SET_ENTRIES_SEARCH_TERM',
	SET_ENTRIES_TABLE_PARAMS = 'data/entries/SET_ENTRIES_TABLE_PARAMS',
	SET_ENTRIES_TABLE_ERRORS_FILTER = 'data/entries/SET_ENTRIES_TABLE_ERRORS_FILTER',

	// FILES
	GET_ENTRY_FILE = 'data/entries/GET_ENTRY_FILE',
	GET_ENTRY_FILES = 'data/entries/GET_ENTRY_FILES',
	ADD_LOCAL_ENTRY_FILE = 'data/entries/ADD_LOCAL_ENTRY_FILE',
	DELETE_LOCAL_ENTRY_FILE = 'data/entries/DELETE_LOCAL_ENTRY_FILE',
	DELETE_ALL_LOCAL_ENTRY_FILES = 'data/entries/DELETE_ALL_LOCAL_ENTRY_FILES',
	STORE_ENTRY_FILES_REQ = 'data/entries/STORE_ENTRY_FILES_REQ',
	STORE_ENTRY_FILE_REQ = 'data/entries/STORE_ENTRY_FILE_REQ',
	REMOVE_ENTRY_FILES_REQ = 'data/entries/REMOVE_ENTRY_FILES_REQ',

	// STATUSES
	SET_ENTRY_STATUS = 'data/entries/SET_ENTRY_STATUS',

	// PROM - MANUAL DISTRIBUTION
	CREATE_ENTRY_SURVEY = 'data/entries/CREATE_ENTRY_SURVEY',
	GET_SURVEY_DATA = 'data/entries/GET_SURVEY_DATA',

	REBUILD_ENTRIES = 'data/entries/REBUILD_ENTRIES',
	SET_REFETCH_ENTRIES = 'data/entries/SET_REFETCH_ENTRIES',
	RESET_ENTRY_FETCHED_DATA = 'data/entries/RESET_ENTRY_FETCHED_DATA',
	SET_ENTRIES_TABLE_VISIBLE_COLUMNS = 'data/entries/SET_ENTRIES_TABLE_VISIBLE_COLUMNS',

	// DRAFTS
	GET_ENTRY_DRAFT = 'data/entries/GET_ENTRY_DRAFT',
	SAVE_ENTRY_DRAFT = 'data/entries/SAVE_ENTRY_DRAFT',
	RESET_CREATE_ENTRY_DRAFT = 'data/entries/RESET_CREATE_ENTRY_DRAFT',

	// SERIES
	GET_SERIES_ENTRIES = 'data/entries/GET_SERIES_ENTRIES',
	CREATE_SERIES_ENTRY = 'data/entries/CREATE_SERIES_ENTRY',
	UPDATE_SERIES_ENTRY = 'data/entries/UPDATE_SERIES_ENTRY',
	DELETE_SERIES_ENTRY = 'data/entries/DELETE_SERIES_ENTRY',
	GET_SERIES_ENTRIES_COUNT = 'data/entries/GET_SERIES_ENTRIES_COUNT',
	SET_SELECTED_SERIES_ENTRY = 'data/entries/SET_SELECTED_SERIES_ENTRY',

	// TRANSFER ENTRIES OWNERSHIP
	TRANSFER_ENTRIES_OWNERSHIP = 'data/entries/TRANSFER_ENTRIES_OWNERSHIP',

	// CONFLICTED DATA
	SET_CONFLICTED_DATA = 'data/entries/SET_CONFLICTED_DATA',
	SET_SERIES_CONFLICTED_DATA = 'data/entries/SET_SERIES_CONFLICTED_DATA',

	ADD_NAMES_FROM_USER_IDS = 'data/entries/ADD_NAMES_FROM_USER_IDS'
}

export type GetEntriesAction = Action<
	ActionTypes.GET_ENTRIES,
	{
		projectId: string;
		entries: Entries;
		statuses?: GenericMap<EntryStatusValue>;
		errors?: EntriesErrors;
		totalCount?: number;
	}
>;

export type GetEntryAction = Action<
	ActionTypes.GET_ENTRY,
	{
		projectId: string;
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
	}
>;

export type GetLatestEntryAction = Action<
	ActionTypes.GET_LATEST_ENTRY,
	{
		projectId: string;
		oldEntryId: string;
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
	}
>;

export type GetConflictEntryAction = Action<
	ActionTypes.GET_CONFLICT_ENTRY,
	{
		projectId: string;
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
	}
>;

export type GetMoreEntriesAction = Action<
	ActionTypes.GET_MORE_ENTRIES,
	{
		projectId: string;
		entries: Entries;
	}
>;

export type CreateEntryAction = Action<
	ActionTypes.CREATE_ENTRY,
	{
		projectId: string;
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
		setEntryId?: boolean;
	}
>;

export type UpdateEntryAction = Action<
	ActionTypes.UPDATE_ENTRY,
	{
		projectId: string;
		oldEntryId: string;
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
	}
>;

export type UpdateEntryStatusAction = Action<
	ActionTypes.UPDATE_ENTRY_STATUS,
	{
		projectId: string;
		oldEntryId: string;
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
	}
>;

export type DeleteEntryAction = Action<
	ActionTypes.DELETE_ENTRY,
	{
		projectId: string;
		entryId: string;
	}
>;

export type HydrateEntryAction = Action<
	ActionTypes.HYDRATE_ENTRY,
	{
		entry: Entry;
		entryStatus: EntryStatus;
		columns: string[];
	}
>;

export type SetEntryIdAction = Action<
	ActionTypes.SET_ENTRY_ID,
	{
		entryId: string | null;
	}
>;

export type SetEntriesSearchTermAction = Action<
	ActionTypes.SET_ENTRIES_SEARCH_TERM,
	{
		term: string;
	}
>;

export type SetEntriesTableParamsAction = Action<
	ActionTypes.SET_ENTRIES_TABLE_PARAMS,
	EntriesTableParams
>;

// Entry Files Actions
export type GetEntryFileAction = Action<
	ActionTypes.GET_ENTRY_FILE,
	{
		file: StoredEntryFile;
	}
>;

export type GetEntryFilesAction = Action<
	ActionTypes.GET_ENTRY_FILES,
	{
		files: StoredEntryFiles;
	}
>;

export type SaveLocalEntryFileAction = Action<
	ActionTypes.ADD_LOCAL_ENTRY_FILE,
	{
		file: LocalEntryFile;
	}
>;

export type DeleteLocalEntryFileAction = Action<
	ActionTypes.DELETE_LOCAL_ENTRY_FILE,
	{
		id: string;
	}
>;
export type SetEntryStatusAction = Action<
	ActionTypes.SET_ENTRY_STATUS,
	{
		status: EntryStatus;
		entryId: string;
		oldEntryId?: string;
		initial?: boolean;
	}
>;

export type DeleteAllLocalEntryFilesAction = Action<ActionTypes.DELETE_ALL_LOCAL_ENTRY_FILES>;

export type RebuildEntriesTableAction = Action<
	ActionTypes.REBUILD_ENTRIES,
	{
		columns: string[];
	}
>;

export type SetRefetchEntriesAction = Action<ActionTypes.SET_REFETCH_ENTRIES>;

export type ResetEntryFetchedDataAction = Action<
	ActionTypes.RESET_ENTRY_FETCHED_DATA,
	{
		entryId: string;
	}
>;

export type SetEntriesTableVisibleColumnsAction = Action<
	ActionTypes.SET_ENTRIES_TABLE_VISIBLE_COLUMNS,
	{
		columnNames: string[];
	}
>;

export type GetEntryDraftAction = Action<
	ActionTypes.GET_ENTRY_DRAFT,
	{
		entryDraft: EntryDraft;
		entryId: string | null;
	}
>;

export type SaveEntryDraftAction = Action<
	ActionTypes.SAVE_ENTRY_DRAFT,
	{
		entryDraft: EntryDraft;
		entryId: string | null;
	}
>;

export type ResetCreateEntryDraftAction = Action<ActionTypes.RESET_CREATE_ENTRY_DRAFT>;

export type GetSeriesEntriesAction = Action<
	ActionTypes.GET_SERIES_ENTRIES,
	{
		projectId: string;
		entryId: string;
		setName: string;
		entries: Entries;
	}
>;

export type CreateSeriesEntryAction = Action<
	ActionTypes.CREATE_SERIES_ENTRY,
	{
		projectId: string;
		parentEntryId: string;
		setName: string;
		entry: Entry;
		columns: string[];
		setEntryId?: boolean;
	}
>;

export type UpdateSeriesEntryAction = Action<
	ActionTypes.UPDATE_SERIES_ENTRY,
	{
		projectId: string;
		parentEntryId: string;
		setName: string;
		oldEntryId: string;
		entry: Entry;
		columns: string[];
		setEntryId?: boolean;
	}
>;

export type DeleteSeriesEntryAction = Action<
	ActionTypes.DELETE_SERIES_ENTRY,
	{
		projectId: string;
		parentEntryId: string;
		setName: string;
		entryId: string;
	}
>;

export type GetSeriesEntriesCountAction = Action<
	ActionTypes.GET_SERIES_ENTRIES_COUNT,
	{
		projectId: string;
		entryId: string;
		entriesCount: NumberMap;
	}
>;

export type SetSelectedSeriesEntryAction = Action<
	ActionTypes.SET_SELECTED_SERIES_ENTRY,
	{
		selected: SelectedSeriesEntry;
	}
>;

export type TransferEntriesOwnershipAction = Action<ActionTypes.TRANSFER_ENTRIES_OWNERSHIP>;

export type SetEntriesTableErrorsFilterAction = Action<
	ActionTypes.SET_ENTRIES_TABLE_ERRORS_FILTER,
	EntriesErrorsFilter
>;

// CONFLICTED DATA
export type SetConflictedDataAction = Action<
	ActionTypes.SET_CONFLICTED_DATA,
	Nullable<ConflictedData>
>;
export type SetSeriesConflictedDataAction = Action<
	ActionTypes.SET_SERIES_CONFLICTED_DATA,
	Nullable<ConflictedData>
>;

export type AddNamesFromUserIdsAction = Action<
	ActionTypes.ADD_NAMES_FROM_USER_IDS,
	{ namesFromUserIds: EntryNamesFromUserIds }
>;

export type Actions =
	| GetEntriesAction
	| GetEntryAction
	| GetLatestEntryAction
	| GetConflictEntryAction
	| GetMoreEntriesAction
	| CreateEntryAction
	| UpdateEntryAction
	| UpdateEntryStatusAction
	| DeleteEntryAction
	| HydrateEntryAction
	| SetEntryIdAction
	| SetEntriesSearchTermAction
	| SetEntriesTableParamsAction
	| GetEntryFileAction
	| GetEntryFilesAction
	| SaveLocalEntryFileAction
	| DeleteLocalEntryFileAction
	| DeleteAllLocalEntryFilesAction
	| SetEntryStatusAction
	| RebuildEntriesTableAction
	| SetRefetchEntriesAction
	| ResetEntryFetchedDataAction
	| SetEntriesTableVisibleColumnsAction
	| GetEntryDraftAction
	| SaveEntryDraftAction
	| ResetCreateEntryDraftAction
	| GetSeriesEntriesAction
	| CreateSeriesEntryAction
	| UpdateSeriesEntryAction
	| DeleteSeriesEntryAction
	| GetSeriesEntriesCountAction
	| SetSelectedSeriesEntryAction
	| TransferEntriesOwnershipAction
	| SetEntriesTableErrorsFilterAction
	| SetConflictedDataAction
	| SetSeriesConflictedDataAction
	| AddNamesFromUserIdsAction;

import { useState } from 'react';

import { useTranslation, useAccount, useUpdateDashboard, useCreateDashboard } from 'hooks/store';
import { Colors, Svgs } from 'environment';

import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Switch } from 'components/UI/Interactables/Switch';
import { stringAsBoolean } from 'helpers/generic';
import { useDashboardId } from 'hooks/store/data/projectDashboard/useDashboardId';
import { AdvancedMenu } from 'components/UI/Interactables/AdvancedMenu';
import { Typography } from 'components/UI/Typography';

interface Props {
	showFilters: boolean;
	emptyDashboard: boolean;
	layoutSize: number;
	setShowFilters: (showFilters: boolean) => void;
	addNewCard: () => void;
	setLayoutSize: (layoutSize: number) => void;
	// automaticGrid?: boolean;
	// setAutomaticGrid?: (automaticGrid: boolean) => void;
}
export function AdminDashboardHeader({
	showFilters,
	emptyDashboard,
	layoutSize,
	setShowFilters,
	addNewCard,
	setLayoutSize
}: Props) {
	const { translate } = useTranslation();
	const [menuVisible, setMenuVisible] = useState(false);

	// Disabled automatic grid layout for cards (cards push each other to make space)
	const useAutomaticGrids = stringAsBoolean(process.env.REACT_APP_USE_AUTOMATIC_GRID_CARDS!);

	const [
		{
			data: { details: userDetails }
		}
	] = useAccount();

	const [dashboardId] = useDashboardId();

	const [, updateDashboard] = useUpdateDashboard();
	const [, createDashboard] = useCreateDashboard();

	function saveDashboard() {
		console.log('dashboardId', dashboardId);
		if (!dashboardId) return;
		if (dashboardId > 0) {
			updateDashboard();
		} else {
			createDashboard();
		}
	}

	function handleAddCard() {
		addNewCard();
	}

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) => (
					<Flex justify={j => j.center} fullWidth>
						{!emptyDashboard && (
							<Flex align={a => a.center} justify={j => j.between} fullWidth>
								<AdvancedMenu
									offset={{ top: 8, right: 8 }}
									items={[
										{
											label: translate(({ buttons }) =>
												dashboardId && dashboardId > 0
													? buttons.update
													: buttons.create
											),
											handler: () => saveDashboard()
										},
										{ label: translate(({ buttons }) => buttons.edit) },
										// { label: translate(({ buttons }) => buttons.layout) },
										{
											label: translate(({ buttons }) => buttons.delete),
											handler: () => console.log(dashboardId)
										}
									]}
									active={menuVisible}
									onOpen={() => setMenuVisible(true)}
									onClose={() => setMenuVisible(false)}
								/>
								{addButton({
									label: `+ ${translate(({ buttons }) => buttons.add)}`,
									action: handleAddCard
								})}
							</Flex>
						)}
					</Flex>
				)}
			/>
			<Header.Title
				title={`${userDetails?.userFirstName} ${userDetails?.userSirName} ${translate(
					({ projectTabs }) => projectTabs.dashboard
				)}`}
				component={
					<Flex
						style={{
							visibility: 'visible',
							width: !useAutomaticGrids ? 'initial' : '20%'
						}}
						justify={j => j.between}
					>
						<Flex
							align={a => a.center}
							marginOffset={{ x: 2 }}
							style={{ background: Colors.vibrantGreen, borderRadius: '0.6rem' }}
						>
							<Icon svg={Svgs.Minus} onClick={() => setLayoutSize(layoutSize - 1)} />
							<Typography.Paragraph marginOffset={{ x: 1 }}>
								Layout columns:
							</Typography.Paragraph>
							<Typography.H6>{layoutSize}</Typography.H6>
							<Icon
								svg={Svgs.Add}
								marginOffset={{ left: 1 }}
								onClick={() => setLayoutSize(layoutSize + 1)}
							/>
						</Flex>

						<Switch
							label={'Show Filters'}
							onChange={() => setShowFilters(!showFilters)}
							on={showFilters}
						/>
					</Flex>
				}
			/>
		</>
	);
}

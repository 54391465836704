import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import {
	moveVariableGroupBetweenSets,
	MoveVariableGroupBetweenSetsAction,
	ActionTypes
} from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveVariableGroupBetweenSets(activityPayload?: {
	groupName: string;
}): Operation<(payload: ActionPayload<MoveVariableGroupBetweenSetsAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(
		ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS,
		activityPayload
	);

	function handler(payload: ActionPayload<MoveVariableGroupBetweenSetsAction>) {
		dispatch(moveVariableGroupBetweenSets(payload));
	}

	return [{ loading, error }, handler];
}

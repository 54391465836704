import { Group } from 'api/data/variables';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteGroup } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	group: Group;
	onClose: () => void;
}

export function DeleteVariableGroupModal({ group, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: deletingGroup, error: errorDeletingGroup }, deleteGroup] = useDeleteGroup();

	// CLOSE MODAL AFTER GROUP WAS DELETED
	useCompletedAction(deletingGroup, errorDeletingGroup, onClose);

	const { groupName, groupLabel } = group;

	function handleDelete() {
		deleteGroup(groupName);
	}

	const title = `${translate(dict => dict.terms.delete)} ${groupLabel}?`;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: deletingGroup,
				onClick: handleDelete
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.variablesPage.deleteVariableGroupModal.message)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { sendRequest, STATISTICS_URL } from 'api/utils';

import { decodeURIComponentSafe, stringAsBoolean } from 'helpers/generic';
import {
	GetCorrelationsInput,
	GetExploreInput,
	GetExploreInputV2,
	GetFrequenciesInput,
	GetExploreResponse,
	GetFrequenciesResponse,
	GetKaplanMeierResponse,
	GetKaplanMeierRequestInput,
	KaplanMeierResults,
	GetPlotNumericResponse,
	GetPlotNumericInput,
	GetDensityPlotResponse,
	GetDensityPlotInput,
	GetDensityPlotInputV2,
	TimeCourseInputV1,
	TimeCourseInputV2,
	GetTimeCourseResponseV1,
	//////////////////////
	GetCompareNumericInputV1,
	GetCompareNumericRequestV1,
	GetCompareNumericResponseV1,
	GetCompareNumericOutputV1,
	GetCompareNumericInputV2,
	GetCompareNumericRequestV2,
	GetCompareNumericResponseV2,
	GetCompareNumericOutputV2,
	//////////////////////
	GetComparePairedInput,
	GetComparePairedRequest,
	GetComparePairedResponse,
	GetComparePairedOutput,
	//////////////////////
	GetCrosstabInput,
	GetCrosstabRequest,
	GetCrosstabResponse,
	GetCrosstabOutput,
	//////////////////////
	GetLogisticRegressionInput,
	GetLogisticRegressionOutput,
	GetLogisticRegressionRequest,
	GetLogisticRegressionResponse,
	///////////////////////
	GetNumberPlotXYInput,
	GetNumberPlotXYRequest,
	GetNumberPlotXYResponse,
	GetNumberPlotXYOutput,

	/*
	 * STATISTICS
	 */

	//////////////////////
	GetMcNemarStatisticsInput,
	GetMcNemarStatisticsRequest,
	GetMcNemarStatisticsResponse,
	GetMcNemarStatisticsOutput,
	//////////////////////
	GetTwoWayManovaStatisticsInputV1,
	GetTwoWayManovaStatisticsOutputV1,
	GetTwoWayManovaStatisticsRequestV1,
	GetTwoWayManovaStatisticsResponseV1,
	GetTwoWayManovaStatisticsInputV2,
	GetTwoWayManovaStatisticsOutputV2,
	GetTwoWayManovaStatisticsRequestV2,
	GetTwoWayManovaStatisticsResponseV2,
	//////////////////////
	GetTwoWayAnovaStatisticsInputV1,
	GetTwoWayAnovaStatisticsRequestV1,
	GetTwoWayAnovaStatisticsResponseV1,
	GetTwoWayAnovaStatisticsOutputV1,
	GetTwoWayAnovaStatisticsInputV2,
	GetTwoWayAnovaStatisticsRequestV2,
	GetTwoWayAnovaStatisticsResponseV2,
	GetTwoWayAnovaStatisticsOutputV2,
	//////////////////////
	GetPairedTTestStatisticsInput,
	GetPairedTTestStatisticsRequest,
	GetPairedTTestStatisticsResponse,
	GetPairedTTestStatisticsOutput,
	//////////////////////
	GetPairedWilcoxonStatisticsInput,
	GetPairedWilcoxonStatisticsRequest,
	GetPairedWilcoxonStatisticsResponse,
	GetPairedWilcoxonStatisticsOutput,
	//////////////////////
	GetFisherStatisticsInput,
	GetFisherStatisticsRequest,
	GetFisherStatisticsResponse,
	GetFisherStatisticsOutput,
	//////////////////////
	GetChiSquareStatisticsInput,
	GetChiSquareStatisticsRequest,
	GetChiSquareStatisticsResponse,
	GetChiSquareStatisticsOutput,
	//////////////////////
	GetKruskalStatisticsInputV1,
	GetKruskalStatisticsRequestV1,
	GetKruskalStatisticsResponseV1,
	GetKruskalStatisticsOutputV1,
	GetKruskalStatisticsInputV2,
	GetKruskalStatisticsRequestV2,
	GetKruskalStatisticsResponseV2,
	GetKruskalStatisticsOutputV2,
	//////////////////////
	GetMannWhitneyStatisticsInputV1,
	GetMannWhitneyStatisticsRequestV1,
	GetMannWhitneyStatisticsResponseV1,
	GetMannWhitneyStatisticsOutputV1,
	GetMannWhitneyStatisticsInputV2,
	GetMannWhitneyStatisticsRequestV2,
	GetMannWhitneyStatisticsResponseV2,
	GetMannWhitneyStatisticsOutputV2,
	//////////////////////
	GetIndependentStatisticsInputV1,
	GetIndependentStatisticsRequestV1,
	GetIndependentStatisticsResponseV1,
	GetIndependentStatisticsOutputV1,
	GetIndependentStatisticsInputV2,
	GetIndependentStatisticsRequestV2,
	GetIndependentStatisticsResponseV2,
	GetIndependentStatisticsOutputV2,
	//////////////////////
	GetPearsonStatisticsInput,
	GetPearsonStatisticsRequest,
	GetPearsonStatisticsResponse,
	GetPearsonStatisticsOutput,
	//////////////////////
	GetSpearmanStatisticsInput,
	GetSpearmanStatisticsRequest,
	GetSpearmanStatisticsResponse,
	GetSpearmanStatisticsOutput,
	//////////////////////
	GetLinearRegressionStatisticsInput,
	GetLinearRegressionStatisticsRequest,
	GetLinearRegressionStatisticsResponse,
	GetLinearRegressionStatisticsOutput,
	//////////////////////
	GetOneWayAnovaStatisticsInputV1,
	GetOneWayAnovaStatisticsRequestV1,
	GetOneWayAnovaStatisticsResponseV1,
	GetOneWayAnovaStatisticsOutputV1,
	GetOneWayAnovaStatisticsInputV2,
	GetOneWayAnovaStatisticsRequestV2,
	GetOneWayAnovaStatisticsResponseV2,
	GetOneWayAnovaStatisticsOutputV2,
	//////////////////////
	GetShapiroStatisticsInputV1,
	GetShapiroStatisticsRequestV1,
	GetShapiroStatisticsResponseV1,
	GetShapiroStatisticsOutputV1,
	GetShapiroStatisticsInputV2,
	GetShapiroStatisticsRequestV2,
	GetShapiroStatisticsResponseV2,
	GetShapiroStatisticsOutputV2,
	//////////////////////
	GetOneWayManovaStatisticsInputV1,
	GetOneWayManovaStatisticsOutputV1,
	GetOneWayManovaStatisticsRequestV1,
	GetOneWayManovaStatisticsResponseV1,
	GetOneWayManovaStatisticsInputV2,
	GetOneWayManovaStatisticsOutputV2,
	GetOneWayManovaStatisticsRequestV2,
	GetOneWayManovaStatisticsResponseV2,
	//////////////////////
	GetLogRankStatisticsInput,
	GetLogRankStatisticsOutput,
	GetLogRankStatisticsRequest,
	GetLogRankStatisticsResponse,
	//////////////////////
	GetTukeyStatisticsInputV1,
	GetTukeyStatisticsOutputV1,
	GetTukeyStatisticsRequestV1,
	GetTukeyStatisticsResponseV1,
	GetTukeyStatisticsInputV2,
	GetTukeyStatisticsOutputV2,
	GetTukeyStatisticsRequestV2,
	GetTukeyStatisticsResponseV2,
	CrosstabRowDetails,
	CrosstabColumnsData,
	CrosstabRowsData,
	FrequenciesDecodedResults,
	KaplanMeierDataModels,
	GetLinearRegressionStatisticsV1Response,
	GetLinearRegressionStatisticsV1Request,
	GetSpearmanStatisticsV1Output,
	GetLinearRegressionStatisticsV1Input,
	GetSpearmanStatisticsV1Input,
	GetSpearmanStatisticsV1Request,
	GetSpearmanStatisticsV1Response,
	GetPearsonStatisticsV1Input,
	GetPearsonStatisticsV1Output,
	GetPearsonStatisticsV1Request,
	GetPearsonStatisticsV1Response,
	GetLinearRegressionStatisticsV1Output,
	GetCorrelationsInputV1,
	GetExploreResponseV2,
	GetCorrelationsV1Response,
	GetDensityPlotResponseV2,
	GetCorrelationsResponse,
	GetTimeCourseResponseV2,
	GetPlotNumericInputV2,
	GetPlotNumericResponseV2,
	GetLogisticRegressionInputV2,
	GetLogisticRegressionOutputV2,
	GetLogisticRegressionRequestV2,
	GetLogisticRegressionResponseV2,
	GetCrosstabInputV2,
	GetCrosstabRequestV2,
	GetCrosstabResponseV2,
	GetChiSquareStatisticsInputV2,
	GetFisherStatisticsInputV2,
	GetFisherStatisticsRequestV2,
	GetMcNemarStatisticsInputV2,
	GetMcNemarStatisticsRequestV2,
	GetChiSquareStatisticsRequestV2,
	GetChiSquareStatisticsResponseV2,
	GetChiSquareStatisticsOutputV2,
	GetFisherStatisticsResponseV2,
	GetFisherStatisticsOutputV2,
	GetMcNemarStatisticsResponseV2,
	GetMcNemarStatisticsOutputV2,
	GetFrequenciesInputV2,
	GetFrequenciesResponseV2,
	GetComparePairedInputV2,
	GetComparePairedResponseV2,
	GetComparePairedRequestV2,
	GetComparePairedOutputV2,
	GetPairedTTestStatisticsInputV2,
	GetPairedTTestStatisticsRequestV2,
	GetPairedTTestStatisticsResponseV2,
	GetPairedTTestStatisticsOutputV2,
	ComparePairedDataModels,
	GetPairedWilcoxonStatisticsInputV2,
	GetPairedWilcoxonStatisticsOutputV2,
	GetPairedWilcoxonStatisticsRequestV2,
	GetPairedWilcoxonStatisticsResponseV2,
	GetKaplanMeierRequestInputV2,
	GetKaplanMeierResponseV2,
	KaplanMeierResultsV2,
	GetLogRankStatisticsInputV2,
	GetLogRankStatisticsOutputV2,
	GetLogRankStatisticsRequestV2,
	GetLogRankStatisticsResponseV2,
	ExploreResultsV2,
	PlotNumericResultsV2,
	DensityPlotResultsV2,
	TimeCourseResultsV2,
	CrosstabResultsV2,
	CorrelationsResultsV2,
	FrequenciesDecodedResultsV2,
	AnalysisOutputData,
	AnalysisErrorCodes,
	PlotNumericData
} from './types';
import { parseCorrelationsResponse } from 'helpers/analysis/parseCorrelationsResponse';
import { parseCorrelationStatisticsResponse } from 'helpers/analysis/parseCorrelationStatisticsResponse';
import { parseLinearRegressionStatisticResponse } from 'helpers/analysis/parseLinearRegressionStatisticResponse';
import { parseExploreResponse } from 'helpers/analysis/parseExploreResponse';
import { parseTimeCourseResponse } from 'helpers/analysis/parseTimeCourseResponse';
import { parseCompareNumericResponse } from 'helpers/analysis/parseCompareNumericResponse';
import { parseDensityPlotResponse } from 'helpers/analysis/parseDensityPlotResponse';
import { parsePlotNumericResponse } from 'helpers/analysis/parsePlotNumeric';
import { parseLogisticRegressionResponse } from 'helpers/analysis/parseLogisticRegressionResponse';
import { parseKaplanMeierResponse } from 'helpers/analysis/parseKaplanMeierResponse';
import { NumberMap } from 'types';
import { getTranslation } from 'hooks/store/ui/useTranslation';

export const methods = {
	// ANALYSIS
	compareNumericV1: 'superExploreVariable',
	compareNumericV2: 'descriptiveStatistics',
	correlationsV1: 'getScatterPlot',
	correlationsV2: 'scatterPlot',
	crosstab: 'getCrosstab',
	crosstabV2: 'crosstab',
	explore: 'getAverages',
	exploreV2: 'descriptiveStatistics',
	frequencies: 'getFrequencies',
	frequenciesV2: 'frequencies',
	kaplanMeier: {
		kaplanMeierDuration: 'getKaplanMeierDuration',
		kaplanMeierTimeRange: 'getKaplanMeierTimeRange'
	},
	kaplanMeierV2: {
		kaplanMeierDuration: 'kaplanMeierDuration',
		kaplanMeierTimeRange: 'kaplanMeierTimeRange'
	},
	plotNumeric: {
		columns: 'getNumericPlotColumns',
		boxplot: 'getNumericPlotBoxes',
		scatter: 'getNumericPlotScatter'
	},
	plotNumericV2: {
		columns: 'barPlot',
		boxplot: 'boxPlot',
		scatter: 'swarmPlot'
	},
	densityPlot: 'getKernelDensityEstimation',
	densityPlotV2: 'densityPlot',
	timeCourseV1: 'getTimeCourseExplore',
	timeCourseV2: 'timeCourse',
	comparePaired: {
		main: 'getPairedExplore',
		twoEntries: 'getPairedMainExplore',
		series: 'getPairedSeriesExplore'
	},
	comparePairedV2: {
		main: 'pairedDescriptiveStatisticsWide',
		twoEntries: 'pairedDescriptiveStatistics',
		series: 'pairedDescriptiveStatistics'
	},
	numberPlotXY: 'getPlotXY',
	// STATISTICS
	fisher: 'getFisherExactStatistics',
	fisherV2: 'fisherExactTest',
	chiSquare: 'getChiStatistics',
	chiSquareV2: 'chiSquaredTest',
	shapiroV1: 'getShapiroStatistics',
	shapiroV2: 'shapiroWilkTest',
	mannWhitneyV1: 'getMannWhitneyUTestStatistics',
	mannWhitneyV2: 'mannWhitneyTest',
	independentV1: 'getIndependentTTest',
	independentV2: 'independentTTest',
	spearmanV1: 'getSpearmanCorrelation',
	spearmanV2: 'correlation',
	pearsonV1: 'getPearsonCorrelation',
	pearsonV2: 'correlation',
	linearRegressionV1: 'getLinearRegression',
	linearRegressionV2: 'linearRegression',
	logisticRegression: 'getLogisticRegression',
	logisticRegressionV2: 'logisticRegression',
	oneWayAnovaV2: 'nWayAnova',
	oneWayAnovaV1: 'getOneWayAnova',
	logRank: {
		logRankDuration: 'getLogRankDuration',
		logRankTimeRange: 'getLogRankTimeRange'
	},
	logRankV2: {
		logRankDuration: 'logRankTestDuration',
		logRankTimeRange: 'logRankTestTimeRange'
	},
	tukeyV1: 'getTukey',
	tukeyV2: 'tukeyHSDTest',
	pairedTTest: {
		main: 'getPairedTTest',
		twoEntries: 'getPairedMainTTest',
		series: 'getPairedSeriesTTest'
	},
	pairedTTestV2: {
		single: 'pairedTTestWide',
		multiple: 'pairedTTest'
	},
	pairedWilcoxon: {
		main: 'getPairedWilcoxon',
		twoEntries: 'getPairedMainWilcoxon',
		series: 'getPairedSeriesWilcoxon'
	},
	pairedWilcoxonV2: {
		single: 'pairedWilcoxonTestWide',
		multiple: 'pairedWilcoxonTest'
	},
	getKruskalV1: 'getKruskal',
	getKruskalV2: 'kruskalWallisTest',
	getMcNemar: 'getMcnemar',
	getMcNemarV2: 'mcnemarTest',
	getTwoWayManovaV1: 'getTwoWayManova',
	getTwoWayManovaV2: 'nWayManova',
	getTwoWayAnovaV1: 'getTwoWayAnova',
	getTwoWayAnovaV2: 'nWayAnova',
	getOneWayManovaV1: 'getOneWayManova',
	getOneWayManovaV2: 'nWayManova',
	logisticReggresionStatistics: 'getLogisticReggresionStatistics'
};

const DEFAULT_ERR_MESSAGE = 'An error occurred, please check the logs';

const NUMERIC_PLOT_TYPE_TO_METHOD_MAP = {
	0: methods.plotNumericV2.columns,
	1: methods.plotNumericV2.boxplot,
	2: methods.plotNumericV2.scatter
};

export default () => ({
	async getCompareNumericV1(input: GetCompareNumericInputV1): Promise<GetCompareNumericOutputV1> {
		const res = await sendRequest<GetCompareNumericRequestV1, GetCompareNumericResponseV1>(
			STATISTICS_URL,
			{
				method: methods.compareNumericV1,
				...input
			}
		);

		const { data } = res;

		// TODO: CHANGE TO `data.httpStatusCode !== 200` WHEN BACKEND CHANGES RESPONSE
		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);
		}

		return data;
	},
	async getCompareNumericV2(input: GetCompareNumericInputV2): Promise<GetCompareNumericOutputV2> {
		const res = await sendRequest<GetCompareNumericRequestV2, GetCompareNumericResponseV2>(
			STATISTICS_URL,
			{
				method: methods.compareNumericV2,
				...input
			}
		);

		const { data } = res;

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return parseCompareNumericResponse(res);
	},

	async getCorrelations(input: GetCorrelationsInput): Promise<CorrelationsResultsV2> {
		const res: GetCorrelationsResponse = await sendRequest(STATISTICS_URL, {
			method: methods.correlationsV2,
			...input
		});

		const { data } = res;

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return parseCorrelationsResponse(res);
	},

	async getCorrelationsV1(input: GetCorrelationsInputV1) {
		const { data }: GetCorrelationsV1Response = await sendRequest(STATISTICS_URL, {
			method: methods.correlationsV1,
			...input
		});

		if (!Array.isArray(data)) throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);

		return data;
	},

	async getCrosstab(input: GetCrosstabInput): Promise<GetCrosstabOutput> {
		const { data } = await sendRequest<GetCrosstabRequest, GetCrosstabResponse>(
			STATISTICS_URL,
			{
				method: methods.crosstab,
				...input
			}
		);
		const hasData = data.crosstab;
		// TODO: ENABLE THIS BACK WHEN BE RESPONSE GET FIXED
		// if (data.httpStatusCode !== 200) {
		if (!hasData) {
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);
		}

		const { crosstab } = data;

		const categoryKeys = Object.keys(crosstab);
		const categoryLabels = categoryKeys.filter(rowName => rowName !== '__Total');
		const categoryTotals = categoryLabels.map(label => {
			const filteredResult = crosstab[label].filter(item => item.rowName === '__Total');
			return parseInt(filteredResult[0].count);
		});

		const columns = {
			name: input.column.variableName,
			label: input.column.variableName,
			categoryLabels: categoryLabels,
			categoryTotals: categoryTotals
		};

		const rows = [];

		const indexNameLabels = [
			...new Set(Object.values(crosstab).flatMap(arr => arr.map(item => item.rowName)))
		];

		const filteredLabels = indexNameLabels.filter(item => item !== '__Total');
		for (const label of filteredLabels) {
			const valueDetails = [];

			for (const categoryLabel of categoryLabels) {
				const item = crosstab[categoryLabel].find(elem => elem.rowName === label);
				if (item && item.rowName !== '__Total') {
					valueDetails.push({
						N: item.count,
						percent: item.percentage,
						variable: input.row.variableName,
						variableValue: item.rowName,
						groupVariable: input.column.variableName,
						groupVariableValue: categoryLabel
					} as CrosstabRowDetails);
				}
			}
			rows.push({
				label: label,
				valueDetails: valueDetails
			});
		}

		const output: GetCrosstabOutput = {
			yVariable: {
				label: input.row.variableName,
				name: input.row.variableName,
				rows: rows
			} as CrosstabRowsData,
			groupingVariable: columns as CrosstabColumnsData
		};
		return output;
	},

	async getCrosstabV2(input: GetCrosstabInputV2): Promise<CrosstabResultsV2> {
		const { data } = await sendRequest<GetCrosstabRequestV2, GetCrosstabResponseV2>(
			STATISTICS_URL,
			{
				method: methods.crosstabV2,
				...input
			}
		);

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}
		return {
			data: data.result
		};
	},

	async getExplore(input: GetExploreInput) {
		const { data }: GetExploreResponse = await sendRequest(STATISTICS_URL, {
			method: methods.explore,
			...input
		});

		if (!Array.isArray(data)) throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);

		return data;
	},

	async getExploreV2(input: GetExploreInputV2): Promise<ExploreResultsV2> {
		const { data }: GetExploreResponseV2 = await sendRequest(STATISTICS_URL, {
			method: methods.exploreV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return parseExploreResponse(data);
	},

	async getFrequencies(input: GetFrequenciesInput): Promise<FrequenciesDecodedResults> {
		const { data }: GetFrequenciesResponse = await sendRequest(STATISTICS_URL, {
			method: methods.frequencies,
			requestValueOrder: 'true',
			...input
		});

		if (data.errorMessage || data.statusMessage || !data.frequencies)
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);

		const decodedResults: FrequenciesDecodedResults['results'] = {};

		for (let i = 0; i < data.frequencies.length; i++) {
			const value = data.frequencies[i];
			const decodedKey = decodeURIComponentSafe(value.categoryValue ?? 'null-category-value');
			const count = value.count;

			decodedResults[decodedKey] = count;
		}

		const formattedData: FrequenciesDecodedResults = {
			results: decodedResults
		};
		return formattedData;
	},

	async getFrequenciesV2(
		input: GetFrequenciesInputV2
	): Promise<AnalysisOutputData<FrequenciesDecodedResultsV2>> {
		const { data }: GetFrequenciesResponseV2 = await sendRequest(STATISTICS_URL, {
			method: methods.frequenciesV2,
			requestValueOrder: 'true',
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}
		const dict: NumberMap = {};

		for (let i = 0; i < data.result?.length; i++) {
			const value = data.result[i];
			const decodedKey = decodeURIComponentSafe(value.categoryValue ?? 'null-category-value');
			const count = value.count;

			dict[decodedKey] = count;
		}

		const formattedData: FrequenciesDecodedResultsV2 = {
			dict,
			keys: data.result.map(item => item.categoryValue ?? 'null-category-value')
		};

		return {
			data: formattedData
		};
	},

	async getKaplanMeier(
		input: GetKaplanMeierRequestInput,
		dataModel: KaplanMeierDataModels
	): Promise<KaplanMeierResults> {
		let method = methods.kaplanMeier.kaplanMeierDuration;
		if (dataModel === KaplanMeierDataModels.timeRangeWithEvent) {
			method = methods.kaplanMeier.kaplanMeierTimeRange;
		}

		const { data }: GetKaplanMeierResponse = await sendRequest(STATISTICS_URL, {
			method,
			...input
		});

		if (data.errorCode || data.stackTrace)
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);

		// INIT DATA
		const results: KaplanMeierResults = {};

		if ('kaplanMeier' in data) {
			// SINGLE
			if (Array.isArray(data.kaplanMeier.lowerCI)) {
				const dataset = data.kaplanMeier;

				results.single = dataset;
			}
		}

		// GROUPED
		else if ('groupedKaplanMeier' in data) {
			data.groupedKaplanMeier.forEach(el => {
				results[el.group.groupValue] = el.kaplanMeier;
			});
		}

		return results;
	},
	async getKaplanMeierV2(
		input: GetKaplanMeierRequestInputV2,
		dataModel: KaplanMeierDataModels
	): Promise<KaplanMeierResultsV2> {
		let method = methods.kaplanMeierV2.kaplanMeierDuration;
		if (dataModel === KaplanMeierDataModels.timeRangeWithEvent) {
			method = methods.kaplanMeierV2.kaplanMeierTimeRange;
		}

		const { data }: GetKaplanMeierResponseV2 = await sendRequest(STATISTICS_URL, {
			method,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return parseKaplanMeierResponse(data.result);
	},
	async getPlotNumeric(input: GetPlotNumericInput, type: number): Promise<PlotNumericData> {
		let method = methods.plotNumeric.columns;

		if (type === 1) {
			method = methods.plotNumeric.boxplot;
			delete input.errorBar;
		} else if (type === 2) {
			method = methods.plotNumeric.scatter;
			delete input.errorBar;
			delete input.groupingVariable;
		}

		const { data }: GetPlotNumericResponse = await sendRequest(STATISTICS_URL, {
			method,
			...input
		});

		if (data.errorMessage || data.statusMessage)
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);

		return data;
	},

	async getPlotNumericV2(
		input: GetPlotNumericInputV2,
		type: number
	): Promise<PlotNumericResultsV2> {
		const method =
			NUMERIC_PLOT_TYPE_TO_METHOD_MAP[type as keyof typeof NUMERIC_PLOT_TYPE_TO_METHOD_MAP];

		const res: GetPlotNumericResponseV2 = await sendRequest(STATISTICS_URL, {
			method,
			...input
		});
		const { data } = res;

		if (data?.statusCode !== 200 && data.errors) {
			const error = data.errors?.[0];
			return {
				boxplot: {
					data: null,
					error
				},
				columns: {
					data: null,
					error
				},
				scatter: {
					data: null,
					error
				}
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return parsePlotNumericResponse(res);
	},

	async getDensityPlot(input: GetDensityPlotInput) {
		const { data }: GetDensityPlotResponse = await sendRequest(STATISTICS_URL, {
			method: methods.densityPlot,
			...input
		});

		if (!Array.isArray(data)) throw new Error();

		return data;
	},

	async getDensityPlotV2(input: GetDensityPlotInputV2): Promise<DensityPlotResultsV2> {
		const res: GetDensityPlotResponseV2 = await sendRequest(STATISTICS_URL, {
			method: methods.densityPlotV2,
			...input
		});
		const { data } = res;

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		const parsed = parseDensityPlotResponse(res);

		return parsed;
	},

	async getTimeCourseV1(input: TimeCourseInputV1) {
		const { data }: GetTimeCourseResponseV1 = await sendRequest(STATISTICS_URL, {
			method: methods.timeCourseV1,
			...input
		});

		// TODO: CHANGE TO `data.httpStatusCode !== 200` WHEN BACKEND CHANGES RESPONSE
		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);
		}

		return data;
	},

	async getTimeCourseV2(input: TimeCourseInputV2): Promise<TimeCourseResultsV2> {
		const res: GetTimeCourseResponseV2 = await sendRequest(STATISTICS_URL, {
			method: methods.timeCourseV2,
			...input
		});

		const { data } = res;

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		const parsedData = parseTimeCourseResponse(res);

		if (parsedData) {
			return parsedData;
		}

		throw new Error(DEFAULT_ERR_MESSAGE);
	},

	async getComparePaired(
		input: GetComparePairedInput,
		setName?: string,
		single?: boolean
	): Promise<GetComparePairedOutput> {
		const { data } = await sendRequest<GetComparePairedRequest, GetComparePairedResponse>(
			STATISTICS_URL,
			{
				method: single
					? methods.comparePaired.main
					: setName
					? methods.comparePaired.series
					: methods.comparePaired.twoEntries,
				...input
			}
		);

		const output: GetComparePairedOutput = {
			data: null
		};

		if (data.delta_stats) {
			output.data = {
				delta_stats: data.delta_stats,
				sample_values: data.sample_stats
			};
		} else {
			output.error = true;
			output.errorMessage = data.ledidiStatusText;
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText || DEFAULT_ERR_MESSAGE);
		}

		return output;
	},

	async getComparePairedV2(
		input: GetComparePairedInputV2,
		single?: boolean
	): Promise<GetComparePairedOutputV2> {
		const { data } = await sendRequest<GetComparePairedRequestV2, GetComparePairedResponseV2>(
			STATISTICS_URL,
			{
				method: single ? methods.comparePairedV2.main : methods.comparePairedV2.series,
				...input
			}
		);

		if (data?.statusCode !== 200 && data.errors) {
			const error = data.errors[0];
			if (error.code === AnalysisErrorCodes.IncompatibleNumberOfGroups) {
				const keyCode = error.code.split('.')[1];
				throw new Error(
					getTranslation(
						dict =>
							//@ts-ignore
							dict.analysis.analyses.error[keyCode as string],
						false,
						error.values
					)
				);
			}
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return {
			data: data.result
		};
	},

	async getNumberPlotXY(input: GetNumberPlotXYInput): Promise<GetNumberPlotXYOutput> {
		const { data } = await sendRequest<GetNumberPlotXYRequest, GetNumberPlotXYResponse>(
			STATISTICS_URL,
			{
				method: methods.numberPlotXY,
				...input
			}
		);

		const output: GetNumberPlotXYOutput = {
			data: null
		};

		if (data.PlotResult) {
			output.data = {
				PlotResult: {
					x_value: data.PlotResult.x_value,
					y_value: data.PlotResult.y_value[0]
				}
			};
		} else {
			output.error = true;
			output.errorMessage = data.ledidiStatusText;
		}

		return output;
	},

	async getLogisticReggresion(
		input: GetLogisticRegressionInput
	): Promise<GetLogisticRegressionOutput> {
		const { data } = await sendRequest<
			GetLogisticRegressionRequest,
			GetLogisticRegressionResponse
		>(STATISTICS_URL, {
			method: methods.logisticRegression,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(data.message);
		}

		const output: GetLogisticRegressionOutput =
			data.logisticRegression ?? data.groupedLogisticRegression;

		return output;
	},

	async getLogisticReggresionV2(
		input: GetLogisticRegressionInputV2
	): Promise<GetLogisticRegressionOutputV2> {
		const res = await sendRequest<
			GetLogisticRegressionRequestV2,
			GetLogisticRegressionResponseV2
		>(STATISTICS_URL, {
			method: methods.logisticRegressionV2,
			...input
		});

		const { data } = res;

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return parseLogisticRegressionResponse(res);
	},

	//statistics

	async getFisherStatistics(input: GetFisherStatisticsInput): Promise<GetFisherStatisticsOutput> {
		const { data } = await sendRequest<GetFisherStatisticsRequest, GetFisherStatisticsResponse>(
			STATISTICS_URL,
			{
				method: methods.fisher,
				...input
			}
		);

		const output: GetFisherStatisticsOutput = {
			data: null
		};

		if (!data.fisherResults) {
			output.error = true;
		} else {
			output.data = data.fisherResults;
		}

		return output;
	},

	async getFisherStatisticsV2(
		input: GetFisherStatisticsInputV2
	): Promise<GetFisherStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetFisherStatisticsRequestV2,
			GetFisherStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.fisherV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		let output: GetFisherStatisticsOutputV2 = {
			data: null,
			error: data.errors?.[0]
		};

		if (data.result) {
			const { errors, ...result } = data.result;

			output = {
				data: {
					...result
				},
				error: data.result.errors?.[0]
			};
		}

		return output;
	},

	async getChiStatistics(
		input: GetChiSquareStatisticsInput
	): Promise<GetChiSquareStatisticsOutput> {
		const { data } = await sendRequest<
			GetChiSquareStatisticsRequest,
			GetChiSquareStatisticsResponse
		>(STATISTICS_URL, {
			method: methods.chiSquare,
			...input
		});

		const output: GetChiSquareStatisticsOutput = {
			data: null
		};

		if (data.chiSquareResults) {
			output.data = data.chiSquareResults;
		} else {
			output.error = true;
			output.errorMessage = data.errorMessage;
		}

		return output;
	},

	async getChiStatisticsV2(
		input: GetChiSquareStatisticsInputV2
	): Promise<GetChiSquareStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetChiSquareStatisticsRequestV2,
			GetChiSquareStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.chiSquareV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		let output: GetChiSquareStatisticsOutputV2 = {
			data: null
		};

		if (data.result) {
			output = {
				data: {
					...data.result
				},
				error: data.result.errors?.[0]
			};
		}
		return output;
	},

	async getShapiroStatisticsV1(
		input: GetShapiroStatisticsInputV1
	): Promise<GetShapiroStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetShapiroStatisticsRequestV1,
			GetShapiroStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.shapiroV1,
			...input
		});

		const output: GetShapiroStatisticsOutputV1 = {
			data: null
		};
		if (data.shapiroWilk) {
			output.data = data.shapiroWilk;
		} else {
			output.error = true;
			// output.errorMessage = data.errorMessage; // implement when returned
		}

		return output;
	},

	async getShapiroStatisticsV2(
		input: GetShapiroStatisticsInputV2
	): Promise<GetShapiroStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetShapiroStatisticsRequestV2,
			GetShapiroStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.shapiroV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return {
			data: data.result.map(d => ({
				pValue: d.values.pValue
			}))
		};
	},

	async getMannWhitneyStatisticsV1(
		input: GetMannWhitneyStatisticsInputV1
	): Promise<GetMannWhitneyStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetMannWhitneyStatisticsRequestV1,
			GetMannWhitneyStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.mannWhitneyV1,
			...input
		});

		if (data.httpStatusCode !== 200 && data.ledidiStatusText) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		if (data.errors?.length) {
			throw new Error(data.errors?.[0].message);
		}

		const output: GetMannWhitneyStatisticsOutputV1 = {
			data: null
		};
		if (data.uTestResults) {
			output.data = {
				pValue: data.uTestResults.pValue,
				wStat: data.uTestResults.wStat
			};
		} else {
			output.error = true;
		}

		return output;
	},

	async getMannWhitneyStatisticsV2(
		input: GetMannWhitneyStatisticsInputV2
	): Promise<GetMannWhitneyStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetMannWhitneyStatisticsRequestV2,
			GetMannWhitneyStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.mannWhitneyV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return {
			data: {
				pValue: data.result.pValue,
				statistic: data.result.statistic
			},
			error: data.result.error
		};
	},

	async getIndependentStatisticsV1(
		input: GetIndependentStatisticsInputV1
	): Promise<GetIndependentStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetIndependentStatisticsRequestV1,
			GetIndependentStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.independentV1,
			...input
		});

		if (data.httpStatusCode !== 200 && data.ledidiStatusText) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		if (data.errors?.length) {
			throw new Error(data.errors?.[0].message);
		}

		const output: GetIndependentStatisticsOutputV1 = {
			data: null
		};
		if (data.independetTTest) {
			const { degrees_of_freedom, pValue, tStat } = data.independetTTest;
			output.data = {
				degrees_of_freedom,
				pValue,
				tStat
			};
		} else {
			output.error = true;
		}

		return output;
	},

	async getIndependentStatisticsV2(
		input: GetIndependentStatisticsInputV2
	): Promise<GetIndependentStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetIndependentStatisticsRequestV2,
			GetIndependentStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.independentV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode !== 200 && data.ledidiStatusText) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return {
			data: {
				degrees_of_freedom: data.result.df,
				pValue: data.result.pValue,
				tStat: data.result.statistic
			},
			error: data.errors?.[0] ?? data.result.error
		};
	},

	async getPearsonStatisticsV2(
		input: GetPearsonStatisticsInput
	): Promise<GetPearsonStatisticsOutput> {
		const res = await sendRequest<GetPearsonStatisticsRequest, GetPearsonStatisticsResponse>(
			STATISTICS_URL,
			{
				...input,
				type: 'pearson',
				method: methods.pearsonV2
			}
		);

		if (res.data.httpStatusCode !== 200 && res.data.ledidiStatusText) {
			throw new Error(res.data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		if (res.data.errors?.length) {
			throw new Error(res.data.errors?.[0].message);
		}

		const output = parseCorrelationStatisticsResponse(res);

		return output;
	},

	async getPearsonStatisticsV1(
		input: GetPearsonStatisticsV1Input
	): Promise<GetPearsonStatisticsV1Output> {
		const { data } = await sendRequest<
			GetPearsonStatisticsV1Request,
			GetPearsonStatisticsV1Response
		>(STATISTICS_URL, {
			method: methods.pearsonV1,
			...input
		});
		const output: GetPearsonStatisticsV1Output = {
			data: null
		};

		if (Array.isArray(data)) {
			output.data = data.map(g => ({
				group: g.group,
				rValue: g.pearson,
				pValue: g.pearsonPValue,
				error: g.error
			}));
		} else {
			output.error = true;
		}

		return output;
	},

	async getSpearmanStatisticsV1(
		input: GetSpearmanStatisticsV1Input
	): Promise<GetSpearmanStatisticsV1Output> {
		const { data } = await sendRequest<
			GetSpearmanStatisticsV1Request,
			GetSpearmanStatisticsV1Response
		>(STATISTICS_URL, {
			method: methods.spearmanV1,
			...input
		});

		if (data.httpStatusCode !== 200 && data.ledidiStatusText) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		if (data.errors?.length) {
			throw new Error(data.errors?.[0].message);
		}

		const output: GetSpearmanStatisticsV1Output = {
			data: null
		};

		if (Array.isArray(data)) {
			output.data = data.map(g => ({
				group: g.group,
				rValue: g.spearman,
				pValue: g.spearmanPValue,
				error: g.error
			}));
		} else {
			output.error = true;
		}

		return output;
	},

	async getSpearmanStatisticsV2(
		input: GetSpearmanStatisticsInput
	): Promise<GetSpearmanStatisticsOutput> {
		const res = await sendRequest<GetSpearmanStatisticsRequest, GetSpearmanStatisticsResponse>(
			STATISTICS_URL,
			{
				...input,
				method: methods.spearmanV2,
				type: 'spearman'
			}
		);

		if (res.data.httpStatusCode !== 200 && res.data.ledidiStatusText) {
			throw new Error(res.data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		if (res.data.errors?.length) {
			throw new Error(res.data.errors?.[0].message);
		}

		const output = parseCorrelationStatisticsResponse(res);

		return output;
	},

	async getLinearRegressionStatisticsV1(
		input: GetLinearRegressionStatisticsV1Input
	): Promise<GetLinearRegressionStatisticsV1Output> {
		const { data } = await sendRequest<
			GetLinearRegressionStatisticsV1Request,
			GetLinearRegressionStatisticsV1Response
		>(STATISTICS_URL, {
			method: methods.linearRegressionV1,
			...input
		});

		const output: GetLinearRegressionStatisticsV1Output = {
			data: null
		};

		if (Array.isArray(data)) {
			output.data = data.map(g => ({
				group: g.group,
				rValue: g.linearRValue,
				pValue: g.linearPValue,
				slope: g.slope,
				intercept: g.intercept,
				error: g.error
			}));
		} else {
			output.error = true;
		}

		return output;
	},

	async getLinearRegressionStatisticsV2(
		input: GetLinearRegressionStatisticsInput
	): Promise<GetLinearRegressionStatisticsOutput> {
		const res = await sendRequest<
			GetLinearRegressionStatisticsRequest,
			GetLinearRegressionStatisticsResponse
		>(STATISTICS_URL, {
			method: methods.linearRegressionV2,
			...input
		});

		let output: GetLinearRegressionStatisticsOutput = {
			data: null
		};

		// errored on UNGROUPED OR 500 internal?
		// grouped results errors are handled inside components (they are non-breaking)
		if (!res.data?.result) {
			throw new Error(res.data?.ledidiStatusText ?? res.data?.message);
		}

		if (res.data.errors?.length) {
			throw new Error(res.data.errors?.[0].message);
		}

		output = {
			...output,
			...parseLinearRegressionStatisticResponse(res)
		};

		return output;
	},

	async getOneWayAnovaStatisticsV1(
		input: GetOneWayAnovaStatisticsInputV1
	): Promise<GetOneWayAnovaStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetOneWayAnovaStatisticsRequestV1,
			GetOneWayAnovaStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.oneWayAnovaV1,
			...input
		});

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		const output: GetOneWayAnovaStatisticsOutputV1 = {
			data: null
		};

		if (!data.anovaResults) {
			output.error = true;
		} else {
			const { anovaResults: apiAnovaResults } = data;
			const { error, ...results } = apiAnovaResults;
			const result = results[input.categoryVariable];
			if (result) {
				output.data = {
					fValue: result.f,
					pValue: result.pValue,
					df: result.df,
					errorDf: error.df
				};
			}
		}
		return output;
	},
	async getOneWayAnovaStatisticsV2(
		input: GetOneWayAnovaStatisticsInputV2
	): Promise<GetOneWayAnovaStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetOneWayAnovaStatisticsRequestV2,
			GetOneWayAnovaStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.oneWayAnovaV2,
			...input
		});

		// check for v2 shape first
		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return {
			data: data.result.reduce((acc, curr) => {
				const key = curr.variables.map(variable => variable.name).join(' * ');
				const { variables, ...values } = curr;
				return { ...acc, [key]: values };
			}, {})
		};
	},

	async getLogRankStatistics(
		input: GetLogRankStatisticsInput,
		dataModel: KaplanMeierDataModels
	): Promise<GetLogRankStatisticsOutput> {
		let method = methods.logRank.logRankDuration;
		if (dataModel === KaplanMeierDataModels.timeRangeWithEvent) {
			method = methods.logRank.logRankTimeRange;
		}
		const { data } = await sendRequest<
			GetLogRankStatisticsRequest,
			GetLogRankStatisticsResponse
		>(STATISTICS_URL, {
			method: method,
			...input
		});

		const output: GetLogRankStatisticsOutput = {
			data: null
		};

		if (!data.logRank) {
			output.error = true;
			output.errorMessage = data.ledidiStatusText;
		} else {
			const { pValue, statistic } = data.logRank;

			output.data = {
				pValue,
				statistic
			};
		}

		return output;
	},

	async getLogRankStatisticsV2(
		input: GetLogRankStatisticsInputV2,
		dataModel: KaplanMeierDataModels
	): Promise<GetLogRankStatisticsOutputV2> {
		let method = methods.logRankV2.logRankDuration;
		if (dataModel === KaplanMeierDataModels.timeRangeWithEvent) {
			method = methods.logRankV2.logRankTimeRange;
		}
		const { data } = await sendRequest<
			GetLogRankStatisticsRequestV2,
			GetLogRankStatisticsResponseV2
		>(STATISTICS_URL, {
			method: method,
			...input
		});

		const output: GetLogRankStatisticsOutputV2 = {
			data: null
		};

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		const { pValue, statistic } = data.result;

		output.data = {
			pValue,
			statistic
		};
		output.error = data.errors?.[0];

		return output;
	},

	async getTukeyStatisticsV1(
		input: GetTukeyStatisticsInputV1
	): Promise<GetTukeyStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetTukeyStatisticsRequestV1,
			GetTukeyStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.tukeyV1,
			...input
		});

		const output: GetTukeyStatisticsOutputV1 = {
			data: null
		};

		if (!data.result) {
			output.error = true;
			output.errorMessage = data.ledidiStatusText;
		} else {
			output.data = data.result;
		}

		return output;
	},

	async getTukeyStatisticsV2(
		input: GetTukeyStatisticsInputV2
	): Promise<GetTukeyStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetTukeyStatisticsRequestV2,
			GetTukeyStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.tukeyV2,
			...input
		});

		const output: GetTukeyStatisticsOutputV2 = {
			data: null
		};

		if (data.httpStatusCode !== 200 && data.ledidiStatusText) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		if (data.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		output.data = data.result;

		return output;
	},

	async getPairedTTestStatistics(
		input: GetPairedTTestStatisticsInput,
		setName?: string,
		single?: boolean
	): Promise<GetPairedTTestStatisticsOutput> {
		const { data } = await sendRequest<
			GetPairedTTestStatisticsRequest,
			GetPairedTTestStatisticsResponse
		>(STATISTICS_URL, {
			method: single
				? methods.pairedTTest.main
				: setName
				? methods.pairedTTest.series
				: methods.pairedTTest.twoEntries,
			...input
		});

		const output: GetPairedTTestStatisticsOutput = {
			data: null
		};

		if (!data.paired_t_test) {
			output.error = true;
			output.errorMessage = data.ledidiStatusText;
		} else {
			const { tStat, pValue, df } = data.paired_t_test.pairedTTest;

			output.data = {
				tStat,
				pValue,
				df
			};
		}

		return output;
	},

	async getPairedTTestStatisticsV2({
		input,
		dataModel
	}: {
		input: GetPairedTTestStatisticsInputV2;
		dataModel: ComparePairedDataModels;
	}): Promise<GetPairedTTestStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetPairedTTestStatisticsRequestV2,
			GetPairedTTestStatisticsResponseV2
		>(STATISTICS_URL, {
			method:
				dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
					? methods.pairedTTestV2.single
					: methods.pairedTTestV2.multiple,
			...input
		});

		const output: GetPairedTTestStatisticsOutputV2 = {
			data: null
		};

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		output.data = data.result;

		return output;
	},

	async getPairedWilcoxonStatistics(
		input: GetPairedWilcoxonStatisticsInput,
		setName?: string,
		single?: boolean
	): Promise<GetPairedWilcoxonStatisticsOutput> {
		const { data } = await sendRequest<
			GetPairedWilcoxonStatisticsRequest,
			GetPairedWilcoxonStatisticsResponse
		>(STATISTICS_URL, {
			method: single
				? methods.pairedWilcoxon.main
				: setName
				? methods.pairedWilcoxon.series
				: methods.pairedWilcoxon.twoEntries,
			...input
		});

		const output: GetPairedWilcoxonStatisticsOutput = {
			data: null
		};

		if (!data.paired_wilcoxon_test) {
			output.error = true;
			output.errorMessage = data.ledidiStatusText;
		} else {
			const { tStat, pValue } = data.paired_wilcoxon_test.pairedWilcoxon;

			output.data = {
				tStat,
				pValue
			};
		}

		return output;
	},

	async getPairedWilcoxonStatisticsV2({
		input,
		dataModel
	}: {
		input: GetPairedWilcoxonStatisticsInputV2;
		dataModel: ComparePairedDataModels;
	}): Promise<GetPairedWilcoxonStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetPairedWilcoxonStatisticsRequestV2,
			GetPairedWilcoxonStatisticsResponseV2
		>(STATISTICS_URL, {
			method:
				dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
					? methods.pairedWilcoxonV2.single
					: methods.pairedWilcoxonV2.multiple,
			...input
		});

		const output: GetPairedWilcoxonStatisticsOutputV2 = {
			data: null
		};

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		output.data = data.result;

		return output;
	},

	async getKruskalStatisticsV1(
		input: GetKruskalStatisticsInputV1
	): Promise<GetKruskalStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetKruskalStatisticsRequestV1,
			GetKruskalStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.getKruskalV1,
			...input
		});

		const output: GetKruskalStatisticsOutputV1 = {
			data: null
		};

		if (!data.kruskalResults) {
			output.error = true;
		} else {
			const { kruskalResults: apiKruskalResults } = data;
			output.data = {
				df: apiKruskalResults.df,
				stat: apiKruskalResults.stat,
				pValue: apiKruskalResults.pValue,
				validTest: stringAsBoolean(apiKruskalResults.validTest)
			};
		}
		return output;
	},
	async getKruskalStatisticsV2(
		input: GetKruskalStatisticsInputV2
	): Promise<GetKruskalStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetKruskalStatisticsRequestV2,
			GetKruskalStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.getKruskalV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		return {
			data: {
				df: data.result.df,
				pValue: data.result.pValue,
				statistic: data.result.statistic
			},
			warnings: data.result.warnings?.[0],
			error: data.errors?.[0]
		};
	},

	async getMcNemarStatistics(
		input: GetMcNemarStatisticsInput
	): Promise<GetMcNemarStatisticsOutput> {
		const { data } = await sendRequest<
			GetMcNemarStatisticsRequest,
			GetMcNemarStatisticsResponse
		>(STATISTICS_URL, {
			method: methods.getMcNemar,
			...input
		});

		const output: GetMcNemarStatisticsOutput = {
			data: null
		};

		if (!data.mcnemarResults) {
			output.error = true;
		} else {
			const { mcnemarResults: apiMcNemarResults } = data;

			output.data = {
				stat: apiMcNemarResults.stat,
				pValue: apiMcNemarResults.pValue,
				exact: stringAsBoolean(apiMcNemarResults.exact)
			};
		}

		return output;
	},

	async getMcNemarStatisticsV2(
		input: GetMcNemarStatisticsInputV2
	): Promise<GetMcNemarStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetMcNemarStatisticsRequestV2,
			GetMcNemarStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.getMcNemarV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		let output: GetMcNemarStatisticsOutputV2 = {
			data: null,
			error: data.errors?.[0]
		};

		if (data.result) {
			output = {
				data: {
					...data.result
				},
				error: data.result.errors?.[0]
			};
		}
		return output;
	},

	async getTwoWayManovaStatisticsV1(
		input: GetTwoWayManovaStatisticsInputV1
	): Promise<GetTwoWayManovaStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetTwoWayManovaStatisticsRequestV1,
			GetTwoWayManovaStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.getTwoWayManovaV1,
			...input
		});

		const output: GetTwoWayManovaStatisticsOutputV1 = {
			data: null
		};
		if (!data.manovaResults) {
			output.error = true;
		} else {
			const { manovaResults: apiManovaResults } = data;
			const { Intercept: intercept, testType, ...results } = apiManovaResults;
			output.data = {
				dynamic: results,
				intercept,
				testType,
				expanded: {
					pillaisTrace: false,
					hotellingTrace: false,
					roysRoot: false,
					wilksLamda: false
				}
			};
		}
		return output;
	},

	async getTwoWayManovaStatisticsV2(
		input: GetTwoWayManovaStatisticsInputV2
	): Promise<GetTwoWayManovaStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetTwoWayManovaStatisticsRequestV2,
			GetTwoWayManovaStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.getTwoWayManovaV2,
			...input
		});

		if (data?.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		// parse
		return {
			data: data.result.reduce((acc, curr) => {
				const key = curr.variables.map(variable => variable.name).join(' * ');
				const { variables, ...values } = curr;
				return { ...acc, [key]: values };
			}, {})
		};
	},

	async getTwoWayAnovaStatisticsV1(
		input: GetTwoWayAnovaStatisticsInputV1
	): Promise<GetTwoWayAnovaStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetTwoWayAnovaStatisticsRequestV1,
			GetTwoWayAnovaStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.getTwoWayAnovaV1,
			...input
		});

		const output: GetTwoWayAnovaStatisticsOutputV1 = {
			data: null
		};
		if (!data.anovaResults) {
			output.error = true;
		} else {
			const { anovaResults: apiAnovaResults } = data;
			const { intercept, testType, condition_number, error, total, ...results } =
				apiAnovaResults;
			output.data = {
				dynamic: results,
				intercept,
				testType,
				condition_number,
				error,
				total
			};
		}

		return output;
	},

	async getTwoWayAnovaStatisticsV2(
		input: GetTwoWayAnovaStatisticsInputV2
	): Promise<GetTwoWayAnovaStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetTwoWayAnovaStatisticsRequestV2,
			GetTwoWayAnovaStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.getTwoWayAnovaV2,
			...input
		});

		// check for v2 shape first
		if (data.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: data.errors?.[0]
			};
		}

		if (data.httpStatusCode !== 200 && data.ledidiStatusText) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}
		return {
			data: data.result
		};
	},

	async getOneWayManovaStatisticsV1(
		input: GetOneWayManovaStatisticsInputV1
	): Promise<GetOneWayManovaStatisticsOutputV1> {
		const { data } = await sendRequest<
			GetOneWayManovaStatisticsRequestV1,
			GetOneWayManovaStatisticsResponseV1
		>(STATISTICS_URL, {
			method: methods.getOneWayManovaV1,
			...input
		});

		const output: GetOneWayManovaStatisticsOutputV1 = {
			data: null
		};
		if (!data.manovaResults) {
			output.error = true;
		} else {
			const { manovaResults: apiManovaResults } = data;
			const { Intercept: intercept, testType, ...results } = apiManovaResults;
			output.data = {
				dynamic: results,
				intercept,
				testType,
				expanded: {
					pillaisTrace: true,
					hotellingTrace: true,
					roysRoot: true,
					wilksLamda: true
				}
			};
		}

		return output;
	},

	async getOneWayManovaStatisticsV2(
		input: GetOneWayManovaStatisticsInputV2
	): Promise<GetOneWayManovaStatisticsOutputV2> {
		const { data } = await sendRequest<
			GetOneWayManovaStatisticsRequestV2,
			GetOneWayManovaStatisticsResponseV2
		>(STATISTICS_URL, {
			method: methods.getOneWayManovaV2,
			...input
		});

		// check for v2 shape first
		if (data.statusCode !== 200 && data.errors) {
			return {
				data: null,
				error: {
					message: data.errors?.[0].message,
					code: data.errors?.[0].code
				}
			};
		}

		if (data.httpStatusCode && data.httpStatusCode !== 200) {
			throw new Error(data.ledidiStatusText ?? DEFAULT_ERR_MESSAGE);
		}

		// parse
		return {
			data: data.result.reduce((acc, curr) => {
				const key = curr.variables.map(variable => variable.name).join(' * ');
				const { variables, ...values } = curr;
				return { ...acc, [key]: values };
			}, {})
		};
	}
});

import { GetAnalysisResultsOutput } from 'api/addons/jadbio';
import {
	JADBioModelElement,
	JADBioAnalysis,
	JADBioAnalysisModels,
	JADBioScatterPlot,
	JADBioScatterGroup,
	JADBioFeatureImportanceElement,
	JADBioProgressiveFeatureImportanceElement,
	PlotNumericBoxplotUngroupedData,
	JADBioOutputMap
} from 'api/data/analyses';
import { JADBIO_PROBABILITIES_DENSITY_SEGMENTS } from 'consts';
import { ApplicationState } from 'store';

export function parseJADBioAnalysisResult(
	analysesResult: any,
	jadBioAnalysisId: string
): JADBioOutputMap {
	const elements: JADBioModelElement[] = [];

	const models = analysesResult.models;
	if (models) {
		Object.keys(models).forEach(key => {
			const element: JADBioModelElement = {
				type: key,
				featureSelection: models[key].featureSelection ?? '',
				model: models[key].model ?? '',
				preprocessing: models[key].preprocessing ?? '',
				performance: models[key].performance ?? {},
				signatures: models[key].signatures ?? []
			};
			elements.push(element);
		});
	} else {
		throw Error('Unable to parse JADBio data - models missing');
	}

	const plots = analysesResult.plots;
	if (!plots.best || !plots.best.values || !plots.best.values.plots || !plots.best.boxPlot) {
		throw Error('Unable to parse JADBio data - plots missing');
	} else {
		const plotsModel = plots.best.values.plots;
		const boxPlot = plots.best.boxPlot; // might be temporary till response is restructured
		const PCA = parseJADBioScatterPlot(plotsModel, 'PCA');
		const UMAP = parseJADBioScatterPlot(plotsModel, 'UMAP');
		const probabilitiesDensityPlot = parseJADBioDensityPlot(plotsModel, 'Probabilities');
		const probabilitiesBoxPlot: PlotNumericBoxplotUngroupedData[] = [];
		probabilitiesBoxPlot.push(parseJADBioBoxPlotCategoryItem(boxPlot, 0));
		probabilitiesBoxPlot.push(parseJADBioBoxPlotCategoryItem(boxPlot, 1));
		const featureImportance = parseJADBioFeatureSelection(
			plotsModel,
			'Feature Importance'
		) as JADBioFeatureImportanceElement[];
		const progressiveFeatureImportance = parseJADBioFeatureSelection(
			plotsModel,
			'Progressive Feature Importance'
		) as JADBioProgressiveFeatureImportanceElement[];

		return {
			classification: {
				summary: elements,
				plots: {
					performance: {
						PCA,
						UMAP,
						probabilitiesDensityPlot,
						probabilitiesBoxPlot,
						featureImportance,
						progressiveFeatureImportance
					}
				},
				progress: null,
				pauseProgress: false,
				stopProgress: false,
				jadBioAnalysisId
			}
		};
	}
}

function parseJADBioScatterPlot(JADBioPlots: any[], plotName: string): JADBioScatterPlot | null {
	if (JADBioPlots) {
		const JADBioPlot = JADBioPlots.find(plot => plot[plotName] !== undefined);
		if (!JADBioPlot) {
			return null;
		}

		let plotBody = JADBioPlot[plotName];
		if (plotBody instanceof Array) {
			// this might have to be analyzed more in the future
			plotBody = plotBody[0];
		}
		const groups: JADBioScatterGroup[] = [];
		plotBody.points.forEach((element: any) => {
			const groupElement = groups.find(
				parsedElem => parsedElem.group === plotBody.legend[element.categoryID]
			);
			if (groupElement) {
				groupElement.xValues.push(element.x);
				groupElement.yValues.push(element.y);
			} else {
				const group = {
					xValues: [element.x],
					yValues: [element.y],
					group: plotBody.legend[element.categoryID]
				};
				groups.push(group);
			}
		});

		const pcaPlotParsed: JADBioScatterPlot = {
			title: plotBody.title,
			xaxis: plotBody.xaxis,
			yaxis: plotBody.yaxis,
			groups: groups
		};

		return pcaPlotParsed;
	} else {
		throw Error('Unable to parse JADbio data missing');
	}
}

function parseJADBioDensityPlot(JADBioPlots: any[], plotName: string): JADBioScatterPlot | null {
	if (JADBioPlots) {
		const JADBioPlot = JADBioPlots.find(plot => plot[plotName] !== undefined);
		if (!JADBioPlot) {
			return null;
		}

		let plotBody = JADBioPlot[plotName];
		if (plotBody instanceof Array) {
			// this might have to be analyzed more in the future
			plotBody = plotBody[0];
		}

		const groups: JADBioScatterGroup[] = [];
		plotBody.points.forEach((element: any) => {
			const groupElement = groups.find(
				parsedElem => parsedElem.group === plotBody.legend[element.categoryID]
			);
			if (groupElement) {
				groupElement.xValues.push(element.x);
				groupElement.yValues.push(element.y);
			} else {
				const group = {
					xValues: [element.x],
					yValues: [element.y],
					group: plotBody.legend[element.categoryID]
				};
				groups.push(group);
			}
		});

		// This is temporary till backend are sending us the calculated data for the plot area
		const finalGroups: JADBioScatterGroup[] = [];
		groups.forEach(group => {
			const segments = [];
			let count = 0;
			while (count <= JADBIO_PROBABILITIES_DENSITY_SEGMENTS) {
				segments.push((1 / JADBIO_PROBABILITIES_DENSITY_SEGMENTS) * count);
				count++;
			}
			const xDensityValues: number[] = [];
			const segmentLength = 1 / JADBIO_PROBABILITIES_DENSITY_SEGMENTS;
			segments.forEach((segment, i) => {
				xDensityValues[i] = 0;
				group.xValues.forEach(xValue => {
					if (
						xValue > segment - segmentLength / 2 &&
						xValue < segment + segmentLength / 2
					) {
						xDensityValues[i] = xDensityValues[i] + 1;
					}
				});
			});
			const finalGroup = {
				xValues: segments,
				yValues: xDensityValues,
				group: group.group
			};
			finalGroups.push(finalGroup);
		});

		const pcaPlotParsed: JADBioScatterPlot = {
			title: plotBody.title,
			xaxis: plotBody.xaxis,
			yaxis: plotBody.yaxis,
			groups: finalGroups
		};
		return pcaPlotParsed;
	} else {
		throw Error('Unable to parse JADbio data - Performance PCA missing');
	}
}

interface BoxPlotGroup {
	median: string | number;
	minimum: string;
	maximum: string;
	IQRLower: string;
	IQRUpper: string;
}

function parseJADBioBoxPlotCategoryItem(
	boxPlotRes: any,
	categorizationIndex: number
): PlotNumericBoxplotUngroupedData {
	if (boxPlotRes) {
		const boxPlotValues: BoxPlotGroup[] = Object.values(boxPlotRes);
		const boxPlotGroup = boxPlotValues[categorizationIndex];
		if (!boxPlotGroup) {
			throw Error('Unable to parse JADBio data - Probabilities Box Plot');
		}

		const boxPlot: PlotNumericBoxplotUngroupedData = {
			categorizationValue: Object.keys(boxPlotRes)[categorizationIndex],
			median: boxPlotGroup.median,
			errorValueMin: boxPlotGroup.minimum,
			errorValueMax: boxPlotGroup.maximum,
			IQRLower: boxPlotGroup.IQRLower,
			IQRUpper: boxPlotGroup.IQRUpper
		};
		return boxPlot;
	} else {
		throw Error('Unable to parse JADbio data - Performance PCA missing');
	}
}

function parseJADBioFeatureSelection(
	JADBioPlots: any[],
	plotName: string
): JADBioFeatureImportanceElement[] | JADBioProgressiveFeatureImportanceElement[] {
	if (JADBioPlots) {
		const JADBioPlot = JADBioPlots.find(plot => plot[plotName] !== undefined);
		if (!JADBioPlot) return [];

		return JADBioPlot[plotName].reverse();
	} else {
		throw Error('Unable to parse JADbio data - Performance PCA missing');
	}
}

export function extractAnalysisFromResult(
	result: GetAnalysisResultsOutput,
	storeAnalysis: JADBioAnalysis,
	getState: () => ApplicationState
) {
	let analysisResult = null;

	if (result && result.data) {
		result.data.forEach(resAnalysis => {
			if (resAnalysis.outcome) {
				const analysesResType = resAnalysis.outcome;
				if (storeAnalysis.input.model === JADBioAnalysisModels.Classification) {
					const classificationVarLabel = extractVariableLabel(
						getState,
						storeAnalysis.input.variables.classificationVariable
					);
					if (
						analysesResType.classification &&
						analysesResType.classification === classificationVarLabel
					) {
						analysisResult = resAnalysis.models;
					}
				} else if (storeAnalysis.input.model === JADBioAnalysisModels.SurvivalAnalysis) {
					const eventVarLabel = extractVariableLabel(
						getState,
						storeAnalysis.input.variables.eventVariableName
					);
					const timeToEventVarLabel = extractVariableLabel(
						getState,
						storeAnalysis.input.variables.timeToEventVariableName
					);
					if (
						analysesResType.survival &&
						analysesResType.survival.timeToEvent === timeToEventVarLabel &&
						analysesResType.survival.event === eventVarLabel
					) {
						analysisResult = resAnalysis.models;
					}
				} else if (storeAnalysis.input.model === JADBioAnalysisModels.Regression) {
					const regressionVarLabel = extractVariableLabel(
						getState,
						storeAnalysis.input.variables.regressionVariable
					);
					if (
						analysesResType.regression &&
						analysesResType.regression === regressionVarLabel
					) {
						analysisResult = resAnalysis.models;
					}
				}
			}
		});
	}

	return analysisResult;
}

/**
 * Returns variable label by variable name from store
 * Might have a temporary usage for initiateJADBioClassificationModel,
 * initiateJADBioSurvivalAnalysisModel, initiateJADBioRegressionModel
 * @param getState
 * @param variableName
 */
export function extractVariableLabel(getState: () => ApplicationState, variableName: string) {
	const {
		projects: { projectId },
		variables: { byProjectId: variablesByProjectId }
	} = getState().data;

	if (projectId && projectId in variablesByProjectId) {
		const {
			initial: {
				variables: { byName: variablesByName }
			}
		} = variablesByProjectId[projectId];

		if (variableName in variablesByName) {
			return variablesByName[variableName].label;
		}
	}

	return null;
}

import { apiFactory } from 'api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/data/variables';
import { Nullable } from 'types/index';
import { isVariableInSet } from 'helpers/variables';
import { useAPI } from 'hooks/api';
import { useProjectId } from '../projects/useProjectId';
import { useVariablesData } from './useVariablesData';
import { VariableType } from 'types/data/variables/constants';

export function useCheckVariableTypeChange(): ApiOperation<
	Nullable<boolean>,
	(variableName: string, toType: VariableType) => void
> {
	const [projectId] = useProjectId();

	const variablesData = useVariablesData({ initial: true });

	const [{ data, error, loading, fetched }, checkVariableTypeChange, resetVariableTypeChange] =
		useAPI<Nullable<boolean>>({
			lazy: true,
			initialData: null,
			resetData: {
				onFetch: true,
				onError: true
			},
			handleError: {
				showNotification: true,
				translateMessage: true
			},
			activityType: ActionTypes.CHECK_VARIABLE_TYPE_CHANGE
		});

	function promiseFn(variableName: string, toType: VariableType) {
		if (!projectId) return null;

		const setName = isVariableInSet({ variableName, variablesData });

		return apiFactory()
			.data.variables()
			.checkVariableTypeChange({
				projectId: Number(projectId),
				variable: { variableName },
				targetType: toType,
				...(setName !== undefined && { set: { setName } })
			});
	}

	function handler(variableName: string, toType: VariableType) {
		const functionCall = promiseFn(variableName, toType);

		if (functionCall) checkVariableTypeChange(() => functionCall);
	}

	return [{ data, error, loading, fetched }, handler, resetVariableTypeChange];
}

import produce from 'immer';

import { initialState } from './initialState';
import { ActionTypes, Actions, State } from './types';

import { ActionTypes as ProjectsActionTypes, Actions as ProjectsActions } from '../projects/types';

export default (state: State = initialState, action: Actions | ProjectsActions): State => {
	switch (action.type) {
		case ProjectsActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					byProjectId[projectId] = {
						active: true,
						dependencies: [],
						bySetName: {},
						fetched: false,
						refetch: false,
						metadata: {
							pageIndex: 0,
							pageSize: 10
						}
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		case ActionTypes.GET_DEPENDENCIES: {
			const { projectId, dependenciesData } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					const { active, dependencies, dependenciesBySetName } = dependenciesData;

					projectData.active = active;
					projectData.dependencies = dependencies;
					projectData.bySetName = dependenciesBySetName;

					projectData.fetched = true;
					projectData.refetch = false;
				}
			});
		}

		case ActionTypes.UPDATE_DEPENDENCIES: {
			const { projectId, active, dependencies, setName } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					if (setName) {
						// INIT STRUCTURE
						if (!projectData.bySetName[setName]) {
							projectData.bySetName[setName] = {
								active: true,
								dependencies: []
							};
						}

						projectData.bySetName[setName].active = active;
						projectData.bySetName[setName].dependencies = dependencies;
					} else {
						projectData.active = active;
						projectData.dependencies = dependencies;
					}
				}
			});
		}

		case ActionTypes.SET_REFETCH_DEPENDENCIES: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId].refetch = true;
				}
			});
		}

		case ActionTypes.DEPENDENCIES_SET_TABLE_PAGE_PARAMS: {
			const { pageIndex, pageSize } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { metadata } = byProjectId[projectId];
					metadata.pageIndex = pageIndex;
					metadata.pageSize = pageSize;
				}
			});
		}

		default: {
			return state;
		}
	}
};

import styled from 'styled-components';

import { disableableComponent, Disabled } from 'helpers/cssGenerators';
import { CSSGrid } from 'components/UI/CSSGrid';

export const Container = styled(CSSGrid.Container)<Disabled>`
	row-gap: 0;
	position: relative;
	align-items: center;

	${({ disabled }) => disableableComponent({ disabled })}
`;

export const TopCol = styled(CSSGrid.Col)`
	margin-bottom: auto;
`;

import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';
import { ROUTES } from 'types/navigation';
import { DynamicFormValue, DynamicFormValues } from 'store/data/entries';
import { SetState, BooleanMap } from 'types/index';
import { useDependencies } from '../dependencies/useDependencies';
import { entryFormDependenciesCheckEvent, withCustomSuffix } from 'helpers/entries';
import { useRouteMatch } from 'hooks/navigation';
import { useStatic } from 'hooks/utils';
import { useVariables } from '../variables/useVariables';
import { useEntries } from './useEntries';
import { useEntryDraft } from './useEntryDraft';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	reactFormState: {
		values: DynamicFormValues;
	};
	reactFormMethods: {
		reset: UseFormReset<DynamicFormValues>;
	};
	setOpenCustomsMap: SetState<BooleanMap>;
}

/**
 * APPLY DRAFT VALUES (IF DRAFT EXISTS)
 */
export function useApplyEntryDraft({
	reactFormState: { values },
	reactFormMethods: { reset },
	setOpenCustomsMap
}: Props) {
	const [
		{
			data: { variablesMap },
			fetched: areVariablesFetched
		}
	] = useVariables({ lazy: true, initial: true });
	const [{ fetched: areEntriesFetched }] = useEntries({ lazy: true });
	const [{ data: entryDraft, fetched: isEntryDraftFetched }] = useEntryDraft({
		lazy: true
	});
	const [{ fetched: areDependenciesFetched }] = useDependencies({
		lazy: true
	});

	const isOnUpdateEntryRoute = useRouteMatch([ROUTES.UpdateEntry, ROUTES.UpdatePromEntry]);

	/**
	 * Used to avoid double trigger on heavy-computation of entry-page-dependencies check
	 */
	const [getTriggeredDependenciesCheck, setTriggeredDependenciesCheck] = useStatic(false);

	/**
	 * Will fire a global event which will be caught in `DependenciesMapChecker` to re-trigger visibility and filtering condition
	 */
	function triggerDependenciesCheck() {
		if (getTriggeredDependenciesCheck()) return;

		if (!entryDraft) return;

		const fieldNames = Object.keys(entryDraft.values);

		// console.log('triggerDependenciesCheck()', { fieldNames });

		entryFormDependenciesCheckEvent().dispatch({ fieldNames });

		setTriggeredDependenciesCheck(true);
	}

	// APPLY DRAFT VALUES (IF DRAFT EXISTS)
	useEffect(() => {
		const entries: DynamicFormValues = {};
		if (!areVariablesFetched) return;

		if (isOnUpdateEntryRoute && !areEntriesFetched) return;

		if (isEntryDraftFetched && entryDraft?.values) {
			Object.entries(entryDraft.values).forEach(([key, value]) => {
				const variable = variablesMap[key];

				// CATER FOR `personalData` MARKED VARIABLES AS WELL
				if (!variable) return;

				const isCategoryVariable = variable.type === VariableType.Category;
				const isCategoryMultipleVariable = variable.type === VariableType.CategoryMultiple;
				const allowCreate = !variable.fixedCategories;

				const customKey = withCustomSuffix(key);

				const valueGotDeleted = value === null;

				const defaultValue: DynamicFormValue = isCategoryMultipleVariable ? [] : '';
				// MAP `null` TO FORM EMPTY VALUE
				if (value === null) value = defaultValue;

				// CATEGORY - string
				if (isCategoryVariable) {
					value = value as string;

					const categoryValueExists = variable.categories
						.map(c => c.value)
						.includes(value);

					if (categoryValueExists || valueGotDeleted) {
						entries[key] = value;
					} else {
						if (!allowCreate) return;

						// INVALIDATE EXISTING VALUE
						entries[key] = '';
						// SET CUSTOM VALUE FIELD
						entries[customKey] = value;
						// OPEN CUSTOM VALUE FIELD
						setOpenCustomsMap(state => ({ ...state, [key]: true }));
					}

					return;
				}

				// CATEGORY MULTIPLE - string[]
				if (isCategoryMultipleVariable) {
					const values = value as string[];

					const existingCategoryValues: string[] = [];
					const customValues: string[] = [];

					values.forEach(value => {
						const categoryValueExists = variable.categories
							.map(c => c.value)
							.includes(value);

						if (categoryValueExists) return existingCategoryValues.push(value);

						customValues.push(value);
					});

					if (customValues.length) {
						if (!allowCreate) return;

						const joinedCustomValues = customValues.join(', ');

						// SET CUSTOM VALUE FIELD
						entries[customKey] = joinedCustomValues;
						// OPEN CUSTOM VALUE FIELD
						setOpenCustomsMap(state => ({ ...state, [key]: true }));
					}

					entries[key] = existingCategoryValues;

					return;
				}

				entries[key] = value;
			});

			reset({ ...values, ...entries });
			if (areDependenciesFetched) triggerDependenciesCheck();
		}
	}, [
		areVariablesFetched,
		areEntriesFetched,
		isEntryDraftFetched,
		////////////
		areDependenciesFetched
	]);
}

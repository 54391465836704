import type { UserDetails } from 'api/data/accounts';
import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export enum ActionTypes {
	GET_USERS_BY_EMAIL = 'data/accounts/GET_USERS_BY_EMAIL',
	SEARCH_USERS_BY_EMAIL = 'data/accounts/SEARACH_USERS_BY_EMAIL',
	GET_USERS_BY_ID = 'data/accounts/GET_USERS_BY_ID',
	CLEAR_INVALID_USERS = 'data/accounts/CLEAR_INVALID_USERS',
	SET_INVALID_USERS = 'data/accounts/SET_INVALID_USERS',

	IS_EMAIL_UNIQUE = 'data/accounts/IS_EMAIL_UNIQUE',
	INREACT_APP_USER = 'data/accounts/INREACT_APP_USER'
}

export interface InvitedUser {
	email: string;
}

export interface State {
	users: {
		byEmail: GenericMap<UserDetails>;
		byUserId: GenericMap<UserDetails>;
		invitedByEmail: GenericMap<InvitedUser>;
		eligibleForInvitation: string[];
		invalidUsers: string[];
		notAllowedDueToDomainConstraintViolation: string[];
	};
}

export type GetUsersByEmailsAction = Action<
	ActionTypes.GET_USERS_BY_EMAIL,
	{
		users: UserDetails[];
		eligibleForInvitation: string[];
		invalidUsers: string[];
		notAllowedDueToDomainConstraintViolation: string[];
	}
>;
export type SearchUsersByEmailsAction = Action<
	ActionTypes.SEARCH_USERS_BY_EMAIL,
	{
		users: UserDetails[];
		notSharedWith: string[];
	}
>;

export type GetUsersByIdsAction = Action<
	ActionTypes.GET_USERS_BY_ID,
	{
		users: UserDetails[];
	}
>;

export type ClearInvalidUsersAction = Action<ActionTypes.CLEAR_INVALID_USERS>;

export type SetInvalidUsersAction = Action<
	ActionTypes.SET_INVALID_USERS,
	{
		users: string[];
	}
>;

export type InviteUserAction = Action<
	ActionTypes.INREACT_APP_USER,
	{
		emailAddress: string;
	}
>;

export type Actions =
	| GetUsersByEmailsAction
	| SearchUsersByEmailsAction
	| GetUsersByIdsAction
	| ClearInvalidUsersAction
	| SetInvalidUsersAction
	| InviteUserAction;

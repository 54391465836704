import { EntryFileResponse, EntryFilesResponse } from 'api/data/entries';
import { StoredEntryFiles, StoredEntryFile } from './types';

export function parseGetFileResponse(fileResponse: EntryFileResponse, id: string): StoredEntryFile {
	const type = fileResponse.metadata.fileName
		.substring(
			fileResponse.metadata.fileName.lastIndexOf('.') + 1,
			fileResponse.metadata.fileName.length
		)
		.toLowerCase();

	const name = fileResponse.metadata.fileName.substring(
		0,
		fileResponse.metadata.fileName.lastIndexOf('.')
	);

	const entryFile: StoredEntryFile = {
		fileId: id,
		fileName: name,
		fileLabel: name,
		signedS3Url: fileResponse.fileURL,
		thumbnailURL: fileResponse.thumbnailURL,
		mimeType: type,
		extendedMimeType: fileResponse.metadata.mimeType,
		isLocal: false
	};

	return entryFile;
}

export function parseGetFilesResponse(filesResponse: EntryFilesResponse): StoredEntryFiles {
	const entryFiles: StoredEntryFiles = {};

	Object.keys(filesResponse).forEach(id => {
		entryFiles[id] = parseGetFileResponse(filesResponse[id], id);
	});

	return entryFiles;
}

export function formatScientificNumberEntryValue(scientificNumber: number, decimals: number) {
	// Split the number
	const [coefficient, exponent] = scientificNumber.toString().split('e').map(Number);
	// determine  if it's a large or small number and its sign
	const sign = exponent < 0 ? '-' : '+';

	// Calculate the power of 10 needed  to preserve the desired decimals
	const tenExponent = Math.pow(10, decimals);

	// Multiply, truncate, and divide to avoid rounding
	const truncatedCoefficient = Math.floor(coefficient * tenExponent) / tenExponent;

	//  Final formatted number
	const parsedValue = `${truncatedCoefficient}e${sign}${Math.abs(exponent)}`;

	return parsedValue;
}

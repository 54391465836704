import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import { apiFactory } from 'api';
import { EnvType } from 'types/index';

import reducers, { ApplicationState } from './root';

export function getStore(preloadedState?: ApplicationState) {
	const api = apiFactory();

	const thunkContext = applyMiddleware(thunk.withExtraArgument({ api }));

	const isProductionENV = process.env.MODE === EnvType.Production;
	const isTestENV = process.env.NODE_ENV === EnvType.Test;

	const middleware =
		isProductionENV || isTestENV
			? thunkContext
			: composeWithDevTools({ trace: true })(thunkContext);

	const store = createStore(reducers, preloadedState, middleware);

	return store;
}

export function getMockedStore(apiFactory: any, preloadedState?: ApplicationState) {
	const thunkContext = applyMiddleware(thunk.withExtraArgument({ api: apiFactory }));

	const isProductionENV = process.env.MODE === EnvType.Production;
	const isTestENV = process.env.NODE_ENV === EnvType.Test;

	const middleware =
		isProductionENV || isTestENV
			? thunkContext
			: composeWithDevTools({ trace: true })(thunkContext);

	const store = createStore(reducers, preloadedState, middleware);

	return store;
}

import { ActionPayload, Thunk } from 'store/types';
import {
	ActionTypes,
	ActivateUserAddonAction,
	AssignSubscriptionAddonAction,
	DeactivateUserAddonAction,
	GetAllAddonsAction,
	GetUserAddonsAction
} from './types';
import { createActivity } from 'store/ui/activities';
import { Dictionary } from 'environment';
import { StorageKeys, SubscriptionAddonCode } from 'types/index';

export const getAllAddonsAction = (
	payload: ActionPayload<GetAllAddonsAction>
): GetAllAddonsAction => ({
	type: ActionTypes.GET_ALL_ADDONS,
	payload
});

export const getAllAddons = (): Thunk => async (dispatch, __, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ALL_ADDONS,
		dispatch
	});

	try {
		activity.begin();

		const addons = await context.api.account.addons().getAllAddons();
		dispatch(getAllAddonsAction({ addons }));
	} catch (e: any) {
		activity.error({ error: Dictionary.errors.api.addons.getAllSubsriptionAddons });
	} finally {
		activity.end();
	}
};

export const getUserAddonsAction = (
	payload: ActionPayload<GetUserAddonsAction>
): GetUserAddonsAction => ({
	type: ActionTypes.GET_USER_ADDONS,
	payload
});

export const getUserAddons = (): Thunk => async (dispatch, __, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_USER_ADDONS,
		dispatch
	});

	try {
		activity.begin();

		const { userSubscriptionAddons, activeUserSubscriptionAddons } = await context.api.account
			.subscription()
			.getAccount();

		dispatch(
			getUserAddonsAction({
				userAddons: userSubscriptionAddons,
				activeUserAddons: activeUserSubscriptionAddons
			})
		);

		localStorage.setItem(StorageKeys.RefetchUserAddons, 'false');
	} catch (e: any) {
		activity.error({ error: Dictionary.errors.api.addons.getActiveSubsriptionAddons });
	} finally {
		activity.end();
	}
};

export const activateSubscriptionAddonAction = (
	payload: ActionPayload<ActivateUserAddonAction>
): ActivateUserAddonAction => ({
	type: ActionTypes.ACTIVATE_USER_ADDON,
	payload
});

export const activateSubscriptionAddon =
	(addonCode: SubscriptionAddonCode): Thunk =>
	async (dispatch, __, context) => {
		const activity = createActivity({
			type: ActionTypes.ACTIVATE_USER_ADDON,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.addons().activateUserAddon(addonCode);

			dispatch(activateSubscriptionAddonAction({ addonCode }));
		} catch (e: any) {
			activity.error({ error: Dictionary.errors.api.addons.activateSubscriptionAddon });
		} finally {
			activity.end();
		}
	};

export const deactivateUserAddonAction = (
	payload: ActionPayload<DeactivateUserAddonAction>
): DeactivateUserAddonAction => ({
	type: ActionTypes.DEACTIVATE_USER_ADDON,
	payload
});

export const deactivateUserAddon =
	(addonCode: SubscriptionAddonCode): Thunk =>
	async (dispatch, __, context) => {
		const activity = createActivity({
			type: ActionTypes.DEACTIVATE_USER_ADDON,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.addons().deactivateUserAddon(addonCode);

			dispatch(deactivateUserAddonAction({ addonCode }));
		} catch (e: any) {
			activity.error({ error: Dictionary.errors.api.addons.deactivateSubscriptionAddon });
		} finally {
			activity.end();
		}
	};

export const assignSubscriptionAddonAction = (
	payload: ActionPayload<AssignSubscriptionAddonAction>
): AssignSubscriptionAddonAction => ({
	type: ActionTypes.ASSIGN_SUBSCRIPTION_ADDON,
	payload
});

export const assignSubscriptionAddon =
	(addonCode: SubscriptionAddonCode, userId: string): Thunk =>
	async (dispatch, __, context) => {
		const activity = createActivity({
			type: ActionTypes.ASSIGN_SUBSCRIPTION_ADDON,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.addons().assignSubscriptionAddon(addonCode, userId);

			dispatch(assignSubscriptionAddonAction({ addonCode }));
		} catch (e: any) {
			activity.error({ error: Dictionary.errors.api.addons.activateSubscriptionAddon });
		} finally {
			activity.end();
		}
	};

export const retractSubscriptionAddon =
	(addonCode: SubscriptionAddonCode, userId: string): Thunk =>
	async (dispatch, __, context) => {
		const activity = createActivity({
			type: ActionTypes.RETRACT_SUBSCRIPTION_ADDON,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.addons().assignSubscriptionAddon(addonCode, userId);

			dispatch(assignSubscriptionAddonAction({ addonCode }));
		} catch (e: any) {
			activity.error({ error: Dictionary.errors.api.addons.activateSubscriptionAddon });
		} finally {
			activity.end();
		}
	};

import { Link as DefaulLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Typography, getTypographyCSS } from 'components/UI/Typography';

export const ExternalLink = styled.a`
	${getTypographyCSS(t => t.Paragraph)}
	color: ${Colors.primary.normal};
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}
`;

export const Link = styled(DefaulLink)`
	${getTypographyCSS(t => t.Paragraph)}
	color: ${Colors.primary.normal};
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}
`;

export const Img = styled.img`
	max-width: 60rem;
	margin: 2rem 0;
	width: 100%;
	height: auto;
`;

export const P = styled(Typography.Paragraph)`
	color: inherit;
	margin-bottom: 1.5em;
`;

interface ListProps {
	kind: string;
}

export const List = styled.ol<ListProps>`
	${({ kind }) =>
		kind === 'ol' &&
		css`
			list-style-type: none;
			counter-reset: li;
		`}

	font-size: 1.6rem;
	padding-left: 2rem;
	padding-top: 2rem;

	li {
		position: relative;
		padding: 0 0 2rem 3rem;

		&::before {
			${({ kind }) =>
				kind === 'ol' &&
				css`
					content: counter(li);
					counter-increment: li;
				`}
			height: 30px;
			width: 30px;
			font-size: 1.4rem;
			background-color: ${Colors.background.disabled};
			border-radius: 50%;
			color: ${Colors.text.caption};
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			left: -2rem;
		}
	}
`;

export const H1 = styled(Typography.H1)`
	margin: 0 0 0.75rem 0;
`;

export const H2 = styled(Typography.H2)`
	margin: 0 0 0.75rem 0;
`;

export const H3 = styled(Typography.H3)`
	margin: 0 0 0.75rem 0;
`;

export const H4 = styled(Typography.H4)`
	margin: 0 0 0.75rem 0;
`;

export const H5 = styled(Typography.H5)`
	margin: 0 0 0.75rem 0;
`;

export const H6 = styled(Typography.H6)`
	margin: 0 0 0.75rem 0;
`;

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontWeights, getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	cursor: pointer;

	> :last-child {
		align-self: flex-start;
	}

	:hover > :first-child {
		color: ${Colors.gray.darkest};
	}
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 1.6rem;
`;

export const Link = styled(NavLink)`
	${getTypographyCSS(t => t.H6)}
	color: ${Colors.text.caption};
	font-weight: ${FontWeights.normal};
	padding: 0.4rem 0.8rem;
	text-decoration: none;

	&.active,
	&.active:hover {
		color: ${Colors.primary.normal};
	}

	:hover {
		cursor: pointer;
		color: ${Colors.gray.darkest};
	}
`;

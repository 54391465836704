import { useDispatch, useSelector } from 'hooks/utils';
import {
	SelectedSeriesEntry,
	setSelectedSeriesEntry,
	selectSelectedSeriesEntry
} from 'store/data/entries';
import { LocalOperationResult } from 'hooks/store/types';

export function useSelectedSeriesEntry(): LocalOperationResult<
	SelectedSeriesEntry,
	(selected: SelectedSeriesEntry) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectSelectedSeriesEntry(state.data.entries));

	function handler(selected: SelectedSeriesEntry) {
		dispatch(setSelectedSeriesEntry(selected));
	}

	return [data, handler];
}

import styled from 'styled-components';

export const FormElementsContainer = styled.div`
	margin-bottom: 3rem;
`;

export const SwitchContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 2rem;
`;

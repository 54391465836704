import { useProjectId } from 'hooks/store';
import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { analysesCleanUp } from 'store/data/analyses';

export function useAnalysesCleanup(): LocalOperation<() => void> {
	const dispatch = useDispatch();

	const [projectId] = useProjectId();

	function handler() {
		if (projectId) dispatch(analysesCleanUp(projectId));
	}

	return handler;
}

import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useProjectId, usePermissions } from 'hooks/store';

interface Props {
	onCreateVariable: () => void;
}

export function NoVariables({ onCreateVariable }: Props) {
	const { routes, promOrProject, navigate } = useNavigation();
	const { translate } = useTranslation();

	const [projectId] = useProjectId();

	const { hasVariablesWriteAccess, hasDatasetWriteAccess } = usePermissions();

	function onImportDataset() {
		if (projectId) navigate(routes[promOrProject].import(projectId));
	}

	return (
		<Flex align={a => a.center} column>
			<Spacer size={s => s.xxl} />
			<Svgs.EmptyStatesNoVariables style={{ minHeight: 240 }} />
			<Spacer size={s => s.m} />
			<Typography.H3>{translate(dict => dict.variablesPage.noVariables.title)}</Typography.H3>

			<Spacer size={s => s.xs} />
			<Typography.Paragraph multiline alignCenter>
				{translate(({ addVariable }) => addVariable.addVariablesText)}
			</Typography.Paragraph>

			{hasVariablesWriteAccess && (
				<>
					<Spacer size={s => s.m} />
					<Button
						title={translate(dict => dict.variablesPage.noVariables.create)}
						onClick={onCreateVariable}
					/>

					{hasDatasetWriteAccess && (
						<>
							<Spacer size={s => s.s} />
							<Flex column align={a => a.center}>
								<Typography.Paragraph>
									{translate(dict => dict.terms.or)}
								</Typography.Paragraph>
								<Button
									variant={v => v.link}
									title={translate(dict => dict.variablesPage.noVariables.import)}
									onClick={onImportDataset}
								/>
							</Flex>
						</>
					)}
				</>
			)}
			<Spacer size={s => s.xxl} />
		</Flex>
	);
}

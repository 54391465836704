import { useFlags } from 'launchdarkly-react-client-sdk';

import { useDispatch, useSelector } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import { ProjectMetadataDefinition, getProjectMetadataDefinition } from 'store/data/projects';
import { selectHasProjectMetadataDefinition } from 'store/account/subscription';
import { selectProjectMetadataDefinition, ActionTypes } from 'store/data/projects';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useEffect } from 'react';

export function useProjectMetadataDefinition(): OperationResult<ProjectMetadataDefinition> {
	const dispatch = useDispatch();

	const hasProjectMetadata = useSelector(state =>
		selectHasProjectMetadataDefinition(state.account.subscription)
	);

	const projectMetadataDefinition = useSelector(state =>
		selectProjectMetadataDefinition(state.data.projects)
	);

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.GET_PROJECT_METADATA_DEFINITION
	);

	const { projectCustomisableMetadataFlag } = useFlags();

	const fetched = projectMetadataDefinition !== null;

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getProjectMetadataDefinition());
	}

	useEffect(() => {
		if (
			!loading &&
			!error &&
			!fetched &&
			hasProjectMetadata &&
			projectCustomisableMetadataFlag
		) {
			handler();
		}
	}, [loading, error, projectCustomisableMetadataFlag, hasProjectMetadata]);

	return [{ data: projectMetadataDefinition ?? [], loading, error, fetched }, handler];
}

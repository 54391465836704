import styled from 'styled-components';

import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { Flex } from 'components/UI/Flex';

export const DescriptionHeader = styled(Typography.Caption)`
	margin-top: 2.4rem;
	margin-bottom: 1.2rem;
`;

export const Container = styled.div``;

export const DescriptionSubtitle = styled(Typography.Paragraph)`
	margin-bottom: 3.2rem;
`;

export const CenteredCheckbox = styled(Checkbox)`
	align-items: center;
	flex-direction: column;
`;

export const RightSide = styled.div`
	display: flex;
	margin-left: auto;
`;

export const MoreIcon = styled(Icon)`
	margin-left: 1.6rem;
`;

export const Hint = styled(Typography.Hint)`
	font-size: 1.2rem;
`;

export const TH = styled.th`
	height: 3.6rem;
`;

export const TableHeader = styled(Flex)`
	padding: 0 1.6rem;
`;

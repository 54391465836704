import { Container, Text } from './PdfControls.style';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { useTranslation } from 'hooks/store';

interface Props {
	pageIndex?: number;
	totalPages?: number;
	defaultScale: number;
	scale: number;
	zoomIn(): void;
	zoomOut(): void;
}

export function PdfControls({
	pageIndex,
	totalPages,
	defaultScale,
	scale,
	zoomIn,
	zoomOut
}: Props) {
	const canZoomOut = scale < 1;
	const canZoomIn = scale > defaultScale;
	const { translate } = useTranslation();

	return (
		<Container>
			{pageIndex && totalPages && (
				<>
					<Text>{translate(dict => dict.documentsPage.page)}</Text>
					<Text>
						{pageIndex} / {totalPages}
					</Text>
				</>
			)}
			<Icon
				svg={Svgs.Minus}
				onClick={zoomOut}
				variant={v => v.simple}
				colors={{
					color: canZoomOut ? Colors.text.hint : Colors.background.disabled
				}}
			/>
			<Icon
				customSize={2}
				svg={Svgs.ZoomOut}
				colors={{ color: Colors.gray.darkest }}
				disabled
			/>
			<Icon
				svg={Svgs.Add}
				onClick={zoomIn}
				variant={v => v.simple}
				colors={{
					color: canZoomIn ? Colors.text.hint : Colors.background.disabled
				}}
			/>
		</Container>
	);
}

import { useDeepCompareEffect, usePrevious } from 'hooks/utils';
import { useRefetchAnalyses } from './useRefetchAnalyses';

export function useRefetchStatistics(
	flags: boolean[],
	callbacks: (() => void)[],
	options: {
		condition: boolean;
	}
) {
	const [shouldRefetchAllAnalyses] = useRefetchAnalyses();

	const prevFlags = usePrevious(flags);
	useDeepCompareEffect(() => {
		flags.forEach((flag, index) => {
			const prevFlag = prevFlags?.[index];
			if (!(prevFlag !== undefined && flag && options.condition)) {
				return;
			}

			const flagChanged = prevFlag !== flag || shouldRefetchAllAnalyses;
			if (flagChanged) {
				callbacks[index]();
			}
		});
	}, [flags, options.condition, shouldRefetchAllAnalyses]);
}

import { useState } from 'react';
import { AggregationRule } from 'api/data/variables';
import { Svgs } from 'environment';
import { Entry } from 'store/data/entries';
import { VariablesMap } from 'store/data/variables';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import { AggregationRuleType } from 'api/data/variables';
import { Container, Header, Body } from './AggregationRulesTable.style';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { getAggregationRuleTypeLabel } from 'helpers/variables';
import { useSelector } from 'hooks/utils';
import { useTimeDurationEntries, useTranslation } from 'hooks/store';

interface Props {
	aggregationRules: AggregationRule[];
	mainEntry: Entry | null;
	variablesMap: VariablesMap;
}

export function AggregationRulesTable({ aggregationRules, mainEntry, variablesMap }: Props) {
	const { translate } = useTranslation();

	const [open, setOpen] = useState(false);
	const { parseVariableSetAggregatedValue } = useTimeDurationEntries({ withTranslation: true });

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);

	function getFullAggregationLabel(aggregationRule: AggregationRule) {
		const { aggregator } = aggregationRule;

		return `${getVariableLabel(aggregator.variableName)} (${translate(
			dict =>
				dict.variablesPage.aggregationRuleType[
					getAggregationRuleTypeLabel(aggregationRule) as AggregationRuleType
				]
		).toUpperCase()})`;
	}

	function getVariableLabel(variableName: string) {
		if (variableName in variablesMap) return variablesMap[variableName].label;

		return '';
	}

	function getResultValue(aggregationRule: AggregationRule) {
		const cellValue = mainEntry?.[aggregationRule.name]?.toString() ?? null;
		if (cellValue === null) {
			return translate(dict => dict.terms.empty);
		}

		return parseVariableSetAggregatedValue(cellValue, aggregationRule, {
			variablesMap,
			dateTimeFormat
		});
	}

	return (
		<Container>
			<Header onClick={() => setOpen(state => !state)}>
				<Icon
					svg={Svgs.ChevronDown}
					rotate={open ? 180 : 0}
					marginOffset={{ right: 0.8 }}
					propagate
				/>
				<Typography.Paragraph ellipsis>{`All aggregations`}</Typography.Paragraph>
			</Header>

			{open && (
				<Body>
					<Table.Responsive>
						<Table fullWidth>
							<Table.Head>
								<Table.Row>
									<Table.Column>
										{translate(
											dict =>
												dict.dataset.aggregationRulesTable.allAggregations
										)}
									</Table.Column>
									<Table.Column>
										{translate(
											dict =>
												dict.dataset.aggregationRulesTable
													.aggregationVariable
										)}
									</Table.Column>
									<Table.Column>
										{translate(
											dict =>
												dict.dataset.aggregationRulesTable
													.aggregationFcuntion
										)}
									</Table.Column>
									<Table.Column>
										{translate(
											dict => dict.dataset.aggregationRulesTable.result
										)}
									</Table.Column>
								</Table.Row>
							</Table.Head>

							<Table.Body>
								{aggregationRules.map(aggregationRule => (
									<Table.Row key={`aggregation_rule_row-${aggregationRule.name}`}>
										<Table.Cell
											title={getFullAggregationLabel(aggregationRule)}
											minWidth={16}
											maxWidth={32}
											noWrap
										>
											{getFullAggregationLabel(aggregationRule)}
										</Table.Cell>
										<Table.Cell
											title={getVariableLabel(
												aggregationRule.aggregator.variableName
											)}
											minWidth={16}
											maxWidth={32}
											noWrap
										>
											{getVariableLabel(
												aggregationRule.aggregator.variableName
											)}
										</Table.Cell>
										<Table.Cell
											title={translate(
												dict =>
													dict.variablesPage.aggregationRuleType[
														getAggregationRuleTypeLabel(
															aggregationRule
														) as AggregationRuleType
													]
											).toUpperCase()}
											minWidth={16}
										>
											{translate(
												dict =>
													dict.variablesPage.aggregationRuleType[
														getAggregationRuleTypeLabel(
															aggregationRule
														) as AggregationRuleType
													]
											).toUpperCase()}
										</Table.Cell>
										<Table.Cell
											title={getResultValue(aggregationRule)}
											minWidth={10}
										>
											{getResultValue(aggregationRule)}
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Table.Responsive>
				</Body>
			)}
		</Container>
	);
}

import { useState, useMemo } from 'react';
import { Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { DraggableGroupCardWrapper, DraggableCard, TimesUsed } from 'components/Forms';
import { Svgs, Colors } from 'environment';
import { GroupData } from 'store/data/variables';
import { DragAndDropTypes } from 'types/index';
import { DraggableFormGroupedVariable } from './DraggableFormGroupedVariable';
import { ColorIndicator } from './style';
import { CardLabel } from './DraggableFormVariableCard.style';
import { AddGroupIcon, GroupedVariablesWrapper, Wrapper } from './DraggableFormGroupCard.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { computeFormGroupFromGroupData } from 'helpers/forms';
import { useFormsMap, useTranslation, useAppendFormGroup } from 'hooks/store';

interface Props {
	groupData: GroupData;
	provided: DraggableProvided;
	groupBroken: boolean;
	canAppend: boolean;
}

export function DraggableFormGroupCard({ groupData, provided, canAppend, groupBroken }: Props) {
	const [groupOpen, setGroupOpen] = useState(false);

	const formsMap = useFormsMap();
	const { translate } = useTranslation();
	const appendFormGroup = useAppendFormGroup();

	const { groupName, groupLabel, groupVariables } = groupData;

	const { formGroup, groupElements } = computeFormGroupFromGroupData(groupData);

	const timesUsed = useMemo(() => {
		let times = 0;

		Object.values(formsMap).forEach(form => {
			if (form.initial.usedGroups.includes(groupName)) times++;
		});

		return times;
	}, [formsMap]);

	return (
		<DraggableGroupCardWrapper
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
			data-test-id={groupName}
		>
			<DraggableCard key={groupName} groupBroken={groupBroken} groupCard>
				<ColorIndicator type="group" />

				<Icon
					svg={Svgs.ChevronDown}
					onClick={() => setGroupOpen(state => !state)}
					rotate={groupOpen ? 180 : 0}
				/>
				<Icon
					svg={Svgs.Folder}
					size={s => s.m}
					colors={{ color: Colors.groupOrange }}
					marginOffset={{ x: 0.8 }}
					propagate
				/>
				<CardLabel title={groupLabel} fontweight={w => w.medium} isGroup>
					{groupLabel}
				</CardLabel>
				{!groupBroken && canAppend && (
					<AddGroupIcon className="add-group-icon">
						<Icon
							showTooltip={false}
							svg={Svgs.Add}
							onClick={() => appendFormGroup({ formGroup, groupElements })}
							active
						/>
						{timesUsed > 0 && (
							<TimesUsed className="times-group-used">
								<Typography.Paragraph>{`${translate(
									dict => dict.formDesigner.usedIn
								)} ${timesUsed} ${
									timesUsed > 1
										? translate(dict => dict.projectTabs.forms).toLowerCase()
										: translate(dict => dict.formDesigner.form).toLowerCase()
								}`}</Typography.Paragraph>
							</TimesUsed>
						)}
					</AddGroupIcon>
				)}
			</DraggableCard>

			{groupOpen && (
				<Droppable
					droppableId={`${DragAndDropTypes.DraggableFormGroup}${groupName}`}
					isDropDisabled
				>
					{provided => (
						<GroupedVariablesWrapper ref={provided.innerRef}>
							{groupVariables.map((variable, index) => (
								<Wrapper key={variable.name}>
									<Draggable
										key={variable.name}
										draggableId={variable.name}
										index={index}
										isDragDisabled={!canAppend}
									>
										{(provided, snapshot) => (
											<Wrapper
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<DraggableFormGroupedVariable
													variable={variable}
													canAppend={canAppend}
													isDragging={snapshot.isDragging}
												/>
											</Wrapper>
										)}
									</Draggable>
								</Wrapper>
							))}
							{provided.placeholder}
						</GroupedVariablesWrapper>
					)}
				</Droppable>
			)}
		</DraggableGroupCardWrapper>
	);
}

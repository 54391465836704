import { AnalysisVariable, ComparePairedResultsV2, ComparePairedValueV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { computeCellValue } from 'helpers/analysis';
import { useTranslation } from 'hooks/store';
import { VariablesData } from 'store/data/variables';

import { AnalysisGroupedTables } from '../../UI';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	buildVariableCategoriesMap
} from 'helpers/variables';

interface TableRows {
	'nr of rows': React.ReactElement[];
	average: React.ReactElement[];
	std: React.ReactElement[];
	confidentLower: React.ReactElement[];
	confidentUpper: React.ReactElement[];
	sem: React.ReactElement[];
	variance: React.ReactElement[];
	skewness: React.ReactElement[];
	kurtosis: React.ReactElement[];
	median: React.ReactElement[];
	min: React.ReactElement[];
	max: React.ReactElement[];
	range: React.ReactElement[];
	IQRLower: React.ReactElement[];
	IQRUpper: React.ReactElement[];
	missing: React.ReactElement[];
}

interface Props {
	variablesData: VariablesData;
	dataset: ComparePairedResultsV2;
	groupVariable?: AnalysisVariable;
}

export function ComparePairedTableV2({ variablesData, dataset, groupVariable }: Props) {
	const { translate } = useTranslation();

	const { variablesMap, variableSetsMap } = variablesData;
	const aggRuleToVariableNameMap =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	const groupVariableValue =
		variablesMap[groupVariable?.name ?? ''] ??
		variablesMap[aggRuleToVariableNameMap[groupVariable?.name ?? '']?.aggregator?.variableName];

	const categories = buildVariableCategoriesMap(groupVariableValue?.categories ?? []);

	const leftRows = [
		translate(({ analysis }) => analysis.generic.n),
		translate(({ analysis }) => analysis.generic.mean),
		translate(({ analysis }) => analysis.generic.sd),
		translate(({ analysis }) => analysis.generic.confidenceLower),
		translate(({ analysis }) => analysis.generic.confidenceUpper),
		translate(({ analysis }) => analysis.generic.sem),
		translate(({ analysis }) => analysis.generic.variance),
		translate(({ analysis }) => analysis.generic.skewness),
		translate(({ analysis }) => analysis.generic.kurtosis),
		translate(({ analysis }) => analysis.generic.median),
		translate(({ analysis }) => analysis.generic.min),
		translate(({ analysis }) => analysis.generic.max),
		translate(({ analysis }) => analysis.generic.range),
		translate(({ analysis }) => analysis.generic.IQRLower),
		translate(({ analysis }) => analysis.generic.IQRUpper),
		translate(({ analysis }) => analysis.generic.missing)
	];

	const columns: string[] = [];
	const rows: TableRows = {
		'nr of rows': [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	if (dataset.data) {
		dataset.data.forEach(rowData => {
			addRow(rowData, getColumnName(rowData.variable.name));
		});
	}

	function addRow(row: ComparePairedValueV2, columnName: string) {
		columns.push(columnName);

		rows['nr of rows'].push(computeCellValue(row['n'], { noDecimals: true }));
		rows.average.push(computeCellValue(row.mean));
		rows.std.push(computeCellValue(row.sd));
		rows.confidentLower.push(computeCellValue(row.lowerCI));
		rows.confidentUpper.push(computeCellValue(row.upperCI));
		rows.sem.push(computeCellValue(row.sem));
		rows.variance.push(computeCellValue(row.variance));
		rows.skewness.push(computeCellValue(row.skewness));
		rows.kurtosis.push(computeCellValue(row.kurtosis));
		rows.median.push(computeCellValue(row.median));
		rows.min.push(computeCellValue(row.min));
		rows.max.push(computeCellValue(row.max));
		rows.IQRLower.push(computeCellValue(row.lowerIQR));
		rows.IQRUpper.push(computeCellValue(row.upperIQR));
		rows.missing.push(computeCellValue(row.missing, { noDecimals: true }));
		rows.range.push(computeCellValue((Number(row.max) - Number(row.min)).toString()));
	}

	function getColumnName(variableName: string) {
		const names: string[] = [];
		variableName.split(' - ').forEach(variableName => {
			const variable =
				variablesMap[variableName] ??
				variablesMap[aggRuleToVariableNameMap[variableName]?.aggregator?.variableName];

			if (!variable) {
				names.push(categories?.[variableName]?.label || categories?.[variableName]?.value);
			} else {
				names.push(variable.label);
			}
		});

		return names.join(' - ');
	}

	return (
		<AnalysisGroupedTables>
			<Table maxWidth={15} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column empty />
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{leftRows.map((value, index) => (
						<Table.Row key={`left-table-row-${index}`}>
							<Table.Cell key={`left-table-row-cell-${index}`} bold>
								{value}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							{columns.map((column, index) => (
								<Table.Column
									key={`right-table-column-${index}`}
									title={column}
									minWidth={12}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{Object.keys(rows).map((key, index) => (
							<Table.Row key={`right-table-row-${index}`}>
								{rows[key as keyof TableRows].map((item, i) => (
									<Table.Cell key={`right-table-row-cell-${i}`}>
										{item}
									</Table.Cell>
								))}
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}

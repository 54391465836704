import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectAdminUsersFilters,
	AdminUsersFilters,
	AdminUsersFilterNames,
	setAdminUsersFilter,
	setAdminUsersDateFilter,
	resetAdminUsersFilter,
	resetAllAdminUsersFilters,
	DateFilter
} from 'store/account/admin';

interface Handlers {
	setFilter: (filterName: AdminUsersFilterNames, value: string) => void;
	setDateFilter: (filterName: AdminUsersFilterNames, filter: DateFilter) => void;
	resetFilter: (filterName: AdminUsersFilterNames) => void;
	resetAllFilters: () => void;
}

export function useAdminUsersListFilters(): LocalOperationResult<AdminUsersFilters, Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAdminUsersFilters(state.account.admin));

	function setFilter(filterName: AdminUsersFilterNames, value: string) {
		dispatch(setAdminUsersFilter({ filterName, value }));
	}

	function setDateFilter(filterName: AdminUsersFilterNames, filter: DateFilter) {
		dispatch(setAdminUsersDateFilter({ filterName, filter }));
	}

	function resetFilter(filterName: AdminUsersFilterNames) {
		dispatch(resetAdminUsersFilter({ filterName }));
	}

	function resetAllFilters() {
		dispatch(resetAllAdminUsersFilters());
	}

	const handlers = {
		setFilter,
		setDateFilter,
		resetFilter,
		resetAllFilters
	};

	return [data, handlers];
}

import { useEffect, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { isEqual } from 'lodash';
import { ProjectStatus } from 'types/data/projects/constants';
import { Select } from 'components/UI/Interactables/Select';
import { StringMap } from 'types/index';
import { ProjectStatusData, ProjectData } from 'store/data/projects';
import { useTranslation } from 'hooks/store';
import { usePrevious } from 'hooks/utils';

interface Props {
	column: ColumnInstance<ProjectData>;
	statuses: string[];
	operator: string;
}

export function FilterStatuses({ column, statuses, operator }: Props) {
	const { setFilter } = column;
	const { translate } = useTranslation();

	const translatedStatus: StringMap = {
		[ProjectStatusData.ongoing]: ProjectStatus.Ongoing,
		[ProjectStatusData.onHold]: ProjectStatus.OnHold,
		[ProjectStatusData.ended]: ProjectStatus.Ended
	};

	const [selected, setSelected] = useState<string>(column.filterValue?.value);

	const prevStatusNames = usePrevious(statuses);
	useEffect(() => {
		if (!prevStatusNames) return;
		if (isEqual(prevStatusNames, statuses)) return;

		setSelected(column.filterValue.value);
	}, [statuses, column.filterValue]);

	useEffect(() => {
		if (selected) {
			setFilter({ value: selected, operator });
		}
	}, [selected]);

	function onCheck(value: string) {
		setSelected(value);
	}

	return (
		<Select
			title={selected ? selected : translate(dict => dict.terms.all)}
			items={format => format(statuses)}
			onSelect={item => item.value && onCheck(translatedStatus[item.value])}
			width={10}
			offset={{ top: 2 }}
			translateChildren
			type={s => s.Tag}
		/>
	);
}

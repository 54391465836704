import format from 'date-fns/format';
import { useTranslation } from 'hooks/store';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { isValidDate } from 'helpers/isValidDate';
import { VERSION_DATE_YEAR_TIME_FORMAT } from 'consts';

interface Props {
	visible: boolean;
	loadingRestore: boolean;
	onPrimaryClick: () => void;
	onNeutralClick: () => void;
	creationDate?: string;
}

export function RestoreEntryModal({
	loadingRestore,
	visible,
	onPrimaryClick,
	onNeutralClick,
	creationDate
}: Props) {
	const { translate } = useTranslation();
	const modalTitle = translate(dict => dict.versionChanges.restoreQuestion);
	const warningText = translate(dict => dict.versionChanges.restoreWarning);
	const primaryLabel = translate(dict => dict.buttons.restore);
	const neutralLabel = translate(dict => dict.buttons.cancel);
	function getDate() {
		if (creationDate) {
			const creationValidDate = isValidDate(creationDate)
				? creationDate.replace(/\s/g, 'T')
				: creationDate;

			return format(new Date(creationValidDate), VERSION_DATE_YEAR_TIME_FORMAT);
		}
	}
	return (
		<Modal
			size={s => s.s}
			visible={visible}
			title={modalTitle}
			primary={{
				loading: loadingRestore,
				label: primaryLabel,
				onClick: onPrimaryClick
			}}
			neutral={{
				label: neutralLabel,
				onClick: onNeutralClick,
				disabled: loadingRestore
			}}
			onClose={onNeutralClick}
			enterAsPrimaryOnClick
			close
		>
			<Typography.Paragraph multiline>
				{`${warningText} ${getDate()}`}
				{'\n\n'}
			</Typography.Paragraph>
		</Modal>
	);
}

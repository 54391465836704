import { MediaQueries } from 'environment';
import styled from 'styled-components';

export const CardGridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 1.6rem 2.2rem;

	@media ${MediaQueries.maxWidth.lg} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.sm} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

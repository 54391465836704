import type { Action } from 'types/store/types';
import type { GenericMap, StringArrayMap } from 'types/index';
import type { Role } from '../roles';

export interface State {
	projectId: string | null;
	byProjectId: ByProjectId;
	selectedOrganizationsById: StringArrayMap;
	metadata: {
		searchTerm: string;
	};
	refetch: boolean;
}

type CollaboratorsProjectData = {
	data: StoreCollaboratorsData;
	fetched: boolean;
	metadata: {
		viewOption: CollaboratorsViewOptions;
	};
};

type ByProjectId = GenericMap<CollaboratorsProjectData>;

export interface StoreCollaboratorsData {
	collaborators: {
		byId: CollaboratorsMap;
	};
	organizations: {
		byId: OrganizationsMap;
		default: {
			id: string | null;
		};
	};
	order: CollaboratorsOrder;
}

export interface CollaboratorsData {
	collaboratorsMap: CollaboratorsMap;
	organizationsMap: OrganizationsMap;
	order: CollaboratorsOrder;
}

export interface CollaboratorsRichData {
	collaborators: Collaborator[];
	collaboratorsMap: CollaboratorsMap;
	collaboratorIds: string[];
	//////////////
	organizations: Organization[];
	organizationsMap: OrganizationsMap;
	organizationIds: string[];
	organizationNames: string[];
	//////////////
	collaboratorsDataArray: CollaboratorsDataArray;
	//////////////
	order: CollaboratorsOrder;
	//////////////
	hasCollaborators: boolean;
	hasOrganizations: boolean;
}

export type OrganizationsMap = GenericMap<Organization>;
export type CollaboratorsMap = GenericMap<Collaborator>;

export type CollaboratorsOrder = CollaboratorsOrderItem[];
export type CollaboratorsOrderItem = { collaborator: string } | { organization: string };
export type CollaboratorsDataArray = CollaboratorsDataArrayItem[];
export type CollaboratorsDataArrayItem = Collaborator | OrganizationData;

export enum CollaboratorsViewOptions {
	GRID = 'grid',
	TABLE = 'table'
}

export interface Organization {
	id: string;
	name: string;
	collaborators: string[];
}

export interface OrganizationData {
	id: string;
	name: string;
	collaborators: Collaborator[];
}

export interface Collaborator {
	userId: string;
	emailAddress: string;
	userFirstName?: string;
	userSirName?: string;
	phoneNumber?: string;
	userRole?: string;
	projectRoleId?: string;
	address?: string;
	imageURL?: string;
	department?: string;
	organization?: string;
	subOrganization?: string;
	workplace: string;
	city?: string;
	country?: string;
	position?: string;
	accessToProject?: CollaboratorPermissions;
	pending?: boolean;
	organizationsCount: number;
	status: string;
}

export type GeneralPermissions = {
	[key in GeneralPermissionFlags]: boolean;
};

export type OrganizationPermissions = {
	organizationId: number;
	readAccess: boolean;
	writeAccess: boolean;
	deleteAccess: boolean;
	analysisAccess: boolean;
};

export type CollaboratorPermissions = GeneralPermissions & {
	modules: ModulesPermissions;
	statusTypeAccesses?: StatusTypeAccesses;
	projectOrganizationAccesses: OrganizationPermissions[];
};

export type ModulesPermissions = {
	[key in ModulesPermissionsFlags]: boolean;
};

export enum ModulesPermissionsFlags {
	projectDesign = 'projectDesign',
	collaborators = 'collaborators'
}

export type StatusPermissions = {
	[key in StatusPermissionFlags]: boolean;
};

export interface StatusTypeAccess extends StatusPermissions {
	statusTypeVariableName: string;
}

export type StatusTypeAccesses = StatusTypeAccess[];

export interface CollaboratorEmailAddressWithPermissions {
	emailAddress: string;
	permissions: CollaboratorPermissions;
}

export enum StatusPermissionFlags {
	viewData = 'viewData',
	editData = 'editData',
	setStatus = 'setStatus'
}

export enum GeneralPermissionFlags {
	accessEntriesAllRead = 'accessEntriesAllRead',
	accessEntriesAllWrite = 'accessEntriesAllWrite',
	accessEntriesAllDelete = 'accessEntriesAllDelete',
	accessEntriesOrgRead = 'accessEntriesOrgRead',
	accessEntriesOrgWrite = 'accessEntriesOrgWrite',
	accessEntriesOrgDelete = 'accessEntriesOrgDelete',
	accessAnalysisOwnonly = 'accessAnalysisOwnonly',
	accessAnalysisOrg = 'accessAnalysisOrg',
	accessAnalysisAll = 'accessAnalysisAll',
	accessEntriesOwnonlyRead = 'accessEntriesOwnonlyRead',
	accessEntriesOwnonlyWrite = 'accessEntriesOwnonlyWrite',
	accessEntriesOwnonlyDelete = 'accessEntriesOwnonlyDelete',
	accessExport = 'accessExport',
	accessProjectRead = 'accessProjectRead',
	accessProjectWrite = 'accessProjectWrite',
	accessVariablesRead = 'accessVariablesRead',
	accessVariablesWrite = 'accessVariablesWrite',
	accessPersonalData = 'accessPersonalData',
	accessGlobalTransferOwnership = 'projectOwnerHasAccessEntriesAllTransferOwnership'
}

export interface CollaboratorsDataLocation {
	collaboratorsLocation: GenericMap<{
		organizationIds: string[];
	}>;
}

/*
	============================
			ACTION TYPES
	============================
*/
export enum ActionTypes {
	// ASYNC
	GET_COLLABORATORS = 'data/collaborators/GET_COLLABORATORS',
	ADD_COLLABORATORS = 'data/collaborators/ADD_COLLABORATORS',
	UPDATE_COLLABORATOR_PERMISSIONS = 'data/collaborators/UPDATE_COLLABORATOR_PERMISSIONS',
	UPDATE_COLLABORATORS_PERMISSIONS = 'data/collaborators/UPDATE_COLLABORATORS_PERMISSIONS',
	REMOVE_COLLABORATOR = 'data/collaborators/REMOVE_COLLABORATOR',
	CREATE_ORGANIZATION = 'data/collaborators/CREATE_ORGANIZATION',
	UPDATE_ORGANIZATION = 'data/collaborators/UPDATE_ORGANIZATION',
	DELETE_ORGANIZATION = 'data/collaborators/DELETE_ORGANIZATION',
	ADD_COLLABORATORS_TO_ORGANIZATIONS = 'data/collaborators/ADD_COLLABORATORS_TO_ORGANIZATIONS',
	REMOVE_COLLABORATORS_FROM_ORGANIZATIONS = 'data/collaborators/REMOVE_COLLABORATORS_FROM_ORGANIZATIONS',
	DELETE_BULK_COLLABORATORS_DATA = 'data/collaborators/DELETE_BULK_COLLABORATORS_DATA',
	// LOCAL
	SET_ORGANIZATIONS_BY_ID = 'data/collaborators/SET_ORGANIZATIONS_BY_ID',
	SET_COLLABORATORS_SEARCH_TERM = 'data/collaborators/SET_COLLABORATORS_SEARCH_TERM',
	SET_COLLABORATORS_VIEW_OPTION = 'data/collaborators/SET_COLLABORATORS_VIEW_OPTION',
	RESET_FETCHED_COLLABORATORS = 'data/collaborators/RESET_FETCHED_COLLABORATORS',
	SET_REFETCH_COLLABORATORS = 'data/collaborators/SET_REFETCH_COLLABORATORS'
}

/*
	============================
				ASYNC
	============================
*/

export type GetCollaboratorsAction = Action<
	ActionTypes.GET_COLLABORATORS,
	{
		projectId: string;
		collaborators: Collaborator[];
		organizationsData: {
			organizations: Organization[];
			default: {
				id: string;
			};
		};
	}
>;

export type UpdateCollaboratorPermissionsAction = Action<
	ActionTypes.UPDATE_COLLABORATOR_PERMISSIONS,
	{
		collaboratorId: string;
		permissions?: CollaboratorPermissions;
		projectRoleId: Role['id'] | null;
	}
>;

export type UpdateCollaboratorsPermissionsAction = Action<
	ActionTypes.UPDATE_COLLABORATORS_PERMISSIONS,
	{
		collaboratorsWithPermissions: CollaboratorEmailAddressWithPermissions[];
		projectRoleId?: Role['id'];
	}
>;

export type RemoveCollaboratorAction = Action<
	ActionTypes.REMOVE_COLLABORATOR,
	{
		collaboratorId: string;
	}
>;

export type CreateOrganizationAction = Action<
	ActionTypes.CREATE_ORGANIZATION,
	{
		projectId: string;
		organization: Organization;
	}
>;

export type UpdateOrganizationAction = Action<
	ActionTypes.UPDATE_ORGANIZATION,
	{
		projectId: string;
		organization: Organization;
	}
>;

export type DeleteOrganizationAction = Action<
	ActionTypes.DELETE_ORGANIZATION,
	{
		projectId: string;
		organizationId: string;
	}
>;

export type AddCollaboratorsToOrganizationsAction = Action<
	ActionTypes.ADD_COLLABORATORS_TO_ORGANIZATIONS,
	{
		projectId: string;
		organizationIds: string[];
		collaborators: string[];
	}
>;

export type RemoveCollaboratorsFromOrganizationsAction = Action<
	ActionTypes.REMOVE_COLLABORATORS_FROM_ORGANIZATIONS,
	{
		projectId: string;
		organizationIds: string[];
		collaborators: string[];
	}
>;

/*
	============================
				LOCAL
	============================
*/

export type SetOrganizationsByIdAction = Action<
	ActionTypes.SET_ORGANIZATIONS_BY_ID,
	{
		organizations: string[];
	}
>;

export type SetCollaboratorsSearchTermAction = Action<
	ActionTypes.SET_COLLABORATORS_SEARCH_TERM,
	{
		term: string;
	}
>;

export type SetCollaboratorsViewOption = Action<
	ActionTypes.SET_COLLABORATORS_VIEW_OPTION,
	{
		viewOption: CollaboratorsViewOptions;
	}
>;
export type ResetFetchedCollaboratorsAction = Action<
	ActionTypes.RESET_FETCHED_COLLABORATORS,
	{
		projectId: string;
	}
>;
export type SetRefetchCollaboratorsAction = Action<ActionTypes.SET_REFETCH_COLLABORATORS>;

export type Actions =
	// ASYNC
	| GetCollaboratorsAction
	| UpdateCollaboratorPermissionsAction
	| UpdateCollaboratorsPermissionsAction
	| RemoveCollaboratorAction
	| CreateOrganizationAction
	| UpdateOrganizationAction
	| DeleteOrganizationAction
	| AddCollaboratorsToOrganizationsAction
	| RemoveCollaboratorsFromOrganizationsAction
	// LOCAL
	| SetOrganizationsByIdAction
	| SetCollaboratorsSearchTermAction
	| SetCollaboratorsViewOption
	| ResetFetchedCollaboratorsAction
	| SetRefetchCollaboratorsAction;

import styled, { css } from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

export const BuilderList = styled.div`
	display: flex;
	flex-direction: column;
`;

interface DraggableElementProps {
	isCombinable?: boolean;
}
export const DraggableElement = styled.div<DraggableElementProps>`
	@keyframes dragOverToCombine {
		0% {
			margin-left: 0;
		}
		50% {
			margin-left: 1.5rem;
		}
		100% {
			margin-left: 0;
		}
	}

	${({ isCombinable }) =>
		isCombinable &&
		css`
			animation: dragOverToCombine 1s infinite !important;
			background-color: ${Colors.olive};
		`}
`;

export const DragIcon = styled.img`
	width: 5rem;
	object-fit: contain;
	margin-right: 1rem;
`;

interface IDropZone {
	highlighted: boolean;
}

export const DroppableZoneWrapper = styled.div<IDropZone>`
	width: 100%;
	height: 8.4rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-radius: 0.8rem;
	background-color: ${({ highlighted }) =>
		highlighted ? Colors.blue.lightest : Colors.silver.lightest};
	border: 0.1rem dashed
		${({ highlighted }) => (highlighted ? Colors.blue.lightest : Colors.gray.lighter)};
`;

export const DropZoneMessage = styled(Typography.Paragraph)`
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	color: ${Colors.gray.dark};
`;

import { useMemo, useState } from 'react';
import { Draggable, Droppable, DraggingStyle } from 'react-beautiful-dnd';
import { VariableContainer } from 'components/Templates';
import { TemplateGroupWithVariables, TemplateVariable } from 'components/Templates/Draggable';
import { Images, Svgs } from 'environment';
import { getCloneStyle } from 'helpers/templates';
import {
	DragAndDropTypes,
	InputType,
	SetState,
	TemplateShareLevel,
	TemplateViewTypes
} from 'types/index';
import { TemplateCardHeader } from './TemplateCardHeader';
import {
	TemplateCard,
	DragIcon,
	DroppableZoneWrapper,
	DropzoneContainer,
	DropZoneMessage,
	TemplateBody,
	TabsContainer,
	DescriptionContainer
} from './Template.style';
import { Tabs } from 'components/UI/Tabs';
import { Spacer } from 'components/UI/Spacer';
import { Icon } from 'components/UI/Icons';
import { Input } from 'components/UI/Inputs/Input';
import { Gap } from 'components/UI/Gap';
import { useTranslation, useSetTemplateDescription, useTemplateById } from 'hooks/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { VariableType } from 'types/data/variables/constants';
import { cloneDeep } from 'lodash';

interface Props {
	index?: number;
	templateId: string;
	accessWrite: boolean;
	withUserEdit?: boolean;
	setShareLevel: SetState<TemplateShareLevel | null>;
	setTemplateIdForImport: SetState<string | null>;
}

export function Template({
	index = 0,
	templateId,
	accessWrite,
	withUserEdit = false,
	setShareLevel,
	setTemplateIdForImport
}: Props) {
	const { translate } = useTranslation();
	const { timeDurationFlag } = useFlags();
	const setTemplateDescription = useSetTemplateDescription();
	const template = useTemplateById(templateId);

	const computedTemplate = useMemo(() => {
		const computedTemplate = cloneDeep(template);
		if (!timeDurationFlag) {
			computedTemplate.variables = template.variables.filter(
				variable => variable.type !== VariableType.TimeDuration
			);
			computedTemplate.groups.forEach(group => {
				group.variables = group.variables.filter(
					variable => variable.type !== VariableType.TimeDuration
				);
			});
		}
		let newLength = 0;
		computedTemplate.variables.forEach(variable => {
			if (variable.type === VariableType.TimeDuration && !timeDurationFlag) {
				return;
			}
			newLength += 1;
		});
		computedTemplate.groups.forEach(group =>
			group.variables.forEach(variable => {
				if (variable.type === VariableType.TimeDuration && !timeDurationFlag) {
					return;
				}
				newLength += 1;
			})
		);
		computedTemplate.totalVariableNumber = newLength;
		return computedTemplate;
	}, [timeDurationFlag, template]);

	const {
		templateName,
		variables,
		groups,
		elementsOrder,
		totalVariableNumber,
		accessPublicRead,
		description: templateDescription
	} = computedTemplate;

	const [description, setDescription] = useState(templateDescription);
	const [expanded, setExpanded] = useState(false);
	const [view, setView] = useState(
		withUserEdit ? TemplateViewTypes.Dropzone : TemplateViewTypes.Description
	);

	function isVariable(draggableId: string) {
		return draggableId.includes(DragAndDropTypes.DraggableTemplateVariable);
	}

	const emptyVariablesList = elementsOrder && !elementsOrder.length;
	const droppableId = `${DragAndDropTypes.DroppableTemplate}-${templateId}`;

	const dropzoneTab = translate(({ templates }) => templates.dropzone);
	const descriptionTab = translate(({ templates }) => templates.description);

	return (
		<TemplateCard expanded={expanded}>
			<TemplateCardHeader
				index={index}
				templateId={templateId}
				templateName={templateName}
				accessPublicRead={accessPublicRead}
				expanded={expanded}
				accessWrite={accessWrite}
				withUserEdit={withUserEdit}
				variablesLength={totalVariableNumber}
				setExpanded={setExpanded}
				setShareLevel={setShareLevel}
				setTemplateIdForImport={setTemplateIdForImport}
			/>

			{expanded &&
				(withUserEdit ? (
					<>
						<TabsContainer>
							<Tabs
								maxWidth={32}
								labels={[dropzoneTab, descriptionTab]}
								onChangeTabs={tab => {
									const selectedPageView =
										tab === 0
											? TemplateViewTypes.Dropzone
											: TemplateViewTypes.Description;
									setView(selectedPageView);
								}}
							/>
						</TabsContainer>

						<Spacer size={s => s.s} />

						<Droppable droppableId={droppableId} isDropDisabled={!accessWrite}>
							{(provided, snapshot) => {
								const dragGroupedVariable =
									!!snapshot.draggingOverWith?.includes('-groupedVariable');

								const draggedName =
									snapshot.draggingOverWith &&
									snapshot.draggingOverWith.split(
										DragAndDropTypes.DraggableTemplateVariable
									)[0];
								const variableIsAlreadyInTemplate =
									(elementsOrder &&
										!!elementsOrder.find(
											element =>
												element.split(
													DragAndDropTypes.DraggableTemplateVariable
												)[0] === draggedName
										)) ||
									!!groups.find(group =>
										group.elementsOrder.find(
											element =>
												element.split(
													DragAndDropTypes.DraggableTemplateVariable
												)[0] === draggedName
										)
									);
								const freezeItems =
									dragGroupedVariable && variableIsAlreadyInTemplate;

								return (
									<TemplateBody ref={provided.innerRef}>
										{view === TemplateViewTypes.Dropzone ? (
											<DropzoneContainer>
												<DroppableZoneWrapper
													highlighted={snapshot.isDraggingOver}
													forbiddenHighlighted={freezeItems}
													data-test-id="templateDropzone"
												>
													{!freezeItems ? (
														<DragIcon src={Images.dragIcon} />
													) : (
														<Icon svg={Svgs.AlertCircle} />
													)}
													<DropZoneMessage
														forbiddenHighlighted={freezeItems}
													>
														{translate(({ templates }) =>
															freezeItems
																? templates.dropNotAllowed
																: emptyVariablesList
																? templates.emptyTemplateDropZoneMessage
																: templates.dropZoneMessage
														)}
													</DropZoneMessage>
												</DroppableZoneWrapper>
											</DropzoneContainer>
										) : (
											<DescriptionContainer
												writeAccess={accessWrite || withUserEdit}
											>
												<Input
													className="description-input"
													type={InputType.Textarea}
													value={description}
													readOnly={!withUserEdit}
													placeholder={translate(
														({ templates }) =>
															templates.descriptionPlaceholder
													)}
													onChange={e => setDescription(e.target.value)}
													onBlur={() =>
														setTemplateDescription({
															templateId,
															description
														})
													}
												/>
											</DescriptionContainer>
										)}

										<Spacer size={s => s.s} />

										<Gap marginGap={{ bottom: 0.8 }} style={{ width: '100%' }}>
											{elementsOrder.map((draggableId, elementsIndex) => {
												if (isVariable(draggableId)) {
													const variableName = draggableId.split(
														DragAndDropTypes.DraggableTemplateVariable
													)[0];

													const variable = variables.find(
														variable => variable.name === variableName
													);

													if (variable) {
														return (
															<Draggable
																index={elementsIndex}
																draggableId={draggableId}
																key={draggableId}
																isDragDisabled={!accessWrite}
															>
																{(
																	variableProvided,
																	variableSnapshot
																) => (
																	<VariableContainer
																		key={draggableId}
																		ref={
																			variableProvided.innerRef
																		}
																		{...variableProvided.draggableProps}
																		{...variableProvided.dragHandleProps}
																		style={
																			freezeItems
																				? getCloneStyle(
																						variableProvided
																							.draggableProps
																							.style as DraggingStyle,
																						variableSnapshot
																				  )
																				: variableProvided
																						.draggableProps
																						.style
																		}
																	>
																		<TemplateVariable
																			variable={variable}
																			publishedTemplate
																			templateId={templateId}
																			accessWrite={
																				accessWrite
																			}
																		/>
																	</VariableContainer>
																)}
															</Draggable>
														);
													} else {
														return null;
													}
												} else {
													const groupName = draggableId.split(
														DragAndDropTypes.DraggableTemplateGroup
													)[0];
													const group = groups.find(
														group => group.groupName === groupName
													);

													if (group) {
														return (
															<Draggable
																index={elementsIndex}
																draggableId={draggableId}
																key={draggableId}
																isDragDisabled={!accessWrite}
															>
																{(groupProvided, groupSnapshot) => (
																	<VariableContainer
																		key={draggableId}
																		ref={groupProvided.innerRef}
																		{...groupProvided.dragHandleProps}
																		{...groupProvided.draggableProps}
																		style={
																			freezeItems
																				? getCloneStyle(
																						groupProvided
																							.draggableProps
																							.style as DraggingStyle,
																						groupSnapshot
																				  )
																				: groupProvided
																						.draggableProps
																						.style
																		}
																	>
																		<TemplateGroupWithVariables
																			key={templateId}
																			templateId={templateId}
																			group={group}
																			publishedTemplate
																			writeAccess={
																				accessWrite
																			}
																			withDropzone={
																				withUserEdit
																			}
																		/>
																	</VariableContainer>
																)}
															</Draggable>
														);
													} else {
														return null;
													}
												}
											})}
										</Gap>

										{!freezeItems ? (
											provided.placeholder
										) : (
											<div
												style={{
													visibility: 'hidden',
													height: 0,
													width: 0
												}}
											>
												{provided.placeholder}
											</div>
										)}
									</TemplateBody>
								);
							}}
						</Droppable>
					</>
				) : (
					// Variables and groups without dropzone
					<>
						<Spacer size={s => s.s} />

						<DescriptionContainer writeAccess={accessWrite}>
							<Input
								className="description-input"
								type={InputType.Textarea}
								value={description}
								placeholder={translate(({ templates }) => templates.noDescription)}
								readOnly={accessWrite}
							/>
						</DescriptionContainer>

						<Spacer size={s => s.s} />

						<Gap marginGap={{ bottom: 0.8 }} style={{ width: '100%' }}>
							{variables &&
								variables.map(variable => {
									return (
										<VariableContainer key={variable.draggableId}>
											<TemplateVariable
												variable={variable}
												publishedTemplate
											/>
										</VariableContainer>
									);
								})}
							{groups &&
								groups.map((group, index) => (
									<TemplateGroupWithVariables
										key={group.groupName + index}
										templateId={templateId}
										group={group}
										publishedTemplate
									/>
								))}
						</Gap>
					</>
				))}
		</TemplateCard>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { setAnalysesColumn, SetAnalysesColumnAction } from 'store/data/analyses';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

export function useAnalysesActiveColum(): LocalOperationResult<
	number,
	(payload: ActionPayload<SetAnalysesColumnAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.analyses.activeColumn);

	function handler(payload: ActionPayload<SetAnalysesColumnAction>) {
		dispatch(setAnalysesColumn(payload));
	}

	return [data, handler];
}

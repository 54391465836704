import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setSubscriptionUsersSearchTerm } from 'store/account/subscription';

export function useSubscriptionUsersSearchTerm(): LocalOperationResult<
	string,
	(term: string) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.account.subscription.subscriptionUsers.searchTerm);

	function handler(term: string) {
		dispatch(setSubscriptionUsersSearchTerm({ term }));
	}

	return [data, handler];
}

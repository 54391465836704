import type {
	ArithmeticNode,
	CalculationActions,
	Variable,
	VariableNode
} from 'api/data/variables';
import { ArithmeticOperator, OperandType, VariableType } from 'types/data/variables/constants';
import { Dropdown } from 'components/UI/Dropdown';
import { useTranslation } from 'hooks/store';
import { useMemo, useState } from 'react';
import { SelectItem } from 'types/index';
import {
	AddButton,
	FieldColumn,
	ReadonlyField,
	RuleContainer,
	RuleRow
} from '../CalculatedVariable.style';
import { CalculationDeleteIcon } from '../DeleteIcon/CalculationDeleteIcon';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { DynamicCalculationInput } from './DynamicCalculationInput';
import { isArithmeticNode } from '../helpers';

type Props = {
	node: ArithmeticNode | null;
	nodePath: string | null;
	variables: Variable[];
	draftVariableType: VariableType;
	calculationActions: CalculationActions;
};

export function DurationOperation({
	node,
	nodePath,
	variables,
	draftVariableType,
	calculationActions
}: Props) {
	const { onAddOperandValue, onAddEmptyOperandAtJsonLogicPath, onOperatorChange } =
		calculationActions;
	const { translate } = useTranslation();

	const [isDeleteHovered, setIsDeleteHovered] = useState(false);

	const { firstField, secondField } = useMemo(
		() => extractFields(node, nodePath),
		[node, nodePath]
	);

	const operator = useMemo(() => extractOperator(node), [node]);

	const operatorSelectItems: SelectItem[] = [
		ArithmeticOperator.Addition,
		ArithmeticOperator.Subtraction
	].map(operator => ({
		label: operator,
		value: operator
	}));

	function handleSetOperator(operator: ArithmeticOperator) {
		onOperatorChange(nodePath, operator);
	}

	function handleAddNewOperand(path: string, type: OperandType) {
		onAddEmptyOperandAtJsonLogicPath(path, type);
	}

	function dropdownElements(path: string) {
		return [
			<Dropdown.Item
				key={`durationOperation-addInput`}
				title={translate(dict => dict.terms.variable)}
				onClick={() => handleAddNewOperand(path, OperandType.DurationVariable)}
			/>
		];
	}

	return (
		<RuleContainer
			isDeleteHovered={isDeleteHovered}
			id={`${nodePath}-durationOperation-container`}
		>
			<RuleRow depth={0}>
				{
					<FieldColumn>
						<ReadonlyField>{translate(dict => dict.terms.valuesIs)}</ReadonlyField>
					</FieldColumn>
				}
				<FieldColumn>
					{firstField.value == null || firstField.value == undefined ? (
						<Dropdown
							button
							width={24}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => {
								return (
									<AddButton
										ref={ref}
										onClick={toggle}
										full
										id={`duration-add-operand-${
											firstField.path ? firstField.path : nodePath + '.0'
										}`}
									>
										{translate(dict => dict.terms.add)}
									</AddButton>
								);
							}}
						>
							{dropdownElements(firstField.path)}
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={firstField.type!}
							value={firstField.value}
							valueDepth={0}
							valuePath={firstField.path}
							onChange={value =>
								onAddOperandValue(firstField.path, firstField.type!, value)
							}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
						/>
					)}
				</FieldColumn>

				<FieldColumn>
					<CreatableSelect
						items={operatorSelectItems}
						value={operatorSelectItems.find(item => item.value === operator)}
						onValueSelected={operator =>
							operator && handleSetOperator(operator as ArithmeticOperator)
						}
						canClear={false}
						id={`${nodePath}-duration-operator`}
					/>
				</FieldColumn>

				<FieldColumn>
					{secondField.value == null || secondField.value == undefined ? (
						<Dropdown
							button
							width={24}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => {
								return (
									<AddButton
										ref={ref}
										onClick={toggle}
										full
										id={`duration-add-operand-${
											secondField.path ? secondField.path : nodePath + '.1'
										}`}
									>
										{translate(dict => dict.terms.add)}
									</AddButton>
								);
							}}
						>
							{dropdownElements(secondField.path)}
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={secondField.type!}
							value={secondField.value}
							valueDepth={0}
							valuePath={secondField.path}
							onChange={value =>
								onAddOperandValue(secondField.path, secondField.type!, value)
							}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
						/>
					)}
				</FieldColumn>
			</RuleRow>
			<CalculationDeleteIcon
				calculationActions={calculationActions}
				depth={0}
				isDeleteHovered={isDeleteHovered}
				setIsDeleteHovered={setIsDeleteHovered}
				nodePath={nodePath}
			/>
		</RuleContainer>
	);
}

function extractFields(rootNode: ArithmeticNode | null, rootPath: string | null) {
	const fields: {
		firstField: {
			value: string | VariableNode | null;
			path: string;
			type: OperandType | null;
		};
		secondField: {
			value: string | VariableNode | null;
			path: string;
			type: OperandType | null;
		};
	} = {
		firstField: {
			value: null,
			path: '',
			type: null
		},
		secondField: {
			value: null,
			path: '',
			type: null
		}
	};

	if (rootNode && rootPath && ArithmeticOperator.Addition in rootNode) {
		rootNode[ArithmeticOperator.Addition].forEach((value, index) => {
			const nodePath = `${rootPath}.${ArithmeticOperator.Addition}.${index}`;

			if (value || value === '') {
				const type = OperandType.DurationVariable;

				if (index == 0) {
					fields.firstField = {
						value: !isArithmeticNode(value) && typeof value !== 'number' ? value : null,
						path: nodePath,
						type
					};
				} else {
					fields.secondField = {
						value: !isArithmeticNode(value) && typeof value !== 'number' ? value : null,
						path: nodePath,
						type
					};
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (rootNode && rootPath && ArithmeticOperator.Subtraction in rootNode) {
		rootNode[ArithmeticOperator.Subtraction].forEach((value, index) => {
			const nodePath = `${rootPath}.${ArithmeticOperator.Subtraction}.${index}`;

			if (value || value === '' || value === 0) {
				const type = OperandType.DurationVariable;
				if (index == 0) {
					fields.firstField = {
						value: !isArithmeticNode(value) && typeof value !== 'number' ? value : null,
						path: nodePath,
						type
					};
				} else {
					fields.secondField = {
						value: !isArithmeticNode(value) && typeof value !== 'number' ? value : null,
						path: nodePath,
						type
					};
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	return fields;
}

function extractOperator(rootNode: ArithmeticNode | null) {
	if (rootNode && ArithmeticOperator.Addition in rootNode) {
		return ArithmeticOperator.Addition;
	}

	if (rootNode && ArithmeticOperator.Subtraction in rootNode) {
		return ArithmeticOperator.Subtraction;
	}

	if (rootNode && ArithmeticOperator.Multiplication in rootNode) {
		return ArithmeticOperator.Multiplication;
	}

	if (rootNode && ArithmeticOperator.Division in rootNode) {
		return ArithmeticOperator.Division;
	}

	return null;
}

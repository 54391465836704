import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	margin: auto;
	max-width: 60rem;
	padding: 0 2rem;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;

	@media ${MediaQueries.maxWidth.sm} {
		flex-direction: column;
	}
`;

export const FlexElement = styled.div`
	flex: 1;
`;

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, createOrganization } from 'store/data/collaborators';

type HandlerInput = Parameters<typeof createOrganization>[0];

export function useCreateOrganization(): Operation<(input: HandlerInput) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_ORGANIZATION);

	async function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		await dispatch(createOrganization(input));
	}

	return [{ loading, error }, handler];
}

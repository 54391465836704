import { isEqual } from 'lodash';

import { asyncForEach } from 'api/utils/helpers';
import { UpdateCollaboratorsAccess } from 'api/data/collaborators';
import { setInvalidUsers } from 'store/data/accounts';
import { setRefetchEntries } from 'store/data/entries';
import { setRefetchAnalyses } from 'store/data/analyses';
import {
	updateProjectOwnerPermissions,
	getProjectsCollaboratorsAvatarData
} from 'store/data/projects';
import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	CollaboratorPermissions,
	// ASYNC
	GetCollaboratorsAction,
	UpdateCollaboratorPermissionsAction,
	UpdateCollaboratorsPermissionsAction,
	RemoveCollaboratorAction,
	// LOCAL
	SetOrganizationsByIdAction,
	SetCollaboratorsSearchTermAction,
	CollaboratorEmailAddressWithPermissions,
	Organization,
	CreateOrganizationAction,
	UpdateOrganizationAction,
	DeleteOrganizationAction,
	AddCollaboratorsToOrganizationsAction,
	RemoveCollaboratorsFromOrganizationsAction,
	SetCollaboratorsViewOption,
	ResetFetchedCollaboratorsAction,
	SetRefetchCollaboratorsAction
} from './types';
import {
	LicenceLimitationErrorTypes,
	toggleFreeLicenceLimitationModalEvent
} from 'helpers/licences';
import { Role } from '../roles';
import { track } from 'app/tracking/TrackingProvider';

/*
	============================
				ASYNC
	============================
*/

export const getCollaboratorsAction = (
	payload: ActionPayload<GetCollaboratorsAction>
): GetCollaboratorsAction => ({
	type: ActionTypes.GET_COLLABORATORS,
	payload
});

export const getCollaborators = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_COLLABORATORS, dispatch });

	const { projectId, byId: projectsById } = getState().data.projects;
	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const getCollaboratorsPromise = context.api.data
				.collaborators()
				.getCollaborators({ project: { projectId } });

			const getOrganizationsPromise = context.api.data
				.collaborators()
				.getOrganizations({ project: { projectId } });

			const [collaborators, { organizationsData }] = await Promise.all([
				getCollaboratorsPromise,
				getOrganizationsPromise
			]);

			dispatch(getCollaboratorsAction({ projectId, collaborators, organizationsData }));

			// GET FRESH LIST OF AVATARS
			dispatch(
				getProjectsCollaboratorsAvatarData({
					fetchForProms: !!projectsById[projectId]?.promType
				})
			);
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

export const addCollaborators =
	(emailAddresses: string[]): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ADD_COLLABORATORS, dispatch });

		try {
			activity.begin();

			const { projectId } = getState().data.collaborators;

			if (projectId) {
				// COMPUTE DATA FOR API
				const userAccesses = emailAddresses.map(emailAddress => ({
					projectId: Number(projectId),
					emailAddress
				}));

				const { notSharedWith, notEligibleForSharing } = await context.api.data
					.collaborators()
					.addCollaborators({
						userAccesses
					});

				if (notEligibleForSharing && notEligibleForSharing.length) {
					dispatch(setInvalidUsers({ users: notEligibleForSharing }));

					toggleFreeLicenceLimitationModalEvent().dispatch(
						LicenceLimitationErrorTypes.collaboratorShareProject
					);
					activity.error({ error: '' });
				}

				track({
					eventName: 'collaborators_confirmed',
					data: {
						not_shared_with_count: notSharedWith.length,
						not_eligible_for_sharing_count: notEligibleForSharing?.length,
						total_number_of_collaborators: emailAddresses.length
					}
				});

				if (notSharedWith.length > 0) {
					activity.error({
						error: `Project could not be shared with users ${notSharedWith.join(',')}`
					});
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

const updateCollaboratorPermissionsAction = (
	payload: ActionPayload<UpdateCollaboratorPermissionsAction>
): UpdateCollaboratorPermissionsAction => ({
	type: ActionTypes.UPDATE_COLLABORATOR_PERMISSIONS,
	payload
});

export const updateCollaboratorPermissions =
	(input: {
		collaboratorId: string;
		permissions: CollaboratorPermissions;
		projectRoleId: Role['id'] | null;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_COLLABORATOR_PERMISSIONS,
			dispatch
		});

		const { collaboratorId, permissions, projectRoleId } = input;

		try {
			activity.begin();

			const {
				account: {
					subscription: { accountDetails }
				},
				auth: { username },
				data: {
					collaborators: { projectId, byProjectId },
					projects: { byId: projectsById }
				}
			} = getState();

			if (projectId && byProjectId[projectId] && accountDetails) {
				// GET COLLABORATOR
				const collaborator = byProjectId[projectId].data.collaborators.byId[collaboratorId];

				const ownerPermissions = projectsById[projectId].userAccess;

				// IF THERE IS NO COLLABORATOR, IT MEANS IS THE PROJECT OWNER
				const isProjectOwner = collaboratorId === username;

				const emailAddress = isProjectOwner
					? accountDetails.emailAddress
					: collaborator.emailAddress;

				const { modules, statusTypeAccesses, ...generalPermissions } = permissions;

				// CHECK IF THERE IS A ROLEID AND PREPARE DATA FOR API
				const roleAccess = projectRoleId
					? { projectRoleId: Number(projectRoleId), userEmailAddress: emailAddress }
					: null;

				// COMPUTE DATA FOR API
				const userAccess: UpdateCollaboratorsAccess = {
					userEmailAddress: emailAddress,
					...generalPermissions,
					projectOrganizationAccesses: [],
					accessModuleProjectDesign: modules.projectDesign,
					accessModuleCollaborators: modules.collaborators,
					statusTypeAccesses
				};

				await context.api.data.collaborators().updateCollaboratorsPermissions({
					project: {
						projectId,
						userAccesses: roleAccess ? [roleAccess] : [userAccess]
					}
				});

				if (isProjectOwner) {
					dispatch(updateProjectOwnerPermissions({ permissions }));

					if (ownerPermissions) {
						const permissionsChanged = !isEqual(ownerPermissions, permissions);

						if (permissionsChanged) {
							// MARK ENTRIES FOR REFETCH
							dispatch(setRefetchEntries());
							// MARK ANALYSIS FOR REFETCH
							dispatch(setRefetchAnalyses());
						}
					}
				}

				dispatch(
					updateCollaboratorPermissionsAction({
						collaboratorId,
						permissions,
						projectRoleId
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

const updateCollaboratorsPermissionsAction = (
	payload: ActionPayload<UpdateCollaboratorsPermissionsAction>
): UpdateCollaboratorsPermissionsAction => ({
	type: ActionTypes.UPDATE_COLLABORATORS_PERMISSIONS,
	payload
});

export const updateCollaboratorsPermissions =
	(
		collaboratorsWithPermissions: CollaboratorEmailAddressWithPermissions[],
		projectRoleId?: string
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_COLLABORATORS_PERMISSIONS,
			dispatch
		});

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				// COMPUTE DATA FOR API
				const userAccesses: UpdateCollaboratorsAccess[] = !projectRoleId
					? collaboratorsWithPermissions.map(
							({
								emailAddress,
								permissions: { modules, statusTypeAccesses, ...generalPermissions }
							}) => ({
								userEmailAddress: emailAddress,
								...generalPermissions,
								projectOrganizationAccesses: [],
								accessModuleProjectDesign: modules.projectDesign,
								accessModuleCollaborators: modules.collaborators,
								statusTypeAccesses
							})
					  )
					: collaboratorsWithPermissions.map(({ emailAddress }) => ({
							userEmailAddress: emailAddress,
							projectRoleId: Number(projectRoleId)
					  }));

				await context.api.data.collaborators().updateCollaboratorsPermissions({
					project: {
						projectId,
						userAccesses
					}
				});

				dispatch(updateCollaboratorsPermissionsAction({ collaboratorsWithPermissions }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

const removeCollaboratorAction = (
	payload: ActionPayload<RemoveCollaboratorAction>
): RemoveCollaboratorAction => ({
	type: ActionTypes.REMOVE_COLLABORATOR,
	payload
});

export const removeCollaborator =
	(input: {
		collaboratorId: string;
		options?: {
			omitRefetch?: boolean;
		};
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.REMOVE_COLLABORATOR, dispatch });

		const { collaboratorId, options } = input;

		try {
			activity.begin({ payload: { collaboratorId } });

			const { projectId, byProjectId } = getState().data.collaborators;

			if (
				projectId &&
				byProjectId[projectId] &&
				collaboratorId in byProjectId[projectId].data.collaborators.byId
			) {
				// EXTRACT COLLABORATOR EMAIL ADDRESS
				const { emailAddress } =
					byProjectId[projectId].data.collaborators.byId[collaboratorId];

				const notSharedWith = await context.api.data.collaborators().removeCollaborator({
					userAccesses: [
						{
							projectId: Number(projectId),
							emailAddress
						}
					]
				});

				if (notSharedWith.length) {
					activity.error({
						error: `Could not remove user from project ${notSharedWith.join(',')}`
					});
				} else {
					dispatch(removeCollaboratorAction({ collaboratorId }));
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { collaboratorId } });
		} finally {
			activity.end();

			if (!options?.omitRefetch) dispatch(getCollaborators());
		}
	};

const createOrganizationAction = (
	payload: ActionPayload<CreateOrganizationAction>
): CreateOrganizationAction => ({
	type: ActionTypes.CREATE_ORGANIZATION,
	payload
});

export const createOrganization =
	(input: { organization: Organization }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_ORGANIZATION, dispatch });

		const { organization } = input;

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				const { organization: createdOrganization } = await context.api.data
					.collaborators()
					.createOrganization({
						project: {
							projectId,
							organization: {
								organizationName: organization.name,
								...(organization.collaborators.length && {
									organizationCollaborators: organization.collaborators.map(
										collaboratorId => ({ userId: collaboratorId })
									)
								})
							}
						}
					});

				dispatch(
					createOrganizationAction({
						projectId,
						organization: createdOrganization
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

const updateOrganizationAction = (
	payload: ActionPayload<UpdateOrganizationAction>
): UpdateOrganizationAction => ({
	type: ActionTypes.UPDATE_ORGANIZATION,
	payload
});

export const updateOrganization =
	(input: { organization: Organization }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_ORGANIZATION, dispatch });

		const { organization } = input;

		try {
			activity.begin({ payload: { organizationId: organization.id } });

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				const {
					// TODO: use when parser is in place
					// organization: updatedOrganization
				} = await context.api.data.collaborators().updateOrganization({
					project: {
						projectId,
						organization: {
							organizationId: Number(organization.id),
							organizationName: organization.name
						}
					}
				});

				dispatch(
					updateOrganizationAction({
						projectId,
						// TODO: replace with `updatedOrganization` when parser is in place
						organization
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { organizationId: organization.id } });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

const deleteOrganizationAction = (
	payload: ActionPayload<DeleteOrganizationAction>
): DeleteOrganizationAction => ({
	type: ActionTypes.DELETE_ORGANIZATION,
	payload
});

export const deleteOrganization =
	(input: {
		organizationId: string;
		options?: {
			omitRefetch?: boolean;
		};
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_ORGANIZATION, dispatch });

		const { organizationId, options } = input;

		try {
			activity.begin({ payload: { organizationId } });

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				await context.api.data.collaborators().deleteOrganization({
					project: {
						projectId,
						organization: {
							organizationId: Number(organizationId)
						}
					}
				});

				dispatch(deleteOrganizationAction({ projectId, organizationId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { organizationId } });
		} finally {
			activity.end();

			if (!options?.omitRefetch) dispatch(getCollaborators());
		}
	};

const addCollaboratorsToOrganizationAction = (
	payload: ActionPayload<AddCollaboratorsToOrganizationsAction>
): AddCollaboratorsToOrganizationsAction => ({
	type: ActionTypes.ADD_COLLABORATORS_TO_ORGANIZATIONS,
	payload
});

export const addCollaboratorsToOrganization =
	(input: {
		organizationIds: string[];
		collaborators: AddCollaboratorsToOrganizationsAction['payload']['collaborators'];
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.ADD_COLLABORATORS_TO_ORGANIZATIONS,
			dispatch
		});

		const { organizationIds, collaborators } = input;

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				await context.api.data.collaborators().addCollaboratorsToOrganizations({
					projectId,
					organizationIds,
					userIds: collaborators
				});

				dispatch(
					addCollaboratorsToOrganizationAction({
						projectId,
						organizationIds,
						collaborators
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

const removeCollaboratorsFromOrganizationAction = (
	payload: ActionPayload<RemoveCollaboratorsFromOrganizationsAction>
): RemoveCollaboratorsFromOrganizationsAction => ({
	type: ActionTypes.REMOVE_COLLABORATORS_FROM_ORGANIZATIONS,
	payload
});

export const removeCollaboratorsFromOrganization =
	(input: { organizationIds: string[]; collaborators: string[] }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_COLLABORATORS_FROM_ORGANIZATIONS,
			dispatch
		});

		const { collaborators, organizationIds } = input;

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				await context.api.data.collaborators().removeCollaboratorsFromOrganizations({
					organizationIds,
					projectId,
					userIds: collaborators
				});

				dispatch(
					removeCollaboratorsFromOrganizationAction({
						projectId,
						organizationIds,
						collaborators
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

export const deleteBulkCollaboratorsData =
	(input: { organizationIds: string[]; collaboratorIds: string[] }): Thunk =>
	async (dispatch, getState) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_BULK_COLLABORATORS_DATA,
			dispatch
		});

		const { organizationIds, collaboratorIds } = input;

		try {
			activity.begin();

			const { projectId, byProjectId } = getState().data.collaborators;

			if (projectId && byProjectId[projectId]) {
				// TODO: replace with `removeCollaborators`
				await asyncForEach(collaboratorIds, async collaboratorId => {
					await dispatch(
						removeCollaborator({
							collaboratorId,
							options: {
								omitRefetch: true
							}
						})
					);
				});

				// TODO: replace with `deleteOrganizations`
				await asyncForEach(organizationIds, async organizationId => {
					await dispatch(
						deleteOrganization({
							organizationId,
							options: {
								omitRefetch: true
							}
						})
					);
				});
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

/*
	============================
				LOCAL
	============================
*/

export const setOrganizationsById = (
	payload: ActionPayload<SetOrganizationsByIdAction>
): SetOrganizationsByIdAction => ({
	type: ActionTypes.SET_ORGANIZATIONS_BY_ID,
	payload
});

export const setCollaboratorsSearchTerm = (
	payload: ActionPayload<SetCollaboratorsSearchTermAction>
): SetCollaboratorsSearchTermAction => ({
	type: ActionTypes.SET_COLLABORATORS_SEARCH_TERM,
	payload
});

export const setCollaboratorsViewOption = (
	payload: ActionPayload<SetCollaboratorsViewOption>
): SetCollaboratorsViewOption => ({
	type: ActionTypes.SET_COLLABORATORS_VIEW_OPTION,
	payload
});
export const resetFetchedCollaborators = (
	payload: ActionPayload<ResetFetchedCollaboratorsAction>
): ResetFetchedCollaboratorsAction => ({
	type: ActionTypes.RESET_FETCHED_COLLABORATORS,
	payload
});
export const setRefetchCollaborators = (): SetRefetchCollaboratorsAction => ({
	type: ActionTypes.SET_REFETCH_COLLABORATORS
});

import { State } from './types';

const initialState: State = {
	projectId: null,
	exportType: null,
	downloadUrl: null,
	valid: [],
	empty: [],
	invalid: []
};

export default initialState;

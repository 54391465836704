import { createSelector } from 'reselect';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getState(state: State) {
	return state;
}

function getJADBioProject(state: State, projectId: string | null) {
	if (state && projectId && projectId in state.projects.byProjectId) {
		return state.projects.byProjectId[projectId];
	}

	return null;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const isLoggedInToJADBio = createSelector([getState], JADBio => JADBio !== null);

export const selectJADBioProject = createSelector([getJADBioProject], datasetId => datasetId);

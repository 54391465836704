import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, addWhitelistedDomains } from 'store/account/admin';

export function useAddWhitelistedDomains(): Operation<(domains: string[]) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.ADD_WHITELISTED_DOMAINS);

	async function handler(domains: string[]) {
		if (loading) return;
		if (error) resetError();

		await dispatch(addWhitelistedDomains(domains));
	}

	return [{ loading, error }, handler];
}

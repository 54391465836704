import styled from 'styled-components';

import { Svgs, MediaQueries } from 'environment';
import { RequireAtLeastOne } from 'types/index';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface StyleProps {
	isConfigPanelOpen?: boolean;
	fullScreen?: boolean;
	activeColumn?: number;
}
interface Props {
	category?: boolean;
	numeric?: boolean;
	date?: boolean;
	noPositiveOutcome?: boolean;
	bodyMessage?: string;
	headerMessage?: string;
}

export function InvalidAnalysis({
	category,
	numeric,
	date,
	noPositiveOutcome,
	bodyMessage,
	headerMessage,
	isConfigPanelOpen,
	fullScreen,
	activeColumn
}: RequireAtLeastOne<Props & StyleProps>) {
	const { translate } = useTranslation();

	function getMessage() {
		if (bodyMessage) return bodyMessage;

		if (category && numeric && date) {
			return translate(
				({ analysis }) => analysis.errors.invalidAnalysis.categoryNumericDateError
			);
		}
		if (category && numeric) {
			return translate(
				({ analysis }) => analysis.errors.invalidAnalysis.categoryAndNumericError
			);
		}
		if (numeric && date) {
			return translate(({ analysis }) => analysis.errors.invalidAnalysis.numericAndDateError);
		}
		if (category) {
			return translate(({ analysis }) => analysis.errors.invalidAnalysis.categoryError);
		}

		if (numeric) {
			return translate(({ analysis }) => analysis.errors.invalidAnalysis.numericError);
		}

		if (noPositiveOutcome) {
			return translate(
				({ analysis }) => analysis.errors.invalidAnalysis.positiveOutcomeError
			);
		}
	}

	return (
		<Container
			isConfigPanelOpen={isConfigPanelOpen}
			activeColumn={activeColumn}
			fullScreen={fullScreen}
		>
			<Icon />
			<Spacer size={m => m.m} />
			<Typography.H3 alignCenter>
				{headerMessage ?? translate(({ analysis }) => analysis.errors.noAnalyses)}
			</Typography.H3>
			<Spacer size={s => s.xs} />
			<Typography.Paragraph alignCenter>{getMessage()}</Typography.Paragraph>
		</Container>
	);
}

const Container = styled.div<StyleProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 auto;
	width: ${({ isConfigPanelOpen, activeColumn, fullScreen }) =>
		(isConfigPanelOpen && activeColumn === 1) || (isConfigPanelOpen && fullScreen)
			? `calc(100% - 27.5rem)`
			: '100%'};
`;

const Icon = styled(Svgs.EmptyStatesAnalysis)`
	@media ${MediaQueries.maxWidth.sm} {
		height: calc(100vw - 22rem);
	}
`;

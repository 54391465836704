import type { ConnectDropTarget } from 'react-dnd';

import type { Action } from 'types/store/types';

export interface DragProps {
	ref: React.RefObject<HTMLDivElement>;
	isDragging: boolean;
	drag: ConnectDropTarget;
}

export interface VariableCardPayload {
	name: string;
	index: number;
	isGroup?: boolean;
	isVariableSet?: boolean;
	parentGroup?: string;
	parentSet?: string;
	systemGenerated?: boolean;
}

export enum CardTypesE {
	Variable = 'variable',
	Group = 'group',
	Set = 'set'
}

export type VariableCardAction = Action<
	CardTypesE.Variable,
	{
		name: string;
		subtitle: string;
		required: boolean;
	}
>;

export type GroupCardAction = Action<
	CardTypesE.Group,
	{
		name: string;
		selected: boolean;
		numberOfVariables: number;
	}
>;

export type VariableSetCardAction = Action<
	CardTypesE.Set,
	{
		name: string;
		selected: boolean;
		numberOfVariables: number;
	}
>;

export type CardTypes = VariableCardAction | GroupCardAction | VariableSetCardAction;

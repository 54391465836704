import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';

interface ContainerProps {
	previewEnabled: boolean;
}
export const Container = styled.div<ContainerProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};

	${({ previewEnabled }) =>
		previewEnabled &&
		css`
			cursor: pointer;

			:hover ${Border} {
				display: block;
			}
		`}
`;

export const Border = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 0.4rem;
	pointer-events: none;
	border: 0.2rem solid ${Colors.primary.normal};
	display: none;
`;

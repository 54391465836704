import type {
	Group,
	Variable,
	VariableSet,
	AggregationRule,
	VariableCategory
} from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import type { Entry } from 'store/data/entries';
import type { Action } from 'types/store/types';
import type { GenericMap, Nullable, RequireOnlyOne } from 'types/index';

export interface ProjectVariablesData {
	initial: StoreVariablesData;
	current: StoreVariablesData;
	metadata: {
		viewOption: VariablesViewOptions;
		filters: VariablesFilters;
		variablesTypeChanges: VariableTypeChange[];
	};
	fetched: boolean;
}
export interface State {
	projectId: string | null;
	byProjectId: GenericMap<ProjectVariablesData>;
	metadata: {
		variableName: string | null;
		destinationSetName: string | null;
		destinationGroupName: string | null;
		searchTerm: string;
		dragging: string;
	};
}

export interface StoreVariablesData {
	variables: VariablesState;
	groups: VariableGroupsState;
	variableSets: VariableSetsState;
	order: VariablesOrder;
}

export interface VariablesState {
	byName: VariablesMap;
}

export interface VariableGroupsState {
	byName: GroupsMap;
}

export interface VariableSetsState {
	byName: VariableSetsMap;
}

export type VariablesMap = GenericMap<Variable>;
export type GroupsMap = GenericMap<Group>;
export type VariableSetsMap = GenericMap<VariableSet>;

export type VariablesOrderItem = { variable: string } | { group: string } | { set: string };
export type VariableSetOrderItem = Exclude<VariablesOrderItem, { set: string }>;

export type VariablesOrder = VariablesOrderItem[];
export type VariableSetOrder = VariableSetOrderItem[];

export type VariablesDataArrayItem = Variable | GroupData | VariableSetData;
export type VariablesSetDataArrayItem = Exclude<VariablesDataArrayItem, VariableSetData>;

export type VariablesDataArray = VariablesDataArrayItem[];
export type VariableSetDataArray = VariablesSetDataArrayItem[];

export interface VariablesData {
	variablesMap: VariablesMap;
	groupsMap: GroupsMap;
	variableSetsMap: VariableSetsMap;
	order: VariablesOrder;
}

export interface VariablesRichData {
	variables: Variable[];
	variablesMap: VariablesMap;
	variableNames: string[];
	//////////////
	groups: Group[];
	groupsMap: GroupsMap;
	groupNames: string[];
	//////////////
	groupsOutsideSets: Group[];
	groupNamesOutsideSets: string[];
	//////////////
	variableSets: VariableSet[];
	variableSetsMap: VariableSetsMap;
	variableSetNames: string[];
	//////////////
	variablesOutsideSets: Variable[];
	variableNamesOutsideSets: string[];
	//////////////
	variablesDataArray: VariablesDataArray;
	//////////////
	order: VariablesOrder;
	//////////////
	hasVariables: boolean;
	hasGroups: boolean;
	hasVariableSets: boolean;
	//////////////
	groupedVariableNames: string[];
	groupedVariables: Variable[];
	ungroupedVariableNames: string[];
	ungroupedVariables: Variable[];
}

export interface VariablesDataLocation {
	variablesLocation: GenericMap<{
		setName?: string;
		groupName?: string;
	}>;
	groupsLocation: GenericMap<{
		setName: string;
	}>;
}

export interface GroupData {
	groupName: string;
	groupLabel: string;
	groupVariables: Variable[];
}

export interface VariableSetData {
	setName: string;
	setLabel: string;
	setData: VariableSetDataArray;
	identifier: {
		variableName: string | null;
	};
	aggregationRules: AggregationRule[];
}

export interface VariablesFilters {
	show: ItemOptions;
	type: VariableType | GridVariableTypes.ALL;
	sort: Sort;
	errored: boolean;
}

export interface VariableTypeChange {
	variableName: string;
	toType: VariableType;
	isSafe: Nullable<boolean>;
	isConfirmed: boolean;
}

export type MergeVariablesIntoGroup = (input: MergeVariablesIntoGroupInput) => void;

export type MergeVariablesIntoGroupInput = {
	targetVariable: MergeableVariable;
	draggedVariable: MergeableVariable;
	setName?: string;
	from?: RequireOnlyOne<{
		set?: {
			setName: string;
			variableName: string;
			parentGroup?: string;
		};
		group?: {
			groupName: string;
			variableName: string;
		};
		mainList?: {
			variableName: string;
		};
	}>;
};

export type MergeableVariable = { index: number; name: string };

export interface VariableLocation {
	variableName: string;
	from?: RequireOnlyOne<{
		group?: {
			groupName: string;
		};
		set?: {
			setName: string;
			group?: {
				groupName: string;
			};
		};
	}>;
}

export interface GroupLocation {
	groupName: string;
	from?: RequireOnlyOne<{
		set?: {
			setName: string;
		};
	}>;
}

export enum VariablesViewOptions {
	GRID = 'grid',
	TABLE = 'table'
}

export enum ItemOptions {
	ALL = 'all',
	VARIABLES = 'variablesToUpperCase',
	GROUPS = 'groupsToUpperCase',
	VARIABLE_SETS = 'seriesToUpperCase'
}

export enum GridVariableTypes {
	ALL = 'variableLabels.allTypes'
}

export enum Sort {
	DEFAULT = 'default',
	ALPHABETICALLY = 'alphabetically'
}

export enum DraggableVariableCardType {
	Variable = 'variable',
	Group = 'variableGroup',
	VariableSet = 'variableSet'
}

export enum ActionTypes {
	/**
	 * ===================
	 * 	CRUD - VARIABLES
	 * ===================
	 */

	// GET ONE
	GET_VARIABLE = 'data/variables/GET_VARIABLE',

	// GET ALL
	GET_VARIABLES = 'data/variables/GET_VARIABLES',

	// CREATE
	CREATE_VARIABLE = 'data/variables/CREATE_VARIABLE',
	CREATE_VARIABLE_LOCAL = 'data/variables/CREATE_VARIABLE_LOCAL',

	// UPDATE
	UPDATE_VARIABLE = 'data/variables/UPDATE_VARIABLE',

	// DELETE
	DELETE_VARIABLE = 'data/variables/DELETE_VARIABLE',
	DELETE_VARIABLE_LOCAL = 'data/variables/DELETE_VARIABLE_LOCAL',

	/**
	 * ================
	 * 	CRUD - GROUPS
	 * ================
	 */

	// CREATE
	CREATE_GROUP = 'data/variables/CREATE_GROUP',
	CREATE_GROUP_LOCAL = 'data/variables/CREATE_GROUP_LOCAL',

	// UPDATE
	UPDATE_GROUP = 'data/variables/UPDATE_GROUP',

	// DELETE
	DELETE_GROUP = 'data/variables/DELETE_GROUP',
	DELETE_GROUP_LOCAL = 'data/variables/DELETE_GROUP_LOCAL',

	/**
	 * =======================
	 * 	CRUD - VARIABLE SETS
	 * =======================
	 */

	// CREATE
	CREATE_VARIABLE_SET = 'data/variables/CREATE_VARIABLE_SET',
	CREATE_VARIABLE_SET_LOCAL = 'data/variables/CREATE_VARIABLE_SET_LOCAL',

	// UPDATE
	UPDATE_VARIABLE_SET = 'data/variables/UPDATE_VARIABLE_SET',

	// DELETE
	DELETE_VARIABLE_SET = 'data/variables/DELETE_VARIABLE_SET',
	DELETE_VARIABLE_SET_LOCAL = 'data/variables/DELETE_VARIABLE_SET_LOCAL',

	/**
	 * ==========================
	 * 	CRUD - AGGREGATION RULES
	 * ==========================
	 */

	// CREATE
	CREATE_VARIABLE_SET_AGGREGATION_RULE = 'data/variables/CREATE_VARIABLE_SET_AGGREGATION_RULE',

	// UPDATE
	UPDATE_VARIABLE_SET_AGGREGATION_RULE = 'data/variables/UPDATE_VARIABLE_SET_AGGREGATION_RULE',

	// DELETE
	DELETE_VARIABLE_SET_AGGREGATION_RULE = 'data/variables/DELETE_VARIABLE_SET_AGGREGATION_RULE',

	/**
	 * =========================================
	 * 	ACTIONS: MOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
	 * 	TARGET: ROOT LIST
	 * =========================================
	 */

	// MOVE
	MOVE_VARIABLE = 'data/variables/MOVE_VARIABLE',
	MOVE_VARIABLE_LOCAL = 'data/variables/MOVE_VARIABLE_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_GROUP = 'data/variables/MOVE_GROUP',
	MOVE_GROUP_LOCAL = 'data/variables/MOVE_GROUP_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLE_SET = 'data/variables/MOVE_VARIABLE_SET',
	MOVE_VARIABLE_SET_LOCAL = 'data/variables/MOVE_VARIABLE_SET_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLE_SET_AGGREGATION_RULE = 'data/variables/MOVE_VARIABLE_SET_AGGREGATION_RULE',
	MOVE_VARIABLE_SET_AGGREGATION_RULE_LOCAL = 'data/variables/MOVE_VARIABLE_SET_AGGREGATION_RULE_LOCAL', // USED FOR PREDICTIVE UPDATES

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S)
	 * 	TARGET: FROM/TO GROUP(S)
	 * ==========================================
	 */

	// ADD
	ADD_VARIABLE_TO_GROUP = 'data/variables/ADD_VARIABLE_TO_GROUP',
	ADD_VARIABLE_TO_GROUP_LOCAL = 'data/variables/ADD_VARIABLE_TO_GROUP_LOCAL', // USED FOR PREDICTIVE UPDATES

	ADD_VARIABLES_TO_GROUP = 'data/variables/ADD_VARIABLES_TO_GROUP',
	ADD_VARIABLES_TO_GROUP_LOCAL = 'data/variables/ADD_VARIABLES_TO_GROUP_LOCAL', // USED FOR PREDICTIVE UPDATES

	// REMOVE
	REMOVE_VARIABLE_FROM_GROUP = 'data/variables/REMOVE_VARIABLE_FROM_GROUP',
	REMOVE_VARIABLE_FROM_GROUP_LOCAL = 'data/variables/REMOVE_VARIABLE_FROM_GROUP_LOCAL',

	REMOVE_VARIABLES_FROM_GROUP = 'data/variables/REMOVE_VARIABLES_FROM_GROUP', // TODO: TO BE IMPLEMENTED WITH NEW PAGE DESIGN AND LOGIC
	REMOVE_VARIABLES_FROM_GROUP_LOCAL = 'data/variables/REMOVE_VARIABLES_FROM_GROUP_LOCAL', // USED FOR PREDICTIVE UPDATES

	// MOVE
	MOVE_VARIABLE_INSIDE_GROUP = 'data/variables/MOVE_VARIABLE_INSIDE_GROUP',
	MOVE_VARIABLE_INSIDE_GROUP_LOCAL = 'data/variables/MOVE_VARIABLE_INSIDE_GROUP_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLE_BETWEEN_GROUPS = 'data/variables/MOVE_VARIABLE_BETWEEN_GROUPS',
	MOVE_VARIABLE_BETWEEN_GROUPS_LOCAL = 'data/variables/MOVE_VARIABLE_BETWEEN_GROUPS_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLES_BETWEEN_GROUPS = 'data/variables/MOVE_VARIABLES_BETWEEN_GROUPS',
	MOVE_VARIABLES_BETWEEN_GROUPS_LOCAL = 'data/variables/MOVE_VARIABLES_BETWEEN_GROUPS_LOCAL', // USED FOR PREDICTIVE UPDATES

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S)
	 * 	TARGET: FROM/TO SET(S)
	 * ==========================================
	 */

	// ADD
	ADD_VARIABLE_TO_SET = 'data/variables/ADD_VARIABLE_TO_SET',
	ADD_VARIABLE_TO_SET_LOCAL = 'data/variables/ADD_VARIABLE_TO_SET_LOCAL', // USED FOR PREDICTIVE UPDATES

	// REMOVE
	REMOVE_VARIABLE_FROM_SET = 'data/variables/REMOVE_VARIABLE_FROM_SET',
	REMOVE_VARIABLE_FROM_SET_LOCAL = 'data/variables/REMOVE_VARIABLE_FROM_SET_LOCAL', // USED FOR PREDICTIVE UPDATES

	ADD_VARIABLE_GROUP_TO_SET = 'data/variables/ADD_VARIABLE_GROUP_TO_SET',
	ADD_VARIABLE_GROUP_TO_SET_LOCAL = 'data/variables/ADD_VARIABLE_GROUP_TO_SET_LOCAL', // USED FOR PREDICTIVE UPDATES

	REMOVE_VARIABLE_GROUP_FROM_SET = 'data/variables/REMOVE_VARIABLE_GROUP_FROM_SET',
	REMOVE_VARIABLE_GROUP_FROM_SET_LOCAL = 'data/variables/REMOVE_VARIABLE_GROUP_FROM_SET_LOCAL', // USED FOR PREDICTIVE UPDATES

	// MOVE
	MOVE_VARIABLE_BETWEEN_SETS = 'data/variables/MOVE_VARIABLE_BETWEEN_SETS',
	MOVE_VARIABLE_BETWEEN_SETS_LOCAL = 'data/variables/MOVE_VARIABLE_BETWEEN_SETS_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLES_OR_GROUPS_TO_SET = 'data/variables/MOVE_VARIABLES_OR_GROUPS_TO_SET',
	MOVE_VARIABLES_OR_GROUPS_TO_SET_LOCAL = 'data/variables/MOVE_VARIABLES_OR_GROUPS_TO_SET_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLE_GROUP_BETWEEN_SETS = 'data/variables/MOVE_VARIABLE_GROUP_BETWEEN_SETS',
	MOVE_VARIABLE_GROUP_BETWEEN_SETS_LOCAL = 'data/variables/MOVE_VARIABLE_GROUP_BETWEEN_SETS_LOCAL', // USED FOR PREDICTIVE UPDATES

	MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST = 'data/variables/MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST',
	MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST_LOCAL = 'data/variables/MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST_LOCAL', // USED FOR PREDICTIVE UPDATES

	/**
	 * ================
	 * 	BULK DELETE
	 * ================
	 */

	DELETE_BULK_VARIABLES_DATA = 'data/variables/DELETE_BULK_VARIABLES_DATA',

	/**
	 * ===================
	 * 	CRUD - CATEGORIES
	 * ===================
	 */

	CREATE_VARIABLE_CATEGORY_VALUE = 'data/variables/CREATE_VARIABLE_CATEGORY_VALUE',
	CREATE_VARIABLE_CATEGORY_VALUE_LOCAL = 'data/variables/CREATE_VARIABLE_CATEGORY_VALUE_LOCAL', // USED FOR PREDICTIVE UPDATES
	CREATE_VARIABLE_CATEGORY_VALUES = 'data/variables/CREATE_VARIABLE_CATEGORY_VALUES',
	UPDATE_VARIABLE_CATEGORY_VALUE = 'data/variables/UPDATE_VARIABLE_CATEGORY_VALUE',
	DELETE_VARIABLE_CATEGORY_VALUE = 'data/variables/DELETE_VARIABLE_CATEGORY_VALUE',
	DELETE_VARIABLE_CATEGORY_VALUES = 'data/variables/DELETE_VARIABLE_CATEGORY_VALUES',
	MOVE_VARIABLE_CATEGORY_VALUE = 'data/variables/MOVE_VARIABLE_CATEGORY_VALUE',
	MOVE_VARIABLE_CATEGORY_VALUE_LOCAL = 'data/variables/MOVE_VARIABLE_CATEGORY_VALUE_LOCAL', // USED FOR PREDICTIVE UPDATES
	MOVE_VARIABLE_CATEGORY_VALUES = 'data/variables/MOVE_VARIABLE_CATEGORY_VALUES',
	MOVE_VARIABLE_CATEGORY_VALUES_LOCAL = 'data/variables/MOVE_VARIABLE_CATEGORY_VALUES_LOCAL', // USED FOR PREDICTIVE UPDATES
	MERGE_VARIABLE_CATEGORY_VALUES = 'data/variables/MERGE_VARIABLE_CATEGORY_VALUES',
	ACTIVATE_VARIABLE_CATEGORIES = 'data/variables/ACTIVATE_VARIABLE_CATEGORIES',
	DEACTIVATE_VARIABLE_CATEGORIES = 'data/variables/DEACTIVATE_VARIABLE_CATEGORIES',

	//////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////

	REBUILD_VARIABLES_DATA_CATEGORIES = 'data/variables/REBUILD_VARIABLES_DATA_CATEGORIES',

	// TYPE CHANGE
	CHECK_VARIABLE_TYPE_CHANGE = 'data/variables/CHECK_VARIABLE_TYPE_CHANGE',

	// METADATA
	SET_VARIABLE_NAME = 'data/variables/SET_VARIABLE_NAME',
	SET_DESTINATION_SET_NAME = 'data/variables/SET_DESTINATION_SET_NAME',
	SET_DESTINATION_GROUP_NAME = 'data/variables/SET_DESTINATION_GROUP_NAME',

	// FILTER
	SET_VARIABLES_FILTERS = 'data/variables/SET_VARIABLES_FILTERS',

	// VIEW OPTION
	SET_VARIABLES_VIEW_OPTION = 'data/variables/SET_VARIABLES_VIEW_OPTION',

	// SEARCH TERM
	SET_VARIABLES_SEARCH_TERM = 'data/documents/SET_VARIABLES_SEARCH_TERM',

	// DRAGGING VARIABLE NAME
	SET_DRAGGING_VARIABLE_NAME = 'data/variables/SET_DRAGGING_VARIABLE_NAME'
}

/**
 * ===================
 * 	CRUD - VARIABLES
 * ===================
 */

export type GetVariableAction = Action<ActionTypes.GET_VARIABLE, { variable: Variable }>;

export type GetVariablesAction = Action<
	ActionTypes.GET_VARIABLES,
	{
		projectId: string;
		variablesData: VariablesData;
	}
>;

export type CreateVariableAction = Action<
	ActionTypes.CREATE_VARIABLE | ActionTypes.CREATE_VARIABLE_LOCAL,
	{
		variable: Variable;
		destinationSetName?: string;
		destinationGroupName?: string;
		localChanges?: boolean;
	}
>;

export type UpdateVariableAction = Action<ActionTypes.UPDATE_VARIABLE, { variable: Variable }>;

export type DeleteVariableAction = Action<
	ActionTypes.DELETE_VARIABLE | ActionTypes.DELETE_VARIABLE_LOCAL,
	{
		variableName: string;
		setName?: string;
		localChanges?: boolean;
	}
>;

/**
 * ================
 * 	CRUD - GROUPS
 * ================
 */

export type CreateGroupAction = Action<
	ActionTypes.CREATE_GROUP | ActionTypes.CREATE_GROUP_LOCAL,
	{
		group: Group;
		destinationIndex?: number;
		setName?: string;
		from?: RequireOnlyOne<{
			set?: {
				setName: string;
				variableName: string;
				parentGroup?: string;
			};
			group?: {
				groupName: string;
				variableName: string;
			};
			mainList?: {
				variableName: string;
			};
		}>;
		localChanges?: boolean;
	}
>;

export type UpdateGroupAction = Action<ActionTypes.UPDATE_GROUP, { group: Group }>;

export type DeleteGroupAction = Action<
	ActionTypes.DELETE_GROUP | ActionTypes.DELETE_GROUP_LOCAL,
	{
		groupName: string;
		setName?: string;
		localChanges?: boolean;
	}
>;

/**
 * =======================
 * 	CRUD - VARIABLE SETS
 * =======================
 */

export type CreateVariableSetAction = Action<
	ActionTypes.CREATE_VARIABLE_SET | ActionTypes.CREATE_VARIABLE_SET_LOCAL,
	{
		variableSet: VariableSet;
		group?: Group;
		variableNames?: string[];
		groupNames?: string[];
		destinationIndex?: number;
		localChanges?: boolean;
	}
>;

export type UpdateVariableSetAction = Action<
	ActionTypes.UPDATE_VARIABLE_SET,
	{
		variableSet: VariableSet;
	}
>;

export type DeleteVariableSetAction = Action<
	ActionTypes.DELETE_VARIABLE_SET | ActionTypes.DELETE_VARIABLE_SET_LOCAL,
	{
		setName: string;
		localChanges?: boolean;
	}
>;

/**
 * ==========================
 * 	CRUD - AGGREGATION RULES
 * ==========================
 */

export type CreateVariableSetAggregationRuleAction = Action<
	ActionTypes.CREATE_VARIABLE_SET_AGGREGATION_RULE,
	{
		variableSet: VariableSet;
	}
>;

export type UpdateVariableSetAggregationRuleAction = Action<
	ActionTypes.UPDATE_VARIABLE_SET_AGGREGATION_RULE,
	{
		variableSet: VariableSet;
	}
>;

export type DeleteVariableSetAggregationRuleAction = Action<
	ActionTypes.DELETE_VARIABLE_SET_AGGREGATION_RULE,
	{
		setName: string;
		ruleName: string;
	}
>;

/**
 * =========================================
 * 	ACTIONS: MOVE
 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
 * 	TARGET: ROOT LIST
 * =========================================
 */

export type MoveVariableAction = Action<
	ActionTypes.MOVE_VARIABLE | ActionTypes.MOVE_VARIABLE_LOCAL,
	{
		variableName: string;
		sourceIndex: number;
		destinationIndex: number;
		localChanges?: boolean;
		setName?: string;
	}
>;

export type MoveGroupAction = Action<
	ActionTypes.MOVE_GROUP | ActionTypes.MOVE_GROUP_LOCAL,
	{
		groupName: string;
		sourceIndex: number;
		destinationIndex: number;
		localChanges?: boolean;
		setName?: string;
	}
>;

export type MoveVariableSetAction = Action<
	ActionTypes.MOVE_VARIABLE_SET | ActionTypes.MOVE_VARIABLE_SET_LOCAL,
	{
		setName: string;
		sourceIndex: number;
		destinationIndex: number;
		localChanges?: boolean;
	}
>;

export type MoveVariableSetAggregationRuleAction = Action<
	| ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE
	| ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE_LOCAL,
	{
		setName: string;
		ruleName: string;
		sourceIndex: number;
		destinationIndex: number;
		localChanges?: boolean;
	}
>;

/**
 * ==========================================
 * 	ACTIONS: ADD/MOVE/REMOVE
 * 	SOURCE: VARIABLE(S)
 * 	TARGET: FROM/TO GROUP(S)
 * ==========================================
 */

export type AddVariableToGroupAction = Action<
	ActionTypes.ADD_VARIABLE_TO_GROUP | ActionTypes.ADD_VARIABLE_TO_GROUP_LOCAL,
	{
		variableName: string;
		groupName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		setName?: string;
		from?: RequireOnlyOne<{
			set?: {
				setName: string;
				parentGroup?: string;
			};
			mainList?: boolean;
		}>;
	}
>;

export type AddVariablesToGroupAction = Action<
	ActionTypes.ADD_VARIABLES_TO_GROUP | ActionTypes.ADD_VARIABLES_TO_GROUP_LOCAL,
	{
		variableNames: string[];
		groupName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		predictiveUpdates?: boolean;
		setName?: string;
	}
>;

export type RemoveVariableFromGroupAction = Action<
	ActionTypes.REMOVE_VARIABLE_FROM_GROUP | ActionTypes.REMOVE_VARIABLE_FROM_GROUP_LOCAL,
	{
		variableName: string;
		groupName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		setName?: string;
	}
>;

export type MoveVariableInsideGroupAction = Action<
	ActionTypes.MOVE_VARIABLE_INSIDE_GROUP | ActionTypes.MOVE_VARIABLE_INSIDE_GROUP_LOCAL,
	{
		variableName: string;
		sourceIndex: number;
		destinationIndex: number;
		groupName: string;
		localChanges?: boolean;
	}
>;

export type MoveVariableBetweenGroupsAction = Action<
	ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS | ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS_LOCAL,
	{
		variableName: string;
		sourceGroupName: string;
		destinationGroupName: string;
		destinationIndex?: number;
		localChanges?: boolean;
	}
>;

export type MoveVariablesBetweenGroupsAction = Action<
	ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS | ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS_LOCAL,
	{
		variableNames: string[];
		sourceGroupName: string;
		destinationGroupName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		predictiveUpdates?: boolean;
		setName?: string;
	}
>;

/**
 * ==========================================
 * 	ACTIONS: ADD/MOVE/REMOVE
 * 	SOURCE: VARIABLE(S) / GROUP(S)
 * 	TARGET: FROM/TO SET(S)
 * ==========================================
 */

export type AddVariableToSetAction = Action<
	ActionTypes.ADD_VARIABLE_TO_SET | ActionTypes.ADD_VARIABLE_TO_SET_LOCAL,
	{
		variableName: string;
		setName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		from?: RequireOnlyOne<{
			group?: {
				groupName: string;
			};
			set?: {
				setName: string;
				parentGroup?: string;
			};
		}>;
	}
>;

export type RemoveVariableFromSetAction = Action<
	ActionTypes.REMOVE_VARIABLE_FROM_SET | ActionTypes.REMOVE_VARIABLE_FROM_SET_LOCAL,
	{
		variableName: string;
		setName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		parentGroup?: string;
	}
>;

export type AddVariableGroupToSetAction = Action<
	ActionTypes.ADD_VARIABLE_GROUP_TO_SET | ActionTypes.ADD_VARIABLE_GROUP_TO_SET_LOCAL,
	{
		groupName: string;
		setName: string;
		destinationIndex?: number;
		localChanges?: boolean;
	}
>;

export type RemoveVariableGroupFromSetAction = Action<
	ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET | ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET_LOCAL,
	{
		groupName: string;
		setName: string;
		destinationIndex?: number;
		localChanges?: boolean;
	}
>;

export type MoveVariableBetweenSetsAction = Action<
	ActionTypes.MOVE_VARIABLE_BETWEEN_SETS | ActionTypes.MOVE_VARIABLE_BETWEEN_SETS_LOCAL,
	{
		variableName: string;
		sourceSetName: string;
		destinationSetName: string;
		destinationIndex?: number;
		localChanges?: boolean;
		parentGroup?: string;
	}
>;

export type MoveVariablesOrGroupsToSetAction = Action<
	ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET | ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET_LOCAL,
	{
		destinationSetName: string;
		localChanges?: boolean;
		data: {
			mainList: {
				variables: VariableLocation[];
				groups: GroupLocation[];
				hasData: boolean;
			};
			variableSets: {
				setName: string;
				variables: VariableLocation[];
				groups: GroupLocation[];
			}[];
		};
	}
>;

export type MoveVariableGroupBetweenSetsAction = Action<
	| ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS
	| ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS_LOCAL,
	{
		groupName: string;
		sourceSetName: string;
		destinationSetName: string;
		destinationIndex?: number;
		localChanges?: boolean;
	}
>;

export type MoveVariablesOrGroupsToRootListAction = Action<
	| ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST
	| ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST_LOCAL,
	{
		data: {
			mainList: {
				variables: VariableLocation[];
				groups: GroupLocation[];
				hasData: boolean;
			};
			variableSets: {
				setName: string;
				variables: VariableLocation[];
				groups: GroupLocation[];
			}[];
		};
		localChanges?: boolean;
		destinationIndex?: number;
	}
>;

/**
 * ================
 * 	BULK DELETE
 * ================
 */

export type DeleteBulkVariablesDataAction = Action<
	ActionTypes.DELETE_BULK_VARIABLES_DATA,
	{
		variables: VariableLocation[];
		groups: GroupLocation[];
		variableSets: {
			setName: string;
		}[];
	}
>;

/**
 * ===================
 * 	CRUD - CATEGORIES
 * ===================
 */

export type CreateVariableCategoryValueAction = Action<
	ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE,
	{
		variable: Variable;
	}
>;

export type CreateVariableCategoryValueLocalAction = Action<
	ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE_LOCAL,
	{
		variableName: string;
		categoryValue: VariableCategory;
	}
>;

export type CreateVariableCategoryValuesAction = Action<
	ActionTypes.CREATE_VARIABLE_CATEGORY_VALUES,
	{
		variable: Variable;
	}
>;

export type UpdateVariableCategoryValueAction = Action<
	ActionTypes.UPDATE_VARIABLE_CATEGORY_VALUE,
	{
		variable: Variable;
	}
>;

export type DeleteVariableCategoryValueAction = Action<
	ActionTypes.DELETE_VARIABLE_CATEGORY_VALUE,
	{
		variable: Variable;
	}
>;

export type DeleteVariableCategoryValuesAction = Action<
	ActionTypes.DELETE_VARIABLE_CATEGORY_VALUES,
	{
		variable: Variable;
	}
>;

export type MoveVariableCategoryValueAction = Action<
	ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE | ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE_LOCAL,
	{
		variableName: string;
		categoryValueId: string;
		sourceIndex: number;
		destinationIndex: number;
		localChanges?: boolean;
	}
>;

export type MoveVariableCategoryValuesAction = Action<
	ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES | ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES_LOCAL,
	{
		variableName: string;
		categoryValueIds: string[];
		destination:
			| {
					index: number;
			  }
			| {
					before: {
						id: string;
					};
			  }
			| {
					after: {
						id: string;
					};
			  };
		localChanges?: boolean;
		predictiveUpdates?: boolean;
		variable?: Variable;
	}
>;

export type MergeVariableCategoryValuesAction = Action<
	ActionTypes.MERGE_VARIABLE_CATEGORY_VALUES,
	{
		variable: Variable;
	}
>;

export type ActivateVariableCategoriesAction = Action<
	ActionTypes.ACTIVATE_VARIABLE_CATEGORIES,
	{
		variable: Variable;
	}
>;

export type DeactivateVariableCategoriesAction = Action<
	ActionTypes.DEACTIVATE_VARIABLE_CATEGORIES,
	{
		variable: Variable;
	}
>;

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export type RebuildVariablesDataCategoriesAction = Action<
	ActionTypes.REBUILD_VARIABLES_DATA_CATEGORIES,
	{
		entry: Entry;
	}
>;

export type SetVariableNameAction = Action<
	ActionTypes.SET_VARIABLE_NAME,
	{
		variableName: string | null;
	}
>;

export type SetDestinationSetNameAction = Action<
	ActionTypes.SET_DESTINATION_SET_NAME,
	{
		setName: string | null;
	}
>;

export type SetDestinationGroupNameAction = Action<
	ActionTypes.SET_DESTINATION_GROUP_NAME,
	{
		groupName: string | null;
	}
>;

export type SetVariablesFiltersAction = Action<
	ActionTypes.SET_VARIABLES_FILTERS,
	{
		filters: Partial<VariablesFilters>;
	}
>;

export type SetVariablesViewOptionAction = Action<
	ActionTypes.SET_VARIABLES_VIEW_OPTION,
	{
		viewOption: VariablesViewOptions;
	}
>;

export type SetVariablesSearchTermAction = Action<
	ActionTypes.SET_VARIABLES_SEARCH_TERM,
	{
		term: string;
	}
>;

export type SetDraggingVariableNameAction = Action<
	ActionTypes.SET_DRAGGING_VARIABLE_NAME,
	{
		name: string;
	}
>;

export type Actions =
	/**
	 * ===================
	 * 	CRUD - VARIABLES
	 * ===================
	 */
	| GetVariableAction
	| GetVariablesAction
	| CreateVariableAction
	| UpdateVariableAction
	| DeleteVariableAction
	/**
	 * ================
	 * 	CRUD - GROUPS
	 * ================
	 */
	| CreateGroupAction
	| UpdateGroupAction
	| DeleteGroupAction
	/**
	 * =======================
	 * 	CRUD - VARIABLE SETS
	 * =======================
	 */
	| CreateVariableSetAction
	| UpdateVariableSetAction
	| DeleteVariableSetAction
	/**
	 * ==========================
	 * 	CRUD - AGGREGATION RULES
	 * ==========================
	 */
	| CreateVariableSetAggregationRuleAction
	| UpdateVariableSetAggregationRuleAction
	| DeleteVariableSetAggregationRuleAction
	/**
	 * =========================================
	 * 	ACTIONS: MOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
	 * 	TARGET: ROOT LIST
	 * =========================================
	 */
	| MoveVariableAction
	| MoveGroupAction
	| MoveVariableSetAction
	| MoveVariableSetAggregationRuleAction
	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S)
	 * 	TARGET: FROM/TO GROUP(S)
	 * ==========================================
	 */
	| AddVariableToGroupAction
	| AddVariablesToGroupAction
	| RemoveVariableFromGroupAction
	| MoveVariableInsideGroupAction
	| MoveVariableBetweenGroupsAction
	| MoveVariablesBetweenGroupsAction
	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S)
	 * 	TARGET: FROM/TO SET(S)
	 * ==========================================
	 */
	| AddVariableToSetAction
	| RemoveVariableFromSetAction
	| AddVariableGroupToSetAction
	| RemoveVariableGroupFromSetAction
	| MoveVariableBetweenSetsAction
	| MoveVariablesOrGroupsToSetAction
	| MoveVariableGroupBetweenSetsAction
	| MoveVariablesOrGroupsToRootListAction
	/**
	 * ================
	 * 	BULK DELETE
	 * ================
	 */
	| DeleteBulkVariablesDataAction
	/**
	 * ===================
	 * 	CRUD - CATEGORIES
	 * ===================
	 */
	| CreateVariableCategoryValueAction
	| CreateVariableCategoryValueLocalAction
	| CreateVariableCategoryValuesAction
	| UpdateVariableCategoryValueAction
	| DeleteVariableCategoryValueAction
	| DeleteVariableCategoryValuesAction
	| MoveVariableCategoryValueAction
	| MoveVariableCategoryValuesAction
	| MergeVariableCategoryValuesAction
	| ActivateVariableCategoriesAction
	| DeactivateVariableCategoriesAction
	//////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////
	| RebuildVariablesDataCategoriesAction
	| SetVariableNameAction
	| SetDestinationSetNameAction
	| SetDestinationGroupNameAction
	//
	// FILTERS
	//
	| SetVariablesFiltersAction
	| SetVariablesViewOptionAction
	//
	| SetVariablesSearchTermAction
	| SetDraggingVariableNameAction;

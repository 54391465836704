import { getPermissions } from 'helpers/projects';
import { useSelector } from 'hooks/utils';
import { selectProjectPermissions, ActionTypes, selectProjectActions } from 'store/data/projects';
import { useActivities } from '../utils/useActivities';
import { useIsProjectOwner } from '../data/projects/useIsProjectOwner';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
	projectId?: string;
}

export function usePermissions(options?: Props) {
	const { enterpriseAdminAddCollaboratorFlag } = useFlags();

	const projectId = options?.projectId;

	const basePermissions = useSelector(state =>
		selectProjectPermissions(state.data.projects, projectId)
	);

	const projectActions = useSelector(state =>
		selectProjectActions(state.data.projects, projectId)
	);

	const isOwner = useIsProjectOwner(projectId ?? undefined);

	const [{ loading }] = useActivities([
		ActionTypes.GET_PROJECT,
		ActionTypes.GET_PROJECTS,
		ActionTypes.GET_PROMS
	]);
	const permissions = getPermissions(basePermissions);
	const permissionsWithActions = {
		...permissions,
		canAddCollaborator: enterpriseAdminAddCollaboratorFlag
			? projectActions?.canAddCollaborator
			: isOwner
	};

	return {
		...{
			...permissionsWithActions,
			hasOwnershipAllAccess: !!permissionsWithActions.hasOwnershipAllAccess && isOwner
		},
		loading,
		fetched: !!permissionsWithActions
	};
}

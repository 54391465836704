import { Flex } from 'components/UI/Flex';
import { ProjectNotificationSettingsView } from './ProjectNotificationSettingsView';

export function NotificationsSettings() {
	return (
		<Flex paddingOffset={{ x: 2.4, y: 1.6 }} column>
			<ProjectNotificationSettingsView />
		</Flex>
	);
}

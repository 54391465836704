import { useState, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';

import { ResizableCardDnDAutomatic } from 'components/Account';
// import {ResizableCardDnDAutomatic} from './ResizableCardDnDAutomatic';

import {
	DropToLeft,
	DropToRight,
	LineMarker,
	RelativeContainer,
	RowLineBottom,
	RowLineTop
} from '../../style';
import { WidgetHeightSize, WidgetWidthSize, HeightSizes } from 'store/data/projectDashboard';
import {
	SetSizePayload,
	CoordinatePayload,
	WidthSizes
} from 'components/Account/AdminDashboard/AdminDashboardAutomatic';

interface Props {
	cards: CardDraggableResizableProps[];
	index: number;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	totalCards: number;
	isResizing: boolean;
	group: {
		index: number;
		groupId: string;
	};
	setElementsOrder: (coordinates: CoordinatePayload) => void;
	setDimensions: (payload: SetSizePayload) => void;
	setIsResizing: (isResizing: boolean) => void;
	combineLayout: (coordinates: CoordinatePayload) => void;
	expandCardNewRow: (coordinates: CoordinatePayload) => void;
	removeCard: (index: number) => void;
}

enum DraggableItemType {
	Card = 'card'
}

export interface CardDraggableResizableProps extends CardDraggableItem {
	cardName: string;
	widthSize: WidgetWidthSize;
	heightSize: WidgetHeightSize;
}

export interface CardDraggableItem {
	index: number;
	cardId: string;
}

export function CardsSharedLayout({
	cards,
	widthSizes,
	heightSizes,
	index,
	totalCards,
	isResizing,
	group,
	setElementsOrder,
	setDimensions,
	setIsResizing,
	combineLayout,
	expandCardNewRow,
	removeCard
}: Props) {
	const elementValueRef = useRef<HTMLDivElement>(null);
	const mainRef = useRef<HTMLDivElement>(null);

	const invisRefRight = useRef<HTMLDivElement>(null);
	const invisRefLeft = useRef<HTMLDivElement>(null);

	const invisRefNewLineTop = useRef<HTMLDivElement>(null);
	const invisRefNewLineBottom = useRef<HTMLDivElement>(null);

	const [cardAutoResize, setCardAutoResize] = useState<string | null>(null);

	// DROP FOR LEFT
	const [{ leftHandlerId, isOverCurrentLeft }, dropLeft] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverLeft: monitor.isOver(),
			isOverCurrentLeft: monitor.isOver({ shallow: true }),
			leftHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { index: sourceIndex } = item;

			const destinationIndex = index;

			setElementsOrder({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group,
					droppedInMain: true
				}
			});
		}
	});

	// DROP FOR RIGHT
	const [{ rightHandlerId, isOverCurrentRight }, dropRight] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverRight: monitor.isOver(),
			isOverCurrentRight: monitor.isOver({ shallow: true }),
			rightHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { index: sourceIndex } = item;
			const destinationIndex = index < sourceIndex ? index + 1 : index;

			setElementsOrder({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group,
					droppedInMain: true
				}
			});
		}
	});

	const [{ newLineTopHandlerId, isOverCurrentNewLineTop }, dropNewLineTop] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentNewLineTop: monitor.isOver(),
			newLineTopHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			expandCardNewRow({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	const [{ newLineBottomHandlerId, isOverCurrentNewLineBottom }, dropNewLineBottom] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentNewLineBottom: monitor.isOver(),
			newLineBottomHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			expandCardNewRow({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	dropNewLineTop(invisRefNewLineTop);
	dropNewLineBottom(invisRefNewLineBottom);

	dropLeft(invisRefLeft);
	dropRight(invisRefRight);
	const commonChildren = (
		<>
			<DropToLeft ref={invisRefLeft} data-handler-id={leftHandlerId} splitLayout>
				<LineMarker isHovered={isOverCurrentLeft} isDraggingInMain={true} />
			</DropToLeft>
			<DropToRight
				ref={invisRefRight}
				data-handler-id={rightHandlerId}
				splitLayout
				isResizing={isResizing}
			>
				<LineMarker isHovered={isOverCurrentRight} isDraggingInMain={true} />
			</DropToRight>
		</>
	);

	const cardIds = Object.values(cards).map(element => element.cardId);

	const [_, setIsDraggingDelayed] = useState(true);

	useEffect(() => {
		return () => setIsDraggingDelayed(false);
	}, []);

	return (
		<div>
			<div ref={mainRef}>
				{index === 0 && (
					<RelativeContainer>
						<RowLineTop
							ref={invisRefNewLineTop}
							data-handler-id={newLineTopHandlerId}
							index={10000 + totalCards - index}
							isHovered={isOverCurrentNewLineTop}
						/>
					</RelativeContainer>
				)}
				<div ref={elementValueRef} style={{ position: 'relative' }}>
					{cards.map((card, rowIndex) => (
						<ResizableCardDnDAutomatic
							totalCards={totalCards}
							key={card.cardId}
							index={rowIndex}
							cardIds={cardIds}
							card={card}
							widthSizes={widthSizes}
							heightSizes={heightSizes}
							isResizing={isResizing}
							cardAutoResize={cardAutoResize}
							group={group}
							setElementsOrder={setElementsOrder}
							setDimensions={setDimensions}
							setIsResizing={setIsResizing}
							combineLayout={combineLayout}
							setFillLayoutVisible={() => undefined}
							setCardAutoResize={setCardAutoResize}
							expandCardNewRow={expandCardNewRow}
							removeCard={removeCard}
						/>
					))}
					{/* DRAG N DROP LINE INDICATOR */}
					{commonChildren}
				</div>
				<RelativeContainer>
					<RowLineBottom
						ref={invisRefNewLineBottom}
						data-handler-id={newLineBottomHandlerId}
						isHovered={isOverCurrentNewLineBottom}
						style={{ bottom: '-0.1rem' }}
					/>
				</RelativeContainer>
			</div>
		</div>
	);
}

import { forwardRef } from 'react';

import { Svgs } from 'environment';

import { Container, Icon } from './ChevronToggle.style';

interface Props {
	open: boolean;
	children: React.ReactNode;
	inactive?: boolean; // takes away the dropdown capabilities
	disabled?: boolean;
	onClick?: () => void;
}

export const ChevronToggle = forwardRef<HTMLDivElement, Props>(
	({ children, open, inactive, disabled, onClick }, ref) => (
		<Container onClick={onClick} disabled={disabled} inactive={inactive} ref={ref} open={open}>
			{children}
			{!inactive && (
				<Icon disabled={disabled} svg={Svgs.ChevronDown} open={open} onClick={onClick} />
			)}
		</Container>
	)
);

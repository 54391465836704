import React, { useRef, useState } from 'react';
import { Svgs } from 'environment';
import { SetState } from 'types/index';
import {
	BodyWrapper,
	Container,
	Description,
	FooterWrapper,
	HeaderWrapper,
	MoreIconContainer,
	Title,
	TitleWrapper
} from './Card.style';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icons';
import { showOnTouchDevices } from 'helpers/generic';
import { useOutsideClick } from 'hooks/utils';

interface MenuItem {
	label: string;
	action: () => void;
}

interface Props {
	children: React.ReactNode;
	onClick: () => void;
}

export function Card({ onClick, children }: Props) {
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const elementValueRef = useRef<HTMLDivElement>(null);

	useOutsideClick(() => setIsMenuVisible(false), [elementValueRef]);

	const enhancedChildren = React.Children.toArray(children)
		.filter(child => child)
		.map((child, _) =>
			React.cloneElement(child as JSX.Element, {
				menuState: { visible: isMenuVisible, setVisible: setIsMenuVisible }
			})
		);

	return (
		<Container
			ref={elementValueRef}
			menuVisible={isMenuVisible}
			onClick={onClick}
			data-testid="card-container"
		>
			{enhancedChildren}
		</Container>
	);
}

interface HeaderProps<T> {
	title: string;
	data: T;
	menuState?: {
		visible: boolean;
		setVisible: SetState<boolean>;
	};
	showCardMenu?: boolean;
	menuItems: MenuItem[];
	renderTitleIcon?: (visible: boolean) => React.ReactNode;
	renderTitleHint?: (data: T) => React.ReactNode;
}

function Header<T>({
	title,
	data,
	menuState,
	menuItems,
	showCardMenu = true,
	renderTitleIcon,
	renderTitleHint
}: HeaderProps<T>) {
	return (
		<HeaderWrapper>
			<TitleWrapper>
				{/* Card Icon */}
				{renderTitleIcon?.(!!menuState?.visible)}

				{/* Card Hint */}
				{renderTitleHint?.(data)}

				{/* Card Title */}
				<Title>{title}</Title>
			</TitleWrapper>

			{/* Card Menu */}
			{showCardMenu && (
				<MoreIconContainer
					visible={!!menuState?.visible}
					className={showOnTouchDevices('card-more-icon')}
				>
					<Dropdown
						toggleComponent={({ ref, toggle }) => (
							<Icon
								dataTestId="card_menu_icon"
								ref={ref}
								variant={v => v.button}
								svg={Svgs.More}
								onClick={() => {
									menuState?.setVisible(state => !state);
									toggle();
								}}
								active={!!menuState?.visible}
							/>
						)}
						width={18}
						offset={{ top: 20, right: 20 }}
					>
						{menuItems.map(({ label, action }) => (
							<Dropdown.Item
								key={label}
								onClick={action}
								dataTestId={`dropdown_item_${label}`}
							>
								{label}
							</Dropdown.Item>
						))}
					</Dropdown>
				</MoreIconContainer>
			)}
		</HeaderWrapper>
	);
}

type ChildrenProps = {
	children: React.ReactNode;
};

function Body({ children }: ChildrenProps) {
	return (
		<BodyWrapper>
			<Description>{children}</Description>
		</BodyWrapper>
	);
}

function Footer({ children }: ChildrenProps) {
	return <FooterWrapper>{children}</FooterWrapper>;
}

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

import styled, { keyframes } from 'styled-components';

import { Colors } from 'environment';
import { transparentHex } from 'helpers/cssGenerators';

export const PlaceholderCardWrapper = styled.div`
	padding: 1.6rem 2.4rem;
	position: relative;
	display: flex;
	border-bottom: 0.1rem solid ${transparentHex(Colors.text.caption, 0.1)};
`;

const shineAnimation = keyframes`
	0% { 
	  opacity: 1;
	}
	50% {
	  opacity: 0.45;
	}
	100% {
	  opacity: 1;
	}
`;

export const PlaceholderAvatar = styled.div`
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	background-color: ${transparentHex(Colors.text.disabled, 0.6)};
	width: 2.4rem;
	height: 2.4rem;
	min-width: 2.4rem;
	animation: ${shineAnimation} 2.4s infinite;
`;

export const PlaceholderText = styled.div`
	width: 100%;
	height: 1.1rem;
	border-radius: 1rem;
	background-color: ${transparentHex(Colors.text.disabled, 0.6)};
	margin-top: 0.2rem;
	margin-bottom: 0.6rem;
	animation: ${shineAnimation} 2.4s infinite;
`;

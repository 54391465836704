import { useState, useEffect } from 'react';

import { useSelector } from 'hooks/utils';
import { Operation } from 'hooks/store/types';
import { ActionTypes } from 'store/ui/activities';
import { selectActivity, selectError } from 'store/ui/activities';

export function useTransactionActivity(type: string): Operation {
	const [localError, setLocalError] = useState(false);

	const loading = useSelector(state =>
		selectActivity(state.ui.activities, {
			type: ActionTypes.DO_AFTER_TRANSACTION,
			payload: type
		})
	);
	const errored = useSelector(state =>
		selectError(state.ui.activities, {
			type: ActionTypes.DO_AFTER_TRANSACTION,
			payload: type
		})
	);

	useEffect(() => {
		if (errored) setLocalError(true);
	}, [errored]);

	function reset() {
		setLocalError(false);
	}

	return [{ loading, error: localError }, reset];
}

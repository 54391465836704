import { useEffect, useMemo, useRef, useState } from 'react';
import { nanoid as generate } from 'nanoid';
import { EntryFilter as FilterType, Operator } from 'api/data/filters';
import { VariableType } from 'types/data/variables/constants';
import { STATUS_COLUMN } from 'consts';
import { FilterIcon } from 'components/UI/Icons';
import { VariablesData } from 'store/data/variables';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { Offset } from 'types/index';
import { Filter } from 'components/Analysis/Filters/FilterList/Filter';
import { Container, Floating } from './ColumnFilter.style';
import { getDefaultOperator } from 'helpers/filters';
import { getPositionWithinBounds } from 'helpers/generic';
import { useUiStates } from 'hooks/ui';
import { useFilters } from 'hooks/store';

import { useSeriesFilters } from 'hooks/store/data/useSeriesFilters/useSeriesFilters';

const PADDING = 40;
const DEFAULT_OFFSET = 20;

function computeLeftOffset(position: Offset | null) {
	let left = -DEFAULT_OFFSET;

	if (position && position.left) {
		left = -(position.left + DEFAULT_OFFSET + PADDING);
	}

	return left;
}

interface Props {
	tableRef: React.RefObject<HTMLTableElement>;
	columnName: string;
	computePosition: boolean;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	isSeriesFilter?: boolean;
	filterDrawerContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export function ColumnFilter({
	tableRef,
	columnName,
	computePosition,
	variablesData,
	variablesDataSelectItems,
	isSeriesFilter,
	filterDrawerContainerRef = undefined
}: Props) {
	const [visible, setVisible] = useState(false);
	const [seriesVisible, setSeriesVisible] = useState(false);
	const [position, setPosition] = useState<Offset | null>(null);
	const [inView, setInview] = useState(true);
	const [computingPosition, setComputingPosition] = useState(true);

	const [{ focusState: focus }] = useUiStates();

	const isInputFocused = useRef(focus);

	const filterRef = useRef<HTMLDivElement>(null);
	const iconRef = useRef<HTMLImageElement>(null);

	// DELETE CREATE FILTER
	const [{ filters }, { createFilter, deleteFilters }] = useFilters();

	// DELETE CREATE SERIES FILTER
	const [{ seriesFilters }, { createSeriesFilter, deleteSeriesFilters }] = useSeriesFilters();

	const columnFilters = useMemo(
		() => filters.filter(filter => filter.columnName === columnName),
		[filters]
	);

	const columnSeriesFilters = useMemo(
		() => seriesFilters.filter(filter => filter.columnName === columnName),
		[seriesFilters]
	);

	// Hide the filters when the last one is deleted
	useEffect(() => {
		if (!columnFilters.length && visible) setVisible(false);
	}, [columnFilters]);

	// Hide the Series filters when the last one is deleted
	useEffect(() => {
		if (!columnSeriesFilters.length && seriesVisible) setSeriesVisible(false);
	}, [columnSeriesFilters]);

	// Delete invalid filters when hiding
	useEffect(() => {
		const invalidFilterIds = columnFilters
			.filter(filter => filter.invalid)
			.map(filter => filter.itemId);

		if (!visible && invalidFilterIds.length) {
			deleteFilters(invalidFilterIds);
		}
	}, [visible]);

	// Delete invalid Series filters when hiding
	useEffect(() => {
		const invalidSeriesFilterIds = columnSeriesFilters
			.filter(filter => filter.invalid)
			.map(filter => filter.itemId);

		if (!seriesVisible && invalidSeriesFilterIds.length)
			deleteSeriesFilters(invalidSeriesFilterIds);
	}, [seriesVisible]);

	// Compute the needed offset to display a filter
	// within screen bounds
	useEffect(() => {
		if (computingPosition) {
			const bounds = getPositionWithinBounds(filterRef, tableRef);

			if (bounds) setPosition(bounds);

			setComputingPosition(false);
		}
	}, [computingPosition]);

	// isInputFocused is used in the onOutsideClick event handler
	// that reads the props from the closure where it is defined.
	// Manually updating a ref is the preferred solution here.
	useEffect(() => {
		isInputFocused.current = focus;
	}, [focus]);

	function showFilter() {
		setVisible(true);
		setSeriesVisible(true);
	}

	function hideFilter() {
		if (!isInputFocused.current) {
			setVisible(false);
			setSeriesVisible(false);
		}
	}

	function onIconClick(e: React.MouseEvent) {
		setInview(e.clientX < window.innerWidth - 390);

		if (!visible) {
			if (computePosition && !position) {
				setComputingPosition(true);
			}

			if (!columnFilters.length) {
				let aggregationVariableType: VariableType | null = null;
				const isAggregationFilter = Object.values(variablesData.variableSetsMap).some(set =>
					set.aggregationRules.some(rule => {
						if (rule.name === columnName) {
							aggregationVariableType =
								variablesData.variablesMap[rule.aggregator.variableName].type;
							return true;
						}
						return false;
					})
				);

				if (isAggregationFilter && aggregationVariableType) {
					const filter: FilterType = {
						itemId: generate(),
						columnName,
						filterType: aggregationVariableType,
						operator: getDefaultOperator(aggregationVariableType),
						invalid: true
					};

					createFilter({ filter });
				} else if (columnName === STATUS_COLUMN.name) {
					const filter: FilterType = {
						itemId: generate(),
						columnName: STATUS_COLUMN.name,
						filterType: VariableType.Status,
						operator: Operator.In,
						invalid: true
					};

					createFilter({ filter });
				} else {
					const variable = variablesData.variablesMap[columnName];

					if (!variable) return;

					/*
					 *  Currently BE does not support "userDefinedUnique" filterType, for now all unique variables must be treated as strings
					 */
					const getVariableType = () => {
						if (variable.type === VariableType.Unique) {
							return VariableType.Unique;
						}
						if (variable.type === VariableType.TimeDuration) {
							return VariableType.Integer;
						}
						return variable.type;
					};

					const filter: FilterType = {
						itemId: generate(),
						columnName: variable.name, //variable
						filterType: getVariableType(),
						filterSubType: variable.uniquenessType,
						operator: getDefaultOperator(variable.type),
						invalid: true
					};

					createFilter({ filter });
				}
			}

			showFilter();
		} else {
			hideFilter();
		}
	}

	//ON SERIES FILTER CLICK
	function onIconSeriesFilterClick(e: React.MouseEvent) {
		setInview(e.clientX < window.innerWidth - 390);

		if (!seriesVisible) {
			if (computePosition && !position) {
				setComputingPosition(true);
			}

			if (!columnSeriesFilters.length) {
				if (columnName === STATUS_COLUMN.name) {
					const seriesFilter: FilterType = {
						itemId: generate(),
						columnName: STATUS_COLUMN.name,
						filterType: VariableType.Status,
						operator: Operator.In,
						invalid: true
					};

					createSeriesFilter({ seriesFilter });
				} else {
					const variable = variablesData.variablesMap[columnName];

					if (!variable) return;

					const seriesFilter: FilterType = {
						itemId: generate(),
						columnName: variable.name, //variable
						filterType: variable.type,
						filterSubType: variable.uniquenessType,
						operator: getDefaultOperator(variable.type),
						invalid: true
					};

					createSeriesFilter({ seriesFilter });
				}
			}

			showFilter();
		} else {
			hideFilter();
		}
	}

	const left = useMemo(() => computeLeftOffset(position), [position]);

	const filterActive = columnFilters.length > 0;
	const seriesFilterActive = columnSeriesFilters.length > 0;

	const isFilterActive = isSeriesFilter ? seriesFilterActive : filterActive;

	const filterContainerRef = useRef<HTMLDivElement>(null);

	return (
		<Container ref={filterContainerRef}>
			<FilterIcon
				className="column-filter-icon"
				ref={iconRef}
				active={isFilterActive}
				onClick={isSeriesFilter ? onIconSeriesFilterClick : onIconClick}
				style={
					isFilterActive
						? {
								visibility: 'visible'
						  }
						: undefined
				}
			/>

			{isFilterActive && (visible || computingPosition || seriesVisible) && (
				<Floating
					ref={filterRef}
					style={{
						left: inView ? left : -307,
						opacity: computingPosition ? 0 : 1
					}}
				>
					{isSeriesFilter
						? columnSeriesFilters.map(seriesFilter => (
								<Filter
									tableFilter
									filterDrawerContainerRef={filterDrawerContainerRef}
									filterContainerRef={filterContainerRef}
									key={`entries-filter-${seriesFilter.itemId}`}
									filter={seriesFilter}
									hideFilter={hideFilter}
									variablesData={variablesData}
									variablesDataSelectItems={variablesDataSelectItems}
									isSeriesFilter
								/>
						  ))
						: columnFilters.map(filter => (
								<Filter
									tableFilter
									filterDrawerContainerRef={filterDrawerContainerRef}
									filterContainerRef={filterContainerRef}
									hideFilter={hideFilter}
									key={`entries-filter-${filter.itemId}`}
									filter={filter}
									variablesData={variablesData}
									variablesDataSelectItems={variablesDataSelectItems}
								/>
						  ))}
				</Floating>
			)}
		</Container>
	);
}

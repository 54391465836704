import { useActivity, useShowAddons } from 'hooks/store';
import { OperationResult } from 'hooks/store/types';
import { useSelector } from 'hooks/utils';
import { ActionTypes, selectUserAddons } from 'store/account/addons';
import { ActionTypes as AccountActionTypes } from 'store/account/subscription';
import { SubscriptionAddonCode } from 'types/index';

export function useIsUserAddonActive(
	subscriptionAddonCode: SubscriptionAddonCode
): OperationResult<boolean, null> {
	const { activeUserAddons } = useSelector(state => selectUserAddons(state.account.addons));

	const { data: showAddons } = useShowAddons();

	const fetched = activeUserAddons !== null;

	//Currently we don't use a separate handler for this because this info is taken from the getUser call
	// which is called only once when logging in
	const [{ loading: fetchingAccount, error: errorFetchingAccount }] = useActivity(
		AccountActionTypes.GET_ACCOUNT
	);
	const [{ loading: fetchingAddons, error: errorFetchingAddons }] = useActivity(
		ActionTypes.GET_USER_ADDONS
	);

	const addonIsActive =
		showAddons && activeUserAddons !== null && activeUserAddons.includes(subscriptionAddonCode);

	return [
		{
			loading: fetchingAccount || fetchingAddons,
			error: errorFetchingAccount || errorFetchingAddons,
			fetched,
			data: addonIsActive
		},
		null
	];
}

import styled, { css } from 'styled-components';
import { DrawerHistoryType } from 'types/index';

export const OlderRevisions = styled.div`
	height: 4rem;
	display: flex;
	align-items: center;
	cursor: pointer;
`;

interface Props {
	drawerType?: string;
}

export const RecentRevisions = styled.div<Props>`
	padding-left: 2.2rem;
	height: 4rem;
	display: flex;
	align-items: center;

	${({ drawerType }) => {
		if (drawerType === DrawerHistoryType.Mobile) {
			return css`
				margin-top: 2.4rem;
			`;
		}
	}}
`;

import styled from 'styled-components';

import { Colors } from 'environment';
import { getTypographyCSS } from 'components/UI/Typography';

export const Container = styled.div`
	.react-tooltip {
		/* resetters */
		border: unset;

		&.show {
			opacity: 1;
		}

		padding: 0.4rem 0.8rem;
		border-radius: 0.4rem;
		background-color ${Colors.text.main};
		max-width: 32rem;
		width: max-content;
		transition: opacity 0.15s ease-out;
		
		${getTypographyCSS(t => t.Tooltip)}

		&.__react_component_tooltip.place-right::before {
			display: none;
		}

		&.__react_component_tooltip.place-right::after {
			left: -6px;
		}

		/* Place icon and black bar under the text */
		&.__react_component_tooltip.place-left::before,
		&.__react_component_tooltip.place-top::before,
		&.__react_component_tooltip.place-right::before,
		&.__react_component_tooltip.place-bottom::before {
			z-index: -1;
		}

		&.__react_component_tooltip.place-left::after,
		&.__react_component_tooltip.place-top::after,
		&.__react_component_tooltip.place-right::after,
		&.__react_component_tooltip.place-bottom::after {
			z-index: -1;
		}
		
	}
`;

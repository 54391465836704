import { UserDetails } from 'api/data/accounts';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { getUsersByIds, selectAllUsersById } from 'store/data/accounts';
import { GenericMap } from 'types/index';

export function useUsersByIds(): LocalOperationResult<
	GenericMap<UserDetails>,
	(ids: string[]) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAllUsersById(state.data.accounts));

	function handler(ids: string[]) {
		dispatch(getUsersByIds(ids));
	}

	return [data, handler];
}

import { type DependencyFilter, type EntryFilter, Operator } from 'api/data/filters';
import type { Action } from 'types/store/types';
import type { GenericMap, StringArrayMap } from 'types/index';
import { VariableUniquenessType } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';

export interface State {
	dataset: {
		projectId: string | null;
		byId: FiltersById;
		byProjectId: FiltersByProjectId;
		bySnapshotId: FiltersBySnapshotId;
		byVariableName: FiltersByVariableName;
		open?: boolean;
	};
	series: {
		projectId: string | null;
		byId: FiltersById;
		byProjectId: ByProjectIdSeriesType;
		bySnapshotId: FiltersBySnapshotId;
		byVariableName: FiltersByVariableName;
		open?: boolean;
		bySeriesName?: string;
	};
	dependencies: {
		projectId: string | null;
		byId: FiltersById;
		byProjectId: FiltersByProjectId;
		bySnapshotId: FiltersBySnapshotId;
		byVariableName: FiltersByVariableName;
		open?: boolean;
	};
}

export type FiltersById = GenericMap<DependencyFilter>;

export type FiltersByProjectId = GenericMap<{
	active: string[];
	invalid: string[];
}>;

type FiltersBySnapshotId = StringArrayMap;

export type FiltersByVariableName = StringArrayMap;

export enum ActionTypes {
	CREATE_FILTER = 'data/filters/CREATE_FILTER',
	UPDATE_FILTER = 'data/filters/UPDATE_FILTER',
	DELETE_DATASET_FILTER = 'data/filters/DELETE_DATASET_FILTER',
	DELETE_FILTERS = 'data/filters/DELETE_FILTERS',
	INVALIDATE_FILTER = 'data/filters/INVALIDATE_FILTER',
	CLEAR_FILTERS = 'data/filters/CLEAR_FILTERS',
	REBUILD_FILTERS = 'data/filters/REBUILD_FILTERS',
	TOGGLE_OPEN_FILTERS = 'data/filters/TOGGLE_OPEN_FILTERS',
	// DEPENDENCIES
	CREATE_DEPENDENCIES_FILTER = 'data/dependenciesFilters/CREATE_DEPENDENCIES_FILTER',
	UPDATE_DEPENDENCIES_FILTER = 'data/dependenciesFilter/UPDATE_DEPENDENCIES_FILTER',
	DELETE_DEPENDENCIES_FILTER = 'data/dependenciesFilters/DELETE_DEPENDENCIES_FILTER',
	DELETE_DEPENDENCIES_FILTERS = 'data/dependenciesFilters/DELETE_DEPENDENCIES_FILTERS',
	INVALIDATE_DEPENDENCIES_FILTER = 'data/dependenciesFilter/INVALIDATE_DEPENDENCIES_FILTER',

	//SERIES
	CREATE_SERIES_FILTER = 'data/seriesFilters/CREATE_SERIES_FILTER',
	UPDATE_SERIES_NAME = 'data/seriesFilters/UPDATE_SERIES_NAME',
	UPDATE_SERIES_FILTER = 'data/seriesFilters/UPDATE_SERIES_FILTER',
	DELETE_SERIES_FILTER = 'data/seriesFilters/DELETE_SERIES_FILTER',
	DELETE_SERIES_FILTERS = 'data/seriesFilters/DELETE_SERIES_FILTERS',
	INVALIDATE_SERIES_FILTER = 'data/seriesFilters/INVALIDATE_SERIES_FILTER'
}

export type CreateFilterAction = Action<
	ActionTypes.CREATE_FILTER,
	{
		filter: EntryFilter;
	}
>;

export type UpdateFilterAction = Action<
	ActionTypes.UPDATE_FILTER,
	{
		filter: EntryFilter;
	}
>;

export type DeleteFilterAction = Action<
	ActionTypes.DELETE_DATASET_FILTER,
	{
		filterId: string;
		snapshotId: string | null;
	}
>;

export type DeleteFiltersAction = Action<
	ActionTypes.DELETE_FILTERS,
	{
		filterIds: string[];
		snapshotId: string | null;
	}
>;

export type InvalidateFilterAction = Action<
	ActionTypes.INVALIDATE_FILTER,
	{
		filterId: string;
	}
>;

export type ClearFiltersAction = Action<ActionTypes.CLEAR_FILTERS>;

export type RebuildFiltersAction = Action<
	ActionTypes.REBUILD_FILTERS,
	{
		active: string[];
		byId: FiltersById;
	}
>;

export type ToggleOpenFiltersAction = Action<ActionTypes.TOGGLE_OPEN_FILTERS>;

//DEPENDENCIES
export type CreateDependenciesFilterAction = Action<
	ActionTypes.CREATE_DEPENDENCIES_FILTER,
	{
		dependenciesFilter: DependencyFilter;
	}
>;

export type DeleteDependenciesFilterAction = Action<
	ActionTypes.DELETE_DEPENDENCIES_FILTER,
	{
		filterId: string;
		snapshotId: string | null;
	}
>;

export type DeleteDependenciesFiltersAction = Action<
	ActionTypes.DELETE_DEPENDENCIES_FILTERS,
	{
		filterIds: string[];
		snapshotId: string | null;
	}
>;

export type UpdateDependenciesFilterAction = Action<
	ActionTypes.UPDATE_DEPENDENCIES_FILTER,
	{
		dependenciesFilter: DependencyFilter;
	}
>;

export type InvalidateDependenciesFilterAction = Action<
	ActionTypes.INVALIDATE_DEPENDENCIES_FILTER,
	{
		filterId: string;
	}
>;

//SERIES

type ByProjectIdSeriesType = {
	[byProjectId: string]: BySeriesNameType;
};

type BySeriesNameType = {
	[bySeriesName: string]: FiltersByProjectIdSeriesType;
};

export type FiltersByProjectIdSeriesType = {
	active: string[];
	invalid: string[];
};
export interface FilterSeries {
	itemId: string;
	columnName: string;
	operator: Operator;
	filterType: VariableType;
	filterSubType?: VariableUniquenessType;
	from?: number | string;
	to?: number | string;
	value?: number | string;
	values?: string[];
	invalid?: boolean;
}

export type CreateSeriesFilterAction = Action<
	ActionTypes.CREATE_SERIES_FILTER,
	{
		seriesFilter: EntryFilter;
	}
>;

export type UpdateSeriesName = Action<
	ActionTypes.UPDATE_SERIES_NAME,
	{
		seriesName: string;
	}
>;

export type UpdateSeriesFilterAction = Action<
	ActionTypes.UPDATE_SERIES_FILTER,
	{
		seriesFilter: EntryFilter;
	}
>;

export type DeleteSeriesFilterAction = Action<
	ActionTypes.DELETE_SERIES_FILTER,
	{
		filterId: string;
		snapshotId: string | null;
	}
>;

export type DeleteSeriesFiltersAction = Action<
	ActionTypes.DELETE_SERIES_FILTERS,
	{
		filterIds: string[];
		snapshotId: string | null;
	}
>;

export type InvalidateSeriesFilterAction = Action<
	ActionTypes.INVALIDATE_SERIES_FILTER,
	{
		filterId: string;
	}
>;

export type Actions =
	| CreateFilterAction
	| UpdateFilterAction
	| DeleteFilterAction
	| DeleteFiltersAction
	| InvalidateFilterAction
	| ClearFiltersAction
	| RebuildFiltersAction
	| ToggleOpenFiltersAction

	//DEPENDENCIES
	| CreateDependenciesFilterAction
	| UpdateDependenciesFilterAction
	| DeleteDependenciesFilterAction
	| DeleteDependenciesFiltersAction
	| InvalidateDependenciesFilterAction

	//SERIES
	| CreateSeriesFilterAction
	| UpdateSeriesFilterAction
	| DeleteSeriesFilterAction
	| DeleteSeriesFiltersAction
	| InvalidateSeriesFilterAction
	| UpdateSeriesName;

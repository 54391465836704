import { useDispatch } from 'hooks/utils';
import { Operation } from 'hooks/store/types';
import { toggleFormById, ActionTypes, toggleActiveForm } from 'store/data/forms';
import { useActivity } from 'hooks/store';

export function useToggleFormActive(formId?: string): Operation<(formId?: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.TOGGLE_ACTIVE_FORM, formId);

	function handler(formId?: string) {
		if (formId) dispatch(toggleFormById(formId));
		else dispatch(toggleActiveForm());
	}

	return [{ loading, error }, handler];
}

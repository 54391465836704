import { Typography } from '../Typography';
import { Bullet, Flex } from './BulletList.style';

interface Props {
	elements: string[] | React.ReactElement[];
}

export function BulletList({ elements }: Props) {
	return (
		<>
			{elements.map((element, index) => (
				<Flex
					marginOffset={{ bottom: 0.8 }}
					align={align => align.center}
					key={`bullet-point-${index}`}
				>
					<Bullet />
					{typeof element === 'string' ? (
						<Typography.Paragraph>{element}</Typography.Paragraph>
					) : (
						element
					)}
				</Flex>
			))}
		</>
	);
}

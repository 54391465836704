import { createSelector } from 'reselect';

import { DEFAULT_DATE_TIME_FORMAT } from 'consts';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getIsAccountFetched(state: State) {
	return !!state.accountDetails;
}

function getCurrentUserEmail({ accountDetails }: State) {
	return accountDetails ? accountDetails.emailAddress : '';
}

function getLicenceOwnerFullName(state: State) {
	return state.subscriptionDetails
		? state.subscriptionDetails.ownerFirstName + ' ' + state.subscriptionDetails.ownerLastName
		: '';
}

function getSubscriptionUsers(state: State) {
	return state.subscriptionUsers.byId;
}

function getUserPreferences(state: State) {
	return state.accountDetails ? state.accountDetails.userPreferences : null;
}

function getSubscriptionDetails(state: State) {
	return state.subscriptionDetails;
}

function getOrganizations(state: State) {
	return state.organizations;
}

function getUserDateTimeFormat(state: State) {
	return state.accountDetails?.userPreferences.dateTimeFormat;
}

function getUserById({ subscriptionUsers: { byId } }: State, userId: string) {
	return byId[userId];
}

function hasProjectMetadataDefinition(state: State) {
	return state.hasMetadataDefinition;
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectCurrentUserEmail = createSelector(
	[getCurrentUserEmail],
	emailAddress => emailAddress
);

export const selectIsAccountFetched = createSelector(
	[getIsAccountFetched],
	myAccountLoaded => myAccountLoaded
);

export const selectLicenceOwnerFullName = createSelector(
	[getLicenceOwnerFullName],
	ownerFullName => ownerFullName
);

export const selectUserPreferences = createSelector(
	[getUserPreferences],
	userPreferences => userPreferences
);

export const selectSubscriptionUsers = createSelector(
	[getSubscriptionUsers],
	usersList => usersList
);

export const selectSubscriptionDetails = createSelector(
	[getSubscriptionDetails],
	subscription => subscription
);

export const selectOrganizations = createSelector(
	[getOrganizations],
	organizations => organizations
);

export const selectUserDateTimeFormat = createSelector(
	[getUserDateTimeFormat],
	dateTimeFormat => dateTimeFormat ?? DEFAULT_DATE_TIME_FORMAT
);

export const selectHasProjectMetadataDefinition = createSelector(
	[hasProjectMetadataDefinition],
	hasMetadata => hasMetadata
);

export const selectSubscriptionUserById = createSelector([getUserById], userById => userById);

import { useState } from 'react';
import { NewGroup } from 'api/data/variables';
import { InputType } from 'types/index';
import { MergeVariablesIntoGroupInput } from 'store/data/variables';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import {
	useTranslation,
	useVariables,
	useDestinationSetName,
	useDestinationGroupName,
	useCreateGroup
} from 'hooks/store';
import { useCompletedAction, useEffectOnce } from 'hooks/utils';

interface Props {
	variablesToBeMerged?: MergeVariablesIntoGroupInput | null;
	onClose: () => void;
}

export function CreateVariableGroupModal({ variablesToBeMerged, onClose }: Props) {
	const { translate } = useTranslation();

	const [newGroupLabel, setNewGroupLabel] = useState('');

	const [
		{
			data: { groups }
		}
	] = useVariables({ initial: true, lazy: true });

	const [destinationSetName, setDestinationSetName] = useDestinationSetName();
	const [destinationGroupName, setDestinationGroupName] = useDestinationGroupName();

	const [{ loading: creatingGroup, error: errorCreatingGroup }, createGroup] = useCreateGroup();

	const newGroupLabelTrimmed = newGroupLabel.trim();
	const isGroupLabelValid = newGroupLabelTrimmed.length > 0;
	const isGroupLabelUnique = !groups.some(group => group.groupLabel === newGroupLabelTrimmed);

	const canCreateGroup = isGroupLabelValid && isGroupLabelUnique;

	// CLOSE MODAL AFTER GROUP CREATION
	useCompletedAction(creatingGroup, errorCreatingGroup, onClose);

	useEffectOnce(() => {
		return () => {
			if (destinationSetName !== null) setDestinationSetName(null);
			if (destinationGroupName !== null) setDestinationGroupName(null);
		};
	});

	function handleCreateGroup() {
		if (creatingGroup || !canCreateGroup) return;

		const group: NewGroup = {
			groupLabel: newGroupLabelTrimmed
		};

		if (variablesToBeMerged) {
			const { targetVariable, draggedVariable, setName, from } = variablesToBeMerged;

			createGroup(group, {
				variableNames: [targetVariable.name, draggedVariable.name],
				destinationIndex:
					/**
					 * NOTICE:
					 *
					 * DECREMENT `destinationIndex` BY 1 IF THE DRAGGED VARIABLE WAS BEFORE THE TARGET VARIABLE
					 * IN ORDER TO PLACE THE NEWLY CREATED GROUP IN THE CORRECT POSITION AND NOT CREATE IT 1 INDEX AHEAD
					 */
					draggedVariable.index < targetVariable.index
						? targetVariable.index - 1
						: targetVariable.index,
				setName,
				from
			});
		} else {
			createGroup(group);
		}
	}

	function trimFields() {
		setNewGroupLabel(state => state.trim());
	}

	const primaryButtonDisabled = !canCreateGroup;

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.variablesPage.createVariableGroupModal.title)}
			primary={{
				label: translate(({ buttons }) => buttons.create),
				loading: creatingGroup,
				disabled: primaryButtonDisabled,
				onClick: handleCreateGroup
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Input
				type={InputType.Text}
				value={newGroupLabel}
				placeholder={translate(
					dict => dict.variablesPage.createVariableGroupModal.placeholder
				)}
				error={
					!isGroupLabelUnique ? translate(dict => dict.terms.errorlabelUnique) : undefined
				}
				onChange={e => setNewGroupLabel(e.target.value)}
				onSubmit={handleCreateGroup}
				onBlur={trimFields}
				autoFocus
			/>
		</Modal>
	);
}

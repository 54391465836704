import { useEffect, useMemo, useRef, useState } from 'react';
import { nanoid as generate } from 'nanoid';
import { DragAndDrop } from 'components/UI/DragAndDrop';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { ENTRY_FILE_SIZE_LIMIT } from 'consts';
import { Svgs } from 'environment';
import { resetCsvTable } from 'store/data/documents';
import {
	ActionTypes,
	addLocalEntryFileAction,
	deleteLocalEntryFileAction,
	LocalEntryFile,
	StoredEntryFile
} from 'store/data/entries';
import { ACCEPTED_ENTRY_FILE_TYPES, ACCEPTED_PREVIEWABLE_TYPES } from 'types/index';
import { OnFileDropOptions, ReaderType } from 'hooks/store/ui/useImportDropzone';
import { PreviewFileModal } from 'pages/Documents/Modals/PreviewModal/PreviewFileModal';
import { InputError } from '../InputError';
import { InputLabel } from '../InputLabel';
import { Container, Row } from './EntryFileInput.style';
import { Flex } from 'components/UI/Flex';
import { downloadFileFromUrl } from 'helpers/generic';
import { withMemo } from 'helpers/HOCs';
import { useDispatch, usePrevious } from 'hooks/utils';
import { useTranslation, useEntryFiles, useEntryFile, useActivity } from 'hooks/store';

interface EntryFileInputProps {
	value: string;
	name?: string;
	label?: string;
	required?: boolean;
	error?: string;
	disabled?: boolean;
	readOnly?: boolean;
	tooltipComponent?: React.ReactNode;
	onBlur?: () => void;
	onValueChange?: (value: string) => void;
}

function Component({
	value,
	name,
	label,
	required,
	error,
	readOnly,
	disabled = false,
	tooltipComponent,
	onBlur,
	onValueChange
}: EntryFileInputProps) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [isPreviewWindowOpen, setIsPreviewWindowOpen] = useState(false);

	const [{ loading: filesLoading, error: filesError }] = useEntryFiles({
		lazy: true
	});
	const [{ data: entryFile, loading: fileLoading, error: fileError }, getEntryFile] =
		useEntryFile({
			id: value
		});

	const [{ loading: loadingCreateEntry }] = useActivity(ActionTypes.CREATE_ENTRY);
	const [{ loading: loadingUpdateEntry }] = useActivity(ActionTypes.UPDATE_ENTRY);

	const isPreviewableFile = useMemo(() => {
		return (
			entryFile &&
			!entryFile.isLocal &&
			ACCEPTED_PREVIEWABLE_TYPES.includes(entryFile.extendedMimeType)
		);
	}, [entryFile]);

	useEffect(() => {
		if (filesError && value && !entryFile && !loadingCreateEntry && !loadingUpdateEntry) {
			getEntryFile();
		}
	}, [filesError, entryFile, value, loadingCreateEntry, loadingUpdateEntry]);

	const prevEntryFile = usePrevious(entryFile);
	useEffect(() => {
		// in case there is another local file previously loaded, delete it from store
		if (prevEntryFile && prevEntryFile.fileId !== value && prevEntryFile.isLocal) {
			dispatch(deleteLocalEntryFileAction({ id: prevEntryFile.fileId }));
		}
	}, [value]);

	function onFileDrop({ file, readerResult }: OnFileDropOptions) {
		const id = generate();
		const newFile: LocalEntryFile = {
			fileId: id,
			variableName: name ? name : '',
			fileName: file.name,
			size: file.size,
			base64: readerResult,
			isLocal: true
		};

		if (onValueChange) onValueChange(id);
		if (onBlur) onBlur();

		dispatch(addLocalEntryFileAction({ file: newFile }));
	}

	function onPreviewClick() {
		dispatch(resetCsvTable());
		setIsPreviewWindowOpen(true);
	}

	function onReplaceClick() {
		// trigger upload new file
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	}

	function onDeleteClick() {
		if (onValueChange) onValueChange('');
		if (onBlur) onBlur();
	}

	function onDownloadClick() {
		if (entryFile && !entryFile.isLocal) {
			downloadFileFromUrl(entryFile.signedS3Url, entryFile.fileName, true);
		}
	}

	const showMenu = useMemo(() => {
		let show = true;

		const isLocalFile = entryFile?.isLocal;

		const hasFile = (entryFile && !isLocalFile) || value;

		if (disabled || readOnly || isLocalFile || !hasFile) show = false;

		return show;
	}, [disabled, readOnly, value, entryFile]);

	const showDelete = useMemo(
		() => !disabled && entryFile?.isLocal && !readOnly,
		[disabled, readOnly, entryFile]
	);

	return (
		<Container>
			<Flex align={a => a.center}>
				<InputLabel required={required} label={label} />
				{tooltipComponent}
			</Flex>

			<Row>
				<DragAndDrop
					ref={fileInputRef}
					filename={entryFile?.fileName ?? ''}
					acceptedFileTypes={ACCEPTED_ENTRY_FILE_TYPES}
					maxFileSize={ENTRY_FILE_SIZE_LIMIT}
					readerType={ReaderType.BASE64}
					disabled={disabled || !!entryFile}
					loading={!entryFile && !!value && (filesLoading || fileLoading)}
					error={
						fileError &&
						translate(dict => dict.errors.api.entriesFiles.couldNotRetrieveEntryFile)
					}
					onFileDrop={onFileDrop}
					onFileDialogCancel={onBlur}
					onFilenameClick={!entryFile?.isLocal ? onPreviewClick : undefined}
				/>

				{showDelete && (
					<Icon variant={v => v.button} svg={Svgs.Delete} onClick={onDeleteClick} />
				)}

				{showMenu && (
					<div>
						<Dropdown
							toggleComponent={({ ref, open, toggle }) => (
								<Icon
									svg={Svgs.More}
									active={open}
									variant={v => v.button}
									ref={ref}
									onClick={filesLoading || fileLoading ? undefined : toggle}
								/>
							)}
							width={20}
							offset={{ right: 15, top: 12 }}
						>
							<Dropdown.Item
								title={translate(dict => dict.buttons.replace)}
								onClick={onReplaceClick}
							/>
							{!fileError && (
								<Dropdown.Item
									title={
										isPreviewableFile
											? translate(dict => dict.buttons.preview)
											: translate(dict => dict.buttons.download)
									}
									onClick={onDownloadClick}
								/>
							)}
							<Dropdown.Item
								title={translate(dict => dict.buttons.delete)}
								onClick={onDeleteClick}
							/>
						</Dropdown>
					</div>
				)}
			</Row>

			<InputError error={error} />

			{isPreviewWindowOpen && entryFile && (
				<PreviewFileModal
					usedInEntries
					file={entryFile as StoredEntryFile}
					hasRename={false}
					hasDelete={!disabled || !readOnly}
					onDelete={onDeleteClick}
					onClose={() => setIsPreviewWindowOpen(false)}
				/>
			)}
		</Container>
	);
}

export const EntryFileInput = withMemo(Component, [
	'value',
	'name',
	'label',
	'required',
	'error',
	'disabled',
	'onBlur',
	'onValueChange'
]);

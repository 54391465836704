import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	CollaboratorsViewOptions,
	selectCollaboratorsViewOption,
	setCollaboratorsViewOption
} from 'store/data/collaborators';

export function useCollaboratorsViewOption(): LocalOperationResult<
	CollaboratorsViewOptions,
	(viewOption: CollaboratorsViewOptions) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectCollaboratorsViewOption(state.data.collaborators));

	function handler(viewOption: CollaboratorsViewOptions) {
		dispatch(setCollaboratorsViewOption({ viewOption }));
	}

	return [data, handler];
}

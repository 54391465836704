import { useState } from 'react';

import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { SubscriptionName } from 'store/account/subscription';
import {
	BillingPeriodUnit,
	LedidiPlanCurrency,
	SupportedLedidiPlansPrices,
	SupportedLedidiPlans
} from 'store/account/payments';
interface Props {
	currency: LedidiPlanCurrency;
	payMonthly: boolean;
	coveredUsersNumber: number;
	licencesQuantity: number;
	selectedSubscriptionName: SupportedLedidiPlans;
	refundableCredits: string;
	setLicencesQuantity: (quantity: number) => void;
	onSubmit: () => void;
}

export function PlanDetails({
	currency,
	payMonthly,
	coveredUsersNumber,
	licencesQuantity,
	selectedSubscriptionName,
	refundableCredits,
	setLicencesQuantity,
	onSubmit
}: Props) {
	const { translate } = useTranslation();

	const isOneLicenceSubscription = [
		SubscriptionName.LedidiCollaborator,
		SubscriptionName.LedidiFreeTrial
	].includes(selectedSubscriptionName);

	const [debouncedLicenceQuantity, setDebouncedLicenceQuantity] = useState<number>(
		isOneLicenceSubscription ? 1 : licencesQuantity
	);

	const newQuantityValid =
		(debouncedLicenceQuantity !== 0 && debouncedLicenceQuantity === coveredUsersNumber) ||
		debouncedLicenceQuantity > coveredUsersNumber ||
		isOneLicenceSubscription;

	const debouncedTime = newQuantityValid ? 0 : 1200;

	const minimumLicenceQuantity =
		isOneLicenceSubscription || coveredUsersNumber === 0 ? 1 : coveredUsersNumber;

	useDebounce(
		() => {
			if (newQuantityValid) {
				setLicencesQuantity(debouncedLicenceQuantity);
			} else {
				setLicencesQuantity(minimumLicenceQuantity);
				setDebouncedLicenceQuantity(minimumLicenceQuantity);
			}
		},
		[debouncedLicenceQuantity],
		debouncedTime
	);

	const isNorwegianCurrency = currency === LedidiPlanCurrency.NOK;

	const formattedRefundableCredits =
		refundableCredits === '0'
			? '0'
			: `${!isNorwegianCurrency ? `\u20AC` : ''}
		${refundableCredits.slice(0, -2) + '.' + refundableCredits.slice(-2)} ${
					isNorwegianCurrency ? 'NOK' : ''
			  }`;

	const { pricePerUnit } =
		SupportedLedidiPlansPrices[currency][selectedSubscriptionName][
			payMonthly ? BillingPeriodUnit.Month : BillingPeriodUnit.Year
		];

	const totalToPay = licencesQuantity ? licencesQuantity * pricePerUnit : 0;

	const parsedSumToPay = `${!isNorwegianCurrency ? `\u20AC` : ''}${totalToPay
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00 ${isNorwegianCurrency ? 'NOK' : ''}`;

	return (
		<>
			<Spacer size={s => s.m} />
			<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
				<Flex fullWidth align={a => a.end} justify={j => j.between}>
					<Flex wrap align={a => a.end}>
						<div>
							<Typography.H5 fontweight={f => f.bold}>
								{payMonthly
									? translate(
											({ accountUM }) =>
												accountUM.changePlan.ledidiCoreMonthly
									  )
									: translate(
											({ accountUM }) => accountUM.changePlan.ledidiCoreYearly
									  )}
							</Typography.H5>
							<Typography.Caption>
								{translate(
									({ accountUM }) => accountUM.changePlan.ledidiCoreCaption
								)}
							</Typography.Caption>
						</div>
					</Flex>
					<Flex wrap align={a => a.center}>
						<Input
							label={translate(({ accountUM }) => accountUM.changePlan.licences)}
							value={debouncedLicenceQuantity !== 0 ? debouncedLicenceQuantity : ''}
							minValue={minimumLicenceQuantity}
							onChange={e => {
								const value = parseInt(e.target.value, 10);
								if (value) {
									setDebouncedLicenceQuantity(value);
								} else {
									setDebouncedLicenceQuantity(0);
								}
							}}
							onSubmit={onSubmit}
							type={InputType.Number}
							disabled={isOneLicenceSubscription}
						/>
					</Flex>
				</Flex>
				<Spacer size={s => s.m} />
				<div style={{ width: '100%' }}>
					<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
						<Typography.H6 fontweight={f => f.bold}>
							{translate(({ accountUM }) => accountUM.changePlan.creditBalance)}
						</Typography.H6>
						<Typography.H6 fontweight={f => f.bold}>
							{formattedRefundableCredits}
						</Typography.H6>
					</Flex>
					<Typography.Caption>
						{translate(({ accountUM }) => accountUM.changePlan.creditBalanceCaption)}
					</Typography.Caption>
				</div>
				<Spacer size={s => s.xl} />
				<div style={{ width: '100%' }}>
					<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
						<Typography.Paragraph>
							{translate(({ accountUM }) => accountUM.changePlan.totalDueToday)}
						</Typography.Paragraph>
					</Flex>
					<Spacer size={s => s.xs} />
					<Typography.Caption>
						{payMonthly
							? translate(({ accountUM }) => accountUM.changePlan.billedMonthly)
							: translate(({ accountUM }) => accountUM.changePlan.billedYearly)}
					</Typography.Caption>
					<Spacer size={s => s.xs} />
				</div>
				<Flex fullWidth justify={j => j.end}>
					<Typography.H3>{parsedSumToPay}</Typography.H3>
				</Flex>
			</Flex>
		</>
	);
}

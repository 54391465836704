import { useState, useMemo } from 'react';
import { FormsListDrawer } from './FormsListDrawer';
import { stringAsBoolean } from 'helpers/generic';
import { useForms, useFormsBySetName } from 'hooks/store';

export function useFormsDrawer({ setName }: { setName?: string } = {}) {
	const [visible, setVisible] = useState(true);

	const formsBySetName = useFormsBySetName();
	const [{ data: forms }] = useForms({ lazy: true });
	const envFormsDesignerEnabled = stringAsBoolean(process.env.REACT_APP_USE_FORMS_DESIGNER!);

	const activeForms = useMemo(() => {
		const scopeForms = setName ? formsBySetName[setName] ?? [] : forms;

		return scopeForms.filter(form => form.active);
	}, [forms, formsBySetName, setName]);

	const enabled = envFormsDesignerEnabled && activeForms.length > 1;

	function toggleVisible() {
		setVisible(state => !state);
	}

	return {
		enabled,
		visible,
		toggleVisible,
		component: enabled ? (
			<FormsListDrawer open={visible} forms={activeForms} setName={setName} />
		) : null
	};
}

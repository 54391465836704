import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, addCollaborators } from 'store/data/collaborators';

export function useAddCollaborators(): Operation<(emailAddresses: string[]) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.ADD_COLLABORATORS);

	function handler(emailAddresses: string[]) {
		dispatch(addCollaborators(emailAddresses));
	}

	return [{ loading, error }, handler];
}

import { useMemo, useState } from 'react';
import { Variable } from 'api/data/variables';
import { Colors } from 'environment';
import { initButtonProps } from 'helpers/buttons';
import { InputType, SelectItem } from 'types/index';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Modal } from 'components/UI/Modal';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { Typography } from 'components/UI/Typography';
import { initVariableCategory, buildVariableCategoriesMap } from 'helpers/variables';
import { useTranslation, useMergeVariableCategoryValues } from 'hooks/store';
import { useMutableState, useCompletedAction, usePaginate } from 'hooks/utils';

enum Steps {
	Form = 'form',
	Confirm = 'confirm'
}

enum TargetType {
	New = 'new',
	Existing = 'existing'
}

interface Props {
	variable: Variable;
	categoryValueIds: string[];
	onClose: (success?: boolean) => void;
}

export function MergeCategoryValuesModal({ variable, categoryValueIds, onClose }: Props) {
	const { translate } = useTranslation();

	const [step, setStep] = useState(Steps.Form);
	const [targetType, setTargetType] = useState(TargetType.New);

	const [draftCategoryValue, setDraftCategoryValue] = useMutableState(initVariableCategory());

	const categoriesMap = useMemo(
		() => buildVariableCategoriesMap(variable.categories, 'id'),
		[variable.categories]
	);

	const [
		{ loading: mergingCategoryValues, error: errorMergingCategoryValues },
		mergeCategoryValues
	] = useMergeVariableCategoryValues();

	useCompletedAction(mergingCategoryValues, errorMergingCategoryValues, () => onClose(true));

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, changePageSize } =
		usePaginate(getTagInputItems(), {
			threshold: 10,
			pageSize: 10
		});

	function handleSubmit() {
		if (!isFormValid()) return;

		if (isAtStep(Steps.Form)) setStep(Steps.Confirm);

		if (isAtStep(Steps.Confirm)) {
			if (isTargetType(TargetType.New)) {
				const { id, ...newCategoryValue } = draftCategoryValue;

				mergeCategoryValues({
					variableName: variable.name,
					categoryValueIds,
					targetCategoryValue: newCategoryValue
				});
			}

			if (isTargetType(TargetType.Existing)) {
				mergeCategoryValues({
					variableName: variable.name,
					categoryValueIds,
					targetCategoryValue: { id: draftCategoryValue.id }
				});
			}
		}
	}

	function isFormValid() {
		let valid = false;

		if (isTargetType(TargetType.New)) {
			valid =
				draftCategoryValue.value.trim().length > 0 && isValueUnique() && isLabelUnique();
		}

		if (isTargetType(TargetType.Existing)) {
			valid = draftCategoryValue.value.length > 0;
		}

		return valid;
	}

	function isValueUnique(): boolean {
		return !variable.categories.some(c => c.value === draftCategoryValue.value.trim());
	}

	function isLabelUnique(): boolean {
		return !variable.categories.some(
			c => c.label.length > 0 && c.label === draftCategoryValue.label.trim()
		);
	}

	function getTagInputItems(): string[] {
		const items: string[] = [];

		categoryValueIds.forEach(categoryValueId => {
			if (categoryValueId in categoriesMap) {
				const category = categoriesMap[categoryValueId];

				const item = category.label || category.value;

				items.push(item);
			}
		});

		return items;
	}

	function getTargetValueSelectItems(): SelectItem[] {
		const items: SelectItem[] = [];

		variable.categories.forEach(c => {
			if (categoryValueIds.includes(c.id)) return;

			const item: SelectItem = {
				label: c.label || c.value,
				value: c.id
			};

			items.push(item);
		});

		return items;
	}

	function getModalTitle() {
		return `${translate(dict => dict.terms.merge)} ${categoryValueIds.length} ${
			categoryValueIds.length === 1
				? translate(dict => dict.terms.value)
				: translate(dict => dict.terms.values)
		}`;
	}

	function resetDraftCategoryValue() {
		setDraftCategoryValue(initVariableCategory());
	}

	function isAtStep(targetStep: string) {
		return step === targetStep;
	}

	function isTargetType(type: TargetType) {
		return targetType === type;
	}

	const buttonProps = initButtonProps(buttons => {
		if (isAtStep(Steps.Form)) {
			buttons.primary = {
				label: translate(({ buttons }) => buttons.confirm),
				disabled: !isFormValid(),
				onClick: () => setStep(Steps.Confirm)
			};

			buttons.neutral = {
				label: translate(({ buttons }) => buttons.back),
				onClick: onClose
			};
		}

		if (isAtStep(Steps.Confirm)) {
			buttons.primary = {
				label: translate(({ buttons }) => buttons.merge),
				loading: mergingCategoryValues,
				onClick: handleSubmit
			};

			buttons.neutral = {
				label: translate(({ buttons }) => buttons.back),
				onClick: () => setStep(Steps.Form)
			};
		}
	});

	const targetValueSelectItems = getTargetValueSelectItems();

	return (
		<Modal
			size={s => (isAtStep(Steps.Confirm) ? s.s : s.m)}
			title={getModalTitle()}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			{isAtStep(Steps.Form) && (
				<>
					{shouldPaginate && (
						<Flex marginOffset={{ bottom: 0.4 }}>
							<Pagination
								totalCountLabel="values"
								pageIndex={pageIndex}
								pageSize={pageSize}
								pagesCount={pagesCount}
								changePage={changePage}
								changePageSize={changePageSize}
								totalCount={getTagInputItems().length}
							/>
						</Flex>
					)}

					<TagInput items={page} readOnly />

					<Spacer size={s => s.m} />

					<Typography.H6>{translate(dict => dict.terms.mergeInto)}</Typography.H6>

					<Spacer size={s => s.s} />

					<RadioGroup
						selected={targetType}
						items={[TargetType.New, TargetType.Existing]}
						onSelect={item => {
							const newTargetType = item as TargetType;

							if (targetType !== newTargetType) {
								resetDraftCategoryValue();
								setTargetType(newTargetType);
							}
						}}
					/>

					<Spacer size={s => s.s} />

					{isTargetType(TargetType.New) && (
						<>
							<Input
								label={translate(dict => dict.terms.valueName)}
								type={InputType.Text}
								value={draftCategoryValue.value}
								error={
									!isValueUnique()
										? translate(dict => dict.terms.uniqueValue)
										: undefined
								}
								onChange={e =>
									setDraftCategoryValue(state => {
										state.value = e.target.value;
									})
								}
								onSubmit={handleSubmit}
								autoFocus={draftCategoryValue.value === ''}
								required
							/>
							<Spacer size={s => s.m} />
							<Input
								label={translate(dict => dict.terms.label)}
								type={InputType.Text}
								value={draftCategoryValue.label}
								error={
									!isLabelUnique()
										? translate(dict => dict.terms.labelUnique)
										: undefined
								}
								onChange={e =>
									setDraftCategoryValue(state => {
										state.label = e.target.value;
									})
								}
								onSubmit={handleSubmit}
							/>
							<Spacer size={s => s.m} />
							<Input
								label={translate(dict => dict.terms.description)}
								type={InputType.Text}
								value={draftCategoryValue.description}
								onChange={e =>
									setDraftCategoryValue(state => {
										state.description = e.target.value;
									})
								}
								onSubmit={handleSubmit}
							/>
						</>
					)}

					{isTargetType(TargetType.Existing) && (
						<CreatableSelect
							placeholder={translate(dict => dict.terms.selectTargetValue)}
							value={targetValueSelectItems.find(
								item => item.value === draftCategoryValue.id
							)}
							items={targetValueSelectItems}
							onValueSelected={value => {
								if (value && value in categoriesMap) {
									const categoryValue = categoriesMap[value];

									setDraftCategoryValue(categoryValue);
								}
							}}
							canClear={false}
						/>
					)}
				</>
			)}

			{isAtStep(Steps.Confirm) && (
				<>
					<Typography.Paragraph multiline>
						{`${translate(
							dict => dict.variablesPage.mergeCategoryValuesModal.beforeVariable
						)}"${draftCategoryValue.value}"${translate(
							dict => dict.variablesPage.mergeCategoryValuesModal.afterVariable
						)}"${draftCategoryValue.value}".`}
					</Typography.Paragraph>

					<Spacer size={s => s.m} />

					<Typography.Paragraph fontweight={w => w.bold} color={Colors.text.error}>
						{translate(dict => dict.terms.deleteWarningMessage)}
					</Typography.Paragraph>
				</>
			)}
		</Modal>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	EntriesErrors,
	EntriesErrorsFilter,
	setEntriesTableErrorFilters
} from 'store/data/entries';
import { useMutableState, useDebounce } from 'hooks/utils';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

interface Data {
	errors: EntriesErrors | null;
	filter: EntriesErrorsFilter;
	draftFilter: EntriesErrorsFilter;
}

interface Handlers {
	toggleColumnsFilter: () => void;
	toggleRowsFilter: () => void;
	clearFilters: () => void;
}

export function useEntriesErrors(): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const { data: errors, filter } = useSelector(state => state.data.entries.errors);

	const [draftFilter, setDraftFilter] = useMutableState(filter);

	// SYNC `draftFilter` STATE
	useEffect(() => {
		if (!isEqual(draftFilter, filter)) setDraftFilter(filter);
	}, [filter]);

	useDebounce(
		() => {
			if (isEqual(draftFilter, filter)) return;

			dispatch(setEntriesTableErrorFilters(draftFilter));
		},
		[draftFilter],
		400
	);

	function clearFilters() {
		dispatch(setEntriesTableErrorFilters({ columns: false, rows: false }));
	}

	function toggleColumnsFilter() {
		setDraftFilter(state => {
			state.columns = !state.columns;
		});
	}

	function toggleRowsFilter() {
		setDraftFilter(state => {
			state.rows = !state.rows;
		});
	}

	const handlers: Handlers = {
		clearFilters,
		toggleColumnsFilter,
		toggleRowsFilter
	};

	return [{ errors, filter, draftFilter }, handlers];
}

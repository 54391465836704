import { useProject } from 'hooks/store';
import { PromDistributionTypes } from 'types/index';

export function usePromManualDistribution() {
	const [{ data: project }] = useProject();

	const isPromManualDistribution = project?.promType === PromDistributionTypes.Manual;

	return { isPromManualDistribution };
}

import { FontWeights, Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const GroupContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 5.6rem;
	width: 100%;
	padding: 0.8rem 1.6rem;

	:nth-child(even) {
		background: ${Colors.background.disabled};
	}
	:nth-child(odd) {
		background: ${Colors.white};
	}

	:hover {
		background: ${Colors.primary.disabled};
	}

	.remove-button {
		visibility: hidden;
		color: ${Colors.text.error};
		font-weight: ${FontWeights.normal};
	}

	:hover {
		background-color: ${Colors.primary.disabled};

		.remove-button {
			visibility: visible;
		}
	}
`;

export const Title = styled(Typography.Paragraph)`
	font-weight: 500;
`;

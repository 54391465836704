import { State } from './types';

const initialState: State = {
	users: {
		byEmail: {},
		byUserId: {},
		invitedByEmail: {},
		eligibleForInvitation: [],
		invalidUsers: [],
		notAllowedDueToDomainConstraintViolation: []
	}
};

export default initialState;

import { sendRequest, NOTIFICATIONS_URL, PROJECTS_URL } from 'api/utils';
import { NotificationActionState, ProjectNotificationSettings } from 'store/data/notifications';

import { GenericMap } from 'types/index';

import {
	GetNotificationHistory,
	GetTopicNameResponse,
	GetNotificationHistoryResponse,
	GetPojectNotificationSettingsResponse,
	GetPojectsLabelsResponse
} from './types';

const methods = {
	getNotificationSubscriptionDetails: 'getNotificationSubscriptionDetails',
	getNotificationHistory: 'getNotificationHistory',
	setNotificationsAsRead: 'setNotificationsAsRead',
	setNotificationActionState: 'setNotificationActionState',

	// settings
	getProjectsLabels: 'getProjectsLabels',
	getProjectNotificationSettings: 'getProjectNotificationSettings',
	updateProjectNotificationSettings: 'updateProjectNotificationSettings'
};

export default () => ({
	async getTopicName(): Promise<string> {
		const { data }: GetTopicNameResponse = await sendRequest(NOTIFICATIONS_URL, {
			method: methods.getNotificationSubscriptionDetails
		});

		if (data && !data.topicName) {
			throw Error();
		}

		return data.topicName;
	},

	async getNotificationHistory(offset: number): Promise<GetNotificationHistory> {
		const { data }: GetNotificationHistoryResponse = await sendRequest(NOTIFICATIONS_URL, {
			method: methods.getNotificationHistory,
			offset
		});

		if (data && !data.notifications) {
			throw Error();
		}

		return { notifications: data.notifications, totalNumber: data.totalNumber };
	},

	async setNotificationsAsRead(notificationIds: string[]): Promise<void> {
		await sendRequest(NOTIFICATIONS_URL, {
			method: methods.setNotificationsAsRead,
			notificationIds
		});
	},

	async setNotificationActionState(
		notificationIds: string[],
		actionState: NotificationActionState
	): Promise<void> {
		await sendRequest(NOTIFICATIONS_URL, {
			method: methods.setNotificationActionState,
			notificationIds,
			actionState
		});
	},

	getProjectNotificationSettings: async (
		projectId: string
	): Promise<ProjectNotificationSettings> => {
		const { data }: GetPojectNotificationSettingsResponse = await sendRequest(
			NOTIFICATIONS_URL,
			{
				method: methods.getProjectNotificationSettings,
				projectId
			}
		);

		if (!data.notificationSettings || !data.notificationSettings.projectEntryAdd) throw Error;

		return data.notificationSettings;
	},

	updateProjectNotificationSettings: async (
		projectId: string,
		settings: ProjectNotificationSettings
	) => {
		await sendRequest(NOTIFICATIONS_URL, {
			method: methods.updateProjectNotificationSettings,
			projectId,
			notificationSettings: settings
		});
	},

	getProjectsLabels: async (): Promise<GenericMap<string>> => {
		const { data }: GetPojectsLabelsResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getProjectsLabels
		});

		if (!data.projectIdsToLabels) throw Error();

		return data.projectIdsToLabels;
	}
});

/**
 * Delay for a number of milliseconds
 */
// function sleep(delay: number) {
// 	return new Promise(resolve => setTimeout(resolve, delay));
// }

import { useState } from 'react';
import { OrganizationsList, AddDomainsModal } from 'components/Admin';
import { Header } from 'components/Header';
import { Suspend } from 'components/UI/Suspend';
import { Grid } from 'components/UI/Grid';
import { Spacer } from 'components/UI/Spacer';
import { useEnterpriseOrganizations, useTranslation } from 'hooks/store';

export function AdminEnterprisePage() {
	const { translate } = useTranslation();
	const [domainsModalVisible, setDomainsModalVisible] = useState(false);

	const [{ data: enterpriseOrganizations, loading, fetched }] = useEnterpriseOrganizations();

	function handleDomainsModalVisibility() {
		setDomainsModalVisible(!domainsModalVisible);
	}

	return (
		<>
			<Header.Main />
			<Header.Navigation />
			<Header.Title title={translate(({ admin }) => admin.enterprise.title)} />
			<Suspend loading={loading} immediate={!fetched}>
				<Grid.Container>
					<OrganizationsList
						enterpriseOrganizations={enterpriseOrganizations}
						setDomainsModalVisible={handleDomainsModalVisibility}
					/>
				</Grid.Container>
			</Suspend>
			<Spacer size={s => s.xl} />

			{/* Add domain(s) modal */}
			{domainsModalVisible && <AddDomainsModal onClose={handleDomainsModalVisibility} />}
		</>
	);
}

import { useSelector } from 'hooks/utils';
import { selectVariablesByName } from 'store/data/variables';
import { RequireAtLeastOne } from 'types/index';

export function useVariablesByName(
	variableNames: string[],
	options?: RequireAtLeastOne<{ initial?: boolean }>
) {
	const { initial = false } = options ?? {};

	return useSelector(state =>
		selectVariablesByName(state.data.variables, { variableNames, initial })
	);
}

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useRemoveCollaboratorsFromOrganization, useTranslation } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { Organization } from 'store/data/collaborators';

interface Props {
	organization: Organization;
	collaboratorIds: string[];
	onClose: (success?: boolean) => void;
}

export function RemoveFromOrganizationModal({ organization, collaboratorIds, onClose }: Props) {
	const { translate } = useTranslation();

	const [
		{
			loading: removingCollaboratorsFromOrganization,
			error: errorRemovingCollaboratorsFromOrganization
		},
		removeCollaboratorsFromOrganization
	] = useRemoveCollaboratorsFromOrganization();

	useCompletedAction(
		removingCollaboratorsFromOrganization,
		errorRemovingCollaboratorsFromOrganization,
		() => onClose(true)
	);

	function handleSubmit() {
		removeCollaboratorsFromOrganization({
			organizationIds: [organization.id],
			collaborators: collaboratorIds
		});
	}

	function getPlural() {
		return collaboratorIds.length === 1
			? ''
			: translate(dict => dict.collaborators.removeFromOrganizationModal.plural);
	}

	function getTitle(): string {
		return `${translate(dict => dict.collaborators.removeFromOrganizationModal.remove)} ${
			collaboratorIds.length
		} ${translate(
			dict => dict.collaborators.removeFromOrganizationModal.collaborator
		)}${getPlural()}?`;
	}

	return (
		<Modal
			size={s => s.s}
			title={getTitle()}
			primary={{
				label: translate(({ buttons }) => buttons.remove),
				loading: removingCollaboratorsFromOrganization,
				warning: true,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{`${translate(
					dict => dict.collaborators.removeFromOrganizationModal.multilineStart
				)}${getPlural()} ${translate(
					dict => dict.collaborators.removeFromOrganizationModal.from
				)} `}
				<b>{organization.name}</b>
				{` ${translate(dict => dict.collaborators.removeFromOrganizationModal.group)}?`}

				{`\n\n`}

				<b>{`${translate(
					dict => dict.collaborators.removeFromOrganizationModal.message
				)}${getPlural()} ${translate(
					dict => dict.collaborators.removeFromOrganizationModal.permissions
				)}.`}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

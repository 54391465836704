import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	SetPatientManualSurveyParamsAction,
	setPatientManualSurveyParams
} from 'store/data/patients';
import { PatientManualSurveyParams } from 'store/data/patients';
import { ActionPayload } from 'store/types';

export function usePatientManualSurveyParams(): LocalOperationResult<
	PatientManualSurveyParams | null,
	(payload: ActionPayload<SetPatientManualSurveyParamsAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.patients.surveyParams.manual);

	function handler(payload: ActionPayload<SetPatientManualSurveyParamsAction>) {
		dispatch(setPatientManualSurveyParams(payload));
	}

	return [data, handler];
}

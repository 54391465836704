import { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { Svgs, Colors } from 'environment';
import { toValidDate } from 'helpers/dateFormat';
import { useDebounce } from 'hooks/utils';
import { useNotificationsBatch, useSetNotificationsAsRead, useTranslation } from 'hooks/store';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Notification } from 'store/data/notifications';

import { LoadingWrapper } from './NotificationsLists.style';
import { NotificationCard } from '../NotificationCard';
import { NotificationPlaceholer } from '../NotificationPlaceholder';

interface NotificationsListProps {
	onCardClick: (notification: Notification) => void;
}

export function NotificationsList({ onCardClick }: NotificationsListProps) {
	const { translate } = useTranslation();

	const [loadingPlaceholderRef, inView] = useInView();

	const [
		{
			data: {
				notifications,
				batchNotifications,
				showOnlyUnread,
				fullNotificationHistoryFetched
			},
			loading: notificationsLoading,
			error: notificationsError,
			fetched: notificationsFetched
		},
		getMoreNotifications
	] = useNotificationsBatch();

	const [_, setNotificationsAsRead] = useSetNotificationsAsRead();

	const unreadNotifications = batchNotifications ? batchNotifications.filter(n => !n.isRead) : [];

	const filteredNotifications = useMemo(() => {
		if (!batchNotifications) return [];
		return showOnlyUnread ? unreadNotifications : batchNotifications;
	}, [batchNotifications, showOnlyUnread]);

	useDebounce(
		() => {
			if (inView && !notificationsLoading) {
				getMoreNotifications(false);
			}
		},
		[inView, notificationsLoading],
		500
	);

	function onMarkAllAsRead() {
		if (notifications) {
			const ids: string[] = [];
			notifications.forEach(notif => {
				if (!notif.isRead) ids.push(notif.notificationId);
			});
			setNotificationsAsRead(ids);
		}
	}

	const MarkAllAsReadButton = () => (
		<Typography.Paragraph
			fontweight={f => f.bold}
			color={unreadNotifications.length ? Colors.text.main : Colors.text.disabled}
			onClick={unreadNotifications.length ? onMarkAllAsRead : undefined}
			style={{
				cursor: unreadNotifications.length ? 'pointer' : 'default'
			}}
		>
			{translate(dict => dict.notifications.markAllAsRead)}
		</Typography.Paragraph>
	);

	function isSameDay(date1: Date, date2: Date) {
		if (
			date1.getDate() === date2.getDate() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()
		) {
			return true;
		} else {
			return false;
		}
	}

	function isDateToday(date: Date) {
		const todayDate = new Date();
		return isSameDay(date, todayDate);
	}

	function isDateYesterday(date: Date) {
		const yesterdayDate = Date.now() - 1000 * 60 * 60 * 24;
		return isSameDay(date, new Date(yesterdayDate));
	}

	const todaysNotifications = useMemo(
		() =>
			filteredNotifications
				.filter(notif => {
					return isDateToday(toValidDate(notif.timestamp));
				})
				.reverse(),
		[filteredNotifications]
	);

	const yesterdaysNotifications = useMemo(
		() =>
			filteredNotifications
				.filter(notif => {
					return isDateYesterday(toValidDate(notif.timestamp));
				})
				.reverse(),
		[filteredNotifications]
	);

	const olderNotifications = useMemo(
		() =>
			filteredNotifications
				.filter(notif => {
					const date = toValidDate(notif.timestamp);
					return !isDateToday(date) && !isDateYesterday(date);
				})
				.reverse(),
		[filteredNotifications]
	);

	return (
		<div>
			{filteredNotifications.length === 0 && (notificationsFetched || notificationsError) && (
				<Flex paddingOffset={{ y: 1.6, x: 2.4 }} justify={j => j.center} column>
					<Svgs.EmptyStatesNoNotifications style={{ minHeight: 240 }} />
					<Typography.Paragraph alignCenter>
						{notificationsFetched
							? translate(dict => dict.notifications.noNewNotifications)
							: translate(dict => dict.errors.api.notifications.notificationHistory)}
					</Typography.Paragraph>
				</Flex>
			)}

			{todaysNotifications.length > 0 && (
				<>
					<Flex paddingOffset={{ y: 1.6, x: 2.4 }} align={a => a.center} fullWidth>
						<Typography.Caption fontweight={f => f.bold} style={{ flex: 1 }}>
							{translate(dict => dict.notifications.today)}
						</Typography.Caption>
						<MarkAllAsReadButton />
					</Flex>
					{todaysNotifications.map(notif => (
						<NotificationCard
							key={notif.notificationId}
							notification={notif}
							onClick={() => onCardClick(notif)}
						/>
					))}
				</>
			)}
			{yesterdaysNotifications.length > 0 && (
				<>
					<Flex paddingOffset={{ y: 1.6, x: 2.4 }} align={a => a.center} fullWidth>
						<Typography.Caption fontweight={f => f.bold} style={{ flex: 1 }}>
							{translate(dict => dict.notifications.yesterday)}
						</Typography.Caption>
						{todaysNotifications.length === 0 && <MarkAllAsReadButton />}
					</Flex>
					{yesterdaysNotifications.map(notif => (
						<NotificationCard
							key={notif.notificationId}
							notification={notif}
							onClick={() => onCardClick(notif)}
						/>
					))}
				</>
			)}
			{olderNotifications.length > 0 && (
				<>
					<Flex paddingOffset={{ y: 1.6, x: 2.4 }} align={a => a.center} fullWidth>
						<Typography.Caption fontweight={f => f.bold} style={{ flex: 1 }}>
							{translate(dict => dict.notifications.older)}
						</Typography.Caption>
						{todaysNotifications.length === 0 &&
							yesterdaysNotifications.length === 0 && <MarkAllAsReadButton />}
					</Flex>
					{olderNotifications.map(notif => (
						<NotificationCard
							key={notif.notificationId}
							notification={notif}
							onClick={() => onCardClick(notif)}
						/>
					))}
				</>
			)}

			<LoadingWrapper
				ref={loadingPlaceholderRef}
				show={!fullNotificationHistoryFetched && !notificationsError}
			>
				<NotificationPlaceholer />
				<NotificationPlaceholer />
			</LoadingWrapper>
		</div>
	);
}

import { AdminUsersHeader, AdminUsersList } from 'components/Admin';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import { useAdminUsersList, useOrganizations, useLicenceModels } from 'hooks/store';

export function AdminUsersPage() {
	const [{ data: users, loading: loadingUsers, fetched }] = useAdminUsersList();
	const [{ loading: loadingOrganizations }] = useOrganizations();
	const [{ loading: loadingLicenceModels }] = useLicenceModels();

	return (
		<>
			<AdminUsersHeader />

			<Suspend
				loading={loadingUsers || loadingOrganizations || loadingLicenceModels}
				immediate={!fetched}
			>
				<Grid.Container>
					<AdminUsersList users={users} />
				</Grid.Container>
			</Suspend>
		</>
	);
}

import { isEmail } from 'api/utils/helpers';
import produce from 'immer';

import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_USERS_BY_EMAIL: {
			const {
				users,
				eligibleForInvitation,
				invalidUsers,
				notAllowedDueToDomainConstraintViolation
			} = action.payload;

			return produce(state, draft => {
				const { byEmail, invitedByEmail } = draft.users;

				users.forEach(user => {
					user.emailAddress = user.emailAddress.trim().toLowerCase();

					if (!byEmail[user.emailAddress]) byEmail[user.emailAddress] = user;
				});
				if (!eligibleForInvitation) {
					draft.users.invalidUsers = invalidUsers;
					draft.users.eligibleForInvitation = [];
				} else {
					const filteredEligibles = eligibleForInvitation.filter(
						eligibleEmail => !Object.keys(invitedByEmail).includes(eligibleEmail)
					);
					draft.users.eligibleForInvitation = filteredEligibles;
					const filteredInvalidUsers = invalidUsers.filter(
						invalidUser => !eligibleForInvitation.includes(invalidUser)
					);

					draft.users.invalidUsers = filteredInvalidUsers;

					draft.users.notAllowedDueToDomainConstraintViolation =
						notAllowedDueToDomainConstraintViolation;
				}
			});
		}

		case ActionTypes.SEARCH_USERS_BY_EMAIL: {
			const { users, notSharedWith } = action.payload;

			return produce(state, draft => {
				const { byEmail } = draft.users;

				users.forEach(user => {
					user.emailAddress = user.emailAddress.trim().toLowerCase();

					if (!byEmail[user.emailAddress]) byEmail[user.emailAddress] = user;
				});

				const eligibleForInvitationValidEmails = notSharedWith.filter(email =>
					isEmail(email)
				);

				draft.users.eligibleForInvitation = eligibleForInvitationValidEmails;
			});
		}

		case ActionTypes.GET_USERS_BY_ID: {
			const { users } = action.payload;

			return produce(state, draft => {
				const { byUserId } = draft.users;

				users.forEach(user => {
					user.emailAddress = user.emailAddress.trim().toLowerCase();
					const userId = user.userid ?? user.emailAddress;

					if (!byUserId[userId]) byUserId[userId] = user;
				});
			});
		}

		case ActionTypes.CLEAR_INVALID_USERS: {
			return produce(state, draft => {
				draft.users.invalidUsers = [];
			});
		}

		case ActionTypes.SET_INVALID_USERS: {
			const { users } = action.payload;

			return produce(state, draft => {
				draft.users.invalidUsers = [...draft.users.invalidUsers, ...users];
			});
		}
		case ActionTypes.INREACT_APP_USER: {
			const { emailAddress } = action.payload;

			return produce(state, draft => {
				draft.users.invitedByEmail[emailAddress] = {
					email: emailAddress
				};
				const users = draft.users;
				users.eligibleForInvitation = users.eligibleForInvitation.filter(
					user => user !== emailAddress
				);
			});
		}

		default: {
			return state;
		}
	}
};

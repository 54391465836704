import { EntryFilter } from 'api/data/filters';
import { VariableType } from 'types/data/variables/constants';
import { systemGeneratedVariables } from 'consts';

import { mapVariableCategoryValueToLabel } from './variables';
import { mapVariableCategoryValuesToLabels } from './variables';
import { matchesFilter } from './entries';
import { cloneDeep } from 'lodash';
import { Entries } from 'store/data/entries/types';
import { VariablesMap } from 'store/data/variables/types';

export function getSeriesRows(input: {
	entries: Entries;
	searchTerm: string;
	variablesMap: VariablesMap;
	seriesFilters: EntryFilter[];
}) {
	const { entries, searchTerm, variablesMap, seriesFilters } = input;

	let filteredSeries = cloneDeep(entries);

	// APPLY FILTERS
	seriesFilters.forEach(filter => {
		filteredSeries = filteredSeries.filter(entry =>
			matchesFilter(entry[filter.columnName], filter)
		);
	});

	// FILTER BY TERM
	if (searchTerm.trim().length) {
		filteredSeries = filteredSeries.filter(entry => {
			let found = false;

			// Disregard `userProjectOrgId` and the user ids columns when searching
			const { userProjectOrgId, enteredbyuser, ownedbyuser, ...rest } = entry;

			for (const key in rest) {
				const value = rest[key];

				if (value !== null) {
					const variable = variablesMap[key];

					const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();

					let lowerCaseValue = value;

					if (variable) {
						// `categoryMultiple`
						if (Array.isArray(value)) {
							const labels = mapVariableCategoryValuesToLabels(value, variable);

							lowerCaseValue = labels.map(value => value.trim().toLowerCase());
						} else {
							// `category`
							if (variable.type === VariableType.Category) {
								const label =
									mapVariableCategoryValueToLabel(value, variable) ?? '';

								lowerCaseValue = label.trim().toLowerCase();
							}
							// rest
							else {
								lowerCaseValue = value.trim().toLowerCase();
							}
						}
					}

					if (systemGeneratedVariables.includes(key)) {
						lowerCaseValue = (value as string).trim().toLowerCase();
					}

					if (Array.isArray(lowerCaseValue)) {
						const matched = lowerCaseValue.join(', ').includes(lowerCaseSearchTerm);

						// TODO: Revert in case we want to match any value from `categoryMultiple` array
						// const matched = lowerCaseEntryValue.some(value =>
						// 	value.startsWith(lowerCaseSearchTerm)
						// );

						if (matched) {
							found = true;
							break;
						}
					} else {
						if (lowerCaseValue.startsWith(lowerCaseSearchTerm)) {
							found = true;
							break;
						}
					}
				}
			}

			return found;
		});
	}

	// FILTER ERRORED ROWS
	// if (errors.data && errors.filter.rows) {
	// 	const entriesErrors = errors.data.rows;

	// 	filteredEntries = filteredEntries.filter(entry => entry.datasetentryid in entriesErrors);
	// }

	return filteredSeries;
}

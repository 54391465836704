import { ImportDropzone, ImportText, NarrowContainer } from 'components/Projects/CreateAndImport';
import { Spacer } from 'components/UI/Spacer';
import { StickyFooter } from 'components/UI/StickyFooter';
import { Typography } from 'components/UI/Typography';
import { DATASET_FILE_SIZE_LIMIT } from 'consts';
import { ImportType, SetState } from 'types/index';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useImportDataText, useImport, useImportVariableSet } from 'hooks/store';

interface Props {
	importFileName: string;
	selectedOption: ImportType | null;
	uploadingFile: boolean;
	setImportFileName: SetState<string>;
	handleFinishedLoading: () => void;
	handleUploadingError: () => void;
}

export function Reupload({
	importFileName,
	selectedOption,
	uploadingFile,
	setImportFileName,
	handleFinishedLoading,
	handleUploadingError
}: Props) {
	const { translate } = useTranslation();
	const { goBack } = useNavigation();

	const [{ data: importText }] = useImportDataText();
	const [, { setPreviousMapping }] = useImport();

	const [
		{ isImportVariableSet, importVariableSetName },
		{ setIsImportVariableSet, setImportVariableSetName }
	] = useImportVariableSet();

	function handleClose() {
		setPreviousMapping(null);
		goBack();

		isImportVariableSet && setIsImportVariableSet(false);
		importVariableSetName && setImportVariableSetName('');
	}

	return (
		<>
			<NarrowContainer>
				{selectedOption && (
					<>
						<ImportDropzone
							maxFileSize={DATASET_FILE_SIZE_LIMIT}
							importType={selectedOption}
							handleFinishedLoading={handleFinishedLoading}
							handleUploadingError={handleUploadingError}
							setImportFileName={setImportFileName}
							importFileName={importFileName}
							getSuggestedVariables={false}
						/>
						<Spacer size={s => s.s} />
					</>
				)}
				{uploadingFile && (
					<Typography.Hint marginOffset={{ y: 1 }}>
						{translate(dict => dict.projects.createAndImport.dropzoneLabel)}
					</Typography.Hint>
				)}
				<ImportText>{importText?.content}</ImportText>
			</NarrowContainer>

			<StickyFooter
				neutral={
					!uploadingFile && {
						label: translate(({ buttons }) => buttons.cancel),
						onClick: handleClose
					}
				}
				maxWidth={65.2}
			/>
		</>
	);
}

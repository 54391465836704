import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0.8rem;

	.confirm-checkbox {
		width: auto;
		margin-right: 0.8rem;
	}

	.user-card {
		flex: 1;
	}
`;

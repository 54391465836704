import { useState } from 'react';
import { FilterIcon } from 'components/UI/Icons';
import { Svgs, Colors } from 'environment';
import { useTranslation } from 'hooks/store';
import { UserLicenceLabels, UserLicenceModel } from 'store/account/subscription';
import { CheckboxFilter as CheckboxFilterInterface } from 'store/account/admin';
import { ProjectFilesListFilterNames } from 'store/data/documents';
import { Offset, InputType } from 'types/index';

import {
	DropdownScrollContainer,
	Dropdown,
	DropdownHeader,
	DropdownTitle,
	ResetFilterIcon,
	NoResults
} from './styles';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Input } from 'components/UI/Inputs/Input';

interface Props {
	filter: CheckboxFilterInterface;
	filterName: ProjectFilesListFilterNames;
	title: string;
	width?: number;
	offset?: Offset;
	withSearch?: boolean;
	translateLabel?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	handleFilter: (filterName: ProjectFilesListFilterNames, item: string) => void;
	handleResetFilter: (filterName: ProjectFilesListFilterNames) => void;
}

export function CheckboxFilter({
	filter,
	filterName,
	title,
	width,
	offset,
	withSearch,
	translateLabel,
	onOpen,
	onClose,
	handleFilter,
	handleResetFilter
}: Props) {
	const { translate } = useTranslation();

	const [term, setTerm] = useState('');

	function reset() {
		setTerm('');
		if (filter.active.length) handleResetFilter(filterName);
	}

	const list = filter.all.filter(item => {
		const trimmedTerm = term.trim().toLowerCase();

		if (!trimmedTerm.length) return true;

		item = item.trim().toLowerCase();

		return item.includes(trimmedTerm);
	});

	const noResults = term.length > 0 && !list.length;

	function onCloseDropdown() {
		setTerm('');
		onClose && onClose();
	}

	return (
		<Dropdown
			usePortal
			isPopup
			width={width}
			offset={offset}
			onOpen={onOpen}
			toggleComponent={({ ref, open, toggle }) => (
				<FilterIcon ref={ref} active={open || filter.active.length > 0} onClick={toggle} />
			)}
			onClose={onCloseDropdown}
		>
			<>
				<DropdownHeader onClick={e => e.stopPropagation()}>
					<DropdownTitle>{title}</DropdownTitle>
					<ResetFilterIcon
						svg={Svgs.Delete}
						disabled={!filter.active.length}
						colors={{
							color: Colors.text.disabled,
							hover: Colors.primary.normal
						}}
						onClick={reset}
					/>
				</DropdownHeader>
				{withSearch && (
					<Input
						type={InputType.Text}
						value={term}
						placeholder={translate(dict => dict.terms.search)}
						onChange={e => setTerm(e.target.value)}
					/>
				)}
				<DropdownScrollContainer>
					{list.map((item, index) => (
						<Dropdown.Item key={index} onClick={() => handleFilter(filterName, item)}>
							<Checkbox
								label={
									translateLabel
										? translate(
												() => UserLicenceLabels[item as UserLicenceModel]
										  ) ?? item
										: item
								}
								checked={filter.active.includes(item)}
								onClick={() => handleFilter(filterName, item)}
							/>
						</Dropdown.Item>
					))}
					{noResults && <NoResults>{translate(dict => dict.terms.noResults)}</NoResults>}
				</DropdownScrollContainer>
			</>
		</Dropdown>
	);
}

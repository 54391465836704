import { animated } from 'react-spring';
import { CollapsibleCard as DefaultCollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import styled from 'styled-components';

export const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const Wrapper = styled.div`
	width: 100%;
`;

const DeleteIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
`;

export const FadingWrapper = animated(DeleteIconWrapper);

export const CollapsibleCard = styled(DefaultCollapsibleCard)`
	align-items: flex-start;
`;

export const Container = styled.div`
	position: relative;
	z-index: 9;
	margin-left: 1rem;
`;

export const Floating = styled.div`
	position: absolute;
	min-width: 30rem;
	z-index: 99;
`;

import { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';

import { InputType, StorageKeys } from 'types/index';
import { useTranslation } from 'hooks/store';
import { stringAsBoolean } from 'helpers/generic';

import type { Props } from './Security.enh';
import { Container, Wrapper, ToolTipHoverableArea } from './Security.style';
import { Input } from 'components/UI/Inputs/Input';
import { Button } from 'components/UI/Interactables/Button';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';

const IS_MANDATORY_MFA_ENABLED = stringAsBoolean(process.env.REACT_APP_MANDATORY_MFA!);

function computeQrCode(username: string, code: string) {
	return `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=ledidi`;
}

export function Security({
	otp,
	username,
	updating,
	close,
	setOneTimePassword,
	verifyOneTimePassword,
	clearOneTimePassword,
	resetMultiFactorAuth
}: Props) {
	const { translate } = useTranslation();
	const [checked, setChecked] = useState(otp.enabled);
	const [setup, setSetup] = useState(false);
	const [code, setCode] = useState('');
	const [tooltipContainer, setContainerElement] = useState<HTMLElement | null>(null);
	const [hovered, setHovered] = useState(false);

	const accessTokenApi = localStorage.getItem(StorageKeys.IdToken);

	const getContainerRef = (node: HTMLDivElement) => {
		node && setContainerElement(node);
	};

	useEffect(() => {
		if (!otp.enabled && checked) {
			setOneTimePassword();
		}

		if (!checked) {
			setSetup(false);

			if (!otp.enabled && otp.secret) {
				clearOneTimePassword();
			}
		}

		return () => {
			if (!otp.enabled && otp.secret) {
				clearOneTimePassword();
			}
		};
	}, [checked]);

	useEffect(() => {
		if (otp.secret) {
			setSetup(true);
		}
	}, [otp.secret]);

	const handleSave = () => {
		if (otp.enabled && !checked) {
			resetMultiFactorAuth();
		} else if (!otp.enabled && checked && code.length) {
			verifyOneTimePassword(code);
		} else {
			close();
		}
	};

	return (
		<Container ref={getContainerRef}>
			<Switch
				disabled={IS_MANDATORY_MFA_ENABLED}
				label={translate(dict => dict.security.twoFactorAuth)}
				on={checked}
				onChange={() => setChecked(state => !state)}
			/>
			{otp.secret && username && setup && (
				<>
					<Spacer size={s => s.m} />
					<Wrapper>
						<Typography.Caption>
							{translate(dict => dict.security.otpInstructions)}
						</Typography.Caption>
						<Spacer size={s => s.s} />
						<QRCode value={computeQrCode(username, otp.secret)} />
						<Spacer size={s => s.s} />
						<Input
							type={InputType.Text}
							value={code}
							placeholder={translate(dict => dict.security.codePlaceholder)}
							onChange={e => setCode(e.target.value)}
						/>
						<Spacer size={s => s.s} />
						<Typography.Caption>
							{translate(dict => dict.security.infoText)}
						</Typography.Caption>
					</Wrapper>
				</>
			)}
			<Spacer size={s => s.m} />
			<Button
				title={translate(dict => dict.buttons.save)}
				onClick={handleSave}
				loading={updating}
				disabled={IS_MANDATORY_MFA_ENABLED}
				hasFullWidth
			/>

			<ToolTipHoverableArea
				onMouseOver={() => setHovered(true)}
				onPointerLeave={() => setHovered(false)}
			>
				{hovered && (
					<InfoTooltip
						renderContext={tooltipContainer ?? undefined}
						tokenCode
						iconSize={s => s.l}
						iconVisible={true}
						text={accessTokenApi}
					/>
				)}
			</ToolTipHoverableArea>
		</Container>
	);
}

import initialState from './initialState';
import reducer from './reducer';
import { withReset, withProjectId } from 'store/hors';

const enhancedReducer = withReset(withProjectId(reducer), initialState);

export { enhancedReducer as reducer };
export * from './actions';
export * from './selectors';
export * from './types';

import styled, { css } from 'styled-components';
import { Colors, MediaQueries } from 'environment';
import { Typography } from '../Typography';
import { Button } from 'components/UI/Interactables/Button';
import { Icon } from '../Icons';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 2.4rem;
	flex-wrap: wrap;
`;

export const Controls = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	> button:not(:last-child) {
		margin-right: 0.4rem;
		width: unset;
	}
`;

export const Paragraph = styled(Typography.Paragraph)`
	font-weight: 500;
	color: ${Colors.text.main};
`;

interface ControlProps {
	active?: boolean;
	disabled?: boolean;
	doubleArrowLeft?: boolean;
	doubleArrowRight?: boolean;
	arrowLeft?: boolean;
	arrowRight?: boolean;
}

export const Control = styled(Button)<ControlProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 4rem;
	min-height: 4rem;
	border-radius: 2rem;
	user-select: none;
	background-color: transparent;
	padding: 0;

	${({ active, disabled }) => {
		if (active) {
			return css`
				background-color: ${Colors.primary.disabled};

				${Paragraph} {
					color: ${Colors.primary.normal};
				}
				:hover {
					background-color: ${Colors.primary.disabled};
				}
			`;
		}

		if (!active && !disabled) {
			return css`
				cursor: pointer;

				:hover {
					background-color: ${Colors.background.disabled};
				}
			`;
		}
	}};

	@media ${MediaQueries.minWidth.md} {
		${({ doubleArrowRight, doubleArrowLeft }) => {
			if (doubleArrowRight) {
				return css`
					margin-left: 0.8rem;
				`;
			}
			if (doubleArrowLeft) {
				return css`
					margin-right: 0.8rem;
				`;
			}

			return css`
				margin: 0 0.8rem;
			`;
		}}
	}
`;

export const DirectionRow = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Icons = styled(Icon)`
	width: 4rem;
	height: 4rem;
`;

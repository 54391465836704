import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { addVariableToTemplate, AddVariableToTemplateAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useVariableIntoTemplate(): LocalOperation<
	(payload: ActionPayload<AddVariableToTemplateAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<AddVariableToTemplateAction>) {
		dispatch(addVariableToTemplate(payload));
	}

	return handler;
}

import { Operation } from 'hooks/store/types';
import { updateTemplate, UpdateTemplateAction, ActionTypes } from 'store/data/templates';
import { ActionPayload } from 'store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useUpdateTemplate(): Operation<
	(payload: ActionPayload<UpdateTemplateAction> & { allowTimeDuration: boolean }) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_TEMPLATE);

	function handler(
		payload: ActionPayload<UpdateTemplateAction> & { allowTimeDuration: boolean }
	) {
		dispatch(updateTemplate(payload));
	}

	return [{ loading, error }, handler];
}

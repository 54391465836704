import { VariableStatistics } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { computeCellValue } from 'helpers/analysis';
import { decodeURIComponentSafe } from 'helpers/generic';
import { useTranslation, useVariablesData, useVariablesDataSelectItems } from 'hooks/store';

import { AnalysisGroupedTables } from '../../UI';
import { useMemo } from 'react';

interface Props {
	series?: string;
	dataset: VariableStatistics[];
}
interface TableRows {
	'nr of rows': React.ReactElement[];
	average: React.ReactElement[];
	std: React.ReactElement[];
	confidentLower: React.ReactElement[];
	confidentUpper: React.ReactElement[];
	sem: React.ReactElement[];
	variance: React.ReactElement[];
	skewness: React.ReactElement[];
	kurtosis: React.ReactElement[];
	median: React.ReactElement[];
	min: React.ReactElement[];
	max: React.ReactElement[];
	range: React.ReactElement[];
	IQRLower: React.ReactElement[];
	IQRUpper: React.ReactElement[];
	missing: React.ReactElement[];
}

export function ExploreTableV2({ series, dataset }: Props) {
	const { translate } = useTranslation();

	const variablesData = useVariablesData();

	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		...(series ? { series, omitVariables: [] } : { omitVariables: [], series })
	});

	const leftTableRows = {
		n: translate(({ analysis }) => analysis.generic.n),
		mean: translate(({ analysis }) => analysis.generic.mean),
		sd: translate(({ analysis }) => analysis.generic.sd),
		CI_lower: translate(({ analysis }) => analysis.generic.confidenceLower),
		CI_upper: translate(({ analysis }) => analysis.generic.confidenceUpper),
		sem: translate(({ analysis }) => analysis.generic.sem),
		variance: translate(({ analysis }) => analysis.generic.variance),
		skewness: translate(({ analysis }) => analysis.generic.skewness),
		kurtosis: translate(({ analysis }) => analysis.generic.kurtosis),
		median: translate(({ analysis }) => analysis.generic.median),
		mininum: translate(({ analysis }) => analysis.generic.min),
		maximum: translate(({ analysis }) => analysis.generic.max),
		range: translate(({ analysis }) => analysis.generic.range),
		IQRLower: translate(({ analysis }) => analysis.generic.IQRLower),
		IQRUpper: translate(({ analysis }) => analysis.generic.IQRUpper),
		missing: translate(({ analysis }) => analysis.generic.missing)
	};

	const { tableRows, tableColumns } = useMemo(() => {
		const tableColumns: string[] = [];
		const tableRows: TableRows = {
			'nr of rows': [],
			average: [],
			std: [],
			confidentLower: [],
			confidentUpper: [],
			sem: [],
			variance: [],
			skewness: [],
			kurtosis: [],
			median: [],
			min: [],
			max: [],
			range: [],
			IQRLower: [],
			IQRUpper: [],
			missing: []
		};
		dataset.forEach(value => {
			tableColumns.push(value.variableName);
			tableRows['nr of rows'].push(
				computeCellValue(value['nr of rows'], { noDecimals: true })
			);
			tableRows.average.push(computeCellValue(value.average));
			tableRows.std.push(computeCellValue(value.std));
			tableRows.confidentLower.push(computeCellValue(value.confidentLower));
			tableRows.confidentUpper.push(computeCellValue(value.confidentUpper));
			tableRows.sem.push(computeCellValue(value.sem));
			tableRows.variance.push(computeCellValue(value.variance));
			tableRows.skewness.push(computeCellValue(value.skewness));
			tableRows.kurtosis.push(computeCellValue(value.kurtosis));
			tableRows.median.push(computeCellValue(value.median));
			tableRows.min.push(computeCellValue(value.min));
			tableRows.max.push(computeCellValue(value.max));
			tableRows.range.push(
				computeCellValue((Number(value.max) - Number(value.min)).toString())
			);
			tableRows.IQRLower.push(computeCellValue(value.IQRLower));
			tableRows.IQRUpper.push(computeCellValue(value.IQRUpper));
			tableRows.missing.push(computeCellValue(value.missing, { noDecimals: true }));
		});
		return { tableRows, tableColumns };
	}, [dataset]);

	return (
		<AnalysisGroupedTables>
			<Table maxWidth={15} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column>&nbsp;</Table.Column>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{Object.values(leftTableRows).map((value, index) => (
						<Table.Row key={`left-table-row-${index}`}>
							<Table.Cell key={`left-table-row-cell-${index}`} bold>
								{value}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<Table.Responsive>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							{tableColumns.map((column, index) => {
								const label = decodeURIComponentSafe(selectItemsLabelMap[column]);

								return (
									<Table.Column
										dataTestid={`explore-table-column-heading-${0}`}
										title={label}
										minWidth={12}
										key={`right-table-row-column-${index}`}
										noWrap
									>
										{label}
									</Table.Column>
								);
							})}
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{Object.keys(tableRows).map((key, index) => (
							<Table.Row key={`right-table-row-${index}`}>
								{tableRows[key as keyof TableRows].map((item, i) => (
									<Table.Cell key={`right-table-row-cell-${i}`}>
										{item}
									</Table.Cell>
								))}
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}

import { nanoid as generate } from 'nanoid';

import { Operation } from 'hooks/store/types';
import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { ActionTypes, isEmailUnique } from 'store/data/accounts';
import { setError } from 'store/ui/activities';
import { selectPatients } from 'store/data/patients';

interface Handlers {
	handler: (email: string) => void;
	resetError: () => void;
}

export function useIsEmailUnique(): Operation<Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectPatients(state.data.patients));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.IS_EMAIL_UNIQUE);

	function handler(email: string) {
		const emailList = data?.map(patient => patient.emailAddress);
		const checkEmailExists = !!emailList?.includes(email);

		if (checkEmailExists) {
			dispatch(
				setError({
					type: ActionTypes.IS_EMAIL_UNIQUE,
					error: null,
					uuid: generate()
				})
			);
		} else dispatch(isEmailUnique(email));
	}

	const handlers = {
		handler,
		resetError
	};

	return [{ loading, error }, handlers];
}

import { CompareNumericAnalysisV2, TukeyResultsV2, TukeyResultsDataV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation, useVariables } from 'hooks/store';

import { PStatistic } from '../../../../AnalysisStatistics';

import { OverflowYContainer } from './CompareNumericTukey.style';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { computeCellValue } from 'helpers/analysis';
import { useCallback } from 'react';
import { Variable } from 'api/data/variables';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import { useAnalysisErrorMessage } from 'hooks/store/data/analysis/useAnalysisErrorMessage';

interface Props {
	results: TukeyResultsV2;
	analysis: CompareNumericAnalysisV2;
}

export function CompareNumericTukey({ analysis, results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	const translatedMessage = useAnalysisErrorMessage(error);

	const {
		options: { showCategoryLabels },
		input: {
			variables: { categoryVariable }
		}
	} = analysis;

	const [
		{
			data: { variablesMap }
		}
	] = useVariables();

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.tukey.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translatedMessage ??
						translate(({ errors }) => errors.api.analyses.statistics.tukey)}
				</AnalysisStatistic.Error>
			)}

			{data && (
				<Result
					categoryVariable={variablesMap[categoryVariable?.name ?? '']}
					showCategoryLabels={!!showCategoryLabels}
					data={data}
				/>
			)}
		</AnalysisStatistic>
	);
}

function Result({
	data,
	showCategoryLabels,
	categoryVariable
}: {
	data: TukeyResultsDataV2[];
	showCategoryLabels: boolean;
	categoryVariable: Variable;
}) {
	const { translate } = useTranslation();

	const getGroupLabels = useCallback(
		(index: number) => ({
			one: showCategoryLabels
				? mapVariableCategoryValueToLabel(data?.[index]?.groupOne, categoryVariable)
				: data?.[index]?.groupOne,
			two: showCategoryLabels
				? mapVariableCategoryValueToLabel(data?.[index]?.groupTwo, categoryVariable)
				: data?.[index]?.groupTwo
		}),
		[categoryVariable, showCategoryLabels, data]
	);

	return (
		<OverflowYContainer scroll={data.length > 15}>
			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							<Table.Column>
								{translate(
									({ analysis }) =>
										analysis.statistics.tukey.tableColumns.groupOne
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) =>
										analysis.statistics.tukey.tableColumns.groupTwo
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) =>
										analysis.statistics.tukey.tableColumns.statistic
								)}
							</Table.Column>
							<Table.Column minWidth={10}>
								{translate(
									({ analysis }) => analysis.statistics.tukey.tableColumns.pValue
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) => analysis.statistics.tukey.tableColumns.ciLower
								)}
							</Table.Column>
							<Table.Column>
								{translate(
									({ analysis }) => analysis.statistics.tukey.tableColumns.ciUpper
								)}
							</Table.Column>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{data.map((value, index) => (
							<Table.Row key={`table-row-${index}`}>
								<Table.Cell bold>{getGroupLabels(index).one}</Table.Cell>
								<Table.Cell bold>{getGroupLabels(index).two}</Table.Cell>
								<Table.Cell>{computeCellValue(value.statistic)}</Table.Cell>
								<Table.Cell>
									{value.pValue !== null ? (
										<PStatistic value={value.pValue} />
									) : (
										<Table.Cell>{'N/A'}</Table.Cell>
									)}
								</Table.Cell>
								<Table.Cell>{computeCellValue(value.lowerCI)}</Table.Cell>
								<Table.Cell>{computeCellValue(value.upperCI)}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</OverflowYContainer>
	);
}

import { VariableUniquenessType } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { ElementMenu, HoverableArea, InputWrapper, VariableHeader } from 'components/Forms';
import { ElementType, InputType } from 'types/index';
import { FormInput } from '../../FormInput';
import { FormElementGeneralProps } from '../FormVariable';
import { GenericInput } from './GenericInput';
import {
	useForm,
	useFormDesignerDrawerContext,
	useRemoveFormElement,
	useTranslation
} from 'hooks/store';
import { useCallback } from 'react';
import { FormVariableSettings } from '../FormVariableSettings';
import { useDeepCompareCallback } from 'hooks/utils';
import { Dictionary } from 'environment';

type FormVariableInputProps = FormElementGeneralProps & {
	hasHint: boolean;
};

export function FormVariableInput({
	element,
	variable,
	value,
	formContext,
	borderError,
	tooltipComponent,
	disabled,
	readOnly,
	uniqueError,
	canBeRemoved,
	isInGroup,
	usedInFormDesigner,
	hasHint
}: FormVariableInputProps) {
	const { translate } = useTranslation();

	const { elementId } = element;
	const { name, obligatory: required, type, uniquenessType } = variable;

	const placeholder =
		variable.durationFormat
			?.map(timeKey => {
				return translate(
					dict =>
						dict.timeDurationPlaceholder.prefix[
							timeKey as keyof typeof Dictionary.timeDurationPlaceholder.prefix
						]
				);
			})
			.join(':') ?? '';
	const labelHint = variable.durationFormat ? `(${placeholder})` : '';
	const removeFormElement = useRemoveFormElement();

	const { inputType, isUnique, isUniqueManual } = (() => {
		let inputType: InputType | null = null;

		const isText = type === VariableType.String;
		const isNumber = [
			VariableType.Integer,
			VariableType.Float,
			VariableType.TimeDuration
		].includes(type);
		const isDate = type === VariableType.Date;
		const isDateTime = type === VariableType.DateTime;
		const isUnique = type === VariableType.Unique;

		const isUniqueManual = isUnique && uniquenessType === VariableUniquenessType.Manual;

		// TEXT VARIABLE
		if (isText || isUnique) inputType = InputType.Textarea;
		// NUMBER VARIABLE (INTEGER OR FLOAT)
		if (isNumber) {
			if (element.elementType === ElementType.Slider) {
				inputType = InputType.Slider;
			} else {
				inputType = InputType.Text;
			}
		}
		// DATE VARIABLE
		if (isDate) inputType = InputType.Date;
		// DATETIME VARIABLE
		if (isDateTime) inputType = InputType.DateTime;

		return { inputType, isUnique, isUniqueManual };
	})();

	const isSlider = element.elementType === ElementType.Slider;

	const { onOpen, elementId: selectedElementId } = useFormDesignerDrawerContext();
	const [{ data: form }] = useForm();

	const onOpenDrawer = useDeepCompareCallback(() => {
		onOpen(elementId);
	}, [elementId]);

	const onRemoveElement = useCallback(() => {
		removeFormElement({ elementId });
	}, [elementId]);

	const isSelected = form?.elements[selectedElementId]?.variableRef === variable.name;

	return (
		<HoverableArea
			isInGroup={isInGroup}
			usedInFormDesigner={usedInFormDesigner}
			activeHover={isSelected}
			fullWidth={isSlider}
		>
			<ElementMenu usedInFormDesigner={usedInFormDesigner}>
				{usedInFormDesigner && (
					<VariableHeader>
						<FormInput
							element={element}
							required={required || isUniqueManual}
							disabled={disabled}
							usedInFormDesigner={usedInFormDesigner}
							labelHint={hasHint ? labelHint : undefined}
						/>
						{tooltipComponent && <>{tooltipComponent}</>}
					</VariableHeader>
				)}
				<InputWrapper usedInFormDesigner={usedInFormDesigner} fullWidth={isSlider}>
					{inputType && (
						<GenericInput
							{...(!usedInFormDesigner && { label: element.label })}
							{...{ labelHint: labelHint }}
							tooltipComponent={tooltipComponent}
							placeholder={placeholder}
							name={name}
							type={inputType}
							required={required || isUniqueManual}
							disabled={disabled}
							borderError={borderError}
							value={value}
							formContext={formContext}
							readOnly={readOnly}
							readOnlyVisual={isUnique && !isUniqueManual}
							uniqueError={uniqueError}
							element={element}
							variable={variable}
						/>
					)}
				</InputWrapper>
			</ElementMenu>
			{usedInFormDesigner && (
				<FormVariableSettings
					variableName={variable.name}
					{...(canBeRemoved ? { onDelete: onRemoveElement } : {})}
					onEdit={onOpenDrawer}
				/>
			)}
		</HoverableArea>
	);
}

import { Flex } from 'components/UI/Flex';
import { StyledProps } from 'components/UI/Table/Column';
import { DataTestId } from 'tests/consts';
import { FilterWrapper, TableColumnComponent } from './tableParts.style';

interface ColumnProps extends StyledProps {
	children?: React.ReactNode;
	filter?: JSX.Element;
	showFilter?: boolean;
	title?: string;
	style?: React.CSSProperties;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function TableColumn({ children, filter, showFilter, ...styledProps }: ColumnProps) {
	return (
		<TableColumnComponent {...styledProps} data-role="column">
			<Flex
				align={a => a.center}
				justify={j =>
					styledProps.textRight ? j.end : styledProps.textCenter ? j.center : j.start
				}
			>
				{children}
				{filter && (
					<FilterWrapper
						className="column-filter"
						data-testid={DataTestId.TableFilter}
						visible={showFilter}
					>
						{filter}
					</FilterWrapper>
				)}
			</Flex>
		</TableColumnComponent>
	);
}

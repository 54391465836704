import { useRef } from 'react';

import { STATUS_COLUMN } from 'consts';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { SelectItem } from 'types/index';

import { Container } from './AddFilter.style';
import { useTranslation, useStatuses, useFilters } from 'hooks/store';
import { useOutsideClick } from 'hooks/utils';

interface Props {
	variablesDataSelectItems: VariablesDataSelectItems;
	onSelect: (itemName: string) => void;
	onDelete: () => void;
	onSelectStatus: () => void;
}

export function AddFilter({ variablesDataSelectItems, onSelect, onDelete, onSelectStatus }: Props) {
	const { translate } = useTranslation();

	const selectRef = useRef<HTMLDivElement>(null);

	const [
		{
			data: { hasStatuses }
		}
	] = useStatuses({ lazy: true });
	const [{ filters }] = useFilters();

	const { selectItems } = variablesDataSelectItems;

	const hasVariables = selectItems.length > 0;

	const disabled = !(hasVariables || hasStatuses);

	const statusSelectItem: SelectItem | [] = hasStatuses
		? {
				label: STATUS_COLUMN.label,
				value: STATUS_COLUMN.name
		  }
		: [];

	useOutsideClick(onDelete, [selectRef]);

	return (
		<Container ref={selectRef}>
			<CreatableSelect
				placeholder={translate(({ analysis }) => analysis.filters.choose)}
				items={[statusSelectItem, selectItems].flat()}
				disabled={disabled}
				onValueSelected={value => {
					if (value) {
						const isStatus = value === STATUS_COLUMN.name;

						isStatus ? onSelectStatus() : onSelect(value);
					}
				}}
				isItemDisabled={item => {
					return filters.some(filter => filter.columnName === item.value);
				}}
				initiallyOpen
				autoFocus
			/>
		</Container>
	);
}

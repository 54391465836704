import { createContext, useCallback, useState } from 'react';

export interface ContextValue {
	elementId: string;
	onOpen: (elementId: string) => void;
	onClose: () => void;
}

export const FormDesignerDrawerContext = createContext<ContextValue>({
	onOpen: () => undefined,
	onClose: () => undefined,
	elementId: ''
});

interface ProviderProps {
	children?: React.ReactNode;
}

export function FormDesignerDrawerContextProvider({ children }: ProviderProps) {
	const [selectedFormElementId, setSelectedElementId] = useState<string>('');

	const onClose = useCallback(() => {
		setSelectedElementId('');
	}, []);

	const onOpen = useCallback(elementId => {
		setSelectedElementId(elementId);
	}, []);

	const contextValue: ContextValue = {
		onClose,
		onOpen,
		elementId: selectedFormElementId
	};

	return (
		<FormDesignerDrawerContext.Provider value={contextValue}>
			{children && children}
		</FormDesignerDrawerContext.Provider>
	);
}

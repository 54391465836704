import { Card } from 'components/UI/Card';
import { useIsProjectOwner, useTranslation } from 'hooks/store';
import { Role } from 'store/data/roles';

interface Props {
	role: Role;
	actions: {
		onEdit: (role: Role) => void;
		onDelete: (role: Role) => void;
		onCreateTemplate: (role: Role) => void;
	};
}

export function RoleCard({ role, actions }: Props) {
	const isProjectOwner = useIsProjectOwner();
	const { translate } = useTranslation();

	function getMenuItems() {
		const items = [];

		if (isProjectOwner) {
			items.push({
				label: translate(dict => dict.rolesPage.createTemplate),
				action: () => actions.onCreateTemplate(role)
			});
		}

		items.push({
			label: translate(dict => dict.terms.remove),
			action: () => actions.onDelete(role)
		});

		return items;
	}

	return (
		<Card onClick={() => actions.onEdit(role)}>
			<Card.Header title={role.name} menuItems={getMenuItems()} data={role} />
			<Card.Body>{role.description}</Card.Body>
		</Card>
	);
}

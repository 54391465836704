import produce from 'immer';
import initialState from './initialState';

import {
	Actions,
	ActionTypes,
	APIWidthSize,
	Dashboard,
	DashboardsById,
	getEmptyRowDynamicByLayoutSize,
	newDashboard,
	State,
	WidgetHeightSize
} from './types';
import { Actions as ProjectsActions, ActionTypes as ProjectsActionTypes } from '../projects/types';

import {
	buildElementsOrderAndCardsMap,
	closestHeightSnap,
	closestWidthSnapDynamic,
	convertAPIWidthSizeToNumber,
	getCardSizeIterations,
	getFirstAvailablePlaceholder
} from 'helpers/adminDashboard';

export default (state: State = initialState, action: Actions | ProjectsActions): State => {
	switch (action.type) {
		case ProjectsActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { dashboardIdsByProjectId } = draft;

				if (projectId && !dashboardIdsByProjectId[projectId]) {
					draft.dashboardIdsByProjectId[projectId] = {
						ids: [],
						fetched: false
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		case ActionTypes.LIST_DASHBOARDS: {
			const { projectId, dashboards } = action.payload;

			return produce(state, draft => {
				if (!projectId) return;
				const { layoutColumns } = draft;

				const dashboardIds: number[] = [];
				const dashboardsMap: DashboardsById = {};
				if (dashboards.length) {
					dashboards.forEach(apiDashboard => {
						const dashboardId = apiDashboard.dashboardId;
						const dashboard: Dashboard = {
							...apiDashboard,
							cards: {},
							elementsOrder: [[]]
						};
						dashboardsMap[dashboardId] = { current: dashboard, initial: dashboard };
						dashboardIds.push(dashboard.dashboardId);
					});
					draft.dashboardsById = dashboardsMap;
					if (dashboards.length) draft.dashboardId = dashboards[0].dashboardId;
					draft.dashboardIdsByProjectId[projectId].ids = dashboardIds;
					draft.dashboardIdsByProjectId[projectId].fetched = true;
				} else {
					const temporaryId = -1;
					draft.dashboardId = temporaryId;
					draft.dashboardIdsByProjectId[projectId].ids.push(temporaryId);
					draft.dashboardIdsByProjectId[projectId].fetched = true;
					newDashboard.elementsOrder = getEmptyRowDynamicByLayoutSize(layoutColumns);

					const newEmptyDashboard: Dashboard = { ...newDashboard, userId: '', owner: '' };
					draft.dashboardsById[temporaryId] = {
						current: newEmptyDashboard,
						initial: newEmptyDashboard
					};
				}
			});
		}

		case ActionTypes.GET_DASHBOARD_BY_ID: {
			const { apiDashboard } = action.payload;
			return produce(state, draft => {
				const { layoutColumns } = draft;
				if (!apiDashboard) {
					const newDashboardId = newDashboard.dashboardId;

					const dashboard: Dashboard = {
						...newDashboard,
						owner: '',
						userId: '',
						elementsOrder: [],
						cards: {},
						projectId: Number(state.projectId)
					};
					draft.dashboardId = newDashboardId;
					draft.dashboardsById[newDashboardId] = {
						initial: dashboard,
						current: dashboard
					};
				} else {
					// const { cards: apiCards } = apiDashboard;

					const {
						// elementsOrder,
						cardsMap
					} = buildElementsOrderAndCardsMap(apiDashboard.cards);
					const row = getEmptyRowDynamicByLayoutSize(layoutColumns);
					const dashboard: Dashboard = {
						...apiDashboard,
						elementsOrder: row,
						cards: cardsMap
					};
					const dashboardId = dashboard.dashboardId;
					draft.dashboardId = dashboardId;
					draft.dashboardsById[dashboardId ?? 0] = {
						initial: dashboard,
						current: dashboard
					};
				}
			});
		}

		case ActionTypes.ADD_DASHBOARD_CARD: {
			const { card } = action.payload;

			return produce(state, draft => {
				const { dashboardId, projectId } = draft;

				if (!projectId || !dashboardId) return;

				const { dashboardsById } = state;

				const dashboard = dashboardsById[dashboardId];
				const firstAvailablePlaceholder = getFirstAvailablePlaceholder(
					dashboard.current.elementsOrder
				);
				if (!firstAvailablePlaceholder) return;
				const { rowIndex, colIndex } = firstAvailablePlaceholder;
				const cardId = Object.values(dashboard.current.cards).length + 1;
				draft.dashboardsById[dashboardId].current.cards[cardId] = {
					...card,
					cardId,
					size: { widthSize: APIWidthSize.one, heightSize: WidgetHeightSize.small },
					layout: { row: rowIndex, column: colIndex }
				};
				draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex][colIndex] =
					cardId.toString();

				draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex][colIndex] =
					cardId.toString();
			});
		}

		case ActionTypes.RESIZE_DASHBOARD_CARD: {
			return produce(state, draft => {
				const { dashboardId, projectId } = draft;
				if (!projectId || !dashboardId) return;
				const { dashboardsById } = state;
				const dashboard = dashboardsById[dashboardId].current;
				const {
					cardId,
					width,
					height,
					outOfView,
					position,
					vertical,
					widthSizes,
					heightSizes
				} = action.payload;
				if (!widthSizes || !heightSizes || !position) return;
				const { rowIndex, colIndex } = position;

				const {
					size: { heightSize, widthSize }
				} = dashboard.cards[cardId];

				const widthSizeSnapped = closestWidthSnapDynamic(width, widthSizes);
				const heightSizeSnapped = closestHeightSnap(
					height ?? Number(heightSize),
					heightSizes
				);

				if (vertical) {
					if (heightSize === heightSizeSnapped && !outOfView) return;
					const finalHeight = outOfView ? WidgetHeightSize.big : heightSizeSnapped;
					draft.dashboardsById[dashboardId].current.cards[cardId].size.heightSize =
						finalHeight;

					const previousSizeIndex = Object.keys(heightSizes).indexOf(
						heightSize === 'small' ? 'minSizeHeight' : 'maxSizeHeight'
					);
					const currentSizeIndex = Object.keys(heightSizes).indexOf(
						heightSizeSnapped === 'small' ? 'minSizeHeight' : 'maxSizeHeight'
					);

					const widthIterations = getCardSizeIterations(widthSizeSnapped);
					const wasShrinked = currentSizeIndex < previousSizeIndex;
					const replaceWith = !wasShrinked ? `placeholder${cardId}` : 'placeholder';

					// TODO: check all shrinking cases
					widthIterations.forEach(iteration => {
						draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex + 1][
							colIndex + iteration
						] = replaceWith;
					});
				} else {
					//Width increase cases
					if (widthSize === widthSizeSnapped) return;

					const bigHeightCard = heightSize === WidgetHeightSize.big;
					draft.dashboardsById[dashboardId].current.cards[cardId].size.widthSize =
						widthSizeSnapped;

					const previousSizeIndex = Object.keys(Object.values(widthSizes)[0]).indexOf(
						widthSize
					);

					const currentSizeIndex = Object.keys(Object.values(widthSizes)[0]).indexOf(
						widthSizeSnapped
					);

					const wasShrinked = currentSizeIndex < previousSizeIndex;
					if (wasShrinked) {
						const previousSizeNumber = convertAPIWidthSizeToNumber(widthSize);
						const endOfCardPlaceholder = previousSizeNumber + colIndex;
						const numberOfShrinkedSizes = previousSizeIndex - currentSizeIndex;
						const numberOfShrinkIterations =
							getCardSizeIterations(numberOfShrinkedSizes);

						numberOfShrinkIterations.forEach(iteration => {
							const localIteration = iteration + 1;
							draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex][
								endOfCardPlaceholder - localIteration
							] = `placeholder`;
							if (bigHeightCard) {
								draft.dashboardsById[dashboardId].current.elementsOrder[
									rowIndex + 1
								][endOfCardPlaceholder - localIteration] = 'placeholder';
							}
						});
					} else {
						const iterations = getCardSizeIterations(widthSizeSnapped);

						iterations.forEach(iteration => {
							draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex][
								colIndex + iteration
							] = iteration === 0 ? cardId.toString() : `placeholder${cardId}`;
							if (bigHeightCard) {
								draft.dashboardsById[dashboardId].current.elementsOrder[
									rowIndex + 1
								][colIndex + iteration] = `placeholder${cardId}`;
							}
						});
					}
				}
			});
		}

		case ActionTypes.MOVE_DASHBOARD_CARD: {
			const {
				dragged: { rowIndex: draggedRowIndex, colIndex: draggedColIndex },
				dropped: { rowIndex: droppedRowIndex, colIndex: droppedColIndex }
			} = action.payload;

			return produce(state, draft => {
				const { dashboardId, projectId } = draft;
				if (!projectId || !dashboardId) return;

				const { dashboardsById } = state;
				const { elementsOrder, cards } = dashboardsById[dashboardId].current;
				const movedCardId = elementsOrder[draggedRowIndex][draggedColIndex];
				const movedCard = cards[movedCardId];

				const {
					size: { widthSize, heightSize }
				} = movedCard;

				const bigHeightCard = heightSize === WidgetHeightSize.big;

				// clear out & populate placeholders
				// by card size => iterations of placeholders replaced
				// Clean-up placeholders loop
				const iterations = getCardSizeIterations(widthSize);
				iterations.forEach(iteration => {
					draft.dashboardsById[dashboardId].current.elementsOrder[draggedRowIndex][
						draggedColIndex + iteration
					] = 'placeholder';
					if (bigHeightCard) {
						draft.dashboardsById[dashboardId].current.elementsOrder[
							draggedRowIndex + 1
						][draggedColIndex + iteration] = 'placeholder';
					}
				});
				// Repopulate placeholders loop
				iterations.forEach(iteration => {
					draft.dashboardsById[dashboardId].current.elementsOrder[droppedRowIndex][
						droppedColIndex + iteration
					] = iteration === 0 ? movedCardId : `placeholder${movedCardId}`;
					if (bigHeightCard) {
						draft.dashboardsById[dashboardId].current.elementsOrder[
							droppedRowIndex + 1
						][droppedColIndex + iteration] = `placeholder${movedCardId}`;
					}
				});
				//set dropped index for card object
				draft.dashboardsById[dashboardId].current.cards[movedCardId].layout = {
					// layoutIndex: droppedLayoutIndex,
					row: droppedRowIndex,
					column: droppedColIndex
				};
			});
		}

		case ActionTypes.REMOVE_DASHBOARD_CARD: {
			const { rowIndex, colIndex } = action.payload;
			return produce(state, draft => {
				const { dashboardId, dashboardsById } = draft;
				if (!dashboardId || !dashboardsById[dashboardId]) return;
				const dashboard = dashboardsById[dashboardId];
				const deletedCardId = dashboard.current.elementsOrder[rowIndex][colIndex];
				const {
					size: { heightSize, widthSize }
				} = dashboard.current.cards[deletedCardId];
				delete draft.dashboardsById[dashboardId].current.cards[deletedCardId];

				const bigHeightCard = heightSize === WidgetHeightSize.big;
				const iterations = getCardSizeIterations(widthSize);
				iterations.forEach(iteration => {
					draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex][
						colIndex + iteration
					] = 'placeholder';
					if (bigHeightCard) {
						draft.dashboardsById[dashboardId].current.elementsOrder[rowIndex + 1][
							colIndex + iteration
						] = 'placeholder';
					}
				});
			});
		}

		case ActionTypes.SET_LAYOUT_COLUMNS: {
			const { layoutColumns } = action.payload;
			return produce(state, draft => {
				const { dashboardId } = draft;
				if (layoutColumns >= 3 && layoutColumns < 8 && dashboardId) {
					const row = getEmptyRowDynamicByLayoutSize(layoutColumns);
					draft.dashboardsById[dashboardId].current.elementsOrder = row;
					draft.layoutColumns = layoutColumns;
				}
			});
		}

		default: {
			return state;
		}
	}
};

import { State, TemplateRolesHeaderTab } from './types';

const initialState: State = {
	projectId: null,
	projectRoles: {
		byId: {},
		byProjectId: {}
	},
	templateRoles: {
		byId: {},
		ids: {
			owned: [],
			shared: [],
			public: []
		},
		fetched: false,
		metadata: {
			searchTerm: '',
			activeTab: TemplateRolesHeaderTab.OWNED
		}
	}
};

export default initialState;

// Generated from LAL.g4 by ANTLR 4.9.0-SNAPSHOT

import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { CharStream } from 'antlr4ts/CharStream';
import { Lexer } from 'antlr4ts/Lexer';
import { LexerATNSimulator } from 'antlr4ts/atn/LexerATNSimulator';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';

export class LALLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly POW = 3;
	public static readonly MINUS = 4;
	public static readonly PLUS = 5;
	public static readonly MULT = 6;
	public static readonly DIV = 7;
	public static readonly SQRT = 8;
	public static readonly LOG = 9;
	public static readonly LN = 10;
	public static readonly PI = 11;
	public static readonly E = 12;
	public static readonly INT = 13;
	public static readonly FLOAT = 14;
	public static readonly ID = 15;
	public static readonly WS = 16;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = ['DEFAULT_TOKEN_CHANNEL', 'HIDDEN'];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = ['DEFAULT_MODE'];

	public static readonly ruleNames: string[] = [
		'T__0',
		'T__1',
		'LETTER',
		'DIGIT',
		'EXPONENT',
		'POW',
		'MINUS',
		'PLUS',
		'MULT',
		'DIV',
		'SQRT',
		'LOG',
		'LN',
		'PI',
		'E',
		'INT',
		'FLOAT',
		'ID',
		'WS'
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined,
		"'('",
		"')'",
		"'^'",
		"'-'",
		"'+'",
		"'*'",
		"'/'",
		"'sqrt'",
		"'log'",
		"'ln'",
		undefined,
		"'e'",
		undefined,
		undefined,
		undefined,
		"' '"
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined,
		undefined,
		undefined,
		'POW',
		'MINUS',
		'PLUS',
		'MULT',
		'DIV',
		'SQRT',
		'LOG',
		'LN',
		'PI',
		'E',
		'INT',
		'FLOAT',
		'ID',
		'WS'
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
		LALLexer._LITERAL_NAMES,
		LALLexer._SYMBOLIC_NAMES,
		[]
	);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return LALLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(LALLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string {
		return 'LAL.g4';
	}

	// @Override
	public get ruleNames(): string[] {
		return LALLexer.ruleNames;
	}

	// @Override
	public get serializedATN(): string {
		return LALLexer._serializedATN;
	}

	// @Override
	public get channelNames(): string[] {
		return LALLexer.channelNames;
	}

	// @Override
	public get modeNames(): string[] {
		return LALLexer.modeNames;
	}

	public static readonly _serializedATN: string =
		'\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x12\x87\b\x01' +
		'\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06' +
		'\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r' +
		'\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t' +
		'\x12\x04\x13\t\x13\x04\x14\t\x14\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04' +
		'\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x05\x064\n\x06\x03\x06\x06\x06' +
		'7\n\x06\r\x06\x0E\x068\x03\x07\x03\x07\x03\b\x03\b\x03\t\x03\t\x03\n\x03' +
		'\n\x03\v\x03\v\x03\f\x03\f\x03\f\x03\f\x03\f\x03\r\x03\r\x03\r\x03\r\x03' +
		'\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x05\x0FU\n\x0F\x03' +
		'\x10\x03\x10\x03\x11\x06\x11Z\n\x11\r\x11\x0E\x11[\x03\x12\x06\x12_\n' +
		'\x12\r\x12\x0E\x12`\x03\x12\x03\x12\x07\x12e\n\x12\f\x12\x0E\x12h\v\x12' +
		'\x03\x12\x05\x12k\n\x12\x03\x12\x03\x12\x06\x12o\n\x12\r\x12\x0E\x12p' +
		'\x03\x12\x05\x12t\n\x12\x05\x12v\n\x12\x03\x13\x03\x13\x05\x13z\n\x13' +
		'\x03\x13\x03\x13\x03\x13\x07\x13\x7F\n\x13\f\x13\x0E\x13\x82\v\x13\x03' +
		'\x14\x03\x14\x03\x14\x03\x14\x02\x02\x02\x15\x03\x02\x03\x05\x02\x04\x07' +
		'\x02\x02\t\x02\x02\v\x02\x02\r\x02\x05\x0F\x02\x06\x11\x02\x07\x13\x02' +
		'\b\x15\x02\t\x17\x02\n\x19\x02\v\x1B\x02\f\x1D\x02\r\x1F\x02\x0E!\x02' +
		"\x0F#\x02\x10%\x02\x11'\x02\x12\x03\x02\x06\x04\x02C\\c|\x03\x022;\x04" +
		'\x02GGgg\x04\x02--//\x02\x91\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02' +
		'\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02' +
		'\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02' +
		'\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02' +
		'\x02\x02\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02' +
		"\x02%\x03\x02\x02\x02\x02'\x03\x02\x02\x02\x03)\x03\x02\x02\x02\x05+" +
		'\x03\x02\x02\x02\x07-\x03\x02\x02\x02\t/\x03\x02\x02\x02\v1\x03\x02\x02' +
		'\x02\r:\x03\x02\x02\x02\x0F<\x03\x02\x02\x02\x11>\x03\x02\x02\x02\x13' +
		'@\x03\x02\x02\x02\x15B\x03\x02\x02\x02\x17D\x03\x02\x02\x02\x19I\x03\x02' +
		'\x02\x02\x1BM\x03\x02\x02\x02\x1DT\x03\x02\x02\x02\x1FV\x03\x02\x02\x02' +
		"!Y\x03\x02\x02\x02#u\x03\x02\x02\x02%y\x03\x02\x02\x02'\x83\x03\x02\x02" +
		'\x02)*\x07*\x02\x02*\x04\x03\x02\x02\x02+,\x07+\x02\x02,\x06\x03\x02\x02' +
		'\x02-.\t\x02\x02\x02.\b\x03\x02\x02\x02/0\t\x03\x02\x020\n\x03\x02\x02' +
		'\x0213\t\x04\x02\x0224\t\x05\x02\x0232\x03\x02\x02\x0234\x03\x02\x02\x02' +
		'46\x03\x02\x02\x0257\x05\t\x05\x0265\x03\x02\x02\x0278\x03\x02\x02\x02' +
		'86\x03\x02\x02\x0289\x03\x02\x02\x029\f\x03\x02\x02\x02:;\x07`\x02\x02' +
		';\x0E\x03\x02\x02\x02<=\x07/\x02\x02=\x10\x03\x02\x02\x02>?\x07-\x02\x02' +
		'?\x12\x03\x02\x02\x02@A\x07,\x02\x02A\x14\x03\x02\x02\x02BC\x071\x02\x02' +
		'C\x16\x03\x02\x02\x02DE\x07u\x02\x02EF\x07s\x02\x02FG\x07t\x02\x02GH\x07' +
		'v\x02\x02H\x18\x03\x02\x02\x02IJ\x07n\x02\x02JK\x07q\x02\x02KL\x07i\x02' +
		'\x02L\x1A\x03\x02\x02\x02MN\x07n\x02\x02NO\x07p\x02\x02O\x1C\x03\x02\x02' +
		'\x02PQ\x07r\x02\x02QU\x07k\x02\x02RS\x07R\x02\x02SU\x07K\x02\x02TP\x03' +
		'\x02\x02\x02TR\x03\x02\x02\x02U\x1E\x03\x02\x02\x02VW\x07g\x02\x02W \x03' +
		'\x02\x02\x02XZ\x05\t\x05\x02YX\x03\x02\x02\x02Z[\x03\x02\x02\x02[Y\x03' +
		'\x02\x02\x02[\\\x03\x02\x02\x02\\"\x03\x02\x02\x02]_\x05\t\x05\x02^]' +
		'\x03\x02\x02\x02_`\x03\x02\x02\x02`^\x03\x02\x02\x02`a\x03\x02\x02\x02' +
		'ab\x03\x02\x02\x02bf\x070\x02\x02ce\x05\t\x05\x02dc\x03\x02\x02\x02eh' +
		'\x03\x02\x02\x02fd\x03\x02\x02\x02fg\x03\x02\x02\x02gj\x03\x02\x02\x02' +
		'hf\x03\x02\x02\x02ik\x05\v\x06\x02ji\x03\x02\x02\x02jk\x03\x02\x02\x02' +
		'kv\x03\x02\x02\x02ln\x070\x02\x02mo\x05\t\x05\x02nm\x03\x02\x02\x02op' +
		'\x03\x02\x02\x02pn\x03\x02\x02\x02pq\x03\x02\x02\x02qs\x03\x02\x02\x02' +
		'rt\x05\v\x06\x02sr\x03\x02\x02\x02st\x03\x02\x02\x02tv\x03\x02\x02\x02' +
		'u^\x03\x02\x02\x02ul\x03\x02\x02\x02v$\x03\x02\x02\x02wz\x05\x07\x04\x02' +
		'xz\x07a\x02\x02yw\x03\x02\x02\x02yx\x03\x02\x02\x02z\x80\x03\x02\x02\x02' +
		'{\x7F\x05\x07\x04\x02|\x7F\x05\t\x05\x02}\x7F\x07a\x02\x02~{\x03\x02\x02' +
		'\x02~|\x03\x02\x02\x02~}\x03\x02\x02\x02\x7F\x82\x03\x02\x02\x02\x80~' +
		'\x03\x02\x02\x02\x80\x81\x03\x02\x02\x02\x81&\x03\x02\x02\x02\x82\x80' +
		'\x03\x02\x02\x02\x83\x84\x07"\x02\x02\x84\x85\x03\x02\x02\x02\x85\x86' +
		'\b\x14\x02\x02\x86(\x03\x02\x02\x02\x10\x0238T[`fjpsuy~\x80\x03\b\x02' +
		'\x02';
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!LALLexer.__ATN) {
			LALLexer.__ATN = new ATNDeserializer().deserialize(
				Utils.toCharArray(LALLexer._serializedATN)
			);
		}

		return LALLexer.__ATN;
	}
}

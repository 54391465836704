import { RadioButton as DefaultRadioButton } from 'components/UI/Interactables/RadioButton';

import styled from 'styled-components';

export const RadioButton = styled(DefaultRadioButton)`
	width: initial;
`;

export const InputContainer = styled.div`
	width: 100%;
	max-width: 36rem;
`;

import { useFormContext } from 'react-hook-form';

import { Variable } from 'api/data/variables';
import { Svgs, Colors } from 'environment';
import { DynamicFormValues } from 'store/data/entries';

import { Wrapper, Container } from './AddEditGroupTitle.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { getFormInputsToWatch, getFormInputsValidation } from 'helpers/entries';
import { buildVariablesMap } from 'helpers/variables';
import { useGetValidationErrors } from 'hooks/store';
import { useDeepCompareMemo } from 'hooks/utils';

interface Props {
	title: string;
	groupVariables: Variable[];
}

export function AddEditGroupTitle({ title, groupVariables }: Props) {
	const validationErrors = useGetValidationErrors();

	const {
		getValues,
		formState: { errors }
	} = useFormContext<DynamicFormValues>();

	function getGroupVariableNames() {
		return groupVariables.map(variable => variable.name);
	}

	const groupVariableNames = useDeepCompareMemo(() => getGroupVariableNames(), [groupVariables]);

	const groupVariablesMap = buildVariablesMap(groupVariables);
	const inputsToWatch = getFormInputsToWatch({
		variableNames: groupVariableNames,
		variablesMap: groupVariablesMap
	});

	const values = getValues(inputsToWatch).reduce<DynamicFormValues>((acc, value, idx) => {
		acc[inputsToWatch[idx]] = value;

		return acc;
	}, {});

	const getGroupValidation = () => {
		const { areInputsFilled, hasErrors } = getFormInputsValidation({
			variableNames: groupVariableNames,
			values,
			variablesMap: groupVariablesMap,
			validationErrors,
			errors
		});

		return { areInputsFilled, hasErrors };
	};
	const { areInputsFilled, hasErrors } = getGroupValidation();

	const svg = hasErrors ? Svgs.AlertCircle : areInputsFilled ? Svgs.CheckCircle : null;
	const iconColor = hasErrors
		? Colors.text.error
		: areInputsFilled
		? Colors.vibrantGreen
		: undefined;

	return (
		<Wrapper>
			<Container>
				<Icon
					svg={Svgs.Folder}
					size={s => s.m}
					paddingOffset={{ y: 0.4 }}
					marginOffset={{ x: 0.8 }}
					propagate
				/>
				<Typography.Paragraph paddingOffset={{ top: 0.2 }}>{title}</Typography.Paragraph>
			</Container>
			{svg && (
				<Icon
					svg={svg}
					colors={{ color: iconColor }}
					size={s => s.m}
					marginOffset={{ top: 0.4 }}
				/>
			)}
		</Wrapper>
	);
}

import type { PromStatistics } from 'api/data/dashboard';
import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export enum ActionTypes {
	GET_DISTRIBUTION_STATISTICS = 'data/dashboard/GET_DISTRIBUTION_STATISTICS'
}

export interface State {
	projectId: string | null;
	byProjectId: GenericMap<{
		automaticDistributionStatistics?: PromStatistics;
		manualDistributionStatistics?: string[];
		fetched: boolean;
	}>;
}

export type GetDistributionStatisticsAction = Action<
	ActionTypes.GET_DISTRIBUTION_STATISTICS,
	{
		projectId: string;
		promStatistics?: PromStatistics;
		listOfCreationDates?: string[];
	}
>;

export type Actions = GetDistributionStatisticsAction;

import styled, { css } from 'styled-components';

interface WrapperProps {
	stacked?: boolean;
	responsive?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	width: 100%;

	${({ stacked }) =>
		stacked &&
		css`
			flex-direction: column;
		`}

	// Currently only used for analysis date and time filters
	${({ responsive }) =>
		responsive &&
		css`
			@media (min-width: 1024px) and (max-width: 1550px) {
				flex-direction: column;
				gap: 8px;

				& > div:nth-child(2) > div:nth-child(2) {
					margin-left: 0;

					& > div {
						width: 100%;
						transition: width 0s;
					}
				}
			}
		`}
`;

export const Container = styled.div<{ compact: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	${({ compact }) =>
		compact &&
		css`
			.react-date-picker__inputGroup {
				min-width: 9.6rem;
			}
		`}
`;

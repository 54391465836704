import { mergeRefs } from 'helpers/generic';
import { Column } from './Column';
import { Body, Cell, Head, ResponsiveContainer, Row, Table as DefaultTable } from './Table.style';
import { useHorizontalScroll } from 'hooks/ui';

export interface TableProps {
	children: React.ReactNode;
	tableRef?: React.RefObject<HTMLTableElement>;
	noDesign?: boolean;
	hoverEffect?: boolean;
	fullWidth?: boolean;
	width?: number;
	minWidth?: number;
	maxWidth?: number;
	stickyHead?: boolean;
	paddingX?: number;
	paddingY?: number;
	isLoading?: boolean;
	dataTestId?: string;
}

export function Table({
	children,
	tableRef,
	noDesign,
	hoverEffect,
	fullWidth,
	width,
	minWidth,
	maxWidth,
	stickyHead,
	paddingX,
	paddingY,
	isLoading,
	dataTestId
}: TableProps) {
	return (
		<DefaultTable
			ref={tableRef}
			noDesign={noDesign}
			hoverEffect={hoverEffect}
			fullWidth={fullWidth}
			width={width}
			minWidth={minWidth}
			maxWidth={maxWidth}
			stickyHead={stickyHead}
			paddingX={paddingX}
			paddingY={paddingY}
			isLoading={isLoading}
			data-testid={dataTestId}
		>
			{children}
		</DefaultTable>
	);
}

interface ResponsiveProps {
	children: React.ReactNode;
	_ref?: React.RefCallback<HTMLDivElement> | React.RefObject<HTMLDivElement>;
	noDesign?: boolean;
	fullWidth?: boolean;
	fullHeight?: boolean;
	horizontalScroll?: boolean;
	headerOverflow?: boolean;
	id?: string;
}

export function Responsive({
	children,
	_ref,
	noDesign,
	fullWidth,
	fullHeight,
	horizontalScroll,
	headerOverflow,
	id
}: ResponsiveProps) {
	const { handleRef } = useHorizontalScroll();

	const mergedRef = mergeRefs([handleRef, ...(_ref ? [_ref] : [])].flat());

	return (
		<ResponsiveContainer
			noDesign={noDesign}
			fullWidth={fullWidth}
			fullHeight={fullHeight}
			ref={horizontalScroll ? mergedRef : _ref}
			headerOverflow={headerOverflow}
			id={id}
		>
			{children}
		</ResponsiveContainer>
	);
}

Table.Responsive = Responsive;
Table.Head = Head;
Table.Column = Column;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;

import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div``;

type RowProps = {
	/** Margin in rem */
	marginBottom?: number;
};

export const Row = styled.div<RowProps>`
	display: flex;
	margin-bottom: ${p => (p.marginBottom ? p.marginBottom + 'rem' : '0')};
`;

type ColumnProps = {
	mB?: boolean;
	w?: number;
};

export const Column = styled.div<ColumnProps>`
	margin-bottom: ${p => (p.mB ? '4rem' : '0')};
	width: ${p => (p.w ? p.w + '%' : 'initial')};
`;

export const Title = styled.div.attrs(() => ({ className: 'h4' }))`
	display: block;
	width: 100%;
`;
export const SubHeader = styled.div`
	${getTypographyCSS(t => t.Paragraph)}

	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;

	color: #25253a;
	width: 100%;
`;

export const InputWrapper = styled.div`
	display: flex;

	width: 100%;
`;

type InputProps = {
	error?: boolean;
	validated?: boolean;
};

export const InputInfo = styled.div<InputProps>`
	${getTypographyCSS(t => t.Hint)}

	padding: 0.5rem 0;

	color: ${p =>
		p.error
			? Colors.numericEditor.error
			: p.validated
			? Colors.numericEditor.validated
			: Colors.text.hint};
`;

type FormulaWrapperProps = {
	focused: boolean;
	error: boolean;
	validated: boolean;
};

export const FormulaWrapper = styled.div<FormulaWrapperProps>`
	border: ${p =>
			p.focused
				? Colors.primary.normal
				: p.error
				? Colors.numericEditor.error
				: p.validated
				? Colors.numericEditor.validated
				: Colors.numericEditor.initial}
		solid 1px;
	width: 100%;
	z-index: 10;

	display: flex;

	border-top-left-radius: 0.4rem;
	border-bottom-left-radius: 0.4rem;
`;

export const CalculatedInput = styled.input<InputProps>`
	${getTypographyCSS(t => t.Paragraph)}

	box-sizing: border-box;

	width: calc(100% - 32px);
	padding: 1rem;

	line-height: 2.2rem !important;

	border: none;
	border-radius: 0.25rem;

	outline: none;
`;

export const ValidationButton = styled.button`
	${getTypographyCSS(t => t.Paragraph)}

	font-size: 14px;
	font-weight: 500;
	padding: 9px 17px 9px 14px;
	border-top-right-radius: 0.4rem;
	border-bottom-right-radius: 0.4rem;

	border: solid 1px #b0bfc7;
	border-left: none;
	background-color: ${Colors.background.disabled};
	color: ${Colors.text.main};

	cursor: pointer;
`;

export const InfoMessage = styled.div`
	${getTypographyCSS(t => t.Paragraph)}

	font-size: 14px;
	width: calc(100% - 16px);

	margin-right: 1.8rem;

	color: ${Colors.text.captionSecondary};
`;

export const Info = styled.div`
	display: flex;
	width: 100%;

	padding: 0.8rem;

	background-color: ${Colors.background.disabledSecondary};

	align-items: center;
`;

import type {
	AndNode,
	ArithmeticNode,
	CalculationActions,
	CategoryNode,
	ComparisonNode,
	JsonLogic,
	JsonLogicMap,
	LogicalNode,
	Variable,
	VariableNode
} from 'api/data/variables/types';
import { nanoid as generate } from 'nanoid';
import { LogicalOperator } from 'components/Variables/CreateUpdateVariable/CalculatedVariable';
import { generateCalculationId } from 'helpers/variables';
import { useState } from 'react';

import { cloneDeep } from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';
import type { VariableAliasesMap } from 'api/data/variables/types';
import { useCalculationVariableAliases } from 'components/Variables/CreateUpdateVariable/NumericCalculatedVariable/useCalculationVariableAliases/useCalculationVariableMap';
import {
	ArithmeticOperator,
	ComparisonOperator,
	OperandType,
	RuleType,
	VariableType
} from 'types/data/variables/constants';

type Props = {
	initialCases: JsonLogicMap;
	initialVariableAliases: VariableAliasesMap | null;
	draftVariable: Variable;
	scopeVariables: Variable[];
};
export function useJsonLogicManager({
	initialCases,
	initialVariableAliases,
	draftVariable,
	scopeVariables
}: Props) {
	const [draftCases, setDraftCases] = useState<JsonLogicMap>(initialCases);

	const numericVariables = scopeVariables.filter(
		v => v.type === VariableType.Integer || v.type === VariableType.Float
	);

	const [variableAliases, variableAliasesActions] = useCalculationVariableAliases(
		draftCases,
		numericVariables,
		initialVariableAliases
	);

	function handleResetCases(cases: JsonLogicMap) {
		setDraftCases(cases);
	}

	function handleAddJsonLogic(type: RuleType) {
		switch (type) {
			case RuleType.LogicalOperation: {
				const mustAddCategory =
					draftVariable.categories.length > 0 &&
					draftVariable.type === VariableType.Category;

				const categoryNode = mustAddCategory
					? { catVal: draftVariable.categories[0].value }
					: null;

				const newEmptyCase = {
					if: [
						{
							[ComparisonOperator.GreaterThan]: [null, null]
						},
						categoryNode,
						null
					]
				} as JsonLogic;

				const currentLogicId = generateCalculationId();

				setDraftCases(prevState => {
					const state = cloneDeep(prevState);
					state.order.push(currentLogicId);
					state.logics[currentLogicId] = newEmptyCase;

					return state;
				});

				break;
			}
			case RuleType.ArithmeticOperation: {
				const newEmptyCase = {
					[ArithmeticOperator.Addition]: [null, null]
				} as JsonLogic;

				const id = generate();

				setDraftCases(prevState => {
					const state = cloneDeep(prevState);
					state.order.push(id);
					state.logics[id] = newEmptyCase;

					return state;
				});

				break;
			}

			case RuleType.DateAdditionOperation: {
				const newEmptyCase = {
					[ArithmeticOperator.Addition]: [null, null, 'days']
				} as JsonLogic;

				const id = generate();

				setDraftCases(prevState => {
					const state = cloneDeep(prevState);
					state.order.push(id);
					state.logics[id] = newEmptyCase;

					return state;
				});

				break;
			}

			case RuleType.DateSubtractionOperation: {
				const newEmptyCase = {
					[ArithmeticOperator.Subtraction]: [null, null, 'days']
				} as JsonLogic;

				const id = generate();

				setDraftCases(prevState => {
					const state = cloneDeep(prevState);
					state.order.push(id);
					state.logics[id] = newEmptyCase;

					return state;
				});

				break;
			}

			case RuleType.DurationOperation: {
				const newEmptyCase = {
					[ArithmeticOperator.Addition]: [null, null]
				} as ArithmeticNode;

				const id = generate();

				setDraftCases(prevState => {
					const state = cloneDeep(prevState);
					state.order.push(id);
					state.logics[id] = newEmptyCase;

					return state;
				});
				break;
			}
			default: {
				return;
			}
		}
	}

	function handleAddEmptyOperandAtPath(path: string, type: OperandType) {
		const pathKeys = path.split('.');

		const logicId = pathKeys.shift();

		if (!logicId) return;

		const variable =
			draftVariable.type === VariableType.Category ||
			draftVariable.type === VariableType.Float
				? scopeVariables.filter(
						v => v.type === VariableType.Integer || v.type === VariableType.Float
				  )[0]
				: draftVariable.type === VariableType.Integer
				? scopeVariables.filter(v => v.type === VariableType.Integer)[0]
				: draftVariable.type === VariableType.TimeDuration
				? scopeVariables.filter(v => v.type === VariableType.TimeDuration)[0]
				: scopeVariables.filter(
						v => v.type === VariableType.Date || v.type === VariableType.DateTime
				  )[0];

		const value =
			type === OperandType.NumberInput || type === OperandType.DateInput
				? ''
				: type === OperandType.NumberVariable || type === OperandType.DateVariable
				? ({
						var: [variable.name, variable.type]
				  } as VariableNode)
				: type === OperandType.Category
				? ({ catVal: draftVariable.categories[0].value } as CategoryNode)
				: type === OperandType.DurationVariable
				? ({ var: [variable.name, variable.type] } as VariableNode)
				: ({
						[ArithmeticOperator.Addition]: [null, null]
				  } as ArithmeticNode);

		setDraftCases(prevState => {
			const state = cloneDeep(prevState);
			const targetLogic = state.logics[logicId];

			if (!targetLogic) return state;

			function setValueByPathKeys(
				currentObj: any,
				objKeys: string[],
				value?:
					| string
					| number
					| CategoryNode
					| VariableNode
					| LogicalNode
					| ComparisonNode
					| ArithmeticNode
			) {
				const part = objKeys.shift();
				if (!part) return;
				const maybeNumber = Number(part);

				if (!isNaN(maybeNumber)) {
					// We have an array index
					if (objKeys.length === 0) {
						// We have reached the target
						currentObj[maybeNumber] = value ? value : '';
					} else {
						setValueByPathKeys(currentObj[maybeNumber], objKeys, value);
					}
				} else {
					// We have an object property
					if (objKeys.length === 0) {
						// We have reached the target
						currentObj[part] = value ? value : '';
					} else {
						setValueByPathKeys(currentObj[part], objKeys, value);
					}
				}
			}

			setValueByPathKeys(targetLogic, pathKeys, value);

			return state;
		});
	}

	function handleAddOperandAtPath(
		path: string,
		type: OperandType,
		value?:
			| string
			| number
			| CategoryNode
			| VariableNode
			| LogicalNode
			| ComparisonNode
			| ArithmeticNode
	) {
		const pathKeys = path.split('.');

		const logicId = pathKeys.shift();

		if (!logicId) return;

		setDraftCases(prevState => {
			const state = cloneDeep(prevState);

			const targetLogic = state.logics[logicId];

			if (!targetLogic) return state;

			function setValueByPathKeys(
				currentObj: any,
				objKeys: string[],
				value?:
					| string
					| number
					| CategoryNode
					| VariableNode
					| LogicalNode
					| ComparisonNode
					| ArithmeticNode
			) {
				const part = objKeys.shift();
				if (!part) return;
				const maybeNumber = Number(part);

				if (!isNaN(maybeNumber)) {
					// We have an array index
					if (objKeys.length === 0) {
						// We have reached the target
						currentObj[maybeNumber] = value || value === 0 ? value : '';
					} else {
						setValueByPathKeys(currentObj[maybeNumber], objKeys, value);
					}
				} else {
					// We have an object property
					if (objKeys.length === 0) {
						// We have reached the target
						currentObj[part] = value ? value : '';
					} else {
						setValueByPathKeys(currentObj[part], objKeys, value);
					}
				}
			}

			setValueByPathKeys(targetLogic, pathKeys, value);

			return state;
		});
	}

	function handleDeleteOperandAtPath(path: string | null) {
		if (!path) return;

		const pathKeys = path.split('.');

		const logicId = pathKeys.shift();

		if (!logicId) return;

		setDraftCases(prevState => {
			const state = cloneDeep(prevState);
			const targetLogic = state.logics[logicId];

			if (!targetLogic) return state;

			function deleteValueByPathKeys(currentObj: any, objKeys: string[]) {
				const part = objKeys.shift();
				if (!part) return;
				const maybeNumber = Number(part);

				if (!isNaN(maybeNumber)) {
					// We have an array index
					if (objKeys.length === 0) {
						// We have reached the target
						currentObj[maybeNumber] = null;
					} else {
						deleteValueByPathKeys(currentObj[maybeNumber], objKeys);
					}
				} else {
					// We have an object property
					if (objKeys.length === 0) {
						// We have reached the target
						currentObj[part] = null;
					} else {
						deleteValueByPathKeys(currentObj[part], objKeys);
					}
				}
			}

			deleteValueByPathKeys(targetLogic, pathKeys);

			return state;
		});
	}

	function handleDeleteJsonLogicAtPath(path: string | null) {
		if (!path) return;

		const pathKeys = path.split('.');

		const logicIdToRemove = pathKeys.shift();

		if (!logicIdToRemove) return;

		setDraftCases(prevState => {
			const state = cloneDeep(prevState);
			state.order = state.order.filter(id => id !== logicIdToRemove);

			delete state.logics[logicIdToRemove];

			return state;
		});
	}

	function handleOperandChangeAtPath(path: string | null, value: string) {
		if (!path) return;

		const pathKeys = path.split('.');

		const logicId = pathKeys.shift();

		if (!logicId) return;

		setDraftCases(prevState => {
			const state = cloneDeep(prevState);
			const targetLogic = state.logics[logicId];

			if (!targetLogic) return state;

			function setValueByPathKeys(currentObj: any, objKeys: string[]) {
				const key = objKeys.shift();
				if (!key) return;
				const maybeNumberKey = Number(key);

				if (!isNaN(maybeNumberKey)) {
					// We have an array index
					if (objKeys.length === 0) {
						// We have reached the target
						const oldNode = currentObj[maybeNumberKey];
						const nodeValues = Object.values(oldNode) as any[];

						const newNode = {
							[value]: [...nodeValues[0]]
						};

						currentObj[maybeNumberKey] = newNode;
					} else {
						setValueByPathKeys(currentObj[maybeNumberKey], objKeys);
					}
				} else {
					// We have an object property
					if (objKeys.length === 0) {
						// We have reached the target
						const oldNode = currentObj[key];
						const nodeValues = Object.values(oldNode) as any[];

						const newNode = {
							[value]: [...nodeValues[0]]
						};

						currentObj[key] = newNode;
					} else {
						setValueByPathKeys(currentObj[key], objKeys);
					}
				}
			}

			if (pathKeys.length > 0) {
				setValueByPathKeys(targetLogic, pathKeys);
			} else {
				const oldNode = targetLogic as ArithmeticNode;
				const nodeValues = Object.values(oldNode) as any[];

				const newNode = {
					[value]: [...nodeValues[0]]
				} as ArithmeticNode;

				state.logics[logicId] = newNode;
			}

			return state;
		});
	}

	function handleLogicalOperatorChangeAtPath(path: string | null, value: string) {
		if (!path) return;

		const isAndOperator = value === LogicalOperator.And;

		const pathKeys = path.split('.');

		const logicId = pathKeys.shift();

		if (!logicId) return;

		setDraftCases(prevState => {
			const state = cloneDeep(prevState);
			const targetLogic = state.logics[logicId];

			let currIndex = 0;

			function setAndByPathKeys(currentNode: any, root: any) {
				const key = pathKeys[currIndex];
				if (!key) return;
				const maybeNumberKey = Number(key);

				if (!isNaN(maybeNumberKey)) {
					// We have an array index
					if (currIndex === pathKeys.length - 1) {
						// We have reached the target
						const oldNode = currentNode[maybeNumberKey];
						const isAndNode = 'and' in oldNode;

						if (!isAndNode && isAndOperator) {
							const emptyConditionNode: ComparisonNode = {
								[ComparisonOperator.GreaterThan]: [null, null]
							};
							const newNode = {
								and: [oldNode, emptyConditionNode]
							} as AndNode;

							currentNode[key] = newNode;
						}
					} else {
						currIndex++;
						setAndByPathKeys(currentNode[maybeNumberKey], root);
					}
				} else {
					if (pathKeys[currIndex] === 'and') {
						// We have reached the target
						// We have an existin 'and' object property
						const andIndex = Number(pathKeys[currIndex + 1]);

						if (isNaN(andIndex)) return;

						if (andIndex === 0 && !isAndOperator) {
							// We are changing the first 'and' node
							// we need to remove the and node and replace it with the first comparison node in the 'and' node
							const comparisonNode = currentNode.and[0] as ComparisonNode;

							root[pathKeys[0]][Number(pathKeys[1])] = comparisonNode;
						}

						if (andIndex > 0 && isAndOperator) {
							const emptyConditionNode: ComparisonNode = {
								[ComparisonOperator.GreaterThan]: [null, null]
							};

							// Add the new comparison node if the selected andIndex is the last one
							if (andIndex === currentNode.and.length - 1) {
								currentNode.and.push(emptyConditionNode);
							}
						}

						if (andIndex > 0 && !isAndOperator) {
							// Remove the comparison nodes starting from the selected andIndex
							currentNode.and.splice(andIndex + 1);
						}
					} else {
						currIndex++;
						setAndByPathKeys(currentNode[key], root);
					}
				}
			}

			setAndByPathKeys(targetLogic, targetLogic);

			return state;
		});
	}

	function handleEditorRuleWithVariableMap(
		logicMap: JsonLogicMap,
		variableKeyMap: VariableAliasesMap
	) {
		unstable_batchedUpdates(() => {
			setDraftCases(logicMap);
			variableAliasesActions.setKeyMap(variableKeyMap);
		});
	}

	const calculationActions: CalculationActions = {
		setDraftCases: handleResetCases,
		onAddJsonLogic: handleAddJsonLogic,
		onAddEmptyOperandAtJsonLogicPath: handleAddEmptyOperandAtPath,
		onAddOperandValue: handleAddOperandAtPath,
		onOperatorChange: handleOperandChangeAtPath,
		onLogicalOperatorChange: handleLogicalOperatorChangeAtPath,
		onDeleteCase: handleDeleteJsonLogicAtPath,
		onDeleteOperand: handleDeleteOperandAtPath,

		onValidEditorRuleWithVariableMap: handleEditorRuleWithVariableMap
	};

	return {
		draftCases,

		variableAliases,
		calculationActions,
		variableAliasesActions
	};
}

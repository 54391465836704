import { useRef, useMemo } from 'react';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';

import { CorrelationsResults, JADBioScatterGroup } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { CHART_COLORS, CHART_HEIGHT } from 'consts';
import { Colors } from 'environment';
import { ZingChartTypes } from 'types/charts';
import { BooleanMap, ScalesLabels } from 'types/index';
import { computeScalesLabels, computeLegend, getMaxAndMinInArray } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import { useAnalysisContext, useToggleAnalysisChartPlot } from 'hooks/store';

function computeData(
	dataset: JADBioScatterGroup[],
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	plots: BooleanMap,
	legendHeader?: string
) {
	const trackers: number[] = [];
	const xValuesSeries: number[] = [];
	const yValuesSeries: number[] = [];
	const [labelX, labelY] = computeScalesLabels(scalesLabels);
	const [legendItem, legendTooltip] = computeLegend(Object.keys(dataset));

	const scatterSeries = dataset.map(({ group, xValues, yValues }, index) => {
		const values = xValues.map((x, i) => [Number(x), Number(yValues[i])]);
		trackers.push(values.length);
		xValuesSeries.push(...xValues);
		yValuesSeries.push(...yValues);

		return {
			type: ZingChartTypes.Scatter,
			tooltip: {
				text:
					dataset.length > 1
						? '%t: %scale-key-value, %node-value'
						: '%scale-key-value, %node-value'
			},
			values,
			text: group,
			size: 20,
			marker: {
				backgroundColor: CHART_COLORS[index],
				borderColor: Colors.white,
				borderWidth: 2
			},
			visible: plots && plots[index] !== undefined ? plots[index] : true
		};
	});

	const series = scatterSeries;

	const { max: xMaxValue, min: xMinValue } = getMaxAndMinInArray(xValuesSeries);
	const { max: yMaxValue, min: yMinValue } = getMaxAndMinInArray(yValuesSeries);
	const maxXValue = Math.ceil(xMaxValue);
	const minXValue = Math.floor(xMinValue);
	const maxYValue = Math.ceil(yMaxValue);
	const minYValue = Math.floor(yMinValue);
	const maxTrackers = Math.max(...trackers);

	const disableAnimations = trackers.reduce((a, b) => a + b, 0) > 100;

	const options = {
		plot: {
			marker: {
				size: 8
			},
			maxTrackers
		},
		legend: {
			item: legendItem,
			tooltip: legendTooltip,
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled && dataset.length > 1,
			marker: {
				type: 'circle'
			}
		},
		scaleX: {
			label: labelX,
			minValue: minXValue,
			maxXValue,
			exponent: xMaxValue >= 10000 ? true : false
		},
		scaleY: {
			label: labelY,
			minValue: minYValue,
			maxYValue,
			exponent: yMaxValue >= 10000 ? true : false
		},
		plotarea: {
			marginLeft: yMaxValue >= 10000 ? 75 : undefined,
			marginRight: yMaxValue >= 10000 ? 40 : undefined
		}
	};

	return { series, options, disableAnimations };
}

interface Props {
	id?: string;
	data: CorrelationsResults;
	isForExport?: boolean;
	isLegendEnabled: boolean;
	legendHeader?: string;
	scalesLabels: ScalesLabels;
}

function Component({ id, data, isForExport, isLegendEnabled, legendHeader, scalesLabels }: Props) {
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);

	const ref = useRef<ZingChart>(null);
	const { series, options, disableAnimations } = useMemo(
		() => computeData(data, isLegendEnabled, scalesLabels, plots, legendHeader),
		[data, isLegendEnabled, scalesLabels, legendHeader]
	);

	// legend item click toggle chained range and line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		!isForExport && togglePlot({ id: analysisId, plotIndex: e.plotindex });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `linear-${e.plotindex}` });
		}
	}

	return (
		<Chart
			id={id}
			chartRef={ref}
			type={t => t.Mixed}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			disableAnimations={disableAnimations}
			effect={e => e.SlideBottom}
			sequence={s => s.ByPlot}
			method={m => m.RegularEaseOut}
			speed={s => s.Faster}
			isForExport={isForExport}
			height={CHART_HEIGHT}
		/>
	);
}

export const JADBioReductionScatterChart = withMemo(Component, [
	'data',
	'isLegendEnabled',
	'scalesLabels',
	'legendHeader'
]);

import { apiFactory } from 'api';
import { ApiUserPendingInvitation } from 'api/account/subscription';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/account/subscription';

export function useAPIGetPendingInvitations(): ApiOperation<
	ApiUserPendingInvitation[],
	() => void
> {
	const [{ data, error, loading, fetched }, handler, resetData] = useAPI<
		ApiUserPendingInvitation[]
	>({
		promiseFn: () => apiFactory().account.subscription().getPendingInvitations(),
		lazy: true,
		initialData: [],
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		activityType: ActionTypes.GET_PENDING_INVITATION
	});

	return [{ data, error, loading, fetched }, handler, resetData];
}

import { Colors } from 'environment';
import styled from 'styled-components';
import { Flex as DefaultFlex } from '../Flex';

export const Bullet = styled.div`
	width: 0.8rem;
	height: 0.8rem;
	margin-right: 1.6rem;
	border-radius: 50%;
	background: ${Colors.text.main};
`;

export const Flex = styled(DefaultFlex)`
	:not(:last-child) {
		margin-bottom: 0.8rem;
	}
`;

import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { clearInvalidUsers } from 'store/data/accounts';

export function useInvalidUsers(): LocalOperationResult<string[]> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.accounts.users.invalidUsers);

	function handler() {
		dispatch(clearInvalidUsers());
	}

	return [data, handler];
}

import { useDispatch, useSelector } from 'hooks/utils';
import { setRefetchAnalyses } from 'store/data/analyses';
import { LocalOperationResult } from 'hooks/store/types';

export function useRefetchAnalyses(): LocalOperationResult<boolean> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.analyses.refetch);

	function handler() {
		dispatch(setRefetchAnalyses());
	}

	return [data, handler];
}

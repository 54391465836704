import { useDispatch, useSelector } from 'hooks/utils';
import {
	setSenderDescription,
	SetSenderDescriptionAction,
	selectSenderDescription
} from 'store/data/patients';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

interface Options {
	initial?: boolean;
}

export function useDistributionSenderDescription(
	props?: Options
): LocalOperationResult<
	string | undefined,
	(payload: ActionPayload<SetSenderDescriptionAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectSenderDescription(state.data.patients, props?.initial));

	function handler(payload: ActionPayload<SetSenderDescriptionAction>) {
		dispatch(setSenderDescription(payload));
	}

	return [data, handler];
}

import { useInView } from 'react-intersection-observer';

import { Button } from 'components/UI/Interactables/Button';
import { NeutralButtonProps } from 'types/index';

import { Footer, Container, IntersectRefDiv, Group } from './StickyFooter.style';
import useAdjustFooter from 'hooks/ui/useAdjustFooter';
import { ButtonVariants } from '../Interactables/Button/Button';

interface Props {
	primary?: NeutralButtonProps;
	neutral?: NeutralButtonProps;
	danger?: NeutralButtonProps;
	maxWidth?: number;
	fixedBottom?: boolean;
	alignButtonsLeft?: boolean;
	dynamicMarginBottom?: boolean;
	adjustFooterDeps?: React.DependencyList;
	zIndex?: number;
	modalFooter?: boolean;
	styleOptions?: {
		dangerButtonType?: ButtonVariants;
	};
}

export const DEFAULT_FOOTER_MARGIN_TOP = 100;

export function StickyFooter({
	primary,
	neutral,
	danger,
	maxWidth = 58,
	fixedBottom = false,
	alignButtonsLeft = false,
	dynamicMarginBottom = true,
	adjustFooterDeps,
	zIndex,
	modalFooter,
	styleOptions
}: Props) {
	const [ref, inView] = useInView({
		threshold: 0,
		rootMargin: '0px 0px -110px 0px'
	});

	const { marginTop } = useAdjustFooter(adjustFooterDeps);

	return (
		<>
			{!fixedBottom && <IntersectRefDiv ref={ref} />}
			<Footer
				id="sticky-footer"
				shadow={fixedBottom ? fixedBottom : !inView}
				modalFooter={modalFooter}
				marginTop={dynamicMarginBottom && marginTop ? marginTop : DEFAULT_FOOTER_MARGIN_TOP}
				className="sticky-footer"
				zIndex={zIndex}
			>
				<Container maxWidth={maxWidth}>
					{danger && (
						<Button
							id="sticky-footer-danger-button"
							variant={
								styleOptions && styleOptions.dangerButtonType
									? _ => styleOptions.dangerButtonType!
									: v => v.link
							}
							title={danger.label ?? ''}
							loading={danger.loading}
							disabled={danger.disabled}
							onClick={danger.onClick}
							marginOffset={(neutral || primary) && { right: 2 }}
						/>
					)}
					<Group alignLeft={alignButtonsLeft}>
						{neutral && (
							<Button
								id="sticky-footer-neutral-button"
								variant={v => v.secondary}
								title={neutral.label ?? ''}
								loading={neutral.loading}
								disabled={neutral.disabled}
								marginOffset={primary && { right: 2 }}
								onClick={neutral.onClick}
							/>
						)}
						{primary && (
							<Button
								id="sticky-footer-primary-button"
								dataTestId="sticky-footer-primary-button"
								title={primary.label ?? ''}
								loading={primary.loading}
								disabled={primary.disabled}
								onClick={primary.onClick}
							/>
						)}
					</Group>
				</Container>
			</Footer>
		</>
	);
}

import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';
import { publishTemplate } from 'store/data/templates';

export function usePublishTemplate(): LocalOperation<(templateId: string) => void> {
	const dispatch = useDispatch();

	function handler(templateId: string) {
		dispatch(publishTemplate(templateId));
	}

	return handler;
}

import { withReset, withResetToInitialOnProjectIdChange } from 'store/hors';
import initialState from './initialState';
import reducer from './reducer';

const enhancedReducer = withReset(
	withResetToInitialOnProjectIdChange(reducer, initialState),
	initialState
);

export { enhancedReducer as reducer };
export * from './actions';
export * from './selectors';
export * from './types';

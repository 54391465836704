import { State } from './types';

const initialState: State = {
	allEnterpriseProjects: [],
	//add roles
	roles: {
		byId: {},
		metadata: {
			roleId: null,
			searchTerm: '',
			fetched: false
		}
	}
};

export default initialState;

import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';

interface Props {
	value: number | string;
	formatNaN?: boolean;
}

export function PStatistic({ value, formatNaN = true }: Props) {
	const { translate } = useTranslation();

	if (isNaN(Number(value)) && !formatNaN) return <>{`N/A`}</>;

	if (!Number(value) && Number(value) !== 0) {
		return (
			<>
				{translate(({ analysis }) => analysis.errors.statistics.missing)}{' '}
				<i>{translate(dict => dict.analysis.errors.statistics.pStatistic)}</i>
			</>
		);
	}

	const { operator, value: parsedValue } = parseAnalysisNumber(value, {
		decimals: 3,
		formatAsPValue: true
	});

	return (
		<>
			<i>{translate(dict => dict.analysis.errors.statistics.pStatistic)}</i>
			{` ${operator} ${parsedValue}`}
		</>
	);
}

import { useEffect } from 'react';
import { Suspend } from 'components/UI/Suspend';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useLogout, useLogin, useAccount } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';

export function LogoutPage() {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();
	const { setError } = useAlerts();
	const [{ loading: loggingOut, error: logoutError }, logout] = useLogout();
	const [{ data: isLoggedIn, loading: loggingIn }] = useLogin();
	const [{ loading: loadingAccount, fetched: fetchedAccount }] = useAccount();

	useEffect(() => {
		if (isLoggedIn && fetchedAccount) {
			logout();
		}
		if (!isLoggedIn) {
			navigate(routes.login);
		}
	}, [isLoggedIn, fetchedAccount]);

	useCompletedAction(
		loggingOut,
		logoutError,
		() => {
			navigate(routes.login);
		},
		() => {
			setError(translate(dict => dict.logout.errorMessage));
			navigate(routes.logout);
		}
	);

	const loading = loadingAccount && loggingIn;

	return (
		<Suspend immediate loading={loading}>
			<></>
		</Suspend>
	);
}

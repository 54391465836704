import React, { useRef, useState } from 'react';

import { MediaQueries, Svgs } from 'environment';
import { useTranslation } from 'hooks/store';

import { Flex } from 'components/UI/Flex';
import { supportsTouch } from 'consts';
import {
	SetSizePayloadDynamic,
	WidgetHeightSize,
	HeightSizes,
	WidthSizes,
	APIWidthSize
} from 'store/data/projectDashboard';

import {
	HorizontalResizeContainer,
	ResizableContainer,
	VerticalResizeContainer,
	ResizeIcon,
	ResizeIconWrapper,
	CardContent
} from '../style';
import { convertAPIWidthToPX } from 'helpers/adminDashboard';
import { useMediaQuery, useStatic } from 'hooks/utils';

enum ResizeDirection {
	vertical = 'vertical',
	horizontal = 'horizontal'
}

interface Props {
	id: string;
	widthSize: APIWidthSize;
	heightSize: WidgetHeightSize;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	resizeVertically?: boolean;
	children: React.ReactNode;
	position?: {
		rowIndex: number;
		colIndex: number;
	};
	lockExpandUnder?: boolean;
	placeholdersCanOccupy?: number;
	setDimensions: (payload: SetSizePayloadDynamic) => void;
	setResizingState: () => void;
}

export function ResizableComponentDynamic({
	id,
	widthSizes,
	heightSizes,
	resizeVertically = false,
	widthSize,
	heightSize,
	children,
	position,
	lockExpandUnder = false,
	placeholdersCanOccupy,
	setDimensions,
	setResizingState
}: Props) {
	const ref = useRef<HTMLDivElement>(null);
	const { translate } = useTranslation();

	const [resizing, setResizing] = useState<ResizeDirection | null>(null);
	const [initialPos, setInitialPos] = useStatic<number>(0);
	const [initialSize, setInitialSize] = useStatic<number>(0);

	const { threeColumns, fourColumns, fiveColumns, sixColumns, sevenColumns } = widthSizes;

	const dynamicSizeWidth =
		sixColumns ?? fiveColumns ?? fourColumns ?? threeColumns ?? sevenColumns;

	const firstSizeWidth = dynamicSizeWidth?.firstSizeWidth ?? 0;
	const thirdSizeWidth = dynamicSizeWidth?.thirdSizeWidth ?? 0;

	const elementSnappedWidth = convertAPIWidthToPX(widthSize, widthSizes);

	const { minSizeHeight, maxSizeHeight } = heightSizes;

	function getInitialWidth(e: React.DragEvent<HTMLDivElement>) {
		e.nativeEvent.stopImmediatePropagation();

		setInitialPos(e.clientX);
		if (ref.current) {
			setInitialSize(ref.current?.getBoundingClientRect().width);
		}
		setResizingState();
	}

	function getInitialHeight(e: React.DragEvent<HTMLDivElement>) {
		e.nativeEvent.stopImmediatePropagation();

		setInitialPos(e.clientY);
		if (ref.current) {
			setInitialSize(ref.current?.getBoundingClientRect().height);
		}
	}

	function resizingComponentWidth(e: React.DragEvent<HTMLDivElement>) {
		setResizing(ResizeDirection.horizontal);
		if (!initialSize || !initialPos) return;

		const width = initialSize() + (e.clientX - initialPos());
		const initPos = initialPos();
		const blockWidthExpansion = isSetAsMaxSize && width + 90 > initPos;

		if (blockWidthExpansion) {
			if (ref.current) {
				ref.current.style.maxWidth = `${maxWidth}px!important`;
				return (ref.current.style.width = `${maxWidth}px`);
			}
		}

		if (!blockWidthExpansion && ref.current) {
			{
				return (ref.current.style.width = `${width}px`);
			}
		}
	}

	function resizingComponentHeight(e: React.DragEvent<HTMLDivElement>) {
		setResizing(ResizeDirection.vertical);
		if (!initialSize || !initialPos) return;

		const previousHeight = Number(ref?.current?.style.height.split('px')[0]);
		const previousClosestHight = [minSizeHeight, maxSizeHeight].reduce((a, b) => {
			return Math.abs(b - previousHeight) < Math.abs(a - previousHeight) ? b : a;
		});

		const height = initialSize() + (e.clientY - initialPos());
		const shouldBlockExpansion = lockExpandUnder && height > initialSize();
		const shouldBlockFurtherExpansion = height > maxSizeHeight;
		const cursorLeftWindow = !e.clientY;

		if (shouldBlockExpansion) {
			if (ref.current && height > minSizeHeight) {
				ref.current.style.height = `${
					cursorLeftWindow
						? previousClosestHight
						: heightSize === WidgetHeightSize.small
						? minSizeHeight
						: maxSizeHeight
				}px`;
				return;
			}
		}
		if (!shouldBlockFurtherExpansion && ref.current && height > minSizeHeight) {
			{
				ref.current.style.height = `${cursorLeftWindow ? previousClosestHight : height}px`;
			}
		}
	}

	function componentResizedWidth(e: React.DragEvent<HTMLDivElement>) {
		const width = Number(`${initialSize() + (e.clientX - initialPos())}`);

		if (ref.current && initialSize() && initialPos()) {
			setDimensions({
				cardId: Number(id),
				width: width > maxWidth && width > initialSize() ? maxWidth : width,
				position
			});
		}
		setResizing(null);
		setResizingState();
	}

	function componentResizedHeight(e: React.DragEvent<HTMLDivElement>) {
		const height = Number(`${initialSize() + (e.clientY - initialPos())}`);

		if (lockExpandUnder && height > initialSize()) {
			setResizing(null);
			return;
		}
		if (ref.current && initialSize() && initialPos()) {
			// Cater for resizing out of screen
			const previousClosestHight = Number(ref?.current?.style.height.split('px')[0]);
			const shouldBeSmall =
				e.clientY < 100 && previousClosestHight < heightSizes.minSizeHeight + 5;

			setDimensions({
				cardId: Number(id),
				width: elementSnappedWidth,
				height,
				outOfView: !shouldBeSmall
					? !(e.clientY >= 0 && e.clientY <= window.innerHeight - 35)
					: false,
				vertical: true,
				position
			});
		}

		setResizing(null);
	}

	const elementSnappedHeight =
		heightSize === WidgetHeightSize.small ? minSizeHeight : maxSizeHeight;

	const numberOfSizes = (dynamicSizeWidth && Object.values(dynamicSizeWidth).length) ?? 0;

	const isSetAsMaxSize =
		dynamicSizeWidth && widthSize === Object.keys(dynamicSizeWidth)[numberOfSizes - 1];

	const extendOnPositions = placeholdersCanOccupy ?? 0;

	const maxSize: number = dynamicSizeWidth
		? Object.values(dynamicSizeWidth)[numberOfSizes - 1]
		: 320;

	const sizeIndexMax = dynamicSizeWidth ? Object.keys(dynamicSizeWidth).indexOf(widthSize) : 0;

	const maxWidth = dynamicSizeWidth
		? isSetAsMaxSize
			? maxSize
			: Object.values(dynamicSizeWidth)[sizeIndexMax + extendOnPositions]
		: 320;

	const constraints = {
		minWidthConstr: position ? firstSizeWidth / 10 : 10,
		minHeightConstr: position ? minSizeHeight / 10 : 10,
		maxWidthConstr: maxWidth / 10,
		maxHeightConstr:
			(lockExpandUnder && heightSize === WidgetHeightSize.small
				? minSizeHeight
				: maxSizeHeight) / 10
	};

	const isMediumScreen = useMediaQuery(MediaQueries.maxWidth.md);
	const fullWidth = useMediaQuery(MediaQueries.maxWidth.lg);

	return (
		<ResizableContainer
			id={id}
			ref={ref}
			width={!isMediumScreen ? elementSnappedWidth : thirdSizeWidth}
			isMediumScreen={isMediumScreen}
			height={elementSnappedHeight}
			resizing={!!resizing}
			resizingHeight={resizing === ResizeDirection.vertical}
			resizingWidth={resizing === ResizeDirection.horizontal}
			constraints={constraints}
			fullWidth={fullWidth}
		>
			<Flex fullWidth align={a => a.center}>
				{!resizing && <CardContent width={elementSnappedWidth}>{children}</CardContent>}
				<ResizeIconWrapper horizonal>
					<HorizontalResizeContainer
						supportsTouch={!!supportsTouch}
						draggable={'true'}
						onDragStart={e => {
							getInitialWidth(e);
						}}
						onDragCapture={resizingComponentWidth}
						onDragEnd={componentResizedWidth}
					/>
					<Flex style={{ height: '100%' }} align={a => a.center}>
						<ResizeIcon
							svg={Svgs.More}
							visible={resizing === ResizeDirection.horizontal}
							title={translate(({ buttons }) => buttons.resizeCard)}
							rotate={90}
							size={s => s.m}
							customSize={2.2}
							variant={v => v.button}
						/>
					</Flex>
				</ResizeIconWrapper>
			</Flex>
			{resizeVertically && (
				<ResizeIconWrapper
					onDragStart={e => {
						getInitialHeight(e);
					}}
					onDragCapture={resizingComponentHeight}
					onDragEnd={componentResizedHeight}
				>
					<VerticalResizeContainer supportsTouch={!!supportsTouch} draggable="true" />
					<Flex fullWidth justify={j => j.center}>
						<ResizeIcon
							svg={Svgs.More}
							visible={resizing === ResizeDirection.vertical}
							title={translate(({ buttons }) => buttons.resizeCard)}
							size={s => s.m}
							customSize={2.2}
							variant={v => v.button}
							vertical
						/>
					</Flex>
				</ResizeIconWrapper>
			)}
		</ResizableContainer>
	);
}

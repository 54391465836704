import { useState, useMemo } from 'react';
import { isToday, isYesterday, format, getYear, isThisYear } from 'date-fns';
import { Colors, Svgs } from 'environment';
import { Revision } from 'store/data/revisions';
import { StatusesMap } from 'store/data/statuses';
import { RevisionCard } from './RevisionCard';
import { OlderRevisions, RecentRevisions } from './HistoryView.style';
import { isValidDate } from 'helpers/isValidDate';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useEntryId, useSelectedSeriesEntry } from 'hooks/store';
import { EntryNamesFromUserIds } from 'store/data/entries';

interface Props {
	revisions: Revision[];
	revisionId: string | null;
	statusesMap: StatusesMap;
	namesFromUserIds: EntryNamesFromUserIds;
	drawerType?: string;
	onSelect: (revisionId: string) => void;
}

export function HistoryView({
	revisions,
	revisionId,
	statusesMap,
	drawerType,
	namesFromUserIds,
	onSelect
}: Props) {
	const { translate } = useTranslation();

	const [mainEntryId] = useEntryId();
	const [selectedSeries] = useSelectedSeriesEntry();

	const entryId = selectedSeries.subEntryId ?? mainEntryId;

	const [olderRevisionsExpanded, setOlderRevisionsExpanded] = useState(false);

	const oldestRevisionId = useMemo(() => {
		if (revisions[0]) {
			return revisions[0].revisionId;
		}
	}, [revisions]);

	function groupByMonth(revisions: Revision[]) {
		const groupedRecent: Revision[][] = [];
		let monthIndex = 0;

		for (const revision of revisions) {
			const { creationDate } = revision;
			const parsedCreationDate = new Date(creationDate);
			monthIndex = parsedCreationDate.getMonth();

			if (!groupedRecent[monthIndex]) {
				groupedRecent[monthIndex] = [];
			}
			groupedRecent[monthIndex].push(revision);
		}
		return groupedRecent.reverse();
	}

	function getRevisionsToShow(revisions: Revision[]) {
		const recent: Revision[] = [];
		const older: Revision[] = [];
		const today: Revision[] = [];
		const yesterday: Revision[] = [];

		revisions.forEach(revision => {
			const { creationDate } = revision;

			const creationValidDate = isValidDate(creationDate)
				? creationDate.replace(/\s/g, 'T')
				: creationDate;

			const isRecent = creationDate && isThisYear(new Date(creationValidDate));
			const isItToday = creationDate && isToday(new Date(creationValidDate));
			const isItYesterday = creationDate && isYesterday(new Date(creationValidDate));

			if (isRecent) {
				if (isItToday) {
					today.unshift(revision);
				} else if (isItYesterday) {
					yesterday.unshift(revision);
				} else {
					recent.unshift(revision);
				}
			} else {
				older.unshift(revision);
			}
		});

		recent.forEach(revision => {
			const { creationDate } = revision;

			if (isToday(new Date(creationDate))) {
				today.push(revision);
			}

			if (isYesterday(new Date(creationDate))) {
				yesterday.push(revision);
			}
		});

		const groupedRecent = groupByMonth(recent);
		return { groupedRecent, older, today, yesterday };
	}

	const { groupedRecent, older, today, yesterday } = useMemo(
		() => getRevisionsToShow(revisions),
		[revisions]
	);

	const getMonth = (date: Date) => {
		return date && format(date, 'MMM');
	};

	return (
		<>
			{today.length > 0 && (
				<RecentRevisions drawerType={drawerType}>
					<Typography.Caption
						fontweight={w => w.medium}
						fontSize="1.35rem"
						color={Colors.gray.darkest}
					>
						{translate(dict => dict.entryRevisionsDrawer.today)}
					</Typography.Caption>
				</RecentRevisions>
			)}

			{today.map((revision, index) => (
				<RevisionCard
					key={`today-revision-${index}`}
					revision={revision}
					statusesMap={statusesMap}
					current={entryId === revision.revisionId}
					active={revision.revisionId === revisionId}
					isFirst={oldestRevisionId ? oldestRevisionId === revision.revisionId : false}
					onClick={() => onSelect(revision.revisionId)}
					index={index}
					nameFromUserId={namesFromUserIds[revision.userName] ?? revision.userName}
				/>
			))}

			{yesterday.length > 0 && (
				<RecentRevisions>
					<Typography.Caption
						fontweight={w => w.medium}
						fontSize="1.35rem"
						color={Colors.gray.darkest}
					>
						{translate(dict => dict.entryRevisionsDrawer.yesterday)}
					</Typography.Caption>
				</RecentRevisions>
			)}

			{yesterday.map((revision, index) => (
				<RevisionCard
					key={`yesterday-revision-${index}`}
					revision={revision}
					statusesMap={statusesMap}
					active={revision.revisionId === revisionId}
					current={entryId === revision.revisionId}
					isFirst={oldestRevisionId ? oldestRevisionId === revision.revisionId : false}
					onClick={() => onSelect(revision.revisionId)}
					index={index}
					nameFromUserId={namesFromUserIds[revision.userName] ?? revision.userName}
				/>
			))}

			{groupedRecent.map(revisions => {
				const creationValidDate = isValidDate(revisions[0].creationDate)
					? revisions[0].creationDate.replace(/\s/g, 'T')
					: revisions[0].creationDate;

				const month = getMonth(new Date(creationValidDate));
				const year = getYear(new Date(creationValidDate));

				return (
					<div key={`recent-revisions-${month}-${year}`}>
						<RecentRevisions>
							<Typography.Caption
								fontweight={w => w.medium}
								fontSize="1.35rem"
								color={Colors.gray.darkest}
							>
								{month}
							</Typography.Caption>
						</RecentRevisions>
						{revisions.map((revision, revisionIndex) => (
							<RevisionCard
								key={`revision-${month}-${revision.revisionId}`}
								revision={revision}
								statusesMap={statusesMap}
								active={revision.revisionId === revisionId}
								current={entryId === revision.revisionId}
								isFirst={
									oldestRevisionId
										? oldestRevisionId === revision.revisionId
										: false
								}
								onClick={() => onSelect(revision.revisionId)}
								index={revisionIndex}
								nameFromUserId={
									namesFromUserIds[revision.userName] ?? revision.userName
								}
							/>
						))}
					</div>
				);
			})}

			{older.length > 0 && (
				<OlderRevisions onClick={() => setOlderRevisionsExpanded(state => !state)}>
					<Icon
						svg={Svgs.ChevronDown}
						rotate={olderRevisionsExpanded ? 180 : 0}
						propagate
					/>
					<Typography.Caption
						fontweight={w => w.medium}
						fontSize="1.35rem"
						color={Colors.gray.darkest}
					>
						{`${translate(
							dict => dict.entryRevisionsDrawer.older
						)} ${new Date().getFullYear()}`}
					</Typography.Caption>
				</OlderRevisions>
			)}

			{olderRevisionsExpanded &&
				older.map((revision, index) => (
					<RevisionCard
						key={`older-revision-${index}`}
						revision={revision}
						statusesMap={statusesMap}
						active={revision.revisionId === revisionId}
						isFirst={
							oldestRevisionId ? oldestRevisionId === revision.revisionId : false
						}
						onClick={() => onSelect(revision.revisionId)}
						index={index}
						nameFromUserId={namesFromUserIds[revision.userName] ?? revision.userName}
					/>
				))}
		</>
	);
}

import styled from 'styled-components';

import { Colors } from 'environment';
import { transparentHex } from 'helpers/cssGenerators';

export const NotificationCardWrapper = styled.div`
	padding: 1.6rem 2.4rem;
	position: relative;
	border-bottom: 0.1rem solid ${transparentHex(Colors.text.caption, 0.1)};

	:hover {
		background-color: ${transparentHex(Colors.pale, 0.4)};
	}
`;

export const NotificationContent = styled.div`
	flex: 1;
	padding: 0 1.6rem;
`;

export const UnreadNotificationCircle = styled.div`
	position: absolute;
	left: 1.6rem;
	top: 1rem;
	width: 0.8rem;
	height: 0.8rem;
	background: ${Colors.notificationBlue};
	border-radius: 50%;
`;

import { useState } from 'react';
import {
	selectTemplateRolesPageActiveTab,
	setTemplateRolePageTabAction,
	TemplateRolesHeaderTab
} from 'store/data/roles';
import { SetState } from 'types/index';
import { NormalTab } from 'components/Header/HeaderNavigation/Tab.style';
import { MediaQueries } from 'environment';
import { Container } from './TemplateRolesPage.style';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Tabs } from 'components/UI/Tabs';
import { Select } from 'components/UI/Interactables/Select';
import { useDispatch, useEffectOnce, useMediaQuery, useSelector } from 'hooks/utils';
import { useTranslation } from 'hooks/store';

enum TabIndex {
	OWNED = 0,
	SHARED = 1
	// ORGANIZATION = 2,
}

function getTabIndex(tab: TemplateRolesHeaderTab): TabIndex {
	const tabByValue = {
		[TemplateRolesHeaderTab.OWNED]: TabIndex.OWNED,
		[TemplateRolesHeaderTab.SHARED]: TabIndex.SHARED
		// TODO: Add back in the future
		// [TemplateRolesHeaderTab.ORGANIZATION]: TabIndex.ORGANIZATION,
	};

	return tabByValue[tab];
}

interface Props {
	hasData: boolean;
	searchTermState: {
		searchTerm: string;
		setSearchTerm: SetState<string>;
	};
	onCreate: () => void;
}

export function RolesTemplatePageHeader({
	hasData,
	onCreate,
	searchTermState: { searchTerm, setSearchTerm }
}: Props) {
	const { translate } = useTranslation();
	const dispatch = useDispatch();

	const activeTabValue = useSelector(state => selectTemplateRolesPageActiveTab(state.data.roles));

	const [activeTab, setActiveTab] = useState(getTabIndex(activeTabValue));

	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const menuTabs = [
		{
			label: translate(dict => dict.roles.header.menuTabs.ownTemplates)
		},
		{
			label: translate(dict => dict.roles.header.menuTabs.sharedTemplates)
		}
	];

	function handleTabChange(index: number) {
		const tab = Object.values(TemplateRolesHeaderTab)[index];

		setActiveTab(index);
		// setSearchTerm('');
		dispatch(setTemplateRolePageTabAction({ activeTab: tab }));
	}

	useEffectOnce(() => {
		return () => {
			dispatch(setTemplateRolePageTabAction({ activeTab: TemplateRolesHeaderTab.OWNED }));
		};
	});

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					hasData && (
						<Flex>
							<SearchInput
								placeholder={translate(
									dict => dict.roles.header.searchInput.placeholder
								)}
								term={searchTerm}
								onChangeTerm={term => term !== searchTerm && setSearchTerm(term)}
								usedInHeader
							/>

							{addButton({
								label: translate(dict => dict.roles.header.createButton.title),
								action: onCreate
							})}
						</Flex>
					)
				}
				leftComponent={
					<Container>
						{isPhone ? (
							<NormalTab>
								<Select
									title={translate(
										dict => dict.accountUM.userDrawer.headers.profile
									)}
									className="dropdown-tab"
									items={menuTabs}
									type={s => s.Tab(true)}
									activeItem={item => menuTabs[activeTab].label === item.label}
									onSelect={item => handleTabChange(menuTabs.indexOf(item))}
								/>
							</NormalTab>
						) : (
							<Tabs
								labels={[
									translate(dict => dict.roles.header.menuTabs.ownTemplates),
									translate(dict => dict.roles.header.menuTabs.sharedTemplates)
								]}
								startIndex={activeTab}
								onChangeTabs={handleTabChange}
								maxWidth={20}
								tabClassName="tabs__navigation__item"
							/>
						)}
					</Container>
				}
			/>
			<Header.Title title={translate(dict => dict.roles.header.roleTemplates)} />
		</>
	);
}

/*




					


*/

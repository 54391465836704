import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { Svgs, Colors } from 'environment';
import { useTranslation } from 'hooks/store';
import { Status } from 'store/data/statuses';

import { Wrapper, IconsContainer, BadgeContainer, Badge, Circle } from './StatusesTable.style';

interface Props {
	statuses: Status[];
	onUpdate: (status: Status) => void;
	onDelete: (status: Status) => void;
}

export function StatusesTable({ statuses, onUpdate, onDelete }: Props) {
	const { translate } = useTranslation();

	return (
		<Wrapper>
			<Table.Responsive>
				<Table fullWidth hoverEffect>
					<Table.Head>
						<Table.Row>
							{/* NAME */}
							<Table.Column minWidth={20} maxWidth={30} paddingLeft={2}>
								{translate(dict => dict.statuses.statusTable.name)}
							</Table.Column>

							{/* DESCRIPTION */}
							<Table.Column minWidth={16}>
								{translate(dict => dict.statuses.statusTable.description)}
							</Table.Column>

							{/* DUE TIME */}
							<Table.Column minWidth={12} maxWidth={14}>
								{translate(dict => dict.statuses.statusTable.dueTime)}
							</Table.Column>

							{/* IS DEFAULT */}
							<Table.Column minWidth={12} maxWidth={14}>
								{translate(dict => dict.statuses.statusTable.isDefault)}
							</Table.Column>

							{/* COLOR */}
							<Table.Column maxWidth={4}>
								{translate(dict => dict.statuses.statusTable.color)}
							</Table.Column>
						</Table.Row>
					</Table.Head>

					<Table.Body>
						{statuses.map((status, index) => (
							<Table.Row key={`table-row-${index}`} className="table-row">
								{/* NAME */}
								<Table.Cell width={40} minWidth={20} maxWidth={30} paddingLeft={2}>
									<BadgeContainer>
										<Badge title={status.label} color={status.statusColor}>
											{status.label}
										</Badge>
									</BadgeContainer>
								</Table.Cell>

								{/* DESCRIPTION */}
								<Table.Cell
									title={status.description}
									minWidth={20}
									maxWidth={20}
									noWrap
								>
									{status.description}
								</Table.Cell>

								{/* DUE TIME */}
								<Table.Cell width={20} minWidth={12}>
									{status.dueTimeAmount && status.dueTimeUnit
										? `${status.dueTimeAmount} ${status.dueTimeUnit}`
										: null}
								</Table.Cell>

								{/* IS DEFAULT */}
								<Table.Cell width={20} minWidth={12}>
									{status.isDefault ? (
										<Typography.Paragraph fontweight={f => f.medium}>
											{translate(dict => dict.terms.yes)}
										</Typography.Paragraph>
									) : (
										'-'
									)}
								</Table.Cell>

								{/* COLOR */}
								<Table.Cell width={16} minWidth={12}>
									<Flex align={a => a.center} justify={j => j.between}>
										<Circle color={status.statusColor} />

										{/* ACTION BUTTONS */}
										<IconsContainer className="icons-container">
											<Icon
												svg={Svgs.Edit}
												marginOffset={{ right: 1.6 }}
												colors={{
													color: Colors.text.hint
												}}
												onClick={() => onUpdate(status)}
											/>
											<Icon
												svg={Svgs.Delete}
												colors={{
													color: Colors.text.hint
												}}
												onClick={() => onDelete(status)}
											/>
										</IconsContainer>
									</Flex>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</Wrapper>
	);
}

import {
	removeVariableGroupFromSet,
	ActionTypes,
	RemoveVariableGroupFromSetAction
} from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionPayload } from 'store/types';

export function useRemoveVariableGroupFromSet(activityPayload?: {
	groupName: string;
}): Operation<(payload: ActionPayload<RemoveVariableGroupFromSetAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(
		ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET,
		activityPayload
	);

	function handler(payload: ActionPayload<RemoveVariableGroupFromSetAction>) {
		dispatch(removeVariableGroupFromSet(payload));
	}

	return [{ loading, error }, handler];
}

import { useMemo } from 'react';
import styled from 'styled-components';

import { Variable } from 'api/data/variables';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useVariables } from 'hooks/store';

function requiredVariableExists(variables: Variable[]) {
	return !!variables.find(variable => variable.obligatory);
}

interface Props {
	customVariables?: Variable[];
}

export function RequiredFieldsLabel({ customVariables }: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { variablesOutsideSets }
		}
	] = useVariables({
		initial: true,
		lazy: true
	});

	const requiredExists = useMemo(
		() => requiredVariableExists(customVariables ?? variablesOutsideSets),
		[variablesOutsideSets, customVariables]
	);

	if (requiredExists) {
		return <RequiredLabel>{translate(dict => dict.addEditEntry.requiredFields)}</RequiredLabel>;
	}

	return null;
}

const RequiredLabel = styled(Typography.Paragraph)`
	padding: 1rem;
	margin-top: 2rem;

	&.disable {
		@media (max-width: 767px) {
			max-width: 150px;
		}
	}
`;

import { useDispatch, useStatic } from 'hooks/utils';
import { forceLogoutAction, ActionTypes } from 'store/auth';
import { OperationResult } from '../types';
import { useEffect } from 'react';
import { useActivity } from '../utils/useActivity';

export function useForceLogout(): OperationResult<null, () => void> {
	const dispatch = useDispatch();
	const [loading, setLoading] = useStatic(false);

	const [{ loading: startedActivity, error }] = useActivity(ActionTypes.TOKEN_EXPIRED_ERROR);
	useEffect(() => {
		setLoading(startedActivity);
	}, [startedActivity]);

	function handler() {
		dispatch(forceLogoutAction());
	}

	return [{ data: null, loading: loading(), error }, handler];
}

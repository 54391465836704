import { OperationResult } from 'hooks/store/types';
import { SubscriptionAddonCode } from 'types/index';
import { useProject } from '../projects/useProject';

export function useIsProjectAddonActive(
	userAddonCode: SubscriptionAddonCode
): OperationResult<boolean, null> {
	const [{ data: project, fetched, loading, error }] = useProject();

	const addonIsActive =
		project !== null &&
		project.activeAddonCodes &&
		project.activeAddonCodes.includes(userAddonCode)
			? true
			: false;

	return [{ loading, error, fetched, data: addonIsActive }, null];
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setFormId, FormId, SetFormIdAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormId(): LocalOperationResult<
	FormId,
	(formId: ActionPayload<SetFormIdAction>['formId']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.forms.formId);

	function handler(formId: ActionPayload<SetFormIdAction>['formId']) {
		dispatch(setFormId({ formId }));
	}

	return [data, handler];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionTypes, getDownloadableDataset } from 'store/data/entries';
import { Operation } from 'hooks/store/types';

type HandlerInput = Parameters<typeof getDownloadableDataset>[0];

export function useDownloadDataset(): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_DOWNLOADABLE_ENTRIES);

	function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(getDownloadableDataset(input));
	}

	return [{ loading, error }, handler];
}

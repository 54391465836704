import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';
import { updateAnalysis, UpdateAnalysisAction } from 'store/data/analyses';
import { ActionPayload } from 'store/types';

export function useUpdateAnalysis(): LocalOperation<
	(payload: ActionPayload<UpdateAnalysisAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<UpdateAnalysisAction>) {
		dispatch(updateAnalysis(payload));
	}

	return handler;
}

import { Control, useWatch } from 'react-hook-form';

import { AdminAccount } from 'api/account/admin';

import { AvatarContainer } from './AdminUserAvatar.style';
import { Avatar } from 'components/UI/Interactables/Avatar';

interface Props {
	control: Control<AdminAccount>;
}

export function AdminUserAvatar({ control }: Props) {
	const firstName = useWatch({
		control,
		name: 'userFirstName'
	});
	const lastName = useWatch({
		control,
		name: 'userSirName'
	});

	const id = useWatch({
		control,
		name: 'userid'
	});

	const avatarData = {
		userFirstName: firstName ?? '',
		userSirName: lastName ?? '',
		imageString: '',
		imageURL: ''
	};

	return (
		<AvatarContainer>
			<Avatar userId={id ?? null} data={avatarData} size={s => s.l} />
		</AvatarContainer>
	);
}

import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { Separator } from 'components/UI/Separator';

export const Container = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;

	@media ${MediaQueries.maxWidth.sm} {
		justify-content: space-evenly;

		div: nth-child(2) {
			flex-grow: 1;
		}
	}
`;

export const FlexSpaceBetween = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: fit-content;

	@media ${MediaQueries.maxWidth.sm} {
		display: initial;
		justify-content: initial;
	}
`;

export const MenuOption = styled.div<{ active?: boolean }>`
	height: 4rem;
	display: flex;
	cursor: pointer;
	padding: 0 2.4rem;
	align-items: center;
	justify-content: space-between;

	color: ${props => (props.active ? Colors.primary.hover : '')}};

	p {
		color: ${props => (props.active ? Colors.primary.hover : '')}};
	}
	:hover {
		background-color: ${Colors.primary.disabled};
	}
`;

export const UserDropDownChoicesMenu = styled.div`
	font-size: 1.6rem;
`;

export const Divider = styled(Separator)`
	&& {
		width: auto;
		margin-left: 2.4rem;
		margin-right: 2.4rem;
		margin-top: 1.8rem;
		margin-bottom: 1.8rem;
	}
`;

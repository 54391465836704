import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment/theme/Colors';
import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	grid-template-areas:
		'icon title title title title close'
		'icon content content content content close';
	background-color: ${Colors.background.errorDisable};
	padding: 0.9rem;

	margin-bottom: 3.2rem;
`;

export const FileName = styled(Typography.Paragraph)`
	grid-area: title;
	font-weight: bold;
	padding-top: 0.2rem;
`;

export const IconContainer = styled.div`
	grid-area: icon;
	display: flex;
	justify-content: center;
`;

export const CloseIconContainer = styled.div`
	grid-area: close;

	display: flex;
	justify-content: center;
`;

export const Content = styled.div`
	grid-area: content;
`;

export const WarningMessage = styled(Typography.Paragraph)`
	color: ${Colors.text.error};
	font-weight: 500;
	width: 80%;
`;

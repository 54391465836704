import { LocalOperationResult } from 'hooks/store/types';
import { useSelector, useDispatch } from 'hooks/utils';
import {
	ConflictedData,
	selectSeriesConflictedData,
	setSeriesConflictedData
} from 'store/data/entries';
import { Nullable } from 'types/index';

export function useSeriesConflictedData(): LocalOperationResult<
	Nullable<ConflictedData>,
	(data: Nullable<ConflictedData>) => void
> {
	const dispatch = useDispatch();

	function handler(data: Nullable<ConflictedData>) {
		dispatch(setSeriesConflictedData(data));
	}

	const data = useSelector(state => selectSeriesConflictedData(state.data.entries));

	return [data, handler];
}

import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { Table } from 'components/UI/Table';
import { variablesDataArrayJSXIterator, variablesDataArrayIterator } from 'helpers/variables';
import { VariablesDataArray } from 'store/data/variables';
import { NumberMap, BooleanMap, RequireOnlyOne } from 'types/index';
import { VariablesTableCheckedState } from 'hooks/store/data/variables/useVariablesTableCheckedData';
import { GroupRow } from './GroupRow';
import { VariableRow } from './VariableRow';
import { VariableSetRow } from './VariableSetRow';
import { CenteredCheckbox } from './styles';
import { useVariablesSearchTerm, useTranslation } from 'hooks/store';
import { usePrevious } from 'hooks/utils';

enum GenerateType {
	variable = 'variable',
	group = 'group',
	series = 'series'
}
interface GenerateIdItems {
	label: string;
	type: GenerateType;
}

interface Props {
	variablesDataArray: VariablesDataArray;
	expandedRowsData: {
		expandedRows: BooleanMap;
		toggleExpandedRow: (rowName: string) => void;
		expandRows: (rowNames: string[]) => void;
	};
	checkedRowsData: {
		checkedMap: {
			variables: BooleanMap;
			groups: BooleanMap;
			variableSets: BooleanMap;
		};
		checkedState: VariablesTableCheckedState;
		toggleChecked: (
			input: RequireOnlyOne<{
				variableName?: string | undefined;
				groupName?: string | undefined;
				setName?: string | undefined;
			}>
		) => void;
		toggleAllChecked: () => void;
	};
	rowNumberByName: {
		variables: NumberMap;
		groups: NumberMap;
		variableSets: NumberMap;
	};
	readOnly: boolean;
	hasErrors?: string[];
	onVariableClick: (variableName: string, setName?: string) => void;
	onGroupClick: (groupName: string) => void;
	onVariableSetClick: (setName: string) => void;
}

export function VariablesTable({
	variablesDataArray,
	expandedRowsData: { expandedRows, toggleExpandedRow, expandRows },
	checkedRowsData: { checkedMap, checkedState, toggleChecked, toggleAllChecked },
	rowNumberByName,
	readOnly,
	onVariableClick,
	onGroupClick,
	onVariableSetClick,
	hasErrors
}: Props) {
	const [searchTerm] = useVariablesSearchTerm();
	const { translate } = useTranslation();

	const prevFilteredVariablesDataArray = usePrevious(variablesDataArray);
	useEffect(() => {
		if (prevFilteredVariablesDataArray === undefined) return;

		if (isEqual(prevFilteredVariablesDataArray, variablesDataArray)) return;

		const isSearchTermValid = searchTerm.trim().length > 0;

		if (!isSearchTermValid) return;

		const rowNamesToExpand: string[] = [];

		variablesDataArrayIterator(
			variablesDataArray,
			// VARIABLE - OMIT
			() => null,
			// GROUP
			group => rowNamesToExpand.push(group.groupName),
			// VARIABLE SET
			variableSet => {
				rowNamesToExpand.push(variableSet.setName);

				variablesDataArrayIterator(
					variableSet.setData,
					// VARIABLE - OMIT
					() => null,
					// GROUP
					group => rowNamesToExpand.push(group.groupName),
					// VARIABLE SET - OMIT
					() => null
				);
			}
		);

		if (rowNamesToExpand.length) expandRows(rowNamesToExpand);
	}, [variablesDataArray, searchTerm]);

	function generateId(items: GenerateIdItems[]) {
		let id = '';
		items.forEach((item, index) => {
			let generatedLabel = item.label
				.replace(/[^a-zA-Z]/g, '')
				.substring(0, 2)
				.toLowerCase();
			if (generatedLabel.length !== 2) {
				generatedLabel = item.type.substring(0, 2).toLowerCase();
			}
			if (index !== items.length - 1) {
				id += `${generatedLabel}_`;
			} else {
				id += generatedLabel;
			}
		});
		return id;
	}

	return (
		<Table.Responsive>
			<Table fullWidth hoverEffect>
				<Table.Head>
					<Table.Row>
						{!readOnly && (
							<Table.Column
								width={5.6}
								minWidth={5.6}
								css={`
									cursor: pointer;
									/*
									RESETS THE COLUMN PADDING AND FLEX DIRECTION
									TO ALIGN CHECKBOX CORRECTLY
								*/
									padding-left: 0.8rem !important;
									padding-right: 0.8rem !important;
								`}
								onClick={toggleAllChecked}
							>
								<CenteredCheckbox
									checked={checkedState.all}
									partial={checkedState.partial.main}
									onClick={toggleAllChecked}
								/>
							</Table.Column>
						)}
						<Table.Column width={5.6} minWidth={5.6}>
							{translate(dict => dict.variablesPage.variablesTable.number)}
						</Table.Column>
						<Table.Column minWidth={20} maxWidth={50} width={50}>
							{translate(dict => dict.variablesPage.variablesTable.title)}
						</Table.Column>
						<Table.Column minWidth={15} maxWidth={20} width={20}>
							{translate(dict => dict.variablesPage.variablesTable.variableType)}
						</Table.Column>
						<Table.Column minWidth={20} maxWidth={30} width={30}>
							{translate(dict => dict.variablesPage.variablesTable.dataType)}
						</Table.Column>
						<Table.Column minWidth={20} maxWidth={40}>
							{translate(dict => dict.variablesPage.variablesTable.description)}
						</Table.Column>
					</Table.Row>
				</Table.Head>

				<Table.Body>
					{variablesDataArrayJSXIterator(
						variablesDataArray,
						/**
						 * VARIABLE
						 */
						variable => (
							<VariableRow
								key={`variable_${variable.name}`}
								id={generateId([
									{ label: variable.label, type: GenerateType.variable }
								])}
								variable={variable}
								rowNumber={rowNumberByName.variables[variable.name]}
								checkedData={{
									checked: checkedMap.variables[variable.name],
									onCheck: () =>
										toggleChecked({
											variableName: variable.name
										})
								}}
								readOnly={readOnly}
								onClick={() => onVariableClick(variable.name)}
								hasError={!!hasErrors?.includes(variable.name)}
							/>
						),
						/**
						 * GROUP DATA
						 */
						groupData => (
							<GroupRow
								key={`group_${groupData.groupName}`}
								id={generateId([
									{ label: groupData.groupLabel, type: GenerateType.group }
								])}
								groupData={groupData}
								rowNumber={rowNumberByName.groups[groupData.groupName]}
								expandedRowsData={{
									expanded: expandedRows[groupData.groupName],
									onExpand: () => toggleExpandedRow(groupData.groupName)
								}}
								checkedRowData={{
									checked: checkedMap.groups[groupData.groupName],
									partial: checkedState.partial.groups[groupData.groupName],
									onCheck: () =>
										toggleChecked({
											groupName: groupData.groupName
										})
								}}
								readOnly={readOnly}
								hasError={
									!!hasErrors?.some(variableName =>
										groupData.groupVariables.some(v => v.name === variableName)
									)
								}
								onClick={() => onGroupClick(groupData.groupName)}
								renderVariableRow={variable => (
									<VariableRow
										key={`group_${groupData.groupName}-variable_${variable.name}`}
										id={generateId([
											{
												label: groupData.groupLabel,
												type: GenerateType.group
											},
											{ label: variable.label, type: GenerateType.variable }
										])}
										variable={variable}
										checkedData={{
											checked: checkedMap.variables[variable.name],
											onCheck: () =>
												toggleChecked({
													variableName: variable.name
												})
										}}
										readOnly={readOnly}
										hasError={!!hasErrors?.includes(variable.name)}
										onClick={() => onVariableClick(variable.name)}
										isNested
									/>
								)}
							/>
						),
						/**
						 * VARIABLE SET DATA
						 */
						variableSetData => (
							<VariableSetRow
								key={`variable_set_${variableSetData.setName}`}
								id={generateId([
									{ label: variableSetData.setLabel, type: GenerateType.series }
								])}
								variableSetData={variableSetData}
								rowNumber={rowNumberByName.variableSets[variableSetData.setName]}
								expandedRowsData={{
									expanded: expandedRows[variableSetData.setName],
									onExpand: () => toggleExpandedRow(variableSetData.setName)
								}}
								checkedRowsData={{
									checked: checkedMap.variableSets[variableSetData.setName],
									partial:
										checkedState.partial.variableSets[variableSetData.setName],
									onCheck: () =>
										toggleChecked({
											setName: variableSetData.setName
										})
								}}
								readOnly={readOnly}
								onClick={() => onVariableSetClick(variableSetData.setName)}
								renderVariableRow={variable => (
									<VariableRow
										key={`variable_set_${variableSetData.setName}-variable_${variable.name}`}
										id={generateId([
											{
												label: variableSetData.setLabel,
												type: GenerateType.series
											},
											{ label: variable.label, type: GenerateType.variable }
										])}
										variable={variable}
										checkedData={{
											checked: checkedMap.variables[variable.name],
											onCheck: () =>
												toggleChecked({
													variableName: variable.name
												})
										}}
										readOnly={readOnly}
										hasError={!!hasErrors?.includes(variable.name)}
										onClick={() =>
											onVariableClick(variable.name, variableSetData.setName)
										}
										isNested
									/>
								)}
								renderGroupRow={groupData => (
									<GroupRow
										key={`variable_set_${variableSetData.setName}-group_${groupData.groupName}`}
										id={generateId([
											{
												label: variableSetData.setLabel,
												type: GenerateType.series
											},
											{
												label: groupData.groupLabel,
												type: GenerateType.group
											}
										])}
										groupData={groupData}
										expandedRowsData={{
											expanded: expandedRows[groupData.groupName],
											onExpand: () => toggleExpandedRow(groupData.groupName)
										}}
										checkedRowData={{
											checked: checkedMap.groups[groupData.groupName],
											partial:
												checkedState.partial.groups[groupData.groupName],
											onCheck: () =>
												toggleChecked({
													groupName: groupData.groupName
												})
										}}
										readOnly={readOnly}
										hasError={
											!!hasErrors?.some(variableName =>
												groupData.groupVariables.some(
													v => v.name === variableName
												)
											)
										}
										onClick={() => onGroupClick(groupData.groupName)}
										renderVariableRow={variable => (
											<VariableRow
												key={`variable_set_${variableSetData.setName}-group_${groupData.groupName}-variable_${variable.name}`}
												id={generateId([
													{
														label: variableSetData.setLabel,
														type: GenerateType.series
													},
													{
														label: groupData.groupLabel,
														type: GenerateType.group
													},
													{
														label: variable.label,
														type: GenerateType.variable
													}
												])}
												variable={variable}
												checkedData={{
													checked: checkedMap.variables[variable.name],
													onCheck: () =>
														toggleChecked({
															variableName: variable.name
														})
												}}
												readOnly={readOnly}
												hasError={!!hasErrors?.includes(variable.name)}
												onClick={() => onVariableClick(variable.name)}
												isNested={{ secondLevel: true }}
											/>
										)}
										isNested
									/>
								)}
							/>
						)
					)}
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

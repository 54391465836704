import { Variable } from 'api/data/variables';
import { useGroupByName, useVariableByName, useVariablesByName } from 'hooks/store';
import { useSelector, useDeepCompareMemo, useDispatch } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setDraggingVariableName } from 'store/data/variables';

export function useDraggingVariable(): LocalOperationResult<Variable[], (name: string) => void> {
	const dispatch = useDispatch();

	const name = useSelector(state => state.data.variables.metadata.dragging);

	const group = useGroupByName(name);
	const variable = useVariableByName(name);

	const groupVariables = useVariablesByName(group?.variablesBelongingToGroup ?? []);

	const data = useDeepCompareMemo(() => {
		if (group) {
			return groupVariables;
		}
		if (variable) {
			return [variable];
		}
		return [];
	}, [name, variable, groupVariables, group]);

	function handler(name: string) {
		dispatch(setDraggingVariableName({ name }));
	}

	return [data, handler];
}

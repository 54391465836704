import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import type { TabToRender } from './ProjectTabs/ProjectTabs';
import { NavTab as Tab, Text, NormalTab } from './Tab.style';
import { Select } from 'components/UI/Interactables/Select';
import { useTranslation } from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

const PROJECT_DESIGN_WIDTH = 126;

const tabsProps = {
	width: 10.9,
	underline_width: 3.6
};

interface Props {
	tabsToRender: TabToRender[];
	onTabClick: (route: string) => void;
	isTabDisabled: (label: string) => boolean;
}

export function MoreNavigation({ tabsToRender, onTabClick, isTabDisabled }: Props) {
	// Used to trigger render on window resize
	useWindowSize();

	const { pathname } = useLocation();
	const { translate } = useTranslation();
	const computedTabs = computeTabs(tabsToRender);

	const activeMoreDropdown = useMemo(
		() => computedTabs.more.some(tab => pathname === tab.value),
		[computedTabs.more, pathname]
	);

	return (
		<>
			{computedTabs.visible.map(tab => (
				<Tab
					key={tab.dataTestId}
					to={tab.value}
					{...tabsProps}
					title={tab.label}
					data-test-id={tab.dataTestId}
					data-testid={tab.dataTestId}
					disabled={isTabDisabled(tab.label)}
				>
					<div>
						<Text>{tab.label}</Text>
					</div>
				</Tab>
			))}

			{computedTabs.more.length > 0 && (
				<NormalTab width={10.9}>
					<Select
						title={translate(dict => dict.iconTooltip.more)}
						className="dropdown-tab"
						items={computedTabs.more.map(tab => ({
							label: tab.label,
							value: tab.value
						}))}
						type={s => s.Tab(activeMoreDropdown)}
						// disableItem={item => !isItemEnabled(item.label)}
						activeItem={item => pathname === item.value}
						onSelect={item => onTabClick(item.value as string)}
					/>
				</NormalTab>
			)}
		</>
	);
}

function computeTabs(tabsToRender: TabToRender[]) {
	const visible: TabToRender[] = cloneDeep(tabsToRender);
	const more: TabToRender[] = [];

	const header = document.querySelector('.header-navigation');
	const headerStyle = header && window.getComputedStyle(header);

	const headerPadding = headerStyle
		? parseInt(headerStyle.paddingLeft, 10) + parseInt(headerStyle.paddingRight, 10)
		: 0;
	const headerWidth = (header?.clientWidth ?? 0) - headerPadding;

	const rightComponent = document.querySelector('.header-navigation__right-component');
	const rightComponentWidth = rightComponent
		? rightComponent.getBoundingClientRect().width ?? 0
		: 0;

	const spaceAvailableForTabs = headerWidth - rightComponentWidth - PROJECT_DESIGN_WIDTH - 110;

	const visibleTabsWidth = () => visible.length * (tabsProps.width * 10);

	if (spaceAvailableForTabs > 0) {
		while (visibleTabsWidth() > spaceAvailableForTabs) {
			const tab = visible.pop();

			if (tab) more.unshift(tab);
		}
	} else {
		return {
			visible: [],
			more: tabsToRender
		};
	}

	return { visible, more };
}

import { useState } from 'react';
import { useEffectOnce } from 'hooks/utils';
import { EventType } from 'types/index';
import { isOnWindows } from 'helpers/generic';

export const useScrollPosition = () => {
	const [scrollPosition, setScrollPosition] = useState({
		scrollTop: 0,
		scrollLeft: 0
	});

	const isWindows = isOnWindows();

	function scrollHandler(event: any) {
		const bodyHeight = document.querySelector('body')?.scrollTop;
		setScrollPosition({
			scrollTop: isWindows ? event.target.scrollTop : bodyHeight ?? window.pageYOffset,
			scrollLeft: event.target.scrollLeft
		});
	}

	useEffectOnce(() => {
		if (isWindows) {
			document.body.addEventListener(EventType.Scroll, scrollHandler, {
				capture: true
			});

			return () => {
				document.body.removeEventListener(EventType.Scroll, scrollHandler, {
					capture: true
				});
			};
		} else {
			window &&
				window.addEventListener(EventType.Scroll, scrollHandler, {
					capture: true
				});

			return () => {
				window &&
					window.removeEventListener(EventType.Scroll, scrollHandler, {
						capture: true
					});
			};
		}
	});

	return {
		...scrollPosition
	};
};

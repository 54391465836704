import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { setCBInstantiated, WindowDOMwithChargebee } from 'store/account/payments';
import { ActionTypes } from 'store/account/payments';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useCBInstance(): OperationResult<boolean> {
	const dispatch = useDispatch();
	const chargebeeSite = process.env.REACT_APP_CHARGEBEE_DATA_CB_SITE;
	const chargebeeKey = process.env.REACT_APP_CHARGEBEE_DATA_PUBLISH_API_KEY;

	const data = useSelector(state => state.account.payments.cbInstantiated);

	const fetched = data !== null;

	const [{ loading, error }] = useActivity(ActionTypes.GET_USER_BILLING_INFO);

	useEffect(() => {
		if (!fetched && !loading && !error) {
			(window as WindowDOMwithChargebee).Chargebee?.init({
				site: chargebeeSite, // test site
				publishableKey: chargebeeKey
			});
			handler();
		}
	}, [fetched, loading, error]);

	function handler() {
		dispatch(setCBInstantiated());
	}

	return [{ loading, error, fetched, data }, handler];
}

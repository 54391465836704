import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const CardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	gap: 1.6rem 2.2rem;
	padding-bottom: 2.4rem;

	@media ${MediaQueries.maxWidth.hd} {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.xl} {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.lg} {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.md} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.sm} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	&& .tabs__navigation__item {
		height: 7.2rem;
		width: 14.3rem;
		min-width: 14.3rem;
	}
`;

import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { selectActiveAnalysisIds } from 'store/data/analyses';
import { selectActiveFilterIds, selectAreFiltersValid } from 'store/data/filters';
import { clearSnapshot, selectActiveSnapshot, updateSnapshot } from 'store/data/snapshots';

import { CreateSnapshotModal } from '../CreateSnapshotModal';
import { DeleteSnapshotModal } from '../DeleteSnapshotModal';

import { Container } from './SnapshotOptions.style';
import { useDispatch, useSelector } from 'hooks/utils';
import { useTranslation, useAnalysesCleanup } from 'hooks/store';
import { useAlerts, useModalState } from 'hooks/ui';

export function SnapshotOptions() {
	const dispatch = useDispatch();
	const { setError } = useAlerts();
	const { translate } = useTranslation();

	const cleanAnalyses = useAnalysesCleanup();

	const createSnapshotModal = useModalState();
	const deleteSnapshotModal = useModalState<{ snapshotId: string }>();

	const activeFilters = useSelector(state => selectActiveFilterIds(state.data.filters));
	const activeAnalyses = useSelector(state => selectActiveAnalysisIds(state.data.analyses));
	const activeSnapId = useSelector(state => selectActiveSnapshot(state.data.snapshots));
	const valid = useSelector(state => selectAreFiltersValid(state.data.filters));

	const activeFiltersOrAnalyses =
		activeFilters.length > 0 || (activeAnalyses && activeAnalyses.length > 0) || false;

	function handleSaveAs() {
		if (valid) {
			createSnapshotModal.open();
		} else {
			setError({
				message: translate(({ analysis }) => analysis.errors.snapshots.saveError)
			});
		}
	}

	function handleSave(snapshotId: string) {
		if (valid) {
			dispatch(updateSnapshot(snapshotId));
		} else {
			setError({
				message: translate(({ analysis }) => analysis.errors.snapshots.saveError)
			});
		}
	}

	return (
		<Container>
			<Dropdown
				toggleComponent={({ ref, toggle }) => (
					<Icon
						variant={v => v.button}
						ref={ref}
						svg={Svgs.More}
						disabled={!activeFiltersOrAnalyses && !activeSnapId}
						onClick={() => {
							if (activeSnapId || activeFiltersOrAnalyses) {
								toggle();
							}
						}}
					/>
				)}
				width={15}
				offset={{ right: 15, top: 20 }}
			>
				{activeSnapId && (
					<Dropdown.Item disabled={!valid} onClick={() => handleSave(activeSnapId)}>
						{translate(({ buttons }) => buttons.save)}
					</Dropdown.Item>
				)}
				{activeFiltersOrAnalyses && (
					<Dropdown.Item disabled={!valid} onClick={handleSaveAs}>
						{translate(({ buttons }) => buttons.saveAs)}
					</Dropdown.Item>
				)}
				{(activeSnapId || activeFiltersOrAnalyses) && (
					<Dropdown.Item
						onClick={() => {
							cleanAnalyses();
							dispatch(clearSnapshot());
						}}
					>
						{translate(({ buttons }) => buttons.clear)}
					</Dropdown.Item>
				)}
				{activeSnapId && (
					<Dropdown.Item
						onClick={() => deleteSnapshotModal.open({ snapshotId: activeSnapId })}
					>
						{translate(({ buttons }) => buttons.delete)}
					</Dropdown.Item>
				)}
			</Dropdown>

			{/* MODAL - CREATE SNAPSHOT */}
			{createSnapshotModal.visible && (
				<CreateSnapshotModal onClose={createSnapshotModal.close} />
			)}

			{/* MODAL - DELETE SNAPSHOT */}
			{deleteSnapshotModal.visible && (
				<DeleteSnapshotModal
					snapshotId={deleteSnapshotModal.payload?.snapshotId ?? ''}
					onClose={deleteSnapshotModal.close}
				/>
			)}
		</Container>
	);
}

import React from 'react';
import { InputType } from 'types/index';
import { Label, Stack } from './FormDesignerDrawer.style';
import { Input } from 'components/UI/Inputs/Input';
import { Title } from './FormDesignerDrawer.style';

interface ValidationRangeSectionProps {
	minValue?: string;
	maxValue?: string;
}

const ValidationRangeSection: React.FC<ValidationRangeSectionProps> = ({ minValue, maxValue }) => {
	return (
		<>
			<Title marginOffset={{ top: 1.6 }}>Validation Range</Title>
			<div style={{ display: 'flex', gap: '16px' }}>
				<Stack>
					<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>Minimum value</Label>
					<Input
						name="Minimum value"
						type={InputType.Number}
						value={minValue || ''}
						disabled
					/>
				</Stack>
				<Stack>
					<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>Maximum value</Label>
					<Input
						name="Maximum value"
						type={InputType.Number}
						value={maxValue || ''}
						disabled
					/>
				</Stack>
			</div>
		</>
	);
};

export default ValidationRangeSection;

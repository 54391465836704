import { useState, useEffect, useMemo } from 'react';

import { Variable } from 'api/data/variables';
import { withMemo } from 'helpers/HOCs';
import { SetGroupPaginationDataInput, FormGroupPaginationDataByname } from 'store/data/entries';
import { GroupData } from 'store/data/variables';
import { Revision } from 'store/data/revisions';
import { GetMutableState } from 'types/index';
import { AddEditGroupTitle } from './AddEditGroupTitle';
import { CollapsibleGroup, InputGroup, Divider } from './AddEditInputGroup.style';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { usePaginate } from 'hooks/utils';

interface Props {
	groupData: GroupData;
	expanded: boolean;
	revision?: Revision;
	isRevisionSelected?: boolean;
	isSeriesEntryForm?: boolean;
	dataTestId?: string;
	setGroupExpanded: (groupName: string, value?: boolean) => void;
	getGroupPaginationDataByName: GetMutableState<FormGroupPaginationDataByname>;
	setGroupPaginationData: (input: SetGroupPaginationDataInput) => void;
	renderVariable: (variable: Variable, index: number) => React.ReactNode;
}

function Component({
	groupData,
	expanded,
	revision,
	isRevisionSelected,
	isSeriesEntryForm,
	dataTestId,
	setGroupExpanded,
	getGroupPaginationDataByName,
	setGroupPaginationData,
	renderVariable
}: Props) {
	const [groupExpandedLocal, setGroupExpandedLocal] = useState(expanded);

	const { groupName, groupLabel, groupVariables } = groupData;

	const groupPaginationData = getGroupPaginationDataByName()[groupName];

	let startPageSize: number | undefined;
	let startPageIndex: number | undefined;

	if (groupPaginationData) {
		startPageSize = groupPaginationData.pageSize;
		startPageIndex = groupPaginationData.pageIndex;
	}

	const revisionStartIndexPage = useMemo(() => {
		let page = 0;

		groupVariables.forEach(variable => {
			if (revision?.changes.variableNames.includes(variable.name) && isRevisionSelected) {
				page = (groupVariables.indexOf(variable) + 1) / (startPageSize ?? 25);
			}
		});
		if (Math.round(page) === 0) {
			return 0;
		}
		return Math.round(page) < page ? Math.round(page) : Math.round(page) - 1;
	}, [groupVariables, revision]);

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, changePageSize } =
		usePaginate(groupVariables, {
			threshold: 25,
			pageSize: startPageSize ?? 25,
			startPageIndex
		});

	useEffect(() => {
		handleChangePage(revisionStartIndexPage);
	}, [revisionStartIndexPage, revision]);

	// SYNC `groupExpandedLocal` STATE
	useEffect(() => {
		if (expanded !== groupExpandedLocal) setGroupExpandedLocal(expanded);
	}, [expanded]);

	function handleExpandedState(value: boolean) {
		setGroupExpandedLocal(value);

		// UPDATE MASTER STATE
		setGroupExpanded(groupName, value);
	}

	function handleChangePage(index: number) {
		changePage(index);

		// UPDATE MASTER STATE
		setGroupPaginationData({
			groupName,
			paginationData: {
				pageIndex: index,
				pageSize
			}
		});
	}

	function handleChangePageSize(size: number) {
		changePageSize(size);

		// UPDATE MASTER STATE
		setGroupPaginationData({
			groupName,
			paginationData: {
				pageIndex: 0,
				pageSize: size
			}
		});
	}

	const hasVariables = groupVariables.length > 0;

	const hasChanges = useMemo(() => {
		if (
			groupVariables.filter(
				variable => !!revision?.changes.variableNames.includes(variable.name)
			).length > 0
		) {
			return true;
		}
		return false;
	}, [revision]);

	function dataTestIdValue() {
		if (dataTestId) {
			return dataTestId.replace(/\s/g, '').toLowerCase();
		}
		return groupName.replace(/\s/g, '').toLowerCase();
	}

	if (!hasVariables) return null;

	return (
		<Flex id={groupName} data-test-id={dataTestIdValue()} column fullWidth>
			<CollapsibleGroup
				isSeriesEntryForm={isSeriesEntryForm}
				hasChanges={hasChanges && !!isRevisionSelected && !groupExpandedLocal}
				key={`add-edit-form-group-${groupName}`}
				titleComponent={
					<AddEditGroupTitle title={groupLabel} groupVariables={groupVariables} />
				}
				expanded={groupExpandedLocal}
				onChange={value => handleExpandedState(value)}
			>
				{shouldPaginate && (
					<Flex paddingOffset={{ x: 1 }} marginOffset={{ y: 0.8 }} fullWidth column>
						<Pagination
							totalCountLabel="group fields"
							pageIndex={pageIndex}
							pageSize={pageSize}
							pagesCount={pagesCount}
							changePage={handleChangePage}
							changePageSize={handleChangePageSize}
							showCounts={false}
						/>

						<Divider />
					</Flex>
				)}

				<InputGroup>{page.map(renderVariable)}</InputGroup>
			</CollapsibleGroup>
		</Flex>
	);
}

export const AddEditInputGroup = withMemo(Component);

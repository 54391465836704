// exports

export function numberToFixed(
	value: number | string,
	options?: {
		decimals?: number;
		roundDecimals?: boolean;
		removeLeadingZero?: boolean;
		removeTrailingZeros?: boolean;
	}
): string {
	const {
		decimals = 3,
		roundDecimals = true,
		removeLeadingZero = false,
		removeTrailingZeros = true
	} = options ?? {};

	let parsedValue = '';

	if (roundDecimals) {
		const roundedValue =
			Math.round((Number(value) + Number.EPSILON) * Math.pow(10, decimals)) /
			Math.pow(10, decimals);

		parsedValue = `${roundedValue}`;
	} else {
		// Show only up to N decimals
		parsedValue = Number(value).toFixed(decimals);
	}

	// Remove trailing zeros
	if (removeTrailingZeros) parsedValue = handleRemoveTrailingZeros(parsedValue);

	// Remove leading zero
	if (removeLeadingZero) parsedValue = handleRemoveLeadingZero(parsedValue);

	return parsedValue;
}

/**
 * Removes trailing zero's from fractional part
 */
export function handleRemoveTrailingZeros(value: number | string): string {
	let parsedValue = value.toString();

	const hasDecimals = parsedValue.includes('.');

	if (hasDecimals) {
		parsedValue = parsedValue.replace(/0+$/, '');

		const [, decimals] = parsedValue.split('.');

		/**
		 * Removes the remaining `.` after removing all the trailing zero's
		 */
		if (decimals !== undefined && decimals === '') {
			parsedValue = parsedValue.replace('.', '');
		}
	}

	return parsedValue;
}

/**
 * Removes leading zero if
 * - the number is between 0 and < 1 (positive range)
 * - the number is between > -1 and < 0 (negative range)
 */
export function handleRemoveLeadingZero(value: number | string): string {
	let parsedValue = value.toString();

	const numberValue = Number(parsedValue);

	const positiveRange = numberValue > 0 && numberValue < 1;
	const negativeRange = numberValue > -1 && numberValue < 0;

	if (positiveRange || negativeRange) parsedValue = parsedValue.replace('0.', '.');

	return parsedValue;
}

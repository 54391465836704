import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, createVariableCategoryValue } from 'store/data/variables';

type HandlerInput = Parameters<typeof createVariableCategoryValue>[0];

export function useCreateVariableCategoryValue(): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE
	);

	function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(createVariableCategoryValue(input));
	}

	return [{ loading, error }, handler];
}

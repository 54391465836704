import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	changeUserLicenceModel,
	ChangeUserLicenceModelAction
} from 'store/account/subscription';
import { ActionPayload } from 'store/types';

export function useChangeUserLicenceModel(): Operation<
	(payload: ActionPayload<ChangeUserLicenceModelAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CHANGE_USER_LICENCE_MODEL);

	function handler(payload: ActionPayload<ChangeUserLicenceModelAction>) {
		dispatch(changeUserLicenceModel(payload));
	}

	return [{ loading, error }, handler];
}

import styled, { css } from 'styled-components';

import { Colors, Shadows, MediaQueries } from 'environment';
import { getTypographyCSS } from 'components/UI/Typography';

interface IDropZone {
	highlighted?: boolean;
	forbiddenHighlighted: boolean;
}

interface WriteAccessProps {
	writeAccess: boolean;
}

export const DragIcon = styled.img`
	width: 5rem;
	object-fit: contain;
	margin-right: 1rem;
`;

export const DroppableZoneWrapper = styled.div<IDropZone>`
	width: 60%;
	height: 6.4rem;
	display: flex;
	justify-content: center;
	border-radius: 0.8rem;
	padding: 0 2rem;
	opacity: 1;
	background-color: ${({ highlighted, forbiddenHighlighted }) =>
		highlighted && !forbiddenHighlighted ? Colors.blue.lightest : Colors.silver.lightest};
	border: 0.1rem dashed
		${({ highlighted }) => (highlighted ? Colors.blue.lightest : Colors.gray.lighter)};

	${({ forbiddenHighlighted }) =>
		forbiddenHighlighted &&
		css`
			opacity: 0.6;
		`}

	@media ${MediaQueries.maxWidth.md} {
		height: inherit;
	}
`;

export const DropzoneContainer = styled.div`
	display: flex;
	padding: 1rem 0;
	width: 100%;
	justify-content: center;
`;

export const DropZoneMessage = styled.div<IDropZone>`
	${getTypographyCSS(t => t.Paragraph)}

	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	color: ${Colors.gray.dark};

	${({ forbiddenHighlighted }) =>
		forbiddenHighlighted &&
		css`
			margin-left: 0.5rem;
		`}
`;

interface CardProps {
	expanded: boolean;
}

export const TemplateCard = styled.div<CardProps>`
	width: 100%;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	display: flex;
	flex-direction: column;
	margin-bottom: 0.8rem;
	position: relative;
	background-color: ${Colors.white};
	align-items: center;
	transition: box-shadow 0.05s;
	padding: 0.8rem;

	:hover {
		box-shadow: ${Shadows.hover};

		.card-more-icon,
		.import-template-icon {
			visibility: visible;
		}
	}

	${({ expanded }) =>
		expanded &&
		css`
			box-shadow: ${Shadows.hover};
		`}
`;

export const TabsContainer = styled.div`
	display: flex;
	align-self: center;
	width: 34rem;
	height: 5rem;
	max-width: 100%;

	> :first-child {
		> :first-child {
			> div {
				> :first-child {
					font-weight: 600;
				}
			}
		}
	}
`;

export const DescriptionContainer = styled.div<WriteAccessProps>`
	width: 100%;
	display: flex;
	background-color: ${Colors.white};

	.description-input textarea {
		background-color: ${Colors.white};
		border: 0.2rem solid ${Colors.gray.light};
		opacity: 1;
		margin: 2.2rem;
	}

	${({ writeAccess }) =>
		!writeAccess &&
		css`
			.description-input {
				pointer-events: none;
			}
		`}
`;

export const TemplateBody = styled.div`
	width: 100%;
	border-radius: 0.4rem;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: ${Colors.white};
	align-items: center;
	justify-content: center;
`;

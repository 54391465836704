import * as React from 'react';
import { Colors } from 'environment';
import { ScalesLabels } from 'types/index';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { decodeURIComponentSafe } from 'helpers/generic';
import { DEFAULT_MAX_LABELS } from 'consts';

export function toCeil(number: number) {
	if (number < 0) {
		return Math.floor(number * 10) / 10;
	}

	if (number >= 0 && number < 1) {
		return Math.ceil(number * 10) / 10;
	} else {
		const n = number.toFixed(0).toString().length;
		if (n >= 1 && n < 2) {
			return Math.ceil(number / 5) * 5;
		} else if (n === 2) {
			return Math.ceil(number / 10) * 10;
		} else {
			const pow = 5 * 10 ** (n - 2);
			return Math.ceil(number / pow) * pow;
		}
	}
}

export function toFloor(number: number) {
	const n = number.toFixed(0).toString().length - 1;
	if (n < 2) {
		return Math.floor(number / 10) * 10;
	}
	const pow = 10 ** n;
	return Math.floor(number / pow) * pow;
}

export function getMaxAndMinInStringArray(arr: string[]) {
	const numArr: number[] = [];
	arr.forEach(stringNumber => {
		if (!isNaN(Number(stringNumber))) {
			numArr.push(Number(stringNumber));
		}
	});
	const min = Math.min(...numArr);
	const max = Math.max(...numArr);
	return { min, max };
}

export function getMaxAndMinInArray(arr: number[]) {
	const numArr: number[] = [];
	arr.forEach(number => {
		if (!isNaN(number)) {
			numArr.push(number);
		}
	});
	const min = Math.min(...numArr);
	const max = Math.max(...numArr);
	return { min, max };
}

export function getScaleRange(values: number[]) {
	const { min, max } = getMaxAndMinInArray(values);

	const range = max - min;
	const paddingPercentage = 0.05;

	return {
		min: min - range * paddingPercentage,
		max: max + range * paddingPercentage
	};
}

export function computeLegend(labels: string[]) {
	const maxCategoryChars = Math.max(...labels.map(el => el.length));
	const maxItems = labels.length ?? null;

	let maxChars = null;
	if (10 < maxItems) {
		maxChars = 10;
	} else if (15 < maxCategoryChars) {
		maxChars = 15;
	}

	const legendItem = {
		maxChars: maxChars ? maxChars : null
	};
	const legendTooltip = maxChars
		? {
				text: '%t',
				maxWidth: '200'
		  }
		: null;
	return [legendItem, legendTooltip];
}

export function computeScalesLabels(scalesLabels: ScalesLabels) {
	let labelX = null;
	let labelY = null;

	if (scalesLabels.labelX) {
		labelX = {
			text: decodeURIComponentSafe(scalesLabels.labelX),
			maxChars: 60
		};
	}
	if (scalesLabels.labelY) {
		labelY = {
			text: decodeURIComponentSafe(scalesLabels.labelY),
			maxChars: 60
		};
	}

	return [labelX, labelY];
}

export function computeLabels(labels: string[], maximumLengthLabels: boolean) {
	const maxCategoryChars = Math.max(...labels.map(el => el.length));
	const maxItems = labels.length ?? null;

	let fontAngle = 0;
	let maxChars = null;

	if (maxCategoryChars < 4 && labels.length < 20) {
		fontAngle = 0;
		maxChars = null;
	} else if (labels.length > 5 && labels.length <= 10) {
		fontAngle = -45;
		maxChars = 6;
	} else if (labels.length > 10) {
		fontAngle = 90;
		maxChars = 6;
	} else if (labels.length <= 5 && maxCategoryChars > 10) {
		maxChars = maximumLengthLabels ? 28 : 8;
	}

	const tooltip = maxChars
		? {
				text: '%v',
				borderRadius: 5,
				borderWidth: 1,
				padding: 10,
				borderColor: Colors.gray.lighter,
				maxWidth: '200'
		  }
		: null;

	return [maxItems, maxChars, fontAngle, tooltip];
}

export function computeCellValue(
	value: number | string | null,
	options?: { noDecimals?: boolean; duration?: boolean }
): React.ReactElement {
	const { noDecimals = false, duration = false } = options ?? {};
	if (value === undefined || value === null) return <React.Fragment>{'N/A'}</React.Fragment>;
	value = value.toString();

	if (!duration) {
		const { operator, value: parsedValue, tenExponent } = parseAnalysisNumber(value);

		let result = '';

		if (operator !== '=') result += `${operator} `;

		result += parsedValue;

		let parsedResult: string | number;

		if (noDecimals) {
			parsedResult = parseInt(result.replace(/\s/g, ''));
		} else {
			parsedResult = result;
		}

		return (
			<div
				style={{
					height: '22px',
					maxHeight: '22px',
					display: 'flex'
				}}
			>
				{parsedResult}
				{tenExponent !== 0 && (
					<>
						*10<sup style={{ lineHeight: 1, alignSelf: 'unset' }}>{tenExponent}</sup>
					</>
				)}
			</div>
		);
	}
	return (
		<div
			style={{
				height: '22px',
				maxHeight: '22px',
				display: 'flex'
			}}
		>
			{value}
		</div>
	);
}
export function computeCellValueForExport(
	value: number | string | null,
	options?: { noDecimals?: boolean; duration?: boolean }
) {
	const { noDecimals = false, duration = false } = options ?? {};
	if (value === undefined || value === null) return 'N/A';
	value = value.toString();

	if (!duration) {
		const { operator, value: parsedValue, tenExponent } = parseAnalysisNumber(value);

		let result = '';

		if (operator !== '=') result += `${operator} `;

		result += parsedValue;

		let parsedResult: string | number;

		if (noDecimals) {
			parsedResult = parseInt(result);
		} else {
			parsedResult = result;
		}

		return `${parsedResult}${tenExponent !== 0 ? `*10^${tenExponent}` : ``}`;
	}
	return value;
}

export type Params = {
	scaleXValues: string[];
	scalesLabels: ScalesLabels;
	activeColumn: number;
	fullscreen: boolean;
	yMaxValue: number;
	yMinValue: number;
	maxY?: number;
	minY?: number;
	itemsOverlap?: boolean;
};

/**
 * Scales a range by a percentage
 * used to compute charts' y-axis scale
 * @param min lower interval range
 * @param max upper interval range
 * @param percentage default = 0.03(3%); possible values inside [0,1]
 * @returns return new [min,max] scaled by percentage
 */
function computeScaleRange(min: number, max: number, percentage = 0.03) {
	// edge cases => both ends are negative/positive
	const delta = (max - min) * percentage;
	let start = min - delta;
	let end = max + delta;
	// only 1 value
	if (start === end) {
		if (start >= 0) {
			start = 0;
		} else {
			end = 0;
		}

		return [start, end];
	}
	if (max >= 0 && min === 0) start = 0;
	if (min <= 0 && max === 0) end = 0;

	if (min >= 0 && max >= 0) return [0, end];
	if (min <= 0 && max <= 0) return [start, 0];
	return [start, end];
}

export const getChartOptions = (params: Params) => {
	const MAX_NUMBER_FORMATTING = 100000;
	const MIN_NUMBER_FORMATTING = 0.00001;
	const {
		scaleXValues,
		scalesLabels,
		activeColumn,
		fullscreen,
		yMaxValue,
		yMinValue,
		maxY,
		minY,
		itemsOverlap
	} = params;

	const [maxItems, maxChars, fontAngle, tooltip] = computeLabels(
		scaleXValues,
		activeColumn === 1 || fullscreen
	);
	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	const [minPlotHeight, maxPlotHeight] = computeScaleRange(yMinValue, yMaxValue);

	let showExponent = false;
	if (
		yMaxValue > MAX_NUMBER_FORMATTING ||
		(yMaxValue < 0 && yMaxValue < -MAX_NUMBER_FORMATTING) ||
		(yMinValue > 0 && yMinValue < MIN_NUMBER_FORMATTING) ||
		(yMinValue < 0 && yMinValue > MIN_NUMBER_FORMATTING)
	) {
		showExponent = true;
	}
	return {
		scaleX: {
			label: labelX,
			labels: scaleXValues,
			itemsOverlap: itemsOverlap ? itemsOverlap : false,
			maxItems: activeColumn === 3 && !fullscreen ? DEFAULT_MAX_LABELS : maxItems,
			item: {
				fontAngle,
				maxChars
			},
			tooltip,
			exponent: showExponent
		},
		scaleY: {
			label: labelY,
			maxValue: maxY ? maxY : maxPlotHeight,
			step: null,
			exponent: showExponent,
			minValue: minY ? minY : minPlotHeight
		},

		plotarea: {
			marginLeft: yMaxValue >= 10000 ? 75 : undefined,
			marginRight: yMaxValue >= 10000 ? 40 : undefined
		}
	};
};

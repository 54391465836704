import { Fragment, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { useCreateEnterpriseRole, useTranslation, useUpdateEnterpriseRole } from 'hooks/store';
import {
	EnterpriseRole,
	ModulesPermissions,
	RolePermissions,
	UserManagement,
	UserActivity,
	EnterpriseAdminRoles,
	GeneralRolePermissions,
	NewEnterpriseRole
} from 'store/account/enterprise';
import { useCompletedAction, useMediaQuery, useMutableState } from 'hooks/utils';

import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { Flex } from 'components/UI/Flex';
import { Switch } from 'components/UI/Interactables/Switch';
// import { Tabs, Tab } from 'components/UI/Modal/Modal.style';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Icon } from 'components/UI/Icons';
import { GenericMap, InputType, SelectItem } from 'types/index';
import { Colors, MediaQueries, Svgs } from 'environment';

import {
	getInitialCardsBatchExpanded,
	isEnterpriseAdminBatch,
	isUserActivityBatch,
	isUserManagementBatch,
	newRole
} from './helpers';

import { CardWrapper, Row } from './EnterpriseRoleModal.style';
import { useAlerts, useReactForm } from 'hooks/ui';
import { getEnterpriseRoleNameSchema } from 'helpers/subscription';
import produce from 'immer';

enum Views {
	GeneralPermissions = 'GeneralPermissions',
	AdminPermissions = 'AdminPermissions'
}

type ViewsType = keyof typeof Views;

interface Props {
	role?: EnterpriseRole;
	predefinedRoles?: GenericMap<EnterpriseRole>;
	onClose: (success?: true) => void;
}

export function EnterpriseRoleModal({ role, predefinedRoles, onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [mutableRole, setMutableRole] = useMutableState(role ?? newRole);
	const [selectedPredefinedRoleId, setSelectedPredefinedRoleId] = useState<string | null>(null);
	// const [permissionsCardIndexExpanded, setPermissionsCardIndexExpanded] = useMutableState(0);
	const [permissionsCardIndexExpanded, setPermissionsCardIndexExpanded] = useMutableState(
		getInitialCardsBatchExpanded(Object.keys(mutableRole.permissions))
	);
	const [unsavedChangesModalVisible, setUnsavedChangesModalVisible] = useMutableState(false);
	const tabViewsLabels = {
		[Views.GeneralPermissions]: translate(
			({ enterpriseAdmin }) =>
				enterpriseAdmin.rolesModules.enterpriseRolesModal.generalPermissions
		),
		[Views.AdminPermissions]: translate(
			({ enterpriseAdmin }) =>
				enterpriseAdmin.rolesModules.enterpriseRolesModal.adminPermissions
		)
	};

	const validationSchema = getEnterpriseRoleNameSchema({ translate });
	const {
		Form: RoleForm,
		handleSubmit: handleRoleSubmit,
		isDirtyAndValid: canSubmitRoleForm,
		errors,
		register
	} = useReactForm({
		initialValues: { roleName: mutableRole.roleName.trim() ?? '' },
		validationSchema: validationSchema
	});
	const tabsOrder = [tabViewsLabels.GeneralPermissions, tabViewsLabels.AdminPermissions];
	const [
		modalPermissionsView
		// , setModalPermissionsView
	] = useState<ViewsType>(tabsOrder[1]);

	const [
		{ loading: creatingEnterpriseRole, error: errorCreatingEnterpriseRole },
		createEnterpriseRole
	] = useCreateEnterpriseRole();
	const [
		{ loading: updatingEnterpriseRole, error: errorUpdatingEnterpriseRole },
		updateEnterpriseRole
	] = useUpdateEnterpriseRole();
	const isEditModal = Number(role?.roleId ?? '-1') > 0;
	const loading = isEditModal ? updatingEnterpriseRole : creatingEnterpriseRole;
	const error = isEditModal ? errorUpdatingEnterpriseRole : errorCreatingEnterpriseRole;

	// When selecting predefined role name, automatically load permissions preset
	useEffect(() => {
		if (selectedPredefinedRoleId && predefinedRoleSelected)
			setMutableRole(state => ({
				...state,
				permissions: predefinedRoleSelected.permissions
			}));
	}, [selectedPredefinedRoleId]);

	useCompletedAction(loading, error, () => {
		setNotification({
			message: translate(({ enterpriseAdmin }) =>
				isEditModal
					? enterpriseAdmin.rolesModules.api.roleUpdatedSuccesfully
					: enterpriseAdmin.rolesModules.api.roleCreatedSuccesfully
			)
		});
		onClose(true);
	});

	const onRoleSubmit = handleRoleSubmit(roleToSubmit => {
		const { roleName } = roleToSubmit;
		if (hasChanges)
			if (isEditModal) {
				const roleToSubmit: EnterpriseRole = {
					...mutableRole,
					roleId: role?.roleId ?? '',
					roleName
				};
				updateEnterpriseRole(roleToSubmit);
			} else {
				const roleToSubmit: NewEnterpriseRole = {
					...mutableRole,
					roleName: roleName ?? ''
				};
				createEnterpriseRole(roleToSubmit);
			}
		onClose();
	});

	function toggleBatchCard(cardKey: string) {
		setPermissionsCardIndexExpanded(prevState =>
			produce(prevState, draft => {
				// Object.keys(draft).forEach(key => {
				draft[cardKey] = !prevState[cardKey];
				// });
			})
		);
	}

	function handleTogglePermission(key: keyof RolePermissions, permission: string) {
		if (selectedPredefinedRoleId) setSelectedPredefinedRoleId(null);
		setMutableRole &&
			setMutableRole(state => {
				if (!state) return;
				const { permissions } = state;
				const permissionsBatch = permissions[key];
				if (isUserManagementBatch(permissionsBatch)) {
					const prevPermissionState =
						permissionsBatch[permission as keyof UserManagement];
					permissionsBatch[permission as keyof UserManagement] = !prevPermissionState;
				} else if (isUserActivityBatch(permissionsBatch)) {
					const prevPermissionState = permissionsBatch[permission as keyof UserActivity];
					permissionsBatch[permission as keyof UserActivity] = !prevPermissionState;
				} else if (isEnterpriseAdminBatch(permissionsBatch)) {
					const prevPermissionState =
						permissionsBatch[permission as keyof EnterpriseAdminRoles];
					permissionsBatch[permission as keyof EnterpriseAdminRoles] =
						!prevPermissionState;
				}
				// else {
				// 	const prevPermissionState =
				// 		permissionsBatch[permission as keyof GeneralRolePermissions];
				// 	permissionsBatch[permission as keyof GeneralRolePermissions] =
				// 		!prevPermissionState;
				// }
			});
	}

	function closeUnsavedChangesModal() {
		setUnsavedChangesModalVisible(false);
		onClose();
	}

	const hasChanges = useMemo(
		() => !isEqual(!isEditModal ? newRole : role, mutableRole),
		[role, mutableRole]
	);
	const predefinedRoleSelected = predefinedRoles
		? Object.values(predefinedRoles).find(role => role.roleId === selectedPredefinedRoleId)
		: undefined;
	const { roleName, roleDescription } = mutableRole;
	const editableRoleName = mutableRole.roleName;
	const updateRoleTitle = !isEditModal
		? undefined
		: translate(({ admin }) => admin.adminRoles.modal.title.update, false, {
				roleName
		  });
	const descriptionCharactersNumber = `${240 - roleDescription.length} / 240`;
	const modalTitle = isEditModal
		? updateRoleTitle
		: translate(({ roles }) => roles.modals.roleModal.title.create);
	const adminPermissionsLabels = {
		edit: translate(({ buttons }) => buttons.edit),
		delete: translate(({ buttons }) => buttons.delete),
		assignAndSet: `${translate(({ buttons }) => buttons.assign)}/${translate(
			({ buttons }) => buttons.set
		)}`,
		remove: translate(({ buttons }) => buttons.remove)
	};
	const predefinedRolesName: string[] = [];
	const predefinedRolesItems: (SelectItem & { permissions: RolePermissions })[] | [] =
		predefinedRoles
			? Object.values(predefinedRoles).map(role => {
					predefinedRolesName.push(role.roleName);
					return {
						label: role.roleName,
						value: role.roleId,
						permissions: role.permissions
					};
			  })
			: [];
	const selectedPredefinedRoleItem: SelectItem | undefined = predefinedRoleSelected
		? {
				label: predefinedRoleSelected.roleName,
				value: predefinedRoleSelected.roleId
		  }
		: undefined;
	const roleNameUniqueError = predefinedRolesName.includes(editableRoleName) && canSubmitRoleForm;
	const roleNameInputError = roleNameUniqueError
		? translate(({ roles }) => roles.modals.roleModal.errors.nameUnique)
		: !editableRoleName.trim()
		? errors.roleName?.message
		: undefined;

	const isSmallScreen = useMediaQuery(MediaQueries.maxWidth.sm);
	const showTabs = false;

	return (
		<>
			<Modal
				title={modalTitle}
				onClose={hasChanges ? () => setUnsavedChangesModalVisible(true) : onClose}
				primary={{
					label: translate(({ buttons }) =>
						!hasChanges ? buttons.done : !isEditModal ? buttons.create : buttons.update
					),
					onClick: onRoleSubmit,
					disabled:
						loading ||
						!mutableRole.roleDescription.trim().length ||
						roleNameUniqueError ||
						(!canSubmitRoleForm && !editableRoleName.trim())
				}}
				neutral={{ label: translate(({ buttons }) => buttons.cancel), onClick: onClose }}
				extendedHeight
				visible
				close
			>
				<>
					<>
						<Row>
							{!isEditModal && (
								<CreatableSelect
									label={translate(
										dict => dict.rolesPage.roleModal.selectRoleLabel
									)}
									value={selectedPredefinedRoleItem}
									items={predefinedRolesItems}
									onValueSelected={value =>
										value ? setSelectedPredefinedRoleId(value) : undefined
									}
									canClear={!!selectedPredefinedRoleId}
									onClear={() => setSelectedPredefinedRoleId(null)}
									allowCreate={false}
								/>
							)}
							<RoleForm onSubmit={onRoleSubmit}>
								<Input
									{...register('roleName')}
									type={InputType.Text}
									label={translate(
										({ roles }) => roles.modals.roleModal.fields.name
									)}
									required
									value={editableRoleName}
									onChange={e =>
										setMutableRole(state => {
											if (!state) return;
											state.roleName = e.target.value;
										})
									}
									error={roleNameInputError}
								/>
							</RoleForm>
						</Row>
						<Spacer size={s => s.s} />
						<Input
							type={InputType.Textarea}
							label={translate(
								({ roles }) => roles.modals.roleModal.fields.description
							)}
							required
							value={mutableRole.roleDescription}
							rows={3}
							maxLength={240}
							onChange={e =>
								setMutableRole(state => {
									if (!state) return;
									state.roleDescription = e.target.value;
								})
							}
							onBlur={() => undefined}
							onSubmit={() => undefined}
						/>
						<Typography.Hint>{descriptionCharactersNumber}</Typography.Hint>
						<Spacer size={s => s.s} />

						{/* This was commented due to current feature's scope
						Implementaton made also for General Permissions */}
						{/* Select Tab View Permission */}
						{/* General and Admin permissions */}
						{/* <Tabs data-testid="modal-tabs" centerTabs>
							{tabsOrder.map((label, index) => (
								<Tab
									centerTabs={!isSmallScreen}
									data-testid={`modal-tab-${index}`}
									key={label}
									id={label}
									active={tabsOrder.indexOf(modalPermissionsView) === index}
									onClick={() => setModalPermissionsView(tabsOrder[index])}
								>
									<Typography.Paragraph>{label}</Typography.Paragraph>
								</Tab>
							))}
						</Tabs>
						<Spacer size={s => s.m} /> */}
						{/* General Permissions View */}
						{showTabs && modalPermissionsView === tabViewsLabels.GeneralPermissions && (
							<>
								<Flex
									justify={j => (isSmallScreen ? j.start : j.between)}
									align={a => (isSmallScreen ? a.start : a.end)}
									style={{ height: '6rem' }}
									wrap={isSmallScreen}
								>
									<Typography.Paragraph
										marginOffset={{ right: isSmallScreen ? 2 : 15 }}
									>
										{translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.rolesModules.allData
										)}
									</Typography.Paragraph>

									<Flex
										wrap={isSmallScreen}
										justify={j => j.between}
										style={{
											width: '60%',
											height: isSmallScreen ? '20rem' : 'fit-content'
										}}
									>
										{/* All data - permission checkboxes */}
										{Object.keys(
											mutableRole?.permissions
											// .generalRolePermissions
										).map(generalPermissionsKey => {
											return (
												<Flex
													key={generalPermissionsKey}
													column
													style={{ height: '6rem', minWidth: '6rem' }}
													justify={j => j.between}
													align={a => a.center}
												>
													<Typography.Caption>
														{
															adminPermissionsLabels[
																generalPermissionsKey as keyof GeneralRolePermissions
															]
														}
													</Typography.Caption>
													<Switch
													// on={
													// 	mutableRole.permissions
													// 		.generalRolePermissions[
													// 		generalPermissionsKey as keyof GeneralRolePermissions
													// 	]
													// }
													// onChange={() =>
													// 	handleTogglePermission(
													// 		'generalRolePermissions',
													// 		generalPermissionsKey
													// 	)
													// }
													/>
												</Flex>
											);
										})}
									</Flex>
								</Flex>
							</>
						)}

						{/* Admin Permissions View */}
						{modalPermissionsView === tabViewsLabels.AdminPermissions && (
							<div>
								<Typography.Caption
									marginOffset={{ bottom: 0.4 }}
									color={Colors.text.main}
									fontweight={w => w.medium}
								>
									{tabViewsLabels.AdminPermissions}
								</Typography.Caption>
								{mutableRole &&
									Object.keys(mutableRole?.permissions).map(
										permissionsBatchKey => {
											if (permissionsBatchKey === 'generalRolePermissions')
												return;
											const moduleKey = permissionsBatchKey as keyof Omit<
												RolePermissions,
												'generalRolePermissions'
											>;

											const cardExpanded =
												permissionsCardIndexExpanded[permissionsBatchKey];
											return (
												<CardWrapper key={permissionsBatchKey}>
													{/* Permissions Batch Card header */}
													<Flex
														justify={j => j.between}
														style={{ cursor: 'pointer' }}
														onClick={() =>
															toggleBatchCard(permissionsBatchKey)
														}
													>
														<Typography.Paragraph>
															{translate(
																({ enterpriseAdmin }) =>
																	enterpriseAdmin.rolesModules[
																		moduleKey
																	].title
															)}
														</Typography.Paragraph>
														<Icon
															svg={Svgs.ChevronDown}
															rotate={cardExpanded ? 180 : 0}
														/>
													</Flex>
													{/* Permissions Batch Card body */}
													{cardExpanded && (
														<Flex wrap>
															{Object.keys(
																mutableRole.permissions[moduleKey]
															).map(permission => {
																return (
																	<Fragment key={permission}>
																		<Flex
																			paddingOffset={{
																				all: 1.7
																			}}
																			style={{
																				minWidth: '16.5rem'
																			}}
																		>
																			<Checkbox
																				label={translate(
																					({
																						enterpriseAdmin
																					}) =>
																						enterpriseAdmin
																							.rolesModules[
																							moduleKey
																						][
																							permission as keyof ModulesPermissions
																						]
																				)}
																				checked={
																					mutableRole
																						.permissions[
																						moduleKey
																					][
																						permission as keyof ModulesPermissions
																					]
																				}
																				onClick={() =>
																					handleTogglePermission(
																						moduleKey,
																						permission
																					)
																				}
																			/>
																		</Flex>
																	</Fragment>
																);
															})}
														</Flex>
													)}
												</CardWrapper>
											);
										}
									)}
							</div>
						)}
						<Spacer size={s => s.l} />
					</>
				</>
			</Modal>
			{unsavedChangesModalVisible && (
				<Modal
					size={s => s.s}
					title={translate(dict => dict.promptToSave.title)}
					primary={{
						label: translate(dict => dict.buttons.save),
						loading: loading,
						onClick: onRoleSubmit
					}}
					neutral={{
						label: translate(dict => dict.buttons.discard),
						onClick: closeUnsavedChangesModal
					}}
					onClose={() => setUnsavedChangesModalVisible(false)}
					enterAsPrimaryOnClick
					visible
					close
				>
					<Typography.Paragraph>
						{translate(dict => dict.promptToSave.unsavedChanges)}
					</Typography.Paragraph>
				</Modal>
			)}
		</>
	);
}

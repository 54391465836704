import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteStatus } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { Status } from 'store/data/statuses';

interface Props {
	status: Status;
	onClose: () => void;
}

export function DeleteStatusModal({ status, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: deletingStatus, error: errorDeletingStatus }, deleteStatus] =
		useDeleteStatus();

	useCompletedAction(
		deletingStatus,
		errorDeletingStatus,
		// SUCCESS CALLBACK
		onClose,
		// ERROR CALLBACK
		onClose
	);

	return (
		<Modal
			size={s => s.s}
			title={`${translate(dict => dict.statusesPage.deleteStatusModal.title)} ${
				status.label
			}?`}
			primary={{
				label: translate(dict => dict.buttons.delete),
				loading: deletingStatus,
				warning: true,
				onClick: () => deleteStatus(status.name)
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.statuses.statusModal.deleteConfimation)}
			</Typography.Paragraph>
		</Modal>
	);
}

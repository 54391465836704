import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const HSpacer = styled.div`
	margin-right: 2.4rem;

	@media ${MediaQueries.maxWidth.sm} {
		margin-right: 0;
		height: '1.6rem';
	}
`;

export const AddFirstCardContainer = styled.div`
	width: 100%;
	height: 13.4rem;
	min-height: 13.4rem;
	border: dashed 0.1rem ${Colors.text.disabled};
	border-radius: 0.4rem;
	background: ${Colors.background.disabled};
`;

import { useEffect } from 'react';

import { Dropdown } from 'components/UI/Dropdown';
import { DropdownToggle } from 'components/UI/Dropdown/DropdownToggle';
import { selectFiltersBySnapshotId } from 'store/data/filters';
import {
	ActionTypes as SnapshotsActionTypes,
	getSnapshot,
	selectActiveSnapshot,
	selectSnapshotIds,
	setActiveSnapshot
} from 'store/data/snapshots';
import { ActionTypes as VariablesActionTypes } from 'store/data/variables';

import { SnapshotOptions } from './SnapshotOptions';

import { Actions, Container, Snapshot } from './Snapshots.style';
import { useDispatch, useSelector } from 'hooks/utils';
import { useTranslation, useVariables, useActivities, useAnalysesCleanup } from 'hooks/store';

export function Snapshots() {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const [{ fetched: areVariablesFetched }] = useVariables({
		lazy: true,
		initial: true
	});

	const snapIds = useSelector(state => selectSnapshotIds(state.data.snapshots));
	const snapshots = useSelector(state => state.data.snapshots.byId);

	const filtersBySnapId = useSelector(state => selectFiltersBySnapshotId(state.data.filters));
	const activeSnapId = useSelector(state => selectActiveSnapshot(state.data.snapshots));

	const areFiltersLoaded = activeSnapId ? !!filtersBySnapId[activeSnapId] : false;

	const [{ loading }] = useActivities([
		SnapshotsActionTypes.GET_SNAPSHOTS,
		SnapshotsActionTypes.GET_SNAPSHOT,
		VariablesActionTypes.GET_VARIABLES
	]);

	useEffect(() => {
		if (activeSnapId && !areFiltersLoaded) {
			dispatch(getSnapshot(activeSnapId));
		}
	}, [activeSnapId]);

	const cleanAnalyses = useAnalysesCleanup();

	return (
		<Container>
			<Snapshot>
				<Dropdown
					toggleComponent={({ ref, open, toggle }) => (
						<DropdownToggle
							ref={ref}
							open={open}
							disabled={!snapIds.length || !areVariablesFetched || loading}
							title={
								activeSnapId
									? snapshots[activeSnapId].snapName
									: translate(({ analysis }) => analysis.snapshots.choose)
							}
							toggle={toggle}
						/>
					)}
					button
				>
					{snapIds.map(id => (
						<Dropdown.Item
							key={id}
							onClick={() => {
								cleanAnalyses();
								dispatch(setActiveSnapshot({ snapshotId: id }));
							}}
						>
							{snapshots[id].snapName}
						</Dropdown.Item>
					))}
				</Dropdown>
			</Snapshot>
			<Actions>
				<SnapshotOptions />
			</Actions>
		</Container>
	);
}

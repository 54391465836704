import styled, { css } from 'styled-components';

export const GridContainer = styled.div`
	display: flex;
	/* position: relative; */
	flex-wrap: wrap;
	margin-left: -1rem;
	margin-right: -1rem;
`;

export const FiltersDrawerWrapper = styled.div<{ visible: boolean }>`
	bottom: 0;
	left: 0;
	height: calc(100% - 23.2rem);
	z-index: 98;

	${({ visible }) =>
		visible &&
		css`
			width: 100%;
		`};
`;

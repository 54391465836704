import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, CreateProject, createProject } from 'store/data/projects';
import { PromDistributionTypes } from 'types/index';

export function useCreateProject(): Operation<
	(project: CreateProject, promDistributionType?: PromDistributionTypes) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_PROJECT);

	function handler(project: CreateProject, promDistributionType?: PromDistributionTypes) {
		if (loading) return;
		if (error) resetError();

		dispatch(createProject(project, promDistributionType));
	}

	return [{ loading, error }, handler];
}

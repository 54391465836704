import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useVariables,
	useEntries,
	useProjectId
} from 'hooks/store';
import { Container } from './NoDataset.style';

export function NoDataset() {
	const { translate } = useTranslation();
	const { routes, promOrProject, navigate } = useNavigation();
	const { hasVariablesWriteAccess, hasDatasetWriteAccess, hasModulesAccess } = usePermissions();

	const [
		{
			data: { hasVariables }
		}
	] = useVariables({ initial: true, lazy: true });

	const [{ data: entries }] = useEntries({ lazy: true });

	const hasEntries = entries.length > 0;

	const [projectId] = useProjectId();

	function createNewVariable() {
		if (projectId) navigate(routes[promOrProject].variables.create(projectId));
	}

	function handleCreateEntry() {
		if (projectId) navigate(routes[promOrProject].dataset.create(projectId));
	}

	function onImportDataset() {
		if (projectId) navigate(routes[promOrProject].import(projectId));
	}

	return (
		<Container>
			{/* NO VARIABLES */}
			{!hasVariables && (
				<>
					<Spacer size={s => s.xxl} />
					<Svgs.EmptyStatesNoDataset style={{ minHeight: 240 }} />
					<Spacer size={s => s.m} />
					<Typography.H3>{translate(dict => dict.dataset.noVariables)}</Typography.H3>

					{hasModulesAccess.projectDesign && hasVariablesWriteAccess && (
						<>
							<Spacer size={s => s.xs} />
							<Typography.Paragraph>
								{translate(dict => dict.dataset.letsCreate)}
							</Typography.Paragraph>
							<Spacer size={s => s.m} />
							<Button
								title={translate(dict => dict.addVariable.addVariableLabel)}
								onClick={createNewVariable}
							/>
						</>
					)}
				</>
			)}

			{/* NO ENTRIES */}
			{hasVariables && !hasEntries && (
				<>
					<Spacer size={s => s.xxl} />
					<Svgs.EmptyStatesNoDataset style={{ minHeight: 240 }} />
					<Spacer size={s => s.m} />
					<Typography.H3>{translate(dict => dict.dataset.noEntries)}</Typography.H3>

					{hasDatasetWriteAccess && (
						<>
							<Spacer size={s => s.xs} />
							<Typography.Paragraph>
								{translate(dict => dict.dataset.noEntriesDescription)}
							</Typography.Paragraph>
							<Spacer size={s => s.m} />
							<Button
								title={translate(dict => dict.entriesHeader.createNewEntry)}
								onClick={handleCreateEntry}
							/>
						</>
					)}
				</>
			)}

			{/* IMPORT  */}
			{hasDatasetWriteAccess && hasVariablesWriteAccess && (
				<>
					<Spacer size={s => s.s} />
					<Flex column align={a => a.center}>
						<Typography.Paragraph>or</Typography.Paragraph>
						<Button
							variant={v => v.link}
							title={translate(dict => dict.projectOptions.importDataset)}
							onClick={onImportDataset}
						/>
					</Flex>
				</>
			)}

			<Spacer size={s => s.xxl} />
		</Container>
	);
}

import { Project } from 'store/data/projects';

export function computeProjectTitle(project: Project) {
	const hasProjectNumber = project.givenProjectNumber !== '';

	const title = `${hasProjectNumber ? `${project.givenProjectNumber}: ` : ''}${
		project.projectName
	}`;

	return title;
}

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	min-height: 7.2rem;
	border-bottom: 0.1rem solid #ededed;

	/* SPACING LIKE GRID SYSTEM */
	margin-left: 4%;
	margin-right: 4%;

	@media only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.lg} {
		h3 {
			font-size: 2rem;
		}
	}

	@media print {
		display: none;
	}
`;

import { ArithmeticNode, VariableNode } from 'api/data/variables';
import { ArithmeticOperator } from 'types/data/variables/constants';
import { MAX_SAFE_INTEGER_VARIABLE_VALUE } from 'statics';
import * as yup from 'yup';

const noDecimalsAllowed = 'No decimals allowed in this field';
const mustBeNumeric = 'The value must be numeric';
const maxValueMessage = `Value is too big. Max value is ${MAX_SAFE_INTEGER_VARIABLE_VALUE}.`;

export const integerSchema = yup
	.string()
	.trim()
	.nullable()
	.transform((v: number, o: string) => (o === '' ? null : v))
	.test(
		'isDecimal',
		noDecimalsAllowed,
		value =>
			value === null ||
			value === undefined ||
			!(value + '').match(
				/(^[+-]?[1-9]\d*[,.]{1}\d*$)|(^[+-]?[1-9]\d*[,.]{1}\d*([Ee][+-]?\d+)+?$)/
			)
	)
	.matches(/(^[+-]?[1-9]\d*$)|(^[+-]?[1-9]\d*([Ee][+-]?\d+)+?$)|(^0$)/, mustBeNumeric)
	.test(
		'isMaxValue',
		maxValueMessage,
		value => value === null || Number(value) <= MAX_SAFE_INTEGER_VARIABLE_VALUE
	);

export const floatSchema = yup
	.string()
	.trim()
	.nullable()
	.transform((v: number, o: string) => (o === '' ? null : o))
	.test(
		'isNumeric',
		mustBeNumeric,
		value =>
			value === null ||
			value === undefined ||
			!(value + '').match(
				/(^[+-]?[1-9]\d*[,.]{1}\d*$)|(^[+-]?[1-9]\d*[,.]{1}\d*([Ee][+-]?\d+)+?$)/
			) ||
			!Number.isNaN(parseFloat(value))
	)
	.test(
		'isMaxValue',
		maxValueMessage,
		value =>
			value === null ||
			value === undefined ||
			parseFloat(value) <= MAX_SAFE_INTEGER_VARIABLE_VALUE
	);

export function isArithmeticNode(
	value: number | string | VariableNode | null | ArithmeticNode
): value is ArithmeticNode {
	return (
		!!value &&
		typeof value === 'object' &&
		(ArithmeticOperator.Addition in value ||
			ArithmeticOperator.Division in value ||
			ArithmeticOperator.Multiplication in value ||
			ArithmeticOperator.Subtraction in value)
	);
}

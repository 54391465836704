import { ImportPageProps } from 'types/data/projects/import/types';
import {
	DataToEntries,
	EntriesToDataset,
	ReplaceAll
} from 'components/Projects/CreateAndImport/PreviewVariables';
import { ImportType } from 'types/index';
import { ApiImportErrorsByVariableName, ApiVariableErrorCount } from 'types/data/projects/types';

interface Props {
	'data-testid'?: string;
	selectedOption: ImportType;
	handleFinishImport: () => void;
	handleBack: () => void;
	handleApiImportErrors?: (
		apiErrorMap: ApiImportErrorsByVariableName,
		errorCountMap: ApiVariableErrorCount
	) => void;
}

export function PreviewVariables({
	selectedOption,
	handleFinishImport,
	handleBack,
	handleApiImportErrors
}: Props) {
	const previewProps: ImportPageProps = {
		selectedOption,
		handleBack,
		handleFinishImport,
		handleApiImportErrors
	};

	return (
		<>
			{selectedOption === ImportType.MoreDataToExistingEntries && (
				<DataToEntries {...previewProps} />
			)}
			{selectedOption === ImportType.MoreEntriesToDataset && (
				<EntriesToDataset {...previewProps} />
			)}
			{(selectedOption === ImportType.ReplaceAll || selectedOption === ImportType.Now) && (
				<ReplaceAll {...previewProps} />
			)}
		</>
	);
}

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { contentfulClient, contentfulOptions } from 'api/utils/Contentful';
import { HelpTopicList, HelpTopicCategory, HelpTopic } from 'store/data/help';
import { LanguageType } from 'types/index';

import { HelpTopicListAPI } from './types';

export default () => ({
	async getHelpTopics(locale: LanguageType): Promise<HelpTopicList> {
		const entries = await contentfulClient.getEntries<HelpTopicListAPI>({
			// `helpTopicList` is the ID of the content we are querying
			content_type: 'helpTopicList',
			// handles the localization
			locale: locale.toLowerCase(),
			// needs to be here to retrieve 2nd level linked data
			include: 2
		});

		const helpTopicsAPI = entries.items[0]?.fields.allTopics ?? [];

		const helpTopics: HelpTopicList = [];

		// PARSE ALL TOPICS
		helpTopicsAPI.forEach(({ fields }) => {
			// IF CONTENT IS UNPUBLISHED - CONTENTFUL WILL NOT RETURN `fields` FIELD IN THE OBJECT RESPONSE BODY
			if (!fields) return;

			const { categoryName, categoryTopics = [] } = fields;

			// INIT TOPIC CATEGORY
			const topicCategory: HelpTopicCategory = {
				categoryName,
				categoryTopics: []
			};

			// PARSE TOPIC
			categoryTopics.forEach(({ fields }) => {
				// IF CONTENT IS UNPUBLISHED - CONTENTFUL WILL NOT RETURN `fields` FIELD IN THE OBJECT RESPONSE BODY
				if (!fields) return;

				const { title, url, topicName, keywords, content } = fields;

				const titleArray = title.split(' ');
				const topicNameArray = topicName.split(' ');
				const keywordsArray = keywords.split(',');

				const mixedArray = [...titleArray, ...topicNameArray, ...keywordsArray];

				const trimToLower = mixedArray.map(string => string.trim().toLowerCase());

				const topicFilters = [...new Set(trimToLower)];

				const topic: HelpTopic = {
					title,
					url,
					topicName,
					topicFilters,
					content: documentToReactComponents(content, contentfulOptions)
				};

				topicCategory.categoryTopics.push(topic);
			});

			// ADD TOPIC TO CATEGORY
			if (topicCategory.categoryTopics.length) helpTopics.push(topicCategory);
		});

		return helpTopics;
	}
});

import { ApiEntryFilters } from 'api/data/filters';
import { GenericApiResponse, NetworkRequestBase } from 'api/types';

export type JADBioToken = { jadBioToken: { Authorization: string } };

type NameOfAnalysisInput = { nameOfAnalysis: string };

type ProjectIdInput = { projectId: number };

type JADBioDatasetId = {
	jadBioDatasetId: string;
};

export type JADBioAnalysisId = { jadBioAnalysisId: string };

/*
	VALIDATE TOKEN
*/
export type ValidateJADBioTokenRequest = NetworkRequestBase;
export type ValidateJADBioTokenOutput = { valid: boolean };

export type ValidateJADBioTokenResponse = GenericApiResponse<ValidateJADBioTokenOutput>;

/*
	LOGIN TO JADBIO
*/
export type LogInToJADBioInput = {
	jadbioUserName: string;
	jadbioPassword: string;
};
export type LogInToJADBioRequest = NetworkRequestBase & LogInToJADBioInput;
export type LogInToJADBioResponse = GenericApiResponse<{
	Authorization: string;
}>;
export type LogInToJADBioOutput = JADBioToken;

/*
	INITIATE JADBIO PROJECT
*/
export type InitiateJADBioProjectInput = ProjectIdInput & {
	jadBioProjectName: string;
};
export type InitiateJADBioProjectRequest = NetworkRequestBase & InitiateJADBioProjectInput;
export type InitiateJADBioProjectResponse = GenericApiResponse<{
	jadBioProjectId: string;
}>;

/*
	INITIATE JADBIO DATASET
*/
export type InitiateJADBioDatasetInput = ProjectIdInput & {
	jadBioDatasetName: string;
	jadBioProjectId: string;
	filters: ApiEntryFilters;
};
export type InitiateJADBioDatasetRequest = NetworkRequestBase & InitiateJADBioDatasetInput;
export type InitiateJADBioDatasetResponse = GenericApiResponse<{
	jadBioTaskId: string;
}>;

/*
	GET JADBIO DATASET STATUS
*/
export type GetJADBioDatasetStatusInput = ProjectIdInput & {
	jadBioTaskId: string;
};
export type GetJADBioDatasetStatusOutput = JADBioDatasetId & { state: JADBioDatasetState };
export type GetJADBioDatasetStatusRequest = NetworkRequestBase & GetJADBioDatasetStatusInput;
export type GetJADBioDatasetStatusResponse = GenericApiResponse<GetJADBioDatasetStatusOutput>;

export enum JADBioDatasetState {
	Pending = 'pending',
	Finished = 'finished'
}

/*
	IS PROJECT INITIALIZED
*/
export type GetJADBioProjectIdInput = ProjectIdInput;
export type GetJADBioProjectIdOutput = {
	jadbioProjectId: string;
};
export type GetJADBioProjectIdRequest = NetworkRequestBase & GetJADBioProjectIdInput;
export type GetJADBioProjectIdResponse = GenericApiResponse<GetJADBioProjectIdOutput>;

/*
	INITIATE CLASSIFICATION MODEL
*/

export type InitiateClassificationModelInput = ProjectIdInput &
	NameOfAnalysisInput &
	JADBioDatasetId & {
		classificationVariable: string; // CATEGORY VARIABLE
	};
export type InitiateClassificationModelRequest = NetworkRequestBase &
	InitiateClassificationModelInput;
export type InitiateClassificationModelResponse = GenericApiResponse<JADBioAnalysisId>;
export type InitiateClassificationModelOutput = JADBioAnalysisId;

/*
	INITIATE SURVIVAL ANALYSIS MODEL
*/
export type InitiateSurvivalAnalysisModelInput = ProjectIdInput &
	NameOfAnalysisInput &
	JADBioDatasetId & {
		eventVariableName: string; // CATEGORY VARIABLE
		timeToEventVariableName: string; // NUMERIC (FLOAT / INTEGER) VARIABLE
	};
export type InitiateSurvivalAnalysisModelRequest = NetworkRequestBase &
	InitiateSurvivalAnalysisModelInput;
export type InitiateSurvivalAnalysisModelResponse = GenericApiResponse<JADBioAnalysisId>;
export type InitiateSurvivalAnalysisModelOutput = JADBioAnalysisId;

/*
	INITIATE REGRESSION MODEL
*/
export type InitiateRegressionModelInput = ProjectIdInput &
	NameOfAnalysisInput &
	JADBioDatasetId & {
		regressionVariable: string; // NUMERIC (FLOAT / INTEGER) VARIABLE
	};
export type InitiateRegressionModelRequest = NetworkRequestBase & InitiateRegressionModelInput;
export type InitiateRegressionModelResponse = GenericApiResponse<JADBioAnalysisId>;
export type InitiateRegressionModelOutput = JADBioAnalysisId;

/*
	GET ANALYSIS STATUS (USED FOR POLLING)
*/
export type GetAnalysisStatusInput = ProjectIdInput & JADBioAnalysisId;
export type GetAnalysisStatusRequest = NetworkRequestBase & GetAnalysisStatusInput;

export type GetAnalysisStatusOutput = {
	jadBioAnalysisStatus: JADBioAnalysisStatus;
	jadBioAnalysisProgress: number;
	jadBioAnalysisTimeElapsed: string;
	jadBioAnalysisStartTime: string;
};

// TODO: find the correct status strings
export enum JADBioAnalysisStatus {
	Finished = 'finished',
	Pending = 'pending',
	Running = 'running',
	Aborted = 'aborted'
}

export type GetAnalysisStatusResponse = GenericApiResponse<GetAnalysisStatusOutput>;

/*
	GET ANALYSIS RESULT
*/
export type GetAnalysisResultInput = ProjectIdInput & JADBioAnalysisId;
export type GetAnalysisResultRequest = NetworkRequestBase & GetAnalysisResultsInput;
export type GetAnalysisResultResponse = GenericApiResponse;

/*
	GET ANALYSIS RESULTS
*/
export type GetAnalysisResultsInput = JADBioAnalysisId & ProjectIdInput;
export type GetAnalysisResultsRequest = NetworkRequestBase & GetAnalysisResultsInput;
export type GetAnalysisResultsResponse = GenericApiResponse<any[]>;
export type GetAnalysisResultsOutput = { data: any[] };

/*
	STOP ANALYSIS GENERATION
*/
//export type StopAnalysisGenerationInput = JADBioAnalysisId & ProjectIdInput;
export type DeleteJADBioAnalysisGenerationInput = { jadbioAnalysisId: string } & ProjectIdInput;
export type DeleteJADBioAnalysisGenerationRequest = NetworkRequestBase &
	DeleteJADBioAnalysisGenerationInput;

export type DeleteJADBioAnalysesInput = { jadbioAnalysisIds: string[] } | ProjectIdInput;
export type DeleteJADBioAnalysesRequest = NetworkRequestBase & DeleteJADBioAnalysesInput;

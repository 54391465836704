import { Colors, Svgs } from 'environment';
import { SecurePaymentButton } from './PaymentSecuredCardsInfo.style';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface Props {
	isCompactSize?: boolean;
}
export function PaymentSecuredCardsInfo({ isCompactSize = false }: Props) {
	const { translate } = useTranslation();
	const computedSVGsWidth = !isCompactSize ? 50 : 30;

	return (
		<Flex wrap>
			<SecurePaymentButton isCompactSize={isCompactSize}>
				<Svgs.SecurePaymentLogo />
				<Typography.Hint
					color={Colors.vibrantGreen}
					style={{ fontWeight: 'bold' }}
					paddingOffset={isCompactSize ? { left: 0.4 } : undefined}
				>
					{translate(({ accountUM }) =>
						isCompactSize
							? accountUM.payment.sSLSecureConnection
							: accountUM.payment.secureConnection
					)}
				</Typography.Hint>
			</SecurePaymentButton>
			<Flex>
				<Svgs.MastercardLogo width={computedSVGsWidth} />
				<Svgs.MaestroLogo width={computedSVGsWidth} />
				<Svgs.VisaElectronLogo width={computedSVGsWidth} />
				<Svgs.VisaLogo width={computedSVGsWidth} />
			</Flex>
		</Flex>
	);
}

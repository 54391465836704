import { useState, useRef, useLayoutEffect, useEffect, StrictMode } from 'react';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { nanoid as generate } from 'nanoid';

import { useDashboardById, useTranslation } from 'hooks/store';
import { useElementSize } from 'hooks/ui/useElementSize';

import { AdminDashboardHeader } from './AdminDashboardHeader';

import {
	ResizableCardDnDAutomatic,
	CardsSharedLayout,
	CardsSharedLayoutRows,
	FillLayout
} from 'components/Account';

import { supportsTouch } from 'consts';

import {
	areThreeCardsGrouped,
	checkForCombinables,
	closestHeightSnap,
	closestWidthSnap,
	isSingleElementCard
} from 'helpers/adminDashboard';

import { FiltersDrawerWrapper, GridContainer } from './style';
import { APIWidthSize, WidgetHeightSize, WidgetWidthSize } from 'store/data/projectDashboard';
import { EmptyDashboard } from '../EmptyDashboard';
import { arrayUtils } from 'helpers/arrays';
import { Grid } from 'components/UI/Grid';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Spacer } from 'components/UI/Spacer';

export type SetSizePayload = {
	cardId: number;
	width: number;
	height?: number;
	outOfView?: boolean;
	vertical?: boolean;
	groupId?: string;
	position?: CardPositionManual;
	widthSizes?: WidthSizes;
	heightSizes?: HeightSizes;
};

export interface WidthSizes {
	minSizeWidth: number;
	mediumSizeWidth: number;
	maxSizeWidth: number;
}

export interface HeightSizes {
	minSizeHeight: number;
	maxSizeHeight: number;
}

export interface CardDraggableResizableProps extends CardDraggableItem {
	cardName: string;
	widthSize: WidgetWidthSize;
	heightSize: WidgetHeightSize;
	group?: {
		index: number;
		groupId: string;
	};
	position?: {
		rowNumber: number;
		colIndex: number;
		rowIndex: number;
	};
}

export interface CardDraggableItem {
	index: number;
	cardId: string;
}

export interface GroupedCardsDraggableResizableProps {
	groupId: string;
	elements: CardDraggableResizableProps[];
}
export interface GroupedThreeCardsDraggableResizableProps {
	groupId: string;
	threeCards: boolean;
	elements: Array<CardDraggableResizableProps[]>;
}

export interface RowsCompletenessProps {
	[index: number]: {
		elements: PossibleLayouts;
		maxHeightRow: boolean;
	};
}

export type PossibleLayouts = Array<
	| CardDraggableResizableProps
	| GroupedCardsDraggableResizableProps
	| GroupedThreeCardsDraggableResizableProps
	| string
>;

export interface CoordinatePayload {
	dragged: {
		index: number;
		group?: {
			groupId: string;
			index: number;
			rowIndex?: number;
			colIndex?: number;
		};
	};
	dropped: {
		index: number;
		group?: {
			groupId: string;
			index: number;
			rowIndex?: number;
			colIndex?: number;
		};
		droppedInMain?: boolean;
	};
}

export interface IndexPayload {
	dragged: {
		rowNumber: number;
		rowIndex: number;
		colIndex: number;
	};
	dropped: {
		rowNumber: number;
		rowIndex: number;
		colIndex: number;
	};
}

export interface CardDraggableResizableProps extends CardDraggableItem {
	cardName: string;
	widthSize: WidgetWidthSize;
	heightSize: WidgetHeightSize;
	group?: {
		index: number;
		groupId: string;
	};
}

export interface CardDraggableResizablePropsDynamic extends CardDraggableItem {
	cardName: string;
	widthSize: APIWidthSize;
	heightSize: WidgetHeightSize;
	group?: {
		index: number;
		groupId: string;
	};
	position?: {
		colIndex: number;
		rowIndex: number;
	};
}

export interface CardDraggableItem {
	index: number;
	cardId: string;
}

export type ManualLayoutElement = string | CardDraggableResizableProps | null;

export type ManualLayoutRows = {
	[index: string]: {
		elements: ManualLayoutElement[][];
		maxHeightRow: boolean;
		fullHeightRows: number;
	};
};

export interface CoordinatePayload {
	dragged: {
		index: number;
		group?: {
			groupId: string;
			index: number;
			rowIndex?: number;
			colIndex?: number;
		};
	};
	dropped: {
		index: number;
		group?: {
			groupId: string;
			index: number;
			rowIndex?: number;
			colIndex?: number;
		};
		droppedInMain?: boolean;
	};
}

export interface CardPositionManual {
	rowIndex: number;
	colIndex: number;
}

export enum GridModes {
	manual = 'manual',
	automatic = 'automatic'
}

export enum ResizeDirection {
	vertical = 'vertical',
	horizontal = 'horizontal'
}

export function AdminDashboard() {
	const { translate } = useTranslation();
	const { move, insert, remove, replace } = arrayUtils;
	const gridRef = useRef<HTMLDivElement>(null);
	const filtersRef = useRef<HTMLDivElement>(null);
	const { handleRef: adminPannelRef, width: screenWidth } = useElementSize();
	const [cardAutoResize, setCardAutoResize] = useState<string | null>(null);

	const [
		{
			data: { dashboard }
			// loading: loadingProjectDashboard
			// fetched: isDashboardFetched
		}
	] = useDashboardById();

	const [showFilters, setShowFilters] = useState(false);

	const [elementsOrder, setElementsOrder] = useState<PossibleLayouts>([]);

	useEffect(() => {
		console.log('dashboard', dashboard);
	}, [dashboard]);

	const [fillLayoutVisible, setFillLayoutVisible] = useState(false);
	const [isResizing, setIsResizing] = useState(false);

	const [widthSizes, setWidthSizes] = useState<WidthSizes>({
		maxSizeWidth: 0,
		mediumSizeWidth: 0,
		minSizeWidth: 0
	});

	const heightSizes = {
		minSizeHeight: 307 - 44,
		maxSizeHeight: 614 - 20
	};

	function calculateCardWidthSizes() {
		let localWidth;
		if (!gridRef || !gridRef.current) localWidth = window.innerWidth;
		else {
			localWidth = gridRef.current.clientWidth + 2;
		}

		// In case deciding to have responsiveness,
		// Modify the element column splitting dinamically by screen size
		const colSize = localWidth / 3 - 64;
		const newWidthSizes = {
			minSizeWidth: colSize * 1 + 10,
			mediumSizeWidth: colSize * 2 + 70,
			maxSizeWidth: colSize * 3 + 130
		};
		if (newWidthSizes !== widthSizes) setWidthSizes(newWidthSizes);
	}

	useLayoutEffect(() => {
		calculateCardWidthSizes();
	}, [showFilters, screenWidth]);

	function setElementsOrderHandler(coordinates: CoordinatePayload) {
		const { dragged, dropped } = coordinates;

		if (dragged.group?.rowIndex === 0 && dragged.group?.colIndex === 1) {
			// brake group into two and one
			return;
		}
		if (dragged.group?.colIndex === 0) {
			//brake group into three separate
			return;
		}
		if (dragged.group && dropped.group && dropped.droppedInMain) {
			const draggedFromGroup = elementsOrder[
				dragged.group.index
			] as GroupedCardsDraggableResizableProps;
			const filteredList = replace(
				elementsOrder,
				dragged.group.index,
				draggedFromGroup.elements[dragged.index === 0 ? 1 : 0]
			);
			const orderedList = insert(
				filteredList,
				dragged.group.index < dropped.group.index
					? dropped.group.index + 1
					: dropped.group.index,
				draggedFromGroup.elements[dragged.index]
			);
			const { newGrid } = checkForCombinables(orderedList);
			return setElementsOrder(newGrid);
		}
		if (dragged.group && dropped.group && !dropped.droppedInMain) {
			const dragAndDropSameGroup = dragged.group?.groupId === dropped.group?.groupId;

			const draggedFromGroup = elementsOrder[
				dragged.group.index
			] as GroupedCardsDraggableResizableProps;

			const droppedToGroup = elementsOrder[
				dropped.group.index
			] as GroupedCardsDraggableResizableProps;

			if (dragAndDropSameGroup) {
				if (!dragged.group) return;
				const newArray = move(draggedFromGroup.elements, dragged.index, dropped.index);
				const filteredList = replace(elementsOrder, dragged.group.index, {
					groupId: draggedFromGroup.groupId,
					elements: newArray
				});
				const { newGrid } = checkForCombinables(filteredList);
				return setElementsOrder(newGrid);
			}
			const filteredList = replace(
				elementsOrder,
				dragged.group.index,
				draggedFromGroup.elements[dragged.index === 0 ? 1 : 0]
			);

			const newDroppedGroup =
				dropped.index === 0
					? remove(
							insert(
								droppedToGroup.elements,
								dropped.index,
								draggedFromGroup.elements[dropped.index === 0 ? 1 : 0]
							),
							2
					  )
					: replace(
							droppedToGroup.elements,
							dropped.index,
							draggedFromGroup.elements[dragged.index]
					  );
			const newListWithDroppedGroup = replace(filteredList, dropped.group.index, {
				groupId: droppedToGroup.groupId,
				elements: newDroppedGroup
			});
			const finalList = insert(
				newListWithDroppedGroup,
				dropped.group.index + 1,
				droppedToGroup.elements[dropped.index === 1 ? dropped.index : dropped.index + 1]
			);
			const { newGrid } = checkForCombinables(finalList);
			return setElementsOrder(newGrid);
		}

		if (dragged.group && !dropped.group) {
			const groupDraggedFrom = elementsOrder[dragged.group.index] as
				| GroupedCardsDraggableResizableProps
				| GroupedThreeCardsDraggableResizableProps;
			if (areThreeCardsGrouped(groupDraggedFrom)) {
				//replace 3Cards with 2Cards items dragged from
				//insert the element into main list
			} else {
				const filteredList = replace(
					elementsOrder,
					dragged.group.index,
					groupDraggedFrom.elements[dragged.index === 0 ? 1 : 0]
				);
				const finalList = insert(
					filteredList,
					dropped.index,
					groupDraggedFrom.elements[dragged.index] as CardDraggableResizableProps
				);
				const { newGrid } = checkForCombinables(finalList);
				return setElementsOrder(newGrid);
			}
		}

		if (!dragged.group && dropped.group) {
			if (dropped.droppedInMain) {
				const newArray = move(elementsOrder, dragged.index, dropped.index);
				const { newGrid } = checkForCombinables(newArray);
				return setElementsOrder(newGrid);
			}
			//brake group dropped in
			const droppedToGroup = elementsOrder[
				dropped.group.index
			] as GroupedCardsDraggableResizableProps;

			const newDroppedElements =
				dropped.index === 0
					? remove(
							insert(
								droppedToGroup.elements,
								dropped.index,
								elementsOrder[dragged.index] as CardDraggableResizableProps
							),
							2
					  )
					: replace(
							droppedToGroup.elements,
							dropped.index === 2 ? dropped.index - 1 : dropped.index,
							elementsOrder[dragged.index] as CardDraggableResizableProps
					  );

			const orderedList = replace(elementsOrder, dropped.group.index, {
				groupId: droppedToGroup.groupId,
				elements: newDroppedElements
			});
			const finalList = replace(
				orderedList,
				dragged.index,
				droppedToGroup.elements[
					dropped.index === 0
						? dropped.index + 1
						: dropped.index === 2
						? dropped.index - 1
						: dropped.index
				] as CardDraggableResizableProps
			);
			if (dragged.index === 0) {
				const { newGrid } = checkForCombinables(
					move(finalList, dragged.index, dragged.index + 1)
				);
				return setElementsOrder(newGrid);
			}
			const { newGrid } = checkForCombinables(finalList);
			return setElementsOrder(newGrid);
		}
		if (!dragged.group && !dropped.group) {
			//move in main list
			const newArray = move(elementsOrder, dragged.index, dropped.index);
			const { newGrid } = checkForCombinables(newArray);
			return setElementsOrder(newGrid);
		}
	}

	function handleGroupedElementsSize(payload: SetSizePayload, groupId: string) {
		const payloadWithGroupId = { ...payload, groupId };
		setElementSize(payloadWithGroupId);
	}

	function combineLayout(coordinates: CoordinatePayload) {
		const { dragged, dropped } = coordinates;

		if (dragged.group) {
			const draggedFromGroup = elementsOrder[dragged.group.index] as
				| GroupedCardsDraggableResizableProps
				| GroupedThreeCardsDraggableResizableProps;
			if (areThreeCardsGrouped(draggedFromGroup)) {
				console.log(dragged.group.colIndex);
				const draggedFromRowIndex = dragged.group.rowIndex;
				const draggedFromColIndex = dragged.group.colIndex;
				if (
					typeof draggedFromRowIndex === 'number' &&
					typeof draggedFromColIndex === 'number'
				) {
					const draggedElement =
						draggedFromGroup.elements[draggedFromRowIndex][draggedFromColIndex];
					console.log('draggedElement', draggedElement);
					if (draggedFromRowIndex === 0) {
						const newGroup: GroupedThreeCardsDraggableResizableProps = {
							...draggedFromGroup,
							elements: [
								[
									draggedFromGroup.elements[draggedFromRowIndex][
										draggedFromColIndex === 0 ? 1 : 0
									]
								],
								[draggedFromGroup.elements[1][0], draggedElement]
							]
						};
						const newArray = replace(elementsOrder, dragged.group.index, newGroup);
						const { newGrid } = checkForCombinables(newArray);
						return setElementsOrder(newGrid);
					} else {
						const newGroup: GroupedThreeCardsDraggableResizableProps = {
							...draggedFromGroup,
							elements: [
								[draggedFromGroup.elements[0][0], draggedElement],
								[
									draggedFromGroup.elements[draggedFromRowIndex][
										draggedFromColIndex === 0 ? 1 : 0
									]
								]
							]
						};
						const newArray = replace(elementsOrder, dragged.group.index, newGroup);
						const { newGrid } = checkForCombinables(newArray);
						return setElementsOrder(newGrid);
					}
				}
			} else {
				const newListWitoutDragged = replace(
					elementsOrder,
					dragged.group.index,
					draggedFromGroup.elements[dragged.index === 0 ? 1 : 0]
				);

				const newGroup: GroupedCardsDraggableResizableProps = {
					groupId: generate(),
					elements: [
						elementsOrder[dropped.index] as CardDraggableResizableProps,
						draggedFromGroup.elements[dragged.index]
					]
				};
				const newListWithGroup = replace(newListWitoutDragged, dropped.index, newGroup);
				const { newGrid } = checkForCombinables(newListWithGroup);
				return setElementsOrder(newGrid);
			}
		}

		const newGroup: GroupedCardsDraggableResizableProps = {
			groupId: generate(),
			elements: [
				elementsOrder[dropped.index] as CardDraggableResizableProps,
				elementsOrder[dragged.index] as CardDraggableResizableProps
			]
		};
		const newListCombinedElements = replace(elementsOrder, dropped.index, newGroup);
		const filteredList = remove(newListCombinedElements, dragged.index);

		const { newGrid } = checkForCombinables(filteredList);
		return setElementsOrder(newGrid);
	}

	function expandCardNewRow(coordinates: CoordinatePayload) {
		const { dragged, dropped } = coordinates;
		console.log({ dragged }, { dropped });
		if (dragged.group) {
			const draggedFromGroup = elementsOrder[
				dragged.group.index
			] as GroupedCardsDraggableResizableProps;

			const elementsWithoutDragged = replace(
				elementsOrder,
				dragged.group.index,
				draggedFromGroup.elements[dragged.index === 0 ? 1 : 0]
			);
			const draggedElement = draggedFromGroup.elements[dragged.index];
			const draggedCardMaxWidth: CardDraggableResizableProps = {
				...draggedElement,
				widthSize: WidgetWidthSize.big
			};
			const listWithMaxCard = insert(
				elementsWithoutDragged,
				dropped.index,
				draggedCardMaxWidth
			);
			const { newGrid } = checkForCombinables(listWithMaxCard);
			return setElementsOrder(newGrid);
		} else {
			const draggedElement = elementsOrder[dragged.index] as CardDraggableResizableProps;
			const draggedCardMaxWidth: CardDraggableResizableProps = {
				...draggedElement,
				widthSize: WidgetWidthSize.big
			};
			const filteredList = remove(elementsOrder, dragged.index);
			const listWithMaxCard = insert(filteredList, dropped.index, draggedCardMaxWidth);

			const { newGrid } = checkForCombinables(listWithMaxCard);
			return setElementsOrder(newGrid);
		}
	}

	function setElementSize(payload: SetSizePayload) {
		const { cardId, height, width, outOfView, vertical, groupId } = payload;

		const widthSize = closestWidthSnap(width, widthSizes);
		const heightSize = closestHeightSnap(height ?? 16, heightSizes);

		const newElements: PossibleLayouts = [];
		let resizedElement: CardDraggableResizableProps | null;

		elementsOrder.forEach(elementOrGroup => {
			if (typeof elementOrGroup === 'string') return newElements.push(elementOrGroup);
			if (isSingleElementCard(elementOrGroup)) {
				const element = elementOrGroup;
				if (element.cardId === cardId.toString()) {
					if (outOfView) {
						newElements.push({
							...element,
							heightSize: !vertical ? heightSize : WidgetHeightSize.big,
							widthSize: vertical
								? widthSize
								: widthSize === WidgetWidthSize.small
								? WidgetWidthSize.medium
								: WidgetWidthSize.big
						});
					} else {
						newElements.push({
							...element,
							widthSize: vertical ? element.widthSize : widthSize,
							heightSize: !vertical ? element.heightSize : heightSize
						});
					}
				} else {
					newElements.push(elementOrGroup);
				}
			} else {
				if (areThreeCardsGrouped(elementOrGroup)) {
					let nothingResized = false;
					let resizedRowIndex: number | null = null;
					let resizedColIndex: number | null = null;

					elementOrGroup.elements.forEach((elementsRow, index) => {
						elementsRow.forEach((element, colIndex) => {
							if (element.cardId === cardId.toString()) {
								resizedRowIndex = index;
								resizedColIndex = colIndex;
								nothingResized = element.widthSize === widthSize;
								resizedElement = element;
							}
						});
					});

					if (!nothingResized && !resizedRowIndex) newElements.push(elementOrGroup);
					if (resizedElement && resizedElement.cardId === cardId.toString()) {
						if (heightSize !== resizedElement.heightSize) {
							//brakeGroup
							if (
								resizedColIndex === 1 &&
								elementOrGroup.elements[1][0].widthSize === WidgetWidthSize.small
							) {
								//turn into shared layout
								const newGroup: GroupedCardsDraggableResizableProps = {
									groupId: elementOrGroup.groupId,
									elements: [
										elementOrGroup.elements[0][0],
										elementOrGroup.elements[1][0]
									]
								};
								newElements.push(newGroup);

								return newElements.push({ ...resizedElement, heightSize });
							} else {
								const brokenGroupElements = [
									resizedRowIndex === 0 && resizedColIndex === 0
										? { ...resizedElement, heightSize }
										: elementOrGroup.elements[0][0],
									resizedRowIndex === 0 && resizedColIndex === 1
										? { ...resizedElement, heightSize }
										: elementOrGroup.elements[0][1] ?? undefined,
									resizedRowIndex === 1 && resizedColIndex === 0
										? { ...resizedElement, heightSize }
										: elementOrGroup.elements[1][0] ?? undefined,
									resizedRowIndex === 1 && resizedColIndex === 1
										? { ...resizedElement, heightSize }
										: elementOrGroup.elements[1][1] ?? undefined
								];
								const finalArray = brokenGroupElements.filter(function (element) {
									return element !== undefined;
								});

								return newElements.push(...finalArray.flat());
							}
						} else {
							const newThreeGroup: GroupedThreeCardsDraggableResizableProps = {
								...elementOrGroup,
								elements:
									resizedRowIndex === 1
										? [
												elementOrGroup.elements[0],
												[
													{
														...resizedElement,
														widthSize
													}
												]
										  ]
										: [
												elementOrGroup.elements[0].length === 1
													? [{ ...resizedElement, widthSize }]
													: resizedColIndex === 1
													? [
															elementOrGroup.elements[0][0],
															{
																...resizedElement,
																widthSize
															}
													  ]
													: [
															{
																...resizedElement,
																widthSize
															},
															elementOrGroup.elements[0][1]
													  ],
												elementOrGroup.elements[1]
										  ]
							};

							if (
								newElements.find(
									element =>
										typeof element !== 'string' &&
										!isSingleElementCard(element) &&
										areThreeCardsGrouped(element) &&
										element.groupId === groupId
								)
							) {
								newElements.pop();
								newElements.push(newThreeGroup);
							} else {
								newElements.push(newThreeGroup);
							}
						}
					}
				} else {
					const group = elementOrGroup as GroupedCardsDraggableResizableProps;
					if (group.groupId === groupId) {
						if (vertical) {
							const nothingResized = group.elements.find(
								element =>
									element.cardId === cardId.toString() &&
									element.heightSize === heightSize
							);
							if (nothingResized) return newElements.push(group);

							group.elements.forEach(element =>
								element.cardId === cardId.toString()
									? newElements.push({ ...element, heightSize })
									: newElements.push(element)
							);
						} else {
							const newArray = [] as CardDraggableResizableProps[];
							group.elements.forEach(element => {
								if (outOfView) {
									newArray.push({
										...element,
										heightSize: !vertical ? heightSize : WidgetHeightSize.big,
										widthSize: vertical
											? widthSize
											: widthSize === WidgetWidthSize.small
											? WidgetWidthSize.medium
											: WidgetWidthSize.big
									});
								} else {
									newArray.push({
										...element,
										widthSize: vertical ? element.widthSize : widthSize,
										heightSize: !vertical ? heightSize : element.heightSize
									});
								}
							});

							newElements.push({ groupId: group.groupId, elements: newArray });
						}
					} else newElements.push(group);
				}
			}
		});
		const { newGrid } = checkForCombinables(newElements);
		return setElementsOrder(newGrid);
	}

	function addCardToGridAutomatic() {
		setIsResizing(true);
		const { newGrid } = checkForCombinables(
			insert(elementsOrder, elementsOrder.length, {
				cardId: generate(),
				cardName: `Card ${elementsOrder.length + 1}`,
				heightSize: WidgetHeightSize.small,
				widthSize: WidgetWidthSize.small,
				index: elementsOrder.length
			})
		);
		setElementsOrder(newGrid);
		setIsResizing(false);
	}
	function removeCard(index: number, groupIndex?: number) {
		setIsResizing(true);
		if (typeof groupIndex === 'number') {
			const group = elementsOrder[groupIndex] as GroupedCardsDraggableResizableProps;
			const filteredList = replace(
				elementsOrder,
				groupIndex,
				group.elements[index === 0 ? 1 : 0]
			);
			const { newGrid } = checkForCombinables(filteredList);
			setElementsOrder(newGrid);

			return setIsResizing(false);
		}
		const { newGrid } = checkForCombinables(remove(elementsOrder, index));
		setElementsOrder(newGrid);
		setIsResizing(false);
	}

	const filterColumns = showFilters ? { xl: 3, l: 3, m: 3 } : { xl: 0, l: 0, m: 0 };
	const gridColumns = showFilters ? { xl: 13, l: 9, m: 3 } : { xl: 16, l: 12, m: 6 };

	const emptyDashboard = !!(dashboard && !Object.values(dashboard.cards).length);

	return (
		<>
			<AdminDashboardHeader
				showFilters={showFilters}
				layoutSize={2}
				// automaticGrid={automaticGrid}
				// setAutomaticGrid={setAutomaticGrid}
				emptyDashboard={emptyDashboard}
				setShowFilters={setShowFilters}
				addNewCard={addCardToGridAutomatic}
				setLayoutSize={() => undefined}
			/>
			<StrictMode>
				<Grid.Container>
					<div ref={adminPannelRef}>
						<Flex>
							{showFilters && (
								<Grid.Row style={{ flexWrap: 'nowrap' }}>
									<Grid.Col sizes={filterColumns}>
										<FiltersDrawerWrapper
											ref={filtersRef}
											visible={showFilters}
										>
											<>
												<Typography.H5>
													{translate(dict => dict.filters.filters)}
												</Typography.H5>
												<CreatableSelect
													name={'Project Filters'}
													label={'Project Filters'}
													placeholder={'All Projects'}
													items={[
														{
															label: 'All Projects',
															value: 'All Projects'
														},
														{
															label: 'Ongoing Projects',
															value: 'Ongoing Projects'
														}
													]}
													disabled={false}
													hasMultipleValues={false}
												/>
											</>
										</FiltersDrawerWrapper>
									</Grid.Col>
								</Grid.Row>
							)}
							<Grid.Row style={{ flexWrap: 'nowrap' }}>
								<Grid.Col sizes={gridColumns}>
									<DndProvider
										backend={supportsTouch ? TouchBackend : HTML5Backend}
									>
										<GridContainer ref={gridRef}>
											{emptyDashboard && (
												<EmptyDashboard
													addCardToGrid={addCardToGridAutomatic}
												/>
											)}
											{elementsOrder.map((elementRow, mainIndex) => {
												if (typeof elementRow === 'string') return null;
												if (isSingleElementCard(elementRow)) {
													const singleLayoutElement = elementRow;
													const { cardId } = singleLayoutElement;
													return (
														<ResizableCardDnDAutomatic
															key={cardId}
															totalCards={elementsOrder.length}
															index={mainIndex}
															card={singleLayoutElement}
															cardAutoResize={cardAutoResize}
															widthSizes={widthSizes}
															heightSizes={heightSizes}
															isResizing={isResizing}
															setElementsOrder={
																setElementsOrderHandler
															}
															setDimensions={setElementSize}
															setIsResizing={setIsResizing}
															combineLayout={combineLayout}
															removeCard={removeCard}
															setFillLayoutVisible={() =>
																setFillLayoutVisible(true)
															}
															setCardAutoResize={setCardAutoResize}
															expandCardNewRow={expandCardNewRow}
														/>
													);
												} else {
													if (areThreeCardsGrouped(elementRow)) {
														const { groupId } = elementRow;
														return (
															<CardsSharedLayoutRows
																key={groupId + mainIndex}
																cardsRows={elementRow.elements}
																totalCards={elementsOrder.length}
																isResizing={isResizing}
																heightSizes={heightSizes}
																widthSizes={widthSizes}
																index={mainIndex}
																group={{
																	groupId,
																	index: mainIndex
																}}
																setDimensions={payload =>
																	handleGroupedElementsSize(
																		payload,
																		groupId
																	)
																}
																setElementsOrder={
																	setElementsOrderHandler
																}
																setIsResizing={setIsResizing}
																combineLayout={combineLayout}
																removeCard={index =>
																	removeCard(index, mainIndex)
																}
																expandCardNewRow={expandCardNewRow}
															/>
														);
													} else {
														const { groupId, elements } =
															elementRow as GroupedCardsDraggableResizableProps;

														return (
															<div key={groupId}>
																<CardsSharedLayout
																	totalCards={
																		elementsOrder.length
																	}
																	cards={elements}
																	isResizing={isResizing}
																	heightSizes={heightSizes}
																	widthSizes={widthSizes}
																	index={mainIndex}
																	group={{
																		groupId,
																		index: mainIndex
																	}}
																	setDimensions={payload =>
																		handleGroupedElementsSize(
																			payload,
																			groupId
																		)
																	}
																	setElementsOrder={
																		setElementsOrderHandler
																	}
																	setIsResizing={setIsResizing}
																	combineLayout={combineLayout}
																	removeCard={index =>
																		removeCard(index, mainIndex)
																	}
																	expandCardNewRow={
																		expandCardNewRow
																	}
																/>
															</div>
														);
													}
												}
											})}
										</GridContainer>
									</DndProvider>
									<Spacer size={s => s.xxl} />
									{fillLayoutVisible && (
										<FillLayout
											addNewCard={addCardToGridAutomatic}
											onClose={() => setFillLayoutVisible(false)}
										/>
									)}
								</Grid.Col>
							</Grid.Row>
						</Flex>
					</div>
				</Grid.Container>
			</StrictMode>
		</>
	);
}

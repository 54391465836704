import type { ApiProjectFile, ProjectFile } from 'api/data/documents';
import type { Action } from 'types/store/types';

export enum ActionTypes {
	GET_PROJECT_FILES = 'data/documents/GET_PROJECT_FILES',
	UPLOAD_PROJECT_FILE = 'data/documents/UPLOAD_PROJECT_FILE',
	VIEW_PROJECT_FILE = 'data/documents/VIEW_PROJECT_FILE',
	PROJECT_FILES_SET_SEARCH_TERM = 'data/documents/PROJECT_FILES_SET_SEARCH_TERM',
	PROJECT_FILES_SET_FILTER = 'data/documents/PROJECT_FILES_SET_FILTER',
	PROJECT_FILES_SET_DATE_FILTER = 'data/documents/PROJECT_FILES_SET_DATE_FILTER',
	PROJECT_FILES_RESET_FILTER = 'data/documents/PROJECT_FILES_RESET_FILTER',
	PROJECT_FILES_RESET_ALL_FILTERS = 'data/documents/PROJECT_FILES_RESET_ALL_FILTERS',
	REMOVE_DOCUMENT = 'data/documents/REMOVE_DOCUMENT',
	RENAME_DOCUMENT = 'data/documents/RENAME_DOCUMENT',
	GET_CSV_XLS_FROM_S3 = 'data/documents/GET_CSV_XLS_FROM_S3',
	RESET_CSV_TABLE = 'data/documents/RESET_CSV_TABLE'
}

export interface State {
	projectId: string | null;
	filters: ProjectFilesListFilters;
	projectFiles: ProjectFile[] | null;
	metadata: {
		fileToView: string | null;
		csvFileToView: string[][] | null;
		pageSize: number;
		pageIndex: number;
		searchTerm: string;
	};
}

export enum DateFilterOperator {
	Between = 'between',
	Exact = 'exact',
	Before = 'before',
	After = 'after'
}

export interface CheckboxFilter {
	all: string[];
	active: string[];
}

export interface DateFilter {
	valid: boolean;
	operator: DateFilterOperator;
	value: string | null;
	from: string | null;
	to: string | null;
}

export interface ProjectFilesListFilters {
	type: CheckboxFilter;
	uploadDate: DateFilter;
}

export enum ProjectFilesListFilterNames {
	Types = 'types',
	UploadDate = 'uploadDate'
}

export type SetProjectFilesFilterAction = Action<
	ActionTypes.PROJECT_FILES_SET_FILTER,
	{
		filterName: ProjectFilesListFilterNames;
		value: string;
	}
>;

export type SetProjectFilesDateFilterAction = Action<
	ActionTypes.PROJECT_FILES_SET_DATE_FILTER,
	{
		filterName: ProjectFilesListFilterNames;
		filter: DateFilter;
	}
>;

export type ResetProjectFilesFilterAction = Action<
	ActionTypes.PROJECT_FILES_RESET_FILTER,
	{
		filterName: ProjectFilesListFilterNames;
	}
>;

export type ResetAllProjectFilesFiltersAction = Action<ActionTypes.PROJECT_FILES_RESET_ALL_FILTERS>;

export type GetProjectFilesAction = Action<
	ActionTypes.GET_PROJECT_FILES,
	{
		files: ApiProjectFile[];
	}
>;

export type ViewProjectFileAction = Action<
	ActionTypes.VIEW_PROJECT_FILE,
	{
		fileId: string;
	}
>;

export type SetProjectFilesSearchTermAction = Action<
	ActionTypes.PROJECT_FILES_SET_SEARCH_TERM,
	{
		term: string;
	}
>;

export type RemoveDocument = Action<
	ActionTypes.REMOVE_DOCUMENT,
	{
		projectId: string;
		fileId: string;
	}
>;

export type RenameDocument = Action<
	ActionTypes.RENAME_DOCUMENT,
	{
		projectId: string;
		fileId: string;
		name: string;
	}
>;

export type GetCsvXlsFromS3Action = Action<
	ActionTypes.GET_CSV_XLS_FROM_S3,
	{
		fileData: string[][];
	}
>;

export type ResetCsvTableAction = Action<ActionTypes.RESET_CSV_TABLE>;

export type Actions =
	| GetProjectFilesAction
	| ViewProjectFileAction
	| SetProjectFilesSearchTermAction
	| ResetAllProjectFilesFiltersAction
	| ResetProjectFilesFilterAction
	| SetProjectFilesDateFilterAction
	| SetProjectFilesFilterAction
	| RemoveDocument
	| RenameDocument
	| GetCsvXlsFromS3Action
	| ResetCsvTableAction;

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { appendFormSet, AppendFormSetAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useAppendFormSet(): LocalOperation<
	(payload: ActionPayload<AppendFormSetAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<AppendFormSetAction>) {
		dispatch(appendFormSet(payload));
	}

	return handler;
}

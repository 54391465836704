import { useState, useEffect } from 'react';

import { useSelector } from 'hooks/utils';
import { Operation } from 'hooks/store/types';
import { selectActivities, selectErrors } from 'store/ui/activities';

export function useActivities(types: string[], payload?: any): Operation {
	const [cancel, setCancel] = useState(false);
	const loading = useSelector(state => selectActivities(state.ui.activities, { types, payload }));
	const errored = useSelector(state => selectErrors(state.ui.activities, { types, payload }));

	useEffect(() => {
		if (errored) setCancel(true);
	}, [errored]);

	function reset() {
		setCancel(false);
	}

	return [{ loading, error: cancel }, reset];
}

import { Component, ErrorInfo } from 'react';

import { ErrorPage } from 'pages/Error/ErrorPage';

interface State {
	hasError: boolean;
	pathname?: string;
}

interface Props {
	children: React.ReactNode;
	pathname?: string;
}

export default class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
		pathname: this.props.pathname
	};

	public static getDerivedStateFromError(_: Error): State {
		// update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// logging the error to tools like Sentry
		console.error(error, errorInfo);
	}

	public static getDerivedStateFromProps(props: Props, state: State) {
		// in case the pathname changes, reset hasError and don't show the error fallback UI anymore
		if (props.pathname !== state.pathname) {
			return {
				hasError: false,
				pathname: props.pathname
			};
		}

		return null;
	}

	public render() {
		if (this.state.hasError) {
			return <ErrorPage />;
		}

		return this.props.children;
	}
}

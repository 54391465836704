import { cloneDeep } from 'lodash';

import {
	EntryFilter,
	ApiEntryFilters,
	ApiEntryStatusFilter,
	ApiEntryNoStatusFilter
} from 'api/data/filters';
import { STATUS_COLUMN, SYSTEM_GENERATED_STATUS_NAME } from 'consts';
import { VariablesMap } from 'store/data/variables';
import { getMicrosecondsFromTimeDurationString } from 'helpers/entries';
import { VariableType } from 'types/data/variables/constants';
import { VariableUniquenessType } from '../../api/data/variables/types';

export function parseApiEntryFilters(
	filters: EntryFilter[],
	variablesMap: VariablesMap = {}
): ApiEntryFilters {
	const parsedFilters: ApiEntryFilters = [];
	let statusFilter: EntryFilter | null = null;
	filters.forEach(filter => {
		let parsedFilter = cloneDeep(filter);
		const variable = variablesMap[filter.columnName];

		const isStatusFilter = parsedFilter.columnName === STATUS_COLUMN.name;

		if (isStatusFilter) return (statusFilter = parsedFilter);

		// parse timeDuration type filters
		if (variable.type === VariableType.TimeDuration) {
			parsedFilter = {
				...parsedFilter,
				...(parsedFilter.from !== undefined &&
					variable?.durationFormat && {
						from: getMicrosecondsFromTimeDurationString(
							parsedFilter.from.toString(),
							variable?.durationFormat
						)
					}),
				...(parsedFilter.to !== undefined &&
					variable?.durationFormat && {
						to: getMicrosecondsFromTimeDurationString(
							parsedFilter.to.toString(),
							variable?.durationFormat
						)
					}),
				...(parsedFilter.value !== undefined &&
					parsedFilter.value !== null &&
					variable?.durationFormat && {
						value: getMicrosecondsFromTimeDurationString(
							parsedFilter.value.toString(),
							variable?.durationFormat
						)
					})
			};
		}

		if (parsedFilter.filterSubType && parsedFilter.filterType === VariableType.Unique) {
			parsedFilter = {
				...parsedFilter,
				filterType:
					parsedFilter.filterSubType === VariableUniquenessType.Sequence
						? VariableType.Integer
						: VariableType.String
			};
		}

		if (parsedFilter.values !== undefined && parsedFilter.values.length === 0) {
			return;
		}
		parsedFilters.push(parsedFilter);
	});

	if (statusFilter) {
		const { values } = statusFilter as EntryFilter;

		const statusFilters: (ApiEntryStatusFilter | ApiEntryNoStatusFilter)[] = [];

		values?.forEach(statusVariableName => {
			if (statusVariableName === null) return;

			let parsedStatusFilter: ApiEntryStatusFilter | ApiEntryNoStatusFilter = {
				columnName: statusVariableName.toString(),
				filterType: 'statusSimple'
			};

			if (statusVariableName === SYSTEM_GENERATED_STATUS_NAME) {
				parsedStatusFilter = {
					filterType: 'noStatus'
				};
			}

			statusFilters.push(parsedStatusFilter);
		});

		parsedFilters.push(...statusFilters);
	}

	const parsedFiltersWithValues = parsedFilters.filter(f => {
		if (f.filterType === 'noStatus' || f.filterType === 'statusSimple') {
			return true;
		}
		if (f.filterType === 'search' || f.filterType === 'owner') {
			return f.value != null && f.value.length > 0;
		}
		return (
			f.value !== undefined ||
			(f.values && f.values.length > 0) ||
			(f.from !== undefined && f.to !== undefined)
		);
	});
	return parsedFiltersWithValues;
}

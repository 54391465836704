import { useSelector } from 'hooks/utils';
import { isBefore, isAfter, isEqual, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { TableName, TableFilterType } from 'types/index';
import { selectTableFilters } from 'store/ui/tables';
import { DateFilterOperator, NumericFilterOperator } from 'store/ui/tables/types';
import { formatAPIDate } from 'helpers/dateFormat';

type ItemToFilter = { [key: string]: string | number };

export function useFilteredItems(items: ItemToFilter[], tableName: TableName) {
	const filters = useSelector(state => selectTableFilters(state.ui.tables, tableName));

	const filteredItems = useMemo(() => {
		// filtering items
		let filteredArray = [...items];

		try {
			Object.keys(filters).forEach(columnName => {
				const filter = filters[columnName];

				if (filter.filterType === TableFilterType.Text && filter.text) {
					filteredArray = filteredArray.filter(
						item =>
							item[columnName]
								.toString()
								.toLocaleLowerCase()
								.indexOf(filter.text.toLocaleLowerCase()) >= 0
					);
				}

				if (filter.filterType === TableFilterType.Checkbox && filter.active.length > 0) {
					filteredArray = filteredArray.filter(item =>
						filter.active.includes(item[columnName].toString())
					);
				}

				if (filter.filterType === TableFilterType.Numeric) {
					const { operator, value, from, to } = filter;

					if (value || from || to) {
						filteredArray = filteredArray.filter(item => {
							if (operator === NumericFilterOperator.EqualTo)
								return Number(value) === Number(item[columnName]);
							if (operator === NumericFilterOperator.GreaterThan)
								return Number(value) < Number(item[columnName]);
							if (operator === NumericFilterOperator.LessThan)
								return Number(value) > Number(item[columnName]);
							if (operator === NumericFilterOperator.Between)
								return (
									Number(from) <= Number(item[columnName]) &&
									Number(to) >= Number(item[columnName])
								);
							return false;
						});
					}
				}

				if (filter.filterType === TableFilterType.Date) {
					const { operator, value, from, to } = filter;

					if (value || from || to) {
						filteredArray = filteredArray.filter(item => {
							const formattedDate = formatAPIDate(
								item[columnName].toString(),
								filter.formatType
							) as string;

							if (operator === DateFilterOperator.Between) {
								return (
									isAfter(parseISO(formattedDate), parseISO(from as string)) &&
									isBefore(parseISO(formattedDate), parseISO(to as string))
								);
							}
							if (operator === DateFilterOperator.Exact) {
								return isEqual(parseISO(formattedDate), parseISO(value as string));
							}
							if (operator === DateFilterOperator.Before) {
								return isBefore(parseISO(formattedDate), parseISO(value as string));
							}
							if (operator === DateFilterOperator.After) {
								return isAfter(parseISO(formattedDate), parseISO(value as string));
							}

							return false;
						});
					}
				}
			});
		} catch (err: any) {
			console.log('Error filtering items ' + err.message);
		}

		return filteredArray;
	}, [filters, items]);

	return { filteredItems };
}

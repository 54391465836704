import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectProjectsViewOption,
	ProjectsViewOptions,
	setProjectsViewOption
} from 'store/data/projects';

export function useProjectsViewOption(): LocalOperationResult<
	ProjectsViewOptions,
	(viewOption: ProjectsViewOptions) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectProjectsViewOption(state.data.projects));

	function handler(viewOption: ProjectsViewOptions) {
		dispatch(setProjectsViewOption({ viewOption }));
	}

	return [data, handler];
}

import produce from 'immer';

import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_ALL_ADDONS: {
			const { addons } = action.payload;

			return produce(state, draft => {
				draft.allAddons = addons;
			});
		}

		case ActionTypes.GET_USER_ADDONS: {
			const { userAddons, activeUserAddons } = action.payload;

			return produce(state, draft => {
				draft.userAddons = userAddons;
				draft.activeUserAddons = activeUserAddons;
			});
		}

		case ActionTypes.ACTIVATE_USER_ADDON: {
			const { addonCode } = action.payload;

			return produce(state, draft => {
				if (draft.activeUserAddons) draft.activeUserAddons.push(addonCode);
			});
		}

		case ActionTypes.DEACTIVATE_USER_ADDON: {
			const { addonCode } = action.payload;

			return produce(state, draft => {
				if (draft.activeUserAddons)
					draft.activeUserAddons = draft.activeUserAddons.filter(
						addon => addon !== addonCode
					);
			});
		}

		case ActionTypes.ASSIGN_SUBSCRIPTION_ADDON: {
			const { addonCode } = action.payload;

			return produce(state, draft => {
				if (draft.subscriptionAddonsByCode)
					draft.subscriptionAddonsByCode = {
						...draft.subscriptionAddonsByCode,
						[addonCode]: {
							...draft.subscriptionAddonsByCode[addonCode],
							available: draft.subscriptionAddonsByCode[addonCode].available--
						}
					};
			});
		}

		case ActionTypes.RETRACT_SUBSCRIPTION_ADDON: {
			const { addonCode } = action.payload;

			return produce(state, draft => {
				if (draft.subscriptionAddonsByCode)
					draft.subscriptionAddonsByCode = {
						...draft.subscriptionAddonsByCode,
						[addonCode]: {
							...draft.subscriptionAddonsByCode[addonCode],
							available: draft.subscriptionAddonsByCode[addonCode].available++
						}
					};
			});
		}

		default: {
			return state;
		}
	}
};

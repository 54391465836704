import { VariableSet } from 'api/data/variables';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { useTranslation, useDeleteVariableSet } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	variableSet: VariableSet;
	onClose: () => void;
}

export function DeleteVariableSetModal({ variableSet, onClose }: Props) {
	const { translate } = useTranslation();

	/* 
		TODO: ENABLE WHEN BACKEND IS ABLE TO KEEP VARIABLE SET CONTENT
		JIRA TASK: https://ledidi.atlassian.net/browse/PRJCTS-5446
	*/
	// const [removeContent, toggleRemoveContent] = useToggle(false);

	const [{ loading: deletingVariableSet, error: errorDeletingVariableSet }, deleteVariableSet] =
		useDeleteVariableSet();

	// CLOSE MODAL AFTER VARIABLE SET WAS DELETED
	useCompletedAction(deletingVariableSet, errorDeletingVariableSet, onClose);

	const { setName, setLabel } = variableSet;

	function handleDelete() {
		// TODO: ENHANCE THE API METHOD WITH `removeContent` FLAG
		deleteVariableSet(setName);
	}

	const title = `${translate(dict => dict.terms.delete)} ${setLabel}?`;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: deletingVariableSet,
				onClick: handleDelete
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				<b>{translate(dict => dict.terms.warning)}</b>
				{` ${translate(dict => dict.variablesPage.deleteVariableSetModal.message)}`}
			</Typography.Paragraph>

			{/* 
				TODO: ENABLE WHEN BACKEND IS ABLE TO KEEP VARIABLE SET CONTENT
				JIRA TASK: https://ledidi.atlassian.net/browse/PRJCTS-5446
			*/}
			{/* <Spacer size={s => s.s} />

			<Switch
				label={'Remove content'}
				description={
					removeContent
						? 'Variables & groups will be removed'
						: 'Variables & groups will maintain position'
				}
				on={removeContent}
				onChange={toggleRemoveContent}
			/> */}

			<Spacer size={s => s.m} />

			<Typography.Paragraph fontweight={w => w.bold} color={Colors.text.error}>
				{translate(dict => dict.terms.deleteWarningMessage)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { useEffect, useState } from 'react';
import { NarrowContainer } from 'components/Projects/CreateAndImport';

import {
	CenterContainer,
	ProgressContainer,
	ProgressWrapper,
	ProgressBar
} from './LoadingImportedData.style';
import { Spacer } from 'components/UI/Spacer';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useNavigation, useMatchProms } from 'hooks/navigation';
import {
	useTranslation,
	useProjectId,
	useUploadUrl,
	useImport,
	useImportVariableSet,
	useImportDataset
} from 'hooks/store';
import { useSelector } from 'hooks/utils';

interface Props {
	handleLoadingErrors: () => void;
}

export function LoadingImportedData({ handleLoadingErrors }: Props) {
	const { routes, replace } = useNavigation();
	const { translate } = useTranslation();
	const [projectId] = useProjectId();
	const matchProms = useMatchProms();
	const [, { resetCsvData }] = useUploadUrl();
	const [, { setPreviousMapping }] = useImport();
	const [
		{ isImportVariableSet, importVariableSetName },
		{ setIsImportVariableSet, setImportVariableSetName }
	] = useImportVariableSet();

	const [{ loading: uploading, error }] = useImportDataset();
	const [fakeLoading, setFakeLoading] = useState(false);
	const [finishedLoading, setFinishedLoading] = useState(false);
	const [fakePercentage, setFakePercentage] = useState(0);

	const importPercentage = useSelector(state => state.data.projects.import.importPercentage);

	useEffect(() => {
		if (!finishedLoading && uploading && fakeLoading && fakePercentage <= 85) {
			setTimeout(() => setFakePercentage(fakePercentage + 1), 20);
		}
	}, [uploading, fakeLoading, finishedLoading, fakePercentage]);

	useEffect(() => {
		if (uploading) {
			setFakeLoading(true);
			setTimeout(() => setFakeLoading(false), 1500);
		}
	}, [uploading]);

	useEffect(() => {
		if (!fakeLoading && !uploading) {
			setFakePercentage(100);
			setTimeout(() => {
				setFinishedLoading(true);
			}, 500);
		}
	}, [fakeLoading, uploading]);

	useEffect(() => {
		if (!uploading && error) {
			handleLoadingErrors();
		}
	}, [uploading, error]);

	useEffect(() => {
		if (finishedLoading && !uploading && !error && projectId) {
			if (matchProms) replace(routes.proms.dataset.view(projectId));
			else replace(routes.projects.dataset.view(projectId));

			resetCsvData();
			setPreviousMapping(null);
			isImportVariableSet && setIsImportVariableSet(false);
			importVariableSetName && setImportVariableSetName('');
		}
	}, [uploading, error, finishedLoading]);

	const subtitle =
		(translate(({ projects }) => projects.createAndImport.subtitle.finalStep) as string) ?? '';

	const splitSubtitle = subtitle.split('\n');

	const showFakePercentage = importPercentage === 0 || importPercentage < fakePercentage;

	return (
		<CenterContainer>
			<NarrowContainer>
				<Loader />

				<Spacer size={s => s.m} />

				<Typography.H3>
					{translate(({ projects }) => projects.createAndImport.title.finalStep)}
				</Typography.H3>

				<Spacer size={s => s.s} />

				<Typography.Paragraph>
					{splitSubtitle[0]} <br></br>
					{splitSubtitle[1]} <br></br>
					<b>{splitSubtitle[2]}</b>
				</Typography.Paragraph>

				<Spacer size={s => s.m} />

				<ProgressContainer>
					<ProgressWrapper>
						<ProgressBar
							width={`${showFakePercentage ? fakePercentage : importPercentage}%`}
						/>
					</ProgressWrapper>
					<Typography.H6>
						{showFakePercentage ? fakePercentage : importPercentage}%
					</Typography.H6>
				</ProgressContainer>
			</NarrowContainer>
		</CenterContainer>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, importTemplate } from 'store/data/templates';

export function useImportTemplate(): Operation<(templateId: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.IMPORT_TEMPLATE);

	function handler(templateId: string) {
		if (error) resetError();
		dispatch(importTemplate(templateId));
	}

	return [{ loading, error }, handler];
}

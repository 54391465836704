import { GroupData, VariablesData } from 'store/data/variables';
import { Variable } from 'api/data/variables';
import { DraggableVariable, TemplateGroupInterface } from 'store/data/templates';
import { TemplateVariablesTable } from './TemplateVariablesTable';
import { CreateWithTemplateHeader } from './CreateWithTemplateHeader';
import { Suspend } from 'components/UI/Suspend';
import { Modal } from 'components/UI/Modal';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import {
	getTemplateVariables,
	getTemplateGroupData,
	getTemplateVariablesAndGroupsMap,
	getFilteredGroupsAndVariablesArray,
	getFilteredGroupsAndVariablesMap,
	buildVariablesDataFromTemplate
} from 'helpers/templates';
import { buildVariablesDataLocation } from 'helpers/variables';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useProjectId,
	useSourceCreateTemplateId,
	useTemplateById,
	useFilteredVariablesDataArray,
	useTemplateVariablesTableCheckedData,
	useFetchedTemplates,
	useProjectTemplates,
	useUserTemplates,
	usePublicTemplates,
	useCreateAndImportTemplate
} from 'hooks/store';
import { useDeepCompareMemo, useDeepCompareCallback, useCompletedAction } from 'hooks/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

function isVariable(variableOrGroup: Variable | GroupData): variableOrGroup is Variable {
	return 'name' in variableOrGroup;
}

interface Props {
	onClose: () => void;
}

export function CreateWithTemplate({ onClose }: Props) {
	const { translate } = useTranslation();
	const { timeDurationFlag } = useFlags();
	const { navigate, routes } = useNavigation();
	const [projectId] = useProjectId();
	const [templateId, setTemplateId] = useSourceCreateTemplateId();
	const template = useTemplateById(templateId ?? '');

	const variablesDataArray = useDeepCompareMemo(() => {
		if (template) {
			const variables = getTemplateVariables(template.variables, timeDurationFlag);
			const groupData = getTemplateGroupData(template.groups, timeDurationFlag);

			return [...variables, ...groupData];
		}
		return [];
	}, [template, timeDurationFlag]);

	const templateVariablesMap: {
		variables: Record<string, DraggableVariable>;
		groups: Record<string, TemplateGroupInterface>;
	} = useDeepCompareMemo(() => {
		if (!template) return { variables: {}, groups: {} };
		return getTemplateVariablesAndGroupsMap(template?.variables, template.groups);
	}, [template]);

	const filteredVariablesDataArray = useFilteredVariablesDataArray({ variablesDataArray }) as (
		| Variable
		| GroupData
	)[];

	const computeTemplateVariableAndGroups = useDeepCompareCallback(
		(variablesDataArray: (Variable | GroupData)[]) => {
			const variables: DraggableVariable[] = [];
			const groups: TemplateGroupInterface[] = [];

			// SHOULD NOT USE TEMPLATEVARIABLESMAP!!! (IT HAS GROUP VARIABLES THAT ARE NOT FILTERED)
			variablesDataArray.forEach(variableOrGroup => {
				if (isVariable(variableOrGroup)) {
					variables.push(templateVariablesMap.variables[variableOrGroup.name]);
				} else {
					groups.push(templateVariablesMap.groups[variableOrGroup.groupName]);
				}
			});

			return { variables, groups };
		},
		[templateVariablesMap]
	);

	const { variables: originalVariables, groups: originalGroups } =
		computeTemplateVariableAndGroups(variablesDataArray);

	const { filteredVariables, filteredGroups } = getFilteredGroupsAndVariablesArray(
		filteredVariablesDataArray
	);

	const { variablesMap: filteredVariablesMap, groupsMap: filteredGroupsMap } =
		getFilteredGroupsAndVariablesMap(filteredVariables, filteredGroups);

	const { checkedMap, toggleChecked, toggleAllChecked } = useTemplateVariablesTableCheckedData({
		originalVariables,
		originalGroups,
		filteredVariablesMap,
		filteredGroupsMap,
		allowTimeDuration: timeDurationFlag
	});

	const variablesData: VariablesData = useDeepCompareMemo(() => {
		if (!template) {
			return {
				groupsMap: {},
				variablesMap: {},
				variableSetsMap: {},
				order: []
			};
		}
		return buildVariablesDataFromTemplate({
			templateGroups: originalGroups,
			templateVariables: originalVariables,
			allowTimeDuration: timeDurationFlag
		});
	}, [template]);

	const { variablesLocation } = useDeepCompareMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const templates = useFetchedTemplates();

	const [{ loading: loadingProjectTemplates, fetched: fetchedProjectTemplates }] =
		useProjectTemplates();

	const [{ loading: loadingUserTemplates, fetched: fetchedUserTemplates }] = useUserTemplates();
	const [{ loading: loadingPublicTemplates, fetched: fetchedPublicTemplates }] =
		usePublicTemplates();
	const [{ loading: importing, error: errorImporting }, createAndImportTemplate] =
		useCreateAndImportTemplate();

	const items = useDeepCompareMemo(() => {
		if (templates) {
			return Object.values(templates)
				.filter(template => !!template.templateName)
				.map(template => ({
					label: template.templateName,
					value: template.templateId
				}));
		}
		return [];
	}, [templates]);

	const fetchingTemplates =
		loadingProjectTemplates || loadingUserTemplates || loadingPublicTemplates;

	const fetched = fetchedUserTemplates || fetchedPublicTemplates || fetchedProjectTemplates;

	const loading = importing || fetchingTemplates;

	const onImport = useDeepCompareCallback(() => {
		// FILTER CHECKED PAYLOAD BASED ON FILTERED ARRAY;
		const variables: string[] = [];
		const groups: string[] = [];
		filteredVariables.forEach(({ name: variableName }) => {
			const groupParent =
				variablesLocation[variableName] && variablesLocation[variableName].groupName;
			if (
				checkedMap.variables[variableName] ||
				(groupParent && checkedMap.groupVariables[groupParent][variableName])
			) {
				variables.push(variableName);
			}
		});

		filteredGroups.forEach(({ groupName }) => {
			if (checkedMap.groups[groupName]) {
				groups.push(groupName);
			}
		});

		createAndImportTemplate({ variables, groups });
	}, [filteredVariables, filteredGroups, checkedMap, variablesLocation]);

	useCompletedAction(importing, errorImporting, () => {
		projectId && navigate(routes.projects.variables.view(projectId));
	});

	return (
		<Suspend immediate={!fetched} loading={loading}>
			<Modal
				size={s => s.full}
				fullSizeConfig={{
					centerTitle: true,
					narrow: true
				}}
				visible
				title={translate(dict => dict.projects.createAndImport.template.modal.title)}
				close
				local
				onClose={onClose}
				neutral={{ label: translate(dict => dict.buttons.back), onClick: onClose }}
				primary={{
					disabled: !templateId,
					label: translate(dict => dict.buttons.continue),
					onClick: onImport
				}}
			>
				<CreatableSelect
					onClear={() => setTemplateId(null)}
					items={items}
					value={items.find(item => item.value === templateId)}
					name={translate(
						dict => dict.projects.createAndImport.template.modal.selectName
					)}
					placeholder={translate(
						dict => dict.projects.createAndImport.template.modal.selectPlaceholder
					)}
					// value={templateId ? {value: templateId} undefined}
					onValueSelected={value => value && setTemplateId(value)}
				/>
				{templateId && (
					<>
						<CreateWithTemplateHeader templateId={templateId} />
						<TemplateVariablesTable
							variablesDataArray={filteredVariablesDataArray}
							filteredVariablesMap={filteredVariablesMap}
							filteredGroupsMap={filteredGroupsMap}
							checkedMap={checkedMap}
							toggleChecked={toggleChecked}
							toggleAllChecked={toggleAllChecked}
						/>
					</>
				)}
			</Modal>
		</Suspend>
	);
}

import * as yup from 'yup';
import { RequiredFields } from 'components/UI/RequiredFields';
import { AddonsToggles } from 'components/Addons';
import { NarrowContainer, Title } from 'components/Projects/CreateAndImport';
import { InputType, SetState, SubscriptionAddonCode } from 'types/index';
import { CreateProject } from 'store/data/projects';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { StickyFooter } from 'components/UI/StickyFooter';
import { arrayUtils } from 'helpers/arrays';
import { useNavigation } from 'hooks/navigation';
import { useShowAddons, useTranslation } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useEffectOnce } from 'hooks/utils';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';

interface Props {
	draftProm: CreateProject | null;
	setDraftProm: SetState<CreateProject | null>;
	userAddons: SubscriptionAddonCode[];
	activeUserAddons: SubscriptionAddonCode[];
	onContinue: () => void;
}

export function CreatePromForm({
	draftProm,
	setDraftProm,
	userAddons,
	activeUserAddons,
	onContinue
}: Props) {
	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();

	const { data: showAddons } = useShowAddons();

	// APPLY DRAFT PROJECT (IF EXISTS)
	useEffectOnce(() => {
		if (draftProm) {
			Object.keys(draftProm).forEach(key =>
				setValue(key as keyof CreateProject, draftProm[key as keyof CreateProject], {
					shouldDirty: true,
					shouldValidate: true
				})
			);
		}
	});

	const validationSchema = yup.object({
		projectName: yup
			.string()
			.trim()
			.required(translate(dict => dict.createProm.promTitleError))
	});

	const initialValues: CreateProject = {
		projectName: '',
		givenProjectNumber: '',
		status: ProjectStatus.Ongoing,
		projectType: ProjectType.PROM,
		description: '',
		slideFolderURL: '',
		activeAddonCodes: []
	};

	const {
		Form,
		register,
		setValue,
		getValues,
		handleSubmit,
		handleTrimOnBlur,
		errors,
		isDirty,
		touchedFields,
		control,
		Controller,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues,
		validationSchema
	});

	const handleFormSubmit = handleSubmit(() => {
		if (isDirty) {
			trimFields();

			const values = { ...getValues() };

			setDraftProm(values);
			onContinue();
		}
	});

	function trimFields() {
		const values = { ...getValues() };

		setValue('projectName', values.projectName.trim());
		setValue('description', values.description.trim());
		setValue('givenProjectNumber', values.givenProjectNumber.trim());
	}

	function toggleAddon(currentValues: SubscriptionAddonCode[], addon: SubscriptionAddonCode) {
		if (currentValues) {
			const newValues = arrayUtils.addOrSubstract(currentValues, addon);

			setValue('activeAddonCodes', newValues, {
				shouldDirty: true
			});
		}
	}

	return (
		<>
			<NarrowContainer>
				<Form onSubmit={handleFormSubmit}>
					<Title>{translate(({ projects }) => projects.createNewProm)}</Title>

					<Input
						{...register('projectName', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Text}
						label={translate(dict => dict.createProm.promTitle)}
						error={touchedFields.projectName ? errors.projectName?.message : ''}
						autoFocus
						required
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('description', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Textarea}
						label={translate(dict => dict.createProm.promDescription)}
						rows={4}
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('givenProjectNumber', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Text}
						label={translate(dict => dict.createProm.promNumber)}
					/>

					{showAddons && (
						<div>
							<Spacer size={s => s.l} />
							<Flex align={a => a.center}>
								<Typography.Paragraph>
									{translate(dict => dict.accountUM.tabs.addons)}
								</Typography.Paragraph>
							</Flex>
							<Spacer size={s => s.s} />
							<Controller
								control={control}
								name="activeAddonCodes"
								render={({ field: { value: currentEnabledAddons = [] } }) => (
									<AddonsToggles
										addons={userAddons}
										enabledAddons={currentEnabledAddons}
										activeUserAddons={activeUserAddons}
										onAddonClicked={(addonCode: SubscriptionAddonCode) =>
											toggleAddon(currentEnabledAddons, addonCode)
										}
									/>
								)}
							/>
						</div>
					)}

					<Spacer size={s => s.m} />
					<RequiredFields />
				</Form>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					disabled: !canSubmitForm,
					onClick: handleFormSubmit
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: () => navigate(routes.proms.list)
				}}
				maxWidth={65.2}
			/>
		</>
	);
}

import { selectError, selectErrors } from 'store/ui/activities';
import { useSelector } from 'hooks/utils';

export function useHasErrored(types: string | string[], payload?: any) {
	return useSelector(state => {
		if (typeof types === 'string') {
			return selectError(state.ui.activities, {
				type: types,
				payload
			});
		}

		return selectErrors(state.ui.activities, {
			types,
			payload
		});
	});
}

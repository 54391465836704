import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { GenericMap } from 'types/index';
import { ActionTypes, getProjectsLabels, selectProjectsLabels } from 'store/data/notifications';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useProjectsLabels(): OperationResult<GenericMap<string>> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectProjectsLabels(state.data.notifications));

	const [{ loading, error }, reset] = useActivity(ActionTypes.GET_PROJECTS_LABELS);

	useEffect(() => {
		if (!data && !error && !loading) handler();
	}, [loading, error, data]);

	function handler() {
		reset();
		dispatch(getProjectsLabels());
	}

	return [{ loading, error, data }, handler];
}

import { useState } from 'react';
import { NoDocuments } from './NoDocuments';
import { DocumentsList } from './List/DocumentsList';
import { DocumentsHeader } from './Header/DocumentsHeader';
import { UploadDocumentModal } from './Modals/UploadDocumentModal';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import { usePermissions, useProject, useProjectDocuments } from 'hooks/store';

export function DocumentsPage() {
	const {
		hasDatasetWriteAllAccess,
		fetched: arePermissionsFetched,
		loading: loadingPermissions
	} = usePermissions();

	const [{ loading: loadingProject }] = useProject();
	const [{ loading: loadingDocuments, data: documents, fetched: areDocumentsFetched }] =
		useProjectDocuments();

	const [uploadModalVisible, setUploadModalVisible] = useState(false);

	const hasDocuments = documents.length > 0;

	const loadingDocumentsInitial = loadingDocuments && !areDocumentsFetched;

	const loading = loadingDocumentsInitial || loadingProject || loadingPermissions;

	const immediate = !arePermissionsFetched || !areDocumentsFetched;

	return (
		<>
			<DocumentsHeader
				fetching={loadingDocuments}
				showAddFileButton={hasDatasetWriteAllAccess}
				hasDocuments={hasDocuments}
				onAddFileClick={() => setUploadModalVisible(true)}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{documents.length ? (
						<DocumentsList documents={documents} />
					) : (
						<NoDocuments onAddFileClick={() => setUploadModalVisible(true)} />
					)}
				</Grid.Container>
			</Suspend>

			{/* UPLOAD MODAL */}
			{uploadModalVisible && (
				<UploadDocumentModal onClose={() => setUploadModalVisible(false)} />
			)}
		</>
	);
}

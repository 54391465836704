import { EmptyListWrapper, Template } from '..';
import { SetState, TemplateShareLevel } from 'types/index';
import { Spacer } from 'components/UI/Spacer';
import { BuilderList } from './TemplatesBuilder.style';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { useNewBlankTemplate, useTranslation, useUsername } from 'hooks/store';

interface Props {
	editableTemplatesIds: string[];
	accessWrite: boolean;
	setShareLevel: SetState<TemplateShareLevel | null>;
	setTemplateIdForImport: SetState<string | null>;
}

export function TemplatesBuilder({
	editableTemplatesIds,
	accessWrite,
	setShareLevel,
	setTemplateIdForImport
}: Props) {
	const { translate } = useTranslation();
	const owner = useUsername() ?? '';
	const buildNewBlankTemplate = useNewBlankTemplate();

	const emptyTemplateList = !Object.values(editableTemplatesIds).length;
	const noTemplatesMessage = translate(({ templates }) => templates.noTemplatesMessage);

	return (
		<BuilderList>
			{emptyTemplateList ? (
				<EmptyListWrapper>
					<Typography.Paragraph>{noTemplatesMessage}</Typography.Paragraph>
					{accessWrite && (
						<>
							<Spacer size={s => s.s} />
							<Button
								title={translate(
									({ templates }) => templates.createYourFirstTemplate
								)}
								onClick={() => buildNewBlankTemplate({ owner })}
								variant={v => v.link}
							/>
						</>
					)}
				</EmptyListWrapper>
			) : (
				// Templates List
				editableTemplatesIds.map((templateId, index) => {
					return (
						<Template
							key={templateId + index}
							withUserEdit
							index={index}
							templateId={templateId}
							accessWrite={accessWrite}
							setShareLevel={setShareLevel}
							setTemplateIdForImport={setTemplateIdForImport}
						/>
					);
				})
			)}
		</BuilderList>
	);
}

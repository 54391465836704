import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { VariableCardPayload } from '../Cards/CommonCard';
import { useTranslation, useVariables, useIsVariableInCalculationCases } from 'hooks/store';

interface Props {
	item: VariableCardPayload;
	direction: 'from' | 'to';
	onConfirm: () => void;
	onClose: () => void;
}

export function VariableSetConfirmDragAndDropActionModal({
	item,
	direction,
	onConfirm,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { variablesMap, groupsMap }
		}
	] = useVariables({ initial: true, lazy: true });

	const calculatedVariableLabels = useIsVariableInCalculationCases(item.name);

	function getDragDirectionText() {
		if (direction === 'from') return 'out';

		return '';
	}

	function getItemLabel() {
		if (item.isGroup) return groupsMap[item.name].groupLabel;

		return variablesMap[item.name].label;
	}

	function getItemType() {
		if (item.isGroup) return 'group';

		return 'variable';
	}

	function handleConfirm() {
		onConfirm();
		onClose();
	}

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.terms.confirmation)}
			primary={{
				label: translate(dict => dict.buttons.yes),
				warning: true,
				onClick: handleConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{`${translate(
					dict =>
						dict.variablesPage.variableSetConfirmDragAndDropActionModal.beforeVariable
				)} ${getDragDirectionText()} ${translate(
					dict => dict.variablesPage.variableSetConfirmDragAndDropActionModal.between
				)} "${getItemLabel()}" ${getItemType()} ${direction} ${translate(
					dict =>
						dict.variablesPage.variableSetConfirmDragAndDropActionModal.afterVariable
				)}\n\n`}

				{calculatedVariableLabels.length > 0 && (
					<>
						{translate(dict => dict.promptComposite.description)}
						{'\n'}
						{calculatedVariableLabels.map((compositeVar, index) => (
							<b key={index}>{`${compositeVar}\n`}</b>
						))}
						{'\n'}
					</>
				)}

				<b>{translate(dict => dict.terms.deleteWarningMessage)}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

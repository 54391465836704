import { Entries } from 'components/Dataset';
import { Container } from './DatasetEntriesPreviewModal.style';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useTransferEntriesOwnershipContext, useEntriesById } from 'hooks/store';
import type { TableSort } from 'store/ui/tables/types';

interface Props {
	activeSort?: TableSort;
	handleSort: (columnName: string) => void;
}

export function DatasetEntriesPreviewModal({ activeSort, handleSort }: Props) {
	const { translate } = useTranslation();

	const {
		actions: { getSelectedEntries, setTransferModal, closePreviewModal }
	} = useTransferEntriesOwnershipContext();

	const selectedEntriesCount = getSelectedEntries().filter(
		selectedEntry => selectedEntry.confirmed
	).length;

	const selectedEntryIds = getSelectedEntries().map(({ entryId }) => entryId);

	const selectedEntries = useEntriesById(selectedEntryIds);

	function onContinue() {
		setTransferModal(true);
	}

	function handleClose() {
		closePreviewModal();
	}

	return (
		<Modal
			title={translate(dict => dict.dataset.entries.transferOwnership.previewModal.title)}
			primary={{
				label: translate(dict => dict.buttons.continue),
				disabled: selectedEntriesCount === 0,
				onClick: onContinue
			}}
			neutral={{
				label: translate(dict => dict.buttons.back),
				onClick: handleClose
			}}
			onClose={handleClose}
			size={s => s.full}
			visible
			close
		>
			<Typography.Paragraph fontweight={w => w.bold} marginOffset={{ bottom: 2.6 }}>
				{translate(dict => dict.dataset.entries.transferOwnership.previewModal.text, true, {
					count: selectedEntriesCount,
					plural:
						selectedEntriesCount === 1
							? translate(
									dict => dict.dataset.modals.datasetEntriesPreviewModal.singular
							  )
							: translate(
									dict => dict.dataset.modals.datasetEntriesPreviewModal.plural
							  )
				})}
			</Typography.Paragraph>
			<Container>
				<Entries
					handleSort={handleSort}
					activeSort={activeSort}
					presetEntries={selectedEntries}
					transferOwnershipUsage
				/>
			</Container>
		</Modal>
	);
}

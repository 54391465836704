import { EntryFilter } from 'api/data/filters';
import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectActiveFilterIds,
	CreateFilterAction,
	UpdateFilterAction,
	InvalidateFilterAction,
	createFilter,
	updateFilter,
	deleteDatasetFilter,
	deleteFilters,
	toggleOpenFilters,
	invalidateFilter,
	clearFilters,
	selectFiltersById,
	selectActiveFilters,
	selectInvalidFilterIds,
	selectAreFiltersValid
} from 'store/data/filters';
import { ActionPayload } from 'store/types';
import { GenericMap } from 'types/index';

interface Data {
	activeFilterIds: string[];
	invalidFilterIds: string[];
	filters: EntryFilter[];
	filtersById: GenericMap<EntryFilter>;
	areFiltersValid: boolean;
	areFiltersActive: boolean;
	areFiltersOpen?: boolean;
}

interface Handlers {
	createFilter: (payload: ActionPayload<CreateFilterAction>) => void;
	updateFilter: (payload: ActionPayload<UpdateFilterAction>) => void;
	deleteDatasetFilter: (filterId: string) => void;
	deleteFilters: (filterIds: string[]) => void;
	invalidateFilter: (payload: ActionPayload<InvalidateFilterAction>) => void;
	clearFilters: () => void;
	toggleOpenFilters: () => void;
}

export function useFilters(): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const activeFilterIds = useSelector(state => selectActiveFilterIds(state.data.filters));
	const invalidFilterIds = useSelector(state => selectInvalidFilterIds(state.data.filters));

	const filters = useSelector(state => selectActiveFilters(state.data.filters));

	const filtersById = useSelector(state => selectFiltersById(state.data.filters));

	const areFiltersValid = useSelector(state => selectAreFiltersValid(state.data.filters));
	const areFiltersOpen = useSelector(state => state.data.filters.dataset.open);

	const areFiltersActive = activeFilterIds.length > 0;

	const data = {
		activeFilterIds,
		invalidFilterIds,
		filters,
		filtersById,
		areFiltersValid,
		areFiltersActive,
		areFiltersOpen
	};

	const handlers = {
		createFilter: (payload: ActionPayload<CreateFilterAction>) =>
			dispatch(createFilter(payload)),
		updateFilter: (payload: ActionPayload<UpdateFilterAction>) =>
			dispatch(updateFilter(payload)),
		deleteDatasetFilter: (filterId: string) => dispatch(deleteDatasetFilter(filterId)),
		deleteFilters: (filterIds: string[]) => dispatch(deleteFilters(filterIds)),
		invalidateFilter: (payload: ActionPayload<InvalidateFilterAction>) =>
			dispatch(invalidateFilter(payload)),
		clearFilters: () => dispatch(clearFilters()),
		toggleOpenFilters: () => dispatch(toggleOpenFilters())
	};

	return [data, handlers];
}

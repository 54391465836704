import { useEffect } from 'react';
import { isEqual, cloneDeep } from 'lodash';

import { useSelector } from 'hooks/utils';
import { useDispatch } from 'hooks/utils';
import {
	selectTableFilters,
	updateFilterAction,
	updateFiltersAction,
	resetFilterAction
} from 'store/ui/tables';
import type { TableFilter, CheckboxTableFilter, FiltersByColumnName } from 'store/ui/tables/types';
import { DateFilterOperator, NumericFilterOperator } from 'store/ui/tables/types';
import { GenericMap, TableFilterType, TableName } from 'types/index';

interface InitFilterData {
	filterType: TableFilterType;
	filterDateFormat: string;
	checkboxes?: string[];
	hasSearch?: boolean;
}

export type InitFiltersDataByColumn = GenericMap<InitFilterData | undefined>;

export const useTableFilters = (
	tableName: TableName,
	initFiltersDataByColumn: InitFiltersDataByColumn
) => {
	const filters = useSelector(state => selectTableFilters(state.ui.tables, tableName));

	const dispatch = useDispatch();

	/**
	 * Update store with default filters
	 * If filter already exists, update only the value for available checkboxes in care it is a CheckboxFilter type
	 */
	useEffect(() => {
		const filtersToUpdate: FiltersByColumnName = {};

		Object.keys(initFiltersDataByColumn).forEach(columnName => {
			const initFilterData = initFiltersDataByColumn[columnName];
			let filterToUpdate: TableFilter | undefined;

			if (initFilterData) {
				if (!filters[columnName]) {
					filterToUpdate = generateEmptyFilter(initFilterData);
				} else {
					// If filter already exists, update only the value for checkboxes in case it is a CheckboxFilter type
					// and is different than the store value
					if (
						initFilterData.checkboxes &&
						filters[columnName].filterType === TableFilterType.Checkbox
					) {
						const storeFilter = {
							...filters[columnName]
						} as CheckboxTableFilter;
						if (!isEqual(storeFilter.all, initFilterData.checkboxes)) {
							filterToUpdate = cloneDeep(storeFilter);
							filterToUpdate.all = initFilterData.checkboxes.slice();
						}
					}
				}
			}
			if (filterToUpdate) {
				filtersToUpdate[columnName] = filterToUpdate;
			}
		});

		if (Object.values(filtersToUpdate).length > 0) {
			dispatch(updateFiltersAction({ filters: filtersToUpdate, tableName }));
		}
	}, [initFiltersDataByColumn]);

	function generateEmptyFilter({
		filterType,
		checkboxes,
		filterDateFormat,
		hasSearch
	}: InitFilterData): TableFilter | undefined {
		if (filterType === TableFilterType.Text) {
			return {
				filterType,
				text: ''
			};
		}
		if (filterType === TableFilterType.Checkbox) {
			return {
				filterType,
				active: [],
				all: checkboxes ?? [],
				hasSearch: hasSearch ?? false
			};
		}
		if (filterType === TableFilterType.Date) {
			return {
				filterType,
				valid: false,
				operator: DateFilterOperator.Before,
				value: null,
				from: null,
				to: null,
				formatType: filterDateFormat
			};
		}
		if (filterType === TableFilterType.Numeric) {
			return {
				filterType,
				valid: false,
				operator: NumericFilterOperator.EqualTo,
				value: null,
				from: null,
				to: null
			};
		}
	}

	function updateFilter(filter: TableFilter, columnName: string) {
		dispatch(updateFilterAction({ filter, columnName, tableName }));
	}

	function resetFilter(columnName: string) {
		dispatch(resetFilterAction({ columnName, tableName }));
	}

	return { filters, updateFilter, resetFilter };
};

import { getSetEntryRevisionData } from './../../../../store/data/revisions/actions';
import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, Revision, selectSetRevision } from 'store/data/revisions';
import { useSetRevisionId } from './useSetRevisionId';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

interface Options {
	lazy?: boolean;
}

type Data = Revision | undefined;

export function useSetRevision(options?: Options): OperationResult<Data> {
	const dispatch = useDispatch();

	const [revisionId] = useSetRevisionId();

	const data = useSelector(state => selectSetRevision(state.data.revisions));

	const [{ loading, error }] = useActivity(ActionTypes.GET_SET_ENTRY_REVISION_DATA);

	useEffect(() => {
		// replace once changes api is fixed;
		const isDataFetched = data?.changes.data !== null;

		if (revisionId && !isDataFetched && !loading && !error && !options?.lazy) {
			handler();
		}
	}, [error, loading, data]);

	function handler() {
		dispatch(getSetEntryRevisionData());
	}

	return [{ data, loading, error }, handler];
}

import { useMatchProms, useMatchProjects } from 'hooks/navigation';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	setTableFilters,
	TableFilters,
	selectProjectsTableFilters,
	selectPromsTableFilters
} from 'store/data/projects';

export function useProjectsTableFilters(): LocalOperationResult<
	TableFilters,
	(options: TableFilters) => void
> {
	const dispatch = useDispatch();
	const projectsFilters = useSelector(state => selectProjectsTableFilters(state.data.projects));
	const promsFilters = useSelector(state => selectPromsTableFilters(state.data.projects));
	const matchProms = useMatchProms();
	const matchProjects = useMatchProjects();

	let data: TableFilters = { filters: [], pageIndex: 0, pageSize: 0, sortBy: [] };

	if (matchProjects) {
		data = projectsFilters;
	}

	if (matchProms) {
		data = promsFilters;
	}

	function handler(options: TableFilters) {
		dispatch(setTableFilters({ options, matchProms }));
	}

	return [data, handler];
}

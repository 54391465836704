import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FontWeights, Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export const Text = styled(Typography.Paragraph)``;

interface TabProps {
	width: number;
	underline_width: number;
	disabled?: boolean;
}

interface DivTabProps extends TabProps {
	active: boolean;
}
export const DivTab = styled.div<DivTabProps>`
	${({ active, ...tabProps }) => css`
		${getTabStyle(tabProps)}

		${active &&
		css`
			div {
				::after {
					opacity: 1;
				}
			}

			${Text} {
				color: ${Colors.primary.normal};
				font-weight: ${FontWeights.medium};
			}
		`}
	`}
`;

export const NavTab = styled(NavLink)<TabProps>`
	${tabProps => css`
		${getTabStyle(tabProps)}

		&.active {
			text-decoration: none;

			div {
				::after {
					opacity: 1;
				}
			}

			${Text} {
				color: ${Colors.primary.normal};
				font-weight: ${FontWeights.medium};
			}
		}

		:hover {
			border-color: ${Colors.primary.normal}!important;
			text-decoration: none;
		}

		&:not(.active):visited {
			border-color: ${Colors.transparent};
			text-decoration: none;
		}
	`}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;

			${Typography.Paragraph} {
				color: ${Colors.text.disabled};
			}
		`}
`;

interface NormalTabProps {
	width?: number;
}
export const NormalTab = styled.div<NormalTabProps>`
	display: flex;
	align-items: center;
	width: ${({ width = 12.6 }) => width}rem;
	height: 7.2rem;
	position: relative;
	cursor: pointer;

	.dropdown-tab {
		height: 100%;
	}
`;

function getTabStyle({ underline_width }: TabProps) {
	return css`
		display: flex;
		align-items: center;
		height: 7.2rem;
		position: relative;
		user-select: none;
		cursor: pointer;
		text-decoration: none;
		margin: 0 1.6rem;

		div {
			width: 100%;
			display: flex;
			justify-content: center;

			::after {
				display: block;
				content: '';
				width: ${underline_width}rem;
				height: 0.2rem;
				border-radius: 0 0 0.4rem 0.4rem;
				background-color: ${Colors.primary.normal};
				position: absolute;
				bottom: 0;
				opacity: 0;
			}
		}

		@media print {
			display: none;
		}

		:hover {
			${Text} {
				color: ${Colors.primary.normal};
			}
		}
	`;
}

import { useActivities } from 'hooks/store/utils/useActivities';
import { ActionTypes } from 'store/data/variables';
import { ActionTypes as ProjectActionTypes } from 'store/ui/activities';

export function useIsMoveInProgress() {
	const [{ loading }] = useActivities([
		ActionTypes.ADD_VARIABLE_TO_SET,
		ActionTypes.REMOVE_VARIABLE_FROM_SET,
		ActionTypes.MOVE_VARIABLE_BETWEEN_SETS,
		ActionTypes.ADD_VARIABLE_GROUP_TO_SET,
		ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET,
		ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS,
		ProjectActionTypes.DO_AFTER_TRANSACTION
	]);
	return loading;
}

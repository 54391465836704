import { useMemo } from 'react';

import { McNemarResultsDataV2, McNemarResultsV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { useAnalysisErrorMessage } from 'hooks/store/data/analysis/useAnalysisErrorMessage';

interface Props {
	results: McNemarResultsV2;
}

export function CrosstabMcNemarV2({ results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	const translatedMessage = useAnalysisErrorMessage(error);

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.mcNemar.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{translatedMessage ??
						translate(({ errors }) => errors.api.analyses.statistics.mcNemar)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result data={data} />}
		</AnalysisStatistic>
	);
}

function Result({ data }: { data: McNemarResultsDataV2 }) {
	const { XStatistic, pStatistic } = useMemo(() => {
		let XStatistic = {
			JSX: <>N/A</>,
			text: 'N/A'
		};

		if (data.statistic !== null) {
			XStatistic = getXStatistic({
				stat: data.statistic
			});
		}
		let pStatistic = {
			JSX: <>N/A</>,
			text: 'N/A'
		};
		if (data.pValue !== null) {
			pStatistic = getPStatistic({
				pValue: data.pValue
			});
		}

		return { XStatistic, pStatistic };
	}, [data]);

	return (
		<Table.Responsive horizontalScroll fullWidth={false}>
			<Table>
				<Table.Body>
					<Table.Row>
						{!data.exact && (
							<Table.Cell title={XStatistic.text} width={14} noWrap alignBottom>
								{XStatistic.JSX}
							</Table.Cell>
						)}

						<Table.Cell title={pStatistic.text} width={14} noWrap alignBottom>
							{pStatistic.JSX}
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

function getXStatistic(input: { stat: number }) {
	const { stat } = input;

	const { value: parsedStat } = parseAnalysisNumber(stat);

	const output = {
		JSX: (
			<>
				<i>{`X`}</i>
				<sup>{`2`}</sup>
				{`(1) = ${parsedStat}`}
			</>
		),
		text: `X^2(1) = ${parsedStat}`
	};

	return output;
}

function getPStatistic(input: { pValue: number }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import {
	shareRoleTemplateWithInstance,
	ShareRoleTemplateWithInstanceAction
} from 'store/data/roles';
import { ActionPayload } from 'store/types';

export function useShareRoleTemplateWithInstance(): LocalOperation<
	(payload: ActionPayload<ShareRoleTemplateWithInstanceAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ShareRoleTemplateWithInstanceAction>) {
		dispatch(shareRoleTemplateWithInstance(payload));
	}

	return handler;
}

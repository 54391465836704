import { useEffect } from 'react';

import { useActivity, useIsProjectValid, useProjectId } from 'hooks/store';
import { useSelector, useDispatch } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	listDashboards,
	selectDashboards,
	selectDashboardsFetched,
	Dashboard
} from 'store/data/projectDashboard';
import { GenericMap } from 'types/index';

interface Options {
	lazy?: boolean;
}

export function useListCurrentDashboards(
	options?: Options
): OperationResult<GenericMap<Dashboard> | null> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};
	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const data = useSelector(state => selectDashboards(state.data.projectDashboard));

	const fetched = useSelector(state => selectDashboardsFetched(state.data.projectDashboard));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.LIST_DASHBOARDS, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(listDashboards());
	}

	return [
		{
			loading,
			error,
			data
		},
		handler
	];
}

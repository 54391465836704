import { useState, useRef } from 'react';
import { Document } from 'react-pdf';
import { nanoid as generate } from 'nanoid';
import { Colors, Svgs } from 'environment';
import { StoredEntryFile } from 'store/data/entries';
import { selectProjectFileToView } from 'store/data/documents';
import { FileMimeType, FileExtension, StringMap } from 'types/index';
import { PdfControls } from './PdfControls/PdfControls';
import { CsvToTable } from './CsvToTable/CsvToTable';
import {
	Image,
	NoPreview,
	NoPreviewTitle,
	TextPreview,
	PageBackground,
	ViewDocumentContainer,
	PDFContainer,
	Page
} from './PreviewDocument.style';
import { NumberedPages } from './PdfControls/NumberedPages';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useProjectId, useConvertS3Document } from 'hooks/store';
import { useEffectOnce, useSelector } from 'hooks/utils';

interface Props {
	entryFile?: StoredEntryFile;
}

const defaultScale = 1.3;

export function PreviewDocument({ entryFile }: Props) {
	const { translate } = useTranslation();
	const [projectId] = useProjectId();

	const [scale, setScale] = useState(defaultScale);
	const [pages, setPages] = useState<number[]>([]);
	const [pageNumberInView, setPageNumberInView] = useState(1);

	const ref = useRef<HTMLDivElement>(null);

	const projectFile = useSelector(state => selectProjectFileToView(state.data.documents));

	const file = entryFile ? entryFile : projectFile;

	const [{ data: csvFile }, parseCsvFromS3] = useConvertS3Document();

	useEffectOnce(() => {
		if (file) {
			const displayAsTextorTable =
				file.extendedMimeType === FileMimeType.CSV ||
				file.extendedMimeType === FileMimeType.XLS ||
				file.extendedMimeType === FileMimeType.XLSX ||
				file.extendedMimeType === FileMimeType.TXT;

			if (displayAsTextorTable && projectId) {
				parseCsvFromS3(file.signedS3Url);
			}
		}
	});

	function onDocumentLoadSuccess(numPages: number) {
		const state = [...new Array(numPages)].fill(null).map((_, i) => i + 1);
		setPages(state);
	}

	function zoomIn() {
		setScale(scale * 2);
	}

	function zoomOut() {
		if (scale > 1) setScale(scale / 2);
	}

	const pictureTypes = [
		FileMimeType.JPG,
		FileMimeType.PNG,
		FileMimeType.BMP,
		FileMimeType.GIF,
		FileMimeType.TIFF,
		//
		FileExtension.JPEG,
		FileExtension.JPG,
		FileExtension.PNG,
		FileExtension.GIF,
		FileExtension.BMP,
		FileExtension.TIFF
	] as string[];

	const tableTypes = [
		FileMimeType.CSV,
		FileMimeType.XLS,
		FileMimeType.XLSX,
		//
		FileExtension.CSV,
		FileExtension.XLS,
		FileExtension.XLSX
	] as string[];

	return (
		<ViewDocumentContainer id="print-table" className="scrollContainer">
			{(() => {
				if (file) {
					const { extendedMimeType, mimeType } = file;
					if (
						pictureTypes.indexOf(extendedMimeType) > -1 ||
						pictureTypes.indexOf(mimeType) > -1
					)
						return (
							<Image
								src={file.signedS3Url}
								alt={translate(dict => dict.login.chromeLogoAlt)}
							/>
						);

					if (extendedMimeType === FileMimeType.PDF || mimeType === FileExtension.PDF)
						return (
							<>
								<PDFContainer>
									<Document
										file={file.signedS3Url}
										loading={
											<Typography.Paragraph>
												{translate(
													({ documents }) => documents.view.loading
												)}
											</Typography.Paragraph>
										}
										error={
											<Typography.Paragraph>
												{translate(({ documents }) => documents.view.error)}
											</Typography.Paragraph>
										}
										onLoadSuccess={info => onDocumentLoadSuccess(info.numPages)}
									>
										<NumberedPages
											pages={pages}
											targetClass="scrollContainer"
											setPageNumberInView={setPageNumberInView}
											pageComponent={pageNumber => (
												<Page
													pageNumber={pageNumber}
													scale={scale}
													renderAnnotationLayer={false}
													loading={<></>} // hide loading message
												/>
											)}
										/>
									</Document>
								</PDFContainer>

								<PdfControls
									pageIndex={pageNumberInView}
									totalPages={pages.length}
									defaultScale={defaultScale}
									scale={scale}
									zoomIn={zoomIn}
									zoomOut={zoomOut}
								></PdfControls>
							</>
						);

					if (
						tableTypes.indexOf(extendedMimeType) > -1 ||
						tableTypes.indexOf(mimeType) > -1
					) {
						if (csvFile) {
							const header = csvFile[0];
							const [, ...rows] = csvFile;
							let unnamedColumn = 0;

							const formattedHeader = header.map(headerItem => {
								const headerAccessor =
									headerItem === ''
										? `${translate(
												dict => dict.terms.unnamed
										  )} ${unnamedColumn}`
										: headerItem;

								unnamedColumn++;

								return {
									Header: headerAccessor,
									accessor: generate()
								};
							});

							const formattedRows = rows.map(row => {
								const newRow: StringMap = {};

								formattedHeader.forEach(({ accessor }, index) => {
									newRow[accessor] = row[index];
								});

								return newRow;
							});

							return (
								<PageBackground ref={ref}>
									<CsvToTable
										containerRef={ref}
										columns={formattedHeader}
										data={formattedRows}
									/>
								</PageBackground>
							);
						} else
							return (
								<PageBackground center>
									<Loader />
								</PageBackground>
							);
					}

					if (extendedMimeType === FileMimeType.TXT || mimeType === FileExtension.TXT)
						return (
							<PageBackground center>
								<TextPreview>{csvFile}</TextPreview>
							</PageBackground>
						);

					return (
						<NoPreview>
							<Icon svg={Svgs.File} colors={{ color: Colors.gray.medium }} />
							<NoPreviewTitle>
								{translate(dict => dict.documents.view.noPreview)}
							</NoPreviewTitle>
						</NoPreview>
					);
				}
			})()}
		</ViewDocumentContainer>
	);
}

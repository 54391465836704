import styled from 'styled-components';

interface LoadingWrapperProps {
	show: boolean;
}

export const LoadingWrapper = styled.div<LoadingWrapperProps>`
	min-height: 10rem;
	display: ${({ show }) => (show ? 'block' : 'none')};
`;

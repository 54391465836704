import { useMemo } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { DraggableCard, TimesUsed } from 'components/Forms';
import { Svgs, Colors } from 'environment';
import { VariableSetData } from 'store/data/variables';
import { ColorIndicator } from './style';
import { AddVariableIcon, CardLabel } from './DraggableFormVariableCard.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { computeFormSetFromVariableSetData } from 'helpers/forms';
import { useFormsMap, useAppendFormSet, useTranslation } from 'hooks/store';

interface Props {
	provided: DraggableProvided;
	variableSetData: VariableSetData;
	canAppend: boolean;
}

export function DraggableFormVariableSetCard({ provided, variableSetData, canAppend }: Props) {
	const formsMap = useFormsMap();

	const appendFormSet = useAppendFormSet();

	const { setName, setLabel } = variableSetData;
	const { translate } = useTranslation();
	const formSet = computeFormSetFromVariableSetData(variableSetData);

	const timesUsed = useMemo(() => {
		let times = 0;

		Object.values(formsMap).forEach(form => {
			if (form.initial.usedSets.includes(setName)) times++;
		});

		return times;
	}, [formsMap]);

	return (
		<DraggableCard
			key={setName}
			data-test-id={setLabel}
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
		>
			<ColorIndicator type="variableSet" />

			<Icon
				svg={Svgs.Set}
				size={s => s.m}
				colors={{ color: Colors.seriesGreen }}
				marginOffset={{ right: 0.8 }}
				propagate
			/>
			<CardLabel title={setLabel}>{setLabel}</CardLabel>
			{canAppend && (
				<AddVariableIcon className="add-set-icon">
					<Icon
						showTooltip={false}
						svg={Svgs.Add}
						onClick={() => appendFormSet({ formSet })}
						active
					/>
					{timesUsed > 0 && (
						<TimesUsed className="times-set-used">
							<Typography.Paragraph>{`${translate(
								dict => dict.formDesigner.usedIn
							)} ${timesUsed} ${
								timesUsed > 1
									? translate(dict => dict.projectTabs.forms).toLowerCase()
									: translate(dict => dict.formDesigner.form).toLowerCase()
							}`}</Typography.Paragraph>
						</TimesUsed>
					)}
				</AddVariableIcon>
			)}
		</DraggableCard>
	);
}

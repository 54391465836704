import { AggregationRule, TimeDurationFormat } from 'api/data/variables';
import { Dictionary } from 'environment';
import { getTimeDurationInputPreview, parseTimeDurationEntryCell } from 'helpers/entries';
import { parseVariableSetAggregatedValue } from 'helpers/variables';
import { useTranslation } from 'hooks/store';
import { useSelector } from 'hooks/utils';
import { keys } from 'lodash';
import { useCallback } from 'react';
import { DateTimeFormat } from 'store/account/subscription';
import { VariablesMap } from 'store/data/variables';
import { selectLanguage, selectTranslations } from 'store/ui/i18n';
import { StringMap } from 'types/index';

interface Props {
	withTranslation?: boolean;
}

export function useTimeDurationEntries({ withTranslation = true }: Props) {
	const { translate } = useTranslation();
	const language = useSelector(state => selectLanguage(state.ui.i18n));
	const translations = useSelector(state => selectTranslations(state.ui.i18n));

	const getTranslateMap = useCallback(
		(key: keyof typeof Dictionary.timeDurationPlaceholder) => {
			const translateMap = keys(Dictionary.timeDurationPlaceholder[key]).reduce<StringMap>(
				(acc, currKey) => ({
					...acc,
					[currKey]: translate(
						dict =>
							dict.timeDurationPlaceholder[key][
								currKey as keyof typeof Dictionary.timeDurationPlaceholder.prefix
							]
					)
				}),
				{}
			);
			return translateMap;
		},
		[language, translations]
	);

	const parseTimeDurationTranslated = useCallback(
		(value: string | undefined, format: TimeDurationFormat) => {
			const translateMap = getTranslateMap('prefix');

			return parseTimeDurationEntryCell(value, format, translateMap);
		},
		[language, translations]
	);

	const getTimeDurationInputPreviewTranslated = useCallback(
		(value: string, format: TimeDurationFormat) => {
			const translateMap = getTranslateMap('prefix');
			return getTimeDurationInputPreview(value, format, translateMap);
		},
		[language, translations]
	);

	const parseVariableSetAggregatedValueTranslated = useCallback(
		(
			value: string,
			aggregationRule: AggregationRule,
			metadata: { variablesMap: VariablesMap; dateTimeFormat: DateTimeFormat }
		) => {
			const translateMap = getTranslateMap('prefix');
			return parseVariableSetAggregatedValue(value, aggregationRule, metadata, translateMap);
		},
		[language, translations]
	);

	if (withTranslation) {
		return {
			getTimeDurationInputPreview: getTimeDurationInputPreviewTranslated,
			parseTimeDurationEntryCell: parseTimeDurationTranslated,
			parseVariableSetAggregatedValue: parseVariableSetAggregatedValueTranslated
		};
	}

	return {
		getTimeDurationInputPreview,
		parseTimeDurationEntryCell,
		parseVariableSetAggregatedValue
	};
}

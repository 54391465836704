import styled from 'styled-components';

import { Tabs as DefaultTabs } from 'components/UI/Tabs';

export const Tabs = styled(DefaultTabs)`
	> div > div {
		width: 11.3rem;

		& ::after {
			width: 4.1rem;
		}
	}
`;

import { apiFactory } from 'api';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/account/subscription';

export function useAPIResetEnterpriseUserPassword(): ApiOperation<void, (userId: string) => void> {
	const [{ error, loading, fetched }, resetPassword, resetHandler] = useAPI<void>({
		promiseFn: undefined,
		lazy: true,
		initialData: undefined,
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		activityType: ActionTypes.RESPOND_TO_SUBSCRIPTION_INVITATION
	});

	function promiseFn(userId: string) {
		return apiFactory().account.enterprise().resetEnterpriseUserPassword(userId);
	}

	function handler(userId: string) {
		resetPassword(() => promiseFn(userId));
	}

	return [{ data: undefined, error, loading, fetched }, handler, resetHandler];
}

import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

interface ContainerProps {
	isOpen?: boolean;
}

export const Container = styled.div<ContainerProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};
	margin-bottom: ${({ isOpen }) => (isOpen ? '2rem' : '1rem')};
`;

export const Header = styled.div`
	display: flex;
	padding: 1rem;
	align-items: center;
	cursor: pointer;
`;

export const HeaderInfo = styled.div`
	display: flex;
	padding: 0 1rem;
	flex-direction: column;
`;

export const Body = styled.div`
	padding: 2rem;
`;

export const Row = styled.div`
	display: flex;

	> :first-child {
		margin-right: 1rem;
	}
`;

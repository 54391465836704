import styled from 'styled-components';

import { Colors } from 'environment';

export const OrganiationAvatar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	/* TODO: replace with colors from the styleguide */
	background-color: #eef2f7;

	border-radius: 4rem;

	height: 8rem;
	min-height: 8rem;
	width: 8rem;
	min-width: 8rem;
`;

export const Footer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex: 1;

	width: 100%;
	min-height: 2.4rem;
`;

export const CollaboratorAvatarsContainer = styled.div`
	display: flex;
	align-items: center;

	> div:not(:last-child) {
		margin-right: 0.4rem;
	}
`;

export const MoreAvatarsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	color: ${Colors.white};
	background: ${Colors.text.disabled};
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.6;
`;

import styled from 'styled-components';

import { Shadows, Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0.8rem;
	user-select: none;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
`;

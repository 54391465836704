import {
	ShapiroResultsV2,
	DescriptiveStatistics,
	CompareNumericAnalysisV2,
	CompareNumericResultsDataV2
} from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { VariablesData } from 'store/data/variables';

import { AnalysisGroupedTables } from '../../UI';
import { PStatistic } from '../../AnalysisStatistics';
import { decodeURIComponentSafe } from 'helpers/generic';
import { computeCellValue } from 'helpers/analysis';
import {
	getAggregationLabel,
	mapVariableCategoryValueToLabel
} from 'helpers/variables/variableHelpers';

interface TableRows {
	'nr of rows': React.ReactElement[];
	average: React.ReactElement[];
	std: React.ReactElement[];
	confidentLower: React.ReactElement[];
	confidentUpper: React.ReactElement[];
	sem: React.ReactElement[];
	variance: React.ReactElement[];
	skewness: React.ReactElement[];
	kurtosis: React.ReactElement[];
	median: React.ReactElement[];
	min: React.ReactElement[];
	max: React.ReactElement[];
	range: React.ReactElement[];
	IQRLower: React.ReactElement[];
	IQRUpper: React.ReactElement[];
	missing: React.ReactElement[];
}

interface Props {
	variablesData: VariablesData;
	dataset: CompareNumericResultsDataV2;
	shapiro: {
		visible: boolean;
		data: ShapiroResultsV2;
	};
	analysis: CompareNumericAnalysisV2;
}

export function CompareNumericTable({ analysis, variablesData, dataset, shapiro }: Props) {
	const { translate } = useTranslation();

	const { variablesMap, variableSetsMap } = variablesData;

	const {
		options: { showCategoryLabels },
		input: {
			variables: { categoryVariable }
		}
	} = analysis;

	const LEFT_ROWS = {
		n: translate(({ analysis }) => analysis.generic.n),
		mean: translate(({ analysis }) => analysis.generic.mean),
		sd: translate(({ analysis }) => analysis.generic.sd),
		CI_lower: translate(({ analysis }) => analysis.generic.confidenceLower),
		CI_upper: translate(({ analysis }) => analysis.generic.confidenceUpper),
		sem: translate(({ analysis }) => analysis.generic.sem),
		variance: translate(({ analysis }) => analysis.generic.variance),
		skewness: translate(({ analysis }) => analysis.generic.skewness),
		kurtosis: translate(({ analysis }) => analysis.generic.kurtosis),
		median: translate(({ analysis }) => analysis.generic.median),
		mininum: translate(({ analysis }) => analysis.generic.min),
		maximum: translate(({ analysis }) => analysis.generic.max),
		range: translate(({ analysis }) => analysis.generic.range),
		IQRLower: translate(({ analysis }) => analysis.generic.IQRLower),
		IQRUpper: translate(({ analysis }) => analysis.generic.IQRUpper),
		missing: translate(({ analysis }) => analysis.generic.missing)
	};

	const columns: string[] = [];
	const rows: TableRows = {
		'nr of rows': [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	const categoryVariableColumns: string[] = [];

	const exploreVariableColumns: string[] = [];

	const categoryColSpans: number[] = [];

	Object.entries(dataset).forEach(([variableKey, result], index) => {
		const key = variableKey ?? '';
		const aggregationLabel = getAggregationLabel(key, variablesMap, variableSetsMap);

		const exploreVariableColumnLabel = variablesMap[key]
			? variablesMap[key].label
			: aggregationLabel
			? aggregationLabel
			: '';

		exploreVariableColumns.push(exploreVariableColumnLabel);

		result.forEach(data => {
			const { group1, group2, ...row } = data;

			if (group2 && group1) {
				if (categoryVariableColumns[categoryVariableColumns.length - 1] !== group1.value) {
					// CALCULATE DYNAMIC COLSPAN;
					if (categoryVariableColumns.length) {
						if (categoryVariableColumns.length === 1) {
							categoryColSpans.push(rows['nr of rows'].length);
						} else {
							const colSpanSums = categoryColSpans.reduce(
								(acc, curr) => acc + curr,
								0
							);

							categoryColSpans.push(rows['nr of rows'].length - colSpanSums);
						}
					}

					//group1
					if (showCategoryLabels && categoryVariable) {
						const colLabel = mapVariableCategoryValueToLabel(
							group1.value,
							variablesMap[categoryVariable.name]
						);
						categoryVariableColumns.push(colLabel ?? group1.value);
					} else {
						categoryVariableColumns.push(group1.value);
					}
				}

				addRow(row, group2.value);
			} else {
				if (group1) addRow(row, group1.value);
			}
		});

		if (categoryColSpans.length && index === Object.keys(dataset ?? {}).length - 1) {
			const colSpanSums = categoryColSpans.reduce((acc, curr) => acc + curr, 0);

			categoryColSpans.push(rows['nr of rows'].length - colSpanSums);
		}
	});

	const columnsConfig = {
		colSpan: {
			exploreVariable: columns.length / exploreVariableColumns.length,
			categoryVariable: columns.length / categoryVariableColumns.length
		}
	};

	function addRow(row: DescriptiveStatistics, columnName: string) {
		columnName && columns.push(decodeURIComponentSafe(columnName));

		rows['nr of rows'].push(computeCellValue(row['n'], { noDecimals: true }));
		rows.average.push(computeCellValue(row.mean));
		rows.std.push(computeCellValue(row.sd));
		rows.confidentLower.push(computeCellValue(row.lowerCI));
		rows.confidentUpper.push(computeCellValue(row.upperCI));
		rows.sem.push(computeCellValue(row.sem));
		rows.variance.push(computeCellValue(row.variance));
		rows.skewness.push(computeCellValue(row.skewness));
		rows.kurtosis.push(computeCellValue(row.kurtosis));
		rows.median.push(computeCellValue(row.median));
		rows.min.push(computeCellValue(row.min));
		rows.max.push(computeCellValue(row.max));
		rows.range.push(computeCellValue(row.range));
		rows.IQRLower.push(computeCellValue(row.lowerIQR));
		rows.IQRUpper.push(computeCellValue(row.upperIQR));
		rows.missing.push(computeCellValue(row.missing, { noDecimals: true }));
	}

	const hasExploreVariableColumns = exploreVariableColumns.length > 0;
	const hasCategoryVariableColumns = categoryVariableColumns.length > 0;

	const leftRowHeight =
		((hasExploreVariableColumns ? 1.1 : 0) + (hasCategoryVariableColumns ? 1.05 : 0) + 1) * 4;

	return (
		<AnalysisGroupedTables>
			<Table maxWidth={15} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column height={leftRowHeight}>&nbsp;</Table.Column>
					</Table.Row>
				</Table.Head>

				<Table.Body>
					{Object.values(LEFT_ROWS).map((value, index) => (
						<Table.Row key={`left-table-row-${index}`}>
							<Table.Cell key={`left-table-row-cell-${index}`} bold>
								{value}
							</Table.Cell>
						</Table.Row>
					))}

					{shapiro.visible && shapiro.data.data && (
						<Table.Row>
							<Table.Cell bold>
								{translate(dict => dict.analysis.generic.shapiro)}
							</Table.Cell>
						</Table.Row>
					)}
				</Table.Body>
			</Table>

			<Table.Responsive>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							{hasExploreVariableColumns &&
								exploreVariableColumns.map((column, index) => (
									<Table.Column
										key={`right-table-column-explore-variable-${index}`}
										title={column}
										minWidth={12}
										maxWidth={14}
										colSpan={columnsConfig.colSpan.exploreVariable}
										noWrap
									>
										{column}
									</Table.Column>
								))}
						</Table.Row>

						<Table.Row>
							{categoryVariableColumns.map((column, index) => (
								<Table.Column
									key={`right-table-column-category-variable-${index}`}
									title={column}
									minWidth={12}
									maxWidth={14}
									colSpan={
										categoryColSpans[index] ??
										columnsConfig.colSpan.categoryVariable
									}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>

						<Table.Row>
							{columns.map((column, index) => (
								<Table.Column
									key={`right-table-column-${index}`}
									title={column}
									minWidth={12}
									maxWidth={14}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>
					</Table.Head>

					<Table.Body>
						{Object.keys(rows).map((key, index) => (
							<Table.Row key={`right-table-row-${index}`}>
								{rows[key as keyof TableRows].map((item, i) => (
									<Table.Cell key={`right-table-row-cell-${i}`}>
										{item}
									</Table.Cell>
								))}
							</Table.Row>
						))}

						{shapiro.visible && shapiro.data.data && (
							<Table.Row>
								{shapiro.data.data.map((item, index) => (
									<Table.Cell key={`right-table-row-saphiro-${index}`}>
										<PStatistic
											formatNaN={false}
											value={item.pValue ?? 'N/A'}
										/>
									</Table.Cell>
								))}
							</Table.Row>
						)}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}

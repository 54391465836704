import React, { useEffect, useRef, useState } from 'react';

import { MediaQueries, Svgs } from 'environment';
import { useTranslation } from 'hooks/store';
import { useMediaQuery, useStatic } from 'hooks/utils';

import { supportsTouch } from 'consts';
import { WidgetWidthSize, WidgetHeightSize, HeightSizes } from 'store/data/projectDashboard';

import {
	HorizontalResizeContainer,
	ResizableContainer,
	VerticalResizeContainer,
	ResizeIcon,
	ResizeIconWrapper
} from '../style';
import { Flex } from 'components/UI/Flex';
import { ResizeDirection, SetSizePayload, WidthSizes } from '../../AdminDashboardAutomatic';

interface Props {
	id: string;
	widthSize: WidgetWidthSize;
	heightSize: WidgetHeightSize;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	resizeVertically?: boolean;
	cardIds?: string[];
	cardAutoResize?: string | null;
	children: React.ReactNode;
	groupId?: string;
	position?: {
		rowIndex: number;
		colIndex: number;
	};
	lockExpandRight?: boolean;
	lockExpandFullRight?: boolean;
	lockExpandUnder?: boolean;
	setDimensions: (payload: SetSizePayload) => void;
	setCardAutoResize?: (cardAutoResize: string | null) => void;
}

export function ResizableComponent({
	id,
	cardAutoResize,
	widthSizes,
	heightSizes,
	resizeVertically = false,
	cardIds,
	widthSize,
	heightSize,
	children,
	position,
	lockExpandRight = false,
	lockExpandFullRight = false,
	lockExpandUnder = false,
	setCardAutoResize,
	setDimensions
}: Props) {
	const ref = useRef<HTMLDivElement>(null);
	const { translate } = useTranslation();

	const [resizing, setResizing] = useState<ResizeDirection | null>(null);
	const [initialPos, setInitialPos] = useStatic<number>(0);
	const [initialSize, setInitialSize] = useStatic<number>(0);

	const { minSizeWidth, mediumSizeWidth, maxSizeWidth } = widthSizes;
	const { minSizeHeight, maxSizeHeight } = heightSizes;

	const autoResizing =
		cardIds?.includes(cardAutoResize ?? 'null') &&
		cardAutoResize === id &&
		resizing !== ResizeDirection.vertical;

	useEffect(() => {
		if (autoResizing && ref?.current) {
			ref.current.style.width = 'auto';
		}
	}, [cardAutoResize]);

	function getInitialWidth(e: React.DragEvent<HTMLDivElement>) {
		e.nativeEvent.stopImmediatePropagation();
		if (cardIds && cardIds?.includes(id) && setCardAutoResize)
			setCardAutoResize(cardIds.find(cardId => cardId !== id) ?? null);

		if (position && setCardAutoResize) setCardAutoResize(id);
		setInitialPos(e.clientX);
		if (ref.current) {
			setInitialSize(ref.current?.getBoundingClientRect().width);
		}
	}

	function getInitialHeight(e: React.DragEvent<HTMLDivElement>) {
		setCardAutoResize && setCardAutoResize(id);
		e.nativeEvent.stopImmediatePropagation();
		if (position && setCardAutoResize) setCardAutoResize(id);

		setInitialPos(e.clientY);
		if (ref.current) {
			setInitialSize(ref.current?.getBoundingClientRect().height);
		}
	}

	function resizingComponentWidth(e: React.DragEvent<HTMLDivElement>) {
		setResizing(ResizeDirection.horizontal);
		if (!initialSize || !initialPos) return;

		const width = initialSize() + (e.clientX - initialPos());
		const shouldBlockToFullExpansion = lockExpandFullRight && width > mediumSizeWidth;

		if (shouldBlockToFullExpansion) {
			if (ref.current) {
				return (ref.current.style.width = `${mediumSizeWidth}px`);
			}
		}
		if (!cardIds && lockExpandRight && width > initialSize()) return e.preventDefault();
		if (ref.current) {
			{
				ref.current.style.width = `${width}px`;
			}
		}
	}

	function resizingComponentHeight(e: React.DragEvent<HTMLDivElement>) {
		setResizing(ResizeDirection.vertical);
		if (!initialSize || !initialPos) return;

		const previousHeight = Number(ref?.current?.style.height.split('px')[0]);
		const previousClosestHight = [minSizeHeight, maxSizeHeight].reduce((a, b) => {
			return Math.abs(b - previousHeight) < Math.abs(a - previousHeight) ? b : a;
		});

		const height = initialSize() + (e.clientY - initialPos());
		const shouldBlockExpansion = lockExpandUnder && height > initialSize();
		const shouldBlockFurtherExpansion = height > maxSizeHeight;
		const cursorLeftWindow = !e.clientY;

		if (shouldBlockExpansion) {
			if (ref.current && height > minSizeHeight) {
				ref.current.style.height = `${
					cursorLeftWindow
						? previousClosestHight
						: heightSize === WidgetHeightSize.small
						? minSizeHeight
						: maxSizeHeight
				}px`;
				return;
			}
		}
		if (!shouldBlockFurtherExpansion && ref.current && height > minSizeHeight) {
			{
				ref.current.style.height = `${cursorLeftWindow ? previousClosestHight : height}px`;
			}
		}
	}

	function componentResizedWidth(e: React.DragEvent<HTMLDivElement>) {
		const width = Number(`${initialSize() + (e.clientX - initialPos())}`);
		if (lockExpandRight && width > initialSize()) {
			setCardAutoResize && setCardAutoResize(null);
			setResizing(null);
			return;
		}
		const shouldBlockToFullExpansion = lockExpandFullRight && width > mediumSizeWidth;

		if (ref.current && initialSize() && initialPos()) {
			setDimensions({
				cardId: Number(id),
				width: shouldBlockToFullExpansion ? mediumSizeWidth : width,
				outOfView: !(e.clientX >= 0 && e.clientX <= window.innerWidth - 35),
				// groupId: groupId,
				position
			});
		}
		setCardAutoResize && setCardAutoResize(null);
		setResizing(null);
	}

	function componentResizedHeight(e: React.DragEvent<HTMLDivElement>) {
		const height = Number(`${initialSize() + (e.clientY - initialPos())}`);

		if (lockExpandUnder && height > initialSize()) {
			setCardAutoResize && setCardAutoResize(null);
			setResizing(null);
			return;
		}
		if (ref.current && initialSize() && initialPos()) {
			const width =
				widthSize === WidgetWidthSize.small
					? minSizeWidth
					: widthSize === WidgetWidthSize.medium
					? mediumSizeWidth
					: maxSizeWidth;
			// Cater for resizing out of screen
			const previousClosestHight = Number(ref?.current?.style.height.split('px')[0]);
			const shouldBeSmall =
				e.clientY < 100 && previousClosestHight < heightSizes.minSizeHeight + 5;

			setDimensions({
				cardId: Number(id),
				width,
				height,
				outOfView: !shouldBeSmall
					? !(e.clientY >= 0 && e.clientY <= window.innerHeight - 35)
					: false,
				vertical: true,
				position
			});
		}
		setCardAutoResize && setCardAutoResize(null);
		setResizing(null);
	}

	const elementSnappedWidth =
		widthSize === WidgetWidthSize.small
			? minSizeWidth - 1
			: widthSize === WidgetWidthSize.medium
			? mediumSizeWidth
			: maxSizeWidth;

	const elementSnappedHeight =
		heightSize === WidgetHeightSize.small ? minSizeHeight : maxSizeHeight;

	const constraints = {
		minWidthConstr: position ? minSizeWidth / 10 : 10,
		minHeightConstr: position ? minSizeHeight / 10 : 10,
		maxWidthConstr:
			(lockExpandRight
				? widthSize === WidgetWidthSize.small
					? minSizeWidth
					: mediumSizeWidth
				: maxSizeWidth) / 10,
		maxHeightConstr:
			(lockExpandUnder && heightSize === WidgetHeightSize.small
				? minSizeHeight
				: maxSizeWidth) / 10
	};

	const isMediumScreen = useMediaQuery(MediaQueries.maxWidth.md);
	const fullWidth = useMediaQuery(MediaQueries.maxWidth.lg);

	return (
		<ResizableContainer
			id={id}
			ref={ref}
			width={!isMediumScreen ? elementSnappedWidth : maxSizeWidth}
			height={elementSnappedHeight}
			resizing={!!resizing || autoResizing}
			resizingHeight={resizing === ResizeDirection.vertical}
			resizingWidth={resizing === ResizeDirection.horizontal || autoResizing}
			constraints={constraints}
			fullWidth={fullWidth}
		>
			<Flex fullWidth align={a => a.center}>
				<>{children}</>
				<ResizeIconWrapper horizonal>
					<HorizontalResizeContainer
						supportsTouch={!!supportsTouch}
						draggable={!!lockExpandRight.toString()}
						onDragStart={e => {
							!position && !cardIds && setCardAutoResize && setCardAutoResize(id);
							getInitialWidth(e);
						}}
						onDragCapture={resizingComponentWidth}
						onDragEnd={componentResizedWidth}
					/>
					<Flex style={{ height: '100%' }} align={a => a.center}>
						<ResizeIcon
							svg={Svgs.More}
							visible={resizing === ResizeDirection.horizontal}
							rotate={90}
							title={translate(({ buttons }) => buttons.resizeCard)}
							onClick={() => false}
							propagate={false}
							size={s => s.m}
							variant={v => v.button}
						/>
					</Flex>
				</ResizeIconWrapper>
			</Flex>
			{resizeVertically && (
				<ResizeIconWrapper
					onDragStart={e => {
						!position && !cardIds && setCardAutoResize && setCardAutoResize(id);
						getInitialHeight(e);
					}}
					onDragCapture={resizingComponentHeight}
					onDragEnd={componentResizedHeight}
				>
					<VerticalResizeContainer supportsTouch={!!supportsTouch} draggable="true" />
					<Flex fullWidth justify={j => j.center}>
						<ResizeIcon
							svg={Svgs.More}
							visible={resizing === ResizeDirection.vertical}
							title={translate(({ buttons }) => buttons.resizeCard)}
							size={s => s.m}
							variant={v => v.button}
							vertical
						/>
					</Flex>
				</ResizeIconWrapper>
			)}
		</ResizableContainer>
	);
}

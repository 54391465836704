import { useState } from 'react';
import { Svgs, Colors } from 'environment';
import { FileMimeType } from 'types/index';
import { Title } from './PreviewDocumentHeader.style';
import { selectProjectFileToView } from 'store/data/documents';
import { DeleteDocumentModal } from 'pages/Documents/Modals/DeleteDocumentModal';
import { StoredEntryFile } from 'store/data/entries';
import { ProjectFile } from 'api/data/documents';
import { RenameDocumentModal } from '../../RenameDocumentModal';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { downloadFileFromUrl } from 'helpers/generic';
import { useTranslation } from 'hooks/store';
import { useKeyPress, useSelector } from 'hooks/utils';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';

interface Props {
	usedInEntries?: boolean;
	entryFile?: StoredEntryFile;
	hasDelete?: boolean;
	hasRename?: boolean;
	onClose?: () => void;
	onDelete?: () => void;
}

// suggestion: if possible remove usedInEntries and use callbacks/external logic for the parts that are different
export function PreviewDocumentHeader({
	usedInEntries,
	entryFile,
	hasDelete = true,
	hasRename = true,
	onClose,
	onDelete
}: Props) {
	const { translate } = useTranslation();

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [renameModalVisible, setRenameModalVisible] = useState(false);

	const projectFile = useSelector(state => selectProjectFileToView(state.data.documents));

	const file = entryFile ? entryFile : projectFile;

	useKeyPress({
		onEscapeKeyPress: onClose,
		onDeleteKeyPress: () => setShowDeleteModal(true)
	});

	function printOnNewPage() {
		const newWindow = window.open('');

		const divElements = document.getElementById('print-table')?.innerHTML;

		if (newWindow) {
			newWindow.document.body.innerHTML =
				'<html><head><title>Print</title></head><body>' + divElements + '</body>';
			newWindow.print();
		}
	}

	function handlePrint() {
		if (
			file.extendedMimeType === FileMimeType.PDF ||
			file.extendedMimeType === FileMimeType.XLS ||
			file.extendedMimeType === FileMimeType.XLSX ||
			file.extendedMimeType === FileMimeType.CSV
		) {
			window.open(file.signedS3Url);
		} else printOnNewPage();
	}

	const printDisabled =
		file?.extendedMimeType !== FileMimeType.PDF &&
		file?.extendedMimeType !== FileMimeType.TXT &&
		file?.extendedMimeType !== FileMimeType.XLS &&
		file?.extendedMimeType !== FileMimeType.XLSX &&
		file?.extendedMimeType !== FileMimeType.CSV &&
		file?.extendedMimeType !== FileMimeType.JPG &&
		file?.extendedMimeType !== FileMimeType.PNG;

	return (
		<>
			<Header.Main
				fullWidth
				leftComponent={
					<Flex>
						<Title>
							<Icon
								svg={Svgs.File}
								colors={{ color: Colors.text.disabled }}
								size={s => s.m}
								marginOffset={{ right: 0.8 }}
							/>
							<Typography.H6 title={`${file?.fileLabel}.${file?.mimeType}`} ellipsis>
								{file?.fileLabel}.{file?.mimeType}
							</Typography.H6>
						</Title>
					</Flex>
				}
				rightComponent={
					<Flex>
						<Icon
							svg={Svgs.Print}
							variant={v => v.buttonActive}
							onClick={handlePrint}
							disabled={printDisabled}
							marginOffset={{ right: 1.6 }}
						/>
						<Dropdown
							toggleComponent={({ ref, open, toggle }) => (
								<Icon
									variant={v => v.buttonActive}
									ref={ref}
									svg={Svgs.More}
									onClick={toggle}
									marginOffset={{ right: 1.6 }}
									active={open}
								/>
							)}
							width={20}
							offset={{ top: 25, right: 0 }}
						>
							<Dropdown.Item
								onClick={() => {
									if (file)
										downloadFileFromUrl(file.signedS3Url, file.fileName, true);
								}}
							>
								{translate(dict => dict.buttons.download)}
							</Dropdown.Item>

							{hasRename && (
								<Dropdown.Item
									onClick={() => {
										if (file) setRenameModalVisible(true);
									}}
								>
									{translate(dict => dict.buttons.rename)}
								</Dropdown.Item>
							)}

							{hasDelete && (
								<Dropdown.Item onClick={() => setShowDeleteModal(true)}>
									{translate(dict => dict.buttons.delete)}
								</Dropdown.Item>
							)}
						</Dropdown>
						<Icon svg={Svgs.Close} variant={v => v.buttonActive} onClick={onClose} />
					</Flex>
				}
			/>

			{/* MODALS */}
			{file && (
				<>
					{renameModalVisible && (
						<RenameDocumentModal
							file={file as ProjectFile}
							onClose={() => setRenameModalVisible(false)}
						/>
					)}
					{showDeleteModal && (
						<DeleteDocumentModal
							file={file as ProjectFile}
							usedInEntries={usedInEntries}
							onDelete={onDelete}
							onClose={() => setShowDeleteModal(false)}
						/>
					)}
				</>
			)}
		</>
	);
}

import { useEffect, useMemo } from 'react';
import { isEqual, cloneDeep } from 'lodash';
import { useWatch } from 'react-hook-form';

import { VariableType } from 'types/data/variables/constants';
import { DynamicFormValues } from 'store/data/entries';
import { SetState, BooleanMap } from 'types/index';
import { withCustomSuffix } from 'helpers/entries';
import { getUsedVariablesInForms } from 'helpers/forms';
import { withMemo } from 'helpers/HOCs';
import { getVariableSetVariableNames } from 'helpers/variables';
import { useForms, useFormsBySetName, useVariables, useVariablesData } from 'hooks/store';

interface OpenCustomsMapCheckerProps {
	openCustomsMap: BooleanMap;
	setOpenCustomsMap: SetState<BooleanMap>;
	setName?: string;
}

function OpenCustomsMapCheckerComponent({
	openCustomsMap,
	setOpenCustomsMap,
	setName
}: OpenCustomsMapCheckerProps) {
	const variablesData = useVariablesData({ initial: true });
	const [
		{
			data: { variableNamesOutsideSets }
		}
	] = useVariables({ initial: true, lazy: true });

	const formsBySetName = useFormsBySetName();
	const [{ data: forms }] = useForms({ lazy: true });

	const inputsToWatch = useMemo<string[]>(() => {
		const { variablesMap, groupsMap, variableSetsMap } = variablesData;

		// Get variable names used in active forms
		const scopeForms = setName ? formsBySetName[setName] ?? [] : forms;
		const activeForms = scopeForms.filter(form => form.active);
		const variableNamesInForms = getUsedVariablesInForms(activeForms);
		const hasActiveForms = activeForms.length > 0;

		// Get all variable names used in scope (main level / series)
		const scopeVariableNames = setName
			? getVariableSetVariableNames(variableSetsMap[setName], {
					groupsMap
			  })
			: variableNamesOutsideSets;

		const variableNamesToWatch = hasActiveForms ? variableNamesInForms : scopeVariableNames;

		const inputsToWatch: string[] = [];

		variableNamesToWatch.forEach(variableName => {
			const variable = variablesMap[variableName];

			if (!variable) return;

			const { name, type, fixedCategories } = variable;

			const isCategory =
				type === VariableType.Category || type === VariableType.CategoryMultiple;
			const allowCreate = !fixedCategories;

			if (isCategory && allowCreate) inputsToWatch.push(name, withCustomSuffix(name));
		});

		return inputsToWatch;
	}, [variablesData, variableNamesOutsideSets, forms, formsBySetName, setName]);

	const values = useWatch({ name: inputsToWatch }) as unknown as DynamicFormValues;

	useEffect(() => {
		const newOpenCustomsMap: BooleanMap = cloneDeep(openCustomsMap);

		Object.keys(values).forEach(key => {
			const customValue = values[withCustomSuffix(key)];
			const isCustomValueValid =
				customValue !== undefined ? customValue.toString().trim() !== '' : false;

			if (isCustomValueValid) newOpenCustomsMap[key] = true;
		});

		if (!isEqual(openCustomsMap, newOpenCustomsMap)) setOpenCustomsMap(newOpenCustomsMap);
	}, [values]);

	return null;
}

export const OpenCustomsMapChecker = withMemo(OpenCustomsMapCheckerComponent, ['openCustomsMap']);

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	createCustomerSubscription,
	CreateCustomerSubscriptionAction
} from 'store/account/payments';
import { ActionPayload } from 'store/types';

export function useCreateCustomerSubscription(): Operation<
	(payload: ActionPayload<CreateCustomerSubscriptionAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CREATE_CUSTOMER_SUBSCRIPTION);

	function handler(payload: ActionPayload<CreateCustomerSubscriptionAction>) {
		dispatch(createCustomerSubscription(payload));
	}

	return [{ loading, error }, handler];
}

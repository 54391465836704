import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, uniqueId } from 'lodash';

import { EntryFilter } from 'api/data/filters';
import { VariablesData } from 'store/data/variables';
import { BooleanMap } from 'types/index';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { withMemo } from 'helpers/HOCs';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';
import { useActivities, useDependenciesFilters, useFilters, useTranslation } from 'hooks/store';
import { useOutsideClick } from 'hooks/utils';
import { DependenciesTableView, TableDependency } from 'store/data/dependencies';
import { VariableCategory } from 'api/data/variables';
import { useSeriesFilters } from 'hooks/store/data/useSeriesFilters/useSeriesFilters';
import { ActionTypes } from 'store/data/analyses';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { EMPTY_VALUE } from 'helpers/filters';

function buildSelectedMap(categories: string[], selectedCategories: (string | null)[] = []) {
	const map: BooleanMap = {};

	categories.forEach(category => (map[category] = false));

	if (selectedCategories)
		selectedCategories.forEach(category =>
			category === null ? (map[EMPTY_VALUE] = true) : (map[category] = true)
		);

	return map;
}

interface Props {
	filter: EntryFilter;
	variablesData: VariablesData;
	disabled: boolean;
	isDependencyFilter?: boolean;
	tableDependencies?: TableDependency[];
	isSeriesFilter?: boolean;
	filterContainerRef: React.RefObject<HTMLDivElement>;
	tableFilter?: boolean;
	hideFilter: () => void;
}

function Component({
	isDependencyFilter,
	filter,
	variablesData,
	disabled,
	isSeriesFilter,
	tableDependencies,
	filterContainerRef,
	tableFilter,
	hideFilter
}: Props) {
	const { translate } = useTranslation();

	const [{ loading: fetching }] = useActivities([
		ActionTypes.GET_CHI_SQUARE_STATISTICS,
		ActionTypes.GET_COMPARE_NUMERIC_V1,
		ActionTypes.GET_COMPARE_NUMERIC_V2,
		ActionTypes.GET_COMPARE_PAIRED,
		ActionTypes.GET_CORRELATIONS_V1,
		ActionTypes.GET_CORRELATIONS_V2,
		ActionTypes.GET_CROSSTAB,
		ActionTypes.GET_DENSITY_PLOT,
		ActionTypes.GET_EXPLORE,
		ActionTypes.GET_FREQUENCIES,
		ActionTypes.GET_KAPLAN_MEIER,
		ActionTypes.GET_NUMBER_PLOT_XY,
		ActionTypes.GET_PLOT_NUMERIC_BOXPLOT,
		ActionTypes.GET_PLOT_NUMERIC_COLUMNS,
		ActionTypes.GET_PLOT_NUMERIC_SCATTER,
		ActionTypes.GET_TIME_COURSE_V1,
		ActionTypes.GET_TIME_COURSE_V2
	]);
	const { variablesMap, variableSetsMap } = variablesData;

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const { categories, categoryValues } = useMemo(() => {
		let categories = getVariableCategories(filter.columnName);

		if (isDependencyFilter && tableDependencies) {
			const dependencyCategories = tableDependencies.flatMap(dep => {
				if (
					filter.columnName === DependenciesTableView.supplierVariableName ||
					filter.columnName === DependenciesTableView.dependantVariableName
				) {
					const supplierVariableValue = dep[filter.columnName];
					const variable = variablesMap[supplierVariableValue];
					return [
						{
							id: uniqueId(),
							value: variable.name,
							label: variable.label,
							description: variable.description
						} as VariableCategory
					];
				} else if (filter.columnName === DependenciesTableView.filteredValues) {
					const variableValue = dep[filter.columnName];

					const result = variableValue.map(value => {
						return {
							id: uniqueId(),
							value: value,
							label: value,
							description: ''
						} as VariableCategory;
					});

					return result;
				} else {
					const name = filter.columnName as keyof TableDependency;
					const variableValue = dep[name];

					return [
						{
							id: uniqueId(),
							value: variableValue as string,
							label: variableValue as string,
							description: ''
						} as VariableCategory
					];
				}
			});

			// Filter dependencyCategories from duplicate objects
			const filteredDependencyCategories = dependencyCategories.filter(
				(obj, index, self) => index === self.findIndex(item => item.label === obj.label)
			);

			categories = filteredDependencyCategories;
		}

		const categoryValues = categories.map(c => c.value);
		return { categories, categoryValues };
	}, [filter, variablesMap, variableSetsMap]);

	const [, { updateFilter, deleteDatasetFilter }] = useFilters();
	const [, { updateDependenciesFilter, deleteDependenciesFilter }] = useDependenciesFilters();
	const [, { updateSeriesFilter, deleteSeriesFilter }] = useSeriesFilters();
	const [selected, setSelected] = useState(buildSelectedMap(categoryValues, filter.values));

	// cannot update if (1) result is already applied
	const canUpdateFilter = useMemo(() => {
		const map = buildSelectedMap(categoryValues, filter.values);

		return !isEqual(map, selected);
	}, [categoryValues, filter.values, selected]);

	// Rebuild the map when the available `categoryValues` change
	useEffect(() => {
		const map = buildSelectedMap(categoryValues, filter.values);

		setSelected(map);
	}, [categoryValues]);

	const applyFilter = useCallback(() => {
		const map = buildSelectedMap(categoryValues, filter.values);

		if (isEqual(map, selected)) return;

		const values = getSelectedValues(selected);

		if (isDependencyFilter) {
			updateDependenciesFilter({ dependenciesFilter: { ...filter, values } });
		} else if (isSeriesFilter) {
			updateSeriesFilter({ seriesFilter: { ...filter, values } });
		} else if (!isDependencyFilter || !isSeriesFilter) {
			updateFilter({ filter: { ...filter, values } });
		}
	}, [isDependencyFilter, isSeriesFilter, categoryValues, filter.values, selected]);

	const onCancel = useCallback(() => {
		const map = buildSelectedMap(categoryValues, filter.values);

		setSelected(map);
		hideFilter();
	}, [categoryValues, filter.values]);

	function getVariableCategories(name: string) {
		let variable = variablesMap[name];

		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			variable = variablesMap[aggregatorVariableName];
		}

		if (variable) {
			const categories = [
				...variable.categories,
				{
					id: 'draft_' + EMPTY_VALUE,
					value: EMPTY_VALUE,
					label: 'Empty',
					description: ''
				} as VariableCategory
			];

			return categories;
		}

		return [];
	}

	function onCheck(category: string) {
		setSelected({ ...selected, [category]: !selected[category] });
	}

	function onToggleAll(flag: boolean) {
		setSelected(state =>
			Object.keys(state).reduce((res, key) => {
				return { ...res, [key]: flag };
			}, {} as BooleanMap)
		);
	}

	// Function overloads
	function getSelectedValues(selected: BooleanMap, hasNullValue: false): string[];
	function getSelectedValues(selected: BooleanMap, hasNullValue?: true): (string | null)[];

	function getSelectedValues(selected: BooleanMap, hasNullValue = true) {
		const values = Object.keys(selected).filter(key => selected[key]);

		return values.map(v => (v === EMPTY_VALUE ? (hasNullValue ? null : EMPTY_VALUE) : v));
	}

	function onClear() {
		if (isSeriesFilter) deleteSeriesFilter(filter.itemId);
		if (isDependencyFilter) deleteDependenciesFilter(filter.itemId);
		if (!isDependencyFilter && !isSeriesFilter) deleteDatasetFilter(filter.itemId);
		hideFilter();
	}

	useOutsideClick(() => {
		if (!tableFilter) return;
	}, [filterContainerRef]);

	return (
		<Flex fullWidth column>
			<SelectMultiple
				items={categories.map(c => ({
					label: c.label || c.value,
					value: c.value
				}))}
				onSelect={item => onCheck(item.value)}
				selectedItems={getSelectedValues(selected, false)}
				disabled={disabled || fetching}
				onToggleAll={onToggleAll}
			/>
			<Flex marginOffset={{ top: 1.6 }} fullWidth>
				<Flex align={align => align.center}>
					<Button
						onClick={onClear}
						size="small"
						title="Clear"
						variant={variant => variant.link}
					/>
				</Flex>
				<Flex fullWidth justify={j => j.end}>
					<Button
						size="small"
						onClick={onCancel}
						title={translate(dict => dict.buttons.cancel)}
						variant={variant => variant.secondary}
						marginOffset={{ right: 1.6 }}
					/>
					<Button
						size="small"
						onClick={applyFilter}
						disabled={!canUpdateFilter}
						title={translate(dict => dict.buttons.apply)}
						variant={variant => variant.primary}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
}

export const FilterCategories = withMemo(Component);

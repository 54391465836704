import { useEffect, useState } from 'react';

import { ApiUserPendingInvitation } from 'api/account/subscription';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Flex } from 'components/UI/Flex';
import {
	useTranslation,
	useSubscription,
	useUsername,
	useAPIRespondToSubscriptionInvitation
} from 'hooks/store';
import { usePrevious } from 'hooks/utils';

interface Props {
	pendingInvitations: ApiUserPendingInvitation[];
	onRespond(isSubscriptionAccepted?: boolean): void;
	onClose: () => void;
}

export function PendingInvitationsModal({ pendingInvitations, onRespond, onClose }: Props) {
	const { translate } = useTranslation();

	const [currentPendingInvitation, setCurrentPendingInvitation] = useState(
		pendingInvitations.length ? pendingInvitations[0] : null
	);

	const [cancelPending, setCancelPending] = useState(false);

	const [
		{
			data: { details },
			loading: subscriptionLoading
		},
		getUserSubscription
	] = useSubscription();

	const currentUserId = useUsername();

	const [
		{ loading: respondToInvitationLoading, error: respondToInvitationError },
		respondToInvitation
	] = useAPIRespondToSubscriptionInvitation();

	function onRespondToInvitation(isSubscriptionAccepted: boolean) {
		if (currentPendingInvitation) {
			const { userid } = currentPendingInvitation;
			setCancelPending(!isSubscriptionAccepted);
			if (userid)
				respondToInvitation({
					isSubscriptionAccepted,
					userId: userid
				});
			onRespond(isSubscriptionAccepted);
		}
	}

	/**
	 * After the api call to respond to current invitation in over and there are no errors, move to next invitation
	 */
	const wasRespondingToSubscriptionInvitation = usePrevious(respondToInvitationLoading);
	useEffect(() => {
		if (
			wasRespondingToSubscriptionInvitation &&
			!respondToInvitationLoading &&
			!respondToInvitationError
		)
			moveToNextPendingInvitation();
	}, [respondToInvitationLoading, respondToInvitationError]);

	/**
	 * Verifies if there is any other pending invitation in the array of pendingInvitations.
	 * In case the current pending invitation is the last one in the list,
	 * sets current pending invitation to null and calls getUserSubscription to update subscription details
	 */
	function moveToNextPendingInvitation() {
		if (currentPendingInvitation) {
			const currentIndex = pendingInvitations.indexOf(currentPendingInvitation);
			if (currentIndex < pendingInvitations.length - 1) {
				setCurrentPendingInvitation(pendingInvitations[currentIndex + 1]);
			} else {
				getUserSubscription();
			}
		}
	}

	/**
	 * After subscription was fetched close the modal
	 */
	const wasSubscriptionLoading = usePrevious(subscriptionLoading);
	useEffect(() => {
		if (wasSubscriptionLoading && !subscriptionLoading) {
			setCurrentPendingInvitation(null);
			onClose();
		}
	}, [subscriptionLoading]);

	/**
	   If the current invitation is not the first one, that means user had accepted/canceled some invitations
	   so before closing the modal, a call to getSubscription is being done.
	 */
	function closeModal() {
		if (pendingInvitations.length && currentPendingInvitation !== pendingInvitations[0]) {
			getUserSubscription();
		} else {
			onClose();
		}
	}

	return (
		<Modal
			visible={!!currentPendingInvitation}
			title={translate(dict => dict.accountUM.pendingInvitation.modalTitle)}
			primary={{
				label: translate(dict => dict.buttons.accept),
				loading: (respondToInvitationLoading || subscriptionLoading) && !cancelPending,
				onClick: () => onRespondToInvitation(true)
			}}
			neutral={{
				label: translate(dict => dict.buttons.decline),
				onClick: () => onRespondToInvitation(false),
				loading: (respondToInvitationLoading || subscriptionLoading) && cancelPending
			}}
			onClose={closeModal}
			closeOnBackdrop={false}
			close
		>
			{currentPendingInvitation && (
				<>
					<Flex justify={j => j.center}>
						<Avatar
							userId={
								currentPendingInvitation.ownerFirstName +
								currentPendingInvitation.ownerSirName
							}
							data={{
								userFirstName: currentPendingInvitation.ownerFirstName,
								userSirName: currentPendingInvitation.ownerSirName,
								imageURL: currentPendingInvitation.ownerImageUrl
							}}
							size={s => s.l}
						/>
					</Flex>
					<Typography.Paragraph marginOffset={{ top: 1 }} alignCenter>
						{currentPendingInvitation.ownerFirstName}&nbsp;
						{currentPendingInvitation.ownerSirName}
					</Typography.Paragraph>
					<Spacer size={s => s.l} />
					<Typography.H3 alignCenter>
						{currentPendingInvitation.userid === currentUserId
							? translate(
									dict =>
										dict.accountUM.pendingInvitation.userPendingInvitationTitle,
									false,
									{
										subscriptionLabel:
											currentPendingInvitation.ownerSubscriptionLabel
									}
							  )
							: translate(
									dict =>
										dict.accountUM.pendingInvitation.ownerPendingInvitationTitle
							  )}
					</Typography.H3>
					<Spacer size={s => s.m} />
					<Typography.Paragraph multiline alignCenter>
						{currentPendingInvitation.userid === currentUserId
							? details && details.ownerId !== currentUserId
								? translate(
										dict =>
											dict.accountUM.pendingInvitation
												.userPendingInvitationMessage,
										false,
										{
											currentOwner: `${details.ownerFirstName} ${details.ownerLastName}`,
											newOwner: `${currentPendingInvitation.ownerFirstName} ${currentPendingInvitation.ownerSirName}`
										}
								  )
								: translate(
										dict =>
											dict.accountUM.pendingInvitation
												.userWithNoOwnerPendingInvitationMessage,
										false,
										{
											newOwner: `${currentPendingInvitation.ownerFirstName} ${currentPendingInvitation.ownerSirName}`
										}
								  )
							: translate(
									dict =>
										dict.accountUM.pendingInvitation
											.ownerPendingInvitationMessage,
									false,
									{
										user: `${currentPendingInvitation.userFirstName} ${currentPendingInvitation.userSirName}`,
										newOwner: `${currentPendingInvitation.ownerFirstName} ${currentPendingInvitation.ownerSirName}`
									}
							  )}
					</Typography.Paragraph>
				</>
			)}
		</Modal>
	);
}

import { ElementMenu, ElementMenuControls, DeleteIcon, HoverableArea } from 'components/Forms';
import { Svgs } from 'environment';
import { FormElement as FormElementInterface } from 'store/data/forms';
import { ElementType } from 'types/index';
import { FormInput } from '../FormInput';
import { SeparatorBorder } from './FormElement.style';
import { useRemoveFormElement } from 'hooks/store';

interface Props {
	element: FormElementInterface;
	disabled?: boolean;
	usedInFormDesigner?: boolean;
}

export function FormElement({ element, disabled = false, usedInFormDesigner = false }: Props) {
	const { elementId, elementType } = element;

	const removeFormElement = useRemoveFormElement();

	const isSeparator = elementType === ElementType.Separator;
	const isSubtitle = elementType === ElementType.Subtitle;

	return (
		<HoverableArea fullWidth usedInFormDesigner={usedInFormDesigner}>
			<ElementMenu usedInFormDesigner={usedInFormDesigner} isSeparator={isSeparator}>
				{isSeparator ? (
					<SeparatorBorder />
				) : (
					<FormInput
						element={element}
						disabled={disabled}
						usedInFormDesigner={usedInFormDesigner}
					/>
				)}
			</ElementMenu>
			{usedInFormDesigner && (
				<ElementMenuControls isSubtitle={isSubtitle}>
					<DeleteIcon
						svg={Svgs.Delete}
						disabled={disabled}
						size={s => s.m}
						onClick={() => removeFormElement({ elementId })}
					/>
				</ElementMenuControls>
			)}
		</HoverableArea>
	);
}

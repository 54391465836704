import { useEffect } from 'react';
import { throttle } from 'lodash';
import { nanoid as generate } from 'nanoid';

import { useMemoOnce, useEffectOnce, useRender } from 'hooks/utils';

import { EventType, StorageKeys } from 'types/index';
import { isAuthSessionExpired, useAutoLogoutOnTabClose } from 'helpers/autoLogout';
import { debuggerLog } from 'helpers/generic';
import { useForceLogout, useLogin } from 'hooks/store';

import { ssoClientsConfigs } from 'ssoConfig/consts';

// enum LifecycleOptions {
// 	Active = 'active',
// 	Passive = 'passive',
// 	Hidden = 'hidden',
// 	Frozen = 'frozen',
// 	Terminated = 'terminated',
// 	Discarded = 'discarded'
// }

// interface LifecycleEvent {
// 	oldState: LifecycleOptions;
// 	newState: LifecycleOptions;
// }

interface Props {
	children: React.ReactElement;
}

export function PageLifecycle({ children }: Props) {
	const [{ loading: signOutStarted }, forceLogout] = useForceLogout();
	const isFederatedUser = localStorage.getItem(StorageKeys.FederatedUILogin) === 'true';

	const ssoProvider = localStorage.getItem('SSOProvider');

	const ssoInactivityAutoLogout = ssoProvider
		? ssoClientsConfigs.inactivityTrack[
				ssoProvider.toLocaleLowerCase() as keyof typeof ssoClientsConfigs.inactivityTrack
		  ]
		: false;

	useAutoLogoutOnTabClose();

	const [render, triggerRender] = useRender();

	const [{ data: isLoggedIn }] = useLogin();

	const uniqueAppInstanceId = useMemoOnce(() => generate());

	// SET TO `true` TO SEE THE LOGS
	const DEBUGGER = false;
	const log = debuggerLog(DEBUGGER);

	const tokenExpiryMinutes = process.env.REACT_APP_TOKEN_EXPIRY_MINUTES;
	const enableAutoLogout = tokenExpiryMinutes !== undefined;
	// users logged in through hosted UI will not be logged out automatically

	// useEffectOnce(() => {
	// 	(window as any).lifecycle.addEventListener('statechange', handleTabState);

	// 	return () => (window as any).lifecycle.removeEventListener('statechange', handleTabState);
	// });

	// CLEANUP TAB ID FROM THE ACTIVE TABS LIST
	useEffectOnce(() => {
		window.addEventListener('storage', handleSyncCountdown);
		window.addEventListener('pagehide', handleSyncTabs);

		return () => {
			window.removeEventListener('storage', handleSyncCountdown);
			window.removeEventListener('pagehide', handleSyncTabs);
		};
	});

	function handleSyncCountdown({ key }: StorageEvent) {
		if (key === StorageKeys.UniqueTabIDs) {
			triggerRender();
		}
	}

	function handleSyncTabs() {
		const ledidiTabIds = localStorage.getItem(StorageKeys.UniqueTabIDs);

		if (ledidiTabIds) {
			const parsed: string[] = JSON.parse(ledidiTabIds);

			// REMOVE OLD ID ON TAB CLOSE OR REFRESH
			const filtered = parsed.filter(id => id !== uniqueAppInstanceId);

			const uniqueTabIds = [...new Set(filtered)];

			localStorage.setItem(StorageKeys.UniqueTabIDs, JSON.stringify(uniqueTabIds));
		}
	}

	// KEEP TRACK OF ACTIVE TABS INSIDE LOCAL STORAGE AS AN ARRAY OF UNIQUE IDS
	useEffect(() => {
		const ledidiTabIds = localStorage.getItem(StorageKeys.UniqueTabIDs);

		// NEW TAB - APPEND TO ARRAY
		if (ledidiTabIds) {
			const parsed: string[] = JSON.parse(ledidiTabIds);

			const uniqueTabIds = [...new Set([...parsed, uniqueAppInstanceId])];

			localStorage.setItem(StorageKeys.UniqueTabIDs, JSON.stringify(uniqueTabIds));
		}
		// INIT ARRAY
		else {
			const stringified = JSON.stringify([uniqueAppInstanceId]);

			localStorage.setItem(StorageKeys.UniqueTabIDs, stringified);
		}
	}, [uniqueAppInstanceId, isLoggedIn]);

	useEffect(() => {
		if ((isFederatedUser && !ssoInactivityAutoLogout) || !enableAutoLogout || !isLoggedIn)
			return;

		// START TRACKING USER ACTIVITY
		addActivityListeners();

		// REMOVE COUNTDOWN TIMER + TRACKING OF USER ACTIVITY
		return () => removeActivityListeners();
	}, [isLoggedIn]);

	// ACTIVE COUNTDOWN LOGIC
	useEffect(() => {
		if (
			(isFederatedUser && !ssoInactivityAutoLogout) ||
			!enableAutoLogout ||
			!shouldStartTracking()
		)
			return;

		if (!isLoggedIn) return removeTracking();
		// START COUNTDOWN
		startTracking();

		// COUNTDOWN TIMER
		const countdown = setInterval(async () => {
			// SIGN OUT ALREADY STARTED - DO NOTHING
			if (signOutStarted) return;

			const inactivityTimeStamp = localStorage.getItem(StorageKeys.InactivityTimestamp);

			if (inactivityTimeStamp !== null) {
				const t0 = Number(inactivityTimeStamp);
				const t1 = new Date().getTime();
				const duration = t1 - t0;
				const timeOutDuration = Number(tokenExpiryMinutes) * 60 * 1000;
				const isSessionExpired = await isAuthSessionExpired();

				// more than inactivity timeout => force logout
				if (duration > timeOutDuration || isSessionExpired) {
					if (isSessionExpired) {
						log('force logout - AWS session expired');
					} else {
						log('force logout - inactivity timeout');
					}

					// WHEN COMING BACK FROM SLEEP - THE NETWORK HAS TO RECONNECT
					if (window.navigator.onLine) forceLogout();
				}
			}
		}, 1000);

		// REMOVE COUNTDOWN TIMER + TRACKING OF USER ACTIVITY
		return () => {
			clearInterval(countdown);
		};
	}, [isLoggedIn, render]);

	// LIST OF EVENTS WHICH RESET THE COUNTDOWN
	const userActivityEventList: EventType[] = [
		EventType.MouseDown,
		EventType.Scroll,
		EventType.Wheel,
		EventType.KeyUp,
		EventType.MouseMove
	];

	const startCountdownThrottled = throttle(startTracking, 1000, {
		leading: true,
		trailing: false
	});

	function addActivityListeners() {
		userActivityEventList.forEach(eventKey =>
			document.addEventListener(eventKey, startCountdownThrottled)
		);
	}

	function removeActivityListeners() {
		userActivityEventList.forEach(eventKey =>
			document.removeEventListener(eventKey, startCountdownThrottled)
		);
	}

	function updateInactivityTimestamp() {
		localStorage.setItem(StorageKeys.InactivityTimestamp, new Date().getTime().toString());
	}

	function startTracking() {
		updateInactivityTimestamp();
	}

	function removeTracking() {
		log('removes countdown');

		removeActivityListeners();
		localStorage.removeItem(StorageKeys.InactivityTimestamp);
	}

	// ALLOW THE COUNTDOWN ONLY TO WORK FOR A SINGLE TAB
	function shouldStartTracking() {
		const ledidiTabIds = localStorage.getItem(StorageKeys.UniqueTabIDs);

		if (ledidiTabIds) {
			const parsed: string[] = JSON.parse(ledidiTabIds);

			const firstTabId = parsed[0];

			if (firstTabId === uniqueAppInstanceId) return true;
		}

		return false;
	}

	// function parseTabStates(tabStates: LifecycleEvent) {
	// 	const { oldState, newState } = tabStates;

	// 	const initialState = {
	// 		[LifecycleOptions.Active]: false,
	// 		[LifecycleOptions.Passive]: false,
	// 		[LifecycleOptions.Hidden]: false,
	// 		[LifecycleOptions.Frozen]: false,
	// 		[LifecycleOptions.Terminated]: false,
	// 		[LifecycleOptions.Discarded]: false
	// 	};

	// 	const states = {
	// 		was: { ...initialState, [oldState]: true },
	// 		is: { ...initialState, [newState]: true }
	// 	};

	// 	return states;
	// }

	// function handleTabState(tabStates: LifecycleEvent) {
	// 	const { oldState, newState } = tabStates;

	// 	const { was, is } = parseTabStates(tabStates);

	// 	console.log({ oldState, newState, was, is });

	// 	if (is.frozen || was.frozen) forceLogout();

	// 	const shouldReload = was.frozen && (is.passive || is.active || is.hidden);

	// 	if (shouldReload) {
	// 		// when app is resumed and it was frozen before just reload the page to ensure a safe state!
	// 		console.log('Need to reload, for previous frozen state');
	// 		window.location.reload();
	// 	}
	// }

	return children;
}

import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';
import {
	ResetRoleTemplateShareListFetchedAction,
	resetRoleTemplateShareListFetchedAction
} from 'store/data/roles';
import { ActionPayload } from 'store/types';

export function useResetRoleTemplateShareListFetched(): LocalOperation<
	(payload: ActionPayload<ResetRoleTemplateShareListFetchedAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ResetRoleTemplateShareListFetchedAction>) {
		dispatch(resetRoleTemplateShareListFetchedAction(payload));
	}

	return handler;
}

import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { Colors, Svgs } from 'environment';
import { Dependency, DependencyType } from 'store/data/dependencies';
import { FlexRow } from 'components/Dependencies/styles';
import { DependantFiltering } from './DependantFiltering';
import { DependencyCondition } from './DependencyCondition';
import { DoubleVLine, HLine, VLine } from '../DependencyRule.style';
import {
	ConfigureCategory,
	Container,
	DependantVariable,
	SupplierVariable
} from './DirectDependency.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { useTranslation, useDependenciesContext } from 'hooks/store';

interface Props {
	dependency: Dependency;
	supplierVariable: Variable;
	dependantVariablesSelectItems: { label: string; value: string }[];
}

export function DirectDependency({
	dependency,
	supplierVariable,
	dependantVariablesSelectItems
}: Props) {
	const { translate } = useTranslation();
	const {
		actions: { setDependantSupplierValueCondition, changeDependantVariable, deleteDependant }
	} = useDependenciesContext();

	const supplierVariableType = supplierVariable.type;
	const isDateTime = supplierVariableType === VariableType.DateTime;

	const isFilteringCondition = dependency.dependencyType === DependencyType.Filtering;

	return (
		<Flex column fullWidth>
			{dependency.dependantVariables.map(dependant => (
				<Container
					key={`dependency_${dependency.dependencyName}-dependant_${dependant.dependantId}`}
				>
					<FlexRow
						level={0}
						css={`
							.delete-dependant-icon {
								visibility: hidden;
								min-width: unset;
							}

							:hover .delete-dependant-icon {
								visibility: visible;
							}
						`}
					>
						<HLine />
						<VLine />

						{/* IS CATEGORY / CATEGORY MULTIPLE TYPE */}
						{[VariableType.Category, VariableType.CategoryMultiple].includes(
							supplierVariableType
						) ? (
							<SupplierVariable>
								{/* SUPPLIER VALUE CONDITION */}
								<CreatableSelect
									className="creatable-select-small"
									placeholder={translate(
										dict => dict.dependencies.directDependency.selectCategory
									)}
									value={
										dependant.supplierValueCondition !== ''
											? {
													label: dependant.supplierValueCondition,
													value: dependant.supplierValueCondition
											  }
											: null
									}
									items={supplierVariable.categories.map(category => ({
										label: category.label,
										value: category.value
									}))}
									onValueSelected={value => {
										if (value) {
											setDependantSupplierValueCondition({
												dependencyName: dependency.dependencyName,
												dependantId: dependant.dependantId,
												supplierValueCondition: value
											});
										}
									}}
									canClear={false}
								/>
							</SupplierVariable>
						) : (
							<DependencyCondition
								dependency={dependency}
								dependant={dependant}
								supplierVariable={supplierVariable}
							/>
						)}

						<Icon
							className="delete-dependant-icon"
							title={translate(
								dict => dict.dependencies.directDependency.deleteDependant
							)}
							svg={Svgs.Delete}
							marginOffset={{
								left: 0.8
							}}
							colors={{
								color: Colors.text.disabled,
								hover: Colors.primary.normal
							}}
							style={{
								alignItems: !isDateTime ? 'center' : 'flex-start'
							}}
							onClick={() =>
								deleteDependant({
									dependencyName: dependency.dependencyName,
									dependantId: dependant.dependantId
								})
							}
						/>
					</FlexRow>

					<FlexRow
						level={1}
						css={`
							flex-direction: column;
						`}
					>
						<HLine />
						{isDateTime ? <DoubleVLine /> : <VLine />}

						<DependantVariable>
							{/* DEPENDANT VARIABLE */}
							<CreatableSelect
								className="creatable-select-small"
								placeholder={translate(
									dict => dict.dependencies.directDependency.selectVariable
								)}
								value={dependantVariablesSelectItems.find(
									item => item.value === dependant.dependantVariableName
								)}
								items={dependantVariablesSelectItems}
								onValueSelected={value => {
									if (value) {
										changeDependantVariable({
											dependencyName: dependency.dependencyName,
											dependantId: dependant.dependantId,
											dependantVariableName: value
										});
									}
								}}
								canClear={false}
							/>
						</DependantVariable>

						{/* DEPENDANT FILTERED VALUES - ONLY VISIBLE IN FILTERING CONDITION */}
						{isFilteringCondition && dependant.dependantVariableName && (
							<ConfigureCategory>
								<HLine />
								<VLine />

								<DependantFiltering dependency={dependency} dependant={dependant} />
							</ConfigureCategory>
						)}
					</FlexRow>
				</Container>
			))}
		</Flex>
	);
}

import { apiFactory } from 'api';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/account/payments';
import { useStatic } from 'hooks/utils';

export function useAPIDownloadInvoice(
	onSuccess?: ((data: { downloadURL: string }) => void) | undefined
): ApiOperation<{ downloadURL: string }, (paymentId: string) => void> {
	const [getRequestData, setRequestData] = useStatic<string>('');

	const [{ data, error, loading, fetched }, downloadInvoice, resetAddUser] = useAPI<{
		downloadURL: string;
	}>({
		promiseFn: () => promiseFn(getRequestData()),
		lazy: true,
		initialData: { downloadURL: '' },
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		onSuccess,
		activityType: ActionTypes.DOWNLOAD_INVOICE
	});

	function promiseFn(paymentId: string) {
		return apiFactory().account.payments().downloadInvoice(paymentId);
	}

	function handler(paymentId: string) {
		downloadInvoice(() => promiseFn(paymentId));
		setRequestData(paymentId);
	}

	return [{ data, error, loading, fetched }, handler, resetAddUser];
}

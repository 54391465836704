import { merge, cloneDeep, isEmpty } from 'lodash';
import 'zingchart/es6';
import zingchart from 'zingchart/es6';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';
import 'zingchart/modules-es6/zingchart-boxplot.min.js';
import 'zingchart/modules-es6/zingchart-sunburst.min.js';

import { DEFAULT_CHART_OPTIONS, DEFAULT_EXPORT_CHART_OPTIONS } from 'consts';
import { ZingChartTypes } from 'types/charts';

zingchart.LICENSE = [
	'241728bd2a51703502456f14cb8dd822',
	'd8dc16a5876c97686d2052fafd82da13',
	'6873e167bff5b335e56d935398675a96',
	'cd0057f95637b26b9f8c2c11be734333' // app-preprod.ledidi.com
];

enum ZingChartAnimationEffect {
	FadeIn = 1,
	ExpandVertical = 2,
	ExpandTop = 3,
	ExpandBottom = 4,
	ExpandLeft = 5,
	ExpandRight = 6,
	ExpandHorizontal = 7,
	SlideLeft = 8,
	SlideRight = 9,
	SlideTop = 10,
	SlideBottom = 11,
	UnfoldHorizontal = 12,
	UnfoldVertical = 13
}

enum ZingChartAnimationMethod {
	Linear = 0,
	BackEaseOut = 1,
	ElasticEaseOut = 2,
	BounceEaseOut = 3,
	StrongEaseOut = 4,
	RegularEaseOut = 5
}

enum ZingChartAnimationSequence {
	ByPlot = 1,
	ByNode = 2,
	ByPlotAndNode = 3
}

enum ZingChartAnimationSpeed {
	Slower = 1000,
	Slow = 750,
	Normal = 500,
	Fast = 250,
	Faster = 100
}

interface AnimationProps {
	delay?: number;
	effect?: (value: typeof ZingChartAnimationEffect) => number;
	method?: (value: typeof ZingChartAnimationMethod) => number;
	sequence?: (value: typeof ZingChartAnimationSequence) => number;
	speed?: (value: typeof ZingChartAnimationSpeed) => number;
}

interface Props extends AnimationProps {
	id?: string;
	chartRef?: React.RefObject<ZingChart>;
	series: {};
	options?: {};
	width?: string | number;
	height?: string | number;
	isForExport?: boolean;
	disableAnimations?: boolean;
	type: (value: typeof ZingChartTypes) => string;
	onLegendItemClick?: (e: ZingChartClickEvent) => void;
	noLegendOnExport?: boolean;
}

export function Chart({
	id,
	chartRef,
	type,
	series,
	options,
	width,
	height,
	isForExport = false,
	disableAnimations,
	effect,
	method,
	sequence,
	speed,
	delay,
	onLegendItemClick,
	noLegendOnExport
}: Props) {
	const animationConfig: {
		effect?: number;
		method?: number;
		sequence?: number;
		speed?: number;
		delay?: number;
	} = {};

	if (effect) animationConfig['effect'] = effect(ZingChartAnimationEffect);
	if (method) animationConfig['method'] = method(ZingChartAnimationMethod);
	if (sequence) animationConfig['sequence'] = sequence(ZingChartAnimationSequence);
	if (speed) animationConfig['speed'] = speed(ZingChartAnimationSpeed);
	if (delay) animationConfig['delay'] = delay;

	const animation = !isEmpty(animationConfig) ? animationConfig : undefined;

	const exportOptions = DEFAULT_EXPORT_CHART_OPTIONS;

	let computedOptions = options;
	if (isForExport) {
		if (type(ZingChartTypes) !== ZingChartTypes.Pie) {
			// @ts-ignore
			delete exportOptions.plot.valueBox;
		}

		if (type(ZingChartTypes) === ZingChartTypes.Mixed && !!noLegendOnExport) {
			exportOptions.legend.visible = false;
		} else {
			exportOptions.legend.visible = true;
		}

		computedOptions = merge(options, exportOptions);
	}

	const dynamicConfigOptions = {
		type: type(ZingChartTypes),
		plot: {
			animation
		},
		series
	};

	if (disableAnimations) delete dynamicConfigOptions.plot.animation;

	const config = merge(
		cloneDeep(DEFAULT_CHART_OPTIONS),
		merge(dynamicConfigOptions, computedOptions)
	);

	function handleLegendItemClick(e: ZingChartClickEvent) {
		onLegendItemClick?.(e);
	}

	return (
		<ZingChart
			ref={chartRef}
			legend_item_click={handleLegendItemClick}
			legend_marker_click={handleLegendItemClick}
			id={id}
			width={isForExport ? 1920 : width}
			height={isForExport ? 1080 : height}
			data={config}
		/>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, EnterpriseRole, updateEnterpriseRole } from 'store/account/enterprise';

export function useUpdateEnterpriseRole(): Operation<(role: EnterpriseRole) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_ENTERPRISE_ROLE);

	function handler(role: EnterpriseRole) {
		dispatch(updateEnterpriseRole(role));
	}

	return [{ loading, error }, handler];
}

import type { JsonLogic, JsonLogicMap } from 'api/data/variables/types';
import { generateCalculationId } from 'helpers/variables/variableHelpers';

// ====================================================================================================
// ======================================== VARIABLE MODAL HELPERS ====================================
// ====================================================================================================

export function initializeJsonLogicMapping(cases: JsonLogic[]): JsonLogicMap {
	const jsonLogicMapping: JsonLogicMap = {
		order: [],
		logics: {}
	};

	for (const logic of cases) {
		const id = generateCalculationId();

		jsonLogicMapping.order.push(id);

		jsonLogicMapping.logics[id] = logic;
	}

	return jsonLogicMapping;
}

import type { SnapshotDefinition, SnapshotResults } from 'api/data/snapshots';
import type { GenericMap } from 'types/index';
import type { Action } from 'types/store/types';

export type SnapshotsById = GenericMap<SnapshotDefinition>;

export type SnapshotsByProjectId = GenericMap<{
	active: string | null;
	ids: string[];
}>;

export interface State {
	projectId: string | null;
	byId: SnapshotsById;
	byProjectId: SnapshotsByProjectId;
}

export enum ActionTypes {
	GET_SNAPSHOTS = 'data/snapshots/GET_SNAPSHOTS',
	GET_SNAPSHOT = 'data/snapshots/GET_SNAPSHOT',
	CREATE_SNAPSHOT = 'data/snapshots/CREATE_SNAPSHOT',
	UPDATE_SNAPSHOT = 'data/snapshots/UPDATE_SNAPSHOT',
	DELETE_SNAPSHOT = 'data/snapshots/DELETE_SNAPSHOT',
	SET_ACTIVE_SNAPSHOT = 'data/snapshots/SET_ACTIVE_SNAPSHOT',
	CLEAR_SNAPSHOT = 'data/snapshots/CLEAR_SNAPSHOT'
}

export type GetSnapshotsAction = Action<
	ActionTypes.GET_SNAPSHOTS,
	{
		projectId: string;
		snapshots: SnapshotDefinition[];
	}
>;

export type GetSnapshotAction = Action<
	ActionTypes.GET_SNAPSHOT,
	{
		projectId: string;
		snapshotId: string;
		data: SnapshotResults;
	}
>;

export type CreateSnapshotAction = Action<
	ActionTypes.CREATE_SNAPSHOT,
	{
		projectId: string;
		snapshotId: string;
		name: string;
	}
>;

export type UpdateSnapshotAction = Action<
	ActionTypes.UPDATE_SNAPSHOT,
	{
		projectId: string;
		snapshotId: string;
	}
>;

export type DeleteSnapshotAction = Action<
	ActionTypes.DELETE_SNAPSHOT,
	{
		projectId: string;
		snapshotId: string;
	}
>;

export type SetActiveSnapshotAction = Action<
	ActionTypes.SET_ACTIVE_SNAPSHOT,
	{
		snapshotId: string;
	}
>;

export type ClearSnapshotAction = Action<ActionTypes.CLEAR_SNAPSHOT>;

export type Actions =
	| GetSnapshotsAction
	| GetSnapshotAction
	| CreateSnapshotAction
	| UpdateSnapshotAction
	| DeleteSnapshotAction
	| SetActiveSnapshotAction
	| ClearSnapshotAction;

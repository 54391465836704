import { isPageScrollable } from 'helpers/generic';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import { DataTestId } from 'tests/consts';

interface Props {
	children: React.ReactNode;
	local?: boolean;
	isModal?: boolean;
	modalSize?: number;
	onClick: () => void;
}

export function Backdrop({ children, local, isModal, modalSize, onClick }: Props) {
	function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		if (e && e.target === e.currentTarget) onClick();
	}

	if (local) {
		return (
			<Container
				modalSize={modalSize}
				isModal={isModal}
				onMouseDown={handleClick}
				data-testid={DataTestId.Backdrop}
			>
				<GlobalStyle isPageScrollable={!!isPageScrollable()}></GlobalStyle>
				{children}
			</Container>
		);
	}

	return ReactDOM.createPortal(
		<Container modalSize={modalSize} isModal={isModal} onMouseDown={handleClick}>
			<GlobalStyle isPageScrollable={!!isPageScrollable()}></GlobalStyle>
			{children}
		</Container>,
		document.body
	);
}

interface GlobalStyleProps {
	isPageScrollable: boolean;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
${props =>
	props.isPageScrollable &&
	css`
		body {
			overflow: hidden;
		}
	`}
`;

interface ContainerProps {
	isModal?: boolean;
	modalSize?: number;
}

const Container = styled.div<ContainerProps>`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: flex-end;

	${({ isModal, modalSize }) =>
		isModal &&
		modalSize &&
		css`
			justify-content: center;
			align-items: center;

			@media (max-width: ${modalSize * 10 + 100}px) {
				align-items: flex-end;
			}
		`};

	height: 100%;
	width: 100%;
	max-width: 100vw;
	cursor: default;
	background-color: rgba(37, 37, 58, 0.5);
	z-index: 1007;

	@keyframes enterAnimationBackdrop {
		from {
			background-color: rgba(37, 37, 58, 0.25);
		}
		to {
			background-color: rgba(37, 37, 58, 0.5);
		}
	}

	animation: enterAnimationBackdrop 0.15s;
`;

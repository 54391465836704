import { hasMatches } from 'helpers/strings';
import { cloneDeep } from 'lodash';
import { EnterpriseRole } from 'store/account/enterprise';

export function filterAdminRolesBySearchTerm(roles: EnterpriseRole[], searchTerm: string) {
	const isSearchTermValid = searchTerm.trim().length > 0;

	if (!isSearchTermValid) return roles;

	function match(role: EnterpriseRole) {
		const keywords: string[] = [role.roleName, role.roleDescription];

		return hasMatches({ searchTerm, keywords });
	}

	return cloneDeep(roles).filter(match);
}

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, selectForm, selectAreFormsFetched, getForm, Form } from 'store/data/forms';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';

interface UseFormOptions {
	initial?: boolean;
	lazy?: boolean;
}

export function useForm(options?: UseFormOptions): OperationResult<Form | undefined> {
	const dispatch = useDispatch();

	const { initial = false, lazy = false } = options ?? {};

	const isProjectValid = useIsProjectValid();

	const fetched = useSelector(state => selectAreFormsFetched(state.data.forms));
	const data = useSelector(state => selectForm(state.data.forms, { initial }));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_FORM);

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getForm());
	}

	return [{ loading, error, data, fetched }, handler];
}

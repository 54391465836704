import { EntryFilter, Operator, ProjectFilter } from 'api/data/filters';
import { ProjectFilterType } from 'types/data/projects/constants';
import { VariableType } from 'types/data/variables/constants';

export const EMPTY_VALUE = 'empty';

export function getDefaultOperator(type: VariableType) {
	const isText = type === VariableType.String;
	const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(type);

	if (isCategory) return Operator.In;

	if (isText) return Operator.Equals;

	return Operator.LessThan;
}

export function getProjectDefaultOperator(type: ProjectFilterType) {
	const isText = type === ProjectFilterType.String;

	if (isText) return Operator.In;

	return Operator.Equals;
}

export function getInitialValues(filter: EntryFilter | ProjectFilter) {
	let from = '';
	let to = '';

	// FROM
	if (filter.value !== undefined) {
		if (filter.value === null) {
			from = EMPTY_VALUE;
		} else {
			from = filter.value.toString();
		}
	} else if (filter.from !== undefined) {
		from = filter.from.toString();
	}

	// TO
	if (filter.to !== undefined) {
		to = filter.to.toString();
	}

	return { from, to };
}

export function parseFilterNumber(
	value: string,
	type: VariableType | ProjectFilterType = VariableType.Integer
) {
	if (type === VariableType.Float) {
		return parseFloat(value);
	}

	return parseInt(value, 10);
}

function splitText(text: string) {
	return text
		.toLowerCase()
		.split(/[\s,]+/)
		.map(value => value.trim())
		.filter(value => !!value);
}

export function matchTextFilter(filterValue: string | number, cellValue: string) {
	const parsedFilterValue = filterValue.toString();
	const parsedValue = cellValue.toString();

	let matches = false;

	const terms = splitText(parsedFilterValue);
	const keywords = splitText(parsedValue);

	terms.forEach(term => {
		let termFound = false;

		keywords.forEach(keyword => {
			if (keyword.indexOf(term) !== -1) termFound = true;
		});

		if (termFound) matches = true;
	});

	return matches;
}

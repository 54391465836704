import { createSelector } from 'reselect';

import { Variable, JsonLogic } from 'api/data/variables';
import { selectParams } from 'store/utils';

import {
	State,
	VariablesViewOptions,
	GridVariableTypes,
	ItemOptions,
	Sort,
	ProjectVariablesData
} from './types';
import { buildVariablesDataFromStoreData, initVariablesData } from 'helpers/variables';

/*
 * EXTRACTOR FUNCTIONS
 */

function getVariablesByProjectId({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

type SelectVariablesDataParams = { initial: boolean };

/**
 * Make it a factory in order to remove the cahce size of `1`
 *
 * This enforces the usage to be inside a `useMemo` with [] dependency array
 */
export const selectVariablesData = () =>
	createSelector(
		[
			getVariablesByProjectId,
			// PARAMS
			(_: State, params: SelectVariablesDataParams) => selectParams.encode(params)
		],
		(variablesByProjectId, params) => {
			const { initial } = selectParams.decode<SelectVariablesDataParams>(params);

			if (variablesByProjectId) {
				const { initial: initialVariables, current: currentVariables } =
					variablesByProjectId;

				const storeVariablesData = initial ? initialVariables : currentVariables;

				return buildVariablesDataFromStoreData(storeVariablesData);
			}

			return initVariablesData();
		}
	);

type SelectVariableByNameParams = {
	variableName: string | null;
	initial: boolean;
};

export const selectVariableByName = createSelector(
	[
		getVariablesByProjectId,
		// PARAMS
		(_: State, params: SelectVariableByNameParams) => selectParams.encode(params)
	],
	(variablesByProjectId, params) => {
		if (variablesByProjectId) {
			const { variableName, initial } =
				selectParams.decode<SelectVariableByNameParams>(params);

			const { initial: initialVariables, current: currentVariables } = variablesByProjectId;

			const { variables } = initial ? initialVariables : currentVariables;

			if (variableName !== null && variableName in variables.byName) {
				return variables.byName[variableName];
			}
		}

		return null;
	}
);

type SelectGroupByNameParams = {
	groupName: string | null;
	initial: boolean;
};

export const selectGroupByName = createSelector(
	[
		getVariablesByProjectId,
		// PARAMS
		(_: State, params: SelectGroupByNameParams) => selectParams.encode(params)
	],
	(variablesByProjectId, params) => {
		if (variablesByProjectId) {
			const { groupName, initial } = selectParams.decode<SelectGroupByNameParams>(params);

			const { initial: initialVariables, current: currentVariables } = variablesByProjectId;

			const { groups } = initial ? initialVariables : currentVariables;

			if (groupName !== null && groupName in groups.byName) {
				return groups.byName[groupName];
			}
		}

		return null;
	}
);

type SelectVariablesByNameParams = {
	variableNames: string[];
	initial: boolean;
};

export const selectVariablesByName = createSelector(
	[
		getVariablesByProjectId,
		// PARAMS
		(_: State, params: SelectVariablesByNameParams) => selectParams.encode(params)
	],
	(variablesByProjectId: ProjectVariablesData | undefined, params: string) => {
		if (variablesByProjectId) {
			const { variableNames, initial } =
				selectParams.decode<SelectVariablesByNameParams>(params);

			const { initial: initialVariables, current: currentVariables } = variablesByProjectId;

			const { variables } = initial ? initialVariables : currentVariables;

			const output: Variable[] = [];

			variableNames.forEach(variableName => {
				if (variableName in variables.byName) {
					const variable = variables.byName[variableName];

					output.push(variable);
				}
			});

			return output;
		}

		return [];
	}
);

function isVariableInCalculationCase(currentCase: JsonLogic, variableName: string): boolean {
	function traverseNodes(node: any): boolean {
		// Check if node is VariableNode and variableName matches
		if ('var' in node && node.var[0] === variableName) {
			return true;
		}

		// If node is an object (JsonLogic), recurse into each property
		if (typeof node === 'object' && node !== null) {
			for (const key in node) {
				if (Array.isArray(node[key])) {
					for (const child of node[key]) {
						if (typeof child === 'object' && child !== null) {
							if (traverseNodes(child)) {
								return true;
							}
						}
					}
				}
			}
		}

		// If we reach here, the variableName was not found
		return false;
	}
	return traverseNodes(currentCase);
}

type SelectIsVariableInCalculationCasesParams = { variableName: string };

export const selectIsVariableInCalculationCases = createSelector(
	[
		getVariablesByProjectId,
		// PARAMS
		(_: State, params: SelectIsVariableInCalculationCasesParams) => selectParams.encode(params)
	],
	(variablesByProjectId, params) => {
		const calculatedVariableLabels: string[] = [];

		if (variablesByProjectId) {
			const { variableName } =
				selectParams.decode<SelectIsVariableInCalculationCasesParams>(params);

			const { byName } = variablesByProjectId.current.variables;

			for (const variable of Object.values(byName)) {
				variable.cases.forEach(jsonLogic => {
					const existsInCase = isVariableInCalculationCase(jsonLogic, variableName);

					if (existsInCase && !calculatedVariableLabels.includes(variable.label)) {
						calculatedVariableLabels.push(variable.label);
					}
				});
			}
		}

		return calculatedVariableLabels;
	}
);

export const selectVariablesFilters = createSelector(
	[getVariablesByProjectId],
	variablesByProjectId => {
		if (variablesByProjectId) return variablesByProjectId.metadata.filters;

		return {
			show: ItemOptions.ALL,
			type: GridVariableTypes.ALL,
			sort: Sort.DEFAULT,
			errored: false
		};
	}
);

export const selectVariablesViewOption = createSelector(
	[getVariablesByProjectId],
	variablesByProjectId => {
		if (variablesByProjectId) return variablesByProjectId.metadata.viewOption;

		return VariablesViewOptions.GRID;
	}
);

export const selectAreVariablesFetched = createSelector(
	[getVariablesByProjectId],
	variablesByProjectId => {
		if (variablesByProjectId) return variablesByProjectId.fetched;

		return false;
	}
);

import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MessageContainer = styled.div`
	display: flex;
	gap: 1.2rem;

	${getTypographyCSS(t => t.Paragraph)};

	background-color: ${Colors.background.error};
	color: ${Colors.text.errorHover};

	border-radius: 4px;
	padding: 0.9rem 1.2rem;
`;

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { unshareWithInstance, UnshareWithInstanceAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useUnshareTemplateWithInstance(): LocalOperation<
	(payload: ActionPayload<UnshareWithInstanceAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<UnshareWithInstanceAction>) {
		dispatch(unshareWithInstance(payload));
	}

	return handler;
}

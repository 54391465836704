import styled from 'styled-components';

import { MediaQueries } from 'environment';
import { Columns } from 'api/data/analyses';

interface ContainerProps {
	filtersOpen?: boolean;
}

interface ListProps {
	activeColumn: number;
	heightOffset: number;
	filtersOpen?: boolean;
}

interface ColumnsProps {
	activeColumn: number;
	heightOffset: number;
	filtersOpen?: boolean;
}
export const Container = styled.div<ContainerProps>`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	overflow: visible;
	@media ${MediaQueries.maxWidth.lg} {
		padding: 0;
	}

	@media ${MediaQueries.maxWidth.sm} {
		display: ${props => (props.filtersOpen ? 'none' : 'flex')};
	}
`;

export const List = styled.div<ListProps>`
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	width: 100%;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: row;
	}
`;

export const AnalysisColumnContainer = styled.div<ColumnsProps>`
	width: ${props =>
		props.activeColumn === Columns.OneColumn
			? '100%'
			: props.activeColumn === Columns.TwoColumns
			? 'calc(50% - 2rem)'
			: 'calc(33.3% - 2rem)'};

	&:first-of-type {
		height: ${props =>
			props.activeColumn === Columns.OneColumn
				? `calc((100vh - ${props.heightOffset}rem ) - 1rem)`
				: 'calc(50vh - 6rem)'};
	}

	height: ${props =>
		props.activeColumn === Columns.OneColumn
			? props.filtersOpen
				? `calc(100vh - ${props.heightOffset}rem)`
				: 'calc(100vh - 8rem)'
			: props.activeColumn === Columns.TwoColumns ||
			  props.activeColumn === Columns.ThreeColumns
			? 'calc(50vh  - 6rem)'
			: `calc(100vh - ${props.heightOffset}rem)`};

	margin: 0 1rem 2rem;

	@media ${MediaQueries.maxWidth.lg} {
		margin: ${props =>
			props.activeColumn === Columns.OneColumn ? '0 0 2rem 0' : '0 1rem 2rem'};
	}

	@media ${MediaQueries.maxWidth.sm} {
		padding-top: 2rem;
		margin-bottom: 0;
		height: 50vh;
		&:first-of-type {
			height: 50vh;
		}
	}
`;

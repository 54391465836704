import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 1.2rem;

	> :nth-child(1) {
		width: 34rem;
	}

	> :nth-child(2) {
		width: 18rem;
	}
`;

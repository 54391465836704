import { useVariablesData } from './useVariablesData';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	getAggregationRuleTypeLabel
} from 'helpers/variables';
import { AggregationRule, AggregationRuleType } from 'api/data/variables';
import { useTranslation } from 'hooks/store';

export function useVariableLabel(name: string) {
	const { translate } = useTranslation();
	const { variableSetsMap, variablesMap } = useVariablesData();

	const aggregationToVariableNameMap =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	function buildAggregatedVariableLabel(aggregationRule: AggregationRule) {
		return `${variablesMap[aggregationRule.aggregator.variableName].label} (${translate(
			dict =>
				dict.variablesPage.aggregationRuleType[
					getAggregationRuleTypeLabel(aggregationRule) as AggregationRuleType
				]
		)})`;
	}

	if (variablesMap[name]) {
		return variablesMap[name].label;
	}

	return buildAggregatedVariableLabel(aggregationToVariableNameMap[name]);
}

// NOTE: 0.001 shows as 0.0010 instead of 0.001

const DEFAULT_LOCALE = 'EN-us';

export function parseAnalysisNumber(
	value: number | string,
	options: {
		decimals?: number;
		formatAsPValue?: boolean;
	} = {}
) {
	options.decimals = options.decimals ?? 2;
	options.formatAsPValue = options.formatAsPValue ?? false;

	let skipFormatting = false;
	let operator = '=';
	let parsedValue = '';
	let tenExponent = 0;

	const stringValue = value.toString();
	const isNegative = Number(value) < 0;
	let numberValue: number | undefined;
	// adding Number.EPSILON solves issue for numbers that only have zero decimals like `1.000` which are parsed as 1;
	if (!isNegative) {
		numberValue = Number(value) + Math.pow(10, Math.log10(Number(value))) * Number.EPSILON;
	} else {
		numberValue = Number(value) - Math.pow(10, Math.log10(Number(-value))) * Number.EPSILON;
	}

	// PRJCTS-6996: Skip formatting if stringValue has only zeros (eg: 1.00, 1.0)
	const onlyZerosRegExp = /^\d+\.0{1,2}$/;
	if (onlyZerosRegExp.test(stringValue)) {
		skipFormatting = true;
	}

	if (options.formatAsPValue) {
		if (numberValue < 0.001) {
			operator = '<';
			parsedValue = '0.001';
			parsedValue = parsedValue.substring(1);
		} else {
			parsedValue = numberValue.toLocaleString(DEFAULT_LOCALE, {
				maximumFractionDigits: 3
			});

			// use substring only for values beginning with 0
			if (numberValue < 1 && Number(parsedValue) < 1) {
				parsedValue = parsedValue.substring(1);
			}
		}
		if (isNaN(numberValue)) {
			parsedValue = 'N/A';
		}
	}

	if (stringValue.includes('e')) {
		const ePosition = stringValue.indexOf('e');
		value = stringValue.substring(0, ePosition);
		tenExponent = Math.abs(Number(stringValue.substring(ePosition + 1)));
	} else {
		value = stringValue;
	}

	if (!options.formatAsPValue) {
		if (numberValue >= 100000 || numberValue <= -100000) {
			tenExponent = !tenExponent ? getTenExponent(value, options.decimals) : tenExponent;
			parsedValue = (numberValue / Math.pow(10, tenExponent)).toLocaleString(DEFAULT_LOCALE, {
				useGrouping: false,
				maximumFractionDigits: options.decimals,
				minimumFractionDigits: options.decimals
			});
		} else if (
			(numberValue >= 1 && numberValue < 100000) ||
			(numberValue <= -1 && numberValue > -100000)
		) {
			if (skipFormatting) {
				return {
					operator,
					tenExponent,
					value: stringValue
				};
			}
			parsedValue = numberValue.toLocaleString(DEFAULT_LOCALE, {
				minimumFractionDigits: numberValue % 1 == 0 ? 0 : options.decimals,
				maximumFractionDigits: options.decimals
			});
		} else if (
			(numberValue > 0.0001 && numberValue < 1) ||
			(numberValue < -0.0001 && numberValue > -1)
		) {
			parsedValue = numberValue.toLocaleString(DEFAULT_LOCALE, {
				maximumSignificantDigits: options.decimals,
				minimumSignificantDigits: options.decimals,
				useGrouping: false
			});
		} else if (
			(numberValue > 0 && numberValue < 0.0001) ||
			(numberValue < 0 && numberValue > -0.0001)
		) {
			tenExponent = !tenExponent
				? getTenExponent(
						numberValue.toLocaleString(DEFAULT_LOCALE, {
							maximumSignificantDigits: options.decimals + 1,
							minimumSignificantDigits: options.decimals + 1
						}),
						options.decimals + 1
				  ) * -1
				: tenExponent * -1;
			parsedValue = (numberValue * Math.pow(10, tenExponent * -1)).toLocaleString(
				DEFAULT_LOCALE,
				{
					minimumSignificantDigits: options.decimals + 1,
					maximumSignificantDigits: options.decimals + 1
				}
			);
		} else if (isNaN(numberValue)) {
			parsedValue = 'N/A';
		} else {
			parsedValue = '0';
		}
	}

	parsedValue = parsedValue.split(',').join(' ');

	return {
		operator,
		tenExponent,
		value: parsedValue
	};
}

export function getTenExponent(number: string, decimals = 0) {
	const num = Number(number);

	let res = 0;

	if ((num > 0 && num < 1) || (num > -1 && num < 0)) {
		res += number.split('.')[1].length - decimals + 1;
	} else {
		res += number.split('.')[0].length - 1;
	}

	if ((num <= -1 && number.includes('.')) || num <= -100000) res -= 1;

	return res;
}

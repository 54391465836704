import { Collection, ProjectsById, ProjectFilters, ProjectSort } from 'store/data/projects';

export function filterAndSortProjects(
	byId: ProjectsById,
	filters: ProjectFilters,
	collection: Collection
) {
	const { status, term, sort, ownership } = filters;
	const { all, byStatus, byOwnership } = collection;

	let ids = [...all];

	if (status) {
		if (!byStatus[status]) return [];

		ids = [...byStatus[status]];
	}

	if (!byOwnership[ownership]) {
		return [];
	} else {
		ids = ids.filter(id => byOwnership[ownership].includes(id));
	}

	if (ids.length === 0) {
		return [];
	}

	ids = ids.filter(
		id => byId[id] && byId[id].projectName.toLowerCase().includes(term.toLowerCase())
	);

	ids.sort((x, y) => {
		if (sort === ProjectSort.byName) {
			return byId[x].projectName
				.toLowerCase()
				.localeCompare(byId[y].projectName.toLowerCase());
		}
		if (sort === ProjectSort.byDate) {
			const xDate = new Date(byId[x].lastModifiedDate);
			const yDate = new Date(byId[y].lastModifiedDate);

			return yDate.getTime() - xDate.getTime();
		}

		return Number(byId[x].givenProjectNumber) - Number(byId[y].givenProjectNumber);
	});

	return ids;
}

import { format } from 'date-fns';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import {
	ElementMenu,
	HoverableArea,
	InputWrapper,
	ToolTipWrapper,
	VariableHeader
} from 'components/Forms';
import { dateTimeFormatMap, DATE_FORMAT } from 'consts';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import { DynamicFormValue, DynamicFormValues } from 'store/data/entries';
import { InputType } from 'types/index';
import { FormInput } from '../FormInput';
import { FormElementGeneralProps } from './FormVariable';
import { Input } from 'components/UI/Inputs/Input';
import {
	useForm,
	useFormDesignerDrawerContext,
	useRemoveFormElement,
	useTranslation
} from 'hooks/store';
import { useSelector } from 'hooks/utils';
import { FormVariableSettings } from './FormVariableSettings';
import { useCallback } from 'react';

type FormVariableCalculatedProps = FormElementGeneralProps & {
	labelHint?: string;
};

export function FormVariableCalculated({
	element,
	variable,
	value,
	formContext,
	tooltipComponent,
	disabled,
	canBeRemoved,
	isInGroup,
	usedInFormDesigner,
	labelHint
}: FormVariableCalculatedProps) {
	const { name } = variable;
	const { elementId, label } = element;

	const removeFormElement = useRemoveFormElement();

	const { onOpen, elementId: selectedElementId } = useFormDesignerDrawerContext();
	const [{ data: form }] = useForm();

	const onOpenDrawer = useCallback(() => {
		onOpen(elementId);
	}, [element]);

	const onRemoveElement = useCallback(() => {
		removeFormElement({ elementId });
	}, [elementId]);

	const isSelected = form?.elements[selectedElementId]?.variableRef === variable.name;

	return (
		<HoverableArea
			activeHover={isSelected}
			isInGroup={isInGroup}
			usedInFormDesigner={usedInFormDesigner}
		>
			<ElementMenu usedInFormDesigner={usedInFormDesigner}>
				<VariableHeader>
					<FormInput
						element={element}
						disabled={disabled}
						usedInFormDesigner={usedInFormDesigner}
						labelHint={labelHint}
					/>
					{tooltipComponent && <ToolTipWrapper>{tooltipComponent}</ToolTipWrapper>}
				</VariableHeader>
				<InputWrapper usedInFormDesigner={usedInFormDesigner}>
					<CalculatedInput
						name={name}
						label={label}
						variable={variable}
						value={value}
						formContext={formContext}
					/>
				</InputWrapper>
			</ElementMenu>
			{usedInFormDesigner && (
				<FormVariableSettings
					variableName={variable.name}
					onEdit={onOpenDrawer}
					{...(canBeRemoved ? { onDelete: onRemoveElement } : {})}
				/>
			)}
		</HoverableArea>
	);
}

interface CalculatedInputProps {
	name: string;
	label?: string;
	variable: Variable;
	value?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
}

function CalculatedInput({
	name,
	label,
	variable,
	value: _value,
	formContext
}: CalculatedInputProps) {
	const { translate } = useTranslation();

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);

	function parseCalculatedValue(value: string) {
		let parsedValue = value;

		if (value) {
			// `date`
			if (variable.type === VariableType.Date) {
				parsedValue = format(new Date(value), DATE_FORMAT);
			}

			// `datetime`
			if (variable.type === VariableType.DateTime) {
				parsedValue = format(new Date(value), dateTimeFormatMap[dateTimeFormat]);
			}
		}

		return parsedValue;
	}

	if (_value) {
		return (
			<Input
				data-test-id={label}
				type={InputType.Textarea}
				value={parseCalculatedValue(_value as string)}
				placeholder={translate(dict => dict.variableFields.calculated)}
				readOnly
			/>
		);
	}

	const CalculatedComponent = (
		<Input
			data-test-id={label}
			type={InputType.Textarea}
			placeholder={translate(dict => dict.variableFields.calculated)}
			readOnly
		/>
	);

	if (!formContext) return CalculatedComponent;

	return (
		<Controller
			name={name}
			defaultValue={''}
			render={({ field: { value } }) => (
				<Input
					data-test-id={label}
					type={InputType.Textarea}
					value={parseCalculatedValue(value)}
					placeholder={translate(dict => dict.variableFields.calculated)}
					readOnly
				/>
			)}
		/>
	);
}

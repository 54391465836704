// exports

export enum WidgetWidthSize {
	small = 'small',
	medium = 'medium',
	big = 'big'
}
export enum WidgetHeightSize {
	small = 'small',
	big = 'big'
}
export type SetSizePayload = {
	elementId: string;
	width: number;
	height?: number;
	outOfView?: boolean;
	vertical?: boolean;
	groupId?: string;
	position?: CardPositionManual;
};
export interface WidthSizes {
	minSizeWidth: number;
	mediumSizeWidth: number;
	maxSizeWidth: number;
}
export interface HeightSizes {
	minSizeHeight: number;
	maxSizeHeight: number;
}
export interface CardDraggableResizableProps extends CardDraggableItem {
	cardName: string;
	widthSize: WidgetWidthSize;
	heightSize: WidgetHeightSize;
	group?: {
		index: number;
		groupId: string;
	};
	position?: {
		rowNumber: number;
		colIndex: number;
		rowIndex: number;
	};
}
export interface CardDraggableItem {
	index: number;
	cardId: string;
}
export interface GroupedCardsDraggableResizableProps {
	groupId: string;
	elements: CardDraggableResizableProps[];
}
export interface GroupedThreeCardsDraggableResizableProps {
	groupId: string;
	threeCards: boolean;
	elements: Array<CardDraggableResizableProps[]>;
}
export interface RowsCompletenessProps {
	[index: number]: {
		elements: PossibleLayouts;
		maxHeightRow: boolean;
	};
}
export type PossibleLayouts = Array<
	| CardDraggableResizableProps
	| GroupedCardsDraggableResizableProps
	| GroupedThreeCardsDraggableResizableProps
	| string
>;
export type ManualLayoutElement = string | CardDraggableResizableProps | null;
export type ManualLayoutRows = {
	[index: string]: {
		elements: ManualLayoutElement[][];
		maxHeightRow: boolean;
		fullHeightRows: number;
	};
};
export interface CoordinatePayload {
	dragged: {
		index: number;
		group?: {
			groupId: string;
			index: number;
			rowIndex?: number;
			colIndex?: number;
		};
	};
	dropped: {
		index: number;
		group?: {
			groupId: string;
			index: number;
			rowIndex?: number;
			colIndex?: number;
		};
		droppedInMain?: boolean;
	};
}
export interface IndexPayload {
	dragged: {
		rowNumber: number;
		rowIndex: number;
		colIndex: number;
	};
	dropped: {
		rowNumber: number;
		rowIndex: number;
		colIndex: number;
	};
}
export interface CardPositionManual {
	rowNumber: number;
	rowIndex: number;
	colIndex: number;
}
export enum GridModes {
	manual = 'manual',
	automatic = 'automatic'
}
export enum ResizeDirection {
	vertical = 'vertical',
	horizontal = 'horizontal'
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setHelpSearchTerm } from 'store/data/help';

export function useHelpSearchTerm(): LocalOperationResult<string, (term: string) => void> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.help.searchTerm);

	function handler(term: string) {
		dispatch(setHelpSearchTerm({ term }));
	}

	return [data, handler];
}

import styled from 'styled-components';

export const Table = styled.div`
	padding: 1rem;

	.table {
		display: inline-block;
		border-spacing: 0;
		font-size: 1.2rem;

		.tr {
			:last-child {
				.td {
					border-bottom: 0;
				}
			}
		}

		.th {
			font-weight: bold;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.th,
		.td {
			margin: 0;
			padding: 0.5rem;
		}
	}
`;

export const TableWrapper = styled.div`
	overflow: auto;
`;

import { CorrelationsAnalysis } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { CorrelationsPearson } from './CorrelationsPearson';
import { CorrelationsSpearman } from './CorrelationsSpearman';
import { CorrelationsLinearRegressionTable } from './CorrelationsLinearRegressionTable';

interface Props {
	analysis: CorrelationsAnalysis;
}

export function CorrelationsStatistics({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		pearson: {
			visible: inputStatistics.pearsonV2,
			results: outputStatistics.pearsonV2
		},
		spearman: {
			visible: inputStatistics.spearmanV2,
			results: outputStatistics.spearmanV2
		},
		linearRegression: {
			visible: inputStatistics.linearRegressionV2,
			results: outputStatistics.linearRegressionV2
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.pearson.visible && (
				<CorrelationsPearson results={statistics.pearson.results} />
			)}

			{statistics.spearman.visible && (
				<CorrelationsSpearman results={statistics.spearman.results} />
			)}

			{statistics.linearRegression.visible && (
				<CorrelationsLinearRegressionTable results={statistics.linearRegression.results} />
			)}
		</Gap>
	);
}

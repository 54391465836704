import { Card } from 'components/UI/Card';
import { useTranslation } from 'hooks/store';
import { EnterpriseRole } from 'store/account/enterprise';

interface Props {
	role: EnterpriseRole;
	actions: {
		onEdit: (role: EnterpriseRole) => void;
		onDelete: (role: EnterpriseRole) => void;
	};
}

export function EnterpriseRoleCard({ role, actions }: Props) {
	const { translate } = useTranslation();

	function getMenuItems() {
		const items = [];

		items.push({
			label: translate(dict => dict.roles.modals.roleModal.title.update),
			action: () => actions.onEdit(role)
		});
		items.push({
			label: translate(dict => dict.buttons.delete),
			action: () => actions.onDelete(role)
		});

		return items;
	}

	return (
		<Card onClick={() => actions.onEdit(role)}>
			<Card.Header title={role.roleName} menuItems={getMenuItems()} data={role} />
			<Card.Body>{role.roleDescription}</Card.Body>
		</Card>
	);
}

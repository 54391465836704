import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, getSurveyData } from 'store/data/entries';

export function useSurveyData(): Operation<() => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.GET_SURVEY_DATA);

	function handler() {
		dispatch(getSurveyData());
	}

	return [{ loading, error }, handler];
}

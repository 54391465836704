import { useEffect, useState, useMemo } from 'react';
import {
	AddCreditCardModal,
	AddFirstCard,
	ChangePlanModal,
	CreditCard,
	PaymentSecuredCardsInfo
} from 'components/Account';
import {
	SupportedLedidiPlans,
	SupportedLedidiPlansPrices,
	WindowDOMwithChargebee
} from 'store/account/payments';
import { BillingPeriodUnit, CreditCardInterface, PaymentMethod } from 'store/account/payments';

import { CardsTitleSection } from './AccountUMPaymentPage.style';
import { Header } from 'components/Header';
import { Grid } from 'components/UI/Grid';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useBillingInfo,
	useSubscription,
	useCBInstance,
	useRemoveSubscriptionPaymentMethod,
	useMakePaymentSourcePrimary,
	useSubscriptionPaymentMethods,
	useAccount,
	useSubscriptionRules,
	useSelectedPaymentMethod,
	useUpdateCustomerSubscription
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';
import { useMatchEnterpriseAdmin, useNavigation } from 'hooks/navigation';
import { AddCreditCardWarningModal } from 'components/Account/Payment/PaymentMethodsWarnings/AddCreditCardWarningModal';
import { RemoveCreditCardWarningModal } from 'components/Account/Payment/PaymentMethodsWarnings/RemoveCreditCardWarningModal';
import UpdatingSubscriptionText from 'components/Account/Common/UpdatingSubscriptionText/UpdatingSubscriptionText';
import PaymentMethods from 'components/Account/Payment/PaymentMethods/PaymentMethods';
import { CardGridContainer } from 'components/Account/Common/styles';

export function AccountUMPaymentPage() {
	const chargebeeSite = process.env.REACT_APP_CHARGEBEE_DATA_CB_SITE;
	const chargebeeKey = process.env.REACT_APP_CHARGEBEE_DATA_PUBLISH_API_KEY;
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const { navigate, routes } = useNavigation();

	const [creditCardForEdit, setCreditCardForEdit] = useState<CreditCardInterface | null>(null);
	const [creditCardModalVisible, setCreditCardModalVisible] = useState(false);
	const [addCreditCardWarningVisible, setAddCreditCardWarningVisible] = useState(false);
	const [cardToDelete, setCardToDelete] = useState<string | null>(null);
	const [shouldProcessCard, setShouldProcessCard] = useState(false);
	const [changePlanModalVisible, setChangePlanModalVisible] = useState(false);

	const [{ data: billingInfo }, getBillingInfo] = useBillingInfo();

	const [
		{
			data: { details: subscriptionDetails },
			loading: subscriptionLoading,
			fetched: subscriptionFetched
		},
		getSubscription
	] = useSubscription();
	const { canChangePlan, isSubscriptionUpdating, showEnterpriseAdminRoute } =
		useSubscriptionRules();
	const [
		{ data: isChargebeeLoaded, loading: checkingChargebeeInstanceLoaded },
		setChargebeeIsLoaded
	] = useCBInstance();
	const [
		{
			loading: removingSubscriptionPaymentMethod,
			error: errorRemovingSubscriptionPaymentMethod
		},
		removeSubscriptionPaymentMethod
	] = useRemoveSubscriptionPaymentMethod();
	const [
		{ loading: makingPaymentSourcePrimary, error: errorMakingPaymentSourcePrimary },
		makePaymentSourcePrimary
	] = useMakePaymentSourcePrimary();

	const [{ data: paymentMethods, fetched: paymentMethodsFetched }, fetchPaymentMethods] =
		useSubscriptionPaymentMethods();
	const {
		data: selectedPaymentMethod,
		fetched: selectedPaymentMethodFetched,
		loading: selectedPaymentMethodLoading
	} = useSelectedPaymentMethod();
	const [{ loading: accountLoading, fetched: accountFetched }] = useAccount();

	const isOnEnterpriseAdminPage = useMatchEnterpriseAdmin();

	const [
		{ loading: customerSubscriptionLoading, error: customerSubscriptionError },
		updateCustomerSubscription
	] = useUpdateCustomerSubscription();

	/**
	 * Updates the subscription and sets the
	 * selected payment method to INVOICE and the
	 * payment period unit to YEARLY
	 */
	function updateSubscriptionToInvoice() {
		const currencyCode = billingInfo?.currencyCode;
		const subscriptionName = subscriptionDetails?.subscriptionName;
		const quantity = subscriptionDetails?.licenceQuantity;
		if (currencyCode && subscriptionName && quantity) {
			const newPriceId =
				SupportedLedidiPlansPrices[currencyCode][subscriptionName as SupportedLedidiPlans][
					BillingPeriodUnit.Year
				].priceId;
			updateCustomerSubscription({
				customerSubscription: {
					priceId: newPriceId,
					selectedPaymentMethod: PaymentMethod.INVOICE,
					quantity: quantity
				}
			});
		}
	}

	useEffect(() => {
		if (showEnterpriseAdminRoute && !isOnEnterpriseAdminPage) {
			navigate(routes.enterpriseAdmin.subscription);
		}
	}, [showEnterpriseAdminRoute, isOnEnterpriseAdminPage]);

	useEffect(() => {
		if (!isChargebeeLoaded && !checkingChargebeeInstanceLoaded) {
			(window as WindowDOMwithChargebee).Chargebee?.init({
				site: chargebeeSite, // test site
				publishableKey: chargebeeKey
			});
			setChargebeeIsLoaded();
		}
	}, []);

	useCompletedAction(
		removingSubscriptionPaymentMethod,
		errorRemovingSubscriptionPaymentMethod,
		() => {
			setNotification({
				message: translate(
					({ accountUM }) => accountUM.payment.notifications.deletedPaymentSource
				)
			});
			fetchPaymentMethods();
			setCardToDelete(null);
		}
	);

	useCompletedAction(makingPaymentSourcePrimary, errorMakingPaymentSourcePrimary, () => {
		getBillingInfo();
		setNotification({
			message: translate(
				({ accountUM }) => accountUM.payment.notifications.madePaymentSourcePrimary
			)
		});
	});

	/**
	 * Called after removing the last credit card and updating the subscription's payment method to INVOICE.
	 */
	useCompletedAction(customerSubscriptionLoading, customerSubscriptionError, () => {
		getSubscription();
		fetchPaymentMethods();
		getBillingInfo();
		setCardToDelete(null);
	});

	function deleteCard(cardNumber: string) {
		removeSubscriptionPaymentMethod(cardNumber);
	}
	function makePrimary(cardNumber: string) {
		makePaymentSourcePrimary(cardNumber);
	}

	function onAddCreditCard() {
		if (!paymentMethods || !paymentMethods.cards.length) {
			setAddCreditCardWarningVisible(true);
		} else setCreditCardModalVisible(true);
	}

	function closeCreditCardModal() {
		setCreditCardModalVisible(false);
		setShouldProcessCard(false);
		setCreditCardForEdit(null);
	}

	const cardActionsDisabled = useMemo(
		() =>
			removingSubscriptionPaymentMethod ||
			makingPaymentSourcePrimary ||
			isSubscriptionUpdating ||
			customerSubscriptionLoading ||
			selectedPaymentMethodLoading,
		[
			removingSubscriptionPaymentMethod,
			makingPaymentSourcePrimary,
			isSubscriptionUpdating,
			customerSubscriptionLoading,
			selectedPaymentMethodLoading
		]
	);

	const loading = !changePlanModalVisible ? subscriptionLoading || accountLoading : false;

	const immediate =
		!subscriptionFetched ||
		!accountFetched ||
		!paymentMethodsFetched ||
		!selectedPaymentMethodFetched;

	return (
		<>
			<Header.Main
				rightComponent={null}
				leftComponent={translate(({ accountUM }) => accountUM.subscriptionManagementTitle)}
			/>
			<Header.Navigation
				rightComponent={
					canChangePlan ? (
						<Button
							hasPointer
							title={translate(({ accountUM }) => accountUM.changePlan.changePlan)}
							disabled={isSubscriptionUpdating}
							onClick={() => setChangePlanModalVisible(true)}
						/>
					) : undefined
					// <ChargebeeButton
					// 	onWaitingModalConfirmed={() => {
					// 		fetchPaymentMethods();
					// 	}}
					// />
				}
			/>
			<Header.Title title={translate(({ accountUM }) => accountUM.tabs.payment)} />

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					<Spacer size={s => s.xs} />
					{isSubscriptionUpdating ? (
						<>
							<UpdatingSubscriptionText />
							<Spacer size={s => s.m} />
						</>
					) : (
						selectedPaymentMethod &&
						paymentMethods && (
							<>
								<Typography.H5>
									{translate(
										({ accountUM }) => accountUM.payment.methods.currentMethod
									)}
								</Typography.H5>
								<Spacer size={s => s.s} />
								<PaymentMethods primaryPaymentMethod={selectedPaymentMethod} />
								<Spacer size={s => s.l} />

								<CardsTitleSection>
									<Typography.H5>
										{translate(
											({ accountUM }) => accountUM.payment.creditCards
										)}
									</Typography.H5>
									<PaymentSecuredCardsInfo />
								</CardsTitleSection>
								<Spacer size={s => s.s} />

								<CardGridContainer>
									{paymentMethods.cards.map((creditCard, index) => (
										<CreditCard
											withCardActions
											key={index}
											creditCard={creditCard}
											cardActionsDisabled={cardActionsDisabled}
											deleteCard={cardNumber => {
												setCardToDelete(cardNumber);
											}}
											makePrimary={makePrimary}
										/>
									))}
								</CardGridContainer>
								{paymentMethods.cards.length ? (
									<Button
										variant={v => v.link}
										title={translate(
											dict => dict.accountUM.payment.addAnotherCard
										)}
										disabled={isSubscriptionUpdating}
										onClick={onAddCreditCard}
										marginOffset={{ bottom: 1 }}
									/>
								) : (
									<AddFirstCard setAddCreditCardModalVisible={onAddCreditCard} />
								)}
							</>
						)
					)}
				</Grid.Container>
				{addCreditCardWarningVisible && (
					<AddCreditCardWarningModal
						onConfirm={() => {
							setAddCreditCardWarningVisible(false);
							setCreditCardModalVisible(true);
						}}
						onCancel={() => setAddCreditCardWarningVisible(false)}
					/>
				)}
				{cardToDelete && (
					<RemoveCreditCardWarningModal
						loading={customerSubscriptionLoading || removingSubscriptionPaymentMethod}
						removeLastCard={!!paymentMethods && paymentMethods.cards.length === 1}
						onConfirm={() => {
							// in case user wants to remove the last card, we update the subscription
							// to payment method INVOICE which will automatically remove all cards
							if (paymentMethods && paymentMethods.cards.length === 1)
								updateSubscriptionToInvoice();
							else deleteCard(cardToDelete);
						}}
						onCancel={() => setCardToDelete(null)}
					/>
				)}
				{creditCardModalVisible && (
					<AddCreditCardModal
						creditCard={creditCardForEdit}
						shouldProcessCard={shouldProcessCard}
						setShouldProcessCard={setShouldProcessCard}
						onClose={closeCreditCardModal}
						onCreditCardAdded={() => getBillingInfo()}
					/>
				)}
				{changePlanModalVisible && canChangePlan && (
					<ChangePlanModal onClose={() => setChangePlanModalVisible(false)} />
				)}
			</Suspend>
		</>
	);
}

import { useEffect } from 'react';
import { OperationResult, HookOptions } from 'hooks/store/types';
import { ActionTypes, selectRevisions, getEntryRevisions, Revision } from 'store/data/revisions';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity, useEntryId } from 'hooks/store';

type Data = Revision[] | undefined;

export function useRevisions(options?: HookOptions): OperationResult<Data> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};

	const [entryId] = useEntryId();

	const data = useSelector(state => selectRevisions(state.data.revisions, { entryId }));
	const fetched = !!data;

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_ENTRY_REVISIONS);

	useEffect(() => {
		if (!error && !loading && !fetched && !lazy) {
			handler();
		}
	}, [error, loading, fetched, lazy]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getEntryRevisions());
	}

	return [{ data, loading, error, fetched }, handler];
}

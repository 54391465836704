import styled from 'styled-components';

import { Shadows, Colors } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';

export const Container = styled.div<Disabled>`
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};

	${({ disabled }) => disableableComponent({ disabled })}
`;

import { useMemo, useState } from 'react';
import { RoleCard, RoleModal } from 'components/Roles';
import { CreateTemplateFromRoleModal } from 'components/Roles/CreateTemplateFromRoleModal';
import { DeleteRoleModal } from 'components/Roles/DeleteRoleModal';
import { NoRoles } from 'components/Roles/NoRoles/NoRoles';
import { Role } from 'store/data/roles';
import { RolesPageHeader } from './RolesPageHeader';
import { CardContainer } from './RolesPage.style';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import { filterRolesBySearchTerm } from 'helpers/roles';
import { useRoles } from 'hooks/store';
import { useModalState } from 'hooks/ui';

export function RolesPage() {
	const roleModal = useModalState<Role>();
	const deleteModal = useModalState<Role>();
	const createTemplateRoleFromRoleModal = useModalState<Role>();

	const [
		{
			data: { roles },
			loading: loadingRoles,
			fetched: areRolesFetched
		}
	] = useRoles();

	const suspendProps = {
		loading: loadingRoles && !areRolesFetched,
		immediate: !areRolesFetched
	};

	const [searchTerm, setSearchTerm] = useState('');

	const filteredRoles = useMemo(
		() => filterRolesBySearchTerm(roles, searchTerm),
		[roles, searchTerm]
	);

	return (
		<>
			<RolesPageHeader
				hasData={roles.length > 0}
				onCreate={roleModal.open}
				searchTermState={{ searchTerm, setSearchTerm }}
			/>

			<Suspend loading={suspendProps.loading} immediate={suspendProps.immediate}>
				<Grid.Container>
					<CardContainer>
						{filteredRoles.map(role => (
							<RoleCard
								key={role.id}
								role={role}
								actions={{
									onEdit: roleModal.open,
									onDelete: deleteModal.open,
									onCreateTemplate: createTemplateRoleFromRoleModal.open
								}}
							/>
						))}
					</CardContainer>
					{roles.length === 0 && <NoRoles onCreateRole={roleModal.open} />}
				</Grid.Container>
			</Suspend>

			{roleModal.visible && <RoleModal role={roleModal.payload} onClose={roleModal.close} />}
			{deleteModal.visible && deleteModal.payload && (
				<DeleteRoleModal role={deleteModal.payload} onClose={deleteModal.close} />
			)}
			{createTemplateRoleFromRoleModal.visible && (
				<CreateTemplateFromRoleModal
					role={createTemplateRoleFromRoleModal.payload}
					onClose={createTemplateRoleFromRoleModal.close}
				/>
			)}
		</>
	);
}

import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Input as DefaultInput } from 'components/UI/Inputs/Input';
import { TypographyTypes, getTypographyCSS } from 'components/UI/Typography';

interface GeneralProps {
	typographyType: TypographyTypes;
	$usedInGroup?: boolean;
	renderPlaceholder?: boolean;
	$isVariable?: boolean;
	$isCategoryVariable?: boolean;
	$isLabel?: boolean;
	$usedInFormDesigner?: boolean;
}

export const Input = styled(DefaultInput)<GeneralProps>`
	z-index: 2;
	// absolutely positioned menu;
	> div {
		padding-right: 9.6rem;

		${({ $isLabel }) =>
			!$isLabel &&
			css`
				word-break: break-word;
				padding-right: 0;
				overflow: auto;
				text-overflow: hidden;
			`}
	}
	textarea {
		${({ typographyType }) => getTypographyCSS(() => typographyType)}
		width: 100%;
		resize: none;
		border: none;
		outline: none;
		padding: 0;
		height: unset;
		min-height: unset;
		border-radius: unset;

		color: ${Colors.text.main} !important;
	}
`;

export const ReadOnlyContainer = styled.div<GeneralProps>`
	color: ${Colors.text.main} !important;
	white-space: pre-wrap;
	${({ $isLabel, $usedInFormDesigner }) =>
		!$isLabel &&
		$usedInFormDesigner &&
		css`
			z-index: 2;
		`}
	// absolutely positioned menu on the right side;
	${({ $usedInFormDesigner }) =>
		$usedInFormDesigner &&
		css`
			padding-right: 9.6rem;
			text-overflow: ellipsis;
			overflow: hidden;
		`}

	${({ $isLabel, $usedInFormDesigner }) =>
		!$isLabel &&
		$usedInFormDesigner &&
		css`
			word-break: break-word;
			padding-right: 0;
			overflow: auto;
			text-overflow: hidden;
		`}

	${({ typographyType }) => getTypographyCSS(() => typographyType)}

	${({ $usedInGroup }) =>
		$usedInGroup &&
		css`
			padding-top: 0.2rem;
		`};

	${({ renderPlaceholder }) =>
		renderPlaceholder &&
		css`
			color: ${Colors.text.disabled} !important;
		`};

	${({ $isVariable }) =>
		$isVariable &&
		css`
			margin-bottom: 0.4rem;
			font-weight: 500 !important;
		`}
	${({ $isCategoryVariable }) =>
		$isCategoryVariable &&
		css`
			margin-bottom: 1.6rem;
		`}
`;

import { Button } from 'components/UI/Interactables/Button';
import { useTranslation } from 'hooks/store';

import { Container } from './NoFilters.style';

interface Props {
	disabled?: boolean;
	onAddFilter?: () => void;
}

export function NoFilters({ disabled, onAddFilter }: Props) {
	const { translate } = useTranslation();

	return (
		<Container>
			<Button
				hasFullWidth={true}
				variant={v => v.outline}
				title={translate(({ analysis }) => analysis.filters.add)}
				disabled={disabled}
				onClick={onAddFilter}
			/>
		</Container>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setVariableName, SetVariableNameAction } from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useVariableName(): LocalOperationResult<
	string | null,
	(variableName: ActionPayload<SetVariableNameAction>['variableName']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.variables.metadata.variableName);

	function handler(variableName: ActionPayload<SetVariableNameAction>['variableName']) {
		dispatch(setVariableName({ variableName }));
	}

	return [data, handler];
}

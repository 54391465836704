import produce from 'immer';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

import { ActionTypes as EntriesActionTypes, Actions as EntriesActions } from '../entries/types';

export default (state: State = initialState, action: Actions | EntriesActions): State => {
	switch (action.type) {
		/*
			============================
						ASYNC
			============================
		*/

		case ActionTypes.GET_ENTRY_REVISIONS: {
			const { entryId, revisions } = action.payload;

			return produce(state, draft => {
				const { byId, byEntryId } = draft;

				const revisionIds: string[] = [];

				revisions.forEach(revision => {
					const { revisionId } = revision;

					revisionIds.push(revisionId);

					byId[revisionId] = {
						...revision,
						changes: {
							...revision.changes,
							data: byId[revisionId]
								? byId[revisionId].changes.data
								: revision.changes.data
						},
						statusChanges: {
							...revision.statusChanges,
							data: byId[revisionId]
								? byId[revisionId].statusChanges.data
								: revision.statusChanges.data
						}
					};
				});

				byEntryId[entryId] = { revisionIds };
			});
		}

		case ActionTypes.GET_SET_ENTRY_REVISIONS: {
			const { revisions, entryId } = action.payload;

			return produce(state, draft => {
				const revisionIds: string[] = [];
				const { byId, byEntryId } = draft;

				revisions.forEach(revision => {
					const { revisionId } = revision;

					revisionIds.push(revisionId);

					byId[revisionId] = {
						...revision,
						changes: {
							...revision.changes,
							data: byId[revisionId]
								? byId[revisionId].changes.data
								: revision.changes.data
						},
						statusChanges: {
							...revision.statusChanges,
							data: byId[revisionId]
								? byId[revisionId].statusChanges.data
								: revision.statusChanges.data
						}
					};
				});
				byEntryId[entryId] = { revisionIds };
			});
		}

		// gabriel
		// NEEDS CUSTOM FOR SET CASE!!!
		case ActionTypes.GET_ENTRY_REVISION_DATA: {
			const { entry, entryStatus } = action.payload;

			return produce(state, draft => {
				const {
					byId,

					metadata: { revisionId }
				} = draft;

				if (revisionId && byId[revisionId]) {
					const revision = byId[revisionId];

					revision.changes.data = entry;
					revision.statusChanges.data = entryStatus;
				}
			});
		}

		case ActionTypes.GET_SET_ENTRY_REVISION_DATA: {
			const { entry, entryStatus } = action.payload;

			return produce(state, draft => {
				const {
					byId,

					metadata: { setRevisionId }
				} = draft;

				if (setRevisionId && byId[setRevisionId]) {
					const revision = byId[setRevisionId];

					revision.changes.data = entry;
					revision.statusChanges.data = entryStatus;
				}
			});
		}

		/*
			============================
						LOCAL
			============================
		*/

		case ActionTypes.SET_CURRENT_CHANGE: {
			const { currentChange } = action.payload;

			return produce(state, draft => {
				draft.metadata.currentChange = currentChange;
			});
		}

		case ActionTypes.SET_CURRENT_REVISION: {
			const { currentRevision } = action.payload;

			return produce(state, draft => {
				draft.currentRevision = currentRevision;
			});
		}

		case ActionTypes.SET_IS_CLICKED: {
			const { isClicked } = action.payload;

			return produce(state, draft => {
				draft.metadata.isClicked = isClicked;
			});
		}

		case ActionTypes.SET_REVISION_ID: {
			const { revisionId } = action.payload;

			return produce(state, draft => {
				draft.metadata.revisionId = revisionId;
			});
		}

		case ActionTypes.SET_SET_REVISION_ID: {
			const { revisionId } = action.payload;

			return produce(state, draft => {
				draft.metadata.setRevisionId = revisionId;
			});
		}

		//////////////////////////////////////////////////
		//////////////////////////////////////////////////

		case EntriesActionTypes.UPDATE_ENTRY: {
			const { oldEntryId, entry } = action.payload;

			return produce(state, draft => {
				const { byEntryId } = draft;

				if (oldEntryId in byEntryId) {
					const { revisionIds } = byEntryId[oldEntryId];

					byEntryId[entry.datasetentryid] = { revisionIds };

					delete byEntryId[oldEntryId];
				}
			});
		}

		case EntriesActionTypes.RESET_ENTRY_FETCHED_DATA: {
			const { entryId } = action.payload;

			return produce(state, draft => {
				const { byEntryId, byId, metadata } = draft;

				if (entryId in byEntryId) {
					const { revisionIds } = byEntryId[entryId];

					revisionIds.forEach(revisionId => delete byId[revisionId]);

					delete byEntryId[entryId];

					metadata.revisionId = null;
				}
			});
		}

		default: {
			return state;
		}
	}
};

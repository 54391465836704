import { useEffect } from 'react';
import { useRouteMatch } from 'hooks/navigation';
import { NotificationActionState, NotificationBodyType } from 'store/data/notifications';
import { ROUTES, StorageKeys } from 'types/index';
import { useRefetchEntries } from '../entries/useRefetchEntries';
import { useProjectId } from '../projects/useProjectId';
import { useVariables } from '../variables/useVariables';
import { useNewestNotification } from './useNewestNotification';
import {
	useSubscription,
	useAccount,
	useAllEnterpriseOwnedProjects,
	useScopedProjects
} from 'hooks/store';

export function useNewestNotificationsHandler() {
	const [, getSubscription] = useSubscription();
	const [, getAccount] = useAccount();
	const [projectId] = useProjectId();
	const [, refetchEntries] = useRefetchEntries();
	const [, getProjects] = useScopedProjects({
		lazy: true,
		checkRoute: false
	});
	const [, getAllEnterpriseOwnedProjects] = useAllEnterpriseOwnedProjects();
	const [, getVariables] = useVariables({ lazy: true });

	const isOnEnterpriseProjectsTab = useRouteMatch(
		ROUTES.AdminEnterprise + '/' + ROUTES.EnterpriseAdminProjects
	);
	const isOnProjectsTab = useRouteMatch(ROUTES.Projects);
	const isOnPromsTab = useRouteMatch(ROUTES.Proms);
	const isOnRoot = useRouteMatch(ROUTES.Root);

	// SUBSCRIPTION PLAN CREATION, CHANGE or UPDATE cases
	const [{ data: newestPlanCreation }] = useNewestNotification(
		NotificationBodyType.SubscriptionPlanCreation
	);

	const [{ data: newestPlanChange }] = useNewestNotification(
		NotificationBodyType.SubscriptionPlanChange
	);

	const [{ data: newestPlanUpdate }] = useNewestNotification(
		NotificationBodyType.SubscriptionPlanUpdate
	);

	useEffect(() => {
		if (newestPlanCreation || newestPlanChange || newestPlanUpdate) {
			localStorage.setItem(StorageKeys.SubscriptionUpdating, 'false');
			getSubscription();
			// in order to have the correct licence type, account info needs to be refetched
			getAccount();
		}
	}, [newestPlanCreation, newestPlanChange, newestPlanUpdate]);

	// USER & OWNER TRANSFER INVITATIONS ANSWERS - notifications received by the inviter
	const [{ data: newestSubscriptionInvitationUserAnswer }] = useNewestNotification(
		NotificationBodyType.SubscriptionInvitationUserAnswer
	);

	const [{ data: newestSubscriptionInvitationOwnerAnswer }] = useNewestNotification(
		NotificationBodyType.SubscriptionInvitationOwnerAnswer
	);

	useEffect(() => {
		if (newestSubscriptionInvitationUserAnswer || newestSubscriptionInvitationOwnerAnswer) {
			getSubscription();
		}
	}, [newestSubscriptionInvitationUserAnswer, newestSubscriptionInvitationOwnerAnswer]);

	// USER & OWNER TRANSFER INVITATIONS ANSWERS - notifications received by the user invited
	// and by the owner of the user invited to a new subscription
	const [{ data: newestSubscriptionInvitation }] = useNewestNotification(
		NotificationBodyType.SubscriptionInvitation
	);
	useEffect(() => {
		if (
			newestSubscriptionInvitation &&
			(newestSubscriptionInvitation.actionState === NotificationActionState.ActionAccepted ||
				newestSubscriptionInvitation.actionState === NotificationActionState.NoAction)
		) {
			getSubscription();
			// in order to have the correct licence type, account info needs to be refetched
			getAccount();
		}
	}, [newestSubscriptionInvitation]);

	// SUBSCRIPTION USER REMOVAL - notification received by the removed user
	const [{ data: newestSubscriptionUserRemoval }] = useNewestNotification(
		NotificationBodyType.SubscriptionUserRemoval
	);

	useEffect(() => {
		if (newestSubscriptionUserRemoval) {
			getSubscription();
			// in order to have the correct licence type, account info needs to be refetched
			getAccount();
		}
	}, [newestSubscriptionUserRemoval]);

	//

	// SUBSCRIPTION USER REMOVAL - notification received by the removed user
	const [{ data: newestSubscriptionUserRemovalByDowngrade }] = useNewestNotification(
		NotificationBodyType.SubscriptionUserRemovalByDowngrade
	);

	useEffect(() => {
		if (newestSubscriptionUserRemovalByDowngrade) {
			getSubscription();
			// in order to have the correct licence type, account info needs to be refetched
			getAccount();
		}
	}, [newestSubscriptionUserRemovalByDowngrade]);
	//

	// PROJECT ENTRY ADD
	const [{ data: newestProjectEntryAdd }] = useNewestNotification(
		NotificationBodyType.ProjectEntryAdd
	);

	/**
	 * Set to refetch the entries & the variables when getting a ProjectEntryAdd notification
	 * and when the current project is the same as the one from the notification's body
	 */
	useEffect(() => {
		if (newestProjectEntryAdd && projectId) {
			if (newestProjectEntryAdd.body.projectId === projectId) {
				refetchEntries();
				getVariables();
			}
		}
	}, [newestProjectEntryAdd]);

	// PROJECT ENTRY ADD
	const [{ data: projectTransferred }] = useNewestNotification(
		NotificationBodyType.ProjectOwnershipTransferred
	);
	const [{ data: projectTransferredMulti }] = useNewestNotification(
		NotificationBodyType.ProjectOwnershipTransferredMulti
	);

	/**
	 * Set to refetch the entries & the variables when getting a ProjectEntryAdd notification
	 * and when the current project is the same as the one from the notification's body
	 */
	useEffect(() => {
		if (projectTransferred || projectTransferredMulti) {
			if (isOnEnterpriseProjectsTab) getAllEnterpriseOwnedProjects();
			if (isOnPromsTab || isOnProjectsTab || isOnRoot) getProjects();
		}
	}, [
		projectTransferred,
		projectTransferredMulti,
		isOnProjectsTab,
		isOnRoot,
		isOnPromsTab,
		isOnEnterpriseProjectsTab
	]);

	return null;
}

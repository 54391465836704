import { useState, useEffect, useCallback, useMemo } from 'react';
import { withMemo } from 'helpers/HOCs';
import { FormElements, FormGroup as FormGroupInterface, FormElement } from 'store/data/forms';
import { SetGroupPaginationDataInput, FormGroupPaginationData } from 'store/data/entries';
import { Revision } from 'store/data/revisions';
import { GroupCardHeader } from './GroupCardHeader';
import { Container, Body, Divider } from './FormGroup.style';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { usePaginate } from 'hooks/utils';

interface Props {
	formGroup: FormGroupInterface;
	elements: FormElements;
	expanded: boolean;
	disableFormContext?: boolean;
	revision?: Revision;
	isRevisionSelected?: boolean;
	setGroupExpanded: (groupName: string, value?: boolean) => void;
	groupPaginationData?: FormGroupPaginationData;
	setGroupPaginationData: (input: SetGroupPaginationDataInput) => void;
	renderElement: (element: FormElement, options?: { grouped?: boolean }) => React.ReactNode;
}

function Component({
	formGroup,
	elements,
	expanded,
	disableFormContext,
	revision,
	isRevisionSelected,
	setGroupExpanded,
	groupPaginationData,
	setGroupPaginationData,
	renderElement
}: Props) {
	const { elementsOrder, groupName } = formGroup;

	const [groupExpandedLocal, setGroupExpandedLocal] = useState(expanded);

	let startPageSize: number | undefined;
	let startPageIndex: number | undefined;

	if (groupPaginationData) {
		startPageSize = groupPaginationData.pageSize;
		startPageIndex = groupPaginationData.pageIndex;
	}

	const revisionStartIndexPage = useMemo(() => {
		let page = 0;

		Object.values(elements).forEach(variable => {
			const { variableRef } = variable;

			if (
				variableRef &&
				revision?.changes.variableNames.includes(variableRef) &&
				isRevisionSelected
			) {
				page = (Object.values(elements).indexOf(variable) + 1) / (startPageSize ?? 25);
			}
		});
		if (Math.round(page) === 0) {
			return 0;
		}
		return Math.round(page) < page ? Math.round(page) : Math.round(page) - 1;
	}, [elements, revision]);

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, changePageSize } =
		usePaginate(elementsOrder, {
			threshold: 25,
			pageSize: startPageSize ?? 25,
			startPageIndex
		});

	useEffect(() => {
		handleChangePage(revisionStartIndexPage);
	}, [revisionStartIndexPage, revision]);

	// SYNC `groupExpandedLocal` STATE
	useEffect(() => {
		if (expanded !== groupExpandedLocal) setGroupExpandedLocal(expanded);
	}, [expanded]);

	function handleExpandedState(value: boolean) {
		setGroupExpandedLocal(value);

		// UPDATE MASTER STATE
		setGroupExpanded(groupName, value);
	}

	function handleChangePage(index: number) {
		changePage(index);

		// UPDATE MASTER STATE
		setGroupPaginationData({
			groupName: formGroup.groupName,
			paginationData: {
				pageIndex: index,
				pageSize
			}
		});
	}

	function handleChangePageSize(size: number) {
		changePageSize(size);

		// UPDATE MASTER STATE
		setGroupPaginationData({
			groupName: formGroup.groupName,
			paginationData: {
				pageIndex: 0,
				pageSize: size
			}
		});
	}

	const handleExpandedStateMemo = useCallback(handleExpandedState, [groupExpandedLocal]);

	const hasChanges = useMemo(() => {
		if (
			Object.values(elements).filter(
				variable =>
					variable.variableRef &&
					!!revision?.changes.variableNames.includes(variable.variableRef)
			).length > 0
		) {
			return true;
		}
		return false;
	}, [revision]);

	return (
		<Container id={groupName}>
			<GroupCardHeader
				hasChanges={hasChanges && !!isRevisionSelected && !groupExpandedLocal}
				formGroup={formGroup}
				elements={elements}
				disableFormContext={disableFormContext}
				groupExpanded={groupExpandedLocal}
				onChange={handleExpandedStateMemo}
			/>

			{groupExpandedLocal && (
				<Body>
					{shouldPaginate && (
						<Flex paddingOffset={{ x: 1 }} marginOffset={{ y: 0.8 }} fullWidth column>
							<Pagination
								totalCountLabel="group fields"
								pageIndex={pageIndex}
								pageSize={pageSize}
								pagesCount={pagesCount}
								changePage={handleChangePage}
								changePageSize={handleChangePageSize}
								showCounts={false}
							/>

							<Divider />
						</Flex>
					)}

					{/* FORM ELEMENTS */}
					{page.map(row => {
						// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
						if (Array.isArray(row)) {
							return (
								<Flex
									key={`two-column-${row[0]}-${row[1]}}`}
									className="two-column-row"
								>
									{row.map(elementId => {
										const element = elements[elementId];

										if (!element) return null;

										return renderElement(element, {
											grouped: true
										});
									})}
								</Flex>
							);
						}
						// ONE COLUMN - FORM ELEMENT
						else {
							const elementId = row;

							const element = elements[elementId];

							if (!element) return null;

							return renderElement(element);
						}
					})}
				</Body>
			)}
		</Container>
	);
}

export const FormGroup = withMemo(Component);

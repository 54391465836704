import { ExploreResults, ExploreResultsV2, GetExploreResponseV2 } from 'api/data/analyses';

function parseItem(item: number | null): string {
	if (item === null) return 'N/A';

	return item.toString();
}

export function parseExploreResponse(data: GetExploreResponseV2['data']): ExploreResultsV2 {
	const result: ExploreResults = [];

	data.result?.forEach(item => {
		result.push({
			'nr of rows': item.n.toString(),
			average: parseItem(item.mean),
			confidentLower: parseItem(item.lowerCI),
			confidentUpper: parseItem(item.upperCI),
			IQRLower: parseItem(item.lowerIQR),
			IQRUpper: parseItem(item.upperIQR),
			kurtosis: parseItem(item.kurtosis),
			max: parseItem(item.max),
			min: parseItem(item.min),
			median: parseItem(item.median),
			missing: parseItem(item.missing),
			sem: parseItem(item.sem),
			skewness: parseItem(item.skewness),
			std: parseItem(item.sd),
			variableName: item.variable.name,
			variance: parseItem(item.variance),
			categorizationValue: '' // kept for backwards compatibility
		});
	});

	return { data: result, error: data.errors?.[0] };
}

import { useEffect, useRef, useState } from 'react';
import { FormElement, FormGroup, FormSet, setFormElementLabel } from 'store/data/forms';
import { ElementType, HTMLInput, InputType } from 'types/index';
import { useFormElementText, useTranslation } from 'hooks/store';
import { Input, ReadOnlyContainer } from './FormInput.style';
import { TypographyTypes } from 'components/UI/Typography';
import { Asterisk } from 'components/UI/Asterisk';
import { useOutsideClick } from 'hooks/utils';
import { LabelHint } from 'components/UI/Inputs/Input/Input.style';
import { Flex } from 'components/UI/Flex';

interface Props {
	element?: FormElement;
	formGroup?: FormGroup;
	formSet?: FormSet;
	required?: boolean;
	disabled?: boolean;
	isCategoryVariable?: boolean;
	usedInFormDesigner?: boolean;
	labelHint?: string;
}

export function FormInput({
	element,
	formGroup,
	formSet,
	disabled,
	required,
	usedInFormDesigner,
	isCategoryVariable,
	labelHint
}: Props) {
	const { translate } = useTranslation();
	const isBasicElement = element
		? [ElementType.Subtitle, ElementType.Text, ElementType.Separator].includes(
				element.elementType
		  )
		: false;
	const isVariableElement = !!element?.variableRef;

	const label = getLabel(element, formGroup, formSet);
	const type = element?.elementType;

	const [value, setValue] = useState(label);
	const [editMode, setEditMode] = useState(false);

	const inputRef = useRef<HTMLTextAreaElement>(null);
	const setFormElementText = useFormElementText();

	// SYNC VALUE STATE WITH LABEL
	useEffect(() => {
		if (label !== value) setValue(label);
	}, [label]);

	// FOCUS TEXT ON CLICK
	useEffect(() => {
		if (inputRef && inputRef.current) {
			if (editMode) {
				inputRef.current.focus();
			} else {
				inputRef.current.blur();
			}
		}
	}, [editMode]);

	function getLabel(element?: FormElement, formGroup?: FormGroup, formSet?: FormSet) {
		let label = '';

		// FORM SET
		if (formSet) label = formSet.setLabel;
		// FORM GROUP
		if (formGroup) label = formGroup.groupLabel;
		// FORM ELEMENT
		if (element) {
			if (isBasicElement) {
				label = element.text ?? '';
			} else {
				label = element.label ?? '';
			}
		}

		return label.trim();
	}

	const placeholder: string = translate(({ formDesigner }) => {
		let message = '';

		if (type === ElementType.Text) message = formDesigner.formTextPlaceholder;
		if (type === ElementType.Subtitle) message = formDesigner.formSubtitlePlaceholder;
		if (formGroup) message = formDesigner.formGroupPlaceholder;
		if (formSet) message = formDesigner.formSetPlaceholder;
		if (isVariableElement) message = formDesigner.formVariablePlaceholder;

		return message;
	});

	function handleLabelUpdate() {
		if (!usedInFormDesigner) return;

		const trimmedValue = value.trim();

		if (label === trimmedValue) return;

		if (element) {
			if (isBasicElement) {
				setFormElementText({
					elementId: element.elementId,
					text: trimmedValue
				});
			} else {
				setFormElementLabel({
					elementId: element.elementId,
					label: trimmedValue
				});
			}
		}

		focusOut();
	}
	function handleLabelReset() {
		if (!usedInFormDesigner) return;

		const label = getLabel(element, formGroup, formSet);

		setValue(label);
	}

	function onChange(e: React.ChangeEvent<HTMLInput>) {
		setValue(e.currentTarget.value);
	}

	// DISABLE EDIT MODE ON CLICK AWAY
	useOutsideClick(() => {
		focusOut();
		setValue(state => state.trim());
		setEditMode(false);
	}, [inputRef]);

	function focusOut() {
		inputRef.current?.blur();
	}

	// ENABLE EDIT MODE ON CLICK
	function handleEdit(e: React.MouseEvent) {
		if (usedInFormDesigner && !disabled && isBasicElement) {
			e.stopPropagation();
			setEditMode(true);
		}
	}

	let typographyType = TypographyTypes.Caption;

	if (type === ElementType.Text) typographyType = TypographyTypes.Paragraph;
	if (type === ElementType.Subtitle) typographyType = TypographyTypes.H6;
	if (formGroup) typographyType = TypographyTypes.Paragraph;
	if (formSet) typographyType = TypographyTypes.Paragraph;

	if (isCategoryVariable) typographyType = TypographyTypes.Paragraph;

	return usedInFormDesigner && editMode ? (
		<Input
			labelHint={labelHint}
			$isLabel={false}
			ref={inputRef}
			type={InputType.Textarea}
			required={required}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			disabled={disabled}
			typographyType={typographyType}
			$usedInGroup={!!formGroup}
			onSubmit={handleLabelUpdate}
			onBlur={handleLabelUpdate}
			onCancel={handleLabelReset}
			$isCategoryVariable={isCategoryVariable}
			$isVariable={isVariableElement}
		>
			{value !== '' ? value : placeholder}
		</Input>
	) : (
		<ReadOnlyContainer
			$usedInFormDesigner={usedInFormDesigner}
			$isLabel={!isBasicElement}
			title={value || placeholder}
			renderPlaceholder={!value}
			typographyType={typographyType}
			$usedInGroup={!!formGroup}
			$isVariable={isVariableElement}
			$isCategoryVariable={isCategoryVariable}
			onClick={handleEdit}
		>
			<Flex align={a => a.center}>
				{value || placeholder} {required && <Asterisk />}{' '}
				{!!labelHint && <LabelHint noMargin>{labelHint}</LabelHint>}
			</Flex>
		</ReadOnlyContainer>
	);
}

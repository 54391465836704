import { cloneDeep, orderBy } from 'lodash';
import { VariablesDataArray, ItemOptions, GridVariableTypes, Sort } from 'store/data/variables';

import {
	filterVariablesDataArrayBySearchTerm,
	isVariable,
	isGroupData,
	isVariableSetData
} from 'helpers/variables';
import { useTranslation } from 'hooks/store';
import { useVariablesFilters } from './useVariablesFilters';
import { useVariablesSearchTerm } from './useVariablesSearchTerm';
import { useDeepCompareMemo, useSelector } from 'hooks/utils';

interface Props {
	variablesDataArray: VariablesDataArray;
}

/**
 * Used for filtering variables TABLE and GRID views
 *
 * Filters applied:
 * - search term
 * - show (variables & groups / variables / groups)
 * - type (variable type)
 * - order (ASC / DESC)
 *
 * @returns filtered array of `variables` and `groups`
 */
export function useFilteredVariablesDataArray({ variablesDataArray }: Props): VariablesDataArray {
	const { translate } = useTranslation();

	const { data: entriesErrors } = useSelector(state => state.data.entries.errors);

	const [{ filters, areFiltersActive }] = useVariablesFilters();

	const [searchTerm] = useVariablesSearchTerm();

	return useDeepCompareMemo(() => {
		if (!(areFiltersActive || filters.errored)) return variablesDataArray;

		let filtered: VariablesDataArray = cloneDeep(variablesDataArray);

		// APPLY SEARCH TERM FILTER
		filtered = filterVariablesDataArrayBySearchTerm(filtered, searchTerm, {
			translate
		});

		// APPLY `show` FILTERS
		if (filters.show !== ItemOptions.ALL) {
			if (filters.show === ItemOptions.VARIABLES) {
				filtered = filtered.filter(item => isVariable(item));
			}
			if (filters.show === ItemOptions.GROUPS) {
				filtered = filtered.filter(item => isGroupData(item));
			}
			if (filters.show === ItemOptions.VARIABLE_SETS) {
				filtered = filtered.filter(item => isVariableSetData(item));
			}
		}

		// APPLY `type` FILTERS
		if (filters.type !== GridVariableTypes.ALL) {
			filtered = filtered.filter(item => {
				// GROUP DATA
				if (isGroupData(item)) {
					const group = item;

					group.groupVariables = group.groupVariables.filter(
						variable => variable.type === filters.type
					);

					return group.groupVariables.length > 0;
				}

				// VARIABLE SET DATA
				if (isVariableSetData(item)) {
					const variableSetData = item;

					variableSetData.setData = variableSetData.setData.filter(
						variableSetDataItem => {
							// GROUP
							if (isGroupData(variableSetDataItem)) {
								const group = variableSetDataItem;

								group.groupVariables = group.groupVariables.filter(
									variable => variable.type === filters.type
								);

								return group.groupVariables.length > 0;
							}

							// VARIABLE
							const variable = variableSetDataItem;

							return variable.type === filters.type;
						}
					);

					return variableSetData.setData.length > 0;
				}

				// VARIABLE
				const variable = item;

				return variable.type === filters.type;
			});
		}

		// APPLY `sort` FILTERS
		if (filters.sort !== Sort.DEFAULT) {
			if (filters.sort === Sort.ALPHABETICALLY) {
				filtered = orderBy(filtered, item => {
					// GROUP
					if (isGroupData(item)) {
						const group = item;

						// SORT GROUP VARIABLES
						group.groupVariables = orderBy(
							group.groupVariables,
							variable => variable.label
						);

						// SORT GROUP
						return group.groupLabel;
					}

					// VARIABLE SET DATA
					if (isVariableSetData(item)) {
						const variableSetData = item;

						variableSetData.setData.forEach(variableSetDataItem => {
							// GROUP
							if (isGroupData(variableSetDataItem)) {
								const group = variableSetDataItem;

								// SORT GROUP VARIABLES
								group.groupVariables = orderBy(
									group.groupVariables,
									variable => variable.label
								);

								// SORT GROUP
								return group.groupLabel;
							}

							// VARIABLE
							const variable = variableSetDataItem;

							return variable.label;
						});

						// SORT VARIABLE SET
						return variableSetData.setLabel;
					}

					// VARIABLE
					const variable = item;

					return variable.label;
				});
			}
			// TODO: MORE TO COME MAYBE (ASC/DESC)
		}

		// APPLY 'errored' FILTERS
		if (filters.errored) {
			filtered = filtered.filter(item => {
				// GROUP DATA
				if (isGroupData(item)) {
					const group = item;

					group.groupVariables = group.groupVariables.filter(variable =>
						isVariableErrored(variable.name)
					);

					return group.groupVariables.length > 0;
				}

				// VARIABLE SET DATA
				if (isVariableSetData(item)) {
					const variableSetData = item;

					variableSetData.setData = variableSetData.setData.filter(
						variableSetDataItem => {
							// GROUP
							if (isGroupData(variableSetDataItem)) {
								const group = variableSetDataItem;

								group.groupVariables = group.groupVariables.filter(variable =>
									isVariableErrored(variable.name)
								);

								return group.groupVariables.length > 0;
							}

							// VARIABLE
							const variable = variableSetDataItem;

							return isVariableErrored(variable.name);
						}
					);

					return variableSetData.setData.length > 0;
				}

				// VARIABLE
				const variable = item;

				return isVariableErrored(variable.name);
			});
		}

		function isVariableErrored(variableName: string) {
			return !!entriesErrors?.columns.includes(variableName);
		}

		return filtered;
	}, [variablesDataArray, searchTerm, filters, filters, areFiltersActive, entriesErrors]);
}

import { ActionTypes, addCollaboratorsToOrganization } from 'store/data/collaborators';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';

type HandlerInput = Parameters<typeof addCollaboratorsToOrganization>[0];

export function useAddCollaboratorsToOrganization(): Operation<
	(input: HandlerInput) => Promise<void>
> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.ADD_COLLABORATORS_TO_ORGANIZATIONS
	);

	async function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		await dispatch(addCollaboratorsToOrganization(input));
	}

	return [{ loading, error }, handler];
}

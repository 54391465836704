import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, moveVariablesOrGroupsToRootList } from 'store/data/variables';

type HandlerInput = Parameters<typeof moveVariablesOrGroupsToRootList>;

export function useMoveVariablesOrGroupsToRootList(): Operation<(...input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST);

	function handler(...input: HandlerInput) {
		dispatch(moveVariablesOrGroupsToRootList(...input));
	}

	return [{ loading, error }, handler];
}

import { useState } from 'react';
import {
	AccountInformation,
	PersonalSettings,
	Profile,
	Security,
	WorkInformation
} from 'components/MyAccount';
import { Drawer } from 'components/UI/Drawer';
import { MediaQueries, Svgs } from 'environment';
import { DrawerChoices } from 'types/index';
import { Container, Separator, Slide, Slider, Version } from './User.style';
import { Icon } from 'components/UI/Icons';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { useAccount, useLogout, useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { Typography } from 'components/UI/Typography';

export function User() {
	const { translate } = useTranslation();

	const [, logout] = useLogout();

	const [
		{
			data: { details },
			loading: loadingAccount
		}
	] = useAccount();

	const [open, setOpen] = useState(false);
	const [step, setStep] = useState(DrawerChoices.profile);

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.lg}`
	);

	function handleCloseDrawer() {
		setOpen(state => !state);
		setStep(DrawerChoices.profile);
	}

	const viewTitles = {
		[DrawerChoices.profile]: translate(({ accountUM }) => accountUM.userDrawer.headers.profile),
		[DrawerChoices.accountInformation]: translate(
			({ accountUM }) => accountUM.userDrawer.headers.accountInformation
		),
		[DrawerChoices.workInformation]: translate(
			({ accountUM }) => accountUM.userDrawer.headers.workInformation
		),
		[DrawerChoices.personalSettings]: translate(
			({ accountUM }) => accountUM.userDrawer.headers.personalSettings
		),
		[DrawerChoices.security]: translate(
			({ accountUM }) => accountUM.userDrawer.headers.security
		)
	};

	const title = viewTitles[step];

	return (
		<Container>
			{isMobileDevice ? (
				<Icon svg={Svgs.Menu} variant={v => v.button} onClick={() => setOpen(true)} />
			) : (
				<Avatar
					userId={details ? details.userid : null}
					data={{
						userFirstName: details?.userFirstName,
						userSirName: details?.userSirName,
						imageURL: details?.imageURL,
						imageString: details?.imageString
					}}
					size={s => s.m}
					loading={loadingAccount}
					alt={translate(dict => dict.accountUM.userDrawer.avatar.alt)}
					onClick={() => setOpen(true)}
				/>
			)}

			<Drawer
				open={open}
				onClose={handleCloseDrawer}
				title={title}
				onGoBack={() => setStep(DrawerChoices.profile)}
				showBackButton={step !== DrawerChoices.profile}
			>
				<Slider>
					{/* MAIN VIEW */}
					<Slide active={step === DrawerChoices.profile}>
						<Profile
							onChangeStepTo={setStep}
							onLogout={logout}
							closeDrawer={handleCloseDrawer}
						/>
					</Slide>

					<Slide active={step === DrawerChoices.accountInformation}>
						{details && <AccountInformation details={details} />}
					</Slide>
					<Slide active={step === DrawerChoices.workInformation}>
						{details && <WorkInformation details={details} />}
					</Slide>
					<Slide active={step === DrawerChoices.personalSettings}>
						{details && <PersonalSettings details={details} />}
					</Slide>
					<Slide active={step === DrawerChoices.security}>
						<Security close={() => setStep(DrawerChoices.security)} />
					</Slide>
				</Slider>
				<Version>
					<Separator />
					<Typography.Caption>
						{translate(({ accountUM }) => accountUM.userDrawer.headers.version)}:{' '}
						{process.env.REACT_APP_VERSION}
					</Typography.Caption>
				</Version>
			</Drawer>
		</Container>
	);
}

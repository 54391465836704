import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, getSubscription } from 'store/account/subscription';
import { OrganizationAccessRights, selectOrganizationAccessRights } from 'store/account/enterprise';

export function useOrganizationAccessRights(): OperationResult<
	OrganizationAccessRights,
	() => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.GET_SUBSCRIPTION);

	const data = useSelector(state => selectOrganizationAccessRights(state.account.enterprise));

	function handler() {
		dispatch(getSubscription());
	}

	return [
		{
			data: {
				accessExportDelegation: data ? data.accessExportDelegation : true
			},
			loading,
			error
		},
		handler
	];
}

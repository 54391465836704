import { ActionTypes, updateDependencies } from 'store/data/dependencies';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';

type HandlerInput = Parameters<typeof updateDependencies>[0];

export function useUpdateDependencies(): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.UPDATE_DEPENDENCIES);

	function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(updateDependencies(input));
	}

	return [{ loading, error }, handler];
}

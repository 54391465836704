import { ActionTypes, completeResetPassword } from 'store/auth';
import { Operation } from 'hooks/store/types';
import { ResetPasswordInput } from 'api/auth';
import { useDispatch } from 'hooks/utils';
import { useActivity } from '../utils/useActivity';

interface Handlers {
	handler: (input: ResetPasswordInput) => void;
	resetError: () => void;
}

export function useCompleteResetPassword(): Operation<Handlers> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.COMPLETE_RESET_PASSWORD);

	function handler(input: ResetPasswordInput) {
		dispatch(completeResetPassword(input));
	}

	const handlers = {
		handler,
		resetError
	};

	return [{ loading, error }, handlers];
}

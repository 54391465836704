import { useState } from 'react';
import { Svgs, Colors } from 'environment';
import { getCloneStyle } from 'helpers/templates';
import { TemplateGroupInterface } from 'store/data/templates';
import { DragAndDropTypes } from 'types/index';
import { TemplateVariable } from '../TemplateVariable';
import {
	DraggableCard,
	CardContainer,
	CardHeader,
	Wrapper,
	VariableContainer,
	Container
} from 'components/Templates';
import { Draggable, DraggingStyle, Droppable } from 'react-beautiful-dnd';
import { VanishingActionIcon, GroupedIconWrapper } from 'components/Templates/styles';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useRemoveFromTemplate } from 'hooks/store';

interface Props {
	group: TemplateGroupInterface;
	templateId: string;
	writeAccess?: boolean;
	publishedTemplate?: boolean;
	withDropzone?: boolean;
}

export function TemplateGroupWithVariables({
	group,
	templateId,
	publishedTemplate = false,
	writeAccess = false,
	withDropzone = false
}: Props) {
	const [collapsed, setCollapsed] = useState(false);
	const removeFromTemplate = useRemoveFromTemplate();

	const { groupLabel, groupName, variables, draggableId } = group;
	const droppableId = `${groupName}${DragAndDropTypes.DraggableTemplateGroupedVariable}${templateId}`;

	return (
		<>
			<DraggableCard>
				<CardContainer>
					<CardHeader>
						<Container insideTemplate>
							<Icon
								svg={Svgs.ChevronDown}
								onClick={() => setCollapsed(state => !state)}
								rotate={collapsed ? 180 : 0}
							/>

							<Icon
								svg={Svgs.Folder}
								size={s => s.m}
								paddingOffset={{ y: 0.4 }}
								marginOffset={{ x: 0.8 }}
								propagate
							/>

							<Typography.Paragraph title={groupLabel} ellipsis>
								{groupLabel}
							</Typography.Paragraph>
						</Container>
						{publishedTemplate && templateId && (
							<VanishingActionIcon
								svg={Svgs.Delete}
								colors={{
									color: Colors.text.disabled,
									hover: Colors.primary.normal
								}}
								onClick={() =>
									removeFromTemplate({
										elementId: draggableId,
										templateId
									})
								}
							/>
						)}
					</CardHeader>
				</CardContainer>
			</DraggableCard>

			{/* BODY */}
			{collapsed &&
				(withDropzone ? (
					<Droppable droppableId={droppableId} isDropDisabled>
						{provided => (
							<div ref={provided.innerRef}>
								<Gap marginOffset={{ top: 0.8 }} marginGap={{ bottom: 0.8 }}>
									{variables.map((variable, index) => {
										const { label, draggableId: variableDraggableId } =
											variable;
										return (
											<Draggable
												key={variableDraggableId}
												index={index}
												draggableId={variableDraggableId}
												isDragDisabled={!writeAccess}
											>
												{(
													{ innerRef, draggableProps, dragHandleProps },
													snapshot
												) => {
													return (
														<>
															<VariableContainer
																data-test-id={label}
																ref={innerRef}
																{...draggableProps}
																{...dragHandleProps}
																style={getCloneStyle(
																	draggableProps.style as DraggingStyle,
																	snapshot
																)}
															>
																<Flex
																	key={
																		variable.draggableId + index
																	}
																>
																	<GroupedIconWrapper
																		isDragging={false}
																	>
																		<Icon
																			svg={
																				Svgs.ChevronArrowCorner
																			}
																			size={s => s.m}
																			paddingOffset={{
																				all: 0.8
																			}}
																		/>
																	</GroupedIconWrapper>

																	<TemplateVariable
																		variable={variable}
																		publishedTemplate
																		partOfGroup
																	/>
																</Flex>
															</VariableContainer>

															{snapshot.isDragging && (
																<Flex>
																	<Icon
																		svg={
																			Svgs.ChevronArrowCorner
																		}
																		size={s => s.m}
																		paddingOffset={{
																			all: 0.8
																		}}
																	/>
																	<DraggableCard
																		isDraggingSnapshot={
																			snapshot.isDragging
																		}
																		groupedVariable
																	>
																		<Wrapper>
																			<CardHeader>
																				<Container
																					insideTemplate
																				>
																					<Icon
																						svg={
																							Svgs.ChevronDown
																						}
																						marginOffset={{
																							right: 0.8
																						}}
																						onClick={() =>
																							undefined
																						}
																					/>
																					<Typography.Paragraph
																						ellipsis
																					>
																						{label}
																					</Typography.Paragraph>
																				</Container>
																			</CardHeader>
																		</Wrapper>
																	</DraggableCard>
																</Flex>
															)}
														</>
													);
												}}
											</Draggable>
										);
									})}
								</Gap>

								{/* Hide placeholder for clonable elements */}
								<div
									style={{
										visibility: 'hidden',
										height: 0,
										width: 0
									}}
								>
									{provided.placeholder}
								</div>
							</div>
						)}
					</Droppable>
				) : (
					<Gap marginGap={{ bottom: 0.8 }}>
						{variables.map((variable, index) => (
							<Flex key={variable.draggableId + index}>
								<GroupedIconWrapper isDragging={false}>
									<Icon
										svg={Svgs.ChevronArrowCorner}
										size={s => s.m}
										paddingOffset={{
											all: 0.8
										}}
									/>
								</GroupedIconWrapper>

								<TemplateVariable variable={variable} publishedTemplate />
							</Flex>
						))}
					</Gap>
				))}
		</>
	);
}

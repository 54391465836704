import produce from 'immer';
import { merge, uniqBy } from 'lodash';

import { VariableCategory } from 'api/data/variables';
import initialState from './initialState';
import {
	Actions,
	ActionTypes,
	State,
	///////
	GridVariableTypes,
	ItemOptions,
	Sort,
	StoreVariablesData,
	VariableSetOrderItem,
	VariablesViewOptions
} from './types';

import { Actions as ProjectActions, ActionTypes as ProjectActionTypes } from '../projects/types';
import { arrayUtils } from 'helpers/arrays';
import {
	variablesDataContainer,
	isVariableInGroup,
	isVariableOrderItem,
	orderContainer,
	repairVariableSetSettings,
	isGroupOrderItem,
	getVariableSetVariableNames,
	buildVariableCategoriesMap,
	initVariableCategory
} from 'helpers/variables';
import { VariableType } from 'types/data/variables/constants';

export default (state: State = initialState, action: Actions | ProjectActions): State => {
	switch (action.type) {
		case ProjectActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					const initial: StoreVariablesData = {
						variables: {
							byName: {}
						},
						groups: {
							byName: {}
						},
						variableSets: {
							byName: {}
						},
						order: []
					};

					// INITIALIZE STRUCTURE
					byProjectId[projectId] = {
						initial,
						current: initial,
						metadata: {
							viewOption: VariablesViewOptions.GRID,
							filters: {
								show: ItemOptions.ALL,
								type: GridVariableTypes.ALL,
								sort: Sort.DEFAULT,
								errored: false
							},
							variablesTypeChanges: []
						},
						fetched: false
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		/**
		 * ===================
		 * 	CRUD - VARIABLES
		 * ===================
		 */

		case ActionTypes.GET_VARIABLE: {
			// const { variable } = action.payload;

			// return produce(state, draft => {
			// 	// logic  here
			// })

			return state;
		}

		case ActionTypes.GET_VARIABLES: {
			const { projectId, variablesData } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					const { variablesMap, groupsMap, variableSetsMap, order } = variablesData;

					const initial: StoreVariablesData = {
						variables: {
							byName: variablesMap
						},
						groups: {
							byName: groupsMap
						},
						variableSets: {
							byName: variableSetsMap
						},
						order
					};

					projectData.initial = initial;
					projectData.current = initial;
					projectData.fetched = true;
				}
			});
		}

		case ActionTypes.CREATE_VARIABLE:
		case ActionTypes.CREATE_VARIABLE_LOCAL: {
			const { variable, destinationSetName, destinationGroupName, localChanges } =
				action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					variablesDataContainer(data).createVariable(variable, {
						destination: {
							setName: destinationSetName,
							groupName: destinationGroupName
						}
					});
				}
			});
		}

		case ActionTypes.UPDATE_VARIABLE: {
			const { variable } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variables.byName[variable.name] = variable;
					current.variables.byName[variable.name] = variable;
				}
			});
		}

		case ActionTypes.DELETE_VARIABLE:
		case ActionTypes.DELETE_VARIABLE_LOCAL: {
			const { variableName, setName, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { groups } = data;

					const parentGroupName = isVariableInGroup({
						variableName,
						groupsMap: groups.byName
					});

					variablesDataContainer(data).deleteVariable(variableName, {
						source: {
							setName,
							groupName: parentGroupName
						}
					});
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * ================
		 * 	CRUD - GROUPS
		 * ================
		 */

		case ActionTypes.CREATE_GROUP:
		case ActionTypes.CREATE_GROUP_LOCAL: {
			const { group, destinationIndex, setName, from, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					data.groups.byName[group.groupName] = group;

					// INSIDE VARIABLE SET
					if (setName !== undefined) {
						const variableSet = data.variableSets.byName[setName];

						// GROUP WAS CREATED WITH VARIABLES IN IT
						if (group.variablesBelongingToGroup.length) {
							// REMOVE VARIABLES THAT WERE ADDED TO GROUP FROM THE MAIN SET LIST
							variableSet.setOrder = variableSet.setOrder.filter(item => {
								if (isVariableOrderItem(item)) {
									const variableName = item.variable;

									return !group.variablesBelongingToGroup.includes(variableName);
								}

								return true;
							});
						}

						const groupOrderItem = { group: group.groupName };

						if (destinationIndex !== undefined) {
							variableSet.setOrder = arrayUtils.insert(
								variableSet.setOrder,
								destinationIndex,
								groupOrderItem
							);
						} else {
							variableSet.setOrder.push(groupOrderItem);
						}
					}
					// MAIN LIST
					else {
						// GROUP WAS CREATED WITH VARIABLES IN IT
						if (group.variablesBelongingToGroup.length) {
							// REMOVE VARIABLES THAT WERE ADDED TO GROUP FROM THE MAIN LIST
							data.order = orderContainer(data.order).removeVariables(
								group.variablesBelongingToGroup
							);
						}

						// APPEND NEW GROUP NAME TO NEW ORDER LIST
						data.order = orderContainer(data.order).addGroup(
							group.groupName,
							destinationIndex
						);
					}

					if (from?.set) {
						const { setName, variableName, parentGroup } = from.set;

						if (parentGroup !== undefined) {
							const group = data.groups.byName[parentGroup];

							group.variablesBelongingToGroup =
								group.variablesBelongingToGroup.filter(
									groupVariableName => groupVariableName !== variableName
								);
						} else {
							const variableSet = data.variableSets.byName[setName];

							// REMOVE VARIABLE FROM SET
							variableSet.setOrder = variableSet.setOrder.filter(item => {
								if (isVariableOrderItem(item)) {
									return item.variable !== variableName;
								}

								return true;
							});
						}
					}

					if (from?.group) {
						const { groupName, variableName } = from.group;

						const group = data.groups.byName[groupName];

						group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
							groupVariableName => groupVariableName !== variableName
						);
					}

					if (from?.mainList) {
						const { variableName } = from.mainList;

						// REMOVE VARIABLE FROM MAIN LIST
						data.order = orderContainer(data.order).removeVariable(variableName);
					}
				}
			});
		}

		case ActionTypes.UPDATE_GROUP: {
			const { group } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.groups.byName[group.groupName] = group;
					current.groups.byName[group.groupName] = group;
				}
			});
		}

		case ActionTypes.DELETE_GROUP:
		case ActionTypes.DELETE_GROUP_LOCAL: {
			const { groupName, setName, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					variablesDataContainer(data).deleteGroup(groupName, {
						source: {
							setName
						}
					});
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * =======================
		 * 	CRUD - VARIABLE SETS
		 * =======================
		 */

		case ActionTypes.CREATE_VARIABLE_SET:
		case ActionTypes.CREATE_VARIABLE_SET_LOCAL: {
			const {
				variableSet,
				variableNames,
				groupNames,
				destinationIndex,
				localChanges,
				group
			} = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					variablesDataContainer(data).createVariableSet(variableSet, {
						variableNames,
						groupNames,
						destination: {
							index: destinationIndex
						}
					});

					const variableSetData = data.variableSets.byName[variableSet.setName];

					if (group) {
						data.groups.byName[group.groupName] = group;

						// GROUP WAS CREATED WITH VARIABLES IN IT
						if (group.variablesBelongingToGroup.length) {
							// REMOVE VARIABLES THAT WERE ADDED TO GROUP FROM THE MAIN SET LIST
							variableSetData.setOrder = variableSetData.setOrder.filter(item => {
								if (isVariableOrderItem(item)) {
									const variableName = item.variable;

									return !group.variablesBelongingToGroup.includes(variableName);
								}

								return true;
							});
						}

						const groupOrderItem = { group: group.groupName };

						variableSetData.setOrder.push(groupOrderItem);
					}
				}
			});
		}

		case ActionTypes.UPDATE_VARIABLE_SET: {
			const { variableSet } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variableSets.byName[variableSet.setName] = variableSet;
					current.variableSets.byName[variableSet.setName] = variableSet;
				}
			});
		}

		case ActionTypes.DELETE_VARIABLE_SET:
		case ActionTypes.DELETE_VARIABLE_SET_LOCAL: {
			const { setName, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					variablesDataContainer(data).deleteVariableSet(setName, {
						// TODO: CHANGE WHEN CONTROLLED FROM THE UI
						removeContent: true
					});
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * ==========================
		 * 	CRUD - AGGREGATION RULES
		 * ==========================
		 */

		case ActionTypes.CREATE_VARIABLE_SET_AGGREGATION_RULE: {
			const { variableSet } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variableSets.byName[variableSet.setName] = variableSet;
					current.variableSets.byName[variableSet.setName] = variableSet;
				}
			});
		}

		case ActionTypes.UPDATE_VARIABLE_SET_AGGREGATION_RULE: {
			const { variableSet } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variableSets.byName[variableSet.setName] = variableSet;
					current.variableSets.byName[variableSet.setName] = variableSet;
				}
			});
		}

		case ActionTypes.DELETE_VARIABLE_SET_AGGREGATION_RULE: {
			const { setName, ruleName } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const data = byProjectId[projectId];

					const { initial } = data;

					const { variableSets } = initial;

					if (setName in variableSets.byName) {
						const variableSet = variableSets.byName[setName];

						variableSet.aggregationRules = variableSet.aggregationRules.filter(
							({ name }) => name !== ruleName
						);
					}

					data.current = { ...data.initial };
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * =========================================
		 * 	ACTIONS: MOVE
		 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
		 * 	TARGET: ROOT LIST
		 * =========================================
		 */

		case ActionTypes.MOVE_VARIABLE:
		case ActionTypes.MOVE_VARIABLE_LOCAL: {
			const { sourceIndex, destinationIndex, localChanges, setName } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					if (setName !== undefined) {
						const variableSet = data.variableSets.byName[setName];

						variableSet.setOrder = arrayUtils.move(
							variableSet.setOrder,
							sourceIndex,
							destinationIndex
						);
					} else {
						data.order = orderContainer(data.order).moveItem(
							sourceIndex,
							destinationIndex
						);
					}
				}
			});
		}

		case ActionTypes.MOVE_GROUP:
		case ActionTypes.MOVE_GROUP_LOCAL: {
			const { sourceIndex, destinationIndex, localChanges, setName } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					if (setName !== undefined) {
						const variableSet = data.variableSets.byName[setName];

						variableSet.setOrder = arrayUtils.move(
							variableSet.setOrder,
							sourceIndex,
							destinationIndex
						);
					} else {
						data.order = orderContainer(data.order).moveItem(
							sourceIndex,
							destinationIndex
						);
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_SET:
		case ActionTypes.MOVE_VARIABLE_SET_LOCAL: {
			const { sourceIndex, destinationIndex, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					data.order = orderContainer(data.order).moveItem(sourceIndex, destinationIndex);
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE:
		case ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE_LOCAL: {
			const { setName, sourceIndex, destinationIndex, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					if (setName in data.variableSets.byName) {
						const variableSet = data.variableSets.byName[setName];

						variableSet.aggregationRules = arrayUtils.move(
							variableSet.aggregationRules,
							sourceIndex,
							destinationIndex
						);
					}
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * ==========================================
		 * 	ACTIONS: ADD/MOVE/REMOVE
		 * 	SOURCE: VARIABLE(S)
		 * 	TARGET: FROM/TO GROUP(S)
		 * ==========================================
		 */

		case ActionTypes.ADD_VARIABLE_TO_GROUP:
		case ActionTypes.ADD_VARIABLE_TO_GROUP_LOCAL: {
			const { variableName, destinationIndex, groupName, localChanges, setName, from } =
				action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const data = localChanges ? current : initial;
					const { groups, variableSets } = data;

					const group = groups.byName[groupName];

					// ADD VARIABLE TO GROUP
					if (destinationIndex !== undefined) {
						// SPECIFIC INDEX
						group.variablesBelongingToGroup = arrayUtils.insert(
							group.variablesBelongingToGroup,
							destinationIndex,
							variableName
						);
					} else {
						// AT THE END OF THE LIST
						group.variablesBelongingToGroup.push(variableName);
					}

					// FROM VARIABLE SET DRAWER => VARIABLE GROUP CARD (MAIN LIST)
					if (from?.set) {
						const { setName, parentGroup } = from.set;

						// REMOVE FROM GROUP
						if (parentGroup !== undefined) {
							const group = groups.byName[parentGroup];

							group.variablesBelongingToGroup =
								group.variablesBelongingToGroup.filter(
									groupVariable => groupVariable !== variableName
								);

							return;
						}

						const variableSet = variableSets.byName[setName];

						// REMOVE VARIABLE FROM SET
						variableSet.setOrder = variableSet.setOrder.filter(item => {
							if (isVariableOrderItem(item)) {
								return item.variable !== variableName;
							}

							return true;
						});

						return;
					}

					// REMOVE VARIABLE FROM VARIABLE SET ORDER LIST
					if (!from?.mainList && setName !== undefined) {
						const variableSet = variableSets.byName[setName];

						// REMOVE VARIABLE FROM SET
						variableSet.setOrder = variableSet.setOrder.filter(item => {
							if (isVariableOrderItem(item)) {
								return item.variable !== variableName;
							}

							return true;
						});

						return;
					}

					// REMOVE VARIABLE FROM NEW ORDER LIST
					data.order = orderContainer(data.order).removeVariable(variableName);
				}
			});
		}

		case ActionTypes.ADD_VARIABLES_TO_GROUP:
		case ActionTypes.ADD_VARIABLES_TO_GROUP_LOCAL: {
			const {
				variableNames,
				groupName,
				destinationIndex,
				localChanges,
				predictiveUpdates,
				setName
			} = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const data = localChanges ? current : initial;
					const { groups, variableSets } = data;

					const group = groups.byName[groupName];

					// ADD VARIABLE TO GROUP
					if (destinationIndex !== undefined) {
						// SPECIFIC INDEX
						group.variablesBelongingToGroup = arrayUtils.insertMany(
							group.variablesBelongingToGroup,
							destinationIndex,
							variableNames
						);
					} else {
						// AT THE END OF THE LIST
						group.variablesBelongingToGroup.push(...variableNames);
					}

					// REMOVE VARIABLE FROM VARIABLE SET ORDER LIST
					if (setName !== undefined) {
						const variableSet = variableSets.byName[setName];

						// REMOVE VARIABLE FROM SET
						variableSet.setOrder = variableSet.setOrder.filter(item => {
							if (isVariableOrderItem(item)) {
								return !variableNames.includes(item.variable);
							}

							return true;
						});
					}
					// REMOVE VARIABLE FROM NEW ORDER LIST
					else {
						data.order = orderContainer(data.order).removeVariables(variableNames);
					}

					if (!predictiveUpdates) {
						byProjectId[projectId].current = byProjectId[projectId].initial;
					}
				}
			});
		}

		case ActionTypes.REMOVE_VARIABLE_FROM_GROUP:
		case ActionTypes.REMOVE_VARIABLE_FROM_GROUP_LOCAL: {
			const { variableName, groupName, destinationIndex, localChanges, setName } =
				action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { groups, variableSets } = data;

					const group = groups.byName[groupName];

					// REMOVE VARIABLE FROM GROUP
					group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
						name => name !== variableName
					);

					// ADD VARIALBE TO VARIABLE SET ORDER LIST
					if (setName !== undefined) {
						const variableSet = variableSets.byName[setName];

						variableSet.setOrder.push({ variable: variableName });
					}
					// ADD VARIALBE TO NEW ORDER LIST
					else {
						data.order = orderContainer(data.order).addVariable(
							variableName,
							destinationIndex
						);
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_INSIDE_GROUP:
		case ActionTypes.MOVE_VARIABLE_INSIDE_GROUP_LOCAL: {
			const { sourceIndex, destinationIndex, groupName, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const { groups } = localChanges ? current : initial;

					const group = groups.byName[groupName];

					group.variablesBelongingToGroup = arrayUtils.move(
						group.variablesBelongingToGroup,
						sourceIndex,
						destinationIndex
					);
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS:
		case ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS_LOCAL: {
			const {
				variableName,
				sourceGroupName,
				destinationGroupName,
				destinationIndex,
				localChanges
			} = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const { groups } = localChanges ? current : initial;

					const { byName: groupsByName } = groups;

					if (sourceGroupName in groupsByName && destinationGroupName in groupsByName) {
						const sourceGroup = groupsByName[sourceGroupName];
						const destinationGroup = groupsByName[destinationGroupName];

						// REMOVE VARIABLE FROM SOURCE GROUP
						sourceGroup.variablesBelongingToGroup =
							sourceGroup.variablesBelongingToGroup.filter(
								name => name !== variableName
							);

						// ADD VARIABLE TO DESTINATION GROUP
						if (destinationIndex !== undefined) {
							// SPECIFIC INDEX
							destinationGroup.variablesBelongingToGroup = arrayUtils.insert(
								destinationGroup.variablesBelongingToGroup,
								destinationIndex,
								variableName
							);
						} else {
							// AT THE END OF THE LIST
							destinationGroup.variablesBelongingToGroup.push(variableName);
						}
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS:
		case ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS_LOCAL: {
			const {
				variableNames,
				sourceGroupName,
				destinationGroupName,
				destinationIndex,
				localChanges,
				predictiveUpdates
			} = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const { groups } = localChanges ? current : initial;

					const { byName: groupsByName } = groups;

					if (sourceGroupName in groupsByName && destinationGroupName in groupsByName) {
						const sourceGroup = groupsByName[sourceGroupName];
						const destinationGroup = groupsByName[destinationGroupName];

						// REMOVE VARIABLES FROM SOURCE GROUP
						sourceGroup.variablesBelongingToGroup =
							sourceGroup.variablesBelongingToGroup.filter(
								name => !variableNames.includes(name)
							);

						// ADD VARIABLES TO DESTINATION GROUP
						if (destinationIndex !== undefined) {
							// SPECIFIC INDEX
							destinationGroup.variablesBelongingToGroup = arrayUtils.insertMany(
								destinationGroup.variablesBelongingToGroup,
								destinationIndex,
								variableNames
							);
						} else {
							// AT THE END OF THE LIST
							destinationGroup.variablesBelongingToGroup.push(...variableNames);
						}

						if (!predictiveUpdates) {
							byProjectId[projectId].current = byProjectId[projectId].initial;
						}
					}
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * ==========================================
		 * 	ACTIONS: ADD/MOVE/REMOVE
		 * 	SOURCE: VARIABLE(S) / GROUP(S)
		 * 	TARGET: FROM/TO SET(S)
		 * ==========================================
		 */

		case ActionTypes.ADD_VARIABLE_TO_SET:
		case ActionTypes.ADD_VARIABLE_TO_SET_LOCAL: {
			const { variableName, destinationIndex, setName, localChanges, from } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const data = localChanges ? current : initial;
					const { groups, variableSets } = data;

					const { byName: variableSetsByName } = variableSets;
					const variableSet = variableSetsByName[setName];

					const variableOrderItem: VariableSetOrderItem = {
						variable: variableName
					};

					// ADD VARIABLE TO SET
					if (destinationIndex !== undefined) {
						// SPECIFIC INDEX
						variableSet.setOrder = arrayUtils.insert(
							variableSet.setOrder,
							destinationIndex,
							variableOrderItem
						);
					} else {
						// AT THE END OF THE LIST
						variableSet.setOrder.push(variableOrderItem);
					}

					// FROM VARIABLE SET DRAWER => VARIABLE GROUP CARD (MAIN LIST)
					if (from?.set) {
						const { setName, parentGroup } = from.set;

						// REMOVE FROM GROUP
						if (parentGroup !== undefined) {
							const group = groups.byName[parentGroup];

							group.variablesBelongingToGroup =
								group.variablesBelongingToGroup.filter(
									groupVariable => groupVariable !== variableName
								);

							return;
						}

						const variableSet = variableSets.byName[setName];

						// REMOVE VARIABLE FROM SET
						variableSet.setOrder = variableSet.setOrder.filter(item => {
							if (isVariableOrderItem(item)) {
								return item.variable !== variableName;
							}

							return true;
						});

						return;
					}

					// FROM VARIABLE GROUP DRAWER => VARIABLE GROUP CARD (MAIN LIST)
					if (from?.group) {
						const { groupName } = from.group;

						const group = groups.byName[groupName];

						group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
							groupVariable => groupVariable !== variableName
						);

						return;
					}

					// REMOVE VARIABLE FROM NEW ORDER LIST
					data.order = orderContainer(data.order).removeVariable(variableName);
				}
			});
		}

		case ActionTypes.REMOVE_VARIABLE_FROM_SET:
		case ActionTypes.REMOVE_VARIABLE_FROM_SET_LOCAL: {
			const { variableName, setName, destinationIndex, localChanges, parentGroup } =
				action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { groups, variableSets } = data;

					const variableSet = variableSets.byName[setName];

					// VARIABLE IS WITHIN A GROUP INSIDE A VARIABLE SET
					if (parentGroup !== undefined) {
						const group = groups.byName[parentGroup];

						// REMOVE VARIABLE FROM GROUP
						group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
							groupVariableName => groupVariableName !== variableName
						);
					}
					// REMOVE VARIABLE FROM SET
					else {
						variableSet.setOrder = variableSet.setOrder.filter(item => {
							if (isVariableOrderItem(item)) {
								return item.variable !== variableName;
							}

							return true;
						});
					}

					repairVariableSetSettings(variableSet, groups.byName);

					// ADD VARIALBE TO NEW ORDER LIST
					data.order = orderContainer(data.order).addVariable(
						variableName,
						destinationIndex
					);
				}
			});
		}

		case ActionTypes.ADD_VARIABLE_GROUP_TO_SET:
		case ActionTypes.ADD_VARIABLE_GROUP_TO_SET_LOCAL: {
			const { groupName, destinationIndex, setName, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];
					const data = localChanges ? current : initial;
					const { groups, variableSets } = data;

					const { byName: variableSetsByName } = variableSets;
					const variableSet = variableSetsByName[setName];
					const group = groups.byName[groupName];

					const groupOrderItem: VariableSetOrderItem = {
						group: groupName
					};

					// ADD GROUP TO SET
					if (destinationIndex !== undefined) {
						// SPECIFIC INDEX
						variableSet.setOrder = arrayUtils.insert(
							variableSet.setOrder,
							destinationIndex,
							groupOrderItem
						);
					} else {
						// AT THE END OF THE LIST
						variableSet.setOrder.push(groupOrderItem);
					}

					// REMOVE GROUP FROM NEW ORDER LIST
					data.order = orderContainer(data.order).removeGroup(
						groupName,
						group.variablesBelongingToGroup,
						{
							removeVariables: true
						}
					);
				}
			});
		}

		case ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET:
		case ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET_LOCAL: {
			const { groupName, setName, destinationIndex, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { variableSets } = data;

					const variableSet = variableSets.byName[setName];

					// REMOVE VARIABLE GROUP FROM SET
					variableSet.setOrder = variableSet.setOrder.filter(item => {
						if (isGroupOrderItem(item)) return item.group !== groupName;

						return true;
					});

					// ADD VARIALBE TO NEW ORDER LIST
					data.order = orderContainer(data.order).addGroup(groupName, destinationIndex);
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_BETWEEN_SETS:
		case ActionTypes.MOVE_VARIABLE_BETWEEN_SETS_LOCAL: {
			const {
				variableName,
				sourceSetName,
				destinationSetName,
				destinationIndex,
				localChanges,
				parentGroup
			} = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const { groups, variableSets } = localChanges ? current : initial;

					if (
						sourceSetName in variableSets.byName &&
						destinationSetName in variableSets.byName
					) {
						const sourceSet = variableSets.byName[sourceSetName];
						const destinationSet = variableSets.byName[destinationSetName];

						// REMOVE VARIABLE FROM SOURCE SET
						sourceSet.setOrder = sourceSet.setOrder.filter(item => {
							if (isVariableOrderItem(item)) return item.variable !== variableName;

							return true;
						});

						// REMOVE VARIABLE FROM GROUP
						if (parentGroup !== undefined) {
							const group = groups.byName[parentGroup];

							group.variablesBelongingToGroup =
								group.variablesBelongingToGroup.filter(
									groupVariableName => groupVariableName !== variableName
								);
						}

						repairVariableSetSettings(sourceSet, groups.byName);

						const variableOrderItem = { variable: variableName };

						// ADD VARIABLE TO DESTINATION SET
						if (destinationIndex !== undefined) {
							// SPECIFIC INDEX
							destinationSet.setOrder = arrayUtils.insert(
								destinationSet.setOrder,
								destinationIndex,
								variableOrderItem
							);
						} else {
							// AT THE END OF THE LIST
							destinationSet.setOrder.push(variableOrderItem);
						}
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS:
		case ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS_LOCAL: {
			const { groupName, sourceSetName, destinationSetName, destinationIndex, localChanges } =
				action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const { variableSets } = localChanges ? current : initial;

					if (
						sourceSetName in variableSets.byName &&
						destinationSetName in variableSets.byName
					) {
						const sourceSet = variableSets.byName[sourceSetName];
						const destinationSet = variableSets.byName[destinationSetName];

						// REMOVE VARIABLE GROUP FROM SOURCE SET
						sourceSet.setOrder = sourceSet.setOrder.filter(item => {
							if (isGroupOrderItem(item)) return item.group !== groupName;

							return true;
						});

						const groupOrderItem = { group: groupName };

						// ADD VARIABLE GROUP TO DESTINATION SET
						if (destinationIndex !== undefined) {
							// SPECIFIC INDEX
							destinationSet.setOrder = arrayUtils.insert(
								destinationSet.setOrder,
								destinationIndex,
								groupOrderItem
							);
						} else {
							// AT THE END OF THE LIST
							destinationSet.setOrder.push(groupOrderItem);
						}
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET:
		case ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_SET_LOCAL: {
			const { destinationSetName, localChanges, data: dataToMove } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { groups, variableSets } = data;

					const { byName: variableSetsByName } = variableSets;
					const variableSet = variableSetsByName[destinationSetName];

					if (dataToMove.mainList.hasData) {
						dataToMove.mainList.groups.forEach(groupLocation => {
							const group = groups.byName[groupLocation.groupName];

							const groupOrderItem: VariableSetOrderItem = {
								group: groupLocation.groupName
							};

							// ADD GROUP TO SET

							variableSet.setOrder.push(groupOrderItem);

							// REMOVE GROUP FROM NEW ORDER LIST
							data.order = orderContainer(data.order).removeGroup(
								groupLocation.groupName,
								group.variablesBelongingToGroup,
								{ removeVariables: true }
							);
						});

						dataToMove.mainList.variables.forEach(variable => {
							const variableOrderItem: VariableSetOrderItem = {
								variable: variable.variableName
							};

							// ADD VARIABLE TO SET

							variableSet.setOrder.push(variableOrderItem);

							// FROM VARIABLE SET DRAWER => VARIABLE GROUP CARD (MAIN LIST)
							if (variable.from?.set) {
								const { setName } = variable.from.set;

								// REMOVE FROM GROUP
								if (variable.from.set.group !== undefined) {
									const group = groups.byName[variable.from.set.group.groupName];

									group.variablesBelongingToGroup =
										group.variablesBelongingToGroup.filter(
											groupVariable => groupVariable !== variable.variableName
										);

									return;
								}

								const variableSet = variableSets.byName[setName];

								// REMOVE VARIABLE FROM SET
								variableSet.setOrder = variableSet.setOrder.filter(item => {
									if (isVariableOrderItem(item)) {
										return item.variable !== variable.variableName;
									}

									return true;
								});

								return;
							}

							// FROM VARIABLE GROUP DRAWER => VARIABLE GROUP CARD (MAIN LIST)
							if (variable.from?.group) {
								const { groupName } = variable.from.group;

								const group = groups.byName[groupName];

								group.variablesBelongingToGroup =
									group.variablesBelongingToGroup.filter(
										groupVariable => groupVariable !== variable.variableName
									);

								return;
							}

							// REMOVE VARIABLE FROM NEW ORDER LIST
							data.order = orderContainer(data.order).removeVariable(
								variable.variableName
							);
						});
					}

					if (dataToMove.variableSets) {
						dataToMove.variableSets.forEach(variableSet => {
							const { setName, variables } = variableSet;
							variableSet.groups.forEach(groupLocation => {
								if (
									setName in variableSets.byName &&
									destinationSetName in variableSets.byName
								) {
									const sourceSet = variableSets.byName[setName];
									const destinationSet = variableSets.byName[destinationSetName];

									// REMOVE VARIABLE GROUP FROM SOURCE SET
									sourceSet.setOrder = sourceSet.setOrder.filter(item => {
										if (isGroupOrderItem(item))
											return item.group !== groupLocation.groupName;

										return true;
									});

									const groupOrderItem = { group: groupLocation.groupName };

									// ADD VARIABLE GROUP TO DESTINATION SET

									destinationSet.setOrder.push(groupOrderItem);
								}
							});
							variables.forEach(variableLocation => {
								if (
									setName in variableSets.byName &&
									destinationSetName in variableSets.byName
								) {
									const sourceSet = variableSets.byName[setName];
									const destinationSet = variableSets.byName[destinationSetName];

									// REMOVE VARIABLE FROM SOURCE SET
									sourceSet.setOrder = sourceSet.setOrder.filter(item => {
										if (isVariableOrderItem(item))
											return item.variable !== variableLocation.variableName;

										return true;
									});

									// REMOVE VARIABLE FROM GROUP
									if (variableLocation.from?.group !== undefined) {
										const group =
											groups.byName[variableLocation.from?.group.groupName];

										group.variablesBelongingToGroup =
											group.variablesBelongingToGroup.filter(
												groupVariableName =>
													groupVariableName !==
													variableLocation.variableName
											);
									}

									repairVariableSetSettings(sourceSet, groups.byName);

									const variableOrderItem = {
										variable: variableLocation.variableName
									};

									// ADD VARIABLE TO DESTINATION SET
									destinationSet.setOrder.push(variableOrderItem);
								}
							});
						});
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST:
		case ActionTypes.MOVE_VARIABLES_OR_GROUPS_TO_ROOT_LIST_LOCAL: {
			const { localChanges, data: dataToMove, destinationIndex } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { groups, variableSets } = data;

					if (dataToMove.mainList.hasData) {
						dataToMove.mainList.variables.forEach(variable => {
							if (variable.from?.group) {
								const group = groups.byName[variable.from?.group.groupName];

								// REMOVE VARIABLE FROM GROUP
								group.variablesBelongingToGroup =
									group.variablesBelongingToGroup.filter(
										name => name !== variable.variableName
									);

								// ADD VARIALBE TO NEW ORDER LIST
								data.order = orderContainer(data.order).addVariable(
									variable.variableName,
									destinationIndex
								);
							}
						});
					}

					if (dataToMove.variableSets) {
						dataToMove.variableSets.forEach(variableSet => {
							const { setName, variables } = variableSet;
							const variableSetData = variableSets.byName[setName];
							variableSet.groups.forEach(groupLocation => {
								const variableSet = variableSets.byName[setName];

								// REMOVE VARIABLE GROUP FROM SET
								variableSet.setOrder = variableSet.setOrder.filter(item => {
									if (isGroupOrderItem(item))
										return item.group !== groupLocation.groupName;

									return true;
								});

								// ADD VARIALBE TO NEW ORDER LIST
								data.order = orderContainer(data.order).addGroup(
									groupLocation.groupName,
									destinationIndex
								);
							});

							variables.forEach(variableLocation => {
								// VARIABLE IS WITHIN A GROUP INSIDE A VARIABLE SET
								if (variableLocation.from?.set?.group !== undefined) {
									const group =
										groups.byName[variableLocation.from?.set?.group.groupName];

									// REMOVE VARIABLE FROM GROUP
									group.variablesBelongingToGroup =
										group.variablesBelongingToGroup.filter(
											groupVariableName =>
												groupVariableName !== variableLocation.variableName
										);
								}
								// REMOVE VARIABLE FROM SET
								else {
									variableSetData.setOrder = variableSetData.setOrder.filter(
										item => {
											if (isVariableOrderItem(item)) {
												return (
													item.variable !== variableLocation.variableName
												);
											}

											return true;
										}
									);
								}

								repairVariableSetSettings(variableSetData, groups.byName);

								// ADD VARIALBE TO NEW ORDER LIST
								data.order = orderContainer(data.order).addVariable(
									variableLocation.variableName,
									destinationIndex
								);
							});
						});
					}
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * ================
		 * 	BULK DELETE
		 * ================
		 */

		case ActionTypes.DELETE_BULK_VARIABLES_DATA: {
			const {
				variables: variablesToDelete,
				groups: groupsToDelete,
				variableSets: variableSetsToDelete
			} = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const data = byProjectId[projectId];

					const { initial } = data;

					const { variables, groups, variableSets } = initial;

					variablesToDelete.forEach(variableToDelete => {
						const { variableName, from } = variableToDelete;

						delete variables.byName[variableName];

						if (from?.set) {
							const { setName, group } = from.set;

							const variableSet = variableSets.byName[setName];

							// REMOVE FROM GROUP
							if (group) {
								const parentGroup = groups.byName[group.groupName];

								parentGroup.variablesBelongingToGroup =
									parentGroup.variablesBelongingToGroup.filter(
										groupVariable => groupVariable !== variableName
									);

								repairVariableSetSettings(variableSet, groups.byName);

								return;
							}

							// REMOVE VARIABLE FROM SET
							variableSet.setOrder = variableSet.setOrder.filter(item => {
								if (isVariableOrderItem(item)) {
									return item.variable !== variableName;
								}

								return true;
							});

							repairVariableSetSettings(variableSet, groups.byName);

							return;
						}

						if (from?.group) {
							const { groupName } = from.group;

							const parentGroup = groups.byName[groupName];

							parentGroup.variablesBelongingToGroup =
								parentGroup.variablesBelongingToGroup.filter(
									groupVariable => groupVariable !== variableName
								);

							return;
						}

						initial.order = orderContainer(initial.order).removeVariable(variableName);
					});

					groupsToDelete.forEach(groupToDelete => {
						const { groupName, from } = groupToDelete;

						const group = groups.byName[groupName];

						if (from?.set) {
							const { setName } = from.set;

							const variableSet = variableSets.byName[setName];

							const itemIndex = variableSet.setOrder.findIndex(
								item => isGroupOrderItem(item) && item.group === groupName
							);

							// REMOVE GROUP
							variableSet.setOrder = variableSet.setOrder.filter(item => {
								if (isGroupOrderItem(item)) return item.group !== groupName;

								return true;
							});

							// BUILD GROUP VARIABLES AS ORDER ITEMS
							const variableItems = group.variablesBelongingToGroup.map(
								variableName => ({
									variable: variableName
								})
							);

							// RELEASE GROUP VARIABLES IN THE SAME INDEX
							variableSet.setOrder = arrayUtils.insertMany(
								variableSet.setOrder,
								itemIndex,
								variableItems
							);
						} else {
							// FILTER OUT GROUPS FROM NEW ORDER LIST
							initial.order = orderContainer(initial.order).removeGroup(
								groupName,
								group.variablesBelongingToGroup
							);
						}

						delete groups.byName[groupName];
					});

					variableSetsToDelete.forEach(variableSetToDelete => {
						const { setName } = variableSetToDelete;

						const variableSet = variableSets.byName[setName];

						const variableSetVariableNames = getVariableSetVariableNames(variableSet, {
							groupsMap: groups.byName
						});

						variableSetVariableNames.forEach(
							variableName => delete variables.byName[variableName]
						);

						// TODO: CATER FOR NOT DELETING VARIABLE SET CONTENT
						// REMOVE VARIABLE SET FROM NEW ORDER LIST
						initial.order = orderContainer(initial.order).removeVariableSet(setName);

						// DELETE VARIABLE SET
						delete variableSets.byName[setName];
					});

					data.current = { ...data.initial };
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		/**
		 * ===================
		 * 	CRUD - CATEGORIES
		 * ===================
		 */

		case ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE:
		case ActionTypes.CREATE_VARIABLE_CATEGORY_VALUES:
		case ActionTypes.UPDATE_VARIABLE_CATEGORY_VALUE:
		case ActionTypes.DELETE_VARIABLE_CATEGORY_VALUE:
		case ActionTypes.DELETE_VARIABLE_CATEGORY_VALUES: {
			const { variable } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variables.byName[variable.name] = variable;
					current.variables.byName[variable.name] = variable;
				}
			});
		}

		case ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE_LOCAL: {
			const { variableName, categoryValue } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { current } = byProjectId[projectId];

					const { variables } = current;

					if (variableName in variables.byName) {
						const variable = variables.byName[variableName];

						variable.categories.push(categoryValue);
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE:
		case ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE_LOCAL: {
			const { variableName, sourceIndex, destinationIndex, localChanges } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const data = localChanges ? current : initial;

					const { variables } = data;

					if (variableName in variables.byName) {
						const variable = variables.byName[variableName];

						variable.categories = arrayUtils.move(
							variable.categories,
							sourceIndex,
							destinationIndex
						);
					}
				}
			});
		}

		case ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES:
		case ActionTypes.MOVE_VARIABLE_CATEGORY_VALUES_LOCAL: {
			const { variableName, categoryValueIds, destination, localChanges, variable } =
				action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					if (variable) {
						initial.variables.byName[variable.name] = variable;
						current.variables.byName[variable.name] = variable;
						return;
					}

					const data = localChanges ? current : initial;

					const { variables } = data;

					if (variableName in variables.byName) {
						const variable = variables.byName[variableName];

						const categoriesMap = buildVariableCategoriesMap(variable.categories, 'id');
						const selectedCategories = categoryValueIds.map(id => categoriesMap[id]);
						const originalCategoryValueIds = variable.categories.map(c => c.id);

						variable.categories = variable.categories.filter(
							c => !categoryValueIds.includes(c.id)
						);

						if ('index' in destination) {
							// first
							if (destination.index === 0) {
								variable.categories.unshift(...selectedCategories);
							}

							// specific
							if (destination.index > 0) {
								let offset = 0;

								categoryValueIds.forEach(id => {
									if (originalCategoryValueIds.indexOf(id) < destination.index) {
										offset++;
									}
								});

								const destionationIndex = destination.index - offset;

								variable.categories = arrayUtils.insertMany(
									variable.categories,
									destionationIndex,
									selectedCategories
								);
							}

							// last
							if (destination.index === -1) {
								variable.categories.push(...selectedCategories);
							}
						}
						if ('before' in destination) {
							const categoryId = destination.before.id;
							const categoryIdIndex = variable.categories.findIndex(
								c => c.id === categoryId
							);

							if (categoryIdIndex !== -1) {
								variable.categories = arrayUtils.insertMany(
									variable.categories,
									categoryIdIndex,
									selectedCategories
								);
							}
						}
						if ('after' in destination) {
							const categoryId = destination.after.id;
							const categoryIdIndex = variable.categories.findIndex(
								c => c.id === categoryId
							);

							if (categoryIdIndex !== -1) {
								variable.categories = arrayUtils.insertMany(
									variable.categories,
									categoryIdIndex + 1,
									selectedCategories
								);
							}
						}
					}
				}
			});
		}

		case ActionTypes.MERGE_VARIABLE_CATEGORY_VALUES: {
			const { variable } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variables.byName[variable.name] = variable;
					current.variables.byName[variable.name] = variable;
				}
			});
		}

		case ActionTypes.ACTIVATE_VARIABLE_CATEGORIES:
		case ActionTypes.DEACTIVATE_VARIABLE_CATEGORIES: {
			const { variable } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					initial.variables.byName[variable.name] = variable;
					current.variables.byName[variable.name] = variable;
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		case ActionTypes.REBUILD_VARIABLES_DATA_CATEGORIES: {
			const { entry } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { initial, current } = byProjectId[projectId];

					const { byName: currentByName } = current.variables;
					const { byName: initialByName } = initial.variables;

					Object.entries(entry).forEach(([name, value]) => {
						const variableExists = name in initialByName;

						if (!variableExists) return;

						const isCategory = [
							VariableType.Category,
							VariableType.CategoryMultiple
						].includes(initialByName[name].type);

						/*
						 *  Prevents previously deleted fixed category value from appearing when restoring to a revision that had it
						 */
						if (isCategory) {
							if (initialByName[name].fixedCategories) {
								return;
							}
						}

						const isValueValid = value !== null && value.length > 0;

						if (isCategory && isValueValid) {
							const newCategories: VariableCategory[] = [];

							// CATEGORY MULTIPLE - string[]
							if (Array.isArray(value)) {
								const categoryValues = value.map(value =>
									initVariableCategory({ value })
								);

								newCategories.push(...categoryValues);
							}
							// CATEGORY - string
							else {
								if (value !== null) {
									const categoryValue = initVariableCategory({ value });

									newCategories.push(categoryValue);
								}
							}

							// TODO: FIND ANOTHER APPROACH
							currentByName[name].categories = uniqBy(
								[currentByName[name].categories, newCategories].flat(),
								'value'
							);
							initialByName[name].categories = uniqBy(
								[initialByName[name].categories, newCategories].flat(),
								'value'
							);
						}
					});
				}
			});
		}

		case ActionTypes.SET_VARIABLE_NAME: {
			const { variableName } = action.payload;

			return produce(state, draft => {
				draft.metadata.variableName = variableName;
			});
		}

		case ActionTypes.SET_DESTINATION_SET_NAME: {
			const { setName } = action.payload;

			return produce(state, draft => {
				draft.metadata.destinationSetName = setName;
			});
		}

		case ActionTypes.SET_DESTINATION_GROUP_NAME: {
			const { groupName } = action.payload;

			return produce(state, draft => {
				draft.metadata.destinationGroupName = groupName;
			});
		}

		case ActionTypes.SET_VARIABLES_FILTERS: {
			const { filters } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const { metadata } = byProjectId[projectId];

					metadata.filters = merge(metadata.filters, filters);
				}
			});
		}

		case ActionTypes.SET_VARIABLES_VIEW_OPTION: {
			const { viewOption } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					byProjectId[projectId].metadata.viewOption = viewOption;
				}
			});
		}

		case ActionTypes.SET_VARIABLES_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.searchTerm = term;
			});
		}

		case ActionTypes.SET_DRAGGING_VARIABLE_NAME: {
			const { name } = action.payload;

			return produce(state, draft => {
				draft.metadata.dragging = name;
			});
		}

		default: {
			return state;
		}
	}
};

import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { Container } from './NoDocuments.style';
import { usePermissions, useTranslation } from 'hooks/store';

interface Props {
	onAddFileClick: () => void;
}

export function NoDocuments({ onAddFileClick }: Props) {
	const { translate } = useTranslation();

	const { hasDatasetWriteAllAccess } = usePermissions();

	return (
		<Container>
			<Spacer size={s => s.xxl} />
			<Svgs.EmptyStatesNoDocuments style={{ minHeight: 240 }} />
			<Spacer size={s => s.m} />
			<Typography.H3>{translate(dict => dict.documents.list.noFilesTitle)}</Typography.H3>

			{hasDatasetWriteAllAccess && (
				<>
					<Spacer size={s => s.xs} />
					<Typography.Paragraph>
						{translate(dict => dict.documents.list.noFilesSubTitle)}
					</Typography.Paragraph>
					<Spacer size={s => s.m} />
					<Button
						title={translate(dict => dict.documents.buttons.add)}
						onClick={onAddFileClick}
					/>
				</>
			)}
			<Spacer size={s => s.xxl} />
		</Container>
	);
}

import styled, { css } from 'styled-components';
import { Colors, MediaQueries } from 'environment';
import { FontWeights, Typography } from 'components/UI/Typography';

interface TabProps {
	active: boolean;
}
export const Tab = styled.div<TabProps>`
	display: flex;
	align-items: center;

	height: 4rem;
	cursor: pointer;
	position: relative;

	padding-left: 2.4rem;
	padding-right: 0.8rem;

	:hover ${Typography.Paragraph} {
		color: ${Colors.primary.normal};
	}

	@media only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm} {
		padding-right: 2.4rem;
	}

	${({ active }) =>
		active &&
		css`
			${Typography.Paragraph} {
				color: ${Colors.primary.normal};
				font-weight: ${FontWeights.medium};

				::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					display: block;
					height: 100%;
					width: 0.2rem;
					border-radius: 0 0.4rem 0.4rem 0;
					background-color: ${Colors.primary.normal};

					@media only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth
							.sm} {
						height: 0.2rem;
						width: 100%;
						bottom: 0;
						top: auto;
					}
				}
			}
		`}
`;

import { useEffect, useState, useMemo } from 'react';
import { isEqual, cloneDeep } from 'lodash';

import { Columns, DataModel, ExploreAnalysisV2 } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { Svgs, Colors } from 'environment';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { ConfigContainer } from '../../UI';

import { Button, SelectContainer } from './ExploreConfigV2.style';
import { Icon } from 'components/UI/Icons';
import { CollapsibleCard } from 'components/Projects/Filters/Filter.style';
import { Gap } from 'components/UI/Gap';
import { Typography } from 'components/UI/Typography';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import {
	useTranslation,
	useUpdateAnalysis,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../../AnalysisOptions/AnalysisOptionsHeader';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { Nullable, SelectItem, SelectItemOrGroup } from 'types';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: ExploreAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function ExploreConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const usedVariableNames = useMemo(
		() => analysis.input.variables.map(({ name }) => name),
		[analysis.input.variables]
	);

	const [newVariable, setNewVariable] = useState(true);
	const [variablesList, setVariablesList] = useState(usedVariableNames);

	const [activeColumn] = useAnalysesActiveColum();
	const { selectItemsMap: mainSelectItemsMap, selectItems: mainSelectItems } =
		variablesDataSelectItems;
	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);

	const {
		input: { series, dataModel }
	} = analysis;

	const [{ data: variablesData }] = useVariables();
	const { variableSets } = variablesData;
	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);

	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const selectItemsMap = { ...seriesSelectItemsMap, ...mainSelectItemsMap };

	const numericSelectItems = useMemo(() => {
		function match(item: SelectItemOrGroup): boolean {
			if ('options' in item) {
				item.options = item.options.filter(match);
				return item.options.length > 0;
			}
			if ('value' in item) {
				return !variablesList.includes(item.value);
			}

			return true;
		}

		if (series) {
			return cloneDeep(
				mergeSelectItems(seriesSelectItems, variablesData, [
					VariableType.Float,
					VariableType.Integer,
					VariableType.TimeDuration
				])
			).filter(match);
		}

		return cloneDeep(
			mergeSelectItems(mainSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			])
		).filter(match);
	}, [mainSelectItems, seriesSelectItems, series]);

	useEffect(() => {
		if (!isEqual(usedVariableNames, variablesList)) setVariablesList(usedVariableNames);
	}, [usedVariableNames]);

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	useDebounce(
		() => {
			if (isEqual(usedVariableNames, variablesList)) return;

			const values = variablesList.map(name => ({
				name,
				...(variablesLocation[name]?.setName && { series: variablesLocation[name].setName })
			}));

			const updatedAnalysis: ExploreAnalysisV2 = {
				...analysis,
				input: { ...analysis.input, variables: values }
			};

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[variablesList],
		ANALYSIS_DEBOUNCE_TIME
	);

	function changeVariable(previousVariableName: string, variableName: string) {
		setVariablesList(state => {
			const previousVariableIndex = state.indexOf(previousVariableName);
			const newState = [...state];
			newState[previousVariableIndex] = variableName;

			return newState;
		});
	}

	function addVariable(variableName: string) {
		setVariablesList(state => [...state, variableName]);
	}

	function removeVariable(variableName: string) {
		setVariablesList(state => [...state.filter(name => name !== variableName)]);
	}

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: ExploreAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: []
			}
		};

		setNewVariable(true);

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = { ...analysis, input: { ...analysis.input, series } };

		setNewVariable(true);
		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input,
					variables: []
				}
			}
		});
	}

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.singleSeries),
			value: DataModel.series
		}
	];

	const noVariablesAvailable = !numericSelectItems.length;

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as ExploreAnalysisV2} />
			)}
			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.correlationsV2.config.series
							)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}

					<div>
						<Typography.Caption
							color={Colors.text.main}
							fontweight={w => w.medium}
							marginOffset={{ bottom: 0.4 }}
						>
							{translate(
								({ analysis }) => analysis.analyses.explore.config.variables
							)}
						</Typography.Caption>

						<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
							{variablesList.map((variableName, index) => (
								<SelectContainer key={index}>
									<CreatableSelect
										items={numericSelectItems}
										value={selectItemsMap[variableName] ?? null}
										onValueSelected={value =>
											value && changeVariable(variableName, value)
										}
										canClear={false}
									/>
									<Icon
										svg={Svgs.Delete}
										marginOffset={{ left: 0.8 }}
										colors={{
											color: Colors.text.disabled,
											hover: Colors.primary.normal
										}}
										onClick={() => removeVariable(variableName)}
									/>
								</SelectContainer>
							))}
						</Gap>
						<>
							{newVariable && (
								<SelectContainer {...(variablesList.length >= 1 && { top: 1.6 })}>
									<CreatableSelect
										disabled={
											dataModel === null ||
											(dataModel === DataModel.series && !series)
										}
										placeholder={translate(
											({ analysis }) =>
												analysis.analyses.explore.config.selectVariables
										)}
										items={numericSelectItems}
										onValueSelected={value => {
											if (value) {
												addVariable(value);
												setNewVariable(false);
											}
										}}
										canClear={false}
									/>
									<Icon
										disabled={
											dataModel === null ||
											(dataModel === DataModel.series && !series)
										}
										svg={Svgs.Delete}
										marginOffset={{ left: 0.8 }}
										colors={{
											color: Colors.text.disabled,
											hover: Colors.primary.normal
										}}
										onClick={() => setNewVariable(false)}
									/>
								</SelectContainer>
							)}

							{!newVariable && !noVariablesAvailable && (
								<Button
									marginOffset={{ top: 1.6 }}
									variant={v => v.link}
									title={translate(
										({ analysis }) =>
											analysis.analyses.explore.config.addVariables
									)}
									onClick={() => setNewVariable(true)}
								/>
							)}
						</>
					</div>
				</Gap>
			</CollapsibleCard>
		</ConfigContainer>
	);
}

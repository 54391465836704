import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { ResizeDashboardCardActionDynamic, resizeDashboardCard } from 'store/data/projectDashboard';
import { ActionPayload } from 'store/types';

export function useResizeDashboardCard(): LocalOperation<
	(payload: ActionPayload<ResizeDashboardCardActionDynamic>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ResizeDashboardCardActionDynamic>) {
		dispatch(resizeDashboardCard(payload));
	}

	return handler;
}

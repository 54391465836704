import { useRef, useState } from 'react';
import { TableInstance } from 'react-table';
import { ProjectCardOptions } from 'components/Projects/Project';
import { Svgs } from 'environment';
import { ProjectData } from 'store/data/projects';
import { CollaboratorPermissions } from 'store/data/collaborators';
import { StringMap } from 'types/index';
import { Option } from './helpers';
import { Cell, Wrapper, Container, MoreIconContainer, TextContainer } from './ProjectsTable.style';
import { Flex } from 'components/UI/Flex';
import { Table } from 'components/UI/Table';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { filterEmptyHeaderGroups } from 'helpers/entries';
import { showOnTouchDevices, loseFocus } from 'helpers/generic';
import { useProjectId } from 'hooks/store';
import { useOutsideClick, useEffectOnce } from 'hooks/utils';

interface Props extends ProjectCardOptions {
	tableRef: React.RefObject<HTMLTableElement>;
	size: number;
	instance: TableInstance<ProjectData>;
	translatedStatus: StringMap;
	projectOptions: (projectOwner?: string, userAcces?: CollaboratorPermissions) => Option[];
	onClick: (projectId: string, userAccess?: CollaboratorPermissions) => void;
}

export function ProjectsTable({ instance, projectOptions, onClick, tableRef, size }: Props) {
	const [projectId] = useProjectId();
	return (
		<Container>
			<Wrapper>
				<Table tableRef={tableRef} {...instance.getTableProps()} fullWidth hoverEffect>
					<Table.Head>
						{filterEmptyHeaderGroups(instance.headerGroups).map(headerGroup => (
							<Table.Row
								{...headerGroup.getHeaderGroupProps()}
								key={headerGroup.getHeaderGroupProps().key}
							>
								{headerGroup.headers.map(column => (
									<Table.Column
										{...column.getHeaderProps([
											column.getSortByToggleProps(),
											{
												...column.customHeaderProps
											}
										])}
										key={column.getHeaderProps().key}
									>
										{column.render('Header')}
									</Table.Column>
								))}
							</Table.Row>
						))}
					</Table.Head>

					<Table.Body {...instance.getTableBodyProps()}>
						{instance.page.map(row => {
							instance.prepareRow(row);

							const project = row.original;

							return (
								<Table.Row
									{...row.getRowProps()}
									key={row.getRowProps().key}
									onClick={() => onClick(project.projectId, project.userAccess)}
									clickable
								>
									{row.cells.map((cell, index, all) => (
										<Cell
											{...cell.getCellProps([
												{
													...cell.column.customCellProps
												}
											])}
											key={cell.getCellProps().key}
											noWrap
											style={{
												overflowX: 'visible'
											}}
											menuVisible={project.projectId === projectId}
										>
											<Flex align={a => a.center} justify={j => j.between}>
												<TextContainer
													style={{
														width: `calc(85vw / ${size})`
													}}
												>
													{cell.render('Cell')}
												</TextContainer>

												{/* show options menu */}
												{index === all.length - 1 && (
													<Options
														project={project}
														projectOptions={projectOptions}
													/>
												)}
											</Flex>
										</Cell>
									))}
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Wrapper>
		</Container>
	);
}

interface OptionsProps {
	project: ProjectData;
	projectOptions: (projectOwner?: string, userAcces?: CollaboratorPermissions) => Option[];
}

function Options({ project, projectOptions }: OptionsProps) {
	const elementValueRef = useRef<HTMLAnchorElement>(null);
	const [menuVisible, setMenuVisible] = useState(false);

	useOutsideClick(() => setMenuVisible(false), [elementValueRef]);

	const [, setProjectId] = useProjectId();

	function stopEvent(e: React.MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
	}

	useEffectOnce(() => setProjectId(null));

	return (
		<MoreIconContainer visible={menuVisible} className={showOnTouchDevices('card-more-icon')}>
			<Dropdown
				toggleComponent={({ ref, toggle }) => (
					<Icon
						ref={ref}
						variant={v => v.button}
						svg={Svgs.More}
						onClick={e => {
							stopEvent(e);
							loseFocus();
							setProjectId(project.projectId);
							toggle();
						}}
						active={menuVisible}
						size={s => s.m}
					/>
				)}
				width={10}
				offset={{ right: 2 }}
			>
				{projectOptions(project.createdByUser, project.userAccess).map((option, index) => (
					<Dropdown.Item
						key={index}
						title={option.title}
						onClick={() => option.onClick(project.projectId)}
					/>
				))}
			</Dropdown>
		</MoreIconContainer>
	);
}

import { createSelector } from 'reselect';
import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getTemplatesById({ templatesById }: State) {
	return templatesById;
}

function getListOfUsersSharedWIth({ templateId, templatesById }: State) {
	if (!templateId || !templatesById[templateId]) return;
	const template = templatesById[templateId];

	return template.usersSharedWith;
}

function getListOfProjectsSharedWIth({ templateId, templatesById }: State) {
	if (!templateId || !templatesById[templateId]) return;
	const template = templatesById[templateId];

	return template.projectsSharedWith;
}

function getTemplate({ templatesById, templateId }: State) {
	if (!templateId || !templatesById[templateId]) return;

	return templatesById[templateId];
}

function getTemplateById({ templatesById }: State, templateId: string) {
	return templatesById[templateId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectTemplates = createSelector([getTemplatesById], templatesById => templatesById); //check how to trigger that

export const selectListOfUsersSharedWIth = createSelector(
	[getListOfUsersSharedWIth],
	users => users
);

export const selectListOfProjectsSharedWIth = createSelector(
	[getListOfProjectsSharedWIth],
	projects => projects
);

export const selectTemplate = createSelector([getTemplate], template => template);

export const selectTemplateById = createSelector([getTemplateById], template => template);

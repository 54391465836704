import {
	JADBioAnalysisInput,
	JADBioAnalysisModels,
	JADBioFeatureImportancePlots,
	JADBioModelElement,
	JADBioProbabilitiesDisplayType,
	JADBioReductionType
} from 'api/data/analyses';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

export function getJADBioZingChartExportType(input: JADBioAnalysisInput, step: number) {
	const { model, probabilitiesDisplayType } = input;
	if (model === JADBioAnalysisModels.Classification) {
		if (step === 1) {
			return ZingChartExportTypes.JADBioClassificationPerformance;
		}

		if (step === 2 && probabilitiesDisplayType === JADBioProbabilitiesDisplayType.BoxPlot) {
			return ZingChartExportTypes.JADBioClassificationProbabilitiesBoxPlot;
		}

		if (step === 2 && probabilitiesDisplayType === JADBioProbabilitiesDisplayType.DensityPlot) {
			return ZingChartExportTypes.JADBioClassificationProbabilitiesDensityPlot;
		}

		if (step === 3) {
			return ZingChartExportTypes.JADBioClassificationFeatureImportance;
		}
	}
	return '';
}

export function getJADBioExportFileName(input: JADBioAnalysisInput, step: number) {
	const { model, performanceReductionType, featureImportanceType, probabilitiesDisplayType } =
		input;
	if (model === JADBioAnalysisModels.Classification) {
		if (step === 1 && performanceReductionType === JADBioReductionType.PCA) {
			return ExportFileNames.JADBioClassificationPerformancePCA;
		}
		if (step === 1 && performanceReductionType === JADBioReductionType.UMAP) {
			return ExportFileNames.JADBioClassificationPerformanceUMAP;
		}

		if (step === 2 && probabilitiesDisplayType === JADBioProbabilitiesDisplayType.BoxPlot) {
			return ExportFileNames.JADBioClassificationProbabilitiesBoxPlot;
		}

		if (step === 2 && probabilitiesDisplayType === JADBioProbabilitiesDisplayType.DensityPlot) {
			return ExportFileNames.JADBioClassificationProbabilitiesDensityPlot;
		}

		if (
			step === 3 &&
			featureImportanceType === JADBioFeatureImportancePlots.FeatureImportance
		) {
			return ExportFileNames.JADBioClassificationFeatureImportance;
		}
		if (
			step === 3 &&
			featureImportanceType === JADBioFeatureImportancePlots.ProgressiveFeatureInclusion
		) {
			return ExportFileNames.JADBioClassificationProgressiveFeatureImportance;
		}
	}
	return '';
}

export function exportJADBioTable(
	data: JADBioModelElement[],
	{ translate }: { translate: TranslateFunction }
) {
	const comma = ',';
	const newLine = '\r\n';
	let csv = '';

	data.forEach(element => {
		let elementString = '';
		if (element.type) {
			const column = element.type.toUpperCase();
			elementString += column + newLine;

			// Model
			const rowModel = element.model.replace(/,/g, ' / ');
			elementString +=
				translate(({ analysis }) => analysis.analyses.JADBio.models.model) +
				comma +
				rowModel +
				newLine;

			// Feature Selection
			const rowFeatureSelection = element.featureSelection.replace(/,/g, ' / ');
			elementString +=
				translate(({ analysis }) => analysis.analyses.JADBio.models.featureSelection) +
				comma +
				rowFeatureSelection +
				newLine;

			// Preprocessing
			const rowPreprocessing = element.preprocessing.replace(/,/g, ' / ');
			elementString +=
				translate(({ analysis }) => analysis.analyses.JADBio.models.preprocessing) +
				comma +
				rowPreprocessing +
				newLine;

			// Performance
			if (element.performance) {
				elementString +=
					translate(({ analysis }) => analysis.analyses.JADBio.models.performance) +
					comma +
					newLine;
				Object.keys(element.performance).forEach(performanceKey => {
					elementString +=
						' ' +
						comma +
						performanceKey +
						' - ' +
						element.performance[performanceKey] +
						newLine;
				});
			}

			// Signatures

			if (element.signatures.flat(1).length > 0) {
				elementString +=
					translate(({ analysis }) => analysis.analyses.JADBio.models.signatures) +
					newLine;
				element.signatures.flat(1).forEach(signature => {
					elementString += ' ' + comma + signature + newLine;
				});
			}

			csv += elementString;
		}
	});

	return csv;
}

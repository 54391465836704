import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Suspend } from 'components/UI/Suspend';
// import { useTranslation } from 'hooks/store';

export function EnterpriseAdminSettingsPage() {
	// const { translate } = useTranslation();

	return (
		<>
			<Header.Main />
			<Header.Navigation rightComponent={<Flex></Flex>} />

			<Suspend loading={false} immediate={false}>
				<div>Enterprise Admin Settings page</div>
			</Suspend>
		</>
	);
}

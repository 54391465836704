import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { DependencyOperators, Dependency, Dependant } from 'store/data/dependencies';
import { HTMLInput, InputType } from 'types/index';
import { Container } from './DependencyCondition.style';
import { SupplierVariable } from '../DirectDependency.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import {
	useTranslation,
	useDependenciesContext,
	useTimeDurationEntries,
	useUpdateDependencies
} from 'hooks/store';
import { TIME_DURATION_OPTIONS_PREFIX_KEY_MAP } from 'timeDurationConsts';
import { sanitizeTimeDurationInput, validateTimeDurationInput } from 'helpers/entries';
import { useCompletedAction, useDeepCompareEffect, useSelector } from 'hooks/utils';
import { useCallback, useState } from 'react';
import { selectTranslations } from 'store/ui/i18n';

type DateOperators = Omit<Record<DependencyOperators, string>, '<=' | '>='>;
type Operators = Record<DependencyOperators, string>;

interface Props {
	dependency: Dependency;
	dependant: Dependant;
	supplierVariable: Variable;
}

export function DependencyCondition({ dependency, dependant, supplierVariable }: Props) {
	const { translate } = useTranslation();
	const translations = useSelector(state => selectTranslations(state.ui.i18n));
	const [{ loading, error }] = useUpdateDependencies();

	const {
		actions: { setDependantSupplierValueCondition, changeDependantOperator }
	} = useDependenciesContext();

	const { getTimeDurationInputPreview } = useTimeDurationEntries({ withTranslation: true });

	const [inputRef, setInputRef] = useState<HTMLInput | null>(null);

	const getInputRef = useCallback((node: HTMLInput | null) => {
		if (node) {
			if (!inputRef) {
				setInputRef(node);
			}
		}
	}, []);

	useDeepCompareEffect(() => {
		if (inputRef) {
			inputRef.focus();
			inputRef.blur();
		}
	}, [inputRef, translations]);

	// focus/blur on update (formatting input values)
	useCompletedAction(loading, error, () => {
		if (inputRef) {
			inputRef.focus();
			inputRef.blur();
		}
	});

	const supplierVariableType = supplierVariable.type;

	function handleSupplierValueConditionChange(supplierValueCondition: string) {
		if (supplierVariable.type === VariableType.TimeDuration) {
			const format = supplierVariable.durationFormat;
			if (!format) return;
			if (!validateTimeDurationInput(supplierValueCondition, format)) return;
		}
		setDependantSupplierValueCondition({
			dependencyName: dependency.dependencyName,
			dependantId: dependant.dependantId,
			supplierValueCondition
		});
	}

	function handleChangeDependantOperator(operator: DependencyOperators) {
		changeDependantOperator({
			dependencyName: dependency.dependencyName,
			dependantId: dependant.dependantId,
			operator
		});
	}

	const translatedNumberOperator: Operators = {
		[DependencyOperators.LESS_THAN]: translate(
			dict => dict.dependencies.dependencyCondition.lessThan
		),
		[DependencyOperators.LESS_OR_EQUAL_TO]: translate(
			dict => dict.dependencies.dependencyCondition.lessOrEqualTo
		),
		[DependencyOperators.EQUAL_TO]: translate(
			dict => dict.dependencies.dependencyCondition.equals
		),
		[DependencyOperators.GREATER_OR_EQUAL_TO]: translate(
			dict => dict.dependencies.dependencyCondition.greaterOrEqualTo
		),
		[DependencyOperators.GREATER_THAN]: translate(
			dict => dict.dependencies.dependencyCondition.greaterThan
		)
	};

	const numberOperatorSelectItems = [
		{
			label: translatedNumberOperator[DependencyOperators.LESS_THAN],
			value: DependencyOperators.LESS_THAN
		},
		{
			label: translatedNumberOperator[DependencyOperators.LESS_OR_EQUAL_TO],
			value: DependencyOperators.LESS_OR_EQUAL_TO
		},
		{
			label: translatedNumberOperator[DependencyOperators.EQUAL_TO],
			value: DependencyOperators.EQUAL_TO
		},
		{
			label: translatedNumberOperator[DependencyOperators.GREATER_OR_EQUAL_TO],
			value: DependencyOperators.GREATER_OR_EQUAL_TO
		},
		{
			label: translatedNumberOperator[DependencyOperators.GREATER_THAN],
			value: DependencyOperators.GREATER_THAN
		}
	];

	const translatedDateOperator: DateOperators = {
		[DependencyOperators.LESS_THAN]: translate(
			dict => dict.dependencies.dependencyCondition.before
		),
		[DependencyOperators.EQUAL_TO]: translate(
			dict => dict.dependencies.dependencyCondition.equals
		),
		[DependencyOperators.GREATER_THAN]: translate(
			dict => dict.dependencies.dependencyCondition.after
		)
	};

	const dateOperatorSelectItems = [
		{
			label: translatedDateOperator[DependencyOperators.LESS_THAN],
			value: DependencyOperators.LESS_THAN
		},
		{
			label: translatedDateOperator[DependencyOperators.EQUAL_TO],
			value: DependencyOperators.EQUAL_TO
		},
		{
			label: translatedDateOperator[DependencyOperators.GREATER_THAN],
			value: DependencyOperators.GREATER_THAN
		}
	];

	const placeholder =
		supplierVariable.durationFormat
			?.map(timeKey => TIME_DURATION_OPTIONS_PREFIX_KEY_MAP[timeKey])
			.join(':') ?? '';

	const timeDurationProps = {
		placeholder
	};

	return (
		<Container>
			{/* NUMERIC (INTEGER / FLOAT) */}
			{(supplierVariableType === VariableType.Float ||
				supplierVariableType === VariableType.Integer) && (
				<>
					<SupplierVariable>
						<CreatableSelect
							className="creatable-select-small"
							items={numberOperatorSelectItems}
							value={numberOperatorSelectItems.find(
								item => item.value === dependant.operator
							)}
							onValueSelected={item =>
								item && handleChangeDependantOperator(item as DependencyOperators)
							}
							canClear={false}
						/>
					</SupplierVariable>
					<Input
						className="input-small"
						type={InputType.Number}
						value={dependant.supplierValueCondition}
						onChange={e => handleSupplierValueConditionChange(e.target.value)}
					/>
				</>
			)}

			{/* TIME DURATION */}
			{supplierVariableType === VariableType.TimeDuration && (
				<>
					<SupplierVariable>
						<CreatableSelect
							className="creatable-select-small"
							items={numberOperatorSelectItems}
							value={numberOperatorSelectItems.find(
								item => item.value === dependant.operator
							)}
							onValueSelected={item =>
								item && handleChangeDependantOperator(item as DependencyOperators)
							}
							canClear={false}
						/>
					</SupplierVariable>
					<Input
						ref={getInputRef}
						className="input-small"
						type={InputType.Text}
						value={dependant.supplierValueCondition}
						onChange={e => handleSupplierValueConditionChange(e.target.value)}
						onBlur={e =>
							// ts check -> this cannot happen
							supplierVariable.durationFormat
								? handleSupplierValueConditionChange(
										getTimeDurationInputPreview(
											e.target.value,
											supplierVariable.durationFormat
										)
								  )
								: undefined
						}
						onFocus={e =>
							handleSupplierValueConditionChange(
								sanitizeTimeDurationInput(e.target.value)
							)
						}
						{...timeDurationProps}
					/>
				</>
			)}
			{/* DATE || DATETIME*/}
			{(supplierVariableType === VariableType.Date ||
				supplierVariableType === VariableType.DateTime) && (
				<>
					<SupplierVariable>
						<CreatableSelect
							className="creatable-select-small"
							items={dateOperatorSelectItems}
							value={dateOperatorSelectItems.find(
								item => item.value === dependant.operator
							)}
							onValueSelected={item =>
								item && handleChangeDependantOperator(item as DependencyOperators)
							}
							canClear={false}
						/>
					</SupplierVariable>

					{supplierVariableType === VariableType.Date && (
						<Input
							className="date-input-small"
							type={InputType.Date}
							value={dependant.supplierValueCondition}
							onDateChange={({ formattedDate: from }) =>
								handleSupplierValueConditionChange(from)
							}
						/>
					)}
					{supplierVariableType === VariableType.DateTime && (
						<DateTimeInput
							value={dependant.supplierValueCondition}
							onChange={value => handleSupplierValueConditionChange(value)}
							options={{
								small: true
							}}
						/>
					)}
				</>
			)}

			{/* STRING */}
			{supplierVariableType === VariableType.String && (
				<>
					<SupplierVariable>
						<Input
							className="input-small"
							type={InputType.Text}
							value={translate(
								dict => dict.dependencies.dependencyCondition.contains
							)}
							readOnly
						/>
					</SupplierVariable>
					<Input
						className="input-small"
						type={InputType.Text}
						value={dependant.supplierValueCondition}
						onChange={e => handleSupplierValueConditionChange(e.target.value)}
					/>
				</>
			)}
		</Container>
	);
}

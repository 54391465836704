import styled, { css } from 'styled-components';
import { Flex } from 'components/UI/Flex';
import { Responsive } from 'components/UI/Table/Table';
import { Colors } from 'environment';
import { FontWeights, getTypographyCSS } from 'components/UI/Typography';
import { Caption } from 'components/UI/Pagination/Pagination.style';

export const Container = styled.div`
	width: 100%;
`;

export const Wrapper = styled(Responsive)`
	margin-bottom: 2.4rem;
	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		min-width: 5.6rem;
		max-width: 5.6rem;
		border-top: 0;
	}

	.errored {
		/* Colors.text.error, 10% */
		background-color: rgba(255, 88, 93, 0.1);
	}
`;

export const IconWrapper = styled.div`
	width: 4rem;
`;

export const StatusTagWrapper = styled.div`
	display: flex;
	max-width: 100%;
`;

interface StatusTagProps {
	color: string;
}
export const StatusTag = styled.div<StatusTagProps>`
	${getTypographyCSS(t => t.Hint)};

	padding: 0.2rem 0.8rem;
	border-radius: 0.4rem;
	color: ${Colors.text.main};
	font-weight: ${FontWeights.bold};
	background-color: ${({ color }) => color};
`;

export const ExpiredStatusIndicator = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0.5rem 0;
	height: calc(100% - 1rem);
	border-left: 0.4rem solid ${Colors.text.error};
`;

export const ColumnsPaginationControllers = styled.div`
	display: flex;
	align-items: flex-start;
	border: 0.1rem solid ${Colors.background.disabled};
`;

interface ColorIndicatorProps {
	type: 'group' | 'variableSet' | 'error';
}
export const ColorIndicator = styled.div<ColorIndicatorProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 0.2rem;
	margin: 0 0.4rem;
	width: calc(100% - 0.8rem);

	${({ type }) => {
		const colors = {
			group: Colors.groupOrange,
			variableSet: Colors.seriesGreen,
			error: Colors.text.error
		};

		return css`
			background-color: ${colors[type]};
		`;
	}}
`;

export const FlexContainer = styled(Flex)`
	flex: 1;
	height: calc(100vh - 26rem);
`;

export const SeriesView = styled(Caption)`
	color: ${Colors.primary.normal};
	cursor: pointer;
	background: linear-gradient(to left, ${Colors.primary.disabled} 70%, transparent 100%);

	position: absolute;
	right: 0;
	top: 0.8rem;
`;

export const SeriesCaption = styled(Caption)`
	font-weight: 500;
	color: ${Colors.text.main};
	padding: 0;
`;

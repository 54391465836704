import {
	InvoiceInfoWrapper,
	PaymentMethodTextWrapper,
	PaymentMethodWrapper,
	RadioButton
} from './style';
import { PaymentMethod } from 'store/account/payments';
import { Loader } from 'components/UI/Loader';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation } from 'hooks/store';
import { Typography } from 'components/UI/Typography';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';

interface Props {
	selectedPaymentMethod: PaymentMethod; // because we have only 2 options we keep it like this. but might change in the future
	paymentMethodsLoading: boolean;
	payMonthly: boolean;
	onSelectPaymentMethod: (selectedPaymentMethod: PaymentMethod) => void;
}

export function SelectPaymentMethod({
	selectedPaymentMethod,
	paymentMethodsLoading,
	payMonthly,
	onSelectPaymentMethod
}: Props) {
	const { translate } = useTranslation();

	return (
		<>
			{paymentMethodsLoading ? (
				<Loader />
			) : (
				<>
					<Spacer size={s => s.m} />
					<Flex>
						<PaymentMethodWrapper
							selected={selectedPaymentMethod === PaymentMethod.CARD}
							disabled={false}
							onClick={() => onSelectPaymentMethod(PaymentMethod.CARD)}
							paddingOffset={{ all: 1.6 }}
							marginOffset={{ right: 1.1 }}
						>
							<RadioButton
								selected={selectedPaymentMethod === PaymentMethod.CARD}
								onSelect={() => onSelectPaymentMethod(PaymentMethod.CARD)}
								paddingOffset={{ top: 0.4, left: 0.2 }}
							/>
							<PaymentMethodTextWrapper>
								<Typography.Paragraph fontweight={w => w.bold}>
									{translate(
										dict =>
											dict.accountUM.changePlan.selectPaymentMethod.creditCard
									)}
								</Typography.Paragraph>
								<Typography.Paragraph>
									{translate(
										dict => dict.accountUM.changePlan.selectPaymentMethod.payNow
									)}
								</Typography.Paragraph>
							</PaymentMethodTextWrapper>
						</PaymentMethodWrapper>

						<PaymentMethodWrapper
							selected={selectedPaymentMethod === PaymentMethod.INVOICE}
							disabled={payMonthly}
							onClick={() => onSelectPaymentMethod(PaymentMethod.INVOICE)}
							paddingOffset={{ all: 1.6 }}
							marginOffset={{ left: 1.1 }}
						>
							<RadioButton
								selected={selectedPaymentMethod === PaymentMethod.INVOICE}
								onSelect={() => onSelectPaymentMethod(PaymentMethod.INVOICE)}
								disabled={payMonthly}
								paddingOffset={{ top: 0.4, left: 0.2 }}
							/>
							<PaymentMethodTextWrapper>
								<Typography.Paragraph
									fontweight={w => w.bold}
									color={payMonthly ? Colors.text.disabled : Colors.text.main}
								>
									{translate(
										dict =>
											dict.accountUM.changePlan.selectPaymentMethod.invoice
									)}
								</Typography.Paragraph>
								<Typography.Paragraph
									color={payMonthly ? Colors.text.disabled : Colors.text.main}
								>
									{translate(
										dict =>
											dict.accountUM.changePlan.selectPaymentMethod
												.payByInvoice
									)}
								</Typography.Paragraph>
							</PaymentMethodTextWrapper>
						</PaymentMethodWrapper>
					</Flex>
					<Spacer size={s => s.m} />
					<InvoiceInfoWrapper>
						<Icon
							svg={Svgs.Information}
							colors={{ color: Colors.text.hint }}
							marginOffset={{ all: 0.8 }}
						/>
						<Typography.Paragraph
							marginOffset={{ left: 0, top: 0.9, right: 0.8, bottom: 1.1 }}
						>
							{translate(
								dict => dict.accountUM.changePlan.selectPaymentMethod.invoiceInfo
							)}
						</Typography.Paragraph>
					</InvoiceInfoWrapper>
				</>
			)}

			<Spacer size={s => s.xs} />
		</>
	);
}

import { stringAsBoolean } from 'helpers/generic';
import { batchNotifications } from 'helpers/notifications';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import { useMemo } from 'react';
import {
	ActionTypes,
	Notification,
	getNotificationHistory,
	selectNotificationHistory
} from 'store/data/notifications';
import { Nullable } from 'types/index';

interface NotificationData {
	notifications: Nullable<Notification[]>;
	batchNotifications: Nullable<Notification[]>;
	unreadCount: number;
	showOnlyUnread: boolean;
	fullNotificationHistoryFetched: boolean;
}

export function useNotificationsBatch(): OperationResult<
	NotificationData,
	(resetOffset: boolean) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectNotificationHistory(state.data.notifications));

	const batchData = useMemo(
		() => (data ? batchNotifications(Object.values(data)) : null),
		[data]
	);

	const showOnlyUnread = useSelector(state => state.data.notifications.showOnlyUnread);

	const fullNotificationHistoryFetched = useSelector(
		state => state.data.notifications.fullNotificationHistoryFetched
	);

	const [{ loading, error }] = useActivity(ActionTypes.GET_NOTIFICATION_HISTORY);

	const SHOW_NOTIFICATIONS = stringAsBoolean(process.env.REACT_APP_USE_NOTIFICATIONS!);

	function handler(clearPrevious: boolean) {
		if (SHOW_NOTIFICATIONS) {
			dispatch(getNotificationHistory(clearPrevious));
		}
	}

	const unreadCount = useMemo(
		() => (batchData ? Object.values(batchData).filter(notif => !notif.isRead).length : 0),
		[batchData]
	);

	const fetched = data !== null;

	return [
		{
			loading,
			error,
			fetched,
			data: {
				notifications: data ? Object.values(data) : null,
				batchNotifications: batchData,
				unreadCount,
				showOnlyUnread,
				fullNotificationHistoryFetched
			}
		},
		handler
	];
}

import { Table } from 'components/UI/Table';
import { withMemo } from 'helpers/HOCs';
import { JADBioModelElement } from 'api/data/analyses';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface Props {
	data: JADBioModelElement[];
}

function Component({ data: elements }: Props) {
	const { translate } = useTranslation();

	return (
		<>
			{elements.map(element => {
				return (
					<div key={element.type}>
						<Table.Responsive horizontalScroll fullHeight>
							<Table fullWidth>
								<Table.Head>
									<Table.Row>
										<Table.Column colSpan={2}>
											{element.type && element.type.toUpperCase()}
										</Table.Column>
									</Table.Row>
								</Table.Head>
								<Table.Body>
									<Table.Row key={`${element.type}-table-row-${element.model}`}>
										<Table.Cell width={20}>
											{translate(
												({ analysis }) =>
													analysis.analyses.JADBio.models.model
											)}
										</Table.Cell>
										<Table.Cell>{element.model}</Table.Cell>
									</Table.Row>
									<Table.Row
										key={`${element.type}-table-row-${element.featureSelection}`}
									>
										<Table.Cell width={20}>
											{translate(
												({ analysis }) =>
													analysis.analyses.JADBio.models.featureSelection
											)}
										</Table.Cell>
										<Table.Cell>{element.featureSelection}</Table.Cell>
									</Table.Row>
									<Table.Row
										key={`${element.type}-table-row-${element.preprocessing}`}
									>
										<Table.Cell width={20}>
											{translate(
												({ analysis }) =>
													analysis.analyses.JADBio.models.preprocessing
											)}
										</Table.Cell>
										<Table.Cell>{element.preprocessing}</Table.Cell>
									</Table.Row>

									{element.performance && (
										<>
											<Table.Row
												key={`${element.type}-table-row-performance`}
											>
												<Table.Cell colSpan={2}>
													<Typography.Paragraph fontweight={w => w.bold}>
														{translate(
															({ analysis }) =>
																analysis.analyses.JADBio.models
																	.performance
														)}
													</Typography.Paragraph>
												</Table.Cell>
											</Table.Row>

											{Object.keys(element.performance).map(
												performanceKey => (
													<Table.Row
														key={`${element.type}-table-row-${performanceKey}`}
													>
														<Table.Cell width={20}>
															{performanceKey}
														</Table.Cell>
														<Table.Cell>
															{element.performance[performanceKey]}
														</Table.Cell>
													</Table.Row>
												)
											)}
										</>
									)}

									{element.signatures.flat(1).length > 0 && (
										<>
											<Table.Row key={`${element.type}-table-row-signatures`}>
												<Table.Cell colSpan={2}>
													<Typography.Paragraph
														fontweight={w => w.bold}
														paddingOffset={{
															top: 1
														}}
													>
														{translate(
															({ analysis }) =>
																analysis.analyses.JADBio.models
																	.signatures
														)}
													</Typography.Paragraph>
												</Table.Cell>
											</Table.Row>
											{element.signatures.flat(1).map(signature => (
												<Table.Row
													key={`${element.type}-table-row-signature-${signature}`}
												>
													<Table.Cell colSpan={2}>
														<Typography.Paragraph>
															{signature}
														</Typography.Paragraph>
													</Table.Cell>
												</Table.Row>
											))}
										</>
									)}
								</Table.Body>
							</Table>
						</Table.Responsive>
						<br />
						<br />
					</div>
				);
			})}
		</>
	);
}

export const JADBioTable = withMemo(Component);

import { getFormVariableNames } from 'helpers/forms';
import { buildVariableSetVariablesData, buildVariablesRichData } from 'helpers/variables';
import { useVariablesData } from 'hooks/store';
import { useMemo } from 'react';
import { Form } from 'store/data/forms';

export function useSortFormErroredFields(input: { setName?: string; form?: Form }) {
	const { setName, form } = input;

	const variablesData = useVariablesData({ initial: true });

	const scopeVariableNames = useMemo<string[]>(() => {
		let scopeVariableNames: string[] = [];

		if (form) {
			scopeVariableNames = getFormVariableNames(form);
		} else {
			if (setName) {
				const setVariablesData = buildVariableSetVariablesData({
					setName,
					variablesData
				});
				const { variableNamesOutsideSets: setVariableNamesOutsideSets } =
					buildVariablesRichData(setVariablesData);

				scopeVariableNames = setVariableNamesOutsideSets;
			} else {
				const { variableNamesOutsideSets } = buildVariablesRichData(variablesData);

				scopeVariableNames = variableNamesOutsideSets;
			}
		}

		return scopeVariableNames;
	}, [variablesData, setName, form]);

	function sortFormErroredFields(fieldNames: string[]) {
		const draftFieldNames = [...fieldNames];

		const sorted = draftFieldNames.sort(
			(a, b) => scopeVariableNames.indexOf(a) - scopeVariableNames.indexOf(b)
		);

		return sorted;
	}

	return { sortFormErroredFields };
}

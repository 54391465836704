import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { Modal } from 'components/UI/Modal';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { arrayUtils } from 'helpers/arrays';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { buildVariableCategoriesMap } from 'helpers/variables';
import { useTranslation, useVariablesData } from 'hooks/store';
import { useMutableState, usePaginate } from 'hooks/utils';
import { Dependant } from 'store/data/dependencies';
import { SelectItem } from 'types/index';

interface Props {
	dependant: Dependant;
	onClose(): void;
	onConfirm(categoryValues: string[]): void;
}

export function ConfigureFilteringValuesModal({ dependant, onClose, onConfirm }: Props) {
	const { translate } = useTranslation();

	const { variablesMap } = useVariablesData({ initial: true });

	const [selectedCategories, setSelectedCategories] = useMutableState(dependant.filteredValues);

	const variable = variablesMap[dependant.dependantVariableName];

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(getSelectedCategoryItems(), {
		threshold: 10,
		pageSize: 10
	});

	function handleCreate() {
		if (!isFormValid()) return;

		onConfirm(selectedCategories);
	}

	function isFormValid() {
		return selectedCategories.length > 0;
	}

	function onCheck(category: string) {
		setSelectedCategories(state => {
			if (state.includes(category)) return state.filter(c => c !== category);

			state.push(category);
		});
	}

	function onToggleAll(flag: boolean) {
		const categories = flag ? getVariableCategories() : [];

		setSelectedCategories(categories);
	}

	function getVariableCategories(): string[] {
		if (variable) return variable.categories.map(c => c.value);

		return [];
	}

	function getSelectCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];

		if (variable) {
			variable.categories.forEach(c => {
				const item: SelectItem = {
					label: c.label || c.value,
					value: c.value
				};

				items.push(item);
			});
		}

		return items;
	}

	function getSelectedCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];

		if (variable) {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);

			selectedCategories.forEach(value => {
				const category = categoriesMap[value];

				const item: SelectItem = {
					label: category.label || category.value,
					value: category.value
				};

				items.push(item);
			});
		}

		return items;
	}

	function handleMoveCategoryValue(input: { sourceIndex: number; destinationIndex: number }) {
		const { sourceIndex, destinationIndex } = input;

		if (sourceIndex === destinationIndex) return;

		const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
			pageIndex,
			pageSize
		});

		setSelectedCategories(state =>
			arrayUtils.move(state, absoluteIndexes.sourceIndex, absoluteIndexes.destinationIndex)
		);
	}

	return (
		<Modal
			title={translate(
				dict => dict.dependencies.dependantFiltering.configureFilteringValuesModal.title
			)}
			primary={{
				label: translate(dict => dict.buttons.confirm),
				disabled: !isFormValid(),
				onClick: handleCreate
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Dropdown
				label={translate(
					dict =>
						dict.dependencies.dependantFiltering.configureFilteringValuesModal
							.selectCategoryValues
				)}
				title={`${selectedCategories.length} ${
					selectedCategories.length === 1
						? translate(dict => dict.terms.value)
						: translate(dict => dict.terms.values)
				} ${translate(dict => dict.terms.selected)}`}
				button
			>
				<Flex paddingOffset={{ x: 1.2, y: 1.6 }}>
					<SelectMultiple
						items={getSelectCategoryItems()}
						onSelect={item => onCheck(item.value)}
						selectedItems={selectedCategories}
						onToggleAll={onToggleAll}
					/>
				</Flex>
			</Dropdown>

			<Spacer size={s => s.s} />

			<InputLabel
				label={translate(
					dict =>
						dict.dependencies.dependantFiltering.configureFilteringValuesModal
							.previewSelected.label
				)}
			/>

			{shouldPaginate && (
				<Flex marginOffset={{ bottom: 0.4 }}>
					<Pagination
						totalCountLabel={translate(dict => dict.terms.values)}
						pageIndex={pageIndex}
						pageSize={pageSize}
						pagesCount={pagesCount}
						changePage={changePage}
						changePageSize={changePageSize}
						totalCount={getSelectedCategoryItems().length}
					/>
				</Flex>
			)}

			<TagInput
				hint={translate(
					dict =>
						dict.dependencies.dependantFiltering.configureFilteringValuesModal
							.previewSelected.hint
				)}
				items={selectedCategoryItems}
				onMove={handleMoveCategoryValue}
				readOnly
				sortable
			/>
		</Modal>
	);
}

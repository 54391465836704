import {
	AnalysisV2,
	//////////////////
	CorrelationsAnalysis,
	PlotNumericAnalysisV2,
	TimeCourseAnalysisV2,
	JADBioAnalysis,
	JADBioAnalysisModels,
	ComparePairedDataModels,
	NumberPlotXYAnalysis,
	ExploreAnalysisV2,
	CompareNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	DensityPlotAnalysisV2,
	CrosstabAnalysisV2,
	FrequenciesAnalysisV2,
	ComparePairedAnalysisV2,
	KaplanMeierAnalysisV2
} from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';

export function isAnalysisInputValid(analysis: AnalysisV2) {
	let valid = true;

	// FREQUENCIES
	if (isType(AnalysisType.FrequenciesV2)) {
		analysis = analysis as FrequenciesAnalysisV2;

		const { categoryVariable } = analysis.input.variables;

		const variableRefs = [categoryVariable?.name ?? ''];

		valid = areVariableRefsValid(variableRefs);
	}

	// EXPLORE V2
	if (isType(AnalysisType.ExploreV2)) {
		analysis = analysis as ExploreAnalysisV2;

		const variableRefs = analysis.input.variables.map(item => item?.name);

		valid = areVariableRefsValid(variableRefs) && !!variableRefs.length;
	}

	// COMPARE NUMERIC
	if (isType(AnalysisType.CompareNumericV2)) {
		analysis = analysis as CompareNumericAnalysisV2;

		const { categoryVariable, exploreVariable } = analysis.input.variables;

		if (!categoryVariable || !exploreVariable) {
			valid = false;
		} else {
			const variableRefs = [categoryVariable.name, exploreVariable.name];

			valid = areVariableRefsValid(variableRefs);
		}
	}

	// CROSSTAB
	if (isType(AnalysisType.CrosstabV2)) {
		analysis = analysis as CrosstabAnalysisV2;

		const { rowVariable, columnVariable } = analysis.input.variables;

		const variableRefs = [rowVariable?.name ?? '', columnVariable?.name ?? ''];

		valid = areVariableRefsValid(variableRefs);
	}

	// KAPLAN MEIER
	if (isType(AnalysisType.KaplanV2)) {
		analysis = analysis as KaplanMeierAnalysisV2;

		const {
			durationVariable, // numeric variable ref
			eventVariable, // category variable ref
			positiveEvent, // category variable ref value
			startDate, // date variable (1st)
			endDate // date variable (2nd)
		} = analysis.input.variables;

		valid =
			!!(eventVariable && endDate && startDate && positiveEvent.length) ||
			!!(durationVariable && eventVariable && positiveEvent.length);
	}

	// CORRELATIONS
	if (isType(AnalysisType.CorrelationsV2)) {
		analysis = analysis as CorrelationsAnalysis;

		const { xVariable, yVariable, groupVariables } = analysis.input.variables;
		if (!xVariable || !yVariable) {
			valid = false;
		} else {
			const variableRefs = [
				...(xVariable?.name ? [xVariable?.name] : []),
				...(yVariable?.name ? [yVariable?.name] : []),
				...(groupVariables.length ? [groupVariables[0]?.name] : [])
			];

			valid = areVariableRefsValid(variableRefs);
		}
	}
	// PLOT NUMERIC
	if (isType(AnalysisType.PlotNumericV2)) {
		analysis = analysis as PlotNumericAnalysisV2;

		const { categoryVariable, numericVariable } = analysis.input.variables;

		const variableRefs = [categoryVariable?.name ?? '', numericVariable?.name ?? ''];

		valid = areVariableRefsValid(variableRefs);
	}

	// DENSITY PLOT
	if (isType(AnalysisType.DensityPlotV2)) {
		analysis = analysis as DensityPlotAnalysisV2;

		const { numericVariable } = analysis.input.variables;

		const variableRefs = [numericVariable?.name ?? ''];

		valid = areVariableRefsValid(variableRefs);
	}

	// TIME COURSE
	if (isType(AnalysisType.TimeCourseV2)) {
		analysis = analysis as TimeCourseAnalysisV2;

		const { numericVariable, timeVariable } = analysis.input.variables;
		if (!numericVariable || !timeVariable) {
			valid = false;
		} else {
			const variableRefs = [
				...(numericVariable?.name ? [numericVariable?.name] : []),
				...(timeVariable?.name ? [timeVariable?.name] : [])
			];
			valid = areVariableRefsValid(variableRefs);
		}
	}

	// LOGISTIC REGRESSION
	if (isType(AnalysisType.LogisticRegressionV2)) {
		analysis = analysis as LogisticRegressionAnalysisV2;

		const { xVariable, yVariable, outcomes } = analysis.input.variables;

		const variableRefs = [xVariable?.name ?? '', yVariable?.name ?? ''];

		valid = areVariableRefsValid(variableRefs) && outcomes.length >= 1;
	}

	// COMPARE PAIRED
	if (isType(AnalysisType.ComparePairedV2)) {
		analysis = analysis as ComparePairedAnalysisV2;

		const {
			dataModel,
			variables: {
				beforeVariable,
				afterVariable,
				numericVariable,
				groupVariable,
				pairIdentifier
			}
		} = analysis.input;
		const variableRefs: string[] = [];

		if (dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT) {
			variableRefs.push(beforeVariable?.name ?? '', afterVariable?.name ?? '');
		}

		if (dataModel === ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT) {
			variableRefs.push(
				numericVariable?.name ?? '',
				groupVariable?.name ?? '',
				pairIdentifier?.name ?? ''
			);
		}

		if (dataModel === ComparePairedDataModels.USING_SERIES) {
			variableRefs.push(numericVariable?.name ?? '', groupVariable?.name ?? '');
		}

		if (!dataModel) return false;

		valid = areVariableRefsValid(variableRefs);
	}

	// NUMBER PLOT XY
	if (isType(AnalysisType.NumberPlotXY)) {
		analysis = analysis as NumberPlotXYAnalysis;

		const { x_value, y_value } = analysis.input.variables;

		const variableRefs = [x_value, y_value[0]];

		valid = areVariableRefsValid(variableRefs);
	}

	// JADBIO
	if (isType(AnalysisType.JADBio)) {
		analysis = analysis as JADBioAnalysis;

		const { model, variables } = analysis.input;

		const {
			// CLASSIFICATION MODEL
			classificationVariable,
			// SURVIVAL ANALYSIS MODEL
			eventVariableName,
			timeToEventVariableName,
			// REGRESSION MODEL
			regressionVariable
		} = variables;

		const isModelValid = model !== null;

		const isClassificationModel = model && model === JADBioAnalysisModels.Classification;
		const isSurvivalModel = model && model === JADBioAnalysisModels.SurvivalAnalysis;
		const isRegressionModel = model && model === JADBioAnalysisModels.Regression;

		const variableRefs: string[] = [];

		if (isClassificationModel) variableRefs.push(classificationVariable);
		if (isSurvivalModel) variableRefs.push(eventVariableName, timeToEventVariableName);
		if (isRegressionModel) variableRefs.push(regressionVariable);

		valid = isModelValid && variableRefs.length > 0 && areVariableRefsValid(variableRefs);
	}

	function isType(analysisType: AnalysisType) {
		return analysis.type === analysisType;
	}

	return valid;
}

function areVariableRefsValid(variableRefs: string[]) {
	return variableRefs.every(variableRef => variableRef !== '');
}

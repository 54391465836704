import { useState } from 'react';
import { isEqual } from 'lodash';

import {
	JADBioAnalysis,
	JADBioProbabilitiesDisplayType,
	JADBioProbabilitiesDisplayLabels
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { SelectItem } from 'types/index';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoMessage } from 'components/UI/InfoMessage';
import { useTranslation, useUpdateAnalysis } from 'hooks/store';
import { useDebounce } from 'hooks/utils';

interface Props {
	analysis: JADBioAnalysis;
}

export function JADBioConfigProbabilities({ analysis }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		input: { probabilitiesDisplayType }
	} = analysis;

	const [probabilitiesDisplayTypeDraft, setProbabilitiesDisplayTypeDraft] =
		useState(probabilitiesDisplayType);

	const JADBioProbabilitiesDisplayTypeItems: SelectItem[] = [
		{
			label: translate(
				() => JADBioProbabilitiesDisplayLabels[JADBioProbabilitiesDisplayType.BoxPlot]
			),
			value: JADBioProbabilitiesDisplayType.BoxPlot
		},
		{
			label: translate(
				() => JADBioProbabilitiesDisplayLabels[JADBioProbabilitiesDisplayType.DensityPlot]
			),
			value: JADBioProbabilitiesDisplayType.DensityPlot
		}
	];

	useDebounce(
		() => {
			if (!isEqual(probabilitiesDisplayType, probabilitiesDisplayTypeDraft)) {
				const updatedAnalysis: JADBioAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						probabilitiesDisplayType: probabilitiesDisplayTypeDraft
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[probabilitiesDisplayTypeDraft],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<>
			<CreatableSelect
				label={translate(({ analysis }) => analysis.analyses.JADBio.displayAs)}
				items={JADBioProbabilitiesDisplayTypeItems}
				value={JADBioProbabilitiesDisplayTypeItems.find(
					item => item.value === probabilitiesDisplayTypeDraft
				)}
				onValueSelected={value =>
					value &&
					setProbabilitiesDisplayTypeDraft(value as JADBioProbabilitiesDisplayType)
				}
				canClear={false}
			/>

			<InfoMessage
				message={translate(
					dict => dict.analysis.analyses.JADBio.descriptions.probabilities
				)}
			/>
		</>
	);
}

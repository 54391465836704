import { useEffect } from 'react';

import { useActivity, useIsProjectValid, useProjectId } from 'hooks/store';
import { useSelector, useDispatch } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	selectDashboardCards,
	selectDashboardElementsOrder,
	createDashboard,
	Dashboard
} from 'store/data/projectDashboard';
import { ApiDashboardCard, DashboardPermission, DashboardType } from 'api/data/projectDashboard';
// import { selectProjectId } from 'store/data/projects';
import { selectUsername } from 'store/auth';

interface Options {
	dashboardId: number;
	lazy?: boolean;
}

interface DataProps {
	cards: ApiDashboardCard[];
	elementsOrder: Array<Array<string[]>>;
}

export function useCreateDashboard(options?: Options): OperationResult<DataProps> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const elementsOrder = useSelector(state =>
		selectDashboardElementsOrder(state.data.projectDashboard)
	);
	const payload: Dashboard = {
		cards: useSelector(state => selectDashboardCards(state.data.projectDashboard)) ?? [],
		projectId: Number(useSelector(state => state.data.projects.projectId)),
		owner: useSelector(state => selectUsername(state.auth)) ?? '',
		description: '',
		label: '',
		permissions: DashboardPermission.write,
		type: DashboardType.project,
		userId: 'gabreal',
		dashboardId: 1,
		elementsOrder: [[]]
	};

	const { cards } = payload;

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_DASHBOARD, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && !cards && isProjectValid && !options?.lazy) {
			handler();
		}
	}, [loading, error, cards, isProjectValid]);

	function handler() {
		if (loading || !payload) return;
		if (error) resetError();

		dispatch(createDashboard(payload));
	}

	return [
		{
			loading,
			error,
			data: { cards: [], elementsOrder }
		},
		handler
	];
}

import { MediaQueries } from 'environment';
import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	width: 100%;

	@media ${MediaQueries.maxWidth.sm} {
		flex-direction: column;
	}
`;

export const HSpacer = styled.div`
	margin-right: 2.4rem;

	@media ${MediaQueries.maxWidth.sm} {
		margin-right: 0;
	}
`;

export const RowElement = styled.div`
	flex: 1;
`;

import { useMemo, useRef } from 'react';
import { useTranslation, useVariablesData, useVariablesDataSelectItems } from 'hooks/store';
import {
	DependenciesTableViewData,
	Dependency,
	DependencyOperators,
	TableDependency,
	SubRows
} from 'store/data/dependencies';
import { SelectItem, StringMap } from 'types/index';
import { CellProps, Column, ColumnInstance } from 'react-table';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons/Icon';
import { Tag } from 'components/UI/Tags/Tag';
import { Svgs } from 'environment';
import { mapColumnNameToValue } from './helpers';
import { ExpandableTable } from './ExpandableTable';
import { ColumnFilter } from './ColumnFilter/ColumnFilter';

type Operators = Record<DependencyOperators, string>;

interface Props {
	dependencies: Dependency[];
	getDependantVariablesSelectItems: (
		dependency: Dependency | TableDependency | SubRows
	) => SelectItem[];
	dependencySuppliersSelectItems: SelectItem[];
	context: string | null;
	scopeDependenciesData: { active: boolean; dependencies: Dependency[] };
	areRulesExpanded: boolean;
	openModal: boolean;
	onClose: () => void;
	collapseAllRules: () => void;
}

export function DependenciesTable({
	dependencies,
	getDependantVariablesSelectItems,
	dependencySuppliersSelectItems,
	context,
	scopeDependenciesData,
	areRulesExpanded,
	openModal,
	onClose,
	collapseAllRules
}: Props) {
	const { translate } = useTranslation();
	const tableRef = useRef<HTMLTableElement>(null);
	const variablesData = useVariablesData({ initial: true });
	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);

	const allColumnNames = [
		DependenciesTableViewData.supplierVariableName,
		DependenciesTableViewData.dependencyType,
		DependenciesTableViewData.operator,
		DependenciesTableViewData.supplierValueCondition,
		DependenciesTableViewData.dependantVariableName,
		DependenciesTableViewData.filteredValues,
		DependenciesTableViewData.description
	];

	const translatedColumn: StringMap = {
		[DependenciesTableViewData.supplierVariableName]: translate(
			({ dependencies }) => dependencies.builder.tableView.supplier
		),
		[DependenciesTableViewData.dependencyType]: translate(
			({ dependencies }) => dependencies.builder.tableView.dependencyType
		),
		[DependenciesTableViewData.operator]: translate(
			({ dependencies }) => dependencies.builder.tableView.operator
		),
		[DependenciesTableViewData.supplierValueCondition]: translate(
			({ dependencies }) => dependencies.builder.tableView.value
		),
		[DependenciesTableViewData.dependantVariableName]: translate(
			({ dependencies }) => dependencies.builder.tableView.dependantVariables
		),
		[DependenciesTableViewData.filteredValues]: translate(
			({ dependencies }) => dependencies.builder.tableView.result
		),
		[DependenciesTableViewData.description]: translate(
			({ dependencies }) => dependencies.builder.tableView.ruleDescription
		)
	};

	const tableDependencies = useMemo<TableDependency[]>(() => {
		const tableData: TableDependency[] = [];

		const existingObjects: any = {};

		for (const dependency of dependencies) {
			for (const dependant of dependency.dependantVariables) {
				const key = `${dependency.dependencyName}_${dependency.dependencyType}`;
				if (!existingObjects[key]) {
					const newObj: TableDependency = {
						dependencyName: dependency.dependencyName,
						dependantVariableName: dependant.dependantVariableName,
						dependencyType: dependency.dependencyType,
						filteredValues: dependant.filteredValues,
						dependantId: dependant.dependantId,
						operator: dependant.operator,
						supplierValueCondition: dependant.supplierValueCondition,
						supplierVariableName: dependency.supplierVariableName,
						description: dependency.description
					};

					if (dependency.dependantVariables.length > 1) {
						newObj.subRows = [];
					}

					existingObjects[key] = newObj;
					tableData.push(newObj);
				} else if (
					dependency.dependantVariables.length > 1 &&
					existingObjects[key].subRows
				) {
					existingObjects[key].subRows.push({
						dependencyName: dependency.dependencyName,
						dependantVariableName: dependant.dependantVariableName,
						dependencyType: dependency.dependencyType,
						filteredValues: dependant.filteredValues,
						dependantId: dependant.dependantId,
						operator: dependant.operator,
						supplierValueCondition: dependant.supplierValueCondition,
						supplierVariableName: dependency.supplierVariableName,
						description: dependency.description
					});
				}
			}
		}

		return tableData;
	}, [dependencies]);

	function parseResultCell(value: string[]): React.ReactNode {
		if (value.length <= 0) return '-';
		return (
			<Flex style={{ gap: '0.8rem' }} wrap>
				{value.map((val, i) => (
					<Tag key={`item-${val}-${i}`} title={val} active />
				))}
			</Flex>
		);
	}

	function parseDependencySupplierCell(value: string) {
		if (value.length <= 0) return '-';
		dependencySuppliersSelectItems.forEach(item => {
			if (value === item.value) {
				value = item.label;
			}
		});
		return value;
	}

	function parsedependantVariableNameCell(value: string) {
		if (value.length <= 0) return '-';
		dependencies.forEach(dependency => {
			const dependantVariableNameSelect = getDependantVariablesSelectItems(dependency);
			dependantVariableNameSelect.forEach(item => {
				if (value === item.value) {
					value = item.label;
				}
			});
		});

		return value;
	}
	function parseOperatorCell(value: string) {
		const translatedNumberOperator: Operators = {
			[DependencyOperators.LESS_THAN]: translate(
				dict => dict.dependencies.dependencyCondition.lessThan
			),
			[DependencyOperators.LESS_OR_EQUAL_TO]: translate(
				dict => dict.dependencies.dependencyCondition.lessOrEqualTo
			),
			[DependencyOperators.EQUAL_TO]: translate(
				dict => dict.dependencies.dependencyCondition.equals
			),
			[DependencyOperators.GREATER_OR_EQUAL_TO]: translate(
				dict => dict.dependencies.dependencyCondition.greaterOrEqualTo
			),
			[DependencyOperators.GREATER_THAN]: translate(
				dict => dict.dependencies.dependencyCondition.greaterThan
			)
		};

		const numberOperatorSelectItems = [
			{
				label: translatedNumberOperator[DependencyOperators.LESS_THAN],
				value: DependencyOperators.LESS_THAN
			},
			{
				label: translatedNumberOperator[DependencyOperators.LESS_OR_EQUAL_TO],
				value: DependencyOperators.LESS_OR_EQUAL_TO
			},
			{
				label: translatedNumberOperator[DependencyOperators.EQUAL_TO],
				value: DependencyOperators.EQUAL_TO
			},
			{
				label: translatedNumberOperator[DependencyOperators.GREATER_OR_EQUAL_TO],
				value: DependencyOperators.GREATER_OR_EQUAL_TO
			},
			{
				label: translatedNumberOperator[DependencyOperators.GREATER_THAN],
				value: DependencyOperators.GREATER_THAN
			}
		];

		const foundOperators = numberOperatorSelectItems.find(item => item.value === value) as {
			label: string;
			value: DependencyOperators;
		};

		return foundOperators;
	}
	function buildHeader(input: {
		title: string;
		column: ColumnInstance<TableDependency>;
		filter?: React.ReactNode;
	}) {
		const { title, column, filter } = input;

		return (
			<>
				<Flex
					align={a => a.center}
					css={`
						width: 100%;
						white-space: nowrap;

						.column-filter-icon {
							visibility: hidden;
						}

						:hover .column-filter-icon {
							visibility: visible;
						}
					`}
				>
					<Flex fullWidth>
						{title}

						<Icon
							style={{ opacity: column.isSorted ? 1 : 0 }}
							svg={column.isSortedDesc ? Svgs.ArrowDown : Svgs.ArrowUp}
							size={s => s.m}
							active
							propagate
						/>
					</Flex>
					{filter}
				</Flex>
			</>
		);
	}

	function buildDependenciesTableColumn(title: string): Column<TableDependency> {
		return {
			Header: ({ column }) =>
				buildHeader({
					title: translatedColumn[title],
					column,
					filter: (
						<ColumnFilter
							tableRef={tableRef}
							columnName={column.id}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							tableDependencies={tableDependencies}
							computePosition
						/>
					)
				}),

			accessor: mapColumnNameToValue(title),
			Cell: ({ value }: CellProps<TableDependency>) => {
				if (!value) return '';

				if (title === DependenciesTableViewData.filteredValues) {
					const parsedValue = parseResultCell(value);
					return parsedValue;
				}

				if (title === DependenciesTableViewData.supplierVariableName) {
					const parsedValue = parseDependencySupplierCell(value);
					return parsedValue;
				}

				if (title === DependenciesTableViewData.dependantVariableName) {
					const parsedValue = parsedependantVariableNameCell(value);
					return parsedValue;
				}

				if (title === DependenciesTableViewData.operator) {
					const parsedValue = parseOperatorCell(value);
					return value.length <= 0 ? '-' : parsedValue?.label;
				}

				return value;
			}
		};
	}

	const columns = useMemo<Column<TableDependency>[]>(() => {
		const expanderColumn = {
			id: 'expander',
			Header: '',
			Cell: ({ row }: CellProps<TableDependency>) => {
				if (row.canExpand) {
					return (
						<span
							{...row.getToggleRowExpandedProps({
								style: {
									paddingLeft: `${row.depth * 2}rem`
								}
							})}
							id="expander"
						>
							{
								<Icon
									size={s => s.s}
									svg={row.isExpanded ? Svgs.ArrowDown : Svgs.ArrowRight}
									onClick={e => {
										e.stopPropagation();
										row.toggleRowExpanded();
									}}
								/>
							}
						</span>
					);
				} else if (row.depth > 0) {
					return <span>{<Icon size={s => s.s} svg={Svgs.ChevronArrowCorner} />}</span>;
				}
				return null;
			}
		};

		const tableColumns = [
			expanderColumn,
			...allColumnNames.map(column => {
				return buildDependenciesTableColumn(column);
			})
		];
		return tableColumns;
	}, [allColumnNames]);

	return (
		<>
			<ExpandableTable
				tableRef={tableRef}
				areRulesExpanded={areRulesExpanded}
				scopeDependenciesData={scopeDependenciesData}
				columns={columns}
				dependencies={dependencies}
				tableDependencies={tableDependencies}
				dependencySuppliersSelectItems={dependencySuppliersSelectItems}
				getDependantVariablesSelectItems={getDependantVariablesSelectItems}
				context={context}
				openModal={openModal}
				onClose={onClose}
				collapseAllRules={collapseAllRules}
				parseOperatorCell={parseOperatorCell}
			/>
		</>
	);
}

import { useEffect } from 'react';

import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getTemplateRoles,
	TemplateRolesData,
	selectTemplateRolesData
} from 'store/data/roles';

interface Props {
	lazy?: boolean;
}

export function useTemplateRoles(options?: Props): OperationResult<TemplateRolesData> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};

	const data = useSelector(state => selectTemplateRolesData(state.data.roles));
	const fetched = useSelector(state => state.data.roles.templateRoles.fetched);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_TEMPLATE_ROLES);

	useEffect(() => {
		if (!loading && !error && !fetched && !lazy) {
			handler();
		}
	}, [loading, error, fetched, lazy]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getTemplateRoles());
	}

	return [{ data, loading, error, fetched }, handler];
}

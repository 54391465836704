import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useLeaveProject } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	projectId: string;
	onClose: () => void;
}

export function LeaveProjectModal({ projectId, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: leavingProject, error: errorLeavingProject }, leaveProject] =
		useLeaveProject();

	useCompletedAction(leavingProject, errorLeavingProject, onClose);

	function handleSubmit() {
		leaveProject(projectId);
	}

	const leaveProjectTitle = translate(dict => dict.leaveProject.leaveProject);
	const primaryWarningText = translate(dict => dict.leaveProject.warningText);
	const leaveProjectConfirmationQuestion = translate(dict => dict.leaveProject.warningQuestion);

	return (
		<Modal
			size={s => s.s}
			title={leaveProjectTitle}
			primary={{
				label: leaveProjectTitle,
				loading: leavingProject,
				warning: true,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{primaryWarningText}
				{'\n\n'}
				<b>{leaveProjectConfirmationQuestion}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

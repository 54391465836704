import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface JADBioWarningModalProps {
	onClose: () => void;
}

export function JADBioWarningModal({ onClose }: JADBioWarningModalProps) {
	const { translate } = useTranslation();

	return (
		<Modal
			title={translate(dict => dict.analysis.analyses.JADBio.warningModal.title)}
			primary={{
				label: translate(dict => dict.buttons.ok),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			visible
		>
			<Typography.Paragraph multiline>
				{translate(dict => dict.analysis.analyses.JADBio.warningModal.description)}
			</Typography.Paragraph>
		</Modal>
	);
}

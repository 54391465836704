import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

interface RecipientProps {
	isSelected?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
`;

export const ListHeader = styled(Typography.H5)`
	width: 100%;
	margin-bottom: 1rem;
	margin-top: 3rem;
`;

export const ReceipientsList = styled.div`
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	> :nth-child(odd) {
		background-color: ${Colors.background.disabled};
	}
`;

export const Receipient = styled.div<RecipientProps>`
	height: 6rem;
	display: flex;
	font-weight: 600;
	align-items: center;
	justify-content: space-between;
	padding-left: 1rem;
	border-bottom: 0.1rem solid ${Colors.gray.lightest};

	background-color: ${({ isSelected }) =>
		(isSelected && Colors.primary.disabled + ' !important') || ''};
`;

import { useEffect, useState } from 'react';
import { Operation } from 'hooks/store/types';
import { AccountDetails, ActionTypes, updateAccount } from 'store/account/subscription';
import { DeepPartial } from 'types/index';
import { useDispatch, usePrevious } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useAlerts } from 'hooks/ui';

export function useUpdateAccount(): Operation<
	(
		accountDetails: DeepPartial<AccountDetails>,
		updateNotification?: string,
		isUserActivating?: boolean
	) => void
> {
	const dispatch = useDispatch();
	const { setNotification } = useAlerts();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_ACCOUNT);
	const [notificationMessage, setNotificationMessage] = useState<string | null>(null);

	const wasUpdating = usePrevious(loading);
	useEffect(() => {
		if (error) return setNotificationMessage(null);

		if (notificationMessage && wasUpdating && !loading && !error) {
			setNotification({
				message: notificationMessage
			});
			setNotificationMessage(null);
		}
	}, [loading, error, notificationMessage]);

	function handler(
		accountDetails: DeepPartial<AccountDetails>,
		updateNotification?: string,
		isUserActivating?: boolean
	) {
		dispatch(updateAccount(accountDetails, isUserActivating));
		if (updateNotification) setNotificationMessage(updateNotification);
	}

	return [{ loading, error }, handler];
}

import { entries, keys } from 'lodash';
import { CellProps, Column, usePagination, useTable } from 'react-table';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Flex } from 'components/UI/Flex';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useUpdateEntry, useVariablesData } from 'hooks/store';
import { Pagination, Table, Container, Wrapper } from './ConflictsModal.style';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { EntryValues, EntryValue, Entry, ConflictedData, EntryStatus } from 'store/data/entries';
import { ConflictsGroupRow } from './ConflictsGroupRow';
import { ConflictsVariableRow } from './ConflictsVariableRow';
import { buildVariablesDataLocation } from 'helpers/variables';
import { usePrevious } from 'hooks/utils';
import { VERSION_DATE_YEAR_TIME_FORMAT } from 'consts';
import { Colors } from 'environment';
import { IconSizes } from 'components/UI/Icons';
import { BooleanMap } from 'types/index';

export interface MergedFormValues {
	values: Entry;
	status?: EntryStatus;
}
interface Props {
	open?: boolean;
	conflictDraft: MergedFormValues;
	setConflictDraft: React.Dispatch<React.SetStateAction<MergedFormValues>>;
	conflictedData: ConflictedData;
	onClose: () => void;
	onContinue: (data: MergedFormValues) => void;
}

export type ConflictedVariable = {
	current: EntryValue;
	previous: EntryValue;
	variableName: string;
};

export type ConflictedGroup = {
	groupVariables: ConflictedVariable[];
	groupName: string;
};

export type TableData = ConflictedVariable | ConflictedGroup;

type FieldsChanged = {
	current: string[];
	previous: string[];
};

enum Option {
	keep,
	take,
	combined
}

export function ConflictsModal({
	open = false,
	conflictDraft,
	setConflictDraft,
	conflictedData,
	onContinue,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const [{ loading }] = useUpdateEntry();

	// SYNC DRAFT STATE WHEN CONFLICTED STATE CHANGES (NEEDED ONLY IN RE-SUBMIT CONFLICTED FORM SCENARIO)
	useEffect(() => {
		const values = entries(conflictedData.values).reduce((acc, [key, val]) => {
			acc[key] = val.current;
			return acc;
		}, {} as Entry);
		setConflictDraft({
			values,
			status: conflictedData.statuses.current
		});
	}, [conflictedData]);

	const variablesData = useVariablesData();

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const [option, setOption] = useState(Option.keep);
	// RADIO GROUP SELECTION STATE
	const [fieldsChanged, setFieldsChanged] = useState<FieldsChanged>({
		current: keys(conflictedData.values),
		previous: []
	});

	useEffect(() => {
		if (fieldsChanged.current.length && fieldsChanged.previous.length)
			return setOption(Option.combined);
		if (fieldsChanged.current.length && !fieldsChanged.previous.length)
			return setOption(Option.keep);
		if (!fieldsChanged.current.length && fieldsChanged.previous.length)
			return setOption(Option.take);
	}, [fieldsChanged]);

	function handleSelectYourVersion() {
		setOption(Option.keep);
		const currentValues = entries(conflictedData.values).reduce<Entry>(
			(obj, [key, value]) => ({
				...obj,
				[key]: value.current
			}),
			{} as Entry
		);
		setConflictDraft({
			values: currentValues,
			...(conflictedData.statuses.current && { status: conflictedData.statuses.current })
		});

		// MARK CURRENT SELECTED STATE (for radio selection)
		setFieldsChanged({
			current: keys(currentValues),
			previous: []
		});
	}

	function handleSelectOtherVersion() {
		setOption(Option.take);
		const currentValues = entries(conflictedData.values).reduce<Entry>(
			(obj, [key, value]) => ({
				...obj,
				[key]: value.previous
			}),
			{} as Entry
		);
		setConflictDraft({
			values: currentValues,
			...(conflictedData.statuses.previous && { status: conflictedData.statuses.previous })
		});

		// MARK CURRENT SELECTED STATE (for radio selection)
		setFieldsChanged({
			current: [],
			previous: keys(currentValues)
		});
	}
	// TABLE LOGIC
	const tableRef = useRef<HTMLTableElement>(null);
	const [expandedRows, setExpandedRows] = useState<BooleanMap>({});

	function toggleExpandedRows(key: string) {
		setExpandedRows(prev => ({ ...prev, [key]: !prev[key] }));
	}

	const tableData = useMemo(() => {
		const newData: TableData[] = [];
		if (conflictedData.values)
			entries(conflictedData.values).forEach(([key, values]) => {
				const { current, previous } = values;
				const variableLocation = variablesLocation[key];
				if (variableLocation && variableLocation.groupName) {
					if (
						!newData.some(
							data =>
								'groupName' in data && data.groupName === variableLocation.groupName
						)
					) {
						newData.push({
							groupName: variableLocation.groupName,
							groupVariables: [
								{
									variableName: key,
									current,
									previous
								}
							]
						});
					} else {
						newData.forEach(group => {
							if (
								'groupName' in group &&
								group.groupName === variableLocation.groupName
							) {
								group.groupVariables.push({
									variableName: key,
									current,
									previous
								});
							}
						});
					}
				} else {
					newData.push({
						variableName: key,
						current,
						previous
					});
				}
			});
		if (
			conflictedData.statuses &&
			conflictedData.statuses.current !== conflictedData.statuses.previous
		) {
			newData.push({
				variableName: 'status_column',
				current: conflictedData.statuses.current?.variableName ?? '',
				previous: conflictedData.statuses.previous?.variableName ?? ''
			});
		}
		return newData;
	}, [conflictedData]);

	function parseChangeValue(value: EntryValue) {
		let parsedValue = '';

		// CATEGORY MULTIPLE VARIABLE - EXPLODE ARRAY
		if (Array.isArray(value)) {
			parsedValue = value.length ? value.toString() : translate(dict => dict.terms.empty);
		} else {
			parsedValue = (value as string) ?? translate(dict => dict.terms.empty);
		}

		return parsedValue;
	}

	function buildHeader(input: { title: string }) {
		const { title } = input;

		return (
			<>
				<Flex
					align={a => a.center}
					css={`
						width: 100%;
						white-space: nowrap;
					`}
				>
					<Typography.Paragraph fontweight={f => f.medium}>{title}</Typography.Paragraph>
				</Flex>
			</>
		);
	}

	function getVariableLabel(variableName: string) {
		const variable = variablesData.variablesMap[variableName];
		if (!variable) {
			return 'status';
		}

		if (variable) return variable.label;
	}

	function mapColumnNameToValue(title: string) {
		if (title === translate(dict => dict.dataset.modals.resolveConflictsModal.fields)) {
			return 'variableName';
		} else if (
			title.includes(translate(dict => dict.dataset.modals.resolveConflictsModal.yourVersion))
		) {
			return 'current';
		} else {
			return 'previous';
		}
	}

	function buildProjecsTableColumn(title: string): Column<TableData> {
		return {
			Header: () =>
				buildHeader({
					title: title
				}),
			accessor: mapColumnNameToValue(title),
			Cell: ({ value }: CellProps<TableData>) => {
				if (!value) return translate(dict => dict.entries.empty);
				if (title === translate(dict => dict.dataset.modals.resolveConflictsModal.fields))
					return getVariableLabel(value);
				return parseChangeValue(value);
			}
		};
	}

	const handleSelectFinalValues = useCallback((newValues: EntryValues) => {
		setConflictDraft(prev => ({
			...prev,
			values: {
				...prev.values,
				...newValues
			}
		}));

		const [key, value] = entries(newValues)[0];

		// check if previous or current selection was chosen to keep track of fields changed;
		if (conflictedData.values[key].current === value) {
			setFieldsChanged(state => ({
				current: [...state.current, key],
				previous: state.previous.filter(prev => prev !== key)
			}));
		} else {
			setFieldsChanged(state => ({
				current: state.current.filter(prev => prev !== key),
				previous: [...state.previous, key]
			}));
		}
	}, []);

	const handleSelectFinalStatus = useCallback((status: EntryStatus) => {
		setConflictDraft(prev => ({
			...prev,
			status
		}));

		// check if previous or current selection was chosen to keep track of fields changed;
		const value = status?.variableName;

		if (conflictedData.statuses.current?.variableName === value) {
			setFieldsChanged(state => ({
				current: [...state.current, ...(value ? value : [])],
				previous: state.previous.filter(prev => prev !== value)
			}));
		} else {
			setFieldsChanged(state => ({
				current: state.current.filter(prev => prev !== value),
				previous: [...state.previous, ...(value ? value : [])]
			}));
		}
	}, []);

	const allColumnNames = useMemo(() => {
		const columnNames: string[] = [
			translate(dict => dict.dataset.modals.resolveConflictsModal.fields),
			`${translate(dict => dict.dataset.modals.resolveConflictsModal.yourVersion)}${format(
				new Date(),
				VERSION_DATE_YEAR_TIME_FORMAT
			)}`
		];
		if (conflictedData.metadata)
			columnNames.push(
				`${conflictedData.metadata.user}${translate(
					dict => dict.dataset.modals.resolveConflictsModal.otherVersion
				)}${format(
					new Date(
						conflictedData.metadata.date.includes('Z')
							? conflictedData.metadata.date.slice(0, -1)
							: (conflictedData.metadata.date as string)
					),
					VERSION_DATE_YEAR_TIME_FORMAT
				)}`
			);
		return columnNames;
	}, [conflictedData.metadata]);

	const prevColumnNames = usePrevious(allColumnNames);
	const tableColumns = useMemo(
		() => allColumnNames.map(column => buildProjecsTableColumn(column)),
		[allColumnNames !== prevColumnNames]
	);

	const {
		page,
		headerGroups,
		pageOptions,
		getTableProps,
		getTableBodyProps,
		prepareRow,
		gotoPage,
		state
	} = useTable(
		{
			columns: tableColumns,
			data: tableData,
			initialState: {
				pageIndex: 0,
				pageSize: 10
			},
			autoResetPage: false,
			autoResetSortBy: false
		},
		usePagination
	);

	function handleChangePageIndex(pageIndex: number) {
		gotoPage(pageIndex);
	}

	const conflictsCount =
		keys(conflictedData.statuses).length + keys(conflictedData.values).length;

	return (
		<Modal
			visible={open}
			title={translate(dict => dict.dataset.modals.resolveConflictsModal.title)}
			primary={{
				label: translate(dict => dict.buttons.save),
				onClick: () => onContinue(conflictDraft),
				loading
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.full}
			enterAsPrimaryOnClick
			close
			headerStyle={{ backgroundColor: Colors.background.disabled }}
			closeIconSize={IconSizes.l}
		>
			<Flex style={{ paddingLeft: 56, paddingRight: 56 }} justify={j => j.center} column>
				<Typography.H6>
					{`${conflictsCount} ${
						conflictsCount === 1
							? translate(dict => dict.dataset.modals.resolveConflictsModal.conflict)
							: translate(dict => dict.dataset.modals.resolveConflictsModal.conflicts)
					} (${
						pageOptions.length !== state.pageIndex + 1
							? (state.pageIndex + 1) * 10
							: conflictsCount
					} ${translate(dict => dict.terms.of)}
				${conflictsCount})`}
				</Typography.H6>
				<Spacer size={s => s.s} />
				<Typography.Paragraph>
					{translate(dict => dict.dataset.modals.resolveConflictsModal.info)}
				</Typography.Paragraph>
				<Spacer size={s => s.m} />
				<Flex justify={j => j.between} align={a => a.center}>
					<Typography.Caption>
						{translate(dict => dict.dataset.modals.resolveConflictsModal.showing)}
						{pageOptions.length !== state.pageIndex + 1
							? (state.pageIndex + 1) * 10
							: conflictsCount}
						{translate(
							dict => dict.dataset.modals.resolveConflictsModal.conflictsValues
						)}
					</Typography.Caption>
					<Pagination
						totalCount={conflictsCount}
						totalCountLabel={translate(dict => dict.pagination.entries)}
						pageIndex={state.pageIndex}
						pageSize={state.pageSize}
						pagesCount={pageOptions.length}
						changePage={handleChangePageIndex}
						simpleVersion
					/>
				</Flex>

				<Spacer size={s => s.xs} />
				<Container>
					<Wrapper>
						<Table tableRef={tableRef} {...getTableProps()} fullWidth hoverEffect>
							<Table.Head>
								{headerGroups.map(headerGroup => (
									<Table.Row
										{...headerGroup.getHeaderGroupProps()}
										key={headerGroup.getHeaderGroupProps().key}
									>
										{headerGroup.headers.map(column => (
											<Table.Column
												{...column.getHeaderProps([
													{
														...column.customHeaderProps
													}
												])}
												key={column.getHeaderProps().key}
											>
												{column.render('Header')}
											</Table.Column>
										))}
									</Table.Row>
								))}
							</Table.Head>

							<Table.Body {...getTableBodyProps()}>
								{conflictDraft &&
									page.map(row => {
										prepareRow(row);
										const variable = row.original;

										if ('groupName' in variable) {
											return (
												<ConflictsGroupRow
													key={`group_${variable.groupName}`}
													groupData={variable}
													expandedRowsData={{
														expanded: expandedRows[variable.groupName],
														onExpand: () =>
															toggleExpandedRows(variable.groupName)
													}}
													groupsMap={variablesData.groupsMap}
													renderVariableRow={variable => (
														<ConflictsVariableRow
															key={`group_variable_${variable.variableName}`}
															row={row}
															variable={variable}
															finalValues={conflictDraft}
															setFinalValues={handleSelectFinalValues}
															setStatus={handleSelectFinalStatus}
															getVariableLabel={getVariableLabel}
															isNested
														/>
													)}
												/>
											);
										}

										return (
											<ConflictsVariableRow
												key={`variable_${variable.variableName}`}
												row={row}
												variable={variable}
												finalValues={conflictDraft}
												setFinalValues={handleSelectFinalValues}
												setStatus={handleSelectFinalStatus}
												getVariableLabel={getVariableLabel}
											/>
										);
									})}
							</Table.Body>
						</Table>
					</Wrapper>
				</Container>
				<Spacer size={s => s.m} />

				<RadioButton
					selected={option === Option.keep}
					onSelect={handleSelectYourVersion}
					label={translate(dict => dict.dataset.modals.resolveConflictsModal.keep.title)}
				/>
				<Typography.Caption>
					{translate(dict => dict.dataset.modals.resolveConflictsModal.keep.infoMessage)}
				</Typography.Caption>
				<Spacer size={s => s.s} />
				<RadioButton
					selected={option === Option.take}
					onSelect={handleSelectOtherVersion}
					label={translate(dict => dict.dataset.modals.resolveConflictsModal.take.title)}
				/>
				<Typography.Caption>
					{translate(dict => dict.dataset.modals.resolveConflictsModal.take.infoMessage)}
				</Typography.Caption>
			</Flex>
		</Modal>
	);
}

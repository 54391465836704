import { get } from 'lodash';

import { Dictionary } from 'environment/i18n/Dictionary';
import { useSelector } from 'hooks/utils';
import { selectTranslations } from 'store/ui/i18n';
import { GenericMap } from 'types/index';

export type TranslateFunction = (
	getKey: (dict: typeof Dictionary) => string,
	returnKey?: boolean,
	options?: Record<string, string | number> | undefined
) => any;

export function useTranslation(): { translate: TranslateFunction } {
	const translations = useSelector(state => selectTranslations(state.ui.i18n));

	function translate(
		getKey: (dict: typeof Dictionary) => string,
		returnKey = false,
		options?: GenericMap<string | number>
	) {
		const key = getKey(Dictionary);
		let translatedValue = get(translations, key);
		if (options && translatedValue) {
			Object.entries(options).forEach(entry => {
				translatedValue = translatedValue.replaceAll(`\${${entry[0]}}`, entry[1]);
			});
		}
		return translatedValue || (returnKey ? key : undefined);
	}

	return { translate };
}

/**
 * Works similar to useTranslation but does not neet to respect hook rules so can be used anywhere
 *
 * @example
 * getTranslation(dict => dict dict.buttons.add);
 *
 * > "Add"
 */
const translations = JSON.parse(localStorage.getItem('translations') as string);
export function getTranslation(
	getKey: (dict: typeof Dictionary) => string,
	returnKey = false,
	options?: GenericMap<string | number>
) {
	const key = getKey(Dictionary);
	let translatedValue = get(translations, key);
	if (options && translatedValue) {
		Object.entries(options).forEach(entry => {
			translatedValue = translatedValue.replaceAll(`\${${entry[0]}}`, entry[1]);
		});
	}
	return translatedValue || (returnKey ? key : undefined);
}

import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'types/navigation';
import { Suspend } from 'components/UI/Suspend';
import { AddEditForm } from 'components/Dataset';
import { Colors, Svgs } from 'environment';
import {
	deleteAllLocalEntryFilesAction,
	getLatestEntry,
	resetCreateEntryDraftAction
} from 'store/data/entries';
import { Container, ScrollToTopIcon } from './AddEditEntryPage.style';
import { useDispatch, useEffectOnce, usePrevious } from 'hooks/utils';
import { useNavigation, useRouteMatch } from 'hooks/navigation';
import {
	useStatuses,
	useDependencies,
	useSeriesEntriesCount,
	useCollaborators,
	useEntriesForm,
	useVariables,
	useForms,
	useFormId,
	useEntryId,
	useProjectId,
	useIsProjectValid,
	useEntries
} from 'hooks/store';
import { ExpandGroupsProvider } from 'contexts';

export function AddEditEntryPage() {
	const dispatch = useDispatch();
	const { replace, routes, promOrProject } = useNavigation();
	const [{ fetched: areEntriesFetched, loading: loadingEntries }] = useEntries();
	const { entryId: urlEntryId } = useParams<{ entryId: string }>();

	const formTopRef = useRef<HTMLDivElement>(null);

	const isEdit = useRouteMatch([ROUTES.UpdateEntry, ROUTES.UpdatePromEntry]);
	const isOnCreateRoute = useRouteMatch([ROUTES.CreateEntry, ROUTES.CreatePromEntry]);

	/**
	 * NEEDS TO BE HERE FOR WHEN THE USER REFRESHES THE PAGE ON AN ENTRY
	 * THE STATUS COMPONENT NEEDS STATUSES TO BE FETCHED IN ORDER TO BE RENDERED
	 */
	useStatuses();

	/**
	 * NEEDS TO BE HERE TO FETCH SERIES ENTRIES COUNT
	 */
	useSeriesEntriesCount({ lazy: !isEdit });

	/**
	 * NEEDS TO BE HERE TO FETCH ORGANIZATIONS AND APPLY PERMISSIONS
	 */
	useCollaborators();

	/**
	 * NEEDS TO BE HERE TO APPLY DEPENDENCY RULES OVER THE ENTRY FIELDS
	 */
	const [, getDependencies] = useDependencies({ lazy: true });

	const { values: initialValues, validationSchema, files } = useEntriesForm();

	const [
		{
			data: { variables },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true });

	useEffect(() => {
		if (areVariablesFetched) {
			getDependencies();
		}
	}, [areVariablesFetched]);

	const [{ data: forms, loading: loadingForms, fetched: areFormsFetched }] = useForms();

	const [formId, setFormId] = useFormId();
	const [entryId, setEntryId] = useEntryId();
	const [projectId] = useProjectId();

	const isProjectValid = useIsProjectValid();

	const [ref, inView] = useInView({
		threshold: 0,
		rootMargin: '0px 0px -110px 0px'
	});

	useEffectOnce(() => {
		return () => {
			setFormId(null);
			setEntryId(null);
			// remove all local entry files when component unmounts
			dispatch(deleteAllLocalEntryFilesAction());

			if (isOnCreateRoute) dispatch(resetCreateEntryDraftAction());
		};
	});

	// fetch initial entry
	useEffect(() => {
		if (projectId && isProjectValid && areVariablesFetched && entryId) {
			dispatch(getLatestEntry());
		}
	}, [projectId, isProjectValid, areVariablesFetched, entryId]);

	const prevEntryId = usePrevious(entryId);
	const prevUrlEntryId = usePrevious(urlEntryId);
	useEffect(() => {
		if (projectId === null) return;

		const entryIdChanged = prevEntryId !== undefined && prevEntryId !== entryId;
		const urlEntryIdChanged = prevUrlEntryId !== undefined && prevUrlEntryId !== urlEntryId;

		// Set `entryId` on reload
		if (urlEntryId && entryId === null) return setEntryId(urlEntryId);

		// Sync `urlEntryId` when `entryId` changes
		if (entryIdChanged && entryId && !urlEntryIdChanged) {
			// DEBOUNCE IN JS CALL STACK
			const timeout = setTimeout(() => {
				replace(routes[promOrProject].dataset.update(projectId, entryId));
			}, 100);

			return () => clearTimeout(timeout);
		}
	}, [entryId, urlEntryId, projectId]);

	// SELECT THE FIRST FORM ON-LOAD (IF ANY)
	useEffect(() => {
		if (!areFormsFetched) return;

		const firstFormId = forms.find(form => form.active)?.id;

		// CUSTOM FORM WAIT UNTIL FORM ID IS SET DEBOUNCE IT WITH STATE
		if (firstFormId && firstFormId !== formId) {
			setFormId(firstFormId);
		}
	}, [forms, areFormsFetched]);

	/*
		REDIRECT TO ENTRIES LIST IF NO VARIABLES 
	*/
	useEffect(() => {
		const shouldRedirect = areVariablesFetched && variables.length === 0;

		if (shouldRedirect && projectId) {
			replace(routes[promOrProject].dataset.view(projectId));
		}
	}, [variables, areVariablesFetched]);

	function scrollToTop() {
		if (formTopRef.current) {
			formTopRef.current.scrollIntoView({
				block: 'start',
				behavior: 'smooth'
			});
		}
	}

	const loading = loadingVariables || loadingForms || loadingEntries;
	const immediate = !(
		areVariablesFetched &&
		areFormsFetched &&
		(isEdit ? areEntriesFetched : true)
	);

	// scroll to top on page close
	useEffect(() => {
		return scrollToTop();
	}, [entryId]);

	return (
		<Suspend loading={loading} immediate={immediate}>
			<ExpandGroupsProvider>
				<Container>
					<div ref={ref} />
					<div ref={formTopRef} />

					<AddEditForm
						initialValues={initialValues}
						validationSchema={validationSchema}
						files={files}
						isEdit={isEdit}
					/>

					{!inView && (
						<ScrollToTopIcon
							svg={Svgs.ChevronUp}
							colors={{
								background: Colors.background.disabled,
								hover: Colors.primary.normal,
								hoverBackground: Colors.primary.disabled
							}}
							onClick={scrollToTop}
						/>
					)}
				</Container>
			</ExpandGroupsProvider>
		</Suspend>
	);
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { setFormElementOrientation, SetFormElementOrientationAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormElementOrientation(): LocalOperation<
	(payload: ActionPayload<SetFormElementOrientationAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormElementOrientationAction>) {
		dispatch(setFormElementOrientation(payload));
	}

	return handler;
}

import styled from 'styled-components';

import { Colors } from 'environment';

export const BulletPoint = styled.div`
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	margin-right: 0.5rem;
	background: ${Colors.text.main};
`;

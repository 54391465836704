import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	height: 4rem;
	position: fixed;
	bottom: 7rem;
	display: flex;
	align-items: center;
	padding: 0 2rem;
	background: ${Colors.text.main};
	border-radius: 0.4rem;

	> :first-child {
		margin-right: 1rem;
	}

	> :nth-child(2) {
		margin-right: 1rem;
	}
`;

export const Text = styled.p`
	font-size: 1.4rem;
	color: ${Colors.background.disabled};
`;

import { EntryFilter } from 'api/data/filters';
import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	CreateDependenciesFilterAction,
	createDependenciesFilter,
	selectDependenciesActiveFilters,
	deleteDependenciesFilters,
	deleteDependenciesFilter,
	updateDependenciesFilter,
	UpdateDependenciesFilterAction,
	InvalidateDependenciesFilterAction,
	invalidateDependenciesFilter,
	selectDependenciesActiveFilterIds,
	selectDependenciesInvalidFilterIds,
	selectDependenciesAreFiltersValid,
	selectDependenciesFiltersById
} from 'store/data/filters';
import { ActionPayload } from 'store/types';
import { GenericMap } from 'types/index';

interface Data {
	activeFilterIds: string[];
	invalidFilterIds: string[];
	dependenciesFilters: EntryFilter[];
	filtersById: GenericMap<EntryFilter>;
	areFiltersValid: boolean;
	areFiltersActive: boolean;
	areFiltersOpen?: boolean;
}

interface Handlers {
	createDependenciesFilter: (payload: ActionPayload<CreateDependenciesFilterAction>) => void;
	updateDependenciesFilter: (payload: ActionPayload<UpdateDependenciesFilterAction>) => void;
	deleteDependenciesFilter: (filterId: string) => void;
	deleteDependenciesFilters: (filterIds: string[]) => void;
	invalidateDependenciesFilter: (
		payload: ActionPayload<InvalidateDependenciesFilterAction>
	) => void;
}

export function useDependenciesFilters(): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const activeFilterIds = useSelector(state =>
		selectDependenciesActiveFilterIds(state.data.filters)
	);
	const invalidFilterIds = useSelector(state =>
		selectDependenciesInvalidFilterIds(state.data.filters)
	);
	const dependenciesFilters = useSelector(state =>
		selectDependenciesActiveFilters(state.data.filters)
	);

	const filtersById = useSelector(state => selectDependenciesFiltersById(state.data.filters));
	const areFiltersValid = useSelector(state =>
		selectDependenciesAreFiltersValid(state.data.filters)
	);
	const areFiltersOpen = useSelector(state => state.data.filters.dependencies.open);
	const areFiltersActive = activeFilterIds.length > 0;

	const data = {
		activeFilterIds,
		invalidFilterIds,
		dependenciesFilters,
		filtersById,
		areFiltersValid,
		areFiltersActive,
		areFiltersOpen
	};

	const handlers = {
		createDependenciesFilter: (payload: ActionPayload<CreateDependenciesFilterAction>) =>
			dispatch(createDependenciesFilter(payload)),
		updateDependenciesFilter: (payload: ActionPayload<UpdateDependenciesFilterAction>) =>
			dispatch(updateDependenciesFilter(payload)),
		deleteDependenciesFilter: (filterId: string) =>
			dispatch(deleteDependenciesFilter(filterId)),
		deleteDependenciesFilters: (filterIds: string[]) =>
			dispatch(deleteDependenciesFilters(filterIds)),
		invalidateDependenciesFilter: (
			payload: ActionPayload<InvalidateDependenciesFilterAction>
		) => dispatch(invalidateDependenciesFilter(payload))
	};

	return [data, handlers];
}

import { useSelector, useDispatch } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	setDashboardId,
	selectDashboardId,
	DashboardsId,
	SetDashboardIdAction
} from 'store/data/projectDashboard';
import { ActionPayload } from 'store/types';

export function useDashboardId(): LocalOperationResult<
	DashboardsId,
	(dashboardId: ActionPayload<SetDashboardIdAction>['dashboardId']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectDashboardId(state.data.projectDashboard));

	function handler(dashboardId: ActionPayload<SetDashboardIdAction>['dashboardId']) {
		dispatch(setDashboardId({ dashboardId }));
	}

	return [data, handler];
}

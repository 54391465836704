import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputType } from 'types/index';

import { FormFields } from './EditSubscriptionUserModal';

import { HSpacer, Row, RowElement } from './EditEnterpriseUserForm.style';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { formatPhoneNumber } from 'helpers/auth';
import { useOrganizations, useSubscription, useTranslation } from 'hooks/store';
import { AddSubscriptionUser } from 'store/account/subscription';

interface Props {
	onResetPassword: () => void;
}

export function EditEnterpriseUserForm({ onResetPassword }: Props) {
	const { translate } = useTranslation();

	const {
		register,
		setValue,
		control,
		formState: { errors }
	} = useFormContext<FormFields>();

	const [{ data: organizations, loading: loadingOrganizations }] = useOrganizations();

	const [
		{
			data: {
				subscriptionTypes: { isLedidiEnterprise }
			}
		}
	] = useSubscription();

	function handleValue(name: keyof Omit<AddSubscriptionUser, 'licenceModelId'>, value: string) {
		setValue(name, value, { shouldDirty: true });
	}

	function handlePhoneNumber(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		handleValue('phoneNumber', formatPhoneNumber(e.target.value));
	}

	const [searchTerm, setSearchTerm] = useState('');

	const isSearchTermValid = searchTerm.trim().length > 0;

	return (
		<>
			<Typography.H6>
				{translate(dict => dict.accountUM.editSubscriptionUser.personalDetailsSubheader)}
			</Typography.H6>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('userFirstName')}
					type={InputType.Text}
					error={errors.userFirstName?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.firstName)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
				/>
				<HSpacer />
				<Input
					{...register('userSirName')}
					type={InputType.Text}
					error={errors.userSirName?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.lastName)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
				/>
			</Row>

			<Spacer size={s => s.s} />

			<Row>
				<Input
					{...register('emailAddress')}
					type={InputType.Text}
					error={errors.emailAddress?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.emailAddress)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
					disabled
				/>
				<HSpacer />
				<Input
					{...register('phoneNumber')}
					type={InputType.Text}
					error={errors.phoneNumber?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.phoneNumber)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					onChange={handlePhoneNumber}
				/>
			</Row>
			<Spacer size={s => s.s} />

			<Row>
				<Controller
					control={control}
					name="country"
					render={({ field: { value } }) => (
						<CountrySelector
							value={value}
							label={translate(dict => dict.accountUM.userDetails.countryOfResidence)}
							placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
							onSelect={country => handleValue('country', country?.ISO_2 ?? '')}
						/>
					)}
				/>

				<HSpacer />
				<Input
					{...register('city')}
					type={InputType.Text}
					error={errors.city?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.city)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
				/>
			</Row>

			<Spacer size={s => s.m} />
			<Typography.H6>
				{translate(dict => dict.accountUM.editSubscriptionUser.workDetailsSubheader)}
			</Typography.H6>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('position')}
					type={InputType.Text}
					error={errors.position?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.position)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
				/>
				<HSpacer />
				<Input
					{...register('department')}
					type={InputType.Text}
					error={errors.department?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.department)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
				/>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<RowElement>
					<Controller
						control={control}
						name="workplace"
						render={({ field: { value } }) => (
							<CreatableSelect
								value={value ? { label: value, value } : null}
								defaultCustomValue={value ? value : undefined}
								label={translate(dict => dict.accountUM.userDetails.workplace)}
								error={errors.workplace?.message ?? ''}
								disabled={
									!organizations || loadingOrganizations || isLedidiEnterprise
								}
								items={
									organizations && isSearchTermValid && searchTerm.length > 2
										? organizations.map(org => ({
												value: org.organizationName,
												label: org.organizationName
										  }))
										: []
								}
								onInputChange={newValue => setSearchTerm(newValue)}
								onValueSelected={value => handleValue('workplace', value ?? '')}
								scrollIntoView
								allowCreate
								required
							/>
						)}
					/>
				</RowElement>
				<HSpacer />
				<RowElement />
			</Row>

			<Spacer size={s => s.m} />
			<Typography.H6>
				{translate(dict => dict.accountUM.editSubscriptionUser.passwordSubheader)}
			</Typography.H6>
			<Spacer size={s => s.s} />
			<Button
				variant={v => v.link}
				title={translate(dict => dict.admin.users.details.resetPassword)}
				paddingOffset={{ all: 0 }}
				onClick={onResetPassword}
			/>

			<Spacer size={s => s.s} />
		</>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	ProjectFilesListFilters,
	setProjectFilesFilter,
	setProjectFilesDateFilter,
	resetProjectFilesFilter,
	resetAllProjectFilesFilters,
	SetProjectFilesFilterAction,
	SetProjectFilesDateFilterAction,
	ResetProjectFilesFilterAction
} from 'store/data/documents';
import { ActionPayload } from 'store/types';

interface Handlers {
	setFilter: (payload: ActionPayload<SetProjectFilesFilterAction>) => void;
	setDateFilter: (payload: ActionPayload<SetProjectFilesDateFilterAction>) => void;
	resetFilter: (payload: ActionPayload<ResetProjectFilesFilterAction>) => void;
	resetAllFilters: () => void;
}

export function useDocumentsListFilters(): LocalOperationResult<ProjectFilesListFilters, Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.documents.filters);

	function setFilter(payload: ActionPayload<SetProjectFilesFilterAction>) {
		dispatch(setProjectFilesFilter(payload));
	}

	function setDateFilter(payload: ActionPayload<SetProjectFilesDateFilterAction>) {
		dispatch(setProjectFilesDateFilter(payload));
	}

	function resetFilter(payload: ActionPayload<ResetProjectFilesFilterAction>) {
		dispatch(resetProjectFilesFilter(payload));
	}

	function resetAllFilters() {
		dispatch(resetAllProjectFilesFilters());
	}

	const handlers = {
		setFilter,
		setDateFilter,
		resetFilter,
		resetAllFilters
	};

	return [data, handlers];
}

import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Title } from 'components/Login/styles';
import { LoginSteps } from 'store/auth';
import { ActionTypes } from 'store/account/subscription';
import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useLogin, useLoginStep, useLoginErrors, useActivity } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

export function LoginForm() {
	const { translate } = useTranslation();

	const [{ loading: loggingIn }, login] = useLogin();
	const [_, setLoginStep] = useLoginStep();
	const [
		{
			hasErrors: hasLoginErrors,
			errors: { credentials: credentialsError }
		},
		resetLoginErrors
	] = useLoginErrors();

	const [usernameError, setUsernameError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	const [{ loading: loadingAccount }] = useActivity(ActionTypes.GET_ACCOUNT);

	const loading = loggingIn || loadingAccount;

	const validationSchema = yup.object({
		username: yup
			.string()
			.trim()
			.required(translate(dict => dict.validation.username.required))
			.test(
				'no-spaces',
				translate(dict => dict.validation.username.noSpaces),
				value => !value?.match(/\s/)
			),
		password: yup.string().required(translate(dict => dict.validation.password.required))
	});

	const { Form, handleSubmit, handleTrimOnBlur, register, errors, isDirty } = useReactForm({
		initialValues: { username: '', password: '' },
		validationSchema
	});

	const handleFormSubmit = handleSubmit(({ username, password }) => {
		if (isDirty && !loading && !hasLoginErrors) login(username, password);
	});

	const handleClearError = useCallback(() => {
		if (hasLoginErrors) resetLoginErrors();
	}, [hasLoginErrors]);

	const handleLoginButton = () => {
		setUsernameError(errors.username?.message ?? '');
		setPasswordError(errors.password?.message ?? '');
	};

	useEffect(() => {
		if (errors.username?.message !== usernameError) {
			setUsernameError('');
		}
		if (errors.password?.message !== passwordError) {
			setPasswordError('');
		}
		if (credentialsError) {
			setPasswordError(credentialsError);
		}
	}, [errors.username, errors.password, credentialsError]);

	return (
		<Form onSubmit={handleFormSubmit}>
			<Title>{translate(dict => dict.login.title)}</Title>

			<Input
				{...register('username', {
					onBlur: handleTrimOnBlur,
					onChange: handleClearError
				})}
				type={InputType.Text}
				label={translate(dict => dict.loginForm.username)}
				placeholder={translate(dict => dict.loginForm.usernamePlaceholder)}
				error={usernameError}
				autoFocus
			/>

			<Spacer size={s => s.m} />

			<Input
				{...register('password', {
					onChange: handleClearError
				})}
				type={InputType.Password}
				label={translate(dict => dict.loginForm.password)}
				placeholder={translate(dict => dict.loginForm.passwordPlaceholder)}
				error={passwordError}
			/>

			<Spacer size={s => s.m} />

			<Button
				type="submit"
				title={translate(dict => dict.loginForm.login)}
				loading={loading}
				onClick={!loading ? handleLoginButton : undefined}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Flex justify={j => j.center}>
				<Button
					variant={v => v.link}
					title={translate(dict => dict.loginForm.forgotPassword)}
					onClick={() =>
						setLoginStep({
							step: LoginSteps.forgotPassword
						})
					}
				/>
			</Flex>
		</Form>
	);
}

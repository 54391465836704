import { Colors } from 'environment';
import styled, { css } from 'styled-components';

export const ProjectWrapper = styled.div<{ backgroundColoredRow: boolean }>`
	padding: 0 2rem;
	display: flex;
	align-items: center;
	border-radius: 0.4rem;
	min-height: 4.4rem;

	${({ backgroundColoredRow }) =>
		!backgroundColoredRow &&
		css`
			background: ${Colors.background.disabled};
		`}
`;

import { Typography } from 'components/UI/Typography';
import { Modal } from 'components/UI/Modal';
import { useTranslation, useDeleteTemplate } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	templateId: string;
	onClose: (success?: boolean) => void;
}

export function DeleteTemplateModal({ templateId, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: deletingTemplate, error: errorDeletingTemplate }, deleteTemplate] =
		useDeleteTemplate();

	useCompletedAction(deletingTemplate, errorDeletingTemplate, () => onClose(true));

	function handleSubmit() {
		deleteTemplate(templateId);
	}

	return (
		<Modal
			title={translate(dict => dict.templates.deleteTemplate)}
			primary={{
				label: translate(dict => dict.buttons.delete),
				loading: deletingTemplate,
				warning: true,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{translate(dict => dict.templates.deleteTemplateHint)}
				{'\n\n'}
				<strong>{translate(dict => dict.templates.deleteTemplatePrompt)}</strong>
			</Typography.Paragraph>
		</Modal>
	);
}

import { createSelector } from 'reselect';
import { isEqual } from 'lodash';

import { selectParams } from 'store/utils';

import { State, Form, FormsFilters, FormsFilterShowOptions, FormsData, FormsById } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getForm({ byId, formId }: State) {
	if (formId && formId in byId) return byId[formId];
}

function getFormsByProjectId({ byProjectId, projectId }: State) {
	if (projectId && projectId in byProjectId) return byProjectId[projectId];
}

function getFormsById({ byId }: State) {
	return byId;
}

function getMetadata({ metadata }: State) {
	return metadata;
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectFormsByProjectId = createSelector(
	[getFormsByProjectId, getFormsById],
	(formsByProjectId, formsById) => {
		const forms: Form[] = [];

		if (formsByProjectId && formsByProjectId.fetched) {
			const formIds = formsByProjectId.ids;

			formIds.forEach(formId => {
				if (formId in formsById) forms.push(formsById[formId].current);
			});
		}

		return forms;
	}
);

type SelectFormParams = { initial: boolean };

export const selectForm = createSelector(
	[
		getForm,
		// PARAMS
		(_: State, params: SelectFormParams) => selectParams.encode(params)
	],
	(form, params) => {
		const { initial } = selectParams.decode<SelectFormParams>(params);

		if (form) return initial ? form.initial : form.current;
	}
);

export const selectFormHasChanges = createSelector([getForm], form => {
	if (form) return !isEqual(form.initial, form.current);

	return false;
});

export const selectAreFormsFetched = createSelector([getFormsByProjectId], formsByProjectId => {
	if (formsByProjectId) return formsByProjectId.fetched;

	return false;
});

export const selectFormSearchTerm = createSelector([getMetadata], metadata => metadata.searchTerm);

export const selectFormsBySetName = createSelector(
	[getFormsByProjectId, getFormsById],
	(formsByProjectId, formsById) => {
		const formsBySetName: FormsData['formsBySetName'] = {};

		if (formsByProjectId) {
			Object.entries(formsByProjectId.bySetName).forEach(([setName, { ids: formIds }]) => {
				const forms: Form[] = [];

				formIds.forEach(formId => {
					if (formId in formsById) forms.push(formsById[formId].initial);
				});

				formsBySetName[setName] = forms;
			});
		}

		return formsBySetName;
	}
);

type SelectFormByIdParams = { formId: string; initial: boolean };

/**
 * Make it a factory in order to remove the cahce size of `1`
 *
 * This enforces the usage to be inside a `useMemo` with [] dependency array
 */
export const selectFormById = () =>
	createSelector(
		[
			getFormsById,
			// PARAMS
			(_: State, params: SelectFormByIdParams) => selectParams.encode(params)
		],
		(formsById, params) => {
			const { formId, initial } = selectParams.decode<SelectFormByIdParams>(params);

			if (formId in formsById) {
				const form = formsById[formId];

				return initial ? form.initial : form.current;
			}
		}
	);

export const selectFormsFilters = createSelector([getFormsByProjectId], formsByProjectId => {
	if (formsByProjectId) return formsByProjectId.filters;

	const defaultFilters: FormsFilters = {
		show: FormsFilterShowOptions.ALL
	};

	return defaultFilters;
});

export const selectFormsSectionCollapsed = createSelector(
	[getFormsByProjectId],
	formsByProjectId => {
		if (formsByProjectId) return formsByProjectId.collapsed;

		return {};
	}
);

export const selectFormsMap = createSelector([getFormsById], formsById => formsById);

export const selectFormsMapByProjectId = createSelector(
	[getFormsByProjectId, getFormsById],
	(formsByProjectId, formsById) => {
		if (formsByProjectId)
			return formsByProjectId.ids.reduce((acc, formId) => {
				acc[formId] = formsById[formId];
				return acc;
			}, {} as FormsById);

		return {} as FormsById;
	}
);

import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
	useLogin,
	useAccount,
	useLogout,
	useSubscription,
	useSyncAccountAndSubscription
} from 'hooks/store';
import { useEffect } from 'react';

export function AccountUMProvider() {
	const [{ data: loggedIn }] = useLogin();

	const [
		{
			fetched: isAccountFetched,
			data: { details: userDetails },
			error: fetchingAccountError
		},
		getAccount
	] = useAccount();
	const [_, logout] = useLogout();
	const [{ loading: loggingOut }] = useLogout();
	const ldclient = useLDClient();

	useSyncAccountAndSubscription();

	const [
		{
			fetched: isSubscriptionFetched,
			loading: subscriptionLoading,
			error: subscriptionError,
			data: { details: subscriptionDetails }
		},
		getSubscription
	] = useSubscription();

	useEffect(() => {
		if (fetchingAccountError) logout();
	}, [fetchingAccountError]);

	useEffect(() => {
		if (!isAccountFetched && loggedIn && !loggingOut) {
			getAccount();
		}
	}, [loggedIn, isAccountFetched, loggingOut]);

	useEffect(() => {
		if (isAccountFetched && loggedIn) {
			if (ldclient) {
				const context: any = ldclient.getContext();

				if (context) {
					context.user.name = userDetails?.userid;
					context.user.firstName = userDetails?.userFirstName;
					context.user.lastName = userDetails?.userFirstName;
					context.user.email = userDetails?.emailAddress;
					if (subscriptionDetails)
						context.user.subscriptionId = subscriptionDetails.subscriptionId;
				}

				localStorage.setItem('LDContext', JSON.stringify(context));

				ldclient.identify(context);
			}
		}
	}, [isAccountFetched, loggedIn, subscriptionDetails]);

	useEffect(() => {
		if (
			!isSubscriptionFetched &&
			loggedIn &&
			!subscriptionLoading &&
			!subscriptionError &&
			!loggingOut
		)
			getSubscription();
	}, [loggedIn, loggingOut, isSubscriptionFetched, subscriptionError, subscriptionLoading]);

	return null;
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionTypes, verifyOneTimePassword } from 'store/auth';
import { Operation } from 'hooks/store/types';

interface Handlers {
	handler: (code: string) => void;
	resetError: () => void;
}

export function useVerifyOneTimePassword(): Operation<Handlers> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.VERIFY_ONE_TIME_PASSWORD);

	function handler(code: string) {
		dispatch(verifyOneTimePassword(code));
	}

	const handlers = {
		handler,
		resetError
	};

	return [{ loading, error }, handlers];
}

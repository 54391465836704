import { isEqual, merge, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
	type TimeDurationFormat,
	type ValidationCases,
	type Variable,
	type VariableActions,
	type VariableCategory,
	type VariableAliasesMap,
	VariableUniquenessType
} from 'api/data/variables/types';
import { AddVariableFields, DeleteVariableModal } from 'components/Variables';
import { initButtonProps } from 'helpers/buttons';
import {
	getInitialVariableData,
	getScopeVariables,
	isVariablePromGenerated
} from 'helpers/variables';
import { CategoryValuesModal } from 'components/Variables/CreateUpdateVariable/CategoryValuesModal';
import validateVariable from './variableValidation';
import { Content } from './VariableModal.style';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { debuggerLog } from 'helpers/generic';
import { objectDifference } from 'helpers/objects';
import {
	useTranslation,
	useVariableName,
	useVariableByName,
	useDeleteVariable,
	useVariablesData,
	useVariables,
	useCreateVariable,
	useUpdateVariable,
	useDestinationSetName,
	useDestinationGroupName,
	useCheckVariableTypeChange,
	useDependencies
} from 'hooks/store';
import { useAlerts, useModalState } from 'hooks/ui';
import {
	useMutableState,
	useStatic,
	useCompletedAction,
	useEffectOnce,
	useKeyPress,
	useDeepCompareMemo
} from 'hooks/utils';
import { TypeChangeModal } from 'components/Variables/CreateUpdateVariable';

import { initializeJsonLogicMapping } from 'helpers/calculatedEditor/editorHelpers';
import { useJsonLogicManager } from 'hooks/ui/useJsonLogicManager/useJsonLogicManager';
import { VariableSubType } from 'api/data/variables/statics';
import { CalculatedVariable } from 'components/Variables/CreateUpdateVariable/CalculatedVariable';

import { TimeDurationKey } from 'timeDurationConsts';
import type { GenericMap } from '../../../../types/maps';
import { nanoid as generate } from 'nanoid';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';
import { parseDateToAPIStorage } from 'helpers/entries';

enum Views {
	General = 'general',
	Calculations = 'calculations'
}

enum PromptToSaveActions {
	OPEN_CATEGORY_VALUES_MODAL = 'OPEN_CATEGORY_VALUES_MODAL'
}

interface Props {
	onClose: () => void;
	disabledVariableTypes: VariableType[];
}

export function VariableModal({ onClose, disabledVariableTypes = [] }: Props) {
	// SET TO `true` TO SEE THE LOGS
	const logs = {
		global: false,
		/////////////
		fields: false,
		cases: false,
		changes: false,
		validations: false
	};

	const [{ data: dependencies }] = useDependencies();

	const log = {
		fields: debuggerLog(logs.global && logs.fields),
		cases: debuggerLog(logs.global && logs.cases),
		changes: debuggerLog(logs.global && logs.changes),
		validations: debuggerLog(logs.global && logs.validations)
	};

	// ==================================

	const { translate } = useTranslation();

	const { setError } = useAlerts();

	const [variableName] = useVariableName();
	const isEdit = variableName !== null;

	const variable = useVariableByName(variableName);

	const [{ loading: deletingVariable, error: errorDeletingVariable }] = useDeleteVariable();

	const variablesData = useVariablesData({ initial: true });

	const [
		{
			data: { variables }
		}
	] = useVariables({ initial: true });

	const [{ loading: creatingVariable, error: errorCreatingVariable }, createVariable] =
		useCreateVariable();
	const [{ loading: updatingVariable, error: errorUpdatingVariable }, updateVariable] =
		useUpdateVariable();

	const [destinationSetName, setDestinationSetName] = useDestinationSetName();
	const [destinationGroupName, setDestinationGroupName] = useDestinationGroupName();

	const initialVariable = useMemo(() => getInitialVariableData(variable), [variable]);
	const initialCases = useMemo(
		() => initializeJsonLogicMapping(variable?.cases || []),
		[variables, variable]
	);

	const [sourceTimeUnits, setSourceTimeUnits] = useState<TimeDurationKey | undefined>(undefined);

	// VARIABLE STATE
	const [initialDraftVariable, setInitialDraftVariable] = useState(initialVariable);
	const [draftVariable, setDraftVariable] = useMutableState(initialVariable);

	// VARIABLE CASES STATE
	const [initialDraftCases, setInitialDraftCases] = useState(initialCases);

	const scopeVariables = useMemo(
		() => getScopeVariables({ variables, variablesData, destinationSetName }),
		[variables, variablesData, destinationSetName]
	);

	const initialVariableAliases = useMemo(() => {
		if (
			!variable ||
			!variable.casesVariableAliases ||
			!Object.entries(variable.casesVariableAliases).length
		)
			return null;
		return Object.entries(variable.casesVariableAliases).reduce(
			(acc, [variableId, key]) => {
				const variable = variables.find(v => v.name === variableId);

				const id = generate();

				acc.order.push(id);

				acc.map[id] = {
					variableId,
					key,
					type: variable?.type || VariableType.String
				};

				return acc;
			},
			{
				order: [] as string[],
				map: {} as VariableAliasesMap['map']
			}
		);
	}, []);

	const { draftCases, variableAliases, calculationActions, variableAliasesActions } =
		useJsonLogicManager({
			initialCases: initialCases,
			initialVariableAliases: initialVariableAliases,
			draftVariable,
			scopeVariables
		});

	const [shouldRenderNumericEditor, setShouldRenderNumericEditor] = useState<boolean>(false);
	// Not the best fix, but what can you do?
	const [showNumericInfoMessage, setShowNumericInfoMessage] = useState<boolean>(
		isEdit ? false : true
	);

	const isSystemGenerated = isVariablePromGenerated({
		specialization: draftVariable.specialization
	});

	const patchDependenciesModal = useModalState<{ patchDependencies: boolean }>({});

	// DELETE VARIABLE MODAL STATE
	const [variableToDelete, setVariableToDelete] = useState<Variable | null>(null);
	const deleteVariableModal = {
		variable: variableToDelete,
		open: () => setVariableToDelete(variable),
		close: () => setVariableToDelete(null)
	};

	// CONFIRM TYPE CHANGE MODAL
	const [showTypeChangeModal, setShowTypeChangeModal] = useState<boolean>(false);
	const typeChangeModal = {
		visible: showTypeChangeModal,
		open: () => setShowTypeChangeModal(true),
		close: () => setShowTypeChangeModal(false)
	};

	const shouldPromptTypeModal = useDeepCompareMemo(() => {
		if (!isEdit || !variable) return false;
		const { type } = variable;
		const { type: targetType } = draftVariable;

		// CUSTOM TRANSLATE MESSAGE
		if (type === VariableType.DateTime && targetType === VariableType.Date) {
			// setTypeConfirmMessage('');
			return true;
		}

		if (type === VariableType.Float && targetType === VariableType.Integer) {
			// setTypeConfirmMessage('');
			return true;
		}

		if (
			(initialVariable.type === VariableType.Float &&
				draftVariable.type === VariableType.TimeDuration) ||
			(initialVariable.type === VariableType.Integer &&
				draftVariable.type === VariableType.TimeDuration)
		) {
			return true;
		}

		return false;
	}, [draftVariable, variable]);

	// CATEGORY VALUE MANAGEMENT MODAL STATE
	const [categoryValuesModalState, setCategoryValuesModalState] = useState(false);
	const categoryValuesModal = {
		visible: categoryValuesModalState,
		open: () => setCategoryValuesModalState(true),
		close: () => setCategoryValuesModalState(false)
	};

	const [promptToSave, setPromptToSave] = useState<PromptToSaveActions | null>(null);
	const promptToSaveModal = {
		action: promptToSave,
		open: (action: PromptToSaveActions) => setPromptToSave(action),
		close: () => setPromptToSave(null)
	};

	const [variableTypeChangePayload, setVariableTypeChangePayload] = useStatic<{
		type: VariableType;
		isEntry: boolean;
		confirmSameType?: boolean;
	} | null>(null);

	const [
		{
			data: isTypeChangeOK,
			loading: checkingVariableTypeChange,
			error: errorCheckingVariableTypeChange
		},
		checkVariableTypeChange,
		resetVariableTypeChange
	] = useCheckVariableTypeChange();

	// CREATE VARIABLE SUCCESS => GO TO VARIABLES LIST
	useCompletedAction(
		creatingVariable,
		errorCreatingVariable,
		onClose,
		setErrorAlertCreatingVariable
	);

	// UPDATE VARIABLE SUCCESS => GO TO VARIABLES LIST
	useCompletedAction(updatingVariable, errorUpdatingVariable, () => {
		if (promptToSaveModal.action) return;

		onClose();
	});

	// DELETE VARIABLE SUCCESS => GO TO VARIABLES LIST
	useCompletedAction(deletingVariable, errorDeletingVariable, onClose);

	useEffectOnce(() => {
		return () => {
			if (destinationSetName !== null) setDestinationSetName(null);
			if (destinationGroupName !== null) setDestinationGroupName(null);
		};
	});

	// SYNC `draftVariable`, `draftCases` STATES
	useEffect(() => {
		if (!variable) return;

		const newDraftVariable = getInitialVariableData(variable);
		const newDraftCases = initializeJsonLogicMapping(variable.cases);

		const changes = !isEqual(initialDraftVariable, newDraftVariable);

		if (changes) {
			setDraftVariable(newDraftVariable);
			setInitialDraftVariable(newDraftVariable);

			!!newDraftCases && calculationActions.setDraftCases(newDraftCases);
			!!newDraftCases && setInitialDraftCases(newDraftCases);
		}
	}, [variable]);

	const errors = useMemo(
		() =>
			validateVariable(
				draftVariable,
				{
					labels:
						isEdit && variable
							? variables.map(v => v.label).filter(l => l !== variable.label)
							: variables.map(v => v.label),
					isEdit
				},
				translate
			),
		[draftVariable]
	);

	async function handlePromptToSaveConfirm() {
		if (!promptToSaveModal.action) return;

		await handleSubmit();

		const actionToFunctionCallback = getActionToFunctionCallback();
		const functionCallback = actionToFunctionCallback[promptToSaveModal.action];

		functionCallback();
		promptToSaveModal.close();
	}

	function handlePromptToSaveDiscard() {
		if (!promptToSaveModal.action) return;

		const actionToFunctionCallback = getActionToFunctionCallback();
		const functionCallback = actionToFunctionCallback[promptToSaveModal.action];

		functionCallback();
		promptToSaveModal.close();

		resetDraftVariable();
	}

	function handleActions(action: PromptToSaveActions) {
		if (hasChanges) return promptToSaveModal.open(action);

		const actionToFunctionCallback = getActionToFunctionCallback();
		const functionCallback = actionToFunctionCallback[action];

		functionCallback();
	}

	function getActionToFunctionCallback() {
		return {
			[PromptToSaveActions.OPEN_CATEGORY_VALUES_MODAL]: categoryValuesModal.open
		};
	}

	function resetDraftCases() {
		calculationActions.setDraftCases(initializeJsonLogicMapping([]));
	}

	function resetDraftVariable() {
		setDraftVariable(initialDraftVariable);
	}

	function handleChange(value: string, field: keyof Variable) {
		log.fields('handleChange()', { value, field });

		setDraftVariable(state => ({ ...state, [field]: value }));
	}

	function handleTypeChange(
		type: VariableType,
		isEntry: boolean,
		confirmSameType?: boolean,
		options?: {
			fromPayload: boolean;
		}
	) {
		log.fields('handleTypeChange()', {
			type,
			isEntry,
			confirmSameType,
			options
		});

		if (isEdit) {
			if (!options?.fromPayload) {
				setVariableTypeChangePayload({
					type,
					isEntry,
					confirmSameType: type === initialDraftVariable.type
				});
				if (type !== initialDraftVariable.type) {
					checkVariableTypeChange(initialVariable.name, type);
					setDraftVariable(state => ({ ...state, type }));
				} else {
					setDraftVariable(state => {
						const initialState = { ...state, type };
						delete initialState.targetType;

						return initialState;
					});
				}

				return;
			}
		}

		const typeChangedToNonCategory =
			type !== VariableType.Category && type !== VariableType.CategoryMultiple;
		const fixedCategories =
			isEdit && type === variable?.type ? variable.fixedCategories : false;

		setDraftVariable(state => {
			state.type = type;
			state.validationRange = false;
			state.validationCases = {
				minValue: '',
				maxValue: ''
			};

			if (isEntry) {
				state.fixedCategories = fixedCategories;
				state.entryType = EntryVariableType.Entry;
			}

			if (confirmSameType) {
				state.targetType = null;
			} else {
				state.targetType = initialDraftVariable.targetType;
			}

			if (type === VariableType.Unique) {
				state.uniquenessType = VariableUniquenessType.Manual;
			} else {
				delete state.uniquenessType;
			}
		});

		resetDraftCases();

		if (type !== VariableType.TimeDuration) {
			setDraftVariable(state => {
				delete state.durationFormat;
				return state;
			});
		}

		if (draftVariable.entryType === EntryVariableType.Calculated && !typeChangedToNonCategory) {
			setDraftVariable(state => {
				state.fixedCategories = true;
			});
		} else {
			if (typeChangedToNonCategory) {
				setDraftVariable(state => {
					state.fixedCategories = false;
				});
			} else {
				setDraftVariable(state => {
					state.fixedCategories = draftVariable.categories.length > 0;
				});
			}
		}
	}

	function toggleFixedCategoriesHandler() {
		log.fields('toggleFixedCategoriesHandler()');

		setDraftVariable(state => {
			state.fixedCategories = !state.fixedCategories;
		});
	}

	function handleMinInputPrecisionChange(value?: number) {
		setDraftVariable(state => {
			state.minInputPrecision = value;
		});
	}

	function handleVisiblePrecisionChange(value: number | null) {
		setDraftVariable(state => {
			state.visiblePrecision = value;
		});
	}

	function handleFormatChange(value?: TimeDurationFormat) {
		setDraftVariable(state => {
			state.durationFormat = value;
		});
	}

	function handleValidationChange(validationCases: ValidationCases) {
		log.fields('handleValidationChange()', { validationCases });
		const parsedValidationCases = {
			...validationCases,
			...(validationCases.minValue && {
				minValue: [VariableType.DateTime].includes(draftVariable.type)
					? parseDateToAPIStorage(validationCases.minValue)
					: validationCases.minValue
			}),
			...(validationCases.maxValue && {
				maxValue: [VariableType.DateTime].includes(draftVariable.type)
					? parseDateToAPIStorage(validationCases.maxValue)
					: validationCases.maxValue
			})
		};

		setDraftVariable(state => {
			state.validationCases = merge(state.validationCases, parsedValidationCases);
		});
	}

	function handleEntryChange(entryType: EntryVariableType) {
		log.fields('handleEntryChange()', { entryType });

		if (draftVariable.entryType === entryType) return;

		if (entryType === EntryVariableType.Calculated) {
			setDraftVariable(state => {
				state.entryType = entryType;
				state.obligatory = false;
				state.validationRange = false;
				state.validationCases = {
					minValue: '',
					maxValue: ''
				};

				if (state.type === VariableType.Category) {
					state.fixedCategories = true;
				}
			});
		} else {
			setDraftVariable(state => {
				state.entryType = entryType;
			});

			resetDraftCases();
		}
	}

	function toggleOptimizeForManyValues() {
		log.fields('toggleOptimizeForManyValues()');

		setDraftVariable(state => {
			state.optimizeForManyValues = !state.optimizeForManyValues;
		});
	}

	function handleSubTypeChange(value: VariableSubType) {
		log.cases('handleSubTypeChange()', value);

		setDraftVariable(state => {
			state.subType = value;
		});
	}

	function toggleObligatory() {
		log.fields('toggleObligatory()');

		setDraftVariable(state => {
			state.personalData = false;
			state.obligatory = !state.obligatory;
		});
	}

	function togglePersonalData() {
		log.fields('togglePersonalData()');

		setDraftVariable(state => {
			state.obligatory = false;
			state.personalData = !state.personalData;
		});
	}

	function toggleRange() {
		log.fields('toggleRange()');

		setDraftVariable(state => {
			state.validationRange = !state.validationRange;

			if (state.validationRange) {
				state.validationCases = {
					minValue: '',
					maxValue: ''
				};
			}
		});
	}

	function setErrorAlertCreatingVariable() {
		setError({
			message: translate(({ errors }) => errors.api.variables.couldNotCreateVariable)
		});
	}

	function handleUniquenessTypeChange(value: VariableUniquenessType) {
		log.fields('handleUniquenessTypeChange()');

		setDraftVariable(state => {
			state.uniquenessType = value;
		});
	}

	function handleCategoriesChange(categoryValues: VariableCategory[]) {
		setDraftVariable(state => {
			state.categories = categoryValues;
		});

		const idsToRemove: string[] = [];

		draftCases.order.forEach(jsonLogicId => {
			const draftCase = draftCases.logics[jsonLogicId];

			if (
				'if' in draftCase &&
				draftCase.if[1] &&
				typeof draftCase.if[1] === 'object' &&
				'catVal' in draftCase.if[1]
			) {
				const currCategory = draftCase.if[1];

				const category = categoryValues.find(
					category => category.value === currCategory.catVal
				);

				if (!category) {
					idsToRemove.push(jsonLogicId);
				}
			}
		});

		if (idsToRemove.length > 0) {
			const updatedJsonLogicMap = draftCases.order.reduce((acc, jsonLogicId) => {
				if (!idsToRemove.includes(jsonLogicId)) {
					acc.order.push(jsonLogicId);
					acc.logics[jsonLogicId] = draftCases.logics[jsonLogicId];
				}

				return acc;
			}, initializeJsonLogicMapping([]));

			calculationActions.setDraftCases(updatedJsonLogicMap);

			const resetEntryType =
				initialDraftVariable.entryType !== EntryVariableType.Entry &&
				initialDraftVariable.name !== '';

			if (resetEntryType) {
				handleEntryChange(EntryVariableType.Entry);
			}
		}
	}

	const variableActions: VariableActions = {
		onLabelChanged: (value: string) => handleChange(value, 'label'),
		onDescriptionChange: (value: string) => handleChange(value, 'description'),
		onToggleFixedCategory: toggleFixedCategoriesHandler,
		onToggleOptimizeForManyValues: toggleOptimizeForManyValues,
		onToggleObligatory: toggleObligatory,
		onTogglePersonalData: togglePersonalData,
		onToggleRange: toggleRange,
		onUniquenessTypeChange: handleUniquenessTypeChange,
		onTypeChange: handleTypeChange,
		onEntryTypeChange: handleEntryChange,
		onSubTypeChange: handleSubTypeChange,
		onValidationChange: handleValidationChange,
		onCategoriesChange: handleCategoriesChange,
		onMinInputPrecisionChange: handleMinInputPrecisionChange,
		onVisiblePrecisionChange: handleVisiblePrecisionChange,
		onFormatChange: handleFormatChange,
		onSourceTimeUnitsChange: setSourceTimeUnits
	};

	/**
	 * === CHANGE VARIABLE TYPE ===
	 *
	 * 1. if current type change can't be done
	 * 2. if there was an error checking the type change
	 * => revert changes
	 */
	useCompletedAction(
		checkingVariableTypeChange,
		errorCheckingVariableTypeChange,
		// SUCCESS CALLBACK
		() => {
			if (isTypeChangeOK) {
				const payload = variableTypeChangePayload();

				if (payload) {
					handleTypeChange(payload.type, payload.isEntry, payload.confirmSameType, {
						fromPayload: true
					});
					setVariableTypeChangePayload(null);

					return;
				}
			}

			resetVariableTypeChange();
			setVariableTypeChangePayload(null);
		},
		// ERROR CALLBACK
		() => {
			setVariableTypeChangePayload(null);
		}
	);

	const hasChanges = useMemo(() => {
		const omitedFieldNames: (keyof Variable)[] = isEdit ? ['fixedCategories', 'cases'] : [];

		// VARIABLE FIELDS EQUALITY - EXCEPT CALCULATION CASES
		const fieldsChanged = !isEqual(
			omit(initialDraftVariable, omitedFieldNames),
			omit(draftVariable, omitedFieldNames)
		);

		if (fieldsChanged) {
			const diff = objectDifference(initialDraftVariable, draftVariable);

			log.changes('[initialDraftVariable, draftVariable] are not equal', diff);
		}

		// CALCULATION CASES EQUALITY
		const casesChanged = !isEqual(initialDraftCases, draftCases);

		if (casesChanged) {
			const diff = objectDifference(
				initialDraftCases ? initialDraftCases : {},
				draftCases ? draftCases : {}
			);

			log.changes('[initialDraftCases, draftCases] are not equal', diff);
		}

		const changes = fieldsChanged || casesChanged;

		log.changes('hasChanges:', {
			variable: {
				initial: initialDraftVariable,
				current: draftVariable
			},
			variableCases: {
				initial: initialDraftCases,
				current: draftCases
			},
			hasChanges: changes
		});

		return changes;
	}, [initialDraftVariable, draftVariable, initialDraftCases, draftCases]);

	const hasValidCases = useMemo(() => {
		let valid = true;

		const isCalculated = draftVariable.entryType === EntryVariableType.Calculated;

		if (isCalculated) {
			valid = draftCases.order.length > 0;
		}

		return valid;
	}, [draftVariable, draftCases]);

	const hasValidVisiblePrecision = useMemo(
		() => draftVariable.visiblePrecision !== 0,
		[draftVariable]
	);

	const hasMissingValuesInCases = useMemo(() => {
		function traverseNode(node: any): boolean {
			if (typeof node === 'object' && node !== null) {
				for (const key in node) {
					// If it's a logical node and the key is 'if', ignore the third value
					if (key === 'if' && Array.isArray(node[key]) && node[key].length === 3) {
						if (traverseNode(node[key][0]) || traverseNode(node[key][1])) {
							return true;
						}
					} else if (traverseNode(node[key])) {
						return true;
					}
				}
			} else if (node === null) {
				// If it's null, return true
				return true;
			} else if (Array.isArray(node)) {
				// If it's an array, check each element
				for (const item of node) {
					if (traverseNode(item)) {
						return true;
					}
				}
			}

			// If none of the above conditions were met, return false
			return false;
		}

		return draftVariable.entryType === EntryVariableType.Calculated && traverseNode(draftCases);
	}, [draftCases]);

	function primaryButtonDisabled() {
		return (
			!(hasChanges && hasValidCases) ||
			!!errors ||
			!(!hasMissingValuesInCases && hasValidCases) ||
			(draftVariable.type === VariableType.TimeDuration &&
				!draftVariable.durationFormat?.length) ||
			!hasValidVisiblePrecision ||
			(initialVariable.type !== draftVariable.type &&
				initialVariable.name !== '' &&
				draftVariable.type === VariableType.TimeDuration &&
				!sourceTimeUnits)
		);
	}

	async function handleSubmit() {
		if (loadingAction || primaryButtonDisabled()) return;
		if (shouldPromptTypeModal && !typeChangeModal.visible) {
			typeChangeModal.open();
			return;
		}

		const variable: Variable = {
			...draftVariable,
			cases: draftCases ? draftCases.order.map(id => draftCases.logics[id]) : [],
			casesVariableAliases:
				draftCases && Object.keys(variableAliases).length > 0
					? Object.entries(variableAliases.map).reduce(
							(acc, [_, { variableId, key }]) => {
								acc[variableId] = key;
								return acc;
							},
							{} as GenericMap<string>
					  )
					: {}
		};

		if (isEdit) {
			const setDependencies = Object.values(dependencies.dependenciesBySetName)
				.map(depBySet => depBySet.dependencies)
				.flat();
			// prompt only if target variable is linked to a dependency rule.
			const promptDependenciesWarning = !![
				...dependencies.dependencies,
				...setDependencies
			].find(dep =>
				[
					...dep.dependantVariables.map(d => d.dependantVariableName),
					dep.supplierVariableName
				].find(variableName => variable.name === variableName)
			);
			//
			await updateVariable({
				variable: {
					...variable,
					obligatory: variable.obligatory || variable.type === VariableType.Unique
				},
				...(variable.type === VariableType.TimeDuration && {
					sourceTimeUnit: sourceTimeUnits
				}),
				patchDependencies:
					!promptDependenciesWarning || patchDependenciesModal.payload?.patchDependencies,
				callbacks: {
					onPatchDependencies: () =>
						patchDependenciesModal.open({ patchDependencies: true })
				}
			});
		} else {
			await createVariable({
				...variable,
				obligatory: variable.obligatory || variable.type === VariableType.Unique
			});
		}
	}

	const loadingAction = creatingVariable || updatingVariable;

	const [view, setView] = useState<Views>(Views.General);
	const views = {
		order: [Views.General, Views.Calculations],
		labels: {
			[Views.General]: translate(dict => dict.variablesPage.variableModal.generalDetails),
			[Views.Calculations]: translate(dict => dict.variablesPage.variableModal.calculations)
		},
		is: {
			general: view === Views.General,
			calculations: view === Views.Calculations
		}
	};

	useKeyPress(
		{
			onEnterKeyPress:
				isEdit && !hasChanges
					? views.is.calculations
						? undefined
						: onClose
					: views.is.calculations
					? undefined
					: handleSubmit,
			onDeleteKeyPress: () => {
				if (!isSystemGenerated) deleteVariableModal.open();
			}
		},
		{
			listen: !(
				creatingVariable ||
				updatingVariable ||
				deletingVariable ||
				deleteVariableModal.variable ||
				categoryValuesModal.visible
			)
		}
	);

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(({ buttons }) =>
				isEdit && !isEqual(initialVariable, draftVariable) ? buttons.update : buttons.create
			),
			loading: loadingAction && !promptToSaveModal.action,
			disabled: primaryButtonDisabled(),
			onClick: handleSubmit
		};

		buttons.neutral = {
			label: translate(({ buttons }) => buttons.cancel),
			onClick: onClose
		};

		if (isEdit && !hasChanges) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	return (
		<>
			<Modal
				size={s => s.full}
				title={
					isEdit
						? translate(dict => dict.editVariable.title)
						: translate(dict => dict.addVariable.title)
				}
				primary={buttonProps.primary}
				neutral={buttonProps.neutral}
				tabs={{
					labels: views.order.map(view => views.labels[view]),
					active: views.order.indexOf(view),
					onClick: tabIndex => setView(views.order[tabIndex]),
					isDisabed: tabIndex =>
						tabIndex === views.order.indexOf(Views.Calculations) &&
						draftVariable.entryType !== EntryVariableType.Calculated
				}}
				onClose={onClose}
				fullSizeConfig={{
					narrow: true,
					centerTitle: true
				}}
				tooltipContainer={<div id="root-editor-tooltip"></div>}
				visible
				close
			>
				<Content disabled={checkingVariableTypeChange}>
					{views.is.general && (
						<AddVariableFields
							disabledVariableTypes={disabledVariableTypes}
							variable={draftVariable}
							initialVariable={initialDraftVariable}
							variables={scopeVariables}
							errors={errors}
							variableActions={variableActions}
							isOnUpdateRoute={isEdit}
							sourceTimeUnits={sourceTimeUnits}
							onCategoryValuesClick={() =>
								handleActions(PromptToSaveActions.OPEN_CATEGORY_VALUES_MODAL)
							}
							onSubmit={handleSubmit}
						/>
					)}

					{views.is.calculations && (
						<CalculatedVariable
							renderProps={{
								shouldRenderNumericEditor,
								setShouldRenderNumericEditor
							}}
							showNumericInfoMessage={showNumericInfoMessage}
							setShowNumericInfoMessage={setShowNumericInfoMessage}
							jsonLogicMap={draftCases}
							draftVariable={draftVariable}
							variables={scopeVariables.filter(
								v => v.entryType !== EntryVariableType.Calculated
							)}
							calculationActions={calculationActions}
							variableAliases={variableAliases}
							variableAliasesActions={variableAliasesActions}
						/>
					)}
				</Content>

				{views.is.general && variable && !isSystemGenerated && (
					<Flex flex={1} align={a => a.end} marginOffset={{ top: 2.4 }}>
						<Button
							title={translate(
								dict => dict.variablesPage.variableModal.deleteVariable
							)}
							variant={v => v.link}
							paddingOffset={{ all: 0 }}
							onClick={deleteVariableModal.open}
						/>
					</Flex>
				)}
			</Modal>

			{/* MODALS */}

			{/* DELETE VARIABLE MODAL */}
			{deleteVariableModal.variable && (
				<DeleteVariableModal
					variable={deleteVariableModal.variable}
					onClose={deleteVariableModal.close}
				/>
			)}

			{/* TYPE CHANGE MODAL */}
			{typeChangeModal.visible && (
				<TypeChangeModal
					onClose={typeChangeModal.close}
					onConfirm={handleSubmit}
					confirmationPhrase={translate(
						({ variables }) => variables.confirmTypeChangeModal.phrase
					)}
				/>
			)}

			{/* CATEGORY VALUE MANAGEMENT MODAL */}
			{categoryValuesModal.visible && (
				<CategoryValuesModal
					variable={draftVariable}
					initialVariable={initialDraftVariable}
					onToggle={variableActions.onToggleFixedCategory}
					onClose={categoryValuesModal.close}
				/>
			)}

			{/* PROMPT TO SAVE MODAL */}
			{promptToSaveModal.action && (
				<Modal
					size={s => s.s}
					title={translate(dict => dict.promptToSave.title)}
					primary={{
						label: translate(dict => dict.buttons.save),
						loading: loadingAction,
						onClick: handlePromptToSaveConfirm
					}}
					neutral={{
						label: translate(dict => dict.buttons.discard),
						onClick: handlePromptToSaveDiscard
					}}
					onClose={promptToSaveModal.close}
					enterAsPrimaryOnClick
					visible
					close
				>
					<Typography.Paragraph>
						{translate(dict => dict.promptToSave.unsavedChanges)}
					</Typography.Paragraph>
				</Modal>
			)}

			{/* PATCH DEPENDENCIES CONFIRM MODAL */}
			{patchDependenciesModal.visible && (
				<Modal
					size={s => s.s}
					title={translate(
						dict => dict.variablesPage.variableModal.patchDependenciesModal.title
					)}
					primary={{
						label: translate(dict => dict.buttons.proceed),
						warning: true,
						onClick: () => {
							handleSubmit();
							patchDependenciesModal.close();
						}
					}}
					neutral={{
						label: translate(dict => dict.buttons.cancel),
						onClick: patchDependenciesModal.close
					}}
					onClose={patchDependenciesModal.close}
					enterAsPrimaryOnClick
					visible
					close
				>
					<Typography.Paragraph multiline>
						{translate(
							dict => dict.variablesPage.variableModal.patchDependenciesModal.message
						)}
						{'\n\n'}
						<b>{translate(dict => dict.terms.deleteWarningMessage)}</b>
					</Typography.Paragraph>
				</Modal>
			)}
		</>
	);
}

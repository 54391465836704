import styled, { css } from 'styled-components';

interface CircleProps {
	color: string;
	size?: number;
}
export const Circle = styled.span<CircleProps>`
	display: block;
	border-radius: 50%;
	background: ${({ color }) => color};

	${({ size = 1.6 }) => css`
		height: ${size}rem;
		width: ${size}rem;
		min-width: ${size}rem;
	`}
`;

import { useEffect } from 'react';
import { AdminAccount } from 'api/account/admin';
import { Operation } from 'hooks/store/types';
import { ActionTypes, addAdminUser } from 'store/account/admin';
import { useDispatch, usePrevious } from 'hooks/utils';
import { useNavigation } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useAlerts } from 'hooks/ui';

export function useAdminCreateUser(): Operation<(user: AdminAccount) => void> {
	const dispatch = useDispatch();
	const { navigate, routes } = useNavigation();
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.ADMIN_CREATE_USER);

	const wasLoading = usePrevious(loading);
	useEffect(() => {
		if (wasLoading && !loading && !error) {
			setNotification({
				message: translate(({ admin }) => admin.users.notifications.createdUser)
			});
			navigate(routes.admin.view);
		}
	}, [loading, error]);

	function handler(user: AdminAccount) {
		if (error) resetError();
		dispatch(addAdminUser(user));
	}

	return [{ loading, error }, handler];
}

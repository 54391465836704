import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, renameDocument } from 'store/data/documents';

export function useRenameDocument(): Operation<(fileId: string, newLabel: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.RENAME_DOCUMENT);

	function handler(fileId: string, newLabel: string) {
		dispatch(renameDocument(fileId, newLabel));
	}

	return [{ loading, error }, handler];
}

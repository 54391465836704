import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	createChargebeeCustomer,
	CreateChargebeeCustomerAction
} from 'store/account/payments';
import { ActionPayload } from 'store/types';

export function useCreateChargebeeCustomer(): Operation<
	(payload: ActionPayload<CreateChargebeeCustomerAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CREATE_CHARGEBEE_CUSTOMER);

	function handler(payload: ActionPayload<CreateChargebeeCustomerAction>) {
		dispatch(createChargebeeCustomer(payload));
	}

	return [{ loading, error }, handler];
}

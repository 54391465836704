import { useNavigate } from 'react-router-dom';

import { StorageKeys } from 'types/index';

import { useMatchProms } from '.';

const routes = {
	logout: '/logout',
	login: '/login',
	templateRoles: '/roles',
	patientLogin: '/recipient/login',
	projects: {
		list: '/projects',
		create: '/projects/create',
		import: (projectId: string) => `/projects/${projectId}/import`,
		analysis: (projectId: string) => `/projects/${projectId}/analysis`,
		dataset: {
			view: (projectId: string) => `/projects/${projectId}/dataset`,
			create: (projectId: string) => `/projects/${projectId}/dataset/create`,
			update: (projectId: string, entryId: string) =>
				`/projects/${projectId}/dataset/update/${entryId}`,
			conflicts: (projectId: string, entryId: string) =>
				`/projects/${projectId}/dataset/update/${entryId}/conflicts`
		},
		statuses: (projectId: string) => `/projects/${projectId}/statuses`,
		variables: {
			view: (projectId: string) => `/projects/${projectId}/variables`,
			create: (projectId: string) => `/projects/${projectId}/variables/create`,
			update: (projectId: string) => `/projects/${projectId}/variables/update`
		},
		templates: (projectId: string) => `/projects/${projectId}/templates`,
		dependencies: (projectId: string) => `/projects/${projectId}/dependencies`,
		forms: {
			list: (projectId: string) => `/projects/${projectId}/forms`,
			form: (projectId: string, formId: string) => `/projects/${projectId}/forms/${formId}`
		},
		documents: {
			list: (projectId: string) => `/projects/${projectId}/documents`,
			view: (projectId: string) => `/projects/${projectId}/documents/view`
		},
		collaborators: {
			view: (projectId: string) => `/projects/${projectId}/collaborators`,
			invite: (projectId: string) => `/projects/${projectId}/collaborators/invite`
		},
		roles: (projectId: string) => `/projects/${projectId}/roles`,
		projectDashboard: {
			view: (projectId: string) => `/projects/${projectId}/projectDashboard`
		}
	},
	proms: {
		list: '/surveys',
		create: '/surveys/create',
		import: (promId: string) => `/surveys/${promId}/import`,
		dashboard: (promId: string) => `/surveys/${promId}/dashboard`,
		patients: (promId: string) => `/surveys/${promId}/recipients`,
		analysis: (promId: string) => `/surveys/${promId}/analysis`,
		dataset: {
			view: (promId: string) => `/surveys/${promId}/dataset`,
			create: (promId: string) => `/surveys/${promId}/dataset/create`,
			update: (promId: string, entryId: string) =>
				`/surveys/${promId}/dataset/update/${entryId}`
		},
		statuses: (promId: string) => `/surveys/${promId}/statuses`,
		variables: {
			view: (promId: string) => `/surveys/${promId}/variables`,
			create: (promId: string) => `/surveys/${promId}/variables/create`,
			update: (promId: string) => `/surveys/${promId}/variables/update`
		},
		templates: (promId: string) => `/surveys/${promId}/templates`,
		dependencies: (promId: string) => `/surveys/${promId}/dependencies`,
		forms: {
			list: (promId: string) => `/surveys/${promId}/forms`,
			form: (promId: string, formId: string) => `/surveys/${promId}/forms/${formId}`
		},
		documents: {
			list: (promId: string) => `/surveys/${promId}/documents`,
			view: (promId: string) => `/surveys/${promId}/documents/view`
		},
		collaborators: {
			view: (promId: string) => `/surveys/${promId}/collaborators`,
			invite: (promId: string) => `/surveys/${promId}/collaborators/invite`
		},
		roles: (promId: string) => `/surveys/${promId}/roles`
	},
	help: '/help/',
	accountUM: {
		subscription: '/account/subscription',
		payment: '/account/payment',
		billingHistory: '/account/billing',
		addons: '/account/addons'
	},
	admin: {
		view: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/users`,
		create: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/users/create`,
		details: (userId: string) =>
			`/${process.env.REACT_APP_ADMIN_PATH_ROOT}/users/details/${userId}`,
		enterprise: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/enterprise`,
		roles: `/${process.env.REACT_APP_ADMIN_PATH_ROOT}/roles`
	},
	enterpriseAdmin: {
		dashboard: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/dashboard`,
		users: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/users`,
		projects: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/projects`,
		subscription: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/subscription`,
		roles: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/roles`,
		settings: `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}/settings`
	},
	dashboard: '/dashboard'
};

export type NavigationRoutes = typeof routes;

export function useNavigation() {
	const _navigate = useNavigate();

	function navigate(route: string) {
		_navigate(route);
	}

	function replace(route: string) {
		_navigate(route, { replace: true });
	}

	function goBack() {
		const prevPathname = localStorage.getItem(StorageKeys.PrevPathname);
		if (prevPathname !== null) {
			navigate(prevPathname);
		}
	}

	const matchProms = useMatchProms();

	const promOrProject: 'proms' | 'projects' = matchProms ? 'proms' : 'projects';

	return {
		routes,
		promOrProject,
		navigate,
		replace,
		goBack
	};
}

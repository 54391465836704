import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, setNotificationsAsRead } from 'store/data/notifications';

export function useSetNotificationsAsRead(): Operation<(notificationIds: string[]) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.SET_NOTIFICATIONS_AS_READ);

	function handler(notificationIds: string[]) {
		dispatch(setNotificationsAsRead(notificationIds));
	}

	return [{ loading, error }, handler];
}

import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { Input as DefaultInput } from 'components/UI/Inputs/Input';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;

	> :nth-child(n) {
		width: calc(50% - 1.1rem);
	}

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
		justify-content: unset;

		> :nth-child(n) {
			width: 100%;
		}

		> :nth-child(n):not(:last-child) {
			margin-bottom: 1.4rem;
		}
	}
`;

export const ConversionRow = styled(Row)`
	position: relative;
	// ICON
	> :nth-child(2) {
		position: absolute;
		left: calc(50% - 0.8rem);
		top: calc(50% + 0.4rem);
		width: auto;
	}

	@media ${MediaQueries.maxWidth.md} {
		:nth-child(2) {
			display: none;
		}
	}
`;

export const InfoMessageDecorator = styled.div`
	padding: 0.8rem;
	border-radius: 0.4rem;
	background-color: ${Colors.background.disabled};
`;

export const InfoMessagePrompt = styled.div`
	color: ${Colors.brownOrange};
	background: ${Colors.paleOrange};
	border: 1px solid ${Colors.lightOrange};
	border-radius: 0.4rem;

	> div {
		align-items: center;
	}

	svg {
		color: ${Colors.brownOrange};
	}

	p {
		color: inherit;
	}
`;

export const RadioButtonContainer = styled.div`
	cursor: pointer;
	user-select: none;
`;

export const Input = styled(DefaultInput)`
	width: calc(50% - 1.1rem);
`;

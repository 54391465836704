import { useSelector, useDispatch } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	SetLayoutColumnsAction,
	selectLayoutColumns,
	setLayoutColumns
} from 'store/data/projectDashboard';
import { ActionPayload } from 'store/types';

type Data = number;

export function useLayoutColumns(): LocalOperationResult<
	Data,
	(layoutColumns: ActionPayload<SetLayoutColumnsAction>['layoutColumns']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectLayoutColumns(state.data.projectDashboard));

	function handler(layoutColumns: ActionPayload<SetLayoutColumnsAction>['layoutColumns']) {
		dispatch(setLayoutColumns({ layoutColumns }));
	}

	return [data, handler];
}

import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import { useMemo } from 'react';
import {
	ActionTypes,
	NewNotification,
	addQueueNotificationAction,
	selectNotificationHistory,
	selectQueueNotifications
} from 'store/data/notifications';
import { Nullable } from 'types/index';

export function useAddQueueNotification(): OperationResult<
	Nullable<NewNotification[]>,
	(notification: NewNotification) => void
> {
	const dispatch = useDispatch();

	const notifications = useSelector(state => selectNotificationHistory(state.data.notifications));

	const queueNotifications = useSelector(state =>
		selectQueueNotifications(state.data.notifications)
	);

	const [{ loading, error }] = useActivity(ActionTypes.GET_NOTIFICATION_HISTORY);

	const fetched = useMemo(() => notifications !== null, [notifications]);

	function handler(notification: NewNotification) {
		dispatch(addQueueNotificationAction({ notification }));
	}

	return [
		{
			loading,
			error,
			fetched,
			data: queueNotifications
		},
		handler
	];
}

import { useSelector } from 'hooks/utils';
import { selectIsVariableInCalculationCases } from 'store/data/variables';

export function useIsVariableInCalculationCases(variableName: string) {
	return useSelector(state =>
		selectIsVariableInCalculationCases(state.data.variables, {
			variableName
		})
	);
}

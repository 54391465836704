import { createSelector } from 'reselect';
import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getSnapshotsFetched({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) {
		return byProjectId[projectId].ids !== null;
	}

	return false;
}

function getSnapshotById({ byId }: State, snapshotId: string) {
	return byId[snapshotId];
}

function getActiveSnapshot({ projectId, byProjectId }: State) {
	return projectId && byProjectId[projectId] ? byProjectId[projectId].active : null;
}

function getSnapshotIds({ projectId, byProjectId }: State) {
	return projectId && byProjectId[projectId] ? byProjectId[projectId].ids : [];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectAreSnapshotsFetched = createSelector([getSnapshotsFetched], fetched => fetched);

export const selectSnapshotById = createSelector([getSnapshotById], snapshot => snapshot);

export const selectSnapshotIds = createSelector([getSnapshotIds], ids => ids);

export const selectActiveSnapshot = createSelector([getActiveSnapshot], id => id);

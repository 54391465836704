import { patientLogin, ActionTypes } from 'store/auth';
import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from '../utils/useActivity';

export function usePatientLoggedIn(): OperationResult<
	boolean,
	(username: string, password: string) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.auth.isPatientLoggedIn);

	const [{ loading, error }] = useActivity(ActionTypes.PATIENT_LOGIN);

	function handler(username: string, password: string) {
		dispatch(patientLogin(username, password));
	}

	return [{ data, loading, error }, handler];
}

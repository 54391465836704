import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { TemplatePageViews } from 'types/index';
import { Tabs } from './TemplatesPageHeader.style';
import {
	useTranslation,
	useUsername,
	usePermissions,
	useEditableTemplatesIds,
	useNewBlankTemplate,
	usePageView,
	useTemplatesSearchTerm
} from 'hooks/store';

export function TemplatesPageHeader() {
	const { translate } = useTranslation();

	const currentUserId = useUsername() ?? '';
	const { hasVariablesWriteAccess } = usePermissions();

	const editableTemplatesIds = useEditableTemplatesIds();

	const hasTemplates = editableTemplatesIds.length > 0;

	const buildNewBlankTemplate = useNewBlankTemplate();

	const [pageView, setPageView] = usePageView();
	const [term, setSearchTerm] = useTemplatesSearchTerm();

	const myTemplates = translate(({ templatesHeader }) => templatesHeader.myTemplates);
	const otherTemplates = translate(({ templatesHeader }) => templatesHeader.otherTemplates);

	const pageViews = [TemplatePageViews.MyTemplates, TemplatePageViews.OtherTemplates];

	const onOtherTemplatesView = pageView === TemplatePageViews.OtherTemplates;

	function handleCreateTemplate() {
		buildNewBlankTemplate({
			owner: currentUserId
		});
	}

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) => (
					<Flex
						justify={j => j.end}
						css={`
							.search-templates-input {
								max-width: 27rem;
							}
						`}
					>
						{onOtherTemplatesView && (
							<SearchInput
								className="search-templates-input"
								term={term}
								placeholder={translate(({ templates }) => templates.searchTemplate)}
								onChangeTerm={value => setSearchTerm({ term: value })}
								usedInHeader
							/>
						)}
						{hasVariablesWriteAccess &&
							hasTemplates &&
							addButton({
								label: translate(({ templates }) => templates.createNewTemplate),
								disabled: onOtherTemplatesView,
								action: handleCreateTemplate
							})}
					</Flex>
				)}
			/>
			<Header.Title
				title={translate(dict => dict.templates.templates)}
				component={
					<div>
						<Tabs
							labels={[myTemplates, otherTemplates]}
							onChangeTabs={tabIndex => {
								const selectedPageView = pageViews[tabIndex];

								setPageView(selectedPageView);
							}}
						/>
					</div>
				}
			/>
		</>
	);
}

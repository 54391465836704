import { VariableType } from 'types/data/variables/constants';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { Input } from 'components/UI/Inputs/Input';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { Modal } from 'components/UI/Modal';
import { Pagination } from 'components/UI/Pagination';
import { TagInput } from 'components/UI/TagInput';
import { DEPENDENCY_OPTIONS } from 'consts';
import { arrayUtils } from 'helpers/arrays';
import { isDependencyValid } from 'helpers/dependencies';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { buildVariableCategoriesMap } from 'helpers/variables';
import {
	useDependenciesContext,
	useTranslation,
	useUpdateDependencies,
	useVariablesData
} from 'hooks/store';
import {
	useCompletedAction,
	useKeyPress,
	useMutableState,
	useOutsideClick,
	usePaginate
} from 'hooks/utils';
import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
	Dependency,
	DependencyOperators,
	DependencyType,
	SubRows,
	TableDependency
} from 'store/data/dependencies';
import { InputType, SelectItem } from 'types/index';

interface AddNewRuleModalProps {
	initialDependencies: { active: boolean; dependencies: Dependency[] };
	editedDraftDependencies: Dependency[];
	onClose: () => void;
	dependencySuppliersSelectItems: { label: string; value: string }[];
	getDependantVariablesSelectItems: (
		dependency: Dependency | TableDependency | SubRows
	) => SelectItem[];
	dependantVariablesSelectItems: { label: string; value: string }[];
	context: string | null;
	newDependencyAdded: Dependency;
	collapseAllRules?: () => void;
	parseOperatorCell: (value: string) => { label: string; value: DependencyOperators };
}
export function AddNewRuleModal({
	onClose,
	initialDependencies,
	editedDraftDependencies,
	dependencySuppliersSelectItems,
	getDependantVariablesSelectItems,
	dependantVariablesSelectItems,
	context,
	newDependencyAdded,
	collapseAllRules,
	parseOperatorCell
}: AddNewRuleModalProps) {
	const { translate } = useTranslation();

	const [initialDraftDependecy, setInitialDraftDependency] = useState({
		dependencyName: '',
		supplierVariableName: '',
		dependencyType: '' as DependencyType,
		dependantVariables: [
			{
				dependantId: '',
				operator: DependencyOperators.EQUAL_TO,
				supplierValueCondition: '',
				filteredValues: [],
				dependantVariableName: ''
			}
		]
	});
	const [newDraftDependency, setNewDraftDependency] = useState<Dependency>(newDependencyAdded);

	const [editedDependantVariablesSelectItems, setEditedDependantVariablesSelectItems] = useState(
		dependantVariablesSelectItems
	);

	const [
		{ loading: updatingDependencies, error: errorUpdatingDependencies },
		updateDependencies
	] = useUpdateDependencies();

	const variablesData = useVariablesData({ initial: true });
	const { variablesMap } = variablesData;

	const variable = variablesMap[newDraftDependency.dependantVariables[0].dependantVariableName];

	const [selectedCategories, setSelectedCategories] = useMutableState(
		newDraftDependency.dependantVariables[0].filteredValues
	);

	const supplierVariable = variablesMap[newDraftDependency.supplierVariableName];
	const supplierVariableType = supplierVariable.type;

	const {
		actions: {
			changeDependencyType,
			changeDependencyDescription,
			changeDependencyVariable,
			setDependantSupplierValueCondition,
			changeDependantVariable,
			changeDependantOperator,
			setDependantFilteredValues,
			deleteDependency
		}
	} = useDependenciesContext();

	useEffect(() => collapseAllRules && collapseAllRules(), []);

	useEffect(() => {
		if (editedDraftDependencies.length > 0 && editedDraftDependencies[0].dependencyName) {
			setInitialDraftDependency({
				...initialDraftDependecy,
				dependencyName: editedDraftDependencies[0].dependencyName
			});
		}
	}, [editedDraftDependencies]);

	useEffect(() => {
		setEditedDependantVariablesSelectItems(
			getDependantVariablesSelectItems(newDraftDependency)
		);
	}, [newDraftDependency.supplierVariableName]);

	// ****HANDLE TYPE****
	const translateLabel = (label: string) => {
		switch (label) {
			case 'Visibility':
				return translate(dict => dict.dependencies.types.visibility);
			case 'Filtering':
				return translate(dict => dict.dependencies.types.filtering);
			default:
				return 'N/A';
		}
	};

	const dependencyOptions = DEPENDENCY_OPTIONS.map(item => {
		return {
			label: translateLabel(item.label),
			value: item.value
		};
	});

	function handleDependencyTypeVariable(item: string) {
		setNewDraftDependency({
			...newDraftDependency,
			dependencyType: item as DependencyType
		});
	}

	// ** HANDLE OPERATOR CHANGE ***
	function handleDependencyOperatorVariable(operator: string) {
		setNewDraftDependency(prevNewDraftDependency => {
			const updatedNewDependantVariables = [...prevNewDraftDependency.dependantVariables];

			updatedNewDependantVariables[0].operator = operator as DependencyOperators;

			return { ...prevNewDraftDependency, dependantVariables: updatedNewDependantVariables };
		});
	}

	const shouldChangeOperator =
		supplierVariableType == VariableType.Float ||
		supplierVariableType == VariableType.Integer ||
		supplierVariableType == VariableType.Date ||
		supplierVariableType == VariableType.DateTime;

	const operatorOptions = Object.values(DependencyOperators).map(operator =>
		parseOperatorCell(operator)
	);

	// *** HANDLE DEPENDANT NAME ***
	function handleChangeDependantVariable(value: string) {
		setNewDraftDependency(prevNewDraftDependency => {
			const updatedNewDependantVariables = [...prevNewDraftDependency.dependantVariables];

			updatedNewDependantVariables[0].dependantVariableName = value;

			return { ...prevNewDraftDependency, dependantVariables: updatedNewDependantVariables };
		});

		setSelectedCategories([]);
	}

	// *** RESULT CONFIGURATION  ***
	function onConfirm(categories: string[]) {
		setDependantFilteredValues({
			dependencyName: newDraftDependency.dependencyName,
			dependantId: newDraftDependency.dependantVariables[0].dependantId,
			filteredValues: categories
		});
	}
	function handleCreate() {
		if (!isFormValid()) return;
		onConfirm(selectedCategories);
	}
	function isFormValid() {
		return selectedCategories.length > 0;
	}
	function onCheck(category: string) {
		setSelectedCategories(state => {
			if (state.includes(category)) return state.filter(c => c !== category);
			state.push(category);
		});
	}
	function onToggleAll(flag: boolean) {
		const categories = flag ? getVariableCategories() : [];
		setSelectedCategories(categories);
	}
	function getVariableCategories(): string[] {
		if (variable) return variable.categories.map(c => c.value);
		return [];
	}
	function getSelectCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];
		if (variable) {
			variable.categories.forEach(c => {
				const item: SelectItem = {
					label: c.label || c.value,
					value: c.value
				};
				items.push(item);
			});
		}
		return items;
	}

	function getSelectedCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];
		if (variable && newDraftDependency.dependantVariables[0].filteredValues.length > 0) {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);
			selectedCategories.forEach(value => {
				const category = categoriesMap[value];
				const item: SelectItem = {
					label: category.label || category.value,
					value: category.value
				};
				items.push(item);
			});
		}
		return items;
	}

	function handleMoveCategoryValue(input: { sourceIndex: number; destinationIndex: number }) {
		const { sourceIndex, destinationIndex } = input;
		if (sourceIndex === destinationIndex) return;
		const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
			pageIndex,
			pageSize
		});
		setSelectedCategories(state =>
			arrayUtils.move(state, absoluteIndexes.sourceIndex, absoluteIndexes.destinationIndex)
		);
	}

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(getSelectedCategoryItems(), {
		threshold: 10,
		pageSize: 10
	});

	// ADD RESULT IN  NEW DRAFT DEPENDENCY
	useEffect(() => {
		setNewDraftDependency(prevNewDraftDependency => {
			const updatedNewDependantVariables = [...prevNewDraftDependency.dependantVariables];
			if (updatedNewDependantVariables[0].filteredValues) {
				updatedNewDependantVariables[0].filteredValues = selectedCategories;
			}
			return { ...prevNewDraftDependency, dependantVariables: updatedNewDependantVariables };
		});
	}, [selectedCategories]);

	// Update Dependant variables if dependency type is changed
	useEffect(() => {
		setEditedDependantVariablesSelectItems(
			getDependantVariablesSelectItems(newDraftDependency)
		);
	}, [newDraftDependency.dependencyType]);

	// ***  UPDATE newDependencyAdded WITH CHANGES OF NEW DRAFT DEPENDENCY ***
	const handleDependencyChanges = () => {
		if (newDraftDependency.dependencyName) {
			changeDependencyVariable({
				dependencyName: newDraftDependency.dependencyName,
				supplierVariableName: newDraftDependency.supplierVariableName
			});

			changeDependencyType({
				dependencyName: newDraftDependency.dependencyName,
				dependencyType: newDraftDependency.dependencyType as DependencyType
			});

			setDependantSupplierValueCondition({
				dependencyName: newDraftDependency.dependencyName,
				dependantId: newDraftDependency.dependantVariables[0].dependantId,
				supplierValueCondition:
					newDraftDependency.dependantVariables[0].supplierValueCondition
			});

			changeDependantVariable({
				dependencyName: newDraftDependency.dependencyName,
				dependantId: newDraftDependency.dependantVariables[0].dependantId,
				dependantVariableName:
					newDraftDependency.dependantVariables[0].dependantVariableName
			});

			changeDependantOperator({
				dependencyName: newDraftDependency.dependencyName,
				dependantId: newDraftDependency.dependantVariables[0].dependantId,
				operator: newDraftDependency.dependantVariables[0].operator
			});
		}

		// SETTING RESULT
		handleCreate();
		changeDependencyDescription({
			dependencyName: newDraftDependency.dependencyName,
			description: newDraftDependency.description
		});
	};

	// ***  UPDATE DRAFT DEPENDENCIES LIST  WITH NEW DRAFT DEPENDENCY CHANGES ***
	const draftDependencies = useMemo(() => {
		const draft = cloneDeep(editedDraftDependencies);

		const editedDependencies = draft.map((dep: Dependency) => {
			const matchingTableDependency = newDraftDependency.dependencyName == dep.dependencyName;

			if (matchingTableDependency) {
				const updatedDependants = dep.dependantVariables.map(dependant => {
					const matchingDependant =
						newDraftDependency.dependantVariables[0].dependantId ===
						dependant.dependantId;
					if (matchingDependant) {
						return {
							...dependant,
							supplierValueCondition:
								newDraftDependency.dependantVariables[0].supplierValueCondition,
							dependantVariableName:
								newDraftDependency.dependantVariables[0].dependantVariableName,
							filteredValues: newDraftDependency.dependantVariables[0].filteredValues
						};
					}

					return dependant;
				});
				return {
					...dep,
					supplierVariableName: newDraftDependency.supplierVariableName,
					description: newDraftDependency.description,
					dependencyType: newDraftDependency.dependencyType,
					dependantVariables: updatedDependants
				};
			}
			return dep;
		});

		return editedDependencies;
	}, [editedDraftDependencies, newDraftDependency]);

	const hasChanges = useMemo(
		() => !isEqual(initialDraftDependecy, newDraftDependency),

		[newDraftDependency]
	);

	//  CHECK IF NEW DRAFT DEPENDENCY IS  VALID
	const areDependenciesValid = useMemo(() => {
		let valid = true;
		const invalidDependencyNames: string[] = [];

		const validDependency = isDependencyValid(newDraftDependency);
		if (!validDependency) {
			valid = false;
			invalidDependencyNames.push(newDraftDependency.supplierVariableName);
		}
		return valid;
	}, [draftDependencies]);

	const canUpdate = useMemo(
		() => hasChanges && areDependenciesValid,
		[hasChanges, areDependenciesValid]
	);

	// HANDLE MODAL  SUBMIT
	const handleFormSubmit = () => {
		handleDependencyChanges();

		if (!canUpdate) return;

		updateDependencies({
			active: initialDependencies.active,
			dependencies: draftDependencies,
			setName: context ?? undefined
		});
	};

	//  AVOID CLOSING THE MODAL IF THERE ARE ANY ERRORS
	useCompletedAction(
		updatingDependencies,
		errorUpdatingDependencies,
		// SUCCESS CALLBACK
		() => {
			onClose();
		}
	);

	const modalRef = useRef<HTMLDivElement>(null);

	function onCancel() {
		deleteDependency({
			dependencyName: newDraftDependency.dependencyName
		});
		onClose();
	}

	useOutsideClick(onCancel, [modalRef]);

	useKeyPress({
		onEscapeKeyPress: () => {
			onCancel();
		}
	});

	return (
		<Modal
			title={'Add new rule '}
			visible
			modalRef={modalRef}
			onClose={onCancel}
			close
			enterAsPrimaryOnClick
			primary={{
				label: translate(({ buttons }) => buttons.done),

				disabled: !canUpdate,
				onClick: handleFormSubmit,
				loading: updatingDependencies
			}}
			neutral={
				hasChanges && {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onCancel
				}
			}
		>
			<Flex column>
				<Flex fullWidth marginOffset={{ bottom: 1.2 }}>
					{/* SUPPLIER VARIABLE */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							label={translate(dict => dict.dependencies.builder.tableView.supplier)}
							className="creatable-select-small"
							items={dependencySuppliersSelectItems}
							value={
								newDraftDependency &&
								newDraftDependency.supplierVariableName !== undefined
									? dependencySuppliersSelectItems.find(
											item =>
												item.value ===
												newDraftDependency.supplierVariableName
									  )
									: null
							}
							onValueSelected={item =>
								item &&
								setNewDraftDependency({
									...newDraftDependency,
									supplierVariableName: item
								})
							}
							canClear={false}
						/>
					</Flex>

					<Flex fullWidth>
						{/* DEPENDENCY TYPE  */}
						<CreatableSelect
							label={translate(
								dict => dict.dependencies.builder.tableView.dependencyType
							)}
							className="creatable-select-small"
							items={dependencyOptions}
							value={dependencyOptions.find(
								item => item.value === newDraftDependency.dependencyType
							)}
							onValueSelected={item => item && handleDependencyTypeVariable(item)}
							canClear={false}
						/>
					</Flex>
				</Flex>

				<Flex>
					{/* DEPENDENCY OPERATOR  */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							disabled={!shouldChangeOperator}
							label={translate(dict => dict.dependencies.builder.tableView.operator)}
							className="creatable-select-small"
							items={operatorOptions}
							value={
								shouldChangeOperator
									? parseOperatorCell(
											newDraftDependency.dependantVariables[0].operator
									  )
									: parseOperatorCell(
											initialDraftDependecy.dependantVariables[0].operator
									  )
							}
							onValueSelected={item => item && handleDependencyOperatorVariable(item)}
							canClear={false}
						/>
					</Flex>

					{/* SUPPLIER  VALUE CONDITION */}
					{[VariableType.Category, VariableType.CategoryMultiple].includes(
						supplierVariableType
					) ? (
						<Flex fullWidth>
							<CreatableSelect
								label={translate(dict => dict.dependencies.builder.tableView.value)}
								className="creatable-select-small"
								placeholder={translate(
									dict => dict.dependencies.directDependency.selectCategory
								)}
								value={{
									label: newDraftDependency.dependantVariables[0]
										.supplierValueCondition,
									value: newDraftDependency.dependantVariables[0]
										.supplierValueCondition
								}}
								items={
									supplierVariable
										? supplierVariable.categories.map(category => ({
												label: category.label,
												value: category.value
										  }))
										: []
								}
								onValueSelected={value => {
									if (value) {
										setNewDraftDependency(prevNewDraftDependency => {
											const updatedNewDependantVariables = [
												...prevNewDraftDependency.dependantVariables
											];

											updatedNewDependantVariables[0].supplierValueCondition =
												value;

											return {
												...prevNewDraftDependency,
												dependantVariables: updatedNewDependantVariables
											};
										});
									}
								}}
								canClear={false}
							/>
						</Flex>
					) : (
						<Flex fullWidth>
							{/* NUMERIC (INTEGER / FLOAT) */}
							{(supplierVariableType === VariableType.Float ||
								supplierVariableType === VariableType.Integer) && (
								<>
									<Input
										label={translate(
											dict => dict.dependencies.builder.tableView.value
										)}
										className="input-small"
										type={InputType.Number}
										value={
											newDraftDependency.dependantVariables[0]
												.supplierValueCondition
										}
										onChange={e => {
											setNewDraftDependency(prevNewDraftDependency => {
												const updatedNewDependantVariables = [
													...prevNewDraftDependency.dependantVariables
												];

												updatedNewDependantVariables[0].supplierValueCondition =
													e.target.value;

												return {
													...prevNewDraftDependency,
													dependantVariables: updatedNewDependantVariables
												};
											});
										}}
									/>
								</>
							)}

							{/* DATE || DATETIME*/}
							{(supplierVariableType === VariableType.Date ||
								supplierVariableType === VariableType.DateTime) && (
								<>
									{supplierVariableType === VariableType.Date && (
										<Input
											label={translate(
												dict => dict.dependencies.builder.tableView.value
											)}
											className="date-input-small"
											type={InputType.Date}
											value={
												newDraftDependency.dependantVariables[0]
													.supplierValueCondition
											}
											onDateChange={({ formattedDate: from }) =>
												setNewDraftDependency(prevNewDraftDependency => {
													const updatedNewDependantVariables = [
														...prevNewDraftDependency.dependantVariables
													];

													updatedNewDependantVariables[0].supplierValueCondition =
														from;

													return {
														...prevNewDraftDependency,
														dependantVariables:
															updatedNewDependantVariables
													};
												})
											}
										/>
									)}
									{supplierVariableType === VariableType.DateTime && (
										<Flex fullWidth>
											<DateTimeInput
												value={
													newDraftDependency.dependantVariables[0]
														.supplierValueCondition
												}
												onChange={value =>
													setNewDraftDependency(
														prevNewDraftDependency => {
															const updatedNewDependantVariables = [
																...prevNewDraftDependency.dependantVariables
															];

															updatedNewDependantVariables[0].supplierValueCondition =
																value;

															return {
																...prevNewDraftDependency,
																dependantVariables:
																	updatedNewDependantVariables
															};
														}
													)
												}
												options={{
													small: true,
													label: translate(
														dict =>
															dict.dependencies.builder.tableView
																.value
													)
												}}
											/>
										</Flex>
									)}
								</>
							)}
							{/* STRING */}
							{supplierVariableType === VariableType.String && (
								<>
									<Input
										label={translate(
											dict => dict.dependencies.builder.tableView.value
										)}
										className="input-small"
										type={InputType.Text}
										value={
											newDraftDependency.dependantVariables[0]
												.supplierValueCondition
										}
										onChange={e =>
											setNewDraftDependency(prevNewDraftDependency => {
												const updatedNewDependantVariables = [
													...prevNewDraftDependency.dependantVariables
												];
												updatedNewDependantVariables[0].supplierValueCondition =
													e.target.value;

												return {
													...prevNewDraftDependency,
													dependantVariables: updatedNewDependantVariables
												};
											})
										}
									/>
								</>
							)}
						</Flex>
					)}
				</Flex>

				<Flex fullWidth marginOffset={{ top: 1.2 }}>
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						{/* DEPENDANT VARIABLE */}
						<CreatableSelect
							label={translate(
								dict => dict.dependencies.builder.tableView.dependantVariables
							)}
							className="creatable-select-small"
							placeholder={translate(
								dict => dict.dependencies.directDependency.selectVariable
							)}
							value={editedDependantVariablesSelectItems.find(
								item =>
									item.value ===
									newDraftDependency.dependantVariables[0].dependantVariableName
							)}
							items={editedDependantVariablesSelectItems}
							onValueSelected={value => {
								if (value) {
									handleChangeDependantVariable(value);
								}
							}}
							canClear={false}
						/>
					</Flex>

					{/* FILTERED VALUES  - RESULT */}
					<Flex fullWidth>
						<Dropdown
							disabled={
								newDraftDependency.dependencyType !== DependencyType.Filtering
							}
							label={translate(dict => dict.dependencies.builder.tableView.result)}
							title={`${selectedCategories.length} ${
								selectedCategories.length === 1
									? translate(dict => dict.terms.value)
									: translate(dict => dict.terms.values)
							} ${translate(dict => dict.terms.selected)}`}
							button
						>
							<Flex paddingOffset={{ x: 1.2, y: 1.6 }}>
								<SelectMultiple
									items={getSelectCategoryItems()}
									onSelect={item => onCheck(item.value)}
									selectedItems={selectedCategories}
									onToggleAll={onToggleAll}
								/>
							</Flex>
						</Dropdown>
					</Flex>
				</Flex>
				{newDraftDependency.dependantVariables[0].filteredValues.length > 0 && (
					<Flex column fullWidth marginOffset={{ top: 1.2 }}>
						<InputLabel
							label={translate(
								dict =>
									dict.dependencies.dependantFiltering
										.configureFilteringValuesModal.previewSelected.label
							)}
						/>

						{shouldPaginate && (
							<Flex marginOffset={{ bottom: 0.4 }}>
								<Pagination
									totalCountLabel={translate(dict => dict.terms.values)}
									pageIndex={pageIndex}
									pageSize={pageSize}
									pagesCount={pagesCount}
									changePage={changePage}
									changePageSize={changePageSize}
									totalCount={getSelectedCategoryItems().length}
								/>
							</Flex>
						)}

						<TagInput
							hint={translate(
								dict =>
									dict.dependencies.dependantFiltering
										.configureFilteringValuesModal.previewSelected.hint
							)}
							items={selectedCategoryItems}
							onMove={handleMoveCategoryValue}
							readOnly
							sortable
						/>
					</Flex>
				)}

				<Flex fullWidth marginOffset={{ top: 1.2 }}>
					{/* DEPENDENCY DESCRIPTION */}
					<Input
						type={InputType.Textarea}
						label={translate(
							dict => dict.dependencies.builder.tableView.ruleDescription
						)}
						className="description-input"
						value={newDraftDependency.description}
						onChange={e =>
							setNewDraftDependency({
								...newDraftDependency,
								description: e.target.value
							})
						}
					/>
				</Flex>
			</Flex>
		</Modal>
	);
}

import { useDispatch } from 'hooks/utils';
import { pauseJADBioProgress } from 'store/addons/jadbio';
import { LocalOperation } from 'hooks/store/types';

export function usePauseCheckingJADBioProgressAction(
	analysisId: string
): LocalOperation<() => void> {
	const dispatch = useDispatch();

	function handler() {
		dispatch(pauseJADBioProgress(analysisId, true));
	}

	return handler;
}

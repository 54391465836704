import { stringAsBoolean } from 'helpers/generic';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	ActionTypes,
	Notification,
	getNotificationHistory,
	selectNotificationHistory
} from 'store/data/notifications';
import { Nullable } from 'types/index';

export function useNotifications(): OperationResult<
	Nullable<Notification[]>,
	(resetOffset: boolean) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectNotificationHistory(state.data.notifications));

	const [{ loading, error }] = useActivity(ActionTypes.GET_NOTIFICATION_HISTORY);

	const SHOW_NOTIFICATIONS = stringAsBoolean(process.env.REACT_APP_USE_NOTIFICATIONS!);

	function handler(resetOffset: boolean) {
		if (SHOW_NOTIFICATIONS) {
			dispatch(getNotificationHistory(resetOffset));
		}
	}

	const fetched = data !== null;

	return [
		{
			loading,
			error,
			fetched,
			data: data ? Object.values(data) : null
		},
		handler
	];
}

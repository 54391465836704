import {
	CompareNumericResultsDataV2,
	CompareNumericResultsV2,
	GetCompareNumericResponseV2
} from 'api/data/analyses';

export function parseCompareNumericResponse(
	res: GetCompareNumericResponseV2
): CompareNumericResultsV2 {
	const result: CompareNumericResultsV2 = {
		data: {}
	};

	if (result.data) {
		res.data.result.forEach(data => {
			if (!(result.data as CompareNumericResultsDataV2)[data.values.variable.name]) {
				(result.data as CompareNumericResultsDataV2)[data.values.variable.name] = [];
			}
			(result.data as CompareNumericResultsDataV2)[data.values.variable.name].push({
				...data.values,
				group1: data.groupIdentifiers[0],
				group2: data.groupIdentifiers[1]
			});
		});
	}

	// to do
	// groupedData.forEach(data => {});

	return result;
}

import { DATA_URL, STATISTICS_URL, sendRequest } from 'api/utils';
import { parseApiStatus } from 'helpers/statuses';

import {
	// GET ONE
	GetStatusInput,
	GetStatusRequest,
	GetStatusResponse,
	GetStatusOutput,
	// GET ALL
	GetStatusesInput,
	GetStatusesRequest,
	GetStatusesResponse,
	GetStatusesOutput,
	// CREATE
	CreateStatusInput,
	CreateStatusRequest,
	CreateStatusResponse,
	CreateStatusOutput,
	// UPDATE
	UpdateStatusInput,
	UpdateStatusRequest,
	UpdateStatusResponse,
	UpdateStatusOutput,
	// DELETE
	DeleteStatusInput,
	DeleteStatusRequest,
	DeleteStatusResponse,
	// CREATE STATUSES
	CreateStatusesInput,
	CreateStatusesRequest,
	CreateStatusesResponse,
	CreateStatusesOutput
} from './types';
import { AxiosRequestConfig } from 'axios';

const methods = {
	getStatus: 'getStatus',
	getStatuses: 'getStatuses',
	createStatus: 'createStatus',
	updateStatus: 'updateStatus',
	deleteStatus: 'deleteStatus',
	createStatuses: 'createStatuses'
};

export default () => ({
	async getStatus(input: GetStatusInput): Promise<GetStatusOutput> {
		const { data } = await sendRequest<GetStatusRequest, GetStatusResponse>(STATISTICS_URL, {
			method: methods.getStatus,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const status = parseApiStatus(data.status);

		const output: GetStatusOutput = { status };

		return output;
	},

	async getStatuses(
		input: GetStatusesInput,
		config?: AxiosRequestConfig
	): Promise<GetStatusesOutput> {
		const { data } = await sendRequest<GetStatusesRequest, GetStatusesResponse>(
			STATISTICS_URL,
			{
				method: methods.getStatuses,
				...input
			},
			config
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const statuses = data.statuses.map(apiStatus => parseApiStatus(apiStatus));

		const output: GetStatusesOutput = { statuses };

		return output;
	},

	async createStatus(input: CreateStatusInput): Promise<CreateStatusOutput> {
		const { data } = await sendRequest<CreateStatusRequest, CreateStatusResponse>(DATA_URL, {
			method: methods.createStatus,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(data.message);
		}

		const status = parseApiStatus(data.status);

		const output: CreateStatusOutput = { status };

		return output;
	},

	async updateStatus(input: UpdateStatusInput): Promise<UpdateStatusOutput> {
		const { data } = await sendRequest<UpdateStatusRequest, UpdateStatusResponse>(DATA_URL, {
			method: methods.updateStatus,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const status = parseApiStatus(data.status);

		const output: UpdateStatusOutput = { status };

		return output;
	},

	async deleteStatus(input: DeleteStatusInput): Promise<void> {
		const { data } = await sendRequest<DeleteStatusRequest, DeleteStatusResponse>(DATA_URL, {
			method: methods.deleteStatus,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async createStatuses(input: CreateStatusesInput): Promise<CreateStatusesOutput> {
		const { data } = await sendRequest<CreateStatusesRequest, CreateStatusesResponse>(
			DATA_URL,
			{
				method: methods.createStatuses,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const statuses = data.statuses.map(apiStatus => parseApiStatus(apiStatus));

		const output: CreateStatusesOutput = { statuses };

		return output;
	}
});

import produce from 'immer';
import { useMemo } from 'react';

import { Variable, VariableCategory } from 'api/data/variables';
import { initVariableCategory } from 'helpers/variables';
import { useTranslation } from 'hooks/store';

import { Border, Container } from './CategoryValuesBasic.style';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { Typography } from 'components/UI/Typography';
import { arrayUtils, truncateList } from 'helpers/arrays';
import { EntryVariableType } from 'types/data/variables/constants';

interface Props {
	variable: Variable;
	initialVariable: Variable;
	error?: string;
	readOnly: boolean;
	previewEnabled?: boolean;
	onPreviewClick: () => void;
	onToggle: () => void;
	onChange: (categoryValues: VariableCategory[]) => void;
	onSubmit: () => void;
}

export function CategoryValuesBasic({
	variable,
	initialVariable,
	error,
	readOnly,
	previewEnabled = true,
	onPreviewClick,
	onToggle,
	onChange,
	onSubmit
}: Props) {
	const { translate } = useTranslation();

	const { truncated: truncatedCategories, rest: hiddenCategories } = useMemo(() => {
		// mimic default pagination items-per-page
		const MAX_VISIBLE_CATEGORY_VALUES = 10;

		return truncateList(variable.categories, MAX_VISIBLE_CATEGORY_VALUES);
	}, [variable.categories]);

	function hasFixedCategories() {
		return variable.fixedCategories;
	}

	function wasFixedCategoriesFalse() {
		return !initialVariable.fixedCategories;
	}

	function getAutoFocus() {
		return wasFixedCategoriesFalse() && hasFixedCategories();
	}

	function handlePreview() {
		if (!previewEnabled) return;

		if (readOnly) onPreviewClick();
	}

	function handleToggle() {
		if (readOnly) return;

		onToggle();
	}

	function getError() {
		if (readOnly) return;

		return error;
	}

	const tagInput_controller = {
		items: readOnly ? truncatedCategories : variable.categories,
		create(value: string) {
			if (readOnly) return;

			const updated = produce(variable.categories, draft => {
				const categoryValue = initVariableCategory({ value });

				draft.push(categoryValue);
			});

			onChange(updated);
		},
		createMany(values: string[]) {
			if (readOnly) return;

			const updated = produce(variable.categories, draft => {
				values.forEach(value => {
					const categoryValue = initVariableCategory({ value });

					draft.push(categoryValue);
				});
			});

			onChange(updated);
		},
		delete(value: string) {
			if (readOnly) return;

			const updated = variable.categories.filter(c => c.value !== value);

			onChange(updated);
		},
		deleteAll() {
			if (readOnly) return;

			onChange([]);
		},
		move(input: { sourceIndex: number; destinationIndex: number }) {
			if (readOnly) return;

			const { sourceIndex, destinationIndex } = input;

			const updated = arrayUtils.move(variable.categories, sourceIndex, destinationIndex);

			onChange(updated);
		}
	};

	return (
		<Container previewEnabled={previewEnabled && readOnly} onClick={handlePreview}>
			<Switch
				label={translate(dict => dict.variableFields.fixedValues)}
				description={translate(dict => dict.variableFields.fixedValuesInfo)}
				dataTestId="fixedValuesToggle"
				on={hasFixedCategories()}
				disabled={variable.entryType === EntryVariableType.Calculated}
				onChange={handleToggle}
				propagate={readOnly}
			/>

			{hasFixedCategories() && (
				<>
					<Spacer size={s => s.m} />

					<TagInput
						items={tagInput_controller.items.map(c => ({
							label: c.label || c.value,
							value: c.value
						}))}
						autoFocus={getAutoFocus()}
						placeholder={
							getError() ||
							translate(dict => dict.variableFields.fixedValuesInputPlaceholder)
						}
						error={getError()}
						readOnly={readOnly}
						onCreate={tagInput_controller.create}
						onCreateMany={tagInput_controller.createMany}
						onDelete={tagInput_controller.delete}
						onDeleteAll={tagInput_controller.deleteAll}
						onMove={tagInput_controller.move}
						onSubmit={onSubmit}
						sortable={!readOnly}
					/>

					{readOnly && hiddenCategories.length > 0 && (
						<Typography.Hint marginOffset={{ top: 0.4, left: 0.4 }}>
							{`+ ${hiddenCategories.length} ${translate(
								dict => dict.terms.moreLowerCase
							)}`}
						</Typography.Hint>
					)}
				</>
			)}

			<Border />
		</Container>
	);
}

import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';

export const Wrapper = styled.div`
	padding: 2.4rem;
	display: flex;
	flex-wrap: wrap;

	background-color: ${Colors.background.disabled};

	@media ${MediaQueries.maxWidth.sm} {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const LicenceInfoContainer = styled.div`
	margin-left: 2.4rem;
	flex: 1;
`;

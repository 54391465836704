import {
	ArithmeticNode,
	CalculationActions,
	Variable,
	VariableNode
} from 'api/data/variables/types';
import { VariableType, ArithmeticOperator, OperandType } from 'types/data/variables/constants';
import { useTranslation } from 'hooks/store';
import { useContext, useMemo, useState } from 'react';
import { SelectItem } from 'types/index';
import {
	RuleContainer,
	CalculationRow,
	CalculationColumn,
	ReadonlyField,
	AddButton
} from '../CalculatedVariable.style';
import { Dropdown } from 'components/UI/Dropdown';
import { DynamicCalculationInput } from './DynamicCalculationInput';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { CalculationDeleteIcon } from '../DeleteIcon/CalculationDeleteIcon';
import { HoverContext } from 'helpers/variables/calculatedVariables/HoverContext';

type Props = {
	node: ArithmeticNode | null;
	nodePath: string | null;
	variables: Variable[];
	draftVariableType: VariableType;
	depth: number;
	calculationActions: CalculationActions;
	isChild?: boolean;
};

export function ArithmeticOperation({
	node,
	nodePath,
	variables,
	draftVariableType,
	depth,
	isChild,
	calculationActions
}: Props) {
	const {
		onAddOperandValue,
		onAddEmptyOperandAtJsonLogicPath: onAddEmptyOperandAtPath,
		onOperatorChange
	} = calculationActions;
	const { translate } = useTranslation();

	const [isDeleteHovered, setIsDeleteHovered] = useState(false);

	const { setHoverPath } = useContext(HoverContext);

	const { firstField, secondField } = useMemo(
		() => extractFields(node, nodePath),
		[node, nodePath]
	);

	const operator = useMemo(() => extractOperator(node), [node]);

	const operatorSelectItems: SelectItem[] = [
		ArithmeticOperator.Addition,
		ArithmeticOperator.Subtraction,
		ArithmeticOperator.Multiplication,
		ArithmeticOperator.Division
	].map(operator => ({
		label: operator,
		value: operator
	}));

	function handleSetOperator(operator: ArithmeticOperator) {
		onOperatorChange(nodePath, operator);
	}

	function handleAddNewOperand(path: string, type: OperandType) {
		onAddEmptyOperandAtPath(path, type);

		if (type === OperandType.ArithmeticOperation) {
			setHoverPath && setHoverPath(path);
		}
	}

	function dropdownElements(path: string, depth: number) {
		return [
			<Dropdown.Item
				key={`arithmeticOperation-addInput`}
				title={translate(dict => dict.terms.input)}
				onClick={() => handleAddNewOperand(path, OperandType.NumberInput)}
				dataTestId={`arithmetic_add_input_${path}`}
			/>,
			<Dropdown.Item
				key={`arithmeticOperation-addVariable`}
				title={translate(dict => dict.terms.variable)}
				onClick={() => handleAddNewOperand(path, OperandType.NumberVariable)}
				dataTestId={`arithmetic_add_variable_${path}`}
			/>,

			<Dropdown.Item
				key={`arithmeticOperation-addNumericOperation`}
				title={translate(dict => dict.terms.operation)}
				onClick={() => {
					handleAddNewOperand(path, OperandType.ArithmeticOperation);
				}}
				disabled={depth == 4}
				dataTestId={`arithmetic_add_operation_${path}`}
			/>
		];
	}

	return (
		<RuleContainer
			isChild={isChild}
			isDeleteHovered={isDeleteHovered}
			onMouseEnter={e => {
				e.stopPropagation();
				return setHoverPath && setHoverPath(nodePath!);
			}}
			onMouseLeave={e => {
				e.stopPropagation();
				return setHoverPath && setHoverPath('');
			}}
			onMouseMove={e => {
				e.stopPropagation();
				if (!e.currentTarget.contains(e.relatedTarget as Node)) {
					setHoverPath && setHoverPath(nodePath!);
				}
			}}
			id={`arithmeticOperation_container_${nodePath}`}
		>
			<CalculationRow depth={depth} isChild={isChild}>
				{!isChild && (
					<CalculationColumn>
						<ReadonlyField>{translate(dict => dict.terms.valuesIs)}</ReadonlyField>
					</CalculationColumn>
				)}
				<CalculationColumn>
					{firstField.value == null || firstField.value == undefined ? (
						<Dropdown
							button
							width={24}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => {
								return (
									<AddButton
										ref={ref}
										onClick={toggle}
										full
										id={`arithmetic_add_operand_${
											firstField.path ? firstField.path : nodePath + '.0'
										}`}
									>
										{translate(dict => dict.terms.add)}
									</AddButton>
								);
							}}
						>
							{dropdownElements(firstField.path, depth)}
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={firstField.type!}
							value={firstField.value}
							valueDepth={depth}
							valuePath={firstField.path}
							onChange={value =>
								onAddOperandValue(firstField.path, firstField.type!, value)
							}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
						/>
					)}
				</CalculationColumn>

				<CalculationColumn>
					<CreatableSelect
						items={operatorSelectItems}
						value={operatorSelectItems.find(item => item.value === operator)}
						onValueSelected={operator =>
							operator && handleSetOperator(operator as ArithmeticOperator)
						}
						canClear={false}
						id={`arithmetic_operator_${nodePath}`}
					/>
				</CalculationColumn>

				<CalculationColumn>
					{secondField.value == null || secondField.value == undefined ? (
						<Dropdown
							button
							width={24}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => {
								return (
									<AddButton
										ref={ref}
										onClick={toggle}
										full
										id={`arithmetic_add_operand_${
											secondField.path ? secondField.path : nodePath + '.1'
										}`}
									>
										{translate(dict => dict.terms.add)}
									</AddButton>
								);
							}}
						>
							{dropdownElements(secondField.path, depth)}
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={secondField.type!}
							value={secondField.value}
							valueDepth={depth}
							valuePath={secondField.path}
							onChange={value =>
								onAddOperandValue(secondField.path, secondField.type!, value)
							}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
						/>
					)}
				</CalculationColumn>
			</CalculationRow>
			<CalculationDeleteIcon
				calculationActions={calculationActions}
				depth={depth}
				isChild={!!isChild}
				isDeleteHovered={isDeleteHovered}
				setIsDeleteHovered={setIsDeleteHovered}
				nodePath={nodePath}
			/>
		</RuleContainer>
	);
}

function extractFields(rootNode: ArithmeticNode | null, rootPath: string | null) {
	const fields: {
		firstField: {
			value: number | string | VariableNode | ArithmeticNode | null;
			path: string;
			type: OperandType | null;
		};
		secondField: {
			value: number | string | VariableNode | ArithmeticNode | null;
			path: string;
			type: OperandType | null;
		};
	} = {
		firstField: {
			value: null,
			path: '',
			type: null
		},
		secondField: {
			value: null,
			path: '',
			type: null
		}
	};

	if (rootNode && rootPath && ArithmeticOperator.Addition in rootNode) {
		rootNode[ArithmeticOperator.Addition].forEach((value, index) => {
			const nodePath = `${rootPath}.${ArithmeticOperator.Addition}.${index}`;

			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;

				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (rootNode && rootPath && ArithmeticOperator.Subtraction in rootNode) {
		rootNode[ArithmeticOperator.Subtraction].forEach((value, index) => {
			const nodePath = `${rootPath}.${ArithmeticOperator.Subtraction}.${index}`;

			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;
				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (rootNode && rootPath && ArithmeticOperator.Multiplication in rootNode) {
		rootNode[ArithmeticOperator.Multiplication].forEach((value, index) => {
			const nodePath = `${rootPath}.${ArithmeticOperator.Multiplication}.${index}`;

			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;

				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (rootNode && rootPath && ArithmeticOperator.Division in rootNode) {
		rootNode[ArithmeticOperator.Division].forEach((value, index) => {
			const nodePath = `${rootPath}.${ArithmeticOperator.Division}.${index}`;

			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;

				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	return fields;
}

function extractOperator(rootNode: ArithmeticNode | null) {
	if (rootNode && ArithmeticOperator.Addition in rootNode) {
		return ArithmeticOperator.Addition;
	}

	if (rootNode && ArithmeticOperator.Subtraction in rootNode) {
		return ArithmeticOperator.Subtraction;
	}

	if (rootNode && ArithmeticOperator.Multiplication in rootNode) {
		return ArithmeticOperator.Multiplication;
	}

	if (rootNode && ArithmeticOperator.Division in rootNode) {
		return ArithmeticOperator.Division;
	}

	return null;
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, createVariableSet } from 'store/data/variables';

type HandlerInput = Parameters<typeof createVariableSet>[0];

export function useCreateVariableSet(): Operation<(input: HandlerInput) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_VARIABLE_SET);

	async function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		await dispatch(createVariableSet(input));
	}

	return [{ loading, error }, handler];
}

import { StorageKeys } from 'types/index';
import { logInToJADBioAction, setTokenValidAction } from 'store/addons/jadbio';
import { useLogOutFromJADBio } from './useLogOutFromJADBio';
import { useDebounce, useDispatch, useEffectOnce } from 'hooks/utils';
import { useLogInToJADBio } from 'hooks/store';

export function useSyncStorageJADBioToken() {
	const dispatch = useDispatch();

	const logoutFromJADBio = useLogOutFromJADBio();

	const [{ data: loggedInToJADBio }] = useLogInToJADBio();

	// mocking flow : every 20 seconds, make token expire
	useDebounce(
		() => {
			// dispatch action to make token invalid
			dispatch(setTokenValidAction({ valid: false }));
		},
		[loggedInToJADBio],
		20000
	);

	function handleStorageChange({ key, newValue }: StorageEvent) {
		if (key === StorageKeys.JADBioIdToken) {
			if (newValue === null) {
				logoutFromJADBio();
			} else {
				dispatch(logInToJADBioAction());
			}
		}
	}

	useEffectOnce(() => {
		window.addEventListener('storage', handleStorageChange);

		return () => window.removeEventListener('storage', handleStorageChange);
	});
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setCollaboratorsSearchTerm } from 'store/data/collaborators';

export function useCollaboratorsSearchTerm(): LocalOperationResult<string, (term: string) => void> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.collaborators.metadata.searchTerm);

	function handler(term: string) {
		dispatch(setCollaboratorsSearchTerm({ term }));
	}

	return [data, handler];
}

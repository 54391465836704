import { apiFactory } from 'api';
import { ApiRespondToSubscriptionInvitation } from 'api/account/subscription';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/account/subscription';
import { useStatic } from 'hooks/utils';

export function useAPIRespondToSubscriptionInvitation(): ApiOperation<
	void,
	(responseToSubscription: ApiRespondToSubscriptionInvitation) => void
> {
	const [getRequestData, setRequestData] = useStatic<ApiRespondToSubscriptionInvitation>({
		isSubscriptionAccepted: false,
		userId: ''
	});

	const [{ error, loading, fetched }, addUser, resetAddUser] = useAPI<void>({
		promiseFn: () => promiseFn(getRequestData()),
		lazy: true,
		initialData: undefined,
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		activityType: ActionTypes.RESPOND_TO_SUBSCRIPTION_INVITATION
	});

	function promiseFn(input: ApiRespondToSubscriptionInvitation) {
		return apiFactory().account.subscription().respondToSubscriptionInvitation(input);
	}

	function handler(responseToSubscription: ApiRespondToSubscriptionInvitation) {
		addUser(() => promiseFn(responseToSubscription));
		setRequestData(responseToSubscription);
	}

	return [{ data: undefined, error, loading, fetched }, handler, resetAddUser];
}

import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0 1.8rem;
	max-width: 40rem;
	overflow: auto;
	border-right: 0.1rem solid #ededed;

	@media ${MediaQueries.maxWidth.xl} {
		padding: 0 1rem;
		max-width: 35rem;
	}
	@media ${MediaQueries.maxWidth.lg} {
		height: auto;
		max-width: 100%;
		border-right: unset;
		border-bottom: 0.1rem solid #ededed;
	}
`;

export const NoResults = styled.div`
	font-size: 1.8rem;
	margin: 1rem 2rem;
	color: ${Colors.gray.medium};
`;

import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { SubscriptionName } from 'store/account/subscription';
import { LedidiPlanCurrency, SupportedLedidiPlans } from 'store/account/payments';
import { ChangePlanLicenceModel } from 'hooks/store/account/subscription/useChangePlanLicenceQuantities';
interface Props {
	currency: LedidiPlanCurrency;
	payMonthly: boolean;
	changePlanLicenceModels: ChangePlanLicenceModel[];
	selectedSubscriptionName: SupportedLedidiPlans;
	refundableCredits: string;
	onChangeCount: (licenceId: string, count: number) => void;
	onSubmit: () => void;
}

export function PlanDetailsBundle({
	currency,
	payMonthly,
	changePlanLicenceModels,
	selectedSubscriptionName,
	refundableCredits,
	onChangeCount,
	onSubmit
}: Props) {
	const { translate } = useTranslation();

	const isOneLicenceSubscription = [
		SubscriptionName.LedidiCollaborator,
		SubscriptionName.LedidiFreeTrial
	].includes(selectedSubscriptionName);

	const isNorwegianCurrency = currency === LedidiPlanCurrency.NOK;

	const formattedRefundableCredits =
		refundableCredits === '0'
			? '0'
			: `${!isNorwegianCurrency ? `\u20AC` : ''}
		${refundableCredits.slice(0, -2) + '.' + refundableCredits.slice(-2)} ${
					isNorwegianCurrency ? 'NOK' : ''
			  }`;

	let totalToPay = 0;
	changePlanLicenceModels.forEach(licenceData => {
		totalToPay = totalToPay + licenceData.count * licenceData.unitPrice;
	});

	const parsedSumToPay = `${!isNorwegianCurrency ? `\u20AC` : ''}${totalToPay
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00 ${isNorwegianCurrency ? 'NOK' : ''}`;

	return (
		<>
			<Spacer size={s => s.m} />
			<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
				{changePlanLicenceModels.map(licenceData => (
					<Flex
						fullWidth
						align={a => a.start}
						justify={j => j.between}
						key={licenceData.id}
					>
						<Flex wrap align={a => a.end}>
							<div>
								<Typography.H5 fontweight={f => f.bold}>
									{licenceData.label}
								</Typography.H5>
								<Typography.Caption>{licenceData.description}</Typography.Caption>
							</div>
						</Flex>

						<Flex wrap align={a => a.center}>
							<Input
								label={translate(({ accountUM }) => accountUM.changePlan.licences)}
								value={licenceData.count}
								minValue={licenceData.minimumCount}
								onChange={e => {
									const value = parseInt(e.target.value, 10);
									if (value >= licenceData.minimumCount)
										onChangeCount(licenceData.id, value);
									// if (value) {
									// 	setDebouncedLicenceQuantity(value);
									// } else {
									// 	setDebouncedLicenceQuantity(0);
									// }
								}}
								onSubmit={onSubmit}
								type={InputType.Number}
								disabled={isOneLicenceSubscription}
							/>
							<Spacer size={s => s.s} />
						</Flex>
					</Flex>
				))}

				<Spacer size={s => s.m} />
				<div style={{ width: '100%' }}>
					<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
						<Typography.H6 fontweight={f => f.bold}>
							{translate(({ accountUM }) => accountUM.changePlan.creditBalance)}
						</Typography.H6>
						<Typography.H6 fontweight={f => f.bold}>
							{formattedRefundableCredits}
						</Typography.H6>
					</Flex>
					<Typography.Caption>
						{translate(({ accountUM }) => accountUM.changePlan.creditBalanceCaption)}
					</Typography.Caption>
				</div>
				<Spacer size={s => s.xl} />
				<div style={{ width: '100%' }}>
					<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
						<Typography.Paragraph>
							{translate(({ accountUM }) => accountUM.changePlan.totalDueToday)}
						</Typography.Paragraph>
					</Flex>
					<Spacer size={s => s.xs} />
					<Typography.Caption>
						{payMonthly
							? translate(({ accountUM }) => accountUM.changePlan.billedMonthly)
							: translate(({ accountUM }) => accountUM.changePlan.billedYearly)}
					</Typography.Caption>
					<Spacer size={s => s.xs} />
				</div>
				<Flex fullWidth justify={j => j.end}>
					<Typography.H3>{parsedSumToPay}</Typography.H3>
				</Flex>
			</Flex>
		</>
	);
}

import { useState } from 'react';
import { SelectStep, ConfirmStep } from './Steps';
import { Modal } from 'components/UI/Modal';
import {
	useTranslation,
	useCollaborators,
	useProjectId,
	useTransferEntriesOwnership
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';

enum TransferSteps {
	Select = 'Select',
	Confirm = 'Confirm'
}

export enum TransferOption {
	Group = 'group',
	GroupAndOwnership = 'groupAndOwnership',
	Ownership = 'ownership'
}

interface Props {
	entryIds: string[];
	onClose: (success?: boolean) => void;
}

export function TransferEntriesOwnershipModal({ entryIds, onClose }: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { collaborators, collaboratorsMap, organizations, organizationsMap },
			fetched: areCollaboratorsFetched
		}
	] = useCollaborators();

	const [step, setStep] = useState(TransferSteps.Select);
	const [organizationId, setOrganizationId] = useState<string | null>(null);
	const [collaboratorId, setCollaboratorId] = useState<string | null>(null);
	const [confirmInputValue, setConfirmInputValue] = useState('');
	const [transferOption, setTransferOption] = useState(TransferOption.Group);

	const [projectId] = useProjectId();

	const { setNotification } = useAlerts();
	const [{ loading: transfering, error: errorTransfering }, transferOwnership] =
		useTransferEntriesOwnership();

	const selectedEntriesCount = entryIds.length;

	const CONFIRM_TEXT = 'TRANSFER_OWNERSHIP';

	useCompletedAction(
		transfering,
		errorTransfering,
		// SUCCESS CALLBACK
		() => {
			setNotification({
				message: translate(
					({ dataset }) => dataset.entries.transferOwnership.successMessage
				)
			});
			onClose(true);
		}
	);

	function handleSubmit() {
		if (!(isValid() && !transfering)) return;

		transferOwnership({
			projectId: Number(projectId),
			datasetentryids: entryIds,
			...(collaboratorId && { newOwner: collaboratorId }),
			...(organizationId && { newOrganizationId: Number(organizationId) })
		});
	}

	function isValid() {
		if (step === TransferSteps.Confirm) return confirmInputValue === CONFIRM_TEXT;

		const isCollaboratorSelected = areCollaboratorsFetched && !!getCollaborator();
		const isOrganizationSelected = !!organizationId;

		if (transferOption === TransferOption.Ownership) return isCollaboratorSelected;

		if (transferOption === TransferOption.Group) return isOrganizationSelected;

		return isOrganizationSelected && isCollaboratorSelected;
	}

	function handlePrimaryClick() {
		// changing groups should not prompt confirm step at all.
		if (step === TransferSteps.Select && transferOption === TransferOption.Group)
			return handleSubmit();
		if (step === TransferSteps.Select) setStep(TransferSteps.Confirm);
		if (step === TransferSteps.Confirm) handleSubmit();
	}

	function handleNeutralClick() {
		if (step === TransferSteps.Confirm) setStep(TransferSteps.Select);
		if (step === TransferSteps.Select) onClose();
	}

	function getCollaborator() {
		if (!collaboratorId) return;
		if (collaboratorId in collaboratorsMap) return collaboratorsMap[collaboratorId];
	}

	function onSelectOrganization(organizationId: string | null) {
		setOrganizationId(organizationId);

		if (organizationId) {
			const organization = organizationsMap[organizationId];

			if (!collaboratorId || !organization.collaborators.includes(collaboratorId)) {
				setCollaboratorId(null);
			}
		}
	}

	const collaborator = getCollaborator();

	return (
		<Modal
			size={s => s.m}
			title={translate(({ dataset }) => dataset.entries.transferOwnership.modal.title)}
			primary={{
				label: translate(({ buttons }) => {
					if (step === TransferSteps.Select) return buttons.continue;
					if (step === TransferSteps.Confirm) return buttons.transfer;
					return buttons.continue;
				}),
				loading: transfering,
				disabled: !isValid(),
				onClick: handlePrimaryClick
			}}
			neutral={{
				label: translate(({ buttons }) =>
					step === TransferSteps.Confirm ? buttons.back : buttons.cancel
				),
				onClick: handleNeutralClick
			}}
			onClose={onClose}
			visible
			close
		>
			{/* SELECT */}
			{step === TransferSteps.Select && (
				<SelectStep
					transferOption={transferOption}
					setTransferOption={setTransferOption}
					entryIds={entryIds}
					selectedEntriesCount={selectedEntriesCount}
					organizationsData={{
						organizationId,
						organizations,
						onSelect: organizationId => onSelectOrganization(organizationId),
						onClear: () => setOrganizationId('')
					}}
					collaboratorsData={{
						collaboratorId,
						collaborators,
						onSelect: collaboratorId => setCollaboratorId(collaboratorId)
					}}
				/>
			)}

			{/* CONFIRM */}
			{step === TransferSteps.Confirm && collaborator && (
				<ConfirmStep
					collaborator={collaborator}
					confirmInput={{
						value: confirmInputValue,
						setValue: setConfirmInputValue
					}}
				/>
			)}
		</Modal>
	);
}

import { useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { AggregationRule, AggregationRuleType } from 'api/data/variables';
import { Svgs, Colors } from 'environment';
import { VariablesMap } from 'store/data/variables';
import { SelectItem, GenericMap, SelectItemOrGroup } from 'types/index';
import { useAggregationRuleCardDragAndDrop } from './AggregationRuleCard.controller';
import { Container, Header, HeaderTitle, Body, CardPlaceholder } from './AggregationRuleCard.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { getAggregationRuleTypeLabel } from 'helpers/variables';
import { VariableType } from 'types/data/variables/constants';
import {
	useTranslation,
	useCreateVariableSetAggregationRule,
	useUpdateVariableSetAggregationRule,
	useDeleteVariableSetAggregationRule
} from 'hooks/store';
import { useEffectOnce, useCompletedAction, useMutableState } from 'hooks/utils';

interface Props {
	index: number;
	zIndex: number;
	setName: string;
	aggregationRule: AggregationRule;
	variablesMap: VariablesMap;
	variablesSelectItems: {
		items: SelectItemOrGroup[];
		itemsMap: GenericMap<SelectItem>;
	};
	newRule?: {
		onCancel: () => void;
		onCreated: () => void;
	};
	readOnly: boolean;
}

export function AggregationRuleCard({
	index,
	zIndex,
	setName,
	aggregationRule,
	variablesMap,
	variablesSelectItems,
	newRule,
	readOnly
}: Props) {
	const { mainRef, isDragging, dragPreview, hoverIndicators } = useAggregationRuleCardDragAndDrop(
		{
			index,
			setName,
			ruleName: aggregationRule.name,
			readOnly
		}
	);
	const { translate } = useTranslation();

	const [
		{ loading: creatingAggregationRule, error: errorCreatingAggregationRule },
		createAggregationRule
	] = useCreateVariableSetAggregationRule(aggregationRule.name);
	const [{ loading: updatingAggregationRule }, updateAggregationRule] =
		useUpdateVariableSetAggregationRule(aggregationRule.name);
	const [{ loading: deletingAggregationRule }, deleteAggregationRule] =
		useDeleteVariableSetAggregationRule(aggregationRule.name);

	useEffectOnce(() => {
		if (newRule) mainRef.current?.scrollIntoView();
	});

	useCompletedAction(creatingAggregationRule, errorCreatingAggregationRule, () =>
		newRule?.onCreated()
	);

	const [open, setOpen] = useState(!!newRule || false);

	const [draftAggregationRule, setDraftAggregationRule] = useMutableState(aggregationRule);

	// SYNC `draftAggregationRule` STATE
	useEffect(() => {
		const aggregationRuleChanged = !isEqual(aggregationRule, draftAggregationRule);

		if (aggregationRuleChanged) setDraftAggregationRule(aggregationRule);
	}, [aggregationRule]);

	const hasChanges = useMemo(
		() => !isEqual(aggregationRule, draftAggregationRule),
		[aggregationRule, draftAggregationRule]
	);

	function resetDraft() {
		setDraftAggregationRule(aggregationRule);
	}

	function getAggregatingRulesSelectItems() {
		const items: SelectItem[] = [];

		const aggregatorVariable =
			variablesMap[draftAggregationRule.aggregator.variableName] ?? null;

		let filterOutItems = false;

		if (aggregatorVariable) {
			const { type } = aggregatorVariable;

			const isNumericVariable = [VariableType.Integer, VariableType.Float].includes(type);

			if (!isNumericVariable) filterOutItems = true;
		}

		const allItems = Object.values(AggregationRuleType).map<SelectItem>(value => ({
			label: translate(dict => dict.variablesPage.aggregationRuleType[value]).toUpperCase(),
			value
		}));

		const itemsByVariableType = allItems.filter(item =>
			[AggregationRuleType.Earliest, AggregationRuleType.Latest].includes(
				item.value as AggregationRuleType
			)
		);

		const finalItems = filterOutItems ? itemsByVariableType : allItems;

		items.push(...finalItems);

		return items;
	}

	function getAllowedAggregatingRules(variableName: string) {
		if (variableName in variablesMap) {
			const variable = variablesMap[variableName];

			const { type } = variable;

			const isNumericVariable = [VariableType.Integer, VariableType.Float].includes(type);

			const allRules = Object.values(AggregationRuleType);
			const filteredRules = [AggregationRuleType.Earliest, AggregationRuleType.Latest];

			return isNumericVariable ? allRules : filteredRules;
		}

		return [];
	}

	function getSelectedVariableLabel(variableName: string) {
		if (variableName in variablesMap) {
			const variable = variablesMap[variableName];

			return variable.label;
		}

		return '-';
	}

	function getCardHeaderTitle() {
		return `${getSelectedVariableLabel(aggregationRule.aggregator.variableName)} (${translate(
			dict =>
				dict.variablesPage.aggregationRuleType[
					getAggregationRuleTypeLabel(aggregationRule) as AggregationRuleType
				]
		).toUpperCase()})`;
	}

	function handleSubmit() {
		if (!isRuleValid()) return;

		if (newRule) {
			return createAggregationRule({
				setName,
				aggregationRule: draftAggregationRule
			});
		}

		updateAggregationRule({
			setName,
			aggregationRule: draftAggregationRule
		});
	}

	function handleCancel() {
		if (newRule) return newRule.onCancel();

		resetDraft();
	}

	function isRuleValid() {
		const hasAggregator = draftAggregationRule.aggregator.variableName !== '';

		return hasAggregator;
	}

	if (isDragging) return <CardPlaceholder ref={dragPreview} />;

	const loading = creatingAggregationRule || updatingAggregationRule || deletingAggregationRule;

	return (
		<Container ref={mainRef} zIndex={zIndex} disabled={loading}>
			<Header onClick={() => setOpen(state => !state)}>
				<Icon svg={Svgs.ChevronDown} rotate={open ? 180 : 0} propagate />
				<HeaderTitle title={getCardHeaderTitle()}>
					{/* 
						Title split into 2 `Typography.Paragraph` components
						so we can apply ellipsis only to variable label
						and still show the aggregation function name
					*/}
					<Typography.Paragraph marginOffset={{ right: 0.4 }} ellipsis>
						{getSelectedVariableLabel(aggregationRule.aggregator.variableName)}
					</Typography.Paragraph>
					<Typography.Paragraph>{` (${translate(
						dict =>
							dict.variablesPage.aggregationRuleType[
								getAggregationRuleTypeLabel(aggregationRule) as AggregationRuleType
							]
					).toUpperCase()})`}</Typography.Paragraph>
				</HeaderTitle>

				{!readOnly && (
					<>
						{loading ? (
							<Loader />
						) : (
							<>
								{hasChanges || !!newRule ? (
									<Flex>
										{isRuleValid() && (
											<Icon
												svg={Svgs.Checkmark}
												size={s => s.m}
												colors={{
													color: Colors.text.disabled,
													hover: Colors.vibrantGreen
												}}
												marginOffset={{ right: 0.8 }}
												onClick={handleSubmit}
												id="series_save_icon"
											/>
										)}
										<Icon
											svg={Svgs.Close}
											size={s => s.m}
											colors={{
												color: Colors.text.disabled,
												hover: Colors.text.hint
											}}
											onClick={handleCancel}
											id="series_cancel_icon"
										/>
									</Flex>
								) : (
									<Icon
										className="delete-rule-icon"
										svg={Svgs.Delete}
										size={s => s.m}
										colors={{
											color: Colors.text.disabled,
											hover: Colors.primary.normal
										}}
										onClick={() =>
											deleteAggregationRule({
												setName,
												ruleName: aggregationRule.name
											})
										}
									/>
								)}
							</>
						)}
					</>
				)}
			</Header>

			{open && (
				<Body>
					<CreatableSelect
						label={translate(dict => dict.variablesPage.aggregationVariable)}
						placeholder={`-`}
						value={
							variablesSelectItems.itemsMap[
								draftAggregationRule.aggregator.variableName ?? ''
							] ?? null
						}
						items={variablesSelectItems.items}
						readOnly={readOnly}
						onValueSelected={value =>
							setDraftAggregationRule(state => {
								const aggregatorVariable = value ?? '';

								state.aggregator.variableName = aggregatorVariable;

								if (state.rule.type) {
									const allowedRules =
										getAllowedAggregatingRules(aggregatorVariable);

									const shouldResetRule = !allowedRules.includes(state.rule.type);

									if (shouldResetRule) {
										state.rule.type = AggregationRuleType.Latest;
									}
								}
							})
						}
						canClear={false}
					/>
					<Spacer size={s => s.xs} />
					<CreatableSelect
						label={translate(dict => dict.variablesPage.aggregationFunction)}
						placeholder={`-`}
						value={getAggregatingRulesSelectItems().find(
							selectItem => selectItem.value === draftAggregationRule.rule.type
						)}
						items={getAggregatingRulesSelectItems()}
						readOnly={readOnly}
						onValueSelected={value =>
							setDraftAggregationRule(state => {
								const aggregatingRuleType = (value as AggregationRuleType) ?? null;

								state.rule.type = aggregatingRuleType;
							})
						}
						canClear={false}
					/>
				</Body>
			)}

			{hoverIndicators}
		</Container>
	);
}

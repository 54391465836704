import { DistributionAmountOfTime } from 'api/data/patients';
import { DATE_TIME_TIMEZONE_FORMAT } from 'consts';
import { addDays, addHours, addWeeks, addMonths } from 'date-fns';
import format from 'date-fns/format';
import { isValidDate } from './isValidDate';

//  The date format coming from the API isn't recognized as a date by Safari YYYY-MM-DD hh:mm:ss+0000
//  So we have to format it to new Date(YYYY, MM, DD, hh, mm, ss) which is recongnized by all browsers
export function formatAPIDate(date: string, dateFormat?: string) {
	if (date) {
		if (date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}\+\d{2}:\d{2}$/)) return date;

		if (date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.000Z$/))
			date = date.replace(/.000Z/g, '+0000').replace(/T/g, ' ');

		const splitDate = date.split(' ');

		const newDate = splitDate[0]
			.split('-')
			.concat(splitDate[1].replace(/\+0000/g, '').split(':'))
			.map(item => parseInt(item, 10));

		// Months start from 0
		newDate[1] -= 1;

		const formattedDate = new Date(
			...(newDate as [number, number, number, number, number, number])
		);

		const isCorrectTimeZone = splitDate[1].split('+')[1] !== '0000';

		const timeZoneAdjustedDate = isCorrectTimeZone
			? formattedDate
			: new Date(formattedDate.getTime() - new Date().getTimezoneOffset() * 60000);

		if (dateFormat) return format(timeZoneAdjustedDate, dateFormat);

		return timeZoneAdjustedDate;
	} else return date;
}

/**
 * Creates a new Date using the given string date.
 * Applies correction to the string in order for the conversion
 * to be compatible with all the browsers
 * @param date
 */
export function toValidDate(date: string) {
	const validStringDate = isValidDate(date) ? date.replace(/\s/g, 'T') : date;
	return new Date(validStringDate);
}

export function roundMinutes(date: Date, roundToNextHour?: boolean) {
	const toNextHour = roundToNextHour && date.getMinutes() < 30;

	date.setHours(date.getHours() + (toNextHour ? 1 : Math.round(date.getMinutes() / 60)));
	date.setMinutes(0, 0, 0);

	return date;
}

// When updating distribution quantity/amountOfTime,
// attr timeToSend doesn't recalculate on BE
// see issue https://ledidi.atlassian.net/browse/MOD-646
export function computeNewTimeToSend(
	globalStartDate: string,
	newUnitOfTime: DistributionAmountOfTime,
	quantity: number
) {
	const startDate = Date.parse(globalStartDate);
	const localTimeToSend =
		newUnitOfTime === 'hours'
			? addHours(startDate, quantity)
			: newUnitOfTime === 'days'
			? addDays(startDate, quantity)
			: newUnitOfTime === 'weeks'
			? addWeeks(startDate, quantity)
			: addMonths(startDate, quantity);
	const newTimeToSendValue = format(localTimeToSend, DATE_TIME_TIMEZONE_FORMAT);
	return newTimeToSendValue;
}

import { MediaQueries } from 'environment';
import styled, { css } from 'styled-components';

enum HSpaceTypes {
	xs = 0.6,
	s = 1.6,
	m = 2.4
}

enum MobileHeightTypes {
	s = 1.6
}

interface Props {
	className?: string;
	removeOnMobile?: boolean;
	mobileHeight?: (height: typeof MobileHeightTypes) => MobileHeightTypes;
	size?: (size: typeof HSpaceTypes) => HSpaceTypes;
}

export function HSpacer({ className, removeOnMobile = true, mobileHeight, size }: Props) {
	const spaceSize = size ? size(HSpaceTypes) : HSpaceTypes.m;

	const mHeight = mobileHeight ? mobileHeight(MobileHeightTypes) : undefined;

	return (
		<Component
			className={className}
			removeOnMobile={removeOnMobile}
			mobileHeight={mHeight}
			$size={spaceSize}
		/>
	);
}

interface ComponentProps {
	// using `$` before the prop name to avoid DOM errors
	$size: HSpaceTypes;
	mobileHeight?: number;
	removeOnMobile?: boolean;
}
const Component = styled.div<ComponentProps>`
	display: block;
	opacity: 0;
	height: 100%;

	${({ $size }) => css`
		width: ${$size}rem;
		min-width: ${$size}rem;
		max-width: ${$size}rem;
	`}

	@media ${MediaQueries.maxWidth.sm} {
		${({ removeOnMobile }) =>
			removeOnMobile &&
			`
        width: 0;
        min-width:0;
        max-width:0;
        `};

		${({ mobileHeight }) =>
			mobileHeight &&
			`
        margin-bottom: ${mobileHeight}rem
        `};
	}
`;

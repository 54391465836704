import { State } from './types';

const initialState: State = {
	selectedPaymentMethod: null,
	subscriptionPaymentMethods: null,
	billingInfo: null,
	subscriptionPayments: null,
	cbInstantiated: false
};

export default initialState;

import { State } from './types';

const initialState: State = {
	projectId: null,
	byProjectId: {},
	metadata: {
		variableName: null,
		destinationSetName: null,
		destinationGroupName: null,
		searchTerm: '',
		dragging: ''
	}
};

export default initialState;

import { useState } from 'react';
import { isEqual } from 'lodash';
import { BooleanMap } from 'types/index';
import { DraggableVariable, TemplateGroupInterface } from 'store/data/templates';
import { GroupsMap, VariablesMap } from 'store/data/variables';
import { buildVariablesDataFromTemplate } from 'helpers/templates';
import { useDeepCompareMemo, useDeepCompareEffect, useDeepCompareCallback } from 'hooks/utils';

interface CheckedPayload {
	group?: string;
	variable?: string;
	groupVariable?: string;
	groupName?: string;
}

interface CheckedMapState {
	groups: BooleanMap;
	variables: BooleanMap;
	groupVariables: Record<string, BooleanMap>;
}

export interface TemplateVariablesCheckedData {
	checkedMap: {
		variables: BooleanMap;
		groups: BooleanMap;
		groupVariables: Record<string, BooleanMap>;
	};
	toggleChecked: (item: CheckedPayload) => void;
	toggleAllChecked: () => void;
}

export function useTemplateVariablesTableCheckedData({
	originalVariables,
	originalGroups,
	filteredVariablesMap,
	filteredGroupsMap,
	allowTimeDuration
}: {
	originalVariables: DraggableVariable[];
	originalGroups: TemplateGroupInterface[];
	filteredVariablesMap: VariablesMap;
	filteredGroupsMap: GroupsMap;
	allowTimeDuration: boolean;
}): TemplateVariablesCheckedData {
	const variableData = useDeepCompareMemo(
		() =>
			buildVariablesDataFromTemplate({
				templateGroups: originalGroups,
				templateVariables: originalVariables,
				allowTimeDuration
			}),
		[originalGroups, originalVariables]
	);

	const { groupsMap } = variableData;

	const initialCheckedMap = useDeepCompareMemo(() => {
		const initialCheckedMap: CheckedMapState = {
			variables: originalVariables.reduce<BooleanMap>((acc, variable) => {
				acc[variable.name] = true;
				return acc;
			}, {}),
			groups: originalGroups.reduce<BooleanMap>((acc, group) => {
				acc[group.groupName] = true;
				return acc;
			}, {}),
			// INITIAL STATE HAS ALL GROUPS AND VARIABLES SELECTED
			// NOTE: GROUP VARIABLES ARE UNSELECTED IF THE WHOLE GROUP IS SELECTED;
			groupVariables: originalGroups.reduce<CheckedMapState['groupVariables']>(
				(acc, group) => {
					acc[group.groupName] = group.variables.reduce<BooleanMap>(
						(acc, groupVariable) => {
							acc[groupVariable.name] = false;
							return acc;
						},
						{}
					);

					return acc;
				},
				{}
			)
		};
		return initialCheckedMap;
	}, [originalGroups, originalVariables]);

	// SYNC CHECKED STATE WHEN TEMPLATE CHANGES
	useDeepCompareEffect(() => {
		setCheckedMap(initialCheckedMap);
	}, [initialCheckedMap]);

	// SYNC CHECKED STATE WHEN FILTERED VARIABLE CHANGES
	useDeepCompareEffect(() => {
		setCheckedMap(checkedMap => {
			const newCheckedMap = { ...checkedMap };
			// IF GROUP WAS PREVIOUSLY SELECTED BUT NOW SOME GROUP VARIABLES ARE FILTERED -> UNCHECK GROUP AND CHECK GROUP VARIABLES THAT ARE LEFT
			const checkedGroupKeys = Object.keys(checkedMap.groups);
			checkedGroupKeys.forEach(groupKey => {
				const checkedGroupVariableKeys = Object.keys(checkedMap.groupVariables[groupKey]);
				// IF THERE'S ANY CHECKED GROUP VARIABLE IN THIS GROUP THAT IS FILTERED!!
				const areFilteredGroupVariablesChecked = checkedGroupVariableKeys.some(
					groupVariable => filteredVariablesMap[groupVariable] === undefined
				);
				if (areFilteredGroupVariablesChecked) {
					// (1) UNSELECT
					const groupPreviouslySelected = !!newCheckedMap.groups[groupKey];
					newCheckedMap.groups[groupKey] = false;
					// (2) KEEP FILTERED GROUP VARIABLES' PREVIOUS CHECKED STATE
					checkedGroupVariableKeys.forEach(groupVariable => {
						if (filteredVariablesMap[groupVariable] === undefined) {
							newCheckedMap.groupVariables[groupKey][groupVariable] = false;
							// (3) IF GROUP WAS PREVIOUSLY SELECTED WE NEED TO SELECT ALL OTHER VARIABLES
						} else if (groupPreviouslySelected) {
							newCheckedMap.groupVariables[groupKey][groupVariable] = true;
						}
					});
				}
			});
			return newCheckedMap;
		});
	}, [filteredVariablesMap, filteredGroupsMap]);

	const [checkedMap, setCheckedMap] =
		useState<TemplateVariablesCheckedData['checkedMap']>(initialCheckedMap);

	const toggleChecked = useDeepCompareCallback(
		(item: CheckedPayload) => {
			// MAIN LEVEL VARIABLES
			if (item.variable) {
				const variableName = item.variable;
				setCheckedMap(c => ({
					...c,
					variables: {
						...c.variables,
						[variableName]: !c.variables[variableName]
					}
				}));
				// GROUPS
			} else if (item.group) {
				const groupName = item.group;
				const group = groupsMap[item['group']];
				const selected = checkedMap.groups[item['group']];

				const filteredGroup = filteredGroupsMap[item['group']];

				// IMPORTANT EDGE CASE:
				// TOGGLING A GROUP WHICH HAS FILTERED VARIABLE(S);
				// CHECK TO MAKE SURE WE DO NOT ACTUALLY TOGGLE THE WHOLE GROUP
				// -> TOGGLE JUST GROUPVARIABLE(S) THAT ARE FILTERED;

				const groupHasFilteredVariables =
					group.variablesBelongingToGroup.length >
					filteredGroup.variablesBelongingToGroup.length;

				setCheckedMap(c => ({
					...c,
					groups: {
						...c.groups,
						[groupName]: groupHasFilteredVariables ? false : !selected
					},
					groupVariables: {
						...c.groupVariables,
						[groupName]: filteredGroup.variablesBelongingToGroup.reduce<BooleanMap>(
							(acc, variableName) => {
								acc[variableName] = !groupHasFilteredVariables
									? !selected
									: !checkedMap.groupVariables[groupName][variableName];
								return acc;
							},
							{}
						)
					}
				}));

				// VARIABLES IN GROUPS
			} else if (Object.keys(item).includes('groupVariable')) {
				if (!item.groupName) return;
				const groupName = item.groupName;
				const variableName = item.groupVariable as string;
				const selected =
					checkedMap.groupVariables[groupName][variableName] ||
					checkedMap.groups[groupName];

				// UNSELECTING GROUP VARIABLE
				if (selected) {
					// IF GROUP WAS PREVIOUSLY SELECTED
					if (checkedMap.groups[groupName]) {
						// UNSELECT GROUP AND THIS VARIABLE BUT SELECT ALL OTHER VARIABLES;
						setCheckedMap(c => ({
							...c,
							groups: { ...c.groups, [groupName]: false },
							groupVariables: {
								...c.groupVariables,
								[groupName]: {
									...groupsMap[
										groupName
									].variablesBelongingToGroup.reduce<BooleanMap>(
										(acc, variableName) => {
											acc[variableName] = true;
											return acc;
										},
										{}
									),
									[variableName]: false
								}
							}
						}));
					} else {
						// UNSELECT JUST VARIABLE;
						setCheckedMap(c => ({
							...c,
							groupVariables: {
								...c.groupVariables,
								[groupName]: {
									...c.groupVariables[groupName],
									[variableName]: false
								}
							}
						}));
					}
					// SELECTING VARIABLE
				} else if (!selected) {
					// IF GROUP IS NOT SELECTED COMPLETELY
					if (!checkedMap.groups[groupName]) {
						const otherVariables = filteredGroupsMap[
							groupName
						].variablesBelongingToGroup.filter(variable => variable !== variableName);

						// DO NOT APPLY THIS LOGIC IF GROUP HAS FILTERED VARIABLES BECAUSE GROUP CANNOT BE SELECTED
						const lastToSelect =
							otherVariables.length &&
							otherVariables.every(
								variableName => !!checkedMap.groupVariables[groupName][variableName]
							);
						// IF THIS IS THE LAST VARIABLE THAT IS LEFT TO SELECT
						if (lastToSelect) {
							// SELECT GROUP AND UNCHECK ALL GROUPVARIABLES
							setCheckedMap(c => ({
								...c,
								groups: {
									...c.groups,
									[groupName]: true
								},
								groupVariables: {
									...c.groupVariables,
									[groupName]: groupsMap[
										groupName
									].variablesBelongingToGroup.reduce<BooleanMap>(
										(acc, groupVariable) => {
											acc[groupVariable] = false;
											return acc;
										},
										{}
									)
								}
							}));
						} else {
							// SELECT JUST VARIABLE
							setCheckedMap(c => ({
								...c,
								groupVariables: {
									...c.groupVariables,
									[groupName]: {
										...c.groupVariables[groupName],
										[variableName]: true
									}
								}
							}));
						}
					}
				}
			}
		},
		[groupsMap, checkedMap]
	);

	const toggleAllChecked = useDeepCompareCallback(() => {
		if (!isEqual(checkedMap, initialCheckedMap)) {
			// TOGGLING EVERYTHING TRUE;
			setCheckedMap(initialCheckedMap);
		} else {
			// TOGGLING EVERYTHING FALSE;
			setCheckedMap(({ groups, groupVariables, variables }) => {
				return {
					variables: Object.keys(variables).reduce<BooleanMap>((acc, key) => {
						acc[key] = false;
						return acc;
					}, {}),
					groups: Object.keys(groups).reduce<BooleanMap>((acc, key) => {
						acc[key] = false;
						return acc;
					}, {}),
					groupVariables: Object.keys(groupVariables).reduce<Record<string, BooleanMap>>(
						(acc, key) => {
							acc[key] = Object.keys(groupVariables[key]).reduce<BooleanMap>(
								(acc, key) => {
									acc[key] = false;
									return acc;
								},
								{}
							);
							return acc;
						},
						{}
					)
				};
			});
		}
	}, [checkedMap]);

	return { checkedMap, toggleChecked, toggleAllChecked };
}

import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	align-items: flex-start;
	width: 100%;
	position: relative;
`;

import { useMemo } from 'react';

import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import {
	useTranslation,
	usePermissions,
	useProjectId,
	useExpandGroupsContext,
	useVisibleVariableReferences,
	useOrganizationAccessRights,
	useProject,
	useUsername
} from 'hooks/store';

import { ExportWizard } from 'components/Dataset/Modals';

import { ExportType } from 'consts';
import { Container } from './SeriesOptions.style';
import { VariableSetData } from 'store/data/variables';
import { isGroupData } from 'helpers/variables';
import { useModalState } from 'hooks/ui';
import { DownloadFormat } from 'api/data/entries';

export interface ExportOptions {
	type: ExportType;
	format: DownloadFormat;
	showFilterSection?: boolean;
}

interface CurrentSeriesProps {
	label: string;
	value: string;
	checked: boolean;
}
interface Props {
	currentSeriesItems: CurrentSeriesProps[];
	seriesData: VariableSetData;
}

export function SeriesOptions({ currentSeriesItems, seriesData }: Props) {
	const { translate } = useTranslation();

	const [projectId] = useProjectId();

	const [{ data: project }] = useProject();
	const userId = useUsername();

	const { expandedSeriesGroups, collapseAllSeriesGroups, expandAllSeriesGroups } =
		useExpandGroupsContext();

	const selectedColumnsForExport = useVisibleVariableReferences(currentSeriesItems.length === 1);

	const allowToggleGroupsFeature = Object.keys(expandedSeriesGroups).length > 0;
	const { hasExportDataAccess } = usePermissions();
	const [
		{
			data: { accessExportDelegation }
		}
	] = useOrganizationAccessRights();
	const hasOrganizationExportAccess =
		accessExportDelegation || project?.projectOwnerDetails?.userId === userId;

	const canCollapseGroups = useMemo(() => {
		let canCollapse = false;

		Object.keys(expandedSeriesGroups).forEach(expanded => {
			seriesData.setData.forEach(groupData => {
				if (isGroupData(groupData)) {
					if (expanded === groupData.groupName && expandedSeriesGroups[expanded]) {
						canCollapse = true;
					}
				}
			});
		});

		return canCollapse;
	}, [expandedSeriesGroups]);

	const canExpandGroups = useMemo(() => {
		let canExpand = false;
		Object.keys(expandedSeriesGroups).forEach(expanded => {
			seriesData.setData.forEach(groupData => {
				if (isGroupData(groupData)) {
					if (expanded === groupData.groupName && !expandedSeriesGroups[expanded]) {
						canExpand = true;
					}
				}
			});
		});
		return canExpand;
	}, [expandedSeriesGroups]);

	// EXPORT WIZARD MODAL
	const exportWizardModal = useModalState<ExportOptions>();

	return (
		<>
			<Container>
				<Dropdown
					toggleComponent={({ ref, open, toggle }) => (
						<Icon
							variant={v => v.button}
							ref={ref}
							svg={Svgs.More}
							active={open}
							onClick={toggle}
						/>
					)}
					width={18}
					offset={{ top: 20, right: 0 }}
				>
					{allowToggleGroupsFeature && (
						<>
							<Dropdown.Item
								title={translate(dict => dict.entriesOptions.collapseAllGroups)}
								onClick={() => collapseAllSeriesGroups(seriesData.setData)}
								disabled={!canCollapseGroups}
							/>
							<Dropdown.Item
								title={translate(dict => dict.entriesOptions.expandAllGroups)}
								disabled={!canExpandGroups}
								onClick={() => expandAllSeriesGroups(seriesData.setData)}
							/>
						</>
					)}
					{hasExportDataAccess && hasOrganizationExportAccess && (
						<Dropdown.Item
							title={translate(
								({ exportWizard }) => exportWizard.exportType.seriesAsCSV
							)}
							disabled={!projectId}
							onClick={() =>
								exportWizardModal.open({
									type: ExportType.Series,
									format: DownloadFormat.CSV,
									...(selectedColumnsForExport?.length
										? { showFilterSection: false }
										: {})
								})
							}
						/>
					)}
					{hasExportDataAccess && hasOrganizationExportAccess && (
						<Dropdown.Item
							title={translate(
								({ exportWizard }) => exportWizard.exportType.seriesAsExcel
							)}
							disabled={!projectId}
							onClick={() =>
								exportWizardModal.open({
									type: ExportType.Series,
									format: DownloadFormat.Excel,
									...(selectedColumnsForExport?.length
										? { showFilterSection: false }
										: {})
								})
							}
						/>
					)}
				</Dropdown>
			</Container>

			{exportWizardModal.visible && (
				<ExportWizard
					currentSeriesItems={currentSeriesItems}
					onClose={exportWizardModal.close}
					exportScreenType={ExportType.Series}
					format={exportWizardModal.payload?.format}
					showFilterSection={exportWizardModal.payload?.showFilterSection}
				/>
			)}
		</>
	);
}

import { Colors } from 'environment';
import { getMarginOffset } from 'helpers/cssGenerators';
import styled from 'styled-components';
import { StyleOffsets } from 'types/index';

export const Wrapper = styled.div`
	.table-row:hover .icons-container {
		visibility: visible;
	}
`;

interface IconsContainerProps {
	visible?: boolean;
}

export const IconsContainer = styled.div<IconsContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const DropdownScrollContainer = styled.div`
	width: 100%;
	overflow-y: auto;
	max-height: calc(100vh - 42rem);
	min-height: 5rem;
`;

interface WrapperProps {
	flex?: number;
	marginOffset?: StyleOffsets;
}

export const WrapperDiv = styled.div<WrapperProps>`
	flex: ${({ flex }) => flex};

	${({ marginOffset }) => getMarginOffset(marginOffset)};
`;
export const ControlsWrapper = styled.div<WrapperProps>`
	height: 3.4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 0.1rem solid ${Colors.primary.disabled};
	padding: 0 0.8rem;
`;

import { Row } from 'react-table';

import { Typography } from 'components/UI/Typography';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { EntryStatus, EntryValue, EntryValues } from 'store/data/entries';
import {
	Cell,
	SelectedIconContainer,
	Row as ComponentRow,
	Table,
	TextContainer
} from './ConflictsModal.style';
import { Colors, Svgs } from 'environment';
import { ConflictedVariable, MergedFormValues, TableData } from './ConflictsModal';
import { useCallback, useMemo } from 'react';
import { useVariables } from 'hooks/store';
import { VariableType } from 'types/data/variables/constants';
import { DATE_FORMAT, dateTimeFormatMap } from 'consts';
import { format } from 'date-fns';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import { useSelector } from 'hooks/utils';

interface ConflictsVariableRowProps {
	row: Row<TableData>;
	variable: ConflictedVariable;
	finalValues: MergedFormValues;
	isNested?: boolean;
	setFinalValues: (values: EntryValues) => void;
	setStatus: (status: EntryStatus) => void;
	getVariableLabel: (variableName: string) => string | undefined;
}

export function ConflictsVariableRow({
	row,
	variable,
	finalValues,
	isNested,
	setFinalValues,
	setStatus,
	getVariableLabel
}: ConflictsVariableRowProps) {
	const marginLeftOffset = isNested ? 4 : 0;

	const [
		{
			data: { variablesMap }
		}
	] = useVariables();

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);
	function handleClickCurrentCell(name: string) {
		if (name === 'status_column') {
			if (variable.current === null) {
				setStatus(null);
			} else {
				setStatus({
					comment: '',
					variableName: variable.current as string,
					dueTimeStamp: null
				});
			}
		} else {
			setFinalValues({ [variable.variableName]: variable.current });
		}
	}

	function handleClickPreviousCell(name: string) {
		if (name === 'status_column') {
			if (variable.previous === null) {
				setStatus(null);
			} else {
				setStatus({
					comment: '',
					variableName: variable.previous as string,
					dueTimeStamp: null
				});
			}
		} else {
			setFinalValues({ [variable.variableName]: variable.previous });
		}
	}

	const isCurrentActive = useMemo(() => {
		if (variable.variableName !== 'status_column') {
			return finalValues.values[variable.variableName] === variable.current;
		}
		return (
			(finalValues.status === null && variable.current === null) ||
			finalValues.status?.variableName === variable.current
		);
	}, [finalValues, variable]);

	const isPreviousActive = useMemo(() => {
		if (variable.variableName !== 'status_column') {
			return finalValues.values[variable.variableName] === variable.previous;
		}
		return (
			(finalValues.status === null && variable.previous === null) ||
			finalValues.status?.variableName === variable.previous
		);
	}, [finalValues, variable]);

	const getEntryValue = useCallback(
		(value: EntryValue) => {
			// CAT MULTIPLE
			if (value && typeof value === 'object') {
				return value.join(', ');
			}

			// `date`
			if (variablesMap[variable.variableName].type === VariableType.Date) {
				return format(new Date(value as string), DATE_FORMAT);
			}

			// `datetime`
			if (variablesMap[variable.variableName].type === VariableType.DateTime) {
				return format(new Date(value as string), dateTimeFormatMap[dateTimeFormat]);
			}
			return value;
		},
		[variable, variablesMap]
	);

	return (
		<ComponentRow {...row.getRowProps()} key={row.getRowProps().key} clickable>
			<Table.Cell minWidth={20} maxWidth={30}>
				<Typography.Paragraph
					marginOffset={isNested && { left: marginLeftOffset }}
					ellipsis
				>
					{getVariableLabel(variable.variableName)}
				</Typography.Paragraph>
			</Table.Cell>
			<Cell
				onClick={() => handleClickCurrentCell(variable.variableName)}
				active={isCurrentActive}
			>
				<Flex align={a => a.center} justify={j => j.between}>
					<TextContainer> {getEntryValue(variable.current)}</TextContainer>
					<SelectedIconContainer visible={isCurrentActive}>
						<Icon
							svg={Svgs.Checkmark}
							colors={{
								color: Colors.primary.normal
							}}
							size={s => s.m}
						/>
					</SelectedIconContainer>
				</Flex>
			</Cell>
			<Cell
				onClick={() => handleClickPreviousCell(variable.variableName)}
				active={isPreviousActive}
			>
				<Flex align={a => a.center} justify={j => j.between}>
					<TextContainer>{getEntryValue(variable.previous)}</TextContainer>
					<SelectedIconContainer visible={isPreviousActive}>
						<Icon
							svg={Svgs.Checkmark}
							colors={{
								color: Colors.primary.normal
							}}
							size={s => s.m}
						/>
					</SelectedIconContainer>
				</Flex>
			</Cell>
		</ComponentRow>
	);
}

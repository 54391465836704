import { useSelector } from '../../../../utils/index';
import { selectAssignedOrganizationId } from '../../../../../store/data/projects/selectors';
import { useDispatch } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { Nullable } from 'types';
import { ActionPayload } from 'store/types';
import { SetAssignedOrganizationIdAction } from 'store/data/projects/types';
import { setAssignedOrganizationId } from 'store/data/projects';

export function useAssignedOrganizationId(): LocalOperationResult<
	Nullable<string>,
	(organizationId: string | null) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAssignedOrganizationId(state.data.projects));

	function handler(
		organizationId: ActionPayload<SetAssignedOrganizationIdAction>['organizationId']
	) {
		dispatch(setAssignedOrganizationId({ organizationId }));
	}

	return [data, handler];
}

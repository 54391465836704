import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { getSubscriptionApiKey } from 'store/account/subscription';
import { ActionTypes } from 'store/account/payments';
import { useSubscription } from './useSubscription';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useSubscriptionApiKey(): OperationResult<string | null> {
	const dispatch = useDispatch();

	const subscriptionApiKey = useSelector(state => state.account.subscription.subscriptionApiKey);

	const fetched = subscriptionApiKey !== null;

	const [
		{
			data: {
				userTypesAndRoles: { ledidiEnterprise, isPaidSubscriptionOwner }
			}
		}
	] = useSubscription();

	const [{ loading, error }] = useActivity(ActionTypes.GET_SUBSCRIPTION_PAYMENTS);

	const hasAccessToApiKey =
		ledidiEnterprise.isOrganizationAdmin ||
		ledidiEnterprise.isSuperAdmin ||
		ledidiEnterprise.isAdmin ||
		isPaidSubscriptionOwner;

	useEffect(() => {
		if (!fetched && !loading && !error && hasAccessToApiKey) handler();
	}, [fetched, loading, error]);

	function handler() {
		dispatch(getSubscriptionApiKey());
	}

	return [{ loading, error, data: subscriptionApiKey, fetched }, handler];
}

import {
	isCollaboratorOrderItem,
	isOrganizationOrderItem,
	buildCollaboratorsDataLocation,
	buildCollaboratorsDataFromStoreData
} from './collaboratorHelpers';
import {
	CollaboratorsOrder,
	Collaborator,
	Organization,
	StoreCollaboratorsData
} from 'store/data/collaborators';

export function collaboratorsDataContainer(storeCollaboratorsData: StoreCollaboratorsData) {
	const { collaborators, organizations, order } = storeCollaboratorsData;

	const collaboratorsDataLocation = buildCollaboratorsDataLocation(
		buildCollaboratorsDataFromStoreData(storeCollaboratorsData)
	);

	/*
	 * COLLABORATORS
	 */

	function addCollaborator(collaborator: Collaborator) {
		collaborators.byId[collaborator.userId] = collaborator;

		addOrderItem(collaborator.userId, order, 'collaborator');
	}

	function addCollaborators(collaborators: Collaborator[]) {
		collaborators.forEach(addCollaborator);
	}

	function removeCollaborator(collaboratorId: string) {
		if (!(collaboratorId in collaborators.byId)) return;

		unlinkCollaborator(collaboratorId);

		delete collaborators.byId[collaboratorId];
	}

	function removeCollaborators(collaboratorIds: string[]) {
		collaboratorIds.forEach(removeCollaborator);
	}

	/*
	 * ORGANIZATIONS
	 */

	function createOrganization(organization: Organization) {
		organizations.byId[organization.id] = organization;

		organization.collaborators.forEach(unlinkCollaborator);

		const lastOrganizationIndex = getLastOrganizationIndex();

		const destinationIndex =
			lastOrganizationIndex !== undefined ? lastOrganizationIndex + 1 : 0;

		addOrderItem(organization.id, order, 'organization', destinationIndex);

		organization.collaborators.forEach(collaborator => {
			const storeCollaborator = collaborators.byId[collaborator];
			storeCollaborator.organizationsCount = storeCollaborator.organizationsCount
				? ++storeCollaborator.organizationsCount
				: 1;
		});
	}

	function deleteOrganization(organizationId: string) {
		if (!(organizationId in organizations.byId)) return;

		const organization = organizations.byId[organizationId];

		delete organizations.byId[organizationId];

		organization.collaborators.forEach(collaborator => {
			collaborators.byId[collaborator].organizationsCount = --collaborators.byId[collaborator]
				.organizationsCount;
		});

		removeOrderItem(organizationId, order, 'organization');
	}

	function addCollaboratorToOrganization(collaboratorId: string, organizationId: string) {
		if (!(collaboratorId in collaborators.byId)) return;
		if (!(organizationId in organizations.byId)) return;

		unlinkCollaborator(collaboratorId);

		const organization = organizations.byId[organizationId];

		organization.collaborators.push(collaboratorId);
	}

	function addCollaboratorsToOrganization(collaboratorIds: string[], organizationIds: string[]) {
		organizationIds.forEach(orgId => {
			organizations.byId[orgId].collaborators.push(...collaboratorIds);
			// remove duplicates
			organizations.byId[orgId].collaborators = [
				...new Set(organizations.byId[orgId].collaborators)
			];
		});
	}

	function removeCollaboratorFromOrganization(collaboratorId: string, organizationId: string) {
		if (!(collaboratorId in collaborators.byId)) return;
		if (!(organizationId in organizations.byId)) return;

		unlinkCollaborator(collaboratorId);
	}

	function removeCollaboratorsFromOrganizations(
		collaboratorIds: string[],
		organizationIds: string[]
	) {
		organizationIds.forEach(orgId => {
			collaboratorIds.forEach(collaboratorId => {
				organizations.byId[orgId].collaborators = organizations.byId[
					orgId
				].collaborators.filter(id => id !== collaboratorId);
			});
		});
	}

	/*
	 * HELPERS
	 */

	function unlinkCollaborator(collaboratorId: string) {
		const { collaboratorsLocation } = collaboratorsDataLocation;

		if (collaboratorId in collaboratorsLocation) {
			const { organizationIds } = collaboratorsLocation[collaboratorId];

			organizationIds.forEach(organizationId => {
				if (!(organizationId in organizations.byId)) return;

				const organization = organizations.byId[organizationId];

				organization.collaborators = organization.collaborators.filter(
					id => id !== collaboratorId
				);
			});

			return;
		}

		removeOrderItem(collaboratorId, order, 'collaborator');
	}

	function getLastOrganizationIndex(): number | undefined {
		const organizationIndexes: number[] = [];

		order.forEach((item, index) => {
			if (isOrganizationOrderItem(item)) return organizationIndexes.push(index);
		});

		return organizationIndexes[organizationIndexes.length - 1];
	}

	return {
		/*
		 * COLLABORATORS
		 */
		addCollaborator,
		addCollaborators,
		removeCollaborator,
		removeCollaborators,
		/*
		 * ORGANIZATIONS
		 */
		createOrganization,
		deleteOrganization,
		addCollaboratorToOrganization,
		addCollaboratorsToOrganization,
		removeCollaboratorFromOrganization,
		removeCollaboratorsFromOrganizations
	};
}

function addOrderItem(
	name: string,
	order: CollaboratorsOrder,
	type: 'collaborator' | 'organization',
	destinationIndex?: number
) {
	const orderItem = createOrderItem(name)[type]();

	if (destinationIndex !== undefined) {
		return order.splice(destinationIndex, 0, orderItem);
	}

	order.push(orderItem);
}

function createOrderItem(name: string) {
	return {
		collaborator: () => ({ collaborator: name }),
		organization: () => ({ organization: name })
	};
}

function removeOrderItem(
	name: string,
	order: CollaboratorsOrder,
	type: 'collaborator' | 'organization'
) {
	const orderItemIndex = getOrderItemIndex(name, order, type);

	if (orderItemIndex !== -1) order.splice(orderItemIndex, 1);
}

function getOrderItemIndex(
	name: string,
	order: CollaboratorsOrder,
	type: 'collaborator' | 'organization'
) {
	const isCollaborator = type === 'collaborator';
	const isOrganization = type === 'organization';

	return order.findIndex(item => {
		if (isCollaborator && isCollaboratorOrderItem(item)) return item.collaborator === name;
		if (isOrganization && isOrganizationOrderItem(item)) return item.organization === name;

		return false;
	});
}

import { State } from './types';

const initialState: State = {
	projectId: null,
	rows: {
		idsByPage: {},
		byId: {
			entries: {},
			conflicted: {}
		},
		ids: [],
		count: null,
		latestFetched: false,
		// stores latest revision of entry for conflicts resolution purposes;
		prevEntry: {}
	},
	subEntries: {
		byEntryId: { initial: {}, current: {}, conflicted: {} },
		selected: {
			setName: null,
			subEntryId: null,
			subFormId: null
		},
		count: {
			byEntryId: {}
		}
	},
	files: {
		byId: {},
		toStore: []
	},
	statuses: {
		byId: {
			initial: {},
			current: {}
		}
	},
	drafts: {
		new: {
			data: null,
			fetched: false
		},
		byId: {}
	},
	metadata: {
		fetched: false,
		refetch: false,
		totalCount: 0,
		pageIndex: 0,
		pageSize: 10,
		term: '',
		entryId: null,
		validationErrors: {},
		columnSettings: {
			visible: []
		},
		namesFromUserIds: {}
	},
	errors: {
		data: null,
		filter: {
			columns: false,
			rows: false
		}
	}
};

export default initialState;

import { useMemo, useState } from 'react';
import { Organization } from 'store/data/collaborators';
import { InputType, SelectItem } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import {
	useTranslation,
	useCollaborators,
	useCreateOrganization,
	useAddCollaboratorsToOrganization
} from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';

interface Props {
	collaboratorIds: string[];
	onClose: (success?: boolean) => void;
}

enum AddOption {
	New = 'new',
	Existing = 'existing'
}

export function AddToOrganizationModal({ collaboratorIds, onClose }: Props) {
	const { translate } = useTranslation();

	const [name, setName] = useState('');
	const [addOption, setAddOption] = useState(AddOption.New);
	const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);
	const [
		{
			data: { organizations, organizationNames }
		}
	] = useCollaborators({ lazy: true });

	const [
		{ loading: creatingOrganization, error: errorCreatingOrganization },
		createOrganization
	] = useCreateOrganization();

	const [
		{
			loading: addingCollaboratorsToOrganization,
			error: errorAddingCollaboratorsToOrganization
		},
		addCollaboratorsToOrganizations
	] = useAddCollaboratorsToOrganization();

	useCompletedAction(creatingOrganization, errorCreatingOrganization, () => onClose(true));
	useCompletedAction(
		addingCollaboratorsToOrganization,
		errorAddingCollaboratorsToOrganization,
		() => onClose(true)
	);

	function handleSubmit() {
		if (!(isValid() && !isLoadingAction())) return;

		if (addOption === AddOption.New) {
			const organization: Organization = {
				id: '',
				name: name.trim(),
				collaborators: collaboratorIds
			};

			createOrganization({ organization });
		} else {
			if (selectedItems.length === 0) return;

			const organizationIds = selectedItems.map(item => item.value);
			addCollaboratorsToOrganizations({
				collaborators: collaboratorIds,
				organizationIds
			});
		}
	}

	function isValid(): boolean {
		if (addOption === AddOption.New) {
			const isNameValid = name.trim().length > 0;

			return isNameValid && isNameUnique();
		}

		return selectedItems.length !== 0;
	}

	function isNameUnique(): boolean {
		return !organizationNames.includes(name.trim());
	}

	function isLoadingAction(): boolean {
		return creatingOrganization || addingCollaboratorsToOrganization;
	}

	const organizationSelectItems = [
		{
			label: translate(dict => dict.collaborators.collaboratorsHeader.newGroup),
			value: AddOption.New
		},
		{
			label: translate(dict => dict.collaborators.collaboratorsHeader.existingGroup),
			value: AddOption.Existing
		}
	];

	const organizationDropdownItems = useMemo(() => {
		const items: SelectItem[] = [];
		organizations.forEach(organization => {
			if (!organization.collaborators.includes(collaboratorIds[0]))
				items.push({
					label: organization.name,
					value: organization.id
				});
		});

		return items;
	}, [organizations]);

	const dropdownItemsMap = useMemo(() => {
		return organizationDropdownItems.reduce((map, curr) => {
			map[curr.value] = curr;
			return map;
		}, {} as Record<string, SelectItem>);
	}, [organizationDropdownItems]);

	const onSelectOrganizations = (ids: string[]) => {
		setSelectedItems(ids.map(id => dropdownItemsMap[id]));
	};

	return (
		<Modal
			size={s => s.m}
			title={translate(dict => dict.collaborators.addToOrganisationModal.addTo)}
			primary={{
				label: translate(({ buttons }) =>
					addOption === AddOption.New ? buttons.create : buttons.add
				),
				loading: isLoadingAction(),
				disabled: !isValid(),
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<RadioGroupUncontrolled
				name="options-radio"
				onChange={value => {
					setAddOption(value as AddOption);
					if (value === AddOption.New) setName('');
				}}
				value={
					organizationSelectItems.find(item => item.value === (addOption as string))
						?.value
				}
				options={organizationSelectItems}
			/>

			<Spacer size={s => s.m} />

			{addOption === AddOption.New ? (
				<Input
					type={InputType.Text}
					value={name}
					label={translate(
						dict => dict.collaborators.addToOrganisationModal.groupNameLabel
					)}
					placeholder={translate(
						dict => dict.collaborators.addToOrganisationModal.groupNamePlaceholder
					)}
					error={
						!isNameUnique()
							? translate(dict => dict.collaborators.addToOrganisationModal.error)
							: undefined
					}
					onChange={e => setName(e.target.value)}
					onSubmit={handleSubmit}
					autoFocus
				/>
			) : (
				<CreatableSelect
					hasMultipleValues
					items={organizationDropdownItems}
					canClear
					label={translate(
						dict => dict.collaborators.addToOrganisationModal.groupNameLabel
					)}
					placeholder={translate(
						dict => dict.collaborators.addToOrganisationModal.groupNamePlaceholder
					)}
					onValuesSelected={onSelectOrganizations}
					values={selectedItems}
				/>
			)}
		</Modal>
	);
}

import styled, { css } from 'styled-components';

import { Colors, Shadows, MediaQueries } from 'environment';
import { getTypographyCSS, FontWeights } from 'components/UI/Typography';

import { Responsive } from 'components/UI/Table/Table';

interface WrapperProps {
	isFormActive?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.maxWidth.md} {
		${({ isFormActive }) => {
			if (isFormActive) {
				return css`
					margin: 1.2rem 0 1.2rem 0;
				`;
			}
		}}
	}
`;

export const ResponsiveWrapper = styled(Responsive)`
	margin: 2.4rem;
	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		min-width: 5.6rem;
		max-width: 5.6rem;
		border-top: 0;
	}

	.errored {
		/* Colors.text.error, 10% */
		background-color: rgba(255, 88, 93, 0.1);
	}
`;

export const TableWrapper = styled.div`
	margin: 2.4rem 2.4rem 3.2rem 2.4rem;
`;

interface ContainerProps {
	isFormActive?: boolean;
}

export const Container = styled.div<ContainerProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	background: ${Colors.white};
	box-shadow: ${Shadows.normal};
	border-radius: 0.8rem;

	@media ${MediaQueries.minWidth.md} {
		max-width: calc(100vw - 8rem);
		margin: 1rem -2rem;
	}

	@media ${MediaQueries.maxWidth.md} {
		${({ isFormActive }) => {
			if (!isFormActive) {
				return css`
					max-width: calc(100vw - 4rem);
					margin: 1rem -2rem;
				`;
			}
		}}
	}
	:hover .container-border {
		display: block;
	}
`;

export const ContainerBorder = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 0.8rem;
	pointer-events: none;
	border: 0.2rem solid ${Colors.primary.normal};
	display: none;
`;

export const Header = styled.div`
	display: flex;
	padding: 0.8rem;
	cursor: pointer;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 2rem 2rem;
`;

// TODO: MAKE A GENERAL COMPONENT OUT OF THIS (PILL)
export const EntriesCount = styled.div`
	${getTypographyCSS(t => t.Caption)}

	padding: 0.2rem 0.8rem;
	margin-left: 0.8rem;
	margin-right: 1.6rem;
	color: ${Colors.seriesGreen};
	background-color: rgba(38, 208, 124, 0.1);
	border-radius: 0.4rem;
`;

export const EntryContainer = styled.div``;

export const EntryBorder = styled.div`
	position: absolute;
	top: -1.4rem;
	left: -0.8rem;
	height: calc(100% + 1.6rem);
	width: calc(100% + 1.6rem);
	border-radius: 0.4rem;
	pointer-events: none;
	border: 0.2rem solid ${Colors.primary.normal};
	display: none;
`;

interface EntryContentProps {
	marginTop?: number;
}
export const EntryContent = styled.div<EntryContentProps>`
	position: relative;
	cursor: pointer;

	margin-top: ${({ marginTop }) => marginTop ?? 0}rem;

	:hover ${EntryBorder} {
		display: block;
	}
`;

// TODO: MAKE THIS A GENERAL COMPONENT
export const EntryCount = styled.div`
	${getTypographyCSS(t => t.Caption)}

	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2.4rem;
	min-height: 2.4rem;
	border-radius: 1.2rem;
	user-select: none;

	margin-right: 0.8rem;
	padding: 0 0.7rem;

	color: ${Colors.seriesGreen};
	font-weight: ${FontWeights.medium};
	background-color: rgba(38, 208, 124, 0.1);
`;

export const EntryInputsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
		flex-wrap: nowrap;
	}
`;

export const SeriesDividerContainer = styled.div`
	width: 100%;
	padding: 1.4rem 3.8rem;
	padding-bottom: 2.4rem;
`;

export const SeriesDivider = styled.div`
	border-top-width: 0.1rem;
	border-top-color: ${Colors.text.disabled};
	border-top-style: dashed;
`;

export const EmptySeriesStateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

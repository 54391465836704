import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { usePatientSearchTerm, usePromManualDistribution, useTranslation } from 'hooks/store';

interface Props {
	hasData: boolean;
	onAddNewPatient: () => void;
}

export function PatientsHeader({ hasData, onAddNewPatient }: Props) {
	const { translate } = useTranslation();

	const { isPromManualDistribution } = usePromManualDistribution();
	const [searchTerm, setSearchTerm] = usePatientSearchTerm();

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					// THERE ARE NO PATIENTS FOR MANUAL DISTRIBUTION PROM
					!isPromManualDistribution &&
					hasData && (
						<Flex>
							<SearchInput
								usedInHeader
								placeholder={translate(
									dict => dict.promsPatientsHeader.inputPlaceholder
								)}
								term={searchTerm}
								onChangeTerm={term => setSearchTerm({ term })}
							/>
							{/* PRJCTS-3081 - Import button hidden until functionality is defined*/}
							{/*<ImportButton>{translate(dict => dict.buttons.import)}</ImportButton>*/}
							{addButton({
								label: translate(dict => dict.promsPatientsHeader.addPatientButton),
								action: onAddNewPatient
							})}
						</Flex>
					)
				}
			/>
			<Header.Title title={translate(dict => dict.projectsPage.patient.title)} />
		</>
	);
}

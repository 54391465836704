import { useCallback, useMemo } from 'react';

import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Nullable, SelectItem } from 'types/index';
import { COUNTRIES } from 'helpers/allCountries';
import { isOnWindows } from 'helpers/generic';

type Countries = typeof COUNTRIES;
type Country = Countries[0];

interface DisplayFormat {
	countryCode?: boolean;
}

export function getCountrySelectItems(options?: DisplayFormat): SelectItem[] {
	const selectItems: SelectItem[] = [];

	COUNTRIES.forEach(country => {
		const selectItem = buildCountrySelectItem(country, options);

		selectItems.push(selectItem);
	});

	return selectItems;
}

export function buildCountrySelectItem(country: Country, options?: DisplayFormat): SelectItem {
	const selectItem: SelectItem = { label: '', value: '' };

	const label = `${isOnWindows() ? '' : `${country.unicode} `}${country.name}${
		options?.countryCode ? ` (+${country.dial})` : ''
	}`;

	selectItem.value = country.ISO_2;
	selectItem.label = label;

	return selectItem;
}

export function getCountryByKey(key: keyof Country, value: string) {
	return COUNTRIES.find(country => country[key] === value);
}

interface Props {
	name?: string;
	value?: string;
	label?: string;
	placeholder?: string;
	displayFormat?: DisplayFormat;
	error?: string;
	required?: boolean;
	disabled?: boolean;
	canClear?: boolean;
	maxMenuHeight?: number;
	minMenuHeight?: number;
	className?: string;
	onSelect: (country: Country | null) => void;
	onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
	dataTestId?: string;
}

export function CountrySelector({
	name,
	value,
	label,
	placeholder,
	displayFormat,
	error,
	required,
	disabled,
	canClear,
	maxMenuHeight,
	minMenuHeight,
	className,
	onSelect,
	onFocus,
	onBlur
}: Props) {
	const selectedCountry = useMemo(() => {
		if (!value) return;

		const country = COUNTRIES.find(country => {
			const found = Object.values(country).find(countryField => countryField === value);

			return !!found;
		});

		if (country) return buildCountrySelectItem(country);
	}, [value]);

	const countrySelectItems = useMemo(() => getCountrySelectItems(displayFormat), []);

	const onValueSelected = useCallback(
		(value: Nullable<string>) => {
			if (!value) return onSelect(null);

			const country = getCountryByKey('ISO_2', value);

			if (country) onSelect(country);
		},
		[value]
	);

	return (
		<CreatableSelect
			className={className}
			name={name}
			label={label}
			value={selectedCountry}
			placeholder={placeholder}
			items={countrySelectItems}
			error={error}
			required={required}
			disabled={disabled}
			canClear={canClear}
			maxMenuHeight={maxMenuHeight}
			minMenuHeight={minMenuHeight}
			onValueSelected={onValueSelected}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	);
}

import { useEffect } from 'react';
import { LogisticRegressionAnalysisV2 } from 'api/data/analyses';
import {
	AnalysisErrorWrapper,
	AnalysisViewContainer,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import {
	useProject,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useVariablesDataSelectItems
} from 'hooks/store';

import { LogisticRegressionChartV2 } from './LogisticRegressionChartV2';
import { AnalysisChartContainer, AnalysisStatisticsWrapper } from '../../UI';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { LogisticRegressionTableV2 } from './LogisticRegressionTableV2/LogisticRegressionTableV2';
import { VariablesData } from 'store/data/variables';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: LogisticRegressionAnalysisV2;
	exporting: boolean;
	loading: boolean;
	variablesData: VariablesData;
}

export function LogisticRegressionViewV2({ analysis, loading, exporting, variablesData }: Props) {
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const [{ data: project }] = useProject();
	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		series: analysis.input.series,
		omitVariables: []
	});

	const {
		id,
		options: { configPanel, chartLegend = false },
		output: {
			dataset: { data: dataset }
		},
		input
	} = analysis;

	const logisticRegressionId = ZingChartExportTypes.LogisticRegression + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				logisticRegressionId,
				getExportFileName(
					ExportFileNames.LogisticRegressionChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const legendHeader = input.variables.groupVariables?.[0]?.name
		? selectItemsLabelMap[input.variables.groupVariables?.[0]?.name]
		: undefined;

	const scalesLabels = {
		labelX: selectItemsLabelMap[input.variables.xVariable?.name ?? ''],
		labelY: selectItemsLabelMap[input.variables.yVariable?.name ?? '']
	};

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper
				analysis={analysis}
				analysisType={AnalysisType.LogisticRegressionV2}
			>
				{dataset && (
					<>
						<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
							<LogisticRegressionChartV2
								data={dataset}
								isLegendEnabled={chartLegend}
								legendHeader={legendHeader}
								scalesLabels={scalesLabels}
							/>
							<AnalysisStatisticsWrapper>
								<LogisticRegressionTableV2
									results={dataset}
									variablesData={variablesData}
								/>
							</AnalysisStatisticsWrapper>
						</AnalysisChartContainer>
						<AnalysisExportWrapper>
							<>
								<LogisticRegressionChartV2
									data={dataset}
									isLegendEnabled={chartLegend}
									legendHeader={legendHeader}
									scalesLabels={scalesLabels}
									isForExport
									id={logisticRegressionId}
								/>
							</>
						</AnalysisExportWrapper>
					</>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}

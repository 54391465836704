import { isEmpty, set } from 'lodash';
import * as yup from 'yup';

import type { Variable } from 'api/data/variables';
import { VAR_LABEL_PATTERN } from 'consts';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { DataType } from 'types/data/variables/constants';

type ValidationContext = {
	labels: string[];
	isEdit: boolean;
};

type Errors = {
	label: string;
	categories: string;
	validationCases: {
		minValue: string;
		maxValue: string;
	};
};

function compareValidationCaseValues(type: DataType, maxValue: string, value: string) {
	const isNumber = [DataType.Integer, DataType.Float].includes(type);

	const parsedMaxValue = isNumber ? parseFloat(maxValue) : new Date(maxValue).getTime();
	const parsedValue = isNumber ? parseFloat(value) : new Date(value).getTime();

	return parsedMaxValue < parsedValue;
}

export default function validateVariable(
	draftVariable: Variable,
	context: ValidationContext,
	translate: TranslateFunction
) {
	const errors: Partial<Errors> = {};

	const variableValidationSchema = yup.object({
		type: yup.string().required(),
		validationRange: yup.boolean(),
		fixedCategories: yup.boolean(),
		optimizeForManyValues: yup.boolean(),
		obligatory: yup.boolean(),
		description: yup.string(),
		entryType: yup.string(),
		name: yup.string(),
		personalData: yup.boolean(),
		subType: yup.string(),
		cases: yup.array(),

		label: yup
			.string()
			.required('Please enter a variable name')
			.test(
				'check-patters',
				translate(dict => dict.validation.formVariables.specialCharacters),
				value => (value ? VAR_LABEL_PATTERN.test(value) : true)
			)
			.test('check-label', 'Name already exists', (value, { options }) => {
				const { context } = options;
				const { labels } = context as ValidationContext;

				return value ? !labels.includes(value) : true;
			}),
		categories: yup
			.array()
			.when('fixedCategories', {
				is: true,
				then: schema => schema.min(1, 'Please define fixed values')
			})
			.when('$isEdit', (isEdit, schema) => (isEdit ? schema.min(0) : schema)),

		validationCases: yup.object().when('validationRange', {
			is: true,
			then: yup
				.object()
				.shape({
					minValue: yup.string().test('check-correct-min', (value, context: any) => {
						const { createError, parent, from } = context;
						const { maxValue } = parent;
						const { type } = from[1].value;

						if (value && maxValue) {
							if (compareValidationCaseValues(type, maxValue, value))
								return createError({
									message: 'Please insert a value less than Maximum range'
								});
						}

						return true;
					}),
					maxValue: yup.string().test('check-correct-max', (value, context: any) => {
						const { createError, parent, from } = context;
						const { minValue } = parent;
						const { type } = from[1].value;

						if (value && minValue) {
							if (compareValidationCaseValues(type, value, minValue))
								return createError({
									message: 'Please insert a value less than Maximum range'
								});
						}

						return true;
					})
				})
				.test(
					'at-least-one-not-empty',
					'one field should not be empty',
					({ maxValue, minValue }) => {
						if (!minValue && !maxValue) return false;

						return true;
					}
				)
		})
	});

	try {
		variableValidationSchema.validateSync(draftVariable, {
			abortEarly: false,
			context
		});
	} catch (err) {
		if (err instanceof yup.ValidationError) {
			const { inner } = err;

			if (inner && inner.length) {
				inner.forEach(({ path, message }) => {
					if (path) set(errors, path, message);
				});
			}
		}
	}

	return !isEmpty(errors) ? errors : null;
}

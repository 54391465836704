import { DomainsPayload, AdminApiAccount, GetDomainPayload, AdminAccount } from 'api/account/admin';
import { getOrganizations } from 'store/account/subscription';
import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	// ACTIONS
	CreateAdminUserAction,
	UpdateAdminUserAction,
	GetAdminUsersAction,
	GetAdminUserAction,
	RemoveAdminUserAction,
	ResetAdminUserPasswordAction,
	SetAdminUsersSearchTermAction,
	SetAdminUsersUserIdAction,
	SetAdminUsersTablePageParamsAction,
	SetAdminUsersFilterAction,
	SetAdminUsersDateFilterAction,
	ResetAdminUsersFilterAction,
	ResetAllAdminUsersFiltersAction,
	GetEnterpriseOrganizationAction,
	GetDomainsForOrganization,
	SetAdminOrganizationIdAction,
	AddWhitelistedDomainsAction,
	RemoveDomainsAction
} from './types';

const getAdminUsersAction = (payload: ActionPayload<GetAdminUsersAction>): GetAdminUsersAction => ({
	type: ActionTypes.ADMIN_GET_USERS,
	payload
});

export const getAdminUsers = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.ADMIN_GET_USERS,
		dispatch
	});

	try {
		activity.begin();

		const apiUsers = await context.api.account.admin().getUsers();
		const users: AdminAccount[] = [];
		apiUsers.forEach(apiUser => {
			const { licenseModel, organization, ...fetchedUser } = apiUser;
			const user = { ...fetchedUser, licenceModel: licenseModel };
			users.push(user);
		});
		dispatch(getAdminUsersAction({ users }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const getAdminUserAction = (payload: ActionPayload<GetAdminUserAction>): GetAdminUserAction => ({
	type: ActionTypes.ADMIN_GET_USER,
	payload
});

export const getAdminUser =
	(userId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.ADMIN_GET_USER,
			dispatch
		});

		try {
			activity.begin();

			const apiUser = await context.api.account.admin().getUser(userId);
			const { licenseModel, organization, ...fetchedUser } = apiUser;
			const user = { ...fetchedUser, licenceModel: licenseModel };
			dispatch(getAdminUserAction({ user }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const createAdminUserAction = (
	payload: ActionPayload<CreateAdminUserAction>
): CreateAdminUserAction => ({
	type: ActionTypes.ADMIN_CREATE_USER,
	payload
});

export const addAdminUser =
	(user: AdminAccount): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.ADMIN_CREATE_USER,
			dispatch
		});

		try {
			activity.begin();

			const { licenceModel, ...userToAdd } = user;
			const apiUser: AdminApiAccount = { ...userToAdd, licenseModel: licenceModel };

			const userId = await context.api.account.admin().addUser(apiUser);
			user.userid = userId;

			dispatch(createAdminUserAction({ user }));
			dispatch(getOrganizations());
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateAdminUserAction = (
	payload: ActionPayload<UpdateAdminUserAction>
): UpdateAdminUserAction => ({
	type: ActionTypes.ADMIN_UPDATE_USER,
	payload
});

export const updateAdminUser =
	(user: AdminAccount): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.ADMIN_UPDATE_USER,
			dispatch
		});

		try {
			activity.begin();
			const { licenceModel, ...userToUpdate } = user;
			const apiUser: AdminApiAccount = { ...userToUpdate, licenseModel: licenceModel };

			await context.api.account.admin().updateUser(apiUser);

			dispatch(updateAdminUserAction({ user }));
			dispatch(getOrganizations());
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const removeAdminUserAction = (): RemoveAdminUserAction => ({
	type: ActionTypes.ADMIN_REMOVE_USER
});

export const removeAdminUser = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.ADMIN_REMOVE_USER,
		dispatch
	});

	try {
		activity.begin();

		const {
			byId,
			metadata: { userId }
		} = getState().account.admin.users;

		if (userId && byId[userId]) {
			await context.api.account.admin().removeUser(userId);
			dispatch(removeAdminUserAction());
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const resetAdminUserPasswordAction = (
	payload: ActionPayload<ResetAdminUserPasswordAction>
): ResetAdminUserPasswordAction => ({
	type: ActionTypes.ADMIN_RESET_USER_PASSWORD,
	payload
});

export const resetAdminUserPassword = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.ADMIN_RESET_USER_PASSWORD,
		dispatch
	});

	try {
		activity.begin();

		const {
			metadata: { userId },
			byId
		} = getState().account.admin.users;

		if (userId && byId[userId]) {
			await context.api.account.admin().resetPassword(userId);

			dispatch(resetAdminUserPasswordAction({ userId }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const resetAdminUserMFA = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.ADMIN_RESET_USER_MFA,
		dispatch
	});

	try {
		activity.begin();

		const {
			metadata: { userId },
			byId
		} = getState().account.admin.users;

		if (userId && byId[userId]) {
			await context.api.account.admin().resetUserMFA(userId);
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

//////////////////////////////////////////////////////////////////////////////
// ================================= Enterprise============================ //
//////////////////////////////////////////////////////////////////////////////

const getEnterpriseOrganizationsAction = (
	payload: ActionPayload<GetEnterpriseOrganizationAction>
): GetEnterpriseOrganizationAction => ({
	type: ActionTypes.ADMIN_GET_ENTERPRISE_ORGANIZATIONS,
	payload
});

export const getEnterpriseOrganizations = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.ADMIN_GET_ENTERPRISE_ORGANIZATIONS,
		dispatch
	});

	try {
		activity.begin();

		const organizations = await context.api.account.admin().getEnterpriseOrganizations();

		dispatch(getEnterpriseOrganizationsAction({ organizations }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const getDomainsForOrganizationActions = (
	payload: ActionPayload<GetDomainsForOrganization>
): GetDomainsForOrganization => ({
	type: ActionTypes.ADMIN_GET_DOMAINS_FOR_ORGANIZATION,
	payload
});

export const getDomainsForOrganization = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.ADMIN_GET_DOMAINS_FOR_ORGANIZATION,
		dispatch
	});

	try {
		activity.begin();

		const { enterpriseOrganizations } = getState().account.admin;
		if (!enterpriseOrganizations) return;
		const { byOrganizationId, organizationId } = enterpriseOrganizations;
		const selectedOrganization = byOrganizationId[organizationId];

		const payloadAPI: GetDomainPayload = {
			organizationId: selectedOrganization.orgVATNumber,
			country: selectedOrganization.country
		};
		const domains = await context.api.account.admin().getDomainsForOrganization(payloadAPI);

		dispatch(getDomainsForOrganizationActions({ domains, organizationId }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const addWhitelistedDomainsAction = (
	payload: ActionPayload<AddWhitelistedDomainsAction>
): AddWhitelistedDomainsAction => ({
	type: ActionTypes.ADD_WHITELISTED_DOMAINS,
	payload
});

export const addWhitelistedDomains =
	(domains: string[]): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.ADD_WHITELISTED_DOMAINS,
			dispatch
		});

		try {
			activity.begin();

			const { enterpriseOrganizations } = getState().account.admin;
			if (!enterpriseOrganizations) return;
			const { byOrganizationId, organizationId } = enterpriseOrganizations;
			const selectedOrganization = byOrganizationId[organizationId];

			const payload: DomainsPayload = {
				organizationId: selectedOrganization.orgVATNumber,
				country: selectedOrganization.country,
				domains
			};
			const statusSuccess = await context.api.account.admin().addWhitelistedDomains(payload);

			if (statusSuccess) {
				dispatch(addWhitelistedDomainsAction({ organizationId, domains }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};
const removeDomainsAction = (payload: ActionPayload<RemoveDomainsAction>): RemoveDomainsAction => ({
	type: ActionTypes.REMOVE_DOMAINS,
	payload
});

export const removeDomains =
	(domains: string[]): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_DOMAINS,
			dispatch
		});

		try {
			activity.begin();

			const { enterpriseOrganizations } = getState().account.admin;
			if (!enterpriseOrganizations) return;
			const { byOrganizationId, organizationId } = enterpriseOrganizations;
			const selectedOrganization = byOrganizationId[organizationId];

			const payload: DomainsPayload = {
				organizationId: selectedOrganization.orgVATNumber,
				country: selectedOrganization.country,
				domains
			};
			const statusSuccess = await context.api.account
				.admin()
				.removeOrganizationDomains(payload);

			if (statusSuccess) {
				dispatch(removeDomainsAction({ organizationId, domains }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

export const setAdminUsersSearchTerm = (
	payload: ActionPayload<SetAdminUsersSearchTermAction>
): SetAdminUsersSearchTermAction => ({
	type: ActionTypes.ADMIN_SET_USERS_SEARCH_TERM,
	payload
});

export const setAdminUsersUserId = (
	payload: ActionPayload<SetAdminUsersUserIdAction>
): SetAdminUsersUserIdAction => ({
	type: ActionTypes.ADMIN_SET_USERS_USER_ID,
	payload
});

export const setAdminUsersTablePageParams = (
	payload: ActionPayload<SetAdminUsersTablePageParamsAction>
): SetAdminUsersTablePageParamsAction => ({
	type: ActionTypes.ADMIN_SET_TABLE_PAGE_PARAMS,
	payload
});

export const setOrganizationId = (
	payload: ActionPayload<SetAdminOrganizationIdAction>
): SetAdminOrganizationIdAction => ({
	type: ActionTypes.ADMIN_SET_ORGANIZATION_ID,
	payload
});

//////////////////////////////////////////////////////////////////////////////
// ================================= FILTERS =================================
//////////////////////////////////////////////////////////////////////////////

export const setAdminUsersFilter = (
	payload: ActionPayload<SetAdminUsersFilterAction>
): SetAdminUsersFilterAction => ({
	type: ActionTypes.ADMIN_SET_USERS_FILTER,
	payload
});

export const setAdminUsersDateFilter = (
	payload: ActionPayload<SetAdminUsersDateFilterAction>
): SetAdminUsersDateFilterAction => ({
	type: ActionTypes.ADMIN_SET_USERS_DATE_FILTER,
	payload
});

export const resetAdminUsersFilter = (
	payload: ActionPayload<ResetAdminUsersFilterAction>
): ResetAdminUsersFilterAction => ({
	type: ActionTypes.ADMIN_RESET_USERS_FILTER,
	payload
});

export const resetAllAdminUsersFilters = (): ResetAllAdminUsersFiltersAction => ({
	type: ActionTypes.ADMIN_RESET_ALL_USERS_FILTERS
});

//////////////////////////////////////////////////////////////////////////////
// =============================== END FILTERS ===============================
//////////////////////////////////////////////////////////////////////////////

import { useContext } from 'react';

import { DependenciesContext } from 'contexts';

export function useDependenciesContext() {
	const context = useContext(DependenciesContext);

	if (context === undefined) {
		throw new Error(
			'useDependenciesContext must be used within a `DependenciesContext.Provider`'
		);
	}

	return context;
}

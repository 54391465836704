import { OperationResult } from 'hooks/store/types';
import { Nullable } from 'types/index';
import { ActionTypes, getTopicName } from 'store/data/notifications';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useNotificationsTopicName(): OperationResult<Nullable<string>> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.notifications.topicName);

	const fetched = data !== null;

	const [{ loading, error }] = useActivity(ActionTypes.GET_TOPIC_NAME);

	function handler() {
		dispatch(getTopicName());
	}

	return [{ loading, error, data, fetched }, handler];
}

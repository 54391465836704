import { TOC, Topic } from 'components/Help';
import { Container } from './HelpPage.style';
import { Header } from 'components/Header';
import { Grid } from 'components/UI/Grid';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useHelpSearchTerm, useHelpTopics } from 'hooks/store';

export function HelpPage() {
	const { translate } = useTranslation();

	const [searchTerm, setSearchTerm] = useHelpSearchTerm();

	const [
		{
			data: helpTopicList,
			loading: loadingTopics,
			error: helpTopicsError,
			fetched: areTopicsFetched
		}
	] = useHelpTopics();

	const showLoading = helpTopicsError ? false : loadingTopics || !areTopicsFetched;

	return (
		<>
			<Header.Main />
			<Header.Title
				title="Ledidi User Guide"
				component={
					<div>
						<SearchInput
							placeholder={translate(dict => dict.helpPage.headerPlaceholder)}
							term={searchTerm}
							onChangeTerm={setSearchTerm}
						/>
					</div>
				}
			/>

			<Suspend loading={showLoading} immediate={!areTopicsFetched}>
				<Grid.Container>
					{helpTopicList.length ? (
						<Container>
							{/* TABLE OF CONTENTS */}
							<TOC />

							{/* VIEW TOPIC */}
							<Topic />
						</Container>
					) : (
						<Typography.H6>{translate(dict => dict.helpPage.noTopics)}</Typography.H6>
					)}
				</Grid.Container>
			</Suspend>
		</>
	);
}

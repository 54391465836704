import { ProjectFilterType } from 'types/data/projects/constants';
import { ProjectTableView, ProjectTableViewData } from 'store/data/projects';
import { Operator } from 'api/data/filters';
import { Row, FilterValue, IdType } from 'react-table';

export function mapColumnNameToType(title: string) {
	switch (title) {
		case ProjectTableView.projectName:
		case ProjectTableView.metadataArchiveNumber:
		case ProjectTableView.metadataProjectType:
			return ProjectFilterType.String;
		case ProjectTableView.endDate:
			return ProjectFilterType.Date;
		case ProjectTableView.status:
			return ProjectFilterType.Status;
		default:
			return ProjectFilterType.Integer;
	}
}

export function mapColumnNameToValue(title: string) {
	switch (title) {
		case ProjectTableViewData.title:
			return ProjectTableView.projectName;
		case ProjectTableViewData.collaborators:
			return ProjectTableView.collaborators;
		case ProjectTableViewData.number:
			return ProjectTableView.givenProjectNumber;
		case ProjectTableViewData.status:
			return ProjectTableView.status;
		case ProjectTableViewData.entries:
			return ProjectTableView.entries;
		case ProjectTableViewData.endDate:
			return ProjectTableView.endDate;
		case ProjectTableViewData.metadataArchiveNumber:
			return ProjectTableView.metadataArchiveNumber;
		default:
			return ProjectTableView.metadataProjectType;
	}
}

function filterGreaterThan(
	rows: Array<Row<any>>,
	id: Array<IdType<any>>,
	filterValue: FilterValue
) {
	return rows.filter(row => {
		const rowValue = row.values[id[0]];
		return filterValue?.value ? rowValue >= filterValue?.value : true;
	});
}

function filterLessThan(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
	return rows.filter(row => {
		const rowValue = row.values[id[0]];
		return filterValue?.value ? rowValue <= filterValue?.value : true;
	});
}

function equals(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
	return rows.filter(row => {
		const rowValue = row.values[id[0]];
		return filterValue?.value ? rowValue == filterValue?.value : true;
	});
}

function includes(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
	return rows.filter(row => {
		const rowValue = row.values[id[0]];
		return filterValue?.value ? rowValue.includes(filterValue.value) : true;
	});
}

function between(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
	return rows.filter(row => {
		const rowValue = row.values[id[0]];
		return filterValue?.value
			? rowValue >= filterValue.value[0] && rowValue <= filterValue.value[1]
			: true;
	});
}

export function getOperator(operator: string) {
	switch (operator) {
		case Operator.Equals:
			return equals;
		case Operator.In:
			return includes;
		case Operator.Between:
			return between;
		case Operator.GreaterThan:
			return filterGreaterThan;
		default:
			return filterLessThan;
	}
}

export interface Option {
	title: string;
	onClick: (id: string) => void;
}

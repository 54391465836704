import { createSelector } from 'reselect';

import { selectParams } from 'store/utils';

import { Revision, State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getMetadata({ metadata }: State) {
	return metadata;
}

function getByEntryId({ byEntryId }: State) {
	return byEntryId;
}

function getById({ byId }: State) {
	return byId;
}

function getCurrentRevision({ currentRevision }: State) {
	return currentRevision;
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectCurrentRevision = createSelector(
	[getCurrentRevision],
	currentRevision => currentRevision
);

type SelectRevisionsParams = { entryId: string | null };

export const selectRevisions = createSelector(
	[
		getById,
		getByEntryId,
		// PARAMS
		(_: State, params: SelectRevisionsParams) => selectParams.encode(params)
	],
	(byId, byEntryId, params) => {
		const { entryId } = selectParams.decode<SelectRevisionsParams>(params);

		if (entryId !== null && entryId in byEntryId) {
			const { revisionIds } = byEntryId[entryId];

			const revisions: Revision[] = revisionIds.map(revisionId => byId[revisionId]);

			return revisions;
		}

		// TODO: RETURN ARRAY WHEN MODIFIED ACROSS THE APP THE EXPECTATION AND `fetched` LOGIC CHECK
		// return [];
	}
);

export const selectRevision = createSelector([getById, getMetadata], (byId, metadata) => {
	const { revisionId } = metadata;

	if (revisionId !== null && revisionId in byId) return byId[revisionId];
});
export const selectSetRevision = createSelector([getById, getMetadata], (byId, metadata) => {
	const { setRevisionId } = metadata;

	if (setRevisionId !== null && setRevisionId in byId) return byId[setRevisionId];
});

import produce from 'immer';
import initialState from './initialState';
import { State, Actions, ActionTypes, EnterpriseRole } from './types';
import { GenericMap } from 'types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS: {
			const { projects } = action.payload;

			return produce(state, draft => {
				draft.allEnterpriseProjects = projects;
			});
		}

		case ActionTypes.CREATE_ENTERPRISE_ROLE: {
			const { role } = action.payload;
			const { roleId } = role;

			return produce(state, draft => {
				draft.roles.byId[roleId] = role;
			});
		}

		case ActionTypes.GET_ENTERPRISE_ROLES: {
			const { roles } = action.payload;

			return produce(state, draft => {
				const rolesById: GenericMap<EnterpriseRole> = {};
				draft.roles.metadata.fetched = true;
				if (roles.length)
					roles.forEach(role => {
						const roleId = role.roleId;
						rolesById[roleId] = role;
						draft.roles.byId = rolesById;
					});
			});
		}

		case ActionTypes.UPDATE_ENTERPRISE_ROLE: {
			const { role } = action.payload;
			const { roleId } = role;

			return produce(state, draft => {
				draft.roles.byId[roleId] = role;
			});
		}

		case ActionTypes.DELETE_ENTERPRISE_ROLE: {
			const { roleId: roleIdToDelete } = action.payload;

			return produce(state, draft => {
				delete draft.roles.byId[roleIdToDelete];
			});
		}

		case ActionTypes.ADMIN_SET_ROLES_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.roles.metadata.searchTerm = term;
			});
		}

		case ActionTypes.SET_ORGANIZATION_ACCESS_RIGHTS: {
			const { organizationAccessRights } = action.payload;

			return produce(state, draft => {
				draft.organizationAccessRights = organizationAccessRights;
			});
		}

		default: {
			return state;
		}
	}
};

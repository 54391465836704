import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { ROUTES } from 'types/navigation';
import {
	FormFieldPageLocationByName,
	FormGroupPaginationDataByname,
	SetGroupPaginationDataInput,
	VariableFilteringMap
} from 'store/data/entries';
import { Revision } from 'store/data/revisions';
import { VariablesDataArray } from 'store/data/variables';
import { BooleanMap, GetMutableState, SetMutableState } from 'types/index';

import { AddEditInput } from './AddEditInput';
import { AddEditInputGroup } from './AddEditInputGroup';
import { AddEditInputSet } from './AddEditInputSet';

import { Container, Divider } from './AddEditInputs.style';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { entryFormChangePageSetEvent, entryFormChangePageEvent } from 'helpers/entries';
import { useCustomEventListener } from 'helpers/events';
import { paginate } from 'helpers/generic';
import { withMemo } from 'helpers/HOCs';
import {
	variablesDataArrayIterator,
	getGroupDataVariableNames,
	variablesDataArrayJSXIterator,
	isVariableVisible
} from 'helpers/variables';
import { useRouteMatch } from 'hooks/navigation';
import {
	useVariables,
	useTranslation,
	useSeriesEntriesCount,
	useEntryId,
	useEntry,
	useEntriesErrors
} from 'hooks/store';
import { usePaginate, usePrevious } from 'hooks/utils';

interface Props {
	tooltipContainer?: HTMLDivElement;
	openCustomsMap: BooleanMap;
	variableVisibilityMap: BooleanMap;
	variableFilteringMap: VariableFilteringMap;
	groupsExpanded: BooleanMap;
	customVariablesDataArray?: VariablesDataArray;
	revision?: Revision;
	currentChange?: number;
	readOnly?: boolean;
	isRevisionSelected?: boolean;
	uniqueFieldsError?: string[];
	isSeriesEntryForm?: boolean;
	dataTestIdEntryNumber?: number;
	setGroupExpanded: (groupName: string, value?: boolean) => void;
	setFormFieldPageLocationByName: SetMutableState<FormFieldPageLocationByName>;
	getGroupPaginationDataByName: GetMutableState<FormGroupPaginationDataByname>;
	setGroupPaginationData: (input: SetGroupPaginationDataInput) => void;
	/**
	 * Provided for scope
	 */
	setName?: string;
}

function Component({
	tooltipContainer,
	openCustomsMap,
	variableVisibilityMap,
	variableFilteringMap,
	groupsExpanded,
	customVariablesDataArray,
	revision,
	currentChange,
	readOnly,
	isRevisionSelected,
	uniqueFieldsError,
	isSeriesEntryForm,
	dataTestIdEntryNumber,
	setGroupExpanded,
	setFormFieldPageLocationByName,
	getGroupPaginationDataByName,
	setGroupPaginationData,
	/**
	 * Provided for scope
	 */
	setName
}: Props) {
	const [
		{
			data: { variablesDataArray }
		}
	] = useVariables({
		initial: true,
		lazy: true,
		omit: { promGenerated: true }
	});

	const { translate } = useTranslation();

	const [{ data: entriesCount, fetched: isEntriesCountFetched }] = useSeriesEntriesCount({
		lazy: true
	});

	const [entryId] = useEntryId();
	const [{ data: entry }] = useEntry({ lazy: true });
	const [{ errors: entriesErrors }] = useEntriesErrors();

	const isPatientSurvey = useRouteMatch([ROUTES.PatientEditEntry, ROUTES.PatientManualSurvey]);

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page,
		pages,
		changePage,
		changePageSize
	} = usePaginate(customVariablesDataArray ?? variablesDataArray, {
		threshold: 50,
		pageSize: 50
	});

	const dataArray = useMemo(() => {
		const dataArray: string[] = [];
		const data = customVariablesDataArray ?? variablesDataArray;
		if (revision) {
			data.forEach(element => {
				if ('name' in element && revision?.changes.variableNames.includes(element.name)) {
					dataArray.push(element.name);
				} else if ('groupName' in element) {
					element.groupVariables.forEach(el => {
						if (revision?.changes.variableNames.includes(el.name)) {
							dataArray.push(el.name);
						}
					});
				}
			});
		}
		return dataArray;
	}, [customVariablesDataArray, variablesDataArray, revision]);

	// TODO: REFACTOR
	const prevPages = usePrevious(pages);
	useEffect(() => {
		if (isEqual(prevPages, pages)) return;

		const formFieldPageLocationByName: FormFieldPageLocationByName = {};

		pages.forEach((page, pageIndex) => {
			variablesDataArrayIterator(
				page,
				variable => {
					formFieldPageLocationByName[variable.name] = { pageIndex };
				},
				group => {
					const groupVariableNames = getGroupDataVariableNames(group);

					// TODO: THIS NEEDS TO BE SYNCED WITH THE PAGINATION WITHIN THE GROUP - REFACTOR LATER
					const groupPaginationDataByName = getGroupPaginationDataByName();
					const groupPaginationData = groupPaginationDataByName[group.groupName];
					const groupPageSize = groupPaginationData ? groupPaginationData.pageSize : 25;
					const groupPageCount = Math.ceil(groupVariableNames.length / groupPageSize);

					const groupPages: string[][] = [];

					for (let index = 0; index < groupPageCount; index++) {
						/*
						 * COLUMNS
						 */
						const groupPage = paginate(groupVariableNames, groupPageSize, index);

						groupPages.push(groupPage);
					}

					groupPages.forEach((groupPage, groupPageIndex) => {
						groupPage.forEach(variableName => {
							formFieldPageLocationByName[variableName] = {
								pageIndex,
								group: {
									groupName: group.groupName,
									groupPageIndex
								}
							};
						});
					});
				},
				// VARIABLE SET - OMIT
				() => null
			);
		});

		setFormFieldPageLocationByName(formFieldPageLocationByName);
	}, [pages]);

	// CHANGE PAGE EVENT LISTENER
	useCustomEventListener(
		setName ? entryFormChangePageSetEvent : entryFormChangePageEvent,
		{
			onListen: ({ pageIndex }) => changePage(pageIndex)
		},
		[]
	);

	function getPagination(params?: { bottom: boolean }) {
		return (
			<Pagination
				className="pagination"
				totalCountLabel="form elements"
				pageIndex={pageIndex}
				pageSize={pageSize}
				pagesCount={pagesCount}
				changePage={changePage}
				changePageSize={changePageSize}
				showCounts={false}
				simpleVersion={params?.bottom}
			/>
		);
	}

	function isVariableErrored(variableName: string) {
		if (!(entryId && entriesErrors)) return;

		const entryErrors = entriesErrors.rows[entryId] ?? [];

		return entryErrors.includes(variableName);
	}

	function isUniqueError(variableName: string) {
		if (uniqueFieldsError?.includes(variableName)) {
			return translate(dict => dict.errors.ui.entries.uniqueValue);
		}
	}

	const inputProps = {
		openCustomsMap,
		variableFilteringMap,
		revision,
		isRevisionSelected,
		readOnly
	};

	function dataTestIdValue(variableName: string) {
		if (dataTestIdEntryNumber) {
			return variableName.replace(/\s/g, '').toLowerCase() + dataTestIdEntryNumber;
		}

		return variableName.replace(/\s/g, '').toLowerCase();
	}

	return (
		<Container>
			{/* UPPER PAGINATION */}
			{shouldPaginate && (
				<Flex column fullWidth>
					{getPagination()}
					<Divider />
					<Spacer size={s => s.xs} />
				</Flex>
			)}

			{variablesDataArrayJSXIterator(
				page,
				/**
				 * VARIABLE
				 */
				variable => {
					if (isVariableVisible(variable.name, variableVisibilityMap)) {
						return (
							<AddEditInput
								tooltipContainer={tooltipContainer}
								key={`variable-${variable.name}`}
								variable={variable}
								{...inputProps}
								borderError={isVariableErrored(variable.name)}
								uniqueError={isUniqueError(variable.name)}
								dataTestId={dataTestIdValue(variable.label)}
							/>
						);
					}
				},
				/**
				 * GROUP DATA
				 */
				groupData => {
					return (
						<AddEditInputGroup
							dataTestId={dataTestIdValue(groupData.groupLabel)}
							isSeriesEntryForm={isSeriesEntryForm}
							key={`group-${groupData.groupName}`}
							groupData={groupData}
							expanded={
								groupsExpanded[groupData.groupName] ||
								(Object.values(groupData.groupVariables).filter(
									el =>
										currentChange &&
										el &&
										revision?.changes.variableNames.includes(el.name) &&
										dataArray.indexOf(el.name) === currentChange - 1
								).length > 0 &&
									!!isRevisionSelected)
							}
							setGroupExpanded={setGroupExpanded}
							getGroupPaginationDataByName={getGroupPaginationDataByName}
							setGroupPaginationData={setGroupPaginationData}
							revision={revision}
							isRevisionSelected={isRevisionSelected}
							renderVariable={variable =>
								isVariableVisible(variable.name, variableVisibilityMap) && (
									<AddEditInput
										tooltipContainer={tooltipContainer}
										dataTestId={dataTestIdValue(variable.label)}
										key={`group-${groupData.groupName}-variable-${variable.name}`}
										variable={variable}
										{...inputProps}
										borderError={isVariableErrored(variable.name)}
										uniqueError={isUniqueError(variable.name)}
									/>
								)
							}
						/>
					);
				},

				/**
				 * VARIABLE SET DATA
				 */
				variableSetData =>
					/**
					 * MOD-581 - see Jan Tore Lium's comment
					 * https://ledidi.atlassian.net/browse/MOD-581?focusedCommentId=30229
					 */
					!isPatientSurvey && (
						<AddEditInputSet
							isRevisionSelected={isRevisionSelected}
							key={`variable_set-${variableSetData.setName}`}
							variableSetData={variableSetData}
							entriesCountData={{
								count: entriesCount[variableSetData.setName] ?? null,
								fetched: !!isEntriesCountFetched
							}}
							mainEntry={entry}
							readOnly={readOnly}
						/>
					)
			)}

			{/* LOWER PAGINATION */}
			{shouldPaginate && (
				<Flex column fullWidth>
					<Spacer size={s => s.xs} />
					<Divider />
					<Flex
						css={`
							.pagination {
								width: unset;
							}
						`}
						fullWidth
					>
						<Flex fullWidth />
						{getPagination({ bottom: true })}
					</Flex>
				</Flex>
			)}
		</Container>
	);
}

export const AddEditInputs = withMemo(Component);

import { Collaborator } from 'store/data/collaborators';
import { Flex } from 'components/UI/Flex';
import { UserCard } from 'components/UI/UserCard';
import { useAccount } from 'hooks/store';

interface Props {
	collaborator: Collaborator;
	isProjectOwner?: boolean;
	isSelf?: boolean;
}

export function CollaboratorDetails({ collaborator, isProjectOwner, isSelf }: Props) {
	const {
		userId,
		userFirstName,
		userSirName,
		position,
		emailAddress,
		phoneNumber,
		city,
		country,
		workplace,
		department,
		pending
	} = collaborator;

	const [
		{
			data: { details }
		}
	] = useAccount();

	return (
		<Flex column marginOffset={{ bottom: 2.4 }}>
			<UserCard.Details
				userId={pending ? null : userId}
				userData={{
					emailAddress,
					userFirstName,
					userSirName,
					position,
					imageString: isSelf ? details?.imageString : '',
					imageURL: isSelf ? details?.imageString : '',
					phoneNumber,
					city,
					country,
					workplace,
					department
				}}
				isSelf={isSelf}
				isOwner={isProjectOwner}
			/>
		</Flex>
	);
}

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DistributionAmountOfTime, BaseDistributionData } from 'api/data/patients';
import { Svgs } from 'environment';
import { updateDistribution, ActionTypes, deleteDistribution } from 'store/data/patients';
import { Form } from 'store/data/forms';
import { InputType, GenericMap } from 'types/index';
import { Container, Header, HeaderInfo, Body, Row } from './DistributionCard.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Input } from 'components/UI/Inputs/Input';
import { Dropdown } from 'components/UI/Dropdown';
import { Button } from 'components/UI/Interactables/Button';
import { computeNewTimeToSend } from 'helpers/dateFormat';
import { getNumeralOrderShortEng } from 'helpers/displayers';
import { useDebounce, useDispatch, usePrevious } from 'hooks/utils';
import { useTranslation, useDistributionById, useActivity } from 'hooks/store';

const amountOfTime: GenericMap<DistributionAmountOfTime> = {
	hours: 'hours',
	days: 'days',
	weeks: 'weeks',
	months: 'months'
};

interface Props {
	id: string;
	index: number;
	globalStartDate?: string;
	forms: Form[];
	formsActive: boolean;
}

export function DistributionCard({ id, index, globalStartDate, forms, formsActive }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { id: promId } = useParams<{ id: string }>();

	const isNewDistribution = id.startsWith('-');

	const [isOpen, setIsOpen] = useState(isNewDistribution);

	const distribution = useDistributionById(id, promId as string);

	const [{ loading: savingDistributions, error: errorSavingDistributions }] = useActivity(
		ActionTypes.UPDATE_DISTRIBUTIONS
	);

	const [internalQuantity, setInternalQuantity] = useState<number | undefined>(
		distribution?.quantity ?? undefined
	);

	const [internalUnitOfTime, setInternalUnitOfTime] = useState<
		undefined | DistributionAmountOfTime
	>(distribution?.unitOfTime);

	const wasSavingDistributions = usePrevious(savingDistributions);
	useEffect(() => {
		if (wasSavingDistributions && !savingDistributions && !errorSavingDistributions) {
			setIsOpen(false);
		}
	}, [savingDistributions, errorSavingDistributions]);

	useEffect(() => {
		if (errorSavingDistributions) {
			setInternalQuantity(distribution?.quantity);
			setInternalUnitOfTime(distribution?.unitOfTime);
		}
	}, [errorSavingDistributions, distribution?.quantity, distribution?.unitOfTime]);

	useEffect(() => {
		setInternalUnitOfTime(distribution?.unitOfTime);
	}, [distribution?.unitOfTime]);

	useDebounce(
		() => {
			if (distribution?.quantity !== internalQuantity) updateQuantity();
		},
		[internalQuantity],
		150
	);

	const numeralOrder = getNumeralOrderShortEng(index);

	function onChangeQuantityHandler(value: string) {
		// allow numbers only
		value = value.replace(/[^0-9]+/g, '');
		// trim 0's from the begining
		value = value.replace(/[^1-9]*/, '');

		const newValue = Number(value) || undefined;

		setInternalQuantity(newValue);
	}

	function updateQuantity() {
		let newTimeToSendValue: string | undefined;
		if (
			globalStartDate &&
			internalQuantity &&
			internalUnitOfTime &&
			distribution?.quantity !== internalQuantity
		) {
			newTimeToSendValue = computeNewTimeToSend(
				globalStartDate,
				internalUnitOfTime,
				internalQuantity
			);
		}
		const updatedDistribution: BaseDistributionData = {
			...distribution,
			quantity: internalQuantity,
			unitOfTime: internalUnitOfTime,
			...(!!newTimeToSendValue && { timeToSend: newTimeToSendValue })
		};

		dispatch(
			updateDistribution({
				updatedDistribution,
				internalId: distribution?.distributionEntryId
			})
		);
	}

	function updateUnitOfTime(key: DistributionAmountOfTime) {
		let newTimeToSendValue: string | undefined;
		setInternalUnitOfTime(key);
		if (globalStartDate && internalQuantity && internalUnitOfTime) {
			newTimeToSendValue = computeNewTimeToSend(globalStartDate, key, internalQuantity);
		}
		if (internalQuantity !== undefined) {
			const updatedDistribution: BaseDistributionData = {
				...distribution,
				quantity: internalQuantity,
				unitOfTime: key,
				...(!!newTimeToSendValue && { timeToSend: newTimeToSendValue })
			};

			dispatch(
				updateDistribution({
					updatedDistribution,
					internalId: distribution?.distributionEntryId
				})
			);
		}
	}

	function updateAssignedForm(formId: string | null) {
		const updatedDistribution: BaseDistributionData = { ...distribution };

		if (formId !== null) {
			updatedDistribution.formId = formId;
		} else {
			delete updatedDistribution.formId;
		}

		dispatch(
			updateDistribution({
				updatedDistribution,
				internalId: distribution?.distributionEntryId
			})
		);
	}

	function onDeleteDistribution() {
		dispatch(deleteDistribution({ distributionId: distribution?.distributionEntryId }));
	}

	function computeSubtitle() {
		let computedUnitOfTime = distribution?.unitOfTime ?? '';

		// remove the plural "s" if singular
		if (distribution?.quantity === 1) {
			computedUnitOfTime = computedUnitOfTime.substring(0, computedUnitOfTime.length - 1);
		}

		return `${distribution?.quantity ?? '-'} ${computedUnitOfTime}`;
	}

	const isInitialDistributionCard = !!distribution?.systemGenerated;

	return (
		<Container isOpen={isOpen}>
			<Header onClick={() => setIsOpen(state => !state)}>
				<Icon svg={Svgs.ChevronDown} rotate={isOpen ? 180 : 0} propagate />
				<HeaderInfo>
					<Typography.Paragraph fontweight={w => w.medium}>
						{`${
							!isInitialDistributionCard
								? index + numeralOrder
								: translate(dict => dict.terms.initial)
						} ${translate(dict => dict.terms.distributionLowerCase)}`}
					</Typography.Paragraph>
					<Typography.Caption>
						{!isInitialDistributionCard
							? computeSubtitle()
							: translate(({ patientCard }) => patientCard.startDate)}
					</Typography.Caption>
				</HeaderInfo>
			</Header>
			{isOpen && (
				<Body>
					{!isInitialDistributionCard && (
						<Row>
							<Input
								placeholder="value"
								type={InputType.Text}
								value={internalQuantity ?? ''}
								onChange={e => onChangeQuantityHandler(e.target.value)}
								autoFocus={!internalQuantity}
							/>
							<Dropdown
								button
								title={
									internalUnitOfTime
										? translate(
												dict =>
													dict.promsPatients.distributionList.unitOfTime[
														internalUnitOfTime
													]
										  )
										: translate(
												dict => dict.promsPatients.distributionList.unit
										  )
								}
								shouldScrollIntoView
							>
								{Object.values(amountOfTime).map(val => (
									<Dropdown.Item key={val} onClick={() => updateUnitOfTime(val)}>
										{translate(
											dict =>
												dict.promsPatients.distributionList.unitOfTime[val]
										)}
									</Dropdown.Item>
								))}
							</Dropdown>
						</Row>
					)}
					<>
						<Typography.Caption
							marginOffset={{ top: isInitialDistributionCard ? 0 : 2 }}
						>
							{translate(dict => dict.promsPatients.distributionList.selectAForm)}
						</Typography.Caption>
						<Dropdown
							button
							disabled={!formsActive}
							title={
								distribution?.formId
									? forms.find(
											form =>
												form.id === (distribution?.formId ?? '').toString()
									  )?.name
									: translate(
											dict =>
												dict.promsPatients.distributionList
													.autoGeneratedForm
									  )
							}
						>
							<Dropdown.Item onClick={() => updateAssignedForm(null)}>
								{translate(
									dict => dict.promsPatients.distributionList.autoGeneratedForm
								)}
							</Dropdown.Item>
							{forms.map(form => (
								<Dropdown.Item
									key={form.id}
									onClick={() => updateAssignedForm(form.id)}
								>
									{form.name}
								</Dropdown.Item>
							))}
						</Dropdown>
					</>
					{!isInitialDistributionCard && (
						<Button
							variant={v => v.link}
							title={translate(({ buttons }) => buttons.remove)}
							onClick={onDeleteDistribution}
							marginOffset={{ top: 4 }}
						/>
					)}
				</Body>
			)}
		</Container>
	);
}

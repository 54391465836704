import { useState, useMemo } from 'react';

import { Svgs, Dictionary } from 'environment';
import {
	Notification,
	NotificationBodyTypeLabel,
	NotificationBodyType,
	NotificationActionState,
	NotificationBody
} from 'store/data/notifications';

import {
	NotificationCardWrapper,
	NotificationContent,
	UnreadNotificationCircle
} from './NotificationCard.style';
import { NotificationAvatar } from './NotificationAvatar';
import { TimePassedCounter } from './TimePassedCounter';
import { NotificationButtons } from './NotificationButtons';
import { GenericMap } from 'types/index';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Translate } from 'components/UI/Translate';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import {
	useAcceptSubscriptionInvitationNotification,
	useRejectSubscriptionInvitationNotification,
	useSubscription,
	useTranslation
} from 'hooks/store';

interface NotificationCardProps {
	notification: Notification;
	onClick: () => void;
}

export function NotificationCard({ notification, onClick }: NotificationCardProps) {
	const [isHovered, setIsHovered] = useState(false);

	const { translate } = useTranslation();

	const { notificationId, body, isRead, type, timestamp, actionState } = notification;

	const [
		{
			data: {
				userTypesAndRoles: { isPaidSubscriptionOwner }
			}
		}
	] = useSubscription();

	const [{ loading: rejectSubscriptionInvitationLoading }, rejectSubscriptionInvitation] =
		useRejectSubscriptionInvitationNotification([notificationId]);

	const [{ loading: acceptSubscriptionInvitationLoading }, acceptSubscriptionInvitation] =
		useAcceptSubscriptionInvitationNotification([notificationId]);

	function onTurnOffClicked() {
		console.log('Turn off clicked');
	}

	function onRemoveClicked() {
		console.log('Remove clicked');
	}

	const buttonsOptions = useMemo(() => {
		if (actionState !== NotificationActionState.ActionRequired) return null;

		switch (type) {
			case NotificationBodyType.SubscriptionInvitation:
				return {
					primary: {
						label: translate(dict => dict.buttons.accept),
						loading: acceptSubscriptionInvitationLoading,
						onClick:
							acceptSubscriptionInvitationLoading ||
							rejectSubscriptionInvitationLoading
								? undefined
								: () => acceptSubscriptionInvitation(body.invitedUserId ?? '')
					},
					neutral: {
						label: translate(dict => dict.buttons.decline),
						loading: rejectSubscriptionInvitationLoading,
						onClick:
							acceptSubscriptionInvitationLoading ||
							rejectSubscriptionInvitationLoading
								? undefined
								: () => rejectSubscriptionInvitation(body.invitedUserId ?? '')
					},
					pending:
						acceptSubscriptionInvitationLoading || rejectSubscriptionInvitationLoading
							? {
									label: translate(dict => dict.buttons.pending)
							  }
							: undefined
				};

			default:
				return null;
		}
	}, [
		type,
		actionState,
		acceptSubscriptionInvitationLoading,
		rejectSubscriptionInvitationLoading
	]);

	function getDictLabel(dict: typeof Dictionary) {
		if (type === NotificationBodyType.SubscriptionInvitation) {
			if (actionState === NotificationActionState.ActionAccepted) {
				return isPaidSubscriptionOwner
					? dict.notifications.message.ownerSubscriptionInvitationAccepted
					: dict.notifications.message.userSubscriptionInvitationAccepted;
			} else if (actionState === NotificationActionState.ActionRejected) {
				return isPaidSubscriptionOwner
					? dict.notifications.message.ownerSubscriptionInvitationDeclined
					: dict.notifications.message.userSubscriptionInvitationDeclined;
			}
			if (isPaidSubscriptionOwner) {
				return dict.notifications.message.ownerSubscriptionInvitation;
			} else {
				return dict.notifications.message.userSubscriptionInvitation;
			}
		}

		if (type === NotificationBodyType.SubscriptionInvitationUserAnswer) {
			if (body.accepted)
				return dict.notifications.message.subscriptionInvitationRequestAcceptedByUser;
			else return dict.notifications.message.subscriptionInvitationRequestDeclinedByUser;
		}

		if (type === NotificationBodyType.SubscriptionInvitationOwnerAnswer) {
			if (body.accepted)
				return dict.notifications.message.subscriptionInvitationRequestAcceptedByOwner;
			else return dict.notifications.message.subscriptionInvitationRequestDeclinedByOwner;
		}

		if (type === NotificationBodyType.SubscriptionInvitationRemoved) {
			if (isPaidSubscriptionOwner)
				return dict.notifications.message.ownerSubscriptionInvitationCancelled;
			else return dict.notifications.message.userSubscriptionInvitationCancelled;
		}

		return NotificationBodyTypeLabel[type];
	}

	// extracts non undefined variables from notification body for translation options
	const translateOptions = useMemo(() => {
		const options: GenericMap<string | number> = {};
		Object.keys(body).forEach(bodyKey => {
			const bodyValue = body[bodyKey as keyof NotificationBody];
			if (bodyValue) {
				if (bodyKey === 'projectNames' && (bodyValue as Array<string>).length) {
					// create string of array strings
					const projectNames = bodyValue as Array<string>;
					options[bodyKey] = projectNames.slice(0, -1).join(', ');
					options.projectName = projectNames[projectNames.length - 1];
				} else options[bodyKey] = bodyValue.toString();
			}
		});

		return options;
	}, [body]);

	const avatarFirstName = useMemo(() => {
		if (type === NotificationBodyType.SubscriptionInvitationUserAnswer)
			return body.invitedFirstName;

		if (type === NotificationBodyType.SubscriptionInvitationOwnerAnswer)
			return body.ownerFirstName;

		return body.firstName;
	}, [type]);

	const avatarLastName = useMemo(() => {
		if (type === NotificationBodyType.SubscriptionInvitationUserAnswer)
			return body.invitedLastName;

		if (type === NotificationBodyType.SubscriptionInvitationOwnerAnswer)
			return body.ownerLastName;

		return body.lastName;
	}, [type]);

	return (
		<NotificationCardWrapper
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={onClick}
			style={{ cursor: 'pointer' }}
		>
			{!isRead && <UnreadNotificationCircle data-testid={'unreadNotificationCircle'} />}
			<Flex>
				<div>
					<NotificationAvatar
						firstName={avatarFirstName}
						lastName={avatarLastName}
						userId={avatarFirstName + '-' + avatarLastName}
					/>
				</div>
				<NotificationContent>
					<Typography.Notification data-testid="notification-text">
						<Translate transKey={dict => getDictLabel(dict)} options={translateOptions}>
							{Object.keys(translateOptions).map(key => (
								<b key={key + translateOptions[key]}>{translateOptions[key]}</b>
							))}
						</Translate>
					</Typography.Notification>

					<TimePassedCounter date={timestamp} />
					{buttonsOptions && (
						<NotificationButtons
							primary={buttonsOptions.primary}
							neutral={buttonsOptions.neutral}
							pending={buttonsOptions.pending}
						/>
					)}
				</NotificationContent>
				{
					// The Dropdown is not visible for now on notification cards.
					// This might be changed in the future to allow certain actions on the notification
					// In order to change it, make the dropdown visible on hover.
				}
				<div style={{ visibility: isHovered ? 'hidden' : 'hidden' }}>
					<Dropdown
						toggleComponent={({ ref, open, toggle }) => (
							<Icon
								ref={ref}
								svg={Svgs.More}
								active={open}
								size={s => s.m}
								variant={v => v.button}
								onClick={toggle}
							/>
						)}
						width={12}
						offset={{ top: 20, right: 0 }}
					>
						<Dropdown.Item
							title={translate(dict => dict.notifications.cardButtons.remove)}
							onClick={onRemoveClicked}
						/>

						<Dropdown.Item
							title={translate(dict => dict.notifications.cardButtons.turnOff)}
							onClick={onTurnOffClicked}
						/>
					</Dropdown>
				</div>
			</Flex>
		</NotificationCardWrapper>
	);
}

import { useOrganizationsByCollaborator, useUsername } from 'hooks/store';
import { useMemo } from 'react';
import { useAssignedOrganizationId } from './useAssignedOrganizationId';

const NO_ORGANIZATION_ID = '-1';

export function useUserOrganizationId() {
	const username = useUsername();
	const userOrganizations = useOrganizationsByCollaborator(username ?? undefined);

	const [assignedOrganizationId] = useAssignedOrganizationId();

	const userOrganizationId = useMemo(() => {
		let userOrganizationId = NO_ORGANIZATION_ID; // NO GROUPS BASE CASE
		if (userOrganizations.length === 1) {
			userOrganizationId = userOrganizations[0].id;
		}
		if (userOrganizations.length > 1) {
			userOrganizationId = assignedOrganizationId as string;
		}

		return userOrganizationId;
	}, [userOrganizations, assignedOrganizationId]);

	return userOrganizationId;
}

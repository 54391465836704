import { useSelector } from 'hooks/utils';
import { selectProjectPermissions } from 'store/data/projects';
import { StatusPermissions } from 'store/data/collaborators';
import { GenericMap } from 'types/index';
import { useMemo } from 'react';

export function useStatusPermissions() {
	const permissions = useSelector(state =>
		selectProjectPermissions(state.data.projects, undefined)
	);

	const statusPermissionsMap = useMemo(() => {
		const map: GenericMap<StatusPermissions> = {};

		permissions?.statusTypeAccesses?.forEach(({ statusTypeVariableName, ...statusAccess }) => {
			map[statusTypeVariableName] = statusAccess;
		});

		return map;
	}, [permissions]);

	return { statusPermissionsMap };
}

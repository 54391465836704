import styled, { css } from 'styled-components';

const isOnTouchDevice = 'ontouchstart' in document.documentElement;

type MoreIconContainerProps = {
	visible: boolean;
};

export const MoreIconContainer = styled.div<MoreIconContainerProps>`
	${isOnTouchDevice &&
	css`
		visibility: visible;
	`}
`;

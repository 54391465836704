import { useDispatch, useSelector } from 'hooks/utils';
import {
	setProjectsTableVisibleColumns,
	selectProjectsTableVisibleColumns
} from 'store/data/projects';
import { LocalOperationResult } from 'hooks/store/types';

type HandlerInput = Parameters<typeof setProjectsTableVisibleColumns>[0]['columnNames'];

export function useProjectsTableVisibleColumns(): LocalOperationResult<
	string[],
	(columnNames: HandlerInput) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectProjectsTableVisibleColumns(state.data.projects));

	function handler(columnNames: HandlerInput) {
		dispatch(setProjectsTableVisibleColumns({ columnNames }));
	}

	return [data, handler];
}

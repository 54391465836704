import { VariableSet } from 'api/data/variables';
import { useVariables } from 'hooks/store';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export function useVariableSetDrawer() {
	const [variableSet, setVariableSet] = useState<VariableSet | null>(null);
	const [
		{
			data: { variableSetsMap: currentVariableSetsMap }
		}
	] = useVariables({ lazy: true });

	// SYNC VARIABLE SET DRAWER / CLOSE IT IF VARIABLE SET WAS DELETED
	useEffect(() => {
		if (!variableSet) return;

		const variableSetExists = variableSet.setName in currentVariableSetsMap;

		if (!variableSetExists) return closeVariableSetDrawer();

		const variableSetChanged = !isEqual(
			variableSet,
			currentVariableSetsMap[variableSet.setName]
		);

		if (variableSetChanged) openVariableSetDrawer(variableSet.setName);
	}, [currentVariableSetsMap]);

	const openVariableSetDrawer = useCallback(
		(variableSet: string) => {
			if (currentVariableSetsMap[variableSet]) {
				setVariableSet(currentVariableSetsMap[variableSet]);
			}
		},
		[currentVariableSetsMap]
	);

	const closeVariableSetDrawer = useCallback(() => {
		setVariableSet(null);
	}, []);

	return {
		variableSet,
		openVariableSetDrawer,
		closeVariableSetDrawer
	};
}

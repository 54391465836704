import { ExploreResults, VariableStatistics } from 'api/data/analyses';
import { decodeURIComponentSafe } from 'helpers/generic';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { StringMap } from 'types/index';
import { computeCellValueForExport } from './computeData';
import { TimeDurationFormat } from 'api/data/variables';
import {
	getMicrosecondsFromTimeDurationString,
	getTimeDurationFormatForAnalysis,
	getTimeDurationStringFromMicroseconds
} from 'helpers/entries';
import { VariableSetsMap, VariablesMap } from 'store/data/variables';
import { buildAggregationRuleNameToAggregatorVariableMap } from 'helpers/variables';
import { VariableType } from 'types/data/variables/constants';

interface TableRows {
	nrOfRows: string[];
	average: string[];
	std: string[];
	confidentLower: string[];
	confidentUpper: string[];
	sem: string[];
	variance: string[];
	skewness: string[];
	kurtosis: string[];
	median: string[];
	min: string[];
	max: string[];
	range: string[];
	IQRLower: string[];
	IQRUpper: string[];
	missing: string[];
}

export function exportExplore(
	dataset: ExploreResults,
	{
		variablesMap,
		variableSetsMap
	}: {
		variablesMap: VariablesMap;
		variableSetsMap: VariableSetsMap;
	},
	{ translate, columnLabelsMap }: { translate: TranslateFunction; columnLabelsMap: StringMap },
	{
		parseTimeDurationEntryCell
	}: {
		parseTimeDurationEntryCell: (
			value: string | undefined,
			format: TimeDurationFormat
		) => string;
	}
) {
	const aggregationRuleToVariableNameMap =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);
	const leftRows: string[] = [
		translate(({ analysis }) => analysis.generic.n),
		translate(({ analysis }) => analysis.generic.mean),
		translate(({ analysis }) => analysis.generic.sd),
		translate(({ analysis }) => analysis.generic.confidenceLower),
		translate(({ analysis }) => analysis.generic.confidenceUpper),
		translate(({ analysis }) => analysis.generic.sem),
		translate(({ analysis }) => analysis.generic.variance),
		translate(({ analysis }) => analysis.generic.skewness),
		translate(({ analysis }) => analysis.generic.kurtosis),
		translate(({ analysis }) => analysis.generic.median),
		translate(({ analysis }) => analysis.generic.min),
		translate(({ analysis }) => analysis.generic.max),
		translate(({ analysis }) => analysis.generic.range),
		translate(({ analysis }) => analysis.generic.IQRLower),
		translate(({ analysis }) => analysis.generic.IQRUpper),
		translate(({ analysis }) => analysis.generic.missing)
	];

	const columns: string[] = [];
	const rows: TableRows = {
		nrOfRows: [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	dataset.forEach(addRow);

	function addRow(row: VariableStatistics) {
		const variable =
			variablesMap[row.variableName] ??
			variablesMap[
				aggregationRuleToVariableNameMap[row.variableName].aggregator.variableName
			];
		const isTimeDuration = variable?.type === VariableType.TimeDuration;
		const format = variable?.durationFormat;

		columns.push(decodeURIComponentSafe(columnLabelsMap[row.variableName]));

		rows.nrOfRows.push(computeCellValueForExport(row['nr of rows'], { noDecimals: true }));
		rows.average.push(
			computeCellValueForExport(row.average, { noDecimals: false, duration: isTimeDuration })
		);
		rows.std.push(
			computeCellValueForExport(row.std, { noDecimals: false, duration: isTimeDuration })
		);
		rows.confidentLower.push(
			computeCellValueForExport(row.confidentLower, {
				noDecimals: false,
				duration: isTimeDuration
			})
		);
		rows.confidentUpper.push(
			computeCellValueForExport(row.confidentUpper, {
				noDecimals: false,
				duration: isTimeDuration
			})
		);
		rows.sem.push(
			computeCellValueForExport(row.sem, { noDecimals: false, duration: isTimeDuration })
		);
		rows.variance.push(
			computeCellValueForExport(row.variance, { noDecimals: false, duration: isTimeDuration })
		);
		rows.skewness.push(computeCellValueForExport(row.skewness));
		rows.kurtosis.push(computeCellValueForExport(row.kurtosis));
		rows.median.push(
			computeCellValueForExport(row.median, { noDecimals: false, duration: isTimeDuration })
		);
		rows.min.push(
			computeCellValueForExport(row.min, { noDecimals: false, duration: isTimeDuration })
		);
		rows.max.push(
			computeCellValueForExport(row.max, { noDecimals: false, duration: isTimeDuration })
		);
		if (isTimeDuration && format) {
			const hasValue = row.min
				.replaceAll(/[a-zA-Z]/g, '')
				.split(':')
				.some(number => !isNaN(Number(number)));
			if (!hasValue) {
				rows.range.push(computeCellValueForExport(row.min));
			} else {
				const analysisFormat = getTimeDurationFormatForAnalysis(format);
				const max = getMicrosecondsFromTimeDurationString(row.max, format);
				const min = getMicrosecondsFromTimeDurationString(row.min, format);
				const newValue = getTimeDurationStringFromMicroseconds(max - min, format) ?? '';
				rows.range.push(
					computeCellValueForExport(
						parseTimeDurationEntryCell(newValue, analysisFormat),
						{
							noDecimals: false,
							duration: isTimeDuration
						}
					)
				);
			}
		} else {
			rows.range.push(
				computeCellValueForExport((Number(row.max) - Number(row.min)).toString())
			);
		}
		rows.IQRLower.push(
			computeCellValueForExport(row.IQRLower, { noDecimals: false, duration: isTimeDuration })
		);
		rows.IQRUpper.push(
			computeCellValueForExport(row.IQRUpper, { noDecimals: false, duration: isTimeDuration })
		);
		rows.missing.push(computeCellValueForExport(row.missing, { noDecimals: true }));
	}

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';

	// LEFT-TOP CORNER SPACER
	csv += comma;
	// ADD COLUMNS
	csv += columns.join(comma) + newLine;

	Object.keys(rows).forEach((key, index) => {
		const row = rows[key as keyof TableRows];

		const leftRowColumn = leftRows[index];

		row.unshift(leftRowColumn);

		csv += row.join(comma) + newLine;
	});

	return csv;
}

import { DATA_URL, sendRequest, STATISTICS_URL } from 'api/utils';
import { Dictionary } from 'environment';
import {
	parseApiGroup,
	parseApiVariable,
	parseApiVariablesData,
	parseApiVariableSet
} from 'helpers/variables';

import {
	/**
	 * ===================
	 * 	CRUD - VARIABLES
	 * ===================
	 */

	// GET ONE
	GetVariableInput,
	GetVariableRequest,
	GetVariableResponse,
	GetVariableOutput,
	// GET ALL
	GetVariablesInput,
	GetVariablesRequest,
	GetVariablesResponse,
	GetVariablesOutput,
	// CREATE
	CreateVariableInput,
	CreateVariableRequest,
	CreateVariableResponse,
	CreateVariableOutput,
	// UPDATE
	UpdateVariableInput,
	UpdateVariableRequest,
	UpdateVariableResponse,
	UpdateVariableOutput,
	// DELETE
	DeleteVariableInput,
	DeleteVariableRequest,
	DeleteVariableResponse,
	DeleteVariableOutput,

	/**
	 * ================
	 * 	CRUD - GROUPS
	 * ================
	 */

	// CREATE
	CreateGroupInput,
	CreateGroupRequest,
	CreateGroupResponse,
	CreateGroupOutput,
	// UPDATE
	UpdateGroupInput,
	UpdateGroupRequest,
	UpdateGroupResponse,
	UpdateGroupOutput,
	// DELETE
	DeleteGroupInput,
	DeleteGroupRequest,
	DeleteGroupResponse,

	/**
	 * =======================
	 * 	CRUD - VARIABLE SETS
	 * =======================
	 */

	// CREATE
	CreateVariableSetInput,
	CreateVariableSetRequest,
	CreateVariableSetResponse,
	CreateVariableSetOutput,
	// UPDATE
	UpdateVariableSetInput,
	UpdateVariableSetRequest,
	UpdateVariableSetResponse,
	UpdateVariableSetOutput,
	// DELETE
	DeleteVariableSetInput,
	DeleteVariableSetRequest,
	DeleteVariableSetResponse,

	/**
	 * ==========================
	 * 	CRUD - AGGREGATION RULES
	 * ==========================
	 */

	// CREATE
	CreateVariableSetAggregationRuleInput,
	CreateVariableSetAggregationRuleRequest,
	CreateVariableSetAggregationRuleResponse,
	CreateVariableSetAggregationRuleOutput,
	// UPDATE
	UpdateVariableSetAggregationRuleInput,
	UpdateVariableSetAggregationRuleRequest,
	UpdateVariableSetAggregationRuleResponse,
	UpdateVariableSetAggregationRuleOutput,
	// DELETE
	DeleteVariableSetAggregationRuleInput,
	DeleteVariableSetAggregationRuleRequest,
	DeleteVariableSetAggregationRuleResponse,
	DeleteVariableSetAggregationRuleOutput,
	/**
	 * =========================================
	 * 	ACTIONS: MOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
	 * 	TARGET: ROOT LIST
	 * =========================================
	 */

	// MOVE VARIABLE
	MoveVariableInput,
	MoveVariableRequest,
	MoveVariableResponse,
	// MOVE GROUP
	MoveGroupInput,
	MoveGroupRequest,
	MoveGroupResponse,
	// MOVE VARIABLE SET
	MoveVariableSetInput,
	MoveVariableSetRequest,
	MoveVariableSetResponse,
	// MOVE AGGREGATION RULE
	MoveVariableSetAggregationRuleInput,
	MoveVariableSetAggregationRuleRequest,
	MoveVariableSetAggregationRuleResponse,

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S)
	 * 	TARGET: FROM/TO GROUP(S)
	 * ==========================================
	 */

	// ADD VARIABLE TO GROUP
	AddVariableToGroupInput,
	AddVariableToGroupRequest,
	AddVariableToGroupResponse,
	// ADD VARIABLES TO GROUP
	AddVariablesToGroupInput,
	AddVariablesToGroupRequest,
	AddVariablesToGroupResponse,
	// REMOVE VARIABLE FROM GROUP
	RemoveVariableFromGroupInput,
	RemoveVariableFromGroupRequest,
	RemoveVariableFromGroupResponse,
	// REMOVE VARIABLES FROM GROUP
	RemoveVariablesFromGroupInput,
	RemoveVariablesFromGroupRequest,
	RemoveVariablesFromGroupResponse,
	// MOVE VARIABLE INSIDE GROUP
	MoveVariableInsideGroupInput,
	MoveVariableInsideGroupRequest,
	MoveVariableInsideGroupResponse,
	// MOVE VARIABLE BETWEEN GROUPS
	MoveVariableBetweenGroupsInput,
	MoveVariableBetweenGroupsRequest,
	MoveVariableBetweenGroupsResponse,
	// MOVE VARIABLES BETWEEN GROUPS
	MoveVariablesBetweenGroupsInput,
	MoveVariablesBetweenGroupsRequest,
	MoveVariablesBetweenGroupsResponse,

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S)
	 * 	TARGET: FROM/TO SET(S)
	 * ==========================================
	 */

	// ADD VARIABLE TO SET
	AddVariableToSetInput,
	AddVariableToSetRequest,
	AddVariableToSetResponse,
	AddVariableToSetOutput,
	AddVariableGroupToSetOutput,
	// REMOVE VARIABLE FROM SET
	RemoveVariableFromSetInput,
	RemoveVariableFromSetRequest,
	RemoveVariableFromSetResponse,
	RemoveVariableFromSetOutput,
	// ADD VARIABLE GROUP TO SET
	AddVariableGroupToSetInput,
	AddVariableGroupToSetRequest,
	AddVariableGroupToSetResponse,
	// REMOVE VARIABLE GROUP FROM SET
	RemoveVariableGroupFromSetInput,
	RemoveVariableGroupFromSetRequest,
	RemoveVariableGroupFromSetResponse,
	RemoveVariableGroupFromSetOutput,
	// MOVE VARIABLE BETWEEN SETS
	MoveVariableBetweenSetsInput,
	MoveVariableBetweenSetsRequest,
	MoveVariableBetweenSetsResponse,
	MoveVariableBetweenSetsOutput,
	// MOVE VARIABLE GROUP BETWEEN SETS
	MoveVariableGroupBetweenSetsInput,
	MoveVariableGroupBetweenSetsRequest,
	MoveVariableGroupBetweenSetsResponse,
	MoveVariableGroupBetweenSetsOutput,

	/**
	 * ================
	 * 	BULK DELETE
	 * ================
	 */

	//  DELETE BULK VARIABLES OR GROUPS
	DeleteBulkVariablesDataInput,
	DeleteBulkVariablesDataRequest,
	DeleteBulkVariablesDataResponse,
	DeleteBulkVariablesDataOutput,

	/**
	 * ===================
	 * 	CRUD - CATEGORIES
	 * ===================
	 */

	// CREATE CATEGORY VALUE
	CreateVariableCategoryValueInput,
	CreateVariableCategoryValueRequest,
	CreateVariableCategoryValueResponse,
	CreateVariableCategoryValueOutput,
	// CREATE CATEGORY VALUES
	CreateVariableCategoryValuesInput,
	CreateVariableCategoryValuesRequest,
	CreateVariableCategoryValuesResponse,
	CreateVariableCategoryValuesOutput,
	// UPDATE CATEGORY VALUE
	UpdateVariableCategoryValueInput,
	UpdateVariableCategoryValueRequest,
	UpdateVariableCategoryValueResponse,
	UpdateVariableCategoryValueOutput,
	// DELETE CATEGORY VALUE
	DeleteVariableCategoryValueInput,
	DeleteVariableCategoryValueRequest,
	DeleteVariableCategoryValueResponse,
	DeleteVariableCategoryValueOutput,
	// DELETE CATEGORY VALUES
	DeleteVariableCategoryValuesInput,
	DeleteVariableCategoryValuesRequest,
	DeleteVariableCategoryValuesResponse,
	DeleteVariableCategoryValuesOutput,
	// MOVE CATEGORY VALUE
	MoveVariableCategoryValueInput,
	MoveVariableCategoryValueRequest,
	MoveVariableCategoryValueResponse,
	MoveVariableCategoryValueOutput,
	// MOVE CATEGORY VALUES
	MoveVariableCategoryValuesInput,
	MoveVariableCategoryValuesRequest,
	MoveVariableCategoryValuesResponse,
	MoveVariableCategoryValuesOutput,
	// MERGE CATEGORY VALUES
	MergeVariableCategoryValuesInput,
	MergeVariableCategoryValuesRequest,
	MergeVariableCategoryValuesResponse,
	MergeVariableCategoryValuesOutput,

	/**
	 * ========
	 * 	OTHERS
	 * ========
	 */

	// CHECK VARIABLE TYPE CHANGE
	CheckVariableTypeChangeInput,
	CheckVariableTypeChangeRequest,
	CheckVariableTypeChangeResponse,
	CheckVariableTypeChangeOutput
} from './types';

export const methods = {
	checkTypeChange: 'checkIfTransformationIsOk',

	/**
	 * ===================
	 * 	CRUD - VARIABLES
	 * ===================
	 */
	getVariable: 'getVariable',
	getVariables: 'getVariablesData',
	createVariable: 'createVariable',
	updateVariable: 'updateVariable',
	deleteVariable: 'deleteVariable',

	/**
	 * ================
	 * 	CRUD - GROUPS
	 * ================
	 */
	createGroup: 'createVariableGroup',
	updateGroup: 'updateVariableGroup',
	deleteGroup: 'deleteVariableGroup',

	/**
	 * =======================
	 * 	CRUD - VARIABLE SETS
	 * =======================
	 */
	createVariableSet: 'createVariableSet',
	updateVariableSet: 'updateVariableSet',
	deleteVariableSet: 'deleteVariableSet',

	/**
	 * ==========================
	 * 	CRUD - AGGREGATION RULES
	 * ==========================
	 */
	createVariableSetAggregationRule: 'createVariableSetAggregationRule',
	updateVariableSetAggregationRule: 'updateVariableSetAggregationRule',
	deleteVariableSetAggregationRule: 'deleteVariableSetAggregationRule',

	/**
	 * =========================================
	 * 	ACTIONS: MOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
	 * 	TARGET: ROOT LIST
	 * =========================================
	 */
	moveVariable: 'moveVariable',
	moveGroup: 'moveVariableGroup',
	moveVariableSet: 'moveVariableSet',
	moveVariableSetAggregationRule: 'moveVariableSetAggregationRule',

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S)
	 * 	TARGET: FROM/TO GROUP(S)
	 * ==========================================
	 */
	addVariableToGroup: 'addVariableToGroup',
	addVariablesToGroup: 'addVariablesToGroup',
	removeVariableFromGroup: 'removeVariableFromGroup',
	removeVariablesFromGroup: 'removeVariablesFromGroup',
	moveVariableInsideGroup: 'moveVariableInsideGroup',
	moveVariableBetweenGroups: 'moveVariableBetweenGroups',
	moveVariablesBetweenGroups: 'moveVariablesBetweenGroups',

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S)
	 * 	TARGET: FROM/TO SET(S)
	 * ==========================================
	 */
	addVariableToSet: 'addVariableToSet',
	removeVariableFromSet: 'removeVariableFromSet',
	addVariableGroupToSet: 'addVariableGroupToSet',
	removeVariableGroupFromSet: 'removeVariableGroupFromSet',
	moveVariableBetweenSets: 'moveVariableBetweenSets',
	moveVariableGroupBetweenSets: 'moveVariableGroupBetweenSets',

	/**
	 * ================
	 * 	BULK DELETE
	 * ================
	 */
	deleteBulkVariablesData: 'deleteBulkVariablesOrGroups',

	/**
	 * ===================
	 * 	CRUD - CATEGORIES
	 * ===================
	 */
	createVariableCategoryValue: 'createVariableCategoryValue',
	createVariableCategoryValues: 'createVariableCategoryValues',
	updateVariableCategoryValue: 'updateVariableCategoryValue',
	deleteVariableCategoryValue: 'deleteVariableCategoryValue',
	deleteVariableCategoryValues: 'deleteVariableCategoryValues',
	moveVariableCategoryValue: 'moveVariableCategoryValue',
	moveVariableCategoryValues: 'moveVariableCategoryValues',
	mergeVariableCategoryValues: 'mergeVariableCategoryValues'
	/**
	 * ===================
	 * 	CRUD - CATEGORIES
	 * ===================
	 */
};

export default () => ({
	async checkVariableTypeChange(
		input: CheckVariableTypeChangeInput
	): Promise<CheckVariableTypeChangeOutput> {
		const { data } = await sendRequest<
			CheckVariableTypeChangeRequest,
			CheckVariableTypeChangeResponse
		>(DATA_URL, {
			method: methods.checkTypeChange,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.variables.couldNotCheckVariableTypeChange);
		}

		if (!data.transformationOk) {
			throw new Error(Dictionary.variables.changeTypeNotAllowed);
		}

		return true;
	},

	/**
	 * ===================
	 * 	CRUD - VARIABLES
	 * ===================
	 */

	async getVariable(input: GetVariableInput): Promise<GetVariableOutput> {
		const { data } = await sendRequest<GetVariableRequest, GetVariableResponse>(DATA_URL, {
			method: methods.getVariable,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: GetVariableOutput = {
			variable
		};

		return output;
	},

	async getVariables(input: GetVariablesInput): Promise<GetVariablesOutput> {
		const { data } = await sendRequest<GetVariablesRequest, GetVariablesResponse>(
			STATISTICS_URL,
			{
				method: methods.getVariables,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.variables.couldNotLoadVariables);
		}

		const variablesData = parseApiVariablesData(data);

		const output: GetVariablesOutput = { variablesData };

		return output;
	},

	async createVariable(input: CreateVariableInput): Promise<CreateVariableOutput> {
		const { data } = await sendRequest<CreateVariableRequest, CreateVariableResponse>(
			DATA_URL,
			{
				method: methods.createVariable,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: CreateVariableOutput = {
			variable,
			transactionId: data.transactionId
		};

		return output;
	},

	async updateVariable(
		input: UpdateVariableInput,
		callbacks?: {
			onPatchDependencies?(): void;
		}
	): Promise<UpdateVariableOutput> {
		const { data } = await sendRequest<UpdateVariableRequest, UpdateVariableResponse>(
			DATA_URL,
			{
				method: methods.updateVariable,
				...input
			}
		);

		if (data.ledidiStatusCode === 'error.dependenciesViolation') {
			callbacks?.onPatchDependencies?.();
			throw new Error();
		}

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: UpdateVariableOutput = {
			variable,
			transactionId: data.transactionId
		};

		return output;
	},

	async deleteVariable(input: DeleteVariableInput): Promise<DeleteVariableOutput> {
		const { data } = await sendRequest<DeleteVariableRequest, DeleteVariableResponse>(
			DATA_URL,
			{
				method: methods.deleteVariable,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const output: DeleteVariableOutput = {
			transactionId: data.transactionId
		};

		return output;
	},

	/**
	 * ================
	 * 	CRUD - GROUPS
	 * ================
	 */

	async createGroup(input: CreateGroupInput): Promise<CreateGroupOutput> {
		const { data } = await sendRequest<CreateGroupRequest, CreateGroupResponse>(DATA_URL, {
			method: methods.createGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const group = parseApiGroup(data.group);

		const output: CreateGroupOutput = { group };

		return output;
	},

	async updateGroup(input: UpdateGroupInput): Promise<UpdateGroupOutput> {
		const { data } = await sendRequest<UpdateGroupRequest, UpdateGroupResponse>(DATA_URL, {
			method: methods.updateGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const group = parseApiGroup(data.group);

		const output: UpdateGroupOutput = { group };

		return output;
	},

	async deleteGroup(input: DeleteGroupInput): Promise<void> {
		const { data } = await sendRequest<DeleteGroupRequest, DeleteGroupResponse>(DATA_URL, {
			method: methods.deleteGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	/**
	 * =======================
	 * 	CRUD - VARIABLE SETS
	 * =======================
	 */

	async createVariableSet(input: CreateVariableSetInput): Promise<CreateVariableSetOutput> {
		const { data } = await sendRequest<CreateVariableSetRequest, CreateVariableSetResponse>(
			DATA_URL,
			{
				method: methods.createVariableSet,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variableSet = parseApiVariableSet(data.set);

		const output: CreateVariableSetOutput = { variableSet };

		return output;
	},

	async updateVariableSet(input: UpdateVariableSetInput): Promise<UpdateVariableSetOutput> {
		const { data } = await sendRequest<UpdateVariableSetRequest, UpdateVariableSetResponse>(
			DATA_URL,
			{
				method: methods.updateVariableSet,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variableSet = parseApiVariableSet(data.set);

		const output: UpdateVariableSetOutput = {
			variableSet,
			transactionId: data.transactionId
		};

		return output;
	},

	async deleteVariableSet(input: DeleteVariableSetInput): Promise<void> {
		const { data } = await sendRequest<DeleteVariableSetRequest, DeleteVariableSetResponse>(
			DATA_URL,
			{
				method: methods.deleteVariableSet,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	/**
	 * ==========================
	 * 	CRUD - AGGREGATION RULES
	 * ==========================
	 */

	async createVariableSetAggregationRule(
		input: CreateVariableSetAggregationRuleInput
	): Promise<CreateVariableSetAggregationRuleOutput> {
		const { data } = await sendRequest<
			CreateVariableSetAggregationRuleRequest,
			CreateVariableSetAggregationRuleResponse
		>(DATA_URL, {
			method: methods.createVariableSetAggregationRule,
			...input
		});

		if (data.httpStatusCode !== 200) throw new Error();

		const variableSet = parseApiVariableSet(data.set);

		const output: CreateVariableSetAggregationRuleOutput = {
			variableSet,
			transactionId: data.transactionId
		};

		return output;
	},

	async updateVariableSetAggregationRule(
		input: UpdateVariableSetAggregationRuleInput
	): Promise<UpdateVariableSetAggregationRuleOutput> {
		const { data } = await sendRequest<
			UpdateVariableSetAggregationRuleRequest,
			UpdateVariableSetAggregationRuleResponse
		>(DATA_URL, {
			method: methods.updateVariableSetAggregationRule,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variableSet = parseApiVariableSet(data.set);

		const output: UpdateVariableSetAggregationRuleOutput = {
			variableSet,
			transactionId: data.transactionId
		};

		return output;
	},

	async deleteVariableSetAggregationRule(
		input: DeleteVariableSetAggregationRuleInput
	): Promise<DeleteVariableSetAggregationRuleOutput> {
		const { data } = await sendRequest<
			DeleteVariableSetAggregationRuleRequest,
			DeleteVariableSetAggregationRuleResponse
		>(DATA_URL, {
			method: methods.deleteVariableSetAggregationRule,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	/**
	 * =========================================
	 * 	ACTIONS: MOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
	 * 	TARGET: ROOT LIST
	 * =========================================
	 */

	async moveVariable(input: MoveVariableInput): Promise<void> {
		const { data } = await sendRequest<MoveVariableRequest, MoveVariableResponse>(DATA_URL, {
			method: methods.moveVariable,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async moveGroup(input: MoveGroupInput): Promise<void> {
		const { data } = await sendRequest<MoveGroupRequest, MoveGroupResponse>(DATA_URL, {
			method: methods.moveGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async moveVariableSet(input: MoveVariableSetInput): Promise<void> {
		const { data } = await sendRequest<MoveVariableSetRequest, MoveVariableSetResponse>(
			DATA_URL,
			{
				method: methods.moveVariableSet,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async moveVariableSetAggregationRule(
		input: MoveVariableSetAggregationRuleInput
	): Promise<void> {
		const { data } = await sendRequest<
			MoveVariableSetAggregationRuleRequest,
			MoveVariableSetAggregationRuleResponse
		>(DATA_URL, {
			method: methods.moveVariableSetAggregationRule,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S)
	 * 	TARGET: FROM/TO GROUP(S)
	 * ==========================================
	 */

	async addVariableToGroup(input: AddVariableToGroupInput): Promise<void> {
		const { data } = await sendRequest<AddVariableToGroupRequest, AddVariableToGroupResponse>(
			DATA_URL,
			{
				method: methods.addVariableToGroup,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async addVariablesToGroup(input: AddVariablesToGroupInput): Promise<void> {
		const { data } = await sendRequest<AddVariablesToGroupRequest, AddVariablesToGroupResponse>(
			DATA_URL,
			{
				method: methods.addVariablesToGroup,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async removeVariableFromGroup(input: RemoveVariableFromGroupInput): Promise<void> {
		const { data } = await sendRequest<
			RemoveVariableFromGroupRequest,
			RemoveVariableFromGroupResponse
		>(DATA_URL, {
			method: methods.removeVariableFromGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async removeVariablesFromGroup(input: RemoveVariablesFromGroupInput): Promise<void> {
		const { data } = await sendRequest<
			RemoveVariablesFromGroupRequest,
			RemoveVariablesFromGroupResponse
		>(DATA_URL, {
			method: methods.removeVariablesFromGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async moveVariableInsideGroup(input: MoveVariableInsideGroupInput): Promise<void> {
		const { data } = await sendRequest<
			MoveVariableInsideGroupRequest,
			MoveVariableInsideGroupResponse
		>(DATA_URL, {
			method: methods.moveVariableInsideGroup,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async moveVariableBetweenGroups(input: MoveVariableBetweenGroupsInput): Promise<void> {
		const { data } = await sendRequest<
			MoveVariableBetweenGroupsRequest,
			MoveVariableBetweenGroupsResponse
		>(DATA_URL, {
			method: methods.moveVariableBetweenGroups,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async moveVariablesBetweenGroups(input: MoveVariablesBetweenGroupsInput): Promise<void> {
		const { data } = await sendRequest<
			MoveVariablesBetweenGroupsRequest,
			MoveVariablesBetweenGroupsResponse
		>(DATA_URL, {
			method: methods.moveVariablesBetweenGroups,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	/**
	 * ==========================================
	 * 	ACTIONS: ADD/MOVE/REMOVE
	 * 	SOURCE: VARIABLE(S) / GROUP(S)
	 * 	TARGET: FROM/TO SET(S)
	 * ==========================================
	 */

	async addVariableToSet(input: AddVariableToSetInput): Promise<AddVariableToSetOutput> {
		const { data } = await sendRequest<AddVariableToSetRequest, AddVariableToSetResponse>(
			DATA_URL,
			{
				method: methods.addVariableToSet,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error(data.message ?? '');
		}

		return { transactionId: data.transactionId };
	},

	async removeVariableFromSet(
		input: RemoveVariableFromSetInput
	): Promise<RemoveVariableFromSetOutput> {
		const { data } = await sendRequest<
			RemoveVariableFromSetRequest,
			RemoveVariableFromSetResponse
		>(DATA_URL, {
			method: methods.removeVariableFromSet,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return { transactionId: data.transactionId };
	},

	async addVariableGroupToSet(
		input: AddVariableGroupToSetInput
	): Promise<AddVariableGroupToSetOutput> {
		const { data } = await sendRequest<
			AddVariableGroupToSetRequest,
			AddVariableGroupToSetResponse
		>(DATA_URL, {
			method: methods.addVariableGroupToSet,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return { transactionId: data.transactionId };
	},

	async removeVariableGroupFromSet(
		input: RemoveVariableGroupFromSetInput
	): Promise<RemoveVariableGroupFromSetOutput> {
		const { data } = await sendRequest<
			RemoveVariableGroupFromSetRequest,
			RemoveVariableGroupFromSetResponse
		>(DATA_URL, {
			method: methods.removeVariableGroupFromSet,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return { transactionId: data.transactionId };
	},

	async moveVariableBetweenSets(
		input: MoveVariableBetweenSetsInput
	): Promise<MoveVariableBetweenSetsOutput> {
		const { data } = await sendRequest<
			MoveVariableBetweenSetsRequest,
			MoveVariableBetweenSetsResponse
		>(DATA_URL, {
			method: methods.moveVariableBetweenSets,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return { transactionId: data.transactionId };
	},

	async moveVariableGroupBetweenSets(
		input: MoveVariableGroupBetweenSetsInput
	): Promise<MoveVariableGroupBetweenSetsOutput> {
		const { data } = await sendRequest<
			MoveVariableGroupBetweenSetsRequest,
			MoveVariableGroupBetweenSetsResponse
		>(DATA_URL, {
			method: methods.moveVariableGroupBetweenSets,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return { transactionId: data.transactionId };
	},

	/**
	 * ================
	 * 	BULK DELETE
	 * ================
	 */

	async deleteBulkVariablesData(
		input: DeleteBulkVariablesDataInput
	): Promise<DeleteBulkVariablesDataOutput> {
		const { data } = await sendRequest<
			DeleteBulkVariablesDataRequest,
			DeleteBulkVariablesDataResponse
		>(DATA_URL, {
			method: methods.deleteBulkVariablesData,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const output: DeleteBulkVariablesDataOutput = {
			transactionId: data.transactionId
		};

		return output;
	},

	/**
	 * ===================
	 * 	CRUD - CATEGORIES
	 * ===================
	 */

	async createVariableCategoryValue(
		input: CreateVariableCategoryValueInput
	): Promise<CreateVariableCategoryValueOutput> {
		const { data } = await sendRequest<
			CreateVariableCategoryValueRequest,
			CreateVariableCategoryValueResponse
		>(DATA_URL, {
			method: methods.createVariableCategoryValue,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: CreateVariableCategoryValueOutput = {
			variable
		};

		return output;
	},

	async createVariableCategoryValues(
		input: CreateVariableCategoryValuesInput
	): Promise<CreateVariableCategoryValuesOutput> {
		const { data } = await sendRequest<
			CreateVariableCategoryValuesRequest,
			CreateVariableCategoryValuesResponse
		>(DATA_URL, {
			method: methods.createVariableCategoryValues,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: CreateVariableCategoryValuesOutput = {
			variable
		};

		return output;
	},

	async updateVariableCategoryValue(
		input: UpdateVariableCategoryValueInput
	): Promise<UpdateVariableCategoryValueOutput> {
		const { data } = await sendRequest<
			UpdateVariableCategoryValueRequest,
			UpdateVariableCategoryValueResponse
		>(DATA_URL, {
			method: methods.updateVariableCategoryValue,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: UpdateVariableCategoryValueOutput = {
			variable,
			...(data.transactionId && { transactionId: data.transactionId }),
			...(data.transactionIds && { transactionIds: data.transactionIds })
		};

		return output;
	},

	async deleteVariableCategoryValue(
		input: DeleteVariableCategoryValueInput
	): Promise<DeleteVariableCategoryValueOutput> {
		const { data } = await sendRequest<
			DeleteVariableCategoryValueRequest,
			DeleteVariableCategoryValueResponse
		>(DATA_URL, {
			method: methods.deleteVariableCategoryValue,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: DeleteVariableCategoryValueOutput = {
			variable,
			...(data.transactionId && { transactionId: data.transactionId }),
			...(data.transactionIds && { transactionIds: data.transactionIds })
		};

		return output;
	},

	async deleteVariableCategoryValues(
		input: DeleteVariableCategoryValuesInput
	): Promise<DeleteVariableCategoryValuesOutput> {
		const { data } = await sendRequest<
			DeleteVariableCategoryValuesRequest,
			DeleteVariableCategoryValuesResponse
		>(DATA_URL, {
			method: methods.deleteVariableCategoryValues,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: DeleteVariableCategoryValuesOutput = {
			variable,
			...(data.transactionId && { transactionId: data.transactionId }),
			...(data.transactionIds && { transactionIds: data.transactionIds })
		};

		return output;
	},

	async moveVariableCategoryValue(
		input: MoveVariableCategoryValueInput
	): Promise<MoveVariableCategoryValueOutput> {
		const { data } = await sendRequest<
			MoveVariableCategoryValueRequest,
			MoveVariableCategoryValueResponse
		>(DATA_URL, {
			method: methods.moveVariableCategoryValue,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: MoveVariableCategoryValueOutput = {
			variable
		};

		return output;
	},

	async moveVariableCategoryValues(
		input: MoveVariableCategoryValuesInput
	): Promise<MoveVariableCategoryValuesOutput> {
		const { data } = await sendRequest<
			MoveVariableCategoryValuesRequest,
			MoveVariableCategoryValuesResponse
		>(DATA_URL, {
			method: methods.moveVariableCategoryValues,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: MoveVariableCategoryValuesOutput = {
			variable
		};

		return output;
	},

	async mergeVariableCategoryValues(
		input: MergeVariableCategoryValuesInput
	): Promise<MergeVariableCategoryValuesOutput> {
		const { data } = await sendRequest<
			MergeVariableCategoryValuesRequest,
			MergeVariableCategoryValuesResponse
		>(DATA_URL, {
			method: methods.mergeVariableCategoryValues,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const variable = parseApiVariable(data.variable);

		const output: MergeVariableCategoryValuesOutput = {
			variable,
			...(data.transactionId && { transactionId: data.transactionId }),
			...(data.transactionIds && { transactionIds: data.transactionIds })
		};

		return output;
	}
});

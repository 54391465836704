import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, UserLicenceModel, removeSubscriptionUser } from 'store/account/subscription';

export function useRemoveSubscriptionUser(): Operation<
	(userId: string, licenceModel: UserLicenceModel) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.REMOVE_SUBSCRIPTION_USER);

	function handler(userId: string, licenceModel: UserLicenceModel) {
		dispatch(removeSubscriptionUser(userId, licenceModel));
	}

	return [{ loading, error }, handler];
}

import { sendRequest, DATA_URL, STATISTICS_URL } from 'api/utils';
import { GenericApiResponse } from 'api/types';
import { Dictionary } from 'environment';
import { parseOldApiForm, parseApiForm, parseApiFormsData } from 'helpers/forms';
import { Form } from 'store/data/forms';

import {
	// `getForm`
	GetFormInput,
	GetFormRequest,
	GetFormResponse,
	// `getForms`
	GetFormsInput,
	GetFormsRequest,
	GetFormsResponse,
	GetFormsOutput,
	// `createForm`
	CreateFormInput,
	CreateFormRequest,
	CreateFormResponse,
	CreateFormOutput,
	// `updateForm`
	UpdateFormInput,
	UpdateFormRequest,
	// `deleteForm`
	DeleteFormInput,
	DeleteFormRequest,
	// `activateForms`
	ActivateFormsInput,
	ActivateFormsRequest,
	// `deactivateForms`
	DeactivateFormsInput,
	DeactivateFormsRequest,
	// `moveForm`
	MoveFormInput,
	MoveFormRequest,
	MoveFormResponse
} from './types';
import { AxiosRequestConfig } from 'axios';

const methods = {
	getForm: 'getForm',
	getForms: 'getForms',
	createForm: 'createForm',
	updateForm: 'updateForm',
	deleteForm: 'deleteForm',
	activateForms: 'activateForms',
	deactivateForms: 'deactivateForms',
	moveForm: 'moveForm'
};

export default () => ({
	async getForm(input: GetFormInput): Promise<Form> {
		const { data } = await sendRequest<GetFormRequest, GetFormResponse>(STATISTICS_URL, {
			method: methods.getForm,
			...input
		});

		if (!data.form) {
			input.callbacks?.errorCallback?.();

			throw new Error();
		}

		// MAP OLD STRUCTURE TO NEW
		const newApiForm = parseOldApiForm(data.form);
		// COMPUTE FORM FOR STORE
		const form = parseApiForm(newApiForm);

		return form;
	},

	async getForms(input: GetFormsInput, config?: AxiosRequestConfig): Promise<GetFormsOutput> {
		const { data } = await sendRequest<GetFormsRequest, GetFormsResponse>(
			STATISTICS_URL,
			{
				method: methods.getForms,
				...input
			},
			config
		);

		if (!data.forms) {
			throw new Error(Dictionary.errors.api.forms.couldNotGetForms);
		}

		const formsData = parseApiFormsData(data);

		const output: GetFormsOutput = { formsData };

		return output;
	},

	async createForm(input: CreateFormInput): Promise<CreateFormOutput> {
		const { data } = await sendRequest<CreateFormRequest, CreateFormResponse>(DATA_URL, {
			method: methods.createForm,
			...input
		});

		if (!data.formId) {
			throw new Error(Dictionary.errors.api.forms.couldNotCreateNewForm);
		}

		const output: CreateFormOutput = {
			formId: data.formId.toString()
		};

		return output;
	},

	async updateForm(input: UpdateFormInput): Promise<void> {
		const { data } = await sendRequest<UpdateFormRequest, GenericApiResponse>(DATA_URL, {
			method: methods.updateForm,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.forms.couldNotUpdateForm);
		}
	},

	async deleteForm(input: DeleteFormInput): Promise<void> {
		const { data } = await sendRequest<DeleteFormRequest, GenericApiResponse>(DATA_URL, {
			method: methods.deleteForm,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.forms.couldNotDeleteForm);
		}
	},

	async activateForms(input: ActivateFormsInput): Promise<void> {
		const { data } = await sendRequest<ActivateFormsRequest, GenericApiResponse>(DATA_URL, {
			method: methods.activateForms,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.forms.couldNotDeleteForm);
		}
	},

	async deactivateForms(input: DeactivateFormsInput): Promise<void> {
		const { data } = await sendRequest<DeactivateFormsRequest, GenericApiResponse>(DATA_URL, {
			method: methods.deactivateForms,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.forms.couldNotDeleteForm);
		}
	},

	async moveForm(input: MoveFormInput): Promise<void> {
		const { data } = await sendRequest<MoveFormRequest, MoveFormResponse>(DATA_URL, {
			method: methods.moveForm,
			...input
		});

		if (data.httpStatusCode !== 200) throw new Error();
	}
});

import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import { ActionTypes, getUserAddons } from 'store/account/addons';
import { ActionTypes as AccountActionTypes } from 'store/account/subscription';
import { selectUserAddons } from 'store/account/addons';
import { Nullable, SubscriptionAddonCode } from 'types/index';

interface UserAddonsProps {
	userAddons: Nullable<SubscriptionAddonCode[]>;
	activeUserAddons: Nullable<SubscriptionAddonCode[]>;
}

export function useUserAddons(): OperationResult<UserAddonsProps> {
	const dispatch = useDispatch();

	const { userAddons, activeUserAddons } = useSelector(state =>
		selectUserAddons(state.account.addons)
	);

	const fetched = userAddons !== null && activeUserAddons != null;

	//Currently we don't use a separate handler for this because this info is taken from the getUser call
	// which is called only once when logging in
	const [{ loading: fetchingAccount, error: errorFetchingAccount }] = useActivity(
		AccountActionTypes.GET_ACCOUNT
	);
	const [{ loading: fetchingAddons, error: errorFetchingAddons }] = useActivity(
		ActionTypes.GET_USER_ADDONS
	);

	function handler() {
		dispatch(getUserAddons());
	}

	return [
		{
			loading: fetchingAccount || fetchingAddons,
			error: errorFetchingAccount || errorFetchingAddons,
			fetched,
			data: { userAddons, activeUserAddons }
		},
		handler
	];
}

import { addVariableToSet, AddVariableToSetAction, ActionTypes } from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionPayload } from 'store/types';

export function useAddVariableToSet(activityPayload?: {
	variableName: string;
}): Operation<(payload: ActionPayload<AddVariableToSetAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.ADD_VARIABLE_TO_SET, activityPayload);

	function handler(payload: ActionPayload<AddVariableToSetAction>) {
		dispatch(addVariableToSet(payload));
	}

	return [{ loading, error }, handler];
}

import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import { Colors } from 'environment';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Spacer } from 'components/UI/Spacer';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	useTranslation,
	useProjectsLabels,
	useProjectNotificationSettings,
	useUpdateProjectNotificationSettings
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useDebounce } from 'hooks/utils';

export function ProjectNotificationSettingsView() {
	const { translate } = useTranslation();

	const [projectId, setProjectId] = useState<null | string>(null);
	// const [searchTerm, setSearchTerm] = useState('');

	const [{ data: projectsLabels, loading: projectsLabelsLoading }, getProjectsLabels] =
		useProjectsLabels();

	useEffect(() => {
		getProjectsLabels();
	}, []);

	const [{ data: projectSettings, loading: projectSettingsLoading }] =
		useProjectNotificationSettings(projectId ?? '');

	const [{ loading: updateProjectLoading, error: updateProjectError }, updateNotifications] =
		useUpdateProjectNotificationSettings(projectId ?? '');

	const togglesDisabled =
		projectSettingsLoading || updateProjectLoading || !projectSettings || !projectId;

	// REACT_ FORM
	const {
		Form,
		setValue,
		getValues,
		reset,
		Controller,
		isDirty,
		control,
		isDirtyAndValid,
		errors
	} = useReactForm({
		initialValues: getFormValuesFromNotificationSettings(),
		enableReinitialize: true
	});

	errors.projectEntryAddApp?.message;

	useDebounce(
		() => {
			if (isDirty) updateNotifications(getNotificationSettingsFromForm());
		},
		[isDirtyAndValid, isDirty],
		500
	);

	useEffect(() => {
		if (updateProjectError && projectSettings) {
			reset();
		}
	}, [updateProjectError, projectSettings]);

	useEffect(() => {
		if (projectSettings && !isEqual(getNotificationSettingsFromForm(), projectSettings))
			reset(getFormValuesFromNotificationSettings());
	}, [projectSettings]);

	function getNotificationSettingsFromForm() {
		const { projectEntryAddApp, projectEntryAddEmail } = getValues();
		return {
			projectEntryAdd: {
				app: projectEntryAddApp,
				email: projectEntryAddEmail
			}
		};
	}

	function getFormValuesFromNotificationSettings() {
		return {
			projectEntryAddApp: projectSettings ? projectSettings.projectEntryAdd.app : false,
			projectEntryAddEmail: projectSettings ? projectSettings.projectEntryAdd.email : false
		};
	}

	return (
		<Form onSubmit={() => updateNotifications(getNotificationSettingsFromForm())}>
			<CreatableSelect
				value={
					projectId
						? {
								label: projectsLabels[projectId],
								value: projectId
						  }
						: null
				}
				placeholder={translate(dict => dict.notifications.settings.project.typeProjectName)}
				disabled={false}
				items={
					projectsLabels
						? //  && searchTerm.trim().length > 0
						  Object.keys(projectsLabels).map(key => ({
								value: key,
								label: projectsLabels[key]
						  }))
						: []
				}
				//onInputChange={newValue => console.log('new value ', newValue)}
				onValueSelected={value => setProjectId(value ?? '')}
				noOptionsMessage={projectsLabelsLoading ? 'Loading...' : 'No projects'}
				scrollIntoView
				required
				type={t => t.Search}
			/>
			<Spacer size={s => s.m} />

			<Controller
				name="projectEntryAddApp"
				control={control}
				render={({ field: { value } }) => (
					<SettingsToggle
						on={value}
						disabled={togglesDisabled}
						title={translate(dict => dict.notifications.settings.project.entries)}
						description={translate(
							dict => dict.notifications.settings.project.entriesCreated
						)}
						onChange={() =>
							setValue('projectEntryAddApp', !value, {
								shouldDirty: true
							})
						}
					/>
				)}
			/>
		</Form>
	);
}

// This component will be moved to NotificationSettings once we have more type of settings
type SettingsToggleProps = {
	on: boolean;
	title: string;
	description: string;
	disabled: boolean;
	onChange: () => void;
};
function SettingsToggle({ on, title, description, disabled, onChange }: SettingsToggleProps) {
	return (
		<Flex align={c => c.center}>
			<div style={{ flex: 1 }}>
				<Typography.Paragraph color={disabled ? Colors.text.disabled : Colors.text.main}>
					{title}
				</Typography.Paragraph>
				<Typography.Hint color={disabled ? Colors.text.disabled : Colors.text.main}>
					{description}
				</Typography.Hint>
			</div>

			<Switch disabled={disabled} on={on} onChange={onChange} />
		</Flex>
	);
}

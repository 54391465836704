import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const Container = styled.div`
	width: calc(100% - 36rem);

	@media ${MediaQueries.maxWidth.lg} {
		width: 100%;
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const NoPatients = styled.div`
	font-size: 1.6rem;
	color: ${Colors.gray.light};
	text-align: center;
	width: 100%;
`;

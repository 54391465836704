import { useAnalysesActiveColum, useFullscreenAnalysis } from 'hooks/store';
import { NoPlot } from '../NoPlot';
import { Container } from './AnalysisViewContainer.style';

interface Props {
	children?: React.ReactNode;
	loading?: boolean;
	isConfigPanelOpen: boolean;
	message?: string;
	error?: boolean;
	disableInteractions?: boolean;
}

export function AnalysisViewContainer({
	children,
	loading,
	isConfigPanelOpen,
	message,
	error = false,
	disableInteractions = false
}: Props) {
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	if (error) {
		return (
			<Container
				fullScreen={!!fullscreen}
				isConfigPanelOpen={isConfigPanelOpen}
				activeColumn={activeColumn}
			>
				<NoPlot message={message} />
			</Container>
		);
	}

	return (
		<Container
			fullScreen={!!fullscreen}
			disabled={loading}
			isConfigPanelOpen={isConfigPanelOpen}
			activeColumn={activeColumn}
			disableInteractions={disableInteractions}
		>
			{children}
		</Container>
	);
}

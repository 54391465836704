import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Svgs, Colors } from 'environment';
import { variableSetCountPulse, variableSetCardPulse } from 'events/variables';
import { useCustomEventListener } from 'helpers/events';
import { isVariablePromGenerated } from 'helpers/variables';
import { DraggableVariableCardType } from 'store/data/variables';
import { DragProps, VariableCardPayload } from '../CommonCard';
import { VariableSetConfirmDragAndDropActionModal } from '../../ConfirmDragAndDropActionModal';
import {
	LineMarker,
	VariableCardContainer,
	ColorIndicator,
	SelectedCardIndicator
} from '../VariableCard/VariableCard.style';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import {
	useAddVariableToSet,
	useAddVariableGroupToSet,
	useRemoveVariableFromGroup,
	useMoveVariableBetweenSets,
	useMoveVariableGroupBetweenSets,
	useVariables,
	useTranslation
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	title: string;
	name: string;
	selected: boolean;
	numberOfVariables: number;
	dragMainProps: DragProps;
	onClick: () => void;
	children: React.ReactNode;
}

export function VariableSetCard({
	title,
	name,
	selected,
	numberOfVariables,
	dragMainProps: { ref, isDragging, drag },
	children,
	onClick
}: Props) {
	const [, addVariableToSet] = useAddVariableToSet();
	const [, addVariableGroupToSet] = useAddVariableGroupToSet();
	const [, removeVariableFromGroup] = useRemoveVariableFromGroup();
	const [, moveVariableBetweenSets] = useMoveVariableBetweenSets();
	const [, moveVariableGroupBetweenSets] = useMoveVariableGroupBetweenSets();
	const [
		{
			data: { variablesMap, groupsMap }
		}
	] = useVariables();
	const { translate } = useTranslation();

	const { setError: setErrorNotification } = useAlerts();

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	const [pulseCard, setPulseCard] = useState(false);
	const [pulseCount, setPulseCount] = useState(false);

	useCustomEventListener(variableSetCardPulse, {
		onListen: payload => {
			if (payload.setName === name) setPulseCard(true);
		}
	});
	useCustomEventListener(variableSetCountPulse, {
		onListen: payload => {
			if (payload.setName === name) setPulseCount(true);
		}
	});

	// Drop over variable set card
	const [{ handlerId, isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable =
				draggedVariable &&
				isVariablePromGenerated({ systemGenerated: draggedVariable.systemGenerated });

			return {
				handlerId: monitor.getHandlerId(),
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop: (item: VariableCardPayload, monitor) => {
			if (!monitor.isOver({ shallow: true })) return;

			const type = variablesMap[item.name]?.type;

			if (type === VariableType.File) {
				return setErrorNotification({
					// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
					message: 'Series do not support file variables!'
				});
			}

			if (item.isGroup) {
				const groupHasUnallowedTypes = groupsMap[item.name].variablesBelongingToGroup.find(
					variable => variablesMap[variable].type === VariableType.File
				);

				if (groupHasUnallowedTypes)
					return setErrorNotification({
						// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
						message: 'Series do not support file variables!'
					});

				if (groupsMap[item.name].variablesBelongingToGroup.length === 0) {
					return setErrorNotification({
						message: translate(dict => dict.variables.move.emptyGroup)
					});
				}
			}

			const isParentSetCurrent = item.parentSet === name;

			if (isSysGenVariable)
				return setErrorNotification({
					message: `${translate(
						dict => dict.variablesPage.variableCard.beforeVariable
					)} ${title} ${translate(dict => dict.terms.variableLowerCase)}`
				});

			if (isParentSetCurrent) {
				if (item.parentGroup) return handleDrop(item);

				return;
			}

			confirmActionModal.open(item);
		}
	});

	function handleDrop(item: VariableCardPayload) {
		// DROP CARD ON `variableSet` CARD (VARIABLE SET DRAWER)
		if (item.parentSet) {
			const isParentSetCurrent = item.parentSet === name;

			// CARD IS IN VARIABLE SET
			if (isParentSetCurrent) {
				if (item.isGroup) return;

				if (item.parentGroup) {
					return removeVariableFromGroup({
						variableName: item.name,
						groupName: item.parentGroup,
						setName: item.parentSet
					});
				}
			}

			if (item.isGroup) {
				// MOVE VARIABLE GROUP CARD FROM DIFFERENT VARIABLE SET DRAWER
				return moveVariableGroupBetweenSets({
					groupName: item.name,
					sourceSetName: item.parentSet,
					destinationSetName: name
				});
			}

			// MOVE VARIABLE CARD FROM DIFFERENT VARIABLE SET DRAWER
			return moveVariableBetweenSets({
				variableName: item.name,
				sourceSetName: item.parentSet,
				destinationSetName: name,
				...(item.parentGroup !== undefined && {
					parentGroup: item.parentGroup
				})
			});
		}

		// DROP CARD ON `variableSet` CARD (VARIABLE GROUP DRAWER)
		if (item.parentGroup) {
			return addVariableToSet({
				variableName: item.name,
				setName: name,
				from: {
					group: {
						groupName: item.parentGroup
					}
				}
			});
		}

		// DROP `group` CARD ON `variableSet` CARD (MAIN LEVEL)
		if (item.isGroup) {
			return addVariableGroupToSet({
				groupName: item.name,
				setName: name
			});
		}

		// DROP `variable` CARD ON `variableSet` CARD (MAIN LEVEL)
		return addVariableToSet({
			variableName: item.name,
			setName: name
		});
	}

	drop(drag(ref));

	const hasOneVariable = numberOfVariables === 1;

	const subtitle = `${numberOfVariables} ${
		hasOneVariable
			? translate(dict => dict.terms.variableLowerCase)
			: translate(dict => dict.terms.variables)
	}`;

	return (
		<>
			<VariableCardContainer
				{...(pulseCard && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={() => setPulseCard(false)}
				//
				ref={ref}
				isDragging={isDragging}
				data-handler-id={handlerId}
				isHovered={isOverCurrent}
				onClick={onClick}
				isVariableSet
				id={title?.replaceAll(' ', '').toLowerCase()}
				data-scroll-id={name}
			>
				<ColorIndicator type="variableSet" />
				{selected && <SelectedCardIndicator />}

				<Flex align={a => a.center}>
					<Icon
						svg={Svgs.Set}
						size={s => s.m}
						colors={{ color: Colors.seriesGreen }}
						propagate
					/>
					<Typography.Paragraph fontweight={w => w.medium} title={title} ellipsis>
						{title}
					</Typography.Paragraph>
				</Flex>
				<Flex>
					<Typography.Caption
						{...(pulseCount && {
							className: 'pulse-vibrant-green',
							style: {
								color: Colors.chart.darkGreen.dark
							}
						})}
						onAnimationEnd={() => setPulseCount(false)}
						//
						title={subtitle}
						ellipsis
					>
						{subtitle}
					</Typography.Caption>
				</Flex>

				<LineMarker />

				{children}
			</VariableCardContainer>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="to"
					onConfirm={() => handleDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}

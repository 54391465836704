import { MediaQueries } from 'environment';
import styled from 'styled-components';

export const CardsTitleSection = styled.div`
	display: flex;
	justify-content: space-between;

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
	}
`;

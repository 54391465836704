import { InfoMessage } from 'components/UI/InfoMessage';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { keyMaps } from 'consts';
import { SetState } from 'types/index';

import { Title } from '../styles';
import { useTranslation } from 'hooks/store';
import { useMatchProms } from 'hooks/navigation';

interface Props {
	newUsersState: {
		newUsers: string[];
		setNewUsers: SetState<string[]>;
	};
	areAllInputsEmails: boolean;
	onSubmit: () => void;
}

export function InviteCollaborators({
	newUsersState: { newUsers, setNewUsers },
	areAllInputsEmails,
	onSubmit
}: Props) {
	const { translate } = useTranslation();
	const matchProms = useMatchProms();

	return (
		<>
			<Title>{translate(({ collaborators }) => collaborators.add.title.addStep)}</Title>

			<TagInput
				label={translate(({ collaborators }) =>
					matchProms
						? collaborators.add.input.label.prom
						: collaborators.add.input.label.project
				)}
				items={newUsers}
				placeholder={translate(({ collaborators }) => collaborators.add.input.placeholder)}
				error={
					!areAllInputsEmails &&
					translate(({ collaborators }) => collaborators.errors.emailInputError)
				}
				delimiters={[keyMaps.comma]}
				onChange={setNewUsers}
				onSubmit={onSubmit}
				toLowerCase
				autoFocus
			/>

			{/* disable this until imports are ready */}
			{/* {translate(({ collaborators }) => collaborators.add.import)} */}

			<Spacer size={s => s.l} />

			<InfoMessage message={translate(({ collaborators }) => collaborators.add.notice)} />
		</>
	);
}

import { ProjectType } from 'types/data/projects/constants';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useProjectById, useDeleteProject } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { useState } from 'react';
import { InputType } from 'types';

interface Props {
	projectId: string;
	onClose: (success?: boolean) => void;
}

export function DeleteProjectModal({ projectId, onClose }: Props) {
	const { translate } = useTranslation();
	const [confirmDelete, setConfirmDelete] = useState('');

	const project = useProjectById(projectId);

	const isProm = project?.projectType === ProjectType.PROM;

	const [{ loading: deletingProject, error: errorDeletingProject }, deleteProject] =
		useDeleteProject();

	useCompletedAction(deletingProject, errorDeletingProject, () => onClose(true));

	function handleSubmit() {
		deleteProject(projectId);
	}

	return (
		<Modal
			data-test-id="confirmDeleteProject"
			size={s => s.s}
			title={translate(dict => dict.promptToDelete.title)}
			primary={{
				label: translate(({ buttons }) => buttons.delete),
				loading: deletingProject,
				warning: true,
				disabled:
					confirmDelete !==
					(isProm
						? translate(dict => dict.projects.confirmDeleteProjectModal.prom)
						: translate(dict => dict.projects.confirmDeleteProjectModal.project)),
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.promptToDelete.description[isProm ? 'prom' : 'project'])}{' '}
				<b>{project?.projectName}</b>?
			</Typography.Paragraph>

			<Spacer size={s => s.m} />

			<Input
				type={InputType.Text}
				value={confirmDelete}
				onChange={e => setConfirmDelete(e.target.value)}
				label={`${translate(dict => dict.projects.confirmDeleteProjectModal.type)}
				 "${
						isProm
							? translate(dict => dict.projects.confirmDeleteProjectModal.prom)
							: translate(dict => dict.projects.confirmDeleteProjectModal.project)
					}" 
				${translate(dict => dict.projects.confirmDeleteProjectModal.toContinue)}`}
				dataTestId="confirm-delete-project-input"
			/>
		</Modal>
	);
}

import { MediaQueries } from 'environment';
import styled, { css } from 'styled-components';

interface ContainerType {
	seriesView: SeriesViewType;
}

interface SeriesViewType {
	narrowTableView?: boolean;
	gridView?: boolean;
	tableFullSizeView?: boolean;
}

export const Container = styled.div<ContainerType>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin: auto;

	${({ seriesView: { tableFullSizeView } }) => {
		if (tableFullSizeView) {
			return css`
				@media ${MediaQueries.maxWidth.md} {
					padding: 0 1rem 0 1rem;
				}

				padding: 0 4.6rem 0 4.6rem;
			`;
		}
	}}

	@media (min-width: 768px) {
		${({ seriesView: { narrowTableView } }) => {
			if (narrowTableView) {
				return css`
					width: 62.4rem;
				`;
			}
		}}
	}
`;

import { ActionTypes, initiateJADBioDataset } from 'store/addons/jadbio';
import { OperationResult } from 'hooks/store/types';
import { selectJADBioDatasetInitialized } from 'store/data/analyses';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useInitiateJADBioDataset(analysisId: string): OperationResult<boolean | null> {
	const dispatch = useDispatch();

	const data = useSelector(state =>
		selectJADBioDatasetInitialized(state.data.analyses, analysisId)
	);

	const fetched = data !== null;

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.INITIATE_JADBIO_DATASET,
		analysisId
	);

	function handler() {
		if (error) resetError();
		dispatch(initiateJADBioDataset(analysisId));
	}

	return [{ data, loading, error, fetched }, handler];
}

import { mergeSelectItems } from 'helpers/variables';
import { useMemo } from 'react';
import { useVariablesDataSelectItems } from './useVariablesDataSelectItems';
import { VariableType } from 'types/data/variables/constants';
import { VariablesData } from 'store/data/variables';

interface Props {
	series?: string;
	variablesData: VariablesData;
}

const ALLOWED_TYPES = {
	series: [VariableType.Category, VariableType.Integer, VariableType.Unique, VariableType.String],
	main: [VariableType.Category, VariableType.Integer]
};

export function useGroupingVariablesSelectItems({ series, variablesData }: Props) {
	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData, {
		series,
		omitVariables: []
	});

	const selectItems = useMemo(() => {
		if (series) {
			const extracted = mergeSelectItems(
				variablesDataSelectItems.selectItems,
				variablesData,
				ALLOWED_TYPES.series
			);
			return extracted;
		}

		return mergeSelectItems(
			variablesDataSelectItems.selectItems,
			variablesData,
			ALLOWED_TYPES.main
		);
	}, [series, variablesData, variablesData]);

	return selectItems;
}

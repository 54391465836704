import { Icon } from 'components/UI/Icons';
import { DeleteIcon } from 'components/Forms';
import { Svgs } from 'environment';
import { FormSet } from 'store/data/forms';

import {
	Container,
	BorderContainer,
	Border,
	MenuWrapper,
	MenuWrapperPlaceholder,
	Header
} from './DraggableFormSet.style';
import { FormInput } from '../FormInput';
import { useRemoveFormSet } from 'hooks/store';

interface Props {
	formSet: FormSet;
	accessWrite: boolean;
}

export function DraggableFormSet({ formSet, accessWrite }: Props) {
	const { setId } = formSet;

	const removeFormSet = useRemoveFormSet();

	// TODO: FIX - GET VARIABLE SET BY `setName` AND CHECK IF IT HAS DATA
	const isEmpty = false;

	// ONLY ALLOW THE PROJECT OWNER TO SEE AND EDIT
	// RESTRICT THE COLLABORATORS TO SEE EMPTY GROUPS IN FORM DESIGNER
	if (!accessWrite && isEmpty) return null;

	return (
		<Container>
			<Header>
				<Icon svg={Svgs.SetBig} marginOffset={{ left: 1.6, right: 0.8 }} propagate />
				<FormInput formSet={formSet} disabled={!accessWrite} usedInFormDesigner />
			</Header>

			<BorderContainer>
				<Border>
					<MenuWrapper>
						<DeleteIcon svg={Svgs.Delete} size={s => s.m} disabled={!accessWrite} />
					</MenuWrapper>
				</Border>
			</BorderContainer>

			<MenuWrapperPlaceholder onClick={() => accessWrite && removeFormSet({ setId })} />
		</Container>
	);
}

import {
	buildVariablesDataLocation,
	isGroupOrderItem,
	isVariableOrderItem
} from 'helpers/variables';
import { useSelector } from 'hooks/utils';
import { selectEntriesTableVisibleColumns } from 'store/data/entries';
import { useVariablesData } from './useVariablesData';
import { AnalysisVariable } from 'api/data/analyses';
import { selectSeriesTableVisibleColumns } from 'store/data/series';
import { useMemo } from 'react';
import { systemGeneratedVariables } from 'consts';
import { buildEntriesTableColumns } from 'helpers/entries';
import { useStatuses } from '../statuses/useStatuses';
import { useSelectedSeriesEntry } from '../entries/useSelectedSeriesEntry';

export function useVisibleVariableReferences(series?: boolean): AnalysisVariable[] {
	const mainVisibleColumnNames = useSelector(state =>
		selectEntriesTableVisibleColumns(state.data.entries)
	);

	const seriesVisibleColumnNames = useSelector(state =>
		selectSeriesTableVisibleColumns(state.data.series)
	);

	const variablesData = useVariablesData();
	const variablesLocation = buildVariablesDataLocation(variablesData);
	const [
		{
			data: { statuses }
		}
	] = useStatuses({ lazy: true });

	const [{ setName: seriesName }] = useSelectedSeriesEntry();

	const allSeriesColumns = useMemo(() => {
		if (!seriesName) return [];

		const { variableSetsMap, groupsMap } = variablesData;
		const selectedSet = variableSetsMap[seriesName];
		const seriesColumns: string[] = [];

		selectedSet.setOrder.forEach(value => {
			if (isVariableOrderItem(value)) {
				seriesColumns.push(value.variable);
			}
			if (isGroupOrderItem(value)) {
				const group = groupsMap[value.group];
				group.variablesBelongingToGroup.forEach(variableName =>
					seriesColumns.push(variableName)
				);
			}
		});

		// ADD SYSTEM GENERATED VARIABLES
		systemGeneratedVariables.forEach(variableName => seriesColumns.push(variableName));

		return seriesColumns;
	}, [seriesName, variablesData]);

	const allMainColumns = useMemo(() => {
		const hasStatusColumn = statuses.length > 0;
		return buildEntriesTableColumns(variablesData, { hasStatusColumn });
	}, [variablesData]);

	// if all columns are selected, inject `original_id/parentsetrevisionid` to replicate default behaviour
	const visibleColumnNames = useMemo(() => {
		if (series) {
			if (
				allSeriesColumns.length > 0 &&
				allSeriesColumns.length === seriesVisibleColumnNames.length
			) {
				return ['parentsetrevisionid', ...seriesVisibleColumnNames, 'original_id'];
			}

			return seriesVisibleColumnNames;
		}

		if (allMainColumns.length > 0 && mainVisibleColumnNames.length === allMainColumns.length) {
			return ['original_id', ...mainVisibleColumnNames];
		}
		return mainVisibleColumnNames;
	}, [series, mainVisibleColumnNames, seriesVisibleColumnNames]);

	return visibleColumnNames.map(column => {
		let series: string | null = null;
		if (
			seriesName &&
			(column === 'parentsetrevisionid' ||
				column === 'original_id' ||
				systemGeneratedVariables.includes(column))
		) {
			series = seriesName;
		}
		const parentSetName = variablesLocation.variablesLocation[column]?.setName;
		if (parentSetName) {
			series = parentSetName;
		}

		return {
			name: column,
			...(series ? { series } : {})
		};
	});
}

import { Svgs } from 'environment';

import { Container, DescriptionContainer } from './MaintenanceWarning.style';
import { Icon } from '../Icons';

export function MaintenanceWarning({ children }: { children?: React.ReactNode }) {
	return (
		<Container>
			<Icon
				svg={Svgs.InformationDark}
				size={s => s.l}
				variant={v => v.simple}
				marginOffset={{ right: 1.6 }}
			/>
			<DescriptionContainer>{children}</DescriptionContainer>
		</Container>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, deleteEnterpriseRole } from 'store/account/enterprise';

export function useDeleteEnterpriseRole(): Operation<(roleId: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.DELETE_ENTERPRISE_ROLE);

	function handler(roleId: string) {
		dispatch(deleteEnterpriseRole(roleId));
	}

	return [{ loading, error }, handler];
}

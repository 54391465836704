import { createSelector } from 'reselect';

import { State } from './types';

function getPrimaryPaymentId(state: State) {
	return state.subscriptionPaymentMethods?.cards?.find(card => card.isPrimary)?.id;
}

function getBillingInfo(state: State) {
	return state.billingInfo;
}

function getSubscriptionPayments(state: State) {
	return state.subscriptionPayments;
}

export const selectPrimaryPaymentId = createSelector(
	[getPrimaryPaymentId],
	primaryPaymentId => primaryPaymentId
);

export const selectBillingInfo = createSelector([getBillingInfo], billingInfo => billingInfo);

export const selectSubscriptionPayments = createSelector(
	[getSubscriptionPayments],
	subscriptionPayments => subscriptionPayments
);

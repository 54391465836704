import { Icon } from 'components/UI/Icons';
import { Box, Container, PaginationText } from './VariableErrorPagination.style';
import { Svgs } from 'environment';
import { useTranslation } from 'hooks/store';

type Props = {
	previousDisabled: boolean;
	nextDisabled: boolean;
	handleNext: () => void;
	handlePrevious: () => void;
};

export function VariableErrorPagination({
	handleNext,
	handlePrevious,
	nextDisabled,
	previousDisabled
}: Props) {
	const { translate } = useTranslation();

	return (
		<Container>
			<Box onClick={handlePrevious} disabled={previousDisabled}>
				<Icon svg={Svgs.ArrowLeft} onClick={handlePrevious} />
				<PaginationText>
					{translate(dict => dict.import.importReview.previousVariable)}
				</PaginationText>
			</Box>
			<Box onClick={handleNext} disabled={nextDisabled}>
				<PaginationText>
					{translate(dict => dict.import.importReview.nextVariable)}
				</PaginationText>
				<Icon svg={Svgs.ArrowLongRight} onClick={handleNext} />
			</Box>
		</Container>
	);
}

import { useMemoOnce, useSelector } from 'hooks/utils';
import { selectFormById, Form } from 'store/data/forms';

interface UseFormOptions {
	initial?: boolean;
}

export function useFormById(formId: string, options?: UseFormOptions): Form | undefined {
	const { initial = false } = options ?? {};

	/**
	 * Used like this to increase the cache size from 1 to n
	 */
	const _selectFormById = useMemoOnce(selectFormById);

	return useSelector(state => _selectFormById(state.data.forms, { formId, initial }));
}

import { Navigate, useLocation, Outlet } from 'react-router-dom';
import ErrorBoundary from 'app/ErrorBoundary';
import { ROUTES } from 'types/navigation';
import { Loader } from 'components/UI/Loader';
import {
	useLogin,
	useAccount,
	useSubscription,
	useWithProject,
	usePermissionAccess,
	useProfileIncomplete,
	useSubscriptionRules
} from 'hooks/store';

interface Props {
	subscriptionAccess?: boolean;
}

export function PrivateRoutes({ subscriptionAccess }: Props) {
	const [{ data: loggedIn }] = useLogin();
	const [{ fetched: isAccountFetched }] = useAccount();
	const [
		{
			data: {
				subscriptionTypes: { isLedidiEnterprise },
				userTypesAndRoles: { isSubscriptionOwner }
			},
			fetched: isSubscriptionFetched
		}
	] = useSubscription();

	const { showEnterpriseAdminRoute } = useSubscriptionRules();

	if (!loggedIn) return <Navigate to={ROUTES.Login} replace />;

	if (!isAccountFetched) return <Loader primary />;

	if (subscriptionAccess && !isSubscriptionFetched) return <Loader primary />;

	if (
		subscriptionAccess &&
		isSubscriptionFetched &&
		(!isSubscriptionOwner || isLedidiEnterprise)
	) {
		if (showEnterpriseAdminRoute) {
			<Navigate to={ROUTES.EnterpriseAdminSubscription} replace />;
		} else return <Navigate to={ROUTES.AccountUMSubscription} replace />;
	}

	return <LoggedInComponent />;
}

function LoggedInComponent() {
	useWithProject();
	usePermissionAccess();
	useProfileIncomplete();

	const { pathname } = useLocation();

	return (
		<ErrorBoundary pathname={pathname}>
			<Outlet />
		</ErrorBoundary>
	);
}

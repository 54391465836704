import { AnalysisVariable, KaplanMeierResultsDataV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation, useVariables } from 'hooks/store';
import { AnalysisStatistic } from 'components/Analysis/Analyses/UI';
import { useMemo } from 'react';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';
import { formatTableCell } from './helpers';

interface Props {
	dataset: KaplanMeierResultsDataV2;
	groupVariable: AnalysisVariable | null;
	showCategoryLabels?: boolean;
}

export enum FormatRule {
	Default,
	Scientific,
	TwoDecimals
}

const MAX_FORMATTING_NUMBER = 100000;

export function KaplanPatientsTableV2({ dataset, groupVariable, showCategoryLabels }: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { variablesMap, variableSetsMap }
		}
	] = useVariables();

	const grouping = !!groupVariable;

	const aggregationToVariableName =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	const { tableColumnNames, patientsTables } = useMemo(() => {
		const tableColumnNames: string[] = [];

		const patientsTables = Object.entries(dataset).map(([categoryValue, value]) => {
			const variable =
				variablesMap[groupVariable?.name ?? ''] ??
				variablesMap[
					aggregationToVariableName[groupVariable?.name ?? '']?.aggregator?.variableName
				];

			const key = showCategoryLabels
				? mapVariableCategoryValueToLabel(categoryValue, variable)
				: categoryValue;
			tableColumnNames.push(key ?? '');
			return value.numberAtRiskMatrix;
		});

		return { tableColumnNames, patientsTables };
	}, [showCategoryLabels, dataset]);

	const formatRule = useMemo(() => {
		const numbers = Object.values(dataset).flatMap(d => d.timeline);

		if (
			numbers.find(
				number => number > MAX_FORMATTING_NUMBER || number < -MAX_FORMATTING_NUMBER
			)
		) {
			return FormatRule.Scientific;
		}

		if (!numbers.find(number => number > 10)) return FormatRule.TwoDecimals;

		return FormatRule.Default;
	}, [dataset]);

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.analyses.kaplan.config.patientsAtRisk)}
			</AnalysisStatistic.Title>
			<Table>
				<Table.Head>
					<Table.Row>
						{grouping ? <Table.Column minWidth={8} /> : <></>}
						{patientsTables[0].timeline.map(xPoint => {
							return (
								<Table.Column key={`column-${xPoint}`} minWidth={8}>
									{formatTableCell(xPoint, formatRule)}
								</Table.Column>
							);
						})}
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{patientsTables.map(({ numberAtRisk }, tableIndex) => (
						<Table.Row key={`row-${tableColumnNames[tableIndex]}`}>
							{grouping ? (
								<Table.Column>
									<Table.Cell bold>
										{grouping ? tableColumnNames[tableIndex] : ''}
									</Table.Cell>
								</Table.Column>
							) : (
								<></>
							)}

							{numberAtRisk.map((number, index) => {
								return (
									<Table.Column
										key={`${
											dataset[tableColumnNames[tableIndex]]
										}-column-${index}`}
									>
										<Table.Cell>{number}</Table.Cell>
									</Table.Column>
								);
							})}
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</AnalysisStatistic>
	);
}

import { addVariablesToGroup, AddVariablesToGroupAction, ActionTypes } from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionPayload } from 'store/types';

export function useAddVariablesToGroup(): Operation<
	(payload: ActionPayload<AddVariablesToGroupAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.ADD_VARIABLES_TO_GROUP);

	function handler(payload: ActionPayload<AddVariablesToGroupAction>) {
		dispatch(addVariablesToGroup(payload));
	}

	return [{ loading, error }, handler];
}

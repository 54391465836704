import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateUserBillingAddress, BillingAddress } from 'store/account/payments';

export function useUpdateBillingAddress(): Operation<(billingAddress: BillingAddress) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_USER_BILLING_ADDRESS);

	function handler(billingAddress: BillingAddress) {
		dispatch(updateUserBillingAddress(billingAddress));
	}

	return [{ loading, error }, handler];
}

import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Images } from 'environment';
import { useTranslation } from 'hooks/store';

import { ScrollableContainer, Image } from './HelpSetupOneTimePassword.style';

interface Props {
	onClose: () => void;
}

export function HelpSetupOneTimePassword({ onClose }: Props) {
	const { translate } = useTranslation();

	return (
		<Modal
			title={translate(({ security }) => security.helpComponent.whatIsMfa.title)}
			onClose={onClose}
			visible
			close
		>
			<ScrollableContainer>
				<Typography.Paragraph>
					{translate(({ security }) => security.helpComponent.whatIsMfa.text)}
				</Typography.Paragraph>

				<Spacer size={s => s.s} />

				<Typography.H6 uppercase>
					{translate(({ security }) => security.helpComponent.stepOne.title)}
				</Typography.H6>

				<Spacer size={s => s.xs} />

				<Typography.Caption uppercase>
					{translate(({ security }) => security.helpComponent.stepOne.subtitle)}
				</Typography.Caption>

				<Spacer size={s => s.xs} />

				<Typography.Paragraph>
					{translate(({ security }) => security.helpComponent.stepOne.text)}
				</Typography.Paragraph>

				<Spacer size={s => s.xs} />

				<Typography.Hint>
					{translate(({ security }) => security.helpComponent.stepOne.textSmall)}
				</Typography.Hint>

				<Spacer size={s => s.s} />

				<Image
					src={Images.mfaHelpStepOne}
					alt={translate(dict => dict.login.chromeLogoAlt)}
				/>

				<Spacer size={s => s.m} />

				<Typography.H6 uppercase>
					{translate(({ security }) => security.helpComponent.stepTwo.title)}
				</Typography.H6>

				<Spacer size={s => s.xs} />

				<Typography.Caption uppercase>
					{translate(({ security }) => security.helpComponent.stepTwo.subtitle)}
				</Typography.Caption>

				<Spacer size={s => s.xs} />

				<Typography.Paragraph>
					{translate(({ security }) => security.helpComponent.stepTwo.text)}
				</Typography.Paragraph>

				<Spacer size={s => s.xs} />

				<Typography.Hint>
					{translate(({ security }) => security.helpComponent.stepTwo.textSmall)}
				</Typography.Hint>

				<Spacer size={s => s.s} />

				<Image
					src={Images.mfaHelpStepTwo}
					alt={translate(dict => dict.login.chromeLogoAlt)}
				/>

				<Spacer size={s => s.m} />

				<Typography.H6 uppercase>
					{translate(({ security }) => security.helpComponent.stepThree.title)}
				</Typography.H6>

				<Spacer size={s => s.xs} />

				<Typography.Caption uppercase>
					{translate(({ security }) => security.helpComponent.stepThree.subtitle)}
				</Typography.Caption>

				<Spacer size={s => s.xs} />

				<Typography.Paragraph>
					{translate(({ security }) => security.helpComponent.stepThree.text)}
				</Typography.Paragraph>

				<Spacer size={s => s.s} />
			</ScrollableContainer>
		</Modal>
	);
}

import styled from 'styled-components';

import { CountrySelector as PrimaryCountrySelector } from 'components/UI/Interactables/CountrySelector';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 2.4rem;
`;

export const CountrySelector = styled(PrimaryCountrySelector)`
	margin-bottom: 2vh;
`;

import { useEffect, useRef } from 'react';
import { OperationResult, HookOptions } from 'hooks/store/types';
import {
	ActionTypes,
	getEntries,
	selectEntriesRows,
	Entries,
	selectEntriesTableParams,
	selectEntriesRowsByPage
} from 'store/data/entries';
import { useDispatch, useSelector } from 'hooks/utils';
import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

type PaginationOptions = {
	paginated?: boolean;
	filtered?: boolean;
};

export function useEntries(
	options?: HookOptions & PaginationOptions
): OperationResult<Entries, (input?: { username: string }) => void> {
	const { lazy, paginated, filtered } = options ?? {
		lazy: false,
		paginated: false,
		filtered: false
	};

	const dispatch = useDispatch();

	const abortController = useRef(new AbortController());

	const { hasDatasetReadAccess } = usePermissions();
	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const { pageIndex } = useSelector(state => selectEntriesTableParams(state.data.entries));

	const fetched = useSelector(state => state.data.entries.metadata.fetched);

	const data = useSelector(state => selectEntriesRows(state.data.entries));
	const dataByPage = useSelector(state => selectEntriesRowsByPage(state.data.entries, pageIndex));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_ENTRIES, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && hasDatasetReadAccess && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid, hasDatasetReadAccess]);

	function handler(input?: { username?: string }) {
		if (loading) return;
		if (error) resetError();

		dispatch(
			getEntries({
				abortController: abortController.current,
				filtered,
				...(input?.username && { username: input.username })
			})
		);
	}

	return [
		{
			loading,
			error,
			data: paginated ? dataByPage ?? [] : data,
			fetched,
			abortController: abortController.current
		},
		handler
	];
}

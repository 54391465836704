import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

export const Container = styled.div`
	position: relative;
	display: flex;
	padding-left: 0.5rem;
	padding-right: 0.3rem;
`;

export const ActionComponent = styled.div`
	display: flex;
	cursor: pointer;
`;

export const FloatingContainer = styled.div`
	position: absolute;
	top: 2.8rem;
	right: 0;
	z-index: 99;

	width: 20.8rem;
	min-width: 20.8rem;
	background-color: ${Colors.white};
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};

	.checkbox p {
		margin-left: 0.4rem;
	}
`;

export const FloatingContainerTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 4rem;
	min-height: 4rem;
	margin-bottom: 1.6rem;
	padding: 0.9rem 1.6rem;

	.delete-columns-filter-icon {
		visibility: hidden;
	}

	:hover .delete-columns-filter-icon {
		visibility: visible;
	}
`;

export const ItemsContainer = styled.div`
	overflow-y: auto;
	max-height: 30vh;
`;

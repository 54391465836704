import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { BillingAddress, PaymentAddress } from 'store/account/payments';
import { ObjectSchema } from 'yup';
import { getBillingAddressSchema } from 'helpers/subscription';

const emptyBillingAddress = {
	city: '',
	country: '',
	firstName: '',
	lastName: '',
	line1: '',
	line2: '',
	state: '',
	company: '',
	vatNumber: '',
	zip: ''
};

const emptyPrimaryPaymentSourceAddress = {
	city: '',
	country: '',
	firstName: '',
	lastName: '',
	line1: '',
	line2: '',
	state: '',
	zip: ''
};

interface UseBillingAddressFormResult {
	data: {
		usePaymentInfo: boolean;
		useCompanyInfo: boolean;
		billingAddressValidationSchema: ObjectSchema<{}>;
		initialValues: BillingAddress;
	};
	emptyValues: {
		emptyBillingAddress: BillingAddress;
		emptyPrimaryPaymentSourceAddress: PaymentAddress;
	};
	toggleUsePaymentInfo: () => void;
	toggleUseCompanyInfo: () => void;
}

export function useBillingAddressFormValues(
	apiBillingAddress?: BillingAddress,
	apiPrimaryPaymentSourceAddress?: PaymentAddress
): UseBillingAddressFormResult {
	const { translate } = useTranslation();

	const [usePaymentInfo, setUsePaymentInfo] = useState(false);
	const [useCompanyInfo, setUseCompanyInfo] = useState(false);

	const billingAddress = apiBillingAddress ?? emptyBillingAddress;
	const primaryPaymentSourceAddress =
		apiPrimaryPaymentSourceAddress ?? emptyPrimaryPaymentSourceAddress;

	useEffect(() => {
		let usePaymentInfo = false;
		const { firstName, lastName, city, country, line1, line2, state, zip } =
			primaryPaymentSourceAddress;

		const paymentAddressNotEmpty =
			firstName || lastName || city || country || line1 || line2 || state || zip;

		if (
			paymentAddressNotEmpty &&
			billingAddress.city === city &&
			billingAddress.country === country &&
			billingAddress.firstName === firstName &&
			billingAddress.lastName === lastName &&
			billingAddress.line1 === line1 &&
			(billingAddress.line2 === line2 || (!billingAddress.line2 && !line2)) &&
			(billingAddress.zip === zip || (!billingAddress.zip && !zip)) &&
			(billingAddress.state === state || (!billingAddress.state && !state))
		) {
			usePaymentInfo = true;
		}
		setUsePaymentInfo(usePaymentInfo);
	}, [billingAddress, primaryPaymentSourceAddress]);

	useEffect(() => {
		setUseCompanyInfo(!!billingAddress.company && !!billingAddress.vatNumber);
	}, [billingAddress]);

	const billingAddressValidationSchema = useMemo(
		() =>
			getBillingAddressSchema({
				usePaymentInfo,
				useCompanyInfo,
				translate
			}),
		[usePaymentInfo, useCompanyInfo]
	);

	const initialValues = useMemo(
		() =>
			usePaymentInfo
				? {
						company: billingAddress.company,
						vatNumber: billingAddress.vatNumber,
						...primaryPaymentSourceAddress
				  }
				: billingAddress,
		[usePaymentInfo, billingAddress, primaryPaymentSourceAddress]
	);

	return {
		data: {
			usePaymentInfo,
			useCompanyInfo,
			initialValues,
			billingAddressValidationSchema
		},
		emptyValues: {
			emptyBillingAddress,
			emptyPrimaryPaymentSourceAddress
		},
		toggleUsePaymentInfo: () => setUsePaymentInfo(value => !value),
		toggleUseCompanyInfo: () => setUseCompanyInfo(value => !value)
	};
}

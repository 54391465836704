import { sendRequest, USER_URL, PROJECTS_URL } from 'api/utils';

import {
	// GET
	GetCollaboratorsInput,
	GetCollaboratorsRequest,
	GetCollaboratorsResponse,
	// ADD
	AddCollaboratorsInput,
	AddCollaboratorsRequest,
	AddCollaboratorsResponse,
	// UPDATE PERMISSIONS
	UpdateCollaboratorsPermissionsInput,
	UpdateCollaboratorsPermissionsRequest,
	UpdateCollaboratorsPermissionsResponse,
	// REMOVE
	RemoveCollaboratorInput,
	RemoveCollaboratorRequest,
	RemoveCollaboratorResponse,
	// GET ORGANIZATIONS
	GetOrganizationsInput,
	GetOrganizationsRequest,
	GetOrganizationsResponse,
	GetOrganizationsOutput,
	// CREATE ORGANIZATION
	CreateOrganizationInput,
	CreateOrganizationRequest,
	CreateOrganizationResponse,
	CreateOrganizationOutput,
	// UPDATE ORGANIZATION
	UpdateOrganizationInput,
	UpdateOrganizationRequest,
	UpdateOrganizationResponse,
	UpdateOrganizationOutput,
	// DELETE ORGANIZATION
	DeleteOrganizationInput,
	DeleteOrganizationRequest,
	DeleteOrganizationResponse,
	// ADD COLLABORATOR TO ORGANIZATION
	AddCollaboratorsToProjectOrganizationsInput,
	AddCollaboratorsToProjectOrganizationsRequest,
	AddCollaboratorsToProjectOrganizationsResponse,
	// REMOVE COLLABORATORS FROM ORGANIZATIONS
	RemoveCollaboratorsFromProjectOrganizationsInput,
	RemoveCollaboratorsFromProjectOrganizationsRequest,
	RemoveCollaboratorsFromProjectOrganizationsResponse
} from './types';

import { Dictionary } from 'environment';
import { Collaborator, Organization } from 'store/data/collaborators';
import {
	LicenceLimitationErrorTypes,
	toggleFreeLicenceLimitationModalEvent
} from 'helpers/licences';
import {
	parseApiCollaborators,
	parseApiOrganizations,
	parseApiOrganization
} from 'store/data/collaborators/parsers';

const methods = {
	getCollaborators: 'getCollaborators',
	addCollaborators: 'shareProjectWithUser',
	updateCollaboratorsPermissions: 'updateUsersAccessRights',
	removeCollaborator: 'unShareProjectWithUser',
	// ORGANIZATIONS
	organization: {
		get: 'getProjectOrganizationCollaborators',
		create: 'createProjectOrganization',
		update: 'updateProjectOrganization',
		delete: 'deleteProjectOrganization',
		collaborator: {
			add: 'addCollaboratorsToProjectOrganizations',
			remove: 'removeCollaboratorsFromProjectOrganizations'
		}
	}
};

export default () => ({
	async getCollaborators(input: GetCollaboratorsInput): Promise<Collaborator[]> {
		const { data } = await sendRequest<GetCollaboratorsRequest, GetCollaboratorsResponse>(
			PROJECTS_URL,
			{
				method: methods.getCollaborators,
				...input
			}
		);

		if (data && data.collaborators) return parseApiCollaborators(data.collaborators);

		throw new Error(Dictionary.errors.api.projects.couldNoGetUsersSharedProject);
	},

	async addCollaborators(input: AddCollaboratorsInput): Promise<{
		notSharedWith: string[];
		notEligibleForSharing: string[] | undefined;
	}> {
		const { data } = await sendRequest<AddCollaboratorsRequest, AddCollaboratorsResponse>(
			USER_URL,
			{
				method: methods.addCollaborators,
				...input
			}
		);

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.projects.couldNotShareProject);
		}

		const { notSharedWith, notEligibleForSharing } = data;

		const response = {
			notSharedWith,
			notEligibleForSharing
		};

		return response;
	},

	async updateCollaboratorsPermissions(
		input: UpdateCollaboratorsPermissionsInput
	): Promise<void> {
		const { data } = await sendRequest<
			UpdateCollaboratorsPermissionsRequest,
			UpdateCollaboratorsPermissionsResponse
		>(PROJECTS_URL, {
			method: methods.updateCollaboratorsPermissions,
			...input
		});

		if (data.ledidiStatusCode === 'error.licence.other') {
			toggleFreeLicenceLimitationModalEvent().dispatch(
				LicenceLimitationErrorTypes.collaboratorShareProject
			);
			throw new Error();
		}

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.projects.couldNotUpdatePermissionsForUser);
		}
	},

	async removeCollaborator(input: RemoveCollaboratorInput): Promise<string[]> {
		const { data } = await sendRequest<RemoveCollaboratorRequest, RemoveCollaboratorResponse>(
			USER_URL,
			{
				method: methods.removeCollaborator,
				...input
			}
		);

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.projects.couldNotUnshareProject);
		}

		return data.notSharedWith;
	},

	async getOrganizations(input: GetOrganizationsInput): Promise<GetOrganizationsOutput> {
		const { data } = await sendRequest<GetOrganizationsRequest, GetOrganizationsResponse>(
			PROJECTS_URL,
			{
				method: methods.organization.get,
				...input
			}
		);

		// TODO: uncomment when BE sends `httpStatusCode`
		// if (data.httpStatusCode !== 200) throw new Error();

		if (!data.project) throw new Error();

		const defaultApiOrganization = data.project.organizations.find(
			organization => organization.isDefaultOrg
		);

		const output: GetOrganizationsOutput = {
			organizationsData: {
				organizations: parseApiOrganizations(data.project.organizations),
				default: {
					id: defaultApiOrganization
						? parseApiOrganization(defaultApiOrganization).id
						: ''
				}
			}
		};

		return output;
	},

	async createOrganization(input: CreateOrganizationInput): Promise<CreateOrganizationOutput> {
		const { data } = await sendRequest<CreateOrganizationRequest, CreateOrganizationResponse>(
			PROJECTS_URL,
			{
				method: methods.organization.create,
				...input
			}
		);

		if (data.httpStatusCode !== 200) throw new Error();

		const organization: Organization = parseApiOrganization({
			...data.project.organization,
			// TODO: remove when BE returns `organizationCollaborators`
			organizationCollaborators: input.project.organization.organizationCollaborators ?? []
		});

		const output: CreateOrganizationOutput = {
			organization
		};

		return output;
	},

	async updateOrganization(input: UpdateOrganizationInput): Promise<UpdateOrganizationOutput> {
		const { data } = await sendRequest<UpdateOrganizationRequest, UpdateOrganizationResponse>(
			PROJECTS_URL,
			{
				method: methods.organization.update,
				...input
			}
		);

		if (data.httpStatusCode !== 200) throw new Error();

		const output: UpdateOrganizationOutput = {
			organization: data.project.organization
		};

		return output;
	},

	async deleteOrganization(input: DeleteOrganizationInput): Promise<void> {
		const { data } = await sendRequest<DeleteOrganizationRequest, DeleteOrganizationResponse>(
			PROJECTS_URL,
			{
				method: methods.organization.delete,
				...input
			}
		);

		// TODO: its usually `200`
		if (data.httpStatusCode !== 204) throw new Error(data.message);
	},

	async addCollaboratorsToOrganizations(
		input: AddCollaboratorsToProjectOrganizationsInput
	): Promise<void> {
		const { data } = await sendRequest<
			AddCollaboratorsToProjectOrganizationsRequest,
			AddCollaboratorsToProjectOrganizationsResponse
		>(PROJECTS_URL, {
			method: methods.organization.collaborator.add,
			project: { ...input }
		});

		if (data.httpStatusCode !== 200) throw new Error();
	},

	async removeCollaboratorsFromOrganizations(
		input: RemoveCollaboratorsFromProjectOrganizationsInput
	): Promise<void> {
		const { data } = await sendRequest<
			RemoveCollaboratorsFromProjectOrganizationsRequest,
			RemoveCollaboratorsFromProjectOrganizationsResponse
		>(PROJECTS_URL, {
			method: methods.organization.collaborator.remove,
			project: { ...input }
		});

		// TODO: its usually `200`
		if (data.httpStatusCode !== 204) throw new Error();
	}
});

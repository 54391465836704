import { downloadFile } from 'helpers/generic';

export function exportSvg(id: string, fileName: string) {
	const root = document.getElementById(id);

	if (root) {
		const svg = root.querySelector('.zc-svg');

		if (svg) {
			svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
			downloadFile(svg.outerHTML, `${fileName}.svg`);
		}
	}
}

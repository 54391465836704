import { createSelector } from 'reselect';
import { values, keys } from 'lodash';

import { State, CollaboratorsViewOptions, CollaboratorsMap, Organization } from './types';
import { selectParams } from 'store/utils';
import { buildCollaboratorsDataFromStoreData, initCollaboratorsData } from 'helpers/collaborators';

/*
 * EXTRACTOR FUNCTIONS
 */

function getProjectId({ projectId }: State) {
	return projectId;
}

function getByProjectId({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

function getSelectedOrganizationsById({ selectedOrganizationsById }: State) {
	return selectedOrganizationsById;
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectCollaboratorsData = createSelector([getByProjectId], byProjectId => {
	if (byProjectId) return buildCollaboratorsDataFromStoreData(byProjectId.data);

	return initCollaboratorsData();
});

export const selectProjectOrganizations = createSelector([getByProjectId], collaboratorsData => {
	if (!collaboratorsData) return [];
	return values(collaboratorsData.data.organizations.byId);
});

//
export const selectUserAssignableOrganizations = createSelector(
	[getByProjectId, (state: State, userId: string | null) => userId],
	(byProjectId, userId) => {
		const organizations: Organization[] = [];
		if (byProjectId && userId) {
			const { organizationsMap } = buildCollaboratorsDataFromStoreData(byProjectId.data);
			keys(organizationsMap).forEach(key => {
				const org = organizationsMap[key];
				if (!org.collaborators.includes(userId)) organizations.push(org);
			});
		}
		return organizations;
	}
);

type AllCollaboratorsParams = {
	projectIds: string[];
};

export const selectAllCollaborators = createSelector(
	[
		(state: State) => state.byProjectId,
		(_: State, params: AllCollaboratorsParams) => selectParams.encode(params)
	],
	(byProjectId, params) => {
		const { projectIds } = selectParams.decode<AllCollaboratorsParams>(params);
		const data = projectIds.reduce((acc, projectId) => {
			if (projectId in byProjectId) {
				const { data } = byProjectId[projectId];
				const { collaborators } = data;

				acc = { ...acc, ...collaborators.byId };
			}
			return acc;
		}, {} as CollaboratorsMap);

		return data;
	}
);

export const selectOrganizationsById = createSelector(
	[getProjectId, getSelectedOrganizationsById],
	(projectId, selectedOrganizationsById) => {
		if (projectId && selectedOrganizationsById[projectId]) {
			return selectedOrganizationsById[projectId];
		}

		return [];
	}
);

export const selectCollaboratorOrganizations = createSelector(
	[getByProjectId, (state: State, userId?: string) => userId],
	(collaboratorsData, userId) => {
		const orgs: Organization[] = [];
		if (collaboratorsData && userId) {
			values(collaboratorsData?.data.organizations.byId).forEach(organization => {
				if (organization.collaborators.includes(userId)) return orgs.push(organization);
			});
		}
		return orgs;
	}
);

type SelectOrganizationByIdParams = {
	id?: string;
};

export const selectOrganizationById = createSelector(
	[
		getByProjectId,
		(_: State, params: SelectOrganizationByIdParams) => selectParams.encode(params)
	],
	(collaboratorsData, params) => {
		const { id } = selectParams.decode<SelectOrganizationByIdParams>(params);
		if (collaboratorsData && id) {
			return collaboratorsData.data.organizations.byId[id];
		}
		return undefined;
	}
);

export const selectCollaboratorsFetched = createSelector([getByProjectId], byProjectId => {
	if (byProjectId) return byProjectId.fetched;

	return false;
});

export const selectCollaboratorsViewOption = createSelector([getByProjectId], byProjectId => {
	if (byProjectId) return byProjectId.metadata.viewOption;

	return CollaboratorsViewOptions.TABLE;
});

export const selectDefaultOrganizationId = createSelector([getByProjectId], byProjectId => {
	if (byProjectId) return byProjectId.data.organizations.default.id;

	return null;
});

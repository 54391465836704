import { useState, useEffect, FormEvent } from 'react';

import { Typography } from 'components/UI/Typography';
import { CreditCardInterface, CreditCardInterfaceWithValidation } from 'store/account/payments';

import { getCreditCardValidationSchema } from 'helpers/subscription';
import { Svgs } from 'environment';

import { RedirrectContainer } from './AddCreditCardModal.style';
import { newCreditCard } from 'consts';
import { AddCreditCardForm } from '.';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Icon } from 'components/UI/Icons';
import { useTranslation, useAddSubscriptionPaymentMethod } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { usePrevious } from 'hooks/utils';

interface Props {
	creditCard: CreditCardInterface | null;
	shouldProcessCard: boolean;
	onCreditCardAdded?: () => void;
	setShouldProcessCard: (shouldProcess: boolean) => void;
	onClose: () => void;
}

export function AddCreditCardModal({
	creditCard,
	shouldProcessCard,
	setShouldProcessCard,
	onCreditCardAdded,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const [recurrentBilling, setRecurrentBilling] = useState(false);

	const validationSchema = getCreditCardValidationSchema({ translate });

	const [{ loading: addPaymentMethodLoading, error: addPaymentMethodError }] =
		useAddSubscriptionPaymentMethod();

	const newCreditCardWithCBValidation: CreditCardInterfaceWithValidation = creditCard
		? {
				...creditCard,
				cardValid: false
		  }
		: newCreditCard;

	const {
		Form: CreditCardForm,
		formProviderProps,
		FormProvider,
		isValid,
		isDirtyAndValid
	} = useReactForm({
		initialValues: newCreditCardWithCBValidation,
		validationSchema
	});

	/**
	 * After AddubscriptionPaymentMethod is called and the response is not an error, close the modal
	 */
	const prevAddPaymentMethodLoading = usePrevious(addPaymentMethodLoading);
	useEffect(() => {
		if (!addPaymentMethodLoading && prevAddPaymentMethodLoading && !addPaymentMethodError) {
			onClose();
		}
	}, [addPaymentMethodLoading, addPaymentMethodError]);

	function submitCreditCard(event?: FormEvent<HTMLFormElement>) {
		event && event.preventDefault();
		if (recurrentBilling && isValid && isDirtyAndValid) {
			setShouldProcessCard(true);
		}
	}

	return (
		<div>
			<Modal
				visible
				title={
					creditCard
						? translate(({ accountUM }) => accountUM.payment.editCard)
						: translate(({ accountUM }) => accountUM.payment.addNewCard)
				}
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					onClick: submitCreditCard,
					loading: shouldProcessCard,
					disabled: shouldProcessCard || !recurrentBilling || !isValid || !isDirtyAndValid
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onClose,
					disabled: shouldProcessCard
				}}
				close
				closeOnBackdrop
				onClose={onClose}
				enterAsPrimaryOnClick
			>
				<FormProvider {...formProviderProps}>
					<CreditCardForm onSubmit={submitCreditCard}>
						<AddCreditCardForm
							recurrentBilling={recurrentBilling}
							shouldProcessCard={shouldProcessCard}
							onCreditCardAdded={onCreditCardAdded}
							onClose={onClose}
							setShouldProcessCard={setShouldProcessCard}
							setRecurrentBilling={setRecurrentBilling}
						/>
					</CreditCardForm>
				</FormProvider>
				<Spacer size={s => s.s} />
				<RedirrectContainer>
					<Icon svg={Svgs.Information} />
					<Typography.Caption paddingOffset={{ left: 0.8 }}>
						{translate(({ creditCardForm }) => creditCardForm.redirectionInfoMessage)}
					</Typography.Caption>
				</RedirrectContainer>
			</Modal>
		</div>
	);
}

import { ROUTES, ROUTE_QUERY } from 'types/navigation';
import { NavigationRoutes } from 'hooks/navigation/useNavigation';

import { customEventFactory } from './events';

export const navigationHubEvent = () =>
	customEventFactory<{
		navigate?: (input: {
			routes: NavigationRoutes;
			promOrProject: 'proms' | 'projects';
		}) => string;
		replace?: (input: {
			routes: NavigationRoutes;
			promOrProject: 'proms' | 'projects';
		}) => string;
	}>('navigation-hub');

export function openNewTabSubscriptionPage(openUpgrade?: boolean) {
	if (openUpgrade) window.open(`${ROUTES.AccountUMSubscription}?${ROUTE_QUERY.Upgrade}=true`);
	else window.open(`${ROUTES.AccountUMSubscription}`);
}

export function openBillingPage() {
	window.open(`${ROUTES.AccountUMBillingHistory}`);
}

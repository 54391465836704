import { UserDetails } from 'api/data/accounts';
import { OperationResult } from 'hooks/store/types';
import {
	InvitedUser,
	selectInvitedByEmails,
	selectNotAllowedDueToDomainConstraintViolation,
	selectUsersByEmails
} from 'store/data/accounts';
import { ActionTypes, getUsersByEmails } from 'store/data/accounts';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

interface Data {
	newUsersData?: UserDetails[];
	invitedUsersByEmail?: InvitedUser[];
	notAllowedDueToDomainConstraintViolation: string[];
}

export function useUsersByEmails(
	emails: string[]
): OperationResult<Data, (emails: string[], projectID_?: string) => void> {
	const dispatch = useDispatch();

	const projectId = useSelector(state => state.data.projects.projectId);
	const newUsersData = useSelector(state =>
		selectUsersByEmails(state.data.accounts, {
			emails
		})
	);
	const invitedUsersByEmail = useSelector(state =>
		selectInvitedByEmails(state.data.accounts, {
			emails
		})
	);
	const notAllowedDueToDomainConstraintViolation = useSelector(state =>
		selectNotAllowedDueToDomainConstraintViolation(state.data.accounts)
	);
	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_USERS_BY_EMAIL);

	function handler(emails: string[], projectID_?: string) {
		if (projectId || projectID_) {
			resetError();
			if (projectId) dispatch(getUsersByEmails(projectId, emails));
			if (projectID_) dispatch(getUsersByEmails(projectID_, emails));
		}
	}

	return [
		{
			loading,
			error,
			data: { newUsersData, invitedUsersByEmail, notAllowedDueToDomainConstraintViolation }
		},
		handler
	];
}

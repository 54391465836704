import { useEffect, useState } from 'react';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { Loader } from 'components/UI/Loader';
import { Tag } from 'components/UI/Tags';

import { GenericMap } from 'types/index';
import { EnterpriseOrganization } from 'store/account/admin';
import { Svgs } from 'environment';

import { Column, Domain, Organization } from './OrganizationsList.style';
import {
	useTranslation,
	useAdminEnterpriseOrganizationId,
	useRemoveDomains,
	useOrganizationDomains
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { usePrevious } from 'hooks/utils';

interface Props {
	enterpriseOrganizations: GenericMap<EnterpriseOrganization> | undefined;
	setDomainsModalVisible: () => void;
}
export function OrganizationsList({ enterpriseOrganizations, setDomainsModalVisible }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [organizationId, setOrganizationId] = useAdminEnterpriseOrganizationId();
	const [checkedDomains, setCheckedDomains] = useState<GenericMap<boolean>>({});

	const [{ loading: removingDomains, error: errorRemovingDomains }, removeDomains] =
		useRemoveDomains();

	const [
		{
			data: { allowed, blocked },
			loading: loadingDomains
		},
		getOrganizationDomains
	] = useOrganizationDomains();

	const previousOrganizationId = usePrevious(organizationId);
	useEffect(() => {
		if (organizationId && organizationId !== previousOrganizationId) setCheckedDomains({});
	}, [organizationId]);

	const wasRemovingDomains = usePrevious(removingDomains);
	useEffect(() => {
		if (wasRemovingDomains && !removingDomains && !errorRemovingDomains) {
			setNotification({
				message: translate(({ admin }) => admin.enterprise.api.domainsRemovedSuccessfully)
			});
			setCheckedDomains({});
		}
	}, [removingDomains]);

	function fetchEnterpriseDomanins(organization: EnterpriseOrganization) {
		setOrganizationId(organization.subscriptionId);
		getOrganizationDomains();
	}

	function handleCheckboxChange(domain: string) {
		setCheckedDomains(state => ({
			...state,
			[domain]: !checkedDomains[domain]
		}));
	}

	function handleRemoveDomains() {
		const domainsToRemove = Object.keys(checkedDomains).filter(
			key => checkedDomains[key] === true
		);
		removeDomains(domainsToRemove);
	}

	const showDeleteDomainsIcon = !!Object.values(checkedDomains).reduce(
		(prevDomain, domain) => prevDomain + (domain === true ? 1 : 0),
		0
	);

	return (
		<Flex fullWidth>
			<Column>
				<Typography.H4 ellipsis style={{ height: '4rem' }}>
					{translate(dict => dict.admin.enterprise.organizationsList.enterpriseHeader)}
				</Typography.H4>
				<Spacer size={s => s.m} />
				{enterpriseOrganizations &&
					Object.values(enterpriseOrganizations).map(organization => (
						<Organization
							key={organization.subscriptionId}
							onClick={() => fetchEnterpriseDomanins(organization)}
							selected={organizationId === organization.subscriptionId}
							ellipsis
						>
							{organization.organizationName}
						</Organization>
					))}
			</Column>
			<Column last>
				{organizationId ? (
					<>
						<Flex justify={j => j.between}>
							<Typography.H4 ellipsis style={{ height: '4rem' }}>
								{translate(
									dict => dict.admin.enterprise.organizationsList.domainsHeader
								)}
							</Typography.H4>
							<Flex>
								{showDeleteDomainsIcon && (
									<Icon
										title={translate(
											dict =>
												dict.admin.enterprise.organizationsList
													.removeSelected
										)}
										svg={Svgs.Delete}
										marginOffset={{ right: 1 }}
										variant={v => v.buttonActive}
										onClick={handleRemoveDomains}
									/>
								)}
								<Icon
									title={translate(
										dict => dict.admin.enterprise.organizationsList.addDomains
									)}
									svg={Svgs.Add}
									marginOffset={{ right: 1 }}
									variant={v => v.buttonActive}
									onClick={setDomainsModalVisible}
								/>
							</Flex>
						</Flex>
						<Spacer size={s => s.m} />
						{loadingDomains ? (
							<Loader center />
						) : (
							<>
								{allowed &&
									allowed.map(domain => (
										<Domain key={domain}>
											<Flex fullWidth>
												<div>
													<Checkbox
														marginOffset={{ right: 1 }}
														checked={!!checkedDomains[domain]}
														onClick={() => handleCheckboxChange(domain)}
													/>
												</div>
												<Typography.Paragraph ellipsis>
													{domain}
												</Typography.Paragraph>
											</Flex>
											<Tag
												title={translate(
													dict =>
														dict.admin.enterprise.organizationsList
															.domainAllowed
												)}
												vibrantGreen
											/>
										</Domain>
									))}
								{blocked &&
									blocked.map(domain => (
										<Domain key={domain}>
											<Flex fullWidth>
												<div>
													<Checkbox
														marginOffset={{ right: 1 }}
														checked={!!checkedDomains[domain]}
														onClick={() => handleCheckboxChange(domain)}
													/>
												</div>
												<Typography.Paragraph ellipsis>
													{domain}
												</Typography.Paragraph>
											</Flex>
											<Tag
												title={translate(
													dict =>
														dict.admin.enterprise.organizationsList
															.domainBlocked
												)}
												active
											/>
										</Domain>
									))}
							</>
						)}
						{/* No domains defined */}
						{!allowed.length && !blocked.length && !loadingDomains && (
							<Flex
								justify={j => j.center}
								align={a => a.center}
								fullWidth
								style={{ height: '100%' }}
							>
								<Typography.Paragraph ellipsis>
									{translate(
										dict =>
											dict.admin.enterprise.organizationsList.noDomainsDefined
									)}
								</Typography.Paragraph>
							</Flex>
						)}
					</>
				) : (
					//  No organization selected
					<Flex
						justify={j => j.center}
						align={a => a.center}
						fullWidth
						style={{ height: '100%' }}
					>
						<Typography.Paragraph ellipsis>
							{translate(
								dict =>
									dict.admin.enterprise.organizationsList.noOrganizationSelected
							)}
						</Typography.Paragraph>
					</Flex>
				)}
			</Column>
		</Flex>
	);
}

import styled from 'styled-components';

import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	align-items: center;

	> div:not(:last-child) {
		margin-right: 0.4rem;
	}
`;

export const MoreAvatarsContainer = styled.div`
	font-weight: bold;
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	color: ${Colors.white};
	background: ${Colors.text.disabled};
	font-size: 1rem;
	padding-top: 0.6rem;
	padding-left: 0.6rem;
`;

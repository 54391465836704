import { useContext } from 'react';

import { FormDesignerDrawerContext } from 'contexts';

export function useFormDesignerDrawerContext() {
	const context = useContext(FormDesignerDrawerContext);

	if (context === undefined) {
		throw new Error(
			'useFormDesignerDrawerContext must be used within a `FormDesignerDrawerContext.Provider`'
		);
	}

	return context;
}

import { SYSTEM_GENERATED_STATUS_NAME } from 'consts';
import produce from 'immer';

import { StatusTypeAccess } from '../collaborators';
import { Actions as ProjectActions, ActionTypes as ProjectActionTypes } from '../projects/types';
import { Actions as StatusesActions, ActionTypes as StatusesActionTypes } from '../statuses/types';
import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (
	state: State = initialState,
	action: Actions | ProjectActions | StatusesActions
): State => {
	switch (action.type) {
		case ProjectActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { projectRoles } = draft;

				const { byProjectId } = projectRoles;

				if (projectId && !byProjectId[projectId]) {
					// INITIALIZE STRUCTURE
					byProjectId[projectId] = {
						ids: [],
						fetched: false,
						metadata: {
							searchTerm: ''
						}
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.CREATE_TEMPLATE_ROLE: {
			const { templateRole } = action.payload;

			return produce(state, draft => {
				const { templateRoles } = draft;

				const { byId, ids } = templateRoles;

				byId[templateRole.id] = templateRole;

				ids.owned.push(templateRole.id);
			});
		}

		case ActionTypes.UPDATE_TEMPLATE_ROLE: {
			const { templateRole } = action.payload;

			return produce(state, draft => {
				const { templateRoles } = draft;

				const { byId } = templateRoles;

				byId[templateRole.id] = templateRole;
			});
		}

		case ActionTypes.DELETE_TEMPLATE_ROLE: {
			const { templateRoleId } = action.payload;

			return produce(state, draft => {
				const { templateRoles } = draft;

				const { byId, ids } = templateRoles;

				delete byId[templateRoleId];

				// REMOVE FROM OWNED
				const ownedIndex = ids.owned.indexOf(templateRoleId);

				if (ownedIndex > -1) {
					ids.owned.splice(ownedIndex, 1);
				}
			});
		}

		case ActionTypes.GET_TEMPLATE_ROLES: {
			const { ownedTemplateRoles, sharedTemplateRoles, publicTemplateRoles } = action.payload;

			return produce(state, draft => {
				const { templateRoles } = draft;

				const { byId, ids } = templateRoles;

				ownedTemplateRoles.forEach(ownedRole => {
					if (!byId[ownedRole.id]) {
						byId[ownedRole.id] = ownedRole;
						ids.owned.push(ownedRole.id);
					}
				});

				sharedTemplateRoles.forEach(sharedRole => {
					if (!byId[sharedRole.id]) {
						byId[sharedRole.id] = sharedRole;
						ids.shared.push(sharedRole.id);
					}
				});

				publicTemplateRoles.forEach(publicRole => {
					if (!byId[publicRole.id]) {
						byId[publicRole.id] = publicRole;
						ids.public.push(publicRole.id);
					}
				});

				templateRoles.fetched = true;
			});
		}
		case ActionTypes.GET_ROLE_TEMPLATE_SHARE_LIST: {
			const { projects, users, templateRoleId } = action.payload;

			return produce(state, draft => {
				const roleTemplate = draft.templateRoles.byId[templateRoleId];

				if (roleTemplate) {
					const { shareList } = roleTemplate;

					shareList.projectShareList.current = projects;
					shareList.projectShareList.initial = projects;

					shareList.userShareList.current = users;
					shareList.userShareList.initial = users;

					shareList.fetched = true;
				}
			});
		}

		case ActionTypes.SHARE_ROLE_TEMPLATE_WITH_INSTANCE: {
			const { instanceId, userShare, templateRoleId } = action.payload;

			return produce(state, draft => {
				const roleTemplate = draft.templateRoles.byId[templateRoleId];

				if (!roleTemplate) return;

				const { shareList } = roleTemplate;

				const { userShareList, projectShareList } = shareList;

				const userAddedAlready = userShareList.current.find(
					userId => userId === instanceId
				);
				const projectAddedAlready = projectShareList.current.find(
					projectId => projectId === instanceId
				);

				if (userAddedAlready || projectAddedAlready) return;

				if (userShare) {
					userShareList.current.unshift(instanceId);
				} else {
					projectShareList.current.unshift(instanceId);
				}
			});
		}

		case ActionTypes.UNSHARE_ROLE_TEMPLATE_WITH_INSTANCE: {
			const { instanceId, userShare, templateRoleId } = action.payload;

			return produce(state, draft => {
				const roleTemplate = draft.templateRoles.byId[templateRoleId];

				if (!roleTemplate) return;

				const { shareList } = roleTemplate;

				const { userShareList, projectShareList } = shareList;

				if (userShare) {
					const userIndex = userShareList.current.indexOf(instanceId);

					if (userIndex > -1) {
						userShareList.current.splice(userIndex, 1);
					}
				} else {
					const projectIndex = projectShareList.current.indexOf(instanceId);

					if (projectIndex > -1) {
						projectShareList.current.splice(projectIndex, 1);
					}
				}
			});
		}

		case ActionTypes.RESET_ROLE_TEMPLATE_SHARE_LIST: {
			const { templateRoleId } = action.payload;

			return produce(state, draft => {
				const {
					templateRoles: { byId }
				} = draft;
				const roleTemplate = byId[templateRoleId];
				if (roleTemplate) {
					const { shareList } = roleTemplate;

					const { userShareList, projectShareList } = shareList;

					userShareList.current = userShareList.initial;
					projectShareList.current = projectShareList.initial;

					shareList.fetched = false;
				}
			});
		}

		case ActionTypes.RESET_ROLE_TEMPLATE_SHARE_LIST_FETCHED: {
			const { templateRoleId } = action.payload;

			return produce(state, draft => {
				const {
					templateRoles: { byId }
				} = draft;
				const roleTemplate = byId[templateRoleId];
				if (roleTemplate) {
					const { shareList } = roleTemplate;

					shareList.fetched = false;
				}
			});
		}

		case ActionTypes.CHANGE_TEMPLATE_ROLE_PAGE_TAB: {
			const { activeTab } = action.payload;

			return produce(state, draft => {
				const { templateRoles } = draft;

				const { metadata } = templateRoles;

				metadata.activeTab = activeTab;
			});
		}

		case ActionTypes.GET_ROLES: {
			const { projectId, roles } = action.payload;

			return produce(state, draft => {
				const { projectRoles } = draft;
				const { byProjectId } = projectRoles;

				const projectData = byProjectId[projectId];

				const roleIds: string[] = [];

				roles.forEach(role => {
					roleIds.push(role.id);
					projectRoles.byId[role.id] = role;
				});

				projectData.ids = roleIds;
				projectData.fetched = true;
			});
		}

		case ActionTypes.CREATE_ROLE: {
			const { projectId, role } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, projectRoles } = draft;
				const { byId, byProjectId } = projectRoles;

				if (projectId !== currentProjectId) return;

				const projectData = byProjectId[projectId];

				byId[role.id] = role;
				projectData.ids.push(role.id);
			});
		}

		case ActionTypes.UPDATE_ROLE: {
			const { projectId, role } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, projectRoles } = draft;
				const { byId } = projectRoles;

				if (projectId !== currentProjectId) return;

				byId[role.id] = role;
			});
		}

		case ActionTypes.DELETE_ROLE: {
			const { projectId, roleId } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, projectRoles } = draft;
				const { byId, byProjectId } = projectRoles;

				if (projectId !== currentProjectId) return;

				const projectData = byProjectId[projectId];

				projectData.ids = projectData.ids.filter(id => id !== roleId);

				delete byId[roleId];
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case StatusesActionTypes.CREATE_STATUS: {
			const { status } = action.payload;

			return produce(state, draft => {
				const { projectId, projectRoles } = draft;
				const { byId, byProjectId } = projectRoles;

				if (projectId && projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					projectData.ids.forEach(roleId => {
						const role = byId[roleId];

						const { permissions } = role;

						// INIT STRUCTURE
						if (!permissions.statusTypeAccesses) {
							permissions.statusTypeAccesses = [];

							const noStatus: StatusTypeAccess = {
								statusTypeVariableName: SYSTEM_GENERATED_STATUS_NAME,
								viewData: false,
								editData: false,
								setStatus: false
							};

							permissions.statusTypeAccesses.push(noStatus);
						}

						const statusAccess: StatusTypeAccess = {
							statusTypeVariableName: status.name,
							viewData: false,
							editData: false,
							setStatus: false
						};

						permissions.statusTypeAccesses.push(statusAccess);
					});
				}
			});
		}

		case StatusesActionTypes.CREATE_STATUSES: {
			const { statuses } = action.payload;

			return produce(state, draft => {
				const { projectId, projectRoles } = draft;
				const { byId, byProjectId } = projectRoles;

				if (projectId && projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					projectData.ids.forEach(roleId => {
						const role = byId[roleId];

						const { permissions } = role;

						// INIT STRUCTURE
						if (!permissions.statusTypeAccesses) {
							permissions.statusTypeAccesses = [];

							const noStatus: StatusTypeAccess = {
								statusTypeVariableName: SYSTEM_GENERATED_STATUS_NAME,
								viewData: false,
								editData: false,
								setStatus: false
							};

							permissions.statusTypeAccesses.push(noStatus);
						}

						statuses.forEach(status => {
							const statusAccess: StatusTypeAccess = {
								statusTypeVariableName: status.name,
								viewData: false,
								editData: false,
								setStatus: false
							};

							permissions.statusTypeAccesses?.push(statusAccess);
						});
					});
				}
			});
		}

		case StatusesActionTypes.DELETE_STATUS: {
			const { statusName } = action.payload;

			return produce(state, draft => {
				const { projectId, projectRoles } = draft;
				const { byId, byProjectId } = projectRoles;

				if (projectId && projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					projectData.ids.forEach(roleId => {
						const role = byId[roleId];

						const { permissions } = role;

						if (!permissions.statusTypeAccesses) return;

						permissions.statusTypeAccesses = permissions.statusTypeAccesses.filter(
							statusAccess => statusAccess.statusTypeVariableName !== statusName
						);

						const hasOneStatusAccess = permissions.statusTypeAccesses.length === 1;

						if (hasOneStatusAccess) {
							const statusAccess = permissions.statusTypeAccesses[0];

							const isSystemGenerated =
								statusAccess.statusTypeVariableName ===
								SYSTEM_GENERATED_STATUS_NAME;

							if (isSystemGenerated) permissions.statusTypeAccesses = [];
						}
					});
				}
			});
		}

		default: {
			return state;
		}
	}
};

import { useState } from 'react';

import { Patient } from 'api/data/patients';
import {
	PatientsHeader,
	DistributionList,
	PatientList,
	PatientModal,
	DistributionsTable
} from 'components/Proms';
import { Container } from './PatientsPage.style';
import { Grid } from 'components/UI/Grid';
import { PromptToSave } from 'components/UI/PromptToSave';
import { Suspend } from 'components/UI/Suspend';
import { isVariablePromGenerated } from 'helpers/variables';
import {
	useTranslation,
	usePromManualDistribution,
	useDiscardDistributionsChanges,
	usePatients,
	useForms,
	useDistributions,
	useVariables,
	useUpdateDistributions,
	useDistributionsHaveChanges,
	useProjectId,
	usePermissions
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import { Button } from 'components/UI/Interactables/Button';
import { Svgs } from 'environment';
import { Flex } from 'components/UI/Flex';

export const patientDefaultValue: Patient = {
	patientId: 0,
	firstName: '',
	lastName: '',
	startTime: '',
	emailAddress: '',
	phoneNumber: '',
	active: true,
	patientGivenId: '',
	phoneCountryCode: ''
};

export function PatientsPage() {
	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const { navigate, routes } = useNavigation();
	const [projectId] = useProjectId();

	const { isPromManualDistribution } = usePromManualDistribution();

	const resetDistributions = useDiscardDistributionsChanges();
	const [{ loading: loadingPatients, data: patients }] = usePatients({
		lazy: isPromManualDistribution
	});
	const { hasVariablesWriteAccess } = usePermissions();
	const [{ data: forms, loading: loadingForms, fetched: areFormsFetched }] = useForms();

	const [{ data: distributionsIds, loading: loadingDistributions }] = useDistributions();
	const [
		{
			data: { variables },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true });
	const [{ loading: savingDistributions, error: errorSavingDistributions }, updateDistributions] =
		useUpdateDistributions();
	const distributionsHaveChanges = useDistributionsHaveChanges();

	useCompletedAction(savingDistributions, errorSavingDistributions, () =>
		setNotification({
			message: translate(
				dict => dict.promsPatients.distributionList.notifications.distributionListUpdated
			)
		})
	);

	function onAddNewPatient() {
		setSelectedPatient({ ...patientDefaultValue });
	}

	function onSaveHandler() {
		updateDistributions();
	}

	function onDiscard() {
		resetDistributions();
	}

	function navigateToVariablesPage() {
		if (projectId) navigate(routes.proms.variables.view(projectId));
	}

	// System generates variables for proms - see comment for 3050
	const hasVariablesDefined =
		variables.filter(v => !isVariablePromGenerated({ specialization: v.specialization }))
			.length > 0;

	const loading = isPromManualDistribution
		? loadingDistributions || loadingVariables || loadingForms
		: loadingPatients || loadingDistributions || loadingVariables || loadingForms;

	const immediate = isPromManualDistribution
		? !areVariablesFetched || !areFormsFetched || !distributionsIds
		: !patients || !distributionsIds || !areVariablesFetched || !areFormsFetched;

	return (
		<>
			<PatientsHeader
				hasData={hasVariablesDefined && !!patients && !immediate}
				onAddNewPatient={onAddNewPatient}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{hasVariablesDefined ? (
						<Container>
							<DistributionList
								ids={distributionsIds ?? []}
								forms={forms}
								saving={savingDistributions}
								unsavedChanges={distributionsHaveChanges}
								hasVariablesDefined={hasVariablesDefined}
								handleSubmit={onSaveHandler}
							/>

							{/* PROM - AUTOMATIC DISTRIBUTION */}
							{!isPromManualDistribution && (
								<PatientList
									patients={patients ?? []}
									filteredEntriesCount={1}
									setSelectedPatient={setSelectedPatient}
								/>
							)}

							{/* PROM - MANUAL DISTRIBUTION */}
							{isPromManualDistribution && <DistributionsTable />}

							{selectedPatient && (
								<PatientModal
									patient={selectedPatient}
									onClose={() => setSelectedPatient(null)}
								/>
							)}
						</Container>
					) : (
						<Flex align={a => a.center} column>
							<Spacer size={s => s.xxl} />
							<Svgs.EmptyStatesNoVariable style={{ minHeight: 240 }} />
							<Spacer size={s => s.m} />
							<Typography.H3>
								{translate(dict => dict.variableList.noVariables)}
							</Typography.H3>

							{hasVariablesWriteAccess && (
								<>
									<Spacer size={s => s.xs} />
									<Typography.Paragraph>
										{translate(
											dict => dict.promsDashboard.startCreatingFirstVariable
										)}
										<Button
											title={translate(
												dict => dict.promsDashboard.variablesPage
											)}
											variant={v => v.link}
											onClick={navigateToVariablesPage}
										/>
									</Typography.Paragraph>
								</>
							)}
						</Flex>
					)}
				</Grid.Container>

				<PromptToSave
					saving={savingDistributions}
					when={distributionsHaveChanges}
					onSave={onSaveHandler}
					onDiscard={onDiscard}
				/>
			</Suspend>
		</>
	);
}

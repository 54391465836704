import { useState } from 'react';

import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { useTranslation } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';
import { toValidDate } from 'helpers/dateFormat';

export function TimePassedCounter({ date }: { date: string }) {
	const { translate } = useTranslation();

	const [, setTime] = useState(0);

	const timestamp = toValidDate(date).getTime();

	useEffectOnce(() => {
		const timer = setInterval(() => setTime(state => state + 1), 1000);

		return () => clearInterval(timer);
	});

	/**
	 * Returns a proper text for how much time passed from the given timestamp
	 * The time passed calculated has the following rule:
	 * - if more than two thirds (2/3) of the 'time unit' already passed,
	 * we round to the upper number. For example:
	 * 8 days will be 'one week ago',
	 * 10 days will still be 'one week ago'
	 * 12 days will be '2 weeks ago'
	 * 40 days will be 'one month ago',
	 * 51 days will be '2 months ago'
	 *
	 */
	function timeCountAndUnit() {
		const secondsAgo = Math.floor((new Date().getTime() - timestamp) / 1000);
		const roundRatio = 2 / 3; // rounds up the number if the decimal is greater than roundRatio

		if (secondsAgo < 60) {
			return translate(dict => dict.notifications.timeUnit.secondsAgo, false, {
				seconds: secondsAgo
			});
		} else if (secondsAgo <= 100) {
			return translate(dict => dict.notifications.timeUnit.minuteAgo);
		} else if (secondsAgo < 60 * 60) {
			const minutesAgo = secondsAgo / 60;
			const floorNr = Math.floor(minutesAgo);
			const decimalNr = minutesAgo - floorNr;
			return translate(dict => dict.notifications.timeUnit.minutesAgo, false, {
				minutes: decimalNr <= roundRatio ? floorNr : floorNr + 1
			});
		} else if (secondsAgo <= 60 * (60 + roundRatio * 60)) {
			return translate(dict => dict.notifications.timeUnit.hourAgo);
		} else if (secondsAgo < 60 * 60 * 24) {
			const hoursAgo = secondsAgo / (60 * 60);
			const floorNr = Math.floor(hoursAgo);
			const decimalNr = hoursAgo - floorNr;
			return translate(dict => dict.notifications.timeUnit.hoursAgo, false, {
				hours: decimalNr <= roundRatio ? floorNr : floorNr + 1
			});
		} else if (secondsAgo <= 60 * 60 * (24 + roundRatio * 24)) {
			return translate(dict => dict.notifications.timeUnit.dayAgo);
		} else if (secondsAgo < 60 * 60 * 24 * 7) {
			const daysAgo = secondsAgo / (60 * 60 * 24);
			const floorNr = Math.floor(daysAgo);
			const decimalNr = daysAgo - floorNr;
			return translate(dict => dict.notifications.timeUnit.daysAgo, false, {
				days: decimalNr <= roundRatio ? floorNr : floorNr + 1
			});
		} else if (secondsAgo <= 60 * 60 * 24 * (7 + roundRatio * 7)) {
			return translate(dict => dict.notifications.timeUnit.weekAgo);
		} else if (secondsAgo < 60 * 60 * 24 * 30.5) {
			const weeksAgo = secondsAgo / (60 * 60 * 24 * 7);
			const floorNr = Math.floor(weeksAgo);
			const decimalNr = weeksAgo - floorNr;
			return translate(dict => dict.notifications.timeUnit.weeksAgo, false, {
				weeks: decimalNr <= roundRatio ? floorNr : floorNr + 1
			});
		} else if (secondsAgo <= 60 * 60 * 24 * (30.5 + roundRatio * 30.5)) {
			return translate(dict => dict.notifications.timeUnit.monthAgo);
		} else {
			const monthsAgo = secondsAgo / (60 * 60 * 24 * 30.5);
			const floorNr = Math.floor(monthsAgo);
			const decimalNr = monthsAgo - floorNr;
			return translate(dict => dict.notifications.timeUnit.monthsAgo, false, {
				months: decimalNr <= roundRatio ? floorNr : floorNr + 1
			});
		}
	}

	return (
		<Typography.Hint color={Colors.text.disabled} marginOffset={{ top: 0.1 }}>
			{timeCountAndUnit()}
		</Typography.Hint>
	);
}

import { Controller, FieldError, Noop, UseFormReturn } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DynamicFormValue, DynamicFormValues } from 'store/data/entries';
import { HTMLInput, InputType } from 'types/index';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { Input } from 'components/UI/Inputs/Input';
import {
	entryFormReadOnlyModalEvent,
	parseDateToAPIStorage,
	sanitizeTimeDurationInput,
	setEntryFormFieldValue,
	validateTimeDurationInput,
	zeroPadDate
} from 'helpers/entries';
import SliderInput from '../FormVariableCategory/SliderInput';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { FormElement } from 'store/data/forms';
import { useTimeDurationEntries } from 'hooks/store';

interface GenericInputProps {
	name: string;
	type: InputType;
	disabled: boolean;
	required?: boolean;
	readOnly?: boolean;
	readOnlyVisual?: boolean;
	uniqueError?: string;
	borderError?: boolean;
	value?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
	element: FormElement;
	variable: Variable;
	label?: string;
	labelHint?: string;
	placeholder?: string;
	tooltipComponent?: React.ReactNode;
}

export function GenericInput({
	name,
	type,
	disabled,
	required,
	readOnly,
	readOnlyVisual,
	uniqueError,
	borderError,
	value: _value,
	formContext,
	element,
	variable,
	label,
	labelHint,
	tooltipComponent,
	placeholder = ''
}: GenericInputProps) {
	const isDateTime = type === InputType.DateTime;
	const isSlider = type === InputType.Slider;
	const isTimeDuration = variable.type === VariableType.TimeDuration;

	const { getTimeDurationInputPreview } = useTimeDurationEntries({ withTranslation: true });

	const inputRef = useRef<HTMLInput | null>(null);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (
			isTimeDuration &&
			!initialized &&
			inputRef.current &&
			formContext?.getValues()[name] &&
			initialized === false
		) {
			inputRef.current.focus();
			inputRef.current.blur();
			setInitialized(true);
		}
	}, [initialized, isTimeDuration, inputRef, formContext, name]);

	function handleOnBlur(e: React.FocusEvent<HTMLInput>, onBlur: Noop) {
		if (!variable.durationFormat) return;
		const value = e.target.value;

		const previewValue = getTimeDurationInputPreview(value, variable.durationFormat);
		handleSetValue(name, previewValue);
		onBlur();
	}

	function handleOnFocus(e: React.FocusEvent<HTMLInput>) {
		const format = variable.durationFormat;
		if (!format) return;
		const value = e.target.value;

		const sanitizedValue = sanitizeTimeDurationInput(value);
		handleSetValue(name, sanitizedValue);
	}

	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInput>) => {
			const value = e.target.value;
			if (variable.type === VariableType.TimeDuration && variable.durationFormat) {
				if (validateTimeDurationInput(value, variable.durationFormat)) {
					handleSetValue(name, value);
					setInitialized(true);
				}
				return;
			}
			setInitialized(true);
			handleSetValue(name, value);
		},
		[variable, name, readOnly]
	);

	if (_value) {
		return isDateTime ? (
			<DateTimeInput value={_value as string} onChange={() => null} />
		) : (
			<Input type={type} value={_value as string} onChange={() => null} />
		);
	}

	if (!formContext) {
		if (isSlider) return <SliderInput element={element} variable={variable} />;

		if (isDateTime) return <DateTimeInput />;

		return <Input type={type} />;
	}
	const {
		setValue,
		formState: { errors }
	} = formContext;

	function handleSetValue(name: string, value: DynamicFormValue) {
		// CHECK IF USER HAS ACCESS TO CHANGE THE VALUE
		if (readOnly) {
			entryFormReadOnlyModalEvent().dispatch(true);
			return;
		}

		const parsedValue = [InputType.Date, InputType.DateTime].includes(type)
			? zeroPadDate(value as string)
			: value;

		setEntryFormFieldValue(name, parsedValue, setValue);
	}

	const error = (errors[name] as FieldError)?.message || uniqueError;

	if (isSlider) {
		return (
			<Controller
				name={name}
				defaultValue={0}
				render={({ field: { value } }) => (
					<SliderInput
						disabled={disabled}
						value={value}
						element={element}
						variable={variable}
						onChange={e => handleSetValue(name, e.target.value)}
					/>
				)}
			/>
		);
	}

	if (isDateTime) {
		return (
			<Controller
				name={name}
				defaultValue={''}
				render={({ field: { value, onBlur } }) => (
					<DateTimeInput
						value={value}
						options={{
							name,
							label,
							readOnly: disabled,
							error,
							onBlur
						}}
						onChange={value => handleSetValue(name, parseDateToAPIStorage(value))}
					/>
				)}
			/>
		);
	}

	return (
		<Controller
			name={name}
			defaultValue={''}
			render={({ field: { value, onBlur } }) => (
				<Input
					ref={inputRef}
					label={label}
					{...(labelHint !== '' && { labelHint })}
					{...(placeholder !== '' && { placeholder })}
					tooltipComponent={tooltipComponent}
					required={required}
					name={name}
					type={type}
					value={value}
					readOnly={disabled || readOnlyVisual}
					error={error}
					borderError={borderError}
					onChange={handleOnChange}
					onDateChange={({ formattedDate }) => handleSetValue(name, formattedDate)}
					onBlur={e => handleOnBlur(e, onBlur)}
					onFocus={handleOnFocus}
				/>
			)}
		/>
	);
}

import { auth } from './auth';

import { jadbio } from './addons/jadbio';

import { subscription } from './account/subscription';
import { addons } from './account/addons';
import { enterprise } from './account/enterprise';
import { payments } from './account/payments';
import { accounts } from './data/accounts';
import { admin } from './account/admin';

import { analyses } from './data/analyses';
import { collaborators } from './data/collaborators';
import { dashboard } from './data/dashboard';
import { projectDashboard } from './data/projectDashboard';
import { dependencies } from './data/dependencies';
import { documents } from './data/documents';
import { entries } from './data/entries';
import { forms } from './data/forms';
import { help } from './data/help';
import { notifications } from './data/notifications';
import { patients } from './data/patients';
import { projects } from './data/projects';
import { revisions } from './data/revisions';
import { roles } from './data/roles';
import { snapshots } from './data/snapshots';
import { statuses } from './data/statuses';
import { templates } from './data/templates';
import { variables } from './data/variables';
import { variablesMapping } from './data/variablesMapping';

export const apiFactory = () => ({
	account: {
		addons: () => addons(),
		admin: () => admin(),
		enterprise: () => enterprise(),
		payments: () => payments(),
		subscription: () => subscription()
	},
	addons: {
		jadbio: () => jadbio()
	},
	auth: () => auth(),
	data: {
		accounts: () => accounts(),
		analyses: () => analyses(),
		collaborators: () => collaborators(),
		dashboard: () => dashboard(),
		projectDashboard: () => projectDashboard(),
		dependencies: () => dependencies(),
		documents: () => documents(),
		entries: () => entries(),
		forms: () => forms(),
		help: () => help(),
		notifications: () => notifications(),
		patients: () => patients(),
		projects: () => projects(),
		revisions: () => revisions(),
		roles: () => roles(),
		snapshots: () => snapshots(),
		statuses: () => statuses(),
		templates: () => templates(),
		variables: () => variables(),
		variablesMapping: () => variablesMapping()
	}
});

export type ApiFactory = ReturnType<typeof apiFactory>;

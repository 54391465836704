import styled, { css } from 'styled-components';

import { Colors, Shadows, MediaQueries } from 'environment';
import { Collapsible } from 'components/UI/Interactables/Collapsible';

export const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

interface CollapsibleGroupProps {
	hasChanges: boolean;
	isSeriesEntryForm?: boolean;
}

export const CollapsibleGroup = styled(Collapsible)<CollapsibleGroupProps>`
	background: ${Colors.white};
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};

	> :nth-child(1) {
		padding: 0.8rem;

		> :first-child {
			align-self: flex-start;
			margin: 0;
		}
	}

	> :nth-child(2) {
		margin: unset;
		padding: 0 2rem 1rem;
	}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`};

	${({ isSeriesEntryForm }) => {
		if (isSeriesEntryForm) {
			return css`
				@media ${MediaQueries.minWidth.md} {
					margin: 1rem -2.4rem;
				}
				@media ${MediaQueries.maxWidth.md} {
					margin: 1rem 1rem;
				}
			`;
		}

		return css`
			margin: 1rem -2rem;
		`;
	}}
`;

export const Divider = styled.div`
	height: 0.1rem;
	background-color: #ededed;
	margin-top: 0.8rem;
`;

import { Colors } from 'environment';
import styled, { css } from 'styled-components';

interface ListProps {
	noMargin?: boolean;
	extraMarginTop?: boolean;
}

export const List = styled.div<ListProps>`
	display: flex;
	flex-direction: column;
`;

export const FlexRow = styled.div<ListProps>`
	display: flex;
	margin-bottom: 2rem;

	${({ noMargin }) =>
		noMargin &&
		css`
			margin-bottom: 0rem;
		`};

	.select {
		background-color: ${Colors.white};
	}
`;

export const FlexCellLabel = styled.div`
	flex: 1;
	margin-right: 1rem;
	min-width: 0;
`;

export const FlexCellType = styled.div`
	flex: 1;
	margin-left: 1rem;
	position: relative;
	min-width: 0;
`;

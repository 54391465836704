import { useState, useEffect } from 'react';

import { Svgs } from 'environment';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { useTranslation } from 'hooks/store';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
	onDropdownVisible: (visible: boolean) => void;
	onTransferProject: () => void;

	onViewCollaboratorsClicked: () => void;
	showTransferOwnership: boolean;

	projectName: string;
}

export function ProjectSettingsDropdown({
	onTransferProject,
	onDropdownVisible,
	onViewCollaboratorsClicked,
	showTransferOwnership,
	projectName
}: Props) {
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const { enterpriseViewCollaboratorsFlag } = useFlags();

	const { translate } = useTranslation();

	useEffect(() => {
		onDropdownVisible(dropdownVisible);
	}, [dropdownVisible]);

	if (!showTransferOwnership && !enterpriseViewCollaboratorsFlag) {
		return null;
	}

	return (
		<Dropdown
			onClose={() => setDropdownVisible(false)}
			onOpen={() => setDropdownVisible(true)}
			toggleComponent={({ ref, toggle }) => (
				<Icon
					ref={ref}
					variant={v => v.button}
					svg={Svgs.More}
					size={s => s.m}
					onClick={toggle}
					active={dropdownVisible}
					dataTestId={'project-settings-dropdown-toggle_' + projectName}
				/>
			)}
			width={18}
			offset={{ top: 10, right: 4 }}
		>
			{showTransferOwnership && (
				<Dropdown.Item
					title={translate(dict => dict.enterpriseAdmin.projects.transferOwnership)}
					onClick={onTransferProject}
				/>
			)}

			{enterpriseViewCollaboratorsFlag && (
				<Dropdown.Item
					title={translate(dict => dict.enterpriseAdmin.projects.viewCollaborators)}
					onClick={onViewCollaboratorsClicked}
				/>
			)}
		</Dropdown>
	);
}

import format from 'date-fns/format';

import { AddPatient, BaseDistributionDataInput, Patient } from 'api/data/patients';
import { DATE_TIME_TIMEZONE_FORMAT } from 'consts';
import { getDistributionStatistics } from 'store/data/dashboard';
import { ActionPayload, Thunk } from 'store/types';
import { createActivity } from 'store/ui/activities';
import { PromDistributionTypes } from 'types/index';

import {
	ActionTypes,
	AddNewDistributionAction,
	AddUpdatePatientAction,
	DeleteDistributionAction,
	DeletePatientAction,
	GetDistributionPatientListAction,
	GetDistributionsAction,
	ResetDistributionsAction,
	SetCentraliseEntryDataAction,
	SetDistributionGlobalStartDateAction,
	SetPatientManualSurveyParamsAction,
	SetPatientsPageIndexAction,
	SetPatientsSearchTermAction,
	SetSenderDescriptionAction,
	UpdateDistributionAction,
	UpdateDistributionsAction
} from './types';

export const setPatientsSearchTerm = (
	payload: ActionPayload<SetPatientsSearchTermAction>
): SetPatientsSearchTermAction => ({
	type: ActionTypes.SET_PATIENTS_SEARCH_TERM,
	payload
});

export const setPatientsPageIndex = (
	payload: ActionPayload<SetPatientsPageIndexAction>
): SetPatientsPageIndexAction => ({
	type: ActionTypes.SET_PATIENTS_PAGE_INDEX,
	payload
});

export const setSenderDescription = (
	payload: ActionPayload<SetSenderDescriptionAction>
): SetSenderDescriptionAction => ({
	type: ActionTypes.SET_SENDER_DESCRIPTION,
	payload
});

export const setCentraliseEntryData = (
	payload: ActionPayload<SetCentraliseEntryDataAction>
): SetCentraliseEntryDataAction => ({
	type: ActionTypes.SET_CENTRALISE_ENTRY_DATA,
	payload
});

const getDistributionPatientListAction = (
	payload: ActionPayload<GetDistributionPatientListAction>
): GetDistributionPatientListAction => ({
	type: ActionTypes.GET_PATIENT_DISTRIBUTION_LIST,
	payload
});

export const getDistributionPatientList = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_PATIENT_DISTRIBUTION_LIST,
		dispatch
	});

	const { projectId } = getState().data.projects;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const patients = await context.api.data
				.patients()
				.getDistributionPatientList(Number(projectId));

			dispatch(getDistributionPatientListAction({ projectId, patients }));
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

export const addDistributionPatientAction = (
	payload: ActionPayload<AddUpdatePatientAction>
): AddUpdatePatientAction => ({
	type: ActionTypes.ADD_PATIENT_DISTRIBUTION,
	payload
});

export const addDistributionPatient =
	(patient: AddPatient): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.ADD_PATIENT_DISTRIBUTION,
			dispatch
		});

		try {
			activity.begin();

			const {
				data: {
					projects: { projectId }
				}
			} = getState();

			if (projectId) {
				const newAddedPatient = await context.api.data
					.patients()
					.addDistributionPatient(Number(projectId), patient);

				const newPatient = {
					...patient,
					patientId: newAddedPatient.patientId,
					patientTimeLine: newAddedPatient.patientTimeLine
				};
				dispatch(addDistributionPatientAction({ patient: newPatient }));
				dispatch(getDistributionStatistics());
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateDistributionPatientAction = (
	payload: ActionPayload<AddUpdatePatientAction>
): AddUpdatePatientAction => ({
	type: ActionTypes.UPDATE_PATIENT_DISTRIBUTION,
	payload
});

export const updateDistributionPatient =
	(patient: Patient): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_PATIENT_DISTRIBUTION,
			dispatch
		});

		const activityPayload = {
			patientId: patient.patientId
		};

		try {
			activity.begin({ payload: activityPayload });

			const {
				data: {
					projects: { projectId }
				}
			} = getState();

			const { patientTimeLine, ...patientForApi } = patient;

			if (projectId) {
				// Will be returned for patient's new timeline after update
				const { patient } = await context.api.data
					.patients()
					.updateDistributionPatient(Number(projectId), patientForApi);
				const updatedPatientWithTimeLine: Patient = {
					...patientForApi,
					patientTimeLine: patient.patientTimeLine
				};
				dispatch(
					updateDistributionPatientAction({
						patient: updatedPatientWithTimeLine
					})
				);
				dispatch(getDistributionStatistics());
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: activityPayload });
		} finally {
			activity.end();
		}
	};

const deleteDistributionPatientAction = (
	payload: ActionPayload<DeletePatientAction>
): DeletePatientAction => ({
	type: ActionTypes.DELETE_PATIENT_DISTRIBUTION,
	payload
});

export const deleteDistributionPatient =
	(patientId: number): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_PATIENT_DISTRIBUTION,
			dispatch
		});

		try {
			activity.begin();

			const {
				data: {
					projects: { projectId }
				}
			} = getState();

			if (projectId) {
				await context.api.data
					.patients()
					.deleteDistributionPatient(Number(projectId), patientId);
				dispatch(deleteDistributionPatientAction({ patientId }));
				dispatch(getDistributionStatistics());
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const getDistributionsAction = (
	payload: ActionPayload<GetDistributionsAction>
): GetDistributionsAction => ({
	type: ActionTypes.GET_DISTRIBUTIONS,
	payload
});

export const getDistributions = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_DISTRIBUTIONS,
		dispatch
	});

	const {
		projects: { projectId, byId: projectsById }
	} = getState().data;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const { promType } = projectsById[projectId];
			const isManualDistribution = promType === PromDistributionTypes.Manual;

			if (isManualDistribution) {
				const {
					manualDistributions: distributions,
					manualDistributionSetup: { globalStartDate }
				} = await context.api.data.patients().getManualDistributions(Number(projectId));

				dispatch(
					getDistributionsAction({
						projectId,
						distributions,
						senderDescription: '',
						globalStartDate
					})
				);
			} else {
				const {
					distributions,
					distributionSetup: { senderDescription = '', centraliseEntryData = false }
				} = await context.api.data.patients().getDistributions(Number(projectId));

				dispatch(
					getDistributionsAction({
						projectId,
						distributions,
						senderDescription,
						centraliseEntryData
					})
				);
			}
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

export const addDistribution = (): AddNewDistributionAction => ({
	type: ActionTypes.ADD_NEW_DISTRIBUTION
});

export const deleteDistribution = (payload: ActionPayload<DeleteDistributionAction>) => ({
	type: ActionTypes.DELETE_DISTRIBUTION,
	payload
});

export const updateDistribution = (
	payload: ActionPayload<UpdateDistributionAction>
): UpdateDistributionAction => ({
	type: ActionTypes.UPDATE_DISTRIBUTION,
	payload
});

const updateDistributionsAction = (
	payload: ActionPayload<UpdateDistributionsAction>
): UpdateDistributionsAction => ({
	type: ActionTypes.UPDATE_DISTRIBUTIONS,
	payload
});

export const updateDistributions = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.UPDATE_DISTRIBUTIONS,
		dispatch
	});

	try {
		activity.begin();

		const {
			data: {
				projects: { projectId, byId: projectsById },
				patients: { byProjectId }
			}
		} = getState();

		if (projectId && byProjectId[projectId]?.distributions?.current.byId) {
			const {
				current: { byId, globalStartDate, senderDescription, centraliseEntryData }
			} = byProjectId[projectId].distributions;

			const distributions = Object.values(byId)
				.map(distribution => {
					const { distributionEntryId, ...others } = distribution;

					return {
						...others,
						...(distributionEntryId &&
							distributionEntryId >= 0 && {
								distributionEntryId
							})
					};
				})
				.filter(
					(distribution): distribution is BaseDistributionDataInput =>
						distribution.quantity !== undefined && distribution.unitOfTime !== undefined
				);

			const { promType } = projectsById[projectId];
			const isManualDistribution = promType === PromDistributionTypes.Manual;

			// PROM - MANUAL DISTRIBUTION
			if (isManualDistribution) {
				const newDistributionList = await context.api.data
					.patients()
					.updateManualDistributions(Number(projectId), distributions, globalStartDate);
				dispatch(
					updateDistributionsAction({
						distributions: newDistributionList,
						globalStartDate
					})
				);
			}
			// PROM - AUTOMATIC DISTRIBUTION
			else {
				const newDistributionList = await context.api.data
					.patients()
					.updateDistributions(
						Number(projectId),
						distributions,
						senderDescription,
						centraliseEntryData
					);
				dispatch(
					updateDistributionsAction({
						distributions: newDistributionList,
						senderDescription,
						centraliseEntryData
					})
				);
			}
			// PRJCTS-2972 Needed to reflect patient's new time line after
			// distributions list update
			const patients = await context.api.data
				.patients()
				.getDistributionPatientList(Number(projectId));
			dispatch(getDistributionPatientListAction({ patients, projectId }));
			dispatch(getDistributionStatistics());
		}
	} catch (e: any) {
		activity.error({ error: e.message });

		dispatch(resetDistributions());
	} finally {
		activity.end();
	}
};

export const updateDistributionsToDefaultForm =
	(formIds: string[]): Thunk =>
	async (dispatch, getState) => {
		const {
			data: {
				projects: { projectId },
				patients: { byProjectId }
			}
		} = getState();

		if (projectId && byProjectId[projectId]?.distributions?.initial.byId) {
			const {
				initial: { byId }
			} = byProjectId[projectId].distributions;

			const distributions = Object.values(byId);

			let updatedDistributions = false;
			distributions.map(distribution => {
				if (distribution.formId && formIds.includes(distribution.formId)) {
					updatedDistributions = true;
					const updatedDistribution = { ...distribution };
					delete updatedDistribution.formId;
					dispatch(
						updateDistribution({
							updatedDistribution,
							internalId: updatedDistribution.distributionEntryId
						})
					);
				}
			});

			if (updatedDistributions) {
				dispatch(updateDistributions());
			}
		}
	};

export const resetDistributions = (): ResetDistributionsAction => ({
	type: ActionTypes.RESET_DISTRIBUTIONS
});

export const confirmStartedFillingForm = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.CONFIRM_STARTED_FILLING_FORM,
		dispatch
	});

	try {
		activity.begin();

		const entryId = getState().auth.patientLoginParams?.entryId;
		const { projectId } = getState().data.projects;

		if (projectId && entryId) {
			const updateTime = format(new Date(), DATE_TIME_TIMEZONE_FORMAT);

			await context.api.data
				.patients()
				.confirmStartedFillingForm(Number(projectId), entryId, updateTime);
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const confirmFinishedForm = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.CONFIRM_FINISHED_FORM,
		dispatch
	});

	try {
		activity.begin();

		const entryId = getState().auth.patientLoginParams?.entryId;
		const { projectId } = getState().data.projects;

		if (projectId && entryId) {
			const updateTime = format(new Date(), DATE_TIME_TIMEZONE_FORMAT);

			await context.api.data
				.patients()
				.confirmFinishedForm(Number(projectId), entryId, updateTime);
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const setPatientManualSurveyParams = (
	payload: ActionPayload<SetPatientManualSurveyParamsAction>
) => ({
	type: ActionTypes.SET_PATIENT_MANUAL_SURVEY_PARAMS,
	payload
});

export const setDistributionGlobalStartDate = (
	payload: ActionPayload<SetDistributionGlobalStartDateAction>
) => ({
	type: ActionTypes.SET_DISTRIBUTION_GLOBAL_START_DATE,
	payload
});

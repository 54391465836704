import { ChangeEvent, useCallback } from 'react';
import * as yup from 'yup';
import { Typography } from 'components/UI/Typography';
import { PHONE_REGEX_WITH_PLUS_PREFIX } from 'consts';
import { Colors } from 'environment';
import { AccountDetails } from 'store/account/subscription';
import { InputType } from 'types/index';
import {
	AvatarContainer,
	Container,
	UploadIconContainer,
	EditAccountDetails,
	HiddenInput
} from './AccountInformation.style';
import { Input } from 'components/UI/Inputs/Input';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Button } from 'components/UI/Interactables/Button';
import { RequiredFields } from 'components/UI/RequiredFields';
import { Spacer } from 'components/UI/Spacer';
import { formatPhoneNumber } from 'helpers/auth';
import { useTranslation, useUpdateAccount } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface UpdateAccountInfo {
	userFirstName: string;
	userSirName: string;
	phoneNumber: string;
	imageURL: string;
	imageString: string;
}

interface Props {
	details: AccountDetails;
}

export function AccountInformation({ details }: Props) {
	const { translate } = useTranslation();

	const { userid, userFirstName, userSirName, emailAddress, phoneNumber, imageURL, imageString } =
		details;

	const [{ loading: updating }, updateAccount] = useUpdateAccount();

	const validationSchema = yup.object({
		userFirstName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.firstNameError)),
		userSirName: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.lastNameError)),
		phoneNumber: yup
			.string()
			.min(
				8,
				translate(({ accountUM }) => accountUM.validation.phoneNumberError)
			)
			.max(
				15,
				translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
			)
			.matches(
				PHONE_REGEX_WITH_PLUS_PREFIX,
				translate(({ accountUM }) => accountUM.validation.phoneNumberErrorValid)
			)
			.required(translate(({ accountUM }) => accountUM.validation.phoneNumberError))
	});

	const initialValues: UpdateAccountInfo = {
		userFirstName,
		userSirName,
		phoneNumber,
		imageString,
		imageURL
	};

	const {
		Form,
		watch,
		setValue,
		register,
		handleSubmit,
		handleTrimOnBlur,
		errors,
		isDirty,
		touchedFields,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		registerInitialValues: true
	});

	const handleFormSubmit = handleSubmit(data => {
		if (isDirty && !updating)
			updateAccount(
				data,
				translate(dict => dict.editAccount.userProfileUpdated)
			);
	});

	function changeAvatarHandler(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files[0]) {
			const avatarFile = e.target.files[0];
			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				setValue('imageURL', avatarFile.name, {
					shouldDirty: true,
					shouldValidate: true
				});
				setValue('imageString', fileReader.result as string, {
					shouldDirty: true,
					shouldValidate: true
				});
			});
			fileReader.readAsDataURL(avatarFile);
		}
	}

	const handlePhoneNumber = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setValue('phoneNumber', formatPhoneNumber(e.target.value), {
				shouldDirty: true,
				shouldValidate: true
			});
		},
		[]
	);

	const avatarData = watch(['imageURL', 'imageString']);

	return (
		<Container>
			<AvatarContainer>
				<Avatar
					userId={userid}
					data={{
						userFirstName,
						userSirName,
						imageURL: avatarData[0],
						imageString: avatarData[1]
					}}
					size={s => s.l}
					alt={translate(dict => dict.editAccount.userAvatarAlt)}
				/>
				<UploadIconContainer htmlFor="imageInput">
					<Typography.Paragraph color={Colors.primary.normal}>
						{translate(({ editAccount }) => editAccount.changeImage)}
					</Typography.Paragraph>
				</UploadIconContainer>
				<HiddenInput
					type="file"
					id="imageInput"
					alt={translate(dict => dict.editAccount.changeImage)}
					accept="image/*"
					onChange={changeAvatarHandler}
				/>
			</AvatarContainer>
			<Spacer size={s => s.m} />
			<EditAccountDetails>
				<Form onSubmit={handleFormSubmit}>
					<Input
						{...register('userFirstName', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Text}
						label={translate(dict => dict.accountUM.userDetails.firstName)}
						placeholder={translate(dict => dict.accountUM.userDetails.firstName)}
						error={touchedFields.userFirstName ? errors.userFirstName?.message : ''}
						required
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('userSirName', {
							onBlur: handleTrimOnBlur
						})}
						type={InputType.Text}
						label={translate(dict => dict.accountUM.userDetails.lastName)}
						placeholder={translate(dict => dict.accountUM.userDetails.lastName)}
						error={touchedFields.userSirName ? errors.userSirName?.message : ''}
						required
					/>
					<Spacer size={s => s.s} />
					<Input
						type={InputType.Email}
						value={emailAddress}
						label={translate(dict => dict.accountUM.userDetails.emailAddress)}
						placeholder={translate(dict => dict.accountUM.userDetails.emailAddress)}
						readOnly
					/>
					<Spacer size={s => s.s} />
					<Input
						{...register('phoneNumber', {
							onChange: handlePhoneNumber
						})}
						type={InputType.Text}
						label={translate(dict => dict.accountUM.userDetails.phoneNumber)}
						placeholder={translate(dict => dict.accountUM.userDetails.phoneNumber)}
						error={touchedFields.phoneNumber ? errors.phoneNumber?.message : ''}
						required
					/>
					<Spacer size={s => s.m} />
					<RequiredFields />
					<Spacer size={s => s.m} />
					<Button
						type="submit"
						title={translate(dict => dict.editAccount.updateInformation)}
						loading={updating}
						disabled={!canSubmitForm}
						hasFullWidth
						hasPointer
					/>
					<Spacer size={s => s.m} />
				</Form>
			</EditAccountDetails>
		</Container>
	);
}

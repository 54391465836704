import {
	CorrelationStatisticsResponse,
	GetPearsonStatisticsOutput,
	GroupedCorrelationStatistics
} from 'api/data/analyses';
import { GenericApiResponse } from 'api/types';

// type guards
function isGroupedCorrelationData(
	data: CorrelationStatisticsResponse['result']
): data is GroupedCorrelationStatistics[] {
	return 'length' in data;
}

export function parseCorrelationStatisticsResponse(
	res: GenericApiResponse<CorrelationStatisticsResponse>
): GetPearsonStatisticsOutput {
	const result: GetPearsonStatisticsOutput = {
		data: null
	};
	if (!res.data.result) {
		return result;
	}
	result.data = [];

	if (isGroupedCorrelationData(res.data.result)) {
		res.data.result.forEach(data => {
			result.data!.push({
				group: data.groupIdentifiers[0].variable.name,
				error: data.values.errors?.[0]?.message,
				pValue: data.values.pValue,
				rValue: data.values.correlation
			});
		});
	}
	if (!isGroupedCorrelationData(res.data.result)) {
		result.data = [
			{
				group: 'default',
				error: res.data.result.errors?.[0]?.message,
				pValue: res.data.result.pValue,
				rValue: res.data.result.correlation
			}
		];
	}

	return result;
}

import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionTypes, deleteGroup } from 'store/data/variables';

export function useDeleteGroup(): Operation<(groupName: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.DELETE_GROUP);

	function handler(groupName: string) {
		if (loading) return;
		if (error) resetError();

		dispatch(deleteGroup(groupName));
	}

	return [{ loading, error }, handler];
}

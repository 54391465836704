import {
	ApiProjectFile,
	GetProjectFilesResponse,
	GetProjectFileUploadUrlResponse,
	GetProjectFileUploadUrlData
} from './types';
import { sendRequest, PROJECTS_URL } from 'api/utils';
import { RequestTypes } from 'api/utils/network';

const methods = {
	getProjectFiles: 'getFiles',
	getProjectFileUploadURL: 'getProjectFileUploadURL',
	confirmUploadedFile: 'confirmUploadedFile',
	renameDocument: 'updateFileMetadata',
	removeDocument: 'removeFile'
};

export default () => ({
	async getProjectFiles(projectId: string): Promise<ApiProjectFile[] | undefined> {
		const { data }: GetProjectFilesResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getProjectFiles,
			project: { projectId }
		});

		if (data && data.statusCode === '200') {
			return data.projectFiles;
		}
	},

	async getProjectFileUploadUrl(
		projectId: string,
		fileName: string
	): Promise<GetProjectFileUploadUrlData | undefined> {
		const { data }: GetProjectFileUploadUrlResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getProjectFileUploadURL,
			fileName,
			project: { projectId }
		});

		if (data && data.statusCode === '200') {
			return data;
		}
	},

	async directUploadProjectFile(
		binaryData: string,
		contentLenght: number,
		fileType: string,
		awsUrl: string
	): Promise<boolean> {
		try {
			await sendRequest(
				awsUrl,
				binaryData,
				{
					withCredentials: false,
					headers: {
						'Content-Type': fileType,
						'Content-Lenght': contentLenght
					}
				},
				RequestTypes.PUT
			);

			return true;
		} catch (e) {
			return false;
		}
	},

	// USED TO SET THE CORRECT FILE SIZE
	async confirmUploadedFile(projectId: string, fileId: string): Promise<boolean> {
		const { data }: GetProjectFileUploadUrlResponse = await sendRequest(PROJECTS_URL, {
			method: methods.confirmUploadedFile,
			fileId,
			project: { projectId }
		});

		if (data && data.statusCode === '200') {
			return true;
		}

		return false;
	},

	async removeDocument(projectId: string, fileId: string): Promise<boolean> {
		const { data }: GetProjectFileUploadUrlResponse = await sendRequest(PROJECTS_URL, {
			method: methods.removeDocument,
			fileId,
			project: { projectId }
		});

		if (data && data.statusCode === '200') {
			return true;
		}

		return false;
	},

	async renameDocument(projectId: string, fileId: string, fileLabel: string): Promise<boolean> {
		const { data }: GetProjectFileUploadUrlResponse = await sendRequest(PROJECTS_URL, {
			method: methods.renameDocument,
			fileId,
			fileLabel,
			project: { projectId }
		});

		if (data && data.statusCode === '200') {
			return true;
		}

		return false;
	}
});

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateEnterpriseUser } from 'store/account/enterprise';
import { UpdateEnterpriseUser } from 'store/account/subscription';

export function useUpdateEnterpriseUser(): Operation<(user: UpdateEnterpriseUser) => void> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_ENTERPRISE_USER);

	function handler(user: UpdateEnterpriseUser) {
		dispatch(updateEnterpriseUser(user));
	}

	return [{ loading, error }, handler];
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setProjectId, SetProjectIdAction } from 'store/data/projects';
import { ActionPayload } from 'store/types';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export function useProjectId(): LocalOperationResult<
	string | null,
	(projectId: ActionPayload<SetProjectIdAction>['projectId']) => void
> {
	const dispatch = useDispatch();
	const ldclient = useLDClient();

	const data = useSelector(state => state.data.projects.projectId);

	function handler(projectId: ActionPayload<SetProjectIdAction>['projectId']) {
		dispatch(setProjectId({ projectId }));

		if (ldclient) {
			const context: any = ldclient.getContext();

			if (context) {
				context.project.projectId = projectId;
			}

			localStorage.setItem('LDContext', JSON.stringify(context));

			ldclient.identify(context);
		}
	}

	return [data, handler];
}

import styled from 'styled-components';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	width: 100%;
	@media ${MediaQueries.maxWidth.sm} {
		padding: 0 1rem;
	}
`;

export const Snapshot = styled.div`
	width: 100%;
	min-width: 32rem;
	height: 4rem;
	min-height: 4rem;
	padding: 0.8rem;
	display: flex;
	align-items: center;
	border-radius: 0.4rem;
	@media ${MediaQueries.maxWidth.xl} {
		min-width: 24rem;
	}
	@media ${MediaQueries.maxWidth.sm} {
		min-width: fit-content;
	}
`;

export const Actions = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 1rem;
	flex: 1;
`;

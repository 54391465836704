import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { useFormSearchTerm, useTranslation } from 'hooks/store';

interface Props {
	onChangeCallback: () => void;
}

export function FormSearchVariables({ onChangeCallback }: Props) {
	const { translate } = useTranslation();

	const [term, setSearchTerm] = useFormSearchTerm();

	function handleOnChange(term: string) {
		onChangeCallback();
		setSearchTerm({ term });
	}

	return (
		<SearchInput
			term={term}
			placeholder={translate(({ formDesigner }) => formDesigner.searchVariable)}
			onChangeTerm={handleOnChange}
		/>
	);
}

import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';
import { FontWeights, getTypographyCSS } from 'components/UI/Typography';

interface VisibilityProps {
	visible: boolean;
}

interface ActiveDisplayProps {
	active: boolean;
	writeAccess: boolean;
	canBeActivated?: boolean;
	labels: {
		Active: string;
		Inactive: string;
		MakeActive: string;
		Deactivate: string;
	};
}

export const LineMarker = styled.div`
	width: 0.4rem;
	height: 100%;
	background: ${Colors.vibrantGreen};
	border-radius: 0.2rem;
	opacity: 0;
`;

interface HoverableAreasProps {
	isHovered: boolean;
}

export const DropToLeft = styled.div<HoverableAreasProps>`
	position: absolute;
	left: -1.2rem;
	top: 0;
	z-index: 99;

	width: 4rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	${({ isHovered }) =>
		isHovered &&
		css`
				${LineMarker} {
					opacity: 1
				}
			}
		`}
`;

export const DropToRight = styled.div<HoverableAreasProps>`
	position: absolute;
	right: -1.3rem;
	top: 0;
	z-index: 99;

	width: 2.5rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	${({ isHovered }) =>
		isHovered &&
		css`
				${LineMarker} {
					opacity: 1
				}
			}
		`}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 25.7rem;
	max-width: 27rem;
	padding: 2rem;
`;

export const FormPreviewContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-wrap: wrap;
	/* FIX FOR SAFARI BLANK FORM THUMBNAIL */
	/* since flex elements don't have height if not specified */
	height: 100%;
`;

export const MoreMenu = styled.div<VisibilityProps>`
	visibility: ${({ visible }) => !visible && 'hidden'};
	position: absolute;
	top: 0.8rem;
	right: 0.8rem;
	z-index: 1;
`;

export const ActiveDisplay = styled.div<ActiveDisplayProps>`
	transition: 0.1s;
	width: 7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${Colors.gray.brightest};
	padding: 0.5rem 0;
	border-radius: 0.4rem;
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	z-index: 100;

	p {
		${getTypographyCSS(t => t.Caption)}

		&:before {
			content: '${({ active, labels }) => (active ? labels.Active : labels.Inactive)}';
		}
	}

	${({ writeAccess }) =>
		!writeAccess &&
		css`
			cursor: default;
		`}

	${({ active }) =>
		active &&
		css`
			background: ${Colors.primary.disabled};

			p {
				color: ${Colors.primary.normal};
				font-weight: ${FontWeights.medium};
			}
		`}
`;

export const HoverMenu = styled.a`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.1s;
	opacity: 0;
	z-index: 99;
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 0.4rem;
	background-color: rgba(255, 255, 255, 0.9);
	text-decoration: none;

	p {
		text-align: center;
		font-size: 1.3rem;
		padding: 0 2rem;
		color: ${Colors.gray.light};
	}
`;

export const CardReplacement = styled.div`
	position: relative;
	height: 30.6rem;
	border-radius: 0.4rem;
	background: ${Colors.background.disabled};
	box-shadow: 0;
`;

export const Card = styled.div<ActiveDisplayProps>`
	display: flex;
	position: relative;
	flex-direction: column;
	height: 30.6rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	transition: background-color 0.1s, box-shadow 0.05s;
	cursor: pointer;
	border-radius: 0.4rem;

	:hover {
		box-shadow: ${Shadows.hover};

		${HoverMenu} {
			opacity: 1;
		}

		${({ writeAccess, canBeActivated, active, labels }) =>
			writeAccess &&
			css`
				${MoreMenu} {
					visibility: visible;
					z-index: 100;
				}

				${ActiveDisplay} {
					width: 10rem;
					white-space: nowrap;

					p {
						&:before {
							content: '${active ? labels.Deactivate : labels.MakeActive}';
						}
					}

					${!canBeActivated
						? css`
								background-color: ${Colors.gray.brightest};

								p {
									color: ${Colors.gray.light};
								}
						  `
						: css`
								:hover {
									background-color: ${Colors.primary.normal};

									p {
										color: ${Colors.white};
									}
								}
						  `}
				}
			`}
	}
`;

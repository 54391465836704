import { useState } from 'react';
import { Project } from 'store/data/projects';
import { PromDistributionTypes } from 'types/index';
import { ProjectType } from 'types/data/projects/constants';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { useMatchProms } from 'hooks/navigation';
import { useTranslation, useConvertProject, useProjectById } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	projectId: string;
	onClose: () => void;
}

export function ConvertProjectModal({ projectId, onClose }: Props) {
	const { translate } = useTranslation();
	const matchProms = useMatchProms();

	const [promType, setPromType] = useState(PromDistributionTypes.Automatic);

	const [{ loading: convertingProject, error: errorConvertingProject }, convertProject] =
		useConvertProject();

	useCompletedAction(convertingProject, errorConvertingProject, onClose);

	const project = useProjectById(projectId);

	function handleSubmit() {
		convertProject({
			projectId,
			promType,
			projectType: matchProms ? ProjectType.CORE : ProjectType.PROM
		});
	}

	function getProjectTitle(project: Project) {
		const computedProjectNumber = project.givenProjectNumber
			? `${project.givenProjectNumber}: `
			: '';

		const title = `${computedProjectNumber}${project.projectName}`;

		return (
			<>
				{`${translate(dict => (matchProms ? dict.terms.survey : dict.terms.project))}: `}
				<b>{title}</b>
			</>
		);
	}

	const promTypes = [
		translate(dict => dict.createProm.distributionAutomatic),
		translate(dict => dict.createProm.distributionManual)
	];

	const getSelectedPromType = {
		[PromDistributionTypes.Automatic]: promTypes[0],
		[PromDistributionTypes.Manual]: promTypes[1]
	};

	if (!project) return null;

	return (
		<Modal
			size={s => s.s}
			title={translate(dict =>
				matchProms
					? dict.projects.convert.convertToProject
					: dict.projects.convert.convertToSurvey
			)}
			primary={{
				label: translate(dict => dict.buttons.convert),
				loading: convertingProject,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>{getProjectTitle(project)}</Typography.Paragraph>

			{!matchProms && (
				<>
					<Spacer size={s => s.l} />

					<RadioGroup
						label={translate(dict => dict.createProm.distributionType)}
						selected={getSelectedPromType[promType]}
						items={promTypes}
						onSelect={type => setPromType(type.toLowerCase() as PromDistributionTypes)}
						isVertical
					/>
				</>
			)}
		</Modal>
	);
}

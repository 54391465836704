import { useActivity, useProjectId } from 'hooks/store';
import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { ActionTypes, parseCsvFromS3, selectCsvFromS3 } from 'store/data/documents';

export function useConvertS3Document(): OperationResult<
	string[][] | null,
	(signedS3Url: string) => void
> {
	const dispatch = useDispatch();
	const [projectId] = useProjectId();

	const data = useSelector(state => selectCsvFromS3(state.data.documents));

	const [{ loading, error }] = useActivity(ActionTypes.GET_CSV_XLS_FROM_S3);

	function handler(signedS3Url: string) {
		if (projectId) dispatch(parseCsvFromS3(projectId, signedS3Url));
	}

	return [{ loading, error, data }, handler];
}

import { ImportType } from 'types';
import { PreviewVariable } from '../../../../../types/data/projects/import/types';
import { Container, Header } from './ErrorTableWrapper.style';

type Props = {
	children: React.ReactNode;
	activeVariable: PreviewVariable;
	selectedOption: ImportType;
};

export function ErrorTableWrapper({ children, activeVariable, selectedOption }: Props) {
	const headerLabel =
		activeVariable.label === activeVariable.previewVariableLabel
			? activeVariable.label
			: [
					ImportType.Now,
					ImportType.ReplaceAll,
					ImportType.MoreDataToExistingEntries
			  ].includes(selectedOption)
			? `${activeVariable.label} (${activeVariable.previewVariableLabel})`
			: `${activeVariable.previewVariableLabel} (${activeVariable.label})`;
	return (
		<Container>
			<Header>{headerLabel}</Header>
			{children}
		</Container>
	);
}

import produce from 'immer';

import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_DISTRIBUTION_STATISTICS: {
			const { projectId, promStatistics, listOfCreationDates } = action.payload;

			return produce(state, draft => {
				draft.byProjectId[projectId] = {
					automaticDistributionStatistics: promStatistics,
					manualDistributionStatistics: listOfCreationDates,
					fetched: true
				};
			});
		}
		default: {
			return state;
		}
	}
};

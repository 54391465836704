import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { addGroupToTemplate, AddGroupToTemplateAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useGroupIntoTemplate(): LocalOperation<
	(payload: ActionPayload<AddGroupToTemplateAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<AddGroupToTemplateAction>) {
		dispatch(addGroupToTemplate(payload));
	}

	return handler;
}

import { Icon } from 'components/UI/Icons';
import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const RadioButtonContainer = styled.div`
	cursor: pointer;
	user-select: none;
`;

export const ListContainer = styled.div`
	> :nth-child(odd) {
		background-color: ${Colors.background.disabled};
	}
`;

interface ListItemProps {
	empty?: boolean;
	flex?: boolean;
}

export const ListItem = styled.div<ListItemProps>`
	padding: 0.5rem;
	padding-left: 1rem;
	/* justify-content: space-between; */

	${getTypographyCSS(t => t.Paragraph)}

	display: ${props => (props.flex ? 'flex' : 'block')};
	color: ${props => (props.empty ? Colors.text.error : Colors.text.main)};

	svg {
		color: ${Colors.text.error};
		margin-right: 0.5rem;
	}
`;

export const SortIcon = styled(Icon)<{ sort: boolean }>`
	transform: ${({ sort }) => `rotate(${sort ? 180 : 0}deg)`};
	transition: transform 300ms;
`;

export const WarningMessage = styled.div`
	width: 100%;
	padding: 0.8rem;
	border-radius: 0.4rem;
	display: flex;
	align-items: start;
	background: ${Colors.background.error};

	p {
		color: ${Colors.text.error};
	}

	svg {
		color: ${Colors.text.error};
		transform: rotate(180deg);
	}
`;

import styled, { css } from 'styled-components';

import { Colors, Shadows } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';

export const AddIcon = styled(DefaultIcon)`
	visibility: hidden;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
`;

interface ContainerProps {
	disabled?: boolean;
	isGrouped?: boolean;
}

export const Container = styled.div<ContainerProps>`
	position: relative;
	height: 4rem;
	width: calc(100% - 3.2rem);
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	padding: 0.8rem 1.6rem;
	border-radius: 0.4rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: box-shadow 0.05s;

	${({ disabled }) => {
		if (disabled) {
			return css`
				cursor: not-allowed;
				& p {
					color: #b0bfc7; // TODO: add disabled color to Colors
				}
			`;
		}

		return css`
			:hover {
				box-shadow: ${Shadows.hover};

				${AddIcon} {
					visibility: visible;
				}
			}
		`;
	}}

	${({ isGrouped }) =>
		!isGrouped &&
		css`
			width: 100%;
		`}
`;

export const TextWrapper = styled.span`
	display: flex;
	width: calc(100% - 2.4rem);
`;

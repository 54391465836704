import { useEffect, useRef } from 'react';
import { OperationResult } from 'hooks/store/types';

import {
	ActionTypes,
	selectFormsByProjectId,
	selectAreFormsFetched,
	getForms,
	Form
} from 'store/data/forms';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

interface UseFormsOptions {
	lazy?: boolean;
}

export function useForms(options?: UseFormsOptions): OperationResult<Form[]> {
	const dispatch = useDispatch();

	const abortController = useRef(new AbortController());

	const { lazy = false } = options ?? {};

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const fetched = useSelector(state => selectAreFormsFetched(state.data.forms));
	const data = useSelector(state => selectFormsByProjectId(state.data.forms));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_FORMS, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getForms(abortController.current));
	}

	return [{ loading, error, data, fetched, abortController: abortController.current }, handler];
}

import styled, { css } from 'styled-components';

function hexToRGB(hex: string, alpha: number) {
	const r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	} else {
		return 'rgb(' + r + ', ' + g + ', ' + b + ')';
	}
}

export const IconsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
`;

export const RoleTag = styled.div<{ roleColor: string }>`
	padding: 0 0.8rem;
	border-radius: 0.4rem;
	width: fit-content;

	${({ roleColor }) =>
		css`
			background: ${hexToRGB(roleColor, 0.3)};
		`}
`;

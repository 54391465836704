import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import {
	moveVariableSetAggregationRule,
	MoveVariableSetAggregationRuleAction,
	ActionTypes
} from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveVariableSetAggregationRule(): Operation<
	(payload: ActionPayload<MoveVariableSetAggregationRuleAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE);

	function handler(payload: ActionPayload<MoveVariableSetAggregationRuleAction>) {
		dispatch(moveVariableSetAggregationRule(payload));
	}

	return [{ loading, error }, handler];
}

import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Svgs, Colors } from 'environment';
import { DraggableVariableCardType } from 'store/data/variables';
import { VariableCardPayload } from '../../../Cards/CommonCard';
import { VariableSetConfirmDragAndDropActionModal } from 'components/Variables/VariablesGrid/ConfirmDragAndDropActionModal';
import {
	CardReplacement,
	DropAbove,
	DropBelow,
	LineMarkerHorizontal,
	VariableCardContainer,
	ColorIndicator,
	HoverOver,
	Label,
	LoadingContainer
} from '../../../Cards/VariableCard/VariableCard.style';
import { groupCardPulse, groupCountPulse } from 'events/variables';
import { VariableType } from 'types/data/variables/constants';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import {
	isVariablePromGenerated,
	groupHasForbiddenVariableType,
	itemIsType
} from 'helpers/variables';
import {
	useMoveGroup,
	useMoveVariable,
	useVariables,
	useTranslation,
	useAddVariableToGroup,
	useAddVariableToSet,
	useMoveVariableGroupBetweenSets,
	useAddVariableGroupToSet
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';

interface Props {
	title: string;
	subtitle: string;
	name: string;
	index: number;
	parentSet: string;
	preventDrag: boolean;
	onClick: () => void;
}

export function VariableGroupCardInSet({
	title,
	subtitle,
	index,
	name,
	parentSet,
	preventDrag,
	onClick
}: Props) {
	const { setError: setErrorNotification } = useAlerts();
	const [, moveGroup] = useMoveGroup();
	const [, moveVariable] = useMoveVariable();
	const [
		{
			data: { variablesMap, groupsMap }
		}
	] = useVariables();
	const { translate } = useTranslation();

	const [, addVariableToGroup] = useAddVariableToGroup();

	const [, addVariableToSet] = useAddVariableToSet();
	const [{ loading: movingVariableGroupBetweenSets }] = useMoveVariableGroupBetweenSets({
		groupName: name
	});
	const [{ loading: addingVariableGroupToSet }, addVariableGroupToSet] = useAddVariableGroupToSet(
		{
			groupName: name
		}
	);

	const loading = addingVariableGroupToSet || movingVariableGroupBetweenSets;

	const mainRef = useRef<HTMLDivElement>(null);
	const aboveRef = useRef<HTMLDivElement>(null);
	const belowRef = useRef<HTMLDivElement>(null);

	const [dropFunctionToCall, setDropFunctionToCall] = useState<
		('handleDrop' | 'handleDropAbove' | 'handleDropBelow') | null
	>(null);

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => {
			setConfirmAction(null);
			setDropFunctionToCall(null);
		}
	};

	const [pulseCard, setPulseCard] = useState(false);
	const [pulseCount, setPulseCount] = useState(false);

	useCustomEventListener(groupCardPulse, {
		onListen: payload => {
			if (payload.groupName === name) setPulseCard(true);
		}
	});
	useCustomEventListener(groupCountPulse, {
		onListen: payload => {
			if (payload.groupName === name) setPulseCount(true);
		}
	});

	// DRAG - CARD
	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: DraggableVariableCardType.Group,
		item: (): VariableCardPayload => ({
			name,
			index,
			isGroup: true,
			parentSet
		}),
		canDrag: !preventDrag,
		collect: monitor => ({ isDragging: monitor.isDragging() })
	});

	// DROP - CARD
	const [{ handlerId, isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable =
				draggedVariable &&
				isVariablePromGenerated({ systemGenerated: draggedVariable.systemGenerated });
			return {
				handlerId: monitor.getHandlerId(),
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToSeries)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDrop');

				return;
			}

			handleDrop(item);
		}
	});

	// DROP - ABOVE
	const [{ aboveHandlerId, isOverCurrentAbove }, dropAbove] = useDrop<any, any, any>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => ({
			aboveHandlerId: monitor.getHandlerId(),
			isOverCurrentAbove: monitor.isOver({ shallow: true })
		}),
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			// SPECIAL CASE: groups that contain file variables;
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropAbove');

				return;
			}

			handleDropAbove(item);
		}
	});

	// DROP - BELOW
	const [{ belowHandlerId, isOverCurrentBelow }, dropBelow] = useDrop<any, any, any>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => ({
			belowHandlerId: monitor.getHandlerId(),
			isOverCurrentBelow: monitor.isOver({ shallow: true })
		}),
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			// SPECIAL CASE: groups that contain file variables;
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			} else {
				const preventDrop = itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropBelow');

				return;
			}

			handleDropBelow(item);
		}
	});

	function handleDrop(item: VariableCardPayload) {
		const fromMainList = !(item.parentSet || item.parentGroup);

		addVariableToGroup({
			variableName: item.name,
			groupName: name,
			setName: parentSet,
			// USED TO: 1. ADD THE VARIABLE INSIDE THE SET - `addVariableToSet`; 2. THEN ADD VARIABLE TO GROUP `addVariableToGroup`
			from: { mainList: fromMainList }
		});
	}

	function handleDropAbove(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index;

		// MOVE ITEMS INSIDE VARIABLE SET
		if (item.parentSet) {
			// GROUP CARD
			if (item.isGroup) {
				moveGroup({
					groupName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			// VARIABLE CARD
			else {
				moveVariable({
					variableName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			return;
		}

		// GROUP CARD
		if (item.isGroup) {
			addVariableGroupToSet({
				groupName: item.name,
				destinationIndex,
				setName: parentSet
			});
		}
		// VARIABLE CARD
		else {
			addVariableToSet({
				variableName: item.name,
				destinationIndex,
				setName: parentSet
			});
		}
	}

	function handleDropBelow(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index < sourceIndex ? index + 1 : index;

		// MOVE ITEMS INSIDE VARIABLE SET
		if (item.parentSet) {
			// GROUP CARD
			if (item.isGroup) {
				moveGroup({
					groupName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			// VARIABLE CARD
			else {
				moveVariable({
					variableName: item.name,
					sourceIndex,
					destinationIndex,
					setName: parentSet
				});
			}
			return;
		}

		// GROUP CARD
		if (item.isGroup) {
			addVariableGroupToSet({
				groupName: item.name,
				destinationIndex: index + 1,
				setName: parentSet
			});
		}
		// VARIABLE CARD
		else {
			addVariableToSet({
				variableName: item.name,
				destinationIndex: index + 1,
				setName: parentSet
			});
		}
	}

	function handleFinalDrop(item: VariableCardPayload) {
		if (!dropFunctionToCall) return;

		if (dropFunctionToCall === 'handleDrop') handleDrop(item);
		if (dropFunctionToCall === 'handleDropAbove') handleDropAbove(item);
		if (dropFunctionToCall === 'handleDropBelow') handleDropBelow(item);
	}

	function shouldTriggerConfirmModal(item: VariableCardPayload) {
		if (item.parentSet) return false;

		return true;
	}

	drop(drag(mainRef));
	////////////////////
	dropAbove(aboveRef);
	dropBelow(belowRef);

	const isFirstItem = index === 0;

	if (isDragging) return <CardReplacement ref={dragPreview} />;

	return (
		<>
			<VariableCardContainer
				{...(pulseCard && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={() => setPulseCard(false)}
				//
				ref={mainRef}
				isDragging={isDragging}
				data-handler-id={handlerId}
				isHovered={isOverCurrent}
				onClick={onClick}
				isGroup
				id={title?.replaceAll(' ', '').toLowerCase()}
				disabled={loading}
				data-scroll-id={name}
			>
				<ColorIndicator type="group" />

				<Flex align={a => a.center}>
					<Icon
						svg={Svgs.Folder}
						size={s => s.m}
						colors={{ color: Colors.groupOrange }}
						propagate
					/>
					<Typography.Paragraph fontweight={w => w.medium} title={title} ellipsis>
						{title}
					</Typography.Paragraph>
				</Flex>
				<Flex>
					<Typography.Caption
						{...(pulseCount && {
							className: 'pulse-vibrant-green',
							style: {
								color: Colors.chart.darkGreen.dark
							}
						})}
						onAnimationEnd={() => setPulseCount(false)}
						//
						title={subtitle}
						ellipsis
					>
						{subtitle}
					</Typography.Caption>
				</Flex>

				<HoverOver systemGenerated={isSysGenVariable}>
					{!isSysGenVariable && (
						<Label>{translate(dict => dict.draggableVariable.addToGroup)}</Label>
					)}
				</HoverOver>

				{isFirstItem && (
					<DropAbove
						ref={aboveRef}
						data-handler-id={aboveHandlerId}
						isHovered={isOverCurrentAbove}
					>
						<LineMarkerHorizontal />
					</DropAbove>
				)}
				<DropBelow
					ref={belowRef}
					data-handler-id={belowHandlerId}
					isHovered={isOverCurrentBelow}
				>
					<LineMarkerHorizontal />
				</DropBelow>

				{loading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
			</VariableCardContainer>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="to"
					onConfirm={() => handleFinalDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}

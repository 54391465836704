import { useState, useEffect } from 'react';

import {
	JADBioAnalysis,
	JADBioAnalysisModels,
	JADBioReductionType,
	JADBioFeatureImportancePlots,
	JADBioProbabilitiesDisplayType
} from 'api/data/analyses';
import { AnalysisViewContainer } from 'components/Analysis/Analyses';
import { Tabs } from 'components/UI/Tabs';
import { Typography } from 'components/UI/Typography';
import {
	exportSvg,
	isAnalysisInputValid,
	isClasificationVariableWithTwoValues
} from 'helpers/analysis';
import {
	exportJADBioTable,
	getJADBioExportFileName,
	getJADBioZingChartExportType
} from 'helpers/analysis/exportJADBio';
import { ExportFileNames, FileType } from 'types/index';

import { JADBioTable } from './JADBioTable';
import { AnalysisErrorWrapper, AnalysisExportWrapper } from '../../UI';
import { JADBioReductionScatterChart } from './JADBioReductionScatterChart';
import { JADBioProbabilitiesDensityPlotChart } from './JADBioProbabilitiesDensityPlotChart';
import { JADBioProbabilitiesBoxPlotChart } from './JADBioProbabilitiesBoxPlotChart';
import { JADBioFeatureSelectionColumnChart } from './JADBioFeatureSelectionColumnChart';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { downloadFile, getExportFileName } from 'helpers/generic';
import {
	useAnalysisActiveTab,
	useVariableByName,
	useTranslation,
	useProject,
	useFilters
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
interface Props {
	analysis: JADBioAnalysis;
	loading: boolean;
	exporting: boolean;
	error: boolean;
	variablesDataSelectItems: VariablesDataSelectItems;
	previousGenerationNotFinished: boolean;
}

export function JADBioView({
	analysis,
	exporting,
	loading: JADBioAnalysisLoading,
	error: JADBioAnalysisError,
	variablesDataSelectItems,
	previousGenerationNotFinished
}: Props) {
	const {
		id: analysisId,
		options: { configPanel },
		input: {
			performanceReductionType,
			featureImportanceType,
			probabilitiesDisplayType,
			model: jadBioModel,
			variables: jadBioVariables
		},
		output: { dataset }
	} = analysis;

	const [activeTab, setActiveTab] = useAnalysisActiveTab(analysisId);

	const classificationVariable = useVariableByName(jadBioVariables.classificationVariable);

	const { translate } = useTranslation();

	const analysisValidInput = isAnalysisInputValid(analysis);

	const [showAnalysisErrorMessage, setAnalysisErrorMessage] = useState(false);

	const { categorySelectItems, numericSelectItems } = variablesDataSelectItems;

	const [{ data: project }] = useProject();

	useEffect(() => {
		if (JADBioAnalysisError) {
			setAnalysisErrorMessage(true);
		} else {
			setAnalysisErrorMessage(false);
		}
	}, [JADBioAnalysisError]);

	useEffect(() => {
		setAnalysisErrorMessage(false);
	}, [jadBioModel, jadBioVariables]);

	//TODO: when SurvivalAnalysis and Regression analysis models will be available, make a better
	// selection of the data based on one of the models
	const jadBioClassificationData =
		jadBioModel === JADBioAnalysisModels.Classification
			? dataset[JADBioAnalysisModels.Classification]
			: null;

	const isModelValid = jadBioModel !== null;

	const classificationSummary =
		jadBioClassificationData !== null ? jadBioClassificationData.summary : null;

	const reductionPlot =
		jadBioClassificationData !== null && jadBioClassificationData.plots
			? performanceReductionType === JADBioReductionType.UMAP
				? jadBioClassificationData.plots.performance.UMAP
				: jadBioClassificationData.plots.performance.PCA
			: null;

	const classificationProbabilitiesDensityPlot =
		jadBioClassificationData !== null && jadBioClassificationData.plots
			? jadBioClassificationData.plots.performance.probabilitiesDensityPlot
			: null;

	const classificationProbabilitiesBoxPlot =
		jadBioClassificationData !== null && jadBioClassificationData.plots
			? jadBioClassificationData.plots.performance.probabilitiesBoxPlot
			: null;

	const classificationFeatureImportance =
		jadBioClassificationData !== null && jadBioClassificationData.plots
			? featureImportanceType === JADBioFeatureImportancePlots.FeatureImportance
				? jadBioClassificationData.plots.performance.featureImportance
				: jadBioClassificationData.plots.performance.progressiveFeatureImportance
			: null;

	const invalidVariablesValidationText =
		jadBioModel === JADBioAnalysisModels.Classification && categorySelectItems.length === 0
			? translate(({ analysis }) => analysis.errors.invalidAnalysis.categoryError)
			: jadBioModel === JADBioAnalysisModels.SurvivalAnalysis &&
			  (categorySelectItems.length === 0 || numericSelectItems.length === 0)
			? translate(({ analysis }) => analysis.errors.invalidAnalysis.categoryAndNumericError)
			: jadBioModel === JADBioAnalysisModels.Regression && numericSelectItems.length === 0
			? translate(({ analysis }) => analysis.errors.invalidAnalysis.numericError)
			: null;

	const zingChartJADBioId =
		getJADBioZingChartExportType(analysis.input, activeTab ?? 0) + analysisId;

	const exportFileName = getJADBioExportFileName(analysis.input, activeTab ?? 0);

	const [{ filters, activeFilterIds }] = useFilters();

	const clasificationVariableWithTwoValues = isClasificationVariableWithTwoValues(
		classificationVariable,
		filters,
		activeFilterIds
	);

	useEffect(() => {
		if (exporting && project && !JADBioAnalysisError && !JADBioAnalysisLoading) {
			if ((!activeTab || activeTab === 0) && classificationSummary) {
				const data = exportJADBioTable(classificationSummary, { translate });
				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.JADBioClassificationSummary,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			} else if (exportFileName) {
				exportSvg(
					zingChartJADBioId,
					getExportFileName(exportFileName, project.projectId, project.projectName)
				);
			}
		}
	}, [exporting, activeTab, project, JADBioAnalysisError, JADBioAnalysisLoading]);

	if (!isModelValid)
		return (
			<AnalysisViewContainer
				isConfigPanelOpen={configPanel.open}
				loading={JADBioAnalysisLoading}
			>
				<Typography.Paragraph paddingOffset={{ left: 2 }}>
					{translate(({ analysis }) => analysis.analyses.JADBio.selectJADBioModel)}
				</Typography.Paragraph>
			</AnalysisViewContainer>
		);

	if (invalidVariablesValidationText) {
		return (
			<AnalysisViewContainer
				isConfigPanelOpen={configPanel.open}
				loading={JADBioAnalysisLoading}
			>
				<Typography.Paragraph paddingOffset={{ left: 2 }}>
					{invalidVariablesValidationText}
				</Typography.Paragraph>
			</AnalysisViewContainer>
		);
	}

	if (!analysisValidInput)
		return (
			<AnalysisViewContainer
				isConfigPanelOpen={configPanel.open}
				loading={JADBioAnalysisLoading}
			>
				<Typography.Paragraph paddingOffset={{ left: 2 }}>
					{analysis.input.model === JADBioAnalysisModels.SurvivalAnalysis
						? translate(
								({ analysis }) => analysis.analyses.JADBio.selectJADBioVariables
						  )
						: translate(
								({ analysis }) => analysis.analyses.JADBio.selectJADBioVariable
						  )}
				</Typography.Paragraph>
			</AnalysisViewContainer>
		);

	if (!clasificationVariableWithTwoValues)
		return (
			<AnalysisViewContainer isConfigPanelOpen={configPanel.open}>
				<Typography.Paragraph paddingOffset={{ left: 2 }}>
					{translate(dict => dict.analysis.analyses.JADBio.jadbioMulticategoryLimitation)}
				</Typography.Paragraph>
			</AnalysisViewContainer>
		);

	if (showAnalysisErrorMessage)
		return (
			<AnalysisViewContainer
				isConfigPanelOpen={configPanel.open}
				loading={JADBioAnalysisLoading}
			>
				<Typography.Paragraph paddingOffset={{ left: 2 }}>
					{translate(dict => dict.analysis.analyses.JADBio.jadbioAnalysisError)}
				</Typography.Paragraph>
			</AnalysisViewContainer>
		);

	if ((JADBioAnalysisLoading || previousGenerationNotFinished) && jadBioModel)
		return (
			<AnalysisViewContainer
				isConfigPanelOpen={configPanel.open}
				loading={JADBioAnalysisLoading}
			>
				<Typography.Paragraph paddingOffset={{ left: 2 }}>
					{translate(dict => dict.analysis.analyses.JADBio.jadbioIsCalculating)}
					{(dataset[jadBioModel].progress !== null ? dataset[jadBioModel].progress : 0) +
						' %'}
				</Typography.Paragraph>
			</AnalysisViewContainer>
		);

	if (!classificationSummary || !classificationSummary.length)
		return (
			<AnalysisViewContainer
				isConfigPanelOpen={configPanel.open}
				loading={JADBioAnalysisLoading}
			/>
		);

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={JADBioAnalysisLoading}>
			{!JADBioAnalysisLoading && analysisValidInput && (
				<Tabs
					labels={[
						translate(
							dict =>
								dict.analysis.analyses.JADBio.analysisTabs.analysisResultOverview
						),
						translate(
							dict => dict.analysis.analyses.JADBio.analysisTabs.performanceReduction
						),
						translate(
							dict =>
								dict.analysis.analyses.JADBio.analysisTabs.performanceProbabilities
						),
						translate(
							dict => dict.analysis.analyses.JADBio.analysisTabs.featureImportance
						)
					]}
					startIndex={activeTab}
					tabMarginOffset={{ x: 0.5 }}
					onChangeTabs={active => setActiveTab({ analysisId, activeTab: active })}
				>
					<Tabs.Content>
						<JADBioTable data={classificationSummary} />
					</Tabs.Content>
					<Tabs.Content>
						{reductionPlot ? (
							<AnalysisErrorWrapper
								analysis={analysis}
								analysisType={AnalysisType.JADBio}
								plotNumericType={1}
							>
								<JADBioReductionScatterChart
									data={reductionPlot.groups}
									isLegendEnabled={true}
									legendHeader={
										performanceReductionType === JADBioReductionType.UMAP
											? translate(
													dict =>
														dict.analysis.analyses.JADBio.labelsNames
															.UMAP
											  )
											: reductionPlot.title
									}
									scalesLabels={{
										labelX: reductionPlot.xaxis,
										labelY: reductionPlot.yaxis
									}}
								/>

								<AnalysisExportWrapper>
									<JADBioReductionScatterChart
										id={zingChartJADBioId}
										isForExport
										data={reductionPlot.groups}
										isLegendEnabled={true}
										legendHeader={
											performanceReductionType === JADBioReductionType.UMAP
												? translate(
														dict =>
															dict.analysis.analyses.JADBio
																.labelsNames.UMAP
												  )
												: reductionPlot.title
										}
										scalesLabels={{
											labelX: reductionPlot.xaxis,
											labelY: reductionPlot.yaxis
										}}
									/>
								</AnalysisExportWrapper>
							</AnalysisErrorWrapper>
						) : (
							<Typography.Paragraph>
								{translate(dict => dict.analysis.analyses.JADBio.unableToExtract)}
							</Typography.Paragraph>
						)}
					</Tabs.Content>
					<Tabs.Content>
						{((probabilitiesDisplayType ===
							JADBioProbabilitiesDisplayType.DensityPlot &&
							!classificationProbabilitiesDensityPlot) ||
							(probabilitiesDisplayType === JADBioProbabilitiesDisplayType.BoxPlot &&
								!classificationProbabilitiesBoxPlot)) && (
							<Typography.Paragraph>
								{translate(dict => dict.analysis.analyses.JADBio.unableToExtract)}
							</Typography.Paragraph>
						)}
						{probabilitiesDisplayType === JADBioProbabilitiesDisplayType.DensityPlot &&
							classificationProbabilitiesDensityPlot && (
								<AnalysisErrorWrapper
									analysis={analysis}
									analysisType={AnalysisType.JADBio}
									plotNumericType={2}
								>
									<JADBioProbabilitiesDensityPlotChart
										data={classificationProbabilitiesDensityPlot.groups}
										isLegendEnabled={true}
										legendHeader={classificationProbabilitiesDensityPlot.title}
										scalesLabels={{
											labelX: classificationProbabilitiesDensityPlot.xaxis,
											labelY: classificationProbabilitiesDensityPlot.yaxis
										}}
									/>
									<AnalysisExportWrapper>
										<JADBioProbabilitiesDensityPlotChart
											id={zingChartJADBioId}
											data={classificationProbabilitiesDensityPlot.groups}
											isLegendEnabled={true}
											legendHeader={
												classificationProbabilitiesDensityPlot.title
											}
											scalesLabels={{
												labelX: classificationProbabilitiesDensityPlot.xaxis,
												labelY: classificationProbabilitiesDensityPlot.yaxis
											}}
											isForExport
										/>
									</AnalysisExportWrapper>
								</AnalysisErrorWrapper>
							)}

						{probabilitiesDisplayType === JADBioProbabilitiesDisplayType.BoxPlot &&
							classificationProbabilitiesBoxPlot && (
								<AnalysisErrorWrapper
									analysis={analysis}
									analysisType={AnalysisType.JADBio}
									plotNumericType={2}
								>
									<JADBioProbabilitiesBoxPlotChart
										data={classificationProbabilitiesBoxPlot}
										isLegendEnabled={false}
										legendHeader={''}
										scalesLabels={{
											labelX: '',
											labelY: classificationProbabilitiesDensityPlot
												? classificationProbabilitiesDensityPlot.xaxis
												: ''
										}}
									/>
									<AnalysisExportWrapper>
										<JADBioProbabilitiesBoxPlotChart
											id={zingChartJADBioId}
											data={classificationProbabilitiesBoxPlot}
											isLegendEnabled={false}
											legendHeader={''}
											scalesLabels={{
												labelX: '',
												labelY: classificationProbabilitiesDensityPlot
													? classificationProbabilitiesDensityPlot.xaxis
													: ''
											}}
											isForExport
										/>
									</AnalysisExportWrapper>
								</AnalysisErrorWrapper>
							)}
					</Tabs.Content>
					<Tabs.Content>
						{classificationFeatureImportance &&
						classificationFeatureImportance.length > 0 ? (
							<AnalysisErrorWrapper
								analysis={analysis}
								analysisType={AnalysisType.JADBio}
								plotNumericType={2}
							>
								<JADBioFeatureSelectionColumnChart
									data={classificationFeatureImportance.slice(2)}
									scalesLabels={{
										labelX: translate(
											dict =>
												dict.analysis.analyses.JADBio.labelsNames.feature
										),
										labelY:
											featureImportanceType ===
											JADBioFeatureImportancePlots.FeatureImportance
												? translate(
														dict =>
															dict.analysis.analyses.JADBio
																.labelsNames
																.predictivePerformancePercentageDrop
												  )
												: translate(
														dict =>
															dict.analysis.analyses.JADBio
																.labelsNames
																.predictivePerformancePercentageIncrease
												  )
									}}
								/>
								<AnalysisExportWrapper>
									<JADBioFeatureSelectionColumnChart
										id={zingChartJADBioId}
										data={classificationFeatureImportance.slice(2)}
										scalesLabels={{
											labelX: translate(
												dict =>
													dict.analysis.analyses.JADBio.labelsNames
														.feature
											),
											labelY:
												featureImportanceType ===
												JADBioFeatureImportancePlots.FeatureImportance
													? translate(
															dict =>
																dict.analysis.analyses.JADBio
																	.labelsNames
																	.predictivePerformancePercentageDrop
													  )
													: translate(
															dict =>
																dict.analysis.analyses.JADBio
																	.labelsNames
																	.predictivePerformancePercentageIncrease
													  )
										}}
										isForExport
									/>
								</AnalysisExportWrapper>
							</AnalysisErrorWrapper>
						) : (
							<Typography.Paragraph>
								{translate(dict => dict.analysis.analyses.JADBio.unableToExtract)}
							</Typography.Paragraph>
						)}
					</Tabs.Content>
				</Tabs>
			)}
		</AnalysisViewContainer>
	);
}

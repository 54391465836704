import type { Action } from 'types/store/types';

export interface State {
	topics: HelpTopicList;
	fetched: boolean;
	searchTerm: string;
}

export type HelpTopicList = HelpTopicCategory[];

export interface HelpTopicCategory {
	categoryName: string;
	categoryTopics: HelpTopic[];
}

export interface HelpTopicBase {
	title: string;
	url: string;
	topicName: string;
	topicFilters: string[];
}

export interface HelpTopic extends HelpTopicBase {
	content: React.ReactNode;
}

export enum ActionTypes {
	GET_HELP_TOPICS = 'data/help/GET_HELP_TOPICS',
	SET_HELP_SEARCH_TERM = 'data/help/SET_HELP_SEARCH_TERM'
}

export type SetHelpSearchTermAction = Action<
	ActionTypes.SET_HELP_SEARCH_TERM,
	{
		term: string;
	}
>;

export type GetHelpTopicsAction = Action<
	ActionTypes.GET_HELP_TOPICS,
	{
		topics: HelpTopicList;
	}
>;

export type Actions = GetHelpTopicsAction | SetHelpSearchTermAction;

import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import {
	KaplanMeierAnalysisV2,
	KaplanMeierDataModels,
	KaplanMeierResultsDataV2
} from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper,
	AnalysisStatisticsWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { KaplanChartV2 } from './KaplanChartV2';
import { KaplanStatisticsV2 } from './KaplanStatisticsV2/KaplanStatisticsV2';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import {
	useProject,
	useAreFiltersValid,
	useTranslation,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: KaplanMeierAnalysisV2;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function KaplanViewV2({ analysis, variablesData, exporting, loading }: Props) {
	const [{ data: project }] = useProject();
	const areFiltersValid = useAreFiltersValid();
	const { translate } = useTranslation();
	const {
		id,
		options: { configPanel, chartLegend = false, showCensorTicks },
		input: {
			selectedDataModel,
			series,
			variables: { durationVariable, startDate, endDate, groupVariable },
			statistics: { confidenceIntervalsV2, patientsV2, logRankV2 }
		},
		output: {
			dataset: { data: groups }
		}
	} = analysis;
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		omitVariables: [],
		series
	});
	const { variablesMap, variableSetsMap } = variablesData;

	const aggregationToVariableName =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	const kaplanMeierId = ZingChartExportTypes.KaplanMeier + id;

	const { parsedGroups } = useMemo(() => {
		let parsedGroups = groups ?? {};

		if (analysis.options.showCategoryLabels) {
			parsedGroups = cloneDeep(groups ?? {});
			const newGroup: KaplanMeierResultsDataV2 = {};

			for (const group in parsedGroups) {
				const parsedKey =
					(groupVariable &&
						mapVariableCategoryValueToLabel(
							group,
							variablesMap[groupVariable?.name ?? ''] ??
								variablesMap[
									aggregationToVariableName[groupVariable?.name ?? ''].aggregator
										.variableName
								]
						)) ??
					group;

				newGroup[parsedKey] = parsedGroups[group];
			}
			parsedGroups = newGroup;
		}

		return { parsedGroups };
	}, [groups, groupVariable, variablesMap, analysis.options.showCategoryLabels]);

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				kaplanMeierId,
				getExportFileName(
					ExportFileNames.KaplanMeierChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX:
			selectedDataModel !== KaplanMeierDataModels.duration
				? selectItemsLabelMap[endDate?.name ?? ''] +
				  ' - ' +
				  selectItemsLabelMap[startDate?.name ?? '']
				: selectItemsLabelMap[durationVariable?.name ?? ''],
		labelY: translate(dict => dict.analysis.analyses.kaplan.yAxis)
	};

	const legendHeader = groupVariable ? selectItemsLabelMap[groupVariable?.name ?? ''] : undefined;

	const areStatisticsEnabled = Object.values(analysis.input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.KaplanV2}>
				<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
					<KaplanChartV2
						statistics={{
							logRankV2,
							patientsV2,
							confidenceIntervalsV2
						}}
						name={durationVariable?.name ?? ''}
						dataset={parsedGroups}
						confidenceIntervals={confidenceIntervalsV2}
						isLegendEnabled={chartLegend}
						showChartTicks={showCensorTicks}
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						loading={loading}
					/>
				</AnalysisChartContainer>
				<AnalysisExportWrapper>
					<KaplanChartV2
						statistics={{
							logRankV2,
							patientsV2,
							confidenceIntervalsV2
						}}
						id={kaplanMeierId}
						name={durationVariable?.name ?? ''}
						dataset={parsedGroups}
						confidenceIntervals={confidenceIntervalsV2}
						isLegendEnabled
						legendHeader={legendHeader}
						showChartTicks={showCensorTicks}
						isForExport
						scalesLabels={scalesLabels}
						loading={loading}
					/>
				</AnalysisExportWrapper>

				{renderStatistics && (
					<AnalysisStatisticsWrapper>
						<KaplanStatisticsV2 analysis={analysis} />
					</AnalysisStatisticsWrapper>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}

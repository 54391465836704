import { Draggable, DraggingStyle, Droppable } from 'react-beautiful-dnd';
import produce from 'immer';
import { nanoid as generate } from 'nanoid';
import { ModulesContainer, DraggableCard } from 'components/Forms';
import { Svgs } from 'environment';
import { FormElement } from 'store/data/forms';
import { DragAndDropTypes, ElementType, SetState } from 'types/index';
import { getCloneStyle } from 'helpers/templates';
import { AddVariableIcon } from '../VariablesAndGroups/DraggableFormVariableCard.style';
import { FormElementsContainer, SwitchContainer } from './DraggableFormElements.style';
import { Switch } from 'components/UI/Interactables/Switch';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { useAppendFormElement, useForm, useToggleFormTitle, useTranslation } from 'hooks/store';

function firstLetterToUpperCase(label: string) {
	return label.charAt(0).toUpperCase() + label.slice(1);
}

interface DesignerProps {
	elements: FormElement[];
	writeAccess: boolean;
	setInitialFormElements: SetState<FormElement[]>;
}

type Options = Exclude<
	ElementType,
	| ElementType.Title
	| ElementType.Checkboxes
	| ElementType.Dropdown
	| ElementType.File
	| ElementType.Input
	| ElementType.Radiobuttons
	| ElementType.Slider
>;

export function DraggableFormElements({
	elements,
	writeAccess,
	setInitialFormElements
}: DesignerProps) {
	const { translate } = useTranslation();

	const [{ data: form }] = useForm({ lazy: true });

	const toggleFormTitle = useToggleFormTitle();
	const appendFormElement = useAppendFormElement();
	const canAppend = writeAccess;

	function handleAppendFormElement(element: FormElement) {
		appendFormElement({ element });

		// GENERATE NEW ELEMENTS IDS - FIXES PRJCTS-3166
		setInitialFormElements(initialFormElements =>
			produce(initialFormElements, draft => {
				draft.forEach(element => (element.elementId = generate()));
			})
		);
	}

	return (
		<>
			<Droppable droppableId={DragAndDropTypes.DroppableFormElement} isDropDisabled>
				{provided => (
					<ModulesContainer ref={provided.innerRef}>
						<FormElementsContainer>
							<SwitchContainer>
								<Switch
									label={translate(dict => dict.formDesigner.formTitle)}
									onChange={() => toggleFormTitle()}
									disabled={!writeAccess}
									on={!!form?.titleEnabled}
								/>
							</SwitchContainer>

							{elements.map((element, index) => (
								<Draggable
									key={element.elementId}
									isDragDisabled={!writeAccess}
									draggableId={element.elementId}
									index={index}
								>
									{({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
										<>
											<DraggableCard
												data-test-id={translate(
													dict =>
														dict.terms[element.elementType as Options]
												)}
												ref={innerRef}
												{...draggableProps}
												{...dragHandleProps}
												style={getCloneStyle(
													draggableProps.style as DraggingStyle,
													snapshot
												)}
											>
												<Typography.Paragraph ellipsis>
													{translate(
														dict =>
															dict.terms[
																element.elementType as Options
															]
													)}
												</Typography.Paragraph>
												{canAppend && (
													<AddVariableIcon className="add-variable-icon">
														<Icon
															svg={Svgs.Add}
															onClick={() =>
																handleAppendFormElement(element)
															}
															active
														/>
													</AddVariableIcon>
												)}
											</DraggableCard>
											{snapshot.isDragging && (
												<DraggableCard>
													<Typography.Paragraph ellipsis>
														{firstLetterToUpperCase(
															element.elementType
														)}
													</Typography.Paragraph>
												</DraggableCard>
											)}
										</>
									)}
								</Draggable>
							))}
						</FormElementsContainer>
						{/* Hide placeholder for clonable elements */}
						<div style={{ visibility: 'hidden', height: 0 }}>
							{provided.placeholder}
						</div>
					</ModulesContainer>
				)}
			</Droppable>
		</>
	);
}

import styled, { css } from 'styled-components';

import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect/CreatableSelect';

import { MediaQueries, Colors } from 'environment';

interface ContainerProps {
	hasChanges: boolean;
}
export const Container = styled.div<ContainerProps>`
	display: flex;
	width: 100%;

	label {
		color: ${Colors.gray.darkest};
		margin-bottom: 1rem;
	}

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
	}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`}
`;

interface CreatableSelectProps {
	color: string;
}
export const CreatableSelectStatuses = styled(CreatableSelect)<CreatableSelectProps>`
	max-width: 29rem;

	@media ${MediaQueries.maxWidth.md} {
		max-width: unset;
	}

	${({ color }) =>
		color &&
		css`
			.select__control,
			.select__control:hover {
				border: 0.1rem solid ${color};
			}

			.select__single-value {
				color: ${color};
			}
		`}
`;

export const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	padding: 1rem;

	/* due time */
	> :first-child {
		/* width: unset; */
		padding-right: 1.5rem;
	}

	/* time */
	> :last-child {
		width: 12rem;
		min-width: 12rem;
	}

	@media ${MediaQueries.maxWidth.md} {
		> :first-child {
			width: 100%;
		}

		/* time */
		> :last-child {
			width: 100%;
		}
	}
`;

export const SelectWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 1rem;
	width: 100%;

	/* show tooltip on hover */
	:hover .tooltip__icon {
		visibility: visible;
	}
`;

import { useSelector } from 'hooks/utils';
import { Result } from 'hooks/store/types';
import { ActionTypes, PaymentMethod } from 'store/account/payments';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useSelectedPaymentMethod(): Result<PaymentMethod | null> {
	const data = useSelector(state => state.account.payments.selectedPaymentMethod);

	const [{ loading: loadingPayments, error: errorPayments }] = useActivity(
		ActionTypes.GET_SUBSCRIPTION_PAYMENTS
	);
	const [{ loading: loadingPayment, error: errorPayment }] = useActivity(
		ActionTypes.GET_SUBSCRIPTION_PAYMENT_METHODS
	);
	const [{ loading: loadingBilling, error: errorBilling }] = useActivity(
		ActionTypes.GET_USER_BILLING_INFO
	);

	const fetched = data !== null;

	return {
		data,
		loading: loadingPayment || loadingBilling || loadingPayments,
		error: errorPayment || errorBilling || errorPayments,
		fetched
	};
}

import { Dictionary } from 'environment';
import type { Action } from 'types/store/types';
import { LanguageType } from 'types/index';

export interface State {
	language: LanguageType;
	translations: typeof Dictionary | null;
}

export enum ActionTypes {
	GET_LANGUAGE = 'ui/i18n/GET_LANGUAGE',
	SET_LANGUAGE = 'ui/i18n/SET_LANGUAGE'
}

export type GetLanguageAction = Action<
	ActionTypes.GET_LANGUAGE,
	{
		translations: typeof Dictionary;
	}
>;

export type SetLanguageAction = Action<
	ActionTypes.SET_LANGUAGE,
	{
		language: LanguageType;
	}
>;

export type Actions = GetLanguageAction | SetLanguageAction;

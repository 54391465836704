import { useUsername } from 'hooks/store/auth/useUsername';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	getSubscription,
	selectSubscriptionDetails,
	selectSubscriptionUsers,
	ActionTypes,
	SubscriptionDetails,
	SubscriptionUser,
	SubscriptionName,
	UserRole,
	SubscriptionStatus,
	SubscriptionUsersById
} from 'store/account/subscription';

interface SubscriptionData {
	details: SubscriptionDetails | null;
	users: SubscriptionUser[];
	status: SubscriptionStatus | null;
	subscriptionTypes: {
		isNonSubscriber: boolean;
		isLedidiFree: boolean;
		isLedidiFreeTrial: boolean;
		isLedidiCollaborator: boolean;
		isLedidiCore: boolean;
		isLedidiEnterprise: boolean;
	};
	userTypesAndRoles: {
		ledidiCore: {
			isOwner: boolean;
			isUser: boolean;
		};
		ledidiEnterprise: {
			modules: {
				hasEnterpriseAdminRights: boolean;
			};
			isOrganizationAdmin: boolean;
			isSuperAdmin: boolean;
			isAdmin: boolean;
			isUser: boolean;
		};
		isSubscriptionOwner: boolean;
		isPaidSubscriptionOwner: boolean;
		isSubscriptionLicenceUser: boolean;
	};
}

export function useSubscription(): OperationResult<SubscriptionData> {
	const dispatch = useDispatch();

	const username = useUsername();

	const details = useSelector(state => selectSubscriptionDetails(state.account.subscription));

	const status = useSelector(state => state.account.subscription.subscriptionStatus);

	const subscriptionUsers: SubscriptionUsersById = useSelector(state =>
		selectSubscriptionUsers(state.account.subscription)
	);

	const loggedInSubscriptionUser = username ? subscriptionUsers[username] : undefined;

	const isNonSubscriber = useSelector(state => state.account.subscription.isNonSubscriber);

	const fetched = !!isNonSubscriber || details !== null;

	const [{ loading, error }] = useActivity(ActionTypes.GET_SUBSCRIPTION);

	function handler() {
		dispatch(getSubscription());
	}

	// The user Roles are found in the list of Subscripiton Users for Enterprise Subscripton.
	// A User that is not Admin will not have access to the list of
	// subscription users, so loggedInSubscriptionUser will be null
	// but his role is User
	function getUserRole() {
		if (loggedInSubscriptionUser) return loggedInSubscriptionUser.userRole;

		if (isLedidiEnterprise) return UserRole.User;
	}

	// SUBSCRIPTION TYPES
	const isLedidiFree = !!details && details.subscriptionName === SubscriptionName.LedidiFree;
	const isLedidiFreeTrial =
		!!details && details.subscriptionName === SubscriptionName.LedidiFreeTrial;
	const isLedidiCollaborator =
		!!details && details.subscriptionName === SubscriptionName.LedidiCollaborator;
	const isLedidiCore = !!details && details.subscriptionName === SubscriptionName.LedidiCore;
	const isLedidiEnterprise =
		!!details && details.subscriptionName === SubscriptionName.LedidiEnterprise;

	// USER TYPES & ROLES

	const isSubscriptionOwner = !!details && details.ownerId === username;

	// ledidi-core user type can be: user and owner
	const isLedidiCoreOwner = isLedidiCore && isSubscriptionOwner;
	const isLedidiCoreUser = isLedidiCore && !isSubscriptionOwner;

	const userRole = getUserRole();

	// ledid-enterprise user roles: superOrgAdmin, orgAdmin, admin, normal
	const isLedidiEnterpriseOrganizationAdmin =
		isLedidiEnterprise && !!details && userRole === UserRole.OrganizationAdmin;

	const isLedidiEnterpriseSuperAdmin =
		isLedidiEnterprise && !!details && userRole === UserRole.SuperAdmin;

	const isLedidiEnterpriseAdmin = isLedidiEnterprise && !!details && userRole === UserRole.Admin;

	const isLedidiEnterpriseUser =
		isLedidiEnterprise && !!details && (userRole === UserRole.User || !userRole);

	const isPaidSubscriptionOwner =
		isLedidiCoreOwner || isLedidiEnterpriseOrganizationAdmin || isLedidiCollaborator;

	const isSubscriptionLicenceUser = isLedidiCoreUser || isLedidiEnterpriseUser;

	const hasEnterpriseAdminRights =
		isLedidiEnterpriseOrganizationAdmin ||
		isLedidiEnterpriseSuperAdmin ||
		isLedidiEnterpriseAdmin;

	const data = {
		details,
		users: Object.values(subscriptionUsers),
		status,
		subscriptionTypes: {
			isNonSubscriber,
			isLedidiFree,
			isLedidiFreeTrial,
			isLedidiCollaborator,
			isLedidiCore,
			isLedidiEnterprise
		},
		userTypesAndRoles: {
			ledidiCore: {
				isOwner: isLedidiCoreOwner,
				isUser: isLedidiCoreUser
			},
			ledidiEnterprise: {
				modules: { hasEnterpriseAdminRights },
				isOrganizationAdmin: isLedidiEnterpriseOrganizationAdmin,
				isSuperAdmin: isLedidiEnterpriseSuperAdmin,
				isAdmin: isLedidiEnterpriseAdmin,
				isUser: isLedidiEnterpriseUser
			},
			isSubscriptionOwner,
			isPaidSubscriptionOwner,

			isSubscriptionLicenceUser
		}
	};

	return [{ loading, error, data, fetched }, handler];
}

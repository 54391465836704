import { State } from './types';

const initialState: State = {
	projectId: null,
	byProjectId: {},
	metadata: {
		pageSize: 10,
		pageIndex: 0,
		term: ''
	},
	surveyParams: {
		manual: null
	}
};

export default initialState;

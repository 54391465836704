import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';

import { getUsersByEmails } from 'store/data/accounts';

export function useMissingUsersInfo(): LocalOperation<(emails: string[]) => void> {
	const projectId = useSelector(state => state.data.projects.projectId) ?? '';
	const dispatch = useDispatch();

	function handler(emails: string[]) {
		dispatch(getUsersByEmails(projectId, emails));
	}

	return handler;
}

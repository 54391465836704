import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateOrganization } from 'store/data/collaborators';

type HandlerInput = Parameters<typeof updateOrganization>[0];

export function useUpdateOrganization(): Operation<(input: HandlerInput) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.UPDATE_ORGANIZATION);

	async function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		await dispatch(updateOrganization(input));
	}

	return [{ loading, error }, handler];
}

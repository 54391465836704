import styled from 'styled-components';

import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-bottom: 1.6rem;
	background-color: ${Colors.white};
`;

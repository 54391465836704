import styled from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { MediaQueries, Svgs } from 'environment';

export const ErrorMessage = styled(Typography.Paragraph)`
	max-width: 50%;
	text-align: center;
`;

export const ErrorContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 17.5rem;
	padding: 2rem;

	@media ${MediaQueries.maxWidth.lg} {
		width: 100%;
	}
`;

export const Icon = styled(Svgs.EmptyStatesNoVariable)`
	@media ${MediaQueries.maxWidth.sm} {
		height: calc(100vw - 22rem);
	}
`;

import { Typography } from 'components/UI/Typography';
import { Svgs, Colors } from 'environment';
import { useTranslation } from 'hooks/store';

import { Container } from './PatientMessage.style';

enum PatientMessages {
	invalidURL = 'invalidURL',
	entryExpired = 'entryExpired',
	thankYouMessage = 'thankYouMessage'
}

interface Props {
	message: (type: typeof PatientMessages) => string;
}

export function PatientMessage({ message }: Props) {
	const { translate } = useTranslation();

	function getMessage() {
		const dictKey = `promPatient.${message(PatientMessages)}`;

		return translate(() => dictKey);
	}

	return (
		<Container>
			<Svgs.LedidiLogoColored height={200} width={200} />

			<Typography.H1
				fontweight={w => w.normal}
				color={Colors.primary.normal}
				marginOffset={{ bottom: 2 }}
			>
				{getMessage()}
			</Typography.H1>

			<Typography.H4 marginOffset={{ bottom: 4 }}>
				{translate(dict => dict.promPatient.closeWindowNotice)}
			</Typography.H4>
		</Container>
	);
}

import { forwardRef } from 'react';

import { Textarea } from './TextareaInput.style';

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

interface Props extends TextareaProps {
	error?: boolean;
}

export const TextareaInput = forwardRef<HTMLTextAreaElement, Props>(({ error, ...props }, ref) => {
	return <Textarea ref={ref} {...props} $error={error} id={props.id} />;
});

import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Variable, VariableCategory } from 'api/data/variables';
import { BooleanMap } from 'types/index';
import { CategoryValueModal } from '../CategoryValueModal';
import { DeleteCategoryValuesModal } from '../DeleteCategoryValuesModal';
import { ImportCategoryValuesModal } from '../ImportCategoryValuesModal';
import { MergeCategoryValuesModal } from '../MergeCategoryValuesModal';
import { MoveCategoryValuesModal } from '../MoveCategoryValuesModal';
import { buildBooleanMap } from 'helpers/maps';
import { buildVariableCategoriesMap } from 'helpers/variables';
import { useModalState } from 'hooks/ui';
import { useMutableState, usePrevious } from 'hooks/utils';

export interface CategoryValuesSelectedState {
	selected: string[];
	all: boolean;
	partial: boolean;
	one: boolean;
	inTotal: number;
}

export interface CategoryValuesActions {
	onSelect: (categoryId: string) => void;
	onSelectAll: () => void;
	isSelected: (categoryId: string) => boolean;
	reset: () => void;
}

interface CategoryValuesSelectedOutput {
	selectedState: CategoryValuesSelectedState;
	actions: CategoryValuesActions;
}

interface CategoryValuesSelectedProps {
	variable: Variable;
}

interface CategoryValueProps {
	description: string;
	id: string;
	label: string;
	value: string;
}
interface CategoryChangedValuesProps {
	categoryValue: CategoryValueProps;
	type: string;
	variableName: string;
}

export function useCategoryValuesSelectedController({
	variable
}: CategoryValuesSelectedProps): CategoryValuesSelectedOutput {
	const { categories } = variable;

	const initialSelectedMap = useMemo(
		() =>
			buildBooleanMap(
				categories.map(c => c.id),
				false
			),
		[categories]
	);
	const [selectedMap, setSelectedMap] = useMutableState<BooleanMap>(initialSelectedMap);

	// SYNC `selectedMap` STATE
	const prevCategories = usePrevious(categories);
	useEffect(() => {
		if (isEqual(prevCategories, categories)) return;

		const newSelectedMap = categories.reduce<BooleanMap>((acc, category) => {
			acc[category.id] = selectedMap[category.id] ?? false;

			return acc;
		}, {});

		setSelectedMap(newSelectedMap);
	}, [categories]);

	function getSelectedState(state: BooleanMap): CategoryValuesSelectedState {
		const keys = Object.keys(state);
		const values = Object.values(state);

		const selected = keys.filter(key => state[key]);
		const all = values.length > 0 && values.every(value => value);
		const partial = values.some(value => value) && !all;
		const one = selected.length === 1;
		const inTotal = selected.length;

		return { selected, all, partial, one, inTotal };
	}

	function onSelect(categoryId: string) {
		setSelectedMap(state => {
			state[categoryId] = !state[categoryId];
		});
	}

	function onSelectAll() {
		setSelectedMap(state => {
			const flag = !getSelectedState(state).all;

			Object.keys(state).forEach(categoryId => (state[categoryId] = flag));
		});
	}

	function isSelected(categoryId: string): boolean {
		return selectedMap[categoryId];
	}

	function reset() {
		setSelectedMap(state => {
			Object.keys(state).forEach(categoryId => (state[categoryId] = false));
		});
	}

	const selectedState = useMemo(() => getSelectedState(selectedMap), [selectedMap]);

	return {
		selectedState,
		actions: {
			onSelect,
			onSelectAll,
			isSelected,
			reset
		}
	};
}

////////////////////////////////////////////////////
////////////////////////////////////////////////////

export function useCategoryValuesModalsController({
	variable,
	selectedStateActions,
	createCallback
}: {
	variable: Variable;
	selectedStateActions: CategoryValuesActions;
	createCallback?(): void;
}) {
	const categoriesMap = buildVariableCategoriesMap(variable.categories, 'id');

	const [categoryValueModalState, setCategoryValueModalState] = useMutableState<{
		visible: boolean;
		categoryValue?: VariableCategory;
	}>({
		visible: false
	});

	const categoryValueModal = {
		visible: categoryValueModalState.visible,
		categoryValue: categoryValueModalState.categoryValue,
		open: (categoryId?: string) => {
			setCategoryValueModalState(state => {
				state.visible = true;

				if (categoryId && categoryId in categoriesMap) {
					state.categoryValue = categoriesMap[categoryId];
				}
			});
		},
		close: (success?: boolean) => {
			if (success) {
				selectedStateActions.reset();
				if (!categoryValueModal.categoryValue) createCallback?.();
			}
			setCategoryValueModalState({ visible: false });
		}
	};

	const [deleteCategoryValuesModalState, setDeleteCategoryValuesModalState] = useState<{
		visible: boolean;
		categoryValueIds: string[];
	}>({
		visible: false,
		categoryValueIds: []
	});
	const deleteCategoryValuesModal = {
		visible: deleteCategoryValuesModalState.visible,
		categoryValueIds: deleteCategoryValuesModalState.categoryValueIds,
		open: (categoryValueIds: string[]) => {
			setDeleteCategoryValuesModalState({
				visible: true,
				categoryValueIds
			});
		},
		close: (success?: boolean) => {
			if (success) selectedStateActions.reset();
			setDeleteCategoryValuesModalState({
				visible: false,
				categoryValueIds: []
			});
		}
	};

	const [importCategoryValuesModalVisible, setImportCategoryValuesModalVisible] = useState(false);
	const importCategoryValuesModal = {
		visible: importCategoryValuesModalVisible,
		open: () => setImportCategoryValuesModalVisible(true),
		close: (success?: boolean) => {
			if (success) selectedStateActions.reset();
			setImportCategoryValuesModalVisible(false);
		}
	};

	const [mergeCategoryValuesModalState, setMergeCategoryValuesModalState] = useState<{
		visible: boolean;
		categoryValueIds: string[];
	}>({
		visible: false,
		categoryValueIds: []
	});
	const mergeCategoryValuesModal = {
		visible: mergeCategoryValuesModalState.visible,
		categoryValueIds: mergeCategoryValuesModalState.categoryValueIds,
		open: (categoryValueIds: string[]) => {
			setMergeCategoryValuesModalState({
				visible: true,
				categoryValueIds
			});
		},
		close: (success?: boolean) => {
			if (success) selectedStateActions.reset();
			setMergeCategoryValuesModalState({
				visible: false,
				categoryValueIds: []
			});
		}
	};

	const moveCategoryValuesModal = useModalState<{ categoryValueIds: string[] }>({
		onClose(success?) {
			if (success) selectedStateActions.reset();
		}
	});

	const turnOffCategoryValuesModal = useModalState<CategoryChangedValuesProps>();

	const modals = {
		categoryValueModal,
		deleteCategoryValuesModal,
		importCategoryValuesModal,
		mergeCategoryValuesModal,
		moveCategoryValuesModal,
		turnOffCategoryValuesModal
	};

	const modalsComponent = (
		<>
			{/* CREATE/UPDATE */}
			{categoryValueModal.visible && (
				<CategoryValueModal
					variable={variable}
					categoryValue={categoryValueModal.categoryValue}
					onClose={categoryValueModal.close}
					turnOffCategoryValuesModal={turnOffCategoryValuesModal}
				/>
			)}

			{/* IMPORT */}
			{importCategoryValuesModal.visible && (
				<ImportCategoryValuesModal
					variableName={variable.name}
					onClose={importCategoryValuesModal.close}
				/>
			)}

			{/* MERGE */}
			{mergeCategoryValuesModal.visible && (
				<MergeCategoryValuesModal
					variable={variable}
					categoryValueIds={mergeCategoryValuesModal.categoryValueIds}
					onClose={mergeCategoryValuesModal.close}
				/>
			)}

			{/* DELETE */}
			{deleteCategoryValuesModal.visible && (
				<DeleteCategoryValuesModal
					variableName={variable.name}
					categoryValueIds={deleteCategoryValuesModal.categoryValueIds}
					onClose={deleteCategoryValuesModal.close}
				/>
			)}

			{/* MOVE */}
			{moveCategoryValuesModal.visible && (
				<MoveCategoryValuesModal
					variable={variable}
					categoryValueIds={moveCategoryValuesModal.payload?.categoryValueIds ?? []}
					onClose={moveCategoryValuesModal.close}
				/>
			)}
		</>
	);

	return { modals, modalsComponent };
}

import { createSelector } from 'reselect';
import { State } from './types';
import { filter, matches } from 'lodash';

/*
 * EXTRACTOR FUNCTIONS
 */

function getProjectFiles({ projectFiles }: State) {
	return projectFiles ?? [];
}

function getProjectFileById({ projectFiles }: State, fileId: string) {
	return projectFiles?.find(file => file.fileId === fileId);
}

function getFileToView({ metadata, projectFiles }: State) {
	return filter(projectFiles, matches({ fileId: metadata.fileToView }))[0];
}

function areFilesFetched({ projectFiles }: State) {
	return projectFiles !== null;
}

function getCsvFileToView({ metadata: { csvFileToView } }: State) {
	return csvFileToView;
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectProjectFiles = createSelector([getProjectFiles], projectFiles => projectFiles);

export const selectProjectFileById = createSelector(
	[getProjectFileById],
	projectFile => projectFile
);

export const selectProjectFileToView = createSelector([getFileToView], fileToView => fileToView);

export const selectAreFilesFetched = createSelector([areFilesFetched], fetched => fetched);

export const selectCsvFromS3 = createSelector([getCsvFileToView], csvFileToView => csvFileToView);

import {
	SubscriptionPaymentMethods,
	ActionTypes,
	getSubscriptionPaymentMethods
} from 'store/account/payments';
import { OperationResult } from 'hooks/store/types';
import { useSelector, useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useEffect } from 'react';
import { useSubscription } from '../subscription/useSubscription';

export function useSubscriptionPaymentMethods(): OperationResult<SubscriptionPaymentMethods> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.account.payments.subscriptionPaymentMethods);

	const [
		{
			data: {
				subscriptionTypes: { isNonSubscriber }
			}
		}
	] = useSubscription();

	const [{ fetched: subscriptionFetched }] = useSubscription();

	const [{ loading, error }] = useActivity(ActionTypes.GET_SUBSCRIPTION_PAYMENT_METHODS);

	const fetched = data !== null;

	useEffect(() => {
		if (!loading && !error && !fetched && subscriptionFetched && !isNonSubscriber) handler();
	}, [loading, error, fetched, subscriptionFetched]);

	function handler() {
		dispatch(getSubscriptionPaymentMethods());
	}

	return [{ data, error, loading, fetched }, handler];
}

import { Typography } from 'components/UI/Typography';
import { TagWrapper } from './Tag.style';
import { Colors, Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import { StyleOffsets } from 'types/index';

interface Props {
	className?: string;
	disabled?: boolean;
	active?: boolean;
	vibrantGreen?: boolean;
	propagate?: boolean;
	width?: number;
	title: string;
	tooltipTitle?: string;
	paddingOffset?: StyleOffsets;
	marginOffset?: StyleOffsets;
	onClick?: () => void;
	onDelete?: () => void;
	dataTestid?: string;
}

export function Tag({
	className,
	active,
	vibrantGreen,
	disabled,
	propagate,
	onClick,
	onDelete,
	title,
	tooltipTitle,
	paddingOffset,
	marginOffset,
	dataTestid
}: Props) {
	function handleClick(e: React.MouseEvent) {
		handlePropagation(e);

		if (onClick) onClick();
	}

	function handleDelete(e: React.MouseEvent) {
		handlePropagation(e);

		if (onDelete) onDelete();
	}

	function handlePropagation(e: React.MouseEvent) {
		if (!propagate) e.stopPropagation();
	}

	return (
		<div className={className}>
			<TagWrapper
				data-testid={dataTestid || 'tag-wrapper'}
				active={{ primary: active, vibrantGreen }}
				disabled={disabled}
				paddingOffset={paddingOffset}
				marginOffset={marginOffset}
				onClick={handleClick}
				clickable={!!onClick}
			>
				<Typography.Caption
					title={tooltipTitle}
					color={
						vibrantGreen
							? Colors.white
							: disabled
							? Colors.text.disabled
							: active
							? Colors.primary.normal
							: Colors.text.caption
					}
				>
					{title}
				</Typography.Caption>

				{onDelete && (
					<Icon
						dataTestId="tag-close-icon"
						svg={Svgs.Close}
						size={s => s.s}
						onClick={handleDelete}
						marginOffset={{ left: 1, right: -0.4 }}
						disabled={disabled}
						active={active}
					/>
				)}
			</TagWrapper>
		</div>
	);
}

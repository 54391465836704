import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, selectRevision, getEntryRevisionData, Revision } from 'store/data/revisions';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useSelectedSeriesEntry } from '../entries/useSelectedSeriesEntry';
import { useRevisionId } from './useRevisionId';

interface Options {
	lazy?: boolean;
}

type Data = Revision | undefined;

export function useRevision(options?: Options): OperationResult<Data> {
	const dispatch = useDispatch();

	const [selectedSeries] = useSelectedSeriesEntry();
	const [revisionId] = useRevisionId();

	const isSeriesSelected = selectedSeries.setName;

	const data = useSelector(state => selectRevision(state.data.revisions));

	const [{ loading, error }] = useActivity(ActionTypes.GET_ENTRY_REVISION_DATA);

	useEffect(() => {
		const isDataFetched = data?.changes.data !== null;

		if (
			!isSeriesSelected &&
			revisionId &&
			!isDataFetched &&
			!loading &&
			!error &&
			!options?.lazy
		) {
			handler();
		}
	}, [error, loading, data]);

	function handler() {
		dispatch(getEntryRevisionData());
	}

	return [{ data, loading, error }, handler];
}

import styled from 'styled-components';

import { Shadows } from 'environment';

interface GenericCardProps {
	clickable: boolean;
}

export const GenericCard = styled.div<GenericCardProps>`
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};

	height: 19.4rem;
	min-height: 19.4rem;

	padding: 0.8rem 1.6rem;

	cursor: ${({ clickable }) => clickable && 'pointer'};

	.self-tag,
	.owner-tag {
		position: absolute;
		top: 0.8rem;
	}

	.self-tag {
		left: 0.8rem;
	}

	.owner-tag {
		right: 0.8rem;
	}

	:hover {
		box-shadow: ${Shadows.hover};
	}
`;

import { VariableSubType } from 'api/data/variables/statics';
import { DateLabels } from 'types/index';

export enum LogicalOperator {
	And = 'and',
	Then = 'thenValueIs'
}

export const SUBTYPE_OPTIONS = [
	{
		label: DateLabels.Years,
		value: VariableSubType.Years
	},
	{
		label: DateLabels.Months,
		value: VariableSubType.Months
	},
	{
		label: DateLabels.Weeks,
		value: VariableSubType.Weeks
	},
	{
		label: DateLabels.Days,
		value: VariableSubType.Days
	},
	{
		label: DateLabels.Hours,
		value: VariableSubType.Hours
	},
	{
		label: DateLabels.Minutes,
		value: VariableSubType.Minutes
	},
	{
		label: DateLabels.Seconds,
		value: VariableSubType.Seconds
	}
];

import { ROUTES } from 'types/navigation';
import { ProjectTabs } from './ProjectTabs';
import { ProjectListTabs } from './ProjectListTabs';
import { AdminTabs } from './AdminTabs';
import { renderComponent } from '../helpers';
import { Container, LeftComponent, RightComponent } from './HeaderNavigation.style';
import { AccountUMTabs } from './AccountUMTabs';
import { Colors, Svgs, MediaQueries } from 'environment';
import { Icon } from 'components/UI/Icons';
import { Button } from 'components/UI/Interactables/Button';
import {
	useIsRouteWithProjectId,
	useRouteMatch,
	useMatchAccountUM,
	useMatchUsersAdmin,
	useMatchEnterpriseAdmin
} from 'hooks/navigation';
import { useMediaQuery } from 'hooks/utils';
import { EnterpriseAdminTabs } from './EnterpriseAdminTabs';

interface AddButtonParams {
	label: string;
	disabled?: boolean;
	action: () => void;
}

interface Props {
	leftComponent?: React.ReactNode | null;
	rightComponent?:
		| ((props: { addButton: ({}: AddButtonParams) => React.ReactNode }) => React.ReactNode)
		| React.ReactNode
		| false;
}

export function HeaderNavigation({ leftComponent, rightComponent }: Props) {
	const { isRouteWithProjectId } = useIsRouteWithProjectId();

	const isOnPromsListRoute = useRouteMatch([ROUTES.Proms]);
	const isOnProjectsListRoute = useRouteMatch([ROUTES.Projects]);

	const isOnListRoute = isOnProjectsListRoute || isOnPromsListRoute;

	const isOnAccountUMRoute = useMatchAccountUM();

	const isOnUsersAdminPage = useMatchUsersAdmin();

	const isOnEnterpriseAdminPage = useMatchEnterpriseAdmin();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.lg}`
	);

	function getTabs() {
		if (isOnListRoute) return <ProjectListTabs />;

		if (isRouteWithProjectId) return <ProjectTabs />;

		if (isOnAccountUMRoute) return <AccountUMTabs />;

		if (isOnUsersAdminPage) return <AdminTabs />;

		if (isOnEnterpriseAdminPage) return <EnterpriseAdminTabs />;
	}

	const addButton = ({ label, disabled, action }: AddButtonParams) =>
		isMobileDevice ? (
			<Icon
				svg={Svgs.Add}
				size={s => s.l}
				disabled={disabled}
				colors={{
					background: Colors.primary.normal,
					hoverBackground: Colors.primary.normal,
					color: Colors.white
				}}
				marginOffset={{ left: 2.4 }}
				variant={v => v.button}
				// it has to be within a callback due to syntetic event mutation
				onClick={() => action()}
				dataTestId="add-button"
			/>
		) : (
			<Button
				title={label}
				disabled={disabled}
				variant={v => v.primary}
				marginOffset={{ left: 2.4 }}
				onClick={action}
				dataTestId="add-button"
			/>
		);

	const minWidth = !(isOnListRoute || isOnAccountUMRoute);

	return (
		<Container className="header-navigation" minWidth={minWidth}>
			<LeftComponent className="header-navigation__left-component">
				{renderComponent(leftComponent) ?? getTabs()}
			</LeftComponent>

			<RightComponent className="header-navigation__right-component">
				{rightComponent && typeof rightComponent === 'function'
					? rightComponent({ addButton })
					: rightComponent}
			</RightComponent>
		</Container>
	);
}

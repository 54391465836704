import { Variable } from 'api/data/variables';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, createVariable } from 'store/data/variables';

export function useCreateVariable(): Operation<(variable: Variable) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_VARIABLE);

	async function handler(variable: Variable) {
		if (loading) return;
		if (error) resetError();

		await dispatch(createVariable(variable));
	}

	return [{ loading, error }, handler];
}

import styled, { css } from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	width: 100%;
`;

export const TitleInfo = styled.h6`
	margin-right: 1rem;

	color: ${Colors.text.captionSecondary};
`;

export const Title = styled.h6``;

type CancelProps = {
	showTitle?: boolean;
};
export const Cancel = styled.div<CancelProps>`
	display: ${props => (props.showTitle ? 'flex' : '')};

	${props =>
		props.showTitle
			? css`
					display: grid;
					grid-template-columns: 1fr 59.2rem 1fr 1.6rem;
					grid-template-areas: '. title . cancel';
			  `
			: css`
					display: flex;
					justify-content: flex-end;
			  `}

	padding: 2rem 4rem 2rem 0;
	height: 8rem;
	min-height: 8rem;

	@media ${MediaQueries.maxWidth.md} {
		padding: 2rem;
	}

	@media ${MediaQueries.maxWidth.sm} {
		padding: 2rem 1.5rem;
	}
`;

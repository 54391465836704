import { TagInput } from 'components/UI/TagInput';
import { Typography } from 'components/UI/Typography';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { keyMaps } from 'consts';
import { useEffect, useState } from 'react';
import { useTranslation, useAddWhitelistedDomains } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { usePrevious } from 'hooks/utils';

interface Props {
	onClose: () => void;
}

export function AddDomainsModal({ onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [domains, setDomains] = useState<string[]>([]);

	const [{ loading: addingDomains, error: errorAddingDomains }, addWhitelistedDomains] =
		useAddWhitelistedDomains();

	const wasAddingDomains = usePrevious(addingDomains);
	useEffect(() => {
		if (wasAddingDomains && !addingDomains && !errorAddingDomains) {
			setNotification({
				message: translate(({ admin }) => admin.enterprise.api.domainsAddedSuccessfully)
			});
			onClose();
		}
	}, [addingDomains, errorAddingDomains]);

	function handleDomainCreate(value: string[]) {
		setDomains(value);
	}
	const primaryButtonLoading = addingDomains;
	const primaryButtonDisabled = addingDomains || !domains?.length;

	return (
		<>
			<Modal
				closeOnBackdrop
				enterAsPrimaryOnClick
				title={translate(dict => dict.admin.enterprise.addDomainsModal.title)}
				primary={{
					label: translate(dict => dict.buttons.add),
					loading: primaryButtonLoading,
					disabled: primaryButtonDisabled,
					onClick: () => addWhitelistedDomains(domains)
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: onClose
				}}
				onClose={onClose}
				visible
				close
			>
				<Typography.Caption>
					{translate(dict => dict.admin.enterprise.addDomainsModal.caption)}
				</Typography.Caption>
				<Spacer size={s => s.s} />
				<TagInput
					items={domains}
					placeholder={translate(
						dict => dict.admin.enterprise.addDomainsModal.inputPlaceholder
					)}
					delimiters={[keyMaps.comma]}
					onChange={handleDomainCreate}
					sortable
					autoFocus
				/>
			</Modal>
		</>
	);
}

import { useLogin } from 'hooks/store/auth/useLogin';
import { useEffectOnce } from 'hooks/utils';
import { StorageKeys } from 'types/index';
import { useAccount } from './useAccount';
import { useSubscription } from './useSubscription';

export function useSyncAccountAndSubscription() {
	const [{ data: loggedIn }] = useLogin();

	const [, getAccount] = useAccount();
	const [, getSuscription] = useSubscription();

	function handleStorageChange({ key }: StorageEvent) {
		const shouldRefetchData = key === StorageKeys.LicenceModelSelfAssigned && loggedIn;

		if (shouldRefetchData) {
			getAccount();
			getSuscription();
		}
	}

	useEffectOnce(() => {
		window.addEventListener('storage', handleStorageChange);

		return () => window.removeEventListener('storage', handleStorageChange);
	});
}

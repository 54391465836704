import { ComparePairedAnalysisV2 } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { ComparePairedTTestV2 } from './ComparePairedTTestV2';
import { ComparePairedWilcoxonV2 } from './ComparePairedWilcoxonV2';

interface Props {
	analysis: ComparePairedAnalysisV2;
}

export function ComparePairedStatisticsV2({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		pairedTTest: {
			visible: inputStatistics.pairedTTestV2,
			results: outputStatistics.pairedTTest
		},
		pairedWilcoxon: {
			visible: inputStatistics.pairedWilcoxonV2,
			results: outputStatistics.pairedWilcoxon
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.pairedTTest.visible && (
				<ComparePairedTTestV2 results={statistics.pairedTTest.results} />
			)}

			{statistics.pairedWilcoxon.visible && (
				<ComparePairedWilcoxonV2 results={statistics.pairedWilcoxon.results} />
			)}
		</Gap>
	);
}

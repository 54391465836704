import { ActionTypes, completeFirstLogin } from 'store/auth';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from '..';

interface Handlers {
	handler: (password: string, phone: string) => void;
	resetError: () => void;
}

export function useCompleteFirstLogin(): Operation<Handlers> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.COMPLETE_FIRST_LOGIN);

	function handler(password: string, phone: string) {
		dispatch(completeFirstLogin(password, phone));
	}

	const handlers = {
		handler,
		resetError
	};

	return [{ loading, error }, handlers];
}

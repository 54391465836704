import {
	Analysis,
	FrequenciesAnalysis,
	FrequenciesAnalysisV2,
	KaplanMeierAnalysis,
	KaplanMeierAnalysisV2
} from 'api/data/analyses';
import { AnalysisType, AnalysisTypeV1 } from 'api/data/analyses/constants';
import { VariablesDataLocation } from 'store/data/variables';
import { StringMap } from 'types';

const ANALYSIS_TO_VARIABLE_MAPPING: Record<AnalysisTypeV1, StringMap> = {
	[AnalysisType.Kaplan]: {
		durationVariable: 'durationVariable',
		eventVariable: 'eventVariable',
		positiveEvent: 'positiveEvent',
		startDate: 'startDate',
		endDate: 'endDate',
		timeUnit: 'timeUnit',
		groupVariable: 'groupVariable',
		autofillDate: 'autoFillDate'
	},
	[AnalysisType.Frequencies]: {
		categoryVariable: 'categoryVariable'
	},
	[AnalysisType.Explore]: {},
	[AnalysisType.ComparePaired]: {},
	[AnalysisType.CorrelationsV1]: {},
	[AnalysisType.Crosstab]: {},
	[AnalysisType.DensityPlot]: {},
	[AnalysisType.LogisticRegression]: {},
	[AnalysisType.PlotNumeric]: {},
	[AnalysisType.CompareNumericV1]: {},
	[AnalysisType.TimeCourseV1]: {}
};

export function parseAnalysisInput(
	analysis: Analysis,
	variablesLocation: VariablesDataLocation['variablesLocation']
) {
	switch (analysis.type) {
		case AnalysisType.Kaplan: {
			const { positiveEvent, timeUnit, autofillDate, ...variables } = (
				analysis as KaplanMeierAnalysis
			).input.variables;

			const parsedVariables = Object.entries(variables).reduce((acc, [key, name]) => {
				const series = variablesLocation[key]?.setName;
				return {
					...acc,
					[ANALYSIS_TO_VARIABLE_MAPPING[AnalysisType.Kaplan][key]]: {
						name,
						...(series ? { series } : {})
					}
				};
			}, {} as KaplanMeierAnalysisV2['input']['variables']);

			return {
				...parsedVariables,
				positiveEvent,
				autofillDate,
				timeUnit
			} as KaplanMeierAnalysisV2['input']['variables'];
		}

		case AnalysisType.Frequencies: {
			const { categoryVariable } = (analysis as FrequenciesAnalysis).input.variables;
			const series = variablesLocation[categoryVariable]?.setName;

			const parsedVariables = {
				categoryVariable: {
					name: categoryVariable,
					...(series ? { series } : {})
				}
			};

			return {
				...parsedVariables
			} as FrequenciesAnalysisV2['input']['variables'];
		}
	}
}

import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { Columns, NumberPlotXYAnalysis } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';

import { VariablesDataSelectItems } from 'store/data/analyses';

import { ConfigContainer } from '../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';

import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';

interface Props {
	analysis: NumberPlotXYAnalysis;

	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function NumberPlotXYConfig({
	analysis,

	variablesDataSelectItems,
	loading
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		options: { chartLegend: legend },
		input: { variables }
	} = analysis;

	const [values, setValues] = useState(variables);
	const [chartLegend, setChartLegend] = useState(legend);
	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [activeColumn] = useAnalysesActiveColum();

	const { selectItemsMap, numericSelectItems, dateSelectItems } = variablesDataSelectItems;

	useEffect(() => {
		if (legend !== undefined) {
			if (!isEqual(legend, chartLegend)) {
				setChartLegend(legend);
			}
		}
	}, [legend]);

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values) || chartLegend !== legend) {
				const updatedAnalysis: NumberPlotXYAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					},
					options: { ...analysis.options, chartLegend }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values, chartLegend],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<ConfigContainer disabled={loading}>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as NumberPlotXYAnalysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.numberPlotXY.config.x_value
						)}
						items={[...dateSelectItems, ...numericSelectItems]}
						value={selectItemsMap[values.x_value]}
						onValueSelected={x_value =>
							x_value && setValues(state => ({ ...state, x_value }))
						}
						canClear={false}
						isItemDisabled={item => item.value === values.y_value[0]}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.numberPlotXY.config.y_value
						)}
						items={numericSelectItems}
						value={selectItemsMap[values.y_value[0]]}
						onValueSelected={y_value =>
							y_value && setValues(state => ({ ...state, y_value: [y_value] }))
						}
						isItemDisabled={item => item.value === values.x_value}
						canClear={false}
					/>
					{/* TODO:add grouping
					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.numberPlotXY.config.grouping_variable
						)}
						placeholder={`-`}
						items={categorySelectItems}
						value={selectItemsMap[values.grouping_variable ?? ''] ?? null}
						onValueSelected={grouping_variable =>
							setValues(state => ({
								...state,
								grouping_variable: grouping_variable ?? undefined
							}))
						}
					/> */}
				</Gap>
			</CollapsibleCard>

			{/* TODO: add Formatting when grouping available */}
			{/* FORMATTING */}
			{/* <CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Formatting
				)}
				open={!isFormattingOpen}
				onToggle={() =>
					openFormatting({ analysisId: analysis.id, formatting: !isFormattingOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<Typography.Caption fontweight={weight => weight.bold}>
						{translate(
							({ analysis }) => analysis.analyses.groupedOptions.title.chartOptions
						)}
					</Typography.Caption>
					<Switch
						label={translate(({ analysis }) => analysis.generic.chartLegend)}
						on={chartLegend}
						onChange={() => setChartLegend(state => !state)}
					/>
					<Typography.Caption fontweight={weight => weight.bold}>
						{translate(
							({ analysis }) => analysis.analyses.groupedOptions.title.categoryValues
						)}
					</Typography.Caption>

					<RadioButton
						label={translate(({ analysis }) => analysis.generic.showValues)}
						selected={!analysis.options.showCategoryLabels}
						onSelect={() => {
							const updatedAnalysis = produce(analysis, draft => {
								draft.options.showCategoryLabels = false;
							});

							updateAnalysis({ analysis: updatedAnalysis });
						}}
					/>

					<RadioButton
						label={translate(({ analysis }) => analysis.generic.showLabels)}
						selected={!!analysis.options.showCategoryLabels}
						onSelect={() => {
							const updatedAnalysis = produce(analysis, draft => {
								draft.options.showCategoryLabels = true;
							});

							updateAnalysis({ analysis: updatedAnalysis });
						}}
					/>
				</Gap>
			</CollapsibleCard> */}
		</ConfigContainer>
	);
}

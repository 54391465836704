import styled from 'styled-components';
import { MediaQueries, Colors } from 'environment';

interface ContainerProps {
	fullscreen?: boolean;
	heightOffset?: number;
}
export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	background-color: ${Colors.white};
	overflow: visible;
	padding: 0 1.6rem;
	min-height: calc(22rem + 12.8rem);
	height: calc(100vh - 22.8rem);

	@media ${MediaQueries.maxWidth.lg} {
		position: absolute;
		z-index: 998;
		width: 100%;
		left: 0;
		right: 0;
	}
`;

import styled, { css } from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Colors, Shadows } from 'environment';

interface DragCardPropsProps {
	groupedVariable?: boolean;
	isDraggingSnapshot?: boolean;
}

export const DraggableCard = styled.div<DragCardPropsProps>`
	width: 100%;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	display: flex;
	position: relative;
	background-color: ${Colors.white};
	align-items: center;
	transition: box-shadow 0.05s;

	:hover {
		box-shadow: ${Shadows.hover};

		.add-group-icon,
		.times-group-used,
		.add-variable-icon,
		.add-grouped-variable-icon,
		.times-variable-used,
		.times-grouped-variable-used {
			visibility: visible;
		}
	}

	${({ isDraggingSnapshot, groupedVariable }) =>
		!isDraggingSnapshot &&
		groupedVariable &&
		css`
			width: calc(100% - 3.2rem);
		`}
`;

export const CardContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const CardHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.8rem;
	padding-left: 1.2rem;
	justify-content: space-between;
	overflow: hidden;

	:hover {
		> :last-child {
			visibility: visible;
		}
	}
`;

export const VanishingActionIcon = styled(DefaultIcon)`
	visibility: hidden;
`;

export const CardBody = styled.div`
	padding: 1.6rem;
`;

interface DraggingProps {
	isDragging: boolean;
}

export const GroupedIconWrapper = styled.div<DraggingProps>`
	transition: width 0.1s;

	> div {
		height: 4rem;
	}

	${({ isDragging }) =>
		isDragging &&
		css`
			width: 0;
		`};
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const VariableContainer = styled.div`
	width: 100%;
`;

export const EmptyListWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Container = styled.div<{ insideTemplate?: boolean }>`
	width: 100%;
	display: flex;
	align-items: center;

	${({ insideTemplate }) =>
		insideTemplate &&
		css`
			width: 94%;
		`};
`;

import { getTypographyCSS } from 'components/UI/Typography';
import { Colors, Shadows } from 'environment';
import { generateSpacingOffsets } from 'helpers/cssGenerators';
import styled, { css } from 'styled-components';
import { SpacingOffsets } from 'types/index';

export const ComponentContainer = styled.div<SpacingOffsets>`
	${({ paddingOffset, marginOffset }) => css`
		position: relative;
		display: flex;
		padding-left: 0.5rem;
		padding-right: 0.3rem;

		/* SPACING OFFSETS */
		${generateSpacingOffsets({ paddingOffset, marginOffset })}
	`};
`;

interface FloatingContainerProps {
	hasLabel?: boolean;
}

export const FloatingContainer = styled.div<FloatingContainerProps>`
	position: absolute;
	right: 0.5rem;
	z-index: 99;
	margin-top: 0.8rem;

	width: 98%;
	min-width: 20.8rem;
	background-color: ${Colors.white};
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};

	.checkbox p {
		margin-left: 0.4rem;
	}
`;

export const ItemsContainer = styled.div`
	overflow-y: auto;
	max-height: 22vh;
	margin-left: 0.8rem;
`;

export const ClearInput = styled.input`
	/* TYPOGRAPHY SETTER */
	${getTypographyCSS(t => t.Paragraph)}

	/* REMOVE AUTOFIL BACKGROUND COLOR */
	:-webkit-autofill,
	:-webkit-autofill:hover,
	:-webkit-autofill:focus,
	:-webkit-autofill:active {
		box-shadow: 0 0 0px 100rem white inset !important;
	}

	border: none;
	outline: none;
	padding: 0;
	flex: 1;

	::placeholder,
	::-webkit-input-placeholder {
		color: ${Colors.text.caption};
	}
`;

interface ContainerProps {
	disabled?: boolean;
	hasError?: boolean;
	expanded?: boolean;
}

export const Container = styled.div<ContainerProps>`
	${({ disabled, hasError, expanded }) => css`
		display: flex;
		flex-wrap: wrap;
		flex-direction: ${expanded ? 'column' : 'row'};
		justify-content: space-between;
		border: 0.1rem solid ${Colors.text.disabled};
		border-radius: 0.4rem;
		width: 100%;
		padding: 0.8rem;
		transition: all 0.2s;
		min-height: 4rem;
		gap: 0;

		${!hasError &&
		css`
			:focus-within {
				border: 0.1rem solid ${Colors.primary.normal};
			}
		`}

		${hasError &&
		css`
			border: 0.1rem solid ${Colors.text.error};
		`}
		${disabled &&
		css`
			cursor: not-allowed;
			background-color: ${Colors.background.disabled};
			border: 0;

			:focus-within {
				border: 0;
			}

			${ClearInput} {
				background-color: ${Colors.background.disabled};
				cursor: not-allowed;
				pointer-events: none;
			}
		`};
	`}
`;

interface IconContainerProps {
	hasUnrenderedItems?: boolean;
}

export const DeleteIconContainer = styled.div<IconContainerProps>`
	height: fit-content;
	align-self: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	${({ hasUnrenderedItems }) =>
		!hasUnrenderedItems &&
		css`
			margin-right: 0.8rem;
		`};
`;

export const RemainingItems = styled.div`
	${getTypographyCSS(t => t.Paragraph)}
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
`;

interface OptionContainerProps {
	expanded?: boolean;
	hasUnrenderedItems?: boolean;
}

export const OptionsContainer = styled.div<OptionContainerProps>`
	align-self: ${({ expanded }) => (expanded ? 'end' : 'center')};
	height: fit-content;
	display: flex;
	justify-content: flex-end;

	${({ hasUnrenderedItems }) =>
		hasUnrenderedItems &&
		css`
			width: 19%;
		`};
`;

import { useSelector } from 'hooks/utils';
import { useDispatch } from 'hooks/utils';
import { selectPagination, setPageSizeAction, setPageIndexAction } from 'store/ui/tables';
import { TableName } from 'types/index';
import { useEffect, useMemo } from 'react';

export function useTablePagination<T>(items: T[], tableName: TableName, defaultPageSize?: number) {
	const { pageIndex, pageSize } = useSelector(state =>
		selectPagination(state.ui.tables, tableName)
	);

	useEffect(() => {
		if (!pageSize && defaultPageSize) setPageSize(defaultPageSize);
	}, []);

	const dispatch = useDispatch();

	function setPageSize(pageSize: number) {
		resetPageIndex();
		dispatch(setPageSizeAction({ pageSize, tableName }));
	}

	function setPageIndex(pageIndex: number) {
		dispatch(setPageIndexAction({ pageIndex, tableName }));
	}

	function resetPageIndex() {
		if (pageIndex !== 0) setPageIndex(0);
	}

	const paginatedItems = useMemo(
		() => (pageSize ? items.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize) : items),
		[items, pageSize, pageIndex]
	);

	const pagesCount = useMemo(
		() => (pageSize ? Math.ceil(items.length / pageSize) : 0),
		[items, pageSize]
	);

	return {
		paginatedItems,
		pagesCount,
		pageIndex,
		pageSize,
		setPageSize,
		setPageIndex,
		resetPageIndex
	};
}

import { useState } from 'react';
import { isEqual } from 'lodash';

import {
	JADBioAnalysis,
	JADBioFeatureImportancePlots,
	JADBioFeatureImportanceLabels
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { SelectItem } from 'types/index';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoMessage } from 'components/UI/InfoMessage';
import { useTranslation, useUpdateAnalysis } from 'hooks/store';
import { useDebounce } from 'hooks/utils';

interface Props {
	analysis: JADBioAnalysis;
}

export function JADBioConfigFeatureImportance({ analysis }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		input: { featureImportanceType }
	} = analysis;

	const [featureImportanceDraft, setFeatureImportanceDraft] = useState(featureImportanceType);

	const JADBioFeatureImportanceItems: SelectItem[] = [
		{
			label: translate(
				() => JADBioFeatureImportanceLabels[JADBioFeatureImportancePlots.FeatureImportance]
			),
			value: JADBioFeatureImportancePlots.FeatureImportance
		},
		{
			label: translate(
				() =>
					JADBioFeatureImportanceLabels[
						JADBioFeatureImportancePlots.ProgressiveFeatureInclusion
					]
			),
			value: JADBioFeatureImportancePlots.ProgressiveFeatureInclusion
		}
	];

	useDebounce(
		() => {
			if (!isEqual(featureImportanceType, featureImportanceDraft)) {
				const updatedAnalysis: JADBioAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						featureImportanceType: featureImportanceDraft
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[featureImportanceDraft],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<>
			<CreatableSelect
				label={translate(({ analysis }) => analysis.analyses.JADBio.displayAs)}
				items={JADBioFeatureImportanceItems}
				value={JADBioFeatureImportanceItems.find(
					item => item.value === featureImportanceDraft
				)}
				onValueSelected={value =>
					value && setFeatureImportanceDraft(value as JADBioFeatureImportancePlots)
				}
				canClear={false}
			/>

			<InfoMessage
				message={
					featureImportanceType === JADBioFeatureImportancePlots.FeatureImportance
						? translate(
								dict =>
									dict.analysis.analyses.JADBio.descriptions.progressiveFeature
						  )
						: translate(
								dict =>
									dict.analysis.analyses.JADBio.descriptions
										.progressiveFeatureInclusion
						  )
				}
			/>
		</>
	);
}

import styled, { css } from 'styled-components';
import { Colors, Svgs } from 'environment';
import { generateSpacingOffsets } from 'helpers/cssGenerators';
import { SpacingOffsets } from 'types/index';
import { Icon } from './Icons';
import { Typography } from './Typography';

interface Props extends SpacingOffsets {
	message: string | React.ReactNode;
	large?: boolean;
}

export function InfoMessage({
	message,
	large,
	//
	marginOffset,
	paddingOffset
}: Props) {
	return (
		<Container marginOffset={marginOffset} paddingOffset={paddingOffset}>
			<Icon
				svg={Svgs.Information}
				size={s => (large ? s.l : s.m)}
				colors={{ color: Colors.text.caption }}
				marginOffset={{ top: large ? -0.2 : 0.2, right: 0.8 }}
			/>
			{large ? (
				<Typography.Paragraph multiline>{message}</Typography.Paragraph>
			) : (
				<Typography.Caption multiline>{message}</Typography.Caption>
			)}
		</Container>
	);
}

const Container = styled.div<SpacingOffsets>`
	${({ marginOffset, paddingOffset }) => css`
		display: flex;
		align-items: flex-start;

		/* SPACING OFFSETS */
		${generateSpacingOffsets({ paddingOffset, marginOffset })}
	`}
`;

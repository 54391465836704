import { useTranslation } from 'hooks/store';
import {
	CollapsibleContainer,
	List,
	TableCell,
	TableRow,
	WarningMessage
} from './tableParts/tableParts.style';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { dynamicTranslate } from 'helpers/dynamicTranslate';
import { Column, usePagination, useTable } from 'react-table';
import { useMemo } from 'react';
import { ErrorEntry, ErrorTableProps } from '../types';
import { toExcelColumnName } from '../helpers';
import { nanoid as generate } from 'nanoid';
import { TableColumn } from './tableParts/tableParts';
import { Pagination } from 'components/UI/Pagination';

export function MissingValueForUniqueVariableTable({
	numberOfErrors,
	errors,
	type,
	isOpen
}: ErrorTableProps) {
	const { translate } = useTranslation();

	function getWarningMessage() {
		const strTemplate = translate(dict => dict.import.importReview.numberOfErrors);

		const realErrCount = numberOfErrors;

		const displayedCount = errors.length;

		if (realErrCount === displayedCount) {
			return null;
		}

		return (
			<TableRow flex>
				<WarningMessage>
					<Icon svg={Svgs.Information} size={s => s.l} />
					<div>
						{dynamicTranslate(
							strTemplate,
							[realErrCount.toString(), displayedCount.toString()],
							{ fontWeight: 'bold' }
						)}
					</div>
				</WarningMessage>
			</TableRow>
		);
	}

	function handleChangePageIndex(index: number) {
		gotoPage(index);
	}

	function handleChangePageSize(size: number) {
		setPageSize(size);
	}

	const columns: Column<Omit<ErrorEntry, 'value'>>[] = useMemo(() => {
		return [
			{
				Header: translate(dict => dict.import.importReview.tableHeaders.row),
				accessor: `row`
			},
			{
				Header: translate(dict => dict.import.importReview.tableHeaders.celCoordinates),
				accessor: `celCoordinates`
			}
		];
	}, [type]);

	const data = useMemo(() => {
		const tableData: Omit<ErrorEntry, 'value'>[] = [];

		errors.forEach(error => {
			tableData.push({
				id: generate(),
				row: error.rowNumber.toString(),
				celCoordinates: `${toExcelColumnName(error.columnNumber)}${error.rowNumber}`
			});
		});

		return tableData;
	}, [errors]);

	const { page, headerGroups, state, pageOptions, prepareRow, gotoPage, setPageSize } = useTable(
		{
			columns: columns,
			data: data,
			initialState: { pageIndex: 0, pageSize: 50 }
		},
		usePagination
	);
	return (
		<CollapsibleContainer isOpen={isOpen}>
			{getWarningMessage()}
			<TableRow
				data-role="row"
				style={{
					position: 'relative',
					zIndex: 1000,
					width: '100%',
					borderTop: `0.1rem solid ${Colors.background.disabled}`
				}}
				flex
				borderBottom
			>
				{
					<TableColumn
						data-role="column"
						css={`
							width: 100%;
							padding: 0.8rem 0.6rem !important;
						`}
					>
						{/* ROWS PAGINATION */}
						<Pagination
							totalCount={errors.length}
							totalCountLabel={translate(dict => dict.pagination.entries)}
							filteredCount={page.length}
							extraPageSizeOptions={[5, 10]}
							pageIndex={state.pageIndex}
							pageSize={state.pageSize}
							pagesCount={pageOptions.length}
							changePage={handleChangePageIndex}
							changePageSize={handleChangePageSize}
						/>
					</TableColumn>
				}
			</TableRow>
			<TableRow
				data-role="row"
				style={{
					width: '100%',
					borderLeft: `0.1rem solid ${Colors.background.disabled}`,
					borderRight: `0.1rem solid ${Colors.background.disabled}`
				}}
				padding
				twoColumnRow
			>
				{headerGroups[0].headers.map((header, hIdx) => (
					<TableColumn
						key={`header_${hIdx}`}
						data-role="column"
						css={`
							width: 100%;
							font-weight: bold;
						`}
					>
						{header.render('Header')}
					</TableColumn>
				))}
			</TableRow>
			<List>
				{page.map(row => {
					row.cells;

					prepareRow(row);

					return (
						<TableRow
							{...row.getRowProps()}
							key={row.id}
							padding
							data-role="row"
							twoColumnRow
						>
							{row.cells.map((cell, cellIdx) => {
								return (
									<TableCell
										{...cell.getCellProps()}
										key={cell.getCellProps().key}
										paddingLeft={cellIdx === 0 ? 3.2 : 2.4}
										data-role="cell"
									>
										{cell.render('Cell')}
									</TableCell>
								);
							})}
						</TableRow>
					);
				})}
			</List>
		</CollapsibleContainer>
	);
}

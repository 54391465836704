import {
	GroupedLinearRegressionResponse,
	GetLinearRegressionStatisticsResponse,
	GetLinearRegressionStatisticsOutput
} from 'api/data/analyses';

// type guards
function isGroupedLinearRegressionsData(
	data: GetLinearRegressionStatisticsResponse['data']['result']
): data is GroupedLinearRegressionResponse[] {
	return 'length' in data;
}

export function parseLinearRegressionStatisticResponse(
	res: GetLinearRegressionStatisticsResponse
): GetLinearRegressionStatisticsOutput {
	const result: GetLinearRegressionStatisticsOutput = {
		data: null
	};

	if (!res.data.result) {
		return result;
	}

	result.data = [];

	if (isGroupedLinearRegressionsData(res.data.result)) {
		res.data.result.forEach(groupedData => {
			const { groupIdentifiers, values } = groupedData;
			result.data!.push({
				coefficients: values.coefficients,
				groupValue: groupIdentifiers?.[0].value,
				error: values.errors?.[0]?.message
			});
		});
	}

	if (!isGroupedLinearRegressionsData(res.data.result)) {
		result.data = [
			{ coefficients: res.data.result.coefficients, error: res.data.errors?.[0].message }
		];
	}

	return result;
}

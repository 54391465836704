import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	FetchedDomains,
	getDomainsForOrganization,
	selectDomainsForOrganization,
	selectOrganizationId
} from 'store/account/admin';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useOrganizationDomains(): OperationResult<FetchedDomains> {
	const dispatch = useDispatch();

	const organizationId = useSelector(state => selectOrganizationId(state.account.admin));
	const organizationDomains = useSelector(state =>
		selectDomainsForOrganization(state.account.admin, organizationId ?? '')
	)?.domains;

	const fetched = organizationDomains !== undefined;

	const [{ loading, error }] = useActivity(ActionTypes.ADMIN_GET_DOMAINS_FOR_ORGANIZATION);

	useEffect(() => {
		if (!fetched && !loading && !error && organizationId) {
			handler();
		}
	}, [loading, error, fetched]);

	function handler() {
		dispatch(getDomainsForOrganization());
	}

	return [{ loading, error, data: organizationDomains ?? { allowed: [], blocked: [] } }, handler];
}

import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
	Columns,
	LogisticRegressionAnalysisV2,
	LogisticRegressionVariablesV2,
	DataModel
} from 'api/data/analyses';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Typography } from 'components/UI/Typography';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useFilters,
	useVariablesDataSelectItems,
	useVariables
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { VariablesDataSelectItems } from 'store/data/analyses';

import {
	buildSeriesLevelVariableData,
	buildVariableCategoriesMap,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { Switch } from 'components/UI/Interactables/Switch';

import { ConfigContainer } from '../UI';
import { Nullable, SelectItem } from 'types/index';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: LogisticRegressionAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function LogisticRegressionConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const [{ areFiltersOpen }] = useFilters();
	const {
		input: { series, dataModel, ...analysisInput },
		options: { chartLegend: legend }
	} = analysis;

	const [activeColumn] = useAnalysesActiveColum();
	const [chartLegend, setChartLegend] = useState(legend);
	const [
		{ isConfigPanelOpen, isParamsOpen, isFormattingOpen },
		{ openFormatting, openParameters }
	] = useAnalysisConfigPanel(analysis.id);
	const [fullscreen] = useFullscreenAnalysis();

	const [draftAnalysisInput, setDraftAnalysisInput] = useState(analysisInput);

	const { variables: inputVariables } = draftAnalysisInput;

	const {
		selectItemsMap: mainSelectItemsMap,
		categorySelectItems: mainCategorySelectItems,
		selectItems: mainSelectItems
	} = variablesDataSelectItems;

	const [{ data: variablesData }] = useVariables();

	const { variableSets, variablesMap } = variablesData;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const {
		selectItems: seriesSelectItems,
		categorySelectItems: seriesCategorySelectItems,
		selectItemsMap: seriesSelectItemsMap
	} = seriesVariableSelectItems;

	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	const xItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}

		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [mainSelectItems, seriesSelectItems, series]);

	const yItems = useMemo(() => {
		if (series) {
			return seriesCategorySelectItems;
		}

		return mainCategorySelectItems;
	}, [series, variablesDataSelectItems, seriesSelectItems]);

	useDebounce(
		() => {
			if (
				haveVariablesChanged(analysisInput.variables, inputVariables) ||
				chartLegend !== legend
			) {
				const updatedAnalysis: LogisticRegressionAnalysisV2 = {
					...analysis,
					input: {
						...analysis.input,
						variables: {
							...analysis.input.variables,
							...inputVariables
						}
					},
					options: { ...analysis.options, chartLegend }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[draftAnalysisInput, chartLegend],
		ANALYSIS_DEBOUNCE_TIME
	);

	// // SYNC `draftAnalysisInput` STATE
	// useEffect(() => {
	// 	if (!isEqual(draftAnalysisInput, analysisInput)) setDraftAnalysisInput(analysisInput);
	// }, [analysisInput]);

	const categoriesWithLabelAndValue = useMemo(() => {
		const categories = variablesMap[inputVariables.yVariable?.name ?? '']?.categories ?? [];
		const computedCategories = categories.map(c => c.value);

		const categoriesMap = buildVariableCategoriesMap(categories);

		const values = computedCategories.map(categoryValue => ({
			label: categoriesMap[categoryValue]?.value || categoryValue,
			value: categoriesMap[categoryValue]?.value || categoryValue,
			...(categoriesMap[categoryValue]?.description.length > 0 && {
				tooltip: categoriesMap[categoryValue].description
			})
		}));

		const selectItemValues = values.map(item => item.value);
		const shouldResetOutcomes = analysis.input.variables.outcomes.find(
			oldOutcome => !selectItemValues.includes(oldOutcome)
		);

		if (shouldResetOutcomes) {
			setDraftAnalysisInput(state => ({
				...state,
				variables: {
					...state.variables,
					outcomes: []
				}
			}));
		}

		return values;
	}, [inputVariables.yVariable]);

	useEffect(() => {
		if (legend !== undefined) {
			if (!isEqual(legend, chartLegend)) {
				setChartLegend(legend);
			}
		}
	}, [legend]);

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: LogisticRegressionAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: {
					groupVariables: [],
					xVariable: null,
					yVariable: null,
					outcomes: []
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});

		setDraftAnalysisInput({
			variables: {
				groupVariables: [],
				xVariable: null,
				yVariable: null,
				outcomes: []
			}
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = {
			...analysis,
			input: {
				...analysis.input,
				variables: { outcomes: [], xVariable: null, yVariable: null, groupVariables: [] },
				series
			}
		};

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input
					// ...(series !== prevSeries ? rebuildVariables(newAnalysis) : {})
				}
			}
		});

		setDraftAnalysisInput({
			variables: { outcomes: [], xVariable: null, yVariable: null, groupVariables: [] }
		});
	}

	function onSelectVariable(
		key: keyof LogisticRegressionVariablesV2,
		variableName: Nullable<string>
	) {
		if (!variableName) {
			setDraftAnalysisInput(values => ({
				...values,
				[key]: null
			}));
			return;
		}

		const setName = variablesLocation[variableName]?.setName;
		setDraftAnalysisInput(values => ({
			...values,
			variables: {
				...values.variables,
				[key]: {
					name: variableName,
					...(setName ? { series: setName } : {})
				}
			}
		}));
	}

	function onSelectGroupVariables(groupVariable: Nullable<string>) {
		if (!groupVariable) {
			return setDraftAnalysisInput(values => ({ ...values, groupVariables: [] }));
		}

		const setName = variablesLocation[groupVariable]?.setName;

		setDraftAnalysisInput(values => ({
			...values,
			variables: {
				...values.variables,
				groupVariables: [
					{
						name: groupVariable,
						...(setName ? { series: setName } : {})
					}
				]
			}
		}));
	}

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	const usedVariableNames = useMemo(() => {
		const {
			variables: { groupVariables, xVariable, yVariable }
		} = draftAnalysisInput;
		return [
			...(groupVariables ? groupVariables.map(d => d.name) : ''),
			xVariable?.name ?? '',
			yVariable?.name ?? ''
		];
	}, [draftAnalysisInput]);

	const selectItemsMap = {
		...mainSelectItemsMap,
		...seriesSelectItemsMap
	};

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as LogisticRegressionAnalysisV2} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({
						analysisId: analysis.id,
						parameters: !isParamsOpen
					})
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.correlationsV2.config.series
							)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegressionV2.config.yVariable
						)}
						isItemDisabled={item => usedVariableNames.includes(item.value)}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						items={yItems}
						value={selectItemsMap[inputVariables.yVariable?.name ?? '']}
						onValueSelected={value => onSelectVariable('yVariable', value)}
						canClear={false}
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.logisticRegressionV2.config.outcomes
						)}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						placeholder={translate(({ radioGroups }) => radioGroups.noSelection)}
						values={inputVariables.outcomes.map(val => ({
							label: val,
							value: val
						}))}
						items={categoriesWithLabelAndValue}
						onValuesSelected={newValues =>
							newValues &&
							setDraftAnalysisInput(state => ({
								...state,
								variables: {
									...state.variables,
									outcomes: newValues
								}
							}))
						}
						onClear={() => {
							setDraftAnalysisInput(state => ({
								...state,
								variables: {
									...state.variables,
									outcomes: []
								}
							}));
						}}
						hasMultipleValues
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegressionV2.config.xVariable
						)}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						items={xItems}
						isItemDisabled={item => usedVariableNames.includes(item.value)}
						value={selectItemsMap[inputVariables.xVariable?.name ?? '']}
						onValueSelected={value => onSelectVariable('xVariable', value)}
						canClear={false}
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) =>
								analysis.analyses.logisticRegression.config.groupVariable
						)}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						items={groupingSelectItems}
						value={
							selectItemsMap[inputVariables.groupVariables?.[0]?.name ?? ''] ?? null
						}
						onValueSelected={onSelectGroupVariables}
						isItemDisabled={item => usedVariableNames.includes(item.value)}
						onClear={() =>
							setDraftAnalysisInput(state => ({
								...state,
								variables: {
									...state.variables,
									groupVariables: []
								}
							}))
						}
					/>
				</Gap>
			</CollapsibleCard>
			{/* FORMATTING */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Formatting
				)}
				open={!isFormattingOpen}
				onToggle={() =>
					openFormatting({ analysisId: analysis.id, formatting: !isFormattingOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<Typography.Caption fontweight={weight => weight.bold}>
						{translate(
							({ analysis }) => analysis.analyses.groupedOptions.title.chartOptions
						)}
					</Typography.Caption>
					<Switch
						label={translate(({ analysis }) => analysis.generic.chartLegend)}
						on={chartLegend}
						disabled={!inputVariables.groupVariables?.length}
						onChange={() => setChartLegend(state => !state)}
					/>
				</Gap>
			</CollapsibleCard>
		</ConfigContainer>
	);
}

const haveVariablesChanged = (
	own: LogisticRegressionVariablesV2,
	values: LogisticRegressionVariablesV2
) =>
	own.xVariable !== values.xVariable ||
	own.yVariable !== values.yVariable ||
	own.groupVariables?.[0] !== values.groupVariables?.[0] ||
	!isEqual(own.outcomes, values.outcomes);

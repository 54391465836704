import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateEntry } from 'store/data/entries';

type HandlerInput = Parameters<typeof updateEntry>;

export function useUpdateEntry(): Operation<(...input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_ENTRY);

	function handler(...input: HandlerInput) {
		dispatch(updateEntry(...input));
	}

	return [{ loading, error }, handler];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	updateCustomerSubscription,
	UpdateCustomerSubscriptionAction
} from 'store/account/payments';
import { ActionPayload } from 'store/types';

export function useUpdateCustomerSubscription(): Operation<
	(payload: ActionPayload<UpdateCustomerSubscriptionAction>) => void
> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_CUSTOMER_SUBSCRIPTION);

	function handler(payload: ActionPayload<UpdateCustomerSubscriptionAction>) {
		dispatch(updateCustomerSubscription(payload));
	}

	return [{ loading, error }, handler];
}

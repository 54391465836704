import { TemplateRole } from 'store/data/roles';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteTemplateRole } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	templateRole: TemplateRole;
	onClose: (success?: boolean) => void;
}

export function DeleteTemplateRoleModal({ templateRole, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: deletingRole, error: errorDeletingRole }, deleteRole] =
		useDeleteTemplateRole();

	useCompletedAction(deletingRole, errorDeletingRole, () => onClose(true));

	function handleDelete() {
		deleteRole(templateRole.id);
	}

	const title = `${translate(dict => dict.terms.delete)} ${templateRole.name}?`;

	return (
		<>
			<Modal
				size={s => s.s}
				title={title}
				primary={{
					label: translate(dict => dict.buttons.delete),
					warning: true,
					loading: deletingRole,
					onClick: handleDelete
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: onClose
				}}
				onClose={onClose}
				enterAsPrimaryOnClick
				visible
				close
			>
				<Typography.Paragraph>
					{translate(dict => dict.rolesPage.deleteRoleModal.message)}
				</Typography.Paragraph>
			</Modal>
		</>
	);
}

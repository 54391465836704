import { useMemo } from 'react';
import { ROUTES } from 'types/navigation';
import { useRouteMatch } from './utils';

export function useMatchProms() {
	const promRoutes = [
		ROUTES.Proms,
		ROUTES.CreateProm,
		ROUTES.Dashboard,
		ROUTES.Patients,
		ROUTES.PromAnalysis,
		ROUTES.PromDataset,
		ROUTES.PromEntryStatuses,
		ROUTES.CreatePromEntry,
		ROUTES.UpdatePromEntry,
		ROUTES.PromVariables,
		ROUTES.CreatePromVariable,
		ROUTES.PromDependencies,
		ROUTES.PromTemplates,
		ROUTES.PromForms,
		ROUTES.PromForm,
		ROUTES.PromDocuments,
		ROUTES.PromCollaborators,
		ROUTES.AddPromCollaborators,
		ROUTES.ImportPromDataset,
		ROUTES.PromRoles
	];

	return useRouteMatch(promRoutes);
}

export function useMatchProjects() {
	const projectRoutes = [
		ROUTES.Projects,
		ROUTES.CreateProject,
		ROUTES.ImportDataset,
		ROUTES.Analysis,
		ROUTES.Dataset,
		ROUTES.EntryStatuses,
		ROUTES.CreateEntry,
		ROUTES.UpdateEntry,
		ROUTES.Variables,
		ROUTES.CreateVariable,
		ROUTES.Dependencies,
		ROUTES.Templates,
		ROUTES.Forms,
		ROUTES.Form,
		ROUTES.Documents,
		ROUTES.Collaborators,
		ROUTES.AddCollaborators,
		ROUTES.Roles,
		ROUTES.ProjectDashboard
	];

	return useRouteMatch(projectRoutes);
}

export function useMatchAccountUM() {
	const accountUMRoutes = [
		ROUTES.AccountUMSubscription,
		ROUTES.AccountUMPayment,
		ROUTES.AccountUMBillingHistory,
		ROUTES.AccountUMAddons
	];

	return useRouteMatch(accountUMRoutes);
}

export function useMatchUsersAdmin() {
	const adminRootPath = '/admin';
	const adminRoutes = [
		`${adminRootPath}${ROUTES.AdminUsers}`,
		`${adminRootPath}${ROUTES.AdminEnterprise}`,
		`${adminRootPath}${ROUTES.AdminRoles}`
	];

	return useRouteMatch(adminRoutes);
}

export function useMatchEnterpriseAdmin() {
	const adminRootPath = '/enterprise-admin';
	const adminRoutes = [
		`${adminRootPath}${ROUTES.EnterpriseAdminDashboard}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminUsers}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminProjects}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminSubscription}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminRoles}`,
		`${adminRootPath}${ROUTES.EnterpriseAdminSettings}`
	];

	return useRouteMatch(adminRoutes);
}

export function useIsRouteWithProjectId() {
	const matchProms = useMatchProms();
	const matchProjects = useMatchProjects();

	const isRouteWithProjectId = useMemo(
		() => matchProms || matchProjects,
		[matchProms, matchProjects]
	);

	return { isRouteWithProjectId };
}

export function useMatchTemplateRoles() {
	return useRouteMatch([ROUTES.TemplateRoles]);
}

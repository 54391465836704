import styled, { css } from 'styled-components';

import { Checkbox as DefaultCheckbox } from 'components/UI/Interactables/Checkbox';
import { Colors } from 'environment';

export const CenteredCheckbox = styled(DefaultCheckbox)`
	align-items: center;
	flex-direction: column;
`;

interface ColorIndicatorProps {
	type: 'group' | 'variableSet';
}
export const ColorIndicator = styled.div<ColorIndicatorProps>`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0.5rem 0;
	height: calc(100% - 1rem);

	${({ type }) => {
		const colors = {
			group: Colors.groupOrange,
			variableSet: Colors.seriesGreen
		};

		return css`
			border-left: 0.4rem solid ${colors[type]};
		`;
	}}
`;

import { deleteVariable, ActionTypes } from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useDeleteVariable(): Operation<(variableName: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.DELETE_VARIABLE);

	function handler(variableName: string) {
		if (loading) return;
		if (error) resetError();

		dispatch(deleteVariable(variableName));
	}

	return [{ loading, error }, handler];
}

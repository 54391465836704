import { useDispatch } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { useSelector } from 'hooks/utils';
import { addModal, removeModal, AddRemoveModalAction } from 'store/ui/states';
import { ActionPayload } from 'store/types';

interface Handlers {
	addModal: (payload: ActionPayload<AddRemoveModalAction>) => void;
	removeModal: (payload: ActionPayload<AddRemoveModalAction>) => void;
}

export function useActiveModals(): LocalOperationResult<string[], Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.ui.states.activeModals);

	function addModalHandler(payload: ActionPayload<AddRemoveModalAction>) {
		dispatch(addModal(payload));
	}

	function removeModalHandler(payload: ActionPayload<AddRemoveModalAction>) {
		dispatch(removeModal(payload));
	}

	const handlers: Handlers = {
		addModal: addModalHandler,
		removeModal: removeModalHandler
	};

	return [data, handlers];
}

import { useTranslation } from 'hooks/store';
import { Asterisk } from '../Asterisk';
import { Typography } from '../Typography';

export function RequiredFields() {
	const { translate } = useTranslation();

	const text = translate(({ requiredFields }) => requiredFields);

	/**
	 * KEPT HERE FOR SAFETY - SOMETIMES THE TRANSLATIONS ARE LOADED AFTER THE RENDER
	 * CAUSING PAGE TO CRASH SINCE IT HAS NOTHING TO SPLIT
	 */
	if (!text) return null;

	const splitText = (text as string).split('\n');

	return (
		<Typography.Caption>
			{splitText[0]} <Asterisk /> {splitText[1]}
		</Typography.Caption>
	);
}

import { useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';

import { VariableType } from 'types/data/variables/constants';
import {
	TimeCourseAnalysisV2,
	TimeWindowSizeType,
	Columns,
	DataModel,
	TimeCourseVariablesV2,
	AnalysisStatisticAggregationType
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { SelectItem, GenericMap, Nullable } from 'types';

import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';

import {
	useAnalysesActiveColum,
	useAnalysisConfigPanel,
	useFilters,
	useFullscreenAnalysis,
	useTranslation,
	useUpdateAnalysis,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce, useMemoOnce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';

const ANALYSIS_STATISTIC_AGGREGATION_VALUES = [
	AnalysisStatisticAggregationType.Mean,
	AnalysisStatisticAggregationType.MeanSD,
	AnalysisStatisticAggregationType.MeanCI,
	AnalysisStatisticAggregationType.MeanRange,
	AnalysisStatisticAggregationType.Median,
	AnalysisStatisticAggregationType.MedianCI,
	AnalysisStatisticAggregationType.MedianRange,
	AnalysisStatisticAggregationType.MedianIQR
];

const TIME_WINDOW_VALUES = [
	TimeWindowSizeType.Years,
	TimeWindowSizeType.Months,
	TimeWindowSizeType.Weeks,
	TimeWindowSizeType.Days
];

const DATE_TIME_WINDOW_VALUES = [
	TimeWindowSizeType.Hours,
	TimeWindowSizeType.Minutes,
	TimeWindowSizeType.Seconds
];

const TIME_WINDOW_VALUES_EXTENDED = [...TIME_WINDOW_VALUES, ...DATE_TIME_WINDOW_VALUES];

interface Props {
	analysis: TimeCourseAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function TimeCourseConfig({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		input: { variables, series, dataModel }
	} = analysis;

	const [values, setValues] = useState(variables);

	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [activeColumn] = useAnalysesActiveColum();

	const [{ data: variablesData }] = useVariables();

	const { variablesMap, variableSetsMap, variableSets } = variablesData;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const isDateTimeVariable = useMemo(() => {
		if (!values.timeVariable) return false;

		const aggRuleToVariableMap =
			buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);
		const variable =
			variablesMap[values.timeVariable.name] ??
			variablesMap[
				aggRuleToVariableMap[values.timeVariable?.name ?? ''].aggregator.variableName
			];

		return values.timeVariable && variable?.type === VariableType.DateTime;
	}, [values.timeVariable, variablesMap, variableSetsMap]);

	const { selectItemsMap, selectItems: mainSelectItems } = variablesDataSelectItems;

	// NUMERIC VARIABLE
	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}

		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [mainSelectItems, seriesSelectItems, series]);

	// TIME VARIABLE
	const timeVariableOptions = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Date,
				VariableType.DateTime,
				VariableType.Integer,
				VariableType.Float,
				VariableType.Category,
				VariableType.TimeDuration
			]);
		}

		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Date,
			VariableType.DateTime,
			VariableType.Integer,
			VariableType.Float,
			VariableType.Category,
			VariableType.TimeDuration
		]);
	}, [series, seriesSelectItems, mainSelectItems]);

	// GROUPING
	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values)) {
				const updatedAnalysis: TimeCourseAnalysisV2 = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	const errorBars = useMemoOnce(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		ANALYSIS_STATISTIC_AGGREGATION_VALUES.forEach(errorBar => {
			const item = {
				label: translateErrorBars(errorBar),
				value: errorBar
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	});

	const timeWindowValues = useMemo(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		const COMPUTED_VALUES = isDateTimeVariable
			? TIME_WINDOW_VALUES_EXTENDED
			: TIME_WINDOW_VALUES;

		COMPUTED_VALUES.forEach(timeWindow => {
			const item = {
				label: translateTimeWindowValues(timeWindow),
				value: timeWindow
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	}, [isDateTimeVariable]);

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.generic.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.generic.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetOptions: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	// HELPERS
	const isSeriesSelected = dataModel === DataModel.series && !!series;
	const isMainLevel = dataModel === DataModel.main;
	const canSelectVariable = isMainLevel || isSeriesSelected;
	const shouldShowTimeWindow = [
		VariableType.Date,
		VariableType.DateTime,
		VariableType.TimeDuration
	].includes(variablesMap[values.timeVariable?.name ?? '']?.type);

	function translateTimeWindowValues(type: TimeWindowSizeType) {
		switch (type) {
			case TimeWindowSizeType.Years:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.years
				);
			case TimeWindowSizeType.Months:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.months
				);
			case TimeWindowSizeType.Weeks:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.weeks
				);
			case TimeWindowSizeType.Days:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.days
				);
			case TimeWindowSizeType.Hours:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.hours
				);
			case TimeWindowSizeType.Minutes:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.minutes
				);
			case TimeWindowSizeType.Seconds:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.seconds
				);

			default:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.years
				);
		}
	}

	function translateErrorBars(type: AnalysisStatisticAggregationType) {
		switch (type) {
			case AnalysisStatisticAggregationType.Mean:
				return translate(({ analysis }) => analysis.generic.errorBars.mean);
			case AnalysisStatisticAggregationType.MeanSD:
				return translate(({ analysis }) => analysis.generic.errorBars.meanSD);
			case AnalysisStatisticAggregationType.MeanCI:
				return translate(({ analysis }) => analysis.generic.errorBars.meanCI);
			case AnalysisStatisticAggregationType.MeanRange:
				return translate(({ analysis }) => analysis.generic.errorBars.meanRange);
			case AnalysisStatisticAggregationType.Median:
				return translate(({ analysis }) => analysis.generic.errorBars.median);
			case AnalysisStatisticAggregationType.MedianCI:
				return translate(({ analysis }) => analysis.generic.errorBars.medianCI);
			case AnalysisStatisticAggregationType.MedianRange:
				return translate(({ analysis }) => analysis.generic.errorBars.medianRange);
			case AnalysisStatisticAggregationType.MedianIQR:
				return translate(({ analysis }) => analysis.generic.errorBars.medianIQR);
			default:
				return translate(({ analysis }) => analysis.generic.errorBars.mean);
		}
	}

	/**
	 * HANDLERS
	 */
	function onSelectDataModel(dataModel: Nullable<string>) {
		if (!dataModel) return;

		const newAnalysis: TimeCourseAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel,
				variables: {
					...analysis.input.variables,
					timeVariable: null,
					numericVariable: null,
					groupVariables: []
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectVariable(key: keyof TimeCourseVariablesV2, variableName: Nullable<string>) {
		if (!variableName) return;
		const aggRuleToVariableMap =
			buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);
		const setName = variablesLocation[variableName]?.setName;
		const variable =
			variablesMap[variableName] ??
			variablesMap[aggRuleToVariableMap[variableName].aggregator.variableName];
		const shouldShowTimeWindow = [
			VariableType.Date,
			VariableType.DateTime,
			VariableType.TimeDuration
		].includes(variable?.type);

		setValues({
			...values,
			[key]: {
				name: variableName,
				...(setName ? { series: setName } : {})
			},
			...(key === 'timeVariable'
				? {
						...(shouldShowTimeWindow && !values.timeUnit
							? { timeUnit: TimeWindowSizeType.Days }
							: !shouldShowTimeWindow
							? { timeUnit: null }
							: {})
				  }
				: {}),
			...(key !== 'groupVariables' && { groupVariables: [] })
		});
	}

	function onSelectTimeUnit(timeUnit: Nullable<string>) {
		setValues({
			...values,
			timeUnit: timeUnit as Nullable<TimeWindowSizeType>
		});
	}

	function onSelectStatistic(statistic: Nullable<string>) {
		if (!statistic) return;
		setValues({
			...values,
			statistic: statistic as AnalysisStatisticAggregationType
		});
	}

	function onSelectGroupVariables(groupVariable: Nullable<string>) {
		if (!groupVariable) {
			return setValues({ ...values, groupVariables: [] });
		}

		const setName = variablesLocation[groupVariable]?.setName;

		setValues({
			...values,
			groupVariables: [
				{
					name: groupVariable,
					...(setName ? { series: setName } : {})
				}
			]
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: TimeCourseAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				variables: {
					...analysis.input.variables,
					numericVariable: null,
					timeVariable: null,
					groupVariables: []
				},
				series
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	// HELPERS
	const allSelectItems = { ...selectItemsMap, ...seriesSelectItemsMap };

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as TimeCourseAnalysisV2} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(({ analysis }) => analysis.generic.dataModel)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(({ analysis }) => analysis.generic.series)}
							items={variableSetOptions}
							value={variableSetOptions.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourse.config.variable
						)}
						disabled={!canSelectVariable}
						items={numericSelectItems}
						isItemDisabled={item =>
							item.value === analysis.input.variables.groupVariables?.[0]?.name ||
							item.value === analysis.input.variables.timeVariable?.name
						}
						value={
							values?.numericVariable
								? allSelectItems[values.numericVariable?.name ?? '']
								: null
						}
						onValueSelected={numericVariable =>
							onSelectVariable('numericVariable', numericVariable)
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourse.config.timeVariable
						)}
						disabled={!canSelectVariable}
						items={timeVariableOptions}
						value={
							values?.timeVariable
								? allSelectItems[values.timeVariable?.name ?? '']
								: null
						}
						isItemDisabled={item =>
							item.value === analysis.input.variables.numericVariable?.name ||
							item.value === analysis.input.variables.groupVariables?.[0]?.name
						}
						onValueSelected={timeVariable =>
							onSelectVariable('timeVariable', timeVariable)
						}
						canClear={false}
					/>
					{shouldShowTimeWindow && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.label
							)}
							items={timeWindowValues.items}
							value={
								timeWindowValues.itemsMap[values.timeUnit ?? ''] ??
								timeWindowValues.items[0]
							}
							onValueSelected={onSelectTimeUnit}
							canClear={false}
						/>
					)}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.timeCourse.config.statistic
						)}
						items={errorBars.items}
						disabled={!canSelectVariable}
						value={errorBars.itemsMap[values.statistic ?? ''] ?? errorBars.items[0]}
						onValueSelected={onSelectStatistic}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.plotNumeric.config.grouping
						)}
						disabled={!canSelectVariable}
						placeholder={translate(
							({ analysis }) => analysis.analyses.plotNumeric.config.noGrouping
						)}
						isItemDisabled={item =>
							item.value === analysis.input.variables.numericVariable?.name ||
							item.value === analysis.input.variables.timeVariable?.name
						}
						items={groupingSelectItems}
						value={allSelectItems[values.groupVariables?.[0]?.name ?? ''] ?? null}
						onValueSelected={onSelectGroupVariables}
					/>
				</Gap>
			</CollapsibleCard>
			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} isEnabled={analysis.output.grouping} />
		</ConfigContainer>
	);
}

import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';

interface TransferDropdownToggleProps {
	open: boolean;
	disabled?: boolean;
	inactive?: boolean;
}

interface IconProps {
	open: boolean;
}

export const Icon = styled(DefaultIcon)<IconProps>`
	transition: transform 0.25s;
	transform: ${({ open }) => open && 'rotate(180deg)'};
`;

export const Container = styled.div<TransferDropdownToggleProps>`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0rem 0.8rem 0rem 1rem;
	height: 5.6rem;
	border-radius: 0.4rem;
	transition: all 0.2s;

	${({ open, inactive, disabled }) =>
		css`
			border: 0.12rem solid ${open ? Colors.primary.normal : Colors.text.disabled};
			cursor: ${inactive ? 'default' : 'pointer'};
			opacity: ${disabled ? '50%' : '100%'};
		`}
`;

import styled from 'styled-components';
import { ROUTES } from 'types/navigation';
import { Typography } from 'components/UI/Typography';
import { useRouteMatch } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';

export function PatientFormTitle() {
	const { translate } = useTranslation();

	const isOnPatientEditEntryPage = useRouteMatch([ROUTES.PatientEditEntry]);

	if (!isOnPatientEditEntryPage) return null;

	return <Title>{translate(({ promPatient }) => promPatient.form.title)}</Title>;
}

const Title = styled(Typography.H4)`
	padding-left: 1rem;
	margin-bottom: 4rem;
`;

import { useMemo } from 'react';

import { CrosstabResultsDataV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useVariableLabel } from 'hooks/store';
import { AnalysisGroupedTables } from '../UI';

interface Props {
	dataset: CrosstabResultsDataV2;
}

export function CrosstabTableV2({ dataset }: Props) {
	const { columnLabels, columnVariable, counts, percentages, rowLabels, rowVariable } =
		dataset ?? {
			columnLabels: [],
			columnVariable: {},
			counts: [],
			percentages: [],
			rowLabels: [],
			rowVariable: {}
		};
	const rowDetails = useMemo(() => {
		return rowLabels.reduce<Record<string, { count: number[]; percentage: number[] }>>(
			(acc, curr, index) => {
				const key = curr;
				const percentage = percentages[index];
				const count = counts[index];
				acc[key] = {
					percentage,
					count
				};
				return acc;
			},
			{}
		);
	}, [rowLabels, counts, percentages]);

	const rowsCount = Object.entries(rowDetails).length;
	const rowVariableLabel = useVariableLabel(rowVariable?.name ?? '');
	const columnVariableLabel = useVariableLabel(columnVariable?.name ?? '');

	return (
		<AnalysisGroupedTables>
			<Table key="crosstab-table-1" maxWidth={16} fullWidth>
				<Table.Head>
					<Table.Row>
						<Table.Column
							title={rowVariableLabel}
							height={8.4}
							minWidth={12}
							maxWidth={20}
							noWrap
						>
							{rowVariableLabel}
						</Table.Column>
					</Table.Row>
				</Table.Head>
				<Table.Body>
					{rowLabels.map((rowLabel, index) => (
						<Table.Row data-testid="row-label" key={`sticky-table-row-${index}`}>
							<Table.Cell
								{...(index === rowsCount - 1 ? { bold: true } : {})}
								title={rowLabel}
								maxWidth={20}
								height={6}
								noWrap
							>
								{rowLabel}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<Table.Responsive key="crosstab-table-2" horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							<Table.Column
								//8818 LABEL perhaps
								title={columnVariableLabel}
								colSpan={columnLabels.length + 1}
								noWrap
							>
								{columnVariableLabel}
							</Table.Column>
						</Table.Row>
						<Table.Row>
							{columnLabels.map((column, index) => (
								<Table.Column
									data-testid="column-label"
									title={column}
									key={`table-column-${index}`}
									minWidth={12}
									maxWidth={16}
									noWrap
								>
									{column}
								</Table.Column>
							))}
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{Object.entries(rowDetails).map(
							([label, { count, percentage }], rowIndex) => (
								<Table.Row key={`table-row-${label}-${rowIndex}`}>
									{count.map((val, cellIndex) => (
										<Table.Cell
											{...(rowIndex === rowsCount - 1 ? { bold: true } : {})}
											key={`table-row-${rowIndex}-cell-${cellIndex}`}
											height={6}
										>
											{val}
											<br></br>
											{(percentage[cellIndex] * 100).toFixed(1)}%
										</Table.Cell>
									))}
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</AnalysisGroupedTables>
	);
}

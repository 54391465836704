import { useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Svgs, Colors } from 'environment';
import { variableCardPulse } from 'events/variables';
import { DraggableVariableCardType } from 'store/data/variables';
import { VariableCardPayload } from '../../Cards/CommonCard';
import { VariableSetConfirmDragAndDropActionModal } from '../../ConfirmDragAndDropActionModal';
import {
	CardReplacement,
	DropAbove,
	DropBelow,
	LineMarkerHorizontal,
	VariableCardContainer,
	LoadingContainer
} from '../../Cards/VariableCard/VariableCard.style';
import { Asterisk } from 'components/UI/Asterisk';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import {
	isVariablePromGenerated,
	groupHasForbiddenVariableType,
	itemIsType,
	buildVariablesDataLocation
} from 'helpers/variables';
import { useTranslation, useVariables, useAddVariableToGroup, useVariablesData } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	index: number;
	name: string;
	title: string;
	subtitle: string;
	required: boolean;
	parentGroup: string;
	parentSet?: string;
	readOnly: boolean;
	preventDrag: boolean;
	groupVariableNames: string[];
	hasError?: boolean;
	moveCard: (input: {
		variableName: string;
		sourceIndex: number;
		destinationIndex: number;
	}) => void;
	onClick: () => void;
}

export function VariableCardInGroup({
	index,
	name,
	title,
	subtitle,
	required,
	parentGroup,
	parentSet,
	readOnly,
	preventDrag,
	groupVariableNames,
	hasError,
	moveCard,
	onClick
}: Props) {
	const { setError: setErrorNotification } = useAlerts();
	const { translate } = useTranslation();
	const [
		{
			data: { groupsMap, variablesMap }
		}
	] = useVariables();

	const [{ loading: addingVariableToGroup }, addVariableToGroup] = useAddVariableToGroup({
		variableName: name,
		toOrFromSet: true
	});

	const variablesData = useVariablesData();

	const { variablesLocation, groupsLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const loading = addingVariableToGroup;

	const mainRef = useRef<HTMLDivElement>(null);
	const aboveRef = useRef<HTMLDivElement>(null);
	const belowRef = useRef<HTMLDivElement>(null);

	const [dropFunctionToCall, setDropFunctionToCall] = useState<
		('handleDropAbove' | 'handleDropBelow') | null
	>(null);

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	const [pulseCard, setPulseCard] = useState(false);

	useCustomEventListener(variableCardPulse, {
		onListen: payload => {
			if (payload.variableName === name) setPulseCard(true);
		}
	});

	// DRAG - CARD
	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: DraggableVariableCardType.Variable,
		item: (): VariableCardPayload => ({
			name,
			index,
			parentGroup,
			...(parentSet !== undefined && { parentSet })
		}),
		canDrag: !preventDrag,
		collect: monitor => ({ isDragging: monitor.isDragging() })
	});

	// DROP - ABOVE
	const [{ aboveHandlerId, isOverCurrentAbove, isSysGenVariable }, dropAbove] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable =
				draggedVariable &&
				isVariablePromGenerated({ systemGenerated: draggedVariable.systemGenerated });
			return {
				aboveHandlerId: monitor.getHandlerId(),
				isOverCurrentAbove: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;
			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToGroup)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = groupHasForbiddenVariableType(
					groupsMap[item.name],
					variablesMap,
					[VariableType.File]
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			} else {
				const isGroupPartOfSeries = !!variablesLocation[name].setName;

				const preventDrop =
					isGroupPartOfSeries && itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropAbove');

				return;
			}

			handleDropAbove(item);
		}
	});

	// DROP - BELOW
	const [{ belowHandlerId, isOverCurrentBelow }, dropBelow] = useDrop<any, any, any>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => ({
			belowHandlerId: monitor.getHandlerId(),
			isOverCurrentBelow: monitor.isOver({ shallow: true })
		}),
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;
			if (isSysGenVariable)
				return setErrorNotification({
					message: translate(dict => dict.variablesPage.errorVariableToGroup)
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop =
					groupsLocation[item.name] &&
					groupHasForbiddenVariableType(groupsMap[item.name], variablesMap, [
						VariableType.File
					]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			} else {
				const isGroupPartOfSeries = !!variablesLocation[name].setName;

				const preventDrop =
					isGroupPartOfSeries && itemIsType(variablesMap[item.name], [VariableType.File]);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}

			if (shouldTriggerConfirmModal(item)) {
				confirmActionModal.open(item);
				setDropFunctionToCall('handleDropBelow');

				return;
			}

			handleDropBelow(item);
		}
	});

	function handleDropAbove(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index;

		if (!groupVariableNames.includes(item.name)) {
			const fromMainList = !(item.parentSet || item.parentGroup);

			addVariableToGroup({
				variableName: item.name,
				groupName: parentGroup,
				setName: parentSet,
				destinationIndex,
				from: { mainList: fromMainList }
			});
		} else {
			if (sourceIndex === destinationIndex) return;

			moveCard({
				variableName: item.name,
				sourceIndex,
				destinationIndex
			});
		}
	}

	function handleDropBelow(item: VariableCardPayload) {
		const sourceIndex = item.index;
		const destinationIndex = index < sourceIndex ? index + 1 : index;

		if (!groupVariableNames.includes(item.name)) {
			const fromMainList = !(item.parentSet || item.parentGroup);

			addVariableToGroup({
				variableName: item.name,
				groupName: parentGroup,
				setName: parentSet,
				destinationIndex: index + 1,
				from: { mainList: fromMainList }
			});
		} else {
			if (sourceIndex === destinationIndex) return;

			moveCard({
				variableName: item.name,
				sourceIndex,
				destinationIndex
			});
		}
	}

	function handleFinalDrop(item: VariableCardPayload) {
		if (!dropFunctionToCall) return;

		if (dropFunctionToCall === 'handleDropAbove') handleDropAbove(item);
		if (dropFunctionToCall === 'handleDropBelow') handleDropBelow(item);
	}

	function shouldTriggerConfirmModal(item: VariableCardPayload) {
		if (parentSet) {
			const fromSameSet = item.parentSet === parentSet;

			if (!fromSameSet) return true;
		}

		return false;
	}

	drag(mainRef);
	////////////////////
	dropAbove(aboveRef);
	dropBelow(belowRef);

	const isFirstItem = index === 0;

	if (isDragging) return <CardReplacement ref={dragPreview} />;

	return (
		<>
			<VariableCardContainer
				{...(pulseCard && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={() => setPulseCard(false)}
				//
				ref={mainRef}
				hasError={hasError}
				isDragging={isDragging}
				onClick={readOnly ? undefined : onClick}
				disabled={loading}
				readOnly={readOnly}
				data-scroll-id={name}
			>
				<Flex align={a => a.center}>
					<Typography.Paragraph fontweight={w => w.normal} title={title} ellipsis>
						{title}
					</Typography.Paragraph>
					{required && (
						<Typography.Paragraph>
							<Asterisk paddingLeft />
						</Typography.Paragraph>
					)}
				</Flex>
				<Flex justify={j => j.between}>
					<Typography.Caption title={subtitle} ellipsis>
						{subtitle}
					</Typography.Caption>
					{hasError && (
						<Icon
							className="variable-card__error-indicator"
							svg={Svgs.Information}
							size={s => s.m}
							colors={{ color: Colors.text.error }}
						/>
					)}
				</Flex>

				{isFirstItem && (
					<DropAbove
						ref={aboveRef}
						data-handler-id={aboveHandlerId}
						isHovered={isOverCurrentAbove}
					>
						<LineMarkerHorizontal />
					</DropAbove>
				)}
				<DropBelow
					ref={belowRef}
					data-handler-id={belowHandlerId}
					isHovered={isOverCurrentBelow}
				>
					<LineMarkerHorizontal />
				</DropBelow>

				{loading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
			</VariableCardContainer>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="to"
					onConfirm={() => handleFinalDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}

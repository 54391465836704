import { Variable, VariableAliasesMap } from 'api/data/variables/types';
import {
	Container,
	Dropdown,
	InputContainer,
	Item,
	ValueContainer,
	Error,
	Wrapper
} from './AliasTableDropdown.style';
import { useMemo, useRef, useState } from 'react';
import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import ReactDOM from 'react-dom';
import { useOutsideClick } from 'hooks/utils';
import { useTranslation } from 'hooks/store';

type Props = {
	id: string;
	variableAliases: VariableAliasesMap;
	value?: Variable;
	variables: Variable[];
	onSelect: (variable: Variable) => void;
	error?: string;
};

export function AliasTableDropdown({
	id,
	variableAliases,
	value,
	variables,
	error,
	onSelect
}: Props) {
	const { translate } = useTranslation();

	const [show, setShow] = useState(false);
	const inputContainerRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useOutsideClick(() => setShow(false), [dropdownRef]);

	const items = useMemo(() => {
		return variables.map(variable => ({
			id: variable.name,
			label: variable.label
		}));
	}, [variables]);

	function onItemClick(value: string) {
		const variable = variables.find(variable => variable.name === value);
		if (variable) {
			onSelect(variable);
		}
		setShow(false);
	}

	const dropdownElement = useMemo(() => {
		if (!inputContainerRef.current) return null;

		const { top, left, width } = inputContainerRef.current.getBoundingClientRect();

		return (
			<Dropdown
				ref={dropdownRef}
				show={show}
				style={{ top: top + 42, left: left, maxWidth: width }}
			>
				{items.map(item => (
					<Item
						key={item.id}
						onClick={() => onItemClick(item.id)}
						selected={variableAliases.map[id].variableId === item.id}
					>
						{item.label}
					</Item>
				))}
			</Dropdown>
		);
	}, [show, items, variableAliases.map[id].variableId]);

	return (
		<Container>
			<Wrapper errored={!!error}>
				<InputContainer ref={inputContainerRef} onClick={() => setShow(!show)}>
					<ValueContainer hasPlaceholder={!value}>
						{value
							? value.label
							: translate(dict => dict.addVariable.compositeVariable.chooseVariable)}
					</ValueContainer>
					<Icon svg={show ? Svgs.ChevronUp : Svgs.ChevronDown} />
				</InputContainer>
			</Wrapper>

			{error && <Error>{error}</Error>}

			{show &&
				ReactDOM.createPortal(dropdownElement, document.getElementById('dropdown-root')!)}
		</Container>
	);
}

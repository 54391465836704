import { CompareNumericResultsV2, DescriptiveStatistics } from 'api/data/analyses';
import { decodeURIComponentSafe } from 'helpers/generic';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { VariablesMap, VariableSetsMap } from 'store/data/variables';

import { computeCellValueForExport } from './computeData';
import { getAggregationLabel } from 'helpers/variables';

interface TableRows {
	nrOfRows: string[];
	average: string[];
	std: string[];
	confidentLower: string[];
	confidentUpper: string[];
	sem: string[];
	variance: string[];
	skewness: string[];
	kurtosis: string[];
	median: string[];
	min: string[];
	max: string[];
	range: string[];
	IQRLower: string[];
	IQRUpper: string[];
	missing: string[];
}

export function exportCompareNumericV2(
	dataset: CompareNumericResultsV2['data'],
	{
		translate,
		variablesMap,
		variableSetsMap
	}: {
		translate: TranslateFunction;
		variablesMap: VariablesMap;
		variableSetsMap: VariableSetsMap;
	}
) {
	const leftRows: string[] = [
		translate(({ analysis }) => analysis.generic.n),
		translate(({ analysis }) => analysis.generic.mean),
		translate(({ analysis }) => analysis.generic.sd),
		translate(({ analysis }) => analysis.generic.confidenceLower),
		translate(({ analysis }) => analysis.generic.confidenceUpper),
		translate(({ analysis }) => analysis.generic.sem),
		translate(({ analysis }) => analysis.generic.variance),
		translate(({ analysis }) => analysis.generic.skewness),
		translate(({ analysis }) => analysis.generic.kurtosis),
		translate(({ analysis }) => analysis.generic.median),
		translate(({ analysis }) => analysis.generic.min),
		translate(({ analysis }) => analysis.generic.max),
		translate(({ analysis }) => analysis.generic.range),
		translate(({ analysis }) => analysis.generic.IQRLower),
		translate(({ analysis }) => analysis.generic.IQRUpper),
		translate(({ analysis }) => analysis.generic.missing)
	];

	const columns: string[] = [];
	const rows: TableRows = {
		nrOfRows: [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	const exploreVariableColumns: string[] = [];
	const categoryVariableColumns: string[] = [];

	Object.entries(dataset ?? {}).forEach(([key, results]) => {
		const aggregationLabel = getAggregationLabel(key, variablesMap, variableSetsMap);

		const exploreVariableColumnLabel = variablesMap[key]
			? variablesMap[key].label
			: aggregationLabel
			? aggregationLabel
			: '';

		exploreVariableColumns.push(exploreVariableColumnLabel);

		results.forEach(data => {
			const { group1, group2, ...row } = data;
			if (
				group2 &&
				categoryVariableColumns.findIndex(category => category === group2.value) === -1
			) {
				categoryVariableColumns.push(group2.value);
			}
			if (group1) addRow(row, group1.value);
		});
	});

	const columnsConfig = {
		colSpan: {
			exploreVariable: columns.length / exploreVariableColumns.length,
			categoryVariable: columns.length / categoryVariableColumns.length
		}
	};

	function addRow(row: DescriptiveStatistics, columnName: string) {
		columns.push(decodeURIComponentSafe(columnName));

		rows.nrOfRows.push(computeCellValueForExport(row['n'], { noDecimals: true }));
		rows.average.push(computeCellValueForExport(row.mean));
		rows.std.push(computeCellValueForExport(row.sd));
		rows.confidentLower.push(computeCellValueForExport(row.lowerCI));
		rows.confidentUpper.push(computeCellValueForExport(row.upperCI));
		rows.sem.push(computeCellValueForExport(row.sem));
		rows.variance.push(computeCellValueForExport(row.variance));
		rows.skewness.push(computeCellValueForExport(row.skewness));
		rows.kurtosis.push(computeCellValueForExport(row.kurtosis));
		rows.median.push(computeCellValueForExport(row.median));
		rows.min.push(computeCellValueForExport(row.min));
		rows.max.push(computeCellValueForExport(row.max));
		rows.range.push(computeCellValueForExport((Number(row.max) - Number(row.min)).toString()));
		rows.IQRLower.push(computeCellValueForExport(row.lowerIQR));
		rows.IQRUpper.push(computeCellValueForExport(row.upperIQR));
		rows.missing.push(computeCellValueForExport(row.missing, { noDecimals: true }));
	}

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';

	const hasExploreVariableColumns = exploreVariableColumns.length > 1;
	const hasCategoryVariableColumns = categoryVariableColumns.length > 0;

	if (hasExploreVariableColumns) {
		const computedColumns: string[] = [];

		// LEFT-TOP CORNER SPACER
		computedColumns.push('');

		exploreVariableColumns.forEach(column => {
			computedColumns.push(column);

			const colSpan = columnsConfig.colSpan.exploreVariable - 1;

			[...new Array(colSpan)].forEach(() => computedColumns.push(''));
		});

		// ADD COLUMNS
		csv += computedColumns.join(comma) + newLine;
	}

	if (hasCategoryVariableColumns) {
		const computedColumns: string[] = [];

		// LEFT-TOP CORNER SPACER
		computedColumns.push('');

		categoryVariableColumns.forEach(column => {
			computedColumns.push(column);

			const colSpan = columnsConfig.colSpan.categoryVariable - 1;

			[...new Array(colSpan)].forEach(() => computedColumns.push(''));
		});

		// ADD COLUMNS
		csv += computedColumns.join(comma) + newLine;
	}

	// LEFT-TOP CORNER SPACER
	csv += comma;
	// ADD COLUMNS
	csv += columns.join(comma) + newLine;

	Object.keys(rows).forEach((key, index) => {
		const row = rows[key as keyof TableRows];

		const leftRowColumn = leftRows[index];

		row.unshift(leftRowColumn);

		csv += row.join(comma) + newLine;
	});

	return csv;
}

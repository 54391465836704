import { useContext } from 'react';

import { TransferEntriesOwnershipContext } from 'contexts';

export function useTransferEntriesOwnershipContext() {
	const context = useContext(TransferEntriesOwnershipContext);

	if (context === undefined) {
		throw new Error(
			'useTransferEntriesOwnershipContext must be used within a `TransferEntriesOwnershipContext.Provider`'
		);
	}

	return context;
}

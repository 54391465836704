import { createContext } from 'react';

import { TransferEntriesOwnershipActions } from 'store/data/entries';

export const TransferEntriesOwnershipContext = createContext<
	| {
			actions: TransferEntriesOwnershipActions;
	  }
	| undefined
>(undefined);

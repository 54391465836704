import styled, { css } from 'styled-components';

import { MediaQueries, Shadows, Colors } from 'environment';
import { Typography } from 'components/UI/Typography';
import { Button } from 'components/UI/Interactables/Button/Button';
import { Disabled } from 'helpers/cssGenerators';

export const NarrowContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	max-width: 69.2rem;
	width: 100%;
	min-height: fit-content;
	padding: 0 2rem;
	padding-bottom: 10rem;

	@media not all and (min-resolution: 0.001dpcm) {
		padding-bottom: 20rem;
	}
`;

export const Title = styled(Typography.H6)`
	margin-bottom: 3rem;
`;

export const Subtitle = styled(Typography.Paragraph)`
	font-weight: bold;
	margin-bottom: 1.6rem;
`;

export const CardContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2.4rem;
	flex-wrap: wrap;

	> :not(:last-child) {
		margin-right: 2.4rem;
	}

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;

		> :not(:last-child) {
			margin-right: 0;
			margin-bottom: 2.4rem;
		}
	}
`;

interface CardProps extends Disabled {
	selected?: boolean;
}
export const Card = styled.div<CardProps>`
	flex: 1;
	width: 100%;
	min-height: 16rem;
	padding: 0 1.6rem;
	cursor: pointer;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0.4rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	border: solid 0.2rem rgba(0, 0, 0, 0);
	transition: border 0.1s, background-color 0.1s, box-shadow 0.05s;

	${({ selected, disabled }) =>
		!selected &&
		!disabled &&
		css`
			:hover {
				box-shadow: ${Shadows.hover};
			}
		`}

	${({ selected }) =>
		selected &&
		css`
			span {
				color: ${Colors.primary.normal};
			}

			border: solid 0.2rem ${Colors.primary.normal};
		`}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;

			span {
				color: ${Colors.text.disabled}!important;
			}
		`}
`;

export const SyncedWidthCard = styled.div<CardProps & { itemCount: number }>`
	flex: 1;
	min-height: 16rem;
	padding: 0 1.6rem;
	cursor: pointer;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0.4rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	border: solid 0.2rem rgba(0, 0, 0, 0);
	transition: border 0.1s, background-color 0.1s, box-shadow 0.05s;
	width: ${({ itemCount }) => `calc((100% - ${itemCount - 1} * 2.4rem) / 3)`};

	${({ selected, disabled }) =>
		!selected &&
		!disabled &&
		css`
			:hover {
				box-shadow: ${Shadows.hover};
			}
		`}

	${({ selected }) =>
		selected &&
		css`
			span {
				color: ${Colors.primary.normal};
			}

			border: solid 0.2rem ${Colors.primary.normal};
		`}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;

			span {
				color: ${Colors.text.disabled}!important;
			}
		`}

		@media ${MediaQueries.maxWidth.md} {
		width: 100%;
	}
`;

interface CardLabelProps {
	usedForImport?: boolean;
}
export const CardLabel = styled(Typography.Caption)<CardLabelProps>`
	user-select: none;
	color: ${Colors.text.main};
`;

export const ColumnTitle = styled(Typography.Paragraph)``;

export const ImportText = styled.div`
	p {
		margin-bottom: 0.5em;
	}

	ul {
		padding-top: 0;
	}

	ul li {
		padding: 0;
	}
`;

export const PreviousMappingButton = styled(Button)`
	padding: 0;
	margin: 0 0 1.6em;
`;

export const MatchColumnToMainVariableContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 2.4rem;
	padding: 0.8rem 0.8rem 1.6rem;
	border-radius: 0.4rem;
	background-color: ${Colors.background.disabled};
`;

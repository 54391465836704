import { useDispatch, useSelector } from 'hooks/utils';
import {
	setDistributionGlobalStartDate,
	selectDistributionGlobalStartDate,
	SetDistributionGlobalStartDateAction
} from 'store/data/patients';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

interface Options {
	initial?: boolean;
}

export function useDistributionGlobalStartDate(
	props?: Options
): LocalOperationResult<
	string | undefined,
	(payload: ActionPayload<SetDistributionGlobalStartDateAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state =>
		selectDistributionGlobalStartDate(state.data.patients, props?.initial)
	);

	function handler(payload: ActionPayload<SetDistributionGlobalStartDateAction>) {
		dispatch(setDistributionGlobalStartDate(payload));
	}

	return [data, handler];
}

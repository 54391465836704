import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useEntry } from './useEntry';
import { useCollaboratorsData } from '../collaborators/useCollaboratorsData';
import { useOrganizationsByCollaborator } from '../collaborators/useOrganizationsByCollaborator';
import { useMemo } from 'react';
import { useAccount } from 'hooks/store';

export function useEntryWriteAccess(): boolean {
	const [{ data: entry }] = useEntry({ lazy: true });

	const { hasDatasetWriteAllAccess, hasDatasetWriteOwnAccess, ...permissions } = usePermissions();

	const hasDatasetWriteOrgAccess = permissions.projectOrganizationAccesses?.find(
		orgAccess => orgAccess.organizationId.toString() === entry?.userProjectOrgId
	);

	const {
		data: { username: currentUserName }
	} = useAccount()[0];
	const collaborator = useCollaboratorsData().collaboratorsMap[currentUserName ?? ''];

	const userOrganizations = useOrganizationsByCollaborator(collaborator?.userId);

	const ownedByUser = entry?.ownedbyuser ?? '';
	const organizationId = entry?.userProjectOrgId ?? '';

	return useMemo(() => {
		return (
			(hasDatasetWriteOwnAccess && ownedByUser === currentUserName) ||
			(hasDatasetWriteOrgAccess?.writeAccess &&
				userOrganizations.map(d => d.id).includes(organizationId)) ||
			hasDatasetWriteAllAccess
		);
	}, [
		hasDatasetWriteAllAccess,
		hasDatasetWriteOwnAccess,
		hasDatasetWriteOrgAccess,
		currentUserName,
		ownedByUser,
		entry?.userProjectOrgId
	]);
}

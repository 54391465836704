import { useMemo, useState } from 'react';
import { NewGroup, NewVariableSet } from 'api/data/variables';
import { buildVariablesDataLocation } from 'helpers/variables';
import { MergeVariablesIntoGroupInput } from 'store/data/variables';
import { InputType } from 'types/index';
import { Dropdown } from 'components/UI/Dropdown';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useVariables,
	useVariablesData,
	useDestinationSetName,
	useDestinationGroupName,
	useCreateGroup,
	useCreateVariableSet
} from 'hooks/store';
import { useCompletedAction, useEffectOnce } from 'hooks/utils';

enum Options {
	group = 'createNewGroup',
	series = 'createNewSeries'
}

interface Props {
	variablesToBeMerged?: MergeVariablesIntoGroupInput | null;
	onClose: () => void;
}

export function CreateVariableGroupOrSetModal({ variablesToBeMerged, onClose }: Props) {
	const { translate } = useTranslation();

	const selectOptions = {
		[Options.group]: translate(dict => dict.variablesPage[Options.group]),
		[Options.series]: translate(dict => dict.variablesPage[Options.series])
	};

	const [newLabel, setNewLabel] = useState('');
	const [option, setOption] = useState<Options>(Options.group);

	const [
		{
			data: { groups, variableSets }
		}
	] = useVariables({ initial: true, lazy: true });

	const variablesData = useVariablesData({ initial: true });

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const [destinationSetName, setDestinationSetName] = useDestinationSetName();
	const [destinationGroupName, setDestinationGroupName] = useDestinationGroupName();

	const [{ loading: creatingGroup, error: errorCreatingGroup }, createGroup] = useCreateGroup();
	const [{ loading: creatingVariableSet, error: errorCreatingVariableSet }, createVariableSet] =
		useCreateVariableSet();

	const newLabelTrimmed = newLabel.trim();
	const isLabelValid = newLabelTrimmed.length > 0;

	const isGroupLabelUnique = !groups.some(group => group.groupLabel === newLabelTrimmed);

	const isSetLabelUnique = !variableSets.some(
		variableSet => variableSet.setLabel === newLabelTrimmed
	);

	const canCreate = {
		group: isLabelValid && isGroupLabelUnique,
		set: isLabelValid && isSetLabelUnique
	};

	// CLOSE MODAL AFTER CREATION
	useCompletedAction(
		creatingGroup || creatingVariableSet,
		errorCreatingGroup || errorCreatingVariableSet,
		onClose
	);

	useEffectOnce(() => {
		return () => {
			if (destinationSetName !== null) setDestinationSetName(null);
			if (destinationGroupName !== null) setDestinationGroupName(null);
		};
	});

	function handleCreateGroup() {
		if (creatingGroup || !canCreate.group) return;

		const group: NewGroup = {
			groupLabel: newLabelTrimmed
		};

		if (variablesToBeMerged) {
			const { targetVariable, draggedVariable, setName, from } = variablesToBeMerged;

			createGroup(group, {
				variableNames: [targetVariable.name, draggedVariable.name],
				destinationIndex:
					/**
					 * NOTICE:
					 *
					 * DECREMENT `destinationIndex` BY 1 IF THE DRAGGED VARIABLE WAS BEFORE THE TARGET VARIABLE
					 * IN ORDER TO PLACE THE NEWLY CREATED GROUP IN THE CORRECT POSITION AND NOT CREATE IT 1 INDEX AHEAD
					 */
					variablesLocation[draggedVariable.name]
						? targetVariable.index
						: draggedVariable.index < targetVariable.index
						? targetVariable.index - 1
						: targetVariable.index,
				setName,
				from
			});
		} else {
			createGroup(group);
		}
	}

	function handleCreateVariableSet() {
		if (creatingVariableSet || !canCreate.set) return;

		const variableSet: NewVariableSet = {
			setLabel: newLabelTrimmed
		};

		if (variablesToBeMerged) {
			const { targetVariable, draggedVariable } = variablesToBeMerged;

			createVariableSet({
				variableSet,
				options: {
					variableNames: [targetVariable.name, draggedVariable.name],
					destinationIndex:
						/**
						 * NOTICE:
						 *
						 * DECREMENT `destinationIndex` BY 1 IF THE DRAGGED VARIABLE WAS BEFORE THE TARGET VARIABLE
						 * IN ORDER TO PLACE THE NEWLY CREATED GROUP IN THE CORRECT POSITION AND NOT CREATE IT 1 INDEX AHEAD
						 */
						variablesLocation[draggedVariable.name]
							? targetVariable.index
							: draggedVariable.index < targetVariable.index
							? targetVariable.index - 1
							: targetVariable.index
				}
			});
		} else {
			createVariableSet({ variableSet });
		}
	}

	function trimFields() {
		setNewLabel(state => state.trim());
	}

	function handleChangeOption(type: Options) {
		setOption(type);
	}

	function getInputProps() {
		const props: {
			label: string;
			placeholder: string;
			error?: string;
			onSubmit(): void;
		} = {
			label: '',
			placeholder: '',
			onSubmit: () => null
		};

		if (option === Options.group) {
			props.label = translate(
				dict => dict.variablesPage.createVariableGroupOrSetModal.groupLabel
			);
			props.placeholder = translate(
				dict => dict.variablesPage.createVariableGroupModal.placeholder
			);
			props.onSubmit = handleCreateGroup;

			if (!isGroupLabelUnique) {
				props.error = translate(dict => dict.terms.errorlabelUnique);
			}
		}

		if (option === Options.series) {
			props.label = translate(dict => dict.variablesPage.createVariableSetModal.label);
			props.placeholder = translate(
				dict => dict.variablesPage.createVariableGroupOrSetModal.seriesPlaceholder
			);
			props.onSubmit = handleCreateVariableSet;

			if (!isSetLabelUnique) {
				props.error = translate(dict => dict.terms.errorlabelUnique);
			}
		}

		return props;
	}

	const inputProps = getInputProps();

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.variablesPage.createGroupOrSeries)}
			primary={{
				label: translate(({ buttons }) => buttons.create),
				loading: creatingGroup || creatingVariableSet,
				disabled: !(canCreate.group || canCreate.set),
				onClick: inputProps.onSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Dropdown offset={{ top: 2, right: 0 }} title={selectOptions[option]} button>
				<Dropdown.Item
					title={translate(dict => dict.variablesPage.createNewGroup)}
					onClick={() => handleChangeOption(Options.group)}
				/>
				<Dropdown.Item
					title={translate(dict => dict.variablesPage.createNewSeries)}
					onClick={() => handleChangeOption(Options.series)}
				/>
			</Dropdown>
			<Input
				type={InputType.Text}
				value={newLabel}
				label={inputProps.label}
				placeholder={inputProps.placeholder}
				error={inputProps.error}
				onChange={e => setNewLabel(e.target.value)}
				onSubmit={inputProps.onSubmit}
				onBlur={trimFields}
				marginOffset={{ top: 1.6 }}
				autoFocus
			/>
			{option === Options.series && (
				<Typography.Paragraph marginOffset={{ top: 1.6 }} multiline>
					{translate(dict => dict.variablesPage.createVariableGroupOrSetModal.message)}
					{`\n\n`}
					<b>{translate(dict => dict.terms.deleteWarningMessage)}</b>
				</Typography.Paragraph>
			)}
		</Modal>
	);
}

import { useScrollPosition } from 'hooks/ui';
import { FilterList } from './FilterList';

import { Container } from './Filters.style';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks/utils';

interface FiltersProps {
	fullscreen?: boolean;
	heightOffset?: number;
	filterDrawerContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export function Filters({ fullscreen, filterDrawerContainerRef }: FiltersProps) {
	const scrollPosition = useScrollPosition();
	const isMaxWidthLg = useMediaQuery(MediaQueries.maxWidth.lg);

	let scrollOffset = 20.5;
	if (isMaxWidthLg && scrollPosition.scrollTop > 140) {
		scrollOffset = 0;
	}

	return (
		<Container
			ref={filterDrawerContainerRef}
			fullscreen={fullscreen}
			heightOffset={scrollOffset}
		>
			<FilterList />
		</Container>
	);
}

import styled, { css } from 'styled-components';

import { getTypographyCSS, Typography, FontWeights } from 'components/UI/Typography';
import { Colors, Shadows } from 'environment';
import { disableableComponent } from 'helpers/cssGenerators';

interface DropProps {
	isHovered: boolean;
}

export const LineMarker = styled.div`
	width: 0.4rem;
	height: 6.2rem;
	background: ${Colors.vibrantGreen};
	border-radius: 0.2rem;
	opacity: 0;
`;

export const LineMarkerHorizontal = styled.div`
	width: 100%;
	height: 0.4rem;
	background: ${Colors.vibrantGreen};
	border-radius: 0.2rem;
	opacity: 0;
`;

export const DropBelow = styled.div<DropProps>`
	position: absolute;
	right: 0;
	bottom: -1.4rem;

	width: 100%;
	height: 2rem;
	padding-bottom: 0.4rem;

	display: flex;
	align-items: flex-end;

	${({ isHovered }) =>
		isHovered &&
		css`
				${LineMarkerHorizontal} {
					opacity: 1
				}
			}
		`}
`;

export const DropAbove = styled(DropBelow)`
	top: -1.1rem;
	align-items: flex-start;
`;

export const HoverOver = styled.div<{ systemGenerated?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: flex-start;

	position: absolute;
	top: -0.4rem;
	right: -0.4rem;
	width: calc(100% + 0.8rem);
	height: calc(100% + 0.8rem);
	border-radius: 0.8rem;

	display: none;
	${({ systemGenerated }) =>
		systemGenerated
			? css`
					cursor: not-allowed;

					> :first-child {
						color: ${Colors.white}!important;
					}
			  `
			: css`
					border: solid 0.4rem ${Colors.vibrantGreen};
					background: rgba(38, 208, 124, 0.3);
			  `}
`;

export const Label = styled.p`
	${getTypographyCSS(t => t.Caption)};

	color: ${Colors.chart.darkGreen.dark} !important;
	font-weight: ${FontWeights.medium};
	margin-top: 0.8rem;
	padding: 0 0.8rem;
	background: white;
	border-radius: 0.4rem;
`;

interface HoverableAreasProps {
	isHovered: boolean;
}

export const DropToLeft = styled.div<HoverableAreasProps>`
	position: absolute;
	left: -1.2rem;
	top: 0;

	width: 4rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	${({ isHovered }) =>
		isHovered &&
		css`
				${LineMarker} {
					opacity: 1
				}
			}
		`}
`;

export const DropToRight = styled.div<HoverableAreasProps>`
	position: absolute;
	right: -1.3rem;
	top: 0;

	width: 4rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	${({ isHovered }) =>
		isHovered &&
		css`
				${LineMarker} {
					opacity: 1
				}
			}
		`}
`;

interface VariableCardContainer {
	isGroup?: boolean;
	isVariableSet?: boolean;
	isDragging?: boolean;
	isHovered?: boolean;
	disabled?: boolean;
	loading?: boolean;
	systemGenerated?: boolean;
	readOnly?: boolean;
	hasError?: boolean;
}
export const VariableCardContainer = styled.div<VariableCardContainer>`
	transform: translate3d(0, 0, 0);
	position: relative;
	height: 6.2rem;
	padding: 0.8rem 1.6rem;

	display: flex;
	flex-direction: column;

	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	background: ${Colors.white};

	transition: box-shadow, opacity 0.15s linear;

	cursor: pointer;

	${Typography.Caption}, .variable-card__error-indicator {
		margin-top: 0.4rem;
	}

	:hover {
		box-shadow: ${Shadows.hover};
	}

	${({ isGroup, isVariableSet }) => {
		if (isGroup || isVariableSet) {
			return css`
				padding: 0.8rem 1.2rem;

				${Typography.Paragraph} {
					margin-left: 0.8rem;
				}

				${Typography.Caption} {
					margin-left: 2.4rem;
					padding: 0 0.8rem;
					border-radius: 0.4rem;
					color: ${Colors.text.main};
					background: ${Colors.background.disabled};
				}
			`;
		}
	}};

	${({ hasError }) =>
		hasError &&
		css`
			/* TODO: replace with colors from styleguide */
			background-color: #ffefef;
			border-left: 0.4rem solid ${Colors.text.error};

			span {
				color: ${Colors.text.error};
			}
		`};

	${({ isDragging }) =>
		isDragging &&
		css`
			background: ${Colors.background.disabled};
			box-shadow: 0;
			&:hover {
				box-shadow: none;
			}
		`}

	${({ isHovered }) =>
		isHovered &&
		css`
			${Typography.Caption}, ${Typography.Paragraph} {
				color: ${Colors.text.disabled};
			}

			${HoverOver} {
				display: flex;
			}
		`}

	${({ readOnly }) =>
		readOnly &&
		css`
			cursor: unset;
		`};
	${({ disabled }) =>
		disabled &&
		css`
			cursor: unset;
		`};

	${({ systemGenerated }) =>
		systemGenerated &&
		css`
			cursor: unset;

			> :first-child,
			> :nth-child(2) {
				opacity: 0.6;
			}
		`};
	${({ systemGenerated, isHovered }) =>
		systemGenerated &&
		isHovered &&
		css`
			cursor: not-allowed !important;
			background: ${Colors.white}!important;
		`};

	${({ loading }) => disableableComponent({ disabled: loading })}
`;

export const CardReplacement = styled.div`
	position: relative;
	height: 6.2rem;
	border-radius: 0.4rem;
	background: ${Colors.background.disabled};
	box-shadow: 0;
`;

interface ColorIndicatorProps {
	type: 'group' | 'variableSet';
}
export const ColorIndicator = styled.div<ColorIndicatorProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 0.4rem 0 0 0.4rem;

	${({ type }) => {
		const colors = {
			group: Colors.groupOrange,
			variableSet: Colors.seriesGreen
		};

		return css`
			border-left: 0.4rem solid ${colors[type]};
		`;
	}}
`;

export const SelectedCardIndicator = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0.1rem solid ${Colors.primary.normal};
	border-radius: 0.4rem;
`;

export const LoadingContainer = styled.div`
	position: absolute;
	bottom: 0.8rem;
	right: 1.6rem;
	background-color: ${Colors.white};
`;

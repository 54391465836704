import { useMemo, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { Table } from 'components/UI/Table';
import { useDependenciesContext, useDependenciesTableParams, useTranslation } from 'hooks/store';
import {
	Conditions,
	Dependency,
	Rules,
	TableDependency,
	OptionType,
	SubRows,
	DependenciesTableView,
	DependencyOperators
} from 'store/data/dependencies';
import { SelectItem } from 'types/index';
import {
	Column,
	useTable,
	useFilters,
	usePagination,
	useSortBy,
	useExpanded,
	Row
} from 'react-table';
import { Flex } from 'components/UI/Flex';

import { Container, NoTableDependenciesContainer } from '../DependenciesTable.styles';
import { Pagination } from 'components/UI/Pagination';
import { DependenciesOptions } from '../DependenciesOptions';
import { NoTableDependencies } from '../NoTableDependencies';
import { AddNewRuleModal } from '../DependenciesTableModals/AddNewRuleModal';
import { useDependenciesFilters } from 'hooks/store';

interface ExpandableTableProps {
	columns: Column<TableDependency>[];
	dependencies: Dependency[];
	tableDependencies: TableDependency[];
	dependencySuppliersSelectItems: { label: string; value: string }[];
	getDependantVariablesSelectItems: (
		dependency: Dependency | TableDependency | SubRows
	) => SelectItem[];
	context: string | null;
	scopeDependenciesData: { active: boolean; dependencies: Dependency[] };
	areRulesExpanded: boolean;
	openModal: boolean;
	onClose: () => void;
	tableRef: React.RefObject<HTMLTableElement>;
	collapseAllRules: () => void;
	parseOperatorCell: (value: string) => { label: string; value: DependencyOperators };
}

export function ExpandableTable({
	columns,
	dependencies,
	tableDependencies,
	dependencySuppliersSelectItems,
	getDependantVariablesSelectItems,
	context,
	scopeDependenciesData,
	areRulesExpanded,
	openModal,
	onClose,
	tableRef,
	collapseAllRules,
	parseOperatorCell,
	...tableProps
}: ExpandableTableProps) {
	const { translate } = useTranslation();
	const noDependencies = scopeDependenciesData.dependencies.length === 0;

	const [{ dependenciesFilters: filters }] = useDependenciesFilters();

	const [{ pageIndex, pageSize }, setPageParams] = useDependenciesTableParams();

	// ADD NEW RULE CONFIGURATION
	const {
		actions: { createDependency, createDependant }
	} = useDependenciesContext();

	const [draftDependency, setDraftDependency] = useState<Dependency>();

	//  add subRows as separate rows to the table data
	const addSubRows = (rows: TableDependency[]) => {
		const newRows: TableDependency[] = [];
		rows.forEach(row => {
			newRows.push(row);
			if (row.subRows) {
				row.subRows.forEach((subRow: SubRows) => {
					newRows.push({
						...subRow,
						isSubRow: true, // add isSubRow property
						parentRowId: row.dependencyName // add parentRowId property
					});
				});
			}
		});

		return newRows;
	};

	// add subRows and filter table data
	const { filteredTableData } = useMemo(() => {
		const filteredTableData = cloneDeep(tableDependencies);
		addSubRows(filteredTableData);
		return { filteredTableData };
	}, [tableDependencies, filters]);

	//  APPLY FILTERS ON TABLEDEPENDNECIES
	const { filterTableDependencies } = useMemo(() => {
		let filterTableDependencies = cloneDeep(filteredTableData);

		filterTableDependencies = filterTableDependencies.filter(row =>
			filters.every(filter => {
				const shouldFilterTable =
					!filter.invalid && ('value' in filter || 'values' in filter);

				const dependencyValue = row[filter.columnName as keyof TableDependency] as string;

				if (shouldFilterTable) {
					if (filter.columnName === DependenciesTableView.filteredValues) {
						// FILTERS THE FILTEREDVALUES ARRAY
						const result = row.filteredValues.filter(filteredValue =>
							filter.values?.includes(filteredValue)
						);
						return result.length > 0;
					} else {
						return (
							// CHECKS IF ANY OF THE TABLE DEPENDENCIES CONTAINS THE FILTER VALUE
							// OR REVERSE (FILTER VALUES CONTAINS THE VALUE OF THE TABLE DEPENDENCIES OBJECT)
							filter.values?.includes(dependencyValue) ||
							(filter.value !== undefined &&
								filter.value !== null &&
								dependencyValue.includes(filter.value.toString()))
						);
					}
				} else {
					return true;
				}
			})
		);

		filterTableDependencies = filterTableDependencies.flatMap(row => {
			if (row.isExpanded) {
				if (row.subRows) {
					return [row, ...row.subRows.map(subRow => subRow)];
				}
			}
			return [row];
		});

		return { filterTableDependencies };
	}, [filters, filteredTableData]);

	const instance = useTable(
		{
			columns,
			data: filterTableDependencies,
			initialState: {
				...tableProps,
				pageIndex: pageIndex,
				pageSize: pageSize
			},
			autoResetPage: false,
			autoResetSortBy: false
		},

		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);

	useEffect(() => {
		if (instance.state.pageIndex !== instance.pageIndex) instance.gotoPage(instance.pageIndex);
	}, [instance.state.pageIndex, instance.pageIndex]);

	useEffect(() => {
		instance.toggleAllRowsExpanded(areRulesExpanded);
	}, [areRulesExpanded, instance.toggleAllRowsExpanded]);

	function handleChangePageIndex(pageIndex: number) {
		instance.gotoPage(pageIndex);
		setPageParams({ pageSize: pageSize, pageIndex });
	}

	function handleChangePageSize(pageSize: number) {
		instance.setPageSize(pageSize);

		setPageParams({ pageSize, pageIndex: 0 });
	}

	const selectedOptions = (row: Row<TableDependency | SubRows>) => {
		if (row.depth > 0) {
			return {
				type: OptionType.condition,
				options: Object.values(Conditions).map(value =>
					translate(dict => dict.dependencies.builder.tableView.condition[value])
				)
			};
		} else {
			return {
				type: OptionType.rule,
				options: Object.values(Rules).map(value =>
					translate(dict => dict.dependencies.builder.tableView.rule[value])
				)
			};
		}
	};

	// ADD NEW RULE CONFIGURATION

	// create dependency
	useEffect(() => {
		if (openModal) {
			createDependency();
		}
	}, [openModal]);

	// SET DRAFT DEPENDENCY WITH NEWLY CREATED DEPENDENCY
	useEffect(() => {
		const [draft] = dependencies.filter(obj => {
			return !scopeDependenciesData.dependencies.some(
				dep => dep.dependencyName === obj.dependencyName
			);
		});

		if (draft && draft.dependencyName) {
			setDraftDependency(draft);
		}
	}, [scopeDependenciesData.dependencies, dependencies]);

	// create dependant
	useEffect(() => {
		if (
			draftDependency &&
			draftDependency.dependencyName &&
			!draftDependency.dependantVariables.length
		) {
			createDependant({ dependencyName: draftDependency.dependencyName });
		}
	}, [draftDependency?.dependencyName]);

	const openAddNewRuleModal =
		openModal &&
		draftDependency &&
		draftDependency.dependencyName !== '' &&
		draftDependency.dependantVariables.length > 0;

	return (
		<>
			{noDependencies ? (
				<NoTableDependenciesContainer>
					<NoTableDependencies
						onCloseFromHeader={onClose}
						openModal={openModal}
						dependencySuppliersSelectItems={dependencySuppliersSelectItems}
						context={context}
						getDependantVariablesSelectItems={getDependantVariablesSelectItems}
						initialDependencies={scopeDependenciesData}
						editedDraftDependencies={dependencies}
						parseOperatorCell={parseOperatorCell}
					/>
				</NoTableDependenciesContainer>
			) : (
				<Container>
					<Flex align={a => a.center} marginOffset={{ bottom: 1 }}>
						{/* TABLE PAGINATION */}
						<Pagination
							totalCount={filteredTableData.length}
							totalCountLabel={translate(dict => dict.pagination.entries)}
							filteredCount={filterTableDependencies.length}
							pageIndex={instance.state.pageIndex}
							pageSize={instance.state.pageSize}
							pagesCount={instance.pageOptions.length}
							changePage={handleChangePageIndex}
							changePageSize={handleChangePageSize}
						/>
					</Flex>

					<Table
						tableRef={tableRef}
						fullWidth
						stickyHead
						hoverEffect
						{...instance.getTableProps()}
					>
						<Table.Head>
							{instance.headerGroups.map(headerGroup => (
								<Table.Row
									{...headerGroup.getHeaderGroupProps()}
									key={headerGroup.getHeaderGroupProps().key}
								>
									{headerGroup.headers.map(column => (
										<Table.Column
											{...column.getHeaderProps([
												column.getSortByToggleProps(),
												{
													...column.customHeaderProps
												}
											])}
											key={column.getHeaderProps().key}
										>
											{column.render('Header')}{' '}
										</Table.Column>
									))}
								</Table.Row>
							))}
						</Table.Head>

						<Table.Body {...instance.getTableBodyProps()}>
							{instance.page.map(row => {
								instance.prepareRow(row);

								return (
									<Table.Row {...row.getRowProps()} key={row.getRowProps().key}>
										{row.cells.map((cell, index, all) => (
											<Table.Cell
												{...cell.getCellProps([
													{
														...cell.column.customCellProps
													}
												])}
												key={cell.getCellProps().key}
												noWrap
												style={{
													overflowX: 'visible'
												}}
											>
												<Flex
													align={a => a.center}
													justify={j => j.between}
												>
													{cell.render('Cell')}

													{/* show dependencies options menu */}
													{index === all.length - 1 && (
														<Flex fullWidth justify={j => j.end}>
															<DependenciesOptions
																collapseAllRules={collapseAllRules}
																initialDependencies={
																	scopeDependenciesData
																}
																dependencySuppliersSelectItems={
																	dependencySuppliersSelectItems
																}
																row={row}
																dependenciesOptions={selectedOptions(
																	row
																)}
																context={context}
																getDependantVariablesSelectItems={
																	getDependantVariablesSelectItems
																}
																dependencies={dependencies}
																parseOperatorCell={
																	parseOperatorCell
																}
															/>
														</Flex>
													)}
												</Flex>
											</Table.Cell>
										))}
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
					{/* ADD NEW RULE/DEPENDENCY MODAL */}
					{openAddNewRuleModal && (
						<AddNewRuleModal
							collapseAllRules={collapseAllRules}
							dependencySuppliersSelectItems={dependencySuppliersSelectItems}
							context={context}
							getDependantVariablesSelectItems={getDependantVariablesSelectItems}
							dependantVariablesSelectItems={getDependantVariablesSelectItems(
								draftDependency
							)}
							onClose={onClose}
							initialDependencies={scopeDependenciesData}
							editedDraftDependencies={dependencies}
							newDependencyAdded={draftDependency}
							parseOperatorCell={parseOperatorCell}
						/>
					)}
				</Container>
			)}
		</>
	);
}

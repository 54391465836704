import { useState } from 'react';
import { NewVariableSet } from 'api/data/variables';
import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { useTranslation, useVariables, useCreateVariableSet } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	onClose: () => void;
}

export function CreateVariableSetModal({ onClose }: Props) {
	const { translate } = useTranslation();

	const [newSetLabel, setNewSetLabel] = useState('');

	const [
		{
			data: { variableSets }
		}
	] = useVariables({ initial: true, lazy: true });

	const [{ loading: creatingVariableSet, error: errorCreatingVariableSet }, createVariableSet] =
		useCreateVariableSet();

	const newSetLabelTrimmed = newSetLabel.trim();
	const isSetLabelValid = newSetLabelTrimmed.length > 0;
	const isSetLabelUnique = !variableSets.some(
		variableSet => variableSet.setLabel === newSetLabelTrimmed
	);

	const canCreateSet = isSetLabelValid && isSetLabelUnique;

	// CLOSE MODAL AFTER VARIABLE SET CREATION
	useCompletedAction(creatingVariableSet, errorCreatingVariableSet, onClose);

	function handleCreateVariableSet() {
		if (creatingVariableSet || !canCreateSet) return;

		const variableSet: NewVariableSet = {
			setLabel: newSetLabelTrimmed
		};

		createVariableSet({ variableSet });
	}

	function trimFields() {
		setNewSetLabel(state => state.trim());
	}

	const primaryButtonDisabled = !canCreateSet;

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.variablesPage.createVariableSetModal.title)}
			primary={{
				label: translate(({ buttons }) => buttons.create),
				loading: creatingVariableSet,
				disabled: primaryButtonDisabled,
				onClick: handleCreateVariableSet
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Input
				type={InputType.Text}
				value={newSetLabel}
				label={translate(dict => dict.variablesPage.createVariableSetModal.label)}
				placeholder={translate(
					dict => dict.variablesPage.createVariableSetModal.placeholder
				)}
				error={
					!isSetLabelUnique ? translate(dict => dict.terms.errorlabelUnique) : undefined
				}
				onChange={e => setNewSetLabel(e.target.value)}
				onSubmit={handleCreateVariableSet}
				onBlur={trimFields}
				autoFocus
			/>
		</Modal>
	);
}

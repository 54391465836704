import { useEffect } from 'react';
import { stringAsBoolean } from 'helpers/generic';
import { OperationResult } from 'hooks/store/types';

import { ActionTypes, getPublicTemplates } from 'store/data/templates';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';

const IS_PUBLIC_SHARING_ENABLED = stringAsBoolean(
	process.env.REACT_APP_USE_TEMPLATES_PUBLIC_SHARING!
);

export function usePublicTemplates(): OperationResult<string[]> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const data = useSelector(state => state.data.templates.sharedWithPublic.ids);
	const fetched = useSelector(state => state.data.templates.sharedWithPublic.fetched);

	const [{ loading, error }] = useActivity(ActionTypes.GET_PUBLIC_TEMPLATES);

	useEffect(() => {
		if (!fetched && !loading && !error && isProjectValid && IS_PUBLIC_SHARING_ENABLED) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (!IS_PUBLIC_SHARING_ENABLED) return;

		dispatch(getPublicTemplates());
	}

	return [{ loading, error, data, fetched }, handler];
}

import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Colors, Svgs } from 'environment';
import { StyleOffsets } from 'types/index';

interface ChildrenProps {
	children: React.ReactNode;
}

export function AnalysisStatistic({ children }: ChildrenProps) {
	return <Flex column>{children}</Flex>;
}

function Title({ children, marginOffset }: ChildrenProps & { marginOffset?: StyleOffsets }) {
	return <Typography.H6 marginOffset={marginOffset ?? { bottom: 0.8 }}>{children}</Typography.H6>;
}

function Error({ children }: ChildrenProps) {
	return (
		<Flex align={a => a.start}>
			<Icon
				svg={Svgs.Information}
				size={s => s.m}
				colors={{ color: Colors.text.error }}
				marginOffset={{ top: 0.3, right: 0.8 }}
			/>
			<Typography.Paragraph color={Colors.text.error}>{children}</Typography.Paragraph>
		</Flex>
	);
}

function Hint({ children }: ChildrenProps) {
	return <Typography.Caption multiline>{children}</Typography.Caption>;
}

AnalysisStatistic.Title = Title;
AnalysisStatistic.Error = Error;
AnalysisStatistic.Hint = Hint;

import { createActivity } from 'store/ui/activities';

import { ActionPayload, Thunk } from 'store/types';

import {
	ActionTypes,
	AddDashboardCardAction,
	APIWidthSize,
	CreateDashboardAction,
	Dashboard,
	GetDashboardByIdAction,
	ListDashboardsAction,
	MoveDashboardCardAction,
	RemoveDashboardCardAction,
	RemoveDashboardCardPayload,
	ResizeDashboardCardActionDynamic,
	SetDashboardIdAction,
	SetLayoutColumnsAction,
	UpdateDashboardAction,
	WidgetHeightSize
} from './types';
import { ApiDashboard, ApiDashboardCard } from 'api/data/projectDashboard';
import { CardPositionManual } from 'components/Account';
import { omit } from 'lodash';

const listDashboardsAction = (
	payload: ActionPayload<ListDashboardsAction>
): ListDashboardsAction => ({
	type: ActionTypes.LIST_DASHBOARDS,
	payload
});

export const listDashboards = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.LIST_DASHBOARDS,
		dispatch
	});

	const {
		data: {
			projectDashboard: { projectId }
		}
	} = getState();

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const dashboards = await context.api.data.projectDashboard().listDashboards(projectId);

			if (dashboards)
				dispatch(
					listDashboardsAction({
						projectId,
						dashboards
					})
				);
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

const getDashboardByIdAction = (
	payload: ActionPayload<GetDashboardByIdAction>
): GetDashboardByIdAction => ({
	type: ActionTypes.GET_DASHBOARD_BY_ID,
	payload
});

export const getDashboardById =
	(dashboardId: number): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_DASHBOARD_BY_ID,
			dispatch
		});

		const {
			data: {
				projectDashboard: { projectId }
			}
		} = getState();

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const apiDashboard = await context.api.data
					.projectDashboard()
					.getDashboardById(projectId, dashboardId);

				if (apiDashboard) {
					dispatch(
						getDashboardByIdAction({
							projectId,
							apiDashboard
						})
					);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

const createDashboardAction = (
	payload: ActionPayload<CreateDashboardAction>
): CreateDashboardAction => ({
	type: ActionTypes.CREATE_DASHBOARD,
	payload
});

export const createDashboard =
	(dashboard: Dashboard): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_DASHBOARD,
			dispatch
		});

		const {
			data: {
				projectDashboard: { projectId }
			}
		} = getState();

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const cardsArray: ApiDashboardCard[] = Object.values(dashboard.cards).map(card => ({
					...card,
					size: { width: 1, height: 1 }
				}));
				const result = await context.api.data
					.projectDashboard()
					.createDashboard({ ...dashboard, cards: cardsArray });

				if (result)
					dispatch(
						createDashboardAction({
							projectId,
							dashboard: result
						})
					);
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

const updateDashboardAction = (
	payload: ActionPayload<UpdateDashboardAction>
): UpdateDashboardAction => ({
	type: ActionTypes.UPDATE_DASHBOARD,
	payload
});

export const updateDashboard =
	(dashboard: Dashboard): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_DASHBOARD,
			dispatch
		});

		const {
			data: {
				projectDashboard: { projectId }
			}
		} = getState();

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const cardsArray: ApiDashboardCard[] = Object.values(dashboard.cards).map(card => {
					const {
						size: { heightSize, widthSize }
					} = card;
					const apiHeight = heightSize === WidgetHeightSize.big ? 2 : 1;
					const apiWidth =
						widthSize === APIWidthSize.one ? 3 : widthSize === APIWidthSize.two ? 2 : 1;
					return {
						...card,
						size: { width: apiWidth, height: apiHeight }
					};
				});
				const computedDashboard = omit(dashboard, 'elementsOrder');
				const apiDashboard: ApiDashboard = { ...computedDashboard, cards: cardsArray };

				const result = await context.api.data
					.projectDashboard()
					.updateDashboard(apiDashboard);

				if (result)
					dispatch(
						updateDashboardAction({
							projectId,
							dashboard: result
						})
					);
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

//Store only

export const addDashboardCard = (
	payload: ActionPayload<AddDashboardCardAction>
): AddDashboardCardAction => ({
	type: ActionTypes.ADD_DASHBOARD_CARD,
	payload
});

export const moveDashboardCard = (
	payload: ActionPayload<MoveDashboardCardAction>
): MoveDashboardCardAction => ({
	type: ActionTypes.MOVE_DASHBOARD_CARD,
	payload
});

export const resizeDashboardCard = (
	payload: ActionPayload<ResizeDashboardCardActionDynamic>
): ResizeDashboardCardActionDynamic => ({
	type: ActionTypes.RESIZE_DASHBOARD_CARD,
	payload
});

export const removeDashboardCardAction = (
	payload: ActionPayload<RemoveDashboardCardAction>
): RemoveDashboardCardAction => ({
	type: ActionTypes.REMOVE_DASHBOARD_CARD,
	payload
});

export const removeDashboardCard =
	(payload: CardPositionManual): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_DASHBOARD_CARD,
			dispatch
		});

		const {
			data: {
				projectDashboard: { projectId, dashboardId }
			}
		} = getState();
		if (!projectId || !dashboardId) return;

		try {
			activity.begin();

			const requestPayload: RemoveDashboardCardPayload = {
				dashboardId,
				projectId: Number(projectId),
				cards: [{ cardId: 2 }]
			};

			await context.api.data.projectDashboard().removeDashboardCard(requestPayload);

			dispatch(removeDashboardCardAction(payload));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setDashboardId = (
	payload: ActionPayload<SetDashboardIdAction>
): SetDashboardIdAction => ({
	type: ActionTypes.SET_DASHBOARD_ID,
	payload
});

export const setLayoutColumns = (
	payload: ActionPayload<SetLayoutColumnsAction>
): SetLayoutColumnsAction => ({
	type: ActionTypes.SET_LAYOUT_COLUMNS,
	payload
});

import produce from 'immer';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.BEGIN_ACTIVITY: {
			const activity = action.payload;

			return produce(state, draft => {
				draft.activities.push(activity);
			});
		}

		case ActionTypes.END_ACTIVITY: {
			const activity = action.payload;

			return produce(state, draft => {
				draft.activities = draft.activities.filter(({ uuid }) => uuid !== activity.uuid);
			});
		}

		case ActionTypes.ERROR: {
			const error = action.payload;

			return produce(state, draft => {
				draft.errors.push(error);
			});
		}

		case ActionTypes.CLEAR_ERROR: {
			const error = action.payload;

			return produce(state, draft => {
				draft.errors = draft.errors.filter(({ uuid }) => uuid !== error.uuid);
			});
		}

		case ActionTypes.CLEAR_ACTION_TYPE_ERROR: {
			const actionType = action.payload;

			return produce(state, draft => {
				draft.errors = draft.errors.filter(({ type }) => actionType !== type);
			});
		}

		default: {
			return state;
		}
	}
};

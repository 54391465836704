import { Svgs } from 'environment';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useDependenciesContext, useTranslation } from 'hooks/store';
import { Button } from 'components/UI/Interactables/Button';
import { useEffect, useState } from 'react';
import { AddNewRuleModal } from '../DependenciesTableModals/AddNewRuleModal/AddNewRuleModal';
import { Dependency, DependencyOperators, SubRows, TableDependency } from 'store/data/dependencies';
import { SelectItem } from 'types/index';
import { Container } from './NoTableDependencies.style';

interface NoTableDependenciesProps {
	initialDependencies: { active: boolean; dependencies: Dependency[] };
	editedDraftDependencies: Dependency[];
	dependencySuppliersSelectItems: { label: string; value: string }[];
	getDependantVariablesSelectItems: (
		dependency: Dependency | TableDependency | SubRows
	) => SelectItem[];
	context: string | null;
	parseOperatorCell: (value: string) => { label: string; value: DependencyOperators };
	openModal: boolean;
	onCloseFromHeader: () => void;
}
export function NoTableDependencies({
	initialDependencies,
	editedDraftDependencies,
	dependencySuppliersSelectItems,
	getDependantVariablesSelectItems,
	context,
	parseOperatorCell,
	openModal,
	onCloseFromHeader
}: NoTableDependenciesProps) {
	const { translate } = useTranslation();
	const [open, setOpen] = useState(openModal);
	const {
		actions: { createDependency }
	} = useDependenciesContext();
	const [draftDependency, setDraftDependency] = useState<Dependency>();

	// create dependency
	useEffect(() => {
		if (open) {
			createDependency();
		}
	}, [open]);

	// SET DRAFT DEPENDENCY WITH NEWLY CREATED DEPENDENCY
	useEffect(() => {
		const [draft] = editedDraftDependencies.filter(obj => {
			return !initialDependencies.dependencies.some(
				dep => dep.dependencyName === obj.dependencyName
			);
		});

		if (draft && draft.dependencyName) {
			setDraftDependency(draft);
		}
	}, [initialDependencies.dependencies, editedDraftDependencies]);

	const onClose = () => {
		setOpen(false);
		onCloseFromHeader();
	};

	const shouldOpenAddNewRuleModal =
		(open || openModal) &&
		draftDependency &&
		draftDependency.dependencyName !== '' &&
		draftDependency.dependantVariables.length > 0;

	return (
		<Container>
			<Svgs.EmptyStatesNoDependencies style={{ minHeight: 240 }} />
			<Spacer size={m => m.m} />
			<Typography.H3>
				{translate(({ dependencies }) => dependencies.builder.tableView.noDependencies)}
			</Typography.H3>
			<Spacer size={m => m.m} />
			<div>
				<Button
					title={translate(dict => dict.dependencies.builder.tableView.addRule)}
					onClick={() => setOpen(true)}
				/>
				{shouldOpenAddNewRuleModal && (
					<AddNewRuleModal
						dependencySuppliersSelectItems={dependencySuppliersSelectItems}
						context={context}
						getDependantVariablesSelectItems={getDependantVariablesSelectItems}
						dependantVariablesSelectItems={getDependantVariablesSelectItems(
							draftDependency
						)}
						onClose={onClose}
						initialDependencies={initialDependencies}
						editedDraftDependencies={editedDraftDependencies}
						newDependencyAdded={draftDependency}
						parseOperatorCell={parseOperatorCell}
					/>
				)}
			</div>
		</Container>
	);
}

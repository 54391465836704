import {
	ApiEntryFilter,
	ApiEntryFilters,
	ApiEntryNoStatusFilter,
	ApiEntryStatusFilter,
	EntryFilter
} from 'api/data/filters';
import { ApiFormsData } from 'api/data/forms';
import { ApiVariablesData } from 'api/data/variables';
import { GenericApiResponse, NetworkRequestBase } from 'api/types';
import {
	EntryValue,
	EntryDraft,
	EntriesErrors,
	Entry,
	EntryStatusValue,
	EntryMetadata,
	EntryStatus,
	Entries,
	EntryDifferenceResponse
} from 'store/data/entries';
import { FileMimeType, StringMap, GenericMap, NumberMap, ExportOptions } from 'types/index';
import { FormsData } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';
import { DependenciesData } from 'store/data/dependencies';
import { AnalysisVariable } from '../analyses';

export type ApiEntry = ApiEntryValues & EntryMetadata;
export type ApiEntryValues = GenericMap<ApiEntryValue>;
export type ApiEntryValue = EntryValue | number;

export type EntryFile = {
	id: string;
	fileName: string;
};

export type LocalEntryFile = EntryFile & {
	variableName: string;
	base64: string;
	size: number;
	isLocal: true;
};

export interface ApiEntryDraft {
	userName: string;
	creationDate: string;
	draftEntryId: string;
	draftValues: ApiEntry;
}

export interface ApiEntryStatus {
	value: boolean;
	comment?: string;
	dueTimeStamp?: string;
}

export type UpdateEntryStatusResponse = GenericApiResponse<{
	newDatasetentryid: string;
	statusObject: GenericMap<ApiEntryStatus>;
}>;

export type DeleteEntryResponse = GenericApiResponse;

export type GetEntryFileResponse = GenericApiResponse<{ doc: EntryFileResponse }>;

export type GetEntryFilesResponse = GenericApiResponse<{ doc: EntryFilesResponse }>;

export type StoreEntryFilesResponse = GenericApiResponse<{ doc: StringMap }>;

export type ExportDatasetFilters = (EntryFilter | ApiEntryStatusFilter | ApiEntryNoStatusFilter)[];

export enum DownloadFormat {
	CSV = 'csv',
	Excel = 'excel'
}

export enum ExportDatasetType {
	Main = 'main',
	Series = 'series'
}

type DatasetDownload = {
	datasetName: string | null;
};

// Get Downloadable Entries
export type GetDownloadableDatasetInput = {
	projectId: number;
	filters: ApiEntryFilter[];
	datasets: DatasetDownload[];
	removeLineShifts: boolean;
	amountOfData?: ExportOptions;
	categoryLabels?: boolean;
	exportFormat: DownloadFormat;
	variables?: AnalysisVariable[];
};
export type GetDownloadableDatasetRequest = NetworkRequestBase & GetDownloadableDatasetInput;
export type GetDownloadableDatasetResponse = GenericApiResponse<{
	emptyDataset: string[];
	populatedDataset: string[];
	singedUrl: string;
}>;
export type GetDownloadableDatasetOutput = {
	emptyDataset: string[];
	populatedDataset: string[];
	url: string;
};

export interface EntryFileResponse {
	metadata: {
		mimeType: FileMimeType;
		s3Bucket: string;
		s3Key: string;
		fileName: string;
	};
	fileURL: string;
	thumbnailURL: string;
}

export type EntryFilesResponse = GenericMap<EntryFileResponse>;

export interface FileToStore {
	variableName: string;
	fileName: string;
	base64Bytes: string;
}

/*
	GET ENTRIES
*/
export interface ApiSort {
	variableName: string;
	direction: 'DESC' | 'ASC';
}

export type GetEntriesInput = {
	projectId: number;
	filters: ApiEntryFilters;
	maxPageSize: number;
	startAt: number;
	sorting?: ApiSort;
};
export type GetEntriesRequest = NetworkRequestBase & GetEntriesInput;
export type GetEntriesResponse = GenericApiResponse<{
	dataRows: ApiEntry[];
	statuses?: GenericMap<{
		variableName: string;
		comment?: string;
		dueTimeStamp?: string;
	}>;
	errors?: {
		varErrors: string[];
		rowErrors: {
			datasetEntryId: string;
			variablesWithErrors: string[];
		}[];
	};
	totalCount: number;
	moreData: boolean;
}>;
export type GetEntriesOutput = {
	entries: Entries;
	statuses?: GenericMap<EntryStatusValue>;
	errors?: EntriesErrors;
	moreData?: boolean;
	totalCount?: number;
};

/*
	CREATE ENTRY
*/
export type CreateEntryInput = {
	projectId: number;
	observationData: ApiEntryValues;
	organizationId?: number;
	draftEntryId?: string;
};
export type CreateEntryRequest = NetworkRequestBase & CreateEntryInput;
export type CreateEntryResponse = GenericApiResponse<{
	datasetentryid: string;
	insertedEntry: ApiEntry;
	/**
	 * Used for variable.type `unique`
	 */
	variables?: {
		variableName: string;
	}[];
}>;
export type CreateEntryOutput = {
	entry: Entry;
};

/*
	UPDATE ENTRY
*/
export type UpdateEntryInput = {
	projectId: number;
	datasetentryid: string;
	observationData: ApiEntryValues;
	draftEntryId?: string;
	ignoreInputVlaidation?: boolean;
};
export type UpdateEntryRequest = NetworkRequestBase & UpdateEntryInput;
export type UpdateEntryResponse = GenericApiResponse<{
	newDatasetentryid: string;
	updatedEntry: ApiEntry;
	/**
	 * Used for variable.type `unique`
	 */
	variables?: {
		variableName: string;
	}[];
}>;
export type UpdateEntryOutput = {
	entry: Entry | null;
	uniqueErrorVariableNames?: string[];
};

/*
	INSERT SURVEY DATA ENTRY
*/
export type CreateEntrySurveyInput = {
	projectId: number;
	distributionEntryId: number;
	hashString: string;
	hashKey: string;
	observationData: ApiEntryValues;
	files?: FileToStore[];
};
export type CreateEntrySurveyRequest = NetworkRequestBase & CreateEntrySurveyInput;
export type CreateEntrySurveyResponse = GenericApiResponse<{}>;

/*
	GET SURVEY DATA
*/
export type GetSurveyDataInput = {
	projectId: number;
	distributionEntryId: number;
	hashString: string;
	hashKey: string;
};
export type GetSurveyDataRequest = NetworkRequestBase & GetSurveyDataInput;
export type GetSurveyDataResponse = GenericApiResponse<{
	variablesData: ApiVariablesData;
	formsData: ApiFormsData;
	dependenciesData: DependenciesData;
}>;
export type GetSurveyDataOutput = {
	variablesData: VariablesData;
	formsData: FormsData;
	dependenciesData: DependenciesData;
};

/*
	GET NEW ENTRY DRAFT
*/
export type GetNewEntryDraftInput = {
	projectId: number;
};
export type GetNewEntryDraftRequest = NetworkRequestBase & GetNewEntryDraftInput;
export type GetNewEntryDraftResponse = GenericApiResponse<{
	draft: ApiEntryDraft;
}>;
export type GetNewEntryDraftOutput = {
	entryDraft: EntryDraft;
};

/*
	GET ENTRY DRAFT
*/
export type GetEntryDraftInput = {
	projectId: number;
	entryId: string;
};
export type GetEntryDraftRequest = NetworkRequestBase & GetEntryDraftInput;
export type GetEntryDraftResponse = GenericApiResponse<{
	draft: ApiEntryDraft;
	differenceFromActiveEntry: EntryDifferenceResponse[];
}>;
export type GetEntryDraftOutput = {
	entryDraft: EntryDraft;
};

/*
	SAVE NEW ENTRY DRAFT
*/
export type SaveNewEntryDraftInput = {
	projectId: number;
	observationData: ApiEntryValues;
	organizationId?: number;
	draftEntryId?: string;
};
export type SaveNewEntryDraftRequest = NetworkRequestBase &
	SaveNewEntryDraftInput & {
		draft: boolean;
	};
export type SaveNewEntryDraftResponse = GenericApiResponse<{
	datasetentryid: string;
	insertedEntry: ApiEntryValues;
	/**
	 * Used for variable.type `unique`
	 */
	variables?: {
		variableName: string;
	}[];
}>;
export type SaveNewEntryDraftOutput = {
	entryDraft: EntryDraft;
};

/*
	SAVE ENTRY DRAFT
*/
export type SaveEntryDraftInput = {
	projectId: number;
	datasetentryid: string;
	observationData: ApiEntryValues;
	draftEntryRevisionId?: string;
};
export type SaveEntryDraftRequest = NetworkRequestBase &
	SaveEntryDraftInput & {
		draft: boolean;
	};
export type SaveEntryDraftResponse = GenericApiResponse<{
	newDatasetentryid: string;
	updatedEntry: ApiEntryValues;
	/**
	 * Used for variable.type `unique`
	 */
	variables?: {
		variableName: string;
	}[];
}>;
export type SaveEntryDraftOutput = {
	entryDraft: EntryDraft;
};

/*
	GET SERIES ENTRIES
*/
export type GetSeriesEntriesInput = {
	projectId: number;
	parentDatasetEntryId: string;
	set: {
		setName: string;
	};
	sorting?: ApiSort;
};
export type GetSeriesEntriesRequest = NetworkRequestBase & GetSeriesEntriesInput;
export type GetSeriesEntriesResponse = GenericApiResponse<{
	dataRows: ApiEntry[];
}>;
export type GetSeriesEntriesOutput = {
	entries: Entries;
};

/*
	CREATE SERIES ENTRY
*/
export type CreateSeriesEntryInput = {
	projectId: number;
	organizationId?: number;
	mainSetRevisionId: string;
	set: {
		setName: string;
	};
	observationData: ApiEntryValues;
};
export type CreateSeriesEntryRequest = NetworkRequestBase & CreateSeriesEntryInput;
export type CreateSeriesEntryResponse = GenericApiResponse<{
	datasetentryid: string;
	insertedEntry: ApiEntry;
	variables?: { variableName: string }[];
}>;
export type CreateSeriesEntryOutput = {
	entry: Entry;
};

/*
	UPDATE SERIES ENTRY
*/
export type UpdateSeriesEntryInput = {
	projectId: number;
	datasetentryid: string;
	set: {
		setName: string;
	};
	observationData: ApiEntryValues;
};
export type UpdateSeriesEntryRequest = NetworkRequestBase & UpdateSeriesEntryInput;
export type UpdateSeriesEntryResponse = GenericApiResponse<{
	newDatasetentryid: string;
	updatedEntry: ApiEntry;
}>;
export type UpdateSeriesEntryOutput = {
	entry: Entry;
};

/*
	DELETE SERIES ENTRY
*/
export type DeleteSeriesEntryInput = {
	projectId: number;
	datasetentryid: string;
	set: {
		setName: string;
	};
};
export type DeleteSeriesEntryRequest = NetworkRequestBase & DeleteSeriesEntryInput;
export type DeleteSeriesEntryResponse = GenericApiResponse<{}>;

/*
	GET SERIES ENTRIES COUNT
*/
export type GetSeriesEntriesCountInput = {
	projectId: number;
	datasetentryid: string;
};
export type GetSeriesEntriesCountRequest = NetworkRequestBase & GetSeriesEntriesCountInput;
export type GetSeriesEntriesCountResponse = GenericApiResponse<{
	numberOfEntries: NumberMap;
}>;
export type GetSeriesEntriesCountOutput = {
	entriesCount: NumberMap;
};

/*
	TRANSFER OWNERSHIP
*/
export type TransferEntriesOwnershipInput = {
	projectId: number;
	datasetentryids: string[];
	newOwner?: string;
	newOrganizationId?: number;
};
export type TransferEntriesOwnershipRequest = NetworkRequestBase & TransferEntriesOwnershipInput;
export type TransferEntriesOwnershipResponse = GenericApiResponse<{}>;

/*
	GET LATEST ENTRY
*/
export type GetLatestEntryInput = {
	projectId: number;
	datasetentryid: string;
	set?: {
		setName: string;
	};
};
export type GetLatestEntryRequest = NetworkRequestBase & GetLatestEntryInput;
export type GetLatestEntryResponse = GenericApiResponse<{
	entry: ApiEntry;
	latestentryid: string;
	statuses?: GenericMap<ApiEntryStatus>;
}>;
export type GetLatestEntryOutput = {
	entry: Entry;
	entryStatus: EntryStatus;
};

/*
	GET ENTRY
*/
export type GetEntryInput = {
	projectId: number;
	datasetentryid: string;
	set?: {
		setName: string;
	};
};
export type GetEntryRequest = NetworkRequestBase & GetEntryInput;
export type GetEntryResponse = GenericApiResponse<{
	entry: ApiEntry;
	statuses?: GenericMap<ApiEntryStatus>;
}>;
export type GetEntryOutput = {
	entry: Entry;
	entryStatus: EntryStatus;
};

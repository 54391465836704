import { Flex } from 'components/UI/Flex';
import {
	PlaceholderCardWrapper,
	PlaceholderAvatar,
	PlaceholderText
} from './NotificationPlaceholder.style';

export function NotificationPlaceholer() {
	return (
		<PlaceholderCardWrapper>
			<PlaceholderAvatar />
			<Flex column fullWidth marginOffset={{ left: 1.6, right: 4 }}>
				<PlaceholderText />
				<PlaceholderText />
			</Flex>
		</PlaceholderCardWrapper>
	);
}

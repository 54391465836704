import { useMatch } from 'react-router-dom';

export function useRouteMatch(path: string | string[]) {
	const match = useMatch;

	if (Array.isArray(path)) {
		return path.reduce((acc, value) => !!match(value) || acc, false);
	}

	return !!match(path);
}

import { useState, useMemo } from 'react';
import { InputType, SelectItem } from 'types/index';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { buildForm, isFormNameUnique } from 'helpers/forms';
import {
	useTranslation,
	useFormsBySetName,
	useForms,
	useCreateForm,
	useVariables
} from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	onClose: (success?: boolean) => void;
}

export function CreateFormModal({ onClose }: Props) {
	const { translate } = useTranslation();

	const formsBySetName = useFormsBySetName();
	const [{ data: forms }] = useForms({ lazy: true });
	const [{ loading: creatingForm, error: errorCreatingForm }, createForm] = useCreateForm();

	const [
		{
			data: { variableSets }
		}
	] = useVariables({ lazy: true });

	const [newFormName, setNewFormName] = useState('');
	const [applyToVariableSet, setApplyToVariableSet] = useState(false);
	const [selectedVariableSet, setSelectedVariableSet] = useState('');

	useCompletedAction(creatingForm, errorCreatingForm, () => onClose(true));

	const allForms = useMemo(
		() => [forms, ...Object.values(formsBySetName)].flat(),
		[forms, formsBySetName]
	);

	function handleSubmit() {
		if (canCreateForm()) {
			const form = buildForm({
				name: newFormName.trim(),
				...(applyToVariableSet && { setName: selectedVariableSet })
			});

			createForm(form);
		}
	}

	function canCreateForm() {
		const isNameValid = isStringValid(newFormName);
		const isNameUnique = isFormNameUnique(newFormName, { forms: allForms });
		const isSelectedVariableSetValid = applyToVariableSet
			? isStringValid(selectedVariableSet)
			: true;

		return isNameValid && isNameUnique && isSelectedVariableSetValid;
	}

	function isStringValid(value: string) {
		return value.trim().length > 0;
	}

	function getInputError() {
		if (isStringValid(newFormName) && !isFormNameUnique(newFormName, { forms: allForms })) {
			return translate(dict => dict.terms.errorlabelUnique);
		}
	}

	function getVariableSetsSelectItems() {
		const items: SelectItem[] = [];

		variableSets.forEach(variableSet => {
			const item: SelectItem = {
				label: variableSet.setLabel,
				value: variableSet.setName
			};

			items.push(item);
		});

		return items;
	}

	function handleSelectedVariableSetToggle() {
		const newFlag = !applyToVariableSet;

		setApplyToVariableSet(newFlag);
		if (!newFlag) resetSelectedVariableSet();
	}

	function resetSelectedVariableSet() {
		setSelectedVariableSet('');
	}

	function trimFields() {
		setNewFormName(state => state.trim());
	}

	const variableSetsSelectItems = getVariableSetsSelectItems();
	const hasVariableSets = variableSets.length > 0;

	return (
		<Modal
			size={s => s.s}
			title={translate(({ formDesigner }) => formDesigner.nameYourForm)}
			primary={{
				label: translate(({ buttons }) => buttons.create),
				loading: creatingForm,
				disabled: !canCreateForm(),
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Input
				value={newFormName}
				type={InputType.Text}
				placeholder={translate(({ formDesigner }) => formDesigner.formNamePlaceholder)}
				error={getInputError()}
				onChange={e => setNewFormName(e.target.value)}
				onSubmit={handleSubmit}
				onBlur={trimFields}
				autoFocus
			/>

			{hasVariableSets && (
				<>
					<Spacer size={s => s.m} />

					<Checkbox
						label={translate(dict => dict.formDesigner.createFormBasedOnSeries)}
						checked={applyToVariableSet}
						onClick={handleSelectedVariableSetToggle}
					/>

					{applyToVariableSet && (
						<>
							<Spacer size={s => s.xs} />

							<CreatableSelect
								items={variableSetsSelectItems}
								value={variableSetsSelectItems.find(
									selectItem => selectItem.value === selectedVariableSet
								)}
								onValueSelected={value => value && setSelectedVariableSet(value)}
								canClear={false}
							/>
						</>
					)}
				</>
			)}
		</Modal>
	);
}

import { createSelector } from 'reselect';
import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getFiltersById(state: State) {
	return state.dataset.byId;
}

function getFiltersBySnapshotId(state: State) {
	return state.dataset.bySnapshotId;
}

function getActiveFiltersByIds({ dataset: { projectId, byId, byProjectId } }: State) {
	return projectId && byProjectId[projectId]
		? byProjectId[projectId].active.map(id => byId[id])
		: [];
}

function getActiveFiltersByProjectId({ dataset: { projectId, byProjectId } }: State) {
	return projectId && byProjectId[projectId] ? byProjectId[projectId].active : [];
}

function getInvalidFiltersByProjectIds({ dataset: { projectId, byProjectId } }: State) {
	return projectId && byProjectId[projectId] ? byProjectId[projectId].invalid : [];
}

function getValidFilters({ dataset: { projectId, byProjectId } }: State) {
	return projectId && byProjectId[projectId]
		? byProjectId[projectId].invalid.length === 0
		: false;
}

function getFiltersIdsByVariableName(state: State, name: string) {
	return state.dataset.byVariableName[name] || [];
}

function getSeriesById(state: State) {
	return state.series.byId;
}

function getSeriesActiveFilters({ series: { projectId, byId, byProjectId, bySeriesName } }: State) {
	return projectId && bySeriesName && byProjectId[projectId][bySeriesName]
		? byProjectId[projectId][bySeriesName].active.map(id => byId[id])
		: [];
}

function getSeriesActiveFilterByIds({ series: { projectId, byProjectId, bySeriesName } }: State) {
	return projectId && bySeriesName && byProjectId[projectId][bySeriesName]
		? byProjectId[projectId][bySeriesName].active
		: [];
}

function getSeriesInvalidFilterIds({ series: { projectId, byProjectId, bySeriesName } }: State) {
	return projectId && bySeriesName && byProjectId[projectId][bySeriesName]
		? byProjectId[projectId][bySeriesName].invalid
		: [];
}

function getValidSeriesFilters({ series: { projectId, byProjectId, bySeriesName } }: State) {
	return projectId && bySeriesName && byProjectId[projectId][bySeriesName]
		? byProjectId[projectId][bySeriesName].invalid.length === 0
		: false;
}

function getDependenciesActiveFilters({ dependencies: { projectId, byId, byProjectId } }: State) {
	return projectId && byProjectId[projectId]
		? byProjectId[projectId].active.map(id => byId[id])
		: [];
}

function getDependenciesActiveFilterIds({ dependencies: { projectId, byProjectId } }: State) {
	return projectId && byProjectId[projectId] ? byProjectId[projectId].active : [];
}

function getDependenciesInvalidFilterIds({ dependencies: { projectId, byProjectId } }: State) {
	return projectId && byProjectId[projectId] ? byProjectId[projectId].invalid : [];
}

function getDependenciesFiltersById(state: State) {
	return state.dependencies.byId;
}

function getDependenciesAreFiltersValid({ dependencies: { projectId, byProjectId } }: State) {
	return projectId && byProjectId[projectId]
		? byProjectId[projectId].invalid.length === 0
		: false;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectFiltersById = createSelector([getFiltersById], filters => filters);

export const selectFiltersBySnapshotId = createSelector(
	[getFiltersBySnapshotId],
	filters => filters
);

export const selectActiveFilters = createSelector([getActiveFiltersByIds], filters => filters);

export const selectReversedActiveFilters = createSelector([getActiveFiltersByIds], filters =>
	filters.reverse()
);

export const selectActiveFilterIds = createSelector([getActiveFiltersByProjectId], ids => ids);

export const selectInvalidFilterIds = createSelector([getInvalidFiltersByProjectIds], ids => ids);

export const selectAreFiltersValid = createSelector([getValidFilters], valid => valid);

export const selectFilterIdsByVariableName = createSelector(
	[getFiltersIdsByVariableName],
	ids => ids
);

//SERIES FILTERS

export const selectSeriesFiltersById = createSelector([getSeriesById], filters => filters);

export const selectSeriesActiveFilters = createSelector(
	[getSeriesActiveFilters],
	filters => filters
);

export const selectSeriesActiveFilterIds = createSelector([getSeriesActiveFilterByIds], ids => ids);

export const selectSeriesInvalidFilterIds = createSelector([getSeriesInvalidFilterIds], ids => ids);

export const selectSeriesAreFiltersValid = createSelector([getValidSeriesFilters], valid => valid);

// DEPENDENCIES FILTERS

export const selectDependenciesActiveFilters = createSelector(
	[getDependenciesActiveFilters],
	filters => filters
);

export const selectDependenciesActiveFilterIds = createSelector(
	[getDependenciesActiveFilterIds],
	ids => ids
);

export const selectDependenciesInvalidFilterIds = createSelector(
	[getDependenciesInvalidFilterIds],
	ids => ids
);

export const selectDependenciesFiltersById = createSelector(
	[getDependenciesFiltersById],
	filters => filters
);

export const selectDependenciesAreFiltersValid = createSelector(
	[getDependenciesAreFiltersValid],
	valid => valid
);

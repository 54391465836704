import { useEffect, useState, useRef, RefObject, useMemo, useCallback } from 'react';
import { useSpring } from 'react-spring';
import { Icon, IconSizes } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Nullable, InputType, SpacingOffsets } from 'types/index';
import { Container, IconWrapper, Text, TokenText, Tooltip, TextWrapper } from './InfoTooltip.style';
import { useTranslation } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useOutsideClick } from 'hooks/utils';

// PX
export const ARROW_SIZE = 20;
export const TOOLTIP_PADDING = 8;
const DEFAULT_MAX_WIDTH = 600;

export interface PositionProps {
	left: number;
	bottom?: number;
	top?: number;
	arrowXOffset?: number;
	arrowPosition?: 'top' | 'bottom';
}
interface Props {
	iconSize?: (sizes: typeof IconSizes) => IconSizes;
	iconVisible?: boolean;
	text: Nullable<string>;
	disabled?: boolean;
	inline?: boolean;
	tokenCode?: boolean;
	renderIcons?: React.ReactNode;
	closeOnMouseLeave?: boolean;
	zIndex?: number;
	iconColor?: string;
	renderContext?: HTMLElement;
	renderChildren?: React.ReactNode;
	customStyles?: React.CSSProperties;
	colorTheme?: 'light' | 'dark';
	position?: 'top' | 'bottom';
}

/**
 * @renderContext
 * Container element where the tooltip text will be contained
 * default to: document.body
 */

export function InfoTooltip({
	iconVisible,
	text,
	disabled = false,
	inline = false,
	marginOffset,
	tokenCode = false,
	renderIcons,
	closeOnMouseLeave,
	zIndex,
	iconColor,
	renderChildren,
	customStyles,
	colorTheme = 'dark',
	renderContext = document.body,
	iconSize = sizes => sizes.m,
	position = 'top'
}: Props & SpacingOffsets) {
	const [done, setDone] = useState(false);
	const [rendered, setRendered] = useState(false);

	const iconRef = useRef<HTMLDivElement>(null);

	function onMouseLeaveAction() {
		setDone(false);
		setRendered(false);
	}

	useEffect(() => {
		if (!iconVisible) {
			setDone(false);
			setRendered(false);
		}
	}, [iconVisible]);

	useEffect(() => {
		if (rendered) {
			setDone(true);
		}
	}, [rendered]);

	function handleIconClick(e: React.MouseEvent) {
		if (!disabled) {
			e.stopPropagation();
			rendered && done && setDone(false);
			setTimeout(() => setRendered(state => !state), rendered ? 250 : 0);
		}
	}

	return (
		<Container
			marginOffset={marginOffset}
			inline={inline}
			onMouseLeave={() => closeOnMouseLeave && setRendered(false)}
			size={iconSize(IconSizes)}
		>
			{text && (
				<IconWrapper
					iconVisible={rendered || iconVisible}
					tokenCode={tokenCode}
					ref={iconRef}
					onClick={handleIconClick}
				>
					<Icon
						svg={Svgs.Information}
						disabled={disabled}
						propagate={true}
						size={iconSize}
						style={{ color: iconColor }}
					/>
				</IconWrapper>
			)}

			{/** TEXT WRAPPER + TEXT */}
			{rendered && text && (
				<TextTooltip
					iconSize={iconSize(IconSizes)}
					marginOffset={marginOffset}
					renderContext={renderContext}
					renderChildren={renderChildren}
					customStyles={customStyles}
					text={text}
					tokenCode={tokenCode}
					renderIcons={renderIcons}
					zIndex={zIndex}
					done={done}
					iconRef={iconRef}
					onMouseLeaveAction={onMouseLeaveAction}
					colorTheme={colorTheme ? colorTheme : 'light'}
					textTooltipPosition={position}
				/>
			)}
		</Container>
	);
}

interface TextToolTipProps {
	text: Nullable<string>;
	tokenCode?: boolean;
	renderIcons?: React.ReactNode;
	zIndex?: number;
	done: boolean;
	iconRef: RefObject<HTMLDivElement>;
	renderContext?: HTMLElement;
	renderChildren?: React.ReactNode;
	onMouseLeaveAction: () => void;
	iconSize: IconSizes;
	customStyles?: React.CSSProperties;
	colorTheme?: 'light' | 'dark';
	textTooltipPosition?: 'top' | 'bottom';
}

function TextTooltip({
	text,
	tokenCode = false,
	renderIcons,
	zIndex,
	done,
	iconRef,
	iconSize,
	renderContext,
	renderChildren,
	customStyles,
	onMouseLeaveAction,
	colorTheme = 'light',
	textTooltipPosition = 'top'
}: TextToolTipProps & SpacingOffsets) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [tooltipPosition, setTooltipPosition] = useState<DOMRect | null>(null);

	const tooltipRef = useRef<HTMLDivElement | null>(null);

	const getTooltipRef = useCallback((node: HTMLDivElement | null) => {
		if (!node) return;
		tooltipRef.current = node;
		setTooltipPosition(node.getBoundingClientRect());
	}, []);

	const iconPosition = useMemo(() => {
		if (!iconRef.current) {
			return undefined;
		}
		return iconRef.current.getBoundingClientRect();
	}, [iconRef]);

	// close on click away
	useOutsideClick(() => {
		onMouseLeaveAction();
	}, [tooltipRef, iconRef]);

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(text ? text : '');
		setNotification({
			message: translate(dict => dict.infoToolTip.copiedToClipBoard)
		});
	};

	const position = useMemo(() => {
		if (!tooltipPosition || !iconPosition) return undefined;
		const { width: tooltipWidth, height: tooltipHeight } = tooltipPosition;

		const position: PositionProps = {
			left: -tooltipWidth / 2 + ARROW_SIZE / 2 - (iconPosition.right - iconPosition.left) / 2,
			bottom:
				textTooltipPosition === 'top'
					? iconPosition.height + TOOLTIP_PADDING + ARROW_SIZE / 2
					: undefined,
			top:
				textTooltipPosition === 'bottom'
					? iconPosition.height + TOOLTIP_PADDING + ARROW_SIZE / 2
					: undefined,
			arrowPosition: textTooltipPosition || 'top',
			arrowXOffset: 0
		};

		if (renderContext) {
			const { top, left, right } = renderContext.getBoundingClientRect();

			const leftOverflowOffset =
				iconPosition.left - left - tooltipWidth / 2 - TOOLTIP_PADDING;
			if (leftOverflowOffset < 0) {
				position.left += -leftOverflowOffset;
				position.arrowXOffset = -leftOverflowOffset;
			}
			const rightOverflowOffset =
				iconPosition.right +
				tooltipWidth / 2 -
				TOOLTIP_PADDING -
				right -
				(iconPosition.right - iconPosition.left) / 2;
			if (rightOverflowOffset > 0) {
				position.left += -rightOverflowOffset;
				position.arrowXOffset = -rightOverflowOffset;
			}
			const topOverflowOffset = iconPosition.top - top - tooltipHeight - TOOLTIP_PADDING;
			if (topOverflowOffset < 0) {
				position.arrowPosition = 'bottom';
				// subtract half arrow size because our "arrow" :after is offset 10px from the center line;
				position.top = position.bottom;
				delete position.bottom;
			}
		}

		return position;
	}, [tooltipPosition, iconPosition, renderContext]);

	const style = useSpring({
		opacity: done ? 1 : 0,
		config: { duration: 25 }
	});

	const maxWidth = useMemo(() => {
		if (!renderContext) return undefined;
		const width = renderContext.getBoundingClientRect().width - 2 * TOOLTIP_PADDING;
		if (width > DEFAULT_MAX_WIDTH + 2 * TOOLTIP_PADDING) {
			return DEFAULT_MAX_WIDTH;
		}
		return width;
	}, [renderContext, TOOLTIP_PADDING]);

	return (
		<TextWrapper
			{...position}
			zIndex={zIndex ?? null}
			style={style}
			tokenCode={tokenCode}
			colorTheme={colorTheme}
			ref={getTooltipRef}
		>
			<Tooltip
				id="tooltip"
				maxWidth={maxWidth}
				arrowPosition={position?.arrowPosition}
				arrowXOffset={position?.arrowXOffset ?? 0}
				tokenCode={tokenCode}
				colorTheme={colorTheme}
				size={iconSize}
				style={customStyles}
			>
				{!tokenCode && (
					<Text colorTheme={colorTheme} style={{ fontSize: customStyles?.fontSize }}>
						{text}
					</Text>
				)}
				{tokenCode && (
					<>
						<TokenText
							type={InputType.Text}
							value={text ?? ''}
							colorTheme={colorTheme}
							onChange={() => undefined}
							className="token-text"
						/>
						<Icon
							svg={Svgs.FileText}
							title={translate(dict => dict.iconTooltip.copyToClipboard)}
							variant={v => v.button}
							marginOffset={{ left: 1 }}
							onClick={handleCopyToClipboard}
						/>
					</>
				)}
				{renderIcons && <>{renderIcons}</>}
				{renderChildren && <>{renderChildren}</>}
			</Tooltip>
		</TextWrapper>
	);
}

export { Tooltip };

import { useRef, useMemo } from 'react';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';

import {
	JADBioFeatureImportanceElement,
	JADBioProgressiveFeatureImportanceElement
} from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { CHART_COLORS, CHART_HEIGHT, DEFAULT_MAX_LABELS } from 'consts';
import { ScalesLabels } from 'types/index';
import { computeScalesLabels, computeLabels, getMaxAndMinInArray } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import { useAnalysesActiveColum, useTranslation } from 'hooks/store';

interface Serie {
	values: number[];
	errors: number[][];
}

interface Props {
	id?: string;
	data: JADBioFeatureImportanceElement[] | JADBioProgressiveFeatureImportanceElement[];
	isForExport?: boolean;
	scalesLabels: ScalesLabels;
}

function Component({ id, data, isForExport, scalesLabels }: Props) {
	const ref = useRef<ZingChart>(null);
	const [activeColumns] = useAnalysesActiveColum();
	const { translate } = useTranslation();

	const { series, options } = useMemo(
		() => computeData(data, scalesLabels, activeColumns),
		[data, scalesLabels]
	);

	function buildCategoryFromDataName(name: string | string[]) {
		if (Array.isArray(name)) {
			if (name.length === 1) {
				return translate(
					dict => dict.analysis.analyses.JADBio.progressiveFeature.categoryOne,
					false,
					{
						name: name[0]
					}
				);
			} else if (name.length === 2) {
				return translate(
					dict => dict.analysis.analyses.JADBio.progressiveFeature.categoryTwo,
					false,
					{
						name1: name[0],
						name2: name[1]
					}
				);
			} else if (name.length === 3) {
				return translate(
					dict => dict.analysis.analyses.JADBio.progressiveFeature.categoryThree
				);
			}
		} else return name;
	}

	function computeData(
		data: JADBioFeatureImportanceElement[] | JADBioProgressiveFeatureImportanceElement[],
		scalesLabels: ScalesLabels,
		activeColumns: number
	) {
		const categories: string[] = [];

		const [labelX, labelY] = computeScalesLabels(scalesLabels);
		const yValues: number[] = [];
		const allValues: number[] = [];

		data.slice()
			.reverse()
			.forEach(
				(
					element:
						| JADBioFeatureImportanceElement
						| JADBioProgressiveFeatureImportanceElement
				) => {
					categories.push(buildCategoryFromDataName(element.name));
					yValues.push(Math.floor(element.value * 100));
					allValues.push(Math.floor(element.value * 100));
					allValues.push(
						...[Math.floor(element.cis[0] * 100), Math.ceil(element.cis[1] * 100)]
					);
				}
			);

		const [maxItems, maxChars, fontAngle, tooltip] = computeLabels(
			categories,
			activeColumns === 1
		);

		const minAndMax: number[][] = [];
		data.forEach(
			(elem: JADBioFeatureImportanceElement | JADBioProgressiveFeatureImportanceElement) =>
				minAndMax.push([
					Math.floor(elem.value * 100) - Math.floor(elem.cis[0] * 100),
					Math.ceil(elem.cis[1] * 100) - Math.floor(elem.value * 100)
				])
		);

		const seriesValues: number[] = [];
		data.forEach(
			(elem: JADBioFeatureImportanceElement | JADBioProgressiveFeatureImportanceElement) => {
				seriesValues.push(Math.floor(elem.value * 100));
			}
		);

		const series: Serie[] = [
			{
				values: seriesValues,
				errors: minAndMax
			}
		];

		const { max: yMaxValue } = getMaxAndMinInArray(allValues);
		const scaleYSteps = yMaxValue <= 10 ? 1 : null;

		const options = {
			scaleY: {
				label: labelY,
				maxValue: yMaxValue,
				step: scaleYSteps,
				format: '%v%'
			},
			scaleX: {
				label: labelX,
				labels: categories,
				itemsOverlap: true,
				maxItems: activeColumns === 3 ? DEFAULT_MAX_LABELS : maxItems,
				item: {
					fontAngle,
					maxChars
				},
				tooltip
			},
			legend: {
				visible: false
			},
			plot: {
				backgroundColor: CHART_COLORS[0]
			},
			plotarea: {
				marginLeft: yMaxValue >= 10000 ? 75 : undefined,
				marginRight: yMaxValue >= 10000 ? 40 : undefined
			},
			tooltip: {
				text: '%kt \n value:%v%', // TODO: [%node-error-plus%, %node-error-minus%]
				backgroundColor: CHART_COLORS[0]
			}
		};

		return { series, options };
	}

	// TODO: check if we need this?
	// legend item click toggle chained range and line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		if (ref.current) {
			ref.current.toggleplot({ plotid: `linear-${e.plotindex}` });
		}
	}

	return (
		<Chart
			id={id}
			chartRef={ref}
			type={t => t.BarHorizontal}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			effect={e => e.SlideBottom}
			sequence={s => s.ByPlot}
			method={m => m.RegularEaseOut}
			speed={s => s.Faster}
			isForExport={isForExport}
			height={CHART_HEIGHT}
		/>
	);
}

export const JADBioFeatureSelectionColumnChart = withMemo(Component, ['data', 'scalesLabels']);

import { useMemo } from 'react';
import { Colors, Svgs } from 'environment';
import { Dependant, Dependency } from 'store/data/dependencies';
import { ConfigureFilteringValuesModal } from './ConfigureFilteringValuesModal';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { TagInput } from 'components/UI/TagInput';
import { Typography } from 'components/UI/Typography';
import { truncateList } from 'helpers/arrays';
import { useTranslation, useDependenciesContext } from 'hooks/store';
import { useModalState } from 'hooks/ui';

interface Props {
	dependency: Dependency;
	dependant: Dependant;
}

export function DependantFiltering({ dependency, dependant }: Props) {
	const { translate } = useTranslation();
	const {
		actions: { setDependantFilteredValues, deleteDependantFilteredValues },
		data: { categoriesMapByVariableName }
	} = useDependenciesContext();

	const { truncated: truncatedCategories, rest: hiddenCategories } = useMemo(() => {
		// mimic default pagination items-per-page
		const MAX_VISIBLE_CATEGORY_VALUES = 10;

		const categoriesMap = categoriesMapByVariableName[dependant.dependantVariableName];
		const categories = dependant.filteredValues.map(
			categoryValue => categoriesMap[categoryValue]
		);

		return truncateList(categories, MAX_VISIBLE_CATEGORY_VALUES);
	}, [dependant.filteredValues, categoriesMapByVariableName]);

	const configureFilteringModal = useModalState();

	return (
		<>
			{/* PREVIEW - filtered values */}
			{dependant.filteredValues.length > 0 && (
				<Flex
					align={a => a.start}
					css={`
						.delete-dependant-filtering-values-icon {
							visibility: hidden;
							min-width: unset;
						}

						:hover .delete-dependant-filtering-values-icon {
							visibility: visible;
						}
					`}
				>
					<Flex
						css={`
							cursor: pointer;
						`}
						onClick={() => configureFilteringModal.open()}
						fullWidth
						column
					>
						<TagInput
							items={truncatedCategories.map(c => ({
								label: c.label || c.value,
								value: c.value
							}))}
							readOnly
						/>

						{hiddenCategories.length > 0 && (
							<Typography.Hint marginOffset={{ top: 0.4, left: 0.4 }}>
								{`+ ${hiddenCategories.length} ${translate(
									dict => dict.dependencies.dependantFiltering.more
								)}`}
							</Typography.Hint>
						)}
					</Flex>

					<Icon
						className="delete-dependant-filtering-values-icon"
						title={translate(dict => dict.dependencies.dependantFiltering.iconTitle)}
						svg={Svgs.Delete}
						marginOffset={{
							left: 0.8
						}}
						colors={{
							color: Colors.text.disabled,
							hover: Colors.primary.normal
						}}
						onClick={() =>
							deleteDependantFilteredValues({
								dependencyName: dependency.dependencyName,
								dependantId: dependant.dependantId
							})
						}
					/>
				</Flex>
			)}

			{/* CONFIGURE - filtered values */}
			{dependant.filteredValues.length === 0 && (
				<Flex>
					<Typography.Caption
						color={Colors.primary.normal}
						marginOffset={{ top: 0.3, left: 0.4 }}
						onClick={() => configureFilteringModal.open()}
						clickable
					>
						{translate(dict => dict.terms.configure)}
					</Typography.Caption>
				</Flex>
			)}

			{configureFilteringModal.visible && (
				<ConfigureFilteringValuesModal
					dependant={dependant}
					onClose={configureFilteringModal.close}
					onConfirm={categoryValues => {
						setDependantFilteredValues({
							dependencyName: dependency.dependencyName,
							dependantId: dependant.dependantId,
							filteredValues: categoryValues
						});
						configureFilteringModal.close();
					}}
				/>
			)}
		</>
	);
}

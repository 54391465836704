import { useState } from 'react';
import { PromOptionTypes } from 'types/index';
import { RadioButtonContainer } from './AfterCreateOptions.style';
import { NarrowContainer, Title } from 'components/Projects/CreateAndImport';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { StickyFooter } from 'components/UI/StickyFooter';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useProjectId } from 'hooks/store';

export function AfterCreateOptions() {
	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();

	const [projectId] = useProjectId();

	const [selectedOption, setSelectedOption] = useState(PromOptionTypes.DefinePromForm);

	const IMPORT_OPTIONS = [
		{
			label: translate(dict => dict.promCreatedOptions.definePromForm),
			value: PromOptionTypes.DefinePromForm
		},
		{
			label: translate(dict => dict.promCreatedOptions.distributionPushSetup),
			value: PromOptionTypes.DistributionPushSetup
		},
		{
			label: translate(dict => dict.promCreatedOptions.finishSetupLater),
			value: PromOptionTypes.Later
		}
	];

	function handlePromChoice() {
		if (projectId && selectedOption === PromOptionTypes.DefinePromForm) {
			navigate(routes.proms.variables.view(projectId));
		} else if (projectId && selectedOption === PromOptionTypes.DistributionPushSetup) {
			navigate(routes.proms.patients(projectId));
		} else {
			navigate(routes.proms.list);
		}
	}

	return (
		<>
			<NarrowContainer>
				<Title>{translate(dict => dict.promCreatedOptions.title)}</Title>

				{IMPORT_OPTIONS.map(({ label, value }) => (
					<RadioButtonContainer key={value}>
						<RadioButton
							key={value}
							label={label}
							selected={selectedOption === value}
							onSelect={() => setSelectedOption(value)}
						/>
					</RadioButtonContainer>
				))}
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) =>
						selectedOption === PromOptionTypes.Later ? buttons.finish : buttons.next
					),
					disabled: !selectedOption,
					onClick: handlePromChoice
				}}
				maxWidth={65.2}
			/>
		</>
	);
}

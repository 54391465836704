import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getVariablesMapping,
	selectAreVariablesMappingFetched,
	selectVariablesMappingRichData,
	VariablesMappingRichData
} from 'store/data/variablesMapping';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

export function useVariablesMapping(): OperationResult<
	VariablesMappingRichData,
	() => Promise<void>
> {
	const dispatch = useDispatch();

	const [projectId] = useProjectId();
	const isProjectValid = useIsProjectValid();

	const data = useSelector(state => selectVariablesMappingRichData(state.data.variablesMapping));
	const fetched = useSelector(state =>
		selectAreVariablesMappingFetched(state.data.variablesMapping)
	);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_VARIABLES_MAPPING, {
		projectId
	});

	useEffect(() => {
		if (!error && !loading && !fetched && isProjectValid) {
			handler();
		}
	}, [error, loading, fetched, isProjectValid]);

	async function handler() {
		if (loading) return;
		if (error) resetError();

		await dispatch(getVariablesMapping());
	}

	return [{ data, loading, error, fetched }, handler];
}

import { Hub, HubCapsule } from '@aws-amplify/core';
import { useNavigation } from 'hooks/navigation';
import { useLoginSwitch } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';
import { useDispatch } from 'react-redux';

import { resetStore } from 'store/auth';

enum HubEventType {
	Auth = 'auth'
}

enum AuthEventType {
	SignIn = 'signIn',
	SignUp = 'signUp',
	SignOut = 'signOut'
}

export function Auth() {
	const dispatch = useDispatch();
	const { navigate, routes } = useNavigation();

	useLoginSwitch();

	useEffectOnce(() => {
		Hub.listen(HubEventType.Auth, onAuth);
		return () => Hub.remove(HubEventType.Auth, onAuth);
	});

	const onAuth = ({ payload }: HubCapsule) => {
		switch (payload.event) {
			case AuthEventType.SignOut: {
				dispatch(resetStore());
				navigate(routes.login);
				break;
			}

			default:
				break;
		}
	};

	return null;
}

import { AxiosRequestConfig } from 'axios';

import { sendRequest, JADBIO_URL, RequestTypes } from 'api/utils';
import { Dictionary } from 'environment';
import { LedidiStatusCode, StorageKeys } from 'types/index';

import {
	LogInToJADBioInput,
	LogInToJADBioOutput,
	LogInToJADBioRequest,
	LogInToJADBioResponse,
	//////////////////////
	InitiateJADBioProjectInput,
	InitiateJADBioProjectRequest,
	InitiateJADBioProjectResponse,
	//////////////////////
	InitiateClassificationModelInput,
	InitiateClassificationModelRequest,
	InitiateClassificationModelResponse,
	InitiateClassificationModelOutput,
	//////////////////////
	InitiateSurvivalAnalysisModelInput,
	InitiateSurvivalAnalysisModelRequest,
	InitiateSurvivalAnalysisModelResponse,
	InitiateSurvivalAnalysisModelOutput,
	//////////////////////
	InitiateRegressionModelInput,
	InitiateRegressionModelRequest,
	InitiateRegressionModelResponse,
	InitiateRegressionModelOutput,
	//////////////////////
	GetAnalysisStatusInput,
	GetAnalysisStatusRequest,
	GetAnalysisStatusResponse,
	GetAnalysisStatusOutput,
	//////////////////////
	GetAnalysisResultsInput,
	GetAnalysisResultsRequest,
	GetAnalysisResultsResponse,
	GetAnalysisResultsOutput,
	//////////////////////
	GetAnalysisResultInput,
	//////////////////////
	InitiateJADBioDatasetInput,
	InitiateJADBioDatasetRequest,
	InitiateJADBioDatasetResponse,
	GetJADBioProjectIdInput,
	GetJADBioProjectIdRequest,
	GetJADBioProjectIdResponse,
	ValidateJADBioTokenRequest,
	ValidateJADBioTokenResponse,
	GetJADBioDatasetStatusInput,
	GetJADBioDatasetStatusOutput,
	GetJADBioDatasetStatusRequest,
	GetJADBioDatasetStatusResponse,
	JADBioAnalysisStatus,
	DeleteJADBioAnalysesInput,
	DeleteJADBioAnalysesRequest
} from './types';
import { ledidiStatusCodeToErrorMessage } from 'helpers/api';

const methods = {
	JADBio: {
		validToken: 'validToken',
		logInToJadbio: 'logInToJadbio',
		initiateJadbioProjectV2: 'initiateJadbioProjectV2',
		initiateJadbioDataset: 'initiateJadbioDataset',
		getJadbioDatasetStatus: 'getJadbioDatasetStatus',
		getJadbioProjectId: 'getJadbioProjectId',
		initiateModels: {
			classification: 'initiateClassificationModelJadBio',
			survival: 'initiateSurvivalAnalysisModelJadBio',
			regression: 'initiateRegressionModelJadBio'
		},
		getAnalysis: {
			status: 'getAnalysisStatus',
			result: 'getAnalysisResult',
			results: 'getAnalysisResults',
			delete: 'deleteMultipleJadbioAnalyses'
		}
	}
};

export default () => ({
	validateToken: async (): Promise<boolean> => {
		const { data } = await sendRequestWithJADBioToken<
			ValidateJADBioTokenRequest,
			ValidateJADBioTokenResponse
		>(JADBIO_URL, {
			method: methods.JADBio.validToken
		});

		return data.valid;
	},

	logInToJadbio: async (input: LogInToJADBioInput): Promise<LogInToJADBioOutput> => {
		const { data } = await sendRequest<LogInToJADBioRequest, LogInToJADBioResponse>(
			JADBIO_URL,
			{
				method: methods.JADBio.logInToJadbio,
				...input
			}
		);

		if (!data.Authorization) {
			throw new Error();
		}

		const output: LogInToJADBioOutput = {
			jadBioToken: {
				Authorization: data.Authorization
			}
		};

		return output;
	},

	initiateJADBioProject: async (input: InitiateJADBioProjectInput): Promise<string> => {
		const { data } = await sendRequestWithJADBioToken<
			InitiateJADBioProjectRequest,
			InitiateJADBioProjectResponse
		>(JADBIO_URL, {
			method: methods.JADBio.initiateJadbioProjectV2,
			...input
		});

		if (data.httpStatusCode !== 200 || !data.jadBioProjectId) {
			throw new Error(Dictionary.errors.api.addons.JADBioInitProject);
		}

		return data.jadBioProjectId;
	},

	initiateJADBioDataset: async (input: InitiateJADBioDatasetInput): Promise<string> => {
		const { data } = await sendRequestWithJADBioToken<
			InitiateJADBioDatasetRequest,
			InitiateJADBioDatasetResponse
		>(JADBIO_URL, {
			method: methods.JADBio.initiateJadbioDataset,
			...input
		});

		if (data.httpStatusCode !== 200 || !data.jadBioTaskId) {
			throw new Error(Dictionary.errors.api.addons.JADBioInitDataset);
		}

		return data.jadBioTaskId;
	},

	getJADBioDatasetStatus: async (
		input: GetJADBioDatasetStatusInput
	): Promise<GetJADBioDatasetStatusOutput> => {
		const { data } = await sendRequestWithJADBioToken<
			GetJADBioDatasetStatusRequest,
			GetJADBioDatasetStatusResponse
		>(JADBIO_URL, {
			method: methods.JADBio.getJadbioDatasetStatus,
			...input
		});

		//TODO: remove this when api handles the ledidiStatusCodes
		if (data.message.includes('data contains null Sample names'))
			data.ledidiStatusCode = LedidiStatusCode.JADBioDataContainsNullSamples;
		if (data.message.includes('is contained twice, code: precond-60-2-3')) {
			data.ledidiStatusCode = LedidiStatusCode.JADBioSampleNameContainedTwice;
			const newLength =
				data.message.length - 'is contained twice, code: precond-60-2-3'.length;

			data.message = data.message.slice(0, newLength - 1);
			data.message = data.message.slice(data.message.lastIndexOf(' ') + 1);
		}
		if (data.httpStatusCode !== 200 || !data.state) {
			const errorMessage = ledidiStatusCodeToErrorMessage(
				data.ledidiStatusCode,
				data.message
			);
			throw new Error(errorMessage ?? Dictionary.errors.api.addons.JADBioInitDataset);
		}

		return { jadBioDatasetId: data.jadBioDatasetId, state: data.state };
	},

	getJadBioProjectId: async (input: GetJADBioProjectIdInput): Promise<string | null> => {
		const { data } = await sendRequest<GetJADBioProjectIdRequest, GetJADBioProjectIdResponse>(
			JADBIO_URL,
			{
				method: methods.JADBio.getJadbioProjectId,
				...input
			}
		);

		//TODO: remove this when we get proper error handling from BE
		if (data.message === 'tuple index out of range')
			data.ledidiStatusCode = LedidiStatusCode.JADBioProjectIdNotFound;

		if (data.ledidiStatusCode === LedidiStatusCode.JADBioProjectIdNotFound) {
			return null;
		}

		if (data.httpStatusCode !== 200 || !data.jadbioProjectId) {
			throw new Error(Dictionary.errors.api.addons.JADBioGetProject);
		}

		return data.jadbioProjectId;
	},

	// MODELS

	initiateClassificationModel: async (
		input: InitiateClassificationModelInput
	): Promise<InitiateClassificationModelOutput> => {
		const { data } = await sendRequestWithJADBioToken<
			InitiateClassificationModelRequest,
			InitiateClassificationModelResponse
		>(JADBIO_URL, {
			method: methods.JADBio.initiateModels.classification,
			...input
		});

		// TODO: REMOVE THIS ONCE WE GET THE RIGHT LEDIDISTATUSCODE
		if (data.message.includes('TooFewSamplesPerClassForAnalysis')) {
			data.ledidiStatusCode = LedidiStatusCode.JADBioTooFewSamplesPerClassForAnalysis;
		}
		if (data.message.includes('TooFewSamplesForAnalysis')) {
			data.ledidiStatusCode = LedidiStatusCode.JADBioTooFewSamplesForAnalysis;
		}

		if (!data.jadBioAnalysisId) {
			const errorMessage = ledidiStatusCodeToErrorMessage(data.ledidiStatusCode);
			throw new Error(errorMessage ?? Dictionary.errors.api.addons.JADBioAnalysis);
		}

		const output: InitiateClassificationModelOutput = {
			jadBioAnalysisId: data.jadBioAnalysisId
		};

		return output;
	},

	initiateSurvivalAnalysisModel: async (
		input: InitiateSurvivalAnalysisModelInput
	): Promise<InitiateSurvivalAnalysisModelOutput> => {
		const { data } = await sendRequestWithJADBioToken<
			InitiateSurvivalAnalysisModelRequest,
			InitiateSurvivalAnalysisModelResponse
		>(JADBIO_URL, {
			method: methods.JADBio.initiateModels.survival,
			...input
		});

		if (!data.jadBioAnalysisId) {
			throw new Error(Dictionary.errors.api.addons.JADBioAnalysis);
		}

		const output: InitiateSurvivalAnalysisModelOutput = {
			jadBioAnalysisId: data.jadBioAnalysisId
		};

		return output;
	},

	initiateRegressionModel: async (
		input: InitiateRegressionModelInput
	): Promise<InitiateRegressionModelOutput> => {
		const { data } = await sendRequestWithJADBioToken<
			InitiateRegressionModelRequest,
			InitiateRegressionModelResponse
		>(JADBIO_URL, {
			method: methods.JADBio.initiateModels.regression,
			...input
		});

		if (!data.jadBioAnalysisId) {
			throw new Error(Dictionary.errors.api.addons.JADBioAnalysis);
		}

		const output: InitiateRegressionModelOutput = {
			jadBioAnalysisId: data.jadBioAnalysisId
		};

		return output;
	},

	/**
	 * Similar to check-transaction-progress
	 *
	 * @returns boolean - `true` if its finished
	 */
	getAnalysisStatus: async (input: GetAnalysisStatusInput): Promise<GetAnalysisStatusOutput> => {
		const { data } = await sendRequestWithJADBioToken<
			GetAnalysisStatusRequest,
			GetAnalysisStatusResponse
		>(JADBIO_URL, {
			method: methods.JADBio.getAnalysis.status,
			...input
		});

		//TODO: remove this once we get the right ledidiStatusCode
		if (data.message.includes('code: notFound-110-2-1'))
			data.ledidiStatusCode = LedidiStatusCode.JADBioAnalysisNotFound;

		if (data.ledidiStatusCode)
			return {
				jadBioAnalysisStatus: JADBioAnalysisStatus.Aborted,
				jadBioAnalysisProgress: 0,
				jadBioAnalysisTimeElapsed: '',
				jadBioAnalysisStartTime: ''
			};

		if (!data.jadBioAnalysisStatus)
			throw new Error(Dictionary.errors.api.addons.JADBioAnalysis);

		return {
			jadBioAnalysisStatus: data.jadBioAnalysisStatus,
			jadBioAnalysisProgress: data.jadBioAnalysisProgress,
			jadBioAnalysisTimeElapsed: data.jadBioAnalysisTimeElapsed,
			jadBioAnalysisStartTime: data.jadBioAnalysisStatus
		};
	},

	getAnalysisResult: async (input: GetAnalysisResultInput): Promise<any> => {
		const { data } = await sendRequestWithJADBioToken<
			GetAnalysisResultsRequest,
			GetAnalysisResultsResponse
		>(JADBIO_URL, {
			method: methods.JADBio.getAnalysis.result,
			...input
		});

		return data;
	},

	getAnalysisResults: async (
		input: GetAnalysisResultsInput
	): Promise<GetAnalysisResultsOutput> => {
		const { data } = await sendRequestWithJADBioToken<
			GetAnalysisResultsRequest,
			GetAnalysisResultsResponse
		>(JADBIO_URL, {
			method: methods.JADBio.getAnalysis.results,
			...input
		});

		if (!Array.isArray(data)) throw new Error(Dictionary.errors.api.addons.JADBioAnalysis);

		const output: GetAnalysisResultsOutput = {
			data
		};

		return output;
	},

	deleteAnalyses: async (input: DeleteJADBioAnalysesInput): Promise<any> => {
		await sendRequestWithJADBioToken<DeleteJADBioAnalysesRequest>(JADBIO_URL, {
			method: methods.JADBio.getAnalysis.delete,
			...input
		});
	}
});

async function sendRequestWithJADBioToken<Request = any, Response = any>(
	url: string,
	body: Request,
	config?: AxiosRequestConfig,
	requestType?: RequestTypes
): Promise<Response> {
	const token = localStorage.getItem(StorageKeys.JADBioIdToken);
	return sendRequest(
		url,
		{ jadBioToken: { Authorization: token }, ...body },
		config,
		requestType
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionTypes, resetPassword } from 'store/auth';
import { Operation } from 'hooks/store/types';

interface Handlers {
	handler: (username: string) => void;
	resetError: () => void;
}

export function useResetPassword(): Operation<Handlers> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.RESET_PASSWORD);

	function handler(username: string) {
		dispatch(resetPassword(username));
	}

	const handlers = {
		handler,
		resetError
	};

	return [{ loading, error }, handlers];
}

import { FileImportErrors } from 'types/data/projects/types';
import {
	CloseIconContainer,
	Container,
	Content,
	FileName,
	IconContainer,
	WarningMessage
} from './ImportErrorWarning.style';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { dynamicTranslate } from '../../../../helpers/dynamicTranslate';
import { useEffect, useMemo, useState } from 'react';
import { ApiImportErrorType } from 'store/data/projects/constants';
import { Icon } from '../../../UI/Dropdown/DropdownToggle/DropdownToggle.style';
import { Colors, Svgs } from 'environment';
import { ErrorTable } from './ErrorTable';

type Props = {
	errors: FileImportErrors;
	importFileName: string;
	onClose: (errors: FileImportErrors | null) => void;
};
export function ImportErrorWarning({ errors, importFileName, onClose }: Props) {
	const { translate } = useTranslation();

	const [displayedFilename, setDisplayedFilename] = useState<string>(importFileName);

	const isMismatchError = useMemo(() => {
		return errors.some(err => err.type === ApiImportErrorType.MismatchingColumnNumber);
	}, [errors]);

	const warningMessage = useMemo(() => {
		if (isMismatchError) {
			const errCount = errors[0].errors!.length;

			return dynamicTranslate(
				translate(dict => dict.importDataset.importErrors.errorDescription),
				[errCount.toString()]
			);
		}

		return translate(dict => dict.importDataset.importErrors.fileNotSupported);
	}, [errors]);

	useEffect(() => {
		if (displayedFilename !== importFileName && importFileName !== '') {
			setDisplayedFilename(importFileName);
		}
	}, [importFileName]);

	function handleClose() {
		onClose(null);
		setDisplayedFilename('');
	}

	return (
		<Container>
			<IconContainer>
				<Icon
					svg={Svgs.File}
					open={false}
					colors={{
						color: Colors.text.error
					}}
					style={{
						paddingTop: '1rem',
						display: 'inline-block'
					}}
				/>
			</IconContainer>
			<FileName>{displayedFilename}</FileName>
			<Content>
				<WarningMessage id="warning_message_description">{warningMessage}</WarningMessage>
				{isMismatchError && <ErrorTable errValues={errors[0].errors!} />}
			</Content>
			<CloseIconContainer>
				<Icon
					svg={Svgs.Close}
					open={false}
					onClick={handleClose}
					colors={{
						color: Colors.text.error
					}}
					style={{
						paddingTop: '1rem',
						display: 'inline-block'
					}}
					size={s => s.m}
				/>
			</CloseIconContainer>
		</Container>
	);
}

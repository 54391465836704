import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';

import { NumberPlotXYAnalysis } from 'api/data/analyses';
import { AnalysisExportWrapper, AnalysisViewContainer } from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { NumberPlotXYChart } from './NumberPlotXYChart';
import { AnalysisChartContainer } from '../../UI';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { useAnalysesActiveColum, useFullscreenAnalysis, useProject } from 'hooks/store';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';

interface Props {
	analysis: NumberPlotXYAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function NumberPlotXYView({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading
}: Props) {
	const [{ data: project }] = useProject();
	const [fullscreen] = useFullscreenAnalysis();
	const { selectItemsLabelMap } = variablesDataSelectItems;
	const [activeColumn] = useAnalysesActiveColum();

	const {
		id,
		options: { configPanel, chartLegend = false },
		input: { variables },
		output: { dataset }
	} = analysis;

	const { variablesMap, variableSetsMap } = variablesData;

	const hasAggregationRules = !!Object.keys(variableSetsMap).find(
		setKey => variableSetsMap[setKey].aggregationRules.length > 0
	);

	const aggregationRules = Object.keys(variableSetsMap).flatMap(
		setKey => variableSetsMap[setKey].aggregationRules
	);

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const { parsedDataset } = useMemo(() => {
		const parsedDataset = cloneDeep(dataset);

		// TODO: check if it has category values
		// if (analysis.options.showCategoryLabels) {
		// 	parsedDataset.forEach(g => {
		// 		if (grouping && 'groupedCalculations' in g) {
		// 			g.groupedCalculations.forEach(variable => {
		// 				variable.groupValue =
		// 					(variables.groupingVariable &&
		// 						mapVariableCategoryValueToLabel(
		// 							variable.groupValue,
		// 							variablesMap[variables.groupingVariable]
		// 						)) ??
		// 					variable.groupValue;
		// 			});
		// 		}
		// 	});
		// }

		if (hasAggregationRules) {
			aggregationRules.forEach(rule => {
				if (
					analysis.input.variables.x_value === rule.name ||
					analysis.input.variables.y_value[0] === rule.name
				) {
					if (
						(analysis.output.dataset.data?.PlotResult.x_value[rule.name] &&
							analysis.output.dataset.data?.PlotResult.x_value[rule.name].length ===
								0) ||
						(analysis.output.dataset.data?.PlotResult.y_value[rule.name] &&
							analysis.output.dataset.data?.PlotResult.y_value[rule.name].length ===
								0)
					) {
						parsedDataset.data = null;
					}
				}
			});
		}
		return { parsedDataset };
	}, [
		dataset,
		variablesMap,
		analysis,
		analysis.options.showCategoryLabels,
		analysis.input.variables.x_value,
		analysis.input.variables.y_value,
		analysis.output.dataset.data?.PlotResult.x_value,
		analysis.output.dataset.data?.PlotResult.y_value[0]
	]);

	const chartId = ZingChartExportTypes.NumberPltoXY + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				chartId,
				getExportFileName(
					ExportFileNames.NumberPlotXYChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: selectItemsLabelMap[variables.x_value],
		labelY: selectItemsLabelMap[variables.y_value[0]]
	};

	const legendHeader = variables.grouping_variable
		? selectItemsLabelMap[variables.grouping_variable]
		: undefined;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
				<NumberPlotXYChart
					data={parsedDataset}
					isLegendEnabled={chartLegend}
					scalesLabels={scalesLabels}
					legendHeader={legendHeader}
					fullscreen={fullscreen}
					activeColumn={activeColumn}
					variablesMap={variablesMap}
					aggregatorVariableNameByAggregationRuleName={
						aggregatorVariableNameByAggregationRuleName
					}
					loading={loading}
				/>
			</AnalysisChartContainer>
			<AnalysisExportWrapper>
				<NumberPlotXYChart
					id={chartId}
					data={parsedDataset}
					scalesLabels={scalesLabels}
					legendHeader={legendHeader}
					variablesMap={variablesMap}
					aggregatorVariableNameByAggregationRuleName={
						aggregatorVariableNameByAggregationRuleName
					}
					isForExport
					isLegendEnabled
					loading={loading}
				/>
			</AnalysisExportWrapper>
		</AnalysisViewContainer>
	);
}

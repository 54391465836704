import { MediaQueries } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';
import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	display: flex;
	scroll-behavior: smooth;

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
	}

	@media print {
		height: auto;
	}
`;

export const Content = styled.div<Disabled>`
	flex: 1;

	${({ disabled }) => disableableComponent({ disabled })}
`;

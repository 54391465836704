import { useTranslation } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useMemoOnce, useStatic, useRender, useDispatch, useEffectOnce } from 'hooks/utils';

import { createActivity } from 'store/ui/activities';

type ReturnType<T> = [
	{
		data: T;
		loading: boolean;
		error: boolean;
		fetched: boolean;
	},
	// HANDLER
	(promiseFnHandler?: () => Promise<T>) => void,
	// RESETTER
	() => void
];

interface Props<T> {
	promiseFn?: () => Promise<T>;
	initialData: T;
	lazy?: boolean;
	activityType?: string;
	resetData?: {
		onFetch?: boolean;
		onError?: boolean;
	};
	handleError?: {
		showNotification?: boolean;
		translateMessage?: boolean;
	};
	onSuccess?: (data: T) => void;
	onError?: (e: any) => void;
}

export function useAPI<T>({
	promiseFn,
	initialData,
	lazy = false,
	activityType,
	resetData = {
		onFetch: false,
		onError: false
	},
	handleError = {
		showNotification: false,
		translateMessage: false
	},
	onSuccess,
	onError
}: Props<T>): ReturnType<T> {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { setError: setErrorNotification } = useAlerts();

	const initial = useMemoOnce(() => initialData);

	const [data, setData] = useStatic<T>(initial);
	const [loading, setLoading] = useStatic(lazy ? false : true);
	const [error, setError] = useStatic(false);
	const [fetched, setFetched] = useStatic(false);

	/**
	 * Control component renders - more performant
	 */
	const [, triggerRender] = useRender();

	/**
	 * Handles instant API call trigger
	 */
	useEffectOnce(() => {
		if (!lazy) handler();
	});

	async function handler(promiseFnHandler?: () => Promise<T>) {
		resetStates();

		const promiseFunction = promiseFnHandler ? promiseFnHandler : promiseFn;
		if (!promiseFunction) return;

		const activity =
			activityType !== undefined ? createActivity({ type: activityType, dispatch }) : null;

		try {
			activity?.begin();

			setLoading(true);
			triggerRender();

			const response = await promiseFunction();

			setData(response);
			setFetched(true);

			if (onSuccess) onSuccess(response);
		} catch (e) {
			errorHandler(e);

			setError(true);
			setFetched(false);

			if (resetData.onError) resetDataHandler();

			if (onError) onError(e);
		} finally {
			setLoading(false);
			triggerRender();

			activity?.end();
		}
	}

	function resetHandler() {
		setLoading(false);
		setError(false);
		setFetched(false);
		resetDataHandler();
		triggerRender();
	}

	function errorHandler(e: any) {
		if (!handleError.showNotification) return;

		const message = handleError.translateMessage ? translate(() => e.message) : e.message;

		setErrorNotification({ message });
	}

	function resetStates() {
		setLoading(false);
		setError(false);
		setFetched(false);
		if (resetData.onFetch) resetDataHandler();
	}

	function resetDataHandler() {
		setData(initial);
	}

	return [
		{
			data: data(),
			loading: loading(),
			error: error(),
			fetched: fetched()
		},
		handler,
		resetHandler
	];
}

import { useMemo, useState } from 'react';
import { Svgs } from 'environment';
import { ExportWizard } from 'components/Dataset/Modals';
import { Container } from './EntriesOptions.style';
import { ExportType } from 'consts';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useProjectId,
	useExpandGroupsContext,
	usePermissions,
	useTransferEntriesOwnershipContext,
	useEntriesErrors,
	useImportVariableSet,
	useOrganizationAccessRights,
	useProject,
	useUsername
} from 'hooks/store';
import { useModalState } from 'hooks/ui';
import { DownloadDatasetModal } from 'components/Dataset/Modals/DownloadDatasetModal/DownloadDatasetModal';
import { DownloadFormat } from 'api/data/entries';

export interface ExportOptions {
	type: ExportType;
	format: DownloadFormat;
}

type Props = {
	hasVariableSets: boolean;
};

export function EntriesOptions({ hasVariableSets }: Props) {
	const { translate } = useTranslation();

	const [projectId] = useProjectId();
	const [{ data: project }] = useProject();
	const userId = useUsername();

	const { collapseAllGroups, expandAllGroups, expanded } = useExpandGroupsContext();

	const allowToggleGroupsFeature = Object.keys(expanded).length > 0;
	const { hasExportDataAccess, hasDatasetWriteAccess, hasVariablesWriteAccess } =
		usePermissions();
	const [
		{
			data: { accessExportDelegation }
		}
	] = useOrganizationAccessRights();
	const { navigate, routes, promOrProject } = useNavigation();
	const hasOrganizationExportAccess =
		accessExportDelegation || project?.projectOwnerDetails?.userId === userId;

	const {
		actions: { setIsTransferOwnership }
	} = useTransferEntriesOwnershipContext();

	const canCollapseGroups = useMemo(() => {
		let canCollapse = false;
		Object.values(expanded).forEach(expanded => {
			if (expanded) {
				canCollapse = true;
			}
		});
		return canCollapse;
	}, [expanded]);

	const canExpandGroups = useMemo(() => {
		let canExpand = false;
		Object.values(expanded).forEach(expanded => {
			if (!expanded) {
				canExpand = true;
			}
		});
		return canExpand;
	}, [expanded]);

	const [{ errors: entriesErrors }] = useEntriesErrors();

	// EXPORT AS CSV MODAL
	const [exportEntriesAsCSVModalVisible, setExportEntriesAsCSVModalVisible] = useState(false);

	// EXPORT AS EXCEL MODAL
	const [exportEntriesAsExcelModalVisible, setExportEntriesAsExcelModalVisible] = useState(false);

	// EXPORT WIZARD MODAL
	const exportWizardModal = useModalState<ExportOptions>();

	function onImportDataset() {
		if (projectId) navigate(routes[promOrProject].import(projectId));
	}

	const [, { setIsImportVariableSet }] = useImportVariableSet();

	return (
		<>
			<Container>
				<Dropdown
					toggleComponent={({ ref, open, toggle }) => (
						<Icon
							variant={v => v.button}
							ref={ref}
							svg={Svgs.More}
							active={open}
							onClick={toggle}
						/>
					)}
					width={18}
					offset={{ top: 20, right: 0 }}
				>
					{allowToggleGroupsFeature && (
						<>
							<Dropdown.Item
								title={translate(dict => dict.entriesOptions.collapseAllGroups)}
								onClick={() => collapseAllGroups()}
								disabled={!canCollapseGroups}
							/>
							<Dropdown.Item
								title={translate(dict => dict.entriesOptions.expandAllGroups)}
								disabled={!canExpandGroups}
								onClick={() => expandAllGroups()}
							/>
						</>
					)}
					{hasDatasetWriteAccess && hasVariablesWriteAccess && (
						<Dropdown.Item
							title={translate(dict => dict.projectOptions.importDataset)}
							onClick={onImportDataset}
						/>
					)}
					{hasDatasetWriteAccess && hasVariablesWriteAccess && (
						<Dropdown.Item
							title={translate(dict => dict.projectOptions.importVariableSet)}
							onClick={() => {
								onImportDataset();
								setIsImportVariableSet(true);
							}}
						/>
					)}
					{hasExportDataAccess && hasOrganizationExportAccess && (
						<Dropdown.Item
							title={translate(dict => dict.entriesOptions.downloadAsCSV)}
							disabled={!projectId}
							onClick={() => setExportEntriesAsCSVModalVisible(true)}
						/>
					)}
					{hasExportDataAccess && hasOrganizationExportAccess && (
						<Dropdown.Item
							title={translate(dict => dict.entriesOptions.downloadAsExcel)}
							disabled={!projectId}
							onClick={() => setExportEntriesAsExcelModalVisible(true)}
						/>
					)}
					{hasExportDataAccess && hasOrganizationExportAccess && (
						<Dropdown.Item
							title={translate(
								({ exportWizard }) => exportWizard.exportType.seriesAsCSV
							)}
							disabled={!projectId || !hasVariableSets}
							onClick={() =>
								exportWizardModal.open({
									type: ExportType.Series,
									format: DownloadFormat.CSV
								})
							}
						/>
					)}
					{hasExportDataAccess && hasOrganizationExportAccess && (
						<Dropdown.Item
							title={translate(
								({ exportWizard }) => exportWizard.exportType.seriesAsExcel
							)}
							disabled={!projectId || !hasVariableSets}
							onClick={() =>
								exportWizardModal.open({
									type: ExportType.Series,
									format: DownloadFormat.Excel
								})
							}
						/>
					)}
					<Dropdown.Item
						title={translate(
							dict => dict.dataset.entries.transferOwnership.moreMenu.item
						)}
						disabled={!!entriesErrors}
						onClick={() => {
							setIsTransferOwnership(true);
						}}
					/>
				</Dropdown>
			</Container>

			{(exportEntriesAsCSVModalVisible || exportEntriesAsExcelModalVisible) && (
				<DownloadDatasetModal
					onClose={() =>
						exportEntriesAsCSVModalVisible
							? setExportEntriesAsCSVModalVisible(false)
							: setExportEntriesAsExcelModalVisible(false)
					}
					format={
						exportEntriesAsCSVModalVisible ? DownloadFormat.CSV : DownloadFormat.Excel
					}
				/>
			)}
			{exportWizardModal.visible && (
				<ExportWizard
					exportScreenType={ExportType.Series}
					onClose={exportWizardModal.close}
					format={exportWizardModal.payload?.format}
				/>
			)}
		</>
	);
}

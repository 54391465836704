import { CSSGrid } from 'components/UI/CSSGrid';
import { Icon } from 'components/UI/Icons/Icon';
import { Typography } from 'components/UI/Typography';
import { Colors, Shadows } from 'environment';
import styled from 'styled-components';

export const Container = styled(CSSGrid.Col)`
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	padding: 0 2.4rem;
	padding: 1.6rem 2.4rem;
	position: relative;
	width: calc(100% + 3.9vw);
	top: 0;
	bottom: 0;
	z-index: 1001;
	overflow: auto;
`;

export const CloseIcon = styled(Icon)`
	cursor: pointer;
`;

export const Label = styled(Typography.Caption)`
	color: ${Colors.text.main};
	font-weight: 500;
`;

export const MappingContainer = styled.div`
	display: flex;
	gap: 8px;
	:hover {
		> :last-child {
			visibility: visible;
		}
	}
`;

export const Equals = styled.div`
	display: flex;
	align-items: center;
	font-weight: 200;
	font-size: 24px;
	height: 38px;
	cursor: arrow;
	user-select: none;
`;

export const DeleteButton = styled(Icon)`
	opacity: 0.6;
	height: 38px;
	:hover {
		color: ${Colors.primary.hover};
		opacity: 1;
	}
	visibility: hidden;
`;

export const Title = styled(Typography.Caption)`
	font-size: 1.4rem;
	color: ${Colors.text.main};
	font-weight: 500;
`;

export const Stack = styled.div`
	display: flex;
	flex-direction: column;
`;

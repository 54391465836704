import { addDays, addHours, addMonths, addWeeks, isAfter } from 'date-fns';
import format from 'date-fns/format';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Patient, TimelineDistribution } from 'api/data/patients';
import { DATE_FORMAT } from 'consts';
import { Colors, Svgs } from 'environment';
import { useElementSize } from 'hooks/ui/useElementSize';
import { Nullable, SetState } from 'types/index';

import {
	CardContainer,
	DistributionWrapper,
	FooterDetail,
	FooterDetailSwitch,
	FooterRight,
	Icon,
	Line,
	PatientContent,
	PatientFooter,
	PatientHeader,
	ScrollableArea,
	SwitchContainer
} from './PatientCard.style';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Switch } from 'components/UI/Interactables/Switch';
import { Button } from 'components/UI/Interactables/Button';
import { formatAPIDate } from 'helpers/dateFormat';
import { useTranslation, useUpdatePatient } from 'hooks/store';
import { useAlerts, useMeasureText, useHorizontalScroll } from 'hooks/ui';
import { usePrevious } from 'hooks/utils';

const DATE_FORMAT_SHORT = 'd MMM';

enum AmountOfTime {
	hours = 'hours',
	days = 'days',
	weeks = 'weeks',
	months = 'months'
}

interface Props {
	patient: Patient;
	setSelectedPatient: SetState<Patient | null>;
}

export function PatientCard({ patient, setSelectedPatient }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const dateNowRef = useRef(new Date());
	const { handleRef: footerRef, width: footerWidth } = useElementSize();
	const { measureText } = useMeasureText();

	const [isOpen, setIsOpen] = useState(false);
	const [active, setActive] = useState(!!patient.active);
	const [oneColumnFooter, setOneColumnFooter] = useState(false);

	const { handleRef } = useHorizontalScroll();

	const [{ error: updateError, loading: updating }, updatePatient] = useUpdatePatient(
		patient.patientId
	);

	const wasUpdating = usePrevious(updating);
	const wasActive = usePrevious(patient.active);
	useEffect(() => {
		if (wasUpdating && !updating && !updateError) {
			setNotification({
				message: translate(dict =>
					wasActive !== active
						? dict.promsPatientsModal.dataUpdate.distributionChanged
						: dict.promsPatientsModal.dataUpdate.patientUpdated
				)
			});
		}
	}, [updating, wasUpdating, updateError]);

	useEffect(() => {
		if (!updating && updateError) {
			setActive(!!patient.active);
		}
	}, [updating, updateError]);

	/**
	 * Calculates if there is enough space to show footer on two columns
	 * In case footer's text is too large show information on one
	 */
	useEffect(() => {
		const text = footerTextSize;

		// value of 110 consists of margins and FooterDetail's icon and switch widths
		setOneColumnFooter(footerWidth !== 0 && footerWidth < text + 110);
	}, [footerWidth]);

	const startDateObj = new Date(formatAPIDate(patient.startTime));

	const distributionDates = patient.patientTimeLine
		? Object.values(patient.patientTimeLine).map((distribution: TimelineDistribution) => {
				let ret = new Date(0);
				if (distribution.unitOfTime === AmountOfTime.hours)
					ret = addHours(startDateObj, distribution.quantity);
				else if (distribution.unitOfTime === AmountOfTime.days)
					ret = addDays(startDateObj, distribution.quantity);
				else if (distribution.unitOfTime === AmountOfTime.weeks)
					ret = addWeeks(startDateObj, distribution.quantity);
				else if (distribution.unitOfTime === AmountOfTime.months)
					ret = addMonths(startDateObj, distribution.quantity);

				return {
					date: ret,
					...distribution
				};
		  })
		: ({} as TimelineDistribution[]);

	const nextDistributionDate = distributionDates.reduce<Nullable<string>>(
		(acc, distributionDate) => {
			const date = new Date(formatAPIDate(distributionDate.timeToSend));

			if (!acc && isAfter(date, dateNowRef.current)) {
				return format(date, DATE_FORMAT_SHORT);
			}
			return acc;
		},
		null
	);

	const allDistributionsTimeline = distributionDates.map(
		({ timeToSend, unitOfTime, quantity, filled, sent, partiallyFilled }) => ({
			dateLabel: format(new Date(formatAPIDate(timeToSend)), DATE_FORMAT_SHORT),
			dateFull: format(new Date(formatAPIDate(timeToSend)), DATE_FORMAT),
			intervalLabel: `${quantity} ${unitOfTime}`,
			totalForms: sent,
			filledForms: filled,
			partialForms: partiallyFilled
		})
	);

	const firstDistribution = allDistributionsTimeline.shift();

	allDistributionsTimeline.unshift({
		dateLabel: format(startDateObj, DATE_FORMAT_SHORT),
		dateFull: format(startDateObj, DATE_FORMAT),
		intervalLabel: translate(dict => dict.patientCard.startDate),
		totalForms: firstDistribution?.totalForms ?? false,
		filledForms: firstDistribution?.filledForms ?? false,
		partialForms: firstDistribution?.partialForms ?? false
	});

	const footerTextSize = useMemo(() => {
		let totalString =
			patient.emailAddress.length > patient.phoneNumber.length
				? patient.emailAddress
				: patient.phoneNumber;

		const rightMaxString = translate(dict => dict.patientCard.disableDistribution);

		if (rightMaxString) {
			totalString = totalString.concat(rightMaxString);
		}

		return measureText(totalString);
	}, [patient]);

	return (
		<CardContainer column={isOpen}>
			{isOpen && <Spacer size={s => s.xs} />}
			<PatientHeader
				color={active ? Colors.primary.normal : Colors.primary.disabled}
				open={isOpen}
			>
				<Flex align={a => a.center}>
					<Icon
						showTooltip
						title="Expand"
						svg={Svgs.ChevronDown}
						onClick={() => setIsOpen(state => !state)}
						rotate={isOpen ? 180 : 0}
					/>
					<Typography.Paragraph>
						{patient.firstName} {patient.lastName}
					</Typography.Paragraph>
				</Flex>
				{!isOpen && nextDistributionDate && (
					<Flex align={a => a.end} column marginOffset={{ right: 1 }}>
						<Typography.Paragraph
							color={active ? Colors.text.main : Colors.text.disabled}
						>
							{nextDistributionDate}
						</Typography.Paragraph>

						<Typography.Hint color={active ? Colors.text.main : Colors.text.disabled}>
							{translate(dict => dict.patientCard.nextDistribution)}
						</Typography.Hint>
					</Flex>
				)}
			</PatientHeader>
			{isOpen && (
				<>
					<Spacer size={s => s.s} />
					<ScrollableArea ref={handleRef}>
						<PatientContent>
							{allDistributionsTimeline.map(
								(
									{
										dateLabel,
										dateFull,
										intervalLabel,
										filledForms,
										totalForms,
										partialForms
									},
									index
								) => (
									<DistributionWrapper key={index}>
										<Icon
											svg={
												isAfter(
													new window.Date(dateFull),
													new window.Date()
												)
													? Svgs.Clock
													: Svgs.CheckCircle
											}
											colors={{
												color: !active
													? Colors.text.disabled
													: filledForms
													? Colors.surveyChartColors.green.medium
													: partialForms
													? Colors.primary.normal
													: totalForms
													? Colors.surveyChartColors.yellow
													: Colors.text.disabled
											}}
										/>
										<Line
											startColor={
												!active
													? Colors.text.disabled
													: filledForms
													? Colors.surveyChartColors.green.medium
													: partialForms
													? Colors.primary.normal
													: totalForms
													? Colors.surveyChartColors.yellow
													: Colors.text.disabled
											}
											endColor={
												!active
													? Colors.text.disabled
													: index < allDistributionsTimeline.length - 1
													? allDistributionsTimeline[index + 1]
															.filledForms
														? Colors.surveyChartColors.green.medium
														: allDistributionsTimeline[index + 1]
																.partialForms
														? Colors.primary.normal
														: allDistributionsTimeline[index + 1]
																.totalForms
														? Colors.surveyChartColors.yellow
														: Colors.text.disabled
													: Colors.transparent
											}
										/>
										<Typography.H4
											color={active ? Colors.text.main : Colors.text.disabled}
										>
											{dateLabel}
										</Typography.H4>
										<Typography.Caption
											color={active ? Colors.text.main : Colors.text.disabled}
										>
											{intervalLabel}
										</Typography.Caption>
									</DistributionWrapper>
								)
							)}
						</PatientContent>
					</ScrollableArea>
					<Spacer size={s => s.m} />
					<PatientFooter ref={footerRef} columnDirection={oneColumnFooter}>
						<Flex column>
							<Flex align={a => a.center}>
								<Icon svg={Svgs.Email} size={s => s.m} />
								<Typography.Paragraph breakWord>
									{patient.emailAddress}
								</Typography.Paragraph>
							</Flex>
							<Flex align={a => a.center}>
								<Icon
									svg={Svgs.Phone} // svg to be added. previous icon: Phone
									size={s => s.m}
								/>
								<Typography.Paragraph
									breakWord
								>{`${patient.phoneCountryCode} ${patient.phoneNumber}`}</Typography.Paragraph>
							</Flex>
						</Flex>
						<FooterRight>
							<FooterDetailSwitch alignRight={true} forceAlignLeft={oneColumnFooter}>
								<Typography.Paragraph>
									{translate(dict => dict.patientCard.disableDistribution)}
								</Typography.Paragraph>
								<SwitchContainer>
									<Switch
										on={active}
										disabled={updating}
										onChange={() => {
											updatePatient({
												...patient,
												active: !active
											});
											setActive(!active);
										}}
									/>
								</SwitchContainer>
							</FooterDetailSwitch>
							<FooterDetail alignRight={true} forceAlignLeft={oneColumnFooter}>
								<Button
									variant={v => v.link}
									title={translate(dict => dict.patientCard.editPatient)}
									onClick={() => setSelectedPatient(patient)}
									marginOffset={{
										custom: oneColumnFooter ? '2rem 0 0 0 ' : '0'
									}}
								/>
							</FooterDetail>
						</FooterRight>
					</PatientFooter>
				</>
			)}
		</CardContainer>
	);
}

// exports

export const icons = {
	checkmark: `
		<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="8px" viewBox="0 0 12 8">
			<path fill="currentColor" d="M11.2745609,0.246957754 C10.9929169,-0.048431774 10.5443241,-0.0796992493 10.2274597,0.157828585 L10.1445326,0.229276761 L4.23623951,6.05450214 L1.85546742,3.70627758 L1.77254026,3.63482941 C1.45567591,3.39730157 1.00708309,3.42856905 0.725439144,3.72395858 C0.446122832,4.01690687 0.427163128,4.46878091 0.664077983,4.78328825 L0.742824194,4.87322316 L3.68085807,7.77072384 L3.76194754,7.84078045 C4.04070837,8.05095026 4.42309957,8.05305196 4.70393301,7.84708554 L4.79350129,7.77072384 L11.2571758,1.39622233 L11.3299458,1.31412132 C11.5731348,0.999322358 11.5562048,0.542347282 11.2745609,0.246957754 Z"></path>
		</svg>
	`,
	group: `
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<path fill="currentColor" d="M6.2 2.333h-3c-1.03 0-1.867.782-1.867 1.75v7.835c0 .967.838 1.749 1.867 1.749h9.6c1.03 0 1.867-.782 1.867-1.75V5.762l-.004-.106c-.059-.918-.872-1.643-1.863-1.643H7.753l-1-1.401c-.124-.174-.331-.278-.553-.278zm-3 1.26h2.646l1.001 1.401c.124.174.331.278.553.278h5.4c.296 0 .533.22.533.49v6.156c0 .268-.237.49-.533.49H3.2c-.296 0-.533-.222-.533-.49V4.082c0-.268.237-.49.533-.49z" />
		</svg>
	`
};

import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 2.4rem 3.2rem 3.2rem 3.2rem;
	border: 1px solid ${Colors.avatar.paleGreyLight};
`;

export const Layout = styled.div`
	display: grid;
	gap: 1.6rem;
	grid-template-columns: 1fr 24px 1fr;
	grid-template-areas: 'column-name arrow variable-type';

	.column-name {
		grid-area: column-name;

		input {
			background-color: white;
			padding: 0.5rem 0.8rem;
			height: 3.2rem;
		}
	}

	.arrow {
		grid-area: arrow;

		height: 48px;
		margin-top: 15px;
	}

	.select__menu {
		z-index: 100;
	}
`;

export const VariableTypeContainer = styled.div`
	grid-area: variable-type;

	display: flex;
	flex-direction: column;

	.select__control {
		height: 3.2rem;
		min-height: 3.2rem;
		padding: 0 0 0 0.8rem;
	}

	.select__value-container {
		padding: 0;
	}

	.select-data-type-custom {
		input {
			padding: 0.5rem 0.8rem;
			height: 3.2rem;
		}
	}
`;

import { useEffect, useState } from 'react';

import {
	CompareNumeric,
	Correlations,
	JADBio,
	NumberPlotXY,
	TimeCourse
} from 'components/Analysis/Analyses';
import { MediaQueries, Svgs } from 'environment';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { AnalysisOptions } from './AnalysisOptions';

import { AnalysisCard, AnalysisCardContainer, InvalidSnapshotContainer } from './Analysis.style';
import { AnalysisContext } from 'contexts';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import {
	useTranslation,
	useAnalysisById,
	useDeleteAnalysis,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysisOpenState
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import {
	Columns,
	CompareNumericAnalysisV2,
	ComparePairedAnalysisV2,
	CorrelationsAnalysis,
	CrosstabAnalysisV2,
	DensityPlotAnalysisV2,
	ExploreAnalysisV2,
	FrequenciesAnalysisV2,
	JADBioAnalysis,
	KaplanMeierAnalysisV2,
	LogisticRegressionAnalysisV2,
	NumberPlotXYAnalysis,
	PlotNumericAnalysisV2,
	TimeCourseAnalysisV2
} from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';
import { ExploreV2 } from '../../ExploreAnalysisV2/ExploreV2';
import { DensityPlotV2 } from '../../DensityPlotV2/DensityPlotV2';
import { PlotNumericV2 } from '../../PlotNumericAnalysisV2/PlotNumericV2';
import { LogisticRegressionV2 } from '../../LogisticRegressionV2/LogisticRegressionV2';
import { CrosstabV2 } from '../../CrosstabAnalysisV2/CrosstabV2';
import { FrequenciesV2 } from '../../FrequenciesAnalysisV2/FrequenciesV2';
import { ComparePairedV2 } from '../../ComparePairedAnalysisV2/ComparePairedV2';
import { KaplanMeierV2 } from '../../KaplanMeierAnalysisV2/KaplanMeierV2';

interface Props {
	id: string;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
}

export function Analysis({ id, variablesData, variablesDataSelectItems }: Props) {
	const { translate } = useTranslation();

	const analysis = useAnalysisById(id);
	const deleteAnalysis = useDeleteAnalysis();
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const [{ isConfigPanelOpen }, { openConfigPanel }] = useAnalysisConfigPanel(id);
	// TODO: deprecate
	const [openState] = useAnalysisOpenState(id);
	const [exporting, setExporting] = useState(false);
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);
	useEffect(() => {
		if (exporting) {
			setExporting(false);
		}
	}, [exporting]);

	function handleDelete() {
		deleteAnalysis(id);
	}

	function handleExport() {
		setExporting(true);
	}

	function handleToggleConfig() {
		openConfigPanel({ analysisId: id, openConfig: !isConfigPanelOpen });
	}

	function handleFullScreen() {
		if (activeColumn !== Columns.OneColumn && !fullscreen) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	}

	if (analysis.invalid) {
		const title = `${translate(() => analysis.type)} ${translate(
			({ analysis }) => analysis.errors.snapshots.invalid
		)}`;

		return (
			<InvalidSnapshotContainer>
				<Typography.Paragraph>{title}</Typography.Paragraph>
				<AnalysisOptions open={false} onDelete={handleDelete} onExport={() => undefined} />
			</InvalidSnapshotContainer>
		);
	}
	return (
		<AnalysisCardContainer>
			<AnalysisCard activeColumn={activeColumn} onClick={handleFullScreen}>
				<Flex
					fullWidth
					justify={j => j.between}
					align={a => a.center}
					paddingOffset={{ y: 0, x: 2.4 }}
				>
					<Typography.H5>{translate(() => analysis.type)}</Typography.H5>
					<Flex>
						{openState &&
							!fullscreen &&
							activeColumn === Columns.OneColumn &&
							!isMobileDevice && (
								<Flex>
									<Icon
										variant={v => v.button}
										svg={Svgs.Edit}
										onClick={handleToggleConfig}
										active={isConfigPanelOpen}
										disabled={!openState}
										marginOffset={{ right: 1 }}
									/>
									<Icon
										title={translate(
											({ analysis }) => analysis.actions.fullscreen
										)}
										variant={v => v.button}
										svg={Svgs.Maximize}
										onClick={() =>
											setFullscreenAnalysis({ fullscreen: analysis.id })
										}
										disabled={!openState}
										marginOffset={{ right: 0.4, left: 0.4 }}
									/>
								</Flex>
							)}
						{(!fullscreen && activeColumn === Columns.OneColumn) ||
						(!isMobileDevice && fullscreen) ? (
							<AnalysisOptions
								open={openState}
								onDelete={handleDelete}
								onExport={handleExport}
							/>
						) : null}
					</Flex>
				</Flex>
				<AnalysisContext.Provider value={{ analysisId: id }}>
					{analysis.type === AnalysisType.FrequenciesV2 && (
						<FrequenciesV2
							analysis={analysis as FrequenciesAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.ExploreV2 && (
						<ExploreV2
							analysis={analysis as ExploreAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.CompareNumericV2 && (
						<CompareNumeric
							analysis={analysis as CompareNumericAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}

					{analysis.type === AnalysisType.CrosstabV2 && (
						<CrosstabV2
							analysis={analysis as CrosstabAnalysisV2}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}

					{analysis.type === AnalysisType.KaplanV2 && (
						<KaplanMeierV2
							analysis={analysis as KaplanMeierAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.CorrelationsV2 && (
						<Correlations
							analysis={analysis as CorrelationsAnalysis}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}

					{analysis.type === AnalysisType.PlotNumericV2 && (
						<PlotNumericV2
							analysis={analysis as PlotNumericAnalysisV2}
							variablesDataSelectItems={variablesDataSelectItems}
							variablesData={variablesData}
							exporting={exporting}
						/>
					)}

					{analysis.type === AnalysisType.DensityPlotV2 && (
						<DensityPlotV2
							analysis={analysis as DensityPlotAnalysisV2}
							variablesDataSelectItems={variablesDataSelectItems}
							variablesData={variablesData}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.TimeCourseV2 && (
						<TimeCourse
							analysis={analysis as TimeCourseAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.ComparePairedV2 && (
						<ComparePairedV2
							analysis={analysis as ComparePairedAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.NumberPlotXY && (
						<NumberPlotXY
							analysis={analysis as NumberPlotXYAnalysis}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.JADBio && (
						<JADBio
							analysis={analysis as JADBioAnalysis}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
					{analysis.type === AnalysisType.LogisticRegressionV2 && (
						<LogisticRegressionV2
							analysis={analysis as LogisticRegressionAnalysisV2}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							exporting={exporting}
						/>
					)}
				</AnalysisContext.Provider>
			</AnalysisCard>
		</AnalysisCardContainer>
	);
}

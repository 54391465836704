import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { UserCard } from 'components/UI/UserCard';

import { Container } from './EligibleUser.style';

interface Props {
	emailAddress: string;
	checked: boolean;
	handleCheck: (emailAddress: string) => void;
}

export function EligibleInvitedByEmail({ emailAddress, checked, handleCheck }: Props) {
	return (
		<Container confirmedUser>
			<Checkbox
				className="confirm-checkbox"
				checked={checked}
				onClick={() => handleCheck(emailAddress)}
			/>
			<UserCard.Basic userData={{ emailAddress }} userId={null} />
		</Container>
	);
}

import { createSelector } from 'reselect';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getUsersById({ users: { byId } }: State) {
	return byId;
}

function getUsersMetadata({ users: { metadata } }: State) {
	return metadata;
}

function getUsersFilters({ users: { filters } }: State) {
	return filters;
}

function getEnterpriseOrganizations(state: State) {
	return state.enterpriseOrganizations?.byOrganizationId;
}

function getDomainsForOrganization(state: State, organizationId: string) {
	return state.enterpriseOrganizations?.byOrganizationId[organizationId];
}

function getOrganizationId(state: State) {
	return state.enterpriseOrganizations?.organizationId;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectAdminUsersUserId = createSelector(
	[getUsersMetadata],
	usersMetadata => usersMetadata.userId
);

export const selectAdminUsersList = createSelector([getUsersById], usersById =>
	Object.values(usersById)
);

export const selectAdminUserDetails = createSelector(
	[getUsersById, selectAdminUsersUserId],
	(usersById, userId) => {
		if (userId && usersById[userId]) return usersById[userId];
	}
);

export const selectAdminUsersTableParams = createSelector([getUsersMetadata], usersMetadata => ({
	pageIndex: usersMetadata.pageIndex,
	pageSize: usersMetadata.pageSize
}));

export const selectAdminUsersFilters = createSelector(
	[getUsersFilters],
	usersFilters => usersFilters
);

export const selectEnterpriseOrganizations = createSelector(
	[getEnterpriseOrganizations],
	organization => organization
);

export const selectDomainsForOrganization = createSelector(
	[getDomainsForOrganization],
	organization => organization
);

export const selectOrganizationId = createSelector(
	[getOrganizationId],
	organizationId => organizationId
);

import type { CollaboratorPermissions, GeneralPermissions } from 'store/data/collaborators';
import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export interface State {
	projectId: string | null;
	projectRoles: {
		byId: RolesMap;
		byProjectId: GenericMap<{
			ids: string[];
			fetched: boolean;
			metadata: {
				searchTerm: string;
			};
		}>;
	};
	templateRoles: {
		byId: TemplateRolesMap;
		ids: {
			owned: string[];
			shared: string[];
			public: string[];
		};
		fetched: boolean;
		metadata: {
			searchTerm: string;
			activeTab: TemplateRolesHeaderTab;
		};
	};
}

export enum TemplateRolesHeaderTab {
	OWNED = 'owned',
	// TODO: Add back in the future
	// ORGANIZATION = 'organization',
	SHARED = 'shared'
}

export type RolesData = {
	roles: Role[];
	rolesMap: RolesMap;
};

// TODO: needs rethinking
export type TemplateRolesData = {
	roles: {
		owned: TemplateRole[];
		shared: TemplateRole[];
		public: TemplateRole[];
	};
	rolesMap: {
		owned: TemplateRolesMap;
		shared: TemplateRolesMap;
		public: TemplateRolesMap;
	};
};

export type Role = {
	projectId: string;
	id: string;
	name: string;
	description: string;
	permissions: CollaboratorPermissions;
};

export type ShareListData = {
	projectShareList: {
		current: string[];
		initial: string[];
	};
	userShareList: {
		current: string[];
		initial: string[];
	};
	fetched: boolean;
};

export type TemplateRole = {
	id: string;
	name: string;
	description: string;
	publicAccess: boolean;
	permissions: GeneralPermissions;
	shareList: ShareListData;
};

export type NewRole = Omit<Role, 'id'>;
export type NewTemplateRole = Omit<TemplateRole, 'id' | 'shareList'>;

export type RolesMap = GenericMap<Role>;
export type TemplateRolesMap = GenericMap<TemplateRole>;

export enum ActionTypes {
	// TEMPLATE ROLES
	GET_TEMPLATE_ROLES = 'data/roles/GET_TEMPLATE_ROLES',
	CREATE_TEMPLATE_ROLE = 'data/roles/CREATE_TEMPLATE_ROLE',
	UPDATE_TEMPLATE_ROLE = 'data/roles/UPDATE_TEMPLATE_ROLE',
	SHARE_ROLE_TEMPLATE = 'data/roles/SHARE_ROLE_TEMPLATE',
	UNSHARE_ROLE_TEMPLATE = 'data/roles/UNSHARE_TEMPLATE_ROLE',
	GET_ROLE_TEMPLATE_SHARE_LIST = 'data/roles/GET_GET_ROLE_TEMPLATE_SHARE_LIST',
	RESET_ROLE_TEMPLATE_SHARE_LIST_FETCHED = 'data/roles/RESET_ROLE_TEMPLATE_SHARE_LIST_FETCHED',
	RESET_ROLE_TEMPLATE_SHARE_LIST = 'data/roles/RESET_ROLE_TEMPLATE_SHARE_LIST',
	SHARE_ROLE_TEMPLATE_WITH_INSTANCE = 'data/roles/SHARE_ROLE_TEMPLATE_WITH_INSTANCE',
	UNSHARE_ROLE_TEMPLATE_WITH_INSTANCE = 'data/roles/UNSHARE_ROLE_TEMPLATE_WITH_INSTANCE',
	DELETE_TEMPLATE_ROLE = 'data/roles/DELETE_TEMPLATE_ROLE',
	CHANGE_TEMPLATE_ROLE_PAGE_TAB = 'data/roles/CHANGE_TEMPLATE_ROLE_PAGE_TAB',
	// PROJECT ROLES
	GET_ROLES = 'data/roles/GET_ROLES',
	CREATE_ROLE = 'data/roles/CREATE_ROLE',
	UPDATE_ROLE = 'data/roles/UPDATE_ROLE',
	DELETE_ROLE = 'data/roles/DELETE_ROLE',
	ASSIGN_ROLE = 'data/roles/ASSIGN_ROLE'
}

export type GetTemplateRolesAction = Action<
	ActionTypes.GET_TEMPLATE_ROLES,
	{
		ownedTemplateRoles: TemplateRole[];
		sharedTemplateRoles: TemplateRole[];
		publicTemplateRoles: TemplateRole[];
	}
>;

export type CreateTemplateRoleAction = Action<
	ActionTypes.CREATE_TEMPLATE_ROLE,
	{
		templateRole: TemplateRole;
	}
>;

export type UpdateTemplateRoleAction = Action<
	ActionTypes.UPDATE_TEMPLATE_ROLE,
	{
		templateRole: TemplateRole;
	}
>;

export type DeleteTemplateRoleAction = Action<
	ActionTypes.DELETE_TEMPLATE_ROLE,
	{
		templateRoleId: TemplateRole['id'];
	}
>;

export type GetRoleTemplateShareListAction = Action<
	ActionTypes.GET_ROLE_TEMPLATE_SHARE_LIST,
	{
		users: string[];
		projects: string[];
		templateRoleId: TemplateRole['id'];
	}
>;

export type ResetShareListAction = Action<
	ActionTypes.RESET_ROLE_TEMPLATE_SHARE_LIST,
	{
		templateRoleId: string;
	}
>;

export type ResetRoleTemplateShareListFetchedAction = Action<
	ActionTypes.RESET_ROLE_TEMPLATE_SHARE_LIST_FETCHED,
	{
		templateRoleId: string;
	}
>;

export type ShareRoleTemplateWithInstanceAction = Action<
	ActionTypes.SHARE_ROLE_TEMPLATE_WITH_INSTANCE,
	{
		templateRoleId: string;
		instanceId: string;
		userShare: boolean;
	}
>;

export type UnshareRoleTemplateWithInstanceAction = Action<
	ActionTypes.UNSHARE_ROLE_TEMPLATE_WITH_INSTANCE,
	{
		templateRoleId: string;
		instanceId: string;
		userShare: boolean;
	}
>;

export type ShareRoleTemplateAction = Action<
	ActionTypes.SHARE_ROLE_TEMPLATE,
	{
		templateRoleId: string;
		users: string[];
		projects: string[];
	}
>;

export type ChangeTemplateRolePageTabAction = Action<
	ActionTypes.CHANGE_TEMPLATE_ROLE_PAGE_TAB,
	{
		activeTab: TemplateRolesHeaderTab;
	}
>;

export type GetRolesAction = Action<
	ActionTypes.GET_ROLES,
	{
		projectId: string;
		roles: Role[];
	}
>;

export type CreateRoleAction = Action<
	ActionTypes.CREATE_ROLE,
	{
		projectId: string;
		role: Role;
	}
>;

export type UpdateRoleAction = Action<
	ActionTypes.UPDATE_ROLE,
	{
		projectId: string;
		role: Role;
	}
>;

export type DeleteRoleAction = Action<
	ActionTypes.DELETE_ROLE,
	{
		projectId: string;
		roleId: Role['id'];
	}
>;

export type Actions =
	| GetTemplateRolesAction
	| CreateTemplateRoleAction
	| UpdateTemplateRoleAction
	| DeleteTemplateRoleAction
	| GetRoleTemplateShareListAction
	| ResetShareListAction
	| ResetRoleTemplateShareListFetchedAction
	| ShareRoleTemplateWithInstanceAction
	| UnshareRoleTemplateWithInstanceAction
	| ChangeTemplateRolePageTabAction
	| GetRolesAction
	| CreateRoleAction
	| UpdateRoleAction
	| DeleteRoleAction;

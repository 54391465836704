import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const TimeZoneSettingsContainer = styled.div`
	color: ${Colors.text.tableMain};
	background-color: ${Colors.background.disabledSecondary};

	border-radius: 4px;
	padding: 0.9rem 1.2rem;
`;

export const TimeZoneLabel = styled.div`
	${getTypographyCSS(t => t.Paragraph)};
	font-weight: bold;
	margin-bottom: 0.4rem;
`;

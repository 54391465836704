import { Droppable } from 'react-beautiful-dnd';
import { Dependency } from 'store/data/dependencies';
import { DragAndDropTypes, SelectItem } from 'types/index';
import { DependencyRule } from '../DependencyRule';
import { Container } from './DependenciesGrid.styles';
import { Button } from 'components/UI/Interactables/Button';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { Typography } from 'components/UI/Typography';
import { useDependenciesContext, useTranslation, useVariablesData } from 'hooks/store';
import { usePaginate } from 'hooks/utils';

interface Props {
	dependencies: Dependency[];
	areRulesExpanded: boolean;
	getDependantVariablesSelectItems: (dependency: Dependency) => SelectItem[];
	dependencySuppliersSelectItems: SelectItem[];
}

export function DependenciesGrid({
	getDependantVariablesSelectItems,
	dependencySuppliersSelectItems,
	dependencies,
	areRulesExpanded
}: Props) {
	const { translate } = useTranslation();

	const {
		actions: { createDependency }
	} = useDependenciesContext();

	const variablesData = useVariablesData({ initial: true });

	const { variablesMap } = variablesData;

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, changePageSize } =
		usePaginate(dependencies, {
			threshold: 20,
			pageSize: 10
		});

	const noDependencies = dependencies.length === 0;

	return (
		<Container>
			{shouldPaginate && (
				<Flex marginOffset={{ bottom: 1.6 }} fullWidth>
					<Pagination
						totalCountLabel={translate(
							dict => dict.dependencies.builder.dependenciesLabel
						)}
						pageIndex={pageIndex}
						pageSize={pageSize}
						pagesCount={pagesCount}
						changePage={changePage}
						changePageSize={changePageSize}
						showCounts={false}
					/>
				</Flex>
			)}

			<Droppable droppableId={DragAndDropTypes.DroppableDependenciesVarList}>
				{provided => (
					<div
						ref={provided.innerRef}
						style={
							noDependencies
								? {
										width: '100%',
										border: '1px dashed lightgrey',
										borderRadius: 5,
										background: '#fafafa',
										padding: '4rem 4rem 2rem 4rem',
										marginBottom: '4rem'
								  }
								: { width: '100%' }
						}
					>
						{noDependencies && (
							<Typography.Paragraph multiline>
								{translate(dict => dict.variableDependencies.noDependenciesText)}
							</Typography.Paragraph>
						)}

						{page.map(dependency => {
							const supplierVariable = variablesMap[dependency.supplierVariableName];

							if (!supplierVariable) return null;

							return (
								<DependencyRule
									key={`dependency_${dependency.dependencyName}`}
									dependency={dependency}
									supplierVariable={supplierVariable}
									dependencySuppliersSelectItems={dependencySuppliersSelectItems}
									dependantVariablesSelectItems={getDependantVariablesSelectItems(
										dependency
									)}
									areRulesExpanded={areRulesExpanded}
								/>
							);
						})}

						{provided.placeholder}
					</div>
				)}
			</Droppable>

			<Flex marginOffset={{ top: 1 }}>
				<Button
					title={translate(dict => dict.variableList.addVariable)}
					onClick={createDependency}
				/>
			</Flex>
		</Container>
	);
}

import styled from 'styled-components';

import { Colors, MediaQueries, Shadows } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';
import { Tabs as TabsComponent } from 'components/UI/Tabs';
import { CSSGrid } from 'components/UI/CSSGrid';

export const CSSGridContainer = styled(CSSGrid.Container)<{ headerHeight: number }>`
	height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}rem)`};
	overflow: visible;

	> div {
		max-height: ${({ headerHeight }) => `calc(100vh - ${headerHeight}rem)`};
	}
`;

export const FormsContainer = styled.div<Disabled>`
	display: flex;
	padding: 2.4rem 2.4rem 0 2.4rem;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column;
	}

	${({ disabled }) => disableableComponent({ disabled })}
`;

export const SettingsContainer = styled(CSSGrid.Col)`
	position: relative;
	left: -3.9vw;
	width: calc(100% + 3.9vw);
	flex-direction: column;
	padding: 0 2.4rem;
	padding-bottom: 2rem;
	box-shadow: ${Shadows.normal};
	z-index: 1001;
	background-color: ${Colors.white};
	overflow: auto;
`;

export const FormElementsContainer = styled.div`
	margin-bottom: 2rem;
`;

export const BuilderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 100%;
	align-items: flex-start;
`;

export const BuilderContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: ${Colors.white};
`;

export const TitleWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
`;

export const ToggleActiveFormContainer = styled.div`
	display: flex;
	align-items: center;

	> :first-child {
		margin-right: 0.5rem;
	}
`;

export const Body = styled.div`
	width: 100%;
`;

export const Tabs = styled(TabsComponent)`
	> div:first-child {
		div {
			padding: 1.2rem 0;
			::after {
				left: 0.8rem;
				right: 0.8rem;
				width: auto;
			}
		}
	}

	> div:last-child {
		padding-top: 2.4rem;
	}
`;

export const MiddleColumn = styled(CSSGrid.Col)`
	margin: 0 -2.4rem;
	padding: 0 2.4rem;
	overflow: auto;
`;

import { useMemo } from 'react';

import { formatAPIDate } from 'helpers/dateFormat';
import { OperationResult } from 'hooks/store/types';
import { PaymentMethod, SubscriptionPayment } from 'store/account/payments';

import { useSubscriptionPayments } from './useSubscriptionPayments';
import { useSelectedPaymentMethod } from './useSelectedPaymentMethod';

interface LastSubscriptionPayment extends SubscriptionPayment {
	invoiceDueDate: Date | null;
	selectedPaymentMethod: PaymentMethod | null;
}

export function useLastSubscriptionPayment(): OperationResult<LastSubscriptionPayment | null> {
	const [{ data: payments, loading, error, fetched }, getSubscriptionPayments] =
		useSubscriptionPayments(true);
	const { data: selectedPaymentMethod } = useSelectedPaymentMethod();

	const lastPayment = useMemo(
		() => (payments && payments.length ? payments[0] : null),
		[payments]
	);

	const lastPaymentDate = useMemo(
		() => (lastPayment ? (formatAPIDate(lastPayment.paidAt) as Date) : null),
		[lastPayment]
	);

	const lastDueDate = useMemo(
		() =>
			lastPayment && lastPayment.dueDate
				? (formatAPIDate(lastPayment.dueDate) as Date)
				: null,
		[lastPayment]
	);

	const invoiceDueDate = useMemo(
		() =>
			lastDueDate && selectedPaymentMethod === PaymentMethod.INVOICE
				? lastDueDate
				: lastPaymentDate
				? new Date(lastPaymentDate.getTime() + 60 * 60 * 24 * 15 * 1000)
				: null,
		[lastPaymentDate, selectedPaymentMethod]
	);

	const data = useMemo(
		() => (lastPayment ? { ...lastPayment, invoiceDueDate, selectedPaymentMethod } : null),
		[lastPayment, invoiceDueDate]
	);

	function handler() {
		getSubscriptionPayments();
	}

	return [{ data, loading, error, fetched }, handler];
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { setFormTitle, SetFormTitleAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useSetFormTitle(): LocalOperation<
	(payload: ActionPayload<SetFormTitleAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormTitleAction>) {
		dispatch(setFormTitle(payload));
	}

	return handler;
}

import { createSelector } from 'reselect';
import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getTranslations(state: State) {
	return state.translations;
}

function getLanguage(state: State) {
	return state.language;
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectTranslations = createSelector([getTranslations], translations => translations);

export const selectLanguage = createSelector([getLanguage], language => language);

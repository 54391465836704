export function renderComponent(component?: React.ReactNode | null) {
	// NO COMPONENT
	if (component === undefined) return;

	// EMPTY COMPONENT - OVERWRITE DEFAULT (LEAVE EMPTY)
	if (component === null) return <></>;

	// RETURN COMPONENT
	return component;
}

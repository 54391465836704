import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { resetShareList, ResetShareListAction } from 'store/data/roles';
import { ActionPayload } from 'store/types';

export function useResetShareList(): LocalOperation<
	(payload: ActionPayload<ResetShareListAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ResetShareListAction>) {
		dispatch(resetShareList(payload));
	}

	return handler;
}

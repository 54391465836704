import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, createEntrySurvey } from 'store/data/entries';

type HandlerInput = Parameters<typeof createEntrySurvey>[0];

export function useCreateEntrySurvey(): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CREATE_ENTRY_SURVEY);

	function handler(input: HandlerInput) {
		dispatch(createEntrySurvey(input));
	}

	return [{ loading, error }, handler];
}

import { Patient } from 'api/data/patients';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateDistributionPatient } from 'store/data/patients';

export function useUpdatePatient(patientId: number): Operation<(patient: Patient) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_PATIENT_DISTRIBUTION, {
		patientId
	});

	function handler(patient: Patient) {
		dispatch(updateDistributionPatient(patient));
	}

	return [{ loading, error }, handler];
}

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';

import {
	ActionTypes,
	getAdminUser,
	selectAdminUsersUserId,
	selectAdminUserDetails
} from 'store/account/admin';
import { AdminAccount } from 'api/account/admin';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useAdminUserDetails(): OperationResult<AdminAccount | undefined> {
	const dispatch = useDispatch();

	const userId = useSelector(state => selectAdminUsersUserId(state.account.admin)) ?? '';
	const user = useSelector(state => selectAdminUserDetails(state.account.admin));

	// rest of the info could be fetched by getAdminUsersList
	const fetched = user ? user.userCredentials?.mfaEnabled !== undefined : false;

	const [{ loading, error }] = useActivity(ActionTypes.ADMIN_GET_USER);

	useEffect(() => {
		if (!fetched && !loading && !error && userId) {
			handler();
		}
	}, [loading, error, userId, userId]);

	function handler() {
		dispatch(getAdminUser(userId));
	}

	return [{ loading, error, data: user }, handler];
}

import type { ProjectFilterType } from 'types/data/projects/constants';
import type { VariableUniquenessType } from '../variables';
import type { VariableType } from 'types/data/variables/constants';

export enum Operator {
	Between = 'between',
	Equals = 'eq',
	GreaterThan = 'gt',
	LessThan = 'lt',
	In = 'in'
}

export type EntryFilter = {
	itemId: string;
	columnName: string;
	operator: Operator;
	filterType?: VariableType;
	filterSubType?: VariableUniquenessType;
	from?: number | string;
	to?: number | string;
	value?: number | string | null;
	values?: (string | null)[];
	invalid?: boolean;
};

export interface ApiEntrySearchFilter {
	filterType: 'search';
	value: string;
}

export type ApiEntryOwnerFilter = {
	filterType: 'owner';
	value: string;
};

export type ApiEntryVariableFilter = {
	columnName: string;
	operator: Operator;
	filterType?: VariableType;
	filterSubType?: VariableUniquenessType;
	from?: number | string;
	to?: number | string;
	value?: number | string | null;
	values?: (string | null)[];
};

export type ApiEntryFilter =
	| ApiEntryVariableFilter
	| ApiEntryStatusFilter
	| ApiEntryNoStatusFilter
	| ApiEntrySearchFilter
	| ApiEntryOwnerFilter;

export interface ApiEntryStatusFilter {
	columnName: string;
	filterType: 'statusSimple';
}

export interface ApiEntryNoStatusFilter {
	filterType: 'noStatus';
}

export type ApiEntryFilters = ApiEntryFilter[];

export interface ProjectFilter {
	itemId: string;
	columnName: string;
	operator: Operator;
	filterType: ProjectFilterType;
	from?: number | string;
	to?: number | string;
	value?: number | string;
	values?: string[];
	invalid?: boolean;
}

// DEPENDENCIES FILTER
export interface DependencyFilter {
	itemId: string;
	columnName: string;
	operator: Operator;
	filterType?: VariableType;
	from?: number | string;
	to?: number | string;
	value?: number | string | null;
	values?: (string | null)[];
	invalid?: boolean;
}

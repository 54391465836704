import { HeaderMain } from './HeaderMain';
import { HeaderNavigation } from './HeaderNavigation';
import { HeaderTitle } from './HeaderTitle';

export function Header() {
	return null;
}

Header.Main = HeaderMain;
Header.Navigation = HeaderNavigation;
Header.Title = HeaderTitle;

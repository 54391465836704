import { UserDetails } from 'api/data/accounts';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { UserCard } from 'components/UI/UserCard';
import { Svgs } from 'environment';
import { useToggle } from 'hooks/utils';

import { Container } from './UserCardDropdownItem.style';
import { DataTestId } from 'tests/consts';

interface Props {
	checked: boolean;
	userData: UserDetails;
	onCheck: () => void;
}

export function UserCardDropdownItem({ checked, userData, onCheck }: Props) {
	const [open, toggleOpen] = useToggle();

	const {
		userid,
		userFirstName,
		userSirName,
		emailAddress,
		phoneNumber,
		city,
		country,
		workplace,
		department
	} = userData;

	return (
		<Flex column>
			<Container>
				<Checkbox
					className="confirm-checkbox"
					checked={checked}
					onClick={onCheck}
					dataTestId={DataTestId.UserCardCheckbox}
				/>
				<Flex className="user-card">
					<UserCard.Basic
						userId={userid ?? null}
						userData={{
							userFirstName,
							userSirName,
							emailAddress
						}}
					/>
				</Flex>
				<Icon svg={open ? Svgs.ChevronUp : Svgs.ChevronDown} onClick={toggleOpen} />
			</Container>

			{open && (
				<Flex paddingOffset={{ y: 1.6 }} marginOffset={{ left: -1.2 }} column>
					<UserCard.Details
						userId={userid ?? null}
						userData={{
							emailAddress,
							phoneNumber,
							city,
							country,
							department,
							workplace
						}}
						showHeader={false}
					/>
				</Flex>
			)}
		</Flex>
	);
}

import { customEventFactory } from './events';

export enum LicenceLimitationErrorTypes {
	createProject = 'error.licence.createProject',
	copyProject = 'error.licence.copyProject',
	transferProject = 'error.licence.transferProject', // can be used for both copyProject and transferProject
	collaboratorShareProject = 'error.licence.shareProject.collaborator',
	createEntry = 'error.licence.createEntry',
	collaboratorCreateEntry = 'error.licence.createEntry.collaborator'
}

export const toggleFreeLicenceLimitationModalEvent = () =>
	customEventFactory<LicenceLimitationErrorTypes>('toggle-free-licence-limitation-modal');

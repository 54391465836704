import { createSelector } from 'reselect';

import { NotificationBodyType, State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getNotificationHistory(state: State) {
	return state.notificationHistory.byNotificationId;
}

function getNewestNotification(state: State, type?: NotificationBodyType) {
	if (state.newestNotifications) {
		return state.newestNotifications.find(n =>
			n.type === type ? state.newestNotifications : null
		);
	} else return null;
}

function getQueueNotifications(state: State) {
	return state.queueNotifications;
}

function getProjectLabels(state: State) {
	return state.projectsLabels;
}

function getProjectNotificationSettings(state: State, projectId: string) {
	return state.settings.byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectNotificationHistory = createSelector(
	[getNotificationHistory],
	notificationHistory => notificationHistory
);

export const selectNewestNotification = createSelector(
	[getNewestNotification],
	newestNotification => newestNotification
);

export const selectQueueNotifications = createSelector(
	[getQueueNotifications],
	queueNotifications => queueNotifications
);

export const selectProjectsLabels = createSelector(
	[getProjectLabels],
	projectsLabels => projectsLabels
);

export const selectProjectNotificationSettings = createSelector(
	[getProjectNotificationSettings],
	byProjectId => byProjectId
);

import { Svgs } from 'environment';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { useTranslation, useAreFiltersValid } from 'hooks/store';

interface Props {
	open: boolean;
	disabled?: boolean;
	onDelete: () => void;
	onExport: () => void;
}

export function AnalysisOptions({ open, disabled = false, onDelete, onExport }: Props) {
	const { translate } = useTranslation();
	const valid = useAreFiltersValid();

	return (
		<Dropdown
			toggleComponent={({ ref, open, toggle }) => (
				<Icon
					variant={v => v.button}
					ref={ref}
					svg={Svgs.More}
					disabled={disabled}
					active={open}
					onClick={toggle}
				/>
			)}
			width={15}
			offset={{ top: 20, right: 0 }}
		>
			{valid && (
				<Dropdown.Item disabled={!open} onClick={onExport}>
					{translate(dict => dict.buttons.export)}
				</Dropdown.Item>
			)}
			<Dropdown.Item onClick={onDelete}>
				{translate(dict => dict.buttons.delete)}
			</Dropdown.Item>
		</Dropdown>
	);
}

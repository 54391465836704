import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, makePaymentSourcePrimary } from 'store/account/payments';

export function useMakePaymentSourcePrimary(): Operation<(cardNumber: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MAKE_PAYMENT_SOURCE_PRIMARY);

	function handler(cardNumber: string) {
		dispatch(makePaymentSourcePrimary(cardNumber));
	}

	return [{ loading, error }, handler];
}

import produce from 'immer';
import { useTranslation } from 'hooks/store';
import {
	ModulesPermissionsFlags,
	ModulesPermissions as ModulesPermissionsType
} from 'store/data/collaborators';

import { Container } from './ModulesPermissions.style';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { InfoMessage } from 'components/UI/InfoMessage';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { withMemo } from 'helpers/HOCs';

interface Props {
	permissions: ModulesPermissionsType;
	disabled?: boolean;
	isProjectOwner?: boolean;
	onChange: (permissions: ModulesPermissionsType) => void;
}

function Component({ permissions, disabled, isProjectOwner, onChange }: Props) {
	const { translate } = useTranslation();
	function handleUpdate(flag: ModulesPermissionsFlags) {
		if (disabled) return;

		const updatedPermissions = produce(permissions, draft => {
			// GET NEW VALUE
			const updatedFlagValue = !draft[flag];

			// TOGGLE FLAG WITH NEW VALUE
			draft[flag] = updatedFlagValue;
		});

		onChange(updatedPermissions);
	}

	return (
		<Container disabled={disabled}>
			<Gap marginGap={{ bottom: 1.6 }} notLastChild>
				<Module
					label={translate(
						dict => dict.rolesPage.roleModal.modulesPermissions.projectLabel
					)}
					description={translate(
						dict => dict.rolesPage.roleModal.modulesPermissions.projectDescription
					)}
					on={permissions.projectDesign}
					onClick={() => handleUpdate(ModulesPermissionsFlags.projectDesign)}
				/>
				<Module
					label={translate(
						dict => dict.rolesPage.roleModal.modulesPermissions.collaboratorsLabel
					)}
					description={translate(
						dict => dict.rolesPage.roleModal.modulesPermissions.collaborators
					)}
					on={permissions.collaborators}
					disabled={isProjectOwner}
					onClick={() => handleUpdate(ModulesPermissionsFlags.collaborators)}
				/>
			</Gap>

			<Spacer size={s => s.l} />

			<InfoMessage message={translate(dict => dict.rolesPage.roleModal.permissionsMessage)} />
		</Container>
	);
}

export const ModulesPermissions = withMemo(Component);

function Module({
	label,
	description,
	on,
	disabled,
	onClick
}: {
	label: string;
	description: string;
	on: boolean;
	disabled?: boolean;
	onClick(): void;
}) {
	return (
		<Flex
			style={{
				cursor: disabled ? '' : 'pointer'
			}}
			onClick={disabled ? undefined : onClick}
		>
			<Flex flex={1} marginOffset={{ right: 2.4 }} column>
				<Typography.Paragraph title={label}>{label}</Typography.Paragraph>
				<Typography.Hint title={description}>{description}</Typography.Hint>
			</Flex>
			<Switch on={on} disabled={disabled} propagate />
		</Flex>
	);
}

import { useAccount, useSubscription, useTranslation, useUserAddons } from 'hooks/store';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { Button } from 'components/UI/Interactables/Button';
import { Tag } from 'components/UI/Tags';

import { Colors, Svgs } from 'environment';

import { CardSpacer, InfoCard } from './EnterpriseAdminSubscriptionPage.style';
// import { SubscriptionAddonCode } from 'types/index';
import { UserTag } from 'components/UI/UserCard/UserTag';
import { LanguageType } from 'types/index';

export function EnterpriseAdminSubscriptionPage() {
	const { translate } = useTranslation();

	const [
		{
			data: { details },
			loading: subscriptionDetailsLoading
		}
	] = useSubscription();

	const [
		{
			// data: { userAddons }
		}
	] = useUserAddons();

	const [
		{
			data: { details: accountDetails }
		}
	] = useAccount();

	const chosenLanguageCode = accountDetails?.userPreferences.chosenLanguageCode;
	const isNorwegianSelected =
		chosenLanguageCode === LanguageType.Norwegian ||
		chosenLanguageCode === LanguageType.Norwegian_Deprecated;

	//later will the addon will be showed based on flag
	// SubscriptionAddonCode.JADBio;
	const showAddon = false;

	//Opens a new mail towards Ledidi support team
	function newMailToSupport() {
		window.open('mailto:support@ledidi.no?');
	}

	return (
		<>
			<Header.Main />
			<Header.Navigation rightComponent={<Flex></Flex>} />

			<Suspend loading={subscriptionDetailsLoading} immediate={false}>
				<Header.Title
					title={translate(
						({ enterpriseAdmin }) => enterpriseAdmin.header.menuTabs.subscription
					)}
				/>
				<Grid.Container>
					<Flex justify={j => j.between} wrap>
						{/* Subscription name card  */}
						<InfoCard>
							<Flex justify={j => j.between}>
								<Flex align={a => a.center}>
									<Icon
										svg={Svgs.Building}
										variant={v => v.button}
										style={{ background: Colors.background.disabled }}
									/>
									<Typography.H5 breakWord marginOffset={{ x: 1.6 }}>
										{translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.subscription.title
										)}
									</Typography.H5>
									<Tag
										title={translate(({ formDesigner }) => formDesigner.active)}
										active
									/>
								</Flex>
							</Flex>
							{showAddon && (
								<CardSpacer>
									<UserTag
										title={translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.rolesModules.jadBioIncluded
										)}
									/>
								</CardSpacer>
							)}
						</InfoCard>
						{/* Licence Info & detials card*/}
						<InfoCard>
							<Flex align={a => a.center}>
								<Icon
									svg={Svgs.User}
									variant={v => v.button}
									style={{ background: Colors.background.disabled }}
								/>
								<Typography.H5 marginOffset={{ left: 1.6 }}>
									{translate(
										({ enterpriseAdmin }) =>
											enterpriseAdmin.rolesModules.licenceInfo
									)}
								</Typography.H5>
							</Flex>
							<Typography.Paragraph marginOffset={{ left: 5.6 }}>
								{!isNorwegianSelected
									? `${details?.availableLicenceQuantity}
								${translate(({ enterpriseAdmin }) => enterpriseAdmin.rolesModules.licencesRemaining)}
								`
									: translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.rolesModules.licencesRemaining,
											false,
											{
												licencesRemaining:
													details?.availableLicenceQuantity ?? ''
											}
									  )}
							</Typography.Paragraph>
							<Typography.Paragraph
								marginOffset={{ left: 5.6 }}
								color={Colors.text.disabled}
							>
								{!isNorwegianSelected
									? `${details?.licenceQuantity}
								${translate(({ enterpriseAdmin }) => enterpriseAdmin.rolesModules.licencesInPlan)}`
									: translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.rolesModules.licencesInPlan,
											false,
											{ licencesInTotal: details?.licenceQuantity ?? '' }
									  )}
							</Typography.Paragraph>
						</InfoCard>
						{/* Contact support card */}
						<InfoCard>
							<Flex column justify={j => j.between} style={{ height: '100%' }}>
								<div>
									<Flex align={a => a.center}>
										<Icon
											svg={Svgs.Phone}
											variant={v => v.button}
											style={{ background: Colors.background.disabled }}
										/>
										<Typography.H5 marginOffset={{ left: 1.6 }}>
											{translate(
												({ enterpriseAdmin }) =>
													enterpriseAdmin.rolesModules.contactSupport
											)}
										</Typography.H5>
									</Flex>
									<Typography.Caption marginOffset={{ left: 5.6 }}>
										{translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.rolesModules.contactSupportAlt
										)}
									</Typography.Caption>
								</div>
								<div>
									<Button
										onClick={newMailToSupport}
										title={translate(
											({ enterpriseAdmin }) =>
												enterpriseAdmin.rolesModules.contactSupport
										)}
										variant={v => v.link}
									/>
								</div>
							</Flex>
						</InfoCard>
					</Flex>
					<Spacer size={s => s.xxl} />
				</Grid.Container>
			</Suspend>
		</>
	);
}

import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const LastPaymentContainer = styled.div`
	background-color: ${Colors.background.disabled};
	padding: 0rem 2.4rem;
	min-height: 11rem;
`;

export const BillingAddressContainer = styled.div`
	grid-column: 1 / span 2;

	@media ${MediaQueries.maxWidth.lg} {
		grid-column: 1 / span 1;
	}
	@media ${MediaQueries.maxWidth.sm} {
		grid-column: 1 / span 1;
	}
`;

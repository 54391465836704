import { useMemo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { BillingAddress, PaymentAddress } from 'store/account/payments';
import { InputType } from 'types/index';

import { Row, RowElement, HSpacer } from './BillingAddressForm.style';
import { Spacer } from 'components/UI/Spacer';
import { Input } from 'components/UI/Inputs/Input';
import { useTranslation } from 'hooks/store';

interface Props {
	primaryPaymentSourceAddress: PaymentAddress | null;
	billingAddress: BillingAddress;
	usePaymentInfo: boolean;
	useCompanyInfo: boolean;
	toggleUseCompanyInfo: () => void;
	toggleUsePaymentInfo?: () => void;
}

export function BillingAddressForm({
	primaryPaymentSourceAddress,
	billingAddress,
	usePaymentInfo,
	useCompanyInfo,
	toggleUsePaymentInfo,
	toggleUseCompanyInfo
}: Props) {
	const { translate } = useTranslation();

	const {
		register,
		setValue,
		setError,
		control,
		formState: { errors }
	} = useFormContext<BillingAddress>();

	function handleValue(name: keyof BillingAddress, value: string) {
		if (value) {
			setValue(name, value, {
				shouldDirty: true,
				shouldValidate: true
			});
		} else {
			setValue(name, value, {
				shouldDirty: !useCompanyInfo && name === 'company',
				shouldValidate: !useCompanyInfo && name === 'company'
			});
			setError(name, { types: {}, message: '' });
		}
	}

	const isPaymentSourceAddressEmpty = useMemo(() => {
		if (primaryPaymentSourceAddress) {
			const { firstName, lastName, city, country, line1, line2, state, zip } =
				primaryPaymentSourceAddress;

			return (
				!firstName && !lastName && !city && !country && !line1 && !line2 && !state && !zip
			);
		} else return true;
	}, [primaryPaymentSourceAddress]);

	function onUseCompanyInfoClicked() {
		toggleUseCompanyInfo();
	}

	function onUsePaymentInfoClicked() {
		toggleUsePaymentInfo && toggleUsePaymentInfo();
	}

	useEffect(() => {
		if (!useCompanyInfo) {
			// reset values to be empty

			handleValue('company', '');
			handleValue('vatNumber', '');
		} else {
			handleValue('company', billingAddress.company);
			handleValue('vatNumber', billingAddress.vatNumber);
		}
	}, [useCompanyInfo, billingAddress]);

	useEffect(() => {
		if (usePaymentInfo && primaryPaymentSourceAddress) {
			// reset values to the primary payment address
			handleValue('firstName', primaryPaymentSourceAddress.firstName);
			handleValue('lastName', primaryPaymentSourceAddress.lastName);
			handleValue('country', primaryPaymentSourceAddress.country);
			handleValue('city', primaryPaymentSourceAddress.city);
			handleValue('line1', primaryPaymentSourceAddress.line1);
			handleValue('line2', primaryPaymentSourceAddress.line2);
			handleValue('state', primaryPaymentSourceAddress.state);
			handleValue('zip', primaryPaymentSourceAddress.zip);
		} else {
			// set values to primary payment source address
			handleValue('firstName', billingAddress.firstName);
			handleValue('lastName', billingAddress.lastName);
			handleValue('country', billingAddress.country);
			handleValue('city', billingAddress.city);
			handleValue('line1', billingAddress.line1);
			handleValue('line2', billingAddress.line2);
			handleValue('state', billingAddress.state);
			handleValue('zip', billingAddress.zip);
		}
	}, [usePaymentInfo, primaryPaymentSourceAddress, billingAddress]);

	return (
		<>
			<Spacer size={s => s.s} />
			{!isPaymentSourceAddressEmpty && (
				<Checkbox
					label={translate(({ accountUM }) => accountUM.billing.sameAsPaymentInfo)}
					onClick={() => {
						onUsePaymentInfoClicked();
					}}
					checked={usePaymentInfo}
				/>
			)}
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('firstName')}
					type={InputType.Text}
					error={errors.firstName?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.firstName)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
					disabled={usePaymentInfo}
				/>
				<HSpacer />
				<Input
					{...register('lastName')}
					type={InputType.Text}
					error={errors.lastName?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.lastName)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
					disabled={usePaymentInfo}
				/>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('line1')}
					type={InputType.Text}
					error={errors.line1?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.addressLine1)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					required
					disabled={usePaymentInfo}
				/>
				<HSpacer />
				<Input
					{...register('line2')}
					type={InputType.Text}
					error={errors.line2?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.addressLine2)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					disabled={usePaymentInfo}
				/>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<RowElement>
					<Controller
						control={control}
						name="country"
						render={({ field: { value } }) => (
							<CountrySelector
								value={value}
								label={translate(
									dict => dict.accountUM.userDetails.countryOfResidence
								)}
								placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
								onSelect={country =>
									setValue('country', country?.ISO_2 ?? '', {
										shouldDirty: true,
										shouldValidate: true
									})
								}
								disabled={usePaymentInfo}
								required
							/>
						)}
					/>
				</RowElement>
				<HSpacer />
				<RowElement>
					<Input
						{...register('city')}
						type={InputType.Text}
						error={errors.city?.message ?? ''}
						label={translate(dict => dict.accountUM.userDetails.city)}
						placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
						disabled={usePaymentInfo}
						required
					/>
				</RowElement>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('state')}
					type={InputType.Text}
					error={errors.state?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.state)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					disabled={usePaymentInfo}
				/>
				<HSpacer />
				<Input
					{...register('zip')}
					type={InputType.Text}
					error={errors.zip?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.zip)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					disabled={usePaymentInfo}
				/>
			</Row>
			<Spacer size={s => s.m} />
			<Checkbox
				label={translate(({ accountUM }) => accountUM.billing.useCompanyDetails)}
				onClick={() => {
					onUseCompanyInfoClicked();
				}}
				checked={useCompanyInfo}
			/>

			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('company')}
					type={InputType.Text}
					error={errors.company?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.company)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					disabled={!useCompanyInfo}
					required
				/>
				<HSpacer />
				<Input
					{...register('vatNumber')}
					type={InputType.Text}
					error={errors.vatNumber?.message ?? ''}
					label={translate(dict => dict.accountUM.userDetails.vatNumber)}
					placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
					disabled={!useCompanyInfo}
					required
				/>
			</Row>
		</>
	);
}

import { DownloadFormat } from 'api/data/entries/types';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useVisibleVariableReferences } from 'hooks/store';
import { useDownloadDataset } from 'hooks/store/data/entries/useDownloadDataset';
import { useCompletedAction } from 'hooks/utils';
import { ExportDatasetType } from '../../../../api/data/entries/types';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { useState } from 'react';
import { Flex } from 'components/UI/Flex';

interface Props {
	onClose: () => void;
	format: DownloadFormat;
}

export function DownloadDatasetModal({ onClose, format }: Props) {
	const { translate } = useTranslation();

	const [exportCategoryLabels, setExportCategoryLabels] = useState(false);

	const [removeLineShifts, setRemoveLineShifts] = useState(true);

	const [
		{ loading: gettingDownloadableDataset, error: errorGettingDownloadableDataset },
		downloadDataset
	] = useDownloadDataset();

	useCompletedAction(gettingDownloadableDataset, errorGettingDownloadableDataset, onClose);

	const selectedColumnsForExport = useVisibleVariableReferences();

	function handleSubmit() {
		downloadDataset({
			removeLineShifts,
			exportFormat: format,
			datasetType: ExportDatasetType.Main,
			datasets: [
				{
					datasetName: null
				}
			],
			categoryLabels: exportCategoryLabels,
			...(selectedColumnsForExport?.length ? { variables: selectedColumnsForExport } : {})
		});
	}

	return (
		<Modal
			size={s => s.s}
			title={
				format === DownloadFormat.CSV
					? translate(dict => dict.entriesOptions.downloadAsCSV)
					: translate(dict => dict.entriesOptions.downloadAsExcel)
			}
			primary={{
				label: translate(dict => dict.buttons.export),
				loading: gettingDownloadableDataset,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph fontweight={fw => fw.bold}>
				{translate(dict => dict.dataset.modals.exportEntriesAsCSVModal.message)}
			</Typography.Paragraph>
			<Spacer size={s => s.xs} />

			<Flex align={a => a.center} justify={j => j.start}>
				<RadioButton
					inline
					label={translate(dict => dict.terms.yes)}
					onSelect={() => setRemoveLineShifts(true)}
					selected={removeLineShifts === true}
					marginOffset={{ right: 1.6 }}
				/>
				<RadioButton
					inline
					label={translate(dict => dict.terms.no)}
					onSelect={() => setRemoveLineShifts(false)}
					selected={removeLineShifts === false}
				/>
			</Flex>

			<Spacer size={size => size.l} />

			<Typography.Paragraph fontweight={fw => fw.bold}>
				{translate(dict => dict.dataset.modals.exportEntriesAsCSVModal.categoriesLabel)}
			</Typography.Paragraph>
			<Spacer size={s => s.xs} />
			<RadioButton
				label={translate(dict => dict.terms.label)}
				selected={exportCategoryLabels}
				onSelect={() => setExportCategoryLabels(true)}
				description={translate(
					dict => dict.dataset.modals.exportEntriesAsCSVModal.labelsHint
				)}
			/>

			<Spacer size={size => size.s} />

			<RadioButton
				label={translate(dict => dict.terms.valueUpperCase)}
				selected={!exportCategoryLabels}
				onSelect={() => setExportCategoryLabels(false)}
				description={translate(
					dict => dict.dataset.modals.exportEntriesAsCSVModal.valuesHint
				)}
			/>
		</Modal>
	);
}

import { DATA_URL, sendRequest } from 'api/utils';
import { parseApiEntryStatus, parseApiEntryValue } from 'helpers/entries';
import { EntryStatus } from 'store/data/entries';
import { Revision, StatusChanges } from 'store/data/revisions';

import {
	GetRevisionsInput,
	GetRevisionsRequest,
	GetRevisionsResponse,
	GetRevisionsOutput
} from './types';

const methods = {
	getRevisions: 'getDataEntryHistory'
};

export default () => ({
	async getRevisions(input: GetRevisionsInput): Promise<GetRevisionsOutput> {
		const { data } = await sendRequest<GetRevisionsRequest, GetRevisionsResponse>(DATA_URL, {
			method: methods.getRevisions,
			...input
		});

		if (data.httpStatusCode !== 200) {
			// throw new Error(Dictionary.errors.api.entries.couldNotGetDataEntryHistory);

			/* 
				THIS IS TO BE DELETED WHEN NEW API ENDPOINT FOR CHECKING IF ENTRY EXISTS IS IMPLEMENTED
			*/

			// THROW BLANK ERROR TO FIRE CUSTOM ONE
			throw new Error();
		}

		const output: GetRevisionsOutput = {
			revisions: []
		};

		data.revisionsWithChanges.forEach((apiRevision, index) => {
			const { revisionId, userName, creationDate, changes } = apiRevision;

			const apiStatuses = data.statusChanges[index];

			const statusChanges: StatusChanges = {
				from: null,
				to: null,
				data: null
			};

			if (apiStatuses.length) {
				// FILTER OUT EMPTY OBJECTS - API RESPONDS WITH `{}` IF NO STATUS
				const filteredStatuses = apiStatuses.filter(s => Object.keys(s).length);

				let parsedFrom: EntryStatus = null;
				let parsedTo: EntryStatus = null;

				filteredStatuses.forEach(statusChange => {
					const { from, to, variableName } = statusChange;

					if (from && from.value) {
						parsedFrom = parseApiEntryStatus(from, variableName);
					}

					if (to && to.value) {
						parsedTo = parseApiEntryStatus(to, variableName);
					}
				});

				statusChanges.from = parsedFrom;
				statusChanges.to = parsedTo;
			}

			const revision: Revision = {
				revisionId,
				userName,
				creationDate,
				changes: {
					variableNames: [],
					list: [],
					data: null
				},
				statusChanges
			};

			changes.forEach(change => {
				const { variableName, from, to } = change;

				revision.changes.variableNames.push(variableName);
				revision.changes.list.push({
					variableName,
					from: parseApiEntryValue(from),
					to: parseApiEntryValue(to)
				});
			});

			output.revisions.push(revision);
		});

		return output;
	}
});

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, inviteUser } from 'store/data/accounts';

export function useInviteUser(): Operation<(emailAddress: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.INREACT_APP_USER);

	function handler(emailAddress: string) {
		dispatch(inviteUser(emailAddress));
	}

	return [{ loading, error }, handler];
}

import styled from 'styled-components';

export const Wrapper = styled.div`
	height: 100vh;
	overflow-y: auto;
`;

export const Container = styled.div`
	margin: auto;
	max-width: 80rem;
	padding: 0 2rem;
`;

export const Header = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin: 4rem 0;
`;

export const HeaderGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BackArrowContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-right: 2rem;
	margin-left: -2rem;
`;

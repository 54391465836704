import { createContext, useContext } from 'react';

export interface SetAlertProps {
	message: string;
	timeout?: number;
}

interface IAlertsContext {
	setNotification: (props: SetAlertProps) => void;
	setError: (props: SetAlertProps) => void;
}

export const AlertsContext = createContext<IAlertsContext>({
	setNotification: () => null,
	setError: () => null
});

export function useAlerts() {
	return useContext(AlertsContext);
}

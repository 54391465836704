import styled from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Warning = styled(Typography.H6)`
	text-align: center;
	font-weight: bold;
	color: ${Colors.text.error};
`;

import { AnalysisList, Filters, Snapshots } from 'components/Analysis';
import { MediaQueries } from 'environment';
import { AnalysisPageHeader } from './AnalysisPageHeader';
import { Suspend } from 'components/UI/Suspend';
import {
	usePermissions,
	useStatuses,
	useSnapshots,
	useProject,
	useEntries,
	useVariables,
	useFilters
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { CSSGrid } from 'components/UI/CSSGrid';
import { AnalysisColumn } from './AnalysisPage.style';

export function AnalysisPage() {
	const { loading: loadingPermissions, fetched: arePermissionsFetched } = usePermissions();

	const [{ areFiltersOpen }] = useFilters();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);
	useStatuses();
	useSnapshots();

	const [{ loading: loadingProject }] = useProject();
	const [{ loading: loadingEntries }] = useEntries();
	const [{ loading: loadingVariables, fetched: areVariablesFetched }] = useVariables({
		initial: true
	});

	const loading = loadingPermissions || loadingProject || loadingVariables || loadingEntries;
	const immediate = !arePermissionsFetched || !areVariablesFetched;

	const filtersColSize = areFiltersOpen ? { xl: 4, l: 3, m: 6 } : { xl: 0, l: 0, m: 0 };

	const analysesColSize = areFiltersOpen ? { xl: 12, l: 9, m: 0 } : { xl: 16, l: 12, m: 6 };

	return (
		<>
			<AnalysisPageHeader />
			<Suspend loading={loading} immediate={immediate}>
				{isMobileDevice && <Snapshots />}

				<CSSGrid.Container>
					{areFiltersOpen && (
						<CSSGrid.Col sizes={filtersColSize} displayGridColumn0>
							<Filters />
						</CSSGrid.Col>
					)}

					<AnalysisColumn sizes={analysesColSize}>
						<AnalysisList />
					</AnalysisColumn>
				</CSSGrid.Container>
			</Suspend>
		</>
	);
}

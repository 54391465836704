import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from 'components/UI/Icons';
import { HelpTopicCategory } from 'store/data/help';
import { Svgs } from 'environment';
import { Container, Header, Body, Link } from './TOCItem.style';
import { Typography } from 'components/UI/Typography';
import { useHelpSearchTerm } from 'hooks/store';
import { useMemoOnce } from 'hooks/utils';

interface Props {
	item: HelpTopicCategory;
	isActive?: boolean;
}

export function TOCItem({ item, isActive }: Props) {
	const { categoryName, categoryTopics } = item;

	const { topicUrl = '' } = useParams<{ topicUrl: string }>();

	const [helpPageSearchTerm] = useHelpSearchTerm();

	const categoryUrls: string[] = useMemoOnce(() => categoryTopics.map(({ url }) => url));

	const initiallyOpen = useMemoOnce(() => isInitiallyOpen());

	const [open, setOpen] = useState(initiallyOpen);

	// BETTER UX -> OPEN TOPIC LIST ON SEARCH AND ON URL CHANGE (IF VALID)
	useEffect(() => {
		if (isInitiallyOpen()) setOpen(true);
	}, [topicUrl, helpPageSearchTerm]);

	function isInitiallyOpen() {
		const isSearchTermValid =
			helpPageSearchTerm
				.trim()
				.split(' ')
				.filter(term => !!term.trim().toLowerCase()).length > 0;

		return isSearchTermValid || isActive || categoryUrls.includes(topicUrl);
	}

	return (
		<Container>
			<Header onClick={() => setOpen(state => !state)}>
				<Typography.H4 marginOffset={{ y: 0.8 }}>{categoryName}</Typography.H4>
				<Icon
					svg={Svgs.ChevronDown}
					rotate={open ? 180 : 0}
					marginOffset={{ top: 1.4 }}
					propagate
				/>
			</Header>

			{open && (
				<Body>
					{categoryTopics.map(({ url, topicName }, index) => (
						<Link
							key={`topic-${index}`}
							to={`/help/${url}`}

							/**
							 * if accessing the help page route `/help/` without the url param
							 * it defaults to the first topic (if exists) therefore `NavLink` doesn't match the url param
							 * so it doesn't make the link active
							 *
							 * FIX: if no url param is set => enable the 1st topic
							 */
							// FIXME:
							// isActive={isActive && index === 0 ? () => true : undefined}
						>
							{topicName}
						</Link>
					))}
				</Body>
			)}
		</Container>
	);
}

import { useMemo, useRef, useState } from 'react';
import { Variable } from 'api/data/variables';
import { InputType, SelectItem } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';
import { Modal } from 'components/UI/Modal';
import { useTranslation, useMoveVariableCategoryValues } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

enum MOVE_OPTIONS {
	start = 'beginning',
	end = 'end',
	before = 'before',
	after = 'after',
	index = 'index'
}

interface Props {
	variable: Variable;
	categoryValueIds: string[];
	onClose: (success?: boolean) => void;
}

export function MoveCategoryValuesModal({ variable, categoryValueIds, onClose }: Props) {
	const { translate } = useTranslation();

	const [moveOption, setMoveOption] = useState(MOVE_OPTIONS.start);
	const [beforeAfterInputValue, setBeforeAfterInputValue] = useState('');
	const [indexInputValue, setIndexInputValue] = useState('');

	const beforeAfterInputRef = useRef<HTMLInputElement>(null);
	const indexInputRef = useRef<HTMLInputElement>(null);

	const [
		{ loading: movingCategoryValues, error: errorMovingCategoryValues },
		moveCategoryValues
	] = useMoveVariableCategoryValues();

	useCompletedAction(movingCategoryValues, errorMovingCategoryValues, () => onClose(true));

	function handleSubmit() {
		if (!(isFormValid() && !movingCategoryValues)) return;

		const destinationByMoveOption = {
			[MOVE_OPTIONS.start]: {
				index: 0
			},
			[MOVE_OPTIONS.end]: {
				index: -1
			},
			[MOVE_OPTIONS.before]: {
				before: {
					id: beforeAfterInputValue
				}
			},
			[MOVE_OPTIONS.after]: {
				after: {
					id: beforeAfterInputValue
				}
			},
			[MOVE_OPTIONS.index]: {
				index: Number(indexInputValue) - 1
			}
		};

		moveCategoryValues({
			variableName: variable.name,
			categoryValueIds,
			destination: destinationByMoveOption[moveOption]
		});
	}

	function isFormValid() {
		let valid = true;

		if (
			[MOVE_OPTIONS.before, MOVE_OPTIONS.after].includes(moveOption) &&
			!beforeAfterInputValue
		) {
			valid = false;
		}
		if ([MOVE_OPTIONS.index].includes(moveOption) && !indexInputValue) {
			valid = false;
		}

		return valid;
	}

	/**
	 * Sets value only within min-max range
	 */
	function setIndexInputValueSafe(value: string) {
		// allow numbers only
		value = value.replace(/[^1-9]+/g, '');

		const numberValue = Number(value);

		if (numberValue > variable.categories.length + 1) return;

		setIndexInputValue(value);
	}

	function focusNextRequiredField(moveOption: MOVE_OPTIONS) {
		const inputRefByMoveOption = {
			[MOVE_OPTIONS.start]: null,
			[MOVE_OPTIONS.end]: null,
			[MOVE_OPTIONS.before]: beforeAfterInputRef,
			[MOVE_OPTIONS.after]: beforeAfterInputRef,
			[MOVE_OPTIONS.index]: indexInputRef
		};

		setTimeout(() => inputRefByMoveOption[moveOption]?.current?.focus());
	}

	const beforeValuesSelectItems: SelectItem[] = useMemo(() => {
		const items: SelectItem[] = [];

		variable.categories.forEach(c => {
			if (categoryValueIds.includes(c.id)) return;

			const item: SelectItem = {
				label: c.label || c.value,
				value: c.id
			};

			items.push(item);
		});

		return items;
	}, [variable.categories, categoryValueIds]);

	const moveOptionsSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.beginning),
			value: MOVE_OPTIONS.start
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.end),
			value: MOVE_OPTIONS.end
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.before),
			value: MOVE_OPTIONS.before
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.after),
			value: MOVE_OPTIONS.after
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.atPosition),
			value: MOVE_OPTIONS.index
		}
	];

	const title = `${translate(dict => dict.terms.move)} ${categoryValueIds.length} ${
		categoryValueIds.length === 1
			? translate(dict => dict.terms.value)
			: translate(dict => dict.terms.values)
	}`;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(({ buttons }) => buttons.confirm),
				loading: movingCategoryValues,
				disabled: !isFormValid(),
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Gap marginGap={{ bottom: 1.6 }} notLastChild>
				<CreatableSelect
					label={translate(dict => dict.variables.move.option)}
					value={moveOptionsSelectItems.find(
						selectItem => selectItem.value === moveOption
					)}
					items={moveOptionsSelectItems}
					onValueSelected={value => {
						if (!value) return;

						const newMoveOption = value as MOVE_OPTIONS;

						setMoveOption(newMoveOption);
						focusNextRequiredField(newMoveOption);
					}}
					canClear={false}
				/>

				{[MOVE_OPTIONS.before, MOVE_OPTIONS.after].includes(moveOption) && (
					<CreatableSelect
						_ref={beforeAfterInputRef}
						label={translate(dict => dict.variablesPage.categoryValue)}
						placeholder={translate(dict => dict.variablesPage.selectCategory)}
						value={beforeValuesSelectItems.find(
							selectItem => selectItem.value === beforeAfterInputValue
						)}
						items={beforeValuesSelectItems}
						onValueSelected={categoryId => {
							if (!categoryId) return;

							setBeforeAfterInputValue(categoryId);
						}}
						canClear={false}
					/>
				)}

				{[MOVE_OPTIONS.index].includes(moveOption) && (
					<Input
						ref={indexInputRef}
						type={InputType.Number}
						label={translate(dict => dict.terms.position)}
						placeholder={translate(dict => dict.terms.position)}
						hint={`${translate(
							dict => dict.variablesPage.moveVariablesModal.positionInList
						)} ${variable.categories.length + 1}`}
						value={indexInputValue}
						minValue={1}
						maxValue={variable.categories.length + 1}
						disabled={moveOption !== MOVE_OPTIONS.index}
						onChange={e => setIndexInputValueSafe(e.target.value)}
					/>
				)}
			</Gap>
		</Modal>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { selectOrganizationId, setOrganizationId } from 'store/account/admin';

export function useAdminEnterpriseOrganizationId(): LocalOperationResult<
	string | undefined,
	(organizationId: string) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectOrganizationId(state.account.admin));

	function handler(organizationId: string) {
		dispatch(setOrganizationId({ organizationId }));
	}

	return [data, handler];
}

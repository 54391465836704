import { SetMutableState } from 'types/index';
import {
	TransferEntriesOwnershipActions,
	SelectedEntry,
	TransferOwnershipState
} from 'store/data/entries';

interface Props {
	transferOwnership: TransferOwnershipState;
	setTransferOwnership: SetMutableState<TransferOwnershipState>;
}

export function getTransferOwnershipActions({ transferOwnership, setTransferOwnership }: Props) {
	function getIsTransferOwnership(): boolean {
		return transferOwnership.isTransferOwnership;
	}

	function setIsTransferOwnership(value: boolean) {
		setTransferOwnership(state => {
			state.isTransferOwnership = value;
		});
	}

	function getSelectedEntries(): SelectedEntry[] {
		return transferOwnership.selectedEntries;
	}

	function setSelectedEntries(selectedEntries: SelectedEntry[]) {
		setTransferOwnership(state => {
			state.selectedEntries = selectedEntries;
		});
	}

	function addSelectedEntry(entryId: string) {
		setTransferOwnership(state => {
			state.selectedEntries.push({ entryId: entryId, confirmed: true });
		});
	}

	function removeSelectedEntry(entryId: string) {
		setTransferOwnership(state => {
			state.selectedEntries = transferOwnership.selectedEntries.filter(
				item => item.entryId !== entryId
			);
		});
	}

	function removeAllSelectedEntries() {
		setTransferOwnership(state => {
			state.selectedEntries = [];
		});
	}

	function toggleSelectedEntryConfirmed(entryId: string) {
		setTransferOwnership(state => {
			state.selectedEntries = state.selectedEntries.map(selectedEntry =>
				selectedEntry.entryId === entryId
					? {
							entryId: selectedEntry.entryId,
							confirmed: !selectedEntry.confirmed
					  }
					: selectedEntry
			);
		});
	}

	function toggleAllSelectedEntriesConfirmed(value: boolean) {
		setTransferOwnership(state => {
			state.selectedEntries = state.selectedEntries.map(selectedEntry => ({
				entryId: selectedEntry.entryId,
				confirmed: value
			}));
		});
	}

	function getCancelModal(): boolean {
		return transferOwnership.showCancelModal;
	}

	function setCancelModal(value: boolean) {
		setTransferOwnership(state => {
			state.showCancelModal = value;
		});
	}

	function getPreviewModal(): boolean {
		return transferOwnership.showPreviewModal;
	}

	function setPreviewModal(value: boolean) {
		setTransferOwnership(state => {
			state.showPreviewModal = value;
		});
	}

	function closePreviewModal() {
		setTransferOwnership(state => {
			state.selectedEntries = state.selectedEntries.map(selectedEntry => ({
				entryId: selectedEntry.entryId,
				confirmed: true
			}));
			state.showPreviewModal = false;
		});
	}

	function getTransferModal(): boolean {
		return transferOwnership.showTransferModal;
	}

	function setTransferModal(value: boolean) {
		setTransferOwnership(state => {
			state.showTransferModal = value;
		});
	}

	const transferOwnershipActions: TransferEntriesOwnershipActions = {
		getIsTransferOwnership,
		setIsTransferOwnership,
		getSelectedEntries,
		setSelectedEntries,
		toggleAllSelectedEntriesConfirmed,
		addSelectedEntry,
		removeSelectedEntry,
		removeAllSelectedEntries,
		toggleSelectedEntryConfirmed,
		getCancelModal,
		setCancelModal,
		getPreviewModal,
		setPreviewModal,
		closePreviewModal,
		getTransferModal,
		setTransferModal
	};

	return { transferOwnershipActions };
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { showOnlyUnreadNotificationsAction } from 'store/data/notifications';

export function useShowOnlyUnreadNotifications(): LocalOperationResult<
	boolean,
	(showOnlyUnread: boolean) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.notifications.showOnlyUnread);

	function handler(showOnlyUnread: boolean) {
		dispatch(showOnlyUnreadNotificationsAction({ showOnlyUnread }));
	}

	return [data, handler];
}

import { State, DateFilterOperator } from './types';

const initialState: State = {
	users: {
		byId: {},
		filters: {
			organizations: {
				all: [],
				active: []
			},
			status: {
				all: [],
				active: []
			},
			licence: {
				all: [],
				active: []
			},
			createdDate: {
				valid: false,
				operator: DateFilterOperator.Before,
				value: null,
				from: null,
				to: null
			}
		},
		metadata: {
			userId: null,
			pageIndex: 0,
			pageSize: 25,
			searchTerm: '',
			fetched: false
		}
	},
	enterpriseOrganizations: null
};

export default initialState;

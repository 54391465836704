import { withMemo } from 'helpers/HOCs';
import { RequiredFieldsLabel, CookiesNotice } from 'components/Dataset/AddEditForm';

import {
	EntryStatus,
	VariableFilteringMap,
	FormFieldPageLocationByName,
	SetGroupPaginationDataInput,
	FormGroupPaginationDataByname
} from 'store/data/entries';
import { Form } from 'store/data/forms';
import { Revision } from 'store/data/revisions';
import { VariablesData } from 'store/data/variables';
import { BooleanMap, SetState, SetMutableState, GetMutableState } from 'types/index';

import { Wrapper, Container, FormTitle } from './AddEditInputsAndGroupsFormDesigner.style';

import { AddEditStatusInputs } from '../AddEditStatusInputs';
import { AddEditInputsFormDesigner } from './AddEditInputsFormDesigner';

interface Props {
	form: Form;
	variablesData: VariablesData;
	groupsExpanded?: BooleanMap;
	openCustomsMap?: BooleanMap;
	variableVisibilityMap?: BooleanMap;
	variableFilteringMap?: VariableFilteringMap;
	revision?: Revision;
	currentChange?: number;
	readOnly?: boolean;
	tooltipContainer?: HTMLDivElement;
	isRevisionSelected?: boolean;
	uniqueFieldsError?: string[];
	usedAsThumbnail?: boolean;
	selectedStatus?: EntryStatus;
	isHistoryPagination?: boolean;
	setSelectedStatus?: SetState<EntryStatus>;
	setGroupExpanded?: (groupName: string, value?: boolean) => void;
	setFormFieldPageLocationByName?: SetMutableState<FormFieldPageLocationByName>;
	getGroupPaginationDataByName?: GetMutableState<FormGroupPaginationDataByname>;
	setGroupPaginationData?: (input: SetGroupPaginationDataInput) => void;
	setStayOnSamePage?: (flag: boolean) => void;
}

function Component({
	form,
	variablesData,
	groupsExpanded = {},
	openCustomsMap = {},
	variableVisibilityMap = {},
	variableFilteringMap = {},
	revision,
	currentChange,
	readOnly,
	tooltipContainer,
	isRevisionSelected,
	uniqueFieldsError,
	usedAsThumbnail,
	selectedStatus = null,
	isHistoryPagination,
	setSelectedStatus = () => undefined,
	setGroupExpanded = () => undefined,
	setFormFieldPageLocationByName = () => undefined,
	getGroupPaginationDataByName = () => ({}),
	setGroupPaginationData = () => undefined,
	setStayOnSamePage
}: Props) {
	const { title, titleEnabled } = form;

	return (
		<Wrapper isHistoryPagination={isHistoryPagination}>
			<Container>
				{/* FORM TITLE */}
				{titleEnabled && title && <FormTitle title={title}>{title}</FormTitle>}

				{/* STATUS INPUT */}
				<AddEditStatusInputs
					selectedStatus={selectedStatus}
					setSelectedStatus={setSelectedStatus}
					isRevisionSelected={isRevisionSelected}
					setStayOnSamePage={setStayOnSamePage}
				/>

				{/* FORM ELEMENTS */}
				<AddEditInputsFormDesigner
					tooltipContainer={tooltipContainer ?? undefined}
					form={form}
					variablesData={variablesData}
					groupsExpanded={groupsExpanded}
					openCustomsMap={openCustomsMap}
					variableVisibilityMap={variableVisibilityMap}
					variableFilteringMap={variableFilteringMap}
					revision={revision}
					currentChange={currentChange}
					readOnly={readOnly}
					isRevisionSelected={isRevisionSelected}
					uniqueFieldsError={uniqueFieldsError}
					usedAsThumbnail={usedAsThumbnail}
					setGroupExpanded={setGroupExpanded}
					setFormFieldPageLocationByName={setFormFieldPageLocationByName}
					getGroupPaginationDataByName={getGroupPaginationDataByName}
					setGroupPaginationData={setGroupPaginationData}
				/>

				<RequiredFieldsLabel />
				<CookiesNotice />
			</Container>
		</Wrapper>
	);
}

export const AddEditInputsAndGroupsFormDesigner = withMemo(Component);

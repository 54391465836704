import styled, { css } from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';

interface CalculationProps {
	depth: number;
	isDeleteHovered?: boolean;
	isChild?: boolean;
}

export const Container = styled.div`
	width: 100%;
	background-color: ${Colors.white};
`;

interface AddButtonProps {
	full?: boolean;
	isOrange?: boolean;
}

export const AddButton = styled.div<AddButtonProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.4rem;
	min-height: 4rem;
	min-width: 18rem;
	padding: 0 3rem;
	font-size: 1.6rem;
	cursor: pointer;
	background: transparent;
	color: ${Colors.primary.normal};
	border: 0.1rem solid ${Colors.primary.normal};

	${props =>
		props.full &&
		css`
			width: 100%;
		`}
	${props =>
		props.isOrange &&
		css`
			color: ${Colors.white};
			background-color: ${Colors.primary.normal};
		`}
`;

interface DeleteButtonContainerProps {
	depth: number;
}
export const DeleteButtonContainer = styled.div<DeleteButtonContainerProps>`
	display: block;
	z-index: ${({ depth: level }) => level && level};
`;
interface DeleteButtonProps {
	visible: boolean;
	zIndex: number;
	isAtRootLevel?: boolean;
}
export const DeleteButton = styled(DefaultIcon)<DeleteButtonProps>`
	position: absolute;
	padding: 0.8rem;
	top: 0;
	left: 100%;
	background-color: transparent;
	visibility: hidden;
	border-radius: 0.4rem;
	color: '#E1A1C2';
	z-index: ${({ zIndex }) => zIndex && zIndex};

	${({ isAtRootLevel: isAtRuleLevel }) =>
		isAtRuleLevel &&
		css`
			padding-left: 3rem;
		`};

	&:hover {
		opacity: 1;
		color: ${Colors.primary.normal};
		transition: color 0.3s;
	}

	${({ visible }) => visible && `visibility: visible;`};
`;

interface RuleProps {
	isChild?: boolean;
	isDeleteHovered?: boolean;
}

export const RuleContainer = styled.div<RuleProps>`
	width: 100%;
	position: relative;
	margin-bottom: 2rem;

	${({ isDeleteHovered }) =>
		isDeleteHovered &&
		css`
			box-shadow: 0 0 0 2px ${Colors.primary.normal};
			border-radius: 5px;
		`}
`;

export const ConditionOuterContainer = styled.div`
	width: 100%;
	position: relative;
	padding-bottom: 3rem;
`;
export const InnerContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const AddRuleWrapper = styled.div`
	width: 100%;
	max-width: 20rem;
`;

export const Rules = styled.div`
	width: calc(100% - 4rem);
	margin-bottom: 4rem;

	@media (${MediaQueries.maxWidth.lg}) {
		& > div > span > div[class*='DeleteButton'] {
			visibility: visible;
		}
	}
`;

export const Title = styled.div.attrs(() => ({ className: 'h4' }))`
	display: block;
	width: 100%;
	margin-bottom: 4rem;
`;

export const CalculationColumn = styled.div`
	position: relative;
	width: 50%;
	margin-bottom: 2rem;
	padding: 0 0.5rem;

	& ${AddButton} {
		min-width: 1rem;
	}
`;

export const CalculationRow = styled.div<CalculationProps>`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	padding-left: ${props => (props.depth >= 1 ? props.depth : 0)}rem;
	position: relative;

	& ${RuleContainer} {
		margin-bottom: 0;
		padding-right: 0;
		& ${CalculationColumn} {
			width: 100%;
			flex-direction: column;
			padding: 0;
		}
	}
	& ${CalculationColumn}:last-child {
		margin-bottom: 0;
	}
`;

export const FieldColumn = styled.div`
	position: relative;

	width: 50%;
	display: flex;
	margin-bottom: 2rem;
	padding: 0 0.5rem;
	& ${CalculationColumn} {
		width: 100%;
		padding: 0;
	}

	& ${AddButton} {
		min-width: 1rem;
	}
`;

export const RuleRow = styled.div<CalculationProps>`
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	& ${RuleContainer} {
		margin-bottom: 0;
		padding-right: 0;
	}
`;

export const ReadonlyField = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: ${Colors.gray.medium};
	color: ${Colors.white};
	border: 0.2rem solid ${Colors.gray.medium};
	border-radius: 0.4rem;
	font-size: 1.6rem;
	font-weight: 400;
	padding: 0.2rem 1rem;
	height: 4rem;
	width: 100%;
`;

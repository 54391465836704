import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';

interface WrapperProps {
	isHistoryPagination?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
	padding: 1rem;

	@media ${MediaQueries.maxWidth.md} {
		padding: 2rem;

		${({ isHistoryPagination }) => {
			if (isHistoryPagination) {
				return css`
					padding: 2rem 2rem 7.8rem 2rem;
				`;
			}
		}}
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 65.2rem;
	padding: 4rem 2rem 0;
	margin: auto;

	label {
		color: ${Colors.text.main};
		margin-bottom: 0.4rem;
	}

	@media ${MediaQueries.maxWidth.sm} {
		height: 100%;
	}

	${Wrapper}:last-of-type {
		.select__menu {
			position: relative;
		}
	}
`;

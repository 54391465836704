import { EntryFilter } from 'api/data/filters';
import { Variable } from 'api/data/variables';

/**
 * Verifies if there are only two values available in the Variable of type ctegory.
 * Filters are taken into consideration also.
 *
 * At the moment only classification variable category with two values is supported
 * Multi-category is not suported in Classification Model
 */
export function isClasificationVariableWithTwoValues(
	variable: Variable | null,
	filters: EntryFilter[],
	activeFilterIds: string[]
) {
	const classificationVariableFilter = variable
		? filters.find(
				filter =>
					filter.columnName === variable.name &&
					activeFilterIds.includes(filter.itemId) &&
					!filter.invalid
		  )
		: undefined;

	return (
		variable &&
		(classificationVariableFilter
			? classificationVariableFilter.values &&
			  classificationVariableFilter.values.length === 2
			: variable.categories.length === 2)
	);
}

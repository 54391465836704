import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { format } from 'date-fns';
import { isValidDate } from 'helpers/isValidDate';
import { useTranslation } from 'hooks/store';

const VERSION_DATE_YEAR_TIME_FORMAT = 'MMM dd, yyyy, h:mm a';

interface Props {
	visible: boolean;
	onPrimaryClick: () => void;
	onNeutralClick: () => void;
	latestRevisionDate?: string;
}

export function UnrestoredRevisionModal({
	visible,
	onPrimaryClick,
	onNeutralClick,
	latestRevisionDate
}: Props) {
	const { translate } = useTranslation();

	const modalTitle = translate(dict => dict.unrestoredRevision.warningTitle);
	const warningText = translate(dict => dict.unrestoredRevision.warningText);
	const primaryLabel = translate(dict => dict.buttons.continue);
	const neutralLabel = translate(dict => dict.buttons.cancel);

	const getDate = () => {
		if (latestRevisionDate) {
			const creationValidDate = isValidDate(latestRevisionDate)
				? latestRevisionDate.replace(/\s/g, 'T')
				: latestRevisionDate;

			return format(new Date(creationValidDate), VERSION_DATE_YEAR_TIME_FORMAT);
		}
	};

	return (
		<Modal
			size={s => s.s}
			visible={visible}
			title={modalTitle}
			primary={{
				label: primaryLabel,
				warning: true,
				onClick: onPrimaryClick
			}}
			neutral={{
				label: neutralLabel,
				onClick: onNeutralClick
			}}
			onClose={onNeutralClick}
			enterAsPrimaryOnClick
			close
		>
			<Typography.Paragraph multiline>
				{warningText}
				<strong>{getDate()}</strong>
			</Typography.Paragraph>
		</Modal>
	);
}

import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { PatientMessage, PatientSurvey } from 'components/Patient';
import { PatientManualSurveyParams } from 'store/data/patients';
import { ROUTES } from 'types/navigation';
import { useNavigation } from 'hooks/navigation';
import { usePatientManualSurveyParams } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

export function PatientManualSurveyPage() {
	const { replace } = useNavigation();

	const routeParams = useParams<{
		projectId: string;
		distributionEntryId: string;
		hashString: string;
		hashKey: string;
		formId?: string;
	}>();

	const [params, setParams] = usePatientManualSurveyParams();

	const [invalidURL, setInvalidURL] = useState(false);

	useEffectOnce(() => {
		const patientParams = routeParams as PatientManualSurveyParams;

		if (!isEmpty(patientParams)) {
			setParams(patientParams);
			replace(ROUTES.PatientManualSurvey);
		} else {
			setInvalidURL(true);
		}
	});

	if (invalidURL) return <PatientMessage message={m => m.invalidURL} />;

	if (!params) return null;

	return <PatientSurvey params={params} />;
}

import { Colors } from 'environment';
import styled from 'styled-components';

export const Link = styled.a`
	cursor: pointer;
	text-decoration: none;
	color: ${Colors.primary.normal};

	:hover {
		text-decoration: underline;
	}
`;

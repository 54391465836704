import styled, { css } from 'styled-components';

import { Colors } from 'environment';

interface DraggableRowContainerProps {
	zIndex: number;
}
export const DraggableRowContainer = styled.div<DraggableRowContainerProps>`
	display: flex;
	position: relative;
	align-items: flex-start;
	z-index: ${({ zIndex }) => zIndex};

	> div > div {
		width: calc(100% - 1.2rem);
	}

	> div:first-child > div {
		margin-right: 1.2rem;
	}

	> div:nth-child(2) > div {
		margin-left: 1.2rem;
	}
`;

export const DraggableHalfWidth = styled.div`
	width: 50%;

	> div {
		z-index: 1;
	}
`;

interface RowMenuWrapperProps {
	activeHover?: boolean;
	isInGroup?: boolean;
}
export const RowMenuWrapper = styled.div<RowMenuWrapperProps>`
	position: absolute;
	left: -0.8rem;
	right: -0.8rem;
	height: 100%;
	border-radius: 0.4rem;

	> :last-child {
		top: 0.8rem;
		right: -2.4rem;
	}

	:hover {
		border: 0.2rem solid ${Colors.primary.normal};

		> div {
			visibility: visible;

			> :first-child {
				visibility: visible;
			}
		}
	}

	${({ activeHover }) =>
		activeHover &&
		css`
			border: 0.2rem solid ${Colors.primary.normal};

			> div {
				visibility: visible;

				> :first-child {
					visibility: visible;
				}
			}
		`}
`;

export const MoreMenuWrapper = styled.div`
	height: 2.4rem;
	width: 2.4rem;
	position: absolute;
	top: 1rem;

	:hover {
		> :first-child {
			visibility: visible;
		}
	}
`;

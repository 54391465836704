import { State } from './types';

export const initialState: State = {
	dataset: {
		projectId: null,
		byId: {},
		byProjectId: {},
		bySnapshotId: {},
		byVariableName: {}
	},
	series: {
		bySeriesName: '',
		projectId: null,
		byId: {},
		byProjectId: {},
		bySnapshotId: {},
		byVariableName: {}
	},
	dependencies: {
		projectId: null,
		byId: {},
		byProjectId: {},
		bySnapshotId: {},
		byVariableName: {}
	}
};

import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionTypes, updateGroup } from 'store/data/variables';
import { UpdatedGroup } from 'api/data/variables';

export function useUpdateGroup(): Operation<(group: UpdatedGroup) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.UPDATE_GROUP);

	function handler(group: UpdatedGroup) {
		if (loading) return;
		if (error) resetError();

		dispatch(updateGroup(group));
	}

	return [{ loading, error }, handler];
}

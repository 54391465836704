import { createSelector } from 'reselect';

import { State } from './types';

function getAllEnterpriseOwnedProjects(state: State) {
	return state.allEnterpriseProjects;
}

export const selectAllEnterpriseOwnedProjects = createSelector(
	[getAllEnterpriseOwnedProjects],
	allEnterpriseProjects => allEnterpriseProjects
);

function getEnterpriseRoles(state: State) {
	return state.roles.byId;
}

function getEnterpriseRolesSearchTerm(state: State) {
	return state.roles.metadata.searchTerm;
}

function getEnterpriseRolesFetched(state: State) {
	return state.roles.metadata.fetched;
}

function getOrganizationAccessRights(state: State) {
	return state.organizationAccessRights;
}

export const selectEnterpriseRoles = createSelector([getEnterpriseRoles], roles => roles);

export const selectEnterpriseSearchTerm = createSelector(
	[getEnterpriseRolesSearchTerm],
	searchTerm => searchTerm
);

export const selectEnterpriseRolesFetched = createSelector(
	[getEnterpriseRolesFetched],
	roles => roles
);

export const selectOrganizationAccessRights = createSelector(
	[getOrganizationAccessRights],
	organizationAccessRights => organizationAccessRights
);

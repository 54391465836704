import { useState } from 'react';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { DATE_TIME_TIMEZONE_FORMAT } from 'consts';
import { addDistribution, selectAllDistributionsAreValid } from 'store/data/patients';
import { Form } from 'store/data/forms';
import { InputType } from 'types/index';
import { DistributionCard } from '../DistributionCard';
import { Container, BoldText, LoadingContainer } from './DistributionList.style';

import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { Flex } from 'components/UI/Flex';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { Input } from 'components/UI/Inputs/Input';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { Button } from 'components/UI/Interactables/Button';
import { Switch } from 'components/UI/Interactables/Switch';
import { useDebounce, useDispatch, useSelector } from 'hooks/utils';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useDistributionGlobalStartDate,
	useDistributionSenderDescription,
	useDistributionCentraliseEntryData,
	usePromManualDistribution
} from 'hooks/store';
import { convertToGlobalStartDate } from 'components/UI/Inputs/DateTimeInput/helpers';

interface DistributionListProps {
	ids: string[];
	forms: Form[];
	saving: boolean;
	unsavedChanges?: boolean;
	hasVariablesDefined: boolean;
	handleSubmit: () => void;
}

export function DistributionList({
	ids,
	forms,
	saving,
	unsavedChanges,
	hasVariablesDefined,
	handleSubmit
}: DistributionListProps) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();
	const { id: projectId = '' } = useParams<{ id: string }>();

	const [globalStartDate, setGlobalStartDate] = useDistributionGlobalStartDate();
	const [senderDescription, setSenderDescription] = useDistributionSenderDescription();
	const [centraliseEntryData, setCentraliseEntryData] = useDistributionCentraliseEntryData();

	const isDistributionListValid = useSelector(state =>
		selectAllDistributionsAreValid(state.data.patients)
	);

	const { isPromManualDistribution } = usePromManualDistribution();

	const [senderDescriptionDraft, setSenderDescriptionDraft] = useState(senderDescription ?? '');

	useDebounce(
		() => {
			if (senderDescription !== undefined && senderDescription !== senderDescriptionDraft) {
				setSenderDescription({
					senderDescription: senderDescriptionDraft
				});
			}
		},
		[senderDescriptionDraft],
		150
	);

	function addNewDistributionHandler() {
		dispatch(addDistribution());
	}

	function changeSenderDescription() {
		setSenderDescription({ senderDescription: senderDescriptionDraft });
	}

	function navigateToForms() {
		navigate(routes.proms.variables.view(projectId));
	}

	const hasActiveForms = forms.filter(form => form.active).length >= 1;

	function handleGlobalDateChange(date: string) {
		if (date) {
			const newDate = new Date(date);
			const newStartDate = format(newDate, DATE_TIME_TIMEZONE_FORMAT);
			const startTime = convertToGlobalStartDate(Date.parse(newStartDate ?? '')) + '+0000';

			setGlobalStartDate({ globalStartDate: startTime });
		}
	}

	const saveDistributionsDisabled =
		!unsavedChanges ||
		!isDistributionListValid ||
		(isPromManualDistribution && (!globalStartDate || globalStartDate === ''));

	return (
		<Container>
			<Typography.H6>
				{translate(dict => dict.promsPatients.distributionList.title)}
			</Typography.H6>
			<Spacer size={s => s.s} />
			{isPromManualDistribution ? (
				<>
					{!hasVariablesDefined && (
						<Typography.Caption marginOffset={{ bottom: 1.6 }}>
							<BoldText>
								{translate(dict => dict.promsPatients.distributionList.note)}
							</BoldText>
							&nbsp;
							{translate(dict => dict.promsPatients.distributionList.warning)}
						</Typography.Caption>
					)}
				</>
			) : (
				<Typography.Caption marginOffset={{ bottom: 1.6 }}>
					<BoldText>
						{translate(dict => dict.promsPatients.distributionList.note)}
					</BoldText>
					&nbsp;
					{hasVariablesDefined
						? translate(dict => dict.promsPatients.distributionList.info)
						: translate(dict => dict.promsPatients.distributionList.warning)}
				</Typography.Caption>
			)}

			<LoadingContainer disabled={saving}>
				{/* GLOBAL START DATE - MANUAL DISTRIBUTION ONLY */}
				{isPromManualDistribution && (
					<Flex marginOffset={{ bottom: 2.4 }} fullWidth column>
						<DateTimeInput
							value={globalStartDate ?? ''}
							onChange={handleGlobalDateChange}
							options={{
								label: translate(
									dict => dict.promsPatients.distributionList.globalStartDate
								),
								// canClearDate: true,
								noLeftMargins: true,
								timeLabel: translate(
									dict => dict.promsPatients.distributionList.globalStartTime
								)
							}}
						/>
					</Flex>
				)}

				{/* SENDER DESCRIPTION - AUTOMATIC DISTRIBUTION ONLY */}
				{!isPromManualDistribution && (
					<Flex marginOffset={{ bottom: 2.4 }} column>
						<Input
							type={InputType.Text}
							value={senderDescriptionDraft}
							label={translate(
								dict => dict.promsPatients.distributionList.senderDescription
							)}
							placeholder={translate(
								dict => dict.promsPatients.distributionList.senderDescriptionHint
							)}
							onChange={e => setSenderDescriptionDraft(e.target.value)}
							tooltipComponent={
								<InfoTooltip
									iconVisible
									closeOnMouseLeave
									marginOffset={{ left: 1.4, bottom: 0.4 }}
									text={translate(
										dict =>
											dict.promsPatients.distributionList
												.senderDescriptionInfo
									)}
								/>
							}
						/>

						<Switch
							label={translate(
								dict => dict.projectsPage.patient.distributionList.label
							)}
							description={translate(
								dict => dict.projectsPage.patient.distributionList.description
							)}
							on={centraliseEntryData}
							marginOffset={{ top: 1.6 }}
							onChange={() =>
								setCentraliseEntryData({
									centraliseEntryData: !centraliseEntryData
								})
							}
						/>
					</Flex>
				)}

				{/* LIST */}
				{ids &&
					ids.map((id, index) => (
						<DistributionCard
							key={'distribution-' + id}
							id={id}
							globalStartDate={globalStartDate}
							formsActive={hasActiveForms}
							index={index + 1}
							forms={[...forms].filter(form => form.active)}
						/>
					))}

				{/* ADD DISTRIBUTION */}
				<Button
					variant={v => v.link}
					title={translate(dict =>
						hasVariablesDefined
							? dict.promsPatients.distributionList.addNewDistribution
							: dict.promsPatients.distributionList.createNewFormVariable
					)}
					onClick={hasVariablesDefined ? addNewDistributionHandler : navigateToForms}
					marginOffset={{ top: 2.4, bottom: 1.6 }}
				/>

				{/* SAVE CHANGES */}
				<Button
					title={translate(dict => dict.promsPatients.distributionList.saveDistributions)}
					loading={saving}
					disabled={saveDistributionsDisabled}
					onClick={() => {
						handleSubmit();
						changeSenderDescription();
					}}
					hasFullWidth
				/>
				<Spacer size={s => s.m} />
			</LoadingContainer>
		</Container>
	);
}

import { customEventFactory } from 'helpers/events';

export const groupCountPulse = () =>
	customEventFactory<{
		groupName: string;
	}>('group-count-pulse');

export const variableSetCountPulse = () =>
	customEventFactory<{
		setName: string;
	}>('variable-set-count-pulse');

export const variableCardPulse = () =>
	customEventFactory<{
		variableName: string;
	}>('variable-card-pulse');

export const groupCardPulse = () =>
	customEventFactory<{
		groupName: string;
	}>('group-card-pulse');

export const variableSetCardPulse = () =>
	customEventFactory<{
		setName: string;
	}>('variable-set-card-pulse');

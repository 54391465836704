import styled, { css } from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { Colors, Shadows } from 'environment';
import { getMultilineEllipsisCSS } from 'helpers/cssGenerators';

const isOnTouchDevice = 'ontouchstart' in document.documentElement;

const MultilineEllipsis = getMultilineEllipsisCSS(3);

export const Title = styled(Typography.Paragraph)`
	font-weight: bold;
	margin-bottom: 0.8rem;

	${MultilineEllipsis}
`;

export const Description = styled(Typography.Hint)`
	${MultilineEllipsis}
`;

export const ReadMore = styled(Typography.Hint)`
	font-weight: 500;
	margin-top: 0.24rem;
`;

export const EntriesCount = styled(Typography.Hint)`
	color: ${Colors.text.disabled};
`;

interface ContainerProps {
	menuVisible: boolean;
}

export const WarningIcon = styled.div`
	position: absolute;
	margin-left: -0.2rem;
	margin-top: -2rem;
	background-color: ${Colors.background.disabled};
	padding: 0.4rem;
`;

export const Container = styled.a<ContainerProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 22.8rem;
	padding: 1.6rem;
	padding-top: 2rem;
	border-radius: 0.4rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	transition: box-shadow 0.05s;
	text-decoration: none;

	.custom-dropdown-container > div {
		color: ${!isOnTouchDevice && Colors.background.disabled};
	}

	.custom-dropdown-container:hover > div {
		color: initial;
	}

	${({ menuVisible }) =>
		menuVisible &&
		css`
			box-shadow: ${Shadows.hover};
			background-color: ${Colors.primary.normal};

			.custom-dropdown-container > div {
				color: ${Colors.primary.normal};
			}

			/* make text white on hover so its visible on "Colors.primary.normal" background */
			${Title}, ${Description}, ${ReadMore}, ${EntriesCount}, ${Typography.Hint} {
				color: ${Colors.background.disabled};
			}
		`}

	${isOnTouchDevice &&
	css`
		.card-more-icon {
			visibility: visible;
		}
	`}

	:hover {
		cursor: pointer;
		box-shadow: ${Shadows.hover};
		background-color: ${Colors.primary.normal};

		.card-more-icon {
			visibility: visible;
		}

		${({ menuVisible }) =>
			menuVisible &&
			css`
				.custom-dropdown-container > div {
					color: ${Colors.primary.normal};
				}
			`}

		${isOnTouchDevice &&
		css`
			.custom-dropdown-container > div {
				color: ${Colors.background.disabled};
			}

			.custom-dropdown-container:hover > div {
				color: initial;
			}
		`}

		/* make text white on hover so its visible on "Colors.primary.normal" background */
		${Title}, ${Description}, ${ReadMore}, ${EntriesCount}, ${Typography.Hint} {
			color: ${Colors.background.disabled};
		}
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
`;

interface MoreIconContainerProps {
	visible: boolean;
}
export const MoreIconContainer = styled.div<MoreIconContainerProps>`
	visibility: ${({ visible }) => !visible && 'hidden'};
	position: absolute;
	top: 0.8rem;
	right: 0.8rem;

	/* TODO: enable in case the design team wants this */
	/* .delete-project-option {
		${Typography.Paragraph} {
			color: ${Colors.text.error};
		}

		:hover {
			background-color: ${Colors.text.error};

			${Typography.Paragraph} {
				color: ${Colors.white};
			}
		}
	} */
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const Footer = styled.div<{ alignItemsRight: boolean }>`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	min-height: 2.4rem;
	${({ alignItemsRight }) =>
		alignItemsRight &&
		css`
			justify-content: end;
		`}
`;

import * as yup from 'yup';

import {
	CreateProject,
	Project,
	ProjectFormValues,
	ProjectMetadata,
	ProjectMetadataDefinition,
	ProjectMetadataParameter,
	ProjectMetadataType,
	UpdateProject
} from 'store/data/projects';
import { GenericMap, SubscriptionAddonCode } from 'types';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';
import { parseFromApiMetadata, parseToApiMetadata } from 'store/data/projects/parsers';

export function getInitialMetadataFormValues(
	metadataDefinition: ProjectMetadataDefinition,
	project?: Project
) {
	const initialMetadataValues: ProjectMetadata = {};
	const parsedMetadata = project ? parseFromApiMetadata(project.metadata) : undefined;
	metadataDefinition.forEach(metadataParameter => {
		if (parsedMetadata && parsedMetadata[metadataParameter.name]) {
			initialMetadataValues[metadataParameter.name] = parsedMetadata[metadataParameter.name];
		} else {
			initialMetadataValues[metadataParameter.name] =
				getDefaultMetadataParameterValue(metadataParameter);
		}
	});
	return initialMetadataValues;
}

function getDefaultMetadataParameterValue(parameter: ProjectMetadataParameter) {
	switch (parameter.type) {
		case ProjectMetadataType.Dropdown:
			return '';
		case ProjectMetadataType.String:
			return '';
		default:
			return '';
	}
}

/**
 *
 * @param definition Creates a matrix of rows and columns in order to
 * display correctly the metadata definition elements in the UI form
 * Returns rows of two columns each.
 * @returns
 */
export function transforMetadataParametersToFormRows(parameters: ProjectMetadataDefinition) {
	const formRows = [];
	for (let i = 0; i < parameters.length; i = i + 2) {
		const formRow = [];
		formRow.push(parameters[i]);
		if (i + 1 < parameters.length) formRow.push(parameters[i + 1]);
		formRows.push(formRow);
	}
	return formRows;
}

type GetProjectValidationSchemaProps = {
	projectMetadataDefinition: ProjectMetadataDefinition;
	translate: TranslateFunction;
};

export function getProjectValidationSchema({
	projectMetadataDefinition,
	translate
}: GetProjectValidationSchemaProps) {
	const schema: GenericMap<any> = {};
	const yupString = yup.string().trim();

	schema['projectName'] = yupString.required(
		translate(dict => dict.createProject.projectTitleError)
	);

	projectMetadataDefinition.forEach(metadataParameter => {
		if (metadataParameter.mandatory)
			schema[`${metadataParameter.name}`] = yupString.required(
				translate(dict => dict.projectMetadata.general.mandatoryField)
			);
	});

	const validationSchema = yup.object(schema);

	return { validationSchema };
}

export function parseFormValuesToEditedProject(
	values: ProjectFormValues,
	projectMetadataDefinition: ProjectMetadataDefinition
): UpdateProject {
	const project = parseFormValuesToProject(values, projectMetadataDefinition);
	return {
		...project,
		projectId: values.projectId?.toString() ?? ''
	};
}

export function parseFormValuesToProject(
	values: ProjectFormValues,
	projectMetadataDefinition: ProjectMetadataDefinition
) {
	const project: CreateProject = {
		projectName: values.projectName?.toString() ?? '',
		givenProjectNumber: values.givenProjectNumber?.toString() ?? '',
		status: values.status as ProjectStatus,
		description: values.description?.toString() ?? '',
		slideFolderURL: values.slideFolderURL?.toString() ?? '',
		projectType: values.projectType as ProjectType,
		projectEndDate: values.projectEndDate?.toString() ?? '',
		activeAddonCodes: values.activeAddonCodes as SubscriptionAddonCode[]
	};

	if (projectMetadataDefinition) {
		projectMetadataDefinition.forEach(definition => {
			if (!project.metadata) project.metadata = {};
			project.metadata[definition.name] = values[definition.name]?.toString() ?? '';
		});
	}

	project.metadata = parseToApiMetadata(project.metadata);
	return project;
}

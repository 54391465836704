import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { Cell as DefaultCell } from 'components/UI/Table/Table.style';

const isOnTouchDevice = 'ontouchstart' in document.documentElement;

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: calc(100vh - 26rem);
`;

interface WrapperProps {
	menuVisible: boolean;
}

export const Cell = styled(DefaultCell)<WrapperProps>`
	.custom-dropdown-container:hover > div {
		color: initial;
	}
	${({ menuVisible }) =>
		menuVisible &&
		css`
			background-color: ${Colors.primary.disabled};
			.custom-dropdown-container > div {
				color: ${Colors.primary.normal};
			}
			.card-more-icon {
				visibility: visible;
			}
		`}

	.card-more-icon {
		visibility: ${({ menuVisible }) => (menuVisible ? 'visible' : 'hidden')};
	}

	${isOnTouchDevice &&
	css`
		.card-more-icon {
			visibility: visible;
		}
	`}

	:hover {
		cursor: pointer;

		.card-more-icon {
			visibility: visible;
		}

		${({ menuVisible }) =>
			menuVisible &&
			css`
				.custom-dropdown-container > div {
					color: ${Colors.primary.normal};
				}
			`}
	}
`;

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	overflow-x: auto;

	/* @AA this is for windows to be able to see scrollbar */
	@media ${MediaQueries.maxWidth.md} {
		margin-bottom: 2.4rem;
	}

	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		min-width: 5.6rem;
		max-width: 5.6rem;
		border-top: 0;
	}

	.errored {
		/* Colors.text.error, 10% */
		background-color: rgba(255, 88, 93, 0.1);
	}
`;

export const IconWrapper = styled.div`
	width: 4rem;
`;

type MoreIconContainerProps = {
	visible: boolean;
};

export const MoreIconContainer = styled.div<MoreIconContainerProps>`
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

	${isOnTouchDevice &&
	css`
		visibility: visible;
	`}
`;

export const TextContainer = styled.div`
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	min-width: 24rem;
`;

import {
	CalculationActions,
	ComparisonNode,
	Variable,
	VariableCategory,
	VariableNode,
	CategoryNode
} from 'api/data/variables/types';
import { useTranslation } from 'hooks/store';
import { SelectItem } from 'types/index';
import { AddButton, FieldColumn, ReadonlyField, RuleRow } from '../CalculatedVariable.style';
import { useMemo } from 'react';
import { ArithmeticNode } from 'api/data/variables/types';
import {
	VariableType,
	ComparisonOperator,
	OperatorLabel,
	OperandType
} from 'types/data/variables/constants';
import { Dropdown } from 'components/UI/Dropdown';
import { DynamicCalculationInput } from './DynamicCalculationInput';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { LogicalOperator } from '../types';

const COMPARATORS = [
	OperatorLabel.GreaterThan,
	OperatorLabel.GreaterThanOrEqual,
	OperatorLabel.Equals,
	OperatorLabel.LessThan,
	OperatorLabel.LessThanOrEqual
];

const COMPARATOR_VALUES = {
	[OperatorLabel.GreaterThan]: ComparisonOperator.GreaterThan,
	[OperatorLabel.GreaterThanOrEqual]: ComparisonOperator.GreaterThanOrEqual,
	[OperatorLabel.Equals]: ComparisonOperator.Equals,
	[OperatorLabel.LessThan]: ComparisonOperator.LessThan,
	[OperatorLabel.LessThanOrEqual]: ComparisonOperator.LessThanOrEqual
};

export type Options = Exclude<
	OperatorLabel,
	OperatorLabel.Divide | OperatorLabel.Minus | OperatorLabel.Multiply | OperatorLabel.Plus
>;

type Props = {
	node: ComparisonNode;
	nodePath: string;
	isLastOperation: boolean;
	draftVariableType: VariableType;
	conclusionValue: CategoryNode | string | number | ArithmeticNode | null;
	conclusionPath: string | null;
	categories?: VariableCategory[];
	variables: Variable[];
	calculationActions: CalculationActions;
};

export function ComparisonOperation({
	node,
	nodePath,
	isLastOperation,
	conclusionValue,
	conclusionPath,
	draftVariableType,
	categories,
	variables,
	calculationActions
}: Props) {
	const {
		onAddEmptyOperandAtJsonLogicPath: onAddEmptyOperandAtPath,
		onAddOperandValue,
		onOperatorChange,
		onLogicalOperatorChange
	} = calculationActions;
	const { translate } = useTranslation();

	const isNumberVariable =
		draftVariableType == VariableType.Integer || draftVariableType == VariableType.Float;

	const isCategoryVariable = draftVariableType == VariableType.Category;

	const { firstField, secondField } = useMemo(
		() => extractFields(node, nodePath),
		[node, nodePath]
	);

	const operator = useMemo(() => {
		const operator = extractOperator(node);

		if (!operator) {
			return OperatorLabel.GreaterThan;
		}

		return operator;
	}, [node]);

	const logicalOperator = useMemo(() => {
		if (isLastOperation) {
			return LogicalOperator.Then;
		}

		return LogicalOperator.And;
	}, [node, isLastOperation]);

	const comparatorSelectItems: SelectItem[] = COMPARATORS.map(comparator => ({
		label: translate(
			dict => dict.variableFields.calculationFormula.conditionLabels[comparator as Options]
		),
		value: COMPARATOR_VALUES[comparator as Options]
	}));

	const logicalOperatorSelectItems: SelectItem[] = [
		LogicalOperator.Then,
		LogicalOperator.And
	].map(operator => ({
		label: translate(dict => dict.variableFields.calculationFormula.conditions[operator]),
		value: operator
	}));

	function handleSetLogicalOperator(operator: string) {
		onLogicalOperatorChange(nodePath, operator);
	}

	return (
		<>
			<RuleRow depth={0}>
				<FieldColumn>
					<ReadonlyField>{translate(dict => dict.terms.if)}</ReadonlyField>
				</FieldColumn>
				<FieldColumn>
					{firstField.value == null && firstField.type == null ? (
						<Dropdown
							shouldScrollIntoView
							button
							width={24}
							toggleComponent={({ ref, toggle }) => (
								<AddButton
									ref={ref}
									onClick={toggle}
									id={`add_first_operand_button_${nodePath}`}
									full
								>
									{translate(dict => dict.terms.add)}
								</AddButton>
							)}
						>
							<Dropdown.Item
								title={translate(dict => dict.terms.input)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										firstField.path,
										OperandType.NumberInput
									)
								}
								dataTestId={`add_first_operand_button_number_input${nodePath}`}
							/>
							<Dropdown.Item
								title={translate(dict => dict.terms.variable)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										firstField.path,
										OperandType.NumberVariable
									)
								}
								dataTestId={`add_first_operand_button_number_variable${nodePath}`}
							/>
							<Dropdown.Item
								title={translate(dict => dict.terms.operation)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										firstField.path,
										OperandType.ArithmeticOperation
									)
								}
								dataTestId={`add_first_operand_button_arithmetic_operation${nodePath}`}
							/>
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={firstField.type!}
							value={firstField.value!}
							valuePath={firstField.path}
							valueDepth={1}
							onChange={value =>
								onAddOperandValue(firstField.path, firstField.type!, value)
							}
							categories={categories}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
						/>
					)}
				</FieldColumn>
			</RuleRow>

			<RuleRow depth={0}>
				<FieldColumn>
					<CreatableSelect
						items={comparatorSelectItems}
						value={comparatorSelectItems.find(item => item.value === operator)}
						onValueSelected={value => value && onOperatorChange(nodePath, value)}
						canClear={false}
						id={`comparator_select_${nodePath}`}
					/>
				</FieldColumn>
				<FieldColumn>
					{secondField.value == null && secondField.type == null ? (
						<Dropdown
							shouldScrollIntoView
							button
							width={24}
							toggleComponent={({ ref, toggle }) => (
								<AddButton
									ref={ref}
									onClick={toggle}
									id={`add_second_operand_button_${nodePath}`}
									full
								>
									{translate(dict => dict.terms.add)}
								</AddButton>
							)}
						>
							<Dropdown.Item
								title={translate(dict => dict.terms.input)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										secondField.path,
										OperandType.NumberInput
									)
								}
								dataTestId={`add_second_operand_button_number_input${nodePath}`}
							/>
							<Dropdown.Item
								title={translate(dict => dict.terms.variable)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										secondField.path,
										OperandType.NumberVariable
									)
								}
								dataTestId={`add_second_operand_button_number_variable${nodePath}`}
							/>
							<Dropdown.Item
								title={translate(dict => dict.terms.operation)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										secondField.path,
										OperandType.ArithmeticOperation
									)
								}
								dataTestId={`add_second_operand_button_arithmetic_operation${nodePath}`}
							/>
						</Dropdown>
					) : (
						<DynamicCalculationInput
							type={secondField.type!}
							value={secondField.value!}
							valuePath={secondField.path}
							valueDepth={1}
							onChange={value =>
								onAddOperandValue(secondField.path, secondField.type!, value)
							}
							categories={categories}
							variables={variables}
							draftVariableType={draftVariableType}
							calculationActions={calculationActions}
						/>
					)}
				</FieldColumn>
			</RuleRow>

			<RuleRow depth={0}>
				<FieldColumn>
					<CreatableSelect
						items={logicalOperatorSelectItems}
						value={logicalOperatorSelectItems.find(
							item => item.value === logicalOperator
						)}
						onValueSelected={value => value && handleSetLogicalOperator(value)}
						canClear={false}
						id={`logical_operator_select_${nodePath}`}
					/>
				</FieldColumn>
				<FieldColumn>
					{conclusionValue == null && isNumberVariable && isLastOperation ? (
						<Dropdown
							shouldScrollIntoView
							button
							width={24}
							toggleComponent={({ ref, toggle }) => (
								<AddButton
									ref={ref}
									onClick={toggle}
									id={`add_category_button_${nodePath}`}
									full
								>
									{translate(dict => dict.terms.add)}
								</AddButton>
							)}
						>
							<Dropdown.Item
								title={translate(dict => dict.terms.input)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										conclusionPath!,
										OperandType.NumberInput
									)
								}
								dataTestId={`add_category_button_number_input${nodePath}`}
							/>
							<Dropdown.Item
								title={translate(dict => dict.terms.operation)}
								onClick={() =>
									onAddEmptyOperandAtPath(
										conclusionPath!,
										OperandType.ArithmeticOperation
									)
								}
								dataTestId={`add_category_button_arithmetic_operation${nodePath}`}
							/>
						</Dropdown>
					) : (
						isLastOperation && (
							<DynamicCalculationInput
								type={
									isCategoryVariable
										? OperandType.Category
										: (isNumberVariable &&
												typeof conclusionValue === 'number') ||
										  conclusionValue === ''
										? OperandType.NumberInput
										: OperandType.ArithmeticOperation
								}
								value={conclusionValue}
								valuePath={conclusionPath}
								valueDepth={1}
								onChange={value =>
									onAddOperandValue(conclusionPath!, OperandType.Category, value)
								}
								categories={categories}
								variables={variables}
								draftVariableType={draftVariableType}
								calculationActions={calculationActions}
							/>
						)
					)}
				</FieldColumn>
			</RuleRow>
		</>
	);
}

function extractFields(rootNode: ComparisonNode, rootPath: string) {
	const fields: {
		firstField: {
			value: number | '' | VariableNode | ArithmeticNode | null;
			path: string;
			type: OperandType | null;
		};
		secondField: {
			value: number | '' | VariableNode | ArithmeticNode | null;
			path: string;
			type: OperandType | null;
		};
	} = {
		firstField: {
			value: null,
			path: '',
			type: null
		},
		secondField: {
			value: null,
			path: '',
			type: null
		}
	};

	if (ComparisonOperator.LessThan in rootNode) {
		rootNode[ComparisonOperator.LessThan].forEach((value, index) => {
			const nodePath = `${rootPath}.${ComparisonOperator.LessThan}.${index}`;
			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;

				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (ComparisonOperator.LessThanOrEqual in rootNode) {
		rootNode[ComparisonOperator.LessThanOrEqual].forEach((value, index) => {
			const nodePath = `${rootPath}.${ComparisonOperator.LessThanOrEqual}.${index}`;
			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;

				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (ComparisonOperator.GreaterThan in rootNode) {
		rootNode[ComparisonOperator.GreaterThan].forEach((value, index) => {
			const nodePath = `${rootPath}.${ComparisonOperator.GreaterThan}.${index}`;
			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;
				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (ComparisonOperator.GreaterThanOrEqual in rootNode) {
		rootNode[ComparisonOperator.GreaterThanOrEqual].forEach((value, index) => {
			const nodePath = `${rootPath}.${ComparisonOperator.GreaterThanOrEqual}.${index}`;
			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;
				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	if (ComparisonOperator.Equals in rootNode) {
		rootNode[ComparisonOperator.Equals].forEach((value, index) => {
			const nodePath = `${rootPath}.${ComparisonOperator.Equals}.${index}`;
			if (value || value === '' || value === 0) {
				const type =
					typeof value === 'number' || value === ''
						? OperandType.NumberInput
						: typeof value === 'object' && 'var' in value
						? OperandType.NumberVariable
						: OperandType.ArithmeticOperation;
				if (index == 0) {
					fields.firstField = { value, path: nodePath, type };
				} else {
					fields.secondField = { value, path: nodePath, type };
				}
			} else {
				if (index == 0) {
					fields.firstField = { value: null, path: nodePath, type: null };
				} else {
					fields.secondField = { value: null, path: nodePath, type: null };
				}
			}
		});
	}

	return fields;
}

function extractOperator(rootNode: ComparisonNode) {
	if (ComparisonOperator.LessThan in rootNode) {
		return ComparisonOperator.LessThan;
	}

	if (ComparisonOperator.LessThanOrEqual in rootNode) {
		return ComparisonOperator.LessThanOrEqual;
	}

	if (ComparisonOperator.GreaterThan in rootNode) {
		return ComparisonOperator.GreaterThan;
	}

	if (ComparisonOperator.GreaterThanOrEqual in rootNode) {
		return ComparisonOperator.GreaterThanOrEqual;
	}

	if (ComparisonOperator.Equals in rootNode) {
		return ComparisonOperator.Equals;
	}

	return null;
}

import { useMemo } from 'react';

import { CorrelationsStatisticsData, CorrelationsStatisticsResults } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { CHART_COLORS } from 'consts';
import { useTranslation } from 'hooks/store';
import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { AnalysisStatistic } from 'components/Analysis/Analyses';

import { Circle } from './styles';

interface Props {
	results: CorrelationsStatisticsResults;
}

export function CorrelationsSpearman({ results }: Props) {
	const { translate } = useTranslation();

	const { data } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.spearman.name)}
			</AnalysisStatistic.Title>

			{data && <Result data={data} />}
		</AnalysisStatistic>
	);
}

function Result({ data }: { data: CorrelationsStatisticsData }) {
	const { translate } = useTranslation();
	const statistics = useMemo(() => {
		return data.map(statistic => {
			const rStatistic = getRStatistic({
				rValue: statistic.rValue ?? ''
			});

			const pStatistic = getPStatistic({
				pValue: statistic.pValue ?? ''
			});

			return {
				rStatistic,
				pStatistic,
				...(statistic.error ? { error: statistic.error } : {})
			};
		});
	}, [data]);

	const isGrouped = statistics.length > 1;

	return (
		<Table.Responsive fullWidth={false} horizontalScroll>
			<Table>
				<Table.Body>
					{statistics.map(({ rStatistic, pStatistic, error }, index) => (
						<Table.Row key={`spearman-statistic-${index}`}>
							{isGrouped && (
								<Table.Cell width={1.6}>
									<Circle color={CHART_COLORS[index]} />
								</Table.Cell>
							)}
							{error ? (
								<>
									<Table.Cell title={error} colSpan={2} width={28} noWrap>
										<AnalysisStatistic.Error>
											{error ??
												translate(
													({ errors }) =>
														errors.api.analyses.statistics.spearman
												)}
										</AnalysisStatistic.Error>
									</Table.Cell>
								</>
							) : (
								<>
									<Table.Cell title={rStatistic.text} width={14} noWrap>
										{rStatistic.JSX}
									</Table.Cell>
									<Table.Cell title={pStatistic.text} width={14} noWrap>
										{pStatistic.JSX}
									</Table.Cell>
								</>
							)}
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

function getRStatistic(input: { rValue: string }) {
	const { rValue } = input;

	const { value: parsedRValue } = parseAnalysisNumber(rValue, {
		decimals: 2
	});

	const output = {
		JSX: (
			<>
				<i>{`r`}</i>
				{` = ${parsedRValue}`}
			</>
		),
		text: `r = ${parsedRValue}`
	};

	return output;
}

function getPStatistic(input: { pValue: string }) {
	const { pValue } = input;

	const { operator, value: parsedPValue } = parseAnalysisNumber(pValue, {
		decimals: 3,
		formatAsPValue: true
	});

	const output = {
		JSX: (
			<>
				<i>{`p`}</i>
				{` ${operator} ${parsedPValue}`}
			</>
		),
		text: `p ${operator} ${parsedPValue}`
	};

	return output;
}

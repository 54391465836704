import type { PreviewVariable } from 'types/data/projects/import/types';

import { DATE_FORMATS } from 'types/data/projects/import/constants';
import { VariableType } from 'types/data/variables/constants';
import { requiresDateFormatTimezone } from './importTimezone';

export function getHasErrors(variables: PreviewVariable[], isBinary?: boolean) {
	return (
		variables.filter(variable => {
			const emptyTypeOrError =
				variable.type === '' ||
				variable.type === null ||
				!!variable.labelError ||
				!!variable.dateFormatError ||
				!!variable.dateTimeOptions?.formatError;

			const isCustomDateFormat = variable.dateFormat === 'Custom';

			const customDateError =
				(isCustomDateFormat && variable.customDateFormat === '') ||
				((variable.dateFormat === '' || variable.dateFormat === null) &&
					variable.customDateFormat === '');
			const emptyDateFormat = variable.dateFormat === '';

			const customDateTimeError =
				variable.dateFormat === DATE_FORMATS.Custom && variable.customDateFormat === '';
			const emptyDateTimeFormat =
				(variable.dateFormat === '' || variable.dateFormat === null) &&
				variable.customDateFormat === '';

			const dateFormatIssue =
				// Variable has to be Date
				variable.type === VariableType.Date &&
				(isBinary
					? // If it's an XLS with unknown date format
					  !variable.isExcelDateFormat && (customDateError || emptyDateFormat)
					: customDateError || emptyDateFormat);

			// if datetime variable format is missing timezone data
			const timezoneMissing =
				variable.type === VariableType.DateTime &&
				!!variable.dateFormat &&
				requiresDateFormatTimezone(
					isCustomDateFormat ? variable.customDateFormat : variable.dateFormat,
					true
				) &&
				(!variable.timeZone || variable.timeZone?.value === '');

			const dateTimeFormatIssue =
				// Variable has to be DateTime
				variable.type === VariableType.DateTime &&
				(customDateTimeError || emptyDateTimeFormat);

			const labelError = !!variable.labelError;

			const previewLabelError = !!variable.previewVariableLabelError;

			return (
				emptyTypeOrError ||
				dateFormatIssue ||
				dateTimeFormatIssue ||
				timezoneMissing ||
				previewLabelError ||
				labelError
			);
		}).length > 0
	);
}

import { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Colors, Svgs } from 'environment';
import { variableCardPulse } from 'events/variables';
import { DraggableVariableCardType, MergeVariablesIntoGroup } from 'store/data/variables';
import { DragProps, VariableCardPayload } from '../CommonCard/types';
import { VariableSetConfirmDragAndDropActionModal } from '../../ConfirmDragAndDropActionModal';
import { VariableCardContainer, LoadingContainer } from './VariableCard.style';
import { Asterisk } from 'components/UI/Asterisk';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import { isVariablePromGenerated } from 'helpers/variables';
import { useDragVariableContext, useRemoveVariableFromSet, useTranslation } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	dragMainProps: DragProps;
	index: number;
	name: string;
	title: string;
	subtitle: string;
	required: boolean;
	children: React.ReactNode;
	readOnly: boolean;
	systemGenerated: boolean;
	onClick: () => void;
	mergeVariables: MergeVariablesIntoGroup;
	hasError?: boolean;
}

export function VariableCard({
	dragMainProps: { ref: mainRef, isDragging, drag },
	index,
	name,
	title,
	subtitle,
	required,
	children,
	readOnly,
	systemGenerated,
	onClick,
	mergeVariables,
	hasError
}: Props) {
	const [{ loading: removingVariableFromSet }] = useRemoveVariableFromSet({
		variableName: name
	});
	const { setError: setErrorNotification } = useAlerts();
	const { translate } = useTranslation();
	const { draggedVariables } = useDragVariableContext();

	const loading = removingVariableFromSet;

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	const [pulseCard, setPulseCard] = useState(false);

	useCustomEventListener(variableCardPulse, {
		onListen: payload => {
			if (payload.variableName === name) setPulseCard(true);
		}
	});

	// DROP - CARD
	const [{ handlerId, isOverCurrent, isSysGenVariable }, drop] = useDrop<any, any, any>({
		accept: DraggableVariableCardType.Variable,
		collect: monitor => {
			const draggedVariable: VariableCardPayload = monitor.getItem();
			const isSysGenVariable =
				draggedVariable &&
				isVariablePromGenerated({ systemGenerated: draggedVariable.systemGenerated });
			return {
				handlerId: monitor.getHandlerId(),
				isOverCurrent: monitor.isOver({ shallow: true }),
				isSysGenVariable
			};
		},
		drop: (item: VariableCardPayload, monitor) => {
			if (!monitor.isOver({ shallow: true })) return;

			if (item.parentSet) return confirmActionModal.open(item);
			if (systemGenerated || isSysGenVariable)
				return setErrorNotification({
					message: `${translate(
						dict => dict.variablesPage.variableCard.beforeVariable
					)} ${title} ${translate(dict => dict.terms.variableLowerCase)}`
				});

			// PRJCTS-7037: https://ledidi.atlassian.net/browse/PRJCTS-7037
			if (item.isGroup) {
				const preventDrop = draggedVariables.some(({ type }) =>
					[VariableType.File].includes(type)
				);
				if (preventDrop) {
					return setErrorNotification({
						message: translate(dict => dict.variables.forbiddenTypeInSeries)
					});
				}
			}
			handleDrop(item);
		}
	});

	function handleDrop(item: VariableCardPayload) {
		if (item.parentSet) {
			// DRAG FROM VARIABLE GROUP FROM VARIABLE SET
			if (item.parentGroup) {
				return mergeVariables({
					targetVariable: {
						index,
						name
					},
					draggedVariable: {
						index: item.index,
						name: item.name
					},
					from: {
						set: {
							setName: item.parentSet,
							variableName: item.name,
							parentGroup: item.parentGroup
						}
					}
				});
			}

			// DRAG FROM VARIABLE SET
			return mergeVariables({
				targetVariable: {
					index,
					name
				},
				draggedVariable: {
					index: item.index,
					name: item.name
				},
				from: {
					set: {
						setName: item.parentSet,
						variableName: item.name
					}
				}
			});
		}

		// CREATE GROUP OUT OF 2 VARIABLES
		mergeVariables({
			targetVariable: {
				index,
				name
			},
			draggedVariable: {
				index: item.index,
				name: item.name
			},
			...(item.parentGroup && {
				from: {
					group: {
						groupName: item.parentGroup,
						variableName: item.name
					}
				}
			})
		});
	}

	drop(drag(mainRef));

	const onAnimationEnd = useCallback(() => setPulseCard(false), []);

	return (
		<>
			<VariableCardContainer
				{...(pulseCard && {
					className: 'pulse-vibrant-green'
				})}
				onAnimationEnd={onAnimationEnd}
				//
				ref={mainRef}
				hasError={hasError}
				isDragging={isDragging}
				data-handler-id={handlerId}
				isHovered={isOverCurrent}
				onClick={readOnly || systemGenerated ? undefined : onClick}
				id={title?.replaceAll(' ', '').toLowerCase()}
				disabled={loading}
				systemGenerated={systemGenerated}
				readOnly={readOnly}
				data-scroll-id={name}
			>
				<Flex align={a => a.center}>
					<Typography.Paragraph fontweight={w => w.normal} title={title} ellipsis>
						{title}
					</Typography.Paragraph>
					{required && (
						<Typography.Paragraph>
							<Asterisk paddingLeft />
						</Typography.Paragraph>
					)}
				</Flex>
				<Flex justify={j => j.between}>
					<Typography.Caption title={subtitle} ellipsis>
						{subtitle}
					</Typography.Caption>
					{hasError && (
						<Icon
							className="variable-card__error-indicator"
							svg={Svgs.Information}
							size={s => s.m}
							colors={{ color: Colors.text.error }}
						/>
					)}
				</Flex>

				{children}

				{loading && (
					<LoadingContainer>
						<Loader />
					</LoadingContainer>
				)}
			</VariableCardContainer>

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="from"
					onConfirm={() => handleDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}

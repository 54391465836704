import { UserCardBasic } from './UserCardBasic';
import { UserCardProfile } from './UserCardProfile';
import { UserCardTable } from './UserCardTable';

import { UserCardDetails } from './UserCardDetails/UserCardDetails';
import { UserCardDropdownItem } from './UserCardDropdownItem/UserCardDropdownItem';

export function UserCard() {
	return null;
}

UserCard.Basic = UserCardBasic;
UserCard.Details = UserCardDetails;
UserCard.Profile = UserCardProfile;
UserCard.Table = UserCardTable;
UserCard.DropdownItem = UserCardDropdownItem;

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
		flex-wrap: nowrap;
	}
`;

export const Divider = styled.div`
	height: 0.1rem;
	background-color: #ededed;
	margin: 0.8rem 0;
`;

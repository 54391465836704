import { NewGroup } from 'api/data/variables';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { createGroup, ActionTypes } from 'store/data/variables';
import { RequireOnlyOne } from 'types/index';

export function useCreateGroup(): Operation<
	(
		group: NewGroup,
		options?: {
			variableNames?: string[];
			destinationIndex?: number;
			setName?: string;
			from?: RequireOnlyOne<{
				set?: {
					setName: string;
					variableName: string;
					parentGroup?: string;
				};
				group?: {
					groupName: string;
					variableName: string;
				};
				mainList?: {
					variableName: string;
				};
			}>;
		}
	) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_GROUP);

	function handler(
		group: NewGroup,
		options?: {
			variableNames?: string[];
			destinationIndex?: number;
			setName?: string;
			from?: RequireOnlyOne<{
				set?: {
					setName: string;
					variableName: string;
					parentGroup?: string;
				};
				group?: {
					groupName: string;
					variableName: string;
				};
				mainList?: {
					variableName: string;
				};
			}>;
		}
	) {
		if (loading) return;
		if (error) resetError();

		dispatch(createGroup(group, options));
	}

	return [{ loading, error }, handler];
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectAnalysisConfigPanel,
	selectAnalysisFormatting,
	selectAnalysisParameters,
	selectAnalysisChartTypes,
	setAnalysisConfigPanel,
	SetAnalysisConfigPanelAction,
	setAnalysisFormatting,
	SetAnalysisFormattingAction,
	setAnalysisParameters,
	SetAnalysisParametersAction,
	SetAnalysisChartTypesAction,
	setAnalysisChartTypes,
	SetJadBioPerformanceAction,
	setJadBioPerformanceAction,
	selectJadBioPerformance
} from 'store/data/analyses';
import { ActionPayload } from 'store/types';

interface Handlers {
	openConfigPanel: (payload: ActionPayload<SetAnalysisConfigPanelAction>) => void;
	openFormatting: (payload: ActionPayload<SetAnalysisFormattingAction>) => void;
	openParameters: (payload: ActionPayload<SetAnalysisParametersAction>) => void;
	openChartTypes: (payload: ActionPayload<SetAnalysisChartTypesAction>) => void;
	openJadBioPerformance: (payload: ActionPayload<SetJadBioPerformanceAction>) => void;
}

interface Data {
	isConfigPanelOpen: boolean;
	isFormattingOpen: boolean;
	isParamsOpen: boolean;
	isJadBioPerformanceOpen?: boolean;
	isChartTypesOpen?: boolean;
}

export function useAnalysisConfigPanel(analysisId: string): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const isConfigPanelOpen = useSelector(state =>
		selectAnalysisConfigPanel(state.data.analyses, analysisId)
	);
	const isFormattingOpen = useSelector(state =>
		selectAnalysisFormatting(state.data.analyses, analysisId)
	);
	const isParamsOpen = useSelector(state =>
		selectAnalysisParameters(state.data.analyses, analysisId)
	);

	const isChartTypesOpen = useSelector(state =>
		selectAnalysisChartTypes(state.data.analyses, analysisId)
	);

	const isJadBioPerformanceOpen = useSelector(state =>
		selectJadBioPerformance(state.data.analyses, analysisId)
	);

	const data = {
		isConfigPanelOpen,
		isFormattingOpen,
		isParamsOpen,
		isJadBioPerformanceOpen,
		isChartTypesOpen
	};

	const handlers = {
		openConfigPanel: (payload: ActionPayload<SetAnalysisConfigPanelAction>) =>
			dispatch(setAnalysisConfigPanel(payload)),
		openFormatting: (payload: ActionPayload<SetAnalysisFormattingAction>) =>
			dispatch(setAnalysisFormatting(payload)),
		openParameters: (payload: ActionPayload<SetAnalysisParametersAction>) =>
			dispatch(setAnalysisParameters(payload)),
		openJadBioPerformance: (payload: ActionPayload<SetJadBioPerformanceAction>) =>
			dispatch(setJadBioPerformanceAction(payload)),
		openChartTypes: (payload: ActionPayload<SetAnalysisChartTypesAction>) =>
			dispatch(setAnalysisChartTypes(payload))
	};

	return [data, handlers];
}

import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { InputType } from 'types/index';
import { Title } from './styles';
import { Input } from 'components/UI/Inputs/Input';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { sanatizeCode } from 'helpers/auth';
import { useTranslation, useAccount, useCompleteResetPassword } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface FormValues {
	code: string;
	password: string;
	confirmPassword: string;
}

interface Props {
	handleBack: () => void;
}

export function ResetPasswordForm({ handleBack }: Props) {
	const { translate } = useTranslation();

	const [{ loading: loadingAccount, error: errorLoadingAccount }] = useAccount();
	const [
		{ loading: loadingCompleteResetPassword, error: errorCompleteResetPassword },
		{ handler: completeResetPassword, resetError: resetCompleteResetPasswordError }
	] = useCompleteResetPassword();

	const loading = loadingCompleteResetPassword || loadingAccount;
	const error = errorCompleteResetPassword || errorLoadingAccount;
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');
	const [codeError, setCodeError] = useState('');

	const initialValues: FormValues = {
		password: '',
		confirmPassword: '',
		code: ''
	};

	const validationSchema = yup.object({
		password: yup
			.string()
			.required(translate(dict => dict.validation.password.required))
			.min(
				16,
				translate(dict => dict.validation.password.length)
			)
			.matches(
				/[a-z]/,
				translate(dict => dict.validation.password.lowercase)
			)
			.matches(
				/[A-Z]/,
				translate(dict => dict.validation.password.uppercase)
			)
			.matches(
				/[0-9]/,
				translate(dict => dict.validation.password.digit)
			),
		confirmPassword: yup
			.string()
			.required(translate(dict => dict.validation.password.confirm))
			.oneOf(
				[yup.ref('password'), null],
				translate(dict => dict.validation.password.match)
			),
		code: yup
			.string()
			.required(translate(dict => dict.validation.code.required))
			.min(
				6,
				translate(dict => dict.validation.code.valid)
			)
	});

	const { Form, trigger, register, setValue, handleSubmit, errors, isDirty } = useReactForm({
		initialValues,
		validationSchema
	});

	const handleFormSubmit = handleSubmit(({ password, code }) => {
		if (isDirty && !error && !loading) completeResetPassword({ password, code });
	});

	function handleClearError() {
		if (errorCompleteResetPassword) resetCompleteResetPasswordError();
	}

	const handleResetButton = () => {
		setPasswordError(errors.password?.message ?? '');
		setConfirmPasswordError(errors.confirmPassword?.message ?? '');
		setCodeError(errors.code?.message ?? '');
	};

	useEffect(() => {
		if (errors.password?.message !== passwordError) {
			setPasswordError('');
		}
		if (errors.confirmPassword?.message !== confirmPasswordError) {
			setConfirmPasswordError('');
		}
		if (errors.code?.message !== codeError) {
			setCodeError('');
		}
	}, [errors.password, errors.confirmPassword, errors.code]);

	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			handleClearError();

			const { name, value } = e.target;

			if (name === 'code') setValue('code', sanatizeCode(value), { shouldValidate: true });
			if (name === 'password') trigger('confirmPassword');
		},
		[errorCompleteResetPassword]
	);

	return (
		<Form onSubmit={handleFormSubmit}>
			<Title>{translate(dict => dict.forgotPasswordForm.title)}</Title>

			<Input
				{...register('password', {
					onChange: handleOnChange
				})}
				type={InputType.Password}
				label={translate(dict => dict.newPasswordForm.password)}
				placeholder={translate(dict => dict.newPasswordForm.password)}
				error={passwordError}
				autoFocus
			/>
			<Spacer size={s => s.xs} />
			<Input
				{...register('confirmPassword', {
					onChange: handleOnChange
				})}
				type={InputType.Password}
				label={translate(dict => dict.newPasswordForm.confirmPassword)}
				placeholder={translate(dict => dict.newPasswordForm.confirmPassword)}
				error={confirmPasswordError}
			/>
			<Spacer size={s => s.xs} />
			<Input
				{...register('code', {
					onChange: handleOnChange
				})}
				type={InputType.Text}
				label={translate(dict => dict.newPasswordForm.code)}
				placeholder={translate(dict => dict.newPasswordForm.codePlaceholder)}
				error={codeError}
			/>

			<Spacer size={s => s.m} />

			<Button
				type="submit"
				title={translate(dict => dict.newPasswordForm.update)}
				loading={loading}
				hasPointer={!loading}
				onClick={handleResetButton}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Button
				variant={v => v.outline}
				title={translate(dict => dict.buttons.goBack)}
				onClick={loading ? undefined : handleBack}
				hasFullWidth
			/>
		</Form>
	);
}

import { useEffect } from 'react';

import { Snapshots } from 'components/Analysis';
import { AddAnalysis } from 'components/Analysis/Analyses/AnalysisList/AddAnalysis';
import { MediaQueries, Svgs } from 'environment';
import { useAnalysesActiveColum } from 'hooks/store/data/analysis/useAnalysesActiveColumn';
import { Columns } from 'api/data/analyses';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Switch } from 'components/UI/Interactables/Switch';
import { useFilters, useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

export function AnalysisPageHeader() {
	const isMinWidthHd = useMediaQuery(MediaQueries.minWidth.hd);
	const [{ areFiltersOpen }, { toggleOpenFilters }] = useFilters();
	const [activeColumn, setAnalysesColumn] = useAnalysesActiveColum();
	const { translate } = useTranslation();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);
	useEffect(() => {
		if (!isMinWidthHd && activeColumn === Columns.ThreeColumns)
			setAnalysesColumn({ column: Columns.TwoColumns });
	}, [isMinWidthHd, activeColumn]);

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) => (
					<Flex align={a => a.center}>
						{!isMobileDevice && <Snapshots />}
						<AddAnalysis addButton={addButton} />
					</Flex>
				)}
			/>
			{!isMobileDevice ? (
				<Header.Title
					title={translate(({ projectTabs }) => projectTabs.analysis)}
					component={
						<Flex align={a => a.center}>
							<Switch
								label={translate(({ filters }) => filters.showFilters)}
								onChange={toggleOpenFilters}
								on={areFiltersOpen}
							/>
							<Icon
								title={translate(({ analysis }) => analysis.columnView.oneColumn)}
								variant={v => v.button}
								svg={Svgs.ColumnsOne}
								onClick={() => setAnalysesColumn({ column: Columns.OneColumn })}
								active={activeColumn === Columns.OneColumn}
								marginOffset={{ left: 2.4 }}
							/>
							<Icon
								title={translate(({ analysis }) => analysis.columnView.twoColumns)}
								variant={v => v.button}
								svg={Svgs.ColumnsTwo}
								onClick={() => setAnalysesColumn({ column: Columns.TwoColumns })}
								active={activeColumn === Columns.TwoColumns}
								marginOffset={{ left: 0.8 }}
							/>
							{isMinWidthHd && (
								<Icon
									title={translate(
										({ analysis }) => analysis.columnView.threeColumns
									)}
									variant={v => v.button}
									svg={Svgs.ColumnsThree}
									onClick={() =>
										setAnalysesColumn({ column: Columns.ThreeColumns })
									}
									active={activeColumn === Columns.ThreeColumns}
									marginOffset={{ left: 0.8 }}
								/>
							)}
						</Flex>
					}
				/>
			) : (
				<Header.Title
					title={translate(({ projectTabs }) => projectTabs.analysis)}
					component={
						<Flex>
							<Icon
								variant={v => v.button}
								svg={Svgs.Filter}
								onClick={toggleOpenFilters}
								active={areFiltersOpen}
							/>
						</Flex>
					}
				></Header.Title>
			)}
		</>
	);
}

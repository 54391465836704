import styled from 'styled-components';

export const Container = styled.div`
	margin: 1.6rem;
`;

export const InputWrapper = styled.div`
	display: flex;

	/* due time */
	> :first-child {
		/* width: unset; */
		padding-right: 1.6rem;
	}

	/* time */
	> :last-child {
		width: 8.9rem;
		min-width: 8.9rem;
	}
`;

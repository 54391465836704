import { useState, useEffect } from 'react';
import { Suspend } from 'components/UI/Suspend';
import { PatientMessage, PatientSurveyForm } from 'components/Patient';
import { PatientManualSurveyParams } from 'store/data/patients';
import { Container } from 'pages/Dataset/AddEditEntry/AddEditEntryPage.style';
import {
	useProjectId,
	useSurveyData,
	useVariables,
	useDependencies,
	useFormId,
	useForms
} from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

interface Props {
	params: PatientManualSurveyParams;
}

export function PatientSurvey({ params }: Props) {
	const [, setProjectId] = useProjectId();

	const [{ loading: loadingSurveyData, error: errorLoadingSurveyData }, getSurveyData] =
		useSurveyData();

	const [{ fetched: areVariablesFetched }] = useVariables({
		initial: true,
		lazy: true
	});

	/**
	 * NEEDS TO BE HERE TO APPLY DEPENDENCY RULES OVER THE ENTRY FIELDS
	 */
	useDependencies();

	const hasFormId = !!params.formId;

	const [formId, setFormId] = useFormId();
	const [{ data: forms, fetched: areFormsFetched }] = useForms({
		lazy: true
	});

	const [render, setRender] = useState(false);
	const [entryExpired, setEntryExpired] = useState(false);
	const [thankYouMessage, setThankYouMessage] = useState(false);

	useEffectOnce(() => getSurveyData());

	useEffect(() => {
		setProjectId(params.projectId);
		if (params.formId) setFormId(params.formId);
	}, [params]);

	// DEBOUNCE RENDER
	useEffect(() => {
		if (formId) setRender(true);
	}, [formId]);

	// SELECT THE FIRST FORM ON-LOAD (IF ANY)
	useEffect(() => {
		if (!(areFormsFetched && !render && !hasFormId)) return;

		const firstFormId = forms.find(form => form.active)?.id;

		// CUSTOM FORM WAIT UNTIL FORM ID IS SET DEBOUNCE IT WITH STATE
		if (firstFormId && firstFormId !== formId) {
			setFormId(firstFormId);
		}
		// DEFAULT FORM - NO NEED TO WAIT - RENDER
		else {
			setRender(true);
		}
	}, [forms, areFormsFetched, render]);

	useEffect(() => {
		if (errorLoadingSurveyData) setEntryExpired(true);
	}, [errorLoadingSurveyData]);

	function handleThankYouMessage() {
		setThankYouMessage(true);
	}

	if (entryExpired) return <PatientMessage message={m => m.entryExpired} />;

	if (thankYouMessage) return <PatientMessage message={m => m.thankYouMessage} />;

	const suspendProps = {
		loading: loadingSurveyData,
		immediate: !areVariablesFetched || !areFormsFetched
	};

	return (
		<Suspend loading={suspendProps.loading} immediate={suspendProps.immediate}>
			<Container>
				{render && (
					<PatientSurveyForm
						renderFormsDrawer={!hasFormId}
						handleThankYouMessage={handleThankYouMessage}
					/>
				)}
			</Container>
		</Suspend>
	);
}

import { CollaboratorsDataArray } from 'store/data/collaborators';

import { CollaboratorCard } from './CollaboratorCard';
import { OrganizationCard } from './OrganizationCard';

import { Container } from './CollaboratorsGrid.style';
import { collaboratorsDataArrayJSXIterator } from 'helpers/collaborators';

interface Props {
	collaboratorsDataArray: CollaboratorsDataArray;
	collaboratorCardData: {
		isSelf: (collaboratorId: string) => boolean;
		isProjectOwner: (collaboratorId: string) => boolean;
		clickable: boolean;
	};
	onCollaboratorClick: (collaboratorId: string) => void;
	onOrganizationClick: (organizationId: string) => void;
}

export function CollaboratorsGrid({
	collaboratorsDataArray,
	collaboratorCardData: { isSelf, isProjectOwner, clickable },
	onCollaboratorClick,
	onOrganizationClick
}: Props) {
	return (
		<Container>
			{collaboratorsDataArrayJSXIterator(
				collaboratorsDataArray,
				// COLLABORATORS
				collaborator => (
					<CollaboratorCard
						key={`collaborator_${collaborator.userId ?? collaborator.emailAddress}`}
						collaborator={collaborator}
						isSelf={isSelf(collaborator.userId)}
						isOwner={isProjectOwner(collaborator.userId)}
						clickable={clickable}
						onClick={() => onCollaboratorClick(collaborator.userId)}
					/>
				),
				// ORGANIZATIONS
				organizationData => (
					<OrganizationCard
						key={`organization_${organizationData.id}`}
						organizationData={organizationData}
						onClick={() => onOrganizationClick(organizationData.id)}
					/>
				)
			)}
		</Container>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { selectAdminUsersUserId, setAdminUsersUserId, AdminUserId } from 'store/account/admin';

export function useAdminUsersUserId(): LocalOperationResult<
	AdminUserId,
	(userId: AdminUserId) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAdminUsersUserId(state.account.admin));

	function handler(userId: AdminUserId) {
		dispatch(setAdminUsersUserId({ userId }));
	}

	return [data, handler];
}

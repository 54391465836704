import { Variable } from 'api/data/variables';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteVariable, useIsVariableInCalculationCases } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	variable: Variable;
	onClose: () => void;
}

export function DeleteVariableModal({ variable, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: deletingVariable, error: errorDeletingVariable }, deleteVariable] =
		useDeleteVariable();

	// CLOSE MODAL AFTER VARIABLE WAS DELETED
	useCompletedAction(deletingVariable, errorDeletingVariable, onClose);

	const { name, label } = variable;

	const calculatedVariableLabels = useIsVariableInCalculationCases(name);

	function handleDelete() {
		deleteVariable(name);
	}

	const title = `${translate(dict => dict.terms.delete)} ${label}?`;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: deletingVariable,
				onClick: handleDelete
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{calculatedVariableLabels.length > 0 && (
					<>
						{translate(dict => dict.promptComposite.description)}
						{'\n'}
						{calculatedVariableLabels.map((compositeVar, index) => (
							<b key={index}>{`${compositeVar}\n`}</b>
						))}
					</>
				)}

				{!calculatedVariableLabels.length && (
					<>
						{translate(dict => dict.variablesPage.deleteVariableModal.message)}
						{'\n\n'}
						<b>{translate(dict => dict.terms.deleteWarningMessage)}</b>
					</>
				)}
			</Typography.Paragraph>
		</Modal>
	);
}

import styled from 'styled-components';

import { Dropdown } from 'components/UI/Dropdown';
import { DropdownItem as DefaultDropdownItem } from 'components/UI/Dropdown/DropdownItem';
import { Dictionary } from 'environment';
import { useTranslation } from 'hooks/store';
import { NumericFilterOperator } from 'store/ui/tables/types';

interface Props {
	selected: NumericFilterOperator;
	onSelect: (operator: NumericFilterOperator) => void;
}

export function SelectNumericOperator({ selected, onSelect }: Props) {
	const { translate } = useTranslation();

	function getOperatorLabel(operator: NumericFilterOperator) {
		if (operator === NumericFilterOperator.Between) return Dictionary.displayers.between;
		if (operator === NumericFilterOperator.EqualTo) return Dictionary.displayers.equals;
		if (operator === NumericFilterOperator.GreaterThan)
			return Dictionary.displayers.greaterThan;
		if (operator === NumericFilterOperator.LessThan) return Dictionary.displayers.lessThan;

		return Dictionary.displayers.between;
	}

	return (
		<Dropdown title={translate(() => getOperatorLabel(selected))} button>
			<DropdownItem onClick={() => onSelect(NumericFilterOperator.Between)}>
				{translate(dict => dict.displayers.between)}
			</DropdownItem>
			<DropdownItem onClick={() => onSelect(NumericFilterOperator.EqualTo)}>
				{translate(dict => dict.displayers.equals)}
			</DropdownItem>
			<DropdownItem onClick={() => onSelect(NumericFilterOperator.GreaterThan)}>
				{translate(dict => dict.displayers.greaterThan)}
			</DropdownItem>
			<DropdownItem onClick={() => onSelect(NumericFilterOperator.LessThan)}>
				{translate(dict => dict.displayers.lessThan)}
			</DropdownItem>
		</Dropdown>
	);
}

const DropdownItem = styled(DefaultDropdownItem)`
	padding: 0 1rem;
`;

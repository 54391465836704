import styled, { css } from 'styled-components';
import DefaultTextarea from 'react-autosize-textarea';

import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';

interface InputProps {
	disabled?: boolean;
	readOnly?: boolean;
	$error?: boolean;
}

export const Textarea = styled(DefaultTextarea)<InputProps>`
	${({ disabled, readOnly, $error }) => css`
		/* TYPOGRAPHY SETTER */
		${getTypographyCSS(t => t.Paragraph)}

		background-color: ${Colors.white};
		border: 0.12rem solid ${$error ? Colors.text.error : Colors.text.disabled};
		border-radius: 0.4rem;
		padding: 0.8rem 1rem;
		width: 100%;
		min-height: 4rem;
		max-width: 100%;
		transition: 0.1s;
		resize: none;
		max-height: 10rem;
		overflow-y: auto;
		box-sizing: border-box;

		/* REMOVE AUTOFIL BACKGROUND COLOR */
		:-webkit-autofill,
		:-webkit-autofill:hover,
		:-webkit-autofill:focus,
		:-webkit-autofill:active {
			box-shadow: 0 0 0px 100rem white inset !important;
		}

		::placeholder,
		::-webkit-input-placeholder {
			color: ${$error ? Colors.text.error : Colors.text.caption};
		}
		:focus {
			outline: unset;
			outline-offset: 0;
			border-color: ${Colors.primary.normal};
		}

		${readOnly &&
		css`
			background-color: ${Colors.background.disabled};

			:focus {
				border-color: ${Colors.text.disabled};
				background-color: ${Colors.background.disabled};
			}
		`};

		${disabled &&
		css`
			pointer-events: none;
			background-color: ${Colors.background.disabled};
			border: 0.12rem solid ${Colors.background.disabled};

			color: ${Colors.text.disabled};

			::placeholder,
			::-webkit-input-placeholder {
				color: ${Colors.text.disabled};
			}
		`};
	`};
`;

import { useMemo, useState } from 'react';
import countries from 'i18n-iso-countries';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';

import { CustomisableTable } from 'components/UI/CustomisableTable';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { UserCard } from 'components/UI/UserCard';
import { CancelUserInvitePayload } from 'components/Account';

import { Colors, Svgs } from 'environment';

import {
	GenericMap,
	TableName,
	TableFilterType,
	CustomCellType,
	CustomisableTableColumn,
	CustomisableTableRow,
	FilterByTypeComponent
} from 'types/index';
import {
	SubscriptionStatus,
	SubscriptionUser,
	UserLicenceLabels,
	UserLicenceModel,
	UserRole,
	UserRoleLabel,
	UserStatus,
	UserStatusLabel
} from 'store/account/subscription';
import { SubscriptionUsersMoreDropdown } from './SubscriptionUsersMoreDropdown';
import { DeleteSubscriptionUserModal, EditSubscriptionUserTab } from '../Modals';
import { RoleTag } from './SubscriptionUsersList.style';
import {
	useTranslation,
	useFilterBySearchTerm,
	useAccount,
	useSubscriptionBundleFlag
} from 'hooks/store';
import { DisabledTextComponent } from 'helpers/table';
import { getRandomColorByRoleName } from 'components/Account/EnterpriseRoles/helpers';
import { notEmpty } from 'helpers/arrays';
countries.registerLocale(countriesEnLocale);

interface SubscriptionUsersListProps {
	isOwner: boolean;
	isLedidiEnterprise: boolean;
	users: SubscriptionUser[];
	ownerAssignedToLicence: boolean;
	onCancelUserInvitation: ({ id, email, licenceModel }: CancelUserInvitePayload) => void;
	allowEditEnterpriseUser: (serRole: UserRole) => boolean;
	onEditUser: (user: SubscriptionUser, selectedTab?: number) => void;
	onResetPassword: (userId: string) => void;
	onDeactivateEnterpriseUser: (userId: string) => void;
	onReactivateEnterpriseUser: (userId: string) => void;
}

export function SubscriptionUsersList({
	isOwner,
	isLedidiEnterprise,
	users,
	ownerAssignedToLicence,
	allowEditEnterpriseUser,
	onEditUser,
	onCancelUserInvitation,
	onResetPassword,
	onDeactivateEnterpriseUser,
	onReactivateEnterpriseUser
}: SubscriptionUsersListProps) {
	const { translate } = useTranslation();
	const [userToRemove, setUserToRemove] = useState<{
		id: string;
		fullName: string;
		licenceModel: UserLicenceModel;
	}>();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const filteredUsersBySearchTerm = useFilterBySearchTerm(
		users,
		FilterByTypeComponent.SubscriptionUsers
	) as SubscriptionUser[];

	const [
		{
			data: { details }
		}
	] = useAccount();

	const [activeDropownByUserId, setActiveDropownByUserId] = useState<string | null>(null);

	function onDropdownVisible(userId: string | null) {
		setActiveDropownByUserId(userId);
	}

	function userNameComponent(user: SubscriptionUser, isPending: boolean, isInactive: boolean) {
		const ownerNotAppendedToLicence = !ownerAssignedToLicence && user.isOwner;

		return (
			<UserCard.Table
				userId={isPending ? null : user.userid}
				userData={{
					userFirstName: isPending
						? translate(({ errors }) => errors.general.notAvailable)
						: user.userFirstName,
					userSirName: isPending ? '' : user.userSirName,
					emailAddress: user.emailAddress,
					imageURL: user.userid === details?.userid ? details.imageURL : '',
					imageString: user.userid === details?.userid ? details.imageString : ''
				}}
				isSelf={user.userid === details?.userid}
				isOwner={user.isOwner}
				disabled={ownerNotAppendedToLicence || isInactive}
			/>
		);
	}

	function getRoleTagColor(role?: UserRole) {
		//default user role color
		if (role === UserRole.OrganizationAdmin) {
			return Colors.avatar.mutedPink;
		}
		if (role === UserRole.Admin) {
			return Colors.vibrantGreen;
		}
		if (role === UserRole.SuperAdmin) {
			return Colors.notificationBlue;
		}
		if (role === UserRole.User) {
			return Colors.avatar.darkCream;
		} else {
			return getRandomColorByRoleName(role);
		}
	}

	const [actionCellByRowId, setActionCellByRowId] = useState<GenericMap<JSX.Element>>({});
	const [customCells, setCustomCells] = useState<CustomCellType>({});

	// populate tableItems, actionCellByRowId and customCells with data from subscription users
	const tableItems = useMemo(() => {
		const items: CustomisableTableRow[] = [];
		filteredUsersBySearchTerm.forEach(user => {
			const isPending =
				user.subscriptionStatus === SubscriptionStatus.Pending ||
				user.subscriptionStatus === SubscriptionStatus.ChangePlanInProgress;
			const isLicenceDeactivated =
				!!(user.status && user.status === UserStatus.Inactive) ||
				user.subscriptionStatus === SubscriptionStatus.Inactive;

			// for pending users, no value is set so that sorting and filtering would not be possible
			const item = {
				id: user.userid,
				name:
					!user.userFirstName && !user.userSirName
						? user.emailAddress
						: user.userFirstName + ' ' + user.userSirName,
				email: user.emailAddress,
				phoneNumber: isPending ? '' : user.phoneNumber,
				organization: isPending ? '' : user.organization ?? '',
				suborganization: isPending ? '' : user.subOrganization ?? '',
				workplace: isPending ? '' : user.workplace ?? '',
				country: isPending ? '' : countries.getName(user.country, 'en'),
				city: isPending ? '' : user.city,
				position: isPending ? '' : user.position,
				department: isPending ? '' : user.department,
				licence: isPending ? '' : translate(() => UserLicenceLabels[user.licenceModel]),
				userRole: isPending
					? ''
					: user.userRole && UserRoleLabel[user.userRole]
					? translate(() =>
							user.userRole ? UserRoleLabel[user.userRole] ?? user.userRole : ''
					  )
					: user.userRole,
				status: isPending
					? ''
					: translate(() => (user.status ? UserStatusLabel[user.status] : ''))
			};
			items.push(item);

			// adding custom cell for user's name containing a UserCard
			const customCell: GenericMap<string | JSX.Element> = {};
			customCell.name = userNameComponent(user, isPending, isLicenceDeactivated);

			// adding custom cell 'not available' text for pending users
			if (isPending || (!ownerAssignedToLicence && user.isOwner) || isLicenceDeactivated) {
				customCell.email = (
					<DisabledTextComponent text={item.email} showNotAvailable={isPending} />
				);
				customCell.country = (
					<DisabledTextComponent text={item.country} showNotAvailable={isPending} />
				);
				customCell.city = (
					<DisabledTextComponent text={item.city} showNotAvailable={isPending} />
				);
				customCell.phoneNumber = (
					<DisabledTextComponent text={item.phoneNumber} showNotAvailable={isPending} />
				);
				customCell.organization = (
					<DisabledTextComponent text={item.organization} showNotAvailable={isPending} />
				);
				customCell.suborganization = (
					<DisabledTextComponent
						text={item.suborganization}
						showNotAvailable={isPending}
					/>
				);
				customCell.workplace = (
					<DisabledTextComponent text={item.workplace} showNotAvailable={isPending} />
				);
				customCell.position = (
					<DisabledTextComponent text={item.position} showNotAvailable={isPending} />
				);
				customCell.department = (
					<DisabledTextComponent text={item.department} showNotAvailable={isPending} />
				);
				customCell.licence = (
					<DisabledTextComponent text={item.licence} showNotAvailable={isPending} />
				);
				customCell.status = (
					<DisabledTextComponent text={item.status} showNotAvailable={isPending} />
				);

				customCell.userRole = (
					<DisabledTextComponent text={item.userRole} showNotAvailable={true} />
				);
			} else {
				customCell.userRole = (
					<RoleTag roleColor={getRoleTagColor(user.userRole)}>
						<Typography.Paragraph>{item.userRole}</Typography.Paragraph>
					</RoleTag>
				);
			}

			setCustomCells(prev => ({ ...prev, [item.id]: customCell }));

			const showDeleteIcon = isPending;
			const showEnterpriseUserDropdown =
				!isPending &&
				user.userRole &&
				allowEditEnterpriseUser(user.userRole) &&
				user.userid !== details?.userid;
			const showEditUser =
				!showEnterpriseUserDropdown &&
				isOwner &&
				(user.userid !== details?.userid || subscriptionBundleFlag);
			// adding edit/remove user invitation button
			// at the end of each row for the owner of the subscription
			if (isOwner || (user.userRole && allowEditEnterpriseUser(user.userRole))) {
				setActionCellByRowId(prev => ({
					...prev,
					[item.id]: showDeleteIcon ? (
						<Icon
							svg={Svgs.Delete}
							title={translate(
								({ accountUM }) => accountUM.subscription.removeUserInvitation
							)}
							onClick={() =>
								onCancelUserInvitation({
									id: user.userid,
									email: user.emailAddress,
									licenceModel: user.licenceModel
								})
							}
							size={s => s.m}
						/>
					) : showEnterpriseUserDropdown ? (
						<SubscriptionUsersMoreDropdown
							isLedidiEnterprise={isLedidiEnterprise}
							isLicenceDeactivated={isLicenceDeactivated}
							onDropdownVisible={(visible: boolean) =>
								onDropdownVisible(visible ? user.userid : null)
							}
							onEditUser={() => onEditUser(user, EditSubscriptionUserTab.Information)}
							onChangeRole={() => onEditUser(user, EditSubscriptionUserTab.Roles)}
							onRemoveLicence={() =>
								setUserToRemove({
									id: user.userid,
									fullName: user.userFirstName + ' ' + user.userSirName,
									licenceModel: user.licenceModel
								})
							}
							onResetPassword={() => onResetPassword(user.userid)}
							onDeactivateEnterpriseUser={() =>
								onDeactivateEnterpriseUser(user.userid)
							}
							onReactivateEnterpriseUser={() =>
								onReactivateEnterpriseUser(user.userid)
							}
						/>
					) : showEditUser ? (
						<Icon svg={Svgs.Edit} onClick={() => onEditUser(user)} size={s => s.m} />
					) : (
						<></>
					)
				}));
			}
		});

		return items;
	}, [
		filteredUsersBySearchTerm,
		ownerAssignedToLicence,
		isLedidiEnterprise,
		isOwner,
		subscriptionBundleFlag
	]);

	const showOrganization = !!filteredUsersBySearchTerm.find(user => !!user.organization);
	const showSuborganization = !!filteredUsersBySearchTerm.find(user => !!user.subOrganization);

	const enterpriseLicenceColumns: CustomisableTableColumn[] = [
		{
			name: 'name',
			label: translate(({ accountUM }) => accountUM.userDetails.name),
			filterType: TableFilterType.Text
		},
		{
			name: 'email',
			label: translate(({ accountUM }) => accountUM.userDetails.email),
			filterType: TableFilterType.Text
		},
		showOrganization
			? {
					name: 'organization',
					label: translate(({ accountUM }) => accountUM.userDetails.organization),
					isOptional: true,
					filterType: TableFilterType.Checkbox
			  }
			: null,
		showSuborganization
			? {
					name: 'suborganization',
					label: translate(({ accountUM }) => accountUM.userDetails.suborganization),
					isOptional: true,
					filterType: TableFilterType.Checkbox
			  }
			: null,
		{
			name: 'status',
			label: translate(({ accountUM }) => accountUM.userDetails.status),
			filterType: TableFilterType.Checkbox,
			isOptional: true
		},
		{
			name: 'userRole',
			label: translate(({ accountUM }) => accountUM.userDetails.role),
			isOptional: true,
			filterType: TableFilterType.Checkbox
		},
		{
			name: 'country',
			label: translate(({ accountUM }) => accountUM.userDetails.country),
			isOptional: true,
			filterType: TableFilterType.Checkbox
		},
		{
			name: 'city',
			label: translate(({ accountUM }) => accountUM.userDetails.city),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'phoneNumber',
			label: translate(({ accountUM }) => accountUM.userDetails.phoneNumber),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'position',
			label: translate(({ accountUM }) => accountUM.userDetails.position),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'department',
			label: translate(({ accountUM }) => accountUM.userDetails.department),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'licence',
			label: translate(({ accountUM }) => accountUM.userDetails.licenceModel),
			isOptional: true,
			filterType: TableFilterType.Checkbox
		}
	].filter(notEmpty);

	const primeLicenceColumns: CustomisableTableColumn[] = [
		{
			name: 'name',
			label: translate(({ accountUM }) => accountUM.userDetails.name),
			filterType: TableFilterType.Text
		},
		{
			name: 'email',
			label: translate(({ accountUM }) => accountUM.userDetails.email),
			filterType: TableFilterType.Text
		},
		{
			name: 'workplace',
			label: translate(({ accountUM }) => accountUM.userDetails.workplace),
			isOptional: true,
			filterType: TableFilterType.Checkbox
		},
		{
			name: 'country',
			label: translate(({ accountUM }) => accountUM.userDetails.country),
			isOptional: true,
			filterType: TableFilterType.Checkbox
		},
		{
			name: 'city',
			label: translate(({ accountUM }) => accountUM.userDetails.city),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'phoneNumber',
			label: translate(({ accountUM }) => accountUM.userDetails.phoneNumber),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'position',
			label: translate(({ accountUM }) => accountUM.userDetails.position),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'department',
			label: translate(({ accountUM }) => accountUM.userDetails.department),
			isOptional: true,
			filterType: TableFilterType.Text
		},
		{
			name: 'licence',
			label: translate(({ accountUM }) => accountUM.userDetails.licenceModel),
			isOptional: true,
			filterType: TableFilterType.Checkbox
		}
	];

	const initialCoreVisibleColumns = ['name', 'email', 'workplace', 'country'];
	const initiaEnterpriseVisibleColumns = [
		'name',
		'email',
		'organization',
		'suborganization',
		'status',
		'userRole'
	];

	const initialVisibleColumns = isLedidiEnterprise
		? initiaEnterpriseVisibleColumns
		: initialCoreVisibleColumns;

	const columns = isLedidiEnterprise ? enterpriseLicenceColumns : primeLicenceColumns;

	return (
		<>
			<CustomisableTable
				tableName={TableName.SubscriptionUsers}
				items={tableItems}
				columns={columns}
				itemsCountLabel={translate(dict => dict.tableLists.users)}
				extraCells={actionCellByRowId}
				activeExtraCell={activeDropownByUserId ?? undefined}
				customCells={customCells}
				initVisibleColumns={initialVisibleColumns}
				hasPagination
			/>
			{userToRemove && (
				<DeleteSubscriptionUserModal
					onClose={() => setUserToRemove(undefined)}
					userId={userToRemove.id}
					licenceModel={userToRemove.licenceModel}
					userName={userToRemove.fullName}
				/>
			)}
		</>
	);
}

import { withReset, withProjectId } from 'store/hors';
import initialState from './initialState';
import reducer from './reducer';

const enhancedReducer = withProjectId(withReset(reducer, initialState));

export { enhancedReducer as reducer };
export * from './actions';
export * from './selectors';
export * from './types';

import styled, { css } from 'styled-components';
import { Colors, MediaQueries } from 'environment';

import { FontWeights, Typography } from '../Typography';
import { ModalSizes } from './Modal';

interface ContainerProps {
	modalSize: number;
	fullSizeConfig?: {
		narrow?: boolean;
		centerTitle?: boolean;
	};
	extendedHeight?: boolean;
	minHeight?: number;
}

export const Container = styled.div<ContainerProps>`
	${({ modalSize, fullSizeConfig, extendedHeight, minHeight }) => css`
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 999;
		width: 100%;
		max-width: ${modalSize}rem;
		border-radius: 0.4rem;
		background-color: ${Colors.white};

		${modalSize === ModalSizes.full
			? css`
					height: 100%;
					max-height: 100vh;
					max-width: 100vw;
					border-radius: unset;

					${fullSizeConfig?.narrow &&
					css`
						${fullSizeConfig?.centerTitle &&
						css`
							${Header} h6 {
								text-align: center;
							}

							${Tabs} > div {
								width: 100%;

								::after {
									width: 74%;
								}
							}
						`}

						${TitleContainer} {
							max-width: 65.2rem;
							margin: auto;
						}

						${Tabs}, ${Body}, ${Footer} {
							max-width: 70rem;
							margin: auto;
						}
					`}
			  `
			: css`
					${modalSize === ModalSizes.s &&
					css`
						${Body} {
							overflow: unset;
						}
					`}

					${modalSize === ModalSizes.m &&
					css`
						height: 100%;
					`}

					@media (max-width: ${modalSize * 10 + 100}px) {
						max-width: 100%;
					}

					@media${MediaQueries.minWidth.md} {
						max-height: 70.8rem;
					}

					@keyframes enterAnimationModal {
						from {
							transform: translate(0, 0.4rem);
						}
						to {
							transform: unset;
						}
					}
					animation: enterAnimationModal 0.15s;
			  `}

		${extendedHeight &&
		css`
			@media${MediaQueries.minWidth.md} {
				max-height: 90%;
			}
		`}
		
		${minHeight &&
		css`
			@media${MediaQueries.minWidth.md} {
				min-height: ${minHeight}rem;
			}
		`}
	`}
`;

export const Header = styled.div`
	width: 100%;
	min-height: 6rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1.6rem 2.4rem;
	position: relative;

	.modal-header__close-icon {
		position: absolute;
		top: 1.6rem;
		right: 2.4rem;
	}
`;

export const TitleContainer = styled.div`
	width: 100%;
	/* in order for ellipsis to work */
	min-width: 0;
	/* give "close-icon" some treshold when ellipsis hits on "modal-title" */
	padding-right: 2.4rem;
`;

interface FooterProps {
	spaceBetween?: boolean;
	fullSizeConfig?: boolean;
}

export const Footer = styled.div<FooterProps>`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem;
	${({ spaceBetween }) =>
		spaceBetween &&
		css`
			justify-content: space-between;
		`}
	${({ fullSizeConfig }) =>
		fullSizeConfig &&
		css`
			padding-left: 3.6%;
			padding-right: 3.6%;
		`}
`;

export const Body = styled.div<{ bodySpacing: boolean; fullSizeConfig: boolean }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: auto;

	/* for Firefox */
	min-height: 0;

	${({ bodySpacing }) =>
		bodySpacing &&
		css`
			padding: 2.4rem;
		`}
	${({ fullSizeConfig }) =>
		fullSizeConfig &&
		css`
			padding-left: 2.8%;
			padding-right: 2.8%;
		`}
`;

export const Tabs = styled.div<{ centerTabs?: boolean }>`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 2.4rem;

	${({ centerTabs }) =>
		centerTabs &&
		css`
			justify-content: space-around;
		`}
`;

interface TabProps {
	centerTabs?: boolean;
	active?: boolean;
	disabled?: boolean;
}

export const Tab = styled.div<TabProps>`
	${({ active, disabled, centerTabs }) => css`
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		height: 4rem;
		min-width: 14rem;
		width: 14rem;
		transition: 0.1s;
		position: relative;
		text-decoration: none;
		user-select: none;

		::after {
			display: block;
			content: '';
			width: 6.8rem;
			height: 0.2rem;
			border-radius: 0 0 0.4rem 0.4rem;
			background-color: ${Colors.primary.normal};
			position: absolute;
			bottom: 0;
			opacity: 0;
		}

		${centerTabs &&
		css`
			&::after {
				width: 180%;
			}
		`}

		${active &&
		css`
			text-decoration: none;

			&::after {
				opacity: 1;
			}

			${Typography.Paragraph} {
				color: ${Colors.primary.normal};
				font-weight: ${FontWeights.medium};
			}
		`}

		:hover {
			border-color: ${Colors.primary.normal}!important;
			text-decoration: none;

			${Typography.Paragraph} {
				color: ${Colors.primary.normal};
			}
		}

		&:visited {
			${!active &&
			css`
				border-color: ${Colors.transparent};
				text-decoration: none;
			`}
		}

		${disabled &&
		css`
			cursor: unset;

			${Typography.Paragraph} {
				color: ${Colors.text.disabled};
			}

			:hover {
				${Typography.Paragraph} {
					color: ${Colors.text.disabled};
				}
			}
		`}
	`}
`;

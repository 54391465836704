import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { nanoid as generate } from 'nanoid';

import {
	ApiForm,
	ApiFormElement,
	ApiFormGroup,
	ApiFormNEW,
	ApiFormElements,
	ApiFormGroupElements,
	ApiFormSet,
	ApiFormsData
} from 'api/data/forms';
import { Variable, VariableSet } from 'api/data/variables';
import { hasMatches } from 'helpers/strings';
import {
	FormElement,
	FormGroup,
	FormGroups,
	Form,
	FormElements,
	FormElementsOrder,
	FormSet,
	FormSets,
	FormsData,
	FormElementDisplayType
} from 'store/data/forms';
import { GroupData, VariableSetData } from 'store/data/variables';
import { ElementType, ChoiceType, OrientationType, DragAndDropTypes } from 'types/index';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';

export function computeVariableTypeAndOrientation(variable: Variable) {
	const { type, optimizeForManyValues, entryType } = variable;

	const isCalculated = entryType === EntryVariableType.Calculated;
	const isRadioGroup = type === VariableType.Category;
	const isCheckboxGroup = type === VariableType.CategoryMultiple;
	const isDropdown = !!optimizeForManyValues;
	const isFile = type === VariableType.File;

	let elementType = ElementType.Input;
	let orientation: OrientationType | undefined;

	if (!isCalculated) {
		// DROPDOWN
		if (isDropdown) {
			elementType = ElementType.Dropdown;
		} else {
			// CHECKBOX
			if (isCheckboxGroup) {
				elementType = ElementType.Checkboxes;
				orientation = OrientationType.Horizontal;
			}
			// RADIO
			if (isRadioGroup) {
				elementType = ElementType.Radiobuttons;
				orientation = OrientationType.Horizontal;
			}
		}
	}

	if (isFile) {
		elementType = ElementType.File;
	}

	return { elementType, orientation };
}

export function computeFormElementFromVariable(variable: Variable): FormElement {
	const { elementType, orientation } = computeVariableTypeAndOrientation(variable);
	const isCategoryVariable =
		variable.type === VariableType.CategoryMultiple || variable.type === VariableType.Category;

	const formElement: FormElement = {
		elementId: 'var-' + generate(),
		elementType,
		...(isCategoryVariable && { displayType: FormElementDisplayType.LABELS }),
		orientation,
		label: variable.label,
		variableRef: variable.name,
		choiceType: ChoiceType.SingleChoice
	};

	return formElement;
}

export function computeFormGroupFromGroupData(groupData: GroupData) {
	const { groupName, groupLabel, groupVariables } = groupData;

	const groupElements: FormElements = {};
	const elementsOrder: FormElementsOrder = [];

	groupVariables.forEach(variable => {
		const formElement = computeFormElementFromVariable(variable);

		groupElements[formElement.elementId] = formElement;
		elementsOrder.push(formElement.elementId);
	});

	const formGroup: FormGroup = {
		groupId: `${DragAndDropTypes.DraggableFormGroup}${groupName}`,
		groupName,
		groupLabel,
		elementsOrder
	};

	return { formGroup, groupElements };
}

export function computeFormSetFromVariableSetData(
	variableSetData: VariableSetData | VariableSet
): FormSet {
	const { setName, setLabel } = variableSetData;

	const setId = `${DragAndDropTypes.DraggableFormSet}${generate()}`;

	const formSet: FormSet = {
		setId,
		setName,
		setLabel
	};

	return formSet;
}

export function parseApiFormElement(element: ApiFormElement): FormElement {
	const formElement: FormElement = { ...element, elementId: generate() };

	const { variableRef } = formElement;

	if (variableRef) {
		formElement.label = formElement.text;
		formElement.elementId = `var-${formElement.elementId}`;
	}
	if (formElement.elementType === ElementType.Separator) {
		delete formElement.text;
	}

	return formElement;
}

export function parseApiFormElements(apiFormElements: ApiFormElements[]) {
	const elements: FormElements = {};
	const groups: FormGroups = {};
	const sets: FormSets = {};
	const elementsOrder: FormElementsOrder = [];

	const usedVariables: string[] = [];
	const usedGroups: string[] = [];
	const usedSets: string[] = [];

	apiFormElements.forEach(rowElements => {
		// ONE COLUMN
		if (rowElements.length < 2) {
			// FORM SET
			if (isApiFormSet(rowElements[0])) {
				const apiFormSet = rowElements[0];

				const setId = `${DragAndDropTypes.DraggableFormSet}${generate()}`;

				const formSet: FormSet = {
					setId,
					setName: apiFormSet.setName,
					setLabel: apiFormSet.setLabel
				};

				sets[formSet.setId] = formSet;
				usedSets.push(formSet.setName);
				elementsOrder.push(formSet.setId);

				return;
			}

			// FORM GROUP
			if (isApiFormGroup(rowElements[0])) {
				const apiFormGroup = rowElements[0];

				/*
					APPLY RECURSIVE FUNCTION
					GET FORM GROUP: `elements`, `elementsOrder` and `usedVariables`
				*/
				const {
					elements: groupElements,
					elementsOrder: groupElementsOrder,
					usedVariables: usedGroupVariables
				} = parseApiFormElements(apiFormGroup.formElements);

				/*
					APPEND ALL GROUP ELEMENTS TO MAIN ELEMENTS MAP
					(HAVE A SINGLE SOURCE OF TRUTH)
				*/
				Object.values(groupElements).forEach(
					groupElement => (elements[groupElement.elementId] = groupElement)
				);

				const groupId = `${DragAndDropTypes.DraggableFormGroup}${generate()}`;

				const formGroup: FormGroup = {
					groupId,
					groupName: apiFormGroup.groupName,
					groupLabel: apiFormGroup.groupLabel,
					elementsOrder: groupElementsOrder
				};

				groups[formGroup.groupId] = formGroup;
				usedGroups.push(formGroup.groupName);
				usedVariables.push(...usedGroupVariables);
				elementsOrder.push(formGroup.groupId);
			}
			// FORM ELEMENT
			else {
				const apiFormElement = rowElements[0];

				const formElement = parseApiFormElement(apiFormElement);

				if (formElement.variableRef !== undefined) {
					usedVariables.push(formElement.variableRef);
				}
				if (formElement.elementType !== ElementType.Title) {
					elements[formElement.elementId] = formElement;
				}
				elementsOrder.push(formElement.elementId);
			}
		}
		// TWO COLUMNS
		else {
			const rowElementIds: string[] = [];

			rowElements.forEach(element => {
				const apiFormElement = element as ApiFormElement;

				const formElement = parseApiFormElement(apiFormElement);

				if (formElement.variableRef !== undefined) {
					usedVariables.push(formElement.variableRef);
				}
				if (formElement.elementType !== ElementType.Title) {
					elements[formElement.elementId] = formElement;
				}
				rowElementIds.push(formElement.elementId);
			});

			elementsOrder.push(rowElementIds);
		}
	});

	return {
		elements,
		groups,
		sets,
		elementsOrder,
		usedVariables,
		usedGroups,
		usedSets
	};
}

export function parseApiForm(apiForm: ApiFormNEW): Form {
	const {
		formId,
		is_active: active,
		formDefinition: { formName = '', formTitle = '', formTitleEnabled = false, formElements }
	} = apiForm;

	const { elements, groups, sets, elementsOrder, usedVariables, usedGroups, usedSets } =
		parseApiFormElements(formElements);

	const form: Form = {
		id: formId.toString(),
		name: formName,
		title: formTitle,
		titleEnabled: formTitleEnabled,
		active,
		elements,
		formErrors: {},
		groups,
		sets,
		elementsOrder,
		usedVariables,
		usedGroups,
		usedSets
	};

	return form;
}

export function parseApiFormsData(apiFormsData: ApiFormsData): FormsData {
	const formsData: FormsData = {
		forms: [],
		formsBySetName: {}
	};

	formsData.forms = apiFormsData.forms.map(apiForm => {
		// MAP OLD STRUCTURE TO NEW
		const newApiForm = parseOldApiForm(apiForm);
		// COMPUTE FORM FOR STORE
		const form = parseApiForm(newApiForm);

		return form;
	});

	Object.entries(apiFormsData.formsBySetName).forEach(([setName, apiForms]) => {
		const forms: Form[] = apiForms.map(apiForm => {
			// MAP OLD STRUCTURE TO NEW
			const newApiForm = parseOldApiForm(apiForm);
			// COMPUTE FORM FOR STORE
			const form = parseApiForm(newApiForm);

			return form;
		});

		formsData.formsBySetName[setName] = forms;
	});

	return formsData;
}

export function prepareApiFormElements(input: {
	elementsOrder: FormElementsOrder;
	elements: FormElements;
	groups: FormGroups;
	sets: FormSets;
}): ApiFormElements[] {
	const { elementsOrder, elements, groups, sets } = input;

	const apiFormElements: ApiFormElements[] = [];

	elementsOrder.forEach(row => {
		// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
		if (Array.isArray(row)) {
			const elementsList: ApiFormElement[] = [];

			row.forEach(elementId => {
				const { label, ...element } = elements[elementId];

				// UPDATE WITH NEW LABEL
				if (element.variableRef && label !== undefined) {
					element.text = label;
				}

				elementsList.push(element);
			});

			apiFormElements.push(elementsList);
		}
		// ONE COLUMN - (FORM ELEMENT / FORM GROUP)
		else {
			const elementId = row;

			const formSet = sets[elementId];

			// FORM SET
			if (formSet) {
				const { setName, setLabel } = formSet;

				const apiFormSet: ApiFormSet = {
					setName,
					setLabel
				};

				apiFormElements.push([apiFormSet]);

				return;
			}

			const group = groups[elementId];

			// FORM GROUP
			if (group) {
				const { groupName, groupLabel, elementsOrder: groupElementsOrder } = group;

				// APPLY RECURSIVE FUNCTION - GET FORM GROUP ELEMENTS
				const apiFormGroupElements = prepareApiFormElements({
					elementsOrder: groupElementsOrder,
					elements,
					groups,
					sets
				}) as ApiFormGroupElements;

				const apiFormGroup: ApiFormGroup = {
					groupName,
					groupLabel: groupLabel,
					formElements: apiFormGroupElements
				};

				apiFormElements.push([apiFormGroup]);
			}
			// FORM ELEMENT
			else {
				const { label, ...element } = elements[elementId];

				// UPDATE WITH NEW LABEL
				if (element.variableRef && label !== undefined) {
					element.text = label;
				}

				apiFormElements.push([element]);
			}
		}
	});

	return apiFormElements;
}

export function prepareApiForm(form: Form): ApiFormNEW {
	const { name, title, titleEnabled, elements, groups, sets, elementsOrder, active } = form;

	const apiFormElements: ApiFormElements[] = prepareApiFormElements({
		elementsOrder,
		elements,
		groups,
		sets
	});

	const apiForm: ApiFormNEW = {
		formId: Number(form.id),
		is_active: active,
		formDefinition: {
			formName: name,
			formTitle: title,
			formTitleEnabled: titleEnabled,
			formElements: apiFormElements
		}
	};

	return apiForm;
}

export function parseOldApiForm(oldApiForm: ApiForm): ApiFormNEW {
	const { formElements } = oldApiForm.formDefinition;

	const apiFormElements: ApiFormElements[] = [];

	// NEW STRUCTURE - ARRAY OF ARRAYS OF OBJECTS
	if (Array.isArray(formElements[0])) {
		const elements = formElements as ApiFormElements[];

		apiFormElements.push(...elements);
	}
	// OLD STRUCTURE - ARRAY OF OBJECTS
	else {
		const elements = formElements as ApiFormElements;

		elements.forEach(element => apiFormElements.push([element]));
	}

	const apiForm: ApiFormNEW = {
		...oldApiForm,
		formDefinition: {
			...oldApiForm.formDefinition,
			formElements: apiFormElements
		}
	};

	return apiForm;
}

export function areMissingVariablesRequired(variables: Variable[], usedFormVariables: string[]) {
	let missingRequired = false;

	for (let index = 0; index < variables.length; index++) {
		const variable = variables[index];

		if (variable.obligatory && !usedFormVariables.includes(variable.name)) {
			missingRequired = true;

			break;
		}
	}

	return missingRequired;
}

export function findElementRowIndex(
	elementId: string,
	elementsOrder: FormElementsOrder
): { rowIndex: number | null } {
	let rowIndex: number | null = null;

	elementsOrder.forEach((row, index) => {
		if (Array.isArray(row)) {
			const elementIsInRow = row.find(rowElementId => rowElementId === elementId);

			if (elementIsInRow) rowIndex = index;
		}
	});

	return { rowIndex };
}

export function isApiFormGroup(
	object: ApiFormElement | ApiFormGroup | ApiFormSet
): object is ApiFormGroup {
	return 'groupName' in object;
}

export function isApiFormSet(
	object: ApiFormElement | ApiFormGroup | ApiFormSet
): object is ApiFormSet {
	return 'setName' in object;
}

export function getFormVariableNames(form: Form): string[] {
	const variableNames: string[] = [];

	const { elements, groups, elementsOrder } = form;

	elementsOrder.flat().forEach(elementOrGroupId => {
		const isGroup = elementOrGroupId.startsWith(DragAndDropTypes.DraggableFormGroup);
		const isSet = elementOrGroupId.startsWith(DragAndDropTypes.DraggableFormSet);

		if (isSet) return;

		if (isGroup) {
			const group = groups[elementOrGroupId];

			const groupVariableNames = getFormGroupVariableNames(group, elements);

			variableNames.push(...groupVariableNames);
		} else {
			const element = elements[elementOrGroupId];

			if (element.variableRef) variableNames.push(element.variableRef);
		}
	});

	return variableNames;
}

export function getFormGroupElementsIds(formGroup: FormGroup): string[] {
	return formGroup.elementsOrder.flat();
}

export function getFormGroupVariableNames(formGroup: FormGroup, elements: FormElements): string[] {
	const variableNames: string[] = [];

	const groupElementsIds = getFormGroupElementsIds(formGroup);

	groupElementsIds.forEach(elementId => {
		const { variableRef: variableName } = elements[elementId];

		if (variableName) variableNames.push(variableName);
	});

	return variableNames;
}

export function isFormElementHalfWidth(element: FormElement) {
	const { elementType, orientation } = element;

	const isInput = elementType === ElementType.Input;
	const isRadio = elementType === ElementType.Radiobuttons;
	const isCheckbox = elementType === ElementType.Checkboxes;
	const isDropdown = elementType === ElementType.Dropdown;
	const isVertical = orientation === OrientationType.Vertical;
	const isFile = elementType === ElementType.File;

	const isHalfWidth = isInput || isDropdown || isFile || ((isRadio || isCheckbox) && isVertical);

	return isHalfWidth;
}

interface RemoveFormGroupProps {
	form: Form;
	groupId: string;
}
export function removeFormGroup({ form, groupId }: RemoveFormGroupProps): Form {
	const draftForm = cloneDeep(form);

	const group = draftForm.groups[groupId];

	const groupElementsIds = getFormGroupElementsIds(group);
	const groupVariablesNames = getFormGroupVariableNames(group, draftForm.elements);

	const filteredUsedVariables = draftForm.usedVariables.filter(
		usedVariable => !groupVariablesNames.includes(usedVariable)
	);

	// REMOVE USED GROUP VARIABLES FROM USED VARIABLES
	draftForm.usedVariables = filteredUsedVariables;
	// REMOVE GROUP NAME FROM USED GROUPS
	draftForm.usedGroups = draftForm.usedGroups.filter(groupName => groupName !== group.groupName);
	// REMOVE GROUP ID FROM ELEMENTS ORDER
	draftForm.elementsOrder = draftForm.elementsOrder.filter(id => id !== groupId);
	// REMOVE GROUP ELEMENTS
	groupElementsIds.forEach(elementId => delete draftForm.elements[elementId]);
	// REMOVE GROUP
	delete draftForm.groups[groupId];

	return draftForm;
}

interface RemoveFormSetProps {
	form: Form;
	setId: string;
}
export function removeFormSet({ form, setId }: RemoveFormSetProps): Form {
	const draftForm = cloneDeep(form);

	const variableSet = draftForm.sets[setId];

	// REMOVE SET NAME FROM USED GROUPS
	draftForm.usedSets = draftForm.usedSets.filter(setName => setName !== variableSet.setName);
	// REMOVE SET ID FROM ELEMENTS ORDER
	draftForm.elementsOrder = draftForm.elementsOrder.filter(id => id !== setId);
	// REMOVE SET
	delete draftForm.sets[setId];

	return draftForm;
}

interface RemoveFormElementProps {
	form: Form;
	elementId: string;
	groupId?: string;
}
export function removeFormElement({ form, elementId, groupId }: RemoveFormElementProps): Form {
	const draftForm = cloneDeep(form);

	const { elements, groups } = draftForm;
	const { variableRef } = elements[elementId];

	let elementsOrder = draftForm.elementsOrder;

	if (groupId) elementsOrder = groups[groupId].elementsOrder;

	// REMOVE IN CASE OF BASIC ELEMENT OR VARIABLE NOT INSIDE ROW
	const { rowIndex } = findElementRowIndex(elementId, elementsOrder);

	// IN ROW
	if (rowIndex !== null) {
		// EXTRACT ROW
		const rowToBreak = elementsOrder[rowIndex] as string[];

		// FILTER DELETED ELEMENT
		const remainedElementId = rowToBreak.filter(id => id !== elementId)[0];

		// UPDATE ELEMENTS ORDER
		elementsOrder[rowIndex] = remainedElementId;
	}

	// UPDATE ELEMENTS ORDER
	elementsOrder = elementsOrder.filter(id => id !== elementId);

	if (groupId) {
		groups[groupId].elementsOrder = elementsOrder;
	} else {
		draftForm.elementsOrder = elementsOrder;
	}

	// UPDATE USED VARIABLES LIST
	if (variableRef !== undefined) {
		// REMOVE VARIABLE FROM USED VARIABLES
		draftForm.usedVariables = draftForm.usedVariables.filter(
			usedVariableName => usedVariableName !== variableRef
		);
	}
	// REMOVE ELEMENT
	delete draftForm.elements[elementId];

	return draftForm;
}

export function isElementCombinable({
	provided,
	snapshot
}: {
	provided: DraggableProvided;
	snapshot: DraggableStateSnapshot;
}): boolean {
	return (
		!!snapshot.combineTargetFor &&
		!snapshot.combineTargetFor.includes('Row') &&
		!snapshot.combineTargetFor.includes('group-') &&
		provided.draggableProps['data-rbd-draggable-id'].includes('var-') &&
		!provided.draggableProps['data-rbd-draggable-id'].includes('Row')
	);
}

/**
 * Returns a list of unique variable names used in all forms
 */
export function getUsedVariablesInForms(forms: Form[]): string[] {
	return [...new Set(forms.flatMap(form => form.usedVariables))];
}

/**
 * Returns a list of unique group names used in all forms
 */
export function getUsedGroupsInForms(forms: Form[]): string[] {
	return [...new Set(forms.flatMap(form => form.usedGroups))];
}

/**
 * Returns a list of unique set names used in all forms
 */
export function getUsedVariableSetsInForms(forms: Form[]): string[] {
	return [...new Set(forms.flatMap(form => form.usedSets))];
}

export function isFormNameUnique(
	formName: string,
	{ forms, exclude = [] }: { forms: Form[]; exclude?: string[] }
) {
	formName = formName.trim();

	const formNames: string[] = [];

	forms.forEach(form => {
		if (exclude.includes(form.name)) return;

		formNames.push(form.name);
	});

	return !formNames.includes(formName);
}

export function getFirstActiveForm(forms: Form[]) {
	return forms.find(form => form.active);
}

export function getActiveForms(forms: Form[]) {
	return forms.filter(form => form.active);
}

export function buildForm(input: { name: string; setName?: string }): Form {
	const { name, setName } = input;

	const form: Form = {
		id: `new_form-${generate()}`,
		name: name,
		title: '',
		titleEnabled: true,
		elements: {},
		formErrors: {},
		groups: {},
		sets: {},
		elementsOrder: [],
		usedVariables: [],
		usedGroups: [],
		usedSets: [],
		active: false,
		...(setName !== undefined && {
			setName
		})
	};

	return form;
}

export function filterFormsBySearchTerm(forms: Form[], searchTerm: string) {
	const isSearchTermValid = searchTerm.trim().length > 0;

	if (!isSearchTermValid) return forms;

	function match(form: Form) {
		const keywords: string[] = [form.name, form.title];

		return hasMatches({ searchTerm, keywords });
	}

	return cloneDeep(forms).filter(match);
}

export function filterFormsDataBySearchTerm(formsData: FormsData, searchTerm: string) {
	const isSearchTermValid = searchTerm.trim().length > 0;

	if (!isSearchTermValid) return formsData;

	const filtered: FormsData = {
		forms: filterFormsBySearchTerm(formsData.forms, searchTerm),
		formsBySetName: {}
	};

	Object.keys(formsData.formsBySetName).forEach(setName => {
		filtered.formsBySetName[setName] = filterFormsBySearchTerm(
			formsData.formsBySetName[setName],
			searchTerm
		);
	});

	return filtered;
}

export function formElementsIterator(
	form: Form,
	iterator: {
		element(element: FormElement, index: number): void;
		variable(element: FormElement, index: number): void;
		formGroup(formGroup: FormGroup, index: number): void;
		formSet(formSet: FormSet, index: number): void;
		twoColumn(
			elements: {
				first: FormElement;
				second: FormElement;
			},
			index: number
		): void;
	}
) {
	const { elements, groups, sets } = form;

	form.elementsOrder.forEach((row, index) => {
		// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
		if (Array.isArray(row)) {
			const [firstElementId, secondElementId] = row;

			const firstElement = elements[firstElementId];
			const secondElement = elements[secondElementId];

			iterator.twoColumn(
				{
					first: firstElement,
					second: secondElement
				},
				index
			);
		}
		// ONE COLUMN - (FORM ELEMENT / FORM GROUP / FORM SET)
		else {
			const elementId = row;

			const formSet = sets[elementId];
			const formGroup = groups[elementId];
			const element = elements[elementId];

			if (formSet) iterator.formSet(formSet, index);

			if (formGroup) iterator.formGroup(formGroup, index);

			if (element) {
				const { variableRef } = element;

				// FORM VARIABLE
				if (variableRef) {
					iterator.variable(element, index);
				}
				// FORM ELEMENT (ex: SUBTITLE / TEXT / SEPARATOR)
				else {
					iterator.element(element, index);
				}
			}
		}
	});
}

import { useMemo, useState } from 'react';

import { Variable } from 'api/data/variables';
import { Colors, Svgs } from 'environment';
import { VariableMapping } from 'store/data/variablesMapping';
import { InputType, SelectItem } from 'types/index';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { InfoMessage } from 'components/UI/InfoMessage';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Switch } from 'components/UI/Interactables/Switch';
import { Modal } from 'components/UI/Modal';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input';
import { Select } from 'components/UI/Interactables/Select';
import {
	useTranslation,
	useVariables,
	useVariablesMapping,
	useInitializeVariablesMapping,
	useEnableVariableMapping,
	useDisableVariableMapping,
	useSetVariableMapping,
	useResetVariableMapping
} from 'hooks/store';
import { useEffectOnce, usePaginate } from 'hooks/utils';
import { InfoMessageDecorator } from 'components/Variables/CreateUpdateVariable/AddVariableFields/AddVariableFields.style';

enum FILTER {
	all = 'all',
	default = 'default',
	customised = 'customised'
}

interface Props {
	onClose(success?: boolean): void;
}

export function VariablesMapping({ onClose }: Props) {
	const [context, setContext] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [filterValue, setFilterValue] = useState(FILTER.all);
	const { translate } = useTranslation();

	const [
		{
			data: { variablesMap, variableSets }
		}
	] = useVariables({ initial: true });

	const [
		{
			data: { byId, main, bySetName },
			loading: loadingVariablesMapping,
			fetched: areVariablesMappingFetched
		},
		getVariablesMapping
	] = useVariablesMapping();
	const [{ loading: initializingVariablesMapping }, initializeVariablesMapping] =
		useInitializeVariablesMapping();
	const [{ loading: enablingVariablesMapping }, enableVariablesMapping] =
		useEnableVariableMapping({ setName: context ?? undefined });
	const [{ loading: disablingVariablesMapping }, disableVariablesMapping] =
		useDisableVariableMapping({ setName: context ?? undefined });

	useEffectOnce(() => {
		if (areVariablesMappingFetched) getVariablesMapping();
	});

	const { ids, active, initialized } = useMemo(() => {
		let scope = main;

		if (context) {
			scope = {
				ids: [],
				active: false,
				initialized: false
			};

			if (bySetName[context]) scope = bySetName[context];
		}

		return scope;
	}, [main, bySetName, context]);

	const filteredIds = useMemo(() => {
		let filtered = ids;

		if (sanatize(searchTerm).length > 0) {
			filtered = filtered.filter(id => {
				const variable = variablesMap[id] ?? {};
				const variableMapping = byId[id];

				const keywords = [variable.label, variableMapping.name, variableMapping.customName];

				return keywords.some(keyword => sanatize(keyword).includes(sanatize(searchTerm)));
			});
		}

		if (filterValue === FILTER.default) {
			filtered = filtered.filter(id => {
				const variableMapping = byId[id];

				return variableMapping.name === variableMapping.customName;
			});
		}

		if (filterValue === FILTER.customised) {
			filtered = filtered.filter(id => {
				const variableMapping = byId[id];

				return variableMapping.name !== variableMapping.customName;
			});
		}

		function sanatize(value: string) {
			return value.toLowerCase().trim();
		}

		return filtered;
	}, [ids, byId, variablesMap, searchTerm, filterValue]);

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page,
		changePage,
		changePageSize,
		resetPage
	} = usePaginate(filteredIds, {
		threshold: 25,
		pageSize: 25
	});

	function handleInitialize() {
		if (context) return initializeVariablesMapping({ setName: context });

		initializeVariablesMapping();
	}

	function handleEnabling() {
		const method = active ? disableVariablesMapping : enableVariablesMapping;

		if (context) return method({ setName: context });

		method();
	}

	function handleSetContext(value: string | null) {
		resetPage();
		setContext(value);
	}

	function handleChangeTerm(term: string) {
		resetPage();
		setSearchTerm(term);
	}

	function getContextSelectItems() {
		const items: SelectItem[] = [];

		items.push({
			label: translate(dict => dict.variablesPage.variablesMapping.mainLevel),
			value: ''
		});

		variableSets.forEach(variableSet => {
			const { setLabel, setName } = variableSet;

			items.push({
				label: setLabel,
				value: setName
			});
		});

		return items;
	}

	const contextSelectItems = useMemo(getContextSelectItems, [variableSets]);

	const filter = {
		labels: {
			[FILTER.all]: translate(dict => dict.terms.all),
			[FILTER.default]: translate(dict => dict.terms.default),
			[FILTER.customised]: translate(dict => dict.terms.customised)
		},
		value: filterValue,
		get items() {
			const { labels } = this;

			return [
				{
					label: labels[FILTER.all],
					value: FILTER.all
				},
				{
					label: labels[FILTER.default],
					value: FILTER.default
				},
				{
					label: labels[FILTER.customised],
					value: FILTER.customised
				}
			];
		},
		onChange(value: FILTER) {
			setFilterValue(value);
		}
	};

	return (
		<Modal
			size={s => s.full}
			title={translate(dict => dict.variablesPage.variablesMapping.title)}
			onClose={onClose}
			fullSizeConfig={{
				narrow: true,
				centerTitle: true
			}}
			visible
			close
		>
			<Suspend
				loading={loadingVariablesMapping && !areVariablesMappingFetched}
				immediate={!areVariablesMappingFetched}
			>
				<CreatableSelect
					label={translate(
						dict => dict.variablesPage.variablesMapping.contextSelect.label
					)}
					hint={translate(dict => dict.variablesPage.variablesMapping.contextSelect.hint)}
					items={contextSelectItems}
					value={
						contextSelectItems.find(item => item.value === context) ??
						contextSelectItems[0]
					}
					onValueSelected={value => handleSetContext(value || null)}
					canClear={false}
				/>

				<Spacer size={s => s.m} />

				{!initialized && (
					<Button
						title={translate(dict => dict.variablesPage.variablesMapping.initialise)}
						loading={initializingVariablesMapping}
						onClick={handleInitialize}
					/>
				)}

				{initialized && (
					<>
						<Switch
							label={translate(
								dict =>
									dict.variablesPage.variablesMapping.enableCustomization.label
							)}
							description={translate(
								dict =>
									dict.variablesPage.variablesMapping.enableCustomization
										.description
							)}
							on={active}
							disabled={enablingVariablesMapping || disablingVariablesMapping}
							onChange={handleEnabling}
						/>
						{active && (
							<>
								<Spacer size={s => s.m} />
								<InfoMessageDecorator>
									<InfoMessage
										large={true}
										message={translate(
											dict =>
												dict.variablesPage.variablesMapping.customMapping
										)}
									/>
								</InfoMessageDecorator>
							</>
						)}

						<Spacer size={s => s.m} />

						{ids.length > 0 && (
							<SearchInput
								term={searchTerm}
								onChangeTerm={handleChangeTerm}
								placeholder={translate(dict => dict.terms.search)}
							/>
						)}

						{ids.length === 0 && (
							<Flex
								css={`
									padding: 0.8rem;
									background-color: ${Colors.background.disabled};
									border-radius: 0.8rem;
								`}
							>
								<InfoMessage
									message={translate(
										dict => dict.variablesPage.variablesMapping.infoMessage
									)}
								/>
							</Flex>
						)}
					</>
				)}

				<Spacer size={s => s.l} />

				{shouldPaginate && (
					<Flex marginOffset={{ bottom: 1.6 }}>
						<Pagination
							totalCountLabel={translate(dict => dict.terms.variables)}
							pageIndex={pageIndex}
							pageSize={pageSize}
							pagesCount={pagesCount}
							changePage={changePage}
							changePageSize={changePageSize}
							totalCount={ids.length}
						/>
					</Flex>
				)}

				{ids.length > 0 && (
					<Flex
						css={`
							gap: 1.6rem;
							margin-bottom: 1.6rem;
						`}
					>
						<Typography.Paragraph
							title={translate(dict => dict.variablesPage.variablesMapping.name)}
							fontweight={w => w.medium}
							css={`
								width: 100%;
							`}
						>
							{translate(dict => dict.variablesPage.variablesMapping.name)}
						</Typography.Paragraph>
						<Typography.Paragraph
							title={translate(dict => dict.variablesPage.variablesMapping.api)}
							fontweight={w => w.medium}
							css={`
								width: 100%;
							`}
						>
							{translate(dict => dict.variablesPage.variablesMapping.api)}
						</Typography.Paragraph>
						<Flex
							css={`
								width: 2.4rem;
								min-width: 2.4rem;
							`}
						/>
						<Flex
							css={`
								width: 145%;
								align-items: center;

								.filter {
									width: unset;
									margin-left: 0.8rem;
								}
							`}
						>
							<Typography.Paragraph
								title={translate(
									dict => dict.variablesPage.variablesMapping.customApi
								)}
								fontweight={w => w.medium}
							>
								{translate(dict => dict.variablesPage.variablesMapping.customApi)}
							</Typography.Paragraph>
							<Select
								className="filter"
								type={t => t.Tag}
								title={filter.labels[filter.value]}
								width={12}
								items={filter.items}
								onSelect={item => filter.onChange(item.value as FILTER)}
							/>
						</Flex>
					</Flex>
				)}

				<Gap marginGap={{ bottom: 0.8 }} notLastChild>
					{page.map(id => (
						<VariableMappingField
							key={id}
							variable={variablesMap[id]}
							variableMapping={byId[id]}
							context={context}
						/>
					))}
				</Gap>
			</Suspend>
		</Modal>
	);
}

interface VariableMappingFieldProps {
	variable: Variable;
	variableMapping: VariableMapping;
	context: string | null;
}

function VariableMappingField({ variable, variableMapping, context }: VariableMappingFieldProps) {
	const { translate } = useTranslation();
	const [{ loading: setting, error: errorSetting }, set] = useSetVariableMapping({
		variableName: variableMapping.name
	});

	const [{ loading: resetting, error: errorResetting }] = useResetVariableMapping({
		variableName: variableMapping.name
	});

	const [draft, setDraft] = useState(getInitialDraftValue(variableMapping));

	function getInitialDraftValue(variableMapping: VariableMapping) {
		let value = variableMapping.customName;

		if (variableMapping.name === variableMapping.customName) value = '';

		return value;
	}

	function handleSet() {
		const curatedValue = draft.trim();
		const newDraft = curatedValue === variableMapping.name ? '' : curatedValue;

		setDraft(newDraft);

		const apiValue = curatedValue || variableMapping.name;

		if (variableMapping.customName !== apiValue) {
			set({
				variableName: variableMapping.name,
				customName: apiValue,
				...(context && {
					setName: context
				})
			});
		}
	}

	function formatValue(value: string) {
		return value.replace(/[^\w-]/g, '');
	}

	function errorSettingMessage() {
		if (errorSetting || errorResetting) {
			return translate(
				dict => dict.variablesPage.variablesMapping.errors.couldntChangeVariableName
			);
		}

		return '';
	}

	// TODO: remove when variables are fetched
	if (!variable) return null;

	return (
		<Flex
			css={`
				gap: 1.6rem;

				input {
					height: 3.2rem;
				}

				.custom-API-input {
					width: 145%;
				}
			`}
		>
			<Input type={InputType.Text} title={variable.label} value={variable.label} readOnly />
			<Input
				type={InputType.Text}
				title={variableMapping.name}
				value={variableMapping.name}
				readOnly
			/>
			<Icon svg={Svgs.ArrowLongRight} />
			<Input
				error={errorSettingMessage()}
				className="custom-API-input"
				type={InputType.Text}
				title={draft}
				value={draft}
				placeholder={variableMapping.name}
				readOnly={setting || resetting}
				onChange={e => setDraft(formatValue(e.target.value))}
				onBlur={handleSet}
				onSubmit={handleSet}
			/>
		</Flex>
	);
}

import { KaplanMeierAnalysisV2 } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { KaplanLogRankV2 } from './KaplanLogRankV2';
import { KaplanPatientsTableV2 } from './KaplanPatientsTableV2/KaplanPatientsTableV2';

interface Props {
	analysis: KaplanMeierAnalysisV2;
}

export function KaplanStatisticsV2({ analysis }: Props) {
	const {
		input: {
			statistics: inputStatistics,
			variables: { groupVariable }
		},
		output: { dataset, statistics: outputStatistics },
		options: { showCategoryLabels }
	} = analysis;

	const statistics = {
		patients: {
			visible: inputStatistics.patientsV2,
			results: dataset
		},
		logRank: {
			visible: inputStatistics.logRankV2,
			results: outputStatistics.logRank
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.patients.visible && statistics.patients.results.data && (
				<KaplanPatientsTableV2
					showCategoryLabels={showCategoryLabels}
					dataset={statistics.patients.results.data}
					groupVariable={groupVariable ?? null}
				/>
			)}

			{statistics.logRank.visible && <KaplanLogRankV2 results={statistics.logRank.results} />}
		</Gap>
	);
}

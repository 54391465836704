import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectAnalysisActiveTab,
	setAnalysisActiveTab,
	SetAnalysisActiveTabAction
} from 'store/data/analyses';
import { ActionPayload } from 'store/types';

export function useAnalysisActiveTab(
	analysisId: string
): LocalOperationResult<
	number | undefined,
	(payload: ActionPayload<SetAnalysisActiveTabAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAnalysisActiveTab(state.data.analyses, analysisId));

	function handler(payload: ActionPayload<SetAnalysisActiveTabAction>) {
		dispatch(setAnalysisActiveTab(payload));
	}

	return [data, handler];
}

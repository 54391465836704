import styled from 'styled-components';

import { Colors, MediaQueries, Shadows } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.8rem 1.6rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	margin-bottom: 1.6rem;
	border-radius: 0.4rem;

	@media ${MediaQueries.maxWidth.xl} {
		margin: 0 1.6rem 1.6rem 0;
		width: Calc((100% - 4.8rem) / 3);
	}

	@media ${MediaQueries.maxWidth.lg} {
		width: Calc((100% - 3.2rem) / 2);
	}
	@media ${MediaQueries.maxWidth.sm} {
		width: 100%;
	}
`;

export const Title = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SentGraphsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const DistributionGraphWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	align-content: space-between;
	padding: 0.6rem 0;
`;

interface BarProps {
	marginBottom?: number;
}

export const DistributionStatus = styled.div<BarProps>`
	width: 100%;
	height: 0.4rem;
	background-color: ${Colors.background.disabled};
	border-radius: 0.4rem;
	margin-bottom: ${props => props.marginBottom}rem;
`;

interface DistributionStatusPercentageProps {
	percentage: number;
	color?: string;
}

export const DistributionStatusPercentage = styled.div<DistributionStatusPercentageProps>`
	width: ${props => props.percentage}%;
	height: 4px;
	border-radius: 0.4rem;
	background-color: ${props => props.color};
`;

export const Icon = styled(DefaultIcon)`
	padding: 0.3rem;
	position: relative;
	top: 0.5rem;
`;

import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { useTranslation } from 'hooks/store';
import { WarningContainer } from './PersonalDataWarning.style';
import { Typography } from 'components/UI/Typography';

export enum BackgroundType {
	grey = 'grey',
	white = 'white'
}

export interface PersonalDataWarningProps {
	width?: number;
	bgType?: (type: typeof BackgroundType) => string;
}

export default function PersonalDataWarning({ width, bgType }: PersonalDataWarningProps) {
	const { translate } = useTranslation();

	return (
		<WarningContainer width={width} bgType={bgType}>
			<Icon svg={Svgs.Pdna} marginOffset={{ x: 1.5, y: 0.8 }} />
			<Typography.Paragraph color={Colors.text.hint} marginOffset={{ right: 1.6 }}>
				{translate(dict => dict.personalDataWarning)}
			</Typography.Paragraph>
		</WarningContainer>
	);
}

import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';

interface ContainerProps extends Disabled {
	zIndex: number;
}
export const Container = styled.div<ContainerProps>`
	min-height: 4rem;
	position: relative;
	box-shadow: ${Shadows.normal};
	border-radius: 0.4rem;
	transform: translate3d(0, 0, 0);
	background-color: ${Colors.white};
	z-index: ${({ zIndex }) => zIndex};

	:hover {
		box-shadow: ${Shadows.hover};
	}

	${({ disabled }) => disableableComponent({ disabled })}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0.8rem;
	user-select: none;

	.delete-rule-icon {
		visibility: hidden;
	}

	:hover .delete-rule-icon {
		visibility: visible;
	}
`;

export const HeaderTitle = styled.div`
	display: flex;
	flex: 1;
	min-width: 0;
	margin: 0 0.8rem;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 0.8rem 0.8rem;
`;

/*
 * DRAG-N-DROP
 */

export const LineMarkerHorizontal = styled.div`
	width: 100%;
	height: 0.4rem;
	background: ${Colors.vibrantGreen};
	border-radius: 0.2rem;
	opacity: 0;
`;

interface DropBelow {
	isHovered: boolean;
}
export const DropBelow = styled.div<DropBelow>`
	position: absolute;
	right: 0;
	bottom: -1.2rem;

	width: 100%;
	height: 1.6rem;

	display: flex;
	align-items: center;

	${({ isHovered }) =>
		isHovered &&
		css`
				${LineMarkerHorizontal} {
					opacity: 1
				}
			}
		`}
`;

export const DropAbove = styled(DropBelow)`
	top: -1.2rem;
`;

export const CardPlaceholder = styled.div`
	height: 4rem;
	border-radius: 0.4rem;
	background: ${Colors.background.disabled};
`;

import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { PlotNumericAnalysisV2, PlotNumericScatterV2 } from 'api/data/analyses';
import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { PlotNumericColumnsChartV2 } from './PlotNumericColumnsChartV2';
import { PlotNumericBoxPlotChartV2 } from './PlotNumericBoxPlotChartV2';
import { PlotNumericScatterChartV2 } from './PlotNumericScatterChartV2';

import { ScatterOptions } from '../PlotNumericV2';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import {
	useProject,
	useAnalysisActiveTab,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import {
	getAggregatorVariableNameByAggregationRuleName,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';

function shuffleDataV2(data: PlotNumericScatterV2) {
	return data.map((element, index) => {
		return element.numericValues.map(() => {
			const min = index - 0.18;
			const max = index + 0.18;

			return Number((Math.random() * (max - min) + min).toFixed(2));
		});
	});
}

interface Props extends ScatterOptions {
	analysis: PlotNumericAnalysisV2;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function PlotNumericViewV2({
	analysis,
	variablesData,
	exporting,
	loading,
	line,
	align
}: Props) {
	const [{ data: project }] = useProject();

	const {
		id,
		options: { configPanel, chartLegend = true },
		input: { variables },
		output: { dataset, grouping }
	} = analysis;

	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		series: analysis.input.series,
		omitVariables: []
	});

	const [activeTab] = useAnalysisActiveTab(id);
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const { variablesMap, variableSetsMap } = variablesData;

	const plotNumericColumnsId = ZingChartExportTypes.PlotNumericColumns + id;
	const plotNumericBoxPlotId = ZingChartExportTypes.PlotNumericBoxPlot + id;
	const plotNumericScatterId = ZingChartExportTypes.PlotNumericScatter + id;

	useEffect(() => {
		if (exporting && project) {
			if (activeTab === 0) {
				exportSvg(
					plotNumericColumnsId,
					getExportFileName(
						ExportFileNames.PlotNumericColumnsChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 1) {
				exportSvg(
					plotNumericBoxPlotId,
					getExportFileName(
						ExportFileNames.PlotNumericBoxPlotChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 2) {
				exportSvg(
					plotNumericScatterId,
					getExportFileName(
						ExportFileNames.PlotNumericScatterChart,
						project.projectId,
						project.projectName
					)
				);
			}
		}
	}, [exporting]);

	const shuffledData = useMemo(
		() => shuffleDataV2(dataset.scatter.data ?? []),
		[dataset.scatter]
	);

	const scalesLabels = {
		labelX: selectItemsLabelMap[variables.categoryVariable?.name ?? ''],
		labelY: selectItemsLabelMap[variables.numericVariable?.name ?? '']
	};

	const legendHeader = variables.groupingVariable
		? selectItemsLabelMap[variables.groupingVariable?.name ?? '']
		: undefined;

	const ruleToVariableName = getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const { parsedDataset } = useMemo(() => {
		const parsedDataset = cloneDeep(dataset);

		if (analysis.options.showCategoryLabels) {
			Object.keys(parsedDataset).forEach(group => {
				parsedDataset[group as keyof typeof parsedDataset].data?.forEach(g => {
					if ('group1' in g) {
						const groupVariableName =
							g.group1.variable.name in ruleToVariableName
								? ruleToVariableName[g.group1.variable.name]
								: g.group1.variable.name;
						g.group1.value =
							(variables.categoryVariable &&
								mapVariableCategoryValueToLabel(
									g.group1.value,
									variablesMap[groupVariableName]
								)) ??
							g.group1.value;
					}

					if ('group2' in g && g.group2) {
						const groupVariableName =
							g.group2.variable.name in ruleToVariableName
								? ruleToVariableName[g.group2.variable.name]
								: g.group2.variable.name;
						g.group2.value =
							(variables.groupingVariable &&
								mapVariableCategoryValueToLabel(
									g.group2.value,
									variablesMap[groupVariableName]
								)) ??
							g.group2.value;
					}
				});
			});
		}
		return { parsedDataset };
	}, [dataset, variablesMap, analysis.options.showCategoryLabels]);

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			{activeTab === 0 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.PlotNumericV2}
					plotNumericType={0}
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<PlotNumericColumnsChartV2
							grouping={grouping}
							data={parsedDataset.columns.data ?? []}
							isLegendEnabled={chartLegend}
							errorBar={variables.errorBar}
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<PlotNumericColumnsChartV2
							id={plotNumericColumnsId}
							grouping={grouping}
							data={parsedDataset.columns.data ?? []}
							errorBar={variables.errorBar}
							isForExport
							isLegendEnabled
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 1 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.PlotNumericV2}
					plotNumericType={1}
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<PlotNumericBoxPlotChartV2
							key={plotNumericBoxPlotId}
							activeColumn={activeColumn}
							fullscreen={fullscreen}
							grouping={grouping}
							data={parsedDataset.boxplot.data ?? []}
							isLegendEnabled={chartLegend}
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<PlotNumericBoxPlotChartV2
							key={plotNumericBoxPlotId}
							id={plotNumericBoxPlotId}
							activeColumn={activeColumn}
							fullscreen={fullscreen}
							grouping={grouping}
							data={parsedDataset.boxplot.data ?? []}
							isForExport
							isLegendEnabled
							scalesLabels={scalesLabels}
							legendHeader={legendHeader}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 2 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.PlotNumericV2}
					plotNumericType={2}
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<PlotNumericScatterChartV2
							line={line}
							align={align}
							shuffledData={shuffledData}
							data={parsedDataset.scatter.data ?? []}
							scalesLabels={scalesLabels}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<PlotNumericScatterChartV2
							id={plotNumericScatterId}
							line={line}
							align={align}
							shuffledData={shuffledData}
							data={parsedDataset.scatter.data ?? []}
							isForExport
							scalesLabels={scalesLabels}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}
		</AnalysisViewContainer>
	);
}

import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Container } from './HeaderTitle.style';

interface Props {
	title: string | React.ReactNode;
	component?: React.ReactNode | null;
	bottomSpacing?: boolean;
	hideTitle?: boolean;
}

export function HeaderTitle({ title, component, bottomSpacing = true, hideTitle = false }: Props) {
	return (
		<>
			<Container className="header-title">
				{!hideTitle &&
					(typeof title === 'string' ? <Typography.H3>{title}</Typography.H3> : title)}
				{component}
			</Container>

			{bottomSpacing && <Spacer className="header-title__spacer" size={s => s.m} />}
		</>
	);
}

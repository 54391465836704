import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Typography } from '../Typography';
import { Button } from 'components/UI/Interactables/Button';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 1.6rem;
	flex-wrap: wrap;
`;

export const PageSizeAndCounts = styled.div`
	display: flex;
	align-items: center;
`;

export const PageSize = styled.div`
	display: flex;
	align-items: center;
`;

export const Wrapper = styled.div`
	margin: 0 0.8rem;
`;

export const Counts = styled.div`
	display: flex;
	min-width: 12rem;
`;

export const Controls = styled.div`
	display: flex;
	align-items: center;

	> button:not(:last-child) {
		margin-right: 0.4rem;
		width: unset;
	}
`;

export const Caption = styled(Typography.Caption)`
	padding: 0 1rem;
	font-weight: 500;
	color: ${Colors.text.main};
`;

interface ControlProps {
	active?: boolean;
	disabled?: boolean;
	isPaginationCenteredComponent?: boolean;
}

export const Control = styled(Button)<ControlProps>`
	display: flex;
	align-items: center;
	min-width: 2.4rem;
	min-height: 2.4rem;
	border-radius: 1.2rem;
	padding: 0;
	user-select: none;
	background-color: transparent;

	${({ isPaginationCenteredComponent }) => {
		if (isPaginationCenteredComponent) {
			return css`
				justify-content: center;
				min-width: 4rem;
				min-height: 4rem;
				border-radius: 2rem;
				margin: 0 0.4rem 0 0.8rem;
			`;
		}
	}};

	${({ active, disabled }) => {
		if (active) {
			return css`
				background-color: ${Colors.primary.disabled};

				${Caption} {
					color: ${Colors.primary.normal};
				}
				:hover {
					background-color: ${Colors.primary.disabled};
				}
			`;
		}

		if (!active && !disabled) {
			return css`
				cursor: pointer;

				:hover {
					background-color: ${Colors.background.disabled};
				}
			`;
		}
	}}
`;

import { GenericMap, ImportType, type SelectItem } from 'types';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount
} from 'types/data/projects/types';

import { useEffect, useMemo, useState } from 'react';
import {
	useCollaborators,
	useImport,
	useImportVariableSet,
	useOrganizationById,
	useOrganizationsByCollaborator,
	useTranslation,
	useUploadUrl,
	useUsername,
	useVariables,
	useVariablesData
} from 'hooks/store';
import { useImportTimezoneSettings } from './useImportTimezoneSettings';
import { getScopeVariables } from 'helpers/variables';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';
import { EDITABLE_DATA_TYPES_OPTIONS } from 'consts';
import { usePaginate, useSelector } from 'hooks/utils';
import { matchDateTimeFormat } from 'helpers/projects/imports/importDateAndDatetime';
import {
	excelDateFormat,
	excelDatetimeFormat
} from 'components/Projects/CreateAndImport/PreviewVariables/constants';
import { getHasErrors } from 'helpers/projects/imports/importErrros';
import { PreviewVariable } from 'types/data/projects/import/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import produce from 'immer';
import { usePreviewVariablesElementRefs } from './usePreviewVariablesElementRefs';

const NO_ORGANIZATION_ID = '-1';

type Props = {
	selectedOption: Omit<ImportType, ImportType.Manual>;
	handleFinishImport: () => void;
	handleApiImportErrors?: (
		apiErrorMap: ApiImportErrorsByVariableName,
		errorCountMap: ApiVariableErrorCount
	) => void;
};

export function useImportManager({
	selectedOption,
	handleFinishImport,
	handleApiImportErrors
}: Props) {
	const { translate } = useTranslation();
	const [
		{
			data: { isBinary }
		}
	] = useUploadUrl({ importType: selectedOption });
	const { timezoneDuringImport } = useFlags();
	const { globalTimezone, handleChangeGlobalTimezone, requiresTimezoneSettings } =
		useImportTimezoneSettings();

	/*
	 ** SERIES IMPORT
	 */
	/*
	 ** ASSIGN GROUP STATE
	 */
	useCollaborators({ lazy: false });

	const username = useUsername();
	const userOrganizations = useOrganizationsByCollaborator(username ?? undefined);

	const [showAssignOrganizationModal, setShowAssignOrganizationModal] = useState(false);
	const [assignedOrganizationId, setAssignedOrganizationId] = useState<string | null>(null);

	const assignedOrganization = useOrganizationById(assignedOrganizationId ?? undefined);

	const [
		{
			isImportVariableSet,
			importVariableSetName,
			variableToMatchOnMainLevel,
			columnToMatchWithMainLevel
		},
		{ setVariableToMatchOnMainLevel, setColumnToMatchWithMainLevel }
	] = useImportVariableSet();

	const userOrganizationId = useMemo(() => {
		let userOrganizationId = NO_ORGANIZATION_ID; // NO GROUPS BASE CASE
		if (userOrganizations.length === 1) {
			userOrganizationId = userOrganizations[0].id;
		}
		if (userOrganizations.length > 1) {
			userOrganizationId = assignedOrganizationId as string;
		}

		return userOrganizationId;
	}, [userOrganizations, assignedOrganizationId]);

	const [destinationSetName, setDestinationSetName] = useState<string | null>(null);

	useEffect(() => {
		const assignedOrgIdCheck = assignedOrganizationId;

		const shouldAssignGroup =
			!isImportVariableSet && userOrganizations.length > 1 && !assignedOrgIdCheck;
		if (!showAssignOrganizationModal && shouldAssignGroup) {
			setShowAssignOrganizationModal(true);
		}
	}, [
		assignedOrganization,
		showAssignOrganizationModal,
		userOrganizations,
		isImportVariableSet,
		assignedOrganizationId
	]);

	/*
	 ** DATA Computation
	 */

	const [showOnlyErrors, setShowOnlyErrors] = useState(false);

	const [
		{
			data: {
				formattedVariables,
				oldFormattedVariables,
				columnToMatch,
				variableToMatch,
				previousMapping,
				initialSuggestions,
				isExcelOrigin,
				timeZones
			}
		},
		{
			setFormattedVariables,
			setOldFormattedVariables,
			setColumnToMatch,
			setVariableToMatch,
			uploadReplaceAll,
			uploadEntriesToDataset,
			uploadDataToEntries,
			onVariableLabelChange
		}
	] = useImport({
		handleFinishImport,
		...(handleApiImportErrors && {
			callbacks: {
				handleApiImportErrors
			}
		}),
		...(!isImportVariableSet && { projectOrganizationId: userOrganizationId })
	});

	const initialNewVariables = useMemo(() => formattedVariables, []);

	const [
		{
			containerRefs,
			customFormatInputRefs,
			dateInputRefs,
			dateTimeInputRefs,
			currentlyModifyingVarID,
			lastRefs,
			setLastRefs,
			setCurrentlyModifyingVarID,
			getRefs,
			setErrors
		}
	] = usePreviewVariablesElementRefs();

	const previewVariableList = useMemo(() => {
		if (showOnlyErrors) {
			return formattedVariables.filter(
				variable =>
					variable.labelError ||
					variable.typeError ||
					variable.dateFormatError ||
					variable.previewVariableLabelError ||
					variable.timeZoneError ||
					variable.id === currentlyModifyingVarID
			);
		}
		return formattedVariables;
	}, [formattedVariables, showOnlyErrors, currentlyModifyingVarID]);

	/*
	 ** PROJECT VARIABLES
	 */
	const variablesData = useVariablesData({ initial: true });
	const [
		{
			data: { variables, variableSets },
			loading: loadingVariables
		}
	] = useVariables({ initial: true, lazy: true });

	const scopeVariables = useMemo(
		() => getScopeVariables({ variables, variablesData, destinationSetName }),
		[variables, variablesData, destinationSetName]
	);
	const mainLevelVariables = useMemo(
		() =>
			getScopeVariables({
				variables,
				variablesData,
				destinationSetName: null
			}),
		[variables, variablesData]
	);

	const mappedMainLevelVariables = mainLevelVariables
		.filter(
			v =>
				![
					VariableType.Float,
					VariableType.CategoryMultiple,
					VariableType.Date,
					VariableType.DateTime
				].includes(v.type as VariableType)
		)
		.map(variable => ({
			label: variable.label,
			value: variable.name
		}));
	const mappedInitialVariables = initialNewVariables.map(variable => ({
		label: variable.label,
		value: variable.label
	}));
	const dropdownVariables = scopeVariables.filter(
		variable => variable.entryType !== EntryVariableType.Calculated
	);

	const dataTypeSelectItems = EDITABLE_DATA_TYPES_OPTIONS.map(dataType => ({
		label: translate(() => dataType.label),
		value: dataType.value
	}));

	const { dateFormats, dateTimeFormats, suggestedVariableTypes } = useSelector(
		state => state.data.projects.import
	);

	/*
	 ** DATE AND DATETIME FORMATS
	 */

	const dateFormatsSelectItems: SelectItem[] = Object.values(dateFormats).map(value => ({
		label: matchDateTimeFormat(value),
		value
	}));

	let dateTimeFormatsSelectItems: SelectItem[] = [];
	if (isExcelOrigin) {
		if (timezoneDuringImport) {
			dateTimeFormatsSelectItems = Object.values(dateTimeFormats)
				.filter(val => val.includes('%z') || val === excelDatetimeFormat)
				.map(value => ({
					label: matchDateTimeFormat(value),
					value
				}))
				.reverse();
		} else {
			dateTimeFormatsSelectItems = Object.values(dateTimeFormats)
				.filter(val => val.includes('%z'))
				.map(value => ({
					label: matchDateTimeFormat(value),
					value
				}));
		}
	} else {
		dateTimeFormatsSelectItems = Object.values(dateTimeFormats).map(value => ({
			label: matchDateTimeFormat(value),
			value
		}));
	}

	const variablesWithDateFormat = useMemo(() => {
		const dateFormatVars: string[] = [];
		const suggestionsWithDateFormat = suggestedVariableTypes?.filter(
			suggestion =>
				(suggestion.suggestedVariableType === VariableType.Date ||
					suggestion.suggestedVariableType === VariableType.DateTime) &&
				suggestion.dateFormat
		);

		suggestionsWithDateFormat &&
			suggestionsWithDateFormat.forEach(suggestion => {
				dateFormatVars.push(suggestion.name);
			});

		return dateFormatVars;
	}, [suggestedVariableTypes, formattedVariables]);

	const duplicationLists = useMemo(() => {
		const duplications: string[][] = [];

		for (let i = 0; i < formattedVariables.length; i++) {
			const variable = formattedVariables[i];
			const duplicate = formattedVariables.filter(
				v =>
					v.previewVariableLabel === variable.previewVariableLabel &&
					v.previewVariableLabel !== ''
			);

			if (duplicate.length > 1 && !duplications.find(v => v.includes(variable.id))) {
				const names: string[] = [];
				for (const v of duplicate) {
					if (!v.isNew) {
						names.push(v.id);
					}
				}

				if (names.length > 1) {
					duplications.push(names);
				}
			}
		}

		return duplications;
	}, [formattedVariables]);

	/*
	 ** ENTRIES TO DATASET -> DROPDOWN VALUES
	 */

	const createNewVariable = translate(
		({ projects }) => projects.createAndImport.generics.entriesToDataset.createLabel
	);
	const formattedDropdownValues = [
		{ label: createNewVariable, value: createNewVariable },
		...dropdownVariables.map(variable => ({
			label: variable.label,
			value: variable.name
		}))
	];

	/*
	 ** HANDLERS
	 */

	function onVariableTypeChanged(varId: string, value: VariableType) {
		let isDateOrDateTime = false;

		const newFormattedVariables = formattedVariables.map(variable => {
			if (variable.id === varId) {
				const updatedVariable: typeof variable = {
					...variable,
					dateFormatError: '',
					timeZone:
						variable.type === VariableType.DateTime ? variable.timeZone : undefined,
					timeZoneError: value === VariableType.DateTime ? variable.timeZoneError : '',
					customDateFormat:
						variable.type === value && variable.customDateFormat
							? variable.customDateFormat
							: '',
					typeError:
						value !== null ? '' : translate(dict => dict.radioGroups.valueRequired),
					type: value
				};

				if (
					[VariableType.Date, VariableType.DateTime].includes(
						updatedVariable.type as VariableType
					)
				) {
					isDateOrDateTime = true;
				}

				if (
					[VariableType.Date, VariableType.DateTime].includes(
						updatedVariable.type as VariableType
					)
				) {
					const foundSuggestion = suggestedVariableTypes
						? suggestedVariableTypes.find(
								suggestion =>
									suggestion.suggestedVariableType === value &&
									(suggestion.name === variable.previewVariableLabel ||
										suggestion.name === variable.label)
						  )
						: null;

					if (foundSuggestion && foundSuggestion.dateFormat) {
						updatedVariable.dateFormat = foundSuggestion.dateFormat;
					} else {
						if (isExcelOrigin) {
							updatedVariable.dateFormat =
								value === VariableType.Date
									? excelDateFormat
									: timezoneDuringImport
									? excelDatetimeFormat
									: '';
							updatedVariable.isExcelDateFormat = true;
						} else {
							updatedVariable.dateFormat = '';
						}

						if (!updatedVariable.timeZone && value === VariableType.DateTime) {
							updatedVariable.timeZone = globalTimezone;

							if (globalTimezone.value === '') {
								updatedVariable.timeZoneError = translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.missingTimeZone
								);
							}
						}
					}
				}

				return updatedVariable;
			}
			return variable;
		});

		setFormattedVariables(newFormattedVariables);
		isDateOrDateTime && setCurrentlyModifyingVarID(varId);
	}

	function handleMainSelectColumnToMatchWithMainLevel(index: number) {
		const initialValue = initialNewVariables[index];
		const newFormattedVariables = [...formattedVariables];

		if (columnToMatchWithMainLevel) {
			const initialValueOfPrevColumn = initialNewVariables.find(
				initialVariable => initialVariable.id === columnToMatchWithMainLevel.id
			);
			initialValueOfPrevColumn &&
				newFormattedVariables.splice(
					initialNewVariables.indexOf(initialValueOfPrevColumn),
					0,
					initialValueOfPrevColumn
				);
		}

		setColumnToMatchWithMainLevel({
			...initialValue,
			index
		});

		newFormattedVariables.forEach(item => {
			if (item.id === initialValue.id) {
				const index = newFormattedVariables.indexOf(item);

				newFormattedVariables.splice(index, 1);
			}
		});

		setFormattedVariables(newFormattedVariables);
	}

	function handleDataToEntriesSelectColumnToMatchWithMainLevel(index: number) {
		const initialValue = initialNewVariables[index];

		// ids to be excluded from preview variables
		const excludedIds = [columnToMatch?.id, initialValue.id].filter(id => !!id);

		// merge initialNewVariables and newVariables but newVariables need to take precedence.
		const filteredVariables: PreviewVariable[] = [];

		initialNewVariables.forEach(initialNewVariable => {
			if (excludedIds.includes(initialNewVariable.id)) {
				return;
			}
			// if newVariable with this id already exists => return it because it contains updated info (name/type);
			const newVariable = formattedVariables.find(
				variable => variable.id === initialNewVariable.id
			);
			if (newVariable) {
				return filteredVariables.push(newVariable);
			}
			// else return initial variable -> we did not currently update this variable or it has been selected right now;
			filteredVariables.push(initialNewVariable);
		});

		setColumnToMatchWithMainLevel({
			...initialValue,
			index
		});

		setFormattedVariables(filteredVariables);
	}

	function onVariablePreviewNameChanged(
		varId: string,
		previewVariableLabel: string,
		variableName: string,
		index: number
	) {
		if (variableName === createNewVariable) {
			const duplicationList = duplicationLists.find(duplications =>
				duplications.includes(varId)
			);

			onVariableLabelChange({
				varId,
				value: previewVariableLabel,
				isPreviewVariable: true,
				isNew: true,
				duplicationList
			});
			return;
		}

		const existingVariableLabels = variables.map(variable => variable.label);

		// Making up for the extra "Create new variable" option at the start of the list
		const selectedVariable = dropdownVariables[index - 1];

		if (selectedVariable && selectedVariable.name === formattedDropdownValues[index].value) {
			const variableLabel = selectedVariable.label;
			const variableType = selectedVariable.type;

			const variableWithDuplicateMapping = formattedVariables.find(
				v => v.previewVariableLabel === variableLabel && v.id !== varId
			);

			let isDateOrDateTime = false;
			setFormattedVariables(
				produce(formattedVariables, draft => {
					draft.forEach(formattedVariable => {
						if (
							formattedVariable.previewVariableLabelError !== '' &&
							!!formattedVariable.previewVariableLabel
						) {
							const duplicationList = duplicationLists.find(duplications => {
								return duplications.includes(formattedVariable.id);
							});

							if (
								!duplicationList &&
								!existingVariableLabels.includes(formattedVariable.label)
							) {
								formattedVariable.previewVariableLabelError = '';
							}

							if (
								duplicationList?.includes(formattedVariable.id) &&
								duplicationList?.includes(varId) &&
								duplicationList?.length === 2
							) {
								formattedVariable.previewVariableLabelError = '';
							}
						}

						if (variableWithDuplicateMapping) {
							if (
								formattedVariable.id === variableWithDuplicateMapping.id &&
								!duplicationLists.find(duplications =>
									duplications.includes(formattedVariable.id)
								)
							) {
								formattedVariable.previewVariableLabelError = translate(
									({ projects }) =>
										projects.createAndImport.generics.previewVariables
											.noDuplicateMapping
								);
							}
						}

						if (formattedVariable.id === varId) {
							if (formattedVariable.type != variableType) {
								formattedVariable.dateFormat = '';
								formattedVariable.customDateFormat = '';

								if (formattedVariable.dateFormatError !== '') {
									formattedVariable.dateFormatError = '';
								}

								if (formattedVariable.timeZoneError !== '') {
									formattedVariable.timeZoneError = '';
								}

								if (formattedVariable.timeZone) {
									formattedVariable.timeZone = undefined;
								}
							}

							if ([VariableType.Date, VariableType.DateTime].includes(variableType)) {
								isDateOrDateTime = true;
							}

							formattedVariable.isNew = false;
							formattedVariable.type = variableType;
							formattedVariable.previewVariableLabel = variableLabel;
							formattedVariable.previewVariableLabelError =
								variableWithDuplicateMapping
									? translate(
											({ projects }) =>
												projects.createAndImport.generics.previewVariables
													.noDuplicateMapping
									  )
									: '';

							formattedVariable.previewVariableName = variableName;

							if (formattedVariable.typeError !== '') {
								formattedVariable.typeError = '';
							}

							const foundSuggestion = initialSuggestions
								? initialSuggestions.find(
										suggestion =>
											suggestion.suggestedVariableType ===
												formattedVariable.type &&
											suggestion.name === formattedVariable.label
								  )
								: null;

							if (foundSuggestion && foundSuggestion.dateFormat) {
								formattedVariable.dateFormat = foundSuggestion.dateFormat;
							} else {
								formattedVariable.dateFormat = '';

								if (formattedVariable.type === VariableType.DateTime) {
									formattedVariable.timeZone = globalTimezone;

									if (globalTimezone.value === '') {
										formattedVariable.timeZoneError = translate(
											({ projects }) =>
												projects.createAndImport.generics.previewVariables
													.missingTimeZone
										);
									}
								}
							}
						}
					});
				})
			);

			isDateOrDateTime && setCurrentlyModifyingVarID(varId);
		}
	}

	function handleClearVariable(varId: string) {
		const duplications = duplicationLists.find(dup => dup.includes(varId));
		const shouldRemoveDuplications = duplications && duplications.length === 2;

		let duplicatedVarId: string | undefined;
		if (shouldRemoveDuplications) {
			duplicatedVarId = duplications.find(dup => dup !== varId);
		}

		setFormattedVariables(
			produce(formattedVariables, draft => {
				draft.forEach(formattedVariable => {
					if (duplicatedVarId && formattedVariable.id === duplicatedVarId) {
						formattedVariable.previewVariableLabelError = '';
					}

					if (formattedVariable.id === varId) {
						formattedVariable.previewVariableLabel = '';
						formattedVariable.previewVariableName = '';
						formattedVariable.previewVariableLabelError = translate(
							({ projects }) =>
								projects.createAndImport.generics.previewVariables
									.missingVariableMapping
						);
						formattedVariable.type = '';
						formattedVariable.dateFormat = '';
						formattedVariable.customDateFormat = '';

						if (formattedVariable.timeZoneError !== '') {
							formattedVariable.timeZoneError = '';
						}

						if (formattedVariable.dateFormatError !== '') {
							formattedVariable.dateFormatError = '';
						}
					}
				});
			})
		);
	}

	/*
	 ** UPLOAD CHECKERS
	 */

	function canUploadReplaceAll() {
		if (isImportVariableSet) {
			return (
				getHasErrors(formattedVariables, isBinary) ||
				!variableToMatchOnMainLevel ||
				!columnToMatchWithMainLevel ||
				formattedVariables.length === 0
			);
		}

		return getHasErrors(formattedVariables, isBinary) || formattedVariables.length === 0;
	}

	const canUploadEntriesToDataset = () => {
		const formatError = getHasErrors(formattedVariables, isBinary);

		if (isImportVariableSet) {
			return (
				formatError ||
				!variableToMatchOnMainLevel ||
				!columnToMatchWithMainLevel ||
				formattedVariables.length === 0
			);
		}

		return formatError || formattedVariables.length === 0;
	};

	function canUploadDataToEntries() {
		if (isImportVariableSet) {
			return (
				getHasErrors(formattedVariables) ||
				!columnToMatch ||
				!variableToMatch ||
				!variableToMatchOnMainLevel ||
				!columnToMatchWithMainLevel ||
				formattedVariables.length === 0
			);
		}

		return (
			getHasErrors(formattedVariables) ||
			!columnToMatch ||
			!variableToMatch ||
			formattedVariables.length === 0
		);
	}

	function computeTitleAndSubtitle() {
		let title = '';
		let subtitle = '';

		if (selectedOption === ImportType.ReplaceAll) {
			title = isImportVariableSet
				? translate(
						({ projects }) => projects.createAndImport.title.replaceAllVariableSet,
						false,
						{
							setLabel: importVariableSetName
						}
				  )
				: translate(({ projects }) => projects.createAndImport.title.replaceAll);
			subtitle = translate(({ projects }) =>
				isImportVariableSet
					? projects.createAndImport.subtitle.replaceAllVariableSet
					: projects.createAndImport.subtitle.replaceAll
			);
		}

		if (selectedOption === ImportType.MoreEntriesToDataset) {
			title = translate(({ projects }) => projects.createAndImport.title.entriesToDataset);
			subtitle = translate(
				({ projects }) => projects.createAndImport.subtitle.entriesToDataset
			);

			if (isImportVariableSet) {
				const isNewVariableSet =
					importVariableSetName &&
					!variableSets.some(
						variableSet => variableSet.setName === importVariableSetName
					);

				const currentVariableSet =
					!isNewVariableSet &&
					variableSets.find(variableSet => variableSet.setName === importVariableSetName);

				const importVariableSetLabel = currentVariableSet
					? currentVariableSet.setLabel
					: importVariableSetName;

				title = translate(
					({ projects }) => projects.createAndImport.title.entriesToVariableSet,
					false,
					{
						setLabel: importVariableSetLabel
					}
				);
				subtitle = translate(
					({ projects }) => projects.createAndImport.subtitle.entriesToVariableSet
				);
			}
		}

		if (selectedOption === ImportType.Now) {
			title = translate(({ projects }) => projects.createAndImport.title.importNow);
			subtitle = translate(({ projects }) => projects.createAndImport.subtitle.importNow);
		}

		if (selectedOption === ImportType.MoreDataToExistingEntries) {
			title = translate(({ projects }) => projects.createAndImport.title.dataToEntries);
			subtitle = translate(({ projects }) => projects.createAndImport.subtitle.dataToEntries);

			if (isImportVariableSet) {
				const isNewVariableSet =
					importVariableSetName &&
					!variableSets.some(
						variableSet => variableSet.setName === importVariableSetName
					);

				const currentVariableSet =
					!isNewVariableSet &&
					variableSets.find(variableSet => variableSet.setName === importVariableSetName);

				const importVariableSetLabel = currentVariableSet
					? currentVariableSet.setLabel
					: importVariableSetName;

				title = translate(
					({ projects }) => projects.createAndImport.title.dataToVariableSet,
					false,
					{
						setLabel: importVariableSetLabel
					}
				);
				subtitle = translate(
					({ projects }) => projects.createAndImport.subtitle.dataToVariableSet
				);
			}
		}
		return { title, subtitle };
	}

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, changePageSize } =
		usePaginate(previewVariableList, {
			threshold: 25,
			pageSize: 25
		});

	const pagination = {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page,
		changePage,
		changePageSize
	};

	const uploadHandlerByType: GenericMap<() => void> = {
		[ImportType.ReplaceAll]: uploadReplaceAll,
		[ImportType.Now]: uploadReplaceAll,
		[ImportType.MoreEntriesToDataset]: uploadEntriesToDataset,
		[ImportType.MoreDataToExistingEntries]: uploadDataToEntries
	};

	const canUploadByType: GenericMap<() => boolean> = {
		[ImportType.ReplaceAll]: canUploadReplaceAll,
		[ImportType.Now]: canUploadReplaceAll,
		[ImportType.MoreEntriesToDataset]: canUploadEntriesToDataset,
		[ImportType.MoreDataToExistingEntries]: canUploadDataToEntries
	};

	const selectColumnToMatchHandlersMap: GenericMap<(index: number) => void> = {
		[ImportType.MoreEntriesToDataset]: handleMainSelectColumnToMatchWithMainLevel,
		[ImportType.ReplaceAll]: handleMainSelectColumnToMatchWithMainLevel,
		[ImportType.MoreDataToExistingEntries]: handleDataToEntriesSelectColumnToMatchWithMainLevel
	};

	return {
		data: {
			main: {
				scopeVariables,
				oldFormattedVariables,
				createNewVariable,
				initialNewVariables,
				columnToMatch,
				variableToMatch,
				formattedVariables,
				timeZones,
				initialSuggestions,
				dataTypeSelectItems,
				variablesWithDateFormat,
				dateFormatsSelectItems,
				dateTimeFormatsSelectItems,
				mappedMainLevelVariables,
				mappedInitialVariables,
				previousMapping,
				loadingVariables,
				globalTimezone,
				formattedDropdownValues,
				duplicationLists,
				isExcelOrigin,
				isBinary
			},
			series: {
				importVariableSetName,
				userOrganizations,
				assignedOrganization,
				showAssignOrganizationModal,
				isImportVariableSet,
				destinationSetName,
				variableToMatchOnMainLevel,
				columnToMatchWithMainLevel
			},
			errors: {
				showOnlyErrors
			},
			refs: {
				containerRefs,
				customFormatInputRefs,
				dateInputRefs,
				dateTimeInputRefs,
				currentlyModifyingVarID,
				lastRefs
			}
		},

		handlers: {
			main: {
				upload: uploadHandlerByType[selectedOption as string],
				canUpload: canUploadByType[selectedOption as string],
				setFormattedVariables,
				setOldFormattedVariables,
				onVariableTypeChanged,
				setVariableToMatch,
				setColumnToMatch,
				onVariableLabelChange,
				onVariablePreviewNameChanged,
				handleChangeGlobalTimezone,
				requiresTimezoneSettings,
				handleClearVariable,
				computeTitleAndSubtitle
			},
			series: {
				setDestinationSetName,
				setShowAssignOrganizationModal,
				setAssignedOrganizationId,
				setVariableToMatchOnMainLevel,
				handleSelectColumnToMatchWithMainLevel:
					selectColumnToMatchHandlersMap[selectedOption as string],
				setColumnToMatchWithMainLevel
			},
			errors: {
				setShowOnlyErrors,
				setErrors
			},
			refs: {
				setLastRefs,
				setCurrentlyModifyingVarID,
				getRefs
			}
		},
		pagination
	};
}

import produce from 'immer';

import initialState from './initialState';
import { Actions, ActionTypes, NewNotification, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_NOTIFICATION_HISTORY: {
			return produce(state, draft => {
				const { notifications, clearPrevious, fullNotificationHistoryFetched } =
					action.payload;
				if (!draft.notificationHistory.byNotificationId || clearPrevious) {
					// replace all notifications with the new ones
					draft.notificationHistory = { byNotificationId: {} };
					draft.fullNotificationHistoryFetched = false;
				}
				notifications.forEach(notif => {
					draft.notificationHistory.byNotificationId = {
						...draft.notificationHistory.byNotificationId,
						[notif.notificationId]: notif
					};
				});
				draft.fullNotificationHistoryFetched = fullNotificationHistoryFetched;
			});
		}

		case ActionTypes.SHOW_ONLY_UNREAD_NOTIFICATIONS: {
			return produce(state, draft => {
				const { showOnlyUnread } = action.payload;
				draft.showOnlyUnread = showOnlyUnread;
			});
		}

		case ActionTypes.SET_NOTIFICATIONS_AS_READ: {
			return produce(state, draft => {
				const { notificationIds, areRead } = action.payload;
				notificationIds.forEach(id => {
					if (
						draft.notificationHistory.byNotificationId &&
						draft.notificationHistory.byNotificationId[id]
					)
						draft.notificationHistory.byNotificationId[id].isRead = areRead;
				});
			});
		}

		case ActionTypes.SET_NOTIFICATION_ACTION_STATE: {
			return produce(state, draft => {
				const { notificationIds, actionState } = action.payload;
				notificationIds.forEach(id => {
					if (
						draft.notificationHistory.byNotificationId &&
						draft.notificationHistory.byNotificationId[id]
					)
						draft.notificationHistory.byNotificationId[id].actionState = actionState;
				});
			});
		}

		case ActionTypes.SET_NEWEST_NOTIFICATIONS_FROM_QUEUE: {
			return produce(state, draft => {
				const { notifications } = action.payload;
				draft.newestNotifications = notifications.slice();
				// remove from the queue list the notifications that are places in
				// the newest notifications list
				const newQueueList: NewNotification[] = [];
				draft.queueNotifications.forEach(n => {
					if (!notifications.find(notif => notif.type === n.type)) {
						newQueueList.push(n);
					}
				});
				draft.queueNotifications = newQueueList;
			});
		}

		case ActionTypes.ADD_QUEUE_NOTIFICATION: {
			return produce(state, draft => {
				const { notification } = action.payload;
				draft.queueNotifications.push(notification);
			});
		}

		case ActionTypes.GET_TOPIC_NAME: {
			return produce(state, draft => {
				const { topicName } = action.payload;
				draft.topicName = topicName;
			});
		}

		case ActionTypes.GET_PROJECT_NOTIFICATION_SETTINGS:
		case ActionTypes.UPDATE_PROJECT_NOTIFICATION_SETTINGS: {
			return produce(state, draft => {
				const { projectId, settings } = action.payload;
				draft.settings.byProjectId = {
					...draft.settings.byProjectId,
					[projectId]: settings
				};
			});
		}

		case ActionTypes.GET_PROJECTS_LABELS: {
			return produce(state, draft => {
				const { projectsLabels } = action.payload;
				draft.projectsLabels = projectsLabels;
			});
		}

		default: {
			return state;
		}
	}
};

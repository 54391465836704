import { Typography } from 'components/UI/Typography';
import { Colors as Colors } from 'environment';
import { StyleOffsets } from 'types/index';

import { Container, TagWrapper } from './UserTag.style';

export enum UserTagType {
	Primary = 'primary',
	Pending = 'pending',
	VibrantGreen = 'vibrantgreen'
}

interface Props {
	title: string;
	className?: string;
	paddingOffset?: StyleOffsets;
	marginOffset?: StyleOffsets;
	type?: (type: typeof UserTagType) => UserTagType;
}

export function UserTag({
	title,
	className,
	paddingOffset,
	marginOffset,
	type = t => t.Primary
}: Props) {
	return (
		<Container className={className}>
			<TagWrapper
				type={type(UserTagType)}
				paddingOffset={paddingOffset}
				marginOffset={marginOffset}
			>
				{type(UserTagType) === UserTagType.VibrantGreen ? (
					<Typography.Caption color={Colors.vibrantGreen}>{title}</Typography.Caption>
				) : (
					<Typography.Hint color={Colors.text.hint}>{title}</Typography.Hint>
				)}
			</TagWrapper>
		</Container>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, resetVariableMapping } from 'store/data/variablesMapping';

type HandlerInput = Parameters<typeof resetVariableMapping>;

export function useResetVariableMapping(activityPayload: {
	variableName: string;
}): Operation<(...input: HandlerInput) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.RESET_VARIABLE_MAPPING,
		activityPayload
	);

	async function handler(...input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		await dispatch(resetVariableMapping(...input));
	}

	return [{ loading, error }, handler];
}

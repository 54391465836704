import { useEffect, useState } from 'react';
import { useLicenceModels } from './useLicenceModels';
import {
	UserLicenceDescriptions,
	UserLicenceLabels,
	UserLicenceModel
} from 'store/account/subscription';
import { BillingPeriodUnit, LedidiPlanCurrency } from 'store/account/payments';
import { useSubscription, useTranslation } from 'hooks/store';
import { GenericMap } from 'types';
import { usePrevious } from 'hooks/utils';

export interface ChangePlanLicenceModel {
	id: string;
	licenceModel: UserLicenceModel;
	count: number;
	minimumCount: number;
	unitPrice: number;
	label: string;
	description: string;
}

export function useChangePlanLicenceQuantities(
	currency: LedidiPlanCurrency,
	billingUnit: BillingPeriodUnit
) {
	const [
		{
			data: { licenceModelsMetadataById, licenceQuantitiesByName }
		}
	] = useLicenceModels();

	const [
		{
			data: {
				subscriptionTypes: { isLedidiCollaborator }
			}
		}
	] = useSubscription();

	const { translate } = useTranslation();

	const [changePlanLicenceModels, setChangePlanLicenceModels] =
		useState<GenericMap<ChangePlanLicenceModel>>();

	useEffect(() => {
		if (licenceModelsMetadataById) {
			let defaultChangePlanLicenceModels = {};
			Object.keys(licenceModelsMetadataById).forEach(licenceKey => {
				if (
					licenceModelsMetadataById[licenceKey].billingUnit === billingUnit &&
					licenceModelsMetadataById[licenceKey].currency === currency
				) {
					const licenceModel = licenceModelsMetadataById[licenceKey].licenceModel;
					defaultChangePlanLicenceModels = {
						...defaultChangePlanLicenceModels,
						[licenceKey]: {
							id: licenceKey,
							licenceModel,
							label:
								translate(
									() => UserLicenceLabels[licenceModel as UserLicenceModel]
								) ?? licenceModel,
							description:
								translate(
									() => UserLicenceDescriptions[licenceModel as UserLicenceModel]
								) ?? licenceModel,
							count:
								licenceQuantitiesByName && licenceQuantitiesByName[licenceModel]
									? licenceQuantitiesByName[licenceModel]
									: licenceModel === UserLicenceModel.Full ||
									  (licenceModel === UserLicenceModel.Collaborator &&
											isLedidiCollaborator)
									? 1
									: 0,
							minimumCount:
								licenceModel === UserLicenceModel.Full ||
								(licenceModel === UserLicenceModel.Collaborator &&
									isLedidiCollaborator)
									? 1
									: 0,
							unitPrice: licenceModelsMetadataById[licenceKey].price
						}
					};
				}
			});
			setChangePlanLicenceModels(defaultChangePlanLicenceModels);
		}
	}, []);

	function changeCount(licenceId: string, count: number) {
		setChangePlanLicenceModels(prev => {
			if (prev && prev[licenceId]) {
				return {
					...prev,
					[licenceId]: {
						...prev[licenceId],
						count: count >= prev[licenceId].minimumCount ? count : 0
					}
				};
			} else return prev;
		});
	}

	function getLicenceModelId(
		currency: LedidiPlanCurrency,
		billingUnit: BillingPeriodUnit,
		licenceModel: UserLicenceModel
	) {
		let foundKey = null;
		Object.keys(licenceModelsMetadataById).forEach(licenceKey => {
			if (
				licenceModelsMetadataById[licenceKey].billingUnit === billingUnit &&
				licenceModelsMetadataById[licenceKey].currency === currency &&
				licenceModelsMetadataById[licenceKey].licenceModel === licenceModel
			)
				foundKey = licenceKey;
		});
		return foundKey;
	}

	useEffect(() => {
		// change id and price
	}, [currency]);

	const prevBillingUnit = usePrevious(billingUnit);
	useEffect(() => {
		// change id and price
		if (
			prevBillingUnit &&
			billingUnit !== prevBillingUnit &&
			licenceModelsMetadataById &&
			changePlanLicenceModels
		) {
			let newChangePlanLicenceModels = {};
			Object.keys(licenceModelsMetadataById).forEach(licenceKey => {
				if (
					licenceModelsMetadataById[licenceKey].billingUnit === billingUnit &&
					licenceModelsMetadataById[licenceKey].currency === currency
				) {
					const licenceModel = licenceModelsMetadataById[licenceKey].licenceModel;
					const prevLicenceModelId = getLicenceModelId(
						currency,
						prevBillingUnit,
						licenceModel
					);

					if (prevLicenceModelId)
						newChangePlanLicenceModels = {
							...newChangePlanLicenceModels,
							[licenceKey]: {
								...changePlanLicenceModels[prevLicenceModelId],
								id: licenceKey,
								unitPrice: licenceModelsMetadataById[licenceKey].price
							}
						};
				}
			});
			setChangePlanLicenceModels(newChangePlanLicenceModels);
		}
	}, [billingUnit, changePlanLicenceModels]);

	return {
		changePlanLicenceModels: changePlanLicenceModels
			? Object.values(changePlanLicenceModels)
			: [],
		changeCount
	};
}

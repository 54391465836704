import styled, { css } from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { MediaQueries } from 'environment';

export const FormTitle = styled(Typography.H3)``;

interface WrapperProps {
	isHistoryPagination?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	padding: 2rem;

	@media ${MediaQueries.maxWidth.md} {
		${({ isHistoryPagination }) => {
			if (isHistoryPagination) {
				return css`
					padding: 2rem 2rem 8.8rem 2rem;
				`;
			}
		}}
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 61.2rem;
	margin: auto;
`;

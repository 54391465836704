import { useEffect, useState } from 'react';

import { ROUTES } from 'types/navigation';
import { Columns, JADBioAnalysis } from 'api/data/analyses';
import { Button } from 'components/UI/Interactables/Button';
import { Colors, MediaQueries } from 'environment';
import { VariablesData } from 'store/data/variables';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { JADBioConfig } from './JADBioConfig/JADBioConfig';
import { JADBioView } from './JADBioView';

import { AnalysisContainer } from '../UI';
import { SubscriptionAddonCode } from 'types/index';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import {
	useJADBioAnalysis,
	useTranslation,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useShowJADBioLogIn,
	useIsUserAddonActive,
	useIsProjectAddonActive,
	useUserAddons,
	useLogInToJADBio,
	useGetJADBioProjectId,
	useEntries,
	usePauseCheckingJADBioProgressAction
} from 'hooks/store';
import { useInitiatigJADBioMessage, useMediaQuery } from 'hooks/utils';

interface Props {
	analysis: JADBioAnalysis;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	exporting: boolean;
}

export function JADBio({ analysis, variablesData, variablesDataSelectItems, exporting }: Props) {
	const {
		input: { model: jadBioModel },
		output: { dataset }
	} = analysis;

	const [{ loading: JADBioAnalysisLoading, error: JADBioAnalysisError }, generateJADBioAnalysis] =
		useJADBioAnalysis(analysis);

	const { translate } = useTranslation();
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const [previousGenerationNotFinished, setPreviousGenerationNotFinished] = useState(false);

	const [, setShowJADBioLoginModal] = useShowJADBioLogIn();

	const [{ data: isJADBioUserAddonActive }] = useIsUserAddonActive(SubscriptionAddonCode.JADBio);

	const [{ data: isJADBioProjectActive }] = useIsProjectAddonActive(SubscriptionAddonCode.JADBio);

	const [
		{
			data: { userAddons }
		}
	] = useUserAddons();

	const [{ data: isLoggedInJadBio, loading: JADBioLoginLoading }] = useLogInToJADBio();

	const { message: initiatingJADBioValidationMessage, retry: retryJADBioInitialization } =
		useInitiatigJADBioMessage(analysis.id);

	const [{ fetched: JADBioProjectIdFetched, loading: JADBioProjectLoading }] =
		useGetJADBioProjectId();

	const { categorySelectItems } = variablesDataSelectItems;

	const [{ data: entries, loading: loadingEntries }] = useEntries();
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const handleFullScreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};

	const pauseCheckingJADBioProgress = usePauseCheckingJADBioProgressAction(analysis.id);

	// Stop checking status when the component is distroyed.
	useEffect(() => {
		return () => {
			pauseCheckingJADBioProgress();
		};
	}, []);

	/**
	 * Resume checking status when user comes back and displays this analysis
	 * Cases when generation of JADBio analysis needs to be resumed:
	 * 1. When user leaves analysis page and comes back later
	 * 2. When user loads a snapshot, and the data was not finished at the time of saving the snapshot
	 * 3. When user relogs in, after token expired during a JADBio analysis generation
	 */
	useEffect(() => {
		if (
			!JADBioAnalysisLoading &&
			jadBioModel &&
			dataset[jadBioModel].progress !== null &&
			JADBioProjectIdFetched
		) {
			setPreviousGenerationNotFinished(true);
		} else if (previousGenerationNotFinished) {
			setPreviousGenerationNotFinished(false);
		}
	}, [JADBioAnalysisLoading, dataset, JADBioProjectIdFetched, jadBioModel]);

	useEffect(() => {
		if (!JADBioAnalysisLoading && previousGenerationNotFinished) {
			generateJADBioAnalysis(true);
		}
	}, [previousGenerationNotFinished, JADBioAnalysisLoading]);

	// Since now we are only using Classification,
	// we verify that there are category variables
	const invalidVariablesValidationText =
		categorySelectItems.length === 0
			? translate(({ analysis }) => analysis.errors.invalidAnalysis.categoryError)
			: null;

	const noDatasetValidationText =
		entries.length === 0 && !loadingEntries
			? translate(({ analysis }) => analysis.errors.invalidAnalysis.missingDatasetError)
			: null;

	const missingUserAddon = !userAddons || !userAddons.includes(SubscriptionAddonCode.JADBio);

	if (!isJADBioProjectActive || !isJADBioUserAddonActive || missingUserAddon) {
		return (
			<Flex column align={c => c.center}>
				<Typography.Paragraph color={Colors.text.error} marginOffset={{ all: 1 }}>
					{missingUserAddon
						? translate(dict => dict.analysis.analyses.JADBio.jadbioMissingUserAddon)
						: !isJADBioUserAddonActive
						? translate(dict => dict.analysis.analyses.JADBio.jadbioInactiveUserAddon)
						: translate(
								dict => dict.analysis.analyses.JADBio.jadbioDesabledProjectAddon
						  )}
				</Typography.Paragraph>
				{!isJADBioUserAddonActive && (
					<Button
						title={translate(dict => dict.accountUM.tabs.addons)}
						onClick={() => window.open(`${ROUTES.AccountUMAddons}`)}
					/>
				)}
			</Flex>
		);
	}

	if (!isLoggedInJadBio)
		return (
			<Flex column>
				<Typography.Paragraph marginOffset={{ bottom: 1 }}>
					{translate(dict => dict.JADBioLogin.logInText)}
				</Typography.Paragraph>
				<Button
					title={translate(dict => dict.JADBioLogin.logInButton)}
					onClick={() => setShowJADBioLoginModal(true)}
				/>
			</Flex>
		);

	if (invalidVariablesValidationText || noDatasetValidationText) {
		return (
			<Flex paddingOffset={{ left: 3 }} style={{ width: 'calc(100% - 27.5rem)' }}>
				<Typography.Paragraph color={Colors.text.error}>
					{invalidVariablesValidationText
						? invalidVariablesValidationText
						: noDatasetValidationText}
				</Typography.Paragraph>
			</Flex>
		);
	}

	if (initiatingJADBioValidationMessage || loadingEntries) {
		return (
			<>
				<Flex justify={j => j.center} fullWidth>
					<Typography.Paragraph>
						{loadingEntries
							? translate(
									({ analysis }) => analysis.errors.invalidAnalysis.loadingEntries
							  )
							: initiatingJADBioValidationMessage}
					</Typography.Paragraph>
				</Flex>
				{retryJADBioInitialization && (
					<Button
						title={translate(dict => dict.buttons.retry)}
						onClick={() => retryJADBioInitialization()}
						marginOffset={{ top: 2 }}
					/>
				)}
			</>
		);
	}

	return (
		<AnalysisContainer onClick={() => handleFullScreen()} activeColumn={activeColumn}>
			<JADBioView
				analysis={analysis}
				loading={JADBioAnalysisLoading}
				error={JADBioAnalysisError}
				exporting={exporting}
				variablesDataSelectItems={variablesDataSelectItems}
				previousGenerationNotFinished={previousGenerationNotFinished}
			/>

			{(analysis.options.configPanel.open &&
				activeColumn === Columns.OneColumn &&
				!isMobileDevice) ||
			(analysis.options.configPanel.open && fullscreen) ? (
				<JADBioConfig
					analysis={analysis}
					variablesData={variablesData}
					generatingAnalysis={JADBioAnalysisLoading}
					variablesDataSelectItems={variablesDataSelectItems}
					loading={JADBioLoginLoading || JADBioProjectLoading || JADBioAnalysisLoading}
					generate={generateJADBioAnalysis}
				/>
			) : null}
		</AnalysisContainer>
	);
}

import type { ApiTemplate } from 'api/data/templates';
import type { Variable } from 'api/data/variables';
import type { Action } from 'types/store/types';
import { TemplatePageViews, type GenericMap } from 'types/index';

export interface DraggableVariable extends Variable {
	draggableId: string;
}

export interface TemplateGroupInterface {
	draggableId: string;
	groupName: string;
	groupLabel: string;
	variables: DraggableVariable[];
	elementsOrder: string[];
}

export interface UserListWithChanges {
	initial: UserAccessInterface[];
	current: UserAccessInterface[];
}

export interface ProjectListWithChanges {
	initial: ProjectAccessInterface[];
	current: ProjectAccessInterface[];
}

export interface Template
	extends Omit<
		ApiTemplate,
		'variablesJson' | 'groupsJson' | 'templateDescription' | 'templateId' | 'createdByUser'
	> {
	variables: DraggableVariable[];
	description: string;
	groups: TemplateGroupInterface[];
	templateId: string;
	elementsOrder: string[];
	totalVariableNumber: number;
	owner: string;
	usersSharedWith: UserListWithChanges;
	projectsSharedWith: ProjectListWithChanges;
}

export interface TemplateSearchType {
	id: string;
	templateName: string;
}

export interface OtherTemplateIdsTypes {
	sharedWithUserIds: string[];
	sharedWithProjectIds: string[];
	sharedWithPublicIds: string[];
}

export type TemplatesById = GenericMap<Template>;

export interface FetchedTemplateIds {
	ids: string[];
	fetched: boolean;
}

export interface UserAccessInterface {
	userId: string;
	accessRead: boolean;
	accessWrite: boolean;
}

export interface ProjectAccessInterface {
	projectId: number;
	accessRead: boolean;
	accessWrite: boolean;
}

export interface State {
	projectId: string | null;
	templateId: string | null;
	sourceCreateTemplateId: string | null;
	templatesById: TemplatesById;
	editableTemplatesIds: string[];
	sharedWithUser: FetchedTemplateIds;
	sharedWithProject: FetchedTemplateIds;
	sharedWithPublic: FetchedTemplateIds;
	metadata: {
		pageView: TemplatePageViews;
		searchTerm: string;
		publishedWithUnsupported: boolean;
	};
}

export enum ActionTypes {
	SET_TEMPLATE_ID = 'data/templates/SET_TEMPLATE_ID',
	SET_SOURCE_CREATE_TEMPLATE_ID = 'data/templates/SET_SOURCE_CREATE_TEMPLATE_ID',
	PUBLISH_TEMPLATE = 'data/templates/PUBLISH_TEMPLATE',
	SHARE_TEMPLATE = 'data/templates/SHARE_TEMPLATE',
	GET_SHARED_WITH = 'data/templates/GET_SHARED_WITH',
	GET_TEMPLATES = 'data/templates/GET_TEMPLATES',
	GET_TEMPLATE = 'data/templates/GET_TEMPLATE',
	UPDATE_TEMPLATE = 'data/templates/UPDATE_TEMPLATE',
	DELETE_TEMPLATE = 'data/templates/DELETE_TEMPLATE',
	GET_PUBLIC_TEMPLATES = 'data/templates/GET_PUBLIC_TEMPLATES',
	GET_PROJECT_TEMPLATES = 'data/templates/GET_PROJECT_TEMPLATES',
	GET_USER_TEMPLATES = 'data/templates/GET_USER_TEMPLATES',
	SET_PAGE_VIEW = 'data/templates/SET_PAGE_VIEW',
	SET_SEARCH_TERM = 'data/templates/SET_SEARCH_TERM',
	BUILD_NEW_BLANK_TEMPLATE = 'data/templates/BUILD_NEW_BLANK_TEMPLATE',
	RENAME_TEMPLATE = 'data/templates/RENAME_TEMPLATE',
	SET_TEMPLATE_DESCRIPTION = 'data/templates/SET_TEMPLATE_DESCRIPTION',
	ADD_VARIABLE_TO_TEMPLATE = 'data/templates/ADD_VARIABLE_TO_TEMPLATE',
	ADD_GROUP_TO_TEMPLATE = 'data/templates/ADD_GROUP_TO_TEMPLATE',
	REMOVE_FROM_TEMPLATE = 'data/templates/REMOVE_FROM_TEMPLATE',
	CREATE_TEMPLATE_FROM_ALL = 'data/templates/CREATE_TEMPLATE_FROM_ALL',
	CREATE_AND_IMPORT_TEMPLATE = 'data/templates/CREATE_AND_IMPORT_TEMPLATE',
	SORT_INSIDE_TEMPLATE = 'data/templates/SORT_INSIDE_TEMPLATE',
	GET_TEMPLATE_LIST_OF_SHARED_WITH = 'data/templates/GET_TEMPLATE_LIST_OF_SHARED_WITH',
	IMPORT_TEMPLATE = 'data/templates/IMPORT_TEMPLATE',
	RESET_PUBLISHED_UNSUPPORTED = 'data/templates/RESET_PUBLISHED_UNSUPPORTED',
	RESET_LISTS_OF_SHARED_WITH = 'data/templates/RESET_LISTS_OF_SHARED_WITH',
	SHARE_WITH_INSTANCE = 'data/templates/SHARE_WITH_INSTANCE',
	UNSHARE_WITH_INSTANCE = 'data/templates/UNSHARE_WITH_INSTANCE'
}

export type SetTemplateIdAction = Action<
	ActionTypes.SET_TEMPLATE_ID,
	{
		templateId: string | null;
	}
>;

export type SetCreateSourceTemplateIdAction = Action<
	ActionTypes.SET_SOURCE_CREATE_TEMPLATE_ID,
	{
		templateId: string | null;
	}
>;

export type SetPageViewAction = Action<
	ActionTypes.SET_PAGE_VIEW,
	{
		pageView: TemplatePageViews;
	}
>;

export type SetSearchTermAction = Action<
	ActionTypes.SET_SEARCH_TERM,
	{
		term: string;
	}
>;

export type PublishTemplateAction = Action<
	ActionTypes.PUBLISH_TEMPLATE,
	{
		templateId: string;
		removedTempId: string;
	}
>;

export type ShareTemplateAction = Action<
	ActionTypes.SHARE_TEMPLATE,
	{
		users: UserAccessInterface[];
		projects: ProjectAccessInterface[];
	}
>;

export type GetSharedWithAction = Action<
	ActionTypes.GET_SHARED_WITH,
	{
		projectId: string;
		templateId: string;
	}
>;

export type GetTemplatesAction = Action<
	ActionTypes.GET_TEMPLATES,
	{
		templates: TemplatesById;
	}
>;

export type GetTemplateAction = Action<
	ActionTypes.GET_TEMPLATE,
	{
		template: Template;
	}
>;

export type GetProjectTemplatesAction = Action<
	ActionTypes.GET_PROJECT_TEMPLATES,
	{
		templates: TemplatesById;
	}
>;

export type GetUserTemplatesAction = Action<
	ActionTypes.GET_USER_TEMPLATES,
	{
		templates: TemplatesById;
		currentUserId: string;
	}
>;

export type GetPublicTemplatesAction = Action<
	ActionTypes.GET_PUBLIC_TEMPLATES,
	{
		templates: TemplatesById;
	}
>;

export type UpdateTemplateAction = Action<
	ActionTypes.UPDATE_TEMPLATE,
	{
		templateId: string;
		shareTemplateGlobally?: boolean;
	}
>;

export type ResetPublishedUnsupportedAction = Action<
	ActionTypes.RESET_PUBLISHED_UNSUPPORTED,
	{
		variablesToDelete: string[];
		avoidUnnecessaryApiCall?: boolean;
	}
>;

export type DeleteTemplateAction = Action<
	ActionTypes.DELETE_TEMPLATE,
	{
		templateId: string;
	}
>;

export type RenameTemplateAction = Action<
	ActionTypes.RENAME_TEMPLATE,
	{
		templateId: string;
		newName: string;
	}
>;

export type ResetListOfSharedWithAction = Action<
	ActionTypes.RESET_LISTS_OF_SHARED_WITH,
	{
		templateId: string;
	}
>;

export type SetTemplateDescriptionAction = Action<
	ActionTypes.SET_TEMPLATE_DESCRIPTION,
	{
		templateId: string;
		description: string;
	}
>;

export type BuildNewBlankTemplateAction = Action<
	ActionTypes.BUILD_NEW_BLANK_TEMPLATE,
	{
		owner: string;
	}
>;

export type AddVariableToTemplateAction = Action<
	ActionTypes.ADD_VARIABLE_TO_TEMPLATE,
	{
		variable: DraggableVariable;
		templateId: string;
		destinationIndex: number;
	}
>;

export type AddGroupToTemplateAction = Action<
	ActionTypes.ADD_GROUP_TO_TEMPLATE,
	{
		group: TemplateGroupInterface;
		templateId: string;
		destinationIndex: number;
	}
>;

export type RemoveFromTemplateAction = Action<
	ActionTypes.REMOVE_FROM_TEMPLATE,
	{
		templateId: string;
		elementId: string;
	}
>;

export type CreateTemplateFromAllAction = Action<
	ActionTypes.CREATE_TEMPLATE_FROM_ALL,
	{
		variables: DraggableVariable[];
		groups: TemplateGroupInterface[];
		owner: string;
	}
>;

export type CreateAndImportTemplateAction = Action<
	ActionTypes.CREATE_AND_IMPORT_TEMPLATE,
	{
		template: Template;
		removedTemplateId: string;
		newTemplateId: string;
	}
>;

export type ShareWithInstanceAction = Action<
	ActionTypes.SHARE_WITH_INSTANCE,
	{
		instanceId: string;
		usersShare: boolean;
	}
>;

export type UnshareWithInstanceAction = Action<
	ActionTypes.UNSHARE_WITH_INSTANCE,
	{
		instanceId: string;
	}
>;

export type SortInsideTemplateAction = Action<
	ActionTypes.SORT_INSIDE_TEMPLATE,
	{
		sourceIndex: number;
		destinationIndex: number;
		templateId: string;
	}
>;

export type GetTemplateListOfSharedWithAction = Action<
	ActionTypes.GET_TEMPLATE_LIST_OF_SHARED_WITH,
	{
		templateId: string;
		users: UserAccessInterface[];
		projects: ProjectAccessInterface[];
		accessPublicRead: boolean;
	}
>;

export type Actions =
	| SetTemplateIdAction
	| SetCreateSourceTemplateIdAction
	| SetSearchTermAction
	| SetPageViewAction
	| PublishTemplateAction
	| CreateAndImportTemplateAction
	| ShareTemplateAction
	| GetSharedWithAction
	| GetTemplatesAction
	| GetTemplateAction
	| GetProjectTemplatesAction
	| GetUserTemplatesAction
	| GetPublicTemplatesAction
	| UpdateTemplateAction
	| ResetPublishedUnsupportedAction
	| DeleteTemplateAction
	| BuildNewBlankTemplateAction
	| RenameTemplateAction
	| SetTemplateDescriptionAction
	| AddVariableToTemplateAction
	| AddGroupToTemplateAction
	| RemoveFromTemplateAction
	| CreateTemplateFromAllAction
	| SortInsideTemplateAction
	| ShareWithInstanceAction
	| UnshareWithInstanceAction
	| ResetListOfSharedWithAction
	| GetTemplateListOfSharedWithAction;

import { VariableType } from 'types/data/variables/constants';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount
} from 'types/data/projects/types';
import { ImportType, SelectItem } from 'types/index';

export interface ExtendedPreviewVariable extends PreviewVariable {
	index: number;
}

export interface PreviewVariable {
	name: string;
	type: VariableType | string;
	typeError: string;
	dateFormat: string;
	label: string;
	labelError: string;
	id: string;
	customDateFormat: string;
	dateFormatError: string;
	previewVariableName: string;
	previewVariableLabel: string;
	previewVariableLabelError: string;
	isDateWithFormat: boolean;
	isNew: boolean;
	omit: boolean;
	isExcelDateFormat: boolean;
	dateTimeOptions?: DateTimeOptions;
	timeZone?: SelectItem | null;
	timeZoneError?: string;
}

interface DateTimeOptions {
	format: string;
	customFormat?: string;
	formatError: string;
	hasFormat?: boolean;
}

export interface ImportPageProps {
	selectedOption: ImportType;
	handleBack: () => void;
	handleFinishImport: () => void;
	handleApiImportErrors?: (
		apiErrorMap: ApiImportErrorsByVariableName,
		errorCountMap: ApiVariableErrorCount
	) => void;
}

export enum ImportSetTypes {
	newSet = 'newSet',
	existingSet = 'existingSet'
}

export interface VariableToMatch {
	name: string;
	label: string;
}

import { useState } from 'react';
import { Group, Variable, VariableSet } from 'api/data/variables';
import { VariablesTableCheckedState } from 'hooks/store/data/variables/useVariablesTableCheckedData';
import {
	CreateVariableGroupModal,
	UpdateVariableGroupModal,
	DeleteVariableGroupModal,
	DeleteVariableModal,
	CreateVariableSetModal,
	UpdateVariableSetModal,
	DeleteVariableSetModal,
	DeleteVariablesSelectedTableRowsModal,
	MoveSelectedVariablesToGroupModal,
	VariableModal
} from 'components/Variables';
import { useVariableName, useDestinationSetName, useVariables } from 'hooks/store';
import { VariableType } from 'types/data/variables/constants';

export function useVariablesPageModalsController({
	checkedState
}: {
	checkedState: VariablesTableCheckedState;
}) {
	const [, setVariableName] = useVariableName();

	const [destinationSetName, setDestinationSetName] = useDestinationSetName();

	const [
		{
			data: { groupsMap, variablesMap, variableSetsMap }
		}
	] = useVariables({ lazy: true, initial: true });

	// CREATE GROUP MODAL STATE
	const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false);
	const createGroupModal = {
		visible: createGroupModalVisible,
		open: () => setCreateGroupModalVisible(true),
		close: () => setCreateGroupModalVisible(false)
	};

	// UPDATE GROUP MODAL STATE
	const [groupToUpdate, setGroupToUpdate] = useState<Group | null>(null);
	const updateGroupModal = {
		group: groupToUpdate,
		open: (groupName: string) => groupsMap[groupName] && setGroupToUpdate(groupsMap[groupName]),
		close: () => setGroupToUpdate(null)
	};

	// DELETE GROUP MODAL STATE
	const [groupToDelete, setGroupToDelete] = useState<Group | null>(null);
	const deleteGroupModal = {
		group: groupToDelete,
		open: (groupName: string) => groupsMap[groupName] && setGroupToDelete(groupsMap[groupName]),
		close: () => {
			setGroupToDelete(null);
		}
	};

	// DELETE VARIABLE MODAL STATE
	const [variableToDelete, setVariableToDelete] = useState<Variable | null>(null);
	const deleteVariableModal = {
		variable: variableToDelete,
		open: (variableName: string) =>
			variablesMap[variableName] && setVariableToDelete(variablesMap[variableName]),
		close: () => setVariableToDelete(null)
	};

	// UPDATE VARIABLE SET MODAL STATE
	const [variableSetToUpdate, setVariableSetToUpdate] = useState<VariableSet | null>(null);
	const updateVariableSetModal = {
		variableSet: variableSetToUpdate,
		open: (setName: string) => {
			setDestinationSetName(setName);
			variableSetsMap[setName] && setVariableSetToUpdate(variableSetsMap[setName]);
		},
		close: () => {
			setVariableSetToUpdate(null);
			setDestinationSetName(null);
		}
	};

	// CREATE VARIABLE SET MODAL STATE
	const [createVariableSetModalVisible, setCreateVariableSetModalVisible] = useState(false);
	const createVariableSetModal = {
		visible: createVariableSetModalVisible,
		open: () => setCreateVariableSetModalVisible(true),
		close: () => setCreateVariableSetModalVisible(false)
	};

	// DELETE VARIABLE SET MODAL STATE
	const [variableSetToDelete, setVariableSetToDelete] = useState<VariableSet | null>(null);
	const deleteVariableSetModal = {
		variableSet: variableSetToDelete,
		open: (setName: string) =>
			variableSetsMap[setName] && setVariableSetToDelete(variableSetsMap[setName]),
		close: () => setVariableSetToDelete(null)
	};

	// DELETE SELECTED MODAL STATE
	const [deleteSelectedModalVisible, setDeleteSelectedModalVisible] = useState(false);
	const deleteSelectedModal = {
		visible: deleteSelectedModalVisible,
		open: () => setDeleteSelectedModalVisible(true),
		close: () => setDeleteSelectedModalVisible(false)
	};

	// MOVE SELECTED VARIABLES MODAL STATE
	const [moveSelectedVariablesModalVisible, setMoveSelectedVariablesModalVisible] =
		useState(false);
	const moveSelectedVariablesModal = {
		visible: moveSelectedVariablesModalVisible,
		open: () => setMoveSelectedVariablesModalVisible(true),
		close: () => setMoveSelectedVariablesModalVisible(false)
	};

	// ADD / EDIT VARIABLE MODAL STATE
	const [variableModalVisible, setVariableModalVisible] = useState(false);
	const variableModal = {
		visible: variableModalVisible,
		open: (variableName?: string, setName?: string) => {
			setVariableModalVisible(true);

			if (variableName) setVariableName(variableName);
			if (setName) setDestinationSetName(setName);
		},
		close: () => {
			setVariableModalVisible(false);
			setVariableName(null);
		}
	};

	const modals = {
		createGroupModal,
		updateGroupModal,
		deleteGroupModal,
		deleteVariableModal,
		updateVariableSetModal,
		createVariableSetModal,
		deleteVariableSetModal,
		deleteSelectedModal,
		moveSelectedVariablesModal,
		variableModal
	};

	const modalsComponent = (
		<>
			{/* CREATE GROUP MODAL */}
			{createGroupModal.visible && (
				<CreateVariableGroupModal onClose={createGroupModal.close} />
			)}

			{/* UPDATE GROUP MODAL */}
			{updateGroupModal.group && (
				<UpdateVariableGroupModal
					group={updateGroupModal.group}
					onClose={updateGroupModal.close}
				/>
			)}

			{/* DELETE GROUP MODAL */}
			{deleteGroupModal.group && (
				<DeleteVariableGroupModal
					group={deleteGroupModal.group}
					onClose={deleteGroupModal.close}
				/>
			)}

			{/* DELETE VARIABLE MODAL */}
			{deleteVariableModal.variable && (
				<DeleteVariableModal
					variable={deleteVariableModal.variable}
					onClose={deleteVariableModal.close}
				/>
			)}

			{/* CREATE VARIABLE SET MODAL */}
			{createVariableSetModal.visible && (
				<CreateVariableSetModal onClose={createVariableSetModal.close} />
			)}

			{/* UPDATE VARIABLE SET MODAL */}
			{updateVariableSetModal.variableSet && (
				<UpdateVariableSetModal
					variableSet={updateVariableSetModal.variableSet}
					onClose={updateVariableSetModal.close}
				/>
			)}

			{/* DELETE VARIABLE SET MODAL */}
			{deleteVariableSetModal.variableSet && (
				<DeleteVariableSetModal
					variableSet={deleteVariableSetModal.variableSet}
					onClose={deleteVariableSetModal.close}
				/>
			)}

			{/* DELETE SELECTED ROWS MODAL */}
			{deleteSelectedModal.visible && (
				<DeleteVariablesSelectedTableRowsModal
					checkedState={checkedState}
					onClose={deleteSelectedModal.close}
				/>
			)}

			{/* MOVE SELECTED VARIABLES TO GROUP (EXISTING OR NEW) MODAL */}
			{moveSelectedVariablesModal.visible && (
				<MoveSelectedVariablesToGroupModal
					disabledVariableTypesInSet={[VariableType.File]}
					checkedState={checkedState}
					onClose={moveSelectedVariablesModal.close}
				/>
			)}
			{/* ADD / EDIT VARIABLE */}
			{variableModal.visible && (
				<VariableModal
					disabledVariableTypes={destinationSetName ? [VariableType.File] : []}
					onClose={variableModal.close}
				/>
			)}
		</>
	);

	return { modals, modalsComponent };
}

import { produce } from 'immer';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.UPDATE_SERIES_NAME: {
			const { seriesName } = action.payload;

			return produce(state, draft => {
				const { metadata } = draft;

				if (!seriesName) return;

				draft.bySeriesName = seriesName;

				if (metadata[seriesName]) return;

				draft.metadata = {
					...metadata,
					[seriesName]: {
						pageIndex: metadata.defaultValues.pageIndex,
						pageSize: metadata.defaultValues.pageSize,
						columnSettings: metadata.defaultValues.columnSettings
					}
				};
			});
		}

		case ActionTypes.SET_SERIES_TABLE_PARAMS: {
			const { pageIndex, pageSize } = action.payload;

			return produce(state, draft => {
				const { bySeriesName, metadata } = draft;

				if (bySeriesName && metadata[bySeriesName]) {
					if (pageIndex !== undefined) metadata[bySeriesName].pageIndex = pageIndex;

					if (pageSize) metadata[bySeriesName].pageSize = pageSize;
				}
			});
		}

		case ActionTypes.SET_SERIES_TABLE_VISIBLE_COLUMNS: {
			const { columnNames } = action.payload;

			return produce(state, draft => {
				const { bySeriesName, metadata } = draft;

				if (!(bySeriesName && metadata[bySeriesName])) return;

				draft.metadata = {
					...metadata,
					[bySeriesName]: {
						pageIndex: metadata[bySeriesName].pageIndex,
						pageSize: metadata[bySeriesName].pageSize,
						columnSettings: {
							visible: (metadata[bySeriesName].columnSettings.visible = columnNames)
						}
					}
				};
			});
		}

		//FOR SERIES TABLE VIEW
		case ActionTypes.UPDATE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW: {
			const { seriesName } = action.payload;

			return produce(state, draft => {
				if (!seriesName) return;

				draft.fullScreenSeriesName = seriesName;
			});
		}
		case ActionTypes.DELETE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW: {
			const { seriesName } = action.payload;
			return produce(state, draft => {
				draft.fullScreenSeriesName = seriesName;
			});
		}

		//FOR SERIES VIEWS
		case ActionTypes.SET_SERIES_VIEWS: {
			const { seriesViews } = action.payload;
			return produce(state, draft => {
				draft.seriesViews = { ...draft.seriesViews, ...seriesViews };
			});
		}

		default: {
			return state;
		}
	}
};

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import {
	unshareRoleTemplateWithInstance,
	UnshareRoleTemplateWithInstanceAction
} from 'store/data/roles';
import { ActionPayload } from 'store/types';

export function useUnshareRoleTemplateWithInstance(): LocalOperation<
	(payload: ActionPayload<UnshareRoleTemplateWithInstanceAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<UnshareRoleTemplateWithInstanceAction>) {
		dispatch(unshareRoleTemplateWithInstance(payload));
	}

	return handler;
}

import React, { useCallback, useMemo } from 'react';
import { NewButton } from 'components/Dependencies/DependencyRule/DependencyRule.style';
import { Spacer } from 'components/UI/Spacer';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InputType, Nullable } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { DeleteButton, Equals, Label, MappingContainer } from './FormDesignerDrawer.style';
import { FormElementSliderValue, FormErrors } from 'store/data/forms/types';
import { useFormErrors } from 'hooks/store/data/forms/useFormErrors';
import { useTranslation } from 'hooks/store';
import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons/Icon';

const emojiItems = [
	{ label: '🙁', value: '🙁' },
	{ label: '😕', value: '😕' },
	{ label: '😐', value: '😐' },
	{ label: '😊', value: '😊' },
	{ label: '😁', value: '😁' },
	{ label: 'No mapping', value: '' }
];

interface SliderValuesEditorProps {
	isInteger: boolean;
	elementId: string;
	sliderValues: FormElementSliderValue[];
	variableValidationRange?: boolean;
	mapValuesWithMoods?: boolean;
	onChangeValue: (index: number, value: string) => void;
	onChangeMapping: (index: number, mapping: Nullable<string>) => void;
	onClickAddNewValue: () => void;
	onDeleteValue: (index: number) => void;
}

const SliderValuesEditor: React.FC<SliderValuesEditorProps> = ({
	isInteger,
	elementId,
	sliderValues,
	variableValidationRange,
	mapValuesWithMoods,
	onChangeValue,
	onChangeMapping,
	onClickAddNewValue,
	onDeleteValue
}) => {
	const { errors: formErrors, setErrors: setFormErrors } = useFormErrors();
	const { translate } = useTranslation();

	const getMappingValue = useCallback((mapping: string) => {
		return { label: mapping, value: mapping };
	}, []);

	const updateFormErrors = useCallback(
		(index: number, value: string | null) => {
			const newSliderValues = [...sliderValues];
			if (value !== null) {
				newSliderValues[index] = {
					...newSliderValues[index],
					value: value
				};
			} else {
				newSliderValues.splice(index, 1);
			}

			const newInputErrors: string[] = new Array(sliderValues.length).fill('');

			const len = newSliderValues.length;
			for (let i = 0; i < len; i++) {
				const currentValue = newSliderValues[i].value;
				const parsedCurrentValue = parseFloat(currentValue);

				/*
				/	CHECK IF VALUES ARE NOT EDITABLE
				*/
				if ((i === 0 || i === len - 1) && variableValidationRange) {
					continue;
				}
				/*
				/	CHECK IF VALUE EXISTS
				*/
				if (currentValue === '') {
					newInputErrors[i] = translate(
						dict => dict.validation.formVariables.fieldRequired
					);
					continue;
				}
				/*
				/	CHECK IF NUMBER
				*/
				const numericPattern = /^-?(\d*\.\d+|\d+\.?|\.\d+)?$/;
				if (!numericPattern.test(currentValue)) {
					newInputErrors[i] = translate(dict => dict.validation.formVariables.numeric);
					continue;
				}

				/*
				/	CHECK IF INTEGER
				*/
				if (isInteger && !Number.isInteger(parsedCurrentValue) && currentValue !== '-') {
					newInputErrors[i] = translate(dict => dict.validation.formVariables.noDecimals);
					continue;
				}

				/*
				/	CHECK ADJACENT VALUES
				*/
				if (i === 0 && parsedCurrentValue > parseFloat(newSliderValues[i + 1].value)) {
					newInputErrors[i] = `${translate(
						dict => dict.validation.formVariables.lowerThan
					)} ${newSliderValues[i + 1].value}`;
					continue;
				} else if (
					i === len - 1 &&
					parsedCurrentValue < parseFloat(newSliderValues[i - 1].value)
				) {
					newInputErrors[i] = `${translate(
						dict => dict.validation.formVariables.greaterThan
					)} ${newSliderValues[i - 1].value}`;
					continue;
				} else if (
					i > 0 &&
					i < len - 1 &&
					(parseFloat(newSliderValues[i - 1].value) > parsedCurrentValue ||
						parseFloat(newSliderValues[i + 1].value) < parsedCurrentValue)
				) {
					newInputErrors[i] = `${translate(
						dict => dict.validation.formVariables.mustBeBetween
					)} ${newSliderValues[i - 1].value} ${translate(
						dict => dict.validation.formVariables.and
					)} ${newSliderValues[i + 1].value}`;
					continue;
				}
			}

			const newErrors: FormErrors = {
				[elementId]: {
					...formErrors[elementId], // Keep existing errors
					sliderValuesErrors: newInputErrors // Overwrite or set new sliderValuesErrors
				}
			};

			const updatedFormErrors = { ...formErrors };

			Object.keys(newErrors).forEach(key => {
				updatedFormErrors[key] = newErrors[key];
			});

			setFormErrors(updatedFormErrors);
		},
		[formErrors, sliderValues, setFormErrors, translate]
	);

	const handleInputChange = useCallback(
		(index: number, value: string) => {
			onChangeValue(index, value);
			updateFormErrors(index, value);
		},
		[onChangeValue, updateFormErrors]
	);

	const handleDelete = useCallback(
		(index: number) => {
			onDeleteValue(index);
			updateFormErrors(index, null);
		},
		[onDeleteValue, updateFormErrors]
	);

	const getInputError = useCallback(
		(index: number) => formErrors?.[elementId]?.sliderValuesErrors?.[index] ?? '',
		[formErrors, elementId]
	);

	const mappedSliderValues = useMemo(
		() =>
			sliderValues.map((sliderValue, index) => (
				<div key={index}>
					<Label marginOffset={{ top: 1.6, bottom: 0.4 }}>
						{index === 0
							? translate(
									dict => dict.formDesigner.variableOptionsDrawer.minimumValue
							  )
							: index === sliderValues.length - 1
							? translate(
									dict => dict.formDesigner.variableOptionsDrawer.maximumValue
							  )
							: translate(dict => dict.formDesigner.variableOptionsDrawer.value)}
					</Label>
					<MappingContainer>
						<Input
							name="Value"
							type={InputType.Text}
							value={sliderValue.value}
							onChange={event => handleInputChange(index, event.target.value)}
							error={getInputError(index)}
							disabled={
								variableValidationRange &&
								(index === 0 || index === sliderValues.length - 1)
							}
						/>
						{mapValuesWithMoods && <Equals>=</Equals>}
						{mapValuesWithMoods && (
							<CreatableSelect
								canClear={false}
								value={getMappingValue(sliderValue.mapping ?? '')}
								items={emojiItems}
								onValueSelected={event => onChangeMapping(index, event)}
							/>
						)}
						{index !== 0 && index !== sliderValues.length - 1 && (
							<DeleteButton
								svg={Svgs.Delete}
								size={s => s.l}
								onClick={() => handleDelete(index)}
							/>
						)}
						{(index === 0 || index === sliderValues.length - 1) && (
							<Icon
								size={s => s.l}
								svg={Svgs.Delete}
								style={{ visibility: 'hidden' }}
							/>
						)}
					</MappingContainer>
				</div>
			)),
		[
			sliderValues,
			handleInputChange,
			getInputError,
			mapValuesWithMoods,
			getMappingValue,
			onChangeMapping,
			variableValidationRange
		]
	);

	return (
		<>
			{mappedSliderValues}
			{sliderValues.length < 5 && (
				<>
					<Spacer size={s => s.s}></Spacer>
					<NewButton onClick={onClickAddNewValue}>+ Add new value</NewButton>
				</>
			)}
		</>
	);
};

export default SliderValuesEditor;

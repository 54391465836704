import { isEmpty } from 'lodash';

import { PlotNumericBoxplotResults, PlotNumericBoxplotSingle } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { CHART_COLORS, CHART_HEIGHT, DEFAULT_MAX_LABELS } from 'consts';
import { Colors } from 'environment';
import { ScalesLabels } from 'types/index';
import { computeScalesLabels, computeLabels } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import { useTranslation, useAnalysesActiveColum } from 'hooks/store';

interface Series {
	dataBox: number[][];
	backgroundColor: string;
	borderColor: string;
	tooltip: any;
}

function useComputeData(
	data: PlotNumericBoxplotResults,
	isLegendEnabled: boolean,
	scalesLabels: ScalesLabels,
	activeColumns: number,
	legendHeader?: string
) {
	const { translate } = useTranslation();

	const [labelX, labelY] = computeScalesLabels(scalesLabels);

	let scaleXValues: string[] = [];
	let series: Series[] = [];

	let maxItems = null;
	let fontAngle = null;
	let maxChars = null;
	let tooltipScaleX = null;

	const errorMaxValues: number[] = [];

	const seriesTooltipText = `<table><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMax
	)}</td><td><b>%data-max</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.IQRUpper
	)}</td><td><b>%data-upper-quartile</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.median
	)}</td><td><b>%data-median</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.IQRLower
	)}</td><td><b>%data-lower-quartile</b></td></tr><tr><td style="padding-right:1rem">${translate(
		({ analysis }) => analysis.analyses.plotNumeric.tooltip.boxplot.errorValueMin
	)}</td><td><b>%data-min</b></td></tr></table>`;

	const dataset = data as PlotNumericBoxplotSingle;
	scaleXValues = dataset.map(serie => serie.categorizationValue);
	dataset.forEach(el => errorMaxValues.push(Number(el.errorValueMax)));
	const [computedMaxItems, computedMaxChars, computedFontAngle, computedTooltip] = computeLabels(
		scaleXValues,
		activeColumns === 1
	);

	maxItems = computedMaxItems;
	maxChars = computedMaxChars;
	fontAngle = computedFontAngle;
	tooltipScaleX = computedTooltip;

	series = [
		{
			dataBox: dataset.map(plot => {
				const errorValueMin = Number(Number(plot.errorValueMin).toFixed(3));
				const IQRLower = Number(Number(plot.IQRLower).toFixed(3));
				const median = Number(Number(plot.median).toFixed(3));
				const IQRUpper = Number(Number(plot.IQRUpper).toFixed(3));
				const errorValueMax = Number(Number(plot.errorValueMax).toFixed(3));

				return [errorValueMin, IQRLower, median, IQRUpper, errorValueMax];
			}),
			backgroundColor: CHART_COLORS[0],
			borderColor: CHART_COLORS[0],
			tooltip: {
				text: seriesTooltipText,
				backgroundColor: CHART_COLORS[0]
			}
		}
	];

	const yMaxValue = Math.max(...errorMaxValues);

	const options = {
		plot: {
			hoverState: {
				visible: false
			}
		},
		legend: {
			header: {
				text: legendHeader
			},
			visible: isLegendEnabled
		},
		plotarea: {
			marginLeft: yMaxValue >= 10000 ? 75 : undefined,
			marginRight: yMaxValue >= 10000 ? 40 : undefined
		},
		scaleX: {
			label: labelX,
			values: scaleXValues,
			itemsOverlap: true,
			maxItems: activeColumns === 3 ? DEFAULT_MAX_LABELS : maxItems,
			item: {
				fontAngle,
				maxChars
			},
			tooltip: tooltipScaleX
		},
		scaleY: {
			label: labelY,
			exponent: yMaxValue >= 10000 ? true : false
		},
		options: {
			lineMedianLevel: {
				lineColor: Colors.gray.darkest,
				lineWidth: 2
			}
		}
	};

	return { series, options };
}

interface Props {
	id?: string;
	data: PlotNumericBoxplotResults;
	isForExport?: boolean;
	isLegendEnabled: boolean;
	legendHeader: string;
	scalesLabels: ScalesLabels;
}

function Component({ id, data, isLegendEnabled, isForExport, scalesLabels, legendHeader }: Props) {
	const [activeColumns] = useAnalysesActiveColum();
	const { series, options } = useComputeData(
		data,
		isLegendEnabled,
		scalesLabels,
		activeColumns,
		legendHeader
	);

	if (isEmpty(data)) return null;

	return (
		<Chart
			id={id}
			type={t => t.BoxPlot}
			series={series}
			options={options}
			isForExport={isForExport}
			height={CHART_HEIGHT}
		/>
	);
}

export const JADBioProbabilitiesBoxPlotChart = withMemo(Component, [
	'data',
	'isLegendEnabled',
	'scalesLabels',
	'legendHeader'
]);

import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	margin-left: 2.4rem;

	& > div {
		height: 4rem;
	}
`;

import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Svgs, Colors } from 'environment';
import { GroupData } from 'store/data/variables';
import { DragAndDropTypes } from 'types/index';
import { DraggableDependencyVariableCard } from '../DraggableDependencyVariableCard';
import { ColorIndicator } from 'components/Forms/Designer/Draggable/VariablesAndGroups/style';
import { GroupVariable, GroupContainer, GroupLabel } from './CollapsibleGroup.style';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useToggle } from 'hooks/utils';

interface Props {
	index: number;
	groupData: GroupData;
}

export function CollapsibleGroup({ index, groupData }: Props) {
	const [groupExpanded, toggleExpanded] = useToggle(false);

	const { groupName, groupLabel, groupVariables } = groupData;

	return (
		<Draggable draggableId={groupName} index={index} key={index} isDragDisabled>
			{provided => (
				<Flex column>
					{/* HEADER */}
					<GroupContainer
						ref={provided.innerRef}
						key={groupName}
						onClick={toggleExpanded}
					>
						<ColorIndicator type="group" />

						<Icon
							svg={Svgs.ChevronDown}
							onClick={toggleExpanded}
							rotate={groupExpanded ? 180 : 0}
						/>
						<Icon
							svg={Svgs.Folder}
							size={s => s.m}
							colors={{ color: Colors.groupOrange }}
							marginOffset={{ x: 0.8 }}
							propagate
						/>
						<GroupLabel>
							<Typography.Paragraph
								title={groupLabel}
								fontweight={w => w.medium}
								ellipsis
							>
								{groupLabel}
							</Typography.Paragraph>
						</GroupLabel>
					</GroupContainer>

					{/* BODY */}
					{groupExpanded && (
						<Droppable
							droppableId={
								DragAndDropTypes.DraggableDependenciesVarList + index.toString()
							}
							isDropDisabled
						>
							{provided => (
								<div ref={provided.innerRef}>
									<Gap
										marginOffset={{ top: 0.8 }}
										marginGap={{ bottom: 0.8 }}
										notLastChild
									>
										{groupVariables.map((variable, index) => (
											<GroupVariable key={index}>
												<Icon
													svg={Svgs.ChevronArrowCorner}
													size={s => s.m}
													paddingOffset={{
														all: 0.8
													}}
												/>
												<DraggableDependencyVariableCard
													index={index}
													variable={variable}
													isGrouped
												/>
											</GroupVariable>
										))}
									</Gap>

									<div
										style={{
											visibility: 'hidden',
											height: 0,
											width: 0
										}}
									>
										{provided.placeholder}
									</div>
								</div>
							)}
						</Droppable>
					)}
				</Flex>
			)}
		</Draggable>
	);
}

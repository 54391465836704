import React, { useState } from 'react';

import { TabsItem } from './TabsItem';
import { TabsContent } from './TabsContent';

import { Container, Items, TabsItemWithIconContainer } from './Tabs.style';
import { StyleOffset, SvgComponent } from 'types/index';
import { Icon } from 'components/UI/Icons';
import { Flex } from '../Flex';

interface Props {
	children?: React.ReactNode;
	icons: SvgComponent[];
	labels: string[];
	startIndex?: number;
	maxWidth?: number;
	className?: string;
	tabClassName?: string;
	tabMarginOffset?: StyleOffset;
	onChangeTabs?: (active: number) => void;
}

export function TabsWithIcon({
	children,
	labels,
	startIndex = 0,
	maxWidth,
	className,
	tabClassName,
	onChangeTabs,
	tabMarginOffset,
	icons
}: Props) {
	const [active, setActive] = useState(startIndex);

	const enhancedChildren = React.Children.toArray(children)
		.filter(child => child)
		.map((child, index) =>
			React.cloneElement(child as JSX.Element, {
				active: index === active
			})
		);

	const onChangeTab = (index: number) => {
		setActive(index);

		if (onChangeTabs) {
			onChangeTabs(index);
		}
	};

	return (
		<Container className={className}>
			<Items maxWidth={maxWidth} hasIcon={true}>
				{labels.map((label, index) => (
					<TabsItemWithIconContainer
						active={active === index}
						marginOffset={{ top: 1.6 }}
						key={index + label}
						onClick={() => onChangeTab(index)}
						wrap
						justify={j => j.around}
						align={a => a.end}
					>
						{icons && (
							<Flex justify={j => j.center}>
								<Icon
									svg={icons[index]}
									style={{ margin: 'auto' }}
									onClick={() => onChangeTab(index)}
									active={active === index}
								/>
							</Flex>
						)}
						<TabsItem
							active={active === index}
							key={`tab-item-${label}`}
							marginOffset={tabMarginOffset}
							className={tabClassName}
							onChangeTab={() => onChangeTab(index)}
							hasIcon={true}
						>
							{label}
						</TabsItem>
					</TabsItemWithIconContainer>
				))}
			</Items>
			{enhancedChildren}
		</Container>
	);
}

TabsWithIcon.Content = TabsContent;

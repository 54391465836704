import { useDispatch, useSelector } from 'hooks/utils';
import { setEntryId, SetEntryIdAction } from 'store/data/entries';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

export function useEntryId(): LocalOperationResult<
	string | null,
	(entryId: ActionPayload<SetEntryIdAction>['entryId']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.entries.metadata.entryId);

	function handler(entryId: ActionPayload<SetEntryIdAction>['entryId']) {
		dispatch(setEntryId({ entryId }));
	}

	return [data, handler];
}

import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 4rem;
	margin-bottom: 1.6rem;
`;

import styled, { css } from 'styled-components';

import { Colors } from 'environment';

interface ColorIndicatorProps {
	type: 'group' | 'variableSet';
}
export const ColorIndicator = styled.div<ColorIndicatorProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 0.4rem 0 0 0.4rem;

	${({ type }) => {
		const colors = {
			group: Colors.groupOrange,
			variableSet: Colors.seriesGreen
		};

		return css`
			border-left: 0.4rem solid ${colors[type]};
		`;
	}}
`;

import countries from 'i18n-iso-countries';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';

import { useTranslation } from 'hooks/store';
import { Collaborator } from 'store/data/collaborators';
import { InputType } from 'types/index';

import { UserCard } from 'components/UI/UserCard';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';

countries.registerLocale(countriesEnLocale);

interface Props {
	collaborator: Collaborator;
	confirmInput: {
		value: string;
		setValue: (value: string) => void;
	};
}

export function ConfirmStep({ collaborator, confirmInput }: Props) {
	const { translate } = useTranslation();

	return (
		<>
			<UserCard.Details
				userId={collaborator.userId}
				userData={collaborator}
				cardLayout={l => l.TransferUser}
			/>
			<Spacer size={s => s.m} />
			<Flex column justify={j => j.center}>
				<Typography.Paragraph
					css={`
						user-select: none;
					`}
					fontweight={w => w.bold}
					onSelect={e => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					{translate(
						({ dataset }) => dataset.entries.transferOwnership.modal.confirmationText
					)}
				</Typography.Paragraph>
				<Spacer size={s => s.xs} />

				<Input
					type={InputType.Text}
					value={confirmInput.value}
					onChange={e => confirmInput.setValue(e.target.value)}
				/>
			</Flex>
		</>
	);
}

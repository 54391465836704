import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { CorrelationsAnalysis } from 'api/data/analyses';
import {
	AnalysisStatisticsWrapper,
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { CorrelationsScatterChart } from './CorrelationsScatterChart';
import { CorrelationsStatistics } from './CorrelationsStatistics';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { exportSvg } from 'helpers/analysis';
import { getExportFileName } from 'helpers/generic';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import {
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useProject,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: CorrelationsAnalysis;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function CorrelationsView({ analysis, exporting, loading, variablesData }: Props) {
	const [{ data: project }] = useProject();

	const {
		id,
		input,
		options: { configPanel, chartLegend = false },
		output: { dataset, statistics }
	} = analysis;

	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		series: input.series,
		omitVariables: []
	});
	const { variablesMap } = variablesData;
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const correlationsScatterId = ZingChartExportTypes.Correlations + id;

	useEffect(() => {
		if (exporting && project) {
			exportSvg(
				correlationsScatterId,
				getExportFileName(
					ExportFileNames.CorrelationsScatterChart,
					project.projectId,
					project.projectName
				)
			);
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: selectItemsLabelMap[input.variables.xVariable?.name ?? ''],
		labelY: selectItemsLabelMap[input.variables.yVariable?.name ?? '']
	};

	const legendHeader = input.variables.groupVariables?.[0]
		? selectItemsLabelMap[input.variables.groupVariables?.[0]?.name]
		: undefined;

	const { parsedDataset } = useMemo(() => {
		let parsedDataset = cloneDeep(dataset.data ?? []);

		if (analysis.options.showCategoryLabels) {
			parsedDataset = parsedDataset?.map(result => {
				result.group =
					(input.variables.groupVariables[0] &&
						mapVariableCategoryValueToLabel(
							result.group,
							variablesMap[input.variables.groupVariables[0].name]
						)) ??
					result.group;
				return result;
			});
		}
		return { parsedDataset };
	}, [
		dataset,
		variablesMap,
		input.variables.groupVariables[0],
		analysis.options.showCategoryLabels
	]);

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.CorrelationsV2}>
				<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
					<CorrelationsScatterChart
						linearRegression={statistics.linearRegressionV2.data ?? []}
						data={parsedDataset}
						isLegendEnabled={chartLegend}
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						isConfigPanelOpen={configPanel.open}
					/>
					<AnalysisStatisticsWrapper>
						<CorrelationsStatistics analysis={analysis} />
					</AnalysisStatisticsWrapper>
				</AnalysisChartContainer>

				<AnalysisExportWrapper>
					<CorrelationsScatterChart
						linearRegression={statistics.linearRegressionV2.data ?? []}
						id={correlationsScatterId}
						data={parsedDataset}
						isForExport
						isLegendEnabled
						legendHeader={legendHeader}
						scalesLabels={scalesLabels}
						isConfigPanelOpen={configPanel.open}
					/>
				</AnalysisExportWrapper>
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}

import { SubscriptionAddonCode } from 'types/index';
import { Switch } from 'components/UI/Interactables/Switch';
import { AddonsOptionLabel, AddonsOptionInfoLabel } from 'store/account/addons';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface AddonsTogglesProps {
	addons: SubscriptionAddonCode[];
	enabledAddons: SubscriptionAddonCode[];
	activeUserAddons?: SubscriptionAddonCode[]; // only used to indicate that addons need to be activated by Users in order to use them on Projects
	onAddonClicked: (addonCode: SubscriptionAddonCode) => void;
}

export function AddonsToggles({
	addons,
	enabledAddons,
	onAddonClicked,
	activeUserAddons
}: AddonsTogglesProps) {
	const { translate } = useTranslation();

	return (
		<>
			{addons.map(addonCode => (
				<div key={addonCode}>
					<Switch
						label={translate(() => AddonsOptionLabel[addonCode])}
						disabled={activeUserAddons && !activeUserAddons.includes(addonCode)} // check if addon is included
						on={enabledAddons && enabledAddons.includes(addonCode)}
						onChange={() => onAddonClicked(addonCode)}
					/>
					<Typography.Caption marginOffset={{ left: 5.6, top: 0.1 }}>
						{translate(() => AddonsOptionInfoLabel[addonCode])}
					</Typography.Caption>
					{activeUserAddons && !activeUserAddons.includes(addonCode) && (
						<Typography.Caption marginOffset={{ left: 5.6 }}>
							{translate(dict => dict.addons.activateAddonFirst)}
						</Typography.Caption>
					)}
				</div>
			))}
		</>
	);
}

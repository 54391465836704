import { PROJECTS_URL, USER_ACCOUNT_URL, sendRequest } from 'api/utils';
import { Dictionary } from 'environment';
import {
	APIEnterpriseRole,
	GetAllActiveEnterpriseOrganizationUsersRequest,
	GetAllEnterpriseOwnedProjectsResponse,
	GetEnterpriseRolesOutput,
	GetEnterpriseRolesRequest,
	GetEnterpriseRolesResponse,
	NewAPIEnterpriseRole,
	ResetEnterpriseUserPasswordRequest,
	UpdateApiEnterpriseUser,
	UpdateEnterpriseUserResponse
} from './types';
import { UserBasicInfo } from 'store/account/subscription';
import { GenericApiResponse } from 'api/types';
import { ApiSubscriptionUser, GetListOfOwnedProjectsResponse } from '../subscription';

export const methods = {
	deactivateUserAccount: 'deactivateUserAccount',
	reactivateUserAccount: 'reactivateUserAccount',

	// enterprise
	getAllEnterpriseOwnedProjects: 'getAllEnterpriseOwnedProjects',
	getListOfAllOwnedProjects: 'getListOfAllOwnedProjects',
	updateEnterpriseUser: 'updateEnterpriseUser',
	resetEnterpriseUserPassword: 'resetEnterpriseUserPassword',
	getAllActiveEnterpriseOrganizationUsers: 'getAllActiveEnterpriseOrganizationUsers',

	//enterprise roles
	getEnterpriseRoles: 'getEnterpriseRoles',
	createEnterpriseRole: 'createEnterpriseRole',
	updateEnterpriseRole: 'updateEnterpriseRole',
	deleteEnterpriseRole: 'deleteEnterpriseRole'
};

export default () => ({
	updateEnterpriseUser: async (user: UpdateApiEnterpriseUser): Promise<ApiSubscriptionUser> => {
		const { data }: UpdateEnterpriseUserResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.updateEnterpriseUser,
			user
		});
		return data.user;
	},

	resetEnterpriseUserPassword: async (userId: string): Promise<void> => {
		try {
			await sendRequest<ResetEnterpriseUserPasswordRequest>(USER_ACCOUNT_URL, {
				method: methods.resetEnterpriseUserPassword,
				user: {
					userid: userId
				}
			});
		} catch (e) {
			throw new Error(Dictionary.admin.users.errors.resetPassword);
		}
	},

	/**
	 * Currently not used, because we are returning the users in getSubscription
	 * @param organizationId
	 * @returns
	 */
	getAllActiveEnterpriseOrganizationUsers: async (
		organizationId: string
	): Promise<UserBasicInfo[]> => {
		try {
			const { data } = await sendRequest<GetAllActiveEnterpriseOrganizationUsersRequest>(
				USER_ACCOUNT_URL,
				{
					method: methods.getAllActiveEnterpriseOrganizationUsers,
					organizationId
				}
			);
			if (!data || !data.users) throw Error();

			return data.users;
		} catch (e) {
			throw new Error(Dictionary.admin.enterprise.errors.api.activeEnterpriseUsers);
		}
	},

	deactivateUserAccount: async (userId: string) => {
		const {
			data: { ledidiStatusCode }
		}: GenericApiResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.deactivateUserAccount,
			user: { userid: userId }
		});
		if (ledidiStatusCode !== '200') {
			throw new Error(Dictionary.enterpriseAdmin.deactivateUser.errors.couldntDeactivateUser);
		}
		return true;
	},

	reactivateUserAccount: async (userId: string) => {
		const {
			data: { ledidiStatusCode }
		}: GenericApiResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.reactivateUserAccount,
			user: { userid: userId }
		});
		if (ledidiStatusCode !== '200') {
			throw new Error(Dictionary.enterpriseAdmin.reactivateUser.errors.couldntReactivateUser);
		}
		return true;
	},

	getListOfAllOwnedProjects: async (userId: string) => {
		const {
			data: { statusCode, projects }
		}: GetListOfOwnedProjectsResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getListOfAllOwnedProjects,
			userId
		});
		if (statusCode !== '200') {
			throw new Error(
				Dictionary.enterpriseAdmin.deactivateUser.errors.couldntGetOwnedProjects
			);
		}
		return projects;
	},

	getAllEnterpriseOwnedProjects: async () => {
		const { data }: GetAllEnterpriseOwnedProjectsResponse = await sendRequest(PROJECTS_URL, {
			method: methods.getAllEnterpriseOwnedProjects
		});
		if (data.statusCode !== '200' || !data.projects) {
			throw new Error(
				Dictionary.errors.api.enterprise.projects.couldNotGetAllEnterpriseOwnedProjects
			);
		}
		return data.projects;
	},

	// Enterprise Roles
	async createEnterpriseRole(role: NewAPIEnterpriseRole): Promise<number> {
		const {
			data: { orgRoleId, statusCode }
		} = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.createEnterpriseRole,
			enterpriseRole: { ...role }
		});

		if (statusCode !== '200') {
			throw new Error(Dictionary.enterpriseAdmin.rolesModules.api.couldntCreateRole);
		}

		return orgRoleId;
	},

	async getEnterpriseRoles(): Promise<GetEnterpriseRolesOutput> {
		const {
			data: { enterpriseRoles, ledidiStatusCode }
		} = await sendRequest<GetEnterpriseRolesRequest, GetEnterpriseRolesResponse>(
			USER_ACCOUNT_URL,
			{
				method: methods.getEnterpriseRoles
			}
		);
		if (ledidiStatusCode !== '200') {
			throw new Error(Dictionary.enterpriseAdmin.rolesModules.api.couldntGetRoles);
		}

		return { enterpriseRoles };
	},

	async updateEnterpriseRole(enterpriseRole: APIEnterpriseRole): Promise<boolean> {
		const { data } = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.updateEnterpriseRole,
			enterpriseRole
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.enterpriseAdmin.rolesModules.api.couldntUpdateRole);
		}

		return true;
	},
	async deleteEnterpriseRole(roleId: string): Promise<boolean> {
		const { data } = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.deleteEnterpriseRole,
			enterpriseRole: { roleId }
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.enterpriseAdmin.rolesModules.api.couldntDeleteRole);
		}

		return true;
	}
});

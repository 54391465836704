import { useParams } from 'react-router-dom';
import { HelpTopicList } from 'store/data/help';
import { FilterByTypeComponent } from 'types/index';
import { TOCItem } from './TOCItem';
import { Container, NoResults } from './TOC.style';
import {
	useTranslation,
	useHelpTopics,
	useHelpSearchTerm,
	useFilterBySearchTerm
} from 'hooks/store';

export function TOC() {
	const { translate } = useTranslation();
	const { topicUrl } = useParams<{ topicUrl: string }>();

	const [{ data: helpTopicList }] = useHelpTopics();

	const [searchTerm] = useHelpSearchTerm();

	const filteredTopics = useFilterBySearchTerm(
		helpTopicList,
		FilterByTypeComponent.HelpPage
	) as HelpTopicList;

	return (
		<Container>
			{filteredTopics.length ? (
				filteredTopics.map((item, index) => (
					<TOCItem
						key={`TOC-item-${index}`}
						item={item}
						isActive={topicUrl === undefined ? index === 0 : undefined}
					/>
				))
			) : (
				<NoResults>
					{translate(dict => dict.helpPage.noResults)} <b>{searchTerm}</b>
				</NoResults>
			)}
		</Container>
	);
}

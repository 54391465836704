import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setAdminUsersSearchTerm } from 'store/account/admin';

export function useAdminUsersSearchTerm(): LocalOperationResult<string, (term: string) => void> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.account.admin.users.metadata.searchTerm);

	function handler(term: string) {
		dispatch(setAdminUsersSearchTerm({ term }));
	}

	return [data, handler];
}

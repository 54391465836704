import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';

interface ContainerProps {
	heightOffset: number;
}
export const Container = styled.div<ContainerProps>`
	position: sticky;
	top: ${({ heightOffset }) => heightOffset}rem;
	z-index: 2;

	display: flex;
	height: calc(100vh - ${({ heightOffset }) => `${heightOffset}rem`});
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};

	@media print {
		display: none;
	}
`;

export const Views = styled.div`
	display: flex;
	flex-direction: column;
	width: 5.6rem;
	padding: 1.6rem 0.8rem;
`;

interface ViewProps {
	isOpenedView?: boolean;
}
export const View = styled.div<ViewProps>`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 5.6rem;
	border-left: 0.1rem solid ${Colors.background.disabled};
	background-color: ${Colors.white};
	/* negate "Shadows.normal" to the left */
	box-shadow: -0.4rem 0.2rem 0.4rem -0.2rem rgb(37 37 58 / 10%);

	${({ isOpenedView }) => {
		if (isOpenedView) {
			return css`
				width: 37rem;
			`;
		}
	}}
`;

export const ViewHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.6rem;
	border-left: ${Colors.background.disabled} 0.1rem solid;
`;

export const ViewBody = styled.div<Disabled>`
	height: 100%;
	overflow-y: auto;
	border-left: ${Colors.background.disabled} 0.1rem solid;

	${({ disabled }) => disableableComponent({ disabled })}
`;

import styled, { css } from 'styled-components';

import { Colors, MediaQueries } from 'environment';

interface CompactSizeProps {
	isCompactSize: boolean;
}

export const SecurePaymentButton = styled.div<CompactSizeProps>`
	display: flex;
	justify-content: space-evenly;
	margin-right: 1.1rem;
	height: 3.2rem;
	padding: 0.8rem 0;
	border-radius: 0.4rem;

	@media ${MediaQueries.maxWidth.md} {
		margin: 0;
	}

	${({ isCompactSize }) =>
		!isCompactSize
			? css`
					border: solid 0.1rem ${Colors.vibrantGreen};
					width: 12.7rem;
			  `
			: css`
					justify-content: flex-start;
			  `}
`;

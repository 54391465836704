import { useState } from 'react';
import { throttle } from 'lodash';
import { useEffectOnce } from 'hooks/utils';
import { EventType } from 'types/index';

const events = new Set<() => void>();
const onResize = () => events.forEach(fn => fn());

export const useWindowSize = () => {
	const [size, setSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	const handle = throttle(() => {
		setSize({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}, 100);

	useEffectOnce(() => {
		if (events.size === 0) {
			window.addEventListener(EventType.Resize, onResize, true);
		}

		events.add(handle);

		return () => {
			events.delete(handle);

			if (events.size === 0) {
				window.removeEventListener(EventType.Resize, onResize, true);
			}
		};
	});

	return size;
};

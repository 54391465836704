import { Svgs } from 'environment';
import { VariablesViewOptions } from 'store/data/variables';
import { VariablesTableCheckedState } from 'hooks/store/data/variables/useVariablesTableCheckedData';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Flex } from 'components/UI/Flex';
import { InfoMessage } from 'components/UI/InfoMessage';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { Header } from 'components/Header';
import {
	useTranslation,
	usePermissions,
	useVariablesViewOption,
	useVariablesSearchTerm,
	useVariablesFilters
} from 'hooks/store';

interface Props {
	hasData: boolean;
	disabled: boolean;
	checkedState: VariablesTableCheckedState;
	variableActions: {
		onCreateVariable: () => void;
		onUpdateVariable: (variableName: string) => void;
	};
	groupActions: {
		onCreateGroup: () => void;
		onUpdateGroup: (groupName: string) => void;
	};
	variableSetActions: {
		onCreateVariableSet: () => void;
		onUpdateVariableSet: (variableName: string) => void;
	};
	onMoveSelected: () => void;
	onDeleteSelected: () => void;
	onAPIFieldsClick: () => void;
}

export function VariablesHeader({
	hasData,
	disabled,
	checkedState,
	variableActions: { onCreateVariable, onUpdateVariable },
	groupActions: { onCreateGroup, onUpdateGroup },
	variableSetActions: { onCreateVariableSet, onUpdateVariableSet },
	onMoveSelected,
	onDeleteSelected,
	onAPIFieldsClick
}: Props) {
	const { translate } = useTranslation();
	const { hasVariablesWriteAccess } = usePermissions();

	const [viewOption, setViewOption] = useVariablesViewOption();
	const [searchTerm, setSearchTerm] = useVariablesSearchTerm();
	const [{ areFiltersActive }, { clearFilters }] = useVariablesFilters();

	const isGridView = viewOption === VariablesViewOptions.GRID;
	const isTableView = viewOption === VariablesViewOptions.TABLE;

	function toggleView() {
		setViewOption(isGridView ? VariablesViewOptions.TABLE : VariablesViewOptions.GRID);
	}

	function handleUpdateGroup() {
		const groupName = checkedState.checked.groups[0];

		onUpdateGroup(groupName);
	}

	function handleUpdateVariable() {
		const variableName = checkedState.checked.variables[0];

		onUpdateVariable(variableName);
	}

	function handleUpdateVariableSet() {
		const setName = checkedState.checked.variableSets[0];

		onUpdateVariableSet(setName);
	}

	function handleClearAllFilters() {
		clearFilters();
		if (searchTerm !== '') setSearchTerm('');
	}

	function handleEditSelected() {
		if (checkedState.one.variable) return handleUpdateVariable();
		if (checkedState.one.group) return handleUpdateGroup();
		if (checkedState.one.variableSet) return handleUpdateVariableSet();
	}

	const oneRowSelected = checkedState.inTotal === 1;

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					hasData && (
						<Flex>
							<SearchInput
								usedInHeader
								placeholder={translate(dict => dict.terms.search)}
								disabled={disabled}
								term={searchTerm}
								onChangeTerm={term => term !== searchTerm && setSearchTerm(term)}
							/>

							{hasVariablesWriteAccess && (
								<Flex>
									<Dropdown
										toggleComponent={({ ref, toggle }) => (
											<div ref={ref}>
												{addButton({
													label: translate(dict => dict.terms.new),
													action: toggle
												})}
											</div>
										)}
										width={16}
										offset={{ top: 0, right: 0 }}
										disabled={disabled}
									>
										<Dropdown.Item
											dataTestId="createNewVariable"
											title={translate(dict => dict.addVariable.title)}
											disabled={disabled}
											onClick={onCreateVariable}
										/>
										<Dropdown.Item
											dataTestId="createNewVariableGroup"
											title={translate(
												dict => dict.variablesPage.newVariableGroup
											)}
											disabled={disabled}
											onClick={onCreateGroup}
										/>
										<Dropdown.Item
											dataTestId="createNewVariableSet"
											title={translate(
												dict => dict.variablesPage.newVariableSet
											)}
											disabled={disabled}
											onClick={onCreateVariableSet}
										/>
									</Dropdown>
								</Flex>
							)}
						</Flex>
					)
				}
			/>
			<Header.Title
				title={translate(dict => dict.terms.variablesToUpperCase)}
				component={
					hasData && (
						<Flex>
							{/* CLEAR FILTERS */}
							{areFiltersActive && (
								<>
									{isGridView && hasVariablesWriteAccess && (
										<Flex align={a => a.center} marginOffset={{ x: 1.6 }}>
											<InfoMessage
												message={translate(
													({ variables }) =>
														variables.header.clearFilters.message
												)}
											/>
										</Flex>
									)}
									<Button
										title={translate(
											({ variables }) => variables.header.clearFilters.button
										)}
										variant={v => v.link}
										disabled={disabled}
										marginOffset={{ right: 2.4 }}
										onClick={handleClearAllFilters}
									/>
								</>
							)}

							{isTableView && checkedState.inTotal > 0 && (
								<Flex align={a => a.center} marginOffset={{ right: 5.6 }}>
									{/* NUMBER OF ROWS SELECTED */}
									<Typography.Paragraph marginOffset={{ right: 2.4 }}>
										{checkedState.inTotal}{' '}
										{translate(dict => dict.terms.selected)}
									</Typography.Paragraph>

									{/*
										MOVE SELECTED VARIABLES TO GROUP (EXISTING OR NEW)
										NOTE: VISIBLE ONLY WHEN VARIABLES ARE SELECTED
									*/}

									<Icon
										title={translate(dict => dict.variables.move.title)}
										svg={Svgs.FolderMove}
										variant={v => v.buttonActive}
										marginOffset={{ right: 0.8 }}
										disabled={disabled}
										onClick={onMoveSelected}
									/>

									{/*
										DELETE ONE OR MANY
										NOTE: VISIBLE ONLY WHEN NON-SYSTEM GENERATED VARIABLES ARE SELECTED
									*/}
									{!checkedState.systemGenerated && (
										<Icon
											svg={Svgs.Delete}
											variant={v => v.buttonActive}
											disabled={disabled}
											onClick={onDeleteSelected}
										/>
									)}

									{/* EDIT ONE - GROUP, VARIABLE OR VARIABLE SET */}
									{oneRowSelected && (
										<Icon
											svg={Svgs.Edit}
											variant={v => v.buttonActive}
											marginOffset={{ left: 0.8 }}
											disabled={disabled}
											onClick={handleEditSelected}
										/>
									)}
								</Flex>
							)}

							<Icon
								title={translate(dict => dict.variablesPage.variablesMapping.title)}
								svg={Svgs.Api}
								variant={v => v.buttonActive}
								marginOffset={{ right: 1.6 }}
								disabled={disabled}
								onClick={onAPIFieldsClick}
							/>
							<Icon
								title={
									isGridView
										? translate(dict => dict.terms.tableView)
										: translate(dict => dict.terms.gridView)
								}
								svg={isGridView ? Svgs.ViewTable : Svgs.ViewGrid}
								variant={v => v.buttonActive}
								disabled={disabled}
								onClick={toggleView}
							/>
						</Flex>
					)
				}
			/>
		</>
	);
}

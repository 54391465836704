import styled from 'styled-components';

import { Colors, MediaQueries, Shadows } from 'environment';

export const Container = styled.div`
	position: relative;

	border-radius: 0.8rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};

	@media ${MediaQueries.minWidth.md} {
		margin: 1rem -2rem;
	}

	@media ${MediaQueries.maxWidth.md} {
		margin: 1.2rem 0 1.2rem 0;
	}
`;

export const Body = styled.div`
	padding: 0 2rem 1rem;
`;

export const Divider = styled.div`
	height: 0.1rem;
	background-color: #ededed;
	margin-top: 0.8rem;
`;

import { useState } from 'react';
import { FilterIcon } from 'components/UI/Icons';
import { Svgs, Colors } from 'environment';
import type { TextTableFilter } from 'store/ui/tables/types';
import { InputType } from 'types/index';
import type { TableFilterProps } from '../TableFilter';
import { Dropdown, DropdownHeader, DropdownTitle, ResetFilterIcon } from '../TableFilter.style';
import { Flex } from 'components/UI/Flex';
import { Input } from 'components/UI/Inputs/Input';
import { useTranslation } from 'hooks/store';
import { useDebounce } from 'hooks/utils';

interface Props extends TableFilterProps {
	filter: TextTableFilter;
}

export function TextFilter({
	filter,
	isLastColumn,
	offset,
	title,
	width,
	onOpen,
	onClose,
	updateFilter
}: Props) {
	const { translate } = useTranslation();
	const [forceClose, setForceClose] = useState(false);

	const [searchText, setSearchText] = useState(filter.text);

	function updateSearchText(text: string) {
		setSearchText(text);
	}

	useDebounce(
		() => {
			if (filter.text !== searchText) updateFilter({ ...filter, text: searchText });
		},
		[searchText, filter],
		400
	);

	function reset() {
		if (searchText) {
			setSearchText('');
		}
		setForceClose(true);
	}
	const dropdownOffset = isLastColumn ? offset : undefined;
	return (
		<Dropdown
			usedForCustomisableTable
			width={width}
			forceClose={forceClose}
			// offset={offset}
			offset={dropdownOffset}
			toggleComponent={({ ref, open, toggle }) => (
				<FilterIcon
					dataTestId="filter-icon"
					ref={ref}
					active={open || !!filter.text}
					onClick={toggle}
				/>
			)}
			setForceClose={setForceClose}
			onOpen={onOpen}
			onClose={onClose}
		>
			<>
				<DropdownHeader onClick={e => e.stopPropagation()}>
					<DropdownTitle>{title}</DropdownTitle>
					<ResetFilterIcon
						svg={Svgs.Delete}
						colors={{
							color: Colors.text.disabled,
							hover: Colors.primary.normal
						}}
						onClick={reset}
					/>
				</DropdownHeader>
				<Flex paddingOffset={{ x: 1, bottom: 1 }}>
					<Input
						dataTestId="text-filter-input"
						type={InputType.Text}
						value={searchText}
						placeholder={translate(dict => dict.tableLists.search)}
						onChange={e => updateSearchText(e.target.value)}
					/>
				</Flex>
			</>
		</Dropdown>
	);
}

import { Role } from 'store/data/roles';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteRole, useDeleteEnterpriseRole } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { EnterpriseRole } from 'store/account/enterprise';
import { isProjectRoleType } from 'components/Account/EnterpriseRoles/helpers';
import { useAlerts } from 'hooks/ui';

interface Props {
	role: Role | EnterpriseRole;
	onClose: (success?: boolean) => void;
}

export function DeleteRoleModal({ role, onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [{ loading: deletingRole, error: errorDeletingRole }, deleteRole] = useDeleteRole();
	const [
		{ loading: deletingEnterpriseRole, error: errorDeletingEnterpriseRole },
		deleteEnterpriseRole
	] = useDeleteEnterpriseRole();

	const projectRoleType = isProjectRoleType(role);
	const deleting = projectRoleType ? deletingRole : deletingEnterpriseRole;
	const errorDeleting = projectRoleType ? errorDeletingRole : errorDeletingEnterpriseRole;

	useCompletedAction(deleting, errorDeleting, () => {
		if (!projectRoleType) {
			setNotification({
				message: translate(
					({ enterpriseAdmin }) => enterpriseAdmin.rolesModules.api.roleDeletedSuccesfully
				)
			});
		}
		onClose(true);
	});

	function handleDelete() {
		if (projectRoleType) {
			const { id } = role;
			deleteRole(id);
		} else {
			const { roleId } = role;
			if (roleId) deleteEnterpriseRole(roleId);
		}
	}

	const title = `${translate(dict => dict.terms.delete)} ${
		projectRoleType ? role.name : role.roleName
	}?`;

	return (
		<>
			<Modal
				size={s => s.s}
				title={title}
				primary={{
					label: translate(dict => dict.buttons.delete),
					warning: true,
					loading: deletingRole,
					disabled: deleting,
					onClick: handleDelete
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: onClose
				}}
				onClose={onClose}
				enterAsPrimaryOnClick
				visible
				close
			>
				<Typography.Paragraph>
					{translate(dict => dict.rolesPage.deleteRoleModal.message)}
				</Typography.Paragraph>
			</Modal>
		</>
	);
}

import { createSelector } from 'reselect';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getActiveSort(state: State, tableName: string) {
	return state.byName[tableName] ? state.byName[tableName].activeSort : undefined;
}

function getPagination(state: State, tableName: string) {
	return state.byName[tableName]
		? {
				pageSize: state.byName[tableName].pageSize,
				pageIndex: state.byName[tableName].pageIndex
		  }
		: { pageSize: null, pageIndex: 0 };
}

function getVisibleColumns(state: State, tableName: string) {
	return state.byName[tableName] ? state.byName[tableName].visibleColumns : [];
}

function getTableFilters(state: State, tableName: string) {
	return state.byName[tableName] ? state.byName[tableName].filtersByColumnName : {};
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectActiveSort = createSelector([getActiveSort], activeSort => activeSort);

export const selectPagination = createSelector([getPagination], pageSize => pageSize);

export const selectVisibleColumns = createSelector(
	[getVisibleColumns],
	visibleColumns => visibleColumns
);

export const selectTableFilters = createSelector(
	[getTableFilters],
	visibleColumns => visibleColumns
);

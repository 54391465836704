import { useEffect, useMemo, useState } from 'react';
import { OperationResult } from 'hooks/store/types';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import {
	ActionTypes,
	UserLicenceModel,
	getLicenceModels,
	UserLicenceLabels,
	SubscriptionLicenceModel,
	LicenceModelQuantities
} from 'store/account/subscription';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { GenericMap, StorageKeys } from 'types';
import { useSubscription } from './useSubscription';
import { useSubscriptionBundleFlag } from './useSubscriptionBundleFlag';
import { useAccount } from './useAccount';
import { mapLicenceModelIdQuantitiesToLicenceModelQuantities } from 'helpers/subscription';

type LicenceModelsType = {
	allLicenceOptions: LicenceOption[]; // contains all ledidi licence models name and label
	availableLicenceOptions: LicenceOption[]; // contains licence models name and label available for the subscription
	licenceModelsMetadataById: GenericMap<SubscriptionLicenceModel>; // all licence ids with prices per unit

	// old version
	totalLicencesCount: number;
	usedLicencesCount: number;
	availableLicencesCount: number;
	// bundle version
	licenceQuantitiesByName?: LicenceModelQuantities; // by licence model name
	usedLicenceQuantitiesByName?: LicenceModelQuantities; // by licence model name
	availableLicenceQuantitiesByName?: LicenceModelQuantities; // by licence model name
	// from subscriptionDetails
	licenceQuantitiesById?: LicenceModelQuantities;
	availableLicenceQuantitiesById?: LicenceModelQuantities;
};

type LicenceOption = { label: string; value: string };

export function useLicenceModels(): OperationResult<LicenceModelsType> {
	const { translate } = useTranslation();

	const dispatch = useDispatch();

	const isUserAdmin = !!localStorage.getItem(StorageKeys.IsUserAdmin);

	const [allLicenceOptions, setAllLicenceOptions] = useState<LicenceOption[]>([]);
	const [availableLicenceOptions, setAvailableLicencesOptions] = useState<LicenceOption[]>([]);

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();
	const [{ data: userAccountData }] = useAccount();

	const { licenceModelsMetadataById, allLicenceModels, fetched } = useSelector(
		state => state.account.subscription.licenceModels
	);

	const [
		{
			data: {
				details: subscriptionDetails,
				subscriptionTypes: { isLedidiFree, isLedidiFreeTrial, isNonSubscriber },
				userTypesAndRoles: {
					isPaidSubscriptionOwner,
					ledidiEnterprise: {
						modules: { hasEnterpriseAdminRights }
					}
				}
			}
		}
	] = useSubscription();

	const [{ loading, error }] = useActivity(ActionTypes.GET_LICENCE_MODELS);

	useEffect(() => {
		if (!fetched && !loading && !error) handler();
	}, [fetched, loading, error]);

	function handler() {
		// TODO: this will be taken from getUserSubscription in the future
		// for now only user admins need this call
		if (isUserAdmin) dispatch(getLicenceModels());
	}

	useEffect(() => {
		if (fetched) {
			const options: LicenceOption[] = allLicenceModels.map(licenceModel => ({
				label:
					translate(() => UserLicenceLabels[licenceModel as UserLicenceModel]) ??
					licenceModel,
				value: licenceModel
			}));

			setAllLicenceOptions(options);
		} else if (!isUserAdmin && !subscriptionBundleFlag) {
			// for now the existing licence models were set on FE
			setAllLicenceOptions([
				{
					label:
						translate(() => UserLicenceLabels[UserLicenceModel.OneOwnedOneShared]) ??
						UserLicenceModel.OneOwnedOneShared,
					value: UserLicenceModel.OneOwnedOneShared
				},
				{
					label:
						translate(() => UserLicenceLabels[UserLicenceModel.Trial]) ??
						UserLicenceModel.Trial,
					value: UserLicenceModel.Trial
				},
				{
					label:
						translate(() => UserLicenceLabels[UserLicenceModel.Collaborator]) ??
						UserLicenceModel.Collaborator,
					value: UserLicenceModel.Collaborator
				},
				{
					label:
						translate(() => UserLicenceLabels[UserLicenceModel.Full]) ??
						UserLicenceModel.Full,
					value: UserLicenceModel.Full
				}
			]);
		}
	}, [allLicenceModels, fetched]);

	useEffect(() => {
		const options: LicenceOption[] = [];
		const availableLicenceQuantities =
			subscriptionDetails && subscriptionDetails.availableLicenceQuantities;

		if (subscriptionBundleFlag && availableLicenceQuantities) {
			Object.keys(availableLicenceQuantities).forEach(licenceModelKey => {
				if (
					licenceModelsMetadataById[licenceModelKey] &&
					availableLicenceQuantities[licenceModelKey] > 0
				) {
					const licenceModel = licenceModelsMetadataById[licenceModelKey].licenceModel;
					options.push({
						label:
							translate(() => UserLicenceLabels[licenceModel as UserLicenceModel]) ??
							licenceModel,
						value: licenceModel
					});
				}
			});

			setAvailableLicencesOptions(options);
		} else {
			// for old version when we don't use bundle subscription, return default licences
			setAvailableLicencesOptions([
				{
					label: translate(() => UserLicenceLabels[UserLicenceModel.Full]),
					value: UserLicenceModel.Full
				},
				{
					label: translate(() => UserLicenceLabels[UserLicenceModel.Collaborator]),
					value: UserLicenceModel.Collaborator
				}
			]);
		}
	}, [subscriptionDetails, subscriptionBundleFlag, licenceModelsMetadataById]);

	// Licences
	const isNoLicenceAccount = useMemo(
		() => isLedidiFree || isLedidiFreeTrial || isNonSubscriber,
		[isLedidiFree, isLedidiFreeTrial, isNonSubscriber]
	);
	const isPaidUserAccount = useMemo(
		() => !isPaidSubscriptionOwner && !hasEnterpriseAdminRights,
		[isPaidSubscriptionOwner, hasEnterpriseAdminRights]
	);

	let licenceQuantitiesByName: LicenceModelQuantities = {};
	let usedLicenceQuantitiesByName: LicenceModelQuantities = {};
	let availableLicenceQuantitiesByName: LicenceModelQuantities = {};
	if (subscriptionBundleFlag) {
		if (isPaidUserAccount && userAccountData.details && userAccountData.details.licenceModel) {
			// if the logged in user is part of a subscription and doesn't have any admin rights, set only his licence
			licenceQuantitiesByName = { [userAccountData.details.licenceModel]: 1 };
			usedLicenceQuantitiesByName = { [userAccountData.details.licenceModel]: 1 };
		} else if (
			subscriptionDetails &&
			subscriptionDetails.licenceQuantities &&
			subscriptionDetails.availableLicenceQuantities
		) {
			licenceQuantitiesByName = mapLicenceModelIdQuantitiesToLicenceModelQuantities(
				subscriptionDetails.licenceQuantities,
				licenceModelsMetadataById
			);
			availableLicenceQuantitiesByName = mapLicenceModelIdQuantitiesToLicenceModelQuantities(
				subscriptionDetails.availableLicenceQuantities,
				licenceModelsMetadataById
			);
			Object.keys(licenceQuantitiesByName).forEach(key => {
				usedLicenceQuantitiesByName = {
					...usedLicenceQuantitiesByName,
					[key]: availableLicenceQuantitiesByName[key]
						? licenceQuantitiesByName[key] - availableLicenceQuantitiesByName[key]
						: licenceQuantitiesByName[key]
				};
			});
		}
	}
	const totalLicencesCount =
		isNoLicenceAccount || !subscriptionDetails
			? 0
			: isPaidUserAccount
			? 1
			: calculateLicencesQuantity(
					subscriptionDetails && subscriptionDetails.licenceQuantity
						? subscriptionDetails.licenceQuantity
						: 0,
					subscriptionDetails.licenceQuantities
			  );

	const availableLicencesCount =
		isNoLicenceAccount || isPaidUserAccount || !subscriptionDetails
			? 1
			: calculateLicencesQuantity(
					subscriptionDetails && subscriptionDetails.availableLicenceQuantity
						? subscriptionDetails.availableLicenceQuantity
						: 0,
					subscriptionDetails.availableLicenceQuantities
			  );

	const usedLicencesCount = isNoLicenceAccount
		? 0
		: isPaidUserAccount
		? 1
		: calculateLicencesQuantity(
				subscriptionDetails &&
					subscriptionDetails.availableLicenceQuantity !== undefined &&
					subscriptionDetails.licenceQuantity !== undefined
					? subscriptionDetails.licenceQuantity -
							subscriptionDetails.availableLicenceQuantity
					: 0,
				usedLicenceQuantitiesByName
		  );

	function calculateLicencesQuantity(licences: number, bundleLicences?: LicenceModelQuantities) {
		if (subscriptionBundleFlag && bundleLicences) {
			let sum = 0;
			Object.values(bundleLicences).forEach(v => (sum = sum + v));
			return sum;
		}
		return licences;
	}

	return [
		{
			data: {
				allLicenceOptions,
				availableLicenceOptions,
				licenceModelsMetadataById,
				totalLicencesCount,
				availableLicencesCount,
				usedLicencesCount,
				licenceQuantitiesByName,
				availableLicenceQuantitiesByName,
				usedLicenceQuantitiesByName,
				licenceQuantitiesById: subscriptionDetails?.licenceQuantities,
				availableLicenceQuantitiesById: subscriptionDetails?.availableLicenceQuantities
			},
			loading,
			error,
			fetched: fetched
		},
		handler
	];
}

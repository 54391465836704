import {
	GetPlotNumericResponseV2,
	GroupIdentifier,
	PlotNumericBoxplotGroupedDataV2,
	PlotNumericBoxplotUngroupedDataV2,
	PlotNumericColumnsGroupedDataV2,
	PlotNumericColumnsUngroupedDataV2,
	PlotNumericResultsV2,
	PlotNumericScatterGroupedResultV2
} from 'api/data/analyses';

// type guards
// phase 2 w/o grouping
// function isPlotNumericGroupedData(
// 	data: GetPlotNumericResponseV2['data']['result']
// ): data is PlotNumericBoxplotGroupedDataV2[] &
// 	PlotNumericColumnsGroupedDataV2[] &
// 	PlotNumericScatterResultsV2['result'] {
// 	return 'length' in data;
// }

function isBoxplotData(
	data: GetPlotNumericResponseV2['data']['result']
): data is PlotNumericBoxplotGroupedDataV2[] & PlotNumericBoxplotUngroupedDataV2 {
	if ('length' in data && data[0] && 'firstQuartile' in data[0].values) {
		return true;
	} else {
		return 'firstQuartile' in data;
	}
}

function isColumnsData(
	data: GetPlotNumericResponseV2['data']['result']
): data is PlotNumericColumnsGroupedDataV2[] & PlotNumericColumnsUngroupedDataV2 {
	if ('length' in data && data[0] && 'medianIQR' in data[0].values) {
		return true;
	} else {
		return 'medianIQR' in data;
	}
}

function isScatterData(
	data: GetPlotNumericResponseV2['data']['result']
): data is PlotNumericScatterGroupedResultV2[] {
	return 'length' in data && data[0] && 'numericValues' in data[0].values;
}

const DEFAULT_GROUP: GroupIdentifier = {
	value: 'default',
	variable: {
		name: 'default'
	}
};

export function parsePlotNumericResponse(res: GetPlotNumericResponseV2): PlotNumericResultsV2 {
	const result: PlotNumericResultsV2 = {
		scatter: { data: [] },
		boxplot: { data: [] },
		columns: { data: [] }
	};

	if (isColumnsData(res.data.result)) {
		// CANNOT BE UNGROUEPD
		const groupedData = res.data.result as PlotNumericColumnsGroupedDataV2[];

		result.columns.data = groupedData.map(data => {
			const { errors, ...columnData } = data.values;

			const group1: GroupIdentifier = data.groupIdentifiers?.[0] ?? DEFAULT_GROUP;
			const group2 = data.groupIdentifiers?.[1];

			return { ...columnData, group1, group2 };
		});
	}

	if (isBoxplotData(res.data.result)) {
		const groupedData = res.data.result as PlotNumericBoxplotGroupedDataV2[];
		result.boxplot.data = groupedData.map(data => {
			const { errors, ...plotData } = data.values;
			const group1 = data.groupIdentifiers[0] ?? DEFAULT_GROUP;
			const group2 = data.groupIdentifiers[1];
			return { ...plotData, group1, group2 };
		});
	}

	if (isScatterData(res.data.result)) {
		// if (isPlotNumericGroupedData(res.data.result)) {
		const groupedData = res.data.result as PlotNumericScatterGroupedResultV2[];
		result.scatter.data = groupedData.map(data => {
			const {
				values,
				groupIdentifiers: [group1]
			} = data;
			return { ...values, group1 };
		});
		// }
	}

	return result;
}

import { useEffect } from 'react';
import { nanoid as generate } from 'nanoid';
import { ToastContainer as Container, toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DEFAULT_ACTIVITY_TIMEOUT } from 'consts';
import { ActionTypes, clearError, selectLatestError } from 'store/ui/activities';
import { AlertType } from 'types/index';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { AlertsContext, SetAlertProps } from 'hooks/ui';
import styled from 'styled-components';
import { DataTestId } from 'tests/consts';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';

interface Props {
	children: React.ReactNode;
}

export function Alerts({ children }: Props) {
	const { translate } = useTranslation();
	const dispatch = useDispatch();

	const error = useSelector(state => selectLatestError(state.ui.activities));

	function setAlert(type: AlertType, { message, timeout }: SetAlertProps) {
		const toastType: {
			notification: TypeOptions;
			error: TypeOptions;
		} = {
			[AlertType.Notification]: 'info',
			[AlertType.Error]: 'error'
		};
		const generatedId =
			message.length > 10
				? message
						.replace(/[^a-zA-Z0-9]/g, '')
						.substring(0, 10)
						.toLowerCase()
				: 'toastMessage';

		const id = generate();
		const JXSMessage = (
			<Typography.Notification id={generatedId} data-testid={DataTestId.Alert}>
				{message}
			</Typography.Notification>
		);

		toast(JXSMessage, {
			type: toastType[type],
			toastId: id,
			updateId: id,
			autoClose: timeout ?? DEFAULT_ACTIVITY_TIMEOUT,
			position: 'top-center',
			closeOnClick: false
		});
	}

	/**
	 * Handles incoming errors from redux
	 */
	useEffect(() => {
		if (!error) return;

		const { uuid, timeout } = error;
		/**
		 * This should be a temporary solution for throwing a different message when importing files that are too large
		 *
		 * The errors are thrown by the axios request from network.ts at line 149
		 */
		const fileTooLarge =
			(error.type === 'data/projects/CONVERT_XLS_TO_CSV' ||
				error.type === 'data/projects/UPLOAD_PROJECT_DATASET') &&
			error.error === 'timeout of 29000ms exceeded';

		const message = fileTooLarge ? 'Import timed out - file may be too large' : error.error;

		if (message && message.length) {
			const translatedMessage = translate(() => message, true);
			const generatedId =
				message.length > 10
					? message
							.replace(/[^a-zA-Z0-9]/g, '')
							.substring(0, 10)
							.toLowerCase()
					: 'toastMessage';

			const JXSMessage = (
				<Typography.Notification id={generatedId} data-testid={DataTestId.Alert}>
					{translatedMessage}
				</Typography.Notification>
			);

			toast.error(JXSMessage, {
				toastId: uuid,
				updateId: uuid,
				autoClose: timeout ?? DEFAULT_ACTIVITY_TIMEOUT,
				position: 'top-center',
				closeOnClick: false
			});
		}
	}, [error]);

	function handleClearError() {
		if (!error) return;
		const { uuid } = error;
		dispatch(clearError({ uuid, type: ActionTypes.CLEAR_ERROR }));
	}

	return (
		<>
			<ToastContainer
				closeButton={({ closeToast }) => {
					const handleCloseToast = (e: React.MouseEvent<Element, MouseEvent>) => {
						closeToast(e as React.MouseEvent<HTMLElement, MouseEvent>);
						handleClearError();
					};
					return <CloseToastIcon closeToast={handleCloseToast} />;
				}}
			/>

			<AlertsContext.Provider
				value={{
					setNotification: (props: SetAlertProps) =>
						setAlert(AlertType.Notification, props),
					setError: (props: SetAlertProps) => setAlert(AlertType.Error, props)
				}}
			>
				{children}
			</AlertsContext.Provider>
		</>
	);
}

function CloseToastIcon({ closeToast }: { closeToast: (e: React.MouseEvent) => void }) {
	return (
		<CloseIcon svg={Svgs.Close} size={s => s.m} variant={v => v.button} onClick={closeToast} />
	);
}

const ToastContainer = styled(Container)`
	white-space: pre-line;
`;

const CloseIcon = styled(Icon)`
	align-self: flex-start;
	&:hover {
		background-color: unset;
	}
`;

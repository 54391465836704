import { CrosstabAnalysisV2 } from 'api/data/analyses';
import { Gap } from 'components/UI/Gap';

import { CrosstabChiSquareV2 } from './CrosstabChiSquareV2';
import { CrosstabFisherV2 } from './CrosstabFisherV2';
import { CrosstabMcNemarV2 } from './CrosstabMcNemarV2';

interface Props {
	analysis: CrosstabAnalysisV2;
}

export function CrosstabStatisticsV2({ analysis }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		fisher: {
			visible: inputStatistics.fisherV2,
			results: outputStatistics.fisher
		},
		mcNemar: {
			visible: inputStatistics.mcNemarV2,
			results: outputStatistics.mcNemar
		},
		chiSquare: {
			visible: inputStatistics.chiSquareV2,
			results: outputStatistics.chiSquare
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.fisher.visible && <CrosstabFisherV2 results={statistics.fisher.results} />}

			{statistics.mcNemar.visible && (
				<CrosstabMcNemarV2 results={statistics.mcNemar.results} />
			)}

			{statistics.chiSquare.visible && (
				<CrosstabChiSquareV2 results={statistics.chiSquare.results} />
			)}
		</Gap>
	);
}

import styled from 'styled-components';

import { Input as DefaultInput } from 'components/UI/Inputs/Input';
import { Typography, getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Title = styled(Typography.H3)``;

export const TitleInput = styled(DefaultInput)`
	cursor: text;

	textarea {
		${getTypographyCSS(t => t.H3)}

		cursor: text;
		background-color: ${Colors.white};
		padding: 0;
		border: none;
		width: 100%;
		max-width: 100%;
		transition: 0.1s;
		resize: none;
		max-height: 100%;
		word-break: break-word;
		border-radius: unset;
	}
`;

import styled, { css } from 'styled-components';

interface ListProps {
	noMargin?: boolean;
	extraMarginTop?: boolean;
}

export const List = styled.div<ListProps>`
	display: flex;
	flex-direction: column;

	${({ noMargin }) =>
		noMargin &&
		css`
			margin-top: 0rem;
		`};
`;

export const FlexRow = styled.div<ListProps>`
	display: flex;
	margin-bottom: 2rem;

	${({ noMargin }) =>
		noMargin &&
		css`
			margin-bottom: 0rem;
		`};

	${({ extraMarginTop }) =>
		extraMarginTop &&
		css`
			margin-top: 4rem;
		`};
`;

export const FlexCellLabel = styled.div`
	flex: 1;
	margin-right: 1rem;
	min-width: 0;
`;

export const FlexCellType = styled.div`
	flex: 1;
	margin-left: 1rem;
	position: relative;
	min-width: 0;
`;

export const Spacer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 4rem;
	width: 4rem;
	justify-content: center;
	align-items: center;
`;

import { convertProject, ActionTypes } from 'store/data/projects';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { PromDistributionTypes } from 'types/index';
import { ProjectType } from 'types/data/projects/constants';

type HandlerInput = {
	projectId: string;
	promType: PromDistributionTypes;
	projectType: ProjectType;
};

export function useConvertProject(): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CONVERT_PROJECT);

	function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(convertProject(input));
	}

	return [{ loading, error }, handler];
}

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useActivity } from 'hooks/store';
import { useCompletedAction, useDispatch, useSelector } from 'hooks/utils';
import { useState } from 'react';
import { ActionTypes, deleteSnapshot, selectSnapshotById } from 'store/data/snapshots';

interface Props {
	snapshotId: string;
	onClose(success?: boolean): void;
}

export function DeleteSnapshotModal({ snapshotId, onClose }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.DELETE_SNAPSHOT);

	useCompletedAction(loading, error, () => onClose(true));

	const snapshot = useSelector(state => selectSnapshotById(state.data.snapshots, snapshotId));

	const [draft] = useState(snapshot);

	const title = `Delete snapshot ${draft.snapName}?`;

	function handleSubmit() {
		if (loading) return;
		if (error) resetError();

		dispatch(deleteSnapshot(snapshotId));
	}

	return (
		<Modal
			title={title}
			primary={{
				label: translate(dict => dict.buttons.delete),
				loading,
				warning: true,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				{translate(({ analysis }) => analysis.snapshots.delete)}
			</Typography.Paragraph>
		</Modal>
	);
}

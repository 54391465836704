import { createSelector } from 'reselect';

import { JADBioAnalysis, JADBioAnalysisModels } from 'api/data/analyses';
import { selectParams } from 'store/utils';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getByProjectId({ byProjectId, projectId }: State) {
	if (projectId && projectId in byProjectId) return byProjectId[projectId];
}

function getById({ byId }: State) {
	return byId;
}

function getAnalysisFetched({ byId }: State, id: string) {
	return byId[id].fetched;
}

function getAnalysisActiveTab({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.activeTab;
}

function getAnalysisConfigPanel({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.configPanel.open;
}

function getJadBioPerformance({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.configPanel.jadBioPerformance;
}

function getAnalysisFormating({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.configPanel.formatting;
}

function getAnalysisParameters({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.configPanel.parameters;
}

function getAnalysisChartType({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.configPanel.chartType;
}

function getAnalysisOpenState({ byId }: State, analysisId: string) {
	return byId[analysisId] && byId[analysisId].options.open;
}

function getJADBioDatasetInitialized(state: State, analysisId: string | null) {
	if (analysisId && state.byId[analysisId]) {
		const analysis = state.byId[analysisId] as JADBioAnalysis;
		return analysis.input.datasetInitialized;
	}

	return null;
}

function getJADBioDatasetProgress(
	state: State,
	analysisId: string | null,
	model: JADBioAnalysisModels
) {
	if (analysisId && state.byId[analysisId]) {
		const analysis = state.byId[analysisId] as JADBioAnalysis;
		return analysis.output.dataset[model].progress;
	}

	return null;
}

function getAnalysisPlots(state: State, id: string) {
	return state.plots[id];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectIsAnalysisFetched = createSelector([getAnalysisFetched], fetched => fetched);

type SelectAnalysisByIdParams = { id: string };

/**
 * Make it a factory in order to remove the cahce size of `1`
 *
 * This enforces the usage to be inside a `useMemo` with [] dependency array
 */
export const selectAnalysisById = () =>
	createSelector(
		[
			getById,
			// PARAMS
			(_: State, params: SelectAnalysisByIdParams) => selectParams.encode(params)
		],
		(byId, params) => {
			const { id } = selectParams.decode<SelectAnalysisByIdParams>(params);

			return byId[id];
		}
	);

export const selectActiveAnalysisIds = createSelector(
	[getByProjectId],
	byProjectId => byProjectId?.active ?? []
);

export const selectAnalysisActiveTab = createSelector(
	[getAnalysisActiveTab],
	activeTab => activeTab
);

export const selectAnalysisConfigPanel = createSelector(
	[getAnalysisConfigPanel],
	activeTab => activeTab
);

export const selectJadBioPerformance = createSelector(
	[getJadBioPerformance],
	activeTab => activeTab
);

export const selectAnalysisFormatting = createSelector(
	[getAnalysisFormating],
	activeTab => activeTab
);

export const selectAnalysisParameters = createSelector(
	[getAnalysisParameters],
	activeTab => activeTab
);

export const selectAnalysisChartTypes = createSelector(
	[getAnalysisChartType],
	activeTab => activeTab
);

export const selectAnalysisOpenState = createSelector(
	[getAnalysisOpenState],
	activeTab => activeTab
);

export const selectJADBioDatasetInitialized = createSelector(
	[getJADBioDatasetInitialized],
	datasetInitialized => datasetInitialized
);

export const selectJADBioStopProgress = createSelector(
	(state: State, analysisId: string, jadBioModel: JADBioAnalysisModels | null) => {
		if (jadBioModel && state.byId[analysisId]) {
			const analysis = state.byId[analysisId] as JADBioAnalysis;
			return analysis.output.dataset[jadBioModel].stopProgress;
		}

		return null;
	},
	datasetInitialized => datasetInitialized
);

export const selectJADBioDatasetProgress = createSelector(
	[getJADBioDatasetProgress],
	datasetInitialized => datasetInitialized
);

export const selectChartState = createSelector([getAnalysisPlots], plots => plots);

import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { generateSpacingOffsets } from 'helpers/cssGenerators';
import { SpacingOffsets } from 'types/index';

export const Container = styled.div<SpacingOffsets>`
	border: 0.1rem solid ${Colors.text.disabled};
	border-radius: 0.4rem;
	padding: 0.2rem 0.8rem;
	background-color: ${Colors.background.disabled};
	cursor: help;

	${({ paddingOffset, marginOffset }) => css`
		/* SPACING OFFSETS */
		${generateSpacingOffsets({ paddingOffset, marginOffset })}
	`};
`;

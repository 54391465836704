import styled, { css } from 'styled-components';

export function CSSGrid() {
	return null;
}

export enum Breakpoints {
	PHONE = '676px',
	TABLET_PORTRAIT = '768px',
	TABLET_LANDSCAPE = '992px',
	SMALL_LAPTOP = '1025px',
	FHD_MONITOR = '1920px',
	TWO_K_MONITOR = '2560px',
	FOUR_K_MONITOR = '3840px'
}

/**
 * // percentages of vw
 */
const GAP = {
	[Breakpoints.PHONE]: 0,
	[Breakpoints.TABLET_PORTRAIT]: 2.2,
	[Breakpoints.TABLET_LANDSCAPE]: 2.2,
	[Breakpoints.SMALL_LAPTOP]: 1.6,
	[Breakpoints.FHD_MONITOR]: 2.2,
	[Breakpoints.TWO_K_MONITOR]: 2.2,
	[Breakpoints.FOUR_K_MONITOR]: 2.2
};

// margin-left + margin-right
const MARGIN = {
	[Breakpoints.PHONE]: 9.375,
	[Breakpoints.TABLET_PORTRAIT]: 9.375,
	[Breakpoints.TABLET_LANDSCAPE]: 7.8,
	[Breakpoints.SMALL_LAPTOP]: 7.8,
	[Breakpoints.FHD_MONITOR]: 7.8,
	[Breakpoints.TWO_K_MONITOR]: 7.29,
	[Breakpoints.FOUR_K_MONITOR]: 7.29
};

/**
 * integer
 */
const COLUMNS = {
	[Breakpoints.PHONE]: 1,
	[Breakpoints.TABLET_PORTRAIT]: 6,
	[Breakpoints.TABLET_LANDSCAPE]: 12,
	[Breakpoints.SMALL_LAPTOP]: 12,
	[Breakpoints.FHD_MONITOR]: 12,
	[Breakpoints.TWO_K_MONITOR]: 12,
	[Breakpoints.FOUR_K_MONITOR]: 16
};

interface ColProps {
	sizes?: {
		xl: number;
		l: number;
		m: number;
	};
	offsets?: {
		xl?: number;
		l?: number;
		m?: number;
	};
	noGutter?: boolean;
	displayGridColumn0?: boolean;
}

interface ContainerProps {
	noGutter?: boolean;
	displayGridColumn0?: boolean;
}

const Container = styled.div<ContainerProps>`
	display: grid;
	margin: ${`0 ${MARGIN[Breakpoints.PHONE] / 2}vw`};
	gap: 1.6vw;
	grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.PHONE]}, 1fr)`};
	grid-auto-flow: column;
	position: relative;

	@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
		margin: ${`0 ${MARGIN[Breakpoints.TABLET_PORTRAIT] / 2}vw`};
		gap: ${GAP[Breakpoints.TABLET_PORTRAIT]}vw;
		grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.TABLET_PORTRAIT]}, 1fr)`};

		${({ noGutter }) =>
			noGutter &&
			css`
				margin: 0;
			`}
	}
	@media (min-width: ${Breakpoints.TABLET_LANDSCAPE}) {
		margin: ${`0 ${MARGIN[Breakpoints.TABLET_LANDSCAPE] / 2}vw`};
		gap: ${GAP[Breakpoints.TABLET_LANDSCAPE]}vw;
		grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.TABLET_LANDSCAPE]}, 1fr)`};

		${({ noGutter }) =>
			noGutter &&
			css`
				margin: 0;
			`}
	}

	@media (min-width: ${Breakpoints.SMALL_LAPTOP}) {
		margin: ${`0 ${MARGIN[Breakpoints.SMALL_LAPTOP] / 2}vw`};
		gap: ${GAP[Breakpoints.SMALL_LAPTOP]}vw;
		grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.SMALL_LAPTOP]}, 1fr)`};

		${({ noGutter }) =>
			noGutter &&
			css`
				margin: 0;
			`}
	}
	@media (min-width: ${Breakpoints.FHD_MONITOR}) {
		margin: ${`0 ${MARGIN[Breakpoints.FHD_MONITOR] / 2}vw`};
		gap: ${GAP[Breakpoints.FHD_MONITOR]}vw;
		grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.FHD_MONITOR]}, 1fr)`};

		${({ noGutter }) =>
			noGutter &&
			css`
				margin: 0;
			`}
	}

	@media (min-width: ${Breakpoints.TWO_K_MONITOR}) {
		margin: ${`0 ${MARGIN[Breakpoints.TWO_K_MONITOR] / 2}vw`};
		gap: ${GAP[Breakpoints.TWO_K_MONITOR]}vw;
		grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.TWO_K_MONITOR]}, 1fr)`};

		${({ noGutter }) =>
			noGutter &&
			css`
				margin: 0;
			`}
	}
	@media (min-width: ${Breakpoints.FOUR_K_MONITOR}) {
		margin: ${`0 ${MARGIN[Breakpoints.FOUR_K_MONITOR] / 2}vw`};
		gap: ${GAP[Breakpoints.FOUR_K_MONITOR]}vw;
		grid-template-columns: ${`repeat(${COLUMNS[Breakpoints.FOUR_K_MONITOR]}, 1fr)`};

		${({ noGutter }) =>
			noGutter &&
			css`
				margin: 0;
			`}
	}
`;

/**
 * @sizes
 *   m: 768px -> 992px
 *   l: 992px -> 2560px
 *   xl: >2560px
 */
const Col = styled.div<ColProps>`
	display: block;
	grid-column: ${({ sizes }) => `span ${sizes?.m ?? 1}`};
	${({ offsets, sizes }) =>
		offsets?.m !== undefined &&
		css`
			grid-column: ${`${offsets.m + 1} / span ${sizes?.m ?? 1}`};
		`};
	${({ displayGridColumn0, sizes }) =>
		!displayGridColumn0 &&
		sizes?.m === 0 &&
		css`
			display: none;
		`}

	@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.m === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`};
	}

	@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
		display: block;
		grid-column: ${({ sizes }) => `span ${sizes?.m ?? 1}`};
		${({ offsets, sizes }) =>
			offsets?.m !== undefined &&
			css`
				grid-column: ${`${offsets.m + 1} / span ${sizes?.m ?? 1}`};
			`};

		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.m === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`}
	}

	@media (min-width: ${Breakpoints.TABLET_LANDSCAPE}) {
		display: block;
		grid-column: ${({ sizes }) => `span ${sizes?.l ?? 1}`};
		${({ offsets, sizes }) =>
			offsets?.l !== undefined &&
			css`
				grid-column: ${`${offsets.l + 1} / span ${sizes?.l ?? 1}`};
			`};

		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.l === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`}
	}

	@media (min-width: ${Breakpoints.SMALL_LAPTOP}) {
		display: block;
		grid-column: ${({ sizes }) => `span ${sizes?.l ?? 1}`};
		${({ offsets, sizes }) =>
			offsets?.l !== undefined &&
			css`
				grid-column: ${`${offsets.l + 1} / span ${sizes?.l ?? 1}`};
			`};

		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.l === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`}
	}

	@media (min-width: ${Breakpoints.FHD_MONITOR}) {
		display: block;
		grid-column: ${({ sizes }) => `span ${sizes?.l ?? 1}`};
		${({ offsets, sizes }) =>
			offsets?.l !== undefined &&
			css`
				grid-column: ${`${offsets.l + 1} / span ${sizes?.l ?? 1}`};
			`};

		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.l === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`}
	}
	@media (min-width: ${Breakpoints.TWO_K_MONITOR}) {
		display: block;
		grid-column: ${({ sizes }) => `span ${sizes?.l ?? 1}`};
		${({ offsets, sizes }) =>
			offsets?.l !== undefined &&
			css`
				grid-column: ${`${offsets.l + 1} / span ${sizes?.l ?? 1}`};
			`};

		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.l === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`}
	}

	@media (min-width: ${Breakpoints.FOUR_K_MONITOR}) {
		display: block;
		grid-column: ${({ sizes }) => `span ${sizes?.xl ?? 1}`};
		${({ offsets, sizes }) =>
			offsets?.xl !== undefined &&
			css`
				grid-column: ${`${offsets.xl + 1} / span ${sizes?.xl ?? 1}`};
			`};

		${({ sizes, displayGridColumn0 }) =>
			sizes &&
			sizes.xl === 0 &&
			!displayGridColumn0 &&
			css`
				display: none;
			`}
	}
`;

CSSGrid.Col = Col;
CSSGrid.Container = Container;

import { Icon } from 'components/UI/Icons';
import { Input } from 'components/UI/Inputs/Input';
import { Colors, Svgs } from 'environment';
import { InputType } from 'types/index';
import { Variable, VariableAliasesMap } from 'api/data/variables';

import {
	AliasTableContainer,
	AliasTableHeader,
	AliasTableFooter,
	AliasTableRow,
	AliasTableContent,
	AliasTableHeaderColumn,
	AliasTableFooterButton,
	DeleteAliasIcon
} from './VariableAliases.style';
import { AliasTableDropdown } from './AliasTableDropdown/AliasTableDropdown';
import { useTranslation } from 'hooks/store';
import { useState } from 'react';
import { VariableType } from 'types/data/variables/constants';

const ILLEGAL_ALIASES = ['ln', 'log', 'sqrt', 'pi', 'e'];

type Props = {
	variableAliases: VariableAliasesMap;
	variables: Variable[];
	setVariableAlias: (id: string, keyValue: string) => void;
	setVariableId: (id: string, varName: string) => void;
	removeEntry: (id: string) => void;
	handleEntryToMap: () => void;
};

export function VariableAliases({
	variableAliases,
	variables,
	setVariableAlias,
	setVariableId,
	removeEntry,
	handleEntryToMap
}: Props) {
	const { translate } = useTranslation();

	// State to track the last alias input that was changed
	const [lastChangedInputId, setLastChangedInputId] = useState<string | null>(null);

	// State to track the last variable dropdown that was changed
	const [lastChangedDropdownId, setLastChangedDropdownId] = useState<string | null>(null);

	return (
		<AliasTableContainer>
			<AliasTableHeader>
				<AliasTableHeaderColumn area="variable">
					{translate(dict => dict.addVariable.compositeVariable.variables)}
				</AliasTableHeaderColumn>
				<AliasTableHeaderColumn area="alias">
					{translate(dict => dict.addVariable.compositeVariable.alias)}
				</AliasTableHeaderColumn>
			</AliasTableHeader>

			{variableAliases.order.length > 0 ? (
				<AliasTableContent>
					{variableAliases.order.map(id => {
						const existingVariableMapping = Object.entries(variableAliases.map).some(
							([aId, aliasEntry]) => {
								return (
									aliasEntry.variableId === variableAliases.map[id].variableId &&
									aId !== id &&
									aliasEntry.type !== VariableType.String &&
									id === lastChangedDropdownId
								);
							}
						);

						return (
							<AliasTableRow key={id}>
								<Input
									type={InputType.Text}
									title={variableAliases.map[id].key}
									value={variableAliases.map[id].key}
									onChange={e => {
										setVariableAlias(id, e.target.value.trim());
										setLastChangedInputId(id);
									}}
									placeholder={translate(
										dict => dict.addVariable.compositeVariable.aliasPlaceholder
									)}
									error={
										ILLEGAL_ALIASES.includes(
											variableAliases.map[id].key.toLowerCase()
										)
											? translate(
													dict =>
														dict.addVariable.compositeVariable
															.aliasNotAllowed
											  )
											: Object.entries(variableAliases.map).some(
													([k, v]) =>
														v.key === variableAliases.map[id].key &&
														k !== id &&
														v.key !== '' &&
														id === lastChangedInputId
											  )
											? translate(
													dict =>
														dict.addVariable.compositeVariable
															.aliasDuplicate
											  )
											: ''
									}
									className="alias_input"
								/>
								<Icon
									svg={Svgs.ArrowLongRight}
									className="icon_arrow"
									style={{
										gridArea: 'arrow-icon',
										width: '100%',
										height: '3.2rem'
									}}
								/>

								<AliasTableDropdown
									variableAliases={variableAliases}
									id={id}
									value={variables.find(
										v => v.name === variableAliases.map[id].variableId
									)}
									variables={variables}
									onSelect={v => {
										setVariableId(id, v.name);
										setLastChangedDropdownId(id);
									}}
									error={
										existingVariableMapping
											? translate(
													dict =>
														dict.addVariable.compositeVariable
															.variableAllreadyMapped
											  )
											: ''
									}
								/>

								{variableAliases.order.length > 1 ? (
									<DeleteAliasIcon
										svg={Svgs.Delete}
										onClick={() => removeEntry(id)}
										variant={v => v.simple}
										colors={{
											color: Colors.text.disabled,
											hover: Colors.primary.normal
										}}
										propagate
									/>
								) : null}
							</AliasTableRow>
						);
					})}
				</AliasTableContent>
			) : null}
			<AliasTableFooter>
				<AliasTableFooterButton
					paddingTop={variableAliases.order.length > 0}
					onClick={handleEntryToMap}
				>
					{translate(dict => dict.addVariable.compositeVariable.addMapping)}
				</AliasTableFooterButton>
			</AliasTableFooter>
		</AliasTableContainer>
	);
}

import styled from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Title = styled(Typography.H4)`
	padding: 4rem 0;
`;

export const WithStripes = styled.div`
	> :nth-child(odd) {
		background-color: ${Colors.background.disabled};
	}
`;

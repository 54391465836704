import { Variable, Group, VariableSet } from 'api/data/variables';
import { StoreVariablesData, VariablesOrder, GroupsMap } from 'store/data/variables';

import {
	isVariableOrderItem,
	isGroupOrderItem,
	isVariableSetOrderItem,
	getVariableSetVariableNames,
	buildVariablesDataLocation,
	buildVariablesDataFromStoreData
} from './variableHelpers';
import { cloneDeep } from 'lodash';

export function variablesDataContainer(storeVariablesData: StoreVariablesData) {
	const { variables, groups, variableSets, order } = storeVariablesData;

	const variablesDataLocation = buildVariablesDataLocation(
		buildVariablesDataFromStoreData(storeVariablesData)
	);

	/*
	 * VARIABLES
	 */

	function createVariable(
		variable: Variable,
		options?: {
			destination?: {
				index?: number;
				setName?: string;
				groupName?: string;
			};
		}
	) {
		const { destination } = options ?? {};

		variables.byName[variable.name] = variable;

		if (destination) {
			const { index, setName, groupName } = destination;

			if (groupName !== undefined) {
				const group = groups.byName[groupName];

				return addVariableToGroup(variable.name, group, index);
			}

			if (setName !== undefined) {
				const variableSet = variableSets.byName[setName];

				return addOrderItem(variable.name, variableSet.setOrder, 'variable', index);
			}

			if (index !== undefined) {
				return addOrderItem(variable.name, order, 'variable', index);
			}
		}

		addOrderItem(variable.name, order, 'variable');
	}

	function deleteVariable(
		variableName: string,
		options?: {
			source?: {
				setName?: string;
				groupName?: string;
			};
		}
	) {
		if (!(variableName in variables.byName)) return;

		const { source } = options ?? {};

		delete variables.byName[variableName];

		if (source) {
			const { setName, groupName } = source;

			if (groupName !== undefined) {
				const group = groups.byName[groupName];

				removeVariableFromGroup(variableName, group);

				if (setName !== undefined) {
					const variableSet = variableSets.byName[setName];

					repairVariableSetSettings(variableSet, groups.byName);
				}

				return;
			}

			if (setName !== undefined) {
				const variableSet = variableSets.byName[setName];

				removeOrderItem(variableName, variableSet.setOrder, 'variable');
				repairVariableSetSettings(variableSet, groups.byName);

				return;
			}
		}

		removeOrderItem(variableName, order, 'variable');
	}

	/*
	 * GROUPS
	 */

	function createGroup(
		group: Group,
		options?: {
			destination?: {
				index?: number;
				setName?: string;
			};
		}
	) {
		const { destination } = options ?? {};

		groups.byName[group.groupName] = group;

		if (destination) {
			const { index, setName } = destination;

			if (setName !== undefined) {
				const variableSet = variableSets.byName[setName];

				return addOrderItem(group.groupName, variableSet.setOrder, 'group', index);
			}

			if (index !== undefined) {
				return addOrderItem(group.groupName, order, 'group', index);
			}
		}

		addOrderItem(group.groupName, order, 'group');
	}

	function deleteGroup(
		groupName: string,
		options?: {
			source?: {
				setName?: string;
			};
			removeContent?: boolean;
		}
	) {
		if (!(groupName in groups.byName)) return;

		const { source, removeContent } = options ?? {};

		const group = groups.byName[groupName];

		delete groups.byName[groupName];

		const groupIndex = getOrderItemIndex(groupName, order, 'group');

		const variableOrderItems = group.variablesBelongingToGroup.map(variableName =>
			createOrderItem(variableName).variable()
		);

		if (source) {
			const { setName } = source;

			if (setName !== undefined) {
				const variableSet = variableSets.byName[setName];

				removeOrderItem(groupName, variableSet.setOrder, 'group');

				if (removeContent) return;

				const setGroupIndex = getOrderItemIndex(groupName, variableSet.setOrder, 'group');

				variableSet.setOrder.splice(setGroupIndex, 0, ...variableOrderItems);

				return;
			}
		}

		removeOrderItem(groupName, order, 'group');

		if (removeContent) return;

		order.splice(groupIndex, 0, ...variableOrderItems);
	}

	/*
	 * VARIABLE SETS
	 */

	function createVariableSet(
		variableSet: VariableSet,
		options?: {
			variableNames?: string[];
			groupNames?: string[];
			destination?: {
				index?: number;
			};
		}
	) {
		const { variableNames, destination, groupNames } = options ?? {};

		const _variableSet = cloneDeep(variableSet);

		if (variableNames) {
			variableNames.forEach(variableName => {
				unlinkVariable(variableName);
				addOrderItem(variableName, _variableSet.setOrder, 'variable');
			});
		}

		if (groupNames) {
			groupNames.forEach(groupName => {
				unlinkGroup(groupName);
				addOrderItem(groupName, _variableSet.setOrder, 'group');
			});
		}

		variableSets.byName[_variableSet.setName] = _variableSet;

		if (destination) {
			const { index } = destination;

			if (index !== undefined) {
				return addOrderItem(_variableSet.setName, order, 'variableSet', index);
			}
		}

		addOrderItem(_variableSet.setName, order, 'variableSet');
	}

	function deleteVariableSet(
		setName: string,
		options?: {
			removeContent?: boolean;
		}
	) {
		if (!(setName in variableSets.byName)) return;

		const { removeContent } = options ?? {};

		const variableSet = variableSets.byName[setName];

		delete variableSets.byName[setName];

		removeOrderItem(setName, order, 'variableSet');

		if (removeContent) {
			const variableSetVariableNames = getVariableSetVariableNames(variableSet, {
				groupsMap: groups.byName
			});

			variableSetVariableNames.forEach(variableName => delete variables.byName[variableName]);
		} else {
			// const variableSetIndex = getOrderItemIndex(setName, order, 'variableSet');
			// TODO: LOGIC HERE - LEAVE CONTENT IN THE SAME INDEX
		}
	}

	/*
	 * HELPERS
	 */

	function unlinkVariable(variableName: string) {
		const { variablesLocation } = variablesDataLocation;

		if (variableName in variablesLocation) {
			const { setName, groupName } = variablesLocation[variableName];

			if (groupName !== undefined) {
				if (!(groupName in groups.byName)) return;

				const group = groups.byName[groupName];

				group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
					groupVariableName => groupVariableName !== variableName
				);

				return;
			}

			if (setName !== undefined) {
				if (!(setName in variableSets.byName)) return;

				const variableSet = variableSets.byName[setName];

				variableSet.setOrder = variableSet.setOrder.filter(item => {
					if (isVariableOrderItem(item)) {
						return item.variable !== variableName;
					}

					return true;
				});

				return;
			}

			return;
		}

		removeOrderItem(variableName, order, 'variable');
	}

	function unlinkGroup(groupName: string) {
		const { groupsLocation } = variablesDataLocation;

		if (groupName in groupsLocation) {
			const { setName } = groupsLocation[groupName];

			if (setName !== undefined) {
				if (!(setName in variableSets.byName)) return;

				const variableSet = variableSets.byName[setName];

				variableSet.setOrder = variableSet.setOrder.filter(item => {
					if (isGroupOrderItem(item)) {
						return item.group !== groupName;
					}

					return true;
				});

				return;
			}

			return;
		}

		removeOrderItem(groupName, order, 'group');
	}

	return {
		/*
		 * VARIABLES
		 */
		createVariable,
		deleteVariable,
		/*
		 * GROUPS
		 */
		createGroup,
		deleteGroup,
		/*
		 * VARIABLE SETS
		 */
		createVariableSet,
		deleteVariableSet
	};
}

function addVariableToGroup(variableName: string, group: Group, destinationIndex?: number) {
	if (destinationIndex !== undefined) {
		group.variablesBelongingToGroup.splice(destinationIndex, 0, variableName);

		return;
	}

	group.variablesBelongingToGroup.push(variableName);
}

function removeVariableFromGroup(variableName: string, group: Group) {
	group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
		groupVariable => groupVariable !== variableName
	);
}

export function repairVariableSetSettings(variableSet: VariableSet, groupsMap: GroupsMap) {
	const setVariableNames = getVariableSetVariableNames(variableSet, {
		groupsMap
	});

	variableSet.aggregationRules = variableSet.aggregationRules.filter(aggregationRule =>
		setVariableNames.includes(aggregationRule.aggregator.variableName)
	);

	if (variableSet.identifier.variableName !== null) {
		if (!setVariableNames.includes(variableSet.identifier.variableName)) {
			variableSet.identifier.variableName = null;
		}
	}
}

function addOrderItem(
	name: string,
	order: VariablesOrder,
	type: 'variable' | 'group' | 'variableSet',
	destinationIndex?: number
) {
	const orderItem = createOrderItem(name)[type]();

	if (destinationIndex !== undefined) {
		return order.splice(destinationIndex, 0, orderItem);
	}

	order.push(orderItem);
}

function createOrderItem(name: string) {
	return {
		variable: () => ({ variable: name }),
		group: () => ({ group: name }),
		variableSet: () => ({ set: name })
	};
}

function removeOrderItem(
	name: string,
	order: VariablesOrder,
	type: 'variable' | 'group' | 'variableSet'
) {
	const orderItemIndex = getOrderItemIndex(name, order, type);

	if (orderItemIndex !== -1) order.splice(orderItemIndex, 1);
}

function getOrderItemIndex(
	name: string,
	order: VariablesOrder,
	type: 'variable' | 'group' | 'variableSet'
) {
	const isVariable = type === 'variable';
	const isGroup = type === 'group';
	const isVariableSet = type === 'variableSet';

	return order.findIndex(item => {
		if (isVariable && isVariableOrderItem(item)) return item.variable === name;
		if (isGroup && isGroupOrderItem(item)) return item.group === name;
		if (isVariableSet && isVariableSetOrderItem(item)) return item.set === name;

		return false;
	});
}

import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const Wrapper = styled.div`
	background-color: ${Colors.pale};
`;

export const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 4.8rem;
	padding: 0rem 20rem;

	@media ${MediaQueries.maxWidth.lg} {
		padding: 0 20rem 0 2rem;
	}

	@media ${MediaQueries.maxWidth.sm} {
		position: staatic;
		flex-direction: column;
		padding: 0 2rem 0 2rem;
	}
`;

export const UpdatePaymentLink = styled.span`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
	width: 20rem;
	cursor: pointer;

	@media ${MediaQueries.maxWidth.sm} {
		position: static;
		padding: 0rem 2rem 0rem 4rem;
		top: 0;
		width: 100%;
		transform: translate(0, 0);
	}
`;

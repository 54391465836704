import { useMemo } from 'react';

import { useModalState } from 'hooks/ui';
import { useAdminRolesSearchTerm } from 'hooks/store';
import { useEnterpriseRoles } from 'hooks/store/account/enterprise/useEnterpriseRoles';

import { EnterpriseRoleCard, EnterpriseRoleModal } from 'components/Account/EnterpriseRoles';
import { DeleteRoleModal } from 'components/Roles/DeleteRoleModal';
import { NoRoles } from 'components/Roles/NoRoles';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';

import { RolesPageHeader } from 'pages/Roles/RolesPageHeader';
import { EnterpriseRole } from 'store/account/enterprise';
import { filterAdminRolesBySearchTerm } from 'pages/Admin/helpers';

import { CardContainer } from 'pages/Roles/RolesPage.style';

export function EnterpriseAdminRolesPage() {
	const [searchTerm, setSearchTerm] = useAdminRolesSearchTerm();
	const [{ data: roles, fetched: areRolesFetched, loading: loadingEnterpriseRoles }] =
		useEnterpriseRoles();

	const addEditRoleModal = useModalState<EnterpriseRole>();
	const deleteRoleModal = useModalState<EnterpriseRole>();

	const filteredRoles = useMemo(
		() => filterAdminRolesBySearchTerm(Object.values(roles), searchTerm),
		[roles, searchTerm]
	);
	const noRoles = Object.values(roles).length === 0;

	return (
		<>
			<RolesPageHeader
				hasData={!noRoles}
				onCreate={addEditRoleModal.open}
				searchTermState={{ searchTerm, setSearchTerm }}
			/>
			<Suspend loading={loadingEnterpriseRoles} immediate={!areRolesFetched}>
				<Grid.Container>
					<CardContainer>
						{filteredRoles.map(role => (
							<EnterpriseRoleCard
								key={role.roleId}
								role={role}
								actions={{
									onEdit: addEditRoleModal.open,
									onDelete: deleteRoleModal.open
								}}
							/>
						))}
					</CardContainer>
					{noRoles && <NoRoles enterpriseRole onCreateRole={addEditRoleModal.open} />}
				</Grid.Container>
			</Suspend>
			{addEditRoleModal.visible && (
				<EnterpriseRoleModal
					role={addEditRoleModal.payload}
					predefinedRoles={roles}
					onClose={addEditRoleModal.close}
				/>
			)}
			{deleteRoleModal.visible && deleteRoleModal.payload && (
				<DeleteRoleModal role={deleteRoleModal.payload} onClose={deleteRoleModal.close} />
			)}
		</>
	);
}

import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	z-index: 9;
	margin-left: 1rem;
`;

export const Floating = styled.div`
	position: absolute;
	min-width: 30rem;
	z-index: 99;
`;

import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const ColorPickerContainer = styled.div`
	position: relative;
`;

export const ColorPickerToggle = styled.div`
	display: flex;
	cursor: pointer;
	padding: 0.4rem;
	border-radius: 0.4rem;
	/* TODO: replace with colors from styleguide */
	border: solid 0.1rem #dae0e6;
`;

interface ColorChipProps {
	color: string;
	isActive?: boolean;
	inSelector?: boolean;
}
export const ColorChip = styled.div<ColorChipProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 2.4rem;
	height: 2.4rem;
	min-width: 2.4rem;
	min-height: 2.4rem;
	background-color: ${({ color }) => color};
	border-radius: 0.4rem;

	:hover {
		cursor: pointer;

		${({ inSelector }) =>
			inSelector &&
			css`
				border: solid 0.1rem ${Colors.text.main};
			`}
	}

	${({ isActive }) =>
		isActive &&
		css`
			border: solid 0.1rem ${Colors.text.main};
		`}
`;

export const ColorPickerModal = styled.div`
	display: flex;
	position: absolute;
	top: 3.8rem;
	left: 0rem;
	min-width: 12.4rem;
	max-width: 12.4rem;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: flex-start;
	z-index: 1000;
	gap: 0.4rem;
	padding: 0.8rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.hover};
`;

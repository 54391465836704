import { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { withMemo } from 'helpers/HOCs';
import { useTranslation } from 'hooks/store';

import { ResizableComponentDynamic } from './ResizableComponentDynamic';
import { Typography } from 'components/UI/Typography';
import { AdvancedMenu } from 'components/UI/Interactables/AdvancedMenu';
import { convertAPIWidthToPX } from 'helpers/adminDashboard';
import { CardPositionManual } from 'components/Account';
import { WidthSizes, HeightSizes, SetSizePayloadDynamic } from 'store/data/projectDashboard';

import {
	CardBody,
	CardHeader,
	CardReplacement,
	ChildrensContainer,
	ComponentWrapper,
	DropToLeft
} from '../style';
import { CardDraggableResizablePropsDynamic } from '../../AdminDashboardAutomatic';

interface Props {
	card: CardDraggableResizablePropsDynamic;
	index: number;
	position: CardPositionManual;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	isDragging?: boolean;
	lockExpandUnder?: boolean;
	placeholdersCanOccupy?: number;
	setDimensions: (payload: SetSizePayloadDynamic) => void;
	removeCard: (position: CardPositionManual) => void;
}

function Component({
	index,
	card,
	position,
	widthSizes,
	heightSizes,
	lockExpandUnder,
	placeholdersCanOccupy,
	setDimensions,
	removeCard
}: Props) {
	const { translate } = useTranslation();
	const [menuVisible, setMenuVisible] = useState(false);
	const [hoveringAnother, setHoveringAnother] = useState(true);
	const [resizingState, setResizingState] = useState(false);

	const elementValueRef = useRef<HTMLDivElement>(null);

	const [{ isDragging }, drag] = useDrag(
		{
			type: 'card',
			canDrag: !!card,
			item: (): CardDraggableResizablePropsDynamic => ({
				index,
				cardId: card.cardId,
				heightSize: card.heightSize,
				cardName: card.cardName,
				widthSize: card.widthSize,
				...(position && { position })
			}),
			collect: monitor => {
				return {
					isDragging: monitor.isDragging()
				};
			}
		},
		[card.cardId]
	);

	const [{ draggedDisabled }, drop] = useDrop<any, any, any>(
		{
			accept: 'card',
			hover(item: CardDraggableResizablePropsDynamic, monitor) {
				if (!monitor.isOver()) return;
				const isOver = monitor.isOver({ shallow: true });

				setHoveringAnother(isOver);
			},
			drop(item: CardDraggableResizablePropsDynamic, monitor) {
				if (!monitor.isOver({ shallow: true })) return;
			},
			collect: monitor => ({
				draggedDisabled: !monitor.getItem() && hoveringAnother
			})
		},
		[card.cardId]
	);

	useEffect(() => {
		if (draggedDisabled) setHoveringAnother(false);
		else setHoveringAnother(isDragging);
	}, [isDragging, draggedDisabled]);

	useLayoutEffect(() => {
		drag(elementValueRef);
		drop(elementValueRef);
		return () => {
			drag(null);
			drop(null);
		};
	}, [drag, drop, elementValueRef]);

	const children = useMemo(
		() => (
			<ChildrensContainer>
				<Typography.H1>
					This is some card content 😎 🤙&nbsp;And some more card content 🤩 👏👏👏
				</Typography.H1>
			</ChildrensContainer>
		),
		[]
	);

	//Calculate header's width to match card content size
	const snappedWidth = convertAPIWidthToPX(card.widthSize, widthSizes);
	const headerMaxWidth = snappedWidth + 32;

	return (
		<>
			<ComponentWrapper
				ref={elementValueRef}
				isDragging={isDragging}
				hoveringAnother={hoveringAnother}
			>
				<CardHeader menuVisible={menuVisible} width={headerMaxWidth}>
					<Typography.H6 ellipsis>
						{!(isDragging || resizingState) && <>{card.cardName}</>}
					</Typography.H6>
					<div style={{ opacity: !resizingState ? '1' : '0' }}>
						<AdvancedMenu
							className={`card-more-icon`}
							items={[
								{ label: translate(({ buttons }) => buttons.edit) },
								{ label: translate(({ buttons }) => buttons.layout) },
								{ label: translate(({ buttons }) => buttons.fullScreen) },
								{
									label: translate(({ buttons }) => buttons.remove),
									handler: () => removeCard(position)
								}
							]}
							active={menuVisible}
							onOpen={() => setMenuVisible(true)}
							onClose={() => setMenuVisible(false)}
						/>
					</div>
				</CardHeader>
				<CardBody>
					<ResizableComponentDynamic
						resizeVertically
						id={card.cardId}
						widthSize={card.widthSize}
						heightSize={card.heightSize}
						widthSizes={widthSizes}
						heightSizes={heightSizes}
						position={position}
						lockExpandUnder={lockExpandUnder}
						placeholdersCanOccupy={placeholdersCanOccupy}
						setResizingState={() => setResizingState(!resizingState)}
						setDimensions={setDimensions}
					>
						{isDragging ? (
							<CardReplacement>
								<DropToLeft />
							</CardReplacement>
						) : (
							<>{children}</>
						)}
					</ResizableComponentDynamic>
				</CardBody>
			</ComponentWrapper>
		</>
	);
}

export const DraggableCardDynamic = withMemo(Component);

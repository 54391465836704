import styled, { css } from 'styled-components';

import { Colors, Shadows } from 'environment';

import { AdvancedMenu as DefaultAdvancedMenu } from 'components/UI/Interactables/AdvancedMenu/AdvancedMenu';

export const CreditCardWrapper = styled.div<{ disableActions: boolean }>`
	box-shadow: ${Shadows.normal};
	border-radius: 0.4rem;

	:hover {
		box-shadow: ${Shadows.hover};

		.card-more-icon {
			visibility: visible;
		}
	}

	${({ disableActions }) =>
		disableActions &&
		css`
			pointer-events: none;
			opacity: 0.7;
		`}
`;

interface AdvancedMenuInterface {
	visible: boolean;
}

export const AdvancedMenu = styled(DefaultAdvancedMenu)<AdvancedMenuInterface>`
	padding-left: 1.6rem;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const CardField = styled.div`
	border: 0.12rem solid ${Colors.text.disabled};
	border-radius: 0.4rem;
	padding: 1.1rem;
`;

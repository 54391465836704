import { State } from './types';

const initialState: State = {
	projectId: null,
	byId: {},
	byProjectId: {},
	bySnapshotId: {},
	refetch: false,
	activeColumn: 1,
	fullscreen: '',
	plots: {}
};

export default initialState;

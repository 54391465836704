import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';

import { Svgs } from 'environment';
import { initButtonProps } from 'helpers/buttons';
import { initOrganization } from 'helpers/collaborators';
import { Organization } from 'store/data/collaborators';
import { InputType, SelectItem } from 'types/index';

import { UserCard } from 'components/UI/UserCard';

import { WithStripes, CollaboratorContainer, Title } from './OrganizationModal.style';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useCollaborators,
	useCreateOrganization,
	useUpdateOrganization,
	useAddCollaboratorsToOrganization,
	useDefaultOrganizationId
} from 'hooks/store';
import { useMutableState, usePrevious, useCompletedAction, useKeyPress } from 'hooks/utils';

const defaultOrganization = initOrganization();

interface Props {
	writeAccess: boolean;
	organization?: Organization;
	removeModalVisible: boolean;
	onDelete: (organizationId: string) => void;
	onRemoveCollaborator: (input: { organizationId: string; collaboratorId: string }) => void;
	onClose: () => void;
}

export function OrganizationModal({
	writeAccess,
	organization,
	removeModalVisible,
	onDelete,
	onRemoveCollaborator,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { collaborators, collaboratorsMap, organizationNames }
		}
	] = useCollaborators({ lazy: true });

	const [
		{ loading: creatingOrganization, error: errorCreatingOrganization },
		createOrganization
	] = useCreateOrganization();
	const [
		{ loading: updatingOrganization, error: errorUpdatingOrganization },
		updateOrganization
	] = useUpdateOrganization();
	const [
		{
			loading: addingCollaboratorsToOrganization,
			error: errorAddingCollaboratorsToOrganization
		},
		addCollaboratorsToOrganization
	] = useAddCollaboratorsToOrganization();

	const defaultOrganizationId = useDefaultOrganizationId();

	const isDefaultOrganization = organization?.id === defaultOrganizationId;

	const initialDraftOrganization = organization ?? defaultOrganization;
	const [draftOrganization, setDraftOrganization] = useMutableState(initialDraftOrganization);
	const [draftCollaborators, setDraftCollaborators] = useMutableState<string[]>([]);

	const { hasChanges, organizationHasChanges, collaboratorsHaveChanges } = useMemo(() => {
		const organizationHasChanges = !isEqual(initialDraftOrganization, draftOrganization);
		const collaboratorsHaveChanges = draftCollaborators.length > 0;
		const hasChanges = organizationHasChanges || collaboratorsHaveChanges;

		return {
			hasChanges,
			organizationHasChanges,
			collaboratorsHaveChanges
		};
	}, [initialDraftOrganization, draftOrganization, draftCollaborators]);

	// SYNC `draftOrganization` STATE
	const prevOrganization = usePrevious(organization);
	useEffect(() => {
		if (prevOrganization === undefined || organization === undefined) return;

		if (!isEqual(prevOrganization, organization)) setDraftOrganization(organization);
	}, [organization]);

	useCompletedAction(creatingOrganization, errorCreatingOrganization, onClose);
	useCompletedAction(updatingOrganization, errorUpdatingOrganization, onClose);
	useCompletedAction(
		addingCollaboratorsToOrganization,
		errorAddingCollaboratorsToOrganization,
		onClose
	);

	useKeyPress({ onDeleteKeyPress: handleDelete }, { listen: canDelete() && !removeModalVisible });
	useKeyPress(
		{ onEnterKeyPress: hasChanges ? handleSubmit : onClose },
		{ listen: !isLoadingAction() && !removeModalVisible }
	);

	async function handleSubmit() {
		if (!(isValid() && !isLoadingAction())) return;

		if (organization) {
			if (organizationHasChanges && collaboratorsHaveChanges) {
				await updateOrganization({ organization: draftOrganization });

				addCollaboratorsToOrganization({
					organizationIds: [organization.id],
					collaborators: draftCollaborators
				});
				return;
			}

			if (organizationHasChanges) {
				updateOrganization({ organization: draftOrganization });
				return;
			}

			if (collaboratorsHaveChanges) {
				addCollaboratorsToOrganization({
					organizationIds: [organization.id],
					collaborators: draftCollaborators
				});
				return;
			}

			return;
		}

		createOrganization({
			organization: {
				...draftOrganization,
				...(draftCollaborators.length && {
					collaborators: draftCollaborators
				})
			}
		});
	}

	function isValid(): boolean {
		return hasChanges && isNameValid() && isNameUnique();
	}

	function isNameValid(): boolean {
		return draftOrganization.name.trim().length > 0;
	}

	function isNameUnique(): boolean {
		let names = [...organizationNames];

		if (organization) names = names.filter(name => name !== organization.name);

		return !names.includes(draftOrganization.name.trim());
	}

	function isLoadingAction(): boolean {
		return creatingOrganization || updatingOrganization || addingCollaboratorsToOrganization;
	}

	function getTitle() {
		if (organization) return translate(dict => dict.collaborators.organizationModal.editGroup);

		return translate(dict => dict.collaborators.organizationModal.createNewGroup);
	}

	function handleDelete() {
		if (canDelete() && organization) onDelete(organization.id);
	}

	function canDelete() {
		return writeAccess && !!organization && !isDefaultOrganization;
	}

	function getCollaboratorsSelectItems(): SelectItem[] {
		const availableCollaborators = organization
			? collaborators.filter(
					collaborator => !organization.collaborators.includes(collaborator.userId)
			  )
			: collaborators;

		return availableCollaborators.map(collaborator => {
			const fullName = `${collaborator.userFirstName} ${collaborator.userSirName}`.trim();
			const label =
				collaborator.pending || collaborator.emailAddress === collaborator.userId
					? collaborator.emailAddress
					: fullName + ` (${collaborator.emailAddress})`;
			return {
				label: label,
				value: collaborator.userId
			};
		});
	}

	function getCollaboratorsSelectedItems(): SelectItem[] {
		return draftCollaborators.map(collaboratorId => {
			const collaborator = collaboratorsMap[collaboratorId];

			const fullName = `${collaborator.userFirstName} ${collaborator.userSirName}`.trim();
			const label =
				collaborator.pending || collaborator.emailAddress === collaborator.userId
					? collaborator.emailAddress
					: fullName;

			return {
				label: label,
				value: collaborator.userId
			};
		});
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(({ buttons }) =>
				organization
					? organizationHasChanges || collaboratorsHaveChanges
						? buttons.update
						: buttons.done
					: buttons.create
			),
			loading: isLoadingAction(),
			disabled: !isValid(),
			onClick: handleSubmit
		};

		buttons.neutral = {
			label: translate(({ buttons }) => buttons.cancel),
			onClick: onClose
		};

		if (!writeAccess || (organization && !hasChanges)) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	return (
		<Modal
			title={getTitle()}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			{...(writeAccess && {
				secondary: {
					label: translate(dict => dict.collaborators.organizationModal.deleteGroup),
					onClick: () => (organization ? onDelete(organization?.id) : undefined)
				}
			})}
			onClose={onClose}
			visible
			close
		>
			<>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.collaborators.organizationModal.groupName)}
					value={draftOrganization.name}
					placeholder={translate(
						dict => dict.collaborators.organizationModal.enterNameOfGroup
					)}
					error={
						!isNameUnique()
							? translate(
									dict => dict.collaborators.organizationModal.nameAlreadyExists
							  )
							: undefined
					}
					readOnly={!writeAccess}
					onChange={e =>
						setDraftOrganization(state => {
							state.name = e.target.value;
						})
					}
					onBlur={() =>
						setDraftOrganization(state => {
							state.name = state.name.trim();
						})
					}
					autoFocus={!organization}
					onSubmit={handleSubmit}
				/>

				<Spacer size={s => s.s} />

				<CreatableSelect
					label={translate(dict => dict.collaborators.organizationModal.addCollaborators)}
					placeholder={translate(
						dict => dict.collaborators.organizationModal.enterNameOrEmail
					)}
					items={getCollaboratorsSelectItems()}
					values={getCollaboratorsSelectedItems()}
					readOnly={!writeAccess}
					onValuesSelected={values => setDraftCollaborators(values)}
					hasMultipleValues
				/>
			</>

			<Title marginOffset={{ top: 4.8, bottom: 0.8 }}>
				{translate(dict => dict.collaborators.organizationModal.collaboratorList)}
			</Title>
			<>
				<WithStripes>
					{draftOrganization.collaborators.map(collaboratorId => {
						const collaborator = collaboratorsMap[collaboratorId];

						if (!collaborator) return null;

						return (
							<CollaboratorContainer key={`collaborator_${collaborator.userId}`}>
								<Flex flex={1}>
									<UserCard.Basic
										userId={collaborator.pending ? null : collaborator.userId}
										userData={{
											userFirstName:
												collaborator.emailAddress === collaborator.userId
													? collaborator.emailAddress
													: collaborator.userFirstName,
											userSirName:
												collaborator.emailAddress === collaborator.userId
													? ''
													: collaborator.userSirName,
											emailAddress: collaborator.emailAddress
										}}
									/>
								</Flex>

								{writeAccess && (
									<Button
										className="remove-button"
										title={translate(dict => dict.buttons.remove)}
										variant={v => v.link}
										paddingOffset={{ all: 0 }}
										onClick={() =>
											onRemoveCollaborator({
												organizationId: draftOrganization.id,
												collaboratorId: collaborator.userId
											})
										}
									/>
								)}
							</CollaboratorContainer>
						);
					})}
				</WithStripes>

				{/* NO COLLABORATORS STATE */}
				{draftOrganization.collaborators.length === 0 && (
					<Flex align={a => a.center} column>
						<Svgs.EmptyStatesOrganization style={{ minHeight: 240 }} />
						<Spacer size={s => s.m} />
						<Typography.H3>
							{translate(
								dict => dict.collaborators.organizationModal.noCollaborators
							)}
						</Typography.H3>
					</Flex>
				)}
			</>
		</Modal>
	);
}

import { Auth } from 'aws-amplify';
import { StorageKeys } from 'types/index';
import { debuggerLog } from 'helpers/generic';
import { useEffectOnce } from 'hooks/utils';
import { logoutURLLedidiSSOParams, ssoClientsConfigs } from 'ssoConfig/consts';

const isFederatedUser = localStorage.getItem(StorageKeys.FederatedUILogin) === 'true';

export async function isAuthSessionExpired(): Promise<boolean> {
	try {
		await Auth.currentSession();
		return false;
	} catch (e) {
		return true;
	}
}

export async function authLogoutOrManualCleanup(federatedUser?: boolean) {
	if (isFederatedUser) {
		const localStorageProviderName = localStorage.getItem('SSOProvider');
		const clientIdSSO = localStorage.getItem(StorageKeys.FederatedLoginClientID);
		const ssoInactivityAutoLogout = localStorageProviderName
			? ssoClientsConfigs.forcedLogoutURL[
					localStorageProviderName.toLocaleLowerCase() as keyof typeof ssoClientsConfigs.forcedLogoutURL
			  ]
			: false;

		const {
			REACT_APP_SSO_SHORT_URL_BASE,
			REACT_APP_SSO_URL_BASE,
			REACT_APP_FEDERATED_LOGOUT_URLS
		} = process.env;

		let redirectWithSignoutURL;

		if (ssoInactivityAutoLogout) {
			if (localStorageProviderName?.toLocaleLowerCase() === 'ledidissotest') {
				redirectWithSignoutURL = `https://${REACT_APP_SSO_URL_BASE}/logout?client_id=${clientIdSSO}${logoutURLLedidiSSOParams}${REACT_APP_SSO_SHORT_URL_BASE}%252Flogin%253Fsso%253D${localStorageProviderName}`;
			} else {
				const ssoClientsLogoutURLs: { [key: string]: string } =
					REACT_APP_FEDERATED_LOGOUT_URLS
						? JSON.parse(REACT_APP_FEDERATED_LOGOUT_URLS)
						: {};
				const ssoClientLogoutURLDecoded = localStorageProviderName
					? ssoClientsLogoutURLs[localStorageProviderName.toLowerCase()]
					: null;
				const ssoClientLogoutURLEncoded = encodeURIComponent(
					ssoClientLogoutURLDecoded ?? ''
				);
				const logoutURLScopes =
					'&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&logout_uri=';

				redirectWithSignoutURL = `https://${REACT_APP_SSO_URL_BASE}/logout?client_id=${clientIdSSO}${logoutURLScopes}${ssoClientLogoutURLEncoded}`;
			}
		} else {
			redirectWithSignoutURL = `https://${REACT_APP_SSO_SHORT_URL_BASE}/login?sso=${localStorageProviderName}`;
		}
		localStorageProviderName && window.location.replace(redirectWithSignoutURL);
		localStorage.clear();
		localStorageProviderName && localStorage.setItem('SSOProvider', localStorageProviderName);
	} else {
		localStorage.removeItem('LDContext');
	}
	try {
		await Auth.signOut(federatedUser ? { global: true } : undefined);
	} catch (err: any) {
		// the very edge case here where signOut fails!
		alert('SignOut failed with error: ' + err.message + '. Please login again.');
		if (!isFederatedUser) {
			localStorage.clear();
			window.location.replace('/login');
		}
	}
}

/**
 * LOG OUT ALL INSTANCES AFTER TAB CLOSE AND INACTIVITY ON OTHER TABS
 */
export function useAutoLogoutOnTabClose() {
	const ssoProvider = localStorage.getItem('SSOProvider');

	const ssoInactivityAutoLogout = ssoProvider
		? ssoClientsConfigs.inactivityTrack[
				ssoProvider.toLocaleLowerCase() as keyof typeof ssoClientsConfigs.inactivityTrack
		  ]
		: false;

	// SET TO `true` TO SEE THE LOGS
	const DEBUGGER = false;
	const log = debuggerLog(DEBUGGER);

	const tokenExpiryTabCloseMilliseconds =
		process.env.REACT_APP_TOKEN_EXPIRY_TAB_CLOSE_MILLISECONDS;

	const isLogoutOnTabCloseEnabled = tokenExpiryTabCloseMilliseconds !== undefined;

	useEffectOnce(() => {
		if (isFederatedUser && !ssoInactivityAutoLogout) {
			localStorage.removeItem(StorageKeys.PageUnloadedTimestamp);
		}
		if ((isFederatedUser && !ssoInactivityAutoLogout) || !isLogoutOnTabCloseEnabled) return;
		if (!isLogoutOnTabCloseEnabled) return;

		handleTabOpen();
		const countdown = setInterval(handleTabClose, 1000);

		return () => {
			clearInterval(countdown);
		};
	});

	/**
	 * When application starts, check the timestamp inactivity and if its more than `tokenExpiryTabCloseMilliseconds` => logout user
	 */
	function handleTabOpen() {
		const timeStamp = getPageUnloadTimeStamp();
		if (timeStamp) {
			const t0 = Number(timeStamp);
			const t1 = new Date().getTime();
			const duration = t1 - t0;
			const timeOutDuration = getTimeOutDuration();

			if (duration > timeOutDuration) {
				// more than timeOutDuration seconds timeout => assume is close tab / window / session
				log('force logout');
				localStorage.clear();
			} else {
				// less than timeOutDuration seconds timeout => assume is reload
				log('just a reload');
				removePageUnloadTimeStamp();
			}
		}
	}

	/**
	 * When tab is closed set a timestamp to check again when opened
	 */
	function handleTabClose() {
		const isOnPatientPage = document.location.pathname.startsWith('/patient/');
		const isOnPatientSurveyPage =
			document.location.pathname.startsWith('/survey/') ||
			document.location.pathname === '/survey';
		const isOnLoginPage =
			document.location.pathname.startsWith('/login') || document.location.pathname === '/';

		if (isOnPatientPage || isOnPatientSurveyPage || isOnLoginPage) {
			getPageUnloadTimeStamp() && removePageUnloadTimeStamp();
		} else {
			setPageUnloadTimesStamp(new Date().getTime().toString());
		}

		return false;
	}

	function getTimeOutDuration() {
		// if user signed in from hosted UI, keep sessions valid for 30 days
		if (localStorage.getItem(StorageKeys.FederatedUILogin) === 'true') {
			return Number(2.592 * 10e9);
		}
		return Number(tokenExpiryTabCloseMilliseconds);
	}

	function getPageUnloadTimeStamp() {
		return localStorage.getItem(StorageKeys.PageUnloadedTimestamp);
	}

	function setPageUnloadTimesStamp(timestamp: string) {
		return localStorage.setItem(StorageKeys.PageUnloadedTimestamp, timestamp);
	}

	function removePageUnloadTimeStamp() {
		return localStorage.removeItem(StorageKeys.PageUnloadedTimestamp);
	}
}

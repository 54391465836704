import { Typography } from 'components/UI/Typography';
import { Modal } from 'components/UI/Modal';
import { useTranslation, useDeleteForm } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	formId: string;
	onClose: (success?: boolean) => void;
}

export function DeleteFormModal({ formId, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: deletingForm, error: errorDeletingForm }, deleteForm] = useDeleteForm();

	useCompletedAction(deletingForm, errorDeletingForm, () => onClose(true));

	function handleSubmit() {
		deleteForm(formId);
	}

	return (
		<Modal
			title={translate(dict => dict.deleteForm.deleteForm)}
			primary={{
				label: translate(dict => dict.buttons.delete),
				loading: deletingForm,
				warning: true,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{translate(dict => dict.deleteForm.warningText)}
				{'\n\n'}
				<strong>{translate(dict => dict.deleteForm.warningQuestion)}</strong>
			</Typography.Paragraph>
		</Modal>
	);
}

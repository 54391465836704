import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { toggleFormTitle } from 'store/data/forms';

export function useToggleFormTitle(): LocalOperation {
	const dispatch = useDispatch();

	function handler() {
		dispatch(toggleFormTitle());
	}

	return handler;
}

import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { ActionTypes, login } from 'store/auth';
import { useActivity } from '../utils/useActivity';

export function useLogin(): OperationResult<boolean, (username: string, password: string) => void> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.auth.loggedIn);

	const [{ loading, error }] = useActivity(ActionTypes.LOGIN);

	function handler(username: string, password: string) {
		dispatch(login({ username, password }));
	}

	return [{ data, loading, error }, handler];
}

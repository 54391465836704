import produce from 'immer';

import { ProjectFile } from 'api/data/documents';
import { DateFilterOperator } from 'store/account/admin';
import { Actions, ActionTypes, State } from 'store/data/documents';

import initialState from './initialState';
import { ProjectFilesListFilterNames } from './types';

import { Actions as ProjectsActions, ActionTypes as ProjectsActionTypes } from '../projects/types';

export default (state: State = initialState, action: Actions | ProjectsActions): State => {
	switch (action.type) {
		case ProjectsActionTypes.SET_PROJECT_ID: {
			return produce(state, draft => {
				draft.projectFiles = null;
			});
		}

		case ActionTypes.GET_PROJECT_FILES: {
			const { files } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				const types: string[] = [];

				const formattedFiles: ProjectFile[] = files.flatMap(file => {
					if (!file.metadata) return [];

					const type = file.metadata.fileName
						.substring(
							file.metadata.fileName.lastIndexOf('.') + 1,
							file.metadata.fileName.length
						)
						.toLowerCase();

					const name = file.metadata.fileName.substring(
						0,
						file.metadata.fileName.lastIndexOf('.')
					);

					const label = file.metadata.fileLabel.substring(
						0,
						file.metadata.fileLabel.lastIndexOf('.')
					);

					if (!types.includes(type)) types.push(type);

					const projectFile: ProjectFile = {
						fileName: name,
						fileLabel: label,
						username: file.metadata.username || '',
						fileSize: file.metadata.fileSize,
						creationDate: file.metadata.creationDate,
						mimeType: type,
						extendedMimeType: file.metadata.mimeType,
						fileId: file.fileId,
						signedS3Url: file.signedS3URL
					};

					return projectFile;
				});

				filters.type.all = types.sort();
				draft.projectFiles = formattedFiles.reverse(); // show most recent on top
			});
		}

		case ActionTypes.VIEW_PROJECT_FILE: {
			const { fileId } = action.payload;

			return produce(state, draft => {
				const { metadata } = draft;
				metadata.csvFileToView = null;
				metadata.fileToView = fileId;
			});
		}

		case ActionTypes.PROJECT_FILES_SET_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.searchTerm = term;
			});
		}

		case ActionTypes.GET_CSV_XLS_FROM_S3: {
			const { fileData } = action.payload;

			return produce(state, draft => {
				draft.metadata.csvFileToView = fileData;
			});
		}

		// ================================= FILTERS =================================

		case ActionTypes.PROJECT_FILES_SET_FILTER: {
			const { filterName, value } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				// MIME TYPE FILTER
				if (filterName === ProjectFilesListFilterNames.Types) {
					const { active } = filters.type;

					const newActive: string[] = active.includes(value)
						? active.filter(activeValue => activeValue !== value)
						: [...active, value];

					filters.type.active = newActive;
				}

				// MORE CAN BE ADDED LATER...
			});
		}

		case ActionTypes.PROJECT_FILES_SET_DATE_FILTER: {
			const { filterName, filter } = action.payload;

			return produce(state, draft => {
				const { filters } = draft;

				if (filterName === ProjectFilesListFilterNames.UploadDate) {
					filters.uploadDate = filter;
				}
			});
		}

		case ActionTypes.PROJECT_FILES_RESET_FILTER: {
			const { filterName } = action.payload;

			return produce(state, draft => {
				const { type, uploadDate } = draft.filters;

				if (filterName === ProjectFilesListFilterNames.Types) {
					type.active = [];
				}
				if (filterName === ProjectFilesListFilterNames.UploadDate) {
					uploadDate.valid = false;
					uploadDate.operator = DateFilterOperator.Before;
					uploadDate.value = null;
					uploadDate.from = null;
					uploadDate.to = null;
				}
			});
		}

		case ActionTypes.PROJECT_FILES_RESET_ALL_FILTERS: {
			return produce(state, draft => {
				const { type, uploadDate } = draft.filters;

				// CHECKBOX
				type.active = [];
				// DATE
				uploadDate.valid = false;
				uploadDate.operator = DateFilterOperator.Before;
				uploadDate.value = null;
				uploadDate.from = null;
				uploadDate.to = null;
			});
		}

		case ActionTypes.RESET_CSV_TABLE: {
			return produce(state, draft => {
				draft.metadata.csvFileToView = null;
			});
		}

		// ================================= FILTERS =================================

		default: {
			return state;
		}
	}
};

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { LocalOperation } from 'hooks/store/types';
import { resetExportWizardAction } from 'store/data/exportWizard';
import { ActionTypes } from 'store/data/exportWizard/types';

export function useResetExportWizard(): LocalOperation<() => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.RESET_EXPORT_WIZARD);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(resetExportWizardAction());
	}

	return handler;
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import {
	setFormElementDisplaySelectedValue,
	SetFormElementDisplaySelectedValueAction
} from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormElementDisplaySelectedValue(): LocalOperation<
	(payload: ActionPayload<SetFormElementDisplaySelectedValueAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormElementDisplaySelectedValueAction>) {
		dispatch(setFormElementDisplaySelectedValue(payload));
	}

	return handler;
}

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex: 1;

	@media ${MediaQueries.maxWidth.xl} {
		flex-direction: column;
	}
`;

export const LeftContainer = styled.div`
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media ${MediaQueries.maxWidth.xl} {
		width: 100%;
		margin-bottom: 3rem;
	}
`;

export const ResponseChartContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.maxWidth.xl} {
		margin-bottom: 3rem;
	}
`;

export const LastDaysActivityContainer = styled.div`
	width: 100%;
	@media ${MediaQueries.maxWidth.sm} {
		width: 98%;
	}
`;

export const RightContainer = styled.div`
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.maxWidth.xl} {
		width: 100%;
	}
`;
export const ResponseRatesContainer = styled.div`
	@media ${MediaQueries.maxWidth.xl} {
		overflow: visible;
		display: flex;
		flex-wrap: wrap;
		margin-right: -1.8rem;
	}
`;

export const Title = styled.h4`
	margin-bottom: 0.8rem;
`;

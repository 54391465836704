import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { MoreIcon, MoreMenuDropdown } from 'components/Forms';
import { Colors, Svgs } from 'environment';
import { FormElement } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';
import { OrientationType, DragAndDropTypes } from 'types/index';
import { FormVariable } from '..';
import {
	DraggableRowContainer,
	DraggableHalfWidth,
	RowMenuWrapper,
	MoreMenuWrapper
} from './DropzoneRow.styles';
import { Dropdown } from 'components/UI/Dropdown';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useUnGroupFormElements } from 'hooks/store';

interface Props {
	variablesData: VariablesData;
	elements: FormElement[];
	rowIndex: number;
	accessWrite: boolean;
	descendingIndex: number;
	groupId?: string;
}

export function DropzoneRow({
	variablesData,
	elements,
	rowIndex,
	accessWrite,
	descendingIndex,
	groupId
}: Props) {
	const { translate } = useTranslation();

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const unGroupFormElements = useUnGroupFormElements();

	const { variablesMap } = variablesData;

	let droppableId = `${DragAndDropTypes.FormDroppableZoneRow}-${rowIndex}`;
	if (groupId) droppableId = droppableId.concat(`-${groupId}`);

	return (
		<Droppable
			type={droppableId}
			droppableId={droppableId}
			direction={OrientationType.Horizontal}
		>
			{(provided, snapshot) => (
				<DraggableRowContainer
					ref={provided.innerRef}
					zIndex={descendingIndex}
					style={snapshot.isDraggingOver ? { background: Colors.olive } : undefined}
				>
					{elements.map((element, elementIndex) => (
						<Draggable
							key={element.elementId}
							index={elementIndex}
							draggableId={element.elementId}
							isDragDisabled={!accessWrite}
						>
							{provided => (
								<DraggableHalfWidth
									itemType={`${DragAndDropTypes.FormDroppableZoneRow}-${rowIndex}${groupId}`}
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
								>
									<FormVariable
										element={element}
										variable={variablesMap[element.variableRef ?? '']}
										canBeRemoved={!groupId}
										disabled={!accessWrite}
										isInGroup={!!groupId}
										usedInFormDesigner
									/>
								</DraggableHalfWidth>
							)}
						</Draggable>
					))}

					{provided.placeholder}

					<RowMenuWrapper isInGroup={!!groupId} activeHover={dropdownOpen}>
						<MoreMenuWrapper>
							<MoreMenuDropdown
								toggleComponent={({ ref, toggle }) => (
									<MoreIcon
										ref={ref}
										svg={Svgs.More}
										size={s => s.m}
										onClick={toggle}
									/>
								)}
								width={20}
								offset={{ right: 0, top: 15 }}
								onOpen={() => setDropdownOpen(true)}
								onClose={() => setDropdownOpen(false)}
							>
								<Dropdown.Item
									onClick={() =>
										unGroupFormElements({
											rowIndex,
											groupId
										})
									}
									disabled={!accessWrite}
								>
									<Typography.Paragraph>
										{translate(dict => dict.formDesigner.splitToSeparateRows)}
									</Typography.Paragraph>
								</Dropdown.Item>
							</MoreMenuDropdown>
						</MoreMenuWrapper>
					</RowMenuWrapper>
				</DraggableRowContainer>
			)}
		</Droppable>
	);
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { setFormElementLabel, SetFormElementLabelAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormElementLabel(): LocalOperation<
	(payload: ActionPayload<SetFormElementLabelAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormElementLabelAction>) {
		dispatch(setFormElementLabel(payload));
	}

	return handler;
}

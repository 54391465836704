import { useRef } from 'react';
import { isEmpty } from 'lodash';
import ZingChart, { ZingChartClickEvent } from 'zingchart-react';
import { nanoid as generate } from 'nanoid';
import { KaplanMeierResultsDataV2 } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels } from 'types/index';
import { NoPlot } from '../../NoPlot';
import {
	useAnalyses,
	useAnalysesActiveColum,
	useAnalysisContext,
	useFilters,
	useFullscreenAnalysis,
	useToggleAnalysisChartPlot,
	useTranslation
} from 'hooks/store';
import { AnalysisContainer } from '../../UI';
import { computeKaplanChartDataV2 as computeData } from 'helpers/analysis';
import { withMemo } from 'helpers/HOCs';
import { useWindowSize } from 'hooks/ui';
import { useDeepCompareMemo } from 'hooks/utils';

interface Props {
	statistics: {
		logRankV2: boolean;
		patientsV2: boolean;
		confidenceIntervalsV2: boolean;
	};
	id?: string;
	name: string;
	dataset: KaplanMeierResultsDataV2;
	confidenceIntervals: boolean;
	isLegendEnabled: boolean;
	showChartTicks?: boolean;
	legendHeader?: string;
	scalesLabels: ScalesLabels;
	isForExport?: boolean;
	loading: boolean;
}

function Component({
	statistics: { confidenceIntervalsV2, logRankV2, patientsV2 },
	id,
	name,
	dataset,
	confidenceIntervals,
	isLegendEnabled,
	legendHeader,
	scalesLabels,
	isForExport,
	showChartTicks,
	loading
}: Props) {
	const ref = useRef<ZingChart>(null);
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const [fullscreen] = useFullscreenAnalysis();
	const [activeColumns] = useAnalysesActiveColum();
	const windowSize = useWindowSize();
	const { translate } = useTranslation();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();

	const { series, options, disableAnimations } = useDeepCompareMemo(() => {
		return computeData(
			name,
			dataset,
			confidenceIntervals,
			isLegendEnabled,
			scalesLabels,
			activeColumns,
			!!fullscreen,
			plots,
			showChartTicks,
			legendHeader
		);
	}, [
		name,
		dataset,
		confidenceIntervals,
		isLegendEnabled,
		scalesLabels,
		legendHeader,
		showChartTicks,
		activeColumns,
		plots
	]);

	const renderId = useDeepCompareMemo(() => {
		return generate();
	}, [
		series,
		fullscreen,
		activeColumns,
		windowSize,
		analysisIds,
		filters,
		confidenceIntervalsV2,
		logRankV2,
		patientsV2
	]);

	// legend item click toggle chained range and line series
	function handleLegendItemClick(e: ZingChartClickEvent) {
		!isForExport && togglePlot({ id: analysisId, plotIndex: e.plotindex });
		if (ref.current) {
			ref.current.toggleplot({ plotid: `range-${e.plotindex}` });
		}
	}

	if (isEmpty(dataset) && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	return (
		<Chart
			key={renderId}
			id={id}
			chartRef={ref}
			type={t => t.Mixed}
			series={series}
			options={options}
			onLegendItemClick={handleLegendItemClick}
			disableAnimations={disableAnimations}
			effect={e => e.ExpandVertical}
			method={m => m.RegularEaseOut}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}

export const KaplanChartV2 = withMemo(Component);

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { renameForm, ActionTypes } from 'store/data/forms';

export function useRenameForm(): Operation<(formName: string, formId: string) => Promise<void>> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.RENAME_FORM);

	async function handler(formName: string, formId: string) {
		if (loading) return;
		if (error) resetError();

		await dispatch(renameForm(formName, formId));
	}

	return [{ loading, error }, handler];
}

import { ResetPasswordInput, Credentials } from 'api/auth';
import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';
import { StorageKeys } from 'types/index';

import {
	ActionTypes,
	LoginAction,
	ResetStoreAction,
	SetOneTimePasswordAction,
	VerifyOneTimePasswordAction,
	SubmitOneTimePasswordAction,
	ClearOneTimePasswordAction,
	ForgotPasswordAction,
	ResetPasswordAction,
	ResetMultiFactorAuthAction,
	PatientLoginAction,
	SetPatientLoginParamsAction,
	SetLoginStepAction,
	LoginSteps,
	LocalLogOutAction,
	LocalLogInAction,
	ResetLoginErrorsAction,
	SetUsernameAction
} from './types';
import { authLogoutOrManualCleanup } from 'helpers/autoLogout';

const loginAction = (payload: ActionPayload<LoginAction>): LoginAction => ({
	type: ActionTypes.LOGIN,
	payload
});

export const login =
	({ username, password }: Credentials): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.LOGIN,
			dispatch
		});

		try {
			activity.begin();

			const { loggedIn, loginStep, errors, user } = await context.api
				.auth()
				.login({ username, password });

			if (loginStep === LoginSteps.setupOneTimePassword) {
				await dispatch(setOneTimePassword());
			} else {
				dispatch(
					loginAction({
						loggedIn,
						loginStep,
						user,
						errors
					})
				);
			}

			// USERNAME NEEDS TO BE SET IN ORDER FOR `completeResetPassword`
			if (errors?.newPasswordRequired) dispatch(setUsername({ username }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const resetLoginErrors = (): ResetLoginErrorsAction => ({
	type: ActionTypes.RESET_LOGIN_ERRORS
});

export const logout = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.LOGOUT,
		dispatch
	});

	try {
		activity.begin();

		const isFederatedUser = localStorage.getItem(StorageKeys.FederatedUILogin) === 'true';
		const ledidiSignOutPromise = context.api.auth().ledidiLogout();
		const AWSSignOutPromise = context.api.auth().logout();
		if (isFederatedUser) {
			const user = localStorage.getItem(StorageKeys.Username);
			await authLogoutOrManualCleanup(true);

			if (user) localStorage.setItem(StorageKeys.Username, user);
		}

		localStorage.removeItem('LDContext');
		Promise.all([ledidiSignOutPromise, AWSSignOutPromise]).then(() =>
			authLogoutOrManualCleanup(false)
		);
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const completeFirstLogin =
	(password: string, phone: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.COMPLETE_FIRST_LOGIN,
			dispatch
		});

		try {
			activity.begin();

			const { user, username } = getState().auth;

			if (user && username) {
				await context.api.auth().completeFirstLogin({ user, password, phone });
				await context.api.account.subscription().updateAccount({ phoneNumber: phone });
				await dispatch(login({ username, password }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const forgotPassword = (): ForgotPasswordAction => ({
	type: ActionTypes.FORGOT_PASSWORD
});

const resetPasswordAction = (payload: ActionPayload<ResetPasswordAction>): ResetPasswordAction => ({
	type: ActionTypes.RESET_PASSWORD,
	payload
});

export const resetPassword =
	(username: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.RESET_PASSWORD,
			dispatch
		});

		try {
			activity.begin();

			await context.api.auth().resetPassword(username);
			dispatch(resetPasswordAction({ username }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const completeResetPassword =
	({ password, code }: ResetPasswordInput): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.COMPLETE_RESET_PASSWORD,
			dispatch
		});

		try {
			activity.begin();

			const { username } = getState().auth;

			if (username) {
				await context.api.auth().completeResetPassword({ username, password, code });
				localStorage.removeItem(StorageKeys.LoginStep);
				await dispatch(login({ username, password }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const setOneTimePasswordAction = (
	payload: ActionPayload<SetOneTimePasswordAction>
): SetOneTimePasswordAction => ({
	type: ActionTypes.SET_ONE_TIME_PASSWORD,
	payload
});

export const setOneTimePassword = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.SET_ONE_TIME_PASSWORD,
		dispatch
	});

	try {
		activity.begin();

		const secret = await context.api.auth().setOneTimePassword();
		dispatch(setOneTimePasswordAction({ secret }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const verifyOneTimePasswordAction = (): VerifyOneTimePasswordAction => ({
	type: ActionTypes.VERIFY_ONE_TIME_PASSWORD
});

export const verifyOneTimePassword =
	(code: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.VERIFY_ONE_TIME_PASSWORD,
			dispatch
		});

		try {
			activity.begin();

			await context.api.auth().verifyOneTimePassword(code);
			dispatch(verifyOneTimePasswordAction());
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const submitOneTimePasswordAction = (): SubmitOneTimePasswordAction => ({
	type: ActionTypes.SUBMIT_ONE_TIME_PASSWORD
});

export const submitOneTimePassword =
	(code: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.SUBMIT_ONE_TIME_PASSWORD,
			dispatch
		});

		try {
			activity.begin();

			const { user } = getState().auth;

			if (user) {
				await context.api.auth().submitOneTimePassword(user, code);
				dispatch(submitOneTimePasswordAction());
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const clearOneTimePassword = (): ClearOneTimePasswordAction => ({
	type: ActionTypes.CLEAR_ONE_TIME_PASSWORD
});

const resetMultiFactorAuthAction = (): ResetMultiFactorAuthAction => ({
	type: ActionTypes.RESET_MULTI_FACTOR_AUTH
});

export const resetMultiFactorAuth = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.RESET_MULTI_FACTOR_AUTH,
		dispatch
	});

	try {
		activity.begin();

		await context.api.auth().resetMultiFactorAuth();
		dispatch(resetMultiFactorAuthAction());
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const resetStore = (): ResetStoreAction => ({
	type: ActionTypes.RESET_STORE
});

const patientLoginAction = (): PatientLoginAction => ({
	type: ActionTypes.PATIENT_LOGIN
});

export const patientLogin =
	(username: string, password: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.PATIENT_LOGIN,
			dispatch
		});

		try {
			activity.begin();

			const loggedIn = await context.api.auth().patientLogin(username, password);

			if (loggedIn) dispatch(patientLoginAction());
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setPatientLoginParams = (
	payload: ActionPayload<SetPatientLoginParamsAction>
): SetPatientLoginParamsAction => ({
	type: ActionTypes.SET_PATIENT_LOGIN_PARAMS,
	payload
});

export const setLoginStep = (payload: ActionPayload<SetLoginStepAction>): SetLoginStepAction => ({
	type: ActionTypes.SET_LOGIN_STEP,
	payload
});

export const localLogOut = (payload: ActionPayload<LocalLogOutAction>): LocalLogOutAction => ({
	type: ActionTypes.LOCAL_LOG_OUT,
	payload
});

export const forceLogoutAction = (): Thunk => async dispatch => {
	const activity = createActivity({
		type: ActionTypes.TOKEN_EXPIRED_ERROR,
		dispatch
	});

	try {
		await dispatch(logout());
	} catch (err: any) {
		// the very edge case here where signOut fails!
		activity.error({ error: err.message || 'Force logout failed' });
	} finally {
		const cachedTranslations = localStorage.getItem(StorageKeys.Translations);
		const isFederatedUser = localStorage.getItem(StorageKeys.FederatedUILogin) === 'true';
		if (isFederatedUser) {
			await authLogoutOrManualCleanup(true);
		}

		if (cachedTranslations) {
			localStorage.setItem(StorageKeys.Translations, cachedTranslations);
		}

		activity.error({
			error: 'You got signed out'
		});
	}
};

export const localLogIn = (): LocalLogInAction => ({
	type: ActionTypes.LOCAL_LOGIN
});

export const setUsername = (payload: ActionPayload<SetUsernameAction>): SetUsernameAction => ({
	type: ActionTypes.SET_USERNAME,
	payload
});

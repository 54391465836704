import styled from 'styled-components';
import { MediaQueries, Shadows } from 'environment';

export const InfoCard = styled.div`
	width: 49%;
	min-height: 18.4rem;
	margin-bottom: 2.4rem;
	padding: 2.4rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	@media ${MediaQueries.maxWidth.md} {
		width: 100%;
	}
`;

export const CardSpacer = styled.div`
	margin-left: 5.6rem;
`;

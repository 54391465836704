import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setPatientsSearchTerm, SetPatientsSearchTermAction } from 'store/data/patients';
import { ActionPayload } from 'store/types';

export function usePatientSearchTerm(): LocalOperationResult<
	string,
	(payload: ActionPayload<SetPatientsSearchTermAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.patients.metadata.term);

	function handler(payload: ActionPayload<SetPatientsSearchTermAction>) {
		dispatch(setPatientsSearchTerm(payload));
	}

	return [data, handler];
}

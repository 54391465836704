import type { GenericMap } from './maps';

export enum TableFilterType {
	Text = 'Text',
	Date = 'Date',
	Checkbox = 'Checkbox',
	Numeric = 'Numeric'
}

export interface CustomisableTableColumn {
	name: string;
	label: string;
	isOptional?: boolean;
	filterType?: TableFilterType;
	hasCheckboxSearch?: boolean; // applies to checkbox filters
	disableSort?: boolean; // removes sorting property from this column
}

export type CustomisableTableRow = { id: string } & {
	[key: string]: string | number;
};

export type CustomCellType = GenericMap<GenericMap<string | JSX.Element>>;

export type ExtraCellsType = GenericMap<JSX.Element>;

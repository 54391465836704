import styled, { css } from 'styled-components';

import { Shadows, Colors } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Typography } from 'components/UI/Typography';

interface VisibleProps {
	visible: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	padding-top: 2.4rem;
	padding-bottom: 2.4rem;
	/* TODO: enable when redesign is done */
	/* padding-bottom: 4.8rem; */
	background-color: ${Colors.background.disabled};

	@media print {
		display: none;
	}
`;

export const ListWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const ScrollWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 120rem;
	min-width: 10rem;
`;

export const ListContainer = styled.div`
	display: flex;
`;

export const FormsList = styled.div`
	display: flex;
	overflow-x: scroll;
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	will-change: transform;

	/* Hide scrollbar for Chrome, Safari and Opera */
	::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

export const NavigationIcon = styled(DefaultIcon)<VisibleProps>`
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.1s;
	padding-bottom: 2.2rem;

	${({ visible }) =>
		visible &&
		css`
			opacity: 1;
			pointer-events: initial;
		`}
`;

interface FormWrapperProps {
	lastForm: boolean;
}
export const FormWrapper = styled.div<FormWrapperProps>`
	margin: 0 1rem;

	${({ lastForm }) =>
		lastForm &&
		css`
			padding-right: 1.5rem;
		`}
`;

interface FormContainerProps {
	selected?: boolean;
	hasChanges?: boolean;
}
export const FormContainer = styled.div<FormContainerProps>`
	height: 15.1rem;
	width: 12rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	overflow: hidden;
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	will-change: transform;
	margin-top: 0.1rem;
	margin-bottom: 0.8rem;
	transition: transform 0.1s;
	border-radius: 0.4rem;
	border: solid 0.1rem ${Colors.transparent};
	cursor: pointer;

	${({ selected }) =>
		selected &&
		css`
			border: solid 0.1rem ${Colors.primary.normal};
		`}
	:hover {
		:first-child {
			transform: scale(1.01);
		}
	}
	${({ hasChanges }) =>
		hasChanges &&
		css`
			border: solid 0.1rem ${Colors.chart.lightGreen.dark};
		`}
`;

export const FormName = styled(Typography.Paragraph)`
	width: 11.2rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

import styled, { css } from 'styled-components';

export function Grid() {
	return null;
}

export enum Breakpoints {
	PHONE = '676px',
	TABLET_PORTRAIT = '768px',
	TABLET_LANDSCAPE = '992px',
	SMALL_LAPTOP = '1025px',
	FHD_MONITOR = '1920px',
	TWO_K_MONITOR = '2560px',
	FOUR_K_MONITOR = '3840px'
}

export enum GAP {
	PHONE = 2.2,
	TABLET_PORTRAIT = 2.2,
	TABLET_LANDSCAPE = 2.2,
	SMALL_LAPTOP = 2.2,
	FHD_MONITOR = 2.2,
	TWO_K_MONITOR = 2.2,
	FOUR_K_MONITOR = 2.2
}

export enum CONTAINERS {
	PHONE = '100%',
	TABLET_PORTRAIT = '90.625vw',
	TABLET_LANDSCAPE = '92.2vw',
	SMALL_LAPTOP = '92.2vw',
	FHD_MONITOR = '92.2vw',
	TWO_K_MONITOR = '92.71vw',
	FOUR_K_MONITOR = '92.71vw'
}

interface ColProps {
	animateOffsets?: boolean;
	sizes: {
		xl: number;
		l: number;
		m: number;
	};
	offsets?: {
		xl?: number;
		l?: number;
		m?: number;
	};
}

enum Position {
	Top = 'top',
	Bottom = 'bottom',
	Left = 'left',
	Right = 'right'
}

interface SidebarProps {
	numberOfColumns: {
		xl?: number;
		l?: number;
		s?: number;
	};
	asPosition?: {
		position: Position;
	};
	noContainerGutter?: boolean;
}

export function getSidebarWidth({ numberOfColumns, asPosition, noContainerGutter }: SidebarProps) {
	return css`
		width: 100%;
		${asPosition &&
		css`
			position: fixed;
			${asPosition.position}: 0;
		`}

		@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
			width: 100%;
		}

		@media (min-width: ${Breakpoints.TABLET_LANDSCAPE}) {
			width: ${numberOfColumns &&
			numberOfColumns.l &&
			`calc(${calculateColumnWidth(numberOfColumns.l, Breakpoints.TABLET_LANDSCAPE)} + ${
				GAP.TABLET_LANDSCAPE / 2
			}rem +  ${noContainerGutter ? 0 : 3.9}vw)`};
		}

		@media (min-width: ${Breakpoints.SMALL_LAPTOP}) {
			width: calc(
				(${numberOfColumns.l} * 7.683333vw) - 2rem + ${noContainerGutter ? 0 : 3.9}vw
			);
			width: ${numberOfColumns &&
			numberOfColumns.l &&
			`calc(${calculateColumnWidth(numberOfColumns.l, Breakpoints.SMALL_LAPTOP)} + ${
				GAP.SMALL_LAPTOP / 2
			}rem + ${noContainerGutter ? 0 : 3.9}vw)`};
		}

		@media (min-width: ${Breakpoints.FHD_MONITOR}) {
			width: calc(
				(${numberOfColumns.l} * 7.683333vw) - 2rem + ${noContainerGutter ? 0 : 3.9}vw
			);
			width: ${numberOfColumns &&
			numberOfColumns.l &&
			`calc(${calculateColumnWidth(numberOfColumns.l, Breakpoints.FHD_MONITOR)} + 
			${GAP.FHD_MONITOR / 2}rem +
			${noContainerGutter ? 0 : 3.9}vw)`};
		}

		@media (min-width: ${Breakpoints.TWO_K_MONITOR}) {
			width: ${numberOfColumns &&
			numberOfColumns.l &&
			`calc(${calculateColumnWidth(numberOfColumns.l, Breakpoints.TWO_K_MONITOR)} + 
			${GAP.TWO_K_MONITOR / 2}rem + ${noContainerGutter ? 0 : 3.645}vw)`};
		}

		@media (min-width: ${Breakpoints.FOUR_K_MONITOR}) {
			width: ${numberOfColumns &&
			numberOfColumns.xl &&
			`calc(${calculateColumnWidth(numberOfColumns.xl, Breakpoints.FOUR_K_MONITOR)} + 
			${GAP.FOUR_K_MONITOR / 2}rem + ${noContainerGutter ? 0 : 3.645}vw)`};
		}
	`;
}

export function calculateColumnWidth(numberOfColumns: number, screenSize: Breakpoints) {
	/*
	FORMULA:

	((CONTAINER_WIDTH / NUMBER_OF_COLUMNS_FOR_THAT_RESOLUTION) + (GUTTER_WIDTH * NUMBER_OF_GUTTERS) * NUMBER_OF_COLUMNS)

	*/
	switch (screenSize) {
		case Breakpoints.FOUR_K_MONITOR:
			return `calc((((${CONTAINERS.FOUR_K_MONITOR} / 16) - ${
				GAP.FOUR_K_MONITOR
			}rem ) * ${numberOfColumns}) + (${numberOfColumns - 1} * ${GAP.FOUR_K_MONITOR}rem))`;

		case Breakpoints.TWO_K_MONITOR:
			return `calc((((${CONTAINERS.TWO_K_MONITOR} / 12) - ${
				GAP.TWO_K_MONITOR
			}rem ) * ${numberOfColumns}) + (${numberOfColumns - 1} * ${GAP.TWO_K_MONITOR}rem))`;

		case Breakpoints.FHD_MONITOR:
			return `calc((((${CONTAINERS.FHD_MONITOR} / 12) - ${
				GAP.FHD_MONITOR
			}rem ) * ${numberOfColumns}) + (${numberOfColumns - 1} * ${GAP.FHD_MONITOR}rem))`;

		case Breakpoints.SMALL_LAPTOP:
			return `calc((((${CONTAINERS.SMALL_LAPTOP} / 12) - ${
				GAP.SMALL_LAPTOP
			}rem ) * ${numberOfColumns}) + (${numberOfColumns - 1} * ${GAP.SMALL_LAPTOP}rem))`;

		case Breakpoints.TABLET_LANDSCAPE:
			return `calc((((${CONTAINERS.TABLET_LANDSCAPE} / 12) - ${
				GAP.TABLET_LANDSCAPE
			}rem ) * ${numberOfColumns}) + (${numberOfColumns - 1} * ${GAP.TABLET_LANDSCAPE}rem))`;

		case Breakpoints.TABLET_PORTRAIT:
			return `calc((((${CONTAINERS.TABLET_PORTRAIT} / 6) - ${
				GAP.TABLET_PORTRAIT
			}rem ) * ${numberOfColumns}) + (${numberOfColumns - 1} * ${GAP.TABLET_PORTRAIT}rem))`;
		case Breakpoints.PHONE:
			return `calc(100% - ${GAP.PHONE}rem)`;
		default:
			return `100%`;
	}
}

const Col = styled.div<ColProps>`
	transition: margin-left 0.15s;
	width: ${calculateColumnWidth(1, Breakpoints.PHONE)};
	margin-bottom: ${GAP.PHONE}rem;

	@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
		width: ${({ sizes: { m } }) => calculateColumnWidth(m, Breakpoints.TABLET_PORTRAIT)};
		margin-left: ${GAP.TABLET_PORTRAIT / 2}rem;
		margin-right: ${GAP.TABLET_PORTRAIT / 2}rem;
		margin-bottom: ${GAP.TABLET_PORTRAIT}rem;
	}

	@media (min-width: ${Breakpoints.TABLET_LANDSCAPE}) {
		width: ${({ sizes: { l } }) => calculateColumnWidth(l, Breakpoints.TABLET_LANDSCAPE)};
		margin-left: ${GAP.TABLET_LANDSCAPE / 2}rem;
		margin-right: ${GAP.TABLET_LANDSCAPE / 2}rem;
		margin-bottom: ${GAP.TABLET_LANDSCAPE}rem;
		${({ offsets }) =>
			offsets?.l !== undefined &&
			css`
				margin-left: ${calculateColumnWidth(offsets.l, Breakpoints.TABLET_LANDSCAPE)};
			`};
	}

	@media (min-width: ${Breakpoints.SMALL_LAPTOP}) {
		width: ${({ sizes: { l } }) => calculateColumnWidth(l, Breakpoints.SMALL_LAPTOP)};
		margin-left: ${GAP.SMALL_LAPTOP / 2}rem;
		margin-right: ${GAP.SMALL_LAPTOP / 2}rem;
		margin-bottom: ${GAP.SMALL_LAPTOP}rem;
		${({ offsets }) =>
			offsets?.l !== undefined &&
			css`
				margin-left: ${calculateColumnWidth(offsets.l, Breakpoints.SMALL_LAPTOP)};
			`};
	}

	@media (min-width: ${Breakpoints.FHD_MONITOR}) {
		width: ${({ sizes: { l } }) => calculateColumnWidth(l, Breakpoints.FHD_MONITOR)};
		margin-left: ${GAP.FHD_MONITOR / 2}rem;
		margin-right: ${GAP.FHD_MONITOR / 2}rem;
		margin-bottom: ${GAP.FHD_MONITOR}rem;
		${({ offsets }) =>
			offsets?.l !== undefined &&
			css`
				margin-left: ${calculateColumnWidth(offsets.l, Breakpoints.FHD_MONITOR)}};
			`};
	

	@media (min-width: ${Breakpoints.TWO_K_MONITOR}) {
		width: ${({ sizes: { l } }) => calculateColumnWidth(l, Breakpoints.TWO_K_MONITOR)};
		margin-left: ${GAP.TWO_K_MONITOR / 2}rem;
		margin-right: ${GAP.TWO_K_MONITOR / 2}rem;
		margin-bottom: ${GAP.TWO_K_MONITOR}rem;
		${({ offsets }) =>
			offsets?.l !== undefined &&
			css`
				margin-left: ${calculateColumnWidth(offsets.l, Breakpoints.TWO_K_MONITOR)}};
			`};
	
	@media (min-width: ${Breakpoints.FOUR_K_MONITOR}) {
		width: ${({ sizes: { xl } }) => calculateColumnWidth(xl, Breakpoints.FOUR_K_MONITOR)};
		margin-left: ${GAP.FOUR_K_MONITOR / 2}rem;
		margin-right: ${GAP.FOUR_K_MONITOR / 2}rem;
		margin-bottom: ${GAP.FOUR_K_MONITOR}rem;
		${({ offsets }) =>
			offsets?.xl !== undefined &&
			css`
				margin-left: ${calculateColumnWidth(offsets.xl, Breakpoints.FOUR_K_MONITOR)}};
			`} 
`;

const Row = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;

	@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
		width: calc(100% + ${GAP.TABLET_PORTRAIT}rem);
		margin-left: -${GAP.TABLET_PORTRAIT / 2}rem;
		margin-right: -${GAP.TABLET_PORTRAIT / 2}rem;
		justify-content: flex-start;
	}
	@media (min-width: ${Breakpoints.TABLET_LANDSCAPE}) {
		width: calc(100% + ${GAP.TABLET_LANDSCAPE}rem);
		margin-left: -${GAP.TABLET_LANDSCAPE / 2}rem;
		margin-right: -${GAP.TABLET_LANDSCAPE / 2}rem;
	}
	@media (min-width: ${Breakpoints.SMALL_LAPTOP}) {
		width: calc(100% + ${GAP.SMALL_LAPTOP}rem);
		margin-left: -${GAP.SMALL_LAPTOP / 2}rem;
		margin-right: -${GAP.SMALL_LAPTOP / 2}rem;
	}

	@media (min-width: ${Breakpoints.FHD_MONITOR}) {
		width: calc(100% + ${GAP.FHD_MONITOR}rem);
		margin-left: -${GAP.FHD_MONITOR / 2}rem;
		margin-right: -${GAP.FHD_MONITOR / 2}rem;
	}
	@media (min-width: ${Breakpoints.TWO_K_MONITOR}) {
		width: calc(100% + ${GAP.TWO_K_MONITOR}rem);
		margin-left: -${GAP.TWO_K_MONITOR / 2}rem;
		margin-right: -${GAP.TWO_K_MONITOR / 2}rem;
	}

	@media (min-width: ${Breakpoints.FOUR_K_MONITOR}) {
		width: calc(100% + ${GAP.FOUR_K_MONITOR}rem);
		margin-left: -${GAP.FOUR_K_MONITOR / 2}rem;
		margin-right: -${GAP.FOUR_K_MONITOR / 2}rem;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 100vw;
	padding: 0 ${GAP.PHONE / 2}rem;
	margin: 0;

	@media (min-width: ${Breakpoints.TABLET_PORTRAIT}) {
		max-width: ${CONTAINERS.TABLET_PORTRAIT};
		margin-left: 4.6875vw;
		margin-right: 4.6875vw;
		padding: 0;
	}
	@media (min-width: ${Breakpoints.TABLET_LANDSCAPE}) {
		max-width: ${CONTAINERS.TABLET_LANDSCAPE};
		margin-left: 3.9vw;
		margin-right: 3.9vw;
	}

	@media (min-width: ${Breakpoints.SMALL_LAPTOP}) {
		max-width: ${CONTAINERS.SMALL_LAPTOP};
		margin-left: 3.9vw;
		margin-right: 3.9vw;
	}
	@media (min-width: ${Breakpoints.FHD_MONITOR}) {
		max-width: ${CONTAINERS.FHD_MONITOR};
		margin-left: 3.9vw;
		margin-right: 3.9vw;
	}

	@media (min-width: ${Breakpoints.TWO_K_MONITOR}) {
		max-width: ${CONTAINERS.TWO_K_MONITOR};
		margin-left: 3.645vw;
		margin-right: 3.645vw;
	}
	@media (min-width: ${Breakpoints.FOUR_K_MONITOR}) {
		max-width: ${CONTAINERS.FOUR_K_MONITOR};
		margin-left: 3.645vw;
		margin-right: 3.645vw;
	}
`;

Grid.Container = Container;
Grid.Row = Row;
Grid.Col = Col;

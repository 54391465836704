import { nanoid as generate } from 'nanoid';

import { JADBioAnalysis, JADBioAnalysisModels } from 'api/data/analyses';
import {
	InitiateJADBioProjectInput,
	InitiateJADBioDatasetInput,
	GetAnalysisStatusOutput,
	JADBioAnalysisStatus,
	GetJADBioProjectIdInput,
	JADBioDatasetState,
	GetJADBioDatasetStatusInput
} from 'api/addons/jadbio';
import { Dictionary } from 'environment';
import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';
import { ApplicationState } from 'store/root';
import { LedidiStatusCode, StorageKeys } from 'types/index';

import { extractVariableLabel, parseJADBioAnalysisResult } from './parsers';
import {
	ActionTypes,
	LogInToJADBioAction,
	InitiateJADBioProjectAction,
	SetJADBioProgressAction,
	GetJADBioResultAction,
	InitiateJADBioDatasetAction,
	LogOutFromJADBioAction,
	SetTokenValidAction,
	GetJADBioAnalysisErrorAction,
	ClearJADBioOutputModelAction,
	StopJADBioAnalysisProgressAction,
	PauseJADBioProgressAction
} from './types';
import { parseApiEntryFilters } from 'helpers/analysis';
import { timeout } from 'helpers/generic';
import { ledidiStatusCodeToErrorMessage } from 'helpers/api';
import { AnalysisType } from 'api/data/analyses/constants';

export const setTokenValidAction = (
	payload: ActionPayload<SetTokenValidAction>
): SetTokenValidAction => ({
	type: ActionTypes.SET_TOKEN_VALID,
	payload
});

export const logInToJADBioAction = (): LogInToJADBioAction => ({
	type: ActionTypes.LOG_IN_TO_JADBIO
});

export const logInToJadbio =
	(username: string, pass: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.LOG_IN_TO_JADBIO, dispatch });

		try {
			activity.begin();

			const { jadBioToken } = await context.api.addons.jadbio().logInToJadbio({
				jadbioUserName: username,
				jadbioPassword: pass
			});

			localStorage.setItem(StorageKeys.JADBioIdToken, jadBioToken.Authorization);

			dispatch(logInToJADBioAction());
		} catch (e: any) {
			activity.error({ error: Dictionary.errors.api.addons.JADBioLogin });
		} finally {
			activity.end();
		}
	};

export const logOutFromJADBioAction = (): LogOutFromJADBioAction => ({
	type: ActionTypes.LOG_OUT_FROM_JADBIO
});

const initiateJADBioProjectAction = (
	payload: ActionPayload<InitiateJADBioProjectAction>
): InitiateJADBioProjectAction => ({
	type: ActionTypes.INITIATE_JADBIO_PROJECT,
	payload
});

export const initiateJADBioProject =
	(projectId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INITIATE_JADBIO_PROJECT,
			dispatch
		});

		try {
			activity.begin({ payload: projectId });

			const {
				addons: { jadbio }
			} = getState();

			if (jadbio) {
				const inputProject: InitiateJADBioProjectInput = {
					projectId: Number(projectId),
					jadBioProjectName: projectId + '_' + generate()
				};

				const jadBioProjectId = await context.api.addons
					.jadbio()
					.initiateJADBioProject(inputProject);

				dispatch(
					initiateJADBioProjectAction({
						jadBioProject: { jadBioProjectId, jadBioProjectInitialized: true },
						projectId
					})
				);
			}
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({
						error: errorMessage ?? e.message,
						payload: projectId
					});
				})
			);
		} finally {
			activity.end();
		}
	};

export const getJADBioProjectId = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_JADBIO_PROJECT_ID,
		dispatch
	});

	const {
		addons: { jadbio },
		data: {
			projects: { projectId }
		}
	} = getState();

	try {
		if (projectId && jadbio) {
			activity.begin({ payload: projectId });

			const inputProject: GetJADBioProjectIdInput = {
				projectId: Number(projectId)
			};

			const jadBioProjectId = await context.api.addons
				.jadbio()
				.getJadBioProjectId(inputProject);

			if (!jadBioProjectId) dispatch(initiateJADBioProject(projectId));
			else
				dispatch(
					initiateJADBioProjectAction({
						jadBioProject: { jadBioProjectId, jadBioProjectInitialized: true },
						projectId
					})
				);
		}
	} catch (e: any) {
		dispatch(
			handleLedidiStatusCode(e.message, errorMessage => {
				activity.error({
					error: errorMessage ?? e.message,
					payload: projectId
				});
			})
		);
	} finally {
		activity.end();
	}
};

const clearJADBioOutputModelAction = (
	payload: ActionPayload<ClearJADBioOutputModelAction>
): ClearJADBioOutputModelAction => ({
	type: ActionTypes.CLEAR_JADBIO_OUTPUT_MODEL,
	payload
});

const initiateJADBioDatasetAction = (
	payload: ActionPayload<InitiateJADBioDatasetAction>
): InitiateJADBioDatasetAction => ({
	type: ActionTypes.INITIATE_JADBIO_DATASET,
	payload
});

export const initiateJADBioDataset =
	(analysisId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INITIATE_JADBIO_DATASET,
			dispatch
		});

		const {
			addons: { jadbio },
			data: {
				projects: { projectId, byId },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				}
			}
		} = getState();

		try {
			if (jadbio && projectId && jadbio.projects.byProjectId[projectId]) {
				const project = byId[projectId];

				activity.begin({ payload: analysisId });

				const { jadBioProjectId } = jadbio.projects.byProjectId[projectId];

				const allFilters = filtersByProjectId[projectId].active.map(id => filtersById[id]);

				const filters = parseApiEntryFilters(allFilters);

				const inputDataset: InitiateJADBioDatasetInput = {
					projectId: Number(projectId),
					jadBioDatasetName: 'Dataset_' + project.projectName + '_' + generate(),
					filters,
					jadBioProjectId
				};

				const jadBioTaskId = await context.api.addons
					.jadbio()
					.initiateJADBioDataset(inputDataset);

				const inputGetDatasetStatus: GetJADBioDatasetStatusInput = {
					projectId: Number(projectId),
					jadBioTaskId
				};

				let datasetStatus = JADBioDatasetState.Pending;
				let jadBioDatasetId = '';
				let timeoutCount = 0;

				while (datasetStatus === JADBioDatasetState.Pending) {
					const [result] = await Promise.all([
						context.api.addons.jadbio().getJADBioDatasetStatus(inputGetDatasetStatus),
						timeout(1000)
					]);

					datasetStatus = result.state;
					jadBioDatasetId = result.jadBioDatasetId;

					if (timeoutCount > 100) throw Error();

					timeoutCount++;
				}

				if (jadBioDatasetId) {
					dispatch(
						initiateJADBioDatasetAction({
							analysisId,
							jadBioDatasetId
						})
					);
				} else throw Error();
			}
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({
						error: errorMessage ?? e.message,
						payload: analysisId
					});
				})
			);
		} finally {
			activity.end();
		}
	};

export const initiateJADBioClassificationModel =
	(analysisId: string, model: JADBioAnalysisModels): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INITIATE_JADBIO_CLASSIFICATION_MODEL,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId + '_' + model });

			const {
				addons: { jadbio },
				data: {
					projects: { projectId },
					analyses: { byId: analysesById }
				}
			} = getState();

			if (projectId && jadbio) {
				const {
					projects: { byProjectId: jadBioProjectByProjectId }
				} = jadbio;

				if (projectId in jadBioProjectByProjectId) {
					const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;
					const jadBioDatasetId = analysis.input.jadBioDatasetId;

					if (jadBioDatasetId) {
						// remove previous output data and set progress to 0
						dispatch(clearJADBioOutputModelAction({ analysisId, model }));
						dispatch(setJADBioProgressAction({ analysisId, model, progress: 0 }));

						const { jadBioAnalysisId } = await context.api.addons
							.jadbio()
							.initiateClassificationModel({
								projectId: Number(projectId),
								nameOfAnalysis: 'Classification_' + jadBioDatasetId,
								jadBioDatasetId,
								classificationVariable:
									extractVariableLabel(
										getState,
										analysis.input.variables.classificationVariable
									) ?? ''
							});

						dispatch(
							setJADBioProgressAction({
								analysisId,
								progress: 0,
								jadBioAnalysisId,
								model
							})
						);
						dispatch(getJADBioAnalysisResult(analysisId, jadBioAnalysisId, model));
					}

					// // For testing
					// const jadBioOutput = parseJADBioAnalysisResult(JADBIO_MOCK_OUTPUT);

					// dispatch(getJADBioAnalysisAction({ jadBioOutput, analysisId }));
				}
			}
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({
						error: errorMessage ?? e.message,
						payload: analysisId + '_' + model
					});
				})
			);

			dispatch(
				getJADBioAnalysisErrorAction({
					analysisId,
					model
				})
			);
		} finally {
			activity.end();
		}
	};

export const initiateJADBioSurvivalAnalysisModel =
	(analysisId: string, model: JADBioAnalysisModels): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INITIATE_JADBIO_SURVIVAL_ANALYSIS_MODEL,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId + '_' + model });

			const {
				addons: { jadbio },
				data: {
					projects: { projectId },
					analyses: { byId: analysesById }
				}
			} = getState();

			if (jadbio && projectId) {
				const {
					projects: { byProjectId: jadBioProjectByProjectId }
				} = jadbio;

				if (projectId in jadBioProjectByProjectId) {
					const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;
					const jadBioDatasetId = analysis.input.jadBioDatasetId;

					if (jadBioDatasetId) {
						// remove previous output data and set progress to 0
						dispatch(clearJADBioOutputModelAction({ analysisId, model }));
						dispatch(setJADBioProgressAction({ analysisId, model, progress: 0 }));

						const { jadBioAnalysisId } = await context.api.addons
							.jadbio()
							.initiateSurvivalAnalysisModel({
								projectId: Number(projectId),
								nameOfAnalysis: 'Survival_' + jadBioDatasetId,
								jadBioDatasetId,
								eventVariableName:
									extractVariableLabel(
										getState,
										analysis.input.variables.eventVariableName
									) ?? '',
								timeToEventVariableName:
									extractVariableLabel(
										getState,
										analysis.input.variables.timeToEventVariableName
									) ?? ''
							});

						dispatch(
							setJADBioProgressAction({
								analysisId,
								progress: 0,
								jadBioAnalysisId,
								model
							})
						);
						dispatch(getJADBioAnalysisResult(analysisId, jadBioAnalysisId, model));
					}
				}
			}
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({
						error: errorMessage ?? e.message,
						payload: analysisId + '_' + model
					});
				})
			);

			dispatch(
				getJADBioAnalysisErrorAction({
					analysisId,
					model
				})
			);
		} finally {
			activity.end();
		}
	};

export const initiateJADBioRegressionModel =
	(analysisId: string, model: JADBioAnalysisModels): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INITIATE_JADBIO_REGRESSION_MODEL,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId + '_' + model });

			const {
				addons: { jadbio },
				data: {
					projects: { projectId },
					analyses: { byId: analysesById }
				}
			} = getState();

			if (jadbio && projectId) {
				const {
					projects: { byProjectId: jadBioProjectByProjectId }
				} = jadbio;

				if (projectId in jadBioProjectByProjectId) {
					const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;
					const jadBioDatasetId = analysis.input.jadBioDatasetId;

					if (jadBioDatasetId) {
						// remove previous output data and set progress to 0
						dispatch(clearJADBioOutputModelAction({ analysisId, model }));
						dispatch(setJADBioProgressAction({ analysisId, model, progress: 0 }));

						const { jadBioAnalysisId } = await context.api.addons
							.jadbio()
							.initiateRegressionModel({
								projectId: Number(projectId),
								nameOfAnalysis: 'Regression_' + jadBioDatasetId,
								jadBioDatasetId,
								regressionVariable:
									extractVariableLabel(
										getState,
										analysis.input.variables.regressionVariable
									) ?? ''
							});

						dispatch(
							setJADBioProgressAction({
								analysisId,
								progress: 0,
								jadBioAnalysisId,
								model
							})
						);
						dispatch(getJADBioAnalysisResult(analysisId, jadBioAnalysisId, model));
					}
				}
			}
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({
						error: errorMessage ?? e.message,
						payload: analysisId + '_' + model
					});
				})
			);

			dispatch(
				getJADBioAnalysisErrorAction({
					analysisId,
					model
				})
			);
		} finally {
			activity.end();
		}
	};

const setJADBioProgressAction = (
	payload: ActionPayload<SetJADBioProgressAction>
): SetJADBioProgressAction => ({
	type: ActionTypes.SET_JADBIO_ANALYSIS_PROGRESS,
	payload
});

export const pauseJADBioProgressAction = (
	payload: ActionPayload<PauseJADBioProgressAction>
): PauseJADBioProgressAction => ({
	type: ActionTypes.PAUSE_JADBIO_ANALYSIS_PROGRESS,
	payload
});

export const pauseJADBioProgress =
	(analysisId: string, pauseProgress: boolean): Thunk =>
	async (dispatch, getState) => {
		const analysesById = getState().data.analyses.byId;
		const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;

		if (analysis && analysis.input) {
			const model = analysis.input.model;
			const dataset = analysis.output.dataset;

			if (model && dataset[model].progress !== null) {
				dispatch(pauseJADBioProgressAction({ analysisId, model, pauseProgress }));
			}
		}
	};

const getJADBioAnalysisErrorAction = (
	payload: ActionPayload<GetJADBioAnalysisErrorAction>
): GetJADBioAnalysisErrorAction => ({
	type: ActionTypes.GET_JADBIO_ANALYSIS_ERROR,
	payload
});

const getJADBioAnalysisAction = (
	payload: ActionPayload<GetJADBioResultAction>
): GetJADBioResultAction => ({
	type: ActionTypes.GET_JADBIO_ANALYSIS_RESULT,
	payload
});

// TODO: Clean and refactor this function after we don't have to call validateJADBioToken anymore
export const getJADBioAnalysisResult =
	(analysisId: string, jadBioAnalysisId: string, model: JADBioAnalysisModels): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_JADBIO_ANALYSIS_RESULT,
			dispatch
		});

		// progress might not be null or 0 at this point, if we are resuming a generation
		let { progress, pauseProgress, stopProgress } = getJADBioProgress(
			analysisId,
			model,
			getState
		);
		progress = progress ?? 0;

		try {
			activity.begin({ payload: analysisId + '_' + model });

			const {
				addons: { jadbio },
				data: {
					projects: { projectId },
					analyses: { byId: analysesById }
				}
			} = getState();

			if (jadbio && projectId) {
				const {
					projects: { byProjectId: jadBioProjectByProjectId }
				} = jadbio;
				const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;

				if (analysis && projectId in jadBioProjectByProjectId) {
					let analysisStatus = JADBioAnalysisStatus.Running;
					let timeoutCount = 0;

					while (
						(analysisStatus === JADBioAnalysisStatus.Running ||
							analysisStatus === JADBioAnalysisStatus.Pending) &&
						progress !== null &&
						!stopProgress &&
						!pauseProgress
					) {
						const [analysisStatusRes] = (await Promise.all([
							context.api.addons.jadbio().getAnalysisStatus({
								projectId: Number(projectId),
								jadBioAnalysisId
							}),
							timeout(1000)
						])) as [GetAnalysisStatusOutput, any];
						analysisStatus = analysisStatusRes.jadBioAnalysisStatus;

						if (analysisStatus !== JADBioAnalysisStatus.Aborted)
							dispatch(
								setJADBioProgressAction({
									progress: Math.round(analysisStatusRes.jadBioAnalysisProgress),
									analysisId,
									jadBioAnalysisId,
									model
								})
							);

						timeoutCount++;

						// try for 10 minutes (used only for demo)
						if (timeoutCount >= 600) {
							throw new Error(Dictionary.errors.api.addons.JADBioAnalysis);
						}

						// get updated progress
						const {
							progress: updatedProgress,
							pauseProgress: updatedPauseProgress,
							stopProgress: updatedStopProgress
						} = getJADBioProgress(analysisId, model, getState);
						progress = updatedProgress;
						pauseProgress = updatedPauseProgress;
						stopProgress = updatedStopProgress;
					}

					if (progress !== null && !pauseProgress && !stopProgress) {
						if (analysisStatus === JADBioAnalysisStatus.Finished) {
							const result = await context.api.addons.jadbio().getAnalysisResult({
								projectId: Number(projectId),
								jadBioAnalysisId
							});

							// extract the analysis element
							//const analysesResult = extractAnalysisFromResult(result, analysis, getState);

							const jadBioOutput = parseJADBioAnalysisResult(
								result,
								jadBioAnalysisId
							);

							dispatch(getJADBioAnalysisAction({ jadBioOutput, analysisId }));
						} else if (analysisStatus !== JADBioAnalysisStatus.Aborted) {
							throw Error();
						}
					}
				}
			}
		} catch (e: any) {
			dispatch(
				getJADBioAnalysisErrorAction({
					analysisId,
					model
				})
			);
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({
						error: errorMessage ?? e.message,
						payload: analysisId + '_' + model
					});
				})
			);
		} finally {
			const { pauseProgress: updatedPauseProgress, stopProgress: updatedStopProgress } =
				getJADBioProgress(analysisId, model, getState);
			if (updatedPauseProgress) {
				dispatch(pauseJADBioProgressAction({ analysisId, model, pauseProgress: false }));
			}
			if (updatedStopProgress) {
				dispatch(clearJADBioOutputModelAction({ analysisId, model }));
			}

			activity.end();
		}
	};

const stopJADBioAnalysisProgressAction = (
	payload: ActionPayload<StopJADBioAnalysisProgressAction>
): StopJADBioAnalysisProgressAction => ({
	type: ActionTypes.STOP_JADBIO_ANALYSIS_PROGRESS,
	payload
});

export const abortJADBioAnalysisGeneration =
	(analysisId: string, model: JADBioAnalysisModels): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.ABORT_JADBIO_ANALYSIS_GENERATION,
			dispatch
		});

		try {
			activity.begin({ payload: analysisId });

			const {
				addons: { jadbio },
				data: {
					projects: { projectId },
					analyses: { byId: analysesById }
				}
			} = getState();

			const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;

			if (projectId && jadbio && analysis && analysis.output) {
				const jadBioOutput = analysis.output.dataset[model];
				if (jadBioOutput) {
					const jadBioAnalysisId = jadBioOutput.jadBioAnalysisId;
					await context.api.addons.jadbio().deleteAnalyses({
						jadbioAnalysisIds: [jadBioAnalysisId],
						projectId: Number(projectId)
					});
					dispatch(stopJADBioAnalysisProgressAction({ analysisId, model }));
				}
			}
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({ payload: analysisId, error: errorMessage ?? '' });
				})
			);
		} finally {
			activity.end();
		}
	};

export const deleteJADBioAnalyses =
	(analysesIds: string[], projectId: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_JADBIO_ANALYSES,
			dispatch
		});

		try {
			activity.begin();

			await context.api.addons.jadbio().deleteAnalyses({
				jadbioAnalysisIds: analysesIds,
				projectId: Number(projectId)
			});
		} catch (e: any) {
			dispatch(
				handleLedidiStatusCode(e.message, errorMessage => {
					activity.error({ error: errorMessage ?? '' });
				})
			);
		} finally {
			activity.end();
		}
	};

/**
 *
 * @param analysesIds Given the analysesIds, extract the JADBio analyses and returns all the JADBioAnalysesIds
 * @param getState
 */
export const extractJADBioAnalysesIds = (
	analysesIds: string[],
	getState: () => ApplicationState
) => {
	const analyses = getState().data.analyses.byId;

	const jadbioAnalysesIds: string[] = [];
	Object.values(analyses).forEach(analysis => {
		if (analysis.type === AnalysisType.JADBio && analysesIds.includes(analysis.id)) {
			const jadbioAnalyses = analysis as JADBioAnalysis;

			const jadbioAnalysisIdClassification =
				jadbioAnalyses.output.dataset[JADBioAnalysisModels.Classification].jadBioAnalysisId;

			const jadbioAnalysisIdSurvival =
				jadbioAnalyses.output.dataset[JADBioAnalysisModels.SurvivalAnalysis]
					.jadBioAnalysisId;

			const jadbioAnalysisIdRegression =
				jadbioAnalyses.output.dataset[JADBioAnalysisModels.Regression].jadBioAnalysisId;

			if (jadbioAnalysisIdClassification) {
				jadbioAnalysesIds.push(jadbioAnalysisIdClassification);
			}
			if (jadbioAnalysisIdSurvival) {
				jadbioAnalysesIds.push(jadbioAnalysisIdSurvival);
			}
			if (jadbioAnalysisIdRegression) {
				jadbioAnalysesIds.push(jadbioAnalysisIdRegression);
			}
		}
	});

	return jadbioAnalysesIds;
};

function getJADBioProgress(
	analysisId: string,
	jadBioModel: JADBioAnalysisModels,
	getState: () => ApplicationState
) {
	const {
		addons: { jadbio },
		data: {
			analyses: { byId: analysesById }
		}
	} = getState();

	if (jadbio) {
		const analysis = { ...analysesById[analysisId] } as JADBioAnalysis;

		if (
			analysis &&
			analysis.input &&
			analysis.input.model &&
			analysis.output.dataset[jadBioModel]
		) {
			return {
				progress: analysis.output.dataset[jadBioModel].progress,
				pauseProgress: analysis.output.dataset[jadBioModel].pauseProgress,
				stopProgress: analysis.output.dataset[jadBioModel].stopProgress
			};
		}
	}

	return { progress: null, pauseProgress: false, stopProgress: false };
}

/**
 *
 * @param error Used to handle errors based on LedidiStatusCode.
 * Currently we store the ledidiStatusCode in the error message content
 * If there is a known ledidiStatusCode,
 * then we pass the message through the callback onErrorMessage
 * @param onCodeErrorMessage
 * @returns
 */
const handleLedidiStatusCode =
	(error: string, onErrorMessage: (errorMessage: string | null) => void): Thunk =>
	dispatch => {
		if (error === LedidiStatusCode.JADBioTokenExpired) {
			localStorage.removeItem(StorageKeys.JADBioIdToken);
			dispatch(logOutFromJADBioAction());
		}
		onErrorMessage(ledidiStatusCodeToErrorMessage(error));
	};

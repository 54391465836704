import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { withMemo } from 'helpers/HOCs';

import {
	CardDraggableResizableProps,
	CardPositionManual,
	HeightSizes,
	WidthSizes
} from 'components/Account/AdminDashboard/types';

import {
	CardBody,
	CardHeader,
	CardReplacement,
	ChildrensContainer,
	ComponentWrapper,
	DropToLeft
} from '../../style';
import { AdvancedMenu } from 'components/UI/Interactables/AdvancedMenu';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { ResizableComponent } from '../..';
import { SetSizePayload } from 'components/Account/AdminDashboard/AdminDashboardAutomatic';

interface Props {
	card: CardDraggableResizableProps;
	index: number;
	position: CardPositionManual;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	lockExpandRight?: boolean;
	lockExpandFullRight?: boolean;
	isDragging?: boolean;
	lockExpandUnder?: boolean;
	setDimensions: (payload: SetSizePayload) => void;
	removeCard: (position: CardPositionManual) => void;
}

function Component({
	index,
	card,
	position,
	widthSizes,
	heightSizes,
	lockExpandRight,
	lockExpandFullRight,
	lockExpandUnder,
	setDimensions,
	removeCard
}: Props) {
	const { translate } = useTranslation();
	const [menuVisible, setMenuVisible] = useState(false);
	const [resizingCard, setResizingCard] = useState<string | null>(null);
	const [pointerEvents, setPointerEvents] = useState(true);

	const elementValueRef = useRef<HTMLDivElement>(null);

	const [{ isDragging }, drag] = useDrag(
		{
			type: 'card',
			canDrag: !!card,
			item: (): CardDraggableResizableProps => ({
				index,
				cardId: card.cardId,
				heightSize: card.heightSize,
				cardName: card.cardName,
				widthSize: card.widthSize,
				...(position && { position })
			}),
			collect: monitor => {
				return {
					isDragging: monitor.isDragging()
				};
			}
		},
		[card.cardId]
	);

	const [, drop] = useDrop<any, any, any>(
		{
			accept: 'card',
			hover(item: CardDraggableResizableProps, monitor) {
				if (!monitor.isOver()) return;
				const hoveringAnother = monitor.isOver({ shallow: true });

				setPointerEvents(hoveringAnother);
			},
			drop(item: CardDraggableResizableProps, monitor) {
				if (!monitor.isOver({ shallow: true })) return;
			}
		},
		[card.cardId]
	);

	useEffect(() => {
		setPointerEvents(isDragging);
	}, [isDragging]);

	useLayoutEffect(() => {
		drag(elementValueRef);
		return () => {
			drag(null);
			drop(null);
		};
	}, [drag, drop, elementValueRef]);

	const children = <ChildrensContainer />;

	return (
		<>
			<ComponentWrapper
				ref={elementValueRef}
				isDragging={isDragging}
				hoveringAnother={pointerEvents}
			>
				<CardHeader menuVisible={menuVisible} width={2}>
					<Typography.H6>
						{!(isDragging || resizingCard) && <>{card.cardName}</>}
					</Typography.H6>
					<AdvancedMenu
						className="card-more-icon"
						offset={{ top: 8, right: 8 }}
						items={[
							{ label: translate(({ buttons }) => buttons.edit) },
							{ label: translate(({ buttons }) => buttons.layout) },
							{ label: translate(({ buttons }) => buttons.fullScreen) },
							{
								label: translate(({ buttons }) => buttons.remove),
								handler: () => removeCard(position)
							}
						]}
						active={menuVisible}
						onOpen={() => setMenuVisible(true)}
						onClose={() => setMenuVisible(false)}
					/>
				</CardHeader>
				<CardBody>
					<ResizableComponent
						resizeVertically
						id={card.cardId}
						widthSize={card.widthSize}
						heightSize={card.heightSize}
						widthSizes={widthSizes}
						heightSizes={heightSizes}
						position={position}
						lockExpandRight={lockExpandRight}
						lockExpandFullRight={lockExpandFullRight}
						lockExpandUnder={lockExpandUnder}
						setDimensions={setDimensions}
						setCardAutoResize={setResizingCard}
					>
						{isDragging || resizingCard ? (
							<CardReplacement>
								<DropToLeft />
							</CardReplacement>
						) : (
							<>{children}</>
						)}
					</ResizableComponent>
				</CardBody>
			</ComponentWrapper>
		</>
	);
}

export const ResizableCardDnD = withMemo(Component);

import { useState, useEffect } from 'react';
import { useTranslation } from 'hooks/store';
import { InputType } from 'types/index';

import { Control, Caption } from '../Pagination.style';
import { Input, Row } from './PageInput.style';
import { Typography } from 'components/UI/Typography';
import { Button } from 'components/UI/Interactables/Button';
import { Popover } from 'components/UI/Interactables/Popover';
import { Paragraph } from 'components/UI/Dropdown/DropdownSubmenu/DropdownSubmenu.style';
import './PageInput.css';
interface Props {
	pageIndex: number;
	openPageInputOnRight?: boolean;
	id?: string;
	isPaginationCenteredComponent?: boolean;
	isRevisionSelected?: boolean | undefined;
	onPageChange: (index: number) => void;
}

export function PageInput({
	pageIndex,
	openPageInputOnRight,
	id,
	isPaginationCenteredComponent,
	isRevisionSelected,
	onPageChange
}: Props) {
	const { translate } = useTranslation();
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState((pageIndex + 1).toString());

	useEffect(() => {
		setValue((pageIndex + 1).toString());
	}, [pageIndex]);

	function handlePageChange() {
		const page = Number(value);

		if (page - 1 !== pageIndex) {
			onPageChange(page - 1);
			setOpen(false);
		}
	}

	function isRevisionSelectedFromPaginationCentered() {
		if (isPaginationCenteredComponent && isRevisionSelected) {
			return true;
		}
		return false;
	}

	return (
		<Popover
			open={open}
			width={20}
			offset={{
				top: 5,
				...(openPageInputOnRight ? { left: -10 } : { right: 0 })
			}}
			toggleComponent={({ ref, toggle }) => (
				<Control
					isPaginationCenteredComponent={isPaginationCenteredComponent}
					ref={ref}
					onClick={
						!isRevisionSelectedFromPaginationCentered()
							? toggle
							: function () {
									return {};
							  }
					}
					title=""
				>
					{isPaginationCenteredComponent ? (
						<Paragraph>...</Paragraph>
					) : (
						<Caption>...</Caption>
					)}
				</Control>
			)}
			onToggle={open => setOpen(open)}
			id={id}
		>
			<div>
				<Typography.Paragraph>
					{translate(d => d.pagination.changePage)}
				</Typography.Paragraph>
				<Row>
					<Input
						className="pt-0"
						type={InputType.Number}
						value={value}
						onChange={e => setValue(e.target.value)}
					/>
					<Button
						variant={v => v.primary}
						size="xsmall"
						title={translate(d => d.pagination.go)}
						onClick={handlePageChange}
						paddingOffset={{ x: 1 }}
					/>
				</Row>
			</div>
		</Popover>
	);
}

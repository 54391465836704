import { useEffect } from 'react';

import { ComparePairedAnalysisV2 } from 'api/data/analyses';
import {
	AnalysisErrorWrapper,
	AnalysisStatisticsWrapper,
	AnalysisViewContainer
} from 'components/Analysis/Analyses';
import { VariablesData } from 'store/data/variables';
import { ExportFileNames, FileType } from 'types/index';

import { ComparePairedStatisticsV2 } from './ComparePairedStatisticsV2/ComparePairedStatisticsV2';
import { ComparePairedTableV2 } from './ComparePairedTableV2';
import { downloadFile, getExportFileName } from 'helpers/generic';
import { useAreFiltersValid, useProject, useTranslation } from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import { exportComparePairedV2 } from 'helpers/analysis/exportComparePairedV2';
import { buildAggregationRuleNameToAggregatorVariableMap } from 'helpers/variables';

interface Props {
	analysis: ComparePairedAnalysisV2;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function ComparePairedViewV2({ analysis, variablesData, exporting, loading }: Props) {
	const { translate } = useTranslation();

	const areFiltersValid = useAreFiltersValid();

	const [{ data: project }] = useProject();

	const { variablesMap, variableSetsMap } = variablesData;

	const {
		input,
		options: { configPanel },
		output: { dataset }
	} = analysis;

	const aggRuleToVariableNameMap =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	useEffect(() => {
		if (exporting && dataset) {
			const data = exportComparePairedV2(dataset, {
				translate,
				variablesMap,
				aggRuleToVariableNameMap,
				groupVariable: input.variables.groupVariable
			});

			if (data && project) {
				const fileName = getExportFileName(
					ExportFileNames.ComparePairedTable,
					project.projectId,
					project.projectName
				);
				downloadFile(data, fileName, FileType.Csv);
			}
		}
	}, [exporting]);

	const areStatisticsEnabled = Object.values(input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper
				ignoreAnalysisData={false}
				analysis={analysis}
				analysisType={AnalysisType.ComparePairedV2}
			>
				{dataset.data && (
					<ComparePairedTableV2
						{...(input.variables.groupVariable
							? { groupVariable: input.variables.groupVariable }
							: {})}
						variablesData={variablesData}
						dataset={dataset}
					/>
				)}

				{renderStatistics && (
					<AnalysisStatisticsWrapper>
						<ComparePairedStatisticsV2 analysis={analysis} />
					</AnalysisStatisticsWrapper>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}

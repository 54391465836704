import produce from 'immer';

import { StorageKeys } from 'types/index';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';
import { LoginSteps } from '.';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.LOGIN: {
			const { loggedIn, loginStep, user, errors } = action.payload;

			const username = localStorage.getItem(StorageKeys.Username);

			const otpEnabled = loginStep === LoginSteps.oneTimePassword;

			return produce(state, draft => {
				draft.loginStep = loginStep ?? null;
				draft.oneTimePassword.enabled = otpEnabled;
				draft.errors = errors ?? {};
				draft.loggedIn = loggedIn || draft.loggedIn;
				draft.user = user || draft.user;
				draft.username = username;
				draft.isPatientLoggedIn = false;
			});
		}

		case ActionTypes.RESET_LOGIN_ERRORS: {
			return produce(state, draft => {
				draft.errors = {};
			});
		}

		case ActionTypes.RESET_PASSWORD: {
			const { username } = action.payload;

			localStorage.setItem(StorageKeys.LoginStep, LoginSteps.resetPassword);
			localStorage.setItem(StorageKeys.Username, username);

			return produce(state, draft => {
				draft.loginStep = LoginSteps.resetPassword;
				draft.username = username;
			});
		}

		case ActionTypes.SET_ONE_TIME_PASSWORD: {
			const { secret } = action.payload;
			const username = localStorage.getItem(StorageKeys.Username);

			return produce(state, draft => {
				draft.loginStep = LoginSteps.setupOneTimePassword;
				draft.oneTimePassword.secret = secret;
				draft.username = username;
			});
		}

		case ActionTypes.VERIFY_ONE_TIME_PASSWORD: {
			localStorage.setItem(StorageKeys.LoginCompleted, 'true');

			return produce(state, draft => {
				draft.loginStep = null;
				draft.loggedIn = true;
				draft.oneTimePassword.enabled = true;
				draft.oneTimePassword.secret = null;
			});
		}

		case ActionTypes.SUBMIT_ONE_TIME_PASSWORD: {
			const username = localStorage.getItem(StorageKeys.Username);
			localStorage.setItem(StorageKeys.LoginCompleted, 'true');

			return produce(state, draft => {
				draft.loginStep = null;
				draft.loggedIn = true;
				draft.username = username;
			});
		}

		case ActionTypes.CLEAR_ONE_TIME_PASSWORD: {
			return produce(state, draft => {
				draft.oneTimePassword.enabled = false;
				draft.oneTimePassword.secret = null;
			});
		}

		case ActionTypes.RESET_STORE: {
			localStorage.clear();

			return {
				loginStep: null,
				oneTimePassword: {
					enabled: false,
					secret: null
				},
				loggedIn: false,
				isPatientLoggedIn: false,
				patientLoginParams: null,
				user: null,
				username: null,
				errors: {}
			};
		}

		case ActionTypes.PATIENT_LOGIN: {
			return produce(state, draft => {
				draft.isPatientLoggedIn = true;
				draft.loggedIn = false;
			});
		}

		case ActionTypes.SET_PATIENT_LOGIN_PARAMS: {
			const { params } = action.payload;

			return produce(state, draft => {
				draft.patientLoginParams = params;
				draft.loggedIn = false;
			});
		}

		case ActionTypes.SET_LOGIN_STEP: {
			const { step } = action.payload;

			return produce(state, draft => {
				draft.loginStep = step;
			});
		}

		case ActionTypes.LOCAL_LOG_OUT: {
			const { patient } = action.payload;

			return produce(state, draft => {
				if (patient) draft.isPatientLoggedIn = false;
				else draft.loggedIn = false;
				draft.username = null;
			});
		}

		case ActionTypes.LOCAL_LOGIN: {
			return produce(state, draft => {
				draft.loggedIn = true;
				draft.username = localStorage.getItem(StorageKeys.Username);
			});
		}

		case ActionTypes.SET_USERNAME: {
			const { username } = action.payload;

			return produce(state, draft => {
				draft.username = username;
			});
		}

		default: {
			return state;
		}
	}
};

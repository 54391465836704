import { useState, useEffect } from 'react';
import { useFormik, FormikErrors, FormikValues } from 'formik';
import * as yup from 'yup';
import { Input } from 'components/UI/Inputs/Input';
import { FilterIcon } from 'components/UI/Icons';
import { Svgs, Colors } from 'environment';
import { type NumericTableFilter, NumericFilterOperator } from 'store/ui/tables/types';
import { InputType } from 'types/index';
import { TableFilterProps } from '../TableFilter';
import { DropdownHeader, ResetFilterIcon, DropdownTitle, Dropdown } from '../TableFilter.style';
import { Container, Inputs, Wrapper } from './NumericFilter.style';
import { SelectNumericOperator } from './SelectNumericOperator';
import { useTranslation } from 'hooks/store';
import { usePrevious } from 'hooks/utils';

interface Props extends TableFilterProps {
	filter: NumericTableFilter;
}

export function NumericFilter({
	filter,
	title,
	width,
	offset,
	isLastColumn,
	onOpen,
	onClose,
	updateFilter,
	resetFilter
}: Props) {
	const { translate } = useTranslation();
	const [forceClose, setForceClose] = useState(false);

	function validate({ from, to }: FormikValues) {
		const errors: FormikErrors<FormikValues> = {};

		if (to && from && Number(to) < Number(from)) {
			errors.from = translate(dict => dict.tableLists.filterErrorToValue);
			errors.to = translate(dict => dict.tableLists.filterErrorToValue);
		}

		return errors;
	}

	const initialValues = {
		from: filter.value ?? filter.from ?? '',
		to: filter.to ?? ''
	};

	const validationSchema = yup.object({
		from: yup.number().required(translate(dict => dict.tableLists.filterErrorNumberRequired)),
		to: yup.number().required(translate(dict => dict.tableLists.filterErrorNumberRequired))
	});

	const { values, errors, touched, setFieldValue, setFieldTouched } = useFormik({
		initialValues,
		validationSchema,
		validate,
		enableReinitialize: true,
		onSubmit: () => undefined
	});

	const [operator, setOperator] = useState(filter.operator);
	const [shouldReset, setShouldReset] = useState(false);

	useEffect(() => {
		if (operator !== filter.operator) setOperator(filter.operator);
	}, [filter.operator]);

	useEffect(() => {
		if (!shouldReset) return;

		if (!filter.valid && (filter.value || filter.from || filter.to)) {
			resetFilter();
			setOperator(filter.operator);
		}
		setShouldReset(false);
	}, [shouldReset]);

	const previousOperator = usePrevious(operator);
	useEffect(() => {
		if (previousOperator) {
			const from = filter.value ?? filter.from ?? '';
			const to = filter.to ?? '';
			update(from, to);
		}
	}, [operator]);

	function update(from: string, to: string) {
		if (filter.valid && shouldInvalidateFilter(filter, operator, from, to)) {
			const invalidFilter = { ...filter };
			invalidFilter.valid = false;

			updateFilter(invalidFilter);
		} else {
			const updatedFilter = parseResults(filter, operator, from, to);

			if (updatedFilter) {
				updateFilter(updatedFilter);
			}
		}
	}

	function shouldInvalidateFilter(
		filter: NumericTableFilter,
		operator: NumericFilterOperator,
		from: string,
		to: string
	) {
		if (!filter.value && !filter.from && !filter.to) {
			return true;
		}

		if (operator === NumericFilterOperator.Between && (!from || !to)) {
			return true;
		}

		if (!from) {
			return true;
		}

		return false;
	}

	function hasFilterChanged(filter: NumericTableFilter, updatedFilter: NumericTableFilter) {
		return (
			filter.operator !== updatedFilter.operator ||
			(updatedFilter.value && filter.value !== updatedFilter.value) ||
			(updatedFilter.from && filter.from !== updatedFilter.from) ||
			filter.to !== updatedFilter.to
		);
	}

	function parseResults(
		filter: NumericTableFilter,
		operator: NumericFilterOperator,
		from: string,
		to: string
	) {
		if (!from) return;

		const updatedFilter = { ...filter };
		updatedFilter.operator = operator;
		updatedFilter.valid = true;

		if (operator === NumericFilterOperator.Between) {
			if (to && from && Number(from) < Number(to)) {
				updatedFilter.value = null;

				updatedFilter.from = from;
				updatedFilter.to = to ?? '';

				if (hasFilterChanged(filter, updatedFilter)) {
					return updatedFilter;
				}
			}
		} else {
			updatedFilter.from = null;
			updatedFilter.to = null;

			updatedFilter.value = from;

			if (hasFilterChanged(filter, updatedFilter)) {
				return updatedFilter;
			}
		}
	}

	function reset() {
		if (filter.valid) resetFilter();
		onCloseDropdown();
	}

	const isBetween = operator === NumericFilterOperator.Between;

	function onCloseDropdown() {
		setShouldReset(true);
		onClose?.();
		setForceClose(true);
	}
	const dropdownOffset = isLastColumn ? offset : undefined;

	return (
		<Dropdown
			usedForCustomisableTable
			width={width}
			forceClose={forceClose}
			offset={dropdownOffset}
			toggleComponent={({ ref, open, toggle }) => (
				<FilterIcon ref={ref} active={open || filter.valid} onClick={toggle} />
			)}
			onOpen={onOpen}
			setForceClose={setForceClose}
			onClose={onClose}
		>
			<>
				<DropdownHeader onClick={e => e.stopPropagation()}>
					<DropdownTitle>{title}</DropdownTitle>
					<ResetFilterIcon
						svg={Svgs.Delete}
						colors={{
							color: Colors.text.disabled,
							hover: Colors.primary.normal
						}}
						onClick={reset}
					/>
				</DropdownHeader>
				<Container onClick={e => e.stopPropagation()}>
					<SelectNumericOperator selected={operator} onSelect={setOperator} />
					<Inputs>
						<Wrapper>
							<Input
								type={InputType.Number}
								value={values.from}
								error={errors.from}
								label={translate(({ filterInputs }) =>
									isBetween ? filterInputs.fromUpperCase : filterInputs.value
								)}
								onChange={e => {
									if (!touched.from) setFieldTouched('from');
									const newValue = e.target.value;
									setFieldValue('from', newValue);
									update(newValue, values.to);
								}}
							/>
						</Wrapper>
						{isBetween && (
							<Wrapper>
								<Input
									type={InputType.Number}
									value={values.to}
									error={errors.to}
									label={translate(
										({ filterInputs }) => filterInputs.toUpperCase
									)}
									onChange={e => {
										if (!touched.to) setFieldTouched('to');
										const newValue = e.target.value;
										setFieldValue('to', newValue);
										update(values.from, newValue);
									}}
								/>
							</Wrapper>
						)}
					</Inputs>
				</Container>
			</>
		</Dropdown>
	);
}

import { hasMatches } from 'helpers/strings';
import { cloneDeep } from 'lodash';
import { Role } from 'store/data/roles/types';

export function filterRolesBySearchTerm(roles: Role[], searchTerm: string) {
	const isSearchTermValid = searchTerm.trim().length > 0;

	if (!isSearchTermValid) return roles;

	function match(role: Role) {
		const keywords: string[] = [role.name, role.description];

		return hasMatches({ searchTerm, keywords });
	}

	return cloneDeep(roles).filter(match);
}

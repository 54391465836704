import styled from 'styled-components';

import { Dropdown } from 'components/UI/Dropdown';
import { DropdownItem as DefaultDropdownItem } from 'components/UI/Dropdown/DropdownItem';
import { Dictionary } from 'environment';
import { useTranslation } from 'hooks/store';
import { DateFilterOperator } from 'store/account/admin';

interface Props {
	selected: DateFilterOperator;
	onSelect: (operator: DateFilterOperator) => void;
}

export function SelectDateOperator({ selected, onSelect }: Props) {
	const { translate } = useTranslation();

	function getOperatorLabel(operator: DateFilterOperator) {
		if (operator === DateFilterOperator.Between) return Dictionary.displayers.between;
		if (operator === DateFilterOperator.Exact) return Dictionary.displayers.atADate;
		if (operator === DateFilterOperator.Before) return Dictionary.displayers.before;
		if (operator === DateFilterOperator.After) return Dictionary.displayers.after;

		return Dictionary.displayers.between;
	}

	return (
		<Dropdown title={translate(() => getOperatorLabel(selected))} button>
			<DropdownItem onClick={() => onSelect(DateFilterOperator.Between)}>
				{translate(dict => dict.displayers.between)}
			</DropdownItem>
			<DropdownItem onClick={() => onSelect(DateFilterOperator.Exact)}>
				{translate(dict => dict.displayers.atADate)}
			</DropdownItem>
			<DropdownItem onClick={() => onSelect(DateFilterOperator.Before)}>
				{translate(dict => dict.displayers.before)}
			</DropdownItem>
			<DropdownItem onClick={() => onSelect(DateFilterOperator.After)}>
				{translate(dict => dict.displayers.after)}
			</DropdownItem>
		</Dropdown>
	);
}

const DropdownItem = styled(DefaultDropdownItem)`
	padding: 0 1rem;
`;

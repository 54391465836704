import { useContext } from 'react';

import { ExpandGroupsContext } from 'contexts';

export function useExpandGroupsContext() {
	const context = useContext(ExpandGroupsContext);

	if (context === undefined) {
		throw new Error(
			'useTransferEntriesOwnershipContext must be used within a `ExpandGroupsContext.Provider`'
		);
	}

	return context;
}

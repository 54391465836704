import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { RemoveDashboardCardAction, removeDashboardCard } from 'store/data/projectDashboard';
import { ActionPayload } from 'store/types';

export function useRemoveDashboardCard(): LocalOperation<
	(payload: ActionPayload<RemoveDashboardCardAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<RemoveDashboardCardAction>) {
		dispatch(removeDashboardCard(payload));
	}

	return handler;
}

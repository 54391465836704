import { useEffect } from 'react';

import { GlobalStyle } from 'environment/theme/global';
import { getLanguage } from 'store/ui/i18n';

import { useDispatch, useSelector } from 'hooks/utils';

interface Props {
	children: React.ReactNode;
}

export function Init({ children }: Props) {
	const dispatch = useDispatch();

	const language = useSelector(state => state.ui.i18n.language);

	useEffect(() => {
		dispatch(getLanguage(language));
	}, [language]);

	return (
		<>
			<GlobalStyle />

			{children}
		</>
	);
}

import { useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Colors } from 'environment';
import { setLanguage } from 'store/ui/i18n';
import { DateTimeFormat, AccountDetails } from 'store/account/subscription';
import { LanguageType, StorageKeys } from 'types/index';
import { Container } from './PersonalSettings.style';
import { Dropdown } from 'components/UI/Dropdown';
import { Button } from 'components/UI/Interactables/Button';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { stringAsBoolean } from 'helpers/generic';
import { useTranslation, useUpdateAccount } from 'hooks/store';
import { useDispatch, useMutableState, usePrevious } from 'hooks/utils';

const IS_LANGUAGE_CHANGE_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_LANGUAGE_CHANGE!);

interface Props {
	details: AccountDetails;
}

export enum LanguageTypeToDictionary {
	EN = 'personalSettings.language.english',
	NB = 'personalSettings.language.norwegian',
	NL = 'personalSettings.language.dutch',
	NO = 'personalSettings.language.norwegian'
}

export function PersonalSettings({ details }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const { userPreferences } = details;

	const [{ loading: updatingAccount }, updateAccount] = useUpdateAccount();

	const initialValues = useMemo(() => userPreferences, [userPreferences]);
	const [draftValues, setDraftValues] = useMutableState(initialValues);

	const hasChanges = useMemo(() => !isEqual(initialValues, draftValues), [draftValues]);

	/**
	 * Sync `draftValues` state with redux values
	 */
	const prevInitialValues = usePrevious(initialValues);
	useEffect(() => {
		if (!prevInitialValues) return;

		if (!isEqual(prevInitialValues, initialValues)) setDraftValues(initialValues);
	}, [initialValues]);

	function handleSubmit() {
		if (!hasChanges) return;

		localStorage.setItem(StorageKeys.UserLanguage, draftValues.chosenLanguageCode);
		dispatch(setLanguage({ language: draftValues.chosenLanguageCode }));

		updateAccount(
			{
				userPreferences: {
					dateTimeFormat: draftValues.dateTimeFormat,
					chosenLanguageCode: draftValues.chosenLanguageCode
				}
			},
			translate(dict => dict.personalSettings.updatedNotification)
		);
	}

	return (
		<Container>
			{IS_LANGUAGE_CHANGE_ENABLED && (
				<>
					<Typography.Paragraph
						color={Colors.text.caption}
						marginOffset={{ bottom: 0.8 }}
					>
						{translate(dict => dict.personalSettings.language.label)}
					</Typography.Paragraph>
					<Dropdown
						title={translate(
							() => LanguageTypeToDictionary[draftValues.chosenLanguageCode]
						)}
						label={translate(dict => dict.personalSettings.language.preferredLanguage)}
						button
					>
						<Dropdown.Item
							onClick={() =>
								setDraftValues(state => {
									state.chosenLanguageCode = LanguageType.English;
								})
							}
						>
							{translate(dict => dict.personalSettings.language.english)}
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() =>
								setDraftValues(state => {
									state.chosenLanguageCode = LanguageType.Norwegian;
								})
							}
						>
							{translate(dict => dict.personalSettings.language.norwegian)}
						</Dropdown.Item>
						{/* <Dropdown.Item
							onClick={() =>
								setDraftValues(state => {
									state.chosenLanguageCode = LanguageType.Dutch;
								})
							}
						>
							{translate(dict => dict.personalSettings.language.NL)}
						</Dropdown.Item> */}
					</Dropdown>

					<Spacer size={s => s.m} />
				</>
			)}

			<Typography.Paragraph color={Colors.text.caption} marginOffset={{ bottom: 0.8 }}>
				{translate(dict => dict.personalSettings.dateTime.label)}
			</Typography.Paragraph>
			<Switch
				label={translate(dict => dict.personalSettings.dateTime.component.label)}
				description={translate(dict => dict.personalSettings.dateTime.component.hint)}
				on={draftValues.dateTimeFormat === '12'}
				onChange={() =>
					setDraftValues(state => {
						const nextValue: DateTimeFormat =
							state.dateTimeFormat === '12' ? '24' : '12';

						state.dateTimeFormat = nextValue;
					})
				}
			/>

			<Spacer size={s => s.m} />

			<Button
				title={translate(dict => dict.personalSettings.submitButton)}
				loading={updatingAccount}
				disabled={!hasChanges}
				onClick={handleSubmit}
				hasFullWidth
			/>
		</Container>
	);
}

import { ApiOperation } from 'hooks/store/types';
import { ActionTypes, OwnedProject } from 'store/account/enterprise';
import { useAPI } from 'hooks/api';
import { apiFactory } from 'api';

export function useAPIUserOwnedProjects(): ApiOperation<OwnedProject[], (userId: string) => void> {
	const [{ data, error, loading, fetched }, getUserOwnedProjects, resetHandler] = useAPI<
		OwnedProject[]
	>({
		promiseFn: undefined,
		lazy: true,
		initialData: [],
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: true
		},
		activityType: ActionTypes.GET_LIST_OF_OWNED_PROJECTS
	});

	function promiseFn(userId: string) {
		return apiFactory().account.enterprise().getListOfAllOwnedProjects(userId);
	}

	function handler(userId: string) {
		getUserOwnedProjects(() => promiseFn(userId));
	}

	return [{ data, error, loading, fetched }, handler, resetHandler];
}

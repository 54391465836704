import { useLayoutEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';

import { IndexPayload } from 'components/Account';

import {
	WidgetHeightSize,
	WidthSizes,
	HeightSizes,
	APIWidthSize,
	WidthFourColumnsLayout,
	WidthFiveColumnsLayout,
	WidthSixColumnsLayout,
	WidthSevenColumnsLayout
} from 'store/data/projectDashboard';

import { Dropzone } from '../style';
import { CardDraggableResizablePropsDynamic } from '../../AdminDashboardAutomatic';

interface Props {
	position: {
		rowIndex: number;
		colIndex: number;
	};
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	moveCardToLayout: (indexPayload: IndexPayload | null) => void;
}

export function DropzoneDynamic({ position, heightSizes, widthSizes, moveCardToLayout }: Props) {
	const dropzoneRef = useRef<HTMLDivElement>(null);
	const { colIndex, rowIndex } = position;

	// DROP FOR LEFT
	const [
		{
			handlerId,
			isDraggingMaxHeight,
			isDraggingSecondWidth,
			isDraggingThirdWidth,
			isDraggingForthWidth,
			isDraggingFifthWidth,
			isDraggingSixthWidth,
			isDraggingSeventhWidth,
			isOverCurrent
		},
		drop
	] = useDrop<any, any, any>({
		accept: 'card',
		collect: monitor => {
			const isDragging = !!monitor.getItem();
			const item = dropzoneRef?.current ? monitor.getItem() : null;
			const card = item ? (item as CardDraggableResizablePropsDynamic) : null;
			const draggedId = card?.cardId;
			return {
				isDragging,
				isOverCurrent: monitor.isOver({ shallow: true }),
				isDraggingMaxHeight: isDragging && card?.heightSize === WidgetHeightSize.big,
				isDraggingSecondWidth: isDragging && card?.widthSize === APIWidthSize.two,
				isDraggingThirdWidth: isDragging && card?.widthSize === APIWidthSize.three,
				isDraggingForthWidth: isDragging && card?.widthSize === APIWidthSize.four,
				isDraggingFifthWidth: isDragging && card?.widthSize === APIWidthSize.five,
				isDraggingSixthWidth: isDragging && card?.widthSize === APIWidthSize.six,
				isDraggingSeventhWidth: isDragging && card?.widthSize === APIWidthSize.seven,
				draggedId
			};
		},
		drop(item: CardDraggableResizablePropsDynamic, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { position: draggedPosition } = item;

			if (!position || !draggedPosition) return;
			const { colIndex: draggedColIndex, rowIndex: draggedRowIndex } = draggedPosition;
			const dragged = {
				colIndex: draggedColIndex,
				rowIndex: draggedRowIndex
			};
			const dropped = { rowIndex, colIndex };
			if (dragged === dropped) return;
			moveCardToLayout({
				dragged,
				dropped
			});

			// Fix for Bug when dropping last element on row above  --->
			// Height Scroll Zone shrinking causing cards be undraggable
			const currentScroll = document.querySelector('body')?.scrollTop;
			currentScroll && document.querySelector('body')?.scroll(0, currentScroll - 1);
		}
	});

	const { threeColumns, fourColumns, fiveColumns, sixColumns, sevenColumns } = widthSizes;

	const dynamicWidthSize =
		threeColumns ?? fourColumns ?? fiveColumns ?? sixColumns ?? sevenColumns;

	const dropzoneWidth = isDraggingSeventhWidth
		? (dynamicWidthSize as WidthSevenColumnsLayout).seventhSizeWidth + 30
		: isDraggingSixthWidth
		? (dynamicWidthSize as WidthSixColumnsLayout).sixthSizeWidth + 30
		: isDraggingFifthWidth
		? (dynamicWidthSize as WidthFiveColumnsLayout).fifthSizeWidth + 30
		: isDraggingForthWidth
		? (dynamicWidthSize as WidthFourColumnsLayout).forthSizeWidth + 30
		: isDraggingThirdWidth
		? (dynamicWidthSize?.thirdSizeWidth ?? 0) + 30
		: isDraggingSecondWidth
		? (dynamicWidthSize?.secondSizeWidth ?? 0) + 30
		: (dynamicWidthSize?.firstSizeWidth ?? 0) + 30;

	const dropzoneHeight =
		(isDraggingMaxHeight ? heightSizes.maxSizeHeight : heightSizes.minSizeHeight) + 50;

	useLayoutEffect(() => {
		drop(dropzoneRef);
		return () => {
			drop(null);
		};
	}, []);

	return (
		<Dropzone
			ref={dropzoneRef}
			data-handler-id={handlerId}
			hovered={isOverCurrent}
			height={dropzoneHeight}
			width={dropzoneWidth}
		/>
	);
}

import { logOutFromJADBioAction } from 'store/addons/jadbio';
import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';

export function useLogOutFromJADBio(): LocalOperation<() => void> {
	const dispatch = useDispatch();

	function handler() {
		dispatch(logOutFromJADBioAction());
	}

	return handler;
}

import { useEffect } from 'react';

import { useActivity, useIsProjectValid, useProjectId } from 'hooks/store';
import { useSelector, useDispatch } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	Dashboard,
	updateDashboard,
	selectDashboardById
} from 'store/data/projectDashboard';

interface Options {
	dashboardId: number;
	lazy?: boolean;
}

interface DataProps {
	dashboard: Dashboard | null;
}

export function useUpdateDashboard(options?: Options): OperationResult<DataProps> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const payload = useSelector(state => selectDashboardById(state.data.projectDashboard));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.UPDATE_DASHBOARD, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && !payload && isProjectValid && !options?.lazy) {
			handler();
		}
	}, [loading, error, payload, isProjectValid]);

	function handler() {
		if (loading || !payload) return;
		if (error) resetError();

		dispatch(updateDashboard(payload));
	}

	return [
		{
			loading,
			error,
			data: { dashboard: payload }
		},
		handler
	];
}

import { format, formatDistanceToNow } from 'date-fns';
import { dateTimeFormatMap } from 'consts';
import { Entry } from 'store/data/entries';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import { Value } from './DetailsView.style';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Typography } from 'components/UI/Typography';
import { isValidDate } from 'helpers/isValidDate';
import { useTranslation } from 'hooks/store';
import { useSelector } from 'hooks/utils';
import { useCollaborators } from 'hooks/store';

interface Props {
	entry: Entry;
}

export function DetailsView({ entry }: Props) {
	const { translate } = useTranslation();

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);

	const [
		{
			data: { organizationsMap }
		}
	] = useCollaborators();

	const fields = [
		{
			label: translate(dict => dict.dataset.detailsView.datasetEntryId),
			value: entry.datasetentryid
		},
		{
			label: translate(dict => dict.dataset.detailsView.owner),
			value: entry.ownedbyuserwithname
		},
		{
			label: translate(dict => dict.dataset.detailsView.group),
			value:
				entry.userProjectOrgId &&
				organizationsMap &&
				entry.userProjectOrgId in organizationsMap
					? organizationsMap[entry.userProjectOrgId].name
					: ''
		},
		{
			label: translate(dict => dict.dataset.detailsView.updatedBy),
			value: entry.enteredbyuserwithname
		},
		{
			label: translate(dict => dict.dataset.detailsView.created),
			value: formatDateValue(entry.creationdate)
		},
		{
			label: translate(dict => dict.dataset.detailsView.updated),
			value: formatDateValue(entry.lastmodifieddate)
		}
	];

	function formatDateValue(value: string) {
		const isValidDateValue = isValidDate(value) ? value.replace(/\s/g, 'T') : value;

		const formattedDate =
			value && format(new Date(isValidDateValue), dateTimeFormatMap[dateTimeFormat]);
		const timeAgo =
			value &&
			formatDistanceToNow(new Date(isValidDateValue), {
				addSuffix: true
			});

		return `${formattedDate}\n${timeAgo}`;
	}

	return (
		<Gap marginGap={{ bottom: 1.6 }} marginOffset={{ all: 1.6 }}>
			{fields.map(field => (
				<Flex key={field.label}>
					<Typography.Paragraph
						title={field.label}
						fontweight={w => w.medium}
						marginOffset={{ right: 0.8 }}
						css={`
							white-space: nowrap;
						`}
					>
						{field.label}
					</Typography.Paragraph>
					<Value title={field.value}>{field.value}</Value>
				</Flex>
			))}
		</Gap>
	);
}

import { useTranslation } from 'hooks/store/ui/useTranslation';
import { useMemo } from 'react';
import { Column, usePagination, useTable } from 'react-table';
import { dynamicTranslate } from '../../../../helpers/dynamicTranslate';
import type { FileImportErrorValue } from 'types/data/projects/types';
import { Table } from 'components/UI/Table';
import { nanoid as generate } from 'nanoid';
import { Colors } from 'environment';
import { Pagination } from 'components/UI/Pagination/Pagination';
import { Flex } from 'components/UI/Flex';

type Props = {
	errValues: FileImportErrorValue[];
};

type TableColumns = {
	id: string;
	row: string;
	errorDescription: string;
};

export function ErrorTable({ errValues }: Props) {
	const { translate } = useTranslation();

	const columns: Column<TableColumns>[] = useMemo(() => {
		return [
			{
				Header: translate(dict => dict.importDataset.importErrors.row),
				accessor: `row`
			},
			{
				Header: translate(dict => dict.importDataset.importErrors.errorDescriptionColumn),
				accessor: `errorDescription`
			}
		];
	}, []);

	const data = useMemo(() => {
		const tableData: TableColumns[] = [];

		errValues.forEach(error => {
			tableData.push({
				id: generate(),
				row: error.rowIdx.toString(),
				errorDescription: dynamicTranslate(
					translate(dict => dict.importDataset.importErrors.errorValueTemplate),
					[error.actualColumnLength.toString(), error.expectedColumnLength.toString()]
				)! as string
			});
		});

		return tableData;
	}, [errValues.length]);

	const { page, headerGroups, state, pageOptions, prepareRow, gotoPage, setPageSize } = useTable(
		{
			columns: columns,
			data: data,
			initialState: { pageIndex: 0, pageSize: 50 }
		},
		usePagination
	);

	function handleChangePageIndex(index: number) {
		gotoPage(index);
	}

	function handleChangePageSize(size: number) {
		setPageSize(size);
	}
	return (
		<>
			<Flex style={{ backgroundColor: 'white', padding: '0.4rem' }}>
				<Pagination
					totalCount={errValues.length}
					totalCountLabel={translate(dict => dict.pagination.entries)}
					filteredCount={page.length}
					extraPageSizeOptions={[5, 10]}
					pageIndex={state.pageIndex}
					pageSize={state.pageSize}
					pagesCount={pageOptions.length}
					changePage={handleChangePageIndex}
					changePageSize={handleChangePageSize}
				/>
			</Flex>

			<Table.Responsive id={`import_error_table`}>
				<Table fullWidth stickyHead>
					<Table.Head>
						{headerGroups.map((headerGroup, hgIdx) => (
							<Table.Row
								{...headerGroup.getHeaderGroupProps()}
								key={`hdr_row_${hgIdx}`}
							>
								{headerGroup.headers.map((column, cIdx) => (
									<Table.Column
										{...column.getHeaderProps()}
										key={`hdr_col_${cIdx}`}
										style={{
											lineHeight: 1.57,
											letterSpacing: 'normal',
											color: `${Colors.text.tableMain}`,
											fontSize: '1.4rem',
											fontWeight: 500
										}}
									>
										{column.render('Header')}
									</Table.Column>
								))}
							</Table.Row>
						))}
					</Table.Head>
					<Table.Body style={{ backgroundColor: 'white' }}>
						{page.map(row => {
							prepareRow(row);
							return (
								<Table.Row {...row.getRowProps()} key={row.id}>
									{row.cells.map((cell, cIdx) => (
										<Table.Cell
											key={`cell_${cIdx}`}
											style={cIdx === 0 ? { paddingLeft: '3.2rem' } : {}}
										>
											{cell.render('Cell')}
										</Table.Cell>
									))}
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</>
	);
}

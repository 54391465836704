import { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { nanoid as generate } from 'nanoid';
import produce from 'immer';
import { Form, FormElement } from 'store/data/forms';
import { GroupData } from 'store/data/variables';
import { DragAndDropTypes, ElementType, OrientationType, SetState } from 'types/index';
import {
	computeFormElementFromVariable,
	isFormElementHalfWidth,
	computeFormSetFromVariableSetData,
	computeFormGroupFromGroupData
} from 'helpers/forms';
import { debuggerLog } from 'helpers/generic';
import { getGroupVariables } from 'helpers/variables';
import { useVariables } from '../variables/useVariables';
import { useAddFormElement } from './useAddFormElement';
import { useAddFormGroup } from './useAddFormGroup';
import { useAddFormSet } from './useAddFormSet';
import { useFormElementOrientation } from './useFormElementOrientation';
import { useGroupFormElements } from './useGroupFormElements';
import { useOrderFormElement } from './useOrderFormElement';
import { VariableType } from 'types/data/variables/constants';

interface InitialFormElementsState {
	initialFormElements: FormElement[];
	setInitialFormElements: SetState<FormElement[]>;
}

interface Props {
	form?: Form;
	initialFormElementsState: InitialFormElementsState;
}

export function useFormDesignerDragAndDrop({ form, initialFormElementsState }: Props) {
	const [
		{
			data: { variablesMap, groupsMap, variableSetsMap }
		}
	] = useVariables({ lazy: true, initial: true });

	const addFormElement = useAddFormElement();
	const addFormGroup = useAddFormGroup();
	const addFormSet = useAddFormSet();
	const orderFormElements = useOrderFormElement();
	const groupFormElements = useGroupFormElements();
	const setFormElementOrientation = useFormElementOrientation();

	const { initialFormElements, setInitialFormElements } = initialFormElementsState;

	// SET TO `true` TO SEE THE LOGS
	const DEBUGGER = false;
	const log = debuggerLog(DEBUGGER);

	function getElementType(variableRef: string) {
		const variable = variablesMap[variableRef];

		const hasMultipleValues = variable.type === VariableType.CategoryMultiple;

		return hasMultipleValues ? ElementType.Checkboxes : ElementType.Radiobuttons;
	}

	const onDragEnd = useCallback(
		({ source, destination, draggableId, combine }: DropResult) => {
			if (!form) return;

			// TODO: NEEDS REFACTOR - START
			// Combining variables half width elements into elemnts row
			if (
				combine &&
				!combine?.draggableId.includes('draggableRow') &&
				!draggableId.includes('draggableRow') &&
				(draggableId.includes('var') || variablesMap[draggableId])
			) {
				log({ source, destination, draggableId, combine });

				const combineInsideGroup = source.droppableId.startsWith(
					DragAndDropTypes.DraggableFormGroup
				);

				let combinedElement: FormElement | undefined;
				let combinableDraggable: FormElement | undefined;

				const combinedFromInitialGroup = groupsMap[source.droppableId];
				const insideGroup = Object.values(form.groups).find(
					group => group.groupId === source.droppableId
				);

				if (combineInsideGroup) {
					if (combinedFromInitialGroup) {
						const variable = variablesMap[draggableId];

						const element = variable
							? computeFormElementFromVariable(variable)
							: undefined;

						combinedElement = element;
						combinableDraggable = form.elements[combine.draggableId];
					} else {
						if (!insideGroup) return;
						combinedElement = form.elements[combine.draggableId];
						combinableDraggable = form.elements[draggableId];
					}
				} else {
					combinedElement = form.elements[combine.draggableId];
					combinableDraggable = form.elements[draggableId];
				}

				const variable = variablesMap[draggableId];

				const newElementToInsert = variable
					? computeFormElementFromVariable(variable)
					: undefined;

				let sourceIndex = source.index;
				let destinationIndex = 0;

				if (combineInsideGroup) {
					if (combinedFromInitialGroup) {
						destinationIndex = form.elementsOrder.findIndex(
							element => element === combine.draggableId
						);
					} else {
						destinationIndex = form.groups[source.droppableId].elementsOrder.findIndex(
							element => element === combine.draggableId
						);
					}
				} else {
					destinationIndex = form.elementsOrder.findIndex(
						element => element === combine.draggableId
					);
				}

				log(
					'👾DEBUG MODE DRAG N DROP COMBINE👾:\n\n',
					`DRAGGABLE_ID: '${draggableId}'\n`,
					`ZONE: '${source.droppableId}' => '${combine.droppableId}'\n`,
					'INDEX:',
					source.index,
					'=>',
					destinationIndex
				);

				if (combinedElement) {
					const { elementId, variableRef, elementType } = combinedElement;

					if (!variableRef) return;

					const isHalfWidth = isFormElementHalfWidth(combinedElement);

					if (!isHalfWidth && elementType !== ElementType.Slider) {
						const newElementType = getElementType(variableRef);
						setFormElementOrientation({
							elementId,
							elementType: newElementType,
							orientation: OrientationType.Vertical
						});
					}
				}

				const element = newElementToInsert ?? combinableDraggable;
				if (!combinedElement || !element) return;

				if (element) {
					const { variableRef } = element;

					if (!variableRef) return;

					const isHalfWidth = isFormElementHalfWidth(element);

					// For variables list directly to nested row
					// Transform both elements to half width

					const newElementType = getElementType(variableRef);

					if (newElementToInsert || combinedFromInitialGroup) {
						addFormElement({
							destinationIndex: destinationIndex + 1,
							element: newElementToInsert ?? combinedElement
						});
						if (!isHalfWidth) {
							setFormElementOrientation({
								elementId: draggableId,
								elementType: newElementType,
								orientation: OrientationType.Vertical
							});
						}
						// Source irrelevant for inserting new element to group-row
						sourceIndex = -1;
					}

					if (!isHalfWidth && combinableDraggable.elementType !== ElementType.Slider) {
						setFormElementOrientation({
							elementId: draggableId,
							elementType: newElementType,
							orientation: OrientationType.Vertical
						});
					}

					groupFormElements({
						sourceId: element.elementId,
						sourceIndex,
						destinationId: combine.draggableId,
						destinationIndex,
						groupName: source.droppableId
					});
				}
			}
			// TODO: NEEDS REFACTOR - END

			if (!destination) return;

			log(
				'👾DEBUG MODE DRAG N DROP👾:\n\n',
				`DRAGGABLE_ID: '${draggableId}'\n`,
				`ZONE: '${source.droppableId}' => '${destination.droppableId}'\n`,
				'INDEX:',
				source.index,
				'=>',
				destination.index
			);

			const hasNotMovedIndex =
				source.droppableId === destination.droppableId &&
				source.index === destination.index;

			if (hasNotMovedIndex) return;

			const fromFormElementsList =
				source.droppableId === DragAndDropTypes.DroppableFormElement;
			const fromFormVariablesList =
				source.droppableId === DragAndDropTypes.DroppableFormVariable;
			const fromDesigner = source.droppableId === DragAndDropTypes.FormDroppableZone;
			const toDesigner = destination.droppableId === DragAndDropTypes.FormDroppableZone;
			const fromRow =
				source.droppableId.startsWith(DragAndDropTypes.FormDroppableZoneRow) &&
				!source.droppableId.includes(DragAndDropTypes.DraggableFormGroup);
			const toRow = destination.droppableId.startsWith(DragAndDropTypes.FormDroppableZoneRow);
			const toSameRow = fromRow && toRow;
			const fromGroupedVariables = source.droppableId.startsWith(
				DragAndDropTypes.DraggableFormGroup
			);
			const draggingGroup = draggableId.startsWith(DragAndDropTypes.DraggableFormGroup);
			const draggingSet = draggableId.startsWith(DragAndDropTypes.DraggableFormSet);
			const fromGroup = source.droppableId.startsWith(DragAndDropTypes.DraggableFormGroup);
			const toGroup = destination.droppableId.startsWith(DragAndDropTypes.DraggableFormGroup);
			const insideSameGroup =
				fromGroup && toGroup && source.droppableId === destination.droppableId;
			const insideGroupedRow =
				source.droppableId === destination.droppableId &&
				source.droppableId.includes(DragAndDropTypes.FormDroppableZoneRow) &&
				source.droppableId.includes(DragAndDropTypes.DraggableFormGroup);

			// INSIDE NESTED ROW
			if (toSameRow) {
				log('toSameRow');

				const rowIndex = Number(source.droppableId.split('-')[1]);

				orderFormElements({
					sourceIndex: source.index,
					destinationIndex: destination.index,
					options: { rowIndex }
				});
			}

			// GROUP ID DROPZONE
			if (insideSameGroup) {
				log('insideSameGroup');

				orderFormElements({
					sourceIndex: source.index,
					destinationIndex: destination.index,
					options: { groupId: source.droppableId }
				});
			}

			if (insideGroupedRow) {
				log('insideGroupedRow');

				const rowIndexAndGroupId = source.droppableId.split(
					DragAndDropTypes.FormDroppableZoneRow + '-'
				)[1];

				const rowIndex = Number(
					rowIndexAndGroupId.split(`-${DragAndDropTypes.DraggableFormGroup}`)[0]
				);
				const groupId =
					DragAndDropTypes.DraggableFormGroup +
					rowIndexAndGroupId.split(`-${DragAndDropTypes.DraggableFormGroup}`)[1];

				orderFormElements({
					sourceIndex: source.index,
					destinationIndex: destination.index,
					options: { rowIndex, groupId }
				});
			}

			// DESIGNER DROPZONE
			if (toDesigner) {
				log('toDesigner');

				// INSERT ELEMENT (VARIABLE)
				if (fromFormVariablesList) {
					log('fromFormVariablesList');

					// SET
					if (draggingSet) {
						log('draggingSet');

						const setName = draggableId.split(DragAndDropTypes.DraggableFormSet)[1];

						const variableSet = variableSetsMap[setName];

						const formSet = computeFormSetFromVariableSetData(variableSet);

						addFormSet({
							destinationIndex: destination.index,
							formSet
						});

						return;
					}

					// GROUP
					if (draggingGroup) {
						log('draggingGroup');

						const groupName = draggableId.split(DragAndDropTypes.DraggableFormGroup)[1];

						const group = groupsMap[groupName];
						const groupVariables = getGroupVariables(group, {
							variablesMap
						});

						const groupData: GroupData = {
							groupName: group.groupName,
							groupLabel: group.groupLabel,
							groupVariables
						};

						const { formGroup, groupElements } =
							computeFormGroupFromGroupData(groupData);

						addFormGroup({
							destinationIndex: destination.index,
							formGroup,
							groupElements
						});

						return;
					}

					const variableName = draggableId;

					const variable = variablesMap[variableName];

					const element = computeFormElementFromVariable(variable);

					addFormElement({
						destinationIndex: destination.index,
						element
					});
				}

				if (fromGroupedVariables) {
					log('fromGroupedVariables');

					const variableName = draggableId;

					const variable = variablesMap[variableName];

					const element = computeFormElementFromVariable(variable);

					addFormElement({
						destinationIndex: destination.index,
						element
					});
				}

				// INSERT ELEMENT
				if (fromFormElementsList) {
					log('fromFormElementsList');

					const element = initialFormElements.find(
						element => element.elementId === draggableId
					);

					if (element) {
						addFormElement({
							destinationIndex: destination.index,
							element
						});

						// GENERATE NEW ELEMENT ID - FIXES PRJCTS-3166
						const newInitialFormElements = produce(initialFormElements, draft => {
							draft.forEach(element => {
								if (element.elementId === draggableId) {
									element.elementId = generate();
								}
							});
						});

						setInitialFormElements(newInitialFormElements);
					}
				}

				// MOVE ELEMENT FROM MAIN LIST
				if (fromDesigner) {
					log('fromDesigner');

					orderFormElements({
						sourceIndex: source.index,
						destinationIndex: destination.index
					});
				}
			}
		},
		[form, initialFormElements, variablesMap, groupsMap]
	);

	return { onDragEnd };
}

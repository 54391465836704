import { useDependenciesContext, useTranslation } from 'hooks/store';
import { useOutsideClick } from 'hooks/utils';
import { useRef, useState } from 'react';
import { Row } from 'react-table';
import {
	Conditions,
	Dependency,
	DependencyOperators,
	OptionType,
	Rules,
	SubRows,
	TableDependency
} from 'store/data/dependencies';
import { SelectItem } from 'types/index';

import { loseFocus, showOnTouchDevices } from 'helpers/generic';
import { AddNewConditionModal } from '../DependenciesTableModals/AddNewConditionModal/AddNewConditionModal';
import { EditRuleOrConditionModal } from '../DependenciesTableModals/EditRuleOrConditionModal/EditRuleOrConditionModal';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { MoreIconContainer } from './DependenciesOptions.style';
import { DeleteRuleOrConditionModal } from '../DependenciesTableModals/DeleteRuleOrConditionModal';

interface OptionsProps {
	dependenciesOptions: { type: string; options: string[] };
	row: Row<TableDependency>;
	dependencySuppliersSelectItems: { label: string; value: string }[];
	getDependantVariablesSelectItems: (
		dependency: Dependency | TableDependency | SubRows
	) => SelectItem[];
	context: string | null;
	initialDependencies: { active: boolean; dependencies: Dependency[] };
	dependencies: Dependency[];
	collapseAllRules: () => void;
	parseOperatorCell: (value: string) => { label: string; value: DependencyOperators };
}

export function DependenciesOptions({
	dependenciesOptions,
	row,
	dependencySuppliersSelectItems,
	getDependantVariablesSelectItems,
	initialDependencies,
	context,
	dependencies,
	collapseAllRules,
	parseOperatorCell
}: OptionsProps) {
	const { translate } = useTranslation();
	const elementValueRef = useRef<HTMLAnchorElement>(null);
	const [menuVisible, setMenuVisible] = useState(false);
	const [openedOptionModal, setOpenedOptionModal] = useState({ type: '', option: '' });

	const {
		actions: { createDependant }
	} = useDependenciesContext();

	useOutsideClick(() => {
		setMenuVisible(false);
	}, [elementValueRef]);

	function onClose() {
		setOpenedOptionModal({ type: '', option: '' });
	}

	function stopEvent(e: React.MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
	}

	const handleOnSelectedOption = (option: string) => {
		setOpenedOptionModal({
			type: dependenciesOptions.type,
			option: option
		});

		if (
			option ===
			translate(dict => dict.dependencies.builder.tableView.rule[Rules.newCondition])
		) {
			createDependant({
				dependencyName: row.original.dependencyName
			});
		}
	};

	const shouldOpenEditConditionModal =
		(openedOptionModal.type === OptionType.condition &&
			openedOptionModal.option ===
				translate(
					dict => dict.dependencies.builder.tableView.condition[Conditions.editCondition]
				)) ||
		(openedOptionModal.type === OptionType.rule &&
			openedOptionModal.option ===
				translate(dict => dict.dependencies.builder.tableView.rule[Rules.editRule]));

	const shouldOpenNewConditionModal =
		openedOptionModal.type === OptionType.rule &&
		openedOptionModal.option ===
			translate(dict => dict.dependencies.builder.tableView.rule[Rules.newCondition]);

	const shouldOpenDeleteRuleOrConditionModal =
		(openedOptionModal.type === OptionType.condition &&
			openedOptionModal.option ===
				translate(
					dict =>
						dict.dependencies.builder.tableView.condition[Conditions.deleteCondition]
				)) ||
		(openedOptionModal.type === OptionType.rule &&
			openedOptionModal.option ===
				translate(dict => dict.dependencies.builder.tableView.rule[Rules.deleteRule]));

	return (
		<>
			<MoreIconContainer visible={true} className={showOnTouchDevices('card-more-icon')}>
				<Dropdown
					toggleComponent={({ ref, toggle }) => (
						<Icon
							ref={ref}
							variant={v => v.button}
							svg={Svgs.More}
							onClick={e => {
								stopEvent(e);
								loseFocus();
								toggle();
							}}
							active={menuVisible}
							size={s => s.m}
						/>
					)}
					width={10}
					offset={{ right: 2 }}
				>
					{dependenciesOptions.options.map((option, index) => (
						<Dropdown.Item
							key={index}
							title={option}
							onClick={() => handleOnSelectedOption(option)}
						/>
					))}
				</Dropdown>
			</MoreIconContainer>

			{/* ADD NEW  CONDITION MODAL */}

			{shouldOpenNewConditionModal && row.original.subRows && (
				<AddNewConditionModal
					collapseAllRules={collapseAllRules}
					title={translate(dict => dict.dependencies.builder.tableView.rule.newCondition)}
					context={context}
					initialDependencies={initialDependencies}
					onClose={onClose}
					subRows={row.original.subRows}
					rule={row.original}
					dependencySuppliersSelectItems={dependencySuppliersSelectItems}
					dependantVariablesSelectItems={getDependantVariablesSelectItems(row.original)}
					getDependantVariablesSelectItems={getDependantVariablesSelectItems}
					parseOperatorCell={parseOperatorCell}
				/>
			)}
			{/* EDIT RULE OR CONDITION MODAL*/}
			{shouldOpenEditConditionModal && (
				<EditRuleOrConditionModal
					collapseAllRules={collapseAllRules}
					onClose={onClose}
					isCondition={row.depth > 0}
					rule={row.original}
					dependencySuppliersSelectItems={dependencySuppliersSelectItems}
					dependantVariablesSelectItems={getDependantVariablesSelectItems(row.original)}
					getDependantVariablesSelectItems={getDependantVariablesSelectItems}
					context={context}
					initialDependencies={initialDependencies}
					parseOperatorCell={parseOperatorCell}
				/>
			)}

			{/* DELETE RULE OR CONDITION MODAL */}
			{shouldOpenDeleteRuleOrConditionModal && (
				<DeleteRuleOrConditionModal
					collapseAllRules={collapseAllRules}
					dependency={row.original}
					onClose={onClose}
					selectedOption={openedOptionModal}
					dependencies={dependencies}
					initialDependencies={initialDependencies}
					context={context}
				/>
			)}
		</>
	);
}

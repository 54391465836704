import { StorageKeys } from 'types/index';
import { State } from './types';

const initialState: State = localStorage.getItem(StorageKeys.JADBioIdToken)
	? {
			tokenValidMock: !localStorage.getItem(StorageKeys.JADBioIdToken) ? true : false,
			projects: { byProjectId: {} }
	  }
	: null;

export default initialState;

import { useCallback, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { useDrop } from 'react-dnd';
import { CreateVariableGroupModal, CreateVariableGroupOrSetModal } from 'components/Variables';
import {
	DraggableVariableCardType,
	MergeVariablesIntoGroupInput,
	VariablesDataArray
} from 'store/data/variables';
import { CommonCard as Card } from './Cards/CommonCard/CommonCard';
import { CardTypesE, VariableCardPayload } from './Cards/CommonCard/types';
import { VariableSetConfirmDragAndDropActionModal } from './ConfirmDragAndDropActionModal';
import { VariableGroupDrawer } from './VariableGroupDrawer';
import { VariableSetDrawer } from './VariableSetDrawer';
import { Container, EmptyList, Wrapper } from './VariablesGrid.style';
import { translateVariableTypeMap } from 'helpers/generic';
import {
	isGroupOrderItem,
	variablesDataArrayJSXIterator,
	isVariablePromGenerated,
	getVariableSetDataVariableNames
} from 'helpers/variables';
import {
	useTranslation,
	useRemoveVariableFromGroup,
	useRemoveVariableFromSet,
	useRemoveVariableGroupFromSet,
	useIsMoveInProgress,
	useVariablesFilters,
	useDraggingVariable,
	useVariables
} from 'hooks/store';
import { useModalState } from 'hooks/ui';
import { useDeepCompareCallback, useDeepCompareMemo } from 'hooks/utils';
import { DragVariableContext } from './DragVariableContext';
import { VariableSet, Group } from 'api/data/variables';
import { withMemo } from 'helpers/HOCs';

interface CollectedProps {
	isOverCurrent: boolean;
}
interface Props {
	variablesDataArray: VariablesDataArray;
	isVariableModalOpened: boolean;
	readOnly: boolean;
	hasErrors?: string[];
	selectedVariableSet: VariableSet | null;
	selectedGroup: Group | null;
	onGroupDelete: (groupName: string) => void;
	onVariableSetDelete: (setName: string) => void;
	onVariableClick: (variableName: string, setName?: string) => void;
	onCreateVariable: () => void;
	onCreateGroup: () => void;
	openVariableSetDrawer: (setName: string) => void;
	closeVariableSetDrawer: () => void;
	openGroupDrawer: (groupName: string) => void;
	closeGroupDrawer: () => void;
}

export function VariablesGrid({
	variablesDataArray,
	isVariableModalOpened,
	readOnly,
	hasErrors,
	selectedGroup,
	selectedVariableSet,
	openGroupDrawer,
	closeGroupDrawer,
	openVariableSetDrawer,
	closeVariableSetDrawer,
	onVariableClick,
	onCreateVariable,
	onCreateGroup,
	onGroupDelete,
	onVariableSetDelete
}: Props) {
	const { translate } = useTranslation();

	const [, removeVariableFromGroup] = useRemoveVariableFromGroup();
	const [, removeVariableFromSet] = useRemoveVariableFromSet();
	const [, removeVariableGroupFromSet] = useRemoveVariableGroupFromSet();
	const moving = useIsMoveInProgress();

	const [
		{
			data: { variableSetsMap }
		}
	] = useVariables({ lazy: true });

	const [{ areFiltersActive }] = useVariablesFilters();

	// TODO: implement creating group out of 2 OR n variables (cater for variables list case as well)
	const [variablesToBeMerged, setVariablesToBeMerged] =
		useState<MergeVariablesIntoGroupInput | null>(null);

	// TODO: REMOVE FROM HERE WHEN `mergeVariablesIntoGroup` IS MOVED OUTSIDE THIS COMPONENT
	const createGroupModal = useModalState();
	const createGroupOrSetModal = useModalState();

	const [showEmptyListHover, setShowEmptyListHover] = useState(false);

	const [confirmAction, setConfirmAction] = useState<VariableCardPayload | null>(null);
	const confirmActionModal = {
		open: (item: VariableCardPayload) => setConfirmAction(item),
		close: () => setConfirmAction(null)
	};

	// Adding this to avoid a weird lag changing the drop preview
	const [{ isOverCurrent }, drop] = useDrop<any, any, CollectedProps>({
		accept: [DraggableVariableCardType.Variable, DraggableVariableCardType.Group],
		collect: monitor => ({
			isOverCurrent: monitor.isOver({ shallow: true })
		}),
		// Remove from group and add to the end of the list
		drop(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			if (item.parentSet) return confirmActionModal.open(item);

			handleDrop(item);
		},
		hover(item: VariableCardPayload, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			// ALLOW ONLY CARDS WITHIN A DRAWER (VARIABLE GROUP/SET DRAWER)
			if (!(item.parentGroup || item.parentSet)) return;

			if (!showEmptyListHover) setShowEmptyListHover(true);
		}
	});

	useEffect(() => {
		if (!isOverCurrent) {
			showEmptyListHover && setShowEmptyListHover(false);
		}
	}, [isOverCurrent]);

	const mergeVariablesMemo = useCallback(
		(input: MergeVariablesIntoGroupInput, options?: { orSeries: boolean }) => {
			setVariablesToBeMerged(input);
			options?.orSeries ? createGroupOrSetModal.open() : createGroupModal.open();
		},
		[]
	);

	function handleDrop(item: VariableCardPayload) {
		// ALLOW ONLY CARDS WITHIN A DRAWER (VARIABLE GROUP/SET DRAWER)
		if (!(item.parentGroup || item.parentSet)) return;

		// DROP CARD FROM VARIABLE SET DRAWER
		if (item.parentSet) {
			// VARIABLE CARD FROM GROUP DRAWER
			if (item.parentGroup) {
				return removeVariableFromSet({
					variableName: item.name,
					setName: item.parentSet,
					parentGroup: item.parentGroup
				});
			}

			// GROUP CARD
			if (item.isGroup) {
				return removeVariableGroupFromSet({
					groupName: item.name,
					setName: item.parentSet
				});
			}

			// VARIABLE CARD
			return removeVariableFromSet({
				variableName: item.name,
				setName: item.parentSet
			});
		}

		// DROP VARIABLE CARD FROM VARIABLE GROUP DRAWER
		if (item.parentGroup) {
			return removeVariableFromGroup({
				variableName: item.name,
				groupName: item.parentGroup
			});
		}
	}

	/**
	 * Closes variable set drawer (if opened) and opens group drawer (or closes if already opened)
	 */
	const handleGroupCardClick = useDeepCompareCallback(
		(groupName: string) => {
			if (selectedVariableSet) closeVariableSetDrawer();

			const clickedSameCard = selectedGroup?.groupName === groupName;
			// DRAWER IS OPENED - ACT LIKE A TOGGLE
			if (clickedSameCard) return closeGroupDrawer();

			openGroupDrawer(groupName);
		},
		[selectedVariableSet, selectedGroup]
	);

	/**
	 * Closes group drawer (if opened) and opens variable set drawer (or closes if already opened)
	 */
	const handleVariableSetCardClick = useDeepCompareCallback(
		(setName: string) => {
			const clickedSameCard = selectedVariableSet?.setName === setName;

			const isSelectedGroupChild = selectedVariableSet?.setOrder.find(
				item => isGroupOrderItem(item) && item.group === selectedGroup?.groupName
			);

			// CLOSE CHILD GROUP DRAWER - KEEP VARIABLE SET DRAWER OPEN
			if (clickedSameCard && isSelectedGroupChild) return closeGroupDrawer();

			if (selectedGroup) closeGroupDrawer();

			// DRAWER IS OPENED - ACT LIKE A TOGGLE
			if (clickedSameCard) return closeVariableSetDrawer();

			openVariableSetDrawer(setName);
		},
		[selectedVariableSet, selectedGroup, variableSetsMap]
	);

	// VARIABLES GRID CONTEXT LOGIC
	const [draggedVariables, setDraggingVariable] = useDraggingVariable();

	const contextValue = useDeepCompareMemo(() => {
		return { draggedVariables, setDraggingVariable };
	}, [draggedVariables]);

	const listLength = variablesDataArray.length;

	const translatedVariableTypeMap = translateVariableTypeMap(translate);

	const isDrawerOpen = !!(selectedGroup || selectedVariableSet);

	const preventDrag = areFiltersActive || readOnly || moving;

	const mergeVariablesMemoWithSeries = useCallback((input: MergeVariablesIntoGroupInput) => {
		return mergeVariablesMemo(input, { orSeries: true });
	}, []);

	const onVariableClickMemo = useCallback((variableName, setName?: string | undefined) => {
		onVariableClick(variableName, setName);
	}, []);

	return (
		<>
			{selectedGroup && (
				<VariableGroupDrawer
					group={selectedGroup}
					parentSet={selectedVariableSet?.setName}
					isVariableModalOpened={isVariableModalOpened}
					readOnly={readOnly}
					preventDrag={preventDrag}
					hasErrors={hasErrors}
					onCreateVariable={onCreateVariable}
					onVariableClick={onVariableClickMemo}
					onGroupDelete={onGroupDelete}
					onClose={closeGroupDrawer}
				/>
			)}

			{selectedVariableSet && (
				<VariableSetDrawer
					variableSet={selectedVariableSet}
					isGroupDrawerOpened={!!selectedGroup}
					isVariableModalOpened={isVariableModalOpened}
					readOnly={readOnly}
					preventDrag={preventDrag}
					onCreateVariable={onCreateVariable}
					onCreateGroup={onCreateGroup}
					mergeVariables={mergeVariablesMemo}
					onVariableClick={onVariableClickMemo}
					onGroupClick={openGroupDrawer}
					onVariableSetDelete={onVariableSetDelete}
					onClose={closeVariableSetDrawer}
				/>
			)}
			<DragVariableContext.Provider value={contextValue}>
				<Wrapper isDrawerOpen={isDrawerOpen}>
					<Container>
						{variablesDataArrayJSXIterator(
							variablesDataArray,
							/**
							 * VARIABLE
							 */
							(variable, index) => {
								const { name, label, obligatory, uniquenessType, specialization } =
									variable;
								const translatedVariableType =
									translatedVariableTypeMap[variable.type];
								const systemGenerated = isVariablePromGenerated({ specialization });
								const subtitle = `${translatedVariableType} ${
									uniquenessType ?? ''
								}`.trim();

								return (
									<CommonCard
										key={`variable-${variable.name}`}
										title={label}
										name={name}
										index={index}
										listLength={listLength}
										readOnly={readOnly}
										preventDrag={preventDrag}
										systemGenerated={systemGenerated}
										isListHovered={showEmptyListHover}
										onClick={onVariableClickMemo}
										mergeVariables={mergeVariablesMemoWithSeries}
										cardType={{
											type: CardTypesE.Variable,
											payload: {
												name,
												subtitle,
												required: obligatory
											}
										}}
										hasError={hasErrors?.includes(name)}
									/>
								);
							},
							/**
							 * GROUP DATA
							 */
							(groupData, index) => {
								const { groupName, groupLabel, groupVariables } = groupData;

								const selected = selectedGroup?.groupName === groupName;

								return (
									<CommonCard
										key={`group-${groupName}`}
										title={groupLabel}
										name={groupName}
										index={index}
										listLength={listLength}
										readOnly={readOnly}
										preventDrag={preventDrag}
										isListHovered={showEmptyListHover}
										onClick={handleGroupCardClick}
										mergeVariables={() => undefined}
										cardType={{
											type: CardTypesE.Group,
											payload: {
												name: groupName,
												selected: selected,
												numberOfVariables: groupVariables.length
											}
										}}
										hasError={hasErrors?.some(variableName =>
											groupVariables.some(v => v.name === variableName)
										)}
									/>
								);
							},
							/**
							 * VARIABLE SET DATA
							 */
							(variableSetData, index) => {
								const { setName, setLabel } = variableSetData;

								const selected = selectedVariableSet?.setName === setName;

								const setVariableNames =
									getVariableSetDataVariableNames(variableSetData);
								const numberOfVariables = setVariableNames.length;

								return (
									<CommonCard
										key={`variable_set-${variableSetData.setName}`}
										title={setLabel}
										name={setName}
										index={index}
										listLength={listLength}
										readOnly={readOnly}
										preventDrag={preventDrag}
										isListHovered={showEmptyListHover}
										onClick={handleVariableSetCardClick}
										mergeVariables={() => undefined}
										cardType={{
											type: CardTypesE.Set,
											payload: {
												name: setName,
												selected: selected,
												numberOfVariables
											}
										}}
										hasError={hasErrors?.includes(setName)}
									/>
								);
							}
						)}
					</Container>

					{/* EMPTY LIST DROPZONE */}
					<EmptyList ref={drop} />
				</Wrapper>
			</DragVariableContext.Provider>

			{createGroupModal.visible && (
				<CreateVariableGroupModal
					variablesToBeMerged={variablesToBeMerged}
					onClose={createGroupModal.close}
				/>
			)}

			{createGroupOrSetModal.visible && (
				<CreateVariableGroupOrSetModal
					variablesToBeMerged={variablesToBeMerged}
					onClose={createGroupOrSetModal.close}
				/>
			)}

			{/* CONFIRM DRAG-N-DROP ACTION MODAL */}
			{confirmAction && (
				<VariableSetConfirmDragAndDropActionModal
					item={confirmAction}
					direction="from"
					onConfirm={() => handleDrop(confirmAction)}
					onClose={confirmActionModal.close}
				/>
			)}
		</>
	);
}

const CommonCard = withMemo(Card);

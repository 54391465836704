import { animated } from 'react-spring';
import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
`;

export const Container = styled.div`
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};
	margin: 8px 0 0;
	width: 100%;

	> div {
		margin-bottom: unset;
	}
`;

export const FadingWrapper = animated(Wrapper);

import { useEffect } from 'react';

import {
	LedidiPlanCurrency,
	SupportedLedidiPlansPrices,
	SupportedLedidiPlans,
	BillingInfo,
	BillingPeriodUnit,
	PaymentMethod
} from 'store/account/payments';

import { ChangePlanSteps } from '..';
import { getName } from 'i18n-iso-countries';
import { isInteger } from 'lodash';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useVATStatus, useSubscriptionPaymentMethods } from 'hooks/store';
import { ChangePlanLicenceModel } from 'hooks/store/account/subscription/useChangePlanLicenceQuantities';
import { SubscriptionNameLabel } from 'store/account/subscription';

interface Props {
	selectedPaymentMethod: PaymentMethod;
	selectedSubscriptionName: SupportedLedidiPlans;
	billingInfo: BillingInfo;
	currency: LedidiPlanCurrency;
	payMonthly: boolean;
	changePlanLicenceModels: ChangePlanLicenceModel[];
	setStep: (step: ChangePlanSteps) => void;
}

export function OrderSummaryBundle({
	selectedPaymentMethod,
	selectedSubscriptionName,
	billingInfo,
	payMonthly,
	currency,
	changePlanLicenceModels,
	setStep
}: Props) {
	const { translate } = useTranslation();

	const isNorwegianCurrency =
		currency === LedidiPlanCurrency.NOK || billingInfo?.currencyCode === LedidiPlanCurrency.NOK;

	const { priceId } =
		SupportedLedidiPlansPrices[
			isNorwegianCurrency ? LedidiPlanCurrency.NOK : LedidiPlanCurrency.EUR
		][selectedSubscriptionName][payMonthly ? BillingPeriodUnit.Month : BillingPeriodUnit.Year];

	const [
		{
			data: { isTaxable: isVATTaxable }
		},
		getVATStatus
	] = useVATStatus(priceId);

	const [{ data: paymentMethods }] = useSubscriptionPaymentMethods();

	useEffect(() => {
		getVATStatus();
	}, []);

	const {
		billingAddress: { company, firstName, lastName, line1, zip, city, state, country }
	} = billingInfo;

	let totalToPay = 0;
	changePlanLicenceModels.forEach(licenceData => {
		totalToPay = totalToPay + licenceData.count * licenceData.unitPrice;
	});

	// const parsedSumToPay = `${!isNorwegianCurrency ? `\u20AC` : ''}${totalToPay
	// 	.toString()
	// 	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00 ${isNorwegianCurrency ? 'NOK' : ''}`;

	const vAT = (totalToPay * 25) / 100;
	const totalWithVAT = isVATTaxable ? totalToPay + vAT : totalToPay;

	const formattedTotalSumToPay = `${!isNorwegianCurrency ? `\u20AC` : ''}${totalToPay
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00 ${
		isNorwegianCurrency && !!totalToPay ? 'NOK' : ''
	}`;

	const formattedVATSumToPay = `${!isNorwegianCurrency ? `\u20AC` : ''}${vAT
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${isInteger(vAT) ? '.00' : '0'} ${
		isNorwegianCurrency && !!totalToPay ? 'NOK' : ''
	}`;

	const formattedTotalSumWithVTAToPay = `${!isNorwegianCurrency ? `\u20AC` : ''}${totalWithVAT
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${isInteger(totalWithVAT) ? '.00' : '0'} ${
		isNorwegianCurrency && !!totalToPay ? 'NOK' : ''
	}`;

	const primaryCard = paymentMethods?.cards.find(card => card.isPrimary);

	const cardNumberMasked = `${primaryCard?.maskedNumber.substring(12, 16)}`;

	return (
		<>
			<Spacer size={s => s.m} />
			<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
				<Flex fullWidth wrap align={a => a.end}>
					<div style={{ width: '100%' }}>
						<>
							<Typography.Caption>
								{translate(dict => dict.accountUM.billing.billedTo)}:
							</Typography.Caption>
							<Spacer size={s => s.xs} />
							{company && (
								<Typography.Paragraph fontweight={f => f.bold}>
									{company}
								</Typography.Paragraph>
							)}
							<Typography.Paragraph fontweight={f => f.bold}>
								{`${firstName} ${lastName}`}
							</Typography.Paragraph>
							<Typography.Paragraph>{`${line1}`}</Typography.Paragraph>
							<Typography.Paragraph>
								{`${zip}, ${city}, ${state}, ${getName(country, 'en')}`}
							</Typography.Paragraph>
							<div>
								<Button
									title={translate(dict => dict.buttons.change)}
									variant={v => v.link}
									paddingOffset={{ all: 0 }}
									onClick={() => setStep(ChangePlanSteps.BillingInformation)}
								/>
							</div>
						</>
						<Spacer size={s => s.m} />
						<Typography.Caption>
							{translate(({ accountUM }) => accountUM.changePlan.paymentMethod)}:
						</Typography.Caption>
						<Spacer size={s => s.xs} />
						<Typography.Paragraph fontweight={f => f.bold}>
							{selectedPaymentMethod === PaymentMethod.CARD
								? translate(dict => dict.accountUM.billing.cardEndingIn, false, {
										cardType: primaryCard?.brand ?? '',
										maskedNumber: cardNumberMasked
								  })
								: translate(
										dict =>
											dict.accountUM.changePlan.selectPaymentMethod.invoice
								  )}
						</Typography.Paragraph>
						<div>
							<Button
								title={translate(dict => dict.buttons.change)}
								variant={v => v.link}
								paddingOffset={{ all: 0 }}
								onClick={() => setStep(ChangePlanSteps.SelectPayment)}
							/>
						</div>
						<Spacer size={s => s.xl} />
						<Typography.Caption>
							{translate(
								({ accountUM }) => accountUM.changePlan.selectedPlanBrakedown
							)}
							:
						</Typography.Caption>
						<Spacer size={s => s.xs} />

						<Flex justify={j => j.between} align={a => a.center}>
							<Flex align={a => a.center}>
								<Typography.Paragraph fontweight={f => f.bold}>
									{translate(
										() => SubscriptionNameLabel[selectedSubscriptionName]
									)}
								</Typography.Paragraph>
								<Button
									title={translate(dict => dict.buttons.change)}
									variant={v => v.link}
									paddingOffset={{ left: 1 }}
									onClick={() => setStep(ChangePlanSteps.PlanDetails)}
								/>
							</Flex>

							<Typography.Paragraph fontweight={f => f.bold}>
								{formattedTotalSumToPay}
							</Typography.Paragraph>
						</Flex>
						{changePlanLicenceModels.map(licence => {
							return licence.count ? (
								<Flex justify={j => j.between} key={licence.id}>
									<Flex>
										<Typography.Paragraph>
											{licence.count} x {licence.label}{' '}
										</Typography.Paragraph>
									</Flex>

									<Typography.Paragraph>
										{`${!isNorwegianCurrency ? `\u20AC` : ''}${(
											licence.unitPrice * licence.count
										)
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00 ${
											isNorwegianCurrency ? 'NOK' : ''
										}`}
									</Typography.Paragraph>
								</Flex>
							) : (
								<></>
							);
						})}

						{isVATTaxable && (
							<>
								<Spacer size={s => s.s} />
								<Flex justify={j => j.between} align={a => a.center}>
									<Typography.Paragraph>
										{translate(({ accountUM }) => accountUM.changePlan.vAT)}
									</Typography.Paragraph>
									<Typography.Paragraph fontweight={f => f.bold}>
										{formattedVATSumToPay}
									</Typography.Paragraph>
								</Flex>
							</>
						)}
						<Spacer size={s => s.s} />
						<Flex fullWidth justify={j => j.end} align={a => a.end}>
							<Typography.Paragraph paddingOffset={{ bottom: 0.25 }}>
								{translate(({ accountUM }) => accountUM.changePlan.totalAmountDue)}
							</Typography.Paragraph>
							<Typography.H5
								paddingOffset={{ left: 1 }}
								fontweight={f => f.bold}
							>{`${formattedTotalSumWithVTAToPay}`}</Typography.H5>
						</Flex>
					</div>
				</Flex>
			</Flex>
		</>
	);
}

import { useMemo, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { DATE_FORMAT_COMMA } from 'consts';
import { SubscriptionPayment, PaymentStatusLabel } from 'store/account/payments';
import {
	TableFilterType,
	TableName,
	CustomisableTableColumn,
	CustomCellType,
	CustomisableTableRow,
	GenericMap
} from 'types/index';

import { ActionCell } from './BillingHistoryList.style';
import { CustomisableTable } from 'components/UI/CustomisableTable';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { formatAPIDate } from 'helpers/dateFormat';
import { downloadFileFromUrl } from 'helpers/generic';
import { parseApiPriceIntoString } from 'helpers/subscription';
import { useTranslation, useAPIDownloadInvoice } from 'hooks/store';

interface BillingHistoryListProps {
	subscriptionPayments: SubscriptionPayment[];
}
export function BillingHistoryList({ subscriptionPayments }: BillingHistoryListProps) {
	const { translate } = useTranslation();

	function onSuccessInvoiceDownloaded(data: { downloadURL: string }) {
		downloadFileFromUrl(data.downloadURL, '');
	}

	const [_, downloadInvoice] = useAPIDownloadInvoice(onSuccessInvoiceDownloaded);

	function onDownloadInvoiceClicked(invoiceId: string) {
		downloadInvoice(invoiceId);
	}

	const columns: CustomisableTableColumn[] = useMemo(
		() => [
			{
				name: 'date',
				label: translate(({ accountUM }) => accountUM.billing.date),
				filterType: TableFilterType.Date
			},
			{
				name: 'plan',
				label: translate(({ accountUM }) => accountUM.billing.plan),
				filterType: TableFilterType.Checkbox
			},
			{
				name: 'orderNumber',
				label: translate(({ accountUM }) => accountUM.billing.orderNumber),
				isOptional: true,
				filterType: TableFilterType.Text
			},
			{
				name: 'amount',
				label: translate(({ accountUM }) => accountUM.billing.amount),
				isOptional: true,
				filterType: TableFilterType.Numeric
			},
			{
				name: 'status',
				label: translate(({ accountUM }) => accountUM.billing.status),
				isOptional: true,
				filterType: TableFilterType.Checkbox
			},
			{
				name: 'action',
				label: translate(({ accountUM }) => accountUM.billing.action),
				disableSort: true
			}
		],
		[]
	);

	const [customCells, setCustomCells] = useState<CustomCellType>({});

	const tableData = useMemo(() => {
		const data: CustomisableTableRow[] = [];
		subscriptionPayments.forEach(payment => {
			const row = {
				id: payment.id,
				date: payment.paidAt,
				plan: payment.pricingModel + ' X' + payment.quantity,
				orderNumber: payment.id,
				amount: payment.amountPaid / 100,
				status: translate(() => PaymentStatusLabel[payment.status]),
				action: ''
			};
			data.push(row);

			// custom cells: date, amount and action

			const customCell: GenericMap<string | JSX.Element> = {};
			customCell.action = (
				<ActionCell onClick={() => onDownloadInvoiceClicked(row.id)}>
					{translate(dict => dict.accountUM.billing.downloadInvoice)}
				</ActionCell>
			);
			customCell.date = formatAPIDate(payment.paidAt, DATE_FORMAT_COMMA).toString();

			customCell.amount =
				parseApiPriceIntoString(payment.amountPaid) +
				' ' +
				getSymbolFromCurrency(payment.currencyCode);

			setCustomCells(prev => ({ ...prev, [row.id]: customCell }));
		});
		return data;
	}, [subscriptionPayments]);

	return (
		<>
			<Typography.H5>
				{translate(dict => dict.accountUM.billing.billingHistory)}
			</Typography.H5>
			<Spacer size={s => s.s} />
			<CustomisableTable
				tableName={TableName.Billing}
				items={tableData}
				columns={columns}
				itemsCountLabel={translate(dict => dict.tableLists.payments)}
				customCells={customCells}
				hasPagination
			/>
		</>
	);
}

import styled, { css } from 'styled-components';

import { Colors, MediaQueries, Shadows } from 'environment';
import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';

interface HoverableAreasProps {
	isHovered: boolean;
	isDraggingInMain: boolean;
}

interface Constraints {
	width: number;
	height: number;
	resizing: boolean;
	fullWidth: boolean;
	resizingHeight: boolean;
	resizingWidth: boolean;
	constraints: {
		minWidthConstr: number;
		maxWidthConstr: number;
		minHeightConstr: number;
		maxHeightConstr: number;
	};
}

export const ResizeIcon = styled(DefaultIcon)<{ visible: boolean; vertical?: boolean }>`
	opacity: 0;
	cursor: e-resize;
	padding: 0;

	${({ vertical }) =>
		vertical &&
		css`
			cursor: n-resize;
		`};
	${({ visible }) =>
		visible &&
		css`
			opacity: 1;
		`};
`;

export const RowWrapper = styled.div<{ isDragging: boolean }>`
	display: flex;

	${({ isDragging }) =>
		isDragging &&
		css`
			margin-bottom: 1rem;
		`};

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column;
		width: 100%;
	}
`;

export const ComponentWrapper = styled.div<{
	isDragging: boolean;
	hoveringAnother?: boolean;
	noMenuOpen?: boolean;
}>`
	display: flex;
	position: relative;
	flex-direction: column;
	cursor: pointer;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	height: fit-content;
	z-index: 200;
	background: ${Colors.white};

	${({ isDragging }) =>
		!isDragging
			? css`
					:hover {
						z-index: 200;
						box-shadow: ${Shadows.hover};
						${ResizeIcon} {
							opacity: 1;
						}
						.card-more-icon {
							visibility: visible;
						}
					}
			  `
			: css`
					overflow: hidden;
					cursor: grabbing;
					background: ${Colors.background.disabled};
			  `};
	${({ hoveringAnother }) =>
		hoveringAnother &&
		css`
			pointer-events: none;
		`};
`;

export const LineMarker = styled.div<HoverableAreasProps>`
	width: 0.6rem;
	height: 100%;
	border-radius: 0.2rem;
	cursor: grabbing !important;
	opacity: 0;

	${({ isDraggingInMain }) =>
		isDraggingInMain &&
		css`
			background: #d0d0d0;
			z-index: 17;
			opacity: 0;
		`};
	${({ isHovered }) =>
		isHovered &&
		css`
			opacity: 1;
			cursor: grabbing !important;
			background: ${Colors.vibrantGreen};
		`};
`;

export const VerticalLineMarker = styled.div<HoverableAreasProps>`
	width: 100%;
	height: 0.6rem;
	border-radius: 0.2rem;
	cursor: grabbing !important;
	opacity: 0;
	background: ${Colors.vibrantGreen};

	${({ isDraggingInMain }) =>
		isDraggingInMain &&
		css`
			background: #d0d0d0;
			opacity: 0;
		`};
	${({ isHovered }) =>
		isHovered &&
		css`
			cursor: grabbing !important;
			background: ${Colors.vibrantGreen};
			opacity: 1;
		`};
`;

export const RelativeContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
`;

export const RowLineTop = styled.div<{ index: number; isHovered: boolean }>`
	position: absolute;
	left: 0;
	right: 0;
	top: -1.8rem;
	height: 0.8rem;
	display: flex;
	background: ${Colors.vibrantGreen};
	border-radius: 0.4rem;
	overflow: hidden;
	opacity: 0;
	z-index: 200;

	${({ isHovered }) =>
		isHovered &&
		css`
			cursor: grabbing !important;
			opacity: 1;
		`};

	${({ index }) =>
		index &&
		css`
			z-index: ${100 + index};
		`};
`;
export const RowLineBottom = styled.div<{
	isHovered: boolean;
	isMaxHeight?: boolean;
	isDragging?: boolean;
}>`
	position: absolute;
	left: 0;
	right: 0;
	bottom: -0.2rem;
	height: 0.8rem;
	display: flex;
	background: ${Colors.vibrantGreen};
	border-radius: 0.4rem;
	overflow: hidden;
	opacity: 0;
	z-index: 200;
	margin-bottom: 0.4rem;

	${({ isHovered }) =>
		isHovered &&
		css`
			cursor: grabbing !important;
			opacity: 1;
		`};

	${({ isMaxHeight, isDragging }) =>
		isMaxHeight &&
		css`
			bottom: ${!isDragging ? '0' : '-33.2rem'};
		`};
`;

export const DropToLeft = styled.div<{
	isResizing?: boolean;
	grouped?: boolean;
	splitLayout?: boolean;
}>`
	position: absolute;
	left: -1.3rem;
	top: 0;
	width: 4rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	z-index: 1;

	${({ grouped }) =>
		grouped &&
		css`
			left: 0;
			top: -2.3rem;
			right: 0;
			height: 3rem;
			width: 100%;
			z-index: 0;
		`};

	${({ splitLayout }) =>
		splitLayout &&
		css`
			left: -0.3rem;
		`};

	${({ isResizing }) =>
		isResizing &&
		css`
			position: sticky;
		`};
`;

export const DropToBottom = styled.div<{
	visible: boolean;
	manualMode?: boolean;
	rightPlaceholder?: boolean;
	centerPlaceholder?: boolean;
	width?: number;
}>`
	z-index: 15;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	display: none;
	height: 0;
	${({ visible }) =>
		visible &&
		css`
			display: flex;
		`};

	${({ manualMode, rightPlaceholder, centerPlaceholder, width }) =>
		!manualMode
			? css`
					/* width: 100%; */
			  `
			: css`
					position: absolute;
					top: 33.2rem;
					${rightPlaceholder &&
					css`
						right: 0;
					`}
					${centerPlaceholder &&
					css`
						right: ${width}px;
					`}
			  `};
`;

export const DropToRight = styled.div<{
	grouped?: boolean;
	splitLayout?: boolean;
	isResizing?: boolean;
}>`
	position: absolute;
	right: -1.3rem;
	top: 0;
	z-index: 17;
	width: 4rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${({ grouped }) =>
		grouped &&
		css`
			left: 0;
			top: 31rem;
			bottom: -2.3rem;
			right: 0;
			height: 3rem;
			width: 100%;
		`};
	${({ splitLayout }) =>
		splitLayout &&
		css`
			right: -0.3rem;
		`};
	${({ isResizing }) =>
		isResizing &&
		css`
			position: relative;
			z-index: 0;
		`};
`;

export const CardReplacement = styled.div`
	position: absolute;
	border-radius: 0.5rem;
	background: ${Colors.vibrantGreen};
	width: 0.6rem;
	/* height: 100%; */
	left: 0;
	top: 0rem;
	bottom: 0rem;
	z-index: 0;
`;

export const CardHeader = styled.div<{ menuVisible: boolean; width: number }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.6rem;
	z-index: 10;

	${({ menuVisible }) =>
		!menuVisible
			? css`
					.card-more-icon {
						visibility: hidden;
					}
			  `
			: css`
					z-index: 99999;
			  `};

	@media ${MediaQueries.minWidth.lg} {
		${({ width }) =>
			width &&
			css`
				max-width: ${width}px;
			`};
	}
`;

export const CardBody = styled.div`
	margin: 0 1.6rem 1.3rem;
`;

export const ResizableContainer = styled.div.attrs((props: Constraints) => {
	if (!props.resizing) {
		return {
			style: {
				...(!props.fullWidth ? { width: props.width } : { width: `auto` }),
				height: props.height
			}
		};
	} else {
		if (props.resizingHeight) {
			return {
				style: {
					width: `${props.width}px`,
					cursor: 'n-resize !important'
				}
			};
		}
		if (props.resizingWidth) {
			return {
				style: {
					height: `${props.height}px`,
					cursor: 'e-resize !important'
				}
			};
		}
	}
})<Constraints>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	overflow: auto;
	position: static;

	::-webkit-scrollbar-thumb {
		background: ${Colors.transparent}!important;
	}

	:hover {
		::-webkit-scrollbar-thumb {
			background: #748394 !important;
		}
	}

	${({ constraints: { minHeightConstr, minWidthConstr, maxHeightConstr, maxWidthConstr } }) =>
		css`
			@media ${MediaQueries.minWidth.lg} {
				min-width: ${minWidthConstr}rem!important;
				max-width: ${maxWidthConstr}rem!important;
				min-height: ${minHeightConstr}rem!important;
				max-height: ${maxHeightConstr}rem!important;
			}
		`}
`;

export const CardContent = styled.div<{ width: number }>`
	@media ${MediaQueries.minWidth.lg} {
		${({ width }) =>
			width &&
			css`
				max-width: ${width - 15}px;
				overflow: hidden;
			`};
	}
`;

export const HorizontalResizeContainer = styled.div<{
	supportsTouch: boolean;
}>`
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 16;
	cursor: e-resize !important;
	width: 1.6rem;

	:hover {
		border-radius: 0.4rem;
	}
`;

export const VerticalResizeContainer = styled.div<{ supportsTouch: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: 0rem;
	left: 0;
	right: 0;
	z-index: 16;
	cursor: n-resize !important;
	user-select: none;
	height: 2rem;

	:hover {
		border-radius: 0.4rem;
	}
`;

export const EmptyLayout = styled.div<{ visible: boolean }>`
	display: none;
	visibility: hidden;
	height: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.7;

	${({ visible }) =>
		visible &&
		css`
			width: 100%;
			height: 33rem;
			display: flex;
			visibility: visible;
		`};
`;

export const ResizeIconWrapper = styled.div<{ horizonal?: boolean }>`
	position: absolute;
	overflow: hidden;

	${({ horizonal }) =>
		horizonal
			? css`
					top: 0;
					right: 0;
					height: 100%;
					width: 1.8rem;
			  `
			: css`
					bottom: 0;
					width: 100%;
					height: 1.8rem;
			  `};
`;

export const DropCardPlaceholder = styled.div<{
	isHovered: boolean;
	smallCard: boolean;
	width?: number;
}>`
	width: 99%;
	border-radius: 0.4rem;
	margin-top: 32rem;
	height: 32rem;
	opacity: 0.4;
	background: ${Colors.background.disabled};
	border: 0.2rem dashed ${Colors.text.main};

	${({ isHovered }) =>
		isHovered &&
		css`
			background: ${Colors.vibrantGreen};
			opacity: 0.5;
		`};
	${({ smallCard }) =>
		smallCard
			? css`
					width: 97%;
			  `
			: css`
					margin-top: 64rem;
			  `};
	${({ width }) =>
		width &&
		css`
			width: ${width + 50}px;
		`};
`;

interface GridCardPosition {
	index: number;
	rowIndex: number;
	colIndex: number;
	minWidth: number;
	minHeight: number;
	dropzoneWrapper?: boolean;
	visible?: boolean;
	isDragging?: boolean;
	// lastRowOnGrid?: boolean;
}

export const PlaceholderContainer = styled.div.attrs((props: GridCardPosition) => {
	const { colIndex, minWidth, dropzoneWrapper, rowIndex, minHeight } = props;
	const left =
		colIndex === 0
			? `${dropzoneWrapper ? -8 : 0}px`
			: colIndex === 1
			? `${minWidth + (dropzoneWrapper ? 45 : 53)}px`
			: colIndex === 2
			? `${minWidth * colIndex + 50 + (dropzoneWrapper ? 45 : 53)}px`
			: colIndex === 3
			? `${minWidth * colIndex + 100 + (dropzoneWrapper ? 45 : 53)}px`
			: colIndex === 4
			? `${minWidth * colIndex + 150 + (dropzoneWrapper ? 45 : 53)}px`
			: colIndex === 5
			? `${minWidth * colIndex + 200 + (dropzoneWrapper ? 45 : 53)}px`
			: `${minWidth * colIndex + 250 + (dropzoneWrapper ? 45 : 53)}px`;
	const top =
		rowIndex === 0 && rowIndex
			? `33.2rem`
			: `${rowIndex !== 0 ? rowIndex * (minHeight + 70) : 0}px`;
	// const paddingBottom = lastRowOnGrid ? `6.4rem` : null;
	const paddingBottom = { paddingBottom: 0 };

	return {
		style: { top, left, ...(paddingBottom && { paddingBottom }) }
	};
})<GridCardPosition>`
	position: absolute;

	@media ${MediaQueries.maxWidth.lg} {
		position: static;
		width: 100%;
	}

	${({ dropzoneWrapper, isDragging, visible }) =>
		dropzoneWrapper &&
		css`
			${isDragging
				? css`
						opacity: 1;
						visibility: visible;
						display: block;
				  `
				: css`
						visibility: hidden;
						opacity: 0;
						display: none;
				  `}
			${visible &&
			css`
				visibility: hidden;
				opacity: 0;
			`};
		`}
`;

export const Dropzone = styled.div<{ hovered: boolean; width: number; height: number }>`
	background: ${({ hovered }) => (hovered ? Colors.vibrantGreen : Colors.primary.disabled)};
	opacity: 0.6;
	border: 0.2rem dashed ${Colors.text.main};
	margin: 0 1rem 1.6rem;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	opacity: 0;

	${({ hovered }) =>
		hovered &&
		css`
			opacity: 0.6;
			border-radius: 0.4rem;
			border: 0.2rem dashed ${Colors.text.main};
			z-index: 999999 !important;
		`};
`;

export const ChildrensContainer = styled.div`
	div[id$='license'] {
		display: none !important;
	}
`;

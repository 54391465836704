import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import produce from 'immer';

import {
	Columns,
	CompareNumericAnalysisV2,
	CompareNumericStatisticsV2,
	DataModel
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useMutableState, useDebounce, usePrevious } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import { Nullable, SelectItem } from 'types';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';
import { isAnalysisInputValid } from 'helpers/analysis';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: CompareNumericAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function CompareNumericConfig({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const validInput = isAnalysisInputValid(analysis);

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();
	const { input: analysisInput } = analysis;

	const [draftAnalysisInput, setDraftAnalysisInput] = useMutableState(analysisInput);
	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [activeColumn] = useAnalysesActiveColum();
	const {
		variables: inputVariables,
		statistics: inputStatistics,
		dataModel,
		series
	} = draftAnalysisInput;

	const [{ data: variablesData }] = useVariables();

	const { variableSets } = variablesData;

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});
	const { selectItemsMap: mainSelectItemsMap, selectItems: mainSelectItems } =
		variablesDataSelectItems;
	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const isTwoWayAnovaEnabled = !!inputVariables.categoryVariableTwo;
	const isTwoWayManovaEnabled =
		!!inputVariables.exploreVariableTwo && !!inputVariables.categoryVariableTwo;
	const isOneWayManovaEnabled = !!inputVariables.exploreVariableTwo;

	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}
		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [series, mainSelectItems, seriesSelectItems]);

	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	const selectItemsMap = { ...seriesSelectItemsMap, ...mainSelectItemsMap };

	useDebounce(
		() => {
			const hasChanges = !isEqual(draftAnalysisInput, analysisInput);

			if (!hasChanges) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input = draftAnalysisInput;
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[draftAnalysisInput, analysis],
		ANALYSIS_DEBOUNCE_TIME
	);

	// SYNC `draftAnalysisInput` STATE
	useEffect(() => {
		if (!isEqual(draftAnalysisInput, analysisInput)) setDraftAnalysisInput(analysisInput);
	}, [analysisInput]);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (prevShouldRefetchAnalyses === undefined) return;

		if (prevShouldRefetchAnalyses !== shouldRefetchAnalyses) {
			turnOffStatistics();
		}
	}, [shouldRefetchAnalyses]);

	useEffect(() => {
		if (!isTwoWayAnovaEnabled && inputStatistics.twoWayAnovaV2) {
			setDraftAnalysisInput(state => {
				state.statistics.twoWayAnovaV2 = false;
			});
		}
		if (!isTwoWayManovaEnabled && inputStatistics.twoWayManovaV2) {
			setDraftAnalysisInput(state => {
				state.statistics.twoWayManovaV2 = false;
			});
		}
	}, [isTwoWayAnovaEnabled, isTwoWayManovaEnabled]);

	function getResetStatistics(): CompareNumericStatisticsV2 {
		return {
			shapiroV2: false,
			mannWhitneyV2: false,
			independentV2: false,
			oneWayAnovaV2: false,
			tukeyV2: false,
			kruskalV2: false,
			twoWayAnovaV2: false,
			twoWayManovaV2: false,
			oneWayManovaV2: false
		};
	}

	function turnOffStatistics() {
		setDraftAnalysisInput(state => {
			state.statistics = getResetStatistics();
		});
	}

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.generic.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.generic.singleSeries),
			value: DataModel.series
		}
	];
	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	function onSelectDataModel(dataModel: Nullable<string>) {
		if (!dataModel) return;

		const newAnalysis: CompareNumericAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel,
				variables: {
					...analysis.input.variables,
					categoryVariable: null,
					categoryVariableTwo: null,
					exploreVariable: null,
					exploreVariableTwo: null
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: CompareNumericAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				variables: {
					...analysis.input.variables,
					categoryVariable: null,
					categoryVariableTwo: null,
					exploreVariable: null,
					exploreVariableTwo: null
				},
				series
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	const { variablesLocation } = buildVariablesDataLocation(variablesData);

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as CompareNumericAnalysisV2} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(({ analysis }) => analysis.generic.dataModel)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(({ analysis }) => analysis.generic.series)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.explore
						)}
						items={numericSelectItems}
						disabled={(dataModel === DataModel.series && !series) || !dataModel}
						value={
							{ ...selectItemsMap, ...seriesSelectItemsMap }[
								inputVariables.exploreVariable?.name ?? ''
							]
						}
						isItemDisabled={item =>
							[
								draftAnalysisInput.variables.exploreVariableTwo?.name,
								draftAnalysisInput.variables.categoryVariableTwo?.name,
								draftAnalysisInput.variables.categoryVariable?.name
							].includes(item.value)
						}
						onValueSelected={exploreVariable =>
							exploreVariable &&
							setDraftAnalysisInput(state => {
								state.variables.exploreVariable = {
									name: exploreVariable,
									...(variablesLocation[exploreVariable]?.setName && {
										series: variablesLocation[exploreVariable].setName
									})
								};
								state.statistics = getResetStatistics();
							})
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.category
						)}
						items={groupingSelectItems}
						isItemDisabled={item =>
							[
								draftAnalysisInput.variables.categoryVariableTwo?.name,
								draftAnalysisInput.variables.exploreVariable?.name,
								draftAnalysisInput.variables.exploreVariableTwo?.name
							].includes(item.value)
						}
						disabled={(dataModel === DataModel.series && !series) || !dataModel}
						value={selectItemsMap[inputVariables.categoryVariable?.name ?? '']}
						onValueSelected={categoryVariable => {
							setDraftAnalysisInput(state => {
								if (categoryVariable) {
									state.variables.categoryVariable = {
										name: categoryVariable,
										...(variablesLocation[categoryVariable]?.setName && {
											series: variablesLocation[categoryVariable].setName
										})
									};
								} else {
									delete state.variables.categoryVariable;
								}
								state.statistics = getResetStatistics();
							});
						}}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.categoryTwo
						)}
						placeholder={`-`}
						items={groupingSelectItems}
						disabled={
							(dataModel === DataModel.series && !series) ||
							!dataModel ||
							(!inputVariables.categoryVariable && !inputVariables.exploreVariable)
						}
						isItemDisabled={item =>
							[
								draftAnalysisInput.variables.categoryVariable?.name,
								draftAnalysisInput.variables.exploreVariable?.name,
								draftAnalysisInput.variables.exploreVariableTwo?.name
							].includes(item.value)
						}
						value={selectItemsMap[inputVariables.categoryVariableTwo?.name ?? '']}
						onValueSelected={categoryVariableTwo =>
							setDraftAnalysisInput(state => {
								if (categoryVariableTwo) {
									state.variables.categoryVariableTwo = {
										name: categoryVariableTwo,
										...(variablesLocation[categoryVariableTwo]?.setName && {
											series: variablesLocation[categoryVariableTwo].setName
										})
									};
								} else {
									delete state.variables.categoryVariableTwo;
								}

								state.statistics = getResetStatistics();
							})
						}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.compare.config.exploreTwo
						)}
						placeholder={`-`}
						items={numericSelectItems}
						disabled={
							(dataModel === DataModel.series && !series) ||
							!dataModel ||
							(!inputVariables.categoryVariable && !inputVariables.exploreVariable)
						}
						isItemDisabled={item =>
							[
								draftAnalysisInput.variables.categoryVariable?.name,
								draftAnalysisInput.variables.exploreVariable?.name,
								draftAnalysisInput.variables.categoryVariableTwo?.name
							].includes(item.value)
						}
						value={selectItemsMap[inputVariables.exploreVariableTwo?.name ?? '']}
						onValueSelected={exploreVariableTwo =>
							setDraftAnalysisInput(state => {
								if (exploreVariableTwo) {
									state.variables.exploreVariableTwo = {
										name: exploreVariableTwo,
										...(variablesLocation[exploreVariableTwo]?.setName && {
											series: variablesLocation[exploreVariableTwo].setName
										})
									};
								} else {
									delete state.variables.exploreVariableTwo;
								}

								state.statistics = getResetStatistics();
							})
						}
					/>

					{/* STATISTICS */}
					<Switch
						disabled={!validInput}
						label={translate(({ analysis }) => analysis.statistics.shapiro.name)}
						on={inputStatistics.shapiroV2}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.shapiroV2 = !state.statistics.shapiroV2;
							})
						}
					/>
					<Switch
						disabled={!validInput}
						label={translate(({ analysis }) => analysis.statistics.independent.name)}
						on={inputStatistics.independentV2}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.independentV2 = !state.statistics.independentV2;
							})
						}
					/>
					<Switch
						disabled={!validInput}
						label={translate(({ analysis }) => analysis.statistics.mannWhitney.name)}
						on={inputStatistics.mannWhitneyV2}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.mannWhitneyV2 = !state.statistics.mannWhitneyV2;
							})
						}
					/>
					<Switch
						disabled={!validInput}
						label={translate(({ analysis }) => analysis.statistics.oneWayAnova.name)}
						on={inputStatistics.oneWayAnovaV2}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.oneWayAnovaV2 = !state.statistics.oneWayAnovaV2;
							})
						}
					/>
					<Switch
						disabled={!validInput}
						label={translate(({ analysis }) => analysis.statistics.tukey.name)}
						on={inputStatistics.tukeyV2}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.tukeyV2 = !state.statistics.tukeyV2;
							})
						}
					/>
					<Switch
						disabled={!validInput}
						label={translate(({ analysis }) => analysis.statistics.kruskal.name)}
						on={inputStatistics.kruskalV2}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.kruskalV2 = !state.statistics.kruskalV2;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.twoWayAnova.name)}
						on={inputStatistics.twoWayAnovaV2}
						disabled={!isTwoWayAnovaEnabled || !validInput}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.twoWayAnovaV2 = !state.statistics.twoWayAnovaV2;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.oneWayManova.name)}
						on={inputStatistics.oneWayManovaV2}
						disabled={!isOneWayManovaEnabled || !validInput}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.oneWayManovaV2 = !state.statistics.oneWayManovaV2;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.twoWayManova.name)}
						on={inputStatistics.twoWayManovaV2}
						disabled={!isTwoWayManovaEnabled || !validInput}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.twoWayManovaV2 = !state.statistics.twoWayManovaV2;
							})
						}
					/>
				</Gap>
			</CollapsibleCard>

			<AnalysisFormatting hasChartOptions={false} isEnabled analysis={analysis} />
		</ConfigContainer>
	);
}

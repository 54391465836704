import type { GenericApiResponse, NetworkRequestBase } from 'api/types';
import { TimeDurationKey } from 'timeDurationConsts';
import type { VariablesData, VariableSetOrder, VariablesOrder } from 'store/data/variables';
import type { GenericMap } from 'types/index';
import { VariableSubType } from './statics';
import {
	ArithmeticOperator,
	ComparisonOperator,
	EntryVariableType,
	FixedCategories,
	OperandType,
	RuleType,
	VariableType
} from '../../../types/data/variables/constants';

export type Mark = {
	type: string;
	attrs?: Record<string, any>;
};

export type SerializedContentWithPositions = {
	type: string;
	pos: {
		start: number;
		end: number;
	};
	text?: string;
	marks?: Mark[];
	content?: SerializedContentWithPositions[];
	attrs?: Record<string, any>;
};

export type SerializedContent = Omit<SerializedContentWithPositions, 'pos' | 'content'> & {
	content?: SerializedContent[];
};

export type SerializedDocWithPositions = {
	type: string;
	pos: {
		start: number;
		end: number;
	};
	content: SerializedContentWithPositions[];
	attrs?: Record<string, any>;
};

export type SerializedDoc = {
	type: string;
	content: SerializedContent[];
	attrs?: Record<string, any>;
};

export type VariableNode = {
	var: [string, string];
};

export type GnericValueNode = VariableNode | '' | null;
export type GenericValueNodeWithString = GnericValueNode | string;
export type GenericValueNodeWithNumber = GnericValueNode | number;
export type GenericValueNodeWithSubExpression = ArithmeticNode | GenericValueNodeWithNumber;

export type AdditionNode = {
	[ArithmeticOperator.Addition]:
		| GenericValueNodeWithSubExpression[]
		| [GenericValueNodeWithSubExpression, GenericValueNodeWithSubExpression, string | null]; // Date Operation
};

export type SubtractionNode = {
	[ArithmeticOperator.Subtraction]:
		| [GenericValueNodeWithSubExpression, GenericValueNodeWithSubExpression]
		| [GenericValueNodeWithString, GenericValueNodeWithString, string | null]; // Date Operation
};

export type MultiplicationNode = {
	[ArithmeticOperator.Multiplication]: GenericValueNodeWithSubExpression[];
};

export type DivisionNode = {
	[ArithmeticOperator.Division]: [
		GenericValueNodeWithSubExpression,
		GenericValueNodeWithSubExpression
	];
};

export type ArithmeticNode = AdditionNode | SubtractionNode | MultiplicationNode | DivisionNode;

export type LessComparisonNode = {
	[ComparisonOperator.LessThan]: [
		GenericValueNodeWithSubExpression,
		GenericValueNodeWithSubExpression
	];
};

export type LessOrEqualComparisonNode = {
	[ComparisonOperator.LessThanOrEqual]: [
		GenericValueNodeWithSubExpression,
		GenericValueNodeWithSubExpression
	];
};

export type GreaterComparisonNode = {
	[ComparisonOperator.GreaterThan]: [
		GenericValueNodeWithSubExpression,
		GenericValueNodeWithSubExpression
	];
};

export type GreaterOrEqualComparisonNode = {
	[ComparisonOperator.GreaterThanOrEqual]: [
		GenericValueNodeWithSubExpression,
		GenericValueNodeWithSubExpression
	];
};

export type EqualsComparisonNode = {
	[ComparisonOperator.Equals]: [
		GenericValueNodeWithSubExpression,
		GenericValueNodeWithSubExpression
	];
};

export type ComparisonNode =
	| LessComparisonNode
	| LessOrEqualComparisonNode
	| GreaterComparisonNode
	| GreaterOrEqualComparisonNode
	| EqualsComparisonNode;

export type AndNode = {
	and: ComparisonNode[];
};

export type CategoryNode = {
	catVal: string;
};

export type LogicalNode = {
	if: [
		ComparisonNode | AndNode | null,
		CategoryNode | string | number | ArithmeticNode | null,
		null
	];
};

export type JsonLogic = LogicalNode | ArithmeticNode;

export type JsonLogicMap = {
	order: string[];
	logics: { [jsonLogicId: string]: JsonLogic };
};

export type ContentMap = {
	[jsonLogicId: string]: {
		startPosition: number;
		endPosition: number;
	};
};

export type VariableAliasesMap = {
	order: string[];
	map: Record<
		string,
		{
			key: string;
			variableId: string;
			type: VariableType;
		}
	>;
};

export interface ApiVariablesData {
	variables: GenericMap<ApiVariable>;
	groups: GenericMap<Group>;
	sets: GenericMap<ApiVariableSet>;
	order: VariablesOrder;
}

export interface ValidationCases {
	minValue?: string;
	maxValue?: string;
}

export interface VariableCategory {
	id: string;
	value: string;
	label: string;
	description: string;
}

export enum VariableUniquenessType {
	Manual = 'Manual',
	Sequence = 'Sequence',
	UUID = 'UUID'
}

export enum PROMSystemGenerated {
	PROM_FIRST_NAME = 'prom_first_name',
	PROM_LAST_NAME = 'prom_last_name',
	PROM_DISTR_NUMBER = 'prom_distribution_number',
	PROM_TIME_OF_UPDATE = 'prom_time_of_update'
}

export type ApiTimeDurationFormat = {
	minTimeUnit: TimeDurationKey;
	maxTimeUnit: TimeDurationKey;
};
export type TimeDurationFormat = TimeDurationKey[];
// Used for granular updates - serves concurrency
export type PartialVariableCategory = Pick<VariableCategory, 'id'> & Partial<VariableCategory>;

interface TransactionResponse {
	transactionId: number;
}

export interface Variable {
	name: string;
	label: string;
	description: string;
	type: VariableType;
	targetType?: VariableType | null;
	subType: VariableSubType;
	entryType: EntryVariableType;
	fixedCategories: boolean;
	categories: VariableCategory[];
	dateFormat?: string;
	obligatory: boolean;
	personalData: boolean;
	optimizeForManyValues?: boolean;
	// VALIDATION
	validationRange: boolean;
	validationCases: ValidationCases | null;
	// CALCULATED
	cases: JsonLogic[];
	casesVariableAliases?: GenericMap<string>;
	// UNIQUE
	uniquenessType?: VariableUniquenessType;
	specialization?: PROMSystemGenerated;
	// FLOAT
	visiblePrecision?: number | null;
	minInputPrecision?: number;
	// TIME DURATION
	durationFormat?: TimeDurationFormat;
}

export interface ApiVariable {
	variableName: string;
	variableLabel: string;
	variableType: VariableType;
	variableTypeTarget?: VariableType | null;
	subType?: VariableSubType;
	description: string;
	categories?: string[];
	fixedCategories?: FixedCategories;
	allowedCategories?: VariableCategory[];
	dateFormat?: string;
	obligatory?: boolean;
	personalData?: boolean;
	entryType: EntryVariableType;
	validationCases?: [];
	cases?: JsonLogic[];
	casesVariableAliases?: GenericMap<string>;
	optimizeForManyValues?: boolean;
	uniquenessType?: VariableUniquenessType;
	visiblePrecision?: number | null;
	minInputPrecision?: number;
	specialization?: PROMSystemGenerated;
	durationFormat?: ApiTimeDurationFormat;
}

// Used for granular updates - serves concurrency
export type PartialApiVariable = Pick<ApiVariable, 'variableName'> & Partial<ApiVariable>;

export interface Group {
	groupName: string;
	groupLabel: string;
	variablesBelongingToGroup: string[];
}

export interface VariableSet {
	setName: string;
	setLabel: string;
	setOrder: VariableSetOrder;
	identifier: {
		variableName: string | null;
	};
	aggregationRules: AggregationRule[];
}

export interface AggregationRule {
	name: string;
	aggregator: {
		variableName: string;
	};
	rule: {
		type: AggregationRuleType;
		// TODO: replace `any` with proper types when implemeted [PRJCTS-5698]
		metadata: Record<AggregationRuleType, any> | null;
	};
}

export enum AggregationRuleType {
	Mean = 'average', // renamed due to `PRJCTS-5818`
	Sum = 'sum',
	Median = 'median',
	Min = 'min',
	Max = 'max',
	Earliest = 'earliest',
	Latest = 'latest'
}

export type NewAggregationRule = Omit<AggregationRule, 'name'>;

export type ApiVariableSet = Omit<VariableSet, 'setOrder' | 'identifier'> &
	Partial<Pick<VariableSet, 'setOrder'>> & {
		identifyingVariable: string | null;
	};

export interface NewVariableSet {
	setLabel: string;
}

// Used for granular updates - serves concurrency
export type PartialVariableSet = Pick<VariableSet, 'setName'> &
	Partial<Omit<VariableSet, 'setOrder' | 'aggregationRules'>>;

// Used for granular updates - serves concurrency
export type PartialApiVariableSet = Pick<VariableSet, 'setName'> &
	Partial<Omit<VariableSet, 'setOrder' | 'aggregationRules' | 'identifier'>> &
	Partial<{
		identifyingVariable: string | null;
	}>;

export interface NewGroup {
	groupLabel: string;
}

export type UpdatedGroup = Omit<Group, 'variablesBelongingToGroup'>;

export type CalculationActions = {
	setDraftCases: (cases: JsonLogicMap) => void;
	onAddJsonLogic: (type: RuleType) => void;
	onAddEmptyOperandAtJsonLogicPath: (path: string, type: OperandType) => void;
	onAddOperandValue: (
		path: string,
		type: OperandType,
		value?:
			| string
			| number
			| VariableNode
			| CategoryNode
			| LogicalNode
			| ComparisonNode
			| ArithmeticNode
	) => void;
	onOperatorChange: (path: string | null, value: string) => void;
	onLogicalOperatorChange: (path: string | null, value: string) => void;
	onDeleteOperand: (path: string | null) => void;
	onDeleteCase: (path: string | null) => void;
	onValidEditorRuleWithVariableMap: (
		logicMap: JsonLogicMap,
		variableMap: VariableAliasesMap
	) => void;
};

export interface VariableAliasesActions {
	handleEntryToMap: () => void;
	setVariableAlias: (id: string, key: string) => void;
	setVariableId: (id: string, varName: string) => void;
	removeEntry: (id: string) => void;
	setKeyMap: (newMap: VariableAliasesMap) => void;
}
export interface VariableActions {
	onLabelChanged: (value: string) => void;
	onDescriptionChange: (value: string) => void;
	onToggleFixedCategory: () => void;
	onToggleOptimizeForManyValues: () => void;
	onToggleObligatory: () => void;
	onTogglePersonalData: () => void;
	onToggleRange: () => void;
	onUniquenessTypeChange: (value: VariableUniquenessType) => void;
	onTypeChange: (value: VariableType, isEntry: boolean) => void;
	onSubTypeChange: (value: VariableSubType) => void;
	onEntryTypeChange: (value: EntryVariableType) => void;
	onValidationChange: (validationCases: ValidationCases) => void;
	onCategoriesChange: (categoryValues: VariableCategory[]) => void;
	onMinInputPrecisionChange: (value?: number) => void;
	onVisiblePrecisionChange: (value: number | null) => void;
	onFormatChange: (value?: TimeDurationFormat) => void;
	onSourceTimeUnitsChange: (value?: TimeDurationKey) => void;
}

/**
 * =====================
 * 		VARIABLES
 * =====================
 */

/*
	GET VARIABLE
*/
export type GetVariableInput = {
	projectId: number;
	variableName: string;
};
export type GetVariableRequest = NetworkRequestBase & GetVariableInput;
export type GetVariableResponse = GenericApiResponse<{ variable: ApiVariable }>;
export type GetVariableOutput = { variable: Variable };

/*
	GET VARIABLES
*/

export type GetVariablesInput = {
	projectId: number;
};
export type GetVariablesRequest = NetworkRequestBase & GetVariablesInput;
export type GetVariablesResponse = GenericApiResponse<ApiVariablesData>;
export type GetVariablesOutput = { variablesData: VariablesData };

/*
	CREATE VARIABLE
*/
export type CreateVariableInput = {
	projectId: number;
	variable: ApiVariable;
	destinationGroup?: { groupName: string };
	set?: { setName: string }; // parent variable set name
};
export type CreateVariableRequest = NetworkRequestBase & CreateVariableInput;
export type CreateVariableResponse = GenericApiResponse<{
	variable: ApiVariable;
	transactionId?: number;
}>;
export type CreateVariableOutput = {
	variable: Variable;
	transactionId?: number;
};

/*
	UPDATE VARIABLE
*/
export type UpdateVariableInput = {
	projectId: number;
	variable: PartialApiVariable;
	patchDependencies?: boolean;
	sourceTimeUnits?: string;
	updateDataset?: true; // used for `fixedCategories` === "no"
	set?: { setName: string }; // parent variable set name
};
export type UpdateVariableRequest = NetworkRequestBase & UpdateVariableInput;
export type UpdateVariableResponse = GenericApiResponse<{
	variable: ApiVariable;
	transactionId?: number;
}>;
export type UpdateVariableOutput = {
	variable: Variable;
	transactionId?: number;
};

/*
	DELETE VARIABLE
*/
export type DeleteVariableInput = {
	projectId: number;
	variable: { variableName: string };
	set?: { setName: string }; // parent variable set name
};
export type DeleteVariableRequest = NetworkRequestBase & DeleteVariableInput;
export type DeleteVariableResponse = GenericApiResponse<{
	transactionId?: number;
}>;
export type DeleteVariableOutput = { transactionId?: number };

/**
 * ===================
 * 		GROUPS
 * ===================
 */

/*
	CREATE GROUP
*/
export type CreateGroupInput = {
	projectId: number;
	group: NewGroup;
	variables?: { variableName: string }[];
	destinationIndex?: number;
	set?: { setName: string }; // parent variable set name
};
export type CreateGroupRequest = NetworkRequestBase & CreateGroupInput;
export type CreateGroupResponse = GenericApiResponse<{ group: Group }>;
export type CreateGroupOutput = { group: Group };

/*
	UPDATE GROUP
*/
export type UpdateGroupInput = {
	projectId: number;
	group: UpdatedGroup;
	set?: { setName: string }; // parent variable set name
};
export type UpdateGroupRequest = NetworkRequestBase & UpdateGroupInput;
export type UpdateGroupResponse = GenericApiResponse<{ group: Group }>;
export type UpdateGroupOutput = { group: Group };

/*
	DELETE GROUP
*/
export type DeleteGroupInput = {
	projectId: number;
	group: { groupName: string };
	set?: { setName: string }; // parent variable set name
};
export type DeleteGroupRequest = NetworkRequestBase & DeleteGroupInput;
export type DeleteGroupResponse = GenericApiResponse<{}>;

/**
 * ========================
 * 		VARIABLE SETS
 * ========================
 */

/*
	CREATE VARIABLE SET
*/
export type CreateVariableSetInput = {
	projectId: number;
	set: NewVariableSet;
};
export type CreateVariableSetRequest = NetworkRequestBase & CreateVariableSetInput;
export type CreateVariableSetResponse = GenericApiResponse<{
	set: ApiVariableSet;
}>;
export type CreateVariableSetOutput = { variableSet: VariableSet };

/*
	UPDATE VARIABLE SET
*/
export type UpdateVariableSetInput = {
	projectId: number;
	set: PartialApiVariableSet;
};
export type UpdateVariableSetRequest = NetworkRequestBase & UpdateVariableSetInput;
export type UpdateVariableSetResponse = GenericApiResponse<{
	set: ApiVariableSet;
	transactionId?: number;
}>;
export type UpdateVariableSetOutput = {
	variableSet: VariableSet;
	transactionId?: number;
};

/*
	DELETE VARIABLE SET
*/
export type DeleteVariableSetInput = {
	projectId: number;
	set: { setName: string };
};
export type DeleteVariableSetRequest = NetworkRequestBase & DeleteVariableSetInput;
export type DeleteVariableSetResponse = GenericApiResponse<{}>;

/**
 * ==========================
 * 	CRUD - AGGREGATION RULES
 * ==========================
 */

/*
	CREATE VARIABLE SET AGGREGATION RULE
*/
export type CreateVariableSetAggregationRuleInput = {
	projectId: number;
	set: {
		setName: string;
	};
	rule: NewAggregationRule;
};
export type CreateVariableSetAggregationRuleRequest = NetworkRequestBase &
	CreateVariableSetAggregationRuleInput;
export type CreateVariableSetAggregationRuleResponse = GenericApiResponse<{
	set: ApiVariableSet;
	transactionId?: number;
}>;
export type CreateVariableSetAggregationRuleOutput = {
	variableSet: VariableSet;
	transactionId?: number;
};

/*
	UPDATE VARIABLE SET AGGREGATION RULE
*/
export type UpdateVariableSetAggregationRuleInput = {
	projectId: number;
	set: {
		setName: string;
	};
	rule: AggregationRule;
};
export type UpdateVariableSetAggregationRuleRequest = NetworkRequestBase &
	UpdateVariableSetAggregationRuleInput;
export type UpdateVariableSetAggregationRuleResponse = GenericApiResponse<{
	set: ApiVariableSet;
	transactionId?: number;
}>;
export type UpdateVariableSetAggregationRuleOutput = {
	variableSet: VariableSet;
	transactionId?: number;
};

/*
	DELETE VARIABLE SET AGGREGATION RULE
*/
export type DeleteVariableSetAggregationRuleInput = {
	projectId: number;
	set: {
		setName: string;
	};
	rule: {
		name: string;
	};
};
export type DeleteVariableSetAggregationRuleRequest = NetworkRequestBase &
	DeleteVariableSetAggregationRuleInput;
export type DeleteVariableSetAggregationRuleResponse = GenericApiResponse<{}>;
export type DeleteVariableSetAggregationRuleOutput = void;

/**
 * =========================================
 * 	ACTIONS: MOVE
 * 	SOURCE: VARIABLE(S) / GROUP(S) / SET(S)
 * 	TARGET: ROOT LIST
 * =========================================
 */

/*
	MOVE VARIABLE
*/
export type MoveVariableInput = {
	projectId: number;
	variable: { variableName: string };
	sourceIndex: number; // current position inside main list
	destinationIndex: number; // new position inside main list
	set?: { setName: string }; // parent variable set name
};
export type MoveVariableRequest = NetworkRequestBase & MoveVariableInput;
export type MoveVariableResponse = GenericApiResponse<{}>;

/*
	MOVE GROUP
*/
export type MoveGroupInput = {
	projectId: number;
	group: { groupName: string };
	sourceIndex: number; // current position inside main list
	destinationIndex: number; // new position inside main list
	set?: { setName: string }; // parent variable set name
};
export type MoveGroupRequest = NetworkRequestBase & MoveGroupInput;
export type MoveGroupResponse = GenericApiResponse<{}>;

/*
	MOVE VARIABLE SET
*/
export type MoveVariableSetInput = {
	projectId: number;
	set: { setName: string };
	sourceIndex: number; // current position inside main list
	destinationIndex: number; // new position inside main list
};
export type MoveVariableSetRequest = NetworkRequestBase & MoveVariableSetInput;
export type MoveVariableSetResponse = GenericApiResponse<{}>;

/*
	MOVE AGGREGATION RULE
*/
export type MoveVariableSetAggregationRuleInput = {
	projectId: number;
	set: { setName: string };
	rule: { name: string };
	sourceIndex: number; // current position
	destinationIndex: number; // new position
};
export type MoveVariableSetAggregationRuleRequest = NetworkRequestBase &
	MoveVariableSetAggregationRuleInput;
export type MoveVariableSetAggregationRuleResponse = GenericApiResponse<{}>;

/**
 * ==========================================
 * 	ACTIONS: ADD/MOVE/REMOVE
 * 	SOURCE: VARIABLE(S)
 * 	TARGET: FROM/TO GROUP(S)
 * ==========================================
 */

/*
	ADD VARIABLE TO GROUP
*/
export type AddVariableToGroupInput = {
	projectId: number;
	variable: { variableName: string };
	group: { groupName: string };
	destinationIndex?: number; // position inside `group`
	set?: { setName: string }; // parent variable set name
};
export type AddVariableToGroupRequest = NetworkRequestBase & AddVariableToGroupInput;
export type AddVariableToGroupResponse = GenericApiResponse<{}>;

/*
	ADD VARIABLES TO GROUP
*/
export type AddVariablesToGroupInput = {
	projectId: number;
	variables: { variableName: string }[];
	group: { groupName: string };
	destinationIndex?: number; // position inside `group`
	set?: { setName: string }; // parent variable set name
};
export type AddVariablesToGroupRequest = NetworkRequestBase & AddVariablesToGroupInput;
export type AddVariablesToGroupResponse = GenericApiResponse<{}>;

/*
	REMOVE VARIABLE FROM GROUP
*/
export type RemoveVariableFromGroupInput = {
	projectId: number;
	variable: { variableName: string };
	group: { groupName: string };
	destinationIndex?: number; // position inside main list
	set?: { setName: string }; // parent variable set name
};
export type RemoveVariableFromGroupRequest = NetworkRequestBase & RemoveVariableFromGroupInput;
export type RemoveVariableFromGroupResponse = GenericApiResponse<{}>;

/*
	REMOVE VARIABLES FROM GROUP
*/
export type RemoveVariablesFromGroupInput = {
	projectId: number;
	variables: { variableName: string }[];
	group: { groupName: string };
};
export type RemoveVariablesFromGroupRequest = NetworkRequestBase & RemoveVariablesFromGroupInput;
export type RemoveVariablesFromGroupResponse = GenericApiResponse<{}>;

/*
	MOVE VARIABLE INSIDE GROUP
*/
export type MoveVariableInsideGroupInput = {
	projectId: number;
	variable: { variableName: string };
	group: { groupName: string };
	sourceIndex: number; // current position inside `group`
	destinationIndex: number; // new position inside `group`
	set?: { setName: string }; // parent variable set name
};
export type MoveVariableInsideGroupRequest = NetworkRequestBase & MoveVariableInsideGroupInput;
export type MoveVariableInsideGroupResponse = GenericApiResponse<{}>;

/*
	MOVE VARIABLE BETWEEN GROUPS
*/
export type MoveVariableBetweenGroupsInput = {
	projectId: number;
	variable: { variableName: string };
	sourceGroup: { groupName: string };
	destinationGroup: { groupName: string };
	destinationIndex?: number; // position inside `destinationGroup`
	set?: { setName: string }; // parent variable set name
};
export type MoveVariableBetweenGroupsRequest = NetworkRequestBase & MoveVariableBetweenGroupsInput;
export type MoveVariableBetweenGroupsResponse = GenericApiResponse<{}>;

/*
	MOVE VARIABLES BETWEEN GROUPS
*/
export type MoveVariablesBetweenGroupsInput = {
	projectId: number;
	variables: { variableName: string }[];
	sourceGroup: { groupName: string };
	destinationGroup: { groupName: string };
	destinationIndex?: number; // position inside `destinationGroup`
	set?: { setName: string }; // parent variable set name
};
export type MoveVariablesBetweenGroupsRequest = NetworkRequestBase &
	MoveVariablesBetweenGroupsInput;
export type MoveVariablesBetweenGroupsResponse = GenericApiResponse<{}>;

/**
 * ==========================================
 * 	ACTIONS: ADD/MOVE/REMOVE
 * 	SOURCE: VARIABLE(S) / GROUP(S)
 * 	TARGET: FROM/TO SET(S)
 * ==========================================
 */

/*
	ADD VARIABLE TO SET
*/
export type AddVariableToSetInput = {
	projectId: number;
	variable: { variableName: string };
	set: { setName: string };
	destinationIndex?: number; // position inside `set`
};
export type AddVariableToSetRequest = NetworkRequestBase & AddVariableToSetInput;
export type AddVariableToSetResponse = GenericApiResponse<TransactionResponse>;

export type AddVariableToSetOutput = TransactionResponse;

/*
	REMOVE VARIABLE FROM SET
*/
export type RemoveVariableFromSetInput = {
	projectId: number;
	variable: { variableName: string };
	set: { setName: string };
	destinationIndex?: number; // position inside main list
};
export type RemoveVariableFromSetRequest = NetworkRequestBase & RemoveVariableFromSetInput;
export type RemoveVariableFromSetResponse = GenericApiResponse<TransactionResponse>;

export type RemoveVariableFromSetOutput = TransactionResponse;

/*
	ADD VARIABLE GROUP TO SET
*/
export type AddVariableGroupToSetInput = {
	projectId: number;
	group: { groupName: string };
	set: { setName: string };
	destinationIndex?: number; // position inside `set`
};
export type AddVariableGroupToSetRequest = NetworkRequestBase & AddVariableGroupToSetInput;
export type AddVariableGroupToSetResponse = GenericApiResponse<TransactionResponse>;

export type AddVariableGroupToSetOutput = TransactionResponse;
/*
	REMOVE VARIABLE GROUP FROM SET
*/
export type RemoveVariableGroupFromSetInput = {
	projectId: number;
	group: { groupName: string };
	set: { setName: string };
	destinationIndex?: number; // position inside main list
};
export type RemoveVariableGroupFromSetRequest = NetworkRequestBase &
	RemoveVariableGroupFromSetInput;
export type RemoveVariableGroupFromSetResponse = GenericApiResponse<TransactionResponse>;

export type RemoveVariableGroupFromSetOutput = TransactionResponse;
/*
	MOVE VARIABLE BETWEEN SETS
*/
export type MoveVariableBetweenSetsInput = {
	projectId: number;
	variable: { variableName: string };
	sourceSet: { setName: string };
	destinationSet: { setName: string };
	destinationIndex?: number; // position inside `destinationSet`
};
export type MoveVariableBetweenSetsRequest = NetworkRequestBase & MoveVariableBetweenSetsInput;
export type MoveVariableBetweenSetsResponse = GenericApiResponse<TransactionResponse>;

export type MoveVariableBetweenSetsOutput = TransactionResponse;
/*
	MOVE VARIABLE GROUP BETWEEN SETS
*/
export type MoveVariableGroupBetweenSetsInput = {
	projectId: number;
	group: { groupName: string };
	sourceSet: { setName: string };
	destinationSet: { setName: string };
	destinationIndex?: number; // position inside `destinationSet`
};
export type MoveVariableGroupBetweenSetsRequest = NetworkRequestBase &
	MoveVariableGroupBetweenSetsInput;
export type MoveVariableGroupBetweenSetsResponse = GenericApiResponse<TransactionResponse>;

export type MoveVariableGroupBetweenSetsOutput = TransactionResponse;
/**
 * ================
 * 	BULK DELETE
 * ================
 */

/*
	DELETE BULK VARIABLES OR GROUPS
*/
export type DeleteBulkVariablesDataInput = {
	projectId: number;
	variables: { variableName: string }[];
	groups: { groupName: string }[];
	set?: { setName: string };
};
export type DeleteBulkVariablesDataRequest = NetworkRequestBase & DeleteBulkVariablesDataInput;
export type DeleteBulkVariablesDataResponse = GenericApiResponse<{
	transactionId?: number;
}>;
export type DeleteBulkVariablesDataOutput = { transactionId?: number };

/**
 * ===================
 * 	CRUD - CATEGORIES
 * ===================
 */

/*
	CREATE CATEGORY VALUE
*/
export type CreateVariableCategoryValueInput = {
	projectId: number;
	variable: { variableName: string };
	value: Omit<VariableCategory, 'id'>;
	set?: { setName: string };
};
export type CreateVariableCategoryValueRequest = NetworkRequestBase &
	CreateVariableCategoryValueInput;
export type CreateVariableCategoryValueResponse = GenericApiResponse<{
	variable: ApiVariable;
}>;
export type CreateVariableCategoryValueOutput = {
	variable: Variable;
};

/*
	CREATE CATEGORY VALUES
*/
export type CreateVariableCategoryValuesInput = {
	projectId: number;
	variable: { variableName: string };
	values: VariableCategory[];
	set?: { setName: string };
};
export type CreateVariableCategoryValuesRequest = NetworkRequestBase &
	CreateVariableCategoryValuesInput;
export type CreateVariableCategoryValuesResponse = GenericApiResponse<{
	variable: ApiVariable;
}>;
export type CreateVariableCategoryValuesOutput = {
	variable: Variable;
};

/*
	UPDATE CATEGORY VALUE
*/
export type UpdateVariableCategoryValueInput = {
	projectId: number;
	variable: { variableName: string };
	value: PartialVariableCategory;
	set?: { setName: string };
};
export type UpdateVariableCategoryValueRequest = NetworkRequestBase &
	UpdateVariableCategoryValueInput;
export type UpdateVariableCategoryValueResponse = GenericApiResponse<{
	variable: ApiVariable;
	transactionId?: number;
	transactionIds?: number[];
}>;
export type UpdateVariableCategoryValueOutput = {
	variable: Variable;
	transactionId?: number;
	transactionIds?: number[];
};

/*
	DELETE CATEGORY VALUE
*/
export type DeleteVariableCategoryValueInput = {
	projectId: number;
	variable: { variableName: string };
	value: { id: string };
	updateDataset?: true;
	set?: { setName: string };
};
export type DeleteVariableCategoryValueRequest = NetworkRequestBase &
	DeleteVariableCategoryValueInput;
export type DeleteVariableCategoryValueResponse = GenericApiResponse<{
	variable: ApiVariable;
	transactionId?: number;
	transactionIds?: number[];
}>;
export type DeleteVariableCategoryValueOutput = {
	variable: Variable;
	transactionId?: number;
	transactionIds?: number[];
};

/*
	DELETE CATEGORY VALUES
*/
export type DeleteVariableCategoryValuesInput = {
	projectId: number;
	variable: { variableName: string };
	values: { id: string }[];
	updateDataset?: true;
	set?: { setName: string };
};
export type DeleteVariableCategoryValuesRequest = NetworkRequestBase &
	DeleteVariableCategoryValuesInput;
export type DeleteVariableCategoryValuesResponse = GenericApiResponse<{
	variable: ApiVariable;
	transactionId?: number;
	transactionIds?: number[];
}>;
export type DeleteVariableCategoryValuesOutput = {
	variable: Variable;
	transactionId?: number;
	transactionIds?: number[];
};

/*
	MOVE CATEGORY VALUE
*/
export type MoveVariableCategoryValueInput = {
	projectId: number;
	variable: { variableName: string };
	value: { id: string };
	sourceIndex: number; // current position
	destinationIndex: number; // new position
	set?: { setName: string }; // parent variable set name
};
export type MoveVariableCategoryValueRequest = NetworkRequestBase & MoveVariableCategoryValueInput;
export type MoveVariableCategoryValueResponse = GenericApiResponse<{
	variable: ApiVariable;
}>;
export type MoveVariableCategoryValueOutput = { variable: Variable };

/*
	MOVE CATEGORY VALUES
*/
export type MoveVariableCategoryValuesInput = {
	projectId: number;
	variable: { variableName: string };
	values: { id: string }[];
	destination:
		| {
				index: number;
		  }
		| {
				before: {
					id: string;
				};
		  }
		| {
				after: {
					id: string;
				};
		  };
	set?: { setName: string }; // parent variable set name
};
export type MoveVariableCategoryValuesRequest = NetworkRequestBase &
	MoveVariableCategoryValuesInput;
export type MoveVariableCategoryValuesResponse = GenericApiResponse<{
	variable: ApiVariable;
}>;
export type MoveVariableCategoryValuesOutput = {
	variable: Variable;
};

/*
	MERGE CATEGORY VALUES
*/
export type MergeVariableCategoryValuesInput = {
	projectId: number;
	variable: { variableName: string };
	values: {
		id: string;
	}[];
	targetValue: Pick<VariableCategory, 'id'> | Omit<VariableCategory, 'id'>;
	set?: { setName: string }; // parent variable set name
};
export type MergeVariableCategoryValuesRequest = NetworkRequestBase &
	MergeVariableCategoryValuesInput;
export type MergeVariableCategoryValuesResponse = GenericApiResponse<{
	variable: ApiVariable;
	transactionId?: number;
	transactionIds?: number[];
}>;
export type MergeVariableCategoryValuesOutput = {
	variable: Variable;
	transactionId?: number;
	transactionIds?: number[];
};

/**
 * ========
 * 	OTHERS
 * ========
 */

/*
	CHECK VARIABLE TYPE CHANGE
*/
export type CheckVariableTypeChangeInput = {
	projectId: number;
	variable: { variableName: string };
	targetType: string;
	set?: { setName: string };
};
export type CheckVariableTypeChangeRequest = NetworkRequestBase & CheckVariableTypeChangeInput;
export type CheckVariableTypeChangeResponse = GenericApiResponse<{
	transformationOk: boolean;
}>;
export type CheckVariableTypeChangeOutput = boolean;

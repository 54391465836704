import { Container, AddAnalysisContainer } from './NoAnalyses.style';
import { Svgs } from 'environment';
import { AddAnalysis } from 'components/Analysis/Analyses/AnalysisList/AddAnalysis';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Button } from 'components/UI/Interactables/Button';
import { useTranslation } from 'hooks/store';

interface Props {
	onAddVariable: () => void;
	onImportDataset: () => void;
	variablesDataSelectItems: VariablesDataSelectItems;
}
interface AddButtonParams {
	label: string;
	disabled?: boolean;
	action: () => void;
}

export function NoAnalyses({ onAddVariable, onImportDataset, variablesDataSelectItems }: Props) {
	const { translate } = useTranslation();

	const addButton = ({ label, disabled, action }: AddButtonParams) => (
		<>
			<Spacer size={m => m.m} />
			<Button title={label} disabled={disabled} variant={v => v.primary} onClick={action} />
		</>
	);
	return (
		<Container>
			<Spacer size={s => s.xxl} />
			<Svgs.EmptyStatesNoAnalysis style={{ minHeight: 240 }} />
			<Spacer size={m => m.m} />
			<Typography.H3>{translate(({ analysis }) => analysis.errors.noAnalyses)}</Typography.H3>
			<Spacer size={s => s.xs} />
			{variablesDataSelectItems.categorySelectItems.length > 0 ||
			variablesDataSelectItems.numericSelectItems.length > 0 ||
			variablesDataSelectItems.durationSelectItems.length > 0 ? (
				<AddAnalysisContainer>
					<AddAnalysis addButton={addButton} />
				</AddAnalysisContainer>
			) : (
				<>
					<Typography.Paragraph multiline alignCenter>
						{translate(({ addVariable }) => addVariable.addVariablesText)}
					</Typography.Paragraph>
					<Spacer size={m => m.m} />
					<Button
						title={translate(({ varGroup }) => varGroup.createNewVariable)}
						width={16.4}
						onClick={onAddVariable}
					></Button>
					<Spacer size={s => s.s} />
					<Button
						variant={v => v.link}
						title={translate(({ varGroup }) => varGroup.importVariables)}
						onClick={onImportDataset}
					/>
				</>
			)}
			<Spacer size={s => s.xs} />
		</Container>
	);
}

import { Svgs } from 'environment';
import { Container, Layout, VariableTypeContainer } from './FixOption.style';
import { Icon } from 'components/UI/Icons';
import { Input } from 'components/UI/Inputs/Input';
import { ImportType, InputType } from 'types/enums';
import { useTranslation } from 'hooks/store/ui/useTranslation';
import { PreviewVariable } from '../../../../../types/data/projects/import/types';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { VariableType } from 'types/data/variables/constants';
import { EDITABLE_DATA_TYPES_OPTIONS } from 'consts';

import { useSelector } from 'hooks/utils';
import { SelectItem } from 'types';
import { useImportTimezoneSettings } from 'hooks/store/data/projects/import/useImportTimezoneSettings';
import { Spacer } from 'components/UI/Spacer';
import {
	matchDateTimeFormat,
	showDateFormatPicker
} from 'helpers/projects/imports/importDateAndDatetime';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import { getTimeZoneOffset } from 'helpers/projects/imports/importTimezone';

type Props = {
	variable: PreviewVariable | null;
	selectedOption: ImportType;
	updateVariable: (variable: PreviewVariable) => void;
	handleDateTypeUpdate: (vID: string, value: string) => void;
	handleCustomDateTypeUpdate: (vID: string, value: string, isDatetime?: boolean) => void;
};

export function FixOption({
	variable,
	selectedOption,
	updateVariable,
	handleDateTypeUpdate,
	handleCustomDateTypeUpdate
}: Props) {
	const { translate } = useTranslation();
	const { requiresTimezoneSettings } = useImportTimezoneSettings();

	const { dateFormats, dateTimeFormats, suggestedVariableTypes, isExcelOrigin, timeZones } =
		useSelector(state => state.data.projects.import);

	const dateFormatsSelectItems: SelectItem[] = Object.values(dateFormats).map(value => ({
		label: matchDateTimeFormat(value),
		value
	}));
	const dateTimeFormatsSelectItems: SelectItem[] = Object.values(dateTimeFormats).map(value => ({
		label: matchDateTimeFormat(value),
		value
	}));

	const variablesWithDateFormat: string[] = [];
	suggestedVariableTypes &&
		suggestedVariableTypes.forEach(suggestion => {
			if (
				suggestion.suggestedVariableType === VariableType.Date ||
				suggestion.suggestedVariableType === VariableType.DateTime
			) {
				variablesWithDateFormat.push(suggestion.name);
			}
		});

	const dataTypeSelectItems = EDITABLE_DATA_TYPES_OPTIONS.filter(
		item => item.value !== VariableType.TimeDuration
	).map(dataType => ({
		label: translate(() => dataType.label),
		value: dataType.value
	}));

	if (!variable) {
		return null;
	}

	function onVariableTypeChanged(type: VariableType) {
		if (!variable) {
			return;
		}

		updateVariable({
			...variable,
			type
		});
	}

	return (
		<Container id={`fix-option-container`}>
			<Layout>
				<div>
					<Input
						type={InputType.Text}
						label={translate(dict => dict.import.importReview.fixOption.columnName)}
						value={
							selectedOption === ImportType.ReplaceAll ||
							selectedOption === ImportType.Now ||
							selectedOption === ImportType.MoreDataToExistingEntries
								? variable.label
								: variable.previewVariableLabel
						}
						className="column-name"
						readOnly
					/>
					{showDateFormatPicker(
						variable.type as VariableType,
						VariableType.Date,
						variable.isExcelDateFormat
					) && (
						<>
							<Spacer size={s => s.s} />
							<CreatableSelect
								canClear={false}
								scrollIntoView
								disabled={variablesWithDateFormat.includes(
									variable.previewVariableLabel
								)}
								value={
									variable.dateFormat
										? {
												label: matchDateTimeFormat(variable.dateFormat),
												value: matchDateTimeFormat(variable.dateFormat)
										  }
										: {
												label: translate(
													dict =>
														dict.projectsPage.dataToEntries
															.selectDateFormat
												),
												value: translate(
													dict =>
														dict.projectsPage.dataToEntries
															.selectDateFormat
												)
										  }
								}
								items={[
									...dateFormatsSelectItems,
									{ label: 'Custom', value: 'Custom' }
								]}
								className="select-data-type"
								onValueSelected={value =>
									value && handleDateTypeUpdate(variable.id, value)
								}
							/>
							{variable.dateFormat === DATE_FORMATS.Custom && (
								<Input
									type={InputType.Text}
									value={variable.customDateFormat}
									error={variable.dateFormatError}
									className="select-data-type-custom"
									placeholder={translate(
										dict => dict.projectsPage.dataToEntries.customDateFormat
									)}
									onChange={e =>
										handleCustomDateTypeUpdate(
											variable.id,
											e.target.value.toUpperCase()
										)
									}
								/>
							)}
						</>
					)}

					{showDateFormatPicker(
						variable.type as VariableType,
						VariableType.DateTime,
						variable.isExcelDateFormat
					) && (
						<>
							<Spacer size={s => s.s} />
							<CreatableSelect
								scrollIntoView
								canClear={false}
								disabled={variablesWithDateFormat.includes(
									variable.previewVariableLabel
								)}
								value={
									variable.dateFormat
										? {
												label: matchDateTimeFormat(variable.dateFormat),
												value: matchDateTimeFormat(variable.dateFormat)
										  }
										: {
												label: translate(
													dict =>
														dict.projectsPage.dataToEntries
															.selectDateAndTime
												),
												value: translate(
													dict =>
														dict.projectsPage.dataToEntries
															.selectDateAndTime
												)
										  }
								}
								items={[
									...dateTimeFormatsSelectItems,
									{ label: 'Custom', value: 'Custom' }
								]}
								className="select-data-type"
								onValueSelected={value =>
									value && handleDateTypeUpdate(variable.id, value)
								}
							/>

							{variable.dateFormat === DATE_FORMATS.Custom && (
								<Input
									type={InputType.Text}
									value={variable.customDateFormat}
									error={variable.dateFormatError}
									placeholder={translate(
										dict => dict.projectsPage.dataToEntries.customDateFormat
									)}
									className="select-data-type"
									onChange={e =>
										handleCustomDateTypeUpdate(
											variable.id,
											e.target.value.toUpperCase(),
											true
										)
									}
								/>
							)}
						</>
					)}
				</div>
				<Icon svg={Svgs.ArrowLongRight} className="arrow" />
				<VariableTypeContainer>
					<CreatableSelect
						canClear={false}
						value={
							variable.type
								? {
										label: translate(
											dict =>
												dict.variableLabels[variable.type as VariableType]
										),
										value: variable.type
								  }
								: {
										label: translate(
											({ inputPlaceholder }) => inputPlaceholder.pleaseSelect
										),
										value: translate(
											({ inputPlaceholder }) => inputPlaceholder.pleaseSelect
										)
								  }
						}
						scrollIntoView
						items={dataTypeSelectItems}
						className="select-data-type"
						onValueSelected={value =>
							value && onVariableTypeChanged(value as VariableType)
						}
						label={translate(dict => dict.import.importReview.fixOption.variableType)}
						disabled={!variable.isNew}
					/>
					{/* TIMEZONE SETTINGS IF FORMAT DOES NOT INCLUDE Z / +HH:MM / -HH:MM */}
					{!isExcelOrigin && requiresTimezoneSettings(variable) && (
						<>
							<Spacer size={s => s.s} />
							<CreatableSelect
								canClear={true}
								id={`global_timezone_select`}
								items={timeZones}
								onClear={() =>
									updateVariable({
										...variable,
										timeZone: {
											label: '',
											value: ''
										}
									})
								}
								onValueSelected={value =>
									value &&
									updateVariable({
										...variable,
										timeZone: {
											label: `${getTimeZoneOffset(value)} ${value}`,
											value
										}
									})
								}
								value={variable.timeZone}
							/>
						</>
					)}
				</VariableTypeContainer>
			</Layout>
		</Container>
	);
}

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface RemoveCreditCardWarningModalProps {
	loading: boolean;
	removeLastCard: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

export function RemoveCreditCardWarningModal({
	loading,
	removeLastCard,
	onConfirm,
	onCancel
}: RemoveCreditCardWarningModalProps) {
	const { translate } = useTranslation();

	function onClose() {
		if (!loading) onCancel();
	}

	return (
		<Modal
			visible
			title={translate(({ accountUM }) => accountUM.payment.removeCard)}
			primary={{
				label: translate(({ buttons }) => buttons.confirm),
				loading,
				onClick: onConfirm
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				disabled: loading,
				onClick: onCancel
			}}
			close
			closeOnBackdrop={loading ? false : true}
			onClose={onClose}
			size={s => s.s}
		>
			{removeLastCard && (
				<Typography.Paragraph alignCenter>
					{translate(({ accountUM }) => accountUM.payment.removeLastCardWarning)}
				</Typography.Paragraph>
			)}

			<Typography.Paragraph alignCenter fontweight={f => f.bold}>
				{translate(({ accountUM }) => accountUM.payment.removeCardWarningConfirm)}
			</Typography.Paragraph>
		</Modal>
	);
}

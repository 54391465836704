import { Typography } from 'components/UI/Typography';
import styled from 'styled-components';

export const DropdownScrollContainer = styled.div`
	width: 100%;
	overflow-y: auto;
	max-height: calc(100vh - 42rem);
	min-height: 5rem;
`;

export const NoResults = styled(Typography.Paragraph)`
	padding: 1.5rem 0 1rem 2rem;
`;

import produce from 'immer';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.SET_HELP_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.searchTerm = term;
			});
		}
		case ActionTypes.GET_HELP_TOPICS: {
			const { topics } = action.payload;

			return produce(state, draft => {
				draft.topics = topics;
				draft.fetched = true;
			});
		}

		default: {
			return state;
		}
	}
};

import styled from 'styled-components';
import { Dictionary, Svgs, MediaQueries } from 'environment';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useAnalysesActiveColum } from 'hooks/store';

interface Props {
	message?: string;
}

export function NoPlot({ message = Dictionary.analysis.errors.noResults }: Props) {
	const { translate } = useTranslation();
	const [activeColumn] = useAnalysesActiveColum();

	return (
		<Container>
			<Icon />
			<Spacer size={m => m.m} />
			<ResponsiveH3 activeColumn={activeColumn} alignCenter>
				{translate(({ analysis }) => analysis.errors.noAnalyses)}
			</ResponsiveH3>
			<Spacer size={s => s.xs} />
			<Typography.Paragraph alignCenter>{message}</Typography.Paragraph>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 85%;
	margin: auto;
`;

const Icon = styled(Svgs.EmptyStatesNoVariable)`
	@media ${MediaQueries.maxWidth.sm} {
		height: calc(100vw - 22rem);
	}
`;

const ResponsiveH3 = styled(Typography.H3)<{ activeColumn: number }>`
	${props =>
		props.activeColumn === 3
			? 'font-size: 2.4rem;'
			: props.activeColumn === 2
			? 'font-size: 2.6rem;'
			: ''}
`;

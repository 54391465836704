import { useState } from 'react';
import { Draggable, DraggingStyle, Droppable } from 'react-beautiful-dnd';

import {
	DraggableCard,
	CardHeader,
	TemplateVariable,
	Container,
	Wrapper,
	GroupedIconWrapper
} from 'components/Templates';
import { Svgs, Colors } from 'environment';
import { getCloneStyle } from 'helpers/templates';
import { TemplateGroupInterface } from 'store/data/templates';
import { DragAndDropTypes } from 'types/index';

import { ColorIndicator } from 'components/Forms/Designer/Draggable/VariablesAndGroups/style';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';

interface Props {
	writeAccess: boolean;
	group: TemplateGroupInterface;
}

export function DraggableGroup({ writeAccess, group }: Props) {
	const [groupExpanded, setGroupExpanded] = useState(false);

	const { groupName, groupLabel, variables } = group;

	if (!groupName) return null;

	return (
		<Droppable
			droppableId={`${groupName}${DragAndDropTypes.DraggableTemplateGroupedVariable}`}
			isDropDisabled
		>
			{provided => (
				<div ref={provided.innerRef}>
					<Flex column>
						{/* HEADER */}
						<DraggableCard>
							<ColorIndicator type="group" />

							<CardHeader>
								<Icon
									svg={Svgs.ChevronDown}
									onClick={() => setGroupExpanded(!groupExpanded)}
									rotate={groupExpanded ? 180 : 0}
								/>
								<Icon
									svg={Svgs.Folder}
									size={s => s.m}
									colors={{
										color: Colors.groupOrange
									}}
									marginOffset={{ x: 0.8 }}
									propagate
								/>
								<Typography.Paragraph
									title={groupLabel}
									fontweight={w => w.medium}
									style={{ flex: 1 }}
									ellipsis
								>
									{groupLabel}
								</Typography.Paragraph>
							</CardHeader>
						</DraggableCard>

						{/* BODY */}
						{groupExpanded && (
							<Flex marginOffset={{ top: 0.8 }} column>
								<Gap marginGap={{ bottom: 0.8 }}>
									{variables.map((variable, index) => {
										const { label, draggableId } = variable;

										return (
											<Draggable
												key={draggableId}
												index={index}
												draggableId={draggableId}
												isDragDisabled={!writeAccess}
											>
												{(
													{ innerRef, draggableProps, dragHandleProps },
													snapshot
												) => (
													<>
														{label && (
															<div
																data-test-id={label}
																ref={innerRef}
																{...draggableProps}
																{...dragHandleProps}
																style={getCloneStyle(
																	draggableProps.style as DraggingStyle,
																	snapshot
																)}
															>
																<Flex>
																	<GroupedIconWrapper
																		isDragging={
																			snapshot.isDragging
																		}
																	>
																		<Icon
																			svg={
																				Svgs.ChevronArrowCorner
																			}
																			size={s => s.m}
																			paddingOffset={{
																				all: 0.8
																			}}
																		/>
																	</GroupedIconWrapper>

																	<TemplateVariable
																		variable={variable}
																		partOfGroup
																	/>
																</Flex>
															</div>
														)}

														{/* PLACEHOLDER WHEN DRAGGING */}
														{snapshot.isDragging && (
															<Flex>
																<GroupedIconWrapper
																	isDragging={false}
																>
																	<Icon
																		svg={
																			Svgs.ChevronArrowCorner
																		}
																		size={s => s.m}
																		paddingOffset={{
																			all: 0.8
																		}}
																	/>
																</GroupedIconWrapper>

																<DraggableCard
																	isDraggingSnapshot={false}
																	groupedVariable
																>
																	<Wrapper>
																		<CardHeader>
																			<Container>
																				<Icon
																					svg={
																						Svgs.ChevronDown
																					}
																					onClick={() =>
																						undefined
																					}
																				/>
																				<Typography.Paragraph
																					ellipsis
																				>
																					{label}
																				</Typography.Paragraph>
																			</Container>
																		</CardHeader>
																	</Wrapper>
																</DraggableCard>
															</Flex>
														)}
													</>
												)}
											</Draggable>
										);
									})}
								</Gap>
							</Flex>
						)}
					</Flex>

					{/* Hide placeholder for clonable elements */}
					<div style={{ visibility: 'hidden', height: 0, width: 0 }}>
						{provided.placeholder}
					</div>
				</div>
			)}
		</Droppable>
	);
}

import { AnalysisModal } from 'components/Analysis/Modals/AnalysisModal';
import { useVariablesPageModalsController } from 'pages/Variables/useVariablesPageModalsController';

import { Analysis } from './Analysis';

import { Container, List, AnalysisColumnContainer } from './AnalysisList.style';
import { NoAnalyses } from './NoAnalyses';
import { AnalysisContext } from 'contexts';
import { getHeaderHeight } from 'helpers/generic';
import { useNavigation } from 'hooks/navigation';
import {
	useAnalyses,
	useVariablesData,
	useVariablesTableCheckedData,
	useProjectId,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useVariablesDataSelectItems,
	useFilters
} from 'hooks/store';
import { withMemoOnce } from 'helpers/HOCs';

function Component() {
	const ids = useAnalyses();
	const variablesData = useVariablesData({ initial: true });
	const { checkedState } = useVariablesTableCheckedData();
	const {
		modals: { variableModal },
		modalsComponent
	} = useVariablesPageModalsController({ checkedState });
	const { routes, promOrProject, navigate } = useNavigation();
	const [projectId] = useProjectId();
	const [{ areFiltersOpen }] = useFilters();
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	function onImportDataset() {
		if (projectId) navigate(routes[promOrProject].import(projectId));
	}
	/*
	 * Compute here and pass through props to optmimize performance
	 * (avoid looping thorugh all variables where needed)
	 */
	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);

	function getClassName() {
		if (activeColumn === 2) {
			return 'two-column-view';
		} else if (activeColumn === 3) {
			return 'three-column-view';
		} else {
			return 'one-column-view';
		}
	}

	return (
		<Container filtersOpen={areFiltersOpen}>
			<List
				filtersOpen={areFiltersOpen}
				heightOffset={getHeaderHeight().rem}
				activeColumn={activeColumn}
			>
				{ids.map(id => (
					<AnalysisColumnContainer
						className={getClassName()}
						key={id}
						filtersOpen={areFiltersOpen}
						heightOffset={getHeaderHeight().rem}
						activeColumn={activeColumn}
					>
						<Analysis
							key={id}
							id={id}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
						/>
					</AnalysisColumnContainer>
				))}
				{!ids.length && (
					<NoAnalyses
						onAddVariable={variableModal.open}
						onImportDataset={onImportDataset}
						variablesDataSelectItems={variablesDataSelectItems}
					/>
				)}
			</List>
			{fullscreen && (
				<AnalysisContext.Provider value={{ analysisId: fullscreen }}>
					<AnalysisModal
						id={fullscreen}
						variablesData={variablesData}
						variablesDataSelectItems={variablesDataSelectItems}
					/>
				</AnalysisContext.Provider>
			)}
			{modalsComponent}
		</Container>
	);
}

export const AnalysisList = withMemoOnce(Component);

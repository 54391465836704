import { useProjectById } from './useProjectById';

export function useForbidPersonalData(projectId: string) {
	const project = useProjectById(projectId);

	const forbidPersonalData =
		!!project &&
		project.projectOwnerDetails?.organization === 'OUSHF' &&
		(!project.metadata || !project.metadata['archiveNumber']);

	return { forbidPersonalData };
}

import { isAfter, isBefore, isEqual, formatDistance } from 'date-fns';
import { DATE_TIME_FORMAT__NO_SECONDS } from 'consts';
import { Svgs } from 'environment';
import { selectDistributions } from 'store/data/patients';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Table } from 'components/UI/Table';
import { Icon } from 'components/UI/Icons';
import { formatAPIDate } from 'helpers/dateFormat';
import { getNumeralOrderShortEng } from 'helpers/displayers';
import { useTranslation, useForms } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useSelector } from 'hooks/utils';

export function DistributionsTable() {
	const { setNotification } = useAlerts();
	const { translate } = useTranslation();

	const [{ data: forms }] = useForms();

	const distributions = useSelector(state => selectDistributions(state.data.patients));

	function getDistributionTime(timeToSend: string) {
		return formatAPIDate(timeToSend, DATE_TIME_FORMAT__NO_SECONDS) as string;
	}

	function getAssignedFormName(formId?: string) {
		const formName = forms.find(f => f.id === formId)?.name;

		return formName ?? translate(dict => dict.promsPatients.distributionList.autoGeneratedForm);
	}

	function getSentStatus(timeToSend: string) {
		const distributionDate = new Date(timeToSend);

		const dateNow = new Date();
		// SET MINUTES, SECONDS AND MILLISECONDS TO 0 SO `isEqual` WILL WORK PROPERLY
		dateNow.setMinutes(0, 0, 0);

		if (isBefore(dateNow, distributionDate)) {
			return translate(dict => dict.promsPatients.distributionTable.shouldBeSentIn, false, {
				time: formatDistance(dateNow, distributionDate)
			});
		}

		if (isEqual(dateNow, distributionDate)) {
			return translate(dict => dict.promsPatients.distributionTable.shouldBeSentNow);
		}

		if (isAfter(dateNow, distributionDate)) {
			return translate(dict => dict.promsPatients.distributionTable.shouldHaveBeenSent);
		}
	}

	function handleCopyToClipboard(distributionLink: string) {
		navigator.clipboard.writeText(distributionLink);

		setNotification({
			message: translate(dict => dict.infoToolTip.copiedToClipBoard)
		});
	}

	return (
		<Flex marginOffset={{ bottom: 2.4 }} column fullWidth>
			<Typography.H6 marginOffset={{ bottom: 2 }}>
				{translate(dict => dict.promsPatients.distributionTable.timeline)}
			</Typography.H6>
			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					{/* HEAD */}
					<Table.Head>
						<Table.Row>
							<Table.Column>
								{translate(dict => dict.promsPatients.distributionTable.nr)}
							</Table.Column>
							<Table.Column>
								{translate(dict => dict.promsPatients.distributionTable.timeToSend)}
							</Table.Column>
							<Table.Column>
								{translate(
									dict => dict.promsPatients.distributionTable.assignedForm
								)}
							</Table.Column>
							<Table.Column>
								{translate(dict => dict.promsPatients.distributionTable.link)}
							</Table.Column>
							<Table.Column>
								{translate(dict => dict.promsPatients.distributionTable.status)}
							</Table.Column>
						</Table.Row>
					</Table.Head>

					{/* BODY */}
					<Table.Body>
						{distributions.map((distribution, index) => {
							const { link, timeToSend, formId: assignedFormId } = distribution;

							if (link === undefined || timeToSend === undefined) {
								return null;
							}

							const numeralOrder = getNumeralOrderShortEng(index + 1);
							const nth = `${index + 1}${numeralOrder}`;

							return (
								<Table.Row key={`distribution-${index}`}>
									<Table.Cell minWidth={5}>{nth}</Table.Cell>
									<Table.Cell minWidth={15}>
										{getDistributionTime(timeToSend)}
									</Table.Cell>
									<Table.Cell minWidth={15}>
										{getAssignedFormName(assignedFormId)}
									</Table.Cell>
									<Table.Cell>
										<Flex
											style={{ cursor: 'pointer' }}
											onClick={() => handleCopyToClipboard(link)}
										>
											<Icon
												svg={Svgs.Link}
												title={link}
												size={s => s.s}
												marginOffset={{ right: 0.8 }}
												onClick={() => handleCopyToClipboard(link)}
											/>
											<Typography.Paragraph>
												{translate(
													({ promsPatients }) =>
														promsPatients.distributionTable.copyLink
												)}
											</Typography.Paragraph>
										</Flex>
									</Table.Cell>
									<Table.Cell minWidth={25}>
										{getSentStatus(timeToSend)}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</Flex>
	);
}

import styled from 'styled-components';

interface Props {
	minWidth: boolean;
}

export const Container = styled.div<Props>`
	display: flex;
	/* flex-wrap: wrap; */
	align-items: center;
	justify-content: space-between;
	min-height: 7.2rem;
	box-shadow: 0 1px 1px 1px rgba(33, 33, 33, 0.05);

	/* SPACING LIKE GRID SYSTEM */
	padding-left: 4%;
	padding-right: 4%;

	@media print {
		display: none;
	}
`;

export const LeftComponent = styled.div`
	flex: 1;
`;

export const RightComponent = styled.div``;

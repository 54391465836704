import { entryFormChangePageSetEvent, entryFormChangePageEvent } from 'helpers/entries';
import { useSortFormErroredFields } from 'hooks/store';
import { FormFieldPageLocationByName } from 'store/data/entries';
import { Form } from 'store/data/forms';

export function useEntryGoToFirstFieldError(input: { setName?: string; form?: Form }) {
	const { setName } = input;

	const { sortFormErroredFields } = useSortFormErroredFields(input);

	// SCROLL INTO VIEW TO THE FIRST ERRORED INPUT
	function goToFirstFieldError(params: {
		fieldNames: string[];
		formFieldPageLocationByName: FormFieldPageLocationByName;
	}) {
		const { fieldNames, formFieldPageLocationByName } = params;

		const fieldNamesWithError = sortFormErroredFields(fieldNames);

		const firstFieldNameWithError = fieldNamesWithError[0];

		if (firstFieldNameWithError) {
			const isPaginated = firstFieldNameWithError in formFieldPageLocationByName;

			if (isPaginated) {
				const pageInfo = formFieldPageLocationByName[firstFieldNameWithError];

				const { pageIndex, group } = pageInfo;

				// SET PAGE WHERE 1ST ERROR FIELD IS
				const changePageEvent = setName
					? entryFormChangePageSetEvent
					: entryFormChangePageEvent;

				changePageEvent().dispatch({ pageIndex });

				// WAIT UNTIL PAGE WAS SET
				setTimeout(() => scrollToField(firstFieldNameWithError, group), 50);
			} else {
				// Debounce in JS call stack
				setTimeout(() => scrollToField(firstFieldNameWithError), 50);
			}
		}
	}

	function scrollToField(fieldName: string, group?: { groupName: string }) {
		const element = document.querySelector(`[id="${fieldName}"]`);

		if (element) return element.scrollIntoView({ block: 'center' });

		if (group) {
			const groupElement = document.querySelector(`[id="${group.groupName}"]`);

			if (groupElement) groupElement.scrollIntoView({ block: 'center' });
		}
	}

	return { goToFirstFieldError };
}

import { useEffect, useState } from 'react';
import {
	BillingInformation,
	BillingHistoryList,
	BillingAddressModal,
	ChangePlanModal
} from 'components/Account';
import { WindowDOMwithChargebee } from 'store/account/payments';
import { Header } from 'components/Header';
import { Grid } from 'components/UI/Grid';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import {
	useSubscription,
	useTranslation,
	useCBInstance,
	useAccount,
	useSubscriptionPayments,
	useBillingInfo,
	useSubscriptionRules
} from 'hooks/store';
import { useEffectOnce, usePrevious } from 'hooks/utils';
import { useMatchEnterpriseAdmin, useNavigation } from 'hooks/navigation';
import { useLastSubscriptionPayment } from 'hooks/store/account/payments/useLastSubscriptionPayment';

export function AccountUMBillingPage() {
	const chargebeeSite = process.env.REACT_APP_CHARGEBEE_DATA_CB_SITE;
	const chargebeeKey = process.env.REACT_APP_CHARGEBEE_DATA_PUBLISH_API_KEY;
	const [
		{
			data: {
				details,
				subscriptionTypes: { isLedidiFree, isLedidiFreeTrial, isNonSubscriber }
			},

			loading: subscriptionLoading,
			fetched: subscriptionFetched
		}
	] = useSubscription();

	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();

	const [showBillingAddressModal, setShowBillingAddressModal] = useState(false);
	const [changePlanModalVisible, setChangePlanModalVisible] = useState(false);

	const [
		{ data: isChargebeeLoaded, loading: checkingChargebeeInstanceLoaded },
		setChargebeeIsLoaded
	] = useCBInstance();

	const [{ loading: accountLoading, fetched: accountFetched }] = useAccount();

	const { canChangePlan, isSubscriptionUpdating, showEnterpriseAdminRoute } =
		useSubscriptionRules();

	const [{ loading: paymentsLoading, fetched: paymentsFetched, data: payments }, getPayments] =
		useSubscriptionPayments(true);

	const [
		{ loading: billingInfoLoading, fetched: billingInfoFetched, data: billingInfo },
		getBillingInfo
	] = useBillingInfo(true);

	const [{ data: lastPayment }] = useLastSubscriptionPayment();

	const isOnEnterpriseAdminPage = useMatchEnterpriseAdmin();

	useEffectOnce(() => {
		getPayments();
		getBillingInfo();
		if (!isChargebeeLoaded && !checkingChargebeeInstanceLoaded) {
			(window as WindowDOMwithChargebee).Chargebee?.init({
				site: chargebeeSite, // test site
				publishableKey: chargebeeKey
			});
			setChargebeeIsLoaded();
		}
	});

	useEffect(() => {
		if (showEnterpriseAdminRoute && !isOnEnterpriseAdminPage) {
			navigate(routes.enterpriseAdmin.subscription);
		}
	}, [showEnterpriseAdminRoute, isOnEnterpriseAdminPage]);

	const wasLoadingSubscription = usePrevious(subscriptionLoading);
	useEffect(() => {
		if (
			!isLedidiFree &&
			!isLedidiFreeTrial &&
			!isNonSubscriber &&
			!subscriptionLoading &&
			wasLoadingSubscription
		) {
			getBillingInfo();
		}
	}, [subscriptionLoading]);

	function onShowBillingAddressModal() {
		setShowBillingAddressModal(true);
	}

	function onCloseBillingAddressModal() {
		setShowBillingAddressModal(false);
	}

	return (
		<>
			<Header.Main
				rightComponent={null}
				leftComponent={translate(({ accountUM }) => accountUM.subscriptionManagementTitle)}
			/>
			<Header.Navigation
				rightComponent={
					canChangePlan ? (
						<Button
							hasPointer
							title={translate(({ accountUM }) => accountUM.changePlan.changePlan)}
							disabled={isSubscriptionUpdating}
							onClick={() => setChangePlanModalVisible(true)}
						/>
					) : undefined
					// 	<ChargebeeButton
					// 	onWaitingModalConfirmed={() => {
					// 		getPaymentsAndBillingInfo();
					// 	}}
					// />
				}
			/>
			<Header.Title title={translate(({ accountUM }) => accountUM.tabs.billingHistory)} />

			<Suspend
				loading={
					accountLoading ||
					subscriptionLoading ||
					(paymentsLoading && !paymentsFetched) ||
					(billingInfoLoading && !billingInfoFetched)
				}
				immediate={
					!accountFetched ||
					!subscriptionFetched ||
					!paymentsFetched ||
					!billingInfoFetched
				}
			>
				<Grid.Container>
					<Spacer size={s => s.m} />
					{billingInfo && details && (
						<BillingInformation
							data={billingInfo}
							dueDateAt={
								lastPayment && lastPayment.invoiceDueDate
									? lastPayment.invoiceDueDate
									: undefined
							}
							licenceQuantity={details.licenceQuantity ?? 0}
							licenceQuantities={details.licenceQuantities}
							isLedidiFree={isLedidiFree}
							isSubscriptionUpdating={isSubscriptionUpdating}
							onChangeClicked={onShowBillingAddressModal}
						/>
					)}
					<Spacer size={s => s.m} />
					{payments && payments.length > 0 && (
						<BillingHistoryList subscriptionPayments={payments} />
					)}
				</Grid.Container>
			</Suspend>

			{billingInfo && showBillingAddressModal && (
				<BillingAddressModal
					billingAddress={billingInfo.billingAddress}
					primaryPaymentSourceAddress={billingInfo.primaryPaymentSourceAddress}
					onClose={onCloseBillingAddressModal}
				/>
			)}
			{changePlanModalVisible && canChangePlan && (
				<ChangePlanModal onClose={() => setChangePlanModalVisible(false)} />
			)}
		</>
	);
}

import { useState, useEffect, useMemo } from 'react';
import { isBefore, isAfter, isEqual, parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import { ProjectFile } from 'api/data/documents';
import { DATE_FORMAT, DATE_FORMAT_COMMA, DEFAULT_TABLE_PAGE_SIZE } from 'consts';
import { Colors, Svgs } from 'environment';
import {
	DateFilter as DateFilterInterface,
	DateFilterOperator,
	setFileToViewAction,
	ProjectFilesListFilterNames
} from 'store/data/documents';
import { FilterByTypeComponent, TableName } from 'types/index';
import { DateFilter, CheckboxFilter } from './Filters';
import { DeleteDocumentModal } from '../Modals/DeleteDocumentModal';
import { PreviewFileModal } from '../Modals/PreviewModal/PreviewFileModal';
import { FileNameContainer, FileTypeButtons } from './DocumentsList.style';
import { Table } from 'components/UI/Table';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { formatAPIDate } from 'helpers/dateFormat';
import { downloadFileFromUrl } from 'helpers/generic';
import {
	useTranslation,
	useDocumentsSearchTerm,
	useProjectId,
	useDocumentsListFilters,
	useTableSort,
	useProjectDocumentById,
	useFilterBySearchTerm,
	useTablePagination
} from 'hooks/store';
import { useDispatch, usePrevious } from 'hooks/utils';

enum Columns {
	fileName = 'fileName',
	ownerName = 'username',
	createdDate = 'creationDate',
	size = 'fileSize',
	type = 'mimeType'
}

interface Props {
	documents: ProjectFile[];
}

function formatFileSize(fileSize: number) {
	if (fileSize === 0) return '0 B';

	const power = Math.floor(Math.log(fileSize) / Math.log(1024));

	return (
		parseFloat((fileSize / Math.pow(1024, power)).toFixed(0)) +
		' ' +
		['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][power]
	);
}

export function DocumentsList({ documents }: Props) {
	const { translate } = useTranslation();

	const [searchTerm] = useDocumentsSearchTerm();

	const [projectId] = useProjectId();

	const [filters, { setFilter, setDateFilter, resetFilter }] = useDocumentsListFilters();

	const [documentsList, setDocumentsList] = useState<ProjectFile[]>([]);

	const { activeSort, handleSort, sortIcon } = useTableSort(TableName.Documents);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [fileIdToDelete, setFileIdToDelete] = useState('');
	const [isPreviewWindowOpen, setIsPreviewWindowOpen] = useState(false);
	const [openFilter, setOpenFilter] = useState<string | null>(null);

	const file = useProjectDocumentById(fileIdToDelete);

	const dispatch = useDispatch();

	const filteredDocumentsBySearchTerm = useFilterBySearchTerm(
		documents,
		FilterByTypeComponent.Documents
	) as ProjectFile[];

	useEffect(() => {
		setDocumentsList(filteredDocumentsBySearchTerm);
	}, [filteredDocumentsBySearchTerm]);

	const prevSearchTerm = usePrevious(searchTerm);
	useEffect(() => {
		const termChanged = prevSearchTerm !== undefined && prevSearchTerm !== searchTerm;
		if (termChanged) resetPageIndex();
	}, [searchTerm]);

	const prevActiveSort = usePrevious(activeSort);
	useEffect(() => {
		const sortChanged = prevActiveSort !== undefined && activeSort !== prevActiveSort;
		if (sortChanged) {
			resetPageIndex();
		}
	}, [activeSort]);

	function handleFilter(filterName: ProjectFilesListFilterNames, value: string) {
		resetPageIndex();
		setFilter({ filterName, value });
	}

	function handleResetFilter(filterName: ProjectFilesListFilterNames) {
		resetPageIndex();
		resetFilter({ filterName });
	}

	function handleDateFilter(
		filterName: ProjectFilesListFilterNames,
		filter: DateFilterInterface
	) {
		resetPageIndex();
		setDateFilter({ filterName, filter });
	}

	function handleSelect(fileId: string) {
		if (projectId) {
			dispatch(setFileToViewAction({ fileId }));
			setIsPreviewWindowOpen(true);
		}
	}

	const documentsListWithFilters = useMemo(() => {
		const { type, uploadDate } = filters;

		let filteredArray = [...documentsList];

		if (type.active.length) {
			filteredArray = filteredArray.filter(({ mimeType }) => type.active.includes(mimeType));
		}

		if (uploadDate.valid) {
			filteredArray = filteredArray.filter(({ creationDate }) => {
				const formattedDate = formatAPIDate(creationDate, DATE_FORMAT) as string;

				const { operator, value, from, to } = uploadDate;

				if (operator === DateFilterOperator.Between) {
					return (
						isAfter(parseISO(formattedDate), parseISO(from as string)) &&
						isBefore(parseISO(formattedDate), parseISO(to as string))
					);
				}
				if (operator === DateFilterOperator.Exact) {
					return isEqual(parseISO(formattedDate), parseISO(value as string));
				}
				if (operator === DateFilterOperator.Before) {
					return isBefore(parseISO(formattedDate), parseISO(value as string));
				}
				if (operator === DateFilterOperator.After) {
					return isAfter(parseISO(formattedDate), parseISO(value as string));
				}

				return false;
			});
		}

		return filteredArray;
	}, [documentsList, filters]);

	const documentsToShow = useMemo(
		() =>
			activeSort
				? orderBy(documentsListWithFilters, activeSort.column, activeSort.order)
				: documentsListWithFilters,
		[documentsListWithFilters, activeSort]
	);

	// PAGINATION

	const {
		paginatedItems: paginatedDocuments,
		pageSize,
		pageIndex,
		pagesCount,
		setPageSize,
		setPageIndex,
		resetPageIndex
	} = useTablePagination(documentsToShow, TableName.Documents, DEFAULT_TABLE_PAGE_SIZE);

	return (
		<>
			<Pagination
				totalCount={documents.length}
				totalCountLabel={translate(dict => dict.pagination.files)}
				filteredCount={paginatedDocuments.length}
				pageIndex={pageIndex}
				pageSize={pageSize ?? 0}
				pagesCount={pagesCount}
				changePage={setPageIndex}
				changePageSize={setPageSize}
			/>
			<Spacer size={s => s.xs} />

			<Table.Responsive fullHeight>
				<Table hoverEffect fullWidth stickyHead>
					<Table.Head>
						<Table.Row clickable>
							<Table.Column
								minWidth={40}
								onClick={() => handleSort(Columns.fileName)}
								style={{ zIndex: 10 }}
							>
								{translate(({ documents }) => documents.list.columns.name)}
								{sortIcon(Columns.fileName)}
							</Table.Column>
							<Table.Column
								minWidth={10}
								onClick={() => handleSort(Columns.ownerName)}
							>
								{translate(({ documents }) => documents.list.columns.owner)}
								{sortIcon(Columns.ownerName)}
							</Table.Column>
							<Table.Column
								minWidth={10}
								onClick={() => handleSort(Columns.createdDate)}
								filter={
									<DateFilter
										title={translate(dict => dict.terms.createdDate)}
										width={30}
										filter={filters.uploadDate}
										filterName={ProjectFilesListFilterNames.UploadDate}
										onOpen={() => setOpenFilter(Columns.createdDate)}
										onClose={() => setOpenFilter(null)}
										handleDateFilter={handleDateFilter}
										handleResetFilter={handleResetFilter}
									/>
								}
								showFilter={
									filters.uploadDate.valid || openFilter === Columns.createdDate
								}
							>
								{translate(({ documents }) => documents.list.columns.date)}
								{sortIcon(Columns.createdDate)}
							</Table.Column>
							<Table.Column minWidth={10} onClick={() => handleSort(Columns.size)}>
								{translate(({ documents }) => documents.list.columns.fileSize)}
								{sortIcon(Columns.size)}
							</Table.Column>
							<Table.Column
								minWidth={13}
								onClick={() => handleSort(Columns.type)}
								style={{ zIndex: 10 }}
								filter={
									<CheckboxFilter
										title={translate(dict => dict.terms.types)}
										width={20}
										offset={{ top: 20, left: -160 }}
										filter={filters.type}
										filterName={ProjectFilesListFilterNames.Types}
										onOpen={() => setOpenFilter(Columns.type)}
										onClose={() => setOpenFilter(null)}
										handleFilter={handleFilter}
										handleResetFilter={handleResetFilter}
									/>
								}
								showFilter={
									filters.type.active.length > 0 || openFilter === Columns.type
								}
							>
								{translate(({ documents }) => documents.list.columns.fileType)}
								{sortIcon(Columns.type)}
							</Table.Column>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{paginatedDocuments.map(
							(
								{
									username,
									creationDate,
									fileSize,
									fileName,
									fileLabel,
									mimeType,
									fileId,
									signedS3Url
								},
								rowIndex: any
							) => (
								<Table.Row
									key={`row-${rowIndex}`}
									onClick={() => handleSelect(fileId)}
									css={`
										:hover .icons-container {
											visibility: visible;
										}
									`}
									clickable
								>
									<Table.Cell
										style={{ minHeight: '4rem' }}
										minWidth={30}
										width={40}
									>
										<FileNameContainer>
											<Icon
												svg={Svgs.File}
												size={s => s.m}
												marginOffset={{ right: 0.8 }}
												colors={{
													color: Colors.text.disabled
												}}
											/>
											<Typography.Paragraph style={{ fontWeight: 500 }}>
												{fileLabel}.{mimeType}
											</Typography.Paragraph>
										</FileNameContainer>
									</Table.Cell>
									<Table.Cell minWidth={20} width={20}>
										{username}
									</Table.Cell>
									<Table.Cell minWidth={20} width={20}>
										{formatAPIDate(creationDate, DATE_FORMAT_COMMA) as string}
									</Table.Cell>
									<Table.Cell minWidth={15} width={20}>
										{formatFileSize(fileSize)}
									</Table.Cell>
									<Table.Cell width={20} minWidth={16}>
										<Flex align={a => a.center} justify={j => j.between}>
											{mimeType}

											{/* ACTION BUTTONS */}
											<FileTypeButtons className="icons-container">
												<Icon
													svg={Svgs.Delete}
													size={s => s.m}
													marginOffset={{
														right: 2.4
													}}
													onClick={e => {
														// PREVENT ROW CLICK
														e.stopPropagation();

														setFileIdToDelete(fileId);
														setDeleteModalVisible(true);
													}}
												/>
												<Typography.Paragraph
													fontweight={w => w.medium}
													color={Colors.primary.normal}
													onClick={e => {
														// PREVENT ROW CLICK
														e.stopPropagation();

														downloadFileFromUrl(
															signedS3Url,
															fileName,
															true
														);
													}}
												>
													{translate(dict => dict.terms.download)}
												</Typography.Paragraph>
											</FileTypeButtons>
										</Flex>
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>
			</Table.Responsive>

			{deleteModalVisible && file && (
				<DeleteDocumentModal
					file={file}
					onClose={() => {
						setDeleteModalVisible(false);
						setFileIdToDelete('');
					}}
				/>
			)}

			{isPreviewWindowOpen && (
				<PreviewFileModal onClose={() => setIsPreviewWindowOpen(false)} />
			)}
		</>
	);
}

import { withReset } from 'store/hors';
import initialState from './initialState';
import reducer from './reducer';

export * from './actions';
export * from './selectors';
export * from './types';
export * from './utils';

const enhancedReducer = withReset(reducer, initialState);

export { enhancedReducer as reducer };

import { useState, useCallback } from 'react';
import QRCode from 'qrcode.react';
import * as yup from 'yup';
import { HelpSetupOneTimePassword } from 'components/Login';
import { Title } from 'components/Login/styles';
import { Colors } from 'environment';
import { sanatizeCode } from 'helpers/auth';
import { setOneTimePassword } from 'store/auth';
import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useUsername,
	useOneTimePassword,
	useVerifyOneTimePassword,
	useAccount
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useDispatch, useEffectOnce } from 'hooks/utils';

function computeQrCode(username: string | null = '', code: string | null = '') {
	return `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=ledidi`;
}

interface Props {
	handleBack: () => void;
}

export function SetupOneTimePasswordForm({ handleBack }: Props) {
	const { translate } = useTranslation();
	const dispatch = useDispatch();

	const username = useUsername();
	const otp = useOneTimePassword();

	const [
		{ loading: loadingVerifyOtp, error: errorVerifyOtp },
		{ handler: verifyOneTimePassword, resetError: resetOtpError }
	] = useVerifyOneTimePassword();
	const [{ loading: loadingGetAccount, error: errorGetAccount }] = useAccount();

	const loading = loadingVerifyOtp || loadingGetAccount;
	const error = errorVerifyOtp || errorGetAccount;

	const [help, setHelp] = useState(false);

	useEffectOnce(() => {
		if (!otp.enabled) dispatch(setOneTimePassword());
	});

	const validationSchema = yup.object({
		code: yup.string().required().min(6)
	});

	const { Form, register, setValue, handleSubmit, isDirty } = useReactForm({
		initialValues: { code: '' },
		validationSchema
	});

	const handleFormSubmit = handleSubmit(({ code }) => {
		if (isDirty && !error && !loading && !otp.enabled) verifyOneTimePassword(code);
	});

	function handleClearError() {
		if (errorVerifyOtp) resetOtpError();
	}

	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			handleClearError();

			setValue('code', sanatizeCode(e.target.value), {
				shouldValidate: true
			});
		},
		[errorVerifyOtp]
	);

	const inputError = errorVerifyOtp ? translate(dict => dict.errors.api.login.setupMFA) : '';

	return (
		<Form onSubmit={handleFormSubmit}>
			<Title>{translate(dict => dict.codeForm.title)}</Title>

			<Typography.Paragraph color={Colors.text.hint}>
				{translate(dict => dict.security.otpInstructions)}
			</Typography.Paragraph>

			<Spacer size={s => s.m} />

			<Flex justify={j => j.center}>
				<QRCode value={computeQrCode(username, otp.secret)} />
			</Flex>

			<Spacer size={s => s.m} />

			<Input
				{...register('code', {
					onChange: handleOnChange
				})}
				type={InputType.Text}
				label="MFA Authentication Code"
				placeholder={translate(dict => dict.security.codePlaceholder)}
				error={inputError}
				autoComplete="off"
				autoFocus
			/>

			<Spacer size={s => s.m} />

			<Button
				type="submit"
				title="Activate Account"
				loading={loading}
				hasPointer={!loading}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Button
				variant={v => v.outline}
				title={translate(dict => dict.buttons.goBack)}
				onClick={loading ? undefined : handleBack}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Flex justify={j => j.center}>
				<Typography.Paragraph
					color={Colors.text.caption}
					onClick={() => setHelp(true)}
					style={{ cursor: 'pointer' }}
				>
					{translate(dict => dict.security.helpText)}
				</Typography.Paragraph>
			</Flex>

			{/* HELP SCREEN */}
			{help && <HelpSetupOneTimePassword onClose={() => setHelp(false)} />}
		</Form>
	);
}

import { orderBy } from 'lodash';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { DEFAULT_TABLE_PAGE_SIZE, DATE_FORMAT } from 'consts';
import { Colors, Svgs } from 'environment';
import { isFilterActive } from 'helpers/table';
import {
	type InitFiltersDataByColumn,
	useTableFilters
} from 'hooks/store/ui/tables/useTableFilters';
import {
	TableName,
	TableFilterType,
	type CustomisableTableColumn,
	type CustomCellType,
	type ExtraCellsType,
	type CustomisableTableRow
} from 'types/index';
import type { TableFilter as FilterProps } from 'store/ui/tables/types';
import {
	IconsContainer,
	Wrapper,
	DropdownScrollContainer,
	WrapperDiv,
	ControlsWrapper
} from './CustomisableTable.style';
import { TableFilter } from '../TableFilter';
import { Flex } from '../Flex';
import { Pagination } from '../Pagination';
import { Typography } from '../Typography';
import { Dropdown } from '../Dropdown';
import { Icon } from '../Icons';
import { Checkbox } from '../Interactables/Checkbox';
import { Table } from '../Table';
import { Spacer } from '../Spacer';
import {
	useTranslation,
	useFilteredItems,
	useTableSort,
	useTablePagination,
	useVisibleColumns
} from 'hooks/store';
import { usePrevious } from 'hooks/utils';
import { DataTestId } from 'tests/consts';

interface CellProps {
	minWidth?: number;
	width?: number;
	maxWidth?: number;
	noWrap?: boolean;
}

interface StoreTableProps {
	tableName: TableName;
	items: CustomisableTableRow[];
	columns: CustomisableTableColumn[];
	itemsCountLabel?: string;
	customCells?: CustomCellType;
	extraCells?: ExtraCellsType;
	activeExtraCell?: string;
	initVisibleColumns?: string[];
	hasPagination?: boolean;
	cellProps?: CellProps;
	filterDateFormat?: string;
	loading?: boolean;
	selectableRows?: string[]; // rowsIds
	selectedIds?: string[]; // selectedIds from Parent Component
	noItemsMessage?: string;
	renderControlsHeader?: {
		leftNode?: React.ReactNode;
		rightNode?: React.ReactNode;
	};
	onRowClick?: (rowId: string) => void;
	onRowsSelect?: (rowIds: string[]) => void;
}

/**
 *
 * @param items: all elements in table, actual values used for SORTING and FILTERING
 * @param columns: column name and column label
 * @param itemsCountLabel: label used to show items count number
 * @param customCells: custom cells by row id and column name
 * @param extraCells: custom buttons/containers by row id are shown at the end of each row
 */
export function CustomisableTable({
	tableName,
	items,
	columns,
	itemsCountLabel,
	customCells,
	extraCells,
	activeExtraCell,
	initVisibleColumns,
	hasPagination,
	cellProps = {},
	filterDateFormat = DATE_FORMAT,
	selectableRows,
	loading,
	noItemsMessage,
	renderControlsHeader = undefined,
	selectedIds = [],
	onRowClick,
	onRowsSelect
}: StoreTableProps) {
	const { translate } = useTranslation();

	// --- FILTERS ---

	/**
	 * Returns a string array with all the unique values found on a specific column of the table.
	 * Used in order to create Checkbox filters
	 */
	const getUniqueValuesForColumn = useCallback(
		(columnName: string) => {
			const uniqueValues: string[] = [];
			items.forEach(item => {
				if (item[columnName] && !uniqueValues.includes(item[columnName].toString()))
					uniqueValues.push(item[columnName].toString());
			});
			return uniqueValues;
		},
		[items]
	);

	/**
	 * Returns a Map with filters data by column name
	 * Filters data contains:
	 * 		- filter type and
	 * 		- checkbox values & hasSearch flag in case filter is a CheckboxFilter
	 */
	const defaultFiltersByColumn = useMemo(() => {
		const initFiltersDataByColumn: InitFiltersDataByColumn = {};
		columns.forEach(col => {
			if (col.filterType)
				initFiltersDataByColumn[col.name] = {
					filterType: col.filterType,
					checkboxes:
						col.filterType === TableFilterType.Checkbox
							? getUniqueValuesForColumn(col.name)
							: undefined,
					filterDateFormat,
					hasSearch: col.hasCheckboxSearch
				};
		});
		return initFiltersDataByColumn;
	}, [columns, getUniqueValuesForColumn]);

	const { filters, updateFilter, resetFilter } = useTableFilters(
		tableName,
		defaultFiltersByColumn
	);

	const [openFilter, setOpenFilter] = useState<string | null>(null);

	const { filteredItems } = useFilteredItems(items, tableName);

	// --- SORTING ---

	const { activeSort, handleSort, sortIcon } = useTableSort(tableName);

	const sortedItems = useMemo(
		() =>
			activeSort
				? orderBy(filteredItems, activeSort.column, activeSort.order)
				: filteredItems,
		[filteredItems, activeSort]
	);

	// --- PAGINATION ---

	const {
		paginatedItems,
		pageSize,
		pageIndex,
		pagesCount,
		setPageSize,
		setPageIndex,
		resetPageIndex
	} = useTablePagination(
		sortedItems,
		tableName,
		hasPagination ? DEFAULT_TABLE_PAGE_SIZE : undefined
	);

	// reset pagination on sort change
	const prevActiveSort = usePrevious(activeSort);
	useEffect(() => {
		const sortChanged = prevActiveSort !== undefined && activeSort !== prevActiveSort;
		if (sortChanged) {
			resetPageIndex();
		}
	}, [activeSort]);

	//reset pagination on filters change
	useEffect(() => {
		resetPageIndex();
	}, [filters]);

	// CUSTOMIZABLE TABLE

	const hasCustomisableColumns = useMemo(
		() => !!columns.find(column => column.isOptional),
		[columns]
	);

	const initialVisibleColumns = useMemo(() => {
		if (initVisibleColumns && hasCustomisableColumns) return initVisibleColumns;
		else {
			const allColumns: string[] = [];
			columns.forEach(col => allColumns.push(col.name));
			return allColumns;
		}
	}, [initVisibleColumns, columns, hasCustomisableColumns]);

	const { visibleColumns, toggleColumnVisibility, isColumnVisible } = useVisibleColumns(
		tableName,
		initialVisibleColumns
	);

	// COMPONENTS & UTILS FUNCTIONS

	/**
	 * Calculates open filter offset based on how much space it has if it's close to margins
	 */
	const filterOffset = useCallback(
		(columnName: string) => {
			const visibleColumnsList: string[] = [];
			columns.forEach(col => {
				if (!visibleColumns || visibleColumns.includes(col.name))
					visibleColumnsList.push(col.name);
			});

			const colIndex = visibleColumnsList.indexOf(columnName);
			const lastColIndex = visibleColumnsList.length - 1;

			let rightOffest = 50;

			const colWidth = cellProps.width ? cellProps.width * 10 : 350;

			const filterDivWidth = filterWidth(
				filters[columnName] ? filters[columnName].filterType : undefined
			);

			if (colIndex === lastColIndex) rightOffest = 10;
			if (colIndex === lastColIndex - 1 && colWidth < rightOffest) rightOffest = colWidth;
			if (colIndex === 0 && colWidth < filterDivWidth - rightOffest)
				rightOffest = filterDivWidth - colWidth;

			return { top: 10, right: rightOffest };
		},
		[columns, cellProps]
	);

	function getLastVisibleColumnIndex() {
		return visibleColumns?.reduce((lastIndex, columnName) => {
			const columnIndex = columns.findIndex(column => column.name === columnName);
			return columnIndex > lastIndex ? columnIndex : lastIndex;
		}, -1);
	}

	const getFilterComponent = useCallback(
		(columnName: string, columnLabel: string) => {
			const lastVisibleColumnIndex = getLastVisibleColumnIndex();
			const isLastColumn = lastVisibleColumnIndex
				? columns[lastVisibleColumnIndex].name === columnName
				: false;

			return filters[columnName] ? (
				<TableFilter
					title={columnLabel}
					width={filterWidth(filters[columnName].filterType)}
					offset={filterOffset(columnName)}
					filter={filters[columnName]}
					isLastColumn={isLastColumn}
					onOpen={() => setOpenFilter(columnName)}
					onClose={() => setOpenFilter(null)}
					updateFilter={(filter: FilterProps) => updateFilter(filter, columnName)}
					resetFilter={() => resetFilter(columnName)}
				/>
			) : undefined;
		},
		[filters, filterOffset, visibleColumns]
	);

	const showFilter = useCallback(
		(columnName: string) => {
			if (openFilter === columnName) return true;

			if (filters[columnName] && isFilterActive(filters[columnName])) {
				return true;
			}

			return false;
		},
		[filters, openFilter]
	);

	function filterWidth(type?: TableFilterType) {
		if (type === TableFilterType.Date) return 30;
		else return 25;
	}

	// HANDLING SELECTABLE ROWS

	const [selectedRows, setSelectedRows] = useState<string[]>(selectedIds);

	function handleRowSelect(rowId: string) {
		let newSelection = [];
		if (selectedRows.includes(rowId)) {
			newSelection = selectedRows.filter(s => s !== rowId);
		} else {
			newSelection = selectedRows.concat(rowId);
		}
		setSelectedRows(newSelection);
		if (onRowsSelect) onRowsSelect(newSelection);
	}

	function handleSelectAll() {
		if (selectableRows) {
			let newSelection: string[] = [];
			// if all are selected , then deselect all
			if (selectedRows.length === selectableRows.length) newSelection = [];
			else newSelection = selectableRows; // otherwise, select all

			setSelectedRows(newSelection);
			if (onRowsSelect) onRowsSelect(newSelection);
		}
	}

	// LOADING PLACEHOLDERS

	const loadingRows = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

	function generateLoadingRow(rowIndex: number) {
		return (
			<Table.Row
				data-testid={`complex-table-row-${rowIndex}`}
				key={`row-${rowIndex}`}
				className="table-row"
			>
				<Table.Cell
					height={3.5}
					relativePosition
					colSpan={visibleColumns?.length}
				></Table.Cell>
			</Table.Row>
		);
	}

	function generateNoItemsMessage() {
		return (
			<Typography.H4 marginOffset={{ top: 2 }} style={{ width: '100%' }} alignCenter>
				{noItemsMessage}
			</Typography.H4>
		);
	}

	const customisableColumns = useCallback(() => {
		return (
			<>
				{hasCustomisableColumns && (
					<WrapperDiv
						marginOffset={{ left: hasPagination && !renderControlsHeader ? 1 : 0 }}
					>
						<Dropdown
							width={20}
							offset={{ top: 12, right: 0 }}
							toggleComponent={({ toggle, ref }) => (
								<div ref={ref}>
									<Flex style={{ cursor: 'pointer' }} onClick={toggle}>
										{renderControlsHeader ? (
											<Svgs.Settings
												style={{
													scale: '0.88'
												}}
											/>
										) : (
											<>
												<Typography.Caption
													color={Colors.primary.normal}
													fontweight={w => w.medium}
												>
													{translate(
														dict => dict.tableLists.customiseTable
													)}
												</Typography.Caption>
												<Icon
													svg={Svgs.ArrowDown}
													size={s => s.m}
													active
													marginOffset={{ left: 1 }}
													propagate
												/>
											</>
										)}
									</Flex>
								</div>
							)}
						>
							<DropdownScrollContainer>
								{columns.map((column, index) => (
									<Dropdown.Item key={index} disabled={!column.isOptional}>
										<Checkbox
											label={column.label}
											checked={
												!!visibleColumns &&
												visibleColumns.includes(column.name)
											}
											onClick={() => toggleColumnVisibility(column.name)}
										/>
									</Dropdown.Item>
								))}
							</DropdownScrollContainer>
						</Dropdown>
					</WrapperDiv>
				)}
			</>
		);
	}, [visibleColumns, columns, renderControlsHeader]);

	return (
		<>
			<Flex align={a => a.center}>
				{/* pagination or display of items number */}
				{!renderControlsHeader && (
					<WrapperDiv flex={1}>
						{hasPagination ? (
							<Pagination
								totalCount={filteredItems.length}
								totalCountLabel={itemsCountLabel}
								filteredCount={paginatedItems.length}
								pageIndex={pageIndex}
								pageSize={pageSize ?? 0}
								pagesCount={pagesCount}
								changePage={setPageIndex}
								changePageSize={setPageSize}
							/>
						) : (
							<Typography.Caption>
								{translate(dict => dict.tableLists.showing)} {paginatedItems.length}
								{itemsCountLabel}
							</Typography.Caption>
						)}
					</WrapperDiv>
				)}
				{/* customise table */}
				{!renderControlsHeader ? customisableColumns() : null}
			</Flex>
			<Spacer size={s => s.xs} />

			<Wrapper>
				{renderControlsHeader && (
					<ControlsWrapper>
						{renderControlsHeader.leftNode ? (
							renderControlsHeader.leftNode
						) : selectedRows.length ? (
							<Typography.Caption>
								{translate(
									({ enterpriseAdmin }) => enterpriseAdmin.projects.selected,
									false,
									{ selected: selectedRows.length }
								)}
							</Typography.Caption>
						) : (
							<div />
						)}
						<Flex align={a => a.center}>
							{renderControlsHeader.rightNode}
							{/* Settings Icon for customise table columns */}
							{customisableColumns()}
						</Flex>
					</ControlsWrapper>
				)}
				{!loading && !paginatedItems.length && noItemsMessage ? (
					generateNoItemsMessage()
				) : (
					<Table.Responsive fullHeight headerOverflow={!!paginatedItems.length}>
						<Table hoverEffect fullWidth stickyHead isLoading={loading}>
							<Table.Head>
								<Table.Row>
									{
										// Show checkbox if there are selectableRows
										selectableRows && selectableRows.length ? (
											<Table.Column key={'selectableColumn'}>
												<Checkbox
													checked={
														selectableRows.length ===
														selectedRows.length
													}
													onClick={() => handleSelectAll()}
												/>
											</Table.Column>
										) : null
									}

									{columns.map(column => {
										const hasSort = !column.disableSort;
										return isColumnVisible(column.name) ? (
											<Table.Column
												key={column.name}
												data-testid={
													DataTestId.CustomisableColumn + column.name
												}
												onClick={() => hasSort && handleSort(column.name)}
												filter={getFilterComponent(
													column.name,
													column.label
												)}
												showFilter={showFilter(column.name)}
												clickable={hasSort}
											>
												{column.label}
												{hasSort && sortIcon(column.name)}
											</Table.Column>
										) : undefined;
									})}

									{/* ACTION BUTTONS EMPTY COLUMNS*/}
									{extraCells && <Table.Column empty />}
								</Table.Row>
							</Table.Head>

							{!loading ? (
								<Table.Body>
									{paginatedItems.map((row, rowIndex) => {
										return (
											<Table.Row
												data-testid={`complex-table-row-${rowIndex}`}
												key={`row-${rowIndex}`}
												onClick={() =>
													onRowClick
														? onRowClick(row.id.toString())
														: selectableRows?.includes(
																row.id.toString()
														  )
														? handleRowSelect(row.id.toString())
														: undefined
												}
												clickable={
													!!onRowClick ||
													selectableRows?.includes(row.id.toString())
												}
												className="table-row"
											>
												{
													// Show checkbox if there are selectableRows
													selectableRows && selectableRows.length && (
														<Table.Cell
															key={'selectableColumn'}
															minWidth={5}
															width={15}
															maxWidth={20}
															noWrap={cellProps.noWrap}
														>
															<Flex
																justify={j => j.center}
																align={a => a.center}
															>
																<Checkbox
																	checked={selectedRows.includes(
																		row.id.toString()
																	)}
																	disabled={
																		!selectableRows.includes(
																			row.id.toString()
																		)
																	}
																	onClick={() =>
																		handleRowSelect(
																			row.id.toString()
																		)
																	}
																/>
															</Flex>
														</Table.Cell>
													)
												}

												{columns.map(column => {
													const columnName = column.name;
													return isColumnVisible(column.name) ? (
														<Table.Cell
															key={columnName}
															minWidth={cellProps.minWidth ?? 25}
															width={cellProps.width ?? 35}
															maxWidth={cellProps.maxWidth}
															noWrap={cellProps.noWrap}
														>
															{customCells &&
															customCells[row.id] &&
															customCells[row.id][columnName]
																? customCells[row.id][columnName]
																: row[columnName]}
														</Table.Cell>
													) : undefined;
												})}

												{/* ACTION BUTTONS*/}
												{extraCells && (
													<Table.Cell
														minWidth={5}
														width={5}
														relativePosition
													>
														<IconsContainer
															visible={activeExtraCell === row.id}
															className="icons-container"
														>
															{extraCells[row.id]}
														</IconsContainer>
													</Table.Cell>
												)}
											</Table.Row>
										);
									})}
								</Table.Body>
							) : (
								<Table.Body>
									{loadingRows.map((_, index) => generateLoadingRow(index))}
								</Table.Body>
							)}
						</Table>
					</Table.Responsive>
				)}
			</Wrapper>
			<Spacer size={s => s.m} />
		</>
	);
}

import { useEffect } from 'react';

interface Props {
	client: string;
}
export const TinyURLPage = ({ client }: Props) => {
	const APP_SSO_URL_BASE = process.env.REACT_APP_SSO_SHORT_URL_BASE!;
	useEffect(() => {
		window.location.replace(
			`https://${APP_SSO_URL_BASE}/login?sso=${client === 'hso' ? 'sykehuspartner' : client}`
		);
	}, []);
	return <></>;
};

import produce from 'immer';

import { initialState } from './initialState';
import {
	ActionTypes as FiltersActionTypes,
	Actions as FiltersActions,
	FiltersByVariableName,
	State
} from './types';

import { ActionTypes as EntriesActionTypes, Actions as EntriesActions } from '../entries/types';
import {
	ActionTypes as SnapshotsActionTypes,
	Actions as SnapshotsActions
} from '../snapshots/types';
import {
	ActionTypes as DependenciesActionType,
	Actions as DependenciesAction
} from '../dependencies/types';

export default (
	state: State = initialState,
	action: FiltersActions | EntriesActions | SnapshotsActions | DependenciesAction
): State => {
	switch (action.type) {
		case DependenciesActionType.GET_DEPENDENCIES: {
			const { projectId } = action.payload;
			return produce(state, draft => {
				draft.dependencies = {
					...draft.dependencies,
					projectId: projectId,
					byProjectId: {
						...draft.dependencies.byProjectId,
						[projectId]: {
							...draft.dependencies.byProjectId[projectId],
							active: draft.dependencies.byProjectId[projectId]
								? draft.dependencies.byProjectId[projectId].active
								: [],
							invalid: draft.dependencies.byProjectId[projectId]
								? draft.dependencies.byProjectId[projectId].invalid
								: []
						}
					}
				};
			});
		}
		case FiltersActionTypes.CREATE_FILTER: {
			const { filter } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, byVariableName } = draft.dataset;

				if (!(projectId && byProjectId[projectId])) return;

				byId[filter.itemId] = filter;

				if (!byVariableName[filter.columnName]) {
					byVariableName[filter.columnName] = [];
				}
				byVariableName[filter.columnName].push(filter.itemId);

				const existingActiveFilters = byProjectId[projectId]?.active ?? [];
				const existingInvalidFilters = byProjectId[projectId]?.invalid ?? [];
				byProjectId[projectId] = {
					active: [filter.itemId, ...existingActiveFilters],
					invalid: [...(filter.invalid ? [filter.itemId] : []), ...existingInvalidFilters]
				};
			});
		}

		case FiltersActionTypes.UPDATE_FILTER: {
			const { filter } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId } = draft.dataset;

				if (!(projectId && byProjectId[projectId])) return;

				byId[filter.itemId] = filter;

				const notInvalidAnymore =
					filter.value !== undefined ||
					(filter.values && filter.values.length) ||
					(filter.to !== undefined && filter.from !== undefined);
				if (notInvalidAnymore) {
					byProjectId[projectId].invalid = byProjectId[projectId].invalid.filter(
						id => id !== filter.itemId
					);
					delete byId[filter.itemId].invalid;
				}
			});
		}

		case FiltersActionTypes.DELETE_DATASET_FILTER: {
			const { filterId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySnapshotId, byVariableName } =
					draft.dataset;

				if (!(projectId && byProjectId[projectId])) return;

				byProjectId[projectId] = {
					active: byProjectId[projectId].active.filter(id => id !== filterId),
					invalid: byProjectId[projectId].invalid.filter(id => id !== filterId)
				};

				if (snapshotId) {
					bySnapshotId[snapshotId] = bySnapshotId[snapshotId].filter(
						id => id !== filterId
					);
				}

				const variableName = byId[filterId].columnName;

				if (byVariableName[variableName].length < 2) {
					delete byVariableName[variableName];
				} else {
					byVariableName[variableName] = byVariableName[variableName].filter(
						id => id !== filterId
					);
				}

				delete byId[filterId];
			});
		}

		case FiltersActionTypes.DELETE_FILTERS: {
			const { filterIds, snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySnapshotId, byVariableName } =
					draft.dataset;

				if (!(projectId && byProjectId[projectId])) return;

				byProjectId[projectId] = {
					active: byProjectId[projectId].active.filter(id => !filterIds.includes(id)),
					invalid: byProjectId[projectId].invalid.filter(id => !filterIds.includes(id))
				};

				if (snapshotId) {
					bySnapshotId[snapshotId] = bySnapshotId[snapshotId].filter(
						id => !filterIds.includes(id)
					);
				}

				filterIds.forEach(filterId => {
					const variableName = byId[filterId].columnName;

					if (byVariableName[variableName].length < 2) {
						delete byVariableName[variableName];
					} else {
						byVariableName[variableName] = byVariableName[variableName].filter(
							id => id !== filterId
						);
					}

					delete byId[filterId];
				});
			});
		}

		case FiltersActionTypes.INVALIDATE_FILTER: {
			const { filterId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId } = draft.dataset;

				if (!(projectId && byProjectId[projectId])) return;

				byId[filterId].invalid = true;
				byProjectId[projectId].invalid.push(filterId);
			});
		}

		case FiltersActionTypes.CLEAR_FILTERS: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft.dataset;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId] = {
						active: [],
						invalid: []
					};
				}
			});
		}

		case FiltersActionTypes.TOGGLE_OPEN_FILTERS: {
			return produce(state, draft => {
				draft.dataset.open = !draft.dataset.open;
			});
		}

		case FiltersActionTypes.REBUILD_FILTERS: {
			const { active, byId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft.dataset;

				if (projectId && byProjectId[projectId]) {
					const byVariableName: FiltersByVariableName = {};

					Object.values(byId).forEach(filter => {
						if (!byVariableName[filter.columnName]) {
							byVariableName[filter.columnName] = [];
						}

						byVariableName[filter.columnName].push(filter.itemId);
					});

					draft.dataset.byId = byId;
					draft.dataset.byVariableName = byVariableName;
					byProjectId[projectId].active = active;
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		// TODO: REFACTOR WITH `produce` FOR IMMUTABILITY
		case EntriesActionTypes.GET_ENTRIES:
		case SnapshotsActionTypes.GET_SNAPSHOTS: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				draft.dataset = {
					...draft.dataset,
					projectId: projectId,
					byProjectId: {
						...draft.dataset.byProjectId,
						[projectId]: {
							...draft.dataset.byProjectId[projectId],
							active: draft.dataset.byProjectId[projectId]
								? draft.dataset.byProjectId[projectId].active
								: [],
							invalid: draft.dataset.byProjectId[projectId]
								? draft.dataset.byProjectId[projectId].invalid
								: []
						}
					}
				};

				//SERIES
				draft.series = {
					...draft.series,
					projectId: projectId,
					byProjectId: {
						...draft.series.byProjectId,
						[projectId]: {}
					}
				};
			});
		}

		case SnapshotsActionTypes.GET_SNAPSHOT: {
			const {
				projectId,
				snapshotId,
				data: { filters }
			} = action.payload;

			return produce(state, draft => {
				const { byId, bySnapshotId, byVariableName, byProjectId } = draft.dataset;

				bySnapshotId[snapshotId] = [];
				filters.forEach(filter => {
					if (!byVariableName[filter.columnName]) {
						byVariableName[filter.columnName] = [];
					}

					byId[filter.itemId] = filter;
					bySnapshotId[snapshotId].push(filter.itemId);
					byVariableName[filter.columnName].push(filter.itemId);
				});

				byProjectId[projectId].active = bySnapshotId[snapshotId];
			});
		}

		case SnapshotsActionTypes.CREATE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { byProjectId, bySnapshotId } = draft.dataset;

				bySnapshotId[snapshotId] = byProjectId[projectId].active;
			});
		}

		case SnapshotsActionTypes.UPDATE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { byProjectId, bySnapshotId } = draft.dataset;

				bySnapshotId[snapshotId] = byProjectId[projectId].active;
			});
		}

		case SnapshotsActionTypes.DELETE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { byProjectId, bySnapshotId } = draft.dataset;

				byProjectId[projectId].active = byProjectId[projectId].active.filter(
					id => !bySnapshotId[snapshotId].includes(id)
				);
				byProjectId[projectId].invalid = byProjectId[projectId].invalid.filter(
					id => !bySnapshotId[snapshotId].includes(id)
				);

				delete bySnapshotId[snapshotId];
			});
		}

		case SnapshotsActionTypes.SET_ACTIVE_SNAPSHOT: {
			const { snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, bySnapshotId } = draft.dataset;

				if (projectId && byProjectId[projectId] && bySnapshotId[snapshotId]) {
					byProjectId[projectId].active = bySnapshotId[snapshotId];
				}
			});
		}

		case SnapshotsActionTypes.CLEAR_SNAPSHOT: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft.dataset;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId] = { active: [], invalid: [] };
				}
			});
		}

		// DEPENDENCIES FILTERS
		case FiltersActionTypes.CREATE_DEPENDENCIES_FILTER: {
			const { dependenciesFilter } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, byVariableName } = draft.dependencies;

				if (!(projectId && byProjectId[projectId])) return;

				byId[dependenciesFilter.itemId] = dependenciesFilter;

				if (!byVariableName[dependenciesFilter.columnName]) {
					byVariableName[dependenciesFilter.columnName] = [];
				}
				byVariableName[dependenciesFilter.columnName].push(dependenciesFilter.itemId);

				const existingActiveFilters = byProjectId[projectId]?.active ?? [];
				const existingInvalidFilters = byProjectId[projectId]?.invalid ?? [];
				byProjectId[projectId] = {
					active: [dependenciesFilter.itemId, ...existingActiveFilters],
					invalid: [
						...(dependenciesFilter.invalid ? [dependenciesFilter.itemId] : []),
						...existingInvalidFilters
					]
				};
			});
		}

		case FiltersActionTypes.UPDATE_DEPENDENCIES_FILTER: {
			const { dependenciesFilter } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId } = draft.dependencies;

				if (!(projectId && byProjectId[projectId])) return;

				byId[dependenciesFilter.itemId] = dependenciesFilter;

				const notInvalidAnymore =
					dependenciesFilter.value !== undefined ||
					(dependenciesFilter.values && dependenciesFilter.values.length) ||
					(dependenciesFilter.to !== undefined && dependenciesFilter.from !== undefined);
				if (notInvalidAnymore) {
					byProjectId[projectId].invalid = byProjectId[projectId].invalid.filter(
						id => id !== dependenciesFilter.itemId
					);
					delete byId[dependenciesFilter.itemId].invalid;
				}
			});
		}

		case FiltersActionTypes.DELETE_DEPENDENCIES_FILTER: {
			const { filterId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySnapshotId, byVariableName } =
					draft.dependencies;

				if (!(projectId && byProjectId[projectId])) return;

				byProjectId[projectId] = {
					active: byProjectId[projectId].active.filter(id => id !== filterId),
					invalid: byProjectId[projectId].invalid.filter(id => id !== filterId)
				};

				if (snapshotId) {
					bySnapshotId[snapshotId] = bySnapshotId[snapshotId].filter(
						id => id !== filterId
					);
				}

				const variableName = byId[filterId].columnName;

				if (byVariableName[variableName].length < 2) {
					delete byVariableName[variableName];
				} else {
					byVariableName[variableName] = byVariableName[variableName].filter(
						id => id !== filterId
					);
				}

				delete byId[filterId];
			});
		}

		case FiltersActionTypes.DELETE_DEPENDENCIES_FILTERS: {
			const { filterIds, snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySnapshotId, byVariableName } =
					draft.dependencies;

				if (!(projectId && byProjectId[projectId])) return;

				byProjectId[projectId] = {
					active: byProjectId[projectId].active.filter(id => !filterIds.includes(id)),
					invalid: byProjectId[projectId].invalid.filter(id => !filterIds.includes(id))
				};

				if (snapshotId) {
					bySnapshotId[snapshotId] = bySnapshotId[snapshotId].filter(
						id => !filterIds.includes(id)
					);
				}

				filterIds.forEach(filterId => {
					const variableName = byId[filterId].columnName;

					if (byVariableName[variableName].length < 2) {
						delete byVariableName[variableName];
					} else {
						byVariableName[variableName] = byVariableName[variableName].filter(
							id => id !== filterId
						);
					}

					delete byId[filterId];
				});
			});
		}

		////SERIES
		case FiltersActionTypes.CREATE_SERIES_FILTER: {
			const { seriesFilter } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, byVariableName, bySeriesName } = draft.series;

				if (!(projectId && bySeriesName && byProjectId)) return;

				byId[seriesFilter.itemId] = seriesFilter;

				if (!byVariableName[seriesFilter.columnName]) {
					byVariableName[seriesFilter.columnName] = [];
				}
				byVariableName[seriesFilter.columnName].push(seriesFilter.itemId);

				const existingActiveFilters = byProjectId[projectId][bySeriesName]?.active ?? [];
				const existingInvalidFilters = byProjectId[projectId][bySeriesName]?.invalid ?? [];

				byProjectId[projectId] = {
					...byProjectId[projectId],
					[bySeriesName]: {
						active: [seriesFilter.itemId, ...existingActiveFilters],
						invalid: [seriesFilter.itemId, ...existingInvalidFilters]
					}
				};
			});
		}
		case FiltersActionTypes.UPDATE_SERIES_NAME: {
			const { seriesName } = action.payload;

			return produce(state, draft => {
				const { projectId } = draft.series;

				if (!(projectId && seriesName)) return;

				draft.series.bySeriesName = seriesName;
			});
		}

		case FiltersActionTypes.UPDATE_SERIES_FILTER: {
			const { seriesFilter } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySeriesName } = draft.series;

				if (!(projectId && bySeriesName && byProjectId[projectId][bySeriesName])) return;

				byId[seriesFilter.itemId] = seriesFilter;

				const notInvalidAnymore =
					seriesFilter.value !== undefined ||
					(seriesFilter.values && seriesFilter.values.length) ||
					(seriesFilter.to !== undefined && seriesFilter.from !== undefined);
				if (notInvalidAnymore) {
					byProjectId[projectId][bySeriesName].invalid = byProjectId[projectId][
						bySeriesName
					].invalid.filter(id => id !== seriesFilter.itemId);
					delete byId[seriesFilter.itemId].invalid;
				}
			});
		}

		case FiltersActionTypes.DELETE_SERIES_FILTER: {
			const { filterId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySnapshotId, byVariableName, bySeriesName } =
					draft.series;

				if (!(projectId && bySeriesName && byProjectId[projectId][bySeriesName])) return;

				byProjectId[projectId] = {
					...byProjectId[projectId],
					[bySeriesName]: {
						active: byProjectId[projectId][bySeriesName].active.filter(
							id => id !== filterId
						),
						invalid: byProjectId[projectId][bySeriesName].invalid.filter(
							id => id !== filterId
						)
					}
				};

				if (snapshotId) {
					bySnapshotId[snapshotId] = bySnapshotId[snapshotId].filter(
						id => id !== filterId
					);
				}

				const variableName = byId[filterId].columnName;

				if (byVariableName[variableName].length < 2) {
					delete byVariableName[variableName];
				} else {
					byVariableName[variableName] = byVariableName[variableName].filter(
						id => id !== filterId
					);
				}

				delete byId[filterId];
			});
		}

		case FiltersActionTypes.DELETE_SERIES_FILTERS: {
			const { filterIds, snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySnapshotId, byVariableName, bySeriesName } =
					draft.series;

				if (
					!(
						projectId &&
						byVariableName &&
						bySeriesName &&
						byProjectId[projectId][bySeriesName]
					)
				)
					return;

				byProjectId[projectId] = {
					...byProjectId[projectId],
					[bySeriesName]: {
						active: byProjectId[projectId][bySeriesName].active.filter(
							id => !filterIds.includes(id)
						),
						invalid: byProjectId[projectId][bySeriesName].invalid.filter(
							id => !filterIds.includes(id)
						)
					}
				};

				if (snapshotId) {
					bySnapshotId[snapshotId] = bySnapshotId[snapshotId].filter(
						id => !filterIds.includes(id)
					);
				}

				filterIds.forEach(filterId => {
					const variableName = byId[filterId].columnName;

					if (byVariableName[variableName].length < 2) {
						delete byVariableName[variableName];
					} else {
						byVariableName[variableName] = byVariableName[variableName].filter(
							id => id !== filterId
						);
					}

					delete byId[filterId];
				});
			});
		}

		case FiltersActionTypes.INVALIDATE_SERIES_FILTER: {
			const { filterId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId, bySeriesName } = draft.series;

				if (!(projectId && bySeriesName && byProjectId[projectId][bySeriesName])) return;

				byId[filterId].invalid = true;
				byProjectId[projectId][bySeriesName].invalid.push(filterId);
			});
		}

		default: {
			return state;
		}
	}
};

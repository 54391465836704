import { ANALYSIS_URL, sendRequest } from 'api/utils';
import {
	CreateSnapshot,
	CreateSnapshotResponse,
	GetSnapshotResponse,
	GetSnapshotsResponse,
	Snapshot,
	SnapshotResults,
	UpdateSnapshot
} from './types';
import { Dictionary } from 'environment';
import { Analysis } from '../analyses';

const methods = {
	getOne: 'getSnapshot',
	getAll: 'getSnapshotsForProject',
	create: 'createSnapshot',
	update: 'updateSnapshot',
	delete: 'deleteSnapshot'
};

export default () => ({
	async getSnapshots(projectId: string): Promise<Snapshot[]> {
		try {
			const { data }: GetSnapshotsResponse = await sendRequest(ANALYSIS_URL, {
				method: methods.getAll,
				snapshot: {
					projectId
				}
			});

			if (data.statusCode && data.statusCode !== 200) throw new Error();

			return data.snapshots;
		} catch (e) {
			throw new Error(Dictionary.errors.api.snapshots.couldNotLoadSnapshots);
		}
	},
	async getSnapshot(snapshotId: string, projectId: string): Promise<SnapshotResults> {
		try {
			const { data }: GetSnapshotResponse = await sendRequest(ANALYSIS_URL, {
				method: methods.getOne,
				snapshot: {
					snapshotId: Number(snapshotId),
					projectId
				}
			});

			if (data.statusCode && data.statusCode !== 200) throw new Error();

			const parsedData = JSON.parse(data.snapshot.snapshotJson);

			parsedData.analysisList.forEach((a: Analysis) => {
				if (typeof a.options.configPanel === 'boolean') {
					a.options.configPanel = {
						open: true,
						formatting: true,
						parameters: true
					};
				}
			});

			return parsedData;
		} catch (e) {
			throw new Error(Dictionary.errors.api.snapshots.couldNotLoadSnapshot);
		}
	},
	async createSnapshot(snapshot: CreateSnapshot) {
		try {
			const { data }: CreateSnapshotResponse = await sendRequest(ANALYSIS_URL, {
				method: methods.create,
				snapshot
			});

			if (data.errorCode) {
				throw new Error();
			}

			return data.snapshot.snapshotId;
		} catch (e) {
			throw new Error(Dictionary.errors.api.snapshots.couldNotCreateSnapshot);
		}
	},
	async updateSnapshot(snapshot: UpdateSnapshot) {
		try {
			await sendRequest(ANALYSIS_URL, {
				method: methods.update,
				snapshot
			});
		} catch (e) {
			throw new Error(Dictionary.errors.api.snapshots.couldNotUpdateSnapshot);
		}
	},
	async deleteSnapshot(snapshotId: string, projectId: string) {
		try {
			await sendRequest(ANALYSIS_URL, {
				method: methods.delete,
				snapshot: { snapshotId: Number(snapshotId), projectId }
			});
		} catch (e) {
			throw new Error(Dictionary.errors.api.snapshots.couldNotDeleteSnapshot);
		}
	}
});

import { useRef, useState } from 'react';

import { useOutsideClick } from 'hooks/utils';
import { Input } from '../Inputs/Input';
import { InputType } from 'types/index';

import { Colors, Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import styled from 'styled-components';
import { useTranslation } from 'hooks/store';

interface Props {
	searchTerm: string | null;
	setSearchTerm: (term: string | null) => void;
}
export function SearchIconToInputToggle({ searchTerm, setSearchTerm }: Props) {
	const { translate } = useTranslation();
	const [searchInputVisible, setSearchInputVisible] = useState(!!searchTerm);
	const inputRef = useRef<HTMLInputElement>(null);

	//Hide search project filter on outside input click
	useOutsideClick(() => (searchTerm ? undefined : setSearchInputVisible(false)), [inputRef]);
	return (
		<div>
			<Svgs.Search
				onClick={() => setSearchInputVisible(true)}
				style={{
					visibility: !searchInputVisible ? 'visible' : 'hidden',
					cursor: 'pointer',
					width: '2rem',
					height: '2rem',
					marginRight: '0.8rem'
				}}
			/>

			<Wrapper searchInputVisible={searchInputVisible}>
				<Input
					id={'searchInput'}
					ref={inputRef}
					placeholder={translate(({ iconTooltip }) => iconTooltip.search)}
					type={InputType.Text}
					value={searchTerm ?? ''}
					onChange={e => setSearchTerm(e.target.value)}
				/>
				<Icon
					style={{
						...(!searchTerm?.length
							? { visibility: 'hidden', height: 0 }
							: {
									position: 'absolute',
									right: '0.7rem',
									top: '0.8rem'
							  })
					}}
					svg={Svgs.Clear}
					size={s => s.l}
					colors={{ color: Colors.text.disabled }}
					onClick={() => setSearchTerm('')}
				/>
			</Wrapper>
		</div>
	);
}

const Wrapper = styled.div<{ searchInputVisible: boolean }>`
	display: ${({ searchInputVisible }) => (searchInputVisible ? 'block' : 'none')};
	max-width: 20rem;
	scale: 0.76;
	margin-top: -3.2rem;
	margin-right: -1rem;
	#searchInput {
		padding-right: 4rem;
	}
`;

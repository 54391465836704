import { FontWeights } from 'components/UI/Typography';
import { Colors } from 'environment';

export const pageStyles = `
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap" rel="stylesheet">
    <style>
        * {
            box-sizing: border-box;
            -webkit-print-color-adjust: exact !important;
        }

        html * {
            font-family: 'Roboto Flex', sans-serif;
        }
        html {
            /* SET rem BASE TO 10px */
            font-size: 10px;
        }
        body {
            margin: 0;
            padding: 0 1cm;
        }

        /* CSS resetters */
        h1, h2, h3, h4, h5, h6, p, label {
            margin: 0;
        }

        /* Typography */
        p, .paragraph {
            font-size: 1.4rem;
            line-height: 1.57;
            font-weight: ${FontWeights.normal};
            color: ${Colors.text.main};
        }
        h1, h1 {
            font-size: 2.8rem;
            line-height: 1.43;
            font-weight: ${FontWeights.bold};
            color: ${Colors.text.main};
        }
        h3, .h3 {
            font-size: 2.8rem;
            line-height: 1.43;
            font-weight: ${FontWeights.bold};
            color: ${Colors.text.main};
        }
        h6, .h6 {
            font-size: 1.8rem;
            line-height: 1.56;
            font-weight: ${FontWeights.medium};
            color: ${Colors.text.main};
        }

        label {
            font-size: 1.2rem;
            line-height: 1.67;
            font-weight: ${FontWeights.medium};
            display: block;
            margin-bottom: 0.4rem;
        }
        label > span {
            color: ${Colors.text.caption};
        }
        input {
            display: block;
            width: 100%;
            opacity: 1;
            font-size: 1.4rem;
            line-height: 1.57;
            font-weight: ${FontWeights.normal};
            color: ${Colors.text.main};
            height: 4rem;
            padding: 0.9rem 0.8rem;
            border-radius: 0.4rem;
            border: 0.1rem solid ${Colors.text.disabled};
            background-color: ${Colors.white};
        }
        div.textarea {
            display: block;
            width: 100%;
            opacity: 1;
            font-size: 1.4rem;
            line-height: 1.57;
            font-weight: ${FontWeights.normal};
            color: ${Colors.text.main};
            padding: 0.9rem 0.8rem;
            border-radius: 0.4rem;
            border: 0.1rem solid ${Colors.text.disabled};
            background-color: ${Colors.white};
            box-sizing: border-box;
            min-height: 42px;
        }
        input[disabled="true"], div.textarea[disabled="true"] {
            background-color: ${Colors.background.disabled};
        }
        .title {
            font-size: 1.4rem;
            line-height: 1.67;
            font-weight: ${FontWeights.medium};
            page-break-after: avoid;
            display: flex;
            align-items: center;
            padding-top: 0.8rem;
            border-top: 0.1rem solid ${Colors.text.disabled};
            border-radius: 0.4rem 0.4rem 0 0;
        }
        .title svg {
            display: inline-block;
            margin-right: 0.8rem;
        }
        .container {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
        }
        .end_group {
            height: 1.2rem;
            border-bottom: 0.1rem solid ${Colors.text.disabled};
            border-radius: 0 0 0.4rem 0.4rem;
        }
        .checkbox {
            width: 1.6rem;
            min-width: 1.6rem;
            height: 1.6rem;
            min-height: 1.6rem;
            border: 0.2rem solid ${Colors.text.caption};
            display: inline-block;
            margin-right: 0.4rem;
			border-radius: 0.2rem;
			position: relative;
        }
		.checkbox.selected {
			border-color: ${Colors.primary.normal};
			background-color: ${Colors.primary.normal};
		}
		.checkbox.selected svg {
            margin-top: 0.2rem;
		}
		.checkbox.selected svg path {
			fill: ${Colors.white};
		}
		.radio {
            width: 1.6rem;
            min-width: 1.6rem;
            height: 1.6rem;
            min-height: 1.6rem;
            border: 0.2rem solid ${Colors.text.caption};
            display: inline-block;
            margin-right: 0.8rem;
            border-radius: 50%;
			position: relative;
        }
		.radio.selected {
			border-color: ${Colors.primary.normal};
		}
		.radio.selected::after {
			background-color: ${Colors.primary.normal};
			display: block;
			content: '';
			width: 0.8rem;
			height: 0.8rem;
            border-radius: 50%;
			position: absolute;
            top: 0.2rem;
            left: 0.2rem;
		}
        span {
            display: inline-flex;
            align-items: center;
        }
        .categories_wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 0.8rem 1.6rem;
        }
        .categories_wrapper.vertical {
            flex-direction: column;
        }
        
        .category {
            display: flex;
            align-items: center;
        }
        div[class*="cell_"] {
            min-height: 66px;
            break-inside: avoid;
            page-break-inside: avoid;
        }
        .cell_half {
            float: left;
            width: calc(50% - 1.6rem);
            margin: 0 8px 16px;
        }
        .cell_full {
            float: left;
            width: 100%;
            margin: 0 8px 16px;
        }
        .separator {
            border-top: 2px solid ${Colors.gray.darkest};
        }
        .row {
            width: 100%;
            display: flex;
            gap: 2.4rem;
        }
        .type_placeholder {
            width: 4cm;
            height: 1.6rem;
            border-bottom: 1px solid black;
        }
        .cell_full > .cell_half {
            margin: 0 0 16px;
        }
        .slider {
            display: flex;
            height: 80px;
            align-items: center;
            border: 1px solid #dae0e6;
            border-radius: 4px;
            padding-right: 10px;
            padding-left: 10px;
        }
        .slider_content_wrapper {
            width: 100%;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translate(0px, 4px);
            padding-right: 10px;
            padding-left: 10px;
        }
        .slider_main_container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dae0e6;
            padding-right: 10px;
        }
        .slider_input_wrapper {
            width: 100%;
        }
        
        .slider_input_wrapper input[type='range'] {
            -webkit-appearance: none;
            background: #dae0e6;
            padding-top: 2px;
            padding-bottom: 2px;
            height: 1px;
            border-radius: 20px;
            width: 100%;
            transform: translate(-2px, 0);
        }
        
        .slider_input_wrapper input::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            background-color: #ffffff;
            border: 1.5px solid #b0bfc7;
            border-radius: 50%;
            cursor: pointer;
        }
        .value_container {
            height: 22px;
            background: #ffffff;
            color: #25253a;
            font-weight: 500;
            border: 1px solid #dae0e6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 5px;
        }
        .slider_dots_container {
            display: flex;
            position: relative;
            width: calc(100% - 34px);
            margin-top: 2px;
        }
        .slider_dot {
            width: 1px;
            height: 6px; /* default height */
            background-color: #515161;
            position: absolute;
	        transform: translate(-50%);

            &:first-child {
                height: 8px;
            }

            &:last-child {
                height: 8px;
            }
        }
        .mood_mappings_container {
            width: 100%;
            padding-right: 23px;
            padding-left: 11px;
        }
        .slider_values_container {
            width: 100%;
            padding-right: 17px;
            padding-left: 17px;
        }
        .mood_mappings_content {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
        }
        .slider_values_wrapper {
            margin-top: 15px;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .slider_mapping {
            font-size: 12px;
            color: black;
            position: absolute;
        }
        .slider_value {
            font-size: 12px;
            color: black;
            position: absolute;
            transform: translateX(-50%);
        }
    </style>
`;

import { useEffect } from 'react';
import { AdminAccount } from 'api/account/admin';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateAdminUser } from 'store/account/admin';
import { useDispatch, usePrevious } from 'hooks/utils';
import { useTranslation } from 'hooks/store';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useAlerts } from 'hooks/ui';

export function useAdminUpdateUser(): Operation<(user: AdminAccount) => void> {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [{ loading, error }] = useActivity(ActionTypes.ADMIN_UPDATE_USER);

	const wasLoading = usePrevious(loading);
	useEffect(() => {
		if (wasLoading && !loading && !error) {
			setNotification({
				message: translate(({ admin }) => admin.users.notifications.updatedUser)
			});
		}
	}, [loading, error]);

	function handler(user: AdminAccount) {
		dispatch(updateAdminUser(user));
	}

	return [{ loading, error }, handler];
}

import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { useIsProjectOwner, useTranslation } from 'hooks/store';

interface Props {
	enterpriseRole?: boolean;
	onCreateRole: () => void;
}

export function NoRoles({ enterpriseRole, onCreateRole }: Props) {
	const { translate } = useTranslation();
	const isProjectOwner = useIsProjectOwner();

	function handleCreate() {
		if (isProjectOwner || enterpriseRole) {
			onCreateRole();
		}
	}

	return (
		<Flex align={a => a.center} column>
			<Spacer size={s => s.xl} />
			<Svgs.EmptyStatesRoles style={{ minHeight: 240 }} />
			<Spacer size={s => s.m} />
			<Typography.H3>{translate(dict => dict.roles.noRoles.title)}</Typography.H3>

			<Spacer size={s => s.xs} />
			<Typography.Paragraph multiline alignCenter>
				{translate(dict =>
					!enterpriseRole
						? dict.roles.noRoles.description
						: dict.enterpriseAdmin.rolesModules.createFirstRole
				)}
			</Typography.Paragraph>
			<Spacer size={s => s.m} />
			<Button title={translate(dict => dict.rolesPage.newRole)} onClick={handleCreate} />
			<Spacer size={s => s.xxl} />
		</Flex>
	);
}

import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { nanoid as generate } from 'nanoid';
import { ZingChartClickEvent } from 'zingchart-react';
import { NumberPlotXYResults } from 'api/data/analyses';
import { Chart } from 'components/UI/Chart';
import { ScalesLabels, StringMap } from 'types/index';

import { VariablesMap } from 'store/data/variables';

import { NoPlot } from '../../NoPlot';
import { AnalysisContainer } from '../../UI';
import { useComputeNumberPlotXYChartData as useComputeData } from 'helpers/analysis';

import {
	useAnalysisContext,
	useToggleAnalysisChartPlot,
	useTranslation,
	useAnalyses,
	useFilters
} from 'hooks/store';
import { useWindowSize } from 'hooks/ui';

interface Props {
	id?: string;
	data: NumberPlotXYResults;
	variablesMap: VariablesMap;
	scalesLabels: ScalesLabels;
	isLegendEnabled: boolean;
	aggregatorVariableNameByAggregationRuleName: StringMap;
	legendHeader?: string;
	isForExport?: boolean;
	fullscreen?: string;
	activeColumn?: number;
	loading: boolean;
}

export function NumberPlotXYChart({
	id,
	data,
	isLegendEnabled,
	aggregatorVariableNameByAggregationRuleName,
	legendHeader,
	isForExport,
	fullscreen,
	activeColumn,
	variablesMap,
	loading
}: Props) {
	const { analysisId } = useAnalysisContext() as { analysisId: string };
	const [plots, togglePlot] = useToggleAnalysisChartPlot(analysisId);
	const { translate } = useTranslation();
	const windowSize = useWindowSize();
	const analysisIds = useAnalyses();
	const [{ filters }] = useFilters();
	const { series, options } = useComputeData(
		data.data,
		isLegendEnabled,
		variablesMap,
		aggregatorVariableNameByAggregationRuleName,
		plots,
		!!fullscreen,
		activeColumn,
		legendHeader
	);

	const renderId = useMemo(generate, [
		fullscreen,
		activeColumn,
		windowSize,
		analysisIds,
		filters
	]);

	const onLegendItemClick = useCallback((e: ZingChartClickEvent) => {
		togglePlot({ id: analysisId, plotIndex: e.plotindex });
	}, []);

	if (isEmpty(data.data) && !loading)
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	return (
		<Chart
			onLegendItemClick={onLegendItemClick}
			key={renderId}
			id={id}
			type={t => t.Line}
			series={series}
			options={options}
			isForExport={isForExport}
			height={'100%'}
		/>
	);
}

import { isEqual } from 'lodash';
import { createSelector } from 'reselect';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getDistributionsIds({ byProjectId, projectId }: State) {
	if (projectId && byProjectId[projectId]?.distributions) {
		const { distributions } = byProjectId[projectId];
		return distributions.fetched ? byProjectId[projectId].distributions.current.ids : undefined;
	}
	return undefined;
}

function getPatients({ byProjectId, projectId }: State) {
	if (projectId && byProjectId[projectId]?.patients) {
		const { patients } = byProjectId[projectId];
		return patients.fetched ? Object.values(patients.byId) : undefined;
	}
	return undefined;
}

function getDistributions({ byProjectId, projectId }: State) {
	if (projectId) {
		const { ids, byId } = byProjectId[projectId].distributions.initial;

		const distributions = ids.map(distributionId => byId[distributionId]);

		return distributions;
	}

	return [];
}

function getDistributionById({ byProjectId }: State, id: string, promId: string) {
	return byProjectId[promId]?.distributions?.current.byId[id];
}

function getAllDistributionsAreValid({ byProjectId, projectId }: State) {
	if (projectId && byProjectId[projectId]) {
		const { byId } = byProjectId[projectId].distributions.current;

		const isValid = Object.values(byId).every(
			distribution =>
				distribution.quantity !== undefined && distribution.unitOfTime !== undefined
		);

		return isValid;
	}

	return false;
}

function getDistributionsHaveChanges({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) {
		return !isEqual(
			byProjectId[projectId].distributions.initial,
			byProjectId[projectId].distributions.current
		);
	}

	return false;
}

function getDistributionGlobalStartDate({ projectId, byProjectId }: State, initial?: boolean) {
	if (projectId && byProjectId[projectId]) {
		const { distributions } = byProjectId[projectId];

		if (initial) return distributions.initial.globalStartDate;

		return distributions.current.globalStartDate;
	}
}

function getPatientsPageParams(state: State) {
	return {
		pageIndex: state.metadata.pageIndex,
		pageSize: state.metadata.pageSize,
		term: state.metadata.term
	};
}

function getSenderDescription({ projectId, byProjectId }: State, initial?: boolean) {
	if (projectId && byProjectId[projectId]) {
		const { distributions } = byProjectId[projectId];

		if (initial) return distributions.initial.senderDescription;

		return distributions.current.senderDescription;
	}
}

function getCentraliseEntryData({ projectId, byProjectId }: State, initial?: boolean) {
	if (projectId && byProjectId[projectId]) {
		const { distributions } = byProjectId[projectId];

		if (initial) return distributions.initial.centraliseEntryData;

		return distributions.current.centraliseEntryData;
	}
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectPatientsPageParams = createSelector([getPatientsPageParams], params => params);

export const selectPatients = createSelector([getPatients], patients => patients);

export const selectDistributionsIds = createSelector([getDistributionsIds], ids => ids);

export const selectDistributions = createSelector(
	[getDistributions],
	distributions => distributions
);

export const selectDistributionById = createSelector(
	[getDistributionById],
	distribution => distribution
);

export const selectAllDistributionsAreValid = createSelector(
	[getAllDistributionsAreValid],
	distributionsAreValid => distributionsAreValid
);

export const selectDistributionsHaveChanges = createSelector(
	[getDistributionsHaveChanges],
	status => status
);

export const selectSenderDescription = createSelector(
	[getSenderDescription],
	senderDescription => senderDescription
);

export const selectCentraliseEntryData = createSelector(
	[getCentraliseEntryData],
	centraliseEntryData => centraliseEntryData
);

export const selectDistributionGlobalStartDate = createSelector(
	[getDistributionGlobalStartDate],
	globalStartDate => globalStartDate
);

import { useMemo } from 'react';
import { Variable } from 'api/data/variables';
import { InputType, SelectItem } from 'types/index';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { Input } from 'components/UI/Inputs/Input';
import { Pagination } from 'components/UI/Pagination';
import { TagInput } from 'components/UI/TagInput';
import { Flex } from 'components/UI/Flex';
import { Modal } from 'components/UI/Modal';
import { useTranslation } from 'hooks/store';
import { usePaginate } from 'hooks/utils';

export interface Props {
	variable: Variable;
	onClose(): void;
}

export function PreviewCategoriesModal({ variable, onClose }: Props) {
	const { translate } = useTranslation();

	const categorySelectItems: SelectItem[] = useMemo(
		() =>
			variable.categories.map(c => ({
				label: c.label || c.value,
				value: c.value
			})),
		[variable.categories]
	);

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(categorySelectItems, {
		threshold: 10,
		pageSize: 10
	});

	return (
		<Modal
			title={`Preview category values`}
			primary={{
				label: translate(dict => dict.buttons.done),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Input
				label={`Variable name`}
				type={InputType.Text}
				value={variable.label}
				marginOffset={{ bottom: 1.6 }}
				readOnly
			/>

			<InputLabel label={`Category values`} />

			{shouldPaginate && (
				<Flex marginOffset={{ bottom: 0.4 }}>
					<Pagination
						totalCountLabel="values"
						pageIndex={pageIndex}
						pageSize={pageSize}
						pagesCount={pagesCount}
						changePage={changePage}
						changePageSize={changePageSize}
						totalCount={variable.categories.length}
					/>
				</Flex>
			)}

			<TagInput items={selectedCategoryItems} readOnly />
		</Modal>
	);
}

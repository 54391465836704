import type { Action } from 'types/store/types';
import {
	ElementType,
	OrientationType,
	ChoiceType,
	type GenericMap,
	type BooleanMap
} from 'types/index';

export interface State {
	projectId: string | null;
	formId: FormId;
	byId: FormsById;
	byProjectId: FormsByProjectId;
	metadata: {
		searchTerm: string;
	};
}

export type FormId = string | null;

export type FormsById = GenericMap<{ initial: Form; current: Form }>;
export type FormsByProjectId = GenericMap<{
	ids: string[];
	bySetName: GenericMap<{
		ids: string[];
	}>;
	filters: FormsFilters;
	collapsed: BooleanMap;
	fetched: boolean;
	refetch: boolean;
}>;

export interface FormsFilters {
	show: FormsFilterShowOptions;
}

export enum FormsFilterShowOptions {
	ALL = 'all',
	MAIN = 'main',
	VARIABLE_SETS = 'series'
}

export enum FormElementDisplayType {
	VALUES = 'value',
	LABELS = 'label'
}

export type FormElementSliderValue = {
	value: string;
	mapping?: string;
};
export enum FormElementSliderType {
	HORIZONTAL = 'horizontal',
	VERTICAL = 'vertical'
}

export interface Form {
	id: string;
	name: string;
	title: string;
	titleEnabled: boolean;
	active: boolean;
	elements: FormElements;
	formErrors: FormErrors;
	groups: FormGroups;
	sets: FormSets;
	elementsOrder: FormElementsOrder;
	usedVariables: string[];
	usedGroups: string[];
	usedSets: string[];
	setName?: string;
}

export type FormElementsOrder = Array<string | string[]>;

export type FormElements = GenericMap<FormElement>;
export type FormGroups = GenericMap<FormGroup>;
export type FormSets = GenericMap<FormSet>;

export interface FormElement {
	elementId: string;
	elementType: ElementType;
	text?: string;
	label?: string;
	variableRef?: string;
	orientation?: OrientationType;
	displayType?: FormElementDisplayType;
	sliderValues?: FormElementSliderValue[];
	sliderType?: FormElementSliderType;
	scaleInterval?: number | null;
	displaySelectedValue?: boolean;
	mapValuesWithMoods?: boolean;
	choiceType?: ChoiceType;
}

export interface FormGroup {
	groupId: string;
	groupName: string;
	groupLabel: string;
	elementsOrder: FormElementsOrder;
}

export interface FormSet {
	setId: string;
	setName: string;
	setLabel: string;
}

export interface FormsData {
	forms: Form[];
	formsBySetName: GenericMap<Form[]>;
}

export interface OrderFormElementOptions {
	rowIndex?: number;
	groupId?: string;
}

export type ElementErrors = {
	scaleIntervalError?: string;
	sliderValuesErrors?: string[];
};

export type FormErrors = {
	[elementId: string]: ElementErrors;
};

export enum ActionTypes {
	GET_FORM = 'data/forms/GET_FORM',
	GET_FORMS = 'data/forms/GET_FORMS',
	CREATE_FORM = 'data/forms/CREATE_FORM',
	UPDATE_FORM = 'data/forms/UPDATE_FORM',
	RENAME_FORM = 'data/forms/RENAME_FORM',
	DELETE_FORM = 'data/forms/DELETE_FORM',
	DEACTIVATE_FORMS = 'data/forms/DEACTIVATE_FORMS',
	DEACTIVATE_FORM = 'data/forms/DEACTIVATE_FORM',
	ACTIVATE_FORM = 'data/forms/ACTIVATE_FORM',
	MOVE_FORM = 'data/forms/MOVE_FORM',
	GET_LATEST_FORM = 'data/forms/GET_LATEST_FORM',
	/////////////////////////////////////////////////////////
	SET_FORM_ID = 'data/forms/SET_FORM_ID',
	RESET_FORM = 'data/forms/RESET_FORM',
	SET_FORM_VARIABLE_SEARCH_TERM = 'data/forms/SET_FORM_VARIABLE_SEARCH_TERM',
	SET_FORMS_FILTERS = 'data/forms/SET_FORMS_FILTERS',
	SET_FORMS_SECTION_COLLAPSED = 'data/forms/SET_FORMS_SECTION_COLLAPSED',
	SET_REFETCH_FORMS = 'data/forms/SET_REFETCH_FORMS',
	/////////////////////////////////////////////////////////
	ADD_FORM_ELEMENT = 'data/forms/ADD_FORM_ELEMENT',
	ADD_FORM_GROUP = 'data/forms/ADD_FORM_GROUP',
	ADD_FORM_SET = 'data/forms/ADD_FORM_SET',
	APPEND_FORM_ELEMENT = 'data/forms/APPEND_FORM_ELEMENT',
	APPEND_FORM_GROUP = 'data/forms/APPEND_FORM_GROUP',
	APPEND_FORM_SET = 'data/forms/APPEND_FORM_SET',
	REMOVE_FORM_ELEMENT = 'data/forms/REMOVE_FORM_ELEMENT',
	REMOVE_FORM_GROUP = 'data/forms/REMOVE_FORM_GROUP',
	REMOVE_FORM_SET = 'data/forms/REMOVE_FORM_SET',
	ORDER_FORM_ELEMENT = 'data/forms/ORDER_FORM_ELEMENT',
	SET_FORM_ELEMENT_ORIENTATION = 'data/forms/SET_FORM_ELEMENT_ORIENTATION',
	SET_FORM_ELEMENT_DISPLAY_TYPE = 'data/forms/SET_FORM_ELEMENT_DISPLAY_TYPE',
	SET_FORM_ELEMENT_DISPLAY_AS = 'data/forms/SET_FORM_ELEMENT_DISPLAY_AS',
	SET_FORM_ELEMENT_SLIDER_VALUES = 'data/forms/SET_FORM_ELEMENT_SLIDER_VALUES',
	SET_FORM_ELEMENT_SLIDER_TYPE = 'data/forms/SET_FORM_ELEMENT_SLIDER_TYPE',
	SET_FORM_ELEMENT_SCALE_INTERVAL = 'data/forms/SET_FORM_ELEMENT_SCALE_INTERVAL',
	SET_FORM_ELEMENT_DISPLAY_SELECTED_VALUE = 'data/forms/SET_FORM_ELEMENT_DISPLAY_SELECTED_VALUE',
	SET_FORM_ELEMENT_MAP_VALUES_WITH_MOODS = 'data/forms/SET_FORM_ELEMENT_MAP_VALUES_WITH_MOODS',
	SET_FORM_ELEMENT_LABEL = 'data/forms/SET_FORM_ELEMENT_LABEL',
	SET_FORM_GROUP_LABEL = 'data/forms/SET_FORM_GROUP_LABEL',
	SET_FORM_SET_LABEL = 'data/forms/SET_FORM_SET_LABEL',
	SET_FORM_ELEMENT_TYPE = 'data/forms/SET_FORM_ELEMENT_TYPE',
	SET_FORM_ELEMENT_TEXT = 'data/forms/SET_FORM_ELEMENT_TEXT',
	SET_FORM_ERRORS = 'data/forms/SET_FORM_ERRORS',
	TOGGLE_FORM_ACTIVE = 'data/forms/TOGGLE_FORM_ACTIVE',
	TOGGLE_ACTIVE_FORM = 'data/forms/TOGGLE_ACTIVE_FORM',
	TOGGLE_FORM_TITLE = 'data/forms/TOGGLE_FORM_TITLE',
	SET_FORM_TITLE = 'data/forms/SET_FORM_TITLE',
	GROUP_FORM_ELEMENTS = 'data/forms/GROUP_FORM_ELEMENTS',
	UNGROUP_FORM_ELEMENTS = 'data/forms/UNGROUP_FORM_ELEMENTS'
}

export type GetFormAction = Action<
	ActionTypes.GET_FORM,
	{
		form: Form;
	}
>;

export type GetFormsAction = Action<
	ActionTypes.GET_FORMS,
	{
		projectId: string;
		formsData: FormsData;
	}
>;

export type CreateFormAction = Action<
	ActionTypes.CREATE_FORM,
	{
		form: Form;
	}
>;

export type UpdateFormAction = Action<ActionTypes.UPDATE_FORM>;

export type RenameFormAction = Action<
	ActionTypes.RENAME_FORM,
	{
		formName: string;
		formId: string;
	}
>;

export type DeleteFormAction = Action<
	ActionTypes.DELETE_FORM,
	{
		formId: string;
	}
>;

export type DeactivateFormsAction = Action<
	ActionTypes.DEACTIVATE_FORMS,
	{
		formIds: string[];
	}
>;

export type DeactivateFormAction = Action<
	ActionTypes.DEACTIVATE_FORM,
	{
		formId: string;
	}
>;

export type ActivateFormAction = Action<
	ActionTypes.ACTIVATE_FORM,
	{
		formId: string;
	}
>;

export type MoveFormAction = Action<
	ActionTypes.MOVE_FORM,
	{
		formId: string;
		sourceIndex: number;
		destinationIndex: number;
	}
>;

export type GetLatestFormAction = Action<
	ActionTypes.GET_LATEST_FORM,
	{
		form: Form;
	}
>;

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

export type SetFormIdAction = Action<
	ActionTypes.SET_FORM_ID,
	{
		formId: FormId;
	}
>;

export type ResetFormAction = Action<ActionTypes.RESET_FORM>;

export type SetFormVariableSearchTermAction = Action<
	ActionTypes.SET_FORM_VARIABLE_SEARCH_TERM,
	{
		term: string;
	}
>;

export type SetFormsFiltersAction = Action<
	ActionTypes.SET_FORMS_FILTERS,
	{
		filters: FormsFilters;
	}
>;

export type SetFormsSectionCollapsedAction = Action<
	ActionTypes.SET_FORMS_SECTION_COLLAPSED,
	{
		sectionName: string;
	}
>;

export type SetRefetchFormsAction = Action<ActionTypes.SET_REFETCH_FORMS>;

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

export type AddFormElementAction = Action<
	ActionTypes.ADD_FORM_ELEMENT,
	{
		destinationIndex: number;
		element: FormElement;
	}
>;

export type AddFormGroupAction = Action<
	ActionTypes.ADD_FORM_GROUP,
	{
		destinationIndex: number;
		formGroup: FormGroup;
		groupElements: FormElements;
	}
>;

export type AddFormSetAction = Action<
	ActionTypes.ADD_FORM_SET,
	{
		destinationIndex: number;
		formSet: FormSet;
	}
>;

export type AppendFormElementAction = Action<
	ActionTypes.APPEND_FORM_ELEMENT,
	{
		element: FormElement;
	}
>;

export type AppendFormGroupAction = Action<
	ActionTypes.APPEND_FORM_GROUP,
	{
		formGroup: FormGroup;
		groupElements: FormElements;
	}
>;

export type AppendFormSetAction = Action<
	ActionTypes.APPEND_FORM_SET,
	{
		formSet: FormSet;
	}
>;

export type RemoveFormElementAction = Action<
	ActionTypes.REMOVE_FORM_ELEMENT,
	{
		elementId: string;
	}
>;

export type RemoveFormGroupAction = Action<
	ActionTypes.REMOVE_FORM_GROUP,
	{
		groupId: string;
	}
>;

export type RemoveFormSetAction = Action<
	ActionTypes.REMOVE_FORM_SET,
	{
		setId: string;
	}
>;

export type GroupFormElementsAction = Action<
	ActionTypes.GROUP_FORM_ELEMENTS,
	{
		sourceId: string;
		sourceIndex: number;
		destinationId: string;
		destinationIndex: number;
		groupName?: string;
	}
>;

export type UnGroupFormElementsAction = Action<
	ActionTypes.UNGROUP_FORM_ELEMENTS,
	{
		rowIndex: number;
		groupId?: string;
	}
>;

export type OrderFormElementAction = Action<
	ActionTypes.ORDER_FORM_ELEMENT,
	{
		sourceIndex: number;
		destinationIndex: number;
		options?: OrderFormElementOptions;
	}
>;

export type SetFormElementOrientationAction = Action<
	ActionTypes.SET_FORM_ELEMENT_ORIENTATION,
	{
		elementId: string;
		elementType: ElementType;
		orientation: OrientationType;
	}
>;

export type SetFormElementDisplayTypeAction = Action<
	ActionTypes.SET_FORM_ELEMENT_DISPLAY_TYPE,
	{
		elementId: string;
		elementType: ElementType;
		displayType: FormElementDisplayType;
	}
>;

export type SetFormElementSliderValuesAction = Action<
	ActionTypes.SET_FORM_ELEMENT_SLIDER_VALUES,
	{
		elementId: string;
		sliderValues: FormElementSliderValue[];
	}
>;

export type SetFormElementSliderTypeAction = Action<
	ActionTypes.SET_FORM_ELEMENT_SLIDER_TYPE,
	{
		elementId: string;
		elementType: ElementType;
		sliderType: FormElementSliderType;
	}
>;

export type SetFormElementScaleIntervalAction = Action<
	ActionTypes.SET_FORM_ELEMENT_SCALE_INTERVAL,
	{
		elementId: string;
		scaleInterval: number | null;
	}
>;

export type SetFormElementDisplaySelectedValueAction = Action<
	ActionTypes.SET_FORM_ELEMENT_DISPLAY_SELECTED_VALUE,
	{
		elementId: string;
		displaySelectedValue: boolean;
	}
>;

export type SetFormElementMapValuesWithMoodsAction = Action<
	ActionTypes.SET_FORM_ELEMENT_MAP_VALUES_WITH_MOODS,
	{
		elementId: string;
		mapValuesWithMoods: boolean;
	}
>;

export type SetFormElementLabelAction = Action<
	ActionTypes.SET_FORM_ELEMENT_LABEL,
	{
		elementId: string;
		label: string;
		belongingToGroup?: string;
	}
>;

export type SetFormGroupLabelAction = Action<
	ActionTypes.SET_FORM_GROUP_LABEL,
	{
		groupId: string;
		label: string;
	}
>;

export type SetFormSetLabelAction = Action<
	ActionTypes.SET_FORM_SET_LABEL,
	{
		setId: string;
		label: string;
	}
>;

export type SetFormElementTypeAction = Action<
	ActionTypes.SET_FORM_ELEMENT_TYPE,
	{
		elementId: string;
		elementType: ElementType;
	}
>;

export type SetFormElementTextAction = Action<
	ActionTypes.SET_FORM_ELEMENT_TEXT,
	{
		elementId: string;
		text: string;
	}
>;

export type SetFormErrorsAction = Action<
	ActionTypes.SET_FORM_ERRORS,
	{
		formErrors: FormErrors;
	}
>;

export type ToggleActiveFormAction = Action<ActionTypes.TOGGLE_FORM_ACTIVE>;

export type ToggleFormTitleAction = Action<ActionTypes.TOGGLE_FORM_TITLE>;

export type SetFormTitleAction = Action<
	ActionTypes.SET_FORM_TITLE,
	{
		title: string;
	}
>;

export type Actions =
	| GetFormAction
	| GetFormsAction
	| CreateFormAction
	| UpdateFormAction
	| RenameFormAction
	| DeleteFormAction
	| DeactivateFormsAction
	| DeactivateFormAction
	| ActivateFormAction
	| MoveFormAction
	| GetLatestFormAction
	//////////////////////////
	| SetFormIdAction
	| ResetFormAction
	| SetFormVariableSearchTermAction
	| SetFormsFiltersAction
	| SetFormsSectionCollapsedAction
	| SetRefetchFormsAction
	//////////////////////////
	| AddFormElementAction
	| AddFormGroupAction
	| AddFormSetAction
	| AppendFormElementAction
	| AppendFormGroupAction
	| AppendFormSetAction
	| RemoveFormElementAction
	| RemoveFormGroupAction
	| RemoveFormSetAction
	| GroupFormElementsAction
	| UnGroupFormElementsAction
	| OrderFormElementAction
	| SetFormElementOrientationAction
	| SetFormElementLabelAction
	| SetFormGroupLabelAction
	| SetFormSetLabelAction
	| SetFormElementTypeAction
	| SetFormElementTextAction
	| SetFormErrorsAction
	| ToggleActiveFormAction
	| ToggleFormTitleAction
	| SetFormTitleAction
	| SetFormElementDisplayTypeAction
	| SetFormElementSliderValuesAction
	| SetFormElementSliderTypeAction
	| SetFormElementScaleIntervalAction
	| SetFormElementDisplaySelectedValueAction
	| SetFormElementMapValuesWithMoodsAction;

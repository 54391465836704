import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const Container = styled.div`
	width: 100%;
	max-width: 60rem;
	margin: 0 2rem;
`;

import type { Patient, BaseDistributionData } from 'api/data/patients';
import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export enum ActionTypes {
	SET_PATIENTS_SEARCH_TERM = 'data/patients/SET_PATIENTS_SEARCH_TERM',
	SET_PATIENTS_PAGE_INDEX = 'data/patients/SET_PATIENTS_PAGE_INDEX',
	SET_SENDER_DESCRIPTION = 'data/patients/SET_SENDER_DESCRIPTION',
	SET_CENTRALISE_ENTRY_DATA = 'data/patients/SET_CENTRALISE_ENTRY_DATA',
	GET_PATIENT_DISTRIBUTION_LIST = 'data/patients/GET_PATIENT_DISTRIBUTION_LIST',
	ADD_PATIENT_DISTRIBUTION = 'data/patients/ADD_PATIENT_DISTRIBUTION',
	UPDATE_PATIENT_DISTRIBUTION = 'data/patients/UPDATE_PATIENT_DISTRIBUTION',
	DELETE_PATIENT_DISTRIBUTION = 'data/patients/DELETE_PATIENT_DISTRIBUTION',
	GET_DISTRIBUTIONS = 'data/patients/GET_DISTRIBUTIONS',
	ADD_NEW_DISTRIBUTION = 'data/patients/ADD_NEW_DISTRIBUTION',
	UPDATE_DISTRIBUTION = 'data/patients/UPDATE_DISTRIBUTION',
	UPDATE_DISTRIBUTIONS = 'data/patients/UPDATE_DISTRIBUTIONS',
	DELETE_DISTRIBUTION = 'data/patients/DELETE_DISTRIBUTION',
	RESET_DISTRIBUTIONS = 'data/patients/RESET_DISTRIBUTIONS',
	CONFIRM_STARTED_FILLING_FORM = 'data/patients/CONFIRM_STARTED_FILLING_FORM',
	CONFIRM_FINISHED_FORM = 'data/patients/CONFIRM_FINISHED_FORM',
	// SURVEY - MANUAL DISTRIBUTION
	SET_PATIENT_MANUAL_SURVEY_PARAMS = 'data/patients/SET_PATIENT_MANUAL_SURVEY_PARAMS',
	SET_DISTRIBUTION_GLOBAL_START_DATE = 'data/patients/SET_DISTRIBUTION_GLOBAL_START_DATE'
}

export interface PatientManualSurveyParams {
	projectId: string;
	distributionEntryId: string;
	hashString: string;
	hashKey: string;
	formId?: string;
}

export interface PatientsListParams {
	pageIndex: number;
	pageSize: number;
	term: string;
}

export type PatientsByProjectId = GenericMap<{
	patients: {
		fetched: boolean;
		byId: PatientsById;
	};
	distributions: {
		fetched: boolean;
		initial: {
			byId: DistributionsById;
			ids: string[];
			// FOR AUTOMATIC DISTRIBUTION
			senderDescription?: string;
			centraliseEntryData?: boolean;
			// FOR MANUAL DISTRIBUTION
			globalStartDate?: string;
		};
		current: {
			byId: DistributionsById;
			ids: string[];
			// FOR AUTOMATIC DISTRIBUTION
			senderDescription?: string;
			centraliseEntryData?: boolean;
			// FOR MANUAL DISTRIBUTION
			globalStartDate?: string;
		};
	};
}>;

export type PatientsById = GenericMap<Patient>;

export type DistributionsById = GenericMap<BaseDistributionData>;

export interface State {
	projectId: string | null;
	byProjectId: PatientsByProjectId;
	metadata: {
		pageSize: number;
		pageIndex: number;
		term: string;
	};
	surveyParams: {
		manual: PatientManualSurveyParams | null;
	};
}

export type GetDistributionPatientListAction = Action<
	ActionTypes.GET_PATIENT_DISTRIBUTION_LIST,
	{
		projectId: string;
		patients: Patient[];
	}
>;

export type SetPatientsSearchTermAction = Action<
	ActionTypes.SET_PATIENTS_SEARCH_TERM,
	{
		term: string;
	}
>;

export type SetPatientsPageIndexAction = Action<
	ActionTypes.SET_PATIENTS_PAGE_INDEX,
	{
		pageIndex: number;
	}
>;

export type AddUpdatePatientAction = Action<
	ActionTypes.ADD_PATIENT_DISTRIBUTION | ActionTypes.UPDATE_PATIENT_DISTRIBUTION,
	{
		patient: Patient;
	}
>;

export type DeletePatientAction = Action<
	ActionTypes.DELETE_PATIENT_DISTRIBUTION,
	{
		patientId: number;
	}
>;

export type GetDistributionsAction = Action<
	ActionTypes.GET_DISTRIBUTIONS,
	{
		projectId: string;
		distributions: BaseDistributionData[];
		senderDescription: string;
		centraliseEntryData?: boolean;
		globalStartDate?: string;
	}
>;

export type SetSenderDescriptionAction = Action<
	ActionTypes.SET_SENDER_DESCRIPTION,
	{
		senderDescription: string;
	}
>;

export type SetCentraliseEntryDataAction = Action<
	ActionTypes.SET_CENTRALISE_ENTRY_DATA,
	{
		centraliseEntryData: boolean;
	}
>;

export type AddNewDistributionAction = Action<ActionTypes.ADD_NEW_DISTRIBUTION>;

export type UpdateDistributionsAction = Action<
	ActionTypes.UPDATE_DISTRIBUTIONS,
	{
		distributions: BaseDistributionData[];
		senderDescription?: string;
		centraliseEntryData?: boolean;
		globalStartDate?: string;
	}
>;

export type DeleteDistributionAction = Action<
	ActionTypes.DELETE_DISTRIBUTION,
	{
		distributionId: number;
	}
>;

export type UpdateDistributionAction = Action<
	ActionTypes.UPDATE_DISTRIBUTION,
	{
		updatedDistribution: BaseDistributionData;
		internalId: number;
	}
>;

export type ResetDistributionsAction = Action<ActionTypes.RESET_DISTRIBUTIONS>;

export type SetPatientManualSurveyParamsAction = Action<
	ActionTypes.SET_PATIENT_MANUAL_SURVEY_PARAMS,
	PatientManualSurveyParams
>;

export type SetDistributionGlobalStartDateAction = Action<
	ActionTypes.SET_DISTRIBUTION_GLOBAL_START_DATE,
	{ globalStartDate: string }
>;

export type Actions =
	| SetPatientsSearchTermAction
	| SetPatientsPageIndexAction
	| SetSenderDescriptionAction
	| SetCentraliseEntryDataAction
	| GetDistributionPatientListAction
	| AddUpdatePatientAction
	| DeletePatientAction
	| GetDistributionsAction
	| AddNewDistributionAction
	| DeleteDistributionAction
	| UpdateDistributionAction
	| UpdateDistributionsAction
	| ResetDistributionsAction
	| SetPatientManualSurveyParamsAction
	| SetDistributionGlobalStartDateAction;

import { useEffect } from 'react';
import { stringAsBoolean } from 'helpers/generic';
import { ROUTES } from 'types/navigation';
import { SubscriptionAddonCode } from 'types/index';
import { JADBioLoginModal } from './JADBioLoginModal';
import { useRouteMatch, useIsRouteWithProjectId } from 'hooks/navigation';
import {
	useLogInToJADBio,
	useShowJADBioLogIn,
	useSyncStorageJADBioToken,
	useGetJADBioProjectId,
	useIsUserAddonActive,
	useIsProjectAddonActive
} from 'hooks/store';

export function JADBioProvider() {
	const IS_JADBIO_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_JADBIO!);

	const [{ data: isLoggedInToJADBio }] = useLogInToJADBio();

	const [showLogInModal, setShowLoginModal] = useShowJADBioLogIn();

	useSyncStorageJADBioToken();

	const [{ fetched: isJADBioProjetIdFetched }, getJADBioProjectId] = useGetJADBioProjectId();

	const [{ data: isJADBioUserAddonActive, loading: jadbioUserAddonActiveLoading }] =
		useIsUserAddonActive(SubscriptionAddonCode.JADBio);

	const [{ data: isJADBioProjectActive }] = useIsProjectAddonActive(SubscriptionAddonCode.JADBio);

	const isOnProjectsOrPromsPage = useRouteMatch([ROUTES.Projects, ROUTES.Proms]);
	const isOnCreateProjectOrProm = useRouteMatch([ROUTES.CreateProject, ROUTES.CreateProm]);
	const { isRouteWithProjectId } = useIsRouteWithProjectId();
	const isInsideProjectOrProm =
		isRouteWithProjectId && !isOnProjectsOrPromsPage && !isOnCreateProjectOrProm;

	// Makes JADBio login pop up when user is in a project and is not logged in to JADBio
	useEffect(() => {
		if (IS_JADBIO_ENABLED) {
			if (
				isInsideProjectOrProm &&
				isJADBioUserAddonActive &&
				isJADBioProjectActive &&
				!jadbioUserAddonActiveLoading &&
				!isLoggedInToJADBio
			) {
				setShowLoginModal(true);
			}
		}
	}, [
		isInsideProjectOrProm,
		isJADBioUserAddonActive,
		isLoggedInToJADBio,
		isJADBioProjectActive,
		jadbioUserAddonActiveLoading
	]);

	// retrieve JADBio Project Id
	useEffect(() => {
		if (
			isLoggedInToJADBio &&
			isJADBioProjectActive &&
			isJADBioUserAddonActive &&
			!isJADBioProjetIdFetched
		) {
			getJADBioProjectId();
		}
	}, [
		isLoggedInToJADBio,
		isJADBioProjectActive,
		isJADBioUserAddonActive,
		isJADBioProjetIdFetched
	]);

	return showLogInModal ? <JADBioLoginModal onClose={() => setShowLoginModal(false)} /> : null;
}

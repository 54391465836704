import type { ExtendedPreviewVariable, VariableToMatch } from 'types/data/projects/import/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { selectImportVariableSet, setImportVariableSetAction } from 'store/data/projects';
import { Nullable } from 'types/index';

interface DataType {
	isImportVariableSet: boolean;
	importVariableSetName: string;
	variableToMatchOnMainLevel: VariableToMatch | null;
	columnToMatchWithMainLevel: Nullable<ExtendedPreviewVariable>;
}

interface Handlers {
	setIsImportVariableSet: (isImportVariableSet: boolean) => void;
	setImportVariableSetName: (setName: string) => void;
	setVariableToMatchOnMainLevel: (variableToMatchOnMainLevel: VariableToMatch) => void;
	setColumnToMatchWithMainLevel: (columnToMatchWithMainLevel: ExtendedPreviewVariable) => void;
}

export function useImportVariableSet(): LocalOperationResult<DataType, Handlers> {
	const dispatch = useDispatch();

	const {
		isImportVariableSet,
		importVariableSetName,
		variableToMatchOnMainLevel,
		columnToMatchWithMainLevel
	} = useSelector(state => selectImportVariableSet(state.data.projects));

	function setIsImportVariableSet(isImportVariableSet: boolean) {
		dispatch(
			setImportVariableSetAction({
				importVariableSet: { isImportVariableSet: isImportVariableSet }
			})
		);
	}

	function setImportVariableSetName(setName: string) {
		dispatch(
			setImportVariableSetAction({
				importVariableSet: { importVariableSetName: setName }
			})
		);
	}

	function setVariableToMatchOnMainLevel(variableToMatchOnMainLevel: VariableToMatch) {
		dispatch(
			setImportVariableSetAction({
				importVariableSet: { variableToMatchOnMainLevel }
			})
		);
	}

	function setColumnToMatchWithMainLevel(columnToMatchWithMainLevel: ExtendedPreviewVariable) {
		dispatch(
			setImportVariableSetAction({
				importVariableSet: { columnToMatchWithMainLevel }
			})
		);
	}

	return [
		{
			isImportVariableSet,
			importVariableSetName,
			variableToMatchOnMainLevel,
			columnToMatchWithMainLevel
		},
		{
			setIsImportVariableSet,
			setImportVariableSetName,
			setVariableToMatchOnMainLevel,
			setColumnToMatchWithMainLevel
		}
	];
}

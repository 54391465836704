export enum VariableType {
	Category = 'category',
	CategoryMultiple = 'categoryMultiple',
	Date = 'date',
	DateTime = 'datetime',
	Float = 'float',
	Integer = 'integer',
	String = 'string',
	File = 'file',
	Status = 'status',
	Unique = 'userDefinedUnique',
	TimeDuration = 'timeDuration'
}

export enum EntryVariableType {
	Entry = 'entry',
	Calculated = 'calculated'
}

export enum VariableLabels {
	Category = 'variableLabels.category',
	CategoryMultiple = 'variableLabels.categoryMultiple',
	Integer = 'variableLabels.integer',
	Float = 'variableLabels.float',
	Date = 'variableLabels.date',
	DateTime = 'variableLabels.dateTime',
	String = 'variableLabels.string',
	File = 'variableLabels.file',
	Unique = 'variableLabels.userDefinedUnique',
	TimeDuration = 'variableLabels.timeDuration'
}

export enum VariableEntryLabels {
	Entry = 'variableEntryLabels.entry',
	Calculated = 'variableEntryLabels.calculated'
}

export enum FixedCategories {
	Yes = 'yes',
	No = 'no'
}

export enum ComparisonOperator {
	LessThan = '<',
	LessThanOrEqual = '<=',
	GreaterThan = '>',
	GreaterThanOrEqual = '>=',
	Equals = '=='
}

export enum ArithmeticOperator {
	Addition = '+',
	Subtraction = '-',
	Multiplication = '*',
	Division = '/'
}

export enum CaseType {
	LogicalOperation = 'logicalOperation',
	ArithmeticOperation = 'arithmeticOperation',
	AdditionDate = 'additionDate',
	SubtractionDate = 'subtractionDate'
}

export enum ConditionType {
	If = 'if',
	IfAnd = 'ifAnd',
	And = 'and',
	Operation = 'operation',
	Date = 'date'
}

export enum OperandType {
	NumberVariable = 'number-variable',
	DateVariable = 'date-variable',
	DurationVariable = 'duration-variable',
	NumberInput = 'number-input',
	ArithmeticOperation = 'arithmetic-operation',
	DateInput = 'dateInput',
	String = 'string',
	Category = 'category'
}

export enum RuleType {
	LogicalOperation = 'logical-operation',
	ArithmeticOperation = 'arithmetic-operation',
	DateSubtractionOperation = 'date-subtraction-operation',
	DateAdditionOperation = 'date-addition-operation',
	DurationOperation = 'duration-operation'
}

export enum OperatorLabel {
	Minus = '-',
	Plus = '+',
	Multiply = '*',
	Divide = '/',
	GreaterThan = 'greaterThan',
	GreaterThanOrEqual = 'greaterThanOrEqual',
	Equals = 'equals',
	LessThan = 'lessThan',
	LessThanOrEqual = 'lessThanOrEqual'
}

export enum DataType {
	String = 'string',
	Integer = 'integer',
	Float = 'float',
	Date = 'date',
	DateTime = 'datetime'
}

export enum ConditionOperator {
	AlwaysTrue = 'no_op_always_true',
	Minus = 'op1_minus_op2',
	Plus = 'op1_plus_op2',
	Multiply = 'op1_multiplied_by_op2',
	Divided = 'op1_divided_by_op2',
	GreaterThan = 'op1_greater_than_op2',
	GreaterThanOrEqual = 'op1_greater_than_or_equals_op2',
	Equals = 'op1_equals_op2',
	LessThan = 'op1_less_than_op2',
	LessThanOrEqual = 'op1_less_than_or_equals_op2',
	String = 'op1_string_val',
	Integer = 'op1_integer_val',
	Float = 'op1_float_val',
	Date = 'op1_date_val',
	DateTime = 'op1_datetime_val',
	InputWithSubType = 'op1_integer_val_op2_subtype',
	NullValue = 'no_op_null_val',
	Variable = 'op1_variable_ref',
	Or = 'op1_or_op2',
	And = 'op1_and_op2',
	InList = 'op1_is_in_list_op2'
}

import styled from 'styled-components';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	.boldText input {
		font-weight: 500;
	}
	.country-code {
		width: 16rem;
	}
	.time {
		width: 22rem;
	}
`;

export const HalfRow = styled.div`
	display: flex;
	width: 61%;

	@media ${MediaQueries.maxWidth.md} {
		width: 100%;
	}
`;

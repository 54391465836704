import { TableFilter as FilterProps } from 'store/ui/tables/types';
import { Offset, TableFilterType } from 'types/index';

import { CheckboxFilter } from './CheckboxFilter';
import { DateFilter } from './DateFilter';
import { NumericFilter } from './NumericFilter';
import { TextFilter } from './Text';

export interface TableFilterProps {
	title: string;
	width?: number;
	offset?: Offset;
	isLastColumn?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	updateFilter: (filter: FilterProps) => void;
	resetFilter: () => void;
}

interface Props extends TableFilterProps {
	filter: FilterProps;
}

export function TableFilter({ filter, ...props }: Props) {
	switch (filter.filterType) {
		case TableFilterType.Text: {
			return <TextFilter {...props} filter={filter} />;
		}
		case TableFilterType.Checkbox: {
			return <CheckboxFilter {...props} filter={filter} />;
		}
		case TableFilterType.Date: {
			return <DateFilter {...props} filter={filter} />;
		}
		case TableFilterType.Numeric: {
			return <NumericFilter {...props} filter={filter} />;
		}
		default:
			return <></>;
	}
}

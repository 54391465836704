import { useSelector } from 'hooks/utils';
import { selectVariableByName } from 'store/data/variables';

export function useVariableByName(variableName: string | null, options?: { initial?: boolean }) {
	const { initial = false } = options ?? {};

	return useSelector(state =>
		selectVariableByName(state.data.variables, { variableName, initial })
	);
}

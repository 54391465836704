import { ApiRole, ApiTemplateRole } from 'api/data/roles';
import {
	parseApiCollaboratorPermissions,
	parseCollaboratorPermissions
} from 'store/data/collaborators/parsers';
import { Role, TemplateRole } from 'store/data/roles';

/*
 * from API
 */

export function parseApiTemplateRole(apiTemplateRole: ApiTemplateRole): TemplateRole {
	const { roleTemplateId, roleName, roleDescription, accessPublicTemplate, ...permissions } =
		apiTemplateRole;

	return {
		id: roleTemplateId.toString(),
		name: roleName,
		description: roleDescription,
		publicAccess: accessPublicTemplate,
		permissions,
		shareList: {
			projectShareList: {
				current: [],
				initial: []
			},
			userShareList: {
				current: [],
				initial: []
			},
			fetched: false
		}
	};
}

export function parseApiTemplateRoles(apiTemplateRoles: ApiTemplateRole[]) {
	return apiTemplateRoles.map(parseApiTemplateRole);
}

export function parseApiRole(apiRole: ApiRole): Role {
	const { projectId, projectRoleId, projectRoleName, projectRoleDescription, ...apiPermissions } =
		apiRole;

	const permissions = parseApiCollaboratorPermissions(apiPermissions);

	return {
		projectId: projectId.toString(),
		id: projectRoleId.toString(),
		name: projectRoleName,
		description: projectRoleDescription,
		permissions
	};
}

export function parseApiRoles(apiRoles: ApiRole[]): Role[] {
	return apiRoles.map(parseApiRole);
}

/*
 * to API
 */

export function parseRole(role: Role): ApiRole {
	const { projectId, id, name, description, permissions } = role;

	const apiPermissions = parseCollaboratorPermissions(permissions);

	return {
		projectId: Number(projectId),
		projectRoleId: Number(id),
		projectRoleName: name,
		projectRoleDescription: description,
		...apiPermissions
	};
}

export function parseTemplateRole(templateRole: TemplateRole): ApiTemplateRole {
	const { id, name, description, publicAccess, permissions } = templateRole;

	return {
		roleTemplateId: Number(id),
		roleName: name,
		roleDescription: description,
		accessPublicTemplate: publicAccess,
		...permissions
	};
}

import styled from 'styled-components';

import { Typography } from 'components/UI/Typography';

export const TextRegular = styled(Typography.Paragraph)`
	margin-left: 1.2rem;
`;

export const VariablesContainer = styled.div`
	width: 100%;
`;

import { createSelector } from 'reselect';

import { selectParams } from 'store/utils';
import { GenericMap } from 'types/index';

import {
	State,
	StoredEntryFile,
	LocalEntryFile,
	EntryDraftData,
	SubEntries,
	SubEntriesCount
} from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getMetadata({ metadata }: State) {
	return metadata;
}

function getSubEntries({ subEntries }: State) {
	return subEntries;
}

function getRows({ rows }: State) {
	return rows;
}

function getRowsByPage({ rows }: State, page: number) {
	return rows.idsByPage[page]
		? rows.idsByPage[page]!.map(id => rows.byId.entries[id])
		: undefined;
}

function getErrors({ errors }: State) {
	return errors;
}

function getEntryFileById({ files: { byId } }: State, id: string) {
	if (byId[id]) return byId[id];
	else return null;
}

function getEntryById({ metadata: { entryId }, rows: { byId } }: State) {
	if (!entryId || !byId.entries[entryId]) return null;

	return byId.entries[entryId];
}

function getMissingFileIds({ files: { byId } }: State, ids: string[]) {
	const data: {
		files: GenericMap<StoredEntryFile | LocalEntryFile>;
		missingFileIds: string[];
	} = {
		files: {},
		missingFileIds: []
	};

	ids.forEach(id => {
		if (byId[id]) {
			data.files[id] = byId[id];
		} else {
			data.missingFileIds.push(id);
		}
	});

	return data;
}

function getStatusById({ metadata: { entryId }, statuses: { byId } }: State, initial?: boolean) {
	if (entryId) {
		if (initial) {
			return byId.initial[entryId];
		} else {
			return byId.current[entryId];
		}
	}

	return null;
}

function getStatusesById({ statuses: { byId } }: State) {
	return byId;
}

function getPageIndexAndSize(state: State) {
	return {
		pageIndex: state.metadata.pageIndex,
		pageSize: state.metadata.pageSize,
		totalCount: state.metadata.totalCount
	};
}

function getValidationErrors(state: State) {
	return state.metadata.validationErrors;
}

function getEntryDraftData({ drafts, metadata: { entryId } }: State) {
	// GET DRAFT FOR EXISTING ENTRY
	if (entryId !== null) {
		if (drafts.byId[entryId]) return drafts.byId[entryId];

		const notFoundDraft: EntryDraftData = {
			data: null,
			fetched: false
		};

		return notFoundDraft;
	}
	// GET DRAFT FOR NEW ENTRY
	else {
		return drafts.new;
	}
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectEntriesRows = createSelector([getRows], rows => {
	const { byId, ids } = rows;

	return ids.map(entryId => byId.entries[entryId]);
});

export const selectEntriesRowsById = createSelector(
	[getRows, (_, ids: string[]) => ids],
	(rows, ids) => {
		const { byId } = rows;

		return ids.map(entryId => byId.entries[entryId]);
	}
);

export const selectEntriesRowsByPage = createSelector([getRowsByPage], entries => {
	return entries;
});

export const selectEntryFile = createSelector([getEntryFileById], entryFile => entryFile);

export const selectEntryFiles = createSelector([getMissingFileIds], entryFiles => entryFiles);

export const selectEntry = createSelector([getEntryById], entry => entry);

export const selectCurrentEntryData = createSelector(
	({ metadata: { entryId }, rows: { byId } }: State) => {
		if (!entryId || !byId.entries[entryId]) return null;

		return byId.entries[entryId];
	},
	entry => entry
);

export const selectEntryStatus = createSelector([getStatusById], entryStatus => entryStatus);

export const selectEntriesStatusMap = createSelector(
	[getStatusesById],
	entriesStatusMap => entriesStatusMap
);

export const selectEntriesTableParams = createSelector([getPageIndexAndSize], params => params);

export const selectValidationErrors = createSelector(
	[getValidationErrors],
	validationErrors => validationErrors
);

export const selectEntryDraft = createSelector([getEntryDraftData], entryDraft => entryDraft);

type SelectEntrySubEntriesParams = { setName: string };

export const selectEntrySubEntries = createSelector(
	[
		getMetadata,
		getSubEntries,
		// PARAMS
		(_: State, params: SelectEntrySubEntriesParams) => selectParams.encode(params)
	],
	(metadata, subEntries, params) => {
		const { setName } = selectParams.decode<SelectEntrySubEntriesParams>(params);

		const { entryId } = metadata;

		let data: SubEntries = {
			rows: {
				byId: {},
				ids: []
			},
			fetched: false
		};

		if (entryId && entryId in subEntries.byEntryId.initial) {
			const { bySetName } = subEntries.byEntryId.initial[entryId];

			if (setName in bySetName) data = bySetName[setName];
		}

		return data;
	}
);

export const selectSeriesEntry = createSelector(
	(state: State, setName: string, entryId: string | null, subEntryId: string | null) =>
		entryId && subEntryId
			? state.subEntries.byEntryId.current[entryId].bySetName[setName].rows.byId[subEntryId]
			: null,
	ids => ids
);

export const selectSeriesEntries = createSelector([selectEntrySubEntries], entrySubEntries => {
	const { byId, ids } = entrySubEntries.rows;

	return ids.map(entryId => byId[entryId]);
});

export const selectAreSeriesEntriesFetched = createSelector(
	[selectEntrySubEntries],
	entrySubEntries => entrySubEntries.fetched
);

export const selectSelectedSeriesEntry = createSelector(
	[getSubEntries],
	subEntries => subEntries.selected
);

export const selectEntriesTableVisibleColumns = createSelector(
	[getMetadata],
	metadata => metadata.columnSettings.visible
);

export const selectSeriesEntriesCount = createSelector(
	[getMetadata, getSubEntries],
	(metadata, subEntries) => {
		const { entryId } = metadata;

		let data: SubEntriesCount = {
			bySetName: {},
			fetched: false
		};

		if (entryId && entryId in subEntries.count.byEntryId) {
			const { bySetName, fetched } = subEntries.count.byEntryId[entryId];

			data = {
				bySetName,
				fetched
			};
		}

		return data;
	}
);

export const selectEntriesErrors = createSelector([getErrors], errors => errors);

export const selectIsLatestEntryFetched = createSelector([getRows], rows => rows.latestFetched);

export const selectConflictedData = createSelector(
	(state: State) => {
		const entryId = state.metadata.entryId;
		if (entryId) {
			return state.rows.byId.conflicted[entryId];
		}
		return null;
	},
	data => data
);

export const selectSeriesConflictedData = createSelector(
	(state: State) => {
		const entryId = state.subEntries.selected.subEntryId;
		if (entryId) {
			return state.subEntries.byEntryId.conflicted[entryId];
		}
		return null;
	},
	data => data
);

export const selectNamesFromUserIds = createSelector(
	({ metadata: { namesFromUserIds } }: State) => {
		return namesFromUserIds;
	},
	entry => entry
);

import produce from 'immer';

import {
	createCollaboratorsOrderItem,
	collaboratorsDataContainer,
	initStoreCollaboratorsData,
	isCollaboratorOrderItem
} from 'helpers/collaborators';

import initialState from './initialState';
import { ActionTypes, Actions, State, CollaboratorsViewOptions, CollaboratorsMap } from './types';

import { Actions as ProjectActions, ActionTypes as ProjectActionTypes } from '../projects/types';

export default (state: State = initialState, action: Actions | ProjectActions): State => {
	switch (action.type) {
		case ProjectActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					// INITIALIZE STRUCTURE
					byProjectId[projectId] = {
						data: initStoreCollaboratorsData(),
						fetched: false,
						metadata: {
							viewOption: CollaboratorsViewOptions.TABLE
						}
					};
				}
			});
		}

		case ProjectActionTypes.CREATE_PROJECT: {
			const {
				project: { organization, projectId },
				user
			} = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					// INITIALIZE STRUCTURE
					byProjectId[projectId] = {
						data: initStoreCollaboratorsData(),
						fetched: false,
						metadata: {
							viewOption: CollaboratorsViewOptions.TABLE
						}
					};

					const projectData = byProjectId[projectId];

					// BUILD COUNT FOR EACH COLLABORATOR;
					const collaboratorCount: Record<string, number> = {};

					if (organization) {
						const organizationOrderItem = createCollaboratorsOrderItem(
							organization.id
						).organization();

						projectData.data.order.push(organizationOrderItem);
						projectData.data.organizations.byId[organization.id] = organization;

						organization.collaborators.forEach(collaborator => {
							collaboratorCount[collaborator] =
								collaborator in collaboratorCount
									? ++collaboratorCount[collaborator]
									: 1;
						});

						projectData.data.organizations.default.id = organization.id;
					}

					if (user) {
						const orderItem = createCollaboratorsOrderItem(user.userId).collaborator();

						projectData.data.order.push(orderItem);

						projectData.data.collaborators.byId[user.userId] = {
							...user,
							organizationsCount: collaboratorCount[user.userId]
						};

						// PUSH OWNER TO MAIN ORGANIZATION
						if (organization) {
							projectData.data.organizations.byId[organization.id].collaborators.push(
								user.userId
							);
						}
					}
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		/*
			============================
						ASYNC
			============================
		*/

		case ActionTypes.GET_COLLABORATORS: {
			const { projectId, collaborators, organizationsData } = action.payload;

			return produce(state, draft => {
				const { byProjectId, selectedOrganizationsById } = draft;
				draft.refetch = false;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					// RESET DATA
					projectData.data = initStoreCollaboratorsData();

					// BUILD COUNT FOR EACH COLLABORATOR;
					const collaboratorCount: Record<string, number> = {};

					organizationsData.organizations.forEach(organization => {
						const orderItem = createCollaboratorsOrderItem(
							organization.id
						).organization();

						projectData.data.order.push(orderItem);
						projectData.data.organizations.byId[organization.id] = organization;

						organization.collaborators.forEach(collaborator => {
							collaboratorCount[collaborator] =
								collaborator in collaboratorCount
									? ++collaboratorCount[collaborator]
									: 1;
						});
					});

					projectData.data.organizations.default.id = organizationsData.default.id;

					collaborators.forEach(collaborator => {
						const orderItem = createCollaboratorsOrderItem(
							collaborator.userId
						).collaborator();

						projectData.data.order.push(orderItem);

						projectData.data.collaborators.byId[collaborator.userId] = {
							...collaborator,
							organizationsCount: collaboratorCount[collaborator.userId]
						};
					});

					projectData.fetched = true;
					selectedOrganizationsById[projectId] = [];
				}
			});
		}

		case ActionTypes.UPDATE_COLLABORATOR_PERMISSIONS: {
			const { collaboratorId, permissions } = action.payload;

			return produce(state, draft => {
				const { byProjectId, projectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					const { collaborators } = projectData.data;

					if (collaboratorId in collaborators.byId) {
						const collaborator = collaborators.byId[collaboratorId];

						collaborator.accessToProject = permissions;
					}
				}
			});
		}

		case ActionTypes.UPDATE_COLLABORATORS_PERMISSIONS: {
			const { collaboratorsWithPermissions } = action.payload;

			return produce(state, draft => {
				const { byProjectId, projectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					const { collaborators } = projectData.data;

					const collaboratorsByEmail: CollaboratorsMap = {};

					Object.values(collaborators.byId).forEach(collaborator => {
						collaboratorsByEmail[collaborator.emailAddress] = collaborator;
					});

					collaboratorsWithPermissions.forEach(({ emailAddress, permissions }) => {
						const collaborator = collaboratorsByEmail[emailAddress];

						if (collaborator) collaborator.accessToProject = permissions;
					});
				}
			});
		}

		case ActionTypes.REMOVE_COLLABORATOR: {
			const { collaboratorId } = action.payload;

			return produce(state, draft => {
				const { byProjectId, projectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					collaboratorsDataContainer(projectData.data).removeCollaborator(collaboratorId);
					projectData.data.order = projectData.data.order.filter(item =>
						isCollaboratorOrderItem(item) ? item.collaborator !== collaboratorId : item
					);
				}
			});
		}

		case ActionTypes.CREATE_ORGANIZATION: {
			const { projectId, organization } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					collaboratorsDataContainer(projectData.data).createOrganization(organization);
				}
			});
		}

		case ActionTypes.UPDATE_ORGANIZATION: {
			const { projectId, organization } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					if (organization.id in projectData.data.organizations.byId) {
						projectData.data.organizations.byId[organization.id] = organization;
					}
				}
			});
		}

		case ActionTypes.DELETE_ORGANIZATION: {
			const { projectId, organizationId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					collaboratorsDataContainer(projectData.data).deleteOrganization(organizationId);
				}
			});
		}

		case ActionTypes.ADD_COLLABORATORS_TO_ORGANIZATIONS: {
			const { projectId, organizationIds, collaborators } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					collaboratorsDataContainer(projectData.data).addCollaboratorsToOrganization(
						collaborators,
						organizationIds
					);
				}
			});
		}

		case ActionTypes.REMOVE_COLLABORATORS_FROM_ORGANIZATIONS: {
			const { projectId, organizationIds, collaborators } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (byProjectId[projectId]) {
					const projectData = byProjectId[projectId];

					// FILTER COLLABORATORS FROM ORGANIZATIONS
					collaboratorsDataContainer(
						projectData.data
					).removeCollaboratorsFromOrganizations(collaborators, organizationIds);
				}
			});
		}

		/*
			============================
						LOCAL
			============================
		*/

		case ActionTypes.SET_ORGANIZATIONS_BY_ID: {
			const { organizations } = action.payload;

			return produce(state, draft => {
				const { projectId, selectedOrganizationsById } = draft;

				if (projectId) selectedOrganizationsById[projectId] = organizations;
			});
		}

		case ActionTypes.SET_COLLABORATORS_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.searchTerm = term;
			});
		}

		case ActionTypes.SET_COLLABORATORS_VIEW_OPTION: {
			const { viewOption } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					byProjectId[projectId].metadata.viewOption = viewOption;
				}
			});
		}

		case ActionTypes.RESET_FETCHED_COLLABORATORS: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					byProjectId[projectId].fetched = false;
				}
			});
		}

		case ActionTypes.SET_REFETCH_COLLABORATORS: {
			return produce(state, draft => {
				draft.refetch = true;
			});
		}

		default: {
			return state;
		}
	}
};

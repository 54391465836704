import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useEffectOnce, useSelector } from 'hooks/utils';
import { ActionTypes, getEntry, selectEntry, Entry } from 'store/data/entries';

interface UseEntryOptions {
	lazy?: boolean;
}

export function useEntry(options?: UseEntryOptions): OperationResult<Entry | null> {
	const lazy = options?.lazy;

	const dispatch = useDispatch();

	const data = useSelector(state => selectEntry(state.data.entries));

	const [{ loading, error }] = useActivity(ActionTypes.GET_ENTRY);

	useEffectOnce(() => {
		if (!lazy) handler();
	});

	function handler() {
		dispatch(getEntry());
	}

	return [{ data, loading, error }, handler];
}

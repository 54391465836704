import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useImportTemplate } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	templateId: string;
	onClose: () => void;
}

export function ImportTemplateModal({ templateId, onClose }: Props) {
	const { translate } = useTranslation();

	const [{ loading: importingTemplate, error: errorImportingTemplate }, importTemplate] =
		useImportTemplate();

	// CLOSE MODAL AFTER SUCCESSFUL IMPORT
	useCompletedAction(importingTemplate, errorImportingTemplate, onClose);

	function importTemplateHandler() {
		importTemplate(templateId);
	}

	const importTemplateTitle = translate(dict => dict.templates.importTemplateTitle);
	const primaryWarningText = translate(dict => dict.templates.primaryWarningText);
	const importTemplateConfirmationQuestion = translate(
		dict => dict.templates.importTemplateConfirmationQuestion
	);

	return (
		<Modal
			size={s => s.s}
			title={importTemplateTitle}
			primary={{
				label: importTemplateTitle,
				loading: importingTemplate,
				onClick: importTemplateHandler
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{primaryWarningText}
				{'\n\n'}
				<strong>{importTemplateConfirmationQuestion}</strong>
			</Typography.Paragraph>
		</Modal>
	);
}

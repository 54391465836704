import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	ActionTypes,
	selectJADBioProject,
	JADBioProject,
	getJADBioProjectId
} from 'store/addons/jadbio';

export function useGetJADBioProjectId(): OperationResult<JADBioProject | null> {
	const dispatch = useDispatch();

	const projectId = useSelector(state => state.data.projects.projectId);

	const data = useSelector(state => selectJADBioProject(state.addons.jadbio, projectId));

	const fetched = data !== null;

	const [
		{ loading: getJADBioProjectLoading, error: getJADBioProjectError },
		getJADBioProjectResetError
	] = useActivity(ActionTypes.GET_JADBIO_PROJECT_ID, projectId);

	const [
		{ loading: initiateJADBioProjectLoading, error: initiateJADBioProjectError },
		initiateJADBioProjectResetError
	] = useActivity(ActionTypes.INITIATE_JADBIO_PROJECT, projectId);

	function handler() {
		if (getJADBioProjectError) getJADBioProjectResetError();
		if (initiateJADBioProjectError) initiateJADBioProjectResetError();
		dispatch(getJADBioProjectId());
	}

	return [
		{
			data,
			loading: getJADBioProjectLoading || initiateJADBioProjectLoading,
			error: getJADBioProjectError || initiateJADBioProjectError,
			fetched
		},
		handler
	];
}

import type { EntryFilter } from 'api/data/filters';
import { FiltersById, FiltersByProjectId } from 'store/data/filters';
import { StringArrayMap } from 'types';

export function removeFitlersWithNoValue(
	filtersStoreData: {
		projectId: string | null;
		byId: FiltersById;
		byProjectId: FiltersByProjectId;
		bySnapshotId: StringArrayMap;
		byVariableName: StringArrayMap;
		open?: boolean | undefined;
	},
	projectId: string | null
) {
	const filters: EntryFilter[] = [];

	if (projectId) {
		filters.push(
			...filtersStoreData.byProjectId[projectId].active
				.map((filterId: string) => filtersStoreData.byId[filterId])
				.filter(
					(f: EntryFilter) =>
						f.value !== undefined ||
						(f.values && f.values.length > 0) ||
						(f.from !== undefined && f.to !== undefined)
				)
		);
	}

	return filters;
}

import styled, { css } from 'styled-components';

import { getSidebarWidth } from 'components/UI/Grid';
import { MediaQueries } from 'environment';

interface WrapperProps {
	isDrawerOpen: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
	/* Giving all the available space for a smooth drag away from group UX */
	min-height: calc(100vh - 28rem);
	display: flex;
	flex-direction: column;

	${({ isDrawerOpen }) =>
		isDrawerOpen &&
		css`
			${getSidebarWidth({
				numberOfColumns: {
					xl: 14,
					l: 10,
					s: 1
				},
				noContainerGutter: true
			})}
		`}
`;

export const EmptyList = styled.div`
	flex: 1;
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	gap: 1.6rem 2.2rem;
	padding-bottom: 2.4rem;

	@media ${MediaQueries.maxWidth.hd} {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.xl} {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.lg} {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.md} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media ${MediaQueries.maxWidth.sm} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
`;

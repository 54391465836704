import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs, Colors } from 'environment';
import {
	LicenceModelQuantities,
	SubscriptionNameLabel,
	UserLicenceLabels,
	UserLicenceModel
} from 'store/account/subscription';

import { Wrapper, LicenceInfoContainer } from './SubscriptionDetails.style';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import {
	useTranslation,
	useSubscriptionApiKey,
	useSubscription,
	useLicenceOwnerFullName,
	useSubscriptionRules,
	useSubscriptionBundleFlag,
	useLicenceModels
} from 'hooks/store';
import UpdatingSubscriptionText from '../Common/UpdatingSubscriptionText/UpdatingSubscriptionText';
import { isCollaborator } from 'helpers/collaborators';

interface SubscriptionDetailsProps {
	setChangePlanModalVisible: (visible: boolean) => void;
}

export function SubscriptionDetails({ setChangePlanModalVisible }: SubscriptionDetailsProps) {
	const { translate } = useTranslation();
	const [{ data: subscriptionApiKey }] = useSubscriptionApiKey();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const [
		{
			data: {
				details,
				subscriptionTypes: {
					isNonSubscriber,
					isLedidiEnterprise,
					isLedidiCore,
					isLedidiCollaborator,
					isLedidiFreeTrial
				},
				userTypesAndRoles: { isSubscriptionLicenceUser, isPaidSubscriptionOwner }
			}
		}
	] = useSubscription();

	const [
		{
			data: {
				totalLicencesCount,
				usedLicencesCount,
				licenceQuantitiesByName,
				usedLicenceQuantitiesByName
			}
		}
	] = useLicenceModels();

	const licenceOwnerFullName = useLicenceOwnerFullName();

	const { canChangePlan, canCancelSubscription, isSubscriptionUpdating } = useSubscriptionRules();

	const isOnePaidLincenseOwner =
		details &&
		details.availableLicenceQuantity === 0 &&
		details.licenceQuantity === 1 &&
		isPaidSubscriptionOwner;

	function SubscriptionIcon() {
		if (isLedidiCollaborator) return <Svgs.IconCollaboratorPlan />;
		if (isLedidiCore && isOnePaidLincenseOwner) return <Svgs.AvatarPlanIcon />;
		if (isLedidiCore) return <Svgs.UsersNetwork />;
		if (isLedidiEnterprise) return <Svgs.IconEnterprisePlan />;
		return <Svgs.FreePlanIcon />;
	}

	function generateTotalLicencesText() {
		let totalLicencesString =
			totalLicencesCount +
			' ' +
			translate(({ accountUM }) =>
				totalLicencesCount > 1
					? accountUM.subscription.licencesInTotal
					: accountUM.subscription.licenceInTotal
			);
		if (subscriptionBundleFlag && licenceQuantitiesByName) {
			const licencesString = generateQuantitiesText(licenceQuantitiesByName);
			if (licencesString) totalLicencesString = totalLicencesString + ': ' + licencesString;
		}
		return totalLicencesString;
	}

	function generateActiveLicencesText() {
		let usedLicencesString =
			translate(({ accountUM }) => accountUM.subscription.activeLicences) +
			' ' +
			usedLicencesCount;

		if (subscriptionBundleFlag && usedLicenceQuantitiesByName && !isCollaborator) {
			const licencesString = generateQuantitiesText(usedLicenceQuantitiesByName);
			if (licencesString)
				usedLicencesString = usedLicencesString + ' (' + licencesString + ')';
		}
		return usedLicencesString;
	}

	/**
	 * returns text of the counted licences by licence model
	 */
	function generateQuantitiesText(totalLicencesQuantities: LicenceModelQuantities) {
		let licencesString = '';
		// extract just the licences quantities that are not 0
		let quantities: LicenceModelQuantities = {};
		Object.keys(totalLicencesQuantities).forEach(key => {
			if (totalLicencesQuantities[key] > 0)
				quantities = { ...quantities, [key]: totalLicencesQuantities[key] };
		});
		// add bundle licences text
		Object.keys(quantities).forEach((licenceKey, index) => {
			const licenceTerm =
				quantities[licenceKey] +
				' ' +
				translate(() => UserLicenceLabels[licenceKey as UserLicenceModel]);
			if (!licencesString) licencesString = licenceTerm;
			else
				licencesString =
					licencesString +
					` ${
						index === Object.keys(quantities).length - 1
							? translate(({ terms }) => terms.andLowerCase)
							: ', '
					} ` +
					licenceTerm;
		});

		return licencesString;
	}

	return (
		<Wrapper>
			<SubscriptionIcon />
			<LicenceInfoContainer>
				<Typography.H3>
					{translate(({ accountUM }) =>
						isNonSubscriber
							? accountUM.subscription.noSubscriptionPlan
							: details?.subscriptionName
							? SubscriptionNameLabel[details?.subscriptionName]
							: ''
					)}
				</Typography.H3>

				{isSubscriptionUpdating && <UpdatingSubscriptionText />}

				{!isSubscriptionUpdating && (
					<>
						<Typography.Caption>
							{totalLicencesCount === 0
								? translate(({ accountUM }) => accountUM.subscription.noLicence)
								: generateTotalLicencesText()}
						</Typography.Caption>

						{totalLicencesCount > 0 && (
							<Typography.Paragraph>
								{generateActiveLicencesText()}
							</Typography.Paragraph>
						)}

						<Spacer size={s => s.s} />

						{isSubscriptionLicenceUser && (
							<Typography.Paragraph color={Colors.text.disabled}>
								{translate(
									({ accountUM }) => accountUM.subscription.licenceManagedBy
								)}
								{licenceOwnerFullName}
							</Typography.Paragraph>
						)}

						{canChangePlan && (!subscriptionBundleFlag || isNonSubscriber) && (
							<Button
								title={
									isNonSubscriber
										? translate(({ buttons }) => buttons.subscribe)
										: translate(
												({ accountUM }) => accountUM.changePlan.changePlan
										  )
								}
								variant={v => v.link}
								paddingOffset={{ all: 0 }}
								// style={{ cursor: 'pointer' }}
								// fontweight={f => f.bold}
								// color={Colors.primary.normal}
								onClick={() => setChangePlanModalVisible(true)}
							/>
						)}

						{subscriptionBundleFlag && canCancelSubscription && (
							<Button
								title={translate(
									({ accountUM }) =>
										accountUM.cancelSubscription.cancelSusbcription
								)}
								variant={v => v.link}
								paddingOffset={{ all: 0 }}
								onClick={() => setChangePlanModalVisible(true)}
							/>
						)}

						{subscriptionBundleFlag && isLedidiFreeTrial && (
							<Button
								title={translate(
									({ accountUM }) => accountUM.cancelSubscription.cancelFreeTrial
								)}
								variant={v => v.link}
								paddingOffset={{ all: 0 }}
								onClick={() => setChangePlanModalVisible(true)}
							/>
						)}
					</>
				)}
			</LicenceInfoContainer>
			{subscriptionApiKey && (
				<Flex align={a => a.center}>
					{
						<Typography.Paragraph>
							{translate(({ accountUM }) => accountUM.subscription.apiKey, false, {
								apiKey: subscriptionApiKey
							})}
						</Typography.Paragraph>
					}
				</Flex>
			)}
		</Wrapper>
	);
}

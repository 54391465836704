import { DraggedFile, SuggestedVariableTypes } from 'api/data/projects';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	uploadUrl,
	ActionTypes,
	resetCsvDataAction,
	selectFileData,
	resetCsvAction
} from 'store/data/projects';
import { FileImportErrors } from 'types/data/projects/types';
import { Nullable } from 'types/index';
import { ImportType } from '../../../../types/enums';
import { useTranslation } from 'hooks/store';

interface Handlers {
	uploadUrl: (
		projectId: number,
		file: DraggedFile,
		isBinaryFile: boolean,
		dispatchSuggestedVariales?: boolean
	) => void;
	resetCsvData: () => void;
	resetCsv: () => void;
}

interface DataType {
	suggestedVariableTypes?: Nullable<SuggestedVariableTypes[]>;
	fileId?: string;
	isBinary?: boolean;
}
type Params = {
	importType?: Omit<ImportType, ImportType.Manual>;
	onAPIError?: (errors: FileImportErrors) => void;
};

export function useUploadUrl(params?: Params): OperationResult<DataType, Handlers> {
	const { translate } = useTranslation();
	const dispatch = useDispatch();
	const { timeDurationFlag, timezoneDuringImport } = useFlags();

	const [{ loading, error }] = useActivity(ActionTypes.UPLOAD_URL);
	const data = useSelector(state => selectFileData(state.data.projects, timeDurationFlag));

	function handler(
		projectId: number,
		file: DraggedFile,
		isBinaryFile: boolean,
		dispatchSuggestedVariales = true
	) {
		dispatch(
			uploadUrl(
				projectId,
				file,
				isBinaryFile,
				dispatchSuggestedVariales,
				translate,
				timezoneDuringImport,
				params?.importType,
				params && params.onAPIError ? { onAPIError: params.onAPIError } : undefined
			)
		);
	}

	function resetCsvData() {
		dispatch(resetCsvDataAction());
	}

	function resetCsv() {
		dispatch(resetCsvAction());
	}

	const handlers = {
		uploadUrl: handler,
		resetCsvData,
		resetCsv
	};

	return [{ loading, error, data }, handlers];
}

import { useEffect } from 'react';
import { ROUTES } from 'types/navigation';
import { useNavigation } from 'hooks/navigation';
import { useAccount } from './useAccount';

export function useProfileIncomplete() {
	const { replace } = useNavigation();

	const [
		{
			data: { isProfileIncomplete },
			fetched: isAccountFetched
		}
	] = useAccount();

	useEffect(() => {
		if (isAccountFetched && isProfileIncomplete) replace(ROUTES.Login);
	}, [isAccountFetched, isProfileIncomplete]);
}

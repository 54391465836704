import { useTranslation } from 'hooks/store';

import { EllipsisAnimation, UpdatingSubscription } from './UpdatingSubscriptionText.style';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';

export default function UpdatingSubscriptionText() {
	const { translate } = useTranslation();

	const updatingText = translate(({ accountUM }) => accountUM.changePlan.subscriptionIsUpdating);
	const updatingTextLastWord = updatingText.split(' ').pop();
	const updatingTextWithoutLastWord = updatingText.substring(0, updatingText.lastIndexOf(' '));

	return (
		<UpdatingSubscription>
			<Flex align={a => a.center} justify={j => j.start}>
				<Typography.Paragraph>
					{updatingTextWithoutLastWord + ' '}
					<div
						style={{
							display: 'inline-block'
						}}
					>
						{updatingTextLastWord}
						<EllipsisAnimation />
					</div>
				</Typography.Paragraph>
			</Flex>
		</UpdatingSubscription>
	);
}

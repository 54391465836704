import { VariableType } from 'types/data/variables/constants';
import type { StringMap } from 'types/maps';

export enum DATE_FORMATS {
	Line = 'DD-MM-YYYY',
	Dash = 'DD/MM/YYYY',
	Dot = 'DD.MM.YYYY',
	Custom = 'Custom'
}

export const DATE_YEAR_TOKENS = ['YY', 'YYYY'];
export const DATE_TOKENS = ['MM', 'DD', ...DATE_YEAR_TOKENS];
export const TIME_TOKENS = ['HH', 'hh', 'mm', 'MM', 'ss', 'SS', 'sss', 'SSS'];
export const SEPARATORS = /[.|/\\,_\-:;]/g;

export const PYTHON_TO_JS_CONVERSION_MAP: StringMap = {
	'%Y': 'YYYY',
	'%y': 'YY',
	'%m': 'MM',
	'%d': 'DD',
	'%H': 'HH',
	'%M': 'MM',
	'%S': 'SS',
	'%f': 'SSS',
	'%z': '+HH:MM'
};

export enum DATE_TIME_FORMATS {
	Line = 'DD-MM-YYYY HH:mm:ss',
	Dash = 'DD/MM/YYYY HH:mm:ss',
	Dot = 'DD.MM.YYYY HH:mm:ss'
	// Custom = 'Custom'
}

export const TIME_ZONE_TOKENS = ['HH:MM', 'HHMM'];

export const ACCEPTED_DATA_TYPES = [
	VariableType.Integer,
	VariableType.Float,
	VariableType.Date,
	VariableType.DateTime,
	VariableType.String,
	VariableType.Category,
	VariableType.Unique,
	VariableType.CategoryMultiple
];

export const DATE_TYPE_REGEX = new RegExp(`^${VariableType.Date}(\\(.+?\\))$`, 'i');
export const DATE_TIME_TYPE_REGEX = new RegExp(`^${VariableType.DateTime}(\\(.+?\\))$`, 'i');

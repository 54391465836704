import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';

import { Colors, Svgs } from 'environment';

interface Props {
	text: string;
}

export function AdvantageInfo({ text }: Props) {
	return (
		<Flex align={a => a.start}>
			<Icon
				active
				marginOffset={{ top: 0.3, left: 3.6, right: 0.8 }}
				size={s => s.s}
				svg={Svgs.CheckCircle}
				colors={{ active: Colors.vibrantGreen }}
			/>
			<Typography.Caption>{text}</Typography.Caption>
		</Flex>
	);
}

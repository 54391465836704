import { useMatchProjects } from 'hooks/navigation';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { setProjectSort, ProjectSort } from 'store/data/projects';

export function useProjectsSort(): LocalOperationResult<ProjectSort, (sort: ProjectSort) => void> {
	const dispatch = useDispatch();
	const matchProjects = useMatchProjects();

	const projectsData = useSelector(state => state.data.projects.filters.projects.sort);

	const promsData = useSelector(state => state.data.projects.filters.proms.sort);

	function handler(sort: ProjectSort) {
		dispatch(setProjectSort({ sort, isProject: matchProjects }));
	}

	const data = matchProjects ? projectsData : promsData;

	return [data, handler];
}

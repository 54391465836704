import { AnalysisVariable, ComparePairedResultsV2, ComparePairedValueV2 } from 'api/data/analyses';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { VariablesMap } from 'store/data/variables';

import { computeCellValueForExport } from './computeData';
import { AggregationRule } from 'api/data/variables';
import { buildVariableCategoriesMap } from 'helpers/variables';

interface TableRows {
	nrOfRows: string[];
	average: string[];
	std: string[];
	confidentLower: string[];
	confidentUpper: string[];
	sem: string[];
	variance: string[];
	skewness: string[];
	kurtosis: string[];
	median: string[];
	min: string[];
	max: string[];
	range: string[];
	IQRLower: string[];
	IQRUpper: string[];
	missing: string[];
}

export function exportComparePairedV2(
	dataset: ComparePairedResultsV2,
	{
		translate,
		variablesMap,
		groupVariable,
		aggRuleToVariableNameMap
	}: {
		translate: TranslateFunction;
		variablesMap: VariablesMap;
		groupVariable: AnalysisVariable | null;
		aggRuleToVariableNameMap: Record<string, AggregationRule>;
	}
) {
	const groupVariableValue =
		variablesMap[groupVariable?.name ?? ''] ??
		variablesMap[aggRuleToVariableNameMap[groupVariable?.name ?? '']?.aggregator?.variableName];

	const categories = buildVariableCategoriesMap(groupVariableValue?.categories ?? []);

	const leftRows: string[] = [
		translate(({ analysis }) => analysis.generic.n),
		translate(({ analysis }) => analysis.generic.mean),
		translate(({ analysis }) => analysis.generic.sd),
		translate(({ analysis }) => analysis.generic.confidenceLower),
		translate(({ analysis }) => analysis.generic.confidenceUpper),
		translate(({ analysis }) => analysis.generic.sem),
		translate(({ analysis }) => analysis.generic.variance),
		translate(({ analysis }) => analysis.generic.skewness),
		translate(({ analysis }) => analysis.generic.kurtosis),
		translate(({ analysis }) => analysis.generic.median),
		translate(({ analysis }) => analysis.generic.min),
		translate(({ analysis }) => analysis.generic.max),
		translate(({ analysis }) => analysis.generic.range),
		translate(({ analysis }) => analysis.generic.IQRLower),
		translate(({ analysis }) => analysis.generic.IQRUpper),
		translate(({ analysis }) => analysis.generic.missing)
	];

	const columns: string[] = [];
	const rows: TableRows = {
		nrOfRows: [],
		average: [],
		std: [],
		confidentLower: [],
		confidentUpper: [],
		sem: [],
		variance: [],
		skewness: [],
		kurtosis: [],
		median: [],
		min: [],
		max: [],
		range: [],
		IQRLower: [],
		IQRUpper: [],
		missing: []
	};

	if (dataset.data) {
		dataset.data.forEach(rowData => {
			addRow(rowData, getColumnName(rowData.variable.name));
		});
	}

	function addRow(row: ComparePairedValueV2, columnName: string) {
		columns.push(columnName);

		rows.nrOfRows.push(computeCellValueForExport(row['n'], { noDecimals: true }));
		rows.average.push(computeCellValueForExport(row.mean));
		rows.std.push(computeCellValueForExport(row.sd));
		rows.confidentLower.push(computeCellValueForExport(row.lowerCI));
		rows.confidentUpper.push(computeCellValueForExport(row.upperCI));
		rows.sem.push(computeCellValueForExport(row.sem));
		rows.variance.push(computeCellValueForExport(row.variance));
		rows.skewness.push(computeCellValueForExport(row.skewness));
		rows.kurtosis.push(computeCellValueForExport(row.kurtosis));
		rows.median.push(computeCellValueForExport(row.median));
		rows.min.push(computeCellValueForExport(row.min));
		rows.max.push(computeCellValueForExport(row.max));
		rows.range.push(computeCellValueForExport((Number(row.max) - Number(row.min)).toString()));
		rows.IQRLower.push(computeCellValueForExport(row.lowerIQR));
		rows.IQRUpper.push(computeCellValueForExport(row.upperIQR));
		rows.missing.push(computeCellValueForExport(row.missing, { noDecimals: true }));
	}

	function getColumnName(variableName: string) {
		const names: string[] = [];
		variableName.split(' - ').forEach(variableName => {
			const variable =
				variablesMap[variableName] ??
				variablesMap[aggRuleToVariableNameMap[variableName]?.aggregator?.variableName];

			if (!variable) {
				names.push(categories?.[variableName]?.label || categories?.[variableName]?.value);
			} else {
				names.push(variable.label);
			}
		});

		return names.join(' - ');
	}

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';

	// LEFT-TOP CORNER SPACER
	csv += comma;
	// ADD COLUMNS
	csv += columns.join(comma) + newLine;

	Object.keys(rows).forEach((key, index) => {
		const row = rows[key as keyof TableRows];

		const leftRowColumn = leftRows[index];

		csv += leftRowColumn + comma + row + newLine;
	});

	return csv;
}

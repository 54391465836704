import { isEmail } from 'api/utils/helpers';
import { Icon } from 'components/UI/Icons';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Loader } from 'components/UI/Loader';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { UserCard } from 'components/UI/UserCard';
import { Svgs } from 'environment';
import { useResetRoleTemplateShareListFetched } from 'hooks/store/data/roles/useResetRoleTemplateShareListFetched';
import { useResetShareList } from 'hooks/store/data/roles/useResetShareList';
import { useRoleTemplateShareList } from 'hooks/store/data/roles/useRoleTemplateShareList';
import { useSearchUserByEmail } from 'hooks/store/data/roles/useSearchUserByEmail';
import { useShareRoleTemplateWithInstance } from 'hooks/store/data/roles/useShareRoleTemplateWithInstance';
import { useUnshareRoleTemplate } from 'hooks/store/data/roles/useUnshareRoleTemplate';
import { useUnshareRoleTemplateWithInstance } from 'hooks/store/data/roles/useUnshareRoleTemplateWithInstance';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { ActionTypes as ProjectActionTypes } from 'store/data/projects';
import { InputType, Nullable, RoleTemplateShareLevel } from 'types/index';
import { useShareRoleTemplate } from '../../../hooks/store/data/roles/useShareRoleTemplate';
import { useCompletedAction, useKeyPress, useMutableState } from '../../../hooks/utils';
import { Container, ListHeader, Receipient, ReceipientsList } from './ShareRoleTemplateModal.style';
import {
	useTranslation,
	useAllProjectsNameId,
	useScopedProjects,
	useUsersByIds,
	useUsers,
	useMissingUsersInfo,
	useActivities
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';

interface Props {
	shareLevel: RoleTemplateShareLevel;
	currentUserId: string;
	templateRoleId: string;
	onClose: (success?: boolean) => void;
}

interface SelectValue {
	label: string;
	value: string;
}

export function ShareRoleTemplateModal({
	shareLevel,
	currentUserId,
	templateRoleId,
	onClose
}: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const isShareWithUser = shareLevel === RoleTemplateShareLevel.ShareWithUsers;
	const isShareWithProjects = shareLevel === RoleTemplateShareLevel.ShareWithProjects;

	const [sharedWith, setSharedWith] = useState<SelectValue[]>([]);
	const [userEmailsSharedWith, setUserEmailsSharedWith] = useState<string[]>([]);
	const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
	const [fetchNewCollaborator, setFetchNewCollaborator] = useState('');
	const [searchInputValidator, setSearchInputValidator] = useMutableState({
		invalidEmail: false,
		invalidUser: false,
		allreadyShared: false,
		message: ''
	});

	const allProjects = useAllProjectsNameId();

	const [
		{
			data: {
				projectShareList: {
					current: projectsSharedWith,
					initial: initialProjectsSharedWith
				},
				userShareList: { current: usersSharedWith, initial: initialUsersSharedWith }
			},
			loading: loadingShareList,
			error: shareListError,
			fetched: shareListFetched
		},
		getShareList
	] = useRoleTemplateShareList({ templateRoleId });

	/**
	 * FETCH
	 */
	useScopedProjects({ lazy: !isShareWithProjects });
	const [usersByIds, getUsersByIds] = useUsersByIds();

	const [{ error: errorSharingTemplateRole, loading: sharingTemplateRole }, shareTemplateRole] =
		useShareRoleTemplate();
	const [
		{ error: errorUnsharingTemplateRole, loading: unsharingTemplateRole },
		unshareTemplateRole
	] = useUnshareRoleTemplate();

	const usersByEmail = useUsers();

	const shareWithInstance = useShareRoleTemplateWithInstance();
	const unshareWithInstance = useUnshareRoleTemplateWithInstance();
	const searchUserByEmail = useSearchUserByEmail();
	const resetShareList = useResetShareList();
	const resetFetched = useResetRoleTemplateShareListFetched();
	const getUsersDetailsByEmail = useMissingUsersInfo();

	const [{ loading: loadingProjects }] = useActivities([
		ProjectActionTypes.GET_PROJECT,
		ProjectActionTypes.GET_PROJECTS,
		ProjectActionTypes.GET_PROMS
	]);

	const loadingAction = sharingTemplateRole || unsharingTemplateRole;
	const errorAction = errorSharingTemplateRole || errorUnsharingTemplateRole;

	useCompletedAction(loadingAction, errorAction, () => {
		// TODO: Add to Dictionary
		setNotification({
			message: translate(dict => dict.terms.changesSaved)
		});
		resetFetched({ templateRoleId });
	});

	const loading =
		loadingProjects || sharingTemplateRole || loadingShareList || unsharingTemplateRole;

	useEffect(() => {
		if (!shareListFetched && !loadingShareList && !shareListError) {
			getShareList();
		}
	}, [shareListFetched]);

	useEffect(() => {
		const trimmedUser = fetchNewCollaborator.trim();
		if (isEmail(trimmedUser)) {
			if (userEmailsSharedWith.includes(trimmedUser)) {
				setSearchInputValidator(state => {
					state.allreadyShared = true;
					state.message = `${translate(
						dict => dict.rolesPage.shareRoleTemplateModal.alreadySharedWith
					)} ${trimmedUser}`;
				});
				return;
			}

			const collaborator = usersByEmail[trimmedUser];
			if (collaborator) {
				const { userid, emailAddress } = collaborator;
				const userId = userid ?? '';
				shareWithInstance({ instanceId: userId, templateRoleId, userShare: true });
				if (!usersByIds[userId]) getUsersByIds([userId]);

				setUserEmailsSharedWith(state => [...state, emailAddress]);
				setFetchNewCollaborator('');
			}
		}
	}, [fetchNewCollaborator, usersByEmail, usersByIds]);

	useEffect(() => {
		if (!sharingTemplateRole) {
			const sharedWithInstances: SelectValue[] = [];

			if (usersSharedWith && isShareWithUser) {
				usersSharedWith.forEach(user => {
					const currentUser = currentUserId === user.toString();

					if (currentUser) return;
					const foundUser =
						Object.values(usersByEmail).find(
							userByEmail => userByEmail.userid === user
						) || Object.values(usersByIds).find(userById => userById.userid === user);

					if (foundUser) {
						const { userFirstName, userSirName, userid } = foundUser;
						const fullName = `${userFirstName} ${userSirName}`;
						const userId = userid ?? '';
						return sharedWithInstances.push({
							label: fullName,
							value: userId
						});
					}
				});
			}
			if (projectsSharedWith && isShareWithProjects) {
				projectsSharedWith.forEach(project => {
					const foundProject = allProjects.find(
						projectSelect => projectSelect.value === project
					);
					if (foundProject) {
						const { label, value } = foundProject;
						sharedWithInstances.push({
							label,
							value
						});
					}
				});
			}

			const uniqueSharedInstances = sharedWithInstances.filter(
				(selectValue, index, array) =>
					array.findIndex(element => element.value === selectValue.value) === index
			);

			setSharedWith(uniqueSharedInstances);
		}
	}, [
		Object.values(usersByEmail).length,
		Object.values(usersByIds).length,
		Object.values(usersSharedWith).length,
		Object.values(projectsSharedWith).length,
		allProjects,
		loading,
		shareListFetched
	]);

	useKeyPress(
		{
			onEnterKeyPress: () => {
				handleInputSubmit();
			}
		},
		{
			listen: isEmail(fetchNewCollaborator.trim())
		}
	);

	function primaryButtonHandler() {
		const projectsForUnsharing = initialProjectsSharedWith.filter(
			id => !projectsSharedWith.includes(id)
		);
		const projectsForSharing = projectsSharedWith.filter(
			id => !initialProjectsSharedWith.includes(id)
		);

		const usersForUnsharing = initialUsersSharedWith.filter(
			id => !usersSharedWith.includes(id)
		);
		const usersForSharing = usersSharedWith.filter(id => !initialUsersSharedWith.includes(id));

		if (projectsForSharing.length > 0 || usersForSharing.length > 0) {
			shareTemplateRole({
				templateRole: {
					id: Number(templateRoleId),
					sharedWithOrganization: false
				},
				userIds: usersForSharing,
				projectIds: projectsForSharing
			});
		}
		if (projectsForUnsharing.length > 0 || usersForUnsharing.length > 0) {
			unshareTemplateRole({
				templateRole: {
					id: Number(templateRoleId)
				},
				userIds: usersForUnsharing,
				projectIds: projectsForUnsharing
			});
		}
	}

	function handleInputSubmit() {
		const trimmedCollaborator = fetchNewCollaborator.trim();
		if (isEmail(trimmedCollaborator)) {
			if (
				!userEmailsSharedWith.includes(trimmedCollaborator) &&
				!usersByEmail[trimmedCollaborator]
			) {
				searchUserByEmail(
					{ emails: [trimmedCollaborator] },
					{
						onIsNotValidEmailList: (invalidEmails: string[]) => {
							setInvalidEmails(state => [...state, ...invalidEmails]);

							setSearchInputValidator(state => {
								state.invalidUser = true;
								state.message = translate(
									dict => dict.rolesPage.shareRoleTemplateModal.errorUserNotFound
								);
							});
						}
					}
				);
				getUsersDetailsByEmail([trimmedCollaborator]);
			}
		} else {
			setSearchInputValidator(state => {
				state.invalidEmail = true;
				state.message = translate(
					dict => dict.rolesPage.shareRoleTemplateModal.invalidEmail
				);
			});
		}
	}

	function onValueSelectHandler(selectedValue: Nullable<string>) {
		if (selectedValue) {
			const foundProject = allProjects?.find(project => project.value === selectedValue);

			if (foundProject) {
				const selectValuesArr = [...sharedWith, foundProject];

				const project = projectsSharedWith.find(
					projectSharedWith => projectSharedWith === foundProject.value
				);
				if (!project) {
					shareWithInstance({
						templateRoleId: templateRoleId,
						instanceId: foundProject.value,
						userShare: isShareWithUser
					});
				}
				const uniqueSharedInstances = selectValuesArr.filter(
					(selectValue, index, array) =>
						array.findIndex(element => element.value === selectValue.value) === index
				);
				setSharedWith(uniqueSharedInstances);
			}
		}
	}

	function onInputChangeHandler(inputValue: string) {
		if (searchInputValidator.invalidUser)
			setSearchInputValidator(state => {
				state.invalidUser = false;
				state.message = '';
			});

		if (searchInputValidator.invalidEmail)
			setSearchInputValidator(state => {
				state.invalidEmail = false;
				state.message = '';
			});

		if (searchInputValidator.allreadyShared)
			setSearchInputValidator(state => {
				state.allreadyShared = false;
				state.message = '';
			});

		if (invalidEmails.includes(inputValue) && searchInputValidator && isEmail(inputValue)) {
			setSearchInputValidator(state => {
				state.invalidUser = true;
				state.message = translate(
					dict => dict.rolesPage.shareRoleTemplateModal.errorUserNotFound
				);
			});
			setFetchNewCollaborator('');
		} else {
			setFetchNewCollaborator(inputValue);
		}
	}

	function handleClose() {
		resetShareList({ templateRoleId });
		setSharedWith([]);
		onClose();
	}

	const filteredProjects = allProjects.filter(
		project => !projectsSharedWith.includes(project.value)
	);

	const hasUsersChanges = isShareWithUser
		? !isEqual(usersSharedWith, initialUsersSharedWith)
		: undefined;
	const hasProjectChanges = isShareWithProjects
		? !isEqual(projectsSharedWith, initialProjectsSharedWith)
		: undefined;
	const hasChanges = hasUsersChanges ?? hasProjectChanges ?? false;

	const modalTitle = isShareWithUser
		? translate(dict => dict.rolesPage.shareRoleTemplateModal.shareWithUsers)
		: translate(dict => dict.rolesPage.shareRoleTemplateModal.shareAccrossProjects);
	const listTitle = isShareWithUser
		? translate(dict => dict.rolesPage.shareRoleTemplateModal.userList)
		: translate(dict => dict.rolesPage.shareRoleTemplateModal.projectList);
	const placeholder = isShareWithUser
		? translate(dict => dict.rolesPage.shareRoleTemplateModal.enterEmail)
		: translate(dict => dict.rolesPage.shareRoleTemplateModal.selectProjects);

	const primaryButtonLabel = translate(({ buttons }) =>
		hasChanges ? buttons.save : buttons.done
	);

	return (
		<Modal
			title={modalTitle}
			primary={{
				label: primaryButtonLabel,
				disabled: loading && !sharingTemplateRole,
				loading: sharingTemplateRole,
				onClick: hasChanges ? primaryButtonHandler : handleClose
			}}
			onClose={handleClose}
			visible
			close
		>
			<Container>
				{isShareWithProjects && (
					<CreatableSelect
						items={filteredProjects}
						allowCreate={isShareWithUser}
						placeholder={placeholder}
						onValueSelected={selectedValue => onValueSelectHandler(selectedValue)}
						formatCreateLabel={userInput =>
							`${translate(dict => dict.terms.searchFor)} "${userInput}"`
						}
					/>
				)}
				{isShareWithUser && (
					<Input
						value={fetchNewCollaborator}
						placeholder={placeholder}
						type={InputType.Text}
						onChange={e => onInputChangeHandler(e.target.value)}
						autoFocus
						onSubmit={handleInputSubmit}
						error={searchInputValidator.message}
					/>
				)}
				{loading ? (
					<Loader primary />
				) : (
					<>
						{sharedWith.length > 0 && <ListHeader>{listTitle}</ListHeader>}
						<ReceipientsList>
							<>
								{/* SHARE WITH USERS */}
								{isShareWithUser &&
									!loading &&
									sharedWith.map((recipient, index) => {
										const userById = usersByIds[recipient.value];
										const user = userById;

										if (user) {
											const { userFirstName, userSirName, emailAddress } =
												user;

											if (!userEmailsSharedWith.includes(emailAddress)) {
												setUserEmailsSharedWith(state => [
													...state,
													emailAddress
												]);
											}

											return (
												<Receipient key={recipient.value + index}>
													<UserCard.Basic
														userId={
															userById.userid ? userById.userid : null
														}
														userData={{
															userFirstName,
															userSirName,
															emailAddress
														}}
													/>
													<Icon
														svg={Svgs.Add}
														variant={v => v.button}
														onClick={() => {
															unshareWithInstance({
																templateRoleId: templateRoleId,
																userShare: isShareWithUser,
																instanceId: recipient.value
															});
															setUserEmailsSharedWith(state =>
																state.filter(
																	email => email !== emailAddress
																)
															);
														}}
														rotate={45}
													/>
												</Receipient>
											);
										}

										return null;
									})}

								{/* SHARE WITH PROJECT */}
								{isShareWithProjects &&
									sharedWith.map(recipient => {
										return (
											<Receipient key={recipient.value}>
												<Typography.Paragraph>
													{recipient.label}
												</Typography.Paragraph>
												<Icon
													svg={Svgs.Add}
													variant={v => v.button}
													onClick={() => {
														unshareWithInstance({
															templateRoleId: templateRoleId,
															userShare: isShareWithUser,
															instanceId: recipient.value
														});
														setSharedWith(state =>
															state.filter(
																project =>
																	project.value !==
																	recipient.value
															)
														);
													}}
													rotate={45}
												/>
											</Receipient>
										);
									})}
							</>
						</ReceipientsList>
					</>
				)}
			</Container>
		</Modal>
	);
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import {
	setFormElementMapValuesWithMoods,
	SetFormElementMapValuesWithMoodsAction
} from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormElementMapValuesWithMoods(): LocalOperation<
	(payload: ActionPayload<SetFormElementMapValuesWithMoodsAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormElementMapValuesWithMoodsAction>) {
		dispatch(setFormElementMapValuesWithMoods(payload));
	}

	return handler;
}

import { useContext } from 'react';

import { AnalysisContext } from 'contexts';

export function useAnalysisContext() {
	const context = useContext(AnalysisContext);

	if (context === undefined) {
		throw new Error('useAnalysisContext must be used within a `AnalysisContext.Provider`');
	}

	return context;
}

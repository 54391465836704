import {
	ApiCollaborator,
	ApiCollaboratorPermissions,
	ApiOrganization
} from 'api/data/collaborators';
import { UserStatus } from 'store/account/subscription';
import { Collaborator, CollaboratorPermissions, Organization } from 'store/data/collaborators';

/**
 * map `userid` key to `userId`
 */
export function parseApiCollaborator(apiCollaborator: ApiCollaborator): Collaborator {
	apiCollaborator.emailAddress = apiCollaborator.emailAddress.trim().toLowerCase();

	if (apiCollaborator.userId === undefined || apiCollaborator.status === UserStatus.Invited)
		apiCollaborator.pending = true;

	apiCollaborator.userId = apiCollaborator.userId ?? apiCollaborator.emailAddress;

	const { accessToProject: apiAccessToProject, ...rest } = apiCollaborator;

	const collaborator: Collaborator = {
		// TODO: remove this when the API is fixed
		projectRoleId: '',
		...rest
	};

	if (apiAccessToProject) {
		const {
			accessModuleProjectDesign,
			accessModuleCollaborators,
			statusTypeAccesses,
			projectRoleId,
			...generalPermissions
		} = apiAccessToProject;

		collaborator.projectRoleId = projectRoleId ? projectRoleId.toString() : '';

		collaborator.accessToProject = {
			...generalPermissions,
			modules: {
				projectDesign: accessModuleProjectDesign,
				collaborators: accessModuleCollaborators
			},
			statusTypeAccesses
		};
	}

	return collaborator;
}

/**
 * map `userid` key to `userId` for each item in the list
 */
export function parseApiCollaborators(apiCollaborators: ApiCollaborator[]): Collaborator[] {
	return apiCollaborators.map(parseApiCollaborator);
}

export function parseApiOrganization(apiOrganization: ApiOrganization): Organization {
	const { organizationId, organizationName, organizationCollaborators } = apiOrganization;

	const collaboratorIds = organizationCollaborators.map(c => c.userId.trim());

	const organization: Organization = {
		id: organizationId.toString(),
		name: organizationName,
		collaborators: collaboratorIds
	};

	return organization;
}

export function parseApiOrganizations(apiOrganizations: ApiOrganization[]): Organization[] {
	return apiOrganizations.map(parseApiOrganization);
}

export function parseCollaboratorPermissions(
	collaboratorPermissions: CollaboratorPermissions
): ApiCollaboratorPermissions {
	const { modules, ...rest } = collaboratorPermissions;

	return {
		...rest,
		accessModuleProjectDesign: modules.projectDesign,
		accessModuleCollaborators: modules.collaborators
	};
}

export function parseApiCollaboratorPermissions(
	apiCollaboratorPermissions: ApiCollaboratorPermissions
): CollaboratorPermissions {
	const { accessModuleProjectDesign, accessModuleCollaborators, ...generalPermissions } =
		apiCollaboratorPermissions;

	return {
		...generalPermissions,
		modules: {
			projectDesign: accessModuleProjectDesign,
			collaborators: accessModuleCollaborators
		}
	};
}

import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { useTranslation } from 'hooks/store';

interface Props {
	addCardToGrid: () => void;
}
export function EmptyDashboard({ addCardToGrid }: Props) {
	const { translate } = useTranslation();

	return (
		<Flex justify={j => j.center} fullWidth>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Spacer size={s => s.xxl} />
				<Svgs.EmptyStatesNoDataset style={{ minHeight: 240 }} />
				<Spacer size={s => s.m} />
				<Typography.H3>Your dashboard is empty</Typography.H3>
				<Typography.Caption>{`Let's create your first card!`}</Typography.Caption>
				<Spacer size={s => s.s} />
				<Button
					title={`+ ${translate(dict => dict.buttons.add)}`}
					onClick={addCardToGrid}
				/>
			</div>
		</Flex>
	);
}

import {
	removeVariableFromSet,
	ActionTypes,
	RemoveVariableFromSetAction
} from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionPayload } from 'store/types';

export function useRemoveVariableFromSet(activityPayload?: {
	variableName: string;
}): Operation<(payload: ActionPayload<RemoveVariableFromSetAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.REMOVE_VARIABLE_FROM_SET, activityPayload);

	function handler(payload: ActionPayload<RemoveVariableFromSetAction>) {
		dispatch(removeVariableFromSet(payload));
	}

	return [{ loading, error }, handler];
}

import produce from 'immer';
import { merge } from 'lodash';

import { LanguageType, StorageKeys } from 'types/index';

import initialState from './initialState';
import {
	Actions,
	ActionTypes,
	State,
	UserLicenceModel,
	SubscriptionStatus,
	UserStatus,
	SubscriptionUsersById,
	SubscriptionName
} from './types';
import { ActionTypes as EnterpriseActionTypes } from '../enterprise/types';
import { Actions as EnterpriseActions } from '../enterprise';

import { Actions as AddonsActions } from '../addons';
import { ActionTypes as PaymentsActionTypes, Actions as PaymentsActions } from '../payments';

export default (
	state: State = initialState,
	action: Actions | AddonsActions | PaymentsActions | EnterpriseActions
): State => {
	switch (action.type) {
		case ActionTypes.GET_ACCOUNT: {
			const { account } = action.payload;

			return produce(state, draft => {
				draft.accountDetails = account;
				// TODO: remove all particular logic for userPreferences when all DB data is scripted NO->NB.
				const chosenLanguageCode = account.userPreferences.chosenLanguageCode;
				if (chosenLanguageCode && draft.accountDetails.userPreferences) {
					draft.accountDetails.userPreferences.chosenLanguageCode =
						chosenLanguageCode === LanguageType.Norwegian_Deprecated
							? LanguageType.Norwegian
							: chosenLanguageCode;
				}
			});
		}
		case ActionTypes.UPDATE_ACCOUNT: {
			const { accountDetails } = action.payload;

			return produce(state, draft => {
				draft.accountDetails = merge(draft.accountDetails, accountDetails);
			});
		}

		case ActionTypes.GET_SUBSCRIPTION: {
			return produce(state, draft => {
				const {
					subscriptionDetails,
					subscriptionUsersById,
					isNonSubscriber,
					hasMetadataDefinition
				} = action.payload;
				draft.subscriptionDetails = subscriptionDetails;
				draft.subscriptionUsers.byId = subscriptionUsersById;
				draft.isNonSubscriber = isNonSubscriber;
				draft.hasMetadataDefinition = hasMetadataDefinition;

				const storageSubscriptionStatus = localStorage.getItem(
					StorageKeys.SubscriptionUpdating
				);
				if (storageSubscriptionStatus === 'true' && isNonSubscriber)
					draft.subscriptionStatus = SubscriptionStatus.ChangePlanInProgress;
				else {
					Object.values(subscriptionUsersById).forEach(user => {
						if (user.isOwner) draft.subscriptionStatus = user.subscriptionStatus;
					});
				}
			});
		}

		case ActionTypes.ADD_SUBSCRIPTION_USER: {
			const { user } = action.payload;

			return produce(state, draft => {
				const { licenceModel, ...userToAdd } = user;
				draft.subscriptionUsers.byId[user.userid] = {
					...userToAdd,
					licenceModel
				};
				// allow backwards compatibility for both availableLicenceQuantity and availableLicenceQuantities
				if (
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantity
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						draft.subscriptionDetails.availableLicenceQuantity - 1;
				}

				// bundle version
				if (
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantities &&
					draft.subscriptionDetails.availableLicenceQuantities[licenceModel]
				) {
					draft.subscriptionDetails.availableLicenceQuantities[licenceModel] =
						draft.subscriptionDetails.availableLicenceQuantities[licenceModel] - 1;
				}
			});
		}

		case EnterpriseActionTypes.UPDATE_ENTERPRISE_USER: {
			const { user } = action.payload;

			return produce(state, draft => {
				draft.subscriptionUsers.byId[user.userid] = user;
			});
		}

		case ActionTypes.CANCEL_SUBSCRIPTION_USER_INVITATION: {
			const { userId, licenceModel } = action.payload;

			return produce(state, draft => {
				delete draft.subscriptionUsers.byId[userId];
				if (
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantity
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						draft.subscriptionDetails.availableLicenceQuantity + 1;
				}

				// bundle version
				if (
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantities &&
					draft.subscriptionDetails.availableLicenceQuantities[licenceModel]
				) {
					draft.subscriptionDetails.availableLicenceQuantities[licenceModel] =
						draft.subscriptionDetails.availableLicenceQuantities[licenceModel] + 1;
				}
			});
		}

		case ActionTypes.REMOVE_SUBSCRIPTION_USER: {
			const { userId, licenceModel } = action.payload;

			return produce(state, draft => {
				delete draft.subscriptionUsers.byId[userId];
				if (
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantity
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						draft.subscriptionDetails.availableLicenceQuantity + 1;
				}

				// bundle version
				if (
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantities &&
					draft.subscriptionDetails.availableLicenceQuantities[licenceModel]
				) {
					draft.subscriptionDetails.availableLicenceQuantities[licenceModel] =
						draft.subscriptionDetails.availableLicenceQuantities[licenceModel] + 1;
				}
			});
		}

		case EnterpriseActionTypes.DEACTIVATE_USER_ACCOUNT: {
			const { userId } = action.payload;
			return produce(state, draft => {
				const computedUsers: SubscriptionUsersById = {};

				Object.values(draft.subscriptionUsers.byId).filter(user =>
					user.userid === userId
						? (computedUsers[userId] = {
								...user,
								status: UserStatus.Inactive,
								licenceModel: UserLicenceModel.NoLicence,
								subscriptionStatus: SubscriptionStatus.Inactive
						  })
						: (computedUsers[user.userid] = user)
				);

				draft.subscriptionUsers.byId = computedUsers;
				if (
					state.subscriptionDetails?.availableLicenceQuantity &&
					draft.subscriptionDetails
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						state.subscriptionDetails?.availableLicenceQuantity + 1;
				}
			});
		}

		case EnterpriseActionTypes.REACTIVATE_USER_ACCOUNT: {
			const { userId } = action.payload;
			return produce(state, draft => {
				const computedUsers: SubscriptionUsersById = {};
				Object.values(draft.subscriptionUsers.byId).filter(user =>
					user.userid === userId
						? (computedUsers[userId] = {
								...user,
								status: UserStatus.Active,
								licenceModel: UserLicenceModel.Full,
								subscriptionStatus: SubscriptionStatus.Active
						  })
						: (computedUsers[user.userid] = user)
				);
				draft.subscriptionUsers.byId = computedUsers;

				draft.subscriptionUsers.byId = computedUsers;
				if (
					state.subscriptionDetails?.availableLicenceQuantity &&
					draft.subscriptionDetails
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						state.subscriptionDetails?.availableLicenceQuantity - 1;
				}
			});
		}

		case ActionTypes.CHANGE_USER_LICENCE_MODEL: {
			const { userId, licenceModel } = action.payload;

			return produce(state, draft => {
				draft.subscriptionUsers.byId[userId].licenceModel = licenceModel;
				// backwards compatibility
				if (
					(licenceModel === UserLicenceModel.Full ||
						licenceModel === UserLicenceModel.Collaborator) &&
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantity
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						draft.subscriptionDetails.availableLicenceQuantity - 1;
				}
				if (
					licenceModel === UserLicenceModel.OneOwnedOneShared &&
					draft.subscriptionDetails &&
					draft.subscriptionDetails.availableLicenceQuantity
				) {
					draft.subscriptionDetails.availableLicenceQuantity =
						draft.subscriptionDetails.availableLicenceQuantity + 1;
				}

				// bundle version
				if (userId && draft.subscriptionUsers.byId[userId]) {
					const previousLicenceModel = draft.subscriptionUsers.byId[userId].licenceModel;
					if (
						draft.subscriptionDetails &&
						draft.subscriptionDetails.availableLicenceQuantities
					) {
						draft.subscriptionDetails.availableLicenceQuantities[previousLicenceModel] =
							draft.subscriptionDetails.availableLicenceQuantities[
								previousLicenceModel
							] + 1;
						if (licenceModel !== UserLicenceModel.OneOwnedOneShared) {
							draft.subscriptionDetails.availableLicenceQuantities[licenceModel] =
								draft.subscriptionDetails.availableLicenceQuantities[licenceModel] -
								1;
						}
					}
				}

				// if user is changing his own licence, update account details and
				// the other tabs with the new licence model
				if (draft.accountDetails && userId === draft.accountDetails.userid) {
					draft.accountDetails.licenceModel = licenceModel;
					// temporary till we get a notification for this event
					localStorage.setItem(
						StorageKeys.LicenceModelSelfAssigned,
						licenceModel.toString()
					);
				}
			});
		}

		case ActionTypes.GET_ORGANIZATIONS: {
			const { organizations } = action.payload;

			return produce(state, draft => {
				draft.organizations = organizations;
			});
		}

		case ActionTypes.GET_LICENCE_MODELS: {
			const { licenceModels } = action.payload;

			return produce(state, draft => {
				draft.licenceModels = {
					fetched: true,
					...licenceModels
				};
			});
		}

		case ActionTypes.SET_SUBSCRIPTION_USERS_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.subscriptionUsers.searchTerm = term;
			});
		}

		case ActionTypes.GET_SUBSCRIPTION_API_KEY: {
			const { apiKey } = action.payload;

			return produce(state, draft => {
				draft.subscriptionApiKey = apiKey;
			});
		}

		case PaymentsActionTypes.CREATE_CUSTOMER_SUBSCRIPTION:
		case PaymentsActionTypes.UPDATE_CUSTOMER_SUBSCRIPTION: {
			return produce(state, draft => {
				draft.subscriptionStatus = SubscriptionStatus.ChangePlanInProgress;
				localStorage.setItem(StorageKeys.SubscriptionUpdating, 'true');
			});
		}

		case PaymentsActionTypes.DOWNGRADE_SUBSCRIPTION_TO_FREE: {
			return produce(state, draft => {
				if (draft.subscriptionDetails) {
					draft.subscriptionDetails.subscriptionName = SubscriptionName.LedidiFreeTrial;

					draft.subscriptionStatus = SubscriptionStatus.ChangePlanInProgress;
					localStorage.setItem(StorageKeys.SubscriptionUpdating, 'true');
				}
			});
		}

		default: {
			return state;
		}
	}
};

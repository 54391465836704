import styled, { createGlobalStyle, css } from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import { getTypographyCSS, FontWeights } from 'components/UI/Typography';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';
import { SeriesFormState } from 'types/index';

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	overflow: auto;
`;

export const Container = styled.div`
	height: 100vh;
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;

	background-color: ${Colors.white};
`;

export const AggregationContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media ${MediaQueries.maxWidth.md} {
		padding: 0 2rem;
	}
`;
export const AggregationWrapper = styled.div`
	width: 60.4rem;

	@media ${MediaQueries.maxWidth.md} {
		width: 100%;
	}
`;

interface ContainerGridViewType {
	isFormVisible: boolean;
}

export const ContainerGridView = styled.div<ContainerGridViewType>`
	display: flex;

	${({ isFormVisible }) => {
		if (isFormVisible) {
			return css`
				margin-bottom: 33rem;
			`;
		}
		return css`
			margin-bottom: 10rem;
		`;
	}}

	@media ${MediaQueries.maxWidth.md} {
		min-width: 100%;
	}
`;

interface NarrowContainerType {
	formType?: string;
}

export const NarrowContainer = styled.div<NarrowContainerType>`
	width: 60.4rem;
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.minWidth.md} {
		margin: 0 4.8rem;
	}

	@media ${MediaQueries.maxWidth.md} {
		${({ formType }) => {
			if (formType !== SeriesFormState.Current) {
				return css`
					display: none;
				`;
			}

			return css`
				margin: 0 2rem;
				width: 100%;
			`;
		}}
	}
`;

export const FlexContainer = styled(Container)<{ direction?: string }>`
	display: flex;
	flex-direction: ${props => props.direction};
`;

interface Props {
	gridView: boolean | undefined;
}

export const FlexWrapper = styled.div<Props>`
	width: 100%;
	height: 100%;
	${({ gridView }) =>
		gridView &&
		css`
			display: flex;
			height: 90vh;
		`}
`;

interface FormWrapperProps {
	isOpenMobileDrawerHistoryView?: boolean;
}

export const FormWrapper = styled.div<FormWrapperProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	${({ isOpenMobileDrawerHistoryView }) => {
		if (!isOpenMobileDrawerHistoryView) {
			return css`
				overflow-x: hidden;
			`;
		}
	}}
`;

export const DisableBodyScroll = createGlobalStyle`
	body {
		overflow: hidden;
	}
`;

interface EntryCountType {
	formType?: string;
	isTheLastSeries?: boolean;
	isTheFirstSeries?: boolean;
}

// TODO: MAKE THIS A GENERAL COMPONENT
export const EntryCount = styled.div<EntryCountType>`
	${getTypographyCSS(t => t.Caption)}

	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2.4rem;
	min-height: 2.4rem;
	border-radius: 1.2rem;
	user-select: none;

	margin-right: 0.8rem;
	padding: 0 0.7rem;

	color: ${Colors.seriesGreen};
	font-weight: ${FontWeights.medium};
	background-color: rgba(38, 208, 124, 0.1);

	@media ${MediaQueries.minWidth.md} {
		${({ formType, isTheLastSeries, isTheFirstSeries }) => {
			if (formType !== SeriesFormState.Current && !isTheLastSeries && !isTheFirstSeries) {
				return css`
					opacity: 0.2;
				`;
			}
		}}
	}
`;

interface NavigationArrowsProps {
	rightArrow?: boolean;
}
export const NavigationArrow = styled.div<NavigationArrowsProps>`
	position: fixed;
	bottom: 50%;
	height: max-content;

	${({ rightArrow }) => {
		if (rightArrow) {
			return css`
				right: 5.6rem;
			`;
		}

		return css`
			left: 5.6rem;
		`;
	}}

	@media print {
		display: none;
	}
`;

export const Content = styled.div<Disabled>`
	${({ disabled, isSeriesEntryForm }) => disableableComponent({ disabled, isSeriesEntryForm })}
`;

export const NavigationWrapper = styled.div`
	cursor: pointer;
`;

export const LoaderContainer = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	background-color: ${Colors.white};
`;

import { AxiosResponse } from 'axios';

import { NetworkRequestBase } from 'api/types';
import { GenericMap } from 'types/index';
import {
	CreateDashboardCardPayload,
	RemoveDashboardCardPayload
} from 'store/data/projectDashboard';

export interface CardSize {
	width: number;
	height: number;
}

export interface CardPosition {
	row: number;
	column: number;
}
export interface LastCardAndPlaceholderPositions {
	card: CardPosition | null;
	placeholder: CardPosition | null;
}

export interface ApiDashboardCard {
	cardTemplateId: number;
	label: string;
	size: CardSize;
	layout?: CardPosition;
	filterParams: {
		key1: string;
		key2: string;
	};
	cardId: number;
	userId?: string;
}

export type ApiDashboardCardsMap = GenericMap<ApiDashboardCard>;

export enum DashboardType {
	project = 'PROJECT'
}
export enum DashboardPermission {
	write = 'write'
}

export interface ApiDashboard {
	dashboardId: number;
	projectId: number;
	owner: string;
	userId: string;
	type: DashboardType;
	label: string;
	description: string;
	permissions: DashboardPermission;
	cards: Array<ApiDashboardCard>;
}

export type NewApiDashboard = Omit<ApiDashboard, 'dashboardId'>;

// Generic API Dashboard method interfaces
export interface ListDashboardRequest extends NetworkRequestBase {
	dashboard: {
		projectId: number;
	};
}

export type ListDashboardResponse = AxiosResponse<{
	dashboard: Array<ApiDashboard>;
	httpStatusCode: string;
}>;

export interface GetDashboardByIdRequest extends NetworkRequestBase {
	dashboard: {
		projectId: number;
		dashboardId: number;
	};
}

export type GetDashboardByIdResponse = AxiosResponse<{
	dashboard: ApiDashboard[];
	httpStatusCode: string;
}>;

export interface CreateDashboardRequest extends NetworkRequestBase {
	dashboard: NewApiDashboard;
}

export interface UpdateDashboardRequest extends NetworkRequestBase {
	dashboard: ApiDashboard;
}

export type CreateDashboardResponse = AxiosResponse<{
	dashboard: ApiDashboard;
	statusCode: string;
}>;

export type UpdateDashboardResponse = AxiosResponse<{
	dashboard: ApiDashboard;
	httpStatusCode: string;
}>;

export interface DeleteDashboardRequest extends NetworkRequestBase {
	projectId: number;
	dashboardId: number;
}

export type DeleteDashboardResponse = AxiosResponse<{
	dashboard: [];
	ledidiStatusCode: string;
}>;

export interface CreateDashboardCardRequest extends NetworkRequestBase {
	payload: CreateDashboardCardPayload;
}

export type CreateDashboardCardResponse = AxiosResponse<{
	dashboard: ApiDashboard;
	ledidiStatusCode: string;
}>;
export interface UpdateDashboardCardRequest extends NetworkRequestBase {
	payload: CreateDashboardCardPayload;
}

export type UpdateDashboardCardResponse = AxiosResponse<{
	dashboard: ApiDashboard;
	ledidiStatusCode: string;
}>;

export interface RemoveDashboardCardRequest extends NetworkRequestBase {
	payload: RemoveDashboardCardPayload;
}

export type RemoveDashboardCardResponse = AxiosResponse<{
	dashboard: ApiDashboard;
	ledidiStatusCode: string;
}>;

import { useState } from 'react';

import { Variable } from 'api/data/variables';
import { Svgs, Colors } from 'environment';
import { variableSetCardPulse, variableSetCountPulse } from 'events/variables';
import { VariableSetData, GroupData } from 'store/data/variables';
import { useTranslation } from 'hooks/store';

import { CenteredCheckbox, ColorIndicator } from '../styles';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { useCustomEventListener } from 'helpers/events';
import { variablesDataArrayIterator, isVariable, isGroupData } from 'helpers/variables';

interface Props {
	variableSetData: VariableSetData;
	rowNumber: number;
	expandedRowsData: {
		expanded: boolean;
		onExpand: () => void;
	};
	checkedRowsData: {
		checked: boolean;
		partial: boolean;
		onCheck: () => void;
	};
	readOnly: boolean;
	id: string;
	onClick: () => void;
	renderVariableRow: (variable: Variable, index: number) => React.ReactNode;
	renderGroupRow: (groupData: GroupData, index: number) => React.ReactNode;
}

export function VariableSetRow({
	variableSetData,
	rowNumber,
	expandedRowsData: { expanded, onExpand },
	checkedRowsData: { checked, partial, onCheck },
	readOnly,
	id,
	onClick,
	renderVariableRow,
	renderGroupRow
}: Props) {
	const { translate } = useTranslation();

	function getNumberOf() {
		const numberOf = {
			variables: 0,
			groups: 0
		};

		variablesDataArrayIterator(
			variableSetData.setData,
			// VARIABLE
			() => numberOf.variables++,
			// GROUP
			group => {
				numberOf.groups++;

				numberOf.variables += group.groupVariables.length;
			},
			// VARIABLE SET - OMIT
			() => null
		);

		return numberOf;
	}

	const numberOfVariables = getNumberOf().variables;
	const hasOneVariable = numberOfVariables === 1;
	const numberOfVariablesMessage = `${numberOfVariables} ${
		hasOneVariable
			? translate(dict => dict.terms.variableLowerCase)
			: translate(dict => dict.terms.variables)
	}`;

	const [pulse, setPulse] = useState(false);
	const [pulseCount, setPulseCount] = useState(false);

	useCustomEventListener(variableSetCardPulse, {
		onListen: payload => {
			if (payload.setName === variableSetData.setName) setPulse(true);
		}
	});
	useCustomEventListener(variableSetCountPulse, {
		onListen: payload => {
			if (payload.setName === variableSetData.setName) setPulseCount(true);
		}
	});

	return (
		<>
			<Table.Row
				{...(pulse && {
					className: 'pulse-vibrant-green'
				})}
				id={id}
				onAnimationEnd={() => setPulse(false)}
				//
				data-scroll-id={variableSetData.setName}
				onClick={readOnly ? undefined : onClick}
				active={checked}
				clickable={!readOnly}
			>
				{!readOnly && (
					<Table.Cell
						style={{ position: 'relative' }}
						onClick={e => {
							e.stopPropagation();
							onCheck();
						}}
					>
						<ColorIndicator type="variableSet" />
						<CenteredCheckbox
							checked={checked}
							partial={!checked && partial}
							onClick={onCheck}
						/>
					</Table.Cell>
				)}
				<Table.Cell style={{ position: 'relative' }} textCenter>
					{readOnly && <ColorIndicator type="variableSet" />}

					<Typography.Caption>{rowNumber}</Typography.Caption>
				</Table.Cell>
				<Table.Cell minWidth={20} maxWidth={30} title={variableSetData.setLabel}>
					<Flex>
						<Icon
							svg={expanded ? Svgs.ArrowDown : Svgs.ArrowRight}
							size={s => s.m}
							onClick={onExpand}
						/>
						<Icon
							svg={Svgs.Set}
							size={s => s.m}
							colors={{ color: Colors.seriesGreen }}
							marginOffset={{ x: 0.4 }}
							propagate
						/>
						<Typography.Paragraph fontweight={w => w.medium} ellipsis>
							{variableSetData.setLabel}
						</Typography.Paragraph>
					</Flex>
				</Table.Cell>
				<Table.Cell
					{...(pulseCount && {
						className: 'pulse-vibrant-green',
						style: {
							color: Colors.chart.darkGreen.dark
						}
					})}
					onAnimationEnd={() => setPulseCount(false)}
					//
					title={numberOfVariablesMessage}
				>
					<Typography.Paragraph fontweight={w => w.medium}>
						{numberOfVariablesMessage}
					</Typography.Paragraph>
				</Table.Cell>
				<Table.Cell>-</Table.Cell>
				{/* TODO: FEATURE: REPLACE WITH `variableSet.description` WHEN IMPLEMENTED */}
				<Table.Cell>-</Table.Cell>
			</Table.Row>

			{expanded &&
				variableSetData.setData.map((item, index) => {
					// VARIABLE
					if (isVariable(item)) {
						const variable = item;

						return renderVariableRow(variable, index);
					}

					// GROUP
					if (isGroupData(item)) {
						const groupData = item;

						return renderGroupRow(groupData, index);
					}

					return null;
				})}
		</>
	);
}

// exports

export enum ROUTE_QUERY {
	Upgrade = 'upgrade'
}

export enum ROUTES {
	Root = '/',
	Login = '/login',
	Logout = '/logout',
	Help = '/help/',
	HelpTopic = '/help/:topicUrl',
	NotFoundPage = '*',
	TemplateRoles = '/roles/',

	// PROJECTS
	Projects = '/projects',
	CreateProject = '/projects/create',
	ImportDataset = '/projects/:id/import',
	Analysis = '/projects/:id/analysis',
	Dataset = '/projects/:id/dataset',
	EntryStatuses = '/projects/:id/statuses',
	CreateEntry = '/projects/:id/dataset/create',
	UpdateEntry = '/projects/:id/dataset/update/:entryId',
	Variables = '/projects/:id/variables',
	CreateVariable = '/projects/:id/variables/create',
	Dependencies = '/projects/:id/dependencies',
	Templates = '/projects/:id/templates',
	Forms = '/projects/:id/forms',
	Form = '/projects/:id/forms/:formId',
	Documents = '/projects/:id/documents',
	Collaborators = '/projects/:id/collaborators',
	AddCollaborators = '/projects/:id/collaborators/invite',
	Roles = '/projects/:id/roles',
	ProjectDashboard = '/projects/:id/projectDashboard',

	// PROMS
	Proms = '/surveys',
	CreateProm = '/surveys/create',
	Dashboard = '/surveys/:id/dashboard',
	Patients = '/surveys/:id/recipients',
	PromAnalysis = '/surveys/:id/analysis',
	PromDataset = '/surveys/:id/dataset',
	PromEntryStatuses = '/surveys/:id/statuses',
	CreatePromEntry = '/surveys/:id/dataset/create',
	UpdatePromEntry = '/surveys/:id/dataset/update/:entryId',
	PromVariables = '/surveys/:id/variables',
	CreatePromVariable = '/surveys/:id/variables/create',
	PromDependencies = '/surveys/:id/dependencies',
	PromTemplates = '/surveys/:id/templates',
	PromForms = '/surveys/:id/forms',
	PromForm = '/surveys/:id/forms/:formId',
	PromDocuments = '/surveys/:id/documents',
	PromCollaborators = '/surveys/:id/collaborators',
	AddPromCollaborators = '/surveys/:id/collaborators/invite',
	ImportPromDataset = '/surveys/:id/import',
	PromRoles = '/surveys/:id/roles',

	// PATIENT - MANUAL DISTRIBUTION
	PatientManualSurveyWithParams = '/survey/:projectId/:distributionEntryId/:hashString/:hashKey',
	PatientManualSurveyWithParamsForm = '/survey/:projectId/:distributionEntryId/:hashString/:hashKey/:formId',
	PatientManualSurvey = '/survey',

	// PATIENT - AUTOMATIC DISTRIBUTION
	PatientLogin = '/recipient/login',
	PatientLoginWithParams = '/recipient/login/:projectId/:entryId/:username/:passPrefix',
	PatientLoginWithParamsForm = '/recipient/login/:projectId/:entryId/:username/:passPrefix/:formId',
	PatientEditEntry = '/recipient/edit-entry',

	// ACCOUNT MANAGEMENT
	AccountUMSubscription = '/account/subscription',
	AccountUMPayment = '/account/payment',
	AccountUMBillingHistory = '/account/billing',
	AccountUMAddons = '/account/addons',

	// ADMIN
	AdminUsers = '/users',
	AdminEnterprise = '/enterprise',
	AdminUserCreate = '/users/create',
	AdminUserDetails = '/users/details/:userId',
	AdminRoles = '/roles',

	// ENTERPRISE ADMIN
	EnterpriseAdminSubscription = '/subscription',
	EnterpriseAdminDashboard = '/dashboard',
	EnterpriseAdminUsers = '/users',
	EnterpriseAdminProjects = '/projects',
	EnterpriseAdminRoles = '/roles',
	EnterpriseAdminSettings = '/settings',

	// DASHBOARD
	AdminDashboard = '/dashboard'
}

import { isEqual, cloneDeep } from 'lodash';

import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	CreateFilterAction,
	DeleteFilterAction,
	InvalidateFilterAction,
	UpdateFilterAction,
	ClearFiltersAction,
	DeleteFiltersAction,
	RebuildFiltersAction,
	FiltersById,
	ToggleOpenFiltersAction,
	CreateDependenciesFilterAction,
	DeleteDependenciesFilterAction,
	DeleteDependenciesFiltersAction,
	UpdateDependenciesFilterAction,
	InvalidateDependenciesFilterAction,
	CreateSeriesFilterAction,
	UpdateSeriesName,
	UpdateSeriesFilterAction,
	DeleteSeriesFilterAction,
	DeleteSeriesFiltersAction,
	InvalidateSeriesFilterAction
} from './types';
import {
	getAggregatorVariableNameByAggregationRuleName,
	isFilterAggregationRule
} from 'helpers/variables';
import { setRefetchEntries } from '../entries';
import { VariableType } from 'types/data/variables/constants';

export const createFilter = (payload: ActionPayload<CreateFilterAction>): CreateFilterAction => ({
	type: ActionTypes.CREATE_FILTER,
	payload
});

export const updateFilter = (payload: ActionPayload<UpdateFilterAction>): UpdateFilterAction => ({
	type: ActionTypes.UPDATE_FILTER,
	payload
});

const deleteFilterAction = (payload: ActionPayload<DeleteFilterAction>): DeleteFilterAction => ({
	type: ActionTypes.DELETE_DATASET_FILTER,
	payload
});

export const deleteDatasetFilter =
	(filterId: string): Thunk =>
	(dispatch, getState) => {
		const {
			projects: { projectId },
			filters: {
				dataset: { byProjectId }
			},
			snapshots: { byProjectId: snapshotsByProjectId }
		} = getState().data;

		if (projectId && byProjectId[projectId]) {
			let snapshotId = null;
			if (snapshotsByProjectId[projectId])
				snapshotId = snapshotsByProjectId[projectId].active;
			dispatch(deleteFilterAction({ filterId, snapshotId }));
		}
	};

const deleteFiltersAction = (payload: ActionPayload<DeleteFiltersAction>): DeleteFiltersAction => ({
	type: ActionTypes.DELETE_FILTERS,
	payload
});

export const deleteFilters =
	(filterIds: string[]): Thunk =>
	(dispatch, getState) => {
		const {
			projects: { projectId },
			filters: {
				dataset: { byProjectId }
			},
			snapshots: { byProjectId: snapshotsByProjectId }
		} = getState().data;

		if (projectId && byProjectId[projectId]) {
			let snapshotId = null;
			if (snapshotsByProjectId[projectId])
				snapshotId = snapshotsByProjectId[projectId].active;

			dispatch(deleteFiltersAction({ filterIds, snapshotId }));

			// if deleting any valid filters we need to flag entries to refetch
			if (!filterIds.every(filterId => byProjectId[projectId].invalid.includes(filterId))) {
				dispatch(setRefetchEntries());
			}
		}
	};

export const invalidateFilter = (
	payload: ActionPayload<InvalidateFilterAction>
): InvalidateFilterAction => ({
	type: ActionTypes.INVALIDATE_FILTER,
	payload
});

export const clearFilters = (): ClearFiltersAction => ({
	type: ActionTypes.CLEAR_FILTERS
});

export const rebuildFiltersAction = (
	payload: ActionPayload<RebuildFiltersAction>
): RebuildFiltersAction => ({
	type: ActionTypes.REBUILD_FILTERS,
	payload
});

export const toggleOpenFilters = (): ToggleOpenFiltersAction => ({
	type: ActionTypes.TOGGLE_OPEN_FILTERS
});

/**
 * - removes invalid filters with inexistent references
 * - repairs filters with inexistent category values (omit inexistent) - removes if all values are inexistent
 */
export const rebuildFilters = (): Thunk => (dispatch, getState) => {
	const {
		projects: { projectId },
		variables: { byProjectId: variablesByProjectId },
		statuses: { byProjectId: statusesByProjectId },
		filters: {
			dataset: { byProjectId: filtersByProjectId, byId: filtersById }
		}
	} = getState().data;

	if (projectId && filtersByProjectId[projectId]) {
		const { active: activeFilterIds } = filtersByProjectId[projectId];
		const { variables, variableSets } = variablesByProjectId[projectId].initial;
		const { byName: statusesByName } = statusesByProjectId[projectId];

		const newActiveFilterIds: string[] = [];
		const newFiltersById: FiltersById = {};

		activeFilterIds.forEach(filterId => {
			const filter = cloneDeep(filtersById[filterId]);

			const isAggregationRuleFilter = isFilterAggregationRule(
				filter.columnName,
				variableSets
			);

			const variableExists =
				filter.columnName in variables.byName || filter.columnName in variableSets.byName;

			if (variableExists || isAggregationRuleFilter) {
				let variable = variables.byName[filter.columnName];

				const aggregatorVariableNameByAggregationRuleName =
					getAggregatorVariableNameByAggregationRuleName(variableSets.byName);

				if (filter.columnName in aggregatorVariableNameByAggregationRuleName) {
					const aggregatorVariableName =
						aggregatorVariableNameByAggregationRuleName[filter.columnName];

					if (aggregatorVariableName === undefined) return;

					variable = variables.byName[aggregatorVariableName];
				}

				// todo 8683 (double check!)
				const variableTypeChanged =
					variable.type !== filter.filterType &&
					variable.type === VariableType.TimeDuration &&
					filter.filterType !== VariableType.Integer;

				// VARIABLE TYPE CHANGED - REMOVE FILTER
				if (variableTypeChanged && variable.type !== VariableType.Unique) return;

				newActiveFilterIds.push(filter.itemId);
				newFiltersById[filter.itemId] = filter;
			}

			const isStatusFilter = filter.filterType === VariableType.Status;

			if (isStatusFilter) {
				const filterValues = filter.values;

				// INVALID FILTER - STRUCTURE NOT INITIALIZED
				if (!filterValues) return;

				const newFilterValues: string[] = [];

				filterValues.forEach(statusName => {
					if (statusName && statusName in statusesByName)
						newFilterValues.push(statusName);
				});

				if (newFilterValues.length) {
					// UPDATE FILTER VALUES
					filter.values = newFilterValues;

					newActiveFilterIds.push(filter.itemId);
					newFiltersById[filter.itemId] = filter;
				}
			}
		});

		// GET FILTERS WITHOUT THE ONES IN CURRENT PROJECT
		// == START ==
		const filtersByIdNotInCurrentProject = cloneDeep(filtersById);

		activeFilterIds.forEach(filterId => {
			delete filtersByIdNotInCurrentProject[filterId];
		});
		// == END ==

		// BUILD FRESH `filtersById` WITH REBUILT FILTERS FROM THE CURRENT PROJECT
		const newComputedFiltersById = {
			...filtersByIdNotInCurrentProject,
			...newFiltersById
		};

		const oldFilters = {
			active: activeFilterIds,
			byId: filtersById
		};

		const newFilters = {
			active: newActiveFilterIds,
			byId: newComputedFiltersById
		};

		const shouldRebuildFilters = !isEqual(oldFilters, newFilters);

		if (shouldRebuildFilters) {
			dispatch(
				rebuildFiltersAction({
					active: newActiveFilterIds,
					byId: newComputedFiltersById
				})
			);
		}
	}
};

// DEPENDENCIES FILTERS
export const createDependenciesFilter = (
	payload: ActionPayload<CreateDependenciesFilterAction>
): CreateDependenciesFilterAction => ({
	type: ActionTypes.CREATE_DEPENDENCIES_FILTER,
	payload
});

export const updateDependenciesFilter = (
	payload: ActionPayload<UpdateDependenciesFilterAction>
): UpdateDependenciesFilterAction => ({
	type: ActionTypes.UPDATE_DEPENDENCIES_FILTER,
	payload
});

export const deleteDependenciesFilterAction = (
	payload: ActionPayload<DeleteDependenciesFilterAction>
): DeleteDependenciesFilterAction => ({
	type: ActionTypes.DELETE_DEPENDENCIES_FILTER,
	payload
});

export const deleteDependenciesFilter =
	(filterId: string): Thunk =>
	(dispatch, getState) => {
		const {
			projects: { projectId },
			filters: {
				dependencies: { byProjectId }
			},
			snapshots: { byProjectId: snapshotsByProjectId }
		} = getState().data;
		if (projectId && byProjectId[projectId]) {
			let snapshotId = null;
			if (snapshotsByProjectId[projectId])
				snapshotId = snapshotsByProjectId[projectId].active;
			dispatch(deleteDependenciesFilterAction({ filterId, snapshotId }));
		}
	};

export const deleteDependenciesFiltersAction = (
	payload: ActionPayload<DeleteDependenciesFiltersAction>
): DeleteDependenciesFiltersAction => ({
	type: ActionTypes.DELETE_DEPENDENCIES_FILTERS,
	payload
});
export const deleteDependenciesFilters =
	(filterIds: string[]): Thunk =>
	(dispatch, getState) => {
		const {
			projects: { projectId },
			filters: {
				dependencies: { byProjectId }
			},
			snapshots: { byProjectId: snapshotsByProjectId }
		} = getState().data;
		if (projectId && byProjectId[projectId]) {
			let snapshotId = null;
			if (snapshotsByProjectId[projectId])
				snapshotId = snapshotsByProjectId[projectId].active;
			dispatch(deleteDependenciesFiltersAction({ filterIds, snapshotId }));
		}
	};

export const invalidateDependenciesFilter = (
	payload: ActionPayload<InvalidateDependenciesFilterAction>
): InvalidateDependenciesFilterAction => ({
	type: ActionTypes.INVALIDATE_DEPENDENCIES_FILTER,
	payload
});

//SERIES
export const createSeriesFilter = (
	payload: ActionPayload<CreateSeriesFilterAction>
): CreateSeriesFilterAction => ({
	type: ActionTypes.CREATE_SERIES_FILTER,
	payload
});

export const updateFiltersSeriesName = (
	payload: ActionPayload<UpdateSeriesName>
): UpdateSeriesName => ({
	type: ActionTypes.UPDATE_SERIES_NAME,
	payload
});

export const updateSeriesFilter = (
	payload: ActionPayload<UpdateSeriesFilterAction>
): UpdateSeriesFilterAction => ({
	type: ActionTypes.UPDATE_SERIES_FILTER,
	payload
});

const deleteSeriesFilterAction = (
	payload: ActionPayload<DeleteSeriesFilterAction>
): DeleteSeriesFilterAction => ({
	type: ActionTypes.DELETE_SERIES_FILTER,
	payload
});

export const deleteSeriesFilter =
	(filterId: string): Thunk =>
	(dispatch, getState) => {
		const {
			projects: { projectId },
			filters: {
				series: { byProjectId }
			},
			snapshots: { byProjectId: snapshotsByProjectId }
		} = getState().data;

		if (projectId && byProjectId[projectId]) {
			let snapshotId = null;
			if (snapshotsByProjectId[projectId])
				snapshotId = snapshotsByProjectId[projectId].active;

			dispatch(deleteSeriesFilterAction({ filterId, snapshotId }));
		}
	};

const deleteSeriesFiltersAction = (
	payload: ActionPayload<DeleteSeriesFiltersAction>
): DeleteSeriesFiltersAction => ({
	type: ActionTypes.DELETE_SERIES_FILTERS,
	payload
});

export const deleteSeriesFilters =
	(filterIds: string[]): Thunk =>
	(dispatch, getState) => {
		const {
			projects: { projectId },
			filters: {
				series: { byProjectId, bySeriesName }
			},
			snapshots: { byProjectId: snapshotsByProjectId }
		} = getState().data;

		if (projectId && bySeriesName && byProjectId[projectId][bySeriesName]) {
			let snapshotId = null;
			if (snapshotsByProjectId[projectId])
				snapshotId = snapshotsByProjectId[projectId].active;

			dispatch(deleteSeriesFiltersAction({ filterIds, snapshotId }));
		}
	};

export const invalidateSeriesFilter = (
	payload: ActionPayload<InvalidateSeriesFilterAction>
): InvalidateSeriesFilterAction => ({
	type: ActionTypes.INVALIDATE_SERIES_FILTER,
	payload
});

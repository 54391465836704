import styled from 'styled-components';

import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const AvatarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
	position: relative;
`;

export const UploadIconContainer = styled.label`
	cursor: pointer;
	margin-top: 0.8rem;
`;

export const HiddenInput = styled.input`
	display: none;
`;

export const EditAccountDetails = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 2.4rem;
	color: ${Colors.gray.light};
`;

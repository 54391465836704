import { useState, useEffect } from 'react';

import { Svgs } from 'environment';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { useTranslation } from 'hooks/store';

import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
	isLedidiEnterprise: boolean;
	isLicenceDeactivated: boolean;
	onDropdownVisible: (visible: boolean) => void;
	onEditUser: () => void;
	onResetPassword: () => void;
	onChangeRole: () => void;
	onDeactivateEnterpriseUser: () => void;
	onReactivateEnterpriseUser: () => void;
	onRemoveLicence: () => void;
}

export function SubscriptionUsersMoreDropdown({
	isLedidiEnterprise,
	isLicenceDeactivated,
	onDropdownVisible,
	onEditUser,
	onResetPassword,
	onChangeRole,
	onDeactivateEnterpriseUser,
	onReactivateEnterpriseUser,
	onRemoveLicence
}: Props) {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const { deactivationReactivationUserFlag } = useFlags();

	const { translate } = useTranslation();

	useEffect(() => {
		onDropdownVisible(dropdownVisible);
	}, [dropdownVisible]);

	return (
		<Dropdown
			onClose={() => setDropdownVisible(false)}
			onOpen={() => setDropdownVisible(true)}
			toggleComponent={({ ref, toggle }) => (
				<Icon
					ref={ref}
					variant={v => v.button}
					svg={Svgs.More}
					size={s => s.m}
					onClick={() => {
						toggle();
					}}
					active={dropdownVisible}
				/>
			)}
			width={18}
			offset={{ top: 10, right: 4 }}
		>
			<Dropdown.Item
				title={translate(dict => dict.accountUM.subscriptionUsersDropdown.editUser)}
				onClick={onEditUser}
			/>
			<Dropdown.Item
				title={translate(dict => dict.accountUM.subscriptionUsersDropdown.resetPassword)}
				onClick={onResetPassword}
			/>
			<Dropdown.Item
				title={translate(dict => dict.accountUM.subscriptionUsersDropdown.changeRole)}
				onClick={onChangeRole}
			/>
			{isLedidiEnterprise && deactivationReactivationUserFlag ? (
				isLicenceDeactivated ? (
					<Dropdown.Item
						title={translate(
							dict => dict.accountUM.subscriptionUsersDropdown.reactivateUser
						)}
						onClick={onReactivateEnterpriseUser}
					/>
				) : (
					<Dropdown.Item
						title={translate(
							dict => dict.accountUM.subscriptionUsersDropdown.deactivateUser
						)}
						onClick={onDeactivateEnterpriseUser}
					/>
				)
			) : null}
			{!isLedidiEnterprise && (
				<Dropdown.Item
					title={translate(
						dict => dict.accountUM.subscriptionUsersDropdown.removeLicence
					)}
					onClick={onRemoveLicence}
				/>
			)}
		</Dropdown>
	);
}

import styled from 'styled-components';

import { disableableComponent, Disabled } from 'helpers/cssGenerators';

export const Content = styled.div<Disabled>`
	display: flex;
	flex-direction: column;

	${({ disabled }) => disableableComponent({ disabled })}
`;

import { StorageKeys } from 'types/index';
import { localLogOut, localLogIn, resetStore } from 'store/auth';
import { useDispatch, useEffectOnce } from 'hooks/utils';

export function useLoginSwitch() {
	const dispatch = useDispatch();

	// auto logout normal user when patient successfully logged in IN ANOTHER TAB and vice-versa
	// + login / logout sync all tabs
	function handleStorageChange({ key, newValue }: StorageEvent) {
		const isOnPatientPage = document.location.pathname.startsWith('/patient/');
		const isOnLoginPage =
			document.location.pathname.startsWith('/login') || document.location.pathname === '/';

		const canLogoutUser =
			key === StorageKeys.PatientLoginCompleted &&
			newValue === 'true' &&
			!isOnPatientPage &&
			!isOnLoginPage;

		const canLogoutPatient =
			key === StorageKeys.LoginCompleted &&
			newValue === 'true' &&
			isOnPatientPage &&
			!isOnLoginPage;

		const canLoginAllTabs =
			key === StorageKeys.LoginCompleted && newValue === 'true' && isOnLoginPage;

		const canLogoutAllTabs =
			!(
				localStorage.getItem(StorageKeys.LoginCompleted) &&
				localStorage.getItem(StorageKeys.IdToken)
			) &&
			!isOnPatientPage &&
			!isOnLoginPage;

		// PATIENT logged in -> LOGOUT USER
		if (canLogoutUser) {
			dispatch(localLogOut({ patient: false }));
		}

		// USER logged in -> LOGOUT PATIENT
		if (canLogoutPatient) {
			dispatch(localLogOut({ patient: true }));
		}

		// USER logged out -> LOGOUT SYNC ALL TABS
		if (canLogoutAllTabs) {
			dispatch(resetStore());
		}

		// USER logged in -> LOGIN SYNC ALL TABS
		if (canLoginAllTabs) {
			dispatch(localLogIn());
		}
	}

	useEffectOnce(() => {
		window.addEventListener('storage', handleStorageChange);

		return () => window.removeEventListener('storage', handleStorageChange);
	});
}

import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	height: 100%;
	padding: 0 1.6rem;
`;

export const AggregationRulesScrollContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;

	overflow-y: auto;
	width: calc(100% + 1.6rem);
	margin: -0.8rem;
	padding: 0.8rem;
`;

import { useCallback, useMemo } from 'react';
import { every, some } from 'lodash';
import { Spacer } from 'components/UI/Spacer';
import { TemplateVariablesCheckedData } from 'hooks/store/data/variables/useTemplateVariablesTableCheckedData';
import { BooleanMap, NumberMap } from 'types/index';
import { GroupRow } from 'components/Variables/VariablesTable/GroupRow';
import { VariableRow } from 'components/Variables/VariablesTable/VariableRow';
import { GroupsMap, VariablesDataArray, VariablesMap } from 'store/data/variables';
import { CenteredCheckbox, Hint, TableHeader, TH } from './style';
import { TemplateVariablesSubHeader } from './TemplateVariablesSubheader';
import { Table } from 'components/UI/Table';
import { variablesDataArrayIterator, variablesDataArrayJSXIterator } from 'helpers/variables';
import { useTranslation } from 'hooks/store';
import { useMutableState, useDeepCompareMemo } from 'hooks/utils';

enum GenerateType {
	variable = 'variable',
	group = 'group',
	series = 'series'
}
interface GenerateIdItems {
	label: string;
	type: GenerateType;
}

interface Props {
	variablesDataArray: VariablesDataArray;
	filteredVariablesMap: VariablesMap;
	filteredGroupsMap: GroupsMap;
	checkedMap: TemplateVariablesCheckedData['checkedMap'];
	toggleChecked: TemplateVariablesCheckedData['toggleChecked'];
	toggleAllChecked: TemplateVariablesCheckedData['toggleAllChecked'];
}

export function TemplateVariablesTable({
	variablesDataArray,
	filteredVariablesMap,
	filteredGroupsMap,
	checkedMap,
	toggleChecked,
	toggleAllChecked
}: Props) {
	const { translate } = useTranslation();

	const [expandedRows, setExpandedRows] = useMutableState<BooleanMap>({});

	function toggleExpandedRow(rowName: string) {
		setExpandedRows(state => {
			state[rowName] = !state[rowName];
		});
	}

	const toggleGroups = useCallback(
		(flag: boolean) => {
			const groupNames = Object.keys(checkedMap.groups);
			setExpandedRows(expandedState => {
				const toggledGroupsState = groupNames.reduce<BooleanMap>((acc, curr) => {
					acc[curr] = flag;
					return acc;
				}, {});
				return {
					...expandedState,
					...toggledGroupsState
				};
			});
		},
		[checkedMap, expandedRows]
	);

	function generateId(items: GenerateIdItems[]) {
		let id = '';
		items.forEach((item, index) => {
			let generatedLabel = item.label
				.replace(/[^a-zA-Z]/g, '')
				.substring(0, 2)
				.toLowerCase();
			if (generatedLabel.length !== 2) {
				generatedLabel = item.type.substring(0, 2).toLowerCase();
			}
			if (index !== items.length - 1) {
				id += `${generatedLabel}_`;
			} else {
				id += generatedLabel;
			}
		});
		return id;
	}

	const rowNumberByName = useMemo(() => {
		const numberMap: {
			variables: NumberMap;
			groups: NumberMap;
		} = {
			variables: {},
			groups: {}
		};

		variablesDataArrayIterator(
			variablesDataArray,
			(variable, index) => {
				numberMap.variables[variable.name] = index + 1;
			},
			(group, index) => {
				numberMap.groups[group.groupName] = index + 1;
			},
			() => null
		);

		return numberMap;
	}, [variablesDataArray]);

	const isAllChecked = useDeepCompareMemo(() => {
		const { groupVariables, ...checkedMapObject } = checkedMap;
		return every(
			[
				...Object.values(checkedMapObject.variables),
				...Object.values(checkedMapObject.groups)
			],
			value => !!value
		);
	}, [checkedMap]);

	const isPartiallyChecked = useDeepCompareMemo(() => {
		if (isAllChecked) return false;
		return (
			some(
				[...Object.values(checkedMap.variables), ...Object.values(checkedMap.groups)],
				value => !!value
			) ||
			some([...Object.values(checkedMap.groupVariables)], value => !!Object.values(value)[0])
		);
	}, [checkedMap, isAllChecked]);

	const checkedCount = useDeepCompareMemo(() => {
		let checkedCount = 0;
		// ADD MAIN VARIABLES COUNT
		checkedCount += Object.entries(checkedMap.variables)
			.filter(([, value]) => !!value)
			.filter(([name]) => filteredVariablesMap[name] !== undefined).length;

		// ADD GROUP VARIABLES COUNT
		const checkedGroupKeys = Object.keys(checkedMap.groups);
		checkedGroupKeys.forEach(groupKey => {
			// IF GROUP IS SELECTED
			if (checkedMap.groups[groupKey]) {
				// ADD VARIABLES COUNT
				if (filteredGroupsMap[groupKey]) {
					checkedCount += filteredGroupsMap[groupKey].variablesBelongingToGroup.length;
				}
			} else {
				// ADD INDIVIDUALLY SELECTED GROUP VARIABLES
				const groupVariableKeys = Object.keys(checkedMap.groupVariables[groupKey]);
				groupVariableKeys.forEach(groupVariableKey => {
					if (filteredVariablesMap[groupVariableKey]) checkedCount++;
				});
			}
		});
		return checkedCount;
	}, [filteredVariablesMap, filteredGroupsMap, checkedMap]);

	return (
		<>
			<TemplateVariablesSubHeader toggleGroups={toggleGroups} />

			<Spacer size={s => s.xs} />
			<Table.Responsive>
				<Table fullWidth hoverEffect>
					<Table.Head>
						<Table.Row>
							<TH colSpan={100}>
								<TableHeader align={t => t.center} fullWidth>
									<Hint>{`${checkedCount} selected`}</Hint>
								</TableHeader>
							</TH>
						</Table.Row>
						<Table.Row>
							<Table.Column
								width={5.6}
								minWidth={5.6}
								css={`
									cursor: pointer;
									/*
									RESETS THE COLUMN PADDING AND FLEX DIRECTION
									TO ALIGN CHECKBOX CORRECTLY
								*/
									padding-left: 0.8rem !important;
									padding-right: 0.8rem !important;
								`}
								onClick={toggleAllChecked}
							>
								<CenteredCheckbox
									checked={isAllChecked}
									partial={isPartiallyChecked}
									onClick={toggleAllChecked}
								/>
							</Table.Column>
							<Table.Column width={5.6} minWidth={5.6}>
								{translate(dict => dict.variablesPage.variablesTable.number)}
							</Table.Column>
							<Table.Column minWidth={20} maxWidth={50} width={50}>
								{translate(dict => dict.variablesPage.variablesTable.title)}
							</Table.Column>
							<Table.Column minWidth={15} maxWidth={20} width={20}>
								{translate(dict => dict.variablesPage.variablesTable.variableType)}
							</Table.Column>
							<Table.Column minWidth={20} maxWidth={30} width={30}>
								{translate(dict => dict.variablesPage.variablesTable.dataType)}
							</Table.Column>
							<Table.Column minWidth={20} maxWidth={40}>
								{translate(dict => dict.variablesPage.variablesTable.description)}
							</Table.Column>
						</Table.Row>
					</Table.Head>

					<Table.Body>
						{variablesDataArrayJSXIterator(
							variablesDataArray,
							/**
							 * VARIABLE
							 */
							variable => {
								return (
									<VariableRow
										readOnly={false}
										key={`variable_${variable.name}`}
										id={generateId([
											{
												label: variable.label,
												type: GenerateType.variable
											}
										])}
										variable={variable}
										rowNumber={rowNumberByName.variables[variable.name]}
										checkedData={{
											checked: checkedMap.variables[variable.name],
											onCheck: () =>
												toggleChecked({ variable: variable.name })
										}}
										onClick={() => toggleChecked({ variable: variable.name })}
										hasError={false}
									/>
								);
							},
							/**
							 * GROUP DATA
							 */
							groupData => {
								return (
									<GroupRow
										readOnly={false}
										key={`group_${groupData.groupName}`}
										id={generateId([
											{
												label: groupData.groupLabel,
												type: GenerateType.group
											}
										])}
										groupData={groupData}
										rowNumber={rowNumberByName.groups[groupData.groupName]}
										expandedRowsData={{
											expanded: expandedRows[groupData.groupName],
											onExpand: () => toggleExpandedRow(groupData.groupName)
										}}
										checkedRowData={{
											checked: checkedMap.groups[groupData.groupName],
											partial:
												checkedMap.groupVariables &&
												checkedMap.groupVariables[groupData.groupName] &&
												Object.values(
													checkedMap.groupVariables[groupData.groupName]
												).some(
													variable => !!variable
												) /*checkedState.partial.groups[groupData.groupName],*/,
											onCheck: () =>
												toggleChecked({ group: groupData.groupName })
										}}
										hasError={false}
										onClick={() =>
											toggleChecked({ group: groupData.groupName })
										}
										renderVariableRow={variable => {
											const groupParent = groupData.groupName;

											return (
												<VariableRow
													readOnly={false}
													key={`group_${groupData.groupName}-variable_${variable.name}`}
													id={generateId([
														{
															label: groupData.groupLabel,
															type: GenerateType.group
														},
														{
															label: variable.label,
															type: GenerateType.variable
														}
													])}
													variable={variable}
													checkedData={{
														checked:
															checkedMap.groups[groupParent] ||
															checkedMap.groupVariables[groupParent][
																variable.name
															],
														onCheck: () =>
															toggleChecked({
																groupName: groupData.groupName,
																groupVariable: variable.name
															})
													}}
													hasError={false}
													onClick={() =>
														toggleChecked({
															groupName: groupData.groupName,
															groupVariable: variable.name
														})
													}
													isNested
												/>
											);
										}}
									/>
								);
							},
							/**
							 * VARIABLE SET DATA
							 */
							() => null
						)}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</>
	);
}

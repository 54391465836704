import { useActivity } from 'hooks/store/utils/useActivity';
import { useSelector, useDispatch } from 'hooks/utils';
import { useEffect } from 'react';
import { ActionTypes, getCollaborators } from 'store/data/collaborators';

export function useRefetchCollaborators() {
	const dispatch = useDispatch();
	const refetch = useSelector(state => state.data.collaborators.refetch);

	const projectId = useSelector(state => state.data.projects.projectId);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_COLLABORATORS, {
		projectId
	});

	useEffect(() => {
		if (refetch) {
			handler();
		}
	}, [refetch]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getCollaborators());
	}
}

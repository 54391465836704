import { produce } from 'immer';
import initialState from './initialState';
import { ActionTypes, Actions, State, EntryFiles, EntriesById } from './types';

import { ActionTypes as FiltersActionTypes, Actions as FiltersActions } from '../filters/types';
import { ActionTypes as ProjectsActionTypes, Actions as ProjectsActions } from '../projects/types';
import { ActionTypes as TablesActionTypes, Actions as TablesActions } from '../../ui/tables/types';
import { buildEntriesById, buildSafeEntry, initEntry } from 'helpers/entries';

export default (
	state: State = initialState,
	action: Actions | FiltersActions | ProjectsActions | TablesActions
): State => {
	switch (action.type) {
		case ActionTypes.GET_ENTRIES: {
			const { projectId, entries, statuses, errors, totalCount } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					metadata: { pageIndex, totalCount: prevTotalCount }
				} = draft;

				if (projectId === currentProjectId) {
					const entriesById = buildEntriesById(entries);
					const entryIds = entries.map(row => row.datasetentryid);
					const count = entries.length;

					// reset cached pages if new totalCount is different change
					if (prevTotalCount !== totalCount) {
						draft.rows.idsByPage = {};
					}

					draft.rows = {
						idsByPage: {
							...draft.rows.idsByPage,
							[pageIndex]: entryIds
						},
						byId: {
							...draft.rows.byId,
							entries: { ...draft.rows.byId.entries, ...entriesById }
						},
						ids: [...new Set([...draft.rows.ids, ...entryIds])],
						count,
						latestFetched: false
					};
					// init pagination state
					if (totalCount !== undefined) {
						draft.metadata.totalCount = totalCount;
					}

					draft.statuses.byId.initial = statuses ?? {};
					draft.statuses.byId.current = statuses ?? {};
					// draft.metadata.pageIndex = 0;
					draft.metadata.fetched = true;
					draft.metadata.refetch = false;
					draft.errors = {
						data: errors ?? null,
						filter: {
							columns: false,
							rows: false
						}
					};
				}
			});
		}

		case ActionTypes.GET_MORE_ENTRIES: {
			const { projectId, entries } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId } = draft;

				if (currentProjectId !== projectId) return;

				const entriesById = buildEntriesById(entries);
				const entryIds = entries.map(row => row.datasetentryid);

				draft.rows.byId = {
					...draft.rows.byId,
					entries: { ...draft.rows.byId.entries, ...entriesById }
				};
				draft.rows.ids.push(...entryIds);
				draft.rows.count = (draft.rows.count || 0) + entries.length;
			});
		}

		case ActionTypes.GET_ENTRY: {
			const { projectId, entry, entryStatus, columns } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId } = draft;

				if (currentProjectId !== projectId) return;

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				const { datasetentryid } = _entry;

				const rows = [_entry];

				const rowsById = buildEntriesById(rows);

				const count = draft.rows.count !== null ? draft.rows.count + 1 : null;

				draft.rows = {
					...draft.rows,
					byId: { entries: rowsById, conflicted: {} },
					// TODO: FIX WHEN REFACTOR
					ids: [datasetentryid],
					count,
					latestFetched: false
				};
				draft.metadata.entryId = datasetentryid;
			});
		}

		case ActionTypes.GET_LATEST_ENTRY: {
			const { projectId, oldEntryId, entry, entryStatus, columns } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					rows: { byId, ids, idsByPage },
					statuses,
					drafts,
					metadata
				} = draft;

				if (currentProjectId !== projectId) return;

				// REMOVE OLD ENTRY
				delete byId.entries[oldEntryId];
				// REMOVE OLD ENTRY STATUS
				delete statuses.byId.initial[oldEntryId];
				// REMOVE OLD DRAFT
				delete drafts.byId[oldEntryId];

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				const { datasetentryid } = _entry;

				// INSERT LATEST ENTRY

				const oldEntryIdIndex = ids.indexOf(oldEntryId);

				byId.entries[datasetentryid] = _entry;
				ids[oldEntryIdIndex] = datasetentryid;

				if (idsByPage[metadata.pageIndex]) {
					const targetPageIndex = idsByPage[metadata.pageIndex]?.findIndex(
						oldId => oldId === oldEntryId
					);
					if (targetPageIndex !== undefined) {
						idsByPage[metadata.pageIndex]![targetPageIndex] = datasetentryid;
					}
				}

				// INSERT LATEST ENTRY STATUS
				statuses.byId.initial[datasetentryid] = entryStatus;

				draft.rows.latestFetched = true;

				if (metadata.entryId) metadata.entryId = datasetentryid;
			});
		}

		case ActionTypes.GET_CONFLICT_ENTRY: {
			const { projectId, entry, entryStatus, columns } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, rows } = draft;

				if (currentProjectId !== projectId) return;

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				rows.prevEntry = _entry;
			});
		}

		case ActionTypes.CREATE_ENTRY: {
			const { projectId, entry, entryStatus, columns, setEntryId } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					rows: { byId, ids },
					drafts,
					metadata
				} = draft;

				if (currentProjectId !== projectId) return;

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				const { datasetentryid } = _entry;

				// INSERT NEW ENTRY
				byId.entries[datasetentryid] = _entry;
				ids.push(datasetentryid);

				if (setEntryId) metadata.entryId = datasetentryid;

				// RESET DRAFT
				drafts.new = {
					data: null,
					fetched: false
				};
			});
		}

		case ActionTypes.UPDATE_ENTRY: {
			const { projectId, oldEntryId, entry, entryStatus, columns } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					rows: { byId, ids, idsByPage },
					drafts,
					metadata
				} = draft;

				if (currentProjectId !== projectId) return;

				// REMOVE OLD ENTRY
				delete byId.entries[oldEntryId];

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				const { datasetentryid } = _entry;

				// INSERT UPDATED ENTRY
				byId.entries[datasetentryid] = _entry;

				const oldEntryIdIndex = ids.indexOf(oldEntryId);

				ids[oldEntryIdIndex] = datasetentryid;
				if (idsByPage[metadata.pageIndex]) {
					const targetPageIndex = idsByPage[metadata.pageIndex]!.findIndex(
						oldId => oldId === oldEntryId
					);
					if (targetPageIndex !== -1) {
						idsByPage[metadata.pageIndex]![targetPageIndex] = datasetentryid;
					}
				}

				// CONFLICTS CLEANUP
				byId.conflicted[oldEntryId] = {
					values: {},
					statuses: {}
				};

				draft.rows.prevEntry = undefined;

				// RESET DRAFT
				delete drafts.byId[oldEntryId];

				metadata.entryId = datasetentryid;
			});
		}

		case ActionTypes.UPDATE_ENTRY_STATUS: {
			const { projectId, oldEntryId, entry, entryStatus, columns } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					rows: { byId, ids, idsByPage },
					drafts,
					metadata
				} = draft;

				if (currentProjectId !== projectId) return;

				// REMOVE OLD ENTRY
				delete byId.entries[oldEntryId];

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				const { datasetentryid } = _entry;

				// INSERT UPDATED ENTRY
				byId.entries[datasetentryid] = _entry;

				const oldEntryIdIndex = ids.indexOf(oldEntryId);

				ids[oldEntryIdIndex] = datasetentryid;
				if (idsByPage[metadata.pageIndex]) {
					const targetPageIndex = idsByPage[metadata.pageIndex]!.findIndex(
						oldId => oldId === oldEntryId
					);
					if (targetPageIndex !== -1) {
						idsByPage[metadata.pageIndex]![targetPageIndex] = datasetentryid;
					}
				}

				// CONFLICTS CLEANUP
				byId.conflicted[oldEntryId] = {
					values: {},
					statuses: {}
				};

				draft.rows.prevEntry = undefined;

				// RESET DRAFT
				delete drafts.byId[oldEntryId];

				metadata.entryId = datasetentryid;
			});
		}

		case ActionTypes.DELETE_ENTRY: {
			const { projectId, entryId } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					rows,
					subEntries,
					statuses,
					drafts,
					metadata
				} = draft;

				if (currentProjectId !== projectId) return;

				delete rows.byId.entries[entryId];
				delete drafts.byId[entryId];
				delete statuses.byId.initial[entryId];
				delete statuses.byId.current[entryId];
				delete subEntries.byEntryId.initial[entryId];
				delete subEntries.byEntryId.current[entryId];

				metadata.entryId = null;
				rows.latestFetched = false;
				metadata.refetch = true;

				rows.ids = rows.ids.filter(id => id !== entryId);
				rows.idsByPage[metadata.pageIndex] = rows.idsByPage[metadata.pageIndex]?.filter(
					id => id !== entryId
				);

				/**
				 * REPAIR ENTRIES TABLE PAGINATION
				 */
				const { pageIndex, pageSize } = metadata;

				const pagesCount = Math.ceil(rows.ids.length / pageSize);
				const newPageIndex = pagesCount - 1;
				const lastPageIndex = newPageIndex < 0 ? 0 : newPageIndex;

				if (pageIndex > lastPageIndex) metadata.pageIndex = lastPageIndex;
			});
		}

		case ActionTypes.HYDRATE_ENTRY: {
			const { entry, entryStatus, columns } = action.payload;

			return produce(state, draft => {
				const {
					rows: { byId },
					statuses
				} = draft;

				const _entry = buildSafeEntry({
					entry,
					entryStatus,
					columns
				});

				const { datasetentryid } = _entry;

				// HYDRATE ENTRY
				byId.entries[datasetentryid] = _entry;
				statuses.byId.initial[datasetentryid] = entryStatus;
				statuses.byId.current[datasetentryid] = entryStatus;
			});
		}

		case ActionTypes.SET_ENTRY_STATUS: {
			const { status, entryId: newEntryId, oldEntryId, initial } = action.payload;

			return produce(state, draft => {
				if (initial) {
					// REMOVE OLD STATUS
					if (oldEntryId && oldEntryId in draft.statuses.byId) {
						delete draft.statuses.byId.initial[oldEntryId];
					}
					// SET NEW STATUS
					draft.statuses.byId.initial[newEntryId] = status;
				} else {
					// REMOVE OLD STATUS
					if (oldEntryId && oldEntryId in draft.statuses.byId) {
						delete draft.statuses.byId.current[oldEntryId];
					}
					// SET NEW STATUS
					draft.statuses.byId.current[newEntryId] = status;
				}
			});
		}

		case ActionTypes.SET_ENTRY_ID: {
			const { entryId } = action.payload;

			return produce(state, draft => {
				draft.metadata.entryId = entryId;
				draft.metadata.validationErrors = {};
				if (!entryId) {
					delete draft.rows.prevEntry;
				}
			});
		}

		case ActionTypes.SET_ENTRIES_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.term = term;
				draft.metadata.pageIndex = 0;
				// reset cached pages on pageSize change
				draft.rows.idsByPage = {};
			});
		}

		case ActionTypes.SET_ENTRIES_TABLE_PARAMS: {
			const {
				pageIndex = state.metadata.pageIndex,
				pageSize = state.metadata.pageSize,
				totalCount = state.metadata.totalCount
			} = action.payload;

			return produce(state, draft => {
				draft.metadata.pageIndex = pageIndex;
				draft.metadata.pageSize = pageSize;
				draft.metadata.totalCount = totalCount;

				// reset cached pages on pageSize change
				if (pageSize !== state.metadata.pageSize) {
					draft.rows.idsByPage = {};
				}
			});
		}

		case ActionTypes.SET_ENTRIES_TABLE_ERRORS_FILTER: {
			const { columns, rows } = action.payload;

			return produce(state, draft => {
				const { errors, metadata } = draft;

				errors.filter = {
					columns,
					rows
				};

				// Reset pagination if rows are filtered
				if (rows) metadata.pageIndex = 0;
			});
		}

		case ActionTypes.GET_ENTRY_FILE: {
			const { file } = action.payload;

			return produce(state, draft => {
				draft.files.byId = {
					...draft.files.byId,
					[file.fileId]: file
				};
			});
		}

		case ActionTypes.GET_ENTRY_FILES: {
			const { files } = action.payload;

			return produce(state, draft => {
				draft.files.byId = {
					...draft.files.byId,
					...files
				};
			});
		}

		case ActionTypes.ADD_LOCAL_ENTRY_FILE: {
			const { file } = action.payload;

			const toStore = [...state.files.toStore];
			if (!toStore.includes(file.fileId)) {
				toStore.push(file.fileId);
			}

			return produce(state, draft => {
				draft.files = {
					...draft.files,
					byId: {
						...draft.files.byId,
						[file.fileId]: file
					},
					toStore: toStore
				};
			});
		}

		case ActionTypes.DELETE_LOCAL_ENTRY_FILE: {
			const { id } = action.payload;

			const {
				[id]: {},
				...rest
			} = state.files.byId;

			const toStore = state.files.toStore.filter(fileId => fileId !== id);

			return produce(state, draft => {
				draft.files = {
					...draft.files,
					byId: rest,
					toStore: toStore
				};
			});
		}

		case ActionTypes.DELETE_ALL_LOCAL_ENTRY_FILES: {
			return produce(state, draft => {
				const { byId } = draft.files;

				let newById: EntryFiles = {};

				Object.values(byId).forEach(entryValue => {
					if (entryValue.isLocal) return;

					newById = { ...newById, [entryValue.fileId]: entryValue };
				});

				draft.files = {
					byId: newById,
					toStore: []
				};
			});
		}

		case ActionTypes.REBUILD_ENTRIES: {
			const { columns } = action.payload;

			return produce(state, draft => {
				const {
					rows: { byId: rowsById },
					metadata: { columnSettings }
				} = draft;

				// ============== BUILD NEW ROWS ================
				const entriesById: EntriesById = {};

				Object.values(rowsById.entries).forEach(row => {
					const computedRow = initEntry();

					columns.forEach(name => {
						const columnExists = name in row;

						if (columnExists) {
							computedRow[name] = row[name];
						} else {
							// NEW VARIABLE/COLUMN - INIT EMPTY
							computedRow[name] = null;
						}
					});

					entriesById[computedRow.datasetentryid] = computedRow;
				});
				// ============== BUILD NEW ROWS ================

				draft.rows.byId.entries = entriesById;

				// SYNC UP COLUMNS FILTER - FILTER OUT ALL NON-EXISTENT COLUMN NAMES (DELETION CASE)
				columnSettings.visible = columnSettings.visible.filter(columnName =>
					columns.includes(columnName)
				);
			});
		}

		case ActionTypes.SET_REFETCH_ENTRIES: {
			return produce(state, draft => {
				draft.metadata.refetch = true;
			});
		}

		case ActionTypes.RESET_ENTRY_FETCHED_DATA: {
			const { entryId } = action.payload;

			return produce(state, draft => {
				const { rows, subEntries, drafts } = draft;

				rows.latestFetched = false;
				subEntries.selected.setName = null;
				subEntries.selected.subEntryId = null;

				delete subEntries.byEntryId.initial[entryId];
				delete subEntries.byEntryId.current[entryId];
				delete subEntries.count.byEntryId[entryId];
				delete drafts.byId[entryId];
			});
		}

		case ActionTypes.SET_ENTRIES_TABLE_VISIBLE_COLUMNS: {
			const { columnNames } = action.payload;

			return produce(state, draft => {
				const {
					metadata: { columnSettings }
				} = draft;

				columnSettings.visible = columnNames;
			});
		}

		/**
		 * ENTRY DRAFT
		 */

		case ActionTypes.GET_ENTRY_DRAFT: {
			const { entryDraft, entryId } = action.payload;

			return produce(state, draft => {
				const {
					drafts,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentEntryId !== entryId) return;

				if (entryId !== null) {
					drafts.byId[entryId] = {
						data: entryDraft,
						fetched: true
					};
				} else {
					drafts.new = {
						data: entryDraft,
						fetched: true
					};
				}
			});
		}

		case ActionTypes.SAVE_ENTRY_DRAFT: {
			const { entryDraft, entryId } = action.payload;

			return produce(state, draft => {
				const {
					drafts,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentEntryId !== entryId) return;

				if (entryId !== null) {
					// INIT STRUCTURE
					if (!drafts.byId[entryId]) {
						drafts.byId[entryId] = { data: null, fetched: false };
					}

					const entryDraftData = drafts.byId[entryId];

					entryDraftData.data = entryDraft;
					entryDraftData.fetched = true;
				} else {
					drafts.new.data = entryDraft;
					drafts.new.fetched = true;
				}
			});
		}

		case ActionTypes.RESET_CREATE_ENTRY_DRAFT: {
			return produce(state, draft => {
				const { drafts } = draft;

				drafts.new = {
					data: null,
					fetched: false
				};
			});
		}

		case ActionTypes.GET_SERIES_ENTRIES: {
			const { projectId, entryId, setName, entries } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					subEntries,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentProjectId !== projectId) return;
				if (currentEntryId !== entryId) return;

				const { byEntryId } = subEntries;

				// INIT 1ST LEVEL STRUCTURE
				if (!byEntryId.initial[entryId]) byEntryId.initial[entryId] = { bySetName: {} };
				if (!byEntryId.current[entryId]) byEntryId.current[entryId] = { bySetName: {} };

				// INIT 2ND LEVEL STRUCTURE
				if (!byEntryId.initial[entryId].bySetName[setName]) {
					byEntryId.initial[entryId].bySetName[setName] = {
						rows: {
							byId: {},
							ids: []
						},
						fetched: false
					};
				}
				if (!byEntryId.current[entryId].bySetName[setName]) {
					byEntryId.current[entryId].bySetName[setName] = {
						rows: {
							byId: {},
							ids: []
						},
						fetched: false
					};
				}

				const entriesById = buildEntriesById(entries);
				const entryIds = entries.map(row => row.datasetentryid);

				byEntryId.initial[entryId].bySetName[setName].rows.byId = entriesById;
				byEntryId.initial[entryId].bySetName[setName].rows.ids = entryIds;
				byEntryId.initial[entryId].bySetName[setName].fetched = true;

				byEntryId.current[entryId].bySetName[setName].rows.byId = entriesById;
				byEntryId.current[entryId].bySetName[setName].rows.ids = entryIds;
				byEntryId.current[entryId].bySetName[setName].fetched = true;
			});
		}

		case ActionTypes.CREATE_SERIES_ENTRY: {
			const { projectId, parentEntryId, setName, entry, columns, setEntryId } =
				action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					subEntries,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentProjectId !== projectId) return;
				if (currentEntryId !== parentEntryId) return;

				const { byEntryId, selected } = subEntries;

				const {
					rows: { byId, ids }
				} = byEntryId.initial[parentEntryId].bySetName[setName];

				const _entry = buildSafeEntry({
					entry,
					entryStatus: null,
					columns
				});

				const { datasetentryid } = _entry;

				// INSERT NEW ENTRY
				byId[datasetentryid] = _entry;
				byEntryId.current[parentEntryId].bySetName[setName].rows.byId[datasetentryid] =
					_entry;

				ids.push(datasetentryid);
				byEntryId.current[parentEntryId].bySetName[setName].rows.ids.push(datasetentryid);

				if (setEntryId) {
					selected.subEntryId = datasetentryid;
				} else {
					selected.setName = null;
					selected.subEntryId = null;
				}
			});
		}

		case ActionTypes.UPDATE_SERIES_ENTRY: {
			const { projectId, parentEntryId, setName, oldEntryId, entry, columns, setEntryId } =
				action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					subEntries,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentProjectId !== projectId) return;
				if (currentEntryId !== parentEntryId) return;

				const { byEntryId, selected } = subEntries;

				const {
					rows: { byId, ids }
				} = byEntryId.initial[parentEntryId].bySetName[setName];

				const _entry = buildSafeEntry({
					entry,
					entryStatus: null,
					columns
				});

				const { datasetentryid } = _entry;

				// INSERT UPDATED ENTRY
				byId[datasetentryid] = _entry;
				byEntryId.current[parentEntryId].bySetName[setName].rows.byId[datasetentryid] =
					_entry;

				//INITIAL
				const oldEntryIdIndex = ids.indexOf(oldEntryId);

				ids[oldEntryIdIndex] = datasetentryid;

				delete byId[oldEntryId];

				//CURRENT
				const oldCurrentEntryIdIndex =
					byEntryId.current[parentEntryId].bySetName[setName].rows.ids.indexOf(
						oldEntryId
					);

				byEntryId.current[parentEntryId].bySetName[setName].rows.ids[
					oldCurrentEntryIdIndex
				] = datasetentryid;

				delete byEntryId.current[parentEntryId].bySetName[setName].rows.byId[oldEntryId];

				// DELETE CONFLICTS DATA
				byEntryId.conflicted[parentEntryId] = {
					statuses: {},
					values: {}
				};

				if (setEntryId) {
					selected.subEntryId = datasetentryid;
				} else {
					selected.setName = null;
					selected.subEntryId = null;
				}
			});
		}

		case ActionTypes.DELETE_SERIES_ENTRY: {
			const { projectId, parentEntryId, setName, entryId } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					subEntries,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentProjectId !== projectId) return;
				if (currentEntryId !== parentEntryId) return;

				const { byEntryId, selected } = subEntries;

				const { rows } = byEntryId.initial[parentEntryId].bySetName[setName];

				const { byId } = rows;

				delete byId[entryId];
				delete byEntryId.current[parentEntryId].bySetName[setName].rows.byId[entryId];

				rows.ids = rows.ids.filter(id => id !== entryId);
				byEntryId.current[parentEntryId].bySetName[setName].rows.ids = byEntryId.current[
					parentEntryId
				].bySetName[setName].rows.ids.filter(id => id !== entryId);

				const lastEntryId: string | null = rows.ids[rows.ids.length - 1];

				selected.subEntryId = lastEntryId ?? null;
			});
		}

		case ActionTypes.GET_SERIES_ENTRIES_COUNT: {
			const { projectId, entryId, entriesCount } = action.payload;

			return produce(state, draft => {
				const {
					projectId: currentProjectId,
					subEntries,
					metadata: { entryId: currentEntryId }
				} = draft;

				if (currentProjectId !== projectId) return;
				if (currentEntryId !== entryId) return;

				const { count } = subEntries;

				count.byEntryId[entryId] = {
					bySetName: entriesCount,
					fetched: true
				};
			});
		}

		case ActionTypes.SET_SELECTED_SERIES_ENTRY: {
			const { selected } = action.payload;

			return produce(state, draft => {
				draft.subEntries.selected = selected;
			});
		}

		case ActionTypes.SET_CONFLICTED_DATA: {
			const conflictedData = action.payload;

			return produce(state, draft => {
				const entryId = state.metadata.entryId;
				if (entryId) {
					draft.rows.byId.conflicted[entryId] = conflictedData ?? null;
				}
			});
		}

		case ActionTypes.SET_SERIES_CONFLICTED_DATA: {
			const conflictedData = action.payload;

			return produce(state, draft => {
				const entryId = state.subEntries.selected.subEntryId;
				if (entryId) {
					draft.subEntries.byEntryId.conflicted[entryId] = conflictedData ?? null;
				}
			});
		}

		case ActionTypes.ADD_NAMES_FROM_USER_IDS: {
			const { namesFromUserIds } = action.payload;

			return produce(state, draft => {
				draft.metadata.namesFromUserIds = {
					...draft.metadata.namesFromUserIds,
					...namesFromUserIds
				};
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ProjectsActionTypes.UPLOAD_PROJECT_DATASET:
		case ProjectsActionTypes.UPDATE_EXISTING_DATASET:
		case ProjectsActionTypes.UPLOAD_TO_EXISTING_DATASET: {
			return produce(state, draft => {
				draft.rows = {
					idsByPage: {},
					byId: { entries: {}, conflicted: {} },
					ids: [],
					count: null,
					latestFetched: false
				};
				// RESET DRAFTS
				draft.drafts = {
					new: {
						data: null,
						fetched: false
					},
					byId: {}
				};
			});
		}

		case FiltersActionTypes.UPDATE_FILTER:
		case FiltersActionTypes.DELETE_DATASET_FILTER:
		case FiltersActionTypes.CLEAR_FILTERS: {
			// even if pageIndex is already 0, this should trigger a on component 'gotoPage' method
			return produce(state, draft => {
				draft.metadata.pageIndex = 0;

				// reset cached pages on pageSize change
				draft.rows.idsByPage = {};
			});
		}

		case TablesActionTypes.SET_ACTIVE_SORT: {
			return produce(state, draft => {
				// reset cached pages on sort change
				draft.rows.idsByPage = {};
			});
		}

		default: {
			return state;
		}
	}
};

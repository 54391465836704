import { Draggable, DraggingStyle } from 'react-beautiful-dnd';
import { Variable } from 'api/data/variables';
import { Svgs } from 'environment';
import { getCloneStyle } from 'helpers/templates';
import { Container, AddIcon, TextWrapper } from './DraggableDependencyVariableCard.style';
import { Asterisk } from 'components/UI/Asterisk';
import { Typography } from 'components/UI/Typography';
import { useDependenciesContext } from 'hooks/store';

interface Props {
	index: number;
	variable: Variable;
	isGrouped?: boolean;
}

export function DraggableDependencyVariableCard({ index, variable, isGrouped }: Props) {
	const {
		actions: { createDependency }
	} = useDependenciesContext();

	return (
		<Draggable draggableId={variable.name} index={index} key={index}>
			{(provided, snapshot) => (
				<>
					{snapshot.isDragging && (
						<Container>
							<TextWrapper>
								<Typography.Paragraph title={variable.label} ellipsis>
									{variable.label}
								</Typography.Paragraph>
								{variable.obligatory && <Asterisk paddingLeft />}
							</TextWrapper>
							<AddIcon svg={Svgs.Add} active />
						</Container>
					)}
					<Container
						key={variable.name}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getCloneStyle(
							provided.draggableProps.style as DraggingStyle,
							snapshot
						)}
						isGrouped={isGrouped}
					>
						<TextWrapper>
							<Typography.Paragraph title={variable.label} ellipsis>
								{variable.label}
							</Typography.Paragraph>
							{variable.obligatory && <Asterisk paddingLeft />}
						</TextWrapper>
						<AddIcon
							svg={Svgs.Add}
							onClick={() =>
								createDependency({
									variableName: variable.name
								})
							}
							active
						/>
					</Container>
				</>
			)}
		</Draggable>
	);
}

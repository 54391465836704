import { MaintenanceWarning } from 'components/UI/MaintenanceWarning';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Typography } from 'components/UI/Typography';

import { Colors } from 'environment';
import { Link } from 'react-router-dom';

export function HickupsWarning() {
	const { maintenance } = useFlags();
	switch (maintenance) {
		case 'NONE':
			// this is the default value of the LD flag when it is disabled.
			return null;
		case 'PLANNED':
			return (
				<MaintenanceWarning>
					<Typography.Paragraph
						color={Colors.text.main}
						marginOffset={{ top: 0.1 }}
						multiline
					>
						Ledidi Prime will be down for maintenance Saturday 13th of January from
						10:00 CET. Expected downtime is 1 hour.
					</Typography.Paragraph>
				</MaintenanceWarning>
			);
		case 'ONGOING':
			return (
				<MaintenanceWarning>
					<Typography.Paragraph
						color={Colors.text.main}
						marginOffset={{ top: 0.1 }}
						multiline
					>
						Ledidi Prime is currently down for maintenance. We expect to be back 11:00
						CET.
					</Typography.Paragraph>
				</MaintenanceWarning>
			);
		case 'PROLONGED':
			return (
				<MaintenanceWarning>
					<Typography.Paragraph
						color={Colors.text.main}
						marginOffset={{ top: 0.1 }}
						multiline
					>
						The scheduled update is taking somewhat longer than expected. We are hard at
						work and expect to have the update done by morning.
					</Typography.Paragraph>
				</MaintenanceWarning>
			);
		case 'DONE':
			return (
				<MaintenanceWarning>
					<Typography.Paragraph
						color={Colors.text.main}
						marginOffset={{ top: 0.1 }}
						multiline
					>
						Ledidi Prime was just updated. If you experience any issues, please
						don&apos;t hesitate to contact us at{' '}
						<Link to="mailto:support@ledidi.com">support@ledidi.com</Link>.
					</Typography.Paragraph>
				</MaintenanceWarning>
			);
		default:
			// this is to catch if the LD flag is not defined.
			return null;
	}
}

import {
	JsonLogicMap,
	Variable,
	VariableAliasesMap,
	VariableAliasesActions
} from 'api/data/variables';
import { useState } from 'react';
import { nanoid as generate } from 'nanoid';
import { VariableType } from 'types/data/variables/constants';

export function useCalculationVariableAliases(
	jsonLogic: JsonLogicMap,
	variables: Variable[],
	previousVariableAliases: VariableAliasesMap | null = null
) {
	const [variableAliases, setVariableAliases] = useState<VariableAliasesMap>(
		previousVariableAliases ?? getInitialMapValue(jsonLogic, variables)
	);

	function handleEntryToMap() {
		const id = generate();

		setVariableAliases(prev => {
			return {
				order: [...prev.order, id],
				map: {
					...prev.map,
					[id]: {
						key: '',
						variableId: '',
						type: VariableType.String
					}
				}
			};
		});
	}

	function setVariableAlias(id: string, key: string) {
		setVariableAliases(prev => ({
			...prev,
			map: {
				...prev.map,
				[id]: {
					...prev.map[id],
					key
				}
			}
		}));
	}

	function setVariableId(id: string, varName: string) {
		const variable = variables.find(v => v.name === varName);
		const variableType = variable ? variable.type : VariableType.String;
		const aliasKey = variable ? variable.label.toLowerCase().replace(/ /g, '') : '';

		setVariableAliases(prev => {
			return {
				...prev,
				map: {
					...prev.map,
					[id]: {
						...prev.map[id],
						variableId: varName,
						type: variableType,
						key: prev.map[id].key === '' ? aliasKey : prev.map[id].key
					}
				}
			};
		});
	}

	function removeEntry(id: string) {
		setVariableAliases(prev => {
			const newMap = { ...prev.map };
			delete newMap[id];

			return {
				...prev,
				order: prev.order.filter(orderId => orderId !== id),
				map: newMap
			};
		});
	}

	function setKeyMap(newMap: VariableAliasesMap) {
		setVariableAliases(newMap);
	}

	const keymapActions: VariableAliasesActions = {
		handleEntryToMap,
		setVariableAlias,
		setVariableId,
		removeEntry,
		setKeyMap
	};

	return [variableAliases, keymapActions] as const;
}

function getInitialMapValue(jsonLogic: JsonLogicMap, variables: Variable[]): VariableAliasesMap {
	const keyMap: VariableAliasesMap = {
		order: [],
		map: {}
	};

	function traverseJsonLogic(jsonLogic: any, count = 0) {
		if (jsonLogic === null || jsonLogic === undefined) return;

		if (
			typeof jsonLogic === 'object' &&
			'var' in jsonLogic &&
			Array.isArray(jsonLogic.var) &&
			jsonLogic.var.length === 2
		) {
			const variable = variables.find(variable => variable.name === jsonLogic.var[0]);

			if (variable) {
				const id = generate();

				keyMap.order.push(id);
				keyMap.map[id] = {
					key: variable.name,
					variableId: variable.name,
					type: variable.type
				};
			}
		} else if (Array.isArray(jsonLogic)) {
			jsonLogic.forEach(e => traverseJsonLogic(e, count++));
		} else if (typeof jsonLogic === 'object') {
			Object.values(jsonLogic).forEach(e => traverseJsonLogic(e, count++));
		}
	}

	jsonLogic.order.forEach(key => {
		traverseJsonLogic(jsonLogic.logics[key]);
	});

	if (!keyMap.order.length) {
		const id = generate();

		keyMap.order.push(id);
		keyMap.map[id] = {
			key: '',
			variableId: '',
			type: VariableType.String
		};
	}

	return keyMap;
}

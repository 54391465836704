import styled, { css } from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Colors } from 'environment';

export const Icon = styled(DefaultIcon)`
	align-self: flex-start;
`;

interface CardHeaderProps {
	cursorPointer?: boolean;
	hasChanges?: boolean;
}
export const CardHeader = styled.div<CardHeaderProps>`
	display: flex;
	padding: 0.8rem;
	flex: 1;
	justify-content: space-between;

	${({ cursorPointer }) =>
		cursorPointer &&
		css`
			cursor: pointer;
		`}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`}
`;

export const GroupTitleWrapper = styled.div`
	display: flex;
	width: calc(100% - 2.4rem);
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	width: calc(100% - 2.4rem);
`;

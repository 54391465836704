import styled, { css } from 'styled-components';

import { getTypographyCSS } from 'components/UI/Typography';
import { Colors, MediaQueries } from 'environment';
import { Button as DefaultButton } from 'components/UI/Interactables/Button';
import { Icon } from 'components/UI/Icons';

export const Input = styled.input`
	/* TYPOGRAPHY SETTER */
	${getTypographyCSS(t => t.Paragraph)}

	/* REMOVE AUTOFIL BACKGROUND COLOR */
	:-webkit-autofill,
	:-webkit-autofill:hover,
	:-webkit-autofill:focus,
	:-webkit-autofill:active {
		box-shadow: 0 0 0px 100rem white inset !important;
	}

	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	padding: 0;
	color: ${Colors.text.main};

	::placeholder {
		color: ${Colors.text.caption};
	}
`;

interface ContainerProps {
	disabled?: boolean;
	usedInHeader?: boolean;
	focused?: boolean;
	hasSubmitButton?: boolean;
}

export const Container = styled.div<ContainerProps>`
	${({ disabled }) => css`
		width: 100%;
		height: 4rem;
		min-height: 4rem;
		padding: 0.8rem;
		display: flex;
		align-items: center;
		border: 0.1rem solid ${Colors.text.disabled};
		border-radius: 0.4rem;
		transition: all 0.2s;

		${!disabled &&
		css`
			:focus-within {
				border: 0.1rem solid ${Colors.primary.normal};
			}
		`}

		${disabled &&
		css`
			border: 0.1rem solid ${Colors.transparent};
			cursor: not-allowed;
			background-color: ${Colors.background.disabled};

			${Input} {
				cursor: not-allowed;
				pointer-events: none;
				background-color: ${Colors.background.disabled};
			}
		`};
	`}

	${({ usedInHeader, focused }) =>
		usedInHeader &&
		css`
			@media only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.lg} {
				justify-content: flex-end;

				${focused
					? css`
							width: calc(92vw - 6.4rem);
							transition: background-color 0s;
							background-color: ${Colors.white};
					  `
					: css`
							border: none;

							${Input} {
								opacity: 0;
								width: 0;
							}
					  `}
			}
		`};
	${({ hasSubmitButton }) =>
		hasSubmitButton &&
		css`
			position: relative;
			padding-right: 4.6rem;
		`}
`;

export const SearchButton = styled(Icon)`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 3.6rem;
	border-radius: 0 0.4rem 0.4rem 0;
	border-left: 0.1rem solid #b0b0b0;
	background-color: #f6f6f6;
	&:hover {
		background-color: #eef7ff;
	}
`;

export const Button = styled(DefaultButton)`
	min-height: 1px;
	min-width: 1px;
	padding: 0.4rem 0.8rem;
`;

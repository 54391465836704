import { useState } from 'react';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import * as yup from 'yup';
import { Patient } from 'api/data/patients';
import { DATE_TIME_FORMAT, PHONE_REGEX_WITHOUT_PREFIX, TIME_FORMAT } from 'consts';
import {
	ActionTypes,
	addDistributionPatient,
	deleteDistributionPatient,
	updateDistributionPatient
} from 'store/data/patients';
import { InputType } from 'types/index';
import { Container, HalfRow } from './PatientModal.style';
import { addMinutes } from 'date-fns';
import { MediaQueries } from 'environment';
import { Modal } from 'components/UI/Modal';
import { Gap } from 'components/UI/Gap';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { HSpacer } from 'components/UI/HSpacer';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { InfoMessage } from 'components/UI/InfoMessage';
import { Typography } from 'components/UI/Typography';
import { COUNTRIES } from 'helpers/allCountries';
import { formatAPIDate, roundMinutes } from 'helpers/dateFormat';
import { isOnWindows } from 'helpers/generic';
import { useCompletedAction, useDispatch, useMediaQuery, useMemoOnce } from 'hooks/utils';
import { useTranslation, useActivity, useIsEmailUnique } from 'hooks/store';
import { useAlerts, useReactForm } from 'hooks/ui';

const countryCodes = COUNTRIES.map(country => {
	return {
		value: `+${country.dial}`,
		label: `${isOnWindows() ? '' : country.unicode} ${country.name} (+${country.dial})`
	};
});

const sendPinOptions = ['E-mail', 'SMS'];
const sendPinRadioGroupOptions = sendPinOptions.map(option => ({
	label: option,
	value: option
}));

export interface InitialValuesInterface extends Patient {
	sendPinOption: string;
}

interface Props {
	patient: Patient;
	onClose: () => void;
}

export function PatientModal({ patient, onClose }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [deleteModalVisible, setDeleteModalVisible] = useState(false);

	const [{ loading: addingPatient, error: errorAddingPatient }] = useActivity(
		ActionTypes.ADD_PATIENT_DISTRIBUTION
	);
	const [{ loading: updatingPatient, error: errorUpdatingPatient }] = useActivity(
		ActionTypes.UPDATE_PATIENT_DISTRIBUTION
	);
	const [{ loading: deletingPatient, error: errorDeletingPatient }] = useActivity(
		ActionTypes.DELETE_PATIENT_DISTRIBUTION
	);

	const [
		{ loading: checkingIsEmailUnique, error: errorIsEmailUnique },
		{ handler: isEmailUnique, resetError: resetIsEmailUniqueError }
	] = useIsEmailUnique();

	// AUTO-CLOSE MODAL AFTER SUCCESSFUL PATIENT ADD
	useCompletedAction(addingPatient, errorAddingPatient, () => {
		setNotification({
			message: translate(dict => dict.promsPatientsModal.dataUpdate.patientAdded)
		});
		onClose();
	});

	// AUTO-CLOSE MODAL AFTER SUCCESSFUL PATIENT UPDATE
	useCompletedAction(updatingPatient, errorUpdatingPatient, onClose);

	// AUTO-CLOSE MODAL AFTER SUCCESSFUL PATIENT DELETE
	useCompletedAction(deletingPatient, errorDeletingPatient, () => {
		setNotification({
			message: translate(dict => dict.promsPatientsModal.dataUpdate.patientDeleted)
		});
		onClose();
	});

	// TRIGGER FORM SUBMIT AFTER SUCCESSFULL EMAIL_IS_UNIQUE CHECK
	useCompletedAction(checkingIsEmailUnique, errorIsEmailUnique, () => {
		const values = getValues();
		// ADD / EDIT patient
		handleSubmitPatient({ ...values });
	});

	const isEditMode = patient.patientId > 0;
	const isPastDate = isPast(new Date(formatAPIDate(patient.startTime)));
	const timeNow = roundMinutes(new Date(), true).getHours();

	const validationSchema = yup.object({
		firstName: yup
			.string()
			.required(translate(dict => dict.promsPatientsModal.errors.firstNameError)),
		lastName: yup
			.string()
			.required(translate(dict => dict.promsPatientsModal.errors.lastNameError)),
		emailAddress: yup
			.string()
			.email(translate(dict => dict.promsPatientsModal.errors.emailAddressErrorValid))
			.required(translate(dict => dict.promsPatientsModal.errors.emailAddressError)),
		phoneNumber: yup
			.string()
			.nullable()
			.transform((v, o) => (o === '' ? null : v))
			.min(
				2,
				translate(dict => dict.promsPatientsModal.errors.phoneNumberErrorValid)
			)
			.max(
				15,
				translate(dict => dict.promsPatientsModal.errors.phoneNumberErrorValid)
			)
			.matches(
				PHONE_REGEX_WITHOUT_PREFIX,
				translate(dict => dict.promsPatientsModal.errors.phoneNumberErrorValid)
			)
			.required(translate(dict => dict.promsPatientsModal.errors.phoneNumberError)),
		phoneCountryCode: yup
			.string()
			.required(translate(dict => dict.promsPatientsModal.errors.phoneCountryCodeError)),
		startDate: yup
			.date()
			.nullable()
			.transform((v, o) => (o === '' ? null : v))
			.required(translate(dict => dict.promsPatientsModal.errors.distributionDateError)),
		startTime: yup
			.string()
			.nullable()
			.required(translate(dict => dict.promsPatientsModal.errors.distributionTimeError))
	});

	const initialValues: InitialValuesInterface = useMemoOnce(() => {
		const startDate = isEditMode
			? patient.startTime?.replace(' ', 'T').split('+')[0] + 'Z'
			: new Date().toString();

		return {
			...patient,
			startDate: startDate,
			startTime: isEditMode
				? convertDateAndTimeValues(patient.startTime)
				: timeNow.toString() + ':00',
			sendPinOption: patient.pinBySMS ? sendPinOptions[1] : sendPinOptions[0]
		};
	});

	const {
		Form,
		handleSubmit,
		register,
		Controller,
		control,
		setValue,
		getValues,
		errors,
		isDirtyAndValid
	} = useReactForm({
		initialValues,
		validationSchema
	});

	const onFormSubmit = handleSubmit(() => {
		if (
			isDirtyAndValid &&
			!(addingPatient || updatingPatient || checkingIsEmailUnique || errorIsEmailUnique)
		) {
			const values = getValues();
			handlePreSubmit({ ...values });
		}
	});

	// PRE_SUBMIT - CHECK IF EMAIL IS UNIQUE
	function handlePreSubmit(values: InitialValuesInterface) {
		const initialEmail = patient.emailAddress;
		const emailChanged = initialEmail !== values.emailAddress;

		if (isEditMode) {
			if (emailChanged) {
				// EDIT MODE: email changed -> check is email unique
				isEmailUnique(values.emailAddress);
			} else {
				// EDIT MODE: email did not change -> submit form
				handleSubmitPatient(values);
			}
		} else {
			// ADD MODE: check -> is email unique
			isEmailUnique(values.emailAddress);
		}
	}

	function formatDate(date: number) {
		const dateType = new Date(date);
		const result = addMinutes(dateType, dateType.getTimezoneOffset());
		return format(result, DATE_TIME_FORMAT);
	}

	// FINAL SUBMIT - SEND VALUES TO THE API
	function handleSubmitPatient(values: InitialValuesInterface) {
		const phoneNumber = values.phoneNumber.toString();
		const startTime = formatDate(Date.parse(values.startDate ?? '')) + '+0000';

		// OMIT CUSTOM PIN OPTION KEY
		const { sendPinOption, ...patientValues } = values;

		const newPatient = {
			...patientValues,
			startTime,
			phoneNumber
		};

		delete newPatient.startDate;

		// HANDLE PIN BY SMS
		newPatient.pinBySMS = values.sendPinOption === sendPinOptions[1];

		// UPDATE
		if (isEditMode) {
			dispatch(updateDistributionPatient(newPatient));
		}
		// ADD
		else {
			// omit `patientId`, `patientGivenId`
			const { patientId, patientGivenId, ...newPatientData } = newPatient;
			dispatch(addDistributionPatient(newPatientData));
		}
	}

	function onDeletePatient(patientId: number) {
		dispatch(deleteDistributionPatient(patientId));
	}

	function convertDateAndTimeValues(startTime: string) {
		if (startTime) {
			return format(new Date(formatAPIDate(patient.startTime)), TIME_FORMAT);
		}
		return '';
	}

	function handlePhoneNumber(phoneNumber: string) {
		// allow numbers only
		const value = phoneNumber.replace(/[^0-9]+/g, '');
		setValue('phoneNumber', value, {
			shouldDirty: true,
			shouldValidate: true
		});
	}

	const primaryButtonLoading = addingPatient || updatingPatient || checkingIsEmailUnique;
	const primaryButtonDisabled = errorIsEmailUnique || !isDirtyAndValid;

	const isMaxWidthMedium = useMediaQuery(MediaQueries.maxWidth.md);

	return (
		<>
			<Modal
				title={translate(dict =>
					isEditMode
						? dict.promsPatientsModal.title.update
						: dict.promsPatientsModal.title.add
				)}
				primary={{
					label: translate(dict => (isEditMode ? dict.buttons.update : dict.buttons.add)),
					loading: primaryButtonLoading,
					disabled: primaryButtonDisabled,
					onClick: onFormSubmit
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: onClose
				}}
				secondary={
					isEditMode
						? {
								label: translate(
									dict => dict.promsPatientsModal.dataUpdate.deletePatient
								),
								onClick: () => setDeleteModalVisible(true)
						  }
						: undefined
				}
				onClose={onClose}
				visible
				close
			>
				<Form onSubmit={onFormSubmit}>
					<Container>
						<Gap marginGap={{ bottom: 1.6 }} notLastChild>
							{isEditMode && (
								<Controller
									control={control}
									name="patientId"
									render={({ field: { value } }) => (
										<Input
											type={InputType.Text}
											label={translate(
												dict => dict.promsPatientsModal.labels.patientId
											)}
											value={`#${value}`}
											className="boldText"
											readOnly
										/>
									)}
								/>
							)}
							<Flex column={isMaxWidthMedium}>
								<Input
									{...register('firstName')}
									type={InputType.Text}
									label={translate(
										dict => dict.promsPatientsModal.labels.firstName
									)}
									error={errors.firstName?.message}
									autoFocus={!isEditMode}
								/>
								<HSpacer size={s => s.m} />
								<Input
									{...register('lastName')}
									type={InputType.Text}
									label={translate(
										dict => dict.promsPatientsModal.labels.lastName
									)}
									error={errors.lastName?.message}
								/>
							</Flex>
							<Flex column={isMaxWidthMedium}>
								<HalfRow>
									<Input
										{...register('emailAddress')}
										type={InputType.Email}
										label={translate(
											dict => dict.promsPatientsModal.labels.emailAddress
										)}
										error={
											errorIsEmailUnique
												? translate(dict => dict.terms.uniqueEmail)
												: errors.emailAddress?.message
										}
										onChange={() =>
											errorIsEmailUnique && resetIsEmailUniqueError()
										}
									/>
								</HalfRow>
								<HSpacer size={s => s.m} />
								<Flex>
									<Controller
										control={control}
										name="phoneCountryCode"
										render={({ field: { name, value, onBlur } }) => {
											const valueSelected = countryCodes.find(
												item => item.value === value
											);
											const valueDisplayed = valueSelected
												? {
														label: valueSelected.value,
														value: valueSelected.label
												  }
												: undefined;
											return (
												<CreatableSelect
													className="country-code"
													value={valueDisplayed}
													label={translate(
														dict =>
															dict.promsPatientsModal.labels
																.countryCode
													)}
													canClear={false}
													placeholder={''}
													items={countryCodes}
													error={errors.phoneCountryCode?.message}
													onValueSelected={value => {
														const item = countryCodes.find(
															item => item.value === value
														);
														setValue(name, item ? item.value : '', {
															shouldDirty: true,
															shouldValidate: true
														});
													}}
													onBlur={onBlur}
													width={10}
													dropdownWidth={20}
												/>
											);
										}}
									/>
									<HSpacer size={s => s.xs} />
									<Input
										{...register('phoneNumber')}
										type={InputType.Text}
										label={translate(
											dict => dict.promsPatientsModal.labels.phoneNumber
										)}
										error={errors.phoneNumber?.message}
										onChange={e => handlePhoneNumber(e.target.value)}
									/>
								</Flex>
							</Flex>
							<Flex column={isMaxWidthMedium}>
								<HalfRow>
									<Controller
										control={control}
										name="startDate"
										defaultValue={''}
										render={({ field: { name, value, onBlur } }) => (
											<DateTimeInput
												value={value}
												onChange={value =>
													setValue(name, value, {
														shouldDirty: true,
														shouldValidate: true
													})
												}
												options={{
													compact: true,
													label: translate(
														dict =>
															dict.promsPatientsModal.labels
																.distributionStartDate
													),
													error: errors.startDate?.message,
													readOnly: isEditMode && isPastDate,
													onBlur
												}}
											/>
										)}
									/>
								</HalfRow>
								{!isMaxWidthMedium && <div style={{ width: '36rem' }} />}
							</Flex>
							<Controller
								control={control}
								name="sendPinOption"
								render={({ field: { name, value, onBlur } }) => (
									<RadioGroupUncontrolled
										name="sendPinOption"
										label={translate(
											dict => dict.projectsPage.patient.pinDelivery
										)}
										options={sendPinRadioGroupOptions}
										// CONTROLLED PROPS
										value={value}
										onBlur={onBlur}
										onChange={newValue =>
											setValue(name, newValue, {
												shouldDirty: true
											})
										}
									/>
								)}
							/>
						</Gap>
						<InfoMessage
							message={
								<>
									<b>
										{translate(dict => dict.promsPatientsModal.notice.note)}:{' '}
									</b>
									{translate(dict => dict.promsPatientsModal.notice.message)}
								</>
							}
							marginOffset={{ top: 2.4 }}
						/>
					</Container>
				</Form>
			</Modal>

			{/* DELETE PATIENT MODAL */}
			<Modal
				size={s => s.s}
				visible={deleteModalVisible}
				title={translate(dict => dict.promptToDelete.title)}
				primary={{
					label: translate(dict => dict.buttons.delete),
					loading: deletingPatient,
					warning: true,
					onClick: () => onDeletePatient(patient.patientId)
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: () => setDeleteModalVisible(false)
				}}
				onClose={() => setDeleteModalVisible(false)}
				enterAsPrimaryOnClick
				close
			>
				<Typography.Paragraph>
					{translate(dict => dict.promptToDelete.description.patient)}
					<b> {patient?.emailAddress}</b>?
				</Typography.Paragraph>
			</Modal>
		</>
	);
}

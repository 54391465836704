import { useMemoOnce, useSelector } from 'hooks/utils';
import { selectVariablesData } from 'store/data/variables';

export function useVariablesData(options?: { initial?: boolean }) {
	const { initial = false } = options ?? {};

	/**
	 * Used like this to increase the cache size from 1 to n
	 */
	const _selectVariablesData = useMemoOnce(selectVariablesData);

	return useSelector(state => _selectVariablesData(state.data.variables, { initial }));
}

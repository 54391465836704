import { AnalysisError } from 'api/data/analyses';

import { Dictionary } from 'environment';
import { useTranslation } from 'hooks/store';
import { keys } from 'lodash';
import { useMemo } from 'react';

/**
 *
 * @param error API error
 * @returns translated message
 */
export function useAnalysisErrorMessage(error?: AnalysisError) {
	const { translate } = useTranslation();

	const translatedMessage = useMemo<string>(() => {
		if (!error) return '';

		const { values, code, message } = error;
		const translateKey = code.split('.')[1];

		// KNOWN TRANSLATED STRING
		if (keys(Dictionary.analysis.analyses.error).includes(translateKey)) {
			return (
				translate(
					//@ts-ignore
					dict => dict.analysis.analyses.error[translateKey],
					false,
					values
				) ?? message
			);
		}
	}, [error]);

	return translatedMessage;
}

import styled, { css } from 'styled-components';
import { Colors, MediaQueries } from 'environment';
import { Flex } from '../Flex';

interface TabsItemWithIconContainerProps {
	active?: boolean;
}

interface ContainerProps {
	fullHeight?: boolean;
}
export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

interface ItemProps {
	maxWidth?: number;
	hasIcon?: boolean;
}
export const Items = styled.div<ItemProps>`
	display: flex;
	width: 100%;
	user-select: none;

	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}rem;
		`}

	&& .tabs__navigation__item {
		padding: 0.9rem 0;

		::after {
			width: 11.2rem;
		}
	}
	@media ${MediaQueries.maxWidth.lg} {
		justify-content: ${props => (props.hasIcon ? 'space-around' : 'unset')};
	}
`;

export const TabsItemWithIconContainer = styled(Flex)<TabsItemWithIconContainerProps>`
	background: ${props => (props.active ? `${Colors.primary.disabled}` : 'none')};
	border-radius: 1rem;
	padding: 0.8rem 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	cursor: pointer;
`;

import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { moveVariable, MoveVariableAction, ActionTypes } from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveVariable(): Operation<(payload: ActionPayload<MoveVariableAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_VARIABLE);

	function handler(payload: ActionPayload<MoveVariableAction>) {
		dispatch(moveVariable(payload));
	}

	return [{ loading, error }, handler];
}

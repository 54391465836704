import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setTemplateId, SetTemplateIdAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useTemplateId(): LocalOperationResult<
	string | null,
	(templateId: ActionPayload<SetTemplateIdAction>['templateId']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.templates.templateId);

	function handler(templateId: ActionPayload<SetTemplateIdAction>['templateId']) {
		dispatch(setTemplateId({ templateId }));
	}

	return [data, handler];
}

import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;

	> :nth-child(n) {
		width: calc(50% - 1.1rem);
	}

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
		justify-content: unset;

		> :nth-child(n) {
			width: 100%;
		}
	}
`;

export const CardWrapper = styled.div`
	border: 0.12rem solid ${Colors.avatar.paleGreyLight};
	border-radius: 0.4rem;
	padding: 1.6rem;
	margin-bottom: 1.6rem;
`;

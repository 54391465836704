import styled from 'styled-components';

import { InputError as DefaultInputError } from 'components/UI/Inputs/InputError';

export const Container = styled.div`
	label {
		font-size: 1.2rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
	}
`;

export const Row = styled.div`
	display: flex;

	> :first-child {
		margin-right: 2rem;
	}
`;

export const InputError = styled(DefaultInputError)`
	height: 1.6rem;
	min-height: 1.6rem;
`;

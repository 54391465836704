import { nanoid as generate } from 'nanoid';

import { Thunk, ActionPayload } from 'store/types';
import { setError } from 'store/ui/activities';
import { LanguageType, StorageKeys } from 'types/index';

import { ActionTypes, GetLanguageAction, SetLanguageAction } from './types';

const getLanguageAction = (payload: ActionPayload<GetLanguageAction>): GetLanguageAction => ({
	type: ActionTypes.GET_LANGUAGE,
	payload
});

export const getLanguage =
	(language: LanguageType): Thunk =>
	async dispatch => {
		try {
			const code = language.toLowerCase();

			const cachedTranslations = localStorage.getItem('translations');
			if (cachedTranslations) {
				dispatch(getLanguageAction({ translations: JSON.parse(cachedTranslations) }));
			}

			const translations = await fetch(
				`/locales/${code}/translation.json`,
				{ cache: 'no-cache' } // No-cache ensures that the browser will send a conditional request with If-None-Match
			)
				.then(res => res.json())
				.then(res => res);

			dispatch(getLanguageAction({ translations }));
			localStorage.setItem(StorageKeys.Translations, JSON.stringify(translations));
		} catch (e: any) {
			await dispatch(
				setError({
					type: ActionTypes.GET_LANGUAGE,
					error: e.message,
					uuid: generate()
				})
			);
		}
	};

export const setLanguage = (payload: ActionPayload<SetLanguageAction>): SetLanguageAction => ({
	type: ActionTypes.SET_LANGUAGE,
	payload
});

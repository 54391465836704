import { connect } from 'react-redux';

import { ApplicationState } from 'store';
import {
	ActionTypes,
	setOneTimePassword,
	selectUsername,
	verifyOneTimePassword,
	clearOneTimePassword,
	resetMultiFactorAuth,
	selectOneTimePasswordData
} from 'store/auth';
import { selectActivities } from 'store/ui/activities';

import { Security } from './Security';

interface OwnProps {
	close: () => void;
}

interface DataProps {
	otp: {
		enabled: boolean;
		secret: string | null;
	};
	username: string | null;
	updating: boolean;
}

interface ActionProps {
	setOneTimePassword: () => void;
	verifyOneTimePassword: (code: string) => void;
	resetMultiFactorAuth: () => void;
	clearOneTimePassword: () => void;
}

export type Props = OwnProps & DataProps & ActionProps;

const mapStateToProps = (state: ApplicationState) => ({
	otp: selectOneTimePasswordData(state.auth),
	username: selectUsername(state.auth),
	updating: selectActivities(state.ui.activities, {
		types: [
			ActionTypes.SET_ONE_TIME_PASSWORD,
			ActionTypes.VERIFY_ONE_TIME_PASSWORD,
			ActionTypes.RESET_MULTI_FACTOR_AUTH
		]
	})
});

export default connect(mapStateToProps, {
	setOneTimePassword,
	verifyOneTimePassword,
	clearOneTimePassword,
	resetMultiFactorAuth
})(Security);

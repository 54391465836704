import { sendRequest, USER_ACCOUNT_URL, USER_URL } from 'api/utils';
import { Dictionary } from 'environment';

import { NetworkRequestBase } from 'api/types';
import {
	AdminApiAccount,
	AddUserResponse,
	AddUserRequest,
	UpdateUserRequest,
	GetListOfAllUsersResponse,
	ResetPasswordRequest,
	RemoveUserRequest,
	ResetMFARequest,
	GetUserResponse,
	GetUserRequest,
	GetEnterpriseOrganizationsResponse,
	Domains,
	GetDomainPayload,
	GetDomainRequest,
	DomainsPayload,
	DomainsPayloadRequest,
	GetOrganizationDomainsResponse,
	AddRemoveDomainsResponse
} from './types';
import { EnterpriseOrganization } from 'store/account/admin';

export const methods = {
	getUsers: 'getListOfAllUsers',
	getUser: 'adminGetUser',
	addUser: 'adminAddUser',
	updateUser: 'adminUpdateUser',
	removeUser: 'adminRemoveUser',
	resetPassword: 'adminResetPassword',
	resetUserMFA: 'adminResetMFA',
	getEnterpriseOrganizations: 'getEnterpriseOrganizations',
	getDomainsForOrganization: 'getDomainsForOrganization',
	addWhitelistedDomains: 'addWhitelistedDomains',
	removeOrganizationDomains: 'removeDomainsOfOrganization'
};

export default () => ({
	getUsers: async (): Promise<AdminApiAccount[]> => {
		try {
			const {
				data: { users }
			}: GetListOfAllUsersResponse = await sendRequest<NetworkRequestBase>(USER_URL, {
				method: methods.getUsers
			});

			return users;
		} catch (e) {
			throw new Error(Dictionary.admin.users.errors.accessForbidden);
		}
	},
	getUser: async (userId: string): Promise<AdminApiAccount> => {
		try {
			const {
				data: { user }
			}: GetUserResponse = await sendRequest<GetUserRequest>(USER_URL, {
				method: methods.getUser,
				userId
			});

			return user;
		} catch (e) {
			throw new Error(Dictionary.admin.users.errors.accessForbidden);
		}
	},
	addUser: async (user: AdminApiAccount): Promise<string> => {
		const filteredUser = { ...user };

		// @ts-ignore
		delete filteredUser.userid;
		// @ts-ignore
		delete filteredUser.cognitoCreatedDate;
		// @ts-ignore
		delete filteredUser.cognitoUserStatus;

		const {
			data: {
				user: { userid }
			}
		}: AddUserResponse = await sendRequest<AddUserRequest>(USER_URL, {
			method: methods.addUser,
			user: filteredUser
		});

		return userid;
	},
	updateUser: async (user: AdminApiAccount): Promise<void> => {
		const filteredUser = { ...user };

		// @ts-ignore
		delete filteredUser.cognitoCreatedDate;
		// @ts-ignore
		delete filteredUser.cognitoUserStatus;

		await sendRequest<UpdateUserRequest>(USER_URL, {
			method: methods.updateUser,
			user: filteredUser
		});
	},
	removeUser: async (userId: string): Promise<void> => {
		await sendRequest<RemoveUserRequest>(USER_URL, {
			method: methods.removeUser,
			user: {
				userid: userId
			}
		});
	},
	resetPassword: async (userId: string): Promise<void> => {
		try {
			await sendRequest<ResetPasswordRequest>(USER_URL, {
				method: methods.resetPassword,
				user: {
					userid: userId
				}
			});
		} catch (e) {
			throw new Error(Dictionary.admin.users.errors.resetPassword);
		}
	},
	resetUserMFA: async (userId: string): Promise<void> => {
		try {
			await sendRequest<ResetMFARequest>(USER_URL, {
				method: methods.resetUserMFA,
				user: {
					userid: userId
				}
			});
		} catch (e) {
			throw new Error(Dictionary.admin.users.errors.resetUserMFA);
		}
	},
	getEnterpriseOrganizations: async (): Promise<EnterpriseOrganization[]> => {
		try {
			const {
				data: { enterpriseOrganizations }
			}: GetEnterpriseOrganizationsResponse = await sendRequest<NetworkRequestBase>(
				USER_ACCOUNT_URL,
				{
					method: methods.getEnterpriseOrganizations
				}
			);

			return enterpriseOrganizations;
		} catch (e) {
			throw new Error(Dictionary.admin.enterprise.errors.api.couldntGetOrganizations);
		}
	},

	getDomainsForOrganization: async (payload: GetDomainPayload): Promise<Domains> => {
		try {
			const {
				data: { blacklistedDomains, whitelistedDomains }
			}: GetOrganizationDomainsResponse = await sendRequest<GetDomainRequest>(
				USER_ACCOUNT_URL,
				{
					method: methods.getDomainsForOrganization,
					...payload
				}
			);

			return { blacklistedDomains, whitelistedDomains };
		} catch (e) {
			throw new Error(
				Dictionary.admin.enterprise.errors.api.couldntGetDomainsForOrganization
			);
		}
	},

	addWhitelistedDomains: async (payload: DomainsPayload): Promise<boolean> => {
		try {
			const {
				data: { statusCode }
			}: AddRemoveDomainsResponse = await sendRequest<DomainsPayloadRequest>(
				USER_ACCOUNT_URL,
				{
					method: methods.addWhitelistedDomains,
					...payload
				}
			);

			return statusCode === '200';
		} catch (e) {
			throw new Error(Dictionary.admin.enterprise.errors.api.couldntAddDomains);
		}
	},

	removeOrganizationDomains: async (payload: DomainsPayload): Promise<boolean> => {
		try {
			const {
				data: { statusCode }
			}: AddRemoveDomainsResponse = await sendRequest<DomainsPayloadRequest>(
				USER_ACCOUNT_URL,
				{
					method: methods.removeOrganizationDomains,
					...payload
				}
			);

			return statusCode === '200';
		} catch (e) {
			throw new Error(Dictionary.admin.enterprise.errors.api.couldntRemoveDomains);
		}
	}
});

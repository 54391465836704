import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { setFormElementDisplayType, SetFormElementDisplayTypeAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormElementDisplayType(): LocalOperation<
	(payload: ActionPayload<SetFormElementDisplayTypeAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormElementDisplayTypeAction>) {
		dispatch(setFormElementDisplayType(payload));
	}

	return handler;
}

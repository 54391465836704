import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';

import { H1, H2, H3, H4, H5, H6, Link, ExternalLink, P, List, Img } from './contentful.styles';

export const contentfulOptions = {
	renderNode: {
		[INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode) => {
			if (node.data.uri.includes('.')) {
				return (
					<ExternalLink href={node.data.uri} rel="noopener noreferrer" target="_blank">
						{children}
					</ExternalLink>
				);
			} else {
				return <Link to={node.data.uri}>{children}</Link>;
			}
		},
		[BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: React.ReactNode) => {
			return <P>{children}</P>;
		},
		[BLOCKS.OL_LIST]: (_node: Block | Inline, children: React.ReactNode) => {
			return <List kind="ol">{children}</List>;
		},
		[BLOCKS.UL_LIST]: (_node: Block | Inline, children: React.ReactNode) => {
			return (
				<List as="ul" kind="ul">
					{children}
				</List>
			);
		},
		[BLOCKS.HEADING_1]: (_node: Block | Inline, children: React.ReactNode) => {
			return <H1>{children}</H1>;
		},
		[BLOCKS.HEADING_2]: (_node: Block | Inline, children: React.ReactNode) => {
			return <H2>{children}</H2>;
		},
		[BLOCKS.HEADING_3]: (_node: Block | Inline, children: React.ReactNode) => {
			return <H3>{children}</H3>;
		},
		[BLOCKS.HEADING_4]: (_node: Block | Inline, children: React.ReactNode) => {
			return <H4>{children}</H4>;
		},
		[BLOCKS.HEADING_5]: (_node: Block | Inline, children: React.ReactNode) => {
			return <H5>{children}</H5>;
		},
		[BLOCKS.HEADING_6]: (_node: Block | Inline, children: React.ReactNode) => {
			return <H6>{children}</H6>;
		},
		[BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
			const {
				title,
				file: {
					url
					// fileName,
					// contentType,
					// details: {
					// 	size,
					// 	image: { width, height }
					// }
				}
			} = node.data.target.fields;

			return <Img alt={title} src={url} />;
		}
	}
	// renderMark: {
	// 	[MARKS.BOLD]: (text: any) => {
	// 		return <span key={`${text}-key`}>{text}</span>;
	// 	}
	// }
};

import { Icon } from 'components/UI/Icons';
import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const AliasTableContainer = styled.div`
	padding: 16px;
	border-radius: 8px;
	border: solid 1px #dae0e6;

	width: 100%;
	max-height: 49rem;
`;

export const AliasTableHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr 48px 1fr 48px;

	grid-template-areas: 'variable . alias .';
`;
export const AliasTableFooter = styled.div`
	display: flex;

	justify-content: flex-end;
`;

export const DeleteAliasIcon = styled(Icon)`
	grid-area: delete-icon;
	width: 100%;
	height: 3.2rem;
	visibility: hidden;
`;

export const AliasTableRow = styled.div`
	display: grid;
	align-items: start;

	grid-template-columns: 1fr 48px 1fr 48px;
	grid-template-areas: 'variable  arrow-icon alias delete-icon';

	margin-bottom: 0.8rem;

	.alias_input {
		grid-area: alias;
	}

	input {
		height: 3.2rem;
	}

	&:hover ${DeleteAliasIcon} {
		visibility: visible;
	}
`;

export const AliasTableContent = styled.div`
	max-height: 232px;
	overflow: overlay;
`;

type AliasTableHeaderColumnProps = {
	area: string;
};

export const AliasTableHeaderColumn = styled.div<AliasTableHeaderColumnProps>`
	${getTypographyCSS(t => t.Paragraph)}

	font-weight: 600;
	font-size: 12px;

	grid-area: ${props => props.area};
`;

type AliasTableFooterButtonProps = {
	paddingTop?: boolean;
};

export const AliasTableFooterButton = styled.div<AliasTableFooterButtonProps>`
	${getTypographyCSS(t => t.Paragraph)}

	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	text-align: center;

	color: ${Colors.primary.normal};

	cursor: pointer;

	padding: ${({ paddingTop }) => (paddingTop ? '1.6rem 4.8rem 0 0' : '0 4.8rem 0 0')};
`;

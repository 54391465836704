import { createSelector } from 'reselect';
import { State } from './types';

function getUserName(state: State) {
	return state.username;
}

function getOneTimePasswordData(state: State) {
	return state.oneTimePassword;
}

export const selectUsername = createSelector([getUserName], username => username);

export const selectOneTimePasswordData = createSelector([getOneTimePasswordData], otp => otp);

import * as yup from 'yup';
import { useCallback } from 'react';
import { AccountDetails } from 'store/account/subscription';
import { InputType } from 'types/index';
import { Container, CountrySelector } from './WorkInformation.style';
import { Input } from 'components/UI/Inputs/Input';
import { Button } from 'components/UI/Interactables/Button';
import { RequiredFields } from 'components/UI/RequiredFields';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useUpdateAccount } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface Props {
	details: AccountDetails;
}
export function WorkInformation({ details }: Props) {
	const { translate } = useTranslation();

	const { position, department, workplace, city, country } = details;

	const [{ error: updateError, loading: updating }, updateAccount] = useUpdateAccount();

	const validationSchema = yup.object({
		department: yup
			.string()
			.trim()
			.required(translate(({ accountUM }) => accountUM.validation.departmentError))
	});

	const {
		Form,
		setValue,
		register,
		Controller,
		handleSubmit,
		handleTrimOnBlur,
		errors,
		isDirty,
		control,
		isDirtyAndValid: canSubmitForm
	} = useReactForm({
		initialValues: { position, department, workplace, city, country },
		validationSchema,
		enableReinitialize: true
	});

	const handleFormSubmit = handleSubmit(data => {
		if (isDirty && !updating && !updateError)
			updateAccount(
				data,
				translate(dict => dict.workInformation.updatedNotification)
			);
	});

	const onSelect = useCallback(
		country => {
			setValue('country', country?.ISO_2 ?? '', {
				shouldDirty: true,
				shouldValidate: true
			});
		},
		[country]
	);

	return (
		<Container>
			<Form onSubmit={handleFormSubmit}>
				<Input
					{...register('position', {
						onBlur: handleTrimOnBlur
					})}
					type={InputType.Text}
					label={translate(dict => dict.accountUM.userDetails.position)}
					placeholder={translate(dict => dict.accountUM.userDetails.position)}
				/>
				<Spacer size={s => s.s} />
				<Input
					{...register('department', {
						onBlur: handleTrimOnBlur
					})}
					type={InputType.Text}
					error={errors.department?.message}
					label={translate(dict => dict.accountUM.userDetails.department)}
					placeholder={translate(dict => dict.accountUM.userDetails.department)}
					required
				/>
				<Spacer size={s => s.s} />
				<Input
					{...register('workplace')}
					type={InputType.Text}
					label={translate(dict => dict.accountUM.userDetails.workplace)}
					placeholder={translate(dict => dict.accountUM.userDetails.workplace)}
					readOnly
				/>
				<Spacer size={s => s.s} />
				<Input
					{...register('city', {
						onBlur: handleTrimOnBlur
					})}
					type={InputType.Text}
					label={translate(dict => dict.accountUM.userDetails.city)}
					placeholder={translate(dict => dict.accountUM.userDetails.city)}
				/>
				<Spacer size={s => s.s} />
				<Controller
					name="country"
					control={control}
					render={({ field: { value } }) => (
						<CountrySelector
							value={value}
							label={translate(dict => dict.accountUM.userDetails.country)}
							placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
							onSelect={onSelect}
						/>
					)}
				/>
				<Spacer size={s => s.m} />
				<RequiredFields />
				<Spacer size={s => s.m} />
				<Button
					type="submit"
					title={translate(dict => dict.workInformation.saveButton)}
					loading={updating}
					disabled={!canSubmitForm}
					hasFullWidth
					hasPointer
				/>
			</Form>
		</Container>
	);
}

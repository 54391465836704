import { DraggableStateSnapshot, DraggingStyle } from 'react-beautiful-dnd';
import { nanoid as generate } from 'nanoid';

import { ApiTemplate } from 'api/data/templates';
import { ApiVariable, Group, Variable, VariableUniquenessType } from 'api/data/variables';
import {
	prepareApiVariable,
	parseApiVariable,
	variablesDataArrayIterator
} from 'helpers/variables';
import {
	GroupData,
	GroupsMap,
	VariableSetsMap,
	VariablesData,
	VariablesMap,
	VariablesOrder
} from 'store/data/variables';
import {
	DraggableVariable,
	Template,
	TemplateGroupInterface,
	TemplatesById
} from 'store/data/templates';
import { DragAndDropTypes } from 'types/index';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';

interface GetInitialDraggableItemsDataProps {
	ungroupedVariables: Variable[];
	variablesMap: VariablesMap;
	groups: Group[];
	timeDurationFlag: boolean;
}

export function getInitialDraggableItemsData({
	ungroupedVariables,
	variablesMap,
	groups,
	timeDurationFlag
}: GetInitialDraggableItemsDataProps) {
	const availableVariables: DraggableVariable[] = [];
	const availableGroups: TemplateGroupInterface[] = [];

	const elementsOrder: string[] = [];

	// GET AVAILABLE VARIABLES
	ungroupedVariables.forEach(variable => {
		const { name, uniquenessType, type } = variable;

		// Fileter out Unique variables that don't have UniquenessType === 'Manual
		if (
			uniquenessType === VariableUniquenessType.Sequence ||
			uniquenessType === VariableUniquenessType.UUID
		) {
			return;
		}

		if (!timeDurationFlag && type === VariableType.TimeDuration) {
			return;
		}

		const draggableId = `${name}${DragAndDropTypes.DraggableTemplateVariable}${generate()}`;

		elementsOrder.push(draggableId);

		const draggableVariable: DraggableVariable = {
			...variable,
			draggableId
		};

		availableVariables.push(draggableVariable);
	});

	groups.forEach(group => {
		const elementsOrder: string[] = [];

		const { groupName, groupLabel, variablesBelongingToGroup } = group;

		const groupVariables: DraggableVariable[] = [];

		// Compute data	 for grouped variables
		variablesBelongingToGroup.forEach(groupedVariableName => {
			const variable = variablesMap[groupedVariableName];

			const { name, uniquenessType } = variable;

			// Fileter out Unique variables that don't have UniquenessType === 'Manual
			if (
				uniquenessType === VariableUniquenessType.Sequence ||
				uniquenessType === VariableUniquenessType.UUID
			)
				return;

			const draggableId = `${name}${DragAndDropTypes.DraggableTemplateVariable}${generate()}`;

			const draggableVariable: DraggableVariable = {
				...variable,
				draggableId
			};

			elementsOrder.push(draggableId);
			groupVariables.push(draggableVariable);
		});

		const draggableId = `${groupName}${DragAndDropTypes.DraggableTemplateGroup}${generate()}`;

		const computedGroup = {
			draggableId,
			groupName,
			groupLabel: groupLabel ?? groupName,
			variables: groupVariables,
			elementsOrder
		};

		elementsOrder.push(draggableId);
		availableGroups.push(computedGroup);
	});

	return { availableVariables, availableGroups };
}

export function computeApiTemplates(
	apiTemplates: ApiTemplate[],
	publicTemplates?: boolean
): TemplatesById {
	const templates: TemplatesById = {};

	apiTemplates.forEach(apiTemplate => {
		let elementsOrder: string[] = [];

		const {
			templateId,
			isSingleVariableTemplate,
			createdByUser,
			templateName,
			accessWrite,
			variablesJson,
			groupsJson,
			templateDescription
		} = apiTemplate;

		if (templateId) {
			let computedVariables: DraggableVariable[] = variablesJson.map(apiVariable => {
				const variable = parseApiVariable(apiVariable);

				const draggableId = `${variable.name}${
					DragAndDropTypes.DraggableTemplateVariable
				}${generate()}`;

				const computedVariable: DraggableVariable = {
					...variable,
					draggableId
				};

				elementsOrder.push(draggableId);

				return computedVariable;
			});

			const groups: TemplateGroupInterface[] = [];

			groupsJson?.forEach(apiGroup => {
				const { groupName, groupLabel, variablesBelongingToGroup } = apiGroup;

				const groupVariables: DraggableVariable[] = [];
				const groupElementsOrder: string[] = [];

				variablesBelongingToGroup.forEach(variableName => {
					const variableToAdd = computedVariables.find(
						variable => variable.name === variableName
					);

					if (variableToAdd) {
						elementsOrder = elementsOrder.filter(
							elementId =>
								elementId.split(DragAndDropTypes.DraggableTemplateVariable)[0] !==
								variableToAdd.name
						);

						groupElementsOrder.push(variableToAdd.draggableId);

						const groupedDraggableId = `${variableToAdd.name}${
							DragAndDropTypes.DraggableTemplateVariable
						}${generate()}-groupedVariable`;

						const groupedVariable = {
							...variableToAdd,
							draggableId: groupedDraggableId
						};

						groupVariables.push(groupedVariable);
						computedVariables = computedVariables.filter(
							variable => variable.name !== variableName
						);
					}
				});

				const draggableId = `${groupName}${
					DragAndDropTypes.DraggableTemplateGroup
				}${generate()}`;

				const group: TemplateGroupInterface = {
					draggableId,
					groupName,
					groupLabel,
					variables: groupVariables,
					elementsOrder: groupElementsOrder
				};

				groups.push(group);
				elementsOrder.push(draggableId);
			});

			const template: Template = {
				templateId,
				templateName,
				accessPublicRead: !!publicTemplates,
				elementsOrder,
				owner: createdByUser ?? '',
				isSingleVariableTemplate,
				description: templateDescription ?? '',
				variables: computedVariables,
				totalVariableNumber: variablesJson.length,
				groups,
				accessWrite,
				projectsSharedWith: { initial: [], current: [] },
				usersSharedWith: { initial: [], current: [] }
			};

			templates[templateId] = template;
		}
	});

	return templates;
}

export function prepareTemplateForApi(
	template: Template,
	username: string,
	allowTimeDuration?: boolean,
	shareTemplateGlobally?: boolean
) {
	const {
		templateName,
		templateId,
		description,
		variables,
		groups,
		accessPublicRead,
		elementsOrder,
		accessWrite
	} = template;
	const variablesJson: ApiVariable[] = [];
	const groupsJson: Group[] = [];

	// Reorder by elementsOrder array
	elementsOrder.forEach(elementOrder => {
		// VARIABLE
		if (elementOrder.includes(DragAndDropTypes.DraggableTemplateVariable)) {
			const draggedVariableName = elementOrder.split(
				DragAndDropTypes.DraggableTemplateVariable
			)[0];

			const variable = variables.find(variable => variable.name === draggedVariableName);
			if (variable?.type === VariableType.TimeDuration && !allowTimeDuration) return;

			if (variable) {
				const apiVariable = prepareApiVariable(variable);

				variablesJson.push(apiVariable);
			}
		}
		// GROUP
		else {
			const draggedGroupName = elementOrder.split(DragAndDropTypes.DraggableTemplateGroup)[0];

			const group = groups.find(group => group.groupName === draggedGroupName);

			if (group) {
				const { groupName, groupLabel, variables: groupVariables } = group;

				const variablesBelongingToGroup: string[] = [];

				groupVariables.forEach(draggableVariable => {
					const { draggableId, ...rest } = draggableVariable;

					const variable: Variable = { ...rest };

					const apiVariable = prepareApiVariable(variable);

					variablesJson.push(apiVariable);
					variablesBelongingToGroup.push(apiVariable.variableName);
				});

				const computedGroup: Group = {
					groupName,
					groupLabel,
					variablesBelongingToGroup
				};

				groupsJson.push(computedGroup);
			}
		}
	});

	const calculatedVariableNames = variablesJson
		.filter(v => v.entryType === EntryVariableType.Calculated)
		.map(v => v.variableName);

	const isSingleVariableTemplate = variablesJson.length === 1;

	const avoidUnnecessaryApiCall =
		calculatedVariableNames.length === 1 && isSingleVariableTemplate;

	const apiTemplate: ApiTemplate = {
		accessPublicRead: shareTemplateGlobally ?? accessPublicRead,
		accessWrite,
		templateId,
		isSingleVariableTemplate,
		templateDescription: description,
		variablesJson,
		groupsJson,
		createdByUser: username,
		templateName
	};

	return { apiTemplate, avoidUnnecessaryApiCall, calculatedVariableNames };
}

export function getCloneStyle(
	style: DraggingStyle,
	{ isDragging, isDropAnimating }: DraggableStateSnapshot
) {
	if (!isDragging) return {};
	if (!isDropAnimating) return style;

	return {
		...style,
		// Set duration for animation
		transitionDuration: `0.1s`
	};
}

export function getTemplateGroups(groups: TemplateGroupInterface[]): Group[] {
	return groups.map(
		gr =>
			({
				groupName: gr.groupName,
				groupLabel: gr.groupLabel,
				variablesBelongingToGroup: gr.variables.map(variable => variable.name)
			} as Group)
	);
}

export function getTemplateGroupData(
	initialGroups: TemplateGroupInterface[],
	allowTimeDuration: boolean
): GroupData[] {
	const groups = initialGroups.map(
		gr =>
			({
				groupName: gr.groupName,
				groupLabel: gr.groupLabel,
				groupVariables: gr.variables
			} as GroupData)
	);
	if (!allowTimeDuration) {
		groups.forEach(group => {
			group.groupVariables = group.groupVariables.filter(
				variable => variable.type !== VariableType.TimeDuration
			);
		});
	}
	return groups;
}

export function getTemplateVariables(
	initialVariables: DraggableVariable[],
	allowTimeDuration: boolean
): Variable[] {
	let variables = initialVariables.map(variable => {
		const { draggableId, ...res } = variable;
		return res;
	});
	if (!allowTimeDuration) {
		variables = variables.filter(variable => variable.type !== VariableType.TimeDuration);
	}
	return variables;
}

export function getTemplateGroupVariables(groups: TemplateGroupInterface[]): Variable[] {
	const res: Variable[] = [];
	groups.forEach(gr => {
		const variables = gr.variables;
		// if (templateVariables) {
		// 	const mainVariablesMap = templateVariables.reduce<Record<string, DraggableVariable>>(
		// 		(acc, variable) => {
		// 			acc[variable.name] = variable;
		// 			return acc;
		// 		},
		// 		{}
		// 	);
		// 	variables = variables.filter(
		// 		({ name: variableName }) => !!mainVariablesMap[variableName]
		// 	);
		// }
		res.push(...variables);
	});

	return res;
}

export function buildVariablesDataFromTemplate({
	templateVariables,
	templateGroups,
	allowTimeDuration
}: {
	templateVariables: DraggableVariable[];
	templateGroups: TemplateGroupInterface[];
	allowTimeDuration: boolean;
}): VariablesData {
	const variables = getTemplateVariables(templateVariables, allowTimeDuration);

	const groupVariables = getTemplateGroupVariables(templateGroups);
	const groups = getTemplateGroups(templateGroups);
	const groupsData = getTemplateGroupData(templateGroups, allowTimeDuration);

	// MAPS
	const variablesMap: VariablesMap = [...variables, ...groupVariables].reduce((acc, curr) => {
		acc[curr.name] = curr;
		return acc;
	}, {} as Record<string, Variable>);

	const groupsMap: GroupsMap = groups.reduce((acc, curr) => {
		// FILTER VARIABLE GROUPS HERE
		acc[curr.groupName] = curr;

		return acc;
	}, {} as Record<string, Group>);

	const variableSetsMap: VariableSetsMap = {};

	const order: VariablesOrder = [];

	// build order;
	variablesDataArrayIterator(
		[...variables, ...groupVariables, ...groupsData],
		variable => {
			order.push({ variable: variable.name });
		},
		groups => {
			order.push({ group: groups.groupName });
		},
		() => null
	);

	return {
		variablesMap,
		groupsMap,
		variableSetsMap,
		order
	};
}

export function getFilteredGroupsAndVariablesArray(
	filteredVariablesDataArray: (Variable | GroupData)[]
) {
	const filteredGroups: GroupData[] = [];
	const filteredVariables: Variable[] = [];

	function isVariable(variableOrGroup: Variable | GroupData): variableOrGroup is Variable {
		return 'name' in variableOrGroup;
	}

	filteredVariablesDataArray.forEach(groupOrVariable => {
		if (!isVariable(groupOrVariable)) {
			filteredGroups.push(groupOrVariable);
		} else {
			filteredVariables.push(groupOrVariable);
		}
	});

	return { filteredGroups, filteredVariables };
}

export function getFilteredGroupsAndVariablesMap(
	filteredVariables: Variable[],
	filteredGroups: GroupData[]
) {
	const groupVariables = filteredGroups.map(group => group.groupVariables).flat();

	const groupsMap = filteredGroups.reduce<GroupsMap>((groupsMap, group) => {
		groupsMap[group.groupName] = {
			groupLabel: group.groupLabel,
			groupName: group.groupLabel,
			variablesBelongingToGroup: group.groupVariables.map(groupVariable => groupVariable.name)
		};
		return groupsMap;
	}, {});

	const variablesMap = [...filteredVariables, ...groupVariables].reduce<VariablesMap>(
		(variablesMap, variable) => {
			variablesMap[variable.name] = variable;
			return variablesMap;
		},
		{}
	);
	return { variablesMap, groupsMap };
}

// build temporary template based on variables and groups
export function buildTemporaryTemplate({
	variables,
	groups,
	owner
}: {
	variables: DraggableVariable[];
	groups: TemplateGroupInterface[];
	owner: string;
}) {
	const tempId = `${'temporaryId-'}${generate()}`;
	const elementsOrder: string[] = [];
	const computedVariables = variables.map(variable => {
		const { name } = variable;
		const draggableId = `${name}${DragAndDropTypes.DraggableTemplateVariable}${generate()}`;
		elementsOrder.push(draggableId);
		return { ...variable, draggableId };
	});
	let variablesCounter = variables.length;
	const computedGroups = groups.map(group => {
		const groupElementsOrder: string[] = [];
		const computedVariables = group.variables.map(variable => {
			const { name } = variable;
			const draggableId = `${name}${
				DragAndDropTypes.DraggableTemplateVariable
			}${generate()}-groupedVariable`;
			groupElementsOrder.push(draggableId);
			return {
				...variable,
				draggableId
			};
		});
		const { groupName } = group;
		const draggableId = `${groupName}${DragAndDropTypes.DraggableTemplateGroup}${generate()}`;
		elementsOrder.push(draggableId);

		return {
			...group,
			draggableId,
			variables: computedVariables,
			elementsOrder: groupElementsOrder
		};
	});
	groups.forEach(group => {
		variablesCounter = group.variables.length + variablesCounter;
	});
	const newTemplate: Template = {
		templateId: tempId,
		variables: computedVariables,
		groups: computedGroups,
		templateName: '',
		elementsOrder,
		isSingleVariableTemplate: false,
		accessPublicRead: false,
		owner,
		accessWrite: true,
		totalVariableNumber: variablesCounter,
		description: '',
		projectsSharedWith: { initial: [], current: [] },
		usersSharedWith: { initial: [], current: [] }
	};

	return newTemplate;
}

// getTemplate redux flow omits variables that are nested in groups from `variables` - this computes
// variables in the same way getVariable does;
export function getTemplateVariablesAndGroupsMap(
	variables: DraggableVariable[],
	groups: TemplateGroupInterface[]
) {
	const groupVariables = groups.map(group => group.variables).flat();
	return {
		variables: [...variables, ...groupVariables].reduce<Record<string, DraggableVariable>>(
			(acc, variable) => {
				acc[variable.name] = variable;
				return acc;
			},
			{}
		),
		groups: groups.reduce<Record<string, TemplateGroupInterface>>((acc, group) => {
			acc[group.groupName] = group;
			return acc;
		}, {})
	};
}

import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.maxWidth.md} {
		.two-column-row {
			flex-direction: column;

			> div {
				width: 100%;
			}
		}
	}
`;

interface VariableWrapperProps {
	halfWidth?: boolean;
	hasChanges?: boolean;
}

export const VariableWrapper = styled.div<VariableWrapperProps>`
	width: 50%;

	/* show tooltip on hover */
	:hover .tooltip__icon {
		visibility: visible;
	}

	${({ halfWidth }) =>
		!halfWidth &&
		css`
			width: 100%;
		`}

	${({ hasChanges }) =>
		hasChanges &&
		css`
			background-color: ${Colors.highlightGreen};
		`}
`;

export const Divider = styled.div`
	height: 0.1rem;
	background-color: #ededed;
	margin: 0.8rem 0;
`;

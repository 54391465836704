import { BoldTextHighlight } from 'components/UI/BoldTextHighlight';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useReactivateUserAccount, useSubscription, useTranslation } from 'hooks/store';
import { useAlerts } from 'hooks/ui';

import { useCompletedAction } from 'hooks/utils';

interface Props {
	userId: string | null;
	onClose: (deleted?: boolean) => void;
}

export function ReactivateEnterpriseUser({ userId, onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [
		{ loading: reactivatingUserAccount, error: errorReactivatingUserAccount },
		reactivateUserAccount
	] = useReactivateUserAccount();

	const [
		{
			data: { users }
		}
	] = useSubscription();

	function onConfirm() {
		if (userId) reactivateUserAccount(userId);
	}
	useCompletedAction(reactivatingUserAccount, errorReactivatingUserAccount, () => {
		setNotification({
			message: translate(
				({ enterpriseAdmin }) =>
					enterpriseAdmin.reactivateUser.api.userReactivatedSuccessfully
			)
		});
		onClose(true);
	});

	const userToDeactivate = users.find(user => user.userid === userId);
	if (!userToDeactivate) return <></>;
	const { userFirstName, userSirName } = userToDeactivate;
	const userToDeactivateFullName = `${userFirstName} ${userSirName}`;
	const modalQuestion = translate(
		dict => dict.enterpriseAdmin.reactivateUser.modalQuestion,
		false,
		{ user: userToDeactivateFullName }
	);

	return (
		<Modal
			visible={!!userId}
			title={translate(dict => dict.enterpriseAdmin.reactivateUser.title)}
			size={s => s.s}
			primary={{
				label: translate(dict => dict.buttons.reactivate),
				loading: reactivatingUserAccount,

				onClick: onConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			close
		>
			<Typography.Paragraph>
				<BoldTextHighlight searchTerm={userToDeactivateFullName} sentence={modalQuestion} />
			</Typography.Paragraph>
		</Modal>
	);
}

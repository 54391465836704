import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectFormsFilters,
	setFormsFilters,
	FormsFilters,
	FormsFilterShowOptions
} from 'store/data/forms';

interface Handlers {
	setShowFilter: (item: FormsFilterShowOptions) => void;
	clearFilters: () => void;
}

interface Data {
	filters: FormsFilters;
	areFiltersActive: boolean;
}

export function useFormsFilters(): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectFormsFilters(state.data.forms));

	const areFiltersActive = data.show !== FormsFilterShowOptions.ALL;

	function setShowFilter(item: FormsFilterShowOptions) {
		dispatch(
			setFormsFilters({
				filters: { show: item }
			})
		);
	}

	function clearFilters() {
		dispatch(
			setFormsFilters({
				filters: { show: FormsFilterShowOptions.ALL }
			})
		);
	}

	return [
		{ filters: data, areFiltersActive },
		{ setShowFilter, clearFilters }
	];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, uploadProjectFile } from 'store/data/documents';

interface ProjectFile {
	name: string;
	size: number;
	type: string;
}

export function useUploadDocument(): Operation<(uploadedFile: ProjectFile) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPLOAD_PROJECT_FILE);

	function handler(uploadedFile: ProjectFile) {
		dispatch(
			uploadProjectFile(
				uploadedFile.name,
				uploadedFile.size,
				uploadedFile.type,
				uploadedFile as any as string
			)
		);
	}

	return [{ loading, error }, handler];
}

import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { Svgs, Colors } from 'environment';
import { Container } from './NotFoundPage.style';
import { useNavigation } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';

export function NotFoundPage() {
	const { goBack } = useNavigation();
	const { translate } = useTranslation();

	return (
		<Container>
			<Svgs.LedidiLogoColored height={200} width={200} />

			<Typography.H1
				fontweight={w => w.normal}
				color={Colors.primary.normal}
				marginOffset={{ bottom: 2 }}
			>
				{translate(dict => dict.notFoundPage.title)}
			</Typography.H1>

			<Typography.H4 marginOffset={{ bottom: 4 }}>
				{translate(dict => dict.notFoundPage.message)}
			</Typography.H4>

			<Button
				variant={v => v.outline}
				title={translate(dict => dict.buttons.goBack)}
				marginOffset={{ bottom: 8 }}
				onClick={goBack}
			/>
		</Container>
	);
}

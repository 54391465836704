import { useEffect } from 'react';

import { ROUTES } from 'types/navigation';
import { Svgs } from 'environment';

import { Container, Wrapper, UpdatePaymentLink } from './LastPaymentWarning.style';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useNavigation, useMatchAccountUM } from 'hooks/navigation';
import { useTranslation, useSubscription } from 'hooks/store';
import { useLastSubscriptionPayment } from 'hooks/store/account/payments/useLastSubscriptionPayment';
import { PaymentMethod, PaymentStatus } from 'store/account/payments';
import { Link } from 'components/UI/Link';

export function LastPaymentWarning() {
	const { translate } = useTranslation();
	const { navigate } = useNavigation();

	const isAccountUMRoute = useMatchAccountUM();

	const [
		{
			data: {
				subscriptionTypes: { isLedidiCollaborator }
			}
		}
	] = useSubscription();

	const [{ data: lastPayment, fetched, loading, error }, getPayments] =
		useLastSubscriptionPayment();

	useEffect(() => {
		if (!fetched && !loading && !error && isAccountUMRoute) getPayments();
	}, [fetched, loading, error, isAccountUMRoute]);

	const invoiceDueDate =
		lastPayment && lastPayment.selectedPaymentMethod === PaymentMethod.INVOICE
			? lastPayment.invoiceDueDate
			: undefined;

	const invoiceDueDateExceeded =
		lastPayment && lastPayment.status === PaymentStatus.NotPaid && invoiceDueDate
			? invoiceDueDate.getTime() - new Date().getTime() < 0
			: false;

	const withCardNotPaid =
		lastPayment &&
		lastPayment.status === PaymentStatus.NotPaid &&
		lastPayment.selectedPaymentMethod === PaymentMethod.CARD;

	return (invoiceDueDateExceeded || withCardNotPaid) && isAccountUMRoute ? (
		<Wrapper>
			<Grid.Container>
				{withCardNotPaid && (
					<Container>
						<Flex align={a => a.center}>
							<Icon svg={Svgs.InformationDark} marginOffset={{ right: 1.6 }} />
							<Typography.Paragraph>
								{translate(dict => dict.accountUM.billing.paymentFailedInfo)}
							</Typography.Paragraph>
						</Flex>

						<UpdatePaymentLink>
							<Typography.Paragraph
								onClick={() =>
									isLedidiCollaborator
										? undefined
										: navigate(ROUTES.AccountUMPayment)
								}
							>
								{translate(dict => dict.accountUM.billing.updatePayment)}
							</Typography.Paragraph>
						</UpdatePaymentLink>
					</Container>
				)}
				{invoiceDueDateExceeded && (
					<Flex paddingOffset={{ y: 1 }}>
						<Icon svg={Svgs.InformationDark} marginOffset={{ right: 1.6 }} />
						<Typography.Paragraph>
							{translate(dict => dict.accountUM.billing.paymentDueDateExceeded)}
							<Link href="mailto:support@ledidi.no">{`support@ledidi.no`}</Link>
						</Typography.Paragraph>
					</Flex>
				)}
			</Grid.Container>
		</Wrapper>
	) : (
		<></>
	);
}

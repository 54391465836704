import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setAdminRolesSearchTerm } from 'store/account/enterprise';

export function useAdminRolesSearchTerm(): LocalOperationResult<string, (term: string) => void> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.account.enterprise.roles.metadata.searchTerm);

	function handler(term: string) {
		dispatch(setAdminRolesSearchTerm({ term }));
	}

	return [data, handler];
}

import { Header } from 'components/Header';
import { CustomisableTable } from 'components/UI/CustomisableTable';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Suspend } from 'components/UI/Suspend';
import { UserCard } from 'components/UI/UserCard';
import { TransferProjectOwnershipModal } from 'components/Account/EnterpriseProjects/TransferProjectOwnershipModal/TransferProjectOwnershipModal';
import { splitString } from 'helpers/strings';
import {
	useAllEnterpriseOwnedProjects,
	useProjectId,
	useSubscriptionRules,
	useTranslation,
	useUsername
} from 'hooks/store';
import { useEffect, useMemo, useState } from 'react';

import { ProjectOwnerDetails } from 'store/data/projects';
import {
	CustomisableTableColumn,
	CustomisableTableRow,
	CustomCellType,
	GenericMap,
	TableFilterType,
	TableName
} from 'types/index';
import { EnterpriseProject } from 'store/account/enterprise';
import { ProjectSettingsDropdown } from 'components/Account/EnterpriseProjects/TransferProjectOwnershipDropdown/ProjectSettingsDropdown';
import { useNavigate } from 'react-router-dom';
import { notEmpty } from 'helpers/arrays';

const PROJECT_METADATA_CATEGORIES_DICT_KEYS_BY_IDS = {
	'1': 'projectMetadata.hso.projectType.medicalResearch.label',
	'2': 'projectMetadata.hso.projectType.internalQA.label'
};

export function EnterpriseAdminProjectsPage() {
	const { translate } = useTranslation();

	const navigate = useNavigate();

	const loggedInUserId = useUsername();

	const [{ data: projects, loading: loadingEnterpriseProjects }, getAllEnterpriseOwnedProjects] =
		useAllEnterpriseOwnedProjects();

	const [searchTerm, setSearchTerm] = useState('');
	const [filteredProjects, setFilteredProjects] = useState(projects);
	const [, setProjectId] = useProjectId();

	const [projectToTransfer, setProjectToTransfer] = useState<EnterpriseProject | null>();

	const projectsSplitSearchTerm = useMemo(() => splitString(searchTerm), [searchTerm]);

	const [activeDropownByProjectId, setActiveDropownByProjectId] = useState<string | null>(null);

	const { canTransferProjectOwnership } = useSubscriptionRules();

	function onDropdownVisible(userId: string | null) {
		setActiveDropownByProjectId(userId);
	}

	useEffect(() => {
		getAllEnterpriseOwnedProjects();
	}, []);

	// SEARCH FILTERING
	useEffect(() => {
		let resultData: EnterpriseProject[] = [];

		if (!projectsSplitSearchTerm.length || !projects.length) {
			resultData = projects;
		} else {
			resultData = projects.filter(project => {
				let matches = true;

				const keywords = [
					project.projectName.toLowerCase().trim(),
					project.projectOwnerDetails.userFirstName?.toLowerCase().trim(),
					project.projectOwnerDetails.userSirName?.toLowerCase().trim(),
					project.projectOwnerDetails.organization?.toLowerCase().trim(),
					project.status?.toLowerCase().trim()
				];

				projectsSplitSearchTerm.forEach(term => {
					let termFound = false;

					keywords.forEach(keyword => {
						if (keyword && keyword.indexOf(term) !== -1) termFound = true;
					});

					if (!termFound) matches = false;
				});

				return matches;
			});
		}
		setFilteredProjects(resultData);
	}, [projects, projectsSplitSearchTerm]);

	function userNameComponent(user: ProjectOwnerDetails) {
		return (
			<UserCard.Table
				userId={user.userId}
				userData={{
					userFirstName: user.userFirstName,
					userSirName: user.userSirName,
					emailAddress: user.emailAddress
				}}
			/>
		);
	}

	const [customCells, setCustomCells] = useState<CustomCellType>({});
	const [actionCellByRowId, setActionCellByRowId] = useState<GenericMap<JSX.Element>>({});

	// populate tableItems and customCells with data
	const tableItems = useMemo(() => {
		const items: CustomisableTableRow[] = [];
		filteredProjects.forEach(project => {
			const projectTypeId = project.metadata?.projectType as '1' | '2';
			const projectTypeDictKey =
				projectTypeId !== undefined
					? PROJECT_METADATA_CATEGORIES_DICT_KEYS_BY_IDS[projectTypeId]
					: undefined;
			const projectTypeLabel = projectTypeDictKey
				? translate(_ => projectTypeDictKey)
				: undefined;

			const item = {
				id: project.projectId,
				projectName: project.projectName,
				createdByUser:
					project.projectOwnerDetails.userFirstName +
					' ' +
					project.projectOwnerDetails.userSirName,
				collaborators: project.collaborators,
				organization: project.projectOwnerDetails?.organization ?? '',
				suborganization: project.projectOwnerDetails?.subOrganization ?? '',
				status: project.status.toString(),
				archiveNumber: project.metadata?.archiveNumber ?? '',
				projectType: projectTypeLabel
			};

			items.push(item);
			const customCell: GenericMap<string | JSX.Element> = {};

			if (project.projectOwnerDetails) {
				customCell.createdByUser = userNameComponent(project.projectOwnerDetails);
			}

			setCustomCells(prev => ({ ...prev, [item.id]: customCell }));

			setActionCellByRowId(prev => ({
				...prev,
				[item.id]: (
					<ProjectSettingsDropdown
						onDropdownVisible={(visible: boolean) =>
							onDropdownVisible(visible ? project.projectId : null)
						}
						projectName={project.projectName}
						showTransferOwnership={
							project.projectOwnerDetails &&
							project.projectOwnerDetails.userId !== loggedInUserId &&
							canTransferProjectOwnership
						}
						onTransferProject={() => {
							setProjectToTransfer(project);
						}}
						onViewCollaboratorsClicked={() => {
							setProjectId(project.projectId);
							navigate(`/projects/${project.projectId}/collaborators`);
						}}
					/>
				)
			}));
		});
		return items;
	}, [filteredProjects, canTransferProjectOwnership]);

	const showSuborganization = useMemo(
		() => !!filteredProjects.find(project => !!project.projectOwnerDetails?.subOrganization),
		[filteredProjects]
	);
	const showOrganization = useMemo(
		() => !!filteredProjects.find(project => !!project.projectOwnerDetails?.organization),
		[filteredProjects]
	);
	const showArchiveNumber = useMemo(
		() => !!filteredProjects.find(project => !!project.metadata?.archiveNumber),
		[filteredProjects]
	);
	const showProjectType = useMemo(
		() => !!filteredProjects.find(project => !!project.metadata?.projectType),
		[filteredProjects]
	);

	const columns: CustomisableTableColumn[] = useMemo(
		() =>
			[
				{
					name: 'projectName',
					label: translate(({ enterpriseAdmin }) => enterpriseAdmin.projects.projects),
					filterType: TableFilterType.Text
				},
				{
					name: 'createdByUser',
					label: translate(({ enterpriseAdmin }) => enterpriseAdmin.projects.ownerName),
					filterType: TableFilterType.Text
				},
				{
					name: 'collaborators',
					label: translate(
						({ enterpriseAdmin }) => enterpriseAdmin.projects.collaborators
					),
					filterType: TableFilterType.Numeric
				},
				showOrganization
					? {
							name: 'organization',
							label: translate(
								({ enterpriseAdmin }) => enterpriseAdmin.projects.organization
							),
							filterType: TableFilterType.Checkbox,
							isOptional: true
					  }
					: null,
				showSuborganization
					? {
							name: 'suborganization',
							label: translate(
								({ accountUM }) => accountUM.userDetails.suborganization
							),
							filterType: TableFilterType.Checkbox,
							isOptional: true
					  }
					: null,
				{
					name: 'status',
					label: translate(({ enterpriseAdmin }) => enterpriseAdmin.projects.status),
					filterType: TableFilterType.Checkbox,
					isOptional: true
				},
				showArchiveNumber
					? {
							name: 'archiveNumber',
							label: translate(dict => dict.projectMetadata.hso.archiveNumber.label),
							filterType: TableFilterType.Text,
							isOptional: true
					  }
					: null,
				showProjectType
					? {
							name: 'projectType',
							label: translate(dict => dict.projectMetadata.hso.projectType.label),
							filterType: TableFilterType.Text,
							isOptional: true
					  }
					: null
			].filter(notEmpty),
		[showSuborganization, showOrganization]
	);

	const initialVisibleColumns = [
		'projectName',
		'createdByUser',
		'collaborators',
		'organization',
		'suborganization',
		'archiveNumber',
		'projectType'
	];

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={() =>
					projects.length && !loadingEnterpriseProjects ? (
						<Flex>
							<SearchInput
								usedInHeader
								term={searchTerm}
								placeholder={translate(dict => dict.terms.search)}
								onChangeTerm={value => setSearchTerm(value)}
							/>
						</Flex>
					) : undefined
				}
			/>

			<Header.Title
				title={translate(dict => dict.enterpriseAdmin.header.menuTabs.projects)}
			/>

			<Suspend loading={false} immediate={false}>
				<Grid.Container>
					<CustomisableTable
						tableName={TableName.EnterpriseAdminProjects}
						items={tableItems}
						columns={columns}
						itemsCountLabel={translate(dict => dict.tableLists.projects)}
						initVisibleColumns={initialVisibleColumns}
						customCells={customCells}
						hasPagination
						extraCells={actionCellByRowId}
						activeExtraCell={activeDropownByProjectId ?? undefined}
						loading={loadingEnterpriseProjects}
						noItemsMessage={translate(dict => dict.projectList.noProjectsToShow)}
					/>
				</Grid.Container>
			</Suspend>
			{projectToTransfer && projectToTransfer.projectOwnerDetails && (
				<TransferProjectOwnershipModal
					projectId={projectToTransfer.projectId}
					currentOwnerDetails={projectToTransfer.projectOwnerDetails}
					onTransferred={() => getAllEnterpriseOwnedProjects()}
					onClose={() => setProjectToTransfer(null)}
				/>
			)}
		</>
	);
}

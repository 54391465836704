import styled from 'styled-components';

import { Colors } from 'environment';
import { getTypographyCSS, FontWeights } from 'components/UI/Typography';

export const Value = styled.div`
	${getTypographyCSS(t => t.Caption)}

	color: ${Colors.text.main};
	border-radius: 0.4rem;
	padding: 0.1rem 0.8rem;
	background-color: ${Colors.background.disabled};
	font-weight: ${FontWeights.medium};
	word-break: break-word;
	white-space: pre-line;
`;

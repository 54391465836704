import { resetLoginErrors } from 'store/auth';
import { LocalOperationResult } from '../types';
import { useDispatch, useSelector } from 'hooks/utils';

interface Data {
	errors: {
		email?: string;
		credentials?: string;
	};
	hasErrors: boolean;
}

export function useLoginErrors(): LocalOperationResult<Data> {
	const dispatch = useDispatch();

	const errors = useSelector(state => state.auth.errors);

	const data: Data = {
		errors,
		hasErrors: !!Object.keys(errors).length
	};

	function handler() {
		dispatch(resetLoginErrors());
	}

	return [data, handler];
}

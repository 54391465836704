import type {
	AndNode,
	ArithmeticNode,
	Variable,
	VariableCategory,
	CalculationActions,
	CategoryNode
} from 'api/data/variables/types';
import { ComparisonOperation } from './ComparisonOperation';

type Props = {
	node: AndNode;
	nodePath: string;
	draftVariable: Variable;
	variables: Variable[];
	categories: VariableCategory[];
	conclusionValue: CategoryNode | string | number | ArithmeticNode | null;
	conclusionPath: string;
	calculationActions: CalculationActions;
};

export function AndOperation({
	node,
	nodePath,
	draftVariable,
	variables,
	categories,
	conclusionPath,
	conclusionValue,
	calculationActions
}: Props) {
	const comparisonOperations = node.and;

	return (
		<>
			{comparisonOperations.map((comparisonOperation, index) => {
				const comparisonNodePath = `${nodePath}.and.${index}`;
				return (
					<ComparisonOperation
						key={comparisonNodePath}
						node={comparisonOperation}
						nodePath={comparisonNodePath}
						conclusionValue={conclusionValue}
						conclusionPath={conclusionPath}
						draftVariableType={draftVariable.type}
						variables={variables}
						categories={categories}
						calculationActions={calculationActions}
						isLastOperation={index === comparisonOperations.length - 1}
					/>
				);
			})}
		</>
	);
}

import { ActionPayload } from 'store/types';
import { ActionTypes, ResetExportWizardAction, SetSeriesExportFileAndNamesAction } from './types';

export const setSeriesExportFileAndNamesAction = (
	payload: ActionPayload<SetSeriesExportFileAndNamesAction>
): SetSeriesExportFileAndNamesAction => ({
	type: ActionTypes.SET_SERIES_EXPORT_FILE_AND_NAMES,
	payload
});

export const resetExportWizardAction = (): ResetExportWizardAction => ({
	type: ActionTypes.RESET_EXPORT_WIZARD
});

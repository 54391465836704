import { useState, useRef } from 'react';
import { Svgs, Colors } from 'environment';
import { Container, Wrapper, FiltersMenu } from './WarningWithFilters.style';
import { Icon } from '../Icons';
import { Button } from '../Interactables/Button';
import { Switch } from '../Interactables/Switch';
import { Typography } from '../Typography';
import { useOutsideClick } from 'hooks/utils';

interface FilterAction {
	label: string;
	description: string;
	on: boolean;
	onChange: () => void;
}

interface Props {
	message: string;
	actions: FilterAction[];
	onClearFilters: () => void;
}

export function WarningWithFilters({ message, actions, onClearFilters }: Props) {
	const hasActiveFilters = actions.some(action => action.on);

	const [filtersOpen, setFiltersOpen] = useState(false);

	const filtersMenuRef = useRef<HTMLDivElement>(null);
	const filterIconRef = useRef<HTMLImageElement>(null);
	const clearFiltersButtonRef = useRef<HTMLButtonElement>(null);

	useOutsideClick(hideFilter, [filterIconRef, filtersMenuRef, clearFiltersButtonRef]);

	function hideFilter() {
		setFiltersOpen(false);
	}

	function handleClearFilters() {
		setFiltersOpen(false);
		onClearFilters();
	}

	return (
		<Container>
			<Wrapper>
				<Icon svg={Svgs.InformationRed} marginOffset={{ right: 1.6 }} />
				<Typography.Paragraph color={Colors.text.error} fontweight={s => s.bold}>
					{message}
				</Typography.Paragraph>
			</Wrapper>

			<Wrapper>
				{hasActiveFilters && (
					<Button
						ref={clearFiltersButtonRef}
						className="clear-button"
						title={'Clear all filters'}
						variant={v => v.link}
						marginOffset={{ right: 2.4 }}
						onClick={handleClearFilters}
					/>
				)}

				<Icon
					ref={filterIconRef}
					svg={Svgs.Filter}
					variant={v => v.button}
					paddingOffset={{ all: 0 }}
					colors={{
						color: Colors.text.error,
						hoverBackground: Colors.transparent
					}}
					onClick={() => setFiltersOpen(value => !value)}
				/>

				{filtersOpen && (
					<FiltersMenu ref={filtersMenuRef}>
						{actions.map(action => (
							<Switch
								key={action.description}
								className="entires-warning-switch"
								label={action.label}
								description={action.description}
								on={action.on}
								onChange={action.onChange}
							/>
						))}
					</FiltersMenu>
				)}
			</Wrapper>
		</Container>
	);
}

import styled, { css } from 'styled-components';

import { Colors, Shadows } from 'environment';
import { RadioButton as DefaultRadioButton } from 'components/UI/Interactables/RadioButton';
import { Disabled, generateSpacingOffsets } from 'helpers/cssGenerators';
import { SpacingOffsets } from 'types/index';

export const PlanCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	cursor: pointer;

	:first-child {
		width: 2.4rem;
	}

	:hover {
		box-shadow: ${Shadows.hover};
	}
`;

export const RadioButton = styled(DefaultRadioButton)`
	width: initial;
`;

export const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: end;
	justify-content: center;
	margin-right: 1.6rem;
`;

export const AdvantagesWrapper = styled.div`
	margin: 1.6rem 2.2rem 0rem 0rem;
`;

export const CreditCardWrapper = styled.div`
	display: flex;
	height: 10.6rem;
	padding: 0 1.6rem;
	border-radius: 0.4rem;
	cursor: pointer;
	box-shadow: ${Shadows.normal};

	:hover {
		box-shadow: ${Shadows.hover};
	}
`;

export const PaymentMethodTextWrapper = styled.div`
	padding: 0.1rem 0.2rem 0rem 0.8rem;
`;
interface PaymentMethodWrapperProps extends Disabled, SpacingOffsets {
	selected: boolean;
}
export const PaymentMethodWrapper = styled.div<PaymentMethodWrapperProps>`
	display: flex;
	flex: 1;
	align-items: start;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};

	cursor: pointer;
	border: 1px solid ${Colors.transparent};

	:hover {
		box-shadow: ${Shadows.hover};
	}

	${({ marginOffset, paddingOffset, disabled, selected }) => css`
		${disabled &&
		css`
			pointer-events: none;
			cursor: unset;
			:hover {
				box-shadow: ${Shadows.normal};
			}
		`}

		${selected &&
		css`
			border: 1px solid ${Colors.primary.normal};
		`}

		${generateSpacingOffsets({ paddingOffset, marginOffset })}
	`}
`;

export const PaymentSuccessWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const InvoiceInfoWrapper = styled.div`
	background-color: ${Colors.background.disabled};
	display: flex;
	align-items: top;
	justify-content: flex-start;
	border-radius: 4px;
`;

import styled, { css } from 'styled-components';
import { Colors } from 'environment';
import { Typography } from 'components/UI/Typography';

interface Props {
	last?: boolean;
}

export const Column = styled.div<Props>`
	width: 66%;
	display: flex;
	flex-direction: column;

	${({ last }) =>
		!last
			? css`
					height: 100%;
					width: 33%;
					border-right: 0.1rem solid #ededed;
					padding-right: 0.5rem;
			  `
			: css`
					padding: 0 1rem;
					margin-left: 1rem;
			  `}
`;

export const Organization = styled(Typography.Paragraph)<{ selected: boolean }>`
	padding: 1rem 0;
	border-radius: 0.4rem;
	height: 4.4rem;

	:hover {
		background-color: ${Colors.primary.hover};
		color: ${Colors.white};
		cursor: pointer;
		margin-left: -1rem;
		padding-left: 1rem;
	}

	${({ selected }) =>
		selected &&
		css`
			margin-left: -1rem;
			padding-left: 1rem;
			background: ${Colors.primary.disabled};
		`}
`;

export const Domain = styled.div`
	height: 4.4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
	border-radius: 0.4rem;

	:hover {
		background-color: ${Colors.primary.hover};
		cursor: pointer;
		margin: 0 -1rem;
		padding: 1rem;

		> :first-child {
			> :nth-child(2) {
				color: ${Colors.white};
			}
			//When hovered, set checkbox border to white
			> :first-child > :first-child > :first-child > :first-child {
				border-color: ${Colors.white};
			}
		}
	}
`;

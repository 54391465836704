import { useEffect, useMemo, useRef, useState } from 'react';
import { nanoid as generate } from 'nanoid';
import { EntryFilter as FilterType, Operator } from 'api/data/filters';
import { VariableType } from 'types/data/variables/constants';

import { FilterIcon } from 'components/UI/Icons';
import { VariablesData } from 'store/data/variables';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { Offset } from 'types/index';
import { Filter } from 'components/Analysis/Filters/FilterList/Filter';
import { Container, Floating } from './ColumnFilter.style';
import { getPositionWithinBounds } from 'helpers/generic';
import { useUiStates } from 'hooks/ui';
import { useDependenciesFilters } from 'hooks/store';
import { DependenciesTableView, TableDependency } from 'store/data/dependencies';

const PADDING = 40;
const DEFAULT_OFFSET = 20;

function computeLeftOffset(position: Offset | null) {
	let left = -DEFAULT_OFFSET;

	if (position && position.left) {
		left = -(position.left + DEFAULT_OFFSET + PADDING);
	}

	return left;
}

interface Props {
	tableRef: React.RefObject<HTMLTableElement>;
	columnName: string;
	computePosition: boolean;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	tableDependencies: TableDependency[];
}

export function ColumnFilter({
	tableRef,
	columnName,
	computePosition,
	variablesData,
	variablesDataSelectItems,
	tableDependencies
}: Props) {
	const [dependenciesVisible, setDependenciesVisible] = useState(false);
	const [{ dependenciesFilters }, { createDependenciesFilter, deleteDependenciesFilters }] =
		useDependenciesFilters();
	const [position, setPosition] = useState<Offset | null>(null);
	const [inView, setInview] = useState(true);
	const [computingPosition, setComputingPosition] = useState(true);

	const [{ focusState: focus }] = useUiStates();

	const isInputFocused = useRef(focus);

	const filterRef = useRef<HTMLDivElement>(null);
	const iconRef = useRef<HTMLImageElement>(null);

	const columnDependenciesFilters = useMemo(
		() => dependenciesFilters.filter(filter => filter.columnName === columnName),
		[dependenciesFilters]
	);

	// Hide the Dependencies filters when the last one is deleted
	useEffect(() => {
		if (!columnDependenciesFilters.length && dependenciesVisible) setDependenciesVisible(false);
	}, [columnDependenciesFilters]);

	// Delete invalid Dependencies filters when hiding
	useEffect(() => {
		const invalidDependenciesFilterIds = columnDependenciesFilters
			.filter(filter => filter.invalid)
			.map(filter => filter.itemId);

		if (!dependenciesVisible && invalidDependenciesFilterIds.length)
			deleteDependenciesFilters(invalidDependenciesFilterIds);
	}, [dependenciesVisible]);

	// Compute the needed offset to display a filter
	// within screen bounds
	useEffect(() => {
		if (computingPosition) {
			const bounds = getPositionWithinBounds(filterRef, tableRef);

			if (bounds) setPosition(bounds);

			setComputingPosition(false);
		}
	}, [computingPosition]);

	// isInputFocused is used in the onOutsideClick event handler
	// that reads the props from the closure where it is defined.
	// Manually updating a ref is the preferred solution here.
	useEffect(() => {
		isInputFocused.current = focus;
	}, [focus]);

	// useOutsideClick(() => hideFilter(), [iconRef, filterRef]);

	function showFilter() {
		setDependenciesVisible(true);
	}

	function hideFilter() {
		if (!isInputFocused.current) setDependenciesVisible(false);
	}

	// ON DEPENDENCIES FILTER ICON CLICK
	function onIconClick(e: React.MouseEvent) {
		setInview(e.clientX < window.innerWidth - 390);

		if (!dependenciesVisible) {
			if (computePosition && !position) {
				setComputingPosition(true);
			}

			if (!columnDependenciesFilters.length) {
				if (columnName as keyof DependenciesTableView) {
					let dependenciesFilter: FilterType = {
						itemId: '',
						columnName: '',
						filterType: VariableType.String,
						operator: Operator.In
					};

					if (
						columnName === DependenciesTableView.supplierValueCondition ||
						columnName === DependenciesTableView.description
					) {
						dependenciesFilter = {
							itemId: generate(),
							columnName: columnName,
							filterType: VariableType.String,
							operator: Operator.In,
							invalid: true
						};
					} else {
						dependenciesFilter = {
							itemId: generate(),
							columnName: columnName,
							filterType: VariableType.Category,
							operator: Operator.In,
							invalid: true
						};
					}

					createDependenciesFilter({ dependenciesFilter });
				}
			}

			showFilter();
		} else {
			hideFilter();
		}
	}

	const left = useMemo(() => computeLeftOffset(position), [position]);

	const filterActive = columnDependenciesFilters.length > 0;

	return (
		<Container>
			<FilterIcon
				className="column-filter-icon"
				ref={iconRef}
				active={filterActive}
				onClick={onIconClick}
				style={
					filterActive
						? {
								visibility: 'visible'
						  }
						: undefined
				}
			/>

			{filterActive && (dependenciesVisible || computingPosition) && (
				<Floating
					ref={filterRef}
					style={{
						left: inView ? left : -307,
						opacity: computingPosition ? 0 : 1
					}}
				>
					{columnDependenciesFilters.map(filter => (
						<Filter
							key={`dependencies-filter-${filter.itemId}`}
							filter={filter}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							isDependencyFilter={true}
							tableDependencies={tableDependencies}
							filterContainerRef={filterRef}
							hideFilter={hideFilter}
						/>
					))}
				</Floating>
			)}
		</Container>
	);
}

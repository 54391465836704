import { deleteProject, ActionTypes } from 'store/data/projects';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { useState } from 'react';

type HandlerInput = Parameters<typeof deleteProject>[0]['projectId'];

export function useDeleteProject(): Operation<(projectId: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [activityPayload, setActivityPayload] = useState('');

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.DELETE_PROJECT,
		activityPayload
	);

	function handler(projectId: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		setActivityPayload(projectId);

		dispatch(deleteProject({ projectId }));
	}

	return [{ loading, error }, handler];
}

import produce from 'immer';

import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

import { Actions as ProjectActions, ActionTypes as ProjectActionTypes } from '../projects/types';

export default (state: State = initialState, action: Actions | ProjectActions): State => {
	switch (action.type) {
		case ProjectActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					// INITIALIZE STRUCTURE
					byProjectId[projectId] = {
						byId: {},
						main: {
							ids: [],
							active: false,
							initialized: false
						},
						bySetName: {},
						fetched: false,
						metadata: {
							searchTerm: ''
						}
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.INITIALIZE_VARIABLES_MAPPING: {
			const { projectId, variablesMappingData, setName } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byProjectId } = draft;

				if (projectId !== currentProjectId) return;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					const { active, initialized, variablesMapping } = variablesMappingData;

					let scopeProjectData = projectData.main;

					if (setName) {
						projectData.bySetName[setName] = {
							ids: [],
							active: false,
							initialized: false
						};

						scopeProjectData = projectData.bySetName[setName];
					}

					variablesMapping.forEach(variableMapping => {
						projectData.byId[variableMapping.name] = variableMapping;
						scopeProjectData.ids.push(variableMapping.name);
					});

					scopeProjectData.active = active;
					scopeProjectData.initialized = initialized;
				}
			});
		}

		case ActionTypes.GET_VARIABLES_MAPPING: {
			const { projectId, variablesMappingData, variablesMappingDataBySetName } =
				action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byProjectId } = draft;

				if (projectId !== currentProjectId) return;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					const { active, initialized, variablesMapping } = variablesMappingData;

					projectData.main = {
						ids: [],
						active: false,
						initialized: false
					};

					variablesMapping.forEach(variableMapping => {
						projectData.byId[variableMapping.name] = variableMapping;
						projectData.main.ids.push(variableMapping.name);
					});

					for (const setName in variablesMappingDataBySetName) {
						const variablesMappingData = variablesMappingDataBySetName[setName];

						const { active, initialized, variablesMapping } = variablesMappingData;

						projectData.bySetName[setName] = {
							ids: [],
							active: false,
							initialized: false
						};

						variablesMapping.forEach(variableMapping => {
							projectData.byId[variableMapping.name] = variableMapping;
							projectData.bySetName[setName].ids.push(variableMapping.name);
						});

						projectData.bySetName[setName].active = active;
						projectData.bySetName[setName].initialized = initialized;
					}

					projectData.main.active = active;
					projectData.main.initialized = initialized;
					projectData.fetched = true;
				}
			});
		}

		case ActionTypes.SET_VARIABLE_MAPPING: {
			const { projectId, variableMapping } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byProjectId } = draft;

				if (projectId !== currentProjectId) return;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					projectData.byId[variableMapping.name] = variableMapping;
				}
			});
		}

		case ActionTypes.RESET_VARIABLE_MAPPING: {
			const { projectId, variableMapping } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byProjectId } = draft;

				if (projectId !== currentProjectId) return;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					projectData.byId[variableMapping.name] = variableMapping;
				}
			});
		}

		case ActionTypes.ENABLE_VARIABLES_MAPPING: {
			const { projectId, setName } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byProjectId } = draft;

				if (projectId !== currentProjectId) return;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					if (setName) {
						projectData.bySetName[setName].active = true;
					} else {
						projectData.main.active = true;
					}
				}
			});
		}

		case ActionTypes.DISABLE_VARIABLES_MAPPING: {
			const { projectId, setName } = action.payload;

			return produce(state, draft => {
				const { projectId: currentProjectId, byProjectId } = draft;

				if (projectId !== currentProjectId) return;

				if (projectId in byProjectId) {
					const projectData = byProjectId[projectId];

					if (setName) {
						projectData.bySetName[setName].active = false;
					} else {
						projectData.main.active = false;
					}
				}
			});
		}

		default: {
			return state;
		}
	}
};

import { useMemo } from 'react';
import countries from 'i18n-iso-countries';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';
import getSymbolFromCurrency from 'currency-symbol-map';
import format from 'date-fns/format';

import { DATE_FORMAT_COMMA } from 'consts';
import {
	parseApiPriceIntoString,
	getBillingAddressString,
	calculateLicenceQuantitiesPrice
} from 'helpers/subscription';
import { BillingPeriodUnitLabel, BillingInfo, PaymentMethod } from 'store/account/payments';

import { BillingAddressContainer, LastPaymentContainer } from './BillingInformation.style';
import { Button } from 'components/UI/Interactables/Button';

import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useLicenceModels, useSubscriptionBundleFlag, useTranslation } from 'hooks/store';
import UpdatingSubscriptionText from 'components/Account/Common/UpdatingSubscriptionText/UpdatingSubscriptionText';
import { CardGridContainer } from 'components/Account/Common/styles';
import { LicenceModelQuantities } from 'store/account/subscription';

countries.registerLocale(countriesEnLocale);
interface BillingInformationProp {
	data: BillingInfo;
	licenceQuantity: number;
	licenceQuantities?: LicenceModelQuantities;
	isLedidiFree: boolean;
	isSubscriptionUpdating: boolean;
	dueDateAt?: Date;
	onChangeClicked: () => void;
}

export function BillingInformation({
	data: {
		billingAddress,
		currencyCode,
		unitPrice,
		billingPeriodUnit,
		maskedNumber,
		nextBillingAt,
		cardBrand,
		selectedPaymentMethod
	},
	licenceQuantity,
	licenceQuantities,
	isSubscriptionUpdating,
	dueDateAt,
	onChangeClicked
}: BillingInformationProp) {
	const { translate } = useTranslation();
	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();
	const [
		{
			data: { licenceModelsMetadataById }
		}
	] = useLicenceModels();

	const currentBillingPrice = useMemo(() => {
		if (unitPrice)
			return `${getSymbolFromCurrency(currencyCode)} ${parseApiPriceIntoString(
				unitPrice * licenceQuantity
			)} / ${translate(() => BillingPeriodUnitLabel[billingPeriodUnit])}`;
		else return '';
	}, [currencyCode, unitPrice, billingPeriodUnit]);

	// for bundle subscription
	let bundlePrice: number | null = null;
	if (subscriptionBundleFlag && licenceQuantities)
		bundlePrice = calculateLicenceQuantitiesPrice(licenceQuantities, licenceModelsMetadataById);
	const currentBillingBundlePrice = useMemo(() => {
		if (bundlePrice)
			return `${getSymbolFromCurrency(currencyCode)} ${parseApiPriceIntoString(
				bundlePrice * 100
			)} / ${translate(() => BillingPeriodUnitLabel[billingPeriodUnit])}`;
		else return '';
	}, [currencyCode, billingPeriodUnit, bundlePrice]);

	const nextPayment = useMemo(() => {
		if (nextBillingAt) {
			const date = new Date(Number(nextBillingAt));
			const formatedDate = format(date, DATE_FORMAT_COMMA);
			return translate(
				dict =>
					selectedPaymentMethod === PaymentMethod.INVOICE
						? dict.accountUM.billing.nextInvoiceDate
						: dict.accountUM.billing.nextPaymentDate,
				false,
				{
					paymentDate: formatedDate.toString()
				}
			);
		} else return '';
	}, [nextBillingAt, selectedPaymentMethod]);

	const dueDate = useMemo(() => {
		if (dueDateAt) {
			const formatedDate = format(dueDateAt, DATE_FORMAT_COMMA);
			return translate(dict => dict.accountUM.billing.dueDate, false, {
				dueDate: formatedDate.toString()
			});
		} else return '';
	}, [dueDateAt]);

	const cardDetails = useMemo(() => {
		return selectedPaymentMethod === PaymentMethod.CARD
			? translate(dict => dict.accountUM.billing.cardEndingIn, false, {
					cardType: cardBrand,
					maskedNumber
			  })
			: null;
	}, [cardBrand, maskedNumber, selectedPaymentMethod]);

	const billingAddressDisplayed = useMemo(() => {
		const billingAddressString = getBillingAddressString(billingAddress);
		return !billingAddressString ? (
			<Typography.Paragraph>
				{translate(dict => dict.accountUM.billing.noBillingAddress)}
			</Typography.Paragraph>
		) : (
			billingAddressString
				.split('\n')
				.map(str => <Typography.Paragraph key={str}>{str}</Typography.Paragraph>)
		);
	}, [billingAddress]);

	return (
		<CardGridContainer>
			<BillingAddressContainer>
				<Typography.Paragraph fontweight={f => f.bold}>
					{translate(dict => dict.accountUM.billing.billedTo)}
				</Typography.Paragraph>
				{billingAddressDisplayed}
				<Spacer size={s => s.xs} />
				<div>
					<Button
						title={translate(dict => dict.buttons.change)}
						variant={v => v.link}
						paddingOffset={{ all: 0 }}
						onClick={onChangeClicked}
						disabled={isSubscriptionUpdating}
					/>
				</div>
			</BillingAddressContainer>

			<div>
				<LastPaymentContainer>
					<Spacer size={s => s.m} />
					{isSubscriptionUpdating ? (
						<UpdatingSubscriptionText />
					) : (
						<>
							<Typography.H3>
								{subscriptionBundleFlag
									? currentBillingBundlePrice
									: currentBillingPrice}
							</Typography.H3>
							<Spacer size={s => s.xs} />
							<Typography.Paragraph fontweight={f => f.bold}>
								{nextPayment}
							</Typography.Paragraph>
							{cardDetails && (
								<>
									<Spacer size={s => s.xs} />
									<Typography.Paragraph>{cardDetails}</Typography.Paragraph>
								</>
							)}
							{dueDate && (
								<>
									<Spacer size={s => s.xs} />
									<Typography.Paragraph>{dueDate}</Typography.Paragraph>
								</>
							)}
						</>
					)}
					<Spacer size={s => s.m} />
				</LastPaymentContainer>
			</div>
		</CardGridContainer>
	);
}

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface Props {
	onClose: () => void;
}

export function ViewPermissionModal({ onClose }: Props) {
	const { translate } = useTranslation();
	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.dataset.modals.viewPermissionModal.title)}
			primary={{
				label: translate(dict => dict.dataset.modals.viewPermissionModal.label),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.dataset.modals.viewPermissionModal.message)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { useMemo, useState } from 'react';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteBulkVariablesData, useActivity } from 'hooks/store';
import { ActionTypes } from 'store/data/variables';
import { VariablesTableCheckedState } from 'hooks/store/data/variables/useVariablesTableCheckedData';
import { useCompletedAction, useMemoOnce } from 'hooks/utils';

interface Props {
	checkedState: VariablesTableCheckedState;
	onClose: () => void;
}

export function DeleteVariablesSelectedTableRowsModal({ checkedState, onClose }: Props) {
	const { translate } = useTranslation();

	const [open, setOpen] = useState(false);

	useMemoOnce(() => {
		setOpen(true);

		return () => setOpen(false);
	});

	const [{ error: errorDeletingSelected }, deleteSelected] = useDeleteBulkVariablesData();

	const [{ loading: deletingSelected }] = useActivity(ActionTypes.DELETE_BULK_VARIABLES_DATA);

	// CLOSE MODAL AFTER SELECTED VARIABLES AND/OR GROUPS WERE DELETED
	useCompletedAction(deletingSelected, errorDeletingSelected, onClose);

	function handleDelete() {
		deleteSelected({
			variableNames: checkedState.checked.variables,
			groupNames: checkedState.checked.groups,
			variableSetNames: checkedState.checked.variableSets
		});
	}

	const variablesSelected = checkedState.checked.variables.length > 0;
	const groupsSelected = checkedState.checked.groups.length > 0;
	const variableSetsSelected = checkedState.checked.variableSets.length > 0;

	const selectedItems = useMemo(() => {
		const items: string[] = [];

		if (variablesSelected) items.push(translate(dict => dict.terms.variables));
		if (groupsSelected) items.push(translate(dict => dict.terms.groups));
		if (variableSetsSelected) items.push(translate(dict => dict.terms.series));

		let selectedItems = items.toString();

		const shouldParse = items.length > 1;

		if (shouldParse) {
			const draftItems = [...items];

			const lastItem = draftItems.pop();

			selectedItems = `${draftItems.join(', ')} ${translate(
				dict => dict.terms.andLowerCase
			)} ${lastItem}`;
		}

		return selectedItems;
	}, [open]);

	const title = `${translate(dict => dict.terms.delete)} ${selectedItems}?`;
	const calculationRulesMessage = variablesSelected
		? translate(
				dict =>
					dict.variablesPage.deleteVariablesSelectedTableRowsModal.calculationRulesMessage
		  )
		: '';
	const message = `${translate(
		dict => dict.variablesPage.deleteVariablesSelectedTableRowsModal.beforeVariable
	)} ${selectedItems} ${calculationRulesMessage}${translate(
		dict => dict.variablesPage.deleteVariablesSelectedTableRowsModal.afterVariable
	)}`;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: deletingSelected,
				onClick: handleDelete
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{message}
				{'\n\n'}
				<b>{translate(dict => dict.terms.deleteWarningMessage)}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

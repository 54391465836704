import styled from 'styled-components';

export const RadioWrapper = styled.div`
	> div {
		> div:nth-child(2) {
			> div:not(:last-child) {
				margin-bottom: 0.4rem;
			}
		}
	}
`;

export const InfoWrapper = styled.div`
	margin-top: auto;
`;

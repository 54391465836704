import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getEntryFile,
	selectEntryFile,
	StoredEntryFile,
	LocalEntryFile
} from 'store/data/entries';

interface Props {
	id: string;
}

export function useEntryFile({
	id
}: Props): OperationResult<StoredEntryFile | LocalEntryFile | null> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectEntryFile(state.data.entries, id));

	const [{ loading, error }] = useActivity(ActionTypes.GET_ENTRY_FILE, id);

	const fetched = data !== null;

	function handler() {
		dispatch(getEntryFile(id));
	}

	return [{ data, loading, error, fetched }, handler];
}

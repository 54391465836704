import styled, { css } from 'styled-components';

interface ContainerProps {
	faded: boolean;
}

export const Container = styled.div<ContainerProps>`
	${({ faded }) => css`
		${faded &&
		css`
			opacity: 0.5;
			transition: 'opacity 0.1s ease-out';
			pointerevents: 'none';
		`}
	`};
`;

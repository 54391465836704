import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes as AccountActionTypes } from 'store/account/subscription';
import {
	selectHelpTopics,
	getHelpTopics,
	ActionTypes as HelpActionTypes,
	HelpTopicList
} from 'store/data/help';
import { useDispatch, usePrevious, useSelector } from 'hooks/utils';
import { useActivities } from 'hooks/store/utils/useActivities';
import { useAccount } from 'hooks/store';

export function useHelpTopics(): OperationResult<HelpTopicList> {
	const dispatch = useDispatch();

	const [
		{
			data: { languageCode },
			fetched: isAccountFetched
		}
	] = useAccount();

	const data = useSelector(state => selectHelpTopics(state.data.help));

	const fetched = useSelector(state => state.data.help.fetched);

	const [{ loading, error }] = useActivities([
		AccountActionTypes.GET_ACCOUNT,
		HelpActionTypes.GET_HELP_TOPICS
	]);

	const prevLanguageCode = usePrevious(languageCode);
	useEffect(() => {
		if (prevLanguageCode !== undefined && prevLanguageCode !== languageCode) handler();
	}, [languageCode]);

	useEffect(() => {
		if (!loading && !error && isAccountFetched && !fetched) handler();
	}, [loading, error, isAccountFetched, fetched]);

	function handler() {
		dispatch(getHelpTopics());
	}

	return [{ data, loading, error, fetched }, handler];
}

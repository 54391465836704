import {
	addVariableGroupToSet,
	AddVariableGroupToSetAction,
	ActionTypes
} from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionPayload } from 'store/types';

export function useAddVariableGroupToSet(activityPayload?: {
	groupName: string;
}): Operation<(payload: ActionPayload<AddVariableGroupToSetAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(
		ActionTypes.ADD_VARIABLE_GROUP_TO_SET,
		activityPayload
	);

	function handler(payload: ActionPayload<AddVariableGroupToSetAction>) {
		dispatch(addVariableGroupToSet(payload));
	}

	return [{ loading, error }, handler];
}

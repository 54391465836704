import type { ProjectMetadata } from 'store/data/projects';
import type { SubscriptionUser } from '../subscription';
import type { ProjectOwnerDetails } from 'api/data/projects';
import { ProjectStatus, ProjectType } from 'types/data/projects/constants';
import type { GenericMap } from 'types/maps';
import type { Action } from 'types/store/types';

export enum ActionTypes {
	// ENTERPRISE
	UPDATE_ENTERPRISE_USER = 'data/account/enterprise/UPDATE_SUBSCRIPTION_USER',
	DEACTIVATE_USER_ACCOUNT = 'data/account/enterprise/DEACTIVATE_USER_ACCOUNT',
	REACTIVATE_USER_ACCOUNT = 'data/account/enterprise/REACTIVATE_USER_ACCOUNT',
	GET_ALL_ACTIVE_ENTERPRISE_ORGANIZATION_USERS = 'data/account/enterprise/GET_ALL_ACTIVE_ENTERPRISE_ORGANIZATION_USERS',
	GET_LIST_OF_OWNED_PROJECTS = 'data/account/enterprise/GET_LIST_OF_OWNED_PROJECTS',
	GET_ALL_ENTERPRISE_OWNED_PROJECTS = 'data/account/enterprise/GET_ALL_ENTERPIRSE_OWNED_PROJECTS',
	SET_ORGANIZATION_ACCESS_RIGHTS = 'data/account/enterprise/SET_ORGANIZATION_ACCESS_RIGHTS',

	// ROLES
	GET_ENTERPRISE_ROLES = 'store/account/enterprise/GET_ENTERPRISE_ROLES',
	ADMIN_SET_ROLES_SEARCH_TERM = 'store/account/enterprise/ADMIN_SET_ROLES_SEARCH_TERM',
	ADMIN_SET_ROLE_ID = 'store/account/enterprise/ADMIN_SET_ROLE_ID',
	CREATE_ENTERPRISE_ROLE = 'store/account/enterprise/CREATE_ENTERPRISE_ROLE',
	UPDATE_ENTERPRISE_ROLE = 'store/account/enterprise/UPDATE_ENTERPRISE_ROLE',
	DELETE_ENTERPRISE_ROLE = 'store/account/enterprise/DELETE_ENTERPRISE_ROLE'
}

export type State = {
	allEnterpriseProjects: EnterpriseProject[];
	organizationAccessRights?: OrganizationAccessRights;

	roles: RolesState;
};

export interface EnterpriseProject {
	projectId: string;
	projectName: string;
	status: ProjectStatus;
	description: string;
	projectOwnerDetails: ProjectOwnerDetails;
	organizations: [];
	collaborators: number;
	fileIds: [];
	metadata?: ProjectMetadata;
}

export interface OwnedProject {
	description: string;
	fileIds: string[];
	organizations: string[];
	projectId: string;
	projectIdLong: number;
	projectName: string;
	projectType: ProjectType;
	collaborators?: number;
}

export interface OrganizationAccessRights {
	accessExportDelegation: boolean;
}

export interface RolesState {
	byId: GenericMap<EnterpriseRole>;
	metadata: {
		searchTerm: string;
		roleId: string | null;
		fetched: boolean;
	};
}

export interface GeneralRolePermissions {
	edit: boolean;
	delete: boolean;
	assignAndSet: boolean;
	remove: boolean;
}

export interface UserManagement {
	addUser: boolean;
	editUser: boolean;
	deactivateUser: boolean;
	reactivateUser: boolean;
	resetPassword: boolean;
}

export interface UserActivity {
	viewProject: boolean;
	transferOwnership: boolean;
	// privateProjects: boolean;
}

export interface EnterpriseAdminRoles {
	createEnterpriseRoles: boolean;
	// viewFeaturePermission: boolean;
}

export type ModulesPermissions =
	| UserManagement
	| UserActivity
	| EnterpriseAdminRoles
	| GeneralRolePermissions;

export type RolePermissionKey =
	| keyof UserManagement
	| keyof UserActivity
	| keyof EnterpriseAdminRoles
	| keyof GeneralRolePermissions;

export interface RolePermissions {
	userManagement: UserManagement;
	userActivity: UserActivity;
	enterpriseAdminRoles: EnterpriseAdminRoles;
	// generalRolePermissions: GeneralRolePermissions;
}

export interface EnterpriseRole {
	roleId: string;
	roleDescription: string;
	roleName: string;
	permissions: RolePermissions;
}
export type NewEnterpriseRole = Omit<EnterpriseRole, 'roleId'>;

export type APIEnterpriseRolePermissions = UserManagement & UserActivity & EnterpriseAdminRoles;

export type EnterpriseRolePermissions = GeneralRolePermissions & APIEnterpriseRolePermissions;

export type UpdateEnterpriseUserAction = Action<
	ActionTypes.UPDATE_ENTERPRISE_USER,
	{
		user: SubscriptionUser;
	}
>;

export type DeactivateUserAccountAction = Action<
	ActionTypes.DEACTIVATE_USER_ACCOUNT,
	{
		userId: string;
	}
>;

export type ReactivateUserAccountAction = Action<
	ActionTypes.REACTIVATE_USER_ACCOUNT,
	{
		userId: string;
	}
>;

export type GetAllEnterpriseOwnedProjectsAction = Action<
	ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS,
	{
		projects: EnterpriseProject[];
	}
>;

export type GetEnterpriseRolesAction = Action<
	ActionTypes.GET_ENTERPRISE_ROLES,
	{
		roles: EnterpriseRole[];
	}
>;

export type CreateEnterpriseRoleAction = Action<
	ActionTypes.CREATE_ENTERPRISE_ROLE,
	{
		role: EnterpriseRole;
	}
>;

export type UpdateEnterpriseRoleAction = Action<
	ActionTypes.UPDATE_ENTERPRISE_ROLE,
	{
		role: EnterpriseRole;
	}
>;

export type DeleteEnterpriseRoleAction = Action<
	ActionTypes.DELETE_ENTERPRISE_ROLE,
	{
		roleId: string;
	}
>;

export type SetAdminRolesSearchTermAction = Action<
	ActionTypes.ADMIN_SET_ROLES_SEARCH_TERM,
	{
		term: string;
	}
>;
export type SetAdminRoleIdAction = Action<
	ActionTypes.ADMIN_SET_ROLE_ID,
	{
		roleId: string | null;
	}
>;

export type SetOrganizationAccessRights = Action<
	ActionTypes.SET_ORGANIZATION_ACCESS_RIGHTS,
	{
		organizationAccessRights: OrganizationAccessRights;
	}
>;

export type Actions =
	| UpdateEnterpriseUserAction
	| DeactivateUserAccountAction
	| ReactivateUserAccountAction
	| GetAllEnterpriseOwnedProjectsAction
	| SetOrganizationAccessRights
	// Roles
	| GetEnterpriseRolesAction
	| CreateEnterpriseRoleAction
	| UpdateEnterpriseRoleAction
	| DeleteEnterpriseRoleAction
	| SetAdminRolesSearchTermAction
	| SetAdminRoleIdAction;

import { useEffect, useState } from 'react';
import { AdminUserForm } from 'components/Admin';
import { Svgs } from 'environment';
import { Wrapper, Container, Header, HeaderGroup, BackArrowContainer } from './styles';
import { useParams } from 'react-router-dom';
import { Icon } from 'components/UI/Icons';
import { AdvancedMenu } from 'components/UI/Interactables/AdvancedMenu';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useAdminUserDetails,
	useAdminUsersUserId,
	useAdminRemoveUser,
	useAdminUserPasswordReset,
	useAdminUserMFAReset
} from 'hooks/store';
import { usePrevious } from 'hooks/utils';

export function AdminUserDetailsPage() {
	const { replace, navigate, routes } = useNavigation();
	const { translate } = useTranslation();

	const { userId: urlUserId } = useParams<{ userId: string }>();

	const [{ data: user, error: errorGettingAdminUser }] = useAdminUserDetails();

	const [userId, setAdminUserId] = useAdminUsersUserId();
	const [{ loading: removingUser }, removeUser] = useAdminRemoveUser();
	const [{ loading: resettingPassword, error: errorResettingPassword }, resetPassword] =
		useAdminUserPasswordReset();
	const [{ loading: resettingMFA, error: errorResettingMFA }, resetMFA] = useAdminUserMFAReset();

	const [resetPasswordModal, setResetPasswordModal] = useState(false);
	const [resetMFAModal, setResetMFAModal] = useState(false);
	const [removeUserModal, setRemoveUserModal] = useState(false);

	useEffect(() => {
		if (errorGettingAdminUser) replace(routes.admin.view);

		return () => {
			setAdminUserId(null);
		};
	}, [errorGettingAdminUser]);

	const prevUserId = usePrevious(userId);
	const prevUrlUserId = usePrevious(urlUserId);
	useEffect(() => {
		const userIdChanged = prevUserId !== undefined && prevUserId !== userId;
		const urlUserIdChanged = prevUrlUserId !== undefined && prevUrlUserId !== urlUserId;

		// Set `userId` on reload
		if (urlUserId && userId === null) return setAdminUserId(urlUserId);

		// Sync `urlUserId` when `userId` changes
		if (userIdChanged && userId && !urlUserIdChanged) {
			// DEBOUNCE IN JS CALL STACK
			const timeout = setTimeout(() => {
				replace(routes.admin.details(userId));
			}, 100);

			return () => clearTimeout(timeout);
		}
	}, [userId, urlUserId]);

	// RESET PASSWORD EFFECT
	const wasResettingPassword = usePrevious(resettingPassword);
	useEffect(() => {
		if (wasResettingPassword && !resettingPassword && !errorResettingPassword)
			setResetPasswordModal(false);
	}, [resettingPassword, errorResettingPassword]);

	// RESET PASSWORD EFFECT
	const wasResettingMFA = usePrevious(resettingMFA);
	useEffect(() => {
		if (wasResettingMFA && !resettingMFA && !errorResettingMFA) setResetMFAModal(false);
	}, [resettingMFA, errorResettingMFA]);

	const userOptions = [
		{
			label: translate(({ admin }) => admin.users.details.resetPassword),
			handler: () => setResetPasswordModal(true)
		},
		{
			label: translate(({ admin }) => admin.users.details.resetMFA),
			handler: () => setResetMFAModal(true)
		}
	];

	if (!user) return null;

	return (
		<Wrapper>
			<Container>
				<HeaderGroup>
					<Header>
						<BackArrowContainer>
							<Icon
								customSize={4}
								svg={Svgs.ChevronLeft}
								variant={v => v.button}
								onClick={() => navigate(routes.admin.view)}
							/>
						</BackArrowContainer>
						<Typography.H4>
							{translate(({ admin }) => admin.users.details.title)}
						</Typography.H4>
					</Header>
					<AdvancedMenu offset={{ top: 20, left: -180 }} items={userOptions} />
				</HeaderGroup>
			</Container>

			{/* FORM */}
			<AdminUserForm user={user} setRemoveUserModal={setRemoveUserModal} />

			{/* 
				=============
					MODALS
				=============
			*/}

			{/* REMOVE USER MODAL */}
			<Modal
				size={s => s.s}
				title={translate(({ promptToDelete }) => promptToDelete.title)}
				visible={removeUserModal}
				primary={{
					label: translate(({ buttons }) => buttons.remove),
					loading: removingUser,
					warning: true,
					onClick: removeUser
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: () => setRemoveUserModal(false)
				}}
				onClose={() => setRemoveUserModal(false)}
				enterAsPrimaryOnClick
				close
			>
				<Typography.Paragraph>
					{translate(
						({ promptToDelete }) => promptToDelete.description.specificUser,
						false,
						{
							user: user.emailAddress
						}
					)}
				</Typography.Paragraph>
			</Modal>

			{/* RESET PASSWORD MODAL */}
			<Modal
				size={s => s.s}
				title={translate(({ admin }) => admin.users.details.resetPassword)}
				visible={resetPasswordModal}
				primary={{
					label: translate(({ buttons }) => buttons.reset),
					loading: resettingPassword,
					warning: true,
					onClick: resetPassword
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: () => setResetPasswordModal(false)
				}}
				onClose={() => setResetPasswordModal(false)}
				enterAsPrimaryOnClick
				close
			>
				<Typography.Paragraph>
					{translate(({ admin }) => admin.users.details.resetPasswordParagraph)}
				</Typography.Paragraph>
			</Modal>

			{/* RESET MFA MODAL */}
			<Modal
				size={s => s.s}
				title={translate(({ admin }) => admin.users.details.resetMFA)}
				visible={resetMFAModal}
				primary={{
					label: translate(({ buttons }) => buttons.reset),
					loading: resettingMFA,
					warning: true,
					onClick: resetMFA
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: () => setResetMFAModal(false)
				}}
				onClose={() => setResetMFAModal(false)}
				enterAsPrimaryOnClick
				close
			>
				<Typography.Paragraph>
					{translate(({ admin }) => admin.users.details.resetMFAParagraph)}
				</Typography.Paragraph>
			</Modal>
		</Wrapper>
	);
}

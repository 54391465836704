import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { setFormElementText, SetFormElementTextAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormElementText(): LocalOperation<
	(payload: ActionPayload<SetFormElementTextAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<SetFormElementTextAction>) {
		dispatch(setFormElementText(payload));
	}

	return handler;
}

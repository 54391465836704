import { ContextType, useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { Blocker, History, Transition } from 'history';
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext
} from 'react-router-dom';

interface Navigator extends BaseNavigator {
	block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & { navigator: Navigator };

function useBlocker(blocker: Blocker, when = true) {
	const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) return;

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				}
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
}

export function usePrompt_NEW(when = true) {
	const rejectCallback = useRef<() => void>();
	const resolveCallback = useRef<() => void>();

	const [visible, setVisible] = useState(false);

	const showPrompt = useCallback(
		() =>
			new Promise<void>((resolve, reject) => {
				rejectCallback.current = reject;
				resolveCallback.current = resolve;

				setVisible(true);
			}),
		[]
	);

	const reject = useCallback(() => {
		if (rejectCallback.current) rejectCallback.current();

		setVisible(false);
	}, []);

	const confirm = useCallback(() => {
		if (resolveCallback.current) resolveCallback.current();

		setVisible(false);
	}, []);

	const blocker = useCallback(
		async (tx: Transition) => {
			try {
				await showPrompt();

				tx.retry();
			} catch (e) {
				// Do nothing
			}
		},
		[showPrompt]
	);

	useBlocker(blocker, when);

	return {
		promptVisible: visible,
		rejectPrompt: reject,
		confirmPrompt: confirm
	};
}

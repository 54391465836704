import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	ActionTypes,
	getRoleTemplateShareList,
	isShareListDataFetched,
	selectShareList,
	ShareListData
} from 'store/data/roles';

interface Props {
	templateRoleId: string;
}

export function useRoleTemplateShareList(input: Props): OperationResult<ShareListData> {
	const dispatch = useDispatch();

	const shareListData = useSelector(state =>
		selectShareList(state.data.roles, { templateRoleId: input.templateRoleId })
	);
	const fetched = useSelector(state =>
		isShareListDataFetched(state.data.roles, { templateRoleId: input.templateRoleId })
	);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_ROLE_TEMPLATE_SHARE_LIST);

	// REMOVE DUPLICATE IDS

	const data: ShareListData = {
		projectShareList: {
			current: shareListData?.projectShareList.current ?? [],
			initial: shareListData?.projectShareList.initial ?? []
		},
		userShareList: {
			current: shareListData?.userShareList.current ?? [],
			initial: shareListData?.userShareList.initial ?? []
		},
		fetched
	};

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getRoleTemplateShareList({ templateRoleId: input.templateRoleId }));
	}

	return [{ data, loading, error, fetched }, handler];
}

import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';
import { resetStore } from 'store/auth';

export function useResetStore(): LocalOperation {
	const dispatch = useDispatch();

	function handler() {
		dispatch(resetStore());
	}

	return handler;
}

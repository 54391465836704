import { ProjectOwnership } from 'types/data/projects/constants';
import { Select } from 'components/UI/Interactables/Select';
import { MediaQueries } from 'environment';
import { Container, DivTab as Tab, NormalTab, Text } from '../Tab.style';
import { useMatchProms } from 'hooks/navigation';
import { useTranslation, useActiveProjectOwnership } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

const tabsProps = {
	width: 14.3,
	min_width: 14.3,
	underline_width: 7.1
};

export function ProjectListTabs() {
	const matchProms = useMatchProms();

	const { translate } = useTranslation();

	const [{ data: activeOwnership }, setActiveOwnership] = useActiveProjectOwnership();

	function isActive(tab: ProjectOwnership) {
		return activeOwnership === tab;
	}

	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const menuTabs = [
		{
			label: matchProms
				? translate(dict => dict.promsList.allPromsHeader)
				: translate(dict => dict.projects.allProjectsHeader),
			value: ProjectOwnership.All
		},
		{
			label: matchProms
				? translate(dict => dict.promsList.myPromsHeader)
				: translate(dict => dict.projects.myProjectsHeader),
			value: ProjectOwnership.Own
		},
		{
			label: translate(dict => dict.projects.sharedWithMeHeader),
			value: ProjectOwnership.SharedWithMe
		}
	];

	return (
		<Container>
			{isPhone ? (
				<NormalTab>
					<Select
						title={translate(dict => dict.terms.menu)}
						className="dropdown-tab"
						items={menuTabs}
						type={s => s.Tab(true)}
						activeItem={item => isActive(item.value as Partial<ProjectOwnership>)}
						onSelect={item =>
							setActiveOwnership(item.value as Partial<ProjectOwnership>)
						}
					/>
				</NormalTab>
			) : (
				<>
					{menuTabs.map(tab => (
						<Tab
							key={tab.label}
							active={isActive(tab.value)}
							onClick={() => setActiveOwnership(tab.value)}
							{...tabsProps}
						>
							<div>
								<Text>{tab.label}</Text>
							</div>
						</Tab>
					))}
				</>
			)}
		</Container>
	);
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { renameTemplate, RenameTemplateAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useRenameTemplate(): LocalOperation<
	(payload: ActionPayload<RenameTemplateAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<RenameTemplateAction>) {
		dispatch(renameTemplate(payload));
	}

	return handler;
}

import { TemplatePageViews } from 'types/index';
import { State } from './types';

const initialState: State = {
	projectId: null,
	templateId: null,
	sourceCreateTemplateId: null,
	templatesById: {},
	editableTemplatesIds: [],
	sharedWithUser: {
		fetched: false,
		ids: []
	},
	sharedWithProject: {
		fetched: false,
		ids: []
	},
	sharedWithPublic: {
		fetched: false,
		ids: []
	},
	metadata: {
		pageView: TemplatePageViews.MyTemplates,
		searchTerm: '',
		publishedWithUnsupported: false
	}
};

export default initialState;

import type { GenericMap } from 'types/index';
import type { Action } from 'types/store/types';
import { JADBioAnalysisModels, type JADBioOutputMap } from 'api/data/analyses';

export type State = {
	tokenValidMock: boolean;
	projects: {
		byProjectId: GenericMap<JADBioProject>;
	};
} | null;

export interface JADBioProject {
	jadBioProjectInitialized: boolean;
	jadBioProjectId: string;
}

export enum ActionTypes {
	VALIDATE_JADBIO_TOKEN = 'data/addons/VALIDATE_JADBIO_TOKEN',
	SET_TOKEN_VALID = 'data/addons/SET_TOKEN_VALID',
	LOG_IN_TO_JADBIO = 'data/addons/LOG_IN_TO_JADBIO',
	LOG_OUT_FROM_JADBIO = 'data/addons/LOG_OUT_FROM_JADBIO',
	INITIATE_JADBIO_PROJECT = 'data/addons/INITIATE_JADBIO_PROJECT',
	INITIATE_JADBIO_DATASET = 'data/addons/INITIATE_JADBIO_DATASET',
	GET_JADBIO_PROJECT_ID = 'data/addons/GET_JADBIO_PROJECT_ID',
	CLEAR_JADBIO_OUTPUT_MODEL = 'data/analyses/CLEAR_JADBIO_OUTPUT_MODEL',
	INITIATE_JADBIO_CLASSIFICATION_MODEL = 'data/analyses/INITIATE_JADBIO_CLASSIFICATION_MODEL',
	INITIATE_JADBIO_SURVIVAL_ANALYSIS_MODEL = 'data/analyses/INITIATE_JADBIO_SURVIVAL_ANALYSIS_MODEL',
	INITIATE_JADBIO_REGRESSION_MODEL = 'data/analyses/INITIATE_JADBIO_REGRESSION_MODEL',
	GET_JADBIO_ANALYSIS_RESULT = 'data/analyses/GET_JADBIO_ANALYSIS_RESULT',
	GET_JADBIO_ANALYSIS_ERROR = 'data/analyses/GET_JADBIO_ANALYSIS_ERROR',

	SET_JADBIO_ANALYSIS_PROGRESS = 'data/analyses/SET_JADBIO_ANALYSIS_PROGRESS',
	PAUSE_JADBIO_ANALYSIS_PROGRESS = 'data/analyses/PAUSE_JADBIO_ANALYSIS_PROGRESS',
	STOP_JADBIO_ANALYSIS_PROGRESS = 'data/analyses/STOP_JADBIO_ANALYSIS_PROGRESS',
	ABORT_JADBIO_ANALYSIS_GENERATION = 'data/analyses/ABORT_JADBIO_ANALYSIS_GENERATION',
	DELETE_JADBIO_ANALYSES = 'data/analyses/DELETE_JADBIO_ANALYSES'
}

export type LogInToJADBioAction = Action<ActionTypes.LOG_IN_TO_JADBIO>;

export type SetTokenValidAction = Action<
	ActionTypes.SET_TOKEN_VALID,
	{
		valid: boolean;
	}
>;

export type LogOutFromJADBioAction = Action<ActionTypes.LOG_OUT_FROM_JADBIO>;

export type InitiateJADBioProjectAction = Action<
	ActionTypes.INITIATE_JADBIO_PROJECT,
	{
		projectId: string;
		jadBioProject: JADBioProject;
	}
>;

export type InitiateJADBioDatasetAction = Action<
	ActionTypes.INITIATE_JADBIO_DATASET,
	{
		analysisId: string;
		jadBioDatasetId: string;
	}
>;

export type GetJADBioResultAction = Action<
	ActionTypes.GET_JADBIO_ANALYSIS_RESULT,
	{
		analysisId: string;
		jadBioOutput: JADBioOutputMap;
	}
>;

export type GetJADBioAnalysisErrorAction = Action<
	ActionTypes.GET_JADBIO_ANALYSIS_ERROR,
	{
		analysisId: string;
		model: JADBioAnalysisModels;
	}
>;

export type PauseJADBioProgressAction = Action<
	ActionTypes.PAUSE_JADBIO_ANALYSIS_PROGRESS,
	{
		analysisId: string;
		model: JADBioAnalysisModels;
		pauseProgress: boolean;
	}
>;

export type SetJADBioProgressAction = Action<
	ActionTypes.SET_JADBIO_ANALYSIS_PROGRESS,
	{
		analysisId: string;
		progress: number;
		model: JADBioAnalysisModels;
		jadBioAnalysisId?: string;
	}
>;

export type ClearJADBioOutputModelAction = Action<
	ActionTypes.CLEAR_JADBIO_OUTPUT_MODEL,
	{
		analysisId: string;
		model: JADBioAnalysisModels;
	}
>;

export type StopJADBioAnalysisProgressAction = Action<
	ActionTypes.STOP_JADBIO_ANALYSIS_PROGRESS,
	{
		analysisId: string;
		model: JADBioAnalysisModels;
	}
>;

export type Actions =
	| LogInToJADBioAction
	| LogOutFromJADBioAction
	| InitiateJADBioProjectAction
	| InitiateJADBioDatasetAction
	| GetJADBioResultAction
	| SetJADBioProgressAction
	| PauseJADBioProgressAction
	| SetTokenValidAction
	| GetJADBioAnalysisErrorAction
	| ClearJADBioOutputModelAction
	| StopJADBioAnalysisProgressAction;

import styled from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.maxWidth.sm} {
		width: 100%;
	}
`;

export const ScrollToTopIcon = styled(DefaultIcon)`
	position: fixed;
	bottom: 12rem;
	right: 3.3rem;
`;

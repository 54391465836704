// Generated from LAL.g4 by ANTLR 4.9.0-SNAPSHOT

import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { FailedPredicateException } from 'antlr4ts/FailedPredicateException';
import { NoViableAltException } from 'antlr4ts/NoViableAltException';
import { Parser } from 'antlr4ts/Parser';
import { ParserRuleContext } from 'antlr4ts/ParserRuleContext';
import { ParserATNSimulator } from 'antlr4ts/atn/ParserATNSimulator';
import { RecognitionException } from 'antlr4ts/RecognitionException';
import { RuleContext } from 'antlr4ts/RuleContext';
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';
import { Token } from 'antlr4ts/Token';
import { TokenStream } from 'antlr4ts/TokenStream';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';

import { LALListener } from './LALListener';
import { LALVisitor } from './LALVisitor';

export class LALParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly POW = 3;
	public static readonly MINUS = 4;
	public static readonly PLUS = 5;
	public static readonly MULT = 6;
	public static readonly DIV = 7;
	public static readonly SQRT = 8;
	public static readonly LOG = 9;
	public static readonly LN = 10;
	public static readonly PI = 11;
	public static readonly E = 12;
	public static readonly INT = 13;
	public static readonly FLOAT = 14;
	public static readonly ID = 15;
	public static readonly WS = 16;
	public static readonly RULE_prog = 0;
	public static readonly RULE_expr = 1;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = ['prog', 'expr'];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined,
		"'('",
		"')'",
		"'^'",
		"'-'",
		"'+'",
		"'*'",
		"'/'",
		"'sqrt'",
		"'log'",
		"'ln'",
		undefined,
		"'e'",
		undefined,
		undefined,
		undefined,
		"' '"
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined,
		undefined,
		undefined,
		'POW',
		'MINUS',
		'PLUS',
		'MULT',
		'DIV',
		'SQRT',
		'LOG',
		'LN',
		'PI',
		'E',
		'INT',
		'FLOAT',
		'ID',
		'WS'
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
		LALParser._LITERAL_NAMES,
		LALParser._SYMBOLIC_NAMES,
		[]
	);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return LALParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string {
		return 'LAL.g4';
	}

	// @Override
	public get ruleNames(): string[] {
		return LALParser.ruleNames;
	}

	// @Override
	public get serializedATN(): string {
		return LALParser._serializedATN;
	}

	protected createFailedPredicateException(
		predicate?: string,
		message?: string
	): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(LALParser._ATN, this);
	}
	// @RuleVersion(0)
	public prog(): ProgContext {
		const _localctx: ProgContext = new ProgContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, LALParser.RULE_prog);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 4;
				this.expr(0);
				this.state = 5;
				this.match(LALParser.EOF);
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expr(): ExprContext;
	public expr(_p: number): ExprContext;
	// @RuleVersion(0)
	public expr(_p?: number): ExprContext {
		if (_p === undefined) {
			_p = 0;
		}

		const _parentctx: ParserRuleContext = this._ctx;
		const _parentState: number = this.state;
		let _localctx: ExprContext = new ExprContext(this._ctx, _parentState);
		let _prevctx: ExprContext = _localctx;
		const _startState = 2;
		this.enterRecursionRule(_localctx, 2, LALParser.RULE_expr, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 34;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
					case LALParser.MINUS:
						{
							this.state = 8;
							this.match(LALParser.MINUS);
							this.state = 9;
							this.expr(13);
						}
						break;
					case LALParser.SQRT:
						{
							this.state = 10;
							this.match(LALParser.SQRT);
							this.state = 11;
							this.match(LALParser.T__0);
							this.state = 12;
							this.expr(0);
							this.state = 13;
							this.match(LALParser.T__1);
						}
						break;
					case LALParser.LOG:
						{
							this.state = 15;
							this.match(LALParser.LOG);
							this.state = 16;
							this.match(LALParser.T__0);
							this.state = 17;
							this.expr(0);
							this.state = 18;
							this.match(LALParser.T__1);
						}
						break;
					case LALParser.LN:
						{
							this.state = 20;
							this.match(LALParser.LN);
							this.state = 21;
							this.match(LALParser.T__0);
							this.state = 22;
							this.expr(0);
							this.state = 23;
							this.match(LALParser.T__1);
						}
						break;
					case LALParser.INT:
						{
							this.state = 25;
							this.match(LALParser.INT);
						}
						break;
					case LALParser.FLOAT:
						{
							this.state = 26;
							this.match(LALParser.FLOAT);
						}
						break;
					case LALParser.ID:
						{
							this.state = 27;
							this.match(LALParser.ID);
						}
						break;
					case LALParser.PI:
						{
							this.state = 28;
							this.match(LALParser.PI);
						}
						break;
					case LALParser.E:
						{
							this.state = 29;
							this.match(LALParser.E);
						}
						break;
					case LALParser.T__0:
						{
							this.state = 30;
							this.match(LALParser.T__0);
							this.state = 31;
							this.expr(0);
							this.state = 32;
							this.match(LALParser.T__1);
						}
						break;
					default:
						throw new NoViableAltException(this);
				}
				this._ctx._stop = this._input.tryLT(-1);
				this.state = 47;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
				while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						if (this._parseListeners != null) {
							this.triggerExitRuleEvent();
						}
						_prevctx = _localctx;
						{
							this.state = 45;
							this._errHandler.sync(this);
							switch (this.interpreter.adaptivePredict(this._input, 1, this._ctx)) {
								case 1:
									{
										_localctx = new ExprContext(_parentctx, _parentState);
										this.pushNewRecursionContext(
											_localctx,
											_startState,
											LALParser.RULE_expr
										);
										this.state = 36;
										if (!this.precpred(this._ctx, 12)) {
											throw this.createFailedPredicateException(
												'this.precpred(this._ctx, 12)'
											);
										}
										this.state = 37;
										this.match(LALParser.POW);
										this.state = 38;
										this.expr(13);
									}
									break;

								case 2:
									{
										_localctx = new ExprContext(_parentctx, _parentState);
										this.pushNewRecursionContext(
											_localctx,
											_startState,
											LALParser.RULE_expr
										);
										this.state = 39;
										if (!this.precpred(this._ctx, 11)) {
											throw this.createFailedPredicateException(
												'this.precpred(this._ctx, 11)'
											);
										}
										this.state = 40;
										_la = this._input.LA(1);
										if (!(_la === LALParser.MULT || _la === LALParser.DIV)) {
											this._errHandler.recoverInline(this);
										} else {
											if (this._input.LA(1) === Token.EOF) {
												this.matchedEOF = true;
											}

											this._errHandler.reportMatch(this);
											this.consume();
										}
										this.state = 41;
										this.expr(12);
									}
									break;

								case 3:
									{
										_localctx = new ExprContext(_parentctx, _parentState);
										this.pushNewRecursionContext(
											_localctx,
											_startState,
											LALParser.RULE_expr
										);
										this.state = 42;
										if (!this.precpred(this._ctx, 10)) {
											throw this.createFailedPredicateException(
												'this.precpred(this._ctx, 10)'
											);
										}
										this.state = 43;
										_la = this._input.LA(1);
										if (!(_la === LALParser.MINUS || _la === LALParser.PLUS)) {
											this._errHandler.recoverInline(this);
										} else {
											if (this._input.LA(1) === Token.EOF) {
												this.matchedEOF = true;
											}

											this._errHandler.reportMatch(this);
											this.consume();
										}
										this.state = 44;
										this.expr(11);
									}
									break;
							}
						}
					}
					this.state = 49;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
			case 1:
				return this.expr_sempred(_localctx as ExprContext, predIndex);
		}
		return true;
	}
	private expr_sempred(_localctx: ExprContext, predIndex: number): boolean {
		switch (predIndex) {
			case 0:
				return this.precpred(this._ctx, 12);

			case 1:
				return this.precpred(this._ctx, 11);

			case 2:
				return this.precpred(this._ctx, 10);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		'\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x125\x04\x02' +
		'\t\x02\x04\x03\t\x03\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03' +
		'\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03' +
		'\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03' +
		'\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05\x03%\n\x03\x03\x03\x03' +
		'\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x07\x030' +
		'\n\x03\f\x03\x0E\x033\v\x03\x03\x03\x02\x02\x03\x04\x04\x02\x02\x04\x02' +
		'\x02\x04\x03\x02\b\t\x03\x02\x06\x07\x02>\x02\x06\x03\x02\x02\x02\x04' +
		'$\x03\x02\x02\x02\x06\x07\x05\x04\x03\x02\x07\b\x07\x02\x02\x03\b\x03' +
		'\x03\x02\x02\x02\t\n\b\x03\x01\x02\n\v\x07\x06\x02\x02\v%\x05\x04\x03' +
		'\x0F\f\r\x07\n\x02\x02\r\x0E\x07\x03\x02\x02\x0E\x0F\x05\x04\x03\x02\x0F' +
		'\x10\x07\x04\x02\x02\x10%\x03\x02\x02\x02\x11\x12\x07\v\x02\x02\x12\x13' +
		'\x07\x03\x02\x02\x13\x14\x05\x04\x03\x02\x14\x15\x07\x04\x02\x02\x15%' +
		'\x03\x02\x02\x02\x16\x17\x07\f\x02\x02\x17\x18\x07\x03\x02\x02\x18\x19' +
		'\x05\x04\x03\x02\x19\x1A\x07\x04\x02\x02\x1A%\x03\x02\x02\x02\x1B%\x07' +
		'\x0F\x02\x02\x1C%\x07\x10\x02\x02\x1D%\x07\x11\x02\x02\x1E%\x07\r\x02' +
		'\x02\x1F%\x07\x0E\x02\x02 !\x07\x03\x02\x02!"\x05\x04\x03\x02"#\x07' +
		'\x04\x02\x02#%\x03\x02\x02\x02$\t\x03\x02\x02\x02$\f\x03\x02\x02\x02$' +
		'\x11\x03\x02\x02\x02$\x16\x03\x02\x02\x02$\x1B\x03\x02\x02\x02$\x1C\x03' +
		'\x02\x02\x02$\x1D\x03\x02\x02\x02$\x1E\x03\x02\x02\x02$\x1F\x03\x02\x02' +
		"\x02$ \x03\x02\x02\x02%1\x03\x02\x02\x02&'\f\x0E\x02\x02'(\x07\x05\x02" +
		'\x02(0\x05\x04\x03\x0F)*\f\r\x02\x02*+\t\x02\x02\x02+0\x05\x04\x03\x0E' +
		',-\f\f\x02\x02-.\t\x03\x02\x02.0\x05\x04\x03\r/&\x03\x02\x02\x02/)\x03' +
		'\x02\x02\x02/,\x03\x02\x02\x0203\x03\x02\x02\x021/\x03\x02\x02\x0212\x03' +
		'\x02\x02\x022\x05\x03\x02\x02\x0231\x03\x02\x02\x02\x05$/1';
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!LALParser.__ATN) {
			LALParser.__ATN = new ATNDeserializer().deserialize(
				Utils.toCharArray(LALParser._serializedATN)
			);
		}

		return LALParser.__ATN;
	}
}

export class ProgContext extends ParserRuleContext {
	public expr(): ExprContext {
		return this.getRuleContext(0, ExprContext);
	}
	public EOF(): TerminalNode {
		return this.getToken(LALParser.EOF, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return LALParser.RULE_prog;
	}
	// @Override
	public enterRule(listener: LALListener): void {
		if (listener.enterProg) {
			listener.enterProg(this);
		}
	}
	// @Override
	public exitRule(listener: LALListener): void {
		if (listener.exitProg) {
			listener.exitProg(this);
		}
	}
	// @Override
	public accept<Result>(visitor: LALVisitor<Result>): Result {
		if (visitor.visitProg) {
			return visitor.visitProg(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

export class ExprContext extends ParserRuleContext {
	public MINUS(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.MINUS, 0);
	}
	public expr(): ExprContext[];
	public expr(i: number): ExprContext;
	public expr(i?: number): ExprContext | ExprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExprContext);
		} else {
			return this.getRuleContext(i, ExprContext);
		}
	}
	public POW(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.POW, 0);
	}
	public MULT(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.MULT, 0);
	}
	public DIV(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.DIV, 0);
	}
	public PLUS(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.PLUS, 0);
	}
	public SQRT(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.SQRT, 0);
	}
	public LOG(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.LOG, 0);
	}
	public LN(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.LN, 0);
	}
	public INT(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.INT, 0);
	}
	public FLOAT(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.FLOAT, 0);
	}
	public ID(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.ID, 0);
	}
	public PI(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.PI, 0);
	}
	public E(): TerminalNode | undefined {
		return this.tryGetToken(LALParser.E, 0);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number {
		return LALParser.RULE_expr;
	}
	// @Override
	public enterRule(listener: LALListener): void {
		if (listener.enterExpr) {
			listener.enterExpr(this);
		}
	}
	// @Override
	public exitRule(listener: LALListener): void {
		if (listener.exitExpr) {
			listener.exitExpr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: LALVisitor<Result>): Result {
		if (visitor.visitExpr) {
			return visitor.visitExpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}

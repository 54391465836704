import { ProjectFile } from 'api/data/documents';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useRemoveDocument } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	file: ProjectFile;
	usedInEntries?: boolean;
	onClose: () => void;
	onDelete?: () => void;
}

export function DeleteDocumentModal({ file, usedInEntries, onClose, onDelete }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [{ loading: removingDocument, error: errorRemovingDocument }, removeDocument] =
		useRemoveDocument();

	useCompletedAction(removingDocument, errorRemovingDocument, () => {
		setNotification({
			message: translate(dict => dict.documents.deleteMessage)
		});
		onClose();
	});

	// TODO: could be moved outside and use only callback onDelete()
	function handleRemoveDocument() {
		if (usedInEntries && onDelete) {
			onDelete();
			onClose();
		} else if (file.fileId) {
			removeDocument(file.fileId);
		}
	}

	const fileNameWithExtention = `${file.fileLabel}.${file.mimeType}`;

	const title = `${translate(({ buttons }) => buttons.delete)} ${fileNameWithExtention}?`;

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(({ buttons }) => buttons.delete),
				loading: removingDocument,
				warning: true,
				onClick: handleRemoveDocument
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{translate(dict => dict.documents.list.remove)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { useRef, useState } from 'react';
import { Colors, Svgs } from 'environment';
import { StatusColor, STATUS_COLORS } from 'store/data/statuses';

import {
	Container,
	ColorPickerContainer,
	ColorPickerToggle,
	ColorChip,
	ColorPickerModal
} from './StatusColorPicker.style';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { useTranslation } from 'hooks/store';
import { useOutsideClick } from 'hooks/utils';

interface Props {
	color: StatusColor;
	onColorChange: (color: StatusColor) => void;
}

export function StatusColorPicker({ color, onColorChange }: Props) {
	const toggleRef = useRef<HTMLDivElement>(null);
	const modalRef = useRef<HTMLDivElement>(null);
	const { translate } = useTranslation();

	const [open, setOpen] = useState(false);

	useOutsideClick(() => setOpen(false), [toggleRef, modalRef]);

	return (
		<Container>
			<Typography.Caption
				marginOffset={{ right: 1.2 }}
				color={Colors.text.main}
				fontweight={w => w.medium}
			>
				{translate(dict => dict.terms.color)}
			</Typography.Caption>

			<ColorPickerContainer>
				<ColorPickerToggle ref={toggleRef} onClick={() => setOpen(state => !state)}>
					<ColorChip color={color} />
					<Icon svg={Svgs.ChevronDown} marginOffset={{ left: 0.4 }} propagate />
				</ColorPickerToggle>

				{open && (
					<ColorPickerModal ref={modalRef}>
						{STATUS_COLORS.map((statusColor, index) => (
							<ColorChip
								key={index}
								color={statusColor}
								onClick={() => {
									setOpen(false);
									onColorChange(statusColor);
								}}
								isActive={statusColor === color}
								inSelector
							>
								{statusColor === color && (
									<Icon svg={Svgs.Checkmark} customSize={1} />
								)}
							</ColorChip>
						))}
					</ColorPickerModal>
				)}
			</ColorPickerContainer>
		</Container>
	);
}

import { cloneDeep } from 'lodash';

import { ApiStatus, PartialApiStatus } from 'api/data/statuses';
import { Status, StatusColor, StatusColor_OLD } from 'store/data/statuses';
import { objectDifference } from 'helpers/objects';
import { hasMatches } from 'helpers/strings';

export function prepareApiStatus(status: Status): ApiStatus {
	const { name, label, description, statusColor, dueTimeUnit, dueTimeAmount, isDefault } = status;

	const apiStatus: ApiStatus = {
		statusName: name,
		statusLabel: label,
		description,
		statusColor: mapStatusColorToNewScheme(statusColor),
		dueTimeUnit,
		dueTimeAmount: dueTimeAmount !== null ? Number(dueTimeAmount) : null,
		default: !!isDefault
	};

	return apiStatus;
}

export function parseApiStatus(apiStatus: ApiStatus): Status {
	const {
		statusName,
		statusLabel,
		description,
		statusColor,
		dueTimeUnit,
		dueTimeAmount,
		default: isDefault
	} = apiStatus;

	const status: Status = {
		name: statusName,
		label: statusLabel,
		description,
		statusColor: mapStatusColorToNewScheme(statusColor),
		dueTimeUnit: dueTimeUnit ?? null,
		dueTimeAmount: dueTimeAmount?.toString() ?? null,
		...(isDefault && { isDefault })
	};

	return status;
}
/**
 * Used for granular updates - serves concurrency
 */
export function preparePartialApiStatus(initialStatus: Status, status: Status): PartialApiStatus {
	const updatedFields = objectDifference(
		{ ...initialStatus, isDefault: !!initialStatus.isDefault },
		status
	);

	const { label, description, statusColor, dueTimeUnit, dueTimeAmount, isDefault } =
		updatedFields;

	const apiStatus: PartialApiStatus = {
		statusName: status.name,
		...(label !== undefined && { statusLabel: label }),
		...(description !== undefined && { description }),
		...(statusColor !== undefined && {
			statusColor: mapStatusColorToNewScheme(statusColor)
		}),
		...(dueTimeUnit !== undefined && { dueTimeUnit }),
		...(dueTimeAmount !== undefined && {
			dueTimeAmount: dueTimeAmount !== null ? Number(dueTimeAmount) : null
		}),
		...(isDefault !== undefined && { default: isDefault })
	};

	return apiStatus;
}

function mapStatusColorToNewScheme(statusColor: StatusColor): StatusColor {
	const colorMap: Record<StatusColor_OLD, StatusColor> = {
		[StatusColor_OLD.Color1]: StatusColor.Color1,
		[StatusColor_OLD.Color2]: StatusColor.Color2,
		[StatusColor_OLD.Color3]: StatusColor.Color3,
		[StatusColor_OLD.Color4]: StatusColor.Color4,
		[StatusColor_OLD.Color5]: StatusColor.Color5,
		[StatusColor_OLD.Color6]: StatusColor.Color6,
		[StatusColor_OLD.Color7]: StatusColor.Color7,
		[StatusColor_OLD.Color8]: StatusColor.Color8,
		[StatusColor_OLD.Color9]: StatusColor.Color9,
		[StatusColor_OLD.Color10]: StatusColor.Color10,
		[StatusColor_OLD.Color11]: StatusColor.Color11,
		[StatusColor_OLD.Color12]: StatusColor.Color12,
		[StatusColor_OLD.Color13]: StatusColor.Color1,
		[StatusColor_OLD.Color14]: StatusColor.Color2,
		[StatusColor_OLD.Color15]: StatusColor.Color3,
		[StatusColor_OLD.Color16]: StatusColor.Color4,
		[StatusColor_OLD.Neutral]: StatusColor.Color1
	};

	return colorMap[statusColor as unknown as StatusColor_OLD] ?? statusColor;
}

export function filterStatusesBySearchTerm(statuses: Status[], searchTerm: string) {
	const isSearchTermValid = searchTerm.trim().length > 0;

	if (!isSearchTermValid) return statuses;

	function match(status: Status) {
		const keywords: string[] = [
			status.label,
			status.description,
			status.dueTimeUnit ?? '',
			status.dueTimeAmount ?? ''
		];

		return hasMatches({ searchTerm, keywords });
	}

	return cloneDeep(statuses).filter(match);
}

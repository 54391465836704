import { StatisticsTotals } from 'api/data/dashboard';
import { UserTag } from 'components/UI/UserCard/UserTag';
import { RESPONSE_RATE_CHART } from 'consts';
import { Colors, MediaQueries, Svgs } from 'environment';
import {
	LegendCircleFill,
	Icon,
	ResponseRateChartContainer,
	ChartFilledPercentage,
	CardView
} from './ResponseRateChart.style';
import { Flex } from 'components/UI/Flex';
import { Chart } from 'components/UI/Chart';
import { HSpacer } from 'components/UI/HSpacer';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

interface Props extends StatisticsTotals {
	totalPatients: number;
	totalCollaborators: number;
}

export function ResponseRateChart({
	totalPatients,
	totalCollaborators,
	totalSent,
	totalFilled,
	totalPartiallyFilled,
	ratioFilled,
	ratioPartiallyFilled,
	totalToBeSent
}: Props) {
	const { translate } = useTranslation();

	const filledPercentage = ratioFilled * 100;
	const partiallyFilledPercentage = ratioPartiallyFilled * 100;
	const sentPercentage = (totalSent * 100) / totalToBeSent;

	const chartOptions = {
		type: 'pie',
		plot: {
			valueBox: {
				visible: false
			},
			angleStart: 270,
			detach: false,
			slice: '100%',
			totals: [100],
			animation: {
				effect: 'ANIMATION_EXPAND_VERTICAL',
				method: 'ANIMATION_LINEAR',
				speed: 'ANIMATION_SLOW'
			}
		},
		legend: {
			visible: false
		},
		tooltip: {
			visible: false
		},
		plotarea: {
			margin: 'dynamic'
		},
		shapes: [
			{
				type: 'pie',
				alpha: 0.25,
				backgroundColor: RESPONSE_RATE_CHART[3],
				size: 118,
				slice: 114,
				x: 135,
				y: 135
			},
			{
				type: 'pie',
				alpha: 0.25,
				backgroundColor: RESPONSE_RATE_CHART[3],
				size: 100,
				slice: 96,
				x: 135,
				y: 135
			},
			{
				type: 'pie',
				alpha: 0.25,
				backgroundColor: RESPONSE_RATE_CHART[3],
				size: 81,
				slice: 77,
				x: 135,
				y: 135
			}
		]
	};

	const dataSeries = [
		{
			values: [filledPercentage],
			size: '100%',
			borderColor: Colors.surveyChartColors.green.light,
			borderWidth: '4px'
		},

		{
			values: [partiallyFilledPercentage],
			size: '85%',
			borderColor: Colors.surveyChartColors.yellow,
			borderWidth: '4px'
		},

		{
			values: [sentPercentage],
			size: '70%',
			borderColor: Colors.primary.normal,
			borderWidth: '4px'
		}
	];

	const smallerScreen = useMediaQuery(MediaQueries.maxWidth.lg);

	return (
		<ResponseRateChartContainer>
			<CardView align={a => a.center}>
				<Flex
					style={{
						transform: 'scale(-1,1)',
						position: 'relative'
					}}
					column
					justify={j => j.evenly}
					align={a => a.center}
				>
					<Chart
						type={t => t.Pie}
						series={dataSeries}
						options={chartOptions}
						effect={e => e.ExpandVertical}
						method={m => m.Linear}
						speed={s => s.Slower}
						height={270}
						width={270}
					/>
					<ChartFilledPercentage>
						{Number(filledPercentage.toFixed(1)) + '%'}
					</ChartFilledPercentage>
				</Flex>
				<HSpacer size={s => s.m} />
				<Flex column>
					<Flex align={a => a.center} fullWidth>
						<LegendCircleFill color={Colors.primary.normal} />
						<HSpacer size={s => s.m} />
						<Flex column>
							<Typography.H4>{totalSent}</Typography.H4>
							<Typography.Caption>
								{translate(dict => dict.responseRateChart.totalFormsSent)}
							</Typography.Caption>
						</Flex>
					</Flex>
					<Spacer size={s => s.s} />
					<Flex align={a => a.center} fullWidth>
						<LegendCircleFill color={Colors.surveyChartColors.yellow} />
						<HSpacer size={s => s.m} />
						<Flex column fullWidth>
							<Typography.H4>{totalPartiallyFilled}</Typography.H4>
							<Typography.Caption>
								{translate(
									dict => dict.responseRateChart.totalFormsPartiallyFilled
								)}
							</Typography.Caption>
						</Flex>
					</Flex>
					<Spacer size={s => s.s} />
					<Flex align={a => a.center} fullWidth>
						<LegendCircleFill color={Colors.surveyChartColors.green.light} />
						<HSpacer size={s => s.m} />
						<Flex column>
							<Typography.H4>{totalFilled}</Typography.H4>
							<Typography.Caption>
								{translate(dict => dict.responseRateChart.totalFormsFilled)}
							</Typography.Caption>
						</Flex>
					</Flex>
				</Flex>
			</CardView>
			<HSpacer size={s => s.m} />
			<Flex
				column={!smallerScreen}
				justify={j => (smallerScreen ? j.between : j.center)}
				style={
					!smallerScreen
						? { minWidth: '22rem' }
						: {
								width: '100%'
						  }
				}
			>
				<CardView right>
					<Flex
						fullWidth
						column
						justify={j => j.center}
						align={a => (!smallerScreen ? a.start : a.center)}
					>
						<Flex>
							<Icon svg={Svgs.Users} />
							<HSpacer size={s => s.m} />
							<Typography.H4>{totalPatients}</Typography.H4>
						</Flex>
						<Spacer size={s => s.s} />
						<Typography.H6 fontweight={w => w.thin}>
							{translate(dict => dict.responseRateChart.totalPromPatients)}
						</Typography.H6>
					</Flex>
				</CardView>
				{smallerScreen ? <HSpacer /> : <Spacer size={s => s.s} />}
				<CardView right>
					<Flex
						fullWidth
						column
						justify={j => j.center}
						align={a => (!smallerScreen ? a.start : a.center)}
					>
						<Flex>
							<Icon svg={Svgs.Workplace} />
							<HSpacer size={s => s.m} />
							<Typography.H4 fontweight={w => w.thin}>
								{totalCollaborators}
							</Typography.H4>
							{totalCollaborators === 1 && (
								<Flex align={a => a.center}>
									<UserTag
										title={translate(({ tags }) => tags.you)}
										marginOffset={{ left: 0.8 }}
									/>
									<UserTag
										title={translate(({ tags }) => tags.owner)}
										marginOffset={{ left: 0.8 }}
									/>
								</Flex>
							)}
						</Flex>
						<Spacer size={s => s.s} />
						<Typography.H6 fontweight={w => w.thin}>
							{translate(dict => dict.responseRateChart.totalPromCollaborators)}
						</Typography.H6>
					</Flex>
				</CardView>
			</Flex>
		</ResponseRateChartContainer>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { updateProject, ActionTypes, UpdateProject } from 'store/data/projects';

export function useUpdateProject(): Operation<(project: UpdateProject) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_PROJECT);

	function handler(project: UpdateProject) {
		dispatch(updateProject(project));
	}

	return [{ loading, error }, handler];
}

import { EntryFilter } from 'api/data/filters';
import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	CreateSeriesFilterAction,
	InvalidateSeriesFilterAction,
	UpdateSeriesFilterAction,
	createSeriesFilter,
	deleteSeriesFilter,
	deleteSeriesFilters,
	invalidateSeriesFilter,
	selectSeriesActiveFilterIds,
	selectSeriesActiveFilters,
	selectSeriesAreFiltersValid,
	selectSeriesFiltersById,
	selectSeriesInvalidFilterIds,
	updateSeriesFilter
} from 'store/data/filters';
import { ActionPayload } from 'store/types';
import { GenericMap } from 'types/index';

interface Data {
	activeFilterIds: string[];
	invalidFilterIds: string[];
	seriesFilters: EntryFilter[];
	filtersById: GenericMap<EntryFilter>;
	areFiltersValid: boolean;
	areFiltersActive: boolean;
	areFiltersOpen?: boolean;
}

interface Handlers {
	createSeriesFilter: (payload: ActionPayload<CreateSeriesFilterAction>) => void;
	updateSeriesFilter: (payload: ActionPayload<UpdateSeriesFilterAction>) => void;
	deleteSeriesFilter: (filterId: string) => void;
	deleteSeriesFilters: (filterIds: string[]) => void;
	invalidateSeriesFilter: (payload: ActionPayload<InvalidateSeriesFilterAction>) => void;
}

export function useSeriesFilters(): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const activeFilterIds = useSelector(state => selectSeriesActiveFilterIds(state.data.filters));

	const invalidFilterIds = useSelector(state => selectSeriesInvalidFilterIds(state.data.filters));

	const seriesFilters = useSelector(state => selectSeriesActiveFilters(state.data.filters));

	const filtersById = useSelector(state => selectSeriesFiltersById(state.data.filters));

	const areFiltersValid = useSelector(state => selectSeriesAreFiltersValid(state.data.filters));

	const areFiltersOpen = useSelector(state => state.data.filters.series.open);

	const areFiltersActive = activeFilterIds.length > 0;

	const data = {
		activeFilterIds,
		invalidFilterIds,
		seriesFilters,
		filtersById,
		areFiltersValid,
		areFiltersActive,
		areFiltersOpen
	};

	const handlers = {
		createSeriesFilter: (payload: ActionPayload<CreateSeriesFilterAction>) =>
			dispatch(createSeriesFilter(payload)),
		updateSeriesFilter: (payload: ActionPayload<UpdateSeriesFilterAction>) =>
			dispatch(updateSeriesFilter(payload)),
		deleteSeriesFilter: (filterId: string) => dispatch(deleteSeriesFilter(filterId)),
		deleteSeriesFilters: (filterIds: string[]) => dispatch(deleteSeriesFilters(filterIds)),
		invalidateSeriesFilter: (payload: ActionPayload<InvalidateSeriesFilterAction>) =>
			dispatch(invalidateSeriesFilter(payload))
	};

	return [data, handlers];
}

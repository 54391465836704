import { useState } from 'react';
import { Variable, VariableCategory } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { Template } from 'store/data/templates';
import { SelectItem } from 'types/index';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { Modal } from 'components/UI/Modal';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { arrayUtils } from 'helpers/arrays';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { buildVariableCategoriesMap, buildVariablesMap } from 'helpers/variables';
import {
	useTranslation,
	useFetchedTemplates,
	useEditableTemplatesIds,
	useProjectTemplates,
	useUserTemplates,
	usePublicTemplates,
	useCreateVariableCategoryValues
} from 'hooks/store';
import { useMutableState, useCompletedAction, usePaginate } from 'hooks/utils';

interface Props {
	variableName: string;
	onClose: (success?: boolean) => void;
}

export function ImportCategoryValuesModal({ variableName, onClose }: Props) {
	const { translate } = useTranslation();

	const [template, setTemplate] = useState<Template | null>(null);
	const [variable, setVariable] = useState<Variable | null>(null);
	const [selectedCategories, setSelectedCategories] = useMutableState<string[]>([]);

	const templatesMap = useFetchedTemplates();
	const editableTemplatesIds = useEditableTemplatesIds();
	const [{ data: sharedWithProjectIds, loading: loadingProjectTemplates }] =
		useProjectTemplates();
	const [{ data: sharedWithUserIds, loading: loadingUserTemplates }] = useUserTemplates();
	const [{ data: sharedWithPublicIds, loading: loadingPublicTemplates }] = usePublicTemplates();

	const aggregatedTemplateIds = [
		...new Set(
			[
				editableTemplatesIds,
				sharedWithProjectIds,
				sharedWithUserIds,
				sharedWithPublicIds
			].flat()
		)
	];

	const loadingTemplates =
		loadingProjectTemplates || loadingUserTemplates || loadingPublicTemplates;

	const [
		{ loading: creatingCategoryValues, error: errorCreatingCategoryValues },
		createCategoryValues
	] = useCreateVariableCategoryValues();

	useCompletedAction(creatingCategoryValues, errorCreatingCategoryValues, () => onClose(true));

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(getSelectedCategoryItems(), {
		threshold: 10,
		pageSize: 10
	});

	function handleSubmit() {
		if (!isFormValid()) return;
		if (!variable) return;

		const categoriesMap = buildVariableCategoriesMap(variable.categories);

		const categoryValues: VariableCategory[] = selectedCategories.map(
			value => categoriesMap[value]
		);

		createCategoryValues({ variableName, categoryValues });
	}

	function isFormValid() {
		return isVariableSelected() && selectedCategories.length > 0;
	}

	function isVariableSelected() {
		return variable !== null;
	}

	function isTemplateSelected() {
		return template !== null;
	}

	function onCheck(category: string) {
		setSelectedCategories(state => {
			if (state.includes(category)) return state.filter(c => c !== category);

			state.push(category);
		});
	}

	function onToggleAll(flag: boolean) {
		const categories = flag ? getVariableCategories() : [];

		setSelectedCategories(categories);
	}

	function getVariableCategories(): string[] {
		if (variable) return variable.categories.map(c => c.value);

		return [];
	}

	function getSelectTemplateItems(): SelectItem[] {
		const items: SelectItem[] = [];

		aggregatedTemplateIds.forEach(templateId => {
			if (templateId in templatesMap) {
				const template = templatesMap[templateId];

				const item: SelectItem = {
					label: template.templateName,
					value: template.templateId.toString()
				};

				items.push(item);
			}
		});

		return items;
	}

	function getSelectVariableItems(): SelectItem[] {
		const items: SelectItem[] = [];

		if (template) {
			const categoryVariables = getTemplateCategoryVariables(template);

			categoryVariables.forEach(variable => {
				const item: SelectItem = {
					label: variable.label,
					value: variable.name
				};

				items.push(item);
			});
		}

		return items;
	}

	function getSelectCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];

		if (variable) {
			variable.categories.forEach(c => {
				const item: SelectItem = {
					label: c.label || c.value,
					value: c.value
				};

				items.push(item);
			});
		}

		return items;
	}

	function getSelectedCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];

		if (variable) {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);

			selectedCategories.forEach(value => {
				const category = categoriesMap[value];

				const item: SelectItem = {
					label: category.label || category.value,
					value: category.value
				};

				items.push(item);
			});
		}

		return items;
	}

	function handleSelectTemplate(templateId: string) {
		if (template && template.templateId !== templateId) {
			setVariable(null);
			setSelectedCategories([]);
		}

		if (templateId in templatesMap) {
			const template = templatesMap[templateId];

			// quickfix due to typescript saying `templateId` is string, but its a number
			setTemplate({
				...template,
				templateId: template.templateId.toString()
			});
		}
	}

	function handleSelectVariable(variableName: string) {
		if (variable && variable.name !== variableName) setSelectedCategories([]);

		if (template) {
			const categoryVariables = getTemplateCategoryVariables(template);
			const categoryVariablesMap = buildVariablesMap(categoryVariables);

			if (variableName in categoryVariablesMap) {
				const variable = categoryVariablesMap[variableName];

				setVariable(variable);
			}
		}
	}

	function handleMoveCategoryValue(input: { sourceIndex: number; destinationIndex: number }) {
		const { sourceIndex, destinationIndex } = input;

		if (sourceIndex === destinationIndex) return;

		const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
			pageIndex,
			pageSize
		});

		setSelectedCategories(state =>
			arrayUtils.move(state, absoluteIndexes.sourceIndex, absoluteIndexes.destinationIndex)
		);
	}

	function getTemplateCategoryVariables(template: Template): Variable[] {
		const categoryVariables: Variable[] = [];

		template.variables.forEach(templateVariable => {
			const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
				templateVariable.type
			);

			if (isCategory) categoryVariables.push(templateVariable);
		});

		template.groups.forEach(templateGroup => {
			templateGroup.variables.forEach(groupTemplateVariable => {
				const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
					groupTemplateVariable.type
				);

				if (isCategory) categoryVariables.push(groupTemplateVariable);
			});
		});

		return categoryVariables;
	}

	const selectTemplateItems = getSelectTemplateItems();
	const selectVariableItems = getSelectVariableItems();

	return (
		<Modal
			title={translate(dict => dict.variablesPage.importCategoryValuesModal.title)}
			primary={{
				label: translate(({ buttons }) => buttons.import),
				loading: creatingCategoryValues,
				disabled: !isFormValid(),
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<CreatableSelect
				disabled={loadingTemplates}
				label={translate(
					dict => dict.variablesPage.importCategoryValuesModal.selectTemplate
				)}
				items={selectTemplateItems}
				value={
					template
						? selectTemplateItems.find(item => item.value === template.templateId)
						: null
				}
				onValueSelected={templateId => {
					if (templateId) handleSelectTemplate(templateId);
				}}
				canClear={false}
			/>

			{isTemplateSelected() ? (
				<>
					<Spacer size={s => s.s} />

					<CreatableSelect
						label={translate(
							dict =>
								dict.variablesPage.importCategoryValuesModal.selectCategoryVariable
						)}
						items={selectVariableItems}
						value={
							variable
								? selectVariableItems.find(item => item.value === variable.name)
								: null
						}
						onValueSelected={variableName => {
							if (variableName) handleSelectVariable(variableName);
						}}
						canClear={false}
					/>
				</>
			) : (
				<Spacer size={s => s.xxl} />
			)}

			{isVariableSelected() ? (
				<>
					<Spacer size={s => s.s} />

					<Dropdown
						label={translate(
							dict =>
								dict.variablesPage.importCategoryValuesModal.selectCategoryValues
						)}
						title={`${selectedCategories.length} ${
							selectedCategories.length === 1
								? translate(dict => dict.terms.value)
								: translate(dict => dict.terms.values)
						} ${translate(dict => dict.terms.selected)}`}
						button
					>
						<Flex paddingOffset={{ x: 1.2, y: 1.6 }}>
							<SelectMultiple
								items={getSelectCategoryItems()}
								onSelect={item => onCheck(item.value)}
								selectedItems={selectedCategories}
								onToggleAll={onToggleAll}
							/>
						</Flex>
					</Dropdown>

					<Spacer size={s => s.s} />

					<InputLabel
						label={translate(
							dict => dict.variablesPage.importCategoryValuesModal.inputLabel
						)}
					/>

					{shouldPaginate && (
						<Flex marginOffset={{ bottom: 0.4 }}>
							<Pagination
								totalCountLabel="values"
								pageIndex={pageIndex}
								pageSize={pageSize}
								pagesCount={pagesCount}
								changePage={changePage}
								changePageSize={changePageSize}
								totalCount={getSelectedCategoryItems().length}
							/>
						</Flex>
					)}

					<TagInput
						hint={translate(dict => dict.variablesPage.importCategoryValuesModal.hint)}
						items={selectedCategoryItems}
						onMove={handleMoveCategoryValue}
						readOnly
						sortable
					/>
				</>
			) : (
				<Spacer size={s => s.xxl} />
			)}
		</Modal>
	);
}

import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useMemo } from 'react';
import { Organization } from 'store/data/collaborators';

interface Props {
	onClose: () => void;
	onContinue: () => void;
	onSelect: (orgId: string) => void;
	visible?: boolean;
	organization?: Organization;
	userOrganizations: Organization[];
}

export function AssignProjectOrganizationModal({
	onClose,
	onContinue,
	onSelect,
	visible = false,
	organization,
	userOrganizations
}: Props) {
	const { translate } = useTranslation();

	const selectGroupItems = useMemo(() => {
		return userOrganizations.map(org => ({
			label: org.name,
			value: org.id
		}));
	}, [userOrganizations]);

	const value = useMemo(() => {
		if (!organization) return null;

		return {
			label: organization.name,
			value: organization.id
		};
	}, [organization]);

	return (
		<Modal
			visible={visible}
			onClose={onClose}
			size={s => s.s}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			primary={{
				label: translate(dict => dict.buttons.done),
				onClick: onContinue
			}}
			title={translate(dict => dict.dataset.modals.assingGroupModal.title)}
		>
			<Typography.Paragraph>
				{translate(dict => dict.dataset.modals.assingGroupModal.text)}
			</Typography.Paragraph>
			<Spacer size={s => s.m} />
			<CreatableSelect
				scrollIntoView={false}
				label={translate(dict => dict.dataset.modals.assingGroupModal.placeholder)}
				items={selectGroupItems}
				onValueSelected={value => (value ? onSelect(value) : null)}
				value={value}
			/>
		</Modal>
	);
}

import { PaymentMethodCard } from './PaymentMethods.style';
import { Icon } from 'components/UI/Icons';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { Colors, Svgs } from 'environment';
import { PaymentMethod } from 'store/account/payments';
import { CardGridContainer } from 'components/Account/Common/styles';

interface PaymentMethodsGridProps {
	primaryPaymentMethod?: PaymentMethod;
}

export default function PaymentMethods({ primaryPaymentMethod }: PaymentMethodsGridProps) {
	const { translate } = useTranslation();

	const invoiceEnabled = primaryPaymentMethod === PaymentMethod.INVOICE;
	const creditCardEnabled = primaryPaymentMethod === PaymentMethod.CARD;

	return (
		<CardGridContainer>
			<PaymentMethodCard disabled={!invoiceEnabled}>
				<Icon
					size={s => s.l}
					svg={Svgs.Bank}
					paddingOffset={{ left: 0.8, right: 1.6 }}
					colors={{
						color: invoiceEnabled ? Colors.text.main : Colors.text.disabled
					}}
				/>
				<Flex column>
					<Typography.Paragraph
						fontweight={w => w.bold}
						color={invoiceEnabled ? Colors.text.main : Colors.text.disabled}
					>
						{translate(dict => dict.accountUM.payment.methods.invoiceOption)}
					</Typography.Paragraph>
					<Typography.Paragraph
						color={invoiceEnabled ? Colors.text.main : Colors.text.disabled}
					>
						{translate(dict => dict.accountUM.payment.methods.invoiceDescription)}
					</Typography.Paragraph>
				</Flex>
			</PaymentMethodCard>

			<PaymentMethodCard disabled={primaryPaymentMethod !== PaymentMethod.CARD}>
				<Icon
					size={s => s.l}
					svg={Svgs.CreditCard}
					paddingOffset={{ left: 0.8, right: 1.6 }}
					colors={{ disabled: Colors.text.disabled }}
					disabled={!creditCardEnabled}
				/>
				<Flex column>
					<Typography.Paragraph
						fontweight={w => w.bold}
						color={creditCardEnabled ? Colors.text.main : Colors.text.disabled}
					>
						{translate(dict => dict.accountUM.payment.methods.creditCardOption)}
					</Typography.Paragraph>
					<Typography.Paragraph
						color={creditCardEnabled ? Colors.text.main : Colors.text.disabled}
					>
						{translate(dict => dict.accountUM.payment.methods.creditCardDescription)}
					</Typography.Paragraph>
				</Flex>
			</PaymentMethodCard>
		</CardGridContainer>
	);
}

import { useState } from 'react';
import { nanoid as generate } from 'nanoid';

import { VariableType } from 'types/data/variables/constants';
import { EntryFilter as FilterType, Operator } from 'api/data/filters';
import { STATUS_COLUMN } from 'consts';
import { Row } from 'components/Analysis/style';
import { MediaQueries, Svgs } from 'environment';
import { ActionTypes } from 'store/data/analyses';

import { AddFilter } from './AddFilter';
import { Filter } from './Filter';
import { NoFilters } from './NoFilters';

import { Container } from './FilterList.style';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Button } from 'components/UI/Interactables/Button/Button';
import { getDefaultOperator } from 'helpers/filters';
import { getAggregatorVariableNameByAggregationRuleName } from 'helpers/variables';
import {
	useTranslation,
	useVariablesData,
	useVariables,
	useVariablesDataSelectItems,
	useActivities,
	useFilters
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

export function FilterList() {
	const { translate } = useTranslation();

	const variablesData = useVariablesData({ initial: true });
	const [
		{
			data: { hasVariables, variablesMap, variableSetsMap },
			loading: loadingVariables
		}
	] = useVariables({ initial: true, lazy: true });

	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);

	const disabled = !hasVariables;

	const [{ filters }, { createFilter, toggleOpenFilters }] = useFilters();

	const [addFilter, setAddFilter] = useState(false);

	const [{ loading: loadingAnalysis }] = useActivities([
		// ANALYSES
		ActionTypes.GET_COMPARE_NUMERIC_V1,
		ActionTypes.GET_COMPARE_NUMERIC_V2,
		ActionTypes.GET_CORRELATIONS_V1,
		ActionTypes.GET_CORRELATIONS_V2,
		ActionTypes.GET_CROSSTAB,
		ActionTypes.GET_EXPLORE,
		ActionTypes.GET_FREQUENCIES,
		ActionTypes.GET_KAPLAN_MEIER,
		ActionTypes.GET_PLOT_NUMERIC_COLUMNS,
		ActionTypes.GET_PLOT_NUMERIC_BOXPLOT,
		ActionTypes.GET_PLOT_NUMERIC_SCATTER,
		ActionTypes.GET_DENSITY_PLOT,
		ActionTypes.GET_TIME_COURSE_V1,
		ActionTypes.GET_TIME_COURSE_V2,
		ActionTypes.GET_COMPARE_PAIRED,
		// STATISTICS
		ActionTypes.GET_FISHER_STATISTICS,
		ActionTypes.GET_CHI_SQUARE_STATISTICS,
		ActionTypes.GET_SHAPIRO_STATISTICS_V1,
		ActionTypes.GET_MANN_WHITNEY_STATISTICS_V1,
		ActionTypes.GET_INDEPENDENT_STATISTICS_V1,
		ActionTypes.GET_SHAPIRO_STATISTICS_V2,
		ActionTypes.GET_MANN_WHITNEY_STATISTICS_V2,
		ActionTypes.GET_INDEPENDENT_STATISTICS_V2,
		ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS_V1,
		ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS_V2,
		ActionTypes.GET_PEARSON_STATISTICS_V1,
		ActionTypes.GET_PEARSON_STATISTICS_V2,
		ActionTypes.GET_SPEARMAN_STATISTICS_V1,
		ActionTypes.GET_SPEARMAN_STATISTICS_V2,
		ActionTypes.GET_LINEAR_REGRESSION_STATISTICS_V1,
		ActionTypes.GET_LINEAR_REGRESSION_STATISTICS_V2,
		ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS,
		ActionTypes.GET_LOG_RANK_TEST_STATISTICS,
		ActionTypes.GET_TUKEY_STATISTICS_V1,
		ActionTypes.GET_TUKEY_STATISTICS_V2,
		ActionTypes.GET_PAIRED_TTEST_STATISTICS,
		ActionTypes.GET_KRUSKAL_STATISTICS_V1,
		ActionTypes.GET_KRUSKAL_STATISTICS_V2,
		ActionTypes.GET_MCNEMAR_STATISTICS,
		ActionTypes.GET_TWO_WAY_MANOVA_STATISTICS_V1,
		ActionTypes.GET_TWO_WAY_ANOVA_STATISTICS_V1,
		ActionTypes.GET_TWO_WAY_MANOVA_STATISTICS_V2,
		ActionTypes.GET_TWO_WAY_ANOVA_STATISTICS_V2
	]);

	function handleSelect(itemName: string) {
		let variable = variablesMap[itemName];
		let columnSetName: string | undefined;

		if (itemName in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[itemName];

			columnSetName = itemName;
			variable = variablesMap[aggregatorVariableName];
		}

		const filter: FilterType = {
			itemId: generate(),
			columnName: columnSetName ?? variable.name,
			filterType:
				variable.type !== VariableType.TimeDuration ? variable.type : VariableType.Integer,
			operator: getDefaultOperator(variable.type),
			...(variable.type === VariableType.Unique
				? { filterSubType: variable.uniquenessType }
				: {})
		};

		if (variable) {
			const isCategoryType =
				variable.type === VariableType.CategoryMultiple ||
				variable.type === VariableType.Category;

			if (isCategoryType) {
				filter.values = [];
				filter.invalid = true;
			}
		}

		createFilter({ filter });
		setAddFilter(false);
	}

	function addStatusFilter() {
		const filter: FilterType = {
			itemId: generate(),
			columnName: STATUS_COLUMN.name,
			filterType: VariableType.Status,
			operator: Operator.In
		};

		createFilter({ filter });
		setAddFilter(false);
	}
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	return (
		<Container>
			<Row>
				{isMobileDevice && (
					<Icon
						svg={Svgs.ArrowLeft}
						marginOffset={{ right: 0.8 }}
						variant={v => v.buttonActive}
						onClick={toggleOpenFilters}
					/>
				)}
				<Typography.H5 title={translate(dict => dict.filters.filters)}>
					{translate(dict => dict.filters.filters)}
				</Typography.H5>
			</Row>

			{/* SELECT FILTER TO ADD */}
			{addFilter && (
				<AddFilter
					variablesDataSelectItems={variablesDataSelectItems}
					onSelect={handleSelect}
					onDelete={() => setAddFilter(false)}
					onSelectStatus={addStatusFilter}
				/>
			)}

			{/* NO FILTERS */}
			{!addFilter && (
				<NoFilters
					disabled={loadingVariables || disabled}
					onAddFilter={() => setAddFilter(true)}
				/>
			)}

			{/* LIST */}
			{filters.map(filter => (
				<Filter
					key={`analysis-filter-${filter.itemId}`}
					filter={filter}
					variablesData={variablesData}
					variablesDataSelectItems={variablesDataSelectItems}
					disabled={loadingAnalysis}
				/>
			))}

			{isMobileDevice && filters.length > 0 && (
				<Button
					title={translate(({ buttons }) => buttons.done)}
					onClick={toggleOpenFilters}
					marginOffset={{ x: 2, y: 2 }}
				/>
			)}
		</Container>
	);
}

import { Flex } from 'components/UI/Flex';
import { Colors } from 'environment';
import styled, { css } from 'styled-components';

interface ItemProps {
	active?: boolean;
}

export const Container = styled.div``;

export const Menu = styled(Flex)`
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	visibility: hidden;

	:hover {
		visibility: visible;
	}
`;

export const MenuItem = styled.div<ItemProps>`
	margin-left: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.4rem;
	height: 2.4rem;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
	bottom: 0.2rem;

	svg {
		cursor: pointer;
	}

	:hover {
		background: ${Colors.primary.disabled};

		svg {
			cursor: pointer;
			color: ${Colors.primary.hover};
		}
	}

	${({ active }) =>
		active &&
		css`
			background: ${Colors.primary.disabled};
			pointer-events: none;
		`}
`;

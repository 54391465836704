import { Controller, FieldError, UseFormReturn } from 'react-hook-form';

import { DynamicFormValue, DynamicFormValues } from 'store/data/entries';
import { EntryFileInput } from 'components/UI/Inputs/EntryFileInput';
import { entryFormReadOnlyModalEvent, setEntryFormFieldValue } from 'helpers/entries';

interface FileComponentProps {
	name: string;
	disabled: boolean;
	readOnly?: boolean;
	value?: DynamicFormValue;
	formContext?: UseFormReturn<DynamicFormValues>;
}

export function FileInput({
	name,
	disabled,
	readOnly,
	value: _value,
	formContext
}: FileComponentProps) {
	if (_value) return <EntryFileInput value={_value as string} readOnly />;

	if (!formContext) return <EntryFileInput value="" readOnly />;

	const {
		setValue,
		formState: { errors }
	} = formContext;

	function handleSetValue(name: string, value: DynamicFormValue) {
		// CHECK IF USER HAS ACCESS TO CHANGE THE VALUE
		if (readOnly) {
			entryFormReadOnlyModalEvent().dispatch(true);
			return;
		}

		setEntryFormFieldValue(name, value, setValue);
	}

	const error = (errors[name] as FieldError)?.message;

	return (
		<Controller
			name={name}
			defaultValue={''}
			render={({ field: { value, onBlur } }) => (
				<EntryFileInput
					name={name}
					error={error}
					value={value ?? _value}
					disabled={disabled}
					readOnly={readOnly}
					onBlur={onBlur}
					onValueChange={value => handleSetValue(name, value)}
				/>
			)}
		/>
	);
}

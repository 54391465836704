import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getOrganizations,
	Organization,
	selectOrganizations
} from 'store/account/subscription';
import { Nullable } from 'types/index';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useOrganizations(): OperationResult<Nullable<Organization[]>> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectOrganizations(state.account.subscription));

	const [{ loading, error }] = useActivity(ActionTypes.GET_ORGANIZATIONS);

	useEffect(() => {
		if (!loading && !error && !data) {
			handler();
		}
	}, [loading, error, data]);

	function handler() {
		dispatch(getOrganizations());
	}

	return [{ loading, error, data }, handler];
}

import {
	type ApiDashboardCard,
	type ApiDashboard,
	DashboardPermission,
	DashboardType
} from 'api/data/projectDashboard';
import type { CardPositionManual, IndexPayload } from 'components/Account';

import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export enum ActionTypes {
	// APIs
	LIST_DASHBOARDS = 'data/projectDashboard/LIST_DASHBOARDS',
	GET_DASHBOARD_BY_ID = 'data/projectDashboard/GET_DASHBOARD_BY_ID',
	CREATE_DASHBOARD = 'data/projectDashboard/CREATE_DASHBOARD',
	UPDATE_DASHBOARD = 'data/projectDashboard/UPDATE_DASHBOARD',
	REMOVE_DASHBOARD = 'data/projectDashboard/REMOVE_DASHBOARD',

	// Store
	ADD_DASHBOARD_CARD = 'data/projectDashboard/ADD_DASHBOARD_CARD',
	RESIZE_DASHBOARD_CARD = 'data/projectDashboard/RESIZE_DASHBOARD_CARD',
	MOVE_DASHBOARD_CARD = 'data/projectDashboard/MOVE_DASHBOARD_CARD',
	REMOVE_DASHBOARD_CARD = 'data/projectDashboard/REMOVE_DASHBOARD_CARD',
	SET_DASHBOARD_ID = 'data/projectDashboard/SET_DASHBOARD_ID',
	//POC
	SET_LAYOUT_COLUMNS = 'data/projectDashboard/SET_LAYOUT_COLUMNS'
}

export interface State {
	projectId: string | null;
	layoutColumns: number;
	dashboardId: number | null;
	dashboardIdsByProjectId: DashboardsIds;
	dashboardsById: DashboardsById;
}

export type DashboardsIds = GenericMap<{
	ids: number[];
	fetched: boolean;
}>;

export type DashboardsId = number | null;

export interface DashboardCard extends Omit<ApiDashboardCard, 'size'> {
	size: {
		widthSize: APIWidthSize;
		heightSize: WidgetHeightSize;
	};
}

export type CardsMap = GenericMap<DashboardCard>;

export interface Dashboard extends Omit<ApiDashboard, 'cards'> {
	cards: CardsMap;
	elementsOrder: GridCardsOrder;
}

export type DashboardsMap = GenericMap<Dashboard>;

export type NewDashboard = Omit<Dashboard, 'owner' | 'userId'>;

export type LayoutRowOrderType = string[][];
export type ElementsOrderType = LayoutRowOrderType[];
export type GridCardsOrder = string[][];

export type DashboardStates = {
	initial: Dashboard;
	current: Dashboard;
};

export type DashboardsById = GenericMap<DashboardStates>;

export interface LayoutPosition {
	rowIndex: number;
	colIndex: number;
}

export enum WidgetWidthSize {
	small = 'small',
	medium = 'medium',
	big = 'big'
}

export enum APIWidthSize {
	one = 'firstSizeWidth',
	two = 'secondSizeWidth',
	three = 'thirdSizeWidth',
	four = 'forthSizeWidth',
	five = 'fifthSizeWidth',
	six = 'sixthSizeWidth',
	seven = 'seventhSizeWidth'
}

export enum WidgetHeightSize {
	small = 'small',
	big = 'big'
}

export interface WidthThreeColumnsLayout {
	firstSizeWidth: number;
	secondSizeWidth: number;
	thirdSizeWidth: number;
}
export interface WidthFourColumnsLayout extends WidthThreeColumnsLayout {
	forthSizeWidth: number;
}
export interface WidthFiveColumnsLayout extends WidthFourColumnsLayout {
	fifthSizeWidth: number;
}
export interface WidthSixColumnsLayout extends WidthFiveColumnsLayout {
	sixthSizeWidth: number;
}
export interface WidthSevenColumnsLayout extends WidthSixColumnsLayout {
	seventhSizeWidth: number;
}

export interface WidthSizes {
	threeColumns?: WidthThreeColumnsLayout;
	fourColumns?: WidthFourColumnsLayout;
	fiveColumns?: WidthFiveColumnsLayout;
	sixColumns?: WidthSixColumnsLayout;
	sevenColumns?: WidthSevenColumnsLayout;
}

export type LayoutColumnsTypes =
	| WidthSizes
	| WidthFourColumnsLayout
	| WidthFiveColumnsLayout
	| WidthSixColumnsLayout;

export type WidthColumnsTypes =
	| WidthThreeColumnsLayout
	| WidthFourColumnsLayout
	| WidthFiveColumnsLayout
	| WidthSixColumnsLayout
	| WidthSevenColumnsLayout;

export interface HeightSizes {
	minSizeHeight: number;
	maxSizeHeight: number;
}

export type ListDashboardsAction = Action<
	ActionTypes.LIST_DASHBOARDS,
	{
		projectId: string;
		dashboards: ApiDashboard[];
	}
>;

export type GetDashboardByIdAction = Action<
	ActionTypes.GET_DASHBOARD_BY_ID,
	{
		projectId: string;
		apiDashboard: ApiDashboard;
	}
>;

export type CreateDashboardAction = Action<
	ActionTypes.CREATE_DASHBOARD,
	{
		projectId: string;
		dashboard: ApiDashboard;
	}
>;

export type UpdateDashboardAction = Action<
	ActionTypes.UPDATE_DASHBOARD,
	{
		projectId: string;
		dashboard: ApiDashboard;
	}
>;

export type AddDashboardCardAction = Action<
	ActionTypes.ADD_DASHBOARD_CARD,
	{
		card: ApiDashboardCard;
	}
>;

export type MoveDashboardCardAction = Action<ActionTypes.MOVE_DASHBOARD_CARD, IndexPayload>;

export type RemoveDashboardCardAction = Action<
	ActionTypes.REMOVE_DASHBOARD_CARD,
	CardPositionManual
>;

export type SizePayload = {
	cardId: number;
	width: number;
	height?: number;
	outOfView?: boolean;
	vertical?: boolean;
	position?: CardPositionManual;
	widthSizes?: WidthSizes;
	heightSizes?: HeightSizes;
};
export type SetSizePayloadDynamic = {
	cardId: number;
	width: number;
	height?: number;
	outOfView?: boolean;
	vertical?: boolean;
	position?: CardPositionManual;
	widthSizes?: WidthSizes;
	heightSizes?: HeightSizes;
};

// export type ResizeDashboardCardAction = Action<ActionTypes.RESIZE_DASHBOARD_CARD, SetSizePayload>;

export type ResizeDashboardCardActionDynamic = Action<
	ActionTypes.RESIZE_DASHBOARD_CARD,
	SetSizePayloadDynamic
>;

export type SetDashboardIdAction = Action<
	ActionTypes.SET_DASHBOARD_ID,
	{
		dashboardId: DashboardsId;
	}
>;
export type SetLayoutColumnsAction = Action<
	ActionTypes.SET_LAYOUT_COLUMNS,
	{
		layoutColumns: number;
	}
>;

export type CommonProperties = {
	dashboardId: number;
	projectId: number;
};

export type CreateDashboardCardPayload = CommonProperties & { cards: ApiDashboardCard[] };
export type RemoveDashboardCardPayload = CommonProperties & { cards: [{ cardId: number }] };

// export type RemoveDashboardCardPayload {
// 	dashboard: {
// 		CommonProperties
// 		cards: [cardId: number];
// 	};
// }

export const newDashboard: NewDashboard = {
	dashboardId: -1,
	projectId: 0,
	description: '',
	label: '',
	permissions: DashboardPermission.write,
	type: DashboardType.project,
	cards: {},
	elementsOrder: [[], [], [], [], []]
};
export const emptyElementsOrderPlaceholders = [
	['placeholder', 'placeholder', 'placeholder'],
	['placeholder', 'placeholder', 'placeholder'],
	['placeholder', 'placeholder', 'placeholder'],
	['placeholder', 'placeholder', 'placeholder'],
	['placeholder', 'placeholder', 'placeholder'],
	['placeholder', 'placeholder', 'placeholder']
];
export function getEmptyRowDynamicByLayoutSize(layoutSize: number) {
	const row: Array<string[]> = [];
	const layoutRowsSize = 12;
	for (let i = 0; i <= layoutRowsSize - 1; i++) {
		if (!row[i]) {
			row[i] = [];
		}
		for (let j = 0; j <= layoutSize; j++) {
			if (j <= layoutSize - 1) {
				row[i].push('placeholder');
				// row[i].push('placeholder');
			}
		}
	}

	return row;
}

export type Actions =
	| GetDashboardByIdAction
	| ListDashboardsAction
	| CreateDashboardAction
	| UpdateDashboardAction
	| SetDashboardIdAction
	| AddDashboardCardAction
	| MoveDashboardCardAction
	// | ResizeDashboardCardAction
	//POC
	| ResizeDashboardCardActionDynamic
	| SetLayoutColumnsAction
	| RemoveDashboardCardAction;

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectAdminUsersTableParams,
	setAdminUsersTablePageParams,
	PageParams
} from 'store/account/admin';

export function useAdminUsersTableParams(): LocalOperationResult<
	PageParams,
	(params: PageParams) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAdminUsersTableParams(state.account.admin));

	function handler(params: PageParams) {
		dispatch(setAdminUsersTablePageParams(params));
	}

	return [data, handler];
}

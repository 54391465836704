import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { useTranslation } from 'hooks/store';
import { SetState } from 'types/index';

interface Props {
	hasData: boolean;
	onCreate: () => void;
	searchTermState: {
		searchTerm: string;
		setSearchTerm: SetState<string> | ((term: string) => void);
	};
}

export function RolesPageHeader({
	hasData,
	onCreate,
	searchTermState: { searchTerm, setSearchTerm }
}: Props) {
	const { translate } = useTranslation();

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					hasData && (
						<Flex>
							<SearchInput
								placeholder={translate(
									dict => dict.roles.header.searchInput.placeholder
								)}
								term={searchTerm}
								onChangeTerm={term => term !== searchTerm && setSearchTerm(term)}
								usedInHeader
							/>

							{addButton({
								label: translate(dict => dict.roles.header.createButton.title),
								action: onCreate
							})}
						</Flex>
					)
				}
			/>
			<Header.Title title={translate(dict => dict.rolesPage.title)} />
		</>
	);
}

import { Draggable, DraggingStyle, Droppable } from 'react-beautiful-dnd';
import { DraggableVariable, TemplateGroupInterface } from 'store/data/templates';
import { Spacer } from 'components/UI/Spacer';
import { DragAndDropTypes } from 'types/index';
import { Svgs, Colors } from 'environment';
import { getCloneStyle } from 'helpers/templates';
import { TemplateVariable } from '../TemplateVariable';
import { CardHeader, Container, DraggableCard, DraggableGroup } from '../..';
import { ListEmpty, Wrapper } from './TemplatesVariablesAndGroups.style';
import { Gap } from 'components/UI/Gap';
import { Asterisk } from 'components/UI/Asterisk';
import { Icon } from 'components/UI/Icons';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useProjectId } from 'hooks/store';

interface Props {
	variables: DraggableVariable[];
	groups: TemplateGroupInterface[];
	writeAccess: boolean;
}

export function TemplatesVariablesAndGroups({ variables, groups, writeAccess }: Props) {
	const { translate } = useTranslation();
	const { routes, navigate, promOrProject } = useNavigation();
	const [projectId] = useProjectId();

	function createNewVariable() {
		if (projectId) {
			navigate(routes[promOrProject].variables.create(projectId));
		}
	}

	const nothingNoDrag = variables.length === 0 && groups.length === 0;

	if (nothingNoDrag) {
		return (
			<ListEmpty>
				<Typography.Paragraph>
					{translate(dict => dict.variableList.noVariables)}
				</Typography.Paragraph>
				{writeAccess && (
					<>
						<Spacer size={s => s.s} />
						<Button
							title={translate(dict => dict.addVariable.addVariableLabel)}
							onClick={createNewVariable}
							variant={v => v.link}
						/>
					</>
				)}
			</ListEmpty>
		);
	}

	return (
		<Droppable droppableId={DragAndDropTypes.DraggableTemplateVarOrGroup} isDropDisabled>
			{provided => (
				<Wrapper ref={provided.innerRef}>
					<Gap marginGap={{ bottom: 0.8 }}>
						{variables.map((variable, index) => {
							const { label, draggableId, obligatory } = variable;

							return (
								<Draggable
									key={draggableId}
									index={index}
									draggableId={draggableId}
									isDragDisabled={!writeAccess}
								>
									{({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
										<>
											{label && (
												<div
													data-test-id={label}
													ref={innerRef}
													{...draggableProps}
													{...dragHandleProps}
													style={getCloneStyle(
														draggableProps.style as DraggingStyle,
														snapshot
													)}
												>
													<TemplateVariable variable={variable} />
												</div>
											)}

											{/* PLACEHOLDER WHEN DRAGGING */}
											{snapshot.isDragging && (
												<DraggableCard>
													<Wrapper>
														<CardHeader>
															<Container>
																<Icon
																	svg={Svgs.ChevronDown}
																	marginOffset={{
																		right: 0.8
																	}}
																/>
																<Typography.Paragraph
																	ellipsis
																	style={{
																		flex: 1
																	}}
																>
																	{label}
																</Typography.Paragraph>
																{obligatory && (
																	<Asterisk paddingLeft />
																)}
															</Container>
														</CardHeader>
													</Wrapper>
												</DraggableCard>
											)}
										</>
									)}
								</Draggable>
							);
						})}

						{groups.map((group, index) => {
							const { groupLabel, groupName, draggableId } = group;

							if (!draggableId) return null;

							return (
								<Draggable
									key={draggableId}
									draggableId={draggableId}
									index={variables.length + index}
									isDragDisabled={!writeAccess}
								>
									{({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
										<>
											<div
												data-test-id={groupLabel ?? groupName}
												ref={innerRef}
												{...draggableProps}
												{...dragHandleProps}
												style={getCloneStyle(
													draggableProps.style as DraggingStyle,
													snapshot
												)}
											>
												<DraggableGroup
													writeAccess={writeAccess}
													group={group}
												/>
											</div>

											{/* PLACEHOLDER WHEN DRAGGING */}
											{snapshot.isDragging && (
												<DraggableCard>
													<Wrapper>
														<CardHeader>
															<Container>
																<Icon svg={Svgs.ChevronDown} />
																<Icon
																	svg={Svgs.Folder}
																	size={s => s.m}
																	colors={{
																		color: Colors.chart.orange
																			.dark
																	}}
																	marginOffset={{
																		x: 0.8
																	}}
																/>
																<Typography.Paragraph ellipsis>
																	{groupLabel ?? groupName}
																</Typography.Paragraph>
															</Container>
														</CardHeader>
													</Wrapper>
												</DraggableCard>
											)}
										</>
									)}
								</Draggable>
							);
						})}
					</Gap>

					{/* Hide placeholder for clonable elements */}
					<div style={{ visibility: 'hidden', height: 0, width: 0 }}>
						{provided.placeholder}
					</div>
				</Wrapper>
			)}
		</Droppable>
	);
}

import styled, { css } from 'styled-components';

export const AddGroupIcon = styled.div`
	visibility: hidden;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);
`;

interface DraggingProps {
	isDragging: boolean;
}

export const GroupedIconWrapper = styled.div<DraggingProps>`
	display: flex;
	align-items: center;
	width: 3.2rem;
	transition: width 0.1s;
	margin-bottom: 1rem;

	${({ isDragging }) =>
		isDragging &&
		css`
			width: 0;
		`};
`;

export const GroupedVariablesWrapper = styled.div``;

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

import type { AdminAccount, Domains } from 'api/account/admin';
import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export enum ActionTypes {
	ADMIN_GET_USERS = 'data/admin/ADMIN_GET_USERS',
	ADMIN_GET_USER = 'data/admin/ADMIN_GET_USER',
	ADMIN_CREATE_USER = 'data/admin/ADMIN_CREATE_USER',
	ADMIN_UPDATE_USER = 'data/admin/ADMIN_UPDATE_USER',
	ADMIN_REMOVE_USER = 'data/admin/ADMIN_REMOVE_USER',
	ADMIN_RESET_USER_PASSWORD = 'data/admin/ADMIN_RESET_USER_PASSWORD',
	ADMIN_RESET_USER_MFA = 'data/admin/ADMIN_RESET_USER_MFA',

	/////////////////////////////////////////////////////////////////////
	ADMIN_SET_USERS_SEARCH_TERM = 'data/admin/ADMIN_SET_USERS_SEARCH_TERM',
	ADMIN_SET_USERS_USER_ID = 'data/admin/ADMIN_SET_USERS_USER_ID',
	ADMIN_SET_TABLE_PAGE_PARAMS = 'data/admin/ADMIN_SET_TABLE_PAGE_PARAMS',
	/////////////////////////////////////////////////////////////////////
	ADMIN_SET_USERS_FILTER = 'data/admin/ADMIN_SET_USERS_FILTER',
	ADMIN_SET_USERS_DATE_FILTER = 'data/admin/ADMIN_SET_USERS_DATE_FILTER',
	ADMIN_RESET_USERS_FILTER = 'data/admin/ADMIN_RESET_USERS_FILTER',
	ADMIN_RESET_ALL_USERS_FILTERS = 'data/admin/ADMIN_RESET_ALL_USERS_FILTERS',
	/////////////////////////////////////////////////////////////////////
	ADMIN_GET_ENTERPRISE_ORGANIZATIONS = 'data/admin/ADMIN_GET_ENTERPRISE_ORGANIZATIONS',
	ADMIN_GET_DOMAINS_FOR_ORGANIZATION = 'data/admin/ADMIN_GET_DOMAINS_FOR_ORGANIZATION',
	ADMIN_SET_ORGANIZATION_ID = 'data/admin/ADMIN_SET_ORGANIZATION_ID',
	ADD_WHITELISTED_DOMAINS = 'data/admin/ADD_WHITELISTED_DOMAINS',
	REMOVE_DOMAINS = 'data/admin/REMOVE_DOMAINS'
}

export interface State {
	users: {
		byId: GenericMap<AdminAccount>;
		filters: AdminUsersFilters;
		metadata: {
			userId: AdminUserId;
			pageSize: number;
			pageIndex: number;
			searchTerm: string;
			fetched: boolean;
		};
	};
	enterpriseOrganizations: {
		byOrganizationId: GenericMap<EnterpriseOrganization>;
		organizationId: string;
	} | null;
}

export type AdminUserId = string | null;

export interface UserCredentials {
	temporaryPasswordValidityDays: number;
	forceChangePassword: boolean;
	phoneNumberVerified: boolean;
	mfaEnabled: boolean;
}

export interface AdminUsersFilters {
	organizations: CheckboxFilter;
	createdDate: DateFilter;
	status: CheckboxFilter;
	licence: CheckboxFilter;
}

export interface CheckboxFilter {
	all: string[];
	active: string[];
}

export interface DateFilter {
	valid: boolean;
	operator: DateFilterOperator;
	value: string | null;
	from: string | null;
	to: string | null;
}

export enum AdminUsersFilterNames {
	Organization = 'organization',
	CreatedDate = 'createdDate',
	Status = 'status',
	Licence = 'licence'
}

export enum DateFilterOperator {
	Between = 'between',
	Exact = 'exact',
	Before = 'before',
	After = 'after'
}

export interface FetchedDomains {
	allowed: string[];
	blocked: string[];
}

export interface EnterpriseOrganization {
	orgVATNumber: string;
	subscriptionId: string;
	organizationName: string;
	country: string;
	domains?: FetchedDomains;
}

export interface PageParams {
	pageIndex: number;
	pageSize: number;
}

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export type GetAdminUsersAction = Action<
	ActionTypes.ADMIN_GET_USERS,
	{
		users: AdminAccount[];
	}
>;

export type GetAdminUserAction = Action<
	ActionTypes.ADMIN_GET_USER,
	{
		user: AdminAccount;
	}
>;

export type CreateAdminUserAction = Action<
	ActionTypes.ADMIN_CREATE_USER,
	{
		user: AdminAccount;
	}
>;
export type UpdateAdminUserAction = Action<
	ActionTypes.ADMIN_UPDATE_USER,
	{
		user: AdminAccount;
	}
>;

export type RemoveAdminUserAction = Action<ActionTypes.ADMIN_REMOVE_USER>;

export type ResetAdminUserPasswordAction = Action<
	ActionTypes.ADMIN_RESET_USER_PASSWORD,
	{
		userId: string;
	}
>;

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export type SetAdminUsersSearchTermAction = Action<
	ActionTypes.ADMIN_SET_USERS_SEARCH_TERM,
	{
		term: string;
	}
>;

export type SetAdminUsersUserIdAction = Action<
	ActionTypes.ADMIN_SET_USERS_USER_ID,
	{
		userId: AdminUserId;
	}
>;

export type SetAdminUsersTablePageParamsAction = Action<
	ActionTypes.ADMIN_SET_TABLE_PAGE_PARAMS,
	PageParams
>;

/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

export type SetAdminUsersFilterAction = Action<
	ActionTypes.ADMIN_SET_USERS_FILTER,
	{
		filterName: AdminUsersFilterNames;
		value: string;
	}
>;

export type SetAdminUsersDateFilterAction = Action<
	ActionTypes.ADMIN_SET_USERS_DATE_FILTER,
	{
		filterName: AdminUsersFilterNames;
		filter: DateFilter;
	}
>;

export type ResetAdminUsersFilterAction = Action<
	ActionTypes.ADMIN_RESET_USERS_FILTER,
	{
		filterName: AdminUsersFilterNames;
	}
>;

export type ResetAllAdminUsersFiltersAction = Action<ActionTypes.ADMIN_RESET_ALL_USERS_FILTERS>;

export type GetEnterpriseOrganizationAction = Action<
	ActionTypes.ADMIN_GET_ENTERPRISE_ORGANIZATIONS,
	{
		organizations: EnterpriseOrganization[];
	}
>;

export type GetDomainsForOrganization = Action<
	ActionTypes.ADMIN_GET_DOMAINS_FOR_ORGANIZATION,
	{
		organizationId: string;
		domains: Domains;
	}
>;

export type AddWhitelistedDomainsAction = Action<
	ActionTypes.ADD_WHITELISTED_DOMAINS,
	{
		organizationId: string;
		domains: string[];
	}
>;

export type RemoveDomainsAction = Action<
	ActionTypes.REMOVE_DOMAINS,
	{
		organizationId: string;
		domains: string[];
	}
>;

export type SetAdminOrganizationIdAction = Action<
	ActionTypes.ADMIN_SET_ORGANIZATION_ID,
	{
		organizationId: string;
	}
>;

export type Actions =
	| GetAdminUsersAction
	| GetAdminUserAction
	| CreateAdminUserAction
	| UpdateAdminUserAction
	| RemoveAdminUserAction
	| ResetAdminUserPasswordAction
	////////////////////////////////
	| SetAdminUsersSearchTermAction
	| SetAdminUsersUserIdAction
	| SetAdminUsersTablePageParamsAction
	////////////////////////////////
	| SetAdminUsersFilterAction
	| SetAdminUsersDateFilterAction
	| ResetAdminUsersFilterAction
	| ResetAllAdminUsersFiltersAction
	////////////////////////////////
	| GetEnterpriseOrganizationAction
	| GetDomainsForOrganization
	| SetAdminOrganizationIdAction
	| RemoveDomainsAction
	| AddWhitelistedDomainsAction;

export enum DataTestId {
	Switch = 'testid-switch',
	Checkbox = 'testid-checkbox',
	Alert = 'testid-alert',
	PaginationButton = 'pagination-button',
	CollapsibleOnChange = 'CollapsibleOnChange',
	DropdownItem = 'dropdown-item',
	TableFilter = 'table-filter',
	DateInput = 'testid-dateInput',
	RegularInput = 'testid-regularInput',
	DropdownToggle = 'dropdown-toggle',
	DragAndDrop = 'testid-dragAndDrop',
	UserCardCheckbox = 'user-card-checkbox',
	CollapsibleCardToggle = 'collapsible-card-toggle',
	NextArrow = 'next-arrow',
	PrevArrow = 'prev-arrow',
	AdvancedMenuToggle = 'advanced-menu-toggle',
	Backdrop = 'testid-backdrop',
	Flex = 'testid-flex',
	Avatar = 'avatar-testid',
	CustomisableColumn = 'customisable-column-',
	GeneralPermissionsSwitch = 'general-permissions-switch'
}

export enum SwitchState {
	EnabledOn,
	EnabledOff,
	DisabledOn,
	DisabledOff
}

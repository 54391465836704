import { getUnixTime } from 'date-fns';
import { nanoid as generate } from 'nanoid';
import { LastDaysChart, ResponseRateChart, ResponseRates } from 'components/Proms';
import { DistributionData } from 'api/data/dashboard';
import {
	LeftContainer,
	Title,
	RightContainer,
	Wrapper,
	LastDaysActivityContainer,
	ResponseChartContainer,
	ResponseRatesContainer
} from './DashboardPage.style';
import { Svgs } from 'environment';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { HSpacer } from 'components/UI/HSpacer';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePromManualDistribution,
	useProjectId,
	useDashboard,
	usePatients,
	useCollaborators,
	useVariables,
	usePermissions
} from 'hooks/store';

export function DashboardPage() {
	const { translate } = useTranslation();

	const { isPromManualDistribution } = usePromManualDistribution();
	const [projectId] = useProjectId();

	const { navigate, routes } = useNavigation();

	const [
		{
			data: { automatic: promStatistics, manual: listOfCreationDates },
			loading: loadingDashboard,
			fetched: isDashboardFetched
		}
	] = useDashboard();
	const [{ data: patients, loading: loadingPatients }] = usePatients({
		lazy: isPromManualDistribution
	});

	const [
		{
			data: { collaborators },
			loading: loadingCollaborators,
			fetched: areCollaboratorsFetched
		}
	] = useCollaborators({
		lazy: isPromManualDistribution
	});

	const [
		{
			data: { hasVariables }
		}
	] = useVariables({ initial: true, lazy: true, omit: { promGenerated: true } });

	const { hasVariablesWriteAccess } = usePermissions();

	function navigateFromDashboard() {
		if (!projectId) return;
		if (hasVariables) navigate(routes.proms.patients(projectId));
		else navigate(routes.proms.variables.view(projectId));
	}

	const loadingDashboardInitial = loadingDashboard && !isDashboardFetched;

	const noResponseRates = !promStatistics?.statisticPerDistribution.length;

	const loading = isPromManualDistribution
		? loadingDashboardInitial
		: loadingDashboardInitial || loadingPatients || loadingCollaborators;
	const immediate = isPromManualDistribution
		? !listOfCreationDates
		: !promStatistics || !patients || !areCollaboratorsFetched;

	return (
		<>
			<Header.Main />
			<Header.Navigation />
			<Header.Title title={'Dashboard'} />

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					{!isPromManualDistribution && promStatistics && !noResponseRates && (
						<Wrapper>
							{/* PROM - AUTOMATIC DISTRIBUTION */}
							<LeftContainer>
								<ResponseChartContainer>
									<Title>
										{translate(dict => dict.promsDashboard.overallResponseRate)}
									</Title>
									<ResponseRateChart
										{...promStatistics.statisticTotals}
										totalPatients={patients?.length ?? 0}
										totalCollaborators={collaborators.length}
									/>
								</ResponseChartContainer>
								<Spacer size={s => s.m} />
								<LastDaysActivityContainer>
									<Title>{translate(dict => dict.promsDashboard.activity)}</Title>
									<LastDaysChart data={promStatistics.distributionTimeLine} />
								</LastDaysActivityContainer>
							</LeftContainer>
							<HSpacer />
							{/* RESPONSE RATES */}
							<RightContainer>
								<Title>
									{translate(dict => dict.promsDashboard.responseRates)}
								</Title>
								<ResponseRatesContainer>
									{promStatistics.statisticPerDistribution
										.map((distribution, index) => (
											<ResponseRates
												key={generate()}
												promData={distribution}
												position={index + 1}
											/>
										))
										.reverse()}
								</ResponseRatesContainer>
							</RightContainer>
						</Wrapper>
					)}

					{/* PROM - MANUAL DISTRIBUTION */}
					{isPromManualDistribution && listOfCreationDates?.length ? (
						<>
							<Title>{translate(dict => dict.promsDashboard.activity)}</Title>
							<LastDaysChart
								data={
									listOfCreationDates.map(date => ({
										date,
										timeInMillis: getUnixTime(new Date(date)),
										filledOnDate: 1
									})) as DistributionData[]
								}
							/>
						</>
					) : (
						noResponseRates && (
							<Flex align={a => a.center} column>
								{/* NO ENTRIES */}
								{
									<>
										<Spacer size={s => s.xxl} />
										<Svgs.EmptyStatesNoVariable style={{ minHeight: 240 }} />
										<Spacer size={s => s.m} />
										<Typography.H3>
											{translate(
												dict => dict.promsDashboard.noActivityToDisplay
											)}
										</Typography.H3>

										{(hasVariablesWriteAccess || hasVariables) && (
											<>
												<Spacer size={s => s.xs} />
												<Typography.Paragraph>
													{translate(dict =>
														hasVariables
															? isPromManualDistribution
																? dict.promsDashboard
																		.startCreatingFirstDistribution
																: dict.promsDashboard
																		.startCreatingFirstRecipient
															: dict.promsDashboard
																	.startCreatingFirstVariable
													)}
													<Button
														title={translate(dict =>
															hasVariables
																? dict.promsDashboard.patientsPage
																: dict.promsDashboard.variablesPage
														)}
														variant={v => v.link}
														onClick={navigateFromDashboard}
													/>
												</Typography.Paragraph>
											</>
										)}
									</>
								}
							</Flex>
						)
					)}
					<Spacer size={s => s.xxl} />
				</Grid.Container>
			</Suspend>
		</>
	);
}

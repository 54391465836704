import { State, DateFilterOperator } from './types';

const initialState: State = {
	projectId: null,
	projectFiles: null,
	filters: {
		type: {
			all: [],
			active: []
		},
		uploadDate: {
			valid: false,
			operator: DateFilterOperator.Before,
			value: null,
			from: null,
			to: null
		}
	},
	metadata: {
		fileToView: null,
		csvFileToView: null,
		pageIndex: 0,
		pageSize: 25,
		searchTerm: ''
	}
};

export default initialState;

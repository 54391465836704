export enum ApiImportErrorType {
	InvalidRequest = 'error.invalidRequest',
	UnknownCodec = 'error.unknownCodec',
	NotSupportedExcelFile = 'error.notSupportedExcelFormat',
	MismatchingColumnNumber = 'error.mismatchingColumnNumber',
	ImportNoDateFormat = 'error.importNoDateFormat',
	ImportCouldNotParseValue = 'error.importCouldNotParseValue',
	NotAllowedFixedCategoryValue = 'error.notAllowedFixedCategoryValue',
	MissingValueForUniqueVariable = 'error.missingValueForUniqueVariable',
	DuplicateValueForUniqueVariable = 'error.duplicateValueForUniqueVariable',
	NotAllowedToSetValueForUniqueVariable = 'error.notAllowedToSetValueForUniqueVariable',
	MissingDurationFormat = 'error.missingDurationFormat',
	MismatchingValueType = 'error.mismatchingValueType',
	// TimeZone
	NonExistentDatetime = 'error.nonExistentDatetime',
	DatetimeExistsTwice = 'error.datetimeExistsTwice'
}

import { Modal } from 'components/UI/Modal';
import {
	Dependency,
	DependencyOperators,
	DependencyType,
	SubRows,
	TableDependency
} from 'store/data/dependencies';
import { DEPENDENCY_OPTIONS } from 'consts';
import {
	useDependenciesContext,
	useTranslation,
	useUpdateDependencies,
	useVariablesData
} from 'hooks/store';
import { useCompletedAction, useMutableState, usePaginate } from 'hooks/utils';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { useEffect, useMemo, useState } from 'react';
import { Flex } from 'components/UI/Flex';
import { isTableDependencyValid } from 'helpers/dependencies';
import { Input } from 'components/UI/Inputs/Input';
import { VariableType } from 'types/data/variables/constants';
import { InputType, SelectItem } from 'types/index';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { arrayUtils } from 'helpers/arrays';
import { TagInput } from 'components/UI/TagInput';
import { isEqual } from 'lodash';
import { Dropdown } from 'components/UI/Dropdown';
import { buildVariableCategoriesMap } from 'helpers/variables';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { Pagination } from 'components/UI/Pagination';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';

interface Props {
	onClose: () => void;

	rule: SubRows | TableDependency;
	dependencySuppliersSelectItems: { label: string; value: string }[];
	dependantVariablesSelectItems: { label: string; value: string }[];
	getDependantVariablesSelectItems: (dependency: TableDependency | SubRows) => SelectItem[];
	context: string | null;
	initialDependencies: { active: boolean; dependencies: Dependency[] };
	isCondition: boolean;
	collapseAllRules: () => void;
	parseOperatorCell: (value: string) => { label: string; value: DependencyOperators };
}

export function EditRuleOrConditionModal({
	onClose,

	rule,
	dependencySuppliersSelectItems,
	dependantVariablesSelectItems,
	getDependantVariablesSelectItems,
	context,
	initialDependencies,
	isCondition,
	collapseAllRules,
	parseOperatorCell
}: Props) {
	const { translate } = useTranslation();

	const title = isCondition
		? translate(dict => dict.dependencies.builder.tableView.options.editConditionTitle)
		: translate(dict => dict.dependencies.builder.tableView.options.editRuleTitle);

	const [draftTableDependency, setdraftTableDependency] = useMutableState(rule);

	const variablesData = useVariablesData({ initial: true });
	const { variablesMap } = variablesData;
	const variable = variablesMap[draftTableDependency.dependantVariableName];
	const [editedDependantVariablesSelectItems, setEditedDependantVariablesSelectItems] = useState(
		dependantVariablesSelectItems
	);

	const supplierVariable = variablesMap[draftTableDependency.supplierVariableName];
	const supplierVariableType = supplierVariable.type;

	const [
		{ loading: updatingDependencies, error: errorUpdatingDependencies },
		updateDependencies
	] = useUpdateDependencies();
	const {
		actions: {
			changeDependencyType,
			changeDependencyDescription,
			changeDependencyVariable,
			setDependantSupplierValueCondition,
			changeDependantVariable,
			changeDependantOperator,
			setDependantFilteredValues
		}
	} = useDependenciesContext();

	const [draftDependencies, setDraftDependencies] = useMutableState(
		initialDependencies.dependencies
	);

	const [selectedCategories, setSelectedCategories] = useMutableState(
		draftTableDependency.filteredValues
	);

	// COLLAPSE  ALL RULES  ON MODAL OPENING
	useEffect(() => {
		collapseAllRules();
	}, []);

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(getSelectedCategoryItems(), {
		threshold: 10,
		pageSize: 10
	});

	const translateLabel = (label: string) => {
		switch (label) {
			case 'Visibility':
				return translate(dict => dict.dependencies.types.visibility);
			case 'Filtering':
				return translate(dict => dict.dependencies.types.filtering);
			default:
				return 'N/A';
		}
	};
	const dependencyOptions = DEPENDENCY_OPTIONS.map(item => {
		return {
			label: translateLabel(item.label),
			value: item.value
		};
	});

	const hasChanges = useMemo(
		() => !isEqual(rule, draftTableDependency),

		[draftTableDependency]
	);

	const areDependenciesValid = useMemo(() => {
		let valid = true;
		const invalidDependencyNames: string[] = [];

		const validDependency = isTableDependencyValid(draftTableDependency);
		if (!validDependency) {
			valid = false;
			invalidDependencyNames.push(draftTableDependency.supplierVariableName);
		}

		return valid;
	}, [draftDependencies, errorUpdatingDependencies]);

	const canUpdate = useMemo(
		() => hasChanges && areDependenciesValid,
		[hasChanges, areDependenciesValid]
	);

	function handleChangeDependantVariable(value: string) {
		setdraftTableDependency({
			...draftTableDependency,
			dependantVariableName: value
		});

		setSelectedCategories([]);
	}

	function handleDependencyTypeVariable(item: string) {
		setdraftTableDependency({
			...draftTableDependency,
			dependencyType: item as DependencyType
		});

		if (draftTableDependency.dependencyType === DependencyType.Visibility) {
			setSelectedCategories([]);
		}
	}

	// CHANGE OPERATOR CONFIGURATION

	function handleDependencyOperatorVariable(operator: string) {
		setdraftTableDependency({
			...draftTableDependency,
			operator: operator as DependencyOperators
		});
	}

	const shouldChangeOperator =
		supplierVariableType == VariableType.Float ||
		supplierVariableType == VariableType.Integer ||
		supplierVariableType == VariableType.Date ||
		supplierVariableType == VariableType.DateTime;

	const operatorOptions = Object.values(DependencyOperators).map(operator =>
		parseOperatorCell(operator)
	);

	useEffect(() => {
		setEditedDependantVariablesSelectItems(
			getDependantVariablesSelectItems(draftTableDependency)
		);
	}, [draftTableDependency.dependencyType]);

	const handleDependencyChanges = () => {
		changeDependencyVariable({
			dependencyName: draftTableDependency.dependencyName,
			supplierVariableName: draftTableDependency.supplierVariableName
		});
		changeDependencyType({
			dependencyName: draftTableDependency.dependencyName,
			dependencyType: draftTableDependency.dependencyType as DependencyType
		});

		changeDependantOperator({
			dependencyName: draftTableDependency.dependencyName,
			dependantId: draftTableDependency.dependantId,
			operator: draftTableDependency.operator as DependencyOperators
		});

		setDependantSupplierValueCondition({
			dependencyName: draftTableDependency.dependencyName,
			dependantId: draftTableDependency.dependantId,
			supplierValueCondition: draftTableDependency.supplierValueCondition
		});
		changeDependantVariable({
			dependencyName: draftTableDependency.dependencyName,
			dependantId: draftTableDependency.dependantId,
			dependantVariableName: draftTableDependency.dependantVariableName
		});

		handleCreate();

		changeDependencyDescription({
			dependencyName: draftTableDependency.dependencyName,
			description: draftTableDependency.description
		});
	};

	useEffect(() => {
		setdraftTableDependency({ ...draftTableDependency, filteredValues: selectedCategories });
	}, [selectedCategories]);

	const updateDraftArray = () => {
		setDraftDependencies(prevDependencies => {
			const editedDependencies = prevDependencies.map((dep: Dependency) => {
				const matchingTableDependency =
					draftTableDependency.dependencyName == dep.dependencyName;

				if (matchingTableDependency) {
					const updatedDependants = dep.dependantVariables.map(dependant => {
						const matchingDependant =
							draftTableDependency.dependantId === dependant.dependantId;
						if (matchingDependant) {
							return {
								...dependant,
								supplierValueCondition: draftTableDependency.supplierValueCondition,
								dependantVariableName: draftTableDependency.dependantVariableName,
								filteredValues: draftTableDependency.filteredValues,
								operator: draftTableDependency.operator as DependencyOperators
							};
						}

						return dependant;
					});
					return {
						...dep,
						supplierVariableName: draftTableDependency.supplierVariableName,
						description: draftTableDependency.description,
						dependencyType: draftTableDependency.dependencyType,
						dependantVariables: updatedDependants
					};
				}

				return dep;
			});
			return editedDependencies;
		});
	};

	useEffect(() => {
		updateDraftArray();
	}, [draftTableDependency]);

	// RESULT CONFIGURATION
	function onConfirm(categories: string[]) {
		setDependantFilteredValues({
			dependencyName: draftTableDependency.dependencyName,
			dependantId: draftTableDependency.dependantId,
			filteredValues: categories
		});
	}
	function handleCreate() {
		if (!isFormValid()) return;
		onConfirm(selectedCategories);
	}
	function isFormValid() {
		return selectedCategories.length > 0;
	}
	function onCheck(category: string) {
		setSelectedCategories(state => {
			if (state.includes(category)) return state.filter(c => c !== category);
			state.push(category);
		});
	}
	function onToggleAll(flag: boolean) {
		const categories = flag ? getVariableCategories() : [];
		setSelectedCategories(categories);
	}
	function getVariableCategories(): string[] {
		if (variable) return variable.categories.map(c => c.value);
		return [];
	}
	function getSelectCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];
		if (variable) {
			variable.categories.forEach(c => {
				const item: SelectItem = {
					label: c.label || c.value,
					value: c.value
				};
				items.push(item);
			});
		}
		return items;
	}
	function getSelectedCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];
		if (variable && draftTableDependency.filteredValues.length > 0) {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);
			selectedCategories.forEach(value => {
				const category = categoriesMap[value];
				const item: SelectItem = {
					label: category.label || category.value,
					value: category.value
				};
				items.push(item);
			});
		}
		return items;
	}

	function handleMoveCategoryValue(input: { sourceIndex: number; destinationIndex: number }) {
		const { sourceIndex, destinationIndex } = input;
		if (sourceIndex === destinationIndex) return;
		const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
			pageIndex,
			pageSize
		});
		setSelectedCategories(state =>
			arrayUtils.move(state, absoluteIndexes.sourceIndex, absoluteIndexes.destinationIndex)
		);
	}

	// HANDLE MODAL  SUBMIT
	const handleFormSubmit = () => {
		handleDependencyChanges();
		if (!canUpdate) return;

		updateDependencies({
			active: initialDependencies.active,
			dependencies: draftDependencies,
			setName: context ?? undefined
		});
	};

	//  AVOID CLOSING THE MODAL IF THERE ARE ANY ERRORS
	useCompletedAction(
		updatingDependencies,
		errorUpdatingDependencies,
		// SUCCESS CALLBACK
		() => {
			onClose();
		}
	);

	return (
		<Modal
			title={title}
			visible
			onClose={onClose}
			close
			enterAsPrimaryOnClick
			primary={{
				label: translate(({ buttons }) => buttons.done),

				disabled: !canUpdate,
				onClick: handleFormSubmit,
				loading: updatingDependencies
			}}
			neutral={
				hasChanges && {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onClose
				}
			}
		>
			<Flex column>
				<Flex fullWidth marginOffset={{ bottom: 1.2 }}>
					{/* SUPPLIER VARIABLE */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							disabled={isCondition}
							label={translate(dict => dict.dependencies.builder.tableView.supplier)}
							className="creatable-select-small"
							items={dependencySuppliersSelectItems}
							value={dependencySuppliersSelectItems.find(
								item => item.value === draftTableDependency.supplierVariableName
							)}
							onValueSelected={item =>
								item &&
								setdraftTableDependency({
									...draftTableDependency,
									supplierVariableName: item
								})
							}
							canClear={false}
						/>
					</Flex>

					<Flex fullWidth>
						{/* DEPENDENCY TYPE  */}
						<CreatableSelect
							disabled={isCondition}
							label={translate(
								dict => dict.dependencies.builder.tableView.dependencyType
							)}
							className="creatable-select-small"
							items={dependencyOptions}
							value={dependencyOptions.find(
								item => item.value === draftTableDependency.dependencyType
							)}
							onValueSelected={item => item && handleDependencyTypeVariable(item)}
							canClear={false}
						/>
					</Flex>
				</Flex>
				<Flex>
					{/* DEPENDENCY OPERATOR  */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							disabled={!shouldChangeOperator}
							label={translate(dict => dict.dependencies.builder.tableView.operator)}
							className="creatable-select-small"
							items={operatorOptions}
							value={
								shouldChangeOperator
									? parseOperatorCell(draftTableDependency.operator)
									: parseOperatorCell(rule.operator)
							}
							onValueSelected={item => item && handleDependencyOperatorVariable(item)}
							canClear={false}
						/>
					</Flex>

					{/* SUPPLIER  VALUE CONDITION */}
					{[VariableType.Category, VariableType.CategoryMultiple].includes(
						supplierVariableType
					) ? (
						<CreatableSelect
							label={translate(dict => dict.dependencies.builder.tableView.value)}
							className="creatable-select-small"
							placeholder={translate(
								dict => dict.dependencies.directDependency.selectCategory
							)}
							value={
								draftTableDependency.supplierVariableName !== ''
									? {
											label: draftTableDependency.supplierValueCondition,
											value: draftTableDependency.supplierValueCondition
									  }
									: null
							}
							items={supplierVariable.categories.map(category => ({
								label: category.label,
								value: category.value
							}))}
							onValueSelected={value => {
								if (value) {
									setdraftTableDependency({
										...draftTableDependency,
										supplierValueCondition: value
									});
								}
							}}
							canClear={false}
						/>
					) : (
						<Flex fullWidth>
							{/* NUMERIC (INTEGER / FLOAT) */}
							{(supplierVariableType === VariableType.Float ||
								supplierVariableType === VariableType.Integer) && (
								<>
									<Input
										label={translate(
											dict => dict.dependencies.builder.tableView.value
										)}
										className="input-small"
										type={InputType.Number}
										value={draftTableDependency.supplierValueCondition}
										onChange={e =>
											setdraftTableDependency({
												...draftTableDependency,
												supplierValueCondition: e.target.value
											})
										}
									/>
								</>
							)}

							{/* DATE || DATETIME*/}
							{(supplierVariableType === VariableType.Date ||
								supplierVariableType === VariableType.DateTime) && (
								<>
									{supplierVariableType === VariableType.Date && (
										<Input
											label={translate(
												dict => dict.dependencies.builder.tableView.value
											)}
											className="date-input-small"
											type={InputType.Date}
											value={draftTableDependency.supplierValueCondition}
											onDateChange={({ formattedDate: from }) =>
												setdraftTableDependency({
													...draftTableDependency,
													supplierValueCondition: from
												})
											}
										/>
									)}
									{supplierVariableType === VariableType.DateTime && (
										<Flex fullWidth>
											<DateTimeInput
												value={draftTableDependency.supplierValueCondition}
												onChange={value =>
													setdraftTableDependency({
														...draftTableDependency,
														supplierValueCondition: value
													})
												}
												options={{
													small: true,
													label: translate(
														dict =>
															dict.dependencies.builder.tableView
																.value
													)
												}}
											/>
										</Flex>
									)}
								</>
							)}
							{/* STRING */}
							{supplierVariable.type === VariableType.String && (
								<>
									<Input
										label={translate(
											dict => dict.dependencies.builder.tableView.value
										)}
										className="input-small"
										type={InputType.Text}
										value={draftTableDependency.supplierValueCondition}
										onChange={e =>
											setdraftTableDependency({
												...draftTableDependency,
												supplierValueCondition: e.target.value
											})
										}
									/>
								</>
							)}
						</Flex>
					)}
				</Flex>

				<Flex fullWidth marginOffset={{ top: 1.2 }}>
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						{/* DEPENDANT VARIABLE */}
						<CreatableSelect
							label={translate(
								dict => dict.dependencies.builder.tableView.dependantVariables
							)}
							className="creatable-select-small"
							placeholder={translate(
								dict => dict.dependencies.directDependency.selectVariable
							)}
							value={editedDependantVariablesSelectItems.find(
								item => item.value === draftTableDependency.dependantVariableName
							)}
							items={editedDependantVariablesSelectItems}
							onValueSelected={value => {
								if (value) {
									handleChangeDependantVariable(value);
								}
							}}
							canClear={false}
						/>
					</Flex>

					{/* FILTERED VALUES  - RESULT */}

					<Flex fullWidth column>
						<Dropdown
							disabled={
								draftTableDependency.dependencyType !== DependencyType.Filtering
							}
							label={translate(dict => dict.dependencies.builder.tableView.result)}
							title={`${selectedCategories.length} ${
								selectedCategories.length === 1
									? translate(dict => dict.terms.value)
									: translate(dict => dict.terms.values)
							} ${translate(dict => dict.terms.selected)}`}
							button
						>
							<Flex paddingOffset={{ x: 1.2, y: 1.6 }}>
								<SelectMultiple
									items={getSelectCategoryItems()}
									onSelect={item => onCheck(item.value)}
									selectedItems={selectedCategories}
									onToggleAll={onToggleAll}
								/>
							</Flex>
						</Dropdown>
					</Flex>
				</Flex>

				{draftTableDependency.filteredValues.length > 0 && (
					<Flex column fullWidth marginOffset={{ top: 1.2 }}>
						<InputLabel
							label={translate(
								dict =>
									dict.dependencies.dependantFiltering
										.configureFilteringValuesModal.previewSelected.label
							)}
						/>

						{shouldPaginate && (
							<Flex marginOffset={{ bottom: 0.4 }}>
								<Pagination
									totalCountLabel={translate(dict => dict.terms.values)}
									pageIndex={pageIndex}
									pageSize={pageSize}
									pagesCount={pagesCount}
									changePage={changePage}
									changePageSize={changePageSize}
									totalCount={getSelectedCategoryItems().length}
								/>
							</Flex>
						)}

						<TagInput
							hint={translate(
								dict =>
									dict.dependencies.dependantFiltering
										.configureFilteringValuesModal.previewSelected.hint
							)}
							items={selectedCategoryItems}
							onMove={handleMoveCategoryValue}
							readOnly
							sortable
						/>
					</Flex>
				)}
				<Flex fullWidth marginOffset={{ top: 1.2 }}>
					{/* DEPENDENCY DESCRIPTION */}
					<Input
						disabled={isCondition}
						type={InputType.Textarea}
						label={translate(
							dict => dict.dependencies.builder.tableView.ruleDescription
						)}
						className="description-input"
						value={draftTableDependency.description}
						onChange={e =>
							setdraftTableDependency({
								...draftTableDependency,
								description: e.target.value
							})
						}
					/>
				</Flex>
			</Flex>
		</Modal>
	);
}

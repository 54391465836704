import { DefaultCollaboratorPermissions } from 'consts';
import {
	CollaboratorPermissions,
	StatusTypeAccess,
	StatusTypeAccesses
} from 'store/data/collaborators';
import { Status } from 'store/data/statuses';

export function buildInitialPermissions(
	permissions: CollaboratorPermissions = DefaultCollaboratorPermissions,
	statuses: Status[]
) {
	const computedPermissions: CollaboratorPermissions = { ...permissions };

	const statusAccesses: StatusTypeAccesses = [];

	statuses.forEach(status => {
		const { name } = status;

		const accesses = computedPermissions.statusTypeAccesses?.find(
			s => s.statusTypeVariableName === name
		);

		const statusAccess: StatusTypeAccess = {
			statusTypeVariableName: name,
			viewData: accesses?.viewData ?? false,
			editData: accesses?.editData ?? false,
			setStatus: accesses?.setStatus ?? false
		};

		statusAccesses.push(statusAccess);
	});

	if (statusAccesses.length) computedPermissions.statusTypeAccesses = statusAccesses;

	return computedPermissions;
}

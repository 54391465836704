import { Card } from 'components/UI/Card';
import { TemplateRole } from 'store/data/roles';
import { RoleTemplateShareLevel } from 'types/index';
import { useTranslation } from 'hooks/store';

interface SharePayload {
	templateRole: TemplateRole;
	shareLevel: RoleTemplateShareLevel;
}

interface Props {
	templateRole: TemplateRole;
	actions: {
		onEdit: (templateRole: TemplateRole) => void;
		onDelete: (templateRole: TemplateRole) => void;
		onShare: (input: SharePayload) => void;
	};
	isShareCard: boolean;
}

export function TemplateRoleCard({ templateRole, actions, isShareCard }: Props) {
	const { translate } = useTranslation();

	return (
		<Card onClick={() => actions.onEdit(templateRole)}>
			<Card.Header
				title={templateRole.name}
				menuItems={[
					{
						label: translate(
							dict => dict.rolesPage.shareRoleTemplateModal.shareAccrossProjects
						),
						action: () =>
							actions.onShare({
								templateRole: templateRole,
								shareLevel: RoleTemplateShareLevel.ShareWithProjects
							})
					},
					{
						label: translate(
							dict => dict.rolesPage.shareRoleTemplateModal.shareWithUsers
						),
						action: () =>
							actions.onShare({
								templateRole,
								shareLevel: RoleTemplateShareLevel.ShareWithUsers
							})
					},
					{
						label: translate(dict => dict.terms.delete),
						action: () => actions.onDelete(templateRole)
					}
				]}
				showCardMenu={!isShareCard}
				data={templateRole}
			/>
			<Card.Body>{templateRole.description}</Card.Body>
		</Card>
	);
}

import { isEqual } from 'lodash';
import { useEffect, useMemo, useState, useRef } from 'react';
import {
	CellProps,
	Column,
	ColumnInstance,
	FilterProps,
	useFilters,
	usePagination,
	useSortBy,
	useTable
} from 'react-table';

import { ProjectOwnership, ProjectStatus } from 'types/data/projects/constants';
import {
	ColumnSettings,
	DeleteProjectModal,
	EditProjectModal,
	FilterColumn,
	getOperator,
	LeaveProjectModal,
	ConvertProjectModal,
	mapColumnNameToType,
	mapColumnNameToValue,
	ProjectDescriptionModal,
	ProjectsGrid,
	ProjectsTable
} from 'components/Projects';
import { Svgs } from 'environment';
import {
	ProjectData,
	ProjectStatusData,
	ProjectsViewOptions,
	ProjectTableViewData,
	ProjectSort,
	ProjectOptions,
	selectProjectsRows
} from 'store/data/projects';
import { ProjectMetadataName, StringMap, TableName } from 'types/index';
import { CollaboratorPermissions } from 'store/data/collaborators';
import { CopyProjectModal } from 'components/Projects/CopyProjectModal/CopyProjectModal';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { Icon } from 'components/UI/Icons';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { getProjectDefaultOperator } from 'helpers/filters';
import { stringAsBoolean } from 'helpers/generic';
import { getPermissions } from 'helpers/projects';
import { useMatchProms, useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useProjectsSearchTerm,
	useActiveProjectOwnership,
	useResetProjectsFilters,
	useProjectId,
	useProjectsTableFilters,
	useActiveProjectStatus,
	useProjectsSort,
	useUsername,
	useProjectsViewOption,
	useProjectsTableVisibleColumns,
	useFilters as useFiltersCustom,
	useScopedProjects,
	useProjectMetadataDefinition
} from 'hooks/store';
import { useDispatch, useEffectOnce, usePrevious, useSelector } from 'hooks/utils';
import { setActiveSortAction } from 'store/ui/tables';
import { DEFAULT_GET_ENTRIES_SORT } from 'store/data/entries';
import { parseApiSortToActiveSort } from 'helpers/entries';
import { PROJECT_METADATA_LOCAL_IDENTIFIER } from 'consts';

const IS_SHARING_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_SHARING!);

const SHOW_PROMS_ROUTE = stringAsBoolean(process.env.REACT_APP_SHOW_PROMS_ROUTE!);

export function ProjectsPage() {
	const dispatch = useDispatch();
	const matchProms = useMatchProms();
	const { navigate, routes, promOrProject } = useNavigation();
	const { translate } = useTranslation();

	const baseDictKey = matchProms ? ProjectOptions.prom : ProjectOptions.project;

	const [{ data: ids, loading, fetched }] = useScopedProjects();

	const [{ data: projectMetadataDefinition }] = useProjectMetadataDefinition();

	const [searchTerm, setSearchTerm] = useProjectsSearchTerm();
	const [{ data: activeOwnership }] = useActiveProjectOwnership();
	const resetProjectsFilters = useResetProjectsFilters();
	const [, setProjectId] = useProjectId();
	const projects = useSelector(state => selectProjectsRows(state.data.projects, ids));

	const [filtersData, setTableFilters] = useProjectsTableFilters();
	const [status] = useActiveProjectStatus();
	const [sort] = useProjectsSort();
	const [description, setDescription] = useState<string | null>(null);
	const [editProjectId, setEditProjectId] = useState<string | null>(null);
	const [leaveProjectId, setLeaveProjectId] = useState<string | null>(null);
	const [deleteProjectId, setDeleteProjectId] = useState<string | null>(null);
	const [convertProjectToPROMId, setConvertProjectToPROMId] = useState<string | null>(null);
	const [copyProjectId, setCopyProjectId] = useState<string | null>(null);

	const statusItems = [
		ProjectStatusData.all,
		ProjectStatusData.ongoing,
		ProjectStatusData.onHold,
		ProjectStatusData.ended
	];

	const translatedStatus: StringMap = {
		[ProjectStatus.Ongoing]: translate(({ displayers }) => displayers.ongoing),
		[ProjectStatus.OnHold]: translate(({ displayers }) => displayers.onHold),
		[ProjectStatus.Ended]: translate(({ displayers }) => displayers.ended)
	};

	const allColumnNames = [
		ProjectTableViewData.title,
		ProjectTableViewData.collaborators,
		// metadata
		...(projectMetadataDefinition.length > 0
			? [ProjectTableViewData.metadataArchiveNumber, ProjectTableViewData.metadataProjectType]
			: []),
		ProjectTableViewData.endDate,
		ProjectTableViewData.status,
		ProjectTableViewData.entries,
		ProjectTableViewData.number
	];

	const metadataArchiveNumber = projectMetadataDefinition
		? projectMetadataDefinition.find(
				metadata =>
					metadata.name ===
					PROJECT_METADATA_LOCAL_IDENTIFIER + ProjectMetadataName.archiveNumber
		  )
		: null;
	const metadataProjectType = projectMetadataDefinition
		? projectMetadataDefinition.find(
				metadata =>
					metadata.name ===
					PROJECT_METADATA_LOCAL_IDENTIFIER + ProjectMetadataName.projectType
		  )
		: null;

	const translatedColumn: StringMap = {
		[ProjectTableViewData.title]: translate(({ projects }) => projects.tableView.title),
		[ProjectTableViewData.collaborators]: translate(
			({ projects }) => projects.tableView.collaborators
		), // metadata
		...(projectMetadataDefinition.length > 0 && {
			[ProjectTableViewData.metadataArchiveNumber]: metadataArchiveNumber
				? translate(() => metadataArchiveNumber.label)
				: '',
			[ProjectTableViewData.metadataProjectType]: metadataProjectType
				? translate(() => metadataProjectType.label)
				: ''
		}),
		[ProjectTableViewData.endDate]: translate(({ projects }) => projects.tableView.endDate),
		[ProjectTableViewData.status]: translate(({ projects }) => projects.tableView.status),
		[ProjectTableViewData.entries]: translate(({ projects }) => projects.tableView.entries),
		[ProjectTableViewData.number]: translate(({ projects }) => projects.tableView.number)
	};

	const username = useUsername();

	const [viewOption, setViewOption] = useProjectsViewOption();
	const isGridView = viewOption === ProjectsViewOptions.GRID;

	const [visibleColumnNames] = useProjectsTableVisibleColumns();

	function toggleView() {
		setViewOption(isGridView ? ProjectsViewOptions.TABLE : ProjectsViewOptions.GRID);
	}

	function onEditProject(projectId: string) {
		setEditProjectId(projectId);
	}

	function onLeaveProject(projectId: string) {
		setLeaveProjectId(projectId);
	}

	function onDeleteProject(projectId: string) {
		setDeleteProjectId(projectId);
	}
	function onCopyProject(projectId: string) {
		setCopyProjectId(projectId);
	}
	function onReadMore(description: string) {
		setDescription(description);
	}

	function onConvertProjectToPROM(projectId: string) {
		setConvertProjectToPROMId(projectId);
	}

	function onCreateClick() {
		navigate(matchProms ? routes.proms.create : routes.projects.create);
	}

	function onShareProject(projectId: string) {
		setProjectId(projectId);
		navigate(routes[promOrProject].collaborators.view(projectId));
	}

	function onSelectDataset(projectId: string) {
		setProjectId(projectId);
		navigate(routes[promOrProject].dataset.view(projectId));
	}

	function onSelectAnalysis(projectId: string) {
		setProjectId(projectId);
		navigate(routes[promOrProject].analysis(projectId));
	}

	function getUrl(projectId: string, userAccess?: CollaboratorPermissions) {
		let url = '';

		const { hasAnalysisAccess, hasDatasetReadAccess, hasVariablesAccess, hasModulesAccess } =
			getPermissions(userAccess);

		setProjectId(projectId);

		if (projectId) {
			if (matchProms) {
				url = routes.proms.dashboard(projectId);
			} else if (hasDatasetReadAccess) {
				url = routes.projects.dataset.view(projectId);
			} else if (hasAnalysisAccess) {
				url = routes.projects.analysis(projectId);
			} else if (hasModulesAccess.projectDesign && hasVariablesAccess) {
				url = routes.projects.variables.view(projectId);
			} else if (hasModulesAccess.collaborators) {
				url = routes.projects.collaborators.view(projectId);
			}
		}

		if (url) navigate(url);
	}

	function onEditVariables(projectId: string) {
		setProjectId(projectId);
		navigate(routes[promOrProject].variables.view(projectId));
	}

	function onProjectImport(projectId: string) {
		setProjectId(projectId);
		navigate(routes[promOrProject].import(projectId));
	}

	function goToDashboard(projectId: string) {
		setProjectId(projectId);
		navigate(routes.proms.dashboard(projectId));
	}

	function goToPatients(projectId: string) {
		setProjectId(projectId);
		navigate(routes.proms.patients(projectId));
	}

	function getHeaderTitle() {
		const headerTile = {
			[ProjectOwnership.All]: matchProms
				? translate(dict => dict.promsList.allPromsHeader)
				: translate(dict => dict.projects.allProjectsHeader),
			[ProjectOwnership.Own]: matchProms
				? translate(dict => dict.promsList.myPromsHeader)
				: translate(dict => dict.projects.myProjectsHeader),
			[ProjectOwnership.SharedWithMe]: translate(dict => dict.projects.sharedWithMeHeader)
		};

		const title = headerTile[activeOwnership];

		return title;
	}

	// reset entries active sort on leave project action
	useEffectOnce(() => {
		dispatch(
			setActiveSortAction({
				activeSort: parseApiSortToActiveSort(DEFAULT_GET_ENTRIES_SORT),
				tableName: TableName.Entries
			})
		);
	});

	function areAnyFiltersActive() {
		if (!isGridView) {
			return [
				searchTerm.trim().length > 0,
				filtersData.filters.length > 0,
				filtersData.sortBy.length > 0,
				sort !== ProjectSort.byNumber,
				status !== ProjectStatus.Ongoing,
				activeOwnership !== ProjectOwnership.All
			].some(Boolean);
		} else {
			return [
				searchTerm.trim().length > 0,
				sort !== ProjectSort.byNumber,
				status !== ProjectStatus.Ongoing,
				activeOwnership !== ProjectOwnership.All
			].some(Boolean);
		}
	}

	const filteredColumns = useMemo<string[]>(() => {
		let filtered = [...allColumnNames];

		if (visibleColumnNames.length) {
			filtered = filtered.filter(column => visibleColumnNames.includes(column));
		}

		return filtered;
	}, [visibleColumnNames, projectMetadataDefinition.length]);

	const tableColumns = useMemo<Column<ProjectData>[]>(
		() => filteredColumns.map(column => buildProjecsTableColumn(column)),
		[filteredColumns, matchProms]
	);

	function buildHeader(input: {
		title: string;
		column: ColumnInstance<ProjectData>;
		filter?: React.ReactNode;
	}) {
		const { title, column, filter } = input;

		return (
			<>
				<Flex
					align={a => a.center}
					css={`
						width: 100%;
						white-space: nowrap;

						.column-filter-icon {
							visibility: hidden;
						}

						:hover .column-filter-icon {
							visibility: visible;
						}
					`}
				>
					<Flex fullWidth>
						{title}

						<Icon
							style={{ opacity: column.isSorted ? 1 : 0 }}
							svg={column.isSortedDesc ? Svgs.ArrowDown : Svgs.ArrowUp}
							size={s => s.m}
							active
							propagate
						/>
					</Flex>
					{filter}
				</Flex>
			</>
		);
	}

	const tableRef = useRef<HTMLTableElement>(null);

	function buildProjecsTableColumn(title: string): Column<ProjectData> {
		return {
			Header: ({ column }) =>
				buildHeader({
					title: translatedColumn[title],
					column,
					filter: (
						<FilterColumn
							tableRef={tableRef}
							column={column}
							statuses={statusItems}
							label={translatedColumn[title]}
						/>
					)
				}),
			Filter: ({ column }: FilterProps<ProjectData>) => (
				<FilterColumn column={column} tableRef={tableRef} statuses={statusItems} />
			),
			filter: getOperator(
				filtersData.filters.filter(filter => filter.id === mapColumnNameToValue(title))[0]
					? filtersData.filters.filter(
							filter => filter.id === mapColumnNameToValue(title)
					  )[0].value?.operator
					: getProjectDefaultOperator(mapColumnNameToType(mapColumnNameToValue(title)))
			),
			accessor: mapColumnNameToValue(title),
			Cell: ({ value }: CellProps<ProjectData>) => {
				if (!value) return '';
				return value;
			}
		};
	}

	const instance = useTable(
		{
			columns: tableColumns,
			data: projects,
			initialState: {
				pageIndex: filtersData.pageIndex,
				pageSize: filtersData.pageSize,
				sortBy: filtersData.sortBy,
				filters: filtersData.filters
			},
			autoResetPage: false,
			autoResetSortBy: false
		},
		useFilters,
		useSortBy,
		usePagination
	);

	const { pageOptions, gotoPage, setPageSize, state, setAllFilters, setSortBy } = instance;

	const prevState = usePrevious(state);

	useEffect(() => {
		if (
			!isEqual(
				{
					pageIndex: state.pageIndex,
					pageSize: state.pageSize,
					sortBy: state.sortBy,
					filters: state.filters
				},
				filtersData
			)
		) {
			setAllFilters(filtersData.filters);
			setSortBy(filtersData.sortBy);
			setPageSize(filtersData.pageSize);
			gotoPage(filtersData.pageIndex);
		}
	}, [matchProms]);
	useEffect(() => {
		if (!isEqual(state, prevState) && prevState) {
			setTableFilters({
				filters: state.filters,
				pageIndex: state.pageIndex,
				pageSize: state.pageSize,
				sortBy: state.sortBy
			});
		}
	}, [state.filters, state.pageIndex, state.pageSize, state.sortBy]);

	function handleChangePageIndex(pageIndex: number) {
		gotoPage(pageIndex);
	}

	function handleChangePageSize(pageSize: number) {
		setPageSize(pageSize);
	}

	function projectOptions(projectOwner?: string, userAccess?: CollaboratorPermissions) {
		const isProjectOwner = projectOwner === username;
		const {
			hasAnalysisAccess,
			hasDatasetReadAccess,
			hasEditProjectAccess,
			hasEditProjectWriteAccess,
			hasVariablesWriteAccess,
			hasDatasetWriteAccess,
			hasVariablesAccess,
			hasModulesAccess
		} = getPermissions(userAccess);
		return [
			...(hasEditProjectAccess
				? [
						{
							title: translate(dict =>
								hasEditProjectWriteAccess
									? dict[baseDictKey].edit
									: dict[baseDictKey].view
							),
							onClick: onEditProject
						}
				  ]
				: []),
			...(isProjectOwner && !matchProms
				? [
						{
							title: translate(dict => dict[baseDictKey].copyProject),
							onClick: onCopyProject
						}
				  ]
				: []),
			...(IS_SHARING_ENABLED && hasModulesAccess.collaborators
				? [
						{
							title: translate(dict => dict[baseDictKey].collaborators),
							onClick: onShareProject
						}
				  ]
				: []),
			...(hasAnalysisAccess
				? [
						{
							title: translate(dict => dict[baseDictKey].analysis),
							onClick: onSelectAnalysis
						}
				  ]
				: []),
			...(hasDatasetReadAccess
				? [
						{
							title: translate(dict => dict[baseDictKey].dataset),
							onClick: onSelectDataset
						}
				  ]
				: []),
			...(!matchProms && hasDatasetWriteAccess && hasVariablesWriteAccess
				? [
						{
							title: translate(dict => dict.projectOptions.importDataset),
							onClick: onProjectImport
						}
				  ]
				: []),
			...(hasModulesAccess.projectDesign && hasVariablesAccess
				? [
						{
							title: translate(dict =>
								hasVariablesWriteAccess
									? dict.projectOptions.editVariable
									: dict.projectOptions.viewVariable
							),
							onClick: onEditVariables
						}
				  ]
				: []),
			...(matchProms
				? [
						{
							title: translate(dict => dict.projectOptions.viewDashboard),
							onClick: goToDashboard
						},
						{
							title: translate(dict => dict.projectOptions.viewPatients),
							onClick: goToPatients
						}
				  ]
				: []),
			...(!isProjectOwner
				? [
						{
							title: translate(dict => dict[baseDictKey].leaveProject),
							onClick: onLeaveProject
						}
				  ]
				: []),
			...(isProjectOwner
				? [
						{
							title: translate(dict => dict[baseDictKey].deleteProject),
							onClick: onDeleteProject
						}
				  ]
				: []),

			...(SHOW_PROMS_ROUTE && isProjectOwner
				? [
						{
							title: translate(dict => dict[baseDictKey].convert),
							onClick: onConvertProjectToPROM
						}
				  ]
				: [])
		];
	}

	function resetFilters() {
		if (isGridView) {
			resetProjectsFilters();
		} else {
			resetProjectsFilters();
			setAllFilters([]);
			setSortBy([]);
		}
	}

	const [{ areFiltersOpen }, { toggleOpenFilters }] = useFiltersCustom();

	function handleChangeAnalysisFilterState() {
		areFiltersOpen && toggleOpenFilters();
	}

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					fetched && (
						<Flex>
							<SearchInput
								usedInHeader
								term={searchTerm}
								placeholder={translate(dict => dict.terms.search)}
								onChangeTerm={value => setSearchTerm(value)}
							/>
							{addButton({
								label: matchProms
									? translate(dict => dict.projectsPage.addButton.newSurvey)
									: translate(dict => dict.projectsPage.addButton.newProject),
								action: onCreateClick
							})}
						</Flex>
					)
				}
			/>
			<Header.Title
				title={getHeaderTitle()}
				component={
					fetched && (
						<Flex>
							{/* CLEAR FILTERS */}
							{areAnyFiltersActive() && (
								<Button
									title={translate(dict => dict.terms.clearFilters)}
									variant={v => v.link}
									onClick={resetFilters}
									marginOffset={{ right: 2.4 }}
								/>
							)}

							<Icon
								title={
									isGridView
										? translate(dict => dict.terms.tableView)
										: translate(dict => dict.terms.gridView)
								}
								svg={isGridView ? Svgs.ViewTable : Svgs.ViewGrid}
								variant={v => v.buttonActive}
								onClick={toggleView}
							/>
						</Flex>
					)
				}
			/>

			<Suspend loading={loading} immediate={!fetched}>
				<Grid.Container>
					<Flex
						css={`
							width: 100%;
							.column-settings {
								margin-left: 1.6rem;
							}
						`}
						align={a => a.center}
						justify={j => j.between}
					>
						{/* ROWS PAGINATION */}

						{!isGridView && (
							<>
								<Pagination
									totalCount={projects.length}
									totalCountLabel={translate(dict => dict.pagination.projects)}
									pageSize={state.pageSize}
									filteredCount={projects.length}
									changePageSize={handleChangePageSize}
									pageIndex={state.pageIndex}
									pagesCount={pageOptions.length}
									changePage={handleChangePageIndex}
								/>

								<ColumnSettings
									className="column-settings"
									columns={allColumnNames}
									translatedColumn={translatedColumn}
								/>
							</>
						)}
					</Flex>

					<Spacer size={s => s.xs} />

					{isGridView ? (
						<ProjectsGrid
							instance={instance}
							ids={ids}
							translatedStatus={translatedStatus}
							statusItems={statusItems}
							onClick={(projectId: string, userAccess?: CollaboratorPermissions) => {
								handleChangeAnalysisFilterState();
								getUrl(projectId, userAccess);
							}}
							onReadMore={onReadMore}
							projectOptions={(
								projectOwner?: string,
								userAccess?: CollaboratorPermissions
							) => projectOptions(projectOwner, userAccess)}
						/>
					) : (
						<ProjectsTable
							size={filteredColumns.length}
							tableRef={tableRef}
							instance={instance}
							onReadMore={onReadMore}
							onClick={(projectId: string, userAccess?: CollaboratorPermissions) => {
								handleChangeAnalysisFilterState();
								getUrl(projectId, userAccess);
							}}
							projectOptions={(
								projectOwner?: string,
								userAccess?: CollaboratorPermissions
							) => projectOptions(projectOwner, userAccess)}
							translatedStatus={translatedStatus}
						/>
					)}
				</Grid.Container>

				{description && (
					<ProjectDescriptionModal
						description={description}
						onClose={() => setDescription(null)}
					/>
				)}
				{editProjectId && (
					<EditProjectModal
						projectId={editProjectId}
						deleteModalVisible={!!deleteProjectId}
						onDelete={() => setDeleteProjectId(editProjectId)}
						onClose={() => setEditProjectId(null)}
					/>
				)}
				{leaveProjectId && (
					<LeaveProjectModal
						projectId={leaveProjectId}
						onClose={() => setLeaveProjectId(null)}
					/>
				)}
				{deleteProjectId && (
					<DeleteProjectModal
						projectId={deleteProjectId}
						onClose={success => {
							if (success && editProjectId) setEditProjectId(null);

							setDeleteProjectId(null);
						}}
					/>
				)}
				{convertProjectToPROMId && (
					<ConvertProjectModal
						projectId={convertProjectToPROMId}
						onClose={() => setConvertProjectToPROMId(null)}
					/>
				)}

				{copyProjectId && (
					<CopyProjectModal
						projectId={copyProjectId}
						onClose={success => {
							if (success && editProjectId) setEditProjectId(null);

							setCopyProjectId(null);
						}}
					/>
				)}
			</Suspend>
		</>
	);
}

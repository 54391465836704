import { SpacingOffsets } from 'types/index';
import {
	Container,
	Text,
	RadioButton as RadioButtonBody,
	RadioButtonCheck,
	Description
} from './RadioButton.style';
import { Disabled } from 'helpers/cssGenerators';
import { Flex } from 'components/UI/Flex';

interface Props extends SpacingOffsets, Disabled {
	selected: boolean;
	label?: string;
	className?: string;
	description?: string;
	id?: string;
	inline?: boolean;
	dataTestId?: string;
	onSelect: () => void;
}

export function RadioButton({
	label,
	selected,
	disabled,
	className,
	description,
	id,
	dataTestId = 'radio-button',
	marginOffset,
	paddingOffset,
	inline = false,
	onSelect
}: Props) {
	function onClick(e: React.MouseEvent) {
		e.stopPropagation();

		if (!disabled) {
			onSelect();
		}
	}

	return (
		<Container
			inline={inline}
			data-testid={dataTestId}
			selected={selected}
			disabled={disabled}
			className={className}
			paddingOffset={paddingOffset}
			marginOffset={marginOffset}
			onClick={onClick}
			id={id}
			hasDescription={!!description}
		>
			<RadioButtonBody>
				{selected && <RadioButtonCheck data-testid="check-button" />}
			</RadioButtonBody>

			<Flex column>
				{label && (
					<Text title={label} id={label?.replaceAll(' ', '').toLowerCase()}>
						{label}
					</Text>
				)}
				{description && <Description>{description}</Description>}
			</Flex>
		</Container>
	);
}

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: calc(100vh - 15.6rem);

	@media ${MediaQueries.maxWidth.lg} {
		height: auto;
	}
`;

import { useMemo, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { DynamicFormValues } from 'store/data/entries';
import { SetState } from 'types/index';
import { getFormInputsValidation } from 'helpers/entries';
import { withMemo } from 'helpers/HOCs';
import {
	usePatientLoginParams,
	useUpdateEntry,
	useVariables,
	useForms,
	useForm
} from 'hooks/store';

interface Props {
	setPartiallyFilled: SetState<boolean>;
}

// THE REASON WE HAVE THE LOGIC INSIDE ANOTHER FUNCTIONAL COMPONENT
// IS TO ISOLATE THE RE-RENDER caused by `useWatch` hook
function Component({ setPartiallyFilled }: Props) {
	const [params] = usePatientLoginParams();
	const hasFormId = !!params?.formId;

	const [{ loading: updatingEntry }] = useUpdateEntry();

	const [
		{
			data: { variableNamesOutsideSets, variablesMap }
		}
	] = useVariables({
		initial: true,
		lazy: true,
		omit: { promGenerated: true }
	});

	const [{ data: form }] = useForm({ lazy: true });
	const [{ data: forms }] = useForms({ lazy: true });

	const variableNames = useMemo(() => {
		const names: string[] = [];

		if (hasFormId) {
			if (form) names.push(...form.usedVariables);
		} else {
			// CUSTOM FORM
			if (form) {
				const firstActiveForm = forms.find(form => form.active);

				if (firstActiveForm) names.push(...firstActiveForm.usedVariables);
			}
			// DEFAULT FORM
			else {
				names.push(...variableNamesOutsideSets);
			}
		}

		// FILTER DUPLICATES
		const uniqueNames = [...new Set(names)];

		return uniqueNames;
	}, [hasFormId, form, forms, variableNamesOutsideSets]);

	const values = useWatch({}) as DynamicFormValues;

	useEffect(
		() => handlePartiallyFilled(variableNames, values, updatingEntry),
		[variableNames, values, updatingEntry]
	);

	function handlePartiallyFilled(
		variableNames: string[],
		values: DynamicFormValues,
		updatingEntry: boolean
	) {
		if (updatingEntry) return;

		const { areInputsFilled } = getFormInputsValidation({
			variableNames,
			values,
			variablesMap
		});

		setPartiallyFilled(!areInputsFilled);
	}

	return null;
}

export const PartiallyFilledChecker = withMemo(Component);

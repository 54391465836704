import { State } from './types';

const initialState: State = {
	accountDetails: null,
	subscriptionDetails: null,
	subscriptionApiKey: null,
	subscriptionStatus: null,
	isNonSubscriber: false,
	subscriptionUsers: {
		byId: {},
		searchTerm: ''
	},
	licenceModels: {
		allLicenceModels: [],
		licenceModelsMetadataById: {},
		fetched: false
	}
};

export default initialState;

import { useSelector } from 'hooks/utils';
import { useDispatch, useEffectOnce } from 'hooks/utils';

import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { selectActiveSort, setActiveSortAction } from 'store/ui/tables';
import { TableName } from 'types/index';
import type { ActiveSort, TableSort } from 'store/ui/tables/types';
import { SortingType } from 'store/ui/tables/types';

interface Options {
	lazy?: boolean;
}

export function useTableSort(
	tableName: TableName,
	initialSort: TableSort = null,
	options?: Options
) {
	const dispatch = useDispatch();

	const lazy = !!options?.lazy;

	const activeSort = useSelector(state => selectActiveSort(state.ui.tables, tableName));

	useEffectOnce(() => {
		if (!lazy && activeSort === undefined) {
			setActiveSort(initialSort);
		}
	});

	function setActiveSort(activeSort: TableSort) {
		dispatch(setActiveSortAction({ activeSort, tableName }));
	}

	function handleSort(columnName: string): TableSort {
		const asc: ActiveSort = {
			column: columnName,
			order: SortingType.Asc
		};
		const desc: ActiveSort = {
			column: columnName,
			order: SortingType.Desc
		};

		if (!activeSort) {
			setActiveSort(asc);
			return asc;
		}

		const { column, order } = activeSort;

		const isSameColumn = column === columnName;
		const isOrderAsc = order === SortingType.Asc;

		if (isSameColumn) {
			const newSort = isOrderAsc ? desc : null;

			setActiveSort(newSort);
			return newSort;
		}

		setActiveSort(asc);
		return asc;
	}

	function getSortIcon(columnName: string, isOrderAsc: boolean) {
		// WORKAROUND UNTIL WE HAVE ARROW UP SVG FROM DESIGN TEAM
		return (
			<div style={{ transform: isOrderAsc ? 'unset' : 'rotate(180deg)' }}>
				<Icon
					svg={Svgs.ArrowDown}
					size={s => s.m}
					active
					onClick={() => handleSort(columnName)}
					marginOffset={{ x: 0.5 }}
				/>
			</div>
		);
	}

	function sortIcon(columnName: string) {
		if (activeSort?.column === columnName) {
			const isOrderAsc = activeSort?.order === SortingType.Asc;

			return getSortIcon(columnName, isOrderAsc);
		}
	}

	return { activeSort, handleSort, sortIcon };
}

import { nanoid as generate } from 'nanoid';
import { Offset } from 'types/index';

import { Svgs } from 'environment';
import { Icon } from 'components/UI/Icons';
import { Dropdown } from 'components/UI/Dropdown';
import { DataTestId } from 'tests/consts';

export interface MenuItem {
	label: string;
	dataTestId?: string;
	disabled?: boolean;
	handler?: () => void;
}

export interface MenuItemExtended extends MenuItem {
	submenu?: MenuItem[];
	left?: boolean;
}

interface Props {
	items: MenuItemExtended[];
	width?: number;
	offset?: Offset;
	className?: string;
	active?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
}

export function AdvancedMenu({
	items,
	width = 20,
	offset,
	className,
	active,
	onOpen,
	onClose
}: Props) {
	const onOptionsClick = (e: React.MouseEvent<Element, MouseEvent>, toggle: () => void) => {
		e.stopPropagation();
		toggle();
	};

	return (
		<div>
			<Dropdown
				className={className}
				shouldScrollIntoView="center"
				width={width}
				offset={offset}
				toggleComponent={({ ref, toggle }) => (
					<Icon
						svg={Svgs.More}
						ref={ref}
						onClick={e => onOptionsClick(e, toggle)}
						variant={v => v.button}
						marginOffset={{ left: 0.5 }}
						active={active}
						dataTestId={DataTestId.AdvancedMenuToggle}
					/>
				)}
				onOpen={onOpen}
				onClose={onClose}
			>
				{items.map(({ disabled, label, handler, submenu, left, dataTestId }) =>
					!submenu ? (
						<Dropdown.Item
							dataTestId={dataTestId}
							key={generate()}
							disabled={disabled}
							onClick={handler}
						>
							{label}
						</Dropdown.Item>
					) : (
						<Dropdown.Submenu
							disabled={disabled}
							title={label}
							key={generate()}
							left={left}
							width={15}
						>
							{submenu.map(({ disabled, label, handler, dataTestId }) => (
								<Dropdown.Item
									dataTestId={dataTestId}
									key={generate()}
									disabled={disabled}
									onClick={handler}
								>
									{label}
								</Dropdown.Item>
							))}
						</Dropdown.Submenu>
					)
				)}
			</Dropdown>
		</div>
	);
}

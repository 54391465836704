import { createSelector } from 'reselect';
import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getDashboard({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectAutomaticDistributionStatisticsForProm = createSelector(
	[getDashboard],
	dashboard => dashboard?.automaticDistributionStatistics
);

export const selectManualDistributionStatisticsForProm = createSelector(
	[getDashboard],
	dashboard => dashboard?.manualDistributionStatistics
);

export const selectIsDashboardFetched = createSelector(
	[getDashboard],
	dashboard => !!dashboard?.fetched
);

import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { Columns, DataModel, DensityPlotAnalysisV2 } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';

import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { Nullable, SelectItem } from 'types';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: DensityPlotAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function DensityPlotConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [{ areFiltersOpen }] = useFilters();

	const {
		input: { variables, dataModel, series }
	} = analysis;

	const [values, setValues] = useState(variables);

	const [{ data: variablesData }] = useVariables();
	const { variableSets } = variablesData;

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const { selectItemsMap: mainSelectItemsMap, selectItems: mainSelectItems } =
		variablesDataSelectItems;

	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}
		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [series, mainSelectItems, seriesSelectItems]);

	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.singleSeries),
			value: DataModel.series
		}
	];

	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [fullscreen] = useFullscreenAnalysis();
	const [activeColumn] = useAnalysesActiveColum();

	useDebounce(
		() => {
			if (!isEqual(analysis.input.variables, values)) {
				const updatedAnalysis: DensityPlotAnalysisV2 = {
					...analysis,
					input: {
						...analysis.input,
						variables: values
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	useEffect(() => {
		setValues(analysis.input.variables);
	}, [analysis.input.variables]);

	/**
	 *
	 * HANDLERS
	 */
	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: DensityPlotAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: {
					groupVariables: [],
					numericVariable: null
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = { ...analysis, input: { ...analysis.input, series } };

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input,
					variables: {
						numericVariable: null,
						groupVariables: []
					}
				}
			}
		});
	}

	function onSelectVariable(
		key: keyof DensityPlotAnalysisV2['input']['variables'],
		variableName: Nullable<string>
	) {
		if (!variableName) {
			setValues(values => ({
				...values,
				[key]: null
			}));
			return;
		}
		const setName = variablesLocation[variableName]?.setName;
		setValues(values => ({
			...values,
			[key]: {
				name: variableName,
				...(setName ? { series: setName } : {})
			}
		}));
	}

	function onSelectGroupVariable(value: Nullable<string>) {
		if (value === null || value === null) {
			return setValues(values => ({
				...values,
				groupVariables: []
			}));
		}
		const setName = variablesLocation[value as string]?.setName;
		setValues(values => ({
			...values,
			groupVariables: [{ name: value as string, series: setName }]
		}));
	}

	const allSelectItemsMap = { ...mainSelectItemsMap, ...seriesSelectItemsMap };

	return (
		<ConfigContainer
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as DensityPlotAnalysisV2} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({
						analysisId: analysis.id,
						parameters: !isParamsOpen
					})
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.correlationsV2.config.series
							)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.densityPlot.config.numeric
						)}
						items={numericSelectItems}
						value={allSelectItemsMap[values.numericVariable?.name ?? '']}
						onValueSelected={numericVariable =>
							onSelectVariable('numericVariable', numericVariable)
						}
						isItemDisabled={item => item.value === values.groupVariables?.[0]?.name}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.densityPlot.config.grouping
						)}
						placeholder={translate(
							({ analysis }) => analysis.analyses.densityPlot.config.noGrouping
						)}
						isItemDisabled={item => values.numericVariable?.name === item.value}
						items={groupingSelectItems}
						disabled={
							!groupingSelectItems.length ||
							!dataModel ||
							(dataModel === DataModel.series && !series)
						}
						value={allSelectItemsMap[values.groupVariables?.[0]?.name ?? ''] ?? null}
						onValueSelected={onSelectGroupVariable}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting
				analysis={analysis}
				isEnabled={variables.groupVariables?.length === 1}
				hasHistogram
			/>
		</ConfigContainer>
	);
}

import { ApiDashboardCard, LastCardAndPlaceholderPositions } from 'api/data/projectDashboard';
import { IndexPayload, CardPositionManual } from 'components/Account/AdminDashboard/AdminDashboard';
import {
	CardDraggableResizableProps,
	GroupedCardsDraggableResizableProps,
	GroupedThreeCardsDraggableResizableProps,
	ManualLayoutRows,
	PossibleLayouts,
	WidthSizes as AutomaticWidthSizes
} from 'components/Account/AdminDashboard/AdminDashboardAutomatic';

import { arrayUtils } from 'helpers/arrays';
import { nanoid as generate } from 'nanoid';
import {
	APIWidthSize,
	CardsMap,
	DashboardCard,
	emptyElementsOrderPlaceholders,
	GridCardsOrder,
	HeightSizes,
	LayoutColumnsTypes,
	WidgetHeightSize,
	WidgetWidthSize,
	WidthFiveColumnsLayout,
	WidthFourColumnsLayout,
	WidthSevenColumnsLayout,
	WidthSixColumnsLayout,
	WidthSizes
} from 'store/data/projectDashboard';
import { GenericMap } from 'types/index';
const { insert, replace, remove } = arrayUtils;

export function closestWidthSnap(value: number, widthSizes: AutomaticWidthSizes) {
	const { minSizeWidth, mediumSizeWidth, maxSizeWidth } = widthSizes;
	const allWidthSizes = [minSizeWidth, mediumSizeWidth, maxSizeWidth];

	let closest = allWidthSizes[0];
	for (const item of allWidthSizes) {
		if (Math.abs(item - value) < Math.abs(closest - value)) {
			closest = item;
		}
	}

	if (closest === minSizeWidth) return WidgetWidthSize.small;
	if (closest === mediumSizeWidth) return WidgetWidthSize.medium;
	return WidgetWidthSize.big;
}

export function closestWidthSnapDynamic(value: number, widthSizes: WidthSizes) {
	const { threeColumns, fourColumns, fiveColumns, sixColumns, sevenColumns } = widthSizes;
	const localWidthSizes =
		sevenColumns ?? sixColumns ?? fiveColumns ?? fourColumns ?? threeColumns;

	const firstSizeWidth = localWidthSizes?.firstSizeWidth ?? 0;
	const secondSizeWidth = localWidthSizes?.secondSizeWidth ?? 0;
	const thirdSizeWidth = localWidthSizes?.thirdSizeWidth ?? 0;
	const forthSizeWidth = (localWidthSizes as WidthFourColumnsLayout).forthSizeWidth ?? 0;
	const fifthSizeWidth = (localWidthSizes as WidthFiveColumnsLayout).fifthSizeWidth ?? 0;
	const sixthSizeWidth = (localWidthSizes as WidthSixColumnsLayout).sixthSizeWidth ?? 0;
	const seventhSizeWidth = (localWidthSizes as WidthSevenColumnsLayout).seventhSizeWidth ?? 0;

	const allWidthSizes = [firstSizeWidth, secondSizeWidth, thirdSizeWidth];
	forthSizeWidth && allWidthSizes.push(forthSizeWidth);
	fifthSizeWidth && allWidthSizes.push(fifthSizeWidth);
	sixthSizeWidth && allWidthSizes.push(sixthSizeWidth);
	seventhSizeWidth && allWidthSizes.push(seventhSizeWidth);

	let closest = allWidthSizes[0];
	for (const item of allWidthSizes) {
		if (Math.abs(item - value) < Math.abs(closest - value)) {
			closest = item;
		}
	}
	if (closest === firstSizeWidth) return APIWidthSize.one;
	if (closest === secondSizeWidth) return APIWidthSize.two;
	if (closest === thirdSizeWidth) return APIWidthSize.three;
	if (closest === forthSizeWidth) return APIWidthSize.four;
	if (closest === fifthSizeWidth) return APIWidthSize.five;
	if (closest === sixthSizeWidth) return APIWidthSize.six;
	return APIWidthSize.seven;
}

export function closestHeightSnap(value: number, heightSizes: HeightSizes) {
	const { minSizeHeight, maxSizeHeight } = heightSizes;
	const allHeightSizes = [minSizeHeight, maxSizeHeight];

	let closest = allHeightSizes[0];
	for (const item of allHeightSizes) {
		if (Math.abs(item - value) < Math.abs(closest - value)) {
			closest = item;
		}
	}

	if (closest === minSizeHeight) return WidgetHeightSize.small;
	return WidgetHeightSize.big;
}

export function isSingleElementCard(
	object:
		| GroupedCardsDraggableResizableProps
		| GroupedThreeCardsDraggableResizableProps
		| CardDraggableResizableProps
): object is CardDraggableResizableProps {
	return 'cardName' in object;
}

export function isCardType(
	object: string | CardDraggableResizableProps
): object is CardDraggableResizableProps {
	return typeof object !== 'string' && 'cardName' in object;
}

export function areThreeCardsGrouped(
	object:
		| CardDraggableResizableProps
		| GroupedCardsDraggableResizableProps
		| GroupedThreeCardsDraggableResizableProps
): object is GroupedThreeCardsDraggableResizableProps {
	return 'threeCards' in object;
}

export function fillPreviousRow(cardRows: PossibleLayouts) {
	const cardRowFilledManual = calculateCardRows(cardRows, 4);
	return cardRowFilledManual;
}

export function checkForCombinables(newElements: PossibleLayouts) {
	let rowPercentage = 0;
	let row = 1;

	const smallLayout = 33;
	const mediumLayout = 66;
	const bigLayout = 99;

	const newGrid: PossibleLayouts = [];
	const cards: GenericMap<CardDraggableResizableProps> = {};
	const automaticGrid: string[] = [];

	let canCombineWithNextCard = false;

	const rowsCompleteness: GenericMap<{
		elements: PossibleLayouts;
		maxHeightRow: boolean;
	}> = {};

	const manualCardsLayoutRow: GenericMap<{
		elements: (null | string | CardDraggableResizableProps)[][];
		maxHeightRow: boolean;
		fullHeightRows: number;
		// numberOfFullRows
	}> = {};

	newElements.forEach((elementRow, index) => {
		if (typeof elementRow === 'string') {
			if (!rowsCompleteness[row]) {
				rowsCompleteness[row] = {
					elements: [],
					maxHeightRow: false
				};
			}

			rowPercentage = rowPercentage + smallLayout;

			if (rowPercentage > bigLayout) {
				row = row + 1;
				rowPercentage = rowPercentage - bigLayout;
				canCombineWithNextCard = false;
			}
			rowsCompleteness[row].elements.push(elementRow);
			newGrid.push(elementRow);
		}
		//check grouped
		else if (!isSingleElementCard(elementRow)) {
			if (areThreeCardsGrouped(elementRow)) {
				rowPercentage = rowPercentage + mediumLayout;

				if (rowPercentage > bigLayout) {
					row = row + 1;
					rowPercentage = 0;
				}
				if (!rowsCompleteness[row]) {
					rowsCompleteness[row] = {
						elements: [],
						maxHeightRow: true
					};
				}
				rowsCompleteness[row].elements.push(elementRow);
				newGrid.push(elementRow);
				automaticGrid.push(elementRow.groupId);
				const destructuredCards = destructureCards(elementRow);
				Object.values(destructuredCards).forEach(card => (cards[card.cardId] = card));
			} else {
				const groupedTwoCards = elementRow as GroupedCardsDraggableResizableProps;
				const { elements } = groupedTwoCards;
				const { widthSize } = elements[0];
				const elementWidthPercentage =
					widthSize === WidgetWidthSize.small
						? smallLayout
						: widthSize === WidgetWidthSize.medium
						? mediumLayout
						: bigLayout;
				rowPercentage = rowPercentage + elementWidthPercentage;
				if (rowPercentage > bigLayout) {
					row = row + 1;
					rowPercentage = 0;
				}
				if (!rowsCompleteness[row]) {
					rowsCompleteness[row] = { elements: [], maxHeightRow: true };
				}
				if (elementWidthPercentage === bigLayout) {
					rowsCompleteness[row].elements.push(elementRow);
					newGrid.push(elementRow);
					row = row + 1;
					rowPercentage = 0;
				} else {
					newGrid.push(elementRow);
					rowsCompleteness[row].elements.push(elementRow);
				}
				automaticGrid.push(elementRow.groupId);
				canCombineWithNextCard = true;
				const destructuredCards = destructureCards(elementRow);
				Object.values(destructuredCards).forEach(card => (cards[card.cardId] = card));
			}
		} else {
			const { cardId, widthSize, heightSize } = elementRow;
			const elementWidthPercentage =
				widthSize === WidgetWidthSize.small
					? smallLayout
					: widthSize === WidgetWidthSize.medium
					? mediumLayout
					: bigLayout;
			rowPercentage = rowPercentage + elementWidthPercentage;

			if (rowPercentage > bigLayout) {
				row = row + 1;
				rowPercentage = rowPercentage - bigLayout;
				canCombineWithNextCard = false;
			}
			if (!rowsCompleteness[row]) {
				rowsCompleteness[row] = {
					elements: [],
					maxHeightRow: heightSize === WidgetHeightSize.big
				};
			}
			if (elementWidthPercentage === bigLayout) {
				rowsCompleteness[row].elements.push(cardId);
				newGrid.push(elementRow);
				cards[cardId] = elementRow;
				row = row + 1;
				rowPercentage = 0;
				canCombineWithNextCard = false;
			} else {
				newGrid.push(elementRow);
				cards[cardId] = elementRow;
				automaticGrid.push(elementRow.cardId);
				rowsCompleteness[row].elements.push(elementRow);
				if (!rowsCompleteness[row].maxHeightRow)
					rowsCompleteness[row].maxHeightRow = heightSize === WidgetHeightSize.big;
			}
			if (heightSize === WidgetHeightSize.small && canCombineWithNextCard) {
				const combinableGroup = newElements[
					index - 1
				] as GroupedCardsDraggableResizableProps;
				const combinableCard = newElements[index] as CardDraggableResizableProps;
				const threeCardsLayout: GroupedThreeCardsDraggableResizableProps = {
					groupId: generate(),
					threeCards: true,
					elements: [
						//@ts-ignore
						[combinableGroup.elements[0], combinableCard],
						[combinableGroup.elements[1]]
					]
				};
				automaticGrid.pop();
				automaticGrid.pop();
				automaticGrid.push(threeCardsLayout.groupId);
				newGrid.pop();
				newGrid.pop();
				newGrid.push(threeCardsLayout);
				rowsCompleteness[row].elements.pop();
				rowsCompleteness[row].elements.pop();
				rowsCompleteness[row].elements.push(threeCardsLayout);
				canCombineWithNextCard = false;
			} else {
				canCombineWithNextCard = false;
			}
		}
	});

	let fullHeightRows = 0;
	Object.values(rowsCompleteness).forEach((row, rowIndex) => {
		const rowFilled = fillPreviousRow(rowsCompleteness[rowIndex + 1].elements);
		if (!manualCardsLayoutRow[rowIndex]) {
			manualCardsLayoutRow[rowIndex] = {
				elements: [],
				maxHeightRow: rowsCompleteness[rowIndex + 1].maxHeightRow,
				fullHeightRows: rowsCompleteness[rowIndex]
					? rowsCompleteness[rowIndex].maxHeightRow
						? (fullHeightRows = fullHeightRows + 1)
						: fullHeightRows
					: 0
			};
		}
		manualCardsLayoutRow[rowIndex].elements = rowFilled;
	});

	return { newGrid, rowsCompleteness, cards, automaticGrid, manualCardsLayoutRow };
}

function destructureCards(
	elements: GroupedThreeCardsDraggableResizableProps | GroupedCardsDraggableResizableProps
) {
	const cards: GenericMap<CardDraggableResizableProps> = {};
	if (areThreeCardsGrouped(elements)) {
		elements.elements.forEach(elementRow =>
			elementRow.forEach(element => (cards[element.cardId] = element))
		);
	} else {
		elements.elements.forEach(element => (cards[element.cardId] = element));
	}
	return cards;
}

export function moveCardToLayout(rows: ManualLayoutRows, indexPayload: IndexPayload | null) {
	if (indexPayload === null) return;
	const { dragged, dropped } = indexPayload;

	const dragDroppedSameRowNumber = dragged.rowIndex === dropped.rowIndex;
	const dragDroppedSameRowNumberSameRowLevel =
		dragDroppedSameRowNumber && dragged.rowIndex === dropped.rowIndex;

	const draggedElement = rows[dragged.rowIndex].elements[dragged.rowIndex][dragged.colIndex];

	if (dragDroppedSameRowNumberSameRowLevel) {
		const filteredArray = replace(
			rows[dragged.rowIndex].elements[dragged.rowIndex],
			dragged.colIndex,
			'placeholder'
		);
		const finalArray = replace(filteredArray, dropped.colIndex, draggedElement);

		rows[dragged.rowIndex].elements[dropped.rowIndex] = finalArray;
	} else {
		const filteredArray = replace(
			rows[dragged.rowIndex].elements[dragged.rowIndex],
			dragged.colIndex,
			'placeholder'
		);
		rows[dragged.rowIndex].elements[dragged.rowIndex] = filteredArray;
		const finalArray = replace(
			rows[dropped.rowIndex].elements[dropped.rowIndex],
			dropped.colIndex,
			draggedElement
		);

		rows[dropped.rowIndex].elements[dropped.rowIndex] = finalArray;
	}
	if (
		typeof draggedElement !== 'string' &&
		draggedElement &&
		draggedElement.heightSize === WidgetHeightSize.big
	) {
		if (dragDroppedSameRowNumber) {
			const filteredArray = remove(
				rows[dragged.rowIndex].elements[dragged.rowIndex + 1],
				dragged.colIndex
			);
			const finalArray = insert(filteredArray, dropped.colIndex, null);
			rows[dragged.rowIndex].elements[dragged.rowIndex + 1] = finalArray;
		} else {
			const firstFilteredArray = replace(
				rows[dragged.rowIndex].elements[dragged.rowIndex],
				dragged.colIndex,
				'placeholder'
			);
			rows[dragged.rowIndex].elements[dragged.rowIndex] = firstFilteredArray;

			const secondFilteredArray = replace(
				rows[dragged.rowIndex].elements[dragged.rowIndex + 1],
				dragged.colIndex,
				'placeholder'
			);
			rows[dragged.rowIndex].elements[dragged.rowIndex + 1] = secondFilteredArray;

			const droppedArray = replace(
				rows[dropped.rowIndex].elements[dropped.rowIndex],
				dropped.colIndex,
				draggedElement
			);
			rows[dropped.rowIndex].elements[dropped.rowIndex] = droppedArray;

			const droppedFinalArray = replace(
				rows[dropped.rowIndex].elements[dropped.rowIndex + 1],
				dropped.colIndex,
				null
			);
			rows[dropped.rowIndex].elements[dropped.rowIndex + 1] = droppedFinalArray;
		}
	}

	return rows;
}

export function calculateCardRows(cardRows: PossibleLayouts, layoutSize: number) {
	const computedCardRows: (CardDraggableResizableProps | string | null)[][] = [];

	cardRows.forEach(layout => {
		if (!computedCardRows[0]) {
			computedCardRows[0] = [];
		}
		if (!computedCardRows[1]) {
			computedCardRows[1] = [];
		}

		if (typeof layout === 'string') {
			computedCardRows[0].push('placeholder');
			return computedCardRows[1].push('placeholder');
		}
		if (isSingleElementCard(layout)) {
			if (layout.heightSize === WidgetHeightSize.big) {
				computedCardRows[0].push(layout);
				return computedCardRows[1].push(null);
			}
			computedCardRows[0].push(layout);
			return computedCardRows[1].push('placeholder');
		}
		if (areThreeCardsGrouped(layout)) {
			return layout.elements.forEach((elementsRow, rowIndex) =>
				elementsRow.forEach((layoutElement, colIndex) => {
					computedCardRows[rowIndex].push(layoutElement);
					if (
						layoutElement.heightSize !== WidgetHeightSize.big &&
						rowIndex === 1 &&
						colIndex === 0
					) {
						computedCardRows[1].push('placeholder');
					}
				})
			);
		} else {
			layout.elements.forEach((elementRow, rowIndex) => {
				computedCardRows[rowIndex] && computedCardRows[rowIndex].push(elementRow);
			});
		}
	});

	if (computedCardRows[0].length < layoutSize) {
		const neededPlaceholders = layoutSize - computedCardRows[0].length;

		for (let i = 1; i <= neededPlaceholders; i++) {
			computedCardRows[0].push('placeholder');
			computedCardRows[1].push('placeholder');
		}
	}
	return computedCardRows;
}

export function isOccupiedByCard(element: string | null) {
	return !!(element && (typeof element !== 'string' || element !== 'placeholder'));
}

export function isPlaceholderFree(element: string | null, draggedId?: string) {
	const elementIsString = !!(element && typeof element === 'string');
	const placeholderFree = !!(elementIsString && element === 'placeholder');
	const dropzoneIsDraggedRoot = elementIsString && draggedId && element === draggedId;
	const dropzoneIsDragged = elementIsString && draggedId && element === `placeholder${draggedId}`;

	return placeholderFree || dropzoneIsDragged || dropzoneIsDraggedRoot;
}

export function getFirstAvailablePlaceholder(elementsOrder: GridCardsOrder) {
	let firstAvailablePlaceholder: CardPositionManual | undefined;

	// elementsOrder.forEach(rowsElements =>
	elementsOrder.forEach((row, rowIndex) => {
		row.forEach((elementId, colIndex) => {
			if (
				elementId &&
				typeof elementId === 'string' &&
				elementId === 'placeholder' &&
				firstAvailablePlaceholder === undefined
			) {
				firstAvailablePlaceholder = {
					rowIndex,
					colIndex
				};
			}
		});
	});
	// );

	return firstAvailablePlaceholder;
}

export function getLastCardAndPlaceholderPositions(elementsOrder?: GridCardsOrder) {
	const lastElementIndex: LastCardAndPlaceholderPositions = {
		card: null,
		placeholder: null
	};
	if (!elementsOrder) return { lastElementIndex };
	// elementsOrder.forEach((layout, ) =>
	elementsOrder.forEach((row, rowIndex) =>
		row.forEach((element, colIndex) => {
			// debugger;
			if (element !== 'placeholder')
				lastElementIndex.card = { row: rowIndex, column: colIndex };
			if (element === 'placeholder')
				lastElementIndex.placeholder = { row: rowIndex, column: colIndex };
		})
	);
	// );
	return { lastElementIndex };
}

export function buildElementsOrderAndCardsMap(cards: ApiDashboardCard[]) {
	let elementsOrder: GridCardsOrder = emptyElementsOrderPlaceholders;
	const cardsMap: CardsMap = {};
	const { replace } = arrayUtils;

	cards.forEach(apiCard => {
		if (!apiCard.layout) return;
		const {
			layout: { row, column },
			size: { width: apiWidth, height: apiHeight }
		} = apiCard;
		const heightSize = apiHeight === 1 ? WidgetHeightSize.small : WidgetHeightSize.big;
		const widthSize =
			apiWidth === 1
				? APIWidthSize.one
				: apiWidth === 2
				? APIWidthSize.two
				: APIWidthSize.three;
		const card: DashboardCard = { ...apiCard, size: { heightSize, widthSize } };
		cardsMap[card.cardId] = card;

		const sizeIterations = getCardSizeIterations(widthSize);
		const cardId = card.cardId.toString();
		sizeIterations.forEach(iteration => {
			elementsOrder[row][column + iteration] =
				iteration === 0 ? cardId : `placeholder${cardId}`;

			if (heightSize === WidgetHeightSize.big) {
				elementsOrder[row + 1][column + iteration] = `placeholder${cardId}`;
			}
		});

		const arrayRowToReplace = elementsOrder[row];
		const computedArray = replace(arrayRowToReplace, column, cardId.toString());
		// const layoutToReplace = elementsOrder[row];
		// const replacedArray = replace(layoutToReplace, row, computedArray);
		elementsOrder = replace(elementsOrder, row, computedArray);
	});

	return { elementsOrder, cardsMap };
}

export function convertAPIWidthSizeToNumber(size: APIWidthSize | number) {
	const secondSize = size === APIWidthSize.two;
	const thirdSize = size === APIWidthSize.three;
	const forthSize = size === APIWidthSize.four;
	const fifthSize = size === APIWidthSize.five;
	const sixthSize = size === APIWidthSize.six;
	const seventhSize = size === APIWidthSize.seven;
	const numberOfIterationsForCardSize =
		typeof size === 'number'
			? size
			: seventhSize
			? 7
			: sixthSize
			? 6
			: fifthSize
			? 5
			: forthSize
			? 4
			: thirdSize
			? 3
			: secondSize
			? 2
			: 1;
	return numberOfIterationsForCardSize;
}

export function getCardSizeIterations(size: APIWidthSize | number) {
	const numberOfIterationsForCardSize = convertAPIWidthSizeToNumber(size);
	const iterations = Array.from({ length: numberOfIterationsForCardSize }, (_, i) => i);
	return iterations;
}

export function gimmeLayoutType(object: LayoutColumnsTypes): object is LayoutColumnsTypes {
	if ('fourColumns' in object) {
		return !!(object as WidthFourColumnsLayout);
	}
	if ('fifthSizeWidth' in object) {
		return !!(object as WidthFiveColumnsLayout);
	}
	if ('forthSizeWidth' in object) {
		return !!(object as WidthSixColumnsLayout);
	}
	return false;
	// if ('thirdSizeWidth' in object) {
	// 	return !!(object as WidthThreeColumnsLayout);
	// }
}

export function convertAPIWidthToPX(widthSize: APIWidthSize, widthSizes: WidthSizes) {
	const { threeColumns, fourColumns, fiveColumns, sixColumns, sevenColumns } = widthSizes;

	const dynamicSizeWidth =
		sixColumns ?? fiveColumns ?? fourColumns ?? threeColumns ?? sevenColumns;

	const firstSizeWidth = dynamicSizeWidth?.firstSizeWidth ?? 0;
	const secondSizeWidth = dynamicSizeWidth?.secondSizeWidth ?? 0;
	const thirdSizeWidth = dynamicSizeWidth?.thirdSizeWidth ?? 0;
	const forthSizeWidth = (dynamicSizeWidth as WidthFourColumnsLayout).forthSizeWidth ?? 0;
	const fifthSizeWidth = (dynamicSizeWidth as WidthFiveColumnsLayout).fifthSizeWidth ?? 0;
	const sixthSizeWidth = (dynamicSizeWidth as WidthSixColumnsLayout).sixthSizeWidth ?? 0;
	const seventhSizeWidth = (dynamicSizeWidth as WidthSevenColumnsLayout).seventhSizeWidth ?? 0;

	const elementSnappedWidth =
		widthSize === APIWidthSize.one
			? firstSizeWidth
			: widthSize === APIWidthSize.two
			? secondSizeWidth
			: widthSize === APIWidthSize.three
			? thirdSizeWidth
			: widthSize === APIWidthSize.four
			? forthSizeWidth
			: widthSize === APIWidthSize.five
			? fifthSizeWidth
			: widthSize === APIWidthSize.six
			? sixthSizeWidth
			: seventhSizeWidth;
	return elementSnappedWidth;
}

// function allAreEqual(array: ManualLayoutElement[]) {
// 	const result = array.every(element => {
// 		if (element === 'placeholder') {
// 			return true;
// 		}
// 	});

// 	return result;
// }

// function computeRows() {
// 	let rowPercentage = 0;
// 	let row = 1;
// 	const smallLayout = 33;
// 	const mediumLayout = 66;
// 	const bigLayout = 99;

// 	const manualCardsLayoutRow: GenericMap<{
// 		elements: (null | string | CardDraggableResizableProps)[][];
// 		maxHeightRow: boolean;
// 		fullHeightRows: number;
// 	}> = {};
// 	// debugger;
// 	Object.values(cardIdsManual).forEach((layoutRows, rowNumber) =>
// 		layoutRows.forEach((layoutRow, rowIndex) => {
// 			layoutRow.forEach((element, colIndex) => {
// 				// debugger;
// 				if (element && typeof element !== 'string') {
// 					const card = gridCards[element.cardId];
// 					const { widthSize, heightSize } = card;

// 					const elementWidthPercentage =
// 						widthSize === WidgetWidthSize.small
// 							? smallLayout
// 							: widthSize === WidgetWidthSize.medium
// 							? mediumLayout
// 							: bigLayout;
// 					rowPercentage = rowPercentage + elementWidthPercentage;

// 					if (rowPercentage > bigLayout) {
// 						row = row + 1;
// 						rowPercentage = rowPercentage - bigLayout;
// 						// canCombineWithNextCard = false;
// 					}
// 					if (!manualCardsLayoutRow[row]) {
// 						manualCardsLayoutRow[row] = {
// 							elements: [[]],
// 							maxHeightRow: heightSize === WidgetHeightSize.big,
// 							fullHeightRows: Object.values(manualCardsLayoutRow).filter(
// 								row => row.maxHeightRow
// 							).length
// 						};
// 					}
// 					if (elementWidthPercentage === bigLayout) {
// 						manualCardsLayoutRow[row].elements[row].push(card);
// 						// newGrid.push(elementRow);
// 						// cards[cardId] = elementRow;
// 						row = row + 1;
// 						rowPercentage = 0;
// 						// canCombineWithNextCard = false;
// 					} else {
// 						manualCardsLayoutRow[row].elements[0].push(element);
// 					}
// 				}
// 			});
// 		})
// 	);
// 	console.log('manualCardsLayoutRow', manualCardsLayoutRow);
// 	const populatedRows: GenericMap<(null | string | CardDraggableResizableProps)[][]> = {};
// 	Object.values(manualCardsLayoutRow).forEach((layoutRow, rowIndex) => {
// 		const populatedRow = populateManualRow(layoutRow.elements);
// 		populatedRows[rowIndex] = populatedRow;
// 	});

// 	return populatedRows;
// }

// function populateManualRow(rowsElements: (string | CardDraggableResizableProps | null)[][]) {
// 	const computedRows: (string | CardDraggableResizableProps | null)[][] = [];
// 	rowsElements.forEach((rowElement, rowIndex) => {
// 		if (!computedRows[rowIndex]) {
// 			computedRows[rowIndex] = [];
// 			computedRows[rowIndex + 1] = [];
// 		}
// 		rowElement.forEach((element, colIndex) => {
// 			if (typeof element === 'string') {
// 				computedRows[rowIndex].push(element);
// 				computedRows[rowIndex + 1].push(element);
// 			} else {
// 				if (!element) {
// 					computedRows[rowIndex].push(element);
// 					computedRows[rowIndex + 1].push(element);
// 				} else {
// 					computedRows[rowIndex].push(element);
// 					computedRows[rowIndex + 1].push('placeholder');
// 				}
// 			}
// 		});
// 	});
// 	if (!rowsElements[1]) rowsElements[1] = [];
// 	if (computedRows[0].length < 3) {
// 		const neededPlaceholders = 3 - computedRows[0].length;

// 		for (let i = 1; i <= neededPlaceholders; i++) {
// 			computedRows[0].push('placeholder');
// 			computedRows[1].push('placeholder');
// 		}
// 		if (computedRows[1].length === 2) computedRows[1].push('placeholder');
// 	}
// 	// debugger;
// 	return computedRows;
// }

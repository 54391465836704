import { useState, useEffect, useCallback } from 'react';
import { ROUTES } from 'types/navigation';
import {
	CreateProjectForm,
	ImportVariableSet,
	ImportData,
	LoadingImportedData,
	PreviewVariables,
	ProjectImportOptions
} from 'components/Projects/CreateAndImport';
import { Svgs } from 'environment';
import { CreateProject } from 'store/data/projects';
import { ImportType } from 'types/index';
import { Cancel, Container, Title, Wrapper } from './CreateAndImportPage.style';
import { CreateWithTemplate } from 'components/Templates/CreateWithTemplate/CreateWithTemplate';
import { Icon } from 'components/UI/Icons';
import { Suspend } from 'components/UI/Suspend';
import { useNavigation, useRouteMatch } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useImportDataText,
	useImportDataset,
	useUploadUrl,
	useCreateProject,
	useSourceCreateTemplateId,
	useImport,
	useImportVariableSet,
	useUserAddons
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction, useKeyPress } from 'hooks/utils';
import { ImportReview } from 'components/Projects/CreateAndImport/ImportReview/ImportReview';
import { Flex } from 'components/UI/Flex';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount,
	FileImportErrors,
	ImportError,
	ImportErrorsByVariableName
} from 'types/data/projects/types';
import { VariableType } from 'types/data/variables/constants';
import { Reupload } from 'components/Projects/CreateAndImport/Reupload/Reupload';
import { useUserOrganizationId } from 'hooks/store/data/projects/import/useUserOrganizationId';
import { useResetImportPercentage } from '../../../hooks/store/data/projects/import/useResetImportPercentage';
import { useTracking } from 'app/tracking/TrackingProvider';

enum WizardSteps {
	ProjectForm = 'projectForm',
	ImportOptions = 'importOptions',
	Template = 'template',
	ImportSet = 'importSet',
	ImportDataset = 'importDataset',
	PreviewData = 'previewData',
	ImportingDataset = 'importingDataset',
	ImportReview = 'importReview',
	Reupload = 'reupload'
}

export function CreateAndImportPage() {
	const { track } = useTracking();
	const { goBack } = useNavigation();
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const { fetched: arePermissionsFetched, loading: loadingPermissions } = usePermissions();

	const [{ data: importText, loading: loadingImportText }] = useImportDataText();
	const [{ loading: importingDataset }] = useImportDataset();

	const [
		{ isImportVariableSet, importVariableSetName },
		{ setIsImportVariableSet, setImportVariableSetName }
	] = useImportVariableSet();

	const usedForImportProject = useRouteMatch(ROUTES.ImportDataset);
	const usedForImportProm = useRouteMatch(ROUTES.ImportPromDataset);
	const usedForImport = usedForImportProject || usedForImportProm;

	const defaultStep =
		isImportVariableSet && !importVariableSetName
			? WizardSteps.ImportSet
			: usedForImport
			? WizardSteps.ImportDataset
			: WizardSteps.ProjectForm;

	const [step, setStep] = useState<WizardSteps>(defaultStep);
	const [selectedOption, setSelectedOption] = useState<ImportType | null>(null);

	const [{ loading: creatingProject, error: errorCreatingProject }, createProject] =
		useCreateProject();
	const [, setSourceCreateTemplateId] = useSourceCreateTemplateId();
	const [, { setPreviousMapping }] = useImport();

	useCompletedAction(creatingProject, errorCreatingProject, () => {
		track({
			eventName: 'project_created'
		});
	});

	const userOrganizationId = useUserOrganizationId();

	const [_, { uploadReplaceAll, uploadEntriesToDataset, uploadDataToEntries }] = useImport({
		handleFinishImport,
		callbacks: {
			handleApiImportErrors: handleApiImportErrors
		},
		...(!isImportVariableSet && { projectOrganizationId: userOrganizationId })
	});

	const [
		{
			loading: uploadingUrl,
			data: { suggestedVariableTypes }
		},
		{ resetCsvData, resetCsv }
	] = useUploadUrl({ ...(selectedOption && { importType: selectedOption }) });

	const [draftProject, setDraftProject] = useState<CreateProject | null>(null);

	const [importFileName, setImportFileName] = useState('');
	const [uploadingFile, setUploadingFile] = useState(false);
	const [finishedLoading, setFinishedLoading] = useState(false);
	const [errorMap, setErrorMap] = useState<ImportErrorsByVariableName | null>(null);
	const [errorCountMap, setErrorCountMap] = useState<ApiVariableErrorCount | null>(null);
	const [fileImportErrors, setFileImportErrors] = useState<FileImportErrors | null>(null);

	const resetImportPercentage = useResetImportPercentage();

	const [
		{
			data: { userAddons, activeUserAddons }
		}
	] = useUserAddons();

	/**
	 * Keeps a single source of truth of the instance (`loading`, `error`)
	 */
	const createProjectController = {
		loading: creatingProject,
		error: errorCreatingProject,
		create: createProject
	};

	function triggerSuccessNotification() {
		setNotification({
			message: translate(dict => dict.createProject.projectCreated)
		});
	}

	useCompletedAction(
		creatingProject,
		errorCreatingProject,
		// SUCCESS CALLBACK
		() => (!errorCreatingProject ? triggerSuccessNotification : undefined),
		// ERROR CALLBACK
		() => goToCreateProjectForm()
	);

	// FAKE UPLOADING FILE COUNTER
	useEffect(() => {
		// BEGIN COUNTER
		if (uploadingUrl && !uploadingFile) setUploadingFile(true);
		// END COUNTER
		if (finishedLoading) setUploadingFile(false);
	}, [uploadingUrl, finishedLoading]);

	// HANDLES THE REDIRECT TO NEXT STEP (PREVIEW) AFTER IMPORT FILE IS DONE
	useEffect(() => {
		if (!uploadingUrl && finishedLoading && suggestedVariableTypes) {
			if (step !== WizardSteps.Reupload) {
				setStep(WizardSteps.PreviewData);
			}
			if (step === WizardSteps.Reupload) {
				setStep(WizardSteps.ImportingDataset);

				// RESET ERROR MAP
				setErrorMap(null);

				if (selectedOption === ImportType.ReplaceAll) {
					uploadReplaceAll();
				}

				if (selectedOption === ImportType.MoreEntriesToDataset) {
					uploadEntriesToDataset();
				}

				if (selectedOption === ImportType.MoreDataToExistingEntries) {
					uploadDataToEntries();
				}
			}
		}
	}, [uploadingUrl, finishedLoading, step, suggestedVariableTypes]);

	useKeyPress({ onEscapeKeyPress: handleClose }, { noModalsOpened: true, listen: getCanClose() });

	function handleFinishedLoading() {
		setFinishedLoading(true);
	}

	function handleUploadingError() {
		setUploadingFile(false);
		resetImportPercentage();
	}

	function goToCreateProjectForm() {
		resetCsvData();
		setStep(WizardSteps.ProjectForm);
	}

	function goToProjectImportOptions() {
		resetCsvData();
		setStep(WizardSteps.ImportOptions);
	}

	function handleFinishImport() {
		setFinishedLoading(false);
		setStep(WizardSteps.ImportingDataset);
	}

	const handleBack = useCallback(() => {
		setStep(usedForImport ? WizardSteps.ImportDataset : WizardSteps.ImportOptions);
		setImportFileName('');
		setSelectedOption(null);
		setUploadingFile(false);
		setFinishedLoading(false);
		setSourceCreateTemplateId(null);
		resetCsvData();
	}, [usedForImport]);

	const handleReset = useCallback(() => {
		setImportFileName('');
		setUploadingFile(false);
		setFinishedLoading(false);
		setSourceCreateTemplateId(null);
		resetCsvData();
	}, []);

	function handleLoadingErrors() {
		if (
			usedForImport &&
			(selectedOption === ImportType.MoreDataToExistingEntries ||
				selectedOption === ImportType.MoreEntriesToDataset ||
				selectedOption === ImportType.VariableTemplate)
		) {
			setStep(WizardSteps.PreviewData);
		} else handleBack();
	}

	function handleContinueImportSet(importVariableSetName: string) {
		setImportVariableSetName(importVariableSetName);
		setStep(WizardSteps.ImportDataset);
	}

	function handleClose() {
		isImportVariableSet && setIsImportVariableSet(false);
		importVariableSetName && setImportVariableSetName('');
		resetCsvData();
		setPreviousMapping(null);
		goBack();
	}

	function getCanClose() {
		return !(
			uploadingFile ||
			importingDataset ||
			creatingProject ||
			step === WizardSteps.ImportingDataset
		);
	}

	function getTitle() {
		switch (step) {
			case WizardSteps.ImportSet:
			case WizardSteps.ImportDataset:
				return translate(dict => dict.import.dataset.title);
			case WizardSteps.PreviewData:
				if (selectedOption === ImportType.ReplaceAll) {
					return translate(dict => dict.import.previewData.title);
				}

				if (selectedOption === ImportType.MoreEntriesToDataset) {
					return translate(dict => dict.projects.createAndImport.title.entriesToDataset);
				}

				if (selectedOption === ImportType.MoreDataToExistingEntries) {
					return translate(dict => dict.projects.createAndImport.title.dataToEntries);
				}

				return '';
			case WizardSteps.ImportReview:
				return translate(dict => dict.import.importReview.title);
			default:
				return '';
		}
	}

	function getApiErrorHandler() {
		if (
			selectedOption &&
			[
				ImportType.ReplaceAll,
				ImportType.MoreEntriesToDataset,
				ImportType.Now,
				ImportType.MoreDataToExistingEntries
			].includes(selectedOption)
		) {
			return handleApiImportErrors;
		}

		return undefined;
	}

	function handleApiImportErrors(
		apiErrorMap: ApiImportErrorsByVariableName,
		errorCountMap: ApiVariableErrorCount
	) {
		const errorMap: ImportErrorsByVariableName = {};

		for (const [variableName, apiErrorsByType] of Object.entries(apiErrorMap)) {
			let variableType = '';

			const errorsByType: Record<string, ImportError[]> = {};
			const variableErrors: ImportError[] = [];

			for (const [errType, apiErrors] of Object.entries(apiErrorsByType)) {
				const errors: ImportError[] = apiErrors.map(apiErr => {
					const error: ImportError = {
						columnNumber: Number(apiErr.values.column_number),
						rowNumber: Number(apiErr.values.row_number),
						value: apiErr.values.value
							? apiErr.values.value
							: translate(dict => dict.terms.empty)
					};

					if (variableType === '') {
						variableType = apiErr.values.type;
					}

					return error;
				});

				errorsByType[errType] = errors;
				variableErrors.push(...errors);
			}

			errorMap[variableName] = {
				errors: variableErrors,
				errorsByType: errorsByType,
				type: variableType as VariableType
			};
		}

		setErrorMap(errorMap);
		setErrorCountMap(errorCountMap);
		setStep(WizardSteps.ImportReview);
	}

	function handleReupload() {
		setStep(WizardSteps.Reupload);
		setImportFileName('');
		resetCsv();
		resetImportPercentage();
	}

	const isOnImport = step === WizardSteps.ImportOptions || step === WizardSteps.ImportDataset;
	const loading = isOnImport ? loadingPermissions || loadingImportText : loadingImportText;
	const immediate =
		loadingImportText || !importText || (isOnImport ? !arePermissionsFetched : false);

	const closeIconVisible = getCanClose();

	const showTitle =
		step === WizardSteps.ImportReview ||
		step === WizardSteps.PreviewData ||
		step === WizardSteps.ImportDataset ||
		step === WizardSteps.ImportSet;

	return (
		<Suspend loading={loading} immediate={immediate}>
			<Wrapper>
				<Cancel showTitle={showTitle}>
					{showTitle && (
						<Flex
							css={`
								grid-area: title;
								width: 100%;
							`}
						>
							<Title>{getTitle()}</Title>
						</Flex>
					)}
					{closeIconVisible && (
						<Icon
							style={{ gridArea: 'cancel' }}
							svg={Svgs.Close}
							size={s => s.m}
							onClick={handleClose}
						/>
					)}
				</Cancel>
				<Container>
					{step === WizardSteps.ProjectForm && (
						<CreateProjectForm
							draftProject={draftProject}
							userAddons={userAddons ?? []}
							activeUserAddons={activeUserAddons ?? []}
							setDraftProject={setDraftProject}
							createProjectController={createProjectController}
							onFinish={goToProjectImportOptions}
						/>
					)}
					{step === WizardSteps.ImportOptions && (
						<ProjectImportOptions
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
							importFileName={importFileName}
							setImportFileName={setImportFileName}
							uploadingFile={uploadingFile}
							handleFinishedLoading={handleFinishedLoading}
							fileImportErrors={fileImportErrors}
							setFileImportErrors={setFileImportErrors}
							handleUploadingError={handleUploadingError}
							handleReset={handleReset}
							onTemplateNext={() => setStep(WizardSteps.Template)}
						/>
					)}
					{step === WizardSteps.Template && <CreateWithTemplate onClose={handleBack} />}
					{step === WizardSteps.ImportSet && (
						<ImportVariableSet
							handleClose={handleClose}
							handleContinue={handleContinueImportSet}
						/>
					)}
					{step === WizardSteps.ImportDataset && (
						<ImportData
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
							importFileName={importFileName}
							setImportFileName={setImportFileName}
							uploadingFile={uploadingFile}
							fileImportErrors={fileImportErrors}
							setFileImportErrors={setFileImportErrors}
							handleFinishedLoading={handleFinishedLoading}
							handleUploadingError={handleUploadingError}
							handleReset={handleReset}
						/>
					)}
					{step === WizardSteps.PreviewData && selectedOption && (
						<PreviewVariables
							data-testid="previewVariables"
							selectedOption={selectedOption}
							handleFinishImport={handleFinishImport}
							handleBack={handleBack}
							handleApiImportErrors={getApiErrorHandler()}
						/>
					)}
					{step === WizardSteps.ImportReview && errorMap && (
						<ImportReview
							errorMap={errorMap}
							errorCountMap={errorCountMap}
							selectedOption={selectedOption}
							handleBack={handleBack}
							handleFinishImport={handleFinishImport}
							handleApiImportErrors={getApiErrorHandler()}
							handleReupload={handleReupload}
						/>
					)}

					{step === WizardSteps.Reupload && (
						<Reupload
							selectedOption={selectedOption}
							importFileName={importFileName}
							uploadingFile={uploadingFile}
							setImportFileName={setImportFileName}
							handleFinishedLoading={handleFinishedLoading}
							handleUploadingError={handleUploadingError}
						/>
					)}

					{step === WizardSteps.ImportingDataset && (
						<LoadingImportedData handleLoadingErrors={handleLoadingErrors} />
					)}
				</Container>
			</Wrapper>
		</Suspend>
	);
}

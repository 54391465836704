import { Avatar } from 'components/UI/Interactables/Avatar';
import { useSelector } from 'hooks/utils';
import { selectProjectCollaboratorsAvatars } from 'store/data/projects';

import { Container, MoreAvatarsContainer } from './ProjectCollaboratorAvatars.style';

interface Props {
	projectId: string;
}

export function ProjectCollaboratorAvatars({ projectId }: Props) {
	const projectCollaboratorAvatars = useSelector(state =>
		selectProjectCollaboratorsAvatars(state.data.projects, { projectId })
	);

	const hasMoreThanThreeAvatars =
		projectCollaboratorAvatars && projectCollaboratorAvatars.length > 3;

	const avatarsToRender =
		projectCollaboratorAvatars.length > 3
			? projectCollaboratorAvatars.slice(0, 2)
			: projectCollaboratorAvatars;

	const numberOfOtherAvatars = projectCollaboratorAvatars
		? projectCollaboratorAvatars.length - 2
		: 0;

	return (
		<Container>
			{avatarsToRender.map(({ userId, firstName, lastName }, index) => (
				<Avatar
					key={index}
					userId={userId}
					data={{
						userFirstName: firstName,
						userSirName: lastName
					}}
					size={s => s.s}
				/>
			))}
			{hasMoreThanThreeAvatars && (
				<MoreAvatarsContainer>{`+${numberOfOtherAvatars}`}</MoreAvatarsContainer>
			)}
		</Container>
	);
}

import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import { Disabled, disableableComponent } from 'helpers/cssGenerators';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 32rem;
	max-width: 32rem;
	margin-right: 4rem;

	@media ${MediaQueries.maxWidth.lg} {
		min-width: none;
		max-width: none;
		margin-right: 0;
	}
`;

export const BoldText = styled.span`
	font-weight: 600;
	color: ${Colors.gray.dark};
`;

export const LoadingContainer = styled.div<Disabled>`
	display: flex;
	flex-direction: column;
	align-items: center;

	${({ disabled }) => disableableComponent({ disabled })}
`;

import styled from 'styled-components';

interface Props {
	children: React.ReactElement;
}
export function AnalysisExportWrapper({ children }: Props) {
	return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
	visibility: hidden;
	position: fixed;
`;

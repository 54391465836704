import produce from 'immer';
import { ChoiceType, OrientationType, DragAndDropTypes } from 'types/index';

import initialState from './initialState';
import { ActionTypes, Actions, State, FormElementsOrder, FormsFilterShowOptions } from './types';

import { Actions as ProjectsActions, ActionTypes as ProjectsActionTypes } from '../projects/types';
import {
	Actions as VariablesActions,
	ActionTypes as VariablesActionTypes
} from '../variables/types';
import { arrayUtils } from 'helpers/arrays';
import { getFormGroupVariableNames, findElementRowIndex } from 'helpers/forms';
import { removeFormElement, removeFormGroup, removeFormSet } from 'helpers/forms';

export default (
	state: State = initialState,
	action: Actions | ProjectsActions | VariablesActions
): State => {
	switch (action.type) {
		case ProjectsActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					byProjectId[projectId] = {
						ids: [],
						bySetName: {},
						filters: {
							show: FormsFilterShowOptions.ALL
						},
						collapsed: {},
						fetched: false,
						refetch: false
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		case ActionTypes.GET_FORM: {
			const { form } = action.payload;

			return produce(state, draft => {
				const { projectId } = draft;

				if (!projectId) return;

				draft.byId[form.id] = {
					initial: form,
					current: form
				};
				draft.byProjectId[projectId].ids = [form.id];
				draft.byProjectId[projectId].fetched = true;
			});
		}

		case ActionTypes.GET_FORMS: {
			const { projectId, formsData } = action.payload;

			return produce(state, draft => {
				const { byProjectId, byId } = draft;

				if (!(projectId in byProjectId)) return;

				const { forms, formsBySetName } = formsData;

				const projectData = byProjectId[projectId];

				projectData.ids = [];
				projectData.bySetName = {};

				forms.forEach(form => {
					byId[form.id] = {
						initial: form,
						current: form
					};
					projectData.ids.push(form.id);
				});

				Object.entries(formsBySetName).forEach(([setName, forms]) => {
					projectData.bySetName[setName] = {
						ids: []
					};

					forms.forEach(form => {
						form.setName = setName;

						byId[form.id] = {
							initial: form,
							current: form
						};
						projectData.bySetName[setName].ids.push(form.id);
					});
				});

				if (projectData.refetch) {
					projectData.filters = {
						show: FormsFilterShowOptions.ALL
					};
					projectData.collapsed = {};
				}

				/**
				 * Remove dead forms
				 */
				const allFormIds: string[] = [];

				Object.values(byProjectId).forEach(projectData => {
					allFormIds.push(...projectData.ids);

					Object.values(projectData.bySetName).forEach(({ ids }) =>
						allFormIds.push(...ids)
					);
				});

				Object.keys(byId).forEach(formId => {
					if (!allFormIds.includes(formId)) delete byId[formId];
				});
				/**
				 * End
				 */

				projectData.fetched = true;
				projectData.refetch = false;
			});
		}

		case ActionTypes.CREATE_FORM: {
			const { form } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, byId } = draft;

				if (projectId && byProjectId[projectId]) {
					const data = byProjectId[projectId];

					if (form.setName) {
						if (!(form.setName in data.bySetName)) {
							data.bySetName[form.setName] = { ids: [] };
						}
					}

					const computedData = form.setName ? data.bySetName[form.setName] : data;

					computedData.ids.push(form.id);

					byId[form.id] = {
						initial: form,
						current: form
					};
				}
			});
		}

		case ActionTypes.UPDATE_FORM: {
			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) byId[formId].initial = byId[formId].current;
			});
		}

		case ActionTypes.RENAME_FORM: {
			const { formName, formId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (byId[formId]) {
					byId[formId].initial.name = formName;
					byId[formId].current.name = formName;
				}
			});
		}

		case ActionTypes.DELETE_FORM: {
			const { formId } = action.payload;

			return produce(state, draft => {
				const { projectId, byId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const form = byId[formId].current;
					const data = byProjectId[projectId];

					const computedData = form.setName ? data.bySetName[form.setName] : data;

					computedData.ids = computedData.ids.filter(id => id !== formId);

					delete byId[formId];
				}
			});
		}

		case ActionTypes.DEACTIVATE_FORMS: {
			const { formIds } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				formIds.forEach(formId => {
					if (byId[formId]) {
						byId[formId].current.active = false;
						byId[formId].initial.active = false;
					}
				});
			});
		}

		case ActionTypes.DEACTIVATE_FORM: {
			const { formId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (byId[formId]) {
					byId[formId].current.active = false;
					byId[formId].initial.active = false;
				}
			});
		}

		case ActionTypes.ACTIVATE_FORM: {
			const { formId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (byId[formId]) {
					byId[formId].current.active = true;
					byId[formId].initial.active = true;
				}
			});
		}

		case ActionTypes.MOVE_FORM: {
			const { formId, sourceIndex, destinationIndex } = action.payload;

			return produce(state, draft => {
				const { projectId, byId, byProjectId } = draft;

				if (projectId && projectId in byProjectId && formId in byId) {
					const form = byId[formId].current;
					const data = byProjectId[projectId];

					const computedData = form.setName ? data.bySetName[form.setName] : data;

					computedData.ids = arrayUtils.move(
						computedData.ids,
						sourceIndex,
						destinationIndex
					);
				}
			});
		}

		case ActionTypes.GET_LATEST_FORM: {
			const { form } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				byId[form.id] = {
					initial: form,
					current: form
				};
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		case ActionTypes.SET_FORM_ID: {
			const { formId } = action.payload;

			return produce(state, draft => {
				draft.formId = formId;
			});
		}

		case ActionTypes.SET_FORM_ERRORS: {
			const { formErrors } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;
					form.formErrors = formErrors;
				}
			});
		}

		case ActionTypes.RESET_FORM: {
			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					byId[formId].current = byId[formId].initial;
				}
			});
		}

		case ActionTypes.SET_FORM_VARIABLE_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.searchTerm = term;
			});
		}

		case ActionTypes.SET_FORMS_FILTERS: {
			const { filters } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					byProjectId[projectId].filters = filters;
				}
			});
		}

		case ActionTypes.SET_FORMS_SECTION_COLLAPSED: {
			const { sectionName } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					const flag = !!byProjectId[projectId].collapsed[sectionName];

					byProjectId[projectId].collapsed[sectionName] = !flag;
				}
			});
		}

		case ActionTypes.SET_REFETCH_FORMS: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && projectId in byProjectId) {
					byProjectId[projectId].refetch = true;
				}
			});
		}

		//////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////

		case ActionTypes.ADD_FORM_ELEMENT: {
			const { destinationIndex, element } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					form.elements[element.elementId] = element;

					const newElementsOrder = arrayUtils.insert(
						form.elementsOrder,
						destinationIndex,
						element.elementId
					);

					form.elementsOrder = newElementsOrder;

					if (element.variableRef !== undefined) {
						form.usedVariables.push(element.variableRef);
					}
				}
			});
		}

		case ActionTypes.ADD_FORM_GROUP: {
			const { destinationIndex, formGroup, groupElements } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const { groupId, groupName } = formGroup;

					const newElementsOrder: FormElementsOrder = arrayUtils.insert(
						form.elementsOrder,
						destinationIndex,
						groupId
					);

					const groupUsedVariables = getFormGroupVariableNames(formGroup, groupElements);

					form.groups[formGroup.groupId] = formGroup;
					form.usedGroups.push(groupName);
					form.elementsOrder = newElementsOrder;
					form.usedVariables.push(...groupUsedVariables);

					Object.entries(groupElements).forEach(
						([elementId, element]) => (form.elements[elementId] = element)
					);
				}
			});
		}

		case ActionTypes.ADD_FORM_SET: {
			const { destinationIndex, formSet } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					form.sets[formSet.setId] = formSet;
					form.usedSets.push(formSet.setName);

					const newElementsOrder = arrayUtils.insert(
						form.elementsOrder,
						destinationIndex,
						formSet.setId
					);

					form.elementsOrder = newElementsOrder;
				}
			});
		}

		case ActionTypes.APPEND_FORM_ELEMENT: {
			const { element } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const { elementId, variableRef } = element;

					form.elements[elementId] = element;
					form.elementsOrder.push(elementId);

					// FORM VARIABLE
					if (variableRef !== undefined) {
						form.usedVariables.push(variableRef);
					}
				}
			});
		}

		case ActionTypes.APPEND_FORM_GROUP: {
			const { formGroup, groupElements } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const { groupId, groupName } = formGroup;

					const groupVariableNames = getFormGroupVariableNames(formGroup, groupElements);

					form.groups[groupId] = formGroup;
					form.usedGroups.push(groupName);
					form.elementsOrder.push(groupId);
					form.usedVariables.push(...groupVariableNames);

					Object.entries(groupElements).forEach(
						([elementId, element]) => (form.elements[elementId] = element)
					);
				}
			});
		}

		case ActionTypes.APPEND_FORM_SET: {
			const { formSet } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					form.sets[formSet.setId] = formSet;
					form.elementsOrder.push(formSet.setId);
					form.usedSets.push(formSet.setName);
				}
			});
		}

		case ActionTypes.REMOVE_FORM_ELEMENT: {
			const { elementId } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const updatedForm = removeFormElement({ form, elementId });

					byId[formId].current = updatedForm;
				}
			});
		}

		case ActionTypes.REMOVE_FORM_GROUP: {
			const { groupId } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const updatedForm = removeFormGroup({ form, groupId });

					byId[formId].current = updatedForm;
				}
			});
		}

		case ActionTypes.REMOVE_FORM_SET: {
			const { setId } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const updatedForm = removeFormSet({ form, setId });

					byId[formId].current = updatedForm;
				}
			});
		}

		// TODO: REFACTOR
		case ActionTypes.GROUP_FORM_ELEMENTS: {
			const { sourceId, sourceIndex, destinationId, destinationIndex, groupName } =
				action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (!(formId && byId[formId])) return;

				const form = byId[formId].current;

				const newVariableToInsert = sourceIndex >= 0;

				if (groupName?.startsWith(DragAndDropTypes.DraggableFormGroup)) {
					const group = form.groups[groupName];

					let elementsOrder;

					if (!group) {
						elementsOrder = form.elementsOrder;
					} else {
						elementsOrder = group.elementsOrder;
					}

					const computedElementsOrder = [...elementsOrder];
					const elementsRow: string[] = [];

					const isCombineAscending = sourceIndex < destinationIndex;

					if (isCombineAscending && newVariableToInsert) {
						elementsRow.push(...[sourceId, destinationId]);
					} else {
						elementsRow.push(...[destinationId, sourceId]);
					}

					computedElementsOrder.splice(destinationIndex, 1, elementsRow);

					if (!newVariableToInsert) {
						computedElementsOrder.splice(destinationIndex + 1, 1);
					} else {
						computedElementsOrder.splice(sourceIndex, 1);
					}

					if (!group) {
						form.elementsOrder = computedElementsOrder;
					} else {
						group.elementsOrder = computedElementsOrder;
					}
				} else {
					const computedElementsOrder = [...form.elementsOrder];
					const elementsRow: string[] = [];

					const isCombineAscending = sourceIndex < destinationIndex;

					if (isCombineAscending && newVariableToInsert) {
						elementsRow.push(...[sourceId, destinationId]);
					} else {
						elementsRow.push(...[destinationId, sourceId]);
					}

					computedElementsOrder.splice(destinationIndex, 1, elementsRow);

					if (!newVariableToInsert) {
						computedElementsOrder.splice(destinationIndex + 1, 1);
					} else {
						computedElementsOrder.splice(sourceIndex, 1);
					}

					form.elementsOrder = computedElementsOrder;
				}
			});
		}

		case ActionTypes.UNGROUP_FORM_ELEMENTS: {
			const { rowIndex, groupId } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const group = groupId ? form.groups[groupId] : null;

					let elementsOrder = [...form.elementsOrder];

					if (group) elementsOrder = [...group.elementsOrder];

					// EXTRACT ROW
					const arrayToBreak = elementsOrder[rowIndex] as string[];

					// FLAT EXTRACTED ROW
					elementsOrder.splice(rowIndex, 1, ...arrayToBreak);

					if (group) {
						group.elementsOrder = elementsOrder;
					} else {
						form.elementsOrder = elementsOrder;
					}
				}
			});
		}

		case ActionTypes.ORDER_FORM_ELEMENT: {
			const { sourceIndex, destinationIndex, options } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const groupId = options?.groupId;
					const rowIndex = options?.rowIndex;

					const group = groupId ? form.groups[groupId] : null;

					let elementsOrder = [...form.elementsOrder];

					if (group) elementsOrder = [...group.elementsOrder];

					// ORDER INSIDE ROW
					if (rowIndex !== undefined) {
						// EXTRACT ROW TO ORDER
						const rowElementsToOrder = elementsOrder[rowIndex] as string[];

						// ORDER EXTRACTED ROW
						const orderedRowElements: string[] = arrayUtils.move(
							rowElementsToOrder,
							sourceIndex,
							destinationIndex
						);

						// REPLACE WITH NEW ORDERED ROW
						elementsOrder.splice(rowIndex, 1, orderedRowElements);
					}
					// ORDER IN MAIN LIST
					else {
						elementsOrder = arrayUtils.move(
							elementsOrder,
							sourceIndex,
							destinationIndex
						);
					}

					if (group) {
						group.elementsOrder = elementsOrder;
					} else {
						form.elementsOrder = elementsOrder;
					}
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_ORIENTATION: {
			const { elementId, elementType, orientation } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = byId[formId].current.elements[elementId];

					let elementsOrder = form.elementsOrder;

					// CHECK IF ELEMENT IS INSIDE A GROUP - SET ACTUAL ELEMENTS ORDER WHERE ELEMENT IS FOUND
					Object.values(form.groups).forEach(formGroup => {
						const groupElements = formGroup.elementsOrder.flat();

						if (groupElements.includes(elementId)) {
							elementsOrder = formGroup.elementsOrder;
						}
					});

					// CHECK IF PART OF A ROW => FLAT ROW
					if (orientation === OrientationType.Horizontal) {
						const { rowIndex } = findElementRowIndex(elementId, elementsOrder);

						// IN ROW
						if (rowIndex !== null) {
							// EXTRACT ROW
							const arrayToBreak = elementsOrder[rowIndex] as string[];

							// FLAT EXTRACTED ROW
							elementsOrder.splice(rowIndex, 1, ...arrayToBreak);
						}
					}

					delete element.choiceType;
					element.elementType = elementType;
					element.orientation = orientation;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_LABEL: {
			const { elementId, label } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					element.label = label;
				}
			});
		}

		case ActionTypes.SET_FORM_GROUP_LABEL: {
			const { groupId, label } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const group = form.groups[groupId];
					group.groupLabel = label;
				}
			});
		}

		case ActionTypes.SET_FORM_SET_LABEL: {
			const { setId, label } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const formSet = form.sets[setId];
					formSet.setLabel = label;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_TYPE: {
			const { elementId, elementType } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					delete element.orientation;
					element.elementType = elementType;
					element.choiceType = ChoiceType.SingleChoice;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_SLIDER_VALUES: {
			const { elementId, sliderValues } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.sliderValues = sliderValues;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_TEXT: {
			const { elementId, text } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.text = text;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_DISPLAY_TYPE: {
			const { elementId, displayType } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.displayType = displayType;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_SLIDER_TYPE: {
			const { elementId, sliderType } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.sliderType = sliderType;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_SCALE_INTERVAL: {
			const { elementId, scaleInterval } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.scaleInterval = scaleInterval;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_DISPLAY_SELECTED_VALUE: {
			const { elementId, displaySelectedValue } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.displaySelectedValue = displaySelectedValue;
				}
			});
		}

		case ActionTypes.SET_FORM_ELEMENT_MAP_VALUES_WITH_MOODS: {
			const { elementId, mapValuesWithMoods } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					const form = byId[formId].current;

					const element = form.elements[elementId];
					if (element) element.mapValuesWithMoods = mapValuesWithMoods;
				}
			});
		}

		case ActionTypes.TOGGLE_FORM_ACTIVE: {
			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					byId[formId].current.active = !byId[formId].current.active;
				}
			});
		}

		case ActionTypes.TOGGLE_FORM_TITLE: {
			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					byId[formId].current.titleEnabled = !byId[formId].current.titleEnabled;
				}
			});
		}

		case ActionTypes.SET_FORM_TITLE: {
			const { title } = action.payload;

			return produce(state, draft => {
				const { byId, formId } = draft;

				if (formId && byId[formId]) {
					byId[formId].current.title = title;
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		/*
			Mark forms to be refetched after the following variable actions
			NOTE: Forms sync happens in the BE now
		*/

		case VariablesActionTypes.CREATE_VARIABLE:
		case VariablesActionTypes.UPDATE_VARIABLE:
		case VariablesActionTypes.DELETE_VARIABLE:
		case VariablesActionTypes.UPDATE_GROUP:
		case VariablesActionTypes.DELETE_GROUP:
		case VariablesActionTypes.UPDATE_VARIABLE_SET:
		case VariablesActionTypes.DELETE_VARIABLE_SET:
		case VariablesActionTypes.ADD_VARIABLE_TO_GROUP:
		case VariablesActionTypes.ADD_VARIABLES_TO_GROUP:
		case VariablesActionTypes.REMOVE_VARIABLE_FROM_GROUP:
		case VariablesActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS:
		case VariablesActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS:
		case VariablesActionTypes.ADD_VARIABLE_TO_SET:
		case VariablesActionTypes.ADD_VARIABLE_GROUP_TO_SET:
		case VariablesActionTypes.REMOVE_VARIABLE_FROM_SET:
		case VariablesActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET:
		case VariablesActionTypes.MOVE_VARIABLE_BETWEEN_SETS:
		case VariablesActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS:
		case VariablesActionTypes.DELETE_BULK_VARIABLES_DATA: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId].refetch = true;
				}
			});
		}

		default: {
			return state;
		}
	}
};

import { Icon } from 'components/UI/Icons';
import { Menu, MenuItem, Container } from './FormVariableSettings.style';
import { Svgs } from 'environment';
import { useForm, useFormDesignerDrawerContext } from 'hooks/store';
interface Props {
	variableName: string;
	onEdit: () => void;
	onDelete?: () => void;
}

export function FormVariableSettings({ variableName, onEdit, onDelete }: Props) {
	const { elementId: selectedElementId } = useFormDesignerDrawerContext();
	const [{ data: form }] = useForm();

	const selected = variableName === form?.elements[selectedElementId]?.variableRef;

	return (
		<Container>
			<Menu align={a => a.center} justify={j => j.end}>
				<MenuItem active={selected}>
					<Icon onClick={onEdit} active={selected} svg={Svgs.Edit} size={s => s.m} />
				</MenuItem>
				{onDelete && (
					<MenuItem>
						<Icon onClick={onDelete} svg={Svgs.Delete} size={s => s.m} />
					</MenuItem>
				)}
				<MenuItem>
					<Icon svg={Svgs.Move} size={s => s.m} />
				</MenuItem>
			</Menu>
		</Container>
	);
}

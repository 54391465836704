import styled from 'styled-components';

export const RangeInputWrapper = styled.div`
	margin-top: 8px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
`;

export const CurrentValueDisplay = styled.div<{ disabled?: boolean }>`
	height: 22px;
	background: ${props => (props.disabled ? '#f9fafb' : '#ffffff')};
	color: #25253a;
	font-weight: 500;
	border: 1px solid #dae0e6;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 5px;
	padding-right: 5px;
	margin-left: 5px;
`;

export const ValueDisplayWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 50px;
`;

export const SliderMainWrapper = styled.div`
	width: 100%;
	display: flex;
	height: 80px;
	align-items: center;
	border: 1px solid #dae0e6;
	border-radius: 4px;
	padding-right: 10px;
	padding-left: 10px;
`;

export const SliderContentContainer = styled.div`
	// width: calc(98% - 26px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

export const InputWrapper = styled.div<{ hasValue: boolean }>`
	width: 100%;
	input[type='range'] {
		-webkit-appearance: none;
		background: #dae0e6;
		height: 4px;
		border-radius: 20px;
		width: 100%;
		transform: translate(-2px, 0);
		cursor: pointer;
	}

	input::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 16px;
		height: 16px;
		${props =>
			props.hasValue &&
			`
				background-color: #ffffff;
				border: 1.5px solid #b0bfc7;
				border-radius: 50%;
				cursor: pointer;
			`}
	}

	input::-webkit-slider-thumb:hover {
		border-color: #a20067;
	}
`;

export const NumberScaleContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: calc(100% - 15px);
`;

export const ScaleNumber = styled.div`
	font-size: 12px;
	color: black;
	position: absolute;
	transform: translateX(-50%);
	height: 16px;
`;

export const MoodsContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: calc(100% - 15px);
`;

export const Mood = styled.div`
	font-size: 12px;
	color: black;
	position: absolute;
	transform: translateX(-50%);
	height: 16px;
`;

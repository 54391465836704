import styled from 'styled-components';

import { Colors } from 'environment';
import { Typography } from 'components/UI/Typography';

interface ContainerProps {
	active: boolean;
	current?: boolean;
	index: number;
}

interface TextProps {
	active: boolean;
	current?: boolean;
}

export const Container = styled.div<ContainerProps>`
	padding: 1.2rem 3.4rem;
	min-height: 6rem;
	cursor: pointer;

	background-color: ${props => props.index % 2 == 0 && Colors.background.disabled};

	:hover {
		background-color: ${Colors.primary.disabled};
	}

	&& {
		background-color: ${({ active }) => active && Colors.primary.disabled};
	}
`;

export const Text = styled(Typography.Hint)<TextProps>`
	&& {
		color: ${({ active, current }) =>
			active ? Colors.primary.normal : !current && Colors.text.main};
		font-size: ${({ current }) => !current && '1.2rem'};
		font-style: ${({ current }) => current && 'italic'};
		font-weight: ${({ current }) => !current && '500'};
	}
`;

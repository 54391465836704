import { useSelector } from 'hooks/utils';
import { useMemoOnce } from 'hooks/utils';
import { selectAnalysisById } from 'store/data/analyses';

export function useAnalysisById(id: string) {
	/**
	 * Used like this to increase the cache size from 1 to n
	 */
	const _selectAnalysisById = useMemoOnce(selectAnalysisById);

	return useSelector(state => _selectAnalysisById(state.data.analyses, { id }));
}

import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DeleteIcon, FormVariable } from 'components/Forms';
import { Colors, Svgs } from 'environment';
import { FormGroup, FormElements } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';
import { DragAndDropTypes } from 'types/index';
import { GroupCardHeader } from '.';
import { DropzoneRow } from '../../Dropzone/DropzoneRow';
import { DraggableElement } from '../../Dropzone/DesignerDropZone.style';

import {
	Container,
	BodyWrapper,
	Body,
	DroppableArea,
	TextRegular,
	Border,
	MenuWrapper,
	MenuWrapperPlaceholder
} from './DraggableFormGroup.style';
import { isElementCombinable } from 'helpers/forms';
import { useTranslation, useRemoveFormGroup, useIsFormGroupEmpty } from 'hooks/store';

interface Props {
	variablesData: VariablesData;
	formGroup: FormGroup;
	elements: FormElements;
	accessWrite: boolean;
}

export function DraggableFormGroup({ variablesData, formGroup, elements, accessWrite }: Props) {
	const { translate } = useTranslation();

	const [groupExpanded, setGroupExpanded] = useState(false);

	const { groupId, elementsOrder } = formGroup;
	const { variablesMap } = variablesData;

	const noVariablesMessage = translate(dict => dict.varGroup.noVariablesInsideGroup);

	const emptyForm = elementsOrder.length === 0;

	const removeFormGroup = useRemoveFormGroup();

	const { isEmpty } = useIsFormGroupEmpty({ formGroup, elements });

	// ONLY ALLOW THE PROJECT OWNER TO SEE AND EDIT
	// RESTRICT THE COLLABORATORS TO SEE EMPTY GROUPS IN FORM DESIGNER
	if (!accessWrite && isEmpty) return null;

	return (
		<Container>
			<GroupCardHeader
				formGroup={formGroup}
				elements={elements}
				groupExpanded={groupExpanded}
				onChange={setGroupExpanded}
				disabled={!accessWrite}
				usedInFormDesigner
			/>

			<BodyWrapper expanded={groupExpanded}>
				<Body>
					{groupExpanded && (
						<Droppable droppableId={groupId} type={groupId} isCombineEnabled>
							{(provided, snapshot) => (
								<DroppableArea
									ref={provided.innerRef}
									style={
										snapshot.isDraggingOver
											? { backgroundColor: Colors.olive }
											: undefined
									}
								>
									{/* NO ELEMENTS */}
									{emptyForm && <TextRegular>{noVariablesMessage}</TextRegular>}

									{/* FORM ELEMENTS */}
									{elementsOrder.map((row, rowIndex) => {
										const descendingIndex = elementsOrder.length - rowIndex;

										// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
										if (Array.isArray(row)) {
											const leftElementId = row[0];
											const rightElementId = row[1];

											const rowElements = [
												elements[leftElementId],
												elements[rightElementId]
											];

											return (
												<Draggable
													key={`${DragAndDropTypes.DraggableFormGroup}${groupId}-${rowIndex}`}
													draggableId={`${DragAndDropTypes.DraggableFormGroup}${groupId}-${rowIndex}`}
													index={rowIndex}
													isDragDisabled={!accessWrite}
												>
													{provided => (
														<DraggableElement
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<DropzoneRow
																variablesData={variablesData}
																rowIndex={rowIndex}
																elements={rowElements}
																groupId={groupId}
																accessWrite={accessWrite}
																descendingIndex={descendingIndex}
															/>
														</DraggableElement>
													)}
												</Draggable>
											);
										}
										// ONE COLUMN - FORM ELEMENT
										else {
											const elementId = row;

											const element = elements[elementId];

											return (
												<Draggable
													key={elementId}
													draggableId={elementId}
													index={rowIndex}
													isDragDisabled={!accessWrite}
												>
													{(provided, snapshot) => {
														const isCombinable = isElementCombinable({
															provided,
															snapshot
														});

														return (
															<DraggableElement
																ref={provided.innerRef}
																isCombinable={isCombinable}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															>
																<FormVariable
																	element={element}
																	variable={
																		variablesMap[
																			element.variableRef ??
																				''
																		]
																	}
																	isInGroup
																	disabled={!accessWrite}
																	canBeRemoved={false}
																	usedInFormDesigner
																/>
															</DraggableElement>
														);
													}}
												</Draggable>
											);
										}
									})}
									{provided.placeholder}
								</DroppableArea>
							)}
						</Droppable>
					)}

					<Border>
						<MenuWrapper>
							<DeleteIcon svg={Svgs.Delete} size={s => s.m} disabled={!accessWrite} />
						</MenuWrapper>
					</Border>
				</Body>
			</BodyWrapper>

			<MenuWrapperPlaceholder onClick={() => accessWrite && removeFormGroup({ groupId })} />
		</Container>
	);
}

import { createActivity } from 'store/ui/activities';
import { ActionPayload, Thunk, ThunkContext, ThunkDispatch } from 'store/types';
import { setLanguage } from 'store/ui/i18n';
import { StorageKeys, DeepPartial } from 'types/index';

import {
	GetSubscriptionAction,
	ActionTypes,
	SubscriptionUsersById,
	AddSubscriptionUserAction,
	RemoveSubscriptionUserAction,
	GetOrganizationsAction,
	GetAccountAction,
	UpdateAccountAction,
	AccountDetails,
	GetLicenceModelsAction,
	ChangeUserLicenceModelAction,
	CancelUserSubscriptionInvitationAction,
	SetSubscriptionUsersSearchTermAction,
	SubscriptionUser,
	SubscriptionDetails,
	GetSubscriptionApiKeyAction,
	UserLicenceModel,
	LicenceModels,
	SubscriptionName
} from './types';
import { getUserAddonsAction } from '../addons';
import { setOrganizationAccessRightsAction } from '../enterprise';

//  -- ACCOUNT DETAILS --

export const getAccountAction = (payload: ActionPayload<GetAccountAction>): GetAccountAction => ({
	type: ActionTypes.GET_ACCOUNT,
	payload
});

export const getAccount = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ACCOUNT,
		dispatch
	});

	let errorMessage = null;
	const startTime = new Date().getTime();

	do {
		try {
			if (!errorMessage) activity.begin();
			await fetchAccount(dispatch, context);
			// in case it succeeds, set errorMessage to null and don't retry
			errorMessage = null;
		} catch (e: any) {
			errorMessage = e.message;
			// wait one second before retrying
			await new Promise(resolve => setTimeout(resolve, 1000));
		}
	} while (new Date().getTime() - startTime < 20000 && errorMessage);

	activity.end();
	if (errorMessage) activity.error({ error: errorMessage });
};

export const fetchAccount = async (dispatch: ThunkDispatch, context: ThunkContext) => {
	const {
		user: userData,
		userSubscriptionAddons,
		activeUserSubscriptionAddons
	} = await context.api.account.subscription().getAccount();

	const savedLanguage = localStorage.getItem(StorageKeys.UserLanguage);
	if (!savedLanguage) {
		localStorage.setItem(StorageKeys.UserLanguage, userData.userPreferences.chosenLanguageCode);
		dispatch(setLanguage({ language: userData.userPreferences.chosenLanguageCode }));
	}

	const imageBase64String = userData.imageURL
		? await context.api.account.subscription().getAvatar(userData.imageURL)
		: '';

	// handling naming difference in licence/license
	const { licenseModel, organization, ...userDataRest } = userData;

	dispatch(
		getAccountAction({
			account: {
				...userDataRest,
				licenceModel: licenseModel,
				imageString: imageBase64String
			}
		})
	);

	dispatch(
		getUserAddonsAction({
			userAddons: userSubscriptionAddons,
			activeUserAddons: activeUserSubscriptionAddons
		})
	);
};

const updateAccountAction = (payload: ActionPayload<UpdateAccountAction>): UpdateAccountAction => ({
	type: ActionTypes.UPDATE_ACCOUNT,
	payload
});

export const updateAccount =
	(accountDetails: DeepPartial<AccountDetails>, isUserActivating?: boolean): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_ACCOUNT,
			dispatch
		});

		try {
			activity.begin();

			// Storing avatar photo first
			if (accountDetails.imageString && accountDetails.imageURL) {
				const storeAccountDetials = getState().account.subscription.accountDetails;
				const prevImageString = storeAccountDetials
					? storeAccountDetials.imageString ?? ''
					: '';
				const avatarChanged = accountDetails.imageString !== prevImageString;

				if (avatarChanged) {
					const newImageURL = await context.api.account
						.subscription()
						.storeAvatar(accountDetails.imageURL, accountDetails.imageString);
					accountDetails.imageURL = newImageURL;
				}
			}

			const { imageString, licenceModel, ...accountRest } = accountDetails;

			// handling naming difference in licence/license
			let accountToStore;
			if (licenceModel) accountToStore = { licenseModel: licenceModel, ...accountRest };
			else accountToStore = accountRest;

			await context.api.account
				.subscription()
				.updateAccount({ ...accountToStore }, isUserActivating);

			dispatch(updateAccountAction({ accountDetails }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

//  -- SUBSCRIPTION --

export const getSubscriptionAction = (
	payload: ActionPayload<GetSubscriptionAction>
): GetSubscriptionAction => ({
	type: ActionTypes.GET_SUBSCRIPTION,
	payload
});

export const getSubscription = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SUBSCRIPTION,
		dispatch
	});

	try {
		activity.begin();

		const {
			coveredUsers,
			subscriptionDetails: apiSubscriptionDetails,
			isNonSubscriber,
			hasMetadataDefinition
		} = await context.api.account.subscription().getSubscription();

		const subscriptionUsersById: SubscriptionUsersById = {};
		if (coveredUsers) {
			coveredUsers.forEach(user => {
				const { licenseModel, ...apiUser } = user;
				const coveredUser: SubscriptionUser = {
					...apiUser,
					licenceModel: licenseModel
				};
				subscriptionUsersById[user.userid] = coveredUser;
			});
		}

		// For subscription bundle development
		// const mockLicenceQuantities = {
		// 	'ledidi-collaborator-licence-EUR-Yearly': 10,
		// 	'ledidi-prime-licence-EUR-Yearly': 5
		// };

		// const mockAvailableLicenceQuantities = {
		// 	'ledidi-collaborator-licence-EUR-Yearly': 3,
		// 	'ledidi-prime-licence-EUR-Yearly': 4
		// };

		const subscriptionDetails: SubscriptionDetails | null = apiSubscriptionDetails
			? {
					isPayedMonthly: apiSubscriptionDetails.isPayedMonthly,
					refundableCredits: apiSubscriptionDetails.refundableCredits,
					subscriptionId: apiSubscriptionDetails.id,
					subscriptionName: apiSubscriptionDetails.name,
					licenceQuantity: apiSubscriptionDetails.licenceQuantity,
					availableLicenceQuantity: apiSubscriptionDetails.availableLicenceQuantity,
					licenceQuantities: apiSubscriptionDetails.licenceQuantities,
					availableLicenceQuantities: apiSubscriptionDetails.availableLicenceQuantities,
					ownerFirstName: apiSubscriptionDetails.ownerFirstName,
					ownerLastName: apiSubscriptionDetails.ownerLastName,
					ownerId: apiSubscriptionDetails.ownerId
			  }
			: null;
		dispatch(
			getSubscriptionAction({
				subscriptionDetails,
				subscriptionUsersById,
				isNonSubscriber,
				hasMetadataDefinition
			})
		);
		if (
			apiSubscriptionDetails?.organizationAccessRights &&
			apiSubscriptionDetails.name === SubscriptionName.LedidiEnterprise
		)
			dispatch(
				setOrganizationAccessRightsAction({
					organizationAccessRights: apiSubscriptionDetails?.organizationAccessRights
				})
			);
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const addSubscriptionUserAction = (
	payload: ActionPayload<AddSubscriptionUserAction>
): AddSubscriptionUserAction => ({
	type: ActionTypes.ADD_SUBSCRIPTION_USER,
	payload
});

const cancelUserSubscriptionInvitationAction = (
	payload: ActionPayload<CancelUserSubscriptionInvitationAction>
): CancelUserSubscriptionInvitationAction => ({
	type: ActionTypes.CANCEL_SUBSCRIPTION_USER_INVITATION,
	payload
});

export const cancelUserSubscriptionInvitation =
	(userId: string, licenceModel: UserLicenceModel): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.CANCEL_SUBSCRIPTION_USER_INVITATION,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.subscription().cancelUserSubscriptionInvitation({ userId });

			dispatch(cancelUserSubscriptionInvitationAction({ userId, licenceModel }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const removeSubscriptionUserAction = (
	payload: ActionPayload<RemoveSubscriptionUserAction>
): RemoveSubscriptionUserAction => ({
	type: ActionTypes.REMOVE_SUBSCRIPTION_USER,
	payload
});

export const removeSubscriptionUser =
	(userId: string, licenceModel: UserLicenceModel): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_SUBSCRIPTION_USER,
			dispatch
		});

		try {
			activity.begin();

			await context.api.account.subscription().removeSubscriptionUser(userId);

			dispatch(removeSubscriptionUserAction({ userId, licenceModel }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const changeUserLicenceModelAction = (
	payload: ActionPayload<ChangeUserLicenceModelAction>
): ChangeUserLicenceModelAction => ({
	type: ActionTypes.CHANGE_USER_LICENCE_MODEL,
	payload
});

export const changeUserLicenceModel =
	(payload: ActionPayload<ChangeUserLicenceModelAction>): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.CHANGE_USER_LICENCE_MODEL,
			dispatch
		});

		try {
			activity.begin();

			const { userId, licenceModel } = payload;

			await context.api.account.subscription().changeUserLicenceModel(userId, licenceModel);

			dispatch(changeUserLicenceModelAction(payload));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const getSubscriptionApiKeyAction = (
	payload: ActionPayload<GetSubscriptionApiKeyAction>
): GetSubscriptionApiKeyAction => ({
	type: ActionTypes.GET_SUBSCRIPTION_API_KEY,
	payload
});

export const getSubscriptionApiKey = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SUBSCRIPTION_API_KEY,
		dispatch
	});

	try {
		activity.begin();

		const apiKey = await context.api.account.subscription().getSubscriptionApiKey();

		dispatch(getSubscriptionApiKeyAction({ apiKey }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

//  -- ORGANIZATIONS --

const getOrganizationsAction = (
	payload: ActionPayload<GetOrganizationsAction>
): GetOrganizationsAction => ({
	type: ActionTypes.GET_ORGANIZATIONS,
	payload
});

export const getOrganizations = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_ORGANIZATIONS,
		dispatch
	});

	try {
		activity.begin();

		const organizations = await context.api.account.subscription().getOrganizations();
		dispatch(getOrganizationsAction({ organizations }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const getLicenceModelsAction = (
	payload: ActionPayload<GetLicenceModelsAction>
): GetLicenceModelsAction => ({
	type: ActionTypes.GET_LICENCE_MODELS,
	payload
});

export const getLicenceModels = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_LICENCE_MODELS,
		dispatch
	});

	try {
		activity.begin();

		const apiLicenceModels = await context.api.account.subscription().getLicenceModels();

		// TODO: BE will send more data in the Bundle version including licenceModelsMetadataById
		const licenceModels: LicenceModels = {
			allLicenceModels: apiLicenceModels as UserLicenceModel[],
			licenceModelsMetadataById: {}
			// For Bundle Subscription development
			// allLicenceModels: [
			// 	UserLicenceModel.Full,
			// 	UserLicenceModel.Collaborator,
			// 	UserLicenceModel.OneOwnedOneShared,
			// 	UserLicenceModel.Trial
			// ],
			// licenceModelsMetadataById: {
			// 	'ledidi-prime-licence-EUR-Monthly': {
			// 		currency: LedidiPlanCurrency.EUR,
			// 		licenceModel: UserLicenceModel.Full,
			// 		price: 70,
			// 		billingUnit: BillingPeriodUnit.Month
			// 	},
			// 	'ledidi-prime-licence-EUR-Yearly': {
			// 		currency: LedidiPlanCurrency.EUR,
			// 		licenceModel: UserLicenceModel.Full,
			// 		price: 840,
			// 		billingUnit: BillingPeriodUnit.Year
			// 	},
			// 	'ledidi-prime-licence-NOK-Monthly': {
			// 		currency: LedidiPlanCurrency.NOK,
			// 		licenceModel: UserLicenceModel.Full,
			// 		price: 840,
			// 		billingUnit: BillingPeriodUnit.Month
			// 	},
			// 	'ledidi-prime-licence-NOK-Yearly': {
			// 		currency: LedidiPlanCurrency.NOK,
			// 		licenceModel: UserLicenceModel.Full,
			// 		price: 10080,
			// 		billingUnit: BillingPeriodUnit.Year
			// 	},
			// 	'ledidi-collaborator-licence-EUR-Monthly': {
			// 		currency: LedidiPlanCurrency.EUR,
			// 		licenceModel: UserLicenceModel.Collaborator,
			// 		price: 40,
			// 		billingUnit: BillingPeriodUnit.Month
			// 	},
			// 	'ledidi-collaborator-licence-EUR-Yearly': {
			// 		currency: LedidiPlanCurrency.EUR,
			// 		licenceModel: UserLicenceModel.Collaborator,
			// 		price: 480,
			// 		billingUnit: BillingPeriodUnit.Year
			// 	},
			// 	'ledidi-collaborator-licence-NOK-Monthly': {
			// 		currency: LedidiPlanCurrency.NOK,
			// 		licenceModel: UserLicenceModel.Collaborator,
			// 		price: 480,
			// 		billingUnit: BillingPeriodUnit.Month
			// 	},
			// 	'ledidi-collaborator-licence-NOK-Yearly': {
			// 		currency: LedidiPlanCurrency.NOK,
			// 		licenceModel: UserLicenceModel.Collaborator,
			// 		price: 5760,
			// 		billingUnit: BillingPeriodUnit.Year
			// 	}
			// }
		};
		dispatch(getLicenceModelsAction({ licenceModels }));
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const setSubscriptionUsersSearchTerm = (
	payload: ActionPayload<SetSubscriptionUsersSearchTermAction>
): SetSubscriptionUsersSearchTermAction => ({
	type: ActionTypes.SET_SUBSCRIPTION_USERS_SEARCH_TERM,
	payload
});

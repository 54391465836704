import styled from 'styled-components';

import { Shadows } from 'environment';

export const Container = styled.div`
	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		border-top: 0;
	}

	.errored {
		background-color: rgba(255, 88, 93, 0.1);
	}
`;

interface PaymentMethodCardProps {
	disabled?: boolean;
}

export const PaymentMethodCard = styled.div<PaymentMethodCardProps>`
	display: flex;
	align-items: start;

	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};

	min-height: 10.6rem;

	padding: 1.6rem;

	/* cursor: ${({ disabled }) => !disabled && 'pointer'};

	:hover {
		box-shadow: ${({ disabled }) => !disabled && Shadows.hover};
	} */
`;

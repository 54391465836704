import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	position: relative;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column;
	}
`;

export const SettingsContainer = styled.div`
	width: 100%;
	max-width: 35rem;
	display: flex;
	flex-direction: column;
	margin-right: 4rem;

	@media ${MediaQueries.maxWidth.lg} {
		max-width: unset;
	}
`;

export const NoDependenciesContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: calc(100vh - 23rem);
`;

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { updateImportPercentage } from 'store/data/projects/actions';

export function useResetImportPercentage(): LocalOperation<() => void> {
	const dispatch = useDispatch();

	function handler() {
		dispatch(updateImportPercentage({ importPercentage: 0 }));
	}

	return handler;
}

import { apiFactory } from 'api';
import { TransferEntriesOwnershipInput } from 'api/data/entries';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { useStatic } from 'hooks/utils';
import { ActionTypes } from 'store/data/entries';
import { useRefetchAnalyses } from '../analysis/useRefetchAnalyses';
import { useRefetchEntries } from './useRefetchEntries';

export function useTransferEntriesOwnership(): ApiOperation<
	void,
	(input: TransferEntriesOwnershipInput) => void
> {
	const [, refetchEntries] = useRefetchEntries();
	const [, refetchAnalyses] = useRefetchAnalyses();

	const [getRequestData, setRequestData] = useStatic<TransferEntriesOwnershipInput>({
		projectId: Number(''),
		datasetentryids: []
	});

	const [{ data, error, loading, fetched }, transferEntriesOwnership, resetData] = useAPI<void>({
		promiseFn: () => promiseFn(getRequestData()),
		lazy: true,
		initialData: undefined,
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		onSuccess: () => {
			refetchEntries();
			refetchAnalyses();
		},
		activityType: ActionTypes.TRANSFER_ENTRIES_OWNERSHIP
	});

	function promiseFn(input: TransferEntriesOwnershipInput) {
		return apiFactory().data.entries().transferEntriesOwnership(input);
	}

	function handler(input: TransferEntriesOwnershipInput) {
		transferEntriesOwnership(() => promiseFn(input));
		setRequestData(input);
	}

	return [{ data, error, loading, fetched }, handler, resetData];
}

import styled, { css } from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons';
import { Flex as FlexComponent } from 'components/UI/Flex';
import { Colors, MediaQueries, Shadows } from 'environment';

interface LegendProps {
	color: string;
}

export const ResponseRateChartContainer = styled.div`
	display: flex;
	justify-content: space-between;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column;
	}

	[id$='-license-text'] {
		transform: scale(-1, 1);
		margin-top: 0.8rem;
	}
`;

export const ChartFilledPercentage = styled.div.attrs(() => ({
	className: 'h2'
}))`
	position: absolute;
	transform: scale(-1, 1);
`;

export const LegendCircleFill = styled.div<LegendProps>`
	border: 0.2rem solid ${({ color }) => color};
	border-radius: 50%;
	min-width: 3rem;
	height: 3rem;
	color: ${Colors.text.main};
	box-shadow: 0 1px 6px 0 ${({ color }) => color};
`;

export const Icon = styled(DefaultIcon)`
	cursor: default;
`;

export const CardView = styled(FlexComponent)<{ right?: boolean }>`
	box-shadow: ${Shadows.normal};
	border-radius: 0.4rem;

	${({ right }) =>
		!right
			? css`
					width: 100%;
					padding: 1rem;
					@media ${MediaQueries.maxWidth.lg} {
						flex-direction: column;
						margin-bottom: 2.4rem;
					}
			  `
			: css`
					padding: 2.4rem 1.6rem;
					height: 100%;
					@media ${MediaQueries.maxWidth.lg} {
						min-height: 50%;
						width: 48%;
						height: 16rem;
					}
			  `}
`;

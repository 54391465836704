import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { Colors, Svgs } from 'environment';
import { Container } from './ErrorPage.style';
import { useNavigation } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';

export function ErrorPage() {
	const { translate } = useTranslation();

	const { navigate, routes } = useNavigation();

	function refreshPage() {
		window.location.reload();
	}

	function goToProjects() {
		navigate(routes.projects.list);
		refreshPage();
	}

	return (
		<Container>
			<Svgs.LedidiLogoColored height={200} width={200} />

			<Typography.H1
				fontweight={w => w.normal}
				color={Colors.primary.normal}
				marginOffset={{ bottom: 2 }}
			>
				{translate(dict => dict.errorPage.title)}
			</Typography.H1>

			<Typography.H4 marginOffset={{ bottom: 4 }} multiline alignCenter>
				{translate(dict => dict.errorPage.message)}
			</Typography.H4>

			<Flex marginOffset={{ bottom: 8 }}>
				<Button
					variant={v => v.link}
					title={translate(dict => dict.buttons.reload)}
					onClick={refreshPage}
					marginOffset={{ right: 2 }}
				/>

				{history && (
					<Button
						variant={v => v.outline}
						title={translate(dict => dict.buttons.goToProjects)}
						onClick={goToProjects}
					/>
				)}
			</Flex>
		</Container>
	);
}

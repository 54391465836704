import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, downgradeSubscriptionToFree } from 'store/account/payments';

export function useDowngradeSubscriptionToFree(): Operation<() => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.DOWNGRADE_SUBSCRIPTION_TO_FREE);

	function handler() {
		dispatch(downgradeSubscriptionToFree());
	}

	return [{ loading, error }, handler];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	ProjectNotificationSettings,
	updateProjectNotificationSettings
} from 'store/data/notifications';

export function useUpdateProjectNotificationSettings(
	projectId: string
): Operation<(settings: ProjectNotificationSettings) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, reset] = useActivity(
		ActionTypes.UPDATE_PROJECT_NOTIFICATION_SETTINGS,
		projectId
	);

	function handler(settings: ProjectNotificationSettings) {
		reset();
		if (projectId) dispatch(updateProjectNotificationSettings(projectId, settings));
	}

	return [{ loading, error }, handler];
}

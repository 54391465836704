import styled from 'styled-components';
import { Colors } from 'environment';

const BoldText = styled.span`
	font-weight: bold;
	color: ${Colors.text.main};
`;

interface Props {
	sentence: string;
	searchTerm: string;
}

export function BoldTextHighlight({ sentence, searchTerm }: Props) {
	if (!searchTerm) {
		return <>{sentence}</>;
	}

	const regex = new RegExp(`(${searchTerm})`, 'gi');
	const words = sentence.split(regex);

	return (
		<>
			{words.map((word, index) =>
				regex.test(word) ? <BoldText key={index}>{word}</BoldText> : word
			)}
		</>
	);
}

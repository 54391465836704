import { isPast } from 'date-fns';
import format from 'date-fns/format';
import { isEmpty, without } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import {
	Cell,
	CellProps,
	Column,
	ColumnGroup,
	ColumnInstance,
	HeaderGroup,
	Row,
	usePagination,
	useSortBy,
	useTable
} from 'react-table';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import {
	dateTimeFormatMap,
	DATE_FORMAT,
	STATUS_COLUMN,
	systemGeneratedVariables,
	SELECT_COLUMN
} from 'consts';
import { Colors, Svgs } from 'environment';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import {
	DEFAULT_GET_ENTRIES_SORT,
	Entries,
	Entry,
	EntryValue,
	SelectedEntry,
	selectEntriesStatusMap,
	selectEntriesTableParams,
	setEntriesTableParams
} from 'store/data/entries';
import { setActiveSortAction } from 'store/ui/tables';
import { SortingType, TableSort } from 'store/ui/tables/types';
import { ColumnFilter } from './ColumnFilter';
import { ColumnSettings } from './ColumnSettings';
import { AggregationRuleType } from 'api/data/variables';
import {
	ColorIndicator,
	ColumnsPaginationControllers,
	Container,
	ExpiredStatusIndicator,
	IconWrapper,
	StatusTag,
	StatusTagWrapper,
	Wrapper,
	FlexContainer,
	SeriesView,
	SeriesCaption
} from './EntriesTable.style';
import { Icon } from 'components/UI/Icons';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { WarningWithFilters } from 'components/UI/WarningWithFilters';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { Table } from 'components/UI/Table';
import {
	getEntriesTableColumnSortType,
	buildEntriesTableColumns,
	parseApiSortToActiveSort,
	zeroPadDate
} from 'helpers/entries';
import { isValidDate } from 'helpers/isValidDate';
import {
	buildVariablesRichData,
	variablesDataArrayIterator,
	getAggregationRuleTypeLabel,
	mapVariableCategoryValuesToLabels,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';
import {
	useDeepCompareMemo,
	useDispatch,
	useEffectOnce,
	usePaginate,
	useSelector,
	useStatic
} from 'hooks/utils';
import {
	useCollaborators,
	useTranslation,
	useVariablesData,
	useVariablesDataSelectItems,
	useExpandGroupsContext,
	useStatuses,
	useEntriesErrors,
	useEntriesTableVisibleColumns,
	useTransferEntriesOwnershipContext,
	useTimeDurationEntries,
	useAccount,
	usePermissions
} from 'hooks/store';
import {
	setDeleteFullScreenSeriesNameTableView,
	setUpdateFullScreenSeriesNameTableView
} from 'store/data/series';
import { Loader } from 'components/UI/Loader';
import { TableName } from 'types/index';
import { selectLanguage, selectTranslations } from 'store/ui/i18n';

interface Props {
	entries: Entries;
	onRowClick: (entryId: string, newTab?: boolean) => void;
	onHeaderVariableClick: (variableName: string) => void;
	loading: boolean;
	activeSort?: TableSort;
	handleSort: (columnName: string) => void;
	handleSeriesClick: (entryId: string) => void;
	filterDrawerContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export function EntriesTable({
	entries,
	loading = true,
	activeSort,
	filterDrawerContainerRef = undefined,
	handleSort,
	onRowClick,
	onHeaderVariableClick,
	handleSeriesClick
}: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const [
		{
			data: { username }
		}
	] = useAccount();
	const { projectOwnerHasAccessEntriesAllTransferOwnership } = usePermissions();
	const language = useSelector(state => selectLanguage(state.ui.i18n));
	const translations = useSelector(state => selectTranslations(state.ui.i18n));

	const variablesData = useVariablesData({ initial: true });
	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData);

	const tableRef = useRef<HTMLTableElement>(null);
	const tableResponsiveRef = useRef<HTMLDivElement>(null);

	const { parseTimeDurationEntryCell, parseVariableSetAggregatedValue } = useTimeDurationEntries({
		withTranslation: true
	});

	const { expanded, toggleGroup } = useExpandGroupsContext();

	const { pageSize, totalCount, pageIndex } = useSelector(state =>
		selectEntriesTableParams(state.data.entries)
	);

	const [
		{
			data: { organizationsMap }
		}
	] = useCollaborators();

	const [
		{
			data: { statusesMap, hasStatuses }
		}
	] = useStatuses({ lazy: true });

	const [
		{
			errors: entriesErrors,
			filter: entriesErrorsFilter,
			draftFilter: draftEntriesErrorsFilter
		},
		{ clearFilters, toggleRowsFilter, toggleColumnsFilter }
	] = useEntriesErrors();

	const [visibleColumnNames] = useEntriesTableVisibleColumns();

	const [clickedEntryId, setClickedEntryId] = useStatic('');

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);
	const entriesStatusMap = useSelector(state => selectEntriesStatusMap(state.data.entries));

	// TRANSFER ENTRIES OWNERSHIP
	const {
		actions: {
			getIsTransferOwnership,
			getSelectedEntries,
			setSelectedEntries,
			removeAllSelectedEntries,
			toggleAllSelectedEntriesConfirmed,
			getPreviewModal
		}
	} = useTransferEntriesOwnershipContext();

	const selectedEntries = getSelectedEntries();

	const transferOwnershipPreviewModal = getPreviewModal();

	const { variablesDataArray, variablesMap, groupsMap, variableSetsMap } =
		buildVariablesRichData(variablesData);

	useEffectOnce(() => {
		dispatch(setDeleteFullScreenSeriesNameTableView({ seriesName: null }));
	});

	/**
	 * Build table columns
	 *
	 * @for `useTable`
	 */
	const tableColumns = useDeepCompareMemo<Column<Entry>[]>(() => {
		const tableColumns: Column<Entry>[] = [];

		const hasAggregationRules = !!Object.keys(variableSetsMap).find(
			setKey => variableSetsMap[setKey].aggregationRules.length > 0
		);

		const hasGroupWithVariables = isEmpty(groupsMap)
			? true
			: !!Object.values(groupsMap).find(
					group => group.variablesBelongingToGroup.length === 0
			  );

		const hasExtendedHeader = hasAggregationRules ?? hasGroupWithVariables;

		// HANDLE STATUS COLUMN (AT POSITION 0)
		if (hasStatuses) {
			const { name, label } = STATUS_COLUMN;

			tableColumns.push({
				Header: ({ column }) =>
					buildHeader({
						title: label,
						column,
						filter: (
							<ColumnFilter
								tableRef={tableRef}
								columnName={column.id}
								variablesData={variablesData}
								variablesDataSelectItems={variablesDataSelectItems}
								computePosition
							/>
						)
					}),
				accessor: name,
				disableSortBy: true,
				Cell: ({ row, value }: CellProps<Entry, EntryValue>) => {
					value = value as string;

					const statusVariable = statusesMap[value];

					if (!statusVariable) return null;

					const { label, statusColor } = statusVariable;

					return (
						<>
							<StatusTagWrapper>
								<StatusTag color={statusColor}>{label}</StatusTag>
							</StatusTagWrapper>

							{/* EXPIRED STATUS INDICATOR */}
							{isPastDueDate(row.original.datasetentryid) && (
								<ExpiredStatusIndicator />
							)}
						</>
					);
				},
				customCellProps: {
					style: {
						position: 'relative'
					}
				},
				customHeaderProps: {
					style: {
						top: !hasExtendedHeader ? 0 : '2.5rem'
					}
				}
			});
		}

		variablesDataArrayIterator(
			variablesDataArray,
			variable => tableColumns.push(buildVariableTableColumn(variable, hasExtendedHeader)),
			groupData => {
				const { groupName, groupLabel, groupVariables } = groupData;

				const groupTableColumn: ColumnGroup<Entry> = {
					id: groupName,
					Header: buildGroupHeader({
						id: groupName,
						title: groupLabel,
						customComponent: <ColorIndicator type="group" />,
						icon: (
							<Icon
								onClick={() => toggleGroup(groupName)}
								style={{ cursor: 'pointer' }}
								size={size => size.m}
								svg={Svgs.ChevronRight}
								rotate={expanded[groupName] ? 90 : 0}
							/>
						)
					}),
					columns: groupVariables.map(variable =>
						buildVariableTableColumn(variable, hasExtendedHeader)
					)
				};

				// INSERT TABLE COLUMN ONLY IF IT HAS SUB-COLUMNS;
				if (groupTableColumn.columns.length) tableColumns.push(groupTableColumn);

				// EXPAND FEATURE;
				if (!expanded[groupName] && groupName in expanded) {
					// REPLACE PREVIOUSLY BUILT TABLE COLUMNS;
					tableColumns.push({
						...groupTableColumn,
						columns: [
							{
								accessor: `${groupName}-placeholder`,
								Cell: () => <>-</>
							}
						]
					});
				}
			},
			variableSetData => {
				const { setName, setLabel, aggregationRules } = variableSetData;

				const variableSetTableColumn: ColumnGroup<Entry> = {
					id: setName,
					Header: buildGroupHeader({
						id: setName,
						title: setLabel,
						customComponent: <ColorIndicator type="variableSet" />
					}),
					columns: []
				};

				aggregationRules.forEach(aggregationRule => {
					const { name, aggregator } = aggregationRule;

					const aggregatorVariable = variablesMap[aggregator.variableName];

					const label = `${aggregatorVariable.label} (${translate(
						dict =>
							dict.variablesPage.aggregationRuleType[
								getAggregationRuleTypeLabel(aggregationRule) as AggregationRuleType
							]
					).toUpperCase()})`;

					variableSetTableColumn.columns.push({
						Header: ({ column }) =>
							buildHeader({
								title: label,
								column,
								filter: (
									<ColumnFilter
										filterDrawerContainerRef={filterDrawerContainerRef}
										tableRef={tableRef}
										columnName={column.id}
										variablesData={variablesData}
										variablesDataSelectItems={variablesDataSelectItems}
										computePosition
									/>
								)
							}),
						accessor: name,
						Cell: ({ value }: CellProps<Entry, EntryValue>) => {
							value = value as Exclude<EntryValue, string[]>;

							if (value === null) return translate(dict => dict.entries.empty);

							const parsedValues = parseVariableSetAggregatedValue(
								value,
								aggregationRule,
								{
									variablesMap,
									dateTimeFormat
								}
							);

							return (
								<Flex
									css={`
										width: 100%;
										white-space: nowrap;
										position: relative;
									`}
									justify={j => j.between}
								>
									<SeriesCaption>{parsedValues}</SeriesCaption>
								</Flex>
							);
						},
						customHeaderProps: {
							style: {
								top: !hasExtendedHeader ? 0 : '2.5rem'
							}
						}
					});
				});

				// INSERT TABLE COLUMN ONLY IF IT HAS SUB-COLUMNS
				if (variableSetTableColumn.columns.length) {
					tableColumns.push(variableSetTableColumn);
				}
			}
		);

		// ADD SYSTEM GENERATED VARIABLES
		systemGeneratedVariables.forEach(variableName => {
			const label = translate(
				dict =>
					dict.variables.systemGeneratedVariables[
						variableName as keyof typeof dict.variables.systemGeneratedVariables
					]
			);

			const dateSystemGeneratedVariables = ['creationdate', 'lastmodifieddate'];
			const isGroupName = variableName === 'userProjectOrgId';
			const isDate = dateSystemGeneratedVariables.includes(variableName);

			tableColumns.push({
				Header: ({ column }) =>
					buildHeader({
						title: label,
						column
					}),
				accessor: variableName,
				...(isGroupName && {
					Cell: ({ value }: CellProps<Entry, EntryValue>) => (
						<>
							{value &&
							typeof value === 'string' &&
							organizationsMap &&
							value in organizationsMap
								? organizationsMap[value].name
								: ''}
						</>
					),
					sortType: getEntriesTableColumnSortType
				}),
				...(isDate && {
					Cell: ({ value }: CellProps<Entry, EntryValue>) => (
						<>
							{value &&
								format(
									new Date(
										value && typeof value === 'string' && isValidDate(value)
											? value.replace(/\s/g, 'T')
											: (value as string)
									),
									dateTimeFormatMap[dateTimeFormat]
								)}
						</>
					),
					sortType: getEntriesTableColumnSortType
				}),
				customHeaderProps: {
					style: {
						top: !hasExtendedHeader ? 0 : '2.5rem'
					}
				}
			});
		});

		return tableColumns;
	}, [
		expanded,
		variablesData,
		hasStatuses,
		selectedEntries,
		getIsTransferOwnership(),
		getPreviewModal(),
		entries,
		translations,
		dateTimeFormat,
		organizationsMap,
		activeSort,
		pageSize,
		organizationsMap
	]);

	const allColumnNames = useMemo<string[]>(
		() =>
			buildEntriesTableColumns(variablesData, {
				hasStatusColumn: hasStatuses
			}),
		[variablesData, hasStatuses]
	);

	const filteredColumns = useMemo<string[]>(() => {
		let filtered = [...allColumnNames];

		const { groupsMap } = buildVariablesRichData(variablesData);

		if (visibleColumnNames.length) {
			filtered = filtered.filter(column => visibleColumnNames.includes(column));
		}

		if (
			entriesErrors &&
			entriesErrors.columns &&
			entriesErrors.columns.length &&
			entriesErrorsFilter.columns
		) {
			filtered = filtered.filter(column => entriesErrors.columns.includes(column));
		}

		const collapsedVariables = Object.entries(expanded)
			.filter(([, expanded]) => !expanded)
			.map(([key]) => groupsMap[key].variablesBelongingToGroup)
			.flat();

		filtered = filtered.filter(column => !collapsedVariables.includes(column));

		return filtered;
	}, [
		language,
		allColumnNames,
		visibleColumnNames,
		entriesErrors,
		entriesErrorsFilter.columns,
		variablesData,
		expanded
	]);

	const {
		shouldPaginate: shouldPaginateColumns,
		////////////
		page: paginatedColumns,
		//////////////////////
		canPrevPage: canPrevColumnsPage,
		canNextPage: canNextColumnsPage,
		//////////////////////
		firstPage: firstColumnsPage,
		lastPage: lastColumnsPage,
		prevPage: prevColumnsPage,
		nextPage: nextColumnsPage
	} = usePaginate(filteredColumns, {
		threshold: 300,
		pageSize: 100
	});

	/**
	 * Build table hidden columns based on visible columns (reverse thinking)
	 *
	 * @for `useTable` and table-column-pagination logic
	 */
	const hiddenColumns = useMemo<string[]>(() => {
		const columnNames: string[] = [];

		const hiddenColumnNames = without(allColumnNames, ...paginatedColumns);

		columnNames.push(...hiddenColumnNames);

		// Hide `userProjectOrgId` and the users ids
		columnNames.push(systemGeneratedVariables[6]);
		columnNames.push(systemGeneratedVariables[7]);

		// Filter collapsed groups' variables columns;

		return columnNames;
	}, [allColumnNames, paginatedColumns, expanded]);

	function buildHeader(input: {
		title: string;
		column: ColumnInstance<Entry>;
		error?: React.ReactNode;
		filter?: React.ReactNode;
	}) {
		const { title, column, error, filter } = input;

		return (
			<>
				<Flex
					align={a => a.center}
					css={`
						width: 100%;
						white-space: nowrap;

						.column-filter-icon {
							visibility: hidden;
						}

						:hover .column-filter-icon {
							visibility: visible;
						}
					`}
				>
					<Flex fullWidth title={title}>
						{title.length > 20 ? title.substring(0, 20) + '...' : title}

						<Icon
							style={{ opacity: column.isSorted ? 1 : 0 }}
							svg={column.isSortedDesc ? Svgs.ArrowDown : Svgs.ArrowUp}
							size={s => s.m}
							active
							propagate
						/>

						{error}
					</Flex>

					{filter}
				</Flex>
			</>
		);
	}

	function buildGroupHeader(input: {
		id: string;
		title: string;
		customComponent?: React.ReactNode;
		icon?: React.ReactNode;
	}) {
		const { title, customComponent, id, icon } = input;

		return (
			<>
				<Flex
					title={title}
					css={`
						align-items: center;
						width: 100%;
						left: 0.4rem;
						z-index: 999;
						white-space: nowrap;
						cursor: pointer;
					`}
					{...(variablesData.groupsMap[id] && {
						onClick: () => toggleGroup(id)
					})}
				>
					{icon && icon}
					{title.length > 20 ? title.substring(0, 20) + '...' : title}
				</Flex>

				{customComponent}
			</>
		);
	}

	function buildVariableTableColumn(
		variable: Variable,
		hasExtendedHeader: boolean
	): Column<Entry> {
		const { name, label, type } = variable;

		const isDate = [VariableType.Date, VariableType.DateTime].includes(type);
		const isCategoryMultiple = [VariableType.CategoryMultiple].includes(type);
		const columnError = isColumnErrored(name);

		return {
			Header: ({ column }) =>
				buildHeader({
					title: label,
					column,
					error: columnError && (
						<>
							<Icon
								svg={Svgs.Information}
								colors={{
									color: Colors.text.error
								}}
								marginOffset={{ left: 0.6 }}
								size={s => s.m}
								onClick={() => onHeaderVariableClick(name)}
							/>

							<ColorIndicator type="error" />
						</>
					),
					filter: (
						<ColumnFilter
							filterDrawerContainerRef={filterDrawerContainerRef}
							tableRef={tableRef}
							columnName={column.id}
							variablesData={variablesData}
							variablesDataSelectItems={variablesDataSelectItems}
							computePosition
						/>
					)
				}),
			accessor: name,
			Cell: ({ value, row }: CellProps<Entry, EntryValue>) => {
				const entryId = row.original.datasetentryid;
				const parsedValue = parseVariableCell(value, variable, { entryId });

				if (isCellErrored(entryId, name)) {
					return (
						<Flex>
							<Typography.Paragraph color={Colors.text.error}>
								{parsedValue}
							</Typography.Paragraph>

							<Icon
								svg={Svgs.Information}
								colors={{
									color: Colors.text.error
								}}
								marginOffset={{ left: 0.6 }}
								size={s => s.m}
								propagate
							/>
						</Flex>
					);
				}

				return <>{parsedValue}</>;
			},
			...(isDate && {
				sortType: getEntriesTableColumnSortType
			}),
			...(isCategoryMultiple && {
				sortType: (rowA: Row<Entry>, rowB: Row<Entry>, columnId: string) => {
					const valueA: string[] | undefined = rowA.values[columnId];
					const valueB: string[] | undefined = rowB.values[columnId];

					if (valueA && valueB) {
						const stringValueA = valueA.join(',');
						const stringValueB = valueB.join(',');

						return stringValueA.localeCompare(stringValueB);
					}

					if (valueA && !valueB) return 1;
					if (valueB && !valueA) return -1;

					return -1;
				}
			}),
			...(columnError && {
				customHeaderProps: {
					className: 'errored',
					style: {
						position: 'relative'
					}
				},
				customCellProps: {
					className: 'errored'
				}
			}),
			customHeaderProps: {
				style: {
					top: !hasExtendedHeader ? 0 : '2.5rem'
				}
			}
		};
	}

	function parseVariableCell(
		value: EntryValue,
		variable: Variable,
		metadata: {
			entryId: string;
		}
	): React.ReactNode {
		const { entryId } = metadata;

		if (value === null || value === undefined) return translate(dict => dict.entries.empty);

		// `categoryMultiple`
		if (Array.isArray(value)) {
			const labels = mapVariableCategoryValuesToLabels(value, variable);

			return labels.join(', ');
		}

		// `category`
		if (variable.type === VariableType.Category) {
			const label = mapVariableCategoryValueToLabel(value, variable);

			return label ?? translate(dict => dict.entries.empty);
		}

		// `file`
		if (variable.type === VariableType.File) {
			return (
				<IconWrapper>
					<Icon svg={Svgs.File} size={s => s.m} />
				</IconWrapper>
			);
		}

		// `date`
		if (variable.type === VariableType.Date) {
			return format(new Date(zeroPadDate(value)), DATE_FORMAT);
		}

		// `datetime`
		if (variable.type === VariableType.DateTime) {
			return format(new Date(zeroPadDate(value)), dateTimeFormatMap[dateTimeFormat]);
		}

		// 'timeDuration'
		if (variable.type === VariableType.TimeDuration && variable.durationFormat?.length) {
			return parseTimeDurationEntryCell(value, variable.durationFormat);
		}

		/**
		 * Handle errored state - `variable.type` = `string`, `variable.targetType` = [`date` / `datetime`]
		 * - format only cell values that are of `variable.targetType` = [`date` / `datetime`]
		 */
		if (isColumnErrored(variable.name) && !isCellErrored(entryId, variable.name)) {
			try {
				// `date`
				if (variable.targetType === VariableType.Date) {
					return format(new Date(zeroPadDate(value)), DATE_FORMAT);
				}

				// `datetime`
				if (variable.targetType === VariableType.DateTime) {
					return format(new Date(zeroPadDate(value)), dateTimeFormatMap[dateTimeFormat]);
				}
			} catch (e) {
				return value;
			}
		}

		// LONG VALUE - add ellipsis effect
		if (value.length > 29) return value.substr(0, 30) + '...';

		return value;
	}

	const isPreviewEntriesMode = getPreviewModal();

	const {
		page,
		headerGroups,
		pageOptions,
		getTableProps,
		getTableBodyProps,
		prepareRow,
		gotoPage,
		setPageSize,
		setHiddenColumns,
		state,
		setSortBy
	} = useTable(
		{
			columns: tableColumns,
			data:
				getIsTransferOwnership() && !projectOwnerHasAccessEntriesAllTransferOwnership
					? entries.filter(entry => entry.ownedbyuser === username)
					: entries,
			initialState: {
				hiddenColumns,
				pageIndex,
				pageSize,
				sortBy: getSortBy(parseApiSortToActiveSort(DEFAULT_GET_ENTRIES_SORT))
			},
			pageCount: Math.ceil(totalCount / pageSize),
			autoResetPage: false,
			autoResetSortBy: false,
			manualFilters: true,
			...(!isPreviewEntriesMode && { manualSortBy: true }),
			...(!isPreviewEntriesMode && { manualPagination: true })
		},
		useSortBy,
		usePagination,
		hooks => {
			if (getIsTransferOwnership() && entries) {
				hooks.visibleColumns.push(columns => [
					{
						id: 'select_column',
						Header: (
							<Flex justify={j => j.center}>
								<Checkbox
									className="table-checkbox"
									checked={isColumnChecked()}
									partial={isColumnPartiallyChecked()}
									onClick={handleSelectAllItems}
								/>
							</Flex>
						),
						Cell: ({ row }: CellProps<Entry, EntryValue>) => (
							<Checkbox
								className="table-checkbox"
								checked={isRowSelected(row.original.datasetentryid)}
								onClick={() => onRowClick(row.original.datasetentryid)}
							/>
						),
						customHeaderProps: {
							className: 'select-column'
						}
					},
					...columns
				]);
			}
		}
	);

	/**
	 * Sync `initialState.hiddenColumn` when `hiddenColumns` changes
	 */
	useEffect(() => setHiddenColumns(hiddenColumns), [hiddenColumns]);

	/**
	 * Sync local `state.pageIndex` state with controlled prop `pageIndex`
	 *
	 * NOTE: Not applicable when component is being used in transfer-ownership-preview-modal
	 */
	useEffect(() => {
		if (state.pageIndex !== pageIndex) gotoPage(pageIndex);
	}, [state.pageIndex, pageIndex]);

	/**
	 * Reset horizontal scroll when column pagination changes
	 */
	useEffect(() => {
		const { current } = tableResponsiveRef;

		if (current) current.scrollLeft = 0;
	}, [paginatedColumns]);

	function handleChangePageIndex(pageIndex: number) {
		gotoPage(pageIndex);
		dispatch(
			setEntriesTableParams({
				pageSize,
				pageIndex
			})
		);
	}

	function handleChangePageSize(pageSize: number) {
		setPageSize(pageSize);
		dispatch(
			setEntriesTableParams({
				pageSize,
				pageIndex
			})
		);
	}

	function isPastDueDate(entryId: string): boolean | undefined {
		const status = entriesStatusMap.initial[entryId];

		if (!status) return;

		return status.dueTimeStamp ? isPast(new Date(status.dueTimeStamp)) : false;
	}

	// TODO: use internal react-table select logic
	function isColumnChecked() {
		if (transferOwnershipPreviewModal) {
			return (
				selectedEntries.filter(selectedEntry => selectedEntry.confirmed).length ===
				entries.length
			);
		} else {
			return selectedEntries.length === totalCount && selectedEntries.length > 0;
		}
	}

	// TODO: use internal react-table select logic
	function isColumnPartiallyChecked() {
		if (transferOwnershipPreviewModal) {
			return (
				selectedEntries.filter(selectedEntry => selectedEntry.confirmed).length > 0 &&
				selectedEntries.filter(selectedEntry => selectedEntry.confirmed).length <
					entries.length
			);
		} else {
			return selectedEntries.length > 0 && !isColumnChecked();
		}
	}

	// TODO: use internal react-table select logic
	function isRowSelected(entryId: string) {
		if (transferOwnershipPreviewModal) {
			return selectedEntries.some(
				selectedEntry => selectedEntry.entryId === entryId && selectedEntry.confirmed
			);
		} else {
			return selectedEntries.some(selectedEntry => selectedEntry.entryId === entryId);
		}
	}

	// TODO: use internal react-table select logic
	function handleSelectAllItems() {
		if (transferOwnershipPreviewModal) {
			if (selectedEntries.some(selectedEntry => !selectedEntry.confirmed)) {
				toggleAllSelectedEntriesConfirmed(true);
			} else {
				toggleAllSelectedEntriesConfirmed(false);
			}
		} else {
			if (selectedEntries.length >= 0 && selectedEntries.length < totalCount) {
				const entryIdSet = new Set(selectedEntries.map(entry => entry.entryId));
				const uniqueEntries: SelectedEntry[] = [...selectedEntries];

				for (const row of entries) {
					const entryId = row.datasetentryid;
					if (!entryIdSet.has(entryId)) {
						uniqueEntries.push({ entryId, confirmed: true });
						entryIdSet.add(entryId);
					}
				}

				setSelectedEntries(uniqueEntries);
			} else {
				removeAllSelectedEntries();
			}
		}
	}

	function handleRowClick(entryId: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		// forward / backward mouse buttons
		if ([3, 4].includes(e.button)) return;

		const newTab =
			// CTRL / CMD + click
			e.metaKey ||
			e.ctrlKey ||
			// mouse middle click
			[1].includes(e.button);

		onRowClick(entryId, newTab);
	}

	function isColumnErrored(columnName: string) {
		return entriesErrors && entriesErrors.columns && entriesErrors.columns.includes(columnName);
	}

	function isCellErrored(entryId: string, columnName: string) {
		const entryErrors = entriesErrors && entriesErrors.rows[entryId];

		return entryErrors && entryErrors.includes(columnName);
	}

	function getSortBy(sortedVariable: TableSort) {
		if (sortedVariable) {
			return [
				{
					id: sortedVariable.column,
					desc: sortedVariable.order === SortingType.Desc
				}
			];
		}

		return [];
	}

	//OPEN SERIES TABLE FULL SCREEN
	function handleOpenSeriesTable(entryId: string, setName: string) {
		const isSeriesName = Object.keys(variableSetsMap).some(data => {
			return data === setName;
		});

		if (isSeriesName) {
			handleSeriesClick(entryId);
			dispatch(setUpdateFullScreenSeriesNameTableView({ seriesName: setName }));
		}
	}

	function getSeriesName(cell: Cell<Entry>) {
		if (cell.column.parent && cell.column.parent.id) {
			const seriesName = cell.column.parent.id;

			const isSeriesName = Object.keys(variableSetsMap).some(data => {
				return data === seriesName;
			});

			return isSeriesName ? seriesName : '';
		}

		return '';
	}

	useEffect(() => {
		if (activeSort) {
			setSortBy(getSortBy(activeSort));
		}
	}, [activeSort]);

	// on header click
	function handleColumnSort(e: React.MouseEvent, column: HeaderGroup<Entry>) {
		// @ts-ignore https://github.com/TanStack/table/issues/3453
		// note: originalId seems to be used only on grouped headers (groups + variable, series + aggregationVariables)
		const id: string = column.originalId ?? column.id;
		if (
			id === STATUS_COLUMN.name ||
			id === SELECT_COLUMN.name ||
			id.includes('placeholder') ||
			id in variableSetsMap ||
			id in groupsMap
		)
			return;

		const { canSort } = column;
		if (id === STATUS_COLUMN.name) return;
		if (activeSort) {
			const { column: columnName, order } = activeSort;
			if (columnName === id && order === SortingType.Desc) {
				// if already `lastmodifieddate DESC' => 'lastmodifieddate ASC'
				if (columnName === 'lastmodifieddate') {
					const sort = {
						column: id,
						order: SortingType.Asc
					};
					dispatch(
						setActiveSortAction({
							tableName: TableName.Entries,
							activeSort: sort
						})
					);
					setSortBy(getSortBy(sort));
					// 3rd click => sort by 'lastmodifieddate DESC'
				} else {
					dispatch(
						setActiveSortAction({
							tableName: TableName.Entries,
							activeSort: parseApiSortToActiveSort(DEFAULT_GET_ENTRIES_SORT)
						})
					);
					setSortBy(getSortBy(parseApiSortToActiveSort(DEFAULT_GET_ENTRIES_SORT)));
				}
			} else {
				if (canSort) {
					handleSort(id);
				}
				column.getSortByToggleProps().onClick?.(e);
			}
		}
	}

	return (
		<Container>
			{entriesErrors && (
				<>
					<WarningWithFilters
						message={translate(
							dict => dict.dataset.entries.entriesTable.warningMessage
						)}
						actions={[
							{
								onChange: toggleRowsFilter,
								label: translate(
									dict => dict.dataset.entries.entriesTable.errorRows
								),
								description: translate(
									dict => dict.dataset.entries.entriesTable.displayOnlyRows
								),
								on: draftEntriesErrorsFilter.rows
							},
							{
								onChange: toggleColumnsFilter,
								label: translate(
									dict => dict.dataset.entries.entriesTable.errorColumns
								),
								description: translate(
									dict => dict.dataset.entries.entriesTable.displayOnlyColumns
								),
								on: draftEntriesErrorsFilter.columns
							}
						]}
						onClearFilters={clearFilters}
					/>
					<Spacer size={s => s.s} />
				</>
			)}

			<Spacer size={s => s.s} />

			<Flex
				css={`
					.column-settings {
						margin-left: 1.6rem;
					}
				`}
			>
				{/* ROWS PAGINATION */}
				<Pagination
					totalCount={totalCount}
					totalCountLabel={translate(dict => dict.pagination.entries)}
					filteredCount={entries.length}
					pageIndex={state.pageIndex}
					pageSize={state.pageSize}
					pagesCount={pageOptions.length}
					changePage={handleChangePageIndex}
					changePageSize={handleChangePageSize}
				/>

				<ColumnSettings className="column-settings" />
			</Flex>

			<Spacer size={s => s.xs} />

			<FlexContainer>
				{/* COLUMNS PAGINATION - TO-LEFT CONTROLLER */}
				{shouldPaginateColumns && (
					<ColumnsPaginationControllers>
						<Icon
							svg={Svgs.ChevronLeftDouble}
							variant={v => v.buttonActive}
							size={s => s.m}
							disabled={!canPrevColumnsPage}
							onClick={firstColumnsPage}
						/>
						<Icon
							svg={Svgs.ChevronLeft}
							variant={v => v.buttonActive}
							size={s => s.m}
							disabled={!canPrevColumnsPage}
							onClick={prevColumnsPage}
						/>
					</ColumnsPaginationControllers>
				)}

				<Wrapper _ref={tableResponsiveRef} fullHeight>
					{loading ? (
						<Loader center />
					) : (
						<Table
							{...getTableProps()}
							tableRef={tableRef}
							fullWidth
							stickyHead
							hoverEffect
							dataTestId="entries-table"
						>
							<Table.Head>
								{headerGroups.map((headerGroup, index) => (
									<Table.Row
										{...headerGroup.getHeaderGroupProps()}
										key={headerGroup.getHeaderGroupProps().key}
									>
										{headerGroup.headers.map(column => (
											<Table.Column
												{...column.getHeaderProps([
													column.getSortByToggleProps(),
													{
														...column.customHeaderProps
													}
												])}
												onClick={e => handleColumnSort(e, column)}
												key={column.getHeaderProps().key}
												css={`
													${
														/* Placeholder header for non-group headers */
														column.placeholderOf
															? `
															border-bottom: 1px !important;
															border-bottom-color: transparent !important;
															padding: 0.2rem 0.4rem !important;
														`
															: ''
													}

													${
														/* Header */
														/* @ts-ignore */
														column.accessor && index !== 0
															? `
															border-top: 0 !important;
														`
															: ''
													}

													/* Group header */
													${column.columns
														? `
														padding: 0.2rem 0.4rem !important;
													`
														: ''}
												`}
											>
												{column.render('Header')}
											</Table.Column>
										))}
									</Table.Row>
								))}
							</Table.Head>

							<Table.Body {...getTableBodyProps()}>
								{page.map(row => {
									row.cells;
									prepareRow(row);

									const entryId = row.original.datasetentryid;

									return (
										<Table.Row
											{...row.getRowProps()}
											key={row.getRowProps().key}
											active={isRowSelected(entryId)}
											clickable
											css={`
											.series-view-button {
												visibility: hidden;
											},
											:hover .series-view-button {
											visibility: visible;
										},
											
										`}
										>
											{row.cells.map(cell => (
												<Table.Cell
													{...cell.getCellProps([
														{
															...cell.column.customCellProps
														}
													])}
													key={cell.getCellProps().key}
													noWrap
													style={{ position: 'relative' }}
												>
													{
														<div
															onMouseDown={() => {
																setClickedEntryId(entryId);
															}}
															onMouseUp={e => {
																if (clickedEntryId() !== entryId) {
																	return setClickedEntryId('');
																}
																setClickedEntryId('');
																handleRowClick(entryId, e);
															}}
														>
															{cell.render('Cell')}
														</div>
													}
													{getSeriesName(cell) !== '' && (
														<SeriesView
															className="series-view-button"
															onMouseDown={() => {
																handleOpenSeriesTable(
																	entryId,
																	getSeriesName(cell)
																);
															}}
														>
															View
														</SeriesView>
													)}
												</Table.Cell>
											))}
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					)}
				</Wrapper>

				{/* COLUMNS PAGINATION - TO-RIGHT CONTROLLER */}
				{shouldPaginateColumns && (
					<ColumnsPaginationControllers>
						<Icon
							svg={Svgs.ChevronRight}
							variant={v => v.buttonActive}
							size={s => s.m}
							disabled={!canNextColumnsPage}
							onClick={nextColumnsPage}
						/>
						<Icon
							svg={Svgs.ChevronRightDouble}
							variant={v => v.buttonActive}
							size={s => s.m}
							disabled={!canNextColumnsPage}
							onClick={lastColumnsPage}
						/>
					</ColumnsPaginationControllers>
				)}
			</FlexContainer>
		</Container>
	);
}

import {
	ANTLRErrorListener,
	CharStreams,
	Token,
	CommonTokenStream,
	Recognizer,
	RecognitionException
} from 'antlr4ts';
import { LALVisitor } from './LALVisitor';
import { ErrorNode } from 'antlr4ts/tree/ErrorNode';
import { ParseTree } from 'antlr4ts/tree/ParseTree';
import { RuleNode } from 'antlr4ts/tree/RuleNode';
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';
import { ExprContext, LALParser, ProgContext } from './LALParser';
import { LALLexer } from './LALLexer';

export type Error = {
	precidence: number;
	offendingSymbol: string | undefined;
	row: number;
	column: number;
	text: string;
	type: string;
};

enum ErrorParts {
	EOF_MSG_START = 'extraneous input',
	EOF_MSG_END = 'expecting <eof>',
	MISMATCHED_START = 'mismatched input',
	MISSING = 'missing',
	OPERATORS = "pow, '-', '+', '*', '/'",
	O_PARENTHESIS = "'('",
	C_PARENTHESIS = "')'",
	OPERANDS = "'-', 'sqrt', 'log', 'ln', pi, 'e', int, float, id",
	LOG = 'log',
	SQRT = 'sqrt',
	LN = 'ln',
	PI = 'pi',
	E = 'e',
	EOF = '<eof>'
}

class SyntaxErrorListener implements ANTLRErrorListener<any> {
	errors: Error[];
	constructor() {
		this.errors = [];
	}
	syntaxError(
		recognizer: Recognizer<any, any>,
		offendingSymbol: Token | number | undefined,
		line: number,
		column: any,
		msg: string,
		_e: RecognitionException | undefined
	) {
		const previousToken = this.getPreviousToken(recognizer);

		const err = buildSyntaxError(msg, line, column, offendingSymbol, previousToken);
		this.errors.push(err);
	}

	getPreviousToken(recognizer: Recognizer<any, any>) {
		const _stream = recognizer.inputStream as CommonTokenStream;

		if (!_stream) {
			return undefined;
		}
		const currIdx = _stream.index;
		const tkn = _stream.get ? _stream.get(currIdx - 1) : undefined;

		return tkn;
	}
}

class JsonLogicVisitor implements LALVisitor<any> {
	variableNames: VariableItem[];
	semanticErrors: Error[];
	syntaxErrors: Error[];
	constructor(variableNames: VariableItem[]) {
		this.variableNames = variableNames;
		this.semanticErrors = [];
		this.syntaxErrors = [];
	}

	visit(node: ParseTree, syntaxErros?: Error[]): any {
		if (syntaxErros && syntaxErros.length > 0) {
			this.syntaxErrors = syntaxErros;
		}
		return node.accept(this);
	}

	visitChildren(node: RuleNode): any {
		for (let i = 0; i < node.childCount; i++) {
			if (!node.getChild(i).accept(this)) {
				return false;
			}
		}
	}

	visitTerminal(_node: TerminalNode): any {
		// Implement logic for terminal nodes if necessary
	}

	visitErrorNode(node: ErrorNode, msg?: string): any {
		this.semanticErrors.push({
			offendingSymbol: node.text,
			precidence: 1,
			row: node.symbol.line - 1,
			column: node.symbol.charPositionInLine,
			text: msg ?? 'Invalid token',
			type: 'senamtic-error'
		});
	}

	visitProg(ctx: ProgContext) {
		if (!ctx) {
			return {};
		}
		return this.visitExpr(ctx.expr());
	}

	visitExpr(ctx: ExprContext): any {
		if (!ctx) {
			return {};
		}
		if (ctx.INT()) {
			return parseInt(ctx.INT()!.text);
		}
		if (ctx.FLOAT()) {
			return parseFloat(ctx.FLOAT()!.text);
		}
		if (ctx.ID()) {
			const variable = this.variableNames.find(variable => variable.key === ctx.ID()!.text);

			if (variable) {
				return { var: [variable.variableId, variable.type] };
			}

			return this.visitErrorNode(ctx.ID()!, `Variable not found for alias ${ctx.ID()!.text}`);
		}
		if (ctx.PI()) {
			return Math.PI;
		}
		if (ctx.E()) {
			return Math.E;
		}
		if (ctx.expr()) {
			if (ctx.expr().length === 1) {
				if (ctx.MINUS()) {
					return { '-': [this.visitExpr(ctx.expr()[0])] };
				}
				if (ctx.SQRT()) {
					return { sqrt: [this.visitExpr(ctx.expr()[0])] };
				}
				if (ctx.LOG()) {
					return { log: [this.visitExpr(ctx.expr()[0])] };
				}
				if (ctx.LN()) {
					return { ln: [this.visitExpr(ctx.expr()[0])] };
				}
				return this.visitExpr(ctx.expr()[0]);
			}
			const exprNodes = ctx.expr();
			const [left, right] = exprNodes;
			const leftNode = this.visitExpr(left);
			const rightNode = this.visitExpr(right);

			if (ctx.MULT()) {
				return { '*': [leftNode, rightNode] };
			}
			if (ctx.DIV()) {
				return { '/': [leftNode, rightNode] };
			}
			if (ctx.PLUS()) {
				return { '+': [leftNode, rightNode] };
			}
			if (ctx.MINUS()) {
				return { '-': [leftNode, rightNode] };
			}
			if (ctx.POW()) {
				return { '**': [leftNode, rightNode] };
			}
		}

		return null;
	}

	getErrors() {
		return [...this.semanticErrors, ...this.syntaxErrors];
	}
}

type VariableItem = {
	variableId: string;
	key: string;
	type: string;
};

export function parseToJsonLogic(input: string, variableNames: VariableItem[]) {
	const syntaxErrorListener = new SyntaxErrorListener();

	const stream = CharStreams.fromString(input);

	const lexer = new LALLexer(stream);
	lexer.removeErrorListeners();
	lexer.addErrorListener(syntaxErrorListener);

	const tokens = new CommonTokenStream(lexer);

	const parser = new LALParser(tokens);
	parser.removeErrorListeners();
	parser.addErrorListener(syntaxErrorListener);

	const visitor = new JsonLogicVisitor(variableNames);

	const tree = parser.prog();

	const jsonLogicRule = visitor.visit(tree, syntaxErrorListener.errors);

	const errors = visitor.getErrors();

	return {
		jsonLogicRule,
		errors
	};
}

function buildSyntaxError(
	msg: string,
	line: number,
	column: number,
	offendingSymbol?: Token | number,
	previousToken?: Token
) {
	let errMsg = msg.toLowerCase();

	if (offendingSymbol) {
		if (
			errMsg.startsWith(ErrorParts.EOF_MSG_START) &&
			errMsg.endsWith(ErrorParts.EOF_MSG_END)
		) {
			errMsg = `Unexpected end of formula`;
		}

		if (errMsg.startsWith(ErrorParts.MISMATCHED_START)) {
			errMsg = previousToken
				? `Unexpected value after "${previousToken.text}"`
				: `Unexpected value`;

			if (typeof offendingSymbol === 'number') {
				const typeofToken = LALLexer.VOCABULARY.getSymbolicName(offendingSymbol);
				if (typeofToken) {
					errMsg += `: ${typeofToken}`;
				}
			} else {
				if (offendingSymbol.text === ErrorParts.EOF) {
					errMsg += `: end of formula. Expecting` + generateErrorMessageInfo(msg);
				} else {
					errMsg +=
						`: "${offendingSymbol.text}" and expecting` + generateErrorMessageInfo(msg);
				}
			}
		}

		if (errMsg.startsWith(ErrorParts.MISSING)) {
			const msgParts = errMsg.split('at');

			errMsg =
				`Missing value` +
				generateErrorMessageInfo(msg) +
				(msgParts[1] && msgParts[1] === ErrorParts.EOF
					? ' at end of formula'
					: [
							ErrorParts.E,
							ErrorParts.LN,
							ErrorParts.SQRT,
							ErrorParts.LOG,
							ErrorParts.PI
					  ].includes(msgParts[1] as ErrorParts)
					? ` at ${msgParts[1]}`
					: '');
		}
	}

	return {
		precidence: 0,
		row: line - 1,
		offendingSymbol: offendingSymbol
			? typeof offendingSymbol !== 'number'
				? offendingSymbol.text ?? ''
				: offendingSymbol.toString()
			: '',
		column: column,
		text: errMsg,
		type: 'syntax-error'
	};
}

function generateErrorMessageInfo(msg: string) {
	const shouldAddOpenParen = msg.includes(ErrorParts.O_PARENTHESIS);
	const shouldAddCloseParen = msg.includes(ErrorParts.C_PARENTHESIS);
	const shouldAddOperators = msg.includes(ErrorParts.OPERATORS);
	const shouldAddOperand = msg.includes(ErrorParts.OPERANDS);

	const shouldAddHelpInfo =
		shouldAddOpenParen || shouldAddCloseParen || shouldAddOperators || shouldAddOperand;

	let infoMsg = '';

	if (shouldAddHelpInfo) {
		infoMsg += ' (';
	}

	if (shouldAddOpenParen) {
		infoMsg += ' open parenthesis';
	}

	if (shouldAddCloseParen) {
		infoMsg += shouldAddOpenParen ? ', close parenthesis' : ' close parenthesis';
	}

	if (shouldAddOperators) {
		infoMsg += shouldAddOpenParen || shouldAddCloseParen ? ', operators' : ' operators';
	}

	if (shouldAddOperand) {
		infoMsg +=
			shouldAddOpenParen || shouldAddCloseParen ? ', numbers, aliases' : ' numbers, aliases';
	}

	if (shouldAddHelpInfo) {
		infoMsg += ' )';
	}

	return infoMsg;
}

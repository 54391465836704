import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { Nullable } from 'types/index';
import {
	ActionTypes,
	getProjectNotificationSettings,
	ProjectNotificationSettings,
	selectProjectNotificationSettings
} from 'store/data/notifications';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useProjectNotificationSettings(
	projectId: string
): OperationResult<Nullable<ProjectNotificationSettings>> {
	const dispatch = useDispatch();

	const data = useSelector(state =>
		selectProjectNotificationSettings(state.data.notifications, projectId)
	);

	const [{ loading, error }, reset] = useActivity(
		ActionTypes.GET_PROJECT_NOTIFICATION_SETTINGS,
		projectId
	);

	useEffect(() => {
		if (!data && !error && !loading && projectId) handler();
	}, [loading, error, data, projectId]);

	function handler() {
		reset();
		if (projectId) dispatch(getProjectNotificationSettings(projectId));
	}

	return [{ loading, error, data }, handler];
}

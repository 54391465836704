import { Flex } from 'components/UI/Flex';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { Typography } from 'components/UI/Typography';
import { useEnterpriseRoles, useSubscription, useTranslation } from 'hooks/store';
import { UserRole } from 'store/account/subscription';

interface Props {
	selected: UserRole;
	onSelect: (selected: UserRole | string) => void;
	isOwner?: boolean;
}

export function UserRoleSelector({ selected, onSelect, isOwner }: Props) {
	const [
		{
			data: {
				userTypesAndRoles: { ledidiEnterprise }
			}
		}
	] = useSubscription();

	const [{ data: roles }] = useEnterpriseRoles();

	const { translate } = useTranslation();

	return (
		<Flex column>
			{!isOwner && (
				<Typography.Paragraph fontweight={b => b.bold} paddingOffset={{ bottom: 1.7 }}>
					{translate(dict => dict.accountUM.userDetails.chooseRole)}
				</Typography.Paragraph>
			)}
			{isOwner ? (
				<RadioButton
					label={translate(dict => dict.accountUM.userRoles.superAdmin)}
					selected={selected === UserRole.OrganizationAdmin}
					onSelect={() => onSelect(UserRole.OrganizationAdmin)}
					disabled
				/>
			) : (
				<>
					{ledidiEnterprise.isOrganizationAdmin && (
						<>
							<RadioButton
								label={translate(dict => dict.accountUM.userRoles.superAdmin)}
								selected={selected === UserRole.SuperAdmin}
								onSelect={() => onSelect(UserRole.SuperAdmin)}
							/>
							<Typography.Caption paddingOffset={{ bottom: 0.8, left: 2.4 }}>
								{translate(dict => dict.accountUM.userRoles.superAdminDescription)}
							</Typography.Caption>
						</>
					)}
					{(ledidiEnterprise.isOrganizationAdmin || ledidiEnterprise.isSuperAdmin) && (
						<>
							<RadioButton
								label={translate(dict => dict.accountUM.userRoles.admin)}
								selected={selected === UserRole.Admin}
								onSelect={() => onSelect(UserRole.Admin)}
							/>
							<Typography.Caption paddingOffset={{ bottom: 0.8, left: 2.4 }}>
								{translate(dict => dict.accountUM.userRoles.adminDescription)}
							</Typography.Caption>
						</>
					)}
					{(ledidiEnterprise.isOrganizationAdmin ||
						ledidiEnterprise.isSuperAdmin ||
						ledidiEnterprise.isAdmin) && (
						<>
							<RadioButton
								label={translate(dict => dict.accountUM.userRoles.user)}
								selected={selected === UserRole.User}
								onSelect={() => onSelect(UserRole.User)}
							/>
							<Typography.Caption paddingOffset={{ bottom: 0.8, left: 2.4 }}>
								{translate(dict => dict.accountUM.userRoles.userDescription)}
							</Typography.Caption>
						</>
					)}

					{/* User defined Roles  */}
					{Object.values(roles).map(role => (
						<Flex key={role.roleName} column>
							<RadioButton
								label={role.roleName}
								selected={selected === role.roleName}
								onSelect={() => onSelect(role.roleName)}
							/>
							<Typography.Caption paddingOffset={{ bottom: 0.8, left: 2.4 }}>
								{role.roleDescription}
							</Typography.Caption>
						</Flex>
					))}
				</>
			)}
		</Flex>
	);
}

// https://gist.github.com/Goles/3196253#gistcomment-3104816

export const COUNTRIES = [
	{
		name: 'Bangladesh',
		ISO_2: 'BD',
		ISO_3: 'BGD',
		unicode: '🇧🇩',
		dial: '880',
		currency: 'BDT',
		capital: 'Dhaka',
		continent: 'AS'
	},
	{
		name: 'Belgium',
		ISO_2: 'BE',
		ISO_3: 'BEL',
		unicode: '🇧🇪',
		dial: '32',
		currency: 'EUR',
		capital: 'Brussels',
		continent: 'EU'
	},
	{
		name: 'Burkina Faso',
		ISO_2: 'BF',
		ISO_3: 'BFA',
		unicode: '🇧🇫',
		dial: '226',
		currency: 'XOF',
		capital: 'Ouagadougou',
		continent: 'AF'
	},
	{
		name: 'Bulgaria',
		ISO_2: 'BG',
		ISO_3: 'BGR',
		unicode: '🇧🇬',
		dial: '359',
		currency: 'BGN',
		capital: 'Sofia',
		continent: 'EU'
	},
	{
		name: 'Bosnia and Herzegovina',
		ISO_2: 'BA',
		ISO_3: 'BIH',
		unicode: '🇧🇦',
		dial: '387',
		currency: 'BAM',
		capital: 'Sarajevo',
		continent: 'EU'
	},
	{
		name: 'Barbados',
		ISO_2: 'BB',
		ISO_3: 'BRB',
		unicode: '🇧🇧',
		dial: '1-246',
		currency: 'BBD',
		capital: 'Bridgetown',
		continent: 'NA'
	},
	{
		name: 'Wallis and Futuna',
		ISO_2: 'WF',
		ISO_3: 'WLF',
		unicode: '🇼🇫',
		dial: '681',
		currency: 'XPF',
		capital: 'Mata Utu',
		continent: 'OC'
	},
	{
		name: 'Saint Barthelemy',
		ISO_2: 'BL',
		ISO_3: 'BLM',
		unicode: '🇧🇱',
		dial: '590',
		currency: 'EUR',
		capital: 'Gustavia',
		continent: 'NA'
	},
	{
		name: 'Bermuda',
		ISO_2: 'BM',
		ISO_3: 'BMU',
		unicode: '🇧🇲',
		dial: '1-441',
		currency: 'BMD',
		capital: 'Hamilton',
		continent: 'NA'
	},
	{
		name: 'Brunei',
		ISO_2: 'BN',
		ISO_3: 'BRN',
		unicode: '🇧🇳',
		dial: '673',
		currency: 'BND',
		capital: 'Bandar Seri Begawan',
		continent: 'AS'
	},
	{
		name: 'Bolivia',
		ISO_2: 'BO',
		ISO_3: 'BOL',
		unicode: '🇧🇴',
		dial: '591',
		currency: 'BOB',
		capital: 'Sucre',
		continent: 'SA'
	},
	{
		name: 'Bahrain',
		ISO_2: 'BH',
		ISO_3: 'BHR',
		unicode: '🇧🇭',
		dial: '973',
		currency: 'BHD',
		capital: 'Manama',
		continent: 'AS'
	},
	{
		name: 'Burundi',
		ISO_2: 'BI',
		ISO_3: 'BDI',
		unicode: '🇧🇮',
		dial: '257',
		currency: 'BIF',
		capital: 'Bujumbura',
		continent: 'AF'
	},
	{
		name: 'Benin',
		ISO_2: 'BJ',
		ISO_3: 'BEN',
		unicode: '🇧🇯',
		dial: '229',
		currency: 'XOF',
		capital: 'Porto-Novo',
		continent: 'AF'
	},
	{
		name: 'Bhutan',
		ISO_2: 'BT',
		ISO_3: 'BTN',
		unicode: '🇧🇹',
		dial: '975',
		currency: 'BTN',
		capital: 'Thimphu',
		continent: 'AS'
	},
	{
		name: 'Jamaica',
		ISO_2: 'JM',
		ISO_3: 'JAM',
		unicode: '🇯🇲',
		dial: '1-876',
		currency: 'JMD',
		capital: 'Kingston',
		continent: 'NA'
	},
	{
		name: 'Bouvet Island',
		ISO_2: 'BV',
		ISO_3: 'BVT',
		unicode: '🇧🇻',
		dial: '',
		currency: 'NOK',
		capital: '',
		continent: 'AN'
	},
	{
		name: 'Botswana',
		ISO_2: 'BW',
		ISO_3: 'BWA',
		unicode: '🇧🇼',
		dial: '267',
		currency: 'BWP',
		capital: 'Gaborone',
		continent: 'AF'
	},
	{
		name: 'Samoa',
		ISO_2: 'WS',
		ISO_3: 'WSM',
		unicode: '🇼🇸',
		dial: '685',
		currency: 'WST',
		capital: 'Apia',
		continent: 'OC'
	},
	{
		name: 'Bonaire, Saint Eustatius and Saba ',
		ISO_2: 'BQ',
		ISO_3: 'BES',
		unicode: '🇧🇶',
		dial: '599',
		currency: 'USD',
		capital: '',
		continent: 'NA'
	},
	{
		name: 'Brazil',
		ISO_2: 'BR',
		ISO_3: 'BRA',
		unicode: '🇧🇷',
		dial: '55',
		currency: 'BRL',
		capital: 'Brasilia',
		continent: 'SA'
	},
	{
		name: 'Bahamas',
		ISO_2: 'BS',
		ISO_3: 'BHS',
		unicode: '🇧🇸',
		dial: '1-242',
		currency: 'BSD',
		capital: 'Nassau',
		continent: 'NA'
	},
	{
		name: 'Jersey',
		ISO_2: 'JE',
		ISO_3: 'JEY',
		unicode: '🇯🇪',
		dial: '44-1534',
		currency: 'GBP',
		capital: 'Saint Helier',
		continent: 'EU'
	},
	{
		name: 'Belarus',
		ISO_2: 'BY',
		ISO_3: 'BLR',
		unicode: '🇧🇾',
		dial: '375',
		currency: 'BYR',
		capital: 'Minsk',
		continent: 'EU'
	},
	{
		name: 'Belize',
		ISO_2: 'BZ',
		ISO_3: 'BLZ',
		unicode: '🇧🇿',
		dial: '501',
		currency: 'BZD',
		capital: 'Belmopan',
		continent: 'NA'
	},
	{
		name: 'Russia',
		ISO_2: 'RU',
		ISO_3: 'RUS',
		unicode: '🇷🇺',
		dial: '7',
		currency: 'RUB',
		capital: 'Moscow',
		continent: 'EU'
	},
	{
		name: 'Rwanda',
		ISO_2: 'RW',
		ISO_3: 'RWA',
		unicode: '🇷🇼',
		dial: '250',
		currency: 'RWF',
		capital: 'Kigali',
		continent: 'AF'
	},
	{
		name: 'Serbia',
		ISO_2: 'RS',
		ISO_3: 'SRB',
		unicode: '🇷🇸',
		dial: '381',
		currency: 'RSD',
		capital: 'Belgrade',
		continent: 'EU'
	},
	{
		name: 'East Timor',
		ISO_2: 'TL',
		ISO_3: 'TLS',
		unicode: '🇹🇱',
		dial: '670',
		currency: 'USD',
		capital: 'Dili',
		continent: 'OC'
	},
	{
		name: 'Reunion',
		ISO_2: 'RE',
		ISO_3: 'REU',
		unicode: '🇷🇪',
		dial: '262',
		currency: 'EUR',
		capital: 'Saint-Denis',
		continent: 'AF'
	},
	{
		name: 'Turkmenistan',
		ISO_2: 'TM',
		ISO_3: 'TKM',
		unicode: '🇹🇲',
		dial: '993',
		currency: 'TMT',
		capital: 'Ashgabat',
		continent: 'AS'
	},
	{
		name: 'Tajikistan',
		ISO_2: 'TJ',
		ISO_3: 'TJK',
		unicode: '🇹🇯',
		dial: '992',
		currency: 'TJS',
		capital: 'Dushanbe',
		continent: 'AS'
	},
	{
		name: 'Romania',
		ISO_2: 'RO',
		ISO_3: 'ROU',
		unicode: '🇷🇴',
		dial: '40',
		currency: 'RON',
		capital: 'Bucharest',
		continent: 'EU'
	},
	{
		name: 'Tokelau',
		ISO_2: 'TK',
		ISO_3: 'TKL',
		unicode: '🇹🇰',
		dial: '690',
		currency: 'NZD',
		capital: '',
		continent: 'OC'
	},
	{
		name: 'Guinea-Bissau',
		ISO_2: 'GW',
		ISO_3: 'GNB',
		unicode: '🇬🇼',
		dial: '245',
		currency: 'XOF',
		capital: 'Bissau',
		continent: 'AF'
	},
	{
		name: 'Guam',
		ISO_2: 'GU',
		ISO_3: 'GUM',
		unicode: '🇬🇺',
		dial: '1-671',
		currency: 'USD',
		capital: 'Hagatna',
		continent: 'OC'
	},
	{
		name: 'Guatemala',
		ISO_2: 'GT',
		ISO_3: 'GTM',
		unicode: '🇬🇹',
		dial: '502',
		currency: 'GTQ',
		capital: 'Guatemala City',
		continent: 'NA'
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		ISO_2: 'GS',
		ISO_3: 'SGS',
		unicode: '🇬🇸',
		dial: '',
		currency: 'GBP',
		capital: 'Grytviken',
		continent: 'AN'
	},
	{
		name: 'Greece',
		ISO_2: 'GR',
		ISO_3: 'GRC',
		unicode: '🇬🇷',
		dial: '30',
		currency: 'EUR',
		capital: 'Athens',
		continent: 'EU'
	},
	{
		name: 'Equatorial Guinea',
		ISO_2: 'GQ',
		ISO_3: 'GNQ',
		unicode: '🇬🇶',
		dial: '240',
		currency: 'XAF',
		capital: 'Malabo',
		continent: 'AF'
	},
	{
		name: 'Guadeloupe',
		ISO_2: 'GP',
		ISO_3: 'GLP',
		unicode: '🇬🇵',
		dial: '590',
		currency: 'EUR',
		capital: 'Basse-Terre',
		continent: 'NA'
	},
	{
		name: 'Japan',
		ISO_2: 'JP',
		ISO_3: 'JPN',
		unicode: '🇯🇵',
		dial: '81',
		currency: 'JPY',
		capital: 'Tokyo',
		continent: 'AS'
	},
	{
		name: 'Guyana',
		ISO_2: 'GY',
		ISO_3: 'GUY',
		unicode: '🇬🇾',
		dial: '592',
		currency: 'GYD',
		capital: 'Georgetown',
		continent: 'SA'
	},
	{
		name: 'Guernsey',
		ISO_2: 'GG',
		ISO_3: 'GGY',
		unicode: '🇬🇬',
		dial: '44-1481',
		currency: 'GBP',
		capital: 'St Peter Port',
		continent: 'EU'
	},
	{
		name: 'French Guiana',
		ISO_2: 'GF',
		ISO_3: 'GUF',
		unicode: '🇬🇫',
		dial: '594',
		currency: 'EUR',
		capital: 'Cayenne',
		continent: 'SA'
	},
	{
		name: 'Georgia',
		ISO_2: 'GE',
		ISO_3: 'GEO',
		unicode: '🇬🇪',
		dial: '995',
		currency: 'GEL',
		capital: 'Tbilisi',
		continent: 'AS'
	},
	{
		name: 'Grenada',
		ISO_2: 'GD',
		ISO_3: 'GRD',
		unicode: '🇬🇩',
		dial: '1-473',
		currency: 'XCD',
		capital: "St. George's",
		continent: 'NA'
	},
	{
		name: 'United Kingdom',
		ISO_2: 'GB',
		ISO_3: 'GBR',
		unicode: '🇬🇧',
		dial: '44',
		currency: 'GBP',
		capital: 'London',
		continent: 'EU'
	},
	{
		name: 'Gabon',
		ISO_2: 'GA',
		ISO_3: 'GAB',
		unicode: '🇬🇦',
		dial: '241',
		currency: 'XAF',
		capital: 'Libreville',
		continent: 'AF'
	},
	{
		name: 'El Salvador',
		ISO_2: 'SV',
		ISO_3: 'SLV',
		unicode: '🇸🇻',
		dial: '503',
		currency: 'USD',
		capital: 'San Salvador',
		continent: 'NA'
	},
	{
		name: 'Guinea',
		ISO_2: 'GN',
		ISO_3: 'GIN',
		unicode: '🇬🇳',
		dial: '224',
		currency: 'GNF',
		capital: 'Conakry',
		continent: 'AF'
	},
	{
		name: 'Gambia',
		ISO_2: 'GM',
		ISO_3: 'GMB',
		unicode: '🇬🇲',
		dial: '220',
		currency: 'GMD',
		capital: 'Banjul',
		continent: 'AF'
	},
	{
		name: 'Greenland',
		ISO_2: 'GL',
		ISO_3: 'GRL',
		unicode: '🇬🇱',
		dial: '299',
		currency: 'DKK',
		capital: 'Nuuk',
		continent: 'NA'
	},
	{
		name: 'Gibraltar',
		ISO_2: 'GI',
		ISO_3: 'GIB',
		unicode: '🇬🇮',
		dial: '350',
		currency: 'GIP',
		capital: 'Gibraltar',
		continent: 'EU'
	},
	{
		name: 'Ghana',
		ISO_2: 'GH',
		ISO_3: 'GHA',
		unicode: '🇬🇭',
		dial: '233',
		currency: 'GHS',
		capital: 'Accra',
		continent: 'AF'
	},
	{
		name: 'Oman',
		ISO_2: 'OM',
		ISO_3: 'OMN',
		unicode: '🇴🇲',
		dial: '968',
		currency: 'OMR',
		capital: 'Muscat',
		continent: 'AS'
	},
	{
		name: 'Tunisia',
		ISO_2: 'TN',
		ISO_3: 'TUN',
		unicode: '🇹🇳',
		dial: '216',
		currency: 'TND',
		capital: 'Tunis',
		continent: 'AF'
	},
	{
		name: 'Jordan',
		ISO_2: 'JO',
		ISO_3: 'JOR',
		unicode: '🇯🇴',
		dial: '962',
		currency: 'JOD',
		capital: 'Amman',
		continent: 'AS'
	},
	{
		name: 'Croatia',
		ISO_2: 'HR',
		ISO_3: 'HRV',
		unicode: '🇭🇷',
		dial: '385',
		currency: 'HRK',
		capital: 'Zagreb',
		continent: 'EU'
	},
	{
		name: 'Haiti',
		ISO_2: 'HT',
		ISO_3: 'HTI',
		unicode: '🇭🇹',
		dial: '509',
		currency: 'HTG',
		capital: 'Port-au-Prince',
		continent: 'NA'
	},
	{
		name: 'Hungary',
		ISO_2: 'HU',
		ISO_3: 'HUN',
		unicode: '🇭🇺',
		dial: '36',
		currency: 'HUF',
		capital: 'Budapest',
		continent: 'EU'
	},
	{
		name: 'Hong Kong',
		ISO_2: 'HK',
		ISO_3: 'HKG',
		unicode: '🇭🇰',
		dial: '852',
		currency: 'HKD',
		capital: 'Hong Kong',
		continent: 'AS'
	},
	{
		name: 'Honduras',
		ISO_2: 'HN',
		ISO_3: 'HND',
		unicode: '🇭🇳',
		dial: '504',
		currency: 'HNL',
		capital: 'Tegucigalpa',
		continent: 'NA'
	},
	{
		name: 'Heard Island and McDonald Islands',
		ISO_2: 'HM',
		ISO_3: 'HMD',
		unicode: '🇭🇲',
		dial: ' ',
		currency: 'AUD',
		capital: '',
		continent: 'AN'
	},
	{
		name: 'Venezuela',
		ISO_2: 'VE',
		ISO_3: 'VEN',
		unicode: '🇻🇪',
		dial: '58',
		currency: 'VEF',
		capital: 'Caracas',
		continent: 'SA'
	},
	{
		name: 'Puerto Rico',
		ISO_2: 'PR',
		ISO_3: 'PRI',
		unicode: '🇵🇷',
		dial: '1-787 and 1-939',
		currency: 'USD',
		capital: 'San Juan',
		continent: 'NA'
	},
	{
		name: 'Palestinian Territory',
		ISO_2: 'PS',
		ISO_3: 'PSE',
		unicode: '🇵🇸',
		dial: '970',
		currency: 'ILS',
		capital: 'East Jerusalem',
		continent: 'AS'
	},
	{
		name: 'Palau',
		ISO_2: 'PW',
		ISO_3: 'PLW',
		unicode: '🇵🇼',
		dial: '680',
		currency: 'USD',
		capital: 'Melekeok',
		continent: 'OC'
	},
	{
		name: 'Portugal',
		ISO_2: 'PT',
		ISO_3: 'PRT',
		unicode: '🇵🇹',
		dial: '351',
		currency: 'EUR',
		capital: 'Lisbon',
		continent: 'EU'
	},
	{
		name: 'Svalbard and Jan Mayen',
		ISO_2: 'SJ',
		ISO_3: 'SJM',
		unicode: '🇸🇯',
		dial: '47',
		currency: 'NOK',
		capital: 'Longyearbyen',
		continent: 'EU'
	},
	{
		name: 'Paraguay',
		ISO_2: 'PY',
		ISO_3: 'PRY',
		unicode: '🇵🇾',
		dial: '595',
		currency: 'PYG',
		capital: 'Asuncion',
		continent: 'SA'
	},
	{
		name: 'Iraq',
		ISO_2: 'IQ',
		ISO_3: 'IRQ',
		unicode: '🇮🇶',
		dial: '964',
		currency: 'IQD',
		capital: 'Baghdad',
		continent: 'AS'
	},
	{
		name: 'Panama',
		ISO_2: 'PA',
		ISO_3: 'PAN',
		unicode: '🇵🇦',
		dial: '507',
		currency: 'PAB',
		capital: 'Panama City',
		continent: 'NA'
	},
	{
		name: 'French Polynesia',
		ISO_2: 'PF',
		ISO_3: 'PYF',
		unicode: '🇵🇫',
		dial: '689',
		currency: 'XPF',
		capital: 'Papeete',
		continent: 'OC'
	},
	{
		name: 'Papua New Guinea',
		ISO_2: 'PG',
		ISO_3: 'PNG',
		unicode: '🇵🇬',
		dial: '675',
		currency: 'PGK',
		capital: 'Port Moresby',
		continent: 'OC'
	},
	{
		name: 'Peru',
		ISO_2: 'PE',
		ISO_3: 'PER',
		unicode: '🇵🇪',
		dial: '51',
		currency: 'PEN',
		capital: 'Lima',
		continent: 'SA'
	},
	{
		name: 'Pakistan',
		ISO_2: 'PK',
		ISO_3: 'PAK',
		unicode: '🇵🇰',
		dial: '92',
		currency: 'PKR',
		capital: 'Islamabad',
		continent: 'AS'
	},
	{
		name: 'Philippines',
		ISO_2: 'PH',
		ISO_3: 'PHL',
		unicode: '🇵🇭',
		dial: '63',
		currency: 'PHP',
		capital: 'Manila',
		continent: 'AS'
	},
	{
		name: 'Pitcairn',
		ISO_2: 'PN',
		ISO_3: 'PCN',
		unicode: '🇵🇳',
		dial: '870',
		currency: 'NZD',
		capital: 'Adamstown',
		continent: 'OC'
	},
	{
		name: 'Poland',
		ISO_2: 'PL',
		ISO_3: 'POL',
		unicode: '🇵🇱',
		dial: '48',
		currency: 'PLN',
		capital: 'Warsaw',
		continent: 'EU'
	},
	{
		name: 'Saint Pierre and Miquelon',
		ISO_2: 'PM',
		ISO_3: 'SPM',
		unicode: '🇵🇲',
		dial: '508',
		currency: 'EUR',
		capital: 'Saint-Pierre',
		continent: 'NA'
	},
	{
		name: 'Zambia',
		ISO_2: 'ZM',
		ISO_3: 'ZMB',
		unicode: '🇿🇲',
		dial: '260',
		currency: 'ZMK',
		capital: 'Lusaka',
		continent: 'AF'
	},
	{
		name: 'Western Sahara',
		ISO_2: 'EH',
		ISO_3: 'ESH',
		unicode: '🇪🇭',
		dial: '212',
		currency: 'MAD',
		capital: 'El-Aaiun',
		continent: 'AF'
	},
	{
		name: 'Estonia',
		ISO_2: 'EE',
		ISO_3: 'EST',
		unicode: '🇪🇪',
		dial: '372',
		currency: 'EUR',
		capital: 'Tallinn',
		continent: 'EU'
	},
	{
		name: 'Egypt',
		ISO_2: 'EG',
		ISO_3: 'EGY',
		unicode: '🇪🇬',
		dial: '20',
		currency: 'EGP',
		capital: 'Cairo',
		continent: 'AF'
	},
	{
		name: 'South Africa',
		ISO_2: 'ZA',
		ISO_3: 'ZAF',
		unicode: '🇿🇦',
		dial: '27',
		currency: 'ZAR',
		capital: 'Pretoria',
		continent: 'AF'
	},
	{
		name: 'Ecuador',
		ISO_2: 'EC',
		ISO_3: 'ECU',
		unicode: '🇪🇨',
		dial: '593',
		currency: 'USD',
		capital: 'Quito',
		continent: 'SA'
	},
	{
		name: 'Italy',
		ISO_2: 'IT',
		ISO_3: 'ITA',
		unicode: '🇮🇹',
		dial: '39',
		currency: 'EUR',
		capital: 'Rome',
		continent: 'EU'
	},
	{
		name: 'Vietnam',
		ISO_2: 'VN',
		ISO_3: 'VNM',
		unicode: '🇻🇳',
		dial: '84',
		currency: 'VND',
		capital: 'Hanoi',
		continent: 'AS'
	},
	{
		name: 'Solomon Islands',
		ISO_2: 'SB',
		ISO_3: 'SLB',
		unicode: '🇸🇧',
		dial: '677',
		currency: 'SBD',
		capital: 'Honiara',
		continent: 'OC'
	},
	{
		name: 'Ethiopia',
		ISO_2: 'ET',
		ISO_3: 'ETH',
		unicode: '🇪🇹',
		dial: '251',
		currency: 'ETB',
		capital: 'Addis Ababa',
		continent: 'AF'
	},
	{
		name: 'Somalia',
		ISO_2: 'SO',
		ISO_3: 'SOM',
		unicode: '🇸🇴',
		dial: '252',
		currency: 'SOS',
		capital: 'Mogadishu',
		continent: 'AF'
	},
	{
		name: 'Zimbabwe',
		ISO_2: 'ZW',
		ISO_3: 'ZWE',
		unicode: '🇿🇼',
		dial: '263',
		currency: 'ZWL',
		capital: 'Harare',
		continent: 'AF'
	},
	{
		name: 'Saudi Arabia',
		ISO_2: 'SA',
		ISO_3: 'SAU',
		unicode: '🇸🇦',
		dial: '966',
		currency: 'SAR',
		capital: 'Riyadh',
		continent: 'AS'
	},
	{
		name: 'Spain',
		ISO_2: 'ES',
		ISO_3: 'ESP',
		unicode: '🇪🇸',
		dial: '34',
		currency: 'EUR',
		capital: 'Madrid',
		continent: 'EU'
	},
	{
		name: 'Eritrea',
		ISO_2: 'ER',
		ISO_3: 'ERI',
		unicode: '🇪🇷',
		dial: '291',
		currency: 'ERN',
		capital: 'Asmara',
		continent: 'AF'
	},
	{
		name: 'Montenegro',
		ISO_2: 'ME',
		ISO_3: 'MNE',
		unicode: '🇲🇪',
		dial: '382',
		currency: 'EUR',
		capital: 'Podgorica',
		continent: 'EU'
	},
	{
		name: 'Moldova',
		ISO_2: 'MD',
		ISO_3: 'MDA',
		unicode: '🇲🇩',
		dial: '373',
		currency: 'MDL',
		capital: 'Chisinau',
		continent: 'EU'
	},
	{
		name: 'Madagascar',
		ISO_2: 'MG',
		ISO_3: 'MDG',
		unicode: '🇲🇬',
		dial: '261',
		currency: 'MGA',
		capital: 'Antananarivo',
		continent: 'AF'
	},
	{
		name: 'Saint Martin',
		ISO_2: 'MF',
		ISO_3: 'MAF',
		unicode: '🇲🇫',
		dial: '590',
		currency: 'EUR',
		capital: 'Marigot',
		continent: 'NA'
	},
	{
		name: 'Morocco',
		ISO_2: 'MA',
		ISO_3: 'MAR',
		unicode: '🇲🇦',
		dial: '212',
		currency: 'MAD',
		capital: 'Rabat',
		continent: 'AF'
	},
	{
		name: 'Monaco',
		ISO_2: 'MC',
		ISO_3: 'MCO',
		unicode: '🇲🇨',
		dial: '377',
		currency: 'EUR',
		capital: 'Monaco',
		continent: 'EU'
	},
	{
		name: 'Uzbekistan',
		ISO_2: 'UZ',
		ISO_3: 'UZB',
		unicode: '🇺🇿',
		dial: '998',
		currency: 'UZS',
		capital: 'Tashkent',
		continent: 'AS'
	},
	{
		name: 'Myanmar',
		ISO_2: 'MM',
		ISO_3: 'MMR',
		unicode: '🇲🇲',
		dial: '95',
		currency: 'MMK',
		capital: 'Nay Pyi Taw',
		continent: 'AS'
	},
	{
		name: 'Mali',
		ISO_2: 'ML',
		ISO_3: 'MLI',
		unicode: '🇲🇱',
		dial: '223',
		currency: 'XOF',
		capital: 'Bamako',
		continent: 'AF'
	},
	{
		name: 'Macao',
		ISO_2: 'MO',
		ISO_3: 'MAC',
		unicode: '🇲🇴',
		dial: '853',
		currency: 'MOP',
		capital: 'Macao',
		continent: 'AS'
	},
	{
		name: 'Mongolia',
		ISO_2: 'MN',
		ISO_3: 'MNG',
		unicode: '🇲🇳',
		dial: '976',
		currency: 'MNT',
		capital: 'Ulan Bator',
		continent: 'AS'
	},
	{
		name: 'Marshall Islands',
		ISO_2: 'MH',
		ISO_3: 'MHL',
		unicode: '🇲🇭',
		dial: '692',
		currency: 'USD',
		capital: 'Majuro',
		continent: 'OC'
	},
	{
		name: 'Macedonia',
		ISO_2: 'MK',
		ISO_3: 'MKD',
		unicode: '🇲🇰',
		dial: '389',
		currency: 'MKD',
		capital: 'Skopje',
		continent: 'EU'
	},
	{
		name: 'Mauritius',
		ISO_2: 'MU',
		ISO_3: 'MUS',
		unicode: '🇲🇺',
		dial: '230',
		currency: 'MUR',
		capital: 'Port Louis',
		continent: 'AF'
	},
	{
		name: 'Malta',
		ISO_2: 'MT',
		ISO_3: 'MLT',
		unicode: '🇲🇹',
		dial: '356',
		currency: 'EUR',
		capital: 'Valletta',
		continent: 'EU'
	},
	{
		name: 'Malawi',
		ISO_2: 'MW',
		ISO_3: 'MWI',
		unicode: '🇲🇼',
		dial: '265',
		currency: 'MWK',
		capital: 'Lilongwe',
		continent: 'AF'
	},
	{
		name: 'Maldives',
		ISO_2: 'MV',
		ISO_3: 'MDV',
		unicode: '🇲🇻',
		dial: '960',
		currency: 'MVR',
		capital: 'Male',
		continent: 'AS'
	},
	{
		name: 'Martinique',
		ISO_2: 'MQ',
		ISO_3: 'MTQ',
		unicode: '🇲🇶',
		dial: '596',
		currency: 'EUR',
		capital: 'Fort-de-France',
		continent: 'NA'
	},
	{
		name: 'Northern Mariana Islands',
		ISO_2: 'MP',
		ISO_3: 'MNP',
		unicode: '🇲🇵',
		dial: '1-670',
		currency: 'USD',
		capital: 'Saipan',
		continent: 'OC'
	},
	{
		name: 'Montserrat',
		ISO_2: 'MS',
		ISO_3: 'MSR',
		unicode: '🇲🇸',
		dial: '1-664',
		currency: 'XCD',
		capital: 'Plymouth',
		continent: 'NA'
	},
	{
		name: 'Mauritania',
		ISO_2: 'MR',
		ISO_3: 'MRT',
		unicode: '🇲🇷',
		dial: '222',
		currency: 'MRO',
		capital: 'Nouakchott',
		continent: 'AF'
	},
	{
		name: 'Isle of Man',
		ISO_2: 'IM',
		ISO_3: 'IMN',
		unicode: '🇮🇲',
		dial: '44-1624',
		currency: 'GBP',
		capital: 'Douglas, Isle of Man',
		continent: 'EU'
	},
	{
		name: 'Uganda',
		ISO_2: 'UG',
		ISO_3: 'UGA',
		unicode: '🇺🇬',
		dial: '256',
		currency: 'UGX',
		capital: 'Kampala',
		continent: 'AF'
	},
	{
		name: 'Tanzania',
		ISO_2: 'TZ',
		ISO_3: 'TZA',
		unicode: '🇹🇿',
		dial: '255',
		currency: 'TZS',
		capital: 'Dodoma',
		continent: 'AF'
	},
	{
		name: 'Malaysia',
		ISO_2: 'MY',
		ISO_3: 'MYS',
		unicode: '🇲🇾',
		dial: '60',
		currency: 'MYR',
		capital: 'Kuala Lumpur',
		continent: 'AS'
	},
	{
		name: 'Mexico',
		ISO_2: 'MX',
		ISO_3: 'MEX',
		unicode: '🇲🇽',
		dial: '52',
		currency: 'MXN',
		capital: 'Mexico City',
		continent: 'NA'
	},
	{
		name: 'Israel',
		ISO_2: 'IL',
		ISO_3: 'ISR',
		unicode: '🇮🇱',
		dial: '972',
		currency: 'ILS',
		capital: 'Jerusalem',
		continent: 'AS'
	},
	{
		name: 'France',
		ISO_2: 'FR',
		ISO_3: 'FRA',
		unicode: '🇫🇷',
		dial: '33',
		currency: 'EUR',
		capital: 'Paris',
		continent: 'EU'
	},
	{
		name: 'British Indian Ocean Territory',
		ISO_2: 'IO',
		ISO_3: 'IOT',
		unicode: '🇮🇴',
		dial: '246',
		currency: 'USD',
		capital: 'Diego Garcia',
		continent: 'AS'
	},
	{
		name: 'Saint Helena',
		ISO_2: 'SH',
		ISO_3: 'SHN',
		unicode: '🇸🇭',
		dial: '290',
		currency: 'SHP',
		capital: 'Jamestown',
		continent: 'AF'
	},
	{
		name: 'Finland',
		ISO_2: 'FI',
		ISO_3: 'FIN',
		unicode: '🇫🇮',
		dial: '358',
		currency: 'EUR',
		capital: 'Helsinki',
		continent: 'EU'
	},
	{
		name: 'Fiji',
		ISO_2: 'FJ',
		ISO_3: 'FJI',
		unicode: '🇫🇯',
		dial: '679',
		currency: 'FJD',
		capital: 'Suva',
		continent: 'OC'
	},
	{
		name: 'Falkland Islands',
		ISO_2: 'FK',
		ISO_3: 'FLK',
		unicode: '🇫🇰',
		dial: '500',
		currency: 'FKP',
		capital: 'Stanley',
		continent: 'SA'
	},
	{
		name: 'Micronesia',
		ISO_2: 'FM',
		ISO_3: 'FSM',
		unicode: '🇫🇲',
		dial: '691',
		currency: 'USD',
		capital: 'Palikir',
		continent: 'OC'
	},
	{
		name: 'Faroe Islands',
		ISO_2: 'FO',
		ISO_3: 'FRO',
		unicode: '🇫🇴',
		dial: '298',
		currency: 'DKK',
		capital: 'Torshavn',
		continent: 'EU'
	},
	{
		name: 'Nicaragua',
		ISO_2: 'NI',
		ISO_3: 'NIC',
		unicode: '🇳🇮',
		dial: '505',
		currency: 'NIO',
		capital: 'Managua',
		continent: 'NA'
	},
	{
		name: 'Netherlands',
		ISO_2: 'NL',
		ISO_3: 'NLD',
		unicode: '🇳🇱',
		dial: '31',
		currency: 'EUR',
		capital: 'Amsterdam',
		continent: 'EU'
	},
	{
		name: 'Norway',
		ISO_2: 'NO',
		ISO_3: 'NOR',
		unicode: '🇳🇴',
		dial: '47',
		currency: 'NOK',
		capital: 'Oslo',
		continent: 'EU'
	},
	{
		name: 'Namibia',
		ISO_2: 'NA',
		ISO_3: 'NAM',
		unicode: '🇳🇦',
		dial: '264',
		currency: 'NAD',
		capital: 'Windhoek',
		continent: 'AF'
	},
	{
		name: 'Vanuatu',
		ISO_2: 'VU',
		ISO_3: 'VUT',
		unicode: '🇻🇺',
		dial: '678',
		currency: 'VUV',
		capital: 'Port Vila',
		continent: 'OC'
	},
	{
		name: 'New Caledonia',
		ISO_2: 'NC',
		ISO_3: 'NCL',
		unicode: '🇳🇨',
		dial: '687',
		currency: 'XPF',
		capital: 'Noumea',
		continent: 'OC'
	},
	{
		name: 'Niger',
		ISO_2: 'NE',
		ISO_3: 'NER',
		unicode: '🇳🇪',
		dial: '227',
		currency: 'XOF',
		capital: 'Niamey',
		continent: 'AF'
	},
	{
		name: 'Norfolk Island',
		ISO_2: 'NF',
		ISO_3: 'NFK',
		unicode: '🇳🇫',
		dial: '672',
		currency: 'AUD',
		capital: 'Kingston',
		continent: 'OC'
	},
	{
		name: 'Nigeria',
		ISO_2: 'NG',
		ISO_3: 'NGA',
		unicode: '🇳🇬',
		dial: '234',
		currency: 'NGN',
		capital: 'Abuja',
		continent: 'AF'
	},
	{
		name: 'New Zealand',
		ISO_2: 'NZ',
		ISO_3: 'NZL',
		unicode: '🇳🇿',
		dial: '64',
		currency: 'NZD',
		capital: 'Wellington',
		continent: 'OC'
	},
	{
		name: 'Nepal',
		ISO_2: 'NP',
		ISO_3: 'NPL',
		unicode: '🇳🇵',
		dial: '977',
		currency: 'NPR',
		capital: 'Kathmandu',
		continent: 'AS'
	},
	{
		name: 'Nauru',
		ISO_2: 'NR',
		ISO_3: 'NRU',
		unicode: '🇳🇷',
		dial: '674',
		currency: 'AUD',
		capital: 'Yaren',
		continent: 'OC'
	},
	{
		name: 'Niue',
		ISO_2: 'NU',
		ISO_3: 'NIU',
		unicode: '🇳🇺',
		dial: '683',
		currency: 'NZD',
		capital: 'Alofi',
		continent: 'OC'
	},
	{
		name: 'Cook Islands',
		ISO_2: 'CK',
		ISO_3: 'COK',
		unicode: '🇨🇰',
		dial: '682',
		currency: 'NZD',
		capital: 'Avarua',
		continent: 'OC'
	},
	{
		name: 'Kosovo',
		ISO_2: 'XK',
		ISO_3: 'XKX',
		unicode: '🇽🇰',
		dial: '',
		currency: 'EUR',
		capital: 'Pristina',
		continent: 'EU'
	},
	{
		name: 'Ivory Coast',
		ISO_2: 'CI',
		ISO_3: 'CIV',
		unicode: '🇨🇮',
		dial: '225',
		currency: 'XOF',
		capital: 'Yamoussoukro',
		continent: 'AF'
	},
	{
		name: 'Switzerland',
		ISO_2: 'CH',
		ISO_3: 'CHE',
		unicode: '🇨🇭',
		dial: '41',
		currency: 'CHF',
		capital: 'Berne',
		continent: 'EU'
	},
	{
		name: 'Colombia',
		ISO_2: 'CO',
		ISO_3: 'COL',
		unicode: '🇨🇴',
		dial: '57',
		currency: 'COP',
		capital: 'Bogota',
		continent: 'SA'
	},
	{
		name: 'China',
		ISO_2: 'CN',
		ISO_3: 'CHN',
		unicode: '🇨🇳',
		dial: '86',
		currency: 'CNY',
		capital: 'Beijing',
		continent: 'AS'
	},
	{
		name: 'Cameroon',
		ISO_2: 'CM',
		ISO_3: 'CMR',
		unicode: '🇨🇲',
		dial: '237',
		currency: 'XAF',
		capital: 'Yaounde',
		continent: 'AF'
	},
	{
		name: 'Chile',
		ISO_2: 'CL',
		ISO_3: 'CHL',
		unicode: '🇨🇱',
		dial: '56',
		currency: 'CLP',
		capital: 'Santiago',
		continent: 'SA'
	},
	{
		name: 'Cocos Islands',
		ISO_2: 'CC',
		ISO_3: 'CCK',
		unicode: '🇨🇨',
		dial: '61',
		currency: 'AUD',
		capital: 'West Island',
		continent: 'AS'
	},
	{
		name: 'Canada',
		ISO_2: 'CA',
		ISO_3: 'CAN',
		unicode: '🇨🇦',
		dial: '1',
		currency: 'CAD',
		capital: 'Ottawa',
		continent: 'NA'
	},
	{
		name: 'Republic of the Congo',
		ISO_2: 'CG',
		ISO_3: 'COG',
		unicode: '🇨🇬',
		dial: '242',
		currency: 'XAF',
		capital: 'Brazzaville',
		continent: 'AF'
	},
	{
		name: 'Central African Republic',
		ISO_2: 'CF',
		ISO_3: 'CAF',
		unicode: '🇨🇫',
		dial: '236',
		currency: 'XAF',
		capital: 'Bangui',
		continent: 'AF'
	},
	{
		name: 'Democratic Republic of the Congo',
		ISO_2: 'CD',
		ISO_3: 'COD',
		unicode: '🇨🇩',
		dial: '243',
		currency: 'CDF',
		capital: 'Kinshasa',
		continent: 'AF'
	},
	{
		name: 'Czech Republic',
		ISO_2: 'CZ',
		ISO_3: 'CZE',
		unicode: '🇨🇿',
		dial: '420',
		currency: 'CZK',
		capital: 'Prague',
		continent: 'EU'
	},
	{
		name: 'Cyprus',
		ISO_2: 'CY',
		ISO_3: 'CYP',
		unicode: '🇨🇾',
		dial: '357',
		currency: 'EUR',
		capital: 'Nicosia',
		continent: 'EU'
	},
	{
		name: 'Christmas Island',
		ISO_2: 'CX',
		ISO_3: 'CXR',
		unicode: '🇨🇽',
		dial: '61',
		currency: 'AUD',
		capital: 'Flying Fish Cove',
		continent: 'AS'
	},
	{
		name: 'Costa Rica',
		ISO_2: 'CR',
		ISO_3: 'CRI',
		unicode: '🇨🇷',
		dial: '506',
		currency: 'CRC',
		capital: 'San Jose',
		continent: 'NA'
	},
	{
		name: 'Curacao',
		ISO_2: 'CW',
		ISO_3: 'CUW',
		unicode: '🇨🇼',
		dial: '599',
		currency: 'ANG',
		capital: ' Willemstad',
		continent: 'NA'
	},
	{
		name: 'Cape Verde',
		ISO_2: 'CV',
		ISO_3: 'CPV',
		unicode: '🇨🇻',
		dial: '238',
		currency: 'CVE',
		capital: 'Praia',
		continent: 'AF'
	},
	{
		name: 'Cuba',
		ISO_2: 'CU',
		ISO_3: 'CUB',
		unicode: '🇨🇺',
		dial: '53',
		currency: 'CUP',
		capital: 'Havana',
		continent: 'NA'
	},
	{
		name: 'Swaziland',
		ISO_2: 'SZ',
		ISO_3: 'SWZ',
		unicode: '🇸🇿',
		dial: '268',
		currency: 'SZL',
		capital: 'Mbabane',
		continent: 'AF'
	},
	{
		name: 'Syria',
		ISO_2: 'SY',
		ISO_3: 'SYR',
		unicode: '🇸🇾',
		dial: '963',
		currency: 'SYP',
		capital: 'Damascus',
		continent: 'AS'
	},
	{
		name: 'Sint Maarten',
		ISO_2: 'SX',
		ISO_3: 'SXM',
		unicode: '🇸🇽',
		dial: '599',
		currency: 'ANG',
		capital: 'Philipsburg',
		continent: 'NA'
	},
	{
		name: 'Kyrgyzstan',
		ISO_2: 'KG',
		ISO_3: 'KGZ',
		unicode: '🇰🇬',
		dial: '996',
		currency: 'KGS',
		capital: 'Bishkek',
		continent: 'AS'
	},
	{
		name: 'Kenya',
		ISO_2: 'KE',
		ISO_3: 'KEN',
		unicode: '🇰🇪',
		dial: '254',
		currency: 'KES',
		capital: 'Nairobi',
		continent: 'AF'
	},
	{
		name: 'South Sudan',
		ISO_2: 'SS',
		ISO_3: 'SSD',
		unicode: '🇸🇸',
		dial: '211',
		currency: 'SSP',
		capital: 'Juba',
		continent: 'AF'
	},
	{
		name: 'Suriname',
		ISO_2: 'SR',
		ISO_3: 'SUR',
		unicode: '🇸🇷',
		dial: '597',
		currency: 'SRD',
		capital: 'Paramaribo',
		continent: 'SA'
	},
	{
		name: 'Kiribati',
		ISO_2: 'KI',
		ISO_3: 'KIR',
		unicode: '🇰🇮',
		dial: '686',
		currency: 'AUD',
		capital: 'Tarawa',
		continent: 'OC'
	},
	{
		name: 'Cambodia',
		ISO_2: 'KH',
		ISO_3: 'KHM',
		unicode: '🇰🇭',
		dial: '855',
		currency: 'KHR',
		capital: 'Phnom Penh',
		continent: 'AS'
	},
	{
		name: 'Saint Kitts and Nevis',
		ISO_2: 'KN',
		ISO_3: 'KNA',
		unicode: '🇰🇳',
		dial: '1-869',
		currency: 'XCD',
		capital: 'Basseterre',
		continent: 'NA'
	},
	{
		name: 'Comoros',
		ISO_2: 'KM',
		ISO_3: 'COM',
		unicode: '🇰🇲',
		dial: '269',
		currency: 'KMF',
		capital: 'Moroni',
		continent: 'AF'
	},
	{
		name: 'Sao Tome and Principe',
		ISO_2: 'ST',
		ISO_3: 'STP',
		unicode: '🇸🇹',
		dial: '239',
		currency: 'STD',
		capital: 'Sao Tome',
		continent: 'AF'
	},
	{
		name: 'Slovakia',
		ISO_2: 'SK',
		ISO_3: 'SVK',
		unicode: '🇸🇰',
		dial: '421',
		currency: 'EUR',
		capital: 'Bratislava',
		continent: 'EU'
	},
	{
		name: 'South Korea',
		ISO_2: 'KR',
		ISO_3: 'KOR',
		unicode: '🇰🇷',
		dial: '82',
		currency: 'KRW',
		capital: 'Seoul',
		continent: 'AS'
	},
	{
		name: 'Slovenia',
		ISO_2: 'SI',
		ISO_3: 'SVN',
		unicode: '🇸🇮',
		dial: '386',
		currency: 'EUR',
		capital: 'Ljubljana',
		continent: 'EU'
	},
	{
		name: 'North Korea',
		ISO_2: 'KP',
		ISO_3: 'PRK',
		unicode: '🇰🇵',
		dial: '850',
		currency: 'KPW',
		capital: 'Pyongyang',
		continent: 'AS'
	},
	{
		name: 'Kuwait',
		ISO_2: 'KW',
		ISO_3: 'KWT',
		unicode: '🇰🇼',
		dial: '965',
		currency: 'KWD',
		capital: 'Kuwait City',
		continent: 'AS'
	},
	{
		name: 'Senegal',
		ISO_2: 'SN',
		ISO_3: 'SEN',
		unicode: '🇸🇳',
		dial: '221',
		currency: 'XOF',
		capital: 'Dakar',
		continent: 'AF'
	},
	{
		name: 'San Marino',
		ISO_2: 'SM',
		ISO_3: 'SMR',
		unicode: '🇸🇲',
		dial: '378',
		currency: 'EUR',
		capital: 'San Marino',
		continent: 'EU'
	},
	{
		name: 'Sierra Leone',
		ISO_2: 'SL',
		ISO_3: 'SLE',
		unicode: '🇸🇱',
		dial: '232',
		currency: 'SLL',
		capital: 'Freetown',
		continent: 'AF'
	},
	{
		name: 'Seychelles',
		ISO_2: 'SC',
		ISO_3: 'SYC',
		unicode: '🇸🇨',
		dial: '248',
		currency: 'SCR',
		capital: 'Victoria',
		continent: 'AF'
	},
	{
		name: 'Kazakhstan',
		ISO_2: 'KZ',
		ISO_3: 'KAZ',
		unicode: '🇰🇿',
		dial: '7',
		currency: 'KZT',
		capital: 'Astana',
		continent: 'AS'
	},
	{
		name: 'Cayman Islands',
		ISO_2: 'KY',
		ISO_3: 'CYM',
		unicode: '🇰🇾',
		dial: '1-345',
		currency: 'KYD',
		capital: 'George Town',
		continent: 'NA'
	},
	{
		name: 'Singapore',
		ISO_2: 'SG',
		ISO_3: 'SGP',
		unicode: '🇸🇬',
		dial: '65',
		currency: 'SGD',
		capital: 'Singapur',
		continent: 'AS'
	},
	{
		name: 'Sweden',
		ISO_2: 'SE',
		ISO_3: 'SWE',
		unicode: '🇸🇪',
		dial: '46',
		currency: 'SEK',
		capital: 'Stockholm',
		continent: 'EU'
	},
	{
		name: 'Sudan',
		ISO_2: 'SD',
		ISO_3: 'SDN',
		unicode: '🇸🇩',
		dial: '249',
		currency: 'SDG',
		capital: 'Khartoum',
		continent: 'AF'
	},
	{
		name: 'Dominican Republic',
		ISO_2: 'DO',
		ISO_3: 'DOM',
		unicode: '🇩🇴',
		dial: '1-809 and 1-829',
		currency: 'DOP',
		capital: 'Santo Domingo',
		continent: 'NA'
	},
	{
		name: 'Dominica',
		ISO_2: 'DM',
		ISO_3: 'DMA',
		unicode: '🇩🇲',
		dial: '1-767',
		currency: 'XCD',
		capital: 'Roseau',
		continent: 'NA'
	},
	{
		name: 'Djibouti',
		ISO_2: 'DJ',
		ISO_3: 'DJI',
		unicode: '🇩🇯',
		dial: '253',
		currency: 'DJF',
		capital: 'Djibouti',
		continent: 'AF'
	},
	{
		name: 'Denmark',
		ISO_2: 'DK',
		ISO_3: 'DNK',
		unicode: '🇩🇰',
		dial: '45',
		currency: 'DKK',
		capital: 'Copenhagen',
		continent: 'EU'
	},
	{
		name: 'British Virgin Islands',
		ISO_2: 'VG',
		ISO_3: 'VGB',
		unicode: '🇻🇬',
		dial: '1-284',
		currency: 'USD',
		capital: 'Road Town',
		continent: 'NA'
	},
	{
		name: 'Germany',
		ISO_2: 'DE',
		ISO_3: 'DEU',
		unicode: '🇩🇪',
		dial: '49',
		currency: 'EUR',
		capital: 'Berlin',
		continent: 'EU'
	},
	{
		name: 'Yemen',
		ISO_2: 'YE',
		ISO_3: 'YEM',
		unicode: '🇾🇪',
		dial: '967',
		currency: 'YER',
		capital: 'Sanaa',
		continent: 'AS'
	},
	{
		name: 'Algeria',
		ISO_2: 'DZ',
		ISO_3: 'DZA',
		unicode: '🇩🇿',
		dial: '213',
		currency: 'DZD',
		capital: 'Algiers',
		continent: 'AF'
	},
	{
		name: 'United States',
		ISO_2: 'US',
		ISO_3: 'USA',
		unicode: '🇺🇸',
		dial: '1',
		currency: 'USD',
		capital: 'Washington',
		continent: 'NA'
	},
	{
		name: 'Uruguay',
		ISO_2: 'UY',
		ISO_3: 'URY',
		unicode: '🇺🇾',
		dial: '598',
		currency: 'UYU',
		capital: 'Montevideo',
		continent: 'SA'
	},
	{
		name: 'Mayotte',
		ISO_2: 'YT',
		ISO_3: 'MYT',
		unicode: '🇾🇹',
		dial: '262',
		currency: 'EUR',
		capital: 'Mamoudzou',
		continent: 'AF'
	},
	{
		name: 'United States Minor Outlying Islands',
		ISO_2: 'UM',
		ISO_3: 'UMI',
		unicode: '🇺🇲',
		dial: '1',
		currency: 'USD',
		capital: '',
		continent: 'OC'
	},
	{
		name: 'Lebanon',
		ISO_2: 'LB',
		ISO_3: 'LBN',
		unicode: '🇱🇧',
		dial: '961',
		currency: 'LBP',
		capital: 'Beirut',
		continent: 'AS'
	},
	{
		name: 'Saint Lucia',
		ISO_2: 'LC',
		ISO_3: 'LCA',
		unicode: '🇱🇨',
		dial: '1-758',
		currency: 'XCD',
		capital: 'Castries',
		continent: 'NA'
	},
	{
		name: 'Laos',
		ISO_2: 'LA',
		ISO_3: 'LAO',
		unicode: '🇱🇦',
		dial: '856',
		currency: 'LAK',
		capital: 'Vientiane',
		continent: 'AS'
	},
	{
		name: 'Tuvalu',
		ISO_2: 'TV',
		ISO_3: 'TUV',
		unicode: '🇹🇻',
		dial: '688',
		currency: 'AUD',
		capital: 'Funafuti',
		continent: 'OC'
	},
	{
		name: 'Taiwan',
		ISO_2: 'TW',
		ISO_3: 'TWN',
		unicode: '🇹🇼',
		dial: '886',
		currency: 'TWD',
		capital: 'Taipei',
		continent: 'AS'
	},
	{
		name: 'Trinidad and Tobago',
		ISO_2: 'TT',
		ISO_3: 'TTO',
		unicode: '🇹🇹',
		dial: '1-868',
		currency: 'TTD',
		capital: 'Port of Spain',
		continent: 'NA'
	},
	{
		name: 'Turkey',
		ISO_2: 'TR',
		ISO_3: 'TUR',
		unicode: '🇹🇷',
		dial: '90',
		currency: 'TRY',
		capital: 'Ankara',
		continent: 'AS'
	},
	{
		name: 'Sri Lanka',
		ISO_2: 'LK',
		ISO_3: 'LKA',
		unicode: '🇱🇰',
		dial: '94',
		currency: 'LKR',
		capital: 'Colombo',
		continent: 'AS'
	},
	{
		name: 'Liechtenstein',
		ISO_2: 'LI',
		ISO_3: 'LIE',
		unicode: '🇱🇮',
		dial: '423',
		currency: 'CHF',
		capital: 'Vaduz',
		continent: 'EU'
	},
	{
		name: 'Latvia',
		ISO_2: 'LV',
		ISO_3: 'LVA',
		unicode: '🇱🇻',
		dial: '371',
		currency: 'EUR',
		capital: 'Riga',
		continent: 'EU'
	},
	{
		name: 'Tonga',
		ISO_2: 'TO',
		ISO_3: 'TON',
		unicode: '🇹🇴',
		dial: '676',
		currency: 'TOP',
		capital: "Nuku'alofa",
		continent: 'OC'
	},
	{
		name: 'Lithuania',
		ISO_2: 'LT',
		ISO_3: 'LTU',
		unicode: '🇱🇹',
		dial: '370',
		currency: 'LTL',
		capital: 'Vilnius',
		continent: 'EU'
	},
	{
		name: 'Luxembourg',
		ISO_2: 'LU',
		ISO_3: 'LUX',
		unicode: '🇱🇺',
		dial: '352',
		currency: 'EUR',
		capital: 'Luxembourg',
		continent: 'EU'
	},
	{
		name: 'Liberia',
		ISO_2: 'LR',
		ISO_3: 'LBR',
		unicode: '🇱🇷',
		dial: '231',
		currency: 'LRD',
		capital: 'Monrovia',
		continent: 'AF'
	},
	{
		name: 'Lesotho',
		ISO_2: 'LS',
		ISO_3: 'LSO',
		unicode: '🇱🇸',
		dial: '266',
		currency: 'LSL',
		capital: 'Maseru',
		continent: 'AF'
	},
	{
		name: 'Thailand',
		ISO_2: 'TH',
		ISO_3: 'THA',
		unicode: '🇹🇭',
		dial: '66',
		currency: 'THB',
		capital: 'Bangkok',
		continent: 'AS'
	},
	{
		name: 'French Southern Territories',
		ISO_2: 'TF',
		ISO_3: 'ATF',
		unicode: '🇹🇫',
		dial: '',
		currency: 'EUR',
		capital: 'Port-aux-Francais',
		continent: 'AN'
	},
	{
		name: 'Togo',
		ISO_2: 'TG',
		ISO_3: 'TGO',
		unicode: '🇹🇬',
		dial: '228',
		currency: 'XOF',
		capital: 'Lome',
		continent: 'AF'
	},
	{
		name: 'Chad',
		ISO_2: 'TD',
		ISO_3: 'TCD',
		unicode: '🇹🇩',
		dial: '235',
		currency: 'XAF',
		capital: "N'Djamena",
		continent: 'AF'
	},
	{
		name: 'Turks and Caicos Islands',
		ISO_2: 'TC',
		ISO_3: 'TCA',
		unicode: '🇹🇨',
		dial: '1-649',
		currency: 'USD',
		capital: 'Cockburn Town',
		continent: 'NA'
	},
	{
		name: 'Libya',
		ISO_2: 'LY',
		ISO_3: 'LBY',
		unicode: '🇱🇾',
		dial: '218',
		currency: 'LYD',
		capital: 'Tripolis',
		continent: 'AF'
	},
	{
		name: 'Vatican',
		ISO_2: 'VA',
		ISO_3: 'VAT',
		unicode: '🇻🇦',
		dial: '379',
		currency: 'EUR',
		capital: 'Vatican City',
		continent: 'EU'
	},
	{
		name: 'Saint Vincent and the Grenadines',
		ISO_2: 'VC',
		ISO_3: 'VCT',
		unicode: '🇻🇨',
		dial: '1-784',
		currency: 'XCD',
		capital: 'Kingstown',
		continent: 'NA'
	},
	{
		name: 'United Arab Emirates',
		ISO_2: 'AE',
		ISO_3: 'ARE',
		unicode: '🇦🇪',
		dial: '971',
		currency: 'AED',
		capital: 'Abu Dhabi',
		continent: 'AS'
	},
	{
		name: 'Andorra',
		ISO_2: 'AD',
		ISO_3: 'AND',
		unicode: '🇦🇩',
		dial: '376',
		currency: 'EUR',
		capital: 'Andorra la Vella',
		continent: 'EU'
	},
	{
		name: 'Antigua and Barbuda',
		ISO_2: 'AG',
		ISO_3: 'ATG',
		unicode: '🇦🇬',
		dial: '1-268',
		currency: 'XCD',
		capital: "St. John's",
		continent: 'NA'
	},
	{
		name: 'Afghanistan',
		ISO_2: 'AF',
		ISO_3: 'AFG',
		unicode: '🇦🇫',
		dial: '93',
		currency: 'AFN',
		capital: 'Kabul',
		continent: 'AS'
	},
	{
		name: 'Anguilla',
		ISO_2: 'AI',
		ISO_3: 'AIA',
		unicode: '🇦🇮',
		dial: '1-264',
		currency: 'XCD',
		capital: 'The Valley',
		continent: 'NA'
	},
	{
		name: 'U.S. Virgin Islands',
		ISO_2: 'VI',
		ISO_3: 'VIR',
		unicode: '🇻🇮',
		dial: '1-340',
		currency: 'USD',
		capital: 'Charlotte Amalie',
		continent: 'NA'
	},
	{
		name: 'Iceland',
		ISO_2: 'IS',
		ISO_3: 'ISL',
		unicode: '🇮🇸',
		dial: '354',
		currency: 'ISK',
		capital: 'Reykjavik',
		continent: 'EU'
	},
	{
		name: 'Iran',
		ISO_2: 'IR',
		ISO_3: 'IRN',
		unicode: '🇮🇷',
		dial: '98',
		currency: 'IRR',
		capital: 'Tehran',
		continent: 'AS'
	},
	{
		name: 'Armenia',
		ISO_2: 'AM',
		ISO_3: 'ARM',
		unicode: '🇦🇲',
		dial: '374',
		currency: 'AMD',
		capital: 'Yerevan',
		continent: 'AS'
	},
	{
		name: 'Albania',
		ISO_2: 'AL',
		ISO_3: 'ALB',
		unicode: '🇦🇱',
		dial: '355',
		currency: 'ALL',
		capital: 'Tirana',
		continent: 'EU'
	},
	{
		name: 'Angola',
		ISO_2: 'AO',
		ISO_3: 'AGO',
		unicode: '🇦🇴',
		dial: '244',
		currency: 'AOA',
		capital: 'Luanda',
		continent: 'AF'
	},
	{
		name: 'Antarctica',
		ISO_2: 'AQ',
		ISO_3: 'ATA',
		unicode: '🇦🇶',
		dial: '',
		currency: '',
		capital: '',
		continent: 'AN'
	},
	{
		name: 'American Samoa',
		ISO_2: 'AS',
		ISO_3: 'ASM',
		unicode: '🇦🇸',
		dial: '1-684',
		currency: 'USD',
		capital: 'Pago Pago',
		continent: 'OC'
	},
	{
		name: 'Argentina',
		ISO_2: 'AR',
		ISO_3: 'ARG',
		unicode: '🇦🇷',
		dial: '54',
		currency: 'ARS',
		capital: 'Buenos Aires',
		continent: 'SA'
	},
	{
		name: 'Australia',
		ISO_2: 'AU',
		ISO_3: 'AUS',
		unicode: '🇦🇺',
		dial: '61',
		currency: 'AUD',
		capital: 'Canberra',
		continent: 'OC'
	},
	{
		name: 'Austria',
		ISO_2: 'AT',
		ISO_3: 'AUT',
		unicode: '🇦🇹',
		dial: '43',
		currency: 'EUR',
		capital: 'Vienna',
		continent: 'EU'
	},
	{
		name: 'Aruba',
		ISO_2: 'AW',
		ISO_3: 'ABW',
		unicode: '🇦🇼',
		dial: '297',
		currency: 'AWG',
		capital: 'Oranjestad',
		continent: 'NA'
	},
	{
		name: 'India',
		ISO_2: 'IN',
		ISO_3: 'IND',
		unicode: '🇮🇳',
		dial: '91',
		currency: 'INR',
		capital: 'New Delhi',
		continent: 'AS'
	},
	{
		name: 'Aland Islands',
		ISO_2: 'AX',
		ISO_3: 'ALA',
		unicode: '🇦🇽',
		dial: '358-18',
		currency: 'EUR',
		capital: 'Mariehamn',
		continent: 'EU'
	},
	{
		name: 'Azerbaijan',
		ISO_2: 'AZ',
		ISO_3: 'AZE',
		unicode: '🇦🇿',
		dial: '994',
		currency: 'AZN',
		capital: 'Baku',
		continent: 'AS'
	},
	{
		name: 'Ireland',
		ISO_2: 'IE',
		ISO_3: 'IRL',
		unicode: '🇮🇪',
		dial: '353',
		currency: 'EUR',
		capital: 'Dublin',
		continent: 'EU'
	},
	{
		name: 'Indonesia',
		ISO_2: 'ID',
		ISO_3: 'IDN',
		unicode: '🇮🇩',
		dial: '62',
		currency: 'IDR',
		capital: 'Jakarta',
		continent: 'AS'
	},
	{
		name: 'Ukraine',
		ISO_2: 'UA',
		ISO_3: 'UKR',
		unicode: '🇺🇦',
		dial: '380',
		currency: 'UAH',
		capital: 'Kiev',
		continent: 'EU'
	},
	{
		name: 'Qatar',
		ISO_2: 'QA',
		ISO_3: 'QAT',
		unicode: '🇶🇦',
		dial: '974',
		currency: 'QAR',
		capital: 'Doha',
		continent: 'AS'
	},
	{
		name: 'Mozambique',
		ISO_2: 'MZ',
		ISO_3: 'MOZ',
		unicode: '🇲🇿',
		dial: '258',
		currency: 'MZN',
		capital: 'Maputo',
		continent: 'AF'
	}
];

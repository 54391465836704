import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, moveForm } from 'store/data/forms';
import { useState } from 'react';

type HandlerInput = Parameters<typeof moveForm>[0];

export function useMoveForm(): Operation<(payload: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [activityPayload, setActivityPayload] = useState('');

	const [{ loading, error }, resetError] = useActivity(ActionTypes.MOVE_FORM, activityPayload);

	function handler(payload: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		setActivityPayload(payload.formId);

		dispatch(moveForm(payload));
	}

	return [{ loading, error }, handler];
}

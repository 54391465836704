import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectFormSearchTerm,
	setFormSearchTerm,
	SetFormVariableSearchTermAction
} from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormSearchTerm(): LocalOperationResult<
	string,
	(payload: ActionPayload<SetFormVariableSearchTermAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectFormSearchTerm(state.data.forms));

	function handler(payload: ActionPayload<SetFormVariableSearchTermAction>) {
		dispatch(setFormSearchTerm(payload));
	}

	return [data, handler];
}

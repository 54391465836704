import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface Props {
	onConfirm: () => void;
	onClose: () => void;
}

export function PromptToCancelModal({ onConfirm, onClose }: Props) {
	const { translate } = useTranslation();

	return (
		<Modal
			title={translate(dict => dict.dataset.entries.transferOwnership.cancelModal.title)}
			visible
			primary={{
				label: translate(dict => dict.buttons.confirm),
				onClick: onConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			close
		>
			<Typography.Paragraph multiline>
				{translate(dict => dict.dataset.entries.transferOwnership.cancelModal.text)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { confirmFinishedForm } from 'store/data/patients';
import { ActionTypes } from 'store/data/patients';

export function useConfirmFinishedForm(): Operation {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CONFIRM_FINISHED_FORM);

	function handler() {
		dispatch(confirmFinishedForm());
	}

	return [{ loading, error }, handler];
}

import produce from 'immer';

import {
	CompareNumericAnalysisV2,
	CorrelationsAnalysis,
	JADBioAnalysis,
	TimeCourseAnalysisV2,
	PlotNumericAnalysisV2,
	CrosstabResultsV2,
	CrosstabAnalysisV2,
	KaplanMeierAnalysisV2,
	DensityPlotAnalysisV2,
	TimeCourseResultsV2,
	ComparePairedAnalysisV2
} from 'api/data/analyses';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

import { ActionTypes as EntriesActionTypes, Actions as EntriesActions } from '../entries/types';
import { ActionTypes as FiltersActionTypes, Actions as FiltersActions } from '../filters/types';
import {
	ActionTypes as SnapshotsActionTypes,
	Actions as SnapshotsActions
} from '../snapshots/types';
import {
	ActionTypes as VariablesActionTypes,
	Actions as VariablesActions
} from '../variables/types';
import { ActionTypes as ProjectsActionTypes, Actions as ProjectsActions } from '../projects/types';
import {
	ActionTypes as JADBioActionTypes,
	Actions as JADBioActions
} from '../../addons/jadbio/types';
import { withStatisticsResetter, validateAnalysis } from 'helpers/analysis';
import { VariableType } from 'types/data/variables/constants';

export default (
	state: State = initialState,
	action:
		| Actions
		| FiltersActions
		| EntriesActions
		| SnapshotsActions
		| VariablesActions
		| ProjectsActions
		| JADBioActions
): State => {
	switch (action.type) {
		case ProjectsActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					byProjectId[projectId] = {
						active: []
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.CREATE_ANALYSIS: {
			const { analysis } = action.payload;

			return produce(state, draft => {
				const { projectId, byId, byProjectId } = draft;

				if (projectId) {
					byId[analysis.id] = analysis;
					byProjectId[projectId].active.unshift(analysis.id);
				}
			});
		}

		case ActionTypes.UPDATE_ANALYSIS: {
			const { analysis } = action.payload;

			return produce(state, draft => {
				// RESET STATISTICS IF WENT FROM `ENABED` TO `DISABLED`
				const parsedAnalysis = withStatisticsResetter(analysis);

				draft.byId[analysis.id] = parsedAnalysis;
			});
		}

		case ActionTypes.DELETE_ANALYSIS: {
			const { projectId, analysisId, snapshotId } = action.payload;

			return produce(state, draft => {
				const { bySnapshotId } = draft;
				const active = draft.byProjectId[projectId].active.filter(id => id !== analysisId);

				if (!snapshotId || !bySnapshotId[snapshotId].includes(analysisId)) {
					delete draft.byId[analysisId];
					draft.plots[analysisId] !== undefined && delete draft.plots[analysisId];
				}

				draft.byProjectId[projectId].active = active;
			});
		}

		case ActionTypes.DELETE_ANALYSES: {
			const { projectId, analysisIds } = action.payload;

			return produce(state, draft => {
				const active = draft.byProjectId[projectId].active.filter(
					id => !analysisIds.includes(id)
				);

				analysisIds.forEach(analysisId => {
					delete draft.byId[analysisId];
					draft.plots[analysisId] !== undefined && delete draft.plots[analysisId];
				});

				draft.byProjectId[projectId].active = active;
			});
		}

		case ActionTypes.SET_ANALYSIS_ACTIVE_TAB: {
			const { analysisId, activeTab } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.activeTab = activeTab;
			});
		}

		case ActionTypes.SET_ANALYSIS_CONFIG_PANEL: {
			const { analysisId, openConfig } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.configPanel.open = openConfig;
			});
		}
		case ActionTypes.SET_ANALYSIS_FORMATTING: {
			const { analysisId, formatting } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.configPanel.formatting = formatting;
			});
		}
		case ActionTypes.SET_ANALYSIS_PARAMETERS: {
			const { analysisId, parameters } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.configPanel.parameters = parameters;
			});
		}
		case ActionTypes.SET_ANALYSIS_CHART_TYPES: {
			const { analysisId, chartType } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.configPanel.chartType = chartType;
			});
		}

		case ActionTypes.SET_ANALYSIS_JADBIO_PERFORMANCE_STATE: {
			const { analysisId, jadBioPerformance } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.configPanel.jadBioPerformance = jadBioPerformance;
			});
		}

		case ActionTypes.SET_ANALYSIS_OPEN_STATE: {
			const { analysisId, open } = action.payload;

			return produce(state, draft => {
				draft.byId[analysisId].options.open = open;
			});
		}

		case ActionTypes.SET_REFETCH_ANALYSES: {
			return produce(state, draft => {
				draft.refetch = true;
			});
		}

		case ActionTypes.SET_REFETCHED_ANALYSES: {
			return produce(state, draft => {
				draft.refetch = false;
			});
		}

		case ActionTypes.REBUILD_ANALYSES: {
			const { byId } = action.payload;

			return produce(state, draft => {
				draft.byId = byId;
			});
		}

		case ActionTypes.SET_ANALYSES_COLUMN: {
			const { column } = action.payload;
			return produce(state, draft => {
				draft.activeColumn = column;
			});
		}
		case ActionTypes.SET_ANALYSIS_FULLSCREEN: {
			const { fullscreen } = action.payload;
			return produce(state, draft => {
				draft.fullscreen = fullscreen;
			});
		}

		case ActionTypes.TOGGLE_CHART_PLOT: {
			const { id, plotIndex } = action.payload;

			return produce(state, draft => {
				// INIT PLOT FIRST
				if (!draft.plots[id]) draft.plots[id] = {};

				draft.plots[id][plotIndex] =
					state.plots[id]?.[plotIndex] !== undefined
						? !state.plots[id][plotIndex]
						: false;
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.GET_COMPARE_NUMERIC_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;
					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_CORRELATIONS_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;

					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_CROSSTAB_V2: {
			const {
				dataset: { data, error },
				analysisId
			} = action.payload;

			// RESET TOGGLED PLOTS STATUS
			return produce(state, draft => {
				draft.plots[analysisId] = {};
				const { byId } = draft;

				if (analysisId in byId) {
					byId[analysisId].output.dataset = {
						data,
						error
					} as CrosstabResultsV2;

					byId[analysisId].fetched = true;
				}
				draft.refetch = false;
			});
		}

		case ActionTypes.GET_EXPLORE_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;
					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_FREQUENCIES_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;
					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_KAPLAN_MEIER_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;
					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_PLOT_NUMERIC_COLUMNS_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as PlotNumericAnalysisV2;

					analysis.output.dataset.columns = dataset;
					analysis.output.grouping = !!dataset.data?.[0].group2;

					analysis.fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_PLOT_NUMERIC_BOXPLOT_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as PlotNumericAnalysisV2;

					let grouping = false;
					const data = dataset;
					if (data?.data?.[0].group2) grouping = true;

					analysis.output.dataset.boxplot = dataset;
					analysis.output.grouping = grouping;
					analysis.fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_PLOT_NUMERIC_SCATTER_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as PlotNumericAnalysisV2;
					analysis.output.dataset.scatter = dataset;
					analysis.fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_DENSITY_PLOT_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as DensityPlotAnalysisV2;
					analysis.output.dataset = dataset;
					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_TIME_COURSE_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as TimeCourseAnalysisV2;

					let grouping = false;
					const { data } = dataset as TimeCourseResultsV2;

					if (data && 'groupedTimeCourse' in data && data?.groupedTimeCourse) {
						data.groupedTimeCourse.forEach(value => {
							if (value.group) grouping = true;
						});
					}

					analysis.output.dataset = dataset;
					analysis.output.grouping = grouping;

					analysis.fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_COMPARE_PAIRED_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;

					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_NUMBER_PLOT_XY: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				// RESET TOGGLED PLOTS STATUS
				draft.plots[analysisId] = {};
				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;
					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_LOGISTIC_REGRESSION_V2: {
			const { dataset, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					byId[analysisId].output.dataset = dataset;

					byId[analysisId].fetched = true;
				}

				draft.refetch = false;
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.GET_FISHER_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CrosstabAnalysisV2;
					analysis.output.statistics.fisher = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_CHI_SQUARE_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CrosstabAnalysisV2;
					analysis.output.statistics.chiSquare = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_SHAPIRO_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.shapiro = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_MANN_WHITNEY_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.mannWhitney = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_INDEPENDENT_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.independent = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_PEARSON_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CorrelationsAnalysis;
					analysis.output.statistics.pearsonV2 = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_SPEARMAN_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CorrelationsAnalysis;
					analysis.output.statistics.spearmanV2 = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_LINEAR_REGRESSION_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CorrelationsAnalysis;
					analysis.output.statistics.linearRegressionV2 = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_ONE_WAY_ANOVA_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.oneWayAnova = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_LOG_RANK_TEST_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as KaplanMeierAnalysisV2;
					analysis.output.statistics.logRank = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_TUKEY_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.tukey = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_PAIRED_TTEST_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as ComparePairedAnalysisV2;
					analysis.output.statistics.pairedTTest = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_PAIRED_WILCOXON_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as ComparePairedAnalysisV2;
					analysis.output.statistics.pairedWilcoxon = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_KRUSKAL_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.kruskal = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_MCNEMAR_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CrosstabAnalysisV2;
					analysis.output.statistics.mcNemar = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_TWO_WAY_MANOVA_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.twoWayManova = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_TWO_WAY_ANOVA_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.twoWayAnova = values;
				}

				draft.refetch = false;
			});
		}

		case ActionTypes.GET_ONE_WAY_MANOVA_STATISTICS_V2: {
			const { values, analysisId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as CompareNumericAnalysisV2;
					analysis.output.statistics.oneWayManova = values;
				}

				draft.refetch = false;
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case SnapshotsActionTypes.GET_SNAPSHOT: {
			const {
				projectId,
				snapshotId,
				data: { analysisList }
			} = action.payload;

			return produce(state, draft => {
				const { byId, bySnapshotId, byProjectId } = draft;

				bySnapshotId[snapshotId] = [];

				analysisList.forEach(analysis => {
					byId[analysis.id] = validateAnalysis(analysis);
					bySnapshotId[snapshotId].push(analysis.id);
					analysis.fetched = true;
					// map old snapshot structure to new one
					if (!analysis.options) {
						byId[analysis.id].options = {
							open: true,
							configPanel: {
								open: true,
								parameters: true,
								formatting: true
							}
						};
					}
					if (analysis.chartLegend !== undefined) {
						byId[analysis.id].options.chartLegend = analysis.chartLegend;
						delete byId[analysis.id].chartLegend;
					}

					if (analysis.histogram !== undefined) {
						byId[analysis.id].options.histogram = analysis.histogram;
						delete byId[analysis.id].histogram;
					}
					if (analysis.activeTab !== undefined) {
						byId[analysis.id].options.activeTab = analysis.activeTab;
						delete byId[analysis.id].activeTab;
					}
				});

				byProjectId[projectId].active = bySnapshotId[snapshotId];
				draft.refetch = false;
			});
		}

		case SnapshotsActionTypes.CREATE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			return produce(state, draft => {
				draft.bySnapshotId[snapshotId] = [...draft.byProjectId[projectId].active];
			});
		}

		case SnapshotsActionTypes.UPDATE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			return produce(state, draft => {
				draft.bySnapshotId[snapshotId] = draft.byProjectId[projectId].active;
			});
		}

		case SnapshotsActionTypes.DELETE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			return produce(state, draft => {
				const analysisIds = draft.bySnapshotId[snapshotId];
				const activeAnalyses = draft.byProjectId[projectId].active;

				draft.byProjectId[projectId].active = activeAnalyses.filter(
					id => !analysisIds.includes(id)
				);
				delete draft.bySnapshotId[snapshotId];
			});
		}

		case SnapshotsActionTypes.SET_ACTIVE_SNAPSHOT: {
			const { snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId, bySnapshotId } = draft;

				if (projectId && byProjectId[projectId] && bySnapshotId[snapshotId]) {
					byProjectId[projectId].active = bySnapshotId[snapshotId];
				}
			});
		}

		case SnapshotsActionTypes.CLEAR_SNAPSHOT: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId].active = [];
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		/**
		 * ==========
		 * 	JADBIO
		 * ==========
		 */

		case JADBioActionTypes.GET_JADBIO_ANALYSIS_RESULT: {
			const { jadBioOutput, analysisId } = action.payload;

			return produce(state, draft => {
				const analysis = draft.byId[analysisId] as JADBioAnalysis;

				analysis.output.dataset = {
					...analysis.output.dataset,
					...jadBioOutput
				};
			});
		}

		case JADBioActionTypes.GET_JADBIO_ANALYSIS_ERROR:
		case JADBioActionTypes.CLEAR_JADBIO_OUTPUT_MODEL: {
			const { model, analysisId } = action.payload;

			return produce(state, draft => {
				const analysis = draft.byId[analysisId] as JADBioAnalysis;

				analysis.output.dataset[model] = {
					summary: [],
					plots: null,
					progress: null,
					pauseProgress: false,
					stopProgress: false,
					jadBioAnalysisId: ''
				};
			});
		}

		case JADBioActionTypes.STOP_JADBIO_ANALYSIS_PROGRESS: {
			const { model, analysisId } = action.payload;

			return produce(state, draft => {
				const analysis = draft.byId[analysisId] as JADBioAnalysis;

				analysis.output.dataset[model].stopProgress = true;
			});
		}

		case JADBioActionTypes.INITIATE_JADBIO_DATASET: {
			const { analysisId, jadBioDatasetId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					(byId[analysisId] as JADBioAnalysis).input.jadBioDatasetId = jadBioDatasetId;
					(byId[analysisId] as JADBioAnalysis).input.datasetInitialized = true;
				}

				draft.refetch = false;
			});
		}

		case JADBioActionTypes.PAUSE_JADBIO_ANALYSIS_PROGRESS: {
			const { analysisId, model, pauseProgress } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as JADBioAnalysis;
					const jadBioOutput = analysis.output.dataset[model];
					if (jadBioOutput) {
						jadBioOutput.pauseProgress = pauseProgress;
					}
				}
			});
		}

		case JADBioActionTypes.SET_JADBIO_ANALYSIS_PROGRESS: {
			const { analysisId, progress, jadBioAnalysisId, model } = action.payload;

			return produce(state, draft => {
				const { byId } = draft;

				if (analysisId in byId) {
					const analysis = draft.byId[analysisId] as JADBioAnalysis;
					const jadBioOutput = analysis.output.dataset[model];
					if (jadBioOutput) {
						jadBioOutput.progress = progress;
						if (jadBioAnalysisId) jadBioOutput.jadBioAnalysisId = jadBioAnalysisId;
					}
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case EntriesActionTypes.CREATE_ENTRY: // ENTRIES
		case EntriesActionTypes.UPDATE_ENTRY:
		case EntriesActionTypes.DELETE_ENTRY:
		case EntriesActionTypes.UPDATE_SERIES_ENTRY:
		case EntriesActionTypes.DELETE_SERIES_ENTRY:
		case EntriesActionTypes.CREATE_SERIES_ENTRY:
		case FiltersActionTypes.UPDATE_FILTER: // FILTERS
		case FiltersActionTypes.DELETE_DATASET_FILTER:
		case FiltersActionTypes.DELETE_FILTERS:
		case FiltersActionTypes.REBUILD_FILTERS:
		case VariablesActionTypes.CREATE_VARIABLE: // VARIABLES
		case VariablesActionTypes.UPDATE_VARIABLE:
		case VariablesActionTypes.DELETE_VARIABLE:
		case VariablesActionTypes.DELETE_VARIABLE_CATEGORY_VALUE:
		case VariablesActionTypes.DELETE_VARIABLE_CATEGORY_VALUES:
		case ProjectsActionTypes.UPDATE_EXISTING_DATASET: // PROJECTS
		case ProjectsActionTypes.UPLOAD_TO_EXISTING_DATASET: {
			return produce(state, draft => {
				draft.refetch = true;
			});
		}

		// FILTERS
		// refetch just for category type filters
		case FiltersActionTypes.CREATE_FILTER: {
			const { filter } = action.payload;
			return produce(state, draft => {
				if (
					filter.filterType === VariableType.Category ||
					filter.filterType === VariableType.CategoryMultiple
				) {
					draft.refetch = true;
				}
			});
		}
		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		/**
		 * Reset project analysis
		 *
		 * [ ] [ ] [x] - ReplaceAll
		 */
		case ProjectsActionTypes.UPLOAD_PROJECT_DATASET: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId, byId, bySnapshotId } = draft;

				if (byProjectId[projectId]) {
					const { active } = byProjectId[projectId];

					active.forEach(id => delete byId[id]);

					Object.keys(bySnapshotId).forEach(key => {
						bySnapshotId[key] = bySnapshotId[key].filter(id => !active.includes(id));
					});

					byProjectId[projectId].active = [];
				}
			});
		}

		default: {
			return state;
		}
	}
};

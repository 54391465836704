import styled from 'styled-components';

import { Colors } from 'environment';

interface Props {
	className?: string;
}

export function Separator({ className }: Props) {
	return <Line className={className} />;
}

const Line = styled.div`
	height: 0.1rem;
	width: 100%;
	background-color: ${Colors.silver.lighter};
	margin: 2rem 0;
`;

import { DependenciesTableView, DependenciesTableViewData } from 'store/data/dependencies';

export function mapColumnNameToValue(title: string) {
	switch (title) {
		case DependenciesTableViewData.supplierVariableName:
			return DependenciesTableView.supplierVariableName;
		case DependenciesTableViewData.dependencyType:
			return DependenciesTableView.dependencyType;
		case DependenciesTableViewData.operator:
			return DependenciesTableView.operator;
		case DependenciesTableViewData.supplierValueCondition:
			return DependenciesTableView.supplierValueCondition;
		case DependenciesTableViewData.description:
			return DependenciesTableView.description;
		case DependenciesTableViewData.filteredValues:
			return DependenciesTableView.filteredValues;
		default:
			return DependenciesTableView.dependantVariableName;
	}
}

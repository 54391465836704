import styled, { css } from 'styled-components';

import { Slider as DefaultSlider } from 'components/UI/Slider';
import { Colors, MediaQueries } from 'environment';
import { transparentHex } from 'helpers/cssGenerators';

interface NotificationContainerProps {
	visible: boolean;
	top: number;
}

export const NotificationContainer = styled.div<NotificationContainerProps>`
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
	overflow: hidden;
	display: flex;
	flex-direction: column;
	right: 0;
	width: 37rem;
	position: absolute;
	box-shadow: 0 2px 4px 2px rgba(37, 37, 58, 0.1);
	height: calc(100vh - ${({ top }) => top}px);
	top: ${({ top }) => top}px;
	z-index: 1001;
	background-color: ${Colors.white};

	@media only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm} {
		width: 100%;
		top: 0;
		height: 100vh;
	}
`;

export const NotificationTitle = styled.div`
	padding: 0 2.4rem;
	display: flex;
	align-items: center;
	min-height: 6rem;
	border-bottom: 0.1rem solid ${transparentHex(Colors.text.caption, 0.1)};
`;

export const Slider = styled(DefaultSlider)`
	height: 100%;
	overflow-x: hidden;
`;

export const Slide = styled(DefaultSlider.Slide)`
	height: 100%;
	overflow-y: auto;
`;

export const IconContainer = styled.div<{ visible: boolean }>`
	line-height: 0;
	width: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s;

	${({ visible }) =>
		visible &&
		css`
			width: 2.6rem;
			opacity: 1;
			visibility: visible;
			margin-right: 1rem;
		`}
`;

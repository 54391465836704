import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Select } from 'components/UI/Interactables/Select';
import { MediaQueries } from 'environment';
import { stringAsBoolean } from 'helpers/generic';
import { MoreNavigation } from '../MoreNavigation';
import { Container, NormalTab } from '../Tab.style';
import { useEntriesErrors, useIsProjectOwner, usePermissions, useTranslation } from 'hooks/store';
import { useNavigation, useMatchProms, useRouteMatch } from 'hooks/navigation';
import { useMediaQuery } from 'hooks/utils';

export interface TabToRender {
	label: string;
	value: string;
	dataTestId: string;
	disabled?: boolean;
}

const formDesignerEnabled = stringAsBoolean(process.env.REACT_APP_USE_FORMS_DESIGNER!);
const templatesEnabled = stringAsBoolean(process.env.REACT_APP_USE_TEMPLATES!);
const projectDashboardEnabled = stringAsBoolean(
	process.env.REACT_APP_USE_PROJECT_ADMIN_DASHBOARD_PAGE!
);

export function ProjectTabs() {
	const { routes, navigate, promOrProject } = useNavigation();
	const { translate } = useTranslation();
	const { pathname } = useLocation();
	const { id: projectId = '' } = useParams<{ id: string }>();
	const matchProms = useMatchProms();

	const {
		hasVariablesAccess,
		hasVariablesWriteAccess,
		hasAnalysisAccess,
		hasDatasetReadAccess,
		hasDatasetReadAllAccess,
		hasModulesAccess
	} = usePermissions();

	const [{ errors: entriesErrors }] = useEntriesErrors();

	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const isProjectOwner = useIsProjectOwner();

	const projectDesignTabs = [
		{
			label: translate(dict => dict.projectTabs.variables),
			value: routes[promOrProject].variables.view(projectId)
		},
		{
			label: translate(dict => dict.projectTabs.templates),
			value: routes[promOrProject].templates(projectId)
		},
		{
			label: translate(dict => dict.projectTabs.dependencies),
			value: routes[promOrProject].dependencies(projectId)
		},
		{
			label: translate(dict => dict.projectTabs.forms),
			value: routes[promOrProject].forms.list(projectId)
		},
		{
			label: translate(dict => dict.projectTabs.statuses),
			value: routes[promOrProject].statuses(projectId)
		}
	];

	const activeProjectDesign = useRouteMatch(projectDesignTabs.map(t => t.value));

	function onTabClick(route: string) {
		navigate(route);
	}

	function isItemEnabled(item: string) {
		switch (item) {
			case translate(dict => dict.projectTabs.templates):
				return hasVariablesAccess && templatesEnabled;
			case translate(dict => dict.projectTabs.variables):
				return hasVariablesAccess;
			case translate(dict => dict.projectTabs.dependencies):
				return hasVariablesAccess && hasVariablesWriteAccess && entriesErrors === null;
			case translate(dict => dict.projectTabs.forms):
				return hasVariablesAccess && formDesignerEnabled;
			case translate(dict => dict.projectTabs.statuses):
				return hasVariablesAccess && isProjectOwner;
			case translate(dict => dict.projectTabs.analysis):
				return hasVariablesAccess && entriesErrors === null;
			case translate(dict => dict.accountUM.userDrawer.headers.projectDesign):
			case translate(dict => dict.iconTooltip.more):
				return false;

			default:
				return true;
		}
	}

	const tabsToRender: TabToRender[] = useMemo(() => {
		return [
			matchProms
				? [
						{
							label: translate(dict => dict.projectTabs.dashboard),
							value: routes.proms.dashboard(projectId),
							dataTestId: 'dashboard'
						},
						{
							label: translate(dict => dict.projectTabs.patients),
							value: routes.proms.patients(projectId),
							dataTestId: 'patients'
						}
				  ]
				: [],
			hasAnalysisAccess
				? {
						label: translate(dict => dict.projectTabs.analysis),
						value: routes[promOrProject].analysis(projectId),
						dataTestId: 'analysis'
				  }
				: [],
			hasDatasetReadAccess
				? {
						label: translate(dict => dict.projectTabs.dataset),
						value: routes[promOrProject].dataset.view(projectId),
						dataTestId: 'dataset'
				  }
				: [],
			hasDatasetReadAllAccess
				? {
						label: translate(dict => dict.projectTabs.documents),
						value: routes[promOrProject].documents.list(projectId),
						dataTestId: 'documents'
				  }
				: [],
			hasModulesAccess.collaborators
				? {
						label: translate(dict => dict.projectTabs.collaborators),
						value: routes[promOrProject].collaborators.view(projectId),
						dataTestId: 'collaborators'
				  }
				: [],
			isProjectOwner
				? {
						label: translate(dict => dict.projectTabs.roles),
						value: routes[promOrProject].roles(projectId),
						dataTestId: 'roles'
				  }
				: [],
			projectDashboardEnabled
				? {
						label: translate(dict => dict.projectTabs.dashboard),
						value: routes.projects.projectDashboard.view(projectId),
						dataTestId: 'Dashboard'
				  }
				: []
		].flat();
	}, [
		matchProms,
		hasAnalysisAccess,
		hasDatasetReadAccess,
		hasDatasetReadAllAccess,
		hasModulesAccess
	]);

	const mobileProjectTabs = [
		{
			label: translate(dict => dict.accountUM.userDrawer.headers.projectDesign)
		},
		...projectDesignTabs,
		{
			label: translate(dict => dict.iconTooltip.more)
		},
		...tabsToRender
	];

	return (
		<Container>
			{hasModulesAccess.projectDesign && (
				<NormalTab>
					<Select
						title={
							isPhone
								? translate(dict => dict.accountUM.userDrawer.headers.profile)
								: translate(dict => dict.accountUM.userDrawer.headers.projectDesign)
						}
						className="dropdown-tab"
						items={isPhone ? mobileProjectTabs : projectDesignTabs}
						type={s => s.Tab(activeProjectDesign)}
						disableItem={item => !isItemEnabled(item.label)}
						activeItem={item => pathname === item.value}
						onSelect={item => onTabClick(item.value as string)}
						dataTestId="project-design-dropdown"
					/>
				</NormalTab>
			)}

			{!isPhone && (
				<MoreNavigation
					tabsToRender={tabsToRender}
					onTabClick={onTabClick}
					isTabDisabled={label => !isItemEnabled(label)}
				/>
			)}
		</Container>
	);
}

import { useCallback, useMemo } from 'react';

import { VariableType } from 'types/data/variables/constants';
import {
	ElementMenu,
	HoverableArea,
	InputWrapper,
	ToolTipWrapper,
	VariableHeader
} from 'components/Forms';
import { ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD } from 'consts';
import { VariableFilteringMap } from 'store/data/entries';
import { BooleanMap, ElementType, OrientationType } from 'types/index';
import { Revision, RevisionChanges } from 'store/data/revisions';
import { CheckboxRadioGroupInput } from './CheckboxRadioGroupInput';
import { DropdownInput } from './DropdownInput';
import { FormElementGeneralProps } from '../FormVariable';
import { FormInput } from '../../FormInput';
import { CategoryWrapper } from './FormVariableCategory.style';

import { buildVariableCategoriesMap } from 'helpers/variables';
import { useForm, useFormDesignerDrawerContext, useRemoveFormElement } from 'hooks/store';
import { FormVariableSettings } from '../FormVariableSettings';
import { FormElementDisplayType } from 'store/data/forms';

interface Props extends FormElementGeneralProps {
	openCustomsMap: BooleanMap;
	variableFilteringMap: VariableFilteringMap;
	omitName?: boolean;
	revision?: Revision;
	isRevisionSelected?: boolean;
}

export function FormVariableCategory({
	element,
	variable,
	value,
	formContext,
	borderError,
	tooltipComponent,
	openCustomsMap,
	variableFilteringMap,
	disabled,
	readOnly,
	revision,
	isRevisionSelected,
	canBeRemoved,
	isInGroup,
	usedInFormDesigner,
	omitName,
	dataTestIdEntryNumber
}: Props) {
	const { elementId, elementType, orientation, displayType } = element;
	const { name, categories, type, fixedCategories, obligatory: required } = variable;

	const hasMultipleValues = type === VariableType.CategoryMultiple;

	const allowCreate = !fixedCategories;

	const filteredCategories = variableFilteringMap[name];
	const computedCategories = filteredCategories ?? categories.map(c => c.value);

	const categoriesMap = buildVariableCategoriesMap(categories);

	const categoriesWithLabelAndValue = useMemo(() => {
		return computedCategories.map(categoryValue => ({
			label:
				displayType === FormElementDisplayType.LABELS && fixedCategories
					? categoriesMap[categoryValue]?.label
					: categoriesMap[categoryValue]?.value ?? categoryValue,
			value: categoriesMap[categoryValue]?.value || categoryValue,
			...(categoriesMap[categoryValue]?.description.length > 0 && {
				tooltip: categoriesMap[categoryValue].description
			})
		}));
	}, [computedCategories, displayType]);

	const hasChanges = revision ? !!revision.changes.variableNames.includes(name) : false;

	const newRevisionChange: string[] = [];
	let revisionChange: RevisionChanges | undefined = undefined;

	if (hasChanges) {
		revisionChange = revision?.changes.list.find(change => change.variableName === name);
		let check = false;
		if (Array.isArray(revisionChange?.to)) {
			revisionChange?.to.forEach(value => {
				if (
					!categoriesWithLabelAndValue.filter(category => category.label === value).length
				) {
					check = true;
					categoriesWithLabelAndValue.push({
						label: value,
						value: value
					});
					newRevisionChange.push(value);
				}
			});
		} else if (
			revisionChange?.to &&
			!categoriesWithLabelAndValue.filter(category => category.label === revisionChange?.to)
				.length
		) {
			check = true;
			categoriesWithLabelAndValue.push({
				label: revisionChange?.to as string,
				value: revisionChange?.to as string
			});
			newRevisionChange.push(revisionChange?.to as string);
		}
		if (!check) {
			revisionChange = undefined;
		}
	}

	const removeFormElement = useRemoveFormElement();

	const isVertical = orientation === OrientationType.Vertical;
	const isDropdown = elementType === ElementType.Dropdown;
	const isRadioGroup = elementType === ElementType.Radiobuttons;
	const isCheckboxGroup = elementType === ElementType.Checkboxes;
	// const isSlider = elementType === ElementType.Slider;

	const forceDropdown = categories.length > ENTRY_FIELD_FORCE_DROPDOWN_THRESHOLD;

	const { onOpen, elementId: selectedElementId } = useFormDesignerDrawerContext();
	const [{ data: form }] = useForm();

	const onOpenDrawer = useCallback(() => {
		onOpen(elementId);
	}, [element]);

	const onRemoveElement = useCallback(() => {
		removeFormElement({ elementId });
	}, [elementId]);

	const isSelected = form?.elements[selectedElementId]?.variableRef === variable.name;

	return (
		<HoverableArea
			fullWidth
			isInGroup={isInGroup}
			usedInFormDesigner={usedInFormDesigner}
			activeHover={isSelected}
		>
			<ElementMenu usedInFormDesigner={usedInFormDesigner}>
				<VariableHeader>
					<FormInput
						element={element}
						required={required}
						disabled={disabled}
						usedInFormDesigner={usedInFormDesigner}
						isCategoryVariable={(isCheckboxGroup || isRadioGroup) && !forceDropdown}
					/>
					{tooltipComponent && <ToolTipWrapper>{tooltipComponent}</ToolTipWrapper>}
				</VariableHeader>

				<CategoryWrapper usedInFormDesigner={usedInFormDesigner}>
					{/* DROPDOWN WITH SELECT */}
					{(isDropdown || forceDropdown) && (
						<InputWrapper usedInFormDesigner={usedInFormDesigner}>
							<DropdownInput
								name={name}
								categories={computedCategories}
								options={categoriesWithLabelAndValue}
								displayType={displayType}
								disabled={disabled}
								isItemDisabled={value =>
									!!isRevisionSelected && newRevisionChange.includes(value.label)
								}
								readOnly={readOnly}
								required={required}
								allowCreate={allowCreate}
								hasMultipleValues={hasMultipleValues}
								borderError={borderError}
								value={value}
								formContext={formContext}
							/>
						</InputWrapper>
					)}

					{/* CHECKBOX GROUP / RADIO GROUP */}
					{(isCheckboxGroup || isRadioGroup) && !forceDropdown && (
						<CheckboxRadioGroupInput
							dataTestIdEntryNumber={dataTestIdEntryNumber}
							name={omitName ? '' : name}
							options={categoriesWithLabelAndValue}
							disabled={disabled}
							readOnly={readOnly}
							required={required}
							isItemDisabled={value =>
								newRevisionChange.includes(value) && !!isRevisionSelected
							}
							allowCreate={allowCreate}
							initialCustomEnabled={openCustomsMap[name]}
							isVertical={isVertical}
							isRadioGroup={isRadioGroup}
							borderError={borderError}
							value={value}
							formContext={formContext}
						/>
					)}
				</CategoryWrapper>
			</ElementMenu>

			{usedInFormDesigner && (
				<FormVariableSettings
					variableName={variable.name}
					onEdit={onOpenDrawer}
					{...(canBeRemoved ? { onDelete: onRemoveElement } : {})}
				/>
			)}
		</HoverableArea>
	);
}

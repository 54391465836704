import { useEffect } from 'react';
import { PatientEditEntry } from 'components/Patient';
import { StorageKeys } from 'types/index';
import { useNavigation } from 'hooks/navigation';
import { useFormId, useProjectId, usePatientLoginParams, usePatientLoggedIn } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

export function PatientEditEntryPage() {
	const { replace, routes } = useNavigation();

	const [, setFormId] = useFormId();
	const [projectId, setProjectId] = useProjectId();
	const [params] = usePatientLoginParams();
	const [{ data: isPatientLoggedIn }] = usePatientLoggedIn();

	// logout patient on tab close or reload
	useEffectOnce(() => {
		window.addEventListener('unload', () =>
			localStorage.removeItem(StorageKeys.PatientLoginCompleted)
		);
	});

	useEffect(() => {
		if (params && isPatientLoggedIn) {
			setProjectId(params.projectId);
			if (params.formId) setFormId(params.formId);
		}

		if (!isPatientLoggedIn) replace(routes.patientLogin);
	}, [params, isPatientLoggedIn]);

	// ENSURE FORM IS RENDERED ONLY AFTER PROJECT ID IS SET
	if (!(params && isPatientLoggedIn && projectId)) return null;

	return <PatientEditEntry params={params} />;
}

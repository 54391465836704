import { useEffect, useRef, useState } from 'react';
import { Breakpoints } from 'components/UI/Grid';
import { Colors, MediaQueries, Svgs } from 'environment';
import { InputType } from 'types/index';
import { Button, Container, Input } from './SearchInput.style';
import { Icon } from 'components/UI/Icons';
import { useWindowSize } from 'hooks/ui';
import { useMediaQuery, useDebounce, useOutsideClick, useKeyPress } from 'hooks/utils';
import { InfoTooltip } from '../../Interactables/InfoTooltip/InfoTooltip';

interface Props {
	term: string;
	placeholder?: string;
	disabled?: boolean;
	className?: string;
	usedInHeader?: boolean;
	autoFocus?: boolean;
	infoTooltipOptions?: {
		tooltipText: string;
		buttonText: string;
		buttonHandler: () => void;
	};
	onChangeTerm: (term: string) => void;
}

export function SearchInput({
	term,
	placeholder,
	disabled,
	className,
	usedInHeader,
	autoFocus,
	infoTooltipOptions,
	onChangeTerm
}: Props) {
	const [value, setValue] = useState(term);
	const [focused, setFocused] = useState(false);
	const [isInputVisible, setIsInputVisible] = useState(false);
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.lg}`
	);

	const { width } = useWindowSize();

	useDebounce(() => {
		if (term !== value) !disabled && onChangeTerm(value);
	}, [value, disabled]);

	useEffect(() => {
		if (term !== value) setValue(term);
	}, [term]);

	const isInputValid = value.length > 0;
	const showOnlySearchBtn = usedInHeader && isMobileDevice && !isInputVisible;

	const tabletBreakPoint = width < parseInt(Breakpoints.TABLET_PORTRAIT.replace('px', ''), 10);

	const containerRef = useRef<HTMLInputElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const leftHeaderSide = document.querySelector(
		'.header-navigation__left-component'
	) as HTMLElement | null;

	useOutsideClick(handleFocusOut, [containerRef]);

	useKeyPress(
		{ onEscapeKeyPress: handleFocusOut },
		{ noModalsOpened: true, listen: tabletBreakPoint && focused }
	);

	function handleFocusOut() {
		setFocused(false);
		setIsInputVisible(false);

		if (leftHeaderSide) leftHeaderSide.style.display = 'block';
	}

	function clearValue() {
		if (disabled) return;

		setValue('');
		onChangeTerm('');
	}

	if (showOnlySearchBtn) {
		return (
			<Icon
				svg={Svgs.Search}
				size={s => s.l}
				variant={v => v.button}
				onClick={() => {
					setIsInputVisible(true);
					setFocused(true);

					if (leftHeaderSide) leftHeaderSide.style.display = 'none';
				}}
			/>
		);
	}

	return (
		<Container
			ref={containerRef}
			focused={focused}
			disabled={disabled}
			className={className}
			usedInHeader={usedInHeader}
		>
			<Icon svg={Svgs.Search} size={s => s.m} marginOffset={{ right: 0.8 }} disabled />
			<Input
				data-testid="search-input"
				id="search"
				ref={inputRef}
				onChange={e => !disabled && setValue(e.target.value)}
				value={value}
				placeholder={placeholder}
				type={InputType.Text}
				autoFocus={autoFocus}
				onFocus={() => setFocused(true)}
				onBlur={e => setValue(e.target.value.trim())}
			/>

			{!disabled && (
				<>
					<Icon
						{...(!isInputValid && { style: { visibility: 'hidden' } })}
						svg={Svgs.Clear}
						size={s => s.m}
						colors={{ color: Colors.text.disabled }}
						marginOffset={{ left: 0.8 }}
						onClick={clearValue}
					/>

					{infoTooltipOptions && (
						<InfoTooltip
							text={infoTooltipOptions.tooltipText}
							marginOffset={{ left: 0.8 }}
							iconColor={Colors.lightPurple}
							iconSize={s => s.m}
							zIndex={100}
							customStyles={{
								display: 'block',
								color: Colors.white,
								fontSize: 12,
								fontFamily: 'DMSans',
								maxWidth: '18.2rem'
							}}
							renderContext={containerRef.current ? containerRef.current : undefined}
							renderChildren={
								<div
									style={{
										padding: '0.8rem'
									}}
								>
									<Button
										onClick={infoTooltipOptions.buttonHandler}
										title={infoTooltipOptions.buttonText}
										variant={v => v.secondary}
									/>
								</div>
							}
							iconVisible
						/>
					)}
				</>
			)}
		</Container>
	);
}

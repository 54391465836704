import Papa from 'papaparse';
import axios from 'axios';

import { Dictionary } from 'environment';
import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload, ThunkDispatch } from 'store/types';
import { FileMimeType } from 'types/index';

import {
	GetProjectFilesAction,
	ActionTypes,
	ViewProjectFileAction,
	SetProjectFilesSearchTermAction,
	SetProjectFilesFilterAction,
	SetProjectFilesDateFilterAction,
	ResetProjectFilesFilterAction,
	ResetAllProjectFilesFiltersAction,
	GetCsvXlsFromS3Action,
	ResetCsvTableAction
} from './types';

export const uploadProjectFile =
	(fileName: string, fileSize: number, fileType: string, binaryData: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPLOAD_PROJECT_FILE,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				const data = await context.api.data
					.documents()
					.getProjectFileUploadUrl(projectId, fileName);

				if (data && data.uploadURL) {
					const directUploadComplete = await context.api.data
						.documents()
						.directUploadProjectFile(binaryData, fileSize, fileType, data.uploadURL);
					if (directUploadComplete) {
						const confirmUpload = await context.api.data
							.documents()
							.confirmUploadedFile(projectId, data.fileId);

						if (confirmUpload) {
							// REMOVE DUPLICATE ?! hopefully just a temporary solution
							// for weird api behaviour

							const removeDuplicate = await context.api.data
								.documents()
								.removeDocument(projectId, data.fileId);

							if (removeDuplicate) await dispatch(getProjectFiles());
						}
					}
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const getProjectFilesAction = (
	payload: ActionPayload<GetProjectFilesAction>
): GetProjectFilesAction => ({
	type: ActionTypes.GET_PROJECT_FILES,
	payload
});

export const getProjectFiles = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_PROJECT_FILES,
		dispatch
	});

	const { projectId } = getState().data.projects;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const projectFiles = await context.api.data.documents().getProjectFiles(projectId);

			if (projectFiles) dispatch(getProjectFilesAction({ files: projectFiles }));
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

export const removeDocument =
	(fileId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.REMOVE_DOCUMENT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				const documentRemoved = await context.api.data
					.documents()
					.removeDocument(projectId, fileId);

				if (documentRemoved) await dispatch(getProjectFiles());
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const renameDocument =
	(fileId: string, newLabel: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.RENAME_DOCUMENT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				const documentRenamed = await context.api.data
					.documents()
					.renameDocument(projectId, fileId, newLabel);

				if (documentRenamed) await dispatch(getProjectFiles());
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const parseCsvFromS3Action = (
	payload: ActionPayload<GetCsvXlsFromS3Action>
): GetCsvXlsFromS3Action => ({
	type: ActionTypes.GET_CSV_XLS_FROM_S3,
	payload
});

function parseCsvData(csvAsText: string, dispatch: ThunkDispatch) {
	const activity = createActivity({
		type: ActionTypes.GET_CSV_XLS_FROM_S3,
		dispatch
	});

	const parseResult = Papa.parse<string[]>(csvAsText, {
		skipEmptyLines: true
	});

	if (parseResult.errors.length > 0) {
		activity.error({
			error: 'CSV parse failed. Please check the format of the imported file.'
		});
	} else {
		dispatch(parseCsvFromS3Action({ fileData: parseResult.data }));
	}
}

export const parseCsvFromS3 =
	(projectId: string, s3Url: string): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_CSV_XLS_FROM_S3,
			dispatch
		});

		try {
			activity.begin();

			await fetch(s3Url)
				.then(res => res.blob())
				.then(blob => {
					if (blob.type === FileMimeType.TXT) {
						blob.text().then(result => {
							dispatch(
								parseCsvFromS3Action({
									fileData: result as unknown as string[][] // TODO: @Acea, please check types here and data flow
								})
							);
						});
					} else if (blob.type === FileMimeType.CSV) {
						blob.text().then(result => {
							parseCsvData(result, dispatch);
						});
					} else {
						const reader = new FileReader();
						reader.readAsBinaryString(blob);

						reader.onload = async () => {
							const readerResult = reader.result as string;

							let readerResultBase64 = '';

							try {
								readerResultBase64 = btoa(readerResult);
							} catch (e: any) {
								activity.error({ error: 'File is corrupted' });
							} finally {
								try {
									const source = axios.CancelToken.source();

									const response = await context.api.data
										.projects()
										.convertXlsToCsv({
											body: {
												projectId: parseInt(projectId, 10),
												Base64EncodedXlsx: readerResultBase64
											},
											config: {
												cancelToken: source.token
											}
										});

									if (response) parseCsvData(response, dispatch);
								} catch (e: any) {
									activity.error({
										error: Dictionary.errors.api.projects.couldNotConvert
									});
								}
							}
						};
					}
				});
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setFileToViewAction = (
	payload: ActionPayload<ViewProjectFileAction>
): ViewProjectFileAction => ({
	type: ActionTypes.VIEW_PROJECT_FILE,
	payload
});

export const setProjectFilesSearchTerm = (
	payload: ActionPayload<SetProjectFilesSearchTermAction>
): SetProjectFilesSearchTermAction => ({
	type: ActionTypes.PROJECT_FILES_SET_SEARCH_TERM,
	payload
});

export const resetCsvTable = (): ResetCsvTableAction => ({
	type: ActionTypes.RESET_CSV_TABLE
});

// ================================= FILTERS =================================

export const setProjectFilesFilter = (
	payload: ActionPayload<SetProjectFilesFilterAction>
): SetProjectFilesFilterAction => ({
	type: ActionTypes.PROJECT_FILES_SET_FILTER,
	payload
});

export const setProjectFilesDateFilter = (
	payload: ActionPayload<SetProjectFilesDateFilterAction>
): SetProjectFilesDateFilterAction => ({
	type: ActionTypes.PROJECT_FILES_SET_DATE_FILTER,
	payload
});

export const resetProjectFilesFilter = (
	payload: ActionPayload<ResetProjectFilesFilterAction>
): ResetProjectFilesFilterAction => ({
	type: ActionTypes.PROJECT_FILES_RESET_FILTER,
	payload
});

export const resetAllProjectFilesFilters = (): ResetAllProjectFilesFiltersAction => ({
	type: ActionTypes.PROJECT_FILES_RESET_ALL_FILTERS
});

// =============================== END FILTERS ===============================

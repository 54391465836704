import { State } from './types';

const initialState: State = {
	byId: {},
	byEntryId: {},
	metadata: {
		revisionId: null,
		setRevisionId: null,
		currentChange: 1,
		isClicked: false
	},
	currentRevision: null
};

export default initialState;

import { useDispatch, useSelector } from 'hooks/utils';
import {
	setCentraliseEntryData,
	SetCentraliseEntryDataAction,
	selectCentraliseEntryData
} from 'store/data/patients';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

interface Options {
	initial?: boolean;
}

export function useDistributionCentraliseEntryData(
	props?: Options
): LocalOperationResult<
	boolean | undefined,
	(payload: ActionPayload<SetCentraliseEntryDataAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state =>
		selectCentraliseEntryData(state.data.patients, props?.initial)
	);

	function handler(payload: ActionPayload<SetCentraliseEntryDataAction>) {
		dispatch(setCentraliseEntryData(payload));
	}

	return [data, handler];
}

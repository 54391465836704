import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { LedidiStatusCode } from 'types/index';

import { Steps as AddUserSteps } from './AddSubscriptionUserModal';
import { useTranslation } from 'hooks/store';

type AddSubscriptionUserViewType = AddUserSteps | LedidiStatusCode | null;

interface ConfirmationAddUserView {
	type: AddSubscriptionUserViewType;
}

/**
 *
 * @param type Checked against strings "requestTransfer", "userAlreadyExists", "newUserAdded", "userInvited", "userInTransfer", "userIsAlreadyOwner"
 * @returns
 */
export function AddSubscriptionUserView({ type }: ConfirmationAddUserView) {
	const { translate } = useTranslation();

	return (
		<Flex column align={a => a.center}>
			{type === AddUserSteps.RequestTransfer && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(dict => dict.accountUM.addSubscriptionUser.transferUserTitle)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph multiline alignCenter>
						{translate(dict => dict.accountUM.addSubscriptionUser.transferUserMessage)}
					</Typography.Paragraph>
				</>
			)}

			{type === AddUserSteps.AlreadyExists && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.userAlreadyExistsTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph multiline alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.userAlreadyExistsMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === AddUserSteps.AlreadyExistsIsOwner && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.userAlreadyExistsIsOwnerTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph multiline alignCenter>
						{translate(
							dict =>
								dict.accountUM.addSubscriptionUser.userAlreadyExistsIsOwnerMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === AddUserSteps.AlreadyExistsIsPending && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict =>
								dict.accountUM.addSubscriptionUser.userAlreadyExistsIsPendingTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph multiline alignCenter>
						{translate(
							dict =>
								dict.accountUM.addSubscriptionUser.userAlreadyExistsIsPendingMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === LedidiStatusCode.SubscriptionNewUserAdded && (
				<>
					<Svgs.EmptyStatesCollaborators style={{ minHeight: 240 }} />
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.confirmationAddedTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter multiline>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.confirmationAddedMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === LedidiStatusCode.SubscriptionUserInvited && (
				<>
					<Svgs.EmptyStatesCollaborators style={{ minHeight: 240 }} />
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.confirmationInvitedTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter multiline>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.confirmationInvitedMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === LedidiStatusCode.SubscriptionUserInTransfer && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.alreadyInTransferTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter multiline>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.alreadyInTransferMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === LedidiStatusCode.SubscriptionUserIsAlreadyOwner && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(dict => dict.accountUM.addSubscriptionUser.alreadyOwnerTitle)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter multiline>
						{translate(dict => dict.accountUM.addSubscriptionUser.alreadyOwnerMessage)}
					</Typography.Paragraph>
				</>
			)}

			{type === LedidiStatusCode.UserIsChangingPlan && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.userIsChangingPlanTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter multiline>
						{translate(
							dict => dict.accountUM.addSubscriptionUser.userIsChangingPlanMessage
						)}
					</Typography.Paragraph>
				</>
			)}

			{type === LedidiStatusCode.UserInEnterpriseSubscription && (
				<>
					<Spacer size={s => s.m} />
					<Typography.H3 alignCenter>
						{translate(
							dict =>
								dict.accountUM.addSubscriptionUser
									.userIsInEnterpriseSubscriptionTitle
						)}
					</Typography.H3>
					<Spacer size={s => s.s} />
					<Typography.Paragraph alignCenter multiline>
						{translate(
							dict =>
								dict.accountUM.addSubscriptionUser
									.userIsInEnterpriseSubscriptionMessage
						)}
					</Typography.Paragraph>
				</>
			)}
		</Flex>
	);
}

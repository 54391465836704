import { ROUTES } from 'types/navigation';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { UserCard } from 'components/UI/UserCard';
import { MediaQueries, Svgs } from 'environment';
import { stringAsBoolean } from 'helpers/generic';
import { openNewTabSubscriptionPage } from 'helpers/navigation';
import { get } from 'lodash';
import { DrawerChoices, StorageKeys } from 'types/index';
import {
	Container,
	Divider,
	FlexSpaceBetween,
	MenuOption,
	UserDropDownChoicesMenu
} from './Profile.style';
import { Icon } from 'components/UI/Icons';
import { useNavigation, useRouteMatch } from 'hooks/navigation';
import { useTranslation, useAccount, useSubscriptionRules } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

const USE_WORK_INFORMATION = stringAsBoolean(process.env.REACT_APP_USE_WORK_INFORMATION!);
const SHOW_PROMS_ROUTE = stringAsBoolean(process.env.REACT_APP_SHOW_PROMS_ROUTE!);
const ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ADMIN_PATH_ROOT}`;
const ENTERPRISE_ADMIN_PATH_ROOT = `/${process.env.REACT_APP_ENTERPRISE_ADMIN_PATH_ROOT}`;
interface Props {
	onChangeStepTo: (step: DrawerChoices) => void;
	onLogout: () => void;
	closeDrawer: () => void;
}

export function Profile({ onChangeStepTo, onLogout, closeDrawer }: Props) {
	const { translate } = useTranslation();

	const { routes, navigate } = useNavigation();
	const isUserAdmin = !!localStorage.getItem(StorageKeys.IsUserAdmin);

	const { showEnterpriseAdminRoute } = useSubscriptionRules();

	const isOnPromsListRoute = useRouteMatch(ROUTES.Proms);
	const isOnProjectsListRoute = useRouteMatch(ROUTES.Projects);
	const isOnAdminUsersRoute = useRouteMatch(ADMIN_PATH_ROOT + ROUTES.AdminUsers);
	const isOnTemplateRolesRoute = useRouteMatch(ROUTES.TemplateRoles);
	const isOnSubscriptionEnterprisePage = useRouteMatch(
		ENTERPRISE_ADMIN_PATH_ROOT + ROUTES.EnterpriseAdminSubscription
	);

	const [
		{
			data: { details }
		}
	] = useAccount();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	/**
	 * Route to navigate to account management page
	 */
	function manageAccountClicked() {
		if (showEnterpriseAdminRoute) {
			if (isOnSubscriptionEnterprisePage) {
				return closeDrawer();
			}
			navigate(routes.enterpriseAdmin.subscription);
		} else openNewTabSubscriptionPage();
	}

	function handleOptionSelect(route: string) {
		navigate(route);
		closeDrawer();
	}

	const drawerSections = [
		isMobileDevice && [
			{
				label: translate(dict => dict.accountUM.userDrawer.views.projects),
				icon: <Icon svg={Svgs.MenuProjects} />,
				active: isOnProjectsListRoute,
				onClick: () => handleOptionSelect(routes.projects.list)
			},

			SHOW_PROMS_ROUTE && {
				label: translate(dict => dict.accountUM.userDrawer.views.proms),
				icon: <Icon svg={Svgs.MenuProms} />,
				active: isOnPromsListRoute,
				onClick: () => handleOptionSelect(routes.proms.list)
			},
			{
				label: translate(dict => dict.accountUM.userDrawer.views.rolesTemplate),
				icon: <Icon svg={Svgs.Package} />,
				active: isOnTemplateRolesRoute,
				onClick: () => handleOptionSelect(routes.templateRoles)
			},

			isUserAdmin && {
				label: translate(dict => dict.accountUM.userDrawer.views.userAdmin),
				icon: <Icon svg={Svgs.MenuProms} />,
				active: isOnAdminUsersRoute,
				onClick: () => handleOptionSelect(routes.admin.view)
			}
		],
		[
			{
				label: translate(dict => dict.accountUM.userDrawer.views.subscriptionManagement),
				icon: <Icon svg={Svgs.CreditCard} />,
				endIcon: <Svgs.ExternalLink />,
				onClick: manageAccountClicked
			}
		],
		[
			{
				label: translate(dict => dict.accountUM.userDrawer.views.accountInformation),
				icon: <Icon svg={Svgs.Information} />,
				onClick: () => onChangeStepTo(DrawerChoices.accountInformation),
				endIcon: <Svgs.ChevronRight />
			},
			USE_WORK_INFORMATION && {
				label: translate(dict => dict.accountUM.userDrawer.views.workInformation),
				icon: <Icon svg={Svgs.Workplace} />,
				onClick: () => onChangeStepTo(DrawerChoices.workInformation),
				endIcon: <Svgs.ChevronRight />
			},
			{
				label: translate(dict => dict.accountUM.userDrawer.views.personalSettings),
				icon: <Icon svg={Svgs.Globe} />,
				onClick: () => onChangeStepTo(DrawerChoices.personalSettings),
				endIcon: <Svgs.ChevronRight />
			},
			{
				label: translate(dict => dict.accountUM.userDrawer.views.security),
				icon: <Icon svg={Svgs.Secure} />,
				onClick: () => onChangeStepTo(DrawerChoices.security),
				endIcon: <Svgs.ChevronRight />
			},
			isMobileDevice && {
				label: translate(dict => dict.accountUM.userDrawer.views.help),
				icon: <Icon svg={Svgs.Help} />,
				onClick: () => handleOptionSelect(routes.help),
				endIcon: <Svgs.ExternalLink />
			}
		],
		[
			{
				label: translate(dict => dict.buttons.logOut),
				icon: <Icon svg={Svgs.LogOut} />,
				onClick: onLogout
			}
		]
	];

	return (
		<Container>
			<div>
				{details && (
					<Flex justify={j => j.start} paddingOffset={{ x: 2.4 }}>
						<UserCard.Profile
							userId={details.userid}
							userData={{
								userFirstName: details.userFirstName,
								userSirName: details.userSirName,
								department: details.department,
								position: details.position,
								imageString: details.imageString,
								imageURL: details.imageURL
							}}
							isMobileDevice={isMobileDevice}
						/>
					</Flex>
				)}

				{isMobileDevice && <Divider />}
			</div>

			<div>
				{drawerSections.map(
					(items, id) =>
						items && (
							<FlexSpaceBetween key={id}>
								<UserDropDownChoicesMenu>
									{items.map(
										item =>
											typeof item !== 'boolean' && (
												<MenuOption
													onClick={item.onClick}
													key={item.label}
													active={get(item, 'active')}
												>
													<Flex fullWidth align={a => a.end}>
														{item.icon}
														<Typography.Paragraph
															marginOffset={{
																left: 1.6
															}}
														>
															{item.label}
														</Typography.Paragraph>
													</Flex>
													{get(item, 'endIcon')}
												</MenuOption>
											)
									)}
									{id !== drawerSections.length - 1 && <Divider />}
								</UserDropDownChoicesMenu>
							</FlexSpaceBetween>
						)
				)}
			</div>
		</Container>
	);
}

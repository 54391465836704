import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, submitOneTimePassword } from 'store/auth';

interface Handlers {
	handler: (code: string) => void;
	resetError: () => void;
}

export function useSubmitOneTimePassword(): Operation<Handlers> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.SUBMIT_ONE_TIME_PASSWORD);

	function handler(code: string) {
		dispatch(submitOneTimePassword(code));
	}

	const handlers = {
		handler,
		resetError
	};

	return [{ loading, error }, handlers];
}

import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import {
	moveVariableBetweenSets,
	MoveVariableBetweenSetsAction,
	ActionTypes
} from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveVariableBetweenSets(activityPayload?: {
	variableName: string;
}): Operation<(payload: ActionPayload<MoveVariableBetweenSetsAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(
		ActionTypes.MOVE_VARIABLE_BETWEEN_SETS,
		activityPayload
	);

	function handler(payload: ActionPayload<MoveVariableBetweenSetsAction>) {
		dispatch(moveVariableBetweenSets(payload));
	}

	return [{ loading, error }, handler];
}

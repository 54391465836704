import { AdminAccount } from 'api/account/admin';
import { useFilterBySearchTerm } from 'hooks/store/utils/useFilterBySearchTerm';
import { UserLicenceLabels } from 'store/account/subscription';
import { FilterByTypeComponent, GenericMap } from 'types/index';
import { utils, writeFileXLSX } from 'xlsx';
import { useAdminUsersList } from './useAdminUsersList';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'consts';

export function useExportUsersToXLSX() {
	const [{ data: users }] = useAdminUsersList();
	const filteredUsersBySearchTerm = useFilterBySearchTerm(
		users,
		FilterByTypeComponent.AdminUsers
	) as AdminAccount[];

	function exportUsersAsXLSX() {
		const computedDataForXSLX: GenericMap<string>[] = [];

		filteredUsersBySearchTerm.forEach(user => {
			const computedUserForXlsx: GenericMap<string> = {
				Name:
					user.userFirstName || user.userSirName
						? `${user.userFirstName}${user.userFirstName ? ' ' : ''}${user.userSirName}`
						: user.userid,
				Username: user.userid,
				Email: user.emailAddress,
				Phone: user.phoneNumber,
				Country: user.country,
				City: user.city,
				Position: user.position,
				Department: user.department,
				Workplace: user.workplace,
				'Created date': user.cognitoCreatedDate,
				'Cognito status': user.cognitoUserStatus,
				Licence: UserLicenceLabels[user.licenceModel],
				'Temporary Password Validity Days':
					user.userCredentials?.temporaryPasswordValidityDays.toString() ?? '',
				'Force Change Password': user.userCredentials?.forceChangePassword.toString() ?? '',
				'Phone Number Verified': user.userCredentials?.phoneNumberVerified.toString() ?? '',
				'Subscription Id': user.subscriptionId ?? '',
				Language: user.userPreferences?.chosenLanguageCode ?? '',
				'Date time format': user.userPreferences?.dateTimeFormat ?? '',
				Active: user.status
			};

			computedDataForXSLX.push(computedUserForXlsx);
		});
		const sheet = utils.json_to_sheet(computedDataForXSLX);
		const workbook = utils.book_new();
		const dateToday = format(new Date(), DATE_FORMAT);
		utils.book_append_sheet(workbook, sheet, 'Ledidi Users');
		writeFileXLSX(workbook, `Ledidi_Users_Export_${dateToday}.xlsx`);
	}

	return exportUsersAsXLSX;
}

import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { selectSeriesTableVisibleColumns, setSeriesTableVisibleColumns } from 'store/data/series';

type HandlerInput = Parameters<typeof setSeriesTableVisibleColumns>[0]['columnNames'];

export function useSeriesTableVisibleColumns(): LocalOperationResult<
	string[],
	(columnNames: HandlerInput) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectSeriesTableVisibleColumns(state.data.series));

	function handler(columnNames: HandlerInput) {
		dispatch(setSeriesTableVisibleColumns({ columnNames }));
	}

	return [data, handler];
}

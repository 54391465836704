import styled, { css } from 'styled-components';

import { Typography } from 'components/UI/Typography';

export const AddVariableIcon = styled.div`
	visibility: hidden;
	position: absolute;
	right: 1rem;
	top: 50%;
	transform: translateY(-50%);

	:hover {
		.times-variable-used {
			visibility: visible;
		}
	}
`;

export const CardLabel = styled(Typography.Paragraph)<{
	isGroupedVariable?: boolean;
	isGroup?: boolean;
}>`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 24rem;

	${({ isGroupedVariable }) =>
		isGroupedVariable &&
		css`
			max-width: 21rem;
		`};
	${({ isGroup }) =>
		isGroup &&
		css`
			max-width: 18rem;
		`};
`;

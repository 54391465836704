import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { SpacingOffsets } from 'types/index';

import { Container } from './PermissionTag.style';

interface Props extends SpacingOffsets {
	title: string;
}

// TODO: to be removed when using `Tag.tsx`
export function PermissionTag({ title, marginOffset, paddingOffset }: Props) {
	return (
		<Container
			className="permission-tag"
			marginOffset={marginOffset}
			paddingOffset={paddingOffset}
		>
			<Typography.Caption title={title} fontweight={w => w.medium} color={Colors.text.main}>
				{title}
			</Typography.Caption>
		</Container>
	);
}

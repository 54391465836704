import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

export const Border = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	pointer-events: none;
	border-radius: 0.8rem;
	border: 0.2rem solid ${Colors.primary.normal};
	display: none;
`;

export const MenuWrapper = styled.div`
	position: absolute;
	top: 0.8rem;
	right: -2.4rem;
	height: 2.4rem;
	width: 2.4rem;

	:hover > :first-child {
		visibility: visible;
	}
`;

export const MenuWrapperPlaceholder = styled.div`
	position: absolute;
	top: 0.8rem;
	right: -2.4rem;
	height: 2.4rem;
	width: 2.4rem;
`;

export const Container = styled.div`
	position: relative;
	margin: 1rem -2.4rem;
	border-radius: 0.8rem;
	box-shadow: ${Shadows.normal};
	background-color: ${Colors.white};

	:hover {
		${Border} {
			display: block;
		}

		${MenuWrapper} > :first-child {
			visibility: visible;
		}
	}
`;

export const BorderContainer = styled.div`
	display: flex;
	flex-direction: column;

	:hover {
		${Border} {
			display: none;
		}
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	padding: 0.8rem;
`;

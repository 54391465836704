import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { addFormGroup, AddFormGroupAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useAddFormGroup(): LocalOperation<
	(payload: ActionPayload<AddFormGroupAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<AddFormGroupAction>) {
		dispatch(addFormGroup(payload));
	}

	return handler;
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	setAnalysisOpenState,
	selectAnalysisOpenState,
	SetAnalysisOpenStateAction
} from 'store/data/analyses';
import { ActionPayload } from 'store/types';

export function useAnalysisOpenState(
	analysisId: string
): LocalOperationResult<boolean, (payload: ActionPayload<SetAnalysisOpenStateAction>) => void> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectAnalysisOpenState(state.data.analyses, analysisId));

	function handler(payload: ActionPayload<SetAnalysisOpenStateAction>) {
		dispatch(setAnalysisOpenState(payload));
	}

	return [data, handler];
}

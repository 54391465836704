import { sendRequest, PATIENTS_URL } from 'api/utils';
import {
	GetDistributionStatisticsRequest,
	GetDistributionStatisticsResponse,
	GetManualDistributionStatisticsResponse,
	PromStatistics
} from './types';

import { Dictionary } from 'environment';

const methods = {
	getDistributionStatistics: 'getDistributionStatistics',
	getManualDistributionStatistics: 'getManualDistributionStatistics'
};

export default () => ({
	async getDistributionStatistics(promId: string): Promise<PromStatistics> {
		const { data }: GetDistributionStatisticsResponse =
			await sendRequest<GetDistributionStatisticsRequest>(PATIENTS_URL, {
				method: methods.getDistributionStatistics,
				project: {
					projectId: Number(promId)
				}
			});

		if (data.statusCode !== '200' || !data.promStatistics) {
			throw new Error(Dictionary.errors.api.dashboard.statisticsFetchError);
		}

		return data.promStatistics;
	},

	async getManualDistributionStatistics(promId: string) {
		const { data }: GetManualDistributionStatisticsResponse = await sendRequest(PATIENTS_URL, {
			method: methods.getManualDistributionStatistics,
			project: {
				projectId: Number(promId)
			}
		});

		if (!data.listOfCreationDates) {
			throw new Error(Dictionary.errors.api.patients.couldNotGetDistribution);
		}

		return data.listOfCreationDates;
	}
});

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { resetPublishedUnsupported } from 'store/data/templates';

export function usePublishedWithUnsupported(): LocalOperationResult<boolean> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.templates.metadata.publishedWithUnsupported);

	function handler() {
		dispatch(resetPublishedUnsupported({ variablesToDelete: [] }));
	}

	return [data, handler];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import {
	ActionTypes,
	updateCollaboratorsPermissions,
	CollaboratorEmailAddressWithPermissions
} from 'store/data/collaborators';

export function useUpdateCollaboratorsPermissions(): Operation<
	(collaboratorsWithPermissions: CollaboratorEmailAddressWithPermissions[]) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_COLLABORATORS_PERMISSIONS);

	function handler(collaboratorsWithPermissions: CollaboratorEmailAddressWithPermissions[]) {
		dispatch(updateCollaboratorsPermissions(collaboratorsWithPermissions));
	}

	return [{ loading, error }, handler];
}

import { Colors, Svgs } from 'environment';
import { Icon } from '../Icons';

import { Container } from './Error.style';
import { Typography } from '../Typography';

interface Props {
	message: string | React.ReactNode;
	className?: string;
	dataTestid?: string;
}

export function Error({ message, className, dataTestid }: Props) {
	return (
		<Container className={className} data-testid={dataTestid}>
			<Icon
				svg={Svgs.Information}
				size={s => s.l}
				variant={v => v.simple}
				marginOffset={{ right: 1.6 }}
				colors={{ color: Colors.text.errorHover }}
			/>

			<Typography.Paragraph color={Colors.text.main} marginOffset={{ top: 0.1 }} multiline>
				{message}
			</Typography.Paragraph>
		</Container>
	);
}

import styled from 'styled-components';

export const TemplatesListWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
`;

export const TemplatesOwnershipFilters = styled.div`
	display: flex;
	justify-content: space-evenly;
`;

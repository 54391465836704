import { Loader } from 'components/UI/Loader';
import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { ActionTypes } from 'store/data/entries';

import { Wrapper } from './FilterBreakdown.style';
import { useActivity } from 'hooks/store';

interface Props {
	count?: number;
}

export function FilterBreakdown({ count = 0 }: Props) {
	const [{ loading }] = useActivity(ActionTypes.GET_ENTRIES);

	if (loading) {
		return (
			<Wrapper>
				<Loader primary />
			</Wrapper>
		);
	}

	return (
		<Typography.Caption fontweight={w => w.medium} color={Colors.text.disabled}>
			{count}
		</Typography.Caption>
	);
}

import { VariableCategory } from 'api/data/variables';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { Svgs, Colors } from 'environment';
import { useTranslation } from 'hooks/store';

import {
	CategoryValuesSelectedState,
	CategoryValuesActions
} from '../CategoryValuesModal/CategoryValuesModal.controller';

interface Props {
	categories: VariableCategory[];
	selectedData: {
		selectedState: CategoryValuesSelectedState;
		actions: CategoryValuesActions;
	};
	activeModals: {
		edit: boolean;
		import: boolean;
		merge: boolean;
		move: boolean;
		delete: boolean;
	};
	actions: {
		onImport: () => void;
		onEdit: () => void;
		onMerge: () => void;
		onMove: () => void;
		onDelete: () => void;
		onCreate: () => void;
		onRowClick: (categoryId: string) => void;
	};
}

export function CategoryValuesTable({
	categories,
	selectedData: {
		selectedState: { selected, all, partial, one, inTotal },
		actions: { onSelect, onSelectAll, isSelected }
	},
	activeModals,
	actions
}: Props) {
	const { Responsive, Head, Body, Row, Column, Cell } = Table;
	const { translate } = useTranslation();

	const hasRowsSelected = selected.length > 0;

	return (
		<Responsive>
			<Table fullWidth hoverEffect>
				<Head>
					<Row>
						<Column colSpan={4}>
							<Flex style={{ flex: 1 }} marginOffset={{ left: 0.8, right: 0.8 }}>
								{hasRowsSelected && (
									<Typography.Caption color={Colors.text.hint}>
										{`${inTotal} ${translate(dict => dict.terms.selected)}`}
									</Typography.Caption>
								)}
							</Flex>
							<Flex marginOffset={{ right: 4.8 }}>
								<Icon
									svg={Svgs.Import}
									size={s => s.m}
									variant={v => v.buttonActive}
									marginOffset={{ right: 0.8 }}
									active={activeModals.import}
									onClick={actions.onImport}
								/>
								{one && (
									<Icon
										svg={Svgs.Edit}
										size={s => s.m}
										variant={v => v.buttonActive}
										marginOffset={{ right: 0.8 }}
										active={activeModals.edit}
										onClick={actions.onEdit}
									/>
								)}
								{hasRowsSelected && (
									<>
										<Icon
											svg={Svgs.Merge}
											size={s => s.m}
											variant={v => v.buttonActive}
											marginOffset={{ right: 0.8 }}
											active={activeModals.merge}
											onClick={actions.onMerge}
										/>
										<Icon
											title={translate(dict => dict.terms.move)}
											svg={Svgs.FolderMove}
											size={s => s.m}
											variant={v => v.buttonActive}
											marginOffset={{ right: 0.8 }}
											active={activeModals.move}
											onClick={actions.onMove}
										/>
										<Icon
											svg={Svgs.Delete}
											size={s => s.m}
											variant={v => v.buttonActive}
											active={activeModals.delete}
											onClick={actions.onDelete}
										/>
									</>
								)}
							</Flex>
							<Flex>
								<Icon
									svg={Svgs.Add}
									size={s => s.m}
									variant={v => v.buttonActive}
									colors={{
										color: Colors.white,
										background: Colors.primary.normal
									}}
									onClick={actions.onCreate}
									active
								/>
							</Flex>
						</Column>
					</Row>

					<Row>
						<Column minWidth={4} maxWidth={4}>
							<Checkbox checked={all} partial={partial} onClick={onSelectAll} />
						</Column>
						<Column>{translate(dict => dict.terms.valueUpperCase)}</Column>
						<Column>{translate(dict => dict.terms.label)}</Column>
						<Column>{translate(dict => dict.terms.description)}</Column>
					</Row>
				</Head>

				<Body>
					{categories.map(category => (
						<Row
							key={`category_row-${category.id}`}
							onClick={() => actions.onRowClick(category.id)}
							clickable
						>
							<Cell minWidth={4} maxWidth={4} width={4}>
								<Checkbox
									checked={isSelected(category.id)}
									onClick={() => onSelect(category.id)}
								/>
							</Cell>
							<Cell>{category.value}</Cell>
							<Cell>{category.label}</Cell>
							<Cell>{category.description}</Cell>
						</Row>
					))}
				</Body>
			</Table>
		</Responsive>
	);
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { removeFormGroup, RemoveFormGroupAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useRemoveFormGroup(): LocalOperation<
	(payload: ActionPayload<RemoveFormGroupAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<RemoveFormGroupAction>) {
		dispatch(removeFormGroup(payload));
	}

	return handler;
}

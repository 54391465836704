import { Backdrop } from 'components/UI/Backdrop';

import { PreviewDocument } from '../PreviewDocument/PreviewDocument';
import { PreviewDocumentHeader } from '../PreviewDocument/Header/PreviewDocumentHeader';
import { StoredEntryFile } from 'store/data/entries';
import { Container } from './PreviewFileModal.style';

interface Props {
	usedInEntries?: boolean;
	file?: StoredEntryFile;
	hasDelete?: boolean;
	hasRename?: boolean;
	onClose: () => void;
	onDelete?: () => void;
}

export function PreviewFileModal({
	file,
	usedInEntries,
	hasDelete,
	hasRename,
	onClose,
	onDelete
}: Props) {
	return (
		<Backdrop onClick={() => undefined}>
			<Container>
				<PreviewDocumentHeader
					usedInEntries={usedInEntries}
					entryFile={file}
					hasDelete={hasDelete}
					hasRename={hasRename}
					onClose={onClose}
					onDelete={onDelete}
				/>
				<PreviewDocument entryFile={file} />
			</Container>
		</Backdrop>
	);
}

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

import { OverflowContainer } from './ProjectDescriptionModal.style';

interface Props {
	description: string;
	onClose: () => void;
}

export function ProjectDescriptionModal({ description, onClose }: Props) {
	const { translate } = useTranslation();

	return (
		<Modal
			title={translate(dict => dict.readMore.description)}
			primary={{
				label: translate(dict => dict.buttons.close),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<OverflowContainer>
				<Typography.Paragraph multiline>{description}</Typography.Paragraph>
			</OverflowContainer>
		</Modal>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, createVariableSetAggregationRule } from 'store/data/variables';

type HandlerInput = Parameters<typeof createVariableSetAggregationRule>[0];

export function useCreateVariableSetAggregationRule(
	ruleName: string
): Operation<(input: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.CREATE_VARIABLE_SET_AGGREGATION_RULE,
		{
			ruleName
		}
	);

	function handler(input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(createVariableSetAggregationRule(input));
	}

	return [{ loading, error }, handler];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { SubscriptionAddonCode } from 'types/index';
import { deactivateUserAddon, ActionTypes } from 'store/account/addons';

export function useDeactivateUserAddon(): Operation<
	(userAddonCode: SubscriptionAddonCode) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.ACTIVATE_USER_ADDON);

	function handler(userAddonCode: SubscriptionAddonCode) {
		if (error) resetError();
		dispatch(deactivateUserAddon(userAddonCode));
	}

	return [{ loading, error }, handler];
}

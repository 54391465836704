import { useEffect, useMemo, useState, useRef } from 'react';
import { Variable, VariableCategory } from 'api/data/variables';
import { Colors, Svgs } from 'environment';
import {
	ActionTypes as VariableActionTypes,
	activateVariableCategories,
	deactivateVariableCategories
} from 'store/data/variables';
import { ActionTypes as EntriesActionTypes } from 'store/data/entries';
import {
	useCategoryValuesModalsController,
	useCategoryValuesSelectedController
} from './CategoryValuesModal.controller';
import { CategoryValuesTable } from '../CategoryValuesTable';
import { Container } from './CategoryValuesModal.style';
import { EditCategoryFixedValuesType } from 'consts';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Switch } from 'components/UI/Interactables/Switch';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { Modal } from 'components/UI/Modal';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { TagInput } from 'components/UI/TagInput';
import { Typography } from 'components/UI/Typography';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { showVariableFixedValuesWarning, initVariableCategory } from 'helpers/variables';
import {
	useTranslation,
	useDependencies,
	useCreateVariableCategoryValue,
	useCreateVariableCategoryValues,
	useMoveVariableCategoryValue,
	useActivities,
	useUpdateVariableCategoryValue
} from 'hooks/store';
import { useStatic, usePaginate, useToggle, useKeyPress, useDispatch } from 'hooks/utils';
import { EntryVariableType } from 'types/data/variables/constants';

enum Views {
	Input = 'input',
	Table = 'table'
}

interface Props {
	variable: Variable;
	initialVariable: Variable;
	onToggle: () => void;
	onClose: () => void;
}

export function CategoryValuesModal({ variable, initialVariable, onToggle, onClose }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const [view, setView] = useState<Views>(Views.Input);
	const [scrollToLastPage, setScrollToLastPage] = useStatic(false);

	const [
		{
			data: { dependencies }
		}
	] = useDependencies({ lazy: true });

	const [, createCategoryValue] = useCreateVariableCategoryValue();
	const [, createCategoryValues] = useCreateVariableCategoryValues();

	const [, moveCategoryValue] = useMoveVariableCategoryValue();

	const [{ loading: movingCategory }, {}] = useActivities([
		VariableActionTypes.MOVE_VARIABLE_CATEGORY_VALUE,
		VariableActionTypes.MOVE_VARIABLE_CATEGORY_VALUE_LOCAL
	]);

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page,
		pages,
		lastPage,
		changePage,
		changePageSize
	} = usePaginate(variable.categories, {
		threshold: 10,
		pageSize: 10
	});

	const { selectedState, actions } = useCategoryValuesSelectedController({
		variable
	});
	const {
		modals: {
			categoryValueModal,
			deleteCategoryValuesModal,
			importCategoryValuesModal,
			mergeCategoryValuesModal,
			moveCategoryValuesModal,
			turnOffCategoryValuesModal
		},
		modalsComponent
	} = useCategoryValuesModalsController({
		variable,
		selectedStateActions: actions,
		createCallback: lastPage
	});

	const [removeDatasetValues, toggleRemoveDatasetValues] = useToggle(false);

	const [firstOpen, setFirstOpen] = useState(true);
	const disabledCategories = useRef(true);

	// is used as dependency for useEffect on activating and deactivating categories
	const [{ loading: loadingActivatingOrDeactivatingCategories }] = useActivities(
		[
			VariableActionTypes.DEACTIVATE_VARIABLE_CATEGORIES,
			VariableActionTypes.ACTIVATE_VARIABLE_CATEGORIES
		],
		{ variableName: variable.name }
	);

	// is used to show disabled container only on activate categories
	const [{ loading: loadingActivateCategories }] = useActivities(
		[VariableActionTypes.ACTIVATE_VARIABLE_CATEGORIES],
		{ variableName: variable.name }
	);

	const [{ loading: loadingActions }] = useActivities([
		VariableActionTypes.GET_VARIABLES,
		VariableActionTypes.UPDATE_VARIABLE,
		EntriesActionTypes.GET_ENTRIES,
		EntriesActionTypes.REBUILD_ENTRIES
	]);

	useKeyPress(
		{
			// onEscapeKeyPress: actions.reset,
			onDeleteKeyPress: () => deleteCategoryValuesModal.open(selectedState.selected)
		},
		{
			listen: view === Views.Table && selectedState.selected.length > 0
		}
	);

	useEffect(() => {
		if (scrollToLastPage()) {
			lastPage();
			setScrollToLastPage(false);
		}
	}, [pages]);

	useEffect(() => {
		disabledCategories.current = loadingActivatingOrDeactivatingCategories;
	}, [loadingActivatingOrDeactivatingCategories]);

	useEffect(() => {
		if (firstOpen && !loadingActions) {
			setFirstOpen(false);
		}
	}, [loadingActions]);

	const showFixedCategoriesWarning = useMemo(() => {
		const hadFixedCategories = initialVariable.fixedCategories;
		const fixedCategoriesChanged = initialVariable.fixedCategories !== variable.fixedCategories;

		const showWarning = showVariableFixedValuesWarning({
			variable: initialVariable,
			dependencies
		});

		const existingCategoriesGotDeleted = wereExistingFixedCategoriesDeleted(
			initialVariable.categories.map(c => c.value),
			variable.categories.map(c => c.value)
		);

		return (
			hadFixedCategories &&
			(fixedCategoriesChanged || existingCategoriesGotDeleted) &&
			showWarning
		);
	}, [variable, initialVariable, dependencies]);

	const [, updateCategoryValue] = useUpdateVariableCategoryValue();

	function wereExistingFixedCategoriesDeleted(initialCategories: string[], categories: string[]) {
		let deleted = false;

		initialCategories.forEach(categoryValue => {
			if (deleted) return;

			if (!categories.includes(categoryValue)) deleted = true;
		});

		return deleted;
	}

	function handleTagInputClick(value: string) {
		const categoryValue = getCategoryByValue(value);

		if (categoryValue) categoryValueModal.open(categoryValue.id);
	}

	function handleTagInputCreate(value: string) {
		const categoryValue = initVariableCategory({ value });

		createCategoryValue({
			variableName: variable.name,
			categoryValue,
			predictiveUpdates: true
		});

		actions.reset();
		setScrollToLastPage(true);
	}

	function handleTagPasteInputCreate(value: string[]) {
		const categoryValueUpdated: VariableCategory[] = [];

		value.forEach(value => {
			const categoryValue = initVariableCategory({ value });

			categoryValueUpdated.push(categoryValue);
		});

		createCategoryValues({
			variableName: variable.name,
			categoryValues: categoryValueUpdated
		});
	}

	function handleTagInputDelete(value: string) {
		const categoryValue = getCategoryByValue(value);

		if (categoryValue) deleteCategoryValuesModal.open([categoryValue.id]);
	}

	function handleTagInputMove(input: {
		value: string;
		sourceIndex: number;
		destinationIndex: number;
	}) {
		if (movingCategory) return;
		const { value, sourceIndex, destinationIndex } = input;

		if (sourceIndex === destinationIndex) return;

		const categoryValue = getCategoryByValue(value);

		if (categoryValue) {
			const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
				pageIndex,
				pageSize
			});

			moveCategoryValue({
				variableName: variable.name,
				categoryValueId: categoryValue.id,
				sourceIndex: absoluteIndexes.sourceIndex,
				destinationIndex: absoluteIndexes.destinationIndex
			});
		}
	}

	function getCategoryByValue(categoryValue: string) {
		return variable.categories.find(c => c.value === categoryValue);
	}

	function handleVariableCategoriesActivation(options?: { confirmed: boolean }) {
		if (hasFixedCategories() && !options?.confirmed && variable.categories.length > 0)
			return turnOffCategoryValuesModal.open();

		onToggle();

		if (hasFixedCategories()) {
			dispatch(
				deactivateVariableCategories({
					variableName: variable.name,
					removeDatasetValues
				})
			);
		} else {
			dispatch(activateVariableCategories({ variableName: variable.name }));
		}
	}

	function hasFixedCategories() {
		return variable.fixedCategories;
	}

	function hasFixedCategoriesInitial() {
		return initialVariable.fixedCategories;
	}

	function handleOnClickProceed() {
		if (
			// IF FIXED CATEGORY VALUES WERE CHANGED UPDATE CATEGORY VALUE
			turnOffCategoryValuesModal.payload &&
			turnOffCategoryValuesModal.payload.type ===
				EditCategoryFixedValuesType.CategoryFixedValuesChanged
		) {
			updateCategoryValue({
				variableName: turnOffCategoryValuesModal.payload.variableName,
				categoryValue: turnOffCategoryValuesModal.payload.categoryValue
			});
			turnOffCategoryValuesModal.close();
		} else {
			turnOffCategoryValuesModal.close();
			handleVariableCategoriesActivation({ confirmed: true });
		}
	}

	const closeModal = () => {
		if (hasFixedCategories() && page.length === 0) {
			setInformationModalVisible(true);
		} else {
			onClose();
		}
	};

	const [informationModalVisible, setInformationModalVisible] = useState(false);

	return (
		<Modal
			size={s => s.full}
			title={`${
				hasFixedCategoriesInitial()
					? translate(dict => dict.terms.manage)
					: translate(dict => dict.terms.configure)
			} ${translate(dict => dict.terms.fixedValues)}`}
			primary={{
				label: translate(dict => dict.buttons.done),
				onClick: closeModal
			}}
			onClose={closeModal}
			fullSizeConfig={{
				narrow: true,
				centerTitle: true
			}}
			visible
			close
		>
			<Container disabled={loadingActivateCategories}>
				<Switch
					label={translate(dict => dict.variableFields.fixedValues)}
					description={translate(dict => dict.variableFields.fixedValuesInfo)}
					dataTestId="fixedValuesToggle"
					on={hasFixedCategories()}
					disabled={
						firstOpen ||
						variable.entryType === EntryVariableType.Calculated ||
						disabledCategories.current
					}
					onChange={handleVariableCategoriesActivation}
				/>

				{hasFixedCategories() && (
					<>
						<Spacer size={s => s.m} />

						<Flex>
							<Typography.Paragraph marginOffset={{ right: 1.6 }}>
								{translate(dict => dict.terms.chooseLayout)}
							</Typography.Paragraph>

							<RadioGroupUncontrolled
								name=""
								value={view}
								options={[
									{
										label: translate(dict => dict.terms.input),
										value: Views.Input
									},
									{
										label: translate(dict => dict.terms.table),
										value: Views.Table
									}
								]}
								onChange={item => setView(item as Views)}
							/>
						</Flex>

						<Spacer size={s => s.m} />

						{shouldPaginate && (
							<Flex marginOffset={{ bottom: 0.4 }}>
								<Pagination
									totalCountLabel="values"
									pageIndex={pageIndex}
									pageSize={pageSize}
									pagesCount={pagesCount}
									changePage={changePage}
									changePageSize={changePageSize}
									totalCount={variable.categories.length}
									filteredCount={variable.categories.length}
									extraPageSizeOptions={[250]}
								/>
							</Flex>
						)}

						{/* TAG INPUT */}

						{view === Views.Input && (
							<TagInput
								items={page.map(c => ({
									label: c.label || c.value,
									value: c.value
								}))}
								moving={movingCategory}
								// autoFocus={wasFixedCategoriesFalse && hasFixedCategories}
								placeholder={translate(
									dict => dict.variableFields.fixedValuesInputPlaceholder
								)}
								onClick={handleTagInputClick}
								onCreate={handleTagInputCreate}
								onPaste={handleTagPasteInputCreate}
								onDelete={handleTagInputDelete}
								onMove={handleTagInputMove}
								focusOutOnDelete
								uniqueItems={false}
								sortable
							/>
						)}

						{/* TABLE */}
						{view === Views.Table && (
							<CategoryValuesTable
								categories={page}
								selectedData={{ selectedState, actions }}
								activeModals={{
									edit:
										categoryValueModal.visible &&
										!!categoryValueModal.categoryValue,
									import: importCategoryValuesModal.visible,
									merge: mergeCategoryValuesModal.visible,
									move: moveCategoryValuesModal.visible,
									delete: deleteCategoryValuesModal.visible
								}}
								actions={{
									onImport: importCategoryValuesModal.open,
									onEdit: () => {
										const firstValueSelected = selectedState.selected[0];

										categoryValueModal.open(firstValueSelected);
									},
									onMerge: () =>
										mergeCategoryValuesModal.open(selectedState.selected),
									onMove: () =>
										moveCategoryValuesModal.open({
											categoryValueIds: selectedState.selected
										}),
									onDelete: () =>
										deleteCategoryValuesModal.open(selectedState.selected),
									onCreate: categoryValueModal.open,
									onRowClick: categoryValueModal.open
								}}
							/>
						)}
					</>
				)}

				{showFixedCategoriesWarning && (
					<Flex marginOffset={{ top: 0.8 }}>
						<Icon
							svg={Svgs.Information}
							size={s => s.m}
							colors={{ color: Colors.text.error }}
							marginOffset={{ right: 0.4 }}
						/>
						<Typography.Hint color={Colors.text.error} marginOffset={{ top: 0.2 }}>
							{translate(dict => dict.variablesPage.categoryValuesModal.message)}
						</Typography.Hint>
					</Flex>
				)}

				{/* MODALS */}
				{modalsComponent}

				{/* Confirm turn OFF fixed values - modal */}
				{turnOffCategoryValuesModal.visible && (
					<Modal
						size={s => s.s}
						title={translate(dict => dict.terms.confirmation)}
						primary={{
							label: translate(dict => dict.buttons.proceed),
							warning: true,
							onClick: handleOnClickProceed
						}}
						neutral={{
							label: translate(dict => dict.buttons.cancel),
							onClick: turnOffCategoryValuesModal.close
						}}
						onClose={turnOffCategoryValuesModal.close}
						enterAsPrimaryOnClick
						visible
						close
					>
						<Typography.Paragraph>
							<b>{translate(dict => dict.terms.warning)}</b>
							{` ${translate(
								dict => dict.variablesPage.categoryValuesModal.message
							)}`}
						</Typography.Paragraph>

						{/* TODO: enable when BE is ready */}
						{false && (
							<>
								<Spacer size={s => s.s} />

								<Switch
									label={translate(
										dict => dict.variablesPage.deleteCategoryValuesModal.delete
									)}
									description={
										removeDatasetValues
											? translate(
													dict =>
														dict.variablesPage.deleteCategoryValuesModal
															.removeDatasetValues
											  )
											: translate(
													dict =>
														dict.variablesPage.deleteCategoryValuesModal
															.datasetValues
											  )
									}
									on={removeDatasetValues}
									onChange={toggleRemoveDatasetValues}
								/>
							</>
						)}

						<Spacer size={s => s.m} />

						<Typography.Paragraph fontweight={w => w.bold} color={Colors.text.error}>
							{translate(dict => dict.terms.deleteWarningMessage)}
						</Typography.Paragraph>
					</Modal>
				)}
			</Container>
			<Modal
				size={s => s.s}
				visible={informationModalVisible}
				title={translate(
					dict => dict.variablesPage.categoryValuesModal.informationModal.title
				)}
				neutral={{
					label: translate(
						dict => dict.variablesPage.categoryValuesModal.informationModal.button
					),
					onClick: () => setInformationModalVisible(false)
				}}
				onClose={() => setInformationModalVisible(false)}
				close
			>
				<Typography.Paragraph multiline>
					{translate(
						dict => dict.variablesPage.categoryValuesModal.informationModal.description
					)}
				</Typography.Paragraph>
			</Modal>
		</Modal>
	);
}

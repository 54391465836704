import { copyProject, ActionTypes, CopyProject } from 'store/data/projects';
import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useCopyProject(): OperationResult<string, (project: CopyProject) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.COPY_PROJECT);
	const copiedProjectId = useSelector(state => state.data.projects.copiedProjectId);

	let data = '';
	let fetched = false;

	if (copiedProjectId) {
		fetched = true;
		data = copiedProjectId;
	}

	function handler(project: CopyProject) {
		if (loading) return;
		if (error) resetError();
		const {
			projectId,
			projectDefinition,
			variables,
			groups,
			dependencies,
			statuses,
			statusPermissions,
			series,
			forms,
			data,
			newOwnerId,
			projectRoles
		} = project;

		dispatch(
			copyProject({
				projectId,
				projectDefinition,
				variables,
				groups,
				dependencies,
				statuses,
				statusPermissions,
				series,
				forms,
				data,
				newOwnerId,
				projectRoles
			})
		);
	}

	return [{ loading, error, data, fetched }, handler];
}

import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import { ActionTypes, SetHelpSearchTermAction, GetHelpTopicsAction } from './types';

export const setHelpSearchTerm = (
	payload: ActionPayload<SetHelpSearchTermAction>
): SetHelpSearchTermAction => ({
	type: ActionTypes.SET_HELP_SEARCH_TERM,
	payload
});

const getHelpTopicsAction = (payload: ActionPayload<GetHelpTopicsAction>): GetHelpTopicsAction => ({
	type: ActionTypes.GET_HELP_TOPICS,
	payload
});

export const getHelpTopics = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_HELP_TOPICS,
		dispatch
	});

	try {
		activity.begin();

		const language = getState().ui.i18n.language;

		if (language) {
			const topics = await context.api.data.help().getHelpTopics(language);
			dispatch(getHelpTopicsAction({ topics }));
		}
	} catch (e) {
		activity.error({ error: 'Failed to fetch help topics' });
	} finally {
		activity.end();
	}
};

import { createActivity } from 'store/ui/activities';
import { ActionPayload, Thunk } from 'store/types';
import { PromDistributionTypes } from 'types/index';

import { ActionTypes, GetDistributionStatisticsAction } from './types';

const getDistributionStatisticsAction = (
	payload: ActionPayload<GetDistributionStatisticsAction>
): GetDistributionStatisticsAction => ({
	type: ActionTypes.GET_DISTRIBUTION_STATISTICS,
	payload
});

export const getDistributionStatistics = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_DISTRIBUTION_STATISTICS,
		dispatch
	});

	const {
		data: {
			projects: { projectId, byId: projectsById }
		}
	} = getState();

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const { promType } = projectsById[projectId];

			const isManualDistribution = promType === PromDistributionTypes.Manual;

			if (isManualDistribution) {
				const listOfCreationDates = await context.api.data
					.dashboard()
					.getManualDistributionStatistics(projectId);
				dispatch(
					getDistributionStatisticsAction({
						projectId,
						listOfCreationDates
					})
				);
			} else {
				const promStatistics = await context.api.data
					.dashboard()
					.getDistributionStatistics(projectId);
				dispatch(
					getDistributionStatisticsAction({
						projectId,
						promStatistics
					})
				);
			}
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

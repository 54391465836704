import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useUpdateVariable } from 'hooks/store';
import { useState } from 'react';
import { InputType } from 'types/index';

interface Props {
	onClose: () => void;
	onConfirm: () => void;
	confirmationPhrase?: string;
}

export function TypeChangeModal({ onClose, onConfirm, confirmationPhrase }: Props) {
	const { translate } = useTranslation();
	const [confirm, setCofirm] = useState('');

	const [{ loading }] = useUpdateVariable();

	function handleSubmitConfirmation() {
		if (!confirmationPhrase) {
			onConfirm();
		}
		if (confirmationPhrase === confirm) {
			onConfirm();
		}
	}

	return (
		<Modal
			size={size => size.s}
			title={translate(dict => dict.variablesPage.variableModal.typeChangeModal.title)}
			primary={{
				label: translate(dict => dict.buttons.confirm),
				onClick: handleSubmitConfirmation,
				loading,
				disabled: confirm !== confirmationPhrase
			}}
			enterAsPrimaryOnClick
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose,
				loading
			}}
			onClose={onClose}
			fullSizeConfig={{
				narrow: true,
				centerTitle: true
			}}
			visible
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.variablesPage.variableModal.typeChangeModal.message)}
			</Typography.Paragraph>
			<Spacer size={s => s.m} />
			<Input
				type={InputType.Text}
				value={confirm}
				onChange={e => setCofirm(e.target.value)}
				label={`${translate(
					({ variables }) => variables.confirmTypeChangeModal.type
				)} "${confirmationPhrase}" ${translate(
					({ variables }) => variables.confirmTypeChangeModal.toContinue
				)}`}
			/>
		</Modal>
	);
}

import { nanoid as generate } from 'nanoid';

import { Variable } from 'api/data/variables';
import { buildDependenciesIndexByName } from 'helpers/dependencies';
import {
	Dependant,
	Dependency,
	DependencyActions,
	DependencyOperators,
	DependencyType
} from 'store/data/dependencies';
import { VariablesMap } from 'store/data/variables';
import { GetMutableState, NumberMap, SetMutableState } from 'types/index';
import { debuggerLog } from 'helpers/generic';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	draftDependenciesState: {
		draftDependencies: Dependency[];
		setDraftDependencies: SetMutableState<Dependency[]>;
	};
	dependenciesIndexByName: {
		getDependenciesIndexByName: GetMutableState<NumberMap>;
		setDependenciesIndexByName: SetMutableState<NumberMap>;
	};
	variables: Variable[];
	variablesMap: VariablesMap;
}

export function getDependencyActions({
	draftDependenciesState: {
		// draftDependencies,
		setDraftDependencies
	},
	dependenciesIndexByName: { getDependenciesIndexByName, setDependenciesIndexByName },
	variables,
	variablesMap
}: Props) {
	// SET TO `true` TO SEE THE LOGS
	const DEBUGGER = false;
	const log = debuggerLog(DEBUGGER);

	/*
	 * DEPENDENCY
	 */

	function createDependency(input?: { variableName: string }) {
		log('createDependency()', input);

		const supplierVariableName = input?.variableName ?? variables[0]?.name ?? '';

		setDraftDependencies(state => {
			const dependencyName = generate();

			const newDependency: Dependency = {
				dependencyName,
				supplierVariableName,
				dependencyType: DependencyType.Visibility,
				dependantVariables: [],
				description: ''
			};

			const dependencyIndex = state.push(newDependency) - 1;

			setDependenciesIndexByName(state => {
				state[dependencyName] = dependencyIndex;
			});
		});
	}

	function changeDependencyType(input: {
		dependencyName: string;
		dependencyType: DependencyType;
	}) {
		log('changeDependencyType()', input);

		const { dependencyName, dependencyType } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const hasChanged = dependency.dependencyType !== dependencyType;

			// USER SELECTED SAME VALUE - DO NOTHING
			if (!hasChanged) return;

			const wasVisibilityCondition = dependency.dependencyType === DependencyType.Visibility;
			const wasFilteringCondition = dependency.dependencyType === DependencyType.Filtering;

			const isVisibilityCondition = dependencyType === DependencyType.Visibility;
			const isFilteringCondition = dependencyType === DependencyType.Filtering;

			// SET NEW DEPENDENCY TYPE
			dependency.dependencyType = dependencyType;

			// FILTERING -> VISIBILITY
			if (wasFilteringCondition && isVisibilityCondition) {
				// CLEAR FILTERED VALUES
				dependency.dependantVariables.forEach(dependant => {
					dependant.filteredValues = [];
				});
			}

			// VISIBILITY -> FILTERING
			if (wasVisibilityCondition && isFilteringCondition) {
				dependency.dependantVariables.forEach(dependant => {
					const { dependantVariableName } = dependant;

					const dependantVariable = variablesMap[dependantVariableName];

					if (!dependantVariable) return;

					const isDependantCategory = dependantVariable.type === VariableType.Category;
					const isDependantCategoryMultiple =
						dependantVariable.type === VariableType.CategoryMultiple;

					const hasFixedCategories = dependantVariable.fixedCategories;

					// DEPENDANT IS NOT `category` / `categoryMultiple` VARIABLE -> RESET `dependantVariableName`
					if (
						!(
							(isDependantCategory || isDependantCategoryMultiple) &&
							hasFixedCategories
						)
					) {
						dependant.dependantVariableName = '';
					}
				});
			}
		});
	}

	function changeDependencyDescription(input: { dependencyName: string; description: string }) {
		const { dependencyName, description } = input;
		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const hasChanged = dependency.description !== description;

			// SAME VALUE - DO NOTHING
			if (!hasChanged) return;

			// SET NEW DEPENDENCY DESCRIPTION
			dependency.description = description;
		});
	}

	function changeDependencyVariable(input: {
		dependencyName: string;
		supplierVariableName: string;
	}) {
		log('changeDependencyVariable()', input);

		const { dependencyName, supplierVariableName } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const hasChanged = dependency.supplierVariableName !== supplierVariableName;

			// USER SELECTED SAME VALUE - DO NOTHING
			if (!hasChanged) return;

			const isFilteringCondition = dependency.dependencyType === DependencyType.Filtering;

			const supplierVariable = variablesMap[dependency.supplierVariableName];
			const newSupplierVariable = variablesMap[supplierVariableName];

			const supplierVariableTypeChanged = supplierVariable.type !== newSupplierVariable.type;

			const isNewSupplierCategoryVariable =
				newSupplierVariable.type === VariableType.Category;
			const isNewSupplierCategoryMultipleVariable =
				newSupplierVariable.type === VariableType.CategoryMultiple;

			// SUPPLIER VARIABLE TYPE CHANGED
			if (supplierVariableTypeChanged) {
				// RESET `operator` AND `supplierValueCondition`
				dependency.dependantVariables.forEach(dependant => {
					dependant.operator = DependencyOperators.EQUAL_TO;
					dependant.supplierValueCondition = '';

					// RESET DEPENDANT VARIABLE NAME DUE TO CIRCULAR STRUCTURE
					if (newSupplierVariable.name === dependant.dependantVariableName) {
						dependant.dependantVariableName = '';

						if (isFilteringCondition) dependant.filteredValues = [];
					}
				});
			}
			// NEW SUPPLIER VARIABLE HAS THE SAME VARIABLE TYPE
			else {
				// NEW SUPPLIER VARIABLE IS CATEGORY / CATEGORY MULTIPLE
				if (isNewSupplierCategoryVariable || isNewSupplierCategoryMultipleVariable) {
					// RESET `operator` AND `supplierValueCondition`
					dependency.dependantVariables.forEach(dependant => {
						dependant.operator = DependencyOperators.EQUAL_TO;
						dependant.supplierValueCondition = '';

						// RESET DEPENDANT VARIABLE NAME DUE TO CIRCULAR STRUCTURE
						if (newSupplierVariable.name === dependant.dependantVariableName) {
							dependant.dependantVariableName = '';

							if (isFilteringCondition) dependant.filteredValues = [];
						}
					});
				}
			}

			// SET NEW VARIABLE
			dependency.supplierVariableName = supplierVariableName;
		});
	}

	function deleteDependency(input: { dependencyName: string }) {
		log('deleteDependency()', input);

		const { dependencyName } = input;

		setDraftDependencies(state => {
			const dependencyIndex = getDependenciesIndexByName()[dependencyName];

			state.splice(dependencyIndex, 1);

			setDependenciesIndexByName(buildDependenciesIndexByName(state));
		});
	}

	/*
	 * DEPENDANT
	 */

	function createDependant(input: { dependencyName: string }) {
		log('createDependant()', input);

		const { dependencyName } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const newDependantVariable: Dependant = {
				dependantId: generate(),
				supplierValueCondition: '',
				dependantVariableName: '',
				operator: DependencyOperators.EQUAL_TO,
				filteredValues: []
			};

			dependency.dependantVariables.push(newDependantVariable);
		});
	}

	function changeDependantVariable(input: {
		dependencyName: string;
		dependantId: string;
		dependantVariableName: string;
	}) {
		log('changeDependantVariable()', input);

		const { dependencyName, dependantId, dependantVariableName } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const dependant = dependency.dependantVariables.find(
				dependantVariable => dependantVariable.dependantId === dependantId
			);

			if (!dependant) return;

			const dependantVariableChanged =
				dependant.dependantVariableName !== dependantVariableName;

			if (!dependantVariableChanged) return;

			const isFilteringCondition = dependency.dependencyType === DependencyType.Filtering;

			if (isFilteringCondition) {
				// RESET DEPENDANT VARIABLE CATEGORY VALUES
				dependant.filteredValues = [];
			}

			// SET NEW DEPENDANT VARIABLE NAME
			dependant.dependantVariableName = dependantVariableName;
		});
	}

	function changeDependantOperator(input: {
		dependencyName: string;
		dependantId: string;
		operator: DependencyOperators;
	}) {
		log('changeDependantOperator()', input);

		const { dependencyName, dependantId, operator } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const dependant = dependency.dependantVariables.find(
				dependantVariable => dependantVariable.dependantId === dependantId
			);

			if (!dependant) return;

			// SET NEW OPERATOR
			dependant.operator = operator;
		});
	}

	function setDependantFilteredValues(input: {
		dependencyName: string;
		dependantId: string;
		filteredValues: string[];
	}) {
		log('setDependantFilteredValues()', input);

		const { dependencyName, dependantId, filteredValues } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const dependant = dependency.dependantVariables.find(
				dependantVariable => dependantVariable.dependantId === dependantId
			);

			if (!dependant) return;

			// SET NEW VALUES
			dependant.filteredValues = filteredValues;
		});
	}

	function deleteDependantFilteredValues(input: { dependencyName: string; dependantId: string }) {
		log('deleteDependantFilteredValues()', input);

		const { dependencyName, dependantId } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const dependant = dependency.dependantVariables.find(
				dependantVariable => dependantVariable.dependantId === dependantId
			);

			if (!dependant) return;

			// SET NEW VALUES
			dependant.filteredValues = [];
		});
	}

	function setDependantSupplierValueCondition(input: {
		dependencyName: string;
		dependantId: string;
		supplierValueCondition: string;
	}) {
		log('setDependantSupplierValueCondition()', input);

		const { dependencyName, dependantId, supplierValueCondition } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			const dependant = dependency.dependantVariables.find(
				dependantVariable => dependantVariable.dependantId === dependantId
			);

			if (!dependant) return;

			// SET NEW VALUE
			dependant.supplierValueCondition = supplierValueCondition;
		});
	}

	function deleteDependant(input: { dependencyName: string; dependantId: string }) {
		log('deleteDependant()', input);

		const { dependencyName, dependantId } = input;

		setDraftDependencies(state => {
			const dependency = getDependency({
				dependencyName,
				dependencies: state
			});

			if (!dependency) return;

			dependency.dependantVariables = dependency.dependantVariables.filter(
				dependantVariable => dependantVariable.dependantId !== dependantId
			);
		});
	}

	////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////

	function getDependency(input: {
		dependencyName: string;
		dependencies: Dependency[];
	}): Dependency | undefined {
		const { dependencyName, dependencies } = input;

		const dependencyIndex = getDependenciesIndexByName()[dependencyName];

		return dependencies[dependencyIndex];
	}

	////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////

	const dependencyActions: DependencyActions = {
		// DEPENDENCY
		createDependency,
		changeDependencyType,
		changeDependencyDescription,
		changeDependencyVariable,
		deleteDependency,

		// DEPENDANT
		createDependant,
		changeDependantVariable,
		changeDependantOperator,
		setDependantFilteredValues,
		deleteDependantFilteredValues,
		setDependantSupplierValueCondition,
		deleteDependant
	};

	return { dependencyActions };
}

import { useState } from 'react';
import { Title } from 'components/Login/styles';
import { Images, Svgs } from 'environment';
import { sanatizeCode } from 'helpers/auth';
import { InputType } from 'types/index';
import { Container, Card, LogoWrapper, ChromeLogo } from 'pages/Login/LoginPage.style';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, usePatientLoggedIn, usePatientLoginParams } from 'hooks/store';

export function PatientLoginForm() {
	const { translate } = useTranslation();

	const [{ loading }, loginPatient] = usePatientLoggedIn();
	const [params] = usePatientLoginParams();

	const [code, setCode] = useState('');

	function handleSubmit() {
		if (params && code.length === 6) loginPatient(params.username, params.passPrefix + code);
	}

	function handleInput(value: string) {
		setCode(sanatizeCode(value, 6));
	}

	return (
		<Container>
			<Card>
				<LogoWrapper>
					<Svgs.LedidiLogoLogin />
				</LogoWrapper>

				<Title>{translate(dict => dict.login.title)}</Title>

				<Input
					type={InputType.Password}
					value={code}
					name="code"
					label={translate(dict => dict.loginForm.pin)}
					placeholder={translate(dict => dict.loginForm.pinPlaceholder)}
					onChange={e => handleInput(e.target.value)}
					onSubmit={handleSubmit}
					autoFocus
				/>

				<Spacer size={s => s.m} />

				<Button
					title={translate(dict => dict.loginForm.login)}
					type="submit"
					loading={loading}
					disabled={code.length < 6}
					onClick={handleSubmit}
					hasFullWidth
				/>

				<Flex align={a => a.center} marginOffset={{ top: 10 }}>
					<ChromeLogo
						src={Images.chrome}
						alt={translate(dict => dict.login.chromeLogoAlt)}
					/>
					<Typography.Caption marginOffset={{ left: 0.8 }} uppercase>
						{translate(dict => dict.login.browserInfo)}
					</Typography.Caption>
				</Flex>
			</Card>
		</Container>
	);
}

import { State } from './types';

const initialState: State = {
	projectId: null,
	layoutColumns: 3,
	dashboardId: null,
	dashboardIdsByProjectId: {},
	dashboardsById: {}
};

export default initialState;

import { useEffect, useState } from 'react';
import { useTransition } from 'react-spring';
import { Svgs } from 'environment';
import {
	BarIcon,
	ChildrenContainer,
	DrawerContainer,
	LeftTopSection,
	TopBar
} from './Drawer.style';
import { Backdrop } from '../Backdrop';
import { Icon } from '../Icons/Icon';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import { useKeyPress } from 'hooks/utils';

interface Props {
	open: boolean;
	children: React.ReactNode;
	title?: string;
	showBackButton: boolean;
	disableAnimations?: boolean;
	onClose: () => void;
	onGoBack: () => void;
}

export function Drawer({
	open,
	children,
	title,
	showBackButton,
	onClose,
	onGoBack,
	disableAnimations
}: Props) {
	const [willOpen, setWillOpen] = useState(false);

	useEffect(() => {
		if (open) {
			setWillOpen(true);
		}
	}, [open]);

	const transitions = useTransition(willOpen, {
		from: { right: '-100vw' },
		enter: { right: '0rem' },
		leave: { right: '-100vw' },
		onDestroyed: isDestroyed => {
			if (isDestroyed) {
				onClose();
			}
		},
		config: { duration: disableAnimations ? 0 : 300 }
	});

	function onTransitionCloseMenu() {
		setWillOpen(false);
	}

	useKeyPress(
		{ onEscapeKeyPress: onTransitionCloseMenu },
		{ listen: open, noModalsOpened: true }
	);

	if (!open) return null;

	return (
		<Backdrop onClick={onTransitionCloseMenu}>
			{transitions(
				(props, item) =>
					item && (
						<DrawerContainer style={props}>
							<Spacer size={s => s.s} />
							<TopBar>
								<LeftTopSection>
									<BarIcon visible={showBackButton} onClick={onGoBack}>
										<Icon
											dataTestId="drawer-back-button"
											svg={Svgs.ArrowLeft}
											size={s => s.l}
											onClick={onGoBack}
										/>
									</BarIcon>
									<Typography.H6>{title}</Typography.H6>
								</LeftTopSection>
								<Icon
									dataTestId="drawer-close-button"
									svg={Svgs.Close}
									size={s => s.l}
									onClick={onTransitionCloseMenu}
								/>
							</TopBar>
							<Spacer size={s => s.s} />
							<Spacer size={s => s.xl} />
							<ChildrenContainer>{children}</ChildrenContainer>
						</DrawerContainer>
					)
			)}
		</Backdrop>
	);
}

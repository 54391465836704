import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { resetDistributions } from 'store/data/patients';

export function useDiscardDistributionsChanges(): LocalOperation {
	const dispatch = useDispatch();

	function handler() {
		dispatch(resetDistributions());
	}

	return handler;
}

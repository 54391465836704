import produce from 'immer';

import {
	CompareNumericAnalysisV2,
	CorrelationsAnalysis,
	ComparePairedAnalysisV2,
	KaplanMeierAnalysisV2,
	CrosstabAnalysisV2,
	AnalysisV2
} from 'api/data/analyses';
import { AnalysisType } from 'api/data/analyses/constants';

// RESET STATISTICS IF WENT FROM `ENABED` TO `DISABLED`
export function withStatisticsResetter(analysis: AnalysisV2): AnalysisV2 {
	const parsedAnalysis = produce(analysis, analysisDraft => {
		// COMPARE NUMERIC
		if (analysis.type === AnalysisType.CompareNumericV2) {
			const { input, output } = analysisDraft as CompareNumericAnalysisV2;

			if (!input.statistics.shapiroV2) {
				output.statistics.shapiro = {
					data: null
				};
			}
			if (!input.statistics.mannWhitneyV2) {
				output.statistics.mannWhitney = {
					data: null
				};
			}
			if (!input.statistics.independentV2) {
				output.statistics.independent = {
					data: null
				};
			}
			if (!input.statistics.oneWayAnovaV2) {
				output.statistics.oneWayAnova = {
					data: null
				};
			}
			if (!input.statistics.tukeyV2) {
				output.statistics.tukey = {
					data: null
				};
			}
			if (!input.statistics.kruskalV2) {
				output.statistics.kruskal = {
					data: null
				};
			}
			if (!input.statistics.twoWayAnovaV2) {
				output.statistics.twoWayAnova = {
					data: null
				};
			}
			if (!input.statistics.oneWayManovaV2) {
				output.statistics.oneWayManova = {
					data: null
				};
			}
			if (!input.statistics.twoWayManovaV2) {
				output.statistics.twoWayManova = {
					data: null
				};
			}
		}
		// CROSSTAB
		if (analysis.type === AnalysisType.CrosstabV2) {
			const { input, output } = analysisDraft as CrosstabAnalysisV2;

			if (!input.statistics.fisherV2) {
				output.statistics.fisher = {
					data: null
				};
			}
			if (!input.statistics.chiSquareV2) {
				output.statistics.chiSquare = {
					data: null
				};
			}
			if (!input.statistics.mcNemarV2) {
				output.statistics.mcNemar = {
					data: null
				};
			}
		}
		// KAPLAN MEIER
		if (analysis.type === AnalysisType.KaplanV2) {
			const { input, output } = analysisDraft as KaplanMeierAnalysisV2;

			if (!input.statistics.logRankV2) {
				output.statistics.logRank = {
					data: null
				};
			}
		}
		// CORRELATIONS
		if (analysis.type === AnalysisType.CorrelationsV2) {
			const { input, output } = analysisDraft as CorrelationsAnalysis;

			if (!input.statistics.pearsonV2) {
				output.statistics.pearsonV2 = {
					data: null
				};
			}
			if (!input.statistics.spearmanV2) {
				output.statistics.spearmanV2 = {
					data: null
				};
			}
			if (!input.statistics.linearRegressionV2) {
				output.statistics.linearRegressionV2 = {
					data: null
				};
			}
		}
		// COMPARE PAIRED
		if (analysis.type === AnalysisType.ComparePairedV2) {
			const { input, output } = analysisDraft as ComparePairedAnalysisV2;

			if (!input.statistics.pairedTTestV2) {
				output.statistics.pairedTTest = {
					data: null
				};
			}
			if (!input.statistics.pairedWilcoxonV2) {
				output.statistics.pairedWilcoxon = {
					data: null
				};
			}
		}
	});

	return parsedAnalysis;
}

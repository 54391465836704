import { ActionPayload } from 'store/types';

import {
	ActionTypes,
	SetActiveSortAction,
	SetPageSizeAction,
	SetVisibleColumnsAction,
	AddVisibleColumnAction,
	RemoveVisibleColumnAction,
	SetPageIndexAction,
	ResetFilterAction,
	UpdateFilterAction,
	UpdateFiltersAction
} from './types';

export const setActiveSortAction = (
	payload: ActionPayload<SetActiveSortAction>
): SetActiveSortAction => ({
	type: ActionTypes.SET_ACTIVE_SORT,
	payload
});

export const setPageSizeAction = (
	payload: ActionPayload<SetPageSizeAction>
): SetPageSizeAction => ({
	type: ActionTypes.SET_PAGE_SIZE,
	payload
});

export const setPageIndexAction = (
	payload: ActionPayload<SetPageIndexAction>
): SetPageIndexAction => ({
	type: ActionTypes.SET_PAGE_INDEX,
	payload
});

export const setVisibleColumnsAction = (
	payload: ActionPayload<SetVisibleColumnsAction>
): SetVisibleColumnsAction => ({
	type: ActionTypes.SET_VISIBLE_COLUMNS,
	payload
});

export const addVisibleColumnAction = (
	payload: ActionPayload<AddVisibleColumnAction>
): AddVisibleColumnAction => ({
	type: ActionTypes.ADD_VISIBLE_COLUMN,
	payload
});

export const removeVisibleColumnAction = (
	payload: ActionPayload<RemoveVisibleColumnAction>
): RemoveVisibleColumnAction => ({
	type: ActionTypes.REMOVE_VISIBLE_COLUMN,
	payload
});

export const updateFilterAction = (
	payload: ActionPayload<UpdateFilterAction>
): UpdateFilterAction => ({
	type: ActionTypes.UPDATE_FILTER,
	payload
});

export const updateFiltersAction = (
	payload: ActionPayload<UpdateFiltersAction>
): UpdateFiltersAction => ({
	type: ActionTypes.UPDATE_FILTERS,
	payload
});

export const resetFilterAction = (
	payload: ActionPayload<ResetFilterAction>
): ResetFilterAction => ({
	type: ActionTypes.RESET_FILTER,
	payload
});

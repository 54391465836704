import produce from 'immer';

import {
	ActionTypes as AccountAddonsActionTypes,
	Actions as AccountAddonsActions
} from 'store/account/addons';
import { SubscriptionAddonCode } from 'types/index';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

export default (state: State = initialState, action: Actions | AccountAddonsActions): State => {
	switch (action.type) {
		case ActionTypes.SET_TOKEN_VALID: {
			const { valid } = action.payload;

			return produce(state, draft => {
				if (draft) {
					draft.tokenValidMock = valid;
				}
			});
		}

		case ActionTypes.LOG_IN_TO_JADBIO: {
			return produce(state, draft => {
				draft = {
					tokenValidMock: true,
					projects: { byProjectId: {} }
				};

				return draft;
			});
		}

		case ActionTypes.LOG_OUT_FROM_JADBIO: {
			return null;
		}

		case ActionTypes.INITIATE_JADBIO_PROJECT: {
			const { projectId, jadBioProject } = action.payload;

			return produce(state, draft => {
				if (draft) {
					draft.projects.byProjectId[projectId] = jadBioProject;
				}
			});
		}

		case AccountAddonsActionTypes.GET_USER_ADDONS: {
			const { activeUserAddons } = action.payload;

			// reset JADBio if it's not included in the active addons
			if (state && !activeUserAddons.includes(SubscriptionAddonCode.JADBio)) return null;

			return state;
		}

		default: {
			return state;
		}
	}
};

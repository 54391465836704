import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import {
	moveVariableBetweenGroups,
	MoveVariableBetweenGroupsAction,
	ActionTypes
} from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveVariableBetweenGroups(): Operation<
	(payload: ActionPayload<MoveVariableBetweenGroupsAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS);

	function handler(payload: ActionPayload<MoveVariableBetweenGroupsAction>) {
		dispatch(moveVariableBetweenGroups(payload));
	}

	return [{ loading, error }, handler];
}

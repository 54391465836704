import { VariableUniquenessType } from 'api/data/variables';
import { EntryValues } from 'store/data/entries';
import { VariablesMap } from 'store/data/variables';

/**
 * (1) Filters unique variable values
 */
export function prepareEntryValues(variablesMap: VariablesMap, formValues: EntryValues) {
	const values = {} as EntryValues;

	Object.entries(formValues)
		.filter(
			([key]) =>
				variablesMap[key]?.uniquenessType !== VariableUniquenessType.Sequence &&
				variablesMap[key]?.uniquenessType !== VariableUniquenessType.UUID
		)
		.forEach(([key, entry]) => (values[key] = entry));

	return values;
}

export function countDecimals(number: number) {
	if (Math.floor(number) === number) return 0; // No decimals for whole numbers
	return number.toString().split('.')[1].length || 0;
}

import { Svgs } from 'environment';

import { PaymentSuccessWrapper } from './style';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { useTranslation } from 'hooks/store';

export function UpdatePlanSuccess() {
	const { translate } = useTranslation();

	return (
		<>
			<Spacer size={s => s.m} />
			<PaymentSuccessWrapper>
				<Icon svg={Svgs.PaymentSuccess} />
				<Spacer size={s => s.m} />
				<Typography.H3 fontweight={f => f.bold}>
					{translate(({ accountUM }) => accountUM.changePlan.thankYouForSubscription)}
				</Typography.H3>
				<Spacer size={s => s.s} />
				<Typography.Paragraph>
					{translate(({ accountUM }) => accountUM.changePlan.tillChangesCompleteInfo)}
				</Typography.Paragraph>
			</PaymentSuccessWrapper>
		</>
	);
}

import styled from 'styled-components';

import { Button } from 'components/UI/Interactables/Button/Button';

export const VariablesList = styled.ul`
	margin: 0;
	padding: 0 2.4rem;
`;

export const VariablesListItem = styled.li``;

export const ShowMoreButton = styled(Button)`
	padding: 0;
	margin: 0;
`;

import * as yup from 'yup';
import { ActionTypes, createSnapshot } from 'store/data/snapshots';
import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { useCompletedAction, useDispatch } from 'hooks/utils';
import { useTranslation, useActivity } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface Props {
	onClose(success?: boolean): void;
}

export function CreateSnapshotModal({ onClose }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.CREATE_SNAPSHOT);

	useCompletedAction(loading, error, () => onClose(true));

	const initialValues = { name: '' };

	const validationSchema = yup.object({
		name: yup
			.string()
			.trim()
			.required(translate(({ analysis }) => analysis.snapshots.create.nameRequired))
	});

	const { Form, handleSubmit, register, errors, isDirtyAndValid, handleTrimOnBlur } =
		useReactForm({
			initialValues,
			validationSchema
		});

	const onFormSubmit = handleSubmit(({ name }) => {
		if (isDirtyAndValid) handleCreateSnapshot(name.trim());
	});

	function handleCreateSnapshot(name: string) {
		if (loading) return;
		if (error) resetError();

		dispatch(createSnapshot(name));
	}

	return (
		<Modal
			size={s => s.s}
			title={translate(({ analysis }) => analysis.snapshots.create.title)}
			primary={{
				label: translate(({ buttons }) => buttons.save),
				loading,
				disabled: !isDirtyAndValid,
				onClick: onFormSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Form onSubmit={onFormSubmit}>
				<Input
					{...register('name', {
						onBlur: handleTrimOnBlur
					})}
					type={InputType.Text}
					error={errors.name?.message}
					placeholder={translate(({ analysis }) => analysis.snapshots.create.placeholder)}
					autoFocus
				/>
			</Form>
		</Modal>
	);
}

import type { Action } from 'types/store/types';
import type { StringArrayMap } from 'types/index';

export interface State {
	projectId: string | null;
	bySeriesName?: string | null;
	seriesViews: SeriesViewsProps;
	metadata: MetadataType;
	fullScreenSeriesName?: string | null;
}

interface SeriesViewsProps {
	narrowTableView?: boolean;
	gridView?: boolean;
	tableFullSizeView?: boolean;
}

interface DefaultValueType {
	defaultValues: {
		pageSize: number;
		pageIndex: number;
		columnSettings: {
			visible: string[];
		};
	};
}

interface MetadataType extends DefaultValueType {
	[bySeriesName: string]: {
		pageSize: number;
		pageIndex: number;
		columnSettings: {
			visible: string[];
		};
	};
}

export interface EntriesErrors {
	columns: string[];
	rows: StringArrayMap;
}

export interface EntriesErrorsFilter {
	columns: boolean;
	rows: boolean;
}

export enum ActionTypes {
	SET_SERIES_TABLE_PARAMS = 'data/series/SET_SERIES_TABLE_PARAMS',
	SET_SERIES_TABLE_VISIBLE_COLUMNS = 'data/series/SET_SERIES_TABLE_VISIBLE_COLUMNS',
	UPDATE_SERIES_NAME = 'data/series/UPDATE_SERIES_NAME',
	UPDATE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW = 'data/series/UPDATE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW',
	DELETE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW = 'data/series/DELETE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW',
	SET_SERIES_VIEWS = 'data/series/SET_SERIES_VIEWS'
}

export interface SeriesTableParams {
	pageSize?: number;
	pageIndex?: number;
}

export type SetSeriesTableParamsAction = Action<
	ActionTypes.SET_SERIES_TABLE_PARAMS,
	SeriesTableParams
>;

export type SetSeriesTableVisibleColumnsAction = Action<
	ActionTypes.SET_SERIES_TABLE_VISIBLE_COLUMNS,
	{
		columnNames: string[];
	}
>;

export type UpdateSeriesName = Action<
	ActionTypes.UPDATE_SERIES_NAME,
	{
		seriesName: string | null;
	}
>;

export type UpdateFullScreenSeriesNameTableView = Action<
	ActionTypes.UPDATE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW,
	{
		seriesName: string | null;
	}
>;

export type DeleteFullScreenSeriesNameTableView = Action<
	ActionTypes.DELETE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW,
	{
		seriesName: null;
	}
>;

export type UpdateSeriesViews = Action<
	ActionTypes.SET_SERIES_VIEWS,
	{
		seriesViews: SeriesViewsProps;
	}
>;

export type Actions =
	| SetSeriesTableParamsAction
	| SetSeriesTableVisibleColumnsAction
	| UpdateSeriesName
	| UpdateFullScreenSeriesNameTableView
	| DeleteFullScreenSeriesNameTableView
	| UpdateSeriesViews;

import { addVariableToGroup, AddVariableToGroupAction, ActionTypes } from 'store/data/variables';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { ActionPayload } from 'store/types';

export function useAddVariableToGroup(activityPayload?: {
	variableName: string;
	toOrFromSet: boolean;
}): Operation<(payload: ActionPayload<AddVariableToGroupAction>) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.ADD_VARIABLE_TO_GROUP, activityPayload);

	function handler(payload: ActionPayload<AddVariableToGroupAction>) {
		dispatch(addVariableToGroup(payload));
	}

	return [{ loading, error }, handler];
}

import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;

	> :nth-child(n) {
		width: calc(50% - 1.1rem);
	}

	@media ${MediaQueries.maxWidth.md} {
		flex-direction: column;
		justify-content: unset;

		> :nth-child(n) {
			width: 100%;
		}
	}
`;

import styled, { css, keyframes } from 'styled-components';

import { Colors } from 'environment';

interface RootProps {
	isDragActive: boolean;
}

export const ProgressContainer = styled.div`
	margin: 1rem 0;
	text-align: center;
`;

export const ProgressWrapper = styled.div`
	padding: 0;
	border-radius: 3rem;
	background: #e8e8e8;
`;
interface ProgressProps {
	finished: boolean;
	width: string;
}

const progressAnimation = keyframes`
	from { width: 0 }
	to   { width: 85% }
`;
const secondProgressAnimation = keyframes`
	from { width: 85% }
	to   { width: 100% }
`;
export const ProgressBar = styled.div<ProgressProps>`
	height: 0.4rem;
	border-radius: 3rem;
	transition: 0.4s linear;
	transition-property: width, background-color;
	width: ${({ width }) => width};
	background-color: ${Colors.vibrantGreen};
	animation: ${progressAnimation} 1.5s;
	${({ finished }) =>
		finished &&
		css`
			animation: ${secondProgressAnimation} 0.6s;
		`}
`;

export const ProjectImportRoot = styled.div<RootProps>`
	${({ isDragActive }) => css`
		background: ${isDragActive ? Colors.gray.lighter : Colors.silver.lightest};
		width: 100%;
		min-height: 10rem;
		display: flex;
		flex-direction: column;
		padding: 4rem;
		border: 0.1rem dashed ${Colors.gray.lighter};
		border-radius: 0.4rem;
		font-size: 1.6rem;
		color: ${Colors.gray.medium};
		outline: none;
		transition: 0.3s;
	`}
`;

export const BrowseButton = styled.span`
	cursor: pointer;
	color: ${Colors.primary.normal};
`;

export const ToggleDisplayMessage = styled.span<{ visible: boolean }>`
	${({ visible }) => css`
		display: ${visible ? 'initial;' : 'none'};
	`}
`;

export const ImportedFileContainer = styled.div`
	padding-bottom: 2rem;
`;

export const ImportedFileName = styled.span`
	color: ${Colors.gray.darkest};
`;

export const TranslateContainer = styled.div``;

import { useEffect, useState } from 'react';
import { Variable } from 'api/data/variables';
import { DEPENDENCY_OPTIONS } from 'consts';
import { Colors, Svgs } from 'environment';
import { Dependency, DependencyType } from 'store/data/dependencies';
import { DirectDependency } from './DirectDependency';
import { FlexRow, VLine } from '../styles';
import {
	CollapseContainer,
	DependencyDescriptionInput,
	FlexCellNewDependencyDescription,
	FlexCellNewDependencyOption,
	FlexCellNewVariableSelector,
	List,
	ListContainer,
	NewButton,
	NewHline,
	RuleWrapper
} from './DependencyRule.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { useTranslation, useDependenciesContext } from 'hooks/store';
import { InputType } from 'types/index';

interface Props {
	dependency: Dependency;
	supplierVariable: Variable;
	dependencySuppliersSelectItems: { label: string; value: string }[];
	dependantVariablesSelectItems: { label: string; value: string }[];
	areRulesExpanded: boolean;
}

export function DependencyRule({
	dependency,
	supplierVariable,
	dependencySuppliersSelectItems,
	dependantVariablesSelectItems,
	areRulesExpanded
}: Props) {
	const { translate } = useTranslation();
	const [open, setOpen] = useState(areRulesExpanded);

	useEffect(() => {
		setOpen(areRulesExpanded);
	}, [areRulesExpanded]);

	const translateLabel = (label: string) => {
		switch (label) {
			case 'Visibility':
				return translate(dict => dict.dependencies.types.visibility);
			case 'Filtering':
				return translate(dict => dict.dependencies.types.filtering);
			default:
				return 'N/A';
		}
	};

	const dependencyOptions = DEPENDENCY_OPTIONS.map(item => {
		return {
			label: translateLabel(item.label),
			value: item.value
		};
	});

	const {
		actions: {
			createDependant,
			changeDependencyType,
			changeDependencyVariable,
			deleteDependency,
			changeDependencyDescription
		}
	} = useDependenciesContext();

	return (
		<RuleWrapper>
			<ListContainer>
				<Flex marginOffset={{ top: 1.8 }}>
					<Icon
						svg={open ? Svgs.ChevronUp : Svgs.ChevronDown}
						onClick={() => setOpen(!open)}
					/>
				</Flex>

				<List isOpen={open}>
					<CollapseContainer>
						{open && <VLine fullHeight />}
						{!open && (
							<Flex
								css={`
									display: block;
									height: 1.2rem;
									width: 0.1rem;
									position: absolute;
									top: 2.2rem;
									left: -2.1rem;
									background-color: ${Colors.vibrantGreen};
								`}
							/>
						)}

						<Flex
							css={`
								display: block;
								height: 0.1rem;
								width: 2.1rem;
								position: absolute;
								top: 2.2rem;
								left: -2.1rem;
								background-color: ${Colors.vibrantGreen};
							`}
						/>

						<FlexRow
							css={`
								.delete-dependency-icon {
									visibility: hidden;
								}

								:hover .delete-dependency-icon {
									visibility: visible;
								}
							`}
						>
							<FlexCellNewVariableSelector>
								<CreatableSelect
									className="creatable-select-small"
									value={dependencySuppliersSelectItems.find(
										item => item.value === supplierVariable.name
									)}
									items={dependencySuppliersSelectItems}
									onValueSelected={value => {
										if (value) {
											changeDependencyVariable({
												dependencyName: dependency.dependencyName,
												supplierVariableName: value
											});
										}
									}}
									canClear={false}
								/>
							</FlexCellNewVariableSelector>

							<FlexCellNewDependencyOption>
								<CreatableSelect
									className="creatable-select-small"
									items={dependencyOptions}
									value={dependencyOptions.find(
										item => item.value === dependency.dependencyType
									)}
									onValueSelected={item =>
										item &&
										changeDependencyType({
											dependencyName: dependency.dependencyName,
											dependencyType: item as DependencyType
										})
									}
									canClear={false}
								/>
							</FlexCellNewDependencyOption>
							<FlexCellNewDependencyDescription>
								<DependencyDescriptionInput
									type={InputType.Text}
									placeholder={translate(
										dict =>
											dict.dependencies.builder.tableView
												.ruleDescriptionPlaceholder
									)}
									className="description-input"
									value={dependency.description}
									onChange={e =>
										changeDependencyDescription({
											dependencyName: dependency.dependencyName,
											description: e.target.value
										})
									}
								/>
							</FlexCellNewDependencyDescription>
							<Icon
								className="delete-dependency-icon"
								title={translate(
									dict => dict.dependencies.dependencyRule.deleteDepenency
								)}
								svg={Svgs.Delete}
								marginOffset={{ left: 0.8 }}
								colors={{
									color: Colors.text.disabled,
									hover: Colors.primary.normal
								}}
								onClick={() =>
									deleteDependency({
										dependencyName: dependency.dependencyName
									})
								}
							/>
						</FlexRow>

						{open && (
							<>
								<FlexRow level={1}>
									<DirectDependency
										dependency={dependency}
										supplierVariable={supplierVariable}
										dependantVariablesSelectItems={
											dependantVariablesSelectItems
										}
									/>
								</FlexRow>
								<FlexRow level={1} style={{ marginLeft: 0 }}>
									<NewHline />

									<NewButton
										onClick={() =>
											createDependant({
												dependencyName: dependency.dependencyName
											})
										}
									>
										{translate(
											dict => dict.dependencies.dependencyRule.newCondition
										)}
									</NewButton>
								</FlexRow>
							</>
						)}
					</CollapseContainer>
				</List>
			</ListContainer>
		</RuleWrapper>
	);
}

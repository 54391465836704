import { useGroupByName, useVariables } from 'hooks/store';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export function useGroupDrawer() {
	const [groupName, setGroupName] = useState<string | null>(null);
	const group = useGroupByName(groupName);

	const [
		{
			data: { groupsMap }
		}
	] = useVariables({ lazy: true });

	const openGroupDrawer = useCallback((groupName: string) => {
		setGroupName(groupName);
	}, []);

	const closeGroupDrawer = useCallback(() => {
		setGroupName(null);
	}, []);

	// SYNC GROUP DRAWER / CLOSE IT IF GROUP WAS DELETED
	useEffect(() => {
		if (!group) return;

		const groupExists = group.groupName in groupsMap;

		if (!groupExists) return close();

		const groupChanged = !isEqual(group, groupsMap[group.groupName]);

		if (groupChanged) open(group.groupName);
	}, [groupsMap]);

	return { group, openGroupDrawer, closeGroupDrawer };
}

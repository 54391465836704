import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Svgs } from 'environment';
import { Dropdown } from '../List/Filters/styles';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useAdminUsersSearchTerm,
	useAdminUsersListFilters,
	useAdminUsersTableParams,
	useExportUsersToXLSX
} from 'hooks/store';

import { Tooltip } from 'components/UI/Interactables/Tooltip';

export function AdminUsersHeader() {
	const { translate } = useTranslation();
	const { navigate, routes } = useNavigation();

	const [searchTerm, setSearchTerm] = useAdminUsersSearchTerm();

	const [filters, { resetAllFilters }] = useAdminUsersListFilters();
	const [{ pageIndex, pageSize }, setPageParams] = useAdminUsersTableParams();

	const exportUsersAsXLSX = useExportUsersToXLSX();

	function resetPageIndex() {
		if (pageIndex !== 0) setPageParams({ pageIndex: 0, pageSize });
	}

	function clearFilters() {
		resetPageIndex();
		resetAllFilters();
	}

	function handleAddCollaborator() {
		navigate(routes.admin.create);
	}

	const canClearFilters = !!(
		filters.organizations.active.length ||
		filters.status.active.length ||
		filters.licence.active.length ||
		filters.createdDate.valid
	);

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={
					<Flex>
						<Flex marginOffset={{ right: 2.4 }}>
							<Dropdown
								toggleComponent={({ ref, open, toggle }) => (
									<Icon
										ref={ref}
										svg={Svgs.More}
										active={open}
										variant={v => v.button}
										onClick={toggle}
									/>
								)}
								width={12}
								offset={{ top: 20, right: 0 }}
							>
								<Dropdown.Item
									title={translate(dict => dict.entriesOptions.clearFilters)}
									disabled={!canClearFilters}
									onClick={clearFilters}
								/>
							</Dropdown>
						</Flex>
						<SearchInput
							placeholder={translate(
								({ admin }) => admin.users.list.header.inputPlaceholder
							)}
							term={searchTerm}
							onChangeTerm={term => term !== searchTerm && setSearchTerm(term)}
						/>
						<Button
							title={translate(({ admin }) => admin.users.list.header.createUser)}
							marginOffset={{ left: 2.4 }}
							onClick={handleAddCollaborator}
						/>
					</Flex>
				}
			/>
			<Header.Title
				title={translate(({ admin }) => admin.users.list.title)}
				component={
					<Flex data-for="export-as-xlsx" data-tip={'Export as XLSX'}>
						<Icon
							svg={Svgs.Export}
							variant={v => v.buttonActive}
							onClick={exportUsersAsXLSX}
							marginOffset={{ right: 0.4 }}
						/>
						<Tooltip id="export-as-xlsx" delayShow={200} place="bottom" />
					</Flex>
				}
			/>
		</>
	);
}

import { Container } from './TabsContent.style';

interface Props {
	children: React.ReactNode;
	active?: boolean;
	fullHeight?: boolean;
}

export function TabsContent({ children, active = false, fullHeight }: Props) {
	if (active) {
		return <Container fullHeight={fullHeight}>{children}</Container>;
	}

	return null;
}

import { useTemplateById, useTranslation } from 'hooks/store';
import { DescriptionHeader, DescriptionSubtitle } from './style';

interface Props {
	templateId: string;
}

export function CreateWithTemplateHeader({ templateId }: Props) {
	const { translate } = useTranslation();
	const template = useTemplateById(templateId);

	return (
		<>
			<DescriptionHeader>
				{translate(dict => dict.projects.createAndImport.template.modal.description)}
			</DescriptionHeader>
			<DescriptionSubtitle>{template.description || '-'}</DescriptionSubtitle>
		</>
	);
}

import produce from 'immer';
import format from 'date-fns/format';

import { DATE_FORMAT } from 'consts';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		// TODO: REFACTOR WITH `produce` FOR IMMUTABILITY
		case ActionTypes.GET_SNAPSHOTS: {
			const { projectId, snapshots } = action.payload;

			const byId = { ...state.byId };
			const byProjectId = { ...state.byProjectId };
			byProjectId[projectId] = {
				active: null,
				ids: []
			};

			snapshots.forEach(snapshot => {
				byId[snapshot.snapshotId] = snapshot;
				byProjectId[snapshot.projectId].ids.push(snapshot.snapshotId);
			});

			return {
				...state,
				byId,
				byProjectId
			};
		}

		// TODO: REFACTOR WITH `produce` FOR IMMUTABILITY
		case ActionTypes.CREATE_SNAPSHOT: {
			const { projectId, snapshotId, name } = action.payload;

			let ids: string[] = [snapshotId];
			if (state.byProjectId[projectId]) {
				ids = [...state.byProjectId[projectId].ids, snapshotId];
			}

			return {
				...state,
				byId: {
					...state.byId,
					[snapshotId]: {
						snapshotId,
						projectId,
						datasetId: projectId,
						snapName: name,
						snapTimestamp: format(new Date(), DATE_FORMAT)
					}
				},
				byProjectId: {
					...state.byProjectId,
					[projectId]: {
						active: snapshotId,
						ids
					}
				}
			};
		}

		// TODO: why?
		case ActionTypes.UPDATE_SNAPSHOT: {
			return state;
		}

		// TODO: REFACTOR WITH `produce` FOR IMMUTABILITY
		case ActionTypes.DELETE_SNAPSHOT: {
			const { projectId, snapshotId } = action.payload;

			const {
				[snapshotId]: {},
				...byId
			} = state.byId;
			let snapshot = { ...state.byProjectId[projectId] };
			snapshot = {
				active: null,
				ids: snapshot.ids.filter(snapId => snapId !== snapshotId)
			};

			return {
				...state,
				byId,
				byProjectId: {
					...state.byProjectId,
					[projectId]: snapshot
				}
			};
		}

		case ActionTypes.SET_ACTIVE_SNAPSHOT: {
			const { snapshotId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId].active = snapshotId;
				}
			});
		}

		case ActionTypes.CLEAR_SNAPSHOT: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					byProjectId[projectId].active = null;
				}
			});
		}

		default:
			return state;
	}
};

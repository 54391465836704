import { SetState, PromDistributionTypes } from 'types/index';
import { NarrowContainer, Title } from 'components/Projects/CreateAndImport';
import { RadioGroup } from 'components/UI/Interactables/RadioGroup';
import { StickyFooter } from 'components/UI/StickyFooter';
import { useTranslation, useCreateProject } from 'hooks/store';

interface Props {
	distributionType: PromDistributionTypes;
	setDistributionType: SetState<PromDistributionTypes>;
	onBack: () => void;
	onContinue: () => void;
}

export function PromDistributionType({
	distributionType,
	setDistributionType,
	onBack,
	onContinue
}: Props) {
	const { translate } = useTranslation();

	const [{ loading: creatingProm }] = useCreateProject();

	const radioGroupItems = [
		translate(dict => dict.createProm.distributionAutomatic),
		translate(dict => dict.createProm.distributionManual)
	];

	const getSelected = {
		[PromDistributionTypes.Automatic]: radioGroupItems[0],
		[PromDistributionTypes.Manual]: radioGroupItems[1]
	};

	return (
		<>
			<NarrowContainer>
				<Title>{translate(dict => dict.createProm.selectDistributionType)}</Title>

				<RadioGroup
					label={translate(dict => dict.createProm.distributionType)}
					selected={getSelected[distributionType]}
					items={radioGroupItems}
					onSelect={type =>
						setDistributionType(type.toLowerCase() as PromDistributionTypes)
					}
					isVertical
				/>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: creatingProm,
					onClick: onContinue
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.back),
					onClick: creatingProm ? undefined : onBack
				}}
				maxWidth={65.2}
			/>
		</>
	);
}

import {
	JsonLogic,
	JsonLogicMap,
	Variable,
	VariableAliasesMap,
	VariableAliasesActions
} from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import {
	Container,
	CalculatedInput,
	Row,
	Column,
	SubHeader,
	InputWrapper,
	InputInfo,
	ValidationButton,
	FormulaWrapper,
	Info,
	InfoMessage
} from './NumericCalculatedVariable.style';
import { useState } from 'react';

import { useTranslation } from 'hooks/store';
import { VariableAliases } from './VariableAliases/VariableAliases';
import { nanoid as generate } from 'nanoid';

import { parseToJsonLogic, Error } from './antlr4_lang/validator';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import { Flex } from 'components/UI/Flex';
import { Tooltip } from 'components/UI/Interactables/Tooltip';

type Props = {
	logic: JsonLogicMap;
	variables: Variable[];
	variableAliases: VariableAliasesMap;
	variableAliasesActions: VariableAliasesActions;
	showNumericInfoMessage: boolean;
	toggleNumericInfoMessage: (show: boolean) => void;
	onValidParsedRule: (logic: JsonLogicMap, variableMap: VariableAliasesMap) => void;
};

export function NumericCalculatedVariable({
	variables,
	logic,
	variableAliases,
	variableAliasesActions,
	showNumericInfoMessage,
	toggleNumericInfoMessage,
	onValidParsedRule
}: Props) {
	const { translate } = useTranslation();

	const { handleEntryToMap, removeEntry, setVariableId, setVariableAlias } =
		variableAliasesActions;

	const numericVariables = variables.filter(
		v => v.type === VariableType.Integer || v.type === VariableType.Float
	);

	const [formulaString, setFormulaString] = useState(() =>
		jl2string(logic, variableAliases, variables)
	);
	const [errored, setErrored] = useState(false);
	const [validated, setValidated] = useState(false);
	const [focused, setFocused] = useState(false);
	const [errors, setErrors] = useState<Error[]>([]);

	function handleFormulaParse(value: string) {
		if (value === '') {
			if (!errored) setErrored(true);
			if (validated) setValidated(false);

			setErrors([
				{
					precidence: 0,
					row: 0,
					column: 0,
					text: 'Add formula to input field.',
					type: 'empty-input',
					offendingSymbol: undefined
				} as Error
			]);

			onValidParsedRule({ order: [], logics: {} }, variableAliases);

			return;
		}

		const variableKeyItems = Object.values(variableAliases.map);

		const { jsonLogicRule, errors } = parseToJsonLogic(value, variableKeyItems);

		if (errors.length > 0) {
			setErrored(true);
			setErrors(errors);

			onValidParsedRule({ order: [], logics: {} }, variableAliases);
			return;
		}

		const parsedResult = jsonLogicRule as JsonLogic;

		const isValid = validateJsonLogic(parsedResult, numericVariables);

		if (!isValid) {
			setErrored(true);
			return;
		}

		const calculationId = generate();

		const jsonLogicMap = {
			order: [calculationId],
			logics: {
				[calculationId]: parsedResult
			}
		} as JsonLogicMap;

		onValidParsedRule(jsonLogicMap, variableAliases);
		setValidated(true);
		if (errored) setErrored(false);
		setErrors([]);
	}

	function handleChange(value: string) {
		if (errored) setErrored(false);
		if (validated) setValidated(false);

		setFormulaString(value);
	}

	return (
		<Container>
			{showNumericInfoMessage && (
				<Row marginBottom={2.4}>
					<Column w={100}>
						<Info>
							<Icon
								svg={Svgs.Information}
								size={s => s.l}
								style={{ marginRight: '0.8rem' }}
								colors={{ color: Colors.text.captionSecondary }}
							/>

							<InfoMessage>
								{translate(dict => dict.addVariable.compositeVariable.infoMessage)}
							</InfoMessage>

							<Icon
								svg={Svgs.Close}
								size={s => s.m}
								onClick={() => toggleNumericInfoMessage(false)}
								style={{ marginRight: '0.8rem' }}
								colors={{ color: Colors.text.captionSecondary }}
							/>
						</Info>
					</Column>
				</Row>
			)}

			<Row marginBottom={0.8}>
				<Column>
					<SubHeader>
						{translate(dict => dict.addVariable.compositeVariable.aliasTitle)}
					</SubHeader>
				</Column>
			</Row>

			<Row marginBottom={4.8}>
				<VariableAliases
					variableAliases={variableAliases}
					variables={numericVariables}
					setVariableAlias={setVariableAlias}
					setVariableId={setVariableId}
					removeEntry={removeEntry}
					handleEntryToMap={handleEntryToMap}
				/>
				<div id="dropdown-root"></div>
			</Row>

			<Row marginBottom={0.8}>
				<Column>
					<SubHeader>
						{translate(dict => dict.addVariable.compositeVariable.editorTitle)}
					</SubHeader>
				</Column>
			</Row>

			<Column mB>
				<InputWrapper>
					<FormulaWrapper focused={focused} error={errored} validated={validated}>
						<CalculatedInput
							value={formulaString}
							placeholder={translate(
								dict => dict.addVariable.compositeVariable.formulaPlaceholder
							)}
							onChange={e => handleChange(e.target.value)}
							error={errored}
							onFocus={() => setFocused(true)}
							onBlur={() => setFocused(false)}
						/>
						{errored ? (
							<Flex
								style={{ width: '3.2rem' }}
								data-tip="Check the syntax of your formula. Ensure that all operators and parentheses are used correctly."
								data-for="editor_input_error_tooltip"
							>
								<Icon
									svg={Svgs.Information}
									colors={{ color: Colors.numericEditor.error }}
									size={s => s.m}
									style={{ margin: 'auto' }}
								/>
								<Tooltip
									offset={{ right: 3.2, bottom: 10 }}
									id="editor_input_error_tooltip"
									delayShow={250}
									place="top"
								/>
							</Flex>
						) : validated ? (
							<Icon
								svg={Svgs.CheckCircle}
								size={s => s.m}
								colors={{ color: Colors.numericEditor.validated }}
								style={{ margin: 'auto' }}
							/>
						) : null}
					</FormulaWrapper>

					<ValidationButton onClick={() => handleFormulaParse(formulaString)}>
						{translate(dict => dict.addVariable.compositeVariable.validate)}
					</ValidationButton>
				</InputWrapper>
				<InputInfo error={errored || errors.length > 0} validated={validated}>
					{errored || errors.length > 0
						? translate(dict => dict.addVariable.compositeVariable.invalidFormula)
						: validated
						? translate(dict => dict.addVariable.compositeVariable.validFormula)
						: translate(dict => dict.addVariable.compositeVariable.info)}
					{errors.length > 0 ? (
						<>
							<ul style={{ margin: '0px', paddingLeft: '11px' }}>
								{errors.map((error, index) => (
									<li key={index}>{error.text}</li>
								))}
							</ul>
						</>
					) : null}
				</InputInfo>
			</Column>
		</Container>
	);
}

function jl2string(
	jsonLogicMap: JsonLogicMap,
	variableMap: VariableAliasesMap,
	variables: Variable[]
): string {
	function getVariableName(variableId: string): string {
		const mapEntry = Object.values(variableMap.map).find(
			entry => entry.variableId === variableId
		);
		if (mapEntry) {
			return mapEntry.key;
		}
		const variable = variables.find(v => v.name === variableId);
		return variable ? variable.name : variableId;
	}

	function getOperatorValue(operator: string): string {
		if (operator === '**') {
			return '^';
		}

		return operator;
	}

	function parseNode(node: any, isRoot = true): string {
		if (typeof node === 'object' && 'var' in node) {
			return getVariableName((node as { var: [string, string] }).var[0]);
		} else if (typeof node === 'number') {
			return node.toString();
		} else if (typeof node === 'string') {
			return node;
		} else {
			const operator = Object.keys(node)[0];
			const operands = node[operator];
			const operandStr = Array.isArray(operands)
				? operands
						.map(operand => parseNode(operand, false))
						.join(` ${getOperatorValue(operator)} `)
				: `${parseNode(operands[0], false)} ${getOperatorValue(operator)} ${parseNode(
						operands[1],
						false
				  )}`;

			return isRoot ? operandStr : `( ${operandStr} )`;
		}
	}

	const hasLogics = jsonLogicMap.order.length > 0;

	const logicValues = hasLogics
		? Object.values(jsonLogicMap.logics[jsonLogicMap.order[0]])[0]
		: [];

	if (!jsonLogicMap.order.length || (logicValues.length > 0 && logicValues[0] === null)) {
		return '';
	}

	return jsonLogicMap.order.map(id => parseNode(jsonLogicMap.logics[id])).join(', ');
}

function validateJsonLogic(jsonLogic: JsonLogic, variables: Variable[]) {
	if (
		(typeof jsonLogic === 'object' &&
			(Object.keys(jsonLogic).length === 0 || 'var' in jsonLogic)) ||
		typeof jsonLogic === 'number' ||
		typeof jsonLogic === 'string' ||
		jsonLogic === null ||
		Array.isArray(jsonLogic)
	) {
		// Invalid root node
		return false;
	}

	function traverse(jsonLogic: any) {
		if (jsonLogic === null) {
			return false;
		}

		if (Array.isArray(jsonLogic)) {
			for (const element of jsonLogic) {
				if (!element) return false;

				const result = traverse(element);
				if (!result) {
					return false; // Propagate the false result upwards
				}
			}
		} else if (typeof jsonLogic === 'object' && jsonLogic !== null) {
			if (Object.keys(jsonLogic).length === 0) {
				return false; // Empty object
			}

			if ('var' in jsonLogic) {
				if (!Array.isArray(jsonLogic.var) || jsonLogic.var.length !== 2) {
					return false; // 'var' value should be an array of length 2
				}
				const variableExists = variables.some(
					variable => variable.name === jsonLogic.var[0]
				);
				if (!variableExists) {
					return false; // No matching variable found
				}
			}

			for (const key in jsonLogic) {
				const result = traverse(jsonLogic[key]);

				if (!result) {
					return false; // Propagate the false result upwards
				}
			}
		}

		return true;
	}

	return traverse(jsonLogic);
}

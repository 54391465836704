import { downloadFileFromUrl } from 'helpers/generic';
import { useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { selectExportWizardData } from 'store/data/exportWizard/selectors';
import { selectProjectName } from 'store/data/projects';

interface OutputData {
	projectId: string | null;
	invalid: string[];
	empty: string[];
	valid: string[];
	isReadyForDownload: boolean;
}

export function useDownloadFile(): LocalOperationResult<OutputData, () => void> {
	const data = useSelector(state => selectExportWizardData(state.data.exportWizard));

	const projectName = useSelector(state => selectProjectName(state.data.projects));

	const outputData = {
		projectId: data.projectId,
		invalid: data.invalid,
		empty: data.empty,
		valid: data.valid,
		isReadyForDownload:
			!!data.downloadUrl &&
			!!data.projectId &&
			!!projectName &&
			(data.valid.length > 0 || data.empty.length > 0)
	};

	function handler() {
		if (data.downloadUrl && data.projectId && projectName) {
			downloadFileFromUrl(data.downloadUrl);
		}
	}

	return [outputData, handler];
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { resetProjectValidityAction } from 'store/data/projects';

export function useResetProjectValidity(): LocalOperation<() => void> {
	const dispatch = useDispatch();

	function handler() {
		dispatch(resetProjectValidityAction());
	}

	return handler;
}

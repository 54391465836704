import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import { stringAsBoolean } from 'helpers/generic';

const { REACT_APP_SEGMENT_DATA_KEY, REACT_APP_USE_SEGMENT_TRACKING } = process.env;

const IS_SEGMENT_TRACKING_ENABLED = stringAsBoolean(REACT_APP_USE_SEGMENT_TRACKING!);

function initializeSegment() {
	if (!shouldInit()) return;

	try {
		return Analytics({
			plugins: [
				segmentPlugin({
					writeKey: REACT_APP_SEGMENT_DATA_KEY
				})
			]
		});
	} catch (e) {
		console.log(e);
	}
}

function shouldInit() {
	if (!IS_SEGMENT_TRACKING_ENABLED) return;

	if (!(REACT_APP_SEGMENT_DATA_KEY && REACT_APP_SEGMENT_DATA_KEY.length > 0)) {
		return console.error('Segment integration enabled but no key found.');
	}

	return true;
}

export const SegmentComponent = initializeSegment();

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { MoveDashboardCardAction, moveDashboardCard } from 'store/data/projectDashboard';
import { ActionPayload } from 'store/types';

export function useMoveDashboardCard(): LocalOperation<
	(payload: ActionPayload<MoveDashboardCardAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<MoveDashboardCardAction>) {
		dispatch(moveDashboardCard(payload));
	}

	return handler;
}

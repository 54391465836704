import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { resetListsOfSharedWith, ResetListOfSharedWithAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useResetListsOfSharedWith(): LocalOperation<
	(payload: ActionPayload<ResetListOfSharedWithAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ResetListOfSharedWithAction>) {
		dispatch(resetListsOfSharedWith(payload));
	}

	return handler;
}

import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { TemplateRole } from 'store/data/roles';
import { SelectItem } from 'types/index';

import { TemplateRoleModalType } from './types';

export function generateSelectRoleItems(
	translate: TranslateFunction,
	roles: { public: TemplateRole[]; owned: TemplateRole[]; shared: TemplateRole[] }
): { public: SelectItem[]; owned: SelectItem[]; shared: SelectItem[]; default: SelectItem } {
	const items = {
		public: [] as SelectItem[],
		owned: [] as SelectItem[],
		shared: [] as SelectItem[],
		default: {} as SelectItem
	};
	const { public: publicRoles, owned: ownedRoles, shared: sharedRoles } = roles;

	const defaultItem: SelectItem = {
		label: translate(({ roles }) => roles.modals.roleModal.defaultRoles['custom']),
		value: 'custom'
	};

	items.default = defaultItem;

	publicRoles.forEach(role => {
		const item: SelectItem = {
			label: role.name,
			value: role.id
		};
		items.public.push(item);
	});

	sharedRoles.forEach(role => {
		const item: SelectItem = {
			label: role.name,
			value: role.id
		};
		items.shared.push(item);
	});

	ownedRoles.forEach(role => {
		const item: SelectItem = {
			label: role.name,
			value: role.id
		};
		items.owned.push(item);
	});

	return items;
}

export function generateModalTitle(
	type: TemplateRoleModalType,
	translate: TranslateFunction
): string {
	const titleByType = {
		[TemplateRoleModalType.Create]: translate(
			({ roles }) => roles.modals.roleModal.title.create
		),
		[TemplateRoleModalType.Update]: translate(
			({ roles }) => roles.modals.roleModal.title.update
		)
	};

	return titleByType[type];
}

export function isRoleNameUnique(
	roleName: string,
	{ roles, exclude = [] }: { roles: TemplateRole[]; exclude?: string[] }
) {
	roleName = roleName.trim().toLowerCase();

	const roleNames: string[] = [];

	roles.forEach(r => {
		if (exclude.includes(r.name.trim().toLowerCase())) return;

		roleNames.push(r.name.trim().toLowerCase());
	});

	return !roleNames.includes(roleName);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, removeSubscriptionPaymentMethod } from 'store/account/payments';

export function useRemoveSubscriptionPaymentMethod(): Operation<(cardNumber: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.REMOVE_SUBSCRIPTION_PAYMENT_METHOD);

	function handler(cardNumber: string) {
		dispatch(removeSubscriptionPaymentMethod(cardNumber));
	}

	return [{ loading, error }, handler];
}

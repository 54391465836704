import { useState, useMemo } from 'react';
import { EmptyListWrapper, Template } from 'components/Templates';
import { stringAsBoolean } from 'helpers/generic';
import { OtherTemplateIdsTypes, TemplateSearchType } from 'store/data/templates';
import {
	FilterByTypeComponent,
	SetState,
	TemplateShareLevel,
	TemplatesOwnership,
	SelectItem
} from 'types/index';
import {
	FiltersContainer,
	TemplatesListWrapper,
	TemplatesOwnershipFilters
} from './OtherTemplatesList.style';
import { Select } from 'components/UI/Interactables/Select';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useFetchedTemplates, useFilterBySearchTerm } from 'hooks/store';

const IS_PUBLIC_SHARING_ENABLED = stringAsBoolean(
	process.env.REACT_APP_USE_TEMPLATES_PUBLIC_SHARING!
);

interface Props {
	otherTemplatesIds: OtherTemplateIdsTypes;
	accessWrite: boolean;
	setShareLevel: SetState<TemplateShareLevel | null>;
	setTemplateIdForImport: SetState<string | null>;
}

export function OtherTemplatesList({
	otherTemplatesIds,
	accessWrite,
	setTemplateIdForImport,
	setShareLevel
}: Props) {
	const { translate } = useTranslation();

	const [ownershipLevel, setOwnershipLevel] = useState(
		IS_PUBLIC_SHARING_ENABLED ? TemplatesOwnership.Public : TemplatesOwnership.SharedWithMe
	);

	const { sharedWithUserIds, sharedWithProjectIds, sharedWithPublicIds } = otherTemplatesIds;

	const publicOwnershipSelected =
		IS_PUBLIC_SHARING_ENABLED && ownershipLevel === TemplatesOwnership.Public;
	const sharedWithMeOwnershipSelected = ownershipLevel === TemplatesOwnership.SharedWithMe;

	const selectedIds = publicOwnershipSelected
		? sharedWithPublicIds
		: sharedWithMeOwnershipSelected
		? sharedWithUserIds
		: sharedWithProjectIds;

	const templates = useFetchedTemplates();

	const templatesSearchType: TemplateSearchType[] = useMemo(
		() =>
			selectedIds.map(id => {
				const templateName = templates[id].templateName;

				return { id, templateName };
			}),
		[selectedIds, templates]
	);

	const filteredTemplates = useFilterBySearchTerm(
		templatesSearchType,
		FilterByTypeComponent.Templates
	) as TemplateSearchType[];

	const noTemplates = !filteredTemplates.length;
	const noTemplatesMessage = translate(({ templates }) => templates.emptyTemplateFilter);

	const ownershipLabelByName = {
		[TemplatesOwnership.Public]: translate(({ templates }) => templates.public),
		[TemplatesOwnership.SharedWithMe]: translate(({ templates }) => templates.sharedWithMe),
		[TemplatesOwnership.SharedWithProject]: translate(
			({ templates }) => templates.sharedWithProject
		)
	};

	const showSelectItems: SelectItem[] = [
		{
			label: ownershipLabelByName[TemplatesOwnership.SharedWithMe],
			value: TemplatesOwnership.SharedWithMe
		},
		{
			label: ownershipLabelByName[TemplatesOwnership.SharedWithProject],
			value: TemplatesOwnership.SharedWithProject
		}
	];

	if (IS_PUBLIC_SHARING_ENABLED) {
		showSelectItems.unshift({
			label: ownershipLabelByName[TemplatesOwnership.Public],
			value: TemplatesOwnership.Public
		});
	}

	return (
		<TemplatesListWrapper>
			<FiltersContainer>
				<TemplatesOwnershipFilters>
					<Flex>
						<Typography.Caption marginOffset={{ right: 0.8 }}>
							{translate(dict => dict.pagination.show)}
						</Typography.Caption>
						<Select
							type={s => s.Tag}
							items={showSelectItems}
							title={ownershipLabelByName[ownershipLevel]}
							width={16}
							offset={{ top: 2, left: 0 }}
							onSelect={item =>
								item.value && setOwnershipLevel(item.value as TemplatesOwnership)
							}
						/>
					</Flex>
				</TemplatesOwnershipFilters>
			</FiltersContainer>

			{/* LIST */}
			{filteredTemplates.map(template => (
				<Template
					key={template.id}
					templateId={template.id}
					accessWrite={accessWrite}
					setShareLevel={setShareLevel}
					setTemplateIdForImport={setTemplateIdForImport}
				/>
			))}

			{/* NO TEMPLATES */}
			{noTemplates && (
				<EmptyListWrapper>
					<Typography.Paragraph>{noTemplatesMessage}</Typography.Paragraph>
				</EmptyListWrapper>
			)}
		</TemplatesListWrapper>
	);
}

import { sendRequest, DASHBOARD_URL } from 'api/utils';
import {
	GetDashboardByIdRequest,
	GetDashboardByIdResponse,
	ApiDashboard,
	ListDashboardResponse,
	ListDashboardRequest,
	CreateDashboardResponse,
	CreateDashboardRequest,
	UpdateDashboardRequest,
	UpdateDashboardResponse,
	DeleteDashboardResponse,
	DeleteDashboardRequest,
	RemoveDashboardCardResponse,
	RemoveDashboardCardRequest,
	CreateDashboardCardResponse,
	CreateDashboardCardRequest,
	UpdateDashboardCardResponse,
	UpdateDashboardCardRequest
} from './types';

import { Dictionary } from 'environment';
import {
	CreateDashboardCardPayload,
	RemoveDashboardCardPayload
} from 'store/data/projectDashboard';

const methods = {
	// Generic Dashboard methods
	listDashboards: 'listDashboards',
	getDashboard: 'getDashboard',
	createDashboard: 'createDashboard',
	updateDashboard: 'updateDashboard',
	deleteDashboard: 'deleteDashboard',

	// Dashboard content (cards) - methods
	createDashboardCard: 'createCards',
	updateDashboardCard: 'updateCards',
	removeDashboardCard: 'deleteCards'
};

export default () => ({
	async listDashboards(projectId: string): Promise<ApiDashboard[]> {
		const { data }: ListDashboardResponse = await sendRequest<ListDashboardRequest>(
			DASHBOARD_URL,
			{
				method: methods.listDashboards,
				dashboard: { projectId: Number(projectId) }
			}
		);

		// if (data.httpStatusCode !== '200' || !data.dashboard) {
		// 	throw new Error(Dictionary.errors.api.projectDashboard.getDashboardsError);
		// }

		return data.dashboard ?? [];
	},
	async getDashboardById(projectId: string, dashboardId: number): Promise<ApiDashboard> {
		const { data }: GetDashboardByIdResponse = await sendRequest<GetDashboardByIdRequest>(
			DASHBOARD_URL,
			{
				method: methods.getDashboard,
				dashboard: {
					projectId: Number(projectId),
					dashboardId
				}
			}
		);

		if (data.httpStatusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.getDashboardError);
		}

		return data.dashboard[0];
	},
	async createDashboard(dashboard: ApiDashboard): Promise<ApiDashboard> {
		const { data }: CreateDashboardResponse = await sendRequest<CreateDashboardRequest>(
			DASHBOARD_URL,
			{
				method: methods.createDashboard,
				dashboard
			}
		);

		if (data.statusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.createDashboardError);
		}

		return data.dashboard;
	},
	async updateDashboard(dashboard: ApiDashboard): Promise<ApiDashboard> {
		const { data }: UpdateDashboardResponse = await sendRequest<UpdateDashboardRequest>(
			DASHBOARD_URL,
			{
				method: methods.updateDashboard,
				dashboard
			}
		);

		if (data.httpStatusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.updateDashboardError);
		}

		return data.dashboard;
	},
	async deleteDashboard(deletePayload: DeleteDashboardRequest): Promise<boolean> {
		const { data }: DeleteDashboardResponse = await sendRequest<DeleteDashboardRequest>(
			DASHBOARD_URL,
			{
				method: methods.deleteDashboard,
				...deletePayload
			}
		);

		if (data.ledidiStatusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.deleteDashboardError);
		}

		return !!data.dashboard;
	},
	async createDashboardCards(card: CreateDashboardCardPayload): Promise<boolean> {
		const { data }: CreateDashboardCardResponse = await sendRequest<CreateDashboardCardRequest>(
			DASHBOARD_URL,
			{
				method: methods.createDashboardCard,
				payload: card
			}
		);

		if (data.ledidiStatusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.createDashboardCardsError);
		}

		return !!data.dashboard;
	},
	async updateDashboardCards(card: CreateDashboardCardPayload): Promise<boolean> {
		const { data }: UpdateDashboardCardResponse = await sendRequest<UpdateDashboardCardRequest>(
			DASHBOARD_URL,
			{
				method: methods.updateDashboardCard,
				payload: card
			}
		);

		if (data.ledidiStatusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.updateDashboardCardsError);
		}

		return !!data.dashboard;
	},
	async removeDashboardCard(payload: RemoveDashboardCardPayload): Promise<ApiDashboard> {
		const { data }: RemoveDashboardCardResponse = await sendRequest<RemoveDashboardCardRequest>(
			DASHBOARD_URL,
			{
				method: methods.removeDashboardCard,
				payload
			}
		);

		if (data.ledidiStatusCode !== '200' || !data.dashboard) {
			throw new Error(Dictionary.errors.api.projectDashboard.deleteDashboardCardsError);
		}

		return data.dashboard;
	}
});

import { apiFactory } from 'api';
import { ApiPaymentIntent } from 'api/account/payments';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/account/payments';

export function useAPICreateSubscriptionPaymentIntent(): ApiOperation<
	ApiPaymentIntent | null,
	() => void
> {
	const [{ data, error, loading, fetched }, handler, resetData] = useAPI<ApiPaymentIntent | null>(
		{
			promiseFn: () =>
				apiFactory().account.payments().createSubscriptionPaymentIntent(0, 'EUR'),
			lazy: true,
			initialData: null,
			resetData: {
				onFetch: true
			},
			handleError: {
				showNotification: true,
				translateMessage: false
			},
			activityType: ActionTypes.CREATE_SUBSCRIPTION_PAYMENT_INTENT
		}
	);

	return [{ data, error, loading, fetched }, handler, resetData];
}

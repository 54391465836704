import produce from 'immer';

import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_SUBSCRIPTION_PAYMENT_METHODS:
		case ActionTypes.ADD_SUBSCRIPTION_PAYMENT_METHOD: {
			const { subscriptionPaymentMethods, selectedPaymentMethod } = action.payload;

			return produce(state, draft => {
				draft.selectedPaymentMethod = selectedPaymentMethod;
				draft.subscriptionPaymentMethods = subscriptionPaymentMethods;
			});
		}

		case ActionTypes.REMOVE_SUBSCRIPTION_PAYMENT_METHOD: {
			const { cardNumber } = action.payload;

			return produce(state, draft => {
				if (draft.subscriptionPaymentMethods) {
					draft.subscriptionPaymentMethods.cards =
						draft.subscriptionPaymentMethods.cards.filter(
							card => card.id !== cardNumber
						);
				}
			});
		}

		case ActionTypes.MAKE_PAYMENT_SOURCE_PRIMARY: {
			const { cardNumber } = action.payload;

			return produce(state, draft => {
				if (draft.subscriptionPaymentMethods) {
					draft.subscriptionPaymentMethods.cards.map(card =>
						card.id !== cardNumber ? (card.isPrimary = false) : (card.isPrimary = true)
					);
				}
			});
		}

		case ActionTypes.SET_SELECTED_PAYMENT_METHOD: {
			const { selectedPaymentMethod } = action.payload;

			return produce(state, draft => {
				if (draft.subscriptionPaymentMethods) {
					draft.selectedPaymentMethod = selectedPaymentMethod;
				}
			});
		}

		case ActionTypes.GET_SUBSCRIPTION_PAYMENTS: {
			const { subscriptionPayments, selectedPaymentMethod } = action.payload;
			return produce(state, draft => {
				draft.subscriptionPayments = subscriptionPayments;
				draft.selectedPaymentMethod = selectedPaymentMethod;
			});
		}

		case ActionTypes.GET_USER_BILLING_INFO: {
			return produce(state, draft => {
				const { billingInfo } = action.payload;
				draft.billingInfo = billingInfo;
			});
		}

		case ActionTypes.UPDATE_USER_BILLING_ADDRESS: {
			return produce(state, draft => {
				const { billingAddress } = action.payload;
				if (draft.billingInfo) draft.billingInfo.billingAddress = billingAddress;
			});
		}

		case ActionTypes.SET_CB_INSTANTIATED: {
			return produce(state, draft => {
				draft.cbInstantiated = true;
			});
		}

		default: {
			return state;
		}
	}
};

import styled from 'styled-components';

export const NoTableDependenciesContainer = styled.div`
	margin: 0 auto;
`;
export const Container = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
`;

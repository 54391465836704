import { AdminAccount } from 'api/account/admin';
import { Patient } from 'api/data/patients';
import { FilterByTypeComponent } from 'types/index';
import { Collaborator } from 'store/data/collaborators';
import { ProjectFile } from 'api/data/documents/types';
import { HelpTopicList, HelpTopicCategory } from 'store/data/help';
import { TemplateSearchType } from 'store/data/templates';
import { SubscriptionUser, UserLicenceLabels } from 'store/account/subscription';
import { useState, useEffect, useMemo } from 'react';
import {
	useAdminUsersSearchTerm,
	useCollaboratorsSearchTerm,
	useDocumentsSearchTerm,
	useHelpSearchTerm,
	usePatientSearchTerm,
	useSubscriptionUsersSearchTerm,
	useTemplatesSearchTerm
} from '..';
import { splitString } from 'helpers/strings';

export function useFilterBySearchTerm(data: any, type: FilterByTypeComponent) {
	const [filteredData, setFilteredData] = useState<
		| ProjectFile[]
		| Patient[]
		| Collaborator[]
		| HelpTopicList
		| TemplateSearchType[]
		| AdminAccount[]
		| SubscriptionUser[]
	>([]);

	const [patientsSearchTerm] = usePatientSearchTerm();
	const [collaboratorsSearchTerm] = useCollaboratorsSearchTerm();
	const [helpPageSearchTerm] = useHelpSearchTerm();
	const [userAdminSearchTerm] = useAdminUsersSearchTerm();
	const [projectFilesSearchTerm] = useDocumentsSearchTerm();
	const [templatesSearchTerm] = useTemplatesSearchTerm();
	const [subscriptionUserSearchTerm] = useSubscriptionUsersSearchTerm();

	const patientsSplitSearchTerm = useMemo(
		() => splitString(patientsSearchTerm),
		[patientsSearchTerm]
	);
	const collaboratorsSplitSearchTerm = useMemo(
		() => splitString(collaboratorsSearchTerm),
		[collaboratorsSearchTerm]
	);
	const helpPageSplitSearchTerm = useMemo(
		() => splitString(helpPageSearchTerm),
		[helpPageSearchTerm]
	);
	const userAdminSplitSearchTerm = useMemo(
		() => splitString(userAdminSearchTerm),
		[userAdminSearchTerm]
	);
	const projectFilesSplitSearchTerm = useMemo(
		() => splitString(projectFilesSearchTerm),
		[projectFilesSearchTerm]
	);
	const templatesSplitSearchTerm = useMemo(
		() => splitString(templatesSearchTerm),
		[templatesSearchTerm]
	);
	const subscriptionUserSplitSearchTerm = useMemo(
		() => splitString(subscriptionUserSearchTerm),
		[subscriptionUserSearchTerm]
	);

	// DOCUMENTS
	useEffect(() => {
		let resultData: ProjectFile[] = [];

		if (type === FilterByTypeComponent.Documents) {
			const files = data as ProjectFile[];

			if (!projectFilesSplitSearchTerm.length || !files) {
				resultData = files;
			} else {
				resultData = files.filter(file => {
					let matches = true;

					const keywords = [
						file.fileLabel.toLowerCase().trim(),
						file.mimeType.toLowerCase().trim()
					];

					projectFilesSplitSearchTerm.forEach(term => {
						let termFound = false;

						keywords.forEach(keyword => {
							if (keyword && keyword.indexOf(term) !== -1) termFound = true;
						});

						if (!termFound) matches = false;
					});

					return matches;
				});
			}
			setFilteredData(resultData);
		}
	}, [projectFilesSplitSearchTerm, data]);

	// PATIENTS
	useEffect(() => {
		let resultData: Patient[] = [];

		if (type === FilterByTypeComponent.Patients) {
			const patients = data as Patient[];

			resultData = patientsSplitSearchTerm.length
				? patients.filter(patient => {
						let matches = true;
						const keywords = [
							patient.firstName.toLowerCase().trim(),
							patient.lastName.toLowerCase().trim(),
							patient.emailAddress.toLowerCase().trim(),
							patient.patientId.toString().toLowerCase().trim(),
							patient.phoneNumber.toLowerCase().trim()
						];

						patientsSplitSearchTerm.forEach(term => {
							let termFound = false;

							keywords.forEach(keyword => {
								if (keyword.indexOf(term) !== -1) {
									termFound = true;
								}
							});

							if (!termFound) {
								matches = false;
							}
						});

						return matches;
				  })
				: patients;
			setFilteredData(resultData);
		}
	}, [patientsSplitSearchTerm, data]);

	// COLLABORATORS
	useEffect(() => {
		let resultData: Collaborator[] = [];

		if (type === FilterByTypeComponent.Collaborators) {
			const collaborators = data as Collaborator[];

			if (!collaboratorsSplitSearchTerm.length) {
				resultData = collaborators;
			} else {
				resultData = collaborators.filter(collaborator => {
					let matches = true;

					const keywords = [
						collaborator.emailAddress.toLowerCase().trim(),
						collaborator.userFirstName?.toLowerCase().trim(),
						collaborator.userSirName?.toLowerCase().trim(),
						collaborator.organization?.toLowerCase().trim(),
						collaborator.department?.toLowerCase().trim(),
						collaborator.position?.toLowerCase().trim()
					];

					collaboratorsSplitSearchTerm.forEach(term => {
						let termFound = false;

						keywords.forEach(keyword => {
							if (keyword && keyword.indexOf(term) !== -1) termFound = true;
						});

						if (!termFound) matches = false;
					});

					return matches;
				});
			}
			setFilteredData(resultData);
		}
	}, [collaboratorsSplitSearchTerm, data]);

	// HELP PAGE
	useEffect(() => {
		let resultData: HelpTopicCategory[] = [];

		if (type === FilterByTypeComponent.HelpPage) {
			const helpTopicList = data as HelpTopicList;

			if (!helpPageSplitSearchTerm.length) {
				resultData = helpTopicList;
			} else {
				const filtered: HelpTopicList = [];

				helpTopicList.forEach(helpTopic => {
					const { categoryName, categoryTopics } = helpTopic;

					const filteredTopic: HelpTopicCategory = {
						categoryName,
						categoryTopics: []
					};

					categoryTopics.forEach(topic => {
						const keywords = [...topic.topicFilters];

						let matches = false;

						helpPageSplitSearchTerm.forEach(term => {
							if (matches) return;

							let termFound = false;

							keywords.forEach(keyword => {
								if (keyword.indexOf(term) !== -1) termFound = true;
							});

							if (termFound) {
								matches = true;
								filteredTopic.categoryTopics.push(topic);
							}
						});
					});

					if (filteredTopic.categoryTopics.length) filtered.push(filteredTopic);
				});

				resultData = filtered;
			}
			setFilteredData(resultData);
		}
	}, [helpPageSplitSearchTerm, data]);

	// VARIABLE TEMPLATES
	useEffect(() => {
		let resultData: TemplateSearchType[] = [];

		if (type === FilterByTypeComponent.Templates) {
			const templates = data as TemplateSearchType[];

			if (!templatesSplitSearchTerm.length || !templates) {
				resultData = templates;
			} else {
				resultData = templates.filter(template => {
					const { templateName } = template;
					let matches = true;
					const keywords = [templateName.toLowerCase().trim()];

					templatesSplitSearchTerm.forEach(term => {
						let termFound = false;

						keywords.forEach(keyword => {
							if (keyword.indexOf(term) !== -1) {
								termFound = true;
							}
						});

						if (!termFound) {
							matches = false;
						}
					});

					return matches;
				});
			}
			setFilteredData(resultData);
		}
	}, [templatesSplitSearchTerm, data]);

	// ADMIN USERS
	useEffect(() => {
		let resultData: AdminAccount[] = [];

		if (type === FilterByTypeComponent.AdminUsers) {
			const users = data as AdminAccount[];

			if (!userAdminSplitSearchTerm.length || !users) {
				resultData = users;
			} else {
				resultData = users.filter(user => {
					let matches = true;

					const licenceLabel = UserLicenceLabels[user.licenceModel] ?? user.licenceModel;

					const keywords = [
						user.userid.toLowerCase().trim(),
						licenceLabel.toLowerCase().trim(),
						user.emailAddress.toLowerCase().trim(),
						user.userFirstName?.toLowerCase().trim(),
						user.userSirName?.toLowerCase().trim(),
						user.workplace?.toLowerCase().trim(),
						user.department?.toLowerCase().trim(),
						user.position?.toLowerCase().trim()
					];

					userAdminSplitSearchTerm.forEach(term => {
						let termFound = false;

						keywords.forEach(keyword => {
							if (keyword && keyword.indexOf(term) !== -1) termFound = true;
						});

						if (!termFound) matches = false;
					});

					return matches;
				});
			}
			setFilteredData(resultData);
		}
	}, [userAdminSplitSearchTerm, data]);

	// SUBSCRIPTION USERS
	useEffect(() => {
		let resultData: SubscriptionUser[] = [];

		if (type === FilterByTypeComponent.SubscriptionUsers) {
			const subscriptionUsers = data as SubscriptionUser[];

			if (!subscriptionUserSearchTerm.length || !subscriptionUsers) {
				resultData = subscriptionUsers;
			} else {
				resultData = subscriptionUsers.filter(user => {
					let matches = true;

					const keywords = [
						user.userid.toLowerCase().trim(),
						UserLicenceLabels[user.licenceModel]?.toLowerCase().trim(),
						user.emailAddress.toLowerCase().trim(),
						user.userFirstName?.toLowerCase().trim(),
						user.userSirName?.toLowerCase().trim(),
						user.organization?.toLowerCase().trim(),
						user.subOrganization?.toLowerCase().trim(),
						user.workplace?.toLowerCase().trim(),
						user.department?.toLowerCase().trim(),
						user.position?.toLowerCase().trim(),
						user.userRole?.toLowerCase().trim()
					];

					subscriptionUserSplitSearchTerm.forEach(term => {
						let termFound = false;

						keywords.forEach(keyword => {
							if (keyword && keyword.indexOf(term) !== -1) termFound = true;
						});

						if (!termFound) matches = false;
					});

					return matches;
				});
			}
			setFilteredData(resultData);
		}
	}, [data, subscriptionUserSplitSearchTerm]);

	return filteredData;
}

import { DragVariableContext } from 'components/Variables/VariablesGrid/DragVariableContext';
import { useContext } from 'react';

export function useDragVariableContext() {
	const context = useContext(DragVariableContext);

	if (context === undefined) {
		throw new Error('useAnalysisContext must be used within a `AnalysisContext.Provider`');
	}

	return context;
}

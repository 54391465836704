import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export enum DependencyType {
	Visibility = 'Visibility',
	Filtering = 'Filtering'
}

export interface PageParams {
	pageIndex: number;
	pageSize: number;
}

export enum ActionTypes {
	GET_DEPENDENCIES = 'data/dependencies/GET_DEPENDENCIES',
	CREATE_DEPENDENCIES = 'data/dependencies/CREATE_DEPENDENCIES',
	UPDATE_DEPENDENCIES = 'data/dependencies/UPDATE_DEPENDENCIES',

	SET_REFETCH_DEPENDENCIES = 'data/dependencies/SET_REFETCH_DEPENDENCIES',
	DEPENDENCIES_SET_TABLE_PAGE_PARAMS = 'data/dependencies/DEPENDENCIES_SET_TABLE_PAGE_PARAMS'
}

export interface State {
	projectId: string | null;
	byProjectId: GenericMap<{
		active: boolean;
		dependencies: Dependency[];
		bySetName: GenericMap<{
			active: boolean;
			dependencies: Dependency[];
		}>;
		metadata: {
			pageIndex: number;
			pageSize: number;
		};
		fetched: boolean;
		refetch: boolean;
	}>;
}

export enum OptionType {
	condition = 'Condition',
	rule = 'Rule'
}

export enum Conditions {
	editCondition = 'editCondition',
	deleteCondition = 'deleteCondition'
}

export enum Rules {
	newCondition = 'newCondition',
	editRule = 'editRule',
	deleteRule = 'deleteRule'
}

export enum DependenciesTableView {
	supplierVariableName = 'supplierVariableName',
	dependencyType = 'dependencyType',
	operator = 'operator',
	supplierValueCondition = 'supplierValueCondition',
	dependantVariableName = 'dependantVariableName',
	filteredValues = 'filteredValues',
	description = 'description'
}

export enum DependenciesTableViewData {
	supplierVariableName = 'supplierVariableName',
	dependencyType = 'dependencyType',
	operator = 'operator',
	supplierValueCondition = 'supplierValueCondition',
	dependantVariableName = 'dependantVariableName',
	filteredValues = 'filteredValues',
	description = 'description'
}

export enum DependencyOperators {
	LESS_THAN = '<',
	LESS_OR_EQUAL_TO = '<=',
	EQUAL_TO = '=',
	GREATER_OR_EQUAL_TO = '>=',
	GREATER_THAN = '>'
}

export enum DependenciesViewOptions {
	GRID = 'grid',
	TABLE = 'table'
}
export interface Dependant {
	dependantId: string; //__________________________ unique id for each dependant. Helps on FE logic
	supplierValueCondition: string; //_______________ supplierVariableValues item that enables
	dependantVariableName: string; //________________ dependant variable name
	// TODO: HERE WE SHOULD USE `Operator` TYPE
	operator: DependencyOperators; //________________ used for date, integer, float, string variables
	filteredValues: string[]; //_____________________ used for filtering. What values to show from dependant
}

export interface Dependency {
	dependencyName: string; //_______________________ generated by BE on save. Temp value used when creating, but not sent to BE
	supplierVariableName: string; //_________________ dependency head variable
	dependencyType: DependencyType; //_______________ filtering or visibility
	dependantVariables: Dependant[]; //______________ empty array when no dependencies exist or can not be shown
	description: string;
}

export interface TableDependency {
	dependencyName: string;
	supplierVariableName: string; //_________________ dependency head variable
	dependencyType: DependencyType; //_______________ filtering or visibility
	dependantVariableName: string; //________________ dependant variable name
	operator: DependencyOperators | string;
	supplierValueCondition: string;
	filteredValues: string[];
	description: string;
	dependantId: string;
	subRows?: SubRows[];
	isSubRow?: boolean;
	parentRowId?: string;
	dependantVariables?: Dependant[];
	isExpanded?: boolean;
}

export interface SubRows {
	dependantId: string;
	operator: DependencyOperators | string;
	dependantVariableName: string;
	supplierValueCondition: string;
	dependencyName: string;
	supplierVariableName: string;
	description: string;
	dependencyType: DependencyType;
	filteredValues: string[];
	isSubRow?: boolean;
	parentRowId?: string;
}
export interface DependenciesData {
	active: boolean;
	dependencies: Dependency[];
	dependenciesBySetName: GenericMap<{
		active: boolean;
		dependencies: Dependency[];
	}>;
}

export interface DependencyActions {
	// DEPENDENCY
	createDependency: (input?: { variableName: string }) => void;
	changeDependencyType: (input: {
		dependencyName: string;
		dependencyType: DependencyType;
	}) => void;
	changeDependencyDescription: (input: { dependencyName: string; description: string }) => void;

	changeDependencyVariable: (input: {
		dependencyName: string;
		supplierVariableName: string;
	}) => void;
	deleteDependency: (input: { dependencyName: string }) => void;

	// DEPENDANT
	createDependant: (input: { dependencyName: string }) => void;
	changeDependantVariable: (input: {
		dependencyName: string;
		dependantId: string;
		dependantVariableName: string;
	}) => void;
	changeDependantOperator: (input: {
		dependencyName: string;
		dependantId: string;
		operator: DependencyOperators;
	}) => void;
	setDependantFilteredValues: (input: {
		dependencyName: string;
		dependantId: string;
		filteredValues: string[];
	}) => void;
	deleteDependantFilteredValues: (input: { dependencyName: string; dependantId: string }) => void;
	setDependantSupplierValueCondition: (input: {
		dependencyName: string;
		dependantId: string;
		supplierValueCondition: string;
	}) => void;
	deleteDependant: (input: { dependencyName: string; dependantId: string }) => void;
}

export type GetDependenciesAction = Action<
	ActionTypes.GET_DEPENDENCIES,
	{
		projectId: string;
		dependenciesData: DependenciesData;
	}
>;

export type UpdateDependencyAction = Action<
	ActionTypes.UPDATE_DEPENDENCIES,
	{
		projectId: string;
		active: boolean;
		dependencies: Dependency[];
		setName?: string;
	}
>;

export type SetDependenciesTablePageParamsAction = Action<
	ActionTypes.DEPENDENCIES_SET_TABLE_PAGE_PARAMS,
	PageParams
>;
export type SetRefetchDependenciesAction = Action<ActionTypes.SET_REFETCH_DEPENDENCIES>;

export type Actions =
	| GetDependenciesAction
	| UpdateDependencyAction
	| SetRefetchDependenciesAction
	| SetDependenciesTablePageParamsAction;

import { LedidiStatusCode } from 'types/index';

export function ledidiStatusCodeToErrorMessage(ledidiStatusCode: string, var1?: string) {
	switch (ledidiStatusCode) {
		case LedidiStatusCode.JADBioDataContainsNullSamples:
			return 'Dataset contains null sample names.';
		case LedidiStatusCode.JADBioTokenExpired:
			return 'JADBio session has expired.';
		case LedidiStatusCode.JADBioTooFewSamplesPerClassForAnalysis:
			return 'Too few samples per class for analysis.';
		case LedidiStatusCode.JADBioTooFewSamplesForAnalysis:
			return 'Too few entries for analysis.';
		case LedidiStatusCode.JADBioSampleNameContainedTwice:
			return "Sample name '" + var1 + "' is contained more than once.";
		default:
			return null;
	}
}

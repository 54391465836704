import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';

import { ActionTypes, getUserTemplates } from 'store/data/templates';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity, useIsProjectValid } from 'hooks/store';

export function useUserTemplates(): OperationResult<string[]> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const data = useSelector(state => state.data.templates.sharedWithUser.ids);

	const fetched = useSelector(state => state.data.templates.sharedWithUser.fetched);

	const [{ loading, error }] = useActivity(ActionTypes.GET_USER_TEMPLATES);

	useEffect(() => {
		if (!fetched && !loading && !error && isProjectValid) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		dispatch(getUserTemplates());
	}

	return [{ loading, error, data, fetched }, handler];
}

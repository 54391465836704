import { NetworkRequestBase } from 'api/types';
import { sendRequest, USER_ACCOUNT_URL, USER_URL } from 'api/utils';
import { Dictionary } from 'environment';
import { AddSubscriptionUser, Organization } from 'store/account/subscription';
import { EntryNamesFromUserIds } from 'store/data/entries';
import { DeepPartial } from 'types/index';

import {
	AddUserSubscriptionResponse,
	ApiAddedSubscriptionUser,
	ApiSubscription,
	DoesUserExistApiResponse,
	GetAccountResponse,
	GetAvatarResponse,
	GetLicenceModelsResponse,
	GetOrganizationsRequest,
	GetOrganizationsResponse,
	GetSubscriptionResponse,
	StoreAvatarResponse,
	UpdateAccountResponse,
	ApiUserPendingInvitation,
	ApiRespondToSubscriptionInvitation,
	GetPendingInvitationResponse,
	ApiAccount,
	ApiCancelUserTransfer,
	CancelUserInvitationResponse,
	GetSubscriptionApiKeyResponse,
	GetNamesFromUserIdsRequest,
	GetNamesFromUserIdsResponse,
	ApiAccountDetails
} from './types';

export const methods = {
	// user
	getAccount: 'getUser',
	updateAccount: 'updateUser',
	getAvatar: 'getUserImageByURL',
	storeAvatar: 'storeUserImage',

	// subscription
	getSubscriptionApiKey: 'getSubscriptionApiKey',
	getUserSubscription: 'getUserSubscription',
	addUserToSubscription: 'addUserToSubscription',
	removeUserFromSubscription: 'removeUserFromSubscription',
	changeUserLicenceType: 'setUserLicenseType',
	doesUserExist: 'doesUserExist',

	// subscription invitations
	getPendingInvitations: 'getPendingInvitations',
	respondToSubscriptionInvitation: 'respondToSubscriptionInvitation',
	cancelUserSubscriptionInvitation: 'cancelUserSubscriptionInvitation',

	getOrganizations: 'getOrganizations',
	getLicenceModels: 'getListOfLicenseModels',
	getNamesFromUserIds: 'getNamesFromUserIds'
};

export default () => ({
	getAccount: async (): Promise<ApiAccount> => {
		const { data }: GetAccountResponse = await sendRequest(USER_URL, {
			method: methods.getAccount
		});

		if (!data.user) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetAccount);
		}

		return {
			user: { ...data.user },
			userSubscriptionAddons: data.userSubscriptionAddons ?? [], // TODO: remove fallback to `[]` when BE is ready
			activeUserSubscriptionAddons: data.activeUserSubscriptionAddons ?? [] // TODO: remove fallback to `[]` when BE is ready
		};
	},
	updateAccount: async (
		user: DeepPartial<ApiAccountDetails>,
		isUserActivating?: boolean
	): Promise<void> => {
		const requestBody = { method: methods.updateAccount, user };
		if (isUserActivating) Object.assign(requestBody, { isUserActivating });
		const { data }: UpdateAccountResponse = await sendRequest(USER_URL, requestBody);

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accountUM.couldNotUpdateAccount);
		}
	},
	getAvatar: async (imageURL: string): Promise<string> => {
		const { data }: GetAvatarResponse = await sendRequest(USER_URL, {
			method: methods.getAvatar,
			imageFileName: imageURL
		});

		if (!data.imageDataString) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetUserAvatar);
		}

		return data.imageDataString;
	},
	storeAvatar: async (imageFileName: string, imageString: string): Promise<string> => {
		const { data }: StoreAvatarResponse = await sendRequest(USER_URL, {
			method: methods.storeAvatar,
			imageFileName,
			imageString
		});

		if (!data.imageURL) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotUploadUserAvatar);
		}

		return data.imageURL;
	},

	getSubscription: async (): Promise<ApiSubscription> => {
		const { data }: GetSubscriptionResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.getUserSubscription
		});

		if (!data.subscriptionDetails && data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetSubscription);
		}

		return data;
	},

	addSubscriptionUser: async (user: AddSubscriptionUser): Promise<ApiAddedSubscriptionUser> => {
		const { data }: AddUserSubscriptionResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.addUserToSubscription,
			user
		});

		if (!data.ledidiStatusCode) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotAddSubscriptionUser);
		}

		return {
			userid: data.user ? data.user.userid : undefined,
			ledidiStatusCode: data.ledidiStatusCode
		};
	},

	getPendingInvitations: async (): Promise<ApiUserPendingInvitation[]> => {
		const { data }: GetPendingInvitationResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.getPendingInvitations
		});

		if (!data.pendingInvitations || !Array.isArray(data.pendingInvitations)) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetPendingInvitation);
		}

		return data.pendingInvitations;
	},

	respondToSubscriptionInvitation: async ({
		isSubscriptionAccepted,
		userId
	}: ApiRespondToSubscriptionInvitation): Promise<void> => {
		await sendRequest(USER_ACCOUNT_URL, {
			method: methods.respondToSubscriptionInvitation,
			isSubscriptionAccepted,
			userId
		});
	},

	cancelUserSubscriptionInvitation: async ({
		userId
	}: ApiCancelUserTransfer): Promise<boolean> => {
		const { data }: CancelUserInvitationResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.cancelUserSubscriptionInvitation,
			userId
		});

		if (!data.subscriptionInvitationCancelled) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotCancelUserInvitation);
		}

		return data.subscriptionInvitationCancelled;
	},

	removeSubscriptionUser: async (userId: string) => {
		await sendRequest(USER_ACCOUNT_URL, {
			method: methods.removeUserFromSubscription,
			userId
		});
	},

	changeUserLicenceModel: async (userId: string, licenseType: string) => {
		await sendRequest(USER_ACCOUNT_URL, {
			method: methods.changeUserLicenceType,
			userId,
			licenseType
		});
	},

	getSubscriptionApiKey: async () => {
		const { data }: GetSubscriptionApiKeyResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.getSubscriptionApiKey
		});

		return data.apiKey ?? '';
	},

	doesUserExist: async (emailAddress: string) => {
		const { data }: DoesUserExistApiResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.doesUserExist,
			emailAddress
		});

		if (!data) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotVerifyIfUserExists);
		}

		return data;
	},

	getOrganizations: async (): Promise<Organization[]> => {
		const { data }: GetOrganizationsResponse = await sendRequest<GetOrganizationsRequest>(
			USER_URL,
			{
				method: methods.getOrganizations
			}
		);

		if (!data.organizations) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetOrganizations);
		}

		return data.organizations.filter(org => org.organizationName && org.organizationId);
	},

	getLicenceModels: async (): Promise<string[]> => {
		try {
			const {
				data: { licenseModels }
			}: GetLicenceModelsResponse = await sendRequest<NetworkRequestBase>(USER_URL, {
				method: methods.getLicenceModels
			});

			return licenseModels;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.getLicenceModels);
		}
	},

	getNamesFromUserIds: async (userIds: Array<string>): Promise<EntryNamesFromUserIds> => {
		try {
			const { data } = await sendRequest<
				GetNamesFromUserIdsRequest,
				GetNamesFromUserIdsResponse
			>(USER_URL, {
				method: methods.getNamesFromUserIds,
				userIds
			});

			return data.namesFromUserIds ?? {};
		} catch (e) {
			return {};
		}
	}
});

import produce from 'immer';

import { GenericMap } from 'types/index';

import initialState from './initialState';
import { Actions, ActionTypes, State, Status } from './types';

import { Actions as ProjectActions, ActionTypes as ProjectActionTypes } from '../projects/types';

export default (state: State = initialState, action: Actions | ProjectActions): State => {
	switch (action.type) {
		case ProjectActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId && !byProjectId[projectId]) {
					byProjectId[projectId] = {
						byName: {},
						names: [],
						fetched: false
					};
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.GET_STATUS: {
			const { status } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const data = byProjectId[projectId];

					data.byName[status.name] = status;
					data.names.push(status.name);
				}
			});
		}

		case ActionTypes.GET_STATUSES: {
			const { projectId, statuses } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId in byProjectId) {
					const data = byProjectId[projectId];

					const byName: GenericMap<Status> = {};
					const names: string[] = [];

					statuses.forEach(status => {
						byName[status.name] = status;
						names.push(status.name);
					});

					data.byName = byName;
					data.names = names;
					data.fetched = true;
				}
			});
		}

		case ActionTypes.CREATE_STATUS: {
			const { status } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { byName, names } = byProjectId[projectId];

					byName[status.name] = status;
					names.push(status.name);
				}
			});
		}

		case ActionTypes.UPDATE_STATUS: {
			const { status } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { byName } = byProjectId[projectId];

					byName[status.name] = status;
				}
			});
		}

		case ActionTypes.DELETE_STATUS: {
			const { statusName } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const data = byProjectId[projectId];

					delete data.byName[statusName];
					data.names = data.names.filter(name => name !== statusName);
				}
			});
		}

		case ActionTypes.CREATE_STATUSES: {
			const { statuses } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { byName, names } = byProjectId[projectId];

					statuses.forEach(status => {
						byName[status.name] = status;
						names.push(status.name);
					});
				}
			});
		}

		default: {
			return state;
		}
	}
};

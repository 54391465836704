import { useEffect } from 'react';
import { Patient } from 'api/data/patients';
import { PatientCard } from 'components/Proms/Patients/PatientCard';
import { selectPatientsPageParams, setPatientsPageIndex } from 'store/data/patients';
import { FilterByTypeComponent, SetState } from 'types/index';
import { Header, Container, NoPatients } from './PatientList.style';
import { Typography } from 'components/UI/Typography';
import { Pagination } from 'components/UI/Pagination';
import { Spacer } from 'components/UI/Spacer';
import { paginate } from 'helpers/generic';
import { useDispatch, usePrevious, useSelector } from 'hooks/utils';
import { useTranslation, useFilterBySearchTerm } from 'hooks/store';

interface Props {
	patients: Patient[];
	filteredEntriesCount: number;
	setSelectedPatient: SetState<Patient | null>;
}

export function PatientList({ patients, setSelectedPatient }: Props) {
	const dispatch = useDispatch();
	const { translate } = useTranslation();

	const { pageIndex, pageSize, term } = useSelector(state =>
		selectPatientsPageParams(state.data.patients)
	);

	const previousTerm = usePrevious(term);
	useEffect(() => {
		if (previousTerm !== term) resetPageIndex();
	}, [term]);

	function handleSetPageIndex(pageIndex: number) {
		dispatch(setPatientsPageIndex({ pageIndex }));
	}

	function resetPageIndex() {
		handleSetPageIndex(0);
	}

	const filteredPatients = useFilterBySearchTerm(
		patients,
		FilterByTypeComponent.Patients
	) as Patient[];

	const patientsForPages = paginate(filteredPatients, pageSize, pageIndex);
	const pagesCount = Math.ceil(filteredPatients.length / pageSize);

	return (
		<Container>
			<Header>
				<Typography.H4>
					{translate(dict => dict.promsPatients.patientList.title)}
				</Typography.H4>
				<div>
					<Pagination
						simpleVersion
						totalCount={filteredPatients.length}
						filteredCount={patientsForPages.length}
						pageIndex={pageIndex}
						pageSize={pageSize}
						pagesCount={pagesCount}
						changePage={handleSetPageIndex}
					/>
				</div>
			</Header>
			<Spacer size={s => s.s} />
			{patientsForPages.length ? (
				patientsForPages.map(patient => (
					<PatientCard
						key={patient.patientId}
						patient={patient}
						setSelectedPatient={setSelectedPatient}
					/>
				))
			) : (
				<NoPatients>
					{translate(dict => dict.promsPatients.patientList.noPatientsToDisplay)}
				</NoPatients>
			)}
		</Container>
	);
}

import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.maxWidth.xl} {
		height: auto;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 50%;
	min-height: 20rem;
`;

export const Entries = styled.h1`
	font-size: 6.4rem;
	color: ${Colors.silver.lighter};
`;

export const EntriesContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
`;

export const Label = styled.p`
	font-size: 1.2rem;
	color: ${Colors.silver.lighter};
	text-transform: uppercase;
`;

export const Text = styled.h4`
	color: ${Colors.silver.light};
	text-align: center;
	width: 25rem;
	margin: 4rem 0;
`;

import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
`;

export const Column = styled.div`
	flex: 1;

	:first-of-type {
		margin-right: 2rem;
	}
`;

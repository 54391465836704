import { useState, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Variable } from 'api/data/variables';
import { supportsTouch } from 'consts';
import {
	FormCard,
	DeleteFormModal,
	NoForms,
	CreateFormModal,
	RenameFormModal,
	FormsSection
} from 'components/Forms';
import { FormsFilterShowOptions } from 'store/data/forms';
import { Select } from 'components/UI/Interactables/Select';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Grid } from 'components/UI/Grid';
import { InfoMessage } from 'components/UI/InfoMessage';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { filterFormsDataBySearchTerm } from 'helpers/forms';
import { variablesOrderIterator } from 'helpers/variables';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	useScopedProjects,
	useFormId,
	useProjectId,
	useVariables,
	useVariablesData,
	usePermissions,
	useForms,
	useFormsFilters,
	useFormsSectionCollapsed,
	useFormsBySetName
} from 'hooks/store';

export function FormsPage() {
	const { routes, navigate, promOrProject } = useNavigation();

	const { translate } = useTranslation();
	const [{ loading: loadingProjects }] = useScopedProjects({
		lazy: true
	});

	const [, setFormId] = useFormId();
	const [projectId] = useProjectId();

	const [
		{
			data: { variablesMap, groupsMap, variableSetsMap, variablesOutsideSets },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true });
	const variablesData = useVariablesData({ initial: true });

	const { hasVariablesWriteAccess } = usePermissions();

	const [{ data: forms, loading: loadingForms, fetched: areFormsFetched }] = useForms();

	const [{ filters }, { setShowFilter }] = useFormsFilters();
	const [sectionCollapsedMap, toggleSectionCollapsed] = useFormsSectionCollapsed();

	const [createFormModalVisible, setCreateFormModalVisible] = useState(false);
	const [renameFormModalVisible, setRenameFormModalVisible] = useState(false);
	const [deleteFormModalVisible, setDeleteFormModalVisible] = useState(false);

	const [selectedFormId, setSelectedFormId] = useState<string | null>(null);

	// TODO: move in redux maybe
	const [searchTerm, setSearchTerm] = useState('');
	const isSearchTermValid = searchTerm.trim().length > 0;

	const formsBySetName = useFormsBySetName();

	const filteredFormsData = useMemo(
		() => filterFormsDataBySearchTerm({ forms, formsBySetName }, searchTerm),
		[forms, formsBySetName, searchTerm]
	);

	function handleFormRename(formId: string) {
		setSelectedFormId(formId);
		setRenameFormModalVisible(true);
	}

	function handleFormDelete(formId: string) {
		setSelectedFormId(formId);
		setDeleteFormModalVisible(true);
	}

	function isSectionVisible(filterShowOption: FormsFilterShowOptions) {
		return [FormsFilterShowOptions.ALL, filterShowOption].includes(filters.show);
	}

	function handleFormClick(formId: string, newTab?: boolean) {
		if (projectId) {
			if (newTab) {
				window.open(routes[promOrProject].forms.form(projectId, formId));

				return;
			}

			setFormId(formId);
			navigate(routes[promOrProject].forms.form(projectId, formId));
		}
	}

	const mainSection = {
		label: translate(dict => dict.formDesigner.mainSection),
		value: 'main_section'
	};

	const setForms = Object.values(formsBySetName).flat();
	const hasForms = forms.length > 0 || setForms.length > 0;

	const loadingFormsInitial = loadingForms && !areFormsFetched;
	const suspendProps = {
		loading: loadingFormsInitial || loadingVariables || loadingProjects,
		immediate: !areFormsFetched || !areVariablesFetched
	};

	const setScopeVariablesMap = useMemo(() => {
		const setScopeVariablesMap: Record<string, Variable[]> = {};

		for (const setName in variableSetsMap) {
			const variableSet = variableSetsMap[setName];

			const setVariables: Variable[] = [];

			variablesOrderIterator(
				variableSet.setOrder,
				variableName => setVariables.push(variablesMap[variableName]),
				groupName => {
					const group = groupsMap[groupName];

					group.variablesBelongingToGroup.forEach(variableName =>
						setVariables.push(variablesMap[variableName])
					);
				},
				// VARIABLE SET - OMIT
				() => null
			);

			setScopeVariablesMap[setName] = setVariables;
		}

		return setScopeVariablesMap;
	}, [variableSetsMap]);

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					!suspendProps.immediate &&
					hasVariablesWriteAccess &&
					hasForms && (
						<Flex>
							<SearchInput
								usedInHeader
								placeholder={translate(dict => dict.formDesigner.searchForms)}
								term={searchTerm}
								onChangeTerm={term => term !== searchTerm && setSearchTerm(term)}
							/>

							{addButton({
								label: translate(dict => dict.formDesigner.createNewForm),
								action: () => setCreateFormModalVisible(true)
							})}
						</Flex>
					)
				}
			/>
			<Header.Title
				title={translate(dict => dict.projectTabs.forms)}
				component={
					<Flex>
						{/* CLEAR FILTERS */}
						{isSearchTermValid && (
							<>
								{hasVariablesWriteAccess && (
									<Flex align={a => a.center} marginOffset={{ x: 1.6 }}>
										<InfoMessage
											message={translate(
												dict => dict.variables.header.clearFilters.message
											)}
										/>
									</Flex>
								)}
								<Button
									title={translate(
										dict => dict.variables.header.clearFilters.button
									)}
									variant={v => v.link}
									marginOffset={{ right: 2.4 }}
									onClick={() => setSearchTerm('')}
								/>
							</>
						)}
					</Flex>
				}
			/>

			<Suspend
				loading={loadingFormsInitial || loadingVariables || loadingProjects}
				immediate={!areFormsFetched || !areVariablesFetched}
			>
				<Grid.Container>
					{/* NO FORMS */}
					{!hasForms && <NoForms onCreateClick={() => setCreateFormModalVisible(true)} />}

					{/* FORMS LIST */}
					{hasForms && (
						<DndProvider backend={supportsTouch ? TouchBackend : HTML5Backend}>
							<Flex align={a => a.center}>
								<Flex>
									<Typography.Caption marginOffset={{ right: 0.8 }}>
										{translate(dict => dict.dataset.seriesEntryForm.show)}
									</Typography.Caption>
									<Select
										type={t => t.Tag}
										title={translate(
											dict => dict.formDesigner.showOptions[filters.show]
										)}
										width={12}
										items={Object.values(FormsFilterShowOptions).map(value => ({
											label: translate(
												dict => dict.formDesigner.showOptions[value]
											),
											value
										}))}
										onSelect={item =>
											setShowFilter(item.value as FormsFilterShowOptions)
										}
									/>
								</Flex>
							</Flex>

							<Spacer size={s => s.xs} />

							{isSectionVisible(FormsFilterShowOptions.MAIN) && (
								<>
									<FormsSection
										title={mainSection.label}
										forms={filteredFormsData.forms}
										open={!sectionCollapsedMap[mainSection.value]}
										onToggle={() =>
											toggleSectionCollapsed({
												sectionName: mainSection.value
											})
										}
										renderFormCard={(form, index) => (
											<FormCard
												key={index}
												form={form}
												index={index}
												preventDrag={isSearchTermValid}
												variables={variablesOutsideSets}
												variablesData={variablesData}
												onClick={handleFormClick}
												onRename={handleFormRename}
												onDelete={handleFormDelete}
											/>
										)}
									/>

									<Spacer size={s => s.xs} />
								</>
							)}

							{isSectionVisible(FormsFilterShowOptions.VARIABLE_SETS) && (
								<Gap marginGap={{ bottom: 0.8 }}>
									{Object.entries(filteredFormsData.formsBySetName).map(
										([setName, forms]) => {
											if (!forms.length) return null;

											const setLabel = variableSetsMap[setName]?.setLabel;
											const setScopeVariables = setScopeVariablesMap[setName];

											return (
												<FormsSection
													key={setName}
													title={setLabel}
													forms={forms}
													open={!sectionCollapsedMap[setName]}
													onToggle={() =>
														toggleSectionCollapsed({
															sectionName: setName
														})
													}
													renderFormCard={(form, index) => (
														<FormCard
															key={index}
															form={form}
															index={index}
															preventDrag={isSearchTermValid}
															variables={setScopeVariables}
															variablesData={variablesData}
															onClick={handleFormClick}
															onRename={handleFormRename}
															onDelete={handleFormDelete}
														/>
													)}
												/>
											);
										}
									)}
								</Gap>
							)}
						</DndProvider>
					)}
				</Grid.Container>
			</Suspend>

			{/*
				=============
					MODALS
				=============
			*/}

			{/* CREATE FORM MODAL */}
			{createFormModalVisible && (
				<CreateFormModal onClose={() => setCreateFormModalVisible(false)} />
			)}

			{/* RENAME FORM MODAL */}
			{renameFormModalVisible && selectedFormId !== null && (
				<RenameFormModal
					formId={selectedFormId}
					onClose={() => setRenameFormModalVisible(false)}
				/>
			)}

			{/* DELETE FORM MODAL */}
			{deleteFormModalVisible && selectedFormId !== null && (
				<DeleteFormModal
					formId={selectedFormId}
					onClose={() => setDeleteFormModalVisible(false)}
				/>
			)}
		</>
	);
}

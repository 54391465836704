import { DATA_URL, sendRequest } from 'api/utils';

import {
	GetDependenciesInput,
	GetDependenciesRequest,
	GetDependenciesResponse,
	GetDependenciesOutput,
	////////////////////////
	UpdateDependenciesInput,
	UpdateDependenciesRequest,
	UpdateDependenciesResponse,
	UpdateDependenciesOutput
} from './types';
import { AxiosRequestConfig } from 'axios';

const methods = {
	getVariableDependencies: 'getVariableDependencies',
	updateVariableDependencies: 'updateVariableDependencies'
};

export default () => ({
	async getDependencies(
		input: GetDependenciesInput,
		config?: AxiosRequestConfig
	): Promise<GetDependenciesOutput> {
		const { data } = await sendRequest<GetDependenciesRequest, GetDependenciesResponse>(
			DATA_URL,
			{
				method: methods.getVariableDependencies,
				...input
			},
			config
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return {
			dependenciesData: {
				active: data.active,
				dependencies: data.dependencies,
				dependenciesBySetName: data.dependenciesBySetName
			}
		};
	},

	async updateDependencies(input: UpdateDependenciesInput): Promise<UpdateDependenciesOutput> {
		const { data } = await sendRequest<UpdateDependenciesRequest, UpdateDependenciesResponse>(
			DATA_URL,
			{
				method: methods.updateVariableDependencies,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return {
			active: data.active,
			dependencies: data.dependencies
		};
	}
});

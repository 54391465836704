import { HoverableArea, ElementMenu, ElementMenuControls, DeleteIcon } from 'components/Forms';
import { Svgs } from 'environment';
import { Typography } from 'components/UI/Typography';
import { useRemoveFormElement, useTranslation } from 'hooks/store';

interface Props {
	elementId: string;
}

export function FormVariableNotFound({ elementId }: Props) {
	const { translate } = useTranslation();

	const removeFormElement = useRemoveFormElement();

	return (
		<HoverableArea usedInFormDesigner>
			<ElementMenu usedInFormDesigner>
				<Typography.Paragraph>
					{translate(({ formDesigner }) => formDesigner.noVariableFound)}
				</Typography.Paragraph>
			</ElementMenu>
			<ElementMenuControls>
				<DeleteIcon
					svg={Svgs.Delete}
					size={s => s.m}
					onClick={() => removeFormElement({ elementId })}
				/>
			</ElementMenuControls>
		</HoverableArea>
	);
}

import { useEffect } from 'react';

import { useActivity, useIsProjectValid, useProjectId } from 'hooks/store';
import { useSelector, useDispatch } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getDashboardById,
	selectDashboardById,
	Dashboard,
	selectDashboardId,
	selectDashboardFetched
} from 'store/data/projectDashboard';

interface Options {
	dashboardId: number;
	lazy?: boolean;
}

interface DataProps {
	dashboard: Dashboard | null;
}

export function useDashboardById(options?: Options): OperationResult<DataProps> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const dashboardId = useSelector(state => selectDashboardId(state.data.projectDashboard));
	const dashboard = useSelector(state => selectDashboardById(state.data.projectDashboard));
	const fetched = useSelector(state => selectDashboardFetched(state.data.projectDashboard));

	// to be decided a fair ammount of time when dashboard can be refetched if shared
	// const lastFetched =

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_DASHBOARD_BY_ID, {
		projectId
	});

	useEffect(() => {
		if (!loading && !error && isProjectValid && !options?.lazy && !fetched) {
			handler();
		}
	}, [loading, error, isProjectValid]);

	function handler() {
		if (loading || dashboardId === -1 || !dashboardId) return;
		if (error) resetError();

		dispatch(getDashboardById(dashboardId));
	}

	return [
		{
			loading,
			error,
			data: { dashboard }
		},
		handler
	];
}

import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { setPatientLoginParams, PatientLoginParams, SetPatientLoginParamsAction } from 'store/auth';
import { ActionPayload } from 'store/types';

export function usePatientLoginParams(): LocalOperationResult<
	PatientLoginParams | null,
	(payload: ActionPayload<SetPatientLoginParamsAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.auth.patientLoginParams);

	function handler(payload: ActionPayload<SetPatientLoginParamsAction>) {
		dispatch(setPatientLoginParams(payload));
	}

	return [data, handler];
}

import { Svgs } from 'environment';
import { Form } from 'store/data/forms';

import { FormsListContainer } from 'pages/Forms/FormsPage.style.';
import { useTranslation } from 'hooks/store';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';

interface Props {
	title: string;
	forms: Form[];
	open: boolean;
	onToggle: () => void;
	renderFormCard: (form: Form, index: number) => React.ReactNode;
}

export function FormsSection({ title, forms, open, onToggle, renderFormCard }: Props) {
	const { translate } = useTranslation();
	function getCollapsedMessage() {
		const hasOneForm = forms.length === 1;

		return `${forms.length} ${translate(dict => dict.formDesigner.form).toLowerCase()}${
			hasOneForm ? '' : 's'
		} ${translate(dict => dict.formDesigner.collapsed)}`;
	}

	return (
		<Flex column>
			<Flex justify={j => j.between}>
				<Flex
					align={a => a.center}
					css={`
						max-width: 100%;
					`}
				>
					<Typography.H6 title={title} ellipsis>
						{title}
					</Typography.H6>

					{!open && (
						<Typography.Caption
							title={title}
							marginOffset={{ x: 1.6 }}
							css={`
								white-space: nowrap;
							`}
						>
							{getCollapsedMessage()}
						</Typography.Caption>
					)}
				</Flex>
				<Icon svg={open ? Svgs.ArrowUp : Svgs.ArrowDown} onClick={onToggle} />
			</Flex>

			{open && <FormsListContainer>{forms.map(renderFormCard)}</FormsListContainer>}

			{!open && <Spacer size={s => s.m} />}
		</Flex>
	);
}

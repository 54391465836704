import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setDestinationGroupName, SetDestinationGroupNameAction } from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useDestinationGroupName(): LocalOperationResult<
	string | null,
	(groupName: ActionPayload<SetDestinationGroupNameAction>['groupName']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.variables.metadata.destinationGroupName);

	function handler(groupName: ActionPayload<SetDestinationGroupNameAction>['groupName']) {
		dispatch(setDestinationGroupName({ groupName }));
	}

	return [data, handler];
}

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { useTranslation, useRemoveSubscriptionUser } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { UserLicenceModel } from 'store/account/subscription';

interface Props {
	userId: string | null;
	userName: string | null;
	licenceModel: UserLicenceModel | null;
	onClose: (deleted?: boolean) => void;
}

export function DeleteSubscriptionUserModal({ userId, userName, licenceModel, onClose }: Props) {
	const { translate } = useTranslation();
	const [{ loading: removingUser, error: errorRemovingUser }, removeUser] =
		useRemoveSubscriptionUser();

	function onConfirm() {
		if (userId && licenceModel) {
			removeUser(userId, licenceModel);
		}
	}

	useCompletedAction(removingUser, errorRemovingUser, () => onClose(true));

	return (
		<Modal
			visible={!!userId}
			title={translate(dict => dict.promptToDelete.title)}
			size={s => s.s}
			primary={{
				label: translate(dict => dict.buttons.confirm),
				loading: removingUser,
				warning: true,
				onClick: onConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.abort),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.promptToDelete.description.userLicence, false, {
					user: userName ?? ''
				})}
			</Typography.Paragraph>
			<Typography.Paragraph
				marginOffset={{ top: 2.4 }}
				color={Colors.text.error}
				fontweight={w => w.bold}
			>
				{translate(dict => dict.promptToDelete.description.undoneWarning)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { useActivity, useProjectId } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { ActionTypes, initializeVariablesMapping } from 'store/data/variablesMapping';

type HandlerInput = Parameters<typeof initializeVariablesMapping>;

export function useInitializeVariablesMapping(): Operation<
	(...input: HandlerInput) => Promise<void>
> {
	const dispatch = useDispatch();

	const [projectId] = useProjectId();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.INITIALIZE_VARIABLES_MAPPING, {
		projectId
	});

	async function handler(...input: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		await dispatch(initializeVariablesMapping(...input));
	}

	return [{ loading, error }, handler];
}

import { useMemo } from 'react';
import {
	useInitiateJADBioDataset,
	useTranslation,
	useLogInToJADBio,
	useGetJADBioProjectId
} from 'hooks/store';

export function useInitiatigJADBioMessage(JADBioAnalysisId: string): {
	message: string | null;
	retry: (() => void) | null;
} {
	const { translate } = useTranslation();

	const [{ loading: logginIntoJadBio }] = useLogInToJADBio();

	const [
		{ fetched: JADBioProjectFetched, loading: JADBioProjectLoading, error: JADBioProjectError },
		initiateProjectDataset
	] = useGetJADBioProjectId();
	const [
		{
			data: datasetInitialized,
			loading: initiatingJadBioDataset,
			error: initiateJadBioDatasetError
		},
		initiateJadBioDataset
	] = useInitiateJADBioDataset(JADBioAnalysisId);

	const initiatingJADBioMessage: string | null = useMemo(
		() =>
			logginIntoJadBio
				? translate(({ analysis }) => analysis.analyses.JADBio.loggingJADBio)
				: JADBioProjectLoading
				? translate(({ analysis }) => analysis.analyses.JADBio.fetchingJADBioProject)
				: JADBioProjectError
				? translate(({ analysis }) => analysis.analyses.JADBio.fetchingJADBioProjectError)
				: !JADBioProjectFetched
				? translate(dict => dict.analysis.analyses.JADBio.projectNotFetched)
				: initiatingJadBioDataset
				? translate(({ analysis }) => analysis.analyses.JADBio.initiatingJADBioDataset)
				: initiateJadBioDatasetError
				? translate(({ analysis }) => analysis.analyses.JADBio.initiatingJADBioDatasetError)
				: !datasetInitialized
				? translate(dict => dict.analysis.analyses.JADBio.jadbioDatasetNotInitialized)
				: null,
		[
			logginIntoJadBio,
			JADBioProjectLoading,
			JADBioProjectError,
			initiatingJadBioDataset,
			initiateJadBioDatasetError,
			JADBioProjectFetched,
			datasetInitialized
		]
	);

	const errorCanBeRetried = useMemo(
		() =>
			!logginIntoJadBio &&
			!JADBioProjectLoading &&
			!initiatingJadBioDataset &&
			(JADBioProjectError || initiateJadBioDatasetError),
		[
			logginIntoJadBio,
			JADBioProjectLoading,
			initiatingJadBioDataset,
			JADBioProjectError,
			initiateJadBioDatasetError
		]
	);

	function retry() {
		if (JADBioProjectError) {
			initiateProjectDataset();
		} else if (initiateJadBioDatasetError) {
			initiateJadBioDataset();
		}
	}

	return { message: initiatingJADBioMessage, retry: errorCanBeRetried ? retry : null };
}

import {
	CorrelationsGroup,
	CorrelationsResultsV2,
	GetCorrelationsResponse,
	GetGroupedCorrelationsData
} from 'api/data/analyses';

// type guards
function isGroupedCorrelationData(
	data: GetCorrelationsResponse['data']['result']
): data is GetGroupedCorrelationsData[] {
	return 'length' in data;
}

export function parseCorrelationsResponse(res: GetCorrelationsResponse): CorrelationsResultsV2 {
	const result: CorrelationsResultsV2 = { data: [] };
	if (isGroupedCorrelationData(res.data.result)) {
		const groupedData = res.data.result;

		// any error triggers no data response for grouping!
		const errored = groupedData.find(data => !!data.values.errors);

		if (errored)
			return {
				data: [
					{
						group: '',
						xValues: [],
						yValues: [],
						errors: errored.values.errors
					}
				]
			};

		groupedData.forEach(data => {
			(result.data as CorrelationsGroup[]).push({
				errors: [
					...(data.values.errors && data.values.errors?.[0]
						? [
								{
									code: data.values.errors?.[0].code,
									message: data.values.errors?.[0].message
								}
						  ]
						: [])
				],
				group: data.groupIdentifiers[0].value,
				xValues: data.values.xValues,
				yValues: data.values.yValues
			});
		});
	}

	if (!isGroupedCorrelationData(res.data.result)) {
		// any error triggers toast message AND no data error;
		if (res.data.result.errors?.[0]) {
			throw new Error(res.data.result.errors?.[0].message);
		}

		const ungroupedData = {
			...res.data.result,
			group: 'default',
			errors: [
				...(res.data.result.errors && res.data.result.errors?.[0]
					? [
							{
								code: res.data.result.errors?.[0].code,
								message: res.data.result.errors?.[0].code
							}
					  ]
					: [])
			]
		};

		result.data = [ungroupedData];
	}

	return result;
}

import { CollaboratorPermissions } from 'store/data/collaborators';

export function getPermissions(permissions?: CollaboratorPermissions) {
	return {
		...permissions,
		hasOwnershipAllAccess:
			permissions?.projectOwnerHasAccessEntriesAllTransferOwnership ?? false,
		hasVariablesAccess: permissions
			? permissions.accessVariablesRead || permissions.accessVariablesWrite
			: false,
		hasVariablesReadAccess: permissions ? permissions.accessVariablesRead : false,
		hasVariablesWriteAccess: permissions ? permissions.accessVariablesWrite : false,
		hasDatasetReadAccess: permissions
			? permissions.accessEntriesAllRead ||
			  permissions.accessEntriesAllWrite ||
			  permissions.accessEntriesOwnonlyRead ||
			  permissions.accessEntriesOwnonlyWrite
			: false,
		hasDatasetReadAllAccess: permissions ? permissions.accessEntriesAllRead : false,
		hasDatasetWriteAccess: permissions
			? permissions.accessEntriesAllWrite || permissions.accessEntriesOwnonlyWrite
			: false,
		hasDatasetWriteAllAccess: permissions ? permissions.accessEntriesAllWrite : false,
		hasDatasetDeleteAccess: permissions
			? permissions.accessEntriesAllDelete || permissions.accessEntriesOwnonlyDelete
			: false,
		hasDatasetDeleteAllAccess: permissions ? permissions.accessEntriesAllDelete : false,
		hasDatasetWriteOwnAccess: permissions ? permissions.accessEntriesOwnonlyWrite : false,
		hasDatasetWriteOrgAccess: permissions ? permissions.accessEntriesOrgWrite : false,
		hasDatasetDeleteOwnAccess: permissions ? permissions.accessEntriesOwnonlyDelete : false,
		hasDatasetDeleteOrgAccess: permissions ? permissions.accessEntriesOrgDelete : false,
		hasEditProjectAccess: permissions
			? permissions.accessProjectRead || permissions.accessProjectWrite
			: false,
		hasEditProjectReadAccess: permissions ? permissions.accessProjectRead : false,
		hasEditProjectWriteAccess: permissions ? permissions.accessProjectWrite : false,
		hasExportDataAccess: permissions ? permissions.accessExport : false,
		hasAnalysisAccess: permissions
			? permissions.accessAnalysisOwnonly || permissions.accessAnalysisAll
			: false,
		hasModulesAccess: {
			projectDesign: permissions?.modules.projectDesign ?? false,
			collaborators: permissions?.modules.collaborators ?? false
		}
	};
}

import produce from 'immer';

import { TableFilterType, TableName } from 'types/index';

import initialState from './initialState';
import { ActionTypes, Actions, State, DateFilterOperator, NumericFilterOperator } from './types';

import {
	ActionTypes as ProjectsActionTypes,
	Actions as ProjectsActions
} from '../../data/projects/types';

export default (state: State = initialState, action: Actions | ProjectsActions): State => {
	switch (action.type) {
		case ActionTypes.SET_ACTIVE_SORT: {
			const { activeSort, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName]) {
					draft.byName[tableName] = {
						pageIndex: 0,
						pageSize: null,
						activeSort: activeSort,
						visibleColumns: null,
						filtersByColumnName: {}
					};
				} else draft.byName[tableName].activeSort = activeSort;
			});
		}

		case ActionTypes.SET_PAGE_SIZE: {
			const { pageSize, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName]) {
					draft.byName[tableName] = {
						pageIndex: 0,
						pageSize,
						activeSort: undefined,
						visibleColumns: null,
						filtersByColumnName: {}
					};
				} else draft.byName[tableName].pageSize = pageSize;
			});
		}

		case ActionTypes.SET_PAGE_INDEX: {
			const { pageIndex, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName]) {
					draft.byName[tableName] = {
						pageIndex,
						pageSize: null,
						activeSort: undefined,
						visibleColumns: null,
						filtersByColumnName: {}
					};
				} else draft.byName[tableName].pageIndex = pageIndex;
			});
		}

		case ActionTypes.SET_VISIBLE_COLUMNS: {
			const { columns, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName])
					draft.byName[tableName] = {
						pageIndex: 0,
						pageSize: null,
						activeSort: undefined,
						visibleColumns: columns,
						filtersByColumnName: {}
					};
				else draft.byName[tableName].visibleColumns = columns;
			});
		}

		case ActionTypes.ADD_VISIBLE_COLUMN: {
			const { columnName, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName]) {
					draft.byName[tableName] = {
						pageIndex: 0,
						pageSize: null,
						activeSort: undefined,
						visibleColumns: [columnName],
						filtersByColumnName: {}
					};
				} else {
					if (!draft.byName[tableName].visibleColumns) {
						draft.byName[tableName].visibleColumns = [];
					}

					draft.byName[tableName].visibleColumns?.push(columnName);
				}
			});
		}

		case ActionTypes.REMOVE_VISIBLE_COLUMN: {
			const { columnName, tableName } = action.payload;

			return produce(state, draft => {
				if (draft.byName[tableName] && draft.byName[tableName].visibleColumns) {
					draft.byName[tableName].visibleColumns =
						draft.byName[tableName].visibleColumns?.filter(col => col !== columnName) ??
						null;
				}
			});
		}

		case ActionTypes.UPDATE_FILTER: {
			const { filter, columnName, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName])
					draft.byName[tableName] = {
						pageIndex: 0,
						pageSize: null,
						activeSort: undefined,
						visibleColumns: null,
						filtersByColumnName: {}
					};
				draft.byName[tableName].filtersByColumnName[columnName] = filter;
			});
		}

		case ActionTypes.UPDATE_FILTERS: {
			const { filters, tableName } = action.payload;

			return produce(state, draft => {
				if (!draft.byName[tableName])
					draft.byName[tableName] = {
						pageIndex: 0,
						pageSize: null,
						activeSort: undefined,
						visibleColumns: null,
						filtersByColumnName: filters
					};
				else
					draft.byName[tableName].filtersByColumnName = {
						...draft.byName[tableName].filtersByColumnName,
						...filters
					};
			});
		}

		case ActionTypes.RESET_FILTER: {
			const { columnName, tableName } = action.payload;

			return produce(state, draft => {
				if (
					draft.byName[tableName] &&
					draft.byName[tableName].filtersByColumnName[columnName]
				) {
					const filter = draft.byName[tableName].filtersByColumnName[columnName];
					if (filter.filterType === TableFilterType.Text) {
						filter.text = '';
					}
					if (filter.filterType === TableFilterType.Checkbox) {
						filter.active = [];
					}
					if (filter.filterType === TableFilterType.Date) {
						filter.valid = false;
						filter.operator = DateFilterOperator.Before;
						filter.value = null;
						filter.from = null;
						filter.to = null;
					}
					if (filter.filterType === TableFilterType.Numeric) {
						filter.valid = false;
						filter.operator = NumericFilterOperator.EqualTo;
						filter.value = null;
						filter.from = null;
						filter.to = null;
					}
				}
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ProjectsActionTypes.UPLOAD_PROJECT_DATASET:
		case ProjectsActionTypes.UPDATE_EXISTING_DATASET:
		case ProjectsActionTypes.UPLOAD_TO_EXISTING_DATASET: {
			return produce(state, draft => {
				delete draft.byName[TableName.Entries];
			});
		}

		default: {
			return state;
		}
	}
};

import { useMemo } from 'react';

import { UserLicenceModel } from 'store/account/subscription';
import { Result } from 'hooks/store/types';
import { stringAsBoolean } from 'helpers/generic';

import { useUserAddons } from './useUserAddons';
import { useAccount } from '../subscription/useAccount';

export function useShowAddons(): Result<boolean> {
	const IS_JADBIO_ENABLED = stringAsBoolean(process.env.REACT_APP_USE_JADBIO!);

	const [
		{
			data: { details: accountDetails },
			loading: accountLoading,
			error: accountError
		}
	] = useAccount();

	const [
		{
			data: { userAddons },
			loading: addonsLoading,
			error: addonsError
		}
	] = useUserAddons();

	// Checking that user has a Full Licence in order to show the Addons tabs
	// This might be handled differently - when user loses a licence the addons will be removed on BE
	const isFullLicenceUser = useMemo(
		() => !!accountDetails && accountDetails.licenceModel === UserLicenceModel.Full,
		[accountDetails]
	);

	const showAddons = useMemo(
		() => IS_JADBIO_ENABLED && !!userAddons && userAddons.length > 0 && isFullLicenceUser,
		[userAddons, isFullLicenceUser]
	);

	return {
		data: showAddons,
		loading: addonsLoading || accountLoading,
		error: addonsError || accountError
	};
}

import { Modal } from 'components/UI/Modal';
import {
	Dependant,
	Dependency,
	DependencyOperators,
	DependencyType,
	SubRows,
	TableDependency
} from 'store/data/dependencies';
import { DEPENDENCY_OPTIONS } from 'consts';
import {
	useDependenciesContext,
	useTranslation,
	useUpdateDependencies,
	useVariablesData
} from 'hooks/store';
import { useKeyPress, useMutableState, useOutsideClick, usePaginate } from 'hooks/utils';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from 'components/UI/Flex';
import { isTableDependencyValid } from 'helpers/dependencies';
import { Input } from 'components/UI/Inputs/Input';
import { VariableType } from 'types/data/variables/constants';
import { InputType, SelectItem } from 'types/index';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { arrayUtils } from 'helpers/arrays';
import { TagInput } from 'components/UI/TagInput';
import { isEqual } from 'lodash';
import { Dropdown } from 'components/UI/Dropdown';
import { buildVariableCategoriesMap } from 'helpers/variables';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { Pagination } from 'components/UI/Pagination';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';

interface Props {
	onClose: () => void;
	title: string;
	rule: TableDependency;
	dependencySuppliersSelectItems: { label: string; value: string }[];
	dependantVariablesSelectItems: { label: string; value: string }[];
	getDependantVariablesSelectItems: (dependency: TableDependency | SubRows) => SelectItem[];
	subRows: SubRows[];
	context: string | null;
	initialDependencies: { active: boolean; dependencies: Dependency[] };
	collapseAllRules: () => void;
	parseOperatorCell: (value: string) => { label: string; value: DependencyOperators };
}

export function AddNewConditionModal({
	onClose,
	title,
	rule,
	dependencySuppliersSelectItems,
	dependantVariablesSelectItems,
	getDependantVariablesSelectItems,
	subRows,
	initialDependencies,
	context,
	collapseAllRules,
	parseOperatorCell
}: Props) {
	const { translate } = useTranslation();

	const [tableDependant] = subRows.filter(cond => cond.dependantVariableName.length === 0);
	const [draftTableDependant, setDraftTableDependant] = useMutableState(tableDependant);

	const variablesData = useVariablesData({ initial: true });
	const { variablesMap } = variablesData;
	const variable = variablesMap[draftTableDependant.dependantVariableName];
	const [editedDependantVariablesSelectItems, setEditedDependantVariablesSelectItems] = useState(
		dependantVariablesSelectItems
	);
	const supplierVariable = variablesMap[draftTableDependant.supplierVariableName];
	const supplierVariableType = supplierVariable.type;

	const [{ loading: updatingDependencies }, updateDependencies] = useUpdateDependencies();
	const {
		actions: {
			setDependantSupplierValueCondition,
			changeDependantVariable,
			changeDependantOperator,
			setDependantFilteredValues,
			deleteDependant
		}
	} = useDependenciesContext();

	const [draftDependencies, setDraftDependencies] = useMutableState(
		initialDependencies.dependencies
	);

	const [selectedCategories, setSelectedCategories] = useMutableState(
		draftTableDependant.filteredValues
	);

	const modalRef = useRef<HTMLDivElement>(null);

	// COLLAPSE  ALL RULES  ON MODAL OPENING
	useEffect(() => {
		collapseAllRules();
	}, []);

	function onCancel() {
		deleteDependant({
			dependencyName: draftTableDependant.dependencyName,
			dependantId: draftTableDependant.dependantId
		});
		onClose();
	}

	useOutsideClick(onCancel, [modalRef]);

	useKeyPress({
		onEscapeKeyPress: () => {
			onCancel();
		}
	});

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(getSelectedCategoryItems(), {
		threshold: 10,
		pageSize: 10
	});

	const translateLabel = (label: string) => {
		switch (label) {
			case 'Visibility':
				return translate(dict => dict.dependencies.types.visibility);
			case 'Filtering':
				return translate(dict => dict.dependencies.types.filtering);
			default:
				return 'N/A';
		}
	};
	const dependencyOptions = DEPENDENCY_OPTIONS.map(item => {
		return {
			label: translateLabel(item.label),
			value: item.value
		};
	});

	const hasChanges = useMemo(
		() => !isEqual(tableDependant, draftTableDependant),

		[draftTableDependant]
	);

	const areDependenciesValid = useMemo(() => {
		let valid = true;
		const invalidDependencyNames: string[] = [];

		const validDependency = isTableDependencyValid(draftTableDependant);
		if (!validDependency) {
			valid = false;
			invalidDependencyNames.push(draftTableDependant.supplierVariableName);
		}
		return valid;
	}, [draftDependencies]);

	const canUpdate = useMemo(
		() => hasChanges && areDependenciesValid,
		[hasChanges, areDependenciesValid]
	);

	// CHANGE OPERATOR CONFIGURATION

	function handleDependencyOperatorVariable(operator: string) {
		setDraftTableDependant({
			...draftTableDependant,
			operator: operator as DependencyOperators
		});
	}

	const shouldChangeOperator =
		supplierVariableType == VariableType.Float ||
		supplierVariableType == VariableType.Integer ||
		supplierVariableType == VariableType.Date ||
		supplierVariableType == VariableType.DateTime;

	const operatorOptions = Object.values(DependencyOperators).map(operator =>
		parseOperatorCell(operator)
	);

	// CHANGE DEPENDANT VARIABLE
	function handleChangeDependantVariable(value: string) {
		setDraftTableDependant({
			...draftTableDependant,
			dependantVariableName: value
		});

		setSelectedCategories([]);
	}

	useEffect(() => {
		setEditedDependantVariablesSelectItems(
			getDependantVariablesSelectItems(draftTableDependant)
		);
	}, [draftTableDependant]);

	const handleDependencyChanges = () => {
		setDependantSupplierValueCondition({
			dependencyName: draftTableDependant.dependencyName,
			dependantId: draftTableDependant.dependantId,
			supplierValueCondition: draftTableDependant.supplierValueCondition
		});
		changeDependantVariable({
			dependencyName: draftTableDependant.dependencyName,
			dependantId: draftTableDependant.dependantId,
			dependantVariableName: draftTableDependant.dependantVariableName
		});
		changeDependantOperator({
			dependencyName: draftTableDependant.dependencyName,
			dependantId: draftTableDependant.dependantId,
			operator: draftTableDependant.operator as DependencyOperators
		});
		handleCreate();
	};

	useEffect(() => {
		setDraftTableDependant({ ...draftTableDependant, filteredValues: selectedCategories });
	}, [selectedCategories]);

	const updateDraftArray = () => {
		const editedDependencies = draftDependencies.map(dependency => {
			const newDependant: Dependant = {
				dependantId: draftTableDependant.dependantId,
				dependantVariableName: draftTableDependant.dependantVariableName,
				filteredValues: draftTableDependant.filteredValues,
				operator: draftTableDependant.operator as DependencyOperators,
				supplierValueCondition: draftTableDependant.supplierValueCondition
			};

			const matchingTableDependency =
				draftTableDependant.dependencyName == dependency.dependencyName;

			if (matchingTableDependency) {
				const existingDependant = dependency.dependantVariables.find(
					dependant => dependant.dependantId === newDependant.dependantId
				);

				if (existingDependant) {
					const updatedDependants = dependency.dependantVariables.map(dependant => {
						if (dependant.dependantId === newDependant.dependantId) {
							return {
								...dependant,
								dependantVariableName: draftTableDependant.dependantVariableName,
								filteredValues: draftTableDependant.filteredValues,
								operator: draftTableDependant.operator as DependencyOperators,
								supplierValueCondition: draftTableDependant.supplierValueCondition
							};
						}
						return dependant;
					});

					return {
						...dependency,
						dependantVariables: updatedDependants
					};
				} else {
					const updatedDependants = [...dependency.dependantVariables, newDependant];

					return {
						...dependency,
						dependantVariables: updatedDependants
					};
				}
			}
			return dependency;
		});

		return setDraftDependencies(editedDependencies);
	};

	useEffect(() => {
		updateDraftArray();
	}, [draftTableDependant]);

	// RESULT CONFIGURATION
	function onConfirm(categories: string[]) {
		setDependantFilteredValues({
			dependencyName: draftTableDependant.dependencyName,
			dependantId: draftTableDependant.dependantId,
			filteredValues: categories
		});
	}
	function handleCreate() {
		if (!isFormValid()) return;
		onConfirm(selectedCategories);
	}
	function isFormValid() {
		return selectedCategories.length > 0;
	}
	function onCheck(category: string) {
		setSelectedCategories(state => {
			if (state.includes(category)) return state.filter(c => c !== category);
			state.push(category);
		});
	}
	function onToggleAll(flag: boolean) {
		const categories = flag ? getVariableCategories() : [];
		setSelectedCategories(categories);
	}
	function getVariableCategories(): string[] {
		if (variable) return variable.categories.map(c => c.value);
		return [];
	}
	function getSelectCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];
		if (variable) {
			variable.categories.forEach(c => {
				const item: SelectItem = {
					label: c.label || c.value,
					value: c.value
				};
				items.push(item);
			});
		}

		return items;
	}
	function getSelectedCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];
		if (variable && draftTableDependant.filteredValues.length > 0) {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);

			selectedCategories.forEach(value => {
				const category = categoriesMap[value];
				const item: SelectItem = {
					label: category.label || category.value,
					value: category.value
				};
				items.push(item);
			});
		}
		return items;
	}

	function handleMoveCategoryValue(input: { sourceIndex: number; destinationIndex: number }) {
		const { sourceIndex, destinationIndex } = input;
		if (sourceIndex === destinationIndex) return;
		const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
			pageIndex,
			pageSize
		});
		setSelectedCategories(state =>
			arrayUtils.move(state, absoluteIndexes.sourceIndex, absoluteIndexes.destinationIndex)
		);
	}

	// HANDLE MODAL  SUBMIT
	const handleFormSubmit = () => {
		handleDependencyChanges();

		updateDependencies({
			active: initialDependencies.active,
			dependencies: draftDependencies,
			setName: context ?? undefined
		});
		onClose();
	};

	return (
		<Modal
			title={title}
			visible
			onClose={onCancel}
			close
			enterAsPrimaryOnClick
			modalRef={modalRef}
			primary={{
				label: translate(({ buttons }) => buttons.done),

				disabled: !canUpdate,
				onClick: handleFormSubmit,
				loading: updatingDependencies
			}}
			neutral={
				hasChanges && {
					label: translate(({ buttons }) => buttons.cancel),
					onClick: onCancel
				}
			}
		>
			<Flex column>
				<Flex fullWidth marginOffset={{ bottom: 1.2 }}>
					{/* SUPPLIER VARIABLE */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							disabled
							label={translate(dict => dict.dependencies.builder.tableView.supplier)}
							className="creatable-select-small"
							value={dependencySuppliersSelectItems.find(
								item => item.value === rule.supplierVariableName
							)}
							items={dependencySuppliersSelectItems}
							canClear={false}
						/>
					</Flex>

					<Flex fullWidth>
						{/* DEPENDENCY TYPE  */}
						<CreatableSelect
							disabled
							label={translate(
								dict => dict.dependencies.builder.tableView.dependencyType
							)}
							className="creatable-select-small"
							items={dependencyOptions}
							value={dependencyOptions.find(
								item => item.value === draftTableDependant.dependencyType
							)}
							canClear={false}
						/>
					</Flex>
				</Flex>
				<Flex>
					{/* DEPENDENCY OPERATOR  */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							disabled={!shouldChangeOperator}
							label={translate(dict => dict.dependencies.builder.tableView.operator)}
							className="creatable-select-small"
							items={operatorOptions}
							value={
								shouldChangeOperator
									? parseOperatorCell(draftTableDependant.operator)
									: parseOperatorCell(rule.operator)
							}
							onValueSelected={item => item && handleDependencyOperatorVariable(item)}
							canClear={false}
						/>
					</Flex>

					{/* SUPPLIER  VALUE CONDITION */}
					{[VariableType.Category, VariableType.CategoryMultiple].includes(
						supplierVariableType
					) ? (
						<Flex fullWidth>
							<CreatableSelect
								label={translate(dict => dict.dependencies.builder.tableView.value)}
								className="creatable-select-small"
								placeholder={translate(
									dict => dict.dependencies.directDependency.selectCategory
								)}
								value={{
									label: draftTableDependant.supplierValueCondition,
									value: draftTableDependant.supplierValueCondition
								}}
								items={supplierVariable.categories.map(category => ({
									label: category.label,
									value: category.value
								}))}
								onValueSelected={value => {
									if (value) {
										setDraftTableDependant({
											...draftTableDependant,
											supplierValueCondition: value
										});
									}
								}}
								canClear={false}
							/>
						</Flex>
					) : (
						<Flex fullWidth>
							{/* NUMERIC (INTEGER / FLOAT) */}
							{(supplierVariableType === VariableType.Float ||
								supplierVariableType === VariableType.Integer) && (
								<>
									<Input
										label={translate(
											dict => dict.dependencies.builder.tableView.value
										)}
										className="input-small"
										type={InputType.Number}
										value={draftTableDependant.supplierValueCondition}
										onChange={e =>
											setDraftTableDependant({
												...draftTableDependant,
												supplierValueCondition: e.target.value
											})
										}
									/>
								</>
							)}

							{/* DATE || DATETIME*/}
							{(supplierVariableType === VariableType.Date ||
								supplierVariableType === VariableType.DateTime) && (
								<>
									{supplierVariableType === VariableType.Date && (
										<Input
											label={translate(
												dict => dict.dependencies.builder.tableView.value
											)}
											className="date-input-small"
											type={InputType.Date}
											value={draftTableDependant.supplierValueCondition}
											onDateChange={({ formattedDate: from }) =>
												setDraftTableDependant({
													...draftTableDependant,
													supplierValueCondition: from
												})
											}
										/>
									)}
									{supplierVariableType === VariableType.DateTime && (
										<Flex>
											<DateTimeInput
												value={draftTableDependant.supplierValueCondition}
												onChange={value =>
													setDraftTableDependant({
														...draftTableDependant,
														supplierValueCondition: value
													})
												}
												options={{
													small: true,
													label: translate(
														dict =>
															dict.dependencies.builder.tableView
																.value
													)
												}}
											/>
										</Flex>
									)}
								</>
							)}
							{/* STRING */}
							{supplierVariable.type === VariableType.String && (
								<>
									<Input
										label={translate(
											dict => dict.dependencies.builder.tableView.value
										)}
										className="input-small"
										type={InputType.Text}
										value={draftTableDependant.supplierValueCondition}
										onChange={e =>
											setDraftTableDependant({
												...draftTableDependant,
												supplierValueCondition: e.target.value
											})
										}
									/>
								</>
							)}
						</Flex>
					)}
				</Flex>

				<Flex fullWidth marginOffset={{ top: 1.2 }}>
					{/* DEPENDANT VARIABLE */}
					<Flex fullWidth marginOffset={{ right: 1.2 }}>
						<CreatableSelect
							label={translate(
								dict => dict.dependencies.builder.tableView.dependantVariables
							)}
							className="creatable-select-small"
							placeholder={translate(
								dict => dict.dependencies.directDependency.selectVariable
							)}
							value={editedDependantVariablesSelectItems.find(
								item => item.value === draftTableDependant.dependantVariableName
							)}
							items={editedDependantVariablesSelectItems}
							onValueSelected={value => {
								if (value) {
									handleChangeDependantVariable(value);
								}
							}}
							canClear={false}
						/>
					</Flex>
					{/* FILTERED VALUES  - RESULT */}
					<Flex fullWidth column>
						<Dropdown
							disabled={
								draftTableDependant.dependencyType !== DependencyType.Filtering
							}
							label={translate(dict => dict.dependencies.builder.tableView.result)}
							title={`${selectedCategories.length} ${
								selectedCategories.length === 1
									? translate(dict => dict.terms.value)
									: translate(dict => dict.terms.values)
							} ${translate(dict => dict.terms.selected)}`}
							button
						>
							<Flex paddingOffset={{ x: 1.2, y: 1.6 }}>
								<SelectMultiple
									items={getSelectCategoryItems()}
									onSelect={item => onCheck(item.value)}
									selectedItems={selectedCategories}
									onToggleAll={onToggleAll}
								/>
							</Flex>
						</Dropdown>
					</Flex>
				</Flex>

				{draftTableDependant.filteredValues.length > 0 && (
					<Flex column fullWidth marginOffset={{ top: 1.2 }}>
						<InputLabel
							label={translate(
								dict =>
									dict.dependencies.dependantFiltering
										.configureFilteringValuesModal.previewSelected.label
							)}
						/>

						{shouldPaginate && (
							<Flex marginOffset={{ bottom: 0.4 }}>
								<Pagination
									totalCountLabel={translate(dict => dict.terms.values)}
									pageIndex={pageIndex}
									pageSize={pageSize}
									pagesCount={pagesCount}
									changePage={changePage}
									changePageSize={changePageSize}
									totalCount={getSelectedCategoryItems().length}
								/>
							</Flex>
						)}

						<TagInput
							hint={translate(
								dict =>
									dict.dependencies.dependantFiltering
										.configureFilteringValuesModal.previewSelected.hint
							)}
							items={selectedCategoryItems}
							onMove={handleMoveCategoryValue}
							readOnly
							sortable
						/>
					</Flex>
				)}
				<Flex fullWidth marginOffset={{ top: 1.2 }}>
					{/* DEPENDENCY DESCRIPTION */}
					<Input
						disabled
						type={InputType.Textarea}
						label={translate(
							dict => dict.dependencies.builder.tableView.ruleDescription
						)}
						className="description-input"
						value={draftTableDependant.description}
					/>
				</Flex>
			</Flex>
		</Modal>
	);
}

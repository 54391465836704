import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Svgs } from 'environment';
import { usePermissions, useTranslation } from 'hooks/store';

interface Props {
	onCreateClick: () => void;
	onCreateFromCategoryVariableClick: () => void;
}

export function NoStatuses({ onCreateClick, onCreateFromCategoryVariableClick }: Props) {
	const { translate } = useTranslation();

	const { hasVariablesWriteAccess } = usePermissions();

	return (
		<Flex align={a => a.center} column>
			<Spacer size={s => s.xxl} />
			<Svgs.EmptyStatesNoStatuses style={{ minHeight: 240 }} />
			<Spacer size={s => s.m} />
			<Typography.H3>
				{translate(({ statuses }) => statuses.emptyStatuses.noStatuses)}
			</Typography.H3>

			{hasVariablesWriteAccess && (
				<>
					<Spacer size={s => s.xs} />
					<Typography.Paragraph>
						{translate(({ statuses }) => statuses.emptyStatuses.noStatusesDescription)}
					</Typography.Paragraph>
					<Spacer size={s => s.m} />
					<Button
						title={translate(({ statuses }) => statuses.emptyStatuses.createNew.status)}
						onClick={onCreateClick}
					/>
					<Spacer size={s => s.s} />
					<Typography.Paragraph>{translate(dict => dict.terms.or)}</Typography.Paragraph>
					<Flex column>
						<Button
							variant={v => v.link}
							title={translate(
								({ statuses }) =>
									statuses.emptyStatuses.createNew.fromCategoryVariable
							)}
							onClick={onCreateFromCategoryVariableClick}
						/>
					</Flex>
				</>
			)}
			<Spacer size={s => s.xxl} />
		</Flex>
	);
}

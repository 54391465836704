import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteBulkCollaboratorsData } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	organizationIds: string[];
	collaboratorIds: string[];
	onClose: (success?: boolean) => void;
}

export function DeleteBulkCollaboratorsDataModal({
	organizationIds,
	collaboratorIds,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const [
		{ loading: deletingCollaboratorsData, error: errorDeletingCollaboratorsData },
		deleteBulkCollaboratorsData
	] = useDeleteBulkCollaboratorsData();

	// CLOSE MODAL AFTER SELECTED VARIABLES AND/OR GROUPS WERE DELETED
	useCompletedAction(deletingCollaboratorsData, errorDeletingCollaboratorsData, () =>
		onClose(true)
	);

	function handleDelete() {
		deleteBulkCollaboratorsData({
			organizationIds,
			collaboratorIds
		});
	}

	const collaboratorsSelected = collaboratorIds.length > 0;
	const organizationsSelected = organizationIds.length > 0;

	const title = `${translate(
		dict => dict.collaborators.deleteBulkCollaboratorDataModal.delete
	)} ${getSelectedItems()}?`;
	const message = `${translate(
		dict => dict.collaborators.deleteBulkCollaboratorDataModal.messageStart
	)} ${getSelectedItems()} ${translate(
		dict => dict.collaborators.deleteBulkCollaboratorDataModal.messageEnd
	)}`;

	const warningMessage = translate(
		dict => dict.collaborators.deleteBulkCollaboratorDataModal.warningMessage
	);

	function getSelectedItems(): string {
		const items: string[] = [];

		if (collaboratorsSelected) items.push('collaborators');
		if (organizationsSelected) items.push('groups');

		let selectedItems = items.toString();

		const shouldParse = items.length > 1;

		if (shouldParse) {
			const draftItems = [...items];

			const lastItem = draftItems.pop();

			selectedItems = `${draftItems.join(', ')} ${translate(
				dict => dict.collaborators.deleteBulkCollaboratorDataModal.and
			)} ${lastItem}`;
		}

		return selectedItems;
	}

	return (
		<Modal
			size={s => s.s}
			title={title}
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: deletingCollaboratorsData,
				onClick: handleDelete
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{message}
				{'\n\n'}
				<b>{translate(dict => dict.collaborators.deleteBulkCollaboratorDataModal.note)}</b>
				{'\n\n'}
				<b>{warningMessage}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { selectFormsSectionCollapsed, setFormsSectionCollapsed } from 'store/data/forms';
import { BooleanMap } from 'types/index';

type HandlerInput = Parameters<typeof setFormsSectionCollapsed>[0];

export function useFormsSectionCollapsed(): LocalOperationResult<
	BooleanMap,
	(input: HandlerInput) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectFormsSectionCollapsed(state.data.forms));

	function handler(input: HandlerInput) {
		dispatch(setFormsSectionCollapsed(input));
	}

	return [data, handler];
}

import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { getMarginOffset } from 'helpers/cssGenerators';
import { Typography, FontWeights } from 'components/UI/Typography';
import { StyleOffset } from 'types/index';

export const Text = styled(Typography.Paragraph)``;

interface ContainerProps {
	active: boolean;
	marginOffset?: StyleOffset;
	hasIcon?: boolean;
}
export const Container = styled.div<ContainerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	min-width: ${props => (props.hasIcon ? '7rem' : '10.8rem')};
	padding: ${props => (props.hasIcon ? '1.5rem 0 ' : '2.5rem 0')};
	cursor: pointer;
	position: relative;
	user-select: none;

	::after {
		display: block;
		content: '';
		width: 3.6rem;
		height: 0.2rem;
		border-radius: 0 0 0.4rem 0.4rem;
		background-color: ${Colors.primary.normal};
		position: absolute;
		bottom: 0;
		opacity: 0;
	}

	:hover {
		${Text} {
			color: ${Colors.primary.normal};
		}
	}

	${({ active }) =>
		active &&
		css`
			::after {
				opacity: 1;
			}

			${Text} {
				color: ${Colors.primary.normal};
				font-weight: ${FontWeights.medium};
			}
		`}

	${({ marginOffset }) => marginOffset && getMarginOffset(marginOffset)}
`;

import { useDispatch, useSelector } from 'hooks/utils';
import { showJADBioLogin } from 'store/ui/states';

import { LocalOperationResult } from '../types';

export function useShowJADBioLogIn(): LocalOperationResult<boolean, (show: boolean) => void> {
	const dispatch = useDispatch();

	const showJADBioLogIn = useSelector(state => state.ui.states.showJADBioLogIn);

	function handler(show: boolean) {
		dispatch(showJADBioLogin({ show }));
	}

	return [showJADBioLogIn, handler];
}

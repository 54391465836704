import { useMemo, useEffect, useCallback } from 'react';
import { FieldErrors } from 'react-hook-form';
import { isEqual } from 'lodash';
import { DynamicFormValues } from 'store/data/entries';
import { BooleanMap } from 'types/index';
import { withCustomSuffix } from 'helpers/entries';
import { getUsedGroupsInForms } from 'helpers/forms';
import { buildBooleanMap } from 'helpers/maps';
import { getVariableSetGroupNames } from 'helpers/variables';
import { useStatic, useRender, usePrevious } from 'hooks/utils';
import { useForms } from '../forms/useForms';
import { useFormsBySetName } from '../forms/useFormsBySetName';
import { useVariables } from '../variables/useVariables';

interface Props {
	errors: FieldErrors<DynamicFormValues>;
	isSubmitting: boolean;
	setName?: string;
}

export function useEntryFormGroupsState({ errors, isSubmitting, setName }: Props) {
	const [
		{
			data: { groupsMap, groupNamesOutsideSets, variableSetsMap }
		}
	] = useVariables({
		initial: true,
		lazy: true
	});

	const formsBySetName = useFormsBySetName();
	const [{ data: forms }] = useForms({ lazy: true });

	const scopeGroupNames = useMemo(() => {
		const scopeForms = setName ? formsBySetName[setName] ?? [] : forms;
		const activeForms = scopeForms.filter(form => form.active);
		const hasActiveForms = activeForms.length > 0;

		const customFormsGroupNames = getUsedGroupsInForms(activeForms);

		const groupNames = setName
			? getVariableSetGroupNames(variableSetsMap[setName])
			: groupNamesOutsideSets;

		return hasActiveForms ? customFormsGroupNames : groupNames;
	}, [groupNamesOutsideSets, variableSetsMap, forms, formsBySetName, setName]);

	const [getGroupsExpanded, setGroupsExpanded] = useStatic<BooleanMap>(
		buildBooleanMap(scopeGroupNames, false)
	);

	const [, triggerRender] = useRender();

	// BUILD FRESH EXPANDED GROUP MAP
	const prevScopeGroupNames = usePrevious(scopeGroupNames);
	useEffect(() => {
		if (
			prevScopeGroupNames !== undefined &&
			!isEqual(prevScopeGroupNames, scopeGroupNames) &&
			scopeGroupNames.length > 0
		) {
			const map = buildBooleanMap(scopeGroupNames, false);

			if (!isEqual(map, getGroupsExpanded())) setGroupsExpanded(map);
		}
	}, [scopeGroupNames]);

	// EXPAND GROUPS WITH ERRORS ON FORM SUBMIT
	const wasSubmitting = usePrevious(isSubmitting);
	useEffect(() => {
		function hasErrors(groupVariableNames: string[]) {
			return groupVariableNames.reduce(
				(acc, variableName) =>
					acc || !!errors[variableName] || !!errors[withCustomSuffix(variableName)],
				false
			);
		}

		if (wasSubmitting) {
			const groupsWithErrors: string[] = [];

			scopeGroupNames.forEach(groupName => {
				const group = groupsMap[groupName];

				const { variablesBelongingToGroup: groupVariableNames } = group;

				if (hasErrors(groupVariableNames)) groupsWithErrors.push(group.groupName);
			});

			if (groupsWithErrors.length) {
				setGroupsExpanded(state =>
					groupsWithErrors.forEach(groupName => (state[groupName] = true))
				);
				triggerRender();
			}
		}
	}, [isSubmitting, errors]);

	function setGroupExpanded(groupName: string, value?: boolean) {
		setGroupsExpanded(state => {
			const prevValue = state[groupName];

			const newValue = value ?? !prevValue;

			state[groupName] = newValue;
		});
	}

	const setGroupExpandedMemo = useCallback(setGroupExpanded, [setGroupsExpanded]);

	function collapseGroups() {
		setGroupsExpanded(state => buildBooleanMap(Object.keys(state), false));
		triggerRender();
	}

	function expandGroups() {
		setGroupsExpanded(state => buildBooleanMap(Object.keys(state), true));
		triggerRender();
	}

	return {
		groupsExpanded: getGroupsExpanded(),
		setGroupExpanded: setGroupExpandedMemo,
		collapseGroups,
		expandGroups,
		canExpand: scopeGroupNames.length > 0
	};
}

import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { CollaboratorDetails, CollaboratorPermissions } from 'components/Collaborators';
import { DefaultCollaboratorPermissions } from 'consts';
import { initButtonProps } from 'helpers/buttons';
import { buildInitialPermissions } from 'helpers/collaborators';
import { Collaborator } from 'store/data/collaborators';
import { Modal } from 'components/UI/Modal';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Spacer } from 'components/UI/Spacer';
import { useMatchProms } from 'hooks/navigation';
import {
	useTranslation,
	useAccount,
	useIsProjectOwner,
	useUpdateCollaboratorPermissions,
	useStatuses,
	useRoles,
	useOrganizationsByCollaborator,
	useProjectOrganizations,
	useCollaboratorsData,
	useAddCollaboratorsToOrganization
} from 'hooks/store';
import { useKeyPress, useCompletedAction } from 'hooks/utils';
import { GroupContainer } from './CollaboratorModal.style';
import { Typography } from 'components/UI/Typography';
import { SelectItem } from 'types/index';

enum Views {
	Information = 'information',
	Permissions = 'permissions',
	Groups = 'groups'
}

interface Props {
	writeAccess: boolean;
	collaborator: Collaborator;
	removeModalVisible: boolean;
	onClose: (success?: boolean) => void;
	onRemove: (collaboratorId: string) => void;
	onRemoveCollaborator: (input: { organizationId: string; collaboratorId: string }) => void;
}

export function CollaboratorModal({
	writeAccess,
	collaborator,
	removeModalVisible,
	onClose,
	onRemove,
	onRemoveCollaborator
}: Props) {
	const { translate } = useTranslation();
	const matchProms = useMatchProms();
	const dictKey = matchProms ? 'prom' : 'project';

	const [
		{
			data: { username }
		}
	] = useAccount();

	const collaboratorOrganizations = useOrganizationsByCollaborator(collaborator.userId);
	const { organizationsMap } = useCollaboratorsData();
	const [
		{ loading: updatingCollaboratorPermissions, error: errorUpdatingCollaboratorPermissions },
		updateCollaboratorPermissions
	] = useUpdateCollaboratorPermissions();
	const [
		{ loading: addingCollaboratorsToGroups, error: errorAddingCollaboratorsToGroups },
		addCollaboratorsToOrganizations
	] = useAddCollaboratorsToOrganization();
	const organizations = useProjectOrganizations();
	const [organizationsToAdd, setOrganizationsToAdd] = useState<string[]>([]);

	const isProjectOwner = useIsProjectOwner();
	const isSelf = collaborator.userId === username;

	const [
		{
			data: { statuses }
		}
	] = useStatuses({ lazy: true, omitSystemGenerated: false });

	const [
		{
			data: { roles }
		}
	] = useRoles();

	const roleSelectItems = useMemo(() => {
		const selectItems = [];

		for (const role of roles) {
			selectItems.push({
				label: role.name,
				value: role.id
			});
		}

		return selectItems;
	}, [roles]);

	const organizationSelectItems = useMemo(() => {
		const orgs: SelectItem[] = [];

		organizations.forEach(org => {
			if (
				!org.collaborators.includes(collaborator.userId ?? '') &&
				!organizationsToAdd.includes(org.id)
			)
				orgs.push({
					label: org.name,
					value: org.id
				});
		});

		return orgs;
	}, [organizations, organizationsToAdd, collaborator.userId]);

	const selectedItems: SelectItem[] = useMemo(() => {
		return organizationsToAdd.map(orgId => ({
			label: organizationsMap[orgId].name,
			value: orgId
		}));
	}, [organizationsToAdd, organizationsMap]);

	const collaboratorRoleId = collaborator.projectRoleId || null;

	const [assignedRoleId, setAssignedRoleId] = useState<string | null>(collaboratorRoleId);

	const initialPermissions = useMemo(() => {
		const initial = collaborator.accessToProject ?? DefaultCollaboratorPermissions;

		const permissions = buildInitialPermissions(initial, statuses);

		return permissions;
	}, [collaborator, statuses]);

	const [permissions, setPermissions] = useState(initialPermissions);

	const havePermissionsChanged = useMemo(
		() => !isEqual(permissions, initialPermissions),
		[permissions, initialPermissions]
	);

	const isSameRole = assignedRoleId === collaboratorRoleId;

	const [view, setView] = useState<Views>(Views.Information);
	const views = {
		order: [Views.Information, Views.Permissions, Views.Groups],
		labels: {
			[Views.Information]: translate(
				dict => dict.collaborators.collaboratorModal.information
			),
			[Views.Permissions]: translate(
				dict => dict.collaborators.collaboratorModal.permissions
			),
			[Views.Groups]: translate(dict => dict.collaborators.collaboratorModal.groups)
		}
	};

	useKeyPress(
		{ onDeleteKeyPress: () => onRemove(collaborator.userId) },
		{ listen: canDelete() && !isLoadingAction() && !removeModalVisible }
	);

	useKeyPress(
		{ onEnterKeyPress: havePermissionsChanged ? handleSubmit : onClose },
		{ listen: !isLoadingAction() && !removeModalVisible }
	);

	useCompletedAction(updatingCollaboratorPermissions, errorUpdatingCollaboratorPermissions, () =>
		onClose(true)
	);

	useCompletedAction(addingCollaboratorsToGroups, errorAddingCollaboratorsToGroups, () =>
		onClose(true)
	);

	function handleSelectItemsChange(value: string | null | undefined) {
		if (!value) {
			setPermissions(initialPermissions);
			setAssignedRoleId(null);
		} else {
			if (collaboratorRoleId && value === collaboratorRoleId) {
				setPermissions(initialPermissions);
				setAssignedRoleId(value);
				return;
			}

			const role = roles.find(role => role.id === value);

			if (role) {
				const permissions = buildInitialPermissions(role.permissions, statuses);

				setAssignedRoleId(role.id);
				setPermissions(permissions);
			}
		}
	}

	function handleSubmit() {
		if (isLoadingAction()) return;

		updateCollaboratorPermissions({
			collaboratorId: collaborator.userId,
			permissions,
			projectRoleId: assignedRoleId
		});

		if (selectedItems.length) {
			addCollaboratorsToOrganizations({
				collaborators: [collaborator.userId],
				organizationIds: organizationsToAdd
			});
		}
	}

	function canDelete(): boolean {
		return isProjectOwner && !isSelf;
	}

	function isLoadingAction(): boolean {
		return updatingCollaboratorPermissions || addingCollaboratorsToGroups;
	}

	function isValid() {
		if (collaboratorRoleId)
			return !havePermissionsChanged && isSameRole && selectedItems.length === 0;

		return !havePermissionsChanged && selectedItems.length === 0;
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(({ buttons }) => buttons.update),
			loading: isLoadingAction(),
			onClick: handleSubmit
		};

		buttons.neutral = {
			label: translate(({ buttons }) => buttons.cancel),
			onClick: onClose
		};

		if (isValid()) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	return (
		<Modal
			size={s => s.m}
			title={translate(dict => dict.collaborators.collaboratorModal.collaboratorDetails)}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			tabs={{
				labels: views.order.map(view => views.labels[view]),
				active: views.order.indexOf(view),
				onClick: tabIndex => setView(views.order[tabIndex])
			}}
			onClose={onClose}
			fullSizeConfig={{
				narrow: true
			}}
			visible
			close
		>
			{/* INFORMATION VIEW */}
			{view === Views.Information && (
				<>
					<CollaboratorDetails
						isProjectOwner={!!(isProjectOwner && isSelf)}
						isSelf={isSelf}
						collaborator={collaborator}
					/>

					{canDelete() && (
						<Flex flex={1} align={a => a.end} marginOffset={{ top: 2.4 }}>
							<Button
								variant={v => v.link}
								paddingOffset={{ all: 0 }}
								title={translate(
									({ collaborators }) => collaborators.details.remove[dictKey]
								)}
								onClick={() => onRemove(collaborator.userId)}
							/>
						</Flex>
					)}
				</>
			)}

			{/* PERMISSIONS VIEW */}
			{view === Views.Permissions && (
				<>
					{!isSelf && (
						<>
							<InputWrapper>
								<CreatableSelect
									label={translate(
										dict => dict.collaborators.collaboratorModal.role
									)}
									placeholder={translate(
										dict => dict.collaborators.collaboratorModal.selectRole
									)}
									items={roleSelectItems}
									value={roleSelectItems.find(
										item => item.value === assignedRoleId
									)}
									onValueSelected={handleSelectItemsChange}
								/>
							</InputWrapper>

							<Spacer size={s => s.m} />
						</>
					)}

					<CollaboratorPermissions
						metadata={{
							isSelf,
							isProjectOwner
						}}
						statuses={statuses}
						permissions={permissions}
						onChange={setPermissions}
						disabled={!!assignedRoleId}
					/>
				</>
			)}
			{/* GROUPS VIEW */}
			{view === Views.Groups && (
				<Flex column>
					<CreatableSelect
						items={organizationSelectItems}
						onValuesSelected={values => setOrganizationsToAdd(values)}
						values={selectedItems}
						label={translate(dict => dict.collaborators.organizationModal.addGroup)}
						hasMultipleValues
					/>
					<Spacer size={s => s.l} />
					{collaboratorOrganizations.length > 0 && (
						<Typography.Paragraph marginOffset={{ bottom: 0.8 }}>
							{translate(dict => dict.collaborators.organizationModal.groupsList)}
						</Typography.Paragraph>
					)}
					{collaboratorOrganizations.map(org => (
						<GroupContainer key={org.id}>
							<Typography.Paragraph>{org.name}</Typography.Paragraph>
							{writeAccess && (
								<Button
									className="remove-button"
									title={translate(dict => dict.buttons.remove)}
									variant={v => v.link}
									paddingOffset={{ all: 0 }}
									onClick={() =>
										onRemoveCollaborator({
											collaboratorId: collaborator.userId,
											organizationId: org.id
										})
									}
								/>
							)}
						</GroupContainer>
					))}
				</Flex>
			)}
		</Modal>
	);
}

const InputWrapper = styled.div`
	width: 50%;
`;

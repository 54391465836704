import { CrosstabResultsDataV2 } from 'api/data/analyses';

export function exportCrosstabV2(dataset: CrosstabResultsDataV2) {
	const {
		columnLabels,
		counts,
		percentages,
		rowLabels,
		rowVariable: { name: rowsLabel }
	} = dataset;

	const rows: string[][] = [];

	const firstRow = [rowsLabel, ...columnLabels];
	rows.push(firstRow);

	rowLabels.forEach((rowLabel, index) => {
		const row = [rowLabel];
		counts[index].forEach((_, index2) => {
			const count = counts[index][index2];
			const percentage = percentages[index][index2];

			row.push(`${count} (${(percentage * 100).toFixed(1)}%)`);
		});
		rows.push(row);
	});

	const comma = ',';
	const newLine = '\r\n';

	let csv = '';
	rows.forEach(row => (csv += row.join(comma) + newLine));

	return csv;
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, updateStatus, Status } from 'store/data/statuses';

export function useUpdateStatus(): Operation<
	(status: Status, updateExistingEntries?: boolean) => Promise<void>
> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.UPDATE_STATUS);

	async function handler(status: Status, updateExistingEntries?: boolean) {
		if (loading) return;
		if (error) resetError();

		await dispatch(updateStatus(status, updateExistingEntries));
	}

	return [{ loading, error }, handler];
}

import { useState } from 'react';

import { CreditCardBrands } from 'types/index';
import { MediaQueries } from 'environment';
import { Typography } from 'components/UI/Typography';
import { CreditCardInterface } from 'store/account/payments';
import { CreditCardLogo } from './CreditCardLogo';

import { AdvancedMenu, CreditCardWrapper } from './style';
import { Flex } from 'components/UI/Flex';
import { Tag } from 'components/UI/Tags';
import { useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

interface Props {
	creditCard: CreditCardInterface;
	cardActionsDisabled?: boolean;
	withCardActions?: boolean;
	deleteCard?: (cardNumber: string) => void;
	makePrimary?: (cardNumber: string) => void;
}
export function CreditCard({
	creditCard,
	withCardActions = false,
	cardActionsDisabled = false,
	deleteCard,
	makePrimary
}: Props) {
	const { translate } = useTranslation();
	const isSmallScreen = useMediaQuery(MediaQueries.maxWidth.xs);
	const [menuVisible, setMenuVisible] = useState(false);
	const { brand, maskedNumber, id, expiryMonth, expiryYear, isPrimary } = creditCard;
	const cardNumberMasked = `${maskedNumber.substring(8, 12)} ${maskedNumber.substring(12, 16)}`;
	const expiryDate = `${expiryMonth.toString()}/${expiryYear.toString()}`;

	let cardBrand = '';

	const cardNameWords = brand.split(' ');
	cardNameWords.forEach((word, index) => {
		const lowerCaseWord = word.toLowerCase();
		const brandNameByWord = lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.substring(1);

		if (cardNameWords.length >= 2 && index !== cardNameWords.length - 1) {
			cardBrand += brandNameByWord + ' ';
		} else {
			cardBrand += brandNameByWord;
		}
	});

	const makePrimaryOption = {
		label: translate(({ accountUM }) => accountUM.payment.makePrimary),
		handler: () => makePrimary && makePrimary(id),
		disabled: cardActionsDisabled
	};
	const cardOptions = [
		// {
		// 	label: translate(({ accountUM }) => accountUM.payment.editCardDetails),
		// 	handler: () => editCreditCard()
		// },
		{
			label: translate(({ accountUM }) => accountUM.payment.removeCard),
			disabled: cardActionsDisabled,
			handler: () => deleteCard && deleteCard(id)
		}
	];
	if (!isPrimary) {
		cardOptions.splice(0, 0, makePrimaryOption);
	}
	const creditCardBody = (
		<Flex
			paddingOffset={{ left: 2.4, top: 1.6, right: 1.6, bottom: 1.6 }}
			align={a => (withCardActions ? a.start : a.center)}
			style={{ minHeight: 100 }}
		>
			<CreditCardLogo brand={cardBrand as CreditCardBrands} />
			<Flex column paddingOffset={{ left: 1.6 }} flex={1}>
				<Typography.Paragraph style={{ fontWeight: 'bold' }}>
					{cardBrand}&nbsp;
					{cardNumberMasked}
				</Typography.Paragraph>
				<Typography.Caption>
					{translate(({ accountUM }) => accountUM.payment.expiringIn)}
					&nbsp;
					{expiryDate}
				</Typography.Caption>
				{isPrimary && (
					<Tag
						active
						marginOffset={!isSmallScreen ? { top: 0.4 } : undefined}
						title={translate(({ accountUM }) => accountUM.payment.primary)}
					/>
				)}
			</Flex>
			{withCardActions && (
				<AdvancedMenu
					className="card-more-icon"
					active={menuVisible}
					items={cardOptions}
					onOpen={() => setMenuVisible(true)}
					onClose={() => setMenuVisible(false)}
					visible={menuVisible && !cardActionsDisabled}
				/>
			)}
		</Flex>
	);

	return withCardActions ? (
		<CreditCardWrapper disableActions={cardActionsDisabled}>{creditCardBody}</CreditCardWrapper>
	) : (
		creditCardBody
	);
}

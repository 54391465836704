import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export interface State {
	projectId: string | null;
	byProjectId: GenericMap<{
		byId: GenericMap<VariableMapping>;
		main: VariablesMappingStoreData;
		bySetName: GenericMap<VariablesMappingStoreData>;
		fetched: boolean;
		metadata: {
			searchTerm: string;
		};
	}>;
}

export type VariableMapping = {
	name: string;
	customName: string;
};

export type VariablesMappingData = {
	active: boolean;
	initialized: boolean;
	variablesMapping: VariableMapping[];
};

export type VariablesMappingStoreData = {
	ids: string[];
	active: boolean;
	initialized: boolean;
};

export type VariablesMappingRichData = {
	byId: GenericMap<VariableMapping>;
	main: VariablesMappingStoreData;
	bySetName: GenericMap<VariablesMappingStoreData>;
};

export enum ActionTypes {
	INITIALIZE_VARIABLES_MAPPING = 'data/variablesMapping/INITIALIZE_VARIABLES_MAPPING',
	GET_VARIABLES_MAPPING = 'data/variablesMapping/GET_VARIABLES_MAPPING',
	SET_VARIABLE_MAPPING = 'data/variablesMapping/SET_VARIABLE_MAPPING',
	RESET_VARIABLE_MAPPING = 'data/variablesMapping/RESET_VARIABLE_MAPPING',
	ENABLE_VARIABLES_MAPPING = 'data/variablesMapping/ENABLE_VARIABLES_MAPPING',
	DISABLE_VARIABLES_MAPPING = 'data/variablesMapping/DISABLE_VARIABLES_MAPPING'
}

export type InitializeVariablesMappingAction = Action<
	ActionTypes.INITIALIZE_VARIABLES_MAPPING,
	{
		projectId: string;
		variablesMappingData: VariablesMappingData;
		setName?: string;
	}
>;

export type GetVariablesMappingAction = Action<
	ActionTypes.GET_VARIABLES_MAPPING,
	{
		projectId: string;
		variablesMappingData: VariablesMappingData;
		variablesMappingDataBySetName: GenericMap<VariablesMappingData>;
	}
>;

export type SetVariableMappingAction = Action<
	ActionTypes.SET_VARIABLE_MAPPING,
	{
		projectId: string;
		variableMapping: VariableMapping;
	}
>;

export type ResetVariableMappingAction = Action<
	ActionTypes.RESET_VARIABLE_MAPPING,
	{
		projectId: string;
		variableMapping: VariableMapping;
	}
>;

export type EnableVariablesMappingAction = Action<
	ActionTypes.ENABLE_VARIABLES_MAPPING,
	{
		projectId: string;
		setName?: string;
	}
>;

export type DisableVariablesMappingAction = Action<
	ActionTypes.DISABLE_VARIABLES_MAPPING,
	{
		projectId: string;
		setName?: string;
	}
>;

export type Actions =
	| InitializeVariablesMappingAction
	| GetVariablesMappingAction
	| SetVariableMappingAction
	| ResetVariableMappingAction
	| EnableVariablesMappingAction
	| DisableVariablesMappingAction;

import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { ShowMoreButton, VariablesList, VariablesListItem } from './PreviousMappingModal.style';
import { useTranslation } from 'hooks/store';
import { useToggle } from 'hooks/utils';

interface Props {
	previousVariablesLabels: string[];
	onConfirm: () => void;
	onClose: () => void;
}

export function PreviousMappingModal({ previousVariablesLabels, onConfirm, onClose }: Props) {
	const { translate } = useTranslation();

	const [showAllVariables, toggleShowAllVariables] = useToggle(false);
	const maxVariablesIndex = showAllVariables ? previousVariablesLabels.length : 3;
	const visibleButton = previousVariablesLabels.length > 3;

	return (
		<Modal
			title={translate(
				({ projects }) => projects.createAndImport.generics.previousMappingModal.title
			)}
			visible
			primary={{
				label: translate(dict => dict.buttons.confirm),
				onClick: onConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			close
		>
			<Typography.Paragraph fontweight={f => f.medium} multiline>
				{translate(
					({ projects }) =>
						projects.createAndImport.generics.previousMappingModal.description
				)}
			</Typography.Paragraph>

			<Spacer size={s => s.s} />
			<VariablesList>
				{previousVariablesLabels.map((previousVariablesLabel, index) =>
					index < maxVariablesIndex ? (
						<VariablesListItem key={previousVariablesLabel}>
							<Typography.Paragraph>{previousVariablesLabel}</Typography.Paragraph>
						</VariablesListItem>
					) : null
				)}
				{visibleButton && (
					<ShowMoreButton
						variant={v => v.link}
						title={
							showAllVariables
								? translate(dict => dict.terms.showLess)
								: translate(dict => dict.terms.showMore)
						}
						marginOffset={{ right: 2.4 }}
						onClick={toggleShowAllVariables}
					/>
				)}
			</VariablesList>
		</Modal>
	);
}

import { useEffect } from 'react';
import { OperationResult, HookOptions } from 'hooks/store/types';
import {
	ActionTypes,
	getSeriesEntries,
	selectSeriesEntries,
	selectAreSeriesEntriesFetched,
	Entries
} from 'store/data/entries';
import { useDispatch, useSelector } from 'hooks/utils';
import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';

export function useSeriesEntries(
	setName: string,
	options?: HookOptions
): OperationResult<{ entries: Entries }> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};

	const isProjectValid = useIsProjectValid();
	const { hasDatasetReadAccess } = usePermissions();

	const entries = useSelector(state => selectSeriesEntries(state.data.entries, { setName }));
	const fetched = useSelector(state =>
		selectAreSeriesEntriesFetched(state.data.entries, { setName })
	);

	const data = { entries };

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_SERIES_ENTRIES, setName);

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && hasDatasetReadAccess && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid, hasDatasetReadAccess, lazy]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getSeriesEntries(setName));
	}

	return [{ loading, error, data, fetched }, handler];
}

import type { TableName, GenericMap, TableFilterType } from 'types/index';
import type { Action } from 'types/store/types';

export interface TableProps {
	activeSort: TableSort | undefined; // undefined is used for first time use, to differentiate from null
	visibleColumns: VisibleColumns;
	pageSize: PageSize;
	pageIndex: number;
	filtersByColumnName: FiltersByColumnName;
}

export type TableSort = ActiveSort | null;

export type VisibleColumns = string[] | null;

export type PageSize = number | null;

export type FiltersByColumnName = GenericMap<TableFilter>;

export enum SortingType {
	Asc = 'asc',
	Desc = 'desc'
}
export interface ActiveSort {
	column: string;
	order: SortingType;
}

export type TableFilter =
	| CheckboxTableFilter
	| DateTableFilter
	| TextTableFilter
	| NumericTableFilter;

export interface CheckboxTableFilter {
	filterType: TableFilterType.Checkbox;
	all: string[];
	active: string[];
	hasSearch: boolean;
}

export interface TextTableFilter {
	filterType: TableFilterType.Text;
	text: string;
}

export interface DateTableFilter {
	filterType: TableFilterType.Date;
	valid: boolean;
	operator: DateFilterOperator;
	value: string | null;
	from: string | null;
	to: string | null;
	formatType: string;
}

export interface NumericTableFilter {
	filterType: TableFilterType.Numeric;
	valid: boolean;
	operator: NumericFilterOperator;
	value: string | null;
	from: string | null;
	to: string | null;
}

export enum DateFilterOperator {
	Between = 'between',
	Exact = 'exact',
	Before = 'before',
	After = 'after'
}

export enum NumericFilterOperator {
	Between = 'between',
	EqualTo = 'equal',
	LessThan = 'lessThan',
	GreaterThan = 'greaterThan'
}
export interface State {
	byName: GenericMap<TableProps>;
}

export enum ActionTypes {
	SET_ACTIVE_SORT = 'ui/tables/SET_ACTIVE_SORT',
	SET_VISIBLE_COLUMNS = 'ui/states/SET_VISIBLE_COLUMNS',
	ADD_VISIBLE_COLUMN = 'ui/states/ADD_VISIBLE_COLUMN',
	REMOVE_VISIBLE_COLUMN = 'ui/states/REMOVE_VISIBLE_COLUMN',
	SET_PAGE_SIZE = 'ui/states/SET_PAGE_SIZE',
	SET_PAGE_INDEX = 'ui/states/SET_PAGE_INDEX',
	UPDATE_FILTERS = 'ui/states/UPDATE_FILTERS',
	UPDATE_FILTER = 'ui/states/UPDATE_FILTER',
	RESET_FILTER = 'ui/states/RESET_FILTER'
}

export type SetActiveSortAction = Action<
	ActionTypes.SET_ACTIVE_SORT,
	{ activeSort: TableSort; tableName: TableName }
>;

export type SetVisibleColumnsAction = Action<
	ActionTypes.SET_VISIBLE_COLUMNS,
	{ columns: VisibleColumns; tableName: TableName }
>;

export type AddVisibleColumnAction = Action<
	ActionTypes.ADD_VISIBLE_COLUMN,
	{ columnName: string; tableName: TableName }
>;

export type RemoveVisibleColumnAction = Action<
	ActionTypes.REMOVE_VISIBLE_COLUMN,
	{ columnName: string; tableName: TableName }
>;

export type SetPageSizeAction = Action<
	ActionTypes.SET_PAGE_SIZE,
	{ pageSize: number; tableName: TableName }
>;

export type SetPageIndexAction = Action<
	ActionTypes.SET_PAGE_INDEX,
	{ pageIndex: number; tableName: TableName }
>;

export type UpdateFiltersAction = Action<
	ActionTypes.UPDATE_FILTERS,
	{ filters: FiltersByColumnName; tableName: TableName }
>;

export type UpdateFilterAction = Action<
	ActionTypes.UPDATE_FILTER,
	{ filter: TableFilter; columnName: string; tableName: TableName }
>;

export type ResetFilterAction = Action<
	ActionTypes.RESET_FILTER,
	{ columnName: string; tableName: TableName }
>;

export type Actions =
	| SetActiveSortAction
	| SetVisibleColumnsAction
	| AddVisibleColumnAction
	| RemoveVisibleColumnAction
	| SetPageSizeAction
	| SetPageIndexAction
	| UpdateFilterAction
	| ResetFilterAction
	| UpdateFiltersAction;

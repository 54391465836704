import { useEffect, useRef } from 'react';
import { DATE_FORMAT, PICKER_DATE_FORMAT } from 'consts';
import { selectUserDateTimeFormat } from 'store/account/subscription';
import { convertToUTCTime, getDateTime } from './helpers';
import { TimeInput } from '../TimeInput';
import { DateInput } from '../DateInput';
import { InputLabel } from '../InputLabel';
import { InputError } from '../InputError';
import { Wrapper, Container } from './DateTimeInput.style';
import { Flex } from 'components/UI/Flex';
import { HSpacer } from 'components/UI/HSpacer';
import { useStatic, useRender, usePrevious, useOutsideClick, useSelector } from 'hooks/utils';
import { zeroPadDate } from 'helpers/entries';

interface Props {
	value?: string;
	onChange?: (value: string) => void;
	options?: Options;
}

interface Options {
	name?: string;
	label?: string;
	timeLabel?: string;
	noLeftMargins?: boolean;
	error?: string;
	hint?: string;
	small?: boolean;
	responsive?: boolean;
	compact?: boolean;
	className?: string;
	disabled?: boolean;
	readOnly?: boolean;
	readOnlyController?: boolean;
	required?: boolean;
	canClearDate?: boolean;
	openDownwards?: boolean;
	smallCalendar?: boolean;
	shouldHaveInitialTime?: boolean;
	tooltipComponent?: React.ReactNode;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onReadOnly?: () => void;
}

export function DateTimeInput({
	value,
	onChange,
	options = { shouldHaveInitialTime: true }
}: Props) {
	const containerRef = useRef<HTMLDivElement>(null);
	const dateInputRef = useRef<{
		open(): void;
	}>(null);
	const timeInputRef = useRef<{
		safeTimestamp(): void;
		open(): void;
		reset(): void;
	}>(null);

	const dateTimeFormat = useSelector(state =>
		selectUserDateTimeFormat(state.account.subscription)
	);
	const meridian = dateTimeFormat === '12';

	const [internalDateTime, setInternalDateTime] = useStatic({
		date: value ? getDateTime(value).date : '',
		time: value ? getDateTime(value).time : ''
	});

	const [_, triggerRender] = useRender();

	const errored = options?.error ? !!options.error : false;

	// sync `internalDate`, `internalTime` states
	const prevValue = usePrevious(value);
	const prevMeridian = usePrevious(meridian);
	useEffect(() => {
		if (
			(prevValue !== undefined && prevValue !== value) ||
			(prevMeridian !== undefined && prevMeridian !== meridian)
		) {
			if (value) {
				const { date, time } = getDateTime(value);

				setInternalDateTime({
					date,
					time
				});
			} else {
				setInternalDateTime({
					date: '',
					time: ''
				});
			}

			triggerRender();
		}
	}, [value]);

	function onDateChange({ formattedDate, date }: { formattedDate: string; date: Date | null }) {
		if (options?.readOnlyController && options?.onReadOnly) {
			options.onReadOnly();
			return;
		}

		if (!date) {
			onChange?.('');
			setInternalDateTime({
				date: '',
				time: ''
			});
			triggerRender();

			return;
		}

		setInternalDateTime(prevState => ({
			...prevState,
			date: formattedDate
		}));

		if (internalDateTime().time && formattedDate !== '' && onChange) {
			const d = internalDateTime().date;
			const t = internalDateTime().time;

			if (value) {
				const prevTimezone = new Date(zeroPadDate(value)).getTimezoneOffset();
				const currentTimezone = new Date(`${d}T${t}`).getTimezoneOffset();

				if (prevTimezone !== currentTimezone) {
					const offset = Math.abs(prevTimezone) - Math.abs(currentTimezone);
					const newDate = new Date(`${d}T${t}`);
					newDate.setHours(newDate.getHours() + offset / 60);
					const newValue = convertToUTCTime(newDate);
					onChange(newValue);

					return;
				}
			}
			const newValue = convertToUTCTime(`${d}T${t}`);
			onChange(newValue);
		}

		/**
		 * Sets the current timestamp if no custom time was selected
		 */
		options?.shouldHaveInitialTime && timeInputRef.current?.safeTimestamp();

		/**
		 * Open the time input dropdown + focus => better UX
		 */
		if (!internalDateTime().time) {
			timeInputRef.current?.open();
		}

		triggerRender();
	}

	function onTimeChange(value: string) {
		if (options?.readOnlyController && options?.onReadOnly) {
			options.onReadOnly();
			return;
		}

		value &&
			setInternalDateTime(prevState => ({
				...prevState,
				time: value
			}));
		if (internalDateTime().date && internalDateTime().date !== '' && onChange) {
			const d = internalDateTime().date;
			const t = internalDateTime().time;

			const newValue = convertToUTCTime(`${d}T${t}`);

			onChange(newValue);
		}

		/**
		 * Open the date input dropdown + focus => better UX
		 */
		if (!internalDateTime().date) dateInputRef.current?.open();

		triggerRender();
	}

	useOutsideClick(() => {
		const valid = internalDateTime().date.length > 0 && internalDateTime().time.length > 0;

		if (!valid) {
			if (internalDateTime().date) {
				setInternalDateTime(prevState => ({
					...prevState,
					date: ''
				}));
				triggerRender();
			}
			setInternalDateTime(prevState => ({
				...prevState,
				time: ''
			}));
			timeInputRef.current?.reset();
		}
	}, [containerRef]);

	const hasDateValueBeenSelected = internalDateTime().date !== '';

	return (
		<Container ref={containerRef} className={options?.className} compact={!!options?.compact}>
			<Wrapper
				className={options?.small ? 'date-input-small' : ''}
				stacked={options?.small || meridian}
				responsive={options?.responsive}
			>
				<Flex column fullWidth>
					<Flex>
						<InputLabel
							disabled={options?.disabled}
							required={options?.required}
							label={options?.label}
						/>
						{options?.tooltipComponent && options?.tooltipComponent}
					</Flex>
					<DateInput
						// @ts-ignore
						ref={dateInputRef}
						value={internalDateTime().date}
						disabled={options?.disabled}
						readOnly={options?.readOnly}
						id={options?.label?.replaceAll(' ', '').toLowerCase()}
						error={errored}
						dateFormat={DATE_FORMAT}
						dateDisplayFormat={PICKER_DATE_FORMAT}
						onDateChange={onDateChange}
						onFocus={options?.onFocus}
						onBlur={options?.onBlur}
						canClearDate={options?.canClearDate}
						openDownwards={options?.openDownwards}
						smallCalendar={options?.smallCalendar}
					/>
				</Flex>
				{options?.noLeftMargins && <HSpacer size={s => s.s} />}
				<Flex column justify={j => j.end}>
					<Flex>
						<InputLabel
							disabled={options?.disabled}
							required={options?.required}
							label={options?.timeLabel}
						/>
						{options?.tooltipComponent && options?.tooltipComponent}
					</Flex>
					<TimeInput
						ref={timeInputRef}
						value={internalDateTime().time}
						meridian={meridian}
						small={options?.small}
						noLeftMargins={options?.noLeftMargins}
						error={errored}
						disabled={options?.disabled || !hasDateValueBeenSelected}
						readOnly={options?.readOnly}
						readOnlyController={options?.readOnlyController}
						containerRef={containerRef}
						dateInput={{
							value: internalDateTime().date,
							ref: dateInputRef
						}}
						onChange={onTimeChange}
						onReadOnly={options?.onReadOnly}
					/>
				</Flex>
			</Wrapper>
			<InputError error={options?.error} id={`${options?.name}_error`} />
		</Container>
	);
}

import { useState, useEffect } from 'react';
import { VariableSet } from 'api/data/variables';
import { Colors, Svgs } from 'environment';
import { VariableSetDrawerItems } from './VariableSetDrawerItems';
import { VariableSetDrawerSettings } from './VariableSetDrawerSettings';
import { DeleteWrapper, DrawerBody, Divider, Tabs, Tab } from './VariableSetDrawer.style';
import { MergeVariablesIntoGroup } from 'store/data/variables';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useUpdateVariableSet, useDestinationSetName } from 'hooks/store';
import { useKeyPress, usePrevious } from 'hooks/utils';

enum VariableSetTabs {
	Items = 'items',
	Settings = 'settings'
}

interface Props {
	variableSet: VariableSet;
	isGroupDrawerOpened: boolean;
	isVariableModalOpened: boolean;
	readOnly: boolean;
	preventDrag: boolean;
	onCreateVariable: () => void;
	onCreateGroup: () => void;
	mergeVariables: MergeVariablesIntoGroup;
	onVariableClick: (variableName: string, setName: string) => void;
	onGroupClick: (groupName: string) => void;
	onVariableSetDelete: (setName: string) => void;
	onClose: () => void;
}

export function VariableSetDrawer({
	variableSet,
	isGroupDrawerOpened,
	isVariableModalOpened,
	readOnly,
	preventDrag,
	onCreateVariable,
	onCreateGroup,
	mergeVariables,
	onVariableClick,
	onGroupClick,
	onVariableSetDelete,
	onClose
}: Props) {
	const { setName } = variableSet;
	const { translate } = useTranslation();

	const [tab, setTab] = useState(VariableSetTabs.Items);

	const [{ loading: updatingVariableSet }] = useUpdateVariableSet();

	const [, setDestinationSetName] = useDestinationSetName();

	useKeyPress(
		{ onEscapeKeyPress: onClose },
		{
			noModalsOpened: true,
			listen: !(isGroupDrawerOpened || isVariableModalOpened)
		}
	);

	useKeyPress(
		{ onDeleteKeyPress: handleDelete },
		{
			noModalsOpened: true,
			listen: !readOnly && !(isGroupDrawerOpened || isVariableModalOpened)
		}
	);

	// RESET TAB IF VARIABLE SET CHANGED
	const prevVariableSet = usePrevious(variableSet);
	useEffect(() => {
		if (prevVariableSet && prevVariableSet.setName !== variableSet.setName) {
			setTab(VariableSetTabs.Items);
		}
	}, [variableSet]);

	function handleDelete() {
		if (!(!readOnly && !updatingVariableSet)) return;

		onVariableSetDelete(setName);
	}

	function handleCreateVariable() {
		if (setName !== undefined) setDestinationSetName(setName);

		onCreateVariable();
	}

	function handleCreateGroup() {
		setDestinationSetName(setName);
		onCreateGroup();
	}

	const onItemsTab = tab === VariableSetTabs.Items;
	const onSettingsTab = tab === VariableSetTabs.Settings;

	return (
		<DrawerBody>
			<Spacer size={s => s.s} />

			{/* TITLE */}
			<Flex align={a => a.center} justify={j => j.between} paddingOffset={{ x: 1.6 }}>
				<Typography.H6>{translate(dict => dict.variablesPage.editSeries)}</Typography.H6>
				<Icon
					svg={Svgs.Close}
					size={s => s.m}
					colors={{ color: Colors.text.main }}
					onClick={onClose}
				/>
			</Flex>

			<Spacer size={s => s.s} />

			{/* TABS */}
			<Flex paddingOffset={{ x: 1.6 }}>
				<Tabs>
					<Tab active={onItemsTab} onClick={() => setTab(VariableSetTabs.Items)}>
						<Icon className="tab-icon" svg={Svgs.MenuProjects} propagate />
					</Tab>
					<Tab
						data-testid="set-drawer-settings"
						active={onSettingsTab}
						onClick={() => setTab(VariableSetTabs.Settings)}
					>
						<Icon className="tab-icon" svg={Svgs.Settings} propagate />
					</Tab>
				</Tabs>
			</Flex>

			<Spacer size={s => s.s} />
			<Divider />
			<Spacer size={s => s.s} />

			{/* CONTENT */}
			<Flex column style={{ height: `calc(100% - ${!readOnly ? 20.4 : 14.1}rem)` }}>
				{/* ITEMS */}
				{onItemsTab && (
					<>
						{!readOnly && (
							<Flex justify={j => j.between} paddingOffset={{ x: 1.6 }} wrap>
								<Typography.Caption
									onClick={handleCreateVariable}
									primaryColor
									clickable
									data-testid="create-variable"
								>
									+ {translate(dict => dict.variablesPage.newVariable)}
								</Typography.Caption>
								<Typography.Caption
									onClick={handleCreateGroup}
									primaryColor
									clickable
								>
									+ {translate(dict => dict.variablesPage.newVariableGroup)}
								</Typography.Caption>
							</Flex>
						)}

						<VariableSetDrawerItems
							variableSet={variableSet}
							readOnly={readOnly}
							preventDrag={preventDrag}
							mergeVariables={mergeVariables}
							onVariableClick={onVariableClick}
							onGroupClick={onGroupClick}
						/>
					</>
				)}

				{/* SETTINGS */}
				{onSettingsTab && (
					<VariableSetDrawerSettings variableSet={variableSet} readOnly={readOnly} />
				)}
			</Flex>

			{!readOnly && (
				<>
					<Divider />

					<DeleteWrapper>
						<Spacer size={s => s.m} />

						<Typography.Paragraph
							style={{ cursor: 'pointer' }}
							color={Colors.primary.normal}
							onClick={handleDelete}
						>
							{translate(dict => dict.variablesPage.deleteSeries)}
						</Typography.Paragraph>

						<Spacer size={s => s.m} />
					</DeleteWrapper>
				</>
			)}
		</DrawerBody>
	);
}

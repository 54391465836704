import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { shareWithInstance, ShareWithInstanceAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useShareTemplateWithInstance(): LocalOperation<
	(payload: ActionPayload<ShareWithInstanceAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ShareWithInstanceAction>) {
		dispatch(shareWithInstance(payload));
	}

	return handler;
}

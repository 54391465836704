import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import {
	resetFetchedCollaborators,
	ResetFetchedCollaboratorsAction
} from 'store/data/collaborators';
import { ActionPayload } from 'store/types';

export function useResetFetchedCollaborators(): LocalOperation<
	(payload: ActionPayload<ResetFetchedCollaboratorsAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<ResetFetchedCollaboratorsAction>) {
		dispatch(resetFetchedCollaborators(payload));
	}

	return handler;
}

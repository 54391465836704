import { useEffect } from 'react';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectVariablesFilters,
	setVariablesFilters,
	VariablesFilters,
	GridVariableTypes,
	ItemOptions,
	Sort
} from 'store/data/variables';
import { useDebounce, useDispatch, useMutableState, useSelector } from 'hooks/utils';
import { useVariablesSearchTerm } from './useVariablesSearchTerm';
import { VariableType } from 'types/data/variables/constants';

interface Handlers {
	setShowFilter: (item: ItemOptions) => void;
	setTypeFilter: (item: VariableType | GridVariableTypes.ALL) => void;
	setSort: (value: Sort) => void;
	toggleErroredFilter: () => void;
	clearErroredFilter: () => void;
	clearFilters: () => void;
}

interface Data {
	filters: VariablesFilters;
	draftFilters: VariablesFilters;
	areFiltersActive: boolean;
}

export function useVariablesFilters(): LocalOperationResult<Data, Handlers> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectVariablesFilters(state.data.variables));

	const [searchTerm] = useVariablesSearchTerm();

	const areFiltersActive =
		!!searchTerm.trim() ||
		data.show !== ItemOptions.ALL ||
		data.type !== GridVariableTypes.ALL ||
		data.sort !== Sort.DEFAULT;

	const [draftErrorFilter, setDraftErrorFilter] = useMutableState(data.errored);

	// SYNC `draftFilter` STATE
	useEffect(() => {
		if (draftErrorFilter !== data.errored) setDraftErrorFilter(data.errored);
	}, [data.errored]);

	useDebounce(
		() => {
			if (draftErrorFilter === data.errored) return;

			dispatch(
				setVariablesFilters({
					filters: {
						errored: draftErrorFilter
					}
				})
			);
		},
		[draftErrorFilter],
		400
	);

	function setShowFilter(item: ItemOptions) {
		dispatch(
			setVariablesFilters({
				filters: {
					show: item,
					type: GridVariableTypes.ALL
				}
			})
		);
	}

	function setTypeFilter(item: VariableType | GridVariableTypes.ALL) {
		dispatch(
			setVariablesFilters({
				filters: {
					type: item,
					show: ItemOptions.ALL
				}
			})
		);
	}

	function setSort(value: Sort) {
		dispatch(
			setVariablesFilters({
				filters: {
					sort: value
				}
			})
		);
	}

	function toggleErroredFilter() {
		setDraftErrorFilter(state => !state);
	}

	function clearErroredFilter() {
		dispatch(
			setVariablesFilters({
				filters: {
					errored: false
				}
			})
		);
	}

	function clearFilters() {
		dispatch(
			setVariablesFilters({
				filters: {
					show: ItemOptions.ALL,
					type: GridVariableTypes.ALL,
					sort: Sort.DEFAULT
				}
			})
		);
	}

	return [
		{
			filters: data,
			draftFilters: {
				...data,
				errored: draftErrorFilter
			},
			areFiltersActive
		},
		{
			setShowFilter,
			setTypeFilter,
			setSort,
			toggleErroredFilter,
			clearErroredFilter,
			clearFilters
		}
	];
}

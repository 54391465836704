import { Bounds } from './../../../../types/generic';
import { Icon } from 'components/UI/Icons';
import { Colors } from './../../../../environment/theme/Colors';
import { Tag as RegularTag } from 'components/UI/Tags';
import styled, { css } from 'styled-components';
import { Dropdown as BaseDropdown } from 'components/UI/Dropdown';
import { Table } from 'components/UI/Table';

export const Tag = styled(RegularTag)`
	${({ disabled }) => css`
		:not(:last-child) {
			margin-right: 0.8rem;
		}

		> div {
			border: 1px solid ${Colors.text.disabled};
			background: inherit;
		}

		span {
			color: ${Colors.text.main};
			white-space: nowrap;
		}

		${disabled &&
		css`
			background-color: ${Colors.background.disabled};
			span {
				color: ${Colors.text.disabled};
			}
		`};
	`}
`;

export const ThreeDotsMenu = styled(Icon)`
	position: absolute;
	right: 0.8rem;
	top: 0.8rem;
	padding: 0;

	:hover {
		color: unset;
		background: transparent;
	}
`;

export const Dropdown = styled(BaseDropdown)<Partial<Bounds>>`
	div {
		:nth-child(2) {
			min-width: 18rem;
			top: ${({ top }) => top}px;
			right: ${({ right }) => right}px;
		}
	}
`;

export const TableRow = styled(Table.Row)`
	> td:last-of-type {
		position: absolute;
		max-width: 0;
		border-width: 0;
		padding: 0;
		visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
	}

	// second last element
	td:nth-last-child(2) {
		border-right: none !important; // important overrides more specific react-table selector
	}

	:hover {
		> td:last-of-type {
			visibility: visible;
		}
	}
`;

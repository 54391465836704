import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { Collaborator, Organization, StatusTypeAccess } from 'store/data/collaborators/types';
import { Role, TemplateRole } from 'store/data/roles';
import { SelectGroup, SelectItem } from 'types/index';
import { RoleModalType } from './types';

const TEMPLATE_ROLE_PREFIX = 'template_';

type TemplateRoles = {
	owned: TemplateRole[];
	shared: TemplateRole[];
	public: TemplateRole[];
};

type TemplateRoleValidator = {
	hasTemplateRoles: boolean | undefined;
	hasOwned: boolean;
	hasShared: boolean;
	hasOrganization: boolean;
};

export function generateSelectRoleItems(
	roles: Role[],
	templateRoles: TemplateRoles | undefined,
	templateRoleValidator: TemplateRoleValidator
): SelectItem[] | SelectGroup<SelectItem>[] {
	const items: SelectItem[] = [];
	const groups: SelectGroup<SelectItem>[] = [];

	if (templateRoleValidator.hasTemplateRoles) {
		const owned = templateRoles?.owned ?? [];
		const shared = templateRoles?.shared ?? [];
		// TODO: add public roles when BE is ready
		// const publicRoles = templateRoles?.public ?? [];

		const templateRolesArray = [...owned, ...shared].filter((role, index, self) => {
			return self.findIndex(r => r.id === role.id) === index;
		});

		const templateRolesGroup = getSelectGroup(`Template Roles`);
		const projectRolesGroup = getSelectGroup(`Project Roles`);

		templateRolesArray.forEach(role => {
			const item: SelectItem = {
				label: role.name,
				// prefix the `id` to diff template-role vs project-role
				value: `${TEMPLATE_ROLE_PREFIX}${role.id}`
			};

			templateRolesGroup.options.push(item);
		});

		roles.forEach(role => {
			const item: SelectItem = {
				label: role.name,
				value: role.id
			};

			projectRolesGroup.options.push(item);
		});

		groups.push(templateRolesGroup);
		groups.push(projectRolesGroup);
	} else {
		if (roles.length > 0) {
			roles.forEach((role: Role) => {
				const item: SelectItem = {
					label: role.name,
					value: role.name
				};
				items.push(item);
			});
		}
	}

	return templateRoleValidator.hasTemplateRoles ? groups : items;
}

function getSelectGroup(label: string): SelectGroup<SelectItem> {
	return { label: label, options: [] };
}

export function getSelectedRole(
	value: string | null,
	items: SelectItem[] | SelectGroup<SelectItem>[],
	hasGroups: boolean
) {
	if (value) {
		if (hasGroups) {
			const groups = items as SelectGroup<SelectItem>[];
			for (const group of groups) {
				const item = group.options.find(option => option.value === value);
				if (item) return item;
			}
		} else {
			const item = (items as SelectItem[]).find(option => option.value === value);
			if (item) return item;
		}
	}

	return null;
}

export function generateModalTitle(type: RoleModalType, translate: TranslateFunction) {
	const map = {
		[RoleModalType.Create]: translate(({ roles }) => roles.modals.roleModal.title.create),
		[RoleModalType.Update]: translate(({ roles }) => roles.modals.roleModal.title.update),
		[RoleModalType.User]: translate(({ roles }) => roles.modals.roleModal.title.user)
	};

	return map[type];
}

export function isRoleNameUnique(
	roleName: string,
	{ roles, exclude = [] }: { roles: Role[]; exclude?: string[] }
) {
	roleName = roleName.trim().toLowerCase();

	const roleNames: string[] = [];

	roles.forEach(r => {
		if (exclude.includes(r.name.trim().toLowerCase())) return;

		roleNames.push(r.name.trim().toLowerCase());
	});

	return !roleNames.includes(roleName);
}

export function generateCollaboratorSelectItems(
	username: string | null,
	collaborators: Collaborator[]
): SelectItem[] {
	const items: SelectItem[] = [];

	collaborators.forEach((collaborator: Collaborator) => {
		const name = collaborator.userFirstName + ' ' + collaborator.userSirName;

		const isSelf = collaborator.userId === username;

		const isPending = !!collaborator.pending;

		if (!isSelf) {
			if (isPending) {
				const item: SelectItem = {
					label: collaborator.emailAddress,
					value: collaborator.userId
				};
				items.push(item);
			} else {
				const item: SelectItem = {
					label: name,
					value: collaborator.userId
				};
				items.push(item);
			}
		}
	});

	return items;
}

export function generateOrganizationSelectItems(organizations: Organization[]): SelectItem[] {
	const items: SelectItem[] = [];

	organizations.forEach((organization: Organization) => {
		const item: SelectItem = {
			label: organization.name,
			value: organization.id
		};
		items.push(item);
	});

	return items;
}

interface GetCollaboratorList {
	username: string | null;
	assignTo: {
		collaborators: boolean;
		specificCollaborator: boolean;
	};
	allCollaborators: Collaborator[];
	selectItems?: SelectItem[];
}

export function getCollaboratorList({
	assignTo,
	username,
	allCollaborators,
	selectItems
}: GetCollaboratorList): string[] {
	const items: string[] = [];

	if (assignTo.collaborators) {
		allCollaborators.forEach((collaborator: Collaborator) => {
			const isSelf = collaborator.userId === username;

			if (!isSelf) {
				items.push(collaborator.userId);
			}
		});
	}

	if (assignTo.specificCollaborator && selectItems) {
		selectItems.forEach((item: SelectItem) => {
			items.push(item.value);
		});
	}

	return items;
}

interface GetOrganizationList {
	assignTo: {
		organizations: boolean;
		specificOrganization: boolean;
	};
	allOrganizations: Organization[];
	selectItems?: SelectItem[];
}

export function getOrganizationList({
	assignTo,
	allOrganizations,
	selectItems
}: GetOrganizationList): string[] {
	const items: string[] = [];

	if (assignTo.organizations) {
		allOrganizations.forEach((organization: Organization) => {
			items.push(organization.id);
		});
	}

	if (assignTo.specificOrganization && selectItems) {
		selectItems.forEach((item: SelectItem) => {
			items.push(item.value);
		});
	}

	return items;
}

export function getRolePermission(
	projectRoles: Role[],
	templateRoles: TemplateRoles,
	id: string,
	projectId: string | null,
	statuses?: StatusTypeAccess[]
) {
	let parsedId = id;

	// if the id is a template role convert to original ID
	if (id.startsWith(TEMPLATE_ROLE_PREFIX)) {
		parsedId = id.replace(TEMPLATE_ROLE_PREFIX, '');
	}

	const flattenTemplateRoles = [
		...templateRoles.owned,
		...templateRoles.shared,
		...templateRoles.public
	].filter((role, index, self) => {
		return self.findIndex(r => r.id === role.id) === index;
	});

	const templateRole = convertTemplateRoleToProjectRole(
		projectId,
		statuses,
		flattenTemplateRoles.find(role => role.id === parsedId)
	);

	const projectRole = projectRoles.find(role => role.id === parsedId);

	return templateRole ? templateRole.permissions : projectRole?.permissions;
}

function convertTemplateRoleToProjectRole(
	projectId: string | null,
	statuses?: StatusTypeAccess[],
	role?: TemplateRole
): Role | undefined {
	if (role && projectId) {
		return {
			id: role.id,
			name: role.name,
			projectId: projectId,
			description: role.description,
			permissions: {
				...role.permissions,
				modules: {
					collaborators: true,
					projectDesign: true
				},
				// 8030
				projectOrganizationAccesses: [],
				statusTypeAccesses: statuses ?? []
			}
		};
	}
}

import { useEffect, useState } from 'react';
import { Svgs, Colors } from 'environment';
import { Container } from './HistoryPagination.style';
import { Revision } from 'store/data/revisions';
import { Flex } from '../Flex';
import { Icon } from '../Icons';
import { Typography } from '../Typography';
import {
	useCurrentChangeOfRevision,
	useIsClickedHistoryPagination,
	useTranslation,
	useVariablesData
} from 'hooks/store';
import { useDeepCompareMemo } from 'hooks/utils';

interface Props {
	revision?: Revision;
	goToChangedVariable: (variableNames: string[], currentChangeLocal: number) => void;
}

export function HistoryPagination({ revision, goToChangedVariable }: Props) {
	const [currentChangeLocal, setCurrentChangesLocal] = useCurrentChangeOfRevision();
	const [, setIsClicked] = useIsClickedHistoryPagination();
	const { translate } = useTranslation();

	const { order, groupsMap } = useVariablesData();

	const totalChanges =
		(revision && revision.changes.list.length) ||
		(revision && revision.statusChanges.from !== revision.statusChanges.to && 1) ||
		0;
	const [currentChange, setCurrentChange] = useState(totalChanges > 0 ? 1 : 0);

	// changed variables in order
	const variablesOrder = useDeepCompareMemo<string[]>(() => {
		if (!revision) return [];
		return order.reduce((acc, orderItem) => {
			if (
				'variable' in orderItem &&
				revision.changes.variableNames.includes(orderItem.variable)
			) {
				return [...acc, orderItem.variable];
			} else if ('group' in orderItem) {
				const changedGroupVariables = groupsMap[
					orderItem.group
				].variablesBelongingToGroup.filter(variable =>
					revision.changes.variableNames.includes(variable)
				);
				return [...acc, ...changedGroupVariables];
			}
			return acc;
		}, [] as string[]);
	}, [order, revision]);

	useEffect(() => {
		setCurrentChange(totalChanges > 0 ? 1 : 0);
	}, [totalChanges]);

	useEffect(() => {
		if (currentChange) {
			setCurrentChangesLocal(currentChange);
		}
	}, [currentChange]);

	useEffect(() => {
		setIsClicked(false);
	}, [currentChangeLocal]);

	useEffect(() => {
		if (currentChangeLocal === 1) {
			goToChangedVariable(variablesOrder, currentChangeLocal);
		}
	}, [revision, currentChangeLocal, variablesOrder]);

	function handleNext() {
		if (totalChanges >= currentChange + 1) {
			setCurrentChange(prev => prev + 1);
			setIsClicked(true);
			goToChangedVariable(variablesOrder, currentChangeLocal + 1);
		}
	}

	function handleLastChange() {
		setCurrentChangesLocal(totalChanges);
		setCurrentChange(totalChanges);
		setIsClicked(true);
		goToChangedVariable(variablesOrder, totalChanges);
	}

	function handlePrev() {
		if (currentChange - 1 >= 1) {
			setCurrentChange(prev => prev - 1);
			setIsClicked(true);
			goToChangedVariable(variablesOrder, currentChangeLocal - 1);
		}
	}

	function handleFirstChange() {
		setCurrentChangesLocal(1);
		setCurrentChange(1);
		setIsClicked(true);
		goToChangedVariable(variablesOrder, 1);
	}

	return (
		<Container>
			<Flex>
				<Icon
					svg={Svgs.ChevronLeftDouble}
					variant={v => v.buttonActive}
					size={s => s.m}
					disabled={currentChange === 1}
					onClick={handleFirstChange}
					colors={{ color: Colors.background.disabled }}
				/>
				<Icon
					svg={Svgs.ChevronLeft}
					variant={v => v.buttonActive}
					size={s => s.m}
					disabled={currentChange - 1 === 0}
					onClick={handlePrev}
					colors={{ color: Colors.background.disabled }}
				/>
			</Flex>

			<Typography.Paragraph color={Colors.background.disabled}>
				{translate(dict => dict.dataset.historyChanges)} {currentChange} / {totalChanges}
			</Typography.Paragraph>
			<Flex>
				<Icon
					svg={Svgs.ChevronRight}
					variant={v => v.buttonActive}
					size={s => s.m}
					disabled={currentChange === totalChanges}
					onClick={handleNext}
					colors={{ color: Colors.background.disabled }}
				/>
				<Icon
					svg={Svgs.ChevronRightDouble}
					variant={v => v.buttonActive}
					size={s => s.m}
					disabled={currentChange === totalChanges}
					onClick={handleLastChange}
					colors={{ color: Colors.background.disabled }}
				/>
			</Flex>
		</Container>
	);
}

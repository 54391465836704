import { useMatchProjects } from 'hooks/navigation';
import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { resetProjectsFilters } from 'store/data/projects';

export function useResetProjectsFilters(): LocalOperation {
	const dispatch = useDispatch();
	const matchProjects = useMatchProjects();

	function handler() {
		dispatch(resetProjectsFilters({ isProject: matchProjects }));
	}

	return handler;
}

import { useDispatch, useSelector } from 'hooks/utils';
import { setCurrentChange, SetCurrentChangeAction } from 'store/data/revisions';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

type Handler = (currentChange: ActionPayload<SetCurrentChangeAction>['currentChange']) => void;

export function useCurrentChangeOfRevision(): LocalOperationResult<number, Handler> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.revisions.metadata.currentChange);

	function handler(currentChange: ActionPayload<SetCurrentChangeAction>['currentChange']) {
		dispatch(setCurrentChange({ currentChange }));
	}

	return [data, handler];
}

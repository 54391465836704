import { ROUTES } from 'types/navigation';
import { Typography } from 'components/UI/Typography';
import { useRouteMatch } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';

export function CookiesNotice() {
	const { translate } = useTranslation();

	const isOnPatientEditEntryPage = useRouteMatch([ROUTES.PatientEditEntry]);

	if (!isOnPatientEditEntryPage) return null;

	return (
		<Typography.Paragraph
			fontweight={w => w.bold}
			paddingOffset={{ all: 1 }}
			marginOffset={{ top: 2 }}
		>
			{translate(dict => dict.addEditEntry.cookieMessage)}
		</Typography.Paragraph>
	);
}

import { useEffect } from 'react';
import { PromStatistics } from 'api/data/dashboard';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getDistributionStatistics,
	selectAutomaticDistributionStatisticsForProm,
	selectManualDistributionStatisticsForProm,
	selectIsDashboardFetched
} from 'store/data/dashboard';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

interface Options {
	lazy?: boolean;
}

interface DataType {
	automatic: PromStatistics | undefined;
	manual: string[] | undefined;
}

export function useDashboard(options?: Options): OperationResult<DataType> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const automaticDistributionStatistics = useSelector(state =>
		selectAutomaticDistributionStatisticsForProm(state.data.dashboard)
	);

	const manualDistributionStatistics = useSelector(state =>
		selectManualDistributionStatisticsForProm(state.data.dashboard)
	);

	const fetched = useSelector(state => selectIsDashboardFetched(state.data.dashboard));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_DISTRIBUTION_STATISTICS, {
		projectId
	});

	useEffect(() => {
		if (
			!loading &&
			!error &&
			!automaticDistributionStatistics &&
			!manualDistributionStatistics &&
			isProjectValid &&
			!options?.lazy
		) {
			handler();
		}
	}, [
		loading,
		error,
		manualDistributionStatistics,
		automaticDistributionStatistics,
		isProjectValid
	]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getDistributionStatistics());
	}

	return [
		{
			loading,
			error,
			data: {
				automatic: automaticDistributionStatistics,
				manual: manualDistributionStatistics
			},
			fetched
		},
		handler
	];
}

import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Suspend } from 'components/UI/Suspend';
import { useSubscription } from 'hooks/store';
// import { useTranslation } from 'hooks/store';

export function EnterpriseAdminDashboardPage() {
	// const { translate } = useTranslation();

	const [
		{
			data: {
				userTypesAndRoles: {
					// ledidiEnterprise: { isAdmin, isOrganizationAdmin, isSuperAdmin, isUser }
				}
			},
			loading
		}
	] = useSubscription();
	// const hasLedidiEnterpriseAdminRole = isOrganizationAdmin || isSuperAdmin || isAdmin || isUser;

	return (
		<>
			<Header.Main />
			<Header.Navigation rightComponent={<Flex></Flex>} />

			<Suspend loading={loading} immediate={false}>
				<div>Enterprise Admin Dashboard page</div>
			</Suspend>
		</>
	);
}

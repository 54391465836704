import { CompareNumericAnalysisV2 } from 'api/data/analyses';
import { VariablesData } from 'store/data/variables';

import { CompareNumericTukey } from './CompareNumericTukey';
import { CompareNumericIndependent } from './CompareNumericIndependent';
import { CompareNumericKruskal } from './CompareNumericKruskal';
import { CompareNumericMannWhitney } from './CompareNumericMannWhitney';
import { CompareNumericOneWayAnova } from './CompareNumericOneWayAnova';
import { CompareNumericTwoWayAnova } from './CompareNumericTwoWayAnova';
import { CompareNumericOneWayManova } from './CompareNumericOneWayManova';
import { CompareNumericTwoWayManova } from './CompareNumericTwoWayManova';
import { Gap as DefaultGap } from 'components/UI/Gap';
import styled from 'styled-components';

interface Props {
	analysis: CompareNumericAnalysisV2;
	variablesData: VariablesData;
}

const Gap = styled(DefaultGap)`
	> div {
		width: fit-content;
	}
`;

export function CompareNumericStatistics({ analysis, variablesData }: Props) {
	const {
		input: { statistics: inputStatistics },
		output: { statistics: outputStatistics }
	} = analysis;

	const statistics = {
		independent: {
			visible: inputStatistics.independentV2,
			results: outputStatistics.independent
		},
		mannWhitney: {
			visible: inputStatistics.mannWhitneyV2,
			results: outputStatistics.mannWhitney
		},
		oneWayAnova: {
			visible: inputStatistics.oneWayAnovaV2,
			results: outputStatistics.oneWayAnova
		},
		tukey: {
			visible: inputStatistics.tukeyV2,
			results: outputStatistics.tukey
		},
		kruskal: {
			visible: inputStatistics.kruskalV2,
			results: outputStatistics.kruskal
		},
		twoWayAnova: {
			visible: inputStatistics.twoWayAnovaV2,
			results: outputStatistics.twoWayAnova
		},
		oneWayManova: {
			visible: inputStatistics.oneWayManovaV2,
			results: outputStatistics.oneWayManova
		},
		twoWayManova: {
			visible: inputStatistics.twoWayManovaV2,
			results: outputStatistics.twoWayManova
		}
	};

	return (
		<Gap marginGap={{ bottom: 2.4 }} notLastChild>
			{statistics.independent.visible && (
				<CompareNumericIndependent results={statistics.independent.results} />
			)}

			{statistics.mannWhitney.visible && (
				<CompareNumericMannWhitney results={statistics.mannWhitney.results} />
			)}

			{statistics.oneWayAnova.visible && (
				<CompareNumericOneWayAnova
					variablesData={variablesData}
					results={statistics.oneWayAnova.results}
				/>
			)}

			{statistics.tukey.visible && (
				<CompareNumericTukey analysis={analysis} results={statistics.tukey.results} />
			)}

			{statistics.kruskal.visible && (
				<CompareNumericKruskal results={statistics.kruskal.results} />
			)}

			{statistics.twoWayAnova.visible && (
				<CompareNumericTwoWayAnova
					variablesData={variablesData}
					results={statistics.twoWayAnova.results}
				/>
			)}

			{statistics.oneWayManova.visible && (
				<CompareNumericOneWayManova
					variablesData={variablesData}
					results={statistics.oneWayManova.results}
				/>
			)}

			{statistics.twoWayManova.visible && (
				<CompareNumericTwoWayManova
					variablesData={variablesData}
					results={statistics.twoWayManova.results}
				/>
			)}
		</Gap>
	);
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, shareTemplate, ShareTemplateAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useShareTemplate(): Operation<
	(payload: ActionPayload<ShareTemplateAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.SHARE_TEMPLATE);

	function handler(payload: ActionPayload<ShareTemplateAction>) {
		dispatch(shareTemplate(payload));
	}

	return [{ loading, error }, handler];
}

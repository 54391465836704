import styled from 'styled-components';

import { Operator } from 'api/data/filters';
import { VariableUniquenessType } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { DropdownItem as DefaultDropdownItem } from 'components/UI/Dropdown/DropdownItem';
import { useTranslation } from 'hooks/store';
import { Dropdown } from 'components/UI/Dropdown';
import { Spacer } from 'components/UI/Spacer';
import { getOperatorLabel } from 'helpers/displayers';
import { ProjectFilterType } from 'types/data/projects/constants';

interface Props {
	selected: Operator;
	type?: VariableType | ProjectFilterType;
	subtype?: VariableUniquenessType;
	disabled?: boolean;
	onSelect: (operator: Operator) => void;
}

export function SelectOperator({
	selected,
	type = VariableType.String,
	disabled,
	onSelect,
	subtype
}: Props) {
	const { translate } = useTranslation();

	if (
		type === VariableType.String ||
		(type === VariableType.Unique && subtype === VariableUniquenessType.Manual) ||
		(type === VariableType.Unique && subtype === VariableUniquenessType.UUID)
	)
		return null;

	const isDateOrDateTime = [
		VariableType.Date,
		VariableType.DateTime,
		ProjectFilterType.Date
	].includes(type);

	const isDateTime = type === VariableType.DateTime;
	const isDate = type === VariableType.Date || type === ProjectFilterType.Date;

	const equalsLabel: string = isDate
		? translate(dict => dict.displayers.atADate)
		: isDateTime
		? translate(dict => dict.displayers.atADateAndTime)
		: translate(dict => dict.displayers.equals);

	return (
		<>
			<Dropdown
				title={translate(() => getOperatorLabel(selected, type))}
				disabled={disabled}
				button
			>
				<DropdownItem onClick={() => onSelect(Operator.Between)}>
					{translate(dict => dict.displayers.between)}
				</DropdownItem>
				<DropdownItem onClick={() => onSelect(Operator.Equals)}>{equalsLabel}</DropdownItem>
				<DropdownItem onClick={() => onSelect(Operator.LessThan)}>
					{isDateOrDateTime
						? translate(dict => dict.displayers.before)
						: translate(dict => dict.displayers.lessThan)}
				</DropdownItem>
				<DropdownItem onClick={() => onSelect(Operator.GreaterThan)}>
					{isDateOrDateTime
						? translate(dict => dict.displayers.after)
						: translate(dict => dict.displayers.greaterThan)}
				</DropdownItem>
			</Dropdown>

			<Spacer size={s => s.xs} />
		</>
	);
}

const DropdownItem = styled(DefaultDropdownItem)`
	padding: 0 1rem;
`;

import { useState, useEffect } from 'react';
import { AddonsOptionLabel } from 'store/account/addons';
import { SubscriptionAddonCode, StorageKeys } from 'types/index';
import { Header } from 'components/Header';
import { Grid } from 'components/UI/Grid';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useAccount,
	useUserAddons,
	useActivateUserAddon,
	useDeactivateUserAddon,
	useLogOutFromJADBio
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';

export function AccountUMAddonsPage() {
	const { translate } = useTranslation();

	const [{ loading: accountLoading, fetched: accountFetched }] = useAccount();

	const [
		{
			data: { userAddons, activeUserAddons },
			loading: userSubscriptionAddonsLoading,
			error: userSubscriptionAddonsError,
			fetched: userSubscriptionAddonsFetched
		}
	] = useUserAddons();

	const [
		{ loading: activateAddonLoading, error: activateAddonError },
		activateSubscriptionAddon
	] = useActivateUserAddon();

	const [
		{ loading: deactivateAddonLoading, error: deactivateAddonError },
		deactivateSubscriptionAddon
	] = useDeactivateUserAddon();

	const [currentActiveUserAddons, setCurrentActiveUserAddons] = useState(activeUserAddons);

	const logoutFromJADBio = useLogOutFromJADBio();

	useEffect(() => {
		setCurrentActiveUserAddons(activeUserAddons);
	}, [activeUserAddons]);

	useEffect(() => {
		if (deactivateAddonError || deactivateAddonError) {
			// revert currentAddons changes to store values
			setCurrentActiveUserAddons(activeUserAddons);
		}
	}, [activateAddonError, deactivateAddonError, activeUserAddons]);

	const [timers, setTimers] = useState<Record<SubscriptionAddonCode, number>>();

	const [refetchUserAddons, setRefetchUserAddons] = useState<SubscriptionAddonCode | null>(null);

	useDebounce(
		() => {
			if (refetchUserAddons) {
				localStorage.setItem(StorageKeys.RefetchUserAddons, 'true');
				setRefetchUserAddons(null);
				// in case it's a JADBio addon, logout from JADBio and remove token from store
				if (refetchUserAddons === SubscriptionAddonCode.JADBio) {
					localStorage.removeItem(StorageKeys.JADBioIdToken);
					logoutFromJADBio();
				}
			}
		},
		[refetchUserAddons],
		1000
	);

	// Updates state of currentAddons
	// Sends debounced api call to activate or deactivate addon
	function toggleSubscriptionAddon(code: SubscriptionAddonCode) {
		if (currentActiveUserAddons && userAddons) {
			if (timers && timers[code]) {
				clearTimeout(timers[code]);
			}

			const currentlyEnabled = currentActiveUserAddons.includes(code);

			if (currentlyEnabled) {
				setCurrentActiveUserAddons(currentState =>
					currentState ? currentState.filter(addon => addon !== code) : null
				);
			} else {
				setCurrentActiveUserAddons(currentState => {
					if (currentState) {
						const newState = currentState.slice();
						newState.push(code);
						return newState;
					}
					return null;
				});
			}

			// FIXME:
			// @ts-ignore
			setTimers(prevState => {
				return {
					...prevState,
					[code]: setTimeout(() => {
						if (
							currentlyEnabled &&
							activeUserAddons &&
							activeUserAddons.includes(code)
						) {
							deactivateSubscriptionAddon(code);
							setRefetchUserAddons(code);
						} else if (activeUserAddons && !activeUserAddons.includes(code)) {
							activateSubscriptionAddon(code);
							setRefetchUserAddons(code);
						}
					}, 400)
				};
			});
		}
	}

	return (
		<>
			<Header.Main
				rightComponent={null}
				leftComponent={translate(({ accountUM }) => accountUM.subscriptionManagementTitle)}
			/>
			<Header.Navigation />
			<Header.Title title={translate(({ accountUM }) => accountUM.tabs.addons)} />

			<Suspend
				loading={accountLoading || userSubscriptionAddonsLoading}
				immediate={!accountFetched || !userSubscriptionAddonsFetched}
			>
				<Grid.Container>
					<Spacer size={s => s.m} />
					{userAddons &&
						currentActiveUserAddons &&
						userAddons.map(addonCode => (
							<Switch
								key={addonCode}
								label={translate(() => AddonsOptionLabel[addonCode])}
								disabled={
									activateAddonLoading || deactivateAddonLoading || accountLoading
								}
								on={currentActiveUserAddons.includes(addonCode)}
								onChange={() => toggleSubscriptionAddon(addonCode)}
							/>
						))}
					{userSubscriptionAddonsError && (
						<Typography.Paragraph>
							{translate(dict => dict.addons.errorLoadingAddons)}
						</Typography.Paragraph>
					)}
				</Grid.Container>
			</Suspend>
		</>
	);
}

import { useState } from 'react';

import { useTranslation } from 'hooks/store';
import { EntryStatus } from 'store/data/entries';
import { InputType } from 'types/index';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { Input } from 'components/UI/Inputs/Input';

interface Props {
	entryStatus: EntryStatus;
	statusesWithLabelAndValue: {
		label: string;
		value: string;
	}[];
	handleUpdateStatus: (entryStatus: EntryStatus) => void;
	onClose: () => void;
}

export function SetStatusModal({
	entryStatus,
	statusesWithLabelAndValue,
	handleUpdateStatus,
	onClose
}: Props) {
	const { translate } = useTranslation();

	const [comment, setComment] = useState('');

	function handleSubmit() {
		if (entryStatus) handleUpdateStatus({ ...entryStatus, comment: comment.trim() });
	}

	return (
		<Modal
			title={translate(dict => dict.statuses.statusModal.updateTitle)}
			primary={{
				label: translate(dict => dict.buttons.save),
				onClick: handleSubmit
			}}
			onClose={onClose}
			visible
			close
		>
			<Typography.Paragraph>
				{`Status: `}
				<b>
					{
						statusesWithLabelAndValue.find(el => el.value === entryStatus?.variableName)
							?.label
					}
				</b>
			</Typography.Paragraph>

			<Spacer size={s => s.s} />

			<Input
				type={InputType.Textarea}
				label={translate(dict => dict.statuses.statusModal.comment)}
				rows={4}
				value={comment}
				onChange={e => setComment(e.target.value)}
				onBlur={() => setComment(state => state.trim())}
				onSubmit={handleSubmit}
				autoFocus
			/>
		</Modal>
	);
}

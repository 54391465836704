import { useEffect, Fragment } from 'react';
import { AddFirstCard, CreditCard } from 'components/Account';

import { CreditCardWrapper, RadioButton } from './style';
import { SubscriptionPaymentMethods } from 'store/account/payments';
import { Button } from 'components/UI/Interactables/Button';
import { Loader } from 'components/UI/Loader';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useBillingInfo, useMakePaymentSourcePrimary } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { usePrevious } from 'hooks/utils';

interface Props {
	paymentMethods: SubscriptionPaymentMethods;
	paymentMethodsLoading: boolean;
	setCreditCardModalVisible: () => void;
}

export function SelectCreditCard({
	paymentMethods,
	paymentMethodsLoading,
	setCreditCardModalVisible
}: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();
	const [, getBillingInfo] = useBillingInfo();

	const [
		{ loading: makingPamentSourcePrimary, error: errorMakingPamentSourcePrimary },
		makePaymentSourcePrimary
	] = useMakePaymentSourcePrimary();

	const wasMakingPamentSourcePrimary = usePrevious(makingPamentSourcePrimary);
	useEffect(() => {
		if (
			!makingPamentSourcePrimary &&
			wasMakingPamentSourcePrimary &&
			!errorMakingPamentSourcePrimary
		) {
			setNotification({
				message: translate(
					({ accountUM }) => accountUM.payment.notifications.madePaymentSourcePrimary
				)
			});
			getBillingInfo();
		}
	}, [makingPamentSourcePrimary, errorMakingPamentSourcePrimary]);

	return (
		<>
			{paymentMethodsLoading ? (
				<Loader />
			) : paymentMethods?.cards?.length ? (
				<>
					{paymentMethods.cards?.map((creditCard, index) => (
						<Fragment key={index}>
							<CreditCardWrapper
								onClick={() =>
									creditCard.isPrimary
										? undefined
										: makePaymentSourcePrimary(creditCard.id)
								}
							>
								<RadioButton
									selected={creditCard.isPrimary}
									disabled={makingPamentSourcePrimary}
									onSelect={() =>
										creditCard.isPrimary
											? undefined
											: makePaymentSourcePrimary(creditCard.id)
									}
								/>
								<CreditCard creditCard={creditCard} />
							</CreditCardWrapper>
							{index < paymentMethods.cards.length - 1 ? (
								<Spacer size={s => s.s} />
							) : (
								<Spacer size={s => s.m} />
							)}
						</Fragment>
					))}
					<Button
						variant={v => v.link}
						title={translate(dict => dict.accountUM.payment.addAnotherCard)}
						onClick={setCreditCardModalVisible}
					/>
				</>
			) : (
				<AddFirstCard setAddCreditCardModalVisible={setCreditCardModalVisible} />
			)}

			<Spacer size={s => s.xs} />
		</>
	);
}

import styled from 'styled-components';
import { Separator as DefaultSeparator } from 'components/UI/Separator';
import { Slider as DefaultSlider } from 'components/UI/Slider';

export const Container = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const Slider = styled(DefaultSlider)`
	height: calc(100% - 6.7rem);
	overflow-x: hidden;
`;

export const Version = styled.div`
	height: 6.7rem;
	padding: 0 2.4rem;
	display: flex;
	flex-direction: column;
`;

export const Separator = styled(DefaultSeparator)`
	margin-bottom: 1.6rem;
`;

const StyledSlide = styled(DefaultSlider.Slide)`
	overflow-y: auto;
	height: 100%;
`;
export const Slide = StyledSlide;

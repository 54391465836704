import styled from 'styled-components';

export const UpdatingSubscription = styled.div`
	@keyframes opacity {
		25% {
			opacity: 0.6;
		}
		50% {
			content: 0.8;
		}
		75% {
			content: 1;
		}
		100% {
			content: 0.8;
		}
	}

	animation: opacity 2s infinite;
`;

export const EllipsisAnimation = styled.span`
	display: 'inline-block';

	@keyframes ellipsis {
		25% {
			content: '.';
		}
		50% {
			content: '..';
		}
		75% {
			content: '...';
		}
		100% {
			content: '';
		}
	}

	:after {
		animation: ellipsis steps(1, end) 1s infinite;
		content: '';
	}
`;

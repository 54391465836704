import { DATA_URL, sendRequest } from 'api/utils';

import {
	DisableVariablesMappingInput,
	DisableVariablesMappingRequest,
	DisableVariablesMappingResponse,
	EnableVariablesMappingInput,
	EnableVariablesMappingRequest,
	EnableVariablesMappingResponse,
	GetVariablesMappingInput,
	GetVariablesMappingOutput,
	GetVariablesMappingRequest,
	GetVariablesMappingResponse,
	InitializeVariablesMappingInput,
	InitializeVariablesMappingRequest,
	InitializeVariablesMappingResponse,
	InitializeVariablesMappingOutput,
	ResetVariableMappingInput,
	ResetVariableMappingOutput,
	ResetVariableMappingRequest,
	ResetVariableMappingResponse,
	SetVariableMappingInput,
	SetVariableMappingOutput,
	SetVariableMappingRequest,
	SetVariableMappingResponse
} from './types';

const methods = {
	initializeVariablesMapping: 'initializeVariablesMapping',
	getVariablesMapping: 'getVariablesMapping',
	setVariableMapping: 'setVariableMapping',
	resetVariableMapping: 'resetVariableMapping',
	enableVariablesMapping: 'enableVariablesMapping',
	disableVariablesMapping: 'disableVariablesMapping'
};

export default () => ({
	async initializeVariablesMapping(
		input: InitializeVariablesMappingInput
	): Promise<InitializeVariablesMappingOutput> {
		const { data } = await sendRequest<
			InitializeVariablesMappingRequest,
			InitializeVariablesMappingResponse
		>(DATA_URL, {
			method: methods.initializeVariablesMapping,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return {
			variablesMappingData: data.variablesMappingData
		};
	},

	async getVariablesMapping(input: GetVariablesMappingInput): Promise<GetVariablesMappingOutput> {
		const { data } = await sendRequest<GetVariablesMappingRequest, GetVariablesMappingResponse>(
			DATA_URL,
			{
				method: methods.getVariablesMapping,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return {
			variablesMappingData: data.variablesMappingData,
			variablesMappingDataBySetName: data.variablesMappingDataBySetName
		};
	},

	async setVariableMapping(input: SetVariableMappingInput): Promise<SetVariableMappingOutput> {
		const { data } = await sendRequest<SetVariableMappingRequest, SetVariableMappingResponse>(
			DATA_URL,
			{
				method: methods.setVariableMapping,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return {
			variableMapping: data.variableMapping
		};
	},

	async resetVariableMapping(
		input: ResetVariableMappingInput
	): Promise<ResetVariableMappingOutput> {
		const { data } = await sendRequest<
			ResetVariableMappingRequest,
			ResetVariableMappingResponse
		>(DATA_URL, {
			method: methods.resetVariableMapping,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		return {
			variableMapping: data.variableMapping
		};
	},

	async enableVariablesMapping(input: EnableVariablesMappingInput): Promise<void> {
		const { data } = await sendRequest<
			EnableVariablesMappingRequest,
			EnableVariablesMappingResponse
		>(DATA_URL, {
			method: methods.enableVariablesMapping,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	},

	async disableVariablesMapping(input: DisableVariablesMappingInput): Promise<void> {
		const { data } = await sendRequest<
			DisableVariablesMappingRequest,
			DisableVariablesMappingResponse
		>(DATA_URL, {
			method: methods.disableVariablesMapping,
			...input
		});

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}
	}
});

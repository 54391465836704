import { useDispatch, useSelector } from 'hooks/utils';
import {
	setEntriesTableVisibleColumns,
	selectEntriesTableVisibleColumns
} from 'store/data/entries';
import { LocalOperationResult } from 'hooks/store/types';

type HandlerInput = Parameters<typeof setEntriesTableVisibleColumns>[0]['columnNames'];

export function useEntriesTableVisibleColumns(): LocalOperationResult<
	string[],
	(columnNames: HandlerInput) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectEntriesTableVisibleColumns(state.data.entries));

	function handler(columnNames: HandlerInput) {
		dispatch(setEntriesTableVisibleColumns({ columnNames }));
	}

	return [data, handler];
}

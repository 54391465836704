import { Colors } from 'environment';
import { useTranslation } from 'hooks/store';
import { Collaborator } from 'store/data/collaborators';

import { GenericCard } from '../styles';

import { UserTag } from 'components/UI/UserCard/UserTag';
import { Flex } from 'components/UI/Flex';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Typography } from 'components/UI/Typography';
import { getMultilineEllipsisCSS } from 'helpers/cssGenerators';

interface Props {
	collaborator: Collaborator;
	isSelf: boolean;
	isOwner: boolean;
	clickable: boolean;
	onClick: () => void;
}

export function CollaboratorCard({ collaborator, isSelf, isOwner, clickable, onClick }: Props) {
	const { translate } = useTranslation();

	const { userId, emailAddress, userFirstName, userSirName, organization } = collaborator;

	function getName(): string {
		if (!(userFirstName !== undefined && userSirName !== undefined)) return `N/A`;

		return `${userFirstName ?? ''} ${userSirName ?? ''}`.trim();
	}

	return (
		<GenericCard onClick={onClick} clickable={isSelf || isOwner || clickable}>
			<Avatar userId={userId} data={{ userFirstName, userSirName }} size={s => s.l} />

			<Typography.H6
				marginOffset={{ top: 0.4, bottom: 0.8 }}
				title={getName()}
				css={`
					${getMultilineEllipsisCSS(2)}
				`}
			>
				{getName()}
			</Typography.H6>

			<Flex flex={1} align={a => a.center} justify={j => j.end} column>
				<Typography.Hint marginOffset={{ bottom: 0.2 }}>{emailAddress}</Typography.Hint>
				<Typography.Hint color={Colors.text.disabled}>{organization}</Typography.Hint>
			</Flex>

			{isSelf && <UserTag className="self-tag" title={translate(({ tags }) => tags.you)} />}
			{isOwner && (
				<UserTag className="owner-tag" title={translate(({ tags }) => tags.owner)} />
			)}
		</GenericCard>
	);
}

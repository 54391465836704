import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Operation } from 'hooks/store/types';
import { selectActivity, selectError } from 'store/ui/activities';
import { usePrevious, useSelector } from 'hooks/utils';

export function useActivity(type: string, payload?: any): Operation {
	const [localError, setLocalError] = useState(false);

	const loading = useSelector(state => selectActivity(state.ui.activities, { type, payload }));
	const errored = useSelector(state => selectError(state.ui.activities, { type, payload }));

	useEffect(() => {
		if (errored) setLocalError(true);
	}, [errored]);

	const prevPayload = usePrevious(payload);
	useEffect(() => {
		if ([payload, prevPayload].includes(undefined)) return;

		if (localError && !isEqual(payload, prevPayload)) setLocalError(false);
	}, [payload, localError]);

	function reset() {
		setLocalError(false);
	}

	return [{ loading, error: localError }, reset];
}

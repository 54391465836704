import { Flex } from 'components/UI/Flex';
import styled, { css } from 'styled-components';
interface ThumbnailProps {
	usedInList?: boolean;
}
export const FormThumbnailContainer = styled.div<ThumbnailProps>`
	height: 0%;
	transform: scale(0.4);
	pointer-events: none;
	margin-left: -16rem;
	margin-right: -16rem;

	${({ usedInList }) =>
		usedInList &&
		css`
			height: 5%;
			transform: scale(0.2);
			margin-left: -20rem;
			margin-right: -20rem;
		`}
	> div > div {
		max-width: 52.9rem;
	}

	.react-date-picker__inputGroup {
		min-width: 50%;
	}
`;

export const FormGroupWrapper = styled.div`
	> div {
		margin: 1rem 0;
	}
`;

export const TwoColumnRow = styled(Flex)`
	> div {
		width: 50%;
		> div {
			margin: 1.2rem;
		}
	}
`;

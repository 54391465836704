import { throttle } from 'lodash';
import { useEffect } from 'react';
import { EventType, SetState } from 'types/index';

interface Props {
	pages: number[];
	targetClass: string;
	setPageNumberInView: SetState<number>;
	pageComponent: (pageNumber: number) => JSX.Element;
}

function isPageInViewport(element: Element) {
	const rect = element.getBoundingClientRect();
	return rect.top < rect.height / 2 - 150; // Accounting for the size of the header
}

export function NumberedPages({ targetClass, pages, pageComponent, setPageNumberInView }: Props) {
	function setPageNumber() {
		pages.forEach(page => {
			const next = page === pages.length ? page : page + 1;

			const pageDiv = document.getElementById(`page-${page}`);
			const nextPageDiv = document.getElementById(`page-${page + 1}`);

			if (pageDiv && isPageInViewport(pageDiv)) {
				if (nextPageDiv && isPageInViewport(nextPageDiv)) setPageNumberInView(next);
				else setPageNumberInView(page);
			}
		});
	}

	useEffect(() => {
		const scrollContainer = document.querySelector(`.${targetClass}`);
		scrollContainer &&
			scrollContainer.addEventListener(EventType.Scroll, throttle(setPageNumber, 500));

		return () => {
			scrollContainer &&
				scrollContainer.removeEventListener(EventType.Scroll, throttle(setPageNumber, 500));
		};
	}, [pages]);

	return (
		<>
			{pages.map(pageNumber => (
				<div id={`page-${pageNumber}`} key={`page-${pageNumber}`}>
					{pageComponent(pageNumber)}
				</div>
			))}
		</>
	);
}

import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';
import { deleteAnalysis } from 'store/data/analyses';

export function useDeleteAnalysis(): LocalOperation<(id: string) => void> {
	const dispatch = useDispatch();

	function handler(id: string) {
		dispatch(deleteAnalysis(id));
	}

	return handler;
}

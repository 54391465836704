import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { InputType } from 'types/index';
import { Title } from './styles';
import { Input } from 'components/UI/Inputs/Input';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useResetPassword } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface Props {
	handleBack: () => void;
}

export function ForgotPasswordForm({ handleBack }: Props) {
	const { translate } = useTranslation();
	const [usernameError, setUsernameError] = useState('');

	const [
		{ loading: resetingPassword, error: errorResetingPassword },
		{ handler: resetPassword, resetError }
	] = useResetPassword();

	const validationSchema = yup.object({
		username: yup
			.string()
			.trim()
			.required(translate(dict => dict.validation.username.required))
			.test(
				'no-spaces',
				translate(dict => dict.validation.username.noSpaces),
				value => !value?.match(/\s/)
			)
	});

	const { Form, handleSubmit, handleTrimOnBlur, register, errors, isDirty } = useReactForm({
		initialValues: { username: '' },
		validationSchema
	});

	const handleFormSubmit = handleSubmit(({ username }) => {
		if (isDirty && !resetingPassword && !errorResetingPassword) resetPassword(username);
	});

	const handleClearError = useCallback(() => {
		if (errorResetingPassword) resetError();
		setUsernameError('');
	}, [errorResetingPassword]);

	const handleResetButton = () => {
		setUsernameError(errors.username?.message ?? '');
	};

	return (
		<Form onSubmit={handleFormSubmit}>
			<Title>{translate(dict => dict.forgotPasswordForm.title)}</Title>

			<Input
				{...register('username', {
					onBlur: handleTrimOnBlur,
					onChange: handleClearError
				})}
				type={InputType.Text}
				label={translate(dict => dict.forgotPasswordForm.username)}
				placeholder={translate(dict => dict.forgotPasswordForm.username)}
				error={usernameError}
				autoFocus
			/>

			<Spacer size={s => s.m} />

			<Button
				type="submit"
				title={translate(dict => dict.forgotPasswordForm.reset)}
				loading={resetingPassword}
				hasPointer={!resetingPassword}
				onClick={handleResetButton}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Button
				variant={v => v.outline}
				title={translate(dict => dict.buttons.goBack)}
				onClick={resetingPassword ? undefined : handleBack}
				hasFullWidth
			/>
		</Form>
	);
}

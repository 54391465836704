import {
	DensityPlotResults,
	DensityPlotResultsV2,
	GetDensityPlotResponseV2,
	GetGroupedDensityPlotResultV2,
	GetUngroupedDensityPlotResultV2
} from 'api/data/analyses';

// type guards
function isGroupedDensityData(
	data: GetDensityPlotResponseV2['data']['result']
): data is GetGroupedDensityPlotResultV2[] {
	return 'length' in data;
}

export function parseDensityPlotResponse(res: GetDensityPlotResponseV2): DensityPlotResultsV2 {
	const result: DensityPlotResults = [];
	if (isGroupedDensityData(res.data.result)) {
		const groupedData = res.data.result;

		// NOTE: errored plotted points will be skipped as aggreed with BE
		groupedData
			.filter(data => !data.values.errors)
			.forEach(data => {
				result.push({
					groupingyValue: data.groupIdentifiers[0].value,
					densityPlot: data.values.yValues ?? [],
					histogramBins: data.values.histogram?.bins ?? [],
					histogramColumns: data.values.histogram?.values ?? [],
					values: data.values.xValues ?? []
				});
			});
	}

	if (!isGroupedDensityData(res.data.result)) {
		if (res.data.result.errors?.[0]?.message) {
			throw new Error(res.data.result.errors[0].message);
		}
		const ungroupedData = {
			...res.data.result
		} as GetUngroupedDensityPlotResultV2;

		result.push({
			groupingyValue: 'default',
			densityPlot: ungroupedData.yValues ?? [],
			histogramBins: ungroupedData.histogram?.bins ?? [],
			histogramColumns: ungroupedData.histogram?.values ?? [],
			values: ungroupedData.xValues ?? []
		});
	}

	return { data: result };
}

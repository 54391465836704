import { Draggable, Droppable } from 'react-beautiful-dnd';

import { FormElement, DraggableFormGroup, FormVariable, DraggableFormSet } from 'components/Forms';
import { Colors, Images } from 'environment';
import { useFormDesignerDrawerContext, useTranslation, useVariableByName } from 'hooks/store';
import { Form } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';
import { DragAndDropTypes } from 'types/index';

import { DropzoneRow } from './DropzoneRow';

import {
	DraggableElement,
	DroppableZoneWrapper,
	DragIcon,
	DropZoneMessage,
	BuilderList
} from './DesignerDropZone.style';
import { isElementCombinable } from 'helpers/forms';
import { withMemo } from 'helpers/HOCs';
import { FormDesignerDrawer } from '../Drawer/FormDesignerDrawer';

interface Props {
	variablesData: VariablesData;
	accessWrite: boolean;
	form?: Form;
	tooltipContainer?: HTMLDivElement;
}

function Component({ variablesData, accessWrite, form, tooltipContainer }: Props) {
	const { translate } = useTranslation();

	// DRAWER STATE
	const { elementId, onClose } = useFormDesignerDrawerContext();
	const selectedVariable = useVariableByName(form?.elements[elementId]?.variableRef ?? null);

	const { variablesMap } = variablesData;
	if (!form) return null;

	const { elements, elementsOrder, groups, sets } = form;

	const emptyForm = elementsOrder.length === 0;

	return (
		<>
			<Droppable droppableId={DragAndDropTypes.FormDroppableZone} isCombineEnabled>
				{(provided, snapshot) => (
					<BuilderList
						ref={provided.innerRef}
						style={
							snapshot.isDraggingOver && !emptyForm
								? { background: Colors.olive }
								: undefined
						}
					>
						{/* NO ELEMENTS */}
						{emptyForm && (
							<DroppableZoneWrapper
								highlighted={snapshot.isDraggingOver}
								data-test-id="promDropZone"
							>
								<DragIcon src={Images.dragIcon} />
								<DropZoneMessage>
									{translate(({ formDesigner }) => formDesigner.dropZoneMessage)}
								</DropZoneMessage>
							</DroppableZoneWrapper>
						)}

						{elementsOrder.map((row, rowIndex) => {
							const descendingIndex = elementsOrder.length - rowIndex;

							// TWO COLUMNS - [FORM ELEMENT, FORM ELEMENT]
							if (Array.isArray(row)) {
								const leftElementId = row[0];
								const rightElementId = row[1];

								const rowElements = [
									elements[leftElementId],
									elements[rightElementId]
								];

								return (
									<Draggable
										key={`draggableRow-${rowIndex}`}
										draggableId={`draggableRow-${rowIndex}`}
										index={rowIndex}
										isDragDisabled={!accessWrite}
									>
										{provided => (
											<DraggableElement
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<DropzoneRow
													variablesData={variablesData}
													rowIndex={rowIndex}
													elements={rowElements}
													accessWrite={accessWrite}
													descendingIndex={descendingIndex}
												/>
											</DraggableElement>
										)}
									</Draggable>
								);
							}
							// ONE COLUMN - (FORM ELEMENT / FORM GROUP)
							else {
								const elementId = row;

								const formSet = sets[elementId];

								// FORM SET
								if (formSet) {
									const { setId, setName } = formSet;

									return (
										<Draggable
											key={`set_${setName}`}
											draggableId={setId}
											index={rowIndex}
											isDragDisabled={!accessWrite}
										>
											{provided => (
												<DraggableElement
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													itemType={'set'}
												>
													<DraggableFormSet
														formSet={formSet}
														accessWrite={accessWrite}
													/>
												</DraggableElement>
											)}
										</Draggable>
									);
								}

								const group = groups[elementId];

								// FORM GROUP
								if (group) {
									const { groupId, groupName } = group;

									return (
										<Draggable
											key={`group_${groupName}`}
											draggableId={groupId}
											index={rowIndex}
											isDragDisabled={!accessWrite}
										>
											{provided => (
												<DraggableElement
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													itemType={'group'}
												>
													<DraggableFormGroup
														variablesData={variablesData}
														formGroup={group}
														elements={elements}
														accessWrite={accessWrite}
													/>
												</DraggableElement>
											)}
										</Draggable>
									);
								}

								// FORM ELEMENT
								const element = elements[elementId];

								const { variableRef, elementType } = element;

								return (
									<Draggable
										key={elementId}
										draggableId={elementId}
										index={rowIndex}
										isDragDisabled={!accessWrite}
									>
										{(provided, snapshot) => {
											const isCombinable = isElementCombinable({
												provided,
												snapshot
											});

											return (
												<DraggableElement
													data-test-id={elementType}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													itemType={'item'}
													isCombinable={isCombinable}
												>
													{variableRef ? (
														<FormVariable
															tooltipContainer={tooltipContainer}
															element={element}
															variable={variablesMap[variableRef]}
															disabled={!accessWrite}
															usedInFormDesigner
														/>
													) : (
														<FormElement
															element={element}
															disabled={!accessWrite}
															usedInFormDesigner
														/>
													)}
												</DraggableElement>
											);
										}}
									</Draggable>
								);
							}
						})}
						{provided.placeholder}
					</BuilderList>
				)}
			</Droppable>

			{selectedVariable && (
				<FormDesignerDrawer
					onClose={onClose}
					variable={selectedVariable}
					elementId={elementId}
				/>
			)}
		</>
	);
}

export const DesignerDropZone = withMemo(Component);

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useDeleteOrganization } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { Organization } from 'store/data/collaborators';

interface Props {
	organization: Organization;
	onClose: (success?: boolean) => void;
}

export function DeleteOrganizationModal({ organization, onClose }: Props) {
	const { translate } = useTranslation();

	const [
		{ loading: deletingOrganization, error: errorDeletingOrganization },
		deleteOrganization
	] = useDeleteOrganization();

	useCompletedAction(
		deletingOrganization,
		errorDeletingOrganization,
		() => onClose(true),
		() => {
			onClose(false);
		}
	);

	function handleDelete() {
		deleteOrganization({ organizationId: organization.id });
	}

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.collaborators.deleteOrganizationModal.delete)}
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: deletingOrganization,
				onClick: handleDelete
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph multiline>
				{translate(dict => dict.collaborators.deleteOrganizationModal.messageStart)}
				<b>{organization.name}</b>
				{translate(dict => dict.collaborators.deleteOrganizationModal.messageEnd)}

				{`\n\n`}

				<b>
					{translate(dict => dict.collaborators.deleteOrganizationModal.warningMessage)}
				</b>
			</Typography.Paragraph>
		</Modal>
	);
}

import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { InputType } from 'types/index';
import { Container } from './JADBioLoginModal.style';
import { Modal } from 'components/UI/Modal';
import { Flex } from 'components/UI/Flex';
import { Button } from 'components/UI/Interactables/Button';
import { Typography } from 'components/UI/Typography';
import { Input } from 'components/UI/Inputs/Input';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useLogInToJADBio } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { usePrevious } from 'hooks/utils';

interface JADBioLoginModalProps {
	onClose: () => void;
}

enum JADBioStep {
	SignIn = 'SignIn',
	SignUp = 'SignUp'
	// forgot password?
}

export function JADBioLoginModal({ onClose }: JADBioLoginModalProps) {
	const { translate } = useTranslation();

	const [{ data, loading }, JADBioLogIn] = useLogInToJADBio();

	const [jadBIoStep, setJadBioStep] = useState(JADBioStep.SignIn);

	const validationSchema = yup.object({
		jadBioUsername: yup.string().trim().required(),
		jadBioPassword: yup.string().required()
	});

	const { Form, handleSubmit, handleTrimOnBlur, register, isDirty } = useReactForm({
		initialValues: { jadBioUsername: '', jadBioPassword: '' },
		validationSchema
	});

	const previousLogInLoading = usePrevious(loading);
	useEffect(() => {
		if (!loading && previousLogInLoading && data) {
			onModalClose();
		}
	}, [loading, data]);

	const handleFormSubmit = handleSubmit(({ jadBioUsername, jadBioPassword }) => {
		if (isDirty && !loading) JADBioLogIn(jadBioUsername, jadBioPassword);
	});

	function onModalClose() {
		setJadBioStep(JADBioStep.SignIn);
		onClose();
	}

	return (
		<>
			<Modal onClose={onModalClose} visible close>
				{jadBIoStep === JADBioStep.SignUp && (
					<>
						<iframe
							title="JADBIO SignUp"
							height="100%"
							width="100%"
							marginWidth={0}
							marginHeight={0}
							frameBorder={0}
							src="https://app.jadbio.com/auth/register_iframe"
						/>
						<Flex
							justify={j => j.center}
							fullWidth
							marginOffset={{ top: 1 }}
							paddingOffset={{ right: 1.4 }}
						>
							<Button
								hasFullWidth
								maxWidth={42}
								variant={v => v.outline}
								title={translate(dict => dict.buttons.goBack)}
								onClick={() => setJadBioStep(JADBioStep.SignIn)}
							/>
						</Flex>
					</>
				)}
				{jadBIoStep === JADBioStep.SignIn && (
					<Container>
						<div>
							<Typography.H3 alignCenter marginOffset={{ bottom: 3 }}>
								{translate(dict => dict.JADBioLogin.title)}
							</Typography.H3>
							<Form onSubmit={handleFormSubmit}>
								<Input
									{...register('jadBioUsername', {
										onBlur: handleTrimOnBlur
									})}
									type={InputType.Text}
									label={translate(dict => dict.JADBioLogin.email)}
									placeholder={translate(
										dict => dict.JADBioLogin.emailPlaceholder
									)}
									autoFocus
								/>

								<Spacer size={s => s.m} />

								<Input
									{...register('jadBioPassword')}
									type={InputType.Password}
									label={translate(dict => dict.JADBioLogin.password)}
									placeholder={translate(
										dict => dict.JADBioLogin.passwordPlaceholder
									)}
								/>

								<Spacer size={s => s.m} />

								<Button
									type="submit"
									title={translate(dict => dict.JADBioLogin.login)}
									loading={loading}
									onClick={() => handleFormSubmit}
									hasFullWidth
								/>

								<Spacer size={s => s.m} />

								{/* <Flex align={j => j.center} column>
									<Button
										variant={v => v.link}
										title="Forgot password?"
										onClick={
											loading
												? undefined
												: () => {
														//forogtpass
												  }
										}
									/>
									<Spacer size={s => s.m} />
									<Button
										variant={v => v.link}
										title={translate(dict => dict.buttons.signup)}
										onClick={
											loading
												? undefined
												: () => {
														setJadBioStep(JADBioStep.SignUp);
												  }
										}
									/>
								</Flex> */}
							</Form>
						</div>
					</Container>
				)}
			</Modal>
		</>
	);
}

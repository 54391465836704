import { createSelector } from 'reselect';
import { selectParams } from 'store/utils';

// import { selectParams } from 'store/utils';

import { State, TemplateRolesData, RolesData } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getProjectId({ projectId }: State) {
	return projectId;
}

function getProjectRoles({ projectRoles }: State) {
	return projectRoles;
}

function getTemplateRoles({ templateRoles }: State) {
	return templateRoles;
}

function getRolesByProjectId({ projectId, projectRoles }: State) {
	const { byProjectId } = projectRoles;

	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectRolesData = createSelector(
	[getProjectId, getProjectRoles],
	(projectId, projectRoles) => {
		const { byId, byProjectId } = projectRoles;

		const rolesData: RolesData = {
			roles: [],
			rolesMap: {}
		};

		if (projectId && projectId in byProjectId) {
			const { ids } = byProjectId[projectId];

			ids.forEach(roleId => {
				const role = byId[roleId];

				rolesData.roles.push(role);
				rolesData.rolesMap[role.id] = role;
			});
		}

		return rolesData;
	}
);

type SelectShareListDataParams = {
	templateRoleId: string;
};

export const selectShareList = createSelector(
	[
		getTemplateRoles,
		(_: State, params: SelectShareListDataParams) => selectParams.encode(params)
	],
	(templateRoles, params) => {
		const { templateRoleId } = selectParams.decode<SelectShareListDataParams>(params);

		const { byId } = templateRoles;

		const templateRole = byId[templateRoleId];

		return templateRole.shareList;
	}
);

export const selectTemplateRolesData = createSelector([getTemplateRoles], templateRoles => {
	const { byId, ids } = templateRoles;

	const rolesData: TemplateRolesData = {
		roles: {
			public: [],
			owned: [],
			shared: []
		},
		rolesMap: {
			public: {},
			owned: {},
			shared: {}
		}
	};

	ids.owned.forEach(templateRoleId => {
		const role = byId[templateRoleId];

		rolesData.roles.owned.push(role);
		rolesData.rolesMap.owned[role.id] = role;
	});

	ids.shared.forEach(templateRoleId => {
		const role = byId[templateRoleId];

		rolesData.roles.shared.push(role);
		rolesData.rolesMap.shared[role.id] = role;
	});

	ids.public.forEach(templateRoleId => {
		const role = byId[templateRoleId];

		rolesData.roles.public.push(role);
		rolesData.rolesMap.public[role.id] = role;
	});

	return rolesData;
});

export const selectAreRolesFetched = createSelector(
	[getRolesByProjectId],
	rolesByProjectId => rolesByProjectId?.fetched ?? false
);

type SelectShareListFetchedParams = {
	templateRoleId: string;
};

export const isShareListDataFetched = createSelector(
	[
		getTemplateRoles,
		(_: State, params: SelectShareListFetchedParams) => selectParams.encode(params)
	],
	(templateRoles, params) => {
		const { templateRoleId } = selectParams.decode<SelectShareListFetchedParams>(params);

		const { byId } = templateRoles;

		const templateRole = byId[templateRoleId];

		return !!templateRole.shareList?.fetched;
	}
);

export const selectTemplateRolesPageActiveTab = createSelector(
	[getTemplateRoles],
	templateRoles => templateRoles.metadata.activeTab
);

import { useEffect, useMemo, useState } from 'react';
import { VariableSet } from 'api/data/variables';
import { VariableSetData, MergeVariablesIntoGroup } from 'store/data/variables';
import { VariableSetDrawerItemsDropzone } from './VariableSetDrawerItemsDropzone';
import { LineMarkerHorizontal } from '../../Cards/VariableCard/VariableCard.style';
import { VariableCardInSet } from './VariableCardInSet';
import { VariableGroupCardInSet } from './VariableGroupCardInSet';
import { ItemsWrapper } from './VariableSetDrawerItems.style';
import { translateVariableTypeMap } from 'helpers/generic';
import { isVariableSetData, variablesDataArrayJSXIterator } from 'helpers/variables';
import { useTranslation, useVariables, useFilteredVariablesDataArray } from 'hooks/store';

interface Props {
	variableSet: VariableSet;
	readOnly: boolean;
	preventDrag: boolean;
	mergeVariables: MergeVariablesIntoGroup;
	onVariableClick: (variableName: string, setName: string) => void;
	onGroupClick: (groupName: string) => void;
}

export function VariableSetDrawerItems({
	variableSet,
	readOnly,
	preventDrag,
	mergeVariables,
	onVariableClick,
	onGroupClick
}: Props) {
	const { translate } = useTranslation();

	const { setName } = variableSet;

	const [
		{
			data: { variablesDataArray }
		}
	] = useVariables({ lazy: true });

	const filteredVariablesDataArray = useFilteredVariablesDataArray({ variablesDataArray });

	/**
	 * USE THE VARIABLE SET WHERE FILTERS ARE APPLIED
	 *
	 * EX CASE: VARIABLE SET HAS 3 VARIABLES BUT ONLY 1 IS SHOWN AFTER FILTERS ARE APPLIED
	 */
	const variableSetDataArray = useMemo(() => {
		const filteredVariableSet = filteredVariablesDataArray.find(
			item => isVariableSetData(item) && item.setName === setName
		) as VariableSetData | undefined;

		if (filteredVariableSet) return filteredVariableSet.setData;

		return [];
	}, [filteredVariablesDataArray, setName]);

	const [renderDropzone, setRenderDropzone] = useState(true);
	const [isOverCurrent, setIsOverCurrent] = useState(false);
	const [showEmptySetHover, setShowEmptySetHover] = useState(false);

	useEffect(() => {
		if (!isOverCurrent && showEmptySetHover) setShowEmptySetHover(false);
	}, [isOverCurrent]);

	useEffect(() => setRenderDropzone(false), [setName]);
	useEffect(() => setRenderDropzone(true), [renderDropzone]);

	const translatedVariableTypeMap = translateVariableTypeMap(translate);

	return (
		<>
			<ItemsWrapper>
				{variablesDataArrayJSXIterator(
					variableSetDataArray,
					// VARIABLE
					(variable, index) => {
						const translatedVariableType = translatedVariableTypeMap[variable.type];
						const subtitle = `${translatedVariableType} ${
							variable.uniquenessType ?? ''
						}`.trim();

						return (
							<VariableCardInSet
								key={`variable_set_${setName}-variable_${variable.name}`}
								index={index}
								name={variable.name}
								title={variable.label}
								subtitle={subtitle}
								required={variable.obligatory}
								parentSet={setName}
								readOnly={readOnly}
								preventDrag={preventDrag}
								onClick={() => onVariableClick(variable.name, setName)}
								mergeVariables={mergeVariables}
							/>
						);
					},
					// GROUP
					(groupData, index) => {
						const { groupVariables } = groupData;

						const numberOfVariables = groupVariables.length;
						const hasOneVariable = numberOfVariables === 1;

						const subtitle = `${numberOfVariables} ${
							hasOneVariable
								? translate(dict => dict.terms.variableLowerCase)
								: translate(dict => dict.terms.variables)
						}`;

						return (
							<VariableGroupCardInSet
								key={`variable_set_${setName}-group_${groupData.groupName}`}
								index={index}
								name={groupData.groupName}
								title={groupData.groupLabel}
								preventDrag={preventDrag}
								parentSet={setName}
								subtitle={subtitle}
								onClick={() => onGroupClick(groupData.groupName)}
							/>
						);
					},
					// VARIABLE SET - OMIT
					() => null
				)}

				{/* NO ITEMS & CAN DROP */}
				{showEmptySetHover && (
					<LineMarkerHorizontal style={{ opacity: 1, marginTop: '-1rem' }} />
				)}
			</ItemsWrapper>

			{renderDropzone && (
				<VariableSetDrawerItemsDropzone
					variableSet={variableSet}
					setShowEmptySetHover={setShowEmptySetHover}
					setIsOverCurrent={setIsOverCurrent}
				/>
			)}
		</>
	);
}

import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';

export interface State {
	projectId: string | null;
	byProjectId: GenericMap<{
		byName: StatusesMap;
		names: string[];
		fetched: boolean;
	}>;
}

export interface Status {
	name: string;
	label: string;
	description: string;
	statusColor: StatusColor;
	dueTimeUnit: DueTimeUnit | null;
	dueTimeAmount: string | null;
	isDefault?: boolean;
}

export type StatusesMap = GenericMap<Status>;

export enum StatusColor {
	Color1 = '#7ebfa4',
	Color2 = '#9b92be',
	Color3 = '#7daaca',
	Color4 = '#80d2ee',
	Color5 = '#ffe892',
	Color6 = '#ffb48b',
	Color7 = '#ffa298',
	Color8 = '#f68496',
	Color9 = '#d1799e',
	Color10 = '#90e4af',
	Color11 = 'rgba(112, 116 ,120 , 0.6)',
	Color12 = '#dae0e6'
}

export enum StatusColor_OLD {
	Color1 = '#F34436',
	Color2 = '#E81F63',
	Color3 = '#9D26B0',
	Color4 = '#673AB7',
	Color5 = '#3050B5',
	Color6 = '#0083FF',
	Color7 = '#00A8F4',
	Color8 = '#00BBD4',
	Color9 = '#009688',
	Color10 = '#4CAF50',
	Color11 = '#8BC34A',
	Color12 = '#CDDD39',
	Color13 = '#ffb900',
	Color14 = '#FFC107',
	Color15 = '#FF9800',
	Color16 = '#FE5622',
	Neutral = '#323e49'
}

export enum DueTimeUnit {
	Hours = 'hours',
	Days = 'days',
	Weeks = 'weeks',
	Months = 'months'
}

export const STATUS_COLORS = [
	StatusColor.Color1,
	StatusColor.Color2,
	StatusColor.Color3,
	StatusColor.Color4,
	StatusColor.Color5,
	StatusColor.Color6,
	StatusColor.Color7,
	StatusColor.Color8,
	StatusColor.Color9,
	StatusColor.Color10,
	StatusColor.Color11,
	StatusColor.Color12
];

/**
 * ======================
 * 		ACTION TYPES
 * ======================
 */

export enum ActionTypes {
	GET_STATUS = 'data/statuses/GET_STATUS',
	GET_STATUSES = 'data/statuses/GET_STATUSES',
	CREATE_STATUS = 'data/statuses/CREATE_STATUS',
	UPDATE_STATUS = 'data/statuses/UPDATE_STATUS',
	DELETE_STATUS = 'data/statuses/DELETE_STATUS',
	CREATE_STATUSES = 'data/statuses/CREATE_STATUSES'
}

/**
 * ======================
 *        ACTIONS
 * ======================
 */

export type GetStatusAction = Action<ActionTypes.GET_STATUS, { status: Status }>;

export type GetStatusesAction = Action<
	ActionTypes.GET_STATUSES,
	{
		projectId: string;
		statuses: Status[];
	}
>;

export type CreateStatusAction = Action<ActionTypes.CREATE_STATUS, { status: Status }>;

export type UpdateStatusAction = Action<ActionTypes.UPDATE_STATUS, { status: Status }>;

export type DeleteStatusAction = Action<ActionTypes.DELETE_STATUS, { statusName: string }>;

export type CreateStatusesAction = Action<ActionTypes.CREATE_STATUSES, { statuses: Status[] }>;

export type Actions =
	| GetStatusAction
	| GetStatusesAction
	| CreateStatusAction
	| UpdateStatusAction
	| DeleteStatusAction
	| CreateStatusesAction;

import { useEffect, useRef, useState } from 'react';
import { stringAsBoolean } from 'helpers/generic';
import { InputType, SetState, TemplateShareLevel } from 'types/index';
import { Svgs, Colors } from 'environment';
import { MenuItem } from 'components/UI/Interactables/AdvancedMenu/AdvancedMenu';
import {
	Container,
	TemplateHeaderLeft,
	TemplateHeaderRight,
	Input,
	ImportIcon,
	AdvancedMenu
} from './TemplateCardHeader.style';
import { Typography } from 'components/UI/Typography';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import {
	useTranslation,
	usePublishedWithUnsupported,
	usePublishTemplate,
	useRenameTemplate,
	useUpdateTemplate,
	useTemplateSharedWith,
	useTemplateId
} from 'hooks/store';
import { useDebounce, useOutsideClick } from 'hooks/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DeleteTemplateModal } from 'components/Templates/DeleteTemplateModal/DeleteTemplateModal';

const IS_PUBLIC_SHARING_ENABLED = stringAsBoolean(
	process.env.REACT_APP_USE_TEMPLATES_PUBLIC_SHARING!
);

interface Props {
	index: number;
	variablesLength: number;
	templateName: string;
	templateId: string;
	accessPublicRead?: boolean;
	expanded: boolean;
	accessWrite: boolean;
	withUserEdit?: boolean;
	setShareLevel: SetState<TemplateShareLevel | null>;
	setTemplateIdForImport: SetState<string | null>;
	setExpanded: SetState<boolean>;
}

export function TemplateCardHeader({
	index,
	variablesLength,
	templateName,
	templateId,
	accessPublicRead,
	expanded,
	accessWrite,
	withUserEdit,
	setShareLevel,
	setTemplateIdForImport,
	setExpanded
}: Props) {
	const { translate } = useTranslation();

	const { timeDurationFlag } = useFlags();

	const [menuVisible, setMenuVisible] = useState(false);
	const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);

	const [debouncedTemplateName, setDebouncedTemplateName] = useState(templateName ?? '');
	const [editTemplateName, setEditTemplateName] = useState(index === 0 && !templateName);
	const [touched, setTouched] = useState(false);
	const [, resetUnsupported] = usePublishedWithUnsupported();

	const publishTemplate = usePublishTemplate();
	const renameTemplate = useRenameTemplate();
	const [_, updateTemplate] = useUpdateTemplate();

	const [, getSharedWith] = useTemplateSharedWith();
	const [, setTemplateId] = useTemplateId();

	const templateNameRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (templateNameRef && templateNameRef.current) {
			if (editTemplateName) {
				templateNameRef.current.focus();
			} else {
				templateNameRef.current.blur();
			}
		}
	}, [editTemplateName]);

	useDebounce(() => {
		templateName !== debouncedTemplateName &&
			renameTemplate({ templateId, newName: debouncedTemplateName });
	}, [debouncedTemplateName]);

	function onTemplateNameUpdate(newName: string) {
		if (!touched) setTouched(true);
		setDebouncedTemplateName(newName);
	}

	function handleTemplateNameInputOnBlur() {
		// TRIM NAME
		setDebouncedTemplateName(state => state.trim());
		!touched && setTouched(true);
		// UPDATE TEMPLATE NAME ACTION
		setEditTemplateName(false);
	}

	useOutsideClick(() => handleTemplateNameInputOnBlur(), [templateNameRef]);

	const incompleteTemplate = variablesLength === 0 || debouncedTemplateName === '';

	function getAdvancedMenuItems() {
		const menuItems: MenuItem[] = [];
		const completeTemplateActions = [
			{
				label: isUpdateTemplate
					? translate(({ buttons }) => buttons.update)
					: translate(({ templates }) => templates.save),
				disabled: incompleteTemplate,
				handler: () => {
					resetUnsupported();
					setShareLevel(null);
					if (isUpdateTemplate) {
						updateTemplate({ templateId, allowTimeDuration: timeDurationFlag });
					} else {
						setTemplateId(templateId);
						publishTemplate(templateId);
					}
				}
			},
			{
				label: translate(dict => dict.templates.share),
				disabled: incompleteTemplate,
				left: true,
				submenu: [
					...(IS_PUBLIC_SHARING_ENABLED
						? [
								{
									label: translate(({ templates }) =>
										accessPublicRead
											? templates.notGlobally
											: templates.globally
									),
									disabled: canShareTemplate,
									handler: () => {
										setShareLevel(null);
										updateTemplate({
											templateId,
											shareTemplateGlobally: !accessPublicRead,
											allowTimeDuration: timeDurationFlag
										});
									}
								}
						  ]
						: []),
					{
						label: translate(({ templates }) => templates.projects),
						disabled: canShareTemplate,
						handler: () => {
							setShareLevel(TemplateShareLevel.ShareWithProjects);
							setTemplateId(templateId);
							getSharedWith();
						}
					},
					{
						label: translate(({ templates }) => templates.specificUsers),
						disabled: canShareTemplate,
						handler: () => {
							setShareLevel(TemplateShareLevel.ShareWithUsers);
							setTemplateId(templateId);
							getSharedWith();
						}
					}
				]
			},
			{
				label: translate(({ buttons }) => buttons.import),
				disabled: canShareTemplate,
				handler: () => {
					resetUnsupported();
					setTemplateIdForImport(templateId);
				}
			},
			{
				label: translate(dict => dict.buttons.delete),
				handler: () => setShowDeleteTemplateModal(true)
			}
		];
		menuItems.push(...completeTemplateActions.flat());

		return completeTemplateActions;
	}

	const isUpdateTemplate = templateId && !templateId.toString().includes('temporaryId-');

	const canShareTemplate = incompleteTemplate || !isUpdateTemplate;

	return (
		<Container>
			<TemplateHeaderLeft>
				<Icon
					svg={Svgs.ChevronDown}
					onClick={() => setExpanded(!expanded)}
					rotate={expanded ? 180 : 0}
					marginOffset={{ left: 0.4 }}
				/>

				<Icon
					svg={Svgs.FileText}
					size={s => s.m}
					paddingOffset={{ y: 0.4 }}
					marginOffset={{ x: 0.8 }}
					propagate
				/>

				<Flex
					marginOffset={{ right: 0.8 }}
					paddingOffset={{ y: 0.15 }}
					style={{ width: '100%' }}
				>
					{accessWrite && withUserEdit ? (
						<Input
							ref={templateNameRef}
							type={InputType.Text}
							value={debouncedTemplateName}
							error={
								touched && !debouncedTemplateName
									? translate(({ templates }) => templates.templateNameError)
									: ''
							}
							onFocus={() => !editTemplateName && setEditTemplateName(true)}
							onChange={e => onTemplateNameUpdate(e.target.value)}
						/>
					) : (
						<Typography.Paragraph title={debouncedTemplateName} ellipsis>
							{debouncedTemplateName}
						</Typography.Paragraph>
					)}
				</Flex>
			</TemplateHeaderLeft>
			<TemplateHeaderRight writeAccess={accessWrite}>
				<Typography.Caption marginOffset={{ right: 0.4 }}>
					{variablesLength}&nbsp;
					{variablesLength === 1
						? translate(dict => dict.varGroup.variable)
						: translate(dict => dict.varGroup.variables)}
				</Typography.Caption>

				{accessPublicRead && withUserEdit && (
					<Icon
						svg={Svgs.Globe}
						size={s => s.m}
						marginOffset={{ left: 1 }}
						title={translate(({ templates }) => templates.sharedGloballyTooltipMessage)}
						propagate
					/>
				)}
				{accessWrite && withUserEdit && (
					<AdvancedMenu
						className="card-more-icon"
						offset={{ top: 0, right: 0 }}
						items={getAdvancedMenuItems()}
						onOpen={() => setMenuVisible(true)}
						onClose={() => setMenuVisible(false)}
						visible={menuVisible}
					/>
				)}
				{accessWrite && !withUserEdit && (
					<ImportIcon
						className="import-template-icon"
						title={translate(({ templates }) => templates.importTemplateTitle)}
						svg={Svgs.Add}
						colors={{ color: Colors.primary.normal }}
						onClick={() => {
							resetUnsupported();
							setTemplateIdForImport(templateId);
						}}
						propagate
					/>
				)}
			</TemplateHeaderRight>
			{showDeleteTemplateModal && (
				<DeleteTemplateModal
					templateId={templateId}
					onClose={() => setShowDeleteTemplateModal(false)}
				/>
			)}
		</Container>
	);
}

import { ActionTypes, updateDistributions } from 'store/data/patients';

import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useUpdateDistributions(): Operation {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionTypes.UPDATE_DISTRIBUTIONS);

	function handler() {
		dispatch(updateDistributions());
	}

	return [{ loading, error }, handler];
}

import { useRef, useState, useEffect } from 'react';
import { Offset } from 'types/index';
import { PopoverContent } from './PopoverContent';
import { Container } from './Popover.style';
import { useMeasure, useOutsideClick } from 'hooks/utils';

interface Props {
	children: React.ReactNode;
	open?: boolean;
	disabled?: boolean;
	width?: number;
	offset?: Offset;
	id?: string;
	toggleComponent: (props: {
		ref: React.RefObject<HTMLButtonElement>;
		open: boolean;
		toggle: () => void;
	}) => React.ReactNode;
	onToggle?: (open: boolean) => void;
}

export function Popover({
	children,
	open = false,
	disabled,
	width,
	offset,
	id,
	toggleComponent,
	onToggle
}: Props) {
	const [isOpen, setIsOpen] = useState<boolean>(open);
	const [ref, bounds] = useMeasure<HTMLButtonElement>();
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (onToggle) {
			onToggle(isOpen);
		}
	}, [isOpen]);

	function toggle() {
		setIsOpen(open => !open);
	}

	function close() {
		setIsOpen(false);
	}

	useOutsideClick(() => {
		if (!disabled) close();
	}, [ref, contentRef]);

	return (
		<Container id={id}>
			{toggleComponent({ ref, open: isOpen, toggle })}
			{open && (
				<PopoverContent ref={contentRef} bounds={bounds} width={width} offset={offset}>
					{children}
				</PopoverContent>
			)}
		</Container>
	);
}

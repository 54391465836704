import { useState } from 'react';
import { VariableFields } from 'components/Variables';
import { Svgs, Colors } from 'environment';
import {
	DraggableCard,
	CardHeader,
	CardBody,
	Wrapper,
	Container,
	VanishingActionIcon
} from 'components/Templates';
import { DraggableVariable } from 'store/data/templates';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { Asterisk } from 'components/UI/Asterisk';
import { useRemoveFromTemplate } from 'hooks/store';

interface Props {
	variable: DraggableVariable;
	templateId?: string;
	partOfGroup?: boolean;
	accessWrite?: boolean;
	publishedTemplate?: boolean;
}

export function TemplateVariable({
	variable,
	templateId = undefined,
	partOfGroup = false,
	accessWrite,
	publishedTemplate = false
}: Props) {
	const [variableExpanded, setVariableExpanded] = useState(false);

	const removeFromTemplate = useRemoveFromTemplate();

	const { label, draggableId, obligatory } = variable;

	return (
		<DraggableCard groupedVariable={partOfGroup}>
			<Wrapper>
				<CardHeader>
					<Container insideTemplate={publishedTemplate}>
						<Icon
							svg={Svgs.ChevronDown}
							marginOffset={{ right: 0.8 }}
							rotate={variableExpanded ? 180 : 0}
							onClick={() => setVariableExpanded(state => !state)}
						/>
						<Typography.Paragraph title={label} ellipsis>
							{label}
						</Typography.Paragraph>
						{obligatory && <Asterisk paddingLeft />}
					</Container>
					{publishedTemplate && templateId && !partOfGroup && (
						<VanishingActionIcon
							svg={Svgs.Delete}
							disabled={!accessWrite}
							colors={{
								color: Colors.text.disabled,
								hover: Colors.primary.normal
							}}
							onClick={() =>
								removeFromTemplate({
									elementId: draggableId,
									templateId
								})
							}
						/>
					)}
				</CardHeader>

				{variableExpanded && (
					<CardBody>
						<VariableFields variable={variable} />
					</CardBody>
				)}
			</Wrapper>
		</DraggableCard>
	);
}

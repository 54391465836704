import { LocalOperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { SetCopyProjectAction, setCopyProjectId } from 'store/data/projects';
import { ActionPayload } from 'store/types';

export function useCopiedProjectId(): LocalOperationResult<
	string | null,
	(copiedProjectId: ActionPayload<SetCopyProjectAction>['copiedProjectId']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.projects.copiedProjectId);

	function handler(copiedProjectId: ActionPayload<SetCopyProjectAction>['copiedProjectId']) {
		dispatch(setCopyProjectId({ copiedProjectId }));
	}

	return [data, handler];
}

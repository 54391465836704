import { useEffect, useMemo, useRef, useState } from 'react';

import { Collaborator } from 'store/data/collaborators';
import { UserCard } from 'components/UI/UserCard';
import { CenteredCheckbox } from '../styles';
import { Flex } from 'components/UI/Flex';
import { Table } from 'components/UI/Table';
import {
	useOrganizationsByCollaborator,
	useProjectOwnerDetails,
	useTranslation,
	useUsername
} from 'hooks/store';
import { Tag, ThreeDotsMenu, Dropdown, TableRow } from './style';
import { Colors, Svgs } from 'environment';
import { getElementBounds, mergeRefs } from 'helpers/generic';
import { useOutsideClick } from 'hooks/utils';
import { useWindowSize } from 'hooks/ui';
import { UserStatus } from 'store/account/subscription';

// icon margin right
const DROPDOWN_RIGHT_OFFSET = 8;
// icon height
const DROPDOWN_TOP_OFFSET = 36;

interface Props {
	organizationId?: string;
	tableWidth?: number;
	collaborator: Collaborator;
	checkedData: {
		checked: boolean;
		onCheck: () => void;
	};
	canSelectRow: boolean;
	isSelf: boolean;
	isOwner: boolean;
	isNested?: boolean;
	onClick: () => void;
	onRemove: () => void;

	showSubOrganization: boolean;
}

export function CollaboratorRow({
	tableWidth = 0,
	collaborator,
	canSelectRow,
	isSelf,
	isOwner,
	isNested,
	checkedData: { checked, onCheck },
	onClick,
	onRemove,
	showSubOrganization
}: Props) {
	const { translate } = useTranslation();
	const rowRef = useRef<HTMLTableRowElement | null>(null);

	const currentUserId = useUsername();
	const projectOwnerId = useProjectOwnerDetails()?.userId;
	const { top, right } = getElementBounds(rowRef);
	const organizations = useOrganizationsByCollaborator(collaborator.userId);
	const { width: windowWidth } = useWindowSize();

	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	useOutsideClick(() => setDropdownOpen(false), [dropdownRef]);

	useEffect(() => {
		setDropdownOpen(false);
	}, [windowWidth]);

	const groupTags = useMemo(() => {
		return (
			<Flex>
				{organizations?.map(org => {
					return (
						<Tag
							key={org.id}
							title={org.name}
							disabled={collaborator.status === UserStatus.Inactive}
						/>
					);
				})}
			</Flex>
		);
	}, [organizations, collaborator]);

	const SELECT_OPTIONS = useMemo(() => {
		const currentUserIsProjectOwner = currentUserId === projectOwnerId;

		const removeSelectItem = {
			label: translate(dict => dict.collaborators.collaboratorsTable.removeCollaborator),
			action: onRemove
		};

		return [
			{
				label: translate(dict => dict.collaborators.collaboratorsTable.editCollaborator),
				action: onClick
			},
			...(currentUserIsProjectOwner ? [removeSelectItem] : [])
		];
	}, [isOwner, isNested, organizations]);

	return (
		<TableRow
			data-testid="collaborator-table-row"
			ref={rowRef}
			onClick={onClick}
			active={checked || dropdownOpen}
			clickable
		>
			{canSelectRow && (
				<Table.Cell
					onClick={e => {
						e.stopPropagation();
						onCheck();
					}}
				>
					<CenteredCheckbox checked={checked} onClick={onCheck} />
				</Table.Cell>
			)}
			<Table.Cell
				minWidth={30}
				maxWidth={40}
				title={`${collaborator.userFirstName} ${collaborator.userSirName}`}
			>
				<Flex marginOffset={isNested && { left: 4 }}>
					<UserCard.Table
						userId={collaborator.pending ? null : collaborator.userId}
						userData={{
							userFirstName: collaborator.userFirstName,
							userSirName: collaborator.userSirName,
							emailAddress: collaborator.emailAddress
						}}
						isSelf={isSelf}
						isOwner={isOwner}
						disabled={collaborator.status === UserStatus.Inactive}
					/>
				</Flex>
			</Table.Cell>
			{/** Collaborators placeholder */}
			{isNested && (
				<Table.Cell
					style={{
						color:
							collaborator.status === UserStatus.Inactive
								? Colors.text.disabled
								: Colors.text.main
					}}
				>
					{'-'}
				</Table.Cell>
			)}

			<Table.Cell
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					color:
						collaborator.status === UserStatus.Inactive
							? Colors.text.disabled
							: Colors.text.main
				}}
				minWidth={25.4}
				maxWidth={31}
			>
				{collaborator.emailAddress ?? '-'}
			</Table.Cell>

			<Table.Cell
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					color:
						collaborator.status === UserStatus.Inactive
							? Colors.text.disabled
							: Colors.text.main
				}}
			>
				{collaborator.organization}
			</Table.Cell>

			{showSubOrganization && (
				<Table.Cell
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						color:
							collaborator.status === UserStatus.Inactive
								? Colors.text.disabled
								: Colors.text.main
					}}
				>
					{collaborator.subOrganization || '-'}
				</Table.Cell>
			)}

			<Table.Cell
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					color:
						collaborator.status === UserStatus.Inactive
							? Colors.text.disabled
							: Colors.text.main
				}}
			>
				{collaborator.workplace || '-'}
			</Table.Cell>

			{!isNested && (
				<Table.Cell
					style={{
						color:
							collaborator.status === UserStatus.Inactive
								? Colors.text.disabled
								: Colors.text.main
					}}
				>
					{collaborator.userRole ?? '-'}
				</Table.Cell>
			)}
			{!isNested && <Table.Cell>{groupTags}</Table.Cell>}
			<td>
				{isNested && (
					<Dropdown
						closeOnResize
						shouldScrollIntoView
						menuStyles={{
							top: top + DROPDOWN_TOP_OFFSET,
							right: right - tableWidth + DROPDOWN_RIGHT_OFFSET,
							left: 'unset',
							width: 'auto'
						}}
						usePortal
						// button
						menuClassName="dropdown-menu"
						toggleComponent={({ toggle, ref }) => (
							<ThreeDotsMenu
								ref={mergeRefs([ref, dropdownRef])}
								svg={Svgs.More}
								active={dropdownOpen}
								onClick={() => {
									toggle();
									setDropdownOpen(open => !open);
								}}
								size={s => s.l}
							/>
						)}
					>
						{SELECT_OPTIONS.map(opt => (
							<Dropdown.Item key={opt.label} onClick={opt.action}>
								{opt.label}
							</Dropdown.Item>
						))}
					</Dropdown>
				)}
			</td>
		</TableRow>
	);
}

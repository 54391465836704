import { State } from './types';

const initialState: State = {
	topicName: null,
	notificationHistory: { byNotificationId: null },
	newestNotifications: [],
	queueNotifications: [],
	fullNotificationHistoryFetched: false,
	showOnlyUnread: false,
	settings: { byProjectId: {} },
	projectsLabels: {}
};

export default initialState;

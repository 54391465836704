import { useLogin } from 'hooks/store/auth/useLogin';
import { useEffectOnce } from 'hooks/utils';
import { StorageKeys } from 'types/index';
import { useUserAddons } from './useUserAddons';

export function useShouldRefetchUserAddons() {
	const [{ data: loggedIn }] = useLogin();

	const [_, getUserAddons] = useUserAddons();

	function handleStorageChange({ key, newValue }: StorageEvent) {
		const shouldRefetchUserAddons =
			key === StorageKeys.RefetchUserAddons && newValue === 'true' && loggedIn;

		if (shouldRefetchUserAddons) {
			getUserAddons();
		}
	}

	useEffectOnce(() => {
		window.addEventListener('storage', handleStorageChange);

		return () => window.removeEventListener('storage', handleStorageChange);
	});
}

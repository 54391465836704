// exports

export function getDefaultVariableName(
	variableNames: string[],
	{ second, fallbackToFirst }: { second?: boolean; fallbackToFirst?: boolean } = {
		second: false,
		fallbackToFirst: true
	}
) {
	const firstVariableName = variableNames[0] ?? '';
	const secondVariableName = variableNames[1] ?? '';

	if (second) {
		if (fallbackToFirst) {
			return secondVariableName ?? firstVariableName;
		} else {
			return secondVariableName ?? '';
		}
	}

	return firstVariableName;
}

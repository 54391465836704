import { useEffect, useMemo, useRef, useState } from 'react';
import { InputError } from 'components/UI/Inputs/InputError';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { Colors, Svgs } from 'environment';
import { arrayUtils } from 'helpers/arrays';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import { createGlobalStyle } from 'styled-components';
import { SelectItem, SpacingOffsets } from 'types/index';
import {
	ComponentContainer,
	Container,
	FloatingContainer,
	DeleteIconContainer,
	ItemsContainer,
	OptionsContainer,
	RemainingItems
} from './InputTagWithCheckboxDropdown.style';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Icon } from 'components/UI/Icons';
import { Checkbox } from '../Checkbox';
import { Tooltip } from '../Tooltip';
import { Tag } from 'components/UI/Tags';
import { useMutableState, useOutsideClick } from 'hooks/utils';

type Items = SelectItem[];

interface ParsedItem {
	value: string;
	label: string;
	checked: boolean;
}

interface Props extends SpacingOffsets {
	items: ParsedItem[];
	label?: string;
	hint?: string;
	disabled?: boolean;
	error?: string;
	placeholder?: string;
	required?: boolean;
	sortable?: boolean;
	readOnly?: boolean;
	dataTestId?: string;
	onChange?: (value: string[]) => void;
	onItemClick?: (value: string) => void;
	onDelete?: (value: string) => void;
	onDeleteAll?: () => void;
	onMove?: (input: { value: string; sourceIndex: number; destinationIndex: number }) => void;
	onClick?: () => void;
}

export function InputTagWithCheckboxDropdown({
	items,
	disabled,
	error,
	hint,
	label,
	placeholder,
	required,
	marginOffset,
	paddingOffset,
	sortable,
	readOnly,
	dataTestId = 'input-tag-with-checkbox-dropdown',
	onChange = () => null,
	onItemClick,
	onDelete = () => null,
	onDeleteAll = () => null,
	onMove = () => null,
	onClick
}: Props) {
	const [open, setOpen] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [unrenderedItems, setUnrenderedItems] = useState<ParsedItem[]>([]);

	const containerRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const filteredItems = useMemo(() => filterItemsBySearchTerm(items, searchTerm), [searchTerm]);

	const formatedInitialItems: ParsedItem[] = useMemo(
		() => filteredItems.sort((a, b) => reverseSortByNameLength(a, b)),
		[filteredItems]
	);

	const hasItems = formatedInitialItems.length > 0;

	const [draftFormatedVisibleItems, setDraftFormatedVisibleItems] =
		useMutableState<ParsedItem[]>(formatedInitialItems);

	const limitedList = useMemo(() => {
		const checkedList = draftFormatedVisibleItems.filter(item => item.checked);

		const itemCount = checkedList.length;

		if (!expanded) {
			if (itemCount >= 5) {
				const firstLimitedList = checkedList.slice(0, 4);
				const remainingItems = checkedList.slice(4);

				const longNamedList = firstLimitedList.filter(item => item.label.length >= 18);

				const hasExtremelyLongName = longNamedList.find(item => item.label.length >= 20);

				const hasTwoLongNames = longNamedList.length >= 2;

				const hasOneLongName = longNamedList.length === 1;

				let finalLimitedList: ParsedItem[];
				let finalRemainingItems: ParsedItem[];

				if (hasExtremelyLongName) {
					finalLimitedList = firstLimitedList.slice(0, 1);
					finalRemainingItems = [...firstLimitedList.slice(1), ...remainingItems];
				} else if (hasOneLongName) {
					finalLimitedList = firstLimitedList.slice(0, 3);
					finalRemainingItems = [...firstLimitedList.slice(3), ...remainingItems];
				} else if (hasTwoLongNames) {
					finalLimitedList = firstLimitedList.slice(0, 2);
					finalRemainingItems = [...firstLimitedList.slice(2), ...remainingItems];
				} else {
					finalLimitedList = firstLimitedList;
					finalRemainingItems = remainingItems;
				}

				setUnrenderedItems(finalRemainingItems);

				return finalLimitedList;
			} else {
				const longNamedList = checkedList.filter(item => item.label.length >= 18);

				const hasExtremelyLongName = longNamedList.find(item => item.label.length >= 20);

				const hasTwoLongNames = longNamedList.length >= 2;

				const hasOneLongName = longNamedList.length === 1;

				let finalLimitedList: ParsedItem[];
				let finalRemainingItems: ParsedItem[];

				if (hasOneLongName) {
					finalLimitedList = checkedList.slice(0, 3);
					finalRemainingItems = checkedList.slice(3);

					if (hasExtremelyLongName) {
						finalLimitedList = checkedList.slice(0, 2);
						finalRemainingItems = [...checkedList.slice(2), ...finalRemainingItems];
					}
				} else if (hasTwoLongNames) {
					finalLimitedList = checkedList.slice(0, 2);
					finalRemainingItems = checkedList.slice(2);

					if (hasExtremelyLongName) {
						finalLimitedList = checkedList.slice(0, 1);
						finalRemainingItems.unshift(...checkedList.slice(1, 2));
					}
				} else {
					finalLimitedList = checkedList;
					finalRemainingItems = [];
				}

				setUnrenderedItems(finalRemainingItems);

				return finalLimitedList;
			}
		} else {
			return checkedList;
		}
	}, [draftFormatedVisibleItems, expanded]);

	// SYNC `draftFormatedVisibleItems` STATE
	useEffect(() => {
		const hasChanges = formatedInitialItems.length !== draftFormatedVisibleItems.length;

		if (hasChanges) {
			const diffItems = formatedInitialItems.reduce((acc, item) => {
				const isNotInDraftItems = !draftFormatedVisibleItems.some(
					draftItem => draftItem.value === item.value
				);

				if (isNotInDraftItems) acc.push(item);

				return acc;
			}, [] as ParsedItem[]);

			const newItems = [...draftFormatedVisibleItems, ...diffItems];

			setDraftFormatedVisibleItems(newItems);
		}
	}, [formatedInitialItems]);

	// ON CHANGE Effect
	useEffect(() => {
		const checkedItems = draftFormatedVisibleItems.filter(item => item.checked);

		const values = checkedItems.map(item => item.value);

		if (values.length > 0) onChange(values);
	}, [draftFormatedVisibleItems]);

	function handleToggleOpen() {
		setOpen(!open);
	}

	function handleToggleAll() {
		const { all: allChecked } = getItemsCheckedState();
		const newItems = formatedInitialItems.map(item => ({ ...item, checked: !allChecked }));

		setDraftFormatedVisibleItems(newItems);

		if (allChecked) setSearchTerm('');
	}

	function getItemsCheckedState() {
		const numberOfItems = items.length;
		const numberOfDraftItems = draftFormatedVisibleItems.length;

		const all =
			numberOfDraftItems === numberOfItems &&
			draftFormatedVisibleItems.every(item => item.checked);

		const partial = draftFormatedVisibleItems.some(item => item.checked) && !all;

		return { all, partial };
	}

	function isItemChecked(name: string) {
		const item = draftFormatedVisibleItems.find(item => item.value === name);

		return item ? item.checked : false;
	}

	function handleCheckItem(name: string) {
		setDraftFormatedVisibleItems(state => {
			state.map(item => {
				if (item.value === name) item.checked = !item.checked;
			});
		});
	}

	function deleteItem(index: number) {
		const checkedList = draftFormatedVisibleItems.filter(item => item.checked);

		const realIndex = draftFormatedVisibleItems.findIndex(
			item => item.value === checkedList[index].value
		);
		setDraftFormatedVisibleItems(state => {
			return state.map((item, i) => {
				if (i === realIndex) item.checked = false;
				return item;
			});
		});

		onDelete(draftFormatedVisibleItems[realIndex].value);
	}

	function deleteAll() {
		const uncheckedInitialItems = formatedInitialItems.map(item => ({
			...item,
			checked: false
		}));
		setSearchTerm('');
		setUnrenderedItems([]);
		setExpanded(false);
		setDraftFormatedVisibleItems(uncheckedInitialItems);
		onDeleteAll();
	}

	function handleOnClick() {
		if (onClick) onClick();
		handleToggleOpen();
	}

	const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
		const checkedList = draftFormatedVisibleItems.filter(item => item.checked);

		const realOldIndex = draftFormatedVisibleItems.findIndex(
			item => item.value === checkedList[oldIndex].value
		);

		const newOrder = arrayUtils.move(draftFormatedVisibleItems, realOldIndex, newIndex);

		onMove({
			value: draftFormatedVisibleItems[realOldIndex].value,
			sourceIndex: realOldIndex,
			destinationIndex: newIndex
		});
		setDraftFormatedVisibleItems(newOrder);
		onChange(newOrder.map(item => item.value));
	};

	function hasCheckedItems() {
		return draftFormatedVisibleItems.some(item => item.checked);
	}

	useOutsideClick(() => {
		if (open) setOpen(false);

		setSearchTerm('');
	}, [containerRef, dropdownRef]);

	const isSearchTermValid = searchTerm.trim().length > 0;

	const noSearchResults = isSearchTermValid && filteredItems.length === 0;

	const isInputValid = draftFormatedVisibleItems.filter(item => item.checked).length > 0;

	return (
		<ComponentContainer
			data-testid={dataTestId}
			paddingOffset={paddingOffset}
			marginOffset={marginOffset}
		>
			<div style={{ width: '104%' }}>
				<Flex column fullWidth>
					<InputLabel disabled={disabled} required={required} label={label} />

					<GlobalStyle />

					{/* Tag Input */}

					<Container
						expanded={expanded}
						ref={containerRef}
						disabled={disabled}
						hasError={!!error}
					>
						{!hasCheckedItems() && placeholder && (
							<Typography.Paragraph
								data-testid="input-tag-with-checkbox-dropdown-toggler"
								onClick={handleOnClick}
								style={{ width: '95%' }}
								color="#D3D3D3"
							>
								{placeholder}
							</Typography.Paragraph>
						)}
						{/* Tags */}
						{hasCheckedItems() && sortable ? (
							<SortableItems
								helperClass="draggable-item"
								// custom props
								items={limitedList}
								isDisabled={disabled}
								readOnly={readOnly}
								onClick={onItemClick}
								onDelete={deleteItem}
								handleOnClick={handleOnClick}
								expanded={expanded}
								// sortable props
								axis="xy"
								distance={4}
								onSortEnd={onSortEnd}
								// small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
								getHelperDimensions={({ node }) => node.getBoundingClientRect()}
								// lockToContainerEdges
							/>
						) : (
							hasCheckedItems() && (
								<Flex
									onClick={handleOnClick}
									style={{
										gap: '0.8rem',
										width: !expanded ? '81%' : '100%',
										overflow: 'auto',
										flexDirection: !expanded ? 'row' : 'column',
										maxHeight: '19rem',
										marginBottom: '-0.64rem'
									}}
									wrap
								>
									{limitedList.map((item, index) => (
										<Tag
											key={`item-${item.value}-${index}`}
											title={item.label}
											disabled={disabled}
											propagate={readOnly}
											onClick={
												onItemClick
													? () => onItemClick(item.value)
													: undefined
											}
											onDelete={
												readOnly ? undefined : () => deleteItem(index)
											}
											active
										/>
									))}
								</Flex>
							)
						)}
						<OptionsContainer
							expanded={expanded}
							hasUnrenderedItems={unrenderedItems.length > 0}
						>
							{/* Remaining Items */}
							{!disabled && unrenderedItems.length > 0 && (
								<Flex
									// tooltip props
									data-tip={expanded ? 'Click hide' : 'Click expand'}
									data-for="remaining-items"
								>
									<RemainingItems onClick={() => setExpanded(!expanded)}>
										{!expanded ? `+${unrenderedItems.length} more` : 'hide'}
									</RemainingItems>
									<Tooltip
										id="remaining-items"
										delayShow={250}
										place="bottom"
										html
									/>
								</Flex>
							)}
							<Flex>
								{/* Delete All Icon */}
								{!disabled && hasCheckedItems() && (
									<DeleteIconContainer
										hasUnrenderedItems={unrenderedItems.length > 0}
									>
										<Icon
											{...(!isInputValid && {
												style: { visibility: 'hidden' }
											})}
											paddingOffset={{ all: 0.4, left: 0.8 }}
											svg={Svgs.Clear}
											size={s => s.m}
											colors={{ color: Colors.text.disabled }}
											onClick={deleteAll}
										/>
									</DeleteIconContainer>
								)}
								<Icon
									svg={Svgs.ChevronDown}
									size={s => s.l}
									colors={{ color: Colors.text.main }}
									style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
									dataTestId="input-tag-with-checkbox-dropdown-chevron"
									onClick={handleToggleOpen}
								/>
							</Flex>
						</OptionsContainer>
					</Container>

					{hint !== undefined && (
						<Typography.Hint marginOffset={{ top: 0.2 }} multiline>
							{hint}
						</Typography.Hint>
					)}

					<InputError error={error} />
				</Flex>

				{/* Dropdown */}
				{open && !(error && disabled && readOnly) && (
					<FloatingContainer hasLabel={!!label} ref={dropdownRef}>
						<Flex paddingOffset={{ x: 1.6, y: 1.6 }} column>
							<SearchInput
								term={searchTerm}
								placeholder="Search"
								onChangeTerm={setSearchTerm}
							/>
							<Spacer size={s => s.s} />

							{/* TOGGLE ALL */}
							{hasItems && (
								<>
									<Checkbox
										className="checkbox"
										label={`Check/Uncheck all`}
										checked={getItemsCheckedState().all}
										partial={getItemsCheckedState().partial}
										onClick={handleToggleAll}
									/>
									<Spacer size={s => s.s} />
								</>
							)}

							<ItemsContainer>
								{/* COLUMNS LIST */}
								<Gap marginGap={{ bottom: 0.8 }} notLastChild>
									{hasItems &&
										formatedInitialItems.map(item => {
											return (
												<Checkbox
													key={`item_${item.value}`}
													className="checkbox"
													label={item.label}
													checked={isItemChecked(item.value)}
													onClick={() => handleCheckItem(item.value)}
												/>
											);
										})}
								</Gap>

								{/* NO RESULTS */}
								{noSearchResults && (
									<Typography.Caption
										marginOffset={{ left: 0.4 }}
									>{`No results`}</Typography.Caption>
								)}
							</ItemsContainer>
						</Flex>
					</FloatingContainer>
				)}
			</div>
		</ComponentContainer>
	);
}

const GlobalStyle = createGlobalStyle`
	body {
		.draggable-item {
			/* The tag item that the user moves inside the input */
			/* is injected like a portal right in the body */
			/* so we gotta lift it up over all the content to be visible */
			z-index: 99999;
		}
	}
`;

/**
 * ===================
 * SORTABLE COMPONENTS
 * ===================
 */

interface SortableItemProps {
	item: Items[0];
	itemIndex: number;
	isDisabled?: boolean;
	readOnly?: boolean;
	onClick?: (value: string) => void;
	onDelete: (index: number) => void;
}

const SortableItem = SortableElement<SortableItemProps>(
	({ item, itemIndex, isDisabled, readOnly, onClick, onDelete }: SortableItemProps) => (
		<Tag
			title={item.label}
			disabled={isDisabled}
			propagate={readOnly}
			onClick={onClick ? () => onClick(item.value) : undefined}
			onDelete={readOnly ? undefined : () => onDelete(itemIndex)}
			active
		/>
	)
);

interface SortableItemsProps {
	items: Items;
	isDisabled?: boolean;
	readOnly?: boolean;
	expanded?: boolean;
	onClick?: (value: string) => void;
	onDelete: (index: number) => void;
	handleOnClick: () => void;
}

const SortableItems = SortableContainer<SortableItemsProps>(
	({
		items,
		isDisabled,
		readOnly,
		expanded,
		onClick,
		onDelete,
		handleOnClick
	}: SortableItemsProps) => (
		<Flex
			onClick={handleOnClick}
			style={{
				gap: '0.8rem',
				width: !expanded ? '81%' : '100%',
				overflow: 'scroll',
				maxHeight: '13.5rem',
				marginBottom: '-0.64rem'
			}}
			wrap
		>
			{items.map((item, index) => (
				<SortableItem
					key={`item-${item.value}-${index}`}
					index={index}
					item={item}
					itemIndex={index}
					disabled={isDisabled} // this prop disables the drag functionality
					isDisabled={isDisabled}
					readOnly={readOnly}
					onClick={onClick}
					onDelete={onDelete}
				/>
			))}
		</Flex>
	)
);

export function filterItemsBySearchTerm(items: ParsedItem[], searchTerm: string): ParsedItem[] {
	return items.filter(
		item =>
			item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item.value.toLowerCase().includes(searchTerm.toLowerCase())
	);
}

function reverseSortByNameLength(a: ParsedItem, b: ParsedItem) {
	return b.label.length - a.label.length;
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setUiStates, SetUiStatesAction } from 'store/ui/states';
import { ActionPayload } from 'store/types';

interface Data {
	focusState: boolean;
}
type Handler = (payload: ActionPayload<SetUiStatesAction>) => void;

export function useUiStates(): LocalOperationResult<Data, Handler> {
	const dispatch = useDispatch();

	const focusState = useSelector(state => state.ui.states.focus);

	const data = { focusState };

	function handler(payload: ActionPayload<SetUiStatesAction>) {
		dispatch(setUiStates(payload));
	}

	return [data, handler];
}

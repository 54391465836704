import styled, { css } from 'styled-components';

import { Responsive } from 'components/UI/Table/Table';
import { SeriesViewType } from 'types/index';

interface TableViewProps {
	tableType?: string;
}

export const TableWrapper = styled.div<TableViewProps>`
	height: 100%;
	${({ tableType }) => {
		switch (tableType) {
			case SeriesViewType.NarrowTableView:
				return css`
					margin: 0;
				`;

			case SeriesViewType.EditEntryView:
				return css`
					margin: 0rem 2.4rem 2.4rem 2.4rem;
				`;

			default:
				return css`
					margin: 1.6rem 2.4rem 2.4rem 2.4rem;
				`;
		}
	}}
`;

export const ResponsiveWrapper = styled(Responsive)`
	margin: 2.4rem;
	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		min-width: 5.6rem;
		max-width: 5.6rem;
		border-top: 0;
	}

	.errored {
		/* Colors.text.error, 10% */
		background-color: rgba(255, 88, 93, 0.1);
	}
`;

import { useState, useEffect, useRef } from 'react';
import { InputType } from 'types/index';
import { Title, TitleInput } from './FormTitle.style';
import { useTranslation, usePermissions, useSetFormTitle, useForm } from 'hooks/store';

export function FormTitle() {
	const { translate } = useTranslation();

	const { hasVariablesWriteAccess } = usePermissions();

	const setFormTitle = useSetFormTitle();
	const [{ data: form, fetched: isFormFetched }] = useForm({ lazy: true });

	const [debouncedFormTitle, setDebouncedFormTitle] = useState(getFormTitle());

	useEffect(() => {
		if (isFormFetched && form) setDebouncedFormTitle(form.title);
	}, [isFormFetched, form?.title]);

	const inputRef = useRef<HTMLTextAreaElement>(null);

	function handleUpdateTitle() {
		if (!isFormFetched) return;

		const trimmedValue = debouncedFormTitle.trim();

		if (form?.title === trimmedValue) return;

		setFormTitle({ title: trimmedValue });
		focusOut();
	}

	function handleResetTitle() {
		setDebouncedFormTitle(getFormTitle());
	}

	function getFormTitle() {
		return form?.title ?? '';
	}

	function focusOut() {
		inputRef.current?.blur();
	}

	if (!form?.titleEnabled) return null;

	return (
		<Title data-test-id="promTitle">
			<TitleInput
				ref={inputRef}
				type={InputType.Textarea}
				disabled={!hasVariablesWriteAccess}
				placeholder={translate(dict => dict.formDesigner.formTitlePlaceholder)}
				value={debouncedFormTitle}
				onChange={e => setDebouncedFormTitle(e.target.value)}
				onSubmit={handleUpdateTitle}
				onBlur={handleUpdateTitle}
				onCancel={handleResetTitle}
			/>
		</Title>
	);
}

import { useMemo } from 'react';
import {
	differenceInCalendarDays,
	differenceInWeeks,
	differenceInMonths,
	differenceInYears,
	differenceInHours,
	getUnixTime,
	getWeek
} from 'date-fns';
import { DistributionData } from 'api/data/dashboard';
import { Chart } from 'components/UI/Chart';
import { Colors } from 'environment';
import { GenericMap } from 'types/index';
import { CardView } from '../ResponseRateChart/ResponseRateChart.style';
import { DEFAULT_MAX_LABELS } from 'consts';
import { computeLabels } from 'helpers/analysis';
import { roundMinutes, formatAPIDate } from 'helpers/dateFormat';
import { useAnalysesActiveColum, useFullscreenAnalysis, useTranslation } from 'hooks/store';

const LINE_COLORS = [
	Colors.primary.normal,
	Colors.surveyChartColors.yellow,
	Colors.surveyChartColors.green.light
];

const DAYS_OFFSET = 3;
const WEEKS_OFFSET = 21;
const MONTHS_OFFSET = 91;
const YEARS_OFFSET = 1000;

const LabelTypes = {
	FULL_FORMAT: 'yyyy-MM-dd HH:mm:ss',
	DAY: "d MMM HH:'00'",
	DAYS: 'd MMM',
	WEEKS: "'W' w yyyy",
	MONTHS: 'MMM yyyy',
	YEARS: 'yyyy'
};

interface Serie {
	text: string;
	marker: {
		visible: boolean;
		backgroundColor: string;
		offsetX: string;
	};
	lineColor: string;
	lineWidth: number;
	values: [number, number | null][];
}

function getIntervals(firstDate: Date, lastDate: Date) {
	const hoursInterval = differenceInHours(lastDate, firstDate);
	const daysInterval = differenceInCalendarDays(lastDate, firstDate);
	const weeksInterval = differenceInWeeks(lastDate, firstDate);
	const monthsInterval = differenceInMonths(lastDate, firstDate);
	const yearsInterval = differenceInYears(lastDate, firstDate);

	const DAY_INTERVAL = daysInterval < DAYS_OFFSET;
	const DAYS_INTERVAL = daysInterval >= DAYS_OFFSET && daysInterval < WEEKS_OFFSET;
	const WEEKS_INTERVAL = daysInterval >= WEEKS_OFFSET && daysInterval < MONTHS_OFFSET;
	const MONTHS_INTERVAL = daysInterval >= MONTHS_OFFSET && daysInterval < YEARS_OFFSET;
	const YEARS_INTERVAL = daysInterval >= YEARS_OFFSET;

	return {
		DAY_INTERVAL,
		DAYS_INTERVAL,
		WEEKS_INTERVAL,
		MONTHS_INTERVAL,
		YEARS_INTERVAL,
		hoursInterval,
		daysInterval,
		weeksInterval,
		monthsInterval,
		yearsInterval
	};
}

function computeSeries(distributionData: DistributionData[], names: string[]) {
	const series: Serie[] = names.map((name, i) => ({
		text: name,
		values: [],
		marker: {
			visible: true,
			backgroundColor: LINE_COLORS[i],
			offsetX: '-0.45%'
		},
		lineColor: LINE_COLORS[i],
		lineWidth: 1
	}));

	const sortedDistributionData = distributionData
		.slice()
		.sort((a, b) => a?.timeInMillis - b?.timeInMillis);

	const firstDate = roundMinutes(new Date(formatAPIDate(sortedDistributionData[0]?.date)));
	const lastDate = roundMinutes(
		new Date(formatAPIDate(sortedDistributionData[sortedDistributionData.length - 1]?.date))
	);

	const { DAY_INTERVAL, DAYS_INTERVAL, WEEKS_INTERVAL, MONTHS_INTERVAL, YEARS_INTERVAL } =
		getIntervals(firstDate, lastDate);

	const computedDataByTimestamp = sortedDistributionData.reduce<GenericMap<DistributionData>>(
		(acc, dData) => {
			const date = roundMinutes(new Date(formatAPIDate(dData.date)));

			let newDate = date.getUTCFullYear().toString();

			if (!YEARS_INTERVAL) newDate += `-${date.getUTCMonth() + 1}`;
			if (!MONTHS_INTERVAL) newDate += `-${date.getUTCDate()}`;
			if (!DAYS_INTERVAL) newDate += ` ${date.getUTCHours()}`;

			if (WEEKS_INTERVAL) {
				newDate = date.getUTCFullYear().toString() + `${getWeek(date)}`;

				const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

				date.setDate(diff);
			}

			const prevSentOnDate = acc[newDate]?.sentOnDate;
			const prevPartiallyFilledOnDate = acc[newDate]?.partiallyFilledOnDate;
			const prevFilledOnDate = acc[newDate]?.filledOnDate;

			const timeInMillis = getUnixTime(date) * 1000;

			return {
				...acc,
				[newDate]: {
					...dData,
					date: date.toISOString(),
					timeInMillis,
					...((prevSentOnDate || dData.sentOnDate !== undefined) && {
						sentOnDate: (dData.sentOnDate ?? 0) + (prevSentOnDate ?? 0)
					}),
					...((prevPartiallyFilledOnDate ||
						dData.partiallyFilledOnDate !== undefined) && {
						partiallyFilledOnDate:
							(dData.partiallyFilledOnDate ?? 0) + (prevPartiallyFilledOnDate ?? 0)
					}),
					...((prevFilledOnDate || dData.filledOnDate !== undefined) && {
						filledOnDate: (dData.filledOnDate ?? 0) + (prevFilledOnDate ?? 0)
					})
				}
			};
		},
		{}
	);

	let maxValue = 0;

	Object.values(computedDataByTimestamp)
		.sort((a, b) => a.timeInMillis - b.timeInMillis)
		.forEach(entry => {
			if (entry.sentOnDate) {
				series[0].values.push([entry.timeInMillis, entry.sentOnDate]);
			}
			if (entry.partiallyFilledOnDate) {
				series[1].values.push([entry.timeInMillis, entry.partiallyFilledOnDate]);
			}
			if (entry.filledOnDate) {
				series[2].values.push([entry.timeInMillis, entry.filledOnDate]);
			}
			maxValue = Math.max(
				maxValue,
				entry.sentOnDate ?? 0,
				entry.partiallyFilledOnDate ?? 0,
				entry.filledOnDate ?? 0
			);
		});

	const scaleXValues = [];
	const scaleXLabels = [];

	const { hoursInterval, daysInterval, weeksInterval, monthsInterval, yearsInterval } =
		getIntervals(firstDate, lastDate);

	let maximumInterval: number = hoursInterval;

	if (DAYS_INTERVAL) maximumInterval = daysInterval;
	else if (WEEKS_INTERVAL) maximumInterval = weeksInterval;
	else if (MONTHS_INTERVAL) maximumInterval = monthsInterval;
	else if (YEARS_INTERVAL) maximumInterval = yearsInterval;

	for (let i = 0; i < maximumInterval + 1; i++) {
		scaleXValues[i] = 0;
		scaleXLabels[i] = '';
	}

	Object.values(computedDataByTimestamp).forEach((element, i) => {
		let index = 0;
		if (DAY_INTERVAL) index = differenceInHours(new Date(element.date), new Date(firstDate));
		else if (DAYS_INTERVAL)
			index = differenceInCalendarDays(new Date(element.date), new Date(firstDate));
		if (WEEKS_INTERVAL) index = differenceInWeeks(new Date(element.date), new Date(firstDate));
		else if (MONTHS_INTERVAL)
			index = differenceInMonths(new Date(element.date), new Date(firstDate));
		else if (YEARS_INTERVAL)
			index = differenceInYears(new Date(element.date), new Date(firstDate));

		scaleXValues[index] = Number(Object.values(computedDataByTimestamp)[i].timeInMillis);
		scaleXLabels[index] = element.date;
	});

	if (scaleXValues.length && scaleXValues.length <= 3) {
		series.forEach(item => {
			item.marker.offsetX = '0';
		});
	}

	return {
		series,
		maxValue,
		scaleXValues,
		scaleXLabels,
		firstDate,
		lastDate
	};
}

function computeOptions(
	maxValue: number,
	scaleXValues: number[],
	scaleXLabels: string[],
	firstDate: Date,
	lastDate: Date,
	activeColumns: number,
	fullscreen: boolean
) {
	const { DAY_INTERVAL, DAYS_INTERVAL, WEEKS_INTERVAL, MONTHS_INTERVAL } = getIntervals(
		firstDate,
		lastDate
	);

	const { DAY, DAYS, WEEKS, MONTHS, YEARS } = LabelTypes;

	let labelType = YEARS;

	if (DAY_INTERVAL) labelType = DAY;
	if (DAYS_INTERVAL) labelType = DAYS;
	if (WEEKS_INTERVAL) labelType = WEEKS;
	if (MONTHS_INTERVAL) labelType = MONTHS;

	const labels = scaleXLabels.map(label => {
		if (label) {
			return formatAPIDate(label, labelType);
		} else return '';
	});

	const [maxItems, _, fontAngle, tooltip] = computeLabels(
		scaleXLabels,
		activeColumns === 1 || fullscreen
	);

	return {
		crosshairX: {
			exact: true,
			lineStyle: 'dashed',
			lineColor: Colors.text.disabled,
			plotLabel: {
				bold: false,
				borderRadius: 5,
				borderWidth: 1,
				borderColor: Colors.text.main,
				fontSize: 12,
				fontWeight: 'bold',
				color: Colors.text.main,
				padding: 10,
				callout: false,
				placement: 'node-top',
				multiple: true,
				offsetY: '8px'
			},
			scaleLabel: {
				// set "visible: false" until we figure out a way to only show crosshair lines for 1 element at a time
				visible: false,
				borderRadius: 2,
				borderWidth: 1,
				borderColor: Colors.text.disabled,
				fontSize: 12,
				color: Colors.text.hint,
				padding: 10
			}
		},
		legend: {
			visible: false
		},
		plot: {
			aspect: 'spline',
			tooltip: {
				visible: false
			}
		},
		plotarea: {
			marginRight: '80px',
			marginLeftOffset: '40px',
			marginTop: '40px'
		},

		scaleX: {
			values: scaleXValues,
			labels,
			itemsOverlap: false,
			maxItems: activeColumns === 3 && !fullscreen ? DEFAULT_MAX_LABELS : maxItems,
			tooltip,
			sizeFactor: 'auto',
			item: {
				rules: [
					{
						rule: '%v == 0',
						visible: 'false'
					}
				],
				fontSize: 12,
				color: Colors.text.hint,
				fontAngle: fontAngle ? fontAngle : -45,
				paddingTop: 10,
				paddingLeft: 50
			},
			lineColor: Colors.text.disabled
		},
		scaleY: {
			values: `0:${maxValue + 1}`,
			item: {
				fontSize: 12,
				color: Colors.text.hint
			},
			guide: {
				lineColor: Colors.text.disabled
			}
		}
	};
}

interface Props {
	data: DistributionData[];
}

export function LastDaysChart({ data }: Props) {
	const [activeColumns] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();
	const { translate } = useTranslation();
	const names: string[] = [
		translate(dict => dict.responseRateChart.totalFormsSent),
		translate(dict => dict.responseRateChart.totalFormsPartiallyFilled),
		translate(dict => dict.responseRateChart.totalFormsFilled)
	];

	const { series, maxValue, scaleXValues, scaleXLabels, firstDate, lastDate } = useMemo(
		() => computeSeries(data, names),
		[data, names]
	);

	const options = useMemo(
		() =>
			computeOptions(
				maxValue,
				scaleXValues,
				scaleXLabels,
				firstDate,
				lastDate,
				activeColumns,
				!!fullscreen
			),
		[maxValue, scaleXValues, scaleXLabels]
	);

	return (
		<CardView>
			<Chart
				type={t => t.Line}
				series={series}
				options={options}
				effect={e => e.ExpandBottom}
				method={m => m.RegularEaseOut}
				height={260}
			/>
		</CardView>
	);
}

import { useCallback, useState } from 'react';
import { useTransition } from 'react-spring';

import { EntryFilter as FilterType } from 'api/data/filters';
import { STATUS_COLUMN } from 'consts';
import { Colors, Svgs } from 'environment';
import { useDependenciesFilters, useFilters } from 'hooks/store';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { VariablesData } from 'store/data/variables';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Icon } from 'components/UI/Icons';

import { FilterBreakdown } from './FilterBreakdown';
import { FilterForm } from './FilterForm';
import { Container, FadingWrapper } from './Filter.style';
import { TableDependency } from 'store/data/dependencies';
import { useSeriesFilters } from 'hooks/store/data/useSeriesFilters/useSeriesFilters';
import { setRefetchEntries } from 'store/data/entries';
import { useDispatch } from 'hooks/utils';

interface Props {
	filter: FilterType;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	disabled?: boolean;
	breakdown?: boolean;
	filteredCount?: number;
	isSeriesFilter?: boolean;
	tableFilter?: boolean;
	defaultCheckAll?: boolean;
	isDependencyFilter?: boolean;
	tableDependencies?: TableDependency[];
	hideFilter?: () => void;
	filterContainerRef?: React.RefObject<HTMLDivElement>;
	filterDrawerContainerRef?: React.RefObject<HTMLDivElement>;
}

export function Filter({
	filter,
	variablesData,
	variablesDataSelectItems,
	disabled,
	breakdown = false,
	filteredCount,
	isDependencyFilter,
	tableDependencies,
	isSeriesFilter,
	tableFilter = false,
	filterContainerRef = { current: null },
	filterDrawerContainerRef = { current: null },
	hideFilter
}: Props) {
	const dispatch = useDispatch();
	const [, { deleteDatasetFilter }] = useFilters();
	const [, { deleteDependenciesFilter }] = useDependenciesFilters();
	//SERIES FILTER
	const [, { deleteSeriesFilter }] = useSeriesFilters();

	const [open, setOpen] = useState(true);
	const [mouseover, setMouseover] = useState(false);

	const { selectItemsLabelMap } = variablesDataSelectItems;

	function handleToggle() {
		setOpen(state => !state);
	}

	function handleMouseEnter() {
		if (!mouseover) setMouseover(true);
	}

	function handleMouseLeave() {
		if (mouseover) setMouseover(false);
	}

	const transitions = useTransition(mouseover, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { duration: 0 }
	});

	function getFilterTitle() {
		if (filter.columnName === STATUS_COLUMN.name) return STATUS_COLUMN.label;

		return selectItemsLabelMap[filter.columnName];
	}

	function handleDeleteFilter(itemId: string) {
		if (isDependencyFilter) {
			return deleteDependenciesFilter(itemId);
		}
		if (isSeriesFilter) {
			return deleteSeriesFilter(itemId);
		}

		if (filter.values?.length === 0) {
			dispatch(setRefetchEntries());
		}
		return deleteDatasetFilter(itemId);
	}

	const onHideFilter = useCallback(() => {
		if (hideFilter) hideFilter();

		handleToggle();
	}, [hideFilter, handleToggle]);

	return (
		<Container>
			<CollapsibleCard
				renderHidden
				title={getFilterTitle()}
				open={open}
				disabled={disabled}
				actionComponent={transitions((props, item) => {
					if (item) {
						return (
							<FadingWrapper style={props}>
								<Icon
									svg={Svgs.Delete}
									colors={{
										color: Colors.text.disabled,
										hover: Colors.primary.normal
									}}
									disabled={disabled}
									onClick={() => handleDeleteFilter(filter.itemId)}
								/>
							</FadingWrapper>
						);
					}

					if (breakdown) {
						return (
							<FadingWrapper style={props}>
								<FilterBreakdown count={filteredCount} />
							</FadingWrapper>
						);
					}

					return null;
				})}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onToggle={handleToggle}
			>
				<FilterForm
					filterContainerRef={filterContainerRef}
					filterDrawerContainerRef={filterDrawerContainerRef}
					hideFilter={onHideFilter}
					isDependencyFilter={isDependencyFilter}
					filter={filter}
					tableDependencies={tableDependencies}
					variablesData={variablesData}
					disabled={!!disabled}
					isSeriesFilter={isSeriesFilter}
					tableFilter={tableFilter}
				/>
			</CollapsibleCard>
		</Container>
	);
}

import styled, { css } from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Input as PrimaryInput } from 'components/UI/Inputs/Input';
import { AdvancedMenu as DefaultAdvancedMenu } from 'components/UI/Interactables/AdvancedMenu/AdvancedMenu';

export const Container = styled.div`
	width: 100%;
	justify-content: space-between;
	display: flex;
	align-items: flex-start;
`;

export const Input = styled(PrimaryInput)`
	input {
		border: none;
		outline: none;
		padding: 0;
		height: unset;
	}
`;

export const TemplateHeaderLeft = styled.div`
	display: flex;
	align-items: flex-start;
	flex: 1;
`;

interface WriteAccessProps {
	writeAccess: boolean;
}
export const TemplateHeaderRight = styled.div<WriteAccessProps>`
	display: flex;
	align-items: center;
	position: relative;

	${({ writeAccess }) =>
		!writeAccess &&
		css`
			margin-right: 1.5rem;
		`}
`;

interface AdvancedMenuInterface {
	visible: boolean;
}
export const AdvancedMenu = styled(DefaultAdvancedMenu)<AdvancedMenuInterface>`
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

	/* toggle icon */
	> :first-child {
		/* remove default padding */
		padding: 0;

		/* remove hover effect */
		:hover {
			background-color: unset;
		}
	}
`;

export const ImportIcon = styled(DefaultIcon)`
	visibility: hidden;
`;

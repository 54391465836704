import { VariableType } from 'types/data/variables/constants';
import { EntryFilter } from 'api/data/filters';
import { STATUS_COLUMN } from 'consts';
import { VariablesData } from 'store/data/variables';

import { FilterInputs } from './FilterInputs';
import { FilterStatuses } from './FilterStatuses';
import { FilterCategories } from './FilterCategories';
import { TableDependency } from 'store/data/dependencies';

interface Props {
	filter: EntryFilter;
	variablesData: VariablesData;
	disabled: boolean;
	isDependencyFilter?: boolean;
	tableDependencies?: TableDependency[];
	isSeriesFilter?: boolean;
	tableFilter?: boolean;
	hideFilter: () => void;
	filterContainerRef: React.RefObject<HTMLDivElement>;
	filterDrawerContainerRef: React.RefObject<HTMLDivElement>;
}

export function FilterForm({
	isDependencyFilter,
	filter,
	variablesData,
	disabled,
	isSeriesFilter,
	tableDependencies,
	filterContainerRef,
	tableFilter,
	filterDrawerContainerRef,
	hideFilter
}: Props) {
	const isStatusFilter = filter.columnName === STATUS_COLUMN.name;

	if (isStatusFilter)
		return (
			<FilterStatuses
				tableFilter={tableFilter}
				filterContainerRef={filterContainerRef}
				hideFilter={hideFilter}
				filter={filter}
				disabled={disabled}
			/>
		);

	const isCategory = filter.filterType
		? [VariableType.Category, VariableType.CategoryMultiple].includes(filter.filterType)
		: false;

	if (isCategory) {
		return (
			<FilterCategories
				tableFilter={tableFilter}
				filterContainerRef={filterContainerRef}
				hideFilter={hideFilter}
				tableDependencies={tableDependencies}
				isDependencyFilter={isDependencyFilter}
				filter={filter}
				variablesData={variablesData}
				disabled={disabled}
				isSeriesFilter={isSeriesFilter}
			/>
		);
	}

	return (
		<FilterInputs
			tableFilter={tableFilter}
			filterContainerRef={filterContainerRef}
			filterDrawerContainerRef={filterDrawerContainerRef}
			hideFilter={hideFilter}
			filter={filter}
			disabled={disabled}
			isDependencyFilter={isDependencyFilter}
			isSeriesFilter={isSeriesFilter}
		/>
	);
}

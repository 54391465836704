import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import {
	moveVariablesBetweenGroups,
	MoveVariablesBetweenGroupsAction,
	ActionTypes
} from 'store/data/variables';
import { ActionPayload } from 'store/types';

export function useMoveVariablesBetweenGroups(): Operation<
	(payload: ActionPayload<MoveVariablesBetweenGroupsAction>) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS);

	function handler(payload: ActionPayload<MoveVariablesBetweenGroupsAction>) {
		dispatch(moveVariablesBetweenGroups(payload));
	}

	return [{ loading, error }, handler];
}

import { Flex } from 'components/UI/Flex';
import { Select } from 'components/UI/Interactables/Select';
import { Typography } from 'components/UI/Typography';
import { DATA_TYPES_OPTIONS } from 'consts';
import { GridVariableTypes, ItemOptions, Sort } from 'store/data/variables';
import { Container } from './VariablesSubHeader.style';
import { useTranslation, useVariablesFilters } from 'hooks/store';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	drawerOpen: boolean;
}

export function VariablesSubHeader({ drawerOpen }: Props) {
	const { translate } = useTranslation();

	const [{ filters }, { setShowFilter, setTypeFilter, setSort }] = useVariablesFilters();

	const allDataTypes = [
		{ label: GridVariableTypes.ALL, value: GridVariableTypes.ALL },
		...DATA_TYPES_OPTIONS
	];

	const showTypeDropdown = ![ItemOptions.GROUPS, ItemOptions.VARIABLE_SETS].includes(
		filters.show
	);

	return (
		<Container isDrawerOpen={drawerOpen}>
			<Flex>
				<Flex align={a => a.center} marginOffset={{ right: 1.6 }}>
					<Typography.Caption marginOffset={{ right: 0.8 }}>
						{translate(dict => dict.terms.show)}
					</Typography.Caption>
					<Select
						type={t => t.Tag}
						title={translate(dict => dict.terms[filters.show])}
						items={Object.values(ItemOptions).map(value => ({
							label: translate(dict => dict.terms[value]),
							value
						}))}
						onSelect={item => setShowFilter(item.value as ItemOptions)}
						width={8}
					/>
				</Flex>

				{showTypeDropdown && (
					<Flex>
						<Select
							type={t => t.Tag}
							title={translate(({ variableLabels }) =>
								filters.type === GridVariableTypes.ALL
									? GridVariableTypes.ALL
									: variableLabels[filters.type as keyof typeof variableLabels]
							)}
							items={allDataTypes}
							onSelect={item =>
								setTypeFilter(item.value as VariableType | GridVariableTypes.ALL)
							}
							width={16}
							translateChildren
						/>
					</Flex>
				)}
			</Flex>

			<Flex>
				<Typography.Caption marginOffset={{ right: 0.8 }}>
					{translate(dict => dict.terms.sort)}
				</Typography.Caption>
				<Select
					type={t => t.Tag}
					title={translate(dict => dict.variablesPage.sortVariables[filters.sort])}
					items={Object.values(Sort).map(value => ({
						label: translate(dict => dict.variablesPage.sortVariables[value]),
						value
					}))}
					onSelect={item => setSort(item.value as Sort)}
					width={12}
				/>
			</Flex>
		</Container>
	);
}

import { AnovaData, OneWayAnovaResultsV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { VariablesData } from 'store/data/variables';
import { GenericMap } from 'types';
import { PStatistic } from 'components/Analysis/Analyses/AnalysisStatistics';
import { computeCellValue } from 'helpers/analysis';

interface Props {
	results: OneWayAnovaResultsV2;
	variablesData: VariablesData;
}

export function CompareNumericOneWayAnova({ results, variablesData }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.oneWayAnova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{error ?? translate(({ errors }) => errors.api.analyses.statistics.oneWayAnova)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result variablesData={variablesData} data={data} />}
		</AnalysisStatistic>
	);
}

interface ResultProps {
	data: GenericMap<AnovaData>;
	variablesData: VariablesData;
}

function Result({ data, variablesData }: ResultProps) {
	const { variablesMap } = variablesData;

	const COLUMN_NAMES = [
		'Source/effect',
		'Sum of squares',
		'df',
		'Mean square',
		'F-value',
		'p-value'
	];

	/**
	 *
	 * @param variableNames [var1, var2]
	 * @returns computed label(string) => "var1Label * var2Label" if variableNames > 1, otherwise just "var1Label"
	 */
	function computeName(variableNames: string[]) {
		// special case
		if (variableNames?.[0] === 'Residual') {
			return 'Residual';
		}

		if (variableNames?.[0] === 'Intercept') {
			return 'Intercept';
		}

		if (variableNames.length) {
			return variableNames.map(name => variablesMap[name]?.label).join(' * ');
		}

		return variablesMap[variableNames[0]]?.label ?? variableNames[0];
	}

	return (
		<Table fullWidth={false}>
			<Table.Head>
				<Table.Row>
					{COLUMN_NAMES.map(colName => (
						<Table.Column key={`oneWayAnova-${colName}-head`} title={colName}>
							{colName}
						</Table.Column>
					))}
				</Table.Row>
			</Table.Head>
			<Table.Body>
				{Object.keys(data).map(variableKey => (
					<Table.Row key={`oneWayManova-${variableKey}`}>
						<Table.Cell minWidth={15} bold>
							{computeName(variableKey.split(' * '))}
						</Table.Cell>
						<Table.Cell>{computeCellValue(data[variableKey].sumSq)}</Table.Cell>
						<Table.Cell>
							{computeCellValue(data[variableKey].df, { noDecimals: true })}
						</Table.Cell>
						<Table.Cell>{computeCellValue(data[variableKey].meanSq)}</Table.Cell>
						<Table.Cell>
							{data[variableKey].fValue === null
								? ''
								: computeCellValue(data[variableKey].fValue)}
						</Table.Cell>
						<Table.Cell>
							{data[variableKey].pValue !== null ? (
								<PStatistic value={data[variableKey].pValue as number} />
							) : (
								''
							)}
						</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);
}

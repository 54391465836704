import styled, { css } from 'styled-components';

export const Container = styled.div<{ confirmedUser?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.8rem;

	.confirm-checkbox {
		width: auto;
		margin-right: 0.8rem;
	}

	${({ confirmedUser }) =>
		confirmedUser &&
		css`
			justify-content: flex-start;
		`}
`;

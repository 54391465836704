import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { FrequenciesAnalysisV2 } from 'api/data/analyses';

import {
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames, FileType, NumberMap } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { FrequenciesBarChartV2 } from './FrequenciesBarChartV2';
import { FrequenciesPieChartV2 } from './FrequenciesPieChartV2';
import { FrequenciesTableV2 } from './FrequenciesTableV2';
import { VariablesData } from 'store/data/variables';
import { AnalysisChartContainer } from '../../UI';
import { exportSvg } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import {
	getAggregatorVariableNameByAggregationRuleName,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';
import {
	useTranslation,
	useProject,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useAnalysisActiveTab,
	useVariablesDataSelectItems
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import { exportFrequenciesV2 } from 'helpers/analysis/exportFrequenciesV2';

interface Props {
	analysis: FrequenciesAnalysisV2;
	variablesData: VariablesData;
	loading: boolean;
	exporting: boolean;
}

export function FrequenciesViewV2({ analysis, loading, exporting, variablesData }: Props) {
	const { translate } = useTranslation();

	const [{ data: project }] = useProject();

	const {
		id,
		options: { configPanel, chartLegend = true },
		input: {
			variables: { categoryVariable }
		},
		output: {
			dataset: { data: results }
		}
	} = analysis;

	const [activeColumn] = useAnalysesActiveColum();

	const [fullscreen] = useFullscreenAnalysis();

	const { selectItemsLabelMap } = useVariablesDataSelectItems(variablesData, {
		series: analysis.input.series,
		omitVariables: []
	});

	const categoryLabel = selectItemsLabelMap[categoryVariable?.name ?? ''];

	const [activeTab] = useAnalysisActiveTab(id);

	const frequenciesColumnsId = ZingChartExportTypes.FrequenciesColumns + id;
	const frequenciesPieId = ZingChartExportTypes.FrequenciesPie + id;

	const { variablesMap, variableSetsMap } = variablesData;

	useEffect(() => {
		if (exporting && project && results) {
			if (activeTab === 0) {
				const data = exportFrequenciesV2(parsedResults, { translate });

				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.FrequenciesTable,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			} else if (activeTab === 1) {
				exportSvg(
					frequenciesColumnsId,
					getExportFileName(
						ExportFileNames.FrequenciesColumns,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 2) {
				exportSvg(
					frequenciesPieId,
					getExportFileName(
						ExportFileNames.FrequenciesPie,
						project.projectId,
						project.projectName
					)
				);
			}
		}
	}, [exporting]);

	const scalesLabels = {
		labelX: categoryLabel,
		labelY: translate(d => d.terms.count)
	};

	const aggregatorVariableNameByAggregationRuleNameMap = useMemo(() => {
		if (categoryVariable && !variablesMap[categoryVariable?.name ?? '']) {
			return getAggregatorVariableNameByAggregationRuleName(variableSetsMap);
		}
	}, [variableSetsMap, categoryVariable]);

	const { parsedResults } = useMemo(() => {
		const parsedResults = cloneDeep(results ?? { dict: {}, keys: [] });

		const { dict, keys } = results ?? { dict: {}, keys: [] };

		if (analysis.options.showCategoryLabels) {
			const newDict: NumberMap = {};
			const parsedKeys: string[] = [];

			for (const key of keys) {
				const variable =
					variablesMap[
						aggregatorVariableNameByAggregationRuleNameMap
							? aggregatorVariableNameByAggregationRuleNameMap[
									categoryVariable?.name ?? ''
							  ]
							: categoryVariable?.name ?? ''
					];

				const parsedKey =
					(categoryVariable && mapVariableCategoryValueToLabel(key, variable)) ?? key;
				newDict[parsedKey] = dict[key];
				parsedKeys.push(parsedKey);
			}
			parsedResults.dict = newDict;
			parsedResults.keys = parsedKeys;
		}

		return { parsedResults };
	}, [results, categoryVariable, variablesMap, analysis.options.showCategoryLabels]);

	const disableInteractions = activeColumn !== 1 && !fullscreen;

	return (
		<AnalysisViewContainer
			disableInteractions={disableInteractions}
			isConfigPanelOpen={configPanel.open}
			loading={loading}
		>
			{activeTab === 0 && (
				<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.FrequenciesV2}>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<FrequenciesTableV2
							analysis={analysis}
							data={parsedResults}
							loading={loading}
						/>
					</AnalysisChartContainer>
				</AnalysisErrorWrapper>
			)}
			{activeTab === 1 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.FrequenciesV2}
					ignoreAnalysisData
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<FrequenciesBarChartV2
							data={parsedResults}
							scalesLabels={scalesLabels}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<FrequenciesBarChartV2
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							id={frequenciesColumnsId}
							data={parsedResults}
							scalesLabels={scalesLabels}
							isForExport
							loading={loading}
							isConfigPanelOpen={configPanel.open}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}

			{activeTab === 2 && (
				<AnalysisErrorWrapper
					analysis={analysis}
					analysisType={AnalysisType.FrequenciesV2}
					ignoreAnalysisData
				>
					<AnalysisChartContainer fullscreen={fullscreen} activeColumn={activeColumn}>
						<FrequenciesPieChartV2
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							data={parsedResults.dict}
							isLegendEnabled={chartLegend}
							legendHeader={categoryLabel}
							showLabels={true}
							loading={loading}
						/>
					</AnalysisChartContainer>
					<AnalysisExportWrapper>
						<FrequenciesPieChartV2
							id={frequenciesPieId}
							data={parsedResults.dict}
							isForExport
							isLegendEnabled
							legendHeader={categoryLabel}
							fullscreen={fullscreen}
							activeColumn={activeColumn}
							loading={loading}
						/>
					</AnalysisExportWrapper>
				</AnalysisErrorWrapper>
			)}
		</AnalysisViewContainer>
	);
}

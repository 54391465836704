import produce from 'immer';
import { useMemo, useState } from 'react';
import { DefaultGeneralPermissions } from 'consts';
import { initButtonProps } from 'helpers/buttons';
import {
	GeneralPermissionFlags,
	GeneralPermissions as GeneralPermissionsType
} from 'store/data/collaborators';
import { Role } from 'store/data/roles';
import { HTMLInput, InputType } from 'types/index';
import { isTemplateRoleNameUnique } from './helpers';
import { GeneralPermissions } from '../../Collaborators/GeneralPermissions/GeneralPermissions';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { Input } from 'components/UI/Inputs/Input';
import {
	useTranslation,
	useIsProjectOwner,
	useTemplateRoles,
	useCreateTemplateRole,
	useOrganizationAccessRights
} from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	role?: Role;
	onClose: (success?: true) => void;
}

export function CreateTemplateFromRoleModal({ role, onClose }: Props) {
	const { translate } = useTranslation();

	const isProjectOwner = useIsProjectOwner();

	const [
		{
			data: templateRolesData,
			loading: loadingTemplateRolesData,
			error: templateRolesDataError
		}
	] = useTemplateRoles();
	const [
		{ error: errorCreatingTemplateRole, loading: creatingTemplateRole },
		createTemplateRole
	] = useCreateTemplateRole();

	const [
		{
			data: { accessExportDelegation }
		}
	] = useOrganizationAccessRights();

	const [templateName, setTemplateName] = useState(role?.name || '');

	const [templateDescription, setTemplateDescription] = useState(role?.description || '');

	const initialPermissions = useMemo(() => {
		let permissions = DefaultGeneralPermissions;
		if (role) {
			const { modules, statusTypeAccesses, ...rest } = role.permissions;
			permissions = rest;
		}

		return permissions;
	}, [role]);

	const [permissions, setPermissions] = useState(initialPermissions);

	useCompletedAction(creatingTemplateRole, errorCreatingTemplateRole, () => onClose(true));

	function isLoadingAction() {
		return loadingTemplateRolesData || creatingTemplateRole;
	}

	function isActionDisabled() {
		return !isProjectOwner || !templateName || !isNameUnique() || isLoadingAction();
	}

	function isErrorAction() {
		return Boolean(templateRolesDataError || errorCreatingTemplateRole);
	}

	function handleTemplateNameChange(value: string) {
		setTemplateName(value);
	}

	function isNameUnique() {
		return (
			isTemplateRoleNameUnique(templateRolesData.roles.owned, templateName) &&
			isTemplateRoleNameUnique(templateRolesData.roles.shared, templateName) &&
			isTemplateRoleNameUnique(templateRolesData.roles.public, templateName)
		);
	}

	function getErrorMessage() {
		if (templateName === '') {
			return translate(
				dict => dict.rolesPage.createTemplateFromRoleModal.emptyTemplateNameError
			);
		}

		if (!isNameUnique()) {
			return translate(
				dict => dict.rolesPage.createTemplateFromRoleModal.uniqueTemplateRoleError
			);
		}

		return '';
	}

	function handlePermissionChange(generalPermissions: GeneralPermissionsType) {
		if (role) {
			const updatedPermissions = produce(role.permissions, draft => {
				for (const key in generalPermissions) {
					const value = generalPermissions[key as GeneralPermissionFlags];

					draft[key as GeneralPermissionFlags] = value;
				}
			});

			setPermissions(updatedPermissions);
		}
	}

	const handleInputTrimOnBlur = (
		e: React.FocusEvent<HTMLInput>,
		fieldName: 'name' | 'description'
	) => {
		const { value } = e.target;

		if (fieldName === 'name') {
			setTemplateName(value.trim());
		} else {
			setTemplateDescription(value.trim());
		}
	};

	function handleSubmit() {
		if (isLoadingAction() || isErrorAction() || !isNameUnique()) return;

		if (role) {
			createTemplateRole({
				...role,
				name: templateName,
				permissions,
				publicAccess: false,
				description: role.description
			});
		}
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(({ buttons }) => buttons.add),
			loading: !isProjectOwner,
			onClick: handleSubmit,
			disabled: isActionDisabled()
		};

		buttons.neutral = {
			label: translate(({ buttons }) => buttons.cancel),
			onClick: onClose
		};
	});

	const disabled = !isProjectOwner;

	return (
		<Modal
			title={translate(dict => dict.rolesPage.createTemplateFromRoleModal.title)}
			onClose={onClose}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			visible
			close
		>
			<Typography.Caption>
				{translate(dict => dict.rolesPage.createTemplateFromRoleModal.caption)} {role?.name}
			</Typography.Caption>
			<Spacer size={s => s.s} />
			<Input
				type={InputType.Text}
				label={translate(dict => dict.rolesPage.createTemplateFromRoleModal.label)}
				value={templateName}
				onChange={e => handleTemplateNameChange(e.target.value)}
				error={getErrorMessage()}
				onBlur={e => handleInputTrimOnBlur(e, 'name')}
				autoFocus
			/>
			<Spacer size={s => s.s} />
			<Input
				type={InputType.Textarea}
				label={translate(({ roles }) => roles.modals.roleModal.fields.description)}
				value={templateDescription}
				rows={3}
				onChange={e => setTemplateDescription(e.target.value)}
				onBlur={e => handleInputTrimOnBlur(e, 'description')}
				onSubmit={handleSubmit}
			/>
			<Spacer size={s => s.m} />
			{role && (
				<GeneralPermissions
					permissions={permissions}
					disabled={disabled}
					onChange={handlePermissionChange}
					hasOrganizationExportAccess={accessExportDelegation}
				/>
			)}
		</Modal>
	);
}

import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

export const GroupContainer = styled.div`
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};
	padding: 0.8rem;
	padding-left: 1.2rem;
	border-radius: 0.4rem;
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	transition: box-shadow 0.05s;
	/*
		NEEDS TO BE HERE BECAUSE 'DraggableDependencyVariableCard' HAS POSITION RELATIVE
		WHICH AFFECTS BOX-SHADOW SPILLING OVER THIS CONTAINER (GROUP CARD)
	*/
	position: relative;

	:hover {
		box-shadow: ${Shadows.hover};
	}
`;

export const GroupVariable = styled.div`
	display: flex;
	align-items: center;
`;

export const GroupLabel = styled.div`
	overflow: hidden;
`;

import { useEffect, useState, useMemo } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { nanoid as generate } from 'nanoid';
import { produce } from 'immer';
import { isEqual } from 'lodash';
import {
	TemplatesVariablesAndGroups,
	OtherTemplatesList,
	TemplatesBuilder,
	ShareTemplateModal,
	ImportTemplateModal
} from 'components/Templates';
import { stringAsBoolean } from 'helpers/generic';
import { getInitialDraggableItemsData } from 'helpers/templates';
import {
	DraggableVariable,
	TemplateGroupInterface,
	OtherTemplateIdsTypes,
	ActionTypes
} from 'store/data/templates';
import { DragAndDropTypes, TemplatePageViews, TemplateShareLevel } from 'types/index';
import { TemplatesPageHeader } from './TemplatesPageHeader';
import {
	TemplatesContainer,
	SettingsContainer,
	Title,
	Wrapper,
	BuilderWrapper,
	BuilderContainer,
	TitleWrapper
} from './TemplatesPage.style';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { AdvancedMenu } from 'components/UI/Interactables/AdvancedMenu';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useTemplateId,
	useTemplateById,
	useUsername,
	useProjectId,
	usePageView,
	useVariables,
	useProjectTemplates,
	useUserTemplates,
	usePublicTemplates,
	useVariableIntoTemplate,
	useGroupIntoTemplate,
	useCreateTemplateFromAll,
	useSortInsideTemplate,
	useEditableTemplatesIds,
	usePublishedWithUnsupported,
	useFetchedTemplates,
	useActivity
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useEffectOnce, usePrevious } from 'hooks/utils';
import { Svgs } from 'environment';
import { useFlags } from 'launchdarkly-react-client-sdk';

const IS_PUBLIC_SHARING_ENABLED = stringAsBoolean(
	process.env.REACT_APP_USE_TEMPLATES_PUBLIC_SHARING!
);

export function TemplatesPage() {
	const { translate } = useTranslation();
	const { timeDurationFlag } = useFlags();

	const { setNotification, setError } = useAlerts();
	const { routes, navigate, promOrProject } = useNavigation();
	const { hasVariablesWriteAccess, hasModulesAccess } = usePermissions();

	const [templateId, setTemplateId] = useTemplateId();
	const template = useTemplateById(templateId ?? '');

	const currentUserId = useUsername() ?? '';
	const [projectId] = useProjectId();
	const [pageView, setPageView] = usePageView();

	const [
		{
			data: { variables, variablesMap, ungroupedVariables, groups },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true, omit: { promGenerated: true } });

	const { availableVariables, availableGroups } = useMemo(
		() =>
			getInitialDraggableItemsData({
				ungroupedVariables,
				variablesMap,
				groups,
				timeDurationFlag
			}),
		[variables, groups, timeDurationFlag]
	);

	const [initialVariables, setInitialVariables] =
		useState<DraggableVariable[]>(availableVariables);
	const [initialGroups, setInitialGroups] = useState<TemplateGroupInterface[]>(availableGroups);

	const [templateIdForImport, setTemplateIdForImport] = useState<string | null>(null);
	const [shareLevel, setShareLevel] = useState<TemplateShareLevel | null>(
		TemplateShareLevel.ShareGlobally
	);

	const [
		{
			data: sharedWithProjectIds,
			loading: loadingProjectTemplates,
			fetched: areProjectTemplatesFetched
		}
	] = useProjectTemplates();

	const [
		{ data: sharedWithUserIds, loading: loadingUserTemplates, fetched: areUserTemplatesFetched }
	] = useUserTemplates();
	const [
		{
			data: sharedWithPublicIds,
			loading: loadingPublicTemplates,
			fetched: arePublicTemplatesFetched
		}
	] = usePublicTemplates();

	const addVariableIntoTemplate = useVariableIntoTemplate();
	const addGroupIntoTemplate = useGroupIntoTemplate();
	const createTemplateFromAll = useCreateTemplateFromAll();
	const sortInsideTemplate = useSortInsideTemplate();
	// user templates
	const editableTemplatesIds = useEditableTemplatesIds();
	const [publishedUnsupported, resetPublishedUnsupported] = usePublishedWithUnsupported();
	const templates = useFetchedTemplates();

	const [{ loading: deletingTemplate, error: errorDeletingTemplate }] = useActivity(
		ActionTypes.DELETE_TEMPLATE
	);
	const [{ loading: importingTemplate, error: errorImportingTemplate }] = useActivity(
		ActionTypes.IMPORT_TEMPLATE
	);
	const [{ loading: updatingTemplate, error: errorUpdatingTemplate }] = useActivity(
		ActionTypes.UPDATE_TEMPLATE
	);
	const [{ loading: gettingTemplate, error: errorGettingTemplate }] = useActivity(
		ActionTypes.GET_TEMPLATE
	);
	const [{ loading: publishingTemplate, error: errorPublishingTemplate }] = useActivity(
		ActionTypes.PUBLISH_TEMPLATE
	);

	// RESET PAGE VIEW ON PAGE LEAVE
	useEffectOnce(() => {
		return () => setPageView(TemplatePageViews.MyTemplates);
	});

	const previousVariables = usePrevious(variables);
	const previousGroups = usePrevious(groups);
	const prevTimeDurationFlag = usePrevious(timeDurationFlag);
	useEffect(() => {
		if (!areVariablesFetched) return;

		const variablesChanged = !isEqual(variables, previousVariables);
		const groupsChanged = !isEqual(groups, previousGroups);
		const timeDurationFlagChanged = timeDurationFlag !== prevTimeDurationFlag;

		const variablesDataChanged = variablesChanged || groupsChanged || timeDurationFlagChanged;

		if (variablesDataChanged) {
			const { availableVariables, availableGroups } = getInitialDraggableItemsData({
				ungroupedVariables,
				variablesMap,
				groups,
				timeDurationFlag
			});

			setInitialVariables(availableVariables);
			setInitialGroups(availableGroups);
		}
	}, [areVariablesFetched, variables, groups, prevTimeDurationFlag]);

	const templateUpdateNotification = translate(({ templates }) =>
		shareLevel ? templates.templateShareSettingsUpdated : templates.templateUpdated
	);

	const hadErrorUpdatingTemplate = usePrevious(errorUpdatingTemplate);
	const wasUpdatingTemplate = usePrevious(updatingTemplate);

	useEffect(() => {
		if (
			(hadErrorUpdatingTemplate &&
				wasUpdatingTemplate &&
				!updatingTemplate &&
				errorUpdatingTemplate) ||
			(!publishedUnsupported &&
				wasUpdatingTemplate &&
				!updatingTemplate &&
				!errorUpdatingTemplate)
		) {
			setNotification({
				message: templateUpdateNotification
			});
		}
		if (
			publishedUnsupported &&
			wasUpdatingTemplate &&
			!updatingTemplate &&
			!errorUpdatingTemplate
		) {
			setError({
				message: unsupportedErrorMessage
			});
			resetPublishedUnsupported();
		}
	}, [publishedUnsupported, updatingTemplate, errorUpdatingTemplate]);

	const hadErrorPublishingTemplate = usePrevious(errorPublishingTemplate);
	const wasPublishingTemplate = usePrevious(publishingTemplate);
	useEffect(() => {
		const temporaryTemplate = !!(templateId && templateId.includes('temp'));
		if (
			!publishedUnsupported &&
			wasPublishingTemplate &&
			!publishingTemplate &&
			!hadErrorPublishingTemplate
		) {
			!temporaryTemplate &&
				setNotification({
					message: translate(({ templates }) => templates.templateSaved)
				});
			setTemplateId(null);
			resetPublishedUnsupported();
		}
	}, [wasPublishingTemplate, publishingTemplate, errorPublishingTemplate]);

	const unsupportedErrorMessage = translate(({ templates }) => templates.unsupportedErrorMessage);

	useEffect(() => {
		if (
			publishedUnsupported &&
			!importingTemplate &&
			!updatingTemplate &&
			!gettingTemplate &&
			!errorGettingTemplate
		) {
			setError({
				message: unsupportedErrorMessage
			});
			resetPublishedUnsupported();
		}
	}, [publishedUnsupported, gettingTemplate, errorGettingTemplate]);

	// DELETING TEMPLATE NOTIFICATION
	const prevDeletingTemplate = usePrevious(deletingTemplate);
	useEffect(() => {
		if (prevDeletingTemplate && !deletingTemplate && !errorDeletingTemplate) {
			setNotification({
				message: translate(dict => dict.templates.templateDeleted)
			});
		}
	}, [deletingTemplate, errorDeletingTemplate]);

	const wasImportingTemplateData = usePrevious(importingTemplate);
	useEffect(() => {
		if (
			!publishedUnsupported &&
			wasImportingTemplateData &&
			!importingTemplate &&
			!errorImportingTemplate
		) {
			setNotification({
				message: translate(dict => dict.templates.templateDataImported)
			});
		}
	}, [publishedUnsupported, importingTemplate, errorImportingTemplate]);

	// Function to handle drop
	function onDragEnd({ source, destination, draggableId }: DropResult) {
		// set to `true` to enable debugger logs
		const DEBUG_MODE = false;

		if (!destination) return;

		if (DEBUG_MODE) {
			console.warn(
				'👾DEBUG MODE DRAG N DROP👾:\n\n',
				`DRAGGABLE_ID: '${draggableId}'\n`,
				`ZONE: '${source.droppableId}' => '${destination.droppableId}'\n`,
				'INDEX:',
				source.index,
				'=>',
				destination.index
			);
		}

		const betweenTemplates =
			source.droppableId.includes(DragAndDropTypes.DroppableTemplate) &&
			destination.droppableId.includes(DragAndDropTypes.DroppableTemplate) &&
			source.droppableId !== destination.droppableId;

		const sourceTemplateId = source.droppableId.split(
			`${DragAndDropTypes.DroppableTemplate}-`
		)[1];
		const destinationTemplateId = destination.droppableId.split(
			`${DragAndDropTypes.DroppableTemplate}-`
		)[1];

		const insideSameList = source.droppableId === destination.droppableId;

		const fromInitialVarOrGroupToTemplate =
			source.droppableId === DragAndDropTypes.DraggableTemplateVarOrGroup &&
			destination.droppableId.includes(DragAndDropTypes.DroppableTemplate);

		const fromGroupedToTemplate =
			source.droppableId.includes(DragAndDropTypes.DraggableTemplateGroupedVariable) &&
			destination.droppableId.includes(DragAndDropTypes.DroppableTemplate);

		const sourceTemplate = templates[sourceTemplateId];
		const destinationTemplate = templates[destinationTemplateId];

		if (fromInitialVarOrGroupToTemplate) {
			if (draggableId.includes(DragAndDropTypes.DraggableTemplateVariable)) {
				// Find variable and insert into template

				if (destinationTemplate) {
					const variable = initialVariables.find(
						variable =>
							variable.name ===
							draggableId.split(DragAndDropTypes.DraggableTemplateVariable)[0]
					);

					const variableAlreadyAdded = destinationTemplate.variables.find(
						templateVariable => templateVariable.name === variable?.name
					);
					if (variableAlreadyAdded) return;

					if (variable) {
						addVariableIntoTemplate({
							variable,
							templateId: destinationTemplateId,
							destinationIndex: destination.index
						});
					}
					// Recreate unique id for dragged cloned variable into template
					const newAvaialbleVariables = produce(initialVariables, draft => {
						draft.forEach(element => {
							const { name } = element;
							if (element.draggableId === draggableId) {
								element.draggableId = `${name}${
									DragAndDropTypes.DraggableTemplateVariable
								}${generate()}`;
							}
						});
					});
					setInitialVariables(newAvaialbleVariables);
				}
			} else {
				// Find group and insert into template
				const group = initialGroups.find(
					group =>
						group.groupName ===
						draggableId.split(DragAndDropTypes.DraggableTemplateGroup)[0]
				);
				const groupAlreadyAdded = destinationTemplate.groups.find(
					templateGroup => templateGroup.groupName === group?.groupName
				);
				if (groupAlreadyAdded) return;
				if (group) {
					addGroupIntoTemplate({
						group,
						templateId: destinationTemplateId,
						destinationIndex: destination.index
					});
				}
				// Recreate unique id for dragged cloned group into template
				const newAvaialbleGroups = produce(initialGroups, draft => {
					draft.forEach(element => {
						const { groupName } = element;
						if (element.draggableId === draggableId) {
							element.draggableId = `${groupName}${
								DragAndDropTypes.DraggableTemplateGroup
							}${generate()}`;
						}
					});
				});

				setInitialGroups(newAvaialbleGroups);
			}
			const draggedFromGroup = initialGroups.find(
				group => group.draggableId === source.droppableId
			);
			if (draggedFromGroup) {
				const variableToCompute = draggedFromGroup.variables.find(
					variable => variable.draggableId === draggableId
				);
				if (variableToCompute) {
					// template = buildTemplate(variableToCompute);
				}

				const newAvaialbleGroups = produce(initialGroups, draft => {
					draft.forEach(group => {
						if (group.draggableId === source.droppableId) {
							const { variables } = group;
							variables.forEach(variable => {
								const { name } = variable;
								if (variable.draggableId === draggableId)
									variable.draggableId = `${name}${
										DragAndDropTypes.DraggableTemplateVariable
									}${generate()}`;
							});
						}
					});
				});

				setInitialGroups(newAvaialbleGroups);
			}
		}

		// Scales for both moves from initial grouped variable->template
		// as well as for template grouped variable->template
		if (fromGroupedToTemplate) {
			const draggedVariableName = draggableId.split(
				DragAndDropTypes.DraggableTemplateVariable
			)[0];
			const { variables: destinationVariables, groups: destinationGroups } =
				destinationTemplate;

			const variableAlreadyAdded =
				destinationVariables.find(
					templateVariable => templateVariable.name === draggedVariableName
				) ||
				destinationGroups.find(group =>
					group.variables.find(variable => variable.name === draggedVariableName)
				);
			if (variableAlreadyAdded) return;

			// FromTemplate
			if (draggableId.includes('-groupedVariable')) {
				const templateId = source.droppableId.split(
					DragAndDropTypes.DraggableTemplateGroupedVariable
				)[1];
				const sourceTemplate = templates[templateId];

				const { groups } = sourceTemplate;
				const sourceGroupName = source.droppableId.split(
					DragAndDropTypes.DraggableTemplateGroupedVariable
				)[0];
				const group = groups.find(group => group.groupName === sourceGroupName);
				const variable =
					group &&
					group.variables.find(variable => variable.name === draggedVariableName);

				if (variable) {
					addVariableIntoTemplate({
						variable,
						templateId: destinationTemplateId,
						destinationIndex: destination.index
					});
				}
			} else {
				// FromInitalGroups
				const variable = initialGroups
					.find(
						group =>
							group.groupName ===
							source.droppableId.split(
								DragAndDropTypes.DraggableTemplateGroupedVariable
							)[0]
					)
					?.variables.find(variable => variable.name === draggedVariableName);

				if (variable) {
					addVariableIntoTemplate({
						variable,
						templateId: destinationTemplateId,
						destinationIndex: destination.index
					});
				}
			}
		}

		if (betweenTemplates) {
			if (draggableId.includes(DragAndDropTypes.DraggableTemplateVariable)) {
				const { variables: sourceVariables } = sourceTemplate;
				const { variables: destinationVariables, groups: destinationGroups } =
					destinationTemplate;

				const variable = sourceVariables.find(
					variable =>
						variable.name ===
						draggableId.split(DragAndDropTypes.DraggableTemplateVariable)[0]
				);
				const variableAlreadyAdded =
					destinationVariables.find(
						templateVariable => templateVariable.name === variable?.name
					) ||
					!!destinationGroups.find(
						group =>
							!!group.variables.find(
								groupedVariable => groupedVariable.name === variable?.name
							)
					);

				if (variableAlreadyAdded) return;
				if (variable)
					addVariableIntoTemplate({
						variable,
						templateId: destinationTemplateId,
						destinationIndex: destination.index
					});
			} else {
				// Find group and insert into template
				const { groups: sourceGroups } = sourceTemplate;
				const groupName = draggableId.split(DragAndDropTypes.DraggableTemplateGroup)[0];
				const group = sourceGroups.find(group => group.groupName === groupName);
				const groupAlreadyAdded = destinationTemplate.groups.find(
					templateGroup => templateGroup.groupName === group?.groupName
				);
				if (groupAlreadyAdded) return;
				if (group) {
					addGroupIntoTemplate({
						group,
						templateId: destinationTemplateId,
						destinationIndex: destination.index
					});
				}
			}
		}
		if (insideSameList) {
			sortInsideTemplate({
				sourceIndex: source.index,
				destinationIndex: destination.index,
				templateId: destinationTemplateId
			});
		}
	}

	const loading =
		loadingProjectTemplates ||
		loadingUserTemplates ||
		loadingPublicTemplates ||
		loadingVariables;

	const immediate = !(
		(IS_PUBLIC_SHARING_ENABLED ? arePublicTemplatesFetched : true) &&
		areProjectTemplatesFetched &&
		areUserTemplatesFetched &&
		areVariablesFetched
	);

	const otherTemplatesIds: OtherTemplateIdsTypes = {
		sharedWithUserIds,
		sharedWithProjectIds,
		sharedWithPublicIds
	};

	const noUserTemplates = !editableTemplatesIds.length;

	const nothingToDrag = initialVariables.length === 0 && initialGroups.length === 0;

	function createNewVariable() {
		if (projectId) {
			navigate(routes[promOrProject].variables.create(projectId));
		}
	}

	function NoTemplatesOrVariables() {
		return (
			<Flex column fullWidth justify={j => j.center} align={a => a.center}>
				<Spacer size={s => s.xxl} />
				<Svgs.EmptyStatesNoStatuses style={{ minHeight: 240 }} />
				<Spacer size={s => s.m} />
				<Typography.H3>
					{translate(dict => dict.templates.noTemplatesToDisplay)}
				</Typography.H3>

				{hasModulesAccess.projectDesign && hasVariablesWriteAccess && (
					<>
						<Spacer size={s => s.xs} />
						<Typography.Paragraph>
							{translate(dict => dict.dataset.letsCreate)}
						</Typography.Paragraph>
						<Spacer size={s => s.m} />
						<Button
							title={translate(dict => dict.addVariable.addVariableLabel)}
							onClick={createNewVariable}
						/>
						<Spacer size={s => s.m} />
					</>
				)}
			</Flex>
		);
	}

	return (
		<>
			<TemplatesPageHeader />

			<Suspend loading={loading} immediate={immediate}>
				<Grid.Container>
					<TemplatesContainer>
						{pageView === TemplatePageViews.MyTemplates &&
							(noUserTemplates && nothingToDrag ? (
								<NoTemplatesOrVariables />
							) : (
								<DragDropContext onDragEnd={onDragEnd}>
									{/* LEFT SIDE */}
									<SettingsContainer fullWidth={false}>
										<TitleWrapper>
											<Title>
												{translate(
													dict => dict.templates.variablesAndGroups
												)}
											</Title>

											{hasVariablesWriteAccess && (
												<AdvancedMenu
													offset={{
														top: 20,
														left: -170
													}}
													width={22}
													items={[
														{
															label: translate(
																dict =>
																	dict.templates
																		.makeATemplateFromAll
															),
															handler: () =>
																createTemplateFromAll({
																	variables: initialVariables,
																	groups: initialGroups,
																	owner: currentUserId
																})
														}
													]}
												/>
											)}
										</TitleWrapper>

										<Wrapper emptyList={nothingToDrag}>
											<TemplatesVariablesAndGroups
												variables={initialVariables}
												groups={initialGroups}
												writeAccess={hasVariablesWriteAccess}
											/>
										</Wrapper>
									</SettingsContainer>

									<BuilderWrapper>
										<TitleWrapper>
											<Title>
												{translate(dict => dict.templates.templates)}
											</Title>
										</TitleWrapper>
										<BuilderContainer>
											<TemplatesBuilder
												editableTemplatesIds={editableTemplatesIds}
												accessWrite={hasVariablesWriteAccess}
												setShareLevel={setShareLevel}
												setTemplateIdForImport={setTemplateIdForImport}
											/>
										</BuilderContainer>
									</BuilderWrapper>
								</DragDropContext>
							))}
						{pageView === TemplatePageViews.OtherTemplates && (
							<OtherTemplatesList
								otherTemplatesIds={otherTemplatesIds}
								accessWrite={hasVariablesWriteAccess}
								setShareLevel={setShareLevel}
								setTemplateIdForImport={setTemplateIdForImport}
							/>
						)}
					</TemplatesContainer>
				</Grid.Container>
			</Suspend>

			{/* MODALS */}
			{templateId && shareLevel && template && (
				<ShareTemplateModal currentUserId={currentUserId} shareLevel={shareLevel} />
			)}
			{templateIdForImport && (
				<ImportTemplateModal
					templateId={templateIdForImport}
					onClose={() => setTemplateIdForImport(null)}
				/>
			)}
		</>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { FormErrors, selectForm, setFormErrors, SetFormErrorsAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useFormErrors(): {
	errors: FormErrors;
	setErrors: (newValue: FormErrors) => void;
	hasErrors: () => boolean;
} {
	const dispatch = useDispatch();

	const data = useSelector(state => selectForm(state.data.forms, { initial: false }));

	const formErrors: FormErrors = data?.formErrors ?? {};

	function hasErrors(): boolean {
		for (const elementId in formErrors) {
			const errorObj = formErrors[elementId];

			if (errorObj.scaleIntervalError && errorObj.scaleIntervalError !== '') {
				return true;
			}

			if (errorObj.sliderValuesErrors) {
				for (const error of errorObj.sliderValuesErrors) {
					if (error !== '') {
						return true;
					}
				}
			}
		}

		return false;
	}

	function handler(newValue: FormErrors) {
		const formErrors: ActionPayload<SetFormErrorsAction> = { formErrors: newValue };
		dispatch(setFormErrors(formErrors));
	}

	return {
		errors: formErrors,
		setErrors: handler,
		hasErrors: hasErrors
	};
}

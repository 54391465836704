import { useMemoOnce, useSelector } from 'hooks/utils';
import { selectProjectById } from 'store/data/projects';

export function useProjectById(id: string | null) {
	/**
	 * Used like this to increase the cache size from 1 to n
	 */
	const _selectProjectById = useMemoOnce(selectProjectById);

	return useSelector(state => _selectProjectById(state.data.projects, { id }));
}

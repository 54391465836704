import { useState, useMemo, useRef } from 'react';
import { InputType } from 'types/index';
import { Modal } from 'components/UI/Modal';
import { Input } from 'components/UI/Inputs/Input';
import { isFormNameUnique } from 'helpers/forms';
import {
	useTranslation,
	useFormsBySetName,
	useForms,
	useRenameForm,
	useFormById
} from 'hooks/store';
import { useMemoOnce, useEffectOnce, useCompletedAction } from 'hooks/utils';

interface Props {
	formId: string;
	onClose: (success?: boolean) => void;
}

export function RenameFormModal({ formId, onClose }: Props) {
	const { translate } = useTranslation();

	const formsBySetName = useFormsBySetName();
	const [{ data: forms }] = useForms({ lazy: true });

	const [{ loading: renamingForm, error: errorRenamingForm }, renameForm] = useRenameForm();

	const form = useFormById(formId, { initial: true });

	const initialFormName = useMemoOnce(() => form?.name ?? '');
	const [draftFormName, setDraftFormName] = useState(initialFormName);

	const allForms = useMemo(
		() => [forms, ...Object.values(formsBySetName)].flat(),
		[forms, formsBySetName]
	);

	const inputRef = useRef<HTMLInputElement>(null);

	/**
	 * Select all text on-open
	 * improve UX - save user time
	 */
	useEffectOnce(() => inputRef.current?.select());

	useCompletedAction(renamingForm, errorRenamingForm, () => onClose(true));

	function handleSubmit() {
		if (canRenameForm()) renameForm(draftFormName.trim(), formId);
	}

	function canRenameForm() {
		const isNameValid = isStringValid(draftFormName);
		const isNameUnique = isFormNameUnique(draftFormName, {
			forms: allForms
		});
		const nameChanged = draftFormName.trim() !== initialFormName.trim();

		return isNameValid && isNameUnique && nameChanged;
	}

	function isStringValid(value: string) {
		return value.trim().length > 0;
	}

	function getInputError() {
		if (
			isStringValid(draftFormName) &&
			!isFormNameUnique(draftFormName, {
				forms: allForms,
				exclude: [initialFormName]
			})
		) {
			return translate(dict => dict.terms.errorlabelUnique);
		}
	}

	function trimFields() {
		setDraftFormName(state => state.trim());
	}

	return (
		<Modal
			size={s => s.s}
			title={translate(({ formDesigner }) => formDesigner.renameYourForm)}
			primary={{
				label: translate(({ buttons }) => buttons.rename),
				loading: renamingForm,
				disabled: !canRenameForm(),
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Input
				ref={inputRef}
				value={draftFormName}
				type={InputType.Text}
				placeholder={translate(({ formDesigner }) => formDesigner.formNamePlaceholder)}
				error={getInputError()}
				onChange={e => setDraftFormName(e.target.value)}
				onSubmit={handleSubmit}
				onBlur={trimFields}
				autoFocus
			/>
		</Modal>
	);
}

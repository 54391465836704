import { UserCard } from 'components/UI/UserCard';
import { Container } from './EligibleUser.style';
import { Button } from 'components/UI/Interactables/Button';
import { useTranslation } from 'hooks/store';

interface Props {
	emailAddress: string;
	disableInvite: boolean;
	onInvite: (emailAddress: string) => void;
}

export function EligibleUserForInvite({ emailAddress, disableInvite, onInvite }: Props) {
	const { translate } = useTranslation();

	function handleInvite() {
		if (!disableInvite) {
			onInvite(emailAddress);
		}
	}

	return (
		<Container>
			<UserCard.Basic userData={{ emailAddress }} userId={null} />
			<Button
				variant={v => v.secondary}
				title={translate(
					({ sharingConfirmNewMembers }) => sharingConfirmNewMembers.inviteUser
				)}
				onClick={handleInvite}
			/>
		</Container>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import {
	setCurrentRevision,
	selectCurrentRevision,
	SetCurrentRevisionAction,
	Revision
} from 'store/data/revisions';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

type Handler = (
	currentRevision: ActionPayload<SetCurrentRevisionAction>['currentRevision']
) => void;

export function useCurrentRevision(): LocalOperationResult<Revision | null, Handler> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectCurrentRevision(state.data.revisions));

	function handler(currentRevision: ActionPayload<SetCurrentRevisionAction>['currentRevision']) {
		dispatch(setCurrentRevision({ currentRevision }));
	}

	return [data, handler];
}

import { useState, useEffect } from 'react';
import { PatientEditEntryForm, PatientMessage } from 'components/Patient';
import { PatientLoginParams } from 'store/auth';
import { Container } from 'pages/Dataset/AddEditEntry/AddEditEntryPage.style';
import { Suspend } from 'components/UI/Suspend';
import {
	useEntry,
	useVariables,
	useDependencies,
	useSeriesEntriesCount,
	useFormId,
	useForms,
	useForm
} from 'hooks/store';

interface Props {
	params: PatientLoginParams;
}

export function PatientEditEntry({ params }: Props) {
	const [{ data: entry, loading: loadingEntry, error: errorLoadingEntry }] = useEntry();
	const [{ loading: loadingVariables, fetched: areVariablesFetched }] = useVariables({
		initial: true,
		lazy: true
	});

	/**
	 * NEEDS TO BE HERE TO APPLY DEPENDENCY RULES OVER THE ENTRY FIELDS
	 */
	useDependencies();

	/**
	 * NEEDS TO BE HERE TO FETCH SERIES ENTRIES COUNT
	 */
	useSeriesEntriesCount();

	const hasFormId = !!params.formId;

	const [formId, setFormId] = useFormId();
	const [{ loading: loadingForm, error: erroLoadingForm }] = useForm({
		lazy: !hasFormId
	});
	const [{ data: forms, loading: loadingForms, fetched: areFormsFetched }] = useForms({
		lazy: hasFormId
	});

	const [render, setRender] = useState(false);
	const [entryExpired, setEntryExpired] = useState(false);
	const [thankYouMessage, setThankYouMessage] = useState(false);

	// DEBOUNCE RENDER
	useEffect(() => {
		if (formId) setRender(true);
	}, [formId]);

	// SELECT THE FIRST FORM ON-LOAD (IF ANY)
	useEffect(() => {
		if (!(areFormsFetched && !render && !hasFormId)) return;

		const firstFormId = forms.find(form => form.active)?.id;

		// CUSTOM FORM WAIT UNTIL FORM ID IS SET DEBOUNCE IT WITH STATE
		if (firstFormId && firstFormId !== formId) {
			setFormId(firstFormId);
		}
		// DEFAULT FORM - NO NEED TO WAIT - RENDER
		else {
			setRender(true);
		}
	}, [forms, areFormsFetched, render]);

	useEffect(() => {
		if (errorLoadingEntry || erroLoadingForm) setEntryExpired(true);
	}, [errorLoadingEntry, erroLoadingForm]);

	function handleThankYouMessage() {
		setThankYouMessage(true);
		localStorage.clear();
	}

	if (entryExpired) return <PatientMessage message={m => m.entryExpired} />;

	if (thankYouMessage) return <PatientMessage message={m => m.thankYouMessage} />;

	const suspendProps = {
		loading: loadingVariables || loadingEntry || loadingForms || loadingForm,
		immediate: !areVariablesFetched || !entry || !areFormsFetched
	};

	return (
		<Suspend loading={suspendProps.loading} immediate={suspendProps.immediate}>
			<Container>
				{render && (
					<PatientEditEntryForm
						renderFormsDrawer={!hasFormId}
						handleThankYouMessage={handleThankYouMessage}
					/>
				)}
			</Container>
		</Suspend>
	);
}

import { useEffect, useMemo } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getCollaborators,
	selectCollaboratorsFetched,
	CollaboratorsRichData
} from 'store/data/collaborators';
import { buildCollaboratorsRichData } from 'helpers/collaborators';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';
import { useCollaboratorsData } from './useCollaboratorsData';

interface Options {
	lazy?: boolean;
}

export function useCollaborators(options?: Options): OperationResult<CollaboratorsRichData> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const fetched = useSelector(state => selectCollaboratorsFetched(state.data.collaborators));

	const collaboratorsData = useCollaboratorsData();

	const data = useMemo(() => buildCollaboratorsRichData(collaboratorsData), [collaboratorsData]);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_COLLABORATORS, {
		projectId
	});

	useEffect(() => {
		if (!error && !loading && !fetched && isProjectValid && !options?.lazy) {
			handler();
		}
	}, [error, loading, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getCollaborators());
	}

	return [{ loading, error, data, fetched }, handler];
}

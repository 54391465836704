import produce from 'immer';

import initialState from './initialState';
import { ActionTypes, Actions, State } from './types';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.GET_LANGUAGE: {
			const { translations } = action.payload;

			return produce(state, draft => {
				draft.translations = translations;
			});
		}

		case ActionTypes.SET_LANGUAGE: {
			const { language } = action.payload;

			return produce(state, draft => {
				draft.language = language;
			});
		}

		default: {
			return state;
		}
	}
};

import { createContext } from 'react';

import { VariableCategory } from 'api/data/variables';
import { DependencyActions } from 'store/data/dependencies';
import { GenericMap } from 'types/index';

interface Data {
	categoriesMapByVariableName: GenericMap<GenericMap<VariableCategory>>;
}

export const DependenciesContext = createContext<
	| {
			actions: DependencyActions;
			data: Data;
	  }
	| undefined
>(undefined);

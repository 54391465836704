import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { useTranslation } from 'hooks/store';
import type { TableFilter } from 'store/ui/tables/types';
import { TableFilterType } from 'types/index';

export function isFilterActive(filter: TableFilter) {
	if (filter.filterType === TableFilterType.Text) {
		return !!filter.text;
	}
	if (filter.filterType === TableFilterType.Numeric) {
		return !!filter.value;
	}
	if (filter.filterType === TableFilterType.Checkbox) {
		return filter.active.length > 0;
	}
	if (filter.filterType === TableFilterType.Date) {
		return filter.valid && (filter.from || filter.to || filter.value);
	}
	return false;
}

interface DisabledTextComponentProps {
	text?: string;
	showNotAvailable?: boolean;
}
export function DisabledTextComponent({ text, showNotAvailable }: DisabledTextComponentProps) {
	const { translate } = useTranslation();
	const notAvailableText = translate(({ errors }) => errors.general.notAvailable);

	return (
		<Typography.Paragraph color={Colors.text.disabled}>
			{text ? text : showNotAvailable ? notAvailableText : ''}
		</Typography.Paragraph>
	);
}

import styled, { css } from 'styled-components';

import { getTypographyCSS, FontWeights } from 'components/UI/Typography';
import { Colors } from 'environment';

export const Wrapper = styled.div`
	.table-row:hover .icons-container {
		visibility: visible;
	}
`;

export const IconsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
`;

export const BadgeContainer = styled.div`
	display: flex;
`;

interface BadgeProps {
	color: string;
}
export const Badge = styled.div<BadgeProps>`
	${getTypographyCSS(t => t.Hint)};

	padding: 0.2rem 0.8rem;
	border-radius: 0.4rem;
	color: ${Colors.text.main};
	font-weight: ${FontWeights.bold};
	background-color: ${({ color }) => color};

	/* ellipsis effect */
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

interface CircleProps {
	color?: string;
	size?: number;
}
export const Circle = styled.span<CircleProps>`
	display: block;
	border-radius: 50%;
	background: ${({ color }) => (color ? color : 'blue')};

	${({ size = 1.6 }) => css`
		height: ${size}rem;
		width: ${size}rem;
		min-width: ${size}rem;
	`}
`;

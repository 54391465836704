import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setTemplateSearchTerm, SetSearchTermAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useTemplatesSearchTerm(): LocalOperationResult<
	string,
	(payload: ActionPayload<SetSearchTermAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.templates.metadata.searchTerm);

	function handler(payload: ActionPayload<SetSearchTermAction>) {
		dispatch(setTemplateSearchTerm(payload));
	}

	return [data, handler];
}

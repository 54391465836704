import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROUTE_QUERY } from 'types/navigation';
import {
	PendingInvitationsModal,
	SubscriptionDetails,
	SubscriptionUsers,
	ChangePlanModal
} from 'components/Account';
import { NotificationBodyType } from 'store/data/notifications';
import { Container } from './AccountUMSubscriptionPage.style';
import { Header } from 'components/Header';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { Suspend } from 'components/UI/Suspend';
import {
	useTranslation,
	useSubscriptionUsersSearchTerm,
	useSubscription,
	useAccount,
	useAPIGetPendingInvitations,
	useNewestNotification,
	useSubscriptionRules
} from 'hooks/store';
import { useMatchEnterpriseAdmin, useNavigation } from 'hooks/navigation';
import { HSpacer } from 'components/UI/HSpacer';

export function AccountUMSubscriptionPage() {
	const { translate } = useTranslation();

	const [searchTerm, setSearchTerm] = useSubscriptionUsersSearchTerm();

	const { navigate, routes } = useNavigation();

	const [showPendingTransferModal, setShowPendingTransferModal] = useState(false);
	const [changePlanModalVisible, setChangePlanModalVisible] = useState(false);
	const [showAddUserModal, setShowAddUserModal] = useState(false);

	const isOnEnterpriseAdminPage = useMatchEnterpriseAdmin();

	const [
		{
			data: {
				details,
				users,
				subscriptionTypes: { isLedidiEnterprise, isNonSubscriber },
				userTypesAndRoles: {
					ledidiCore,
					ledidiEnterprise: {
						isUser: isEnterpriseUser,
						modules: { hasEnterpriseAdminRights }
					},
					isPaidSubscriptionOwner
				}
			},

			loading: subscriptionLoading,
			fetched: subscriptionFetched
		}
	] = useSubscription();

	const { canChangePlan, isSubscriptionUpdating, showEnterpriseAdminRoute } =
		useSubscriptionRules();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const upgradeParam = searchParams.get(ROUTE_QUERY.Upgrade);
		if (upgradeParam) setChangePlanModalVisible(true);
	}, []);

	useEffect(() => {
		if (showEnterpriseAdminRoute && !isOnEnterpriseAdminPage) {
			navigate(routes.enterpriseAdmin.subscription);
		}
	}, [showEnterpriseAdminRoute, isOnEnterpriseAdminPage]);

	const [{ loading: accountLoading, fetched: accountFetched }] = useAccount();
	const [{ data: pendingInvitations }, getPendingInvitations] = useAPIGetPendingInvitations();

	const [{ data: newestSubscriptionInvitation }] = useNewestNotification(
		NotificationBodyType.SubscriptionInvitation
	);
	const [{ data: newestSubscriptionInvitationCancelled }] = useNewestNotification(
		NotificationBodyType.SubscriptionInvitationRemoved
	);

	useEffect(() => {
		if (pendingInvitations.length && subscriptionFetched) setShowPendingTransferModal(true);
		else setShowPendingTransferModal(false);
	}, [pendingInvitations, subscriptionFetched]);

	useEffect(() => {
		if (subscriptionFetched) {
			getPendingInvitations();
		}
	}, [subscriptionFetched]);

	useEffect(() => {
		// in case we get a notification with a new transfer invitation, refetch the pending invitations
		if (newestSubscriptionInvitation) {
			getPendingInvitations();
		}
	}, [newestSubscriptionInvitation]);

	useEffect(() => {
		// if a transfer invitation had been cancelled and the PendingTransferModal is open, refresh the pending invitations
		if (newestSubscriptionInvitationCancelled && showPendingTransferModal) {
			getPendingInvitations();
		}
	}, [newestSubscriptionInvitationCancelled]);

	const loadingSubscriptionsInitial = subscriptionLoading && !subscriptionFetched;

	const loading = loadingSubscriptionsInitial || accountLoading;
	const immediate = !(subscriptionFetched && accountFetched);

	const mainHeaderProps = isOnEnterpriseAdminPage
		? null
		: {
				leftComponent: translate(({ accountUM }) => accountUM.subscriptionManagementTitle),
				rightComponent: null
		  };

	return (
		<>
			<Header.Main {...mainHeaderProps} />
			<Header.Navigation
				rightComponent={
					<Flex>
						{(ledidiCore.isOwner || (isLedidiEnterprise && !isEnterpriseUser)) && (
							<div style={{ width: '24rem' }}>
								<SearchInput
									usedInHeader
									placeholder={translate(
										dict => dict.accountUM.subscription.searchUser
									)}
									term={searchTerm}
									onChangeTerm={setSearchTerm}
								/>
							</div>
						)}
						{hasEnterpriseAdminRights && (
							<>
								<HSpacer size={s => s.m} />
								<Button
									title={translate(
										({ accountUM }) => accountUM.subscription.addNewUser
									)}
									onClick={() => setShowAddUserModal(!showAddUserModal)}
									minWidth={14}
									paddingOffset={{ x: 2.4 }}
								/>
							</>
						)}
						{
							canChangePlan ? (
								<Button
									hasPointer
									title={
										isNonSubscriber
											? translate(({ buttons }) => buttons.subscribe)
											: translate(
													({ accountUM }) =>
														accountUM.changePlan.changePlan
											  )
									}
									disabled={isSubscriptionUpdating}
									marginOffset={{
										left: ledidiCore.isOwner ? 2 : 0
									}}
									onClick={() => setChangePlanModalVisible(true)}
								/>
							) : undefined
							// <ChargebeeButton registerChargebeeFirst />
						}
					</Flex>
				}
			/>

			<Suspend loading={loading} immediate={immediate}>
				<Container faded={subscriptionLoading}>
					<Header.Title
						title={
							isOnEnterpriseAdminPage
								? translate(
										({ enterpriseAdmin }) =>
											enterpriseAdmin.header.menuTabs.users
								  )
								: translate(({ accountUM }) => accountUM.tabs.subscription)
						}
					/>
					<Grid.Container>
						{!hasEnterpriseAdminRights && (
							<>
								<Spacer size={s => s.m} />
								<SubscriptionDetails
									setChangePlanModalVisible={visible =>
										setChangePlanModalVisible(visible)
									}
								/>
								<Spacer size={s => s.m} />
							</>
						)}
						{(users.length === 2 ||
							isPaidSubscriptionOwner ||
							(isLedidiEnterprise && !isEnterpriseUser) ||
							details?.licenceQuantity === 1) &&
							!isSubscriptionUpdating && (
								<SubscriptionUsers
									showAddUserModal={showAddUserModal}
									setShowAddUserModal={setShowAddUserModal}
								/>
							)}
					</Grid.Container>
				</Container>
			</Suspend>

			{/* PENDING TRANSFERS MODAL */}
			{showPendingTransferModal && (
				<PendingInvitationsModal
					pendingInvitations={pendingInvitations}
					onClose={() => setShowPendingTransferModal(false)}
					onRespond={accepted => {
						if (accepted) setChangePlanModalVisible(false);
					}}
				/>
			)}
			{changePlanModalVisible && canChangePlan && (
				<ChangePlanModal onClose={() => setChangePlanModalVisible(false)} />
			)}
		</>
	);
}

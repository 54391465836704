import { parseAnalysisNumber } from 'store/data/analyses/parsers';
import { FormatRule } from './KaplanPatientsTableV2';

export function formatTableCell(value: number, formatRule: FormatRule) {
	if (formatRule === FormatRule.Default) return value.toFixed(0);

	if (formatRule === FormatRule.Scientific) {
		const { operator, value: parsedValue, tenExponent } = parseAnalysisNumber(value);

		let result = '';

		if (operator !== '=') result += `${operator} `;

		result += parsedValue;

		return (
			<div
				style={{
					height: '22px',
					maxHeight: '22px',
					display: 'flex'
				}}
			>
				{result}
				{tenExponent !== 0 && (
					<>
						*10<sup style={{ lineHeight: 1, alignSelf: 'unset' }}>{tenExponent}</sup>
					</>
				)}
			</div>
		);
	}

	return value.toFixed(2);
}

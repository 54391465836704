import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: auto;
	max-width: 42rem;
	margin-bottom: 4rem;
`;

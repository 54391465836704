import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes } from 'store/account/payments';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import {
	SubscriptionPayments,
	getSubscriptionPayments,
	selectSubscriptionPayments
} from 'store/account/payments';

export function useSubscriptionPayments(lazy?: boolean): OperationResult<SubscriptionPayments> {
	const dispatch = useDispatch();

	const payments = useSelector(state => selectSubscriptionPayments(state.account.payments));

	const fetched = payments !== null;

	const [{ loading, error }] = useActivity(ActionTypes.GET_SUBSCRIPTION_PAYMENTS);

	useEffect(() => {
		if (!lazy && !fetched && !loading && !error) handler();
	}, [fetched, loading, error, lazy]);

	function handler() {
		dispatch(getSubscriptionPayments());
	}

	return [{ loading, error, data: payments, fetched }, handler];
}

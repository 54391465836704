import { useEffect, useRef } from 'react';

import { TransferEntriesOwnershipContext, ExpandGroupsProvider } from 'contexts';
import { Entries, NoDataset } from 'components/Dataset';
import {
	DatasetEntriesPreviewModal,
	PromptToCancelModal,
	TransferEntriesOwnershipModal
} from 'components/Dataset/Modals';
import { ActionTypes, DEFAULT_GET_ENTRIES_SORT, TransferOwnershipState } from 'store/data/entries';
import { DatasetPageHeader } from './DatasetPageHeader';
import { getTransferOwnershipActions } from './getTransferOwnershipActions';
import { Container, TopCol } from './DatasetPage.style';
import { Suspend } from 'components/UI/Suspend';
import { usePrompt_NEW } from 'hooks/navigation';
import {
	usePermissions,
	useStatuses,
	useForms,
	useDependencies,
	useRefetchEntries,
	useUpdateVariable,
	useTransactionActivity,
	useVariables,
	useEntries,
	useProject,
	useProjectId,
	useTableSort,
	useFilters
} from 'hooks/store';
import { useMutableState } from 'hooks/utils';
import { TableName } from 'types/index';
import { parseApiSortToActiveSort } from 'helpers/entries';
import { Filters } from 'components/Analysis';
import { CSSGrid } from 'components/UI/CSSGrid';

export function DatasetPage() {
	const { fetched: arePermissionsFetched, loading: loadingPermissions } = usePermissions();
	const filterDrawerContainerRef = useRef<HTMLDivElement>(null);

	const [projectId] = useProjectId();
	// NEEDS TO BE HERE FOR: TABLE COLUMN - STATUS - FILTERS
	const [{ abortController: getStatusesAbortController }, getStatuses] = useStatuses({
		lazy: true
	});
	// FETCH FORMS IN THE BACKGROUND - IMPROVE UX - USED FOR ADD/EDIT ENTRY
	const [{ abortController: getFormsAbortController }, getForms] = useForms({ lazy: true });
	// FETCH DEPENDENCIES IN THE BACKGROUND - IMPROVE UX - USED FOR ADD/EDIT ENTRY
	const [{ abortController: getDependenciesAbortController }, getDependencies] = useDependencies({
		lazy: true
	});

	const [shouldRefetchEntries] = useRefetchEntries();

	const [{ loading: updatingVariable }] = useUpdateVariable();
	const [{ loading: recalculatingEntries }] = useTransactionActivity(
		ActionTypes.RECALCULATING_ENTRIES
	);

	// FILTERS SIDE DRAWER
	const [{ areFiltersOpen, areFiltersActive }] = useFilters();

	const [
		{
			data: { hasVariables }
		}
	] = useVariables({ initial: true, lazy: true });

	const [
		{
			loading: loadingEntries,
			data: entries,
			fetched: areEntriesFetched,
			abortController: getEntriesAbortController
		},
		getEntries
	] = useEntries({ filtered: areFiltersActive });
	const [{ loading: loadingProject }] = useProject();

	const hasEntries = entries.length > 0;
	const hasVariablesAndEntries = hasVariables && hasEntries;

	const { activeSort, handleSort } = useTableSort(
		TableName.Entries,
		parseApiSortToActiveSort(DEFAULT_GET_ENTRIES_SORT)
	);

	// FIRE DATASET ROWS REFETCH ONLY IF ENTRIES ARE FETCHED - AVOID FETCHING TWICE
	useEffect(() => {
		if (areEntriesFetched && shouldRefetchEntries) getEntries();
	}, [areEntriesFetched, shouldRefetchEntries]);

	// FETCH ON MOUNT
	useEffect(() => {
		if (projectId) {
			getForms();
			getStatuses();
		}
		if (hasVariables) {
			getDependencies();
		}
	}, [projectId, hasVariables]);

	// CANCEL ONGOING REQUESTS ON DISMOUNT
	useEffect(() => {
		return () => {
			getEntriesAbortController?.abort();
			getFormsAbortController?.abort();
			getDependenciesAbortController?.abort();
			getStatusesAbortController?.abort();
		};
	}, []);

	const loadingEntriesInitial = loadingEntries && !areEntriesFetched;

	// TRANSFER ENTRIES OWNERSHIP STATE
	const initialTransferOwnershipState: TransferOwnershipState = {
		isTransferOwnership: false,
		selectedEntries: [],
		showCancelModal: false,
		showPreviewModal: false,
		showTransferModal: false
	};

	const [transferOwnership, setTransferOwnership] = useMutableState(
		initialTransferOwnershipState
	);

	const { transferOwnershipActions } = getTransferOwnershipActions({
		transferOwnership,
		setTransferOwnership
	});

	const { promptVisible, rejectPrompt, confirmPrompt } = usePrompt_NEW(
		transferOwnership.selectedEntries.length > 0
	);

	function onConfirmPromptToCancelModal() {
		setTransferOwnership(state => {
			state.isTransferOwnership = false;
			state.showCancelModal = false;
			state.selectedEntries = [];
		});
	}

	function resetTransferOwnership() {
		setTransferOwnership(initialTransferOwnershipState);
	}

	const entryIdsToTransfer = transferOwnership.selectedEntries
		.filter(selectedEntry => selectedEntry.confirmed)
		.map(selectedEntry => selectedEntry.entryId);

	const tableColumnSizes = areFiltersOpen ? { xl: 12, l: 9, m: 0 } : { xl: 16, l: 12, m: 6 };

	const filtersDrawerColumnSizes = areFiltersOpen ? { xl: 4, l: 3, m: 6 } : { xl: 0, m: 0, l: 0 };

	return (
		<TransferEntriesOwnershipContext.Provider
			value={{
				actions: transferOwnershipActions
			}}
		>
			<ExpandGroupsProvider>
				<DatasetPageHeader />

				<Suspend
					loading={loadingEntriesInitial || loadingProject || loadingPermissions}
					immediate={!arePermissionsFetched || !areEntriesFetched}
				>
					<Container
						disabled={loadingEntries || updatingVariable || recalculatingEntries}
					>
						<TopCol displayGridColumn0={false} sizes={filtersDrawerColumnSizes}>
							{areFiltersOpen && (
								<Filters filterDrawerContainerRef={filterDrawerContainerRef} />
							)}
						</TopCol>
						<CSSGrid.Col sizes={tableColumnSizes}>
							{/* PRJCTS-7763 below div is needed for Selenium tests */}
							<div style={{ display: 'none' }} data-test-id="DatasetPage"></div>
							{hasVariablesAndEntries ? (
								<Entries
									filterDrawerContainerRef={filterDrawerContainerRef}
									activeSort={activeSort}
									handleSort={handleSort}
								/>
							) : (
								<NoDataset />
							)}
						</CSSGrid.Col>
					</Container>
				</Suspend>

				{/* MODALS */}
				{transferOwnership.showPreviewModal && (
					<DatasetEntriesPreviewModal handleSort={handleSort} activeSort={activeSort} />
				)}
				{transferOwnership.showCancelModal && (
					<PromptToCancelModal
						onConfirm={onConfirmPromptToCancelModal}
						onClose={() =>
							setTransferOwnership(state => {
								state.showCancelModal = false;
							})
						}
					/>
				)}
				{transferOwnership.showTransferModal && (
					<TransferEntriesOwnershipModal
						entryIds={entryIdsToTransfer}
						onClose={success => {
							if (success) return resetTransferOwnership();

							setTransferOwnership(state => {
								state.showTransferModal = false;
							});
						}}
					/>
				)}
				{promptVisible && (
					<PromptToCancelModal onConfirm={confirmPrompt} onClose={rejectPrompt} />
				)}
			</ExpandGroupsProvider>
		</TransferEntriesOwnershipContext.Provider>
	);
}

import { createSelector } from 'reselect';

import { State, DependenciesData } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getByProjectId({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectDependenciesData = createSelector([getByProjectId], byProjectId => {
	const output: DependenciesData = {
		active: true,
		dependencies: [],
		dependenciesBySetName: {}
	};

	if (byProjectId) {
		const { active, dependencies, bySetName } = byProjectId;

		output.active = active;
		output.dependencies = dependencies;
		output.dependenciesBySetName = bySetName;
	}

	return output;
});

export const selectAreDependenciesFetched = createSelector(
	[getByProjectId],
	byProjectId => !!byProjectId?.fetched
);

export const selectDependenciesTableParams = createSelector([getByProjectId], byProjectId => {
	if (byProjectId)
		return {
			pageIndex: byProjectId.metadata.pageIndex,
			pageSize: byProjectId.metadata.pageSize
		};

	return { pageIndex: 0, pageSize: 10 };
});

import { Icon } from 'components/UI/Icons';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Svgs } from 'environment';
import { withMemo } from 'helpers/HOCs';

interface NotificationAvatarProps {
	firstName?: string;
	lastName?: string;
	userId: string;
}

function Component({ userId, firstName, lastName }: NotificationAvatarProps) {
	if (firstName || lastName)
		return (
			<Avatar
				size={s => s.s}
				userId={userId}
				data={{ userFirstName: firstName, userSirName: lastName }}
			/>
		);

	return (
		<Icon
			svg={Svgs.LedidiLogoColored}
			size={s => s.l}
			style={{ border: 'solid 1px #f2f6f8' }}
		/>
	);
}

export const NotificationAvatar = withMemo(Component, ['firstName', 'lastName', 'userId']);

import { useEffect, useMemo } from 'react';
import { JADBioAnalysis, JADBioAnalysisModels } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { OperationResult } from 'hooks/store/types';
import { useRefetchAnalyses } from './useRefetchAnalyses';
import {
	ActionTypes,
	initiateJADBioClassificationModel,
	initiateJADBioRegressionModel,
	initiateJADBioSurvivalAnalysisModel,
	getJADBioAnalysisResult
} from 'store/addons/jadbio';
import { useGetJADBioProjectId } from 'hooks/store/addons/JADBio/useGetJADBioProjectId';
import { useInitiateJADBioDataset } from 'hooks/store/addons/JADBio/useInitiateJADBioDataset';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, usePrevious } from 'hooks/utils';

export function useJADBioAnalysis(
	analysis: JADBioAnalysis
): OperationResult<JADBioAnalysis, (resumeProgress?: boolean) => void> {
	const {
		id: analysisId,
		options: { open },
		input: { model },
		output: { dataset }
	} = analysis;

	const [{ data: JADBioProjectIdFetched }] = useGetJADBioProjectId();

	const [{ data: datasetInitialized }, initializeJADBioDataset] =
		useInitiateJADBioDataset(analysisId);

	useEffect(() => {
		if (JADBioProjectIdFetched && !datasetInitialized) {
			initializeJADBioDataset();
		}
	}, [JADBioProjectIdFetched, datasetInitialized]);

	const isInputValid = useMemo(() => isAnalysisInputValid(analysis), [analysis]);

	const dispatch = useDispatch();

	const [shouldRefetchAnalyses] = useRefetchAnalyses();

	const [
		{ loading: analysisResultLoading, error: analysisResultError },
		resetAnalysisResultError
	] = useActivity(ActionTypes.GET_JADBIO_ANALYSIS_RESULT, analysisId + '_' + model);
	const [
		{ loading: classificationLoading, error: classificationError },
		resetClassificationError
	] = useActivity(ActionTypes.INITIATE_JADBIO_CLASSIFICATION_MODEL, analysisId + '_' + model);
	const [{ loading: survivalAnalysisLoading, error: survivalAnalysisError }, resetSurvivalError] =
		useActivity(ActionTypes.INITIATE_JADBIO_SURVIVAL_ANALYSIS_MODEL, analysisId + '_' + model);
	const [{ loading: regressionLoading, error: regressionError }, resetRegressionError] =
		useActivity(ActionTypes.INITIATE_JADBIO_REGRESSION_MODEL, analysisId + '_' + model);

	const analysisLoading = useMemo(
		() =>
			analysisResultLoading ||
			(classificationLoading &&
				analysis.input.model === JADBioAnalysisModels.Classification) ||
			(survivalAnalysisLoading &&
				analysis.input.model === JADBioAnalysisModels.SurvivalAnalysis) ||
			(regressionLoading && analysis.input.model === JADBioAnalysisModels.Regression),
		[analysisResultLoading, classificationLoading, survivalAnalysisLoading, regressionLoading]
	);

	const analysisError = useMemo(
		() =>
			analysisResultError ||
			(classificationError && analysis.input.model === JADBioAnalysisModels.Classification) ||
			(survivalAnalysisError &&
				analysis.input.model === JADBioAnalysisModels.SurvivalAnalysis) ||
			(regressionError && analysis.input.model === JADBioAnalysisModels.Regression),
		[analysisResultError, classificationError, survivalAnalysisError, regressionError, analysis]
	);

	const analysisFetched = useMemo(
		() => !!(model && dataset[model] && dataset[model].summary.length),
		[model, dataset]
	);

	function resetAnalysisError() {
		resetAnalysisResultError();
		resetClassificationError();
		resetSurvivalError();
		resetRegressionError();
	}

	const analysisResultsReadyToFetch = useMemo(() => {
		return (
			open && JADBioProjectIdFetched && datasetInitialized && !analysisLoading && isInputValid
		);
	}, [open, JADBioProjectIdFetched, datasetInitialized, analysisLoading, isInputValid]);

	const JADBioDatasetCanBeReinitialized = useMemo(
		() => open && JADBioProjectIdFetched && datasetInitialized && !analysisLoading,
		[open, JADBioProjectIdFetched, datasetInitialized, analysisLoading]
	);

	// When new entries or filters are being added/removed, shouldRefetchAnalyses is
	// changing to true. This should trigger a new initialization of the
	// JADBio dataset followed by a new JADBio generation of analysis
	useEffect(() => {
		if (shouldRefetchAnalyses && JADBioDatasetCanBeReinitialized) {
			initializeJADBioDataset();
		}
	}, [shouldRefetchAnalyses, analysisResultsReadyToFetch]);

	const wasShouldRefetchAnalysis = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (
			!shouldRefetchAnalyses &&
			wasShouldRefetchAnalysis &&
			datasetInitialized &&
			analysisResultsReadyToFetch &&
			analysisFetched
		) {
			getJADBioAnalyses();
		}
	}, [datasetInitialized, analysisResultsReadyToFetch, shouldRefetchAnalyses, analysisFetched]);

	// handler to fetch jadbio analysis
	function handler(resumeProgress?: boolean) {
		if (analysisResultsReadyToFetch) {
			getJADBioAnalyses(resumeProgress);
		}
	}

	function getJADBioAnalyses(resumeProgress?: boolean) {
		resetAnalysisError();
		// If analysis generation is already in progress resume getJADBioAnalysisResult call
		if (resumeProgress && model && dataset[model])
			dispatch(getJADBioAnalysisResult(analysisId, dataset[model].jadBioAnalysisId, model));
		else if (model === JADBioAnalysisModels.Classification)
			dispatch(initiateJADBioClassificationModel(analysisId, model));
		else if (model === JADBioAnalysisModels.SurvivalAnalysis)
			dispatch(initiateJADBioSurvivalAnalysisModel(analysisId, model));
		else if (model === JADBioAnalysisModels.Regression)
			dispatch(initiateJADBioRegressionModel(analysisId, model));
	}

	return [
		{
			data: analysis,
			loading: analysisLoading,
			error: analysisError,
			fetched: analysisFetched
		},
		handler
	];
}

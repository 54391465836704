import { cloneDeep, orderBy } from 'lodash';
import { nanoid as generate } from 'nanoid';
import {
	Organization,
	Collaborator,
	CollaboratorsData,
	CollaboratorsOrder,
	CollaboratorsOrderItem,
	CollaboratorsDataArray,
	OrganizationData,
	CollaboratorsDataArrayItem,
	CollaboratorsRichData,
	StoreCollaboratorsData,
	CollaboratorsDataLocation
} from 'store/data/collaborators';
import type { ActiveSort } from 'store/ui/tables/types';
import { RolesMap } from 'store/data/roles';
import { hasMatches } from 'helpers/strings';

export function initOrganization(fields: Partial<Organization> = {}): Organization {
	const organization: Organization = {
		id: fields.id ?? `draft_${generate()}`,
		name: fields.name ?? '',
		collaborators: fields.collaborators ?? []
	};

	return organization;
}

export function initCollaboratorsData(fields: Partial<CollaboratorsData> = {}): CollaboratorsData {
	return {
		collaboratorsMap: fields.collaboratorsMap ?? {},
		organizationsMap: fields.organizationsMap ?? {},
		order: fields.order ?? []
	};
}

export function initStoreCollaboratorsData(
	fields: Partial<StoreCollaboratorsData> = {}
): StoreCollaboratorsData {
	return {
		collaborators: fields.collaborators ?? {
			byId: {}
		},
		organizations: fields.organizations ?? {
			byId: {},
			default: {
				id: null
			}
		},
		order: fields.order ?? []
	};
}

export function collaboratorsOrderIterator(
	order: CollaboratorsOrder,
	getCollaboratorId: (collaboratorId: string, index: number) => void,
	getOrganizationId: (organizationId: string, index: number) => void
) {
	order.forEach((item, index) => {
		if (isCollaboratorOrderItem(item)) {
			const { collaborator: collaboratorId } = item;

			getCollaboratorId(collaboratorId, index);
		}
		if (isOrganizationOrderItem(item)) {
			const { organization: organizationId } = item;

			getOrganizationId(organizationId, index);
		}
	});
}

export function isCollaboratorOrderItem(
	item: CollaboratorsOrderItem
): item is { collaborator: string } {
	return 'collaborator' in item;
}

export function isOrganizationOrderItem(
	item: CollaboratorsOrderItem
): item is { organization: string } {
	return 'organization' in item;
}

export function collaboratorsDataArrayIterator(
	collaboratorsDataArray: CollaboratorsDataArray,
	getCollaborator: (collaborator: Collaborator, index: number) => void,
	getOrganizationData: (organizationData: OrganizationData, index: number) => void
) {
	collaboratorsDataArray.forEach((item, index) => {
		if (isCollaborator(item)) return getCollaborator(item, index);

		if (isOrganizationData(item)) return getOrganizationData(item, index);
	});
}

export function isCollaborator(item: CollaboratorsDataArrayItem): item is Collaborator {
	const keys: (keyof Collaborator)[] = ['userId', 'emailAddress'];

	return keys.some(key => key in item);
}

export function isOrganizationData(item: CollaboratorsDataArrayItem): item is OrganizationData {
	const keys: (keyof OrganizationData)[] = ['id', 'name', 'collaborators'];

	return keys.every(key => key in item);
}

/**
 * It iterates over the collaborators data list based on the `order` prop with automatic casting of types.
 *
 * @param collaboratorsDataArray `collaboratorsMap`, `organizationsMap`, `order`
 * @param getCollaborator iteratable collaborator
 * @param getOrganizationData iteratable organization
 *
 * @returns an array of JSX Elements returned inside the `getCollaborator` and `getOrganizationData`
 */
export function collaboratorsDataArrayJSXIterator(
	collaboratorsDataArray: CollaboratorsDataArray,
	getCollaborator: (collaborator: Collaborator, index: number) => React.ReactNode,
	getOrganizationData: (organizationData: OrganizationData, index: number) => React.ReactNode
) {
	const JSXElements: React.ReactNode[] = [];

	collaboratorsDataArrayIterator(
		collaboratorsDataArray,
		(collaborator, index) => {
			const JSXElement = getCollaborator(collaborator, index);

			if (JSXElement) JSXElements.push(JSXElement);
		},
		(organizationData, index) => {
			const JSXElement = getOrganizationData(organizationData, index);

			if (JSXElement) JSXElements.push(JSXElement);
		}
	);

	return JSXElements;
}

export function createCollaboratorsOrderItem(name: string) {
	return {
		collaborator: () => ({ collaborator: name }),
		organization: () => ({ organization: name })
	};
}

export function buildCollaboratorsRichData(
	collaboratorsData: CollaboratorsData
): CollaboratorsRichData {
	const {
		collaboratorsMap: originalCollaboratorsMap,
		organizationsMap: originalOrganizationsMap,
		order: originalOrder
	} = collaboratorsData;

	const collaborators: Collaborator[] = [];
	const collaboratorsMap = cloneDeep(originalCollaboratorsMap);
	const collaboratorIds: string[] = [];

	const organizations: Organization[] = [];
	const organizationsMap = cloneDeep(originalOrganizationsMap);
	const organizationIds: string[] = [];
	const organizationNames: string[] = [];

	const collaboratorsDataArray: CollaboratorsDataArray = [];

	const order = cloneDeep(originalOrder);

	collaboratorsOrderIterator(
		order,
		collaboratorId => {
			const collaborator = collaboratorsMap[collaboratorId];

			collaborators.push(collaborator);
			collaboratorIds.push(collaborator.userId);
			collaboratorsDataArray.push(collaborator);
		},
		organizationId => {
			const organization = organizationsMap[organizationId];

			const organizationData: OrganizationData = {
				id: organization.id,
				name: organization.name,
				collaborators: []
			};

			organization.collaborators.forEach(collaboratorId => {
				const collaborator = collaboratorsMap[collaboratorId];

				organizationData.collaborators.push(collaborator);
			});

			organizations.push(organization);
			organizationIds.push(organization.id);
			organizationNames.push(organization.name);
			collaboratorsDataArray.push(organizationData);
		}
	);

	const hasCollaborators = collaborators.length > 0;
	const hasOrganizations = organizations.length > 0;

	const collaboratorsRichData: CollaboratorsRichData = {
		collaborators,
		collaboratorsMap,
		collaboratorIds,
		//////////////
		organizations,
		organizationsMap,
		organizationIds,
		organizationNames,
		//////////////
		collaboratorsDataArray,
		//////////////
		order,
		//////////////
		hasCollaborators,
		hasOrganizations
	};

	return collaboratorsRichData;
}

export function buildCollaboratorsDataFromStoreData(
	storeCollaboratorsData: StoreCollaboratorsData
): CollaboratorsData {
	const { collaborators, organizations, order } = storeCollaboratorsData;

	return initCollaboratorsData({
		collaboratorsMap: collaborators.byId,
		organizationsMap: organizations.byId,
		order
	});
}

export function buildCollaboratorsDataLocation(
	collaboratorsData: CollaboratorsData
): CollaboratorsDataLocation {
	const collaboratorsDataLocation: CollaboratorsDataLocation = {
		collaboratorsLocation: {}
	};

	const { collaboratorsLocation } = collaboratorsDataLocation;

	const { collaboratorsDataArray } = buildCollaboratorsRichData(collaboratorsData);

	collaboratorsDataArrayIterator(
		collaboratorsDataArray,
		// COLLABORATOR
		() => null,
		// ORGANIZATION
		organizationData => {
			const { id, collaborators } = organizationData;

			collaborators.forEach(collaborator => {
				// INIT ARRAY;
				if (!collaboratorsLocation[collaborator.userId]?.organizationIds) {
					collaboratorsLocation[collaborator.userId] = {
						organizationIds: []
					};
				}

				collaboratorsLocation[collaborator.userId].organizationIds.push(id);
			});
		}
	);

	return collaboratorsDataLocation;
}

export function filterCollaboratorsDataArrayBySearchTerm(
	collaboratorsDataArray: CollaboratorsDataArray,
	searchTerm: string
): CollaboratorsDataArray {
	const isSearchTermValid = searchTerm.trim().length > 0;

	if (!isSearchTermValid) return collaboratorsDataArray;

	let draft = cloneDeep(collaboratorsDataArray);

	const filteredItems: Set<CollaboratorsDataArrayItem> = new Set();

	for (const item of draft) {
		// ORGANIZATION DATA
		if (isOrganizationData(item)) {
			const organization = item;

			const keywords: string[] = [organization.name];

			const organizationNameMatched = hasMatches({
				searchTerm,
				keywords
			});

			if (organizationNameMatched) {
				filteredItems.add(organization);
			}

			// Collaborator in organization
			const filteredCollaborators = filterCollaboratorsDataArrayBySearchTerm(
				organization.collaborators,
				searchTerm
			) as Collaborator[];

			if (filteredCollaborators.length > 0) {
				organization.collaborators = filteredCollaborators;
				filteredItems.add(organization);
			}

			continue;
		}

		// COLLABORATOR
		const collaborator = item as Collaborator;

		const collaboratorMatched = matchCollaborator(collaborator);

		if (collaboratorMatched) {
			filteredItems.add(collaborator);
		}
	}

	draft = [...filteredItems];

	function matchCollaborator(collaborator: Collaborator) {
		const keywords: string[] = [
			collaborator.emailAddress,
			collaborator.userFirstName ?? '',
			collaborator.userSirName ?? '',
			collaborator.organization ?? '',
			collaborator.department ?? ''
		];

		return hasMatches({ searchTerm, keywords });
	}

	return draft;
}

export type OrganizationSortColumn = 'name' | 'collaborators';
export type CollaboratorSortColumn = 'name' | 'email' | 'role' | 'groups';

export function orderCollaboratorsDataArray(
	collaboratorsDataArray: CollaboratorsDataArray,
	orderData: ActiveSort,
	metadata: {
		rolesMap: RolesMap;
	}
): CollaboratorsDataArray {
	let draft = cloneDeep(collaboratorsDataArray);

	draft = orderBy(
		draft,
		item => {
			// ORGANIZATION DATA
			if (isOrganizationData(item)) {
				const organization = item;

				// FILTER GROUP VARIABLES
				organization.collaborators = orderBy(
					organization.collaborators,
					collaborator => {
						return getCollaboratorSortValue(collaborator, orderData.column, metadata);
					},
					orderData.order
				);
				return getOrganizationSortValue(organization, orderData.column);
			}

			// COLLABORATOR
			const collaborator = item;
			return getCollaboratorSortValue(collaborator, orderData.column, metadata);
		},
		orderData.order
	);

	return draft;
}

export function getCollaboratorSortValue(
	collaborator: Collaborator,
	columnName: string,
	metadata: { rolesMap: RolesMap }
) {
	const columnNameToValue: Record<string, string | number> = {
		name: collaborator.userFirstName || collaborator.userSirName || '',
		email: collaborator.emailAddress,
		groups: collaborator.organizationsCount || 0,
		title: collaborator.position || '',
		organization: collaborator.organization || '',
		subOrganization: collaborator.subOrganization || '',
		workplace: collaborator.workplace || '',
		userRole:
			metadata.rolesMap[collaborator.projectRoleId ?? '']?.name || collaborator.userRole || ''
	};

	return columnNameToValue[columnName];
}

export function getOrganizationSortValue(organization: OrganizationData, columnName: string) {
	const organizationColumnsMapping: Record<string, string | number> = {
		name: organization.name,
		collaborators: organization.collaborators.length
	};

	return organizationColumnsMapping[columnName];
}

import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	width: 100%;
	min-height: 4.8rem;
	padding: 1.2rem 1.6rem;

	background: ${Colors.lightRed};

	z-index: 1000;
`;

import { useCallback, useMemo, useState } from 'react';
import { Svgs, Colors } from 'environment';
import { initButtonProps } from 'helpers/buttons';
import { InputTagWithCheckboxDropdown } from 'components/UI/Interactables/InputTagWithCheckboxDropdown';
import { useDownloadFile } from 'hooks/store/data/ExportWizard/useDownloadFile';
import { useResetExportWizard } from 'hooks/store/data/ExportWizard/useResetExportWizard';
import { ListContainer, ListItem, SortIcon, WarningMessage } from './ExportWizard.style';
import { ExportOptions } from 'types/index';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { Loader } from 'components/UI/Loader';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { ExportType } from 'consts';
import {
	useTranslation,
	useVariables,
	useDownloadDataset,
	useVisibleVariableReferences
} from 'hooks/store';
import { useMutableState, useCompletedAction } from 'hooks/utils';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { DownloadFormat, ExportDatasetType } from '../../../../api/data/entries/types';
import { Icon } from 'components/UI/Icons';

interface CurrentSeriesProps {
	label: string;
	value: string;
	checked: boolean;
}
interface Props {
	onClose: (success?: true) => void;
	currentSeriesItems?: CurrentSeriesProps[];
	exportScreenType?: string;
	format?: DownloadFormat;
	showFilterSection?: boolean;
}

const enum Views {
	mainView = 'mainView',
	secondaryView = 'secondaryView'
}

export function ExportWizard({
	onClose,
	currentSeriesItems,
	exportScreenType,
	format,
	showFilterSection = true
}: Props) {
	const { translate } = useTranslation();

	const [sort, setSort] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);
	const [exportType, setExportType] = useState(ExportOptions.AllData);
	const [view, setView] = useState(Views.mainView);
	const [modalElement, setModalElement] = useState<HTMLElement | null>(null);
	const [exportCategoryLabels, setExportCategoryLabels] = useState(false);
	const [removeLineShifts, setRemoveLineShifts] = useState(false);

	const selectedColumnsForExport = useVisibleVariableReferences(exportScreenType === 'series');

	const resetWizard = useResetExportWizard();

	const [exportableData, downloadFile] = useDownloadFile();

	const [
		{ loading: gettingDownloadableDataset, error: errorGettingDownloadableDataset },
		downloadDataset
	] = useDownloadDataset();

	const [{ data, error: variablesError, loading: loadingVariables }] = useVariables({
		lazy: true
	});

	const { variableSetsMap: currentVariableSetsMap } = data;

	const getModalRef = useCallback((node: HTMLElement | null) => {
		if (!node) return;
		const modalBody = node.querySelector('.modal-body');
		if (modalBody) {
			setModalElement(modalBody as HTMLElement);
		}
	}, []);

	const listItems = useMemo(() => {
		const { valid, empty, invalid } = exportableData;

		const sortFunction = (a: React.ReactElement, b: React.ReactElement) => {
			const getName = (key: React.Key | null) => {
				if (key) {
					return key.toString().split('-')[1] ?? '';
				}
				return '';
			};

			if (sort) {
				return getName(a.key) > getName(b.key) ? -1 : 1;
			}
			return getName(a.key) > getName(b.key) ? 1 : -1;
		};

		const validItems = [...valid].map(
			item =>
				currentVariableSetsMap[item] && (
					<ListItem data-testid="series-list-item" key={`valid-${item}`}>
						{currentVariableSetsMap[item].setLabel}
					</ListItem>
				)
		);

		const invalidItems = [...empty, ...invalid].map(
			item =>
				currentVariableSetsMap[item] && (
					<ListItem data-testid="series-list-item" key={`invalid-${item}`} empty>
						<Flex align={a => a.center}>
							{currentVariableSetsMap[item].setLabel}
							<InfoTooltip
								zIndex={1002}
								iconVisible
								colorTheme="dark"
								text={translate(
									({ exportWizard }) =>
										exportWizard.secondaryView.series.tooltipDescription
								)}
								marginOffset={{ left: 0.8 }}
								renderContext={modalElement ?? undefined}
							/>
						</Flex>
					</ListItem>
				)
		);

		return [...validItems, ...invalidItems].sort(sortFunction);
	}, [exportableData, sort]);

	const inputItems = useMemo(() => {
		const series = Object.values(currentVariableSetsMap).map(variableSet => ({
			label: variableSet.setLabel,
			value: variableSet.setName,
			checked: false
		}));

		return series;
	}, [currentVariableSetsMap]);

	// SERIES ITEMS
	const [parsedSeriesItems, setParsedSeriesItems] = useMutableState(inputItems);

	const hasChanges = useMemo(() => {
		let checkedItems = [];

		if (exportScreenType === ExportType.Series && currentSeriesItems) {
			checkedItems = currentSeriesItems.filter(item => item.checked);
		} else {
			checkedItems = parsedSeriesItems.filter(item => item.checked);
		}

		return checkedItems.length > 0;
	}, [parsedSeriesItems, currentSeriesItems]);

	const selectedSeriesHasData = !!exportableData.valid.length;

	useCompletedAction(
		gettingDownloadableDataset,
		errorGettingDownloadableDataset || !selectedSeriesHasData, // do not proceed to 2nd step if there's no valid data for export
		() => {
			setView(Views.secondaryView);
		}
	);

	function handleSelectItem(seriesNames: string[] | null | undefined) {
		if (!seriesNames) {
			setParsedSeriesItems(state => state.map(item => ({ ...item, checked: false })));
			setIsEmpty(true);
		} else {
			setParsedSeriesItems(state =>
				state.map(item => ({
					...item,
					checked: seriesNames.includes(item.value)
				}))
			);
			setIsEmpty(false);
		}
	}

	function handleDeleteSelectedItem(seriesName: string) {
		setParsedSeriesItems(state =>
			state.map(item => ({
				...item,
				checked: item.value === seriesName ? false : item.checked
			}))
		);
	}

	function isLoadingAction() {
		return gettingDownloadableDataset;
	}

	function isErrorAction() {
		return variablesError || errorGettingDownloadableDataset;
	}

	function handlePrimaryButton() {
		if (view === Views.mainView) {
			let checkedItems = [];

			if (exportScreenType === ExportType.Series && currentSeriesItems) {
				checkedItems = currentSeriesItems.filter(item => item.checked);

				downloadDataset({
					removeLineShifts: true,
					exportFormat: format ?? DownloadFormat.CSV,
					datasetType: ExportDatasetType.Series,
					amountOfData: exportType,
					...(selectedColumnsForExport?.length
						? { variables: selectedColumnsForExport }
						: {}),
					datasets: currentSeriesItems
						.filter(item => item.checked)
						.map(item => ({ datasetName: item.value })),
					categoryLabels: !!exportCategoryLabels
				});
			} else {
				checkedItems = parsedSeriesItems.filter(item => item.checked);

				if (checkedItems.length > 0) {
					downloadDataset({
						removeLineShifts: true,
						exportFormat: format ?? DownloadFormat.CSV,
						datasetType: ExportDatasetType.Series,
						amountOfData: exportType,
						categoryLabels: !!exportCategoryLabels,
						...(selectedColumnsForExport?.length
							? { variables: selectedColumnsForExport }
							: {}),
						datasets: parsedSeriesItems
							.filter(item => item.checked)
							.map(item => ({ datasetName: item.value }))
					});
				} else {
					setIsEmpty(true);
				}
			}
		} else {
			if (exportableData.isReadyForDownload) {
				downloadFile();
				onClose();
			}
		}
	}

	function handleNeutralButtonClick() {
		if (view === Views.mainView) {
			resetWizard();
			onClose();
		} else {
			setView(Views.mainView);
		}
	}

	function validatePrimaryButton() {
		let checkedItems = [];

		if (exportScreenType === ExportType.Series && currentSeriesItems) {
			checkedItems = currentSeriesItems.filter(item => item.checked);
		} else {
			checkedItems = parsedSeriesItems.filter(item => item.checked);
		}

		const allCheckedItemsAreEmpty = checkedItems.every(item =>
			exportableData.empty.includes(item.value)
		);

		return (
			allCheckedItemsAreEmpty ||
			isErrorAction() ||
			checkedItems.length === 0 ||
			isEmpty ||
			(view === Views.secondaryView && !exportableData.isReadyForDownload)
		);
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label:
				view === Views.mainView
					? translate(({ buttons }) => buttons.next)
					: translate(({ buttons }) => buttons.export),
			loading: isLoadingAction(),
			onClick: handlePrimaryButton,
			disabled: validatePrimaryButton()
		};

		buttons.neutral = {
			label:
				view === Views.mainView
					? translate(({ buttons }) => buttons.cancel)
					: translate(({ buttons }) => buttons.back),
			onClick: handleNeutralButtonClick
		};

		if (!hasChanges) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	const modalTitle =
		view === Views.mainView
			? translate(({ exportWizard }) => exportWizard.mainView.series.title)
			: translate(({ exportWizard }) => exportWizard.secondaryView.series.title);

	const exportSeriesTitle =
		exportScreenType === ExportType.Series &&
		currentSeriesItems &&
		currentSeriesItems[0].label &&
		modalTitle + ' ' + currentSeriesItems[0].label;

	const emptySeriesItems = useMemo(() => {
		const parsedSeriesNames = parsedSeriesItems.filter(d => d.checked).map(d => d.value);
		if (exportableData.empty) {
			return exportableData.empty.filter(seriesName =>
				parsedSeriesNames.includes(seriesName)
			);
		}
		return [];
	}, [exportableData, parsedSeriesItems]);

	return (
		<Modal
			title={exportSeriesTitle ? exportSeriesTitle : modalTitle}
			onClose={() => {
				resetWizard();
				onClose(true);
			}}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			fullSizeConfig={{
				narrow: true
			}}
			modalRef={getModalRef}
			close
			visible
		>
			{loadingVariables ? (
				<Loader />
			) : view === Views.mainView ? (
				<>
					{exportScreenType === ExportType.Series && currentSeriesItems ? null : (
						<>
							<Typography.Paragraph fontweight={w => w.bold}>
								{translate(
									({ exportWizard }) =>
										exportWizard.mainView.series.inputs.tagInput.label
								)}
							</Typography.Paragraph>
							<Spacer size={s => s.s} />
							<InputTagWithCheckboxDropdown
								items={parsedSeriesItems}
								label={translate(
									({ exportWizard }) =>
										exportWizard.mainView.series.errors.inputs.required
								)}
								onChange={values => handleSelectItem(values)}
								onDeleteAll={() => handleSelectItem(null)}
								onDelete={value => handleDeleteSelectedItem(value)}
								placeholder={translate(
									({ exportWizard }) =>
										exportWizard.mainView.series.inputs.tagInput.placeholder
								)}
								error={
									isEmpty &&
									translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.errors.inputs.required
									)
								}
							/>
							<Spacer size={s => s.m} />

							{emptySeriesItems.length ? (
								<>
									<WarningMessage data-testid="warning-message">
										<Icon
											svg={Svgs.Information}
											size={s => s.l}
											colors={{ color: Colors.text.error }}
											marginOffset={{ left: 0.4, top: 0.2, right: 0.8 }}
										/>
										<Typography.Paragraph>
											<strong>{emptySeriesItems.join(', ')}</strong>
											{translate(
												dict =>
													dict.exportWizard.mainView.series.warning.noData
											)}
										</Typography.Paragraph>
									</WarningMessage>
									<Spacer size={s => s.m} />
								</>
							) : (
								<></>
							)}
						</>
					)}

					<Gap marginGap={{ bottom: 1.6 }} marginOffset={{ top: 2 }} notLastChild>
						{showFilterSection ? (
							<>
								<Typography.Paragraph fontweight={w => w.bold}>
									{translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.title
									)}
								</Typography.Paragraph>
								<RadioButton
									label={translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.allData.label
									)}
									description={translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.allData.description
									)}
									selected={exportType === ExportOptions.AllData}
									onSelect={() => setExportType(ExportOptions.AllData)}
								/>

								<RadioButton
									label={translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.importable.label
									)}
									description={translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.importable
												.description
									)}
									selected={exportType === ExportOptions.Importable}
									onSelect={() => setExportType(ExportOptions.Importable)}
								/>
								<RadioButton
									label={translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.justData.label
									)}
									selected={exportType === ExportOptions.JustData}
									onSelect={() => setExportType(ExportOptions.JustData)}
									description={translate(
										({ exportWizard }) =>
											exportWizard.mainView.series.options.justData
												.description
									)}
								/>
							</>
						) : (
							<></>
						)}

						<Typography.Paragraph fontweight={fw => fw.bold}>
							{translate(dict => dict.dataset.modals.exportEntriesAsCSVModal.message)}
						</Typography.Paragraph>

						<Flex align={a => a.center} justify={j => j.start}>
							<RadioButton
								inline
								label={translate(dict => dict.terms.yes)}
								onSelect={() => setRemoveLineShifts(true)}
								selected={removeLineShifts === true}
								marginOffset={{ right: 1.6 }}
							/>
							<RadioButton
								inline
								label={translate(dict => dict.terms.no)}
								onSelect={() => setRemoveLineShifts(false)}
								selected={removeLineShifts === false}
							/>
						</Flex>

						<Typography.Paragraph fontweight={fw => fw.bold}>
							{translate(
								dict => dict.dataset.modals.exportEntriesAsCSVModal.categoriesLabel
							)}
						</Typography.Paragraph>
						<RadioButton
							label={translate(dict => dict.terms.label)}
							selected={exportCategoryLabels}
							onSelect={() => setExportCategoryLabels(true)}
							description={translate(
								dict => dict.dataset.modals.exportEntriesAsCSVModal.labelsHint
							)}
						/>

						<RadioButton
							label={translate(dict => dict.terms.valueUpperCase)}
							selected={!exportCategoryLabels}
							onSelect={() => setExportCategoryLabels(false)}
							description={translate(
								dict => dict.dataset.modals.exportEntriesAsCSVModal.valuesHint
							)}
						/>
					</Gap>
				</>
			) : (
				<>
					<Flex>
						<SortIcon
							svg={Svgs.ArrowDown}
							size={s => s.s}
							style={{ color: Colors.primary.normal }}
							sort={sort}
							onClick={() => setSort(s => !s)}
						/>
						<Typography.Caption color={Colors.primary.normal}>
							{translate(
								({ exportWizard }) => exportWizard.secondaryView.series.listLabel
							)}
						</Typography.Caption>
					</Flex>
					<ListContainer data-testid="series-list">{listItems}</ListContainer>
				</>
			)}
		</Modal>
	);
}

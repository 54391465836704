import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { resetForm } from 'store/data/forms';

export function useResetForm(): LocalOperation {
	const dispatch = useDispatch();

	function handler() {
		dispatch(resetForm());
	}

	return handler;
}

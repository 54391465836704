import { useState, useEffect, FormEvent, useCallback } from 'react';
import { BillingAddressForm } from 'components/Account';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	OrderSummary,
	PlanDetails,
	SelectAccountPlanType,
	SelectCreditCard,
	UpdatePlanSuccess
} from '.';
import {
	SubscriptionName,
	UserLicenceModel,
	SubscriptionStatus,
	LicenceModelQuantities
} from 'store/account/subscription';
import {
	ActionTypes,
	WindowDOMwithChargebee,
	LedidiPlanCurrency,
	SupportedLedidiPlans,
	SupportedLedidiPlansPrices
} from 'store/account/payments';
import { BillingPeriodUnit, PaymentMethod } from 'store/account/payments';
import { AddCreditCardForm } from '../AddCreditCard';
import { ROUTES } from 'types/navigation';
import { Loader } from 'components/UI/Loader';
import { PrimaryButtonProps, InputType, SecondaryButtonProps } from 'types/index';
import { Colors, Svgs } from 'environment';
import { newCreditCard } from 'consts';

import { BulletPoint } from './ChangePlanModal.style';
import { Input } from 'components/UI/Inputs/Input';
import { Flex } from 'components/UI/Flex';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { getCreditCardValidationSchema } from 'helpers/subscription';
import { Icon } from 'components/UI/Icons';
import { useRouteMatch } from 'hooks/navigation';
import {
	useTranslation,
	usePrimaryPaymentId,
	useBillingInfo,
	useAccount,
	useCBInstance,
	useUpdateBillingAddress,
	useSubscriptionPaymentMethods,
	useMakePaymentSourcePrimary,
	useAddSubscriptionPaymentMethod,
	useCreateChargebeeCustomer,
	useCreateCustomerSubscription,
	useUpdateCustomerSubscription,
	useDowngradeSubscriptionToFree,
	useBillingAddressFormValues,
	useSubscription,
	useHasErrored,
	useSubscriptionPayments,
	useSelectedPaymentMethod,
	useSubscriptionRules,
	useSubscriptionBundleFlag
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, usePrevious } from 'hooks/utils';
import { SelectPaymentMethod } from './ChangeAccountSteps/SelectPaymentMethod';
import { SelectAccountPlanTypeBundle } from './ChangeAccountSteps/SelectAccountPlanTypeBundle';
import { PlanDetailsBundle } from './ChangeAccountSteps/PlanDetailBundle';
import {
	ChangePlanLicenceModel,
	useChangePlanLicenceQuantities
} from 'hooks/store/account/subscription/useChangePlanLicenceQuantities';
import { OrderSummaryBundle } from './ChangeAccountSteps/OrderSummaryBundle';

export enum ChangePlanSteps {
	ChoosePlan = 'ChoosePlan',
	PlanDetails = 'PlanDetails',
	SelectPayment = 'SelectPayment',
	SelectCreditCard = 'SelectCreditCard',
	AddNewCreditCard = 'AddNewCreditCard',
	BillingInformation = 'BillingInformation',
	OrderSummary = 'OrderSummary',
	UpdateSuccess = 'UpdateSuccess',
	DowngradeWarning = 'DowngradeWarning',
	DowngradeConfirmation = 'DowngradeConfirmation'
}

export enum ModalTitles {
	ChoosePlan = 'choosePlan',
	PlanDetails = 'planDetails',
	SelectPayment = 'selectPayment',
	SelectCreditCard = 'selectCreditCard',
	AddNewCreditCard = 'addNewCreditCard',
	BillingInformation = 'billingInformation',
	OrderSummary = 'orderSummary',
	UpdateSuccess = 'updateSuccess',
	DowngradeWarning = 'downgradeWarning',
	DowngradeConfirmation = 'downgradeConfirmation'
}

interface Props {
	onClose: () => void;
}

export function ChangePlanModal({ onClose }: Props) {
	const { translate } = useTranslation();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const chargebeeSite = process.env.REACT_APP_CHARGEBEE_DATA_CB_SITE;
	const chargebeeKey = process.env.REACT_APP_CHARGEBEE_DATA_PUBLISH_API_KEY;

	const primaryPaymentId = usePrimaryPaymentId();

	//React Form Hooks for Credit card
	const creditCardValidationSchema = getCreditCardValidationSchema({ translate });

	const {
		Form: CreditCardForm,
		formProviderProps: creditCardFormProviderProps,
		FormProvider: CreditCardFormProvider,
		isValid: isCreditCardValid,
		isDirtyAndValid: isCreditCardDirtyAndValid,
		reset
	} = useReactForm({
		initialValues: newCreditCard,
		validationSchema: creditCardValidationSchema
	});

	//React Form Hooks for Billing Address
	const [{ loading: loadingPaymentsAndBillingInfo, data: billingInfo }, getBillingInfo] =
		useBillingInfo();
	const [
		{
			data: { details: userDetails }
		}
	] = useAccount();

	const { isNonPaidSubscription } = useSubscriptionRules();

	const [
		{ data: isChargebeeLoaded, loading: checkingChargebeeInstanceLoaded },
		setChargebeeIsLoaded
	] = useCBInstance();

	const [{ loading: updatingBillingAddress }, updateBillingAddress] = useUpdateBillingAddress();
	const [{ loading: paymentMethodsLoading, data: paymentMethods }, getPaymentMethods] =
		useSubscriptionPaymentMethods();

	const { data: selectedPaymentMethod } = useSelectedPaymentMethod();

	const [{ loading: makingPaymentSourcePrimary, error: errorMakingPaymentSourcePrimary }] =
		useMakePaymentSourcePrimary();
	const [{ loading: addPaymentMethodLoading, error: errorAddPaymentMethodLoading }] =
		useAddSubscriptionPaymentMethod();
	const [
		{ loading: creatingChargebeeCustomer, error: errorCreatingChargebeeCustomer },
		createChargebeeCustomer
	] = useCreateChargebeeCustomer();
	const [{ loading: creatingCustomerSubscription }, createCustomerSubscription] =
		useCreateCustomerSubscription();
	const [{ loading: updatingCustomerSubscription }, updateCustomerSubscription] =
		useUpdateCustomerSubscription();
	const [
		{ loading: downgradingSubscriptionToFree, error: errorDowngradingCustomerSubscription },
		downgradeSubscriptionToFree
	] = useDowngradeSubscriptionToFree();

	const [selectedLocalPaymentMethod, setSelectedLocalPaymentMethod] = useState(
		PaymentMethod.CARD
	);
	const [showInvoicePaymentWarning, setShowInvoicePaymentWarning] = useState(false);

	const {
		data: { initialValues, billingAddressValidationSchema, useCompanyInfo, usePaymentInfo },
		emptyValues: { emptyBillingAddress, emptyPrimaryPaymentSourceAddress },
		toggleUseCompanyInfo,
		toggleUsePaymentInfo
	} = useBillingAddressFormValues(
		billingInfo?.billingAddress,
		billingInfo?.primaryPaymentSourceAddress
	);

	const {
		Form: BillingForm,
		FormProvider: BillingAddressFormProvider,
		formProviderProps: billingAddressFormProps,
		handleSubmit: handleBillingChanged,
		isValid: isBillingAddressValid,
		getValues: getBillingAddressValues
	} = useReactForm({
		validationSchema: billingAddressValidationSchema,
		initialValues: initialValues
	});

	const [
		{
			data: {
				users,
				details: subscriptionDetails,
				subscriptionTypes: { isNonSubscriber }
			}
		},
		fetchSubscription
	] = useSubscription();

	const coveredUsers = users.filter(
		user =>
			(user.licenceModel !== UserLicenceModel.OneOwnedOneShared &&
				user.licenceModel !== UserLicenceModel.Trial &&
				user.licenceModel !== UserLicenceModel.NoLicence) ||
			user.subscriptionStatus === SubscriptionStatus.Pending
	).length;

	const [renderChildren, setRenderChildren] = useState(false);
	const [step, setStep] = useState(ChangePlanSteps.ChoosePlan);
	const [payMonthly, setPayMonthly] = useState(
		subscriptionDetails ? subscriptionDetails.isPayedMonthly : true
	);
	const [selectedSubscriptionName, setSelectedSubscriptionName] = useState<SupportedLedidiPlans>(
		SubscriptionName.LedidiCore
	);
	const [newLicenceQuantity, setNewLicenceQuantity] = useState<number>(
		subscriptionDetails &&
			subscriptionDetails.licenceQuantity &&
			subscriptionDetails.licenceQuantity > 1
			? subscriptionDetails.licenceQuantity
			: 1
	);

	const [shouldProcessCard, setShouldProcessCard] = useState(false);

	const [recurrentBilling, setRecurrentBilling] = useState(false);
	const [downgradeText, setDowngradeText] = useState('');

	const norwegianUser =
		billingInfo?.currencyCode === LedidiPlanCurrency.NOK || userDetails?.country === 'NO';

	const currency = norwegianUser ? LedidiPlanCurrency.NOK : LedidiPlanCurrency.EUR;
	const selectedPlanPriceId =
		SupportedLedidiPlansPrices[currency][selectedSubscriptionName][
			payMonthly ? BillingPeriodUnit.Month : BillingPeriodUnit.Year
		].priceId;

	const wasLoadingPaymentMethods = usePrevious(paymentMethodsLoading);
	const wasloadingPaymentsAndBillingInfo = usePrevious(loadingPaymentsAndBillingInfo);

	const { changePlanLicenceModels, changeCount } = useChangePlanLicenceQuantities(
		currency,
		payMonthly ? BillingPeriodUnit.Month : BillingPeriodUnit.Year
	);

	function extractQuantities(changePlanLicenceModels: ChangePlanLicenceModel[]) {
		let quantities: LicenceModelQuantities = {};
		changePlanLicenceModels.forEach(licence => {
			quantities = { ...quantities, [licence.id]: licence.count };
		});
		return quantities;
	}

	useEffect(() => {
		if (!isNonSubscriber) {
			!wasLoadingPaymentMethods && !paymentMethodsLoading && getPaymentMethods();
			!wasloadingPaymentsAndBillingInfo && !loadingPaymentsAndBillingInfo && getBillingInfo();
		}

		if (!isChargebeeLoaded && !checkingChargebeeInstanceLoaded) {
			(window as WindowDOMwithChargebee).Chargebee?.init({
				site: chargebeeSite, // test site
				publishableKey: chargebeeKey
			});
			setChargebeeIsLoaded();
		}
	}, []);

	//Render children after billing address is fetched
	useEffect(() => {
		if ((!loadingPaymentsAndBillingInfo && wasloadingPaymentsAndBillingInfo) || isNonSubscriber)
			setRenderChildren(true);
	}, [loadingPaymentsAndBillingInfo]);

	const wasUpdatingBillingAddress = usePrevious(updatingBillingAddress);

	const hasErrors = useHasErrored([
		ActionTypes.UPDATE_CUSTOMER_SUBSCRIPTION as string,
		ActionTypes.UPDATE_USER_BILLING_ADDRESS as string,
		ActionTypes.CREATE_CUSTOMER_SUBSCRIPTION as string
	]);

	useEffect(() => {
		if (!hasErrors && !updatingBillingAddress && wasUpdatingBillingAddress) {
			setStep(ChangePlanSteps.OrderSummary);
		}
	}, [updatingBillingAddress]);

	const wasUpdatingCustomerSubscription = usePrevious(updatingCustomerSubscription);
	useEffect(() => {
		if (!hasErrors && wasUpdatingCustomerSubscription && !updatingCustomerSubscription) {
			setStep(ChangePlanSteps.UpdateSuccess);
		}
	}, [updatingCustomerSubscription]);

	const wasAddPaymentMethodLoading = usePrevious(addPaymentMethodLoading);
	useEffect(() => {
		if (
			!errorAddPaymentMethodLoading &&
			wasAddPaymentMethodLoading &&
			!addPaymentMethodLoading
		) {
			getBillingInfo();
		}
	}, [addPaymentMethodLoading]);

	const wasCreatingChargebeeCustomer = usePrevious(creatingChargebeeCustomer);
	useEffect(() => {
		if (
			!creatingChargebeeCustomer &&
			wasCreatingChargebeeCustomer &&
			!errorCreatingChargebeeCustomer
		) {
			if (selectedSubscriptionName === SubscriptionName.LedidiFreeTrial && userDetails) {
				createCustomerSubscription({
					newCustomerSubscription: {
						emailAddress: userDetails.emailAddress,
						firstName: userDetails.userFirstName,
						lastName: userDetails.userSirName,
						priceId: selectedPlanPriceId,
						quantity: 1,
						selectedPaymentMethod: selectedLocalPaymentMethod
					}
				});
			}
		}
	}, [creatingChargebeeCustomer]);

	const wasCreatingCustomerSubscription = usePrevious(creatingCustomerSubscription);

	useEffect(() => {
		if (!creatingCustomerSubscription && wasCreatingCustomerSubscription && !hasErrors) {
			setStep(ChangePlanSteps.UpdateSuccess);
		}
	}, [creatingCustomerSubscription]);

	const wasDowngradingSubscriptionToFree = usePrevious(downgradingSubscriptionToFree);
	useEffect(() => {
		if (
			!downgradingSubscriptionToFree &&
			wasDowngradingSubscriptionToFree &&
			!errorDowngradingCustomerSubscription
		) {
			onClose();
		}
	}, [downgradingSubscriptionToFree]);

	function onGoToPlanDetailsOrDowngradeOrPayment() {
		if (isNonSubscriber && userDetails) {
			createChargebeeCustomer({
				customerData: {
					firstName: userDetails.userFirstName,
					lastName: userDetails.userSirName,
					emailAddress: userDetails.emailAddress
				}
			});

			if (selectedSubscriptionName !== SubscriptionName.LedidiFreeTrial) {
				onGoToPlanDetails();
			}
		} else {
			//TODO: this part will dissappear when we migrate to Bundle version and it will always
			// go to PlanDetails
			if (selectedSubscriptionName === SubscriptionName.LedidiFreeTrial) {
				onGoToDowngradeWarning();
			} else {
				onGoToPlanDetails();
			}
		}
	}

	// Change local payment method with the info from the api
	useEffect(() => {
		if (selectedPaymentMethod && !isNonPaidSubscription)
			setSelectedLocalPaymentMethod(selectedPaymentMethod);
	}, [selectedPaymentMethod]);

	useCompletedAction(makingPaymentSourcePrimary, errorMakingPaymentSourcePrimary, () => {
		getBillingInfo();
	});

	function onGoToPlanDetails() {
		setStep(ChangePlanSteps.PlanDetails);
	}

	function onGoToSelectPayment() {
		setStep(ChangePlanSteps.SelectPayment);
	}

	function onGoToSelectCreditCard() {
		setStep(ChangePlanSteps.SelectCreditCard);
	}

	function onGoToAddNewCreditCard() {
		setStep(ChangePlanSteps.AddNewCreditCard);
	}

	function onGoToBillingInformation() {
		setStep(ChangePlanSteps.BillingInformation);
	}

	function onGoToDowngradeWarning() {
		setStep(ChangePlanSteps.DowngradeWarning);
	}

	function onGoToDowngradeConfirmation() {
		setStep(ChangePlanSteps.DowngradeConfirmation);
	}

	function onDowngradePlan() {
		downgradeSubscriptionToFree();
	}

	/**
	 * In case CARD is selected, we go to select or add a card modal
	 * In case INVOICE is selected and there are cards added, we show the warning
	 * In case INVOICE is selected and there are no cards, we continue to Billing Info Step
	 */
	function onGoToNextStepAfterPaymentSelection() {
		if (selectedLocalPaymentMethod === PaymentMethod.CARD) onGoToSelectCreditCard();
		else if (
			selectedLocalPaymentMethod === PaymentMethod.INVOICE &&
			paymentMethods &&
			paymentMethods.cards.length > 0
		) {
			//open modal if it's invoice to warn about the card deletions
			setShowInvoicePaymentWarning(true);
		} else {
			onGoToBillingInformation();
		}
	}

	function onUpgradeSubscription() {
		const paymentMethodReferenceId =
			selectedSubscriptionName !== SubscriptionName.LedidiFreeTrial
				? primaryPaymentId
				: undefined;

		if (isNonSubscriber && userDetails) {
			createCustomerSubscription({
				newCustomerSubscription: {
					firstName: userDetails.userFirstName,
					lastName: userDetails.userSirName,
					emailAddress: userDetails.emailAddress,
					quantity: subscriptionBundleFlag ? 0 : newLicenceQuantity,
					quantities: subscriptionBundleFlag
						? extractQuantities(changePlanLicenceModels)
						: undefined,
					paymentMethodReferenceId,
					priceId: selectedPlanPriceId,
					selectedPaymentMethod: selectedLocalPaymentMethod
				}
			});
		} else {
			updateCustomerSubscription({
				customerSubscription: {
					quantity: subscriptionBundleFlag ? 0 : newLicenceQuantity,
					quantities: subscriptionBundleFlag
						? extractQuantities(changePlanLicenceModels)
						: undefined,
					priceId: selectedPlanPriceId,
					selectedPaymentMethod: selectedLocalPaymentMethod
				}
			});
		}
	}

	const [_, getPayments] = useSubscriptionPayments(true);

	const isOnBillingPage = useRouteMatch([ROUTES.AccountUMBillingHistory]);

	function onModalClose() {
		setStep(ChangePlanSteps.ChoosePlan);
		onClose();
	}

	function onCreditCardAdded() {
		reset();
		setRecurrentBilling(false);
		setShouldProcessCard(false);
		setStep(ChangePlanSteps.SelectCreditCard);
	}

	const previousSelectedSub = usePrevious(subscriptionDetails?.subscriptionName);
	function onSuccess() {
		const isUpgradeFromFree =
			previousSelectedSub === SubscriptionName.LedidiFreeTrial &&
			selectedSubscriptionName === SubscriptionName.LedidiCore;
		if (!isUpgradeFromFree) fetchSubscription();
		if (isOnBillingPage) {
			getPayments();
		}

		onModalClose();
	}

	const submitCreditCard = useCallback(
		(event?: FormEvent<HTMLFormElement>) => {
			event && event.preventDefault();
			if (
				recurrentBilling &&
				isCreditCardValid &&
				isCreditCardDirtyAndValid &&
				!loadingPaymentsAndBillingInfo &&
				!addPaymentMethodLoading
			) {
				setShouldProcessCard(true);
			}
		},
		[
			recurrentBilling,
			isCreditCardValid,
			isCreditCardDirtyAndValid,
			loadingPaymentsAndBillingInfo,
			addPaymentMethodLoading
		]
	);

	const onChangeBillingAddress = handleBillingChanged(_ => {
		updateBillingAddress(getBillingAddressValues());
	});

	const choosePlanDisabled =
		loadingPaymentsAndBillingInfo ||
		creatingCustomerSubscription ||
		updatingCustomerSubscription ||
		(selectedSubscriptionName === SubscriptionName.LedidiFreeTrial &&
			(subscriptionDetails?.subscriptionName === SubscriptionName.LedidiFreeTrial ||
				subscriptionDetails?.subscriptionName === SubscriptionName.LedidiFree));

	function getPrimaryButton(): PrimaryButtonProps | undefined {
		switch (step) {
			case ChangePlanSteps.ChoosePlan:
				return {
					label:
						isNonSubscriber &&
						selectedSubscriptionName === SubscriptionName.LedidiFreeTrial
							? translate(({ buttons }) => buttons.confirm)
							: translate(({ buttons }) => buttons.continue),
					loading: creatingCustomerSubscription || updatingCustomerSubscription,
					disabled: choosePlanDisabled,
					onClick: onGoToPlanDetailsOrDowngradeOrPayment
				};
			case ChangePlanSteps.PlanDetails:
				return {
					label: translate(({ buttons }) => buttons.continueToPayment),

					onClick: onGoToSelectPayment
				};
			case ChangePlanSteps.SelectPayment:
				return {
					label:
						selectedLocalPaymentMethod === PaymentMethod.INVOICE
							? translate(({ buttons }) => buttons.continueToBilling)
							: translate(({ buttons }) => buttons.continue),
					loading: creatingCustomerSubscription || paymentMethodsLoading,
					disabled: creatingCustomerSubscription || paymentMethodsLoading,
					onClick: onGoToNextStepAfterPaymentSelection
				};
			case ChangePlanSteps.SelectCreditCard:
				return {
					label: translate(({ buttons }) => buttons.continueToBilling),
					loading:
						creatingCustomerSubscription ||
						paymentMethodsLoading ||
						makingPaymentSourcePrimary ||
						addPaymentMethodLoading,
					disabled:
						creatingCustomerSubscription ||
						paymentMethodsLoading ||
						makingPaymentSourcePrimary ||
						addPaymentMethodLoading ||
						!primaryPaymentId,
					onClick: onGoToBillingInformation
				};
			case ChangePlanSteps.AddNewCreditCard:
				return {
					label: translate(({ buttons }) => buttons.continue),
					loading: loadingPaymentsAndBillingInfo || shouldProcessCard,
					disabled:
						shouldProcessCard ||
						!recurrentBilling ||
						!isCreditCardValid ||
						!isCreditCardDirtyAndValid,
					onClick: submitCreditCard
				};
			case ChangePlanSteps.BillingInformation:
				return {
					label: translate(({ buttons }) => buttons.continue),
					loading: updatingBillingAddress,
					disabled: hasErrors || !isBillingAddressValid,
					onClick: onChangeBillingAddress
				};
			case ChangePlanSteps.OrderSummary:
				return {
					label: translate(({ buttons }) => buttons.completePayment),
					loading: creatingCustomerSubscription || updatingCustomerSubscription,
					onClick: onUpgradeSubscription
				};
			case ChangePlanSteps.UpdateSuccess:
				return {
					label: translate(({ buttons }) => buttons.ok),
					onClick: onSuccess
				};
			case ChangePlanSteps.DowngradeWarning:
				return {
					label: translate(({ buttons }) => buttons.understand),
					warning: true,
					loading: false,
					disabled: false,
					onClick: onGoToDowngradeConfirmation
				};
			case ChangePlanSteps.DowngradeConfirmation:
				return {
					label: translate(({ buttons }) => buttons.downgrade),
					warning: true,
					loading: downgradingSubscriptionToFree,
					disabled:
						downgradeText !==
						translate(({ accountUM }) => accountUM.changePlan.downgradeText),
					onClick: onDowngradePlan
				};
			default:
				return undefined;
		}
	}

	function getSecondaryButton(): SecondaryButtonProps | undefined {
		let onClickEvent;
		switch (step) {
			case ChangePlanSteps.ChoosePlan: {
				onClickEvent = onClose;
				break;
			}
			case ChangePlanSteps.PlanDetails: {
				onClickEvent = () => setStep(ChangePlanSteps.ChoosePlan);
				break;
			}
			case ChangePlanSteps.SelectPayment: {
				onClickEvent = () => setStep(ChangePlanSteps.PlanDetails);
				break;
			}
			case ChangePlanSteps.SelectCreditCard: {
				onClickEvent = () => setStep(ChangePlanSteps.SelectPayment);
				break;
			}
			case ChangePlanSteps.AddNewCreditCard: {
				onClickEvent = () => setStep(ChangePlanSteps.SelectCreditCard);
				break;
			}
			case ChangePlanSteps.BillingInformation: {
				onClickEvent = () => setStep(ChangePlanSteps.SelectPayment);
				break;
			}

			case ChangePlanSteps.OrderSummary: {
				onClickEvent = () => setStep(ChangePlanSteps.BillingInformation);
				break;
			}
			case ChangePlanSteps.DowngradeWarning: {
				onClickEvent = () => setStep(ChangePlanSteps.ChoosePlan);
				break;
			}
			case ChangePlanSteps.DowngradeConfirmation: {
				onClickEvent = () => setStep(ChangePlanSteps.DowngradeWarning);
				break;
			}
			default:
				onClickEvent = undefined;
		}
		if (onClickEvent) {
			return {
				label:
					step === ChangePlanSteps.ChoosePlan
						? translate(({ buttons }) => buttons.cancel)
						: translate(({ buttons }) => buttons.back),
				onClick: onClickEvent
			};
		}
	}

	const modalTitle = translate(({ accountUM }) => accountUM.changePlan[ModalTitles[step]]);

	return (
		<>
			<Modal
				visible
				title={modalTitle}
				primary={getPrimaryButton()}
				neutral={getSecondaryButton()}
				close
				closeOnBackdrop
				enterAsPrimaryOnClick
				onClose={onClose}
			>
				{renderChildren ? (
					<div>
						{(step === ChangePlanSteps.ChoosePlan ||
							step === ChangePlanSteps.PlanDetails ||
							step === ChangePlanSteps.SelectPayment) && (
							<>
								<Switch
									label={translate(
										({ accountUM }) => accountUM.changePlan.payMonthly
									)}
									onChange={() => {
										setPayMonthly(!payMonthly);
									}}
									on={payMonthly}
									disabled={selectedLocalPaymentMethod === PaymentMethod.INVOICE}
								/>
							</>
						)}
						{step === ChangePlanSteps.ChoosePlan && (
							<>
								{subscriptionBundleFlag ? (
									<SelectAccountPlanTypeBundle
										currency={currency}
										payMonthly={payMonthly}
										selectedSubscriptionName={selectedSubscriptionName}
										setSelectedSubscriptionName={setSelectedSubscriptionName}
										currentSubsciption={
											subscriptionDetails
												? subscriptionDetails.subscriptionName
												: null
										}
									/>
								) : (
									<SelectAccountPlanType
										currency={currency}
										payMonthly={payMonthly}
										selectedSubscriptionName={selectedSubscriptionName}
										setSelectedSubscriptionName={setSelectedSubscriptionName}
										currentSubsciption={
											subscriptionDetails
												? subscriptionDetails.subscriptionName
												: null
										}
									/>
								)}
							</>
						)}
						{step === ChangePlanSteps.PlanDetails && (
							<>
								{!subscriptionBundleFlag ? (
									<PlanDetails
										currency={currency}
										payMonthly={payMonthly}
										coveredUsersNumber={coveredUsers}
										licencesQuantity={newLicenceQuantity}
										selectedSubscriptionName={selectedSubscriptionName}
										refundableCredits={
											subscriptionDetails
												? subscriptionDetails.refundableCredits.toString()
												: '0'
										}
										setLicencesQuantity={setNewLicenceQuantity}
										onSubmit={onGoToSelectPayment}
									/>
								) : (
									<PlanDetailsBundle
										currency={currency}
										payMonthly={payMonthly}
										//coveredUsersNumber={coveredUsers}
										changePlanLicenceModels={changePlanLicenceModels}
										selectedSubscriptionName={selectedSubscriptionName}
										refundableCredits={
											subscriptionDetails
												? subscriptionDetails.refundableCredits.toString()
												: '0'
										}
										onChangeCount={(id, value) => changeCount(id, value)}
										onSubmit={onGoToSelectPayment}
									/>
								)}
							</>
						)}
						{step === ChangePlanSteps.SelectPayment && (
							<SelectPaymentMethod
								selectedPaymentMethod={selectedLocalPaymentMethod}
								paymentMethodsLoading={paymentMethodsLoading}
								payMonthly={payMonthly}
								onSelectPaymentMethod={selectedPaymentMethod =>
									setSelectedLocalPaymentMethod(selectedPaymentMethod)
								}
							/>
						)}
						{step === ChangePlanSteps.SelectCreditCard && (
							<SelectCreditCard
								paymentMethods={paymentMethods}
								paymentMethodsLoading={paymentMethodsLoading}
								setCreditCardModalVisible={onGoToAddNewCreditCard}
							/>
						)}
						{step === ChangePlanSteps.AddNewCreditCard && (
							<CreditCardFormProvider {...creditCardFormProviderProps}>
								<CreditCardForm onSubmit={submitCreditCard}>
									<AddCreditCardForm
										recurrentBilling={recurrentBilling}
										shouldProcessCard={shouldProcessCard}
										onClose={onClose}
										onCreditCardAdded={onCreditCardAdded}
										setShouldProcessCard={setShouldProcessCard}
										setRecurrentBilling={setRecurrentBilling}
									/>
								</CreditCardForm>
							</CreditCardFormProvider>
						)}
						{step === ChangePlanSteps.BillingInformation && billingInfo && (
							<>
								{loadingPaymentsAndBillingInfo ? (
									<Loader />
								) : (
									<BillingAddressFormProvider {...billingAddressFormProps}>
										<BillingForm onSubmit={onChangeBillingAddress}>
											<BillingAddressForm
												primaryPaymentSourceAddress={
													selectedLocalPaymentMethod ===
													PaymentMethod.INVOICE
														? null
														: billingInfo?.primaryPaymentSourceAddress ??
														  emptyPrimaryPaymentSourceAddress
												}
												billingAddress={
													billingInfo?.billingAddress ??
													emptyBillingAddress
												}
												usePaymentInfo={
													selectedLocalPaymentMethod ===
													PaymentMethod.INVOICE
														? false
														: usePaymentInfo
												}
												useCompanyInfo={useCompanyInfo}
												toggleUsePaymentInfo={
													selectedLocalPaymentMethod ===
													PaymentMethod.INVOICE
														? undefined
														: toggleUsePaymentInfo
												}
												toggleUseCompanyInfo={toggleUseCompanyInfo}
											/>
										</BillingForm>
									</BillingAddressFormProvider>
								)}
							</>
						)}
						{billingInfo && step === ChangePlanSteps.OrderSummary && (
							<>
								{!subscriptionBundleFlag ? (
									<OrderSummary
										selectedPaymentMethod={selectedLocalPaymentMethod}
										selectedSubscriptionName={selectedSubscriptionName}
										billingInfo={billingInfo}
										payMonthly={payMonthly}
										licencesQuantity={newLicenceQuantity}
										currency={currency}
										setStep={setStep}
									/>
								) : (
									<OrderSummaryBundle
										selectedPaymentMethod={selectedLocalPaymentMethod}
										selectedSubscriptionName={selectedSubscriptionName}
										billingInfo={billingInfo}
										payMonthly={payMonthly}
										changePlanLicenceModels={changePlanLicenceModels}
										currency={currency}
										setStep={setStep}
									/>
								)}
							</>
						)}
						{step === ChangePlanSteps.UpdateSuccess && <UpdatePlanSuccess />}
						{(step === ChangePlanSteps.DowngradeWarning ||
							step === ChangePlanSteps.DowngradeConfirmation) && (
							<>
								<Typography.H3 fontweight={f => f.bold}>
									{translate(
										({ accountUM }) =>
											accountUM.changePlan.fromPaidToFreeChangeCaption
									)}
								</Typography.H3>
								<Spacer size={s => s.m} />
							</>
						)}
						{step === ChangePlanSteps.DowngradeWarning && (
							<>
								<Typography.Paragraph fontweight={f => f.bold}>
									{translate(
										({ accountUM }) => accountUM.changePlan.downgradeHead
									)}
								</Typography.Paragraph>
								<Spacer size={s => s.m} />
								<Flex align={a => a.center}>
									<BulletPoint />
									<Typography.Caption fontweight={f => f.bold}>
										{translate(
											({ accountUM }) => accountUM.changePlan.downgradeInfo1
										)}
									</Typography.Caption>
								</Flex>
								<Flex align={a => a.center}>
									<BulletPoint />
									<Typography.Caption fontweight={f => f.bold}>
										{translate(
											({ accountUM }) => accountUM.changePlan.downgradeInfo2
										)}
									</Typography.Caption>
								</Flex>
								<Flex align={a => a.center}>
									<BulletPoint />
									<Typography.Caption fontweight={f => f.bold}>
										{translate(
											({ accountUM }) => accountUM.changePlan.downgradeInfo3
										)}
									</Typography.Caption>
								</Flex>
								<Flex align={a => a.center}>
									<BulletPoint />
									<Typography.Caption fontweight={f => f.bold}>
										{translate(
											({ accountUM }) => accountUM.changePlan.downgradeInfo4
										)}
									</Typography.Caption>
								</Flex>
							</>
						)}
						{step === ChangePlanSteps.DowngradeConfirmation && (
							<>
								<Typography.Caption
									color={Colors.text.main}
									style={{ userSelect: 'none' }}
									marginOffset={{ bottom: 0.4 }}
								>
									{translate(
										({ accountUM }) => accountUM.changePlan.typingExtraCheckText
									)}
								</Typography.Caption>
								<Input
									type={InputType.Text}
									value={downgradeText}
									onChange={e => setDowngradeText(e.target.value)}
								/>
							</>
						)}
						{(step === ChangePlanSteps.DowngradeWarning ||
							step === ChangePlanSteps.DowngradeConfirmation) && (
							<>
								<Spacer size={s => s.m} />
								<Flex>
									<Icon svg={Svgs.InformationRed} />
									<Typography.Paragraph
										fontweight={f => f.bold}
										color={Colors.text.error}
										marginOffset={{ left: 0.6 }}
									>
										{translate(
											({ accountUM }) =>
												accountUM.changePlan.actionCannotBeUndone
										)}
									</Typography.Paragraph>
								</Flex>
							</>
						)}
					</div>
				) : (
					<Loader center />
				)}
			</Modal>
			<Modal
				size={s => s.s}
				visible={showInvoicePaymentWarning}
				title={translate(dict => dict.accountUM.changePlan.invoiceAlertModal.title)}
				primary={{
					label: translate(dict => dict.buttons.confirm),
					warning: true,
					onClick: () => {
						onGoToBillingInformation();
						setShowInvoicePaymentWarning(false);
					}
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: () => setShowInvoicePaymentWarning(false)
				}}
				close
				closeOnBackdrop
				enterAsPrimaryOnClick
				onClose={() => setShowInvoicePaymentWarning(false)}
			>
				<Typography.Paragraph>
					{paymentMethods && paymentMethods.cards.length > 1
						? translate(
								dict =>
									dict.accountUM.changePlan.invoiceAlertModal
										.warningTextMoreCards,
								false,
								{ cardsNumber: paymentMethods.cards.length }
						  )
						: translate(
								dict => dict.accountUM.changePlan.invoiceAlertModal.warningText
						  )}
				</Typography.Paragraph>
				<Typography.Paragraph fontweight={w => w.bold}>
					{translate(dict => dict.accountUM.changePlan.invoiceAlertModal.confirmText)}
				</Typography.Paragraph>
			</Modal>
		</>
	);
}

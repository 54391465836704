import styled, { css } from 'styled-components';

import { MediaQueries } from 'environment';

export const RedirrectContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;

	@media ${MediaQueries.maxWidth.sm} {
		flex-direction: column;
	}
`;
export const Field = styled.div`
	width: 100%;
`;

interface HSpacerProps {
	hasHeightOnMobile?: boolean;
}

export const HSpacer = styled.div<HSpacerProps>`
	${({ hasHeightOnMobile = true }) => css`
		margin-right: 2.4rem;

		@media ${MediaQueries.maxWidth.sm} {
			margin-right: 0;
			height: ${hasHeightOnMobile ? '1.6rem' : 0};
		}
	`}
`;

import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { Table as DefaultTable } from 'components/UI/Table/Table';
import { Cell as DefaultCell, Row as DefaultRow } from 'components/UI/Table/Table.style';
import { Pagination as DefaultPagination } from 'components/UI/Pagination';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
`;

interface WrapperProps {
	active: boolean;
}

export const Cell = styled(DefaultCell)<WrapperProps>`
	.custom-dropdown-container:hover > div {
		color: initial;
	}
	${({ active }) =>
		active &&
		css`
			background-color: ${Colors.primary.disabled};
		`}

	:hover {
		cursor: pointer;
		background-color: ${Colors.primary.disabled};
	}
`;

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	overflow-x: auto;

	/* @AA this is for windows to be able to see scrollbar */
	@media ${MediaQueries.maxWidth.md} {
		margin-bottom: 2.4rem;
	}

	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		min-width: 5.6rem;
		max-width: 5.6rem;
		border-top: 0;
	}
`;

export const IconWrapper = styled.div`
	width: 4rem;
`;

type SelectedIconContainerProps = {
	visible: boolean;
};

export const SelectedIconContainer = styled.div<SelectedIconContainerProps>`
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const TextContainer = styled.div`
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	min-width: 24rem;
`;

export const Row = styled(DefaultRow)`
	:hover {
		background-color: none !important;
	}
`;

export const Table = styled(DefaultTable)`
	tbody tr:hover {
		background-color: none !important;
	}
`;

export const Pagination = styled(DefaultPagination)`
	width: fit-content;
`;

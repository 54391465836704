import { useCustomEventListener } from 'helpers/events';
import { navigationHubEvent } from 'helpers/navigation';
import { useNavigation } from 'hooks/navigation';

export function NavigationHub() {
	const { routes, promOrProject, navigate, replace } = useNavigation();

	useCustomEventListener(
		navigationHubEvent,
		{
			onListen: payload => {
				if (payload.navigate) return navigate(payload.navigate({ routes, promOrProject }));
				if (payload.replace) return replace(payload.replace({ routes, promOrProject }));
			}
		},
		[]
	);

	return null;
}

import { getFormGroupVariableNames } from 'helpers/forms';
import { useVariablesByName } from 'hooks/store';
import { FormGroup, FormElements } from 'store/data/forms';

interface Props {
	formGroup: FormGroup;
	elements: FormElements;
}

export function useIsFormGroupEmpty({ formGroup, elements }: Props) {
	const groupVariableNames = getFormGroupVariableNames(formGroup, elements);
	const groupVariables = useVariablesByName(groupVariableNames, {
		initial: true
	});

	const isEmpty = groupVariables.length === 0;

	return { isEmpty };
}

import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { useMemo } from 'react';
import { CreditCardBrands } from 'types/index';

interface CreditCardLogo {
	brand: CreditCardBrands;
}

export function CreditCardLogo({ brand }: CreditCardLogo) {
	const cardLogoSVG = useMemo(() => {
		switch (brand) {
			case CreditCardBrands.Visa:
				return Svgs.VisaLogo;
			case CreditCardBrands.VisaElectron:
				return Svgs.VisaElectronLogo;
			case CreditCardBrands.Maestro:
				return Svgs.MaestroLogo;
			case CreditCardBrands.Mastercard:
				return Svgs.MastercardLogo;
			default:
				return null;
		}
	}, [brand]);

	return cardLogoSVG && <Icon svg={cardLogoSVG} size={s => s.xl} />;
}

import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	color: ${Colors.text.tableMain};
	background-color: ${Colors.background.disabledSecondary};

	padding: 1.2rem;

	font-weight: 800;
	font-size: 1.4rem;

	margin-bottom: 1.2rem;

	border-radius: 0.4rem;
	border: 1px solid ${Colors.background.disabledSecondary};
`;

import { useState, useEffect, useRef, useMemo } from 'react';
import { Svgs } from 'environment';
import { Form } from 'store/data/forms';
import {
	Container,
	ListWrapper,
	FormsList,
	ListContainer,
	NavigationIcon,
	ScrollWrapper
} from './FormsListDrawer.style';
import { DefaultForm } from './Form';
import { entryFormChangePageSetEvent, entryFormChangePageEvent } from 'helpers/entries';
import {
	useFormId,
	useSelectedSeriesEntry,
	useVariablesData,
	useEntryId,
	useCurrentRevision
} from 'hooks/store';
import { useWindowSize, useHorizontalScroll } from 'hooks/ui';

interface Props {
	open: boolean;
	forms: Form[];
	setName?: string;
}

export function FormsListDrawer({ open, forms, setName }: Props) {
	const listRef = useRef<HTMLDivElement>(null);
	const size = useWindowSize();
	const { handleRef } = useHorizontalScroll();

	const [formId, setFormId] = useFormId();

	const [selectedSeriesEntry, setSelectedSeriesEntry] = useSelectedSeriesEntry();

	const variablesData = useVariablesData({ initial: true });

	const [navigationVisible, setNavigationVisible] = useState(false);

	const scopeFormId = setName ? selectedSeriesEntry.subFormId : formId;

	const [entryId] = useEntryId();
	const [revision] = useCurrentRevision();

	const isRevisionSelected = useMemo(
		() => (revision ? revision.revisionId !== entryId : false),
		[revision, entryId]
	);

	useEffect(() => {
		handleRef(listRef.current);
	}, [open]);

	useEffect(() => {
		if (!listRef.current) return;

		const { clientWidth, scrollWidth } = listRef.current;

		const scrollExists = scrollWidth !== clientWidth;

		if (navigationVisible !== scrollExists) setNavigationVisible(scrollExists);
	}, [size, forms]);

	function handleScroll(direction: string) {
		if (!listRef.current) return;

		if (direction === 'left') {
			listRef.current.scrollLeft -= 80;
		} else {
			listRef.current.scrollLeft += 80;
		}
	}

	function handleFormClick(id: string) {
		if (scopeFormId !== id) {
			if (setName) {
				setSelectedSeriesEntry({
					...selectedSeriesEntry,
					subFormId: id
				});
			} else {
				setFormId(id);
			}
		}

		// RESET PAGINATION
		const changePageEvent = setName ? entryFormChangePageSetEvent : entryFormChangePageEvent;

		changePageEvent().dispatch({ pageIndex: 0 });
	}

	function isFormSelected(id: string): boolean {
		return scopeFormId === id;
	}

	if (!open) return null;

	return (
		<Container>
			<ListWrapper>
				<ScrollWrapper>
					<ListContainer>
						{/* SCROLL LEFT BUTTON */}
						<NavigationIcon
							visible={navigationVisible}
							svg={Svgs.ChevronLeft}
							onClick={() => handleScroll('left')}
						/>

						{/* LIST */}
						<FormsList ref={listRef}>
							{forms.map((form, index) => (
								<DefaultForm
									hasChanges={
										Object.values(form.usedVariables).filter(
											variable =>
												variable &&
												!!revision?.changes.variableNames.includes(variable)
										).length > 0 && !!isRevisionSelected
									}
									form={form}
									key={form.id}
									lastForm={index === forms.length - 1}
									isFormSelected={isFormSelected}
									handleFormClick={handleFormClick}
									variablesData={variablesData}
									revision={revision}
									scopeFormId={scopeFormId}
								/>
							))}
						</FormsList>

						{/* SCROLL RIGHT BUTTON */}
						<NavigationIcon
							visible={navigationVisible}
							svg={Svgs.ChevronRight}
							onClick={() => handleScroll('right')}
						/>
					</ListContainer>
				</ScrollWrapper>
			</ListWrapper>
		</Container>
	);
}

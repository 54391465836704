import { useMatchProms } from 'hooks/navigation';
import { useSelector } from 'hooks/utils';
import { useMemo } from 'react';
import { selectProjectsById, selectAllProjectsIds, selectAllPromsIds } from 'store/data/projects';
import { SelectItem } from 'types/index';

export function useAllProjectsNameId() {
	const matchProms = useMatchProms();

	const allProjectsIds = useSelector(state => selectAllProjectsIds(state.data.projects));
	const allPromsIds = useSelector(state => selectAllPromsIds(state.data.projects));
	const projectsById = useSelector(state => selectProjectsById(state.data.projects));

	const selectItems = useMemo(() => {
		const items: SelectItem[] = [];

		const ids = matchProms ? allPromsIds : allProjectsIds;

		ids.forEach(projectId => {
			const project = projectsById[projectId];

			const item: SelectItem = {
				label: project.projectName,
				value: projectId
			};

			items.push(item);
		});

		return items;
	}, [matchProms, allProjectsIds, allPromsIds, projectsById]);

	return selectItems;
}

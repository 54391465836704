import { Responsive } from 'components/UI/Table/Table';
import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled, { css } from 'styled-components';

export const ResponsiveWrapper = styled(Responsive)`
	margin: 2.4rem;

	border: none;

	.table-checkbox {
		min-height: 0;

		> div {
			padding: 0 1.2rem;
		}
	}

	.select-column {
		width: 5.6rem;
		min-width: 5.6rem;
		max-width: 5.6rem;
		border-top: 0;
	}

	.errored {
		background-color: rgba(255, 88, 93, 0.1);
	}
`;

type TableProps = {
	children: React.ReactNode;
	tableRef?: React.RefObject<HTMLTableElement>;
	noDesign?: boolean;
	fullWidth?: boolean;
	width?: number;
	minWidth?: number;
	maxWidth?: number;
	stickyHead?: boolean;
	paddingX?: number;
	paddingY?: number;
};

export const Table = styled.div<TableProps>`
	${({ noDesign, fullWidth, width, minWidth, maxWidth, stickyHead, paddingX, paddingY }) => css`
		${noDesign &&
		css`
			/* HANDLE THEAD COLUMN SPACING WITHOUT BORDERS */
			div[data-role='head'] div[data-role='row'] div[data-role='column'] {
				padding: 1.3rem 1.6rem 0.6rem 0.8rem;
				font-weight: 500;
			}
		`}

		${!noDesign &&
		css`
			/* SET THEAD COLUMN BORDER TOP FOR STICKY HEADER BEHAVIOUR */
			div[data-role='head'] div[data-role='row'] div[data-role='column'] {
				border-top: 0.1rem solid ${Colors.background.disabled};
				border-bottom: 0.1rem solid ${Colors.background.disabled};
			}

			/* CELL BORDER */
			div[data-role='row'] div[data-role='cell']:not(:last-child),
			div[data-role='row'] div[data-role='column']:not(:last-child) {
				border-right: 0.1rem solid ${Colors.background.disabled};
			}

			/* HANDLE THEAD COLUMN SPACING WITH BORDERS */
			div[data-role='row'] div[data-role='column'] {
				padding: 1.2rem 1.6rem 0.8rem 0.8rem;
				font-weight: 500;
			}
		`}

		${fullWidth &&
		css`
			width: 100%;
		`}
		${width &&
		css`
			width: ${width}rem;
		`}
		${maxWidth &&
		css`
			max-width: ${maxWidth}rem;
		`}
		${minWidth &&
		css`
			min-width: ${minWidth}rem;
		`}
		${stickyHead &&
		css`
			div[data-role='head'] div[data-role='column'] {
				background-color: white;
				position: sticky;
				top: 0;
				z-index: 10;
			}
		`}
		${paddingX &&
		css`
			padding: 0 ${paddingX}rem;
		`}
		${paddingY &&
		css`
			padding: ${paddingY}rem 0;
		`}
	`}
`;

export const TableHead = styled.div`
	${getTypographyCSS(t => t.Paragraph)}
`;
interface CellProps {
	textCenter?: boolean;
	textRight?: boolean;
	bold?: boolean;
	width?: number;
	height?: number;
	minWidth?: number;
	maxWidth?: number;
	noWrap?: boolean;
	alignTop?: boolean;
	alignMiddle?: boolean;
	alignBottom?: boolean;
	paddingLeft?: number;
	paddingRight?: number;
	opacity?: number;
	color?: string;
	relativePosition?: boolean;
}

export const TableCell = styled.div<CellProps>`
	${({
		textCenter,
		textRight,
		bold,
		width,
		height,
		minWidth,
		maxWidth,
		noWrap,
		alignTop,
		alignMiddle,
		alignBottom,
		paddingLeft,
		paddingRight,
		opacity,
		color,
		relativePosition
	}) => css`
		/* TYPOGRAPHY SETTER */
		${getTypographyCSS(t => t.Paragraph)}

		padding: 0.6rem 0.8rem;

		${bold &&
		css`
			font-weight: bold;
		`}

		${textCenter &&
		css`
			text-align: center;
		`}
		${textRight &&
		css`
			text-align: right;
		`}
		${width &&
		css`
			width: ${width}rem;
		`}
		${height &&
		css`
			height: ${height}rem;
		`}
		${minWidth &&
		css`
			min-width: ${minWidth}rem;
		`}
		${maxWidth &&
		css`
			max-width: ${maxWidth}rem;
		`}
		${noWrap &&
		css`
			overflow-x: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		`}
		${alignTop &&
		css`
			vertical-align: top;
		`}
		${alignMiddle &&
		css`
			vertical-align: middle;
		`}
		${alignBottom &&
		css`
			vertical-align: bottom;
		`}
		${paddingLeft !== undefined &&
		css`
			padding-left: ${paddingLeft}rem;
		`}
		${paddingRight !== undefined &&
		css`
			padding-right: ${paddingRight}rem;
		`}
		${opacity &&
		css`
			opacity: ${opacity};
		`}
		${color &&
		css`
			color: ${color};
		`}
		${relativePosition &&
		css`
			position: relative;
		`}
	`}
`;

type CollapsibleContainerProps = {
	isOpen: boolean;
};

export const CollapsibleContainer = styled.div<CollapsibleContainerProps>`
	overflow: hidden !important;
	width: 100%;

	padding: 0 1.2rem;

	height: ${props => (props.isOpen ? 'contain' : '0')} !important;
`;

type TableRowProps = {
	clickable?: boolean;
	flex?: boolean;
	padding?: boolean;
	borderBottom?: boolean;
	twoColumnRow?: boolean;
	borderRadius?: boolean;
};

export const TableRow = styled.div<TableRowProps>`
	${getTypographyCSS(t => t.Paragraph)}

	display: ${({ flex }) => (flex ? 'flex' : 'grid')};

	${({ flex, twoColumnRow }) =>
		!flex && !twoColumnRow
			? `
		grid-template-columns: 19.26% 29.25% 51.49%;
	`
			: `grid-template-columns: 50% 50%;`}

	width: 100%;

	${({ borderBottom }) =>
		borderBottom &&
		`
		border-bottom: 0.1rem solid ${Colors.background.disabled};
	`}

	& div[data-role='column'] {
		color: ${Colors.text.tableMain};
		padding: ${({ padding }) => (padding ? '0.9rem 0.7rem 0.9rem 0.8rem' : '0')};
	}

	${({ clickable }) =>
		clickable &&
		`
		&:hover {
			background-color: ${Colors.primary.disabled};
		}
	`}

	${({ borderRadius }) =>
		borderRadius &&
		`
		border-radius: 0.4rem;
	`}
`;

export const List = styled.div`
	overflow-x: auto;

	max-height: 34rem;
	margin-bottom: 1.2rem;

	border: 1px solid ${Colors.background.disabledSecondary};

	& div[data-role='row']:nth-child(odd) {
		background-color: ${Colors.background.disabled};
	}

	& div[data-role='row']:hover {
		background-color: ${Colors.primary.disabled};

		div[data-role='cell']:not(:last-child) {
			border-right: 0.1rem solid ${Colors.primary.disabled};
		}
	}
`;

export const ClickableBox = styled.div`
	user-select: none;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 1.2rem;

	border-radius: 0.4rem;

	& .error-table-tag {
		position: relative;
		z-index: 10;

		div {
			background-color: ${Colors.background.error} !important;
		}

		span {
			color: ${Colors.text.error};
		}
	}

	&:hover {
		background-color: ${Colors.primary.disabled};
		cursor: pointer;

		div[data-role='cell']:not(:last-child) {
			border-right: 0.1rem solid ${Colors.primary.disabled};
		}
	}
`;

type TableHeaderCellProps = {
	paddingLeft?: number;
};

export const TableHeaderCell = styled.th<TableHeaderCellProps>`
	padding-left: ${props => (props.paddingLeft ? `${props.paddingLeft}rem` : '0')};
`;

export interface TableColumnProps {
	css?: string;
	textCenter?: boolean;
	textRight?: boolean;
	empty?: boolean;
	width?: number;
	minWidth?: number;
	maxWidth?: number;
	height?: number;
	noWrap?: boolean;
	paddingLeft?: number;
	paddingRight?: number;
	clickable?: boolean;
}

export const TableColumnComponent = styled.div<TableColumnProps>`
	${({
		css: _css,
		textCenter,
		textRight,
		empty,
		width,
		minWidth,
		maxWidth,
		height,
		noWrap,
		paddingLeft,
		paddingRight,
		clickable
	}) => css`
		/* TYPOGRAPHY SETTER */
		${getTypographyCSS(t => t.Caption)}
		color: ${Colors.primary.normal};

		text-align: start;

		${textCenter &&
		css`
			text-align: center;
		`}
		${textRight &&
		css`
			text-align: right;
		`}
		${width &&
		css`
			width: ${width}rem;
		`}
		${maxWidth &&
		css`
			max-width: ${maxWidth}rem;
		`}
		${minWidth &&
		css`
			min-width: ${minWidth}rem;
		`}
		${height &&
		css`
			height: ${height}rem;
		`}
		${empty &&
		css`
			height: ${height ? height : 4}rem;
		`}
		${noWrap &&
		css`
			overflow-x: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		`}
		${paddingLeft !== undefined &&
		css`
			padding-left: ${paddingLeft}rem;
		`}
		${paddingRight !== undefined &&
		css`
			padding-right: ${paddingRight}rem;
		`}

		${clickable &&
		css`
			cursor: pointer;
		`}

		:hover .column-filter {
			visibility: visible;
		}

		/* CUSTOM CSS */
		${_css}
	`}
`;

interface FilterWrapperProps {
	visible?: boolean;
}

export const FilterWrapper = styled.div<FilterWrapperProps>`
	padding: 0;
	width: auto;
	display: flex;
	margin-left: auto;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const ErrorWrapper = styled.div`
	position: relative;
	z-index: 1;
	border-radius: 0.4rem;
	border: 0.1rem solid ${Colors.numericEditor.initial};
	margin-bottom: 1.2rem;
`;

export const WarningMessage = styled.div`
	display: flex;
	gap: 0.8rem;

	margin-top: 0.4rem;

	${getTypographyCSS(t => t.Paragraph)}

	width: 100%;

	background-color: ${Colors.background.disabledSecondary};

	padding: 0.9rem 0.8rem;

	border-radius: 4px;
	border: solid 1px ${Colors.background.disabledSecondary};
`;

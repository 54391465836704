import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, deleteVariableSetAggregationRule } from 'store/data/variables';

type HandlerInput = Parameters<typeof deleteVariableSetAggregationRule>[0];

export function useDeleteVariableSetAggregationRule(
	ruleName: string
): Operation<(payload: HandlerInput) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(
		ActionTypes.DELETE_VARIABLE_SET_AGGREGATION_RULE,
		{
			ruleName
		}
	);

	function handler(payload: HandlerInput) {
		if (loading) return;
		if (error) resetError();

		dispatch(deleteVariableSetAggregationRule(payload));
	}

	return [{ loading, error }, handler];
}

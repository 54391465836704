import styled, { css } from 'styled-components';

import { getSidebarWidth } from 'components/UI/Grid';

interface ContainerProps {
	isDrawerOpen: boolean;
}
export const Container = styled.div<ContainerProps>`
	display: flex;
	justify-content: space-between;

	${({ isDrawerOpen }) =>
		isDrawerOpen &&
		css`
			${getSidebarWidth({
				numberOfColumns: {
					xl: 14,
					l: 10,
					s: 1
				},
				noContainerGutter: true
			})}
		`}
`;

import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { FlexCellLabel, FlexCellType, FlexRow } from '../DataToEntries/DataToEntries.style';

import { TimeZoneLabel, TimeZoneSettingsContainer } from './TimeZoneOptions.style';
import { useTranslation } from 'hooks/store';
import { Spacer } from 'components/UI/Spacer';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { SelectItem } from 'types';
import { Button } from 'components/UI/Interactables/Button';
import { timezones } from 'helpers/projects/imports/importTimezone';

interface Props {
	value: SelectItem;
	onChange: (value: string) => void;
	applyToAll: () => void;
}

export function TimeZoneOptions({ value, onChange, applyToAll }: Props) {
	const { translate } = useTranslation();
	return (
		<>
			<TimeZoneSettingsContainer>
				<TimeZoneLabel style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
					<span>Time zone</span>
					<InfoTooltip
						iconVisible={true}
						zIndex={999}
						text={translate(({ formDesigner }) => formDesigner.activateFormTooltip)}
						position="top"
					/>
				</TimeZoneLabel>
				<FlexRow
					style={{
						marginBottom: 0
					}}
				>
					<FlexCellLabel>
						<CreatableSelect
							canClear={true}
							onClear={() => onChange('')}
							id={`global_timezone_select`}
							items={timezones}
							onValueSelected={value => value && onChange(value)}
							value={value}
						/>
					</FlexCellLabel>
					<div style={{ width: 24 }} />
					<FlexCellType>
						<Button
							onClick={applyToAll}
							title="Apply to all"
							variant={v => v.outline}
						/>
					</FlexCellType>
				</FlexRow>
			</TimeZoneSettingsContainer>
			<Spacer size={s => s.m} />
		</>
	);
}

import styled, { css } from 'styled-components';

interface OverflowInterface {
	scroll?: boolean;
}
export const OverflowYContainer = styled.div<OverflowInterface>`
	${({ scroll }) =>
		scroll &&
		css`
			height: 60rem;
		`};
`;

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setEntriesSearchTerm, SetEntriesSearchTermAction } from 'store/data/entries';
import { ActionPayload } from 'store/types';

export function useEntriesSearchTerm(): LocalOperationResult<
	string,
	(payload: ActionPayload<SetEntriesSearchTermAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.entries.metadata.term);

	const handler = (payload: ActionPayload<SetEntriesSearchTermAction>) => {
		dispatch(setEntriesSearchTerm(payload));
	};

	return [data, handler];
}

import { useEffect } from 'react';
import { HookOptions, OperationResult } from 'hooks/store/types';
import { ActionTypes, EntryDraft, getEntryDraft, selectEntryDraft } from 'store/data/entries';
import { useDispatch, useSelector } from 'hooks/utils';
import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useEntryId } from './useEntryId';

export function useEntryDraft(options?: HookOptions): OperationResult<EntryDraft> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};

	const [entryId] = useEntryId();
	const { hasDatasetWriteAccess } = usePermissions();
	const isProjectValid = useIsProjectValid();

	const isLatestEntryFetched = useSelector(state => state.data.entries.rows.latestFetched);

	const { data, fetched } = useSelector(state => selectEntryDraft(state.data.entries));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_ENTRY_DRAFT);

	useEffect(() => {
		if (
			!loading &&
			!error &&
			!fetched &&
			isProjectValid &&
			hasDatasetWriteAccess &&
			!lazy &&
			(entryId ? isLatestEntryFetched : true)
		) {
			handler();
		}
	}, [
		loading,
		error,
		fetched,
		isProjectValid,
		hasDatasetWriteAccess,
		lazy,
		isLatestEntryFetched,
		entryId
	]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getEntryDraft());
	}

	return [{ loading, error, data, fetched }, handler];
}

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, getDistributions, selectDistributionsIds } from 'store/data/patients';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

export function useDistributions(): OperationResult<string[] | undefined> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const data = useSelector(state => selectDistributionsIds(state.data.patients));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_DISTRIBUTIONS, {
		projectId
	});

	useEffect(() => {
		if (!error && !loading && !data && isProjectValid) {
			handler();
		}
	}, [error, loading, data, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getDistributions());
	}

	return [{ loading, error, data }, handler];
}

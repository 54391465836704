import { useState, useRef, useMemo, useEffect, Fragment } from 'react';
import { isEqual } from 'lodash';

import { AggregationRule } from 'api/data/variables';

import { systemGeneratedVariables, systemGeneratedVariableLabels, STATUS_COLUMN } from 'consts';
import { Svgs, Colors } from 'environment';

import {
	Container,
	ActionComponent,
	FloatingContainer,
	FloatingContainerTitle,
	ItemsContainer
} from './ColumnSettings.style';
import { useOutsideClick, useKeyPress, useDebounce, useMutableSeriesState } from 'hooks/utils';
import { AggregationRuleType } from 'api/data/variables';
import { useSeriesTableVisibleColumns } from 'hooks/store/data/entries/useSeriesTableVisibleColumns';
import { VariableSetDataArray } from 'store/data/variables';
import {
	filterVariablesDataArrayBySearchTerm,
	getAggregationRuleTypeLabel,
	variablesDataArrayJSXIterator
} from 'helpers/variables/variableHelpers';
import { hasMatches } from 'helpers/strings';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { Spacer } from 'components/UI/Spacer';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Gap } from 'components/UI/Gap';
import { useTranslation, useVariables } from 'hooks/store';

interface Props {
	className?: string;
	seriesData: VariableSetDataArray;
	seriesColumns: string[];
}

export function ColumnSeriesSettings({ className, seriesData, seriesColumns }: Props) {
	const { translate } = useTranslation();

	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick(handleClose, [ref]);

	useKeyPress({ onEscapeKeyPress: handleClose }, { listen: open, noModalsOpened: true });

	const [
		{
			data: { variablesDataArray, variablesMap, groupsMap, variableSetsMap }
		}
	] = useVariables({
		lazy: true,
		initial: true,
		omit: { promGenerated: true }
	});

	const [visibleColumnNames, setVisibleColumnNames] = useSeriesTableVisibleColumns();

	const [draftVisibleColumnNames, setDraftVisibleColumnNames] =
		useMutableSeriesState(visibleColumnNames);

	// SYNC `draftVisibleColumnNames` STATE
	useEffect(() => {
		const hasChanges = !isEqual(draftVisibleColumnNames, visibleColumnNames);

		if (hasChanges) setDraftVisibleColumnNames(visibleColumnNames);
	}, [visibleColumnNames]);

	useDebounce(() => {
		const hasChanges = !isEqual(draftVisibleColumnNames, visibleColumnNames);

		if (!hasChanges) return;

		const sortedVisibleColumnNames = getSortedVisibleColumnNames(draftVisibleColumnNames);

		setVisibleColumnNames(sortedVisibleColumnNames);
	}, [draftVisibleColumnNames]);

	function getSortedVisibleColumnNames(columnNames: string[]) {
		return [...columnNames].sort((a, b) => seriesColumns.indexOf(a) - seriesColumns.indexOf(b));
	}

	function handleToggleOpen() {
		const flag = !open;

		setOpen(state => !state);

		if (searchTerm && !flag) resetTerm();
	}

	function handleClose() {
		setOpen(false);
		resetTerm();
	}

	function resetTerm() {
		setSearchTerm('');
	}

	/**
	 * Toggles all - `checked` / `un-checked`
	 */
	function handleToggleAll() {
		const columnsCheckedState = getColumnsCheckedState();

		if (columnsCheckedState.all) return setDraftVisibleColumnNames([]);

		const allColumnNames = seriesColumns;

		setDraftVisibleColumnNames(allColumnNames);
	}

	function removeColumnsFilter() {
		handleClose();
		setDraftVisibleColumnNames([]);
		if (visibleColumnNames.length) setVisibleColumnNames([]);
	}

	function getColumnsCheckedState() {
		const allColumnNames = seriesColumns;

		const numberOfCheckedColumns = draftVisibleColumnNames.length;
		const numberOfAllCheckedColumns = allColumnNames.length;

		const hasCheckedColumns = numberOfCheckedColumns > 0;

		const all = hasCheckedColumns && numberOfCheckedColumns === numberOfAllCheckedColumns;
		const partial = hasCheckedColumns && numberOfCheckedColumns !== numberOfAllCheckedColumns;

		return { all, partial };
	}

	function handleSelectItem(
		columnName: string,
		options: { isGroup?: boolean; isVariableSet?: boolean } = {}
	) {
		const { isGroup, isVariableSet } = options;

		setDraftVisibleColumnNames(state => {
			const isChecked = state.includes(columnName);

			if (isVariableSet) {
				const variableSet = variableSetsMap[columnName];
				const subColumnNames = variableSet.aggregationRules.map(
					aggregationRule => aggregationRule.name
				);

				const allSubColumnsChecked = subColumnNames.every(subColumnName =>
					state.includes(subColumnName)
				);

				// REMOVE SUB COLUMNS FROM LIST
				if (allSubColumnsChecked) {
					return state.filter(item => !subColumnNames.includes(item));
				}

				// ADD SUB COLUMNS TO LIST
				subColumnNames.forEach(subColumnName => {
					const isAlreadyChecked = state.includes(subColumnName);

					if (!isAlreadyChecked) state.push(subColumnName);
				});

				return;
			}

			if (isGroup) {
				const group = groupsMap[columnName];
				const subColumnNames = group.variablesBelongingToGroup;

				const allSubColumnsChecked = subColumnNames.every(subColumnName =>
					state.includes(subColumnName)
				);

				// REMOVE SUB COLUMNS FROM LIST
				if (allSubColumnsChecked) {
					return state.filter(item => !subColumnNames.includes(item));
				}

				// ADD SUB COLUMNS TO LIST
				subColumnNames.forEach(subColumnName => {
					const isAlreadyChecked = state.includes(subColumnName);

					if (!isAlreadyChecked) state.push(subColumnName);
				});

				return;
			}

			// REMOVE FROM LIST
			if (isChecked) return state.filter(item => item !== columnName);

			// ADD TO LIST
			state.push(columnName);
		});
	}

	function isColumnChecked(
		columnName: string,
		options: { isGroup?: boolean; isVariableSet?: boolean } = {}
	) {
		const { isGroup, isVariableSet } = options;

		let checked = false;

		if (isVariableSet) {
			const variableSet = variableSetsMap[columnName];
			const subColumnNames = variableSet.aggregationRules.map(
				aggregationRule => aggregationRule.name
			);

			const hasSubColumns = subColumnNames.length > 0;

			const allSubColumnsChecked = subColumnNames.every(subColumnName =>
				draftVisibleColumnNames.includes(subColumnName)
			);

			checked = hasSubColumns && allSubColumnsChecked;
		} else if (isGroup) {
			const group = groupsMap[columnName];
			const subColumnNames = group.variablesBelongingToGroup;

			const hasSubColumns = subColumnNames.length > 0;

			const allSubColumnsChecked = subColumnNames.every(subColumnName =>
				draftVisibleColumnNames.includes(subColumnName)
			);

			checked = hasSubColumns && allSubColumnsChecked;
		} else {
			checked = draftVisibleColumnNames.includes(columnName);
		}

		return checked;
	}

	function isColumnPartiallyChecked(subColumnNames: string[]) {
		let checked = false;

		const hasSubColumns = subColumnNames.length > 0;

		const allSubColumnsChecked = subColumnNames.every(subColumnName =>
			draftVisibleColumnNames.includes(subColumnName)
		);
		const someSubColumnsChecked = subColumnNames.some(subColumnName =>
			draftVisibleColumnNames.includes(subColumnName)
		);

		checked = hasSubColumns && !allSubColumnsChecked && someSubColumnsChecked;

		return checked;
	}

	function getVariableLabel(variableName: string) {
		if (variableName in variablesMap) return variablesMap[variableName].label;

		return '';
	}

	function getFullAggregationLabel(aggregationRule: AggregationRule) {
		const { aggregator } = aggregationRule;

		return `${getVariableLabel(aggregator.variableName)} (${translate(
			dict =>
				dict.variablesPage.aggregationRuleType[
					getAggregationRuleTypeLabel(aggregationRule) as AggregationRuleType
				]
		).toUpperCase()})`;
	}

	const filteredSeriesDataArray = useMemo(
		() =>
			filterVariablesDataArrayBySearchTerm(seriesData, searchTerm, {
				translate
			}),
		[seriesData, searchTerm]
	);

	const isSearchTermValid = searchTerm.trim().length > 0;

	const systemGeneratedColumns = useMemo(() => {
		const columns: { name: string; label: string }[] = [];

		systemGeneratedVariables.forEach(variableName => {
			const hiddenColumns = [systemGeneratedVariables[6], systemGeneratedVariables[7]];

			// Disregard `userProjectOrgId` and the ids
			if (hiddenColumns.includes(variableName)) return;

			const label = systemGeneratedVariableLabels[variableName];

			if (isSearchTermValid) {
				const columnMatched = hasMatches({
					searchTerm,
					keywords: [label]
				});

				if (!columnMatched) return;
			}

			columns.push({ name: variableName, label });
		});

		return columns;
	}, [searchTerm, isSearchTermValid]);

	const statusColumn = useMemo(() => {
		const hasStatusColumn = seriesColumns.includes(STATUS_COLUMN.name);

		if (!hasStatusColumn) return null;

		if (isSearchTermValid) {
			const columnMatched = hasMatches({
				searchTerm,
				keywords: [STATUS_COLUMN.label]
			});

			if (!columnMatched) return null;
		}

		return STATUS_COLUMN;
	}, [searchTerm, isSearchTermValid, seriesColumns]);

	const hasItems = variablesDataArray.length > 0;

	const noSearchResults =
		isSearchTermValid &&
		filteredSeriesDataArray.length === 0 &&
		systemGeneratedColumns.length === 0 &&
		statusColumn === null;

	return (
		<Container ref={ref} className={className}>
			<ActionComponent onClick={handleToggleOpen}>
				<Icon svg={Svgs.Settings} colors={{ color: Colors.primary.normal }} propagate />
				<Icon
					svg={Svgs.ArrowDown}
					size={s => s.m}
					colors={{ color: Colors.primary.normal }}
					propagate
				/>
			</ActionComponent>

			{open && (
				<FloatingContainer>
					<FloatingContainerTitle>
						<Typography.Paragraph>
							{translate(dict => dict.dataset.entries.columnSettings.columnSettings)}
						</Typography.Paragraph>

						<Icon
							className="delete-columns-filter-icon"
							svg={Svgs.Delete}
							colors={{
								color: Colors.text.disabled,
								hover: Colors.primary.normal
							}}
							onClick={removeColumnsFilter}
						/>
					</FloatingContainerTitle>

					<Flex paddingOffset={{ x: 0.8, bottom: 1.6 }} column>
						<SearchInput
							term={searchTerm}
							placeholder={translate(
								dict => dict.dataset.entries.columnSettings.search
							)}
							onChangeTerm={setSearchTerm}
						/>
						<Spacer size={s => s.s} />

						{/* TOGGLE ALL */}
						{hasItems && (
							<>
								<Checkbox
									className="checkbox"
									label={translate(
										dict => dict.dataset.entries.columnSettings.checkUncheck
									)}
									checked={getColumnsCheckedState().all}
									partial={getColumnsCheckedState().partial}
									onClick={handleToggleAll}
								/>
								<Spacer size={s => s.s} />
							</>
						)}

						<ItemsContainer>
							{/* COLUMNS LIST */}
							<Gap marginGap={{ bottom: 0.8 }} notLastChild>
								{/* STATUS COLUMN */}
								{statusColumn && (
									<Checkbox
										className="checkbox"
										label={statusColumn.label}
										checked={isColumnChecked(statusColumn.name)}
										paddingOffset={{ left: 0.8 }}
										onClick={() => handleSelectItem(statusColumn.name)}
									/>
								)}

								{/* VARIABLE/GROUP/SET COLUMNS */}
								{variablesDataArrayJSXIterator(
									filteredSeriesDataArray, //here it takes all data
									/**
									 * VARIABLE
									 */
									variable => (
										<Checkbox
											key={`variable_${variable.name}`}
											className="checkbox"
											label={variable.label}
											checked={isColumnChecked(variable.name)}
											paddingOffset={{ left: 0.8 }}
											onClick={() => handleSelectItem(variable.name)}
										/>
									),
									/**
									 * GROUP
									 */
									groupData => {
										const hasVariables = groupData.groupVariables.length > 0;

										if (!hasVariables) return null;

										return (
											<Fragment key={`group_${groupData.groupName}`}>
												<Checkbox
													className="checkbox"
													label={groupData.groupLabel}
													checked={isColumnChecked(groupData.groupName, {
														isGroup: true
													})}
													partial={isColumnPartiallyChecked(
														groupData.groupVariables.map(g => g.name)
													)}
													onClick={() =>
														handleSelectItem(groupData.groupName, {
															isGroup: true
														})
													}
												/>

												{groupData.groupVariables.map(variable => (
													<Checkbox
														key={`group_${groupData.groupName}-variable_${variable.name}`}
														className="checkbox"
														label={variable.label}
														checked={isColumnChecked(variable.name)}
														paddingOffset={{
															left: 0.8
														}}
														onClick={() =>
															handleSelectItem(variable.name)
														}
													/>
												))}
											</Fragment>
										);
									},
									/**
									 * VARIABLE SET
									 */
									variableSetData => {
										const hasAggregationRules =
											variableSetData.aggregationRules.length > 0;

										if (!hasAggregationRules) return null;

										return (
											<Fragment
												key={`variable_set_${variableSetData.setName}`}
											>
												<Checkbox
													className="checkbox"
													label={variableSetData.setLabel}
													checked={isColumnChecked(
														variableSetData.setName,
														{
															isVariableSet: true
														}
													)}
													partial={isColumnPartiallyChecked(
														variableSetData.aggregationRules.map(
															aggregationRule => aggregationRule.name
														)
													)}
													onClick={() =>
														handleSelectItem(variableSetData.setName, {
															isVariableSet: true
														})
													}
												/>

												{variableSetData.aggregationRules.map(
													aggregationRule => (
														<Checkbox
															key={`variable_set_${variableSetData.setName}-aggregation_rule_${aggregationRule.name}`}
															className="checkbox"
															label={getFullAggregationLabel(
																aggregationRule
															)}
															checked={isColumnChecked(
																aggregationRule.name
															)}
															paddingOffset={{
																left: 0.8
															}}
															onClick={() =>
																handleSelectItem(
																	aggregationRule.name
																)
															}
														/>
													)
												)}
											</Fragment>
										);
									}
								)}

								{/* SYSTEM GENERATED COLUMNS */}
								{systemGeneratedColumns.map(({ name, label }) => (
									<Checkbox
										key={`system_generated_${name}`}
										className="checkbox"
										label={label}
										checked={isColumnChecked(name)}
										paddingOffset={{ left: 0.8 }}
										onClick={() => handleSelectItem(name)}
									/>
								))}
							</Gap>

							{/* NO RESULTS */}
							{noSearchResults && (
								<Typography.Caption marginOffset={{ left: 0.4 }}>
									{translate(
										dict => dict.dataset.entries.columnSettings.noResults
									)}
								</Typography.Caption>
							)}
						</ItemsContainer>
					</Flex>
				</FloatingContainer>
			)}
		</Container>
	);
}

import styled from 'styled-components';

export const FileNameContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 2.8rem;
`;

export const FileTypeButtons = styled.div`
	visibility: hidden;
	display: flex;
	align-items: center;
`;

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, rejectSubscriptionInvitationNotification } from 'store/data/notifications';

export function useRejectSubscriptionInvitationNotification(
	notificationIds: string[]
): Operation<(userId: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(
		ActionTypes.REJECT_SUBSCRIPTION_INVITATION_NOTIFICATION,
		notificationIds
	);

	function handler(userId: string) {
		dispatch(rejectSubscriptionInvitationNotification(notificationIds, userId));
	}

	return [{ loading, error }, handler];
}

import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/data/projects';
import { useAPI } from 'hooks/api';
import { apiFactory } from 'api';

export function useAPITransferEnterpriseProjectsOwnership(): ApiOperation<
	void,
	(userId: string, projectIds: string[]) => void
> {
	const [{ error, loading, fetched }, transferProjectsOwnership, resetHandler] = useAPI<void>({
		promiseFn: undefined,
		lazy: true,
		initialData: undefined,
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: true
		},
		activityType: ActionTypes.ENTERPRISE_TRANSFER_PROJECTS_OWNERSHIP
	});

	function promiseFn(userId: string, projectIds: string[]) {
		return apiFactory().data.projects().transferProjectsOwnership(userId, projectIds);
	}

	function handler(userId: string, projectIds: string[]) {
		transferProjectsOwnership(() => promiseFn(userId, projectIds));
	}

	return [{ data: undefined, error, loading, fetched }, handler, resetHandler];
}

import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { confirmStartedFillingForm } from 'store/data/patients';
import { ActionTypes } from 'store/data/patients';

export function useConfirmStartedFillingForm(): Operation {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.CONFIRM_STARTED_FILLING_FORM);

	function handler() {
		dispatch(confirmStartedFillingForm());
	}

	return [{ loading, error }, handler];
}

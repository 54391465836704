import { useEffect, useLayoutEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';

import { CardPositionManual, IndexPayload } from 'components/Account';

import { isOccupiedByCard, isPlaceholderFree } from 'helpers/adminDashboard';

import {
	APIWidthSize,
	HeightSizes,
	LayoutRowOrderType,
	SetSizePayloadDynamic,
	WidgetHeightSize,
	WidthSizes
} from 'store/data/projectDashboard';
import { useDashboardById } from 'hooks/store';

import { PlaceholderContainer, RowWrapper } from '../style';
import { DropzoneDynamic } from './DropzoneDynamic';
import { DraggableCardDynamic } from './DraggableCardDynamic';
import { CardDraggableResizablePropsDynamic } from '../../AdminDashboardAutomatic';

interface Props {
	cardsRows: LayoutRowOrderType;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	setDimensions: (payload: SetSizePayloadDynamic) => void;
	moveCardToLayout: (indexPayload: IndexPayload | null) => void;
	removeCard: (position: CardPositionManual) => void;
}

export function CardsGridDynamic({
	cardsRows,
	widthSizes,
	heightSizes,
	setDimensions,
	moveCardToLayout,
	removeCard
}: Props) {
	const mainRef = useRef<HTMLDivElement>(null);

	const [
		{
			data: { dashboard }
			// loading: loadingProjectDashboard
			// fetched: isDashboardFetched
		}
	] = useDashboardById();

	// Get card sizes for correct dropzones - dragging state
	const [
		{
			isDragging,
			isDraggingMaxHeight,
			isDraggingSecondWidth,
			isDraggingThirdWidth,
			isDraggingForthWidth,
			isDraggingFifthWidth,
			isDraggingSixthWidth,
			isDraggingSeventhWidth,
			draggedId
		},
		drop
	] = useDrop<any, any, any>({
		accept: 'card',
		collect: monitor => {
			const isDragging = !!monitor.getItem();
			const card = monitor.getItem()
				? (monitor.getItem() as CardDraggableResizablePropsDynamic)
				: null;
			const draggedId = card ? card.cardId : 'notDragged';
			return {
				isDragging,
				isOverCurrent: monitor.isOver({ shallow: true }),
				isDraggingMaxHeight: isDragging && card?.heightSize === WidgetHeightSize.big,
				isDraggingSecondWidth: isDragging && card?.widthSize === APIWidthSize.two,
				isDraggingThirdWidth: isDragging && card?.widthSize === APIWidthSize.three,
				isDraggingForthWidth: isDragging && card?.widthSize === APIWidthSize.four,
				isDraggingFifthWidth: isDragging && card?.widthSize === APIWidthSize.five,
				isDraggingSixthWidth: isDragging && card?.widthSize === APIWidthSize.six,
				isDraggingSeventhWidth: isDragging && card?.widthSize === APIWidthSize.seven,
				draggedId
			};
		}
	});
	useLayoutEffect(() => {
		drop(mainRef);
		return () => {
			drop(null);
		};
	}, [drop, mainRef]);

	useEffect(() => {
		if (isDragging) document.body.style.cursor = 'grab !important';
		else document.body.style.cursor = 'normal';
	}, [isDragging]);

	if (!dashboard) return <></>;
	const { cards } = dashboard;

	return (
		<RowWrapper ref={mainRef} isDragging={isDragging}>
			{cardsRows.length &&
				cardsRows.map((cardsRow, rowIndex) => {
					return cardsRow.map((layoutElement, colIndex) => {
						const dropzoneId = colIndex + rowIndex;
						if (layoutElement === null) return <></>;

						//capture same row elements
						const rowBelow = cardsRows[rowIndex + 1];
						const elementBelow = rowBelow ? rowBelow[colIndex] : '';
						const firstElement = cardsRow[colIndex + 1] ?? '';
						const secondElement = cardsRow[colIndex + 2] ?? '';
						const thirdElement = cardsRow[colIndex + 3] ?? '';
						const forthElement = cardsRow[colIndex + 4] ?? '';
						const fifthElement = cardsRow[colIndex + 5] ?? '';
						const sixthElement = cardsRow[colIndex + 6] ?? '';

						//capture row below elements
						const secondElementUnder = rowBelow ? rowBelow[colIndex + 1] : '';
						const thirdElementUnder = rowBelow ? rowBelow[colIndex + 2] : '';
						const forthElementUnder = rowBelow ? rowBelow[colIndex + 3] : '';
						const fifthElementUnder = rowBelow ? rowBelow[colIndex + 4] : '';
						const sixthElementUnder = rowBelow ? rowBelow[colIndex + 5] : '';
						const seventhElementUnder = rowBelow ? rowBelow[colIndex + 6] : '';

						//Determine which dropzones based on card size & columns number (3-7)
						//Height sizes dropzones check
						const maxHeightDropzone =
							(isPlaceholderFree(elementBelow, draggedId) &&
								isPlaceholderFree(layoutElement, draggedId)) ||
							!isDraggingMaxHeight;
						//Width sizes dropzones check
						//------------- Three columns layout--------------
						const firstPlaceholderFree = isDraggingMaxHeight
							? isPlaceholderFree(firstElement, draggedId) &&
							  isPlaceholderFree(secondElementUnder, draggedId)
							: isPlaceholderFree(firstElement, draggedId);

						const secondSizeDropzone = firstPlaceholderFree || !isDraggingSecondWidth;
						const firstTwoPlaceholderFree = isDraggingMaxHeight
							? isPlaceholderFree(thirdElementUnder, draggedId) &&
							  isPlaceholderFree(secondElement, draggedId) &&
							  firstPlaceholderFree
							: isPlaceholderFree(secondElement, draggedId) && firstPlaceholderFree;
						const thirdSizeDropzones =
							(colIndex <= 4 && firstTwoPlaceholderFree) || !isDraggingThirdWidth;

						//------------- Four columns layout--------------
						const firstThreePlaceholderFree = isDraggingMaxHeight
							? isPlaceholderFree(forthElementUnder, draggedId) &&
							  isPlaceholderFree(thirdElement, draggedId) &&
							  firstTwoPlaceholderFree
							: isPlaceholderFree(thirdElement, draggedId) && firstTwoPlaceholderFree;

						const forthSizeDropzones =
							(colIndex <= 5 && firstThreePlaceholderFree) || !isDraggingForthWidth;

						//------------- Five columns layout--------------
						const firstFourPlaceholderFree = isDraggingMaxHeight
							? isPlaceholderFree(fifthElementUnder, draggedId) &&
							  isPlaceholderFree(forthElement, draggedId) &&
							  firstThreePlaceholderFree
							: isPlaceholderFree(forthElement, draggedId) &&
							  firstThreePlaceholderFree;

						const fifthSizeDropzones =
							(colIndex <= 6 && firstFourPlaceholderFree) || !isDraggingFifthWidth;

						//------------- Six columns layout--------------
						const firstFivePlaceholderFree = isDraggingMaxHeight
							? isPlaceholderFree(sixthElementUnder, draggedId) &&
							  isPlaceholderFree(fifthElement, draggedId) &&
							  firstFourPlaceholderFree
							: isPlaceholderFree(fifthElement, draggedId) &&
							  firstFourPlaceholderFree;

						const sixthSizeDropzones =
							(colIndex <= 6 && firstFivePlaceholderFree) || !isDraggingSixthWidth;

						//------------- Seven columns layout--------------
						const firstSixPlaceholderFree = isDraggingMaxHeight
							? isPlaceholderFree(seventhElementUnder, draggedId) &&
							  isPlaceholderFree(sixthElement, draggedId) &&
							  firstFivePlaceholderFree
							: isPlaceholderFree(sixthElement, draggedId) &&
							  firstFivePlaceholderFree;

						const seventhSizeDropzones =
							(colIndex === 0 && firstSixPlaceholderFree) || !isDraggingSeventhWidth;
						//-------------

						const position = {
							rowIndex,
							colIndex
						};
						const { threeColumns, fourColumns, fiveColumns, sixColumns, sevenColumns } =
							widthSizes;

						const dynamicWidthSize =
							sevenColumns ??
							sixColumns ??
							fiveColumns ??
							fourColumns ??
							threeColumns;

						const minWidth = dynamicWidthSize?.firstSizeWidth ?? 0;

						if (layoutElement.includes('placeholder')) {
							const sharedDropzoneWithDraggedId =
								layoutElement === 'placeholder' ||
								layoutElement === `placeholder${draggedId}`;

							return (
								<PlaceholderContainer
									key={dropzoneId}
									rowIndex={rowIndex}
									colIndex={colIndex}
									minWidth={minWidth}
									minHeight={heightSizes.minSizeHeight}
									isDragging={isDragging}
									visible={
										!(
											maxHeightDropzone &&
											secondSizeDropzone &&
											thirdSizeDropzones &&
											forthSizeDropzones &&
											fifthSizeDropzones &&
											sixthSizeDropzones &&
											seventhSizeDropzones &&
											sharedDropzoneWithDraggedId
										)
									}
									dropzoneWrapper
								>
									<DropzoneDynamic
										position={position}
										heightSizes={heightSizes}
										widthSizes={widthSizes}
										moveCardToLayout={moveCardToLayout}
									/>
								</PlaceholderContainer>
							);
						} else {
							const card = cards[layoutElement];
							if (!card) return null;
							const bigHeightCard = card.size.heightSize === WidgetHeightSize.big;
							const widthCardOne = card.size.widthSize === APIWidthSize.one;
							const widthCardTwo = card.size.widthSize === APIWidthSize.two;
							const widthCardThree = card.size.widthSize === APIWidthSize.three;
							const widthCardFour = card.size.widthSize === APIWidthSize.four;
							const widthCardFive = card.size.widthSize === APIWidthSize.five;
							const widthCardSix = card.size.widthSize === APIWidthSize.six;

							const cardIndex = cardsRow.findIndex(
								(value, index) =>
									!value.includes(card.cardId.toString()) &&
									'placeholder' &&
									index >= colIndex
							);
							const rowFromCardRoot =
								cardIndex != -1 ? cardsRow.slice(cardIndex) : [];
							const rowBelowFromCardRoot = rowBelow.slice(cardIndex);

							//get number of placeholders that card can extend width
							let placeholdersCanOccupy = 0;
							for (const [index, element] of rowFromCardRoot.entries()) {
								if (isPlaceholderFree(element)) {
									if (bigHeightCard) {
										if (isPlaceholderFree(rowBelowFromCardRoot[index])) {
											placeholdersCanOccupy++;
										} else break;
									} else placeholdersCanOccupy++;
								} else {
									break;
								}
							}
							//get neighbouring below cards
							const onePlaceholderBelowFree = isOccupiedByCard(elementBelow);
							const twoPlaceholdersBelowFree =
								onePlaceholderBelowFree || isOccupiedByCard(secondElementUnder);
							const threePlaceholdersBelowFree =
								twoPlaceholdersBelowFree || isOccupiedByCard(thirdElementUnder);
							const fourPlaceholdersBelowFree =
								threePlaceholdersBelowFree || isOccupiedByCard(forthElementUnder);
							const fivePlaceholdersBelowFree =
								fourPlaceholdersBelowFree || isOccupiedByCard(fifthElementUnder);
							const sixPlaceholdersBelowFree =
								fivePlaceholdersBelowFree || isOccupiedByCard(sixthElementUnder);
							const sevenPlaceholdersBelowFree =
								sixPlaceholdersBelowFree || isOccupiedByCard(seventhElementUnder);

							const blockExpansionUnder =
								bigHeightCard ||
								!!(widthCardOne
									? onePlaceholderBelowFree
									: widthCardTwo
									? twoPlaceholdersBelowFree
									: widthCardThree
									? threePlaceholdersBelowFree
									: widthCardFour
									? fourPlaceholdersBelowFree
									: widthCardFive
									? fivePlaceholdersBelowFree
									: widthCardSix
									? sixPlaceholdersBelowFree
									: sevenPlaceholdersBelowFree);

							return (
								<PlaceholderContainer
									id={colIndex.toString()}
									key={dropzoneId}
									rowIndex={rowIndex}
									colIndex={colIndex}
									minWidth={minWidth}
									minHeight={heightSizes.minSizeHeight}
								>
									<DraggableCardDynamic
										index={rowIndex + colIndex}
										position={position}
										card={{
											...card,
											cardName: card.label,
											index: 0,
											cardId: card.cardId.toString(),
											widthSize: card.size.widthSize,
											heightSize: card.size.heightSize
										}}
										isDragging={isDragging}
										placeholdersCanOccupy={placeholdersCanOccupy}
										widthSizes={widthSizes}
										heightSizes={heightSizes}
										lockExpandUnder={blockExpansionUnder}
										setDimensions={setDimensions}
										removeCard={removeCard}
									/>
								</PlaceholderContainer>
							);
						}
					});
				})}
		</RowWrapper>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import {
	selectVariablesViewOption,
	VariablesViewOptions,
	setVariablesViewOption
} from 'store/data/variables';

export function useVariablesViewOption(): LocalOperationResult<
	VariablesViewOptions,
	(viewOption: VariablesViewOptions) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectVariablesViewOption(state.data.variables));

	function handler(viewOption: VariablesViewOptions) {
		dispatch(setVariablesViewOption({ viewOption }));
	}

	return [data, handler];
}

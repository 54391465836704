import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { ActionTypes, deleteTemplate } from 'store/data/templates';

export function useDeleteTemplate(): Operation<(templateId: string) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.DELETE_TEMPLATE);

	function handler(templateId: string) {
		dispatch(deleteTemplate(templateId));
	}

	return [{ loading, error }, handler];
}

import { MediaQueries } from 'environment';
import { Flex } from 'components/UI/Flex';
import { Select } from 'components/UI/Interactables/Select';
import { NavTab as Tab, NormalTab, Text } from '../Tab.style';
import { useNavigation } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

const tabsProps = {
	width: 19.1,
	min_width: 19.1,
	underline_width: 11.9
};

export function AdminTabs() {
	const { translate } = useTranslation();
	const { routes, navigate } = useNavigation();

	const isPhone = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const menuTabs = [
		{
			label: translate(dict => dict.admin.users.list.title),
			value: routes.admin.view
		},
		{
			label: translate(dict => dict.admin.enterprise.title),
			value: routes.admin.enterprise
		},
		{
			label: translate(dict => dict.rolesPage.title),
			value: routes.admin.roles
		}
	];

	return (
		<Flex>
			{isPhone ? (
				<NormalTab>
					<Select
						title={translate(({ accountUM }) => accountUM.userDrawer.headers.profile)}
						className="dropdown-tab"
						items={menuTabs}
						type={select => select.Tab(true)}
						onSelect={item => navigate(item.value as string)}
					/>
				</NormalTab>
			) : (
				menuTabs.map(item => (
					<Tab to={item.value as string} key={item.label} {...tabsProps}>
						<div>
							<Text>{item.label}</Text>
						</div>
					</Tab>
				))
			)}
		</Flex>
	);
}

import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import { useMemo } from 'react';
import {
	ActionTypes,
	NewNotification,
	NotificationBodyType,
	selectNewestNotification,
	selectNotificationHistory,
	setNewestNotificationsFromQueueAction
} from 'store/data/notifications';
import { Nullable } from 'types/index';

export function useNewestNotification(
	type?: NotificationBodyType
): OperationResult<Nullable<NewNotification>, (notifications: NewNotification[]) => void> {
	const dispatch = useDispatch();

	const notifications = useSelector(state => selectNotificationHistory(state.data.notifications));

	const newestNotification = useSelector(state =>
		selectNewestNotification(state.data.notifications, type)
	);

	const [{ loading, error }] = useActivity(ActionTypes.GET_NOTIFICATION_HISTORY);

	const fetched = useMemo(() => notifications !== null, [notifications]);

	function handler(notifications: NewNotification[]) {
		dispatch(setNewestNotificationsFromQueueAction({ notifications }));
	}

	return [
		{
			loading,
			error,
			fetched,
			data: newestNotification
		},
		handler
	];
}

import { useState } from 'react';
import type { SelectItem } from 'types';
import type { PreviewVariable } from 'types/data/projects/import/types';
import { useImport } from './useImport';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { requiresTimezone, userDetectedTimezone } from 'helpers/projects/imports/importTimezone';

export function useImportTimezoneSettings() {
	const [
		{
			data: { timeZones }
		}
	] = useImport();
	const { timezoneDuringImport } = useFlags();

	const [globalTimezone, setGlobalTimezone] = useState<SelectItem>(
		userDetectedTimezone || timeZones[0]
	);

	function requiresTimezoneSettings(variable: PreviewVariable) {
		return requiresTimezone(variable, timezoneDuringImport);
	}

	function handleChangeGlobalTimezone(value: string) {
		if (value === '') setGlobalTimezone({ label: '', value: '' });
		const tz = timeZones.find(timezone => timezone.value === value);
		if (!tz) return;
		setGlobalTimezone(tz);
	}

	return {
		globalTimezone,
		handleChangeGlobalTimezone,
		requiresTimezoneSettings
	};
}

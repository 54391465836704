import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

import { UserTag } from '../UserTag';
import { useTranslation } from 'hooks/store';
import { Avatar } from 'components/UI/Interactables/Avatar';

interface UserCardTableProps {
	userId: string | null;
	userData: {
		userFirstName?: string;
		userSirName?: string;
		imageURL?: string;
		imageString?: string;
		emailAddress?: string;
	};
	title?: string;
	disabled?: boolean;
	isSelf?: boolean;
	isOwner?: boolean;
}

/**
 * Set userId to null when user is pending
 * @returns
 */
export function UserCardTable({
	userId,
	userData: { userFirstName, userSirName, emailAddress, imageURL, imageString },
	disabled,
	isSelf,
	isOwner
}: UserCardTableProps) {
	const { translate } = useTranslation();

	return (
		<Flex align={a => a.center}>
			<Avatar
				userId={userId}
				data={{ userFirstName, userSirName, imageURL, imageString }}
				size={s => s.s}
				faded={disabled}
			/>
			<Typography.Paragraph
				paddingOffset={{ left: 0.8 }}
				color={!userId || disabled ? Colors.text.disabled : Colors.text.main}
			>
				{!userFirstName && !userSirName ? emailAddress : `${userFirstName} ${userSirName}`}
			</Typography.Paragraph>
			{isSelf && (
				<UserTag marginOffset={{ left: 0.8 }} title={translate(({ tags }) => tags.you)} />
			)}
			{isOwner && (
				<UserTag marginOffset={{ left: 0.8 }} title={translate(({ tags }) => tags.owner)} />
			)}
			{!userId && (
				<UserTag
					marginOffset={{ left: 0.8 }}
					type={t => t.Pending}
					title={translate(({ tags }) => tags.pending)}
				/>
			)}
		</Flex>
	);
}

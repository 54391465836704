import { useState } from 'react';
import { isEqual } from 'lodash';

import { JADBioAnalysis, JADBioReductionType } from 'api/data/analyses';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoMessage } from 'components/UI/InfoMessage';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { SelectItem } from 'types/index';
import { useTranslation, useUpdateAnalysis } from 'hooks/store';
import { useDebounce } from 'hooks/utils';

interface Props {
	analysis: JADBioAnalysis;
}

export function JADBioConfigReduction({ analysis }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();

	const {
		input: { performanceReductionType }
	} = analysis;

	const [performanceReductionTypeDraft, setPerformanceReductionTypeDraft] =
		useState(performanceReductionType);

	const JADBioReductionTypeItems: SelectItem[] = [
		{
			label: 'UMAP',
			value: JADBioReductionType.UMAP
		},
		{
			label: 'PCA',
			value: JADBioReductionType.PCA
		}
	];

	useDebounce(
		() => {
			if (!isEqual(performanceReductionType, performanceReductionTypeDraft)) {
				const updatedAnalysis: JADBioAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						performanceReductionType: performanceReductionTypeDraft
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[performanceReductionTypeDraft],
		ANALYSIS_DEBOUNCE_TIME
	);

	return (
		<>
			<CreatableSelect
				label={translate(({ analysis }) => analysis.analyses.JADBio.displayAs)}
				items={JADBioReductionTypeItems}
				value={JADBioReductionTypeItems.find(
					item => item.value === performanceReductionTypeDraft
				)}
				onValueSelected={value =>
					value && setPerformanceReductionTypeDraft(value as JADBioReductionType)
				}
				canClear={false}
			/>

			<InfoMessage
				message={
					<>
						{performanceReductionType === JADBioReductionType.UMAP &&
							translate(dict => dict.analysis.analyses.JADBio.descriptions.UMAP)}
						{performanceReductionType === JADBioReductionType.PCA &&
							translate(dict => dict.analysis.analyses.JADBio.descriptions.PCA)}
						<br />
						<br />
						{translate(
							dict =>
								dict.analysis.analyses.JADBio.descriptions.dimensionalityReduction
						)}
					</>
				}
			/>
		</>
	);
}

import { apiFactory } from 'api';
import { GetVATStatus } from 'api/account/payments';
import { useAPI } from 'hooks/api';
import { ApiOperation } from 'hooks/store/types';
import { ActionTypes } from 'store/account/payments';

export function useVATStatus(itemPriceId: string): ApiOperation<GetVATStatus> {
	const [{ data, error, loading, fetched }, handler, resetData] = useAPI<GetVATStatus>({
		promiseFn: () => apiFactory().account.payments().getVATStatus(itemPriceId),
		lazy: true,
		initialData: { isTaxable: false, unitPrice: 0 },
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		activityType: ActionTypes.GET_VAT_STATUS
	});

	return [{ data, error, loading, fetched }, handler, resetData];
}

import { getTypographyCSS } from 'components/UI/Typography';
import { Colors } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	grid-area: variable;

	width: 100%;

	position: relative;
`;

type WrapperProps = {
	errored?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	flex-direction: column;

	width: 100%;

	border: 1px solid ${props => (props.errored ? Colors.text.error : Colors.numericEditor.initial)};
	border-radius: 4px;
`;

export const InputContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0.8rem;
	width: 100%;
	height: 3.2rem;
`;

type ValueContainerProps = {
	hasPlaceholder: boolean;
};

export const ValueContainer = styled.div<ValueContainerProps>`
	${getTypographyCSS(t => t.Paragraph)}

	width: calc(100% - 24px);

	color: ${({ hasPlaceholder }) => (hasPlaceholder ? '#978888' : Colors.text.main)};
`;

export const DropdownContainer = styled.div`
	position: relative;
	top: 2.4rem;
`;

type DropdownProps = {
	show: boolean;
};

export const Dropdown = styled.div<DropdownProps>`
	position: absolute;

	z-index: 100;

	background: #fff;
	width: 100%;

	border: 1px solid #ccc;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 0 5px #ccc;

	height: ${({ show }) => (show ? 'fit-content' : '0%')};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

	transition: visibility 0.01s ease-in-out, height 0.01s ease-in-out;

	display: flex;

	flex-direction: column;

	max-height: 250px;
	overflow: scroll;
	overflow-x: hidden;

	padding: 0.5rem 0;

	z-index: 9999;
`;

type ItemProps = {
	selected: boolean;
};

export const Item = styled.div<ItemProps>`
	${getTypographyCSS(t => t.Paragraph)}

	padding: 0.5rem 1rem;

	font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};

	cursor: pointer;

	:hover {
		background-color: ${Colors.primary.hover};
		color: ${Colors.white};
	}
`;

export const Error = styled.div`
	${getTypographyCSS(t => t.Paragraph)}
	color: ${Colors.text.error};

	font-size: 1rem;
	line-height: 1.6;

	margin-top: 0.4rem;
`;

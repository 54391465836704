import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface AddCreditCardWarningModalProps {
	onConfirm: () => void;
	onCancel: () => void;
}

export function AddCreditCardWarningModal({ onConfirm, onCancel }: AddCreditCardWarningModalProps) {
	const { translate } = useTranslation();

	return (
		<Modal
			visible
			title={translate(({ accountUM }) => accountUM.payment.addNewCard)}
			primary={{
				label: translate(({ buttons }) => buttons.confirm),
				onClick: onConfirm
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onCancel
			}}
			close
			closeOnBackdrop
			onClose={onCancel}
			size={s => s.s}
		>
			<Typography.Paragraph>
				{translate(({ accountUM }) => accountUM.payment.addNewCardWarning)}
			</Typography.Paragraph>
			<Typography.Paragraph fontweight={f => f.bold}>
				{translate(({ accountUM }) => accountUM.payment.addNewCardWarningConfirm)}
			</Typography.Paragraph>
		</Modal>
	);
}

import { OneWayManovaResultsV2, OneWayManovaResultsDataV2, ManovaValue } from 'api/data/analyses';

import { VariablesData } from 'store/data/variables';
import { AnalysisStatistic } from 'components/Analysis/Analyses';

import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';
import { PStatistic } from 'components/Analysis/Analyses/AnalysisStatistics';
import { computeCellValue } from 'helpers/analysis';

interface Props {
	variablesData: VariablesData;
	results: OneWayManovaResultsV2;
}

export function CompareNumericOneWayManova({ variablesData, results }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title marginOffset={{ bottom: 1.6 }}>
				{translate(({ analysis }) => analysis.statistics.oneWayManova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{error.message ??
						translate(({ errors }) => errors.api.analyses.statistics.oneWayManova)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result variablesData={variablesData} data={data} />}
		</AnalysisStatistic>
	);
}

function Result({
	variablesData,
	data
}: {
	variablesData: VariablesData;
	data: OneWayManovaResultsDataV2;
}) {
	const COLUMN_NAMES = ['Source/effect', 'Type', 'Statistic', 'F-value', 'df1', 'df2', 'p-value'];
	const { variablesMap } = variablesData;

	const statisticLabelsMap = {
		pillaisTrace: `Pillai's trace`,
		lawleyHotellingTrace: `Lawley-Hotelling's trace`,
		roysLargestRoot: `Roy's largest root`,
		wilksLambda: `Wilks' Lambda`
	};

	/**
	 *
	 * @param variableNames [var1, var2]
	 * @returns computed label(string) => "var1Label * var2Label" if variableNames > 1, otherwise just "var1Label"
	 */
	function computeName(variableNames: string[]) {
		if (variableNames.length > 1) {
			return variableNames.map(name => variablesMap[name]?.label).join(' * ');
		}

		if (variableNames?.[0] === 'Intercept') {
			return 'Intercept';
		}

		return variablesMap[variableNames[0]]?.label ?? variableNames[0];
	}

	return (
		<Table>
			<Table.Head>
				<Table.Row>
					{COLUMN_NAMES.map(colName => (
						<Table.Column key={`oneWayManova-${colName}-head`} title={colName}>
							{colName}
						</Table.Column>
					))}
				</Table.Row>
			</Table.Head>
			<Table.Body>
				{Object.keys(data).map(variableKey =>
					Object.keys(data[variableKey]).map((analysisKey, index) => {
						const typedKey = analysisKey as keyof ManovaValue;
						return (
							<Table.Row key={`oneWayManova-${analysisKey}`}>
								<Table.Cell bold>
									{index === 0 ? computeName(variableKey.split(' * ')) : ''}
								</Table.Cell>
								<Table.Cell>{statisticLabelsMap[typedKey]}</Table.Cell>
								<Table.Cell>
									{computeCellValue(data[variableKey][typedKey].statistic)}
								</Table.Cell>
								<Table.Cell>
									{computeCellValue(data[variableKey][typedKey].fValue)}
								</Table.Cell>
								<Table.Cell>
									{computeCellValue(data[variableKey][typedKey].df1, {
										noDecimals: true
									})}
								</Table.Cell>
								<Table.Cell>
									{computeCellValue(data[variableKey][typedKey].df2, {
										noDecimals: true
									})}
								</Table.Cell>
								<Table.Cell>
									<PStatistic value={data[variableKey][typedKey].pValue} />
								</Table.Cell>
							</Table.Row>
						);
					})
				)}
			</Table.Body>
		</Table>
	);
}

import { useState, useRef, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Svgs, Colors } from 'environment';
import { StringMap } from 'types/index';
import {
	Container,
	ActionComponent,
	FloatingContainer,
	ItemsContainer
} from './ColumnSettings.style';
import { ProjectTableViewData } from 'store/data/projects';
import { Icon } from 'components/UI/Icons';
import { Flex } from 'components/UI/Flex';
import { Gap } from 'components/UI/Gap';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { useProjectsTableVisibleColumns } from 'hooks/store';
import { useOutsideClick, useKeyPress, useMutableState, useDebounce } from 'hooks/utils';

interface Props {
	className?: string;
	columns: string[];
	translatedColumn: StringMap;
}

export function ColumnSettings({ className, columns, translatedColumn }: Props) {
	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const ref = useRef<HTMLDivElement>(null);

	useOutsideClick(handleClose, [ref]);

	useKeyPress({ onEscapeKeyPress: handleClose }, { listen: open, noModalsOpened: true });

	const [visibleColumnNames, setVisibleColumnNames] = useProjectsTableVisibleColumns();

	const [draftVisibleColumnNames, setDraftVisibleColumnNames] =
		useMutableState(visibleColumnNames);

	// SYNC `draftVisibleColumnNames` STATE
	useEffect(() => {
		const hasChanges = !isEqual(draftVisibleColumnNames, visibleColumnNames);

		if (hasChanges) setDraftVisibleColumnNames(visibleColumnNames);
	}, [visibleColumnNames]);

	useDebounce(() => {
		const hasChanges = !isEqual(draftVisibleColumnNames, visibleColumnNames);

		if (!hasChanges) return;

		const sortedVisibleColumnNames = getSortedVisibleColumnNames(draftVisibleColumnNames);

		setVisibleColumnNames(sortedVisibleColumnNames);
	}, [draftVisibleColumnNames]);

	function getSortedVisibleColumnNames(columnNames: string[]) {
		return [...columnNames].sort((a, b) => columns.indexOf(a) - columns.indexOf(b));
	}

	function handleToggleOpen() {
		const flag = !open;

		setOpen(state => !state);

		if (searchTerm && !flag) resetTerm();
	}

	function handleClose() {
		setOpen(false);
		resetTerm();
	}

	function resetTerm() {
		setSearchTerm('');
	}

	function handleSelectItem(columnName: string) {
		setDraftVisibleColumnNames(state => {
			const isChecked = state.includes(columnName);

			// REMOVE FROM LIST
			if (isChecked) return state.filter(item => item !== columnName);

			// ADD TO LIST
			state.push(columnName);
		});
	}

	function isColumnChecked(columnName: string) {
		return draftVisibleColumnNames.includes(columnName);
	}

	return (
		<Container ref={ref} className={className}>
			<ActionComponent onClick={handleToggleOpen}>
				<Icon svg={Svgs.Settings} colors={{ color: Colors.primary.normal }} propagate />
				<Icon
					svg={Svgs.ArrowDown}
					size={s => s.m}
					colors={{ color: Colors.primary.normal }}
					propagate
				/>
			</ActionComponent>

			{open && (
				<FloatingContainer>
					<Flex paddingOffset={{ x: 0.8, bottom: 1.6, top: 1.6 }} column>
						<ItemsContainer>
							{/* COLUMNS LIST */}
							<Gap marginGap={{ bottom: 0.8 }} notLastChild>
								{columns.map(column => (
									<Checkbox
										key={column}
										className="checkbox"
										label={translatedColumn[column]}
										disabled={
											column === ProjectTableViewData.title ||
											column === ProjectTableViewData.collaborators
										}
										checked={isColumnChecked(column)}
										paddingOffset={{ left: 0.8 }}
										onClick={() => handleSelectItem(column)}
									/>
								))}
							</Gap>
						</ItemsContainer>
					</Flex>
				</FloatingContainer>
			)}
		</Container>
	);
}

import styled from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Dropdown as DefaultDropdown } from 'components/UI/Dropdown';
import { Typography } from 'components/UI/Typography';

export const DropdownScrollContainer = styled.div`
	width: 100%;
	overflow-y: auto;
	max-height: calc(100vh - 42rem);
	min-height: 5rem;
`;

export const Dropdown = styled(DefaultDropdown)`
	flex: 1;
	justify-content: flex-end;
	padding: 0;
	width: auto;
	display: flex;
`;

export const DropdownHeader = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 0.8rem;
	padding: 0 0.8rem;

	:hover .delete-icon {
		visibility: visible;
	}
`;

export const ResetFilterIcon = styled(DefaultIcon)`
	visibility: hidden;
`;

export const NoResults = styled(Typography.Paragraph)`
	padding: 1.5rem 0 1rem 1rem;
`;

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setProjectFilesSearchTerm, SetProjectFilesSearchTermAction } from 'store/data/documents';
import { ActionPayload } from 'store/types';

export function useDocumentsSearchTerm(): LocalOperationResult<
	string,
	(payload: ActionPayload<SetProjectFilesSearchTermAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.documents.metadata.searchTerm);

	function handler(payload: ActionPayload<SetProjectFilesSearchTermAction>) {
		dispatch(setProjectFilesSearchTerm(payload));
	}

	return [data, handler];
}

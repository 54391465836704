import { PartialVariableSet } from 'api/data/variables';
import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { updateVariableSet, ActionTypes } from 'store/data/variables';

export function useUpdateVariableSet(): Operation<(variableSet: PartialVariableSet) => void> {
	const dispatch = useDispatch();

	const [{ loading, error }, resetError] = useActivity(ActionTypes.UPDATE_VARIABLE_SET);

	function handler(variableSet: PartialVariableSet) {
		if (loading) return;
		if (error) resetError();

		dispatch(updateVariableSet(variableSet));
	}

	return [{ loading, error }, handler];
}

import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useMatchProms } from 'hooks/navigation';
import { useTranslation, useProject, useRemoveCollaborator } from 'hooks/store';
import { useCompletedAction } from 'hooks/utils';
import { Collaborator } from 'store/data/collaborators';

interface Props {
	collaborator: Collaborator;
	onClose: (success?: boolean) => void;
}

export function RemoveCollaboratorModal({ collaborator, onClose }: Props) {
	const { translate } = useTranslation();
	const matchProms = useMatchProms();
	const dictKey = matchProms ? 'prom' : 'project';

	const [{ data: project }] = useProject();

	const [
		{ loading: removingCollaborator, error: errorRemovingCollaborator },
		removeCollaborator
	] = useRemoveCollaborator();

	useCompletedAction(removingCollaborator, errorRemovingCollaborator, () => onClose(true));

	function handleSubmit() {
		removeCollaborator({ collaboratorId: collaborator.userId });
	}

	return (
		<Modal
			title={translate(({ collaborators }) => collaborators.details.remove[dictKey])}
			primary={{
				label: translate(({ buttons }) => buttons.remove),
				loading: removingCollaborator,
				warning: true,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			size={s => s.s}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph fontweight={w => w.bold}>
				{translate(({ collaborators }) => collaborators.details.remove.modal.message)}
			</Typography.Paragraph>

			<Spacer size={s => s.s} />

			<Typography.Paragraph>
				{translate(
					({ collaborators }) => collaborators.details.remove.modal.description.user
				)}
				<b>
					{` ${collaborator.emailAddress} `}
					{(collaborator.userFirstName || collaborator.userSirName) &&
						`(${(
							collaborator.userFirstName +
							' ' +
							collaborator.userSirName
						).trim()}) `}
				</b>
				{translate(
					({ collaborators }) => collaborators.details.remove.modal.description.willLose
				)}
				<b>{` ${project?.projectName}.`}</b>
			</Typography.Paragraph>
		</Modal>
	);
}

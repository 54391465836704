import type { Action } from 'types/store/types';

export interface State {
	projectId: string | null;
	exportType: string | null;
	downloadUrl: string | null;
	valid: string[];
	empty: string[];
	invalid: string[];
}

export enum ActionTypes {
	RESET_EXPORT_WIZARD = 'data/exportWizard/RESET_EXPORT_WIZARD',
	DOWNLOAD_FILE = 'data/exportWizard/DOWNLOAD_FILE',
	// SERIES EXPORT
	GET_SERIES_DOWNLOAD_FILE = 'data/exportWizard/GET_SERIES_DOWNLOAD_FILE',
	SET_SERIES_EXPORT_FILE_AND_NAMES = 'data/exportWizard/SET_SERIES_EXPORT_FILE_AND_DATA'
}

export type SetSeriesExportFileAndNamesAction = Action<
	ActionTypes.SET_SERIES_EXPORT_FILE_AND_NAMES,
	{
		projectId: string;
		downloadUrl: string;
		emptySeriesNames: string[];
		erroredSeriesNames: string[];
		validSeriesNames: string[];
	}
>;

export type ResetExportWizardAction = Action<ActionTypes.RESET_EXPORT_WIZARD>;

export type Actions = SetSeriesExportFileAndNamesAction | ResetExportWizardAction;

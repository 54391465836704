import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import {
	useDependencies,
	useDependenciesContext,
	useTranslation,
	useUpdateDependencies
} from 'hooks/store';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Dependency, OptionType, TableDependency } from 'store/data/dependencies';

interface Props {
	dependency: TableDependency;
	onClose: () => void;
	selectedOption: { type: string; option: string };
	dependencies: Dependency[];
	initialDependencies: { active: boolean; dependencies: Dependency[] };
	context: string | null;
	collapseAllRules: () => void;
}
export const DeleteRuleOrConditionModal = ({
	dependency,
	onClose,
	selectedOption,
	dependencies,
	context,
	collapseAllRules
}: Props) => {
	const { translate } = useTranslation();

	const [{ loading: updatingDependencies }, updateDependencies] = useUpdateDependencies();
	const {
		actions: { deleteDependency, deleteDependant }
	} = useDependenciesContext();

	const [{ data: dependenciesData }] = useDependencies();

	// COLLAPSE  ALL RULES  ON MODAL OPENING
	useEffect(() => {
		collapseAllRules();
	}, []);

	const draftDependencies = useMemo(() => {
		const draft = cloneDeep(dependencies);
		if (selectedOption.type === OptionType.rule) {
			return draft.filter(dep => dep.dependencyName !== dependency.dependencyName);
		} else if (selectedOption.type === OptionType.condition) {
			return draft.map(dep => ({
				...dep,
				dependantVariables: dep.dependantVariables.filter(
					dependantVar => dependantVar.dependantId !== dependency.dependantId
				)
			}));
		}
		return draft;
	}, [dependencies, dependency, dependency]);

	const handleDelete = () => {
		if (selectedOption.type === OptionType.rule) {
			deleteDependency({ dependencyName: dependency.dependencyName });
		} else if (selectedOption.type === OptionType.condition) {
			deleteDependant({
				dependencyName: dependency.dependencyName,
				dependantId: dependency.dependantId
			});
		}

		updateDependencies({
			active: dependenciesData.active,
			dependencies: draftDependencies,
			setName: context ?? undefined
		});
		onClose();
	};

	return (
		<Modal
			title={`${selectedOption.option}?`}
			size={s => s.s}
			visible
			onClose={onClose}
			close
			enterAsPrimaryOnClick
			primary={{
				label: translate(dict => dict.buttons.delete),
				warning: true,
				loading: updatingDependencies,
				onClick: handleDelete
			}}
			neutral={{ label: translate(({ buttons }) => buttons.cancel), onClick: onClose }}
		>
			<Typography.Paragraph>
				{selectedOption.type === OptionType.rule
					? translate(
							dict =>
								dict.dependencies.builder.tableView.deleteModal.deleteRuleMessage
					  )
					: translate(
							dict =>
								dict.dependencies.builder.tableView.deleteModal
									.deleteConditionMessage
					  )}
			</Typography.Paragraph>
		</Modal>
	);
};

import styled from 'styled-components';

import { Colors, MediaQueries, Shadows } from 'environment';
import { Columns } from 'api/data/analyses';

interface CardProps {
	isFullScreen?: string;
	activeColumn?: number;
}
export const AnalysisCardContainer = styled.div`
	height: 100%;
`;

export const AnalysisCard = styled.div<CardProps>`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.8rem;
	margin-bottom: 1rem;
	border-radius: 0.4rem;
	background-color: ${Colors.white};
	position: relative;
	box-shadow: ${props => (props.isFullScreen ? 'none' : `${Shadows.normal}`)};
	cursor: ${props =>
		props.activeColumn === Columns.OneColumn && !props.isFullScreen ? 'default' : 'pointer'};
	@media ${MediaQueries.maxWidth.sm} {
		position: static;
		padding: 0;
		margin: 0;
	}
`;

export const InvalidSnapshotContainer = styled.div`
	height: 100%;
	padding: 0.8rem;
	margin-bottom: 1rem;
	border-radius: 0.4rem;
	background-color: ${Colors.white};
	position: relative;
	box-shadow: ${Shadows.normal};
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: baseline;

	> div {
		margin-left: auto;
		width: auto;
	}

	> p {
		width: 100%;
		text-align: center;
	}
`;

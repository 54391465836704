import { Dictionary } from 'environment';
import {
	ApiBillingAddress,
	ApiDownloadInvoice,
	ApiPaymentIntent,
	ApiSubscriptionPayments,
	ApiUserBillingInfo,
	CreateChargebeeCustomerResponse,
	CreateCustomerSubscription,
	CreateSubscriptionPaymentIntentResponse,
	CustomerSubscription,
	DownloadInvoiceResponse,
	GetSubscriptionBillingInfoResponse,
	GetSubscriptionPaymentMethodsResponse,
	GetSubscriptionPaymentsResponse,
	GetVATStatus,
	GetVATStatusResponse,
	MakePaymentSourcePrimary,
	NewChargebeeCustomer,
	RemoveSubscriptionPaymentMethodsResponse,
	SubscriptionPaymentMethodsApi
} from './types';
import { USER_ACCOUNT_URL, USER_URL, sendRequest } from 'api/utils';
import { GenericApiResponse, NetworkRequestBase } from 'api/types';

export const methods = {
	getSubscriptionPayments: 'getAllSubscriptionPayments',
	downloadInvoice: 'downloadSubscriptionPaymentInvoice',
	getSubscriptionPaymentMethods: 'getSubscriptionPaymentMethods',
	addPaymentSubscriptionMethod: 'addSubscriptionPaymentMethod',
	updateSubscriptionCardPaymentSource: 'updateSubscriptionCardPaymentSource',
	removeSubscriptionCardPaymentSource: 'removeSubscriptionPaymentSource',
	makePaymentSourcePrimary: 'makePaymentSourcePrimary',

	// billing
	getSubscriptionBillingInfo: 'getSubscriptionBillingInfo',
	updateUserBillingAddress: 'updateUserBillingInfo',

	// chargebee and create subscription
	createCustomerSubscription: 'createCustomerSubscription',
	updateCustomerSubscription: 'updateCustomerSubscription',
	getVATStatus: 'getVATStatus',
	downgradeSubscriptionToFree: 'downgradeSubscriptionToFree',
	createChargebeeCustomer: 'createChargebeeCustomer',
	createSubscriptionPaymentIntent: 'createSubscriptionPaymentIntent'
};

export default () => ({
	getSubscriptionPayments: async (): Promise<ApiSubscriptionPayments> => {
		const { data }: GetSubscriptionPaymentsResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.getSubscriptionPayments
		});

		if (!data.invoices) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetInvoices);
		}

		return data;
	},

	downloadInvoice: async (paymentId: string): Promise<ApiDownloadInvoice> => {
		const { data }: DownloadInvoiceResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.downloadInvoice,
			paymentId
		});

		if (!data.downloadURL) {
			throw new Error(Dictionary.errors.api.accountUM.couldNotDownloadInvoice);
		}

		return data;
	},

	getSubscriptionBillingInfo: async (): Promise<ApiUserBillingInfo> => {
		const { data }: GetSubscriptionBillingInfoResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.getSubscriptionBillingInfo
		});

		if (!data.subscriptionDetails && data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetSubscriptionBillingInfo);
		}

		return data;
	},

	updateUserBillingInfo: async (billingAddress: ApiBillingAddress) => {
		await sendRequest(USER_ACCOUNT_URL, {
			method: methods.updateUserBillingAddress,
			billingAddress
		});
	},

	getSubscriptionPaymentMethods: async (): Promise<SubscriptionPaymentMethodsApi> => {
		try {
			const { data }: GetSubscriptionPaymentMethodsResponse = await sendRequest(
				USER_ACCOUNT_URL,
				{
					method: methods.getSubscriptionPaymentMethods
				}
			);

			if (!data.paymentMethods || !data.paymentMethods.cards) {
				throw new Error();
			}

			return data;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.getSubscriptionPaymentMethods);
		}
	},

	addPaymentSubscriptionMethod: async (
		paymentIntentId: string
	): Promise<SubscriptionPaymentMethodsApi> => {
		try {
			const { data }: GetSubscriptionPaymentMethodsResponse = await sendRequest(
				USER_ACCOUNT_URL,
				{
					method: methods.addPaymentSubscriptionMethod,
					paymentIntentId
				}
			);

			if (!data.paymentMethods || !data.paymentMethods.cards) {
				throw new Error();
			}

			return data;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.addPaymentSubscriptionMethod);
		}
	},

	updateSubscriptionCardPaymentSource: async (): Promise<number> => {
		try {
			const {
				data: { httpStatusCode }
			}: GenericApiResponse = await sendRequest<NetworkRequestBase>(USER_URL, {
				method: methods.updateSubscriptionCardPaymentSource
			});

			return httpStatusCode;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.updateSubscriptionCardPaymentSource);
		}
	},

	removeSubscriptionCardPaymentSource: async (paymentSourceId: string): Promise<boolean> => {
		try {
			const {
				data: { paymentSourceDeleted }
			}: RemoveSubscriptionPaymentMethodsResponse = await sendRequest(USER_ACCOUNT_URL, {
				method: methods.removeSubscriptionCardPaymentSource,
				paymentSourceId
			});

			return paymentSourceDeleted;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.removeSubscriptionCardPaymentSource);
		}
	},

	makePaymentSourcePrimary: async (paymentSourceId: string): Promise<string> => {
		try {
			const {
				data: { statusCode }
			}: MakePaymentSourcePrimary = await sendRequest(USER_ACCOUNT_URL, {
				method: methods.makePaymentSourcePrimary,
				paymentSourceId
			});

			return statusCode;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.removeSubscriptionCardPaymentSource);
		}
	},

	createSubscriptionPaymentIntent: async (
		amountToPay: number,
		currencyCode: string
	): Promise<ApiPaymentIntent> => {
		try {
			const {
				data: { paymentIntent }
			}: CreateSubscriptionPaymentIntentResponse = await sendRequest(USER_ACCOUNT_URL, {
				method: methods.createSubscriptionPaymentIntent,
				amountToPay,
				currencyCode
			});

			if (!paymentIntent) {
				throw new Error(Dictionary.errors.api.accountUM.createSubscriptionPaymentIntent);
			}

			return paymentIntent;
		} catch (e) {
			throw new Error(Dictionary.errors.api.accountUM.createSubscriptionPaymentIntent);
		}
	},

	downgradeSubscriptionToFree: async (): Promise<boolean> => {
		const { data } = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.downgradeSubscriptionToFree
		});

		if (data.statusCode !== '200') {
			throw new Error(
				Dictionary.errors.api.accountUM.couldNotDowngradeCustomerSubscriptionToFree
			);
		}
		return true;
	},

	createCustomerSubscription: async (
		customerSubscription: CreateCustomerSubscription
	): Promise<boolean> => {
		const {
			quantity,
			quantities,
			priceId,
			firstName,
			lastName,
			emailAddress,
			selectedPaymentMethod
		} = customerSubscription;
		const { data } = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.createCustomerSubscription,
			quantity,
			quantities,
			priceId,
			firstName,
			lastName,
			emailAddress,
			selectedPaymentMethod
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accountUM.couldNotCreateCustomerSubscription);
		}

		return true;
	},

	updateCustomerSubscription: async (
		customerSubscription: CustomerSubscription
	): Promise<boolean> => {
		const { quantity, quantities, priceId, selectedPaymentMethod } = customerSubscription;
		const { data } = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.updateCustomerSubscription,
			quantity,
			quantities,
			priceId,
			selectedPaymentMethod
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accountUM.couldNotUpdateCustomerSubscription);
		}

		return true;
	},

	createChargebeeCustomer: async (
		newCustomer: NewChargebeeCustomer
	): Promise<CreateChargebeeCustomerResponse> => {
		const { firstName, lastName, emailAddress } = newCustomer;
		const { data } = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.createChargebeeCustomer,
			firstName,
			lastName,
			emailAddress
		});

		if (data.statusCode !== '200') {
			throw new Error(
				Dictionary.errors.api.accountUM.couldNotDowngradeCustomerSubscriptionToFree
			);
		}
		return data;
	},

	getVATStatus: async (itemPriceId: string): Promise<GetVATStatus> => {
		const { data }: GetVATStatusResponse = await sendRequest(USER_ACCOUNT_URL, {
			method: methods.getVATStatus,
			itemPriceId
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.accountUM.couldNotGetVATStatus);
		}
		const { unitPrice, isTaxable } = data;
		return { isTaxable, unitPrice };
	}
});

import { createSelector } from 'reselect';

import { State, VariablesMappingRichData } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getByProjectId({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectVariablesMappingRichData = createSelector([getByProjectId], byProjectId => {
	let variablesMappingRichData: VariablesMappingRichData = {
		byId: {},
		main: {
			ids: [],
			active: false,
			initialized: false
		},
		bySetName: {}
	};

	if (byProjectId) {
		const { byId, main, bySetName } = byProjectId;

		variablesMappingRichData = {
			byId,
			main,
			bySetName
		};
	}

	return variablesMappingRichData;
});

export const selectAreVariablesMappingFetched = createSelector(
	[getByProjectId],
	byProjectId => byProjectId?.fetched ?? false
);

import { SubscriptionAddonCode } from 'types/index';
import {
	ActivateUserAddonRequest,
	ActivateUserAddonResponse,
	AssignSubscriptionAddonRequest,
	AssignSubscriptionAddonResponse,
	DeactivateUserAddonRequest,
	DeactivateUserAddonResponse,
	GetAllAddonsResponse,
	RetractSubscriptionAddonRequest,
	RetractSubscriptionAddonResponse
} from './types';
import { USER_ACCOUNT_URL, sendRequest } from 'api/utils';
import { Dictionary } from 'environment';
import { NetworkRequestBase } from 'api/types';

export const methods = {
	getAllAddons: 'getAllSubscriptionAddons',
	activateUserAddon: 'activateSubscriptionAddon',
	deactivateUserAddon: 'deactivateSubscriptionAddon',
	assignSubscriptionAddon: 'assignSubscriptionAddon',
	retractSubscriptionAddon: 'retractSubscriptionAddon'
};

export default () => ({
	getAllAddons: async (): Promise<Array<SubscriptionAddonCode>> => {
		const { data } = await sendRequest<NetworkRequestBase, GetAllAddonsResponse>(
			USER_ACCOUNT_URL,
			{
				method: methods.getAllAddons
			}
		);

		if (!data.subscriptionAddonCodes || data.httpStatusCode === 500) {
			throw new Error(Dictionary.errors.api.addons.getAllSubsriptionAddons);
		}

		return data.subscriptionAddonCodes;
	},

	activateUserAddon: async (addonCode: SubscriptionAddonCode): Promise<void> => {
		const {
			data: { statusCode }
		} = await sendRequest<ActivateUserAddonRequest, ActivateUserAddonResponse>(
			USER_ACCOUNT_URL,
			{
				method: methods.activateUserAddon,
				subscriptionAddonCode: addonCode
			}
		);
		if (statusCode !== '200') throw Error();
	},

	deactivateUserAddon: async (addonCode: SubscriptionAddonCode): Promise<void> => {
		const {
			data: { statusCode }
		} = await sendRequest<DeactivateUserAddonRequest, DeactivateUserAddonResponse>(
			USER_ACCOUNT_URL,
			{
				method: methods.deactivateUserAddon,
				subscriptionAddonCode: addonCode
			}
		);
		if (statusCode !== '200') throw Error();
	},

	assignSubscriptionAddon: async (
		addonCode: SubscriptionAddonCode,
		userId: string
	): Promise<void> => {
		const {
			data: { statusCode }
		} = await sendRequest<AssignSubscriptionAddonRequest, AssignSubscriptionAddonResponse>(
			USER_ACCOUNT_URL,
			{
				method: methods.assignSubscriptionAddon,
				subscriptionAddonCode: addonCode,
				userId
			}
		);
		if (statusCode !== '200') throw Error();
	},

	retractSubscriptionAddon: async (
		addonCode: SubscriptionAddonCode,
		userId: string
	): Promise<void> => {
		const {
			data: { statusCode }
		} = await sendRequest<RetractSubscriptionAddonRequest, RetractSubscriptionAddonResponse>(
			USER_ACCOUNT_URL,
			{
				method: methods.retractSubscriptionAddon,
				subscriptionAddonCode: addonCode,
				userId
			}
		);
		if (statusCode !== '200') throw Error();
	}
});

import { useDispatch, useSelector } from 'hooks/utils';
import { setIsClicked, SetIsClickedAction } from 'store/data/revisions';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

type Handler = (isClicked: ActionPayload<SetIsClickedAction>['isClicked']) => void;

export function useIsClickedHistoryPagination(): LocalOperationResult<boolean, Handler> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.revisions.metadata.isClicked);

	function handler(isClicked: ActionPayload<SetIsClickedAction>['isClicked']) {
		dispatch(setIsClicked({ isClicked }));
	}

	return [data, handler];
}

import { Colors, MediaQueries } from 'environment';
import styled from 'styled-components';

export const Container = styled.div`
	background-color: ${Colors.text.main};
	width: 28.3rem;
	height: 4rem;
	padding: 0.8rem 1.6rem;
	border-radius: 0.4rem;
	position: absolute;
	bottom: 2.4rem;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media ${MediaQueries.minWidth.md} {
		left: 40%;
	}
`;

import { useEffect } from 'react';

import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getEntryFiles,
	selectEntryFiles,
	DynamicFormFiles,
	StoredEntryFile,
	LocalEntryFile
} from 'store/data/entries';
import { GenericMap } from 'types/index';

import { useEntryId } from './useEntryId';
import { useEntryFile } from './useEntryFile';
import { useRevisionId } from '../revisions/useRevisionId';

interface UseEntryFilesOptions {
	lazy?: boolean;
}

interface Props extends UseEntryFilesOptions {
	entryFiles?: DynamicFormFiles;
}

export function useEntryFiles({
	entryFiles = { fileIds: [] },
	lazy
}: Props): OperationResult<GenericMap<StoredEntryFile | LocalEntryFile> | null> {
	const dispatch = useDispatch();

	const [entryId] = useEntryId();
	const [revisionId] = useRevisionId();

	const [{ loading: entryFilesLoading, error: entryFilesError }] = useActivity(
		ActionTypes.GET_ENTRY_FILES,
		entryId
	);

	const [{ loading: revisionFilesLoading, error: revisionFileError }] = useActivity(
		ActionTypes.GET_ENTRY_FILES,
		revisionId
	);

	const { files, missingFileIds } = useSelector(state =>
		selectEntryFiles(state.data.entries, entryFiles.fileIds)
	);

	const fetched = !Object.keys(files).length || !missingFileIds.length;

	const loading = revisionId ? revisionFilesLoading : entryFilesLoading;
	const error = revisionId ? revisionFileError : entryFilesError;

	const [{ loading: oneFileLoading, error: oneFileError }, getEntryFile] = useEntryFile({
		id: missingFileIds.length === 1 ? missingFileIds[0] : ''
	});

	useEffect(() => {
		if (!error && !loading && !lazy && Object.keys(missingFileIds).length) {
			// MORE THAN 1 FILE => GET FILES API CALL
			if (missingFileIds.length > 1) {
				handler();
			}
			// 1 FILE => GET FILE API CALL
			else if (!oneFileLoading && !oneFileError) {
				getEntryFile();
			}
		}
	}, [error, loading, missingFileIds, oneFileLoading, oneFileError]);

	function handler() {
		dispatch(getEntryFiles(missingFileIds, revisionId ? revisionId : entryId ?? ''));
	}

	return [{ data: files, loading, error, fetched }, handler];
}

import { useRef, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HelpTopic } from 'store/data/help';
import { Svgs } from 'environment';
import { Wrapper, Container, Content, Navigation } from './Topic.style';
import { ROUTES } from 'types/navigation';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import { useHelpTopics, useTranslation } from 'hooks/store';

export function Topic() {
	const { replace } = useNavigation();
	const { translate } = useTranslation();
	const { topicUrl } = useParams<{ topicUrl: string }>();

	const toTopRef = useRef<HTMLDivElement>(null);

	const [{ data: helpTopicList }] = useHelpTopics();

	const allTopics: HelpTopic[] = useMemo(
		() => helpTopicList.map(({ categoryTopics }) => categoryTopics).flat(),
		[helpTopicList]
	);

	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	useEffect(() => {
		setActiveIndex(handleIndexOfTopic(allTopics));
		scrollToTop();
	}, [allTopics, topicUrl]);

	function handleIndexOfTopic(topics: HelpTopic[]) {
		const indexOfUrl = topicUrl ? topics.findIndex(topic => topic.url === topicUrl) : 0;
		return indexOfUrl !== -1 ? indexOfUrl : null;
	}

	function handlePrevTopic() {
		if (activeIndex !== null) {
			const prevIndex = activeIndex - 1;
			setActiveIndex(prevIndex);
			replace(ROUTES.Help + allTopics[prevIndex].url);
		}
	}

	function handleNextTopic() {
		if (activeIndex !== null) {
			const nextIndex = activeIndex + 1;
			setActiveIndex(nextIndex);
			replace(ROUTES.Help + allTopics[nextIndex].url);
		}
	}

	function scrollToTop() {
		if (toTopRef.current) toTopRef.current.scrollTop = 0;
	}

	return (
		<Wrapper ref={toTopRef}>
			{activeIndex !== null ? (
				<Container>
					<Typography.H3 marginOffset={{ bottom: 4 }}>
						{allTopics[activeIndex].title}
					</Typography.H3>
					<Content>{allTopics[activeIndex].content}</Content>
					<Navigation>
						{activeIndex > 0 && (
							<Icon
								svg={Svgs.ChevronLeft}
								variant={v => v.button}
								marginOffset={{ right: 1 }}
								onClick={handlePrevTopic}
							/>
						)}
						{activeIndex < allTopics.length - 1 && (
							<Icon
								svg={Svgs.ChevronRight}
								onClick={handleNextTopic}
								variant={v => v.button}
							/>
						)}
					</Navigation>
				</Container>
			) : (
				<Typography.H3 marginOffset={{ bottom: 4 }}>
					{translate(dict => dict.helpPage.topicNotFound)}
				</Typography.H3>
			)}
		</Wrapper>
	);
}

import { Operator } from 'api/data/filters';
import { ProjectFilterType } from 'types/data/projects/constants';
import { VariableType } from 'types/data/variables/constants';

import { Dictionary } from 'environment';

export function withOpacity(color: string, opacity: number) {
	const HEXRegEx = /#[0-9A-Fa-f]+/;
	const length = color.length;

	if (HEXRegEx.test(color)) {
		if (length === 7) {
			return `rgba(
                ${parseInt(color[1], 16) * 16 + parseInt(color[2], 16)}, 
                ${parseInt(color[3], 16) * 16 + parseInt(color[4], 16)},
                ${parseInt(color[5], 16) * 16 + parseInt(color[6], 16)},
                ${opacity})`;
		} else if (length === 4) {
			return `rgba(
                ${parseInt(color[1], 16) * 17}, 
                ${parseInt(color[2], 16) * 17},
                ${parseInt(color[3], 16) * 17},
                ${opacity})`;
		} else throw new Error('The input is not a hexadecimal color value');
	} else throw new Error('The input is not a hexadecimal color value');
}

export function getOperatorLabel(operator: string, type: VariableType | ProjectFilterType) {
	const isDateOrTime = [
		VariableType.Date,
		VariableType.DateTime,
		ProjectFilterType.Date
	].includes(type);

	const isDate = type === VariableType.Date;

	const isDateTime = type === VariableType.DateTime;

	const equalsLabel: string = isDate
		? Dictionary.displayers.atADate
		: isDateTime
		? Dictionary.displayers.atADateAndTime
		: Dictionary.displayers.equals;

	switch (operator) {
		case Operator.Between: {
			return Dictionary.displayers.between;
		}

		case Operator.Equals: {
			return equalsLabel;
		}

		case Operator.GreaterThan: {
			return isDateOrTime ? Dictionary.displayers.after : Dictionary.displayers.greaterThan;
		}

		case Operator.LessThan: {
			return isDateOrTime ? Dictionary.displayers.before : Dictionary.displayers.lessThan;
		}

		default:
			return Dictionary.displayers.between;
	}
}

export function getNumeralOrderShortEng(position: number) {
	return ['st', 'nd', 'rd'][((((position + 90) % 100) - 10) % 10) - 1] || 'th';
}

import { ActionTypes, logInToJadbio, isLoggedInToJADBio } from 'store/addons/jadbio';
import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useLogInToJADBio(): OperationResult<
	boolean,
	(username: string, pass: string) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => isLoggedInToJADBio(state.addons.jadbio));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.LOG_IN_TO_JADBIO);

	function handler(username: string, pass: string) {
		if (error) resetError();
		dispatch(logInToJadbio(username, pass));
	}

	return [{ data, loading, error }, handler];
}

import {
	ElementMenu,
	FileWrapper,
	HoverableArea,
	ToolTipWrapper,
	VariableHeader
} from 'components/Forms';
import { FormInput } from '../../FormInput';
import { FormElementGeneralProps } from '../FormVariable';
import { FileInput } from './FileInput';
import { useForm, useFormDesignerDrawerContext, useRemoveFormElement } from 'hooks/store';
import { useCallback } from 'react';
import { FormVariableSettings } from '../FormVariableSettings';

export function FormVariableFile({
	element,
	variable,
	value,
	formContext,
	tooltipComponent,
	disabled,
	readOnly,
	canBeRemoved,
	isInGroup,
	usedInFormDesigner
}: FormElementGeneralProps) {
	const { elementId } = element;
	const { name, obligatory: required } = variable;

	const removeFormElement = useRemoveFormElement();

	const onRemoveElement = useCallback(() => {
		removeFormElement({ elementId });
	}, [elementId]);

	const { onOpen, elementId: selelctedElementId } = useFormDesignerDrawerContext();
	const [{ data: form }] = useForm();

	const onOpenDrawer = useCallback(() => {
		onOpen(elementId);
	}, [elementId]);

	const isSelected = form?.elements[selelctedElementId]?.variableRef === variable.name;

	return (
		<HoverableArea
			isInGroup={isInGroup}
			usedInFormDesigner={usedInFormDesigner}
			activeHover={isSelected}
		>
			<ElementMenu usedInFormDesigner={usedInFormDesigner}>
				<VariableHeader>
					<FormInput
						element={element}
						required={required}
						disabled={disabled}
						usedInFormDesigner={usedInFormDesigner}
					/>
					{tooltipComponent && <ToolTipWrapper>{tooltipComponent}</ToolTipWrapper>}
				</VariableHeader>
				<FileWrapper usedInFormDesigner={usedInFormDesigner}>
					<FileInput
						name={name}
						disabled={disabled}
						readOnly={readOnly}
						value={value}
						formContext={formContext}
					/>
				</FileWrapper>
			</ElementMenu>
			{usedInFormDesigner && (
				<FormVariableSettings
					variableName={variable.name}
					onEdit={onOpenDrawer}
					{...(canBeRemoved ? { onDelete: onRemoveElement } : {})}
				/>
			)}
		</HoverableArea>
	);
}

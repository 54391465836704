import produce from 'immer';

import { AdminAccount } from 'api/account/admin';
import { DATE_TIME_FORMAT } from 'consts';

import initialState from './initialState';
import { Actions, ActionTypes, State, AdminUsersFilterNames, DateFilterOperator } from './types';
import { formatAPIDate } from 'helpers/dateFormat';

export default (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case ActionTypes.ADMIN_GET_USERS: {
			const { users } = action.payload;

			return produce(state, draft => {
				const { byId, filters, metadata } = draft.users;

				const licences: string[] = [];
				const organizations: string[] = [];
				const cognitoStatuses: string[] = [];

				users.forEach(user => {
					const computedUser: AdminAccount = {
						userid: user.userid,
						licenceModel: user.licenceModel ?? '',
						cognitoCreatedDate: formatAPIDate(
							user.cognitoCreatedDate,
							DATE_TIME_FORMAT
						) as string,
						cognitoUserStatus: user.cognitoUserStatus,
						userFirstName: user.userFirstName ?? '',
						userSirName: user.userSirName ?? '',
						position: user.position ?? '',
						department: user.department ?? '',
						workplace: user.workplace ?? '',
						city: user.city ?? '',
						country: user.country ?? '',
						phoneNumber: user.phoneNumber ?? '',
						emailAddress: user.emailAddress,
						imageURL: user.imageURL ?? '',
						imageString: user.imageString ?? '',
						address: user.address ?? '',
						status: user.status ?? '',
						userPreferences: user.userPreferences,
						userCredentials: user.userCredentials,
						subscriptionId: user.subscriptionId ?? ''
					};

					if (
						computedUser.licenceModel.length &&
						!licences.includes(computedUser.licenceModel)
					) {
						licences.push(computedUser.licenceModel);
					}

					if (!organizations.includes(computedUser.workplace)) {
						organizations.push(computedUser.workplace);
					}

					if (!cognitoStatuses.includes(computedUser.cognitoUserStatus)) {
						cognitoStatuses.push(computedUser.cognitoUserStatus);
					}

					byId[computedUser.userid] = computedUser;
				});

				filters.licence.all = licences.sort();
				filters.organizations.all = organizations.sort();
				filters.status.all = cognitoStatuses.sort();
				metadata.fetched = true;
			});
		}

		case ActionTypes.ADMIN_GET_USER: {
			const { user } = action.payload;

			return produce(state, draft => {
				const { byId } = draft.users;

				byId[user.userid] = user;
			});
		}

		case ActionTypes.ADMIN_CREATE_USER: {
			const { user } = action.payload;

			return produce(state, draft => {
				const { filters, byId } = draft.users;

				const { userid, workplace, licenceModel } = user;

				const isNewOrganization = !filters.organizations.all.includes(workplace);
				const isNewLicence = !filters.licence.all.includes(licenceModel);
				if (isNewOrganization) {
					filters.organizations.all.push(workplace) && filters.organizations.all.sort();
				}
				if (isNewLicence) {
					filters.licence.all.push(licenceModel) && filters.licence.all.sort();
				}

				// ADD USER
				byId[userid] = user;
			});
		}

		case ActionTypes.ADMIN_UPDATE_USER: {
			const { user } = action.payload;

			return produce(state, draft => {
				const {
					filters: { organizations, licence },
					byId
				} = draft.users;

				const { userid, workplace } = user;

				const previousOrganization = byId[userid].workplace;
				const organizationChanged = previousOrganization !== workplace;

				if (organizationChanged) {
					let organizationCount = 0;
					Object.values(byId).forEach(
						user => user.workplace === previousOrganization && organizationCount++
					);

					const isAloneInOrganization = organizationCount === 1;
					if (isAloneInOrganization) {
						// clear inexistent organization EXCEPT the empty one ""
						if (previousOrganization !== '')
							organizations.all = organizations.all.filter(
								value => value !== previousOrganization
							);
						// clear inexistent organization from active list
						organizations.active = organizations.active.filter(
							value => value !== previousOrganization
						);
					}

					const isNewOrganization = !organizations.all.includes(workplace);
					if (isNewOrganization)
						organizations.all.push(workplace) && organizations.all.sort();
				}

				// ADD NEW LICENCE TO THE LIST IF NEW
				const isNewLicence = !licence.all.includes(user.licenceModel);

				if (isNewLicence) licence.all.push(user.licenceModel) && licence.all.sort();

				// UPDATE USER
				byId[userid] = user;
			});
		}

		case ActionTypes.ADMIN_REMOVE_USER: {
			return produce(state, draft => {
				const {
					metadata: { userId },
					byId,
					filters
				} = draft.users;

				if (userId && byId[userId]) {
					const { workplace, licenceModel } = byId[userId];

					// check if user is alone in organization
					let organizationCount = 0;
					// check if inactive licences exists
					let licenceCount = 0;
					Object.values(byId).forEach(user => {
						user.workplace === workplace && organizationCount++;
						user.licenceModel === licenceModel && licenceCount++;
					});

					const isAloneInOrganization = organizationCount === 1;
					if (isAloneInOrganization) {
						// clear inexistent organization EXCEPT the empty one ""
						if (workplace !== '')
							filters.organizations.all = filters.organizations.all.filter(
								value => value !== workplace
							);
						// clear inexistent organization from active list
						filters.organizations.active = filters.organizations.active.filter(
							value => value !== workplace
						);
					}

					const isTheOnlyLicence = licenceCount === 1;
					if (isTheOnlyLicence) {
						// clear inexistent licence
						filters.licence.all = filters.licence.all.filter(
							value => value !== licenceModel
						);
						// clear inexistent licence from active list
						filters.licence.active = filters.licence.active.filter(
							value => value !== licenceModel
						);
					}

					// REMOVE USER
					delete byId[userId];
				}
			});
		}

		case ActionTypes.ADMIN_RESET_USER_PASSWORD: {
			const { userId } = action.payload;

			return produce(state, draft => {
				const { byId } = draft.users;

				if (byId[userId]) byId[userId].cognitoUserStatus = 'FORCE_CHANGE_PASSWORD';
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.ADMIN_SET_USERS_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.users.metadata.searchTerm = term;
			});
		}

		case ActionTypes.ADMIN_SET_USERS_USER_ID: {
			const { userId } = action.payload;

			return produce(state, draft => {
				draft.users.metadata.userId = userId;
			});
		}

		case ActionTypes.ADMIN_SET_TABLE_PAGE_PARAMS: {
			const { pageIndex, pageSize } = action.payload;

			return produce(state, draft => {
				draft.users.metadata.pageIndex = pageIndex;
				draft.users.metadata.pageSize = pageSize;
			});
		}

		//////////////////////////////////////////////////////////////////////////////
		// ================================= FILTERS =================================
		//////////////////////////////////////////////////////////////////////////////

		case ActionTypes.ADMIN_SET_USERS_FILTER: {
			const { filterName, value } = action.payload;

			return produce(state, draft => {
				const { filters } = draft.users;

				// ORGANIZATION FILTER
				if (filterName === AdminUsersFilterNames.Organization) {
					const { active } = filters.organizations;

					const newActive: string[] = active.includes(value)
						? active.filter(activeValue => activeValue !== value)
						: [...active, value];

					filters.organizations.active = newActive;
				}
				// STATUS FILTER
				if (filterName === AdminUsersFilterNames.Status) {
					const { active } = filters.status;

					const newActive: string[] = active.includes(value)
						? active.filter(activeValue => activeValue !== value)
						: [...active, value];

					filters.status.active = newActive;
				}
				// LICENCE FILTER
				if (filterName === AdminUsersFilterNames.Licence) {
					const { active } = filters.licence;

					const newActive: string[] = active.includes(value)
						? active.filter(activeValue => activeValue !== value)
						: [...active, value];

					filters.licence.active = newActive;
				}
			});
		}

		case ActionTypes.ADMIN_SET_USERS_DATE_FILTER: {
			const { filterName, filter } = action.payload;

			return produce(state, draft => {
				const { filters } = draft.users;

				if (filterName === AdminUsersFilterNames.CreatedDate) {
					filters.createdDate = filter;
				}
			});
		}

		case ActionTypes.ADMIN_RESET_USERS_FILTER: {
			const { filterName } = action.payload;

			return produce(state, draft => {
				const { organizations, status, createdDate, licence } = draft.users.filters;

				if (filterName === AdminUsersFilterNames.Organization) {
					organizations.active = [];
				}
				if (filterName === AdminUsersFilterNames.Status) {
					status.active = [];
				}
				if (filterName === AdminUsersFilterNames.CreatedDate) {
					createdDate.valid = false;
					createdDate.operator = DateFilterOperator.Before;
					createdDate.value = null;
					createdDate.from = null;
					createdDate.to = null;
				}
				if (filterName === AdminUsersFilterNames.Licence) {
					licence.active = [];
				}
			});
		}

		case ActionTypes.ADMIN_RESET_ALL_USERS_FILTERS: {
			return produce(state, draft => {
				const { status, organizations, createdDate, licence } = draft.users.filters;

				// CHECKBOX
				status.active = [];
				licence.active = [];
				organizations.active = [];
				// DATE
				createdDate.valid = false;
				createdDate.operator = DateFilterOperator.Before;
				createdDate.value = null;
				createdDate.from = null;
				createdDate.to = null;
			});
		}

		case ActionTypes.ADMIN_GET_ENTERPRISE_ORGANIZATIONS: {
			const { organizations } = action.payload;
			return produce(state, draft => {
				const orgMap = Object.assign(
					{},
					...organizations.map(org => ({ [org.subscriptionId]: org }))
				);
				if (!draft.enterpriseOrganizations) {
					draft.enterpriseOrganizations = {
						byOrganizationId: {},
						organizationId: ''
					};
				}
				draft.enterpriseOrganizations.byOrganizationId = orgMap;
			});
		}

		case ActionTypes.ADMIN_SET_ORGANIZATION_ID: {
			const { organizationId } = action.payload;
			return produce(state, draft => {
				if (!draft.enterpriseOrganizations) {
					draft.enterpriseOrganizations = {
						byOrganizationId: {},
						organizationId: ''
					};
				}
				draft.enterpriseOrganizations.organizationId = organizationId;
			});
		}

		case ActionTypes.ADMIN_GET_DOMAINS_FOR_ORGANIZATION: {
			const {
				domains: { blacklistedDomains, whitelistedDomains },
				organizationId
			} = action.payload;
			return produce(state, draft => {
				if (draft.enterpriseOrganizations?.byOrganizationId[organizationId]) {
					const computedDomains = {
						allowed: [...new Set(whitelistedDomains)],
						blocked: [...new Set(blacklistedDomains)]
					};

					draft.enterpriseOrganizations.byOrganizationId[organizationId].domains =
						computedDomains;
				}
			});
		}

		case ActionTypes.ADD_WHITELISTED_DOMAINS: {
			const { domains, organizationId } = action.payload;
			return produce(state, draft => {
				if (draft.enterpriseOrganizations?.byOrganizationId[organizationId]) {
					const existingDomains =
						draft.enterpriseOrganizations.byOrganizationId[organizationId].domains
							?.allowed;
					const allDomains = [...new Set([...(existingDomains ?? []), ...domains])];
					//empty domains array
					draft.enterpriseOrganizations.byOrganizationId[
						organizationId
					].domains?.allowed.splice(0, existingDomains?.length);
					//repopulate with fresh domains
					draft.enterpriseOrganizations.byOrganizationId[
						organizationId
					].domains?.allowed.push(...allDomains);
				}
			});
		}

		case ActionTypes.REMOVE_DOMAINS: {
			const { domains: domainsToRemove, organizationId } = action.payload;
			return produce(state, draft => {
				if (
					!draft.enterpriseOrganizations?.byOrganizationId[organizationId] ||
					!draft.enterpriseOrganizations?.byOrganizationId[organizationId].domains
				)
					return;
				else {
					const domains =
						draft.enterpriseOrganizations.byOrganizationId[organizationId].domains
							?.allowed;
					if (domains) {
						const filteredList = domains.filter(
							item => !domainsToRemove.includes(item)
						);
						if (!draft.enterpriseOrganizations.byOrganizationId[organizationId].domains)
							return;
						else {
							//@ts-ignore
							draft.enterpriseOrganizations.byOrganizationId[
								organizationId
							].domains.allowed = filteredList;
						}
					}
				}
			});
		}

		default: {
			return state;
		}
	}
};

import { StyleOffset } from 'types/index';

import { Container, Text } from './TabsItem.style';

interface Props {
	children: React.ReactNode;
	active: boolean;
	className?: string;
	onChangeTab: () => void;
	marginOffset?: StyleOffset;
	hasIcon?: boolean;
}

export function TabsItem({
	children,
	active,
	className,
	onChangeTab,
	marginOffset,
	hasIcon
}: Props) {
	return (
		<Container
			active={active}
			className={className}
			onClick={onChangeTab}
			marginOffset={marginOffset}
			hasIcon={hasIcon}
		>
			<Text>{children}</Text>
		</Container>
	);
}

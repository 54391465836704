import { Header } from 'components/Header';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { SearchInput } from 'components/UI/Inputs/SearchInput';
import { useTranslation } from 'hooks/store';
import { SetState } from 'types/index';

interface Props {
	isProjectOwner: boolean;
	hasStatuses: boolean;
	searchTermState: {
		searchTerm: string;
		setSearchTerm: SetState<string>;
	};
	onCreateClick: () => void;
	onCreateFromCategoriesClick: () => void;
}

export function StatusesPageHeader({
	isProjectOwner,
	hasStatuses,
	searchTermState: { searchTerm, setSearchTerm },
	onCreateClick,
	onCreateFromCategoriesClick
}: Props) {
	const { translate } = useTranslation();

	return (
		<>
			<Header.Main />
			<Header.Navigation
				rightComponent={({ addButton }) =>
					isProjectOwner &&
					hasStatuses && (
						<Flex>
							<SearchInput
								usedInHeader
								placeholder={translate(
									dict => dict.statusesPage.statusesPageHeader.placeholder
								)}
								term={searchTerm}
								onChangeTerm={term => term !== searchTerm && setSearchTerm(term)}
							/>

							<Flex>
								<Dropdown
									toggleComponent={({ ref, toggle }) => (
										<div ref={ref}>
											{addButton({
												label: translate(dict => dict.terms.new),
												action: toggle
											})}
										</div>
									)}
									width={22}
									offset={{ top: 4, right: 0 }}
								>
									<Dropdown.Item
										dataTestId="createNewStatus"
										title={translate(({ statuses }) => statuses.addNew.status)}
										onClick={onCreateClick}
									/>
									<Dropdown.Item
										dataTestId="createNewStatusesFromCategoryVariable"
										title={translate(
											({ statuses }) => statuses.addNew.fromCategoryVariable
										)}
										onClick={onCreateFromCategoriesClick}
									/>
								</Dropdown>
							</Flex>
						</Flex>
					)
				}
			/>
			<Header.Title title={translate(({ statuses }) => statuses.pageTitle)} />
		</>
	);
}

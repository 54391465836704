// TODO: this slice of redux store seems to be conflicting with data/entries/subEntries
// perhaps should be considered for a refactor
import { createSelector } from 'reselect';

import { State } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getSeriesTableParams({ metadata, bySeriesName }: State) {
	if (bySeriesName && metadata[bySeriesName]) {
		return {
			pageIndex: metadata[bySeriesName].pageIndex,
			pageSize: metadata[bySeriesName].pageSize
		};
	}
	return {
		pageIndex: metadata.defaultValues.pageIndex,
		pageSize: metadata.defaultValues.pageSize
	};
}

function getSeriesTableBySeriesName({ metadata, bySeriesName }: State) {
	return { metadata, bySeriesName };
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

export const selectSeriesTableParams = createSelector([getSeriesTableParams], params => params);

export const selectSeriesTableVisibleColumns = createSelector(
	[getSeriesTableBySeriesName],
	({ metadata, bySeriesName }) =>
		bySeriesName && metadata[bySeriesName]
			? metadata[bySeriesName].columnSettings.visible
			: metadata.defaultValues.columnSettings.visible
);

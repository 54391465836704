import { useState, useMemo } from 'react';

import { AdminAccount } from 'api/account/admin';
import { UserCard } from 'components/UI/UserCard';
import { DATE_FORMAT_COMMA } from 'consts';

import { UserLicenceLabels } from 'store/account/subscription';
import {
	CustomisableTableColumn,
	CustomisableTableRow,
	CustomCellType,
	FilterByTypeComponent,
	GenericMap,
	TableFilterType,
	TableName
} from 'types/index';

import { formatAPIDate } from 'helpers/dateFormat';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useAdminUsersUserId, useFilterBySearchTerm } from 'hooks/store';

import { CustomisableTable } from 'components/UI/CustomisableTable';

interface Props {
	users: AdminAccount[];
}

export function AdminUsersList({ users }: Props) {
	const { navigate, routes } = useNavigation();
	const { translate } = useTranslation();

	const [_, setUserId] = useAdminUsersUserId();

	const filteredUsersBySearchTerm = useFilterBySearchTerm(
		users,
		FilterByTypeComponent.AdminUsers
	) as AdminAccount[];

	function userNameComponent(user: AdminAccount) {
		return (
			<UserCard.Table
				userId={user.userid}
				userData={{
					userFirstName: user.userFirstName,
					userSirName: user.userSirName,
					emailAddress: user.emailAddress
				}}
			/>
		);
	}

	const [customCells, setCustomCells] = useState<CustomCellType>({});

	// populate tableItems and customCells with data
	const tableItems = useMemo(() => {
		const items: CustomisableTableRow[] = [];
		filteredUsersBySearchTerm.forEach(user => {
			const userCredentials = user.userCredentials;

			const item = {
				id: user.userid,
				name: user.userFirstName + ' ' + user.userSirName,
				username: user.userid,
				emailAddress: user.emailAddress,
				workplace: user.workplace,
				department: user.department,
				cognitoCreatedDate: user.cognitoCreatedDate, //temporaryPasswordValidityDays:,
				cognitoUserStatus: user.cognitoUserStatus,
				licenceModel: translate(() => UserLicenceLabels[user.licenceModel]),
				temporaryPasswordValidityDays: userCredentials
					? userCredentials.temporaryPasswordValidityDays
					: '',
				forceChangePassword: userCredentials
					? userCredentials.forceChangePassword.toString()
					: '',
				phoneNumberVerified: userCredentials
					? userCredentials.phoneNumberVerified.toString()
					: '',
				subscriptionId: user.subscriptionId ?? ''
			};
			items.push(item);

			// adding custom cell for user's name containing a UserCard
			const customCell: GenericMap<string | JSX.Element> = {};
			customCell.name = userNameComponent(user);

			customCell.cognitoCreatedDate = formatAPIDate(
				user.cognitoCreatedDate,
				DATE_FORMAT_COMMA
			).toString();

			setCustomCells(prev => ({ ...prev, [item.id]: customCell }));
		});
		return items;
	}, [filteredUsersBySearchTerm]);

	const columns: CustomisableTableColumn[] = useMemo(
		() => [
			{
				name: 'name',
				label: translate(({ admin }) => admin.users.list.columns.name),
				filterType: TableFilterType.Text
			},
			{
				name: 'username',
				label: translate(({ admin }) => admin.users.list.columns.username),
				filterType: TableFilterType.Text
			},
			{
				name: 'emailAddress',
				label: translate(({ admin }) => admin.users.list.columns.email),
				filterType: TableFilterType.Text
			},
			{
				name: 'workplace',
				label: translate(dict => dict.accountUM.userDetails.workplace),
				filterType: TableFilterType.Checkbox,
				isOptional: true
			},
			{
				name: 'department',
				label: translate(({ admin }) => admin.users.list.columns.department),
				filterType: TableFilterType.Checkbox,
				isOptional: true
			},
			{
				name: 'cognitoCreatedDate',
				label: translate(({ admin }) => admin.users.list.columns.createdDate),
				filterType: TableFilterType.Date,
				isOptional: true
			},
			{
				name: 'cognitoUserStatus',
				label: translate(({ admin }) => admin.users.list.columns.status),
				filterType: TableFilterType.Checkbox,
				isOptional: true
			},
			{
				name: 'licenceModel',
				label: translate(({ admin }) => admin.users.list.columns.licence),
				filterType: TableFilterType.Checkbox,
				isOptional: true
			},
			{
				name: 'temporaryPasswordValidityDays',
				label: translate(
					({ admin }) => admin.users.list.columns.temporaryPasswordValidityDays
				),
				filterType: TableFilterType.Numeric,
				isOptional: true
			},
			{
				name: 'forceChangePassword',
				label: translate(({ admin }) => admin.users.list.columns.forceChangePassword),
				filterType: TableFilterType.Checkbox,
				isOptional: true
			},
			{
				name: 'phoneNumberVerified',
				label: translate(({ admin }) => admin.users.list.columns.phoneNumberVerified),
				filterType: TableFilterType.Checkbox,
				isOptional: true
			},
			{
				name: 'subscriptionId',
				label: translate(({ admin }) => admin.users.details.form.fields.subscriptionId),
				filterType: TableFilterType.Text,
				isOptional: true
			}
		],
		[]
	);

	function handleSelect(userId: string) {
		setUserId(userId);
		navigate(routes.admin.details(userId));
	}

	return (
		<CustomisableTable
			tableName={TableName.AdminUsers}
			items={tableItems}
			columns={columns}
			itemsCountLabel={translate(dict => dict.tableLists.users)}
			customCells={customCells}
			hasPagination
			onRowClick={id => handleSelect(id)}
		/>
	);
}

import styled from 'styled-components';

export const Container = styled.div`
	padding: 6rem 2.4rem 4.8rem;
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: auto;
	max-width: 42.8rem;
`;

export const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 9.6rem;
`;

export const ChromeLogo = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	object-fit: contain;
`;

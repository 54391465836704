import styled, { css } from 'styled-components';

import { Icon as DefaultIcon } from 'components/UI/Icons/Icon';
import { Dropdown as DefaultDropdown } from 'components/UI/Dropdown/Dropdown';
import { Colors, MediaQueries, Shadows } from 'environment';

export const ModulesContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const DraggableGroupCardWrapper = styled.div``;

interface GroupProps {
	groupCard?: boolean;
	isDraggableGrouped?: boolean;
	groupedVariable?: boolean;
	groupBroken?: boolean;
}

export const DraggableCard = styled.div<GroupProps>`
	height: 4rem;
	width: 100%;
	padding: 0.8rem 1.6rem;
	border-radius: 0.4rem;
	box-shadow: ${Shadows.normal};
	display: flex;
	margin-bottom: 0.8rem;
	position: relative;
	background-color: ${Colors.white};
	align-items: center;
	transition: box-shadow 0.05s;

	:hover {
		box-shadow: ${Shadows.hover};

		.add-group-icon,
		.add-grouped-variable-icon,
		.add-set-icon,
		.add-variable-icon {
			visibility: visible;

			:hover {
				.times-group-used,
				.times-set-used,
				.times-grouped-variable-used,
				.times-variable-used {
					visibility: visible;
				}
			}
		}
	}

	@media ${MediaQueries.maxWidth.lg} {
		max-width: 100%;
	}

	${({ groupCard }) =>
		groupCard &&
		css`
			padding: 0.8rem;
			padding-left: 1.2rem;
		`}
	${({ groupedVariable }) =>
		groupedVariable &&
		css`
			width: 28rem;
		`}
	${({ groupBroken }) =>
		groupBroken &&
		css`
			background-color: ${Colors.gray.lighter};
		`}
`;

interface ElementProps {
	activeHover?: boolean;
	usedInFormDesigner: boolean;
	isSeparator?: boolean;
	isInGroup?: boolean;
	isBasicElement?: boolean;
	fullWidth?: boolean;
}

export const HoverableArea = styled.div<ElementProps>`
	position: relative;
	display: flex;
	position: relative;
	margin: 1.2rem;
	${({ usedInFormDesigner, fullWidth }) =>
		usedInFormDesigner &&
		css`
			width: ${fullWidth ? '100%' : 'calc(50% - 1.2rem)'};
		`}

	:hover {
		::after {
			border-color: ${Colors.primary.normal};
		}
		:last-child {
			> div {
				visibility: visible;
				div {
					visibility: visible;
				}
			}
		}
		::after {
			border-color: ${Colors.primary.normal};
		}
	}

	${({ usedInFormDesigner }) =>
		usedInFormDesigner &&
		css`
			::after {
				position: absolute;
				content: '';
				top: -0.8rem;
				left: -0.8rem;
				right: -0.8rem;
				bottom: -0.8rem;
				border: 0.2rem solid ${Colors.transparent};
				border-radius: 0.8rem;
			}
		`}

	${({ activeHover }) =>
		activeHover &&
		css`
			::after {
				border-color: ${Colors.primary.normal};
			}
			> :last-child > :first-child {
				visibility: visible;
			}
		`}
`;

export const ElementMenu = styled.div<ElementProps>`
	width: 100%;
	display: flex;
	flex-direction: column;

	${({ isSeparator, usedInFormDesigner }) => css`
		padding: ${isSeparator && usedInFormDesigner && '1rem 0'};
	`}
`;

export const ElementMenuControls = styled.div<{ isSubtitle?: boolean }>`
	position: absolute;
	// 0.8rem border padding + 2.4rem absolute width of this component
	right: -3.2rem;
	height: 2.4rem;
	width: 2.4rem;
	// (2.8rem container height - 2.4rem absolute width of this component) / 2
	top: ${({ isSubtitle }) => (!isSubtitle ? '-0.1rem' : '0.2rem')};

	:hover {
		> :first-child {
			visibility: visible;
		}
	}
`;

export const MoreIcon = styled(DefaultIcon)`
	color: white;
	background: ${Colors.primary.normal};
	border-radius: 0 0.4rem 0.4rem 0;
	padding: 0.4rem;

	:hover {
		color: white;
	}
`;

export const MoreMenuDropdown = styled(DefaultDropdown)`
	position: absolute;
	top: 0;
	right: 0;
	visibility: hidden;
	width: auto;
`;

export const DeleteIcon = styled(DefaultIcon)`
	position: absolute;
	top: 0;
	right: 0;
	visibility: hidden;
	color: white;
	background: ${Colors.primary.normal};
	border-radius: 0 0.4rem 0.4rem 0;
	padding: 0.4rem;
	width: 2.4rem;
	height: 2.4rem;

	:hover {
		color: white;
	}
`;

export const VariableHeader = styled.div`
	display: flex;
	position: relative;
	width: 100%;

	> :nth-child(2) {
		width: 2.4rem;
		height: 2.4rem;
		display: flex;
		align-items: center;
		margin-left: 0.5rem;
	}
`;

export const InputWrapper = styled.div<ElementProps>`
	max-width: ${({ usedInFormDesigner, fullWidth }) =>
		usedInFormDesigner || fullWidth ? 'unset' : '29rem'};
	display: flex;
	width: 100%;

	@media ${MediaQueries.maxWidth.md} {
		max-width: ${({ usedInFormDesigner }) => (usedInFormDesigner ? 'unset' : '100%')};
	}

	${({ usedInFormDesigner }) =>
		usedInFormDesigner &&
		css`
			pointer-events: 'none';
			.react-date-picker__inputGroup {
				min-width: unset;
				width: fit-content;
			}
		`}
`;

export const FileWrapper = styled.div<ElementProps>`
	pointer-events: ${({ usedInFormDesigner }) => usedInFormDesigner && 'none'};
`;

export const ToolTipWrapper = styled.div`
	position: relative;
	> :first-child {
		> :first-child {
			position: inherit;
			> :first-child {
				padding: 0;
			}
		}
	}
`;

export const TimesUsed = styled.div`
	background: ${Colors.text.main};
	color: ${Colors.background.disabled};
	visibility: hidden;
	position: absolute;
	right: 0;
	right: -2.4rem;
	top: -4.2rem;
	z-index: 999;
	padding: 0.4rem 0.8rem;
	border-radius: 0.4rem;
	width: max-content;

	&:before {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-top: 1rem solid ${Colors.text.main};
		border-bottom: 1rem solid transparent;
		right: 2.6rem;
		bottom: -2rem;
	}

	p {
		font-size: 1.2rem;
		color: ${Colors.silver.lightest};
	}

	@media ${MediaQueries.maxWidth.lg} {
		right: 0;

		&:before {
			left: 75%;
		}
	}
`;

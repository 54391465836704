import { State } from './types';

const initialState: State = {
	projectId: null,
	byProjectId: {},
	selectedOrganizationsById: {},
	metadata: {
		searchTerm: ''
	},
	refetch: false
};

export default initialState;

import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 2.4rem;
	height: 100%;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ToolTipHoverableArea = styled.div`
	padding-top: 0.8rem;
	height: 10rem;
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: end;
	margin-top: auto;
`;

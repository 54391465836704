import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import {
	Columns,
	JADBioAnalysis,
	JADBioAnalysisModels,
	JADBioAnalysisModelsLabels
} from 'api/data/analyses';
import { VariablesData } from 'store/data/variables';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { SelectItem } from 'types/index';
import { MediaQueries, Svgs } from 'environment';

import { ConfigContainer } from '../../UI';
import { JADBioConfigReduction } from './JADBioConfigReduction';
import { JADBioConfigProbabilities } from './JADBioConfigProbabilities';
import { JADBioConfigFeatureImportance } from './JADBioConfigFeatureImportance';
import { JADBioWarningModal } from '../JADBioWarningModal';
import { OptionsHeaderContainer } from '../../UI';
import { Typography } from 'components/UI/Typography';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Icon } from 'components/UI/Icons';
import { Spacer } from 'components/UI/Spacer';
import { Button } from 'components/UI/Interactables/Button';
import { isClasificationVariableWithTwoValues } from 'helpers/analysis';
import {
	useTranslation,
	useUpdateAnalysis,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysisActiveTab,
	useAbortJADBioAnalysisGeneration,
	useAnalysesActiveColum,
	useVariableByName,
	useFilters
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';

interface Props {
	analysis: JADBioAnalysis;
	variablesData: VariablesData;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
	generatingAnalysis: boolean;
	generate: (alreadyInProgress?: boolean) => void;
}

export function JADBioConfig({
	analysis,
	variablesData,
	variablesDataSelectItems,
	loading,
	generatingAnalysis,
	generate: generateJADBioAnalysis
}: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const {
		id: analysisId,
		input: { model, variables },
		output: { dataset }
	} = analysis;

	const [draftModel, setDraftModel] = useState(model);
	const [values, setValues] = useState(variables);
	const [
		{ isConfigPanelOpen, isParamsOpen, isJadBioPerformanceOpen },
		{ openConfigPanel, openParameters, openJadBioPerformance }
	] = useAnalysisConfigPanel(analysis.id);
	const isMaxWidthLg = useMediaQuery(MediaQueries.maxWidth.lg);
	const [fullscreen] = useFullscreenAnalysis();
	const [{ filters, activeFilterIds, areFiltersOpen }] = useFilters();

	const [showJADBioWarningModal, setShowJADBioWarningModal] = useState(false);

	const { variablesMap } = variablesData;
	const { selectItemsMap, categorySelectItems, numericSelectItems } = variablesDataSelectItems;

	const [activeTab, setActiveTab] = useAnalysisActiveTab(analysisId);

	const [{ loading: abortJADBioGenerationLoading }, abortJADBioGeneration] =
		useAbortJADBioAnalysisGeneration(analysisId, model);

	const [activeColumns] = useAnalysesActiveColum();

	const analysisFetched = useMemo(
		() => !!(model && dataset[model] && dataset[model].summary.length),
		[model, dataset]
	);

	// SYNC `draftModel` STATE
	useEffect(() => {
		if (!isEqual(model, draftModel)) setDraftModel(model);
	}, [model]);

	// SYNC `values` STATE
	useEffect(() => {
		if (!isEqual(variables, values)) setValues(variables);
	}, [variables]);

	useEffect(() => {
		if (!isEqual(model, draftModel) || !isEqual(variables, values)) {
			const updatedAnalysis: JADBioAnalysis = {
				...analysis,
				input: {
					...analysis.input,
					model: draftModel,

					variables: !isEqual(draftModel, model)
						? {
								classificationVariable: '',
								eventVariableName: '',
								regressionVariable: '',
								timeToEventVariableName: ''
						  }
						: values
				}
			};

			updateAnalysis({ analysis: updatedAnalysis });
		}
	}, [draftModel, values]);

	//TODO: for now, only Classification model is supported
	const JADBioAnalysisModelsSelectItems: SelectItem[] = [
		{
			label: translate(() => JADBioAnalysisModelsLabels[JADBioAnalysisModels.Classification]),
			value: JADBioAnalysisModels.Classification
		}
		// {
		// 	label: translate(
		// 		() => JADBioAnalysisModelsLabels[JADBioAnalysisModels.SurvivalAnalysis]
		// 	),
		// 	value: JADBioAnalysisModels.SurvivalAnalysis
		// },
		// {
		// 	label: translate(() => JADBioAnalysisModelsLabels[JADBioAnalysisModels.Regression]),
		// 	value: JADBioAnalysisModels.Regression
		// }
	];

	const isClassificationModel = draftModel && draftModel === JADBioAnalysisModels.Classification;
	const isSurvivalModel = draftModel && draftModel === JADBioAnalysisModels.SurvivalAnalysis;
	const isRegressionModel = draftModel && draftModel === JADBioAnalysisModels.Regression;

	const classificationVariable = useVariableByName(variables.classificationVariable);

	const clasificationVariableWithTwoValues = isClasificationVariableWithTwoValues(
		classificationVariable,
		filters,
		activeFilterIds
	);

	const canAbortGeneration = generatingAnalysis;

	const canGenerateAnalysis =
		!loading &&
		((isClassificationModel &&
			variablesMap[variables.classificationVariable] &&
			clasificationVariableWithTwoValues) ||
			(isSurvivalModel &&
				variablesMap[variables.eventVariableName] &&
				variablesMap[variables.timeToEventVariableName]) ||
			(isRegressionModel && variablesMap[variables.regressionVariable]));

	function abortGeneration() {
		abortJADBioGeneration();
	}

	function onGenerateAnalysisClicked() {
		generateJADBioAnalysis();
		setShowJADBioWarningModal(true);
		setActiveTab({ analysisId, activeTab: 0 });
	}

	function handleCloseConfig() {
		openConfigPanel({ analysisId: analysis.id, openConfig: !isConfigPanelOpen });
	}
	return (
		<>
			<ConfigContainer isFullScreen={fullscreen} areFiltersOpen={areFiltersOpen}>
				{activeColumns === Columns.OneColumn &&
					!fullscreen &&
					isMaxWidthLg &&
					isConfigPanelOpen && (
						<OptionsHeaderContainer>
							<Typography.H6>
								{translate(({ analysis }) => analysis.analyses.name)}
							</Typography.H6>
							<Icon
								variant={v => v.button}
								svg={Svgs.Close}
								size={s => s.m}
								marginOffset={{ top: 0.6 }}
								onClick={handleCloseConfig}
							/>
						</OptionsHeaderContainer>
					)}

				{/* PARAMETERS */}
				<CollapsibleCard
					title={translate(
						({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
					)}
					open={isParamsOpen}
					onToggle={() =>
						openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
					}
				>
					<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.JADBio.config.JADBioModel
							)}
							placeholder={translate(
								({ analysis }) => analysis.analyses.JADBio.config.chooseJADBioModel
							)}
							items={JADBioAnalysisModelsSelectItems}
							value={JADBioAnalysisModelsSelectItems.find(
								item => item.value === draftModel
							)}
							onValueSelected={model =>
								model && setDraftModel(model as JADBioAnalysisModels)
							}
							canClear={false}
							disabled={loading}
						/>

						{/* CLASSIFICATION MODEL */}
						{isClassificationModel && (
							<CreatableSelect
								label={translate(
									({ analysis }) =>
										analysis.analyses.JADBio.config.classificationVariable
								)}
								placeholder={translate(
									({ analysis }) =>
										analysis.analyses.JADBio.config.chooseJADBioVariable
								)}
								items={categorySelectItems}
								value={selectItemsMap[values.classificationVariable]}
								onValueSelected={categoryVariable =>
									categoryVariable &&
									setValues(values => ({
										...values,
										classificationVariable: categoryVariable
									}))
								}
								canClear={false}
								disabled={loading}
							/>
						)}

						{/* SURVIVAL MODEL */}
						{isSurvivalModel && (
							<>
								<CreatableSelect
									label={translate(
										({ analysis }) =>
											analysis.analyses.JADBio.config.eventVariable
									)}
									placeholder={translate(
										({ analysis }) =>
											analysis.analyses.JADBio.config.chooseJADBioVariable
									)}
									items={categorySelectItems}
									value={selectItemsMap[values.eventVariableName]}
									onValueSelected={categoryVariable =>
										categoryVariable &&
										setValues(values => ({
											...values,
											eventVariableName: categoryVariable
										}))
									}
									canClear={false}
									disabled={loading}
								/>

								<Spacer size={s => s.s} />

								<CreatableSelect
									label={translate(
										({ analysis }) =>
											analysis.analyses.JADBio.config.timeToEventVariable
									)}
									placeholder={translate(
										({ analysis }) =>
											analysis.analyses.JADBio.config.chooseJADBioVariable
									)}
									items={numericSelectItems}
									value={selectItemsMap[values.timeToEventVariableName]}
									onValueSelected={numericVariable =>
										numericVariable &&
										setValues(values => ({
											...values,
											timeToEventVariableName: numericVariable
										}))
									}
									canClear={false}
									disabled={loading}
								/>
							</>
						)}

						{/* REGRESSION MODEL */}
						{isRegressionModel && (
							<CreatableSelect
								label={translate(
									({ analysis }) =>
										analysis.analyses.JADBio.config.regressionVariable
								)}
								placeholder={translate(
									({ analysis }) =>
										analysis.analyses.JADBio.config.chooseJADBioVariable
								)}
								items={numericSelectItems}
								value={selectItemsMap[values.regressionVariable]}
								onValueSelected={numericVariable =>
									numericVariable &&
									setValues(values => ({
										...values,
										regressionVariable: numericVariable
									}))
								}
								canClear={false}
								disabled={loading}
							/>
						)}

						<Button
							title={
								canAbortGeneration
									? translate(dict => dict.buttons.abort)
									: translate(dict => dict.buttons.generate)
							}
							disabled={!canGenerateAnalysis && !canAbortGeneration}
							onClick={
								canAbortGeneration ? abortGeneration : onGenerateAnalysisClicked
							}
							loading={abortJADBioGenerationLoading}
							hasFullWidth
						/>
					</Gap>
				</CollapsibleCard>

				{analysisFetched && activeTab !== 0 && (
					<CollapsibleCard
						title={translate(
							({ analysis }) =>
								analysis.analyses.groupedOptions.title.jadBioPerformance
						)}
						open={!!isJadBioPerformanceOpen}
						onToggle={() =>
							openJadBioPerformance({
								analysisId: analysis.id,
								jadBioPerformance: !isJadBioPerformanceOpen
							})
						}
					>
						<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
							{activeTab === 1 && <JADBioConfigReduction analysis={analysis} />}
							{activeTab === 2 && <JADBioConfigProbabilities analysis={analysis} />}
							{activeTab === 3 && (
								<JADBioConfigFeatureImportance analysis={analysis} />
							)}
						</Gap>
					</CollapsibleCard>
				)}
			</ConfigContainer>

			{showJADBioWarningModal && (
				<JADBioWarningModal onClose={() => setShowJADBioWarningModal(false)} />
			)}
		</>
	);
}

import { CSSProperties, Fragment } from 'react';

/**
 * Dynamically replace placeholders in a string with the provided data.
 * @param template The string to replace placeholders in.
 * @param data The data to replace the placeholders with.
 * @param style Optional style to apply to the replaced data.
 * @returns The string with the placeholders replaced with the provided data.
 */
export function dynamicTranslate(template: string, data: string[], style?: CSSProperties) {
	if (!template || template.length === 0 || !data || data.length === 0) {
		return null;
	}

	if (!style) {
		return template.replace(/{(\d+)}/g, function (match, number) {
			return typeof data[number] != 'undefined' ? data[number] : match;
		});
	}

	const parts = template.split(/{\d+}/);
	const indices = template.match(/{(\d+)}/g)?.map(num => parseInt(num.slice(1, -1))) || [];

	return (
		<>
			{parts.map((part, index) => (
				<Fragment key={index}>
					{part}
					{index < indices.length && data[indices[index]] !== undefined ? (
						<span style={style}>{data[indices[index]]}</span>
					) : null}
				</Fragment>
			))}
		</>
	);
}

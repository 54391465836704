import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation } from 'hooks/store';
import { Status } from 'store/data/statuses';
import { InputType } from 'types/index';

import { Container, InputWrapper } from './StatusView.style';

interface Props {
	statuses: Status[];
}

export function StatusView({}: Props) {
	const { translate } = useTranslation();

	return (
		<Container>
			<InputWrapper>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.statuses.statusEntryComponent.statusDueTime)}
					// TODO: FIX
					// value={statusData.date}
					readOnly
				/>
				<Input
					type={InputType.Text}
					label={translate(dict => dict.statuses.statusEntryComponent.statusTime)}
					// TODO: FIX
					// value={statusData.time}
					readOnly
				/>
			</InputWrapper>

			<Spacer size={s => s.s} />

			<CreatableSelect
				label={translate(dict => dict.statuses.statusEntryComponent.status)}
				placeholder={translate(dict => dict.dataset.statusView.noStatusSelected)}
				// value={statusData.value}
				items={[]}
				// items={statusesWithLabelAndValue}
				// onValueSelected={onStatusChange}
				// disabled={readOnly || disabled}
				// canClear={canClear}
			/>
		</Container>
	);
}

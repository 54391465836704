import { Transaction } from 'api/data/projects';
import { GenericApiResponseBody, RequestError } from 'api/types';
import { StorageKeys } from 'types/index';

export function stringArrayToNumberArray(arr: string[]) {
	const result: number[] = [];
	arr.forEach(val => result.push(Number(val)));

	return result;
}

export function stringToMaxChars(text: string, maxChars = 15) {
	if (text.length > maxChars) return text.substr(0, maxChars) + '...';

	return text;
}

export function buildErrorMessage({ errors, message, ledidiStatusText }: GenericApiResponseBody) {
	let customError = '';

	if (errors) {
		errors.forEach(({ message }) => {
			customError += `\n${message}\n`;
		});

		if (customError) throw new Error(customError);
	} else {
		if (message) throw new Error(message);
		else throw new Error(ledidiStatusText);
	}
}

export function createInitTransaction(): Transaction {
	return {
		isFinished: false,
		isSuccessful: false,
		isTimedOut: false,
		progressPercentage: 0
	};
}

export function isServerErrorCode(data: RequestError, code: number) {
	return (
		data.errorCode === code ||
		data.errorCode === code.toString() ||
		data.statusCode === code ||
		data.statusCode === code.toString() ||
		data.httpStatusCode === code
	);
}

export async function asyncForEach<T, P>(
	array: T[],
	callback: (item: T, index: number, array: T[]) => Promise<P>
) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

export function handleIsAdminFlag(idToken: any) {
	const isAdmin = idToken.payload['custom:role'] === 'coreprjctssuperadmin';

	if (isAdmin) {
		localStorage.setItem(StorageKeys.IsUserAdmin, 'true');
	} else {
		localStorage.removeItem(StorageKeys.IsUserAdmin);
	}
}

export function handleIsDataSetSuperAdminFlag(idToken: any) {
	const isDatasetSuperAdmin = idToken.payload['custom:datasetRole'] === 'datasetSuperAdmin';
	if (isDatasetSuperAdmin) {
		localStorage.setItem(StorageKeys.isDatasetSuperAdmin, 'true');
	} else {
		localStorage.removeItem(StorageKeys.isDatasetSuperAdmin);
	}
}

export function isEmail(email: string) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
	return re.test(email);
}

export function absWithNegative(value: number): number {
	// return Math.sign(value) * Math.round(Math.abs(value));
	return Math.sign(value) * Math.floor(Math.abs(value));
}

import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

import { Input as DefaultInput } from 'components/UI/Inputs/Input';
import { Colors, Shadows, MediaQueries } from 'environment';
import { SpacingOffsets } from 'types/index';
import { Typography } from 'components/UI/Typography';
import { generateSpacingOffsets } from 'helpers/cssGenerators';
import { showOnTouchDevices } from 'helpers/generic';
import { IconSizes } from 'components/UI/Icons';

import { PositionProps, ARROW_SIZE } from './InfoTooltip';

interface ContainerProps {
	inline: boolean;
}
interface TokenTipProps {
	tokenCode?: boolean;
	iconVisible?: boolean;
}

interface SizeProps {
	size: IconSizes;
}

export const Container = styled.div<ContainerProps & SpacingOffsets & SizeProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ size }) => `${size}rem`};
	height: ${({ size }) => `${size}rem`};

	${({ marginOffset, paddingOffset }) =>
		css`
			${generateSpacingOffsets({ paddingOffset, marginOffset })}
		`}

	@media print {
		display: none;
	}
`;

export const IconWrapper = styled.div.attrs(() => ({
	className: showOnTouchDevices('tooltip__icon')
}))<TokenTipProps>`
	position: absolute;
	align-items: center;
	justify-content: center;
	visibility: hidden;

	@media ${MediaQueries.maxWidth.sm} {
		position: relative;
		display: flex;
		flex: 1;
	}

	${({ iconVisible }) =>
		iconVisible &&
		css`
			visibility: visible;
		`}
`;

interface WrapperProps {
	zIndex: number;
	width: number;
}

const StyledTextWrapper = styled.div<WrapperProps & PositionProps & TokenTipProps>`
	transition: opacity 0.2s;
	z-index: 12;
	position: absolute;
	${({ left, bottom, top }) => css`
		left: ${left ? `${left}px` : 'unset'};
		bottom: ${bottom ? `${bottom}px` : 'unset'};
		top: ${top ? `${top}px` : 'unset'};
	`}

	${({ zIndex }) =>
		zIndex &&
		css`
			z-index: ${zIndex};
		`};
`;
export const TextWrapper = animated(StyledTextWrapper);

interface ColorThemeProps {
	colorTheme?: 'light' | 'dark';
}

type TooltipProps = TokenTipProps &
	ColorThemeProps &
	SizeProps & {
		arrowXOffset: PositionProps['arrowXOffset'];
		arrowPosition?: PositionProps['arrowPosition'];
		maxWidth?: number;
	};

export const Tooltip = styled.div<TooltipProps>`
	width: max-content;
	max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.4rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.normal};

	${({ colorTheme }) => {
		const isLightMode = colorTheme === 'light';

		return isLightMode
			? css`
					background-color: ${Colors.white};
			  `
			: css`
					background-color: ${Colors.text.main};

					svg {
						color: ${Colors.white};
					}

					div:nth-child(2) {
						&:hover {
							svg {
								color: ${Colors.text.main};
							}
						}
					}
			  `;
	}}

	&::after {
		content: '';
		position: absolute;
		bottom: ${({ arrowPosition = 'top' }) => (arrowPosition === 'top' ? '-2rem' : 'unset')};
		top: ${({ arrowPosition = 'top' }) => (arrowPosition === 'bottom' ? '-2rem' : 'unset')};
		//WHY
		/* margin-left: -1rem; */
		border-width: 1rem;
		border-style: solid;
		border-color: ${({ colorTheme, arrowPosition = 'top' }) =>
			arrowPosition === 'top'
				? `${
						colorTheme === 'light' ? Colors.white : Colors.text.main
				  } transparent transparent transparent`
				: `transparent transparent ${
						colorTheme === 'light' ? Colors.white : Colors.text.main
				  } transparent`};
		left: ${({ arrowXOffset, size }) =>
			`calc(50% - ${ARROW_SIZE}px + ${size}rem - ${arrowXOffset ? arrowXOffset : 0}px)`};
	}

	p {
		overflow-y: auto;
		padding: 0.8rem;
		word-break: break-word;
	}
`;

export const Text = styled(Typography.Paragraph)<ColorThemeProps>`
	white-space: pre-line;

	color: ${({ colorTheme }) => (colorTheme === 'light' ? Colors.text.main : Colors.white)};
`;

export const TokenText = styled(DefaultInput)<ColorThemeProps>`
	input {
		border: none;
		outline: none;
		background-color: ${({ colorTheme }) =>
			colorTheme === 'dark' ? Colors.text.main : Colors.transparent};
		${({ colorTheme }) =>
			colorTheme === 'dark' &&
			css`
				color: ${Colors.white};
			`}
	}
`;

import styled, { css } from 'styled-components';

import { MediaQueries } from 'environment';
import { SpacingOffsets } from 'types/index';
import { generateSpacingOffsets } from 'helpers/cssGenerators';

export const Row = styled.div<SpacingOffsets>`
	${({ paddingOffset, marginOffset }) => css`
		display: flex;
		width: 100%;

		/* SPACING OFFSETS */
		${generateSpacingOffsets({ paddingOffset, marginOffset })}

		@media ${MediaQueries.maxWidth.sm} {
			flex-direction: column;
		}
	`}
`;

export const HSpacer = styled.div`
	margin-right: 2.4rem;

	@media ${MediaQueries.maxWidth.sm} {
		margin-right: 0;
		height: 0;
	}
`;

export const RowElement = styled.div`
	flex: 1;
`;

import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Table } from 'components/UI/Table';
import type { CollaboratorsDataArray } from 'store/data/collaborators';
import { TableSort } from 'store/ui/tables/types';
import type { BooleanMap, RequireOnlyOne } from 'types/index';

import type { CollaboratorsTableCheckedState } from 'pages/Collaborators/CollaboratorsPage.controller';

import { CollaboratorRow } from './CollaboratorRow';
import { OrganizationRow } from './OrganizationRow';

import { CenteredCheckbox } from './styles';
import { useDefaultOrganizationId, useTranslation } from 'hooks/store';
import { collaboratorsDataArrayJSXIterator } from 'helpers/collaborators';
import { FilterCollaborators } from 'pages/Collaborators/CollaboratorsPage';
import { useWindowSize } from 'hooks/ui';

interface Props {
	filter: FilterCollaborators;
	collaboratorsDataArray: CollaboratorsDataArray;
	columnsData: {
		sort: {
			handleSort: (columnName: string) => TableSort;
			sortIcon: (columnName: string) => JSX.Element | undefined;
		};
	};
	expandedRowsData: {
		expandedRows: BooleanMap;
		toggleExpandedRow: (rowName: string) => void;
	};
	checkedRowsData: {
		checkedMap: {
			collaborators: BooleanMap;
			organizations: BooleanMap;
		};
		checkedState: CollaboratorsTableCheckedState;
		toggleChecked: (
			input: RequireOnlyOne<{
				collaboratorId?: string | undefined;
				organizationId?: string | undefined;
			}>
		) => void;
		toggleAllChecked: () => void;
	};
	collaboratorRowData: {
		isSelf: (collaboratorId: string) => boolean;
		isProjectOwner: (collaboratorId: string) => boolean;
		getRoleName: (projectRoleId?: string) => string;
	};
	canSelectRows: boolean;
	onRemoveOrganization: (orgId: string) => void;
	onRemoveCollaboratorFromOrganization: ({
		organizationId,
		collaboratorIds
	}: {
		organizationId: string;
		collaboratorIds: string[];
	}) => void;
	onRemoveCollaborator: (userId: string) => void;
	onCollaboratorClick: (collaboratorId: string) => void;
	onOrganizationClick: (organizationId: string) => void;
}

export function CollaboratorsTable({
	filter,
	collaboratorsDataArray,
	columnsData: { sort },
	expandedRowsData: { expandedRows, toggleExpandedRow },
	checkedRowsData: { checkedMap, checkedState, toggleChecked, toggleAllChecked },
	canSelectRows,
	collaboratorRowData: { isSelf, isProjectOwner },
	onRemoveOrganization,
	onRemoveCollaboratorFromOrganization,
	onRemoveCollaborator,
	onCollaboratorClick,
	onOrganizationClick
}: Props) {
	const { translate } = useTranslation();
	const [tableWidth, setTableWidth] = useState(0);
	const { width: windowWidth } = useWindowSize();
	const tableRef = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		if (!tableRef?.current) return;
		const width = tableRef.current.offsetWidth;
		width !== tableWidth && setTableWidth(tableRef.current.offsetWidth);
	}, [windowWidth]);

	const anyCollaboratorHasSubOrg = useMemo(() => {
		return collaboratorsDataArray.some(collaboratorData => {
			if ('subOrganization' in collaboratorData && collaboratorData.subOrganization) {
				return true;
			}

			if ('collaborators' in collaboratorData) {
				return collaboratorData.collaborators.some(
					collaborator => collaborator.subOrganization
				);
			}

			return false;
		});
	}, [collaboratorsDataArray]);

	const tableColumns = useMemo(() => {
		return {
			name: {
				label: translate(dict => dict.collaborators.collaboratorsTable.name),
				value: 'name'
			},
			...(filter === FilterCollaborators.Groups && {
				collaborators: {
					label: translate(dict => dict.collaborators.collaboratorsTable.collaborators),
					value: 'collaborators'
				}
			}),
			emailAddress: {
				label: translate(dict => dict.collaborators.collaboratorsTable.emailAddress),
				value: 'email'
			},
			organization: {
				label: translate(dict => dict.collaborators.collaboratorsTable.organization),
				value: 'organization'
			},
			subOrganization: {
				label: translate(dict => dict.collaborators.collaboratorsTable.subOrganization),
				value: 'subOrganization'
			},
			workplace: {
				label: translate(dict => dict.collaborators.collaboratorsTable.workplace),
				value: 'workplace'
			},
			...(filter === FilterCollaborators.Collaborators && {
				role: {
					label: translate(dict => dict.collaborators.collaboratorsTable.role),
					value: 'userRole'
				}
			}),
			...(filter === FilterCollaborators.Collaborators && {
				groups: {
					label: translate(dict => dict.collaborators.collaboratorsTable.groups),
					value: 'groups'
				}
			})
		};
	}, [filter]);

	const defaultOrgId = useDefaultOrganizationId();

	return (
		<Table.Responsive _ref={tableRef}>
			<Table fullWidth hoverEffect>
				<Table.Head>
					<Table.Row>
						{canSelectRows && (
							<Table.Column
								width={5.6}
								minWidth={5.6}
								maxWidth={5.6}
								css={`
									cursor: pointer;
									/*
									RESETS THE COLUMN PADDING AND FLEX DIRECTION
									TO ALIGN CHECKBOX CORRECTLY
								*/
									padding-left: 0.8rem !important;
									padding-right: 0.8rem !important;
								`}
								onClick={toggleAllChecked}
							>
								<CenteredCheckbox
									checked={checkedState.all}
									partial={checkedState.partial.main}
									onClick={toggleAllChecked}
								/>
							</Table.Column>
						)}
						<Table.Column
							minWidth={14}
							maxWidth={65}
							width={65}
							onClick={() => sort.handleSort(tableColumns.name.value)}
							clickable
						>
							{sort.sortIcon(tableColumns.name.value)}
							{tableColumns.name.label}
						</Table.Column>
						{filter === FilterCollaborators.Groups && tableColumns.collaborators && (
							<Table.Column
								clickable
								onClick={() => sort.handleSort(tableColumns.collaborators!.value)}
								minWidth={20}
							>
								{sort.sortIcon(tableColumns.collaborators?.value)}
								{tableColumns.collaborators?.label}
							</Table.Column>
						)}

						<Table.Column
							minWidth={14}
							maxWidth={40}
							width={40}
							onClick={() => sort.handleSort(tableColumns.emailAddress.value)}
							clickable
						>
							{sort.sortIcon(tableColumns.emailAddress.value)}
							{tableColumns.emailAddress.label}
						</Table.Column>

						<Table.Column
							onClick={() => sort.handleSort(tableColumns.organization.value)}
							clickable
							minWidth={20}
						>
							{sort.sortIcon(tableColumns.organization.value)}
							{tableColumns.organization.label}
						</Table.Column>

						{anyCollaboratorHasSubOrg && (
							<Table.Column
								onClick={() => sort.handleSort(tableColumns.subOrganization.value)}
								clickable
								minWidth={14}
								dataTestid="sub-organization-column"
							>
								{sort.sortIcon(tableColumns.subOrganization.value)}
								{tableColumns.subOrganization.label}
							</Table.Column>
						)}

						<Table.Column
							onClick={() => sort.handleSort(tableColumns.subOrganization.value)}
							clickable
							minWidth={14}
						>
							{sort.sortIcon(tableColumns.workplace.value)}
							{tableColumns.workplace.label}
						</Table.Column>

						{filter === FilterCollaborators.Collaborators && tableColumns.role && (
							<Table.Column
								onClick={() => sort.handleSort(tableColumns.role!.value)}
								minWidth={20}
								clickable
							>
								{sort.sortIcon(tableColumns.role?.value)}
								{tableColumns.role?.label}
							</Table.Column>
						)}
						{filter === FilterCollaborators.Collaborators && tableColumns.groups && (
							<Table.Column
								onClick={() => sort.handleSort(tableColumns.groups!.value)}
								minWidth={20}
								clickable
							>
								{sort.sortIcon(tableColumns.groups?.value)}
								{tableColumns.groups?.label}
							</Table.Column>
						)}
					</Table.Row>
				</Table.Head>

				<Table.Body>
					{collaboratorsDataArrayJSXIterator(
						collaboratorsDataArray,
						// COLLABORATORS
						collaborator => (
							<CollaboratorRow
								tableWidth={tableWidth}
								onRemove={() => onRemoveCollaborator(collaborator.userId)}
								key={`collaborator_${collaborator.userId}`}
								collaborator={collaborator}
								checkedData={{
									checked: checkedMap.collaborators[collaborator.userId],
									onCheck: () =>
										toggleChecked({
											collaboratorId: collaborator.userId
										})
								}}
								showSubOrganization={anyCollaboratorHasSubOrg}
								canSelectRow={canSelectRows}
								isSelf={isSelf(collaborator.userId)}
								isOwner={isProjectOwner(collaborator.userId)}
								onClick={() => onCollaboratorClick(collaborator.userId)}
							/>
						),
						// ORGANIZATIONS
						organizationData => (
							<OrganizationRow
								tableWidth={tableWidth}
								onRemove={() => onRemoveOrganization(organizationData.id)}
								key={`organization_${organizationData.id}`}
								organizationData={organizationData}
								expandedRowsData={{
									expanded: expandedRows[organizationData.id] ?? true,
									onExpand: () => toggleExpandedRow(organizationData.id)
								}}
								checkedRowData={{
									checked: checkedMap.organizations[organizationData.id],
									partial:
										checkedState.partial.organizations[organizationData.id],
									onCheck: () =>
										toggleChecked({
											organizationId: organizationData.id
										})
								}}
								isDefault={defaultOrgId === organizationData.id}
								canSelectRow={canSelectRows}
								onClick={() => onOrganizationClick(organizationData.id)}
								showSubOrganization={anyCollaboratorHasSubOrg}
								renderCollaboratorRow={collaborator => (
									<CollaboratorRow
										tableWidth={tableWidth}
										onRemove={() =>
											onRemoveCollaboratorFromOrganization({
												organizationId: organizationData.id,
												collaboratorIds: [collaborator.userId]
											})
										}
										key={`organization_${organizationData.id}-collaborator_${collaborator.userId}`}
										collaborator={collaborator}
										checkedData={{
											checked: checkedMap.collaborators[collaborator.userId],
											onCheck: () =>
												toggleChecked({
													collaboratorId: collaborator.userId
												})
										}}
										showSubOrganization={anyCollaboratorHasSubOrg}
										canSelectRow={canSelectRows}
										isSelf={isSelf(collaborator.userId)}
										isOwner={isProjectOwner(collaborator.userId)}
										onClick={() => onCollaboratorClick(collaborator.userId)}
										isNested
									/>
								)}
							/>
						)
					)}
				</Table.Body>
			</Table>
		</Table.Responsive>
	);
}

import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch, useSelector } from 'hooks/utils';
import {
	ActionTypes,
	selectListOfProjectsSharedWIth,
	getTemplateListOfSharedWith,
	selectListOfUsersSharedWIth,
	UserListWithChanges,
	ProjectListWithChanges
} from 'store/data/templates';

interface DataType {
	usersSharedWith: UserListWithChanges;
	projectsSharedWith: ProjectListWithChanges;
}

export function useTemplateSharedWith(): OperationResult<DataType> {
	const dispatch = useDispatch();

	const usersSharedWith = useSelector(state => selectListOfUsersSharedWIth(state.data.templates));
	const projectsSharedWith = useSelector(state =>
		selectListOfProjectsSharedWIth(state.data.templates)
	);

	const [{ loading, error }] = useActivity(ActionTypes.GET_TEMPLATE_LIST_OF_SHARED_WITH);

	function handler() {
		dispatch(getTemplateListOfSharedWith());
	}

	return [
		{
			loading,
			error,
			data: {
				usersSharedWith: usersSharedWith ?? {
					initial: [],
					current: []
				},
				projectsSharedWith: projectsSharedWith ?? {
					initial: [],
					current: []
				}
			}
		},
		handler
	];
}

import { ToggleChartPlotAction, toggleChartPlot, selectChartState } from 'store/data/analyses';
import { useDispatch, useSelector } from 'hooks/utils';
import { ActionPayload } from 'store/types';
import { LocalOperationResult } from 'hooks/store/types';

export function useToggleAnalysisChartPlot(
	id: string
): LocalOperationResult<
	{ [plotIndex: string]: boolean },
	(payload: ActionPayload<ToggleChartPlotAction>) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectChartState(state.data.analyses, id));

	function handler(payload: ActionPayload<ToggleChartPlotAction>) {
		dispatch(toggleChartPlot(payload));
	}

	return [data, handler];
}

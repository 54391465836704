import type {
	CalculationActions,
	JsonLogicMap,
	Variable,
	VariableAliasesActions,
	VariableAliasesMap
} from 'api/data/variables/types';
import {
	AddButton,
	AddRuleWrapper,
	Container,
	InnerContainer,
	RuleContainer,
	Rules,
	Title
} from './CalculatedVariable.style';
import { useTranslation } from 'hooks/store';
import { Dropdown } from 'components/UI/Dropdown';
import { ComparisonOperation } from './rules/ComparisonOperation';
import { ArithmeticNode, AdditionNode, SubtractionNode } from 'api/data/variables/types';
import { AndOperation } from './rules/AndOperation';
import { ArithmeticOperation } from './rules/ArithmeticOperation';
import { DateOperation } from './rules/DateOperation';
import { useState, useLayoutEffect } from 'react';
import { CalculationDeleteIcon } from './DeleteIcon/CalculationDeleteIcon';
import { DurationOperation } from './rules/DurationOperation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NumericCalculatedVariable } from '../NumericCalculatedVariable/NumericCalculatedVariable';
import { usePrevious } from 'hooks/utils/index';
import {
	ArithmeticOperator,
	ComparisonOperator,
	RuleType,
	VariableType
} from 'types/data/variables/constants';
import { HoverContext } from 'helpers/variables/calculatedVariables/HoverContext';

enum AdditionalArithmeticOperation {
	Power = '**'
}

type CalculatedRenderProps = {
	shouldRenderNumericEditor?: boolean;
	setShouldRenderNumericEditor?: (shouldRender: boolean) => void;
};

interface Props {
	jsonLogicMap: JsonLogicMap;
	draftVariable: Variable;
	variables: Variable[];
	calculationActions: CalculationActions;
	showNumericInfoMessage?: boolean;
	setShowNumericInfoMessage?: (show: boolean) => void;
	variableAliases: VariableAliasesMap;
	variableAliasesActions: VariableAliasesActions;
	hideFormula?: boolean;
	renderProps?: CalculatedRenderProps;
}

export function CalculatedVariable({
	jsonLogicMap,
	draftVariable,
	variables,
	calculationActions,
	variableAliases,
	variableAliasesActions,
	renderProps: { shouldRenderNumericEditor, setShouldRenderNumericEditor } = {},
	showNumericInfoMessage,
	setShowNumericInfoMessage
}: Props) {
	const { onAddJsonLogic: onAddRule, onValidEditorRuleWithVariableMap: onValidParsedRule } =
		calculationActions;

	const { translate } = useTranslation();
	const { numericCalculatedVariableFormula } = useFlags();

	const [isDeleteHovered, setIsDeleteHovered] = useState(false);
	const [deleteIconIndex, setDeleteIconIndex] = useState('');
	const [hoverPath, setHoverPath] = useState('');

	const isDateVariable = [VariableType.Date, VariableType.DateTime].includes(draftVariable.type);
	const isNumberVariable = [VariableType.Float, VariableType.Integer].includes(
		draftVariable.type
	);
	const isDurationVariable = draftVariable.type === VariableType.TimeDuration;
	const isCategoryVariable = draftVariable.type === VariableType.Category;

	const hasNoRules = jsonLogicMap.order.length == 0;
	const hasOneRule = jsonLogicMap.order.length == 1;

	const hasMultipleRules = jsonLogicMap.order.length > 1;

	const firstRule = jsonLogicMap.logics[jsonLogicMap.order[0]];

	const isFirstRuleNumericOperation =
		hasOneRule &&
		(ArithmeticOperator.Addition in firstRule ||
			ArithmeticOperator.Subtraction in firstRule ||
			ArithmeticOperator.Multiplication in firstRule ||
			ArithmeticOperator.Division in firstRule ||
			AdditionalArithmeticOperation.Power in firstRule);

	const isFirstRuleDateOperation =
		hasOneRule &&
		(ArithmeticOperator.Addition in firstRule || ArithmeticOperator.Subtraction in firstRule) &&
		Object.values(firstRule)[0].length == 3;
	const isFirstRuleTimeDurationOperation =
		hasOneRule && isDurationVariable && isFirstRuleNumericOperation;

	const hasConditionRule = (hasOneRule || hasMultipleRules) && 'if' in firstRule;

	const showAddRuleButton =
		(isDateVariable && jsonLogicMap.order.length < 1) ||
		(isNumberVariable && jsonLogicMap.order.length == 0) ||
		(isDurationVariable && jsonLogicMap.order.length == 0) ||
		(isNumberVariable && !isFirstRuleNumericOperation) ||
		isCategoryVariable;

	const numericCheck =
		isNumberVariable && isFirstRuleNumericOperation && !isFirstRuleDateOperation;
	const previouslyRenderedRenderNumericEditor = usePrevious(numericCheck);

	const renderNumericEditor =
		(numericCheck || shouldRenderNumericEditor || previouslyRenderedRenderNumericEditor) &&
		numericCalculatedVariableFormula;

	const renderBlockViewRule =
		isFirstRuleDateOperation ||
		isFirstRuleTimeDurationOperation ||
		hasConditionRule ||
		(numericCheck && !numericCalculatedVariableFormula);

	function addNewRow(ruleType: RuleType) {
		setShouldRenderNumericEditor &&
			ruleType === RuleType.ArithmeticOperation &&
			setShouldRenderNumericEditor(true);
		onAddRule(ruleType);
	}

	function getRules(jsonLogicMap: JsonLogicMap) {
		return jsonLogicMap.order.map(logicId => {
			const jsonLogic = jsonLogicMap.logics[logicId];

			const isIfCondition = 'if' in jsonLogic;

			const isArithmeticNode =
				(ArithmeticOperator.Addition in jsonLogic &&
					jsonLogic[ArithmeticOperator.Addition].length == 2) ||
				(ArithmeticOperator.Subtraction in jsonLogic &&
					jsonLogic[ArithmeticOperator.Subtraction].length == 2) ||
				ArithmeticOperator.Multiplication in jsonLogic ||
				ArithmeticOperator.Division in jsonLogic;

			const isSubtractionOpWithSubtype =
				'-' in jsonLogic &&
				jsonLogic['-'].length == 3 &&
				typeof jsonLogic['-'][jsonLogic['-'].length - 1] === 'string';
			const isAdditionOpWithSubtype =
				'+' in jsonLogic &&
				jsonLogic['+'].length == 3 &&
				typeof jsonLogic['+'][jsonLogic['+'].length - 1] === 'string';

			const isArithmeticOp = !isDurationVariable && isArithmeticNode;
			const isDurationOperation = isDurationVariable && isArithmeticNode;

			const isDateSubtraction = isSubtractionOpWithSubtype && !isDurationVariable;
			const isDateAddition = isAdditionOpWithSubtype && !isDurationVariable;

			const isDateOperation = isDateSubtraction || isDateAddition;

			if (
				isIfCondition &&
				jsonLogic.if[0] &&
				(ComparisonOperator.LessThan in jsonLogic.if[0] ||
					ComparisonOperator.LessThanOrEqual in jsonLogic.if[0] ||
					ComparisonOperator.GreaterThan in jsonLogic.if[0] ||
					ComparisonOperator.GreaterThanOrEqual in jsonLogic.if[0] ||
					ComparisonOperator.Equals in jsonLogic.if[0])
			) {
				return (
					<RuleContainer
						key={`${logicId}-comparison-container`}
						isDeleteHovered={isDeleteHovered && deleteIconIndex === logicId}
						id={`comparison_container_${logicId}`}
					>
						<ComparisonOperation
							key={`${logicId}-comparison`}
							node={jsonLogic.if[0]}
							nodePath={`${logicId}.if.0`}
							conclusionValue={jsonLogic.if[1]}
							conclusionPath={`${logicId}.if.1`}
							draftVariableType={draftVariable.type}
							variables={variables}
							categories={draftVariable.categories}
							calculationActions={calculationActions}
							isLastOperation
						/>
						<CalculationDeleteIcon
							calculationActions={calculationActions}
							depth={0}
							isDeleteHovered={isDeleteHovered}
							setIsDeleteHovered={setIsDeleteHovered}
							nodePath={`${logicId}.if.0`}
							jsonLogicId={logicId}
							setDeleteIconIndex={setDeleteIconIndex}
						/>
					</RuleContainer>
				);
			}

			if (isIfCondition && jsonLogic.if[0] && 'and' in jsonLogic.if[0]) {
				return (
					<RuleContainer
						key={`${logicId}-and-container`}
						isDeleteHovered={isDeleteHovered && deleteIconIndex === logicId}
						id={`and_container_${logicId}`}
					>
						<AndOperation
							key={`${logicId}-and`}
							node={jsonLogic.if[0]}
							nodePath={`${logicId}.if.0`}
							draftVariable={draftVariable}
							variables={variables}
							categories={draftVariable.categories}
							conclusionValue={jsonLogic.if[1]}
							conclusionPath={`${logicId}.if.1`}
							calculationActions={calculationActions}
						/>
						<CalculationDeleteIcon
							calculationActions={calculationActions}
							depth={0}
							isDeleteHovered={isDeleteHovered}
							setIsDeleteHovered={setIsDeleteHovered}
							nodePath={`${logicId}.if.0`}
							jsonLogicId={logicId}
							setDeleteIconIndex={setDeleteIconIndex}
						/>
					</RuleContainer>
				);
			}

			if (isArithmeticOp) {
				return (
					<ArithmeticOperation
						key={`${logicId}-arithmetic`}
						node={jsonLogic as ArithmeticNode}
						nodePath={`${logicId}`}
						depth={0}
						variables={variables}
						draftVariableType={draftVariable.type}
						calculationActions={calculationActions}
					/>
				);
			}

			if (isDateOperation) {
				return (
					<DateOperation
						key={`${logicId}-date`}
						node={jsonLogic as AdditionNode | SubtractionNode}
						nodePath={isSubtractionOpWithSubtype ? `${logicId}.-` : `${logicId}.+`}
						variables={variables}
						draftVariableType={draftVariable.type}
						calculationActions={calculationActions}
					/>
				);
			}

			if (isDurationOperation) {
				return (
					<DurationOperation
						key={`${logicId}-duration`}
						node={jsonLogic as AdditionNode | SubtractionNode}
						nodePath={`${logicId}`}
						variables={variables}
						draftVariableType={draftVariable.type}
						calculationActions={calculationActions}
					/>
				);
			}
		});
	}

	const blockViewTitle = (
		<Title id="calculated_variable_title">
			{translate(dict => dict.addVariable.compositeVariable.title)}
		</Title>
	);

	const emptyState = (
		<Container>
			{blockViewTitle}
			<InnerContainer id="calculated_variable_wrapper">
				<AddRuleWrapper>
					{showAddRuleButton && (
						<Dropdown
							button
							width={5}
							shouldScrollIntoView
							toggleComponent={({ ref, toggle }) => (
								<AddButton
									id="add_new_rule"
									ref={ref}
									onClick={toggle}
									isOrange={true}
								>
									{translate(
										dict => dict.variablesPage.compositeVariable.newRule
									)}
								</AddButton>
							)}
						>
							{![
								VariableType.Date,
								VariableType.DateTime,
								VariableType.TimeDuration
							].includes(draftVariable.type) && (
								<Dropdown.Item
									title={translate(dict => dict.terms.condition)}
									onClick={() => addNewRow(RuleType.LogicalOperation)}
									dataTestId="addNewRule_logical_operation"
									disabled={
										draftVariable.type === VariableType.Category ||
										draftVariable.type === VariableType.Float
											? !variables.some(
													v =>
														v.type === VariableType.Integer ||
														v.type === VariableType.Float
											  )
											: !variables.some(v => v.type === VariableType.Integer)
									}
								/>
							)}

							{!(jsonLogicMap.order.length > 0) &&
								![
									VariableType.Date,
									VariableType.DateTime,
									VariableType.Category,
									VariableType.TimeDuration
								].includes(draftVariable.type) && (
									<Dropdown.Item
										title={translate(dict => dict.terms.operation)}
										onClick={() => addNewRow(RuleType.ArithmeticOperation)}
										dataTestId="addNewRule_arithmetic_operation"
										disabled={
											draftVariable.type === VariableType.Float
												? !variables.some(
														v =>
															v.type === VariableType.Integer ||
															v.type === VariableType.Float
												  )
												: !variables.some(
														v => v.type === VariableType.Integer
												  )
										}
									/>
								)}
							{!(jsonLogicMap.order.length > 0) &&
								![VariableType.Category, VariableType.TimeDuration].includes(
									draftVariable.type
								) && (
									<Dropdown.Item
										title={translate(
											dict =>
												dict.variablesPage.compositeVariable.dateCalculation
										)}
										onClick={() =>
											addNewRow(
												[VariableType.Integer, VariableType.Float].includes(
													draftVariable.type
												)
													? RuleType.DateSubtractionOperation
													: RuleType.DateAdditionOperation
											)
										}
										dataTestId="addNewRule_date_operation"
										disabled={
											!variables.some(
												v =>
													v.type === VariableType.Date ||
													v.type === VariableType.DateTime
											)
										}
									/>
								)}

							{!(jsonLogicMap.order.length > 0) &&
								draftVariable.type === VariableType.TimeDuration && (
									<Dropdown.Item
										title={translate(dict => dict.terms.durationOperation)}
										onClick={() => addNewRow(RuleType.DurationOperation)}
										dataTestId="addNewRule__operation"
										disabled={
											!variables.some(
												v => v.type === VariableType.TimeDuration
											)
										}
									/>
								)}
						</Dropdown>
					)}
				</AddRuleWrapper>
			</InnerContainer>
		</Container>
	);

	useLayoutEffect(() => {
		if (!shouldRenderNumericEditor) {
			if (previouslyRenderedRenderNumericEditor || numericCheck) {
				setShouldRenderNumericEditor && setShouldRenderNumericEditor(true);
			}
		}
	}, [numericCheck, shouldRenderNumericEditor, previouslyRenderedRenderNumericEditor]);

	return (
		<>
			{renderBlockViewRule ? (
				<Container>
					{!hasNoRules && blockViewTitle}
					<InnerContainer id="calculated_variable_wrapper">
						<HoverContext.Provider value={{ hoverPath, setHoverPath }}>
							<Rules>{jsonLogicMap && getRules(jsonLogicMap)}</Rules>
						</HoverContext.Provider>
						<AddRuleWrapper>
							{showAddRuleButton && (
								<Dropdown
									button
									width={5}
									shouldScrollIntoView
									toggleComponent={({ ref, toggle }) => (
										<AddButton
											id="add_new_rule"
											ref={ref}
											onClick={toggle}
											isOrange={true}
										>
											{translate(
												dict => dict.variablesPage.compositeVariable.newRule
											)}
										</AddButton>
									)}
								>
									{![
										VariableType.Date,
										VariableType.DateTime,
										VariableType.TimeDuration
									].includes(draftVariable.type) && (
										<Dropdown.Item
											title={translate(dict => dict.terms.condition)}
											onClick={() => addNewRow(RuleType.LogicalOperation)}
											dataTestId="addNewRule_logical_operation"
											disabled={
												draftVariable.type === VariableType.Category ||
												draftVariable.type === VariableType.Float
													? !variables.some(
															v =>
																v.type === VariableType.Integer ||
																v.type === VariableType.Float
													  )
													: !variables.some(
															v => v.type === VariableType.Integer
													  )
											}
										/>
									)}

									{!(jsonLogicMap.order.length > 0) &&
										![
											VariableType.Date,
											VariableType.DateTime,
											VariableType.Category,
											VariableType.TimeDuration
										].includes(draftVariable.type) && (
											<Dropdown.Item
												title={translate(dict => dict.terms.operation)}
												onClick={() =>
													addNewRow(RuleType.ArithmeticOperation)
												}
												dataTestId="addNewRule_arithmetic_operation"
												disabled={
													draftVariable.type === VariableType.Float
														? !variables.some(
																v =>
																	v.type ===
																		VariableType.Integer ||
																	v.type === VariableType.Float
														  )
														: !variables.some(
																v => v.type === VariableType.Integer
														  )
												}
											/>
										)}
									{!(jsonLogicMap.order.length > 0) &&
										![
											VariableType.Category,
											VariableType.TimeDuration
										].includes(draftVariable.type) && (
											<Dropdown.Item
												title={translate(
													dict =>
														dict.variablesPage.compositeVariable
															.dateCalculation
												)}
												onClick={() =>
													addNewRow(
														[
															VariableType.Integer,
															VariableType.Float
														].includes(draftVariable.type)
															? RuleType.DateSubtractionOperation
															: RuleType.DateAdditionOperation
													)
												}
												dataTestId="addNewRule_date_operation"
												disabled={
													!variables.some(
														v =>
															v.type === VariableType.Date ||
															v.type === VariableType.DateTime
													)
												}
											/>
										)}

									{!(jsonLogicMap.order.length > 0) &&
										draftVariable.type === VariableType.TimeDuration && (
											<Dropdown.Item
												title={translate(
													dict => dict.terms.durationOperation
												)}
												onClick={() =>
													addNewRow(RuleType.DurationOperation)
												}
												dataTestId="addNewRule__operation"
												disabled={
													!variables.some(
														v => v.type === VariableType.TimeDuration
													)
												}
											/>
										)}
								</Dropdown>
							)}
						</AddRuleWrapper>
					</InnerContainer>
				</Container>
			) : renderNumericEditor ? (
				<NumericCalculatedVariable
					showNumericInfoMessage={showNumericInfoMessage!}
					toggleNumericInfoMessage={setShowNumericInfoMessage!}
					logic={jsonLogicMap}
					variables={variables}
					variableAliases={variableAliases}
					variableAliasesActions={variableAliasesActions}
					onValidParsedRule={onValidParsedRule}
				/>
			) : (
				emptyState
			)}
		</>
	);
}

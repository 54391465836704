import { useMemo } from 'react';
import { Variable } from 'api/data/variables';
import { DraggableCard, TimesUsed } from 'components/Forms';
import { Svgs, Colors } from 'environment';
import { GroupedIconWrapper } from './DraggableFormGroupCard.style';
import { AddVariableIcon, CardLabel } from './DraggableFormVariableCard.style';
import { Asterisk } from 'components/UI/Asterisk';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { computeFormElementFromVariable } from 'helpers/forms';
import { useFormsMap, useAppendFormElement, useTranslation } from 'hooks/store';

interface Props {
	variable: Variable;
	isDragging: boolean;
	canAppend: boolean;
}

// TODO: REMOVE WHEN USING THE NEW REUSABLE COMPONENT
export function DraggableFormGroupedVariable({ variable, isDragging, canAppend }: Props) {
	const formsMap = useFormsMap();

	const appendFormElement = useAppendFormElement();

	const { label, obligatory } = variable;
	const { translate } = useTranslation();
	const element = computeFormElementFromVariable(variable);

	const timesUsed = useMemo(() => {
		let times = 0;

		Object.values(formsMap).forEach(form => {
			if (form.initial.usedVariables.includes(variable.name)) times++;
		});

		return times;
	}, [formsMap]);

	return (
		<>
			<GroupedIconWrapper isDragging={isDragging}>
				<Icon svg={Svgs.ChevronArrowCorner} size={s => s.m} paddingOffset={{ all: 0.8 }} />
			</GroupedIconWrapper>
			<DraggableCard isDraggableGrouped>
				<CardLabel title={label} isGroupedVariable>
					{label}
				</CardLabel>
				{obligatory && <Asterisk paddingLeft />}
				{canAppend && (
					<AddVariableIcon className="add-grouped-variable-icon">
						<Icon
							showTooltip={false}
							svg={Svgs.Add}
							onClick={() => appendFormElement({ element })}
							colors={{ color: Colors.primary.normal }}
						/>
						{timesUsed > 0 && (
							<TimesUsed className="times-grouped-variable-used">
								<Typography.Paragraph>{`${translate(
									dict => dict.formDesigner.usedIn
								)} ${timesUsed} ${
									timesUsed > 1
										? translate(dict => dict.projectTabs.forms).toLowerCase()
										: translate(dict => dict.formDesigner.form).toLowerCase()
								}`}</Typography.Paragraph>
							</TimesUsed>
						)}
					</AddVariableIcon>
				)}
			</DraggableCard>
		</>
	);
}

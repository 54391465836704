import { useEffect } from 'react';
import { Operation } from 'hooks/store/types';
import { ActionTypes, removeAdminUser } from 'store/account/admin';
import { useDispatch, usePrevious } from 'hooks/utils';
import { useNavigation } from 'hooks/navigation';
import { useTranslation } from 'hooks/store';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useAlerts } from 'hooks/ui';

export function useAdminRemoveUser(): Operation {
	const dispatch = useDispatch();
	const { replace, routes } = useNavigation();
	const { translate } = useTranslation();
	const { setNotification, setError } = useAlerts();

	const [{ loading, error }] = useActivity(ActionTypes.ADMIN_REMOVE_USER);

	const wasLoading = usePrevious(loading);
	useEffect(() => {
		if (wasLoading && !loading && !error) {
			setNotification({
				message: translate(({ admin }) => admin.users.notifications.removedUser)
			});
			replace(routes.admin.view);
		}
	}, [loading, error]);

	useEffect(() => {
		if (error)
			setError({
				message: translate(({ admin }) => admin.users.errors.removedUser)
			});
	}, [error]);

	function handler() {
		dispatch(removeAdminUser());
	}

	return [{ loading, error }, handler];
}

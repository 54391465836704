import { RefObject, useEffect, useMemo, useState, useCallback } from 'react';
import { useTable, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';

import { StringMap } from 'types/index';

import { Table, TableWrapper } from './CsvToTable.style';
interface Column {
	Header: string;
	accessor: string;
}

interface Props {
	columns: Column[];
	data: StringMap[];
	containerRef: RefObject<HTMLDivElement>;
}

export function CsvToTable({ columns, data, containerRef }: Props) {
	const [tableHeight, setTableHeight] = useState(0);

	useEffect(() => {
		if (containerRef.current) {
			setTableHeight(containerRef.current.clientHeight - 100);
		}
	}, [containerRef]);

	const defaultColumn = useMemo(
		() => ({
			width: 250
		}),
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow } =
		useTable(
			{
				columns,
				data,
				defaultColumn
			},
			useBlockLayout
		);

	const RenderRow = useCallback(
		({ index, style }: { index: number; style: any }) => {
			const row = rows[index];
			prepareRow(row);
			return (
				<div
					{...row.getRowProps({
						style
					})}
					className="tr"
				>
					{row.cells.map((cell: any) => {
						return (
							<div
								key={cell.getCellProps().key}
								{...cell.getCellProps()}
								className="td"
							>
								{cell.render('Cell')}
							</div>
						);
					})}
				</div>
			);
		},
		[prepareRow, rows]
	);

	return (
		<TableWrapper>
			<Table>
				<div {...getTableProps()} className="table">
					<div>
						{headerGroups.map((headerGroup: any) => (
							<div
								key={headerGroup.getHeaderGroupProps().key}
								{...headerGroup.getHeaderGroupProps()}
								className="tr"
							>
								{headerGroup.headers.map((column: any) => (
									<div
										key={column.getHeaderProps().key}
										{...column.getHeaderProps()}
										className="th"
									>
										{column.render('Header')}
									</div>
								))}
							</div>
						))}
					</div>

					<div {...getTableBodyProps()}>
						<FixedSizeList
							height={tableHeight}
							itemCount={rows.length}
							itemSize={35}
							width={totalColumnsWidth}
						>
							{RenderRow}
						</FixedSizeList>
					</div>
				</div>
			</Table>
		</TableWrapper>
	);
}

import { Asterisk } from 'components/UI/Asterisk';
import { Typography } from 'components/UI/Typography';
import { Colors } from 'environment';

interface Props {
	required?: boolean;
	disabled?: boolean;
	label?: string | React.ReactNode;
	marginBottom?: number;
	className?: string;
}

export function InputLabel({ required, disabled, label, marginBottom, className }: Props) {
	if (!label) return null;

	const color = disabled ? Colors.text.disabled : Colors.text.main;

	return (
		<Typography.Caption
			className={className}
			color={color}
			marginOffset={{ bottom: marginBottom ?? 0.4 }}
			fontweight={w => w.medium}
		>
			{label}
			{required && <Asterisk />}
		</Typography.Caption>
	);
}

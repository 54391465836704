import { useState, useEffect, useMemo } from 'react';
import { ExpandGroupsContext } from './context';
import { Group, VariableSet } from 'api/data/variables';
import { buildVariablesRichData, isGroupData } from 'helpers/variables';
import { useVariablesData, useVariables } from 'hooks/store';
import { usePrevious } from 'hooks/utils';
import { VariableSetDataArray } from 'store/data/variables';

interface ProviderProps {
	children: React.ReactNode;
}

function isGroupInSet(group: Group, sets: VariableSet[]) {
	return sets.find(set =>
		set.setOrder.find(
			groupOrSet => 'group' in groupOrSet && groupOrSet.group === group.groupName
		)
	);
}

export const ExpandGroupsProvider = ({ children }: ProviderProps) => {
	const variablesData = useVariablesData();
	const { groupsMap, variableSets } = buildVariablesRichData(variablesData);
	const [{ fetched: fetchedVariables }] = useVariables({ lazy: true });

	const initialState = useMemo(
		() =>
			Object.keys(groupsMap).reduce(
				(acc, groupKey) => ({
					...acc,
					...(groupsMap[groupKey].variablesBelongingToGroup.length > 0 &&
						!isGroupInSet(groupsMap[groupKey], variableSets) && {
							[groupKey]: true
						})
				}),
				{} as Record<string, boolean>
			),
		[groupsMap, variableSets, fetchedVariables]
	);

	const initialStateSeriesGroups = useMemo(
		() =>
			Object.keys(groupsMap).reduce(
				(acc, groupKey) => ({
					...acc,
					...(groupsMap[groupKey].variablesBelongingToGroup.length > 0 &&
						isGroupInSet(groupsMap[groupKey], variableSets) && {
							[groupKey]: true
						})
				}),
				{} as Record<string, boolean>
			),
		[groupsMap, variableSets, fetchedVariables]
	);

	const [expanded, setExpanded] = useState<Record<string, boolean>>(initialState);

	const [expandedSeriesGroups, setExpandedSeriesGroups] =
		useState<Record<string, boolean>>(initialStateSeriesGroups);

	const prevFetched = usePrevious(fetchedVariables);
	useEffect(() => {
		if (fetchedVariables && !prevFetched) {
			setExpanded(initialState);
		}
	}, [fetchedVariables]);

	function expandAllGroups() {
		setExpanded(initialState);
	}

	function expandAllSeriesGroups(seriesData: VariableSetDataArray) {
		seriesData.forEach(data => {
			if (isGroupData(data)) {
				setExpandedSeriesGroups(prevValue => ({
					...prevValue,
					[data.groupName]: true
				}));
			}
		});
	}

	function collapseAllSeriesGroups(seriesData: VariableSetDataArray) {
		seriesData.forEach(data => {
			if (isGroupData(data)) {
				setExpandedSeriesGroups(prevValue => ({
					...prevValue,
					[data.groupName]: false
				}));
			}
		});
	}

	function collapseAllGroups() {
		setExpanded(
			Object.keys(groupsMap).reduce(
				(acc, groupKey) => ({
					...acc,
					[groupKey]: false
				}),
				{} as Record<string, boolean>
			)
		);
	}

	function toggleGroup(id: string) {
		setExpanded(state => {
			return state[id] ? { ...state, [id]: false } : { ...state, [id]: true };
		});

		setExpandedSeriesGroups(state => {
			return state[id] ? { ...state, [id]: false } : { ...state, [id]: true };
		});
	}

	return (
		<ExpandGroupsContext.Provider
			value={{
				expanded,
				setExpanded,
				expandedSeriesGroups,
				setExpandedSeriesGroups,
				expandAllSeriesGroups,
				collapseAllSeriesGroups,
				expandAllGroups,
				collapseAllGroups,
				toggleGroup
			}}
		>
			{children}
		</ExpandGroupsContext.Provider>
	);
};

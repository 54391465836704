import { useSelector } from 'hooks/utils';
import { useDispatch } from 'hooks/utils';
import { useEffect } from 'react';
import {
	selectVisibleColumns,
	setVisibleColumnsAction,
	addVisibleColumnAction,
	removeVisibleColumnAction
} from 'store/ui/tables';
import { VisibleColumns } from 'store/ui/tables/types';
import { TableName } from 'types/index';

export function useVisibleColumns(tableName: TableName, defaultColumns: VisibleColumns) {
	const visibleColumns = useSelector(state => selectVisibleColumns(state.ui.tables, tableName));

	const dispatch = useDispatch();

	useEffect(() => {
		if (!visibleColumns || !visibleColumns.length) setVisibleColumns(defaultColumns);
	}, [defaultColumns]);

	function setVisibleColumns(columns: VisibleColumns) {
		dispatch(setVisibleColumnsAction({ columns, tableName }));
	}

	function toggleColumnVisibility(columnName: string) {
		if (visibleColumns) {
			if (visibleColumns.includes(columnName)) {
				dispatch(removeVisibleColumnAction({ columnName, tableName }));
			} else {
				dispatch(addVisibleColumnAction({ columnName, tableName }));
			}
		}
	}

	function isColumnVisible(columnName: string) {
		return (visibleColumns && visibleColumns.includes(columnName)) || !visibleColumns;
	}

	return { visibleColumns, toggleColumnVisibility, isColumnVisible };
}

import styled from 'styled-components';
import { BackgroundType, PersonalDataWarningProps } from './PersonalDataWarning';
import { Colors } from 'environment';

export const WarningContainer = styled.div<PersonalDataWarningProps>`
	display: flex;
	align-items: center;
	border-radius: 0.4rem;
	width: ${({ width }) => (width ? width + 'rem' : 'fit-content')};
	background-color: ${({ bgType }) =>
		bgType && bgType(BackgroundType) === BackgroundType.grey
			? Colors.background.disabled
			: Colors.white};
`;

import { useEffect, useRef } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	selectDependenciesData,
	selectAreDependenciesFetched,
	getDependencies,
	DependenciesData
} from 'store/data/dependencies';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

interface UseDependenciesOptions {
	lazy?: boolean;
}

export function useDependencies(
	options?: UseDependenciesOptions
): OperationResult<DependenciesData> {
	const dispatch = useDispatch();

	const abortController = useRef(new AbortController());

	const lazy = options?.lazy || false;

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const data = useSelector(state => selectDependenciesData(state.data.dependencies));
	const fetched = useSelector(state => selectAreDependenciesFetched(state.data.dependencies));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_DEPENDENCIES, {
		projectId
	});

	useEffect(() => {
		if (!error && !loading && !fetched && !lazy && isProjectValid) {
			handler();
		}
	}, [error, loading, fetched, isProjectValid, lazy]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getDependencies(abortController.current));
	}

	return [{ data, loading, error, fetched, abortController: abortController.current }, handler];
}

import { sendRequest, PATIENTS_URL } from 'api/utils';
import {
	Patient,
	GetDistributionPatientListResponse,
	CreatePatientResponse,
	UpdateDistributionResponse,
	DeleteDistributionResponse,
	GetDistributionResponse,
	UpdatePatientResponse,
	BaseDistributionDataInput,
	AddPatient,
	UpdateManualDistributionResponse,
	GetManualDistributionResponse,
	CreateManualDistributionResponse,
	CreateDistributionResponse
} from './types';

import { Dictionary } from 'environment';
import { PromDistributionTypes } from 'types/index';
import { DATE_TIME_TIMEZONE_FORMAT } from 'consts';
import { formatAPIDate } from 'helpers/dateFormat';

const methods = {
	getDistributionPatientList: 'getDistributionPatientList',
	addDistributionPatient: 'addDistributionPatient',
	updateDistributionPatient: 'updateDistributionPatient',
	deleteDistributionPatient: 'deleteDistributionPatient',
	getDistribution: 'getDistribution',
	updateDistribution: 'updateDistribution',
	createDistribution: 'createDistribution',
	confirmStartedFillingForm: 'confirmStartedFillingForm',
	confirmFinishedForm: 'confirmFinishedForm',
	// PROM - MANUAL DISTRIBUTION
	getManualDistribution: 'getManualDistribution',
	updateManualDistribution: 'updateManualDistribution',
	createManualDistribution: 'createManualDistribution'
};

export default () => ({
	async getDistributionPatientList(projectId: number): Promise<Patient[]> {
		const { data }: GetDistributionPatientListResponse = await sendRequest(PATIENTS_URL, {
			method: methods.getDistributionPatientList,
			project: {
				projectId
			}
		});

		if (!data.patients) {
			throw new Error(Dictionary.errors.api.patients.couldNotGetDistributionPatientList);
		}

		return data.patients;
	},

	async addDistributionPatient(projectId: number, patient: AddPatient) {
		const { data }: CreatePatientResponse = await sendRequest(PATIENTS_URL, {
			method: methods.addDistributionPatient,
			project: {
				projectId
			},
			patient: patient
		});

		if (data.statusCode !== '200' || !data.patient) {
			throw new Error(Dictionary.errors.api.patients.couldNotCreateNewPatient);
		}

		return data.patient;
	},

	async updateDistributionPatient(projectId: number, patient: Patient) {
		const { data }: UpdatePatientResponse = await sendRequest(PATIENTS_URL, {
			method: methods.updateDistributionPatient,
			project: {
				projectId
			},
			patient: patient
		});

		if (data.statusCode !== '200' || !data) {
			throw new Error(Dictionary.errors.api.patients.couldNotUpdatePatient);
		}

		return data;
	},

	async deleteDistributionPatient(projectId: number, patientId: number) {
		const { data }: DeleteDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.deleteDistributionPatient,
			project: {
				projectId
			},
			patient: {
				patientId
			}
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.patients.couldNotDeletePatient);
		}
	},

	async getDistributions(projectId: number) {
		const { data }: GetDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.getDistribution,
			project: {
				projectId
			},
			distributionSetup: {}
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.patients.couldNotGetDistribution);
		}

		return data;
	},

	async updateDistributions(
		projectId: number,
		distributions?: BaseDistributionDataInput[],
		senderDescription?: string,
		centraliseEntryData?: boolean
	) {
		const { data }: UpdateDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.updateDistribution,
			project: {
				projectId
			},
			distributionSetup: {
				type: PromDistributionTypes.Automatic,
				senderDescription,
				centraliseEntryData
			},
			distributions
		});

		if (data.statusCode !== '200' || !data.distributions) {
			throw new Error(Dictionary.errors.api.patients.couldNotUpdateDistribution);
		}

		return data.distributions;
	},

	async createDistributions(
		projectId: number,
		distributions?: BaseDistributionDataInput[],
		senderDescription?: string,
		centraliseEntryData?: boolean
	) {
		const { data }: CreateDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.createDistribution,
			project: {
				projectId
			},
			distributionSetup: {
				type: PromDistributionTypes.Automatic,
				senderDescription,
				centraliseEntryData
			},
			distributions
		});

		if (data.statusCode !== '200' || !data.distributions) {
			throw new Error(Dictionary.errors.api.patients.couldNotUpdateDistribution);
		}

		return data.distributions;
	},

	async confirmStartedFillingForm(projectId: number, datasetEntryId: string, updateTime: string) {
		const { data } = await sendRequest(PATIENTS_URL, {
			method: methods.confirmStartedFillingForm,
			project: {
				projectId
			},
			formFillUpdate: {
				datasetEntryId,
				updateTime
			}
		});

		if (data.statusCode !== '200') throw new Error();
	},

	async confirmFinishedForm(projectId: number, datasetEntryId: string, updateTime: string) {
		const { data } = await sendRequest(PATIENTS_URL, {
			method: methods.confirmFinishedForm,
			project: {
				projectId
			},
			formFillUpdate: {
				datasetEntryId,
				updateTime
			}
		});

		if (data.statusCode !== '200') throw new Error();
	},

	/**
	 * PROM MANUAL DISTRIBUTION
	 */

	async getManualDistributions(projectId: number) {
		const { data }: GetManualDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.getManualDistribution,
			project: {
				projectId
			},
			distributionSetup: {}
		});

		if (data.statusCode !== '200') {
			throw new Error(Dictionary.errors.api.patients.couldNotGetDistribution);
		}

		const { globalStartDate } = data.manualDistributionSetup;

		// FORMAT FROM UTC (+0000) TO USER TIMEZONE
		const formattedGlobalStartDate = formatAPIDate(
			globalStartDate,
			DATE_TIME_TIMEZONE_FORMAT
		) as string;

		data.manualDistributionSetup.globalStartDate = formattedGlobalStartDate;

		return data;
	},

	async updateManualDistributions(
		projectId: number,
		distributions?: BaseDistributionDataInput[],
		globalStartDate?: string
	) {
		const { data }: UpdateManualDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.updateManualDistribution,
			project: {
				projectId
			},
			manualDistributionSetup: {
				type: PromDistributionTypes.Manual,
				globalStartDate
			},
			manualDistributions: distributions
		});

		if (data.statusCode !== '200' || !data.manualDistributions) {
			throw new Error(Dictionary.errors.api.patients.couldNotUpdateDistribution);
		}

		return data.manualDistributions;
	},

	async createManualDistributions(
		projectId: number,
		distributions?: BaseDistributionDataInput[],
		globalStartDate?: string
	) {
		const { data }: CreateManualDistributionResponse = await sendRequest(PATIENTS_URL, {
			method: methods.createManualDistribution,
			project: {
				projectId
			},
			manualDistributionSetup: {
				type: PromDistributionTypes.Manual,
				globalStartDate
			},
			manualDistributions: distributions
		});

		if (data.statusCode !== '200' || !data.manualDistributions) {
			throw new Error(Dictionary.errors.api.patients.couldNotUpdateDistribution);
		}

		return data.manualDistributions;
	}
});

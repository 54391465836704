import { ActionPayload } from 'store/types';

import {
	ActionTypes,
	DeleteFullScreenSeriesNameTableView,
	SetSeriesTableParamsAction,
	SetSeriesTableVisibleColumnsAction,
	UpdateSeriesName,
	UpdateFullScreenSeriesNameTableView,
	UpdateSeriesViews
} from './types';

export const setSeriesTableParams = (
	payload: ActionPayload<SetSeriesTableParamsAction>
): SetSeriesTableParamsAction => ({
	type: ActionTypes.SET_SERIES_TABLE_PARAMS,
	payload
});

export const setSeriesTableVisibleColumns = (
	payload: ActionPayload<SetSeriesTableVisibleColumnsAction>
): SetSeriesTableVisibleColumnsAction => ({
	type: ActionTypes.SET_SERIES_TABLE_VISIBLE_COLUMNS,
	payload
});

// TODO: SEE ALSO data/entries/SET_SELECTED_SERIES_ENTRY
// seem to be the same thing but de-synced in some cases
export const setUpdateColumnsSettingsSeriesName = (
	payload: ActionPayload<UpdateSeriesName>
): UpdateSeriesName => ({
	type: ActionTypes.UPDATE_SERIES_NAME,
	payload
});

export const setUpdateFullScreenSeriesNameTableView = (
	payload: ActionPayload<UpdateFullScreenSeriesNameTableView>
): UpdateFullScreenSeriesNameTableView => ({
	type: ActionTypes.UPDATE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW,
	payload
});

export const setDeleteFullScreenSeriesNameTableView = (
	payload: ActionPayload<DeleteFullScreenSeriesNameTableView>
): DeleteFullScreenSeriesNameTableView => ({
	type: ActionTypes.DELETE_FULL_SCREEN_SERIES_NAME_TABLE_VIEW,
	payload
});

export const setSeriesViews = (payload: ActionPayload<UpdateSeriesViews>): UpdateSeriesViews => ({
	type: ActionTypes.SET_SERIES_VIEWS,
	payload
});

import { useActivity } from 'hooks/store';
import { OperationResult } from 'hooks/store/types';
import { useDispatch, useSelector } from 'hooks/utils';

import {
	ActionTypes,
	EnterpriseProject,
	getAllEnterpriseOwnedProjects,
	selectAllEnterpriseOwnedProjects
} from 'store/account/enterprise';

export function useAllEnterpriseOwnedProjects(): OperationResult<EnterpriseProject[], () => void> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.GET_ALL_ENTERPRISE_OWNED_PROJECTS);

	const projects = useSelector(state =>
		selectAllEnterpriseOwnedProjects(state.account.enterprise)
	);

	const fetched = !!projects;

	function handler() {
		dispatch(getAllEnterpriseOwnedProjects());
	}

	return [{ data: projects, loading, error, fetched }, handler];
}

import styled, { css } from 'styled-components';

import { Colors } from 'environment';

interface VLineProps {
	fullHeight?: boolean;
}
export const VLine = styled.div<VLineProps>`
	display: block;
	width: 0.1rem;
	position: absolute;
	top: 0;
	left: -2.1rem;
	height: 2.8rem;
	background-color: ${Colors.vibrantGreen};

	${({ fullHeight }) =>
		fullHeight &&
		css`
			top: 2.2rem;
			height: calc(100% - 4.6rem);
		`}
`;

interface FlexRowProps {
	level?: number;
	css?: string;
}
export const FlexRow = styled.div<FlexRowProps>`
	display: flex;
	margin-bottom: 0.8rem;
	width: 100%;
	margin-left: ${({ level }) => (level ? 'calc(2rem *' + level + ' )' : '0rem')};
	position: relative;

	/* CUSTOM CSS */
	${({ css: _css }) => _css}
`;

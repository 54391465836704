import { useState, useRef, useLayoutEffect, StrictMode } from 'react';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { nanoid as generate } from 'nanoid';

import {
	useDashboardById,
	useTranslation,
	useAddDashboardCard,
	useListCurrentDashboards,
	useResizeDashboardCard,
	useMoveDashboardCard,
	useRemoveDashboardCard,
	useLayoutColumns
} from 'hooks/store';
import { useElementSize } from 'hooks/ui/useElementSize';

import { AdminDashboardHeader } from './AdminDashboardHeader';

import { WidthSizes, SetSizePayloadDynamic } from 'store/data/projectDashboard';
import { CardsGridDynamic } from './ResizeDragAndDrop';
import { EmptyDashboard } from '../EmptyDashboard';

import { Typography } from 'components/UI/Typography';
import { FiltersDrawerWrapper, GridContainer } from './style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Flex } from 'components/UI/Flex';
import { Grid } from 'components/UI/Grid';
import { Suspend } from 'components/UI/Suspend';
import { supportsTouch } from 'consts';

export interface IndexPayload {
	dragged: CardPositionManual;
	dropped: CardPositionManual;
}

export interface CardPositionManual {
	rowIndex: number;
	colIndex: number;
}

export function AdminDashboard() {
	const { translate } = useTranslation();
	const gridRef = useRef<HTMLDivElement>(null);
	const filtersRef = useRef<HTMLDivElement>(null);
	const { handleRef: adminPannelRef, width: screenWidth } = useElementSize();

	const [
		{
			data: { dashboard },
			loading: loadingProjectDashboard,
			fetched: isDashboardFetched
		}
	] = useDashboardById();
	const [layoutSize, setLayoutSize] = useLayoutColumns();

	const [
		{
			// data: dashboards,
			loading: loadingProjectDashboards,
			fetched: areDashboardsFetched
		}
	] = useListCurrentDashboards();

	const addDashboardCard = useAddDashboardCard();
	const resizeDashboardCard = useResizeDashboardCard();
	const moveDashboardCard = useMoveDashboardCard();
	const removeDashboardCard = useRemoveDashboardCard();

	const [showFilters, setShowFilters] = useState(false);

	const threeColumns = { firstSizeWidth: 0, secondSizeWidth: 0, thirdSizeWidth: 0 };
	const fourColumns = { ...threeColumns, forthSizeWidth: 0 };
	const fiveColumns = { ...fourColumns, fifthSizeWidth: 0 };
	const sixColumns = { ...fiveColumns, sixthSizeWidth: 0 };
	const sevenColumns = { ...sixColumns, seventhSizeWidth: 0 };
	const [dynamicWidthSizesByColumn, setDynamicWidthSizesByColumn] = useState<WidthSizes>({
		threeColumns,
		fourColumns,
		fiveColumns,
		sixColumns,
		sevenColumns
	});

	const heightSizes = {
		minSizeHeight: 307 - 44,
		maxSizeHeight: 614 - 20
	};

	useLayoutEffect(() => {
		calculateCardWidthSizesDynamic(layoutSize);
	}, [showFilters, screenWidth, layoutSize]);

	function addCardToManualGrid() {
		addDashboardCard({
			card: {
				cardTemplateId: 1,
				filterParams: { key1: '', key2: '' },
				label: 'Some test cardSome test cardSome test cardSome test cardSome test card',
				cardId: 1,
				size: { height: 1, width: 1 },
				userId: 'gabreal'
			}
		});
	}

	function setCardSizeDynamic(payload: SetSizePayloadDynamic) {
		dynamicWidthSizesByColumn &&
			resizeDashboardCard({
				...payload,
				cardId: Number(payload.cardId),
				heightSizes,
				widthSizes: dynamicWidthSizesByColumn
			});
	}

	function calculateCardWidthSizesDynamic(columns: number) {
		let localWidth;
		if (!gridRef || !gridRef.current) localWidth = window.innerWidth;
		else {
			localWidth = gridRef.current.clientWidth + 2;
		}

		// Calculate sizes (min/medium/max)
		// Three columns(placeholders) on a row
		const colSize = localWidth / columns - 22;

		//might be needed a third variable dynamic
		const threeColumns = {
			firstSizeWidth: Math.round(colSize * 1 - 22),
			secondSizeWidth: Math.round(colSize * 2 + 12),
			thirdSizeWidth: Math.round(colSize * 3 + 38)
		};
		const fourColumns = {
			...threeColumns,
			forthSizeWidth: Math.round(colSize * 4 + 66)
		};
		const fiveColumns = {
			...fourColumns,
			fifthSizeWidth: Math.round(colSize * 5 + 96)
		};
		const sixColumns = {
			...fiveColumns,
			sixthSizeWidth: Math.round(colSize * 6 + 126)
		};
		const sevenColumns = {
			...sixColumns,
			seventhSizeWidth: Math.round(colSize * 7 + 152)
		};

		const newWidthSizes: WidthSizes = {
			...(columns === 3 && { threeColumns }),
			...(columns === 4 && { fourColumns }),
			...(columns === 5 && { fiveColumns }),
			...(columns === 6 && { sixColumns }),
			...(columns === 7 && {
				sevenColumns
			})
		};

		if (newWidthSizes !== dynamicWidthSizesByColumn)
			setDynamicWidthSizesByColumn(newWidthSizes);
	}

	function moveCardToPlaceholder(indexPayload: IndexPayload | null) {
		if (!indexPayload) return;
		return moveDashboardCard(indexPayload);
	}

	function removeCardFromManualGrid(position: CardPositionManual) {
		return removeDashboardCard(position);
	}
	const emptyDashboard = !!(dashboard && !Object.values(dashboard.cards).length);

	const filterColumns = showFilters ? { xl: 3, l: 3, m: 3 } : { xl: 0, l: 0, m: 0 };
	const gridColumns = showFilters ? { xl: 13, l: 9, m: 3 } : { xl: 16, l: 12, m: 6 };

	const loading = loadingProjectDashboard || loadingProjectDashboards;
	const immediate = isDashboardFetched || areDashboardsFetched;

	// const { lastElementIndex } = getLastCardAndPlaceholderPositions(dashboard?.elementsOrder);

	return (
		<>
			<AdminDashboardHeader
				showFilters={showFilters}
				emptyDashboard={emptyDashboard}
				layoutSize={layoutSize}
				setShowFilters={setShowFilters}
				addNewCard={addCardToManualGrid}
				setLayoutSize={setLayoutSize}
			/>
			<StrictMode>
				<Suspend loading={loading} immediate={immediate}>
					<Grid.Container>
						<div
							ref={adminPannelRef}
							style={{ position: 'relative', margin: '0 -1rem' }}
						>
							<Flex>
								{showFilters && (
									<Grid.Row style={{ flexWrap: 'nowrap' }}>
										<Grid.Col sizes={filterColumns}>
											<FiltersDrawerWrapper
												ref={filtersRef}
												visible={showFilters}
											>
												<>
													<Typography.H5>
														{translate(dict => dict.filters.filters)}
													</Typography.H5>
													<CreatableSelect
														name={'Project Filters'}
														label={'Project Filters'}
														placeholder={'All Projects'}
														items={[
															{
																label: 'All Projects',
																value: 'All Projects'
															},
															{
																label: 'Ongoing Projects',
																value: 'Ongoing Projects'
															}
														]}
														disabled={false}
														hasMultipleValues={false}
													/>
												</>
											</FiltersDrawerWrapper>
										</Grid.Col>
									</Grid.Row>
								)}
								<Grid.Row style={{ flexWrap: 'nowrap' }}>
									<Grid.Col sizes={gridColumns}>
										<DndProvider
											backend={supportsTouch ? TouchBackend : HTML5Backend}
										>
											<GridContainer ref={gridRef}>
												{emptyDashboard && (
													<EmptyDashboard
														addCardToGrid={addCardToManualGrid}
													/>
												)}
												{dashboard
													? dashboard.elementsOrder.length && (
															<CardsGridDynamic
																cardsRows={dashboard.elementsOrder}
																key={generate()}
																widthSizes={
																	dynamicWidthSizesByColumn
																}
																heightSizes={heightSizes}
																removeCard={
																	removeCardFromManualGrid
																}
																setDimensions={payload =>
																	setCardSizeDynamic(payload)
																}
																moveCardToLayout={
																	moveCardToPlaceholder
																}
															/>
													  )
													: null}
											</GridContainer>
										</DndProvider>
									</Grid.Col>
								</Grid.Row>
							</Flex>
						</div>
					</Grid.Container>
				</Suspend>
			</StrictMode>
		</>
	);
}

import { createSelector } from 'reselect';
import { GenericMap } from 'types/index';
import { Dashboard, State } from './types';

// function getDashboardsByProjectId({ dashboardsById, projectId }: State) {
// 	if (projectId && projectId in dashboardsById) return dashboardsById[projectId];
// }

export const selectDashboards = createSelector(
	({ dashboardsById, projectId }: State) => {
		if (!dashboardsById || !projectId) return null;
		const currentDashboards: GenericMap<Dashboard> = {};
		Object.values(dashboardsById).forEach(dashboard => {
			const { dashboardId } = dashboard.current;
			const { current: currentDashboard } = dashboard;
			currentDashboards[dashboardId] = currentDashboard;
		});
		return currentDashboards;
	},
	dashboards => dashboards
);
export const selectDashboardsFetched = createSelector(
	({ dashboardIdsByProjectId, projectId }: State) => {
		return dashboardIdsByProjectId && projectId
			? dashboardIdsByProjectId[projectId].fetched
			: false;
	},
	fetched => fetched
);

export const selectDashboardById = createSelector(
	({ dashboardsById, projectId, dashboardId }: State) => {
		return dashboardsById && projectId && dashboardId && dashboardsById[dashboardId]
			? dashboardsById[dashboardId].current
			: null;
	},
	dashboard => dashboard
);
export const selectDashboardFetched = createSelector(
	({ dashboardsById, projectId, dashboardId }: State) => {
		return (
			// dashboardIdsByProjectId &&
			projectId &&
			dashboardId &&
			// dashboardIdsByProjectId[projectId].ids.includes(dashboardId) &&
			Object.values(dashboardsById[dashboardId].current.cards).length
		);
	},
	dashboard => dashboard
);

export const selectDashboardId = createSelector(
	({ dashboardId }: State) => {
		return dashboardId;
	},
	dashboardId => dashboardId
);
export const selectLayoutColumns = createSelector(
	({ layoutColumns }: State) => {
		return layoutColumns;
	},
	dashboardId => dashboardId
);

export const selectDashboardCards = createSelector(
	({ dashboardsById, projectId, dashboardId }: State) => {
		if (!dashboardsById || !projectId || !dashboardId) return {};
		return dashboardsById[dashboardId].current.cards;
	},
	cards => cards
);

export const selectDashboardElementsOrder = createSelector(
	({}: // byProjectId, projectId
	State) => {
		return [[[]]];
		// return byProjectId && projectId ? byProjectId[projectId] : null;
	},
	emailAddress => emailAddress
);

import styled, { css } from 'styled-components';

import { Colors as Colors } from 'environment';
import { generateSpacingOffsets } from 'helpers/cssGenerators';
import { StyleOffsets } from 'types/index';

import { UserTagType } from './UserTag';

interface Props {
	type: UserTagType;
	paddingOffset?: StyleOffsets;
	marginOffset?: StyleOffsets;
}

export const TagWrapper = styled.div<Props>`
	${({ type, paddingOffset, marginOffset }) => css`
		display: flex;
		width: fit-content;
		padding: 0.1rem 0.4rem 0rem 0.4rem;
		align-items: center;
		border: 0.1rem solid;
		border-radius: 0.4rem;
		background: transparent;

		${type === UserTagType.Primary &&
		css`
			border-color: ${Colors.primary.normal};
		`}

		${type === UserTagType.Pending &&
		css`
			border-color: ${Colors.text.disabled};
		`}

        ${type === UserTagType.VibrantGreen &&
		css`
			border-color: ${Colors.vibrantGreen};
			padding: 0.2rem 0.8rem 0.2rem 0.8rem;
		`}
		
		/* SPACING OFFSETS */
		${generateSpacingOffsets({ paddingOffset, marginOffset })}
	`}
`;

export const Container = styled.div`
	align-self: center;
`;

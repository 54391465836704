import { useMemo } from 'react';
import produce from 'immer';

import { withMemo } from 'helpers/HOCs';
import { useTranslation } from 'hooks/store';
import {
	StatusPermissionFlags,
	StatusPermissions,
	StatusTypeAccesses
} from 'store/data/collaborators';
import { Status } from 'store/data/statuses';
import { GenericMap } from 'types/index';

import { Container } from './StatusesPermissions.style';
import { Flex } from 'components/UI/Flex';
import { InfoMessage } from 'components/UI/InfoMessage';
import { Switch } from 'components/UI/Interactables/Switch';
import { Spacer } from 'components/UI/Spacer';
import { Table } from 'components/UI/Table';

interface Props {
	statuses: Status[];
	permissions: StatusTypeAccesses;
	disabled?: boolean;
	onChange: (permissions: StatusTypeAccesses) => void;
}

function Component({ statuses, permissions, disabled, onChange }: Props) {
	const { translate } = useTranslation();

	const statusPermissionsMap = useMemo(() => {
		const map: GenericMap<StatusPermissions> = {};

		permissions.forEach(
			({ statusTypeVariableName, ...statusAccess }) =>
				(map[statusTypeVariableName] = statusAccess)
		);

		return map;
	}, [permissions]);

	function handleUpdateStatus(
		flag: StatusPermissionFlags,
		variableName: string,
		depFlag: StatusPermissionFlags | null = null
	) {
		if (disabled) return;

		const updatedPermissions = produce(permissions, draft => {
			const statusPermissions = draft.find(
				status => status.statusTypeVariableName === variableName
			);

			if (statusPermissions) {
				// GET NEW VALUE
				const updatedFlagValue = !statusPermissions[flag];

				// TOGGLE FLAG WITH NEW VALUE
				statusPermissions[flag] = updatedFlagValue;

				// UPDATE DEPENDENCY FLAGS WITH NEW FLAG VALUE
				if (depFlag) statusPermissions[depFlag] = true;
			}
		});

		onChange(updatedPermissions);
	}

	return (
		<Container disabled={disabled}>
			<Table.Responsive horizontalScroll noDesign>
				<Table fullWidth noDesign>
					<Table.Head>
						<Table.Row>
							<Table.Column empty />
							<Table.Column noWrap textCenter>
								{translate(dict => dict.sharingPermissions.statusTableColumns.view)}
							</Table.Column>
							<Table.Column noWrap textCenter>
								{translate(dict => dict.sharingPermissions.statusTableColumns.edit)}
							</Table.Column>
							<Table.Column noWrap textCenter>
								{translate(
									dict => dict.sharingPermissions.statusTableColumns.setStatus
								)}
							</Table.Column>
						</Table.Row>
					</Table.Head>

					<Table.Body>
						{statuses.map(status => (
							<Table.Row key={`status_${status.name}`}>
								{/* STATUS NAME */}
								<Table.Cell
									title={status.label}
									paddingLeft={0}
									minWidth={12}
									maxWidth={12}
									noWrap
								>
									{status.label}
								</Table.Cell>

								{/* STATUS - VIEW DATA */}
								<Table.Cell width={12} minWidth={8}>
									<Flex justify={j => j.center}>
										<Switch
											onChange={() =>
												handleUpdateStatus(
													StatusPermissionFlags.viewData,
													status.name
												)
											}
											on={statusPermissionsMap[status.name].viewData}
											disabled={
												statusPermissionsMap[status.name].setStatus ||
												statusPermissionsMap[status.name].editData
											}
										/>
									</Flex>
								</Table.Cell>

								{/* STATUS - EDIT DATA */}
								<Table.Cell width={12} minWidth={8}>
									<Flex justify={j => j.center}>
										<Switch
											onChange={() =>
												handleUpdateStatus(
													StatusPermissionFlags.editData,
													status.name,
													StatusPermissionFlags.viewData
												)
											}
											on={statusPermissionsMap[status.name].editData}
										/>
									</Flex>
								</Table.Cell>

								{/* STATUS - SET STATUS */}
								<Table.Cell width={12} minWidth={8}>
									<Flex justify={j => j.center}>
										<Switch
											onChange={() =>
												handleUpdateStatus(
													StatusPermissionFlags.setStatus,
													status.name,
													StatusPermissionFlags.viewData
												)
											}
											on={statusPermissionsMap[status.name].setStatus}
										/>
									</Flex>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>

				<Spacer size={s => s.l} />

				<InfoMessage
					message={translate(dict => dict.sharingPermissions.statusDescription)}
				/>
			</Table.Responsive>
		</Container>
	);
}

export const StatusesPermissions = withMemo(Component);

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	BillingInfoType,
	selectBillingInfo,
	getSubscriptionBillingInfo
} from 'store/account/payments';
import { useSubscription } from '../subscription/useSubscription';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store';

export function useBillingInfo(lazy?: boolean): OperationResult<BillingInfoType> {
	const dispatch = useDispatch();

	const [
		{
			data: {
				subscriptionTypes: { isNonSubscriber }
			}
		}
	] = useSubscription();

	const data = useSelector(state => selectBillingInfo(state.account.payments));

	const fetched = !!data;

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_USER_BILLING_INFO);

	useEffect(() => {
		if (!loading && !error && !fetched && !lazy && !isNonSubscriber) handler();
	}, [loading, error, fetched, lazy, isNonSubscriber]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getSubscriptionBillingInfo());
	}

	return [{ loading, error, fetched, data }, handler];
}

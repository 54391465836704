import { useState, useMemo } from 'react';
import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { VariablesDataArray } from 'store/data/variables';
import { SelectItem } from 'types/index';
import { InputLabel } from 'components/UI/Inputs/InputLabel';
import { InputError } from './CreateStatusesFromCategoriesModal.style';
import { Modal } from 'components/UI/Modal';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Spacer } from 'components/UI/Spacer';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { SelectMultiple } from 'components/UI/Interactables/SelectMultiple';
import { Pagination } from 'components/UI/Pagination';
import { TagInput } from 'components/UI/TagInput';
import { arrayUtils } from 'helpers/arrays';
import { parsePaginationRelativeIndexes } from 'helpers/generic';
import { variablesDataArrayIterator, buildVariableCategoriesMap } from 'helpers/variables';
import { useTranslation, useVariables, useStatuses, useCreateStatuses } from 'hooks/store';
import { useMutableState, useCompletedAction, usePaginate } from 'hooks/utils';

interface Props {
	onClose: () => void;
}

export function CreateStatusesFromCategoriesModal({ onClose }: Props) {
	const { translate } = useTranslation();

	const statusAlreadyExists = translate(
		({
			statuses: {
				createFromCategoriesModal: { errors }
			}
		}) => errors.statusAlreadyExists
	);

	const [
		{
			data: { variablesDataArray, variablesMap },
			loading: loadingVariables
		}
	] = useVariables({ initial: true, omit: { promGenerated: true } });

	const {
		data: { statuses }
	} = useStatuses()[0];

	const [variableName, setVariableName] = useState('');
	const [selectedCategories, setSelectedCategories] = useMutableState<string[]>([]);

	const [{ loading: creatingStatuses, error: errorCreatingStatuses }, createStatuses] =
		useCreateStatuses();

	useCompletedAction(creatingStatuses, errorCreatingStatuses, onClose);

	const {
		pageIndex,
		pageSize,
		pagesCount,
		shouldPaginate,
		page: selectedCategoryItems,
		changePage,
		changePageSize
	} = usePaginate(getSelectedCategoryItems(), {
		threshold: 10,
		pageSize: 10
	});

	function handleCreate() {
		if (!isFormValid()) return;

		createStatuses(selectedCategories);
	}

	function isFormValid() {
		return (
			isVariableSelected() &&
			selectedCategories.length > 0 &&
			!statuses.find(status => selectedCategories.includes(status.label))
		);
	}

	function isVariableSelected() {
		return variableName !== '';
	}

	function onCheck(category: string) {
		const newCategories = selectedCategories.includes(category)
			? selectedCategories.filter(c => c !== category)
			: [...selectedCategories, category];
		setSelectedCategories(newCategories);
	}

	function onToggleAll(flag: boolean) {
		const categories = flag ? getVariableCategories() : [];

		setSelectedCategories(categories);
	}

	function getVariableCategories(): string[] {
		if (variableName in variablesMap) {
			const variable = variablesMap[variableName];

			return variable.categories.map(c => c.value);
		}

		return [];
	}

	function getCategoriesSelectItems(variablesDataArray: VariablesDataArray): SelectItem[] {
		const selectItems: SelectItem[] = [];

		variablesDataArrayIterator(
			variablesDataArray,
			// VARIABLE
			variable => handleAddSelectItem(variable),
			// GROUP
			groupData => {
				const { groupVariables } = groupData;

				groupVariables.forEach(variable => handleAddSelectItem(variable));
			},
			// VARIABLE SET
			variableSetData => {
				const { setData } = variableSetData;

				variablesDataArrayIterator(
					setData,
					// VARIABLE
					variable => handleAddSelectItem(variable),
					// GROUP
					groupData => {
						const { groupVariables } = groupData;

						groupVariables.forEach(variable => handleAddSelectItem(variable));
					},
					// VARIABLE SET - OMIT
					() => null
				);
			}
		);

		function handleAddSelectItem(variable: Variable) {
			if (!isCategoryVariable(variable)) return;

			const selectItem: SelectItem = {
				label: variable.label,
				value: variable.name
			};

			selectItems.push(selectItem);
		}

		function isCategoryVariable(variable: Variable) {
			const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
				variable.type
			);

			return isCategory;
		}

		return selectItems;
	}

	function getSelectCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];

		const variable = variablesMap[variableName];

		if (variable) {
			variable.categories.forEach(c => {
				const item: SelectItem = {
					label: c.label || c.value,
					value: c.value
				};

				items.push(item);
			});
		}

		return items;
	}

	function getSelectedCategoryItems(): SelectItem[] {
		const items: SelectItem[] = [];

		const variable = variablesMap[variableName];

		if (variable) {
			const categoriesMap = buildVariableCategoriesMap(variable.categories);

			selectedCategories.forEach(value => {
				const category = categoriesMap[value];

				const item: SelectItem = {
					label: category.label || category.value,
					value: category.value
				};

				items.push(item);
			});
		}

		return items;
	}

	function handleMoveCategoryValue(input: { sourceIndex: number; destinationIndex: number }) {
		const { sourceIndex, destinationIndex } = input;

		if (sourceIndex === destinationIndex) return;

		const absoluteIndexes = parsePaginationRelativeIndexes(sourceIndex, destinationIndex, {
			pageIndex,
			pageSize
		});

		setSelectedCategories(state =>
			arrayUtils.move(state, absoluteIndexes.sourceIndex, absoluteIndexes.destinationIndex)
		);
	}

	const categoriesSelectItems = useMemo(
		() => getCategoriesSelectItems(variablesDataArray),
		[variablesDataArray]
	);

	const errorMessage = useMemo(
		() =>
			statuses.find(status => selectedCategories.includes(status.label))
				? statusAlreadyExists
				: '',
		[statuses, selectedCategories]
	);

	return (
		<Modal
			title={translate(({ statuses }) => statuses.createFromCategoriesModal.title)}
			primary={{
				label: translate(dict => dict.buttons.create),
				loading: creatingStatuses,
				disabled: !isFormValid(),
				onClick: handleCreate
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<CreatableSelect
				label={translate(
					({ statuses }) =>
						statuses.createFromCategoriesModal.fields.categoryVariable.label
				)}
				placeholder={translate(
					({ statuses }) =>
						statuses.createFromCategoriesModal.fields.categoryVariable.placeholder
				)}
				value={categoriesSelectItems.find(selectItem => selectItem.value === variableName)}
				items={categoriesSelectItems}
				disabled={loadingVariables}
				onValueSelected={categoryVariable => {
					if (!categoryVariable) return;
					if (variableName === categoryVariable) return;

					setVariableName(categoryVariable);
					setSelectedCategories([]);
				}}
				canClear={false}
			/>

			<Spacer size={s => s.m} />

			{isVariableSelected() && (
				<>
					<Dropdown
						label={translate(
							({ statuses }) =>
								statuses.createFromCategoriesModal.fields.categoryValues.label
						)}
						title={translate(
							({ statuses }) =>
								statuses.createFromCategoriesModal.fields.categoryValues.title,
							true,
							{
								numberOf: selectedCategories.length,
								plural: selectedCategories.length <= 1 ? '' : 's'
							}
						)}
						button
					>
						<Flex paddingOffset={{ x: 1.2, y: 1.6 }}>
							<SelectMultiple
								items={getSelectCategoryItems()}
								onSelect={item => onCheck(item.value)}
								selectedItems={selectedCategories}
								onToggleAll={onToggleAll}
							/>
						</Flex>
					</Dropdown>
					<InputError error={errorMessage} />

					<Spacer size={s => s.s} />

					<InputLabel
						label={translate(
							({ statuses }) =>
								statuses.createFromCategoriesModal.fields.previewSelected.label
						)}
					/>

					{shouldPaginate && (
						<Flex marginOffset={{ bottom: 0.4 }}>
							<Pagination
								totalCountLabel="values"
								pageIndex={pageIndex}
								pageSize={pageSize}
								pagesCount={pagesCount}
								changePage={changePage}
								changePageSize={changePageSize}
								totalCount={getSelectedCategoryItems().length}
							/>
						</Flex>
					)}

					<TagInput
						hint={translate(
							({ statuses }) =>
								statuses.createFromCategoriesModal.fields.previewSelected.hint
						)}
						items={selectedCategoryItems}
						onMove={handleMoveCategoryValue}
						readOnly
						sortable
					/>
				</>
			)}
		</Modal>
	);
}

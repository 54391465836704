import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { GeneralPermissions } from 'components/Collaborators';
import { DefaultGeneralPermissions } from 'consts';
import { initButtonProps } from 'helpers/buttons';
import { GeneralPermissions as GeneralPermissionsType } from 'store/data/collaborators';
import { TemplateRole } from 'store/data/roles';
import { HTMLInput, InputType, SelectItem } from 'types/index';
import { generateModalTitle, generateSelectRoleItems, isRoleNameUnique } from './helpers';
import { TemplateRoleModalType } from './types';
import { Row } from './TemplateRoleModal.style';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Input } from 'components/UI/Inputs/Input/Input';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import {
	useTranslation,
	useTemplateRoles,
	useCreateTemplateRole,
	useUpdateTemplateRole,
	useOrganizationAccessRights
} from 'hooks/store';
import { useMutableState, useCompletedAction } from 'hooks/utils';

interface Props {
	templateRole?: TemplateRole;
	isShared?: boolean;
	onClose: (success?: boolean) => void;
}

export function TemplateRoleModal({ templateRole, onClose, isShared }: Props) {
	const isCreateMode = !templateRole;

	const type = isCreateMode ? TemplateRoleModalType.Create : TemplateRoleModalType.Update;

	const { translate } = useTranslation();
	const [
		{
			data: { roles }
		}
	] = useTemplateRoles();

	const nameInputRef = useRef<HTMLInput>(null);

	const initialRoleName = templateRole ? templateRole.name.toLowerCase() : '';

	const [{ loading: creatingRole, error: errorCreatingRole }, createRole] =
		useCreateTemplateRole();
	const [{ loading: updatingRole, error: errorUpdatingRole }, updateRole] =
		useUpdateTemplateRole();

	const [draftRole, setDraftRole] = useMutableState<TemplateRole>({
		id: templateRole ? templateRole.id : '',
		name: templateRole ? templateRole.name : '',
		description: templateRole ? templateRole.description : '',
		permissions: templateRole ? templateRole.permissions : DefaultGeneralPermissions,
		publicAccess: templateRole ? templateRole.publicAccess : false,
		shareList: {
			projectShareList: {
				current: [],
				initial: []
			},
			userShareList: {
				current: [],
				initial: []
			},
			fetched: false
		}
	});

	const [modalState, setModalState] = useMutableState({
		title: generateModalTitle(type, translate),
		items: generateSelectRoleItems(translate, roles),
		selectedItem: generateSelectRoleItems(translate, roles).default
	});

	const [
		{
			data: { accessExportDelegation }
		}
	] = useOrganizationAccessRights();

	const hasChanges = useMemo(() => !isEqual(templateRole, draftRole), [templateRole, draftRole]);

	useCompletedAction(creatingRole, errorCreatingRole, () => onClose(true));
	useCompletedAction(updatingRole, errorUpdatingRole, () => onClose(true));

	function handleSelectItem(value: string) {
		const publicItem = modalState.items.public.find(item => item.value === value);
		const ownedItem = modalState.items.owned.find(item => item.value === value);
		const sharedItem = modalState.items.shared.find(item => item.value === value);

		const item = publicItem || ownedItem || sharedItem;

		if (value && item) {
			if (value === 'custom') {
				item.label = translate(dict => dict.rolesPage.customRole);
				item.value = '';
			}

			const flatRoles = [...roles.owned, ...roles.shared, ...roles.public];

			const role = flatRoles.find(role => role.id === item.value);

			const permission = role ? role.permissions : DefaultGeneralPermissions;

			const name = role ? role.name : '';

			setModalState(state => {
				state.selectedItem = item;
			});

			setDraftRole(state => {
				state.name = name;
				state.permissions = permission;
			});

			nameInputRef.current?.focus();
		}
	}

	function isLoadingAction(): boolean {
		return updatingRole || creatingRole;
	}

	function isErrorAction(): boolean {
		return errorUpdatingRole || errorCreatingRole;
	}

	function isNameUnique(): boolean {
		const flatRoles = [...roles.public, ...roles.owned, ...roles.shared];

		return isRoleNameUnique(draftRole.name, {
			roles: flatRoles,
			exclude: isCreateMode ? [] : [initialRoleName]
		});
	}

	function isStringValid(value: string) {
		return value.trim().length > 0;
	}

	function getInputError() {
		const flatRoles = [...roles.public, ...roles.owned, ...roles.shared];

		if (
			!isRoleNameUnique(draftRole.name, {
				roles: flatRoles,
				exclude: [initialRoleName]
			})
		) {
			return translate(({ roles }) => roles.modals.roleModal.errors.nameUnique);
		}

		if (!isStringValid(draftRole.name)) {
			return translate(({ roles }) => roles.modals.roleModal.errors.nameRequired);
		}
	}

	function getRoleName(): SelectItem {
		if (modalState.selectedItem.value === 'custom') {
			return {
				value: 'custom',
				label: translate(dict => dict.rolesPage.customRole)
			};
		}

		return modalState.selectedItem;
	}

	function getNeutralButtonLabel() {
		return translate(({ buttons }) => buttons.cancel);
	}

	function handleSubmit() {
		if (isLoadingAction() || isErrorAction() || !isNameUnique()) return;

		const draftRoleTrimmed = trimFields(draftRole);

		if (templateRole) {
			updateRole(draftRoleTrimmed);
		} else {
			createRole(draftRoleTrimmed);
		}
	}

	function trimFields(templateRole: TemplateRole): TemplateRole {
		return {
			...templateRole,
			name: templateRole.name.trim(),
			description: templateRole.description.trim()
		};
	}

	const handleUpdatePermissions = useCallback(
		(permissions: GeneralPermissionsType) =>
			setDraftRole(state => {
				state.permissions = permissions;
			}),
		[draftRole]
	);

	const handleInputTrimOnBlur = (
		e: React.FocusEvent<HTMLInput>,
		fieldName: 'name' | 'description'
	) => {
		const { value } = e.target;

		setDraftRole(state => {
			state[fieldName] = value.trim();
		});
	};

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: isCreateMode
				? translate(({ buttons }) => buttons.add)
				: translate(({ buttons }) => buttons.update),
			loading: isLoadingAction(),
			onClick: handleSubmit,
			disabled: isErrorAction() || !isNameUnique() || !isStringValid(draftRole.name)
		};

		buttons.neutral = {
			label: getNeutralButtonLabel(),
			onClick: onClose
		};

		if (!hasChanges) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	return (
		<Modal
			title={modalState.title}
			onClose={onClose}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			fullSizeConfig={{
				narrow: true
			}}
			visible
			close
		>
			<>
				<Row>
					{/* Create Modal */}
					{isCreateMode && (
						<>
							<CreatableSelect
								label={translate(dict => dict.rolesPage.roleModal.selectRoleLabel)}
								value={getRoleName()}
								items={[...modalState.items.owned, ...modalState.items.shared]}
								onValueSelected={value => {
									if (!value) return;

									handleSelectItem(value);
								}}
								canClear={false}
							/>
							<Input
								type={InputType.Text}
								label={translate(({ roles }) => roles.modals.roleModal.fields.name)}
								value={draftRole.name}
								placeholder={translate(
									({ roles }) => roles.modals.roleModal.inputPlaceholders.custom
								)}
								onChange={e =>
									setDraftRole(state => {
										state.name = e.target.value;
									})
								}
								onBlur={e => handleInputTrimOnBlur(e, 'name')}
								onSubmit={handleSubmit}
								error={
									!isNameUnique()
										? translate(
												({ roles }) =>
													roles.modals.roleModal.errors.nameUnique
										  )
										: ''
								}
								ref={nameInputRef}
								autoFocus
							/>
						</>
					)}

					{/* Update Modal */}
					{!isCreateMode && (
						<Input
							type={InputType.Text}
							label={translate(({ roles }) => roles.modals.roleModal.fields.name)}
							value={draftRole.name}
							onChange={e =>
								setDraftRole(state => {
									state.name = e.target.value;
								})
							}
							onBlur={e => handleInputTrimOnBlur(e, 'name')}
							onSubmit={handleSubmit}
							error={getInputError()}
						/>
					)}
				</Row>
				<Spacer size={s => s.s} />
				<Input
					type={InputType.Textarea}
					label={translate(({ roles }) => roles.modals.roleModal.fields.description)}
					value={draftRole.description}
					rows={3}
					onChange={e =>
						setDraftRole(state => {
							state.description = e.target.value;
						})
					}
					onBlur={e => handleInputTrimOnBlur(e, 'description')}
					onSubmit={handleSubmit}
				/>
				<Spacer size={s => s.m} />
				<GeneralPermissions
					permissions={draftRole.permissions}
					onChange={handleUpdatePermissions}
					disabled={isShared}
					hasOrganizationExportAccess={accessExportDelegation}
				/>
			</>
		</Modal>
	);
}

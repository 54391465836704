import { useSelectedSeriesEntry } from 'hooks/store';
import { Svgs } from 'environment';

import { PageInput } from './PageInput';

import {
	Container,
	Controls,
	Control,
	Paragraph,
	DirectionRow,
	Icons as Icon
} from './PaginationCentered.style';
import { Entries } from 'store/data/entries';
import { PaginationDirection } from 'types/index';
import { useWindowSize } from 'hooks/ui';
import { DataTestId } from 'tests/consts';

interface Props {
	pageIndex: number;
	pagesSize: number;
	openPageInputOnRight?: boolean;
	entriesData: Entries;
	isRevisionSelected: boolean | undefined;
	canNavigate: boolean;
	navigateTo: (entryID: string | null) => void;
	navigateNext: () => void;
	navigatePrev: () => void;
}

export function PaginationCentered({
	pageIndex,
	pagesSize,
	openPageInputOnRight,
	entriesData,
	isRevisionSelected,
	canNavigate,
	navigateTo,
	navigateNext,
	navigatePrev
}: Props) {
	const [selectedSeriesEntry, setSelectedSeriesEntry] = useSelectedSeriesEntry();

	//DEVICE SIZES
	const { width } = useWindowSize();

	const isMobileDevice = width < 768;

	const canNextPage = pageIndex < pagesSize - 1;
	const canPreviousPage = pageIndex >= 1;
	const currentPageIndex = pageIndex;

	const pagesSkipValue = 10;
	const minimumPagesValueForDoubleArrows = 100;

	function isDoubleArrowsDisabled(type: string) {
		if (type === PaginationDirection.Left) {
			if (
				pagesSize < minimumPagesValueForDoubleArrows ||
				(pagesSize > minimumPagesValueForDoubleArrows && !canPreviousPage)
			) {
				return true;
			}
			if (currentPageIndex - 10 >= 0) {
				return false;
			}
		}
		if (type === PaginationDirection.Right) {
			if (
				pagesSize < minimumPagesValueForDoubleArrows ||
				(pagesSize > minimumPagesValueForDoubleArrows && !canNextPage)
			) {
				return true;
			}
			if (currentPageIndex + 10 <= pagesSize - 1) {
				return false;
			}
		}

		return true;
	}

	function handlePageChange(index: number) {
		if (isRevisionSelected) return;

		if (index <= pagesSize - 1) {
			const entryDataId: string | null = entriesData[index].datasetentryid;

			navigateTo(entryDataId);
		}
	}

	function handleDoubleArrowsPageChange(pagesSkip: number, direction: string) {
		let entryDataId: string | null = '';

		if (
			direction === PaginationDirection.Right &&
			!isDoubleArrowsDisabled(PaginationDirection.Right)
		) {
			const newPageIndex = currentPageIndex + pagesSkip;

			entryDataId = entriesData[newPageIndex].datasetentryid;
		}
		if (
			direction === PaginationDirection.Left &&
			!isDoubleArrowsDisabled(PaginationDirection.Left)
		) {
			const newPageIndex = currentPageIndex - pagesSkip;

			entryDataId = entriesData[newPageIndex].datasetentryid;
		}

		entryDataId !== '' &&
			setSelectedSeriesEntry({
				...selectedSeriesEntry,
				subEntryId: entryDataId
			});
	}

	return (
		<Container>
			<Controls>
				{/* PREV */}

				<DirectionRow>
					<Control
						doubleArrowLeft
						disabled={
							isDoubleArrowsDisabled(PaginationDirection.Left) || isRevisionSelected
						}
						onClick={() =>
							canPreviousPage &&
							handleDoubleArrowsPageChange(pagesSkipValue, PaginationDirection.Left)
						}
						title=""
						id={`prv`}
					>
						{isDoubleArrowsDisabled(PaginationDirection.Left) ? (
							<div />
						) : (
							<Icon
								size={s => s.l}
								svg={Svgs.ChevronLeftDouble}
								propagate
								disabled={
									isDoubleArrowsDisabled(PaginationDirection.Left) ||
									isRevisionSelected
								}
							/>
						)}
					</Control>

					<Control
						arrowLeft
						data-testid={DataTestId.PrevArrow}
						disabled={!canPreviousPage || isRevisionSelected}
						onClick={() => navigatePrev()}
						title=""
						id={`prv`}
					>
						<Icon
							svg={Svgs.ChevronLeft}
							disabled={!canPreviousPage || isRevisionSelected}
							propagate
						/>
					</Control>
				</DirectionRow>

				{/* MOBILE SIZE */}
				{isMobileDevice && (
					<DirectionRow>
						{pageIndex == 0 && (
							<Control
								active={pageIndex === 0}
								onClick={() => pageIndex !== 0 && handlePageChange(0)}
								title=""
								id={`pg1`}
							>
								<Paragraph>1</Paragraph>
							</Control>
						)}
						{pageIndex > 0 && pageIndex < pagesSize && (
							<Control
								onClick={() =>
									handlePageChange(
										pageIndex === pagesSize - 1 ? pageIndex - 1 : pageIndex + 1
									)
								}
								active={pageIndex === pagesSize - 1 ? false : true}
								title=""
								id={`pg${pageIndex + 1}`}
							>
								<Paragraph>
									{pageIndex === pagesSize - 1 ? pageIndex : pageIndex + 1}
								</Paragraph>
							</Control>
						)}
						{pagesSize > 2 && (
							<PageInput
								isRevisionSelected={isRevisionSelected}
								isPaginationCenteredComponent={true}
								pageIndex={pageIndex}
								openPageInputOnRight={openPageInputOnRight}
								onPageChange={handlePageChange}
								id={`fpi${pageIndex}`}
							/>
						)}

						{pagesSize > 1 && (
							<Control
								active={pageIndex === pagesSize - 1}
								onClick={() => {
									if (pagesSize >= 1 && pageIndex !== pagesSize - 1) {
										handlePageChange(pagesSize - 1);
									}
								}}
								title=""
								id={`pg${pagesSize}`}
							>
								<Paragraph>{pagesSize}</Paragraph>
							</Control>
						)}
					</DirectionRow>
				)}

				{!isMobileDevice && (
					<DirectionRow>
						{pagesSize > 2 && (
							<Control
								active={pageIndex === 0}
								onClick={() => pageIndex !== 0 && handlePageChange(0)}
								title=""
								id={`pg1`}
							>
								<Paragraph>1</Paragraph>
							</Control>
						)}
						{pagesSize > 3 && (
							<Control
								active={pageIndex === 1}
								onClick={() => pageIndex !== 1 && handlePageChange(1)}
								title=""
								id={`pg2`}
							>
								<Paragraph>2</Paragraph>
							</Control>
						)}
						{pageIndex > 2 && pageIndex < pagesSize - 2 && (
							<PageInput
								isRevisionSelected={isRevisionSelected}
								isPaginationCenteredComponent={true}
								pageIndex={pageIndex}
								openPageInputOnRight={openPageInputOnRight}
								onPageChange={handlePageChange}
								id={`fpi${pageIndex}`}
							/>
						)}
						{pageIndex > 1 && pageIndex < pagesSize - 2 && (
							<Control active title="" id={`pg${pageIndex + 1}`}>
								<Paragraph>{pageIndex + 1}</Paragraph>
							</Control>
						)}
						{pagesSize > 4 && pageIndex !== pagesSize - 3 && (
							<PageInput
								isRevisionSelected={isRevisionSelected}
								isPaginationCenteredComponent={true}
								pageIndex={pageIndex}
								openPageInputOnRight={openPageInputOnRight}
								onPageChange={handlePageChange}
								id={`spi${pageIndex}`}
							/>
						)}
						{pagesSize > 1 && (
							<Control
								title=""
								active={pageIndex === pagesSize - 2}
								onClick={() =>
									pageIndex !== pagesSize - 2 && handlePageChange(pagesSize - 2)
								}
								id={`pg${pagesSize - 1}`}
							>
								<Paragraph>{pagesSize - 1}</Paragraph>
							</Control>
						)}
						<Control
							active={pageIndex === pagesSize - 1}
							onClick={() => {
								if (pagesSize >= 1 && pageIndex !== pagesSize - 1) {
									handlePageChange(pagesSize - 1);
								}
							}}
							title=""
							id={`pg${pagesSize}`}
						>
							<Paragraph>{pagesSize}</Paragraph>
						</Control>
					</DirectionRow>
				)}

				{/* NEXT */}

				<DirectionRow>
					<Control
						dataTestId={DataTestId.NextArrow}
						arrowRight
						disabled={!canNavigate || isRevisionSelected}
						onClick={() => navigateNext()}
						title=""
						id={`nxt`}
					>
						<Icon
							svg={Svgs.ChevronRight}
							disabled={!canNavigate || isRevisionSelected}
							propagate
						/>
					</Control>

					<Control
						doubleArrowRight
						disabled={
							isDoubleArrowsDisabled(PaginationDirection.Right) || isRevisionSelected
						}
						onClick={() =>
							canNextPage &&
							handleDoubleArrowsPageChange(pagesSkipValue, PaginationDirection.Right)
						}
						title=""
						id={`nxt`}
					>
						{isDoubleArrowsDisabled(PaginationDirection.Right) ? (
							<div />
						) : (
							<Icon
								size={s => s.l}
								svg={Svgs.ChevronRightDouble}
								disabled={
									isDoubleArrowsDisabled(PaginationDirection.Right) ||
									isRevisionSelected
								}
								propagate
							/>
						)}
					</Control>
				</DirectionRow>
			</Controls>
		</Container>
	);
}

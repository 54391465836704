import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { ActionTypes, getProjectTemplates } from 'store/data/templates';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity, useIsProjectValid } from 'hooks/store';

export function useProjectTemplates(): OperationResult<string[]> {
	const dispatch = useDispatch();

	const isProjectValid = useIsProjectValid();

	const data = useSelector(state => state.data.templates.sharedWithProject.ids);

	const fetched = useSelector(state => state.data.templates.sharedWithProject.fetched);

	const [{ loading, error }] = useActivity(ActionTypes.GET_PROJECT_TEMPLATES);

	useEffect(() => {
		if (!fetched && !loading && !error && isProjectValid) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		dispatch(getProjectTemplates());
	}

	return [{ loading, error, data, fetched }, handler];
}

import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { UserCard } from 'components/UI/UserCard';
import { Svgs } from 'environment';
import { Nullable, TemplateShareLevel } from 'types/index';
import { ActionTypes as ProjectActionTypes } from 'store/data/projects';
import { ActionTypes as TemplatesActionTypes } from 'store/data/templates';
import { ActionTypes as AccountActionTypes } from 'store/data/accounts';
import {
	Container,
	ListHeader,
	Receipient,
	ReceipientsList,
	SvgWrapper
} from './ShareTemplateModal.style';
import { Typography } from 'components/UI/Typography';
import { Loader } from 'components/UI/Loader';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Modal } from 'components/UI/Modal';
import { Icon } from 'components/UI/Icons';
import {
	useTranslation,
	useCollaborators,
	useAllProjectsNameId,
	useScopedProjects,
	useProjectId,
	useTemplateId,
	useUsersByIds,
	useUserEmail,
	useShareTemplate,
	useTemplateById,
	useResetListsOfSharedWith,
	useUsers,
	useShareTemplateWithInstance,
	useUnshareTemplateWithInstance,
	useMissingUsersInfo,
	useActivities,
	useActivity
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	shareLevel: TemplateShareLevel;
	currentUserId: string;
}

interface SelectValue {
	label: string;
	value: string;
}

export function ShareTemplateModal({ shareLevel, currentUserId }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const isShareWithUser = shareLevel === TemplateShareLevel.ShareWithUsers;
	const isShareWithProjects = shareLevel === TemplateShareLevel.ShareWithProjects;

	const [sharedWith, setSharedWith] = useState<SelectValue[]>([]);
	const [userEmailsSharedWith, setUserEmailsSharedWith] = useState<string[]>([]);
	const [fetchNewCollaborator, setFetchNewCollaborator] = useState('');

	const [
		{
			data: { collaborators },
			loading: loadingCollaborators
		}
	] = useCollaborators();
	const allProjects = useAllProjectsNameId();

	/**
	 * FETCH
	 */
	useScopedProjects({ lazy: !isShareWithProjects });

	const [projectId] = useProjectId();
	const [templateId, setTemplateId] = useTemplateId();
	const [usersByIds, getUsersByIds] = useUsersByIds();
	const currentUserEmail = useUserEmail();
	const [{ error: errorSharingTemplate, loading: sharingTemplate }, shareTemplate] =
		useShareTemplate();

	const template = useTemplateById(templateId ?? '');
	const resetSharedWithList = useResetListsOfSharedWith();
	const usersByEmail = useUsers();

	const shareWithInstance = useShareTemplateWithInstance();
	const unshareWithInstance = useUnshareTemplateWithInstance();
	const getUsersDetailsByEmail = useMissingUsersInfo();

	const {
		usersSharedWith: { current: usersSharedWith, initial: initialUsersSharedWith },
		projectsSharedWith: { current: projectsSharedWith, initial: initialProjectsSharedWith }
	} = template;

	const [{ loading: loadingProjects }] = useActivities([
		ProjectActionTypes.GET_PROJECT,
		ProjectActionTypes.GET_PROJECTS,
		ProjectActionTypes.GET_PROMS
	]);
	const [{ loading: gettingListOfSharedWith }] = useActivity(
		TemplatesActionTypes.GET_TEMPLATE_LIST_OF_SHARED_WITH
	);

	const [{ loading: gettingListOfNewUsers }] = useActivity(AccountActionTypes.GET_USERS_BY_EMAIL);

	const sharedProjectsSelect = projectsSharedWith.map(project => project.projectId);

	const loading =
		loadingProjects ||
		loadingCollaborators ||
		sharingTemplate ||
		gettingListOfSharedWith ||
		gettingListOfNewUsers;

	useCompletedAction(sharingTemplate, errorSharingTemplate, () => {
		setNotification({
			message: translate(dict => dict.templates.templateShareSettingsUpdated)
		});
		setTemplateId(null);
	});

	useEffect(() => {
		if (fetchNewCollaborator) {
			if (
				!userEmailsSharedWith.includes(fetchNewCollaborator) &&
				!usersByEmail[fetchNewCollaborator]
			) {
				getUsersDetailsByEmail([fetchNewCollaborator]);
			}
			const templateCollaborator = usersByEmail[fetchNewCollaborator];
			if (templateCollaborator) {
				const { userid, emailAddress } = templateCollaborator;
				const userId = userid ?? '';
				shareWithInstance({ instanceId: userId, usersShare: true });
				if (!usersByIds[userId]) getUsersByIds([userId]);
				setUserEmailsSharedWith(state => [...state, emailAddress]);
				setFetchNewCollaborator('');
			}
		}
	}, [fetchNewCollaborator, usersByEmail, usersByIds]);

	useEffect(() => {
		if (!templateId) {
			resetSharedWithList({ templateId: templateId ?? '' });
		}
	}, [templateId]);

	useEffect(() => {
		if (!sharingTemplate) {
			const sharedWithInstances: SelectValue[] = [];

			if (usersSharedWith && isShareWithUser) {
				usersSharedWith.forEach(user => {
					if (currentUserId === user.userId) return;

					const foundUser =
						Object.values(usersByEmail).find(
							userByEmail => userByEmail.userid === user.userId
						) ||
						Object.values(usersByIds).find(userById => userById.userid === user.userId);

					if (foundUser) {
						const { userFirstName, userSirName, userid } = foundUser;
						const fullName = `${userFirstName} ${userSirName}`;
						const userId = userid ?? '';
						return sharedWithInstances.push({
							label: fullName,
							value: userId
						});
					}
					const foundCollaborator = collaborators.find(
						collarator => collarator.userId === user.userId
					);
					if (foundCollaborator) {
						const { userFirstName, userSirName, userId } = foundCollaborator;
						const fullName = `${userFirstName} ${userSirName}`;
						return sharedWithInstances.push({
							label: fullName,
							value: userId
						});
					}
				});
			}
			if (projectsSharedWith && isShareWithProjects) {
				sharedProjectsSelect.forEach(project => {
					const currentProject = projectId?.toString() === project.toString();

					if (currentProject) return;
					const foundProject = allProjects.find(
						projectSelect => projectSelect.value === project.toString()
					);
					if (foundProject) {
						const { label, value } = foundProject;
						sharedWithInstances.push({
							label,
							value
						});
					}
				});
			}

			const uniqueSharedInstances = sharedWithInstances.filter(
				(selectValue, index, array) =>
					array.findIndex(element => element.value === selectValue.value) === index
			);
			setSharedWith(uniqueSharedInstances);
		}
	}, [
		Object.values(usersByEmail).length,
		Object.values(usersByIds).length,
		usersSharedWith,
		projectsSharedWith,
		loading
	]);

	function shareTemplateHandler() {
		shareTemplate({ users: usersSharedWith, projects: projectsSharedWith });
	}

	function onValueSelectHandler(selectedValue: Nullable<string>) {
		if (selectedValue) {
			const user = users?.find(user => user.value === selectedValue);
			const project = allProjects?.find(project => project.value === selectedValue);
			const selectedObject = isShareWithUser ? user : project;
			const emailAlreadyUsed = userEmailsSharedWith.includes(
				selectedValue.trim().toLowerCase()
			);
			const emailNotAllowed = currentUserEmail === selectedValue.trim().toLowerCase();
			if (!selectedObject) {
				if (!emailNotAllowed && !emailAlreadyUsed) {
					setFetchNewCollaborator(selectedValue.trim().toLowerCase());
				}
			} else {
				const userWithReadAccess = usersSharedWith.find(
					userSharedWith => userSharedWith.userId === selectedObject.value
				)?.accessRead;
				const projectWithReadAccess = projectsSharedWith.find(
					projectSharedWith =>
						projectSharedWith.projectId.toString() === selectedObject.value
				)?.accessRead;
				if (!userWithReadAccess && !projectWithReadAccess) {
					shareWithInstance({
						instanceId: selectedObject.value,
						usersShare: isShareWithUser
					});
				}
			}
		}
	}

	const users: SelectValue[] = collaborators.flatMap(collaborator => {
		const { userFirstName, userSirName, userId, emailAddress } = collaborator;

		if (userId === currentUserId) return [];

		const label =
			userFirstName && userSirName ? `${userFirstName} ${userSirName}` : emailAddress;

		const user: SelectValue = {
			label,
			value: userId
		};

		return user;
	});

	const allProjectsWitoutCurrent = allProjects.filter(({ value: id }) => id !== projectId);

	const items =
		shareLevel === TemplateShareLevel.ShareWithUsers ? users : allProjectsWitoutCurrent;

	const modalTitle = translate(({ templates }) =>
		isShareWithUser ? templates.shareWithUsers : templates.shareWithProjects
	);
	const listTitle = translate(({ templates }) =>
		isShareWithUser ? templates.usersList : templates.projectsList
	);

	const hasUsersChanges = isShareWithUser
		? !isEqual(usersSharedWith, initialUsersSharedWith)
		: undefined;
	const hasProjectChanges = isShareWithProjects
		? !isEqual(projectsSharedWith, initialProjectsSharedWith)
		: undefined;
	const hasChanges = hasUsersChanges ?? hasProjectChanges ?? false;

	const primaryButtonLabel = translate(({ buttons }) =>
		hasChanges ? buttons.share : buttons.done
	);

	const hasSharingListWithActive = !!(
		usersSharedWith.find(userSharedWith =>
			sharedWith.find(
				sharedWith =>
					sharedWith.value === userSharedWith.userId && userSharedWith.accessRead === true
			)
		) ||
		projectsSharedWith.find(projectSharedWith =>
			sharedWith.find(
				sharedWith =>
					projectSharedWith.projectId.toString() === sharedWith.value &&
					projectSharedWith.accessRead === true
			)
		)
	);

	return (
		<>
			<Modal
				title={modalTitle}
				visible={!!templateId}
				primary={{
					label: primaryButtonLabel,
					disabled: loading && !sharingTemplate,
					loading: sharingTemplate,
					onClick: hasChanges ? shareTemplateHandler : () => setTemplateId(null)
				}}
				onClose={() => {
					setTemplateId(null);
					setSharedWith([]);
				}}
				close
			>
				<Container>
					<CreatableSelect
						items={items}
						allowCreate={isShareWithUser}
						placeholder={translate(({ templates }) => templates.selectOrSearch)}
						onValueSelected={selectedValue => onValueSelectHandler(selectedValue)}
						formatCreateLabel={userInput =>
							`${translate(({ templates }) => templates.searchFor)} "${userInput}"`
						}
					/>
					{loading ? (
						<Loader primary />
					) : hasSharingListWithActive ? (
						<>
							<ListHeader>{listTitle}</ListHeader>
							<ReceipientsList>
								<>
									{/* SHARE WITH USERS */}
									{isShareWithUser &&
										!loading &&
										sharedWith.map((recipient, index) => {
											const collaborator = collaborators.find(
												collaborator =>
													collaborator.userId === recipient.value
											);

											const userById = usersByIds[recipient.value];
											const user = collaborator || userById;

											const hasReadAccess = usersSharedWith.find(
												userAccess => userAccess.userId === recipient.value
											)?.accessRead;

											if (user && hasReadAccess) {
												const { userFirstName, userSirName, emailAddress } =
													user;

												return (
													<Receipient key={recipient.value + index}>
														<UserCard.Basic
															userId={
																collaborator
																	? collaborator.userId
																	: userById.userid
																	? userById.userid
																	: null
															}
															userData={{
																userFirstName,
																userSirName,
																emailAddress
															}}
														/>
														<Icon
															svg={Svgs.Add}
															variant={v => v.button}
															onClick={() => {
																unshareWithInstance({
																	instanceId: recipient.value
																});
																setUserEmailsSharedWith(state =>
																	state.filter(
																		email =>
																			email !== emailAddress
																	)
																);
															}}
															rotate={45}
														/>
													</Receipient>
												);
											}

											return null;
										})}

									{/* SHARE WITH PROJECT */}
									{isShareWithProjects &&
										sharedWith.map(recipient => {
											const hasReadAccess = projectsSharedWith.find(
												projectAccess =>
													projectAccess.projectId.toString() ===
													recipient.value
											)?.accessRead;

											if (!hasReadAccess) return null;

											return (
												<Receipient key={recipient.value}>
													<Typography.Paragraph>
														{recipient.label}
													</Typography.Paragraph>
													<Icon
														svg={Svgs.Add}
														variant={v => v.button}
														onClick={() => {
															unshareWithInstance({
																instanceId: recipient.value
															});
															setSharedWith(state =>
																state.filter(
																	project =>
																		project.value !==
																		recipient.value
																)
															);
														}}
														rotate={45}
													/>
												</Receipient>
											);
										})}
								</>
							</ReceipientsList>
						</>
					) : (
						<SvgWrapper>
							<Svgs.EmptyShareList />
						</SvgWrapper>
					)}
				</Container>
			</Modal>
		</>
	);
}

import { useDispatch, useSelector } from 'hooks/utils';
import { setRevisionId, SetRevisionIdAction } from 'store/data/revisions';
import { LocalOperationResult } from 'hooks/store/types';
import { ActionPayload } from 'store/types';

type Data = string | null;
type Handler = (revisionId: ActionPayload<SetRevisionIdAction>['revisionId']) => void;

export function useRevisionId(): LocalOperationResult<Data, Handler> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.revisions.metadata.revisionId);

	function handler(revisionId: ActionPayload<SetRevisionIdAction>['revisionId']) {
		dispatch(setRevisionId({ revisionId }));
	}

	return [data, handler];
}

import { useEffect, useState } from 'react';
import { Auth, PubSub } from 'aws-amplify';
import { Iot } from 'aws-sdk';
import { Credentials } from '@aws-sdk/types';
import {
	useLogin,
	useNotificationsTopicName,
	useNotifications,
	useNewestNotification,
	useNewestNotificationsHandler,
	useAddQueueNotification
} from 'hooks/store';
import { useActiveModals } from 'hooks/ui';
import { NewNotification, NotificationBodyType } from 'store/data/notifications';
import { useRouteMatch } from 'hooks/navigation';
import { ROUTES } from 'types';

const { REACT_APP_REGION, REACT_APP_AWS_API_VERSION, REACT_APP_PUBSUB_POLICY_NAME } = process.env;

const AWS_ENDPOINT = 'https://iot.' + REACT_APP_REGION + '.amazonaws.com';

interface PubSubscriber {
	unsubscribe: () => void;
}

export function NotificationsProvider() {
	const [{ data: loggedIn }] = useLogin();

	const [activeModals] = useActiveModals();

	const createOrUpdateEntry = useRouteMatch([ROUTES.CreateEntry, ROUTES.UpdateEntry]);

	const [pubSubscriber, setPubSubscriber] = useState<PubSubscriber>();

	const [{ data: topicName }, getTopicName] = useNotificationsTopicName();

	const [, getNotificationHistory] = useNotifications();

	const [, setNewestNotificationsFromQueue] = useNewestNotification();
	const [{ data: queueNotifications }, addQueueNotification] = useAddQueueNotification();

	// handling newest notifications (ex. refetching of api data for particular notifications)
	useNewestNotificationsHandler();

	const [isPolicyAttached, setIsPolicyAttached] = useState<boolean>(false);

	/**
	 * Happens only the first time every new user logs in - in order for the user to
	 * receive notifications, the pubsub policy needs to be attached to user's identity ID.
	 * @param id
	 * @param policyName
	 */
	function attachPolicy(id: string, policyName: string, credentials: Credentials) {
		const IoT = new Iot({
			region: REACT_APP_REGION,
			apiVersion: REACT_APP_AWS_API_VERSION,
			endpoint: AWS_ENDPOINT,
			credentials
		});
		const params = {
			policyName,
			target: id
		};

		IoT.attachPolicy(params, err => {
			if (err) {
				if (err.code !== 'ResourceAlreadyExistsException') {
					console.log('Error attaching policy: ' + err);
				} else {
					// if resource exists, then the policy was already attached
					setIsPolicyAttached(true);
				}
			} else {
				// console.log('Successfully attached policy');
				setIsPolicyAttached(true);
			}
		});
	}

	useEffect(() => {
		if (loggedIn) {
			getTopicName();
			getNotificationHistory(true);
		} else {
			// unsubscribe
			if (pubSubscriber) pubSubscriber.unsubscribe();

			setIsPolicyAttached(false);
		}
	}, [loggedIn]);

	useEffect(() => {
		if (topicName) {
			Auth.currentCredentials().then(credentials => {
				// Calling Iot to get policy and attaching with cognito identity
				attachPolicy(credentials.identityId, REACT_APP_PUBSUB_POLICY_NAME, credentials);
			});
		}
	}, [topicName]);

	useEffect(() => {
		if (isPolicyAttached) {
			setPubSubscriber(
				PubSub.subscribe(topicName!).subscribe({
					next: (data: any) => {
						if (data.value && data.value.new_notification) {
							// adding newest notifications in a queue to be handled
							addQueueNotification(data.value.notification);
							getNotificationHistory(true);
						}
					},
					error: (error: any) => {
						console.error('error subscribing to ! ', topicName, ' ', error);
					}
				})
			);
		}
	}, [isPolicyAttached]);

	// handling queue notifications
	useEffect(() => {
		if (queueNotifications && queueNotifications.length) {
			if (activeModals.length) {
				// if modals are open, handle only the allowed notifications
				// SubscriptionInvitation and SubscriptionInvitationRemoval
				const allowedNewNotifications: NewNotification[] = [];
				queueNotifications.forEach(n => {
					if (
						n.type === NotificationBodyType.SubscriptionInvitation ||
						n.type === NotificationBodyType.SubscriptionInvitationRemoved
					)
						allowedNewNotifications.push(n);
				});
				if (allowedNewNotifications.length)
					setNewestNotificationsFromQueue(allowedNewNotifications);
			} else {
				const allowedNewNotifications: NewNotification[] = [];
				queueNotifications.forEach(n => {
					// if the notification is ProjectEntryAdd we only handle it if we are not on the Create/Update entry page
					if (
						n.type !== NotificationBodyType.ProjectEntryAdd ||
						(n.type === NotificationBodyType.ProjectEntryAdd && !createOrUpdateEntry)
					)
						allowedNewNotifications.push(n);
				});
				if (allowedNewNotifications.length)
					setNewestNotificationsFromQueue(allowedNewNotifications);
			}
		}
	}, [activeModals, queueNotifications, createOrUpdateEntry]);

	return null;
}

export enum ProjectType {
	CORE = 'CORE',
	PROM = 'PROM'
}

export enum ProjectFilterType {
	Date = 'date',
	Integer = 'integer',
	String = 'string',
	Status = 'status'
}

export enum ProjectStatus {
	Ongoing = 'ongoing',
	OnHold = 'onhold',
	Ended = 'ended'
}

export enum ProjectOwnership {
	Own = 'own',
	SharedWithMe = 'sharedWithMe',
	All = 'all'
}

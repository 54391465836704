import { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { ResizableCardDnDAutomatic, RenderDelayed } from 'components/Account';

import {
	DropCardPlaceholder,
	DropToBottom,
	DropToLeft,
	DropToRight,
	EmptyLayout,
	LineMarker,
	RelativeContainer,
	RowLineBottom,
	RowLineTop
} from '../../style';
import { Button } from 'components/UI/Interactables/Button';
import {
	SetSizePayload,
	CardDraggableItem,
	CoordinatePayload,
	CardDraggableResizableProps,
	WidthSizes,
	HeightSizes
} from 'components/Account/AdminDashboard/AdminDashboardAutomatic';
import { WidgetWidthSize } from 'store/data/projectDashboard';

interface Props {
	cardsRows: Array<CardDraggableResizableProps[]>;
	index: number;
	widthSizes: WidthSizes;
	heightSizes: HeightSizes;
	totalCards: number;
	isResizing: boolean;
	group: {
		index: number;
		groupId: string;
	};
	setElementsOrder: (coordinates: CoordinatePayload) => void;
	setDimensions: (payload: SetSizePayload) => void;
	setIsResizing: (isResizing: boolean) => void;
	combineLayout: (coordinates: CoordinatePayload) => void;
	expandCardNewRow: (coordinates: CoordinatePayload) => void;
	removeCard: (index: number) => void;
}

enum DraggableItemType {
	Card = 'card'
}

export function CardsSharedLayoutRows({
	cardsRows,
	widthSizes,
	heightSizes,
	index,
	totalCards,
	isResizing,
	group,
	setElementsOrder,
	setDimensions,
	setIsResizing,
	combineLayout,
	expandCardNewRow,
	removeCard
}: Props) {
	const [cardAutoResize, setCardAutoResize] = useState<string | null>(null);

	const elementValueRef = useRef<HTMLDivElement>(null);
	const mainRef = useRef<HTMLDivElement>(null);

	const invisRefRight = useRef<HTMLDivElement>(null);
	const invisRefLeft = useRef<HTMLDivElement>(null);
	const invisRefRowRight = useRef<HTMLDivElement>(null);

	const invisRefNewLineTop = useRef<HTMLDivElement>(null);
	const invisRefNewLineBottom = useRef<HTMLDivElement>(null);

	const cardIds: string[] = [];

	cardsRows.forEach(cardsRow => cardsRow.forEach(card => cardIds.push(card.cardId)));

	// DROP FOR LEFT
	const [{ leftHandlerId, isOverCurrentLeft }, dropLeft] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverLeft: monitor.isOver(),
			isOverCurrentLeft: monitor.isOver({ shallow: true }),
			leftHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { index: sourceIndex } = item;

			const destinationIndex = index;

			setElementsOrder({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group,
					droppedInMain: true
				}
			});
		}
	});

	// DROP FOR RIGHT
	const [{ isDragging, rightHandlerId, isOverCurrentRight }, dropRight] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isDragging: !monitor.getItem(),
			isOverRight: monitor.isOver(),
			isOverCurrentRight: monitor.isOver({ shallow: true }),
			rightHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			console.log({ item });

			const { index: sourceIndex } = item;
			const destinationIndex = index < sourceIndex ? index + 1 : index;

			setElementsOrder({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: destinationIndex,
					group,
					droppedInMain: true
				}
			});
		}
	});

	const [{ newLineTopHandlerId, isOverCurrentNewLineTop }, dropNewLineTop] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentNewLineTop: monitor.isOver(),
			newLineTopHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			expandCardNewRow({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	const [{ newLineBottomHandlerId, isOverCurrentNewLineBottom }, dropNewLineBottom] = useDrop<
		any,
		any,
		any
	>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentNewLineBottom: monitor.isOver(),
			newLineBottomHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			expandCardNewRow({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	// DROP FOR BOTTOM
	const [{ bottomHandlerId, isOverCurrentBottom }, dropBottom] = useDrop<any, any, any>({
		accept: DraggableItemType.Card,
		collect: monitor => ({
			isOverBottom: monitor.isOver(),
			isOverCurrentBottom: monitor.isOver() && monitor.canDrop(),
			bottomHandlerId: monitor.getHandlerId()
		}),
		drop(item: CardDraggableResizableProps, monitor) {
			if (!monitor.isOver()) return;

			const { index: sourceIndex } = item;

			combineLayout({
				// dragged
				dragged: {
					index: sourceIndex,
					// @ts-ignore
					group: item.group
				},
				// dropped
				dropped: {
					index: index,
					group
				}
			});
		}
	});

	dropNewLineTop(invisRefNewLineTop);
	dropNewLineBottom(invisRefNewLineBottom);

	dropBottom(invisRefRowRight);

	dropLeft(invisRefLeft);
	dropRight(invisRefRight);

	const commonChildren = (
		<>
			<DropToLeft
				ref={invisRefLeft}
				data-handler-id={leftHandlerId}
				splitLayout
				style={{ zIndex: 10000 }}
			>
				<LineMarker isHovered={isOverCurrentLeft} isDraggingInMain={true} />
			</DropToLeft>
			<DropToRight
				ref={invisRefRight}
				data-handler-id={rightHandlerId}
				isResizing={isResizing}
				splitLayout
				style={{ zIndex: 10000 }}
			>
				<LineMarker isHovered={isOverCurrentRight} isDraggingInMain={true} />
			</DropToRight>
		</>
	);

	return (
		<div ref={mainRef} style={{ height: '66.4rem' }}>
			{index === 0 && (
				<RelativeContainer>
					<RowLineTop
						ref={invisRefNewLineTop}
						data-handler-id={newLineTopHandlerId}
						index={10000 + totalCards - index}
						isHovered={isOverCurrentNewLineTop}
					>
						{cardsRows[0][0].cardName}
					</RowLineTop>
				</RelativeContainer>
			)}
			<div style={{ position: 'relative' }}>
				{/* DRAG N DROP LINE INDICATOR */}
				<div>{commonChildren}</div>
				{cardsRows &&
					cardsRows.map((cardsRow: CardDraggableResizableProps[], rowIndex: number) => (
						<div
							ref={elementValueRef}
							key={group.groupId + rowIndex}
							style={{ display: 'flex' }}
						>
							{cardsRow.length &&
								cardsRow.map((card, colIndex) => {
									const lockCard = !!(
										cardsRow.length === 1 &&
										rowIndex === 1 &&
										colIndex === 0 &&
										cardIds.includes(cardAutoResize ?? '') &&
										cardAutoResize &&
										cardsRows[0][0].cardId !== cardAutoResize
									);
									return (
										<ResizableCardDnDAutomatic
											totalCards={totalCards}
											key={card.cardId}
											index={index}
											card={card}
											widthSizes={widthSizes}
											heightSizes={heightSizes}
											isResizing={isResizing}
											group={{ ...group, colIndex, rowIndex }}
											noRowDropzones
											lockCard={lockCard}
											cardAutoResize={cardAutoResize}
											setElementsOrder={setElementsOrder}
											setDimensions={setDimensions}
											setCardAutoResize={setCardAutoResize}
											setIsResizing={setIsResizing}
											combineLayout={combineLayout}
											expandCardNewRow={expandCardNewRow}
											removeCard={removeCard}
										/>
									);
								})}

							{cardsRow.length === 1 &&
								cardsRow[0].widthSize === WidgetWidthSize.small && (
									<div style={{ width: '100%' }}>
										{!cardAutoResize && (
											<div style={{ width: '100%' }}>
												<RenderDelayed>
													<EmptyLayout
														visible={!cardAutoResize && isDragging}
													>
														<Button
															title={'+'}
															variant={v => v.outline}
															// onClick={setFillLayoutVisible}
														/>
													</EmptyLayout>
												</RenderDelayed>
											</div>
										)}
										<DropToBottom
											visible={!isDragging && !isResizing && !cardAutoResize}
											ref={invisRefRowRight}
											data-handler-id={bottomHandlerId}
										>
											<DropCardPlaceholder
												smallCard={
													cardsRow[0].widthSize === WidgetWidthSize.small
												}
												isHovered={isOverCurrentBottom}
											/>
										</DropToBottom>
									</div>
								)}
						</div>
					))}
			</div>
			<RelativeContainer>
				<RowLineBottom
					ref={invisRefNewLineBottom}
					data-handler-id={newLineBottomHandlerId}
					isHovered={isOverCurrentNewLineBottom}
					style={{ bottom: '-0.1rem' }}
				>
					{cardsRows[1][0].cardName}
				</RowLineBottom>
			</RelativeContainer>
		</div>
	);
}

import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';

import { searchUsersByEmails } from 'store/data/accounts';

type HandlerInput = Parameters<typeof searchUsersByEmails>;

export function useSearchUserByEmail(): LocalOperation<(...input: HandlerInput) => void> {
	const dispatch = useDispatch();

	function handler(...input: HandlerInput) {
		dispatch(searchUsersByEmails(...input));
	}

	return handler;
}

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store/utils/useActivity';
import {
	selectRolesData,
	selectAreRolesFetched,
	ActionTypes,
	getRoles,
	RolesData
} from 'store/data/roles';
import { useDispatch, useSelector } from 'hooks/utils';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

interface Props {
	lazy?: boolean;
}

export function useRoles(options?: Props): OperationResult<RolesData> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};

	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const data = useSelector(state => selectRolesData(state.data.roles));
	const fetched = useSelector(state => selectAreRolesFetched(state.data.roles));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_ROLES, { projectId });

	useEffect(() => {
		if (!loading && !error && !fetched && isProjectValid && !lazy) {
			handler();
		}
	}, [loading, error, fetched, isProjectValid]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getRoles());
	}

	return [{ data, loading, error, fetched }, handler];
}

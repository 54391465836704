import { ProjectStatus } from 'types/data/projects/constants';
import { ProjectCard, ProjectCardOptions } from 'components/Projects/Project';
import { TableInstance } from 'react-table';
import { ProjectData, ProjectStatusData } from 'store/data/projects';
import { CollaboratorPermissions } from 'store/data/collaborators';
import { ProjectSort } from 'store/data/projects';
import { StringMap } from 'types/index';
import { Option } from '../ProjectsTable';
import { Container, CardContainer } from './ProjectsGrid.style';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Select } from 'components/UI/Interactables/Select';
import { Spacer } from 'components/UI/Spacer';
import { useMatchProms } from 'hooks/navigation';
import { useTranslation, useActiveProjectStatus, useProjectsSort } from 'hooks/store';

interface Props extends ProjectCardOptions {
	instance: TableInstance<ProjectData>;
	statusItems: ProjectStatusData[];
	translatedStatus: StringMap;
	ids: string[];
	projectOptions: (projectOwner?: string, userAcces?: CollaboratorPermissions) => Option[];
	onClick: (projectId: string, userAcces?: CollaboratorPermissions) => void;
}

enum SortItems {
	Number = 'projects.sortByNumber',
	Name = 'projects.sortByName',
	Date = 'projects.sortByDate'
}

export function ProjectsGrid({
	instance,
	ids,
	statusItems,
	translatedStatus,
	onReadMore,
	projectOptions,
	onClick
}: Props) {
	const { translate } = useTranslation();
	const matchProms = useMatchProms();
	const [status, setActiveStatus] = useActiveProjectStatus();
	const [sort, setSortByDate] = useProjectsSort();

	const translatedSort: StringMap = {
		[ProjectSort.byName]: translate(({ projects }) => projects.sortByName),
		[ProjectSort.byDate]: translate(({ projects }) => projects.sortByDate),
		[ProjectSort.byNumber]: translate(({ projects }) => projects.sortByNumber)
	};

	const sortItems = [SortItems.Number, SortItems.Name, SortItems.Date];

	function handleSort(item: string) {
		// BY NUMBER
		if (item === sortItems[0]) setSortByDate(ProjectSort.byNumber);
		// BY NANE
		if (item === sortItems[1]) setSortByDate(ProjectSort.byName);
		// BY DATE
		if (item === sortItems[2]) setSortByDate(ProjectSort.byDate);
	}

	function handleView(item: string) {
		// ALL
		if (item === statusItems[0]) setActiveStatus(null);
		// ON GOING
		if (item === statusItems[1]) setActiveStatus(ProjectStatus.Ongoing);
		// ON HOLD
		if (item === statusItems[2]) setActiveStatus(ProjectStatus.OnHold);
		// ENDED
		if (item === statusItems[3]) setActiveStatus(ProjectStatus.Ended);
	}

	function getShowingTitle() {
		const promOrProject = matchProms
			? translate(dict => dict.promsList.proms)
			: translate(dict => dict.projects.projects);

		const numberAndItems = `${ids.length} ${
			status && translatedStatus[status] ? translatedStatus[status].toLowerCase() : ''
		} ${promOrProject}`;

		const title = translate(dict => dict.tableLists.showingNumberAndItems, false, {
			numberAndItems
		});

		return title;
	}

	return (
		<>
			<Flex justify={j => j.between} wrap>
				<Typography.Caption id="projectsPageCount">{getShowingTitle()}</Typography.Caption>

				<Flex>
					<Typography.Caption marginOffset={{ x: 0.8 }}>
						{translate(dict => dict.terms.sort)}
					</Typography.Caption>
					<Select
						title={translatedSort[sort]}
						items={format => format(sortItems)}
						onSelect={item => item.value && handleSort(item.value)}
						width={10}
						offset={{ top: 2, right: 0 }}
						translateChildren
						type={s => s.Tag}
					/>
					<Typography.Caption marginOffset={{ x: 0.8 }}>
						{translate(dict => dict.terms.view)}
					</Typography.Caption>
					<Select
						title={
							status && translatedStatus[status]
								? translatedStatus[status]
								: translate(dict => dict.terms.all)
						}
						items={format => format(statusItems)}
						onSelect={item => item.value && handleView(item.value)}
						width={10}
						offset={{ top: 2, right: 0 }}
						translateChildren
						type={s => s.Tag}
					/>
				</Flex>
			</Flex>

			<Spacer size={s => s.xs} />
			<Container>
				<CardContainer>
					{ids.map(id => (
						<ProjectCard
							projectId={id}
							key={`project-${id}`}
							projectOptions={projectOptions}
							showEntries={!matchProms}
							onClick={onClick}
							onReadMore={onReadMore}
						/>
					))}
				</CardContainer>

				{instance.page.length === 0 && (
					// this will be changed
					<Typography.H4 style={{ width: '100%' }} alignCenter>
						{matchProms
							? translate(dict => dict.promsList.noPromsToShow)
							: translate(dict => dict.projectList.noProjectsToShow)}
					</Typography.H4>
				)}
			</Container>
		</>
	);
}

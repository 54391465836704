import { Table } from 'components/UI/Table';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { GetLinearRegressionStatisticsOutput } from 'api/data/analyses';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useVariablesData } from 'hooks/store';

import { PStatistic } from '../../../AnalysisStatistics';
import { computeCellValue } from 'helpers/analysis';
import { VariablesData } from 'store/data/variables';
import { useEffect } from 'react';
import { useMutableState, usePrevious } from 'hooks/utils';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Typography } from 'components/UI/Typography';

interface Props {
	results: GetLinearRegressionStatisticsOutput | null;
}

export function CorrelationsLinearRegressionTable({ results }: Props) {
	const variablesData = useVariablesData();
	return (
		<AnalysisStatistic>
			{results && <Result results={results} variablesData={variablesData} />}
		</AnalysisStatistic>
	);
}

function Result({ results, variablesData }: Props & { variablesData: VariablesData }) {
	const { translate } = useTranslation();
	const { variablesMap } = variablesData;

	const [draftExpanded, setDraftExpanded] = useMutableState<{ [key: string]: boolean }>({});

	const prevResults = usePrevious(results);
	useEffect(() => {
		if (results && prevResults !== results) {
			let newValues = {};
			results.data?.forEach(value => {
				newValues = {
					...newValues,
					...(value.groupValue ? { [value.groupValue]: true } : {})
				};
			});
			setDraftExpanded(newValues);
		}
	}, [results, prevResults]);

	return (
		<Flex column>
			<Spacer size={s => s.m} />
			<Typography.H6 marginOffset={{ bottom: 0.8 }}>
				{translate(dict => dict.analysis.statistics.linearRegression.name)}
			</Typography.H6>
			{results?.data?.map((value, index) => {
				const isGrouped = 'groupValue' in value;
				const isVisible =
					isGrouped && draftExpanded[value.groupValue as keyof typeof draftExpanded];

				return (
					<Flex column key={`linear-reggresion-${index}`}>
						<>
							{index > 0 ? <Spacer size={s => s.m} /> : <></>}
							{/* EXPANDABLE TOGGLE */}
							{isGrouped && (
								<Flex marginOffset={{ bottom: isVisible ? 0.8 : undefined }}>
									<Icon
										svg={isVisible ? Svgs.ArrowDown : Svgs.ArrowRight}
										size={s => s.l}
										marginOffset={{ right: 0.8 }}
										onClick={() =>
											setDraftExpanded(state => {
												state[
													value.groupValue as keyof typeof draftExpanded
												] =
													!state[
														value.groupValue as keyof typeof draftExpanded
													];
											})
										}
									/>
									<Typography.Paragraph
										fontweight={w => w.bold}
										onClick={() =>
											setDraftExpanded(state => {
												state[
													value.groupValue as keyof typeof draftExpanded
												] =
													!state[
														value.groupValue as keyof typeof draftExpanded
													];
											})
										}
										clickable
									>
										{value.groupValue}
									</Typography.Paragraph>
								</Flex>
							)}
							<Spacer size={s => s.s} />
							{(isVisible || !isGrouped) &&
								(value.error ? (
									<AnalysisStatistic.Error>{value.error}</AnalysisStatistic.Error>
								) : (
									<Table.Responsive horizontalScroll>
										<Table fullWidth>
											<Table.Head>
												<Table.Row>
													<Table.Column></Table.Column>
													<Table.Column>
														{translate(
															dict =>
																dict.analysis.analyses
																	.logisticRegression.tableColumns
																	.estimate
														)}
													</Table.Column>
													<Table.Column>
														{translate(
															dict =>
																dict.analysis.analyses
																	.logisticRegression.tableColumns
																	.standardError
														)}
													</Table.Column>
													<Table.Column>
														{translate(
															dict =>
																dict.analysis.analyses
																	.logisticRegression.tableColumns
																	.zValue
														)}
													</Table.Column>
													<Table.Column>
														{translate(
															dict =>
																dict.analysis.analyses
																	.logisticRegression.tableColumns
																	.pValue
														)}
													</Table.Column>
													<Table.Column>
														{translate(
															dict =>
																dict.analysis.analyses
																	.logisticRegression.tableColumns
																	.lowerCI
														)}
													</Table.Column>
													<Table.Column>
														{translate(
															dict =>
																dict.analysis.analyses
																	.logisticRegression.tableColumns
																	.upperCI
														)}
													</Table.Column>
												</Table.Row>
											</Table.Head>
											<Table.Body>
												{value.coefficients?.map((coef, idx) => (
													<Table.Row key={`table-coef-${idx}`}>
														<Table.Cell width={14} noWrap>
															{coef.variable?.name &&
															variablesMap[coef.variable.name]
																? variablesMap[coef.variable.name]
																		.label
																: coef.type}
														</Table.Cell>
														<Table.Cell width={14} noWrap>
															{computeCellValue(
																coef?.estimate ?? null
															)}
														</Table.Cell>
														<Table.Cell width={14} noWrap>
															{'standardError' in coef
																? computeCellValue(
																		coef!
																			.standardError as number
																  )
																: 'N/A'}
														</Table.Cell>
														<Table.Cell width={14} noWrap>
															{computeCellValue(
																coef?.statistic ?? null
															)}
														</Table.Cell>
														<Table.Cell width={14} noWrap>
															<PStatistic
																formatNaN={false}
																value={
																	'pValue' in coef && coef.pValue
																		? coef.pValue
																		: 'N/A'
																}
															/>
														</Table.Cell>
														<Table.Cell width={14} noWrap>
															{'lowerCI' in coef
																? computeCellValue(
																		coef!.lowerCI ?? null
																  )
																: 'N/A'}
														</Table.Cell>
														<Table.Cell width={14} noWrap>
															{'upperCI' in coef
																? computeCellValue(
																		coef!.upperCI ?? null
																  )
																: 'N/A'}
														</Table.Cell>
													</Table.Row>
												))}
												{value.coefficients === null && value.error ? (
													<AnalysisStatistic.Error>
														{value.error}
													</AnalysisStatistic.Error>
												) : null}
											</Table.Body>
										</Table>
									</Table.Responsive>
								))}
						</>
					</Flex>
				);
			})}
		</Flex>
	);
}

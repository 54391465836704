import {
	GetTimeCourseResponseV2,
	GetGroupedTimeCourseData,
	GetUngroupedTimeCourseData,
	TimeCourseResultsV2,
	TimeCourseResultsDataV2,
	TimeCourseGroupedValuesV2
} from 'api/data/analyses';

// type guards
function isGroupedTimeCourseData(
	data: GetTimeCourseResponseV2['data']['result']
): data is GetGroupedTimeCourseData[] {
	return 'length' in data;
}

export function parseTimeCourseResponse(res: GetTimeCourseResponseV2): TimeCourseResultsV2 | null {
	const result: TimeCourseResultsV2 = {
		data: null
	};

	if (isGroupedTimeCourseData(res.data.result)) {
		const groupedData = res.data.result;

		result.data = {
			groupedTimeCourse: []
		} as TimeCourseResultsDataV2;
		groupedData.forEach(data => {
			(result.data as TimeCourseGroupedValuesV2).groupedTimeCourse.push({
				group: {
					groupVariable: data.groupIdentifiers[0].variable.name,
					groupValue: data.groupIdentifiers[0].value
				},
				timeCourse: {
					...data.values
				}
			});
		});

		return result;
	} else if (!isGroupedTimeCourseData(res.data.result)) {
		const ungroupedData = { ...res.data.result } as GetUngroupedTimeCourseData;

		return { data: { timeCourse: { ...ungroupedData } } };
	}

	return null;
}

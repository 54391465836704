import { useState, useRef } from 'react';
import { ProjectFile } from 'api/data/documents';
import { InputType } from 'types/index';
import { Row } from './RenameDocumentModal.style';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation, useRenameDocument } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useEffectOnce, useCompletedAction } from 'hooks/utils';

interface Props {
	file: ProjectFile;
	onClose: () => void;
}

export function RenameDocumentModal({ file, onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [error, setError] = useState('');
	const [inputValue, setInputValue] = useState(file.fileLabel);

	const [{ loading: renaming, error: renamingError }, renameDocument] = useRenameDocument();

	const inputRef = useRef<HTMLInputElement>(null);

	/**
	 * Select all text on-open
	 * improve UX - save user time
	 */
	useEffectOnce(() => inputRef.current?.select());

	useCompletedAction(renaming, renamingError, () => {
		setNotification({
			message: translate(({ documents }) => documents.renameMessage)
		});
		onClose();
	});

	function onFormSubmit() {
		if (!primaryButtonDisabled && !(renaming || renamingError)) {
			renameDocument(file.fileId, `${inputValue}.${file.mimeType}}`);
		}
	}

	function onChange(value: string) {
		setInputValue(value);

		if (value === '')
			setError(
				translate(
					({ projects }) =>
						projects.createAndImport.generics.previewVariables.labelRequired
				)
			);
		else setError('');
	}

	const hasChanged = inputValue !== file.fileLabel;
	const primaryButtonDisabled = renamingError || !hasChanged || !!error;

	return (
		<Modal
			size={s => s.s}
			title={translate(dict => dict.documents.renameModal.title)}
			primary={{
				label: translate(dict => dict.buttons.rename),
				loading: renaming,
				disabled: primaryButtonDisabled,
				onClick: onFormSubmit
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			visible
			close
		>
			<Row>
				<Input
					ref={inputRef}
					label={translate(dict => dict.documents.renameModal.inputLabel)}
					autoComplete="off"
					type={InputType.Text}
					name="fileLabel"
					error={error}
					value={inputValue}
					onChange={e => onChange(e.target.value)}
					onSubmit={onFormSubmit}
				/>
				<Typography.Paragraph
					marginOffset={{ left: 1.6, bottom: 1 }}
				>{`.${file.mimeType}`}</Typography.Paragraph>
			</Row>
		</Modal>
	);
}

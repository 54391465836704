import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';

interface Props {
	visible: boolean;
	onPrimaryClick: () => void;
	onNeutralClick: () => void;
}

export function RevisionChangesModal({ visible, onPrimaryClick, onNeutralClick }: Props) {
	const { translate } = useTranslation();

	const modalTitle = translate(dict => dict.versionChanges.historyDrawerWarning);
	const primaryWarningText = translate(dict => dict.versionChanges.warningText);
	const openHistoryDrawerConfirmationQuestion = translate(
		dict => dict.versionChanges.warningQuestion
	);
	const primaryLabel = translate(dict => dict.buttons.continue);
	const neutralLabel = translate(dict => dict.buttons.cancel);

	return (
		<Modal
			size={s => s.s}
			visible={visible}
			title={modalTitle}
			primary={{
				label: primaryLabel,
				warning: true,
				onClick: onPrimaryClick
			}}
			neutral={{
				label: neutralLabel,
				onClick: onNeutralClick
			}}
			onClose={onNeutralClick}
			enterAsPrimaryOnClick
			close
		>
			<Typography.Paragraph multiline>
				{primaryWarningText}
				{'\n\n'}
				<strong>{openHistoryDrawerConfirmationQuestion}</strong>
			</Typography.Paragraph>
		</Modal>
	);
}

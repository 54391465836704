import { createSelector } from 'reselect';

import { State } from './types';

function getUserAddons(state: State) {
	return state;
}

function getSubscriptionAddons(state: State) {
	return state.subscriptionAddonsByCode;
}

export const selectUserAddons = createSelector([getUserAddons], addons => {
	return {
		userAddons: addons.userAddons,
		activeUserAddons: addons.activeUserAddons
	};
});

export const selectSubscriptionAddons = createSelector(
	[getSubscriptionAddons],
	subscriptionAddonsByCode => subscriptionAddonsByCode
);

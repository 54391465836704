import type { Action } from 'types/store/types';
import type { GenericMap } from 'types/index';
import { ProjectType } from 'types/data/projects/constants';

export enum ActionTypes {
	GET_NOTIFICATION_HISTORY = 'data/notifications/GET_NOTIFICATION_HISTORY',
	SHOW_ONLY_UNREAD_NOTIFICATIONS = 'data/notifications/SHOW_ONLY_UNREAD_NOTIFICATIONS',
	GET_TOPIC_NAME = 'data/notifications/GET_TOPIC_NAME',
	ADD_QUEUE_NOTIFICATION = 'data/notifications/ADD_QUEUE_NOTIFICATION',
	SET_NEWEST_NOTIFICATIONS_FROM_QUEUE = 'data/notifications/SET_NEWEST_NOTIFICATIONS_FROM_QUEUE',
	SET_NOTIFICATIONS_AS_READ = 'data/notifications/SET_NOTIFICATIONS_AS_READ',
	SET_NOTIFICATION_ACTION_STATE = 'data/notifications/SET_NOTIFICATION_ACTION_STATE',

	// SETTINGS
	GET_PROJECTS_LABELS = 'data/notifications/GET_PROJECTS_LABELS',
	GET_PROJECT_NOTIFICATION_SETTINGS = 'data/notifications/GET_PROJECT_NOTIFICATION_SETTINGS',
	UPDATE_PROJECT_NOTIFICATION_SETTINGS = 'data/notifications/UPDATE_PROJECT_NOTIFICATION_SETTINGS',

	// ACTIONS
	ACCEPT_SUBSCRIPTION_INVITATION_NOTIFICATION = 'data/notifications/ACCEPT_SUBSCRIPTION_INVITATION_NOTIFICATION',
	REJECT_SUBSCRIPTION_INVITATION_NOTIFICATION = 'data/notifications/REJECT_SUBSCRIPTION_INVITATION_NOTIFICATION'
}

export enum NotificationBodyType {
	// SUBSCRIPTION
	SubscriptionPlanCreation = 'subscription_plan_creation',
	SubscriptionPlanUpdate = 'subscription_plan_update',
	SubscriptionPlanUpdateBatch = 'subscription_plan_update_batch',
	SubscriptionPlanChange = 'subscription_plan_change',
	SubscriptionCancellation = 'subscription_cancellation',
	SubscriptionInvitation = 'subscription_invitation',
	SubscriptionInvitationUserAnswer = 'subscription_invitation_user_answer',
	SubscriptionInvitationOwnerAnswer = 'subscription_invitation_owner_answer',
	SubscriptionInvitationRemoved = 'subscription_invitation_cancelled',
	SubscriptionPaymentFailed = 'payment_failed',
	SubscriptionUserRemoval = 'subscription_user_removal',
	SubscriptionUserRemovalByDowngrade = 'subscription_user_removal_by_downgrade',

	// PROJECT
	ProjectEntryAdd = 'project_entry_add',
	ProjectEntryAddBatch = 'project_entry_add_batch',
	ProjectOwnershipTransferred = 'project_ownership_transferred',
	ProjectOwnershipTransferredMulti = 'project_ownership_transferred_multi'
}

// General notifications labels that don't require any kind of extra verification
export const NotificationBodyTypeLabel = {
	// SUBSCRIPTION
	[NotificationBodyType.SubscriptionPlanCreation]:
		'notifications.message.subscriptionPlanCreation',
	[NotificationBodyType.SubscriptionPlanChange]: 'notifications.message.subscriptionPlanChange',
	[NotificationBodyType.SubscriptionPlanUpdate]: 'notifications.message.subscriptionPlanUpdate',
	[NotificationBodyType.SubscriptionPlanUpdateBatch]:
		'notifications.message.subscriptionPlanUpdateBatch',
	[NotificationBodyType.SubscriptionCancellation]: 'notifications.message.subscriptionExpiration',
	[NotificationBodyType.SubscriptionPaymentFailed]:
		'notifications.message.subscriptionPaymentFailed',
	[NotificationBodyType.SubscriptionUserRemoval]: 'notifications.message.subscriptionUserRemoval',
	[NotificationBodyType.SubscriptionUserRemovalByDowngrade]:
		'notifications.message.subscriptionUserRemovalByDowngrade',

	// PROJECT
	[NotificationBodyType.ProjectEntryAdd]: 'notifications.message.projectEntryAdd',
	[NotificationBodyType.ProjectEntryAddBatch]: 'notifications.message.projectEntryAddBatch',
	// OWNERSHIP TRANSFER
	[NotificationBodyType.ProjectOwnershipTransferred]:
		'notifications.message.projectOwnershipTransferred',
	[NotificationBodyType.ProjectOwnershipTransferredMulti]:
		'notifications.message.projectOwnershipTransferredMulti'
};

export enum NotificationTagLabel {
	SubscriptionLabel = 'subscription_management',
	ProjectLabel = 'project_management'
}

export enum NotificationActionState {
	NoAction = 'no_action',
	ActionAccepted = 'action_accepted',
	ActionRejected = 'action_rejected',
	ActionRequired = 'action_required'
}

export interface NewNotification {
	type: NotificationBodyType;
	body: NotificationBody;
	actionState: NotificationActionState;
	isRead: boolean;
	tagLabel: NotificationTagLabel;
	timestamp: string;
}
export interface Notification extends NewNotification {
	notificationId: string;
}

export interface NotificationBody {
	accepted?: boolean;

	// common
	firstName?: string;
	lastName?: string;
	userId?: string;
	amount?: number;

	// subscription
	from?: string;
	to?: string;
	days?: number | string;
	invitedFirstName?: string;
	invitedLastName?: string;
	invitedUserId?: string;
	ownerFirstName?: string;
	ownerLastName?: string;
	subscriptionName?: string;

	// project
	projectName?: string;
	projectNames?: string[];
	projectId?: string;
	projectType?: ProjectType;
	datasetId?: string;
	datasetEntryId?: string;
	projectGroupId?: string;
}

export interface State {
	topicName: string | null;
	notificationHistory: NotificationHistoryType;
	newestNotifications: NewNotification[];
	queueNotifications: NewNotification[];

	fullNotificationHistoryFetched: boolean;
	showOnlyUnread: boolean;
	settings: {
		byProjectId: GenericMap<ProjectNotificationSettings>;
	};
	projectsLabels: GenericMap<string>;
}

export type ProjectNotificationSettings = {
	projectEntryAdd: {
		email: boolean;
		app: boolean;
	};
};

export type NotificationHistoryType = {
	byNotificationId: GenericMap<Notification> | null;
};

export type GetNotificationHistoryAction = Action<
	ActionTypes.GET_NOTIFICATION_HISTORY,
	{
		notifications: Notification[];
		clearPrevious: boolean;
		fullNotificationHistoryFetched: boolean;
	}
>;

export type ShowOnlyUnreadNotificationsAction = Action<
	ActionTypes.SHOW_ONLY_UNREAD_NOTIFICATIONS,
	{
		showOnlyUnread: boolean;
	}
>;

export type SetNotificationsAsReadAction = Action<
	ActionTypes.SET_NOTIFICATIONS_AS_READ,
	{
		notificationIds: string[];
		areRead: boolean;
	}
>;

export type SetNotificationActionStateAction = Action<
	ActionTypes.SET_NOTIFICATION_ACTION_STATE,
	{
		notificationIds: string[];
		actionState: NotificationActionState;
	}
>;

export type GetTopicNameAction = Action<
	ActionTypes.GET_TOPIC_NAME,
	{
		topicName: string;
	}
>;

export type AddQueueNotificationAction = Action<
	ActionTypes.ADD_QUEUE_NOTIFICATION,
	{
		notification: NewNotification;
	}
>;

export type SetNewestNotificationsFromQueueAction = Action<
	ActionTypes.SET_NEWEST_NOTIFICATIONS_FROM_QUEUE,
	{
		notifications: NewNotification[];
	}
>;

export type AcceptSubcriptionInvitationNotificationAction = Action<
	ActionTypes.ACCEPT_SUBSCRIPTION_INVITATION_NOTIFICATION,
	{
		userId: string;
	}
>;

export type DeclineSubcriptionInvitationNotificationAction = Action<
	ActionTypes.REJECT_SUBSCRIPTION_INVITATION_NOTIFICATION,
	{
		userId: string;
	}
>;

export type GetProjectNotificationSettingsAction = Action<
	ActionTypes.GET_PROJECT_NOTIFICATION_SETTINGS,
	{
		projectId: string;
		settings: ProjectNotificationSettings;
	}
>;

export type UpdateProjectNotificationSettingsAction = Action<
	ActionTypes.UPDATE_PROJECT_NOTIFICATION_SETTINGS,
	{
		projectId: string;
		settings: ProjectNotificationSettings;
	}
>;

export type GetProjectsLabelsAction = Action<
	ActionTypes.GET_PROJECTS_LABELS,
	{
		projectsLabels: GenericMap<string>;
	}
>;

export type Actions =
	| GetNotificationHistoryAction
	| ShowOnlyUnreadNotificationsAction
	| SetNewestNotificationsFromQueueAction
	| AddQueueNotificationAction
	| SetNotificationActionStateAction
	| SetNotificationsAsReadAction
	| GetTopicNameAction
	| AcceptSubcriptionInvitationNotificationAction
	| DeclineSubcriptionInvitationNotificationAction
	| GetProjectNotificationSettingsAction
	| UpdateProjectNotificationSettingsAction
	| GetProjectsLabelsAction;

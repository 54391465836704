import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.2rem 1.6rem;
	width: 100%;
	/* Colors.text.error, 10% */
	background: rgba(255, 88, 93, 0.1);
	border-radius: 0.4rem;
`;

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	.clear-button {
		white-space: nowrap;
		font-weight: normal;
		color: ${Colors.text.error};
	}
`;

export const FiltersMenu = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	padding: 2.4rem;
	border-radius: 0.4rem;
	margin-top: 0.4rem;
	box-shadow: ${Shadows.hover};
	background: ${Colors.white};
	top: 100%;
	right: 0;
	z-index: 100;

	.entires-warning-switch {
		min-width: 20rem;
	}
	.entires-warning-switch:nth-child(1) {
		margin-bottom: 1.7rem;
	}
`;

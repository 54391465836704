import { useState, useEffect } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { isEqual } from 'lodash';
import { Svgs, Colors } from 'environment';
import { FormGroup, FormElements } from 'store/data/forms';
import { DynamicFormValues } from 'store/data/entries';
import { SetState } from 'types/index';
import { FormInput } from '../FormInput';
import { CardHeader, GroupTitleWrapper, Icon } from './GroupCardHeader.style';
import { getFormInputsToWatch, getFormInputsValidation } from 'helpers/entries';
import { getFormGroupVariableNames } from 'helpers/forms';
import { withMemo } from 'helpers/HOCs';
import { buildVariablesMap } from 'helpers/variables';
import { useGetValidationErrors, useVariablesByName } from 'hooks/store';
import { useDeepCompareMemo } from 'hooks/utils';

interface GroupStatus {
	areInputsFilled: boolean;
	hasErrors: boolean;
}

interface Props {
	formGroup: FormGroup;
	elements: FormElements;
	disabled?: boolean;
	groupExpanded: boolean;
	usedInFormDesigner?: boolean;
	disableFormContext?: boolean;
	hasChanges?: boolean;
	onChange: (value: boolean) => void;
}

function GroupCardHeaderComponent({
	formGroup,
	elements,
	disabled,
	groupExpanded,
	usedInFormDesigner,
	disableFormContext,
	hasChanges,
	onChange
}: Props) {
	const formContext = useFormContext() as UseFormReturn<DynamicFormValues> | undefined;

	const [groupStatus, setGroupStatus] = useState<GroupStatus>({
		areInputsFilled: false,
		hasErrors: false
	});

	const { areInputsFilled, hasErrors } = groupStatus;

	function handleGroupExpanded() {
		onChange(!groupExpanded);
	}

	function onCardHeaderClick() {
		if (!usedInFormDesigner) handleGroupExpanded();
	}

	function onIconClick() {
		if (usedInFormDesigner) handleGroupExpanded();
	}

	const svg = hasErrors ? Svgs.AlertCircle : areInputsFilled ? Svgs.CheckCircle : null;
	const iconColor = hasErrors
		? Colors.text.error
		: areInputsFilled
		? Colors.vibrantGreen
		: undefined;

	return (
		<CardHeader
			hasChanges={hasChanges}
			cursorPointer={!usedInFormDesigner}
			onClick={onCardHeaderClick}
		>
			<GroupTitleWrapper>
				<Icon
					svg={Svgs.ChevronDown}
					onClick={onIconClick}
					rotate={groupExpanded ? 180 : 0}
					propagate
				/>
				<Icon
					svg={Svgs.Folder}
					size={s => s.m}
					paddingOffset={{ y: 0.4 }}
					marginOffset={{ x: 0.8 }}
					propagate
				/>

				<FormInput
					formGroup={formGroup}
					disabled={disabled}
					usedInFormDesigner={usedInFormDesigner}
				/>
			</GroupTitleWrapper>

			{svg && (
				<Icon
					svg={svg}
					colors={{ color: iconColor }}
					size={s => s.m}
					marginOffset={{ top: 0.4 }}
				/>
			)}

			{formContext && !disableFormContext && (
				<GroupCardHeaderStatus
					formGroup={formGroup}
					elements={elements}
					groupStatus={groupStatus}
					setGroupStatus={setGroupStatus}
				/>
			)}
		</CardHeader>
	);
}

export const GroupCardHeader = withMemo(GroupCardHeaderComponent, [
	'formGroup',
	'elements',
	'groupExpanded',
	'onChange'
]);

interface GroupCardHeaderStatusProps {
	formGroup: FormGroup;
	elements: FormElements;
	groupStatus: GroupStatus;
	setGroupStatus: SetState<GroupStatus>;
}

function GroupCardHeaderStatusComponent({
	formGroup,
	elements,
	groupStatus,
	setGroupStatus
}: GroupCardHeaderStatusProps) {
	const validationErrors = useGetValidationErrors();

	const {
		getValues,
		formState: { errors }
	} = useFormContext<DynamicFormValues>();

	const groupVariableNames = useDeepCompareMemo(
		() => getFormGroupVariableNames(formGroup, elements),
		[formGroup, elements]
	);

	const groupVariables = useVariablesByName(groupVariableNames, {
		initial: true
	});

	const groupVariablesMap = buildVariablesMap(groupVariables);

	const inputsToWatch = getFormInputsToWatch({
		variableNames: groupVariableNames,
		variablesMap: groupVariablesMap
	});

	const values = getValues(inputsToWatch).reduce<DynamicFormValues>((acc, value, idx) => {
		acc[inputsToWatch[idx]] = value;

		return acc;
	}, {});

	const newGroupStatus = () =>
		getFormInputsValidation({
			variableNames: groupVariableNames,
			values,
			variablesMap: groupVariablesMap,
			validationErrors,
			errors
		});

	useEffect(() => {
		if (!isEqual(groupStatus, newGroupStatus)) setGroupStatus(newGroupStatus);
	}, [newGroupStatus]);

	return null;
}

const GroupCardHeaderStatus = withMemo(GroupCardHeaderStatusComponent, [
	'formGroup',
	'elements',
	'groupStatus'
]);

import styled from 'styled-components';

export const ConfigureCategory = styled.div`
	position: relative;
	margin-top: 0.8rem;
	margin-left: 2rem;
	max-width: 52.4rem;
`;

export const DependantVariable = styled.div`
	width: 100%;
	max-width: 32rem;
`;

export const SupplierVariable = styled.div`
	width: 34rem;
`;

export const Container = styled.div``;

import { Variable, VariableUniquenessType } from 'api/data/variables';
import { Gap } from 'components/UI/Gap';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { RadioButton } from 'components/UI/Interactables/RadioButton';
import { Switch } from 'components/UI/Interactables/Switch';
import { Typography } from 'components/UI/Typography';
import { DATA_TYPES_OPTIONS, ENTRY_TYPES_OPTIONS } from 'consts';
import { Colors } from 'environment';
import { InputType, SelectItem } from 'types/index';
import { CategoryValuesBasic } from '../CategoryValuesBasic';
import { PreviewCategoriesModal } from './PreviewCategoriesModal';
import { useTranslation } from 'hooks/store';
import { useModalState } from 'hooks/ui';
import { EntryVariableType, VariableType } from 'types/data/variables/constants';

interface Props {
	variable: Variable;
}

export function VariableFields({ variable }: Props) {
	const { translate } = useTranslation();

	const previewCategoriesModal = useModalState();

	const entryTypeSelectItems: SelectItem[] = ENTRY_TYPES_OPTIONS.map(item => ({
		label: translate(() => item.label),
		value: item.value
	}));

	const dataTypeSelectItems: SelectItem[] = DATA_TYPES_OPTIONS.map(item => ({
		label: translate(() => item.label),
		value: item.value
	}));

	return (
		<Gap marginGap={{ bottom: 1.6 }} notLastChild>
			<Input
				type={InputType.Text}
				label={translate(dict => dict.variableFields.variableLabel)}
				value={variable.label}
				readOnly
			/>
			<Input
				type={InputType.Textarea}
				value={variable.description}
				label={translate(dict => dict.variableFields.description)}
				placeholder={translate(dict => dict.variableFields.noDescription)}
				readOnly
			/>
			<CreatableSelect
				label={translate(dict => dict.variableFields.entryType)}
				value={entryTypeSelectItems.find(item => item.value === variable.entryType)}
				items={entryTypeSelectItems}
				readOnly
			/>
			<CreatableSelect
				label={translate(dict => dict.variableFields.dataType)}
				value={dataTypeSelectItems.find(item => item.value === variable.type)}
				items={dataTypeSelectItems}
				readOnly
			/>
			<Switch
				label={translate(dict => dict.variableFields.personalData)}
				description={translate(dict => dict.variableFields.personalDataInfo)}
				disabled={variable.obligatory}
				on={variable.personalData}
			/>

			{variable.type === VariableType.Unique && (
				<>
					<Typography.Caption
						title={`Unique options:`}
						color={Colors.text.main}
						fontweight={w => w.medium}
					>
						{`Unique options:`}
					</Typography.Caption>

					<div>
						<RadioButton
							label={translate(
								dict => dict.variableFields.uniquenessConfig.manual.label
							)}
							selected={variable.uniquenessType === VariableUniquenessType.Manual}
							onSelect={() => null}
						/>
						<Typography.Caption paddingOffset={{ left: 2.4 }}>
							{translate(
								dict => dict.variableFields.uniquenessConfig.manual.description
							)}
						</Typography.Caption>
					</div>
					<div>
						<RadioButton
							label={translate(
								dict => dict.variableFields.uniquenessConfig.sequence.label
							)}
							selected={variable.uniquenessType === VariableUniquenessType.Sequence}
							onSelect={() => null}
						/>
						<Typography.Caption paddingOffset={{ left: 2.4 }}>
							{translate(
								dict => dict.variableFields.uniquenessConfig.sequence.description
							)}
						</Typography.Caption>
					</div>
					<div>
						<RadioButton
							label={translate(
								dict => dict.variableFields.uniquenessConfig.uuid.label
							)}
							selected={variable.uniquenessType === VariableUniquenessType.UUID}
							onSelect={() => null}
						/>
						<Typography.Caption paddingOffset={{ left: 2.4 }}>
							{translate(
								dict => dict.variableFields.uniquenessConfig.uuid.description
							)}
						</Typography.Caption>
					</div>
				</>
			)}

			{variable.entryType === EntryVariableType.Entry && (
				<Switch
					label={translate(dict => dict.variableFields.obligatory)}
					description={translate(
						dict => dict.variablesPage.addVariableFields.description
					)}
					disabled={variable.personalData}
					on={variable.obligatory}
				/>
			)}

			{/* CATEGORY VARIABLE */}
			{[VariableType.Category, VariableType.CategoryMultiple].includes(variable.type) && (
				<>
					{variable.entryType === EntryVariableType.Entry && (
						<Switch
							label={translate(
								dict => dict.variableFields.optimizeForManyValues.label
							)}
							description={translate(
								dict => dict.variableFields.optimizeForManyValues.description
							)}
							on={variable.optimizeForManyValues}
						/>
					)}

					<CategoryValuesBasic
						variable={variable}
						initialVariable={variable}
						error={''}
						previewEnabled={variable.fixedCategories}
						onPreviewClick={previewCategoriesModal.open}
						onToggle={() => null}
						onChange={() => null}
						onSubmit={() => null}
						readOnly
					/>
				</>
			)}

			{previewCategoriesModal.visible && (
				<PreviewCategoriesModal
					variable={variable}
					onClose={previewCategoriesModal.close}
				/>
			)}
		</Gap>
	);
}

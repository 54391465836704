import { LanguageType, StorageKeys } from 'types/index';
import { State } from './types';

let language = localStorage.getItem(StorageKeys.UserLanguage);
// TODO: remove if here when all DB data is scripted NO->NB.
if (language === LanguageType.Norwegian_Deprecated) {
	language = LanguageType.Norwegian;
}

const initialState: State = {
	language: (language as LanguageType) || LanguageType.English,
	translations: null
};

export default initialState;

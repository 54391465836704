import { useEffect, useState } from 'react';
import axios from 'axios';
import { ImportType, ACCEPTED_DOCUMENT_TYPES, ACCEPTED_IMPORT_TYPES } from 'types/index';
import {
	BrowseButton,
	ImportedFileContainer,
	ImportedFileName,
	ProjectImportRoot,
	ToggleDisplayMessage,
	TranslateContainer,
	ProgressBar,
	ProgressContainer,
	ProgressWrapper
} from './ImportDropzone.style';
import {
	DropzoneType,
	OnFileDropOptions,
	useImportDropzone
} from 'hooks/store/ui/useImportDropzone';
import { Translate } from 'components/UI/Translate';
import { useTranslation, useUploadDocument, useUploadUrl, useProjectId } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';
import { useAlerts } from 'hooks/ui';
import { FileImportErrors } from 'types/data/projects/types';

interface Props {
	importType?: ImportType;
	importFileName: string;
	documentImport?: boolean;
	maxFileSize?: number;
	getSuggestedVariables?: boolean;
	setImportFileName: (fileName: string) => void;
	handleFinishedLoading?: () => void;
	handleUploadingError?: () => void;
	handleApiFileImportErrors?: (errors: FileImportErrors) => void;
	setShouldReset?: () => void;
	resetFileImportErrors?: () => void;
}

export function ImportDropzone({
	importType,
	importFileName,
	documentImport,
	maxFileSize,
	handleFinishedLoading,
	handleUploadingError,
	setImportFileName,
	handleApiFileImportErrors,
	resetFileImportErrors,
	setShouldReset,
	getSuggestedVariables = true
}: Props) {
	const { translate } = useTranslation();
	const [startFakeLoading, setStartFakeLoading] = useState(false);
	const [fakeloading, setFakeLoading] = useState(false);
	const [finishedLoading, setFinishedLoading] = useState(false);
	const [{ loading: uploading, error: uploadError }] = useUploadDocument();
	const [{ loading: uploadingUrl, error: uploadUrlError }, { uploadUrl }] = useUploadUrl({
		onAPIError: apiImportErrorHandler,
		importType
	});

	const [projectId] = useProjectId();
	const { setError } = useAlerts();

	const source = axios.CancelToken.source();

	useEffectOnce(() => {
		return () => {
			source.cancel(translate(dict => dict.importDropzone.stopConversionOrUnmount));
		};
	});

	/**
	 * HANDLES FAKE LOADING == START ==
	 */
	useEffect(() => {
		if (!startFakeLoading) return;

		setFakeLoading(true);
		const timeout = setTimeout(() => setFakeLoading(false), 1500);

		return () => clearTimeout(timeout);
	}, [startFakeLoading]);

	useEffect(() => {
		if (uploadingUrl || uploading) setStartFakeLoading(true);
	}, [uploadingUrl, uploading]);
	/**
	 * HANDLES FAKE LOADING == END ==
	 */

	useEffect(() => {
		if (
			!fakeloading &&
			!uploadingUrl &&
			!uploading &&
			!uploadUrlError &&
			!uploadError &&
			importFileName
		) {
			const timeout = setTimeout(() => {
				handleFinishedLoading && handleFinishedLoading();
				documentImport && setImportFileName('');
				setFinishedLoading(true);
			}, 500);

			return () => clearTimeout(timeout);
		}
	}, [fakeloading, uploadingUrl, uploading, uploadUrlError, uploadError]);

	// HANDLE ERROR
	useEffect(() => {
		if (uploadUrlError || uploadError) {
			handleUploadingError && handleUploadingError();
			setImportFileName('');
			setStartFakeLoading(false);
		}
	}, [uploadUrlError, uploadError]);

	function onFileDrop({ file, isBinaryFile }: OnFileDropOptions) {
		if (projectId) {
			resetFileImportErrors && resetFileImportErrors();
			if (!documentImport && !ACCEPTED_IMPORT_TYPES.includes(file.type)) {
				handleUploadingError && handleUploadingError();
				setImportFileName('');
				setStartFakeLoading(false);
				setError({
					message: translate(dict => dict.errors.api.projects.fileFormatUnsupported)
				});
				return;
			}
			uploadUrl(parseInt(projectId), file, isBinaryFile, getSuggestedVariables);
		}
	}

	function apiImportErrorHandler(errors: FileImportErrors) {
		handleApiFileImportErrors && handleApiFileImportErrors(errors);
	}

	const [
		{
			data: { getRootProps, getInputProps, isDragActive }
		}
	] = useImportDropzone({
		acceptedFileTypes: documentImport ? ACCEPTED_DOCUMENT_TYPES : ACCEPTED_IMPORT_TYPES,
		maxFileSize,
		dropzoneType: documentImport ? DropzoneType.DOCUMENT : DropzoneType.DATASET,
		disabled: uploadingUrl || uploading,
		onFileDrop,
		setImportFileName: (name: string) => {
			setShouldReset && setShouldReset();
			setImportFileName(name);
		}
	});

	return (
		<ProjectImportRoot {...getRootProps()} isDragActive={isDragActive}>
			<input data-testid="datasetImportInput" {...getInputProps()} />
			{importFileName ? (
				<>
					<ImportedFileContainer>
						{translate(dict => dict.importDropzone.selectedFile)}
						&nbsp;
						<ImportedFileName>{importFileName}</ImportedFileName>
					</ImportedFileContainer>

					<ProgressContainer>
						<ProgressWrapper>
							<ProgressBar
								finished={fakeloading || uploadingUrl || uploading ? false : true}
								width={
									finishedLoading
										? '100%'
										: fakeloading || uploadingUrl || uploading
										? '85%'
										: '0%'
								}
							/>
						</ProgressWrapper>
					</ProgressContainer>
				</>
			) : (
				<TranslateContainer>
					<Translate transKey={dict => dict.importDropzone.message}>
						<BrowseButton data-test-id="browseButton" />
						<ToggleDisplayMessage visible={!!importFileName} />
					</Translate>
				</TranslateContainer>
			)}
		</ProjectImportRoot>
	);
}

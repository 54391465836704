import { useState, useEffect } from 'react';
import { SubscriptionUser, UserLicenceModel, UserRole } from 'store/account/subscription';

import { SubscriptionUsersList } from './List';
import {
	AddSubscriptionUserModal,
	DeactivateEnterpriseUser,
	EditSubscriptionUserModal,
	EditSubscriptionUserTab,
	ResetSubscriptionUserPasswordModal
} from './Modals';
import { AppendToLicenceContainer } from './SubscriptionUser.style';
import { Switch } from 'components/UI/Interactables/Switch';
import { Button } from 'components/UI/Interactables/Button';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useChangeUserLicenceModel,
	useSubscriptionCancelUserInvitation,
	useSubscription,
	useSubscriptionBundleFlag,
	useLicenceModels
} from 'hooks/store';
import { ReactivateEnterpriseUser } from './Modals/ReactivateEnterpriseUser';

export interface CancelUserInvitePayload {
	id: string;
	email: string;
	licenceModel: UserLicenceModel;
}

interface Props {
	showAddUserModal: boolean;
	setShowAddUserModal: (showAddUserModal: boolean) => void;
}

export function SubscriptionUsers({ showAddUserModal, setShowAddUserModal }: Props) {
	const { translate } = useTranslation();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const [{ loading: changeLicenceLoading }, changeLicenceType] = useChangeUserLicenceModel();
	const [
		{ loading: cancellingUserInvitation, error: errorCancellingUserInvitation },
		cancelUserSubscriptionInvitation
	] = useSubscriptionCancelUserInvitation();
	const [
		{
			data: {
				users,
				details,
				subscriptionTypes: {
					isNonSubscriber,
					isLedidiFree,
					isLedidiFreeTrial,
					isLedidiCollaborator,
					isLedidiCore,
					isLedidiEnterprise
				},
				userTypesAndRoles: { ledidiEnterprise, isPaidSubscriptionOwner }
			}
		}
	] = useSubscription();

	const [
		{
			data: { availableLicencesCount }
		}
	] = useLicenceModels();

	const [editUserModalProps, setEditUserModalProps] = useState<null | {
		user: SubscriptionUser;
		selectedTab?: EditSubscriptionUserTab;
	}>(null);
	const [resetUserPassword, setResetUserPassword] = useState<string | null>(null);
	const [deactivateEnterpriseUserId, setDeactivateEnterpriseUserId] = useState<string | null>(
		null
	);
	const [reactivateEnterpriseUserId, setReactivateEnterpriseUserId] = useState<string | null>(
		null
	);

	const [cancelInvitationForUser, setCancelInvitationForUser] =
		useState<CancelUserInvitePayload | null>(null);

	const [ownerAssignedToLicence, setOwnerAssignedToLicence] = useState(
		users.find(user => user.isOwner)?.licenceModel === UserLicenceModel.Full
	);

	useEffect(() => {
		if (!errorCancellingUserInvitation && !cancellingUserInvitation)
			setCancelInvitationForUser(null);
	}, [cancellingUserInvitation]);

	useEffect(() => {
		if (details) {
			const ownerHasFullLicence =
				users.find(user => user.isOwner)?.licenceModel === UserLicenceModel.Full;

			if (ownerHasFullLicence && availableLicencesCount > 0)
				setOwnerAssignedToLicence(ownerHasFullLicence);
		}
	}, [users]);

	function onEditUserClicked(user: SubscriptionUser, selectedTab?: EditSubscriptionUserTab) {
		setEditUserModalProps({ user, selectedTab });
	}

	function onCancelUserInvitation(payload: CancelUserInvitePayload) {
		setCancelInvitationForUser(payload);
	}

	function onConfirmCancelUserInvitation() {
		if (!cancelInvitationForUser) return;
		cancelUserSubscriptionInvitation(
			cancelInvitationForUser.id,
			cancelInvitationForUser.licenceModel
		);
	}

	function handleOwnerToLicence() {
		const ownerUserId = users.find(user => user.isOwner)?.userid;
		if (!ownerUserId) return;

		setOwnerAssignedToLicence(!ownerAssignedToLicence);
		changeLicenceType({
			licenceModel:
				users.find(user => user.isOwner)?.licenceModel === UserLicenceModel.Full
					? UserLicenceModel.OneOwnedOneShared
					: UserLicenceModel.Full,
			userId: ownerUserId
		});
	}

	function onAddUserClicked() {
		setShowAddUserModal(true);
	}

	function allowEditEnterpriseUser(userRole: UserRole) {
		if (ledidiEnterprise.isOrganizationAdmin) return true;

		if (
			ledidiEnterprise.isSuperAdmin &&
			(userRole === UserRole.Admin || userRole === UserRole.User)
		)
			return true;

		if (ledidiEnterprise.isAdmin && userRole === UserRole.User) return true;

		return false;
	}

	if (isLedidiFree || isLedidiFreeTrial || isNonSubscriber) return <></>;

	const canAddUser = availableLicencesCount > 0 && !isLedidiCollaborator;
	const assignOneLicenceEnabled =
		(availableLicencesCount > 0 &&
			users.find(user => user.isOwner)?.licenceModel ===
				UserLicenceModel.OneOwnedOneShared) ||
		users.find(user => user.isOwner)?.licenceModel === UserLicenceModel.Full;
	return (
		<>
			<AppendToLicenceContainer alignLeft={isLedidiEnterprise} alignRight={canAddUser}>
				{canAddUser && !isLedidiEnterprise && (
					<Button
						title={translate(({ accountUM }) => accountUM.subscription.addNewUser)}
						onClick={onAddUserClicked}
						variant={v => v.outline}
						minWidth={20}
					/>
				)}
				<>
					{isLedidiCore && !subscriptionBundleFlag && (
						<Switch
							label={translate(
								({ accountUM }) => accountUM.subscription.assignOneLicenceToYourself
							)}
							disabled={changeLicenceLoading || !assignOneLicenceEnabled}
							on={
								users.find(user => user.isOwner)?.licenceModel ===
								UserLicenceModel.Full
							}
							onChange={handleOwnerToLicence}
						/>
					)}
				</>
				{/* {hasAvailableLicenceQuantity && (
					<Button
						title={'Import users'}
						variant={v => v.secondary}
						marginOffset={hasAvailableLicenceQuantity && { left: 2.2 }}
						minWidth={20}
					/>
				)} */}
			</AppendToLicenceContainer>

			<Spacer size={s => s.s} />
			{
				<SubscriptionUsersList
					isOwner={isPaidSubscriptionOwner}
					isLedidiEnterprise={isLedidiEnterprise}
					users={users}
					ownerAssignedToLicence={
						!!users.find(
							user =>
								user.isOwner &&
								(user.licenceModel === UserLicenceModel.Full ||
									user.licenceModel === UserLicenceModel.Collaborator)
						)
					}
					allowEditEnterpriseUser={allowEditEnterpriseUser}
					onEditUser={onEditUserClicked}
					onCancelUserInvitation={onCancelUserInvitation}
					onResetPassword={userId => setResetUserPassword(userId)}
					onDeactivateEnterpriseUser={userId => setDeactivateEnterpriseUserId(userId)}
					onReactivateEnterpriseUser={userId => setReactivateEnterpriseUserId(userId)}
				/>
			}
			{cancelInvitationForUser && (
				<Modal
					title={translate(
						({ accountUM }) => accountUM.subscription.removeUserInvitation
					)}
					visible={!!cancelInvitationForUser}
					onClose={() => setCancelInvitationForUser(null)}
					neutral={{
						label: translate(({ buttons }) => buttons.cancel),
						disabled: cancellingUserInvitation,
						onClick: () => setCancelInvitationForUser(null)
					}}
					size={s => s.s}
					primary={{
						onClick: onConfirmCancelUserInvitation,
						label: translate(({ buttons }) => buttons.confirm),
						loading: cancellingUserInvitation,
						disabled: cancellingUserInvitation
					}}
					closeOnBackdrop
					enterAsPrimaryOnClick
				>
					<Typography.Paragraph>
						{translate(
							({ accountUM }) => accountUM.subscription.cancelUserInvitationWarning
						)}
						&nbsp;
						<b>{cancelInvitationForUser.email}</b>?
					</Typography.Paragraph>
				</Modal>
			)}

			{/* ADD USER MODAL */}
			{showAddUserModal && (
				<AddSubscriptionUserModal
					visible={showAddUserModal}
					onClose={() => setShowAddUserModal(false)}
				/>
			)}

			{/* EDIT MODAL */}
			{editUserModalProps && (
				<EditSubscriptionUserModal
					user={editUserModalProps.user}
					selectedTab={editUserModalProps.selectedTab}
					onClose={() => setEditUserModalProps(null)}
				/>
			)}

			{/* RESET PASSWORD */}
			{resetUserPassword && (
				<ResetSubscriptionUserPasswordModal
					onClose={() => setResetUserPassword(null)}
					userId={resetUserPassword}
				/>
			)}

			{/* DEACTIVATE ENTERPRISE USER */}
			{deactivateEnterpriseUserId && (
				<DeactivateEnterpriseUser
					onClose={() => setDeactivateEnterpriseUserId(null)}
					userId={deactivateEnterpriseUserId}
				/>
			)}

			{/* REACTIVATE ENTERPRISE USER */}
			{reactivateEnterpriseUserId && (
				<ReactivateEnterpriseUser
					onClose={() => setReactivateEnterpriseUserId(null)}
					userId={reactivateEnterpriseUserId}
				/>
			)}
		</>
	);
}

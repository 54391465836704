import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DropAbove, LineMarkerHorizontal, DropBelow } from './AggregationRuleCard.style';
import { useMoveVariableSetAggregationRule } from 'hooks/store';

interface DraggableItem {
	index: number;
	ruleName: string;
}

interface Props {
	index: number;
	setName: string;
	ruleName: string;
	readOnly: boolean;
}

export function useAggregationRuleCardDragAndDrop({ index, setName, ruleName, readOnly }: Props) {
	const mainRef = useRef<HTMLDivElement>(null);
	const aboveRef = useRef<HTMLDivElement>(null);
	const belowRef = useRef<HTMLDivElement>(null);

	const draggableItemType = 'aggregation_rule';

	const [, moveVariableSetAggregationRule] = useMoveVariableSetAggregationRule();

	// DRAG - CARD
	const [{ isDragging }, drag, dragPreview] = useDrag({
		canDrag: !readOnly,
		type: draggableItemType,
		item: (): DraggableItem => ({ index, ruleName }),
		collect: monitor => ({ isDragging: monitor.isDragging() })
	});

	// DROP - ABOVE
	const [{ aboveHandlerId, isOverCurrentAbove }, dropAbove] = useDrop<any, any, any>({
		accept: draggableItemType,
		collect: monitor => ({
			aboveHandlerId: monitor.getHandlerId(),
			isOverCurrentAbove: monitor.isOver({ shallow: true })
		}),
		drop(item: DraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			const { index: sourceIndex, ruleName } = item;

			const destinationIndex = index;

			if (sourceIndex === destinationIndex) return;

			moveVariableSetAggregationRule({
				setName,
				ruleName,
				sourceIndex,
				destinationIndex
			});
		}
	});

	// DROP - BELOW
	const [{ belowHandlerId, isOverCurrentBelow }, dropBelow] = useDrop<any, any, any>({
		accept: draggableItemType,
		collect: monitor => ({
			belowHandlerId: monitor.getHandlerId(),
			isOverCurrentBelow: monitor.isOver({ shallow: true })
		}),
		drop(item: DraggableItem, monitor) {
			if (!monitor.isOver({ shallow: true })) return;

			const { index: sourceIndex, ruleName } = item;

			const destinationIndex = index < sourceIndex ? index + 1 : index;

			if (sourceIndex === destinationIndex) return;

			moveVariableSetAggregationRule({
				setName,
				ruleName,
				sourceIndex,
				destinationIndex
			});
		}
	});

	drag(mainRef);
	////////////////////
	dropAbove(aboveRef);
	dropBelow(belowRef);

	const isFirstItem = index === 0;

	const hoverIndicators = (
		<>
			{isFirstItem && (
				<DropAbove
					ref={aboveRef}
					data-handler-id={aboveHandlerId}
					isHovered={isOverCurrentAbove}
				>
					<LineMarkerHorizontal />
				</DropAbove>
			)}
			<DropBelow
				ref={belowRef}
				data-handler-id={belowHandlerId}
				isHovered={isOverCurrentBelow}
			>
				<LineMarkerHorizontal />
			</DropBelow>
		</>
	);

	return { mainRef, isDragging, dragPreview, hoverIndicators };
}

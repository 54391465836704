import styled, { css } from 'styled-components';

import { MediaQueries } from 'environment';
import { disableableComponent } from 'helpers/cssGenerators';

interface Props {
	disabled?: boolean;
	isConfigPanelOpen?: boolean;
	fullScreen?: boolean;
	activeColumn?: number;
	disableInteractions?: boolean;
}

export const Container = styled.div<Props>`
	display: flex;
	flex-direction: column;
	pointer-events: ${({ disableInteractions }) => (disableInteractions ? 'none' : 'auto')};

	height: 100%;
	min-height: 17.5rem;
	width: ${({ isConfigPanelOpen, activeColumn, fullScreen }) =>
		(isConfigPanelOpen && activeColumn === 1) || (isConfigPanelOpen && fullScreen)
			? `calc(100% - 27.5rem)`
			: '100%'};
	padding: 0.8rem;
	overflow: auto;
	height: 100%;

	${({ isConfigPanelOpen }) =>
		isConfigPanelOpen &&
		css`
			padding-right: 2rem;
		`}

	@media ${MediaQueries.maxWidth.lg} {
		width: 100%;
	}

	${({ disabled }) => disableableComponent({ disabled })};

	& > div {
		table {
			height: 100%;
		}
	}
`;

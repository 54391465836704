import { OperationResult } from 'hooks/store/types';
import { useActivity } from 'hooks/store';
import { useDispatch, useSelector } from 'hooks/utils';

import {
	selectEnterpriseRolesFetched,
	ActionTypes,
	selectEnterpriseRoles,
	getEnterpriseRoles,
	EnterpriseRole
} from 'store/account/enterprise';
import { GenericMap } from 'types/index';
import { useEffect } from 'react';

export function useEnterpriseRoles(): OperationResult<GenericMap<EnterpriseRole>> {
	const dispatch = useDispatch();

	const fetched = useSelector(state => selectEnterpriseRolesFetched(state.account.enterprise));
	const roles = useSelector(state => selectEnterpriseRoles(state.account.enterprise));

	const [{ loading, error }] = useActivity(ActionTypes.GET_ENTERPRISE_ROLES);

	useEffect(() => {
		if (!loading && !error && !fetched) {
			handler();
		}
	}, [loading, error, fetched]);

	function handler() {
		dispatch(getEnterpriseRoles());
	}

	return [{ data: roles, loading, error, fetched }, handler];
}

import { LedidiPlanCurrency, SupportedLedidiPlans } from 'store/account/payments';
import { Svgs } from 'environment';
import { AdvantagesWrapper, PlanCardWrapper, PriceWrapper, RadioButton } from './style';
import { AdvantageInfo } from '../..';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { getPriceToDisplay } from 'helpers/subscription';
import { SubscriptionName } from 'store/account/subscription';

interface Props {
	payMonthly: boolean;
	selectedSubscriptionName: SubscriptionName;
	currentSubsciption: SubscriptionName | null;
	currency: LedidiPlanCurrency;
	setSelectedSubscriptionName: (type: SupportedLedidiPlans) => void;
}

export function SelectAccountPlanTypeBundle({
	payMonthly,
	selectedSubscriptionName,
	currency,
	currentSubsciption,
	setSelectedSubscriptionName
}: Props) {
	const { translate } = useTranslation();

	const showCollaboratorPlan = currentSubsciption === SubscriptionName.LedidiCollaborator;
	const showFreeTrialPlan = currentSubsciption === SubscriptionName.LedidiFreeTrial;

	const planIconStyle = {
		height: '4.8rem',
		width: '6rem',
		marginLeft: '0.8rem',
		marginRight: '0.8rem'
	};

	return (
		<>
			<Spacer size={s => s.xs} />
			<Typography.Hint>
				{payMonthly
					? translate(({ accountUM }) => accountUM.changePlan.calculatedYearlyInfo)
					: translate(({ accountUM }) => accountUM.changePlan.calculatedMonthlyInfo)}
			</Typography.Hint>
			<Spacer size={s => s.m} />
			{showFreeTrialPlan && (
				<PlanCardWrapper
					onClick={() => setSelectedSubscriptionName(SubscriptionName.LedidiFreeTrial)}
				>
					<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
						<Flex>
							<RadioButton
								selected={
									selectedSubscriptionName === SubscriptionName.LedidiFreeTrial
								}
								onSelect={() =>
									setSelectedSubscriptionName(SubscriptionName.LedidiFreeTrial)
								}
							/>
							<Flex wrap align={a => a.center}>
								<Svgs.FreePlanIcon style={planIconStyle} />
								<div>
									<Typography.H5>
										{translate(
											({ accountUM }) =>
												accountUM.subscription.subscriptionNames
													.ledidiFreeTrial
										)}
									</Typography.H5>
									<Typography.Hint>
										{translate(
											({ accountUM }) =>
												accountUM.changePlan.ledidiFreeCaption
										)}
									</Typography.Hint>
								</div>
							</Flex>
						</Flex>
						<Flex>
							<PriceWrapper>
								<Typography.H5>
									{translate(({ accountUM }) => accountUM.changePlan.free)}
								</Typography.H5>
								<Typography.Hint>
									{translate(({ accountUM }) => accountUM.changePlan.days30)}
								</Typography.Hint>
							</PriceWrapper>
						</Flex>
					</Flex>
					<AdvantagesWrapper>
						<AdvantageInfo
							text={translate(
								({ accountUM }) => accountUM.changePlan.ledidiFreeTrialPlanAdvantage
							)}
						/>
					</AdvantagesWrapper>
				</PlanCardWrapper>
			)}
			{showCollaboratorPlan && (
				<>
					<Spacer size={s => s.m} />
					<PlanCardWrapper
						onClick={() =>
							setSelectedSubscriptionName(SubscriptionName.LedidiCollaborator)
						}
					>
						<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
							<Flex>
								<RadioButton
									selected={
										selectedSubscriptionName ===
										SubscriptionName.LedidiCollaborator
									}
									onSelect={() =>
										setSelectedSubscriptionName(
											SubscriptionName.LedidiCollaborator
										)
									}
								/>
								<Flex wrap align={a => a.center}>
									<Svgs.UsersNetwork style={planIconStyle} />
									<div>
										<Typography.H5>
											{translate(
												({ accountUM }) =>
													accountUM.subscription.subscriptionNames
														.ledidiCollaborator
											)}
										</Typography.H5>
										<Typography.Hint>
											{translate(
												({ accountUM }) =>
													accountUM.changePlan.ledidiCollaboratorCaption
											)}
										</Typography.Hint>
									</div>
								</Flex>
							</Flex>
							<Flex>
								<PriceWrapper>
									<Typography.H5>
										{getPriceToDisplay(
											SubscriptionName.LedidiCollaborator,
											payMonthly,
											currency
										)}
									</Typography.H5>
									<Typography.Hint>
										{payMonthly
											? translate(
													({ accountUM }) => accountUM.changePlan.monthly
											  )
											: translate(
													({ accountUM }) => accountUM.changePlan.yearly
											  )}
									</Typography.Hint>
								</PriceWrapper>
							</Flex>
						</Flex>

						<AdvantagesWrapper>
							<AdvantageInfo
								text={translate(
									({ accountUM }) =>
										accountUM.changePlan.ledidiCollaboratorPlanAdvantage
								)}
							/>
						</AdvantagesWrapper>
					</PlanCardWrapper>
				</>
			)}
			<Spacer size={s => s.m} />
			<PlanCardWrapper
				onClick={() => setSelectedSubscriptionName(SubscriptionName.LedidiCore)}
			>
				<Flex fullWidth wrap align={a => a.center} justify={j => j.between}>
					<Flex>
						<RadioButton
							selected={selectedSubscriptionName === SubscriptionName.LedidiCore}
							onSelect={() =>
								setSelectedSubscriptionName(SubscriptionName.LedidiCore)
							}
						/>
						<Flex wrap align={a => a.center}>
							<Svgs.UsersNetwork style={planIconStyle} />
							<div>
								<Typography.H5>
									{translate(
										({ accountUM }) =>
											accountUM.subscription.subscriptionNames.ledidiCore
									)}
								</Typography.H5>
								<Typography.Hint>
									{translate(
										({ accountUM }) => accountUM.changePlan.ledidiCoreCaption
									)}
								</Typography.Hint>
							</div>
						</Flex>
					</Flex>
					<Flex>
						<PriceWrapper>
							<Typography.H5>
								{getPriceToDisplay(
									SubscriptionName.LedidiCore,
									payMonthly,
									currency
								)}
							</Typography.H5>
							<Typography.Hint>
								{payMonthly
									? translate(
											({ accountUM }) => accountUM.changePlan.perUserPerMonth
									  )
									: translate(
											({ accountUM }) => accountUM.changePlan.perUserPerYear
									  )}
							</Typography.Hint>
						</PriceWrapper>
					</Flex>
				</Flex>

				<AdvantagesWrapper>
					<AdvantageInfo
						text={translate(
							({ accountUM }) =>
								accountUM.changePlan.ledidiCorePlanAdvantageOneLicence
						)}
					/>
				</AdvantagesWrapper>
			</PlanCardWrapper>
		</>
	);
}

import { useEffect, useMemo, useRef, useState } from 'react';

import { Svgs, Colors } from 'environment';
import type { OrganizationData, Collaborator } from 'store/data/collaborators';
import { useCollaboratorsData, useTranslation } from 'hooks/store';

import { CenteredCheckbox } from '../styles';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Table } from 'components/UI/Table';
import { Typography } from 'components/UI/Typography';
import { UserTag } from 'components/UI/UserCard/UserTag';
import { ThreeDotsMenu, Dropdown, TableRow } from '../CollaboratorRow/style';
import { getElementBounds, mergeRefs } from 'helpers/generic';
import { useOutsideClick } from 'hooks/utils';
import { useWindowSize } from 'hooks/ui';

// icon margin right
const DROPDOWN_RIGHT_OFFSET = 8;
// icon height
const DROPDOWN_TOP_OFFSET = 36;

interface Props {
	tableWidth?: number;
	organizationData: OrganizationData;
	expandedRowsData: {
		expanded: boolean;
		onExpand: () => void;
	};
	checkedRowData: {
		checked: boolean;
		partial: boolean;
		onCheck: () => void;
	};
	canSelectRow: boolean;
	isNested?: boolean;
	isDefault?: boolean;
	onClick: () => void;
	onRemove: () => void;
	renderCollaboratorRow: (collaborator: Collaborator, index: number) => React.ReactNode;
	showSubOrganization: boolean;
}

export function OrganizationRow({
	tableWidth = 0,
	organizationData,
	expandedRowsData: { expanded, onExpand },
	checkedRowData: { checked, partial, onCheck },
	canSelectRow,
	isNested,
	isDefault,
	onClick,
	onRemove,
	renderCollaboratorRow,
	showSubOrganization
}: Props) {
	const rowRef = useRef<HTMLTableRowElement | null>(null);
	const { top, right } = getElementBounds(rowRef);
	const { width: windowWidth } = useWindowSize();

	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	useOutsideClick(() => setDropdownOpen(false), [dropdownRef]);

	useEffect(() => {
		setDropdownOpen(false);
	}, [windowWidth]);

	const { name, collaborators, id } = organizationData;
	const { translate } = useTranslation();

	const { organizationsMap } = useCollaboratorsData();

	// compute collaborators count based on original data (this component receives filtered data thus it will be 0)
	const count = useMemo(() => {
		return organizationsMap[id].collaborators.length;
	}, [organizationsMap, id]);

	const hasOne = count === 1;
	const numberOf = `${count} ${
		hasOne
			? translate(dict => dict.collaborators.organisationCard.collaborator)
			: translate(dict => dict.collaborators.organisationCard.plural)
	}`;

	const SELECT_OPTIONS = [
		{
			label: translate(dict => dict.collaborators.collaboratorsTable.editGroup),
			action: onClick
		},
		{
			label: translate(dict => dict.collaborators.collaboratorsTable.deleteGroup),
			action: onRemove
		}
	];

	return (
		<>
			<TableRow
				data-testid={`organization-row-${id}`}
				ref={rowRef}
				onClick={onClick}
				active={checked || dropdownOpen}
				clickable
			>
				{canSelectRow && (
					<Table.Cell
						onClick={e => {
							e.stopPropagation();
							onCheck();
						}}
					>
						<CenteredCheckbox
							checked={checked}
							partial={!checked && partial}
							onClick={onCheck}
						/>
					</Table.Cell>
				)}
				<Table.Cell minWidth={20} maxWidth={30} title={name}>
					<Flex marginOffset={isNested && { left: 2 }}>
						<Icon
							svg={expanded ? Svgs.ArrowDown : Svgs.ArrowRight}
							size={s => s.m}
							onClick={onExpand}
						/>
						<Icon
							svg={Svgs.Building}
							size={s => s.m}
							colors={{ color: Colors.text.main }}
							marginOffset={{ x: 0.4 }}
							propagate
						/>
						<Typography.Paragraph fontweight={w => w.medium} ellipsis>
							{name}
						</Typography.Paragraph>
						{isDefault && (
							<UserTag
								marginOffset={{ left: 0.8 }}
								title={translate(dict => dict.terms.default)}
							/>
						)}
					</Flex>
				</Table.Cell>
				<Table.Cell title={numberOf}>
					<Typography.Paragraph>{numberOf}</Typography.Paragraph>
				</Table.Cell>
				{/**
				 * EMAIL
				 */}
				<Table.Cell>-</Table.Cell>
				{/**
				 * Organization
				 */}
				<Table.Cell>-</Table.Cell>
				{/**
				 * Sub organization
				 */}
				{showSubOrganization && <Table.Cell>-</Table.Cell>}
				{/**
				 * Workplace
				 */}
				<Table.Cell>-</Table.Cell>

				<td>
					<Dropdown
						menuStyles={{
							top: top + DROPDOWN_TOP_OFFSET,
							right: right - tableWidth + DROPDOWN_RIGHT_OFFSET,
							left: 'unset',
							width: 'auto'
						}}
						usePortal
						initiallyOpen={dropdownOpen}
						button
						shouldScrollIntoView
						closeOnResize
						menuClassName="dropdown-menu"
						toggleComponent={({ toggle, ref, open }) => (
							<ThreeDotsMenu
								ref={mergeRefs([dropdownRef, ref])}
								svg={Svgs.More}
								active={dropdownOpen}
								onClick={() => {
									toggle();
									setDropdownOpen(!open);
								}}
								size={s => s.l}
							/>
						)}
					>
						{SELECT_OPTIONS.map(opt => (
							<Dropdown.Item key={opt.label} onClick={opt.action}>
								{opt.label}
							</Dropdown.Item>
						))}
					</Dropdown>
				</td>
			</TableRow>
			{expanded && collaborators.map(renderCollaboratorRow)}
		</>
	);
}

import { State } from './types';

const initialState: State = {
	projectId: null,
	formId: null,
	byId: {},
	byProjectId: {},
	metadata: {
		searchTerm: ''
	}
};

export default initialState;

import { Modal } from 'components/UI/Modal';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useAPIResetEnterpriseUserPassword } from 'hooks/store/account/enterprise/useAPIResetEnterpriseUserPassword';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	userId: string | null;
	onClose: (deleted?: boolean) => void;
}

export function ResetSubscriptionUserPasswordModal({ userId, onClose }: Props) {
	const { translate } = useTranslation();
	const [{ loading: resetingPassword, error: errorResetingPassword }, resetPassword] =
		useAPIResetEnterpriseUserPassword();

	function onConfirm() {
		if (userId) resetPassword(userId);
	}

	useCompletedAction(resetingPassword, errorResetingPassword, () => onClose(true));

	return (
		<Modal
			visible={!!userId}
			title={translate(dict => dict.accountUM.subscriptionUsersDropdown.resetPassword)}
			size={s => s.s}
			primary={{
				label: translate(dict => dict.buttons.reset),
				loading: resetingPassword,
				warning: true,
				onClick: onConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			close
		>
			<Typography.Paragraph>
				{translate(dict => dict.accountUM.editSubscriptionUser.resetPasswordText)}
			</Typography.Paragraph>
			<br />
			<Typography.Paragraph>
				{translate(dict => dict.accountUM.editSubscriptionUser.proceedConfirmation)}
			</Typography.Paragraph>
		</Modal>
	);
}

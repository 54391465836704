import { useCallback } from 'react';
import { InputType } from 'types/index';
import { Title } from './styles';
import { Input } from 'components/UI/Inputs/Input';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { formatPhoneNumber, getNewPasswordValidationSchema } from 'helpers/auth';
import { useTranslation, useUser, useAccount, useCompleteFirstLogin } from 'hooks/store';
import { useReactForm } from 'hooks/ui';

interface FormValues {
	password: string;
	confirmPassword: string;
	phone: string;
}

interface Props {
	handleBack: () => void;
}

export function NewPasswordForm({ handleBack }: Props) {
	const { translate } = useTranslation();

	const user = useUser();

	const [{ loading: loadingGetAccount, error: errorGetAccount }] = useAccount();
	const [
		{ loading: loadingCompleteFirstLogin, error: errorCompleteFirstLogin },
		{ handler: completeFirstLogin, resetError: resetFirstLoginError }
	] = useCompleteFirstLogin();

	const loading = loadingCompleteFirstLogin || loadingGetAccount;
	const error = errorCompleteFirstLogin || errorGetAccount;

	let requiredAttributes: string[] = [];

	if (user && user.challengeParam && user.challengeParam.requiredAttributes) {
		requiredAttributes = user.challengeParam.requiredAttributes;
	}

	const hasPhoneNumber = requiredAttributes.includes('phone_number');

	const initialValues: FormValues = {
		password: '',
		confirmPassword: '',
		phone: ''
	};

	const { Form, trigger, register, setValue, handleSubmit, errors, isDirty, touchedFields } =
		useReactForm({
			initialValues,
			validationSchema: getNewPasswordValidationSchema(hasPhoneNumber)
		});

	const handleFormSubmit = handleSubmit(({ password, phone }) => {
		if (isDirty && !error && !loading) completeFirstLogin(password, phone);
	});

	function handleClearError() {
		if (errorCompleteFirstLogin) resetFirstLoginError();
	}

	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			handleClearError();

			const { name, value } = e.target;

			if (name === 'phone') setValue('phone', formatPhoneNumber(value));
			if (name === 'password') trigger('confirmPassword');
		},
		[errorCompleteFirstLogin]
	);

	return (
		<Form onSubmit={handleFormSubmit}>
			<Title>{translate(dict => dict.newPasswordForm.title)}</Title>

			{hasPhoneNumber && (
				<Input
					{...register('phone', {
						onChange: handleOnChange
					})}
					type={InputType.Text}
					label={translate(dict => dict.newPasswordForm.phoneNumberLabel)}
					placeholder={translate(dict => dict.newPasswordForm.phoneNumberPlaceholder)}
					error={touchedFields.phone ? errors.phone?.message : ''}
					autoFocus
				/>
			)}
			<Spacer size={s => s.xs} />
			<Input
				{...register('password', {
					onChange: handleOnChange
				})}
				type={InputType.Password}
				label={translate(dict => dict.newPasswordForm.password)}
				placeholder={translate(dict => dict.newPasswordForm.password)}
				error={touchedFields.password ? errors.password?.message : ''}
			/>
			<Spacer size={s => s.xs} />
			<Input
				{...register('confirmPassword', {
					onChange: handleOnChange
				})}
				type={InputType.Password}
				label={translate(dict => dict.newPasswordForm.confirmPassword)}
				placeholder={translate(dict => dict.newPasswordForm.confirmPassword)}
				error={touchedFields.confirmPassword ? errors.confirmPassword?.message : ''}
			/>

			<Spacer size={s => s.m} />

			<Button
				title={translate(dict => dict.newPasswordForm.update)}
				type="submit"
				loading={loading}
				hasPointer={!loading}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Button
				variant={v => v.outline}
				title={translate(dict => dict.buttons.goBack)}
				onClick={loading ? undefined : handleBack}
				hasFullWidth
			/>
		</Form>
	);
}

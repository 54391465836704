import styled from 'styled-components';

import { Colors } from 'environment';

export const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: calc(100% - 8rem);
`;

export const ProgressContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ProgressWrapper = styled.div`
	padding: 0px;
	border-radius: 0.2rem;
	background: ${Colors.background.disabled};
	flex: 1;
	height: 0.4rem;
	margin-right: 0.8rem;
	max-width: 37.4rem;
`;

interface ProgressProps {
	width: string;
}

export const ProgressBar = styled.div<ProgressProps>`
	height: 100%;
	border-radius: 0.2rem;
	transition: 0.4s linear;
	transition-property: width, background-color;
	width: ${({ width }) => width};
	background-color: ${Colors.vibrantGreen};
`;

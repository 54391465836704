export const ssoClientsConfigs = {
	inactivityTrack: {
		sykehuspartner: true,
		ledidissotest: true,
		cornell: false,
		cmr: false
	},
	forcedLogoutURL: {
		sykehuspartner: true,
		ledidissotest: true,
		cornell: false,
		cmr: false
	}
};

export const logoutURLLedidiSSOParams =
	'&logout_uri=https%3A%2F%2Fsso-test.auth.eu-central-1.amazoncognito.com%2Flogout%3Fclient_id%3D6th7q15c6vdl5jct9kaimvt66g%26response_type%3Dtoken%26logout_uri%3Dhttps%253A%252F%252F';

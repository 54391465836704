import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 47.5%;
	height: 100%;
`;

import { useState } from 'react';
import { Modal } from 'components/UI/Modal';
import { ImportDropzone } from 'components/Projects/CreateAndImport';
import { useTranslation, useUploadDocument } from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';

interface Props {
	onClose: () => void;
}

export function UploadDocumentModal({ onClose }: Props) {
	const { translate } = useTranslation();
	const { setNotification } = useAlerts();

	const [importFileName, setImportFileName] = useState('');

	const [{ loading: uploadingDocument, error: errorUploadingDocument }] = useUploadDocument();

	useCompletedAction(uploadingDocument, errorUploadingDocument, () => {
		setNotification({
			message: translate(({ dragAndDropFile }) => dragAndDropFile.fileUploadSuccessful)
		});
		onClose();
	});

	return (
		<Modal
			title={translate(dict => dict.dragAndDropFile.modalTitle)}
			onClose={onClose}
			size={s => s.s}
			visible
			close
		>
			<ImportDropzone
				setImportFileName={setImportFileName}
				importFileName={importFileName}
				documentImport
			/>
		</Modal>
	);
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { createTemplateFromAll, CreateTemplateFromAllAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';

export function useCreateTemplateFromAll(): LocalOperation<
	(payload: ActionPayload<CreateTemplateFromAllAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<CreateTemplateFromAllAction>) {
		dispatch(createTemplateFromAll(payload));
	}

	return handler;
}

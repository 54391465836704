import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputType } from 'types/index';
import { CreditCardInterfaceWithValidation } from 'store/account/payments';

import { CardNumberFields } from 'components/Account';

import { HSpacer, Row, Field } from './AddCreditCardModal.style';
import { Spacer } from 'components/UI/Spacer';
import { Input } from 'components/UI/Inputs/Input';
import { CountrySelector } from 'components/UI/Interactables/CountrySelector';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { useAPICreateSubscriptionPaymentIntent, useTranslation } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

interface Props {
	shouldProcessCard: boolean;
	recurrentBilling: boolean;
	setShouldProcessCard: (shouldProcess: boolean) => void;
	setRecurrentBilling: (recurrentBilling: boolean) => void;
	onClose: () => void;
	onCreditCardAdded?: () => void;
}

export function AddCreditCardForm({
	shouldProcessCard,
	recurrentBilling,
	setShouldProcessCard,
	setRecurrentBilling,
	onClose,
	onCreditCardAdded
}: Props) {
	const { translate } = useTranslation();
	const {
		setValue,
		control,
		register,
		formState: { errors }
	} = useFormContext<CreditCardInterfaceWithValidation>();

	const [{ data: paymentIntent, error: paymentIntentError }, createPaymentIntet] =
		useAPICreateSubscriptionPaymentIntent();

	const [isStateMandatory, setIsStateMandatory] = useState(false);

	useEffectOnce(() => {
		// create a payment intent once the modal opens
		createPaymentIntet();
	});

	useEffect(() => {
		// TODO: in case there is an error creating the intent maybe we
		// shouldn't let the user complete the form any more since it won't work
		if (paymentIntentError) onClose();
	}, [paymentIntentError]);

	return (
		<div>
			<CardNumberFields
				paymentIntent={paymentIntent}
				shouldProcessCard={shouldProcessCard}
				closeModal={onClose}
				onCreditCardAdded={onCreditCardAdded}
				setShouldProcessCard={setShouldProcessCard}
			/>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('firstName')}
					type={InputType.Text}
					error={errors.firstName?.message ?? ''}
					label={translate(({ loginForm }) => loginForm.firstname)}
					required
				/>
				<HSpacer />
				<Input
					{...register('lastName')}
					type={InputType.Text}
					error={errors.lastName?.message ?? ''}
					label={translate(({ loginForm }) => loginForm.lastname)}
					required
				/>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('addressLine1')}
					type={InputType.Text}
					label={translate(({ creditCardForm }) => creditCardForm.addressLineOne)}
					error={errors.addressLine1?.message ?? ''}
					required
				/>
				<HSpacer />
				<Input
					{...register('addressLine2')}
					type={InputType.Text}
					label={translate(({ creditCardForm }) => creditCardForm.addressLineTwo)}
					error={errors.addressLine2?.message ?? ''}
				/>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<Field>
					<Controller
						control={control}
						name="countryCode"
						render={({ field: { value } }) => (
							<CountrySelector
								value={value}
								label={translate(
									dict => dict.accountUM.userDetails.countryOfResidence
								)}
								placeholder={translate(dict => dict.inputPlaceholder.typeHere)}
								onSelect={country => {
									setIsStateMandatory(country?.ISO_2 === 'US');
									setValue('countryCode', country?.ISO_2 ?? '', {
										shouldDirty: true,
										shouldValidate: true
									});
								}}
								required
							/>
						)}
					/>
				</Field>
				<HSpacer />
				<Input
					{...register('billingCity')}
					type={InputType.Text}
					label={translate(({ loginForm }) => loginForm.city)}
					error={errors.billingCity?.message ?? ''}
					required
				/>
			</Row>
			<Spacer size={s => s.s} />
			<Row>
				<Input
					{...register('billingState')}
					type={InputType.Text}
					label={translate(({ creditCardForm }) => creditCardForm.state)}
					error={errors.billingState?.message ?? ''}
					required={isStateMandatory}
				/>
				<HSpacer />
				<Input
					{...register('billingZip')}
					type={InputType.Text}
					label={translate(({ creditCardForm }) => creditCardForm.zip)}
					error={errors.billingZip?.message ?? ''}
					required
				/>
			</Row>
			<Spacer size={s => s.m} />

			<Checkbox
				label={translate(({ creditCardForm }) => creditCardForm.recurrentBillingInfoText)}
				checked={recurrentBilling}
				disabled={shouldProcessCard}
				onClick={() => setRecurrentBilling(!recurrentBilling)}
			/>
		</div>
	);
}

import type { Action } from 'types/store/types';
import { type GenericMap, SubscriptionAddonCode } from 'types/index';

export enum ActionTypes {
	GET_ALL_ADDONS = 'data/accountUM/GET_ALL_ADDONS',
	GET_USER_ADDONS = 'data/accountUM/GET_USER_ADDONS',
	ACTIVATE_USER_ADDON = 'data/accountUM/ACTIVATE_USER_ADDON',
	DEACTIVATE_USER_ADDON = 'data/accountUM/DEACTIVATE_USER_ADDON',
	ASSIGN_SUBSCRIPTION_ADDON = 'data/accountUM/ASSIGN_SUBSCRIPTION_ADDON',
	RETRACT_SUBSCRIPTION_ADDON = 'data/accountUM/RETRACT_SUBSCRIPTION_ADDON'
}

export interface State {
	allAddons: SubscriptionAddonCode[] | null;
	userAddons: SubscriptionAddonCode[] | null;
	activeUserAddons: SubscriptionAddonCode[] | null;
	subscriptionAddonsByCode?: GenericMap<SubscriptionAddon> | null;
}

export type SubscriptionAddon = {
	code: SubscriptionAddonCode;
	total: number;
	available: number;
};

export type GetAllAddonsAction = Action<
	ActionTypes.GET_ALL_ADDONS,
	{
		addons: Array<SubscriptionAddonCode>;
	}
>;

export type GetUserAddonsAction = Action<
	ActionTypes.GET_USER_ADDONS,
	{
		activeUserAddons: Array<SubscriptionAddonCode>;
		userAddons: Array<SubscriptionAddonCode>;
	}
>;

export type ActivateUserAddonAction = Action<
	ActionTypes.ACTIVATE_USER_ADDON,
	{
		addonCode: SubscriptionAddonCode;
	}
>;

export type DeactivateUserAddonAction = Action<
	ActionTypes.DEACTIVATE_USER_ADDON,
	{
		addonCode: SubscriptionAddonCode;
	}
>;

export type AssignSubscriptionAddonAction = Action<
	ActionTypes.ASSIGN_SUBSCRIPTION_ADDON,
	{
		addonCode: SubscriptionAddonCode;
	}
>;

export type RetractSubscriptionAddonAction = Action<
	ActionTypes.RETRACT_SUBSCRIPTION_ADDON,
	{
		addonCode: SubscriptionAddonCode;
	}
>;

export const AddonsOptionLabel: GenericMap<string> = {
	[SubscriptionAddonCode.JADBio]: 'addons.options.jadBio'
};

export const AddonsOptionInfoLabel: GenericMap<string> = {
	[SubscriptionAddonCode.JADBio]: 'addons.options.jadBioInfo'
};

export type Actions =
	| GetAllAddonsAction
	| ActivateUserAddonAction
	| GetUserAddonsAction
	| DeactivateUserAddonAction
	| AssignSubscriptionAddonAction
	| RetractSubscriptionAddonAction;

import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	ActionTypes,
	getEnterpriseOrganizations,
	selectEnterpriseOrganizations,
	EnterpriseOrganization
} from 'store/account/admin';
import { GenericMap } from 'types/index';
import { useDispatch, useSelector } from 'hooks/utils';
import { useActivity } from 'hooks/store/utils/useActivity';

export function useEnterpriseOrganizations(): OperationResult<
	GenericMap<EnterpriseOrganization> | undefined
> {
	const dispatch = useDispatch();

	const organizations = useSelector(state => selectEnterpriseOrganizations(state.account.admin));

	const [{ loading, error }] = useActivity(ActionTypes.ADMIN_GET_ENTERPRISE_ORGANIZATIONS);

	const fetched = organizations !== undefined;

	useEffect(() => {
		if (!fetched && !loading && !error) {
			handler();
		}
	}, [loading, error]);

	function handler() {
		dispatch(getEnterpriseOrganizations());
	}

	return [{ loading, error, fetched, data: organizations }, handler];
}

import { Colors } from 'environment';
import { Switch } from 'components/UI/Interactables/Switch';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { Typography } from 'components/UI/Typography';
import {
	useTranslation,
	useDeleteVariableCategoryValue,
	useDeleteVariableCategoryValues
} from 'hooks/store';
import { useToggle, useCompletedAction } from 'hooks/utils';

interface Props {
	variableName: string;
	categoryValueIds: string[];
	onClose: (success?: boolean) => void;
}

export function DeleteCategoryValuesModal({ variableName, categoryValueIds, onClose }: Props) {
	const { translate } = useTranslation();

	const [removeDatasetValues, toggleRemoveDatasetValues] = useToggle(false);

	const deleteOneValue = categoryValueIds.length === 1;

	const [
		{ loading: deletingCategoryValue, error: errorDeletingCategoryValue },
		deleteCategoryValue
	] = useDeleteVariableCategoryValue();
	const [
		{ loading: deletingCategoryValues, error: errorDeletingCategoryValues },
		deleteCategoryValues
	] = useDeleteVariableCategoryValues();

	useCompletedAction(deletingCategoryValue, errorDeletingCategoryValue, () => onClose(true));
	useCompletedAction(deletingCategoryValues, errorDeletingCategoryValues, () => onClose(true));

	function handleSubmit() {
		if (deleteOneValue) {
			const categoryValueId = categoryValueIds[0];

			deleteCategoryValue({
				variableName,
				categoryValueId,
				removeDatasetValues
			});
		} else {
			deleteCategoryValues({
				variableName,
				categoryValueIds,
				removeDatasetValues
			});
		}
	}

	const computedValuesWord = deleteOneValue
		? translate(dict => dict.terms.value)
		: translate(dict => dict.terms.values);

	return (
		<Modal
			size={s => s.s}
			title={`${translate(dict => dict.terms.delete)} ${computedValuesWord}?`}
			primary={{
				label: translate(({ buttons }) => buttons.delete),
				warning: true,
				loading: deletingCategoryValue || deletingCategoryValues,
				onClick: handleSubmit
			}}
			neutral={{
				label: translate(({ buttons }) => buttons.cancel),
				onClick: onClose
			}}
			onClose={onClose}
			enterAsPrimaryOnClick
			visible
			close
		>
			<Typography.Paragraph>
				<b>{translate(dict => dict.terms.warning)}</b>
				{` ${translate(
					dict => dict.variablesPage.deleteCategoryValuesModal.beforeVariable
				)} ${computedValuesWord} ${translate(
					dict => dict.variablesPage.deleteCategoryValuesModal.afterVariable
				)}`}
			</Typography.Paragraph>

			<Spacer size={s => s.s} />

			<Typography.Paragraph>
				<i>
					{translate(dict => dict.variablesPage.deleteCategoryValuesModal.deleteWarning)}
				</i>
			</Typography.Paragraph>

			<Spacer size={s => s.s} />

			<Switch
				label={translate(dict => dict.variablesPage.deleteCategoryValuesModal.delete)}
				description={
					removeDatasetValues
						? translate(
								dict =>
									dict.variablesPage.deleteCategoryValuesModal.removeDatasetValues
						  )
						: translate(
								dict => dict.variablesPage.deleteCategoryValuesModal.datasetValues
						  )
				}
				on={removeDatasetValues}
				onChange={toggleRemoveDatasetValues}
			/>

			<Spacer size={s => s.m} />

			<Typography.Paragraph fontweight={w => w.bold} color={Colors.text.error}>
				{translate(dict => dict.terms.deleteWarningMessage)}
			</Typography.Paragraph>
		</Modal>
	);
}

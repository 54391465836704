import styled, { css } from 'styled-components';

import { Typography } from 'components/UI/Typography';
import { Colors, MediaQueries } from 'environment';

export const TemplatesContainer = styled.div`
	display: flex;
	position: relative;
	flex: 1;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column;
	}
`;

export const SettingsContainer = styled.div<{ fullWidth?: boolean }>`
	display: flex;
	flex-direction: column;
	padding-right: 1.2rem;
	padding-left: 0.2rem;
	margin-right: 1.2rem;
	overflow: auto;
	max-height: calc(100vh - 22.9rem);
	width: 100%;
	max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : 35 + 'rem')};

	@media ${MediaQueries.maxWidth.lg} {
		margin-right: 0;
		max-width: unset;
	}
`;

export const Title = styled(Typography.H4)``;

export const Wrapper = styled.div<{ emptyList?: boolean }>`
	margin-bottom: 2rem;
	flex: 1;
	display: flex;
	${({ emptyList }) =>
		emptyList &&
		css`
			align-items: center;
			justify-content: center;
		`}
`;

export const BuilderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 35rem);

	@media ${MediaQueries.maxWidth.lg} {
		width: 100%;
	}
`;

export const BuilderContainer = styled.div`
	width: 100%;
	min-height: calc(100vh - 40rem);
	border-radius: 0.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	margin-bottom: 4rem;
	background-color: ${Colors.white};
`;

export const TitleWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.6rem;
`;

import styled, { css } from 'styled-components';

import { getSidebarWidth } from 'components/UI/Grid';
import { Colors, MediaQueries, Shadows } from 'environment';

const drawerWidth = getSidebarWidth({
	numberOfColumns: {
		xl: 2,
		l: 2,
		s: 2
	}
});

export const DrawerBody = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	height: calc(100% - 20.4rem);
	background: white;
	box-shadow: ${Shadows.normal};
	z-index: 98;
	visibility: hidden;

	@media only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm} {
		height: 100vh;
		z-index: 9999;
		position: fixed;
	}

	@keyframes enterAnimation {
		0% {
			transform: translate(100%);
		}
		100% {
			transform: translate(0%);
		}
	}

	@keyframes leaveAnimation {
		0% {
			transform: translate(0%);
		}
		100% {
			visibility: hidden;
			transform: translate(100%);
		}
	}

	${drawerWidth};

	visibility: visible;
	animation: enterAnimation 0.15s;
`;

export const DeleteWrapper = styled.div`
	width: 100%;
	background: ${Colors.white};
	padding: 0 1.6rem;
`;

export const Divider = styled.div`
	height: 0.1rem;
	background-color: #ededed;
	margin: 0 1.6rem;
`;

export const Tabs = styled.div`
	display: flex;
	width: 100%;
`;

interface TabProps {
	active: boolean;
}
export const Tab = styled.div<TabProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
	width: 100%;
	position: relative;
	cursor: pointer;

	:hover .tab-icon {
		color: ${Colors.primary.normal};
	}

	::after {
		display: block;
		content: '';
		width: 100%;
		height: 0.2rem;
		border-radius: 0 0 0.4rem 0.4rem;
		background-color: ${Colors.primary.normal};
		position: absolute;
		bottom: 0;
		opacity: 0;
	}

	${({ active }) =>
		active &&
		css`
			.tab-icon {
				color: ${Colors.primary.normal};
			}

			::after {
				opacity: 1;
			}
		`}
`;

import styled from 'styled-components';

import { Colors, Shadows } from 'environment';

export const Container = styled.div`
	position: relative;
	width: 100%;
`;

export const ToggleContainer = styled.div`
	display: flex;
	flex-direction: column;
	user-select: none;
`;

export const TransferDropdownMenu = styled.div`
	position: absolute;
	width: 100%;
	padding: 1.6rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0.4rem;
	margin-bottom: 2rem;
	background-color: ${Colors.white};
	box-shadow: ${Shadows.hover};
	z-index: 999;
	min-height: 5.6rem;
	top: 8.4rem;

	.transfer-ownership-search {
		width: Calc(100% - 3.2rem);
		margin-bottom: 1.6rem;
	}

	.transfer-ownership-user {
		padding: 0.4rem 1.6rem;
	}
`;

export const DropdownList = styled.div`
	width: 100%;
	max-height: 16rem;
	overflow-y: auto;
`;

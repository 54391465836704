import { DEFAULT_ACTIVITY_TIMEOUT } from 'consts';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	Activity,
	ActivityError,
	ActivityErrorAction,
	BeginActivityAction,
	EndActivityAction,
	EndActivity,
	ActivityClearErrorAction,
	ActivityClearError,
	ActivityClearActionTypeErrorAction
} from './types';

const beginActivityAction = (payload: ActionPayload<BeginActivityAction>): BeginActivityAction => ({
	type: ActionTypes.BEGIN_ACTIVITY,
	payload
});

export const beginActivity =
	(activity: Activity): Thunk =>
	async dispatch => {
		dispatch(beginActivityAction(activity));
	};

const endActivityAction = (payload: ActionPayload<EndActivityAction>): EndActivityAction => ({
	type: ActionTypes.END_ACTIVITY,
	payload
});

export const endActivity =
	(activity: EndActivity): Thunk =>
	async dispatch => {
		dispatch(endActivityAction(activity));
	};

const errorAction = (payload: ActionPayload<ActivityErrorAction>): ActivityErrorAction => ({
	type: ActionTypes.ERROR,
	payload
});

export const setError =
	(error: ActivityError): Thunk =>
	async (dispatch, getState) => {
		try {
			dispatch(errorAction(error));

			// DELETE ERROR AFTER `error.timeout` OR `DEFAULT_ACTIVITY_TIMEOUT`
			setTimeout(async () => {
				const { errors } = getState().ui.activities;
				// THE USER MIGHT JUST CANCEL IT BY CLICK FROM THE ALERTS SYSTEM - DISPATCH STORE ACTION ONLY IF NECESSARY
				const errorExists = errors.find(({ uuid }) => uuid === error.uuid);

				if (errorExists) await dispatch(clearError({ type: error.type, uuid: error.uuid }));
			}, error.timeout ?? DEFAULT_ACTIVITY_TIMEOUT);
		} catch (e) {
			// Dispatching an error here would most probably
			// create an infinite loop so let's simply log it
			console.error(`Awkward... an error occured while dispatching an error ${e}`);
		}
	};

const clearErrorAction = (
	payload: ActionPayload<ActivityClearErrorAction>
): ActivityClearErrorAction => ({
	type: ActionTypes.CLEAR_ERROR,
	payload
});

export const clearError =
	(error: ActivityClearError): Thunk =>
	async dispatch => {
		try {
			dispatch(clearErrorAction(error));
		} catch (e) {
			// Dispatching an error here would most probably
			// create an infinite loop so let's simply log it
			console.error(`Awkward... an error occured while clearing an error ${e}`);
		}
	};

const clearActionTypeErrorAction = (
	payload: ActionPayload<ActivityClearActionTypeErrorAction>
): ActivityClearActionTypeErrorAction => ({
	type: ActionTypes.CLEAR_ACTION_TYPE_ERROR,
	payload
});

export const clearActionTypeError =
	(type: string): Thunk =>
	async dispatch => {
		try {
			dispatch(clearActionTypeErrorAction(type));
		} catch (e) {
			// Dispatching an error here would most probably
			// create an infinite loop so let's simply log it
			console.error(`Awkward... an error occured while clearing an error ${e}`);
		}
	};

import React, { Fragment } from 'react';
import { nanoid as generate } from 'nanoid';

import { Dictionary } from 'environment';
import { useTranslation } from 'hooks/store';
import { GenericMap } from 'types/index';

interface Props {
	children: React.ReactNode;
	transKey: (dict: typeof Dictionary) => string;
	options?: GenericMap<string | number>;
}

export function Translate({ children, transKey, options }: Props) {
	const { translate } = useTranslation();
	const message: string = translate(() => transKey(Dictionary)) || '';

	let availableComponentIndex = 0;
	const components = React.Children.toArray(children);
	const split = message.split('$');
	const items = split.map(value => {
		const startIndex = value.indexOf('{');
		const endIndex = value.indexOf('}');
		let child: string | number = value.substring(startIndex + 1, endIndex);

		if (options && options[child]) {
			child = options[child];
		}

		if (startIndex > -1 && endIndex > -1) {
			const rest = value.substr(endIndex + 1);

			if (availableComponentIndex < components.length) {
				const elem = React.cloneElement(
					components[availableComponentIndex] as React.ReactElement,
					undefined,
					child
				);
				availableComponentIndex++;

				return (
					<Fragment key={generate()}>
						{elem}
						{rest}
					</Fragment>
				);
			}

			return <Fragment key={generate()}>{rest}</Fragment>;
		}

		return value;
	});

	return <>{items}</>;
}

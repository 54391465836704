import { SetStateAction, createContext } from 'react';
import { VariableSetDataArray } from 'store/data/variables';

interface GroupsContext {
	expanded: Record<string, boolean>;
	setExpanded: React.Dispatch<SetStateAction<Record<string, boolean>>>;
	expandedSeriesGroups: Record<string, boolean>;
	setExpandedSeriesGroups: React.Dispatch<SetStateAction<Record<string, boolean>>>;
	expandAllGroups: () => void;
	collapseAllGroups: () => void;
	toggleGroup: (id: string) => void;

	//SERIES
	expandAllSeriesGroups: (value: VariableSetDataArray) => void;
	collapseAllSeriesGroups: (value: VariableSetDataArray) => void;
}

export const ExpandGroupsContext = createContext<GroupsContext>({
	expanded: {},
	setExpanded: () => undefined,
	expandAllGroups: () => undefined,
	collapseAllGroups: () => undefined,
	toggleGroup: () => undefined,

	//SERIES
	expandedSeriesGroups: {},
	setExpandedSeriesGroups: () => undefined,
	expandAllSeriesGroups: () => undefined,
	collapseAllSeriesGroups: () => undefined
});

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { setPageView, SetPageViewAction } from 'store/data/templates';
import { ActionPayload } from 'store/types';
import { TemplatePageViews } from 'types/index';

export function usePageView(): LocalOperationResult<
	TemplatePageViews,
	(pageView: ActionPayload<SetPageViewAction>['pageView']) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => state.data.templates.metadata.pageView);

	function handler(pageView: ActionPayload<SetPageViewAction>['pageView']) {
		dispatch(setPageView({ pageView }));
	}

	return [data, handler];
}

import { AssignRoleUserAccess } from 'api/data/roles';
import { ActionPayload, Thunk } from 'store/types';
import { createActivity } from 'store/ui/activities';
import { getUsersByIds } from '../accounts';

import { getCollaborators } from '../collaborators';
import { parseRole, parseTemplateRole } from './parsers';
import {
	ActionTypes,
	ChangeTemplateRolePageTabAction,
	CreateRoleAction,
	CreateTemplateRoleAction,
	DeleteRoleAction,
	DeleteTemplateRoleAction,
	GetRolesAction,
	GetRoleTemplateShareListAction,
	GetTemplateRolesAction,
	NewRole,
	NewTemplateRole,
	ResetRoleTemplateShareListFetchedAction,
	ResetShareListAction,
	Role,
	ShareRoleTemplateWithInstanceAction,
	TemplateRole,
	UnshareRoleTemplateWithInstanceAction,
	UpdateRoleAction,
	UpdateTemplateRoleAction
} from './types';

const getTemplateRolesAction = (
	payload: ActionPayload<GetTemplateRolesAction>
): GetTemplateRolesAction => ({
	type: ActionTypes.GET_TEMPLATE_ROLES,
	payload
});

export const getTemplateRoles = (): Thunk => async (dispatch, _, context) => {
	const activity = createActivity({ type: ActionTypes.GET_TEMPLATE_ROLES, dispatch });

	try {
		activity.begin();

		const { ownedTemplateRoles, sharedTemplateRoles, publicTemplateRoles } =
			await context.api.data.roles().getTemplateRoles();

		dispatch(
			getTemplateRolesAction({
				ownedTemplateRoles,
				sharedTemplateRoles,
				publicTemplateRoles
			})
		);
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const createTemplateRoleAction = (
	payload: ActionPayload<CreateTemplateRoleAction>
): CreateTemplateRoleAction => ({
	type: ActionTypes.CREATE_TEMPLATE_ROLE,
	payload
});

export const createTemplateRole =
	(templateRole: NewTemplateRole): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_TEMPLATE_ROLE, dispatch });

		try {
			activity.begin();

			const { roleTemplateId, ...apiTemplateRole } = parseTemplateRole({
				id: '',
				shareList: {
					projectShareList: {
						current: [],
						initial: []
					},
					userShareList: {
						current: [],
						initial: []
					},
					fetched: false
				},
				...templateRole
			});

			const { templateRole: newTemplateRole } = await context.api.data
				.roles()
				.createTemplateRole({
					roleTemplate: apiTemplateRole
				});

			dispatch(createTemplateRoleAction({ templateRole: newTemplateRole }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateTemplateRoleAction = (
	payload: ActionPayload<UpdateTemplateRoleAction>
): UpdateTemplateRoleAction => ({
	type: ActionTypes.UPDATE_TEMPLATE_ROLE,
	payload
});

export const updateTemplateRole =
	(templateRole: TemplateRole): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_TEMPLATE_ROLE, dispatch });

		try {
			activity.begin();

			const apiTemplateRole = parseTemplateRole(templateRole);

			await context.api.data.roles().updateTemplateRole({
				roleTemplate: apiTemplateRole
			});

			dispatch(updateTemplateRoleAction({ templateRole }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const shareTemplateRole =
	(input: {
		templateRole: {
			id: number;
			sharedWithOrganization: boolean;
		};
		userIds: string[];
		projectIds: string[];
	}): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.SHARE_ROLE_TEMPLATE, dispatch });

		try {
			activity.begin();

			const { templateRole, userIds, projectIds } = input;

			await context.api.data.roles().shareTemplateRole({
				roleTemplate: {
					roleTemplateId: templateRole.id,
					sharedWithOrganization: templateRole.sharedWithOrganization
				},
				users: userIds.map(userId => ({ username: userId })),
				projects: projectIds.map(projectId => ({ projectId: projectId }))
			});

			dispatch(getRoleTemplateShareList({ templateRoleId: templateRole.id.toString() }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const unshareTemplateRole =
	(input: {
		templateRole: {
			id: number;
		};
		userIds: string[];
		projectIds: string[];
	}): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.UNSHARE_ROLE_TEMPLATE, dispatch });

		try {
			activity.begin();

			const { templateRole, userIds, projectIds } = input;

			await context.api.data.roles().unshareTemplateRole({
				roleTemplate: {
					roleTemplateId: templateRole.id
				},
				users: userIds.map(userId => ({ username: userId })),
				projects: projectIds.map(projectId => ({ projectId: projectId }))
			});

			dispatch(getRoleTemplateShareList({ templateRoleId: templateRole.id.toString() }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const resetShareList = (
	payload: ActionPayload<ResetShareListAction>
): ResetShareListAction => ({
	type: ActionTypes.RESET_ROLE_TEMPLATE_SHARE_LIST,
	payload
});

export const shareRoleTemplateWithInstance = (
	payload: ActionPayload<ShareRoleTemplateWithInstanceAction>
): ShareRoleTemplateWithInstanceAction => ({
	type: ActionTypes.SHARE_ROLE_TEMPLATE_WITH_INSTANCE,
	payload
});

export const unshareRoleTemplateWithInstance = (
	payload: ActionPayload<UnshareRoleTemplateWithInstanceAction>
): UnshareRoleTemplateWithInstanceAction => ({
	type: ActionTypes.UNSHARE_ROLE_TEMPLATE_WITH_INSTANCE,
	payload
});

export const resetRoleTemplateShareListFetchedAction = (
	payload: ActionPayload<ResetRoleTemplateShareListFetchedAction>
): ResetRoleTemplateShareListFetchedAction => ({
	type: ActionTypes.RESET_ROLE_TEMPLATE_SHARE_LIST_FETCHED,
	payload
});

const getRoleTemplateShareListAction = (
	payload: ActionPayload<GetRoleTemplateShareListAction>
): GetRoleTemplateShareListAction => ({
	type: ActionTypes.GET_ROLE_TEMPLATE_SHARE_LIST,
	payload
});

export const getRoleTemplateShareList =
	(input: { templateRoleId: string }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_ROLE_TEMPLATE_SHARE_LIST,
			dispatch
		});

		try {
			activity.begin();

			const { templateRoleId } = input;

			const {
				users: { byEmail, byUserId }
			} = getState().data.accounts;
			const { username } = getState().auth;

			const { projects, users } = await context.api.data.roles().getRoleTemplateShareList({
				roleTemplate: {
					roleTemplateId: Number(templateRoleId)
				}
			});

			const usersWithCompleteInfo: string[] = [];
			const missingUsersInfo: string[] = [];
			if (users.length) {
				Object.values(byEmail).forEach(userByEmail => {
					if (userByEmail.userid) {
						usersWithCompleteInfo.push(userByEmail.userid);
					}
				});
				Object.values(byUserId).forEach(userById => {
					if (userById.userid) {
						usersWithCompleteInfo.push(userById.userid);
					}
				});
				users.forEach(user => {
					if (!usersWithCompleteInfo.includes(user) && username !== user) {
						missingUsersInfo.push(user);
					}
				});

				// Get users with missing info by id
				if (missingUsersInfo.length) {
					dispatch(getUsersByIds(missingUsersInfo));
				}
			}

			dispatch(
				getRoleTemplateShareListAction({
					projects,
					users,
					templateRoleId
				})
			);
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deleteTemplateRoleAction = (
	payload: ActionPayload<DeleteTemplateRoleAction>
): DeleteTemplateRoleAction => ({
	type: ActionTypes.DELETE_TEMPLATE_ROLE,
	payload
});

export const deleteTemplateRole =
	(templateRoleId: TemplateRole['id']): Thunk =>
	async (dispatch, _, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_TEMPLATE_ROLE, dispatch });

		try {
			activity.begin();

			await context.api.data.roles().deleteTemplateRole({
				roleTemplate: {
					roleTemplateId: Number(templateRoleId)
				}
			});

			dispatch(deleteTemplateRoleAction({ templateRoleId }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setTemplateRolePageTabAction = (
	payload: ActionPayload<ChangeTemplateRolePageTabAction>
): ChangeTemplateRolePageTabAction => ({
	type: ActionTypes.CHANGE_TEMPLATE_ROLE_PAGE_TAB,
	payload
});

const getRolesAction = (payload: ActionPayload<GetRolesAction>): GetRolesAction => ({
	type: ActionTypes.GET_ROLES,
	payload
});

export const getRoles = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_ROLES, dispatch });

	try {
		activity.begin();

		const {
			roles: { projectId }
		} = getState().data;

		if (projectId) {
			const { roles } = await context.api.data.roles().getRoles({
				project: {
					projectId
				}
			});

			dispatch(getRolesAction({ projectId, roles }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const createRoleAction = (payload: ActionPayload<CreateRoleAction>): CreateRoleAction => ({
	type: ActionTypes.CREATE_ROLE,
	payload
});

export const createRole =
	(
		role: NewRole,
		options?: {
			assignTo: {
				collaborators: string[];
				groupIds: string[];
			};
		}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_ROLE, dispatch });

		try {
			activity.begin();

			const {
				roles: { projectId }
			} = getState().data;

			if (projectId) {
				const { projectRoleId, ...apiProjectRole } = parseRole({
					id: '',
					...role
				});

				const { role: newRole } = await context.api.data.roles().createRole({
					project: {
						projectId
					},
					projectRole: apiProjectRole
				});

				dispatch(createRoleAction({ projectId, role: newRole }));

				if (options?.assignTo) {
					await dispatch(
						assignRole({
							roleId: newRole.id,
							assignTo: options.assignTo
						})
					);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getRoles());
		}
	};

const updateRoleAction = (payload: ActionPayload<UpdateRoleAction>): UpdateRoleAction => ({
	type: ActionTypes.UPDATE_ROLE,
	payload
});

export const updateRole =
	(role: Role): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_ROLE, dispatch });

		try {
			activity.begin();

			const {
				roles: { projectId }
			} = getState().data;

			if (projectId) {
				const apiRole = parseRole(role);

				await context.api.data.roles().updateRole({
					project: {
						projectId
					},
					projectRole: apiRole
				});

				dispatch(updateRoleAction({ projectId, role }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getRoles());
		}
	};

const deleteRoleAction = (payload: ActionPayload<DeleteRoleAction>): DeleteRoleAction => ({
	type: ActionTypes.DELETE_ROLE,
	payload
});

export const deleteRole =
	(roleId: Role['id']): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_ROLE, dispatch });

		try {
			activity.begin();

			const {
				roles: { projectId }
			} = getState().data;

			if (projectId) {
				await context.api.data.roles().deleteRole({
					project: {
						projectId
					},
					projectRole: {
						projectRoleId: Number(roleId)
					}
				});

				dispatch(deleteRoleAction({ projectId, roleId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getRoles());
			dispatch(getCollaborators());
		}
	};

export const assignRole =
	(input: {
		roleId: Role['id'];
		assignTo: {
			collaborators: string[];
			groupIds: string[];
		};
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ASSIGN_ROLE, dispatch });

		const { roleId, assignTo } = input;

		const { collaborators, groupIds } = assignTo;

		try {
			activity.begin();

			const {
				roles: { projectId },
				collaborators: { byProjectId }
			} = getState().data;
			const {
				subscription: { accountDetails }
			} = getState().account;

			const projectCollaboratorAndOrganizationData = projectId
				? byProjectId[projectId].data
				: null;

			const isOrganizationAndCollaboratorDataFetched = projectId
				? byProjectId[projectId].fetched
				: null;

			const collaboratorsData = projectCollaboratorAndOrganizationData
				? projectCollaboratorAndOrganizationData.collaborators
				: null;

			const self = accountDetails?.userid;

			// Build list of collaborator permissions from the selected individual collaborator array

			const individualSelectedCollaboratorAccessArray =
				collaborators.length && isOrganizationAndCollaboratorDataFetched
					? collaborators.reduce((result, collaboratorId) => {
							const collaborator = collaboratorsData
								? collaboratorsData?.byId[collaboratorId]
								: null;

							if (collaborator) {
								result.push({
									projectRoleId: Number(roleId),
									userEmailAddress: collaborator.emailAddress
								});
							}

							return result;
					  }, [] as AssignRoleUserAccess[])
					: null;

			// Build list of collaborator permissions from the selected organizations
			const organizationsCollaboratorsArray =
				projectCollaboratorAndOrganizationData?.organizations
					? groupIds.map(orgId => {
							const organization =
								projectCollaboratorAndOrganizationData.organizations.byId[orgId];

							return organization.collaborators;
					  })
					: [];
			const flattenCollaboratorArray = organizationsCollaboratorsArray.reduce(
				(acc, cur) => acc.concat(cur),
				[]
			);

			// Remove self if present in the list of Organization Collaborators
			const organizationAccessArray: AssignRoleUserAccess[] = flattenCollaboratorArray.reduce(
				(result, collaboratorId) => {
					const collaborator = collaboratorsData
						? collaboratorsData?.byId[collaboratorId]
						: null;

					if (collaborator) {
						if (collaborator.userId !== self) {
							result.push({
								projectRoleId: Number(roleId),
								userEmailAddress: collaborator.emailAddress
							});
						}
					}

					return result;
				},
				[] as AssignRoleUserAccess[]
			);

			if (projectId) {
				if (individualSelectedCollaboratorAccessArray) {
					await context.api.data.roles().assignRole({
						project: {
							projectId,
							userAccesses: individualSelectedCollaboratorAccessArray
						}
					});
				}

				if (organizationAccessArray.length) {
					await context.api.data.roles().assignRole({
						project: {
							projectId,
							userAccesses: organizationAccessArray
						}
					});
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();

			dispatch(getCollaborators());
		}
	};

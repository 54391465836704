import { useEffect } from 'react';
import { getSeriesEntriesCount, selectSeriesEntriesCount, ActionTypes } from 'store/data/entries';
import { OperationResult, HookOptions } from 'hooks/store/types';
import { NumberMap } from 'types/index';
import { useDispatch, useSelector } from 'hooks/utils';
import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useVariables } from '../variables/useVariables';

export function useSeriesEntriesCount(options?: HookOptions): OperationResult<NumberMap> {
	const dispatch = useDispatch();

	const { lazy = false } = options ?? {};

	const isProjectValid = useIsProjectValid();
	const { hasDatasetReadAccess } = usePermissions();

	const [
		{
			data: { hasVariableSets }
		}
	] = useVariables({ lazy: true, initial: true });

	const isLatestEntryFetched = useSelector(state => state.data.entries.rows.latestFetched);

	const { bySetName: data, fetched } = useSelector(state =>
		selectSeriesEntriesCount(state.data.entries)
	);

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_SERIES_ENTRIES_COUNT);

	useEffect(() => {
		if (
			!loading &&
			!error &&
			!fetched &&
			isProjectValid &&
			hasDatasetReadAccess &&
			!lazy &&
			hasVariableSets &&
			isLatestEntryFetched
		) {
			handler();
		}
	}, [
		loading,
		error,
		fetched,
		isProjectValid,
		hasDatasetReadAccess,
		lazy,
		hasVariableSets,
		isLatestEntryFetched
	]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getSeriesEntriesCount());
	}

	return [{ data, loading, error, fetched }, handler];
}

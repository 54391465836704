import { useDispatch } from 'hooks/utils';
import { useActivity } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { addSubscriptionPaymentMethod, ActionTypes } from 'store/account/payments';

export function useAddSubscriptionPaymentMethod(): Operation<
	(authorizedPaymentIntentId: string) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivity(ActionTypes.ADD_SUBSCRIPTION_PAYMENT_METHOD);

	function handler(authorizedPaymentIntentId: string) {
		dispatch(addSubscriptionPaymentMethod(authorizedPaymentIntentId));
	}

	return [{ loading, error }, handler];
}

import { createSelector } from 'reselect';

import { SYSTEM_GENERATED_STATUS_NAME } from 'consts';
import { selectParams } from 'store/utils';

import { State, Status, StatusesMap } from './types';

/*
 * EXTRACTOR FUNCTIONS
 */

function getStatusesByProjectId({ projectId, byProjectId }: State) {
	if (projectId && byProjectId[projectId]) return byProjectId[projectId];
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

type SelectStatusesDataParams = { omitSystemGenerated: boolean };

export const selectStatusesData = createSelector(
	[
		getStatusesByProjectId,
		// PARAMS
		(_: State, params: SelectStatusesDataParams) => selectParams.encode(params)
	],
	(statusesByProjectId, params) => {
		const { omitSystemGenerated } = selectParams.decode<SelectStatusesDataParams>(params);

		const data: {
			statuses: Status[];
			statusesMap: StatusesMap;
			hasStatuses: boolean;
		} = {
			statuses: [],
			statusesMap: {},
			hasStatuses: false
		};

		if (statusesByProjectId) {
			const { byName, names } = statusesByProjectId;

			names.forEach(name => {
				if (omitSystemGenerated) {
					const isSystemGenerated = SYSTEM_GENERATED_STATUS_NAME === name;

					if (isSystemGenerated) return;
				}

				data.statuses.push(byName[name]);
			});

			data.statusesMap = byName;
			data.hasStatuses = data.statuses.length > 0;
		}

		return data;
	}
);

export const selectAreStatusesFetched = createSelector(
	[getStatusesByProjectId],
	statusesByProjectId => !!statusesByProjectId?.fetched
);

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ROUTES } from 'types/navigation';
import { PatientLoginForm, PatientMessage } from 'components/Patient';
import { PatientLoginParams } from 'store/auth';
import { useNavigation } from 'hooks/navigation';
import { usePatientLoginParams, usePatientLoggedIn } from 'hooks/store';
import { useEffectOnce } from 'hooks/utils';

export function PatientLoginPage() {
	const routeParams = useParams<{
		id: string;
		projectId: string;
		entryId: string;
		username: string;
		passPrefix: string;
		formId?: string;
	}>();
	const { replace } = useNavigation();

	const [_, setParams] = usePatientLoginParams();
	const [{ data: isPatientLoggedIn }] = usePatientLoggedIn();

	const [invalidURL, setInvalidURL] = useState(false);

	useEffectOnce(() => {
		const patientParams = routeParams as PatientLoginParams;

		if (!isEmpty(patientParams)) {
			setParams({ params: patientParams });
			replace(ROUTES.PatientLogin);
		} else {
			setInvalidURL(true);
		}
	});

	useEffect(() => {
		if (isPatientLoggedIn) replace(ROUTES.PatientEditEntry);
	}, [isPatientLoggedIn]);

	if (invalidURL) return <PatientMessage message={m => m.invalidURL} />;

	return <PatientLoginForm />;
}

import { useState } from 'react';
import { Title, NarrowContainer } from 'components/Projects/CreateAndImport';
import { InputType } from 'types/index';
import { InputContainer, RadioButton } from './ImportVariableSet.style';
import { ImportSetTypes } from '../../../../types/data/projects/import/types';
import { Typography } from 'components/UI/Typography';
import { Spacer } from 'components/UI/Spacer';
import { Flex } from 'components/UI/Flex';
import { Input } from 'components/UI/Inputs/Input';
import { StickyFooter } from 'components/UI/StickyFooter';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { useTranslation, useVariables } from 'hooks/store';

interface Props {
	handleClose: () => void;
	handleContinue: (setName: string) => void;
}

export function ImportVariableSet({ handleClose, handleContinue }: Props) {
	const { translate } = useTranslation();

	const [
		{
			data: { hasVariableSets, variableSets }
		}
	] = useVariables({ initial: true });

	const [newVariableSetLabel, setNewVariableSetLabel] = useState('');
	const [existingVariableSetName, setExistingVariableSetName] = useState('');
	const [existingVariableSetLabel, setExistingVariableSetLabel] = useState('');
	const [selectedType, setSelectedType] = useState(ImportSetTypes.newSet);

	const newVariableSetLabelTrimmed = newVariableSetLabel.trim();
	const isSetLabelUnique = !variableSets.some(
		variableSet => variableSet.setLabel === newVariableSetLabelTrimmed
	);

	const variableSetsWithLabelAndValue = variableSets.map(variableSet => ({
		label: variableSet.setLabel,
		value: variableSet.setName
	}));

	return (
		<>
			<NarrowContainer>
				<Title>
					{translate(({ projects }) => projects.createAndImport.title.importVariableSet)}
				</Title>

				<Typography.Paragraph>
					{translate(dict => dict.importVariableSet.chooseVariableSet)}
				</Typography.Paragraph>
				<Spacer size={s => s.m} />
				<Flex>
					<RadioButton
						label={translate(dict => dict.importVariableSet.newVariableSet)}
						selected={selectedType === ImportSetTypes.newSet}
						onSelect={() => {
							setSelectedType(ImportSetTypes.newSet);
							setExistingVariableSetName('');
							setExistingVariableSetLabel('');
						}}
					/>
				</Flex>
				{selectedType === ImportSetTypes.newSet && (
					<InputContainer>
						<Spacer size={s => s.s} />
						<Input
							type={InputType.Text}
							value={newVariableSetLabel}
							error={
								!isSetLabelUnique
									? translate(dict => dict.terms.errorlabelUnique)
									: undefined
							}
							placeholder={translate(dict => dict.importVariableSet.variableSetName)}
							onChange={e => setNewVariableSetLabel(e.target.value)}
						/>
					</InputContainer>
				)}
				<Spacer size={s => s.m} />
				<Flex>
					<RadioButton
						label={translate(dict => dict.importVariableSet.existingVariableSet)}
						selected={selectedType === ImportSetTypes.existingSet}
						onSelect={() => {
							setSelectedType(ImportSetTypes.existingSet);
							setNewVariableSetLabel('');
						}}
						disabled={!hasVariableSets}
					/>
				</Flex>
				{selectedType === ImportSetTypes.existingSet && (
					<InputContainer>
						<Spacer size={s => s.s} />

						<CreatableSelect
							value={
								existingVariableSetLabel
									? {
											value: existingVariableSetLabel,
											label: existingVariableSetLabel
									  }
									: {
											value: translate(
												dict =>
													dict.importVariableSet.selectExistingVariableSet
											),
											label: translate(
												dict =>
													dict.importVariableSet.selectExistingVariableSet
											)
									  }
							}
							items={variableSetsWithLabelAndValue}
							onValueSelected={(_, __, item) => {
								if (item && item.value) {
									setExistingVariableSetName(item.value);
									setExistingVariableSetLabel(item.label);
								}
							}}
						/>
					</InputContainer>
				)}
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.next),
					disabled:
						!isSetLabelUnique ||
						(!newVariableSetLabelTrimmed && !existingVariableSetName),
					onClick: () =>
						handleContinue(newVariableSetLabelTrimmed || existingVariableSetName)
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.cancel),
					onClick: handleClose
				}}
				maxWidth={65.2}
			/>
		</>
	);
}

import { ActionPayload, Thunk } from 'store/types';
import { createActivity } from 'store/ui/activities';

import {
	ActionTypes,
	DisableVariablesMappingAction,
	EnableVariablesMappingAction,
	GetVariablesMappingAction,
	InitializeVariablesMappingAction,
	ResetVariableMappingAction,
	SetVariableMappingAction
} from './types';

const initializeVariablesMappingAction = (
	payload: ActionPayload<InitializeVariablesMappingAction>
): InitializeVariablesMappingAction => ({
	type: ActionTypes.INITIALIZE_VARIABLES_MAPPING,
	payload
});

export const initializeVariablesMapping =
	(input: { setName?: string } = {}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.INITIALIZE_VARIABLES_MAPPING,
			dispatch
		});

		const { projectId } = getState().data.variablesMapping;

		const { setName } = input;

		try {
			activity.begin({ payload: { projectId } });

			if (projectId) {
				const { variablesMappingData } = await context.api.data
					.variablesMapping()
					.initializeVariablesMapping({
						projectId: Number(projectId),
						...(setName !== undefined && { set: { setName } })
					});

				dispatch(
					initializeVariablesMappingAction({ projectId, variablesMappingData, setName })
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

const getVariablesMappingAction = (
	payload: ActionPayload<GetVariablesMappingAction>
): GetVariablesMappingAction => ({
	type: ActionTypes.GET_VARIABLES_MAPPING,
	payload
});

export const getVariablesMapping = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_VARIABLES_MAPPING, dispatch });

	const { projectId } = getState().data.variablesMapping;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const { variablesMappingData, variablesMappingDataBySetName } = await context.api.data
				.variablesMapping()
				.getVariablesMapping({
					projectId: Number(projectId)
				});

			dispatch(
				getVariablesMappingAction({
					projectId,
					variablesMappingData,
					variablesMappingDataBySetName
				})
			);
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

const setVariableMappingAction = (
	payload: ActionPayload<SetVariableMappingAction>
): SetVariableMappingAction => ({
	type: ActionTypes.SET_VARIABLE_MAPPING,
	payload
});

export const setVariableMapping =
	(input: { variableName: string; customName: string; setName?: string }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.SET_VARIABLE_MAPPING, dispatch });

		const { variableName, customName, setName } = input;

		try {
			activity.begin({ payload: { variableName } });

			const { projectId } = getState().data.variables;

			if (projectId) {
				const { variableMapping } = await context.api.data
					.variablesMapping()
					.setVariableMapping({
						projectId: Number(projectId),
						variable: { variableName: variableName },
						customName,
						...(setName !== undefined && { set: { setName } })
					});

				dispatch(setVariableMappingAction({ projectId, variableMapping }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { variableName } });
		} finally {
			activity.end();
		}
	};

const resetVariableMappingAction = (
	payload: ActionPayload<ResetVariableMappingAction>
): ResetVariableMappingAction => ({
	type: ActionTypes.RESET_VARIABLE_MAPPING,
	payload
});

export const resetVariableMapping =
	(input: { variableName: string; setName?: string }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.RESET_VARIABLE_MAPPING, dispatch });

		const { variableName, setName } = input;

		try {
			activity.begin({ payload: { variableName } });

			const { projectId } = getState().data.variables;

			if (projectId) {
				const { variableMapping } = await context.api.data
					.variablesMapping()
					.resetVariableMapping({
						projectId: Number(projectId),
						variable: { variableName: variableName },
						...(setName !== undefined && { set: { setName } })
					});

				dispatch(resetVariableMappingAction({ projectId, variableMapping }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { variableName } });
		} finally {
			activity.end();
		}
	};

const enableVariablesMappingAction = (
	payload: ActionPayload<EnableVariablesMappingAction>
): EnableVariablesMappingAction => ({
	type: ActionTypes.ENABLE_VARIABLES_MAPPING,
	payload
});

export const enableVariablesMapping =
	(input: { setName?: string } = {}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.ENABLE_VARIABLES_MAPPING, dispatch });

		const { setName } = input;

		try {
			activity.begin({ payload: { setName } });

			const { projectId } = getState().data.variables;

			if (projectId) {
				await context.api.data.variablesMapping().enableVariablesMapping({
					projectId: Number(projectId),
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(enableVariablesMappingAction({ projectId, setName }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { setName } });
		} finally {
			activity.end();
		}
	};

const disableVariablesMappingAction = (
	payload: ActionPayload<DisableVariablesMappingAction>
): DisableVariablesMappingAction => ({
	type: ActionTypes.DISABLE_VARIABLES_MAPPING,
	payload
});

export const disableVariablesMapping =
	(input: { setName?: string } = {}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DISABLE_VARIABLES_MAPPING, dispatch });

		const { setName } = input;

		try {
			activity.begin({ payload: { setName } });

			const { projectId } = getState().data.variables;

			if (projectId) {
				await context.api.data.variablesMapping().disableVariablesMapping({
					projectId: Number(projectId),
					...(setName !== undefined && { set: { setName } })
				});

				dispatch(disableVariablesMappingAction({ projectId, setName }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { setName } });
		} finally {
			activity.end();
		}
	};

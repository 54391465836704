import { useActivity } from 'hooks/store/utils/useActivity';
import { useDispatch } from 'hooks/utils';
import { createAndImport, ActionTypes } from 'store/data/templates';
import { Operation } from 'hooks/store/types';

export function useCreateAndImportTemplate(): Operation<
	(checkedState: { variables: string[]; groups: string[] }) => void
> {
	const dispatch = useDispatch();
	const [{ loading, error }] = useActivity(ActionTypes.CREATE_AND_IMPORT_TEMPLATE);

	function handler({ variables, groups }: { variables: string[]; groups: string[] }) {
		dispatch(createAndImport({ variables, groups }));
	}

	return [{ error, loading }, handler];
}

import { createSelector } from 'reselect';
import { State } from './types';

interface SelectUsersByEmailsProps {
	emails: string[];
}

/*
 * EXTRACTOR FUNCTIONS
 */

function getUsersByEmails(state: State, { emails }: SelectUsersByEmailsProps) {
	return emails.map(email => state.users.byEmail[email]).filter(user => !!user);
}

function getInvitedByEmails(state: State, { emails }: SelectUsersByEmailsProps) {
	return emails.map(email => state.users.invitedByEmail[email]).filter(user => !!user);
}

function getNotAllowedDueToDomainConstraintViolation(state: State) {
	return state.users.notAllowedDueToDomainConstraintViolation;
}

function getAllUsersById({ users }: State) {
	return users.byUserId;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

//

export const selectUsersByEmails = createSelector([getUsersByEmails], project => project);

export const selectInvitedByEmails = createSelector([getInvitedByEmails], project => project);

export const selectNotAllowedDueToDomainConstraintViolation = createSelector(
	[getNotAllowedDueToDomainConstraintViolation],
	notAllowedDueToDomainConstraintViolation => notAllowedDueToDomainConstraintViolation
);

export const selectAllUsersById = createSelector([getAllUsersById], users => users);

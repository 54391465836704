import { useState, useEffect, useMemo, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { nanoid as generate } from 'nanoid';
import { Variable } from 'api/data/variables';
import { areMissingVariablesRequired } from 'helpers/forms';
import {
	DesignerDropZone,
	DraggableFormElements,
	DraggableFormVariablesAndGroups,
	FormTitle,
	DeleteFormModal,
	PreviewFormModal
} from 'components/Forms';
import { FormElement, getLatestForm, ActionTypes as FormsActionTypes } from 'store/data/forms';
import { ElementType } from 'types/index';
import { FormDesignerHeader } from './FormDesignerHeader';
import {
	FormsContainer,
	SettingsContainer,
	BuilderWrapper,
	BuilderContainer,
	Body,
	TitleWrapper,
	ToggleActiveFormContainer,
	Tabs,
	CSSGridContainer,
	MiddleColumn
} from './FormDesignerPage.style';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { Switch } from 'components/UI/Interactables/Switch';
import { PromptToSave } from 'components/UI/PromptToSave';
import { Suspend } from 'components/UI/Suspend';
import {
	buildVariableSetVariablesData,
	buildVariablesRichData,
	variablesDataArrayIterator
} from 'helpers/variables';
import { useMatchProms, useNavigation } from 'hooks/navigation';
import {
	useTranslation,
	usePermissions,
	useVariablesData,
	useVariables,
	useFormSearchTerm,
	useForms,
	useUpdateForm,
	useToggleFormActive,
	useFormId,
	useProjectId,
	useResetForm,
	useFormHasChanges,
	useFormDesignerDragAndDrop,
	useIsProjectValid,
	useActivity,
	useForm
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useDispatch, useEffectOnce } from 'hooks/utils';
import { FormDesignerDrawerContextProvider } from 'contexts';
import { getHeaderHeight } from 'helpers/generic';

const formElements: FormElement[] = [
	{ elementId: generate(), elementType: ElementType.Subtitle, text: '' },
	{ elementId: generate(), elementType: ElementType.Text, text: '' },
	{ elementId: generate(), elementType: ElementType.Separator }
];

export function FormDesignerPage() {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { setError } = useAlerts();
	const { navigate, routes, promOrProject } = useNavigation();
	const matchProms = useMatchProms();

	const menuTitleForDraggable = translate(({ formDesigner }) =>
		matchProms ? formDesigner.promVariables : formDesigner.variables
	);

	const [tooltipContainerElement, setTooltipContainerElement] = useState<HTMLDivElement | null>(
		null
	);
	const getContainerRef = useCallback((node: HTMLDivElement | null) => {
		if (!node) return;
		setTooltipContainerElement(node);
	}, []);

	const { formId: urlFormId } = useParams<{ formId: string }>();

	const { hasVariablesWriteAccess } = usePermissions();

	const variablesData = useVariablesData({ initial: true });

	const [
		{
			data: { variableSetsMap, variablesDataArray },
			loading: loadingVariables,
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true });
	const [searchTerm, setSearchTerm] = useFormSearchTerm();
	const [{ data: form }] = useForm({ lazy: true });
	const [{ loading: loadingForms, fetched: areFormsFetched }] = useForms();
	const [{ loading: updatingForm }, updateForm] = useUpdateForm();

	const [_, toggleFormActive] = useToggleFormActive();

	const [formId, setFormId] = useFormId();
	const [projectId] = useProjectId();

	const resetForm = useResetForm();
	const hasChanges = useFormHasChanges();

	const [initialFormElements, setInitialFormElements] = useState<FormElement[]>(formElements);
	const [cantActivateForm, setCantActivateForm] = useState(false);
	const [formActiveTooltip, setFormActiveTooltip] = useState(false);

	const usedFormVariables = useMemo(() => form?.usedVariables ?? [], [form]);

	const initialFormElementsState = {
		initialFormElements,
		setInitialFormElements
	};

	const { onDragEnd } = useFormDesignerDragAndDrop({
		form,
		initialFormElementsState
	});

	const isProjectValid = useIsProjectValid();

	const [{ loading: loadingLatestForm }] = useActivity(FormsActionTypes.GET_LATEST_FORM);

	// DELETE VARIABLE SET MODAL STATE
	const [deleteFormModalVisible, setDeleteFormModalVisible] = useState(false);
	const deleteFormModal = {
		visible: deleteFormModalVisible,
		open: () => setDeleteFormModalVisible(true),
		close: (success?: boolean) => {
			if (success) goToFormsList();

			setDeleteFormModalVisible(false);
		}
	};

	// DELETE VARIABLE SET MODAL STATE
	const [previewFormModalVisible, setPreviewFormModalVisible] = useState(false);
	const previewFormModal = {
		visible: previewFormModalVisible,
		open: () => setPreviewFormModalVisible(true),
		close: () => setPreviewFormModalVisible(false)
	};

	useEffectOnce(() => {
		return () => {
			setFormId(null);

			if (searchTerm.length) setSearchTerm({ term: '' });
		};
	});

	// fetch initial form
	useEffect(() => {
		if (projectId && isProjectValid && formId && areFormsFetched && areVariablesFetched) {
			dispatch(getLatestForm());
		}
	}, [projectId, isProjectValid, formId, areFormsFetched, areVariablesFetched]);

	useEffect(() => {
		if (areFormsFetched && formId && !form && !deleteFormModal.visible && !loadingLatestForm) {
			setError({ message: translate(dict => dict.formDesigner.noExistingForm) });
			goToFormsList();
		}
	}, [areFormsFetched, formId, form]);

	useEffect(() => {
		if (projectId === null) return;

		// Set `entryId` on reload
		if (urlFormId && formId === null) return setFormId(urlFormId);
	}, [formId, urlFormId, projectId]);

	useEffect(() => {
		if (!areFormsFetched || !areVariablesFetched) return;

		const scopeVariables: Variable[] = [];

		if (form?.setName && form.setName in variableSetsMap) {
			const variableSetVariablesData = buildVariableSetVariablesData({
				setName: form.setName,
				variablesData
			});

			const { variables: setVariables } = buildVariablesRichData(variableSetVariablesData);

			scopeVariables.push(...setVariables);
		} else {
			variablesDataArrayIterator(
				variablesDataArray,
				// VARIABLE
				variable => scopeVariables.push(variable),
				// GROUP
				group => scopeVariables.push(...group.groupVariables),
				// VARIABLE SET - OMIT
				() => null
			);
		}

		const areMissingRequired = areMissingVariablesRequired(scopeVariables, usedFormVariables);

		setCantActivateForm(areMissingRequired);

		if (areMissingRequired && form?.active) toggleFormActive();
	}, [variablesDataArray, usedFormVariables]);

	function goToFormsList() {
		if (projectId) navigate(routes[promOrProject].forms.list(projectId));
	}

	const loadingFormsInitial = loadingForms && !areFormsFetched;

	const loading = loadingFormsInitial || loadingVariables;
	const immediate = !areFormsFetched || !areVariablesFetched;

	return (
		/** FORM DESIGNER DRAWER */
		<FormDesignerDrawerContextProvider>
			<DragDropContext onDragEnd={onDragEnd}>
				<Suspend loading={loading} immediate={immediate}>
					<FormDesignerHeader
						loading={loadingLatestForm}
						deleteFormModal={{ visible: deleteFormModal.visible }}
						onDelete={deleteFormModal.open}
						onPreview={previewFormModal.open}
					/>
					<CSSGridContainer headerHeight={getHeaderHeight().rem} id="grid-container">
						{/* LEFT SIDE */}
						<SettingsContainer sizes={{ m: 0, l: 3, xl: 4 }} displayGridColumn0>
							<Tabs
								startIndex={1}
								labels={[
									translate(dict => dict.formDesigner.formElements),
									menuTitleForDraggable
								]}
							>
								<Tabs.Content>
									<DraggableFormElements
										elements={initialFormElements}
										writeAccess={hasVariablesWriteAccess}
										setInitialFormElements={setInitialFormElements}
									/>
								</Tabs.Content>
								<Tabs.Content>
									<DraggableFormVariablesAndGroups
										writeAccess={hasVariablesWriteAccess}
									/>
								</Tabs.Content>
							</Tabs>
						</SettingsContainer>
						<MiddleColumn
							sizes={{
								xl: 8,
								l: 6,
								m: 6
							}}
							offsets={{
								xl: 4,
								l: 3,
								m: 0
							}}
							// containerHasGutter={false}
						>
							<FormsContainer ref={getContainerRef} disabled={loadingLatestForm}>
								{/* MIDDLE SIDE */}
								<BuilderWrapper>
									<TitleWrapper>
										<ToggleActiveFormContainer
											onMouseEnter={() => setFormActiveTooltip(true)}
											onMouseLeave={() => setFormActiveTooltip(false)}
											data-test-id="togglePromActive"
										>
											<InfoTooltip
												iconVisible={cantActivateForm && formActiveTooltip}
												zIndex={999}
												text={translate(
													({ formDesigner }) =>
														formDesigner.activateFormTooltip
												)}
												position="bottom"
											/>
											<Switch
												label={translate(
													dict => dict.formDesigner.makeFormActive
												)}
												disabled={
													!hasVariablesWriteAccess || cantActivateForm
												}
												onChange={() => toggleFormActive()}
												on={!!form?.active}
												reverse
											/>
										</ToggleActiveFormContainer>
									</TitleWrapper>
									<BuilderContainer>
										<Body>
											<FormTitle />
											<DesignerDropZone
												{...(tooltipContainerElement
													? { tooltipContainer: tooltipContainerElement }
													: {})}
												variablesData={variablesData}
												accessWrite={hasVariablesWriteAccess}
												form={form}
											/>
										</Body>
									</BuilderContainer>
								</BuilderWrapper>
							</FormsContainer>
						</MiddleColumn>
					</CSSGridContainer>
				</Suspend>
			</DragDropContext>

			{/*
				=============
					MODALS
				=============
			*/}

			<PromptToSave
				saving={updatingForm}
				when={hasChanges}
				onSave={updateForm}
				onDiscard={resetForm}
			/>

			{/* DELETE FORM MODAL */}
			{deleteFormModal.visible && formId !== null && (
				<DeleteFormModal formId={formId} onClose={deleteFormModal.close} />
			)}

			{/* PREVIEW FORM MODAL */}
			{previewFormModal.visible && form && (
				<PreviewFormModal form={form} onClose={previewFormModal.close} />
			)}
		</FormDesignerDrawerContextProvider>
	);
}

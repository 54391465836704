import { Variable } from 'api/data/variables';
import { createContext } from 'react';

export interface ContextValue {
	draggedVariables: Variable[];
	setDraggingVariable: (name: string) => void;
}

export const DragVariableContext = createContext<ContextValue>({
	draggedVariables: [],
	setDraggingVariable: () => undefined
});

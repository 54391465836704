import { nanoid as generate } from 'nanoid';
import { MediaQueries, Svgs } from 'environment';
import { SecondaryButtonProps, NeutralButtonProps, PrimaryButtonProps } from 'types/index';
import { IconSizes } from '../Icons';

import { Body, Container, Footer, Header, Tab, Tabs, TitleContainer } from './Modal.style';
import { CSSProperties } from 'styled-components';
import { Backdrop } from '../Backdrop';
import { Flex } from '../Flex';
import { Icon } from '../Icons';
import { Button } from '../Interactables/Button';
import { Spacer } from '../Spacer';
import { Typography } from '../Typography';
import { useActiveModals } from 'hooks/ui';
import { useMemoOnce, useEffectOnce, useKeyPress, useMediaQuery } from 'hooks/utils';
import { HeaderMain } from 'components/Header/HeaderMain';
import { HeaderNavigation } from 'components/Header/HeaderNavigation';

export enum ModalSizes {
	s = 40,
	m = 60,
	full = -1
}

interface Props {
	children: React.ReactNode;
	visible: boolean;
	className?: string;
	title?: string;
	primary?: PrimaryButtonProps;
	neutral?: NeutralButtonProps;
	secondary?: SecondaryButtonProps;
	close?: boolean;
	closeOnBackdrop?: boolean;
	bodySpacing?: boolean;
	local?: boolean;
	tabs?: {
		labels: string[];
		active: number;
		onClick: (tabIndex: number) => void;
		isDisabed?: (tabIndex: number) => boolean;
	};
	enterAsPrimaryOnClick?: boolean;
	fullSizeConfig?: {
		narrow?: boolean;
		centerTitle?: boolean;
		headerMainConfig?: {
			leftComponent?: React.ReactNode;
			rightComponent?: React.ReactNode;
		};
		headerNavigationConfig?: {
			leftComponent?: React.ReactNode;
			rightComponent?: React.ReactNode;
		};
	};
	extendedHeight?: boolean;
	minHeight?: number;
	centerTabs?: boolean;
	modalRef?: React.RefObject<HTMLDivElement> | React.RefCallback<HTMLDivElement>;
	headerStyle?: React.CSSProperties;
	closeIconSize?: IconSizes;
	size?: (type: typeof ModalSizes) => ModalSizes;
	onClose: () => void;
	customBodyStyle?: CSSProperties;
	tooltipContainer?: React.ReactNode;
}

export function Modal({
	children,
	visible,
	className,
	title,
	primary,
	neutral,
	secondary,
	close,
	local = false,
	closeOnBackdrop = true,
	bodySpacing = true,
	tabs,
	enterAsPrimaryOnClick,
	fullSizeConfig,
	centerTabs,
	extendedHeight = false,
	minHeight,
	modalRef,
	headerStyle,
	closeIconSize,
	size,
	onClose,
	customBodyStyle,
	tooltipContainer
}: Props) {
	//DEVICE SIZES
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`,
		false
	);

	function onBackdropClick() {
		if (closeOnBackdrop) handleClose();
	}

	function handleClose() {
		if (canClose()) onClose();
	}

	function handleButtonsClick(onClick?: () => void) {
		if (canClose()) return onClick;
	}

	/**
	 * Disable close modal action when there is an action going on
	 */
	function canClose() {
		let close = true;

		if ((primary && primary.loading) || (neutral && neutral.loading)) {
			close = false;
		}

		return close;
	}

	if (!visible) return null;

	const modalSize = size ? size(ModalSizes) : ModalSizes.m;

	const fullScreenModalWithHeader =
		!!fullSizeConfig?.headerMainConfig?.leftComponent ||
		!!fullSizeConfig?.headerMainConfig?.rightComponent;

	return (
		<Backdrop modalSize={modalSize} local={local} onClick={onBackdropClick} isModal>
			<Controller
				primary={primary}
				enterAsPrimaryOnClick={enterAsPrimaryOnClick}
				handleClose={handleClose}
			/>

			<Container
				className={className}
				modalSize={modalSize}
				fullSizeConfig={fullSizeConfig}
				extendedHeight={extendedHeight}
				minHeight={minHeight}
				data-test-id="modal"
				ref={modalRef}
			>
				{/* HEADER */}
				{fullSizeConfig?.headerMainConfig ? (
					<HeaderMain
						fullWidth
						leftComponent={fullSizeConfig.headerMainConfig.leftComponent}
						rightComponent={fullSizeConfig.headerMainConfig.rightComponent}
					/>
				) : (
					<Header style={headerStyle} className="modal-header">
						{title && (
							<TitleContainer className="modal-header__title-container">
								<Typography.H6
									className="modal-header__title"
									title={title}
									ellipsis
								>
									{title}
								</Typography.H6>
							</TitleContainer>
						)}
						{close && (
							<Icon
								className="modal-header__close-icon"
								dataTestId="close-icon"
								svg={Svgs.Close}
								size={s => closeIconSize ?? s.m}
								marginOffset={{ top: 0.6 }}
								onClick={handleClose}
							/>
						)}
					</Header>
				)}
				{fullSizeConfig?.headerNavigationConfig && (
					<HeaderNavigation
						leftComponent={<></>}
						rightComponent={fullSizeConfig?.headerNavigationConfig.rightComponent}
					/>
				)}

				{/* SUB HEADER WITH TABS */}
				{tabs && (
					<>
						{!title && !close && <Spacer size={s => s.s} />}
						<Tabs data-testid="modal-tabs" centerTabs={centerTabs}>
							{tabs.labels.map((label, index) => (
								<Tab
									data-testid={`modal-tab-${index}`}
									key={label}
									id={label}
									active={tabs.active === index}
									centerTabs={centerTabs}
									disabled={tabs.isDisabed?.(index)}
									onClick={() => !tabs.isDisabed?.(index) && tabs.onClick(index)}
								>
									<Typography.Paragraph>{label}</Typography.Paragraph>
								</Tab>
							))}
						</Tabs>
					</>
				)}

				{/* Tooltip container */}
				{tooltipContainer}

				{/* BODY */}
				<Body
					id="modal-body"
					data-testid="modal-body"
					className="modal-body"
					fullSizeConfig={fullScreenModalWithHeader}
					bodySpacing={bodySpacing}
					style={customBodyStyle}
				>
					{children}
				</Body>

				{/* FOOTER */}
				{(primary || neutral) && (
					<Footer
						className="modal-footer"
						data-test-id={title}
						fullSizeConfig={fullScreenModalWithHeader}
						spaceBetween={!!secondary}
					>
						{secondary && (
							<Button
								className="modal-footer__delete-button"
								title={secondary.label ?? ''}
								variant={v => v.link}
								loading={secondary.loading}
								disabled={secondary.disabled}
								onClick={handleButtonsClick(secondary.onClick)}
								id={'secondary' + title?.replace(/\s/g, '').toLowerCase()}
							/>
						)}
						{!isMobileDevice && (
							<Flex justify={j => j.end}>
								{neutral && (
									<Button
										className="modal-footer__neutral-button"
										variant={v => v.secondary}
										title={neutral.label ?? ''}
										loading={neutral.loading}
										disabled={neutral.disabled}
										marginOffset={primary && { right: 2.4 }}
										onClick={handleButtonsClick(neutral.onClick)}
										id={'neutral' + title?.replace(/\s/g, '').toLowerCase()}
									/>
								)}
								{primary && (
									<Button
										className="modal-footer__primary-button"
										variant={v => (primary.warning ? v.warning : v.primary)}
										title={primary.label ?? ''}
										loading={primary.loading}
										disabled={primary.disabled}
										onClick={handleButtonsClick(primary.onClick)}
										id={'primary' + title?.replace(/\s/g, '').toLowerCase()}
									/>
								)}
							</Flex>
						)}

						{isMobileDevice && (
							<Flex fullWidth column>
								{primary && (
									<Button
										hasFullWidth
										className="modal-footer__primary-button"
										variant={v => (primary.warning ? v.warning : v.primary)}
										title={primary.label ?? ''}
										loading={primary.loading}
										disabled={primary.disabled}
										onClick={handleButtonsClick(primary.onClick)}
										id={'primary' + title?.replace(/\s/g, '').toLowerCase()}
									/>
								)}

								<Spacer size={s => s.m} />

								{neutral && (
									<Button
										hasFullWidth
										className="modal-footer__neutral-button"
										variant={v => v.secondary}
										title={neutral.label ?? ''}
										loading={neutral.loading}
										disabled={neutral.disabled}
										marginOffset={primary && { right: 2.4 }}
										onClick={handleButtonsClick(neutral.onClick)}
										id={'neutral' + title?.replace(/\s/g, '').toLowerCase()}
									/>
								)}
							</Flex>
						)}
					</Footer>
				)}
			</Container>
		</Backdrop>
	);
}

interface ControllerProps {
	primary?: PrimaryButtonProps;
	enterAsPrimaryOnClick?: boolean;
	handleClose: () => void;
}

function Controller({ primary, enterAsPrimaryOnClick, handleClose }: ControllerProps) {
	const modalId = useMemoOnce(() => generate());
	const [activeModals, { addModal, removeModal }] = useActiveModals();

	useEffectOnce(() => {
		// ADD MODAL TO STORE ON MOUNT
		addModal({ modalId });

		// REMOVE MODAL FROM STORE ON UNMOUNT
		return () => removeModal({ modalId });
	});

	const lastModalId = activeModals[activeModals.length - 1];
	const enableShortcuts = modalId === lastModalId;

	useKeyPress(
		{
			// ENTER KEY PRESSED - TRIGGER `primary.onClick`
			onEnterKeyPress: handlePrimaryOnClick
		},
		{ listen: enableShortcuts }
	);

	useKeyPress(
		{
			// ESC KEY PRESSED - CLOSE LAST OPENED MODAL
			onEscapeKeyPress: handleClose
		},
		{ listen: enableShortcuts, noInputFocused: false }
	);

	function handlePrimaryOnClick() {
		if (!(enterAsPrimaryOnClick && primary && primary.onClick)) return;

		if (primary.loading || primary.disabled) return;

		primary.onClick();
	}

	return null;
}

import { useDispatch, useSelector } from 'hooks/utils';
import { LocalOperationResult } from 'hooks/store/types';
import { PageParams } from 'store/data/dependencies';
import {
	selectDependenciesTableParams,
	setDependenciesTablePageParams
} from 'store/data/dependencies';

export function useDependenciesTableParams(): LocalOperationResult<
	PageParams,
	(params: PageParams) => void
> {
	const dispatch = useDispatch();

	const data = useSelector(state => selectDependenciesTableParams(state.data.dependencies));

	function handler(params: PageParams) {
		dispatch(setDependenciesTablePageParams(params));
	}

	return [data, handler];
}

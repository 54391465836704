import * as yup from 'yup';
import { Title } from 'components/Login/styles';
import { InputType } from 'types/index';
import { Input } from 'components/UI/Inputs/Input';
import { Button } from 'components/UI/Interactables/Button';
import { Spacer } from 'components/UI/Spacer';
import { useTranslation, useAccount, useSubmitOneTimePassword } from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useEffect } from 'react';
import { Error } from 'components/UI/Error';

interface Props {
	handleBack: () => void;
}

export function OneTimePasswordForm({ handleBack }: Props) {
	const { translate } = useTranslation();

	const [{ loading: loadingAccount, error: errorLoadingAccount }] = useAccount();
	const [
		{ loading: loadingOtp, error: errorOtp },
		{ handler: submitOneTimePassword, resetError: resetOtpError }
	] = useSubmitOneTimePassword();

	const loading = loadingOtp || loadingAccount;
	const error = errorOtp || errorLoadingAccount;

	const validationSchema = yup.object({
		code: yup
			.string()
			.required(translate(dict => dict.validation.code.required))
			.min(
				6,
				translate(dict => dict.validation.code.valid)
			)
	});

	const { Form, register, handleSubmit, watch, isDirty, errors } = useReactForm({
		mode: 'onSubmit',
		validationSchema
	});

	const handleFormSubmit = handleSubmit(({ code }) => {
		if (isDirty && !error && !loading) submitOneTimePassword(code.replace(/ /g, ''));
	});

	function getErrorMessage() {
		if (errorOtp) {
			return translate(dict => dict.errors.api.login.verifyMFA);
		}
		if (errors.code) {
			return errors.code.message as string;
		}
		return undefined;
	}

	useEffect(() => {
		const subscription = watch(() => resetOtpError());
		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<Form onSubmit={handleFormSubmit}>
			<Title>{translate(dict => dict.codeForm.title)}</Title>
			{errorLoadingAccount && (
				<>
					<Error message={translate(dict => dict.codeForm.failedToLoadAccount)} />
					<Spacer size={s => s.m} />
				</>
			)}
			<Input
				{...register('code')}
				type={InputType.Text}
				label={translate(dict => dict.codeForm.label)}
				placeholder={translate(dict => dict.codeForm.placeholder)}
				error={getErrorMessage()}
				autoComplete="off"
				autoFocus
			/>

			<Spacer size={s => s.m} />

			<Button
				title={translate(dict => dict.buttons.submit)}
				type="submit"
				loading={loading}
				hasPointer={!loading}
				hasFullWidth
			/>

			<Spacer size={s => s.m} />

			<Button
				variant={v => v.outline}
				title={translate(dict => dict.buttons.goBack)}
				onClick={handleBack}
				hasFullWidth
			/>
		</Form>
	);
}

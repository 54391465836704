import styled from 'styled-components';

import { Button as DefaultButton } from 'components/UI/Interactables/Button';

interface MarginProps {
	top?: number;
}

export const SelectContainer = styled.div<MarginProps>`
	display: flex;
	margin-top: ${({ top }) => top && `${top}rem`};
	> :first-child {
		width: calc(100% - 4rem);
	}

	> :last-child {
		visibility: hidden;
	}

	:hover {
		> :last-child {
			visibility: visible;
		}
	}
`;

export const Button = styled(DefaultButton)`
	align-self: flex-start;
	padding: 0;
	transition: unset;
`;

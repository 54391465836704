import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { CompareNumericAnalysisV2 } from 'api/data/analyses';
import {
	AnalysisStatisticsWrapper,
	AnalysisViewContainer,
	AnalysisErrorWrapper
} from 'components/Analysis/Analyses';
import { VariablesData } from 'store/data/variables';
import { ExportFileNames, FileType } from 'types/index';

import { CompareNumericTable } from './CompareNumericTable';
import { CompareNumericStatistics } from './CompareNumericStatistics';
import { mapVariableCategoryValueToLabel } from 'helpers/variables';
import { downloadFile, getExportFileName } from 'helpers/generic';
import { exportCompareNumericV2 } from 'helpers/analysis';
import { useAreFiltersValid, useProject, useTranslation } from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: CompareNumericAnalysisV2;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function CompareNumericView({ analysis, variablesData, exporting, loading }: Props) {
	const { translate } = useTranslation();
	const areFiltersValid = useAreFiltersValid();

	const [{ data: project }] = useProject();

	const { variablesMap } = variablesData;

	const {
		input,
		options: { configPanel },
		output: {
			dataset,
			statistics: { shapiro }
		}
	} = analysis;

	useEffect(() => {
		if (exporting) {
			const data = exportCompareNumericV2(parsedDataset, {
				translate,
				variablesMap,
				variableSetsMap: variablesData.variableSetsMap
			});

			if (data && project) {
				const fileName = getExportFileName(
					ExportFileNames.CompareNumericTableV2,
					project.projectId,
					project.projectName
				);
				downloadFile(data, fileName, FileType.Csv);
			}
		}
	}, [exporting]);

	const { parsedDataset } = useMemo(() => {
		const parsedDataset = cloneDeep(dataset.data ?? {});

		if (analysis.options.showCategoryLabels) {
			Object.values(parsedDataset).forEach(data => {
				data.forEach(val => {
					if (val.group1) {
						val.group1.value =
							mapVariableCategoryValueToLabel(
								val.group1.value,
								variablesMap[val.group1.variable.name]
							) ?? val.group1.value;
					}
					if (val.group2) {
						val.group2.value =
							mapVariableCategoryValueToLabel(
								val.group2.value,
								variablesMap[val.group2.variable.name]
							) ?? val.group2.value;
					}
				});
			});
		}

		return { parsedDataset };
	}, [
		dataset,
		input.variables.categoryVariable,
		variablesMap,
		analysis.options.showCategoryLabels
	]);

	const areStatisticsEnabled = Object.values(input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	return (
		<AnalysisViewContainer isConfigPanelOpen={configPanel.open} loading={loading}>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.CompareNumericV2}>
				<CompareNumericTable
					analysis={analysis}
					variablesData={variablesData}
					dataset={parsedDataset}
					shapiro={{
						visible: input.statistics.shapiroV2,
						data: shapiro
					}}
				/>
				{renderStatistics && (
					<AnalysisStatisticsWrapper>
						<CompareNumericStatistics
							analysis={analysis}
							variablesData={variablesData}
						/>
					</AnalysisStatisticsWrapper>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}

import { useTranslation } from 'hooks/store';
import { Colors, Svgs } from 'environment';

import { PerDistributionStatistics } from 'api/data/dashboard';

import {
	Container,
	Title,
	SentGraphsWrapper,
	DistributionGraphWrapper,
	DistributionStatus,
	DistributionStatusPercentage,
	Icon
} from './ResponseRates.style';
import { Typography } from 'components/UI/Typography';
import { Translate } from 'components/UI/Translate';
import { HSpacer } from 'components/UI/HSpacer';
import { getNumeralOrderShortEng } from 'helpers/displayers';

interface Props {
	promData: PerDistributionStatistics;
	position: number;
}

export function ResponseRates({
	promData: {
		sentInDistribution,
		// filledInDistribution,
		// partiallyFilledInDistribution,
		ratioPartiallyFilled,
		ratioFilled,
		ratioSent,
		totalInDistribution
	},
	position
}: Props) {
	const { translate } = useTranslation();

	const filledPercentage = ratioFilled * 100;
	const partialValue = ratioPartiallyFilled * 100;
	const sentPercentage = ratioSent * 100;

	const toDate = new Date(); // TODO -> update here
	toDate.setMinutes(toDate.getMinutes() - 10);

	const numeralOrder = getNumeralOrderShortEng(position);
	const canShowSentIcon = Math.floor(ratioFilled) === 1;

	return (
		<Container>
			<Title>
				<Typography.H6>
					<Translate
						transKey={dict =>
							dict.promsPatients.distributionList.distributionNumberLabel
						}
						options={{
							index: position,
							orderNumber: numeralOrder
						}}
					>
						<span>{position}</span>
						<sup>{numeralOrder}</sup>
					</Translate>
				</Typography.H6>
				<Icon
					customSize={1.8}
					svg={canShowSentIcon ? Svgs.CheckCircle : Svgs.Clock}
					colors={{
						color: canShowSentIcon ? Colors.vibrantGreen : Colors.text.disabled
					}}
				/>
			</Title>
			<Typography.Caption>
				{translate(dict => dict.proms.title, false, {
					sentStatus: sentInDistribution,
					total: totalInDistribution
				})}
			</Typography.Caption>
			<SentGraphsWrapper>
				<Typography.H4 style={{ width: '7rem' }}>
					{filledPercentage.toFixed(1) + '%'}
				</Typography.H4>
				<HSpacer size={s => s.xs} />
				<DistributionGraphWrapper>
					<DistributionStatus marginBottom={1}>
						<DistributionStatusPercentage
							percentage={filledPercentage}
							color={Colors.surveyChartColors.green.light}
						/>
					</DistributionStatus>
					<DistributionStatus marginBottom={1}>
						<DistributionStatusPercentage
							percentage={partialValue}
							color={Colors.surveyChartColors.yellow}
						/>
					</DistributionStatus>
					<DistributionStatus marginBottom={0}>
						<DistributionStatusPercentage
							percentage={sentPercentage}
							color={Colors.primary.normal}
						/>
					</DistributionStatus>
				</DistributionGraphWrapper>
			</SentGraphsWrapper>
		</Container>
	);
}

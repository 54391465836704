import { getTypographyCSS } from 'components/UI/Typography';
import style from 'styled-components';

export const Container = style.div`
	display: flex;
	
    justify-content: space-between;
`;
type BoxProps = {
	disabled: boolean;
};

export const Box = style.div<BoxProps>`
    user-select: none;
    display: flex;
    gap: 0.37rem;

    padding: 0.9rem;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    ${({ disabled }) =>
		disabled &&
		`

        pointer-events: none;
        opacity: 0.5;
    `}
`;

export const PaginationText = style.span`
    ${getTypographyCSS(t => t.Paragraph)};

    font-weight: 500;  

    text-align: center;
`;

import { useState } from 'react';

import { AlignOptions, Columns, LineOptions, PlotNumericAnalysisV2 } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { MediaQueries } from 'environment';
import { PlotNumericConfigV2 } from './PlotNumericConfigV2';
import { PlotNumericViewV2 } from './PlotNumericViewV2/PlotNumericViewV2';
import { InvalidAnalysis } from '..';
import { AnalysisContainer, AnalysisViewContainer } from '../UI';
import { VariablesData } from 'store/data/variables';
import {
	usePlotNumericAnalysisV2,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useTranslation
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

export interface ScatterOptions {
	line: LineOptions;
	align: AlignOptions;
}

interface Props {
	analysis: PlotNumericAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesDataSelectItems, variablesData, exporting }: Props) {
	const { translate } = useTranslation();
	const [line, setLine] = useState(LineOptions.Mean);
	const [align, setAlign] = useState(AlignOptions.Scattered);

	const { loading } = usePlotNumericAnalysisV2(analysis);

	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const analysisValidInput = isAnalysisInputValid(analysis);
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);
	function handleLineSelect(option: LineOptions) {
		setLine(option);
	}

	function handleAlignSelect(value: AlignOptions) {
		setAlign(value);
	}
	const handleFullScreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};
	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullScreen()} activeColumn={activeColumn}>
				{!loading ? (
					<PlotNumericViewV2
						analysis={analysis}
						variablesData={variablesData}
						exporting={exporting}
						loading={loading}
						line={line}
						align={align}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}

				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<PlotNumericConfigV2
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
						line={line}
						align={align}
						onAlignSelect={handleAlignSelect}
						onLineSelect={handleLineSelect}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis
				{...(!analysisValidInput
					? {
							headerMessage: translate(
								dict => dict.analysis.errors.invalidAnalysis.selectInputHeader
							)
					  }
					: {})}
				{...(!analysisValidInput
					? {
							bodyMessage: translate(
								dict => dict.analysis.errors.invalidAnalysis.selectInputBody
							)
					  }
					: {})}
				activeColumn={activeColumn}
				fullScreen={!!fullscreen}
				isConfigPanelOpen={analysis.options.configPanel.open}
				numeric
				category
			/>
			{(analysis.options.configPanel.open &&
				activeColumn === Columns.OneColumn &&
				!isMobileDevice) ||
			analysis.options.configPanel.open ? (
				<PlotNumericConfigV2
					analysis={analysis}
					variablesDataSelectItems={variablesDataSelectItems}
					loading={loading}
					line={line}
					align={align}
					onAlignSelect={handleAlignSelect}
					onLineSelect={handleLineSelect}
				/>
			) : null}
		</AnalysisContainer>
	);
}

export const PlotNumericV2 = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);

import { Variable } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { useMemo } from 'react';
import { DynamicFormValue } from 'store/data/entries/types';
import { FormElement, FormElementSliderValue } from 'store/data/forms';
import {
	SliderMainWrapper,
	SliderContentContainer,
	RangeInputWrapper,
	InputWrapper,
	CurrentValueDisplay,
	ScaleNumber,
	NumberScaleContainer,
	ValueDisplayWrapper,
	MoodsContainer,
	Mood
} from './SliderInput.style';
import DottedScale from './DottedScale';
import { HTMLInput } from 'types/index';
import { useFormErrors } from 'hooks/store/data/forms/useFormErrors';

interface Props {
	name?: string;
	disabled?: boolean;
	value?: DynamicFormValue;
	element: FormElement;
	variable: Variable;
	onChange?: (e: React.ChangeEvent<HTMLInput>) => void;
}

const SliderInput = ({ disabled, element, variable, value, onChange }: Props) => {
	const { errors: formErrors } = useFormErrors();

	const hasScaleIntervalError = (): boolean =>
		!!formErrors[element.elementId]?.scaleIntervalError;

	const getValues = useMemo(() => {
		return element.sliderValues?.map(sliderValue => sliderValue.value) ?? [];
	}, [element]);

	const currentValue: DynamicFormValue = useMemo(() => {
		return value === '' || value === undefined ? '-' : value;
	}, [value]);

	const getMappings = useMemo(() => {
		return element.sliderValues ?? [];
	}, [element]);

	const sliderValues = useMemo(() => {
		const sliderValues = element.sliderValues ?? [];
		const minValue = parseFloat(sliderValues[0]?.value) ?? 0;
		const maxValue = parseFloat(sliderValues[sliderValues.length - 1]?.value) ?? 0;
		const middleValue = (maxValue + minValue) / 2;

		return { minValue, middleValue, maxValue };
	}, [element]);

	const analogStep = useMemo(() => {
		const precision = 100;
		const range = sliderValues.maxValue - sliderValues.minValue;

		if (variable.type === VariableType.Float) {
			return range / precision;
		}

		return Math.floor(range / precision);
	}, [sliderValues, variable]);

	return (
		<SliderMainWrapper>
			<SliderContentContainer>
				{element.mapValuesWithMoods ? (
					<MoodMappingLine
						minValue={sliderValues.minValue}
						maxValue={sliderValues.maxValue}
						valuesArray={getMappings}
					/>
				) : (
					<div></div>
				)}
				<RangeInputWrapper>
					<InputWrapper hasValue={!!value}>
						<input
							value={value}
							min={sliderValues.minValue}
							max={sliderValues.maxValue}
							disabled={disabled}
							type="range"
							step={element.scaleInterval ?? analogStep}
							onChange={onChange}
						/>
					</InputWrapper>
					<DottedScale
						minValue={sliderValues.minValue}
						maxValue={sliderValues.maxValue}
						scaleInterval={element.scaleInterval ?? null}
						error={hasScaleIntervalError()}
					></DottedScale>
				</RangeInputWrapper>
				<SliderNumberScale
					minValue={sliderValues.minValue}
					maxValue={sliderValues.maxValue}
					valuesArray={getValues}
				/>
			</SliderContentContainer>
			{element.displaySelectedValue && (
				<ValueDisplayWrapper>
					<CurrentValueDisplay disabled={disabled}>
						{currentValue ?? sliderValues.minValue}
					</CurrentValueDisplay>
				</ValueDisplayWrapper>
			)}
		</SliderMainWrapper>
	);
};

interface SliderNumberScaleProps {
	minValue: number;
	maxValue: number;
	valuesArray: string[];
}

const SliderNumberScale = ({ minValue, maxValue, valuesArray }: SliderNumberScaleProps) => {
	const filteredValues = useMemo(() => {
		return valuesArray.filter(num => {
			const parsedNum = parseFloat(num);
			return parsedNum !== minValue && parsedNum !== maxValue;
		});
	}, [valuesArray, minValue, maxValue]);

	const numbers = useMemo(() => {
		return filteredValues.map(num => {
			let parsedNum = parseFloat(num);
			if (isNaN(parsedNum)) parsedNum = 0;
			const percentage = ((parsedNum - minValue) / (maxValue - minValue)) * 100;
			const style = { left: `${percentage}%` };

			return (
				<ScaleNumber key={parsedNum} style={style}>
					{parsedNum}
				</ScaleNumber>
			);
		});
	}, [filteredValues, minValue, maxValue]);

	return (
		<NumberScaleContainer>
			<ScaleNumber>{minValue}</ScaleNumber>
			{numbers}
			<ScaleNumber style={{ left: '100%' }}>{maxValue}</ScaleNumber>
		</NumberScaleContainer>
	);
};

interface MoodMappingLineProps {
	minValue: number;
	maxValue: number;
	valuesArray: FormElementSliderValue[];
}

const MoodMappingLine = ({ minValue, maxValue, valuesArray }: MoodMappingLineProps) => {
	const mappings = useMemo(() => {
		return valuesArray.map((item, index) => {
			const { value, mapping } = item;
			const parsedValue = parseFloat(value);
			const percentage = ((parsedValue - minValue) / (maxValue - minValue)) * 100;
			const style = { left: `${percentage}%` };

			return (
				<Mood key={index} style={style}>
					{mapping}
				</Mood>
			);
		});
	}, [valuesArray, minValue, maxValue]);

	return <MoodsContainer>{mappings}</MoodsContainer>;
};

export default SliderInput;

import { isBefore, parseISO } from 'date-fns';
import { FormikErrors, FormikValues } from 'formik';
import * as yup from 'yup';

import { EntryFilter, Operator, ProjectFilter } from 'api/data/filters';
import { Variable, VariableUniquenessType } from 'api/data/variables';
import { InputType } from 'types/index';
import { ProjectFilterType } from 'types/data/projects/constants';
import { parseFilterNumber } from 'helpers/filters';
import { getMicrosecondsFromTimeDurationString } from 'helpers/entries';
import { VariableType } from 'types/data/variables/constants';

/**
 * TODO: cloned here from 'consts' due to:
 * `Cannot access "<NAME>" before initialization`
 */
const DATE_TIME_REGEX =
	/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9](?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])$/;

const DATE_SCHEMA = yup.object({
	from: yup.date().required('Date required'),
	to: yup.date().required('Date required')
});

const yupDateTime = yup
	.string()
	.trim()
	.nullable()
	.transform((v: number, o: string) => (o === '' ? null : v))
	.matches(DATE_TIME_REGEX, 'Date and time required');

const DATE_TIME_SCHEMA = yup.object({
	from: yupDateTime,
	to: yupDateTime
});

const FLOAT_SCHEMA = yup.object({
	from: yup.number().typeError('Number required'),
	to: yup.number().typeError('Number required')
});

const INT_SCHEMA = yup.object({
	from: yup.number().typeError('Integer required').integer('Integer required'),
	to: yup.number().typeError('Integer required').integer('Integer required')
});

const STRING_SCHEMA = yup.object({
	from: yup.string().trim().required('Value required')
});

const TIME_DURATION_SCHEMA = yup.object({
	from: yup.string().trim().required('Value required'),
	to: yup.string().ensure().notRequired()
});

export function useFiltersHelpers({
	filterType = VariableType.String,
	variable,
	subType
}: {
	filterType?: VariableType;
	subType?: VariableUniquenessType;
	variable?: Variable;
}) {
	let inputType = InputType.Text;
	let validationSchema = {};
	const variableType = variable?.type ?? filterType;

	switch (filterType) {
		case VariableType.Date: {
			inputType = InputType.Date;
			validationSchema = DATE_SCHEMA;
			break;
		}

		case VariableType.DateTime: {
			inputType = InputType.DateTime;
			validationSchema = DATE_TIME_SCHEMA;
			break;
		}

		case VariableType.Float: {
			inputType = InputType.Text;
			validationSchema = FLOAT_SCHEMA;
			break;
		}

		// note: timeDuration is masked as integer filterType
		case VariableType.Integer: {
			inputType = InputType.Text;
			validationSchema =
				variableType === VariableType.TimeDuration ? TIME_DURATION_SCHEMA : INT_SCHEMA;
			break;
		}

		case VariableType.String:
		case VariableType.Unique: {
			if (subType === VariableUniquenessType.Sequence) {
				inputType = InputType.Number;
				validationSchema = INT_SCHEMA;
			} else {
				inputType = InputType.Text;
				validationSchema = STRING_SCHEMA;
			}

			break;
		}

		case VariableType.TimeDuration: {
			inputType = InputType.Text;
			validationSchema = TIME_DURATION_SCHEMA;
			break;
		}

		default:
			break;
	}

	function validate({ from, to }: FormikValues) {
		const errors: FormikErrors<FormikValues> = {};

		if (
			filterType === VariableType.Float ||
			filterType === VariableType.Integer ||
			(filterType === VariableType.Unique && subType === VariableUniquenessType.Sequence)
		) {
			if (parseFilterNumber(from, filterType) > parseFilterNumber(to, filterType)) {
				errors.from = 'From value is larger than to value';
				errors.to = 'From value is larger than to value';
			}
		} else if ([VariableType.Date, VariableType.DateTime].includes(filterType)) {
			if (isBefore(new Date(to), new Date(from))) {
				errors.from = 'To value is before from value';
				errors.to = 'To value is before from value';
			}
		}

		return errors;
	}

	function hasFilterChanged(filter: EntryFilter, updatedFilter: EntryFilter) {
		return (
			filter.operator !== updatedFilter.operator ||
			(updatedFilter.value !== undefined && filter.value !== updatedFilter.value) ||
			(updatedFilter.from !== undefined && filter.from !== updatedFilter.from) ||
			filter.to !== updatedFilter.to
		);
	}

	function parseResults({
		filter,
		operator,
		from,
		to
	}: {
		filter: EntryFilter;
		operator: Operator;
		from: string;
		to: string;
	}) {
		const updatedFilter: EntryFilter = { ...filter };
		updatedFilter.operator = operator;

		const isFromValid = from.toString().trim().length > 0;
		const isToValid = to.toString().trim().length > 0;

		// TIME DURATION
		if (variableType === VariableType.TimeDuration) {
			if (operator === Operator.Between && variable?.durationFormat) {
				if (from && to) {
					if (
						!(
							getMicrosecondsFromTimeDurationString(from, variable.durationFormat) <=
							getMicrosecondsFromTimeDurationString(to, variable.durationFormat)
						)
					)
						return;
					delete updatedFilter.value;

					updatedFilter.from = from;
					updatedFilter.to = to;

					if (hasFilterChanged(filter, updatedFilter)) {
						return updatedFilter;
					}
				}
			} else {
				if (from) {
					delete updatedFilter.from;
					delete updatedFilter.to;

					updatedFilter.value = from;
					if (hasFilterChanged(filter, updatedFilter)) {
						return updatedFilter;
					}
				}
			}
		}

		// INTEGER | FLOAT
		if (
			filterType === VariableType.Float ||
			filterType === VariableType.Integer ||
			(filterType === VariableType.Unique &&
				filter.filterSubType === VariableUniquenessType.Sequence) ||
			(filterType === VariableType.Unique &&
				variable?.uniquenessType === VariableUniquenessType.Sequence)
		) {
			const parsedFrom = parseFilterNumber(from, filterType);
			const parsedTo = parseFilterNumber(to, filterType);

			if (operator === Operator.Between) {
				if (!isNaN(parsedFrom) && !isNaN(parsedTo)) {
					if (parsedTo >= parsedFrom) {
						delete updatedFilter.value;

						updatedFilter.from = parsedFrom;
						updatedFilter.to = parsedTo;

						if (hasFilterChanged(filter, updatedFilter)) {
							return updatedFilter;
						}
					}
				}
			} else {
				if (!isNaN(parsedFrom)) {
					delete updatedFilter.from;
					delete updatedFilter.to;

					updatedFilter.value = parsedFrom;
					if (
						filterType === VariableType.Unique &&
						variable?.uniquenessType === VariableUniquenessType.Sequence
					) {
						updatedFilter.filterType = VariableType.Integer;
					}

					if (hasFilterChanged(filter, updatedFilter)) {
						return updatedFilter;
					}
				}
			}
		}

		// DATE | DATETIME
		if ([VariableType.Date, VariableType.DateTime].includes(filterType)) {
			if (operator === Operator.Between) {
				if (isFromValid && isToValid) {
					if (isBefore(parseISO(from), parseISO(to))) {
						delete updatedFilter.value;

						updatedFilter.from = from;
						updatedFilter.to = to;

						if (hasFilterChanged(filter, updatedFilter)) {
							return updatedFilter;
						}
					}
				}
			} else {
				if (isFromValid) {
					delete updatedFilter.from;
					delete updatedFilter.to;

					updatedFilter.value = from;

					if (hasFilterChanged(filter, updatedFilter)) {
						return updatedFilter;
					}
				}
			}
		}

		// STRING
		if (
			filterType === VariableType.String ||
			(filterType === VariableType.Unique &&
				filter.filterSubType === VariableUniquenessType.Manual) ||
			(filterType === VariableType.Unique &&
				filter.filterSubType === VariableUniquenessType.UUID)
		) {
			if (isFromValid) {
				updatedFilter.value = from.trim();

				if (hasFilterChanged(filter, updatedFilter)) {
					return updatedFilter;
				}
			}
		}
	}

	function shouldInvalidateFilter(operator: Operator, from: string, to: string) {
		const isFromInvalid = !from.trim().length;
		const isToInvalid = !to.trim().length;

		if (operator === Operator.Between) {
			if (isFromInvalid || isToInvalid) return true;
		} else {
			if (isFromInvalid) return true;
		}

		return false;
	}

	return {
		inputType,
		parseResults,
		shouldInvalidateFilter,
		validate,
		validationSchema
	};
}

export function useProjectsFiltersHelpers(filterType = ProjectFilterType.String) {
	let inputType = InputType.Text;
	let validationSchema = {};

	switch (filterType) {
		case ProjectFilterType.Date: {
			inputType = InputType.Date;
			validationSchema = DATE_SCHEMA;
			break;
		}

		case ProjectFilterType.Integer: {
			inputType = InputType.Text;
			validationSchema = INT_SCHEMA;
			break;
		}

		case ProjectFilterType.String: {
			inputType = InputType.Text;
			validationSchema = STRING_SCHEMA;
			break;
		}

		default:
			break;
	}

	function validate({ from, to }: FormikValues) {
		const errors: FormikErrors<FormikValues> = {};

		if (filterType === ProjectFilterType.Integer) {
			if (parseFilterNumber(from, filterType) > parseFilterNumber(to, filterType)) {
				errors.from = 'From value is larger than to value';
				errors.to = 'From value is larger than to value';
			}
		} else if (ProjectFilterType.Date === filterType) {
			if (isBefore(new Date(to), new Date(from))) {
				errors.from = 'To value is before from value';
				errors.to = 'To value is before from value';
			}
		}

		return errors;
	}

	function hasFilterChanged(filter: ProjectFilter, updatedFilter: ProjectFilter) {
		return (
			filter.operator !== updatedFilter.operator ||
			(updatedFilter.value !== undefined && filter.value !== updatedFilter.value) ||
			(updatedFilter.from !== undefined && filter.from !== updatedFilter.from) ||
			filter.to !== updatedFilter.to
		);
	}

	function parseResults(filter: ProjectFilter, operator: Operator, from: string, to: string) {
		const updatedFilter: ProjectFilter = { ...filter };
		updatedFilter.operator = operator;

		const isFromValid = from.trim().length > 0;
		const isToValid = to.trim().length > 0;

		// INTEGER
		if (filterType === ProjectFilterType.Integer) {
			const parsedFrom = parseFilterNumber(from, filterType);
			const parsedTo = parseFilterNumber(to, filterType);

			if (operator === Operator.Between) {
				if (!isNaN(parsedFrom) && !isNaN(parsedTo)) {
					if (parsedTo >= parsedFrom) {
						delete updatedFilter.value;

						updatedFilter.from = parsedFrom;
						updatedFilter.to = parsedTo;

						if (hasFilterChanged(filter, updatedFilter)) {
							return updatedFilter;
						}
					}
				}
			} else {
				if (!isNaN(parsedFrom)) {
					delete updatedFilter.from;
					delete updatedFilter.to;

					updatedFilter.value = parsedFrom;

					if (hasFilterChanged(filter, updatedFilter)) {
						return updatedFilter;
					}
				}
			}
		}

		// DATE
		if (ProjectFilterType.Date === filterType) {
			if (operator === Operator.Between) {
				if (isFromValid && isToValid) {
					if (isBefore(parseISO(from), parseISO(to))) {
						delete updatedFilter.value;

						updatedFilter.from = from;
						updatedFilter.to = to;

						if (hasFilterChanged(filter, updatedFilter)) {
							return updatedFilter;
						}
					}
				}
			} else {
				if (isFromValid) {
					delete updatedFilter.from;
					delete updatedFilter.to;

					updatedFilter.value = from;

					if (hasFilterChanged(filter, updatedFilter)) {
						return updatedFilter;
					}
				}
			}
		}

		// STRING
		if (filterType === ProjectFilterType.String) {
			if (isFromValid) {
				updatedFilter.value = from.trim();

				if (hasFilterChanged(filter, updatedFilter)) {
					return updatedFilter;
				}
			}
		}
	}

	function shouldInvalidateFilter(operator: Operator, from: string, to: string) {
		const isFromInvalid = !from.trim().length;
		const isToInvalid = !to.trim().length;

		if (operator === Operator.Between) {
			if (isFromInvalid || isToInvalid) return true;
		} else {
			if (isFromInvalid) return true;
		}

		return false;
	}

	return {
		inputType,
		parseResults,
		shouldInvalidateFilter,
		validate,
		validationSchema
	};
}

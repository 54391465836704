import styled from 'styled-components';

import { DropdownItem as DefaultDropdownItem } from 'components/UI/Dropdown/DropdownItem';

interface CategoryWrapperProps {
	usedInFormDesigner?: boolean;
}
export const CategoryWrapper = styled.div<CategoryWrapperProps>`
	pointer-events: ${({ usedInFormDesigner }) => usedInFormDesigner && 'none'};
`;

export const DropdownItem = styled(DefaultDropdownItem)`
	display: flex;
	align-items: center;
`;

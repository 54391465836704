import { useDispatch } from 'hooks/utils';
import { LocalOperation } from 'hooks/store/types';
import { createAnalysis, CreateAnalysisAction } from 'store/data/analyses';
import { ActionPayload } from 'store/types';

type Input = ActionPayload<CreateAnalysisAction>['analysis'];

export function useCreateAnalysis(): LocalOperation<(analysis: Input) => void> {
	const dispatch = useDispatch();

	function handler(analysis: Input) {
		dispatch(createAnalysis({ analysis }));
	}

	return handler;
}

import { useState, useRef } from 'react';
import { throttle } from 'lodash';
import { Svgs } from 'environment';
import { selectProjectEntriesCount } from 'store/data/projects';
import { CollaboratorPermissions } from 'store/data/collaborators';
import { EventType } from 'types/index';
import { ProjectCollaboratorAvatars } from '../ProjectCollaboratorAvatars';
import { Option } from '../ProjectsTable';

import {
	Body,
	Container,
	Description,
	MoreIconContainer,
	Footer,
	Header,
	ReadMore,
	Title,
	EntriesCount,
	WarningIcon
} from './ProjectCard.style';
import { Typography } from 'components/UI/Typography';
import { Dropdown } from 'components/UI/Dropdown';
import { Icon } from 'components/UI/Icons';
import { stringAsBoolean, loseFocus, showOnTouchDevices } from 'helpers/generic';
import { computeProjectTitle } from 'helpers/projects';
import { useTranslation, useProjectById, useForbidPersonalData } from 'hooks/store';
import { useOutsideClick, useEffectOnce, useSelector } from 'hooks/utils';

const IS_PROJECT_SHARED_AVATARS_ENABLED = stringAsBoolean(
	process.env.REACT_APP_PROJECT_SHARED_AVATARS!
);

export interface ProjectCardOptions {
	onClick: (projectId: string, userAcces?: CollaboratorPermissions) => void;
	onReadMore: (description: string) => void;
}

interface Props extends ProjectCardOptions {
	projectId: string;
	showEntries: boolean;
	projectOptions: (projectOwner?: string, userAcces?: CollaboratorPermissions) => Option[];
}

export function ProjectCard({
	projectId,
	onClick,
	onReadMore,
	showEntries,
	projectOptions
}: Props) {
	const { translate } = useTranslation();

	const [menuVisible, setMenuVisible] = useState(false);
	const elementValueRef = useRef<HTMLAnchorElement>(null);

	useOutsideClick(() => setMenuVisible(false), [elementValueRef]);

	const project = useProjectById(projectId);

	const projectEntriesCount = useSelector(state =>
		selectProjectEntriesCount(state.data.projects, { projectId })
	);

	const { forbidPersonalData } = useForbidPersonalData(projectId ?? '');

	if (!project) return null;

	function onReadMoreClick(e: React.MouseEvent) {
		stopEvent(e);
		closeMoreMenu();
		if (project) onReadMore(project.description);
	}

	function closeMoreMenu() {
		setMenuVisible(false);
		loseFocus();
	}

	function getTitle() {
		const title = project ? computeProjectTitle(project) : '';

		return <Title title={title}>{title}</Title>;
	}

	function stopEvent(e: React.MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
	}

	return (
		<Container
			ref={elementValueRef}
			onClick={() => onClick(projectId, project.userAccess)}
			data-test-id={project.projectName.replaceAll(/[^a-zA-Z]+/g, '').toLowerCase()}
			id={project.projectName.replaceAll(/[^a-zA-Z]+/g, '').toLowerCase()}
			menuVisible={menuVisible}
			data-testid={'project-card_' + project.projectName}
		>
			{forbidPersonalData && (
				<WarningIcon>
					<Icon svg={Svgs.Pdna} size={s => s.s} />
				</WarningIcon>
			)}
			<Header>
				<Typography.Hint title={project.lastModifiedDate}>
					{project.lastModifiedDate}
				</Typography.Hint>

				<MoreIconContainer
					visible={menuVisible}
					className={showOnTouchDevices('card-more-icon')}
				>
					<Dropdown
						toggleComponent={({ ref, toggle }) => (
							<Icon
								ref={ref}
								variant={v => v.button}
								svg={Svgs.More}
								onClick={e => {
									stopEvent(e);
									loseFocus();

									setMenuVisible(state => !state);
									toggle();
								}}
								active={menuVisible}
								dataTestId={
									project
										? 'project-settings-dropdown_' + project.projectName
										: undefined
								}
							/>
						)}
						width={18}
						offset={{ top: 20, right: 20 }}
					>
						{projectOptions(project.createdByUser, project.userAccess).map(
							(option, index) => (
								<Dropdown.Item
									key={index}
									title={option.title}
									onClick={() => option.onClick(projectId)}
									dataTestId={'project-settings-dropdown-item_' + option.title}
								/>
							)
						)}
					</Dropdown>
				</MoreIconContainer>
			</Header>
			<Body>
				{getTitle()}
				<ProjectDescription
					description={project.description}
					onReadMoreClick={onReadMoreClick}
				/>
			</Body>
			<Footer alignItemsRight={!showEntries}>
				{showEntries && (
					<EntriesCount>
						{projectEntriesCount ?? 0} {translate(dict => dict.project.entries)}
					</EntriesCount>
				)}
				{IS_PROJECT_SHARED_AVATARS_ENABLED && (
					<ProjectCollaboratorAvatars projectId={projectId} />
				)}
			</Footer>
		</Container>
	);
}

interface ProjectDescriptionProps {
	description: string;
	onReadMoreClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

function ProjectDescription({ description, onReadMoreClick }: ProjectDescriptionProps) {
	const { translate } = useTranslation();

	const descriptionRef = useRef<HTMLDivElement>(null);

	const [showReadMore, setShowReadMore] = useState(false);

	useEffectOnce(() => {
		handleReadMore();

		window.addEventListener(EventType.Resize, onResize, true);

		return () => window.removeEventListener(EventType.Resize, onResize, true);
	});

	const onResize = throttle(handleReadMore, 100);

	/**
	 * TRACK WINDOW RESIZE -> SHOW READ MORE BUTTON ONLY WHEN ELLIPSIS FIRES
	 */
	function handleReadMore() {
		if (!descriptionRef.current) return;

		const { clientHeight, scrollHeight } = descriptionRef.current;

		const ellipsisExists = clientHeight !== scrollHeight;

		setShowReadMore(ellipsisExists);
	}

	return (
		<>
			<Description ref={descriptionRef} title={description}>
				{description}
			</Description>

			{showReadMore && (
				<div>
					<ReadMore onClick={onReadMoreClick}>
						{translate(dict => dict.project.readMore)}
					</ReadMore>
				</div>
			)}
		</>
	);
}

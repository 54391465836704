import { useEffect } from 'react';
import { OperationResult } from 'hooks/store/types';
import {
	getSnapshots,
	selectAreSnapshotsFetched,
	selectSnapshotIds,
	ActionTypes
} from 'store/data/snapshots';
import { useDispatch, useSelector } from 'hooks/utils';
import { usePermissions } from 'hooks/store/ui/usePermissions';
import { useActivity } from 'hooks/store/utils/useActivity';
import { useIsProjectValid } from '../projects/useIsProjectValid';
import { useProjectId } from '../projects/useProjectId';

export function useSnapshots(): OperationResult<string[]> {
	const dispatch = useDispatch();

	const { hasAnalysisAccess } = usePermissions();
	const isProjectValid = useIsProjectValid();

	const [projectId] = useProjectId();

	const fetched = useSelector(state => selectAreSnapshotsFetched(state.data.snapshots));
	const data = useSelector(state => selectSnapshotIds(state.data.snapshots));

	const [{ loading, error }, resetError] = useActivity(ActionTypes.GET_SNAPSHOTS, {
		projectId
	});

	useEffect(() => {
		if (!error && !fetched && !loading && isProjectValid && hasAnalysisAccess) {
			handler();
		}
	}, [error, fetched, loading, isProjectValid, hasAnalysisAccess]);

	function handler() {
		if (loading) return;
		if (error) resetError();

		dispatch(getSnapshots());
	}

	return [{ loading, error, data }, handler];
}

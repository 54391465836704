import { PROJECTS_URL, sendRequest, USER_URL } from 'api/utils';
import {
	parseApiRole,
	parseApiRoles,
	parseApiTemplateRole,
	parseApiTemplateRoles
} from 'store/data/roles/parsers';

import {
	AssignRoleInput,
	AssignRoleOutput,
	AssignRoleRequest,
	AssignRoleResponse,
	CreateRoleInput,
	CreateRoleOutput,
	CreateRoleRequest,
	CreateRoleResponse,
	CreateTemplateRoleInput,
	CreateTemplateRoleOutput,
	CreateTemplateRoleRequest,
	CreateTemplateRoleResponse,
	DeleteRoleInput,
	DeleteRoleOutput,
	DeleteRoleRequest,
	DeleteRoleResponse,
	DeleteTemplateRoleInput,
	DeleteTemplateRoleOutput,
	DeleteTemplateRoleRequest,
	DeleteTemplateRoleResponse,
	GetRolesInput,
	GetRolesOutput,
	GetRolesRequest,
	GetRolesResponse,
	GetRoleTemplateShareListInput,
	GetRoleTemplateShareListOutput,
	GetRoleTemplateShareListRequest,
	GetRoleTemplateShareListResponse,
	GetTemplateRolesOutput,
	GetTemplateRolesRequest,
	GetTemplateRolesResponse,
	ShareTemplateRoleInput,
	ShareTemplateRoleOutput,
	ShareTemplateRoleRequest,
	ShareTemplateRoleResponse,
	UnshareTemplateRoleInput,
	UnshareTemplateRoleOutput,
	UnshareTemplateRoleRequest,
	UnshareTemplateRoleResponse,
	UpdateRoleInput,
	UpdateRoleOutput,
	UpdateRoleRequest,
	UpdateRoleResponse,
	UpdateTemplateRoleInput,
	UpdateTemplateRoleOutput,
	UpdateTemplateRoleRequest,
	UpdateTemplateRoleResponse
} from './types';

const methods = {
	// TEMPLATE ROLES
	getTemplateRoles: 'getRoleTemplates',
	createTemplateRole: 'createRoleTemplate',
	updateTemplateRole: 'updateRoleTemplate',
	deleteTemplateRole: 'deleteRoleTemplate',
	shareTemplateRole: 'shareRoleTemplate',
	unshareTemplateRole: 'unShareRoleTemplate',
	getRoleTemplateShareList: 'getUsersAndProjectsRoleTemplateSharedWith',
	// PROJECT ROLES
	getRoles: 'getProjectRoles',
	createRole: 'createProjectRole',
	updateRole: 'updateProjectRole',
	deleteRole: 'deleteProjectRole',
	assignRole: 'updateUsersAccessRights'
};

export default () => ({
	async getTemplateRoles(): Promise<GetTemplateRolesOutput> {
		const { data } = await sendRequest<GetTemplateRolesRequest, GetTemplateRolesResponse>(
			USER_URL,
			{
				method: methods.getTemplateRoles
			}
		);

		if (!data.ownedTemplates || !data.sharedTemplates || !data.publicTemplates)
			throw new Error();

		const output: GetTemplateRolesOutput = {
			ownedTemplateRoles: parseApiTemplateRoles(data.ownedTemplates),
			sharedTemplateRoles: parseApiTemplateRoles(data.sharedTemplates),
			publicTemplateRoles: parseApiTemplateRoles(data.publicTemplates)
		};

		return output;
	},

	async createTemplateRole(input: CreateTemplateRoleInput): Promise<CreateTemplateRoleOutput> {
		const { data } = await sendRequest<CreateTemplateRoleRequest, CreateTemplateRoleResponse>(
			USER_URL,
			{
				method: methods.createTemplateRole,
				...input
			}
		);

		if (!data.roleTemplates) throw new Error();

		const output: CreateTemplateRoleOutput = {
			templateRole: parseApiTemplateRole(data.roleTemplates[0])
		};

		return output;
	},

	async updateTemplateRole(input: UpdateTemplateRoleInput): Promise<UpdateTemplateRoleOutput> {
		const { data } = await sendRequest<UpdateTemplateRoleRequest, UpdateTemplateRoleResponse>(
			USER_URL,
			{
				method: methods.updateTemplateRole,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();
	},

	async shareTemplateRole(input: ShareTemplateRoleInput): Promise<ShareTemplateRoleOutput> {
		const { data } = await sendRequest<ShareTemplateRoleRequest, ShareTemplateRoleResponse>(
			USER_URL,
			{
				method: methods.shareTemplateRole,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();
	},

	async unshareTemplateRole(input: UnshareTemplateRoleInput): Promise<UnshareTemplateRoleOutput> {
		const { data } = await sendRequest<UnshareTemplateRoleRequest, UnshareTemplateRoleResponse>(
			USER_URL,
			{
				method: methods.unshareTemplateRole,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();
	},

	async getRoleTemplateShareList(
		input: GetRoleTemplateShareListInput
	): Promise<GetRoleTemplateShareListOutput> {
		const { data } = await sendRequest<
			GetRoleTemplateShareListRequest,
			GetRoleTemplateShareListResponse
		>(USER_URL, {
			method: methods.getRoleTemplateShareList,
			...input
		});

		if (data.statusCode !== '200') throw new Error();

		const projectsIds = data.projects
			.map(obj => obj.projectId)
			.filter((id, index, self) => self.indexOf(id) === index);
		const usersIds = data.users
			.map(obj => obj.userid)
			.filter((id, index, self) => self.indexOf(id) === index);

		const output: GetRoleTemplateShareListOutput = {
			projects: projectsIds.reverse(),
			users: usersIds.reverse(),
			roleTemplateId: input.roleTemplate.roleTemplateId.toString()
		};

		return output;
	},

	async deleteTemplateRole(input: DeleteTemplateRoleInput): Promise<DeleteTemplateRoleOutput> {
		const { data } = await sendRequest<DeleteTemplateRoleRequest, DeleteTemplateRoleResponse>(
			USER_URL,
			{
				method: methods.deleteTemplateRole,
				...input
			}
		);

		if (data.statusCode !== '200') throw new Error();
	},

	async getRoles(input: GetRolesInput): Promise<GetRolesOutput> {
		const { data } = await sendRequest<GetRolesRequest, GetRolesResponse>(PROJECTS_URL, {
			method: methods.getRoles,
			...input
		});

		if (!data.projectRoles) throw new Error();

		const output: GetRolesOutput = {
			roles: parseApiRoles(data.projectRoles)
		};

		return output;
	},

	async createRole(input: CreateRoleInput): Promise<CreateRoleOutput> {
		const { data } = await sendRequest<CreateRoleRequest, CreateRoleResponse>(PROJECTS_URL, {
			method: methods.createRole,
			...input
		});

		if (!data.projectRoles) throw new Error();

		const output: CreateRoleOutput = {
			role: parseApiRole(data.projectRoles[0])
		};

		return output;
	},

	async updateRole(input: UpdateRoleInput): Promise<UpdateRoleOutput> {
		const { data } = await sendRequest<UpdateRoleRequest, UpdateRoleResponse>(PROJECTS_URL, {
			method: methods.updateRole,
			...input
		});

		if (data.statusCode !== '200') throw new Error();
	},

	async deleteRole(input: DeleteRoleInput): Promise<DeleteRoleOutput> {
		const { data } = await sendRequest<DeleteRoleRequest, DeleteRoleResponse>(PROJECTS_URL, {
			method: methods.deleteRole,
			...input
		});

		if (data.statusCode !== '200') throw new Error();
	},

	async assignRole(input: AssignRoleInput): Promise<AssignRoleOutput> {
		const { data } = await sendRequest<AssignRoleRequest, AssignRoleResponse>(PROJECTS_URL, {
			method: methods.assignRole,
			...input
		});

		if (data.statusCode !== '200') throw new Error();
	}
});

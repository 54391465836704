import { CheckboxGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { InputType } from 'types/index';
import { CheckboxWrapper, Container, Input } from './TimeDurationFields.style';
import { useEffect, useMemo, useState } from 'react';
import { ORDERED_TIME_DURATION_KEYS, TimeDurationKey } from 'timeDurationConsts';
import { TimeDurationFormat, Variable } from 'api/data/variables';
import { useMemoOnce, useSelector } from 'hooks/utils';
import { useTranslation } from 'hooks/store';
import { Dictionary } from 'environment';
import { selectLanguage } from 'store/ui/i18n';

const WEEK_INDEX = ORDERED_TIME_DURATION_KEYS.findIndex(d => d === TimeDurationKey.Weeks);

const getUncommonElement = (first: string[], second: string[]) => {
	const res = [];
	for (let i = 0; i < first.length; i++) {
		if (second.indexOf(first[i]) === -1) {
			res.push(first[i]);
		}
	}
	for (let j = 0; j < second.length; j++) {
		if (first.indexOf(second[j]) === -1) {
			res.push(second[j]);
		}
	}
	return res;
};

interface Props {
	variable?: Variable;
	onFormatChange: (value?: TimeDurationFormat) => void;
}

export function TimeDurationFields({ variable, onFormatChange }: Props) {
	const { translate } = useTranslation();
	const language = useSelector(state => selectLanguage(state.ui.i18n));

	const SELECT_TIME_OPTIONS = useMemo(() => {
		return ORDERED_TIME_DURATION_KEYS.map(key => {
			const singularKey = key as keyof typeof Dictionary.timeDurationPlaceholder.full;
			return {
				label: translate(
					({ timeDurationPlaceholder }) => timeDurationPlaceholder.full[singularKey]
				),
				value: key
			};
		});
	}, [language]);

	const initialFormat: Partial<Record<TimeDurationKey, boolean>> = useMemoOnce(() => {
		if (!variable?.durationFormat) return {};
		return variable.durationFormat.reduce(
			(acc, key) => ({
				...acc,
				[key]: true
			}),
			{} as Partial<Record<TimeDurationKey, boolean>>
		);
	});

	const [format, setFormat] = useState(initialFormat);

	const stringFormat = useMemo(() => {
		return ORDERED_TIME_DURATION_KEYS.filter(key => format[key])
			.map((s: string) =>
				translate(
					dict =>
						dict.timeDurationPlaceholder.full[
							s as keyof typeof dict.timeDurationPlaceholder.full
						]
				)
			)
			.join(':');
	}, [format]);

	// mutate variable (draftVariable) state to keep track of changes
	useEffect(() => {
		const newFormat: TimeDurationFormat = [];
		Object.entries(format).forEach(([key, val]) => {
			if (val) newFormat.push(key as TimeDurationKey);
		});

		const sortedFormat = newFormat.sort((a, b) => {
			const indexA = ORDERED_TIME_DURATION_KEYS.findIndex(key => key === a);
			const indexB = ORDERED_TIME_DURATION_KEYS.findIndex(key => key === b);

			return indexA < indexB ? -1 : 1;
		});
		onFormatChange(sortedFormat);
	}, [format]);

	const selectedValues = useMemo(() => {
		return ORDERED_TIME_DURATION_KEYS.filter(key => !!format[key]);
	}, [format]);

	function onFieldSelect(values: string[]) {
		const prevSelected = { ...format };

		/**
		 * COMPUTE INDEXES
		 */
		const selectedValue = getUncommonElement(values, selectedValues)[0];
		const selectedIndex = ORDERED_TIME_DURATION_KEYS.findIndex(
			item => item === (selectedValue as TimeDurationKey)
		);
		const firstIndex = ORDERED_TIME_DURATION_KEYS.findIndex(item => values.includes(item));
		//@ts-ignore ts BUG
		const lastIndex = ORDERED_TIME_DURATION_KEYS.findLastIndex(item => values.includes(item));

		/**
		 * SELECTION CASES
		 **/

		// WEEKS SPECIAL CASE
		if (firstIndex === 2 || lastIndex === 2) {
			prevSelected[ORDERED_TIME_DURATION_KEYS[2]] = true;
		}

		if (firstIndex === lastIndex) {
			if (firstIndex !== -1 && lastIndex !== -1) {
				// UNCHECK REST
				ORDERED_TIME_DURATION_KEYS.forEach(key => (prevSelected[key] = false));
				// ONE SELECTION;
				prevSelected[ORDERED_TIME_DURATION_KEYS[firstIndex]] = true;
			} else {
				// SELECTED ONLY EXISTING SELECTION
				prevSelected[ORDERED_TIME_DURATION_KEYS[selectedIndex]] = false;
			}
		} else if (firstIndex !== -1 && lastIndex !== -1) {
			// RANGE SELECTION
			let end = lastIndex;
			if (selectedIndex < lastIndex && selectedIndex > firstIndex) end = selectedIndex - 1;

			for (let i = firstIndex; i <= lastIndex; i++) {
				prevSelected[ORDERED_TIME_DURATION_KEYS[i]] = true;
			}
			// UNSELECT PREVIOUS OUTED VALUES ([FIRST, SELECTED] AND [SELECTED, LAST])
			for (let i = 0; i < firstIndex; i++) {
				prevSelected[ORDERED_TIME_DURATION_KEYS[i]] = false;
			}
			for (let i = end + 1; i < ORDERED_TIME_DURATION_KEYS.length; i++) {
				prevSelected[ORDERED_TIME_DURATION_KEYS[i]] = false;
			}
			if (WEEK_INDEX > firstIndex && WEEK_INDEX < end) {
				prevSelected[TimeDurationKey.Weeks] = false;
			}
		}

		setFormat(prevSelected);
	}

	return (
		<Container>
			<Input
				label={translate(dict => dict.variables.timeFormat)}
				type={InputType.Text}
				value={stringFormat}
				onChange={() => undefined}
				marginOffset={{ bottom: 1.6 }}
			/>
			<CheckboxWrapper>
				<CheckboxGroupUncontrolled
					values={selectedValues}
					options={SELECT_TIME_OPTIONS}
					onChange={onFieldSelect}
					name="time_opts"
				/>
			</CheckboxWrapper>
		</Container>
	);
}

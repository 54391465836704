import styled, { css } from 'styled-components';

import { getTypographyCSS, Typography } from 'components/UI/Typography';
import { Colors } from 'environment';
import { Input } from 'components/UI/Inputs/Input';

interface ListContainerProps {
	isDragging?: boolean;
}
export const ListContainer = styled.div<ListContainerProps>`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: white;
	border-radius: 0.4rem;

	${({ isDragging }) =>
		isDragging &&
		css`
			box-shadow: 5px 10px 40px rgba(50, 62, 73, 0.1);
		`}
`;

interface ListProps {
	isOpen: boolean;
}
export const List = styled.div<ListProps>`
	flex: 1;
	display: flex;
	flex-direction: column;

	${({ isOpen }) =>
		!isOpen &&
		css`
			height: 10rem;
		`}
`;

export const CollapseContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
	margin-left: 4rem;
	position: relative;
`;

export const FlexCellNewVariableSelector = styled.div`
	width: 100%;
	max-width: 36rem;
	margin-right: 1.2rem;
`;

export const FlexCellNewDependencyOption = styled.div`
	flex: 16;
	max-width: 18rem;
	min-width: 10rem;
`;

export const FlexCellNewDependencyDescription = styled.div`
	width: 100%;
	max-width: 36rem;
	margin-left: 1.2rem;
`;

export const DependencyDescriptionInput = styled(Input)`
	& > :nth-child(2) {
		& > * {
			height: 2.6rem;
			min-height: 2.4rem;
		}
	}
`;

export const NewHline = styled.span`
	display: block;
	width: 0.9rem;
	height: 0.1rem;
	position: absolute;
	top: 1.7rem;
	left: -2.1rem;
	background-color: ${Colors.vibrantGreen};
`;

export const NewButton = styled(Typography.Caption)`
	color: ${Colors.primary.normal};
	padding: 0.7rem;
	cursor: pointer;
	margin-left: -1.3rem;
`;

export const HLine = styled.div`
	display: block;
	width: 4.1rem;
	height: 0.1rem;
	position: absolute;
	top: 1.3rem;
	left: -4.1rem;
	background-color: ${Colors.vibrantGreen};
`;

interface VLineProps {
	fullHeight?: boolean;
}
export const VLine = styled.div<VLineProps>`
	display: block;
	width: 0.1rem;
	position: absolute;
	height: ${({ fullHeight }) => (fullHeight ? '100%' : '3.5rem')};
	top: -2.1rem;
	left: -4.1rem;
	background-color: ${Colors.vibrantGreen};
`;

// Added DoubleVLine when there is a dependency rule that spans on 2 rows
export const DoubleVLine = styled.div<VLineProps>`
	display: block;
	width: 0.1rem;
	position: absolute;
	height: 6.5rem;
	top: -5.1rem;
	left: -4.1rem;
	background-color: ${Colors.vibrantGreen};
`;

export const RuleWrapper = styled.div`
	position: relative;

	/* TODO: make this global */
	.creatable-select-small {
		.select-container {
			min-height: 2.4rem;
		}

		.select__control {
			min-height: 2.4rem;
		}

		.select__value-container {
			height: 2.4rem;
			min-height: 2.4rem;
			align-items: center;

			> :last-child {
				height: 2rem;
				margin: 0;
				padding: 0;

				.select__input[style] {
					display: flex !important;
				}

				input,
				.select__input {
					height: 2rem;
				}
			}
		}

		.select__single-value {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
			height: 2rem;
			margin: 0;
		}

		.select__placeholder {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.disabled};
			margin: 0;
			padding: 0;
		}

		input[style] {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
		}

		input {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
			font-size: 1.2rem !important;
		}
	}

	.dropdown-small {
		> :nth-child(1) {
			> :nth-child(1) {
				height: 2.64rem;

				p {
					${getTypographyCSS(t => t.Caption)}

					color: ${Colors.text.main};
				}
			}
		}
	}

	.input-small {
		input {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
			height: 2.64rem;
		}
	}

	.date-input-small {
		.react-date-picker {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
			height: 2.64rem;

			#calendaricon svg {
				height: 2.2rem;
			}
		}

		input {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
		}

		.react-date-picker__inputGroup {
			min-width: unset;
		}

		.react-date-picker__inputGroup__leadingZero,
		.react-date-picker__inputGroup__divider {
			${getTypographyCSS(t => t.Caption)}

			color: ${Colors.text.main};
		}

		.react-date-picker__calendar,
		.react-date-picker__calendar--open {
			left: unset !important;
			right: 0px !important;
		}
	}
`;

import { Provider } from 'react-redux';

import { getStore } from 'store/store';

interface Props {
	children: React.ReactNode;
}

const store = getStore();

export function Store({ children }: Props) {
	return <Provider store={store}>{children}</Provider>;
}

import { LocalOperation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import { appendFormGroup, AppendFormGroupAction } from 'store/data/forms';
import { ActionPayload } from 'store/types';

export function useAppendFormGroup(): LocalOperation<
	(payload: ActionPayload<AppendFormGroupAction>) => void
> {
	const dispatch = useDispatch();

	function handler(payload: ActionPayload<AppendFormGroupAction>) {
		dispatch(appendFormGroup(payload));
	}

	return handler;
}

import produce from 'immer';

import initialState from './initialState';
import { Actions, State, ActionTypes, DistributionsById } from './types';

import { Actions as ProjectsActions, ActionTypes as ProjectsActionTypes } from '../projects/types';
import { convertToGlobalStartDate } from 'components/UI/Inputs/DateTimeInput/helpers';

export default (state: State = initialState, action: Actions | ProjectsActions): State => {
	switch (action.type) {
		case ProjectsActionTypes.SET_PROJECT_ID: {
			const { projectId } = action.payload;

			return produce(state, draft => {
				if (projectId && !draft.byProjectId[projectId]) {
					draft.byProjectId[projectId] = {
						distributions: {
							fetched: false,
							initial: {
								byId: {},
								ids: []
							},
							current: {
								byId: {},
								ids: []
							}
						},
						patients: { fetched: false, byId: {} }
					};
				}

				draft.metadata.pageIndex = 0;
				draft.metadata.pageSize = 10;
				draft.metadata.term = '';
			});
		}

		case ActionTypes.SET_PATIENTS_SEARCH_TERM: {
			const { term } = action.payload;

			return produce(state, draft => {
				draft.metadata.term = term;
			});
		}

		case ActionTypes.SET_PATIENTS_PAGE_INDEX: {
			const { pageIndex } = action.payload;

			return produce(state, draft => {
				draft.metadata.pageIndex = pageIndex;
			});
		}

		case ActionTypes.SET_SENDER_DESCRIPTION: {
			const { senderDescription } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { current } = byProjectId[projectId].distributions;

					current.senderDescription = senderDescription;
				}
			});
		}

		case ActionTypes.SET_CENTRALISE_ENTRY_DATA: {
			const { centraliseEntryData } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { current } = byProjectId[projectId].distributions;

					current.centraliseEntryData = centraliseEntryData;
				}
			});
		}

		case ActionTypes.ADD_PATIENT_DISTRIBUTION: {
			return produce(state, draft => {
				const { patient } = action.payload;

				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId] && patient.patientId) {
					const { byId } = draft.byProjectId[projectId].patients;
					byId[patient.patientId] = patient;
				}
			});
		}

		case ActionTypes.UPDATE_PATIENT_DISTRIBUTION: {
			const { patient } = action.payload;

			return produce(state, draft => {
				const projectId = draft.projectId;
				if (patient.patientId && projectId) {
					const { byId } = draft.byProjectId[projectId].patients;
					byId[patient.patientId] = patient;
				}
			});
		}

		case ActionTypes.DELETE_PATIENT_DISTRIBUTION: {
			const { patientId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { byId } = draft.byProjectId[projectId].patients;

					delete byId[patientId];
				}
			});
		}

		case ActionTypes.GET_PATIENT_DISTRIBUTION_LIST: {
			const { projectId, patients } = action.payload;

			return produce(state, draft => {
				const { byProjectId } = draft;

				if (projectId in byProjectId) {
					const data = byProjectId[projectId];

					patients.forEach(patient => {
						data.patients.byId[patient.patientId] = patient;
					});
					data.patients.fetched = true;
				}
			});
		}

		case ActionTypes.GET_DISTRIBUTIONS: {
			const {
				projectId,
				distributions,
				senderDescription,
				globalStartDate,
				centraliseEntryData
			} = action.payload;

			return produce(state, draft => {
				const ids: string[] = [];
				const byId: DistributionsById = {};

				distributions.forEach(distribution => {
					if (distribution.distributionEntryId) {
						byId[distribution.distributionEntryId] = distribution;
						ids.push(distribution.distributionEntryId.toString());
					}
				});

				draft.byProjectId[projectId].distributions = {
					fetched: true,
					initial: {
						byId,
						ids,
						senderDescription,
						centraliseEntryData
					},
					current: {
						byId,
						ids,
						senderDescription,
						centraliseEntryData
					}
				};

				if (globalStartDate) {
					const parsedGlobalStartDate =
						convertToGlobalStartDate(Date.parse(globalStartDate ?? '')) + '+0000';

					draft.byProjectId[projectId].distributions.initial.globalStartDate =
						parsedGlobalStartDate;
					draft.byProjectId[projectId].distributions.current.globalStartDate =
						parsedGlobalStartDate;
				}
			});
		}

		case ActionTypes.ADD_NEW_DISTRIBUTION: {
			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { ids, byId } = byProjectId[projectId].distributions.current;

					const tempId = -new Date().getTime();

					byId[tempId.toString()] = {
						quantity: undefined,
						unitOfTime: 'days',
						distributionEntryId: tempId
					};

					ids.push(tempId.toString());
				}
			});
		}

		case ActionTypes.UPDATE_DISTRIBUTION: {
			const { updatedDistribution, internalId } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { byId } = byProjectId[projectId].distributions.current;

					if (internalId < 0) delete byId[internalId];

					byId[updatedDistribution.distributionEntryId] = updatedDistribution;
				}
			});
		}

		case ActionTypes.UPDATE_DISTRIBUTIONS: {
			const { distributions, senderDescription, centraliseEntryData, globalStartDate } =
				action.payload;

			return produce(state, draft => {
				const projectId = Number(draft.projectId);
				const ids: string[] = [];
				const byId: DistributionsById = {};

				distributions.forEach(distribution => {
					if (distribution.distributionEntryId) {
						byId[distribution.distributionEntryId] = distribution;
						ids.push(distribution.distributionEntryId.toString());
					}
				});

				const draftDistributions = draft.byProjectId[projectId].distributions;

				draftDistributions.initial = {
					byId,
					ids,
					globalStartDate,
					senderDescription,
					centraliseEntryData
				};
				draftDistributions.current = {
					byId,
					ids,
					globalStartDate,
					senderDescription,
					centraliseEntryData
				};
			});
		}

		case ActionTypes.DELETE_DISTRIBUTION: {
			const { distributionId: deletedDistributionId } = action.payload;

			return produce(state, draft => {
				const projectId = draft.projectId;
				if (projectId) {
					delete draft.byProjectId[projectId].distributions.current.byId[
						deletedDistributionId
					];
					const newIds = draft.byProjectId[projectId].distributions.current.ids.filter(
						id => id !== deletedDistributionId.toString()
					);
					draft.byProjectId[projectId].distributions.current.ids = newIds;
				}
			});
		}

		case ActionTypes.RESET_DISTRIBUTIONS: {
			return produce(state, draft => {
				const { projectId } = draft;
				if (projectId) {
					const { initial } = draft.byProjectId[projectId].distributions;
					draft.byProjectId[projectId].distributions.current = initial;
				}
			});
		}

		case ActionTypes.SET_PATIENT_MANUAL_SURVEY_PARAMS: {
			const params = action.payload;

			return produce(state, draft => {
				draft.surveyParams.manual = params;
			});
		}

		case ActionTypes.SET_DISTRIBUTION_GLOBAL_START_DATE: {
			const { globalStartDate } = action.payload;

			return produce(state, draft => {
				const { projectId, byProjectId } = draft;

				if (projectId && byProjectId[projectId]) {
					const { current } = byProjectId[projectId].distributions;

					current.globalStartDate = globalStartDate;
				}
			});
		}

		default: {
			return state;
		}
	}
};
